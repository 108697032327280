// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const Button = (props) => {
  return (
    <div
      className={
        props.cssClassNamePrefix + '__move tc__center-text artifact-move'
      }
    >
      <a
        className={
          'tc__clear ' + props.cssClassNamePrefix + '__upload-link tc__link'
        }
        onClick={props.onClick}
      >
        <div
          className={
            'tc-share-arrow ' +
            props.cssClassNamePrefix +
            '__move-icon artifact-move__icon'
          }
        ></div>
        <div
          className={
            'tc__link ' +
            props.cssClassNamePrefix +
            '__move-caption artifact-move__caption'
          }
        >
          {props.label}
        </div>
      </a>
    </div>
  );
};

Button.proptypes = {
  cssClassNamePrefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Button;
