// @ts-nocheck FIXME
import ZenScroll from 'zenscroll';
import ParticipantApi from './../../apis/ParticipantApi';
import Analytics from './../../components/Analytics';
import Utils from './../../components/DynamicUtils';
import { Router } from './../../components/Router';
import Store from './../../components/Store';
import WindowHelpers from './../../components/WindowHelpers';

const store = {
  step: 1,
  participant: {},
  ui: {
    showIncompleteHeadline: false,
    loading: false,
    errors: {},
    alert: {},
    techLevel: 0,
    schoolStep: {
      // display, search, or new
      mode: 'search',
      schoolZipcode: {
        inputStatus: 'blur',
        charCount: 0,
        selectedOption: null,
        visibleOptions: [],
      },
    },

    grades: [],
    allGradesChecked: false,
    subjects: [],
    participantAvailabilities: {},
  },
  allGradeIds: [],
  allSubjectIds: [],
  allSubjectsId: null,
};

/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

const goToNextStep = () => {
  if (store.step < 6) {
    Router.route(
      '/coaching/lab/participant/' +
        store.participant.id +
        '/registration/' +
        (store.step + 1) +
        '?from_step=' +
        store.step
    );
  } else {
    Router.route('/lab/home?from=user_welcome');
  }
};

const initParticipatAvailabilities = (data) => {
  store.ui.participantAvailabilities = data.participant.availabilities || {};
};

const initAllSubjectsState = (data) => {
  for (let i = data.subjects.length - 1; i >= 0; i--) {
    if (data.subjects[i].label === 'All Subjects') {
      store.allSubjectsId = data.subjects[i].id;
      break;
    }
  }
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.step = data.step || 1;
  store.participant = data.participant || {};
  store.ui.showIncompleteHeadline = !!data.showIncompleteHeadline || false;
  store.ui.errors = {};
  store.ui.alert = {};
  store.ui.grades = data.participant.grade_ids || [];
  store.ui.subjects = data.participant.subject_ids || [];

  store.ui.techLevel = store.participant.tech_level
    ? store.participant.tech_level
    : 1;
  if (data.participant.institution_id) {
    store.ui.schoolStep.mode = 'display';
  }

  store.allGradeIds = data.grades.map((grade) => {
    return grade.id;
  });
  store.allSubjectIds = data.subjects.map((subject) => {
    return subject.id;
  });

  initParticipatAvailabilities(data);

  initAllSubjectsState(data);

  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

// clear the errors when input is focused on
store.clearErrorsOnFocus = (field) => {
  if (field in store.ui.errors) {
    delete store.ui.errors[field];
    return true;
  }

  return false;
};

store.setSchoolStepMode = (mode) => {
  store.ui.schoolStep.mode = mode;
  store.ui.schoolStep.schoolZipcode.selectedOption = null;
  store.ui.errors = {};
  store.ui.alert = {};
};

store.updateSelect = (data) => {
  Object.assign(store.ui.schoolStep[data.field], data.data);
  store.clearErrorsOnFocus(data.field);
};

store.getSchoolVisibleOptions = (data) => {
  store.ui.schoolStep[data.field].charCount = data.input.length;
  return ParticipantApi.searchInstitutions({
    searchString: data.input,
  })
    .then((response) => {
      store.ui.schoolStep[data.field].visibleOptions = response.result;
    })
    .catch((response) => {
      setErrorAlert(response.error);
    });
};

store.nextStep = () => {
  goToNextStep();
};

const scrollToError = () => {
  // scroll to the first error
  window.setTimeout(() => {
    const firstError = document.querySelector('.error');
    ZenScroll.setup(300, WindowHelpers.isMobileDevice() ? 55 : 10);
    if (firstError) {
      ZenScroll.to(firstError);
    }
  }, 100);
};

store.submit = (data) => {
  data = data || {};
  store.ui.loading = false;
  store.ui.errors = {};
  store.ui.alert = {};

  const errors = {};
  data.userId = store.participant.user_id;
  switch (store.step) {
    case 1:
      if (!data.firstName) {
        errors.firstName = 'Please enter a first name';
      }
      if (!data.lastName) {
        errors.lastName = 'Please enter a last name';
      }
      if (!data.role) {
        errors.role = 'Please select a role';
      }
      if (!data.yearsTeaching || data.yearsTeaching < 0) {
        errors.yearsTeaching = 'Please enter a valid number';
      }
      if (data.yearsTeaching > 100) {
        errors.yearsTeaching = 'Please enter a value between 0 and 100';
      }
      if (![0, 1].includes(data.isReturning)) {
        errors.isReturning = 'Please select an option';
      }
      if (parseInt(data.isReturning) === 1 && !data.coachPreference) {
        errors.coachPreference = 'Please select an option';
      }
      break;
    case 2:
      // in "search" mode and user did not click on "No school", validate the single select input
      if (store.ui.schoolStep.mode === 'search' && !data.noSchool) {
        if (!store.ui.schoolStep.schoolZipcode.selectedOption) {
          errors.schoolZipcode = 'Please select a school';
        }
        // if there are no errors with the search input, add data relevant to step
        else {
          data.institutionId =
            store.ui.schoolStep.schoolZipcode.selectedOption.id;
        }
      }
      // in "new" mode, validate name/zip
      else if (store.ui.schoolStep.mode === 'new') {
        if (!data.schoolName) {
          errors.schoolName = 'Please enter a name';
        }
        if (!data.schoolZipcode) {
          errors.schoolZipcode = 'Please enter a zip code';
        } else if (!/^[a-z0-9]+$/i.test(data.schoolZipcode)) {
          errors.schoolZipcode = 'Please enter numbers or letters only';
        }
      }
      break;
    case 3:
      if (!store.ui.grades.length && !store.ui.allGradesChecked) {
        errors.grade = 'Select at least one grade to continue';
      }
      data.grades = store.ui.allGradesChecked
        ? store.allGradeIds
        : store.ui.grades;
      if (!data.importGradeId) {
        errors.gradeBand = 'Please select an option';
      }
      break;
    case 4:
      if (!store.ui.subjects.length) {
        errors.subject = 'Please select which subjects you teach';
      }
      data.subjects = store.ui.subjects;
      if (!data.sped) {
        errors.sped = 'Please select an option';
      }
      if (!data.ell) {
        errors.ell = 'Please select an option';
      }
      if (!data.importSubjectId) {
        errors.subjectFocus = 'Please select an option';
      }
      break;
    case 5:
      if (!data.timezone) {
        errors.timezone = 'Please select a time zone';
      }
      if (Utils.isEmpty(store.ui.participantAvailabilities)) {
        errors.availabilities = 'Please select at least one';
      }
      data.participantAvailabilities = store.ui.participantAvailabilities;
      break;
    case 6:
      console.error('step 6 is in the oven');
      break;
  }

  // if the form has any errors, display them instead of submitting the form
  if (Object.keys(errors).length > 0) {
    store.ui.errors = errors;
    scrollToError();
    return;
  }

  // set up the UI to show the loading animation
  store.ui.loading = true;

  // add general data to send to api
  data.step = store.step;
  data.participantId = store.participant.id;

  return ParticipantApi.editParticipantData(data)
    .then(() => {
      store.ui.loading = false;
      goToNextStep();
    })
    .catch(() => {
      store.ui.loading = false;
      setErrorAlert(
        'Sorry, there was a problem submitting the form. Please try again later.'
      );
    });
};

store.setGrades = (grades) => {
  store.ui.grades = grades;
};

store.setSubjects = (subjects) => {
  store.ui.subjects = subjects;
};

store.setParticipantAvailability = (data) => {
  store.ui.participantAvailabilities[data.id] = data.answer;
};

store.deleteParticipantAvailability = (data) => {
  delete store.ui.participantAvailabilities[data.id];
};

store.setTechLevel = (data) => {
  store.ui.techLevel = data.techLevel;
  Analytics.logEvent({
    category: 'leverage_tech',
    action: 'level' + data.techLevel,
    label: 'from_sixth_page_user_welcome',
  });
};

store.initalStepNoAnswer = () => {
  store.ui.errors.initalStep = 'Please confirm or deny';
};

export default Store('ParticipantRegistrationStore', store);
