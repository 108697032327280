// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import CoachParticipant from './CoachParticipant';
import Links from './Links';
import User from './User';

const HamburgerMenu = (props) => {
  const closeMobileDropdown = () => {
    props.setMobileDropdownOpen(false);
  };
  useEffect(() => {
    const menuLinks = document.querySelectorAll(
      '.header__dropdown-mobile--v5 a'
    );
    menuLinks.forEach((link) => {
      delayedEventListener(() =>
        link.addEventListener('click', closeMobileDropdown)
      );
    });
    return () => {
      menuLinks.forEach((link) => {
        link.removeEventListener('click', closeMobileDropdown);
      });
    };
  });

  let rowClass = 'row--1200';

  if (props.responsive) {
    rowClass = 'row--responsive';
  }

  const visibleClass = props.mobileDropdownOpened ? 'show' : '';

  // set the class to make the mobile menu scrollable
  const overflowClass = props.mobileDropdownOverflows ? ' overflow' : '';

  let coachParticipantNode;
  let coachNavSeperator;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(props.user.auth_roles, [
      'BL_SERVICES_MANAGER',
      'BL_CONTRACT_COACH',
      'BL_MANAGER',
      'ADMIN',
    ]) &&
    props.headerParticipant
  ) {
    coachNavSeperator = (
      <div className="v5__border-gradient--agave header__dropdown-mobile-divider"></div>
    );
    coachParticipantNode = (
      <div className="tc-header__admin-nav tc-header__admin-nav--v2 tc-header__admin-nav--mobile tc-header__coach-nav-v5">
        <div className={`row ${rowClass}`}>
          <CoachParticipant participant={props.headerParticipant} />
        </div>
      </div>
    );
  }

  return (
    <div
      id="header__dropdown--mobile"
      className={`header__dropdown  ${visibleClass} cute-12-desktop ${overflowClass} header__dropdown-mobile--v5`}
    >
      {coachParticipantNode}
      {coachNavSeperator}
      <Links user={props.user} />
      <div className="v5__border-gradient--agave header__dropdown-mobile-divider"></div>
      <User user={props.user} />
    </div>
  );
};

export default HamburgerMenu;

HamburgerMenu.propTypes = {
  user: PropTypes.object.isRequired,
  mobileDropdownOpened: PropTypes.bool.isRequired,
  mobileDropdownOverflows: PropTypes.bool.isRequired,
  headerParticipant: PropTypes.object,
  setMobileDropdownOpen: PropTypes.func,
};
