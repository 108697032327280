// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import React from 'react';
import ReactDOM from 'react-dom';
import StrategyApi from './../../../apis/StrategyApi';
import Analytics from './../../../components/Analytics';
import Utils from './../../../components/DynamicUtils';
import LayerManager from './../../../components/LayerManager';
import StrategyMultiSelectStore from './../../../stores/V2/StrategyMultiSelectStore';
import LayerClose from './../../LayerManager/LayerClose';
import NavigationLink from './../../NavigationLink';
import MultiSelect from './MultiSelectCustom';

class CustomStrategyDialogue extends React.Component {
  static contextType = LegacyLabContext;

  constructor(props) {
    super(props);
    StrategyMultiSelectStore.actionDispatchSync('init', props);
    this.state = StrategyMultiSelectStore.getState();
  }

  token = null;

  componentDidMount() {
    this.token = StrategyMultiSelectStore.subscribe(this.onStoreChange);

    // Log the Add Analytics events:
    Analytics.logEvent({
      category: 'custom_strategy',
      action: 'open',
      label: 'from_create_custom_strategy',
    });
  }

  componentWillUnmount() {
    StrategyMultiSelectStore.actionDispatchSync('init', {});
    StrategyMultiSelectStore.unsubscribe(this.token);
  }

  onStoreChange = () => {
    this.setState(StrategyMultiSelectStore.getState());
  };

  getCoachStrategyData = () => {
    // make sure at least one name is selected
    let customStrategyData = {};
    if (!this.state.teachers.length && !this.state.teams.length) {
      customStrategyData.error = 'You must select at least one name';
      return customStrategyData;
    }

    // build the data to be submitted
    customStrategyData = {
      coachId: this.props.user.id,
      teacherIds: this.state.teachers,
      teamId: this.state.teams,
    };

    return customStrategyData;
  };

  handleCreateStrategy = (e) => {
    e.preventDefault();

    StrategyMultiSelectStore.actionDispatchSync('setBtnDisabled', true);
    StrategyMultiSelectStore.actionDispatchSync('setErrors', {});

    const strategyTitle = ReactDOM.findDOMNode(this.refs.strategyTitle).value;
    const strategyDescription = ReactDOM.findDOMNode(
      this.refs.strategyDescription
    ).value;

    // get the appropreate data necessary for a coach or teacher
    let customStrategyData = {};
    customStrategyData.creatorType = 'teacher';
    if (Utils.isServicesManager.call(this.context)) {
      customStrategyData = this.getCoachStrategyData();
      customStrategyData.creatorType = 'coach';
    }

    const errors = {};

    // check to see if an error was returned from getCoachStrategyData
    if (customStrategyData.error) {
      errors.names = ['You must select at least one name'];
    }

    // validate the title and description fields
    if (!strategyTitle) {
      errors.strategyTitle = 'Enter a name for this strategy';
    }
    if (!strategyDescription) {
      errors.strategyDescription = 'Enter a description for this strategy';
    }

    // if there are any errors set them and stop
    if (!Utils.isEmpty(errors)) {
      StrategyMultiSelectStore.actionDispatchSync('setErrors', errors);
      StrategyMultiSelectStore.actionDispatch('setBtnDisabled', false);
      return;
    }

    // set the title and description
    customStrategyData.userId = this.props.user.id;
    customStrategyData.title = strategyTitle;
    customStrategyData.description = strategyDescription;
    customStrategyData.challengeId = this.props.challenge.id;

    // add the challenge to the selected teachers' growth maps
    StrategyApi.createCustomStrategy(customStrategyData)
      .then(
        function () {
          // close the modal
          LayerManager.close();

          // re-enable the button
          StrategyMultiSelectStore.actionDispatch('setBtnDisabled', false);
          StrategyMultiSelectStore.actionDispatch('setSelections', {
            teachers: [],
            teams: [],
          });

          // display success message
          this.props.onShowAlert(
            'success',
            <span>
              Your custom strategy has been created. See it on your{' '}
              <NavigationLink
                url={
                  '/coaching/lab/teacher/' + this.props.user.id + '/dashboard'
                }
              >
                playlist
              </NavigationLink>
              .
            </span>
          );

          // Log the Add Analytics events:
          Analytics.logEvent({
            category: 'custom_strategy',
            action: 'create',
            label: 'from_create_custom_strategy',
          });
        }.bind(this)
      )
      .catch(
        function () {
          // close the modal
          LayerManager.close();

          // re-enable the button
          StrategyMultiSelectStore.actionDispatch('setBtnDisabled', false);
          StrategyMultiSelectStore.actionDispatch('setSelections', {
            teachers: [],
            teams: [],
          });

          // display success message
          this.props.onShowAlert(
            'error',
            'Sorry, there was a problem adding custom strategy. Please try again later.'
          );
        }.bind(this)
      );
  };

  handleClearError = (errorField) => {
    StrategyMultiSelectStore.actionDispatch('clearError', errorField);
  };

  render() {
    let namesErrorClass = '';
    let namesErrorNode = null;
    if (this.state.errors.names) {
      namesErrorClass = ' error';
      namesErrorNode = (
        <div className="tc__error">
          {this.state.errors.names.map(function (error) {
            return <div key={'error-' + error}>{error}</div>;
          })}
        </div>
      );
    }

    let strategyTitleErrorClass = '';
    let strategyTitleErrorNode = null;
    if (this.state.errors.strategyTitle) {
      strategyTitleErrorClass = ' error';
      strategyTitleErrorNode = (
        <div className="tc__error">{this.state.errors.strategyTitle}</div>
      );
    }

    let strategyDescriptionErrorClass = '';
    let strategyDescriptionErrorNode = null;
    if (this.state.errors.strategyDescription) {
      strategyDescriptionErrorClass = ' error';
      strategyDescriptionErrorNode = (
        <div className="tc__error">{this.state.errors.strategyDescription}</div>
      );
    }

    const btnDisabledClass = this.state.btnDisabled ? ' disabled' : '';

    // only display the multiselect node for coaches
    let multiSelectNode = null;
    if (Utils.isServicesManager.call(this.context)) {
      multiSelectNode = (
        <div className="coach-pin__multiselect-container">
          <label className="coach-pin__label custom-strategy__multiselect-label">
            For
          </label>
          <div
            className={
              'custom-strategy__multiselect-input coach-pin__multiselect' +
              namesErrorClass
            }
          >
            <MultiSelect
              options={this.props.user.constituents}
              placeholder="Teacher Name or Team Name"
              analyticsLabel="from_create_custom_strategy"
            />
          </div>
          <div className="clearfix"></div>
        </div>
      );
    }

    // setup the analytics event for modal close
    const closeModalAnalyticsEvent = {
      category: 'custom_strategy',
      action: 'close',
      label: 'from_create_custom_strategy',
    };

    return (
      <div className="custom-strategy">
        <LayerClose
          href="#"
          className="modal__close"
          analyticsEvent={closeModalAnalyticsEvent}
        >
          <div className="tc-x"></div>
        </LayerClose>
        <h3 className="custom-strategy_headline coach-pin__headline tc__headline-3">
          Create a custom strategy
        </h3>
        <form className="custom-strategy__form">
          <div className="custom-strategy__field-container">
            {multiSelectNode}
            {namesErrorNode}
          </div>
          <div className="custom-strategy__field-container">
            <div className="custom-strategy__label">
              Give your Strategy a name.
            </div>
            <input
              type="text"
              ref="strategyTitle"
              className={
                'tc__input custom-strategy__input' + strategyTitleErrorClass
              }
              onFocus={this.handleClearError.bind(this, 'strategyTitle')}
            />
            {strategyTitleErrorNode}
          </div>
          <div className="custom-strategy__field-container">
            <div className="custom-strategy__label">
              Describe your Strategy. It&rsquo;s a good idea to outline the
              implementation steps, the goals and desired outcomes of the
              Strategy, and any resources needed to implement it.
            </div>
            <textarea
              ref="strategyDescription"
              className={
                'tc__input custom-strategy__textarea' +
                strategyDescriptionErrorClass
              }
              onFocus={this.handleClearError.bind(this, 'strategyDescription')}
            />
            {strategyDescriptionErrorNode}
          </div>

          <a
            href="#"
            onClick={this.handleCreateStrategy}
            className={
              'tc__btn tc__btn--blue tc__btn--small' + btnDisabledClass
            }
          >
            Create strategy
          </a>
        </form>
      </div>
    );
  }
}

export default CustomStrategyDialogue;
