// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';
import React from 'react';
import ReservationSessions from './modules/ReservationSessions';

const ConfirmReservation = (props) => {
  const [selectedGroup, setSelectedGroup] = React.useState(
    props.event.event_groups.length && props.event.event_groups[0].id
  );
  const handleGoHome = () => {
    window.location = '/';
  };

  const serviceImage = Utils.mapBLServiceNameToIllustration(
    props.event.service
  );

  const timezone = props.event.time_zone.pytz_timezone;

  return (
    <div className="tc__page tc__page--registration">
      <div className="tc-header__wrap tc-header__wrap--v2">
        <div className="tc-header tc-header--v2 row row--1200">
          <div
            className="logo tc-header__logo cute-3-desktop cute-3-phone left tc-header__registration-phone"
            onClick={handleGoHome}
            src="/legacy-images/betterlesson-logo.svg"
          ></div>
        </div>
      </div>
      <div className="row row--1200">
        <div className="cute-5-desktop cute-12-phone left registration-v5-event__event-image-column">
          <img
            src={`${serviceImage}`}
            className="code-404__img event-v5__headline-image"
          />
        </div>
        <div className={'cute-7-desktop cute-12-phone'}>
          <div className="v5__heading-4 event-v5__reservation-headline">
            You&apos;re going to
          </div>
          <div className="registration-v5-event__event-display-name v5__heading-2">
            {props.event.display_name}
          </div>
          <div className="reservation__session-list">
            <ReservationSessions
              sessions={props.event.sessions}
              eventGroups={props.event.event_groups}
              advancedEvent={props.event.advanced_event}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              timezone={timezone}
            />
          </div>
          <div className="v5__body-copy reservation__confirm-info-text">
            Stay tuned for more detailed information as the event date
            approaches.
          </div>
          <div className="v5__body-copy reservation__confirm-info-text">
            In the meantime, browse thousands of instructional strategies and
            lessons on the{' '}
            <NavigationLink url="/lab/home" className="v5__link">
              BetterLesson website
            </NavigationLink>
            .
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmReservation.propTypes = {
  event: PropTypes.object.isRequired,
};

export default ConfirmReservation;
