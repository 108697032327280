// @ts-nocheck FIXME
import Analytics from './../../components/Analytics';
import Modal from './../../components/Modal';
import Store from './../../components/Store';

const store = {
  ui: {
    alert: {},
    artifactModal: {},
    meetingClipPlayerModal: {},
  },
};

/****** PRIVATE FUNCTIONS ******/

const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

/****** EXTERNAL FUNCTIONS ******/

store.init = (data) => {
  store.ui.alert = {};

  // analytics depend on current page
  store.ui.artifactModal.callbacks = {
    onOpen: () => {
      Analytics.logEvent({
        category: 'artifact_overlay',
        action: 'open',
        label: data.isVisionPage
          ? 'ppd3_participant_portfolio_presentation_UI_vision'
          : 'ppd3_participant_portfolio_presentation_UI_outcome',
      });
    },
    onClose: () => {
      Analytics.logEvent({
        category: 'artifact_overlay',
        action: 'close',
        label: data.isVisionPage
          ? 'ppd3_participant_portfolio_presentation_UI_vision'
          : 'ppd3_participant_portfolio_presentation_UI_outcome',
      });
    },
  };

  // Init the modals
  store.ui.artifactModal = Modal(store, store.ui.artifactModal);
  store.ui.meetingClipPlayerModal = Modal(
    store,
    store.ui.meetingClipPlayerModal
  );

  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

export default Store('ParticipantPorfolioPresentationStore', store);
