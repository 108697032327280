// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';
import UserPhoto from './../../Common/UserPhoto';

const UserInfo = (props) => {
  // Show the admin dashboard link if the user has a partner leader record that isn't archived
  const activeLead = props.user.leads.find((lead) => !lead.archived);

  const adminDashboardNode = activeLead && (
    <NavigationLink
      className="home-v5__quick-link"
      url={`/lab/leader/${activeLead.id}/home`}
    >
      <div className="tc-v5-admin-dash"></div>
      <div className="home-v5__quick-link-copy">Admin Dashboard</div>
    </NavigationLink>
  );

  return (
    <div className="home-v5__info-capsule">
      <div className="participant-details-v5__participant-info--user-photo">
        <UserPhoto
          src={props.user.photo}
          userId={props.user.id}
          diameter={80}
          firstName={props.user.first_name}
          lastName={props.user.last_name}
          editable={true}
        />
      </div>
      <div className="v5__heading-3 home-v5__user-photo">
        Hello, {props.user.first_name}!
      </div>
      <div className="home-v5__quick-links">
        <NavigationLink
          className="home-v5__quick-link"
          url={`/lab/workspace/${props.user.id}`}
        >
          <div className="tc-v5-workspace"></div>
          <div className="home-v5__quick-link-copy">Workspace</div>
        </NavigationLink>
        <NavigationLink
          className="home-v5__quick-link"
          url={`${process.env.REACT_APP_TEACHING_URL}/browse/favorites/${props.user.id}`}
        >
          <div className="tc-v4-heart-filled"></div>
          <div className="home-v5__quick-link-copy">Favorites</div>
        </NavigationLink>
        {adminDashboardNode}
      </div>
    </div>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object,
  participant: PropTypes.object,
};

export default UserInfo;
