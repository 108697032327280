// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

const ModificationsCapsule = (props) => {
  // hide extra information by default
  const [isExpanded, setIsExpanded] = React.useState(false);

  // whether the capsule shows the arrow and is expandable
  const [isExpandable, setExpandable] = React.useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const arrowClass = isExpanded ? ' edit-event__modifications-arrow--up' : '';

  // render nothing if both props are null or empty
  if (!props.modifications && !props.modificationsExplanation) {
    return null;
  }

  // add Modifications text at the end, replace ; with ,
  const modifications = !props.modifications
    ? 'Modifications'
    : props.modifications.replace(/;/g, ', ') + '\n' + 'Modifications';

  // if no explanation then only show cute-3 instead of cute-12 width
  const showFullWidth = !!props.modificationsExplanation;
  const containerWidth = showFullWidth ? 'cute-12-desktop' : 'cute-3-desktop';
  const modificationWidth = showFullWidth ? 'cute-2-desktop' : '';

  // controls whether the expand arrow is visible
  const handleExpandable = (offsetHeight, scrollHeight) => {
    // if there is height difference (overflow happens) or the capsule already expanded
    if (offsetHeight < scrollHeight || isExpanded) {
      setExpandable(true);
    } else {
      setExpandable(false);
    }
  };

  // controls whether to clamp text if more than 2 lines
  const clampClass = isExpanded ? '' : ' edit-event__modifications-text--clamp';

  // expand/collapse arrow
  const expandArrowNode = isExpandable ? (
    <div
      className={`cute-1-desktop tc-v4-circle-arrow edit-event__modifications-arrow${arrowClass}`}
      onClick={toggleExpanded}
      data-testid="expand-arrow"
    ></div>
  ) : null;

  // use a ref to get the actual height of the element in the DOM
  // to determine if the expand arrow will display or not
  const modificationExplanationNode = showFullWidth ? (
    <>
      <div
        className={`cute-9-desktop edit-event__modifications-text left${clampClass}`}
        ref={(element) => {
          if (!element) return;
          handleExpandable(element.offsetHeight, element.scrollHeight);
        }}
      >
        {props.modificationsExplanation}
      </div>
      {expandArrowNode}
    </>
  ) : null;

  return (
    <div className="row">
      <div
        className={`v5__body-copy edit-event__modifications left ${containerWidth}`}
      >
        <div className="row">
          {/* icons and modification types */}
          <div
            className={`${modificationWidth} edit-event__modifications-title-container left`}
          >
            <span className="tc-v5-alert edit-event__modifications-alert-icon"></span>
            <div className="edit-event__modifications-title-text">
              {modifications}
            </div>
          </div>

          {modificationExplanationNode}
        </div>
      </div>
    </div>
  );
};

ModificationsCapsule.propTypes = {
  modifications: PropTypes.string,
  modificationsExplanation: PropTypes.string,
};

export default ModificationsCapsule;
