// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import AbridgeList from '@legacy-components/react/V5/Common/AbridgeList';
import AbridgeText from '@legacy-components/react/V5/Common/AbridgeText';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const EventOverview = (props) => {
  const overviewNode = props.overview ? (
    <AbridgeText lineCount={11} isExpandable={true}>
      <PurifiedHTML className="ckeditor__output" content={props.overview} />
    </AbridgeText>
  ) : (
    <div className="v5__body-copy--italic">
      No overview has been provided for this event
    </div>
  );

  const editOverviewNode = props.canEditOverview ? (
    <div className="cute-12-desktop">
      <button
        className="v5__btn v5__btn--blue-border extra-wide"
        onClick={() => Router.route(`/bl/event/${props.eventId}/overview`)}
      >
        {`${props.overview ? 'Edit' : 'Add'} Overview`}
      </button>
    </div>
  ) : null;

  const changeNotes = props.changeNotes.length ? (
    <div className="cute-12-desktop">
      <label>
        <div className="v5__heading-5 event__overview--label">Change Notes</div>
        <AbridgeList>
          {props.changeNotes.map((note) => {
            const timezone = note.timeZone.pytz_timezone;
            const changeTime = moment(note.created_formatted).tz(timezone);

            return (
              <div
                key={`change-note-${note.id}`}
                className="event__overview-change-note"
              >
                <PurifiedHTML
                  className="event__overview-change-note-note"
                  content={note.note}
                />
                <div className="v5__body-copy--italic">
                  &mdash;{note.author}, {moment(changeTime).format('L LT z')}
                </div>
              </div>
            );
          })}
        </AbridgeList>
      </label>
    </div>
  ) : null;

  return (
    <div className="row event__overview v5__body-copy">
      <div className="cute-12-desktop">
        <label>
          <div className="v5__heading-5 event__overview--label">
            Event Overview
          </div>
          {overviewNode}
        </label>
      </div>
      {editOverviewNode}
      {changeNotes}
    </div>
  );
};

EventOverview.propTypes = {
  overview: PropTypes.string,
  canEditOverview: PropTypes.bool.isRequired,
  changeNotes: PropTypes.array,
  eventId: PropTypes.number.isRequired,
};

export default EventOverview;
