// @ts-nocheck FIXME
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import PropTypes from 'prop-types';

const DeleteEventSessionRecordingConfirmationModal = (props) => {
  return (
    <ActionModal
      headlineText="Delete Event Session Recording?"
      close={props.close}
      spaceFromTop={50}
    >
      <div className="v5__body-copy edit-event__modal-row">
        This session recording information will be removed and cannot be
        recovered.
      </div>
      <div className="edit-event__modal-btn-row">
        <button className="v5__btn v5__btn--red" onClick={props.confirm}>
          Delete Recording
        </button>
      </div>
    </ActionModal>
  );
};

DeleteEventSessionRecordingConfirmationModal.propTypes = {
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default DeleteEventSessionRecordingConfirmationModal;
