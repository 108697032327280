// @ts-nocheck FIXME
const FailureCapsule = () => {
  return (
    <div className="bl4-result bl4__capsule bl4__capsule--result">
      <div className="tc__center-text">
        <div className="tc__body-copy">
          Our trending strategies and lessons feed are temporarily unavailable.
          (We blame the cat.)
        </div>
        <br />
        <div className="tc__body-copy">
          Thanks for your patience as we tidy up this issue.
        </div>
        <img src="/legacy-images/404-pets.png" className="code-404__img" />
      </div>
    </div>
  );
};

export default FailureCapsule;
