// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const EventSessionCard = (props) => {
  const handleSessionClick = () => {
    if (
      !props.userHasAdminPermissions &&
      (!props.session.session_date_formatted ||
        (!props.sessionActivity.sessionDateStarted &&
          !props.sessionActivity.started &&
          !props.sessionActivity.ended))
    ) {
      return;
    }

    props.handeSetActiveSession(props.session.id);
  };

  // show the Zoom link for the session, and make it clickable during the session
  let sessionZoomLink = null;
  const zoomLink = props.session.zoom_join_link || props.session.zoom_link;
  if (zoomLink) {
    sessionZoomLink = (
      <div className="event__attendee-session-zoomlink">{zoomLink}</div>
    );
    if (props.sessionActivity.started) {
      sessionZoomLink = (
        <a
          href={zoomLink}
          target="_blank"
          rel="noopener noreferrer"
          className="event__attendee-session-zoomlink v5__link"
        >
          {zoomLink}
        </a>
      );
    }
  }

  let sessionFutureClass = ' event__attendee-session-capsule--future';
  let sessionStartedClass = '';
  if (
    props.sessionActivity.sessionDateStarted ||
    props.sessionActivity.started
  ) {
    sessionFutureClass = '';
    sessionStartedClass = ' event__attendee-session-capsule--started';
  }

  let sessionEndedClass = '';
  if (props.sessionActivity.ended) {
    sessionZoomLink = null;
    sessionFutureClass = '';
    sessionEndedClass = ' event__attendee-session-capsule--ended';
  }

  let sessionSelectedClass = '';
  if (
    props.sessionActivity.selected ||
    props.session.id === parseInt(props.activeSessionId)
  ) {
    sessionSelectedClass = ' event__attendee-session-capsule--selected';
  }
  let sessionCardHeadline = null;
  if (props.multiSessionEvent) {
    sessionCardHeadline = (
      <div className="event__attendee-session-name">
        {props.session.name || `Session ${props.session.num}`}
      </div>
    );
  }

  let sessionTimeNode = null;
  if (
    props.session.session_date_formatted &&
    props.session.session_start_time_formatted &&
    props.session.session_end_time_formatted
  ) {
    sessionTimeNode = (
      <div className="event__attendee-session-time">
        {Utils.formatEventSessionTime(
          props.session.session_start_time_formatted,
          props.session.session_end_time_formatted,
          props.eventTimezone
        )}
      </div>
    );
  }

  let sessionDateNode = (
    <div className="v5__heading-3 event__attendee-session-date">TBD</div>
  );
  if (props.session.session_date_formatted) {
    sessionDateNode = (
      <div className="v5__heading-3 event__attendee-session-date">
        {moment(props.session.session_date_formatted).format('MMMM D, YYYY')}
      </div>
    );
  } else {
    sessionFutureClass = ' event__attendee-session-capsule--future';
    sessionStartedClass = '';
    sessionEndedClass = '';
    sessionSelectedClass = '';
  }
  let sessionAdminClass = '';
  if (props.userHasAdminPermissions) {
    sessionAdminClass = ' event__attendee-session-capsule--admin';
  }
  return (
    <div
      onClick={handleSessionClick}
      data-testid={'sessionCapsule'}
      className={`event__attendee-session-capsule v5__capsule v5__capsule--margin-bottom-20 ${sessionSelectedClass}${sessionEndedClass}${sessionStartedClass}${sessionFutureClass}${sessionAdminClass}`}
      key={`session-${props.session.id}`}
    >
      {sessionCardHeadline}
      {sessionDateNode}
      {sessionTimeNode}
      {sessionZoomLink}
    </div>
  );
};

export default EventSessionCard;

EventSessionCard.propTypes = {
  sessionActivity: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  user: PropTypes.object,
  partnerLeads: PropTypes.object,
  eventTimezone: PropTypes.string,
};
