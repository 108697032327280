// @ts-nocheck FIXME
import StringHelpers from '@legacy-components/components/StringHelpers';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const ParticipantMeetingInfo = (props) => {
  const teacher = props.teacher || {};
  const activeRecording =
    props.recording || props.recordings[props.recordingIndex];

  // for meeting clips, show the name and then when the meeting took place
  // for the full meeting video, show when the meeting took place and then the meeting #
  let titleText = `Meeting on ${moment(props.time).format('MMMM D, YYYY')}`;
  let countText = `Meeting ${props.count}`;
  if (props.name) {
    countText = titleText;
    titleText = props.name;
  }

  // show the teacher's school if they set one
  let schoolText;
  if (teacher.institution) {
    schoolText = `at ${teacher.institution.name}`;
  }

  // set up the coach info
  let coachNode;
  if (props.coach) {
    coachNode = (
      <div className="meeting__attendee">
        <UserPhoto
          src={props.coach.photo}
          userId={props.coach.id}
          firstName={props.coach.first_name}
          lastName={props.coach.last_name}
          diameter={50}
          className="meeting__attendee-photo"
        />
        <div className="meeting__attendee-info">
          <div className="meeting__attendee-name">
            {props.coach.first_name} {props.coach.last_name}
          </div>
          <div className="meeting__detail-copy">BetterLesson Coach</div>
        </div>
      </div>
    );
  }

  return (
    <div className="meeting__details">
      <h1 className="v5__heading-1 meeting__headline">{titleText}</h1>
      <div className="meeting__detail-copy">{countText}</div>

      {props.description && (
        <div className="v5__body-copy meeting__notes">{props.description}</div>
      )}

      <h2 className="v5__heading-6 meeting__detail-headline">
        Meeting Recordings
      </h2>
      <div>
        {props.recording || props.isClip ? (
          <div className="meeting__recording-title">
            <div className="inline-icon tc-browser-arrow"></div>Recording{' '}
            {props.recording ? props.recording.count : props.recordingIndex + 1}{' '}
            ({StringHelpers.formatDurationHms(activeRecording.video_length)})
          </div>
        ) : (
          props.recordings.map((recording, i) => {
            const duration = StringHelpers.formatDurationHms(
              recording.video_length
            );
            const isActive = props.recordingIndex === i;
            const clickableClass = isActive ? '' : ' clickable';

            return (
              <div
                key={`recording-link-${recording.id}`}
                className={`meeting__recording-title${clickableClass}`}
                onClick={() => props.setRecordingIndex(i)}
              >
                <div
                  className={`inline-icon ${
                    isActive ? 'tc-browser-arrow' : 'tc-bullet'
                  }`}
                ></div>
                Recording {i + 1} ({duration})
              </div>
            );
          })
        )}
      </div>

      <h2 className="v5__heading-6 meeting__detail-headline">
        Meeting Attendees
      </h2>
      <div className="meeting__attendee">
        <UserPhoto
          src={teacher.photo}
          userId={teacher.id}
          firstName={teacher.first_name}
          lastName={teacher.last_name}
          diameter={50}
          className="meeting__attendee-photo"
        />
        <div className="meeting__attendee-info">
          <div className="meeting__attendee-name">
            {teacher.first_name} {teacher.last_name}
          </div>
          <div className="meeting__detail-copy">Teacher {schoolText}</div>
        </div>
      </div>
      {coachNode}

      <h2 className="v5__heading-6 meeting__detail-headline">Cohort</h2>
      <div className="v5__body-copy">{props.cohort_name}</div>
    </div>
  );
};

ParticipantMeetingInfo.propTypes = {
  coach: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string,
    photo: PropTypes.string,
  }),
  cohort_name: PropTypes.string,
  count: PropTypes.number,
  description: PropTypes.string,
  isClip: PropTypes.bool,
  name: PropTypes.string,
  recordingIndex: PropTypes.number,
  recordings: PropTypes.array,
  setRecordingIndex: PropTypes.func,
  teacher: PropTypes.object,
  time: PropTypes.string,
};

export default ParticipantMeetingInfo;
