// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import MobileArtifactUpload from '@legacy-components/react/V5/Common/MobileArtifactUpload';
import WindowScrollElementFixer from '@legacy-components/react/V5/Common/WindowScrollElementFixer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import AddEntryWidget from './AddEntryWidget';
import WorkspaceEntries from './WorkspaceEntries';
import {
  artifactBuilder,
  customStrategyEntryEditForm,
  textEntryEditForm,
} from './WorkspaceForms';

const WorkspaceTimeline = (props) => {
  const [isMobileAddEntryMenuOpen, setIsMobileAddEntryMenuOpen] =
    React.useState(false);
  const [newEntryType, setNewEntryType] = React.useState(
    props.customStrategyAdd ? 'custom_strategy' : ''
  );

  const timelineRef = React.createRef();

  React.useEffect(() => {
    if (props.scrollTo === 'workspace-timeline') {
      // scroll to the top of the timeline
      timelineRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  });

  const handleOpenNewEntry = (newEntryType) => {
    setIsMobileAddEntryMenuOpen(false);
    setNewEntryType(newEntryType);
    props.setIsEditing(true);

    // scroll to the top of the timeline
    timelineRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handleCloseNewEntry = () => {
    setNewEntryType('');
    props.setIsEditing(false);
  };

  const handleMobileFileUpload = (uploadedFiles) => {
    // set up the uploadedFiles on the window object if the user has uploaded
    // files directly on the timeline using a mobile device
    if (uploadedFiles) {
      window.uploadedFiles = uploadedFiles;
    }
    handleOpenNewEntry('file');
  };

  // this filtering block is for filtering by highlighted entries
  let tempEntries = [...props.entries];
  tempEntries = tempEntries.filter((entry) => {
    if (entry.type === 'self_assessment_progress') {
      return entry.highlighted;
    } else {
      const story = props.stories.find((story) => story.id === entry.story_id);
      const schoolYear = story ? story.school_year : entry.school_year;
      return schoolYear === Utils.getCurrentSchoolYear() && entry.highlighted;
    }
  });
  // filter by goals if necessary
  if (props.goalFilter) {
    tempEntries = tempEntries.filter(
      (entry) =>
        (entry.goalIds && entry.goalIds.indexOf(props.goalFilter.id) >= 0) ||
        (entry.type === 'self_assessment_progress' &&
          entry.outcome_id === props.goalFilter.sub_focus_area_id)
    );
  }
  const highlightedCount = tempEntries.length;

  // hide the Highlights filter if the page user is a district coach teacher
  let highlightFilterNode = highlightedCount ? null : (
    <div className="workspace__filter-name workspace__filter-name--inactive">
      <div className="tc-v5__link workspace__filter-icon workspace__action-icon tc-v5-star-empty" />
      <div className="workspace__filter-text">No Highlights</div>
    </div>
  );

  // the "Highlights" filter is only interactive if there are highlighted entries
  if (highlightedCount > 0) {
    // update the timeline if we are actively filtering by highlighted entries
    let filterClass = '';
    let starFilterClass = 'tc-v5-star-empty';
    let filterCopy = 'View Highlights Only';
    if (props.filterByHighlight) {
      filterClass = 'active';
      starFilterClass = 'tc-v5-star-solid filled';
      filterCopy = `Viewing ${highlightedCount} Highlight${
        highlightedCount > 1 ? 's' : ''
      }`;
    }

    highlightFilterNode = (
      <div
        className={`workspace__filter-name ${filterClass}`}
        onClick={props.toggleHighlightFilter}
      >
        <div
          className={`workspace__filter-icon tc-v5__link workspace__action-icon ${starFilterClass}`}
        />
        <div className="workspace__filter-text">{filterCopy}</div>
      </div>
    );
  }

  let addEntryNode = null;
  if (newEntryType) {
    const today = moment().format('MMM D, YYYY');
    let newEntryFormNode;
    switch (newEntryType) {
      case 'link':
        newEntryFormNode = artifactBuilder(
          props,
          'link',
          newEntryType,
          handleCloseNewEntry
        );
        break;
      case 'file':
        newEntryFormNode = artifactBuilder(
          props,
          'file',
          newEntryType,
          handleCloseNewEntry
        );
        break;
      case 'text':
        newEntryFormNode = textEntryEditForm(
          props,
          `Note added on ${today}`,
          '',
          null,
          [],
          handleCloseNewEntry
        );
        break;
      case 'custom_strategy':
        newEntryFormNode = customStrategyEntryEditForm(
          props,
          '',
          '',
          null,
          [],
          handleCloseNewEntry
        );
        break;
    }
    addEntryNode = <div className="workspace__entry">{newEntryFormNode}</div>;
  }

  const entriesNode = (
    <WorkspaceEntries
      {...props}
      handleOpenNewEntry={handleOpenNewEntry}
      newEntryType={newEntryType}
    />
  );

  let goalFilterNode = null;

  if (ArrayHelpers.hasElements(props.goals) || props.newGoalFormVisible) {
    // by default, we only show up to 3 goals
    const goals = props.allGoalsVisible ? props.goals : props.goals.slice(0, 3);

    const goal = goals.find(
      (goal) => props.goalFilter && goal.id === props.goalFilter.id
    );

    if (goal) {
      goalFilterNode = (
        <div
          title={goal.title}
          className="v5__goal-tag workspace__filter-tag"
          onClick={() => props.changeGoalFilter(null)}
        >
          {goal.title}
          <div className="tc-x workspace__filter-tag-x"></div>
        </div>
      );
    }
  }

  // If the user can Edit then show them add entry buttons
  const addEntriesNode = props.canEdit && !props.isEditing && (
    <AddEntryWidget
      userId={props.pageUser.id}
      setNewEntryType={handleOpenNewEntry}
    />
  );
  const mobileMenuClass = isMobileAddEntryMenuOpen
    ? 'tc-x open'
    : 'tc-add-artifact';
  const mobileAddEntryOptionNodes = isMobileAddEntryMenuOpen ? (
    <React.Fragment>
      <NavigationLink
        url={`/lab/workspace/${props.pageUser.id}/select-resource`}
        className="v5__link workspace__mobile-add-entry-menu-btn workspace__mobile-add-entry-menu-btn--option first"
      >
        <div className="tc-v4-strategy icon"></div>
        <label className="workspace__mobile-add-entry-option-text">
          Resource
        </label>
      </NavigationLink>
      <div
        id="workspace__mobile-add-file-menu-btn"
        className="tc-v4-page workspace__mobile-add-entry-menu-btn workspace__mobile-add-entry-menu-btn--option second"
      >
        <MobileArtifactUpload submitFiles={handleMobileFileUpload} />
        <label
          htmlFor="workspace__mobile-add-file-menu-btn"
          className="workspace__mobile-add-entry-option-text"
        >
          File
        </label>
      </div>
      <div
        id="workspace__mobile-add-link-menu-btn"
        onClick={() => handleOpenNewEntry('link')}
        className="tc-v4-link workspace__mobile-add-entry-menu-btn workspace__mobile-add-entry-menu-btn--option third"
      >
        <label
          htmlFor="workspace__mobile-add-link-menu-btn"
          className="workspace__mobile-add-entry-option-text"
        >
          Link
        </label>
      </div>
      <div
        id="workspace__mobile-add-text-menu-btn"
        onClick={() => handleOpenNewEntry('text')}
        className="tc-v4-reflection workspace__mobile-add-entry-menu-btn workspace__mobile-add-entry-menu-btn--option fourth"
      >
        <label
          htmlFor="workspace__mobile-add-text-menu-btn"
          className="workspace__mobile-add-entry-option-text"
        >
          Text
        </label>
      </div>
    </React.Fragment>
  ) : null;

  const timelineClass = props.activeParticipant ? ' ' : ' left ';

  return (
    <div className="row row--responsive">
      {props.activeParticipant ? (
        <React.Fragment>
          {props.canEdit && !props.isEditing && (
            <WindowScrollElementFixer
              positionToClass="workspace__timeline"
              positionThisClass="workspace__add-entry-menu"
              positionThisContainerClass="workspace__add-entry-menu-container"
              positionToElemTopMargin={30}
              updateFlag={JSON.stringify(props)}
            />
          )}

          <div className="cute-12-desktop">
            <div className="workspace__section-separator"></div>
          </div>

          <div className="cute-2-desktop workspace__add-entry-menu-container show-desktop hide-phone">
            {addEntriesNode}
          </div>

          {isMobileAddEntryMenuOpen && (
            <div
              data-testid="workspace_mobile_add_entry_overlay"
              className="workspace__mobile-add-entry-menu-page-overlay"
              onClick={() => setIsMobileAddEntryMenuOpen(false)}
            />
          )}
          {props.canEdit && !props.isEditing && (
            <div className="workspace__mobile-add-entry-menu hide-desktop show-phone">
              <div
                className={`${mobileMenuClass} workspace__mobile-add-entry-menu-btn`}
                data-testid="workspace_mobile_add_entry"
                onClick={() =>
                  setIsMobileAddEntryMenuOpen(!isMobileAddEntryMenuOpen)
                }
              ></div>
              {mobileAddEntryOptionNodes}
            </div>
          )}
        </React.Fragment>
      ) : null}

      <div
        id="workspace-timeline"
        ref={timelineRef}
        className={`cute-10-desktop${timelineClass}cute-12-phone workspace__timeline`}
      >
        {(!props.activeParticipant ||
          !props.activeParticipant.coaching_engagement_id) && (
          <h1 className="workspace__heading-1 v5__heading-3">My Work</h1>
        )}

        <span className="workspace__filters">
          {highlightFilterNode}
          {goalFilterNode}
        </span>
        {addEntryNode}
        {entriesNode}
      </div>
    </div>
  );
};

WorkspaceTimeline.propTypes = {
  activeParticipant: PropTypes.object,
};

export default WorkspaceTimeline;
