// @ts-nocheck FIXME
import StorageHelper from '@legacy-components/components/StorageHelper';
import React, { useEffect } from 'react';
import Utils from '../components/StaticUtils';
/*
    This block initialized the context object.
    It isnt necessary to spell out everything in the context,
    but it does help describe what is going on.
    You could also provide an empty Object
*/
const HeaderContext = React.createContext({
  user: {},
  // methods
  freezeWindowAsNecessary: () => undefined,
  unfreezeWindowAsNecessary: () => undefined,
});

/*
    Context is just a react component, it manages state as any react component would.
    Context should be used to manage state that is global to the app scope
*/
const HeaderProvider = (props) => {
  const [alert, setAlert] = React.useState({});
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [user, setUser] = React.useState(props.user);
  const [responsive, setResponsive] = React.useState(false);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const freezeWindowAsNecessary = () => {
    if (window.matchMedia('(max-width: 48em)').matches) {
      // store the position, in case we need to scroll to it
      setScrollPosition(
        document.documentElement.scrollTop || document.body.scrollTop
      );
      // disable page scrolling if mobile navigation is opened
      Utils.addClass(document.body, 'no-scroll');
    }
  };

  const unfreezeWindowAsNecessary = () => {
    if (window.matchMedia('(max-width: 48em)').matches) {
      Utils.removeClass(document.body, 'no-scroll');
      window.scrollTo(0, scrollPosition);
    }
  };

  // preserve widget state across sessions and pages
  const getCurrentHeaderParticipant = () => {
    // no header participant is set clear it out of web storage
    if (!props.header_participant && !props.keepHeaderParticipant) {
      StorageHelper.removeItem('headerParticipantStorage');
      return null;
    }
    // ws = widget state
    const wsHeaderParticipant = StorageHelper.getItem(
      'headerParticipantStorage'
    );

    // np participant set in WS set it from the backend
    if (!wsHeaderParticipant) {
      StorageHelper.setItem(
        'headerParticipantStorage',
        props.header_participant
      );
      return props.header_participant;
    }
    // search page condition just use what we have stored
    if (!props.header_participant) {
      return wsHeaderParticipant;
    }
    if (
      !wsHeaderParticipant ||
      (wsHeaderParticipant &&
        wsHeaderParticipant.id !== props.header_participant.id)
    ) {
      StorageHelper.setItem(
        'headerParticipantStorage',
        props.header_participant
      );
      return props.header_participant;
    }
    if (props.header_participant.currentStory) {
      props.header_participant.defaultStory =
        props.header_participant.currentStory;
      StorageHelper.setItem(
        'headerParticipantStorage',
        props.header_participant
      );
      return props.header_participant;
    }
    if (
      wsHeaderParticipant &&
      wsHeaderParticipant.defaultStory &&
      wsHeaderParticipant.defaultStory.id !==
        props.header_participant.defaultStory.id
    ) {
      return wsHeaderParticipant;
    }

    return props.header_participant;
  };

  return (
    <HeaderContext.Provider
      value={{
        user,
        responsive,
        setResponsive,
        setUser,
        setAlert,
        alert: alert,
        headerParticipant: getCurrentHeaderParticipant(),
        freezeWindowAsNecessary,
        unfreezeWindowAsNecessary,
      }}
    >
      {props.children}
    </HeaderContext.Provider>
  );
};

const HeaderConsumer = HeaderContext.Consumer;

export { HeaderConsumer, HeaderProvider };
