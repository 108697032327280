// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Analytics from './../../../components/Analytics';
import { Router } from './../../../components/Router';
import SingleSelect from './../Common/SingleSelect/SingleSelect';
import BackButton from './modules/BackButton';

const SchoolStep = (props) => {
  let schoolName;
  let schoolZipcode;

  const handleNextStep = (e) => {
    e.preventDefault();

    Analytics.logEvent({
      category: 'school_info',
      action: 'confirm',
      label: 'from_second_page_user_welcome',
    });

    props.nextStep();
  };

  const handleChangeMode = (e, mode, analyticsAction) => {
    e.preventDefault();

    Analytics.logEvent({
      category: 'school_info',
      action: analyticsAction,
      label: 'from_second_page_user_welcome',
    });

    props.setSchoolStepMode(mode);
  };

  const handleSearchBack = (e) => {
    e.preventDefault();
    // if a school go back a screen
    if (props.participant.institution) {
      handleChangeMode(e, 'display', 'back');
      return;
    }
    Analytics.logEvent({
      category: 'back',
      action: 'click',
      label: 'from_second_page_user_welcome',
    });
    // else navigate back a step
    Router.route(
      `/coaching/lab/participant/${props.participant.id}/registration/${
        props.step - 1
      }?from_step=${props.step}`
    );
  };

  const handleFocus = (e, field, analyticsAction) => {
    e.preventDefault();

    Analytics.logEvent({
      category: 'school_info',
      action: analyticsAction,
      label: 'from_second_page_user_welcome',
    });

    props.clearErrorsOnFocus(field);
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();

    Analytics.logEvent({
      category: 'next',
      action: 'click',
      label: 'from_second_page_user_welcome',
    });

    props.submit();
  };

  // the first submit button is only there for consistency
  // so throw an error to indicate further action is required
  const handleFalseSubmit = (e) => {
    e.preventDefault();
    props.initalStepNoAnswer();
  };

  const handleSubmitNew = (e) => {
    e.preventDefault();

    Analytics.logEvent({
      category: 'next',
      action: 'click',
      label: 'from_second_page_user_welcome',
    });

    props.submit({
      schoolName: schoolName.value,
      schoolZipcode: schoolZipcode.value,
    });
  };

  const handleNoSchool = (e) => {
    e.preventDefault();

    Analytics.logEvent({
      category: 'school_info',
      action: 'select_no_school',
      label: 'from_second_page_user_welcome',
    });

    props.submit({
      institutionId: null,
      noSchool: true,
    });
  };

  // set up errors for school name
  let schoolNameErrorClass = '';
  let schoolNameErrorNode = null;
  if ('schoolName' in props.ui.errors) {
    schoolNameErrorClass = 'error';
    schoolNameErrorNode = (
      <div className="v5__error">{props.ui.errors.schoolName}</div>
    );
  }

  // set up errors for initial confirmation
  let confirmInitalErrorClass = '';
  let confirmInitalErrorNode = null;
  if ('initalStep' in props.ui.errors) {
    confirmInitalErrorClass = 'error';
    confirmInitalErrorNode = (
      <div className="v5__error">{props.ui.errors.initalStep}</div>
    );
  }

  // set up errors for school zipcode
  let schoolZipcodeErrorClass = '';
  let schoolZipcodeErrorNode = null;
  if ('schoolZipcode' in props.ui.errors) {
    schoolZipcodeErrorClass = 'error';
    schoolZipcodeErrorNode = (
      <div className="v5__error">{props.ui.errors.schoolZipcode}</div>
    );
  }

  // disable buttons when form submission is loading
  let disabledClass = '';
  let spinnerNode = null;
  if (props.ui.loading) {
    disabledClass = 'disabled';
    spinnerNode = <span className="littlespinner"></span>;
  }

  // set up the main form based on which mode we are in
  // TODO: should zip code be "number" or "text"?
  let formNode = null;
  // set up the two links under the school select
  let newSchoolLink = (
    <span
      className="tc__link"
      onClick={(e) => handleChangeMode(e, 'new', 'cannot_find_school')}
    >
      I can&apos;t find my school
    </span>
  );
  let noSchoolLink = (
    <span className="tc__link" onClick={handleNoSchool}>
      I&apos;m not associated with a specific school
    </span>
  );
  switch (props.ui.schoolStep.mode) {
    case 'display':
      formNode = (
        <React.Fragment>
          <div className="registration__form-item">
            <span className="registration__input-label registration__input-label--inline">
              School Name:
            </span>
            <span className="registration__form-value tc__headline-2">
              {props.participant.institution.name}
            </span>
          </div>

          <div className="registration__form-item">
            <span className="registration__input-label registration__input-label--inline">
              Zip Code:
            </span>
            <span className="registration__form-value tc__headline-2">
              {props.participant.institution.mailing_zipcode}
            </span>
          </div>

          <div className="registration__form-item registration__input-label registration__input-label--inline">
            Is this information correct?
          </div>

          <div className="registration__confirm-school-options">
            <button
              onClick={handleNextStep}
              className={`registration__checkbox-capsule registration__checkbox-capsule--no-icon ${disabledClass} ${confirmInitalErrorClass}`}
            >
              Yes
            </button>
            <button
              onClick={(e) => handleChangeMode(e, 'search', 'reject')}
              className={`registration__checkbox-capsule registration__checkbox-capsule--no-icon ${disabledClass} ${confirmInitalErrorClass}`}
            >
              No
            </button>
            {confirmInitalErrorNode}
          </div>
          <div className="registration__submit-btns">
            <BackButton
              caption={'Back to Your Experience'}
              participant={props.participant}
              step={props.step}
              analyticsLabel={'from_second_page_user_welcome'}
            />
            <button
              onClick={handleFalseSubmit}
              className={`tc__btn tc__btn--flat-blue ${disabledClass}`}
            >
              {spinnerNode}
              Next: Grades
            </button>
          </div>
        </React.Fragment>
      );
      break;
    case 'search':
      // if a school is selected, then disable the two links
      if (props.ui.schoolStep.schoolZipcode.selectedOption) {
        newSchoolLink = (
          <span className="tc__link--grey">I can&apos;t find my school</span>
        );
        noSchoolLink = (
          <span className="tc__link--grey">
            I&apos;m not associated with a specific school
          </span>
        );
      }

      formNode = (
        <React.Fragment>
          <div className={`registration__form-item ${schoolZipcodeErrorClass}`}>
            <div className="registration__input-label">
              Your School
              <div className="italics registration__input-label--sub-label">
                Search by entering your school or district’s zip code.
              </div>
            </div>

            <SingleSelect
              {...props.ui.schoolStep.schoolZipcode}
              field="schoolZipcode"
              minCharCountForResults={5}
              updateSingleSelect={props.updateSelect}
              getVisibleOptions={props.getSchoolVisibleOptions}
              analyticsCategory="school_info"
              analyticsLabel="from_second_page_user_welcome"
              onChangeDelay="700"
              restrictTo={'^[0-9]*$'}
              focusAnalytics={{
                category: 'school_info',
                action: 'enter_zip',
                label: 'from_second_page_user_welcome',
              }}
            />
            {schoolZipcodeErrorNode}
          </div>

          <div className="registration__form-item">{newSchoolLink}</div>

          <div className="registration__form-item">{noSchoolLink}</div>

          <div className="registration__submit-btns">
            <button
              className="tc__btn tc__btn--flat-grey tc__btn--flat-grey-v3"
              onClick={handleSearchBack}
            >
              {props.participant.institution
                ? 'Back'
                : 'Back to Your Experience'}
            </button>
            <button
              onClick={handleSubmitSearch}
              className={`tc__btn tc__btn--flat-blue ${disabledClass}`}
            >
              {spinnerNode}
              Next: Grades
            </button>
          </div>
        </React.Fragment>
      );
      break;
    case 'new':
      formNode = (
        <React.Fragment>
          <div className="registration__form-item">
            <div className="registration__input-label">Your School</div>
            <input
              type="text"
              ref={(ref) => (schoolName = ref)}
              onFocus={(e) => handleFocus(e, 'schoolName', 'enter_school')}
              className={`registration__input v5__input ${schoolNameErrorClass}`}
            />
            {schoolNameErrorNode}
          </div>

          <div className="registration__form-item">
            <div className="registration__input-label">Zip Code</div>
            <input
              type="text"
              ref={(ref) => (schoolZipcode = ref)}
              onFocus={(e) => handleFocus(e, 'schoolZipcode', 'enter_zip')}
              className={`registration__input v5__input ${schoolZipcodeErrorClass}`}
            />
            {schoolZipcodeErrorNode}
          </div>

          <div className="registration__submit-btns">
            <button
              className="tc__btn tc__btn--flat-grey tc__btn--flat-grey-v3"
              onClick={(e) => handleChangeMode(e, 'search', 'back')}
            >
              Back
            </button>
            <button
              onClick={handleSubmitNew}
              className={`tc__btn tc__btn--flat-blue ${disabledClass}`}
            >
              {spinnerNode}
              Next
            </button>
          </div>
        </React.Fragment>
      );
      break;
  }

  return (
    <div className="row row--1200 registration__primary">
      <form className="cute-6-desktop cute-12-phone center-desktop">
        <div className="registration__headline tc__headline-2">
          What is your school or district?
        </div>
        {formNode}
      </form>
    </div>
  );
};

SchoolStep.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  setSchoolStepMode: PropTypes.func.isRequired,
  clearErrorsOnFocus: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default SchoolStep;
