// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import OutcomeCard from './OutcomeCard';

const Outcomes = (props) => {
  const lab = useLegacyLabContext();

  const canViewOutcomes = Utils.showPartnerLeaderDetails.call(
    lab,
    lab.pageData.pageUser
  );

  const renderOutcomesNode = () => {
    if (props.outcomes.length && canViewOutcomes) {
      return props.outcomes.map((outcome) => {
        return <OutcomeCard outcome={outcome} key={`outcome-${outcome.id}`} />;
      });
    }

    const emptyOutcomesMessage = canViewOutcomes ? (
      <>
        This professional development journey is still a work in progress.
        <div>Check back later for updates.</div>
      </>
    ) : (
      <>
        Activities on this page are private.
        <div>
          If you think you are seeing this page in error, please reach out to
          your CSM for more assistance.
        </div>
      </>
    );

    return (
      <div className="row row--1200 highlights__empty-tab-row">
        <div className="cute-4-desktop cute-1-desktop-offset cute-12-phone cute-0-phone-offset">
          <img
            src="/legacy-images/v5/workspace-timeline.png"
            className="highlights__outcomes-img"
          />
        </div>
        <div className="cute-7-desktop cute-12-phone">
          <div className="highlights__outcomes-text highlights__empty-copy">
            {emptyOutcomesMessage}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="row row--1200">
      <div className="highlights__count-container">
        <span className="tc-v5-star-solid highlights__outcomes-star-icon"></span>
        <span className="highlights__count-number">{props.highlightCount}</span>
        <span className="highlights__count-text">Highlights</span>
      </div>
      {renderOutcomesNode()}
    </div>
  );
};

Outcomes.propTypes = {
  entries: PropTypes.array,
  goals: PropTypes.array,
};

export default Outcomes;
