// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';
import ShareModal from './ShareModal';

const PortfolioManagementSubheader = (props) => {
  // set up any opened modal
  let modalNode = null;
  if (props.shareModal.opened) {
    modalNode = (
      <ShareModal {...props.shareModal} setUrlCopied={props.setUrlCopied} />
    );
  }

  return (
    <div className="row">
      <div className="cute-12-desktop">
        <div className="p-portfolio-mgmt__subheader">
          <div className="p-portfolio-mgmt__subheader-home-link">
            <NavigationLink
              className="tc__link"
              url={
                '/coaching/lab/participant/' +
                props.participantId +
                '/portfolio?from=ppd3_participant_portfolio_manage_UI'
              }
            >
              {props.participantName}&apos;s Portfolio
            </NavigationLink>
          </div>

          <a
            href={
              '/coaching/lab/participant/portfolio/' +
              props.portfolioUuid +
              '?from=ppd3_participant_portfolio_manage_UI'
            }
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link p-portfolio-mgmt__subheader-preview-link"
          >
            <span className="label">Preview</span>{' '}
            <span className="tc-outlink p-portfolio-mgmt__subheader-preview-icon"></span>
          </a>
          <button
            onClick={() =>
              props.shareModal.open({ portfolioUuid: props.portfolioUuid })
            }
            className="tc__btn tc__btn--flat-blue p-portfolio-mgmt__subheader-share-btn"
          >
            Share
          </button>
        </div>
      </div>
      {modalNode}
    </div>
  );
};

PortfolioManagementSubheader.propTypes = {
  participantId: PropTypes.number.isRequired,
  participantName: PropTypes.string.isRequired,
  portfolioUuid: PropTypes.string.isRequired,
  shareModal: PropTypes.object,
};

export default PortfolioManagementSubheader;
