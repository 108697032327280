// @ts-nocheck FIXME
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import NavigationLink from '@legacy-components/react/NavigationLink';
import ArtifactOverlay from '@legacy-components/react/V3/Common/ArtifactOverlay/ArtifactOverlay';
import ConfirmDeleteArtifactModal from '@legacy-components/react/V3/Common/ConfirmModal';
import MultiSelect from '@legacy-components/react/V3/Common/MultiSelect/MultiSelect';
import CKEditor from '@legacy-components/react/V5/Common/CKEditor';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import { default as Clipboard } from 'clipboard';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDeleteModal from './../Common/ConfirmDeleteModal';
import UpdateRow from './UpdateRow';
import UploadArtifactModal from './UploadArtifactModal';

const EditCohort = (props) => {
  const [clipboard, setClipboard] = React.useState(''); // Do not use outside of useEffect

  React.useEffect(() => {
    setClipboard(
      new Clipboard('.admin-v5__cohort-link', {
        text: function (trigger) {
          return trigger.getAttribute('copytext') || '';
        },
      })
    );

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, []);

  const isActive = !props.cohort.archived;
  const isCustom =
    props.ui.editCohort.product.selectedOption &&
    props.ui.editCohort.product.selectedOption.is_custom;

  let modalNode = null;

  if (props.ui.uploadArtifactModal.opened) {
    modalNode = (
      <UploadArtifactModal
        uploadType={props.ui.uploadArtifactModal.data.uploadType}
        entryType="report"
        entry={props.ui.uploadArtifactModal.data}
        artifactUserId={props.user.id}
        cohortId={props.cohort.id}
        delete={props.deleteCohortReport}
        editArtifactCallback={props.editArtifactCallback}
        close={props.ui.uploadArtifactModal.close}
      />
    );
  } else if (props.ui.confirmDeleteArtifactModal.opened) {
    const modal = props.ui.confirmDeleteArtifactModal;
    const title = modal.data.title || 'Progress Report';

    modalNode = (
      <ConfirmDeleteArtifactModal
        object={modal.data}
        closeConfirmModal={modal.close}
        bodyText={'Are you sure you want to delete ' + title + '?'}
        submitBtnText={'Delete'}
        handleSubmit={props.deleteCohortReport}
        isLoading={modal.loading}
      />
    );
  } else if (props.ui.viewArtifactModal.opened) {
    modalNode = <ArtifactOverlay {...props.ui.viewArtifactModal} />;
  } else if (props.ui.confirmDeleteModal.object) {
    modalNode = (
      <ConfirmDeleteModal
        {...props.ui.confirmDeleteModal}
        closeConfirmDeleteModal={props.closeConfirmDeleteModal}
        onDelete={props.deleteCohort}
      />
    );
  }

  // some logic around what to show for the registration link area
  let registrationLinkNode = null;
  // if has display name and not archived, then show link
  if (props.cohort.display_name && isActive) {
    registrationLinkNode = (
      <React.Fragment>
        <b>Participant</b>
        <br />{' '}
        {/* @todo wrap this in a div with a style class and get rid of the line breaks */}
        {props.cohort.participant_registration_link}
        <span
          copytext={props.cohort.participant_registration_link}
          className="v5__link admin-v5__cohort-link"
          onClick={props.handleCopy}
        >
          &nbsp;&nbsp;Copy Link
        </span>
        <br />
        <br />
      </React.Fragment>
    );
  } else if (!isActive) {
    registrationLinkNode = 'Cohort is archived.';
  } else {
    registrationLinkNode = 'Add Display Name';
  }

  let outcomesSelectNode;
  if (props.cohort.outcomes && props.cohort.outcomes.length > 0) {
    outcomesSelectNode = (
      <div className="v5__admin--form-item clearfix">
        <label className="admin-v2__user-page-label">Outcomes</label>
        <MultiSelect
          {...props.ui.editCohort.outcomes}
          field="outcomes"
          feature="editCohort"
          minCharCountForResults={0}
          updateMultiSelect={props.updateSelect}
          getVisibleOptions={props.getVisibleOptions}
          analyticsLabel={''}
          readOnly={true}
        />
      </div>
    );
  }

  // set up any alert banner
  let alertNode = null;
  if (Object.keys(props.ui.alert).length) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // set up errors for name
  let nameErrorNode = null;
  if ('name' in props.ui.editCohort.errors) {
    nameErrorNode = (
      <div className="tc__error">{props.ui.editCohort.errors.name}</div>
    );
  }

  // set up errors for displayName
  let displayNameErrorNode = null;
  if ('displayName' in props.ui.editCohort.errors) {
    displayNameErrorNode = (
      <div className="tc__error">{props.ui.editCohort.errors.displayName}</div>
    );
  }

  // set up errors for displayDescription
  let displayDescriptionErrorClass = '';
  let displayDescriptionErrorNode = null;
  if ('displayDescription' in props.ui.editCohort.errors) {
    displayDescriptionErrorNode = (
      <div className="tc__error">
        {props.ui.editCohort.errors.displayDescription}
      </div>
    );
    displayDescriptionErrorClass = ' error';
  }

  // set up errors for product
  let productErrorClass = '';
  let productErrorNode = null;
  if ('product' in props.ui.editCohort.errors) {
    productErrorNode = (
      <div className="tc__error">{props.ui.editCohort.errors.product}</div>
    );
    productErrorClass = ' error';
  }

  // set up errors for partner
  let partnerErrorNode = null;
  if ('partner' in props.ui.editCohort.errors) {
    partnerErrorNode = (
      <div className="tc__error">{props.ui.editCohort.errors.partner}</div>
    );
  }

  // set up errors for dates
  let startErrorClass = '';
  let endErrorClass = '';
  let startErrorNode = null;
  let endErrorNode = null;
  if ('start' in props.ui.editCohort.errors) {
    startErrorNode = (
      <div className="tc__error date">{props.ui.editCohort.errors.start}</div>
    );
    startErrorClass = ' error';
  }
  if ('end' in props.ui.editCohort.errors) {
    endErrorNode = (
      <div className="tc__error date">{props.ui.editCohort.errors.end}</div>
    );
    endErrorClass = ' error';
  }

  // set up errors for cohort size
  let capacityErrorNode = null;
  if ('capacity' in props.ui.editCohort.errors) {
    capacityErrorNode = (
      <div className="tc__error">{props.ui.editCohort.errors.capacity}</div>
    );
  }
  const startDateMoment = moment(props.ui.editCohort.startDate);
  const terminationDateMoment = moment(props.ui.editCohort.terminationDate);

  const customCoachMatchRequestNode = isCustom && (
    <div className="v5__admin--form-item clearfix">
      <label className="admin-v2__user-page-label">
        Custom Coach Match Request
        <div className="wysiwyg-error-container">
          <CKEditor
            id="cohort-match-request"
            increment={0}
            innerText={props.cohort.custom_coaching_request}
            className="cohort__match-request--wysiwyg"
            configs="/legacy-libs/configs/bl-cke-config-cohort.js"
          />
        </div>
      </label>
    </div>
  );

  // We need to track editability on this page
  let submitButton;
  const nameNode = <div className="v5__heading-5">{props.cohort.name}</div>;
  const displayNameNode = (
    <div className="v5__heading-5">{props.cohort.display_name}</div>
  );
  const partnerNode = (
    <div className="v5__heading-5">{props.cohort.partner.label}</div>
  );
  const descriptionOfServiceNode = (
    <div
      className="v5__body-copy"
      dangerouslySetInnerHTML={{ __html: props.cohort.display_description }}
    />
  );
  const blProductNode = (
    <div className="v5__heading-5">{props.cohort.product.label}</div>
  );
  const startDateNode = (
    <div className="v5__heading-5 edit-cohort__termination-date">
      {startDateMoment.format('MM/DD/YYYY')}
    </div>
  );
  const endDateNode = (
    <div className="v5__heading-5 edit-cohort__termination-date">
      {moment(props.ui.editCohort.endDate).format('MM/DD/YYYY')}
    </div>
  );
  const capacityNode = (
    <div className="v5__heading-5">{props.cohort.capacity}</div>
  );
  const visionGoalsNode = (
    <div className="v5__heading-5">{props.cohort.vision}</div>
  );
  const cohortOverviewNode = (
    <div
      className="v5__body-copy"
      dangerouslySetInnerHTML={{ __html: props.cohort.overview }}
    />
  );
  const domainsNode = (
    <div className="v5__heading-5">
      {props.cohort.priorityDomains.map((domain) => domain.label).join(', ')}
    </div>
  );
  const matchRequirementsNode = (
    <div className="v5__heading-5">{props.cohort.matchRequirements}</div>
  );
  let resourcesNode;

  const pageTitle = 'CohortInfo';

  return (
    <div>
      <Header page={pageTitle} {...props} />
      {modalNode}
      {alertNode}
      <div className="row tc__body-copy">
        <div className="cute-12-desktop ppd-v3-curation__breadcrumbs">
          <NavigationLink url="/bl/cohorts" className="tc__link">
            Cohorts
          </NavigationLink>
          <span className="tc-arrow ppd-v3-curation__breadcrumb-separator"></span>
          Cohort Info
        </div>
      </div>

      <div className="row">
        <div className="cute-12-desktop">
          <h3 className="cohorts__headline tc__headline-2">
            {props.cohort.name}
          </h3>
        </div>

        <div className="cute-12-desktop">
          <div className="v5__admin--form-item clearfix">
            <label>
              <div className="edit-event__metadata-label">Name</div>
              {nameNode}
            </label>
            {nameErrorNode}
          </div>

          <div className="v5__admin--form-item clearfix">
            <label
              htmlFor="display-name"
              className="edit-event__metadata-label"
            >
              Display Name
            </label>
            <div className="v5__admin--section-description">
              This name is displayed on the coaching registration landing page
              and Partner Leader dashboard.
            </div>
            {displayNameNode}
            {displayNameErrorNode}
          </div>
          <div className="v5__admin--form-item clearfix">
            <label>
              <div className="edit-event__metadata-label">Partner</div>
              {partnerNode}
            </label>
            {partnerErrorNode}
          </div>
        </div>
      </div>

      <div className="admin-v2__user-tab-body">
        <div className="row">
          <div className="cute-12-desktop">
            <div className="v5__admin--section-heading">
              Coaching Registration
            </div>
            <div className="v5__admin--section-description">
              The information below is visible to participants and Partner
              Leaders.
            </div>

            <div className="v5__admin--form-item clearfix">
              <label className="v5__body-copy">
                Description of Service (for the coaching registration landing
                page)
                <div
                  className={`wysiwyg-error-container${displayDescriptionErrorClass}`}
                >
                  {descriptionOfServiceNode}
                  {displayDescriptionErrorNode}
                </div>
              </label>
            </div>
            <div
              className={`v5__admin--form-item clearfix${productErrorClass}`}
            >
              <label className="v5__body-copy">
                BL Product
                {blProductNode}
              </label>
              {productErrorNode}
            </div>
            {outcomesSelectNode}

            <div className="v5__admin--form-item inline clearfix">
              <div className={'flatpickr-container'}>
                <label className="v5__body-copy">Termination Date</label>
                <div className="v5__heading-5 edit-cohort__termination-date">
                  {terminationDateMoment.format('MM/DD/YYYY')}
                </div>
              </div>
            </div>

            <div className="v5__admin--form-item inline clearfix">
              <div
                className={`flatpickr-container cohort-dash__date-picker${startErrorClass}`}
              >
                <label className="v5__body-copy">Start Date </label>
                <div className="cohort-dash__label-subscript extra-line">
                  This is the required start date for a cohort
                </div>
                {startDateNode}
                {startErrorNode}
              </div>

              <div
                className={`flatpickr-container cohort-dash__date-picker${endErrorClass}`}
              >
                <label className="v5__body-copy">End Date</label>
                <div className="cohort-dash__label-subscript">
                  This is the required display end date for a cohort
                </div>
                {endDateNode}
                {endErrorNode}
              </div>
            </div>

            <div className="v5__admin--form-item clearfix">
              <label className="v5__body-copy">
                Cohort Size
                {capacityNode}
              </label>
              {capacityErrorNode}
            </div>

            <div className="v5__admin--form-item clearfix">
              <label className="v5__body-copy">Registration Link</label>
              <div style={{ marginTop: '1%' }}>{registrationLinkNode}</div>
            </div>

            <div className="v5__admin--section-separator"></div>
            <div className="v5__admin--section-heading">
              Partner Leader Resources
            </div>
            <div className="v5__admin--section-description">
              The information below is visible to Partner Leaders on the Cohort
              Overview page on the Partner Leader Dashboard.
            </div>

            <div className="v5__admin--form-item clearfix">
              <label className="v5__body-copy">
                Vision & Goals
                <div className="wysiwyg-error-container">{visionGoalsNode}</div>
              </label>
            </div>
            {resourcesNode}
            <div>
              {props.cohort.reports &&
                props.cohort.reports.map((report, i) => {
                  return (
                    <UpdateRow
                      key={'report-' + report.id}
                      update={report}
                      index={i}
                      viewArtifact={props.ui.viewArtifactModal.open}
                      viewText={props.ui.viewTextModal.open}
                      editArtifact={props.ui.uploadArtifactModal.open}
                      editText={props.ui.textEntryModal.open}
                      openConfirmDelete={
                        props.ui.confirmDeleteArtifactModal.open
                      }
                    />
                  );
                })}
            </div>

            <div className="v5__admin--section-separator"></div>
            <div className="v5__admin--section-heading">
              Coaching Requirements
            </div>
            <div className="v5__admin--section-description">
              The information below is visible to BL Coaches only.
            </div>

            <div className="v5__admin--form-item clearfix">
              <label className="v5__body-copy">
                Cohort Overview
                {cohortOverviewNode}
              </label>
            </div>

            <div className="v5__admin--form-item clearfix">
              <label className="v5__body-copy">
                Priority Learning Domains
                {domainsNode}
              </label>
            </div>

            <div className="v5__admin--form-item clearfix">
              <label className="v5__body-copy">
                Cohort-Level Match Requirements and Coaching Modifications
                {matchRequirementsNode}
              </label>
            </div>

            {customCoachMatchRequestNode}

            {submitButton}
          </div>
        </div>
      </div>
    </div>
  );
};

EditCohort.propTypes = {
  cohort: PropTypes.object.isRequired,
  editCohort: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  outcomes: PropTypes.array.isRequired,
  allFocusAreas: PropTypes.array.isRequired,
};

EditCohort.defaultProps = {
  outcomes: [],
  allFocusAreas: [],
};
export default EditCohort;
