// @ts-nocheck FIXME
import Q from 'q';
import React from 'react';
import AdminApi from './../../apis/AdminApi';
import Utils from './../../components/DynamicUtils';
import Header from './../Header';

class AdminTests extends React.Component {
  state = {
    currentlyRunningTestId: null,
    currentlyValidatingTestId: null,
    currentTests: [],
  };

  handleRunTests = (suiteId, e) => {
    e.preventDefault();

    // clear the loading animations
    this.setState({
      currentlyRunningTestId: null,
      currentlyValidatingTestId: null,
    });

    // retrieve the list of tests in the given suite from Ghost Inspector
    AdminApi.getTests({ suiteId: suiteId })
      .then(
        function (response) {
          if (response.tests.data) {
            // add any test data to the table
            const tests = response.tests.data.map(function (test) {
              return { _id: test._id, name: test.name };
            });
            this.setState({
              currentTests: tests,
            });

            // use this to track how long each api call takes
            let startTime = new Date().getTime();
            // run each test in the suite, along with corresponding db validation, sequentially
            response.tests.data
              .reduce(
                function (prev, test) {
                  return prev
                    .then(
                      function (response) {
                        // clear the loading animation
                        this.setState({
                          currentlyValidatingTestId: null,
                        });
                        if (response && response.validationResults) {
                          // update the test data with the validation results
                          const newTests = Utils.clone(this.state.currentTests);
                          newTests.forEach(function (test) {
                            if (test._id == response.validationResults.testId) {
                              test.validated =
                                response.validationResults.validated;
                              test.validationResults =
                                response.validationResults.results;
                              test.validationTime =
                                new Date().getTime() - startTime;
                            }
                          });
                          this.setState({
                            currentTests: newTests,
                          });
                        }
                        // only run the next test if this validation passed, or we have not run any tests yet
                        if (
                          !response ||
                          !response.validationResults ||
                          response.validationResults.validated != 'failing'
                        ) {
                          // set up the loading animation for the next api call
                          this.setState({
                            currentlyRunningTestId: test._id,
                          });
                          // run the next test
                          startTime = new Date().getTime();
                          return AdminApi.runTest({ testId: test._id });
                        } else {
                          throw 'errored';
                        }
                      }.bind(this)
                    )
                    .then(
                      function (response) {
                        // clear the loading animation
                        this.setState({
                          currentlyRunningTestId: null,
                        });
                        if (
                          response &&
                          response.testResults &&
                          response.testResults.data
                        ) {
                          // update the test data with the new results
                          const newTests = Utils.clone(this.state.currentTests);
                          newTests.forEach(function (test) {
                            if (
                              test._id == response.testResults.data.test._id
                            ) {
                              test.passing = response.testResults.data.passing;
                              test.runTime = new Date().getTime() - startTime;
                            }
                          });
                          this.setState({
                            currentTests: newTests,
                          });
                        }
                        // only run the validation for the test if it passed
                        if (
                          response &&
                          response.testResults &&
                          response.testResults.data &&
                          response.testResults.data.passing
                        ) {
                          // set up the loading animation for the next api call
                          this.setState({
                            currentlyValidatingTestId: test._id,
                          });
                          // validate the results of this test in our db
                          startTime = new Date().getTime();
                          return AdminApi.validateTest({ testId: test._id });
                        } else {
                          throw 'errored';
                        }
                      }.bind(this)
                    );
                }.bind(this),
                Q()
              )
              .finally(
                function (response) {
                  // clear the loading animations
                  this.setState({
                    currentlyRunningTestId: null,
                    currentlyValidatingTestId: null,
                  });
                  // clear the test data we added to the session
                  return AdminApi.clearSession();
                }.bind(this)
              )
              .done(
                function (response) {
                  if (response && response.validationResults) {
                    // update the test data with the validation results
                    const newTests = Utils.clone(this.state.currentTests);
                    newTests.forEach(function (test) {
                      if (test._id == response.validationResults.testId) {
                        test.validated = response.validationResults.validated;
                        test.validationResults =
                          response.validationResults.results;
                        test.validationTime = new Date().getTime() - startTime;
                      }
                    });
                    this.setState({
                      currentTests: newTests,
                    });
                  }
                  // TODO: handle errors here
                  // clear the loading animations
                  this.setState({
                    currentlyRunningTestId: null,
                    currentlyValidatingTestId: null,
                  });
                }.bind(this)
              );
          }
        }.bind(this)
      )
      .catch(
        function (response) {
          // TODO: handle errors here
          this.setState({
            currentlyRunningTestId: null,
            currentlyValidatingTestId: null,
          });
        }.bind(this)
      );
  };

  render() {
    // set up the list of test suites
    let suitesNode = null;
    if (this.props.suites) {
      suitesNode = (
        <div className="cute-12-desktop">
          {this.props.suites.map(
            function (suite) {
              return (
                <div
                  key={suite._id}
                  className="admin-v2__user-page-section-header"
                >
                  {suite.name}{' '}
                  <span
                    className="tc__btn tc__btn--grey tc__btn--small"
                    onClick={this.handleRunTests.bind(this, suite._id)}
                  >
                    Run
                  </span>
                </div>
              );
            }.bind(this)
          )}
        </div>
      );
    }

    // set up test table headers
    const headersNode = (
      <tr>
        <th className="admin-v2__user-table-header">Test ID</th>
        <th className="admin-v2__user-table-header">Test Name</th>
        <th className="admin-v2__user-table-header">Test Status</th>
        <th className="admin-v2__user-table-header">Test Run Time (ms)</th>
        <th className="admin-v2__user-table-header">
          Database Validation Status
        </th>
        <th className="admin-v2__user-table-header">
          Database Validation Time (ms)
        </th>
        <th className="admin-v2__user-table-header">
          Database Validation Results
        </th>
      </tr>
    );

    // by default, show an empty row
    const results = (
      <tr className="user-search-table__row">
        <td className="user-search-table__col"></td>
        <td className="user-search-table__col"></td>
        <td className="user-search-table__col"></td>
        <td className="user-search-table__col"></td>
        <td className="user-search-table__col"></td>
        <td className="user-search-table__col"></td>
        <td className="user-search-table__col"></td>
      </tr>
    );
    // show any test data that we have
    if (this.state.currentTests && this.state.currentTests.length > 0) {
      const results = this.state.currentTests.map(
        function (test) {
          // show whether or not the test passed
          let testResult = null;
          if (this.state.currentlyRunningTestId == test._id) {
            testResult = (
              <img
                src="https://media.giphy.com/media/b4R1YmTOwVjB6/200.gif"
                style={{ width: 100 }}
              />
            );
          } else if (test.passing) {
            testResult = <span className="passing">passing</span>;
          } else if (test.passing == false) {
            testResult = <span className="failing">failing</span>;
          }

          // show whether or not the db was validated after the test
          let validationResult = null;
          if (this.state.currentlyValidatingTestId == test._id) {
            validationResult = (
              <img
                src="https://media.giphy.com/media/b4R1YmTOwVjB6/200.gif"
                style={{ width: 100 }}
              />
            );
          } else if (test.validated == 'none') {
            validationResult = (
              <span className="no-validation">no validation</span>
            );
          } else if (test.validated == 'passing') {
            validationResult = <span className="passing">passing</span>;
          } else if (test.validated == 'failing') {
            validationResult = <span className="failing">failing</span>;
          }

          return (
            <tr className="user-search-table__row" key={test._id}>
              <td className="admin-tests__table-col">{test._id}</td>
              <td className="admin-tests__table-col">{test.name}</td>
              <td className="admin-tests__table-col">{testResult}</td>
              <td className="admin-tests__table-col">{test.runTime}</td>
              <td className="admin-tests__table-col">{validationResult}</td>
              <td className="admin-tests__table-col">{test.validationTime}</td>
              <td className="admin-tests__table-col">
                {test.validationResults
                  ? test.validationResults.map(function (result) {
                      if (result[0] == 'info') {
                        return (
                          <div key={result[1]}>
                            <span className="tc-check"></span> {result[1]}
                          </div>
                        );
                      } else {
                        return (
                          <div key={result[1]}>
                            <span className="tc-x"></span> {result[1]}
                          </div>
                        );
                      }
                    })
                  : null}
              </td>
            </tr>
          );
        }.bind(this)
      );
    }

    return (
      <div>
        <Header page="AdminDashboard" {...this.props} />

        <div className="row">
          <div className="cute-12-desktop admin-v2__headline tc__headline-1">
            Tests
          </div>
          <div className="cute-12-desktop tc__headline-5">
            Click the &quot;Run&quot; button next to a test suite to run the
            corresponding tests.
          </div>

          {suitesNode}

          <table className="admin-tests__table">
            <tbody>
              {headersNode}
              {results}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default AdminTests;
