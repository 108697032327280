import { createTheme, PaletteColor } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    blue2: string;
  }
  interface PaletteOptions {
    blue2: PaletteColor;
  }
  interface Palette {
    hover: Palette['primary'];
  }
  interface PaletteOptions {
    hover: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    blue2: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    blue2: true;
  }
}

const { palette } = createTheme();

export default createTheme({
  typography: {
    fontFamily: 'Circular Std',
    h1: {
      fontWeight: 'bold',
      fontSize: '30px',
      color: 'var(--color-grey-2)',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '24px',
      color: 'var(--color-grey-2)',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '18px',
      color: 'var(--color-grey-2)',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: 'var(--color-grey-2)',
    },
    body1: {
      fontWeight: 300,
      fontSize: '16px',
      color: 'var(--color-grey-2)',
    },
    body2: {
      fontWeight: 500,
      fontSize: '14px',
      color: 'var(--color-grey-3)',
    },
    caption: {
      fontWeight: 300,
      fontSize: '12px',
      color: 'var(--color-error)',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            zIndex: 'unset',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          font: 'normal normal bold 11px/14px "Circular Std"',
          backgroundColor: '#B3E5FC',
          color: '#212121',
        },
        arrow: {
          color: '#B3E5FC',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          font: 'normal normal bold 14px/24px "Circular Std"',
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
        containedSecondary: {
          background:
            'linear-gradient(135deg, var(--color-ocean) 0%, var(--color-vivid-blue) 100%)',

          '&:disabled': {
            background: 'unset',
            color: 'rgba(0, 0, 0, 0.26)',
            boxShadow: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
        outlinedSecondary: {
          color: 'var(--color-ocean)',
          position: 'relative',
          border: 'none',
          height: '38px',

          '&:hover': {
            border: 'none',
          },

          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: '2px',
            borderRadius: '18px',
            background:
              'linear-gradient(135deg, var(--color-ocean) 0%, var(--color-vivid-blue) 100%)',
            WebkitMask:
              'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
          },

          '&:disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
            border: '2px solid rgba(0, 0, 0, 0.12)',
            content: 'none',
          },

          '&:disabled::before': {
            background: 'unset',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          font: 'normal normal 500 14px/24px "Circular Std"',
          '&:hover': {
            backgroundColor: '#E0E6E8',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#E0E6E8',
          },
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: '#E0E6E8',
          },
          '&.Mui-selected': {
            backgroundColor: '#EEF2F7',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'var(--color-slate-1)',
        },
        root: {
          height: '40px',
          borderColor: 'var(--color-grey-1)',
        },
        input: {
          fontSize: '14px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& svg': {
            fontSize: '32px',
            fill: 'var(--color-vivid-blue)',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&:hover': {
            backgroundColor: 'var(--color-selected-blue) !important',
          },
          '&[data-focus="true"]': {
            backgroundColor: 'var(--color-selected-blue) !important',
          },
          '&[aria-selected="true"]': {
            backgroundColor: 'var(--color-grey-selected) !important',
          },
        },
      },
    },
  },
  palette: {
    blue2: palette.augmentColor({
      color: { main: '#1976d2' },
    }),
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#08bcc3',
    },
    success: {
      main: '#33b55f',
    },
    hover: {
      main: '#42A5F5',
    },
  },
});
