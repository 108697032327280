// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ArtifactForm from './../Common/ArtifactUpload/ArtifactForm';
import ArtifactLink from './../Common/ArtifactUpload/ArtifactLink';
import ArtifactProgress from './../Common/ArtifactUpload/ArtifactProgress';
import ArtifactUpload from './../Common/ArtifactUpload/ArtifactUpload';
import FreezeWindowScroll from './../Common/FreezeWindowScroll';

const VisionArtifactUploadModal = (props) => {
  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const clearError = (e) => {
    e.preventDefault();
    // TODO: Call the store to clear errors
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    // Call the store
    props.closeArtifactModal(false);
  };

  const handleCloseModalDeleteIfNew = (e) => {
    e.preventDefault();
    // Call the store
    props.closeArtifactModal(true);
  };
  // no upload type is set, the modal is not open
  if (!props.uploadType) {
    return null;
  }

  let artifactUploadNode;
  let modalTypeClass = '';
  let modalTitle = 'Add Artifact';

  switch (props.sectionMode) {
    case 'during':
      modalTypeClass = ' participant__artifact-modal--progress';
      artifactUploadNode = (
        <ArtifactProgress
          file={props.file}
          containerCssClass="outcome-story_artifact__upload-progress"
        />
      );
      break;
    case 'edit':
    case 'after': {
      modalTitle = props.sectionMode == 'edit' ? 'Edit Artifact' : modalTitle;
      modalTypeClass = ' participant__artifact-modal--edit';
      let modalClose = null;
      if (props.sectionMode == 'after') {
        modalClose = handleCloseModalDeleteIfNew;
      } else {
        modalClose = handleCloseModal;
      }

      artifactUploadNode = (
        <ArtifactForm
          file={props.file}
          selectActive={props.selectActive}
          editArtifact={props.editArtifact}
          artifactTypes={props.artifactTypes}
          showDropDownOptions={props.showDropDownOptions}
          handleSelectArtifactType={props.handleSelectArtifactType}
          selectedTypeId={props.selectedTypeId}
          loading={props.loading}
          isNew={props.sectionMode == 'after'}
          handleCloseModal={modalClose}
          confirmDelete={props.confirmDelete}
          toggleElement={props.toggleElement}
          deleteArtifact={props.deleteArtifact}
          showArtifactTypes={false}
          confirmMove={props.confirmMove}
          selectSingleSelectElement={props.selectSingleSelectElement}
          getSingleSelectElement={props.getSingleSelectElement}
          setFeatureFieldError={props.setFeatureFieldError}
          moveCallback={props.moveArtifact}
          containerName="artifactUpload"
        />
      );
      break;
    }

    default:
      modalTypeClass = ' participant__artifact-modal--artifact';
      artifactUploadNode = (
        <ArtifactUpload {...props} handleUploadCancel={handleCloseModal} />
      );
      if (props.uploadType == 'link') {
        modalTitle = 'Add Link Artifact';
        modalTypeClass = ' participant__artifact-modal--link';
        artifactUploadNode = (
          <ArtifactLink
            {...props.link}
            submitLink={props.submitLink}
            artifactTypes={props.artifactTypes}
            selectActive={props.selectActive}
            setArtifactLinkUploadError={props.setArtifactLinkUploadError}
            loading={props.loading}
            handleUploadCancel={handleCloseModal}
          />
        );
      }
  }

  return (
    <div onClick={handleStopPropagation}>
      <div
        className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
        onClick={handleCloseModal}
      ></div>
      <div
        className={
          'modal--fixed modal--grey-overlay ppd-v3-curation-modal participant__artifact-upload-modal outcome-story__modal' +
          modalTypeClass
        }
      >
        <div
          className="modal__close  hide-phone show-desktop"
          onClick={handleCloseModalDeleteIfNew}
        >
          <div className="tc-x"></div>
        </div>
        <div className="tc__mobile-header show-phone hide-desktop">
          <div
            className="tc__back cute-2-desktop left"
            onClick={handleCloseModalDeleteIfNew}
          >
            <div className="tc__back-arrow tc-arrow"></div>
            <div className="tc__back-caption">Back</div>
          </div>
          <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
            {modalTitle}
          </div>
        </div>
        <div>
          <div className="outcome-story__modal-body">
            <div className="outcome-story__modal-headline show-desktop hide-phone">
              {modalTitle}
            </div>
            {artifactUploadNode}
          </div>
        </div>
      </div>
      <FreezeWindowScroll parentModal="outcome-story__modal" />
    </div>
  );
};

VisionArtifactUploadModal.propTypes = {
  getSingleSelectElement: PropTypes.func.isRequired,
  selectSingleSelectElement: PropTypes.func.isRequired,
  confirmDelete: PropTypes.object.isRequired,
  confirmMove: PropTypes.object.isRequired,
  setFeatureFieldError: PropTypes.func.isRequired,
  moveArtifact: PropTypes.func.isRequired,
};

export default VisionArtifactUploadModal;
