// @ts-nocheck FIXME
import dateformat from 'dateformat';
import PropTypes from 'prop-types';

const EditStrategies = (props) => {
  // unpublish the portfolio, clear all entries, and load the latest playlist data
  const handleRefresh = (e) => {
    e.preventDefault();
    props.refreshData();
  };

  // hide/show a strategy in the portfolio
  const handleToggleVisible = (loopId) => {
    props.toggleLoopVisibility(loopId);
  };

  // hide/show the edit menu for the loop
  const handleToggleEditMenu = (e, loopId) => {
    e.preventDefault();
    e.stopPropagation();
    props.toggleLoopEditMenu(loopId);
  };

  // show the "Select Artifacts" page for this loop
  const handleSelectArtifacts = (loopId) => {
    props.setCurrentLoopSubstep({ id: loopId, substep: 'artifacts' });
  };

  // show the "Edit Reflections" page for this loop
  const handleEditReflections = (loopId) => {
    props.setCurrentLoopSubstep({ id: loopId, substep: 'feedback' });
  };

  // if the user clicks on a disabled menu item, we want to prevent it from closing
  const handleClickDisabledMenuItem = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // if the portfolio is already published,
  // then it needs to be unpublished before changes to tml data can be made
  const disabledClass = props.refreshing ? ' disabled' : '';
  const spinnerNode = props.refreshing ? (
    <span className="littlespinner portfolio-edit__refresh-spinner"></span>
  ) : null;
  let bodyNode = (
    <div className="portfolio-edit__container portfolio-edit__container--strategies">
      <div className="row tc__body-copy">
        <div className="portfolio-edit__strategies-refresh-text">
          Your TeachCycle data needs to be refreshed in order to edit this
          section.
        </div>
        <button
          onClick={handleRefresh}
          className={
            'portfolio-edit__strategies-refresh-btn tc__btn tc__btn--blue tc__btn--small' +
            disabledClass
          }
        >
          {spinnerNode}
          Refresh Data
        </button>
      </div>
    </div>
  );

  // if the portfolio is unpublished, then we should show the "tried" strategies
  // from the teacher's playlist so the user can show/hide them as desired
  if (!props.published) {
    // build the list of tml loops from the playlist
    const strategyNodes = props.loops.map((loop) => {
      // grey things out if the loop is not visible
      const capsuleClass = loop.visible
        ? ''
        : ' portfolio-edit__strategy-capsule--grey';
      const visibilityIconClass = loop.visible
        ? ''
        : ' portfolio-edit__strategy-visible-icon--grey';
      const editIconClass = loop.visible
        ? ''
        : ' portfolio-edit__strategy-edit-icon--grey';
      const editIconOnClick = loop.visible
        ? (e) => handleToggleEditMenu(e, loop.loopId)
        : null;

      // set up the text for the artifact count
      let selectedArtifactCount = 0;
      loop.artifacts &&
        loop.artifacts.forEach((artifact) => {
          if (artifact.visible) {
            selectedArtifactCount += 1;
          }
        });

      const measurementNode = loop.teaching_challenge.is_instructional_design
        ? 'Teacher Improvement: ' + loop.teacher_improvement
        : 'Students Impacted: ' + loop.students_impacted;

      // set up the menu that appears when you click on the pencil icon
      let editMenuNode = null;
      if (props.showEditMenuIds.indexOf(loop.loopId) != -1) {
        // disable the Edit Artifacts link if the loop has no artifacts
        const artifactsDisabledClass = loop.artifact_count ? '' : ' disabled';
        const artifactsOnClick = loop.artifact_count
          ? (e) => handleSelectArtifacts(loop.loopId)
          : handleClickDisabledMenuItem;

        // disable the Edit Reflections link if the loop has no feedback
        let hasFeedback = false;
        for (const step in loop.feedback) {
          if (loop.feedback[step].message) {
            hasFeedback = true;
          }
        }
        const reflectionsDisabledClass = hasFeedback ? '' : ' disabled';
        const reflectionsOnClick = hasFeedback
          ? (e) => handleEditReflections(loop.loopId)
          : handleClickDisabledMenuItem;

        editMenuNode = (
          <div className="tc__body-copy portfolio-edit__strategy-edit-menu">
            <div
              onClick={artifactsOnClick}
              className={
                'portfolio-edit__strategy-edit-menu-item' +
                artifactsDisabledClass
              }
            >
              Select Artifacts
            </div>
            <div
              onClick={reflectionsOnClick}
              className={
                'portfolio-edit__strategy-edit-menu-item' +
                reflectionsDisabledClass
              }
            >
              Edit Reflections
            </div>
          </div>
        );
      }

      return (
        <div
          className={'row portfolio-edit__strategy-capsule' + capsuleClass}
          key={loop.loopId}
        >
          <div className="cute-1-desktop portfolio-edit__strategy-capsule-icon-col">
            <span
              onClick={(e) => handleToggleVisible(loop.loopId)}
              className={
                'tc-check portfolio-edit__strategy-visible-icon' +
                visibilityIconClass
              }
            ></span>
          </div>
          <div className="cute-4-desktop">
            <div className="portfolio-edit__strategy-name">{loop.title}</div>
            <div className="portfolio-edit__strategy-data">
              <span>
                {selectedArtifactCount} of {loop.artifact_count} Artifacts
                Selected
              </span>
              <span className="portfolio-edit__strategy-students-impacted">
                {measurementNode}
              </span>
            </div>
          </div>
          <div className="cute-4-desktop portfolio-edit__strategy-capsule-text-col">
            {loop.teaching_challenge.name}
          </div>
          <div className="cute-1-desktop portfolio-edit__strategy-capsule-text-col">
            {loop.started && dateformat(loop.started, 'mm/dd/yyyy')}
          </div>
          <div className="cute-1-desktop portfolio-edit__strategy-capsule-text-col">
            {loop.completed && dateformat(loop.completed, 'mm/dd/yyyy')}
          </div>
          <div className="cute-1-desktop portfolio-edit__strategy-capsule-icon-col">
            <span
              onClick={editIconOnClick}
              className={
                'tc-pencil portfolio-edit__strategy-edit-icon' + editIconClass
              }
            ></span>
            {editMenuNode}
          </div>
        </div>
      );
    });

    bodyNode = (
      <div className="portfolio-edit__container portfolio-edit__container--strategies">
        <div className="row">
          <div className="cute-8-desktop left">
            <div className="portfolio-edit__strategies-headline">
              Select Strategies
            </div>
            <div className="tc__body-copy">
              All of your tried strategies are included in the portfolio by
              default. If you wish to remove a strategy from your portfolio,
              click the check icon next to the strategy name.
            </div>
          </div>
        </div>
        <div className="row portfolio-edit__strategy-field-headers">
          <div className="cute-4-desktop cute-1-desktop-offset">Strategy</div>
          <div className="cute-4-desktop">Teaching Challenge</div>
          <div className="cute-1-desktop">Started</div>
          <div className="cute-1-desktop left">Completed</div>
        </div>
        {strategyNodes}
      </div>
    );
  }

  return bodyNode;
};

EditStrategies.propTypes = {
  teacherId: PropTypes.number.isRequired,
  loops: PropTypes.array,
  toggleLoopVisibility: PropTypes.func.isRequired,
  toggleLoopEditMenu: PropTypes.func.isRequired,
  showEditMenuIds: PropTypes.array.isRequired,
  setCurrentLoopSubstep: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default EditStrategies;
