// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment-timezone';
import React from 'react';
import AdminApi from './../../apis/AdminApi';
import GateKeeperApi from './../../apis/GateKeeperApi';
import UserApi from './../../apis/UserApi';
import ArrayHelpers from './../../components/ArrayHelpers';
import Utils from './../../components/DynamicUtils';
import AbsoluteAlert from './../Alerts/Absolute';
import NavigationLink from './../NavigationLink';
import SingleSelect from './../V3/Common/SingleSelect/SingleSelect';

class AdminUser extends React.Component {
  static contextType = LegacyLabContext;

  constructor(props, context) {
    super(props, context);

    const user = props.userData;
    const partners = props.partners;

    // anyone who has access to this page can access the Roles tab,
    // unless they are only a Help Desk Agent
    const hasNonAgentRole = Utils.arrayContainsAnyItemInAnotherArray(
      this.props.user.auth_roles,
      ['ADMIN', 'BL_MANAGER', 'BL_SERVICES_MANAGER', 'BL_TEAM_MEMBER']
    );

    // Prepare the single select component to show the selected partner
    let selectedPartnerOption;
    const visiblePartnerOptions = [];
    if (user.details && user.details.partner_id) {
      selectedPartnerOption = partners.find(function (partner) {
        return partner.id === user.details.partner_id;
      });
      visiblePartnerOptions.push(selectedPartnerOption);
    } else {
      // If the user is not affiliated with any partner, show the "unaffiliated" label
      selectedPartnerOption = partners.find(function (partner) {
        return !partner.id;
      });
      visiblePartnerOptions.push(selectedPartnerOption);
    }

    const userDetails = user.details || {};

    // track the active participant record for the user, if they have one
    if ('participants' in user && user.participants.length) {
      this.activeParticipant = user.participants.find((par) => !par.archived);
    }

    this.state = {
      currentTab:
        location.hash === '#show-metadata' && hasNonAgentRole
          ? 'metadata'
          : 'general',
      passwordResetCode: null,
      passwordResetToken: null,
      userRoles: this.getUserRolesIds(),
      user: user,
      alert: {},
      errors: {},
      disableBtns: false,
      partners: {
        inputStatus: 'blur',
        charCount: 0,
        selectedOption: selectedPartnerOption,
        visibleOptions: visiblePartnerOptions,
        feature: 'partners',
      },
      userTypeId: userDetails.type_id,
      userGroupId: userDetails.group_id,
    };
  }

  canLoggedInUserEdit = () => {
    // Only super admins and user managers can edit any user data on this page -
    // help desk agents can edit data in the "general" tab but cannot access the roles tab
    return Utils.arrayContainsAnyItemInAnotherArray(
      this.props.user.auth_roles,
      ['ADMIN', 'BL_MANAGER', 'HELP_DESK_AGENT']
    );
  };

  userRolesAreEditable = () => {
    // If the user being edited is an admin, we cannot edit their roles
    return this.canLoggedInUserEdit() && !this.doesUserHaveRole({ id: 0 });
  };

  userIsUnaffiliated = () => {
    const selectedPartner = this.getSelectedPartner();
    return (
      selectedPartner && selectedPartner.name.toLowerCase() === 'unaffiliated'
    );
  };

  getUserRolesIds = () => {
    // initialize with ADMIN role if the user has that role
    const roleIds =
      this.props.userData.auth_roles.indexOf('ADMIN') !== -1 ? [0] : [];

    return this.props.roles.reduce(
      function (roles, role) {
        if (this.props.userData.auth_roles.indexOf(role.name) !== -1) {
          roles.push(parseInt(role.id));
        }
        return roles;
      }.bind(this),
      roleIds
    );
  };

  // change selected tab between "general" and "metadata". resets alerts
  changeTab = (tab) => {
    this.setState({
      currentTab: tab,
      alert: {},
    });
  };

  handleUserFieldChange = (e) => {
    const user = this.state.user;
    user[e.target.id] = e.target.value;
    this.setState({
      user: user,
      alert: {},
    });
  };

  toggleUserCheckbox = (e) => {
    const user = this.state.user;
    user[e.target.id] = e.target.checked ? 1 : 0;
    this.setState({
      user: user,
      alert: {},
    });
  };

  // submit the form in the "Account Settings" tab
  handleSubmitGeneral = (e) => {
    e.preventDefault();

    // Reset alerts
    this.setState({
      disableBtns: true,
      alert: {},
      errors: {},
    });

    const data = {
      user_id: this.props.userData.id,
      user_fields: {
        first_name: this.state.user.first_name,
        last_name: this.state.user.last_name,
        confirmed: this.state.user.confirmed,
        email_confirmed: this.state.user.email_confirmed,
      },
    };

    UserApi.updateUser(data)
      .then(
        function () {
          this.setState({
            disableBtns: false,
            alert: { type: 'success', message: 'User successfully updated' },
          });
        }.bind(this)
      )
      .catch(
        function (response) {
          this.setState({
            disableBtns: false,
            alert: {
              type: 'error',
              message: response.error,
            },
          });
        }.bind(this)
      );
  };

  handleGeneratePasswordResetLink = () => {
    // Reset alerts:
    this.setState({
      alert: {},
      errors: {},
    });

    const data = {
      user_id: this.props.userData.id,
    };

    GateKeeperApi.getPasswordResetCode(data)
      .then(
        function (response) {
          if (response.code) {
            this.setState({ passwordResetCode: response.code });
          } else if (response.token) {
            this.setState({ passwordResetToken: response.token });
          }
        }.bind(this)
      )
      .catch(
        function () {
          this.setState({
            alert: {
              type: 'error',
              message:
                'Sorry, we could not generate the link. Please try again later.',
            },
          });
        }.bind(this)
      );
  };

  handleHighlightPasswordResetLink = (e) => {
    e.target.select();
  };

  doesUserHaveRole = (role) => {
    const result = this.state.userRoles.findIndex(
      function (userRole) {
        return userRole === role.id;
      }.bind(role)
    );

    return result !== -1;
  };

  getRoleNameById = (roleId) => {
    const roles = this.props.roles;
    for (let i = 0, len = roles.length; i < len; i++) {
      if (roles[i].id === roleId) {
        return roles[i].name;
      }
    }
    return null;
  };

  getRoleIdByName = (roleName) => {
    const roles = this.props.roles;
    for (let i = 0, len = roles.length; i < len; i++) {
      if (roles[i].name === roleName) {
        return roles[i].id;
      }
    }
    return null;
  };

  handleRoleChange = (roleId) => {
    // Handle cases where the user roles cannot be edited
    if (!this.userRolesAreEditable()) {
      return false;
    }

    const selectedRoleName = this.getRoleNameById(roleId);
    const userRoles = this.state.userRoles;

    if (Utils.inArray(roleId, userRoles)) {
      // If the user clicks on a role that has been selected, de-select that role
      userRoles.splice(userRoles.indexOf(roleId), 1);
    } else if (!Utils.inArray(roleId, userRoles)) {
      // only a super admin can add the BL_MANAGER role to a user
      if (
        selectedRoleName === 'BL_MANAGER' &&
        this.props.user.auth_roles.indexOf('ADMIN') !== -1
      ) {
        userRoles.push(roleId);
      } else if (selectedRoleName !== 'BL_MANAGER') {
        // for other roles, just add it to the list
        userRoles.push(roleId);
      }
    }

    this.setState({
      userRoles: userRoles,
      alert: {},
    });
  };

  getAvailablePartners = (data) => {
    // SingleSelect was not tested with stateful components
    // Without the setTimeout closure, there will be a race condition between getAvailablePartners and setSelectedPartner
    // And the state never gets updated correctly
    setTimeout(
      function () {
        const partners = this.props.partners;
        const field = Utils.clone(this.state.partners);
        field.charCount = data.input.length;

        // Only show options that contain the input text
        field.visibleOptions = partners.filter((option) => {
          return option.label.toLowerCase().includes(data.input.toLowerCase());
        });
        this.setState({
          partners: field,
          alert: {},
        });
      }.bind(this),
      0
    );
  };

  setSelectedPartner = (data) => {
    // Clear all errors when the partner is selected
    this.clearFieldError();
    // SingleSelect was not tested with stateful components
    // Without the setTimeout closure, there will be a race condition between getAvailablePartners and setSelectedPartner
    // And the state never gets updated correctly
    setTimeout(
      function () {
        const field = Utils.clone(this.state.partners);
        Object.assign(field, data.data);
        const selectedUserType = this.getSelectedUserType();
        let selectedUserTypeId = selectedUserType ? selectedUserType.id : null;
        // Make sure the selected user type and selected user affiliation remain valid
        if (
          selectedUserType &&
          field.selectedOption &&
          field.selectedOption.affiliation_type &&
          selectedUserType.valid_for_affiliation_type !== 'all' &&
          selectedUserType.valid_for_affiliation_type !==
            field.selectedOption.affiliation_type
        ) {
          // Reset the invalid user type
          selectedUserTypeId = null;
        }
        // When the partner is changed, reset the user roles, group and type if needed
        this.setState({
          partners: field,
          alert: {},
          // Only reset the user roles if they are editable
          userRoles: this.userRolesAreEditable() ? [] : this.state.userRoles,
          userGroupId: null,
          userTypeId: selectedUserTypeId,
        });
      }.bind(this),
      0
    );
  };

  getSelectedPartnerId = () => {
    return this.state.partners.selectedOption
      ? this.state.partners.selectedOption.id
      : null;
  };

  // A user can have at most one user type
  handleUserTypeChange = (userTypeId) => {
    this.clearFieldError('type');
    const newState = {
      userTypeId: userTypeId,
      // Only reset the user roles if they are editable
      userRoles: this.userRolesAreEditable() ? [] : this.state.userRoles,
      // Reset the user group
      userGroupId: null,
      alert: {},
    };
    this.setState(newState);
  };

  // submit the form in the "metadata" tab
  handleSubmitMetaData = (e) => {
    e.preventDefault();

    // Reset alerts:
    this.setState({
      disableBtns: true,
      alert: {},
      errors: {},
    });

    if (!this.userDetailsAreValid()) {
      this.setState({
        disableBtns: false,
      });
      return false;
    }

    // build list of roles that the current user is allowed to set/unset
    // Why don't we handle this on the back end? -JR
    const rolesToReplace = this.props.roles.map(function (role) {
      return parseInt(role.id);
    });

    const partnerId = this.getSelectedPartnerId();
    const typeId = this.state.userTypeId;
    const groupId = this.state.userGroupId;

    const data = {
      user_id: this.props.userData.id,
      auth_roles: this.state.userRoles,
      roles_to_replace: rolesToReplace,
      partner_id: partnerId,
      type_id: typeId,
      group_id: groupId,
    };

    AdminApi.updateTeacherAccountV2(data)
      .then(
        function () {
          this.setState({
            disableBtns: false,
            alert: {
              type: 'success',
              message: 'User successfully updated',
            },
          });
        }.bind(this)
      )
      .catch(
        function (response) {
          const errors = Utils.clone(this.state.errors);
          // See if the backend returned errors for individual fields
          if ('fields' in response) {
            // Iterate through the fields dictionary
            for (const field in response.fields) {
              errors[field] = response.fields[field];
            }
          }
          this.setState({
            disableBtns: false,
            alert: {
              type: 'error',
              message: response.error,
            },
            errors: errors,
          });
        }.bind(this)
      );
  };

  userDetailsAreValid = () => {
    const errors = Utils.clone(this.state.errors);

    // Partner
    if (!this.getSelectedPartner()) {
      errors['partner'] = 'Please select an affiliation';
    }

    // User Type
    if (!this.getSelectedUserType()) {
      errors['type'] = 'Please select a user type';
    }

    this.setState({
      errors: errors,
    });

    return Utils.isEmpty(errors);
  };

  clearFieldError = (field) => {
    let errors = Utils.clone(this.state.errors);

    if (field in errors) {
      delete errors[field];
    } else {
      // Clear all errors
      errors = {};
    }

    this.setState({
      errors: errors,
    });
  };

  getPPD3Links = (hasNonAgentRole) => {
    // if the current user is just a Help Desk Agent, we hide the PL section and any cohorts
    const links = [];
    if (
      hasNonAgentRole &&
      'leads' in this.state.user &&
      this.state.user.leads.length
    ) {
      links.push(
        <div
          key="pl-links"
          className="admin-v2__user-page-section admin-v2__user-page-section--margin admin-v2__user-page-form-item clearfix"
        >
          <div className="tc__headline-4">Partner Leader Links</div>
          {this.state.user.leads.map((lead) => {
            // we consider any PL record that started before the 2019-2020 school year
            // to be "archived", since they use the older PL Dashboard
            const isArchivedPL =
              parseInt(lead.school_year.split(' - ').shift()) < 2019;
            if (isArchivedPL) {
              return;
            }
            return (
              <div key={`pl-${lead.id}-year`}>
                <div className="admin-v2__user-teachcycle-link v5__heading-6">
                  School Year: {isArchivedPL ? lead.school_year : '2019 - now'}
                </div>
                <div className="admin-v2__user-teachcycle-link">
                  <NavigationLink
                    className="tc__link"
                    url={`/lab/leader/${lead.id}/home`}
                  >
                    Partner Leader Homepage
                  </NavigationLink>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    if (
      'participants' in this.state.user &&
      this.state.user.participants.length
    ) {
      const activeParticipants = [];
      const archivedParticipants = [];
      this.state.user.participants.forEach((participant) => {
        // show the participant's active school year if they have an "active" date
        const schoolYearText = participant.school_year
          ? ` (school year: ${participant.school_year})`
          : '';

        // cohort node
        let cohortNameNode;
        if (participant.cohort_name && hasNonAgentRole) {
          cohortNameNode = (
            <div style={{ padding: '10px 0 0 20px' }}>
              <b>Cohort:</b> {participant.cohort_name}
            </div>
          );
        }
        // events node
        let eventNamesNode;
        if (participant.events.length > 0) {
          eventNamesNode = (
            <div style={{ padding: '10px 0 0 20px' }}>
              <div>
                <b>Event(s):</b>
              </div>
              {participant.events.map((event) => {
                let eventDescription = event.name;
                if (event.group_name) {
                  eventDescription = `${eventDescription}, ${event.group_name}`;
                }
                let eventDate = '';
                if (event.event_start_date) {
                  const startDate = moment(event.event_start_date).format(
                    'M/DD/YY'
                  );
                  eventDate = startDate;
                  if (event.event_start_date !== event.event_end_date) {
                    const endDate = moment(event.event_end_date).format(
                      'M/DD/YY'
                    );
                    eventDate = `${startDate} - ${endDate}`;
                  }
                }

                return (
                  <li key={event.id} className="admin-v5__event-bullet-item">
                    <NavigationLink
                      url={`/bl/event/${event.id}`}
                      className="tc__link"
                    >
                      {eventDescription}
                    </NavigationLink>
                    <span className="admin-v5__event-bullet-item-date">
                      {eventDate}
                    </span>
                  </li>
                );
              })}
            </div>
          );
        }

        const participantLinkItem = (
          <div
            key={'participant-' + participant.id}
            className="admin-v2__user-teachcycle-link"
          >
            <NavigationLink
              className="tc__link"
              url={'/bl/participant/' + participant.id}
            >
              Participant Management{schoolYearText}
            </NavigationLink>
            {cohortNameNode}
            <ul>{eventNamesNode}</ul>
          </div>
        );
        if (participant.archived) {
          archivedParticipants.push(participantLinkItem);
          return;
        }
        activeParticipants.push(participantLinkItem);
      });

      let activeParticipantsNode = null;
      let participantLinkSecondHeadlineClass = '';
      if (activeParticipants.length > 0) {
        participantLinkSecondHeadlineClass =
          'admin-v2__user-page-secondary-headline';
        activeParticipantsNode = (
          <React.Fragment>
            <div className="tc__headline-4">Participant Links</div>
            {activeParticipants}
          </React.Fragment>
        );
      }
      let archivedParticipantsNode = null;
      if (archivedParticipants.length > 0) {
        archivedParticipantsNode = (
          <React.Fragment>
            <div
              className={`tc__headline-4 ${participantLinkSecondHeadlineClass}`}
            >
              Archived Participant Links
            </div>
            {archivedParticipants}
          </React.Fragment>
        );
      }
      links.push(
        <div key="participant-links">
          <div className="admin-v2__user-page-section admin-v2__user-page-section--margin admin-v2__user-page-form-item admin-v2__user-page-form-item--full-width clearfix">
            {activeParticipantsNode}
            {archivedParticipantsNode}
          </div>
        </div>
      );
    }

    return links;
  };

  getCoachLinks = () => {
    const links = [];
    // if this user is a BL Coach, link to their V5 coach home
    const activeCoaches =
      this.state.user.coaches &&
      this.state.user.coaches.filter((coach) => !coach.archived);
    if (
      Utils.isServicesManager.call(this.context, this.state.user) ||
      Utils.isContractCoach.call(this.context, this.state.user) ||
      ArrayHelpers.hasElements(activeCoaches)
    ) {
      // Handle coaches
      links.push(
        <div
          key="current-coach-dashboard"
          className="admin-v2__user-page-section admin-v2__user-page-form-item clearfix"
        >
          <div className="tc__headline-4">Coach Links</div>
          <div className="admin-v2__user-teachcycle-link">
            <NavigationLink
              className="tc__link"
              url={'/lab/coach/' + this.state.user.id}
            >
              Coach Dashboard
            </NavigationLink>
          </div>
        </div>
      );
    }
    // add any links for archived coach records
    const archivedCoaches =
      this.state.user.coaches &&
      this.state.user.coaches.filter((coach) => coach.archived);
    if (ArrayHelpers.hasElements(archivedCoaches)) {
      links.push(
        <div
          key="archived-coach-dashboard"
          className="admin-v2__user-page-section admin-v2__user-page-form-item clearfix"
        >
          <div className="admin-v2__user-teachcycle-link">
            <NavigationLink
              className="tc__link"
              url={`/lab/coach/${this.state.user.id}/archive`}
            >
              Coach Archive (all previous school years)
            </NavigationLink>
          </div>
        </div>
      );
    }
    return links;
  };

  getUserTypeNodes = () => {
    const userTypeNodes = this.props.user_types.map(
      function (userType) {
        const userHasType = this.state.userTypeId === userType.id;
        let className = '';
        let clickHandler = null;
        const selectedPartner = this.getSelectedPartner();
        const userIsUnaffiliated = this.userIsUnaffiliated();
        // Disable user types not compatible with the selected partner
        if (
          selectedPartner &&
          // Unaffiliated users are eligible for all user types!!!
          !userIsUnaffiliated &&
          selectedPartner.affiliation_type &&
          userType.valid_for_affiliation_type !== 'all' &&
          userType.valid_for_affiliation_type !==
            selectedPartner.affiliation_type
        ) {
          className += 'disabled ';
        } else {
          //  See if the user type should be selected
          className += userHasType ? 'selected ' : '';
          // Add the click handler
          clickHandler = this.handleUserTypeChange.bind(this, userType.id);
        }
        return (
          <div
            key={'userType-' + userType.id}
            onClick={clickHandler}
            className="admin-v2__user-page-form-item clearfix"
          >
            <div
              className={'admin-v2__user-role-radio tc__radio ' + className}
            ></div>
            <div
              className={
                'admin-v2__user-page-label admin-v2__user-page-label--radio ' +
                className
              }
            >
              {userType.name}
            </div>
          </div>
        );
      }.bind(this)
    );

    let errorNode;
    if ('type' in this.state.errors) {
      errorNode = <div className="tc__error">{this.state.errors.type}</div>;
    }
    return (
      <div className="admin-v2__user-page-section admin-v2__user-page-section-header tc__headline-4">
        <div className="admin-v2__user-page-section-header tc__headline-4">
          User Type
        </div>
        {userTypeNodes}
        {errorNode}
      </div>
    );
  };

  // A user can have at most one user group
  handleUserGroupChange = (e) => {
    this.clearFieldError('group');
    this.setState({
      userGroupId: e.target.value === '0' ? null : parseInt(e.target.value),
      alert: {},
    });
  };

  getSelectedUserType = () => {
    return this.props.user_types.filter(
      function (userType) {
        return userType.id === this.state.userTypeId;
      }.bind(this)
    )[0];
  };

  getUserGroupNodes = () => {
    // Get the selected partner
    const selectedPartner = this.getSelectedPartner();

    // Get the selected user type
    const selectedUserType = this.getSelectedUserType();

    let hideUserGroups = false;
    // Hide the field if either user type or affiliation is not set
    hideUserGroups = hideUserGroups || !selectedUserType || !selectedPartner;
    if (hideUserGroups) {
      return null;
    }

    // Get the user groups appropriate for the selected partner
    const userGroupNodes = this.props.user_groups
      .filter(function (userGroup) {
        return (
          userGroup.valid_for_affiliation_type === 'all' ||
          userGroup.valid_for_affiliation_type ===
            selectedPartner.affiliation_type
        );
      })
      .map(
        function (userGroup) {
          return (
            <option key={'userGroup-' + userGroup.id} value={userGroup.id}>
              {userGroup.name}
            </option>
          );
        }.bind(this)
      );

    userGroupNodes.unshift(
      <option key={'userGroup-0'} value={0}>
        Select a team
      </option>
    );

    let errorNode;
    if ('group' in this.state.errors) {
      errorNode = <div className="tc__error">{this.state.errors.group}</div>;
    }

    return (
      <div className="admin-v2__user-page-section admin-v2__user-page-section-header tc__headline-4">
        <div className="admin-v2__user-page-section-header tc__headline-4">
          Team
        </div>
        <select
          ref="userGroup"
          className="tc__select"
          value={this.state.userGroupId || 0}
          onChange={this.handleUserGroupChange}
        >
          {userGroupNodes}
        </select>
        {errorNode}
      </div>
    );
  };

  getAffiliationNodes = () => {
    let errorNode;
    if ('partner' in this.state.errors) {
      errorNode = <div className="tc__error">{this.state.errors.partner}</div>;
    }
    return (
      <div className="admin-v2__user-create-form-item admin-v2__user-create-form-item--room-for-error clearfix">
        <div className="admin-v2__user-page-section-header tc__headline-4">
          Affiliation
        </div>
        <SingleSelect
          {...this.state.partners}
          field="partner"
          minCharCountForResults={0}
          updateSingleSelect={this.setSelectedPartner}
          getVisibleOptions={this.getAvailablePartners}
          onChangeDelay="0"
        />
        {errorNode}
      </div>
    );
  };

  getUserRolesNodes = () => {
    const selectedPartner = this.getSelectedPartner();
    if (!selectedPartner || selectedPartner.affiliation_type !== 'bl')
      return null;

    const rolesNodes = this.props.roles.map((role) => {
      const userHasRole = this.doesUserHaveRole(role);
      const userIsAdmin = this.props.user.auth_roles.indexOf('ADMIN') !== -1;
      const pageUserIsAdmin =
        this.props.userData.auth_roles.indexOf('ADMIN') !== -1;

      // disable the role if the user is an admin or the viewer cannot edit roles
      const isDisabled =
        role.name === ('BL_MANAGER' && !userIsAdmin) || pageUserIsAdmin;
      const className = isDisabled ? ' disabled ' : '';

      return (
        <div
          key={'role-' + role.id}
          className="admin-v2__user-page-form-item admin-v2__user-page-form-item--full-width clearfix"
        >
          <input
            id={role.name}
            checked={userHasRole}
            type="checkbox"
            className={'tc__checkbox' + className}
            value={role.id}
            disabled={isDisabled}
            onChange={this.handleRoleChange.bind(this, role.id)}
          />
          <label
            htmlFor={role.name}
            className={
              'admin-v2__user-page-label admin-v2__user-page-label--checkbox' +
              className
            }
          >
            {role.description}
          </label>
        </div>
      );
    });

    return (
      <div>
        <div className="admin-v2__user-page-section admin-v2__user-page-section-header tc__headline-4">
          Roles
        </div>
        {rolesNodes}
      </div>
    );
  };

  getPseudoRolesNode = () => {
    const pseudoRolesNode = this.props.userData.pseudo_roles.map((role) => {
      let cohortsNode = [];
      let eventsNode = [];

      // set cohortsNode based on pseudo role
      if (role.cohorts) {
        cohortsNode = role.cohorts.map((cohort, i) => (
          <li key={i} className="admin-v5__event-bullet-item">
            <NavigationLink
              key={'cohort-' + cohort.id}
              className="tc__link"
              url={`/bl/cohort/${cohort.id}/members`}
            >
              {cohort.name}
            </NavigationLink>
          </li>
        ));
      }

      // set events that the role might have
      if (role.collated_events) {
        eventsNode = role.collated_events.map((event, i) => {
          let eventDescription = event.name;
          if (event.group_name) {
            eventDescription = `${eventDescription}, ${event.group_name}`;
          }
          let eventDate = '';
          if (event.event_start_date) {
            const startDate = moment(event.event_start_date).format('M/DD/YY');
            eventDate = startDate;
            if (event.event_start_date !== event.event_end_date) {
              const endDate = moment(event.event_end_date).format('M/DD/YY');
              eventDate = `${startDate} - ${endDate}`;
            }
          }
          return (
            <li key={i} className="admin-v5__event-bullet-item">
              <NavigationLink
                key={'event-' + event.id}
                className="tc__link"
                url={`/bl/event/${event.id}/participants`}
              >
                {eventDescription}
              </NavigationLink>
              <span className="admin-v5__event-bullet-item-date">
                {eventDate}
              </span>
            </li>
          );
        });
      }

      return (
        <div
          key={'role-' + role.name}
          className="admin-v2__user-page-form-item admin-v2__user-page-form-item--full-width clearfix"
        >
          <div>{role.description}</div>
          <ul>
            {cohortsNode.length ? (
              <div className="admin-v5__event-bullet-item">
                <b>Cohort(s):</b>
                {cohortsNode}
              </div>
            ) : null}
            {eventsNode.length ? (
              <div className="admin-v5__event-bullet-item">
                <b>Event(s):</b>
                {eventsNode}
              </div>
            ) : null}
          </ul>
        </div>
      );
    });

    return (
      <div>
        <div className="admin-v2__user-page-section admin-v2__user-page-section-header tc__headline-4">
          Pseudo Roles
        </div>
        {pseudoRolesNode}
      </div>
    );
  };

  getSelectedPartner = () => {
    return this.state.partners.selectedOption;
  };

  getAdminBlLogLink = () => {
    if (!Utils.isAdmin.call(this.context)) {
      return null;
    }
    return (
      <div className="admin-v2__user-page-section admin-v2__user-page-form-item admin-v2__user-page-form-item--full-width clearfix">
        <div className="tc__headline-4">Admin Reports</div>
        <div className="admin-v2__user-teachcycle-link">
          <NavigationLink
            className="tc__link"
            url={'/bl/admin/view_bl_logs?user=' + this.state.user.id}
          >
            View Security Logs
          </NavigationLink>
        </div>
      </div>
    );
  };

  render() {
    const user = this.state.user;
    // if the logged in user can't edit, then set to read-only
    const readOnly = !this.canLoggedInUserEdit();

    // for read-only mode, some elements need an extra class to appear disabled
    const disabledClass = readOnly ? ' disabled' : '';

    // Should we disable the submit button?
    const submitDisabledClass =
      readOnly || this.state.disableBtns ? ' disabled' : '';

    // Build the alert node if needed:
    let alertNode;
    if (Object.keys(this.state.alert).length > 0) {
      alertNode = (
        <AbsoluteAlert
          {...this.state.alert}
          cssClasses={{ container: 'alert-banner--fixed' }}
        />
      );
    }

    // anyone who has access to this page can access the Roles tab,
    // unless they are only a Help Desk Agent
    const hasNonAgentRole = Utils.arrayContainsAnyItemInAnotherArray(
      this.props.user.auth_roles,
      ['ADMIN', 'BL_MANAGER', 'BL_SERVICES_MANAGER', 'BL_TEAM_MEMBER']
    );
    const rolesTabNode = hasNonAgentRole && (
      <a
        key="roles-tab"
        onClick={this.changeTab.bind(this, 'metadata')}
        href="#show-metadata"
        className={
          'tc__tab headline-4 tc__center-text cute-6-desktop' +
          (this.state.currentTab === 'metadata' ? ' active' : '')
        }
      >
        Roles & Permissions
      </a>
    );
    // set up default values for the user confirmation checkboxes
    const userConfirmed = user.confirmed;
    const emailConfirmed = user.email_confirmed;

    let passwordResetLinkNode = null;
    if (!readOnly) {
      let resetLink = '';
      if (this.state.passwordResetCode) {
        resetLink =
          process.env.REACT_APP_LAB_URL +
          '/reset-password?code=' +
          this.state.passwordResetCode;
      } else if (this.state.passwordResetToken) {
        resetLink =
          process.env.REACT_APP_LAB_URL +
          '/reset-password?' +
          this.state.passwordResetToken;
      }
      passwordResetLinkNode = (
        <div className="admin-v2__user-page-section">
          <div
            onClick={this.handleGeneratePasswordResetLink}
            className="admin-v2__user-page-section-header tc__link"
          >
            Generate Password Reset Link
          </div>
          <div className="admin-v2__user-page-form-item clearfix">
            <label
              htmlFor="password_reset_link"
              className="admin-v2__user-page-label"
            >
              Password Reset Link
            </label>
            <input
              type="text"
              placeholder="Password Reset Link"
              id="password_reset_link"
              defaultValue={resetLink}
              className="admin-v2__user-page-input admin-v2__user-page-input--password-reset tc__input"
              onClick={this.handleHighlightPasswordResetLink}
            />
          </div>
        </div>
      );
    }

    // only show the list of event RSVPs if the user has RSVP'd to any events
    let eventRsvpsNode;
    if (this.state.user.rsvps.length > 0) {
      eventRsvpsNode = (
        <div className="admin-v2__user-page-section admin-v2__user-page-section--margin admin-v2__user-page-form-item admin-v2__user-page-form-item--full-width clearfix">
          <div className="tc__headline-4">User Event RSVPs</div>
          {this.state.user.rsvps.map((event, i) => {
            let eventDate = '';
            if (event.event_start_date) {
              const startDate = moment(event.event_start_date).format(
                'M/DD/YY'
              );
              eventDate = startDate;
              if (event.event_start_date !== event.event_end_date) {
                const endDate = moment(event.event_end_date).format('M/DD/YY');
                eventDate = `${startDate} - ${endDate}`;
              }
            }

            return (
              <div key={i} className="admin-v2__user-teachcycle-link">
                <NavigationLink
                  className="tc__link"
                  url={`/bl/event/${event.id}`}
                >
                  {event.name}
                </NavigationLink>
                <span className="admin-v5__event-bullet-item-date">
                  {eventDate}
                </span>
              </div>
            );
          })}
        </div>
      );
    }

    // only show the User Links section if the current user has any non-agent roles and is not a participant
    let userLinksNode;
    if (hasNonAgentRole && Utils.isEmpty(this.props.header_participant)) {
      userLinksNode = (
        <div className="admin-v2__user-page-section admin-v2__user-page-section--margin admin-v2__user-page-form-item admin-v2__user-page-form-item--full-width clearfix">
          <div className="tc__headline-4">User Links</div>
          <div className="admin-v2__user-teachcycle-link">
            <NavigationLink
              className="tc__link"
              url={`/home/${this.state.user.id}`}
            >
              Home
            </NavigationLink>
          </div>
          <div className="admin-v2__user-teachcycle-link">
            <NavigationLink
              className="tc__link"
              url={`/profile/${this.state.user.id}`}
            >
              Profile
            </NavigationLink>
          </div>
          <div className="admin-v2__user-teachcycle-link">
            <NavigationLink
              className="tc__link"
              url={`/account/${this.state.user.id}`}
            >
              Settings
            </NavigationLink>
          </div>
          <div className="admin-v2__user-teachcycle-link">
            <NavigationLink
              className="tc__link"
              url={`${process.env.REACT_APP_TEACHING_URL}/browse/favorites/${this.state.user.id}`}
            >
              Favorites
            </NavigationLink>
          </div>
        </div>
      );
    }

    // by default, show the contents of the "Account Settings" tab
    let tabBodyNode = (
      <div className="cute-12-desktop">
        <form>
          <div className="admin-v2__user-page-form-item clearfix">
            <label htmlFor="first_name" className="admin-v2__user-page-label">
              First Name
            </label>
            <input
              type="text"
              defaultValue={user.first_name}
              placeholder="First Name"
              id="first_name"
              className="admin-v2__user-page-input tc__input"
              onChange={this.handleUserFieldChange}
              disabled={readOnly}
            />
          </div>
          <div className="admin-v2__user-page-form-item clearfix">
            <label htmlFor="last_name" className="admin-v2__user-page-label">
              Last Name
            </label>
            <input
              className="admin-v2__user-page-input tc__input"
              type="text"
              defaultValue={user.last_name}
              id="last_name"
              placeholder="Last Name"
              onChange={this.handleUserFieldChange}
              disabled={readOnly}
            />
          </div>
          <div className="admin-v2__user-page-form-item clearfix">
            <label htmlFor="email" className="admin-v2__user-page-label">
              Email Address
            </label>
            <input
              className="admin-v2__user-page-input tc__input"
              type="text"
              defaultValue={user.email}
              id="email"
              placeholder="Email Address"
              onChange={this.handleUserFieldChange}
              disabled={true}
            />
          </div>
          <div className="admin-v2__user-page-form-item clearfix">
            <input
              type="checkbox"
              id="confirmed"
              className="tc__checkbox"
              defaultChecked={userConfirmed}
              onClick={this.toggleUserCheckbox}
              disabled={readOnly}
            />
            <label
              htmlFor="confirmed"
              className={
                'admin-v2__user-page-label admin-v2__user-page-label--checkbox' +
                disabledClass
              }
            >
              User Enabled
            </label>

            <input
              type="checkbox"
              id="email_confirmed"
              className="tc__checkbox"
              defaultChecked={emailConfirmed}
              onClick={this.toggleUserCheckbox}
              disabled={readOnly}
            />
            <label
              htmlFor="email_confirmed"
              className={
                'admin-v2__user-page-label admin-v2__user-page-label--checkbox' +
                disabledClass
              }
            >
              Email Confirmed
            </label>
          </div>

          <div className="admin-v2__user-page-section">
            <div>
              <button
                onClick={this.handleSubmitGeneral}
                className={
                  'admin-v2__submit-btn tc__btn tc__btn--blue tc__btn--small' +
                  submitDisabledClass
                }
              >
                Update
              </button>
            </div>
          </div>
        </form>
        {passwordResetLinkNode}
        {this.getAdminBlLogLink()}
        {this.getCoachLinks()}
        {this.getPPD3Links(hasNonAgentRole)}

        {eventRsvpsNode}

        {userLinksNode}
      </div>
    );
    // if the "metadata" tab is selected, show the relevant nodes
    if (this.state.currentTab === 'metadata' && hasNonAgentRole) {
      tabBodyNode = (
        <div className="cute-12-desktop">
          <form>
            <div className="admin-v2__user-page-section-header tc__headline-3">
              Analytics
            </div>
            <div style={{ marginLeft: '20px' }}>
              {this.getAffiliationNodes()}
              {this.getUserTypeNodes()}
              {this.getUserRolesNodes()}
              {this.getPseudoRolesNode()}
              {this.getUserGroupNodes()}
            </div>
            <div>
              <button
                onClick={this.handleSubmitMetaData}
                className={
                  'admin-v2__submit-btn tc__btn tc__btn--blue tc__btn--small' +
                  submitDisabledClass
                }
              >
                Update
              </button>
            </div>
          </form>
        </div>
      );
    }

    return (
      <div>
        <Header page="AdminDashboard" showSettings={true} {...this.props} />
        {alertNode}

        <div className="row">
          <div className="cute-12-desktop">
            <div className="admin-v2__user-page-breadcrumbs">
              <NavigationLink url="/bl/users" className="tc__link">
                Users
              </NavigationLink>{' '}
              | User Profile
            </div>
            <div className="admin-v2__user-page-header">
              <h1 className="admin-v2__user-page-headline tc__headline-2">
                User: {user.email}
              </h1>
              <div className="admin-v2__user-page-header-date">
                Account Created: {Utils.formatDate(user.created)}
              </div>
              <div className="admin-v2__user-page-header-date">
                Last Active: {Utils.formatDate(user.last_login)}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="cute-12-desktop center-desktop">
            <a
              key="account-tab"
              onClick={this.changeTab.bind(this, 'general')}
              href="#show-general"
              className={
                'tc__tab headline-4 tc__center-text cute-6-desktop left' +
                (this.state.currentTab === 'general' ? ' active' : '')
              }
            >
              Account Settings
            </a>
            {rolesTabNode}
          </div>
        </div>
        <div className="admin-v2__user-tab-body">
          <div className="row">{tabBodyNode}</div>
        </div>
      </div>
    );
  }
}

export default AdminUser;
