// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';

class ActionStep extends React.Component {
  convertLinks = (actionStep) => {
    // Makes sure links open in a new tab:
    return actionStep.split('<a').join('<a target="_blank"');
  };

  render() {
    const actionStep = this.convertLinks(this.props.action_step);
    return (
      <li className="si-v2__action-step" key={'actionStep-' + this.props.id}>
        <PurifiedHTML
          ref="actionStepsContainer"
          className="si-v2__action-step-content"
          content={actionStep}
        />
      </li>
    );
  }
}

export default ActionStep;
