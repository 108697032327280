// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import Utils from './../../../components/DynamicUtils';
import AbsoluteAlert from './../../Alerts/Absolute';
import BlogCapsule from './Capsules/Blog';
import FavoritesCapsule from './Capsules/Favorites';
import ResultCapsule from './Capsules/FeedResult';
import FailureCapsule from './Capsules/SearchTrendsFailureCapsule';
import UserCapsule from './Capsules/User';

const FreeUserHome = (props) => {
  let headerCaptionNode,
    alertNode,
    blogNode,
    resultNode,
    resultsNode,
    userCapsuleNode,
    favoriteCapsuleNode;

  if (!Utils.isEmpty(props.ui.alert)) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // show the latest entry from the blog if it's available
  if (!Utils.isEmpty(props.blogCapsule)) {
    blogNode = <BlogCapsule {...props.blogCapsule} />;
  }

  if (props.searchTerm && props.results.length > 0) {
    headerCaptionNode = (
      <div>
        <h5 className="search__headline tc__headline-5">
          New and popular in {props.searchTerm}
        </h5>
      </div>
    );
  }
  if (props.results && props.results.length) {
    resultNode = props.results.map((result, i) => {
      return (
        <ResultCapsule
          key={'search-result-strategy-' + result.id + '-' + i}
          {...result}
          addFavorite={props.addFavorite}
          removeFavorite={props.removeFavorite}
          user={props.user}
          pageUser={props.pageUser}
          searchContext="feed"
        />
      );
    });
    resultsNode = (
      <div className="search__capsules search__capsules--feed">
        {resultNode}
      </div>
    );
  } else if (props.searchSuccess === false) {
    resultNode = <FailureCapsule />;
    resultsNode = (
      <div className="search__capsules search__capsules--feed">
        {resultNode}
      </div>
    );
  }
  userCapsuleNode = (
    <div className="cute-2-desktop cute-12-phone home__user-capsule-container">
      <UserCapsule pageUser={props.pageUser} />
    </div>
  );
  favoriteCapsuleNode = (
    <FavoritesCapsule
      user={props.user}
      favorites={props.favorites}
      pageUser={props.pageUser}
      searchSuccess={props.searchSuccess}
    />
  );

  return (
    <div className="tc__page tc__page-user--home">
      <div className="bl4__page-background"></div>
      <Header page="Home" {...props} />
      {alertNode}

      <div className="row row--1200 bl4__home">
        {userCapsuleNode}
        <div className="cute-7-desktop cute-12-phone">
          <h1 className="bl4__home-headline">
            Trending Lessons and Strategies
          </h1>
          {headerCaptionNode}
          {resultsNode}
        </div>
        <div className="cute-3-desktop show-desktop hide-phone">
          {favoriteCapsuleNode}
          {blogNode}
        </div>
      </div>
    </div>
  );
};

FreeUserHome.propTypes = {
  ui: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
  blogCapsule: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  pageUser: PropTypes.object.isRequired,
  searchSuccess: PropTypes.bool,
};

export default FreeUserHome;
