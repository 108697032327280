// @ts-nocheck FIXME
import createReactClass from 'create-react-class';

const PositionWindow = (ChildComponent, scrollPosition) => {
  return createReactClass({
    displayName: 'PositionWindow',

    componentDidMount: () => {
      setTimeout(() => {
        window.pageYOffset =
          document.documentElement.scrollTop =
          document.body.scrollTop =
            0;
      }, 10);
    },

    componentWillUnmount: () => {
      setTimeout(() => {
        window.pageYOffset =
          document.documentElement.scrollTop =
          document.body.scrollTop =
            scrollPosition;
      }, 10);
    },

    render: () => {
      return ChildComponent;
    },
  });
};

export default PositionWindow;
