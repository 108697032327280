// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import ZenScroll from 'zenscroll';
import WindowHelpers from './../../../components/WindowHelpers';

class ScrollToElementById extends React.Component {
  componentDidMount() {
    // delay to make sure the page is loaded and browser does not scroll us elsewhere afterward
    window.setTimeout(() => {
      const element = document.getElementById(this.props.id);
      if (element) {
        // on mobile, we need to account for the extra height of the sticky header
        ZenScroll.setup(
          400,
          WindowHelpers.isMobileDevice()
            ? this.props.offset + 55
            : this.props.offset
        );
        ZenScroll.to(element);
      }
    }, 300);
  }

  render() {
    return null;
  }
}

ScrollToElementById.propTypes = {
  id: PropTypes.string.isRequired,
  offset: PropTypes.number.isRequired,
};

ScrollToElementById.defaultProps = {
  offset: 20,
};

export default ScrollToElementById;
