// @ts-nocheck FIXME
import StringHelpers from '@legacy-components/components/StringHelpers';
import Role from '@legacy-components/react/V5/Account/modules/Role';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import { useState } from 'react';

const AddNewPartnerLeadModal = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [role, setRole] = useState(1);
  const [affiliation, setAffiliation] = useState('Partner');
  const [generalError, setGeneralError] = useState('');
  const addNewPLText = 'Add an existing partner leader';
  const headlineText = `Add a Partner Leader to ${props.title}`;

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handleChangeFirstName = (e) => {
    const text = e.target.value;
    setFirstName(text);

    if (!text) {
      setFirstNameError('Hold on, you need to enter a first name for the PL.');
    } else {
      setFirstNameError('');
    }
  };

  const handleChangeLastName = (e) => {
    const text = e.target.value;
    setLastName(text);

    if (!text) {
      setLastNameError('Hold on, you need to enter a last name for the PL.');
    } else {
      setLastNameError('');
    }
  };

  const handleEmailBlur = (e) => {
    if (!StringHelpers.isEmailValid(e.target.value)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
    } else {
      setEmailError('');
    }
  };

  const handleAddPl = (e) => {
    e.preventDefault();
    if (!firstName) {
      setFirstNameError('Hold on, you need to enter a first name for the PL.');
      return;
    } else {
      setFirstNameError('');
    }

    if (!lastName) {
      setLastNameError('Hold on, you need to enter a last name for the PL.');
      return;
    } else {
      setLastNameError('');
    }

    if (!StringHelpers.isEmailValid(email)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
      return;
    } else {
      setEmailError('');
    }

    props.addNewPL({
      firstName: firstName,
      lastName: lastName,
      email: email,
      partnerId: props.partnerId,
      affiliation: affiliation,
      eventId: props.eventId,
      occupationId: role,
      successCallback: props.close,
      failureCallback: setGeneralError,
      emailFailureCallback: setEmailError,
    });
  };

  const errorNode = generalError && <ErrorText error={generalError} />;

  const handleChangeRole = (roleValue) => {
    setRole(roleValue);
  };

  // disable buttons when submitting
  const btnProps = props.isLoading ? { disabled: true } : null;

  return (
    <ActionModal
      headlineText={headlineText}
      close={handleCloseModal}
      spaceFromTop={50}
    >
      <form className="participant-v5__modal-body participant-v5__participant-meeting-clip-modal">
        <label className="workspace__form-row participant-v5__modal-top-row">
          <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
            First Name
          </div>
          <FormField
            type="text"
            className={'admin-v5__event-pl-form-field'}
            disabled={props.isLoading}
            error={firstNameError}
            defaultValue={firstName}
            onChange={handleChangeFirstName}
            maxLength="256"
          />
        </label>

        <label className="workspace__form-row">
          <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
            Last Name
          </div>
          <FormField
            type="text"
            className={'admin-v5__event-pl-form-field'}
            disabled={props.isLoading}
            error={lastNameError}
            defaultValue={lastName}
            onChange={handleChangeLastName}
            maxLength="256"
          />
        </label>

        <label className="workspace__form-row">
          <div className="admin__form-label admin__form-label--inline participant-v5__participant-meeting-clip-type-label">
            Role
          </div>
          <Role
            occupations={props.occupations}
            role={parseInt(role)}
            setRole={handleChangeRole}
          />
        </label>

        <label className="workspace__form-row">
          <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
            Email
          </div>
          <FormField
            type="text"
            className={'admin-v5__event-pl-form-field'}
            disabled={props.isLoading}
            error={emailError}
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setEmailError('')}
            onBlur={handleEmailBlur}
            maxLength="256"
          />
        </label>

        <label className="workspace__form-row">
          <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
            Affiliation
          </div>
          <FormField
            type="select"
            className={'admin-v5__event-pl-form-field'}
            disabled={props.isLoading}
            defaultValue="Partner"
            onChange={(e) => setAffiliation(e.target.value)}
          >
            {['Partner', 'BL Team'].map((affiliationType, id) => {
              return (
                <option key={'affiliationType-' + id} value={affiliationType}>
                  {affiliationType}
                </option>
              );
            })}
          </FormField>
        </label>

        <div className="workspace__form-row">
          <div>
            <button
              className="v5__btn"
              onClick={handleAddPl}
              {...btnProps}
              data-testid="add-new-pl-button"
            >
              <Spinner loading={props.isLoading} />
              Add Partner Leader
            </button>
            {errorNode}
          </div>
        </div>
        <div className="workspace__form-row">
          <div className="admin-v5__event-pl-add">
            Does your partner leader already have a BL account?
          </div>
          <div className="tc__link" onClick={props.addExisting}>
            {addNewPLText}
          </div>
        </div>
      </form>
    </ActionModal>
  );
};

AddNewPartnerLeadModal.propTypes = {
  partnerId: PropTypes.number.isRequired,
  eventId: PropTypes.number,
  occupations: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  close: PropTypes.func.isRequired,
  addNewPL: PropTypes.func.isRequired,
  addExisting: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default AddNewPartnerLeadModal;
