// @ts-nocheck FIXME
import React from 'react';

class ArtifactResponsiveSetup extends React.Component {
  mq = null;
  artifactImageContainer = null;

  setupViewport = (changed) => {
    if (changed.matches) {
      this.setupMobileLayout();
      // calculate new position of overlay on resize
      window.addEventListener('resize', this.setupMobileLayout);
    } else {
      this.setDesktopLayout();
      window.removeEventListener('resize', this.setupMobileLayout);
    }
  };

  setupMobileLayout = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = document.querySelector(
      '.artifact-overlay__mobile-header'
    ).offsetHeight;
    const detailsHeight = document.querySelector(
      '.artifact-overlay__details-container'
    ).offsetHeight;

    const artifactContainerHeight =
      windowHeight - (detailsHeight + headerHeight);
    const styleDeclaration = 'height:' + artifactContainerHeight + 'px;';

    this.artifactImageContainer.setAttribute('style', styleDeclaration);
  };

  setDesktopLayout = () => {
    this.artifactImageContainer.setAttribute('style', 'height:auto;');
  };

  componentDidMount() {
    // measure page
    this.mq = window.matchMedia('(max-width: 48em)');
    this.artifactImageContainer = document.querySelector(
      '.artifact-overlay__image-container'
    );

    // if the page is currently responsive
    if (this.mq.matches) {
      window.addEventListener('resize', this.setupMobileLayout);
      this.setupMobileLayout();
    }

    // if the window size changes
    this.mq.addListener(this.setupViewport);
  }

  componentWillUnmount() {
    this.mq.removeListener(this.setupViewport);
    window.removeEventListener('resize', this.setupViewport);
    window.removeEventListener('resize', this.setupMobileLayout);
    this.artifactImageContainer.removeAttribute('style');
  }

  render() {
    return null;
  }
}

export default ArtifactResponsiveSetup;
