// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import createReactClass from 'create-react-class';
import BrowserStore from './../../stores/V2/BrowserStore';
import AbsoluteAlert from './../Alerts/Absolute';
import NavigationLink from './../NavigationLink';
import ChallengeStatus from './Strategy/ChallengeStatus';
import MobileChallengeMetaSubHead from './Strategy/MobileChallengeMetaSubHead';
import SearchBar from './Strategy/SearchBar';
import SearchTab from './Strategy/SearchTab';
import WideMetaStrategyCapsuleV2 from './Strategy/WideMetaStrategyCapsule';

const ChallengeMetaStrategiesV2 = createReactClass({
  displayName: 'ChallengeMetaStrategiesV2',
  token: null,

  getAnalyticsTrackingLabel: function () {
    return 'from_sb_challenge';
  },

  getInitialState: function () {
    BrowserStore.actionDispatchSync('init', this.props);
    return BrowserStore.getState();
  },

  componentDidMount: function () {
    this.token = BrowserStore.subscribe(this.onStoreChange);
  },

  componentWillUnmount: function () {
    BrowserStore.unsubscribe(this.token);
  },

  handleShowAlert: function (type, message) {
    BrowserStore.actionDispatchSync('setAlert', {
      alert: message,
      alertType: type,
    });
  },

  handleCloseAlert: function () {
    BrowserStore.actionDispatchSync('setAlert', { alert: null, alertType: '' });
  },

  onStoreChange: function () {
    // eslint-disable-next-line react/no-is-mounted
    if (this.isMounted()) {
      this.setState(BrowserStore.getState());
    }
  },

  render: function () {
    // Used to pass in the correct analytics label for children:
    const analyticsTrackingLabel = this.getAnalyticsTrackingLabel();

    // build the alert banner if needed
    let alertNode = null;
    if (this.state.alert) {
      alertNode = (
        <AbsoluteAlert
          message={this.state.alert}
          type={this.state.alertType}
          handleClose={this.handleCloseAlert}
          cssClasses={{ container: 'alert-banner--fixed' }}
        />
      );
    }

    // build the list of meta strategy capsules
    const metaStrategyNodes = this.props.metaStrategies.map(
      function (metaStrategy) {
        return (
          <WideMetaStrategyCapsuleV2
            capsuleType={'challengeMetaStrategy'}
            key={'metaStrategy' + metaStrategy.id}
            url={
              '/coaching/lab/strategy-browser/teaching-challenge/' +
              this.props.challenge.id +
              '/' +
              this.props.challenge.seo_url +
              '/meta-strategy/' +
              metaStrategy.id +
              '?from=sb_meta_strategy_title'
            }
            {...this.props}
            metaStrategy={metaStrategy}
            analyticsTrackingLabel={analyticsTrackingLabel}
          />
        );
      }.bind(this)
    );

    // set up category tabs and url bit to go back to the correct strategy browser
    let currentCategoryUrlBit = null;
    let categoryTabNodes;
    if (this.props.categories) {
      categoryTabNodes = this.props.categories.map(function (category) {
        let activeClass = '';
        if (category.id == this.props.selectedCategory) {
          if (!this.state.searchBarOpened) {
            activeClass = ' active';
          }
          currentCategoryUrlBit = category.seo_url;
        }

        return (
          <NavigationLink
            key={'category-' + category.id}
            className={'browser__tab tc__tab tc__headline-4' + activeClass}
            url={'/coaching/lab/strategy-browser-' + category.seo_url}
          >
            {category.name}
          </NavigationLink>
        );
      }, this);
    }

    // set class of last breadcrumb based on the challenge's status
    const statusClass = this.props.challengeStatus
      ? ' browser-v2__crumb--' + this.props.challengeStatus
      : '';

    // if there is a challenge description show it
    let challengeDescriptionNode = null;
    if (this.props.challenge.description) {
      challengeDescriptionNode = (
        <div className="browser-v2__challenge-description">
          {this.props.challenge.description}
        </div>
      );
    }

    // show the TC header
    const headerNode = <Header {...this.props} page="StrategyBrowser" />;

    return (
      <div className="challenge-meta-strategies">
        {alertNode}
        <div>{headerNode}</div>

        <div className="browser-v2__wrap--tabs show-desktop hide-phone">
          <div className="tc__center-text row">
            <div className="browser__subject-tabs cute-12-desktop">
              {categoryTabNodes}
              <SearchTab />
            </div>
          </div>
        </div>

        <SearchBar
          challenge={this.props.challenge.nickname}
          challengeId={this.props.challenge.id}
        />

        <div className="browser-v2__wrap--breadcrumbs show-desktop hide-phone">
          <div className="row">
            <div className="cute-12-desktop">
              <div className="browser-v2__crumb">
                <NavigationLink
                  className="browser-v2__crumb-link"
                  title={this.props.sga.name}
                  url={
                    '/coaching/lab/strategy-browser-' + currentCategoryUrlBit
                  }
                >
                  {this.props.sga.name}
                </NavigationLink>
              </div>
              <div
                className={'browser-v2__crumb' + statusClass}
                title={this.props.challenge.nickname}
              >
                {this.props.challenge.nickname}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <MobileChallengeMetaSubHead {...this.props} />

          <div className="cute-12-desktop">
            <div className="browser-v2__wrap--challenge row show-desktop hide-phone">
              <div className="cute-12-desktop">
                <div className="tc__headline-2">
                  {this.props.challenge.nickname}
                </div>
                {challengeDescriptionNode}

                <ChallengeStatus
                  {...this.props}
                  className={'browser-v2__challenge-state--btn'}
                  onShowAlert={this.handleShowAlert}
                  analyticsTrackingLabel={analyticsTrackingLabel}
                  showCustomStrategyLink={true}
                />
              </div>
            </div>
            <div className="browser-v2__meta-strategies-container">
              {metaStrategyNodes}
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default ChallengeMetaStrategiesV2;
