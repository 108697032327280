// @ts-nocheck FIXME
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import PropTypes from 'prop-types';

const DeleteGroupConfirmationModal = (props) => {
  // text depends on whether we are in Basic or Advanced mode
  let textNode = (
    <div className="v5__body-copy edit-event__modal-row">
      Facilitator assignments and event materials links attached to these groups
      will be lost. This action cannot be undone.
    </div>
  );
  if (props.useAdvancedMode) {
    textNode = (
      <div className="v5__body-copy edit-event__modal-row">
        All sessions, facilitator assignments, and event materials links
        attached to these groups will be lost. This action cannot be undone.
      </div>
    );
  }

  return (
    <ActionModal
      headlineText="Delete Group?"
      close={props.close}
      spaceFromTop={50}
    >
      {textNode}

      <div className="edit-event__modal-btn-row">
        <button className="v5__btn v5__btn--red" onClick={props.confirm}>
          Delete Group
        </button>
      </div>
    </ActionModal>
  );
};

DeleteGroupConfirmationModal.propTypes = {
  useAdvancedMode: PropTypes.bool,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default DeleteGroupConfirmationModal;
