// @ts-nocheck FIXME
const TmlProgressChart = (props) => {
  // instructional design challenge measurements use a scale of 1-10 instead of a percentage
  const isID = props.isInstructionalDesign || false;

  const chartHeight = props.height || 200;

  const baseline = props.baseline;
  const baselineHeight = Math.round((baseline / 100) * chartHeight);

  const goal = props.goal || 0;
  const goalHeight = Math.round((goal / 100) * chartHeight);

  const measurementPercent = props.measurementPercent || 0;
  const measurementValue = props.measurementValue || 0;
  const measurementBarHeight = Math.round(
    (measurementPercent / 100) * chartHeight
  );

  const baselineColor = '#1b7ee0';
  const goalColor = '#c2df21';
  const goalLegendColor = '#8ba911';
  const measurementColor = '#f5972e';

  // set what the bar represents based on the info passed in
  let bar;
  let multiColumnClass = '';
  if (measurementPercent) {
    let barHeight = measurementBarHeight;
    // if height is 100%, then substract top and bottom border pixels
    if (barHeight == chartHeight) {
      barHeight -= 2;
    }
    // if we have the total # students,
    // we calculate and display the absolute # measured based on the measurement %
    const numStudents = measurementValue ? ' (' + measurementValue + ')' : '';
    bar = {
      color: measurementColor,
      height: barHeight,
      label: isID ? measurementPercent / 10 : measurementPercent,
      labelCaption: 'Student Success',
      mobileLabel: isID
        ? 'This Strategy Attempt: ' + measurementPercent / 10
        : 'This Strategy Attempt: ' + measurementPercent + '%',
    };
  } else if (baseline != null) {
    multiColumnClass = ' chart-v2__container--multi-col';
    let barHeight = baselineHeight;
    // if height is 100%, then substract top and bottom border pixels
    if (barHeight == chartHeight) {
      barHeight -= 2;
    }
    bar = {
      color: baselineColor,
      height: barHeight,
      label: isID ? baseline / 10 : baseline,
      labelCaption: 'baseline',
    };
  } else if (goal) {
    multiColumnClass = ' chart-v2__container--multi-col';
    let barHeight = goalHeight;
    // if height is 100%, then substract top and bottom border pixels
    if (barHeight == chartHeight) {
      barHeight -= 2;
    }
    bar = {
      color: goalColor,
      height: barHeight,
      label: isID ? goal / 10 : goal,
      labelCaption: 'goal',
    };
  }

  // set up the measurement bar and the label under it
  let barNode = null;
  let labelNode = null;
  let mobileLabelNode = null;
  if (bar) {
    barNode = (
      <div className={'chart-v2__total'} style={{ height: chartHeight + 'px' }}>
        <div
          className="chart-v2__value"
          style={{ height: bar.height + 'px', backgroundColor: bar.color }}
        ></div>
      </div>
    );
    labelNode = (
      <div className="chart-v2__label chart-v2__label--ppp show-desktop hide-phone">
        <div>{bar.label} %</div>
        <div>{bar.labelCaption}</div>
      </div>
    );
    if (bar.mobileLabel) {
      mobileLabelNode = (
        <div
          className="chart-v2__label hide-desktop show-phone"
          style={{ color: bar.color }}
        >
          <span>{bar.mobileLabel}</span>
          <span
            className="chart-v2__legend-example"
            style={{ borderColor: bar.color }}
          ></span>
        </div>
      );
    }
  }

  let goalLine;
  let goalLegend;
  let mobileGoalLegend;
  if (goal) {
    goalLine = (
      <div
        className="chart-v2__line chart-v2__line--goal chart-v2__line--ppp"
        style={{ borderColor: goalLegendColor }}
      ></div>
    );
    goalLegend = (
      <div
        className="chart-v2__legend chart-v2__legend--ppp  show-desktop hide-phone"
        style={{ color: goalLegendColor }}
      >
        Goal: {isID ? goal / 10 : goal + '%'}
      </div>
    );
    mobileGoalLegend = (
      <div
        className="chart-v2__label hide-desktop show-phone"
        style={{ color: goalLegendColor }}
      >
        <span>Goal: {isID ? goal / 10 : goal + '%'}</span>
        <span
          className="chart-v2__legend-example"
          style={{ borderColor: goalLegendColor }}
        ></span>
      </div>
    );
  }
  let baselineLine;
  let baselineLegend;
  let mobileBaselineLegend;
  if (baseline != null) {
    baselineLine = (
      <div
        className="chart-v2__line chart-v2__line--baseline chart-v2__line--ppp"
        style={{ borderColor: baselineColor }}
      ></div>
    );
    baselineLegend = (
      <div
        className="chart-v2__legend chart-v2__legend--ppp show-desktop hide-phone"
        style={{ color: baselineColor }}
      >
        baseline: {isID ? baseline / 10 : baseline + '%'}
      </div>
    );
    mobileBaselineLegend = (
      <div
        className="chart-v2__label hide-desktop show-phone"
        style={{ color: baselineColor }}
      >
        <span>Baseline: {isID ? baseline / 10 : baseline + '%'}</span>
        <span
          className="chart-v2__legend-example"
          style={{ borderColor: baselineColor }}
        ></span>
      </div>
    );
  }

  // only show the labels under the chart if showLabels is true
  let labelsNode = null;
  if (props.showLabels) {
    labelsNode = (
      <div className="chart-v2__labels">
        {labelNode}
        {mobileLabelNode}
        {mobileGoalLegend}
        {mobileBaselineLegend}
      </div>
    );
  }
  let baselineLegendsNode = (
    <div
      className="chart-v2__legend-container"
      style={{ bottom: baselineHeight + 'px' }}
    >
      {baselineLegend}
      {baselineLine}
    </div>
  );
  let goalLegendsNode = (
    <div
      className="chart-v2__legend-container"
      style={{ bottom: goalHeight + 'px' }}
    >
      {goalLegend}
      {goalLine}
    </div>
  );

  if (!measurementPercent) {
    baselineLegendsNode = null;
    goalLegendsNode = null;
  }

  return (
    <div className={'chart-v2__container' + multiColumnClass}>
      <div className="chart-v2" style={{ height: chartHeight + 'px' }}>
        <div className="chart-v2__measurements">{barNode}</div>
        {baselineLegendsNode}
        {goalLegendsNode}
      </div>
      {labelsNode}
    </div>
  );
};

export default TmlProgressChart;
