import ErrorPage from '@components/ErrorPage/ErrorPage';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import GoogleAnalytics from '@components/ThirdPartyScripts/GoogleAnalytics';
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import { HeaderProvider } from '@legacy-components/context/HeaderContext';
import Layers from '@legacy-components/react/LayerManager/Layers';
import PendoContainer from '@legacy-components/react/V5/Pendo/PendoContainer';
import { UnifiedFooter } from 'betterlesson-library-react';
import { ComponentType, useEffect, useState } from 'react';

type Props = {
  Component: ComponentType<any>;
  store?: any;
};

export default function LegacyLabPageWrapper({
  Component,
  store,
}: Props): JSX.Element {
  const { pageData, isLoading, error } = useLegacyLabContext();
  const [isStoreInitialized, setIsStoreInitialized] = useState(false);
  const [props, setProps] = useState(pageData || {});
  let previousStore: any = null;

  useEffect(() => {
    setProps(pageData);
  }, [pageData]);

  const updateProps = () => {
    setProps(Object.assign({}, props, store.getState()));
  };

  const initializeStore = () => {
    if (!store && previousStore) {
      previousStore.unsubscribe(previousStore.getName() + '.changed');
    }

    if (store && previousStore && previousStore.getName() !== store.getName()) {
      previousStore.unsubscribe(previousStore.getName() + '.changed');
    }

    if (!store) {
      setIsStoreInitialized(true);
      return;
    }

    if ('init' in store) {
      store.init(pageData);
    }

    store.subscribeOnly(updateProps);
    previousStore = store;
    setProps(Object.assign({}, props, store.getState()));
    setIsStoreInitialized(true);
  };

  useEffect(() => {
    if (!isLoading) {
      initializeStore();
    }
  }, [isLoading]);

  const renderFooter = () => {
    if (!pageData || pageData.hide_footer) {
      return <></>;
    }

    return <UnifiedFooter />;
  };

  const renderPage = () => {
    if (error) {
      return <ErrorPage />;
    }

    if (isLoading || !isStoreInitialized) {
      return <LoadingPage />;
    }

    return <Component {...props} />;
  };

  const renderThirdPartyScripts = () => {
    if (error || isLoading) {
      return <></>;
    }

    return (
      <>
        <PendoContainer />
        <GoogleAnalytics />
      </>
    );
  };

  return (
    <HeaderProvider
      {...{
        user: pageData.user || {},
        header_participant: pageData.header_participant || null,
        keepHeaderParticipant: pageData.keepHeaderParticipant || false,
      }}
    >
      <div className="app__content">
        <Layers />
        {renderThirdPartyScripts()}
        <div className="app__page">{renderPage()}</div>
        {renderFooter()}
      </div>
    </HeaderProvider>
  );
}
