// @ts-nocheck FIXME
import React from 'react';
import ArtifactComment from './ArtifactComment';

class Artifact extends React.Component {
  render() {
    const artifactId = this.props.id ? this.props.id : '';
    const tmlLoopId = this.props.tml_loop_id ? this.props.tml_loop_id : '';
    let previewImageNodes;

    if (this.props.preview_type == 'movie') {
      previewImageNodes = (
        <div className="portfolio__artifact-movie-data">
          <div className="portfolio__artifact-image">
            <img src={this.props.preview} alt={this.props.filename} />
          </div>
          <div className="clearfix"></div>
          <div className="">
            <div className="portfolio__artifact-movie-icon tc-file-video"></div>
            <div className="portfolio__artifact-movie-url">
              {this.props.publicUrl}
            </div>
          </div>
        </div>
      );
    } else {
      if (this.props.content.preview && this.props.filename) {
        previewImageNodes = this.props.content.preview.map(
          function (preview, index) {
            // Only display up to 25 preview pages for every artifact:
            if (index <= 24) {
              const artifactKey = this.props.filename.replace(/[. ,:-]+/g, '-');

              return (
                <div
                  className="portfolio__artifact-image"
                  key={artifactKey + '-' + index}
                >
                  <img src={preview} alt={this.props.filename} />
                </div>
              );
            }
          }.bind(this)
        );
      }
    }

    const artifactBorderClass = this.props.last_of_type ? ' no-border' : '';
    return (
      <div className={'portfolio__artifact' + artifactBorderClass}>
        {previewImageNodes}

        <div className="artifact__type artifact__type--print">
          <div className="artifact__type-caption">{this.props.type_name}</div>
          <div className="artifact__flag-end artifact__flag-end--print tc-flag-end"></div>
        </div>

        <div className="portfolio__artifact-filename">
          {this.props.filename}
        </div>
        <div className="portfolio__artifact-notes">
          <span className="portfolio__artifact-notes-declaration">
            Author&rsquo;s Notes:
          </span>
          <span>{this.props.notes}</span>
        </div>
        {this.props.comments.map(function (comment) {
          return <ArtifactComment key={'comment' + comment.id} {...comment} />;
        })}
      </div>
    );
  }
}

export default Artifact;
