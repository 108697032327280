// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import { Router } from './../../components/Router';
import Utils from './../../components/StaticUtils';
import AbsoluteAlert from './../Alerts/AbsoluteStateless';
import NavigationLink from './../NavigationLink';
import CoverSection from './CoverSection';
import FreeFormSection from './FreeFormSection';
import GrowthAreasSection from './GrowthAreasSection/GrowthAreasSection';
import Sortable from './Sortable';
import SpotlightsSection from './SpotlightsSection/SpotlightsSection';
import NoteModal from './TeacherCohortSection/NoteModal';
import TeacherCohortSection from './TeacherCohortSection/TeacherCohortSection';
// toggle options

const selectedSections = {
  COVER: CoverSection,
  GROWTH_AREAS: GrowthAreasSection,
  TEACHER_COHORT: TeacherCohortSection,
  SPOTLIGHTS: SpotlightsSection,
  HIGHLIGHTS: FreeFormSection,
  IMPACT_INDICATORS: FreeFormSection,
  TEACHER_MORALE: FreeFormSection,
  STUDENT_VOICE: FreeFormSection,
};

const PartnerPortfolioTool = (props) => {
  /* REFERENCE VALUES */
  const maxSectionsNameCountValue = 110;
  let portfolioName = props.portfolio.name;
  let portfolioSectionInput = null;
  let sectionNameCharacterCount = 0;

  /* EVENT HANDLERS */
  const handleSectionChange = (type) => {
    // inhibit functionaltiy if editing section name
    if (props.ui.activeSectionNameEditId) {
      return;
    }

    Router.route(
      '/bl/v1/partner/portfolio/' +
        props.portfolio.id +
        '/edit/' +
        type +
        '?from=pp_admin_view'
    );
  };

  const handleToggleVisible = (id, visibility, sectionName) => {
    // inhibit functionaltiy if editing section name
    if (props.ui.activeSectionNameEditId) {
      return;
    }

    props.toggleSectionVisibility({
      sectionId: id,
      sectionName: sectionName,
      portfolioId: props.portfolio.id,
      visible: visibility,
    });
  };

  const handleSectionNameChange = (sectionId) => {
    props.editSectionName({
      sectionId: sectionId,
      sectionName: portfolioSectionInput.value,
      portfolioId: props.portfolio.id,
    });
  };

  const handlePortfolioNameToggle = (e) => {
    e.preventDefault;
    props.togglePortfolioName();
  };

  const handlePortfolioNameChange = () => {
    props.updatePortfolioName(portfolioName.value);
  };

  const handleDisplaySectionNameEditing = (sectionId, sectionName) => {
    props.displaySectionNameEditing({
      sectionId: sectionId,
      sectionName: sectionName,
      sectionNameLength: sectionName.length,
    });
  };

  const handleHideSectionNameEditing = () => {
    props.hideSectionNameEditing();
  };

  const updateSectionNameInput = (sectionName) => {
    const countIndicator = document.querySelector(
      '.ppt-nav__element-name-char-count'
    );
    const underClass = 'ppt-nav__element-name-char-count--under';
    const overClass = 'ppt-nav__element-name-char-count--over';

    const remaining =
      maxSectionsNameCountValue -
      (sectionNameCharacterCount -
        sectionName.length +
        portfolioSectionInput.value.length);

    if (remaining <= 0) {
      Utils.removeClass(countIndicator, underClass);
      Utils.addClass(countIndicator, overClass);
    } else {
      Utils.removeClass(countIndicator, overClass);
      Utils.addClass(countIndicator, underClass);
    }

    document.querySelector(
      '.ppt__element-name-char-count-remaining'
    ).innerHTML = remaining;
  };

  /* RENDERING LOGIC */
  const alertNode = (
    <AbsoluteAlert
      {...props.alert}
      cssClasses={{ container: 'alert-banner--fixed-under-header' }}
    />
  );

  // show the teacher note modal if necessary
  let modalNode = null;
  if (props.ui.TEACHER_COHORT.currentTeacher) {
    modalNode = (
      <NoteModal
        {...props.ui.TEACHER_COHORT.currentTeacher}
        buttonsDisabled={props.ui.TEACHER_COHORT.modalButtonsDisabled}
        setCurrentTeacher={props.setCurrentTeacher}
        editTeacher={props.editTeacher}
      />
    );
  }

  // set up the list of sections + the current section to show
  const lastModified = dateformat(props.portfolio.lastmodified, 'mm/dd/yyyy');
  let currentSectionNode = null;
  let coverMenuNode = null;

  const freeFormSectionTypes = [
    'TEACHER_MORALE',
    'IMPACT_INDICATORS',
    'HIGHLIGHTS',
    'STUDENT_VOICE',
  ];

  const sectionNodes = props.sections.map((section, i) => {
    // Create Section Count Captions
    let sectionCounts = null;
    let growthAreaCounts = null;
    let selectionBackground = '';

    if (section.visible) {
      if (section.type === 'GROWTH_AREAS') {
        growthAreaCounts =
          String(section.visible_subjects_count) +
          ' content areas | ' +
          String(section.visible_growth_areas_count) +
          ' growth areas';
      }
      if (
        section.type === 'TEACHER_COHORT' &&
        section.visible_teachers_count != 0
      ) {
        sectionCounts = String(section.visible_teachers_count) + ' teachers';
      }
      if (
        ['COVER', 'TEACHER_COHORT'].indexOf(section.type) == -1 &&
        section.visible_entries_count != 0
      ) {
        sectionCounts = String(section.visible_entries_count) + ' elements';
      }
    }

    // handle preparation of which section to show
    if (section.type === props.currentSection) {
      const SelectedSection = selectedSections[props.currentSection];
      currentSectionNode = (
        <SelectedSection
          section={section}
          portfolio={props.portfolio}
          editEntry={props.editEntry}
          deleteEntry={props.deleteEntry}
          toggleSubjectPanel={props.toggleSubjectPanel}
          toggleSubjectVisibility={props.toggleSubjectVisibility}
          sortSubjects={props.sortSubjects}
          toggleGrowthAreaVisibility={props.toggleGrowthAreaVisibility}
          sortGrowthAreaEntries={props.sortGrowthAreaEntries}
          sortSpotlightEntries={props.sortSpotlightEntries}
          toggleSpotlightTypePanel={props.toggleSpotlightTypePanel}
          sortFreeFormEntries={props.sortFreeFormEntries}
          sortTeachers={props.sortTeachers}
          editTeacher={props.editTeacher}
          refreshTeacherCohortSection={props.refreshTeacherCohortSection}
          setCurrentTeacher={props.setCurrentTeacher}
          refreshGrowthAreaSection={props.refreshGrowthAreaSection}
          toggleSaveIcon={props.toggleSaveIcon}
          saveIcon={props.saveIcon}
          ui={props.ui}
        />
      );
      selectionBackground = ' ppt-nav__section-row--selected';
    }
    const visibilityIcon = section.visible ? (
      <div className="ppt__visibility-icon selected">
        <div className="tc-check" />
      </div>
    ) : (
      <div className="ppt__visibility-icon"></div>
    );

    let dragIconNode;
    if (section.type != 'COVER') {
      dragIconNode = (
        <div className="tc-dots-8 ppt__drag-icon ppt__drag-icon--nav"></div>
      );
    }

    // render edit button if freeform section
    let editTitleButton = null;
    if (freeFormSectionTypes.indexOf(section.type) > -1) {
      editTitleButton = (
        <div
          onClick={(e) => {
            handleDisplaySectionNameEditing(section.id, section.name);
          }}
          className="ppt-nav__section-name-edit"
        >
          <div className="tc-pencil"></div>
        </div>
      );
    }

    let node = (
      <div
        key={'section-' + section.id + '-' + i}
        data-id={section.id}
        className={'ppt-nav__section-row' + selectionBackground}
      >
        {dragIconNode}
        <div
          className="ppt-element__visibility"
          onClick={() => {
            handleToggleVisible(section.id, section.visible, section.name);
          }}
        >
          {section.type != 'COVER' && visibilityIcon}
        </div>
        <a
          onClick={() => handleSectionChange(section.type)}
          className={'ppt-nav__section-name ' + section.type}
        >
          {section.name}
        </a>
        <div className="ppt-nav__element-counts tc__caption-copy">
          <div>{growthAreaCounts}</div>
          <div>{sectionCounts}</div>
        </div>
        {editTitleButton}
      </div>
    );

    if (props.ui.activeSectionNameEditId == section.id) {
      for (const section of props.sections) {
        sectionNameCharacterCount =
          sectionNameCharacterCount + section.name.length;
      }

      const remaining = maxSectionsNameCountValue - sectionNameCharacterCount;

      let countClass = ' ppt-nav__element-name-char-count--under';
      if (remaining <= 0) {
        countClass = ' ppt-nav__element-name-char-count--over';
      }

      node = (
        <div key={'section-' + section.id + '-' + i}>
          <div className={'ppt-nav__element-name-char-count' + countClass}>
            <span className="ppt__element-name-char-count-remaining">
              {remaining}
            </span>{' '}
            navigation characters remaining
          </div>
          <form
            className="ppt-nav__element-name-form"
            key={'form-' + section.id + '-' + i}
          >
            <input
              className="ppt-nav__element-name-input tc__input"
              type="text"
              onChange={() => {
                updateSectionNameInput(section.name);
              }}
              key="section-name-input"
              ref={(ref) => {
                portfolioSectionInput = ref;
              }}
              defaultValue={section.name}
            />

            <div>
              <a
                onClick={() => {
                  handleSectionNameChange(section.id);
                }}
                className="tc__btn tc__btn--blue tc__btn--small ppt-nav__element-name-button"
              >
                Save
              </a>
              <a onClick={handleHideSectionNameEditing} className="tc__link">
                Cancel
              </a>
            </div>
          </form>
        </div>
      );
    }

    if (section.type == 'COVER') {
      coverMenuNode = node;
      return;
    }

    return node;
  });

  // attach sorting functionality to sections
  const SortableSections = Sortable(
    <ul id="dragContainer" className="ppt__sidebar-nav">
      {sectionNodes}
    </ul>,
    '#dragContainer',
    '#ppt-nav',
    props.sortSections,
    { sectionNameEditing: props.ui.activeSectionNameEditId }
  );
  const editingSectionNameClass = props.ui.activeSectionNameEditId
    ? ' ppt-nav--section-editing'
    : '';
  return (
    <div>
      <Header page="PartnerPortfolioTool" {...props} />
      {alertNode}
      {modalNode}

      <div className="tc__body-copy">
        <div id="ppt-nav" className={'ppt-nav' + editingSectionNameClass}>
          <div className="tc__headline-5">
            <NavigationLink
              url={'/bl/v1/partners/portfolios?from=pp_admin_view'}
              className="ppt-nav__back tc-short-arrow"
            ></NavigationLink>
            {props.portfolio.partnerName}
          </div>
          <div className="ppt-nav__section-list">
            <div className="tc__headline-5">
              {props.ui.PORTFOLIO.name.editing ? (
                <div>
                  <div className="ppp-nav__title-edit-input-container">
                    <input
                      autoFocus={true}
                      type="text"
                      defaultValue={props.portfolio.name}
                      ref={(ref) => (portfolioName = ref)}
                    />
                  </div>
                  <a
                    className="tc__link"
                    href="#save"
                    onClick={handlePortfolioNameChange}
                  >
                    Save
                  </a>
                  <a
                    className="tc__link ppt-nav__cancel-change-name"
                    href="#cancel"
                    onClick={handlePortfolioNameToggle}
                  >
                    Cancel
                  </a>
                </div>
              ) : (
                <div className="tc__link tc__link--ppp-title">
                  <span>{props.portfolio.name}</span>
                  <div
                    onClick={handlePortfolioNameToggle}
                    className="ppt-nav__portfolio-title-edit"
                  >
                    <div className="tc-pencil"></div>
                  </div>
                </div>
              )}
            </div>
            <div className="ppt-nav__last-modified tc__caption-copy">
              Last Modified {lastModified}
            </div>
            {coverMenuNode}
            <SortableSections />
            <a
              href={
                '/coaching/lab/partner/portfolio/' +
                props.portfolio.guid +
                '?from=pp_admin_view'
              }
              target="_blank"
              rel="noreferrer"
            >
              <div className="ppt-nav__preview tc__link--white">Preview</div>
            </a>
          </div>
        </div>
        <div id="ppt-section__container" className="ppt-section__container">
          {currentSectionNode}
        </div>
      </div>
    </div>
  );
};

PartnerPortfolioTool.propTypes = {
  portfolio: PropTypes.object.isRequired,
  updatePortfolioName: PropTypes.func.isRequired,
  togglePortfolioName: PropTypes.func.isRequired,
};

export default PartnerPortfolioTool;
