// @ts-nocheck FIXME
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import React from 'react';
import UserPhoto from './../../../V5/Common/UserPhoto';
import RegistrationSteps from './RegistrationSteps';

const RegistrationForm = (props) => {
  const registerForEvent = () => {
    // If the user needs to select a group then display the select group form
    // Otherwise there will only be a single current session valid for the user
    // Either because the user already has a group id or because there aren't groups
    // So select the only valid session
    if (props.selectGroupCallback) {
      props.selectGroupCallback(true);
    } else {
      props.setIsLoading(true);
      const event_session =
        props.event.event_groups.length > 1
          ? props.event.current_sessions.find(
              (session) =>
                props.eventParticipant &&
                session.group_id === props.eventParticipant.group_id
            )
          : props.event.current_sessions[0];
      props.handleRegisterForEvent({
        event_id: props.event.id,
        user_id: props.user.id,
        session_id: event_session.id,
        group_id: event_session.group_id,
      });
    }
  };
  const handleRegisterForEvent = (e) => {
    e.preventDefault();
    registerForEvent();
  };

  const handleRegisterForCohort = (e) => {
    props.setIsLoading(true);
    e.preventDefault();
    props.handleRegisterForCohort({
      cohort_id: props.cohort.id,
      user_id: props.user.id,
      userType: props.userType,
    });
  };

  const changeUsers = () => {
    props.logout();
    props.setHasAccount(true);
    props.setIsLoggedIn(false);
    props.clearErrors();
    props.setRecaptchaResponse(null);
  };

  let userConfirmationNode = null;

  React.useEffect(() => {
    if (props.check_in_immediately) {
      registerForEvent();
    }
  }, []);

  if (props.isLoggedIn) {
    const notYouNode = !props.check_in_immediately && (
      <div className="v5__comment-copy">
        Not you?{' '}
        <span className="tc__link" onClick={changeUsers}>
          {' '}
          Log in
        </span>
      </div>
    );
    userConfirmationNode = (
      <React.Fragment>
        <UserPhoto
          src={props.user.photo}
          firstName={props.user.first_name}
          lastName={props.user.last_name}
          userId={props.user.id}
          diameter={40}
          className="search__owner-image left"
        />
        <span>
          <div className="registration-v5__user-name">{`${props.user.first_name} ${props.user.last_name}, ${props.user.email}`}</div>
          {notYouNode}
        </span>
      </React.Fragment>
    );
  } else if (props.accountCreated) {
    userConfirmationNode = (
      <React.Fragment>
        <span className="check-icon"></span>
        <div>{`${props.user.first_name} ${props.user.last_name}, ${props.user.email}`}</div>
      </React.Fragment>
    );
  }

  let accountCreatedNode = null;

  if (props.ui.showAccountCreated) {
    accountCreatedNode = (
      <div className="v5__inline-success">
        <span className="tc-check"></span>
        <span> </span>
        Account created
      </div>
    );
  }

  let eventDescriptionNode = null;
  let buttonText = 'Register';
  let registrationHeadline;
  let registrationSubHeadline;
  let registrationSteps;
  if (props.cohort) {
    registrationHeadline = 'Get your Personal Coach';
    registrationSubHeadline = 'Register to get matched to a coach';
    eventDescriptionNode = (
      <React.Fragment>
        <div className="cute-12-desktop v5__body-copy">
          To help us match you to a BetterLesson coach, you will need to answer
          a few questions about your teaching experience and availability for
          coaching.
        </div>
        <div className="cute-12-desktop v5__body-copy">
          The registration process will take approximately 5 minutes to
          complete.
        </div>
      </React.Fragment>
    );
    registrationSteps = (
      <RegistrationSteps
        hasAccount={props.hasAccount}
        stepTwoActive={true}
        registrationProcess="cohort"
      />
    );

    buttonText = 'Start Registration';
  } else if (props.event) {
    registrationHeadline = 'Check In';
    registrationSubHeadline = `Welcome, ${props.user.first_name}!`;
    buttonText = 'Check In';
    eventDescriptionNode = (
      <div className="cute-12-desktop v5__body-copy">
        <p>Thanks for joining us at today&apos;s event!</p>
        <p>If prompted, please complete a short self-assessment.</p>
        <p>Then you’ll be able to join the session.</p>
      </div>
    );
  }

  const btnDisabledClass = !!props.ui.loading;

  const btnProps = {
    disabled: btnDisabledClass,
  };

  return (
    <div className="registration-v5-event__register">
      <div className="registration-v5-event__register-header v5__heading-3">
        {registrationHeadline}
      </div>
      <div className="registration-v5-event__register-body v5__body-copy">
        {registrationSteps}
        <div className="registration-v5-event__register-for row">
          {accountCreatedNode}
          <div className="cute-12-desktop v5__heading-5">
            {registrationSubHeadline}
          </div>

          <div className="cute-12-desktop v5__body-copy">
            {userConfirmationNode}
          </div>

          {eventDescriptionNode}
        </div>
        <div className="registration-v5-event__register-controls">
          <button
            {...btnProps}
            className="v5__btn"
            onClick={
              props.cohort ? handleRegisterForCohort : handleRegisterForEvent
            }
          >
            {buttonText}
            <Spinner
              className="admin__littlespinner"
              loading={btnDisabledClass}
            />
          </button>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
