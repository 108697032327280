// @ts-nocheck FIXME
import Utils from './DynamicUtils';

const Auth = {
  canEditData: (user, teacherId) => {
    if (!user || !user.auth_roles) {
      return false;
    }

    // if there is a teacher id, then only that teacher has permission to edit data
    if (teacherId) {
      return (
        Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
          'BL_SERVICES_MANAGER',
          'BL_CONTRACT_COACH',
          'ADMIN',
          'TC_DATA_EDITOR',
          'BL_MANAGER',
        ]) ||
        (user.id === teacherId && user.auth_roles.includes('TC_TEACHER'))
      );
    } else {
      return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
        'TC_TEACHER',
        'BL_SERVICES_MANAGER',
        'BL_CONTRACT_COACH',
        'ADMIN',
        'TC_DATA_EDITOR',
        'BL_MANAGER',
      ]);
    }
  },

  hasCoachAccess: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }

    return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
      'BL_SERVICES_MANAGER',
      'BL_CONTRACT_COACH',
      'ADMIN',
      'TC_DATA_EDITOR',
      'BL_MANAGER',
    ]);
  },

  hasCoachAccessMinusDC: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }

    return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
      'BL_SERVICES_MANAGER',
      'BL_CONTRACT_COACH',
      'ADMIN',
      'TC_DATA_EDITOR',
      'BL_MANAGER',
    ]);
  },

  canCreateUsers: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }

    // Only super admins and user managers can create users
    return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
      'ADMIN',
      'BL_MANAGER',
    ]);
  },

  canManagePortfolios: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }
    return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
      'ADMIN',
      'TC_DATA_EDITOR',
      'BL_MANAGER',
    ]);
  },

  isContentCurator: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }
    return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
      'ADMIN',
      'TC_CONTENT_CURATOR',
      'BL_MANAGER',
    ]);
  },

  canManageCohorts: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }
    return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
      'ADMIN',
      'BL_MANAGER',
    ]);
  },

  canEditMetric: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }

    return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
      'BL_SERVICES_MANAGER',
      'ADMIN',
      'TC_DATA_EDITOR',
      'BL_MANAGER',
    ]);
  },

  canViewCohortOverviewPage: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }

    return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
      'BL_SERVICES_MANAGER',
      'BL_CONTRACT_COACH',
      'ADMIN',
      'BL_TEAM_MEMBER',
      'BL_MANAGER',
      'TC_PARTNER',
    ]);
  },

  isParticipant: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }

    const userHasRole = Utils.arrayContainsAnyItemInAnotherArray(
      user.auth_roles,
      ['TC_TEACHER']
    );

    let participantId = user.participant_id;
    if (!participantId) {
      if (user.participants && user.participants[0].user_id === user.id) {
        // Handle district coach teacher record
        participantId = user.participants[0].id;
      }
    }

    return userHasRole && participantId > 0;
  },

  isEnterprise: (user) => {
    return Utils.isParticipant(user) || Utils.isPartnerLeader(user);
  },

  isInternalUser: (user) => {
    if (!user || !user.auth_roles) {
      return false;
    }

    return Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
      'BL_SERVICES_MANAGER',
      'BL_CONTRACT_COACH',
      'ADMIN',
      'BL_TEAM_MEMBER',
      'BL_MANAGER',
      'TC_CONTENT_CURATOR',
    ]);
  },

  isV3DataEditor: (user) => {
    return (
      Auth.isParticipant(user) ||
      user.auth_roles.includes('BL_SERVICES_MANAGER') ||
      Utils.arrayContainsAnyItemInAnotherArray(user.auth_roles, [
        'ADMIN',
        'BL_MANAGER',
      ])
    );
  },
};

export default Auth;
