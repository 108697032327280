// @ts-nocheck FIXME
import Store from './../../components/Store';

const store = {};

/****** EXTERNAL FUNCTIONS ******/
store.init = () => {
  // don't trigger event
  return false;
};

export default Store('PartnerLeadDashboardStore', store);
