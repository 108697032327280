// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Element from './../Element';
import SpotlightTypePanel from './SpotlightTypePanel';

const SpotlightsSection = (props) => {
  return (
    <div className="ppt-section">
      <h1 className="tc__headline-2">Spotlights</h1>

      <Element
        {...props.section.newEntry}
        sectionId={props.section.id}
        editEntry={props.editEntry}
        deleteEntry={props.deleteEntry}
        spotlightsOnly={true}
      />

      <div className="">
        {!props.section.types
          ? null
          : props.section.types.map((type) => {
              return (
                <SpotlightTypePanel
                  key={type.id}
                  {...type}
                  sectionId={props.section.id}
                  toggleSpotlightTypePanel={props.toggleSpotlightTypePanel}
                  sortSpotlightEntries={props.sortSpotlightEntries}
                  editEntry={props.editEntry}
                  deleteEntry={props.deleteEntry}
                />
              );
            })}
      </div>
    </div>
  );
};

SpotlightsSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default SpotlightsSection;
