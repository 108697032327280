// @ts-nocheck FIXME
import offset from '@legacy-components/libraries/videojs-offset';
import PropTypes from 'prop-types';
import React from 'react';
import videojs from 'video.js';

class Video extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let html =
      '<div class="strategy__preview-icon file-type-icon ext-mp4" style="height: ' +
      this.props.height +
      'px; width: ' +
      this.props.width +
      'px" ></div>';
    const target = document.getElementById(
      'lesson__narritve-video-' + this.props.id
    );
    target.innerHTML = html;

    if (this.props.content && this.props.content.preview) {
      const playInlineAttribute = this.props.playsinline ? 'playsInline' : '';
      html =
        '<video  class="video-js vjs-default-skin" controls preload="auto" width="640" height="264" poster="' +
        this.props.content.preview[0] +
        '" ' +
        playInlineAttribute +
        '>';

      if (this.props.content.formats) {
        for (let i = 0; i < this.props.content.formats.length; i++) {
          html +=
            '<source src="' +
            this.props.content.formats[i].url +
            '" type=\'' +
            this.props.content.formats[i].type +
            "' />";
        }
      }
      html +=
        '<p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>';
      html += '</video>';

      target.innerHTML = html;

      videojs.options.flash.swf = '/legacy-libs/videojs/video-js.swf';
      videojs.plugin('offset', offset);
      this.player = videojs(target.firstChild, {
        controls: true,
        preload: 'metadata',
        autoplay: false,
        width: this.props.width == 0 ? 620 : this.props.width,
        height: this.props.height == 0 ? 394 : this.props.height,
        fluid: true,
      }).on('ended', function () {
        this.currentTime(0);
      });
    }
  }

  render() {
    return (
      <div className="lesson__narrative-video-container">
        <div
          id={'lesson__narritve-video-' + this.props.id}
          className="vjs__video-container"
          ref="target"
        />
      </div>
    );
  }
}

Video.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.object,
  playsinline: PropTypes.bool,
};

export default Video;
