// @ts-nocheck FIXME
const ArrayHelpers = {
  remove: (array, element) => {
    if (!Array.isArray(array) || !element) {
      return;
    }
    const index = array.indexOf(element);
    if (index < 0) {
      return;
    }
    array.splice(index, 1);
  },

  hasElements: (array) => {
    return Array.isArray(array) && array.length > 0;
  },

  isEmpty: (array) => {
    return !ArrayHelpers.hasElements(array);
  },
};

export default ArrayHelpers;
