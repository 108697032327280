// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { Router } from './../../../components/Router';
import ScaleCenterImage from './../../ScaleCenterImage';

const NarrativeSpotlight = (props) => {
  const displayNarrativeDetails = (e) => {
    e.preventDefault();
    const tab = props.tab.replace('-', '_');
    Router.route(
      '/coaching/lab/partner/portfolio/' +
        props.portfolio_guid +
        '/page/' +
        props.tab +
        '/spotlight/' +
        props.id +
        '?from=' +
        tab
    );
  };

  // show the default teacher image if the teacher has none
  const teacherImage = props.data.teacher_image
    ? props.data.teacher_image
    : '/legacy-images/default-user.svg';

  // on most pages, we show the default narrative spotlight capsule
  // with just a few pieces of info
  let spotlightNode = (
    <div
      onClick={displayNarrativeDetails}
      className="ppp__elem ppp__elem--narrative cute-10-desktop cute-1-desktop-offset left"
    >
      <div className="ppp__narrative-sl-author-details">
        <div className="ppp__narrative-sl-author-image">
          <ScaleCenterImage src={teacherImage} className="ppp__user-image" />
        </div>
        <div className="ppp__narrative-sl-author">
          <span className="ppp__narrative-sl-author-name">
            {props.data.teacher_name}
          </span>
        </div>
      </div>
      <div className="ppp__narrative-sl-details">
        <a className="ppp__elem-headline tc__link">
          {props.data.strategy.title}
        </a>
        <div className="ppp__elem-subheadline ppp__narrative-sl-challenge">
          <span className="ppp__elem-label">Teaching Challenge:&nbsp;</span>
          <span className="ppp__narrative-sl-challenge-name">
            {props.data.teaching_challenge_nickname}
          </span>
        </div>
      </div>
    </div>
  );

  // only display artifact count if there are any artifacts
  let artifactCountNode = null;
  let growthAreaMarginClass = null;
  if (props.data.artifact_count > 0) {
    growthAreaMarginClass = ' ppp__elem-label--margin';
    artifactCountNode = (
      <div>
        <div
          className="ppp__narrative-sl-detailed-icon
                    ppp__narrative-sl-detailed-icon--artifact
                    tc-partner-portfolio-artifact-icon"
        ></div>
        <div className="ppp__narrative-sl-detailed-artifact-count">
          <span className="ppp__elem-label">Artifacts:&nbsp;</span>
          {props.data.artifact_count}
        </div>
      </div>
    );
  }

  // in the Spotlights section, we need to show more details in the capsule
  if (props.showDetailed) {
    spotlightNode = (
      <div
        onClick={displayNarrativeDetails}
        className="ppp__elem ppp__elem--narrative cute-10-desktop cute-1-desktop-offset left"
      >
        <div className="ppp__narrative-sl-detailed-author-details">
          <ScaleCenterImage
            src={teacherImage}
            className="ppp__user-image-detailed"
          />
          <div className="ppp__narrative-sl-author-name ppp__narrative-sl-author-name--detailed">
            {props.data.teacher_name}
          </div>
        </div>
        <div className="ppp__narrative-sl-detailed-strategy-details ppp__elem-subheadline">
          <div className="ppp__elem-label">My Strategy:</div>
          <div className="ppp__narrative-sl-detailed-body-text">
            {props.data.strategy.title}
          </div>
          <div className="ppp__elem-label ppp__elem-label--margin">
            My Challenge:
          </div>
          <div className="ppp__narrative-sl-detailed-body-text">
            {props.data.teaching_challenge_name}
          </div>
        </div>
        <div className="ppp__narrative-sl-detailed-loop-details ppp__elem-subheadline">
          {artifactCountNode}
          <div>
            <div
              className={
                'ppp__narrative-sl-detailed-icon tc-sga-nb-' +
                props.data.growth_area_id
              }
            ></div>
            <div className="ppp__narrative-sl-detailed-loop-detail-text">
              <div className={'ppp__elem-label' + growthAreaMarginClass}>
                Growth Area:
              </div>
              <div className="ppp__narrative-sl-detailed-body-text">
                {props.data.growth_area_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div className="row">{spotlightNode}</div>;
};

NarrativeSpotlight.propTypes = {
  id: PropTypes.number.isRequired,
  portfolio_guid: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  showDetailed: PropTypes.bool,
};

export default NarrativeSpotlight;
