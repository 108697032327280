// @ts-nocheck FIXME
import GrowthArea from './GrowthArea';

const Category = (props) => {
  // only care about visible growth areas
  const visibleGrowthAreas = props.growth_areas.filter((area) => area.visible);

  // get the categories growth area highest amplitude
  let highestAmplitude;
  for (const area of visibleGrowthAreas) {
    if (area.amplitude_value <= highestAmplitude) {
      continue;
    }
    highestAmplitude = area.amplitude_value;
  }

  // get the percentage of amplitude for relative bars
  const growthAreas = visibleGrowthAreas.map((area, i) => {
    const highestAmplitudePercent =
      (area.amplitude_value / highestAmplitude) * 100;
    const growthAreaActive = area.growth_area_id == props.displayedGAId;
    return (
      <GrowthArea
        {...area}
        changeDisplayedGA={props.changeDisplayedGA}
        showArtifact={props.showArtifact}
        highestCategoryAmplitude={highestAmplitudePercent}
        growthAreaActive={growthAreaActive}
        key={'growth-area-' + area.growth_area_id + '-' + i}
        tab={props.tab}
        portfolio_guid={props.portfolio_guid}
      />
    );
  });

  // set if there is an already expanded GA
  let expandedGAClass = '';
  for (const growthArea of visibleGrowthAreas) {
    if (growthArea.growth_area_id == props.displayedGAId) {
      expandedGAClass = ' expanded';
      break;
    }
  }

  return (
    <div
      className={
        'ppp__growth-area-category' + props.activeClass + expandedGAClass
      }
    >
      {growthAreas}
    </div>
  );
};

export default Category;
