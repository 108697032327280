// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import AbsoluteAlert from './../../Alerts/Absolute';
import MobilePartnerHighlights from './MobilePartnerHighlights';
import PartnerTable from './PartnerTable';

const PartnerHighlights = (props) => {
  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  return (
    <div>
      <Header page="Partner Highlights" {...props} />
      {alertNode}
      <div className="show-desktop hide-phone">
        <div className="row">
          <h1 className="tc__headline-1 ppd-3-coach__headline cute-5-desktop left">
            Partner Highlights
          </h1>
        </div>
        <div className="row">
          <PartnerTable {...props} />
        </div>
      </div>
      <div className="hide-desktop show-phone">
        <MobilePartnerHighlights {...props} />
      </div>
    </div>
  );
};

PartnerHighlights.propTypes = {
  ui: PropTypes.object.isRequired,
};

export default PartnerHighlights;
