// @ts-nocheck FIXME
import DomainTag from '@legacy-components/react/V5/Workspace/modules/Goal/DomainTag';
import propTypes from 'prop-types';
import React from 'react';
import EntryContainer from './EntryContainer';
import HighlightedRow from './HighlightedRow';

const OutcomeCard = (props) => {
  const outcome = props.outcome;
  // whether to show the entries or not
  const [isOutcomeExpanded, setIsOutcomeExpanded] = React.useState(true);
  // whether the description of the outcome is collapsed
  const [isTextExpanded, setIsTextExpanded] = React.useState(false);
  // whether the description part is collapsible (text long enough)
  // if the outcome has a reflection note then it must be expandable
  const [isTextExpandable, setIsTextExpandable] = React.useState(
    !!outcome.reflection_note
  );

  // show check mark as filled in or empty depending on whether goal is completed or not
  const checkClass = outcome.completed
    ? 'tc-v5-milestone-complete'
    : 'tc-v5-milestone';
  // make sure the check mark inside the flag has correct color
  const flagCheckClass = outcome.completed
    ? 'highlights__outcomes-card-flag-check--completed'
    : '';
  // set up goal expand/collapse arrow
  const arrowClass = isOutcomeExpanded
    ? 'highlights__outcomes-card-expand-arrow--expanded'
    : '';
  // outcome without entries is not expandable
  const isOutcomeExpandable = !!outcome.entries.length;

  // bottom left text
  let highlightsCountText = 'No Highlights to Display';
  if (outcome.entries.length) {
    const highlightAction = isOutcomeExpanded ? 'Hide' : 'Show';
    const highlightPlural =
      outcome.entries.length > 1 ? 'Highlights' : 'Highlight';
    highlightsCountText = `${highlightAction} ${outcome.entries.length} ${highlightPlural}`;
  }

  // controls whether the showAllText is visible
  const handleExpandable = (offsetHeight, scrollHeight) => {
    // if there is height difference (overflow happens)
    if (offsetHeight < scrollHeight || isTextExpanded) {
      setIsTextExpandable(true);
    } else {
      setIsTextExpandable(false);
    }
  };

  // controls whether to clamp text if more than 2 lines
  const clampClass = isTextExpanded
    ? ''
    : 'highlights__outcomes-card-text-description--clamp';
  // make the cursor of bottom part to pointer if is clickable
  const clickableClass = isOutcomeExpandable
    ? ' highlights__outcomes-card-bottom-section--clickable'
    : '';
  // controls hiding outcome description if reflection note presents
  const outcomeDescription =
    !isTextExpanded && outcome.reflection_note ? '' : outcome.description;

  // show all text
  const showAllText = isTextExpandable ? (
    <span
      className={'v5__body-copy highlights__outcomes-card-showAll'}
      onClick={() => setIsTextExpanded(!isTextExpanded)}
      data-testid="expand-text"
    >
      {isTextExpanded ? 'Show Less' : 'Show All'}
    </span>
  ) : null;

  const outcomesNode = isOutcomeExpandable
    ? outcome.entries.map((entry, index) => {
        // based on current index, calculate if the row being returned is the first or last row
        const title =
          entry.type === 'self_assessment_progress'
            ? 'Growth Reflection'
            : entry.title || entry.focus_area_title || 'Note';
        return (
          <React.Fragment key={entry.id}>
            <HighlightedRow
              reflection={entry.highlight_reflection}
              author={entry.highlight_author}
              title={title}
              contentId={entry.id}
              storyId={entry.story_id}
              entryType={entry.entryType}
              isFirstRow={!index}
              isLastRow={index === outcome.entries.length - 1}
            >
              <EntryContainer {...entry} outcomeTitle={outcome.title} />
            </HighlightedRow>
          </React.Fragment>
        );
      })
    : null;

  return (
    <>
      <div className="highlights__outcomes-card">
        <div className="highlights__outcomes-card-body">
          <div
            className={`highlights__outcomes-card-complete-check ${checkClass}`}
          >
            <span className="path1"></span>
            <span className={`path2 ${flagCheckClass}`}></span>
          </div>

          <div className="v5__heading-5 highlights__outcomes-card-body-main">
            <div className="highlights__outcomes-card-header">
              <div className="highlights__outcomes-card-title">
                {outcome.title}
              </div>
              <DomainTag
                containerStyles="workspace__goal-domain--align-top"
                tagStyles="workspace__goal-domain--goal-display"
                id={outcome.focus_area.id}
                tagline={outcome.focus_area.tagline}
                label={outcome.focus_area.title}
                hover={true}
              />
              <div className="highlights__outcomes-card-completed-text">
                {outcome.completed && 'Completed'}
              </div>
            </div>

            <div className="highlights__outcomes-card-text-container">
              {outcome.completed && outcome.reflection_note && (
                <div className="workspace__goal-reflection-container">
                  <span className="tc-v5-left-quotes workspace__goal-reflection-icon"></span>
                  <div className="workspace__goal-reflection-note v5__body-copy">
                    {outcome.reflection_note}
                  </div>
                </div>
              )}
              <div
                className={`${clampClass}`}
                ref={(element) => {
                  if (!element) return;
                  if (outcome.reflection_note) return;
                  handleExpandable(element.offsetHeight, element.scrollHeight);
                }}
              >
                <p className="v5__body-copy highlights__outcomes-card-text-description">
                  {outcomeDescription}
                </p>
              </div>
              {showAllText}
            </div>
          </div>
        </div>

        <div
          className={`highlights__outcomes-card-bottom-section${clickableClass}`}
          onClick={() =>
            isOutcomeExpandable && setIsOutcomeExpanded(!isOutcomeExpanded)
          }
        >
          <div className="highlights__outcomes-card-bottom-section-text">
            {highlightsCountText}
          </div>
          {isOutcomeExpandable && (
            <div
              className={`tc-v4-circle-arrow highlights__outcomes-card-expand-arrow ${arrowClass}`}
            />
          )}
        </div>
      </div>
      {isOutcomeExpanded && outcomesNode}
    </>
  );
};

OutcomeCard.propTypes = {
  outcomes: propTypes.array,
};

export default OutcomeCard;
