// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import NavigationLink from './../../NavigationLink';
import ResultCapsule from './../common/ResultCapsule';
import BlendedBreadcrumbs from './modules/BlendedBreadcrumbs';
import BlendedMenu from './modules/BlendedMenu';
import MegaMenu from './modules/MegaMenu';
import MegaMenuBar from './modules/MegaMenuBar';

const BlendedBrowser = (props) => {
  let implementationsNode, blendedTopicSummaryNode, blendedTopicSubheadlineNode;
  let megaMenuHeadline = 'Math';
  let childStandards = props.standards_browser.math_child_standards;

  switch (props.ui.activeSubject) {
    case 'math':
      megaMenuHeadline = 'Math';
      childStandards = props.standards_browser.math_child_standards;
      break;
    case 'ela':
      megaMenuHeadline = 'English Language Arts';
      childStandards = props.standards_browser.ela_child_standards;
      break;
    case 'science':
      megaMenuHeadline = 'Science';
      childStandards = props.standards_browser.science_child_standards;
      break;
    case 'blended':
      megaMenuHeadline = 'Science';
      childStandards = props.standards_browser.science_child_standards;
      break;
  }

  implementationsNode = props.blended_browser.Implementations.map(
    (implementation) => {
      return (
        <ResultCapsule
          resultId={implementation.id}
          resultType={'strategy'}
          resultUrl={
            process.env.REACT_APP_TEACHING_URL +
            '/browse/blended_video/' +
            implementation.id +
            '/' +
            implementation.seo_url +
            '?from=blended_topic_level1'
          }
          preview={implementation.image}
          title={implementation.title}
          summary={implementation.description}
          key={'blended-implementation-' + implementation.id}
          user={props.user}
          topic={props.blended_browser.BlendedTopic.name}
        />
      );
    }
  );
  if (props.blended_browser.BlendedTopic.subheading) {
    blendedTopicSubheadlineNode = (
      <div className="standards__blended-topic">
        {props.blended_browser.BlendedTopic.subheading}
      </div>
    );
  }
  if (props.blended_browser.BlendedTopic.description) {
    blendedTopicSummaryNode = (
      <div className="standards__blended-description">
        {props.blended_browser.BlendedTopic.description}
      </div>
    );
  }
  let megaMenuNode;
  if (props.ui.activeSubject == 'blended') {
    megaMenuNode = (
      <BlendedMenu
        expandMobileStandardsBlock={props.expandMobileStandardsBlock}
        expandedMobileStandardsBlock={props.ui.expandedMobileStandardsBlock}
        categories={props.blended_browser.BlendedTopicCategories}
        megaMenuHeadline={megaMenuHeadline}
        megaMenuOpen={props.ui.megaMenuOpen}
      />
    );
  } else {
    megaMenuNode = (
      <MegaMenu
        childStandards={childStandards}
        expandMobileStandardsBlock={props.expandMobileStandardsBlock}
        expandedMobileStandardsBlock={props.ui.expandedMobileStandardsBlock}
        megaMenuHeadline={megaMenuHeadline}
        megaMenuOpen={props.ui.megaMenuOpen}
      />
    );
  }
  const contentVisibleClass = !props.ui.megaMenuOpen
    ? ' standards__content--visible'
    : ' standards__content--hidden';

  const mobileBackBar = (
    <div className="standards__mobile-header standards__mobile-header--blended show-phone hide-desktop">
      <NavigationLink
        url={process.env.REACT_APP_TEACHING_URL + '/browse/blended'}
        className="standards__mobile-back"
      >
        <div className="standards__mobile-back-icon tc-arrow"></div>
        <div className="standards__mobile-back-caption">Back</div>
      </NavigationLink>
      <div className="standards__mobile-header-title">
        {props.blended_browser.BlendedTopic.nickname}
      </div>
    </div>
  );

  return (
    <div className="tc__page">
      <Header page="commonCore" {...props} />
      <div className="bl4__page-background"></div>

      <MegaMenuBar
        activeStandard={props.blended_browser.BlendedTopic.id}
        activeSubject={props.ui.activeSubject}
        openMenu={props.openMenu}
        closeMenu={props.closeMenu}
      />

      {megaMenuNode}
      {mobileBackBar}

      <div className={'standards__content' + contentVisibleClass}>
        <BlendedBreadcrumbs
          openMenu={props.openMenu}
          selectedHeadline={props.blended_browser.selected_headline_name}
          breadcrumbs={props.blended_browser.Breadcrumbs}
          openBlendedDropdown={props.openBlendedDropdown}
          blendedDropdownActive={props.ui.blendedDropdownActive}
        />
        <div className="row row--1200">
          <div className="cute-10-desktop cute-12-phone left">
            <div className="row">
              <div className="cute-12-desktop standards__blended-header">
                <div className="standards__blended-category">
                  {props.blended_browser.BlendedTopic.name}
                </div>
                {blendedTopicSubheadlineNode}
                {blendedTopicSummaryNode}
                <div>
                  <div className="standards__blended-strategy-count-icon tc-v4-tablet"></div>
                  <div className="standards__blended-strategy-count">
                    {props.blended_browser.BlendedTopic.implementation_count}{' '}
                    Videos
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="standards__child-lessons cute-12-desktop">
                {implementationsNode}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BlendedBrowser.propTypes = {
  ui: PropTypes.object.isRequired,
  standards_browser: PropTypes.object.isRequired,
  blended_browser: PropTypes.object.isRequired,
};

export default BlendedBrowser;
