// @ts-nocheck FIXME
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';
import PartnerDPModal from './PartnerDP';
import PartnerLeaderSurveyModal from './PartnerLeaderSurveyModal';
import PartnerPSMModal from './PartnerPSM';
import PartnerRow from './PartnerRow';

const Partners = (props: any): JSX.Element => {
  const search = React.useRef();
  const [searchTerm, setSearchTerm] = React.useState(null);
  const [selectedPSM, setSelectedPSM] = React.useState(-1);

  React.useEffect(() => {
    // If the user is also a PSM we want the page to default to their partnerships
    const userPSM = props.psms.find((psm) => psm.id === props.user.id);
    if (userPSM) {
      setSelectedPSM(userPSM.id);
    }
  }, []);

  let filteredPartners = [...props.partners];

  const escapeSpecialChars = (term) => {
    return term.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&').toLowerCase();
  };

  // @todo add a loading state spinner - Prospect takes the longest time to display

  // Dropdown filters
  filteredPartners = filteredPartners.filter((partner) => {
    // Filter on status
    if (partner.status.indexOf(props.status) === -1) return false;

    // Filter on PSM
    if (selectedPSM === -1) return true; // Show all PSMs

    if (selectedPSM === -2) {
      if (partner.psms.length) return false;
      return true;
    }

    let showPartner = false;
    partner.psms.forEach((psm) => {
      if (psm.id === selectedPSM) showPartner = true;
    });
    return showPartner;
  });

  if (searchTerm) {
    const escapedSearchTerm = new RegExp(escapeSpecialChars(searchTerm));

    if (!escapedSearchTerm) return;

    // search in partnership name, assigned PSM name and DP name
    filteredPartners = filteredPartners.filter((partner) => {
      let matched = false;

      if (escapedSearchTerm.test(partner.name.toLowerCase())) {
        return true;
      }

      partner.psms.forEach((psm) => {
        const psmName = `${psm.first_name} ${psm.last_name}`.toLowerCase();
        if (escapedSearchTerm.test(psmName)) matched = true;
      });

      if (matched) return true;

      partner.dps.forEach((dp) => {
        const dpName = `${dp.first_name} ${dp.last_name}`.toLowerCase();
        if (escapedSearchTerm.test(dpName)) matched = true;
      });

      return matched;
    });
  }

  // set up any alert banner
  let alertNode = null;
  if (Object.keys(props.ui.alert).length) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // set up any opened modals
  let modalNode = null;
  if (props.ui.psmModal.opened) {
    modalNode = (
      <PartnerPSMModal
        {...props.ui.psmModal}
        psms={props.ui.psms}
        submitEntry={props.editPSMs}
        getVisibleOptions={props.getVisibleOptions}
        updateSelect={props.updateSelect}
      />
    );
  } else if (props.ui.dpModal.opened) {
    modalNode = (
      <PartnerDPModal
        {...props.ui.dpModal}
        dps={props.ui.dps}
        submitEntry={props.editDPs}
        getVisibleOptions={props.getVisibleOptions}
        updateSelect={props.updateSelect}
      />
    );
  } else if (props.ui.partnerLeaderSurveyModal.opened) {
    modalNode = (
      <PartnerLeaderSurveyModal
        {...props.ui.partnerLeaderSurveyModal}
        surveyPartner={props.ui.surveyPartner}
        sendPartnerLeaderSurveys={props.sendPartnerLeaderSurveys}
        loading={props.ui.surveySentLoading}
      />
    );
  }

  let partnerNameSortIndicator;
  let cohortCountSortIndicator;
  let eventCountSortIndicator;
  let surveySortIndicator;

  let directionalArrow = (
    <div className="tc-short-arrow ppp-v3-curation__sort ppp-v3-curation__sort--up"></div>
  );
  if (props.sortDirection !== 'ascending') {
    directionalArrow = (
      <div className="tc-short-arrow ppp-v3-curation__sort ppp-v3-curation__sort--down"></div>
    );
  }

  // Column titles should only have clickable styling when there are multiple cohorts
  let linkClass = '';
  if (filteredPartners.length > 1) {
    linkClass = ' tc__link tc__link--black';
  }

  // Arrow should only show up on column that is currently sorted by
  switch (props.sortColumn) {
    case 'partnerName':
      partnerNameSortIndicator = directionalArrow;
      break;
    case 'cohortCount':
      cohortCountSortIndicator = directionalArrow;
      break;
    case 'eventCount':
      eventCountSortIndicator = directionalArrow;
      break;
    case 'surveySent':
      surveySortIndicator = directionalArrow;
  }

  // Sort handlers
  const handleSortPartnerName = () => {
    props.sortBy('partnerName');
  };

  const handleSortCohortCount = () => {
    props.sortBy('cohortCount');
  };

  const handleSortEventCount = () => {
    props.sortBy('eventCount');
  };

  const handleSortSurvey = () => {
    props.sortBy('surveySent');
  };

  // Filter handlers
  const handleSearchSubmit = () => {
    setSearchTerm(search.current.value);
  };

  const handleFilterPSMs = (e) => {
    setSelectedPSM(+e.target.value);
  };

  const handleFilterStatus = (e) => {
    props.setStatus(e.target.value);
  };

  let surveyColHeader = null;
  if (props.status === 'Current') {
    surveyColHeader = (
      <th
        className={`admin-table__header ${linkClass}`}
        onClick={handleSortSurvey}
      >
        Survey {surveySortIndicator}
      </th>
    );
  }
  let emptyPartnersNode;
  if (filteredPartners.length === 0) {
    emptyPartnersNode = (
      <div className="partners__empty-search row">
        No partners match your search criteria. Try changing your search terms
        or filters to broaden your search.
      </div>
    );
  }

  // if we are only showing Prospect partnerships, hide the Cohorts/Events count columns
  let cohortsColNode = (
    <th
      className={`admin-table__header ${linkClass}`}
      onClick={handleSortCohortCount}
    >
      Cohorts {cohortCountSortIndicator}
    </th>
  );
  let eventsColNode = (
    <th
      className={`admin-table__header ${linkClass}`}
      onClick={handleSortEventCount}
    >
      Events {eventCountSortIndicator}
    </th>
  );
  if (props.status === 'Prospect') {
    cohortsColNode = null;
    eventsColNode = null;
  }

  let bodyNode = (
    <React.Fragment>
      <div className="row cohorts__headline-row">
        <div className="cute-12-desktop">
          <h3 className="cohorts__headline tc__headline-2">Partners</h3>
        </div>
      </div>

      <div className="row">
        <div className="admin-table__search-and-filter">
          <div className="admin-table__search-container">
            <input
              className="v5__input header__v5-search-input"
              placeholder="Search"
              ref={search}
              onInput={handleSearchSubmit}
            />
            <button
              className="header__search-button header__search-button--v5 custom-select__single-selected-remove tc__link tc-search"
              onClick={handleSearchSubmit}
            ></button>
          </div>
          <div>
            <label>PSM </label>
            <select
              className="tc__select"
              onChange={handleFilterPSMs}
              value={selectedPSM}
            >
              <option value="-1">All</option>
              <option value="-2">No PSM</option>
              {props.psms.map((psm) => (
                <option key={`psm-${psm.id}`} value={psm.id}>
                  {psm.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Status </label>
            <select className="tc__select" onChange={handleFilterStatus}>
              <option value="Current">Current</option>
              <option value="Past">Past</option>
              <option value="Prospect">Prospect</option>
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <table className="admin-table">
          <tbody>
            <tr>
              <th
                className={`admin-table__header ${linkClass}`}
                onClick={handleSortPartnerName}
              >
                Partners {partnerNameSortIndicator}
              </th>
              {cohortsColNode}
              {eventsColNode}
              <th className="admin-table__header">PSM</th>
              <th className="admin-table__header">DPs</th>
              <th className="admin-table__header">Leaders</th>
              {surveyColHeader}
            </tr>
            {filteredPartners.map((partner) => {
              return (
                <PartnerRow
                  key={partner.id}
                  user={props.user}
                  partner={partner}
                  openEditPSMs={props.ui.psmModal.open}
                  openEditDPs={props.ui.dpModal.open}
                  openSendPartnerLeaderSurveyModal={
                    props.ui.partnerLeaderSurveyModal.open
                  }
                  statusFilter={props.status}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      {emptyPartnersNode}
    </React.Fragment>
  );

  if (props.search_failed) {
    bodyNode = (
      <React.Fragment>
        <div className="v5__registered-table-details v5__heading-5 row home-v5__empty-activity-capsule">
          <div>
            <img
              src="/legacy-images/404-pets.png"
              className="code-404__img-overview"
            />
          </div>
          <div className="home-v5__empty-activity-row">
            <div className="v5__heading-4">Oops! Something is broken.</div>
            <div className="v5__comment-copy">
              <div>
                Our partner tools are temporarily unavailable. (We blame the
                cat.)
              </div>
              <div>Thanks for your patience as we tidy up this issue.</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div>
      <Header page="Partners" {...props} />
      {alertNode}
      {modalNode}
      {bodyNode}
    </div>
  );
};

Partners.propTypes = {
  ui: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired,
  getVisibleOptions: PropTypes.func.isRequired,
  updateSelect: PropTypes.func.isRequired,
};

export default Partners;
