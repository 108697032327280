// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import SingleSelect from './../../V3/Common/SingleSelect/SingleSelect';
import FreezeWindowScroll from './../../V4/Common/FreezeWindowScroll';

const RecommendModal = (props) => {
  const handleCloseModal = (e) => {
    e.preventDefault();
    props.close({ closeFromX: true });
  };

  const handleClearError = () => {};

  const handleRecommend = (e) => {
    e.preventDefault();
    const data = {};
    data['itemType'] = props.data.itemType;
    data['itemId'] = props.data.itemId;
    data['recommend'] = true;
    props.recommend(data);
  };

  return (
    <div>
      <div
        className="modal__page-overlay modal__page-overlay--grey"
        onClick={handleCloseModal}
      ></div>

      <div className="modal--absolute modal--note cohort-dash__modal">
        <div
          className="modal__close show-desktop hide-phone"
          onClick={handleCloseModal}
        >
          <div className="tc-x"></div>
        </div>

        <h2 className="bl4-result__recomend-modal-headline cohort-dash__modal-headline tc__headline-2">
          Recommend {props.data.itemType}
        </h2>

        <div className="tc__mobile-header hide-desktop show-phone">
          <div
            className="tc__back cute-2-desktop left"
            onClick={handleCloseModal}
          >
            <div className="tc__back-arrow tc-arrow"></div>
            <div className="tc__back-caption">Back</div>
          </div>
        </div>
        <div className="cohort-dash__modal-body">
          <div className="cute-12-desktop cute-12-phone">
            <SingleSelect
              {...props.participants}
              field={'label'}
              placeholder="Select your teacher"
              updateSingleSelect={props.selectRecommendElement}
              getVisibleOptions={props.getRecommendElement}
              feature={props.feature}
              handleFocus={(e) => handleClearError(e)}
              minCharCountForResults={0}
              onChangeDelay={700}
              analyticsLoggedByStore={true}
            />
            <div className="cohort-dash__submit-btns">
              <button
                onClick={handleRecommend}
                className="tc__btn tc__btn--flat-blue"
              >
                Recommend
              </button>
            </div>
          </div>
        </div>
      </div>

      <FreezeWindowScroll parentModal="cohort-dash__modal" />
    </div>
  );
};

RecommendModal.propTypes = {
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default RecommendModal;
