// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import React from 'react';
import RegistrationSteps from './RegistrationSteps';

const ResetPassword = (props) => {
  const userPassword = React.useRef(null);
  const userConfirmPassword = React.useRef(null);

  const handleSetPassword = (e) => {
    props.setIsLoading(true);
    e.preventDefault();
    Router.route(
      `/coaching/lab/participant/${props.participant.id}/registration/1`
    );
  };

  const userConfirmationNode = (
    <React.Fragment>
      <UserPhoto
        src={props.user.photo}
        firstName={props.user.first_name}
        lastName={props.user.last_name}
        userId={props.user.id}
        diameter={40}
        className="search__owner-image left"
      />
      <span>
        <div className="registration-v5__user-name">{`${props.user.first_name} ${props.user.last_name}, ${props.user.email}`}</div>
      </span>
    </React.Fragment>
  );

  const btnDisabledClass = props.ui.loading ? ' disabled' : '';

  return (
    <div className="registration-v5-event__register">
      <div className="registration-v5-event__register-header v5__heading-3">
        {'Get Your Personal Coach'}
      </div>
      <div className="registration-v5-event__register-body v5__body-copy">
        <RegistrationSteps
          hasAccount={false}
          stepTwoActive={true}
          registrationProcess={props.cohort ? 'cohort' : 'event'}
        />
        <div className="row">
          <div className="cute-12-desktop">
            <div className="v5__heading-5 registration-v5-event__register-subheading">
              Register to get matched to a coach
            </div>
            {userConfirmationNode}
          </div>
        </div>
        <div className="row">
          <div className="cute-12-desktop">
            To help us match you to a BetterLesson coach, you will need to
            answer a few questions about your teaching experience and
            availability for coaching.
          </div>
          <div className="cute-12-desktop">
            The registration process will take approximately 5 minutes to
            complete.
          </div>
        </div>
        <div className="registration-v5-event__button-container">
          <button
            className={'v5__btn' + btnDisabledClass}
            onClick={handleSetPassword}
          >
            Start Registration
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
