// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';

const StrategySchema = (props) => {
  const schemaData = {
    '@legacy-components/context': 'http://schema.org',
    '@type': 'ScholarlyArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${process.env.REACT_APP_TEACHING_URL}/strategy/${props.strategy.id}/${props.strategy.seo_url}`,
    },
    headline: props.strategy.title,
    url: `${process.env.REACT_APP_TEACHING_URL}/strategy/${props.strategy.id}/${props.strategy.seo_url}`,
    image:
      props.strategy.lessonfile &&
      props.strategy.lessonfile.connections &&
      props.strategy.lessonfile.connections.preview_type === 'image'
        ? {
            '@type': 'ImageObject',
            url: props.strategy.lessonfile.connections.public_preview,
            caption: props.strategy.lessonfile.connections.name,
          }
        : {},
    video:
      props.strategy.lessonfile &&
      props.strategy.lessonfile.connections &&
      props.strategy.lessonfile.connections.preview_type === 'movie'
        ? {
            '@type': 'VideoObject',
            name: props.strategy.lessonfile.connections.name,
            thumbnailUrl: props.strategy.lessonfile.connections.public_preview,
            duration: `PT${props.strategy.lessonfile.connections.duration_or_pages}S`,
            contentUrl: props.strategy.lessonfile.connections.content.formats
              ? props.strategy.lessonfile.connections.content.formats[0].url
              : '',
            description: props.strategy.lessonfile.connections.name,
            uploadDate: props.strategy.lessonfile.last_binary_edit,
          }
        : {},
    thumbnailUrl:
      props.strategy.lessonfile &&
      props.strategy.lessonfile.connections &&
      props.strategy.lessonfile.connections.public_preview
        ? props.strategy.lessonfile.connections.public_preview
        : '',
    dateCreated: props.strategy.created,
    dateModified: props.strategy.lastmodified,
    publisher: {
      '@type': 'Organization',
      name: 'BetterLesson',
      logo: {
        '@type': 'ImageObject',
        url: `${process.env.REACT_APP_LAB_URL}/legacy-images/betterlesson-logo.svg`,
        width: '744',
        height: '156',
      },
    },
    description: props.strategy.description,
    creator: props.strategy.authors
      ? props.strategy.authors.map((author) => {
          return {
            '@type': 'Person',
            name: author.name,
          };
        })
      : [],
  };

  return (
    <PurifiedHTML
      type="application/ld+json"
      content={JSON.stringify(schemaData)}
      tagName="script"
    />
  );
};

export default StrategySchema;
