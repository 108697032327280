// @ts-nocheck FIXME
import dateformat from 'dateformat';
import PropTypes from 'prop-types';

import ScaleCenterImage from './../../../../ScaleCenterImage';

const getImageSrc = (props) => {
  const noPreviewImage =
    '<div class="portfolio-edit__artifact-image--no-preview"><div class="artifact__type-icon artifact__type-icon--no-preview file-type-icon ext-' +
    props.extension +
    '"></div></div>';
  const containerElem = 'portfolio-edit__artifact-preview--' + props.id;

  // create image instance
  const image = new Image();

  // Set the image source to the preview in props if we have it
  if (
    props.content &&
    props.content.preview &&
    props.content.preview.length > 0
  ) {
    image.src = props.content.preview[0];
  } else {
    // There is no preview. Trigger the image onError event
    image.src = '';
  }
  // Uses the no preview image if the image.src is invalid
  image.onerror = function () {
    document.getElementById(containerElem).innerHTML = noPreviewImage;
  };

  return image.src;
};

const ArtifactCapsule = (props) => {
  let notes;

  // convenience var
  const artifact = props.artifact;

  // hide/show an artifact in the portfolio
  const handleToggleVisible = () => {
    props.toggleArtifactVisibility({ loopId: props.loopId, id: artifact.id });
  };

  // open the artifact overlay
  const handleViewArtifact = () => {
    props.setCurrentArtifact(artifact);
  };

  // handle setting the artifact to edit mode
  const handleEditNote = () => {
    props.setArtifactEditMode(artifact.id);
  };

  // handle updating the artifact note
  const handleNoteChanges = (e) => {
    e.preventDefault();
    props.updateArtifact({
      loopId: props.loopId,
      id: artifact.id,
      notes: notes.value,
    });
  };

  // hide/show an artifact's comments
  const handleShowComments = () => {
    props.showArtifactComments(artifact.id);
  };

  // hide/show an artifact's comment in the portfolio
  const handleToggleCommentVisible = (commentId) => {
    props.toggleArtifactCommentVisibility({
      loopId: props.loopId,
      artifactId: artifact.id,
      id: commentId,
    });
  };

  // grey things out if the artifact is not visible
  let capsuleClass = '';
  let visibilityIconClass = '';
  let typeClass = '';
  let flagEndClass = '';
  let linkClass = '';
  let commentIconClass = '';
  let commentCaptionClass = '';
  if (!artifact.visible) {
    capsuleClass = ' portfolio-edit__artifact-capsule--grey';
    visibilityIconClass = ' portfolio-edit__strategy-visible-icon--grey';
    typeClass = ' artifact__type--grey';
    flagEndClass = ' artifact__flag-end--grey';
    linkClass = ' tc__link--grey-visited';
    commentIconClass = ' portfolio-edit__comment-icon--grey';
    commentCaptionClass = ' portfolio-edit__comment-icon-caption--grey';
  }

  // get the artifact preview
  const imageSrc = getImageSrc(artifact);

  // only show the artifact type flag if the artifact has a type associated with it
  let artifactTypeNode = null;
  if (artifact.type_name) {
    artifactTypeNode = (
      <div className={'artifact__type' + typeClass}>
        <div className="artifact__type-caption">{artifact.type_name}</div>
        <div className={'artifact__flag-end tc-flag-end' + flagEndClass}></div>
      </div>
    );
  }

  // by default, show the artifact notes
  const artifactNotes = artifact.notes ? (
    artifact.notes
  ) : (
    <span className="portfolio-edit__empty-placeholder">No notes added</span>
  );
  let notesNode = (
    <div className="cute-11-desktop right tc__body-copy">
      {artifactNotes}
      <div className="tc__link" onClick={handleEditNote}>
        Edit
      </div>
    </div>
  );
  // in edit mode, show the notes in a textarea
  if (props.inEditMode) {
    notesNode = (
      <div className="cute-11-desktop right tc__body-copy">
        <textarea
          className="tc__input portfolio-edit__substep-textarea"
          defaultValue={artifact.notes}
          ref={(ref) => (notes = ref)}
          onChange={handleNoteChanges}
        />
      </div>
    );
  }

  // build the comments section
  let commentsNode = null;
  if (artifact.commentCount) {
    // if the artifact is not visible, just show the total # comments next to the icon
    let commentCaption =
      artifact.commentCount == 1
        ? artifact.commentCount + ' Comment'
        : artifact.commentCount + ' Comments';
    // otherwise, we want to show the actual # of comments that are visible + the total
    if (artifact.visible) {
      // set up the text for the comment count
      let selectedCommentCount = 0;
      artifact.comments &&
        artifact.comments.forEach((comment) => {
          if (comment.visible) {
            selectedCommentCount += 1;
          }
        });
      commentCaption =
        selectedCommentCount +
        ' of ' +
        artifact.commentCount +
        ' Comments Selected';
    }

    // by default, just show the # of comments
    commentsNode = (
      <div className="cute-11-desktop right portfolio-edit__tml-comments">
        <div onClick={handleShowComments}>
          <div
            className={
              'portfolio-edit__comment-icon tc-comment' + commentIconClass
            }
          ></div>
          <div
            className={
              'portfolio-edit__comment-icon-caption' + commentCaptionClass
            }
          >
            {commentCaption}
          </div>
        </div>
      </div>
    );
    // after clicking on the #, we show the actual list of comments
    if (props.commentsExpanded) {
      commentsNode = artifact.comments.map((comment) => {
        // grey things out if the parent artifact or the comment is not visible
        let commentVisibilityIconOnClick = (e) =>
          handleToggleCommentVisible(comment.id);
        let commentVisibilityIconClass = '';
        let commentClass = '';
        // if the parent artifact is not visible,
        // then also do not allow any user interactions on the child comments
        if (!artifact.visible) {
          commentVisibilityIconOnClick = null;
          commentVisibilityIconClass =
            ' portfolio-edit__strategy-visible-icon--grey disabled';
          commentClass = ' portfolio-edit__tml-comment--grey';
        } else if (!comment.visible) {
          commentVisibilityIconClass =
            ' portfolio-edit__strategy-visible-icon--grey';
          commentClass = ' portfolio-edit__tml-comment--grey';
        }

        // show the default user image if the commenter has no photo
        const commenterImg = comment.photo
          ? comment.photo
          : '/legacy-images/default-user.svg';

        return (
          <div key={comment.id} className={'row' + commentClass}>
            <div className="cute-1-desktop portfolio-edit__strategy-capsule-icon-col">
              <span
                onClick={commentVisibilityIconOnClick}
                className={
                  'tc-check portfolio-edit__strategy-visible-icon' +
                  commentVisibilityIconClass
                }
              ></span>
            </div>
            <div className="cute-1-desktop">
              <ScaleCenterImage
                src={commenterImg}
                className="portfolio-edit__tml-commenter-image"
              />
            </div>
            <div className="cute-10-desktop left">
              <div className="portfolio-edit__tml-commenter">
                {comment.first_name} {comment.last_name}
              </div>
              <div className="portfolio-edit__tml-comment-text tc__body-copy">
                {comment.data}
              </div>
            </div>
          </div>
        );
      });
      commentsNode = (
        <div className="cute-11-desktop right portfolio-edit__tml-comments">
          {commentsNode}
        </div>
      );
    }
  }

  return (
    <div className={'clearfix portfolio-edit__artifact-capsule' + capsuleClass}>
      <div className="cute-1-desktop portfolio-edit__strategy-capsule-icon-col">
        <span
          onClick={handleToggleVisible}
          className={
            'tc-check portfolio-edit__strategy-visible-icon' +
            visibilityIconClass
          }
        ></span>
      </div>
      <div
        className="cute-2-desktop portfolio-edit__artifact-info"
        onClick={handleViewArtifact}
      >
        <div
          id={'portfolio-edit__artifact-preview--' + artifact.id}
          className="portfolio-edit__artifact-preview"
        >
          <ScaleCenterImage
            src={imageSrc}
            className="portfolio-edit__artifact-image"
          />
        </div>
      </div>
      <div
        className="cute-9-desktop portfolio-edit__artifact-info"
        onClick={handleViewArtifact}
      >
        {artifactTypeNode}
        <div>
          <span
            className={
              'portfolio-edit__artifact-type-icon artifact__type-icon file-type-icon ext-' +
              artifact.extension
            }
          ></span>
          <span
            className={'portfolio-edit__artifact-name tc__link' + linkClass}
          >
            {artifact.link_url ? artifact.link_url : artifact.filename}
          </span>
        </div>
        <div className="tc__body-copy portfolio-edit__artifact-uploaded">
          Uploaded:{' '}
          {artifact.created && dateformat(artifact.created, 'mm/dd/yyyy')}
        </div>
      </div>
      {notesNode}
      {commentsNode}
    </div>
  );
};

ArtifactCapsule.propTypes = {
  artifact: PropTypes.object.isRequired,
  loopId: PropTypes.number.isRequired,
  setCurrentArtifact: PropTypes.func.isRequired,
  toggleArtifactVisibility: PropTypes.func.isRequired,
  setArtifactEditMode: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  updateArtifact: PropTypes.func.isRequired,
  showArtifactComments: PropTypes.func.isRequired,
  commentsExpanded: PropTypes.bool.isRequired,
  toggleArtifactCommentVisibility: PropTypes.func.isRequired,
};

export default ArtifactCapsule;
