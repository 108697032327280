// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../components/ArrayHelpers';
import FreezeWindow from './../Common/FreezeWindowScroll';
import SingleSelect from './../Common/SingleSelect/SingleSelect';
import Spinner from './../Common/Spinner';

const SchoolModal = (props) => {
  let schoolName, schoolZipcode;
  let modalCaption;
  switch (props.data.mode) {
    case 'edit':
      modalCaption = 'Edit School';
      break;
    case 'combine':
      modalCaption = 'Combine Schools';
      break;
    default:
      modalCaption = 'Approve School';
  }

  const handleFocus = (e, field) => {
    e.preventDefault();
    props.setFieldError({
      field: field,
      error: null,
    });
  };

  const handleSubmitCombine = (e) => {
    e.preventDefault();
    const destinationId = props.schoolZipcode.selectedOption
      ? props.schoolZipcode.selectedOption.id
      : null;
    if (!destinationId) {
      props.setFieldError({
        field: 'schoolZipcode',
        error: 'Please select the target school',
      });
      return false;
    }
    props.combine({
      destinationId: destinationId,
      sourceSchoolName: props.data.name,
      sourceSchoolZipCode: props.data.mailing_zipcode,
    });
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();

    const errors = [];

    if (!schoolName.value.trim()) {
      errors.push({
        field: 'name',
        error: 'Name is required',
      });
    }

    if (!schoolZipcode.value.trim()) {
      errors.push({
        field: 'schoolZipcode',
        error: 'Zip code is required',
      });
    }

    if (ArrayHelpers.hasElements(errors)) {
      errors.forEach((fieldError) => {
        props.setFieldError(fieldError);
      });
      return false;
    }

    props.edit({
      sourceSchoolName: props.data.name,
      sourceSchoolZipCode: props.data.mailing_zipcode,
      newName: schoolName.value.trim(),
      newZipCode: schoolZipcode.value.trim(),
    });
  };

  const handleSubmitApprove = (e) => {
    e.preventDefault();

    const errors = [];

    if (!props.data.name || !props.data.name.trim()) {
      errors.push({
        field: 'name',
        error: 'Name is required before a school can be approved',
      });
    }

    if (!props.data.mailing_zipcode || !props.data.mailing_zipcode.trim()) {
      errors.push({
        field: 'schoolZipcode',
        error: 'Zip code is required before a school can be approved',
      });
    }

    if (ArrayHelpers.hasElements(errors)) {
      errors.forEach((fieldError) => {
        props.setFieldError(fieldError);
      });
      return false;
    }

    props.approve({
      sourceSchoolName: props.data.name,
      sourceSchoolZipCode: props.data.mailing_zipcode,
    });
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  // set up errors for school name
  let nameErrorClass = '';
  let nameErrorNode = null;
  if ('name' in props.errors) {
    nameErrorClass = ' error';
    nameErrorNode = (
      <div className="tc__error--relative ">{props.errors.name}</div>
    );
  }

  // set up errors for school zipcode
  let zipCodeErrorClass = '';
  let zipCodeErrorNode = null;
  if ('schoolZipcode' in props.errors) {
    zipCodeErrorClass = ' error';
    zipCodeErrorNode = (
      <div className="tc__error--relative ">{props.errors.schoolZipcode}</div>
    );
  }

  // disable buttons when form submission is loading
  const disabledClass = props.loading ? ' disabled' : '';
  const spinnerNode = <Spinner loading={props.loading} />;

  // Setup the main form based on which mode we are in
  let formNode;
  switch (props.data.mode) {
    case 'combine':
      formNode = (
        <form className="cute-6-desktop cute-12-phone center-desktop participant-profile__school-modal-form">
          <div className="registration__form-item tc__body-copy">School</div>

          <div className="registration__form-item tc__body-copy">
            {props.data.name}
          </div>

          <div className="registration__form-item tc__body-copy">
            <div className="tc__body-copy">Zip Code</div>
          </div>

          <div className={`registration__form-item ${zipCodeErrorClass}`}>
            <SingleSelect
              {...props.schoolZipcode}
              feature="schoolModal"
              field="schoolZipcode"
              minCharCountForResults={5}
              restrictTo={'^[0-9]*$'}
              handleFocus={(e) => handleFocus(e, 'schoolZipcode')}
              updateSingleSelect={props.selectSingleSelectElement}
              getVisibleOptions={props.getSingleSelectElement}
              onChangeDelay="700"
            />
            {zipCodeErrorNode}
          </div>

          <div className="registration__submit-btns">
            <button
              onClick={handleSubmitCombine}
              className={`registration__submit-btn tc__btn tc__btn--flat-blue ${disabledClass}`}
            >
              {spinnerNode}
              Combine
            </button>
            &nbsp;
            <button
              onClick={props.close}
              className={`tc__btn tc__btn--flat-grey ${disabledClass}`}
            >
              Cancel
            </button>
          </div>
        </form>
      );
      break;
    case 'edit':
      formNode = (
        <form className="cute-6-desktop cute-12-phone center-desktop participant-profile__school-modal-form">
          <div className="registration__form-item">
            <div className="registration__input-label">School Name</div>
            <input
              type="text"
              ref={(ref) => (schoolName = ref)}
              onFocus={(e) => handleFocus(e, 'name')}
              className={`registration__input tc__input ${nameErrorClass}`}
              defaultValue={props.data.name}
            />
            {nameErrorNode}
          </div>

          <div className="registration__form-item">
            <div className="registration__input-label">Zip Code</div>
            <input
              type="text"
              ref={(ref) => (schoolZipcode = ref)}
              onFocus={(e) => handleFocus(e, 'schoolZipcode')}
              className={`registration__input tc__input ${zipCodeErrorClass}`}
              defaultValue={props.data.mailing_zipcode}
            />
            {zipCodeErrorNode}
          </div>

          <div className="registration__submit-btns">
            <button
              onClick={handleSubmitEdit}
              className={`tc__btn tc__btn--v3 tc__btn--flat-blue ${disabledClass}`}
            >
              {spinnerNode}
              Save
            </button>
            &nbsp;
            <button
              onClick={props.close}
              className={`tc__btn tc__btn--flat-grey ${disabledClass}`}
            >
              Cancel
            </button>
          </div>
        </form>
      );
      break;
    default:
      formNode = (
        <form className="cute-6-desktop cute-12-phone center-desktop participant-profile__school-modal-form">
          <div className="registration__form-item">
            Are you sure you want to approve this school?
          </div>
          <div className="registration__submit-btns">
            <button
              onClick={handleSubmitApprove}
              className={`tc__btn tc__btn--v3 tc__btn--flat-blue ${disabledClass}`}
            >
              {spinnerNode}
              Approve
            </button>
            &nbsp;
            <button
              onClick={props.close}
              className={`tc__btn tc__btn--flat-grey ${disabledClass}`}
            >
              Cancel
            </button>
          </div>
          {nameErrorNode}
          {zipCodeErrorNode}
        </form>
      );
      break;
  }

  return (
    <div onClick={handleStopPropagation}>
      <div
        className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
        onClick={props.close}
      ></div>

      <div className="modal--fixed modal--grey-overlay ppd-v3-curation-modal">
        <div
          className="modal__close hide-phone show-desktop"
          onClick={props.close}
        >
          <div className="tc-x"></div>
        </div>

        <h2 className="pinboard__comment-headline tc__headline-2 hide-phone show-desktop">
          {modalCaption}
        </h2>

        <div className="tc__mobile-header show-phone hide-desktop">
          <div className="tc__back cute-2-desktop left" onClick={props.close}>
            <div className="tc__back-arrow tc-arrow"></div>
            <div className="tc__back-caption">Back</div>
          </div>
          <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
            {modalCaption}
          </div>
        </div>

        {formNode}
      </div>
      <FreezeWindow />
    </div>
  );
};

SchoolModal.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  approve: PropTypes.func.isRequired,
  combine: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  getSingleSelectElement: PropTypes.func.isRequired,
  selectSingleSelectElement: PropTypes.func.isRequired,
};

export default SchoolModal;
