// @ts-nocheck FIXME
import Analytics from '@legacy-components/components/Analytics';
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';

const SingleSelect = (props) => {
  const feature = props.feature ? props.feature : 'pinDialogue';

  let textInput;

  // We would like to wait for the user to stop typing before we fire calls to the server
  let onChangeTimeout = null;

  const handleSelectClick = (e) => {
    e.stopPropagation();

    if (props.readOnly) {
      return false;
    }

    textInput.focus();
    props.updateSingleSelect({
      feature: feature,
      field: props.field,
      data: { inputStatus: 'focus' },
    });

    delayedEventListener(() =>
      window.addEventListener('click', closeOptionsDropdown)
    );
  };

  const handleSelectOption = (option, e) => {
    e.preventDefault();

    if (props.readOnly) {
      return false;
    }

    if (!props.analyticsLoggedByStore) {
      // Log the Analytics event:
      const action =
        props.analyticsCategory === 'school_info' ? 'select_school' : 'select';
      const analyticsEvent = {
        category: props.analyticsCategory || props.field,
        action: action,
        label: props.analyticsLabel,
      };
      Analytics.logEvent(analyticsEvent);
    }

    textInput.value = option.label;
    const data = {
      visibleOptions: [],
      selectedOption: option,
      charCount: 0,
      inputStatus: 'blur',
      // Indicates that an option was selected
      selected: true,
    };
    props.updateSingleSelect({
      feature: feature,
      field: props.field,
      data: data,
    });
  };

  const handleRemoveOption = (e) => {
    e.preventDefault();

    if (props.readOnly) {
      return false;
    }

    if (!props.analyticsLoggedByStore) {
      // Log the Analytics event:
      const analyticsEvent = {
        category: props.analyticsCategory || props.field,
        action: 'remove',
        label: props.analyticsLabel,
      };
      Analytics.logEvent(analyticsEvent);
    }

    textInput.value = '';

    props.updateSingleSelect({
      field: props.field,
      feature: feature,
      data: {
        visibleOptions: [],
        selectedOption: null,
        inputStatus: 'blur',
        // Indicates that an option has been removed
        removed: true,
      },
    });

    // If the parent provided a remove option handler, call it
    if (
      props.handleRemoveOption &&
      typeof props.handleRemoveOption === 'function'
    ) {
      props.handleRemoveOption(e);
    }
  };

  const handleFocus = (e) => {
    if (props.readOnly) {
      return false;
    }

    const input = textInput.value.toLowerCase();
    if (input.length >= props.minCharCountForResults) {
      props.getVisibleOptions({
        feature: feature,
        field: props.field,
        input: input,
      });
    }

    props.updateSingleSelect({
      feature: feature,
      field: props.field,
      data: { inputStatus: 'focus' },
    });
    // If the parent provided a focus handler, call it
    if (props.handleFocus && typeof props.handleFocus === 'function') {
      props.handleFocus(e);
    }

    // set the focus analytics if data is available
    if (props.focusAnalytics) {
      Analytics.logEvent({
        category: props.focusAnalytics.category,
        action: props.focusAnalytics.action,
        label: props.focusAnalytics.label,
      });
    }
  };

  const closeOptionsDropdown = () => {
    if (props.readOnly) {
      return false;
    }

    window.removeEventListener('click', closeOptionsDropdown);
    props.updateSingleSelect({
      feature: feature,
      field: props.field,
      data: { inputStatus: 'blur' },
    });
  };

  const handleTyping = () => {
    if (props.readOnly) {
      return false;
    }

    if (props.restrictTo) {
      const restrictTo = new RegExp(props.restrictTo);
      if (!restrictTo.test(textInput.value)) {
        textInput.value = textInput.value.slice(0, -1);
      }
    }

    // Parent component may request to delay firing the event (e.g. 500 ms)
    // to give the user some room to finish typing
    const onChangeDelay = props.onChangeDelay || 0;

    clearTimeout(onChangeTimeout);

    onChangeTimeout = setTimeout(function () {
      // Handle non-existing input
      if (!textInput) {
        return;
      }
      const input = textInput.value.toLowerCase();
      if (!props.analyticsLoggedByStore) {
        props.updateSingleSelect({
          feature: feature,
          field: props.field,
          data: {
            visibleOptions: [],
            charCount: input.length,
          },
        });
      }
      if (input.length >= props.minCharCountForResults) {
        if (!props.analyticsLoggedByStore) {
          // Log the Analytics event:
          Analytics.logEvent({
            category: props.analyticsCategory || props.field,
            action: 'search',
            label: props.analyticsLabel,
          });
        }
        props.getVisibleOptions({
          feature: feature,
          field: props.field,
          input: input,
        });
      }
    }, onChangeDelay);
  };

  const availableNodes = props.visibleOptions
    .filter((option) => {
      // when the input is blurred... nothing is available
      if (
        props.inputStatus === 'blur' ||
        props.charCount < props.minCharCountForResults
      ) {
        return false;
      }
      // if the value is selected, it is not available
      if (props.selectedOption && props.selectedOption.id === option.id) {
        return false;
      }
      return true;
    })
    .map((option, i) => {
      return (
        <div
          className="custom-select__option custom-select__option--variable-height"
          onClick={(e) => handleSelectOption(option, e)}
          key={props.field + '-' + option.id + '-' + i}
        >
          <div>{option.label}</div>
          <div className="custom-select__option-description">
            {option.description}
          </div>
        </div>
      );
    });

  // only show the options menu if there are visible options
  let optionsNode = null;
  if (availableNodes.length) {
    optionsNode = (
      <div className="custom-select__options">{availableNodes}</div>
    );
  }

  // add the id is it has been passed in
  const customId = {};
  if (props.customId) {
    customId.id = props.customId;
  }

  let singleSelectClass = 'custom-select__selected-options--empty';
  let selectedNode = '';
  if (props.selectedOption) {
    let removeNode;
    if (!props.readOnly) {
      removeNode = (
        <div
          onClick={handleRemoveOption}
          className="custom-select__selected-remove tc-x"
        ></div>
      );
    }
    singleSelectClass = 'custom-select__selected-options--selected';
    selectedNode = (
      <div className="custom-select__selected custom-select__selected--single-select">
        <div className="custom-select__selected-name">
          {props.selectedOption.label}
        </div>
        {removeNode}
      </div>
    );
  }

  return (
    <div className="custom-select__container">
      <div className="custom-select tc__input" onClick={handleSelectClick}>
        <div className={`custom-select__selected-options ${singleSelectClass}`}>
          <input
            type="text"
            {...customId}
            ref={(ref) => (textInput = ref)}
            className="tc__input tc__input--custom-select tc__input--custom-single-select tc__input--custom-select-empty"
            placeholder={props.placeholder}
            defaultValue={props.selectedOption && props.selectedOption.label}
            readOnly={props.selectedOption}
            onChange={handleTyping}
            onFocus={handleFocus}
          />
          {selectedNode}
        </div>
      </div>
      {optionsNode}
    </div>
  );
};

SingleSelect.propTypes = {
  field: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  inputStatus: PropTypes.string.isRequired,
  minCharCountForResults: PropTypes.number,
  charCount: PropTypes.number,
  selectedOption: PropTypes.object,
  visibleOptions: PropTypes.array,
  updateSingleSelect: PropTypes.func,
  getVisibleOptions: PropTypes.func,
  analyticsCategory: PropTypes.string,
  analyticsLabel: PropTypes.string,
  customId: PropTypes.string,
  analyticsLoggedByStore: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  restrictTo: PropTypes.string,
};

SingleSelect.defaultProps = {
  minCharCountForResults: 2,
  analyticsLoggedByStore: false,
  readOnly: false,
};

export default SingleSelect;
