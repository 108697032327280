import { AccessToken, CookieToken } from '@app-types/Token';
import jwtDecode from 'jwt-decode';
import {
  UserManager as BaseUserManager,
  State,
  User,
  UserProfile,
} from 'oidc-client-ts';

type RefreshState = State & {
  id: never;
  created: never;
  request_type: undefined;
  data: unknown | undefined;
  refresh_token: string;
  id_token: string;
  scope: string;
};

export class UserManager extends BaseUserManager {
  static get userStoreKey(): string {
    return `user:${process.env.REACT_APP_USER_SERVICE_URL}/api/v1:${process.env.REACT_APP_CLIENT_ID}`;
  }

  private buildUserFromTokens = ({
    id_token,
    access_token,
    refresh_token,
  }: CookieToken): User => {
    const { exp } = jwtDecode<AccessToken>(access_token);

    return new User({
      id_token,
      session_state: null,
      access_token,
      refresh_token,
      token_type: 'Bearer',
      scope: this.settings.scope,
      profile: jwtDecode<UserProfile>(id_token),
      expires_at: exp,
    });
  };

  async _loadUser(): Promise<User | null> {
    const tokenString = await this.settings.userStore.get(
      UserManager.userStoreKey
    );

    if (!tokenString) {
      return null;
    }

    const token: CookieToken = JSON.parse(tokenString);
    const user = this.buildUserFromTokens(token);
    return this._refreshUser(user);
  }

  async _refreshUser(user: User): Promise<User | null> {
    if (!user?.expired) {
      return user;
    }

    if (!user.refresh_token) {
      await this.storeUser(null);
      return null;
    }

    try {
      const refreshState = user as unknown;
      const refreshedUser = await this._useRefreshToken(
        refreshState as RefreshState
      );

      return refreshedUser;
    } catch (err) {
      console.error(err);
      await this.storeUser(null);
      return null;
    }
  }

  public async storeUser(user: User | null): Promise<void> {
    if (user?.id_token && user?.refresh_token) {
      await this.settings.userStore.set(
        UserManager.userStoreKey,
        JSON.stringify({
          id_token: user.id_token,
          access_token: user.access_token,
          refresh_token: user.refresh_token,
        })
      );
      return;
    }

    await this.settings.userStore.remove(UserManager.userStoreKey);
  }
}
