// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../../components/DynamicUtils';
import { Router } from './../../../../components/Router';
import NavigationLink from './../../../NavigationLink';

const DomainCapsule = (props) => {
  // clicking on Browse routes to search strategies filtered by this domain
  const handleBrowseStrategies = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Router.route(
      process.env.REACT_APP_TEACHING_URL +
        '/search?focusAreas=' +
        props.id +
        '&from=learning_domains_overview'
    );
  };

  let domainUrl =
    process.env.REACT_APP_TEACHING_URL + '/browse/learning-domain/' + props.id;
  if (props.seo_url) {
    domainUrl += '/' + props.seo_url;
  }

  // if the domain does not have its own icon, use the default icon
  const domainIdForHeroImage = Utils.getDomainIdForHeroImage(props.id, 'small');
  const domainIdForIcon = Utils.getDomainIdForIcon(props.id);

  return (
    <NavigationLink
      url={domainUrl + '?from=learning_domains_overview'}
      title={props.title}
      className="bl4__capsule domains__capsule tc__body-copy"
    >
      <img
        src={
          '/legacy-images/learning_domains/small/' +
          domainIdForHeroImage +
          '.webp'
        }
        alt={props.title}
        className="domains__capsule-image"
      />
      <div
        className={'domains__capsule-icon tc-v4-domain-' + domainIdForIcon}
      ></div>
      <div className="domains__capsule-headline">{props.title}</div>
      <div className="domains__capsule-footer">
        <div className="domains__capsule-footer-links">
          <div className="domains__capsule-footer-link domains__capsule-footer-link--learn">
            Learn
          </div>
          <div
            className="domains__capsule-footer-link"
            onClick={handleBrowseStrategies}
          >
            Browse
          </div>
        </div>
      </div>
    </NavigationLink>
  );
};

DomainCapsule.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  seo_url: PropTypes.string,
};

export default DomainCapsule;
