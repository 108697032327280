// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';

const DataPoint = (props) => {
  // insert extra styles into the text to bold numbers and numbers + %
  const boldDataPointValue = () => {
    let formattedDataPoint = '';
    // split the text into chunks of numbers, numbers + %, and other
    let splitDataPoint = props.data.text.split(/([0-9]+\%|[0-9]+)/); //eslint-disable-line

    // recombine the text, adding extra styles to the chunks that have numbers
    splitDataPoint.forEach((text) => {
      if (/[0-9]+/.test(text)) {
        formattedDataPoint +=
          '<span class="ppp__datapoint-value">' + text + '</span>';
      } else {
        formattedDataPoint += text;
      }
    });

    return formattedDataPoint;
  };

  const formattedDataPoint = boldDataPointValue();

  return (
    <div className="row">
      <div className="ppp__elem ppp__elem--datapoint cute-10-desktop cute-1-desktop-offset left">
        <PurifiedHTML className="ppp__datapoint" content={formattedDataPoint} />
      </div>
    </div>
  );
};

DataPoint.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DataPoint;
