// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';

const MeetingRow = (props) => {
  const handleStatusChange = (e) => {
    if (e.target.value === '3' || e.target.value === '5') {
      props.meetingEmailObject.setMeetingObject({
        meetingStatus: e.target.value,
        meetingTimeFormatted: props.meeting_time_formatted,
        internalNotes: props.internal_notes,
        meetingId: props.id,
        whenCancelled: props.when_cancelled_formatted,
        startChecked: !(
          parseInt(props.status) === 3 || parseInt(props.status) === 4
        ),
        isMobile: props.isMobile,
      });
      props.meetingEmailObject.setCancellation(true);
    } else if (e.target.value === '4') {
      props.meetingEmailObject.setMeetingObject({
        meetingTimeFormatted: props.meeting_time_formatted,
        meetingId: props.id,
        startChecked: !(
          parseInt(props.status) === 3 || parseInt(props.status) === 4
        ),
        isMobile: props.isMobile,
      });
      props.meetingEmailObject.setNoShow(true);
    } else {
      const data = {
        participantId: props.participantId,
        coachingEngagementId: props.coachingEngagementId,
        meetingId: props.id,
        status: e.target.value,
      };

      props.updateMeetingStatus(data);
    }
  };

  const timeZone = props.userTimezone;
  const dateTime = moment(props.meeting_time_formatted).tz(timeZone);
  // Time should display in the user's timezone
  const dateTimeString = dateTime.format(
    `MMMM D, YYYY, ${Utils.hourRounder(dateTime)} A`
  );

  // Meetings that have been cancelled can't have their status changed
  const statusDisabledClass =
    props.status === 3 || props.status === 5 ? 'disabled' : '';

  let statusNode = (
    <select
      className={`participant-v5__section-dropdown tc__select ${statusDisabledClass}`}
      value={props.status}
      onChange={handleStatusChange}
      data-testid={`meeting-${props.id}-status${
        props.isMobile ? '-mobile' : ''
      }`}
    >
      {Object.keys(props.validStatuses).map((status) => (
        <option key={`status-${status}`} value={status}>
          {props.validStatuses[status]}
        </option>
      ))}
    </select>
  );

  let iconNode = null;

  if (
    props.status === 1 &&
    moment().isAfter(moment(props.meeting_time_formatted))
  ) {
    // Show change status icon
    iconNode = (
      <div className="participant-v5__meeting--todo status">
        <span className="tc-v5-alert icon"> </span>
        <span className="show-desktop hide-phone">Update status</span>
      </div>
    );
  } else if (props.status === 2 && !props.meeting_notes) {
    iconNode = (
      <NavigationLink
        className="participant-v5__meeting--todo add-notes"
        url={`/lab/participant/${props.participantId}/meeting/${props.id}/details?isEditingMeetingNotes=true`}
      >
        <span className="tc-v5-notes icon"> </span>
        <span className="show-desktop hide-phone">Add notes</span>
      </NavigationLink>
    );
  }

  let zoomSyncIconNode;
  if (props.zoom_meeting_id) {
    zoomSyncIconNode = (
      <span
        className="tc-v5-sync participant-v5__meeting-zoom-icon"
        title="Connected to Zoom"
      ></span>
    );
  }

  let videoIconNode;
  if (props.recordings && props.recordings.length && props.canEditData) {
    videoIconNode = (
      <NavigationLink
        className="participant-v5__meeting--video-icon"
        url={`/lab/participant/${props.participantId}/meeting/${props.id}/details?scrollToSection=videos`}
      >
        <span className="tc-v5-meeting-recording icon"> </span>
      </NavigationLink>
    );
  }

  let styleClass = ' text-node';

  if (!props.canEditData || props.isArchived) {
    statusNode = props.validStatuses[props.status];
    iconNode = null;
    styleClass = '';
  }

  return (
    <div className="participant-v5__meeting-row">
      <div
        className={`participant-v5__meeting-date participant-v5__flush-left${styleClass}`}
      >
        <NavigationLink
          className="participant-v5__meeting--date-time v5__link"
          url={`/lab/participant/${props.participantId}/meeting/${props.id}/details`}
        >
          {dateTimeString}
        </NavigationLink>
        {zoomSyncIconNode}
        {videoIconNode}
      </div>
      <div className="participant-v5__meeting-status participant-v5__header-text">
        {statusNode}
      </div>
      <div className="participant-v5__meeting-cta text-node">{iconNode}</div>
      <div className="clearfix"></div>
    </div>
  );
};

MeetingRow.propTypes = {
  id: PropTypes.number.isRequired,
  meeting_time_formatted: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  participantId: PropTypes.number,
  updateMeetingStatus: PropTypes.func,
  userTimezone: PropTypes.string.isRequired,
  meetingEmailObject: PropTypes.shape({
    setCancellation: PropTypes.func.isRequired,
    setNoShow: PropTypes.func.isRequired,
    setMeetingObject: PropTypes.func.isRequired,
  }).isRequired,
  validStatuses: PropTypes.shape({
    1: PropTypes.string,
    2: PropTypes.string,
    3: PropTypes.string,
    4: PropTypes.string,
  }),
};

export default MeetingRow;
