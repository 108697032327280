// @ts-nocheck FIXME
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const CoachMatchUser = (props) => {
  let isReturningNode = null;
  let mobileIsReturningNode = null;
  if (props.participant.is_returning) {
    isReturningNode = (
      <div className="coach-match__returning v5__body-copy v5__body-copy--bold">
        Returning
      </div>
    );
    mobileIsReturningNode = (
      <div className="coach-match__returning coach-match__returning--mobile v5__body-copy v5__body-copy--bold">
        Returning
      </div>
    );
  }

  return (
    <div>
      {mobileIsReturningNode}
      {props.children}
      <div className="coach-match__user">
        <UserPhoto
          src={props.user.photo}
          userId={props.user.id}
          firstName={props.user.first_name}
          lastName={props.user.last_name}
          diameter={60}
        />
        <div className="coach-match__participant">
          <div className="coach-match__name-container">
            <div className="v5__heading-4">
              {props.user.first_name} {props.user.last_name}
            </div>
            {isReturningNode}
          </div>
          <div className="">
            {props.participant.role || ''},{' '}
            {(props.user.institution && props.user.institution.name) || ''} (
            {moment.tz(props.user.time_zone.pytz_timezone).zoneAbbr()})
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachMatchUser;

CoachMatchUser.propTypes = {
  participant: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
