// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { Router } from './../../components/Router';
import AbsoluteAlert from './../Alerts/AbsoluteStateless';
import Header from './../Header';
import PartnerUserAdd from './PartnerUserAdd';
import PartnerUserRow from './PartnerUserRow';

const PartnerUsersList = (props) => {
  // event listeners
  const handleShowPartners = (e) => {
    e.preventDefault();
    Router.route('/bl/v1/partners');
  };

  const handleShowPartnerUsers = (e) => {
    e.preventDefault();
    Router.route('/bl/v1/partner/' + props.partner.id);
  };

  const handlePartnerUserAddModal = (e) => {
    e.preventDefault();
    props.toggleModal({ feature: 'addPartnerUser' });
  };

  /* BEGIN RENDERING LOGIC */

  // set up partner rows
  const partnerUserRow = props.partnerUsers.map(function (partnerUser) {
    return (
      <PartnerUserRow
        partnerUser={partnerUser}
        key={'partner-' + partnerUser.id}
      />
    );
  });

  const alertNode = (
    <AbsoluteAlert
      {...props.ui.alert}
      cssClasses={{ container: 'alert-banner--fixed' }}
    />
  );

  return (
    <div>
      <Header page="PartnersUserList" {...props} />

      {alertNode}

      {props.ui.addPartnerUser.modalDisplayed && (
        <PartnerUserAdd
          partnerId={props.partner.id}
          toggleModal={props.toggleModal}
          addPartnerUser={props.addPartnerUser}
          isLoading={props.ui.addPartnerUser.isLoading}
          error={props.ui.addPartnerUser.error}
          notifications={props.ui.addPartnerUser.notifications}
          notificationLink={props.ui.addPartnerUser.notificationLink}
          clearErrorsOnFocus={props.clearErrorsOnFocus}
        />
      )}

      <div className="row">
        <div className="cute-12-desktop">
          <div className="admin-v2__user-page-breadcrumbs">
            <a
              onClick={handleShowPartners}
              className="tc__link"
              href={'/bl/v1/partners'}
            >
              Partners
            </a>
            <span> | Partner Users</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="cute-6-desktop">
          <h3 className="tc__modal-add-headline tc__headline-2">
            {props.partner.name}
          </h3>
        </div>
        <div className="cute-6-desktop">
          <a
            className="bl__btn bl__btn--small bl__btn--blue bl__btn--add-partner"
            onClick={handlePartnerUserAddModal}
          >
            Add User to Partner
          </a>
          <a
            className="bl__btn bl__btn--small bl__btn--blue bl__btn--add-partner"
            onClick={handleShowPartnerUsers}
          >
            Edit Partner
          </a>
        </div>
      </div>

      <div className="row">
        <table className="admin-table">
          <tbody>
            <tr>
              <th className="admin-table__header">Name</th>
              <th className="admin-table__header">Email</th>
              <th className="admin-table__header">Pathway</th>
              <th className="admin-table__header">Term Start</th>
              <th className="admin-table__header">Term End</th>
            </tr>
            {partnerUserRow}
          </tbody>
        </table>
      </div>
    </div>
  );
};

PartnerUsersList.propTypes = {
  partner: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default PartnerUsersList;
