// @ts-nocheck FIXME
import React from 'react';
import Utils from './../../../components/StaticUtils';
import TmlProgressChart from './../../V2/TmlLoop/TmlProgressChart';

class MeasurementV2 extends React.Component {
  render() {
    let resultsNode = (
      <div className="portfolio-v2__measurement-results">
        <span className="highlight">{this.props.measurementValue}</span> out of{' '}
        <span className="highlight">
          {this.props.measurementTotal} ({this.props.qualitativePercentage}%)
        </span>
      </div>
    );
    if (this.props.isInstructionalDesign) {
      resultsNode = (
        <div className="portfolio-v2__measurement-results">
          <span className="highlight">
            {this.props.qualitativePercentage / 10}
          </span>{' '}
          out of <span className="highlight">10</span>
        </div>
      );
    }

    const measurementChart = (
      <div className="portfolio-v2__measurement-chart-container">
        <TmlProgressChart
          isInstructionalDesign={this.props.isInstructionalDesign}
          baseline={this.props.baseline.qualitative_percentage}
          goal={this.props.goal.qualitative_percentage}
          measurementPercent={this.props.qualitativePercentage}
          measurementValue={this.props.measurementValue}
        />
      </div>
    );
    return (
      <div className="portfolio-v2__loop-section">
        <div className="portfolio-v2__strategy-headline">THE RESULTS</div>
        <div className="portfolio-v2__measurement-metric">
          <span className="label">I am looking for:</span>{' '}
          {Utils.getMetricName(
            this.props.metric.name,
            this.props.metric.default_n
          )}
        </div>
        <div className="portfolio-v2__measurement-results-section">
          <div>
            {this.props.isInstructionalDesign
              ? 'Teacher Success'
              : 'Student Success'}
          </div>
          {resultsNode}
        </div>
        {measurementChart}
      </div>
    );
  }
}

export default MeasurementV2;
