// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import StorageHelper from '@legacy-components/components/StorageHelper';
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const CallsToAction = (props) => {
  const lab = useLegacyLabContext();
  const userTimeZone = props.pageUser.time_zone.pytz_timezone;
  const currentTime = moment().tz(userTimeZone);
  const fiveMinutes = moment.duration(5, 'minutes');

  const nextMeeting =
    props.upcomingMeetings.length && props.upcomingMeetings[0];

  // set time that the next meeting starts
  const nextMeetingTime =
    nextMeeting &&
    moment(
      nextMeeting.meeting_time_formatted || nextMeeting.start_time_formatted
    ).tz(userTimeZone);
  // set time to display button 5 minutes before the meeting
  const beforeMeetingTime = nextMeetingTime - fiveMinutes;
  // set length of meeting
  const meetingLength = moment.duration(nextMeeting.meeting_length, 'minutes');
  // set time to hide the meeting capsule after the meeting has ended
  const endMeetingTime = moment(nextMeetingTime) + meetingLength;

  const [meetingStarted, setMeetingStarted] = useState(
    currentTime.isSameOrAfter(beforeMeetingTime) &&
      currentTime.isBefore(endMeetingTime)
  );
  const [meetingEnded, setMeetingEnded] = useState(
    currentTime.isSameOrAfter(endMeetingTime)
  );
  const [zoomCtaDismissed, setZoomCtaDismissed] = useState(
    StorageHelper.getItem('zoomCtaDismissed')
  );
  const [outstandingSurveysCtaDismissed, setOutstandingSurveysCtaDismissed] =
    useState(StorageHelper.getItem('outstandingSurveysCtaDismissed'));

  useEffect(() => {
    // If there is no meeting or the meeting is more than 24 days away, don't do anything
    if (
      nextMeeting.length ||
      nextMeetingTime - currentTime >= moment.duration(2147483647)
    ) {
      return;
    }

    let startMeeting;
    let endMeeting;

    // Select the next meeting and calculate the time until it starts
    const timeUntilMeetingStart = moment.duration(
      nextMeetingTime - currentTime
    );

    if (timeUntilMeetingStart.asMinutes() <= fiveMinutes.asMinutes()) {
      // set a timeout to fire when the meeting ends
      endMeeting = setTimeout(() => {
        setMeetingStarted(false);
        setMeetingEnded(true);
      }, moment.duration(nextMeetingTime - moment().tz(userTimeZone)).asMilliseconds() + meetingLength.asMilliseconds());
    } else if (timeUntilMeetingStart.asMinutes() > fiveMinutes.asMinutes()) {
      // set a timeout to fire when the meeting starts
      startMeeting = setTimeout(() => {
        setMeetingStarted(true);
        // set a timeout to fire when the meeting ends
        endMeeting = setTimeout(() => {
          setMeetingStarted(false);
          setMeetingEnded(true);
        }, moment.duration(nextMeetingTime - moment().tz(userTimeZone)).asMilliseconds() + meetingLength.asMilliseconds());
      }, timeUntilMeetingStart.asMilliseconds() - fiveMinutes.asMilliseconds());
    }

    // clear the timeouts on unmount
    return () => {
      clearTimeout(startMeeting);
      clearTimeout(endMeeting);
    };
  }, []);

  const dismissZoomCta = () => {
    StorageHelper.setItem('zoomCtaDismissed', true);
    setZoomCtaDismissed(true);
  };

  const handleOutstandingSurveyCtaDismiss = () => {
    StorageHelper.setItem('outstandingSurveysCtaDismissed', true);
    setOutstandingSurveysCtaDismissed(true);
  };

  const callToActions = [];

  if (Utils.isParticipant.call(lab, props.pageUser)) {
    // use the meeting's unique Zoom link if it has one, or default to the coach's general Zoom link
    const joinMeetingLink = nextMeeting.zoom_join_link || props.zoomLink;
    // Start meeting (5-min before through the end of the meeting time)
    if (joinMeetingLink && nextMeeting && meetingStarted && !meetingEnded) {
      callToActions.push(
        <div className="home-v5__cta" key={'cta-coach-meeting'}>
          <div className="tc-v5-video home-v5__cta-icon" />
          <div className="home-v5__cta-details">
            <div className="home-v5__cta-copy v5__body-copy">
              <span>Time to meet with your coach!</span>
            </div>
            <a
              href={joinMeetingLink}
              target="_blank"
              className="home-v5__cta-btn v5__btn"
              rel="noreferrer"
            >
              Join Meeting
            </a>
          </div>
        </div>
      );
    }
    // Upload profile picture (stays on the page until they’ve uploaded profile picture)
    // only appears for participants with a coaching product (currently, any product)
    if (!props.pageUser.photo && props.participant.product_id) {
      callToActions.push(
        <div className="home-v5__cta" key={'cta-complete-profile'}>
          <div className="tc-v5-profile home-v5__cta-icon" />
          <div className="home-v5__cta-details">
            <div className="home-v5__cta-copy v5__body-copy">
              <span>Complete your profile.</span> Don’t be a stranger—upload
              your photo and write a short bio.
            </div>
            <NavigationLink
              url={'/account/profile'}
              className="home-v5__cta-btn v5__btn"
            >
              Complete Profile
            </NavigationLink>
          </div>
        </div>
      );
    }
  }

  // Set up Install Zoom CTA
  // (stays on the page until they have dismissed the CTA,
  // completed a meeting, or checked in for an event session)
  if (
    Utils.isParticipant.call(lab, props.pageUser) &&
    !zoomCtaDismissed &&
    !props.hasCompletedMeeting &&
    (!props.checkedInSessions ||
      !ArrayHelpers.hasElements(Object.keys(props.checkedInSessions)))
  ) {
    // show Install Zoom CTA
    callToActions.push(
      <div className="home-v5__cta" key={'cta-install-zoom'}>
        <div className="tc-v5-video home-v5__cta-icon" />
        <div className="home-v5__cta-details">
          <div className="home-v5__cta-copy v5__body-copy">
            <span>Install Zoom.</span> Be ready to join your first meeting with
            our coaches.
          </div>
          <div
            className="show-desktop hide-phone v5__link home-v5__cta-dismiss"
            onClick={dismissZoomCta}
          >
            Dismiss
          </div>
          <div>
            <a
              href="https://zoom.us/download"
              target="_blank"
              className="home-v5__cta-btn v5__btn"
              rel="noreferrer"
            >
              Install Zoom
            </a>
            <div
              className="hide-desktop show-phone-inline v5__link home-v5__cta-dismiss"
              onClick={dismissZoomCta}
            >
              Dismiss
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (
    props.participant &&
    props.participant.has_outstanding_surveys &&
    !outstandingSurveysCtaDismissed
  ) {
    callToActions.push(
      <div className="home-v5__cta survey-cta" key={'cta-survey'}>
        <div className="tc-v5-survey-icon home-v5__cta-icon" />
        <div className="home-v5__cta-details">
          <div className="home-v5__cta-copy v5__body-copy">
            <span>We need your insight!</span> Tell us about your learning
            experiences.
          </div>
          <div
            className="show-desktop hide-phone v5__link home-v5__cta-dismiss"
            onClick={handleOutstandingSurveyCtaDismiss}
          >
            Dismiss
          </div>
          <div>
            <a
              href={`${process.env.REACT_APP_SURVEY_URL}/outstanding`}
              target="_blank"
              className="home-v5__cta-btn v5__btn"
              rel="noreferrer"
            >
              Complete Surveys
            </a>
            <div
              className="hide-desktop show-phone-inline v5__link home-v5__cta-dismiss"
              onClick={handleOutstandingSurveyCtaDismiss}
            >
              Dismiss
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div className="home-v5__ctas">{callToActions}</div>;
};

CallsToAction.propTypes = {
  pageUser: PropTypes.object.isRequired,
  upcomingMeetings: PropTypes.array.isRequired,
  pastEvents: PropTypes.array.isRequired,
  hasCompletedMeeting: PropTypes.bool,
  zoomLink: PropTypes.string,
  coach: PropTypes.object,
  participant: PropTypes.object.isRequired,
};

export default CallsToAction;
