// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import createReactClass from 'create-react-class';
import Analytics from './../../components/Analytics';
import { History } from './../../components/Router';
import BrowserStore from './../../stores/V2/BrowserStore';
import BrowserSGAV2 from './Strategy/BrowserSGA';
import SearchBar from './Strategy/SearchBar';
import SearchTab from './Strategy/SearchTab';

const StrategyBrowserV2 = createReactClass({
  displayName: 'StrategyBrowserV2',

  getInitialState: function () {
    BrowserStore.actionDispatchSync('init', this.props);
    return BrowserStore.getState();
  },

  componentDidMount: function () {
    this.token = BrowserStore.subscribe(this.onStoreChange);
  },

  componentWillUnmount: function () {
    BrowserStore.unsubscribe(this.token);
  },

  componentWillReceiveProps: function (nextProps) {
    BrowserStore.actionDispatchSync('init', nextProps);
  },

  onStoreChange: function () {
    // eslint-disable-next-line react/no-is-mounted
    if (this.isMounted()) {
      this.setState(BrowserStore.getState());
    }
  },

  handleSelectCatagory: function (subjectId, e) {
    e.preventDefault();

    // if already selected do nothing
    if (this.state.activeCategory == subjectId && !this.state.searchBarOpened) {
      return;
    }

    // Get the subject name from the tree:
    const subjectNames = this.props.tree.filter(
      function (sub) {
        return sub.id === subjectId;
      }.bind(subjectId)
    );

    // Record the event in GA:
    Analytics.logEvent({
      category: 'strategy',
      action: 'change_content_area',
      label: subjectNames[0].name,
    });

    // update application state
    BrowserStore.actionDispatchSync('selectCategory', subjectId);

    // update URL in history
    let seoUrl;
    this.props.tree.map(function (category) {
      if (category.id == subjectId) {
        seoUrl = category.seo_url;
      }
    });

    History.replace({}, '', '/coaching/lab/strategy-browser-' + seoUrl);
  },

  render: function () {
    // open or close the menu
    const menuOpendedClass = this.state.menuOpened ? ' active' : '';

    // empty arrays to be set up for dom elements
    const catagoryTabNodes = [];
    const catagoryMenuNodes = [];
    let activeCategoryName = '';

    // loop through catagories to set up the tabs and menus
    for (let i = 0; i < this.props.tree.length; i++) {
      const category = this.props.tree[i];

      // check if currently active tab
      let tabActiveClass = '';
      let contentActiveClass = '';
      if (this.state.activeCategory == category.id) {
        if (!this.state.searchBarOpened) {
          tabActiveClass = ' active';
        }
        contentActiveClass = ' active';
        activeCategoryName = category.name;
      }

      // create the tab dom element
      const tabClass = category.name.replace(/\s+/g, '-').toLowerCase();
      const catagoryTab = (
        <a
          key={'category' + category.id}
          className={
            'browser__tab browser-v2__tab browser-v2__tab--' +
            tabClass +
            ' tc__tab tc__headline-4' +
            tabActiveClass
          }
          onClick={this.handleSelectCatagory.bind(this, category.id)}
        >
          {category.name}
        </a>
      );

      // push it to the array
      catagoryTabNodes.push(catagoryTab);

      // set up the catagory sgas
      const SGANodes = category.sga.map(function (sga) {
        // set up the sga teaching challenge links
        return (
          <BrowserSGAV2
            key={category.name + '-' + sga.name + '-links'}
            sga={sga}
          />
        );
      });

      // create the catagory menu
      const catagoryMenu = (
        <div
          key={'menu-' + i}
          className={
            'browser__catagory-menu browser__catagory-menu--v2 cute-12-desktop' +
            contentActiveClass
          }
        >
          {SGANodes}
        </div>
      );

      // push menu to array to be rendered to the dom
      catagoryMenuNodes.push(catagoryMenu);
    }

    // show the TC header
    const headerNode = <Header {...this.props} page="StrategyBrowser" />;

    return (
      <div>
        {headerNode}
        <div className="browser__wrap--tabs">
          <div className="browser__tabs-container tc__center-text row">
            <div className="browser__subject-tabs browser-v2__subject-tabs cute-12-desktop">
              {catagoryTabNodes}
              <SearchTab />
            </div>
          </div>
        </div>
        <SearchBar subject={activeCategoryName} />
        <div className={'browser__menu row' + menuOpendedClass}>
          {catagoryMenuNodes}
        </div>
      </div>
    );
  },
});

export default StrategyBrowserV2;
