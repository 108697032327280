// @ts-nocheck FIXME
import Video from '@legacy-components/react/Utilities/Video';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const EventSessionRecording = (props) => {
  const event = props.event;

  let sessionNode = null;

  if (props.event.num_sessions > 1) {
    sessionNode =
      event.session.name +
      (event.group && event.group.name ? `, ${event.group.name}` : '');
  }

  return (
    <div className="view-event-session-recording tc__page">
      <Header page="EventSessionRecording" {...props} />
      <div className="row event-session-recording">
        <div className="event-session-recording-video">
          <Video
            preview={event.recording.content && event.recording.content.preview}
            formats={event.recording.content && event.recording.content.formats}
            start={event.session.start_time}
            end={event.session.end_time}
            height={320}
            playsinline={true}
          />
        </div>
        <div className="event-session-recording-metadata v5__body-copy">
          <h3 className="v5__heading-2 event-session-recording__event-name">
            {event.display_name}
          </h3>
          <div className="event-session-recording__event-session-name">
            {sessionNode}
          </div>
          <div className="event-session-recording__event-session-date v5__comment-copy">
            {moment(event.session.session_date_formatted).format('LL')}
          </div>
          <div>
            <div className="event-session-recording__section-heading">
              Facilitators
            </div>
            <div className="event-session-recording__event-session-facilitators">
              {event.session.facilitators.map((facilitator) => {
                return (
                  <div
                    key={`facilitator-${facilitator.id}`}
                    className="event-session-recording__event-session-facilitator-container"
                  >
                    <UserPhoto
                      src={facilitator.photo}
                      userId={facilitator.id}
                      firstName={facilitator.first_name}
                      lastName={facilitator.last_name}
                      className="event-session-recording__event-session-facilitator--image"
                      diameter={40}
                    />
                    <span className="event-session-recording__event-session-facilitator--name">
                      <div>
                        {facilitator.first_name} {facilitator.last_name}
                      </div>
                      <div className="event-session-recording__event-session-facilitator--title">
                        BetterLesson Coach
                      </div>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="event-session-recording__section-heading">
              Partner
            </div>
            <div>{event.partner_name}</div>
          </div>
          <div className="event-session-recording__event-attendees">
            {`${
              event.session.participants ? event.session.participants.length : 0
            } Attendee${
              event.session.participants &&
              event.session.participants.length === 1
                ? ''
                : 's'
            }`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSessionRecording;

EventSessionRecording.propTypes = {
  event: PropTypes.shape({
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string.isRequired,
    advanced_event: PropTypes.bool.isRequired,
    partner_id: PropTypes.number.isRequired,
    partner_name: PropTypes.string.isRequired,
    session: PropTypes.shape({
      facilitators: PropTypes.array.isRequired,
    }).isRequired,
    group: PropTypes.object.isRequired,
    recording: PropTypes.object.isRequired,
    time_zone: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      pytz_timezone: PropTypes.string.isRequired,
      is_default: PropTypes.bool.isRequired,
    }),
  }).isRequired,
};
