// @ts-nocheck FIXME

const CarouselButton = (props) => {
  return (
    <div
      onClick={props.buttonHandler}
      className={`carousel__button ${
        props.buttonSelected === props.number ? 'selected' : ''
      }`}
    ></div>
  );
};

export default CarouselButton;
