// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from './../../components/DynamicUtils';
import NavigationLink from './../NavigationLink';
import HeaderDropdown from './Dropdown/HeaderDropdown';
import SelectedParticipantWidget from './ParticipantWidget/SelectedParticipantWidget';
import TeacherViewModal from './ParticipantWidget/TeacherView';

const Header = (props) => {
  const lab = useLegacyLabContext();
  const userRoles = props.user.auth_roles;

  // setup desktop and movbile dropdowns
  const setupDropdownNode = (layout) => {
    return <HeaderDropdown {...props} layout={layout} />;
  };

  // set up the logal link destination
  const setupLogoLink = () => {
    let logoLink = '/?from=header_logo';
    if (Utils.isServicesManager.call(lab) || Utils.isContractCoach.call(lab)) {
      logoLink = '/lab/coach/' + props.user.id + '/home?from=header_logo';
    } else if (Utils.isParticipant.call(lab)) {
      logoLink = '/lab/home?from=header_logo';
    } else if (
      Utils.isPartnerLeader.call(lab) &&
      lab.pageData.user.active_lead === 'v5'
    ) {
      logoLink = `/lab/leader/${lab.pageData.user.leads[0].id}/home`;
    } else if (Utils.isTeacher.call(lab)) {
      logoLink = '/profile?from=header_logo';
    } else if (
      Utils.isAdmin.call(lab) ||
      Utils.isManager.call(lab) ||
      Utils.isDataEditor.call(lab) ||
      Utils.isContentCurator.call(lab)
    ) {
      logoLink = '/bl/admin/home?from=header_logo';
    } else {
      // This will be used for BL team members who have no roles
      logoLink = '/bl/admin/home?from=header_logo';
    }
    return logoLink;
  };

  // participant toggle coach participant view widget
  let hideViewWidget = null;
  let adminToggleWidget;
  let adminOptionsClass = '';
  let teacherViewWidget = null;
  let selectedParticipantWidget = null;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, [
      'TC_DATA_VIEWER',
      'TC_DATA_EDITOR',
      'BL_SERVICES_MANAGER',
      'BL_MANAGER',
      'ADMIN',
    ])
  ) {
    hideViewWidget = (
      <div className="header__view-widget" onClick={props.openTeacherViewModal}>
        <span className="tc-bold-arrow--up tc__link tc-bold-arrow"></span>
      </div>
    );
    if (props.ui.teacherViewModalState) {
      adminOptionsClass = ' tc-header__coach-view--switch--visible';
      teacherViewWidget = (
        <TeacherViewModal
          ui={props.ui}
          submitTeacherViewModal={props.submitTeacherViewModal}
        />
      );
      selectedParticipantWidget = (
        <div className="tc-header__coach-view tc-header__coach-view--actions cute-5-desktop show-desktop hide-phone">
          <SelectedParticipantWidget participant={props.headerParticipant} />
        </div>
      );
      hideViewWidget = (
        <div
          className="header__view-widget"
          onClick={props.closeTeacherViewModal}
        >
          <span className="tc-bold-arrow--left tc__link tc-bold-arrow"></span>
        </div>
      );
    }
    adminToggleWidget = (
      <div
        className={
          'tc-header__coach-view tc-header__coach-view--switch cute-1-desktop left show-desktop hide-phone' +
          adminOptionsClass
        }
      >
        {hideViewWidget}
        {teacherViewWidget}
      </div>
    );
  }

  // if we have no user, show the Sign Up/Login buttons
  let headerNode = (
    <div>
      <div className="tc-header__wrap">
        <div className="tc-header row show-desktop hide-phone">
          <a
            href="/"
            id="tc__logo"
            className="tc-header__logo cute-3-desktop left"
          >
            <span className="tc-header__logo-caption">TeachCycle</span>
          </a>
          <div className="cute-3-desktop">
            <a
              href="/login?redirect=/coaching/lab"
              className="tc-header__visitor-button bl__btn bl__btn--blue"
            >
              <span className="tc-header__login-icon tc-lock"></span>Log In
            </a>
            <a
              href="/register?redirect=/coaching/lab"
              className="tc-header__visitor-button bl__btn bl__btn--orange"
            >
              Sign Up
            </a>
          </div>
        </div>

        <div
          id="tc-mobile-header"
          className="tc-header tc-header--mobile fixed row show-phone hide-desktop"
        >
          <div className="cute-12-phone">
            <a href="/" className="tc-header__logo tc-header__logo--visitor">
              <span className="tc-header__logo-caption">BetterLesson Lab</span>
            </a>
            <a
              href="/login?redirect=/coaching/lab"
              className="tc-header__visitor-button bl__btn bl__btn--blue"
            >
              <span className="tc-header__login-icon tc-lock"></span>Log In
            </a>
            <a
              href="/register?redirect=/coaching/lab"
              className="tc-header__visitor-button tc-header__visitor-button--signup bl__btn bl__btn--orange"
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  // if there is a user logged in, change the logo link
  // and replace the buttons with the dropdown menu and notification icon
  if (props.user && props.user.id) {
    const logoLink = setupLogoLink();

    // necessary for scrolling pages with require fixed headers
    let headerClass = '';
    if (props.page === 'TeacherDashboard') {
      headerClass = ' tc-header--teacher-dashboard';
    } else if (props.page === 'PartnerPortfolioTool') {
      headerClass = ' tc-header--partner-portfolio-tool';
    }

    headerNode = (
      <div>
        <div className={'tc-header__wrap' + headerClass}>
          <div className="tc-header row show-desktop hide-phone">
            <NavigationLink
              url={logoLink}
              id="tc__logo"
              className="tc-header__logo cute-3-desktop left"
            >
              <span className="tc-header__logo-caption">TeachCycle</span>
            </NavigationLink>
            {adminToggleWidget}
            {selectedParticipantWidget}
            <div className="tc-header__rightside">
              <div className="row">
                <div className="header__dropdowns">
                  {setupDropdownNode('desktop')}
                </div>
              </div>
            </div>
          </div>

          <div
            id="tc-mobile-header"
            data-no-layer-close="1"
            className="tc-header tc-header--mobile fixed row show-phone hide-desktop"
          >
            <div className="cute-8-phone cute-2-phone-offset left">
              <NavigationLink url={logoLink} className="tc-header__logo">
                <span className="tc-header__logo-caption">TeachCycle</span>
              </NavigationLink>
            </div>
          </div>
          <div className="header__dropdown-container show-phone hide-desktop">
            {setupDropdownNode('mobile')}
          </div>
        </div>
      </div>
    );
  }

  return headerNode;
};

export default Header;
