// @ts-nocheck FIXME
import Request from './../components/Request';

const ParticipantMeetingApi = {
  add: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/participant/${data.participantId}/participant_meeting/add`,
      JSON.stringify(data)
    );
  },

  edit: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/participant_meeting/${data.meetingId}/edit`,
      JSON.stringify(data)
    );
  },

  delete: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/participant_meeting/${data.id}/delete`,
      JSON.stringify(data)
    );
  },

  disconnectZoom: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/participant_meeting/${data.id}/disconnect_zoom`,
      JSON.stringify(data)
    );
  },

  addClip: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/participant_meeting/${data.meetingId}/participant_meeting_clip/add`,
      JSON.stringify(data)
    );
  },

  editClip: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/participant_meeting/${data.meetingId}/participant_meeting_clip/${data.clipId}/edit`,
      JSON.stringify(data)
    );
  },

  deleteClip: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/participant_meeting/${data.meetingId}/participant_meeting_clip/${data.id}/delete`,
      JSON.stringify(data)
    );
  },

  publishClip: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/participant_meeting/${data.meetingId}/participant_meeting_clip/${data.clipId}/publish`,
      JSON.stringify(data)
    );
  },

  addMeetingNotes: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/lab/participant/${data.participantId}/meeting/${data.meetingId}/addNote`,
      JSON.stringify(data)
    );
  },

  editMeetingNotes: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/lab/participant/${data.participantId}/meeting/${data.meetingId}/${data.storyEntryId}/editNote`,
      JSON.stringify(data)
    );
  },

  editInternalNotes: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/lab/participant/${data.participantId}/meeting/${data.meetingId}/editInternalNote`,
      JSON.stringify(data)
    );
  },
};

export default ParticipantMeetingApi;
