// @ts-nocheck FIXME
import Modal from '@legacy-components/react/V5/Common/Modal';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import React from 'react';

const ConfirmDisconnectGCal = (props) => {
  // track loading state for Disconnect button
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!props.hasSyncedGoogleCalendar) {
      setIsLoading(false);
      props.close();
    }
  }, [props.hasSyncedGoogleCalendar]);

  const handleDisconnect = (e) => {
    e.preventDefault();

    setIsLoading(true);
    props.disconnectGCal();
  };

  // disable the button when loading
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <Modal close={props.close}>
      <form>
        <div className="v5__heading-3">Disconnect Google Calendar</div>

        <div className="v5__body-copy workspace__form-row">
          This will disconnect your Google account from the Lab. You can always
          reconnect your account from the synced app menu.
        </div>

        <div className="workspace__form-row">
          <button
            onClick={handleDisconnect}
            className="v5__btn v5__btn--red"
            {...btnProps}
          >
            <Spinner loading={isLoading} />
            Disconnect
          </button>
        </div>
        <div className="workspace__form-row">
          <button onClick={props.close} className="v5__link v5__link--grey">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

ConfirmDisconnectGCal.propTypes = {
  hasSyncedGoogleCalendar: PropTypes.bool,
  disconnectGCal: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default ConfirmDisconnectGCal;
