// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import ActionModal from './../../Common/ActionModal';
import Spinner from './../../Common/Spinner';

const FilterModal = (props) => {
  const [returningFilter, setReturningFilter] = useState(props.returningFilter);
  const [schoolFilters, setSchoolFilters] = useState(props.schoolFilters);
  const [domainFilters, setDomainFilters] = useState(props.domainFilters);
  const [isLoading, setLoading] = useState(false);

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handleAddRemoveSchoolFilter = (e, school) => {
    if (e.target.checked) {
      setSchoolFilters(schoolFilters.concat([school]));
    } else {
      const index = schoolFilters.indexOf(school);
      setSchoolFilters(
        schoolFilters.slice(0, index).concat(schoolFilters.slice(index + 1))
      );
    }
  };

  const handleAddRemoveDomainFilter = (e, domain) => {
    if (e.target.checked) {
      setDomainFilters(domainFilters.concat([domain]));
    } else {
      const index = domainFilters.indexOf(domain);
      setDomainFilters(
        domainFilters.slice(0, index).concat(domainFilters.slice(index + 1))
      );
    }
  };

  const handleSaveFilters = (e) => {
    e.preventDefault();
    setLoading(true);
    props.setReturningFilter(returningFilter);
    props.setSchoolFilters([...schoolFilters]);
    props.setDomainFilters([...domainFilters]);
    props.close();
  };

  // disable buttons when submitting
  const btnProps = isLoading ? { disabled: true } : null;
  let currentSchoolParticipants = [...props.participants];
  let currentReturningParticipants = [...props.participants];
  let currentDomainParticipants = [...props.participants];
  switch (returningFilter) {
    case 'returning':
      currentSchoolParticipants = currentSchoolParticipants.filter(
        (participant) => participant.isReturning
      );
      currentDomainParticipants = currentDomainParticipants.filter(
        (participant) => participant.isReturning
      );
      break;
    case 'new':
      currentSchoolParticipants = currentSchoolParticipants.filter(
        (participant) => !participant.isReturning
      );
      currentDomainParticipants = currentDomainParticipants.filter(
        (participant) => !participant.isReturning
      );
      break;
  }
  if (schoolFilters.length > 0) {
    currentReturningParticipants = currentReturningParticipants.filter(
      (participant) =>
        schoolFilters.indexOf(
          participant.school_name && participant.school_name
        ) >= 0
    );
    currentDomainParticipants = currentDomainParticipants.filter(
      (participant) =>
        schoolFilters.indexOf(
          participant.school_name && participant.school_name
        ) >= 0
    );
  }
  if (domainFilters.length > 0) {
    currentReturningParticipants = currentReturningParticipants.filter(
      (participant) => props.checkParticipantDomains(participant, domainFilters)
    );
    currentSchoolParticipants = currentSchoolParticipants.filter(
      (participant) => props.checkParticipantDomains(participant, domainFilters)
    );
  }

  const filterItem = (subProps) => {
    return (
      <div className={'cute-6-desktop cohort-v5__filters-item'}>
        <input type="checkbox" {...subProps} className={'tc__checkbox'} />
        <span className="cohort-v5__new-label">
          {subProps.label} ({subProps.count})
        </span>
      </div>
    );
  };

  // only show the Coaching Experience filter if this cohort is in the current school year
  const experienceFilterNode = props.isCurrentYear && (
    <div className="workspace__form-row participant-v5__modal-top-row">
      <div className="admin__form-label v5__heading-5">Coaching Experience</div>
      <div className="cohort-v5__filters-row v5__comment-copy">
        {filterItem({
          label: 'New',
          count: currentReturningParticipants.filter(
            (participant) => !participant.isReturning
          ).length,
          checked: returningFilter === 'new',
          onChange: () =>
            setReturningFilter(returningFilter === 'new' ? '' : 'new'),
          id: 'new_checkbox',
        })}
        {filterItem({
          label: 'Returning',
          count: currentReturningParticipants.filter(
            (participant) => participant.isReturning
          ).length,
          checked: returningFilter === 'returning',
          onChange: () =>
            setReturningFilter(
              returningFilter === 'returning' ? '' : 'returning'
            ),
          id: 'returning_checkbox',
        })}
      </div>
    </div>
  );

  return (
    <ActionModal headlineText="Filter Educator List" close={handleCloseModal}>
      <form className="participant-v5__modal-body">
        {experienceFilterNode}

        <div className="workspace__form-row participant-v5__modal-top-row">
          <div className="admin__form-label v5__heading-5">School</div>
          <div className="cohort-v5__filters-row v5__comment-copy row--max-100">
            {props.schools.map((school, i) =>
              filterItem({
                label: school,
                count: currentSchoolParticipants.filter(
                  (participant) =>
                    participant.school_name &&
                    participant.school_name === school
                ).length,
                checked: schoolFilters.indexOf(school) >= 0,
                onChange: (e) => handleAddRemoveSchoolFilter(e, school),
                id: `${school}_${i}_checkbox`,
              })
            )}
          </div>
        </div>

        <div className="workspace__form-row participant-v5__modal-top-row">
          <div className="admin__form-label v5__heading-5">
            Learning Domains
          </div>
          <div className="cohort-v5__filters-row v5__comment-copy row--max-100">
            {props.domains.map((domain, i) =>
              filterItem({
                label: domain,
                count: currentDomainParticipants.filter(
                  (participant) =>
                    participant.active_domains.filter(
                      (active_domain) => active_domain.title === domain
                    ).length > 0
                ).length,
                checked: domainFilters.indexOf(domain) >= 0,
                onChange: (e) => handleAddRemoveDomainFilter(e, domain),
                id: `${domain}_${i}_checkbox`,
              })
            )}
          </div>
        </div>

        <div className="workspace__form-row admin__right-btn-col">
          <button className="v5__btn" onClick={handleSaveFilters} {...btnProps}>
            <Spinner loading={props.isLoading} />
            Apply
          </button>
        </div>
      </form>
    </ActionModal>
  );
};

FilterModal.propTypes = {
  returningFilter: PropTypes.string.isRequired,
  setReturningFilter: PropTypes.func.isRequired,
  schoolFilters: PropTypes.array.isRequired,
  setSchoolFilters: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  schools: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired,
  domains: PropTypes.array.isRequired,
  domainFilters: PropTypes.array.isRequired,
  checkParticipantDomains: PropTypes.func.isRequired,
  isCurrentYear: PropTypes.bool,
};

FilterModal.defaultProps = {
  data: {},
};

export default FilterModal;
