// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import GateKeeperApi from '@legacy-components/apis/GateKeeperApi';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import AuthRoleIcon from '@legacy-components/react/V5/Common/AuthRoleIcon';
import CopyToClipboard from '@legacy-components/react/V5/Common/CopyToClipboard';
import StatusIcon from '@legacy-components/react/V5/Common/StatusIcon';
import TimeDelta from '@legacy-components/react/V5/Common/TimeDelta';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const UserRow = (props) => {
  const lab = useLegacyLabContext();
  // after copying the reset pw code, we show "Copied!" for 5 sec
  const pwResetIconTimeout = React.useRef(null);
  const [pwResetIcon, setPwResetIcon] = React.useState(() => {
    // only Help Desk Agents can reset the user's pw from this page
    if (Utils.isHelpDeskAgent.call(lab, props.currentUser)) {
      return 'icon';
    }
    return null;
  });

  // if the current user is a Help Desk Agent,
  // they can generate reset password codes for users on this page
  const [pwResetCode, setPwResetCode] = React.useState();

  React.useEffect(() => {
    // This will get rid of the timeout on cleanup
    return () => {
      clearTimeout(pwResetIconTimeout.current);
    };
  }, []);

  const getPwResetCode = () => {
    clearTimeout(pwResetIconTimeout.current);

    GateKeeperApi.getPasswordResetCode({
      user_id: props.id,
    })
      .then((response) => {
        if (response.code) {
          setPwResetCode(`code=${response.code}`);
          // show the "Click to Copy" text
          setPwResetIcon('click');
        } else if (response.token) {
          setPwResetCode(response.token);
          setPwResetIcon('click');
        }
      })
      .catch(() => {
        props.setAlert({
          type: 'error',
          message:
            'Sorry, we could not generate the link. Please try again later.',
        });
      });
  };

  const copyPwResetCode = () => {
    // show the "Copied!" text
    setPwResetIcon('copied');
    // revert to icon after 5 sec
    pwResetIconTimeout.current = setTimeout(() => {
      setPwResetIcon('icon');
      setPwResetCode(null);
    }, 5000);
  };

  // set up the user's auth roles icons - default to just Free User
  let rolesNode = (
    <div className="admin-users__role-icons">
      <AuthRoleIcon role="BL_USER" />
    </div>
  );
  // if the user has any actual auth roles, show those instead
  if (ArrayHelpers.hasElements(props.auth_roles)) {
    rolesNode = (
      <div className="admin-users__role-icons">
        {props.auth_roles.map((role) => {
          return <AuthRoleIcon key={role} role={role} />;
        })}
      </div>
    );
  }

  // set up the info for user's next event
  let nextEventNode = (
    <td className="admin-users__user-next-event-cell">
      <div className="admin-users__italic">No upcoming events</div>
    </td>
  );
  if (props.next_event) {
    const groupName = props.next_event.group_name
      ? `, ${props.next_event.group_name}`
      : '';
    nextEventNode = (
      <td className="admin-users__user-next-event-cell">
        <a
          href={`/bl/event/${props.next_event.id}`}
          className="v5__link"
          target="_blank"
          rel="noreferrer"
        >
          {props.next_event.name}
          {groupName}
        </a>
        <div>
          <TimeDelta datetime={props.next_event.session_start_time} />
        </div>
      </td>
    );
  }

  // set up the user's coaching status icon
  const statusNode = props.status && (
    <StatusIcon
      status={props.status}
      className="admin-users__user-coaching-status-icon"
      displayForActive={true}
    />
  );

  // set up the info for the user's next coaching meeting
  let nextMeetingNode = (
    <div>
      <div className="admin-users__italic">No meeting scheduled</div>
      <div>{props.cohort_name}</div>
    </div>
  );
  if (props.next_meeting_id && props.next_meeting_time) {
    const nextMeeting = moment(props.next_meeting_time).tz(
      props.currentUser.time_zone.pytz_timezone
    );
    nextMeetingNode = (
      <div>
        <a
          href={`/lab/participant/${props.participant_id}/meeting/${props.next_meeting_id}/details`}
          className="v5__link"
          target="_blank"
          rel="noreferrer"
        >
          <div>{nextMeeting.format('MMMM D')}</div>
          <div>{nextMeeting.format('h:mm A z')}</div>
        </a>
        <div>{props.coach}</div>
      </div>
    );
  }

  // if this is a contract coach, link to their calendly
  let userDetailsLink = props.calendly_link ? (
    <Tooltip
      tooltip="Calendly"
      offsetLeft={30}
      nowrap={true}
      delay={1}
      className="admin-users__action-icon details"
    >
      <a
        href={props.calendly_link}
        className="v5__link v5__link--green"
        target="_blank"
        rel="noreferrer"
      >
        <div className="tc-v5-meeting"></div>
      </a>
    </Tooltip>
  ) : null;
  // if this user is a participant, link to their participant details page
  if (props.participant_id) {
    userDetailsLink = (
      <Tooltip
        tooltip="Participant Details"
        offsetLeft={10}
        nowrap={true}
        delay={1}
        className="admin-users__action-icon details"
      >
        <a
          href={`/lab/participant/${props.participant_id}/details`}
          className="v5__link v5__link--green"
          target="_blank"
          rel="noreferrer"
        >
          <div className="tc-v5-details"></div>
        </a>
      </Tooltip>
    );
  }

  // only Help Desk Agents can reset the user's pw from this page
  let resetPasswordNode;
  if (pwResetIcon) {
    switch (pwResetIcon) {
      case 'icon':
        // by default, we show the icon
        resetPasswordNode = (
          <Tooltip
            tooltip="Reset Password"
            offsetLeft={19}
            nowrap={true}
            delay={1}
            className="admin-users__action-icon reset-pw"
          >
            <div className="tc-v5-pw-reset" onClick={getPwResetCode}></div>
          </Tooltip>
        );
        break;
      case 'click':
        // after clicking the icon, we retrieve the code
        // and allow copying the reset pw link by clicking on this
        resetPasswordNode = (
          <div
            id={`reset-pw-link-${props.id}`}
            className="admin-users__action-icon-text admin-users__action-icon-text--clickable reset-pw"
            copytext={`${process.env.REACT_APP_LAB_URL}/reset-password?${pwResetCode}`}
            onClick={copyPwResetCode}
          >
            Click to Copy
            <CopyToClipboard targetSelector={`#reset-pw-link-${props.id}`} />
          </div>
        );
        break;
      case 'copied':
        // after the link is copied, we show this text,
        // and then revert back to the icon after 5 seconds
        resetPasswordNode = (
          <div className="admin-users__action-icon-text reset-pw">Copied!</div>
        );
        break;
    }
  }

  return (
    <tr className="admin-users__table-row">
      <td className="admin-users__user-name-cell">
        <div className="admin-users__user-photo-name">
          <div className="admin-users__user-photo">
            <a
              href={`/bl/user/${props.id}`}
              className="v5__link"
              target="_blank"
              rel="noreferrer"
            >
              <UserPhoto
                src={props.photo}
                userId={props.id}
                firstName={props.first_name}
                lastName={props.last_name}
                diameter={40}
              />
            </a>
          </div>
          <div className="admin-users__user-name-email">
            <a
              href={`/bl/user/${props.id}`}
              className="v5__link v5__link--black admin-users__user-name"
              target="_blank"
              rel="noreferrer"
            >
              {props.first_name} {props.last_name}
            </a>
            <div
              id={`user-email-${props.id}`}
              className="admin-users__user-email"
              copytext={props.email}
              onClick={() =>
                props.setAlert({
                  type: 'success',
                  message: 'Email copied to clipboard',
                })
              }
            >
              {props.email}
            </div>
            <CopyToClipboard targetSelector={`#user-email-${props.id}`} />
          </div>
        </div>
      </td>
      <td>{rolesNode}</td>
      {nextEventNode}
      <td className="admin-users__user-next-meeting-cell">
        <div className="admin-users__user-next-meeting">
          <div className="admin-users__user-coaching-status">{statusNode}</div>
          {nextMeetingNode}
        </div>
      </td>
      <td>
        <div className="admin-users__action-icons">
          {userDetailsLink}
          {resetPasswordNode}
        </div>
      </td>
    </tr>
  );
};

UserRow.propTypes = {
  currentUser: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  photo: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  auth_roles: PropTypes.array,
  next_event: PropTypes.object,
  status: PropTypes.string,
  cohort_name: PropTypes.string,
  next_meeting_id: PropTypes.number,
  next_meeting_time: PropTypes.string,
  participant_id: PropTypes.number,
  coach: PropTypes.string,
  last_login: PropTypes.string,
  setAlert: PropTypes.func.isRequired,
};

export default UserRow;
