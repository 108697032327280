// @ts-nocheck FIXME
import Request from '@legacy-components/components/Request';

const AdminApi = {
  getUsers: () => {
    return Request.get('/api/bl/users/search');
  },
};

export default AdminApi;
