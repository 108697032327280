// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Utils from './../../../components/DynamicUtils';
import WindowHelpers from './../../../components/WindowHelpers';

class WindowScrollElementFixer extends React.Component {
  constructor(props) {
    super(props);

    // params
    this.positionToElem = null;
    this.positionToPosition = 0;
    this.positionToElemHeight = 0;
    this.positionThisElem = null;
    this.positionThisElemHeight = 0;
    this.positionThisElemContainer = null;
    this.topMargin = 0;
    this.headerHeight = 0;
    this.accountForElemHeight = 0;

    // methods
    this.checkIfElementIsScrolledIntoView =
      this.checkIfElementIsScrolledIntoView.bind(this);
    this.updatePositionToElementPosition =
      this.updatePositionToElementPosition.bind(this);
    this.setContainerElementHeight = this.setContainerElementHeight.bind(this);
  }

  componentDidMount() {
    // be default, do not add the listener on mobile
    if (this.props.useOnMobile || !WindowHelpers.isMobileDevice()) {
      // get the position and height of main content to contain position of sidebar
      this.positionToElem = document.querySelector(
        '.' + this.props.positionToClass
      );
      this.positionToPosition = Utils.getAbsolutePosition(this.positionToElem);
      this.positionToElemHeight = this.positionToElem.offsetHeight;

      // custom top margin to handle adjustable space above sidebar
      this.topMargin = this.props.topMargin || 0;

      // get height of the element that will sticky
      this.positionThisElem = document.querySelector(
        '.' + this.props.positionThisClass
      );
      this.positionThisElemHeight =
        this.positionThisElem.offsetHeight + this.topMargin;

      // get the header height to preserve start and point of elements
      this.headerHeight = document.querySelector('.tc-header__wrap')
        ? document.querySelector('.tc-header__wrap').offsetHeight
        : 0;
      if (document.querySelector('.tc-header__admin-nav')) {
        this.headerHeight += document.querySelector(
          '.tc-header__admin-nav'
        ).offsetHeight;
      }

      // get the height of any elements that influence the starting/ending points of the sticky elem
      if (document.querySelector(`.${this.props.accountForElemClass}`)) {
        this.accountForElemHeight = document.querySelector(
          `.${this.props.accountForElemClass}`
        ).offsetHeight;
      }

      // used as a max height column to bottom positon the sticky element
      this.positionThisElemContainer = this.props.positionThisContainerClass
        ? document.querySelector(`.${this.props.positionThisContainerClass}`)
        : null;

      // set the height of the container
      this.setContainerElementHeight();
      // initial check
      this.checkIfElementIsScrolledIntoView();

      // attach a event listeners
      window.addEventListener('resize', this.updatePositionToElementPosition);
      window.addEventListener('scroll', this.checkIfElementIsScrolledIntoView);
    }
  }

  componentWillUnmount() {
    // remove a event listeners
    window.removeEventListener('resize', this.updatePositionToElementPosition);
    window.removeEventListener('scroll', this.checkIfElementIsScrolledIntoView);
  }

  componentDidUpdate(prevProps) {
    // handle page height change if content of the page has changed
    if (
      (this.props.useOnMobile || !WindowHelpers.isMobileDevice()) &&
      this.props.updateFlag &&
      this.props.updateFlag != prevProps.updateFlag
    ) {
      this.positionToElemHeight = this.positionToElem.offsetHeight;

      // set the height of the container
      this.setContainerElementHeight();

      // make sure the sticky sidebar stays where it is supposed to
      this.updatePositionToElementPosition();
    }
  }

  setContainerElementHeight() {
    if (this.positionThisElemContainer) {
      let containerHeight = Math.max(
        this.positionThisElemHeight,
        this.positionToElemHeight
      );

      // this is a fixed hero element accound for header and container
      if (this.props.fixedHero) {
        containerHeight += this.headerHeight - this.positionThisElemHeight;
      }

      // set the styles for the element for bottom absolute position
      this.positionThisElemContainer.setAttribute(
        'style',
        `height:${containerHeight}px;`
      );
    }
  }

  updatePositionToElementPosition() {
    this.positionToPosition = Utils.getAbsolutePosition(this.positionToElem);
  }

  checkIfElementIsScrolledIntoView() {
    const scrollPosition = WindowHelpers.getVerticalScrollPosition();

    // if the scroll position is above the element
    if (
      scrollPosition + this.accountForElemHeight <
      this.positionToPosition[1]
    ) {
      // its not current fixed
      if (!Utils.hasClass(this.positionThisElem, 'fixed')) {
        return;
      }
      // scrolling element has reached the top of its potential position
      Utils.removeClass(this.positionThisElem, 'fixed');
      if (this.props.updateToElement) {
        Utils.removeClass(this.positionToElem, 'fixed');
      }
      return;

      // if the scroll position is below the element add fixed class if necessary
    } else {
      // check if the element is at its potential bottom
      this.checkIfElementIsScrolledToTheBottom(scrollPosition);
      // we are currently scroll its its fixed already
      if (Utils.hasClass(this.positionThisElem, 'fixed')) {
        return;
      }
      // not currently fixed but the user has scrolled down the page
      Utils.addClass(this.positionThisElem, 'fixed');
      if (this.props.updateToElement) {
        Utils.addClass(this.positionToElem, 'fixed');
      }
      return;
    }
  }
  checkIfElementIsScrolledToTheBottom(scrollPosition) {
    const bottomStopClass =
      this.positionThisElemHeight < this.positionToElemHeight
        ? 'absolute'
        : 'absolute-top';

    // if the element is at the bottom of the content area make it absolute to the collumn
    if (
      scrollPosition + this.positionThisElemHeight >
      this.positionToPosition[1] +
        this.positionToElemHeight -
        this.topMargin -
        this.accountForElemHeight
    ) {
      Utils.addClass(this.positionThisElem, bottomStopClass);

      // ensure consistent height of hero image
      if (!this.props.fixedHero) {
        this.positionThisElem.style.height = this.positionThisElemHeight;
      }

      // remove absolute class so that fixed sticky sidebar behavior takes over again
    } else {
      Utils.removeClass(this.positionThisElem, bottomStopClass);
      this.positionThisElem.style.height = '';
    }
  }
  render() {
    return null;
  }
}

WindowScrollElementFixer.propTypes = {
  positionToClass: PropTypes.string.isRequired,
  positionThisClass: PropTypes.string.isRequired,
  positionThisContainerClass: PropTypes.string,
  updateToElement: PropTypes.bool,
  topMargin: PropTypes.number,
  fixedHero: PropTypes.bool,
  accountForElemClass: PropTypes.string,
  // by default, we do not add the scroll listener on mobile
  useOnMobile: PropTypes.bool,
  updateFlag: PropTypes.string,
};

export default WindowScrollElementFixer;
