// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import { Router } from './../../../components/Router';
import ScaleCenterImage from './../../ScaleCenterImage';

const Strategy = (props) => {
  const displayStrategyDetails = (e) => {
    e.preventDefault();
    let tab = props.tab.replace('-', '_');
    if (props.showDetailed) {
      tab = 'strategy_list';
    }
    Router.route(
      '/coaching/lab/partner/portfolio/' +
        props.portfolio_guid +
        '/page/' +
        props.tab +
        '/spotlight/' +
        props.id +
        '?from=' +
        tab
    );
  };

  // get the artifact image to show next to the strategy info
  let strategyImage;
  let strategyImageClass = '';
  // or if there is no image, then show the strategy icon next to the name instead
  let strategyIcon;
  let strategyNameClass = '';
  if (props.data.strategy_implementation.image) {
    strategyImage = (
      <div className="ppp__elem-preview-container">
        <div
          className="ppp__elem-preview"
          id={'ppp__elem-preview--' + props.id}
        >
          <ScaleCenterImage
            src={props.data.strategy_implementation.image}
            className="ppp__elem-image"
          />
        </div>
      </div>
    );
    strategyImageClass = ' ppp__strategy-details--has-image';
  } else {
    strategyIcon = <span className="strategy__icon tc-target"></span>;
    strategyNameClass = ' ppp__strategy-name';
  }

  // on most pages, we show the default strategy spotlight capsule with general info
  let spotlightNode = (
    <div
      onClick={displayStrategyDetails}
      className="ppp__elem ppp__elem--strategy cute-10-desktop cute-1-desktop-offset left"
    >
      {strategyImage}
      <div className={'ppp__strategy-details' + strategyImageClass}>
        {strategyIcon}
        <a
          href={props.data.url}
          className={'ppp__elem-headline tc__link' + strategyNameClass}
        >
          {props.data.strategy_implementation.name}
        </a>
        <div className="ppp__strategy-teaching-challenge ppp__elem-subheadline">
          <span className="ppp__elem-label">Teaching Challenge: </span>
          {props.data.teaching_challenge_name}
        </div>
        <PurifiedHTML
          className="ppp__strategy-text ppp__body-copy"
          content={props.data.strategy_implementation.description}
        />
      </div>
    </div>
  );
  // in the Spotlights section, we need to show more details in the capsule
  // that are relevant to this partner
  if (props.showDetailed) {
    spotlightNode = (
      <div
        onClick={displayStrategyDetails}
        className="ppp__elem ppp__elem--strategy ppp__elem--strategy-detail cute-10-desktop cute-1-desktop-offset left"
      >
        <div className="ppp__strategy-detailed-col">
          <div className="ppp__strategy-detailed-name">
            {props.data.strategy_implementation.name}
          </div>
        </div>
        <div className="ppp__strategy-detailed-col ppp__strategy-detailed-col--subject">
          <div
            className={
              'ppp__strategy-detailed-icon tc-sga-nb-' +
              props.data.growth_area_id
            }
          ></div>
          <div className="ppp__strategy-detailed-subject">
            <div className="ppp__elem-headline">
              {props.data.subject_name} Growth Area:
            </div>
            <div className="ppp__strategy-teaching-challenge ppp__elem-subheadline">
              {props.data.growth_area_name}
            </div>
          </div>
        </div>
        <div className="ppp__strategy-detailed-col">
          <div className="ppp__strategy-detailed-teachers-tried">
            {props.data.teachers_tried}
          </div>
          <span className="ppp__strategy-detailed-teachers-tried-text">
            Teachers tried this strategy
          </span>
        </div>
      </div>
    );
  }

  return <div className="row">{spotlightNode}</div>;
};

export default Strategy;
