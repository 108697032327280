// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';

const Header = (props) => {
  // logo links to different places depending on whether or not we have a logged in user
  const logoUrl =
    props.user && props.user.id
      ? '/coaching/lab'
      : process.env.REACT_APP_LAB_URL;

  return (
    <div>
      <div className="p-portfolio-presn__header-wrapper">
        <div className="p-portfolio-presn__header row tc__center-text">
          <a
            href={logoUrl + '?from=ppd3_participant_portfolio_presentation_UI'}
            id="tc__logo"
            className="p-portfolio-presn__header-logo cute-2-desktop cute-6-phone center-phone left"
          >
            <span className="tc-header__logo-caption">BetterLesson Lab</span>
          </a>
          <div className="p-portfolio-presn__header-home-link cute-8-desktop left show-desktop hide-phone">
            <NavigationLink
              url={
                '/coaching/lab/participant/portfolio/' +
                props.portfolioUuid +
                '?from=ppd3_participant_portfolio_presentation_UI'
              }
              className="tc__link"
            >
              {props.participantName}&apos;s Portfolio
            </NavigationLink>
          </div>
        </div>
      </div>
      <div className="p-portfolio-presn__header-mobile row">
        <div className="p-portfolio-presn__header-home-link cute-12-phone hide-desktop show-phone">
          <NavigationLink
            url={
              '/coaching/lab/participant/portfolio/' +
              props.portfolioUuid +
              '?from=ppd3_participant_portfolio_presentation_UI'
            }
            className="tc__link"
          >
            {props.participantName}&apos;s Portfolio
          </NavigationLink>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  portfolioUuid: PropTypes.string.isRequired,
  participantName: PropTypes.string.isRequired,
};

export default Header;
