// @ts-nocheck FIXME
const PartnerPortfolioPresentationCover = (props) => {
  if (props.tab != 'cover') {
    return <noscript />;
  }

  return (
    <div className="ppp__section ppp__section--cover">
      <div className="ppp__cover-container">
        {props.entries[0].data.primary_heading == '' &&
        props.entries[0].data.primary_subheading == '' ? (
          <noscript />
        ) : (
          <div className="ppp__cover">
            {props.entries[0].data.primary_heading != '' && (
              <div className="ppp__cover-headline ppp__cover-headline--heading1">
                {props.entries[0].data.primary_heading}
              </div>
            )}
            {props.entries[0].data.primary_subheading != '' && (
              <div className="ppp__cover-headline ppp__cover-headline--heading2">
                {props.entries[0].data.primary_subheading}
              </div>
            )}
          </div>
        )}
        {props.entries[0].data.secondary_heading == '' &&
        props.entries[0].data.secondary_subheading == '' ? (
          <noscript />
        ) : (
          <div className="ppp__details">
            {props.entries[0].data.secondary_heading != '' && (
              <div className="ppp__cover-headline ppp__cover-headline--heading3">
                {props.entries[0].data.secondary_heading}
              </div>
            )}
            {props.entries[0].data.secondary_subheading != '' && (
              <div className="ppp__cover-headline ppp__cover-headline--heading4">
                {props.entries[0].data.secondary_subheading}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerPortfolioPresentationCover;
