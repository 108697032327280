// @ts-nocheck FIXME
import Request from './../../components/Request';

const CoachMatchApi = {
  updateCoachMatchStatus: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/lab/coach_participant_match/${data.match_id}/${data.status}`,
      JSON.stringify(data)
    );
  },
};

export default CoachMatchApi;
