// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import { Router } from '@legacy-components/components/Router';
import StorageHelper from '@legacy-components/components/StorageHelper';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import NavigationLink from '@legacy-components/react/NavigationLink';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';
import EventRow from './EventRow';
import EventFilter from './modules/EventFilter';

const Events = (props) => {
  // Save the id of the event as the row expanded
  const [rowExpanded, setRowExpanded] = React.useState(false);
  const [showArchived, setShowArchived] = React.useState(props.archived);
  const [searchField, setSearchField] = React.useState(
    StorageHelper.getSessionItem('events_search_text') || ''
  );
  const [sortedColumn, setSortedColumn] = React.useState(
    StorageHelper.getCookieItem('events_sort_col') || 'name'
  );
  const [sortDirection, setSortDirection] = React.useState(
    StorageHelper.getCookieItem('events_sort_order') || 'asc'
  );

  const [filterByPSM, setFilterByPSM] = React.useState(() => {
    const storedFilter = StorageHelper.getCookieItem('events_psm_filter');
    if (storedFilter) {
      return storedFilter;
    }
    // If the user is also a PSM we want the page to default to their partnerships
    const userPSM = props.psms.find((psm) => psm.id === props.user.id);
    if (userPSM) {
      return userPSM.label;
    }
    return null;
  });
  const [filterByStatus, setFilterByStatus] = React.useState(
    StorageHelper.getCookieItem('events_status_filter') || null
  );

  // set up the toggle link for archived events
  if (props.archived && !showArchived) {
    setShowArchived(true);
  } else if (!props.archived && showArchived) {
    setShowArchived(false);
  }

  // potential event status labels
  const eventStatus = [
    { label: 'Awaiting Session Details' },
    { label: 'Out for Bid' },
    { label: 'Bid Closed' },
    { label: 'Confirmed' },
    { label: 'Planning' },
    { label: 'In Progress' },
    { label: 'Completed' },
    { label: 'Cancelled' },
    { label: 'Closed' },
  ];

  // Wrapper function to sort on null values
  const sortWithNull = (func, key) => {
    return (a, b) => {
      if (a && a[key]) {
        if (b && b[key]) {
          return func(a, b);
        }
        return -1;
      } else if (b && b[key]) {
        return 1;
      } else {
        return 0;
      }
    };
  };

  const sortFunctions = {
    partner: sortWithNull(
      (a, b) => a.partner_name.localeCompare(b.partner_name),
      'partner_name'
    ),
    status: sortWithNull((a, b) => a.status.localeCompare(b.status), 'status'),
    product_code: sortWithNull(
      (a, b) => a.product_code.localeCompare(b.product_code),
      'product_code'
    ),
    date: Utils.eventUpcomingSort,
    participant_count: (a, b) => b.attendance - a.attendance,
    reservation_count: (a, b) => b.reservation_count - a.reservation_count,
  };

  const searchForMatch = (event) => {
    if (
      event.name &&
      event.name.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
    ) {
      return true;
    } else if (
      event.partner_name &&
      event.partner_name.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
    ) {
      return true;
    } else if (
      event.status &&
      event.status.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
    ) {
      return true;
    } else if (
      event.product_code &&
      event.product_code.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
    ) {
      return true;
    } else if (
      event.service &&
      event.service.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
    ) {
      return true;
    } else if (
      event.sessions_product_codes &&
      event.sessions_product_codes.length &&
      event.sessions_product_codes.some(
        (pc) => pc && pc.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
      )
    ) {
      return true;
    }
    return false;
  };

  const handleFilterByStatus = (status) => {
    StorageHelper.setCookieItem('events_status_filter', status);
    setFilterByStatus(status);
    // if the user is trying to filter by an "archived" status, make sure to load those events in
    if (
      !showArchived &&
      ['Completed', 'Cancelled', 'Closed'].indexOf(status) !== -1
    ) {
      Router.route('/bl/events?archived=1');
    }
  };

  let events = [...props.events];

  if (searchField) {
    events = events.filter(searchForMatch);
  }

  // filter by psm
  if (filterByPSM) {
    if (filterByPSM === 'No PSM') {
      events = events.filter((event) => event.psms.length === 0);
    } else {
      events = events.filter((event) =>
        Utils.nameValuePairInArrayOfObjects('name', filterByPSM, event.psms)
      );
    }
  }

  // filter by event
  if (filterByStatus) {
    events = events.filter((event) => event.status === filterByStatus);
  }

  let emptyEventsNode;
  if (events.length === 0) {
    emptyEventsNode = (
      <div className="partners__empty-search">
        No events match your search criteria. Try changing your search terms or
        filters to broaden your search.
      </div>
    );
  }

  // Sort order indication arrow
  let sortIndicator = null;

  if (sortDirection === 'asc') {
    events.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (sortDirection === 'desc') {
    events.sort(sortFunctions[sortedColumn]);
    events.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      StorageHelper.setCookieItem(
        'events_sort_order',
        sortOrder[sortDirection]
      );
      setSortDirection(sortOrder[sortDirection]);
    } else {
      StorageHelper.setCookieItem('events_sort_order', 'asc');
      StorageHelper.setCookieItem('events_sort_col', column);
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };

  const psms = [{ label: 'No PSM' }, ...props.psms];
  const PSMFilters = (
    <EventFilter
      filterType="PSM:"
      options={psms}
      handleChange={(psm) => {
        StorageHelper.setCookieItem('events_psm_filter', psm);
        setFilterByPSM(psm);
      }}
      defaultValue={filterByPSM}
    />
  );
  const StatusFilters = (
    <EventFilter
      filterType="Status:"
      options={eventStatus}
      handleChange={handleFilterByStatus}
      defaultValue={filterByStatus}
    />
  );

  return (
    <div className="tc__page">
      <Header page="Events" {...props} />
      <div className="row row--1200 cohorts__headline-row">
        <div>
          <h3 className="cohorts__headline tc__headline-2">Events</h3>
        </div>
      </div>
      <div className="row row--1200 admin-v5__table-filters top-row">
        <div className="admin-v5__event-list--search-container event-v5__event-search">
          <input
            type="text"
            className="v5__input header__v5-search-input"
            placeholder="Search by name, partner, status, or product."
            value={searchField}
            onChange={(e) => {
              StorageHelper.setSessionItem(
                'events_search_text',
                e.target.value
              );
              setSearchField(e.target.value);
            }}
          />
        </div>
        {PSMFilters}
        {StatusFilters}
      </div>
      <div className="row row--1200">
        <div className="admin__header-archived-checkbox">
          <NavigationLink
            url={`/bl/events${!showArchived ? '?archived=1' : ''}`}
            className="v5__link admin__header-archive-link"
          >
            <input
              type="checkbox"
              className="v5__checkbox admin-v5__events-table--checkbox"
              checked={showArchived}
            />
            Include Cancelled, Completed, and Closed Events
          </NavigationLink>
        </div>
      </div>
      <div className="row row--1200">
        <div className="admin-v5__coach-table">
          <div className="admin-v5__coach-table__header grid-big__row">
            <div
              className="admin-v5__coach-table__header-col grid-big__col-10"
              onClick={() => toggleSortDirection('name')}
            >
              <div
                className={`event-v5__event-column-header clickable${
                  sortedColumn === 'name' ? ' active' : ''
                }`}
              >
                <div>Name</div>
                {sortedColumn === 'name' ? sortIndicator : ''}
              </div>
            </div>
            <div
              className="admin-v5__coach-table__header-col grid-big__col-8"
              onClick={() => toggleSortDirection('partner')}
            >
              <div
                className={`event-v5__event-column-header clickable${
                  sortedColumn === 'partner' ? ' active' : ''
                }`}
              >
                <div>Partner</div>
                {sortedColumn === 'partner' ? sortIndicator : ''}
              </div>
            </div>
            <div
              className="admin-v5__coach-table__header-col grid-big__col-5"
              onClick={() => toggleSortDirection('status')}
            >
              <div
                className={`event-v5__event-column-header clickable${
                  sortedColumn === 'status' ? ' active' : ''
                }`}
              >
                <div>Status</div>
                {sortedColumn === 'status' ? sortIndicator : ''}
              </div>
            </div>
            <div
              className="admin-v5__coach-table__header-col grid-big__col-5"
              onClick={() => toggleSortDirection('product_code')}
            >
              <div
                className={`event-v5__event-column-header clickable${
                  sortedColumn === 'product_code' ? ' active' : ''
                }`}
              >
                <div>Product</div>
                {sortedColumn === 'product_code' ? sortIndicator : ''}
              </div>
            </div>
            <div className="admin-v5__coach-table__header-col grid-big__col-4">
              <div className="event-v5__event-column-header">
                <div>PSMs</div>
              </div>
            </div>
            <div className="admin-v5__coach-table__header-col grid-big__col-5">
              <div className="event-v5__event-column-header">
                <div>Facilitator</div>
              </div>
            </div>
            <div
              className="admin-v5__coach-table__header-col grid-big__col-5"
              onClick={() => toggleSortDirection('date')}
            >
              <div
                className={`event-v5__event-column-header clickable${
                  sortedColumn === 'date' ? ' active' : ''
                }`}
              >
                <div>Date</div>
                {sortedColumn === 'date' ? sortIndicator : ''}
              </div>
            </div>
            <div
              className="admin-v5__coach-table__header-col grid-big__col-3"
              onClick={() => toggleSortDirection('participant_count')}
            >
              <div
                className={`event-v5__event-column-header clickable${
                  sortedColumn === 'participant_count' ? ' active' : ''
                }`}
              >
                <div>Attend.</div>
                {sortedColumn === 'participant_count' ? sortIndicator : ''}
              </div>
            </div>
            <div className="admin-v5__coach-table__header-col grid-big__col-2"></div>
            <div className="admin-v5__coach-table__header-col grid-big__col-3"></div>
          </div>
          {events.map((event) => {
            return (
              <EventRow
                key={`event-${event.id}`}
                event={event}
                rowExpanded={rowExpanded}
                setRowExpanded={setRowExpanded}
                setAlert={props.setAlert}
              />
            );
          })}
        </div>
        {emptyEventsNode}
      </div>
    </div>
  );
};

const WrappedEvents = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => <Events {...props} setAlert={setAlert} />}
  </HeaderConsumer>
);

Events.propTypes = {
  archived: PropTypes.number.isRequired,
  events: PropTypes.array.isRequired,
  psms: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default WrappedEvents;
