// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

class ScaleCenterImage extends React.Component {
  state = {
    scaleTo: 'check-to-scale',
    marginLeft: 'auto',
  };

  componentDidMount() {
    // if the image's mask does not exist do nothing
    if (!ReactDOM.findDOMNode(this.refs.mask)) {
      return;
    }

    // scale and center the image to the bounding box
    this.scaleCenterImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.src == prevProps.src) {
      return;
    }

    // reset state before centering to remove any old margin-left value
    this.setState({
      scaleTo: 'check-to-scale',
      marginLeft: 'auto',
    });

    // scale and center the image to the bounding box
    this.scaleCenterImage();
  }

  scaleCenterImage = () => {
    const component = this;
    const mask = ReactDOM.findDOMNode(this.refs.mask);

    // create image to get native size
    const image = new Image();
    image.src = this.props.src;

    image.onload = function () {
      const maskWidth = mask.offsetWidth;
      const maskHeight = mask.offsetHeight;

      // image dimensions
      const width = this.width;
      const height = this.height;

      //get content to container ratio
      const viewRatio = height / maskHeight;

      //set variable according to edges and get margin
      if (width > height) {
        const viewWidth = Math.round(width / viewRatio);
        const viewHeight = Math.round(height / viewRatio);

        // handle if the computed width is shorter than the container width
        if (viewWidth <= maskWidth) {
          component.setState({ scaleTo: 'scale-to-width' });
        } else {
          const margin = (viewWidth - maskWidth) / 2;
          component.setState({
            scaleTo: 'scale-to-height',
            marginLeft: '-' + Math.round(margin) + 'px',
          });
        }
      } else if (height >= width) {
        component.setState({ scaleTo: 'scale-to-width' });
      }
    };
  };

  render() {
    let maskClass = this.props.className + '-mask ';
    if (this.props.maskClassName) {
      maskClass += this.props.maskClassName + ' ';
    }
    const icon = this.props.icon ? this.props.icon : 'doc';

    // if empty src provided
    if (!this.props.src) {
      maskClass += this.props.className + '-mask--none';

      return (
        <div className={maskClass}>
          <div
            className={
              this.props.className + '__preview-icon file-type-icon ext-' + icon
            }
          ></div>
        </div>
      );
    }

    const imageClasses = this.props.className + ' ' + this.state.scaleTo;
    const altTag = this.props.alt || '';

    // older imgs use marginLeft to center the img within the mask,
    // but most newer imgs just use flexbox
    const styles = this.props.noAdjustments
      ? {}
      : { marginLeft: this.state.marginLeft };

    // set the diameter of the img and its mask if necessary
    // (added for BL V4 round user imgs which may have a border in the mask)
    const maskStyles = {};
    if (this.props.diameter) {
      // set the mask size based on the passed in prop
      maskStyles.height = this.props.diameter;
      maskStyles.width = this.props.diameter;
      // if the mask has a border, we need to make the img a bit smaller
      let borderWidth = 0;
      if (this.props.hasBorder) {
        // add mask class to add a border and box shadow
        maskClass += ' bl4__user-image-mask--border';
        // by default, the border is 3px wide
        borderWidth = 3;
        // for smaller images, make sure any border is not too wide
        if (this.props.diameter < 50) {
          borderWidth = 2;
          maskStyles.borderWidth = borderWidth;
        }
      }

      // set the img size, adjusting to account for border width
      if (this.state.scaleTo == 'scale-to-width') {
        styles.width = this.props.diameter - 2 * borderWidth;
      } else {
        styles.height = this.props.diameter - 2 * borderWidth;
      }
    }

    if (this.props.src == '/legacy-images/default-user.svg') {
      return (
        <div className={maskClass}>
          <img
            src={this.props.src}
            className={this.props.className}
            alt={altTag}
          />
        </div>
      );
    }

    return (
      <div className={maskClass} ref="mask" style={maskStyles}>
        <img
          className={imageClasses}
          id={this.props.id}
          src={this.props.src}
          style={styles}
          alt={altTag}
        />
      </div>
    );
  }
}

ScaleCenterImage.propTypes = {
  src: PropTypes.string,
  icon: PropTypes.string,
  maskClassName: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  diameter: PropTypes.number,
  hasBorder: PropTypes.bool,
  noAdjustments: PropTypes.bool,
};

export default ScaleCenterImage;
