// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Analytics from './../../../../components/Analytics';
import ArrayHelpers from './../../../../components/ArrayHelpers';
import Highlights from './PortfolioStoryCapsuleHighlights';
import Timeline from './PortfolioStoryCapsuleTimeline';

class PortfolioStoryCapsule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: this.props.selectedTab == 'timeline' ? 'timeline' : 'highlights',
    };

    this.setMode = this.setMode.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
  }

  setMode(mode) {
    Analytics.logEvent({
      category: mode,
      action: 'select',
      label: 'from_profile',
    });

    this.setState({ mode: mode });
  }

  toggleMode() {
    if (this.state.mode == 'highlights') {
      Analytics.logEvent({
        category: 'timeline',
        action: 'select',
        label: 'from_profile',
      });

      this.setMode('timeline');
    } else {
      Analytics.logEvent({
        category: 'highlights',
        action: 'select',
        label: 'from_profile',
      });

      this.setMode('highlights');
    }
  }

  render() {
    // Highlights tab is only available if the story has items to show in that tab
    const hasHighlights =
      (this.props.startingStatement &&
        this.props.startingStatement.description) ||
      ArrayHelpers.hasElements(this.props.strategies) ||
      ArrayHelpers.hasElements(this.props.evidence) ||
      ArrayHelpers.hasElements(this.props.reflections);

    // by default, just show the Timeline tab
    let tabsNode = (
      <div className="bl4-profile__story-mode-tabs">
        <div className="bl4-profile__story-mode-tab active">
          <span className="tc-map bl4-profile__story-mode-tab-icon"></span>
          Timeline
        </div>
      </div>
    );
    if (hasHighlights) {
      // underline the correct tab based on the current mode
      const highlightsTabClass =
        this.state.mode == 'highlights' ? ' active' : '';
      const timelineTabClass = this.state.mode == 'timeline' ? ' active' : '';

      tabsNode = (
        <div className="bl4-profile__story-mode-tabs">
          <div
            className={'bl4-profile__story-mode-tab' + highlightsTabClass}
            onClick={() => this.setMode('highlights')}
          >
            <span className="tc-star bl4-profile__story-mode-tab-icon"></span>
            Highlights
          </div>
          <div
            className={'bl4-profile__story-mode-tab' + timelineTabClass}
            onClick={() => this.setMode('timeline')}
          >
            <span className="tc-map bl4-profile__story-mode-tab-icon"></span>
            Timeline
          </div>
        </div>
      );
    }

    // set up the capsule body contents based on the current tab
    let bodyNode;
    let otherModeName = 'Highlights';
    if (hasHighlights && this.state.mode == 'highlights') {
      bodyNode = (
        <Highlights
          {...this.props}
          portfolioArtifactModal={this.props.portfolioArtifactModal}
        />
      );
      otherModeName = 'Timeline';
    } else {
      bodyNode = (
        <Timeline
          startingStatement={this.props.startingStatement}
          storyId={this.props.id}
          entries={this.props.entries}
          portfolioArtifactModal={this.props.portfolioArtifactModal}
        />
      );
    }

    return (
      <div
        id={'story-' + this.props.id}
        className="bl4__capsule bl4__capsule--header-graphic"
      >
        <h3 className="bl4-profile__story-title">
          {this.props.focus_area.title}
        </h3>
        <div className="tc__body-copy bl4-profile__story-tagline">
          {this.props.focus_area.tagline}
        </div>
        {tabsNode}

        {bodyNode}

        {hasHighlights && (
          <div className="tc__center-text">
            <button
              className="bl4__btn bl4__btn--blue bl4-profile__story-mode-btn"
              onClick={this.toggleMode}
            >
              {otherModeName} View
            </button>
          </div>
        )}
      </div>
    );
  }
}

PortfolioStoryCapsule.propTypes = {
  id: PropTypes.number.isRequired,
  focus_area: PropTypes.object.isRequired,
  selectedTab: PropTypes.string,
};

export default PortfolioStoryCapsule;
