// @ts-nocheck FIXME
import Request from './../../components/Request';

const VisionApi = {
  addTextEntry: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/entry/text/add',
      JSON.stringify(data)
    );
  },

  editTextEntry: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/entry/text/' +
        data.textEntryId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addFileEntry: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/entry/file/add',
      JSON.stringify(data)
    );
  },

  editFileEntry: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/entry/file/' +
        data.fileEntryId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addLinkEntry: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/entry/link/add',
      JSON.stringify(data)
    );
  },

  editLinkEntry: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/entry/link/' +
        data.linkEntryId +
        '/edit',
      JSON.stringify(data)
    );
  },

  deleteEntry: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/entry/' +
        data.entryId +
        '/delete',
      JSON.stringify(data)
    );
  },

  addMeetingClipEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/entry/clip/add',
      JSON.stringify(data)
    );
  },

  editMeetingClipEntry: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/entry/clip/' +
        data.meetingClipId +
        '/edit',
      JSON.stringify(data)
    );
  },
};

export default VisionApi;
