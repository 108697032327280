// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import MoveButton from './../MoveComponents/Button';
import MoveForm from './../MoveComponents/Form';
import Spinner from './../Spinner';
import ArtifactSelectType from './ArtifactSelectType';

const ArtifactForm = (props) => {
  let title;
  let notes;

  const formFieldsAreDisabled =
    props.confirmDelete.visible || props.confirmMove.visible;
  const disabledCssClass = formFieldsAreDisabled ? ' disabled ' : '';

  let analyticsCategory = 'file_artifact';
  let analyticsLabel = 'from_file_artifact_modal_vision_page';
  if (props.file.extension == 'link') {
    analyticsCategory = 'link_artifact';
    analyticsLabel = 'from_link_artifact_modal_vision_page';
  }

  const handleFocus = (field) => {
    if (props.handleFocus) {
      props.handleFocus(field);
    }
  };

  const handleSubmit = (e, submitCaption) => {
    e.preventDefault();

    const data = {
      id: props.file.id,
      notes: notes.value,
      title: title.value,
      artifactTypeId: props.selectedTypeId,
      isOutcomeEvidence: props.isOutcomeEvidence,
      isParticipantReflection: props.isParticipantReflection,
      isCoachReflection: props.isCoachReflection,
      submitType: submitCaption,
      analyticsCategory: analyticsCategory,
      analyticsLabel: analyticsLabel,
    };
    props.editArtifact(data);
  };

  const displayConfirmDelete = (e) => {
    e.preventDefault();
    props.toggleElement({
      analyticsCategory: analyticsCategory,
      analyticsLabel: analyticsLabel,
      analyticsAction: 'delete',
      element: 'confirmDelete',
    });
  };

  const cancelConfirmDelete = (e) => {
    props.toggleElement({
      analyticsCategory: analyticsCategory,
      analyticsLabel: analyticsLabel,
      analyticsAction: 'cancel_delete',
      element: 'confirmDelete',
    });
  };

  const deleteArtifact = () => {
    props.deleteArtifact({
      id: props.file.id,
      analyticsCategory: analyticsCategory,
      analyticsLabel: analyticsLabel,
    });
  };

  const toggleMoveArtifact = (e) => {
    e.preventDefault();
    props.toggleElement({
      analyticsCategory: 'entry',
      analyticsLabel: props.confirmMove.visible
        ? 'from_move_vision_page_entry_modal'
        : 'from_vision_page_edit_entry_modal',
      analyticsAction: props.confirmMove.visible ? 'cancel_move' : 'move',
      element: 'confirmMove',
    });
  };

  const getActionsNode = () => {
    let actionsNode, deleteOption, moveOption;
    const submitCaption = props.isNew ? 'Add' : 'Update';
    const buttonDisabledClass = props.loading ? ' disabled' : '';

    // New artifacts cannot be moved or deleted
    if (!props.isNew) {
      deleteOption = (
        <div className="partiacipant-artifact__delete tc__center-text">
          <a
            className="participant-artifact__upload-link"
            onClick={displayConfirmDelete}
          >
            <div className="partiacipant-artifact__delete-icon tc-delete"></div>
            <div className="partiacipant-artifact__delete-caption">Delete</div>
          </a>
        </div>
      );

      if (props.isMovable) {
        moveOption = (
          <MoveButton
            cssClassNamePrefix="partiacipant-artifact"
            onClick={toggleMoveArtifact}
            label="Move Artifact"
          />
        );
      }
    }

    actionsNode = (
      <div className="partiacipant-artifact__delete-container">
        <div className="participant-artifact__uplaod-buttons tc__center-text">
          <a
            className={
              'tc__btn tc__btn--v3 tc__btn--flat-blue tc__btn--no-margin' +
              buttonDisabledClass
            }
            onClick={(e) => {
              handleSubmit(e, submitCaption);
            }}
          >
            {submitCaption}
            <Spinner loading={props.loading} />
          </a>
          <a
            className="tc__btn tc__btn--flat-grey"
            onClick={props.handleCloseModal}
          >
            Cancel
          </a>
        </div>
        {deleteOption}
        {moveOption}
      </div>
    );

    // Display the delete confirmation node
    if (props.confirmDelete.visible) {
      return (
        <div className="partiacipant-artifact__delete-container">
          <div className="partiacipant-artifact__delete-confirmation">
            Are you sure you want to delete this Artifact?
          </div>
          <div className="participant-artifact__uplaod-buttons tc__center-text">
            <div onClick={deleteArtifact} className="tc__btn tc__btn--flat-red">
              Delete
            </div>
            <div
              onClick={cancelConfirmDelete}
              className="tc__btn tc__btn--flat-grey"
            >
              Cancel
            </div>
          </div>
        </div>
      );
    }
    // Display the move confirmation node
    if (props.confirmMove.visible) {
      return (
        <MoveForm
          {...props.confirmMove}
          cssClassNamePrefix="outcome-story"
          selectSingleSelectElement={props.selectSingleSelectElement}
          getSingleSelectElement={props.getSingleSelectElement}
          feature="confirmMove"
          field="participantOutcomes"
          setFeatureFieldError={props.setFeatureFieldError}
          submit={props.moveCallback}
          id={props.moveId || props.file.id}
          cancel={toggleMoveArtifact}
          loading={props.loading}
          containerName={props.containerName}
        />
      );
    }

    return actionsNode;
  };

  // set up any errors that pertain to the whole form
  let errorAlertNode = null;
  if (props.errors && props.errors.api) {
    errorAlertNode = (
      <div className="outcome-story__modal-error-alert-row">
        <span className="outcome-story__modal-error-alert">
          {props.errors.api}
        </span>
      </div>
    );
  }

  // set up the artifact type select if necessary
  let artifactTypesFieldNode = null;
  if (props.showArtifactTypes) {
    artifactTypesFieldNode = (
      <ArtifactSelectType
        showDropDownOptions={props.showDropDownOptions}
        selectActive={props.selectActive}
        artifactTypes={props.artifactTypes}
        selectedTypeId={props.selectedTypeId}
        handleSelectArtifactType={props.handleSelectArtifactType}
        handleFocus={() => handleFocus('artifactType')}
        error={props.errors && props.errors.artifactType}
      />
    );
  }

  // set up the outcome evidence checkbox if necessary
  let outcomeEvidenceNode = null;
  if (props.showOutcomeEvidenceCheckbox) {
    outcomeEvidenceNode = (
      <div className={'artifact__form-checkbox-row ' + disabledCssClass}>
        <input
          type="checkbox"
          id="isOutcomeEvidence"
          checked={props.isOutcomeEvidence}
          onChange={props.handleToggleIsOutcomeEvidence}
          disabled={formFieldsAreDisabled}
        />
        <label
          htmlFor="isOutcomeEvidence"
          className="artifact__form-checkbox-label"
        >
          This artifact is Outcome Evidence
        </label>
      </div>
    );
  }

  let participantReflectionNode = null;
  if (props.showParticipantReflectionCheckbox) {
    participantReflectionNode = (
      <div className={'artifact__form-checkbox-row ' + disabledCssClass}>
        <input
          type="checkbox"
          id="isParticipantReflection"
          checked={props.isParticipantReflection}
          onChange={props.handleToggleIsParticipantReflection}
          disabled={formFieldsAreDisabled}
        />
        <label
          htmlFor="isParticipantReflection"
          className="artifact__form-checkbox-label"
        >
          This is a Reflection on my Outcome
        </label>
      </div>
    );
  }

  // only show the coach reflection checkbox if the current user has the right permissions
  let coachReflectionNode = null;
  if (props.hasCoachAccess) {
    coachReflectionNode = (
      <div className={'artifact__form-checkbox-row ' + disabledCssClass}>
        <input
          type="checkbox"
          id="isCoachReflection"
          checked={props.isCoachReflection}
          onChange={props.handleToggleIsCoachReflection}
          disabled={formFieldsAreDisabled}
        />
        <label
          htmlFor="isCoachReflection"
          className="artifact__form-checkbox-label"
        >
          This is a Coach Reflection
        </label>
      </div>
    );
  }

  // set up any errors on the title
  let titleErrorClass = '';
  let titleErrorNode = null;
  if (props.errors && props.errors.title) {
    titleErrorClass = ' error';
    titleErrorNode = <div className="tc__error">{props.errors.title}</div>;
  }

  const notesCaption = props.notesCaption
    ? props.notesCaption
    : 'Tell us more about this artifact, if you’d like';

  let movableFormClass = '';
  let moveFormVisibleClass = '';
  if (props.isMovable) {
    movableFormClass = ' outcome-story__modal-form--movable';
    moveFormVisibleClass = props.confirmMove.visible
      ? ' outcome-story__modal-form--move-visible'
      : moveFormVisibleClass;
  }

  return (
    <div key="artifact-edit">
      <form
        className={
          'artifact__upload-details-form' +
          movableFormClass +
          moveFormVisibleClass
        }
        onSubmit={handleSubmit}
      >
        <div className="participant__artifact-edit-form">
          <div
            className={
              'artifact__form-row artifact__type-title-container artifact__type-title-container--participant-upload ' +
              disabledCssClass
            }
          >
            <label className="participant__artifact-edit-label">
              Name your artifact
            </label>
            <input
              type="text"
              className={
                'tc__input--artifact-title tc__input--artifact-title--tml-v2 tc__input' +
                titleErrorClass
              }
              ref={(ref) => (title = ref)}
              defaultValue={props.file.title}
              placeholder="Add a title to the artifact"
              onFocus={(e) => handleFocus('title')}
              disabled={formFieldsAreDisabled}
            />
            {titleErrorNode}
          </div>
          {artifactTypesFieldNode}
          {outcomeEvidenceNode}
          {participantReflectionNode}
          {coachReflectionNode}
          <div
            className={
              'participant__artifact-notes-container--participant-upload ' +
              disabledCssClass
            }
          >
            <label className="participant__artifact-edit-label">
              {notesCaption}
            </label>
            <textarea
              className="tc__input--artifact-notes artifact__notes--participant-upload tc__input"
              ref={(ref) => (notes = ref)}
              defaultValue={props.file.notes}
              placeholder="Add any notes about the artifact"
              onFocus={() => handleFocus('notes')}
              disabled={formFieldsAreDisabled}
            />
          </div>
          {errorAlertNode}
          {getActionsNode()}
        </div>
      </form>
    </div>
  );
};

ArtifactForm.proptypes = {
  file: PropTypes.object.isRequired,
  showArtifactTypes: PropTypes.bool,
  artifactTypes: PropTypes.array,
  selectActive: PropTypes.bool,
  selectedTypeId: PropTypes.number,
  showDropDownOptions: PropTypes.func,
  handleSelectArtifactType: PropTypes.func,
  showOutcomeEvidenceCheckbox: PropTypes.bool,
  isOutcomeEvidence: PropTypes.bool,
  handleToggleIsOutcomeEvidence: PropTypes.func,
  handleFocus: PropTypes.func,
  editArtifact: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  errors: PropTypes.object,
  toggleElement: PropTypes.func.isRequired,
  deleteArtifact: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  getSingleSelectElement: PropTypes.func.isRequired,
  selectSingleSelectElement: PropTypes.func.isRequired,
  confirmDelete: PropTypes.object.isRequired,
  confirmMove: PropTypes.object.isRequired,
  setFeatureFieldError: PropTypes.func.isRequired,
  moveCallback: PropTypes.func.isRequired,
  containerName: PropTypes.string.isRequired,
  moveId: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  isMovable: PropTypes.bool.isRequired,
};

ArtifactForm.defaultProps = {
  loading: false,
  isMovable: true,
};

export default ArtifactForm;
