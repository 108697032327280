// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';

const BlendedBreadcrumbs = (props) => {
  let selectedHeadlineNode;
  if (props.selectedHeadline) {
    selectedHeadlineNode = (
      <div className="mtp-lesson__crumb">
        <NavigationLink
          url={process.env.REACT_APP_TEACHING_URL + '/browse/blended'}
        >
          <span className="mtp-lesson__crumb-caption">
            {props.selectedHeadline}
          </span>
        </NavigationLink>
        <div className="mtp-lesson__crumb-border"></div>
      </div>
    );
  }

  let activeCrumb, dropdownItemsNode, activeCrumbNode;
  if (props.breadcrumbs) {
    activeCrumbNode = props.breadcrumbs.map((crumb) => {
      dropdownItemsNode = crumb.map((item) => {
        let selectedClass = '';
        if (item.selected) {
          selectedClass = ' selected';
          activeCrumb = item;
        }

        return (
          <li className={selectedClass} key={'blended-dropdown-' + item.tc_id}>
            <NavigationLink
              url={
                process.env.REACT_APP_TEACHING_URL +
                '/browse/blended_topic/' +
                item.tc_id +
                '/' +
                item.seo_url +
                '?from=breadcrumb_blended_topic'
              }
            >
              <span>{item.name}</span>
            </NavigationLink>
          </li>
        );
      });

      const blendedDropdownClass = props.blendedDropdownActive ? ' active' : '';
      const blendedDropdownNode = (
        <div className={'mtp-lesson__crumb-dropdown' + blendedDropdownClass}>
          <ul>{dropdownItemsNode}</ul>
        </div>
      );
      return (
        <div
          className="mtp-lesson__crumb"
          key={'activeCrumb-' + activeCrumb.id}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              props.openBlendedDropdown();
            }}
          >
            <span className="mtp-lesson__crumb-caption">
              {activeCrumb.name}
            </span>
            <span className="mtp-lesson__crumb-icon tc-bold-arrow"></span>
          </a>
          {blendedDropdownNode}
        </div>
      );
    });
  }

  return (
    <div className="mtp-lesson__breadcumb-wrapper show-desktop hide-phone">
      <div className="row row--1200">
        <div className="mtp-lesson__crumb">
          <NavigationLink
            url={process.env.REACT_APP_TEACHING_URL + '/browse/blended'}
          >
            <span className="mtp-lesson__crumb-caption">Blended</span>
          </NavigationLink>
          <div className="mtp-lesson__crumb-border"></div>
        </div>
        {selectedHeadlineNode}
        {activeCrumbNode}
      </div>
    </div>
  );
};

BlendedBreadcrumbs.propTypes = {
  selectedHeadline: PropTypes.string,
  breadcrumbs: PropTypes.array.isRequired,
  blendedDropdownActive: PropTypes.bool,
  openBlendedDropdown: PropTypes.func.isRequired,
};

export default BlendedBreadcrumbs;
