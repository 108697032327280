// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Analytics from './../../../components/Analytics';
import BackButton from './modules/BackButton';

const SubjectStep = (props) => {
  let subjectFocus;
  let sped;
  let ell;
  let subjectNotes;

  const handleSubjectsChange = (e, subjectIndex) => {
    e.preventDefault();

    const actionPrefix = e.target.checked ? 'select_' : 'deselect_';

    let actionName = props.subjects[subjectIndex].name
      .toLowerCase()
      .replace(/[-\s/]/g, '_');
    switch (actionName) {
      case 'english___language_arts':
        actionName = 'ela';
        break;
      case 'technology_and_engineering':
        actionName = 'technology_engineering';
        break;
      case 'library_science':
        actionName = 'tech_library_specialist';
        break;
    }
    Analytics.logEvent({
      category: 'subject_info',
      action: actionPrefix + actionName,
      label: 'from_fourth_page_user_welcome',
    });

    const val = parseInt(e.target.value);

    if (e.target.checked) {
      props.ui.subjects.push(val);
    } else {
      props.ui.subjects.splice(props.ui.subjects.indexOf(val), 1);
    }
    props.clearErrorsOnFocus('subject');
    props.setSubjects(props.ui.subjects);
  };

  // on this page, only the Notes field uses this, which has no errors
  const handleFocus = (e, field) => {
    e.preventDefault();
    props.clearErrorsOnFocus(field);
  };

  const handleChangeSped = (e) => {
    const val = e.target.value;
    if (val) {
      Analytics.logEvent({
        category: 'sped',
        action:
          'select_' +
          props.spedAnswers[val].toLowerCase().replace(/,\s|[-\s/]/g, '_'),
        label: 'from_fifth_page_user_welcome',
      });
    }
  };

  const handleChangeEll = (e) => {
    const val = e.target.value;
    if (val) {
      Analytics.logEvent({
        category: 'ell',
        action:
          'select_' +
          props.ellAnswers[val].toLowerCase().replace(/,\s|[-\s/]/g, '_'),
        label: 'from_fifth_page_user_welcome',
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Analytics.logEvent({
      category: 'next',
      action: 'click',
      label: 'from_fourth_page_user_welcome',
    });

    props.submit({
      importSubjectId: subjectFocus.value,
      sped: sped.value,
      ell: ell.value,
      subjectNotes: subjectNotes.value,
    });
  };

  // set up errors for grade band
  let subjectFocusErrorClass = '';
  let subjectFocusErrorNode = null;
  if ('subjectFocus' in props.ui.errors) {
    subjectFocusErrorClass = ' error';
    subjectFocusErrorNode = (
      <div className="v5__error">{props.ui.errors.subjectFocus}</div>
    );
  }

  // set up grade band select
  const subjectFocusOptionsNode = Object.keys(props.subjectFocusAnswers)
    .filter((optionId) => {
      // skip, 'Other', 'All Subjects', 'Foreign Language', 'Enrichment', 'Differentiation', 'School Culture and Practices', 'Library Science', 'Instructional Technology'
      return (
        [
          '26596',
          '26597',
          '6',
          '14',
          '25597',
          '25598',
          '26590',
          '26598',
        ].indexOf(optionId) === -1
      );
    })
    .map((optionId) => {
      return (
        <option key={'subjectoption-' + optionId} value={optionId}>
          {props.subjectFocusAnswers[optionId]}
        </option>
      );
    });
  // put this option at the top of the list after 'Select One'
  subjectFocusOptionsNode.unshift(
    <option
      key={'subjectoption-1-' + props.allSubjectsId}
      value={props.allSubjectsId}
    >
      Elementary Education
    </option>
  );
  subjectFocusOptionsNode.unshift(
    <option key={'subjectoption-0'} value="">
      Select One
    </option>
  );
  // extra options that equal to All Subjects
  subjectFocusOptionsNode.push(
    <option
      key={'subjectoption-2-' + props.allSubjectsId}
      value={props.allSubjectsId}
    >
      I teach across a wide range of subjects
    </option>
  );
  subjectFocusOptionsNode.push(
    <option
      key={'subjectoption-3-' + props.allSubjectsId}
      value={props.allSubjectsId}
    >
      I coach or supervise teachers across a wide range of subjects
    </option>
  );
  // include library science
  subjectFocusOptionsNode.push(
    <option key={'subjectoption-26590'} value="26590">
      I want support as a librarian
    </option>
  );
  // include instructional technology
  subjectFocusOptionsNode.push(
    <option key={'subjectoption-26598'} value="26598">
      I want support as an instructional technologist
    </option>
  );

  // set up errors for sped
  let spedErrorClass = '';
  let spedErrorNode = null;
  if ('sped' in props.ui.errors) {
    spedErrorClass = ' error';
    spedErrorNode = <div className="v5__error">{props.ui.errors.sped}</div>;
  }

  // set up sped select
  const spedOptionsNode = Object.keys(props.spedAnswers)
    .filter((optionId) => {
      // skip, 'No'
      return optionId !== '3';
    })
    .map((optionId) => {
      return (
        <option key={'spedoption-' + optionId} value={optionId}>
          {props.spedAnswers[optionId]}
        </option>
      );
    });
  // put 'No' at the top after 'Select One'
  spedOptionsNode.unshift(
    <option key={'spedoption-3'} value="3">
      No
    </option>
  );
  spedOptionsNode.unshift(
    <option key={'spedoption-0'} value="">
      Select One
    </option>
  );

  // set up errors for ell
  let ellErrorClass = '';
  let ellErrorNode = null;
  if ('ell' in props.ui.errors) {
    ellErrorClass = ' error';
    ellErrorNode = <div className="v5__error">{props.ui.errors.ell}</div>;
  }

  // set up ell select
  const ellOptions = Object.keys(props.ellAnswers);
  ellOptions.reverse();
  const ellOptionsNode = ellOptions.map((optionId) => {
    return (
      <option key={'elloption-' + optionId} value={optionId}>
        {props.ellAnswers[optionId]}
      </option>
    );
  });
  ellOptionsNode.unshift(
    <option key={'elloption-none'} value="">
      Select One
    </option>
  );

  // set up errors for subject
  let subjectErrorClass = '';
  let subjectErrorNode = null;
  if ('subject' in props.ui.errors) {
    subjectErrorClass = ' error';
    subjectErrorNode = (
      <div className="v5__error">{props.ui.errors.subject}</div>
    );
  }

  // disable buttons when form submission is loading
  let disabledClass = '';
  let spinnerNode = null;
  if (props.ui.loading) {
    disabledClass = ' disabled';
    spinnerNode = <span className="littlespinner"></span>;
  }

  return (
    <div className="row row--1200 registration__primary">
      <form className="cute-10-desktop cute-12-phone center-desktop">
        <div className="registration__headline tc__headline-2">
          What are your subjects for the current school year?
        </div>
        <div className="registration__input-label">
          If you are an instructional leader or coach, indicate the subject(s)
          taught by the teachers you work with. Additionally, let us know if you
          have a specialized role in your school.
        </div>
        <div
          className={
            'registration__form-item registration__form-item--input-area' +
            subjectErrorClass
          }
        >
          <div className="registration__checkbox-container">
            {props.subjects
              .filter((subject) => {
                // skip 'All Subjects', 'Foreign Language', 'Enrichment', 'Differentiation', 'School Culture and Practices'
                return [26597, 6, 14, 25597, 25598].indexOf(subject.id) === -1;
              })
              .map((subject, i) => {
                const checked =
                  props.ui.subjects.indexOf(parseInt(subject.id)) !== -1;
                const checkedClass = checked ? ' checked' : '';
                const lastInRowClass = (i + 1) % 4 === 0 ? ' last-in-row' : '';
                let subjectName = subject.name;
                if (subjectName === 'Library Science') {
                  subjectName = 'I am a librarian';
                } else if (subjectName === 'Instructional Technology') {
                  subjectName = 'I am an instructional technologist';
                }

                const otherSubCopy =
                  subjectName === 'Other' && checked ? (
                    <div className="v5__author-title-copy registration__checkbox-sub-copy">
                      Please share more detail about the subject you teach in
                      the Additional Notes field below
                    </div>
                  ) : null;

                return (
                  <React.Fragment key={i}>
                    <label
                      key={'subject-' + subject.id}
                      className={
                        'registration__checkbox-capsule registration__checkbox-capsule--tall' +
                        checkedClass +
                        lastInRowClass
                      }
                    >
                      <input
                        type="checkbox"
                        checked={
                          props.ui.subjects.indexOf(parseInt(subject.id)) !== -1
                        }
                        value={subject.id}
                        onChange={(e) => handleSubjectsChange(e, i)}
                      />
                      <span className="tc-check"></span>
                      {subjectName}
                    </label>
                    {otherSubCopy}
                  </React.Fragment>
                );
              })}
            {subjectErrorNode}
          </div>
        </div>

        <div className="registration__form-item">
          <div className="registration__headline tc__headline-2">
            What subject would you like to focus on for coaching?
          </div>
          <div className="registration__input-label">
            Choose the subject or specialized role where you’d like the most
            support—you will be matched with a coach that specializes in that
            area.
          </div>
          <select
            ref={(ref) => (subjectFocus = ref)}
            defaultValue={props.participant.subject_focus_id}
            onFocus={(e) => handleFocus(e, 'subjectFocus')}
            className={
              'registration__input tc__select' + subjectFocusErrorClass
            }
          >
            {subjectFocusOptionsNode}
          </select>
          {subjectFocusErrorNode}
        </div>

        <div className="registration__form-item">
          <div className="registration__headline tc__headline-2">
            Do you primarily teach students with Special Needs?
          </div>
          <select
            ref={(ref) => (sped = ref)}
            defaultValue={props.participant.sped}
            onFocus={(e) => handleFocus(e, 'sped')}
            onChange={handleChangeSped}
            className={'registration__input tc__select' + spedErrorClass}
          >
            {spedOptionsNode}
          </select>
          {spedErrorNode}
        </div>

        <div className="registration__form-item">
          <div className="registration__headline tc__headline-2">
            In your role, are you <span className="italic">primarily</span>{' '}
            focused on English Learners (EL)?
          </div>
          <select
            ref={(ref) => (ell = ref)}
            defaultValue={props.participant.ell}
            onFocus={(e) => handleFocus(e, 'ell')}
            onChange={handleChangeEll}
            className={'registration__input tc__select' + ellErrorClass}
          >
            {ellOptionsNode}
          </select>
          {ellErrorNode}
        </div>

        <div className="registration__form-item">
          <div className="registration__headline tc__headline-2">
            Additional notes (optional)
          </div>
          <div className="registration__input-label">
            Please provide any additional context about you, your role and
            responsibilities that might be helpful for your coach to know.
          </div>
          <textarea
            rows="4"
            ref={(ref) => (subjectNotes = ref)}
            defaultValue={props.participant.subject_notes}
            onFocus={(e) => handleFocus(e, 'subject_notes')}
            className="registration__input v5__input"
            placeholder="I teach Algebra I vs Calculus"
          />
        </div>

        <div className="registration__submit-btns">
          <BackButton
            caption={'Back to Grades'}
            participant={props.participant}
            step={props.step}
            analyticsLabel={'from_fourth_page_user_welcome'}
          />
          <button
            onClick={handleSubmit}
            className={
              'tc__btn tc__btn--flat-blue tc__btn--flat-blue-v3' + disabledClass
            }
          >
            {spinnerNode}
            Next: Availability
          </button>
        </div>
      </form>
    </div>
  );
};

SubjectStep.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  setSubjects: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  allSubjectsId: PropTypes.number.isRequired,
};

export default SubjectStep;
