// @ts-nocheck FIXME
import StaticUtils from './StaticUtils';
import StorageHelper from './StorageHelper';

const _userHasRole = function (user, role) {
  user = user || this?.pageData?.user;
  if (!user || !user.auth_roles) {
    return false;
  }

  return StaticUtils.inArray(role, user.auth_roles);
};

const _hasProductRole = function (product, role) {
  if (!this?.pageData?.user?.product_roles) {
    return false;
  }

  const productRoles = this.pageData.user.product_roles;
  const roles = productRoles[product];

  if (!roles) {
    return false;
  }

  return StaticUtils.inArray(role, roles);
};

const _hasProduct = function (product) {
  if (!this?.pageData?.user?.product_roles) {
    return false;
  }

  const productRoles = this.pageData.user.product_roles;
  const roles = productRoles[product];

  if (!roles) {
    return false;
  }

  return roles.length > 0;
};

const _isElevatedUserType = function (user) {
  return (
    _userHasRole.call(this, user, 'BL_SERVICES_MANAGER') ||
    _userHasRole.call(this, user, 'BL_MANAGER') ||
    _userHasRole.call(this, user, 'ADMIN')
  );
};

const _isParticipant = function (user) {
  user = user || this?.pageData?.user;
  return (
    user &&
    'participants' in user &&
    user.participants.length &&
    _userHasRole.call(this, user, 'TC_TEACHER')
  );
};

const _isContractCoach = function (user) {
  return _userHasRole.call(this, user, 'BL_CONTRACT_COACH');
};

const Utils = {
  ...StaticUtils,

  isElevatedUserType: _isElevatedUserType,

  isAdmin: function (user) {
    return _userHasRole.call(this, user, 'ADMIN');
  },

  isManager: function (user) {
    return _userHasRole.call(this, user, 'BL_MANAGER');
  },

  isNonPsmUserManager: (user) => {
    user = user || this?.pageData?.user;
    if (!user || !user.auth_roles) {
      return false;
    }

    return (
      _userHasRole.call(this, user, 'BL_MANAGER') && user.details.group_id !== 5
    );
  },

  isDataEditor: function (user) {
    return _userHasRole.call(this, user, 'TC_DATA_EDITOR');
  },

  isDataViewer: function (user) {
    return _userHasRole.call(this, user, 'BL_TEAM_MEMBER');
  },

  isContentCurator: function (user) {
    return _userHasRole.call(this, user, 'TC_CONTENT_CURATOR');
  },

  isContentViewer: function (user) {
    return _userHasRole.call(this, user, 'TC_CONTENT_VIEWER');
  },

  isHelpDeskAgent: function (user) {
    return _userHasRole.call(this, user, 'HELP_DESK_AGENT');
  },

  isServicesManager: function (user) {
    return _userHasRole.call(this, user, 'BL_SERVICES_MANAGER');
  },

  isContractCoach: _isContractCoach,

  isTeacher: function (user) {
    return _userHasRole.call(this, user, 'TC_TEACHER');
  },

  isTeamMember: function (user) {
    return _userHasRole.call(this, user, 'BL_TEAM_MEMBER');
  },

  isTeachersCoach: function (user, participant) {
    return user.id === participant.coach_user_id;
  },

  isParticipant: _isParticipant,

  isOnlyParticipant: function () {
    const user = this?.pageData?.user;

    return (
      user &&
      _isParticipant.call(this, user) &&
      _userHasRole.call(this, user, 'BL_USER') &&
      user.auth_roles.length === 2
    );
  },

  isActiveParticipant: function (user) {
    user = user || this?.pageData?.user;
    const currentSchoolYear = StaticUtils.getCurrentSchoolYear();
    // here we define "active" as just belonging to this school year
    return (
      user &&
      'participants' in user &&
      user.participants.length &&
      user.participants.find((par) => par.school_year === currentSchoolYear)
    );
  },

  isPartnerLeader: function (user) {
    user = user || this?.pageData?.user;
    return (
      user &&
      'leads' in user &&
      user.leads.length &&
      _userHasRole.call(this, user, 'TC_PARTNER')
    );
  },

  isFreeUser: function (user) {
    user = user || this?.pageData?.user;
    const onlyHasUserRole =
      user &&
      user.auth_roles &&
      user.auth_roles.length === 1 &&
      _userHasRole.call(this, user, 'BL_USER');
    const archivedPL =
      user &&
      user.auth_roles &&
      user.auth_roles.length === 2 &&
      _userHasRole.call(this, user, 'TC_PARTNER') &&
      user.active_lead === 'v4';
    return onlyHasUserRole || archivedPL;
  },

  hasProductRole: _hasProductRole,

  hasProduct: _hasProduct,

  hasFeatureFlag: function (featureFlag) {
    if (!this?.pageData?.feature_flags) {
      return false;
    }

    return StaticUtils.inArray(featureFlag, this.pageData.feature_flags);
  },

  participantHasFeatureFlag: function (featureFlag) {
    if (!this?.pageData?.participant?.feature_flags) {
      return false;
    }

    return StaticUtils.inArray(
      featureFlag,
      this.pageData.participant.feature_flags
    );
  },

  isReportingUser: function () {
    return _hasProductRole.call(this, 'UTILIZATION_REPORTING', 'viewer');
  },

  isBLConnectUser: function () {
    return _hasProduct.call(this, 'BL_CONNECT');
  },

  // Decides whether a user should see the MTP header in the Strategy Browser
  showMtpHeader: function (user) {
    const tcRoles = ['TC_CONTENT_VIEWER'];

    // Does user have at least one role?
    for (const index in tcRoles) {
      if (_userHasRole.call(this, user, tcRoles[index])) {
        return true;
      }
    }

    return false;
  },

  showPartnerLeaderDetails: function (participantUser) {
    const user = this?.pageData?.page_user || this?.pageData?.user;

    if (!participantUser) {
      return true;
    }

    if (!_userHasRole.call(this, participantUser, 'TC_PARTNER')) {
      return true;
    }

    if (
      _isElevatedUserType.call(this, user) ||
      _isContractCoach.call(this, user)
    ) {
      return true;
    }

    if (participantUser.id === user.id) {
      return true;
    }

    return false;
  },

  reachedFreeContentLimit: (currentLessonId, currentStrategyId) => {
    // get the list of lessons and strategies viewed
    const lessons = StorageHelper.getCookieItem('bl_lessons_viewed') || [];
    const strategies =
      StorageHelper.getCookieItem('bl_strategies_viewed') || [];

    // return whether or not we have reached 5 total content pages
    return (
      ((currentLessonId && lessons.indexOf(currentLessonId) === -1) ||
        (currentStrategyId && strategies.indexOf(currentStrategyId) === -1)) &&
      lessons.length + strategies.length >=
        this?.pageData?.strategy_and_lesson_view_limit
    );
  },
};

export default Utils;
