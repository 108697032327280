// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const ArtifactUpload = (props) => {
  let inputFiles = [];
  const files = {};
  let completedFiles = 0;
  let dropzone;
  let filesInput;

  const submitFiles = () => {
    props.submitFiles({ inputFiles: inputFiles });
  };

  const handleSelectFiles = (e) => {
    // Prevent propagation of the click event
    e.stopPropagation();
    props.handleSelectFiles();
  };

  const onDropHandler = (e) => {
    e.preventDefault();

    props.onDragLeave();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    inputFiles = files;
    completedFiles = 0;

    props.submitFiles({ inputFiles: inputFiles });
  };

  const handleArtifactsChange = (e) => {
    e.preventDefault();
    inputFiles = e.target.files;
    completedFiles = 0;

    submitFiles();
  };

  // control if uploader is blurred or not
  const blurClass = props.blur ? ' artifact__dropzone--blur' : '';
  const dragOverClass = props.dragOver ? ' artifact__dropzone--dragover' : '';

  // button for opening file system
  const fileSelectNode = (
    <div className="artifact__upload-target" key="artifact-upload">
      <div key="upload" onClick={handleSelectFiles}>
        <form>
          <input
            className="artifact__upload-field"
            ref={(ref) => (filesInput = ref)}
            type="file"
            onChange={handleArtifactsChange}
            multiple={props.multiple}
          />
        </form>
      </div>
    </div>
  );

  const errorNode = (
    <div className="tc__error tc__center-text artifact__upload-error">
      {props.error}
    </div>
  );

  // Mobile devices should see the camera option when selecting files
  const mobileUploadNode = (
    <div className="hide-desktop show-phone">
      <button
        className="artifact__upload tc__btn tc__btn--flat-blue"
        href="#upload-dialog"
      >
        Add
        <div className="hide-desktop show-phone" key="artifact-upload-mobile">
          <div key="upload" onClick={handleSelectFiles}>
            <form>
              <input
                className="artifact__upload-field"
                ref={(ref) => (filesInput = ref)}
                type="file"
                onChange={handleArtifactsChange}
                multiple={props.multiple}
                accept="*"
              />
            </form>
          </div>
        </div>
      </button>
      <div className="artifact__cancel--mobile tc__center-text">
        <div className="tc__link" onClick={props.handleUploadCancel}>
          Cancel
        </div>
      </div>
    </div>
  );
  // output form
  return (
    <div>
      <div className={'artifact__wrapper hide-phone show-desktop'}>
        <div
          ref={(ref) => (dropzone = ref)}
          className={
            'cute-12-desktop artifact__dropzone artifact__dropzone--tml-v2' +
            dragOverClass +
            blurClass
          }
          onClick={props.handleSelectFiles}
          onDragLeave={props.onDragLeave}
          onDragExit={props.onDragLeave}
          onDragOver={props.onDragOver}
          onDragEnter={props.onDragOver}
          onDrop={onDropHandler}
        >
          <img
            src="/legacy-images/artifact-upload.svg"
            style={{ width: '170px' }}
          />
          <div className="hide-phone show-desktop artifact__dropzone-headline tc__headline-3 tc__link tc__link--black">
            DRAG &amp; DROP
          </div>
          <div className="hide-desktop show-phone artifact__dropzone-headline tc__headline-3 tc__link">
            Take a picture, video or upload a file
          </div>
          <div className="hide-phone show-desktop artifact__prefer tc__body-copy">
            your file here, or{' '}
            <span className="artifact__dropzone-browse">browse</span>.
          </div>
          <div className="hide-phone show-desktop artifact__max-size tc__caption-copy">
            Maximum file size 1024MB
          </div>
          <div className="hide-desktop show-phone artifact__max-size tc__caption-copy">
            (Max size: 1024MB)
          </div>
          <div>{fileSelectNode}</div>
        </div>
      </div>
      <div className="tc__center-text show-desktop hide-phone">
        <button
          className="tc__btn tc__btn--flat-grey"
          onClick={props.handleUploadCancel}
        >
          Cancel
        </button>
      </div>
      {mobileUploadNode}
      {errorNode}
    </div>
  );
};

ArtifactUpload.propTypes = {
  submitFiles: PropTypes.func.isRequired,
  handleSelectFiles: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  handleUploadCancel: PropTypes.func.isRequired,
  error: PropTypes.string,
  dragOver: PropTypes.bool,
  blur: PropTypes.bool,
  multiple: PropTypes.bool,
};

ArtifactUpload.defaultProps = {
  multiple: false,
};

export default ArtifactUpload;
