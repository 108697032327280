// @ts-nocheck FIXME
const Introduction = (props) => {
  // we only render segments if we have data for them
  let vision = null;
  if (props.vision_statement) {
    vision = (
      <div className="portfolio__intro">
        <div className="portfolio__section-headline portfolio__section-headline--intro">
          Vision of Student Success
        </div>
        <div className="portfolio__intro-body portfolio__intro-body--vision">
          {props.vision_statement}
        </div>
      </div>
    );
  }

  let summary = null;
  if (props.summary_description) {
    summary = (
      <div className="portfolio__intro">
        <div className="portfolio__section-headline portfolio__section-headline--intro">
          Design Summary
        </div>
        <div className="portfolio__intro-body portfolio__intro-body--vision">
          {props.summary_description}
        </div>
      </div>
    );
  }

  let intro = null;
  if (props.teacher_introduction) {
    intro = (
      <div className="portfolio__intro">
        <div className="portfolio__section-headline portfolio__section-headline--intro">
          Introduction
        </div>
        <div className="portfolio__intro-body">
          {props.teacher_introduction}
          <div className="portfolio__intro-author">
            - {props.author.first_name} {props.author.last_name}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {vision}
      {summary}
      {intro}
      <hr className="page-break" />
    </div>
  );
};

export default Introduction;
