// @ts-nocheck FIXME
import AbridgeText from '@legacy-components/react/V5/Common/AbridgeText';
import PropTypes from 'prop-types';
import UserInfo from './../../../Common/UserInfo';

const sortByName = (a, b) => (a.name > b.name ? 1 : -1);

const SideBar = (props) => {
  let leadershipNode = null;

  leadershipNode = (
    <div className="partner_lead_v5__sidebar-leadership-team">
      <div className="partner_lead_v5__sidebar--section-title">
        Leadership Team
      </div>
      <AbridgeText
        isExpandable={true}
        maxHeight={100}
        className="partner_lead_v5__sidebar--list"
      >
        {props.leads.sort(sortByName).map((lead) => (
          <div key={`lead-${lead.id}`}>{lead.name}</div>
        ))}
      </AbridgeText>
    </div>
  );

  let schoolNode = null;

  if (props.schools && props.schools.length) {
    schoolNode = (
      <div className="partner_lead_v5__sidebar-schools">
        <div className="partner_lead_v5__sidebar--section-title">Schools</div>
        <AbridgeText
          isExpandable={true}
          maxHeight={100}
          className="partner_lead_v5__sidebar--list"
        >
          {props.schools.sort(sortByName).map((school) => {
            if (school.id === 116438) return null;
            return (
              <div
                key={`school-${school.id}`}
                className="partner_lead_v5__sidebar--school"
              >
                {school.name.toLowerCase()}
              </div>
            );
          })}
        </AbridgeText>
      </div>
    );
  }

  return (
    <div className="row row--1200 partner_lead_v5__sidebar">
      <div className="partner_lead_v5__sidebar-psm">
        <div className="partner_lead_v5__sidebar--section-title">
          Partner Success Manager{props.psms.length > 1 && 's'}
        </div>
        {props.psms.sort().map((psm) => (
          <UserInfo key={`psm-${psm.id}`} {...psm} />
        ))}
      </div>
      {leadershipNode}
      {schoolNode}
    </div>
  );
};

SideBar.propTypes = {
  leads: PropTypes.array,
  schools: PropTypes.array,
  psms: PropTypes.array,
};

export default SideBar;
