// @ts-nocheck FIXME
import Store from './../../components/Store';

// This store is for all the dialogues that use the MultiSelect component in the strategy browser -
// currently that's CoachPinDialogue, CoachAddNoteDialogue, and CoachAddChallengeDialogue
const store = Store('StrategyMultiSelectStore', {
  teachers: [],
  teams: [],
  btnDisabled: false,
  errors: {},
  selectedOptions: [],
  visibleOptions: [],
  inputStatus: 'blur', // focus, blur, active
  charCount: 0,
  modalMarginTop: null,
});

// ACTIONS
store.actionCreate('init', function (data) {
  store.teachers = data.teachers || [];
  store.teams = data.teams || [];
  store.btnDisabled = false;
  store.errors = {};
  store.selectedOptions = [];
});

// sets which teachers and teams are selected in the multiselect
store.actionCreate('setSelections', function (data) {
  store.teachers = data.teachers;
  store.teams = data.teams;
});

store.actionCreate('setBtnDisabled', function (isDisabled) {
  store.btnDisabled = isDisabled;
});

store.actionCreate('setErrors', function (errors) {
  store.errors = errors;
});

store.actionCreate('updateMultiSelect', function (data) {
  store.selectedOptions = data.selectedOptions
    ? data.selectedOptions
    : store.selectedOptions;
  store.visibleOptions = data.visibleOptions
    ? data.visibleOptions
    : store.visibleOptions;
  store.inputStatus = data.inputStatus ? data.inputStatus : store.inputStatus;
  store.charCount =
    data.charCount || data.charCount === 0 ? data.charCount : store.charCount;
  store.teachers = data.teachers ? data.teachers : store.teachers;
  store.teams = data.teams ? data.teams : store.teams;
  store.dontBlur = data.dontBlur ? data.dontBlur : store.dontBlur;
});

store.actionCreate('setModalMarginTop', function (modalMarginTop) {
  store.modalMarginTop = modalMarginTop;
});

store.actionCreate('clearError', function (errorField) {
  delete store.errors[errorField];
});

export default store;
