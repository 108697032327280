// @ts-nocheck FIXME
import ResourceApi from '@legacy-components/apis/ResourceApi';
import Analytics from '@legacy-components/components/Analytics';
import NavigationLink from '@legacy-components/react/NavigationLink';
import Video from '@legacy-components/react/Utilities/Video';
import TimeDelta from '@legacy-components/react/V4/Common/TimeDelta';
import { useEffect, useState } from 'react';
import ArtifactPagination from './modules/ArtifactPagination';

const ArtifactContainer = (props) => {
  const [activePage, changeActivePage] = useState(0);
  const [imageError, setImageError] = useState(false);
  const [hasViablePreview, setHasViablePreview] = useState(false);
  const [downloadError, setDownloadError] = useState('');
  const [artifactDownloading, setArtifactDownloading] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(94);

  // new entry connections or active pages must change for this to check for image previews
  useEffect(() => {
    setHeaderHeight(
      document.querySelector('.artifact__header-wrap').offsetHeight
    );
    window.addEventListener('resize', calculateHeaderHeight);
    return () => {
      window.removeEventListener('resize', calculateHeaderHeight);
    };
  }, []);

  useEffect(() => {
    if (
      !props.entry.connections.content ||
      !props.entry.connections.content.preview.length
    ) {
      setHasViablePreview(false);
      setImageError(true);
      return;
    }
    const image = new Image();
    image.src = props.entry.connections.content.preview[activePage];

    image.onload = () => {
      setHasViablePreview(true);
    };
    image.onerror = () => {
      setImageError(true);
    };
  }, [props.entry.connections, activePage]);

  const calculateHeaderHeight = () => {
    const currentHeaderHeight = document.querySelector(
      '.artifact__header-wrap'
    ).offsetHeight;
    if (headerHeight == currentHeaderHeight) {
      return;
    }

    setHeaderHeight(currentHeaderHeight);
  };

  // nextPageIndex will be the array position of the previous or next page
  const handlePaginationClick = (nextPageIndex) => {
    // log metrics event
    Analytics.logEvent({
      category: 'artifact_pagination',
      action: nextPageIndex > activePage ? 'next_page' : 'previous_page',
      label: 'from_artifact_details',
    });

    // go forward or backwards a page
    changeActivePage(nextPageIndex);
  };

  // attempting to download a resource artifact
  const handleDownloadClick = (e) => {
    e.preventDefault();

    // log the download metric
    Analytics.logEvent({
      category: 'artifact',
      action: 'download',
      label: 'from_artifact_details',
    });

    const data = {};
    // set the id of the artifact that will be downloaded
    // condition used to ensure that if an id is unavailable use the available appropreate id
    if (props.entry.connections.content.preview_lessonfile_id) {
      data['id'] = props.entry.connections.content.preview_lessonfile_id;
    } else if (props.entry.connections.download_lessonfile_id) {
      data['id'] = props.entry.connections.download_lessonfile_id;
    } else {
      data['id'] = null;
    }

    // Handle cases where the lessonfile id is not known
    if (!data['id']) {
      // no id :(
      setDownloadError(
        'Sorry, we could not download the artifact. Please try again later.'
      );
      // end loading state
      setArtifactDownloading(false);
      return false;
    }

    // used if loading sate is necessary for download
    setArtifactDownloading(true);

    ResourceApi.incrementDownloadCount(data)
      .then((response) => {
        // navigate to file download url and download the file
        location.href = props.entry.connections.download_url;
        // end loading state
        setArtifactDownloading(false);
      })
      .catch(
        function (response) {
          // download failed
          setDownloadError(
            'Sorry, we could not find the artifact. Please try again later.'
          );
          // end loading state
          setArtifactDownloading(false);
        }.bind(this)
      );
  };
  const title = props.entry.name ? props.entry.name : props.entry.title;

  const closeNode = (
    <NavigationLink
      className="artifact__action-btn artifact__action-btn--close v5__heading-5"
      url={props.closeRedirect}
    >
      <div className="tc-x"></div>
    </NavigationLink>
  );

  let downloadNode;
  if (props.entry.connections.download_url) {
    // loading spinner
    const downloadSpinner = artifactDownloading ? (
      <div className="artifact-overlay__download-loader littlespinner"></div>
    ) : null;

    downloadNode = (
      <a
        className="artifact__action-btn artifact__action-btn--download"
        onClick={handleDownloadClick}
      >
        <div className="tc-download"></div>
        <div>Download</div>
        <div>
          {downloadSpinner}
          <div className="tc__error artifact__download_error artifact__download-error--v3">
            {downloadError}
          </div>
        </div>
      </a>
    );
  }
  let noPreviewClass = '';
  let previewNode = (
    <div className="artifact__loading-preview v5__heading-3">
      <div className="artifact__loading-preview-container">
        <img
          className="artifact__loading-preview-image"
          src="/legacy-images/icon-loading.svg"
          alt="loading preview"
        />
        <div>Image loading...</div>
      </div>
    </div>
  );
  if (imageError) {
    noPreviewClass = '';
    previewNode = (
      <div className="artifact__loading-preview v5__heading-5">
        <div className="artifact__loading-preview-container">
          <img
            className="artifact__no-preview-image"
            src="/legacy-images/artifact-failed.svg"
            alt="no preview"
          />
          <div>Preview not available</div>
        </div>
      </div>
    );
  }
  let paginationNode;
  switch (props.entry.connections.preview_type) {
    case 'image':
    case 'document':
      // this image has working previews
      if (hasViablePreview) {
        noPreviewClass = '';
        previewNode = (
          <img
            src={props.entry.connections.content.preview[activePage]}
            id="artifact-overlay__preview"
            className="artifact-overlay__image tc__visible artifact-overlay__image--max-width i-i"
          />
        );
        // this is a multi page document
        if (props.entry.connections.content.preview.length > 1) {
          previewNode = [];

          paginationNode = (
            <ArtifactPagination
              activePage={activePage}
              handlePaginationClick={handlePaginationClick}
              contentLength={props.entry.connections.content.preview.length}
            />
          );

          // create the array of preview objects
          for (
            let i = 0;
            props.entry.connections.content.preview.length > i;
            i++
          ) {
            const displayPageClass =
              i == activePage ? ' tc__visible' : ' tc__hidden';
            previewNode.push(
              <img
                key={'doc-page-' + i}
                src={props.entry.connections.content.preview[i]}
                className={'artifact-overlay__image' + displayPageClass}
              />
            );
          }
        }
      }
      break;
    case 'movie':
      {
        /* TODO get the appropreate props for start and end */
      }
      paginationNode = null;
      noPreviewClass = '';
      previewNode = (
        <Video
          preview={props.entry.connections.content.preview[0]}
          formats={props.entry.connections.content.formats}
          start={props.start_time}
          end={props.end_time}
          height={500}
          width={885}
        />
      );
      break;
  }

  return (
    <div className="tc__page">
      <header className="artifact__header-wrap">
        <div className="artifact__header row--1200">
          <div className="artifact__details">
            <h1 className="artifact__headline v5__heading-5">{title}</h1>
            <div className="v5__author-copy">
              <TimeDelta datetime={props.entry.created_formatted} />
            </div>
          </div>
          {paginationNode}
          <div className="artifact__actions">
            {downloadNode}
            <div className="show-desktop hide-phone">{closeNode}</div>
          </div>
        </div>
        <div className="artifact__close show-phone hide-desktop">
          {closeNode}
        </div>
      </header>

      <div
        className={`artifact__body ${noPreviewClass}`}
        style={{ marginTop: headerHeight }}
      >
        <div className="artifact__content">{previewNode}</div>
      </div>
    </div>
  );
};

export default ArtifactContainer;
