// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Modal from './../../../Common/Modal';
import Spinner from './../../../Common/Spinner';

const GoalUncompleteModal = (props) => {
  const [isLoading, updateIsLoading] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    updateIsLoading(true);
    props.submit();
  };

  // disable button when form is submitted
  const btnProps = isLoading ? { disabled: true } : {};

  return (
    <Modal close={props.close}>
      <div className="v5__heading-3 workspace__goal-modal-header-row">
        Mark as incomplete?
      </div>
      <div className="v5__body-copy">
        Reopen to continue working on this outcome.
      </div>
      <div className="v5__body-copy">
        If a growth reflection was written for this outcome, it will be lost.
      </div>

      <div className="workspace__form-row">
        <button
          onClick={handleSubmit}
          className="v5__btn v5__btn--red"
          {...btnProps}
        >
          <Spinner loading={isLoading} />
          Reopen
        </button>
      </div>
      <div className="workspace__form-row">
        <button onClick={props.close} className="v5__link v5__link--grey">
          Cancel
        </button>
      </div>
    </Modal>
  );
};

GoalUncompleteModal.propTypes = {
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default GoalUncompleteModal;
