// @ts-nocheck FIXME
import GateKeeperApi from './../../apis/GateKeeperApi';
import RegistrationApi from './../../apis/V5/RegistrationApi';
import RedirectHelper from './../../components/RedirectHelper';
import Utils from './../../components/StaticUtils';
import Store from './../../components/Store';
import StringHelpers from './../../components/StringHelpers';
import WindowHelpers from './../../components/WindowHelpers';

const defaultUi = {
  errors: {},
  loading: false,
  showAccountCreated: false,
  showForgotPassword: false,
  showSetPassword: false,
  persistErrors: false,
};

const store = {
  hasAccount: true, // For toggling to log in from create account and back
  isLoggedIn: false, // For already logged in users and users who've logged in during this session
  accountCreated: false, // This flag is set when an account is created in the current session
  hasRegistered: false, // Flag for completion of event registration
  recaptchaSiteKey: null,
  recaptchaResponse: null,
  activeTab: 0,
  registrationType: 'event',
  event: {},
  cohort: null,
  user: null,
  userRoles: [],
  ui: defaultUi,
  loginAttempts: 0,
};

const setApiError = (message) => {
  store.ui.errors.general = store.ui.errors.general
    ? store.ui.errors.general
    : [];

  store.ui.errors.general.push(message);
};

const setInlineApiError = (message, type) => {
  if (type) {
    store.ui.errors[type] = message;
  } else {
    setApiError(message);
  }
};

store.init = (data) => {
  store.event = data.event || {};
  store.cohort = data.cohort;
  store.user = data.user;
  store.userRoles = data.userRoles || [];
  store.hasAccount = true;
  store.isLoggedIn = data.isLoggedIn;
  store.hasRegistered = data.hasRegistered;
  store.registrationType = data.registrationType;
  store.recaptchaSiteKey = data.recaptchaSiteKey;
  store.ui.showSetPassword = data.showSetPassword;
  store.reservationConfirmed = data.reservationConfirmed;

  if (data.errors) {
    store.ui.persistErrors = true;
    setApiError(data.errors.general);
    store.setIsLoading(true);
  }

  // synchronous event
  return true;
};

store.setApiError = (message) => {
  setApiError(message);
};

store.setErrorClass = (errorInfo) => {
  store.ui.errors[errorInfo.fieldName] =
    fieldErrorMessages[errorInfo.fieldName];
};

store.clearErrors = () => {
  store.ui.errors = {};
};

store.setShowSetPassword = (value) => {
  store.ui.showSetPassword = value;
};

store.setHasAccount = (value) => {
  store.hasAccount = value;
};

store.setIsLoggedIn = (value) => {
  store.isLoggedIn = value;
};

store.hideAccountCreated = () => {
  store.ui.showAccountCreated = false;
};

store.setRecaptchaResponse = (value) => {
  store.recaptchaResponse = value;
};

store.setIsLoading = (value) => {
  store.ui.loading = value;
};

store.setCurrentSessions = (value) => {
  store.event.current_sessions = value;
};

store.setParticipant = (value) => {
  store.participant = value;
};

const fieldErrorMessages = {
  firstName: 'Please enter your first name.',
  lastName: 'Please enter your last name.',
  email: 'Please enter your email.',
  password: 'Please enter a password.',
  confirmPassword: 'Please confirm your password.',
  role: 'Please select a role.',
  recaptchaResponse: 'Beep boop beep. Looks like you are a robot.',
};

store.toggleForgotPassword = () => {
  store.ui.showForgotPassword = !store.ui.showForgotPassword;
};

store.register = (data) => {
  data.recaptchaResponse = store.recaptchaResponse;
  data.requestPath = WindowHelpers.getPathName();

  store.ui.alert = {};
  store.ui.errors = {};

  let errors = {};
  const requiredFields = ['firstName', 'lastName', 'email', 'role'];

  requiredFields.forEach(function (field) {
    if (!StringHelpers.isValid(data[field], field)) {
      errors[field] = fieldErrorMessages[field] || field + ' is required.';
      errors['general'] = [];
      errors['general'].push('Please enter all the required fields.');
    }
  });

  if (!StringHelpers.isEmailValid(data.email)) {
    errors = Object.assign(errors, {
      email: 'Please enter a valid email address',
    });
  }

  const passwordValidationErrors = StringHelpers.getPasswordValidationErrors(
    data.password,
    data.confirmPassword
  );

  errors = Object.assign(errors, passwordValidationErrors);

  if (!Utils.isEmpty(errors)) {
    store.setIsLoading(false);
    store.ui.errors = errors;
    return;
  }

  store.ui.loading = true;
  data.isMobileUser = store.isMobileUser;

  data.fromRegistration = 'services_registration';

  return GateKeeperApi.register(data)
    .then((response) => {
      if (response.redirectUrl) {
        location.href = response.redirectUrl;
        return;
      }
      store.setIsLoading(false);
    })
    .catch((response) => {
      store.setIsLoading(false);
      setApiError(response.error);
    });
};

store.login = (data) => {
  store.ui.alert = {};
  store.ui.errors = {};

  const errors = {};
  const requiredFields = ['email', 'password'];
  requiredFields.forEach(function (field) {
    if (!StringHelpers.isValid(data[field], field)) {
      errors[field] = fieldErrorMessages[field] || field + ' is required.';
      errors['general'] = [];
      errors['general'].push('Please enter all the required fields.');
    }
  });

  if (!Utils.isEmpty(errors)) {
    store.setIsLoading(false);
    store.ui.errors = errors;
    return;
  }

  store.ui.loading = true;
  data.isMobileUser = store.isMobileUser;

  return GateKeeperApi.login(data)
    .then((response) => {
      if (response.redirectUrl) {
        location.href = response.redirectUrl;
        return;
      }
      store.setIsLoading(false);
    })
    .catch((response) => {
      store.setIsLoading(false);
      store.ui.errors[response.type] = response.error;
      store.loginAttempts = response.attempts;
    });
};

store.logout = () => {
  store.setIsLoading(false);
  RegistrationApi.logout()
    .then(() => {})
    .catch((response) => {
      store.ui.errors['general'].push(response.message);
    });
};

store.recaptchaSuccessCallback = (data) => {
  if (
    StringHelpers.isValid(data, 'recaptchaResponse') &&
    store.ui.errors['recaptchaResponse']
  ) {
    delete store.ui.errors['recaptchaResponse'];
  }
};

store.eventReservation = (data) => {
  store.setIsLoading(true);
  return RegistrationApi.eventReservation(data)
    .then((response) => {
      if (response.redirect_url) {
        location.href = response.redirect_url;
        return;
      }
    })
    .catch((response) => {
      store.setIsLoading(false);
      setApiError(response.error);
    });
};

store.registerForEvent = (data) => {
  RegistrationApi.registerForEvent(data)
    .then((response) => {
      if (response.redirect_url) {
        location.href = response.redirect_url;
        return;
      } else if (response.need_pre_assessment && response.assessment_uuid) {
        RedirectHelper.redirectToSelfAssessment(response.assessment_uuid);
        return;
      }
    })
    .catch((response) => {
      store.setIsLoading(false);
      setApiError(response.error);
    });
};

store.registerForCohort = (data) => {
  RegistrationApi.registerForCohort(data)
    .then((response) => {
      store.setIsLoading(false);

      if (response.redirect_url) {
        location.href = response.redirect_url;
        return;
      }
    })
    .catch((response) => {
      store.setIsLoading(false);
      setApiError(response.error);
    });
};

store.resetPassword = (data) => {
  store.ui.alert = {};
  store.ui.errors = {};

  const errors = {};

  if (!StringHelpers.isValid(data['email'], 'email')) {
    errors['email'] = fieldErrorMessages['email'] || 'Please enter your email.';
    errors['general'] = [];
    errors['general'].push('Please enter all the required fields.');
  }

  if (!store.recaptchaResponse) {
    errors['recaptchaResponse'] = fieldErrorMessages['recaptchaResponse'];
  }

  if (Object.keys(errors).length) {
    store.setIsLoading(false);
    store.ui.errors = errors;
    return;
  }

  data['recaptchaResponse'] = store.recaptchaResponse;

  GateKeeperApi.requestNewPassword(data)
    .then(() => {
      store.setIsLoading(false);
      store.setHasAccount(true);
      store.setIsLoggedIn(false);
      store.ui.showForgotPassword = false;
    })
    .catch((response) => {
      store.setIsLoading(false);
      setInlineApiError(response.error, response.type);
    });
};

store.setPassword = (data) => {
  // This is for bulk imported particpants who are setting their password for the first time
  const passwordErrors = StringHelpers.getPasswordValidationErrors(
    data.password,
    data.confirmPassword
  );

  if (Object.keys(passwordErrors).length) {
    store.setIsLoading(false);
    setInlineApiError(passwordErrors.password, 'password');
    setInlineApiError(passwordErrors.confirmPassword, 'confirmPassword');
    return;
  }

  GateKeeperApi.resetPassword(data)
    .then(() => {
      store.setIsLoading(false);
      store.setHasAccount(true);
      store.setIsLoggedIn(true);

      if (data.redirectUrl) {
        location.href = data.redirectUrl;
        return;
      }
    })
    .catch((response) => {
      store.setIsLoading(false);
      setInlineApiError(response.error, response.type);
    });
};

store.clearErrorClass = (key) => {
  store.ui.errors[key] = null;
  if (key === 'password') {
    store.loginAttempts = 0;
  }
};

store.startAboutMe = () => {
  store.atAboutMe = true;
};

export default Store('EventRegistrationStore', store);
