// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import StatusIcon from '@legacy-components/react/V5/Common/StatusIcon';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const ParticipantRow = (props) => {
  const participant = props.participant;

  let photoNode = null;

  if (participant.photo) {
    // Set up user photo instead of the default icon if set
    photoNode = (
      <UserPhoto src={participant.photo} diameter={40} hasBorder={false} />
    );
  } else {
    // Use the participant's name and user ID so that two users with the same name don't use the same color
    // Then convert each letter to a char code, sum and take the modulus so the same user is always the same color
    const participantName =
      participant.first_name +
      ' ' +
      participant.last_name +
      participant.user_id;
    const index = participantName
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc);

    const colors = [
      '#004493',
      '#1A7CD9',
      '##2196F3',
      '#0B8484',
      '#0AABB1',
      '#53E6EB',
      '#53E6EB',
      '#107D54',
      '#33B55F',
      '#2ACAA1',
      '#25E8C8',
      '#FF6B2A',
      '#F4AB2A',
    ];

    const iconStyles = {
      backgroundColor: colors[index % colors.length],
    };

    photoNode = (
      <div style={iconStyles} className="admin-v5_coach-dashboard_default-icon">
        <span>
          {participant.first_name.substr(0, 1)}
          {participant.last_name.substr(0, 1)}
        </span>
      </div>
    );
  }
  const registeredNode = !participant.registration_completion ? (
    <div className="v5__quote-copy">Not registered</div>
  ) : (
    moment(participant.registration_completion).format('MMMM D, YYYY')
  );
  let meetingTimeNode = <div className="v5__quote-copy">Not scheduled</div>;
  let meetingStatusNode = meetingTimeNode;
  let meetingClass;
  const coachNameNode = participant.coach_name || (
    <div className="v5__quote-copy">No coach assigned</div>
  );
  const schoolNameNode = participant.school_name || (
    <div className="v5__quote-copy">No school selected</div>
  );
  if (participant.meeting.status) {
    switch (participant.meeting.status) {
      case 'Scheduled':
        meetingClass = 'tc-v5-meeting';
        break;
      case 'Completed':
        meetingClass = 'tc-v5-meeting-complete';
        break;
      case 'Cancelled':
        meetingClass = 'tc-mtg-cancelled-icon';
        break;
      case 'No Show':
        meetingClass = 'tc-v5-meeting-missed';
        break;
    }
    meetingTimeNode = moment(participant.meeting.meeting_time).format(
      'MMMM D, YYYY'
    );
    meetingStatusNode = (
      <div className="v5__registered-table-educator-count">
        <div className={meetingClass}>
          <span className="path1"></span>
          <span className="path2"> </span>
          <span className="path3"> </span>
        </div>
        &nbsp;&nbsp;&nbsp;{participant.meeting.status}
      </div>
    );
  }

  return (
    <div className="row v5__registered-table-copy v5__registered-table-row">
      <div className="cute-2-desktop v5__registered-table-column">
        <div>{photoNode}</div>
        <div className="v5__registered-table-name">
          <NavigationLink
            className="tc__link"
            url={`/lab/highlights/${participant.user_id}`}
          >
            {participant.first_name} {participant.last_name}
          </NavigationLink>
        </div>
        <StatusIcon status={participant.status} />
      </div>

      <div className="cute-2-desktop v5__registered-table-column">
        <div>{schoolNameNode}</div>
      </div>

      <div className="cute-2-desktop v5__registered-table-column">
        <div>{registeredNode}</div>
      </div>

      <div className="cute-2-desktop v5__registered-table-column">
        <div>{meetingTimeNode}</div>
      </div>

      <div className="cute-2-desktop v5__registered-table-column">
        <div>{meetingStatusNode}</div>
      </div>

      <div className="cute-2-desktop v5__registered-table-column">
        <div>{coachNameNode}</div>
      </div>
    </div>
  );
};

ParticipantRow.propTypes = {
  participant: PropTypes.object.isRequired,
};

export default ParticipantRow;
