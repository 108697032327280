// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import StrategyApi from './../../apis/StrategyApi';
import Analytics from './../../components/Analytics';
import BrowserStore from './../../stores/V2/BrowserStore';
import AbsoluteAlert from './../Alerts/Absolute';
import NavigationLink from './../NavigationLink';
import ChallengeStatus from './Strategy/ChallengeStatus';
import MobileChallengeSubHead from './Strategy/MobileChallengeSubHead';
import SearchBar from './Strategy/SearchBar';
import SearchTab from './Strategy/SearchTab';
import WideStrategyCapsuleV2 from './Strategy/WideStrategyCapsule';

const ChallengeStrategiesV2 = createReactClass({
  displayName: 'ChallengeStrategiesV2',

  getAnalyticsTrackingLabel: function () {
    return 'from_sb_challenge';
  },

  getInitialState: function () {
    BrowserStore.actionDispatchSync('init', this.props);
    return BrowserStore.getState();
  },

  componentDidMount: function () {
    this.token = BrowserStore.subscribe(this.onStoreChange);

    // when the user scrolls to the bottom, automatically grab more search results
    window.addEventListener('scroll', this.handleScrollBottom);
  },

  componentDidUpdate: function () {
    window.addEventListener('scroll', this.handleScrollBottom);
  },

  componentWillUnmount: function () {
    BrowserStore.unsubscribe(this.token);
    window.removeEventListener('scroll', this.handleScrollBottom);
  },

  componentWillReceiveProps: function (nextProps) {
    BrowserStore.actionDispatchSync('init', nextProps);
  },

  onStoreChange: function () {
    // eslint-disable-next-line react/no-is-mounted
    if (this.isMounted()) {
      this.setState(BrowserStore.getState());
    }
  },

  displayMobileFilters: function () {
    BrowserStore.actionDispatchSync('toggleMobileFilters');
  },

  handleFilterCheck: function (filter) {
    // Record the Analytics event:
    Analytics.logEvent({
      category: 'strategy',
      action: 'filter_by_grade',
      label: 'from_sb_challenge',
    });
    // Add the meta strategy id to the filter if available:
    let metaStrategyId;
    if (this.props.selectedMetaStrategy) {
      metaStrategyId = this.props.selectedMetaStrategy.id;
    }
    const data = {
      filter: filter,
      challengeId: this.props.challenge.id,
      metaStrategyId: metaStrategyId,
    };

    BrowserStore.actionDispatchSync('toggleGradeFilter', data);
  },

  handleShowAlert: function (type, message) {
    BrowserStore.actionDispatchSync('setAlert', {
      alert: message,
      alertType: type,
    });
  },

  handleCloseAlert: function () {
    BrowserStore.actionDispatchSync('setAlert', { alert: null, alertType: '' });
  },

  handleScrollBottom: function () {
    // get the current scroll position
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    // load more search results if scrolled to the bottom
    if (
      ReactDOM.findDOMNode(this).offsetHeight -
        currentScrollPosition -
        window.innerHeight <
      2000
    ) {
      window.removeEventListener('scroll', this.handleScrollBottom);

      // only load more results if we are not currently waiting for more results to load
      if (!this.state.loading) {
        this.loadMoreResults();
      }
    }
  },

  loadMoreResults: function () {
    const nextOffset = this.state.offset + 15;

    // only load more if there are additional results to show
    if (nextOffset < this.state.numResults) {
      // Record the Analytics event:
      Analytics.logEvent({
        category: 'teaching_challenge',
        action: 'view_more_strategies',
        label: this.getAnalyticsTrackingLabel(),
      });
      // show Loading... spinner
      BrowserStore.actionDispatchSync('startLoadingResults');

      // Add the meta strategy id to the filter if available:
      let metaStrategyId;
      if (this.props.selectedMetaStrategy) {
        metaStrategyId = this.props.selectedMetaStrategy.id;
      }

      const searchData = {
        challengeId: this.props.challenge.id,
        gradeIds: this.state.gradeIds,
        offset: nextOffset,
        metaStrategyId: metaStrategyId,
      };

      StrategyApi.getStrategiesV2(searchData).then(
        function (response) {
          BrowserStore.actionDispatchSync(
            'addSearchResults',
            response.page_data
          );
        }.bind(this)
      );
    }
  },

  getMetaStrategyNode: function () {
    if (!this.props.selectedMetaStrategy) {
      return null;
    }
    return (
      <div className="row show-desktop hide-phone">
        <div className="cute-12-desktop browser-v2__meta-strategy-container">
          <div className="tc__headline-2 browser-v2__meta-strategy-name">
            {this.props.selectedMetaStrategy.name}
          </div>
          <div className="browser-v2__meta-strategy-description">
            {this.props.selectedMetaStrategy.description}
          </div>
        </div>
      </div>
    );
  },

  getDesktopBreadCrumb: function () {
    const statusClass = this.props.challengeStatus
      ? ' browser-v2__crumb--' + this.props.challengeStatus
      : '';
    const currentCategoryUrlBit = this.getCategoryUrlBit();
    const sgaNode = (
      <div className="browser-v2__crumb">
        <NavigationLink
          className="browser-v2__crumb-link"
          title={this.props.sga.name}
          url={'/coaching/lab/strategy-browser-' + currentCategoryUrlBit}
        >
          {this.props.sga.name}
        </NavigationLink>
      </div>
    );

    let tcNode, metaStrategyNode;
    // If we have a meta strategy, show the challenge as a link and also show the meta strategy name in the breadcrumb:
    if (this.props.selectedMetaStrategy) {
      tcNode = (
        <div className="browser-v2__crumb">
          <NavigationLink
            className="browser-v2__crumb-link"
            title={this.props.challenge.nickname}
            url={
              '/coaching/lab/strategy-browser/teaching-challenge/' +
              this.props.challenge.id +
              '/' +
              this.props.challenge.seo_url
            }
          >
            {this.props.challenge.nickname}
          </NavigationLink>
        </div>
      );
      // Show the meta strategy name:
      metaStrategyNode = (
        <div
          className={'browser-v2__crumb' + statusClass}
          title={this.props.selectedMetaStrategy.name}
        >
          {this.props.selectedMetaStrategy.name}
        </div>
      );
    } else {
      // Show the teaching challenge name. No meta strategy:
      tcNode = (
        <div
          className={'browser-v2__crumb' + statusClass}
          title={this.props.challenge.nickname}
        >
          {this.props.challenge.nickname}
        </div>
      );
    }
    return (
      <div className="browser-v2__wrap--breadcrumbs show-desktop hide-phone">
        <div className="row">
          <div className="cute-12-desktop">
            {sgaNode}
            {tcNode}
            {metaStrategyNode}
          </div>
        </div>
      </div>
    );
  },

  getCategoryUrlBit: function () {
    let categoryUrlBit;

    if (this.props.categories) {
      this.props.categories.forEach(
        function (category) {
          if (category.id == this.props.selectedCategory) {
            categoryUrlBit = category.seo_url;
          }
        }.bind(this)
      );
    }

    return categoryUrlBit;
  },

  getCategoryTabNode: function () {
    let categoryTabNode;
    if (this.props.categories) {
      categoryTabNode = this.props.categories.map(function (category) {
        let activeClass = '';
        if (category.id == this.props.selectedCategory) {
          if (!this.state.searchBarOpened) {
            activeClass = ' active';
          }
        }

        return (
          <NavigationLink
            key={'category-' + category.id}
            className={'browser__tab tc__tab tc__headline-4' + activeClass}
            url={'/coaching/lab/strategy-browser-' + category.seo_url}
          >
            {category.name}
          </NavigationLink>
        );
      }, this);
    }
    return categoryTabNode;
  },

  getChallengeContainerNode: function () {
    // if there is a challenge description show it
    let challengeDescriptionNode = null;
    if (this.props.challenge.description) {
      challengeDescriptionNode = (
        <div className="browser-v2__challenge-description">
          {this.props.challenge.description}
        </div>
      );
    }

    return (
      <div className="browser-v2__wrap--challenge row show-desktop hide-phone">
        <div className="cute-12-desktop">
          <div className="tc__headline-2">{this.props.challenge.nickname}</div>
          {challengeDescriptionNode}

          <ChallengeStatus
            {...this.props}
            className={'browser-v2__challenge-state--btn'}
            onShowAlert={this.handleShowAlert}
            analyticsTrackingLabel={this.getAnalyticsTrackingLabel()}
            showCustomStrategyLink={true}
          />
        </div>
      </div>
    );
  },

  render: function () {
    // Used to pass in the correct analytics label for children:
    const analyticsTrackingLabel = this.getAnalyticsTrackingLabel();

    const metaStrategyNode = this.getMetaStrategyNode();

    // build the alert banner if needed
    let alertNode = null;
    if (this.state.alert) {
      alertNode = (
        <AbsoluteAlert
          message={this.state.alert}
          type={this.state.alertType}
          handleClose={this.handleCloseAlert}
          cssClasses={{ container: 'alert-banner--fixed' }}
        />
      );
    }

    let metaStrategyId = null;
    if (this.props.selectedMetaStrategy && this.props.selectedMetaStrategy.id) {
      metaStrategyId = this.props.selectedMetaStrategy.id;
    }

    // build the list of strategy capsules
    const strategyNodes = this.state.searchResults.map(
      function (strategy) {
        return (
          <WideStrategyCapsuleV2
            capsuleType={'challengeStrategy'}
            key={'strategy' + strategy.id}
            url={
              '/coaching/lab/strategy/' +
              strategy.id +
              '/' +
              strategy.seo_url +
              '?from=sb_tc_strategy_title'
            }
            {...this.props}
            strategy={strategy}
            analyticsTrackingLabel={analyticsTrackingLabel}
          />
        );
      }.bind(this)
    );

    // set up category tabs and url bit to go back to the correct strategy browser
    const currentCategoryUrlBit = this.getCategoryUrlBit();
    const categoryTabNode = this.getCategoryTabNode();

    // set class of last breadcrumb based on the challenge's status
    const statusClass = this.props.challengeStatus
      ? ' browser-v2__crumb--' + this.props.challengeStatus
      : '';

    // handle the opening and closing of the mobile filters
    let visibleMobileFiltersClass = '';
    let visibleMobileCaption = 'Edit';
    if (this.state.mobileFiltersVisible) {
      visibleMobileFiltersClass = ' visible';
      visibleMobileCaption = 'Close';
    }

    // set up the grade list for any selected mobile filters
    let mobileFilters = [];

    // used to set flag for css to not display a trailing comma
    let lastItemClass = '';

    // set up the current grade filter nodes
    if (this.state.filterLowerElementary) {
      lastItemClass =
        this.state.filterUpperElementary ||
        this.state.filterMiddleSchool ||
        this.state.filterHighSchool
          ? ''
          : ' last';

      mobileFilters.push(
        <span
          className={'strategy__filter-grades' + lastItemClass}
          key="filter-grade-le"
        >
          1-3
        </span>
      );
    }
    if (this.state.filterUpperElementary) {
      lastItemClass =
        this.state.filterMiddleSchool || this.state.filterHighSchool
          ? ''
          : ' last';

      mobileFilters.push(
        <span
          className={'strategy__filter-grades' + lastItemClass}
          key="filter-grade-ue"
        >
          4-5
        </span>
      );
    }
    if (this.state.filterMiddleSchool) {
      lastItemClass = this.state.filterHighSchool ? '' : ' last';

      mobileFilters.push(
        <span
          className={'strategy__filter-grades' + lastItemClass}
          key="filter-grade-ms"
        >
          6-7
        </span>
      );
    }
    if (this.state.filterHighSchool) {
      mobileFilters.push(
        <span className="strategy__filter-grades last" key="filter-grade-hs">
          9-12
        </span>
      );
    }

    // if all filters are selected filter should display all
    if (
      this.state.filterLowerElementary &&
      this.state.filterUpperElementary &&
      this.state.filterMiddleSchool &&
      this.state.filterHighSchool
    ) {
      mobileFilters = [
        <span className="strategy__filter-grades last" key="filter-grade-all">
          All
        </span>,
      ];
    }

    // if there are no filters selected display the all caption
    if (mobileFilters.length == 0) {
      mobileFilters.push(
        <span className="strategy__filter-grades last" key="filter-grade-all">
          All
        </span>
      );
    }

    // show the TC header
    const headerNode = <Header {...this.props} page="StrategyBrowser" />;

    // Get the desktop breadcrumb:
    const desktopBreadCrumb = this.getDesktopBreadCrumb();
    // let mobileBreadCrumb = this.getMobileBreadCrumb();
    const mobileBreadCrumb = <MobileChallengeSubHead {...this.props} />;

    // Get the challenge container node:
    const challengeContainerNode = this.getChallengeContainerNode();

    return (
      <div className="challenge-strategies">
        {alertNode}
        <div className="">{headerNode}</div>

        <div className="browser-v2__wrap--tabs show-desktop hide-phone">
          <div className="tc__center-text row">
            <div className="browser__subject-tabs cute-12-desktop">
              {categoryTabNode}
              <SearchTab />
            </div>
          </div>
        </div>

        <SearchBar
          challenge={this.props.challenge.nickname}
          challengeId={this.props.challenge.id}
        />

        {desktopBreadCrumb}

        <div className="row">
          {mobileBreadCrumb}

          <div className="cute-12-desktop">
            {challengeContainerNode}
            {metaStrategyNode}

            <div className="browser-v2__grades cute-12-desktop show-desktop hide-phone">
              <div className="browser-v2__grade-headline tc__headline-6">
                Grades
              </div>
              <div
                className="browser-v2__grade-filter tc__caption-copy"
                onClick={this.handleFilterCheck.bind(
                  this,
                  'filterLowerElementary'
                )}
              >
                <div
                  className={
                    'tc__checkbox' +
                    (this.state.filterLowerElementary == true ? ' checked' : '')
                  }
                >
                  <div className="tc-check"></div>
                </div>
                Lower Elementary ({this.props.gradeBuckets['lower-elementary']})
              </div>
              <div
                className="browser-v2__grade-filter tc__caption-copy"
                onClick={this.handleFilterCheck.bind(
                  this,
                  'filterUpperElementary'
                )}
              >
                <div
                  className={
                    'tc__checkbox' +
                    (this.state.filterUpperElementary == true ? ' checked' : '')
                  }
                >
                  <div className="tc-check"></div>
                </div>
                Upper Elementary ({this.props.gradeBuckets['upper-elementary']})
              </div>
              <div
                className="browser-v2__grade-filter tc__caption-copy"
                onClick={this.handleFilterCheck.bind(
                  this,
                  'filterMiddleSchool'
                )}
              >
                <div
                  className={
                    'tc__checkbox' +
                    (this.state.filterMiddleSchool == true ? ' checked' : '')
                  }
                >
                  <div className="tc-check"></div>
                </div>
                Middle School ({this.props.gradeBuckets['middle-school']})
              </div>
              <div
                className="browser-v2__grade-filter tc__caption-copy"
                onClick={this.handleFilterCheck.bind(this, 'filterHighSchool')}
              >
                <div
                  className={
                    'tc__checkbox' +
                    (this.state.filterHighSchool == true ? ' checked' : '')
                  }
                >
                  <div className="tc-check"></div>
                </div>
                High School ({this.props.gradeBuckets['high-school']})
              </div>
            </div>

            <div className="strategy__grades--mobile row show-phone hide-desktop">
              <div className="strategy__grades-current--mobile cute-6-desktop cute-12-phone">
                <div className="strategy__grade-headline">Grades:</div>
                <span className="strategy__grade-filters">{mobileFilters}</span>
                <a className="tc__link" onClick={this.displayMobileFilters}>
                  {visibleMobileCaption}
                </a>
              </div>
              <div className="browser-v2__challenge-container cute-6-desktop cute-12-phone">
                <ChallengeStatus
                  {...this.props}
                  useShortLabels={true}
                  className="browser-v2__challenge-state--right"
                  analyticsTrackingLabel={analyticsTrackingLabel}
                  showCustomStrategyLink={true}
                />
              </div>
            </div>
            <div
              className={
                'strategy__grade-filter--mobile strategy-v2__grade-filter--mobile show-phone hide-desktop' +
                visibleMobileFiltersClass
              }
            >
              <div
                className="strategy__grade-filter tc__caption-copy"
                onClick={this.handleFilterCheck.bind(
                  this,
                  'filterLowerElementary'
                )}
              >
                <div
                  className={
                    'tc__checkbox' +
                    (this.state.filterLowerElementary == true ? ' checked' : '')
                  }
                >
                  <div className="tc-check"></div>
                </div>
                Lower Elementary ({this.props.gradeBuckets['lower-elementary']})
              </div>
              <div
                className="strategy__grade-filter tc__caption-copy"
                onClick={this.handleFilterCheck.bind(
                  this,
                  'filterUpperElementary'
                )}
              >
                <div
                  className={
                    'tc__checkbox' +
                    (this.state.filterUpperElementary == true ? ' checked' : '')
                  }
                >
                  <div className="tc-check"></div>
                </div>
                Upper Elementary ({this.props.gradeBuckets['upper-elementary']})
              </div>
              <div
                className="strategy__grade-filter tc__caption-copy"
                onClick={this.handleFilterCheck.bind(
                  this,
                  'filterMiddleSchool'
                )}
              >
                <div
                  className={
                    'tc__checkbox' +
                    (this.state.filterMiddleSchool == true ? ' checked' : '')
                  }
                >
                  <div className="tc-check"></div>
                </div>
                Middle School ({this.props.gradeBuckets['middle-school']})
              </div>
              <div
                className="strategy__grade-filter tc__caption-copy"
                onClick={this.handleFilterCheck.bind(this, 'filterHighSchool')}
              >
                <div
                  className={
                    'tc__checkbox' +
                    (this.state.filterHighSchool == true ? ' checked' : '')
                  }
                >
                  <div className="tc-check"></div>
                </div>
                High School ({this.props.gradeBuckets['high-school']})
              </div>
            </div>

            <div className="browser-v2__challenge-strategies-container">
              {strategyNodes}
              {this.state.loading ? (
                <div className="browser-v2__search-results-loading-text">
                  <div className="bigspinner"></div>
                  Loading more results...
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default ChallengeStrategiesV2;
