// @ts-nocheck FIXME
import FavoriteApi from '@legacy-components/apis/V4/FavoriteApi';
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const TrendingCapsule = (props) => {
  const [backgroundImageClass, updateBackground] = useState({});
  const [isLoading, updateLoading] = useState(false);
  const [isFavorite, updateIsFavorite] = useState(!!props.result.favorite);

  const handleAddFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (isLoading) {
      return;
    }

    updateLoading(true);
    FavoriteApi.addFavorite({
      itemType: props.result.type,
      itemId: props.result.id,
      userId: props.pageUser.id,
    }).then(() => {
      props.getNewFavorites();
      updateIsFavorite(!isFavorite);
      updateLoading(false);
    });
  };

  useEffect(() => {
    const image = new Image();
    image.src = props.result.preview;

    image.onload = () => {
      updateBackground({ backgroundImage: `url("${props.result.preview}")` });
    };
  }, []);

  const handleRemoveFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (isLoading) {
      return;
    }
    updateLoading(true);
    FavoriteApi.removeFavorite({
      itemType: props.result.type,
      itemId: props.result.id,
      userId: props.pageUser.id,
    }).then(() => {
      props.getNewFavorites();
      updateIsFavorite(!isFavorite);
      updateLoading(false);
    });
  };

  const resultTitle = props.result.title;
  const resultLink =
    process.env.REACT_APP_TEACHING_URL +
    (props.result.type === 'strategy'
      ? `/strategy/${props.result.id}?from=home_trending_carousel`
      : `/lesson/${props.result.id}`);
  const courseTitle = props.result.course_name ? (
    <div className="home-v5__favorites-course-title v5__heading-6">
      {props.result.course_name}
    </div>
  ) : null;
  let favoriteClass = 'tc-v4-heart-empty';
  let handleFavoritesClick = handleAddFavorite;
  if (isFavorite) {
    favoriteClass = 'tc-v4-heart-filled';
    handleFavoritesClick = handleRemoveFavorite;
  }

  return (
    <div
      className={'home-v5__favorites-card-container'}
      key={`profile-favorites-${props.result.id}`}
    >
      <NavigationLink className="v5__link" url={resultLink}>
        <div className="home-v5__favorites-card" style={backgroundImageClass}>
          <div className="v5__heading-6 home-v5__trending-resources">
            <div
              className={`v5__heading-3 ${favoriteClass}`}
              onClick={(e) => handleFavoritesClick(e)}
            />
            {props.result.favorite_count}
          </div>
          <div className="home-v5__favorite-link-text v5__heading-4">
            {resultTitle}
            {courseTitle}
          </div>
        </div>
      </NavigationLink>
    </div>
  );
};

export default TrendingCapsule;

TrendingCapsule.propTypes = {
  result: PropTypes.object,
  getNewFavorites: PropTypes.func,
  pageUser: PropTypes.object,
};
