// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';
import ArtifactImage from './../../Common/ArtifactImage';

const ClipRow = (props) => {
  const clip = props.clip;
  const handleEditClip = (e) => {
    e.preventDefault();
    props.clearAlert();
    props.editClip(clip);
  };

  const handlePublishClip = (e) => {
    e.preventDefault();

    props.publishClip(clip);
  };
  // Can't just use container class because it breaks if the clip needs to be linked.
  let previewNode = (
    <ArtifactImage
      preview={clip.connections.preview}
      title={clip.connections.name}
      icon={clip.connections.icon}
      imageClass="participant-v5__clip-image"
    />
  );

  if (props.video_download_link) {
    previewNode = (
      <div className="cute-6-desktop cute-6-phone participant-v5__meeting-video-clip-image participant-v5__participant-meeting-clip-piece">
        <div className="participant-v5__meeting-preview">
          <NavigationLink
            target="_blank"
            url={'/coaching/lab/meeting/clip/' + clip.uuid}
          >
            {previewNode}
          </NavigationLink>
        </div>
      </div>
    );
  } else {
    previewNode = (
      <div className="cute-6-desktop cute-6-phone participant-v5__meeting-video-clip-image participant-v5__participant-meeting-clip-piece">
        <div className="participant-v5__meeting-preview">{previewNode}</div>
      </div>
    );
  }

  let publishNode = props.participant.active_story_id && props.canEditData && (
    <div
      className="participant-v5__participant-meeting-clips-publish-link v5__link participant-v5__meeting-view-in-workspace-text participant-v5__meeting-link"
      onClick={handlePublishClip}
    >
      <span className="tc-v5-workspace" />
      &nbsp;Share to workspace
    </div>
  );

  if (clip.entry_id) {
    publishNode = (
      <NavigationLink
        className="v5__link participant-v5__meeting-view-in-workspace-text participant-v5__meeting-link"
        url={`/lab/workspace/${props.participant.user.id}?scrollToEntryId=${clip.entry_id}`}
      >
        View in {props.participant.user.first_name}&apos;s workspace
        <span className="tc-v5-arrow-circle-right participant-v5__workspace-link-icon" />
      </NavigationLink>
    );
  }

  return (
    <div className="row row--1200 participant-v5__participant-meeting-clip">
      {previewNode}
      <div className="cute-6-desktop cute-6-phone participant-v5__participant-meeting-clip-piece">
        <div className="v5__sidenav-category-label participant-v5__participant-meeting-clip-title">
          {clip.name}
          <span
            data-testid="edit_clip_button"
            className="v5__link participant-v5__participant-meeting-participant-time-edit tc-pencil"
            onClick={handleEditClip}
          />
        </div>
        <div className="v5__body-copy participant-v5__participant-meeting-notes-text participant-v5__participant-meeting-clips-text">
          <div className="participant-v5__participant-meeting-clips-description">
            {clip.description}
          </div>
          {publishNode}
        </div>
      </div>
    </div>
  );
};

ClipRow.propTypes = {
  clip: PropTypes.object.isRequired,
};

export default ClipRow;
