/*
 * VideoJS offset Wrapper implemented to resolve issues with requiring the file
 * Date of wrapper creation: 9/5/17
 * Version: 2.0.0-beta.0
 * Github Repo: https://github.com/cladera/videojs-offset/blob/master/src/js/index.js
 */

// Default options for the plugin.
var defaults = {};

var onPlayerReady = function onPlayerReady(player, options) {
  player.on('timeupdate', function () {
    var curr = this.currentTime();
    if (curr < 0) {
      this.currentTime(0);
      this.pause();
    }
    if (this._offsetEnd > 0 && curr > this._offsetEnd - this._offsetStart) {
      this.pause();
      if (!this._restartBeginning) {
        this.currentTime(this._offsetEnd - this._offsetStart);
      } else {
        this.trigger('loadstart');
        this.currentTime(0);
      }
    }
  });
};

var offset = function offset(options) {
  var _this = this;

  options = options || {};
  var Player = this.constructor;

  this._offsetStart = options.start || 0;
  this._offsetEnd = options.end || 0;
  this._restartBeginning = options.restart_beginning || false;

  if (!Player.__super__ || !Player.__super__.__offsetInit) {
    Player.__super__ = {
      __offsetInit: true,
      duration: Player.prototype.duration,
      currentTime: Player.prototype.currentTime,
      bufferedPercent: Player.prototype.bufferedPercent,
      remainingTime: Player.prototype.remainingTime,
    };

    Player.prototype.duration = function () {
      if (this._offsetEnd > 0) {
        return this._offsetEnd - this._offsetStart;
      }
      return (
        Player.__super__.duration.apply(this, arguments) - this._offsetStart
      );
    };

    Player.prototype.currentTime = function (seconds) {
      if (seconds !== undefined) {
        return (
          Player.__super__.currentTime.call(this, seconds + this._offsetStart) -
          this._offsetStart
        );
      }
      return (
        Player.__super__.currentTime.apply(this, arguments) - this._offsetStart
      );
    };

    Player.prototype.remainingTime = function () {
      var curr = this.currentTime();

      if (curr < this._offsetStart) {
        curr = 0;
      }
      return this.duration() - curr;
    };

    Player.prototype.startOffset = function () {
      return this._offsetStart;
    };

    Player.prototype.endOffset = function () {
      if (this._offsetEnd > 0) {
        return this._offsetEnd;
      }
      return this.duration();
    };
  }

  this.ready(function () {
    onPlayerReady(_this, { ...defaults, ...options });
  });
};

export default offset;
