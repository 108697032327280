// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import FormField from '@legacy-components/react/V5/Common/FormField';
import TimePicker from '@legacy-components/react/V5/Common/TimePicker';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import SessionCapsule from './SessionCapsule';

const GroupCapsule = (props) => {
  const lab = useLegacyLabContext();
  const [currTime, setCurrTime] = React.useState(
    props.cancellation_time ? moment(props.cancellation_time_formatted) : null
  );
  const [canceledTime, setCanceledTime] = React.useState(null);
  const [canceledDate, setCanceledDate] = React.useState(
    currTime
      ? currTime.tz(props.timezone.pytz_timezone).format('YYYY-MM-DD')
      : null
  );

  const ref = React.useRef();

  const canChangeGroupStatus =
    Utils.isAdmin.call(lab) || Utils.isServicesManager.call(lab);

  React.useEffect(() => {
    if (props.scrollTo === `group-${props.num}`) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  React.useEffect(() => {
    props.update({
      cancelledTime: getCancelDateTime(),
    });
  }, [canceledDate, canceledTime]);

  React.useEffect(() => {
    setCurrTime(moment(props.cancellation_time_formatted));
  }, [props.cancellation_time]);

  const handleSetCancelTime = (time) => {
    if (!time || time === null) {
      setCanceledTime(null);
      return;
    }

    setCanceledTime(time.format('HH:mm'));
  };
  const getCancelDateTime = () => {
    if (!canceledTime || !canceledDate) {
      props.update({
        cancelledTime: null,
      });
      return;
    }
    const combinedTime = moment(`${canceledDate} ${canceledTime}`);
    return combinedTime;
  };

  const handleSetStatus = (status, statusChanged) => {
    if (props.status === 'Active') {
      props.confirmDownsizeGroup({
        participant_count: props.participant_count,
        group_id: props.id,
      });
    } else {
      props.update({
        status: status,
        statusChanged: statusChanged,
        cancelledTime: getCancelDateTime(),
        confirmed: false, // confirmed is set by the modal when user confirms to change the status of the group
      });
    }
  };

  // set up any error messages
  let cancelledTimeErrorClass = '';
  let cancelledTimeErrorNode;
  if (props.errors && props.errors.cancelTime) {
    cancelledTimeErrorClass = 'error';
    cancelledTimeErrorNode = (
      <ErrorText
        errorContainerClass="v5__error-container--no-padding"
        error={props.errors.cancelTime}
      />
    );
  }
  // highlight the capsule when we create a new group
  const capsuleClass = props.id
    ? ''
    : 'edit-event__session-capsule--border-pulse';

  // if this groups has any registered participants, show the # and disable the delete icon
  let participantCountNode;
  if (props.participant_count && props.participant_count > 0) {
    participantCountNode = (
      <div className="edit-event__attendee-count">
        {props.participant_count} attendee
        {props.participant_count > 1 ? 's' : ''}
      </div>
    );
  }
  // set up the delete icon, and hide/disable it if necessary
  let deleteIconNode;
  if (props.canEditEventDetails) {
    deleteIconNode = (
      <div
        data-testid={`group-delete-icon-${props.num}`}
        onClick={props.delete}
        className="tc-delete edit-event__delete-icon v5__link v5__link--white"
      ></div>
    );
    if (participantCountNode) {
      deleteIconNode = (
        <Tooltip
          tipClass="v5__tooltip--wide"
          tooltip="This group cannot be deleted because its participants have checked in for a session."
        >
          <div
            data-testid={`group-delete-icon-${props.num}`}
            className="tc-delete edit-event__delete-icon v5__link v5__link--white disabled"
          ></div>
        </Tooltip>
      );
    } else if (props.deleteDisabled) {
      deleteIconNode = (
        <div
          data-testid={`group-delete-icon-${props.num}`}
          className="tc-delete edit-event__delete-icon v5__link v5__link--white disabled"
        ></div>
      );
    }
  }

  // set up the group name input read-only if unable to edit /
  let nameInputNode = <div className="">{props.name}</div>;
  if (props.canEditEventDetails && props.status !== 'Downsized') {
    nameInputNode = (
      <FormField
        type="text"
        maxLength="200"
        value={props.name}
        isControlled={true}
        onChange={(e) => props.update({ name: e.target.value })}
      />
    );
  }

  // group status, read-only if not editable
  let statusInputNode = (
    <div
      className={`edit-event__status-${props.status} edit-event__status--readonly`}
    >
      {props.status}
    </div>
  );

  // when user have the auth, and original status is Active, show dropdown
  // only show 'X' when original status is Active and changed to 'Downsized' and not saved yet
  if (
    props.canEditEventDetails &&
    canChangeGroupStatus &&
    !(!props.statusChanged && props.status === 'Downsized')
  ) {
    statusInputNode = (
      <div className={`clickable edit-event__status-${props.status}`}>
        <select
          className="clickable"
          value={props.status}
          onChange={(e) => handleSetStatus(e.target.value, true)}
        >
          <option value="Active">Active</option>
          <option value="Downsized">Downsized</option>
        </select>
        {props.statusChanged &&
          props.confirmed &&
          props.status === 'Downsized' && (
            <div
              className="tc-x clickable"
              onClick={() => handleSetStatus('Active', false)}
            />
          )}
      </div>
    );
  }

  // only show status after a group has been created
  const statusDisplayNode = props.status && (
    <div className="edit-event__group-capsule-status">
      <label className="edit-event__group-capsule-label">Status</label>
      {statusInputNode}
    </div>
  );

  // if we are in Advanced mode, show the group's sessions
  let sessionsNode;
  if (ArrayHelpers.hasElements(props.sessions)) {
    sessionsNode = (
      <React.Fragment>
        {!props.statusChanged &&
          props.sessions.map((session) => {
            return (
              <SessionCapsule
                key={`session-${session.id}-${session.num}`}
                {...session}
                singleSession={props.sessions.length === 1}
                canEditEventDetails={props.canEditEventDetails}
                update={(data) => props.updateSession(session.index, data)}
                delete={() => props.deleteSession(session)}
                deleteDisabled={props.sessions.length === 1}
                allProductCodes={props.allProductCodes}
                timezone={props.timezone}
                greyBackground={true}
                confirmUndeliverSession={(data) =>
                  props.confirmUndeliverSession(data)
                }
                scrollTo={props.scrollTo}
              />
            );
          })}

        {props.canEditEventDetails && props.status === 'Active' && (
          <button
            onClick={() => props.addSession(props.num)}
            className="v5__btn v5__btn--blue-border edit-event__add-btn"
          >
            Add a Session
          </button>
        )}
      </React.Fragment>
    );
  }

  let RequestedChangeNode;
  if (props.status) {
    if (props.status !== 'Active') {
      let changeTimesNode;
      let asteriskNode;
      if (!props.statusChanged) {
        changeTimesNode = (
          <React.Fragment>
            <div className={'flatpickr-container'}>
              {currTime.format('M/DD/YY')}
            </div>
            <div
              className={
                'participant-v5__meeting-time-margin edit-event__cancel-time-display'
              }
            >
              {currTime.format('hh:mm A')}
            </div>
          </React.Fragment>
        );
      } else if (props.canEditEventDetails && props.confirmed) {
        asteriskNode = <span className="v5__error">*</span>;
        changeTimesNode = (
          <React.Fragment>
            <div className={'flatpickr-container'}>
              <Flatpickr
                className={`v5__input ${cancelledTimeErrorClass}`}
                onChange={(selectedDates) =>
                  setCanceledDate(moment(selectedDates[0]).format('YYYY-MM-DD'))
                }
                value={canceledDate}
              />
              {cancelledTimeErrorNode}
            </div>
            <div className={'participant-v5__meeting-time-margin'}>
              <TimePicker
                startEmpty={true}
                className={`${cancelledTimeErrorClass}`}
                onChange={(time) => handleSetCancelTime(time)}
                clearMargin={true}
              />
              {cancelledTimeErrorNode}
            </div>
          </React.Fragment>
        );
      }
      RequestedChangeNode = (
        <div className="edit-event__group-capsule-change">
          <label className="edit-event__group-capsule-label">
            Requested Change{asteriskNode}
          </label>
          <div className="edit-event__cancel-time-container">
            {changeTimesNode}
          </div>
        </div>
      );
    }
  }

  return (
    <div className={`edit-event__group-capsule ${capsuleClass}`} ref={ref}>
      <div className="edit-event__group-capsule-headline-row v5__heading-5">
        <div className="tc-v5-group edit-event__row-start-icon"></div>
        <div className="edit-event__group-capsule-headline-text">
          {props.name}
        </div>
        {participantCountNode}
        {deleteIconNode}
      </div>

      <div className="edit-event__group-capsule-body v5__body-copy">
        <div className="edit-event__group-capsule-container">
          <div className="edit-event__group-capsule-name">
            <label className="edit-event__group-capsule-label">Name</label>
            {nameInputNode}
          </div>
          {statusDisplayNode}
          {RequestedChangeNode}
        </div>
        {sessionsNode}
      </div>
    </div>
  );
};

GroupCapsule.propTypes = {
  id: PropTypes.number,
  num: PropTypes.number,
  name: PropTypes.string,
  sessions: PropTypes.array,
  canEditEventDetails: PropTypes.bool,
  update: PropTypes.func.isRequired,
  delete: PropTypes.func,
  deleteDisabled: PropTypes.bool,
  participant_count: PropTypes.number,
  addSession: PropTypes.func,
  updateSession: PropTypes.func,
  deleteSession: PropTypes.func,
  timezone: PropTypes.object,
  scrollTo: PropTypes.string,
  status: PropTypes.string,
  statusChanged: PropTypes.bool,
  confirmed: PropTypes.bool,
  confirmDownsizeGroup: PropTypes.func.isRequired,
  confirmUndeliverSession: PropTypes.func,
};

export default GroupCapsule;
