// @ts-nocheck FIXME
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import Analytics from './../../../../components/Analytics';

class ShareModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { urlCopied: false };
  }

  componentDidMount() {
    // set up copy to clipboard functionality
    this.clipboard = new Clipboard('.p-portfolio-mgmt__share-copy-btn', {
      text: function (trigger) {
        return window.location.href;
      }.bind(this),
    });
    // if copy is successful, log event and show success message
    this.clipboard.on('success', () => {
      if (this.props.analyticsLabel) {
        Analytics.logEvent({
          category: 'share_modal',
          action: 'copy_link',
          label: this.props.analyticsLabel,
        });
      }

      this.setState({ urlCopied: true });
    });
  }

  componentWillUnmount() {
    // clean up the clipboard
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  render() {
    // set up text that appears after copying the url
    let copiedTextNode = null;
    if (this.state.urlCopied) {
      copiedTextNode = (
        <div className="p-portfolio-mgmt__share-success-text">
          Link Successfully Copied
        </div>
      );
    }

    return (
      <div>
        <div
          className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
          onClick={this.props.close}
        ></div>
        <div className="modal--fixed modal--grey-overlay ppd-v3-curation-modal ppd-v3-curation-modal--short">
          <div
            className="modal__close show-desktop hide-phone"
            onClick={this.props.close}
          >
            <div className="tc-x"></div>
          </div>
          <a
            className="tc__mobile-header show-phone hide-desktop"
            onClick={this.props.close}
          >
            <div className="tc__back cute-2-desktop left">
              <div className="tc__back-arrow tc-arrow"></div>
              <div className="tc__back-caption show-desktop hide-phone">
                Back
              </div>
            </div>
            <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
              Share this Page
            </div>
          </a>
          <div className="p-portfolio-mgmt__share-modal-body">
            <div className="tc__headline-2 p-portfolio-mgmt__share-headline show-desktop hide-phone">
              Share this Page
            </div>

            <div className="tc__body-copy p-portfolio-mgmt__share-input-container">
              <input
                type="text"
                readOnly="readOnly"
                className="tc__input p-portfolio-mgmt__share-input"
                defaultValue={window.location.href}
              />
              <button className="tc__btn p-portfolio-mgmt__share-copy-btn">
                Copy
              </button>
              {copiedTextNode}
            </div>

            <div className="p-portfolio-mgmt__share-done-btn-container">
              <button
                onClick={this.props.close}
                className="bl4__btn bl4__btn--blue"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ShareModal.propTypes = {
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  analyticsLabel: PropTypes.string,
};

export default ShareModal;
