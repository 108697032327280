// @ts-nocheck FIXME
import React from 'react';
import Content from './../../components/Content';
import ScaleCenterImage from './../ScaleCenterImage';

class StrategyArtifact extends React.Component {
  state = {
    viablePreview: false,
    artifact: this.props.artifact,
  };

  componentMounted = false;

  componentWillReceiveProps(nextProps) {
    Content.checkForPreview(this, nextProps.artifact.preview);
    this.setState({ artifact: nextProps.artifact });
  }

  componentDidMount() {
    this.componentMounted = true;
    Content.checkForPreview(this, this.state.artifact.preview);
  }

  componentWillUnmount() {
    this.componentMounted = false;
  }

  render() {
    if (
      this.state.artifact.icon == 'link' &&
      (!this.state.artifact.image_type ||
        this.state.artifact.image_type == 'none')
    ) {
      return (
        <div className="artifact__image-mask artifact__image--none">
          <div className="artifact__preview-icon file-type-icon ext-link"></div>
        </div>
      );
    }

    if (this.state.viablePreview) {
      return (
        <ScaleCenterImage
          id={'artifact-image-' + this.state.artifact.id}
          src={this.state.artifact.preview}
          className="artifact__image"
        />
      );
    }

    return (
      <div className="artifact__image-mask artifact__image--none">
        <div
          className={
            'artifact__preview-icon file-type-icon ext-' +
            this.state.artifact.icon
          }
        ></div>
      </div>
    );
  }
}

export default StrategyArtifact;
