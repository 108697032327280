// @ts-nocheck FIXME
import Store from './../components/Store';

const store = {
  ui: {
    activeDropdown: null,
  },
  salt: null,
};

store.init = (data) => {
  store.ui.alert = {};
  store.ui.activeDropdown = null;

  store.salt = data.searchSalt || Math.random().toString(36).substr(2);

  return false;
};

store.setSalt = (salt) => {
  store.salt = salt;
};

store.toggleDropdown = (field) => {
  if (store.ui.activeDropdown == field) {
    store.ui.activeDropdown = null;
  } else {
    store.ui.activeDropdown = field;
  }
};

store.closeDropdown = () => {
  store.ui.activeDropdown = null;
};

export default Store('StaticSiteStore', store);
