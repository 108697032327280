// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';

const TimeZoneIndicator = (props) => {
  if (props.name) {
    return (
      <div
        className={`admin-v5_coach-dashboard--timezone-indicator ${
          props.className ? props.className : ''
        }`}
      >
        Your calendar is in{' '}
        <NavigationLink className="v5__link" url="/account">
          {props.name}
        </NavigationLink>
      </div>
    );
  }

  return (
    <div
      className={`admin-v5_coach-dashboard--timezone-indicator ${
        props.className ? props.className : ''
      }`}
    >
      Your calendar is in{' '}
      <NavigationLink className="v5__link" url="/account">
        Eastern Time Zone
      </NavigationLink>
    </div>
  );
};

TimeZoneIndicator.propTypes = {
  name: PropTypes.string,
};

export default TimeZoneIndicator;
