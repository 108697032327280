// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import StorageHelper from '@legacy-components/components/StorageHelper';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import EventRow from './EventRow';

const EventsTable = (props) => {
  // I know hooks are suppossed to come first, but this has to happen here
  const initialSortState = StorageHelper.getItem('coachEventsSortState');

  const [sortDirection, setSortDirection] = React.useState(
    (initialSortState && initialSortState.direction) || 'ascending'
  );
  const [sortColumn, setSortColumn] = React.useState(
    (initialSortState && initialSortState.column) || 'date'
  );

  React.useEffect(() => {
    sortEventsBy(sortColumn, true);
  }, []);

  // Sort order indication arrow
  let directionalArrow = (
    <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
  );

  if (sortDirection !== 'ascending') {
    directionalArrow = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  // Handle sorting indication
  let sessionNameSortIndicator;
  let eventSortIndicator;
  let dateSortIndicator;
  let rsvpSortIndicator;

  switch (sortColumn) {
    case 'session':
      sessionNameSortIndicator = directionalArrow;
      break;
    case 'event':
      eventSortIndicator = directionalArrow;
      break;
    case 'date':
      dateSortIndicator = directionalArrow;
      break;
    case 'rsvps':
    case 'attendees':
      rsvpSortIndicator = directionalArrow;
      break;
  }

  const emptyStateText =
    props.tableView === 'upcoming'
      ? 'You have no upcoming events scheduled. What your future brings is up to you!'
      : 'Why dwell in the past? You have no past events yet.';

  let eventsNode = (
    <div className="admin-v5__coach-table--empty-state">{emptyStateText}</div>
  );

  // Put's nulls to back
  const sortEventDates = (a, b) => {
    if (!a['session_date_formatted']) {
      // if no date is set, sort it to the bottom
      return 1;
    }

    let fieldA = 'session_start_time_formatted';
    let timeA = moment(a[fieldA]);

    if (!a[fieldA]) {
      if (!a['session_end_time_formatted']) {
        fieldA = 'session_date_formatted';
        timeA = moment(a[fieldA]).endOf('day');
      } else {
        fieldA = 'session_end_time_formatted';
        timeA = moment(a[fieldA]);
      }
    }

    if (!b['session_date_formatted']) {
      return -1;
    }

    let fieldB = 'session_start_time_formatted';

    let timeB = moment(b[fieldB]);

    if (!b[fieldB]) {
      if (!b['session_end_time_formatted']) {
        fieldB = 'session_date_formatted';
        timeB = moment(b[fieldB]).endOf('day');
      } else {
        fieldB = 'session_end_time_formatted';
        timeB = moment(b[fieldB]);
      }
    }

    return timeB.isBefore(timeA) ? 1 : -1;
  };

  const sortEventsBy = (column, firstSort = false) => {
    // Always default sort new columns ascending
    let newSortDirection = 'ascending';

    if (sortColumn === column && !firstSort) {
      if (sortDirection === 'ascending') {
        newSortDirection = 'descending';
        props.tableData.reverse();
      } else {
        newSortDirection = 'ascending';
        props.tableData.reverse();
      }
    } else {
      // Sort the data
      switch (column) {
        case 'session':
          props.tableData.sort((a, b) => {
            return Utils.getEventSessionName(
              a.display_name || a.name,
              a.session.name,
              a.session.group && a.session.group.name,
              a.session.num_sessions
            )
              .toLowerCase()
              .localeCompare(
                Utils.getEventSessionName(
                  b.display_name || b.name,
                  b.session.name,
                  b.session.group && b.session.group.name,
                  b.session.num_sessions
                ).toLowerCase()
              );
          });
          break;
        case 'event':
          props.tableData.sort((a, b) => {
            return a.display_name
              .toLowerCase()
              .localeCompare(b.display_name.toLowerCase());
          });
          break;
        case 'date':
          props.tableData.sort((a, b) => sortEventDates(a.session, b.session));
          break;
        case 'rsvps':
          props.tableData.sort((a, b) => b.rsvps - a.rsvps);
          break;
        case 'attendees':
          props.tableData.sort(
            (a, b) => b.session.participants - a.session.participants
          );
          break;
      }
    }

    // If this is on page load and the direction is reversed, reverse the data
    if (firstSort && sortDirection === 'descending') {
      newSortDirection = 'descending';
      props.tableData.reverse();
    }

    // Update local storage
    const localSortState = { column: column, direction: newSortDirection };
    StorageHelper.setItem('coachEventsSortState', localSortState);

    // Update local state
    setSortDirection(newSortDirection);
    setSortColumn(column);
  };

  const handleSortChange = (e) => {
    e.preventDefault();

    if (['session', 'date'].indexOf(e.target.value) === -1) {
      e.target.value = 'date';
    }

    sortEventsBy(e.target.value);
  };

  const escapeSpecialChars = (term) => {
    return term.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&').toLowerCase();
  };

  // no participants render nothing
  if (props.tableData && props.tableData.length) {
    // create participants rows
    eventsNode = props.tableData.map((eventSession) => {
      const filterTerm =
        props.filter && new RegExp(escapeSpecialChars(props.filter));

      // this logic effectively filters the list of participants.
      // I feel this is clearer like this than in a .filter()
      if (
        !props.filter ||
        (filterTerm &&
          (filterTerm.test(eventSession.session.name.toLowerCase()) ||
            filterTerm.test(eventSession.display_name.toLowerCase())))
      ) {
        return (
          <EventRow
            {...eventSession}
            key={`session-${eventSession.session.id}`}
            eventHasModifications={!!eventSession.modifications}
            handleCopy={props.handleCopy}
            handleEmailCopy={props.handleEmailCopy}
            userTimeZone={props.userTimeZone}
            tableView={props.tableView}
          />
        );
      }
    });
  }

  return (
    <React.Fragment>
      <div className="admin-v5_coach-dashboard__mobile-sort hide-desktop show-phone">
        <label>Sort:</label>
        <select
          className="tc__select"
          onChange={handleSortChange}
          defaultValue={sortColumn}
        >
          <option value="session">Session Name</option>
          <option value="date">Session Date</option>
        </select>
      </div>
      <div className="admin-v5__coach-table">
        {/* Desktop Header */}
        <div className="admin-v5__coach-table__header show-desktop hide-phone row">
          <div
            className={
              'admin-v5__coach-table__header-col cute-3-desktop m_unclickable'
            }
            onClick={() => {
              sortEventsBy('session');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable m_unclickable' +
                (sessionNameSortIndicator ? ' active' : '')
              }
            >
              Session
            </span>
            {sessionNameSortIndicator}
          </div>

          <div
            className="admin-v5__coach-table__header-col cute-4-desktop m_unclickable"
            onClick={() => {
              sortEventsBy('event');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable m_unclickable' +
                (eventSortIndicator ? ' active' : '')
              }
            >
              Event
            </span>
            {eventSortIndicator}
          </div>

          <div
            className="admin-v5__coach-table__header-col cute-3-desktop m_unclickable"
            onClick={() => {
              sortEventsBy('date');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable m_unclickable' +
                (dateSortIndicator ? ' active' : '')
              }
            >
              Date
            </span>
            {dateSortIndicator}
          </div>

          <div
            className={
              'admin-v5__coach-table__header-col cute-2-desktop m_unclickable'
            }
            onClick={() => {
              sortEventsBy(
                props.tableView === 'upcoming' ? 'rsvps' : 'attendees'
              );
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable m_unclickable' +
                (rsvpSortIndicator ? ' active' : '')
              }
            >
              {props.tableView === 'upcoming' ? 'RSVPs' : 'Attendees'}
            </span>
            {rsvpSortIndicator}
          </div>
        </div>

        {/* Mobile Header */}
        <div className="admin-v5__coach-table__header hide-desktop show-phone row">
          <div
            className={
              'admin-v5__coach-table__header-col cute-5-phone m_unclickable'
            }
            onClick={() => {
              sortEventsBy('session');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable m_unclickable' +
                (sessionNameSortIndicator ? ' active' : '')
              }
            >
              Session Name
            </span>
          </div>

          <div
            className="admin-v5__coach-table__header-col cute-5-phone m_unclickable"
            onClick={() => {
              sortEventsBy('date');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable m_unclickable' +
                (dateSortIndicator ? ' active' : '')
              }
            >
              Session Date
            </span>
          </div>

          <div className="cute-2-phone"></div>
        </div>
        <div className="cute-12-desktop cute-12-phone">{eventsNode}</div>
      </div>
    </React.Fragment>
  );
};

EventsTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  userTimeZone: PropTypes.string.isRequired,
  tableView: PropTypes.string.isRequired,
};

export default EventsTable;
