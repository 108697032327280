// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import WheelhouseOptionSelect from './WheelhouseOptionSelect';

const WheelhouseOption = (props) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  let label = null;
  let wheelhouseOptions = [];
  let categoryKey = null;
  const optionOutputBase = {};
  if (props.subjectOption) {
    categoryKey = 'gradeBandId';
    label = props.subjectOption.label;
    wheelhouseOptions = props.gradeBands;
    optionOutputBase.subjectId = props.subjectOption.id;
  } else if (props.gradeOption) {
    categoryKey = 'subjectId';
    label = props.gradeOption.label;
    props.topLevelSubjects.forEach((subject) => {
      // if All Subjects (ID: 26597), then put at the front of the array
      if (subject.id === 26597) {
        wheelhouseOptions.unshift(subject);
      } else {
        wheelhouseOptions.push(subject);
      }
    });
    optionOutputBase.gradeBandId = props.gradeOption.id;
  }

  let selectNodes = null;
  let selectedOptionsIconNode = null;
  selectNodes = wheelhouseOptions.map((option) => {
    let value = null;
    // set a value if there is one set
    props.userWheelhouse.forEach((wheelhouseItem) => {
      if (wheelhouseItem[categoryKey] === option.id && wheelhouseItem.answer) {
        value = wheelhouseItem.answer;
        selectedOptionsIconNode = (
          <div className="tc-v5-complete-solid account-wheelhouse__selected-icon">
            <div className="path1" />
            <div className="path2" />
          </div>
        );
      }
    });

    return (
      <WheelhouseOptionSelect
        key={`wheelhouse-container-option-select-${option.id}`}
        option={option}
        optionOutputBase={optionOutputBase}
        defaultValue={value}
        expanded={expanded}
        handleUpdateWheelhouse={props.handleUpdateWheelhouse}
      />
    );
  });

  return (
    <div className="account-wheelhouse__item">
      <div
        onClick={toggleExpanded}
        className={`account-wheelhouse__option-header 
                ${
                  expanded ? 'account-wheelhouse__option-header--expanded' : ''
                }`}
      >
        <div className="account-wheelhouse__option-header-left">
          <h4 className="account-wheelhouse__option-label">{label}</h4>
          {selectedOptionsIconNode}
        </div>
        <div className={`tc-v4-circle-arrow ${expanded ? 'expanded' : ''}`} />
      </div>
      {selectNodes}
    </div>
  );
};

export default WheelhouseOption;

WheelhouseOption.propTypes = {
  gradeOption: PropTypes.object,
  subjectOption: PropTypes.object,
  topLevelSubjects: PropTypes.array,
  gradeBands: PropTypes.array,
  userWheelhouse: PropTypes.array,
};
