// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import LandingStage from './LandingStage';
import ResetPasswordForm from './forms/ResetPasswordForm';

const ResetPassword = (props) => {
  return (
    <LandingStage {...props}>
      <ResetPasswordForm {...props} />
    </LandingStage>
  );
};

ResetPassword.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  toggleRememberMe: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  isMobileUser: PropTypes.bool.isRequired,
};

export default ResetPassword;
