// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import React from 'react';

const PartnerLeadersHeader = (props) => {
  const lab = useLegacyLabContext();

  let addPartnerLeadButton;
  if (Utils.isManager.call(lab) || Utils.isAdmin.call(lab)) {
    addPartnerLeadButton = (
      <button
        onClick={() => props.openModal({ type: 'add_existing_pl' })}
        className="v5__btn partner_lead_v5__partner-lead-add-button"
      >
        Add a Partner Leader
      </button>
    );
  }
  return (
    <React.Fragment>
      <div className="row cohorts__headline-row">
        <h3 className="cohorts__headline tc__headline-2">
          Partner Leaders: {props.partnerName}
        </h3>
      </div>

      <div className="row">
        <div className="partner_lead_v5__partner-lead-add-row">
          <div>
            <label>Cohort & Event Status: </label>
            <select
              className="tc__select"
              value={props.status}
              onChange={props.setStatus}
            >
              <option value="All">All</option>
              <option value="Current">Current</option>
              <option value="Past">Past</option>
            </select>
          </div>
          {addPartnerLeadButton}
        </div>
      </div>

      <div className="row">
        <table className="admin-table">
          <tbody>
            <tr>
              <th
                className={'admin-table__header tc__link tc__link--black'}
                onClick={() => props.toggleSortDirection('name')}
              >
                Partner Leader{' '}
                {props.sortColumn === 'name' ? props.sortIndicator : ''}
              </th>
              <th className="admin-table__header">Coaching Cohorts</th>
              <th className="admin-table__header">Events</th>
              <th
                className="admin-table__header tc__link tc__link--black"
                onClick={() => props.toggleSortDirection('surveySent')}
              >
                Survey{' '}
                {props.sortColumn === 'surveySent' ? props.sortIndicator : ''}
              </th>
              <th
                className="admin-table__header tc__link tc__link--black"
                onClick={() => props.toggleSortDirection('survey')}
              >
                Survey Status{' '}
                {props.sortColumn === 'survey' ? props.sortIndicator : ''}
              </th>
              <th className="admin-table__header" />
            </tr>
            {props.children}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default PartnerLeadersHeader;

PartnerLeadersHeader.propTypes = {
  partnerName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  sortIndicator: PropTypes.object.isRequired,
  sortColumn: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
};
