// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import AbridgeText from './../../../Common/AbridgeText';

const CustomStrategyEntry = (props) => {
  let toggle = true;
  if (props.toggle === false) {
    toggle = false;
  }

  const bodyNode = toggle ? (
    <AbridgeText
      maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
      isExpandable={true}
      greyFade={props.greyFade}
    >
      {props.description}
    </AbridgeText>
  ) : (
    props.description
  );
  return (
    <div className="workspace__entry-body">
      <div className="workspace__entry-row v5__body-copy">{bodyNode}</div>
    </div>
  );
};

CustomStrategyEntry.propTypes = {
  description: PropTypes.string.isRequired,
  toggle: PropTypes.bool,
  greyFade: PropTypes.bool,
};

export default CustomStrategyEntry;
