// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import AuthRoleIcon from '@legacy-components/react/V5/Common/AuthRoleIcon';
import CopyToClipboard from '@legacy-components/react/V5/Common/CopyToClipboard';
import StatusIcon from '@legacy-components/react/V5/Common/StatusIcon';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Router } from './../../../../components/Router';
import NavigationLink from './../../../NavigationLink';

const CohortUserRow = (props) => {
  // normally the participant (or partner leader) data is on props.cohortUser,
  // but for district coaches we add their participant data separately
  let participant = props.cohortUser.participant || props.cohortUser;
  const coachingEngagement = props.coachingEngagement;
  if (coachingEngagement) {
    participant = coachingEngagement.participant;
    participant.coaching_engagement_id =
      coachingEngagement.coaching_engagement_id;
  }

  const isCoachingOngoing =
    coachingEngagement &&
    Utils.isCoachingEngagementActive(coachingEngagement.status);

  const handleEditParticipant = (e) => {
    e.preventDefault();
    Router.route('/bl/participant/' + participant.id);
  };

  const handleSendWelcomeEmail = (e) => {
    e.preventDefault();
    props.openConfirmSendEmailModal(participant);
  };

  const handleDeleteUser = (e) => {
    e.preventDefault();
    props.openConfirmDeleteModal(participant);
  };

  const handleCoachSelection = (e) => {
    const coachSelection = {};
    coachSelection[participant.id] = e.target.value;
    props.upsertCoachSelection(coachSelection);
  };

  let lastActiveDate, participantNode;

  let plHomePageLink;
  if (props.type === 'leader') {
    plHomePageLink = props.isDv ? (
      <Tooltip
        tooltip="Admin Dashboard"
        offsetLeft={37}
        delay={1}
        inline={true}
      >
        <NavigationLink
          url={`/lab/leader/${props.cohortUser.id}/home`}
          className="tc-v5-admin-dash admin-table__icon v5__link"
          target="_blank"
        ></NavigationLink>
      </Tooltip>
    ) : (
      <div className="cohort-dashboard__blank-span"></div>
    );
  }

  const deleteButtonNode =
    props.type === 'participant' ? (
      <span
        title={'Remove ' + props.cohortUser.name + ' from cohort'}
        className="tc-delete admin-table__icon"
        onClick={handleDeleteUser}
      ></span>
    ) : null;
  const isPartnerLeadNode =
    props.type === 'leader' && props.cohortUser.is_leader_with_full_access ? (
      <span className="tc-icon_partnership-pl partner_lead_v5__partner-partner-lead partner_lead_v5__cohort-view" />
    ) : null;

  // link to participant edit page if the user is just a participant
  // or if they are a coach who is also a participant
  if (props.type === 'participant' || props.cohortUser.participant) {
    participantNode = (
      <span
        title={'Edit ' + props.cohortUser.name}
        className="tc-pencil admin-table__icon"
        onClick={handleEditParticipant}
      ></span>
    );
  }
  const coachName =
    coachingEngagement && coachingEngagement.coach_name
      ? coachingEngagement.coach_name
      : null;
  let coachNode = (
    <div className="admin-v2__users--coach-details">{coachName}</div>
  );
  if (props.isSelectingCoaches && isCoachingOngoing) {
    let changedNode = null;
    let hasCoach = 'admin-v2__users--selected-coach';

    if (
      Object.keys(props.coachSelections).indexOf('' + participant.id) !== -1
    ) {
      changedNode = (
        <div className="admin-v2__users--coach-changed-container">
          <div className="admin-v2__users--coach-changed"></div>
        </div>
      );
    } else {
      hasCoach = coachingEngagement.coach_user_id
        ? 'admin-v2__users--selected-coach'
        : 'admin-v2__users--select-coach';
    }

    coachNode = (
      <div className="admin-v2__users--coach-details">
        <div className="admin-v2__users--coach-select-container">
          <select
            className={hasCoach}
            defaultValue={coachingEngagement.coach_user_id}
            onChange={handleCoachSelection}
          >
            <option value="">Select Coach</option>
            {props.coachOptions
              .sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
              .map((coach) => {
                return (
                  <option key={'coach_' + coach.id} value={coach.id}>
                    {coach.first_name + ' ' + coach.last_name}
                  </option>
                );
              })}
          </select>
          {changedNode}
        </div>
      </div>
    );
  }

  lastActiveDate =
    props.cohortUser.last_active || props.cohortUser.last_login || null;
  if (lastActiveDate) {
    lastActiveDate = moment(lastActiveDate).format('MM/DD/YYYY');
  }

  let registrationDate =
    coachingEngagement && coachingEngagement.registration_completion
      ? coachingEngagement.registration_completion
      : null;
  if (registrationDate) {
    registrationDate = moment(registrationDate).format('MM/DD/YYYY');
  }

  const registrationDateNode = (
    <div className="cohort-dashboard__registration-date">
      {registrationDate}
    </div>
  );

  let outcomesNode = null;
  if (coachingEngagement && coachingEngagement.outcomes) {
    const outcomes = coachingEngagement.outcomes;
    const outcomesList = outcomes.map((outcome, i) => {
      return <li key={i}> {outcome} </li>;
    });
    outcomesNode = (
      <ul className="cohort-dashboard__learning-outcomes">{outcomesList}</ul>
    );
  }
  const profileDetails = (
    <div className="cohort-dashboard__participant-information">
      <UserPhoto
        src={participant.profile_image_url}
        firstName={participant.first_name}
        lastName={participant.last_name}
        userId={participant.user_id}
        diameter={40}
        hasBorder={false}
        className="participant_user_profile"
      />
      <div className="cohort-dashboard__contact-details">
        <a
          href={`/bl/user/${participant.user_id}`}
          className="v5__link v5__link--black cohort-dashboard__user-name"
          target="_blank"
          rel="noreferrer"
        >
          {participant.name}
          {isPartnerLeadNode}
        </a>
        <div
          id={`user-email-${participant.user_id}`}
          className="cohort-dashboard__user-email"
          copytext={participant.email}
          onClick={props.copyEmailAddress}
        >
          {participant.email}
        </div>
        <CopyToClipboard
          targetSelector={`#user-email-${participant.user_id}`}
        />
      </div>
    </div>
  );

  const roles = [];

  const editButtons =
    (props.canEdit && props.type === 'leader') ||
    (props.canEdit && isCoachingOngoing) ? (
      <div
        className={
          'cohort-dash__cohort-data-cell grid-big__col-4 cohort-dash__edit-cell'
        }
      >
        {participantNode}
        {plHomePageLink}
        <span
          title={'Send Welcome Email to ' + props.cohortUser.name}
          className="tc-mail admin-table__icon"
          onClick={handleSendWelcomeEmail}
        ></span>
        {deleteButtonNode}
      </div>
    ) : null;
  if (props.cohortUser.roles) {
    // add roles
    if (props.cohortUser.roles.indexOf('TC_TEACHER') !== -1) {
      roles.push(<AuthRoleIcon key="role-teacher" role="TC_TEACHER" />);
    }
    if (props.cohortUser.roles.indexOf('TC_PARTNER') !== -1) {
      roles.push(<AuthRoleIcon key="role-partner" role="TC_PARTNER" />);
    }
  }

  let lastActiveDateNode = (
    <div className="cohort-dashboard__last_active_status no-icon">
      {lastActiveDate}
    </div>
  );

  if (coachingEngagement && coachingEngagement.status) {
    lastActiveDateNode = (
      <div className="cohort-dashboard__last_active_status">
        <StatusIcon
          status={coachingEngagement.status}
          displayForActive={true}
          className="cohort-dashboard__participant-last-active-status-icon"
        />
        {lastActiveDate}
      </div>
    );
  }
  let rowClassName =
    props.type === 'leader'
      ? 'grid-big__row event__participant-row leader'
      : 'grid-big__row event__participant-row';
  rowClassName =
    props.type !== 'leader' && !isCoachingOngoing
      ? rowClassName + ' cohort-readonly'
      : rowClassName;
  return (
    <div className={rowClassName}>
      <div
        className={
          'cohort-dash__cohort-data-cell grid-big__col-13--start-v user-information'
        }
      >
        {profileDetails}
      </div>
      <div className={'cohort-dash__cohort-data-cell grid-big__col-4--start-v'}>
        {roles}
      </div>
      <div className={'cohort-dash__cohort-data-cell grid-big__col-6--start-v'}>
        {registrationDateNode}
      </div>
      <div className={'cohort-dash__cohort-data-cell grid-big__col-6--start-v'}>
        {lastActiveDateNode}
      </div>
      <div className={'cohort-dash__cohort-data-cell grid-big__col-9--start-v'}>
        {outcomesNode}
      </div>
      <div className={'cohort-dash__cohort-data-cell grid-big__col-6--start-v'}>
        {coachNode}
      </div>
      {editButtons}
    </div>
  );
};

CohortUserRow.propTypes = {
  cohortUser: PropTypes.object.isRequired,
  openConfirmSendEmailModal: PropTypes.func.isRequired,
  openConfirmDeleteModal: PropTypes.func,
  type: PropTypes.string.isRequired,
  copyEmailAddress: PropTypes.func.isRequired,
};

export default CohortUserRow;
