// @ts-nocheck FIXME
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import Analytics from './../../../../../components/Analytics';
import ScaleCenterImage from './../../../../ScaleCenterImage';

const Details = (props) => {
  const linkUrl = props.url ? props.url : null;

  const handleUrlClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Analytics.logEvent({
      category: 'artifact',
      action: 'external_link',
      label: props.analyticsLabel,
    });

    window.open(linkUrl, '_blank');
  };

  const navigateToLesson = (e) => {
    e.preventDefault();

    // Record the analytics event:
    if (props.analyticsLabel) {
      Analytics.logEvent({
        category: 'artifact',
        action: 'link_to_full_lesson',
        label: props.analyticsLabel,
      });
    }
    e.stopPropagation();
    window.location =
      process.env.REACT_APP_TEACHING_URL +
      '/lesson/' +
      props.lesson.id +
      '/' +
      props.lesson.seo_url;
  };

  // display full screen node if available and an image with a preview
  let fullScreenBtnNode = null;
  if (
    (props.connections.preview_type == 'image' ||
      props.connections.preview_type == 'document') &&
    props.hasViablePreview &&
    props.fullScreenAvailable
  ) {
    fullScreenBtnNode = (
      <div
        className="artifact__fullscreen"
        onClick={props.handleFullScreenClick}
      >
        <div className="artifact__action-icon tc-full-screen"></div>
        <div className="tc__link">Full Screen</div>
      </div>
    );
  }

  let artifactTitleNode = (
    <div>
      <div
        className={
          'artifact-overlay__file-icon file-type-icon ext-' +
          props.connections.icon
        }
      ></div>
      <div className="artifact-overlay__file-title tc__headline-5">
        {props.title}
      </div>
    </div>
  );
  if (props.connections.preview_type == 'link') {
    artifactTitleNode = (
      <div>
        <div
          className={'artifact-overlay__file-icon file-type-icon ext-link'}
        ></div>
        <div className="artifact-overlay__file-title tc__headline-5">
          <a
            className="tc__link"
            href="#"
            onClick={handleUrlClick}
            target="_new"
          >
            {linkUrl}
          </a>
        </div>
      </div>
    );
  }

  // show the artifact type available and specifically requested
  let artifactTypeNode = null;

  if (props.typeVisible && props.type_name) {
    artifactTypeNode = (
      <div className="artifact__type artifact__type--v3">
        <div className="artifact__type-caption ">{props.type_name}</div>
      </div>
    );
  }

  // set up default user photo
  let authorImageNode = (
    <div className="artifact-overlay__author-image-mask artifact-overlay__author-image--none">
      <img
        className="artifact-overlay__author-image"
        src="/legacy-images/default-user.svg"
      />
    </div>
  );

  // if its a tc artifact set up the correct nodes to display
  let addedByNode = null;
  if (props.user) {
    if (props.user.photo) {
      authorImageNode = (
        <ScaleCenterImage
          src={props.user.photo}
          className="artifact-overlay__author-image"
        />
      );
    }
    let addedDateNode = null;
    if (props.lessonfile) {
      addedDateNode = (
        <span>
          &nbsp;on {dateformat(props.lessonfile.last_binary_edit, 'mm/dd/yyyy')}
        </span>
      );
    }
    addedByNode = (
      <div className="artifact-overlay__added-by">
        Added by {props.user.first_name} {props.user.last_name}
        {addedDateNode}
      </div>
    );
  }

  // if the artifact is downloadable display the button and the user is not public or bl
  let downloadBtnNode = null;
  const downloadSpinner = props.artifactDownloading ? (
    <div className="artifact-overlay__download-loader littlespinner"></div>
  ) : null;
  if (props.connections.download_url) {
    downloadBtnNode = (
      <div className="artifact__download" onClick={props.handleDownloadClick}>
        <div className="artifact__action-icon tc-download"></div>
        <div className="tc__link">Download</div>
        {downloadSpinner}
        <div className="tc__error artifact__download_error artifact__download-error--v3">
          {props.downloadError}
        </div>
      </div>
    );
  }

  // if content discovery artifact do not default to author icon
  let overlayDetailsClass = '';
  if (props.hideAuthorPhoto) {
    authorImageNode = null;
    overlayDetailsClass = ' artifact-overlay__details--no-author-photo';
  }

  // if there is a mtp link display the button
  let lessonLinkBtnNode = null;
  if (props.lesson) {
    const url =
      process.env.REACT_APP_TEACHING_URL +
      '/lesson/' +
      props.lesson.id +
      '/' +
      props.lesson.seo_url;
    lessonLinkBtnNode = (
      <a href={url} className="artifact__lessonlink" onClick={navigateToLesson}>
        <div className="artifact__lessonlink-icon artifact__action-icon tc-outlink"></div>
        <div className="artifact__lessonlink-caption tc__link">Full Lesson</div>
        <div className="artifact__lessonlink-caption-mobile tc__link">
          Link To Lesson
        </div>
      </a>
    );
  }

  // setup containder for artifact actions
  const actionsNode = (
    <div className="artifact-overlay__actions hide-phone">
      {lessonLinkBtnNode}
      {downloadBtnNode}
      {fullScreenBtnNode}
    </div>
  );

  const mobileNotesClass = authorImageNode
    ? ' artifact-overlay__details--mobile-has-user'
    : '';

  return (
    <div
      className="artifact-overlay__details-container"
      onClick={props.showDetails}
    >
      {authorImageNode}
      <div
        className={
          'artifact-overlay__details' + overlayDetailsClass + mobileNotesClass
        }
      >
        {artifactTypeNode}
        {artifactTitleNode}
        {addedByNode}
        <div className="artifact-overlay__view-details tc__link">
          View Details
        </div>
        <div className="artifact-overlay__view-lesson">{lessonLinkBtnNode}</div>
        {actionsNode}
      </div>
      <div className="artifact-overlay__notes tc__body-copy artifact-overlay__notes--mt">
        {props.notes}
      </div>
      <div className="artifact-overlay__notes--mobile tc__body-copy">
        {props.notes}
      </div>
      <div className="artifact-overlay__details-actions--mobile">
        {lessonLinkBtnNode}
      </div>
    </div>
  );
};

Details.propTypes = {
  showDetails: PropTypes.func.isRequired,
  hasViablePreview: PropTypes.bool.isRequired,
  fullScreenAvailable: PropTypes.bool.isRequired,
  handleDownloadClick: PropTypes.func,
  handleFullScreenClick: PropTypes.func,
  artifactDownloading: PropTypes.bool,
  hideAuthorPhoto: PropTypes.bool,
  downloadError: PropTypes.string,
  typeVisible: PropTypes.bool,
  lessonfile: PropTypes.object,
};

export default Details;
