// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ParticipantRow from './Common/ParticipantRow';

const Registration = (props) => {
  const [sortedColumn, setSortedColumn] = React.useState(props.sortCol);
  const [sortDirection, setSortDirection] = React.useState('asc');

  // Recursive function to get a nested value from an object based on a dot-separated string key
  const getValue = (baseObject, keys) => {
    if (keys.length) {
      return getValue(baseObject[keys.pop()], keys);
    }
    return baseObject;
  };

  // Wrapper function to sort on null values
  const sortWithNull = (func, key) => {
    // Allow nested values
    const accessors = key.split('.').reverse();

    return (a, b) => {
      if (getValue(a, [...accessors])) {
        if (getValue(b, [...accessors])) {
          return func(a, b);
        }
        return -1;
      }
      return 1;
    };
  };

  // Add a comment

  const meetingStatusMap = {
    Completed: 0,
    Scheduled: 1,
    Cancelled: 2,
    'Coach Cancel2ed': 3,
    'No Show': 4,
    null: 5,
  };

  const sortFunctions = {
    name: (a, b) => a.last_name.localeCompare(b.last_name),
    school: sortWithNull(
      (a, b) => a.school_name.localeCompare(b.school_name),
      'school_name'
    ),
    registered: sortWithNull(
      (a, b) =>
        moment(a.registration_completion) - moment(b.registration_completion),
      'registration_completion'
    ),
    meeting: sortWithNull(
      (a, b) => moment(a.meeting.meeting_time) - moment(b.meeting.meeting_time),
      'meeting.meeting_time'
    ),
    'meeting-status': sortWithNull(
      (a, b) =>
        meetingStatusMap[b.meeting.status] - meetingStatusMap[a.meeting.status],
      'meeting.status'
    ), // Do I need to sort these in a different order?
    coach: sortWithNull(
      (a, b) => a.coach_name.localeCompare(b.coach_name),
      'coach_name'
    ),
  };

  // Sort order indication arrow
  let sortIndicator = null;
  const participants = [...props.participants];

  if (sortDirection === 'asc') {
    participants.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (sortDirection === 'desc') {
    participants.sort(sortFunctions[sortedColumn]);
    participants.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortOrder[sortDirection]);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };

  let participantTableNode = null;
  if (props.participants && props.participants.length > 0) {
    participantTableNode = (
      <React.Fragment>
        <div className="admin-v5__coach-table__header row v5__registered-table-header-copy">
          <div
            className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
            onClick={() => {
              toggleSortDirection('name');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption clickable left ' +
                (sortedColumn === 'name' ? 'tc__link--black active' : '')
              }
            >
              Name
            </span>
            {sortedColumn === 'name' ? sortIndicator : null}
          </div>
          <div
            className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
            onClick={() => {
              toggleSortDirection('school');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption clickable left ' +
                (sortedColumn === 'school' ? 'tc__link--black active' : '')
              }
            >
              School
            </span>
            {sortedColumn === 'school' ? sortIndicator : null}
          </div>
          <div
            className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
            onClick={() => {
              toggleSortDirection('registered');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption clickable left ' +
                (sortedColumn === 'registered' ? 'tc__link--black active' : '')
              }
            >
              Registered
            </span>
            {sortedColumn === 'registered' ? sortIndicator : null}
          </div>
          <div
            className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
            onClick={() => {
              toggleSortDirection('meeting');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption clickable left ' +
                (sortedColumn === 'meeting' ? 'tc__link--black active' : '')
              }
            >
              First Meeting
            </span>
            {sortedColumn === 'meeting' ? sortIndicator : null}
          </div>
          <div
            className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
            onClick={() => {
              toggleSortDirection('meeting-status');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption clickable left ' +
                (sortedColumn === 'meeting-status'
                  ? 'tc__link--black active'
                  : '')
              }
            >
              Meeting Status
            </span>
            {sortedColumn === 'meeting-status' ? sortIndicator : null}
          </div>
          <div
            className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
            onClick={() => {
              toggleSortDirection('coach');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption clickable left ' +
                (sortedColumn === 'coach' ? 'tc__link--black active' : '')
              }
            >
              Coach
            </span>
            {sortedColumn === 'coach' ? sortIndicator : null}
          </div>
        </div>
        {participants.map((participant) => {
          return (
            <ParticipantRow key={participant.id} participant={participant} />
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <div className="cute-12-desktop">
      <div className="v5__registered-table">
        {props.tableHeadingRow}
        {participantTableNode}
      </div>
    </div>
  );
};

Registration.propTypes = {
  participants: PropTypes.array.isRequired,
  tableHeadingRow: PropTypes.object.isRequired,
  sortCol: PropTypes.string.isRequired,
};

export default Registration;
