// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import SingleSelect from './../SingleSelect/SingleSelect';
import Spinner from './../Spinner';

const Form = (props) => {
  const handleClearError = () => {
    props.setFeatureFieldError({
      feature: props.feature,
      field: props.field,
      error: null,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const destinationId = props.participantOutcomes.selectedOption
      ? props.participantOutcomes.selectedOption.id
      : null;
    if (!destinationId) {
      props.setFeatureFieldError({
        feature: props.feature,
        field: props.field,
        error: 'Please select a destination',
      });
      return false;
    }
    props.submit({
      destinationId: destinationId,
      id: props.id,
      containerName: props.containerName,
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    props.cancel(e);
  };

  let errorNode;
  let errorClass = '';
  if (props.field in props.errors) {
    errorNode = (
      <div className="tc__error--relative ">
        <div>{props.errors[props.field]}</div>
      </div>
    );
    errorClass = ' error';
  }

  const buttonDisabledClass = props.loading ? ' disabled' : '';
  const actionsNode = (
    <div className={props.cssClassNamePrefix + '__delete-container'}>
      <div
        className={props.cssClassNamePrefix + '__modal-btns tc__center-text'}
      >
        <button
          className={
            'tc__btn tc__btn--v3 tc__btn--flat-blue ' +
            props.cssClassNamePrefix +
            '__modal-btn' +
            buttonDisabledClass
          }
          onClick={(e) => handleSubmit(e)}
        >
          <Spinner loading={props.loading} />
          Move
        </button>
        <button className="tc__btn tc__btn--flat-grey" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div key={props.cssClassNamePrefix + '__delete-container'}>
      <div
        className={
          props.cssClassNamePrefix +
          '-edit-form artifact-move__form ' +
          errorClass
        }
      >
        <SingleSelect
          {...props.participantOutcomes}
          field={props.field}
          placeholder="Choose a destination"
          updateSingleSelect={props.selectSingleSelectElement}
          getVisibleOptions={props.getSingleSelectElement}
          feature={props.feature}
          handleFocus={(e) => handleClearError(e)}
          minCharCountForResults={0}
          onChangeDelay={700}
          analyticsLoggedByStore={true}
        />
        {actionsNode}
      </div>
      <div className="clearfix"></div>
      {errorNode}
    </div>
  );
};

Form.proptypes = {
  participantOutcomes: PropTypes.object.isRequired,
  cssClassNamePrefix: PropTypes.string.isRequired,
  selectSingleSelectElement: PropTypes.func.isRequired,
  getSingleSelectElement: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  feature: PropTypes.string.isRequired,
  setFeatureFieldError: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  loading: PropTypes.object.isRequired,
  containerName: PropTypes.string.isRequired,
};

Form.defaultProps = {
  errors: {},
  loading: false,
};

export default Form;
