// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const FilterCrumbs = (props) => {
  const filterNodes = [];
  let i = 0;

  for (const filter in props.searchFilters) {
    if (props.searchFilters[filter].label) {
      i++;
    }

    // make singular filter labels plural
    let filterLabel = props.searchFilters[filter].label;
    switch (props.searchFilters[filter].label) {
      case 'strategy':
        filterLabel = 'strategies';
        break;
      case 'lesson':
        filterLabel = 'lessons';
        break;
      case 'tech tool':
        filterLabel = 'Tech Tools';
        break;
    }

    const filterNode = (
      <div
        className="bl4-filter__crumb"
        key={'active-filter' + props.searchFilters[filter].id + '-' + filter}
        data-testid="filter-crumb"
        onClick={(e) => {
          e.preventDefault();
          props.updateFilters({
            filterId: props.searchFilters[filter].filter_id,
            filterCategory: props.searchFilters[filter].category,
            filterField: props.searchFilters[filter].field,
            action: 'remove',
            from: 'crumb',
          });
        }}
      >
        <div className="bl4-filter__crumb-label">{filterLabel}</div>
        <div className="bl4-filter__crumb-remove">
          <div className="tc-x"></div>
        </div>
      </div>
    );
    filterNodes.push(filterNode);
  }

  if (i < 1) {
    return null;
  }
  return (
    <div className="bl4__filter-crumbs">
      <div className="bl4__filter-crumbs-headline">Filters</div>
      {filterNodes}
    </div>
  );
};

export default FilterCrumbs;

FilterCrumbs.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  searchFilters: PropTypes.array.isRequired,
};
