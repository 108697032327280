// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

const ArtifactUpload = (props) => {
  let inputFiles = [];
  const dropzone = React.useRef();
  const filesInput = React.useRef();

  const submitFiles = () => {
    props.submitFiles({ inputFiles: inputFiles });
  };

  const handleSelectFiles = (e) => {
    // Prevent propagation of the click event
    e.stopPropagation();
  };

  const onDropHandler = (e) => {
    e.preventDefault();

    props.onDragLeave();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    inputFiles = files;

    props.submitFiles({ inputFiles: inputFiles });
  };

  const handleArtifactsChange = (e) => {
    e.preventDefault();
    inputFiles = e.target.files;

    submitFiles();
  };

  // control if uploader is blurred or not
  const blurClass = props.blur ? ' artifact__dropzone--blur' : '';
  const dragOverClass = props.dragOver ? ' artifact__dropzone--dragover' : '';

  // button for opening file system
  const fileSelectNode = (
    <div className="artifact__upload-target" key="artifact-upload">
      <div key="upload" onClick={handleSelectFiles}>
        <form>
          <input
            className="artifact__upload-field"
            ref={filesInput}
            type="file"
            onChange={handleArtifactsChange}
            multiple={props.multiple}
          />
        </form>
      </div>
    </div>
  );

  const errorNode = (
    <div className="v5__error artifact__upload-error">{props.error}</div>
  );

  // Mobile devices should see the camera option when selecting files
  const mobileUploadNode = (
    <div className="hide-desktop show-phone tc__center-text">
      <button
        href="#upload-dialog"
        className="artifact__upload artifact__upload--mobile bl4__btn bl4__btn--blue"
      >
        Add
        <div className="hide-desktop show-phone" key="artifact-upload-mobile">
          <div key="upload" onClick={handleSelectFiles}>
            <form>
              <input
                className="artifact__upload-field"
                data-testid="file-artifact-upload"
                ref={filesInput}
                type="file"
                onChange={handleArtifactsChange}
                multiple={props.multiple}
                accept="*"
              />
            </form>
          </div>
        </div>
      </button>
    </div>
  );

  return (
    <React.Fragment>
      <div className="v5__heading-5 workspace__entry-header-row">
        <div className="tc-v4-page workspace__entry-header-icon"></div>
        <div className="workspace__entry-header-text">Uploading File</div>
      </div>

      <div className="workspace__entry-body tc__center-text">
        <div className="artifact__wrapper hide-phone show-desktop tc__center-text">
          <div
            ref={dropzone}
            className={
              'artifact__dropzone artifact__dropzone--tml-v2' +
              dragOverClass +
              blurClass
            }
            onClick={props.handleSelectFiles}
            onDragLeave={props.onDragLeave}
            onDragExit={props.onDragLeave}
            onDragOver={props.onDragOver}
            onDragEnter={props.onDragOver}
            onDrop={onDropHandler}
          >
            <div className="tc-v4-upload-lg artifact__dropzone-icon"></div>
            <div className="hide-phone show-desktop artifact__dropzone-headline">
              Drag and Drop
            </div>
            <div className="hide-phone show-desktop artifact__prefer tc__body-copy">
              Your files here or{' '}
              <span className="artifact__dropzone-browse">Choose a file</span>
            </div>
            <div className="hide-phone show-desktop artifact__max-size tc__caption-copy">
              max file size: 1024MB
            </div>
            <div>{fileSelectNode}</div>
          </div>
        </div>

        {mobileUploadNode}
        {errorNode}

        <button className="v5__link" onClick={() => props.close()}>
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

ArtifactUpload.propTypes = {
  submitFiles: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  error: PropTypes.string,
  dragOver: PropTypes.bool,
  blur: PropTypes.bool,
  multiple: PropTypes.bool,
};

ArtifactUpload.defaultProps = {
  multiple: false,
};

export default ArtifactUpload;
