// @ts-nocheck FIXME
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const InfiniteScroll = (props) => {
  useEffect(() => {
    // when the user scrolls to the bottom, see if we need to call props.scrollCallback
    window.addEventListener('scroll', handleScrollDown);

    return () => {
      window.removeEventListener('scroll', handleScrollDown);
    };
  });

  // When the user scrolls down, we should call props.scrollCallback
  const handleScrollDown = () => {
    // account for the height of the footer if the page has one
    const footer = document.querySelector('footer');
    const scrollThreshold = footer ? 100 + footer.offsetHeight : 100;
    // check if we are at the bottom of the main page content
    if (WindowHelpers.hasScrolledToBottom(scrollThreshold)) {
      window.removeEventListener('scroll', handleScrollDown);
      // Call the scroll callback if we are not loading
      if (!props.loading && props.canScroll) {
        props.scrollCallback();
      }
    }
  };

  // Add a spinner if we are loading more data
  let spinner = null;
  if (props.loading) {
    const positionClass = props.positionLeft ? 'infinite-scroll--left' : '';
    spinner = (
      <div className={`infinite-scroll ${positionClass}`}>
        <div className="bigspinner"></div>
        <div className="infinite-scroll__loading-text">
          {props.loadingText || 'Loading...'}
        </div>
      </div>
    );
  }

  return spinner;
};

InfiniteScroll.propTypes = {
  scrollCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  canScroll: PropTypes.bool,
  positionLeft: PropTypes.bool,
  loadingText: PropTypes.string,
};

export default InfiniteScroll;
