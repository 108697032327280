// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import ZenScroll from 'zenscroll';
import Analytics from './../../../components/Analytics';
import WindowHelpers from './../../../components/WindowHelpers';

/*
uses the standard capsule styles to create a capsule with the given maxHeight
that is expandable/collapsable via footer link
*/
class ExpandableCapsule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      expanded: false,
      abridgeContent: false,
      maxHeight: 'auto',
      numVisibleFooterNodes: this.props.stickyFooterNodes
        ? this.props.stickyFooterNodes.length
        : 0,
      recalculateMaxHeight: true,
    };

    this.recalculateMaxHeight = this.recalculateMaxHeight.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.handleCapsuleClick = this.handleCapsuleClick.bind(this);
  }

  componentDidMount() {
    this.recalculateMaxHeight();
    if (this.props.updateSectionExpandToggleCount) {
      // update the section toggle count so that elements that rely on page height know that it chagned
      this.props.updateSectionExpandToggleCount();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // while the capsule contents are > maxHeight,
    // we want to first try reducing the # of visible sticky footer nodes (until one is left)
    // before abridging the main body
    if (
      this.state.recalculateMaxHeight ||
      (this.props.contentKey && prevProps.contentKey != this.props.contentKey)
    ) {
      this.setState(
        {
          isMobile: WindowHelpers.isMobileDevice(),
          abridgeContent: false,
          maxHeight: 'auto',
          recalculateMaxHeight: false,
        },
        () => {
          this.recalculateMaxHeight();
        }
      );
    }
    // update the section toggle count so that elements that rely on page height know that it chagned
    if (
      this.props.updateSectionExpandToggleCount &&
      (prevState.expanded != this.state.expanded ||
        prevState.abridgeContent != this.state.abridgeContent)
    ) {
      this.props.updateSectionExpandToggleCount();
    }
  }

  recalculateMaxHeight() {
    // if we have a sticky footer node, deduct its height from the maxHeight
    // to determine the height available to the children
    const maxChildrenHeight = this.footerElem
      ? this.props.maxHeight - this.footerElem.offsetHeight
      : this.props.maxHeight;

    // if the height of the main body is too tall, we need to abridge something
    if (maxChildrenHeight < this.thisElem.offsetHeight) {
      // if we can reduce the # of nodes in the sticky footer, try that first
      if (
        this.props.stickyFooterNodes &&
        this.state.numVisibleFooterNodes > 1
      ) {
        this.setState({
          isMobile: WindowHelpers.isMobileDevice(),
          expanded: false,
          abridgeContent: true,
          numVisibleFooterNodes: this.state.numVisibleFooterNodes - 1,
        });
      }
      // otherwise, just abridge the main body
      else {
        this.setState({
          isMobile: WindowHelpers.isMobileDevice(),
          expanded: false,
          abridgeContent: true,
          maxHeight: maxChildrenHeight,
          recalculateMaxHeight: false,
        });
      }
    }
    // otherwise, we do not need to abridge the main body children,
    // nor keep hiding elements of the sticky footer
    else {
      this.setState({
        isMobile: WindowHelpers.isMobileDevice(),
        abridgeContent: false,
        maxHeight: 'auto',
        recalculateMaxHeight: false,
      });
    }
  }
  handleCapsuleClick() {
    if (!this.props.expandFromAnywhere || this.state.expanded) {
      return;
    }
    this.toggleExpand();
  }
  toggleExpand() {
    // if we are closing a capsule on mobile, scroll to the collapsed capsule footer
    if (this.state.expanded && this.state.isMobile) {
      window.setTimeout(() => {
        if (this.capsuleFooterElem) {
          // after a slight delay, try to scroll such that the EXPAND footer
          // is in a reasonable position on the screen
          ZenScroll.setup(400, Math.min(300, this.props.maxHeight / 2));
          ZenScroll.to(this.capsuleFooterElem);
        }
      }, 70);
    }
    if (this.props.analytics) {
      Analytics.logEvent({
        category: this.props.analytics.category,
        action: this.state.expanded ? 'collapse' : 'expand',
        label: this.props.analytics.label,
      });
    }
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    // set up any additional attributes for the capsule div
    const attrs = {};
    if (this.props.id) {
      attrs.id = this.props.id;
    }

    // add a class to abridge the main body as necessary
    let abridgeClass = '';
    let contentStyle = {};
    // set up nodes to actually show in the sticky footer above the capsule footer
    let stickyFooterNodes = this.props.stickyFooterNodes;
    const abridgeFooter =
      stickyFooterNodes &&
      this.state.numVisibleFooterNodes < stickyFooterNodes.length;
    let abridgeFooterClass = '';
    let capsuleFooterNode = null;

    // if either the main body or the sticky footer need to be abridged,
    // set up the capsule footer and abridge content
    let capsuleClass = '';
    if (this.state.abridgeContent || abridgeFooter) {
      capsuleClass = this.props.className
        ? this.props.className + '--has-footer '
        : '';
      if (this.state.expanded) {
        capsuleFooterNode = (
          <div
            ref={(el) => (this.capsuleFooterElem = el)}
            className="bl4__capsule-expand-footer tc__link"
            onClick={this.toggleExpand}
          >
            <span className="tc-v4-circle-arrow up"></span> COLLAPSE
          </div>
        );
      } else {
        abridgeClass = this.state.abridgeContent
          ? ' abridge-text fade-whole'
          : '';
        contentStyle = { maxHeight: this.state.maxHeight };
        capsuleFooterNode = (
          <div
            ref={(el) => (this.capsuleFooterElem = el)}
            className="bl4__capsule-expand-footer tc__link"
            onClick={this.toggleExpand}
          >
            <span className="tc-v4-circle-arrow"></span> EXPAND
          </div>
        );
        // if there are sticky footer nodes, truncate the list as necessary
        if (this.props.stickyFooterNodes) {
          stickyFooterNodes = this.props.stickyFooterNodes.slice(
            0,
            this.state.numVisibleFooterNodes
          );
          // if the footer was truncated, add the fade out over it
          if (
            this.state.numVisibleFooterNodes <
            this.props.stickyFooterNodes.length
          ) {
            abridgeFooterClass = ' abridge-text fade-whole';
          }
        }
      }
    }

    // only include the sticky footer if there are elements to show
    let stickyFooterNode = stickyFooterNodes ? (
      <div
        ref={(footerElem) => {
          this.footerElem = footerElem;
        }}
        className={'bl4__capsule-sticky-footer' + abridgeFooterClass}
      >
        {stickyFooterNodes}
      </div>
    ) : null;
    // if there is a different mobile version of the sticky footer,
    // just show that as-is on mobile
    if (this.props.mobileStickyFooterNode && this.state.isMobile) {
      stickyFooterNode = (
        <div
          ref={(footerElem) => {
            this.footerElem = footerElem;
          }}
          className="bl4__capsule-sticky-footer"
        >
          {this.props.mobileStickyFooterNode}
        </div>
      );
    }

    return (
      <div
        className={'bl4__capsule ' + capsuleClass + this.props.className}
        {...attrs}
        onClick={this.handleCapsuleClick}
      >
        <div
          ref={(thisElem) => {
            this.thisElem = thisElem;
          }}
          className={abridgeClass}
          style={contentStyle}
        >
          {this.props.children}
        </div>
        {stickyFooterNode}
        {capsuleFooterNode}
      </div>
    );
  }
}

ExpandableCapsule.propTypes = {
  maxHeight: PropTypes.number.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  // the sticky footer appears right above any capsule footer
  // and gets abridged separately from the rest of the capsule -
  // we first try to reduce the # of sticky footer nodes shown (until one is left)
  // before trying to abridge the main body,
  // then the sticky footer's height is deducted from the maxHeight when abridging the main body
  stickyFooterNodes: PropTypes.array,
  // use this to override the above list on mobile -
  // for simplicity, I do not do the above calculations for mobile and just display it as-is
  mobileStickyFooterNode: PropTypes.node,
  contentKey: PropTypes.any,
  updateSectionExpandToggleCount: PropTypes.func,
};

ExpandableCapsule.defaultProps = {
  className: '',
};

export default ExpandableCapsule;
