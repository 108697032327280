// @ts-nocheck FIXME
import React from 'react';
import Utils from './../components/StaticUtils';
import Cover from './Portfolio/Cover';
import ArtifactV1 from './Portfolio/v1/Artifact';
import ArtifactsHeadlineV1 from './Portfolio/v1/ArtifactsHeadline';
import BadgesV1 from './Portfolio/v1/Badges';
import ChallengeV1 from './Portfolio/v1/Challenge';
import FeedbackV1 from './Portfolio/v1/Feedback';
import IntroductionV1 from './Portfolio/v1/Introduction';
import MeasurementV1 from './Portfolio/v1/Measurement';
import PageBreakV1 from './Portfolio/v1/PageBreak';
import ReflectionV1 from './Portfolio/v1/Reflection';
import StatsV1 from './Portfolio/v1/Stats';
import StrategyV1 from './Portfolio/v1/Strategy';
import ArtifactV2 from './Portfolio/v2/Artifact';
import ArtifactsHeadlineV2 from './Portfolio/v2/ArtifactsHeadline';
import ChallengeV2 from './Portfolio/v2/Challenge';
import FeedbackV2 from './Portfolio/v2/Feedback';
import GrowthMapV2 from './Portfolio/v2/GrowthMap';
import MeasurementV2 from './Portfolio/v2/Measurement';
import PageBreakV2 from './Portfolio/v2/PageBreak';
import StrategyV2 from './Portfolio/v2/Strategy';

// used to display the correct component
const components = {
  v1: {
    stats: StatsV1,
    badges: BadgesV1,
    challenge: ChallengeV1,
    loop: StrategyV1,
    feedback: FeedbackV1,
    measurement: MeasurementV1,
    artifact: ArtifactV1,
    reflection: ReflectionV1,
    artifactsheadline: ArtifactsHeadlineV1,
    pagebreak: PageBreakV1,
  },
  v2: {
    challenge: ChallengeV2,
    loop: StrategyV2,
    feedback: FeedbackV2,
    measurement: MeasurementV2,
    artifact: ArtifactV2,
    artifactsheadline: ArtifactsHeadlineV2,
    pagebreak: PageBreakV2,
    growthmap: GrowthMapV2,
  },
};

class PortfolioPrint extends React.Component {
  componentDidMount() {
    // If we are in print mode, print the portfolio:
    if (!this.props.inPreviewMode) {
      window.print();
      Utils.addClass(document.body, 'fluid');
    }
  }

  componentWillUnmount() {
    if (!this.props.inPreviewMode) {
      window.print();
      Utils.removeClass(document.body, 'fluid');
    }
  }

  render() {
    // insert the Intro entry after the Stats entry if we don't have a Badges entry
    let introSpliceLocation = 2;

    // build all the portfolio entries
    const entries = this.props.entries.map(
      function (entry) {
        const Component = components['v' + entry.version][entry.type];
        // if there is a Badges entry, the Intro will go after that instead
        if (entry.type == 'badges') {
          introSpliceLocation = 4;
        }
        return (
          <Component
            key={'entry' + entry.id}
            {...entry.data}
            portfolio={this.props.portfolio}
          />
        );
      }.bind(this)
    );

    // we need to insert a page break after the Stats entry, for backwards-compatibility
    entries.splice(1, 0, <PageBreakV1 key="statsPageBreak" />);

    // we need to insert the Introduction component after the Badges entry
    // (or the Stats entry if there are no badges), if the teacher wrote one
    if (
      this.props.portfolio.vision_statement ||
      this.props.portfolio.teacher_introduction
    ) {
      entries.splice(
        introSpliceLocation,
        0,
        <IntroductionV1 key="intro" {...this.props} />,
        <PageBreakV1 key="introPageBreak" />
      );
    }

    // add a class to adjust the margin of the cover page details
    const portfolioModeClass = this.props.inPreviewMode
      ? ' print-portfolio--preview-mode'
      : ' print-portfolio--print-mode';

    return (
      <div className={'print-portfolio' + portfolioModeClass}>
        <Cover {...this.props} />
        {entries}
        <ReflectionV1 {...this.props} />
      </div>
    );
  }
}

export default PortfolioPrint;
