// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import { Router } from '@legacy-components/components/Router';
import StringHelpers from '@legacy-components/components/StringHelpers';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import NavigationLink from '@legacy-components/react/NavigationLink';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import AbridgeText from '@legacy-components/react/V5/Common/AbridgeText';
import ArtifactImage from '@legacy-components/react/V5/Common/ArtifactImage';
import AutoSave from '@legacy-components/react/V5/Common/Autosave';
import Breadcrumb from '@legacy-components/react/V5/Common/Breadcrumb';
import CKEditor from '@legacy-components/react/V5/Common/CKEditor';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import TimePicker from '@legacy-components/react/V5/Common/TimePicker';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import ZenScroll from 'zenscroll';
import ClipEditModal from './modules/ClipEditModal';
import ClipPublishModal from './modules/ClipPublishModal';
import ClipRow from './modules/ClipRow';
import ConfirmDisconnectZoom from './modules/ConfirmDisconnectZoom';
import MeetingDeleteModal from './modules/MeetingDeleteModal';

const ParticipantMeetingDetails = (props) => {
  const lab = useLegacyLabContext();
  // setup to set default values on hooks
  const meeting = props.meeting;
  const timezone = props.user.time_zone.pytz_timezone;

  const meetingTimeMoment = useRef(
    moment(meeting.meeting_time_formatted).tz(timezone)
  );
  const internalNotesRef = useRef(null);
  const meetingNotesRef = useRef(null);

  const [cancelTimeDefault, setCancelTimeDefault] = useState(
    moment(meeting.when_cancelled_formatted).tz(timezone)
  );
  const [meetingDateDisplay, setMeetingDateDisplay] = useState(
    meetingTimeMoment.current.format(
      `MMMM D, YYYY, ${Utils.hourRounder(meetingTimeMoment.current)} A z`
    )
  );
  const [meetingTimeFormatted, setMeetingTime] = useState(
    meetingTimeMoment.current.format('HH:mm')
  );
  const [meetingDateFormatted, setMeetingDate] = useState(
    meetingTimeMoment.current.format('YYYY-MM-DD')
  );
  const [meetingLength, setMeetingLength] = useState(
    meeting.meeting_length || 30
  );
  const [meetingStatus, setMeetingStatus] = useState(meeting.status || 1);
  const [videoLink, setVideoLink] = useState('');
  const [isDeleting, setDeleteMode] = useState(false);
  const [videoLinkError, setVideoLinkError] = useState('');
  const [canceledTime, setCanceledTime] = useState(
    cancelTimeDefault.format('HH:mm')
  );
  const [canceledDate, setCanceledDate] = useState(
    cancelTimeDefault.format('YYYY-MM-DD')
  );
  // Default to unchecked if this meeting is already a no show or cancelled
  const [sendRescheduleEmail, setSendRescheduleEmail] = useState(
    !(parseInt(meetingStatus) === 3 || parseInt(meetingStatus) === 4)
  );
  const [notesChanging, setNotesChanging] = useState(false);
  const [meetingTimeError, setMeetingTimeError] = useState('');
  const [cancelTimeError, setCancelTimeError] = useState('');

  React.useEffect(() => {
    if (props.isEditingMeetingNotes) {
      ZenScroll.setup(600, 10);
      ZenScroll.to(meetingNotesRef.current);
    }
    if (props.isEditingInternalNotes) {
      ZenScroll.setup(600, 10);
      ZenScroll.to(internalNotesRef.current);
    }
  }, []);

  const canEditData =
    Utils.isAdmin.call(lab, props.user) ||
    Utils.isManager.call(lab, props.user) ||
    Utils.isServicesManager.call(lab, props.user) ||
    (Utils.isContractCoach.call(lab, props.user) &&
      props.pageUser.isParticipantOfCoach);

  const participant = props.participant;

  const handleEditMeetingNotesFunction = props.isEditingMeetingNotes
    ? () => {}
    : props.toggleEditMeetingNotes;
  const handleEditInternalNotesFunction = props.isEditingInternalNotes
    ? () => {}
    : props.toggleEditInternalNotes;
  const handleEditMeetingVideoLink = props.isEditingVideoLink
    ? () => {}
    : props.toggleEditVideoLink;

  const handleSetMeetingTimeChange = (time) => {
    if (!time || time === null) {
      setMeetingTime(null);
      return;
    }

    // _i is part of moment object which contains  entered timestamp
    // splitting it on the ':' to get the hour field
    const timeFields = time._i.split(':');
    if (timeFields[0] === '00') {
      setMeetingTime(null);
      return;
    }

    setMeetingTimeError('');
    setMeetingTime(time.format('HH:mm'));
  };

  const handleSetCancelTimeChange = (time) => {
    if (!time || time === null) {
      setCanceledTime(null);
      return;
    }

    // _i is part of moment object which contains  entered timestamp
    // splitting it on the ':' to get the hour field
    const timeFields = time._i.split(':');
    if (timeFields[0] === '00') {
      setCanceledTime(null);
      return;
    }

    setCancelTimeError('');
    setCanceledTime(time.format('HH:mm'));
  };

  // set whether this is the default timezone
  const editOrAddTZ = props.user.time_zone.is_default
    ? 'Add your time zone'
    : 'Change your time zone';

  const meetingTimeZoneLabel = (
    <span className="participant-v5__meeting-timezone-padding">
      in {props.user.time_zone.name}.{' '}
      <NavigationLink
        url="/account"
        className="tc__link participant-v5__meeting-time-zone"
      >
        {editOrAddTZ}
      </NavigationLink>
    </span>
  );

  let canEditMeetingNotes = 'tc-pencil';
  let canEditInternalNotes = 'tc-pencil';

  // if the meeting is synced with Zoom, do not let the user add their own video link
  let canAddMeetingVideo = meeting.zoom_meeting_id ? '' : 'tc-pencil';

  const exitEditMode = (newMeeting) => {
    props.toggleEditMode();
    meetingTimeMoment.current = moment(newMeeting.meeting_time_formatted).tz(
      timezone
    );
    setMeetingStatus(newMeeting.status);
    setMeetingLength(newMeeting.meeting_length);
    setMeetingTime(meetingTimeMoment.current.format('HH:mm'));
    setMeetingDate(meetingTimeMoment.current.format('YYYY-MM-DD'));
    setMeetingDateDisplay(
      meetingTimeMoment.current.format('MMMM D, YYYY, h:mm A z')
    );
  };

  const exitEditVideoLink = () => {
    props.toggleEditVideoLink();
  };

  const handleAddClip = (e) => {
    e.preventDefault();
    props.openMeetingClipModal();
  };

  const handleToggleEmail = (e) => {
    setSendRescheduleEmail(e.target.checked);
  };

  const handleStatusChange = (e) => {
    const statusInt = parseInt(e.target.value);
    setMeetingStatus(statusInt);
    // If the meeting is cancelled set the cancelled time to now
    if (statusInt === 3 || statusInt === 5) {
      const now = moment().tz(timezone);
      setCanceledDate(now.format('YYYY-MM-DD'));
      setCanceledTime(now.format('HH:mm'));
      setCancelTimeDefault(now);
    }
  };

  // disable buttons when submitting
  const btnProps = props.isLoading ? { disabled: true } : null;

  const handleMeetingSave = (successCallback) => {
    // don't do anything if the form is submitting
    if (props.isLoading) {
      return;
    }

    if (!meetingTimeFormatted || meetingTimeFormatted === null) {
      setMeetingTimeError('The meeting time is required.');
      return;
    }
    if (!canceledTime || canceledTime === null) {
      setCancelTimeError('The meeting cancel time is required.');
      return;
    }

    const videoPatterns = [
      /^https:\/\/betterlesson.zoom.us\/recording\/play\//,
      /^https:\/\/betterlesson.zoom.us\/recording\/download\//,
      /^https:\/\/betterlesson.zoom.us\/rec\/play\//,
      /^https:\/\/betterlesson.zoom.us\/rec\/download\//,
    ];

    const videoLinks = videoLink.split('\n');

    for (const link of videoLinks) {
      if (
        link &&
        !videoPatterns.find((videoPattern) => videoPattern.test(link.trim()))
      ) {
        setVideoLinkError(
          'Whoops! Your link must begin with https://betterlesson.zoom.us/rec/play or https://betterlesson.zoom.us/rec/download in order to be displayed in the Lab.'
        );
        return;
      }
    }

    let cancelledDateTime;
    const statusInt = parseInt(meetingStatus);
    if (statusInt === 3 || statusInt === 5) {
      cancelledDateTime = `${canceledDate}T${canceledTime}`;
    }

    props.saveParticipantMeeting({
      meeting: {
        meetingId: meeting.id,
        participantId: participant.id,
        meetingTime: `${meetingDateFormatted}T${meetingTimeFormatted}`,
        meetingTimeZone: timezone,
        meetingLength: meetingLength,
        status: meetingStatus ? '' + meetingStatus : '',
        storyId: participant.active_story_id,
        preMeetingNotes: meeting.pre_meeting_notes || '',
        generalNotes: meeting.general_notes || '',
        teacherNextSteps: meeting.teacher_next_steps || '',
        coachNextSteps: meeting.coach_next_steps || '',
        coachingFocus: meeting.coachingFocus || '',
        ratingDelight: meeting.ratingDelight || '',
        ratingRecommendToPST: meeting.ratingRecommendToPST || '',
        videoLink: videoLink,
        gcalEventId: meeting.gcal_event_id,
        when_cancelled: cancelledDateTime,
        sendRescheduleEmail: sendRescheduleEmail,
      },
      successCallback: successCallback,
    });

    // We don't want to accidentally resend emails if edits have to be made
    if (parseInt(meetingStatus) === 3 || parseInt(meetingStatus) === 4) {
      setSendRescheduleEmail(false);
    }
  };

  const saveOrAdd = meeting.id ? 'Save' : 'Add';
  const meetingTimeErrorNode = meetingTimeError ? (
    <div className="v5__body-copy participant-v5__submit-error">
      {meetingTimeError}
    </div>
  ) : null;

  const cancelTimeErrorNode = cancelTimeError ? (
    <div className="v5__body-copy participant-v5__submit-error">
      {cancelTimeError}
    </div>
  ) : null;

  const handleDeleteMeeting = (e) => {
    e.preventDefault();
    props.deleteParticipantMeeting();
  };

  let modalNode = null;
  if (props.editMeetingClipModal.opened) {
    modalNode = (
      <ClipEditModal
        {...props.editMeetingClipModal}
        close={props.closeMeetingClipModal}
        meeting={props.meeting}
        saveClip={props.saveParticipantMeetingClip}
        deleteClip={props.deleteParticipantMeetingClip}
      />
    );
  }

  if (isDeleting) {
    modalNode = (
      <MeetingDeleteModal
        delete={handleDeleteMeeting}
        close={() => setDeleteMode(false)}
        meetingDate={meetingTimeMoment.current.format('MMMM, D, YYYY')}
      />
    );
  }

  if (props.publishMeetingClipModal.opened) {
    modalNode = (
      <ClipPublishModal
        {...props.publishMeetingClipModal}
        close={props.closePublishClipModal}
        meeting={props.meeting}
        first_name={participant.user.first_name}
        setTypeFieldDisabled={props.setClipTypeFieldDisabled}
        setType={props.setClipType}
        saveClip={props.saveParticipantMeetingClip}
        goals={props.goals}
        storyId={participant.active_story_id}
        publishClip={props.publishParticipantMeetingClip}
      />
    );
  }

  if (props.confirmDisconnectZoomModal.opened) {
    modalNode = (
      <ConfirmDisconnectZoom
        disconnectZoom={props.disconnectZoomForMeeting}
        close={props.closeConfirmDisconnectZoomModal}
      />
    );
  }

  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const syncedWithGCal = !props.isEditing && meeting.gcal_event_id && (
    <div className="v5__comment-copy participant-v5__meeting-details-text participant-v5__meeting-details-synced-text">
      <span className="tc-v5-sync" />
      <span className="participant-v5__synced-with-gcal-text">
        Synced with Google Calendar
      </span>
    </div>
  );

  const syncedWithZoom = !props.isEditing && meeting.zoom_meeting_id && (
    <div className="v5__comment-copy participant-v5__meeting-details-text participant-v5__meeting-details-synced-text">
      <span className="tc-v5-sync" />
      <span className="participant-v5__synced-with-gcal-text">
        Synced with Zoom{' '}
        <button
          type="button"
          className="v5__link v5__link--white underline bold no-color-hover"
          onClick={props.openConfirmDisconnectZoomModal}
        >
          Disconnect this meeting
        </button>
      </span>
    </div>
  );

  const nameNode = (
    <div className="v5__author-copy">
      {participant.user.first_name} {participant.user.last_name}
    </div>
  );

  // use the meeting's unique Zoom link if it has one, or default to the coach's general Zoom link
  const zoomLink = meeting.zoom_join_link
    ? meeting.zoom_join_link
    : participant.zoom_link;
  let zoomLinkNode = (
    <div className="v5__body-copy">
      <NavigationLink
        className="v5__link participant-v5__meeting-link participant-v5__zoom-meeting-link"
        target={'_blank'}
        url={zoomLink}
      >
        {zoomLink}
      </NavigationLink>
    </div>
  );

  const institutionString =
    participant.institution && `at ${participant.institution.name}`;

  const workspaceLink = meeting.story_entry_id &&
    meeting.status === 2 &&
    meeting.meeting_notes && (
      <NavigationLink
        url={`/lab/workspace/${participant.user.id}?scrollToEntryId=${meeting.story_entry_id}`}
        className="tc__link"
      >
        <div className="v5__link participant-v5__meeting-view-in-workspace-text participant-v5__participant-meeting-workspace-link">
          View in {participant.user.first_name}&rsquo;s workspace
          <span className="tc-v5-arrow-circle-right participant-v5__workspace-link-icon" />
        </div>
      </NavigationLink>
    );

  const roleNode = (
    <div className="v5__author-title-copy">
      {participant.occupation} {institutionString}
    </div>
  );

  let statusNode = (
    <React.Fragment>
      :
      <span className="participant-v5__meeting-status-value">
        {meeting.statusOptions[meeting.status]}
      </span>
    </React.Fragment>
  );

  // Remove the pencil if another edit panel is active
  const canActivateEditMode =
    !props.isEditingMeetingNotes &&
    !props.isEditingInternalNotes &&
    !props.isEditingVideoLink;
  let saveButtons;
  let meetingLengthNode = `${meeting.meeting_length} minutes`;
  let headlineNode;
  const editTimeNode = canActivateEditMode && canEditData && (
    <span
      className={
        'participant-v5__participant-meeting-participant-time-edit v5__link participant-v5__notes-pencil'
      }
      data-testid="meeting-time-edit-button"
      onClick={props.toggleEditMode}
    >
      <div className="tc-pencil" />
      <div className="tc-pencil-icon-filled" />
    </span>
  );

  let descriptionNode = (
    <React.Fragment>
      <div className="v5__heading-1 participant-v5__participant-meeting-time-headline-text">
        {meetingDateDisplay}
      </div>
      {editTimeNode}
    </React.Fragment>
  );

  let participantInfoClass = '';
  let setCancelTimeNode;
  const sendRescheduleEmailNode = (
    <React.Fragment>
      <div className="participant-v5__meeting-details-no-show-checkbox">
        <input
          type="checkbox"
          id="send_email"
          defaultChecked={sendRescheduleEmail}
          className="tc__checkbox"
          onChange={handleToggleEmail}
        />
      </div>
      <div>Send an email to reschedule meeting</div>
    </React.Fragment>
  );
  let sendNoShowRescheduleEmailCheckbox;
  if (props.isEditing) {
    participantInfoClass = 'participant-v5__meeting-profile-hide';
    canEditMeetingNotes = '';
    canEditInternalNotes = '';
    canAddMeetingVideo = '';
    zoomLinkNode = '';
    // When the meeting is canceled the status can't be changed
    const disableStatusSelect =
      meetingStatus === 3 || meetingStatus === 5 ? 'disabled' : '';
    const editOrAdd = meeting.id ? 'Edit' : 'Add';
    const deleteMeetingNode = meeting.id && (
      <div
        className="tc-delete workspace__delete-icon"
        onClick={setDeleteMode}
      />
    );
    headlineNode = (
      <div className="v5__heading-1 participant-v5__participant-meeting-time-headline-text editing">
        {editOrAdd} Meeting with {participant.user.first_name}{' '}
        {participant.user.last_name}
      </div>
    );
    statusNode = (
      <span className="participant-v5__meeting-status-value">
        <select
          value={meetingStatus}
          className={`tc__select ${disableStatusSelect}`}
          data-testid="meeting-status-select"
          onChange={handleStatusChange}
        >
          {Object.values(meeting.statusOptions).map((option, i) => {
            return (
              <option value={i + 1} key={`status-${i}`}>
                {option}
              </option>
            );
          })}{' '}
          {'>'}
        </select>
      </span>
    );
    const dateOptions = {
      altInput: true,
      altFormat: 'm/d/Y',
      dateFormat: 'Y-m-d',
    };

    const setDateOptions = {
      ...dateOptions,
      minDate: participant.cohort && participant.cohort.term_start_formatted,
      maxDate: participant.cohort && participant.cohort.term_end_formatted,
    };

    // Need to parseint since the meeting status becomes a string after being changed
    if (parseInt(meetingStatus) === 3 || parseInt(meetingStatus) === 5) {
      // Check if the meeting is being set to cancelled(3), if yes ask the coach if they want to email the participant
      const sendCancelledRescheduleEmailCheckbox = parseInt(meetingStatus) ===
        3 && (
        <div className="v5__comment-copy participant-v5__meeting-details-cancelled-checkbox-row">
          {sendRescheduleEmailNode}
        </div>
      );
      setCancelTimeNode = (
        <div className="participant-v5__meeting-cancel-row">
          <div className="participant-v5__meeting-cancel-container">
            <div className="participant-v5__meeting-cancel-topline v5__comment-copy">
              Cancellation date and time
            </div>
            <div className="v5__comment-copy participant-v5__meeting-details-cancelled-checkbox-row">
              <span className="tc-v5-alert participant-v5__resend-alert">
                {' '}
              </span>
              <span>
                Once a meeting is set to <span className="bold">Cancelled</span>
                , you cannot change its status.
              </span>
            </div>
            <div className="participant-v5__meeting-cancel-time">
              <div className={'flatpickr-container'}>
                <Flatpickr
                  className="v5__input"
                  onChange={(selectedDates) =>
                    setCanceledDate(
                      moment(selectedDates[0]).format('YYYY-MM-DD')
                    )
                  }
                  value={canceledDate}
                  options={dateOptions}
                />
              </div>
              <div className={'participant-v5__meeting-time-margin'}>
                <TimePicker
                  onChange={(time) => handleSetCancelTimeChange(time)}
                  value={cancelTimeDefault}
                  clearMargin={true}
                />
              </div>
            </div>
            {sendCancelledRescheduleEmailCheckbox}
          </div>
        </div>
      );
    }

    // Check if the meeting is being set to cancelled(3) or no show(4), if yes ask the coach if they want to email the participant
    if (parseInt(meetingStatus) === 4) {
      sendNoShowRescheduleEmailCheckbox = (
        <div className="v5__comment-copy participant-v5__meeting-details-no-show-checkbox-row">
          {sendRescheduleEmailNode}
        </div>
      );
    }

    descriptionNode = (
      <div className="participant-v5__meeting-time-ordering">
        <div className={'participant-v5__meeting-times flatpickr-container'}>
          <Flatpickr
            className="v5__input"
            onChange={(selectedDates) =>
              setMeetingDate(moment(selectedDates[0]).format('YYYY-MM-DD'))
            }
            value={meetingDateFormatted}
            options={setDateOptions}
          />
        </div>
        <div
          className={
            'participant-v5__meeting-times participant-v5__meeting-time-margin'
          }
        >
          <TimePicker
            onChange={(time) => handleSetMeetingTimeChange(time)}
            value={meetingTimeMoment.current}
            clearMargin={true}
          />
        </div>
        {meetingTimeZoneLabel}
      </div>
    );

    let cancelButtonAction = () => {
      exitEditMode(meeting);
    };
    // If this is a new meeting we want to navigate back to the previous page on cancel
    if (!meeting.id) {
      let url = '';
      if (props.cameFromWorkspace) {
        url = `/lab/workspace/${participant.user.id}`;
      } else if (props.cameFromCoachHome) {
        url = `/lab/coach/${participant.coach_user_id}/coaching`;
      } else {
        url = `/lab/participant/${participant.id}/details`;
      }
      cancelButtonAction = () => {
        Router.route(url);
      };
    }

    saveButtons = (
      <div className="participant-v5__participant-meeting-details-input-buttons">
        <button
          className="v5__btn--blue-border v5__btn participant-v5__meeting-video-url-buttons-cancel participant-v5__meeting-cancel"
          {...btnProps}
          onClick={cancelButtonAction}
        >
          Cancel
        </button>
        <button
          className="v5__btn"
          {...btnProps}
          onClick={() => {
            handleMeetingSave(exitEditMode);
          }}
        >
          <Spinner loading={props.isLoading} />
          {saveOrAdd}
        </button>
        {deleteMeetingNode}
      </div>
    );
    meetingLengthNode = (
      <React.Fragment>
        Meeting length (in minutes)&nbsp;
        <input
          type="number"
          value={meetingLength}
          className="tc__input"
          min="1"
          max="1440"
          onChange={(e) => setMeetingLength(e.target.value)}
        />
      </React.Fragment>
    );
  }

  let meetingNotesNode = (
    <div
      className="participant-v5__empty-notes participant-v5__participant-meeting-notes-text-box v5__comment-copy"
      onClick={handleEditMeetingNotesFunction}
    >
      Provide feedback and next steps for your educator.{'\n'}
      Notes shared here will automatically publish to{' '}
      {participant.user.first_name}&apos;s workspace if the meeting is marked as
      complete.
    </div>
  );

  // This handles all of the different ways for the meeting notes to be saved
  // If it's auto saved we don't want the editing window to close, otherwise we do
  // Additionally we don't want to update the notes if it was a cancel
  const saveMeetingNotes = (meetingNotes, closeOnComplete, successCallback) => {
    props.saveParticipantMeetingNotes(
      {
        storyEntryId: meeting.story_entry_id,
        meetingNotes: meetingNotes,
        meetingId: meeting.id,
        participantId: participant.id,
        storyId: participant.active_story_id,
        noteEntryId: meeting.note_entry_id,
      },
      closeOnComplete,
      successCallback
    );
  };

  if (props.isEditingMeetingNotes) {
    canEditMeetingNotes = '';
    canEditInternalNotes = '';
    canAddMeetingVideo = '';
    const editButtons = (
      <div className="participant-v5__meeting-video-url-buttons">
        <AutoSave
          isLoading={props.isLoading}
          changed={notesChanging}
          save={(successCallback) =>
            saveMeetingNotes(
              CKEDITOR.instances[
                'participant-v5__participant-meeting-notes-input-meeting-notes-0'
              ].getData(),
              false,
              successCallback
            )
          }
          resetChanged={() => setNotesChanging(false)}
        />
        <button
          className="v5__btn"
          {...btnProps}
          onClick={() =>
            saveMeetingNotes(
              CKEDITOR.instances[
                'participant-v5__participant-meeting-notes-input-meeting-notes-0'
              ].getData(),
              true
            )
          }
        >
          <Spinner loading={props.isLoading} />
          Save
        </button>
        <button
          className={
            'v5__btn--blue-border v5__btn participant-v5__meeting-video-url-buttons-cancel'
          }
          {...btnProps}
          onClick={() => saveMeetingNotes(meeting.meeting_notes, true)}
        >
          Cancel
        </button>
      </div>
    );

    meetingNotesNode = (
      <React.Fragment>
        <CKEditor
          id="meeting-notes"
          increment={0}
          innerText={meeting.meeting_notes}
          className="participant-v5__participant-meeting-notes-input"
          placeholder="Provide feedback and next steps for your educator."
          configs="/legacy-libs/configs/bl-cke-config-workspace.js"
          takeFocus={true}
          onChange={() => setNotesChanging(true)}
        />
        {editButtons}
      </React.Fragment>
    );
  } else if (meeting.meeting_notes) {
    meetingNotesNode = (
      <AbridgeText
        isExpandable={true}
        containerClassName="participant-v5__participant-meeting-notes-text ckeditor__output  v5__body-copy workspace__vision-text"
        maxHeight={170}
      >
        <PurifiedHTML content={meeting.meeting_notes} />
      </AbridgeText>
    );
  }
  meetingNotesNode = (
    <div
      className="participant-v5__participant-meeting-notes-text-box"
      ref={meetingNotesRef}
    >
      {meetingNotesNode}
    </div>
  );

  let internalNotesNode = (
    <div
      className="participant-v5__empty-notes participant-v5__participant-meeting-notes-text-box v5__comment-copy"
      onClick={handleEditInternalNotesFunction}
    >
      Take private notes about this educator. How are they progressing? Is there
      anything you’d like to note for your next meeting?
    </div>
  );

  // This handles all of the different ways for the internal notes to be saved
  // If it's auto saved we don't want the editing window to close, otherwise we do
  // Additionally we don't want to update the notes if it was a cancel
  const saveInternalNotes = (
    meetingNotes,
    closeOnComplete,
    successCallback
  ) => {
    props.saveParticipantMeetingInternalNotes(
      {
        internalNotes: meetingNotes,
        meetingId: meeting.id,
        participantId: participant.id,
      },
      closeOnComplete,
      successCallback
    );
  };
  if (props.isEditingInternalNotes) {
    canEditMeetingNotes = '';
    canEditInternalNotes = '';
    canAddMeetingVideo = '';
    const editButtons = (
      <div className="participant-v5__meeting-video-url-buttons">
        <AutoSave
          isLoading={props.isLoading}
          changed={notesChanging}
          save={(successCallback) =>
            saveInternalNotes(
              CKEDITOR.instances[
                'participant-v5__participant-meeting-notes-input-internal-notes-0'
              ].getData(),
              false,
              successCallback
            )
          }
          resetChanged={() => setNotesChanging(false)}
        />
        <button
          className="v5__btn"
          {...btnProps}
          onClick={() => {
            saveInternalNotes(
              CKEDITOR.instances[
                'participant-v5__participant-meeting-notes-input-internal-notes-0'
              ].getData(),
              true
            );
          }}
        >
          <Spinner loading={props.isLoading} />
          Save
        </button>
        <button
          className={
            'v5__btn--blue-border v5__btn participant-v5__meeting-video-url-buttons-cancel'
          }
          {...btnProps}
          onClick={() => {
            saveInternalNotes(meeting.internal_notes, true);
          }}
        >
          Cancel
        </button>
      </div>
    );

    internalNotesNode = (
      <React.Fragment>
        <CKEditor
          id="internal-notes"
          increment={0}
          innerText={meeting.internal_notes}
          className="participant-v5__participant-meeting-notes-input"
          placeholder="Take private notes about this educator. How are they progressing? Is there anything you’d like to note for your next meeting?"
          configs="/legacy-libs/configs/bl-cke-config-workspace.js"
          takeFocus={true}
          onChange={() => setNotesChanging(true)}
        />
        {editButtons}
      </React.Fragment>
    );
  } else if (meeting.internal_notes) {
    internalNotesNode = (
      <AbridgeText
        isExpandable={true}
        containerClassName="participant-v5__participant-meeting-notes-text ckeditor__output  v5__body-copy"
        maxHeight={170}
      >
        <PurifiedHTML content={meeting.internal_notes} />
      </AbridgeText>
    );
  }
  internalNotesNode = (
    <div
      className="participant-v5__participant-meeting-notes-text-box"
      ref={internalNotesRef}
    >
      {internalNotesNode}
    </div>
  );

  const canCreateMeetingClips =
    Utils.isServicesManager.call(lab, props.user) ||
    (Utils.isContractCoach.call(lab, props.user) &&
      Utils.isTeachersCoach.call(lab, props.user, props.participant)) ||
    Utils.isAdmin.call(lab, props.user) ||
    Utils.isManager.call(lab, props.user);
  let meetingClipsNode;
  let meetingVideo = canCreateMeetingClips && canAddMeetingVideo && (
    <div
      className="participant-v5__empty-note v5__comment-copy v5__link"
      onClick={handleEditMeetingVideoLink}
      data-testid="video-link-link"
    >
      Add meeting video
    </div>
  );

  if (props.isEditingVideoLink && canCreateMeetingClips) {
    canEditMeetingNotes = '';
    canEditInternalNotes = '';
    canAddMeetingVideo = '';
    meetingVideo = (
      <div data-testid="meeting-video-editing">
        <label>
          <div className="v5__body-copy participant-v5__meeting-link-info">
            To add a recording, paste the link to your Zoom meeting recording.
            For multiple links, press Return and paste each link on its own
            line.
          </div>
          <FormField
            type="textarea"
            error={videoLinkError}
            className="participant-v5__clip-times-margins"
            inputClassName="participant-v5__meeting-video-url"
            onChange={(e) => setVideoLink(e.target.value)}
            data-testid="video-link-url"
          />
        </label>
        <div className="participant-v5__meeting-video-url-buttons">
          <button
            className="v5__btn"
            {...btnProps}
            onClick={() => handleMeetingSave(exitEditVideoLink)}
          >
            <Spinner loading={props.isLoading} />
            Save
          </button>
          <button
            className={
              'v5__btn--blue-border v5__btn participant-v5__meeting-video-url-buttons-cancel'
            }
            {...btnProps}
            onClick={() => exitEditVideoLink()}
            data-testid="video-cancel-editing"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  } else if (
    canCreateMeetingClips &&
    meeting.recordings &&
    meeting.recordings.length &&
    meeting.recordings.some(
      (recording) =>
        recording.video_link && [2, 3].includes(recording.video_status)
    )
  ) {
    // Once a user has added a video they should be able to create new clips
    // only if they're an internal coach though
    let addClipNode = (
      <div className="participant-v5__meeting-add-clip-placeholder" />
    );
    // Don't let the user open a modal if any other editing is being done
    if (
      !props.isEditing &&
      !props.isEditingMeetingNotes &&
      !props.isEditingInternalNotes &&
      canEditData
    ) {
      addClipNode = (
        <div
          className="participant-v5__meeting-add-clip v5__link participant-v5__meeting-view-in-workspace-text participant-v5__meeting-link"
          onClick={handleAddClip}
        >
          <span className="tc-v5-plus-solid participant-v5__icon-space" />
          Create a meeting clip
        </div>
      );
    }

    const meetingClips = meeting.recordings
      .filter((recording) => {
        return (
          recording.clips &&
          recording.clips.length &&
          recording.video_link &&
          [2, 3].includes(recording.video_status)
        );
      })
      .map((recording) => {
        return recording.clips.map((clip) => (
          <ClipRow
            key={'clip-' + clip.id}
            clip={clip}
            participant={props.participant}
            video_download_link={recording.video_download_link}
            clipTypeOptions={props.meeting.clipTypeOptions}
            canEditData={canEditData}
            editClip={props.openMeetingClipModal}
            publishClip={props.openPublishClipModal}
            clearAlert={props.clearAlert}
          />
        ));
      })
      .reduce((a, b) => [...a, ...b], []);

    meetingClipsNode = (
      <div className="participant-v5__participant-meeting-recording-box">
        <div className="participant-v5__participant-meeting-notes-headline clips-headline v5__heading-5">
          <div>
            <span className="participant-v5__participant-meeting-clips tc-v5-meeting-clip" />
            <span className="participant-v5__participant-meeting-notes-headline-text">
              Clips
            </span>
          </div>
          {addClipNode}
        </div>
        {meetingClips}
      </div>
    );

    meetingVideo = (
      <React.Fragment>
        <div className="v5__heading-6">Full Meeting Video</div>
        <div className="participant-v5__meeting-view-in-workspace-text participant-v5__meeting-link">
          <NavigationLink
            url={`/coaching/lab/meeting/${meeting.uuid}`}
            className="v5__link"
            target="_blank"
          >
            {`${process.env.REACT_APP_LAB_URL}/coaching/lab/meeting/${meeting.uuid}`}
            &nbsp;
          </NavigationLink>
        </div>
        <NavigationLink
          url={`/coaching/lab/meeting/${meeting.uuid}`}
          target="_blank"
        >
          <ArtifactImage
            preview={meeting.content && meeting.content.preview}
            title={meeting.name}
            containerClass="participant-v5__meeting-preview"
            icon="m4v"
            imageClass="participant-v5__recording-image"
            data-testid="zoom-video"
          />
        </NavigationLink>
        {meeting.recordings.map((recording, i) => {
          if (recording.video_status === 1) {
            // Display this message if the video hasn't finished processing
            return (
              <div
                key={`meeting-recording-${recording.id}`}
                className="participant-v5__participant-meeting-video-converted-msg"
              >
                <h2 className="v5__heading-6 meeting__detail-headline">{`Recording ${
                  i + 1
                }`}</h2>
                We are converting your video, which may take a few minutes.
                Refresh the page or check back later to view your video.
              </div>
            );
          } else {
            const duration = StringHelpers.formatDurationHms(
              recording.video_length
            );

            return (
              <div key={`meeting-recording-${recording.id}`}>
                <h2 className="v5__heading-6 meeting__detail-headline">{`Recording ${
                  i + 1
                }`}</h2>
                <NavigationLink
                  url={`/coaching/lab/meeting/recording/${recording.uuid}`}
                  target="_blank"
                  className="v5__link participant-v5__meeting-view-in-workspace-text participant-v5__meeting-link"
                >
                  {`${process.env.REACT_APP_LAB_URL}/coaching/lab/meeting/recording/${recording.uuid} (${duration})`}
                </NavigationLink>
              </div>
            );
          }
        })}
      </React.Fragment>
    );
  } else if (
    meeting.recordings &&
    meeting.recordings.length &&
    meeting.recordings.some((recording) => recording.video_link)
  ) {
    meetingVideo = (
      <div className="participant-v5__participant-meeting-notes-box v5__body-copy">
        We are converting your video, which may take a few minutes. Refresh the
        page or check back later to view your video.
      </div>
    );
  }

  if (!canEditData) {
    canEditMeetingNotes = '';
    canEditInternalNotes = '';
    canAddMeetingVideo = '';
  }

  const meetingRecordingNode = canCreateMeetingClips && (
    <div className="participant-v5__participant-meeting-video-box" id="videos">
      <div className="participant-v5__participant-meeting-notes-headline v5__heading-5">
        <span className="participant-v5__participant-meeting-video tc-v5-meeting-recording" />
        <div className="participant-v5__participant-meeting-clip-title participant-v5__participant-meeting-notes-headline-text">
          Meeting Video
        </div>
        <div
          className={`v5__link ${canAddMeetingVideo} participant-v5__participant-meeting-participant-time-edit participant-v5__participant-meeting-notes-headline-text`}
          onClick={handleEditMeetingVideoLink}
          data-testid="video-link-pencil"
        ></div>
      </div>
      {meetingVideo}
    </div>
  );

  const notesNode = meeting.id && (
    <React.Fragment>
      <div className="participant-v5__participant-meeting-notes-box">
        <div className="participant-v5__participant-meeting-notes-headline v5__heading-5">
          <div className="participant-v5__participant-meeting-notes tc-v5-shared-notes" />
          <div className="participant-v5__participant-meeting-notes-headline-text">
            Shared Notes and Next Steps
          </div>
          <div
            className={`v5__link participant-v5__participant-meeting-participant-time-edit ${canEditMeetingNotes}`}
            onClick={handleEditMeetingNotesFunction}
            data-testid="meeting-notes-pencil"
          />
        </div>
        {meetingNotesNode}
        {workspaceLink}
      </div>
      <div className="participant-v5__participant-meeting-notes-box">
        <div className="participant-v5__participant-meeting-notes-headline v5__heading-5">
          <div className="participant-v5__participant-internal-notes tc-v5-internal-notes" />
          <div className="participant-v5__participant-meeting-notes-headline-text">
            Internal Notes
          </div>
          <div
            className={`v5__link participant-v5__participant-meeting-participant-time-edit ${canEditInternalNotes}`}
            onClick={handleEditInternalNotesFunction}
            data-testid="internal-notes-pencil"
          />
        </div>
        {internalNotesNode}
      </div>
      {meetingRecordingNode}
    </React.Fragment>
  );

  const breadcrumbTrail = [
    {
      text: 'Coach Dashboard',
      link: `/lab/coach/${props.pageUser.id}/coaching`,
    },
    {
      text: `${participant.user.first_name} ${participant.user.last_name}`,
      link: `/lab/participant/${participant.id}/details`,
    },
    {
      text: `Meeting on ${meetingDateDisplay}`,
    },
  ];

  return (
    <div className="tc__page">
      <Header page="ParticipantMeetingDetails" {...props} />
      {alertNode}
      {modalNode}

      <div className="row row--1200" style={{ marginBottom: '100px' }}>
        <div className="cute-12-desktop center-desktop cute-12-phone center-phone">
          <div className="participant-v5__participant-meeting-headline">
            <Breadcrumb trail={breadcrumbTrail} divider=" / " />
          </div>
          <div className="participant-v5__meeting-details-box-outside">
            <div className="participant-v5__meeting-details-box">
              {headlineNode}
              <div className="participant-v5__participant-meeting-time-headline">
                {descriptionNode}
              </div>
              <div className="v5__body-copy participant-v5__meeting-details-text">
                {meetingLengthNode}
              </div>
              {zoomLinkNode}
              {syncedWithGCal}
              {syncedWithZoom}
            </div>
            <div className="participant-v5__participant-meeting-details">
              <NavigationLink
                className={`v5__link participant-v5__participant-meeting-teacher-details ${participantInfoClass}`}
                url={`/lab/participant/${participant.id}/details`}
              >
                <UserPhoto
                  src={participant.user.photo}
                  userId={participant.user.user_id}
                  firstName={participant.user.first_name}
                  lastName={participant.user.last_name}
                  diameter={50}
                />
                <div className="participant-v5__participant-meeting-participant-info">
                  {nameNode}
                  {roleNode}
                </div>
              </NavigationLink>
              <span className="v5__comment-copy">
                Meeting Status{statusNode}
              </span>
            </div>
            {setCancelTimeNode}
            {sendNoShowRescheduleEmailCheckbox}
            {meetingTimeErrorNode}
            {cancelTimeErrorNode}
            {saveButtons}
          </div>
          {notesNode}
          {meetingClipsNode}
        </div>
      </div>
    </div>
  );
};

ParticipantMeetingDetails.propTypes = {
  participant: PropTypes.object.isRequired,
  pageUser: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default ParticipantMeetingDetails;
