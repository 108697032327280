// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../../components/DynamicUtils';
import Spinner from './../Spinner';

const ArtifactForm = (props) => {
  let form;
  let title;
  let description;

  const handleFocus = (field) => {
    if (props.handleFocus) {
      props.handleFocus(field);
    }
  };

  // update the state when a different tag is selected
  const handleTagChange = (tag) => {
    props.setSelectedTag(tag);
  };

  // update the state when toggling a growth area checkbox
  const handleToggleGrowthArea = (growthArea) => {
    if (props.toggleGrowthArea) {
      props.toggleGrowthArea(growthArea);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      type: props.type,
      storyArtifactId: props.storyArtifactId,
      title: title.value,
      description: description.value,
      tag: props.selectedTag,
      private:
        !props.selectedTag || props.selectedTag === 'reflection'
          ? props.isPrivate
          : false,
    };

    // if the "Highlight on my Profile" checkbox is visible, include it
    if (data.tag === 'reflection') {
      data.highlightedOnProfile = props.isHighlighted;
    }

    // if any growth areas were checked, include them
    if (form && form.growthAreas) {
      data.growthAreas = Array.prototype.slice
        .call(form.growthAreas)
        .filter((input) => input.checked)
        .map((input) => parseInt(input.value));
    }

    /****** Necessary Attributes from the profile page ******/

    // this is a vision artifact append the appropreate data
    if (props.data.fileEntryId || props.data.vision_file_id) {
      data.fileEntryId = props.data.fileEntryId
        ? props.data.fileEntryId
        : props.data.vision_file_id;
    }
    // this is a vision link append the appropreate data
    if (props.data.linkEntryId || props.data.vision_link_id) {
      data.linkEntryId = props.data.linkEntryId
        ? props.data.linkEntryId
        : props.data.vision_link_id;
    }
    props.editArtifact(data);
  };

  // set up any errors on the title
  let titleErrorClass = '';
  let titleErrorNode = null;
  if (props.errors && props.errors.title) {
    titleErrorClass = ' error';
    titleErrorNode = <div className="tc__error">{props.errors.title}</div>;
  }

  // set up any errors on the description
  let descriptionErrorClass = '';
  let descriptionErrorNode = null;
  if (props.errors && props.errors.description) {
    descriptionErrorClass = ' error';
    descriptionErrorNode = (
      <div className="tc__error">{props.errors.description}</div>
    );
  }

  // set up any errors on the growth areas
  let growthAreasErrorNode = null;
  if (props.errors && props.errors.growthAreas) {
    growthAreasErrorNode = (
      <div className="tc__error">{props.errors.growthAreas}</div>
    );
  }

  // set up the description label
  let descriptionLabelText = 'Description';
  if (props.selectedTag === 'evidence') {
    descriptionLabelText =
      'Describe this evidence. What does it show? How does it relate to a growth area?';
  } else if (props.selectedTag === 'reflection') {
    descriptionLabelText =
      'Describe your progress within this learning domain. How are \
            things going? What are you learning? What are you working on?';
  }

  // set up default UI
  let tagRadiosNode = null;
  let highlightedCheckboxNode = null;
  let submitBtnText = 'Add';
  let deleteNode = null;
  let hiddenFromParticipantNode = null;

  // in edit mode, we show extra fields and the "Delete" button and change some copy
  if (props.editMode) {
    if (props.hasCoachAccess && props.isMeetingClip) {
      hiddenFromParticipantNode = (
        <div className="story__edit-entry-tag-radio">
          <input
            type="radio"
            id="isHidden"
            name="tag"
            checked={props.selectedTag === 'hidden'}
            onChange={() => handleTagChange('hidden')}
            className="story__edit-entry-radio-input"
          />
          <label htmlFor="isHidden" className="story__edit-entry-radio-label">
            Hidden
          </label>
        </div>
      );
    }
  }

  // if no selected tag method the form request is from the profile we do not need to render these options
  if (props.editMode && props.setSelectedTag) {
    // set up the radio buttons for setting the entry type
    tagRadiosNode = (
      <div className="story__edit-entry-tag-radios">
        <div className="story__edit-entry-tag-radio">
          <input
            type="radio"
            id="isArtifact"
            name="tag"
            checked={!props.selectedTag}
            onChange={() => handleTagChange(null)}
            className="story__edit-entry-radio-input"
          />
          <label htmlFor="isArtifact" className="story__edit-entry-radio-label">
            Artifact
          </label>
        </div>
        <div className="story__edit-entry-tag-radio">
          <input
            type="radio"
            id="isEvidence"
            name="tag"
            checked={props.selectedTag === 'evidence'}
            onChange={() => handleTagChange('evidence')}
            className="story__edit-entry-radio-input"
          />
          <label htmlFor="isEvidence" className="story__edit-entry-radio-label">
            <span className="tc-v4-leaf story__edit-entry-radio-label-icon"></span>
            Evidence
          </label>
        </div>
        <div className="story__edit-entry-tag-radio">
          <input
            type="radio"
            id="isReflection"
            name="tag"
            checked={props.selectedTag === 'reflection'}
            onChange={() => handleTagChange('reflection')}
            className="story__edit-entry-radio-input"
          />
          <label
            htmlFor="isReflection"
            className="story__edit-entry-radio-label"
          >
            <span className="tc-v4-text story__edit-entry-radio-label-icon"></span>
            Reflection
          </label>
        </div>
        {hiddenFromParticipantNode}
      </div>
    );

    // the "Highlight on my Profile" checkbox appears if the entry is a reflection
    if (props.selectedTag === 'reflection') {
      highlightedCheckboxNode = (
        <div className="story__edit-entry-highlighted-checkbox">
          <input
            type="checkbox"
            id="isHighlighted"
            checked={props.isHighlighted}
            onChange={props.toggleIsHighlighted}
            className="story__edit-entry-checkbox-input"
          />
          <label
            htmlFor="isHighlighted"
            className="story__edit-entry-checkbox-label"
          >
            <div className="story__edit-entry-checkbox-circle">
              <span className="tc-check"></span>
            </div>
            Highlight on my Profile
          </label>
        </div>
      );
    }
  }
  if (props.editMode) {
    deleteNode = props.children;
    submitBtnText = 'Update';
  }
  // the growth area checkboxes appear if the entry is evidence and there are sub focus areas to show
  let growthAreaCheckboxesNode = null;
  if (
    props.selectedTag === 'evidence' &&
    props.subFocusAreas &&
    props.subFocusAreas.length
  ) {
    growthAreaCheckboxesNode = (
      <div className="story__edit-entry-form-row story__edit-entry-growth-area-checkboxes">
        <label className="story__edit-entry-form-label">
          Growth Areas: Select a growth area supported by this evidence.
        </label>
        {props.subFocusAreas.map((sfa) => {
          // see if we should show the SFA as checked
          const checked = props.selectedGrowthAreas.indexOf(sfa.id) !== -1;
          // change the icon based on whether or not is checked
          const iconClass = checked ? 'tc-x' : 'tc-check';

          return (
            <div
              key={'sfa-' + sfa.id}
              className="story__edit-entry-growth-area-checkbox"
            >
              <input
                type="checkbox"
                id={'sfa-' + sfa.id}
                name="growthAreas"
                value={sfa.id}
                checked={checked}
                onChange={() => handleToggleGrowthArea(sfa.id)}
                className="story__edit-entry-growth-area-checkbox-input"
              />
              <label
                htmlFor={'sfa-' + sfa.id}
                className="story__edit-entry-growth-area-checkbox-label"
              >
                {sfa.nickname}
                <span
                  className={
                    'story__edit-entry-growth-area-checkbox-icon ' + iconClass
                  }
                ></span>
              </label>
            </div>
          );
        })}
        {growthAreasErrorNode}
      </div>
    );
  }

  // only plain artifacts and reflections can be made private
  let privateCheckboxNode;
  if (!props.selectedTag || props.selectedTag === 'reflection') {
    // private checkbox label has different icon/text for checked vs unchecked
    let privateLabelNode = (
      <label
        htmlFor="isPrivate"
        className="story__edit-entry-checkbox-label story__edit-entry-checkbox-label--grey"
      >
        <div className="tc-v4-unlock story__edit-entry-checkbox-icon"></div>
        Visible on Profile
      </label>
    );
    if (props.isPrivate) {
      privateLabelNode = (
        <label
          htmlFor="isPrivate"
          className="story__edit-entry-checkbox-label story__edit-entry-checkbox-label--grey"
        >
          <div className="tc-v4-lock story__edit-entry-checkbox-icon"></div>
          Private
        </label>
      );
    }

    privateCheckboxNode = (
      <div className="story__edit-entry-form-row">
        <input
          type="checkbox"
          id="isPrivate"
          checked={props.isPrivate}
          onChange={() => {
            props.toggleIsPrivate(
              props.data.extension === 'link' ? 'link' : 'file'
            );
          }}
          className="story__edit-entry-checkbox-input"
        />
        {privateLabelNode}
      </div>
    );
  }

  return (
    <div>
      <form
        ref={(el) => (form = el)}
        className="artifact-form-v4"
        onSubmit={handleSubmit}
      >
        <div className="story__edit-entry-form-row">
          <label className="story__edit-entry-form-label">Title</label>
          <input
            type="text"
            className={
              'story__edit-entry-form-input tc__input' + titleErrorClass
            }
            ref={(ref) => (title = ref)}
            defaultValue={Utils.unicodeToChar(props.data.title)}
            onFocus={() => handleFocus('title')}
          />
          {titleErrorNode}
        </div>

        {tagRadiosNode}
        {highlightedCheckboxNode}

        <div className="story__edit-entry-form-row">
          <label className="story__edit-entry-form-label">
            {descriptionLabelText}
          </label>
          <textarea
            className={
              'story__edit-entry-form-input tc__input' + descriptionErrorClass
            }
            ref={(ref) => (description = ref)}
            defaultValue={props.data.description}
            maxLength={65535}
            onFocus={() => handleFocus('description')}
          />
          {descriptionErrorNode}
        </div>

        {growthAreaCheckboxesNode}
        {privateCheckboxNode}

        <div className="story__edit-entry-form-btns">
          <button
            onClick={handleSubmit}
            className={
              'bl4__btn bl4__btn--blue story__edit-entry-form-btn' +
              (props.loading ? ' disabled' : '')
            }
          >
            <Spinner loading={props.loading} />
            {submitBtnText}
          </button>
        </div>
      </form>

      {deleteNode}
    </div>
  );
};

ArtifactForm.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  subFocusAreas: PropTypes.array,
  storyArtifactId: PropTypes.number,
  handleFocus: PropTypes.func,
  setSelectedTag: PropTypes.func,
  toggleIsHighlighted: PropTypes.func,
  toggleIsPrivate: PropTypes.func,
  toggleGrowthArea: PropTypes.func,
  editArtifact: PropTypes.func.isRequired,
  selectedTag: PropTypes.string,
  selectedGrowthAreas: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  hasCoachAccess: PropTypes.bool,
};

ArtifactForm.defaultProps = {
  loading: false,
  editMode: false,
  hasCoachAccess: false,
};

export default ArtifactForm;
