// @ts-nocheck FIXME
import GateKeeperApi from '@legacy-components/apis/GateKeeperApi';
import Analytics from '@legacy-components/components/Analytics';
import Utils from '@legacy-components/components/DynamicUtils';
import { Router } from '@legacy-components/components/Router';
import Store from '@legacy-components/components/Store';
import StringHelpers from '@legacy-components/components/StringHelpers';
import WindowHelpers from '@legacy-components/components/WindowHelpers';

const store = Store('GateKeeperStore', {
  scrollPosition: 0,
  loginAttempts: 0,
  ui: {
    errorBanner: null,
    modalDisplay: null,
    rememberMe: true,
    loading: false,
    alert: {},
    errors: {},
    successMessage: null,
    cta: null,
  },
  isMobileUser: false,
  // Which site is the user trying to access?
  siteName: 'plans',
  recaptchaSiteKey: null,
  roles: [],
  redirect: null,
});

/****** PRIVATE FUNCTIONS ******/
const setApiError = (message) => {
  store.ui.errors.general = store.ui.errors.general
    ? store.ui.errors.general
    : [];

  store.ui.errors.general.push(message);
};

const setSuccessAlert = (message) => {
  store.ui.alert = {
    type: 'success',
    message: message,
    handleClose: store.clearAlert,
  };

  window.setTimeout(() => {
    window.scroll(0, 0);
  }, 50);
};

const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };

  window.setTimeout(() => {
    window.scroll(0, 0);
  }, 50);
};

const getFieldErrorMessage = (fieldName) => {
  switch (fieldName) {
    case 'firstName':
      return 'Please enter your first name.';
    case 'lastName':
      return 'Please enter your last name.';
    case 'email':
      return 'Please enter your email.';
    case 'password':
      return 'Please enter a password.';
    case 'role':
      return 'Please select a role.';
    case 'recaptchaResponse':
      return 'Beep boop beep. Looks like you are a robot.';
  }
  return fieldName + ' is required.';
};

const handleRedirect = (redirect = null, fromTag = '') => {
  let redirectDestination = '/';
  if (store.redirect) {
    redirectDestination = store.redirect;
  } else if (!store.redirect && redirect) {
    redirectDestination = redirect;
  }
  location.href = redirectDestination + fromTag;
};
/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.ui.alert = {};
  store.ui.errors = {};
  store.isMobileUser = data.isMobileUser;
  const url = Router.getCurrentPath();
  if (url.includes('/coaching/lab/participant')) {
    store.siteName = 'lab app';
  } else if (url.includes('/coaching/lab')) {
    store.siteName = 'lab';
  }
  store.redirect = WindowHelpers.getQueryStringParam('redirect');
  store.roles = data.roles || [];
  store.recaptchaSiteKey = data.recaptcha_site_key;
  store.ui.successMessage = null;

  if (WindowHelpers.getQueryStringParam('from') === 'reset_password') {
    setSuccessAlert('Your password has been saved!');
  }

  const bannerMessage = WindowHelpers.getQueryStringParam('errorMsg');
  if (bannerMessage) {
    store.ui.errorBanner = bannerMessage;
  }
  store.linkSource = WindowHelpers.getQueryStringParam('source');

  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.toggleRememberMe = (page = 'login') => {
  const labelPrefix = 'from_';
  Analytics.logEvent({
    category: 'remember_me',
    action: store.ui.rememberMe ? 'off' : 'on',
    label:
      page === 'resetPW' ? labelPrefix + 'set_password' : labelPrefix + 'login',
  });
  store.ui.rememberMe = !store.ui.rememberMe;
};

store.register = (data) => {
  data.requestPath = WindowHelpers.getPathName();

  store.ui.alert = {};
  store.ui.errors = {};

  let errors = {};
  const requiredFields = ['firstName', 'lastName', 'email', 'role'];

  requiredFields.forEach(function (field) {
    if (!StringHelpers.isValid(data[field], field)) {
      errors[field] = getFieldErrorMessage(field);
      errors.general = [];
      errors.general.push('Please enter all the required fields.');
    }
  });

  const passwordValidationErrors = StringHelpers.getPasswordValidationErrors(
    data.password,
    data.confirmPassword
  );
  errors = Object.assign(errors, passwordValidationErrors);

  if (!Utils.isEmpty(errors)) {
    Analytics.logEvent({
      category: 'sign_up',
      action:
        passwordValidationErrors && errors.length > 1
          ? 'sign_up_failed_password'
          : 'sign_up_failed_required_field',
      label: 'from_sign_up',
    });

    store.ui.errors = errors;

    window.setTimeout(() => {
      window.scroll(0, 0);
    }, 50);
    return;
  }

  store.ui.loading = true;
  data.isMobileUser = store.isMobileUser;

  return GateKeeperApi.register(data)
    .then((response) => {
      store.ui.loading = false;
      Analytics.logEvent({
        category: 'sign_up',
        action: 'sign_up_success',
        label: 'from_sign_up',
      });
      if (data.successCallback && typeof data.successCallback === 'function') {
        data.successCallback(response);
        return false;
      }
      const hasQueryString = Utils.hasQueryString(response.redirectUrl);
      const fromTag = `${hasQueryString}from=sign_up`;
      handleRedirect(response.redirectUrl, fromTag);

      return false;
    })
    .catch((response) => {
      store.ui.loading = false;
      const formattedError = response.error.replace(/ /g, '_').toLowerCase();
      const action = `sign_up_failed_${formattedError}`;

      Analytics.logEvent({
        category: 'sign_up',
        action: action,
        label: 'from_sign_up',
      });
      setApiError(response.error);
    });
};

store.login = (data) => {
  store.ui.alert = {};
  store.ui.errors = {};

  const errors = {};

  const requiredFields = ['email', 'password'];
  requiredFields.forEach(function (field) {
    if (!StringHelpers.isValid(data[field], field)) {
      errors[field] = getFieldErrorMessage(field);
      errors.general = [];
      errors.general.push('Please enter all the required fields.');
      store.loginAttempts = null;
    }
  });

  if (!Utils.isEmpty(errors)) {
    store.ui.errors = errors;

    window.setTimeout(() => {
      window.scroll(0, 0);
    }, 50);
    return;
  }

  store.ui.loading = true;
  data.isMobileUser = store.isMobileUser;

  return GateKeeperApi.login(data)
    .then((response) => {
      store.ui.loading = false;
      if (data.successCallback && typeof data.successCallback === 'function') {
        data.successCallback(response);
        return false;
      }
      const sessionStorage =
        typeof window === 'undefined' ? Storage : window.sessionStorage;
      sessionStorage.clear();

      const hasQueryString = Utils.hasQueryString(response.redirectUrl);
      const fromTag = `${hasQueryString}from=login`;
      handleRedirect(response.redirectUrl, fromTag);
    })
    .catch((response) => {
      store.ui.loading = false;
      store.loginAttempts = response.attempts;
      setApiError(response.error);
    });
};

store.requestNewPassword = (data) => {
  data.requestPath = WindowHelpers.getPathName();

  store.ui.alert = {};
  store.ui.errors = {};
  store.ui.successMessage = null;

  const errors = {};
  const requiredFields = ['email'];
  requiredFields.forEach(function (field) {
    if (!StringHelpers.isValid(data[field], field)) {
      errors[field] = getFieldErrorMessage(field);
      errors.general = [];
      errors.general.push('Please enter all the required fields.');
    }
  });

  if (!Utils.isEmpty(errors)) {
    store.ui.errors = errors;

    window.setTimeout(() => {
      window.scroll(0, 0);
    }, 50);
    return;
  }

  store.ui.loading = true;
  data.isMobileUser = store.isMobileUser;

  return GateKeeperApi.requestNewPassword(data)
    .then(() => {
      store.ui.loading = false;
      const successMessage =
        'If you have an account with us, you will be sent a password reset link to the email provided.';
      if (data.successCallback && typeof data.successCallback === 'function') {
        data.successCallback(successMessage);
        return false;
      }
      setSuccessAlert(successMessage);
    })
    .catch((response) => {
      store.ui.loading = false;
      setApiError(response.error);
    });
};

store.resetPassword = (data) => {
  data.resetCode = WindowHelpers.getQueryStringParam('code');
  data.resetToken = WindowHelpers.getQueryStringParam('token');
  data.sub = WindowHelpers.getQueryStringParam('sub');
  store.ui.alert = {};
  store.ui.errors = {};

  const errors = StringHelpers.getPasswordValidationErrors(
    data.password,
    data.confirmPassword
  );

  // if the form has any errors, display them instead of submitting the form
  if (!Utils.isEmpty(errors)) {
    store.ui.errors = errors;

    window.setTimeout(() => {
      window.scroll(0, 0);
    }, 50);
    return;
  }

  store.ui.loading = true;
  data.isMobileUser = store.isMobileUser;

  return GateKeeperApi.resetPassword(data)
    .then((response) => {
      const hasQueryString = Utils.hasQueryString(response.redirectUrl);
      const fromTag =
        store.siteName === 'plans'
          ? `${hasQueryString}from=reset_password`
          : `${hasQueryString}from=lab_set_password`;
      handleRedirect(response.redirectUrl, fromTag);
    })
    .catch((response) => {
      store.ui.loading = false;
      setErrorAlert(response.error);
    });
};

store.recaptchaSuccessCallback = (data) => {
  if (
    StringHelpers.isValid(data, 'recaptchaResponse') &&
    store.ui.errors.recaptchaResponse
  ) {
    delete store.ui.errors.recaptchaResponse;
  }
};

store.actionCreate('setmodalDisplay', (data) => {
  store.ui.errors = {};
  store.ui.cta = null;
  store.ui.modalDisplay = data.modalToShow;

  const appContainer = document.getElementById('common_app');
  const overlayElem = document.querySelector('.mt-resource-overlay');
  if (data.eventTargetId) {
    let message = null;
    switch (data.eventTargetId) {
      case 'static-site-favorite-button':
        message = 'To favorite lessons, please create your free account.';
        break;
      case 'static-site-print-button':
        message = 'To print lessons, please create your free account.';
        break;
      case 'static-site-download-button':
        message = 'To download resources, please create your free account.';
        break;
    }
    store.ui.cta = message;
  }
  if (data.modalToShow == null) {
    const BL = window.BL;

    WindowHelpers.scrollTo(0, store.scrollPosition);

    if (
      !BL ||
      !appContainer ||
      !overlayElem ||
      Utils.hasClass(overlayElem, 'hidden')
    ) {
      return;
    }

    appContainer.removeAttribute('style');
    BL.UI.disableWindowScroll();
    return;
  }

  store.scrollPosition =
    document.documentElement.scrollTop || document.body.scrollTop;

  // if the resource overlay is open we need to do some extra work to resolve any scroll issues
  if (!appContainer || !overlayElem || Utils.hasClass(overlayElem, 'hidden')) {
    return;
  }

  appContainer.setAttribute('style', 'height:840px;overflow:hidden;');
  document.body.removeAttribute('style');
  Utils.removeClass(document.body, 'opened-resource-overlay');
});

store.requestNewPasswordSuccess = () => {
  store.ui.successMessage =
    'If you have an account with us, you will be sent a password reset link to the email provided.';
};

export default Store('GateKeeperStore', store);
