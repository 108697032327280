// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const ConfirmDeleteModal = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.onDelete({
      participantId: props.object.id,
      coachingEngagementId: props.object.coaching_engagement_id,
    });
  };

  const handleCloseOverlay = (e) => {
    e.preventDefault();
    props.closeConfirmDeleteModal();
  };

  // show the custom body text if provided; otherwise, use the default
  const bodyText = props.customBodyText
    ? props.customBodyText
    : 'Delete "' + (props.object.title || props.object.name) + '"?';

  // disable buttons when form is submitted
  const disabledClass = props.btnDisabled ? ' disabled' : '';
  let errorMessageNode = null;
  if (props.errorMessage) {
    errorMessageNode = (
      <div className="tc__error tc__error--block">{props.errorMessage}</div>
    );
  }

  return (
    <div>
      <div
        className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
        onClick={handleCloseOverlay}
      ></div>
      <div className="modal--fixed modal--grey-overlay ppd-v3-curation-modal ppd-v3-curation-modal--short">
        <div className="modal__close" onClick={handleCloseOverlay}>
          <div className="tc-x"></div>
        </div>
        <div className="ppd-v3-curation-modal__body">
          <div className="ppd-v3-curation-modal__body-text">{bodyText}</div>
          {errorMessageNode}
          <div className="ppd-v3-curation-modal__buttons">
            <button
              onClick={handleSubmit}
              className={`tc__btn tc__btn--small tc__btn--grey-with-orange-outline ppd-v3-curation-modal__button${disabledClass}`}
            >
              Delete
            </button>
            <button
              onClick={handleCloseOverlay}
              className={`tc__btn tc__btn--small tc__btn--grey ppd-v3-curation-modal__button ppd-v3-curation-modal__button--cancel${disabledClass}`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmDeleteModal.propTypes = {
  object: PropTypes.object.isRequired,
  closeConfirmDeleteModal: PropTypes.func.isRequired,
  customBodyText: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  btnDisabled: PropTypes.bool,
};

export default ConfirmDeleteModal;
