// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import React from 'react';
import AbridgeText from './../../../Common/AbridgeText';

const EventSessionEntry = (props) => {
  const inputEL = React.useRef(null);

  React.useEffect(() => {
    if (props.smallView) {
      const elem = inputEL.current;
      const elemHeight = elem.getBoundingClientRect().height;

      if (elemHeight > 230) {
        elem.style.height = '230px';
        elem.style.overflowY = 'hidden';
        props.setTruncateState(true);
      }
    }
  }, []);

  let bodyNode = props.editLink ? (
    <NavigationLink className="v5__btn v5__btn--blue" url={props.editLink}>
      Add Next Steps
    </NavigationLink>
  ) : null;
  if (props.nextSteps) {
    const textNode = (
      <PurifiedHTML
        className="ckeditor__output v5__body-copy"
        ref={inputEL}
        content={props.nextSteps}
      />
    );

    let toggle = true;
    if (props.toggle === false) {
      toggle = false;
    }

    bodyNode = toggle ? (
      <AbridgeText
        maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
        isExpandable={true}
        greyFade={props.greyFade}
      >
        {textNode}
      </AbridgeText>
    ) : (
      textNode
    );
  }

  return (
    <div className="workspace__entry-body">
      <div className="workspace__entry-row">{bodyNode}</div>
    </div>
  );
};

EventSessionEntry.propTypes = {
  nextSteps: PropTypes.string,
  editLink: PropTypes.string,
  setTruncateState: PropTypes.func,
  toggle: PropTypes.bool,
  greyFade: PropTypes.bool,
};

export default EventSessionEntry;
