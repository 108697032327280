// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import StoryApi from '@legacy-components/apis/V4/StoryApi';
import EventApi from '@legacy-components/apis/V5/EventApi';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import RedirectHelper from '@legacy-components/components/RedirectHelper';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import Breadcrumb from '@legacy-components/react/V5/Common/Breadcrumb';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import EventAdditionalResources from './modules/EventAdditionalResources';
import EventAttendeeSessionContent from './modules/EventAttendeeSessionContent';
import EventAttendeeStickyNav from './modules/EventAttendeeStickyNav';
import EventDetails from './modules/EventDetails';
import EventMaterial from './modules/EventMaterial';
import EventSessions from './modules/EventSessions';
import NextSteps from './modules/NextSteps';

const EventAttendeeContainer = (props) => {
  const lab = useLegacyLabContext();
  const mq = window.matchMedia('(max-width: 72em)');
  const sessionsContainerRef = useRef();

  // some user have dont have to checkin and have access to all sessions and regardless of their active state
  const userHasAdminPermissions =
    Utils.isContractCoach.call(lab) ||
    Utils.isServicesManager.call(lab) ||
    Utils.isAdmin.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.getItemIndexFromArrayOfObjects(
      props.event.partner_leads,
      'id',
      props.user.id
    ) !== parseInt(-1)
      ? true
      : false;

  const [eventSessionParticipants, setEventSessionParticipants] = useState(
    props.participant ? props.participant.event_session_participants : []
  );

  const sessionActivityTimers = () => {
    const output = {};

    // no sessions no need for setting up any timers
    if (!props.event.sessions) {
      return output;
    }

    const tenMinutes = moment.duration(10, 'minutes');
    props.event.sessions.forEach((session) => {
      const currentTime = moment();

      if (!session.session_date_formatted) {
        output[session.id] = {
          sessionDateStarted: false,
          ended: false,
          started: false,
          selected: session.id === parseInt(props.selected_session),
          workspaceEntry: session.workspace_entry,
        };
        return;
      }

      // default start time is 12AM on the day of the session
      let sessionStartTime = moment.tz(
        session.session_date_formatted,
        props.event.time_zone.pytz_timezone
      );
      // the capsule becomes clickable on the start of the day of the session
      const sessionDateStarted = currentTime.isSameOrAfter(sessionStartTime);

      // default end time is 12AM the day after the session
      let sessionEndTime = moment(sessionStartTime).add(1, 'day');
      const sessionDateEnded = currentTime.isAfter(sessionEndTime);

      if (
        session.session_start_time_formatted &&
        session.session_end_time_formatted
      ) {
        sessionStartTime = moment(session.session_start_time_formatted);
        sessionEndTime = moment(session.session_end_time_formatted);
      }

      // set time to display button 5 minutes before the meeting
      const started =
        currentTime.isSameOrAfter(sessionStartTime - tenMinutes) &&
        currentTime.isBefore(sessionEndTime);

      output[session.id] = {
        sessionDateStarted: sessionDateStarted && !sessionDateEnded,
        ended: currentTime.isAfter(sessionEndTime),
        started: started,
        selected: session.id === parseInt(props.selected_session),
        workspaceEntry: session.workspace_entry,
      };
    });
    return output;
  };

  // variable is set on mount and on mobile resize
  let sessionsBottom = 0;
  const calculateSessionsBottom = () => {
    // unneeded functonality for desktop
    if (!mq.matches) {
      return;
    }

    const sessionsPosition = Utils.getAbsolutePosition(
      sessionsContainerRef.current
    );
    const sessionsTopPosition = sessionsPosition[1];
    const sessionsHeight = sessionsContainerRef.current.offsetHeight;

    // note 100 represents the height of the mobile header and banner
    sessionsBottom = sessionsTopPosition + sessionsHeight - 120;
  };

  const [sessionsBannerVisible, setSessionsBannerVisible] = useState(false);
  const handleScrollPastSessions = () => {
    // unneeded functonality for desktop
    if (!mq.matches) {
      return;
    }

    // scrolled past the session show nav
    if (
      document.documentElement.scrollTop >= sessionsBottom &&
      sessionsBannerVisible !== true
    ) {
      setSessionsBannerVisible(true);
      // scrolled before the session hide nav
    } else if (document.documentElement.scrollTop < sessionsBottom) {
      setSessionsBannerVisible(false);
    }
  };

  // contains the sesson information started, ended, and selected
  // inorder to decide how to render the page
  const [sessionActivity, setSessionActivity] = useState(
    sessionActivityTimers()
  );

  // used to maintain reference to the setTimeouts to clear them on unmount
  const activityTimers = [];

  useEffect(() => {
    const tenMinutes = moment.duration(10, 'minutes');

    props.event.sessions.forEach((session) => {
      // no session or no session date
      if (!session || !session.session_date_formatted) {
        return;
      }

      let endTimer = null;
      let startTimer = null;

      const currentTime = moment();
      // default start time is 12AM on the day of the session
      let sessionStartTime = moment.tz(
        session.session_date_formatted,
        props.event.time_zone.pytz_timezone
      );

      // default end time is 12AM the day after the session
      let sessionEndTime = moment(sessionStartTime).add(1, 'day');

      if (
        session.session_start_time_formatted &&
        session.session_end_time_formatted
      ) {
        sessionStartTime = moment(session.session_start_time_formatted);
        sessionEndTime = moment(session.session_end_time_formatted);
      }

      // Select the next session and calculate the time until it starts
      const timeUntilSession = moment.duration(sessionStartTime - currentTime);

      // Don't bother trying to update things if the meeting is more than two days in the future
      if (timeUntilSession >= moment.duration(173391689)) {
        return;
      }

      // session ended no need to set up start and end timers
      if (currentTime.isAfter(sessionEndTime)) {
        return;
      }

      // within the 10 min threshold session started
      else if (timeUntilSession.asMinutes() <= tenMinutes.asMinutes()) {
        // end session after session completion
        endTimer = setTimeout(() => {
          const localSessionActivity = { ...sessionActivity };
          localSessionActivity[session.id].ended = true;
          localSessionActivity[session.id].started = false;
          setSessionActivity(localSessionActivity);
        }, moment.duration(sessionEndTime - currentTime).asMilliseconds());

        activityTimers.push(endTimer);
      } else {
        // session hasn't started yet
        startTimer = setTimeout(() => {
          const localSessionActivity = { ...sessionActivity };

          // start the session
          localSessionActivity[session.id].started = true;
          setSessionActivity(localSessionActivity);

          // set a timeout to fire when the session ends
          endTimer = setTimeout(() => {
            const localSessionActivity = { ...sessionActivity };
            localSessionActivity[session.id].ended = true;
            localSessionActivity[session.id].started = false;
            setSessionActivity(localSessionActivity);
          }, moment.duration(sessionEndTime - currentTime).asMilliseconds());
        }, timeUntilSession.asMilliseconds() - tenMinutes.asMilliseconds());

        activityTimers.push(startTimer, endTimer);
      }
    });

    // calculate sessions position
    if (mq.matches) {
      calculateSessionsBottom();
    }

    // resize to calculate sessions position
    window.addEventListener('resize', calculateSessionsBottom);

    // scroll to show fixed mobile nav
    window.addEventListener('scroll', handleScrollPastSessions);

    // clear the listners and timeouts on unmount
    return () => {
      window.addEventListener('resize', calculateSessionsBottom);
      window.removeEventListener('scroll', handleScrollPastSessions);

      activityTimers.forEach((activityTimer) => {
        clearTimeout(activityTimer);
      });
    };
  }, []);

  // update the height of the sessions column when sidebar changes
  useEffect(() => {
    if (mq.matches) {
      calculateSessionsBottom();
    }
  }, [sessionActivity]);

  // event handler for submodules to set active session
  const handeSetActiveSession = (sessionId) => {
    const localSessionActivity = { ...sessionActivity };
    props.event.sessions.forEach((session) => {
      const sessionActive = session.id === sessionId ? true : false;
      localSessionActivity[session.id].selected = sessionActive;
    });
    setSessionActivity(localSessionActivity);
  };

  const discernActiveSession = () => {
    if (!props.event.sessions || !props.event.sessions.length) {
      return null;
    }
    const activeSessionId = props.event.sessions[0].id;
    let selectedId = null;
    let startedId = null;
    let sessionDateStartedId = null;
    for (const id in sessionActivity) {
      if (!sessionActivity[id]) {
        break;
      }
      if (sessionActivity[id].selected && !selectedId) {
        selectedId = id;
      } else if (sessionActivity[id].started && !startedId) {
        startedId = id;
      } else if (
        sessionActivity[id].sessionDateStarted &&
        !sessionDateStartedId
      ) {
        sessionDateStartedId = id;
      }
    }
    return selectedId || startedId || sessionDateStartedId || activeSessionId;
  };

  // used to create session Participant and update the ui for session checkin
  const handleSessionCheckin = () => {
    const activeSessionId = discernActiveSession();
    return EventApi.participantSessionCheckin({
      eventId: props.event.id,
      sessionId: activeSessionId,
      participantId: props.participant.id,
    }).then(
      (response) => {
        if (response.need_pre_assessment && response.assessment_uuid) {
          RedirectHelper.redirectToSelfAssessment(response.assessment_uuid);
        } else {
          // update the page to display checked in state
          const localEventSessionParticipants = Utils.clone(
            eventSessionParticipants
          );
          localEventSessionParticipants.push(response.sessionParticipant);
          setEventSessionParticipants(localEventSessionParticipants);
        }
      },
      () => {
        // show success
        props.setAlert({
          type: 'error',
          message: 'Something went wrong when checking into the event.',
        });
      }
    );
  };

  const submitNextSteps = (data) => {
    // create a new workspace entry if necessary
    if (!data.id) {
      StoryApi.addEventSessionEntry({
        storyId: props.participant.story_id,
        sessionId: activeSessionId,
        nextSteps: data.next_steps,
      })
        .then((response) => {
          const localSessionActivity = { ...sessionActivity };
          localSessionActivity[activeSessionId].workspaceEntry =
            response.storyEventSession;
          setSessionActivity(localSessionActivity);
          // switch to display mode
          data.successCallback();
        })
        .catch(() => {
          // show error
          props.setAlert({
            type: 'error',
            message:
              'Sorry, we were unable to save your Next Steps. Please try again later.',
          });
          // hide loading spinner
          data.errorCallback();
        });
    } else {
      StoryApi.editEventSessionEntry({
        storyId: props.participant.story_id,
        storyEventSessionId: data.id,
        nextSteps: data.next_steps,
      })
        .then(() => {
          const localSessionActivity = { ...sessionActivity };
          localSessionActivity[activeSessionId].workspaceEntry.next_steps =
            data.next_steps;
          setSessionActivity(localSessionActivity);
          // switch to display mode
          data.successCallback();
        })
        .catch(() => {
          // show error
          props.setAlert({
            type: 'error',
            message:
              'Sorry, we were unable to save your Next Steps. Please try again later.',
          });
          // hide loading spinner
          data.errorCallback();
        });
    }
  };

  // set up breadcrumbs depending on the current user's roles,
  // and whether this is the participant-facing version or the preview version
  let breadcrumbs;
  const leader = ArrayHelpers.hasElements(props.user.leads)
    ? props.user.leads.find((lead) => !lead.archived)
    : null;
  const groupName =
    props.event.group &&
    (props.event.group.name || `Group ${props.event.group.num}`);
  const group = groupName ? `, ${groupName}` : '';
  if (Utils.isContractCoach.call(lab)) {
    breadcrumbs = [
      {
        text: 'Coach Dashboard',
        link: '/lab/coach/home',
      },
      {
        text: `${props.event.display_name}${group}`,
      },
    ];
  } else if (props.participant && props.participant.user_id === props.user.id) {
    breadcrumbs = [
      {
        text: 'Home',
        link: '/lab/home',
      },
      {
        text: `${props.event.display_name}${group}`,
      },
    ];
  } else if (Utils.isPartnerLeader() && leader) {
    breadcrumbs = [
      {
        text: 'Homepage',
        link: `/lab/leader/${leader.id}/home`,
      },
      {
        text: `${props.event.display_name}`,
        link: `/lab/leader/${leader.id}/event/${props.event.id}`,
      },
      {
        text: `Attendee Event Page${group}`,
      },
    ];
  } else if (props.participant) {
    breadcrumbs = [
      {
        text: 'Home',
        link: `/lab/home/${props.participant.user_id}`,
      },
      {
        text: `${props.event.display_name}${group}`,
      },
    ];
  }

  const activeSessionId = discernActiveSession();
  let sessionIndex = null;
  if (activeSessionId) {
    sessionIndex = Utils.getItemIndexFromArrayOfObjects(
      props.event.sessions,
      'id',
      parseInt(activeSessionId)
    );
  }

  const activeSession = props.event.sessions[sessionIndex];

  let eventAttendeeContent = null;
  if (activeSession) {
    eventAttendeeContent = (
      <EventAttendeeSessionContent
        event={props.event}
        participant={props.participant || null}
        sessionActivity={sessionActivity}
        activeSession={activeSession}
        eventSessionParticipants={eventSessionParticipants}
        user={props.user}
        userHasAdminPermissions={userHasAdminPermissions}
        handleSessionCheckin={handleSessionCheckin}
      >
        <div>
          <EventMaterial
            sessionsLength={props.event.sessions.length}
            session={activeSession}
            materials={props.event.materials}
            participant={props.participant || null}
          />
          <NextSteps
            key={activeSessionId}
            workspaceEntry={sessionActivity[activeSessionId].workspaceEntry}
            participant={props.participant}
            user={props.user}
            submitNextSteps={submitNextSteps}
          />
        </div>
      </EventAttendeeSessionContent>
    );
  }

  let stickySessionsNavNode = null;
  if (props.event.sessions.length > 1) {
    stickySessionsNavNode = (
      <EventAttendeeStickyNav
        timeZone={props.user.time_zone.pytz_timezone}
        sessionsBannerVisible={sessionsBannerVisible}
        activeSession={activeSession || null}
        previousSession={props.event.sessions[sessionIndex - 1] || null}
        nextSession={props.event.sessions[sessionIndex + 1] || null}
        handeSetActiveSession={handeSetActiveSession}
      />
    );
  }

  return (
    <div className="tc__page">
      <Header {...props} />
      <div className="row event__attendee-breadcrumbs">
        {breadcrumbs && (
          <div className="cute-12-desktop">
            <Breadcrumb trail={breadcrumbs} divider=" / " />
          </div>
        )}
      </div>

      <EventDetails event={props.event} activeSession={activeSession || {}} />
      <div className="row flex-row">
        <div
          ref={sessionsContainerRef}
          className="event__attendee-section event__attendee-session"
        >
          <EventSessions
            userHasAdminPermissions={userHasAdminPermissions}
            user={props.user}
            event={props.event}
            sessionActivity={sessionActivity}
            handeSetActiveSession={handeSetActiveSession}
            sessionsContainerRef={sessionsContainerRef}
            activeSessionId={parseInt(activeSessionId)}
          />
        </div>
        <div className="event__attendee-section event__attendee-content">
          {eventAttendeeContent}
        </div>
      </div>
      <EventAdditionalResources />
      {stickySessionsNavNode}
    </div>
  );
};

const ConnectedEventAttendeeContainer = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => (
      <EventAttendeeContainer {...props} setAlert={setAlert} />
    )}
  </HeaderConsumer>
);

export default ConnectedEventAttendeeContainer;
