// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import VisionApi from './../../../../../apis/V4/VisionApi';
import ArtifactForm from './../../../Common/ArtifactUpload/ArtifactForm';
import Capsule from './Capsule';

class MeetingClip extends React.Component {
  /*** STATE MANAGEMENT ***/
  constructor(props) {
    super(props);

    this.state = {
      isPrivate: this.props.isPrivate,
      loading: false,
      errors: {},
      artifact: this.props.data || {},
      storyArtifactId: null,
    };

    // if we are editing an existing artifact, track its story meeting clip id
    if (this.props.data) {
      this.state.storyArtifactId = this.props.data.story_meeting_clip_id;
    }

    this.handleClose = this.handleClose.bind(this);
    this.editMeetingClip = this.editMeetingClip.bind(this);
    this.clearError = this.clearError.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // the store controls some UI elements in the edit form
    const updatedState = {};
    if (
      'isPrivate' in nextProps &&
      nextProps.isPrivate !== this.state.isPrivate
    ) {
      updatedState.isPrivate = nextProps.isPrivate;
    }
    this.setState(updatedState);
  }

  handleClose(e) {
    e.preventDefault();
    this.props.close();
  }

  editMeetingClip(data) {
    data.participantId = this.props.data.participant_id;
    data.meetingClipId = this.props.data.vision_file_id
      ? this.props.data.vision_file_id
      : this.props.data.story_meeting_clip_id;

    // clear errors
    this.setState({
      errors: {},
    });

    // validate form data
    const errors = {};
    if (!data.title) {
      errors.title = 'Please enter a title';
    }
    if (!data.description) {
      errors.description = 'Please enter a description';
    }

    if (Object.keys(errors).length) {
      this.setState({
        errors: errors,
      });
      return;
    }

    // set up form loading UI
    this.setState({
      loading: true,
    });

    // edit the entry data
    VisionApi.editMeetingClipEntry(data)
      .then((response) => {
        // replace the existing capsule if we're editing an existing artifact
        this.props.editEntryCallback(response);
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        this.props.setErrorAlert(
          'Sorry, there was a problem updating the artifact. Please try again later.'
        );
      });
  }

  /****** HELPERS ******/
  clearError(field) {
    const errors = this.state.errors;
    if (field in errors) {
      delete errors[field];
      this.setState({
        errors: errors,
      });
    }
  }

  render() {
    // header text depends on context
    let headerText = 'Edit';
    const artifactTypeText = ' Meeting Clip';
    headerText += artifactTypeText;

    return (
      <Capsule highlight={this.props.highlightActiveEntry}>
        <div className="story__edit-entry-header-row">
          <div className="story__edit-entry-header-text">{headerText}</div>
          <div
            className="tc-x story__edit-entry-close-icon"
            onClick={this.handleClose}
          ></div>
        </div>

        <ArtifactForm
          type={'file'}
          data={this.state.artifact}
          isMeetingClip={true}
          handleFocus={this.clearError}
          toggleIsPrivate={this.props.toggleIsPrivate}
          editArtifact={this.editMeetingClip}
          isPrivate={this.state.isPrivate}
          selectedGoals={this.state.selectedGoals}
          loading={this.state.loading}
          errors={this.state.errors}
          handleClose={this.handleClose}
          editMode={true}
        >
          {this.props.children}
        </ArtifactForm>
      </Capsule>
    );
  }
}

MeetingClip.propTypes = {
  close: PropTypes.func.isRequired,
  editEntryCallback: PropTypes.func.isRequired,
  setErrorAlert: PropTypes.func.isRequired,
  artifactUserId: PropTypes.number,
};

export default MeetingClip;
