// @ts-nocheck FIXME
import React from 'react';
import { Router } from './../../../components/Router';

class MobileChallengeSubHead extends React.Component {
  state = {
    displaySubHead: ' show',
  };

  prevScrollTop = 0;
  mq = null;

  componentDidMount() {
    // measure the rendered page
    this.mq = window.matchMedia('(max-width: 48em)');

    if (this.mq.matches) {
      window.addEventListener('scroll', this.handleWindowScroll);
    }

    // listen for responsive page
    this.mq.addListener(this.setupViewport);
  }

  componentWillUnmount() {
    this.mq.removeListener(this.setupViewport);
    if (this.mq.matches) {
      window.removeEventListener('scroll', this.handleWindowScroll);
    }
  }

  setupViewport = () => {
    if (this.mq.matches) {
      window.addEventListener('scroll', this.handleWindowScroll);
    } else {
      window.removeEventListener('scroll', this.handleWindowScroll);
    }
  };

  handleHeaderNavigation = (url, e) => {
    e.preventDefault();

    // stop the propogation
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    Router.route(url);
  };

  handleWindowScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // display or hide the sub head if scrolling down or up
    this.setState({
      displaySubHead:
        scrollTop > this.prevScrollTop && scrollTop > 0 ? ' hide' : ' show',
    });

    this.prevScrollTop = scrollTop;
  };

  getCategoryUrlBit = () => {
    let categoryUrlBit;

    if (this.props.categories) {
      this.props.categories.forEach(
        function (category) {
          if (category.id == this.props.selectedCategory) {
            categoryUrlBit = category.seo_url;
          }
        }.bind(this)
      );
    }

    return categoryUrlBit;
  };

  render() {
    // If we have a selected meta strategy, show its name and return user to the challenge
    // meta strategies when they click back
    let subHeadCaption;
    let subHeadLink;
    let targetIconNode = (
      <div className="tc__back-caption tc__back-caption--icon show-phone hide-desktop">
        <div className="tc-target-v2"></div>
      </div>
    );
    if (this.props.selectedMetaStrategy) {
      targetIconNode = null;
      subHeadCaption = this.props.selectedMetaStrategy.name;
      subHeadLink =
        '/coaching/lab/strategy-browser/teaching-challenge/' +
        this.props.challenge.id +
        '/' +
        this.props.challenge.seo_url;
    } else {
      const currentCategoryUrlBit = this.getCategoryUrlBit();

      subHeadCaption = this.props.challenge.nickname;
      subHeadLink = '/coaching/lab/strategy-browser-' + currentCategoryUrlBit;
    }

    return (
      <a
        className={
          'tc__mobile-header tc__mobile-sub-head show-phone hide-desktop' +
          this.state.displaySubHead
        }
        href={subHeadLink}
        onClick={this.handleHeaderNavigation.bind(this, subHeadLink)}
      >
        <div className="tc__back cute-2-desktop left">
          <div className="tc__back-arrow tc-arrow"></div>
          {targetIconNode}
        </div>
        <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
          {subHeadCaption}
        </div>
      </a>
    );
  }
}

export default MobileChallengeSubHead;
