// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../../../components/DynamicUtils';
import NavigationLink from './../../../../NavigationLink';

const SelectedParticipantWidget = (props) => {
  if (Utils.isEmpty(props.participant)) {
    return null;
  }

  const arrowNode = null;

  let participantLinkNodes = null;
  if (props.ui.teacherViewModalState) {
    const homeActiveNode =
      props.page === 'Home' ? ' header__admin-icon--active' : '';
    const favoritesActiveNode =
      props.page === 'Favorites' ? ' header__admin-icon--active' : '';
    const profileActiveNode =
      props.page === 'Profile' ? ' header__admin-icon--active' : '';

    participantLinkNodes = (
      <div className="header__admin-link-container">
        <div className="header__admin-participant-name">
          {props.participant.name}&apos;s
        </div>
        <div className="header__admin-icons">
          <span className={'ppd-3-coach__icon' + homeActiveNode}>
            <NavigationLink
              title={props.participant.name + "'s Home"}
              url={
                '/lab/home/' +
                props.participant.blUserId +
                '?from=navigation_widget'
              }
              className="tc__link tc-dashboard"
            ></NavigationLink>
          </span>
          <span className={'ppd-3-coach__icon' + profileActiveNode}>
            <NavigationLink
              title={props.participant.name + "'s Profile"}
              url={
                '/profile/' +
                props.participant.blUserId +
                '?from=navigation_widget'
              }
              className="tc__link tc-profile"
            ></NavigationLink>
          </span>
          <span className={'ppd-3-coach__icon' + favoritesActiveNode}>
            <NavigationLink
              title={props.participant.name + "'s Favorites"}
              url={
                process.env.REACT_APP_TEACHING_URL +
                '/browse/favorites/' +
                props.participant.blUserId +
                '?from=navigation_widget'
              }
              className="tc__link tc-v4-heart-filled"
            ></NavigationLink>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="show-desktop hide-phone">
        {arrowNode}
        {participantLinkNodes}
      </div>

      <div className="hide-desktop show-phone">
        <div className="header__admin-icons">
          <div className="ppd-v3-curation__inline-label">
            {props.participant.name}&apos;s
          </div>
          <div className="ppd-3-coach__icon-container">
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Home"}
                url={
                  '/lab/home/' +
                  props.participant.blUserId +
                  '?from=navigation_widget'
                }
                className="tc__link tc-dashboard"
              >
                <div className="ppd-3-coach__icon-description">Dashboard</div>
              </NavigationLink>
            </span>
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Profile"}
                url={
                  '/profile/' +
                  props.participant.blUserId +
                  '?from=navigation_widget'
                }
                className="tc__link tc-profile"
              >
                <div className="ppd-3-coach__icon-description">Profile</div>
              </NavigationLink>
            </span>
            <span className="ppd-3-coach__icon ppd-3-coach__icon--heart">
              <NavigationLink
                title={props.participant.name + "'s Favorites"}
                url={
                  process.env.REACT_APP_TEACHING_URL +
                  '/browse/favorites/' +
                  props.participant.blUserId +
                  '?from=navigation_widget'
                }
                className="tc__link tc-v4-heart-filled"
              >
                <div className="ppd-3-coach__icon-description">Favorites</div>
              </NavigationLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

SelectedParticipantWidget.propTypes = {
  participant: PropTypes.object,
};

export default SelectedParticipantWidget;
