// @ts-nocheck FIXME
import React from 'react';

import Action from './../../components/Action';

class Layer extends React.Component {
  state = this.props;

  componentWillMount() {
    const component = this;
    Action.create('LayerManager.' + this.props.offset, function (data) {
      component.setState(data);
    });
  }

  render() {
    const Component = this.state.component;
    const Wrapper = this.state.wrapper;

    const className = 'react-layer';
    const id = 'react-layer-' + this.props.offset;
    if (Component == null) {
      return (
        <div className={className} id={id} style={this.state.style}>
          Empty Layer.
        </div>
      );
    }
    if (Wrapper != null) {
      return (
        <Wrapper
          id={id}
          style={this.state.style}
          className="react-layer"
          {...this.state.params}
        >
          <Component {...this.state.params} />
        </Wrapper>
      );
    } else {
      return (
        <Component
          id={id}
          style={this.state.style}
          className="react-layer"
          {...this.state.params}
        />
      );
    }
  }
}

export default Layer;
