// @ts-nocheck FIXME
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import PropTypes from 'prop-types';

const ConfirmDownsizeGroupModal = (props) => {
  let headlineText = 'Change Group Status?';
  let modalText =
    'Once this status applied, it is permanent. This action cannot be undone. ' +
    'For Advanced Events, Group-specific sessions will be removed.';
  // displays error message instead when there are participants in the group
  const hasParticipants =
    props.data.participant_count && props.data.participant_count > 0;
  if (hasParticipants) {
    headlineText = 'Reassign Participants to New Group';
    modalText =
      'Groups cannot be downsized when there are participants still assigned. ' +
      'Please reassign participants and try again.';
  }
  // cancel button is solid blue when there are participants, has blue border otherwise
  const cancelBtnClass = hasParticipants ? '' : ' v5__btn--blue-border';
  return (
    <ActionModal
      headlineText={headlineText}
      close={props.close}
      spaceFromTop={50}
    >
      <div className="v5__body-copy edit-event__modal-row">{modalText}</div>
      <div className="edit-event__modal-btn-row edit-event__modal-btn-row--centered">
        <button className={`v5__btn${cancelBtnClass}`} onClick={props.close}>
          {hasParticipants ? 'OK' : 'Cancel'}
        </button>
        {!hasParticipants && (
          <button
            className="v5__btn edit-event__confirm-status-btn--left-margin"
            onClick={() => props.confirm(props.data)}
          >
            Change Status
          </button>
        )}
      </div>
    </ActionModal>
  );
};

ConfirmDownsizeGroupModal.propTypes = {
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default ConfirmDownsizeGroupModal;
