// @ts-nocheck FIXME
import React from 'react';

class ArtifactComment extends React.Component {
  render() {
    const authorRole = this.props.is_coach ? 'BetterLesson Coach' : 'Teacher';
    return (
      <div className="portfolio__artifact-comment">
        <div className="portfolio__artifact-comment-text">
          {this.props.data}
        </div>
        <div className="portfolio__artifact-comment-author">
          &mdash; {this.props.first_name}&nbsp;{this.props.last_name},{' '}
          {authorRole}
        </div>
      </div>
    );
  }
}

export default ArtifactComment;
