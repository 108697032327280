// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import EventSessionCard from './EventSessionCard';

const EventSessions = (props) => {
  let eventTimeHeadlineCopy =
    props.event.sessions.length > 1 ? 'Sessions' : 'Event Date';
  if (!props.activeSessionId) {
    eventTimeHeadlineCopy = '';
  }
  let eventTimeNode = null;
  if (props.event.sessions.length) {
    eventTimeNode = props.event.sessions.map((session, i) => {
      return (
        <EventSessionCard
          key={`session-${session.id}-${i}`}
          user={props.user}
          partnerLeads={props.event.partner_leads}
          session={session}
          userHasAdminPermissions={props.userHasAdminPermissions}
          eventTimezone={
            props.event.time_zone && props.event.time_zone.pytz_timezone
          }
          handeSetActiveSession={props.handeSetActiveSession}
          sessionActivity={props.sessionActivity[session.id]}
          activeSessionId={props.activeSessionId}
          multiSessionEvent={props.event.sessions.length > 1}
        />
      );
    });
  }
  return (
    <React.Fragment>
      <h4 className="event__attendee-sessions-headline v5__heading-2">
        {eventTimeHeadlineCopy}
      </h4>
      {eventTimeNode}
    </React.Fragment>
  );
};

export default EventSessions;

EventSessionCard.propTypes = {
  event: PropTypes.object,
  user: PropTypes.object,
  handeSetActiveSession: PropTypes.func.isRequired,
  sessionActivity: PropTypes.object.isRequired,
  activeSessionId: PropTypes.number,
};
