// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ArtifactImage from './../../../Common/ArtifactImage';
import CheckArrows from './../../modules/CheckArrows';
import PortfolioEntryReorder from './../../modules/PortfolioEntryReorder';
import ReorderButtons from './../../modules/ReorderButtons';

const VisionArtifactDisplay = (props) => {
  const displayOrder = props.displayOrder;

  // only show the Reorder button when there is more than one artifact
  let reorderBtnNode = null;
  if (displayOrder.length > 1) {
    reorderBtnNode = (
      <div className="row">
        <div className="p-portfolio-management-vision__reorder cute-2-desktop cute-3-desktop-offset left phone-reset">
          <ReorderButtons {...props} />
        </div>
      </div>
    );
  }

  const artifactListNode = (
    <div>
      {reorderBtnNode}

      {displayOrder.map((artifact, i) => {
        let imageOpacity = '';
        let colorAdjustment = '';
        // everything associated with the entry should be grey if it is hidden
        if (props.hiddenEntryIds.indexOf(artifact.id) !== -1) {
          colorAdjustment =
            ' p-portfolio-management-vision__artifact-capsule--grey';
          imageOpacity = ' p-portfolio-management-vision__image-opacity';
        }
        const entryType = artifact.entry_type;
        const title = artifact.title || artifact.connections.name;
        let artifactNotesNode = '';
        if (artifact.notes) {
          artifactNotesNode = (
            <div className="participant__artifact-notes artifact__notes tc__body-copy">
              {artifact.notes}
            </div>
          );
        }
        let artifactPreviewNode;
        if (entryType === 'uploaded_artifact') {
          artifactPreviewNode = (
            <div className={imageOpacity}>
              <div>
                <ArtifactImage
                  preview={artifact.connections.preview}
                  title={artifact.connections.name}
                  containerClass={
                    'participant__artifact__preview-container' + imageOpacity
                  }
                  icon={artifact.connections.icon}
                  imageClass="participant__artifact-image"
                />
              </div>
              <div className="participant__artifact-image-title">
                <span
                  className={
                    'participant-artifact__title artifact__title title' +
                    colorAdjustment
                  }
                >
                  {title}
                </span>
              </div>
            </div>
          );
        } else if (entryType === 'meeting_clip_entry') {
          artifactPreviewNode = (
            <div className={imageOpacity}>
              <div>
                <ArtifactImage
                  preview={artifact.connections.content.preview}
                  title={artifact.connections.name}
                  containerClass={
                    'participant__artifact__preview-container' + imageOpacity
                  }
                  icon={artifact.connections.icon}
                  imageClass="participant__artifact-image"
                />
              </div>
              <div className="participant__artifact-image-title">
                <span className="participant-artifact__title artifact__title title">
                  {title}
                </span>
              </div>
            </div>
          );
        } else {
          artifactPreviewNode = (
            <div className="ckeditor__output">
              <PurifiedHTML content={artifact.data} tagName="span" />
            </div>
          );
        }

        let transformValue = null;

        let moveTargetClass = '';
        if (props.move.target && artifact.id === props.move.target.id) {
          moveTargetClass = ' p-portfolio__movable-entry--target';
          transformValue = props.move.targetMoveTo || null;
        }
        let moveDestinationClass = '';
        if (
          props.move.destination &&
          artifact.id === props.move.destination.id
        ) {
          moveDestinationClass = ' p-portfolio__movable-entry--destination';
          transformValue = props.move.destinationMoveTo || null;
        }

        let animationStyles = {};
        if (transformValue) {
          animationStyles = {
            transform: transformValue,
          };
        }

        return (
          <div
            className={
              'p-portfolio-management-vision__artifact-section row' +
              colorAdjustment
            }
            key={'artifact-' + artifact.id}
          >
            <PortfolioEntryReorder
              entryId={artifact.id}
              page="vision"
              containerId={artifact.id + '-' + i}
              move={props.ui.move}
              completeMove={props.completeMove}
              animateElemPositions={props.animateElemPositions}
            >
              <div className="p-portfolio__movable-entry-container">
                <div
                  style={animationStyles}
                  className={
                    'p-portfolio__movable-entry tc__capsule tc__capsule--time-line participant__artifact-container p-portfolio-management-vision__artiact-capsule' +
                    colorAdjustment +
                    moveTargetClass +
                    moveDestinationClass
                  }
                >
                  <div className="p-portfolio-management-vision__artifact-check">
                    <CheckArrows
                      {...props}
                      entryId={artifact.id}
                      disableUp={i === 0 || !Utils.isEmpty(props.ui.move)}
                      disableDown={
                        i === displayOrder.length - 1 ||
                        !Utils.isEmpty(props.ui.move)
                      }
                    />
                  </div>
                  {artifactPreviewNode}
                  {artifactNotesNode}
                </div>
              </div>
            </PortfolioEntryReorder>
          </div>
        );
      })}
    </div>
  );

  return <div>{artifactListNode}</div>;
};

VisionArtifactDisplay.propTypes = {
  displayOrder: PropTypes.array.isRequired,
  hiddenEntryIds: PropTypes.array.isRequired,
};

export default VisionArtifactDisplay;
