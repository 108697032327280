// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import Analytics from './../../../../components/Analytics';
import ArrayHelpers from './../../../../components/ArrayHelpers';
import { Router } from './../../../../components/Router';

const HeaderSearch = (props) => {
  let searchTerm;
  // wait for the user to stop typing before we fire calls to the server
  let onChangeTimeout;

  // if we are in the context of a participant (i.e. we see the participant widget under the header)
  // then searches we do from here should only show results that are relevant to the participant
  const searchUrl =
    process.env.REACT_APP_TEACHING_URL +
    (props.headerParticipant
      ? `/search/${props.headerParticipant.blUserId}`
      : '/search');

  const doSearch = (e, searchTerm) => {
    if (props.setSalt) {
      // Create a random string to pass to the backend to get random results yet maintain order during pagination
      props.setSalt(Math.random().toString(36).substr(2));
    }

    e.preventDefault();
    props.closeMobileSearch();
    let queryString = `?q=${searchTerm.value}&from=header`;
    if (props.salt) {
      // Only add the salt to the URL if it's set
      queryString = `?q=${searchTerm.value}&salt=${props.salt}&from=header`;
    }

    Router.route(`${searchUrl}${queryString}`);
  };

  const handleSearchSubmit = (e) => {
    doSearch(e, searchTerm);
  };

  // submit on enter
  const handleSearchSubmitOnEnter = (e) => {
    // 13 is the enter keycode
    const code = e.keyCode || e.which;
    if (code === 13) {
      doSearch(e, searchTerm);
    }
  };

  // this method is necessary in order to prevent window click event from closing search bar
  const preventBubbling = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  // focus event
  const handleFocus = (e) => {
    e.preventDefault();
    Analytics.logEvent({
      category: 'search',
      action: 'type',
      label: 'from_header',
    });

    // Attach the click handler to the window
    delayedEventListener(() =>
      window.addEventListener('click', closeAutocomplete)
    );
  };

  const closeAutocomplete = (e) => {
    // Remove the click handler from the window
    window.removeEventListener('click', closeAutocomplete);
    // clear the list
    props.searchAutocomplete('');
  };

  const handleTyping = (e) => {
    clearTimeout(onChangeTimeout);
    // wait for the user to stop typing before we fire calls to the server
    onChangeTimeout = setTimeout(function () {
      props.searchAutocomplete(searchTerm && searchTerm.value.toLowerCase());
    }, 500);
  };

  const handleKeywordSearch = (text) => {
    props.closeMobileSearch();
    Router.route(`${searchUrl}?q=${text}&from=header&autocomplete=1`);
  };

  const handleSubjectSearch = (subject) => {
    props.closeMobileSearch();
    Router.route(`${searchUrl}?subjects=${subject}&from=header&autocomplete=1`);
  };

  const handleCCStandardSearch = (standard) => {
    props.closeMobileSearch();
    Router.route(
      `${searchUrl}?standards=${standard}&from=header&autocomplete=1`
    );
  };

  const handleLearningDomainSearch = (domain) => {
    props.closeMobileSearch();
    Router.route(
      `${searchUrl}?focusAreas=${domain}&types=strategy&from=header&autocomplete=1`
    );
  };

  const handleSpecialtyTagSearch = (specialtyTag) => {
    props.closeMobileSearch();
    Router.route(
      `${searchUrl}?specialtyTags=${specialtyTag}&from=header&autocomplete=1`
    );
  };

  const handlePracticeTagSearch = (practiceTag) => {
    props.closeMobileSearch();
    Router.route(
      `${searchUrl}?practiceTags=${practiceTag}&from=header&autocomplete=1`
    );
  };

  const handleDanielsonComponentSearch = (danielsonComponent) => {
    props.closeMobileSearch();
    Router.route(
      `${searchUrl}?danielsonComponents=${danielsonComponent}&from=header&autocomplete=1`
    );
  };

  // mobile functionality
  const visibleClass = props.mobileSearchOpened
    ? ' header__search-bar--visible'
    : '';

  // show any items in the search autocomplete list
  let searchAutocompleteNode;
  if (ArrayHelpers.hasElements(props.searchAutocompleteResults)) {
    searchAutocompleteNode = (
      <ul className="header__search-autocomplete tc__body-copy">
        {props.searchAutocompleteResults.map((item) => {
          // dropdown menu item UI depends on its type
          let itemNode;
          switch (item.type) {
            case 'cc_standard':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => handleCCStandardSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  <span className="tag">{item.identifier}</span>
                  <span className="description">
                    {item.text.replace('\n', '')}
                  </span>
                </li>
              );
              break;
            case 'tag':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => handleKeywordSearch(item.text)}
                  className="header__search-autocomplete-item"
                >
                  {item.text}
                </li>
              );
              break;
            case 'subject':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => handleSubjectSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  {item.text}
                </li>
              );
              break;
            case 'learning_domain':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => handleLearningDomainSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  <span className="tag">{item.text.replace('\n', '')}</span>
                </li>
              );
              break;
            case 'specialty_tag':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => handleSpecialtyTagSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  {item.text}
                </li>
              );
              break;
            case 'practice_tag':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => handlePracticeTagSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  {item.text}
                </li>
              );
              break;
            case 'danielson_component':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => handleDanielsonComponentSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  <span className="tag">{item.identifier}</span>
                  <span className="description">{item.text}</span>
                </li>
              );
              break;
          }

          return itemNode;
        })}
      </ul>
    );
  }

  return (
    <div className="header__search-container" onClick={preventBubbling}>
      <div
        className="header__search-mobile-btn tc__link tc-search show-phone hide-desktop"
        onClick={props.openMobileSearch}
      ></div>
      <form
        className={'header__search-bar' + visibleClass}
        onSubmit={handleSearchSubmit}
        onKeyPress={handleSearchSubmitOnEnter}
      >
        <input
          type="text"
          ref={(input) => (searchTerm = input)}
          onFocus={handleFocus}
          onChange={handleTyping}
          className="header__search-input tc__input tc__input--custom-select tc__input--custom-select-empty"
          placeholder="Search"
          defaultValue=""
        />

        <button
          className="header__search-button custom-select__single-selected-remove tc__link tc-search"
          onClick={handleSearchSubmit}
        ></button>

        {searchAutocompleteNode}
      </form>
    </div>
  );
};

export default HeaderSearch;

HeaderSearch.propTypes = {
  closeMobileSearch: PropTypes.func.isRequired,
  mobileSearchOpened: PropTypes.bool.isRequired,
  searchAutocompleteResults: PropTypes.array,
  searchAutocomplete: PropTypes.func.isRequired,
  setSalt: PropTypes.func,
  salt: PropTypes.string,
  headerParticipant: PropTypes.object,
};
