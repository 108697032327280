/* eslint-disable no-case-declarations */
// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ScaleCenterImage from './../../../../ScaleCenterImage';
import ArtifactImage from './../../../Common/ArtifactImage';
import Slider from './../../../Common/Slider';

const Entry = (props) => {
  const getStrategyTitleNode = () => {
    return (
      <div className="tc__capsule--unhoverable title">
        {props.story_strategy.title}
      </div>
    );
  };

  // set up the icon + body contents depending on the entry type
  let iconClass = '';
  let bodyNode = null;
  // set up some data used by many entry types
  let notesNode = null;

  switch (props.type) {
    case 'meeting_clip_entry':
      // set up any notes
      if (props.meeting_clip_entry.notes) {
        notesNode = (
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-description">
            {props.meeting_clip_entry.notes}
          </div>
        );
      }
      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span className="outcome-story__entry-headline-text">
              Artifact of Practice
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <ArtifactImage
              preview={props.meeting_clip_entry.connections.content.preview}
              title={props.meeting_clip_entry.connections.name}
              containerClass=""
              icon={props.meeting_clip_entry.connections.icon}
              imageClass="p-portfolio-outcome-story-management__artifact-image"
            />
          </div>
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-title">
            {props.meeting_clip_entry.title}
          </div>
          {notesNode}
        </div>
      );
      break;

    case 'evidence_meeting_clip':
      iconClass = 'tc-leaf';
      // set up any notes
      if (props.meeting_clip_entry.notes) {
        notesNode = (
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-description">
            {props.meeting_clip_entry.notes}
          </div>
        );
      }
      // if there are no indicators, hide this section
      let evidenceMeetingClipHeaderNode = null;
      let evidenceMeetingClipIndicatorNodes = null;
      // list any indicators
      if (props.indicators && props.indicators.length) {
        evidenceMeetingClipHeaderNode = (
          <div className="outcome-story__entry-indicator-label-row">
            <span className="outcome-story__entry-indicator-label">
              You were looking for
            </span>
          </div>
        );
        evidenceMeetingClipIndicatorNodes = props.indicators.map(
          (indicator) => {
            return (
              <div
                key={'indicator-' + indicator.id}
                className="outcome-story__entry-capsule-row outcome-story__entry-capsule-row--last tc__headline-5"
              >
                <span className="outcome-story__entry-indicator-name">
                  {indicator.label}
                </span>
              </div>
            );
          }
        );
      }

      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span className="outcome-story__entry-headline-text">
              Outcome Evidence
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <ArtifactImage
              preview={props.meeting_clip_entry.connections.content.preview}
              title={props.meeting_clip_entry.connections.name}
              containerClass=""
              icon={props.meeting_clip_entry.connections.icon}
              imageClass="p-portfolio-outcome-story-management__artifact-image"
            />
          </div>
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-title">
            {props.meeting_clip_entry.title}
          </div>
          {notesNode}
          {evidenceMeetingClipHeaderNode}
          {evidenceMeetingClipIndicatorNodes}
        </div>
      );
      break;

    case 'meeting_clip_participant_reflection':
      // set up any notes
      if (props.meeting_clip_entry.notes) {
        notesNode = (
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-description">
            {props.meeting_clip_entry.notes}
          </div>
        );
      }
      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span className="outcome-story__entry-headline-text">
              Reflection
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <ArtifactImage
              preview={props.meeting_clip_entry.connections.content.preview}
              title={props.meeting_clip_entry.connections.name}
              containerClass=""
              icon={props.meeting_clip_entry.connections.icon}
              imageClass="p-portfolio-outcome-story-management__artifact-image"
            />
          </div>
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-title">
            {props.meeting_clip_entry.title}
          </div>
          {notesNode}
        </div>
      );
      break;
    case 'meeting_clip_coach_reflection':
      // use participant's current coach if there is one, or default to entry creator
      const meetingClipCoach = props.currentCoach
        ? props.currentCoach
        : props.coach;
      let meetingClipCoachName = null;
      if (meetingClipCoach) {
        meetingClipCoachName =
          meetingClipCoach.first_name + ' ' + meetingClipCoach.last_name;
      }

      // set up default coach photo
      let meetingClipCoachPhotoNode = (
        <div className="outcome-story__user-photo-mask">
          <img
            className="outcome-story__user-photo"
            src="/legacy-images/default-user.svg"
          />
        </div>
      );
      // if the coach photo exists display the coach's image
      if (meetingClipCoach.photo) {
        meetingClipCoachPhotoNode = (
          <ScaleCenterImage
            src={meetingClipCoach.photo}
            className="outcome-story__user-photo"
          />
        );
      }

      // set up any notes
      if (props.meeting_clip_entry.notes) {
        notesNode = (
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-description">
            {props.meeting_clip_entry.notes}
          </div>
        );
      }

      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span className="outcome-story__entry-headline-text">
              Coach Reflection
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <div className="outcome-story__coach-photo">
              {meetingClipCoachPhotoNode}
            </div>
            <div className="outcome-story__coach-name">
              {meetingClipCoachName}
            </div>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <ArtifactImage
              preview={props.meeting_clip_entry.connections.content.preview}
              title={props.meeting_clip_entry.connections.name}
              containerClass=""
              icon={props.meeting_clip_entry.connections.icon}
              imageClass="p-portfolio-outcome-story-management__artifact-image"
            />
          </div>
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-title">
            {props.meeting_clip_entry.title}
          </div>
          {notesNode}
        </div>
      );
      break;

    case 'text_entry':
      bodyNode = (
        <div className="outcome-story__entry--text">
          <div className="outcome-story__entry-headline">
            <span className="outcome-story__entry-headline-text">
              Artifact of Practice
            </span>
          </div>
          <PurifiedHTML
            className="ckeditor__output"
            content={props.text_entry.data}
          />
        </div>
      );
      break;
    case 'evidence_text':
      iconClass = 'tc-leaf';
      // if there are no indicators, hide this section
      let evidenceTextHeaderNode = null;
      let evidenceTextIndicatorNodes = null;
      // list any indicators
      if (props.indicators && props.indicators.length) {
        evidenceTextHeaderNode = (
          <div className="outcome-story__entry-indicator-label-row">
            <span className="outcome-story__entry-indicator-label">
              You were looking for
            </span>
          </div>
        );
        evidenceTextIndicatorNodes = props.indicators.map((indicator) => {
          return (
            <div
              key={'indicator-' + indicator.id}
              className="outcome-story__entry-capsule-row tc__headline-5"
            >
              <span className="outcome-story__entry-indicator-name">
                {indicator.label}
              </span>
            </div>
          );
        });
      }
      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span
              className={
                'outcome-story__mobile-entry-type-icon hide-desktop show-phone-inline ' +
                iconClass
              }
            ></span>
            <span className="outcome-story__entry-headline-text">
              Outcome Evidence
            </span>
          </div>
          <PurifiedHTML
            className="ckeditor__output"
            content={props.text_entry.data}
          />
          {evidenceTextHeaderNode}
          {evidenceTextIndicatorNodes}
        </div>
      );
      break;
    case 'participant_reflection':
      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span
              className={
                'outcome-story__mobile-entry-type-icon hide-desktop show-phone-inline ' +
                iconClass
              }
            ></span>
            <span className="outcome-story__entry-headline-text">
              Reflection
            </span>
          </div>
          <PurifiedHTML
            className="ckeditor__output"
            content={props.text_entry.data}
          />
        </div>
      );
      break;
    case 'coach_reflection':
      iconClass = 'tc-text';
      // use participant's current coach if there is one, or default to entry creator
      const coach = props.currentCoach ? props.currentCoach : props.coach;

      // set up default coach photo
      let coachPhotoNode = (
        <div className="outcome-story__user-photo-mask">
          <img
            className="outcome-story__user-photo"
            src="/legacy-images/default-user.svg"
          />
        </div>
      );
      // if the coach photo exists display the coach's image
      if (coach.photo) {
        coachPhotoNode = (
          <ScaleCenterImage
            src={coach.photo}
            className="outcome-story__user-photo"
          />
        );
      }
      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span
              className={
                'outcome-story__mobile-entry-type-icon hide-desktop show-phone-inline ' +
                iconClass
              }
            ></span>
            <span className="outcome-story__entry-headline-text">
              Coach Reflection
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <div className="outcome-story__coach-photo">{coachPhotoNode}</div>
            <div className="outcome-story__coach-name">
              {coach.first_name + ' ' + coach.last_name}
            </div>
          </div>
          <PurifiedHTML
            className="ckeditor__output"
            content={props.text_entry.data}
          />
        </div>
      );
      break;
    case 'uploaded_artifact':
      // set up any notes
      if (props.artifact.notes) {
        notesNode = (
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-description">
            {props.artifact.notes}
          </div>
        );
      }
      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span className="outcome-story__entry-headline-text">
              Artifact of Practice
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <ArtifactImage
              preview={props.artifact.connections.preview}
              title={props.artifact.connections.name}
              containerClass=""
              icon={props.artifact.connections.icon}
              imageClass="p-portfolio-outcome-story-management__artifact-image"
            />
          </div>
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-title">
            {props.artifact.title}
          </div>
          {notesNode}
        </div>
      );
      break;
    case 'evidence_artifact':
      iconClass = 'tc-leaf';
      // set up any notes
      if (props.artifact.notes) {
        notesNode = (
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-description">
            {props.artifact.notes}
          </div>
        );
      }
      // if there are no indicators, show the default link
      let evidenceArtifactHeaderNode = null;
      let evidenceArtifactIndicatorNodes = null;
      // list any indicators
      if (props.indicators && props.indicators.length) {
        evidenceArtifactHeaderNode = (
          <div className="outcome-story__entry-indicator-label-row">
            <span className="outcome-story__entry-indicator-label">
              You were looking for
            </span>
          </div>
        );
        evidenceArtifactIndicatorNodes = props.indicators.map((indicator) => {
          return (
            <div
              key={'indicator-' + indicator.id}
              className="outcome-story__entry-capsule-row tc__headline-5"
            >
              <span className="outcome-story__entry-indicator-name">
                {indicator.label}
              </span>
            </div>
          );
        });
      }
      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span
              className={
                'outcome-story__mobile-entry-type-icon hide-desktop show-phone-inline ' +
                iconClass
              }
            ></span>
            <span className="outcome-story__entry-headline-text">
              Outcome Evidence
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <ArtifactImage
              preview={props.artifact.connections.preview}
              title={props.artifact.connections.name}
              containerClass=""
              icon={props.artifact.connections.icon}
              imageClass="p-portfolio-outcome-story-management__artifact-image"
            />
          </div>
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-title">
            {props.artifact.title}
          </div>
          {notesNode}
          {evidenceArtifactHeaderNode}
          {evidenceArtifactIndicatorNodes}
        </div>
      );
      break;

    case 'artifact_participant_reflection':
      // set up any notes
      if (props.artifact.notes) {
        notesNode = (
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-description">
            {props.artifact.notes}
          </div>
        );
      }
      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span className="outcome-story__entry-headline-text">
              Reflection
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <ArtifactImage
              preview={props.artifact.connections.preview}
              title={props.artifact.connections.name}
              containerClass=""
              icon={props.artifact.connections.icon}
              imageClass="p-portfolio-outcome-story-management__artifact-image"
            />
          </div>
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-title">
            {props.artifact.title}
          </div>
          {notesNode}
        </div>
      );
      break;
    case 'artifact_coach_reflection':
      // use participant's current coach if there is one, or default to entry creator
      const artifactCoach = props.currentCoach
        ? props.currentCoach
        : props.coach;
      let artifactCoachName = null;
      if (artifactCoach) {
        artifactCoachName =
          artifactCoach.first_name + ' ' + artifactCoach.last_name;
      }

      // set up default coach photo
      let artifactCoachPhotoNode = (
        <div className="outcome-story__user-photo-mask">
          <img
            className="outcome-story__user-photo"
            src="/legacy-images/default-user.svg"
          />
        </div>
      );
      // if the coach photo exists display the coach's image
      if (artifactCoach.photo) {
        artifactCoachPhotoNode = (
          <ScaleCenterImage
            src={artifactCoach.photo}
            className="outcome-story__user-photo"
          />
        );
      }

      // set up any notes
      if (props.artifact.notes) {
        notesNode = (
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-description">
            {props.artifact.notes}
          </div>
        );
      }

      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span className="outcome-story__entry-headline-text">
              Coach Reflection
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            <div className="outcome-story__coach-photo">
              {artifactCoachPhotoNode}
            </div>
            <div className="outcome-story__coach-name">{artifactCoachName}</div>
          </div>

          <div className="outcome-story__entry-capsule-row">
            <ArtifactImage
              preview={props.artifact.connections.preview}
              title={props.artifact.connections.name}
              containerClass=""
              icon={props.artifact.connections.icon}
              imageClass="p-portfolio-outcome-story-management__artifact-image"
            />
          </div>
          <div className="outcome-story__entry-capsule-row outcome-story__entry-capsule-title">
            {props.artifact.title}
          </div>
          {notesNode}
        </div>
      );
      break;

    case 'strategy':
      iconClass = 'tc-compass';
      // set up strategy data
      const strategyId = props.story_strategy.strategy_id;
      // if there are no indicators, show the default link
      let strategyIndicatorsHeaderNode = null;
      let strategyIndicatorsNodes = null;
      if (ArrayHelpers.hasElements(props.indicators)) {
        strategyIndicatorsHeaderNode = (
          <div className="outcome-story__entry-indicator-label-row">
            <span className="outcome-story__entry-indicator-label">
              You were looking for
            </span>
          </div>
        );
        strategyIndicatorsNodes = props.indicators.map((indicator) => {
          return (
            <div
              key={'indicator-' + indicator.id}
              className="outcome-story__entry-capsule-row outcome-story__entry-capsule-row--indicators tc__headline-5"
            >
              <span className="outcome-story__entry-indicator-name">
                {indicator.label}
              </span>
            </div>
          );
        });
      }

      bodyNode = (
        <div className="outcome-story__entry-capsule--strategy">
          <div className="outcome-story__entry-headline">
            <span
              className={
                'outcome-story__mobile-entry-type-icon hide-desktop show-phone-inline ' +
                iconClass
              }
            ></span>
            <span className="outcome-story__entry-headline-text">Strategy</span>
          </div>
          <div className="outcome-story__entry-capsule-row">
            {getStrategyTitleNode(strategyId)}
          </div>
          {strategyIndicatorsHeaderNode}
          {strategyIndicatorsNodes}
        </div>
      );
      break;
    case 'measurement':
      iconClass = 'tc-measurement';
      // set up data based on whether or not this is the baseline entry
      let type;
      let baseline;
      let measurementHeadlineText = '';
      if (props.story_measurement.id === props.baselineMeasurement.id) {
        // The current measurement is the baseline measurement
        type = 'baseline';
        measurementHeadlineText = 'Starting Measurement';
      } else {
        // This is a regular measurement. We need to include the baseline measurement on the slider
        type = 'measurement';
        baseline = props.baselineMeasurement.measurement;
        measurementHeadlineText = 'Progress Measurement';
      }

      bodyNode = (
        <div>
          <div className="outcome-story__entry-headline">
            <span
              className={
                'outcome-story__mobile-entry-type-icon hide-desktop show-phone-inline ' +
                iconClass
              }
            ></span>
            <span className="outcome-story__entry-headline-text">
              {measurementHeadlineText}
            </span>
          </div>
          <div className="outcome-story__entry-capsule-row outcome-story__slider-container clearfix">
            <Slider
              initialValue={props.story_measurement.measurement}
              baseline={baseline}
              inEditMode={false}
              type={type}
            />
          </div>
          <div className="outcome-story__entry-capsule-description">
            {props.story_measurement.note}
          </div>
        </div>
      );
      break;
  }

  let transformValue = null;

  let moveTargetClass = '';
  if (props.move.target && props.id === props.move.target.id) {
    moveTargetClass = ' p-portfolio__movable-entry--target';
    transformValue = props.move.targetMoveTo || null;
  }
  let moveDestinationClass = '';
  if (props.move.destination && props.id === props.move.destination.id) {
    moveDestinationClass = ' p-portfolio__movable-entry--destination';
    transformValue = props.move.destinationMoveTo || null;
  }

  let animationStyles = {};
  if (transformValue) {
    animationStyles = {
      transform: transformValue,
    };
  }

  return (
    <div
      style={animationStyles}
      className={
        'tc__body-copy p-portfolio__movable-entry p-portfolio-outcome-story-management__entry-capsule' +
        moveTargetClass +
        moveDestinationClass
      }
    >
      {props.children}
      {iconClass && (
        <div
          className={
            'outcome-story__entry-type-icon show-desktop hide-phone ' +
            iconClass
          }
        ></div>
      )}
      {bodyNode}
    </div>
  );
};

Entry.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.object,
  currentCoach: PropTypes.object,
};

export default Entry;
