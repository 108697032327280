// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import PropTypes from 'prop-types';

const StatusIcon = (props) => {
  const statusMaps = {
    'No Coach Assigned': {
      icon: 'tc-icon_status-active v5__activity-table--status-icon-slate',
      leftMargin: 0,
    },
    'Temporarily Inactive': {
      icon: 'tc-icon_status-temp-inactive',
      leftMargin: -2,
    },
    'Done with Coaching': {
      icon: 'tc-icon_status-done',
      leftMargin: -2,
    },
    Dropped: {
      icon: 'tc-icon_status-dropped',
      leftMargin: 28,
    },
    Disengaged: {
      icon: 'tc-icon_status-disengaged',
      leftMargin: 20,
    },
  };

  let statusMap;
  let status = props.status;
  // in most contexts, we do not display an icon for the "Active" status
  if (props.displayForActive && status === 'Active') {
    statusMap = {
      icon: 'tc-icon_status-active participant-v5__active-icon-color',
      leftMargin: 34,
    };
  } else {
    if (status === 'Registered') {
      status = 'No Coach Assigned';
    }
    statusMap = statusMaps[status];
  }

  let statusIcon = null;
  if (statusMap) {
    statusIcon = (
      <Tooltip
        tooltip={Utils.formatEngagementStatus(status)}
        className={props.className || 'v5__activity-table--status-icon'}
        offsetLeft={statusMap.leftMargin}
        tipClass="v5__activity-table--status-tooltip"
      >
        <div className={statusMap.icon}>
          <span className="path1"></span>
          <span className="path2"></span>
        </div>
      </Tooltip>
    );
  }

  return statusIcon;
};

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
  displayForActive: PropTypes.bool,
};

export default StatusIcon;
