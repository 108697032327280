// @ts-nocheck FIXME
const Challenges = (props) => {
  let subject = null;

  if (props.subjects.length) {
    subject = props.subjects.map((subject, id) => {
      return (
        <span
          className="portfolio__stats-subject portfolio__stat-highlight"
          key={'subject-' + id}
        >
          {subject}
        </span>
      );
    });
  }

  let subjectsNode = '';
  if (subject) {
    subjectsNode = (
      <div className="portfolio__stats-subheadline">
        in
        <span className="portfolio__stat-subject portfolio__stat-highlight--uppercase">
          {subject}
        </span>
      </div>
    );
  }

  return (
    <div className="portfolio__stats-row portfolio__stats-row--red">
      <div className="portfolio__stat-number">{props.challenges.length}</div>
      <div className="portfolio__stats-data portfolio__stats-data--double">
        <div className="portfolio__stats-headline">Teaching Challenges</div>
        {subjectsNode}
      </div>
    </div>
  );
};

export default Challenges;
