// @ts-nocheck FIXME
// GG: fake component to help Node out when it encounters sessionStorage or localStorage
// during server rendering

('use script');

const Storage = {
  getItem: () => undefined,

  setItem: () => undefined,

  removeItem: () => undefined,
};

export default Storage;
