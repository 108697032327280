// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Utils from './../../../../components/DynamicUtils';
import EngagementRow from './EngagementRow';

const es_percentages = (a, stat) => {
  if (stat === 'engaged') {
    return Utils.toPercent(
      a.engagement_stats.engaged,
      a.engagement_stats.total_engaged
    );
  } else {
    return Utils.toPercent(a.engagement_stats[stat], a.capacity);
  }
};

const EngagementTable = (props) => {
  const [sortedColumn, setSortedColumn] = React.useState('cohort-name');
  const [sortDirection, setSortDirection] = React.useState('asc');

  const sortFunctions = {
    'cohort-name': (a, b) => a.display_name.localeCompare(b.display_name),
    size: (a, b) => a.capacity - b.capacity,
    registered: (a, b) =>
      es_percentages(a, 'registered') - es_percentages(b, 'registered'),
    scheduled: (a, b) =>
      es_percentages(a, 'scheduled') - es_percentages(b, 'scheduled'),
    completed: (a, b) =>
      es_percentages(a, 'completed') - es_percentages(b, 'completed'),
    engaged: (a, b) =>
      es_percentages(a, 'engaged') - es_percentages(b, 'engaged'),
  };

  // Sort order indication arrow
  let sortIndicator = null;

  if (sortDirection === 'asc') {
    props.cohorts.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (sortDirection === 'desc') {
    props.cohorts.sort(sortFunctions[sortedColumn]);
    props.cohorts.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortOrder[sortDirection]);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };
  // get rows of data
  const EngagementRows = props.cohorts.map((cohort) => {
    return <EngagementRow key={`cohort-${cohort.id}`} {...cohort} />;
  });

  return (
    <div className="admin-v5__coach-table">
      <div className="v5__heading-3 partner-lead-v5__engagement-header">
        Engagement by Cohort
      </div>
      <div className="admin-v5__coach-table__header grid-ten__row">
        <div
          className="v5__registered-table-column-name grid-ten__col-3"
          onClick={() => toggleSortDirection('cohort-name')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'cohort-name' ? ' active' : '')
            }
          >
            Cohort
          </span>
          {sortedColumn === 'cohort-name' ? sortIndicator : null}
        </div>
        <div
          className="v5__registered-table-column-name grid-ten__col-2"
          onClick={() => toggleSortDirection('size')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'size' ? ' active' : '')
            }
          >
            Size
          </span>
          {sortedColumn === 'size' ? sortIndicator : null}
        </div>
        <div
          className="v5__registered-table-column-name grid-ten__col-2"
          onClick={() => toggleSortDirection('registered')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'registered' ? ' active' : '')
            }
          >
            Registered
          </span>
          {sortedColumn === 'registered' ? sortIndicator : null}
        </div>
        <div
          className="v5__registered-table-column-name grid-ten__col-2"
          onClick={() => toggleSortDirection('scheduled')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'scheduled' ? ' active' : '')
            }
          >
            Scheduled First Meeting
          </span>
          {sortedColumn === 'scheduled' ? sortIndicator : null}
        </div>
        <div
          className="v5__registered-table-column-name grid-ten__col-2"
          onClick={() => toggleSortDirection('completed')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'completed' ? ' active' : '')
            }
          >
            Completed First Meeting
          </span>
          {sortedColumn === 'completed' ? sortIndicator : null}
        </div>
        <div
          className="v5__registered-table-column-name grid-ten__col-2"
          onClick={() => toggleSortDirection('engaged')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'engaged' ? ' active' : '')
            }
          >
            Scheduled Next Meeting
          </span>
          {sortedColumn === 'engaged' ? sortIndicator : null}
        </div>
      </div>
      {EngagementRows}
    </div>
  );
};

EngagementTable.propTypes = {
  cohorts: PropTypes.array.isRequired,
};

export default EngagementTable;
