import '@legacy-components/styles/tc-css-all.scss';
import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { AuthProvider } from 'react-oidc-context';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import HubSpot from './components/ThirdPartyScripts/HubSpot';
import './index.css';
import './scss/index.scss';
import theme from './utils/MuiTheme';
import { OIDC_CONFIG } from './utils/OidcConfig';

const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];

if (path) {
  history.replaceState({}, '', path);
}

if (location.hostname.startsWith('teaching')) {
  TagManager.initialize({
    gtmId: 'GTM-P4V77GC',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...OIDC_CONFIG}>
      <ThemeProvider theme={theme}>
        <HubSpot />
        <App />
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
