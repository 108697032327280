// @ts-nocheck FIXME
import ResourceApi from '@legacy-components/apis/ResourceApi';
import Analytics from '@legacy-components/components/Analytics';
import Utils from '@legacy-components/components/DynamicUtils';
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import HeaderStore from '@legacy-components/stores/V4/HeaderStore';
import PropTypes from 'prop-types';
import React from 'react';
import FreezeWindowScroll from './../FreezeWindowScroll';
import Details from './modules/Details';
import FullScreen from './modules/FullScreen';
import Preview from './modules/Preview';
import ResponsiveSetup from './modules/ResponsiveSetup';

class ArtifactOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasViablePreview: false,
      mobileDetailsVisible: false,
      activeArtifactPage: 1,
      artifactDownloading: false,
      downloadError: null,
      fullScreenAvailable: false,
    };

    this.inlineResources = null;
    this.checkViablePreview = this.checkViablePreview.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handlePreviousPageClick = this.handlePreviousPageClick.bind(this);
    this.handleNextPageClick = this.handleNextPageClick.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleFullScreenClick = this.handleFullScreenClick.bind(this);
    this.handleOpenInlineLessonFile =
      this.handleOpenInlineLessonFile.bind(this);
    this.mobileHeader = React.createRef();
  }

  componentDidMount() {
    // fullscreen check
    if (WindowHelpers.checkFullScreenEnabled) {
      this.setState({
        fullScreenAvailable: true,
      });
    }

    // resource image check
    this.checkViablePreview();

    // hold on to a reference of all of the inline resources
    this.inlineResources = document.querySelectorAll(
      '.js-show-resource-from-reflection-narrative'
    );
    // loop over inline resources and add event listner
    for (let i = 0; i < this.inlineResources; i++) {
      this.inlineResources[i].addEventListener(
        'click',
        this.handleOpenInlineLessonFile
      );
    }
  }
  componentWillUnmount() {
    // loop over inline resources and remove event listner
    this.inlineResources.forEach((inlineResource) => {
      inlineResource.removeEventListener(
        'click',
        this.handleOpenInlineLessonFile
      );
    });
  }
  checkViablePreview() {
    if (
      !this.props.data.connections.content ||
      !this.props.data.connections.content.preview.length
    ) {
      return;
    }

    const image = new Image();
    image.src = this.props.data.connections.content.preview[0];

    image.onload = function () {
      this.setState({
        hasViablePreview: true,
      });
    }.bind(this);
  }

  handleDownloadClick(e) {
    e.preventDefault();
    // custom strategies should download without inhibition
    if (
      this.props.user &&
      (this.props.mtLesson || this.props.curatedStrategy)
    ) {
      // if the user is not logged in no download and open registration
      // unless the resource is published open in which case allow download
      if (
        !this.props.user.id &&
        (!this.props.publishStatus ||
          (this.props.publishStatus && this.props.publishStatus !== 'open'))
      ) {
        HeaderStore.actionDispatch('openRegisterModal', {
          reason: 'download_attempt',
        });
        return;
      }
      // shouldn't happen but close up loose end
    } else if (!this.props.user) {
      HeaderStore.actionDispatch('openRegisterModal', {
        reason: 'download_attempt',
      });
      return;
    }

    if (this.props.analyticsLabel) {
      const category =
        this.props.analyticsCategory === 'mtp_resource'
          ? this.props.analyticsCategory +
            '_' +
            this.props.data.connections.content.preview_lessonfile_id
          : this.props.analyticsCategory;
      Analytics.logEvent({
        category: category,
        action: 'download',
        label: this.props.analyticsLabel,
      });
    }

    const data = {};

    if (this.props.data.connections.content.preview_lessonfile_id) {
      data['id'] = this.props.data.connections.content.preview_lessonfile_id;
    } else if (this.props.data.connections.download_lessonfile_id) {
      data['id'] = this.props.data.connections.download_lessonfile_id;
    } else {
      data['id'] = null;
    }

    // Handle cases where the lessonfile id is not known
    if (!data['id']) {
      this.setState({
        downloadError:
          'Sorry, we could not download the artifact. Please try again later.',
        artifactDownloading: false,
      });
      return false;
    }

    this.setState({
      artifactDownloading: true,
    });

    ResourceApi.incrementDownloadCount(data)
      .then(() => {
        location.href = this.props.data.connections.download_url;
        this.setState({
          artifactDownloading: false,
        });
      })
      .catch(
        function () {
          this.setState({
            downloadError:
              'Sorry, we could not find the artifact. Please try again later.',
            artifactDownloading: false,
          });
        }.bind(this)
      );
  }

  handlePreviousPageClick(e) {
    e.preventDefault();
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: this.props.analyticsCategory,
        action: 'paginate_previous',
        label: this.props.analyticsLabel,
      });
    }
    this.setState({
      activeArtifactPage: this.state.activeArtifactPage - 1,
    });
  }

  handleNextPageClick(e) {
    e.preventDefault();
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: this.props.analyticsCategory,
        action: 'paginate_next',
        label: this.props.analyticsLabel,
      });
    }
    this.setState({
      activeArtifactPage: this.state.activeArtifactPage + 1,
    });
  }

  showDetails() {
    // if there are no hidden details for mt lessons
    if (this.props.mtLesson) {
      return;
    }

    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: this.props.analyticsCategory,
        action: 'show_mobile_details',
        label: this.props.analyticsLabel,
      });
    }
    this.setState({
      mobileDetailsVisible: true,
    });
  }

  handleBackClick() {
    // close modal details
    if (this.state.mobileDetailsVisible) {
      if (this.props.analyticsLabel) {
        Analytics.logEvent({
          category: this.props.analyticsCategory,
          action: 'hide_mobile_details',
          label: this.props.analyticsLabel,
        });
      }
      this.setState({
        mobileDetailsVisible: false,
      });
      return;
    }

    // details not open so close overlay
    this.props.close();
  }
  handleOpenInlineLessonFile(e) {
    e.preventDefault();

    // get the parent section
    const sectionId = this.props.data.lesson_section_id;

    const urlArray = e.target.href.split('/');
    const importantResourceSegments = {};
    urlArray.forEach((segment) => {
      // if the segment is an resource or reflection
      if (segment === 'resource' || segment === 'reflection') {
        importantResourceSegments.type = segment;
        return;
      }
      // if only numbers this is the id
      if (/^\d+$/.test(segment)) {
        importantResourceSegments.id = segment;
        return;
      }
    });

    const data = {
      importantResourceSegments: importantResourceSegments,
      urlArray: urlArray,
      sectionId: sectionId,
      lessonfiles: this.props.data.lessonfiles,
      href: e.target.href,
    };
    this.props.openInlineResource(data);
  }
  handleFullScreenClick() {
    // Record the analytics event:
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: this.props.analyticsCategory,
        action: 'view_full_screen',
        label: this.props.analyticsLabel,
      });
    }
    // full screen the resource
    const resourceContainer = document.getElementById(
      'artifact-overlay__fullscreen-container'
    );
    Utils.runPrefixMethod(resourceContainer, 'RequestFullScreen');
  }

  render() {
    const description = this.props.data.notes
      ? this.props.data.notes
      : this.props.data.description;
    let title = this.props.data.title;
    if (!this.props.data.title && this.props.data.url) {
      title = this.props.data.url;
    }

    return (
      <div>
        <div
          className="modal__close--artifact"
          onClick={() => this.props.close()}
        >
          <div className="tc-x"></div>
        </div>
        <div
          className="modal__page-overlay modal__page-overlay--artifact-overlay"
          onClick={() => this.props.close()}
        ></div>
        <div className="modal--absolute modal--artifact-overlay">
          <div className="row">
            <div
              className="artifact-overlay__mobile-header cute-12-phone show-phone hide-desktop"
              ref={this.mobileHeader}
            >
              <div className="row">
                <div
                  className="artifact-overlay__back cute-2-phone"
                  onClick={() => this.handleBackClick()}
                >
                  <div className="artifact-overlay__back-arrow tc-arrow"></div>
                  <div className="artifact-overlay__back-caption">Back</div>
                </div>
                <div className="artifact-overlay__mobile-header-title tc__headline-4 cute-8-phone left">
                  {title}
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              'mt-artifact-overlay' +
              (this.state.mobileDetailsVisible ? ' details-visible' : '')
            }
          >
            <Preview
              content={this.props.data.connections.content}
              preview_type={this.props.data.connections.preview_type}
              icon={this.props.data.connections.icon}
              link={this.props.data.connections.link}
              description={this.props.data.description}
              start_time={this.props.data.start_time}
              end_time={this.props.data.end_time}
              hasViablePreview={this.state.hasViablePreview}
              activeArtifactPage={this.state.activeArtifactPage}
              handlePreviousPageClick={this.handlePreviousPageClick}
              handleNextPageClick={this.handleNextPageClick}
            />

            <FullScreen
              content={this.props.data.connections.content}
              hasViablePreview={this.state.hasViablePreview}
              preview_type={this.props.data.connections.preview_type}
              fullScreenAvailable={this.state.fullScreenAvailable}
              activeArtifactPage={this.state.activeArtifactPage}
              handlePreviousPageClick={this.handlePreviousPageClick}
              handleNextPageClick={this.handleNextPageClick}
            />

            <Details
              {...this.props.data}
              hideAuthorPhoto={this.props.data.hideAuthorPhoto}
              hideAddedDate={this.props.hideAddedDate}
              title={title}
              hasViablePreview={this.state.hasViablePreview}
              fullScreenAvailable={this.state.fullScreenAvailable}
              handleFullScreenClick={this.handleFullScreenClick}
              handleDownloadClick={this.handleDownloadClick}
              showDetails={this.showDetails}
              artifactDownloading={this.state.artifactDownloading}
              downloadError={this.state.downloadError}
              mtLesson={this.props.mtLesson}
              description={description}
            />
          </div>
        </div>
        <ResponsiveSetup mobileHeader={this.mobileHeader} />

        <FreezeWindowScroll
          parentModal="modal--artifact-overlay"
          hasViablePreview={this.state.hasViablePreview}
        />
      </div>
    );
  }
}

ArtifactOverlay.propTypes = {
  analyticsCategory: PropTypes.string,
  analyticsLabel: PropTypes.string,
  openInlineResource: PropTypes.func,
  data: PropTypes.object.isRequired,
};

ArtifactOverlay.defaultProps = {
  analyticsCategory: 'artifact',
};

export default ArtifactOverlay;
