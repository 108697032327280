// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';

const TextElem = (props) => {
  return (
    <div className="row">
      <PurifiedHTML
        className="ppp__elem ppp__elem--text cute-10-desktop cute-1-desktop-offset left"
        content={props.data.text}
      />
    </div>
  );
};

export default TextElem;
