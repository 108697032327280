// @ts-nocheck FIXME
import ArtifactOverlay from '@legacy-components/react/V4/Common/ArtifactOverlay/ArtifactOverlay';
import React from 'react';
import ConfirmDisconnectGCal from './ConfirmDisconnectGCal';
import ConfirmDisconnectZoom from './ConfirmDisconnectZoom';

const CoachModals = (props) => {
  /* Modals */
  let modalNode = null;

  if (props.ui.artifactModal.opened) {
    const artifactOverlayData = {
      title: props.ui.cohortName,
      connections: {
        preview_type: 'text',
        content: {
          preview: props.ui.cohortVision,
        },
      },
    };
    modalNode = (
      <div className="v5-artifact-overlay">
        <ArtifactOverlay
          {...props.ui.artifactModal}
          data={artifactOverlayData}
        />
      </div>
    );
  }

  if (props.showConfirmDisconnectZoom) {
    modalNode = (
      <ConfirmDisconnectZoom
        disconnectZoom={props.handleDisconnectZoom}
        close={() => props.setShowConfirmDisconnectZoom(false)}
      />
    );
  }

  if (props.showConfirmDisconnectGCal) {
    modalNode = (
      <ConfirmDisconnectGCal
        hasSyncedGoogleCalendar={props.hasSyncedGoogleCalendar}
        disconnectGCal={props.revokeGoogleCredentials}
        close={() => props.setShowConfirmDisconnectGCal(false)}
      />
    );
  }

  return <React.Fragment>{modalNode}</React.Fragment>;
};

export default CoachModals;
