// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';
import ScaleCenterImage from './../../../ScaleCenterImage';
import AbridgeText from './../../Common/AbridgeText';
import Header from './../modules/Header';

const PortfolioPresentationHome = (props) => {
  // convenience let for participant name
  const name =
    props.participant.user.first_name + ' ' + props.participant.user.last_name;

  // set up default user photo
  let userPhotoNode = (
    <div className="p-portfolio-presn__participant-photo-mask">
      <img
        className="p-portfolio-presn__participant-photo"
        src="/legacy-images/default-user.svg"
      />
    </div>
  );
  // if the user photo exists display the user's image
  if (props.participant.user.photo) {
    userPhotoNode = (
      <ScaleCenterImage
        src={props.participant.user.photo}
        className="p-portfolio-presn__participant-photo"
        noAdjustments={true}
      />
    );
  }

  // set up school name
  const schoolNode = props.participant.institution ? (
    <div className="tc__feature-copy tc__center-text">
      {props.participant.institution.name}
    </div>
  ) : null;

  // set up vision or design summary
  let visionNode = null;
  const visionText = props.participant.vision
    ? props.participant.vision
    : props.participant.design_summary;
  // only show section if there is vision-related text or artifacts
  if (visionText || props.vision_artifact_count) {
    const visionTextNode = visionText ? (
      <div className="p-portfolio-presn__home-vision-text">
        <AbridgeText maxHeight={90} className="tc__body-copy ckeditor__output">
          {visionText}
        </AbridgeText>
      </div>
    ) : null;
    // set up vision or design summary section
    visionNode = (
      <div className="p-portfolio-presn__home-vision">
        <h2 className="tc__headline-2 tc__center-text">My Vision</h2>
        {visionTextNode}
        <div className="tc__center-text p-portfolio-presn__home-vision-link">
          <NavigationLink
            className="tc__link"
            url={
              '/coaching/lab/participant/portfolio/' +
              props.portfolioUuid +
              '/vision?from=ppd3_participant_portfolio_presentation_UI'
            }
          >
            View my Vision
          </NavigationLink>
        </div>
      </div>
    );
  }

  // set up list of outcomes
  const outcomesNode =
    props.outcomes && props.outcomes.length ? (
      <div className="row">
        <div className="cute-8-desktop center-desktop cute-12-phone p-portfolio-presn__home-bottom-section">
          <div className="tc__center-text tc__headline-2 p-portfolio-presn__bottom-section-headline">
            My Work
          </div>
          {props.outcomes.map((outcome) => {
            return (
              <NavigationLink
                key={'outcome-' + outcome.id}
                url={
                  '/coaching/lab/participant/portfolio/' +
                  props.portfolioUuid +
                  '/outcome/' +
                  outcome.id +
                  '?from=ppd3_participant_portfolio_presentation_UI'
                }
                className="p-portfolio-presn__home-outcome-capsule"
              >
                <div
                  className={
                    'p-portfolio-presn__home-outcome-capsule-icon tc-cd-domain-' +
                    outcome.domainIconId
                  }
                ></div>
                <div>{outcome.outcomeTitle}</div>
              </NavigationLink>
            );
          })}
        </div>
      </div>
    ) : null;

  return (
    <div className="">
      <Header
        portfolioUuid={props.portfolioUuid}
        participantName={name}
        user={props.user}
      />

      <div className="p-portfolio-presn__home-top-section">
        <div className="p-portfolio-presn__home-top-section-bkgd"></div>
        <div className="row">
          <div className="cute-8-desktop cute-12-phone center-desktop p-portfolio-presn__home-top-box-container">
            {userPhotoNode}
            <div className="p-portfolio-presn__home-top-box">
              <h1 className="p-portfolio-presn__headline tc__headline-1 tc__center-text">
                {name}
              </h1>
              {schoolNode}
              {visionNode}
            </div>
          </div>
        </div>
      </div>
      {outcomesNode}
    </div>
  );
};

PortfolioPresentationHome.propTypes = {
  portfolioUuid: PropTypes.string.isRequired,
  participant: PropTypes.object.isRequired,
  vision_artifact_count: PropTypes.number,
  outcomes: PropTypes.array,
};

export default PortfolioPresentationHome;
