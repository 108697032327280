// @ts-nocheck FIXME
import PartnerPortfolioToolApi from './../apis/PartnerPortfolioToolApi';
import Analytics from './../components/Analytics';
import Utils from './../components/DynamicUtils';
import Store from './../components/Store';

const store = {
  currentSection: 'COVER',
  portfolio: {},
  sections: [],
  ui: {
    activeSectionNameEditId: null,
    TEACHER_COHORT: {
      currentTeacher: null,
      modalButtonsDisabled: false,
      refreshing: false,
      error: null,
    },
    GROWTH_AREAS: {
      refreshing: false,
      error: null,
    },
    PORTFOLIO: {
      name: {
        editing: false,
      },
    },
  },
  alert: {
    type: null,
    message: null,
    visible: false,
  },
};

/****** PRIVATE FUNCTIONS ******/
const setupFlashAlert = (alert) => {
  store.alert = {
    type: alert.type,
    message: alert.message,
    visible: true,
  };
  let flashMessageTimeout;
  flashMessageTimeout = setTimeout(() => {
    store.clearFlashMessage();
  }, 3000);
};

// counts the # of visible entries in the given section
const calculateVisibleEntriesCountForSection = (section) => {
  if (section.subjects) {
    section.visible_entries_count = section.subjects.reduce((prev, subject) => {
      if (!subject.growth_areas) {
        return prev;
      }
      return (
        prev +
        subject.growth_areas.reduce((pr, area) => {
          if (!area.entries) {
            return pr;
          }
          return (
            pr +
            area.entries.reduce((p, entry) => {
              return entry.visible ? p + 1 : p;
            }, 0)
          );
        }, 0)
      );
    }, 0);
  } else if (section.types) {
    section.visible_entries_count = section.types.reduce((prev, type) => {
      if (!type.entries) {
        return prev;
      }
      return (
        prev +
        type.entries.reduce((pr, entry) => {
          return entry.visible ? pr + 1 : pr;
        }, 0)
      );
    }, 0);
  } else if (section.entries) {
    section.visible_entries_count = section.entries.reduce((prev, entry) => {
      return entry.visible ? prev + 1 : prev;
    }, 0);
  } else {
    section.visible_entries_count = 0;
  }
};

// delete an entry from both front end and db
const deleteEntryFromList = (
  i,
  entries,
  entryId,
  section,
  analyticsElementType
) => {
  return PartnerPortfolioToolApi.deleteEntry({
    portfolioId: store.portfolio.id,
    entryId: entryId,
  })
    .then((response) => {
      // see if we need to update the visible entries count shown in the nav
      if (entries[i].visible) {
        section.visible_entries_count -= 1;
      }

      // remove the entry from the entries list
      entries.splice(i, 1);

      // set up variables for analytics
      let analyticsCategory = store.currentSection.toLowerCase();
      if (analyticsCategory == 'growth_areas') {
        analyticsCategory = 'content_areas';
      }

      // Record the analytics event:
      Analytics.logEvent({
        category: analyticsCategory,
        action: 'remove_' + analyticsElementType,
        label: 'from_pp_admin_view',
      });

      return;
    })
    .catch((response) => {
      setupFlashAlert({
        type: 'error',
        message: response.error
          ? response.error
          : 'Sorry, there was a problem deleting the element. Please try again later.',
      });
    });
};

const countSectionNameLength = () => {
  let sectionNameCharacterCount = 0;
  for (const section of store.sections) {
    sectionNameCharacterCount = sectionNameCharacterCount + section.name.length;
  }
  return sectionNameCharacterCount;
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.portfolio = data.portfolio || {};
  store.sections = Utils.clone(data.portfolio.sections) || [];
  store.currentSection = data.currentSection || 'COVER';
  delete store.portfolio.sections;
  store.saveIcon = 0;

  // don't trigger event
  return false;
};

store.clearFlashMessage = () => {
  store.alert = {
    type: null,
    message: null,
    visible: false,
  };
};

// update the name of the portfolio
store.updatePortfolioName = (name) => {
  store.portfolio.name = name;
  store.ui.PORTFOLIO.name.editing = false;

  // notify the backend
  PartnerPortfolioToolApi.editPortfolio({
    portfolioId: store.portfolio.id,
    name: name,
  });

  Analytics.logEvent({
    category: 'pp',
    action: 'save_rename',
    label: 'from_pp_admin_view',
  });
};

store.togglePortfolioName = () => {
  store.ui.PORTFOLIO.name.editing = !store.ui.PORTFOLIO.name.editing;

  // Record the analytics event:
  const action = store.ui.PORTFOLIO.name.editting ? 'rename' : 'cancel_rename';
  Analytics.logEvent({
    category: 'pp',
    action: action,
    label: 'from_pp_admin_view',
  });
};

// sorting of sections
store.sortSections = (sectionIds) => {
  // always put cover in the first slot
  let coverId;
  store.sections.map((section) => {
    if (section.type == 'COVER') {
      coverId = section.id;
    }
  });
  sectionIds.unshift(coverId);

  // update store
  store.sections = Utils.sortArrayOfObjectsByProperty(
    store.sections,
    sectionIds
  );

  // notify backend
  PartnerPortfolioToolApi.moveSections({
    portfolioId: store.portfolio.id,
    ids: sectionIds,
  });
};

// sorting of teachers
store.sortTeachers = (teacherIds) => {
  // update store
  store.sections.map((section) => {
    if (section.type == 'TEACHER_COHORT') {
      section.teachers = Utils.sortArrayOfObjectsByProperty(
        section.teachers,
        teacherIds
      );
    }
  });

  // notify backend
  PartnerPortfolioToolApi.moveTeachers({
    portfolioId: store.portfolio.id,
    ids: teacherIds,
  });
};

// sorting of subjects
store.sortSubjects = (subjectIds) => {
  // update store
  store.sections.map((section) => {
    if (section.type == 'GROWTH_AREAS') {
      section.subjects = Utils.sortArrayOfObjectsByProperty(
        section.subjects,
        subjectIds
      );
    }
  });

  // notify backend
  PartnerPortfolioToolApi.moveSubjects({
    portfolioId: store.portfolio.id,
    ids: subjectIds,
  });
};

// edit teacher data
store.editTeacher = (data) => {
  // disable buttons in the Note Modal
  store.ui.TEACHER_COHORT.modalButtonsDisabled = true;

  const analyticsTeacherVisibility = data.visible ? 'show' : 'hide';
  let analyticsAction = analyticsTeacherVisibility;

  switch (data.edited) {
    case 'add note':
      analyticsAction = 'add_note';
      break;
    case 'update note':
      analyticsAction = 'update_note';
      break;
  }

  // Record the analytics event:
  Analytics.logEvent({
    category: 'teacher',
    action: analyticsAction,
    label: 'from_pp_admin_view',
  });

  // update teacher data in store
  const section = store.sections.find(
    (section) => section.type == 'TEACHER_COHORT'
  );
  if (section && section.teachers) {
    section.teachers = section.teachers.map((teacher) => {
      if (teacher.id == data.teacherId) {
        // update the teacher count in the nav if necessary
        if ('visible' in data) {
          if (teacher.visible && !data.visible) {
            section.visible_teachers_count -= 1;
          } else if (!teacher.visible && data.visible) {
            section.visible_teachers_count += 1;
          }
        }
        // update the teacher's data with the new data
        Object.assign(teacher, data);
      }
      return teacher;
    });
  }

  // notify backend
  return PartnerPortfolioToolApi.editTeacher({
    portfolioId: store.portfolio.id,
    teacherId: data.teacherId,
    visible: data.visible,
    note: data.note,
  })
    .then((response) => {
      store.ui.TEACHER_COHORT.modalButtonsDisabled = false;
      store.ui.TEACHER_COHORT.currentTeacher = null;
    })
    .catch((response) => {
      store.ui.TEACHER_COHORT.modalButtonsDisabled = false;
      setupFlashAlert({
        type: 'error',
        message: response.error
          ? response.error
          : 'Sorry, there was a problem editing the teacher data. Please try again later.',
      });
    });
};

// sorting free-form entries
store.sortFreeFormEntries = (data) => {
  // sort entries of specific section
  store.sections.map((section, index) => {
    if (section.type != data.section) {
      return;
    }
    store.sections[index].entries = Utils.sortArrayOfObjectsByProperty(
      store.sections[index].entries,
      data.entryIds
    );
  });

  // notify backend
  PartnerPortfolioToolApi.moveEntries({
    portfolioId: store.portfolio.id,
    ids: data.entryIds,
  });
};

// sort entries within growth area
store.sortGrowthAreaEntries = (data) => {
  // sort the elements within a growth area
  store.sections.map((section, sectionIndex) => {
    if (section.type != 'GROWTH_AREAS') {
      return;
    }
    section.subjects.map((subject, subjectIndex) => {
      if (subject.id != data.subjectId) {
        return;
      }
      subject.growth_areas.map((growthArea, growthAreaIndex) => {
        if (growthArea.id != data.growthAreaId) {
          return;
        }
        store.sections[sectionIndex].subjects[subjectIndex].growth_areas[
          growthAreaIndex
        ].entries = Utils.sortArrayOfObjectsByProperty(
          store.sections[sectionIndex].subjects[subjectIndex].growth_areas[
            growthAreaIndex
          ].entries,
          data.entryIds
        );
      });
    });
  });

  // notify the backend
  PartnerPortfolioToolApi.moveEntries({
    portfolioId: store.portfolio.id,
    ids: data.entryIds,
  });
};

// sort entries within spotlight sections
store.sortSpotlightEntries = (data) => {
  // sort elements with in a type
  store.sections.map((section, sectionIndex) => {
    if (section.type != 'SPOTLIGHTS') {
      return;
    }
    section.types.map((type, typeIndex) => {
      if (type.id != data.typeId) {
        return;
      }
      store.sections[sectionIndex].types[typeIndex].entries =
        Utils.sortArrayOfObjectsByProperty(
          store.sections[sectionIndex].types[typeIndex].entries,
          data.entryIds
        );
    });
  });

  // notify the backend
  PartnerPortfolioToolApi.moveEntries({
    portfolioId: store.portfolio.id,
    ids: data.entryIds,
  });
};

// open/close the content area panels in the Growth Areas section
store.toggleSubjectPanel = (subjectId) => {
  const growthAreasSection = store.sections.find(
    (section) => section.type == 'GROWTH_AREAS'
  );

  if (growthAreasSection) {
    const subject = growthAreasSection.subjects.find(
      (subject) => subject.id == subjectId
    );
    if (subject) {
      subject.isExpanded = !subject.isExpanded;
    }
  }
};

// open/close the spotlight type panels in the Spotlights section
store.toggleSpotlightTypePanel = (spotlightTypeId) => {
  const spotlightsSection = store.sections.find(
    (section) => section.type == 'SPOTLIGHTS'
  );
  if (spotlightsSection) {
    const panel = spotlightsSection.types.find(
      (type) => type.id == spotlightTypeId
    );
    if (panel) {
      panel.isExpanded = !panel.isExpanded;
    }
  }
};

// sets section visability and changes icon
store.toggleSectionVisibility = (data) => {
  const index = store.sections.findIndex(
    (section) => section.id === data.sectionId
  );
  data.visible = !data.visible;

  return PartnerPortfolioToolApi.editSection(data).then((response) => {
    // set up analytics category
    let i = 0;
    const sectionNameArray = data.sectionName.split(' ');
    let reconstructedCategory = '';
    for (let string of sectionNameArray) {
      // dont display underscore before first segment
      const seperator = i == 0 ? '' : '_';

      string = Utils.lowercaseFirstLetter(string);
      reconstructedCategory = reconstructedCategory + seperator + string;
      i++;
    }

    // Record the analytics event
    Analytics.logEvent({
      category: reconstructedCategory,
      action: data.visible ? 'show' : 'hide',
      label: 'from_pp_admin_view',
    });

    // Update the section visibility
    if (response.status == 'ok') {
      store.sections[index].visible = response.visible;
      return;
    }
  });
};

// Edits section name
store.editSectionName = (data) => {
  const index = store.sections.findIndex(
    (section) => section.id === data.sectionId
  );

  // update the state to prevent jitter
  const previousName = store.sections[index].name;
  store.sections[index].name = data.sectionName;

  return PartnerPortfolioToolApi.editSection(data)
    .then((response) => {
      // set up analytics category
      let i = 0;
      const sectionNameArray = data.sectionName.split(' ');
      let reconstructedCategory = '';
      for (let string of sectionNameArray) {
        // dont display underscore before first segment
        const seperator = i == 0 ? '' : '_';

        string = Utils.lowercaseFirstLetter(string);
        reconstructedCategory = reconstructedCategory + seperator + string;
        i++;
      }

      // Record the analytics event
      Analytics.logEvent({
        category: reconstructedCategory,
        action: 'rename',
        label: 'from_pp_admin_view',
      });

      // Update the section name
      store.ui.activeSectionNameEditId = null;
      store.sections[index].name = response.name;

      // discern total tab name character count
      store.ui.sectionNameCharacterCount = countSectionNameLength();

      return;
    })
    .catch((response) => {
      setupFlashAlert({
        type: 'error',
        message:
          'Sorry, there was a problem updating the visibility. Please try again later.',
      });
    });
};

store.displaySectionNameEditing = (data) => {
  store.ui.activeSectionNameEditId = data.sectionId;
};

store.hideSectionNameEditing = (data) => {
  store.ui.activeSectionNameEditId = null;
  store.ui.sectionNameCharacterCount = countSectionNameLength();
};

// toggle the visibility of the subject inside the Growth Areas section
store.toggleSubjectVisibility = (data) => {
  if (data.subjectId) {
    // find the section
    const growthAreasSection = store.sections.find(
      (section) => section.type == 'GROWTH_AREAS'
    );

    if (growthAreasSection && growthAreasSection.subjects) {
      // find the subject that the growth area is in
      const subject = growthAreasSection.subjects.find(
        (subject) => subject.id == data.subjectId
      );
      if (subject) {
        // call the API to update the db
        return PartnerPortfolioToolApi.toggleSubjectVisibility({
          portfolioId: store.portfolio.id,
          subjectId: data.subjectId,
          visible: !subject.visible,
        })
          .then((response) => {
            // update the visibility icon
            const visible = !subject.visible;
            subject.visible = visible;

            // update the subjects count in the nav
            if (visible) {
              growthAreasSection.visible_subjects_count += 1;
            } else {
              growthAreasSection.visible_subjects_count -= 1;
            }

            // update the growth areas and entries
            subject.growth_areas.forEach((growthArea) => {
              if (visible && !growthArea.visible) {
                growthAreasSection.visible_growth_areas_count += 1;
              } else if (!visible && growthArea.visible) {
                growthAreasSection.visible_growth_areas_count -= 1;
              }
              growthArea.visible = visible;
              growthArea.entries.forEach((entry) => {
                if (visible && !entry.visible) {
                  growthAreasSection.visible_entries_count += 1;
                } else if (!visible && entry.visible) {
                  growthAreasSection.visible_entries_count -= 1;
                }
                entry.visible = visible;
              });
            });

            return;
          })
          .catch((response) => {
            setupFlashAlert({
              type: 'error',
              message:
                'Sorry, there was a problem updating the visibility. Please try again later.',
            });
          });
      }
    }
  }
};

// toggle the visibility of the growth area inside the Growth Areas section
store.toggleGrowthAreaVisibility = (data) => {
  if (data.subjectId && data.growthAreaId) {
    // find the section
    const growthAreasSection = store.sections.find(
      (section) => section.type == 'GROWTH_AREAS'
    );
    if (growthAreasSection && growthAreasSection.subjects) {
      // find the subject that the growth area is in
      const subject = growthAreasSection.subjects.find(
        (subject) => subject.id == data.subjectId
      );
      if (subject && subject.growth_areas) {
        // find the growth area
        const growthArea = subject.growth_areas.find(
          (area) => area.id == data.growthAreaId
        );
        if (growthArea) {
          // call the API to update the db
          return PartnerPortfolioToolApi.toggleGrowthAreaVisibility({
            portfolioId: store.portfolio.id,
            growthAreaId: data.growthAreaId,
            visible: !growthArea.visible,
          })
            .then((response) => {
              // update the visibility icon
              const visible = !growthArea.visible;
              growthArea.visible = visible;

              // update the growth areas count in the nav
              if (visible) {
                growthAreasSection.visible_growth_areas_count += 1;
              } else {
                growthAreasSection.visible_growth_areas_count -= 1;
              }

              // update entries
              growthArea.entries.forEach((entry) => {
                if (visible && !entry.visible) {
                  growthAreasSection.visible_entries_count += 1;
                } else if (!visible && entry.visible) {
                  growthAreasSection.visible_entries_count -= 1;
                }
                entry.visible = visible;
              });

              return;
            })
            .catch((response) => {
              setupFlashAlert({
                type: 'error',
                message:
                  'Sorry, there was a problem updating the visibility. Please try again later.',
              });
            });
        }
      }
    }
  }
};

// load or refresh the teacher cohort data
store.refreshTeacherCohortSection = (data) => {
  // add portfolioId, needed for endpoint path
  data.portfolioId = store.portfolio.id;

  // let frontend know that the section is refreshing
  store.ui.TEACHER_COHORT.refreshing = true;
  store.ui.TEACHER_COHORT.error = null;

  // let the backend know which section needs to be refreshed
  return PartnerPortfolioToolApi.refreshSection(data).then(
    // success case
    (response) => {
      store.ui.TEACHER_COHORT.refreshing = false;
      store.ui.TEACHER_COHORT.error = response.payload.section.teachers.length
        ? null
        : 'No teachers found.';
      store.sections.map((section, index) => {
        if (section.type == 'TEACHER_COHORT') {
          store.sections[index] = response.payload.section;
        }
      });

      // Record the analytics event:
      Analytics.logEvent({
        category: 'teacher_cohort',
        action: 'refresh_data',
        label: 'from_pp_admin_view',
      });
    },

    // error case
    () => {
      store.ui.TEACHER_COHORT.refreshing = false;
      store.ui.TEACHER_COHORT.error = 'Error refreshing data.';
    }
  );
};

// load or refresh the growth area data
store.refreshGrowthAreaSection = (data) => {
  // add portfolioId, needed for endpoint path
  data.portfolioId = store.portfolio.id;

  // let frontend know that the section is refreshing
  store.ui.GROWTH_AREAS.refreshing = true;
  store.ui.GROWTH_AREAS.error = null;

  // let the backend know which section needs to be refreshed
  return PartnerPortfolioToolApi.refreshSection(data).then(
    // success case
    (response) => {
      store.ui.GROWTH_AREAS.refreshing = false;
      store.ui.GROWTH_AREAS.error = response.payload.section.subjects.length
        ? null
        : 'No data found.';
      store.sections.map((section, index) => {
        if (section.type == 'GROWTH_AREAS') {
          store.sections[index] = response.payload.section;
        }
      });

      // Record the analytics event:
      Analytics.logEvent({
        category: 'growth_areas',
        action: 'refresh_data',
        label: 'from_pp_admin_view',
      });
    },

    // error case
    () => {
      store.ui.GROWTH_AREAS.refreshing = false;
      store.ui.GROWTH_AREAS.error = 'Error refreshing data.';
    }
  );
};

// update the data of an entry, or create a new entry if none match
// (this could be an Element, or some other piece of data like text on the Cover)
// - expects entryData,
// and either entryId or section/subject/growth area/spotlight type ids to identify it
store.editEntry = (data) => {
  // set up variables for analytics
  let analyticsCategory = store.currentSection.toLowerCase();
  if (analyticsCategory == 'growth_areas') {
    analyticsCategory = 'content_areas';
  }

  let analyticsElementType;
  if (data.entryData.type) {
    analyticsElementType = data.entryData.type.toLowerCase();

    switch (analyticsElementType) {
      case 'coachingspotlight':
        analyticsElementType = 'coach_video';
        break;
      case 'spotlight':
      case 'narrativespotlight':
      case 'teacherspotlight':
      case 'strategyspotlight':
      case 'artifactspotlight':
        analyticsElementType = 'link';
        break;
    }
  }

  // if there is no entryId, we are creating or updating a new entry -
  // find the matching entry using the section/subject/growth area/spotlight type info
  if (!data.entryId) {
    if (data.entryData.mode == 'edit') {
      // Record the analytics event:
      Analytics.logEvent({
        category: analyticsCategory,
        action: 'add_' + analyticsElementType,
        label: 'from_pp_admin_view',
      });
    }

    const section = store.sections.find(
      (section) => section.id == data.sectionId
    );
    // entryContainer is the level above the entries list
    let entryContainer = section;

    // if the entry is inside a growth area, search in there
    if (section && data.subjectId && section.subjects && data.growthAreaId) {
      const subject = section.subjects.find(
        (subject) => subject.id == data.subjectId
      );
      entryContainer = subject.growth_areas.find(
        (area) => area.id == data.growthAreaId
      );
      // if the entry is inside a spotlight type panel, search in there
    } else if (section && data.spotlightTypeId && section.types) {
      entryContainer = section.types.find(
        (type) => type.id == data.spotlightTypeId
      );
    }

    if (entryContainer) {
      // if we are adding form data to the entry, then we should first save it to the db
      if (data.entryData && data.entryData.data) {
        // clear any error
        entryContainer.newEntry.error = null;
        // update UI for loading
        entryContainer.newEntry.isLoading = true;
        // save entry
        return PartnerPortfolioToolApi.createEntry({
          portfolioId: store.portfolio.id,
          sectionId: data.sectionId,
          growthAreaId: data.growthAreaId,
          type: entryContainer.newEntry.type,
          data: data.entryData.data,
        })
          .then((response) => {
            // if we have a new entry that will stay inside entryContainer, add it there
            if (response.entry) {
              // get the existing data for the new entry
              const newEntry = entryContainer.newEntry;
              newEntry.id = response.entry.id;
              newEntry.type = response.entry.type;
              newEntry.visible = response.entry.visible;
              newEntry.mode = null;
              newEntry.isLoading = false;
              newEntry.data = response.entry.data;

              // add the new entry to the list of saved entries in the section
              if (entryContainer.entries) {
                entryContainer.entries.push(newEntry);
              } else {
                entryContainer.entries = [newEntry];
              }

              // see if we need to update the visible entries count shown in the nav
              calculateVisibleEntriesCountForSection(section);
            }

            // if we have an entry to add to the Spotlights section,
            // either because it was added there directly or because we made a copy of
            // an entry from another section, add that here
            if (response.spotlightEntry) {
              // find the Spotlights section
              const spotlightsSection = store.sections.find(
                (section) => section.type == 'SPOTLIGHTS'
              );
              if (spotlightsSection) {
                // get the spotlight type panel that the spotlight will be in
                let spotlightType = spotlightsSection.types.find(
                  (type) => type.entry_type == response.spotlightEntry.type
                );

                // if its a new spotlight type, we need to add the panel to the section
                if (response.spotlightType) {
                  spotlightType = response.spotlightType;
                  spotlightsSection.types.push(spotlightType);
                }

                // add entry to the spotlight type panel
                spotlightType.entries.push(response.spotlightEntry);

                // see if we need to update the visible entries count shown in the nav
                calculateVisibleEntriesCountForSection(spotlightsSection);
              }
            }

            // clear the new entry data tied to the section
            entryContainer.newEntry = null;

            Analytics.logEvent({
              category: analyticsCategory,
              action: 'save_' + analyticsElementType,
              label: 'from_pp_admin_view',
            });

            return;
          })
          .catch((response) => {
            if (entryContainer.newEntry) {
              entryContainer.newEntry.isLoading = false;
            }
            // if there was an invalid spotlight url, then show the error in the form
            if (response.error == 'url' && entryContainer.newEntry) {
              entryContainer.newEntry.error =
                'Invalid URL for spotlight element';
              return;
            }
            // otherwise, show the error banner
            setupFlashAlert({
              type: 'error',
              message: response.error
                ? response.error
                : 'Sorry, there was a problem creating the element. Please try again later.',
            });
          });
      } else {
        // combine the existing entry with the new entry info
        entryContainer.newEntry = Object.assign(
          { isLoading: false },
          entryContainer.newEntry,
          data.entryData
        );
      }
    }

    // if there is an entryId, find the matching entry using the entryId
  } else {
    let entry;
    let section;
    for (section of store.sections) {
      if (section.entries) {
        entry = section.entries.find((en) => en.id == data.entryId);
      } else if (section.type == 'GROWTH_AREAS' && section.subjects) {
        for (const subject of section.subjects) {
          if (subject.growth_areas) {
            for (const area of subject.growth_areas) {
              if (area.entries) {
                entry = area.entries.find((en) => en.id == data.entryId);
              }
              if (entry) {
                break;
              }
            }
          }
          if (entry) {
            break;
          }
        }
      } else if (section.type == 'SPOTLIGHTS' && section.types) {
        for (const spotlightType of section.types) {
          if (spotlightType.entries) {
            entry = spotlightType.entries.find((en) => en.id == data.entryId);
          }
          if (entry) {
            break;
          }
        }
      }
      if (entry) {
        break;
      }
    }
    // if we found or created an entry, update its data
    if (entry) {
      // clear any error
      entry.error = null;

      let analyticsAction = 'edit_' + analyticsElementType;
      if (data.entryData.mode == 'update') {
        analyticsAction = 'save_' + analyticsElementType;
      }
      if (!data.entryData.mode) {
        analyticsAction = 'cancel_' + analyticsElementType;
      }

      if (data.entryData.visible !== undefined) {
        analyticsAction = data.entryData.visible ? 'show' : 'hide';
      }

      Analytics.logEvent({
        category: analyticsCategory,
        action: analyticsAction,
        label: 'from_pp_admin_view',
      });

      // some field changes required db changes as well
      const dbUpdateFields = ['visible', 'data'];
      if (
        data.entryData &&
        dbUpdateFields.some((key) => key in data.entryData)
      ) {
        // update UI for loading
        entry.isLoading = true;

        // gather data to pass to API
        const requestData = {
          portfolioId: store.portfolio.id,
          entryId: data.entryId,
        };
        dbUpdateFields.forEach(
          (key) => (requestData[key] = data.entryData[key])
        );

        // save entry
        return PartnerPortfolioToolApi.editEntry(requestData)
          .then((response) => {
            // we may have received a new entry type from the back end,
            // like if the spotlight URL changed, so update the entry data here
            const updatedData = response.entry;
            // after editing an entry, we want to return to the default mode
            updatedData.mode = null;
            // then combine the existing entry with the updated entry info
            Object.assign(entry, updatedData, { isLoading: false });
            // see if we need to update the visible entries count shown in the nav
            calculateVisibleEntriesCountForSection(section);
            return;
          })
          .catch((response) => {
            entry.isLoading = false;
            // if there was an invalid spotlight url, then show the error in the form
            if (response.error == 'url') {
              entry.error = 'Invalid URL for spotlight element';
              return;
            }
            // otherwise, show the error banner
            setupFlashAlert({
              type: 'error',
              message: response.error
                ? response.error
                : 'Sorry, there was a problem updating the element. Please try again later.',
            });
          });

        // but no db changes are necessary for fields like "mode"
      } else {
        // combine the existing entry with the new entry info
        Object.assign(entry, data.entryData);
      }
    }
  }
};

// delete the entry
// (this could be an Element, or some other piece of data like text on the Cover)
// - expects entryData, and either entryId or section/subject/growth area ids to identify it
store.deleteEntry = (data) => {
  let analyticsCategory = store.currentSection.toLowerCase();
  if (analyticsCategory == 'growth_areas') {
    analyticsCategory = 'content_areas';
  }

  let analyticsElementType = data.elementType.toLowerCase();
  switch (analyticsElementType) {
    case 'coachingspotlight':
      analyticsElementType = 'coach_video';
      break;
    case 'spotlight':
    case 'narrativespotlight':
    case 'teacherspotlight':
    case 'strategyspotlight':
    case 'artifactspotlight':
      analyticsElementType = 'link';
      break;
  }

  // if there is no entryId, we are creating or updating a new entry -
  // find the matching entry using the section/subject/growth area info
  if (!data.entryId) {
    let section = store.sections.find(
      (section) => section.id == data.sectionId
    );
    // if the entry is inside a growth area, search in there
    if (section && data.subjectId && section.subjects && data.growthAreaId) {
      const subject = section.subjects.find(
        (subject) => subject.id == data.subjectId
      );
      section = subject.growth_areas.find(
        (area) => area.id == data.growthAreaId
      );
    }
    // if the entry is inside a spotlight type panel, search in there
    if (section && data.spotlightTypeId && section.types) {
      const section = section.types.find(
        (type) => type.id == data.spotlightTypeId
      );
    }
    // if we couldn't find the entry, just exit
    if (!section) {
      return false;
    }
    // remove the entry data, update the store, and return
    section.newEntry = null;

    // Record the analytics event:
    Analytics.logEvent({
      category: analyticsCategory,
      action: 'cancel_' + analyticsElementType,
      label: 'from_pp_admin_view',
    });

    return;

    // if there is an entryId, find the matching entry using the entryId
  } else {
    // look for the entry in each section -
    // if its found, remove it, update the store, and return
    for (const section of store.sections) {
      if (section.entries) {
        for (let i = 0; i < section.entries.length; i++) {
          if (section.entries[i].id == data.entryId) {
            return deleteEntryFromList(
              i,
              section.entries,
              data.entryId,
              section,
              analyticsElementType
            );
          }
        }
      }
      if (section.type == 'GROWTH_AREAS' && section.subjects) {
        for (const subject of section.subjects) {
          if (subject.growth_areas) {
            for (const area of subject.growth_areas) {
              if (area.entries) {
                for (let i = 0; i < area.entries.length; i++) {
                  if (area.entries[i].id == data.entryId) {
                    return deleteEntryFromList(
                      i,
                      area.entries,
                      data.entryId,
                      section,
                      analyticsElementType
                    );
                  }
                }
              }
            }
          }
        }
      }
      if (section.type == 'SPOTLIGHTS' && section.types) {
        for (const spotlightType of section.types) {
          if (spotlightType.entries) {
            for (let i = 0; i < spotlightType.entries.length; i++) {
              if (spotlightType.entries[i].id == data.entryId) {
                return deleteEntryFromList(
                  i,
                  spotlightType.entries,
                  data.entryId,
                  section,
                  analyticsElementType
                );
              }
            }
          }
        }
      }
    }
  }

  // if we are here, then there is nothing to update
  return false;
};

// Toggle Cover Page Saved Icon
store.toggleSaveIcon = (data) => {
  store.saveIcon = data.value;
};

// set the value of the current teacher to show their edit note modal
store.setCurrentTeacher = (teacher) => {
  store.ui.TEACHER_COHORT.currentTeacher = teacher;

  const analyticsAction = teacher ? 'add_note_overlay' : 'cancel_note_overlay';
  Analytics.logEvent({
    category: 'teacher_cohort',
    action: analyticsAction,
    label: 'from_pp_admin_view',
  });
};

export default Store('PartnerPortfolioToolStore', store);
