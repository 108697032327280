// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const MobileArtifactUpload = (props) => {
  let inputFiles = [];
  const files = {};
  let completedFiles = 0;
  let dropzone;
  let filesInput;

  const submitFiles = () => {
    props.submitFiles({
      inputFiles: inputFiles,
    });
  };

  const handleSelectFiles = (e) => {
    // Prevent propagation of the click event
    e.stopPropagation();
    props.handleSelectFiles();
  };

  const handleArtifactsChange = (e) => {
    e.preventDefault();
    inputFiles = e.target.files;
    completedFiles = 0;
    submitFiles();
  };

  // Mobile devices should see the camera option when selecting files
  return (
    <div className="hide-desktop show-phone" key="artifact-upload-mobile">
      <div key="upload" onClick={handleSelectFiles}>
        <form onSubmit={submitFiles}>
          <input
            className="artifact__upload-field-mobile"
            ref={(ref) => (filesInput = ref)}
            type="file"
            onChange={handleArtifactsChange}
            multiple={props.multiple}
            accept="*"
          />
        </form>
      </div>
    </div>
  );
};

MobileArtifactUpload.propTypes = {
  submitFiles: PropTypes.func.isRequired,
  handleSelectFiles: PropTypes.func.isRequired,
  multiple: PropTypes.bool.isRequired,
};

MobileArtifactUpload.defaultProps = {
  multiple: false,
};

export default MobileArtifactUpload;
