// @ts-nocheck FIXME
import ActivityRow from '@legacy-components/react/V5/PartnerLeader/Dashboard/tabs/Common/ActivityRow';
import DomainRow from '@legacy-components/react/V5/PartnerLeader/modules/DomainRow';
import HighlightRow from '@legacy-components/react/V5/PartnerLeader/modules/HighlightRow';
import StrategyRow from '@legacy-components/react/V5/PartnerLeader/modules/StrategyRow';
import PropTypes from 'prop-types';
import React from 'react';

const UpdatesRowContainer = (props) => {
  const [rowExpanded, setRowExpanded] = React.useState(null);

  const updateShow = (openRow) => {
    if (rowExpanded === openRow) {
      setRowExpanded(null);
    } else {
      setRowExpanded(openRow);
    }
  };

  let rowNodes;
  switch (props.section) {
    case 'highlights':
      rowNodes = props.highlight_entries.map((highlight) => {
        return (
          <HighlightRow
            key={`highlight-${highlight.id}`}
            {...highlight}
            openRow={highlight.id === rowExpanded}
            updateShow={updateShow}
          />
        );
      });
      break;
    case 'strategies':
      rowNodes = props.strategies.map((strategy) => {
        return (
          <StrategyRow
            key={`strategy-${strategy.id}`}
            {...strategy}
            openRow={strategy.id === rowExpanded}
            updateShow={updateShow}
          />
        );
      });
      break;
    case 'domains':
      rowNodes = props.total_learning_domains.map((domain) => {
        return (
          <DomainRow
            key={`domain-${domain.id}`}
            {...domain}
            openRow={domain.id === rowExpanded}
            updateShow={updateShow}
          />
        );
      });
      break;
    case 'participants':
      rowNodes = props.participants.map((participant) => {
        return (
          <ActivityRow
            key={`event-${participant.engagement.id}`}
            {...participant}
            openRow={participant.id === rowExpanded}
            updateShow={updateShow}
            reportingPhases={props.reportingPhases}
            currentYear={props.currentYear}
          />
        );
      });
  }

  return rowNodes;
};

UpdatesRowContainer.propTypes = {
  highlight_entries: PropTypes.array,
  reportingPhases: PropTypes.object,
  total_learning_domains: PropTypes.array,
  strategies: PropTypes.array,
};

export default UpdatesRowContainer;
