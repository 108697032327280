// @ts-nocheck FIXME
import { useState } from 'react';
import StoryApi from './../../../../../../../apis/V4/StoryApi';
import ArrayHelpers from './../../../../../../../components/ArrayHelpers';
import Utils from './../../../../../../../components/DynamicUtils';
import Video from './../../../../../../Utilities/Video';
import FormField from './../../../../../Common/FormField';
import Spinner from './../../../../../Common/Spinner';
import EntryTags from './../EntryTags';

const MeetingClipEntryForm = (props) => {
  const [title, setTitle] = useState(props.titleInput || '');
  const [description, setDescription] = useState(props.textInput || '');
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleFocus = (field) => {
    // clear any field error
    if (errors[field]) {
      const newErrors = Utils.clone(errors);
      delete newErrors[field];
      setErrors(newErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // first, clear any form errors
    setErrors({});

    // validation
    const formErrors = {};
    if (!title) {
      formErrors.title = 'Hold on, you need to enter a title to continue.';
    }
    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);

    // build API data
    const data = {
      storyId: props.activeStoryId,
      storyArtifactId: props.entryId,
      description: description,
      title: title,
      goals: props.currentGoals,
    };

    StoryApi.editMeetingClipEntry(data)
      .then((response) => {
        props.editEntryCallback(response.entry);
        props.close();
      })
      .catch(() => {
        props.setErrorAlert();
        setLoading(false);
      });
  };

  // show meeting clip video
  let videoNode;
  if (
    props.connections &&
    props.connections.content &&
    ArrayHelpers.hasElements(props.connections.content.formats)
  ) {
    videoNode = (
      <div className="workspace__entry-video-container">
        <Video
          preview={props.connections.content.preview[0]}
          formats={props.connections.content.formats}
          height={220}
          width={300}
        />
      </div>
    );
  }

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  // only show the delete icon when editing an entry, not when creating a new entry
  let deleteIconNode;
  if (props.entryId && props.confirmDelete) {
    deleteIconNode = (
      <span
        onClick={props.confirmDelete}
        className="tc-delete workspace__delete-icon"
      ></span>
    );
  }

  return (
    <form>
      <div className="v5__heading-5 workspace__entry-header-row workspace__entry-header-row--editing">
        <div className="tc-v4-calendar workspace__entry-header-icon"></div>
        <div className="workspace__entry-header-text">
          <FormField
            type="text"
            placeholder="Title"
            defaultValue={props.titleInput}
            autoFocus={true}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={() => handleFocus('title')}
            error={errors.title}
          />
        </div>
      </div>

      <div className="workspace__entry-body">
        <div className="workspace__entry-image-row">
          {videoNode}

          <FormField
            type="textarea"
            rows="6"
            placeholder="Describe this meeting clip. What is its significance to your growth?"
            defaultValue={props.textInput}
            onChange={(e) => setDescription(e.target.value)}
            className="workspace__entry-image-description"
          />
        </div>

        <div className="workspace__entry-footer">
          {props.goals && (
            <EntryTags
              goals={props.goals}
              selectedGoalIds={props.currentGoals}
              canEdit={!props.isEntryHighlighted}
              toggleGoal={props.toggleGoalTag}
              storyId={props.activeStoryId}
              entryId={props.entryId}
              tagGoalResponseCallback={props.tagGoalResponseCallback}
              untagGoalResponseCallback={props.untagGoalResponseCallback}
            />
          )}

          <div className="workspace__btns-row">
            <button
              type="button"
              className="v5__btn v5__btn--blue-border workspace__cancel-link"
              onClick={props.close}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="v5__btn"
              {...btnProps}
              onClick={handleSubmit}
            >
              <Spinner loading={isLoading} />
              Save
            </button>
            {deleteIconNode}
          </div>
        </div>
      </div>
    </form>
  );
};

export default MeetingClipEntryForm;
