// @ts-nocheck FIXME
import WindowHelpers from './WindowHelpers';

function getOnLoadForRoute(path) {
  // split by # first
  let pathArray = path.split('#');
  // get the first part and split by ? next
  pathArray = pathArray[0].split('?');
  // get route path
  const routePath = pathArray[0];

  for (const route in Router.routes) {
    const re = new RegExp('^' + route + '$', 'm');
    if (re.exec(routePath)) {
      return Router.routes[route];
    }
  }
}

// TODO wlu: fix the empty methods

const History = {
  push: function (state, title, url) {
    // popstate doesn't fire when pushing to history
    if (!window) {
      return;
    }
    window.history.pushState(state, title, url);
  },

  replace: function (state, title, url) {
    // popstate doesn't fire when replacing history
    if (!window) {
      return;
    }
    window.history.replaceState(state, title, url);
  },
};

const Router = {
  routes: [],
  currentPath: '',

  onStart: () => undefined,
  onSuccess: () => undefined,
  onError: () => undefined,
  onProgress: () => undefined,

  getCurrentPath: () =>
    window.location.pathname + window.location.search + window.location.hash,

  init: function (config, isNode = false) {
    //routes, loader, onStartCallback, onSuccessCallback, onErrorCallback, onProgressCallback) {
    this.routes = config.routes;
    this.loader = config.loader;
    this.onStart = config.onStart;
    this.onSuccess = config.onSuccess;
    this.onError = config.onError;
    this.onProgress = config.onProgress;

    if (!isNode) {
      // pop state event for handling back/forward navigation
      window.addEventListener(
        'popstate',
        function onStateChange(e) {
          // don't fire if just the hash has changed
          const newCurrentPath = Router.getCurrentPath();
          const newPath = newCurrentPath.split('#')[0];
          const oldPath = Router.currentPath.split('#')[0];
          if (newPath == oldPath) {
            return;
          }

          // route to new path
          Router.route(newCurrentPath, false);
        },
        false
      );
    }
  },

  route: function (path) {
    if (window.onbeforeunload) {
      if (
        !confirm(
          'Do you want to leave this page?\n\nChanges you made may not be saved.'
        )
      ) {
        return;
      }
      WindowHelpers.navigationConfirmation(false);
    }

    location.assign(path);
  },

  load: function (path, data, push = true) {
    // set the Router's current path
    this.currentPath = path;

    // get onLoad function for the route by its path
    const onLoad = getOnLoadForRoute(path);

    // if onLoad exists and there is data, execute the onLoad
    // @TODO might not always be the case that data is needed for onLoad to execute
    if (onLoad && data) {
      onLoad(data, push);
    }
  },
};

export { History, Router };
