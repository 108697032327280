// @ts-nocheck FIXME
import NProgress from 'nprogress';
import StrategyApi from './../../apis/StrategyApi';
import Utils from './../../components/DynamicUtils';
import Store from './../../components/Store';

const store = Store('BrowserSGAStoreV2', {
  // strategy browser
  activeCategory: 244,
  menuOpened: true,
  selectedChallenge: null,
  // browser sga
  activeSGAId: null,

  // challenge strategies
  alert: null,
  alertType: '',
  loading: false,
  numResults: 0,
  numNewResults: 0,
  gradeIds: [],
  filterLowerElementary: false,
  filterUpperElementary: false,
  filterMiddleSchool: false,
  filterHighSchool: false,
  mobileFiltersVisible: false,

  // search strategies
  searchBarOpened: false,
  offset: 0,
  searchResults: [],
});

// ACTIONS
store.actionCreate('init', function (data) {
  store.activeCategory = data.selectedCategory;
  store.menuOpened = true;
  store.selectedChallenge = data.selectedChallenge || null;
  store.activeSGAId = null;

  store.numResults = data.numResults || 0;
  store.numNewResults = data.numNewResults || 0;
  store.gradeIds = data.gradeIds || [];
  store.filterLowerElementary = data.filterLowerElementary || false;
  store.filterUpperElementary = data.filterUpperElementary || false;
  store.filterMiddleSchool = data.filterMiddleSchool || false;
  store.filterHighSchool = data.filterHighSchool || false;
  store.mobileFiltersVisible = data.mobileFiltersVisible || false;

  store.searchBarOpened = data.searchBarOpened || false;
  store.offset = data.offset || 0;
  store.searchResults = data.strategies || [];
});

// change the active subject tab
store.actionCreate('selectCategory', function (category) {
  store.activeCategory = category;
  store.searchBarOpened = false;
});

// open menu with specifc menu by id
store.actionCreate('openSGAMenu', function (sga_id) {
  store.activeSGAId = sga_id;
});

// close menu
store.actionCreate('closeSGAMenu', function (sga_id) {
  store.activeSGAId = null;
});

// set the alert banner
store.actionCreate('setAlert', function (data) {
  store.alert = data.alert;
  store.alertType = data.alertType;
});

// toggle the pin for the user + strategy
store.actionCreate('toggleStrategyPin', function (strategyId) {
  const strategies = Utils.clone(store.searchResults);
  // let strategy = strategies.find((strategy) => strategy.id == strategyId);

  let strategy = null;
  for (let i = 0; strategies.length > i; i++) {
    if (strategies[i].id == strategyId) {
      strategy = strategies[i];
    }
  }
  if (strategy) {
    strategy.pinned = !strategy.pinned;
    store.searchResults = strategies;
  }
});

// add a note to a strategy
store.actionCreate('addNote', function (data) {
  const strategies = Utils.clone(store.searchResults);

  // let strategy = strategies.find((strategy) => strategy.id == data.strategyId);
  let strategy = null;
  for (let i = 0; strategies.length > i; i++) {
    if (strategies[i].id == data.strategyId) {
      strategy = strategies[i];
    }
  }

  if (strategy) {
    strategy.pinned = true;
    if ('notes' in strategy) {
      strategy.notes.push(data.note);
    } else {
      strategy.notes = [data.note];
    }
    store.searchResults = strategies;
  }
});

// remove a note from a strategy
store.actionCreate('removeNote', function (noteId) {});

// show/hide the mobile grade filters
store.actionCreate('toggleMobileFilters', function () {
  store.mobileFiltersVisible = !store.mobileFiltersVisible;
});

// data needs to have: filter, challengeId and optionally metaStrategyId
store.actionCreate('toggleGradeFilter', function (data) {
  // update the filter state right away to update the checkbox
  store[data.filter] = !store[data.filter];

  // start progress bar
  NProgress.start();

  // Every filter should search for the individual grade ids as well as the parent grade ids:
  let gradeIds = [];
  if (store.filterLowerElementary) {
    gradeIds = gradeIds.concat(12, 13, 14, 3, 9);
  }
  if (store.filterUpperElementary) {
    gradeIds = gradeIds.concat(15, 16, 17, 5, 9);
  }
  if (store.filterMiddleSchool) {
    gradeIds = gradeIds.concat(17, 18, 19, 20, 6, 9);
  }
  if (store.filterHighSchool) {
    gradeIds = gradeIds.concat(21, 22, 23, 24, 7, 9);
  }

  // Add metaStrategyId to the searchData if we have it:
  const metaStrategyId = data.metaStrategyId ? data.metaStrategyId : null;

  // run a search with the filters and replace the current strategies
  const searchData = {
    challengeId: data.challengeId,
    gradeIds: gradeIds,
    metaStrategyId: metaStrategyId,
    updateSavedFilters: true,
  };

  StrategyApi.getStrategiesV2(searchData).then(
    function (response) {
      store.actionDispatchSync('saveSearchResults', response.page_data);
      // close progress bar
      NProgress.done();
    },
    function (xhr, status, error) {
      // close progress bar
      NProgress.done();
    },
    function (e) {
      // update progress bar
      NProgress.set(e.loaded / e.total);
    }
  );
});

// open/close the search bar
store.actionCreate('toggleSearchBar', function () {
  store.searchBarOpened = !store.searchBarOpened;
  if (store.searchBarOpened) {
    // dissable page scrolling if mobile navigation is opened
    Utils.addClass(document.body, 'no-scroll');
    return;
  }
  Utils.removeClass(document.body, 'no-scroll');
});

store.actionCreate('startLoadingResults', function () {
  store.loading = true;
});

// replace any existing results with new ones
store.actionCreate('saveSearchResults', function (results) {
  store.searchResults = results.strategies || [];
  store.gradeIds = results.gradeIds || [];
  store.offset = 0;
  store.numResults = results.numResults;
  store.numNewResults = results.numNewResults;
});

// add the given results to the end of any existing ones
store.actionCreate('addSearchResults', function (results) {
  store.searchResults = store.searchResults.concat(results.strategies);
  store.offset += results.strategies.length;
  store.loading = false;
});

export default store;
