// @ts-nocheck FIXME
import Spinner from '@legacy-components/react/V3/Common/Spinner';
import PropTypes from 'prop-types';

const UploadArtifactModalEditForm = (props) => {
  let title;

  const handleFocus = (field) => {
    if (props.handleFocus) {
      props.handleFocus(field);
    }
  };

  const handleSubmit = (e, submitCaption) => {
    e.preventDefault();

    const data = {
      id: props.file.id,
      title: title.value,
      description: '',
      submitType: submitCaption,
    };
    props.editArtifact(data);
  };

  // set up submit button
  const submitCaption = props.isNew ? 'Add' : 'Update';
  const buttonDisabledClass = props.loading ? ' disabled' : '';

  // set up any errors on the title
  let titleErrorClass = '';
  let titleErrorNode = null;
  if (props.errors && props.errors.title) {
    titleErrorClass = ' error';
    titleErrorNode = <div className="tc__error">{props.errors.title}</div>;
  }

  // set up any errors that pertain to the whole form
  let errorAlertNode = null;
  if (props.errors && props.errors.api) {
    errorAlertNode = (
      <div className="outcome-story__modal-error-alert-row">
        <span className="outcome-story__modal-error-alert">
          {props.errors.api}
        </span>
      </div>
    );
  }

  return (
    <div key="artifact-edit">
      <form className="artifact__upload-details-form" onSubmit={handleSubmit}>
        <div className="participant__artifact-edit-form">
          <div className="cohort-dash__update-edit-form-row">
            <label className="participant__artifact-edit-label">Title</label>
            <input
              type="text"
              className={`tc__input cohort-dash__update-edit-form-input${titleErrorClass}`}
              ref={(ref) => (title = ref)}
              defaultValue={props.file.title}
              placeholder="Add a title to the artifact"
              onFocus={() => handleFocus('title')}
            />
            {titleErrorNode}
          </div>

          {errorAlertNode}
          <div className="partiacipant-artifact__delete-container">
            <div className="participant-artifact__uplaod-buttons tc__center-text">
              <a
                className={`tc__btn tc__btn--v3 tc__btn--flat-blue tc__btn--no-margin${buttonDisabledClass}`}
                onClick={(e) => {
                  handleSubmit(e, submitCaption);
                }}
              >
                {submitCaption}
                <Spinner loading={props.loading} />
              </a>
              <a
                className="tc__btn tc__btn--flat-grey"
                onClick={props.handleCloseModal}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

UploadArtifactModalEditForm.propTypes = {
  isNew: PropTypes.bool,
  file: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.object,
  handleFocus: PropTypes.func.isRequired,
  editArtifact: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default UploadArtifactModalEditForm;
