// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const FilterTag = (props) => {
  return (
    <div className="cohort-v5__filter-tag">
      <div>{props.filterTitle}</div>
      <div className="tc-x" onClick={props.filterRemove} />
    </div>
  );
};

FilterTag.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  filterRemove: PropTypes.func.isRequired,
};

export default FilterTag;
