// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../components/ArrayHelpers';
import DomainCapsule from './modules/DomainCapsule';

const Domains = (props) => {
  // domains are divided into two lists based on id
  let teacherDomainsNode, coachDomainsNode, partnerDomainsNode;

  if (ArrayHelpers.hasElements(props.teacherDomains)) {
    teacherDomainsNode = (
      <div className="row row--1200">
        <div className="cute-12-desktop">
          <h2 className="domains__section-headline">
            Professional Learning Domains for Classroom Teachers
          </h2>
        </div>

        <div className="cute-12-desktop">
          <div className="domains__capsules">
            {props.teacherDomains.map((domain) => {
              return <DomainCapsule key={'domain-' + domain.id} {...domain} />;
            })}
          </div>
        </div>
      </div>
    );
  }

  if (ArrayHelpers.hasElements(props.coachDomains)) {
    coachDomainsNode = (
      <div className="row row--1200">
        <div className="cute-12-desktop">
          <h2 className="domains__section-headline">
            Professional Learning Domains for Leaders and Instructional Coaches
          </h2>
        </div>

        <div className="cute-12-desktop">
          <div className="domains__capsules">
            {props.coachDomains.map((domain) => {
              return <DomainCapsule key={'domain-' + domain.id} {...domain} />;
            })}
          </div>
        </div>
      </div>
    );
  }
  if (ArrayHelpers.hasElements(props.partnerDomains)) {
    partnerDomainsNode = (
      <div className="row row--1200">
        <div className="cute-12-desktop">
          <h2 className="domains__section-headline">
            Professional Learning Domains from Our Partners
          </h2>
        </div>

        <div className="cute-12-desktop">
          <div className="domains__capsules">
            {props.partnerDomains.map((domain) => {
              return <DomainCapsule key={'domain-' + domain.id} {...domain} />;
            })}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="tc__page domains">
      <Header page="Domains" {...props} hideWidget={true} />

      <div className="row row--1200">
        <div className="cute-10-desktop center-desktop cute-12-phone">
          <h1 className="tc__headline-2 domains__headline tc__center-text">
            BetterLesson Professional Learning Domains
          </h1>
          <div className="tc__body-copy">
            BetterLesson’s Professional Learning Domains are focused areas of
            growth designed to reflect the key elements of learner-centered
            environments. Each learning domain contains a collection of
            high-impact strategies and targeted goals for growth.
          </div>
        </div>
      </div>

      {teacherDomainsNode}
      {coachDomainsNode}
      {partnerDomainsNode}
    </div>
  );
};

Domains.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object,
  teacherDomains: PropTypes.array,
  coachDomains: PropTypes.array,
};

export default Domains;
