// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const EventRow = (props) => {
  const [rowExpanded, setRowExpanded] = React.useState(false);
  let datesNode = [];
  const attendeesMap = {};
  let attendeesNode = [];
  const sessionsByGroup = {};

  // Since there are 2 entirely separate cases where we do the same logic
  // I encapsulated this logic in a function
  const matchingDatesNodes = () => {
    const numSet = new Set();
    // We should only show a list number if the event has more than 1 session in a row
    // To determine this we check if there is more than 1 session listed
    // And more sessions than groups
    const countClass =
      props.sessions.length > 1 && props.sessions.length > props.groups.length
        ? 'partner-lead_session-row'
        : 'partner-lead_session-row-single';
    props.sessions.forEach((session) => {
      if (!session.group_id || !numSet.has(session.session_num)) {
        const sessionDate = session.session_date_formatted
          ? moment(session.session_date_formatted).format('MMMM D, YYYY')
          : 'TBA';
        datesNode.push(<li className={countClass}>{sessionDate}</li>);
        numSet.add(session.session_num);
      }
      if (
        !Object.prototype.hasOwnProperty.call(attendeesMap, session.session_num)
      ) {
        attendeesMap[session.session_num] = 0;
      }
      attendeesMap[session.session_num] += session.participant_count;
    });
    const attendeesKeys = Object.keys(attendeesMap);
    attendeesKeys.sort();
    attendeesKeys.forEach((session_num) => {
      attendeesNode.push(
        <div key={`session-${props.id}-${session_num}`}>
          {attendeesMap[parseInt(session_num)]}
        </div>
      );
    });
  };

  let hideNode;
  if (props.advanced_event) {
    const sessionDates = {};
    const sessionCounts = {};
    // For advanced events if every group has the same number of sessions
    // And all the sessions have the same dates then we use the same logic as basic events
    // Otherwise there is complex logic involved
    let matchingDates = true;
    props.sessions.forEach((session) => {
      const sessionNum = session.session_num;
      const sessionGroupId = session.group_id;
      if (Object.prototype.hasOwnProperty.call(sessionDates, sessionNum)) {
        if (session.session_date_formatted !== sessionDates[sessionNum]) {
          matchingDates = false;
        }
      } else {
        sessionDates[sessionNum] = session.session_date_formatted;
      }
      if (Object.prototype.hasOwnProperty.call(sessionCounts, sessionGroupId)) {
        sessionCounts[sessionGroupId]++;
      } else {
        sessionCounts[sessionGroupId] = 1;
      }
      if (session.group_id in sessionsByGroup) {
        sessionsByGroup[session.group_id].push(session);
      } else {
        sessionsByGroup[session.group_id] = [session];
      }
    });

    const sessionCountSet = new Set();
    Object.keys(sessionCounts).forEach((groupID) =>
      sessionCountSet.add(sessionCounts[groupID])
    );
    matchingDates = matchingDates && sessionCountSet.size === 1;

    if (matchingDates) {
      matchingDatesNodes();
    } else {
      datesNode = (
        <div
          className="v5__link clickable"
          onClick={() => setRowExpanded(!rowExpanded)}
        >
          Custom*
        </div>
      );
      attendeesNode = (
        <div
          className="v5__link clickable"
          onClick={() => setRowExpanded(!rowExpanded)}
        >
          Custom*
        </div>
      );
      hideNode = (
        <div
          className="partner-lead__event-table-row-hide clickable"
          onClick={() => setRowExpanded(!rowExpanded)}
        >
          <span className={'tc-v5-expand-collapse-outline'}></span>
        </div>
      );
    }
  } else {
    matchingDatesNodes();
  }
  let expandedNode;
  if (rowExpanded && props.advanced_event) {
    expandedNode = rowExpanded && props.advanced_event && (
      <div className="grid-big__row expanded admin-v5__pl-event-expanded">
        {props.groups.map((group, i) => {
          const groupName = group.name;
          const countClass =
            sessionsByGroup[group.id].length > 1
              ? 'partner-lead_session-row'
              : 'partner-lead_session-row-single';
          return (
            <div
              key={`group-${group.id}`}
              className="grid-big__col-10--start-v flex-col thin"
            >
              <span className="admin-table__expanded-session-header truncate">
                <div className="admin-v5__expanded-session-header-left">
                  {groupName || `${i + 1}`}
                </div>
                <div className="admin-v5__expanded-session-header-right">
                  Attend.
                </div>
              </span>
              <div className={'admin-table__expanded-session-row'}>
                {sessionsByGroup[group.id].map((session, i) => {
                  const sessionDate = session.session_date_formatted
                    ? moment(session.session_date_formatted).format(
                        'MMMM D, YYYY'
                      )
                    : 'TBA';
                  return (
                    <React.Fragment key={i}>
                      <li className={countClass}>{sessionDate}</li>
                      <div>{session.participant_count || '0'}</div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
    hideNode = (
      <div
        className="partner-lead__event-table-row-hide clickable"
        onClick={() => setRowExpanded(!rowExpanded)}
      >
        <span className={'tc-v5-expand-collapse-outline expanded'}></span>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="cute-100 partner_lead_v5__cohort-table-row admin-v5__pl-event-table-row">
        <div className="cute-3-desktop">
          <NavigationLink
            className="v5__link"
            url={`/lab/leader/${props.leadId}/event/${props.id}`}
          >
            {props.display_name ? props.display_name : 'Untitled Event'}
          </NavigationLink>
        </div>

        <div className="cute-1-desktop">
          {props.minSessionCount === props.maxSessionCount
            ? props.maxSessionCount
            : `Up to ${props.maxSessionCount}`}
        </div>

        <div className="cute-2-desktop">{props.attendee_count}</div>

        <div className="cute-4-desktop partner-leader__dates-column">
          {datesNode}
        </div>

        <div className="cute-1-desktop">{attendeesNode}</div>

        <div className="cute-1-desktop">
          {props.content_link && (
            <Tooltip
              tooltip="Event Report"
              delay={1}
              offsetLeft={21}
              offsetTop={35}
              minWidth={100}
              inline={true}
            >
              <a
                className="partner-lead__event-table-content-link"
                href={props.content_link}
                target="_blank"
                rel="noreferrer"
              >
                <span className="tc-presentation partner-lead__event-table-content-link-icon"></span>
              </a>
            </Tooltip>
          )}
        </div>

        {hideNode}
      </div>
      {expandedNode}
    </React.Fragment>
  );
};

EventRow.propTypes = {
  id: PropTypes.number.isRequired,
  display_name: PropTypes.string.isRequired,
  minSessionCount: PropTypes.number.isRequired,
  maxSessionCount: PropTypes.number.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  reservation_count: PropTypes.number.isRequired,
  participant_count: PropTypes.number.isRequired,
  attendee_count: PropTypes.number.isRequired,
  sessions: PropTypes.array.isRequired,
  advanced_event: PropTypes.number.isRequired,
  content_link: PropTypes.string,
};

export default EventRow;
