// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ArrayHelpers from './../../../../../../components/ArrayHelpers';
import Utils from './../../../../../../components/DynamicUtils';
import MultiSelect from './../../../../Common/MultiSelect/MultiSelectContainer';
import Tooltip from './../../../../Common/Tooltip';

const EntryTags = (props) => {
  const lab = useLegacyLabContext();
  const [isEditing, setIsEditing] = useState(false);

  // handle selecting a goal while editing the entry
  const handleSelectOption = (selectedGoal) => {
    // if the entry is not in edit mode, then we just add/remove goal tags individually
    if (props.toggleGoal) {
      props.toggleGoal(selectedGoal.id);
      return;
    }

    // otherwise, we need to update the edit entry form with the latest list of goal tags
    const selectedGoalIds = Utils.clone(props.selectedGoalIds);

    // Check if the goal is already in the goals selected for the entry
    const index = selectedGoalIds.findIndex((goalId) => {
      return goalId === selectedGoal.id;
    });

    // If the goal is already selected for the entry then remove it, otherwise add it
    if (index !== -1) {
      // Remove the goal if it's already there
      selectedGoalIds.splice(index, 1);
    } else {
      // add newly selected goal id
      selectedGoalIds.push(selectedGoal.id);
    }
    // update the entry with the new list of goals
    props.updateTempGoalIds(selectedGoalIds);
  };

  // add the label prop for the multiselect
  const goals = props.goals.map((goal) => {
    goal.label = goal.title;
    return goal;
  });
  // userGoals include *all* of the user's participant's goals,
  // so we can diplay goal tags for older entries
  const userGoals = props.goals
    ? props.goals.map((goal) => {
        goal.label = goal.title;
        return goal;
      })
    : [];

  // convert the goal ids into goals so they can be displayed properly
  const displayedGoalTags = props.selectedGoalIds
    .map((goalId) => {
      return userGoals.find((goal) => {
        return goal.id === goalId;
      });
    })
    .filter((goal) => !!goal);

  // by default, display the list of goal tags
  const entryHasGoalTags = ArrayHelpers.hasElements(displayedGoalTags);
  let goalsClass = 'workspace__tag-group';
  let tagIcon =
    entryHasGoalTags || props.canEdit ? (
      <div className="tc-v5-tag-outline workspace__tag-icon"></div>
    ) : null;
  let goalsNode = displayedGoalTags.map((goal) => {
    return (
      <Tooltip key={goal.id} tooltip={goal.title}>
        <div className="v5__goal-tag workspace__tag">
          {goal.title}
          {props.canEdit && (
            <div
              className="tc-x workspace__filter-tag-x"
              onClick={() => props.toggleGoal(goal.id)}
            ></div>
          )}
        </div>
      </Tooltip>
    );
  });
  let btnNode;
  // if we are editing the list, show the multiselect
  if (isEditing) {
    goalsClass = '';
    tagIcon = null;

    // convert the goal ids into goals so they can be displayed properly
    const goalTags = props.selectedGoalIds
      .map((goalId) => {
        return goals.find((goal) => {
          return goal.id === goalId;
        });
      })
      .filter((goal) => !!goal);

    const isCohortFocusAreaGoal = (goal) => {
      if (
        !lab.pageData.cohort_focus_areas ||
        !lab.pageData.cohort_focus_areas.length
      ) {
        return true;
      }

      if (
        (lab.pageData.domains || []).some(
          (domain) => domain.label === 'ALL Focus Areas'
        )
      ) {
        return true;
      }

      const focusAreaId = goal.focus_area.id;
      return lab.pageData.cohort_focus_areas.includes(focusAreaId);
    };

    // update the dropdown options by removing selected options from the available options
    let dropdownOptions = goals.filter((goal) => isCohortFocusAreaGoal(goal));
    if (props.selectedGoalIds.length > 0) {
      dropdownOptions = dropdownOptions.filter((goal) => {
        return props.selectedGoalIds.every((selectedGoal) => {
          return goal.id !== selectedGoal.id;
        });
      });
    }

    goalsNode = (
      <MultiSelect
        handleSelectOption={handleSelectOption}
        selectedOptions={goalTags}
        dropdownOptions={dropdownOptions}
        showDescription={false}
        minCharCountForResults={0}
        maximumSelectedOptionsCount={1}
        closeOptions={() => setIsEditing(false)}
      />
    );
    // reset to tooltip from line 66 once a selection is made, code written in a way so as to only apply for highlights modal
    goalsNode = !(props.openedFromModal && props.selectedGoalIds.length)
      ? goalsNode
      : displayedGoalTags.map((goal) => {
          return (
            <Tooltip key={goal.id} tooltip={goal.title}>
              <div className="v5__goal-tag workspace__tag">
                {goal.title}
                {props.canEdit && (
                  <div
                    className="tc-x workspace__filter-tag-x"
                    onClick={() => props.toggleGoal(goal.id)}
                  ></div>
                )}
              </div>
            </Tooltip>
          );
        });
  }
  // if we are not editing and the entry does not have a tag yet,
  // if the user is allowed to edit, then show the "Add a Tag" button
  else if (props.canEdit && !entryHasGoalTags) {
    // if there are no goals, disable the "Add a Tag" button
    const btnProps = ArrayHelpers.hasElements(goals) ? {} : { disabled: true };
    const requiredFieldClass = props.openedFromModal
      ? ' workspace__required-fields'
      : '';
    btnNode = (
      <button
        className={`v5__link workspace__add-tag-link${requiredFieldClass}`}
        onClick={() => setIsEditing(true)}
        {...btnProps}
      >
        Add a Tag
      </button>
    );
  }

  return (
    <div className={`${goalsClass}`}>
      {tagIcon}
      {goalsNode}
      {btnNode}
    </div>
  );
};

EntryTags.propTypes = {
  goals: PropTypes.array.isRequired,
  userGoals: PropTypes.array,
  selectedGoalIds: PropTypes.array.isRequired,
  updateTempGoalIds: PropTypes.func,
  toggleGoal: PropTypes.func,
  canEdit: PropTypes.bool,
  openedFromModal: PropTypes.bool,
};

EntryTags.defaultProps = {
  selectedGoalIds: [],
};

export default EntryTags;
