// @ts-nocheck FIXME
import ScaleCenterImage from './../../../ScaleCenterImage';
import Challenges from './Stats/Challenges';
import Hours from './Stats/Hours';
import StudentsImpacted from './Stats/StudentsImpacted';

const Stats = (props) => {
  const userImageNode = props.photo ? (
    <ScaleCenterImage
      src={props.photo}
      className="portfolio__stats-user-image"
    />
  ) : null;

  // only show the PD Term text if the user has provided the text
  let termNode = null;
  if (props.pdYear) {
    termNode = (
      <div className="portfolio__stats-user-dates">
        Personalized PD Term: {props.pdYear}
      </div>
    );
  }

  const challengesNode = props.challenges ? <Challenges {...props} /> : null;

  const hoursNode = props.hours ? <Hours {...props.hours} /> : null;

  // set up visual students impacted icons
  const studentsImpactedNode = <StudentsImpacted {...props} />;

  let coachMeetingCountNode = null;
  if (props.coachMeetings) {
    coachMeetingCountNode = (
      <div className="portfolio__stats-row portfolio__stats-row--green">
        <div className="portfolio__stat-number">{props.coachMeetings}</div>
        <div className="portfolio__stats-data">
          <div className="portfolio__stats-headline">Coach Meetings</div>
        </div>
      </div>
    );
  }

  let strategyCountNode = null;
  if (props.strategyCount) {
    strategyCountNode = (
      <div className="portfolio__stats-row portfolio__stats-row--blue">
        <div className="portfolio__stat-number">{props.strategyCount}</div>
        <div className="portfolio__stats-data">
          <div className="portfolio__stats-headline">Strategies Tried</div>
        </div>
      </div>
    );
  }

  let danielsonsNode = null;
  let danielsonStandard = null;
  if (props.danielsonComponents.length) {
    danielsonStandard = props.danielsonComponents.map((standard, id) => {
      return (
        <span
          className="portfolio__stats-standard portfolio__stat-highlight"
          key={'danielson-' + id}
        >
          {standard}
        </span>
      );
    });
    danielsonsNode = (
      <div className="portfolio__stats-row portfolio__stats-row--blue">
        <div className="portfolio__stat-number">
          {props.danielsonComponents.length}
        </div>
        <div className="portfolio__stats-data portfolio__stats-data--double">
          <div className="portfolio__stats-headline">Danielson Components</div>
          <div className="portfolio__stats-subheadline">
            {danielsonStandard}
          </div>
        </div>
      </div>
    );
  }

  let coreStandardsNode = null;
  let coreStandard = null;
  if (props.coreStandards.length) {
    coreStandard = props.coreStandards.map((standard, id) => {
      return (
        <span
          className="portfolio__stats-standard portfolio__stat-highlight"
          key={'core-' + id}
        >
          {standard}
        </span>
      );
    });
    coreStandardsNode = (
      <div className="portfolio__stats-row portfolio__stats-row--green">
        <div className="portfolio__stat-number">
          {props.coreStandards.length}
        </div>
        <div className="portfolio__stats-data portfolio__stats-data--double">
          <div className="portfolio__stats-headline">Common Core</div>
          <div className="portfolio__stats-subheadline">{coreStandard}</div>
        </div>
      </div>
    );
  }

  // Next Gen Science Standards are listed separately from other core standards
  let ngssNode = null;
  if (props.ngss && props.ngss.length) {
    const ngss = props.ngss.map((standard, id) => {
      return (
        <span
          className="portfolio__stats-standard portfolio__stat-highlight"
          key={'ngss-' + id}
        >
          {standard}
        </span>
      );
    });
    ngssNode = (
      <div className="portfolio__stats-row portfolio__stats-row--green">
        <div className="portfolio__stat-number">{props.ngss.length}</div>
        <div className="portfolio__stats-data portfolio__stats-data--double">
          <div className="portfolio__stats-headline">Next Gen Science</div>
          <div className="portfolio__stats-subheadline">{ngss}</div>
        </div>
      </div>
    );
  }

  let artifactCountNode = null;
  if (props.strategyCount) {
    artifactCountNode = (
      <div className="portfolio__stats-row portfolio__stats-row--red">
        <div className="portfolio__stat-number">{props.artifacts}</div>
        <div className="portfolio__stats-data">
          <div className="portfolio__stats-headline">Classroom Artifacts</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="portfolio__intro">
        <div className="portfolio__section-headline portfolio__section-headline--intro">
          Highlights
        </div>
      </div>
      <div className="portfolio__stats">
        <div className="portfolio__stats-user">
          {userImageNode}
          <div className="portfolio__stats-user-name">{props.name}</div>
          {termNode}
        </div>
        {challengesNode}
        {strategyCountNode}
        {coachMeetingCountNode}
        {hoursNode}
        {danielsonsNode}
        {coreStandardsNode}
        {ngssNode}
        {artifactCountNode}
        {studentsImpactedNode}
      </div>
      <hr className="page-break" />
    </div>
  );
};

export default Stats;
