// @ts-nocheck FIXME
import Request from './../../components/Request';

const StoryApi = {
  addCuratedStrategyEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/curated-strategy/add',
      JSON.stringify(data)
    );
  },

  editCuratedStrategyEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/curated-strategy/' +
        data.storyCuratedStrategyId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addLessonEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/lesson/add',
      JSON.stringify(data)
    );
  },

  editLessonEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/lesson/' +
        data.storyLessonId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addCustomStrategyEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/custom-strategy/add',
      JSON.stringify(data)
    );
  },

  editCustomStrategyEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/custom-strategy/' +
        data.storyCustomStrategyId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addTextEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/text-entry/add',
      JSON.stringify(data)
    );
  },

  editTextEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/text-entry/' +
        data.storyTextEntryId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addFileEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/file/add',
      JSON.stringify(data)
    );
  },

  addLinkEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/link/add',
      JSON.stringify(data)
    );
  },

  editArtifactEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/artifact/' +
        data.storyArtifactId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addMeetingClipEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/clip/add',
      JSON.stringify(data)
    );
  },

  editMeetingClipEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/clip/' +
        data.storyArtifactId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addEventSessionEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/event-session/add',
      JSON.stringify(data)
    );
  },

  editEventSessionEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/event-session/' +
        data.storyEventSessionId +
        '/edit',
      JSON.stringify(data)
    );
  },

  deleteEntry: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/story/' +
        data.storyId +
        '/entry/' +
        data.entryId +
        '/delete',
      JSON.stringify(data)
    );
  },
};

export default StoryApi;
