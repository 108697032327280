// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import dateformat from 'dateformat';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import TimeAgo from 'react-timeago';
import ArtifactApi from './../../apis/ArtifactApi';
import StrategyApi from './../../apis/StrategyApi';
import Analytics from './../../components/Analytics';
import Content from './../../components/Content';
import LayerManager from './../../components/LayerManager';
import Utils from './../../components/StaticUtils';
import CommentStore from './../../stores/CommentStore';
import TmlLoopStore from './../../stores/V2/TmlLoopStore';
import NavigationLink from './../NavigationLink';
import ScaleCenterImage from './../ScaleCenterImage';
import Video from './../Utilities/Video';

class ArtifactOverlay extends React.Component {
  static contextType = LegacyLabContext;

  constructor(props, context) {
    super(props, context);

    // initialize comment store if this is a TC artifact
    if (
      this.context.pageData.user.id &&
      props.artifact.user &&
      !props.noComments
    ) {
      setTimeout(
        function () {
          ArtifactApi.getTcComments(props.artifact.id).then(function (
            response
          ) {
            CommentStore.actionDispatch('init', response.payload.comments);
          });
        }.bind(this),
        250
      );
    }

    this.state = {
      activePage: 1,
      fullscreenLeft: 0,
      fullscreenMarginLeft: 0,
      fullscreenMarginTop: 0,
      fullscreenHeight: 'auto',
      fullscreenWidth: 'auto',
      fullscreenDimensionsSet: false,
      commentButtonVisible: false,
      comments: [],
      comment: '',
      commentError: null,
      downloadError: null,
      downloading: false,
      viablePreview: false,
      mobileDetailsVisible: false,
      responsiveLayout: false,
      artifactContainerHeight: 'auto',
      btnDisabledClass: '',
      fullScreenAvailable: false,
    };
  }

  commentToken = null;
  initalizingOverlay = false;
  componentMounted = false;
  mq = false;
  scrollPosition = null;

  onStoreChange = () => {
    this.setState(CommentStore.getState());
  };

  checkFullscreenSupport = () => {
    const docElm = document.documentElement;
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    }
  };

  handleBackClick = () => {
    if (this.state.mobileDetailsVisible) {
      this.setState({
        mobileDetailsVisible: false,
      });
      return;
    }

    // dissable page scrolling if mobile navigation is opened
    Utils.removeClass(document.body, 'no-scroll');

    window.scrollTo(0, this.scrollPosition);

    // Record the Analytics event:
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'artifact',
        action: 'close',
        label: this.props.analyticsLabel,
      });
    }

    LayerManager.close(this.props.closeUrl);
  };

  handleArtifactImageClick = () => {
    if (!this.mq.matches || !this.checkFullscreenSupport) {
      return;
    }

    this.handleFullScreenClick();
  };

  handleFullScreenClick = () => {
    // Record the analytics event:
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'artifact',
        action: 'view_full_screen',
        label: this.props.analyticsLabel,
      });
    }
    // full screen the resource
    const resourceContainer = ReactDOM.findDOMNode(
      this.refs.fullscreenContainer
    );
    Utils.runPrefixMethod(resourceContainer, 'RequestFullScreen');
  };

  handleDownloadClick = () => {
    // Reset any download errors:
    this.setState({
      downloadError: null,
    });

    // If there is no download url, show an error:
    if (!this.props.artifact.download_url) {
      this.setState({
        downloadError: 'An error occurred. Please try again!',
      });
      return;
    }

    // Record the analytics event:
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'artifact',
        action: 'download',
        label: this.props.analyticsLabel,
      });
    }

    this.setState({
      downloading: true,
    });

    if (this.props.artifact.user) {
      // Increment the count in the DB
      ArtifactApi.postTcArtifactDownload(this.props.artifact.id)
        .then(
          function () {
            this.setState({
              downloading: false,
            });
            // Trigger the download
            location.href = this.props.artifact.download_url;
          }.bind(this)
        )
        .catch(
          function () {
            this.setState({
              downloadError: 'An error occurred. Please try again!',
              downloading: false,
            });
          }.bind(this)
        );
    } else {
      // Increment the count in the DB
      ArtifactApi.postArtifactDownload(
        this.props.artifact.strategy_implementation_id,
        this.props.artifact.id
      )
        .then(
          function () {
            this.setState({
              downloading: false,
            });
            // Trigger the download
            location.href = this.props.artifact.download_url;
          }.bind(this)
        )
        .catch(
          function () {
            this.setState({
              downloading: false,
              downloadError: 'An error occurred. Please try again!',
            });
          }.bind(this)
        );
    }
  };

  handlePreviousPageClick = () => {
    if (this.state.activePage > 1) {
      // Record the analytics event:
      if (this.props.analyticsLabel) {
        Analytics.logEvent({
          category: 'artifact',
          action: 'previous_preview',
          label: this.props.analyticsLabel,
        });
      }
      this.setState({
        activePage: this.state.activePage - 1,
      });
    }
  };

  handleNextPageClick = () => {
    if (
      this.state.activePage < this.props.artifact.content.preview.length &&
      this.state.activePage < 25
    ) {
      // Record the analytics event:
      if (this.props.analyticsLabel) {
        Analytics.logEvent({
          category: 'artifact',
          action: 'next_preview',
          label: this.props.analyticsLabel,
        });
      }
      this.setState({
        activePage: this.state.activePage + 1,
      });
    }
  };

  handleShowSubmit = (e) => {
    e.preventDefault();
    this.scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;
    this.setState({
      commentButtonVisible: true,
    });
  };

  handleSubmitComment = (e) => {
    e.preventDefault();

    this.scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;

    Analytics.logEvent({
      category: 'artifact',
      action: 'post_comment',
      label: this.props.analyticsLabel,
    });

    this.setState({
      commentError: null,
      btnDisabledClass: ' disabled',
    });
    if (this.state.comment === '') {
      this.setState({
        commentError: 'Comment cannot be blank',
        btnDisabledClass: '',
      });
      return;
    }
    const data = {
      share: 1,
      data: this.state.comment,
      artifact_id: this.props.artifact.id,
    };
    ArtifactApi.postTcComment(data)
      .then(
        function (response) {
          CommentStore.actionDispatchSync('add', response.payload.comment);
          TmlLoopStore.actionDispatchSync(
            'artifactCommentIncrement',
            this.props.artifact.id
          );
          this.setState({
            comment: '',
            btnDisabledClass: '',
          });
        }.bind(this)
      )
      .catch(
        function () {
          this.setState({
            commentError:
              'Sorry, there was a problem posting your comment. Please try again later.',
            btnDisabledClass: '',
          });
        }.bind(this)
      );
  };

  handleCommentChange = (e) => {
    e.preventDefault();
    this.setState({ comment: e.target.value });
  };

  navigateToFullLesson = (e) => {
    e.preventDefault();

    // Record the analytics event:
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'lesson',
        action: 'view',
        label: this.props.analyticsLabel,
      });
    }
    e.stopPropagation();
    window.location =
      process.env.REACT_APP_TEACHING_URL +
      '/lesson/' +
      this.props.artifact.lesson.id +
      '/' +
      this.props.artifact.lesson.seo_url;
  };

  setupViewport = (changed) => {
    if (changed.matches) {
      this.setupMobileLayout();
      // calculate new position of overlay on resize
      window.addEventListener('resize', this.setupMobileLayout);
    } else {
      this.setDesktopLayout();
      window.removeEventListener('resize', this.setupMobileLayout);
    }
  };

  setupMobileLayout = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = ReactDOM.findDOMNode(
      this.refs.mobileArtifactHeader
    ).offsetHeight;
    const detailsHeight = ReactDOM.findDOMNode(
      this.refs.artifactDetails
    ).offsetHeight;

    const artifactContainerHeight =
      windowHeight - (detailsHeight + headerHeight);

    this.setState({
      responsiveLayout: true,
      artifactContainerHeight: artifactContainerHeight,
    });
  };

  setDesktopLayout = () => {
    this.setState({
      responsiveLayout: false,
      artifactContainerHeight: 'auto',
    });
  };

  limitPageHeight = (overlayHeight) => {
    const windowHeight = window.innerHeight;
    const artifactParent = document.getElementById('artifact-parent');
    const teamActivityFeed = document.getElementById('team__activity-feed');

    // scroll to the artifact overlay
    window.scrollTo(0, 0);

    // set the parent height to the lareger of the two window vs overlay
    let parentHeight = windowHeight;
    if (overlayHeight > windowHeight) {
      parentHeight = overlayHeight;
    }

    // set that the overlay is open and add a height and overflow to the overlays parent
    if (artifactParent) {
      artifactParent.setAttribute(
        'style',
        'overflow: hidden; height: ' + parentHeight + 'px;'
      );
    }

    //if the activity feed exists limit its height as well
    if (teamActivityFeed) {
      teamActivityFeed.setAttribute(
        'style',
        'overflow: hidden; height: ' + (parentHeight - 83) + 'px;'
      );
    }

    Utils.addClass(document.body, 'no-scroll--mobile');
  };

  layoutArtifactWindow = (_overlayHeight) => {
    const overlayHeight =
      _overlayHeight ||
      ReactDOM.findDOMNode(this.refs.artifactOverlay).offsetHeight + 56;

    if (this.refs.resourceImg) {
      const imageElem = ReactDOM.findDOMNode(this.refs.resourceImg);
      if (imageElem) {
        const img = new Image();

        img.onload = function () {
          const overlayHeight =
            ReactDOM.findDOMNode(this.refs.artifactOverlay).offsetHeight + 56;
          this.limitPageHeight(overlayHeight);
        }.bind(this);

        img.src = imageElem.src;
      }
    } else {
      this.limitPageHeight(overlayHeight);
    }
  };

  componentDidMount() {
    this.componentMounted = true;

    // ensure browser supports fullscreen
    if (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnable
    ) {
      this.setState({
        fullScreenAvailable: true,
      });
    }

    // measure page
    this.mq = window.matchMedia('(max-width: 48em)');

    // check if the artifact has a preview
    Content.checkForPreview(this, this.props.artifact.preview);

    // list to comment store
    this.commentToken = CommentStore.subscribe(this.onStoreChange);

    // if the page is currently responsive
    if (this.mq.matches) {
      window.addEventListener('resize', this.setupMobileLayout);
      this.setupMobileLayout();
    }

    // if the window size changes
    this.mq.addListener(this.setupViewport);

    // log that this artifact was viewed if the user has an access token
    if (this.context.pageData.user && this.context.pageData.user.id) {
      const logData = {
        artifactId: this.props.artifact.id,
      };
      if (this.props.artifact.strategy_implementation_id) {
        logData.strategyId = this.props.artifact.strategy_implementation_id;
        StrategyApi.postArtifactView(logData);
      } else {
        StrategyApi.postTCArtifactView(logData);
      }
    }

    this.layoutArtifactWindow();
  }

  componentDidUpdate() {
    if (!this.state.fullscreenDimensionsSet && !this.initalizingOverlay) {
      this.initalizingOverlay = true;

      // get the overlay height and margin of the container
      const artifactOverlay = ReactDOM.findDOMNode(this.refs.artifactOverlay);

      let overlayHeight = artifactOverlay.offsetHeight + 56;

      const component = this;

      // if there is no resource image dont bother centering it
      if (this.refs.resourceImg) {
        const standardImageWidth = 970;
        const imageElem = ReactDOM.findDOMNode(this.refs.resourceImg);
        const screenRatio = screen.height / screen.width;

        const image = new Image();
        image.src = imageElem.src;

        image.onload = function () {
          let imageWidth = imageElem.clientWidth;
          let imageHeight = imageElem.clientHeight;

          // get the image ratio
          const imageRatio = imageHeight / imageWidth;

          // compaire the image ratio to the screen ratio
          if (screenRatio > imageRatio) {
            imageWidth = standardImageWidth;
            imageHeight = imageWidth * imageRatio;
          } else {
            imageHeight = screen.height;
            imageWidth = imageHeight / imageRatio;

            if (imageWidth > standardImageWidth) {
              imageWidth = standardImageWidth;
              imageHeight = imageWidth * imageRatio;
            }
          }
          // center the image
          const fullscreenMarginTop = 10;
          const fullscreenMarginLeft = Math.abs(imageWidth / 2) * -1;

          // set the params for the new fullscreened image
          component.setState({
            fullscreenLeft: '50%',
            fullscreenMarginLeft: Math.round(fullscreenMarginLeft),
            fullscreenMarginTop: Math.round(fullscreenMarginTop),
            fullscreenHeight: Math.round(imageHeight),
            fullscreenWidth: Math.round(imageWidth),
            fullscreenDimensionsSet: true,
          });

          // get the overlay height and margin of the container
          overlayHeight = artifactOverlay.offsetHeight + 56;
        };
      }

      this.layoutArtifactWindow(overlayHeight);

      component.initalizingOverlay = false;
    }
    window.scrollTo(0, this.scrollPosition);
  }

  componentWillUnmount() {
    this.componentMounted = false;

    CommentStore.unsubscribe(this.commentToken);
    this.mq.removeListener(this.setupViewport);
    window.removeEventListener('resize', this.setupViewport);
    window.removeEventListener('resize', this.setupMobileLayout);
  }

  showDetails = () => {
    this.setState({
      mobileDetailsVisible: true,
    });
  };

  handleUrlClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Analytics.logEvent({
      category: 'artifact',
      action: 'external_link',
      label: this.props.analyticsLabel,
    });

    window.open(this.props.artifact.link, '_blank');
  };

  render() {
    let videoNode = null;
    let previewNode = null;
    let fullscreenPreviewNode = null;
    let paginationNode = null;
    let fullscreenBtnNode = null;

    const fullscreenImageStyles = {
      height: this.state.fullscreenHeight,
      width: this.state.fullscreenWidth,
    };

    const fullscreenContainerStyles = {
      left: this.state.fullscreenLeft,
      marginLeft: this.state.fullscreenMarginLeft,
      marginTop: this.state.fullscreenMarginTop,
    };

    // only show the artifact type flag if the artifact has a type associated with it
    let artifactTypeNode = null;
    if (this.props.artifact.type_name) {
      artifactTypeNode = (
        <div className="artifact__type">
          <div className="artifact__type-caption">
            {this.props.artifact.type_name}
          </div>
          <div className="artifact__flag-end artifact__flag-end--overlay tc-flag-end"></div>
        </div>
      );
    }

    if (this.props.artifact.icon === 'link') {
      previewNode = (
        <div className="artifact-overlay__image--none">
          <div className="artifact-overlay__preview-icon artifact__preview-icon file-type-icon ext-link"></div>
        </div>
      );
    }

    switch (this.props.artifact.preview_type) {
      case 'movie':
        videoNode = (
          <Video
            preview={this.props.artifact.content.preview[0]}
            formats={this.props.artifact.content.formats}
            height={500}
            width={885}
          />
        );
        break;

      case 'document':
        previewNode = (
          <div className="artifact-overlay__image--none">
            <div
              className={
                'artifact-overlay__preview-icon artifact__preview-icon file-type-icon ext-' +
                this.props.artifact.icon
              }
            ></div>
          </div>
        );

        if (this.state.viablePreview) {
          previewNode = this.props.artifact.content.preview.map(function (
            page,
            i
          ) {
            if (this.state.activePage - 1 === i) {
              return (
                <img
                  ref="resourceImg"
                  key={i}
                  src={page}
                  className="artifact-overlay__image--full-width i-doc"
                  onClick={this.handleArtifactImageClick}
                />
              );
            } else {
              return <img key={i} src={page} className="tc__hidden" />;
            }
          },
          this);

          fullscreenPreviewNode = this.props.artifact.content.preview.map(
            function (page, i) {
              if (this.state.activePage - 1 === i) {
                return (
                  <img
                    ref="fullscreenImg"
                    key={'fullscreen-' + i}
                    src={page}
                    style={fullscreenImageStyles}
                  />
                );
              } else {
                return (
                  <img
                    key={'fullscreen-' + i}
                    src={page}
                    className="tc__hidden"
                    style={fullscreenImageStyles}
                  />
                );
              }
            },
            this
          );

          if (this.props.artifact.content.preview.length > 1) {
            let maxPreviewLength = 25;
            if (this.props.artifact.content.preview.length < 25) {
              maxPreviewLength = this.props.artifact.content.preview.length;
            }
            paginationNode = (
              <div>
                <div className="artifact-overlay__paging-bar"></div>
                <div className="artifact-overlay__page-controls tc__body-copy">
                  <div
                    className="page-control page-control__left"
                    onClick={this.handlePreviousPageClick}
                  >
                    <div className="tc-arrow"></div>
                  </div>
                  <div className="page-control page-control__current">
                    {this.state.activePage} of {maxPreviewLength}
                  </div>
                  <div
                    className="page-control page-control__right"
                    onClick={this.handleNextPageClick}
                  >
                    <div className="tc-arrow"></div>
                  </div>
                </div>
              </div>
            );
          }

          if (this.state.fullScreenAvailable) {
            fullscreenBtnNode = (
              <div
                className="artifact__fullscreen"
                onClick={this.handleFullScreenClick}
              >
                <div className="artifact__action-icon tc-full-screen"></div>
                <div className="tc__link">Full Screen</div>
              </div>
            );
          }
        }

        break;

      case 'image':
        previewNode = (
          <div className="artifact-overlay__image--none">
            <div
              className={
                'artifact-overlay__preview-icon artifact__preview-icon file-type-icon ext-' +
                this.props.artifact.icon
              }
            ></div>
          </div>
        );

        if (this.state.viablePreview) {
          previewNode = (
            <img
              ref="resourceImg"
              src={this.props.artifact.content.preview[0]}
              onClick={this.handleArtifactImageClick}
              className="artifact-overlay__image artifact-overlay__image--max-width i-i"
            />
          );
          if (this.state.fullScreenAvailable) {
            fullscreenPreviewNode = (
              <img
                ref="resourceFullscreenImg"
                src={this.props.artifact.content.preview[0]}
                style={fullscreenImageStyles}
              />
            );
            fullscreenBtnNode = (
              <div
                className="artifact__fullscreen"
                onClick={this.handleFullScreenClick}
              >
                <div className="artifact__action-icon tc-full-screen"></div>
                <div className="tc__link">Full Screen</div>
              </div>
            );
          }
        }
        break;

      case 'text':
        previewNode = (
          <PurifiedHTML
            className="artifact-overlay__text tc__body-copy"
            content={this.props.artifact.content.preview.replace(
              /href="\//g,
              'href="' + process.env.REACT_APP_LAB_URL + '/'
            )}
          />
        );

        break;
    }

    const mobileDetailsClass = this.state.mobileDetailsVisible
      ? ' details-visible'
      : '';

    // if its a mtp artifact set up the correct nodes to display
    let addedByNode = null;
    let commentsNode = null;
    let artifactNotesClass = ' artifact-overlay__notes--mt';

    // if its a tc artifact set up the correct nodes to display
    let authorImageNode = null;
    if (this.props.artifact.user) {
      // set up default user photo
      authorImageNode = (
        <div className="artifact-overlay__author-image-mask artifact-overlay__author-image--none">
          <img
            className="artifact-overlay__author-image"
            src="/legacy-images/default-user.svg"
          />
        </div>
      );

      if (this.props.artifact.user.photo) {
        authorImageNode = (
          <ScaleCenterImage
            src={this.props.artifact.user.photo}
            className="artifact-overlay__author-image"
          />
        );
      }

      const url =
        '/coaching/lab/teacher/' +
        this.props.artifact.user.id +
        '?from=artifact_modal';
      addedByNode = (
        <div className="artifact-overlay__added-by">
          Added by&nbsp;
          <NavigationLink className="tc__link" url={url}>
            {this.props.artifact.user.first_name}{' '}
            {this.props.artifact.user.last_name}
          </NavigationLink>
          &nbsp;on {dateformat(this.props.artifact.created, 'mm/dd/yyyy')}
        </div>
      );

      artifactNotesClass = '';

      const submitHiddenClass = !this.state.commentButtonVisible
        ? ' tc__hidden'
        : '';

      let commentPosterImageNode = (
        <div className="artifact-overlay__author-image-mask artifact-overlay__author-image--none">
          <img
            className="artifact-overlay__author-image"
            src="/legacy-images/default-user.svg"
          />
        </div>
      );

      if (this.context.pageData.user.photo) {
        commentPosterImageNode = (
          <ScaleCenterImage
            src={this.context.pageData.user.photo}
            className="artifact-overlay__author-image"
          />
        );
      }

      // only users with certain roles can add a comment
      let commentForm = null;
      if (this.props.canAddComment) {
        let errorClass = '';
        let errorNode = null;
        if (this.state.commentError) {
          errorClass = ' error';
          errorNode = (
            <div className="tc__error">{this.state.commentError}</div>
          );
        }
        commentForm = (
          <div className="artifact-overlay__comment-container">
            <div className="artifact-overlay__comment-seperator show-phone hide-desktop"></div>
            <div className="artifact-overlay__comment-image">
              {commentPosterImageNode}
            </div>
            <div className="artifact-overlay__comment-data">
              <div className="artifact-overlay__comment-user tc__headline-5">
                {this.context.pageData.user.first_name}{' '}
                {this.context.pageData.user.last_name}
              </div>
              <form>
                <div className="artifact-overlay__comment-input-container">
                  <textarea
                    onChange={this.handleCommentChange}
                    value={this.state.comment}
                    className={
                      'artifact-overlay__comment-input tc__input' + errorClass
                    }
                    placeholder="Add a comment"
                    onFocus={this.handleShowSubmit}
                  />
                  {errorNode}
                </div>
                <a
                  onClick={this.handleSubmitComment}
                  className={
                    'artifact-overlay__comment-submit tc__btn tc__btn--blue tc__btn--small' +
                    submitHiddenClass +
                    this.state.btnDisabledClass
                  }
                >
                  Post Comment
                </a>
              </form>
            </div>
          </div>
        );
      }
      const comments = this.state.comments.map(function (comment) {
        let commentAuthorPhotoNode = (
          <div className="artifact-overlay__author-image-mask artifact-overlay__author-image-mask--none">
            <img
              className="artifact-overlay__author-image"
              src="/legacy-images/default-user.svg"
            />
          </div>
        );

        if (comment.photo) {
          commentAuthorPhotoNode = (
            <ScaleCenterImage
              src={comment.photo}
              className="artifact-overlay__author-image"
            />
          );
        }

        const createdFormatted = moment(comment.created_formatted).toDate();

        return (
          <div
            className="artifact-overlay__comment-container"
            key={'comment-' + comment.id}
          >
            <div className="artifact-overlay__comment-seperator show-phone hide-desktop"></div>
            <div className="artifact-overlay__comment-image">
              {commentAuthorPhotoNode}
            </div>
            <div className="artifact-overlay__comment-data">
              <span className="artifact-overlay__comment-user tc__headline-5">
                {comment.first_name} {comment.last_name}
              </span>
              <span className="artifact-overlay__comment-date">
                <TimeAgo date={createdFormatted} />
              </span>
              <div className="artifact-overlay__comment-text tc__body-copy">
                {comment.data}
              </div>
            </div>
          </div>
        );
      });
      commentsNode = (
        <div className="artifact-overlay__comments">
          {comments}
          {commentForm}
        </div>
      );
    }

    if (this.props.noComments) {
      commentsNode = null;
    }

    // remove artifact details indent if there is no user
    const userDetailsClass = !this.props.artifact.user ? ' mt-artifact' : '';

    // if there is a mtp link display the button
    let lessonLinkBtnNode = null;
    let mobileLinkToLessonNode = null;
    if (this.props.artifact.lesson) {
      const url =
        process.env.REACT_APP_TEACHING_URL +
        '/lesson/' +
        this.props.artifact.lesson.id +
        '/' +
        this.props.artifact.lesson.seo_url;
      lessonLinkBtnNode = (
        <a
          href={url}
          className="artifact__lessonlink"
          onClick={this.navigateToFullLesson}
        >
          <div className="artifact__action-icon tc-outlink"></div>
          <div className="tc__link">Full Lesson</div>
        </a>
      );
      mobileLinkToLessonNode = (
        <a
          href={
            process.env.REACT_APP_TEACHING_URL +
            '/lesson/' +
            this.props.artifact.lesson.id +
            '/' +
            this.props.artifact.lesson.seo_url
          }
          className="artifact__lessonlink--mobile tc__link"
          onClick={this.navigateToFullLesson}
        >
          Link To Lesson
        </a>
      );
    }

    // if the artifact is downloadable display the button and the user is not public or bl
    let downloadBtnNode = null;
    const downloadSpinner = this.state.downloading ? (
      <div className="littlespinner"></div>
    ) : null;
    if (
      this.props.artifact.downloadable &&
      !this.context.pageData.user.is_public &&
      !this.props.artifact.link
    ) {
      downloadBtnNode = (
        <div className="artifact__download" onClick={this.handleDownloadClick}>
          <div className="artifact__action-icon tc-download"></div>
          <div className="tc__link">Download</div>
          {downloadSpinner}
          <div className="tc__error artifact__download_error">
            {this.state.downloadError}
          </div>
        </div>
      );
    }

    // do not display fullscreen button if not supported by the browser
    if (!this.checkFullscreenSupport || this.props.hideFullScreen) {
      fullscreenBtnNode = null;
    }

    // setup containder for artifact actions
    const actionsNode = (
      <div className="artifact-overlay__actions hide-phone">
        {lessonLinkBtnNode}
        {downloadBtnNode}
        {fullscreenBtnNode}
      </div>
    );

    // set up lock icon for private artifacts
    let shareNode = null;
    if (this.props.artifact.share === 0) {
      shareNode = (
        <div className="artifact__overlay-private tc__private">
          <div className="tc__private-icon tc-lock"></div>
          <div className="tc__private-caption">Private</div>
        </div>
      );
    }

    return (
      <div
        className={'mt-artifact-overlay"' + mobileDetailsClass}
        ref="artifactOverlay"
      >
        <div className="row">
          <div
            ref="mobileArtifactHeader"
            className="artifact-overlay__mobile-header cute-12-desktop show-phone hide-desktop"
          >
            <div className="row">
              {!this.props.hideBack && (
                <div
                  className="artifact-overlay__back cute-2-phone"
                  onClick={this.handleBackClick}
                >
                  <div className="artifact-overlay__back-arrow tc-arrow"></div>
                  <div className="artifact-overlay__back-caption">Back</div>
                </div>
              )}
              <div className="artifact-overlay__mobile-header-title tc__headline-4 cute-8-phone left">
                {this.props.artifact.name}
              </div>
            </div>
          </div>
        </div>
        <div className="artifact-overlay__artifact">
          <div
            ref="previewContainer"
            className="artifact-overlay__image-container"
            style={{ height: this.state.artifactContainerHeight }}
          >
            {previewNode}
            {videoNode}
          </div>
          {paginationNode}
        </div>
        <div
          ref="fullscreenContainer"
          style={fullscreenContainerStyles}
          className="artifact-overlay__fullscreen-container"
        >
          {fullscreenPreviewNode}
          {paginationNode}
        </div>
        <div
          className="artifact-overlay__details-container"
          onClick={this.showDetails}
          ref="artifactDetails"
        >
          {authorImageNode}
          <div className={'artifact-overlay__details' + userDetailsClass}>
            {artifactTypeNode}
            {!this.props.artifact.link ? (
              <div>
                <div
                  className={
                    'artifact-overlay__file-icon file-type-icon ext-' +
                    this.props.artifact.icon
                  }
                ></div>
                <div className="artifact-overlay__file-title tc__headline-5">
                  {this.props.artifact.name}
                </div>
              </div>
            ) : (
              <div>
                <div
                  className={
                    'artifact-overlay__file-icon file-type-icon ext-link'
                  }
                ></div>
                <div className="artifact-overlay__file-title tc__headline-5">
                  <a
                    className="tc__link"
                    href="#"
                    onClick={this.handleUrlClick}
                    target="_new"
                  >
                    {this.props.artifact.link}
                  </a>
                </div>
              </div>
            )}
            {addedByNode}
            {shareNode}

            <div className="artifact-overlay__view-details tc__link">
              View Details
            </div>
            {mobileLinkToLessonNode}
            {actionsNode}
          </div>
          <div
            className={
              'artifact-overlay__notes tc__body-copy' + artifactNotesClass
            }
          >
            {this.props.artifact.notes}
          </div>
          <div className="artifact-overlay__notes--mobile tc__body-copy">
            {this.props.artifact.notes}
          </div>
          <div className="show-phone hide-desktop">{lessonLinkBtnNode}</div>
        </div>
        {commentsNode}
      </div>
    );
  }
}

export default ArtifactOverlay;
