// @ts-nocheck FIXME
import React from 'react';
import Utils from './../../../components/StaticUtils';

class ChallengeV2 extends React.Component {
  render() {
    // tga info styles are different from sga styles
    let tgaClass = '';
    if (this.props.challenge.isInstructionalDesign) {
      tgaClass = ' tga';
    }

    // set up metric/baseline/goal data node
    let metricNode = null;
    const startingPointLabel = "What's my starting point?";
    if (this.props.metric && this.props.metric.name) {
      let baselineNode = null;
      let goalNode = null;
      // show the baseline data if we have it
      if (
        this.props.challengeInstance.baseline_value != null &&
        this.props.challenge.isInstructionalDesign
      ) {
        baselineNode = (
          <div className="portfolio-v2__challenge-attribute">
            <div className="portfolio-v2__challenge-attribute-question">
              {startingPointLabel}
            </div>
            <div className="portfolio-v2__challenge-attribute-text">
              I would rate my ability to meet my success indicator at
              <span className="highlight">
                {' '}
                {this.props.challengeInstance.baseline_value / 10}{' '}
              </span>{' '}
              out of <span className="highlight">10</span> at the beginning of
              this challenge.
            </div>
          </div>
        );
      } else if (
        this.props.challengeInstance.baseline_value != null &&
        this.props.challengeInstance.num_students
      ) {
        const baseline = Math.round(
          (this.props.challengeInstance.num_students *
            this.props.challengeInstance.baseline_value) /
            100
        );
        baselineNode = (
          <div className="portfolio-v2__challenge-attribute">
            <div className="portfolio-v2__challenge-attribute-question">
              {startingPointLabel}
            </div>
            <div className="portfolio-v2__challenge-attribute-text">
              <span className="highlight">{baseline}</span> out of{' '}
              <span className="highlight">
                {this.props.challengeInstance.num_students}
              </span>{' '}
              students met the success indicator{' '}
              <span className="highlight">
                ({this.props.challengeInstance.baseline_value}%)
              </span>
              .
            </div>
          </div>
        );
      } else if (this.props.challengeInstance.baseline_value != null) {
        baselineNode = (
          <div className="portfolio-v2__challenge-attribute">
            <div className="portfolio-v2__challenge-attribute-question">
              {startingPointLabel}
            </div>
            <div className="portfolio-v2__challenge-attribute-text">
              <span className="highlight">
                {this.props.challengeInstance.baseline_value}%
              </span>{' '}
              of students met the success indicator.
            </div>
          </div>
        );
      }
      // show the goal data if we have it
      if (
        this.props.challengeInstance.goal_value &&
        this.props.challenge.isInstructionalDesign
      ) {
        goalNode = (
          <div className="portfolio-v2__challenge-attribute">
            <div className="portfolio-v2__challenge-attribute-question">
              Where am I trying to go?
            </div>
            <div className="portfolio-v2__challenge-attribute-text">
              I believe I can get to
              <span className="highlight">
                {' '}
                {this.props.challengeInstance.goal_value / 10}{' '}
              </span>
              out of <span className="highlight">10</span>.
            </div>
          </div>
        );
      } else if (
        this.props.challengeInstance.goal_value &&
        this.props.challengeInstance.num_students
      ) {
        const goal = Math.round(
          (this.props.challengeInstance.num_students *
            this.props.challengeInstance.goal_value) /
            100
        );
        goalNode = (
          <div className="portfolio-v2__challenge-attribute">
            <div className="portfolio-v2__challenge-attribute-question">
              Where am I trying to go?
            </div>
            <div className="portfolio-v2__challenge-attribute-text">
              <span className="highlight">{goal}</span> out of{' '}
              <span className="highlight">
                {this.props.challengeInstance.num_students}
              </span>{' '}
              students is my goal for this challenge{' '}
              <span className="highlight">
                ({this.props.challengeInstance.goal_value}%)
              </span>
              .
            </div>
          </div>
        );
      } else if (this.props.challengeInstance.goal_value) {
        goalNode = (
          <div className="portfolio-v2__challenge-attribute">
            <div className="portfolio-v2__challenge-attribute-question">
              Where am I trying to go?
            </div>
            <div className="portfolio-v2__challenge-attribute-text">
              <span className="highlight">
                {this.props.challengeInstance.goal_value}%
              </span>{' '}
              of students is my goal for this challenge.
            </div>
          </div>
        );
      }

      metricNode = (
        <div className="portfolio-v2__challenge-subsection">
          <div className="portfolio-v2__challenge-attribute">
            <div className="portfolio-v2__challenge-attribute-question">
              What am I looking for? (Success Indicator)
            </div>
            <div className="portfolio-v2__challenge-attribute-text">
              {Utils.getMetricName(
                this.props.metric.name,
                this.props.metric.default_n
              )}
            </div>
          </div>
          {baselineNode}
          {goalNode}
        </div>
      );
    }

    // set up frameworks nodes
    let danielsonNode = null;
    let danielsonFrameworksNode = null;
    if (this.props.challenge.danielsonComponents.length) {
      danielsonNode = this.props.challenge.danielsonComponents.map(function (
        standard
      ) {
        return (
          <div className="portfolio__standard" key={standard.identifier}>
            {standard.identifier} - {standard.name}
          </div>
        );
      });
      danielsonFrameworksNode = (
        <div className="portfolio-v2__challenge-standard">
          <div className="portfolio__declaration">DANIELSON COMPONENTS:</div>
          <div className="portfolio__challenge-standards-list">
            {danielsonNode}
          </div>
        </div>
      );
    }

    // set up commoncore nodes
    let coresNode = null;
    let coresFrameworksNode = null;
    if (this.props.challenge.coreStandards.length) {
      coresNode = this.props.challenge.coreStandards.map(function (standard) {
        return (
          <div className="portfolio__standard" key={standard.identifier}>
            {standard.identifier} - {standard.description}
          </div>
        );
      });
      coresFrameworksNode = (
        <div className="portfolio-v2__challenge-standard">
          <div className="portfolio__declaration">COMMON CORE:</div>
          <div className="portfolio__challenge-standards-list">{coresNode}</div>
        </div>
      );
    }

    // set up next gen science standards node
    let ngssNode = null;
    if (this.props.challenge.ngss && this.props.challenge.ngss.length) {
      const ngss = this.props.challenge.ngss.map(function (standard) {
        return (
          <div className="portfolio__standard" key={standard.identifier}>
            {standard.identifier} - {standard.description}
          </div>
        );
      });
      ngssNode = (
        <div className="portfolio-v2__challenge-standard">
          <div className="portfolio__declaration">NEXT GEN SCIENCE:</div>
          <div className="portfolio__challenge-standards-list">{ngss}</div>
        </div>
      );
    }

    // only show the Frameworks and Standards section if we have data for it
    let standardsNode = null;
    if (danielsonNode || coresNode || ngssNode) {
      standardsNode = (
        <div className="portfolio-v2__challenge-subsection">
          <div className="portfolio-v2__challenge-attribute-question">
            Frameworks and Standards:
          </div>
          {danielsonFrameworksNode}
          {coresFrameworksNode}
          {ngssNode}
        </div>
      );
    }

    // only show the "Students Impacted" section if this is not an ID challenge
    let studentsImpactedSectionNode = null;
    if (!this.props.challenge.isInstructionalDesign) {
      // set up visual students impacted icons
      let studentsImpactedNode = null;
      let studentImpactedLabel = 'Students';
      if (this.props.challengeInstance.studentsImpacted == 1) {
        studentImpactedLabel = 'Student';
      }
      if (
        this.props.challengeInstance.studentsImpacted &&
        this.props.challengeInstance.studentsImpacted > 0
      ) {
        // arrays for color and gender
        const studentGenderSuffixArray = ['f', 'm'];
        const studentColorSuffix = ['r', 'b', 'g'];
        // empty array for the icons
        const studentsImpactedIcons = [];
        // set a random gender and color and push the element to the array
        for (
          let i = 0;
          i < this.props.challengeInstance.studentsImpacted;
          i++
        ) {
          const gender =
            studentGenderSuffixArray[
              Math.floor(Math.random() * studentGenderSuffixArray.length)
            ];
          const color =
            studentColorSuffix[
              Math.floor(Math.random() * studentColorSuffix.length)
            ];
          // if its going to be more than two lines tell how many  more and escape the loop
          if (i > 13) {
            const manyMore = this.props.challengeInstance.studentsImpacted - i;
            if (manyMore > 1) {
              studentsImpactedIcons.push(
                <div
                  key={'student-more'}
                  className="students-impacted__more students-impacted__more--portfolio"
                >
                  {' '}
                  and {Utils.numberWithCommas(manyMore)} more.
                </div>
              );
              break;
            }
          }
          studentsImpactedIcons.push(
            <div
              key={'student-icon-' + i}
              className={
                'students-impacted__icon students-impacted__icon--portfolio-challenge students-impacted__icon--' +
                color +
                ' tc-student-' +
                gender
              }
            ></div>
          );
        }
        // set up the new node for the dom
        studentsImpactedNode = (
          <div className="portfolio__challenge-students-impacted">
            {studentsImpactedIcons}
          </div>
        );
        studentsImpactedSectionNode = (
          <div className="portfolio__challenge-impact">
            <span className="portfolio__declaration portfolio__declaration--challenge-impact">
              Students Impacted:
            </span>
            <span className="portfolio__challenge-impact-count">
              {this.props.challengeInstance.studentsImpacted}
            </span>
            {studentsImpactedNode}
          </div>
        );
      }
    }

    let growthAreaLabel = 'student growth area';
    if (this.props.challenge.isInstructionalDesign) {
      growthAreaLabel = 'teacher growth area';
    }

    return (
      <div className="portfolio-v2__challenge">
        <div className="portfolio-v2__challenge-header">
          <h2 className="portfolio-v2__challenge-page-headline">
            Teaching Challenge
          </h2>
          <div className="portfolio-v2__challenge-question">
            {this.props.challenge.name}
          </div>
          <div className="portfolio-v2__challenge-details">
            <div className={'portfolio-v2__challenge-icon' + tgaClass}>
              <div className={'tc-sga-nb-' + this.props.challenge.sgaId}></div>
            </div>
            <div className="portfolio-v2__challenge-copy">
              <div className="portfolio-v2__challenge-subject-headline">
                <span
                  className={'portfolio-v2__challenge-subject-label' + tgaClass}
                >
                  {growthAreaLabel}
                </span>{' '}
                - {this.props.challenge.subject}
              </div>
              <div className={'portfolio-v2__challenge-sga' + tgaClass}>
                {this.props.challenge.sga}
              </div>
            </div>
          </div>
        </div>

        {metricNode}

        <div className="portfolio-v2__challenge-subsection">
          <div className="portfolio-v2__challenge-attribute-question">
            What were the results of this challenge?
          </div>
          <div className="portfolio-v2__challenge-strategies">
            <span className="portfolio__declaration">Strategies Tried:</span>
            <span>{this.props.challengeInstance.strategyCount}</span>
          </div>
          {studentsImpactedSectionNode}
        </div>

        {standardsNode}
      </div>
    );
  }
}

export default ChallengeV2;
