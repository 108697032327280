// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Analytics from './../../../components/Analytics';
import BackButton from './modules/BackButton';

const AvailabilityStep = (props) => {
  const formFieldNames = ['timezone', 'availabilityOptions'],
    formFieldErrorClasses = {},
    formFieldErrorMessages = {};
  const [timeZone, setTimeZone] = useState(props.participant.timezone || null);

  const handleFocus = (e, field) => {
    e.preventDefault();
    props.clearErrorsOnFocus(field);
  };

  const handleGuessTimeZone = () => {
    const IANAtimezone = moment.tz.guess(true);
    const guessedTimeZone = props.timeZones.find(
      (tz) => tz.pytz === IANAtimezone
    );
    if (guessedTimeZone) {
      setTimeZone(guessedTimeZone.id);
    }
  };

  const handleSelectTimeZone = (selectedTimeZone) => {
    if (timeZone) {
      setTimeZone(null);
    } else {
      setTimeZone(selectedTimeZone.id);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Analytics.logEvent({
      category: 'next',
      action: 'click',
      label: 'from_seventh_page_user_welcome',
    });
    props.submit({
      timezone: timeZone,
    });
  };

  const setAvailability = (id, answer, availabilityOption) => {
    const replaceCharacter =
      availabilityOption.name === 'Over the weekend' ? '_' : '';
    Analytics.logEvent({
      category: availabilityOption.name
        .toLowerCase()
        .replace(/,\s|[-\s/]/g, replaceCharacter),
      action: answer === 'no' ? 'no_way' : answer,
      label: 'from_seventh_page_user_welcome',
    });

    props.clearErrorsOnFocus('availabilities');

    if (props.participant.availabilities[id] === answer) {
      props.deleteParticipantAvailability({
        id: id,
      });
    } else {
      props.setParticipantAvailability({
        id: id,
        answer: answer,
      });
    }
  };

  const getAvailabilityNodes = () => {
    const availabilityRows = [];
    const answerOptions = ['possibly', 'yes'];

    props.availabilityOptions.forEach((availabilityOption) => {
      const availabilityId = availabilityOption.id;
      const key = 'availability-' + availabilityId;
      const selectedAnswer = props.participant.availabilities[availabilityId];
      const radios = answerOptions.map((currentAnswer) => {
        const selectedOptionClass =
          currentAnswer === selectedAnswer ? ' selected' : '';
        return (
          <div className="cute-4-desktop" key={key + '-radio-' + currentAnswer}>
            <div
              className={
                'registration__availability-check' + selectedOptionClass
              }
              onClick={() =>
                setAvailability(
                  availabilityId,
                  currentAnswer,
                  availabilityOption
                )
              }
            >
              <div className="tc-check"></div>
            </div>
          </div>
        );
      });

      // over the weeked does not have am pm so leave it all as one weight
      let availabilityOptionNameNode = (
        <div>
          <div className="registration__availability-ampm">
            Over the
            <br />
            weekend
          </div>
        </div>
      );
      let specificsNode = (
        <div className="cute-12-desktop registration__availability-under">
          Times to be specified with coach later
        </div>
      );
      if (availabilityOption.name !== 'Over the weekend') {
        const availabilityOptionName = availabilityOption.name.split(' ');
        availabilityOptionNameNode = (
          <div>
            <div className="registration__availability-time">
              {availabilityOptionName[0]}
            </div>
            <div className="registration__availability-ampm">
              {availabilityOptionName[1]}
            </div>
          </div>
        );
        specificsNode = null;
      }

      availabilityRows.push(
        <div className="registration__availability-row" key={key}>
          <div className="registration__input-label cute-4-desktop">
            {availabilityOptionNameNode}
          </div>
          {radios}
          {specificsNode}
        </div>
      );
    });
    return <div className="registration__availability">{availabilityRows}</div>;
  };

  formFieldNames.forEach((fieldName) => {
    formFieldErrorClasses[fieldName] = '';
    formFieldErrorMessages[fieldName] = null;
    if (fieldName in props.ui.errors) {
      formFieldErrorClasses[fieldName] = ' error';
      formFieldErrorMessages[fieldName] = (
        <div className="v5__error">{props.ui.errors[fieldName]}</div>
      );
    }
  });

  // set up time zone select
  // The time zones need to be sorted otherwise they'll be listed in the order they were added
  const timeZones = props.timeZones.sort(Utils.sortTimeZones);
  const timeZoneOptions = timeZones.map((tz) => {
    return {
      label: tz.name,
      id: tz.id,
      description: tz.pytz.replace('_', ' '),
    };
  });
  const selectedTimezone = timeZoneOptions.find((tz) => tz.id === timeZone);

  // set up errors for first name
  let availabilitiesErrorClass = '';
  let availabilitiesErrorNode = null;
  if ('availabilities' in props.ui.errors) {
    availabilitiesErrorClass = ' error';
    availabilitiesErrorNode = (
      <div className="v5__error">{props.ui.errors.availabilities}</div>
    );
  }

  // disable buttons when form submission is loading
  let disabledClass = '';
  let spinnerNode = null;
  if (props.ui.loading) {
    disabledClass = ' disabled';
    spinnerNode = <span className="littlespinner"></span>;
  }

  return (
    <div className="row row-1200 registration__primary">
      <form className="cute-6-desktop cute-12-phone center-desktop">
        <div className="registration__headline tc__headline-2">
          What is your availability for coaching sessions?
        </div>
        <div className="registration__form-item">
          <label>
            <div className="registration__input-label" id="section-time-zone">
              Choose your time zone
            </div>
            <MultiSelect
              className="form-field"
              handleFocus={(e) => handleFocus(e, 'timezone')}
              handleSelectOption={handleSelectTimeZone}
              selectedOptions={selectedTimezone ? [selectedTimezone] : []}
              dropdownOptions={timeZoneOptions}
              minCharCountForResults={0}
              stayOpenOnSelect={false}
              maximumSelectedOptionsCount={1}
              isSelectSearch={true}
              placeholder="Search for your time zone"
              error={props.ui.errors.timezone}
            />
            <div className="form-field">
              Need help? Let us{' '}
              <span className="v5__link" onMouseDown={handleGuessTimeZone}>
                guess your time zone.
              </span>
            </div>
          </label>
        </div>

        <div
          className={
            'registration__form-item registration__form-item--input-area' +
            availabilitiesErrorClass
          }
        >
          <div className="registration__availability-copy">
            Select all times you are available for 30-minute coaching sessions
            Monday – Friday. Indicate if you have preferred time(s) to meet.
          </div>
          <div className="registration__input-label">
            Don’t worry, these timeslots are approximate. Once you are matched
            with a coach, you will coordinate the specific day and time for your
            meetings.
          </div>
          <div className="registration__input-label">
            Please select at least one available slot.
          </div>
          <div className="registration__availability-row registration__availability-row--availabilities"></div>
          <div className="registration__checkbox-container less-space">
            <div className="registration__availability-legend-container">
              <div className="registration__availability-legend registration__availability-row">
                <div className="registration__availability-headline cute-4-desktop">
                  <div className="tc-clock"></div>
                </div>
                <div className="registration__availability-headline cute-4-desktop">
                  Available
                </div>
                <div className="registration__availability-headline cute-4-desktop">
                  Preferred
                </div>
              </div>
            </div>
            {getAvailabilityNodes()}
            {availabilitiesErrorNode}
          </div>
        </div>

        <div className="registration__submit-btns registration__submit-btns--fixed">
          <BackButton
            caption={'Back to Subjects'}
            participant={props.participant}
            step={props.step}
            analyticsLabel={'from_seventh_page_user_welcome'}
          />
          <button
            onClick={handleSubmit}
            className={
              'tc__btn tc__btn--flat-blue tc__btn--flat-blue-v3' + disabledClass
            }
          >
            {spinnerNode}
            Next: Submit
          </button>
        </div>
      </form>
      {/*}<WindowScrollElementFixer
            positionToClass={'registration__availability-row--availabilities'}
            positionThisClass={'registration__availability-legend'}
            useOnMobile={true} />*/}
    </div>
  );
};

AvailabilityStep.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  clearErrorsOnFocus: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  availabilityOptions: PropTypes.array.isRequired,
  setParticipantAvailability: PropTypes.func.isRequired,
};

export default AvailabilityStep;
