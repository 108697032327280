// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';

class AbridgeText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      abridgeText: false,
      maxHeight: 'auto',
    };
  }

  componentDidMount() {
    // set default variables
    const lineHeight = this.props.lineHeight ? this.props.lineHeight : 20;
    const lineCount = this.props.lineCount ? this.props.lineCount : 4;

    // get the heights to compaire
    const maxHeight = this.props.maxHeight
      ? this.props.maxHeight
      : lineHeight * lineCount;
    const elemHeight = this.thisElem.offsetHeight;

    // compare max height to element height
    if (maxHeight < elemHeight) {
      this.setState({
        abridgeText: true,
        maxHeight: maxHeight,
      });
    }
  }
  render() {
    // add a class to abridge the text as necessary
    let abridgeClass = this.state.abridgeText ? ' abridge-text' : '';

    // fade either just the last line horizontally or the whole text vertically,
    // depending on whether we have the line height or not
    abridgeClass += this.props.lineHeight ? ' fade-last-line' : ' fade-whole';

    return (
      <PurifiedHTML
        ref={(thisElem) => {
          this.thisElem = thisElem;
        }}
        className={this.props.className + abridgeClass}
        style={{ maxHeight: this.state.maxHeight }}
        content={this.props.children}
      />
    );
  }
}

export default AbridgeText;
