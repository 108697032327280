// @ts-nocheck FIXME
import Content from '@legacy-components/components/Content';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';
import TmlProgressChart from './../TmlProgressChart';

class Measurement extends React.Component {
  render() {
    // set up metric high lighting and customizaton
    let metricName;

    if (this.props.metric) {
      metricName = Content.setupMetric(
        this.props.metric.name,
        Object.keys(this.props.termDefinitions),
        this.props.defined_n
      );
    }

    // Get the baseline and goal depending on the metric type:
    let baselineValue;
    let goalValue;
    const baselineTotal = this.props.baseline
      ? this.props.baseline.total
      : this.props.baselineTotal;
    // Used to display the % sign after the qualitative metric measurements:
    let qualitativeSuffix;

    let metricType = 'qualitative';
    if (this.props.metric && this.props.metric.type === 'quantitative') {
      metricType = 'quantitative';
    }

    if (metricType === 'quantitative') {
      baselineValue = this.props.baseline
        ? this.props.baseline.value
        : this.props.baselineValue;
      goalValue = this.props.goal.value ? this.props.goal.value : 'n/a';
    } else {
      baselineValue = this.props.baseline.qualitative_percentage;
      goalValue = this.props.goal.qualitative_percentage
        ? this.props.goal.qualitative_percentage
        : 'n/a';
      qualitativeSuffix = '%';
    }

    // default is for baseline loop measurements
    let measurementNode = (
      <div className="portfolio__measurement">
        <div className="portfolio__section-headline">
          My Baseline &amp; Goals
        </div>
        <div className="portfolio__measurement-metric-text">
          <span className="portfolio__declaration portfolio__measurement-description--highlight">
            Success Indicator:
          </span>
          <PurifiedHTML content={metricName} tagName="span" />
        </div>

        <div className="portfolio__measurement-description-text">
          <span className="portfolio__declaration">Baseline:</span>
          When I started,{' '}
          <span className="tall highlight">
            {baselineValue}
            {qualitativeSuffix}
          </span>{' '}
          out of <span className="tall highlight">{baselineTotal}</span>{' '}
          students met the indicator for success.
        </div>

        <div className="portfolio__measurement-description-text">
          <span className="portfolio__declaration">Goal:</span>
          My goal was for at least{' '}
          <span className="tall highlight">
            {goalValue}
            {qualitativeSuffix}
          </span>{' '}
          out of <span className="tall highlight">{baselineTotal}</span>{' '}
          students to achieve success.
        </div>
      </div>
    );

    // if this is not a baseline loop, then we need to show the chart
    if (this.props.type === 'measurement') {
      const chartMeasurements = [
        {
          loop: this.props.loopNumber,
          value: this.props.measurementValue,
          percentage: this.props.qualitativePercentage,
          total: this.props.measurementTotal,
        },
      ];
      if (this.props.previousLoops) {
        this.props.previousLoops.map(function (loop) {
          chartMeasurements.unshift({
            loop: loop.num,
            value: loop.measurementValue,
            percentage: loop.qualitativePercentage,
            total: loop.measurementTotal,
          });
        });
      }

      // Get the measurement value based on the metric type:
      let measurementValue;
      if (metricType === 'quantitative') {
        measurementValue = this.props.measurementValue;
      } else {
        measurementValue = this.props.qualitativePercentage;
      }

      let measurmentChart = null;
      if (goalValue !== 'n/a') {
        measurmentChart = (
          <div className="portfolio__measurement-chart-container">
            <TmlProgressChart
              legend={true}
              labels={true}
              smallFonts={true}
              usePercentages={metricType === 'qualitative'}
              goal={{
                value: goalValue,
                percentage: goalValue,
                total: this.props.measurementTotal,
              }}
              baseline={{
                value: baselineValue,
                percentage: baselineValue,
                total: baselineTotal,
              }}
              measurements={chartMeasurements}
            />
          </div>
        );
      }

      measurementNode = (
        <div className="portfolio__measurement">
          <div className="portfolio__section-headline">Student Outcomes</div>
          <div className="portfolio__measurement-metric-text">
            <span className="portfolio__declaration portfolio__measurement-description--highlight">
              Success Indicator:
            </span>
            <PurifiedHTML content={metricName} tagName="span" />
          </div>
          <div className="portfolio__measurement-description-text">
            <span className="highlight portfolio__measurement-description--highlight">
              Loop {this.props.loopNumber}:
            </span>{' '}
            Using this strategy,{' '}
            <span className="tall highlight">
              {measurementValue}
              {qualitativeSuffix}
            </span>{' '}
            out of{' '}
            <span className="tall highlight">
              {this.props.measurementTotal}
            </span>{' '}
            students met the indicator for success.
          </div>
          {measurmentChart}
        </div>
      );
    }

    return <div className="portfolio__loop-measurement">{measurementNode}</div>;
  }
}

export default Measurement;
