// @ts-nocheck FIXME

const RegistrationSteps = (props) => {
  const stepOneCopy = props.hasAccount ? 'Log In' : 'Create Account';
  const stepOneActiveClass = props.stepOneActive ? ' active' : '';
  const stepTwoActiveClass = props.stepTwoActive ? ' active' : '';

  const stepTwoCopy = 'Register';

  return (
    <div className="registration-v5-event__register-tabs row">
      <div className={`cute-6-desktop left-tab ${stepOneActiveClass}`}>
        <div className="registration-step v5__comment-copy">Step 1</div>
        <span className="v5__heading-6">{stepOneCopy}</span>
      </div>
      <div className={`cute-6-desktop right-tab ${stepTwoActiveClass}`}>
        <div className="registration-step v5__comment-copy">Step 2</div>
        <span className="v5__heading-6">{stepTwoCopy}</span>
      </div>
    </div>
  );
};

export default RegistrationSteps;
