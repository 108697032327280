// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Spinner from './Spinner';

const StickyFooter = (props) => {
  // disable buttons/links when loading
  let disabledClass = props.loading ? ' disabled' : '';
  disabledClass = props.disabled ? ' disabled' : disabledClass;

  // set up the Cancel link if we want to show it
  let cancelLinkNode = null;
  if (props.hasCancelLink) {
    cancelLinkNode = (
      <div className="sticky-footer__cancel">
        <span onClick={props.cancel} className="tc__link">
          {props.cancelText}
        </span>
      </div>
    );
  }

  const hiddenClass = props.hidden ? ' sticky-footer--hidden' : '';

  return (
    <div className={'sticky-footer' + hiddenClass}>
      <button
        className={'bl4__btn bl4__btn--blue' + disabledClass}
        onClick={props.submit}
      >
        <Spinner loading={props.loading} />
        {props.submitText}
      </button>
      {cancelLinkNode}
    </div>
  );
};

StickyFooter.propTypes = {
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  submitText: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  hasCancelLink: PropTypes.bool.isRequired,
  cancelText: PropTypes.string,
  cancel: PropTypes.func,
  hidden: PropTypes.bool,
};

StickyFooter.defaultProps = {
  loading: false,
  disabled: false,
  submitText: 'Save',
  hasCancelLink: false,
  cancelText: 'Cancel',
};

export default StickyFooter;
