// @ts-nocheck FIXME
import { Router } from './../../components/Router';
import Utils from './../../components/StaticUtils';

const PartnerPortfolioPresentationHeader = (props) => {
  const changePage = (e, section) => {
    e.preventDefault();
    Router.route(
      '/coaching/lab/partner/portfolio/' +
        props.guid +
        '/page/' +
        section +
        '?from=pp_consumer_view'
    );
  };

  const navigationItemWidth = 100 / props.sections.length;
  const navigationItems = props.sections.map((section) => {
    // if section is cover or it's not visible, don't show tab
    if (section.type == 'COVER' || !section.visible) {
      return;
    }

    // if all spotlights hidden dont show header item
    if (section.type == 'SPOTLIGHTS') {
      let tabVisible = false;
      for (const type of section.types) {
        // check if any type entries are visible
        for (const entry of type.entries) {
          if (!entry.visible) {
            continue;
          }

          // stop loop we found a visible tab
          tabVisible = true;
          break;
        }

        // set tab as visible stop loop
        if (tabVisible) {
          break;
        }
      }

      // dont render the tab if its empty
      if (!tabVisible) {
        return null;
      }
    }

    const tabValue = section.type.replace('_', '-').toLowerCase();
    const tabActiveClass = props.tab == tabValue ? ' active' : '';
    const sectionName = Utils.capitalizeFirstLetter(section.name);
    return (
      <a
        className={'ppp__tab' + tabActiveClass + ' ppp__tab--h-' + tabValue}
        onClick={(e) => {
          changePage(e, tabValue);
        }}
        key={'section' + tabValue}
        href={
          '/coaching/lab/partner/portfolio/' +
          props.guid +
          '/page/' +
          tabValue +
          '?from=pp_consumer_view'
        }
      >
        {sectionName}
      </a>
    );
  });

  return (
    <div className="ppp__header-wrap">
      <div className="ppp__header row">
        <div
          className="ppp__header-details cute-4-desktop"
          onClick={(e) => {
            changePage(e, 'cover');
          }}
        >
          <div className="ppp__header-logo">
            <img src="/legacy-images/bl-square-logo.svg" alt="BetterLesson" />
          </div>
          <div className="ppp__partner-name">{props.partnerName}</div>
        </div>
        <div className="ppp__tabs">{navigationItems}</div>
      </div>
    </div>
  );
};

export default PartnerPortfolioPresentationHeader;
