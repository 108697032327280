// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import PortfolioRow from './PortfolioRow';

const PartnerPortfolioDashboard = (props) => {
  const handleAddClick = (partnerId) => {
    props.addPartner({ partnerId: partnerId });
  };

  /* BEGIN RENDERING LOGIC */
  // list of partners
  const partners = props.partners.map((partner) => {
    return partner.portfolios.reduce(
      (prevRows, portfolio) => {
        return prevRows.concat(<PortfolioRow portfolio={portfolio} />);
      },
      [
        // eslint-disable-next-line react/jsx-key
        <tr className="admin-table__row">
          <td className="admin-table__col">{partner.name}</td>
          <td className="admin-table__col">{partner.count}</td>
          <td className="admin-table__col">
            {partner.portfolios.length}
            {!props.ui.partnerAdding && (
              <a
                className="tc__link admin-table__new-link"
                onClick={(e) => {
                  e.preventDefault();
                  handleAddClick(partner.id);
                }}
              >
                + New
              </a>
            )}
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>,
      ]
    );
  });

  return (
    <div>
      <Header page="PartnerPortfolios" {...props} />

      <div className="row">
        <h1 className="tc__headline-1">Partner Portfolios</h1>
      </div>

      <div className="row">
        <table className="admin-table">
          <tbody>
            <tr>
              <th className="admin-table__header">Partner Name</th>
              <th className="admin-table__header">Active Teachers</th>
              <th className="admin-table__header">Portfolios</th>
              <th className="admin-table__header">Date Created</th>
              <th className="admin-table__header">Last Modified</th>
              <th className="admin-table__header"></th>
            </tr>
            {partners}
          </tbody>
        </table>
      </div>
    </div>
  );
};

PartnerPortfolioDashboard.propTypes = {
  partners: PropTypes.array.isRequired,
  adding: PropTypes.bool.isRequired,
};

export default PartnerPortfolioDashboard;
