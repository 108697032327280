// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import { Router } from './../../../components/Router';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';

const Header = (props) => {
  const lab = useLegacyLabContext();
  // Returns the appropriate label for the nextStep link:
  const getNextStepLabel = () => {
    switch (props.ui.currentStep) {
      case 'cover':
        return 'Introduction & Closing';
      case 'intro':
        return 'Strategies & Artifacts';
      case 'strategies':
        return 'Review';
      case 'summary':
        return 'Finish';
      default:
        return 'Next step';
    }
  };

  // Handles saving and/or publishing, then redirecting to the teacher profile
  const handleSaveAndClose = (e) => {
    e.preventDefault();

    // if the portfolio is already published, then just save any changes + go to profile page
    if (props.portfolio.published) {
      // if there were any changes since we last saved, then save before routing
      if (props.portfolioChanged || Object.keys(props.ui.errors).length) {
        props.savePortfolio(
          teacherProfileUrl,
          Utils.isServicesManager.call(lab)
        );
      } else {
        Router.route(teacherProfileUrl);
      }
      // else, first save any changes, then publish the portfolio + go to profile page
    } else {
      // if there were any changes since we last saved, then save before publishing
      if (props.portfolioChanged || Object.keys(props.ui.errors).length) {
        props.savePortfolio(undefined, Utils.isServicesManager.call(lab));
      }
      props.publishPortfolio(teacherProfileUrl);
    }
  };

  // Handles saving and then navigating to the next step:
  const navigateToNextStep = (e) => {
    e.preventDefault();

    // if we are on the last step and the portfolio is unpublished,
    // the next step is to publish the portfolio,
    // then redirect to the teacher profile
    if (props.ui.currentStep == 'summary' && !props.portfolio.published) {
      props.publishPortfolio(teacherProfileUrl);
      return;
    }

    // otherwise, determine url of next step based on current step
    let url = basePortfolioUrl;
    switch (props.ui.currentStep) {
      case 'cover':
        url += '/intro';
        break;
      case 'intro':
        url += '/strategies';
        break;
      case 'strategies':
        url += '/summary';
        break;
      case 'summary':
        // Redirect the user to the portfolios tab on the teacher profile:
        url = teacherProfileUrl;
        break;
    }

    // append from tag for metrics
    url += '?from=tp_curation_ui';

    // if there were any changes since we last saved, then save before routing
    if (props.portfolioChanged || Object.keys(props.ui.errors).length) {
      props.savePortfolio(url, Utils.isServicesManager.call(lab));
    } else {
      Router.route(url);
    }
  };

  // Handles saving the data and then navigating to a step based on its key.
  const navigateToStep = (stepKey) => {
    // determine the url of the step based on the key
    const steps = ['cover', 'intro', 'strategies', 'summary'];
    const url =
      basePortfolioUrl + '/' + steps[stepKey - 1] + '?from=tp_curation_ui';

    // if there were any changes since we last saved, then save before routing
    if (props.portfolioChanged || Object.keys(props.ui.errors).length) {
      props.savePortfolio(url, Utils.isServicesManager.call(lab));
    } else {
      Router.route(url);
    }
  };

  // save the urls for convenience
  const homeUrl =
    '/coaching/lab/teacher/' +
    props.portfolio.user_id +
    '/home?from=portfolio_edit_' +
    props.ui.currentStep;
  const teacherProfileUrl =
    '/coaching/lab/teacher/' +
    props.portfolio.user_id +
    '/portfolios?from=tp_curation_ui';
  const basePortfolioUrl =
    '/coaching/lab/teacher/' +
    props.portfolio.user_id +
    '/portfolio/' +
    props.portfolio.id;

  // build the navigation section
  const stepNames = [
    '1. Cover Page',
    '2. Introduction & Closing',
    '3. Strategies & Artifacts',
    '4. Review',
  ];
  // create the links for the four steps
  const navLinkNodes = [1, 2, 3, 4].map((i) => {
    let classList = 'portfolio-edit__header-navigation-link';
    // each link has different styles depending on the current step
    if (props.ui.currentStepKey == i) {
      classList += ' current';
    } else if (props.ui.currentStepKey > i) {
      classList += ' previous';
    }
    return (
      <div
        className={classList}
        key={'navigation-link-' + i}
        onClick={(e) => navigateToStep(i)}
      >
        {stepNames[i - 1]}
      </div>
    );
  });

  // Build the alert section if we have an alert:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = <AbsoluteAlert {...props.ui.alert} />;
  }

  // set up auto-save status
  let autosaveIcon = null;
  switch (props.ui.autosaveState) {
    case 'saving':
      autosaveIcon = (
        <span className="littlespinner portfolio-edit__header-autosave-icon"></span>
      );
      break;
    case 'success':
      autosaveIcon = (
        <span className="tc-check portfolio-edit__header-autosave-icon"></span>
      );
      break;
  }

  return (
    <div className="portfolio-edit__header-container">
      <div className="tc-header__wrap">
        <div className="tc-header tc-header--portfolio-edit row" id="tc__logo">
          <NavigationLink
            url={homeUrl}
            className="tc-header__logo tc-header__logo--tml-v2 cute-3-desktop left show-desktop hide-phone"
          >
            <span className="tc-header__logo-caption">TeachCycle</span>
          </NavigationLink>
          <div className="cute-5-desktop left show-desktop hide-phone tc__center-text portfolio-edit__header-name">
            {props.portfolio.name}
          </div>
          <div className="cute-4-desktop show-desktop hide-phone portfolio-edit__header-links">
            {autosaveIcon}
            <a
              onClick={handleSaveAndClose}
              className="tc__link portfolio-edit__header-link"
            >
              Save & Exit
            </a>
          </div>
        </div>
      </div>
      {alertNode}
      <div className="portfolio-edit__header-navigation">
        <div className="row">
          {navLinkNodes}
          <div
            onClick={navigateToNextStep}
            className="portfolio-edit__header-navigation-link portfolio-edit__header-navigation-link--next"
          >
            {getNextStepLabel()}
            <span className="tc-arrow-up"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  portfolio: PropTypes.object.isRequired,
  ui: PropTypes.object,
  portfolioChanged: PropTypes.bool.isRequired,
  savePortfolio: PropTypes.func.isRequired,
  publishPortfolio: PropTypes.func.isRequired,
};

export default Header;
