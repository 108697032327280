// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import AbsoluteAlert from './../../../Alerts/Absolute';
import Subheader from './../modules/PortfolioManagementSubheader';
import VisionArtifactDisplay from './modules/VisionArtifactDisplay';

const PortfolioVisionManagement = (props) => {
  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const vision = props.participant.vision;
  const designSummary = props.participant.design_summary;
  const displayOrder = props.displayOrder;

  let visionNode = null;
  if (vision) {
    let checkColor = '';
    let visionFontColor = '';
    if (!props.visionVisibility) {
      checkColor = ' p-portfolio-management-vision__check--grey';
      visionFontColor = ' p-porfolio-management-vision__text--grey';
    }
    visionNode = (
      <div>
        <div className="p-portfolio-management-vision__heading">Vision</div>
        <div className="row p-portfolio-management-vision__text-row">
          <div
            className={
              'cute-1-desktop cute-1-desktop-offset p-portfolio-management-vision__check phone-reset left' +
              checkColor
            }
            onClick={props.toggleVisionVisibility}
          >
            <span className="tc-check"></span>
          </div>
          <PurifiedHTML
            className={
              'cute-8-desktop cute-12-phone left p-portfolio-management-vision__text' +
              visionFontColor +
              ' ckeditor__output'
            }
            content={vision}
          />
        </div>
      </div>
    );
  }

  let designSummaryNode = null;
  if (designSummary) {
    let designSummaryFontColor = '';
    let checkColor = '';
    if (!props.designSummaryVisibility) {
      designSummaryFontColor = ' p-porfolio-management-vision__text--grey';
      checkColor = ' p-portfolio-management-vision__check--grey';
    }
    designSummaryNode = (
      <div>
        <div className="p-portfolio-management-vision__heading-ds">
          Design Summary
        </div>
        <div className="row p-portfolio-management-vision__text-row">
          <div
            className={
              'cute-1-desktop cute-1-desktop-offset p-portfolio-management-vision__check phone-reset left' +
              checkColor
            }
            onClick={() => props.toggleDesignSummaryVisibility()}
          >
            <span className="tc-check"></span>
          </div>
          <PurifiedHTML
            className={
              'cute-8-desktop cute-12-phone left p-portfolio-management-vision__text' +
              designSummaryFontColor +
              ' ckeditor__output'
            }
            content={designSummary}
          />
        </div>
      </div>
    );
  }

  let artifactsNode = null;
  if (displayOrder && displayOrder.length) {
    artifactsNode = (
      <VisionArtifactDisplay
        {...props}
        move={props.ui.move}
        animateElemPositions={props.animateElemPositions}
        completeMove={props.completeMove}
      />
    );
  }

  return (
    <div className="p-portfolio-management-vision">
      <Header page="ParticipantPortfolioVisionManagement" {...props} />
      <Subheader
        participantId={props.participant.id}
        participantName={
          props.participant.user.first_name +
          ' ' +
          props.participant.user.last_name
        }
        portfolioUuid={props.portfolio.uuid}
        shareModal={props.ui.shareModal}
        setUrlCopied={props.setShareUrlCopied}
      />
      {alertNode}
      <div>{visionNode}</div>
      <div>{designSummaryNode}</div>
      <div>{artifactsNode}</div>
    </div>
  );
};

PortfolioVisionManagement.propTypes = {
  participant: PropTypes.object.isRequired,
  displayOrder: PropTypes.array.isRequired,
  visionVisibility: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    .isRequired,
  toggleVisionVisibility: PropTypes.func.isRequired,
  designSummaryVisibility: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  toggleDesignSummaryVisibility: PropTypes.func.isRequired,
};

export default PortfolioVisionManagement;
