// @ts-nocheck FIXME
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import PropTypes from 'prop-types';

const RoleIcon = (props) => {
  let roleName;
  let roleAbbrev;
  let className;
  let offsetLeft;
  switch (props.role) {
    case 'BL_USER':
      roleName = 'Free User';
      roleAbbrev = 'F';
      className = 'free';
      offsetLeft = 35;
      break;
    case 'TC_TEACHER':
      roleName = 'Participant';
      roleAbbrev = 'P';
      className = 'participant';
      offsetLeft = 31;
      break;
    case 'TC_PARTNER':
      roleName = 'Partner Leader';
      roleAbbrev = 'L';
      className = 'lead';
      offsetLeft = 21;
      break;
    case 'BL_TEAM_MEMBER':
      roleName = 'Data Viewer';
      roleAbbrev = 'DV';
      className = 'data-viewer';
      offsetLeft = 29;
      break;
    case 'HELP_DESK_AGENT':
      roleName = 'Help Desk Agent';
      roleAbbrev = 'HD';
      className = 'helpdesk';
      offsetLeft = 16;
      break;
    case 'Coach':
    case 'BL_CONTRACT_COACH':
      roleName = 'Coach';
      roleAbbrev = 'C';
      className = 'coach';
      offsetLeft = 43;
      break;
    case 'BL_SERVICES_MANAGER':
      roleName = 'Services Manager';
      roleAbbrev = 'SM';
      className = 'services-manager';
      offsetLeft = 13;
      break;
    case 'TC_CONTENT_CURATOR':
      roleName = 'Content Curator';
      roleAbbrev = 'CC';
      className = 'content-curator';
      offsetLeft = 17;
      break;
    case 'BL_MANAGER':
      roleName = 'User Manager';
      roleAbbrev = 'UM';
      className = 'user-manager';
      offsetLeft = 23;
      break;
    case 'ADMIN':
      roleName = 'Super Admin';
      roleAbbrev = 'SA';
      className = 'super-admin';
      offsetLeft = 27;
      break;
    case 'Teacher':
      roleName = 'Teacher';
      roleAbbrev = 'T';
      className = 'teacher';
      offsetLeft = 37;
      break;
    case 'Principal':
      roleName = 'Principal';
      roleAbbrev = 'Pr';
      className = 'principal';
      offsetLeft = 35;
      break;
    case 'Superintendent / Assistant Superintendent':
      roleName = 'Superintendent / Assistant Superintendent';
      roleAbbrev = 'Sas';
      className = 'superintendent-assistant-superintendent';
      offsetLeft = -33;
      break;
    case 'School Administrator':
      roleName = 'School Administrator';
      roleAbbrev = 'Sa';
      className = 'school-administrator';
      offsetLeft = 3;
      break;
    case 'Other District Administrator':
      roleName = 'Other District Administrator';
      roleAbbrev = 'Oda';
      className = 'other-district-administrator';
      offsetLeft = -15;
      break;
    case 'Parent':
      roleName = 'Parent';
      roleAbbrev = 'Pa';
      className = 'parent';
      offsetLeft = 43;
      break;
    case 'Homeschool Educator':
      roleName = 'Homeschool Educator';
      roleAbbrev = 'He';
      className = 'homeschool-educator';
      offsetLeft = 37;
      break;
    case 'Other Education Professional':
      roleName = 'Other Education Professional';
      roleAbbrev = 'O';
      className = 'other-education-professional';
      offsetLeft = -15;
      break;
  }

  if (!roleAbbrev) {
    return null;
  }
  const includedClass = props.className ? `${props.className}` : '';

  return (
    <Tooltip
      tooltip={roleName}
      offsetLeft={offsetLeft}
      offsetTop={35}
      nowrap={true}
      delay={1}
    >
      <div className={`admin-users__role-icon ${className}${includedClass}`}>
        {roleAbbrev}
      </div>
    </Tooltip>
  );
};

RoleIcon.propTypes = {
  role: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default RoleIcon;
