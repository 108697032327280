// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import NavigationLink from '@legacy-components/react/NavigationLink';
import Breadcrumb from '@legacy-components/react/V5/Common/Breadcrumb';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDeleteAttendeeModal from './modules/ConfirmDeleteAttendeeModal';

// const EventParticipantRow = require('./modules/EventParticipantRow.jsx');
import EventApi from '@legacy-components/apis/V5/EventApi';

import { Router } from '@legacy-components/components/Router';
import AddExistingParticipantModal from './modules/AddEventParticipants/AddExistingEventParticipantModal';
import AddNewParticipantModal from './modules/AddEventParticipants/AddNewEventParticipantModal';
import Participants from './modules/Participants';
import Sessions from './modules/Sessions';

const EventParticipants = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [addingExistingParticipant, setAddingExistingParticipant] =
    React.useState(false);
  const [addingNewParticipant, setAddingNewParticipant] = React.useState(false);
  const [eventParticipants, setEventParticipants] = React.useState(
    props.event.participants
  );

  // confirmation modal for deleting an event attendee
  const [deleteAttendeeModal, setDeleteAttendeeModal] = React.useState({
    opened: false,
    loading: false,
    data: {},
  });

  const breadCrumbDest =
    props.pageName === 'sessions' ? 'Sessions' : 'Participants';

  const breadcrumb = [
    {
      text: 'Events',
      link: '/bl/events',
    },
    {
      text: `Event ${breadCrumbDest}`,
    },
  ];

  const handleDeleteAttendee = () => {
    // show the spinner
    const modal = Utils.clone(deleteAttendeeModal);
    modal.loading = true;
    setDeleteAttendeeModal(modal);

    return EventApi.deleteAttendee({
      event_id: modal.data.event_id,
      reservation_id: modal.data.reservation_id,
      registration_id: modal.data.registration_id,
    }).then(
      (response) => {
        // update list
        setEventParticipants(response.event_participants);
        setDeleteAttendeeModal({
          opened: false,
          loading: false,
          data: {},
        });
        // show success
        props.setAlert({
          type: 'success',
          message: 'Attendee deleted successfully',
        });
      },
      () => {
        setDeleteAttendeeModal({
          opened: false,
          loading: false,
          data: {},
        });
        props.setAlert({
          type: 'error',
          message: 'Something went wrong. Please try again later.',
        });
      }
    );
  };

  const addNewParticipant = (data) => {
    setLoading(true);

    EventApi.addNewParticipant(data).then(
      (response) => {
        setLoading(false);
        // show success
        props.setAlert({
          type: 'success',
          message: `${response.full_name} added to ${props.event.name}`,
          handleClose: () => props.setAlert({}),
        });
        setEventParticipants(response.event_participants);
        data.successCallback();
      },
      (response) => {
        setLoading(false);
        data.failureCallback(response.error);
      }
    );
  };

  const addExistingParticipant = (data) => {
    setLoading(true);

    EventApi.addExistingParticipant(data).then(
      (response) => {
        setLoading(false);
        // show success
        props.setAlert({
          type: 'success',
          message: `${response.full_name} added to ${props.event.name}`,
          handleClose: () => props.setAlert({}),
        });
        setEventParticipants(response.event_participants);
        data.successCallback();
      },
      (response) => {
        setLoading(false);
        if (response.type === 'user_not_found') {
          data.emailFailureCallback(response.error);
        } else {
          data.failureCallback(response.error);
        }
      }
    );
  };

  // delete event attendee modal
  let modalNode = null;
  if (deleteAttendeeModal.opened) {
    modalNode = (
      <ConfirmDeleteAttendeeModal
        close={() =>
          setDeleteAttendeeModal({
            opened: false,
            loading: false,
            data: {},
          })
        }
        hasCheckedIn={ArrayHelpers.hasElements(
          deleteAttendeeModal.data.event_sessions
        )}
        attendeeName={`${deleteAttendeeModal.data.user_first_name} ${deleteAttendeeModal.data.user_last_name}`}
        confirm={handleDeleteAttendee}
        loading={deleteAttendeeModal.loading}
      />
    );
  } else if (addingExistingParticipant) {
    modalNode = (
      <AddExistingParticipantModal
        close={() => setAddingExistingParticipant(false)}
        addExistingParticipant={addExistingParticipant}
        eventId={props.event.id}
        groups={props.event.groups}
        isLoading={loading}
        eventName={props.event.name}
        addNew={() => {
          setAddingExistingParticipant(false);
          setAddingNewParticipant(true);
        }}
      />
    );
  } else if (addingNewParticipant) {
    modalNode = (
      <AddNewParticipantModal
        close={() => setAddingNewParticipant(false)}
        addNewParticipant={addNewParticipant}
        eventId={props.event.id}
        groups={props.event.groups}
        occupations={props.occupations}
        isLoading={loading}
        eventName={props.event.name}
        addExisting={() => {
          setAddingNewParticipant(false);
          setAddingExistingParticipant(true);
        }}
      />
    );
  }

  const eventSessionsHeaders = [];
  const sessionNumMap = {};
  const sessionsTableHeaders = [];
  const sessionNums = [];
  // if there are multiple groups in a Basic event,
  // we just show the list of sessions at the top since they are the same for all groups
  const displayGroupId = props.event.sessions[0].group_id;
  const isSingleSession =
    props.event.groups.length === props.event.sessions.length ||
    (!ArrayHelpers.hasElements(props.event.groups) &&
      props.event.sessions.length === 1);
  props.event.sessions.forEach((session) => {
    // push the session table headers
    sessionNumMap[session.id] = session.num;
    if (session.num > sessionsTableHeaders.length) {
      sessionsTableHeaders.push(
        <th className="admin-table__header" key={`event-session-${session.id}`}>
          Session {session.num}
        </th>
      );
      sessionNums.push(sessionsTableHeaders.length);
    }

    // setup the sessions headers copy
    const groupHeaderIndex = Utils.getItemIndexFromArrayOfObjects(
      eventSessionsHeaders,
      'id',
      session.group_id
    );
    const noGroupHeaderIndex = Utils.getItemIndexFromArrayOfObjects(
      eventSessionsHeaders,
      'id',
      'na'
    );

    // the text of the session date/start time/end time depends on what data we have
    let sessionDescription = `${session.name}: ` || `Session ${session.num}: `;
    // if there is only one session (per group, or with no groups), don't show the name
    if (isSingleSession) {
      sessionDescription = '';
    }
    // only show dates/times if the session date is set
    if (session.session_date_formatted) {
      const sessionDate = moment(session.session_date_formatted).format(
        'MMMM D, YYYY'
      );
      // only show times if both times are set
      let sessionTimes = '';
      if (
        session.session_start_time_formatted &&
        session.session_end_time_formatted
      ) {
        const timezone =
          props.event.time_zone && props.event.time_zone.pytz_timezone;
        sessionTimes = `, ${Utils.formatEventSessionTime(
          session.session_start_time_formatted,
          session.session_end_time_formatted,
          timezone
        )}`;
      }
      sessionDescription += `${sessionDate + sessionTimes}`;
    } else {
      sessionDescription += 'TBD';
    }

    const sessionNode = (
      <div
        className="admin-v5__session-times v5__body-copy"
        key={`event-session-${session.id}`}
      >
        {sessionDescription}
      </div>
    );

    if (groupHeaderIndex === -1 && noGroupHeaderIndex === -1) {
      eventSessionsHeaders.push({
        id: 'na',
        nodes: [sessionNode],
      });
    } else if (
      groupHeaderIndex === -1 &&
      noGroupHeaderIndex !== -1 &&
      (!ArrayHelpers.hasElements(props.event.groups) ||
        session.group_id === displayGroupId)
    ) {
      eventSessionsHeaders[noGroupHeaderIndex].nodes.push(sessionNode);
    } else if (groupHeaderIndex !== -1) {
      eventSessionsHeaders[groupHeaderIndex].nodes.push(sessionNode);
    }
  });

  // Render session headers
  const eventSessionsHeaderNodes = [];
  eventSessionsHeaders.forEach((sessionHeader, i) => {
    eventSessionsHeaderNodes.push(
      <div
        className="admin-v5__group-container"
        key={`group-${sessionHeader.id}-sessions-${i}`}
      >
        {sessionHeader.nodes}
      </div>
    );
  });

  // Make participants the default page
  let visiblePage = (
    <Participants
      {...props}
      setAddingExistingParticipant={setAddingExistingParticipant}
      setDeleteAttendeeModal={setDeleteAttendeeModal}
      eventParticipants={eventParticipants}
      eventGroups={props.event.groups}
      setEventParticipants={setEventParticipants}
    />
  );

  let particiipantsActive = ' active';
  let sessionActive = '';
  let participantsProps = {};
  let sessionProps = {
    onClick: () => Router.route(`/bl/event/${props.event.id}/sessions`),
  };
  if (props.pageName === 'sessions') {
    particiipantsActive = '';
    sessionActive = ' active';
    visiblePage = (
      <Sessions event={props.event} eventParticipants={eventParticipants} />
    );
    participantsProps = {
      onClick: () => Router.route(`/bl/event/${props.event.id}/participants`),
    };
    sessionProps = {};
  }

  return (
    <div className="tc__page">
      <Header page="EventParticipants" {...props} />
      {modalNode}
      <div className="row row--1200 participant-v5__breadcrumb">
        <div className="cute-12-desktop">
          <Breadcrumb trail={breadcrumb} divider=" / " />
        </div>
      </div>
      <div className="row row--1200">
        <div className="cute-12-desktop">
          <h3 className="v5__heading-3 v5__heading-with-icon">
            {props.event.name}
          </h3>
          <NavigationLink
            url={`/bl/event/${props.event.id}`}
            className="v5__link"
          >
            <span className="tc-v4-pencil admin-v5__edit-event-icon" />
          </NavigationLink>
        </div>
      </div>
      <div className="row row--1200">
        <div className="cute-12-desktop admin-v5__heading--subtitle">
          {props.event.display_name}
        </div>
      </div>
      <div className="row row--1200">
        <div className="cute-12-desktop v5__tabbed-menu-bar">
          <div
            className={`v5__tabbed-menu-item${particiipantsActive}`}
            {...participantsProps}
          >
            Participants
          </div>
          <div
            className={`v5__tabbed-menu-item${sessionActive}`}
            {...sessionProps}
          >
            Sessions
          </div>
        </div>
      </div>
      {visiblePage}
    </div>
  );
};

const WrappedEventParticipants = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => <EventParticipants {...props} setAlert={setAlert} />}
  </HeaderConsumer>
);

EventParticipants.propTypes = {
  ui: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default WrappedEventParticipants;
