// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ArtifactOverlay from './../../Common/ArtifactOverlay/ArtifactOverlay';
import Header from './../modules/Header';
import VisionArtifactCapsule from './modules/VisionArtifactCapsule';

const PortfolioPresentationVision = (props) => {
  // show a modal if one is opened
  let modalNode = null;
  if (props.ui.artifactModal.opened) {
    modalNode = (
      <ArtifactOverlay
        {...props.ui.artifactModal}
        analyticsLabel="ppd3_participant_portfolio_presentation_UI_outcome"
      />
    );
  }

  // convenience let for participant name
  const name =
    props.participant.user.first_name + ' ' + props.participant.user.last_name;

  // set up vision
  let visionNode;
  if (props.participant.vision) {
    visionNode = (
      <div className="">
        <h2 className="tc__headline-2 tc__center-text">My Vision</h2>
        <PurifiedHTML
          className="tc__body-copy ckeditor__output p-portfolio-presn__home-vision-text"
          content={props.participant.vision}
        />
      </div>
    );
  }

  // set up design summary
  let designSummaryNode;
  if (props.participant.design_summary) {
    designSummaryNode = (
      <div
        className={visionNode ? 'p-portfolio-presn__vision-ds-container' : ''}
      >
        <h2 className="tc__headline-2 tc__center-text">My Design Summary</h2>
        <PurifiedHTML
          className="tc__body-copy ckeditor__output p-portfolio-presn__home-vision-text"
          content={props.participant.design_summary}
        />
      </div>
    );
  }

  // hide the top section capsule if there is no vision/ds text to show
  let topSectionNode = null;
  if (visionNode || designSummaryNode) {
    topSectionNode = (
      <div className="p-portfolio-presn__vision-top-section">
        <div className="p-portfolio-presn__vision-top-section-bkgd show-desktop hide-phone"></div>
        <div className="row">
          <div className="cute-8-desktop cute-12-phone center-desktop">
            <div className="p-portfolio-presn__vision-top-box">
              {visionNode}
              {designSummaryNode}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // set up list of vision artifacts
  const artifactsNode =
    props.artifacts && props.artifacts.length ? (
      <div className="row">
        <div className="cute-6-desktop center-desktop cute-12-phone p-portfolio-presn__vision-bottom-section">
          <div className="tc__center-text tc__headline-2 p-portfolio-presn__bottom-section-headline">
            Artifacts that Support my Vision
          </div>
          {props.artifacts.map((artifact) => {
            return (
              <VisionArtifactCapsule
                key={'artifact-' + artifact.id}
                artifact={artifact}
                artifactModal={props.ui.artifactModal}
              />
            );
          })}
        </div>
      </div>
    ) : null;

  return (
    <div className="tc__page">
      <Header
        portfolioUuid={props.portfolioUuid}
        participantName={name}
        user={props.user}
      />
      {modalNode}

      {topSectionNode}
      {artifactsNode}
    </div>
  );
};

PortfolioPresentationVision.propTypes = {
  portfolioUuid: PropTypes.string.isRequired,
  participant: PropTypes.object.isRequired,
  artifacts: PropTypes.array,
};

export default PortfolioPresentationVision;
