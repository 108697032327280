// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import UserApi from './../../../apis/UserApi';
import Utils from './../../../components/DynamicUtils';
import S3 from './../../../components/S3';

const ProfileImageUpload = (props) => {
  let inputFiles = [];

  const handleFilesChange = (e) => {
    e.preventDefault();

    inputFiles = e.target.files;
    submitFiles(inputFiles);
  };

  const submitFiles = (inputFiles) => {
    props.clearUploadMessage();
    let file;
    for (let i = 0; i < inputFiles.length; i++) {
      // if user selected more than one file to upload only upload the first
      if (i > 0) {
        break;
      }

      // Add some of the POST fields that the backend needs
      file = {
        lastModified: inputFiles[i]['lastModified'],
        lastModifiedDate: inputFiles[i]['lastModifiedDate'],
        name: inputFiles[i]['name'],
        size: inputFiles[i]['size'],
        type: inputFiles[i]['type'],
        uuid: Utils.uuid(),
      };

      // validate file type
      const acceptedExtensions = ['png', 'jpg', 'jpeg'];
      let extension = file.name.split('.');
      extension = extension[extension.length - 1].toLowerCase();
      if (!acceptedExtensions.includes(extension)) {
        props.setUploadMessage({
          type: 'error',
          message: 'File must be a PNG or JPG.',
        });
        continue;
      }

      // add the unique id for image reference
      inputFiles[i]['uuid'] = file['uuid'];
      const callbackFields = {
        userId: props.participant.id,
        inputFile: file,
      };

      // Upload the file to S3
      S3.profileImageUpload(
        inputFiles[i],
        UserApi.prepareImageUpload,
        UserApi.uploadUserImage,
        props.afterUploadCallback,
        callbackFields
      ).then(
        function onSuccess(fileData) {
          fileData['message'] = 'Upload Complete';
          fileData['status'] = 'after';
          props.clearUploadMessage();
          props.setPreview(fileData['preview']);
        },
        function onError(fileData) {
          // See if the file has the error property
          let error = fileData['error'];
          if (!error) {
            (error = 'There was an error uploading your file.'), fileData;
          }

          props.setUploadMessage({
            type: 'error',
            message: error,
          });

          // Clear the file from the store
          props.updateProfileImageUploadProgress(null);
        },
        function onProgress(fileData) {
          // If there is an error, abort
          if (fileData.status === 'error') {
            return;
          }
          fileData['message'] =
            (fileData.progress > 100 ? 100 : fileData.progress) + '%';
          fileData['status'] = 'during';
          props.updateProfileImageUploadProgress(fileData);
        }
      );
    }
  };

  const userRoles = props.user.auth_roles;
  let editNode = null;
  if (
    props.user.id === props.participant.id ||
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, [
      'TC_DATA_EDITOR',
      'BL_SERVICES_MANAGER',
      'BL_CONTRACT_COACH',
      'BL_MANAGER',
      'ADMIN',
    ])
  ) {
    editNode = (
      <div>
        <input
          className="participant-image__upload-input"
          type="file"
          onChange={handleFilesChange}
          value=""
        />
        <div className="participant-image__upload-overlay">Edit</div>
      </div>
    );
  }

  // setup upload message
  let messageNode = null;
  if (!Utils.isEmpty(props.uploadMessage)) {
    messageNode = (
      <div
        className={
          'participant-image__upload-response participant-image__upload-response--' +
          props.uploadMessage.type
        }
      >
        {props.uploadMessage.message}
      </div>
    );
  }
  if (props.uploadLoading.status === 'during') {
    messageNode = (
      <div className="participant-image__upload-response">
        <div className="participant-image__upload-loading-container">
          <div className="spinner-icon participant-image__upload-spinner"></div>
          {props.uploadLoading.message}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        className={
          'participant-image__upload ' +
          props.className +
          ' ' +
          props.className +
          '-uploader'
        }
      >
        {props.children}
        {editNode}
      </div>
      {messageNode}
    </div>
  );
};

ProfileImageUpload.propTypes = {
  user: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  updateProfileImageUploadProgress: PropTypes.func.isRequired,
  afterUploadCallback: PropTypes.func.isRequired,
};

export default ProfileImageUpload;
