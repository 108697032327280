// @ts-nocheck FIXME
import Video from '@legacy-components/react/Utilities/Video';
import PropTypes from 'prop-types';

const ParticipantMeetingVideo = (props) => {
  const activeRecording =
    props.recording ||
    (props.recordings && props.recordings[props.recordingIndex]) ||
    {};

  if (props.start_time >= 0) {
    return (
      <div>
        <Video
          preview={props.content && props.content.preview}
          formats={props.content && props.content.formats}
          start={props.start_time}
          end={props.end_time}
          height={320}
          playsinline={true}
        />
        <h2 className="v5__heading-6 meeting__detail-headline">{`Recording ${
          props.recordingIndex + 1
        }`}</h2>
      </div>
    );
  } else {
    return (
      <div>
        <Video
          preview={activeRecording.content && activeRecording.content.preview}
          formats={activeRecording.content && activeRecording.content.formats}
          height={320}
        />
        {props.recording ? null : (
          <h2 className="v5__heading-6 meeting__detail-headline">{`Recording ${
            props.recordingIndex + 1
          }`}</h2>
        )}
      </div>
    );
  }
};

ParticipantMeetingVideo.propTypes = {
  content: PropTypes.shape({
    formats: PropTypes.array,
    preview: PropTypes.string,
  }),
  start_time: PropTypes.number,
  end_time: PropTypes.number,
  recordingIndex: PropTypes.number,
  recording: PropTypes.object,
  recordings: PropTypes.array,
};

export default ParticipantMeetingVideo;
