// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Auth from '@legacy-components/components/Auth';
import Utils from './../../../../components/DynamicUtils';
import AbsoluteAlert from './../../../Alerts/Absolute';
import HeaderDropdown from './Dropdown/Dropdown';
import HeaderLink from './HeaderLink';
import HeaderSearch from './HeaderSearch';
import LoginModal from './LoginModal';
import SelectedParticipantWidget from './ParticipantWidget/SelectedParticipantWidget';
import RegistrationModal from './RegistrationModal';

const Header = (props) => {
  const lab = useLegacyLabContext();

  // the header looks different for free users
  const isFreeUser = Utils.isFreeUser.call(lab);
  let authNode = null;

  // setup desktop and mobile dropdowns
  const setupDropdownNode = (layout) => {
    return (
      <HeaderDropdown
        {...props}
        layout={layout}
        page={props.page}
        openMarketingDropdown={props.openMarketingDropdown}
        closeMarketingDropdown={props.closeMarketingDropdown}
        marketingDropdownOpened={props.ui.marketingDropdownOpened}
      />
    );
  };

  // set up the logo link destination
  const setupLogoLink = () => {
    // for free and logged out users, link to marketing page
    // for enterprise and internal users, link to their home page
    if (Utils.isServicesManager.call(lab) || Utils.isContractCoach.call(lab))
      return '/lab/coach/' + props.user.id + '/home';
    if (Utils.isPartnerLeader.call(lab)) {
      if (
        Utils.participantHasFeatureFlag.call(
          lab,
          'UNIFIED_NAV_BL_LOGO_ROUTES_TO_REPORTING_FOR_PARTNERS'
        )
      )
        return `${process.env.REACT_APP_REPORTING_URL}`;
      if (lab.pageData.user.active_lead === 'v5') {
        return `/lab/leader/${lab.pageData.user.leads[0].id}/home`;
      }
    }
    if (Utils.isParticipant.call(lab)) return '/lab/home';
    if (
      Utils.isAdmin.call(lab) ||
      Utils.isManager.call(lab) ||
      Utils.isDataEditor.call(lab) ||
      Utils.isContentCurator.call(lab) ||
      Utils.isDataViewer.call(lab, props.user)
    )
      return '/bl/admin/home';
    return '/';
  };

  // setup search node
  let searchNode = (
    <HeaderSearch
      salt={props.salt}
      setSalt={props.setSalt}
      searchText={props.ui.searchText}
      mobileSearchOpened={props.ui.mobileSearchOpened}
      openMobileSearch={props.openMobileSearch}
      closeMobileSearch={props.closeMobileSearch}
      searchAutocompleteResults={props.ui.searchAutocompleteResults}
      searchAutocomplete={props.searchAutocomplete}
    />
  );

  // if we have no user, check if we need to show login/register modal
  // and display the Sign Up/Login buttons in the header
  if (!props.user || !props.user.id) {
    // show login or registration modal if necessary
    if (props.ui.showLoginModal) {
      authNode = <LoginModal {...props} />;
    } else if (props.ui.showRegisterModal) {
      authNode = <RegistrationModal {...props} />;
    }
    return (
      <div>
        {authNode}
        <div className={'tc-header__wrap tc-header__wrap--v2'}>
          <div className="tc-header tc-header--v2 row row--1200 show-desktop hide-phone">
            <a
              href="/"
              id="tc__logo"
              className="tc-header__logo tc-header__logo--bl4 cute-3-desktop left"
            >
              <span className="tc-header__logo-caption">BetterLesson</span>
            </a>
            {searchNode}

            <HeaderLink
              user={props.user}
              page={props.page}
              isFreeUser={isFreeUser}
              openMarketingDropdown={props.openMarketingDropdown}
              closeMarketingDropdown={props.closeMarketingDropdown}
              marketingDropdownOpened={props.ui.marketingDropdownOpened}
            />

            <div className="cute-2-desktop tc-header__visitor-button-container">
              <a
                id="blRegistrationLink"
                href="#"
                onClick={props.openRegisterModal}
                className="tc-header__visitor-link tc__link"
              >
                Sign Up{' '}
              </a>
              <a
                id="blLoginLink"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  props.openLoginModal('header');
                }}
                className="tc-header__visitor-button tc-header__visitor-button--v2 bl4__btn bl4__btn--blue"
              >
                Log In
              </a>
            </div>
          </div>

          <div
            id="tc-mobile-header"
            className="tc-header tc-header--mobile fixed row show-phone hide-desktop"
          >
            <div className="cute-8-phone cute-2-phone-offset left">
              <a href="/" className="tc-header__logo tc-header__logo--bl4">
                <span className="tc-header__logo-caption">BetterLesson</span>
              </a>
            </div>
          </div>
          <div className="tc-header__mobile-element-container">
            <div className="header__dropdown-container">
              {setupDropdownNode('mobile')}
            </div>
            {searchNode}
          </div>
        </div>
      </div>
    );
  }

  // if there is a user logged in, change the logo link
  // and replace the buttons with the dropdown menu and notification icon
  const logoLink = setupLogoLink();

  // participant toggle coach participant view widget
  const adminToggleWidget = null;
  let selectedParticipantWidget = null;
  let widgetVisibleClass = '';
  let adminParticipantNavigationNode = null;

  let isWidgetVisible = false;
  if (props.headerParticipant) {
    // show the widget if the user is a DC and the coach of the participant or if they are not a DC and have coach access
    if (Auth.hasCoachAccess(props.user)) {
      isWidgetVisible = true;
    }

    if (isWidgetVisible) {
      widgetVisibleClass = ' header__widget-visible';
      selectedParticipantWidget = (
        <div className="tc-header__coach-view tc-header__coach-view--actionsshow-desktop hide-phone left">
          <SelectedParticipantWidget
            ui={props.ui}
            openTeacherViewModal={props.openTeacherViewModal}
            closeTeacherViewModal={props.closeTeacherViewModal}
            participant={props.headerParticipant}
            page={props.page}
          />
        </div>
      );

      adminParticipantNavigationNode = (
        <div className="tc-header__admin-nav tc-header__admin-nav--v2">
          <div className="tc-header__admin-nav-content">
            {adminToggleWidget}
            {selectedParticipantWidget}
          </div>
        </div>
      );
    }
  }

  // update search node depending on header participant widget
  searchNode = (
    <HeaderSearch
      salt={props.salt}
      setSalt={props.setSalt}
      searchText={props.ui.searchText}
      mobileSearchOpened={props.ui.mobileSearchOpened}
      openMobileSearch={props.openMobileSearch}
      closeMobileSearch={props.closeMobileSearch}
      searchAutocompleteResults={props.ui.searchAutocompleteResults}
      searchAutocomplete={props.searchAutocomplete}
      headerParticipant={isWidgetVisible ? props.headerParticipant : null}
    />
  );

  // necessary for scrolling pages with require fixed headers
  let headerClass = '';
  if (props.page === 'TeacherDashboard') {
    headerClass = ' tc-header--teacher-dashboard';
  } else if (props.page === 'PartnerPortfolioTool') {
    headerClass = ' tc-header--partner-portfolio-tool';
  }

  // set up logo image depending on user's roles (free/logged out users see generic BL logo)
  const logoClass =
    Auth.isEnterprise(props.user) ||
    Auth.hasCoachAccess(props.user) ||
    Utils.isDataViewer.call(lab, props.user) ||
    Utils.isManager.call(lab, props.user) ||
    Utils.isAdmin.call(lab, props.user)
      ? ''
      : ' tc-header__logo--bl4';

  // set up an alert on the page if necessary
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // Build the alert node if needed
  let contextAlertNode;
  if (Object.keys(props.alert).length > 0) {
    contextAlertNode = (
      <AbsoluteAlert
        {...props.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
        handleClose={() => props.setAlert({})}
      />
    );
  }
  return (
    <header>
      {contextAlertNode}
      {alertNode}
      <div
        className={
          'tc-header__wrap tc-header__wrap--v2 ' +
          headerClass +
          widgetVisibleClass
        }
      >
        <div
          data-testid="desktop-header"
          className="tc-header tc-header--v2 row row--1200 show-desktop hide-phone"
        >
          <a
            href={logoLink}
            id="tc__logo"
            className={'tc-header__logo cute-3-desktop left' + logoClass}
          >
            <span className="tc-header__logo-caption">BetterLesson Lab</span>
          </a>
          {searchNode}
          <HeaderLink
            user={props.user}
            page={props.page}
            isFreeUser={isFreeUser}
            openMarketingDropdown={props.openMarketingDropdown}
            closeMarketingDropdown={props.closeMarketingDropdown}
            marketingDropdownOpened={props.ui.marketingDropdownOpened}
          />
          <div className="tc-header__rightside">
            <div className="row clear">
              <div className="header__dropdowns">
                {setupDropdownNode('desktop')}
              </div>
            </div>
          </div>
          {adminParticipantNavigationNode}
        </div>

        <div
          id="tc-mobile-header"
          data-no-layer-close="1"
          className="tc-header tc-header--mobile fixed row show-phone hide-desktop"
        >
          <div className="cute-8-phone cute-2-phone-offset left">
            <a href={logoLink} className={'tc-header__logo' + logoClass}>
              <span className="tc-header__logo-caption">BetterLesson Lab</span>
            </a>
          </div>
        </div>
        <div
          data-testid="phone-header"
          className="header__dropdown-container show-phone hide-desktop"
        >
          {setupDropdownNode('mobile')}
        </div>
        <div className="show-phone hide-desktop">{searchNode}</div>
      </div>
    </header>
  );
};

export default Header;
