// @ts-nocheck FIXME
import PartnerRow from './PartnerRow';

const PartnerTable = (props) => {
  let directionalArrow = (
    <div className="tc-browser-arrow ppd-3-coach-table__arrow ppd-3-coach-table__arrow--up"></div>
  );
  if (props.ui.sortDirection !== 'ascending') {
    directionalArrow = (
      <div className="tc-browser-arrow ppd-3-coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  // Handle sorting
  let partnerSortIndicator;
  let participantsSortIndicator;
  let growthSortIndicator;
  let meetingsSortIndicator;
  let artifactsSortIndicator;
  let partnerLeadersSortIndicator;

  switch (props.ui.sortColumn) {
    case 'partners':
      partnerSortIndicator = directionalArrow;
      break;
    case 'participants':
      participantsSortIndicator = directionalArrow;
      break;
    case 'growth':
      growthSortIndicator = directionalArrow;
      break;
    case 'meetings':
      meetingsSortIndicator = directionalArrow;
      break;
    case 'artifacts':
      artifactsSortIndicator = directionalArrow;
      break;
    case 'partner_leaders':
      partnerLeadersSortIndicator = directionalArrow;
      break;
  }

  let partnerTableNode = null;

  if (props.partners && props.partners.length > 0) {
    partnerTableNode = (
      <div>
        <div className="cute-12-desktop ppd-3-coach-table__header">
          <div className="row">
            <div className="cute-4-desktop">
              <div className="ppd-3-coach-table__header-col">
                <span
                  className={
                    'ppd-3-coach-table__header-caption  ppd-3-coach-table__f-name-headline tc__link ' +
                    (partnerSortIndicator ? 'tc__link--black' : '')
                  }
                  onClick={() => {
                    props.sortBy('partners');
                  }}
                >
                  Partner
                </span>
                {partnerSortIndicator}
              </div>
            </div>
            <div
              className="cute-3-desktop"
              onClick={() => {
                props.sortBy('participants');
              }}
            >
              <div className="ppd-3-coach-table__header-col">
                <span
                  className={
                    'ppd-3-coach-table__header-caption tc__link ' +
                    (participantsSortIndicator ? 'tc__link--black' : '')
                  }
                >
                  Participants
                </span>
                {participantsSortIndicator}
              </div>
            </div>
            <div className="cute- partner_highlights_cute_12">
              <div className="ppd-3-coach-table__header-col">
                <span
                  onClick={() => {
                    props.sortBy('growth');
                  }}
                  className={
                    'ppd-3-coach-table__header-caption tc__link ' +
                    (growthSortIndicator ? 'tc__link--black' : '')
                  }
                >
                  Growth
                </span>
                {growthSortIndicator}
              </div>
            </div>
            <div
              className="cute- partner_highlights_cute_10"
              onClick={() => {
                props.sortBy('meetings');
              }}
            >
              <div className="ppd-3-coach-table__header-col">
                <span
                  className={
                    'ppd-3-coach-table__header-caption tc__link ' +
                    (meetingsSortIndicator ? 'tc__link--black' : '')
                  }
                >
                  Meetings
                </span>
                {meetingsSortIndicator}
              </div>
            </div>
            <div
              className="cute-1-desktop"
              onClick={() => {
                props.sortBy('artifacts');
              }}
            >
              <div className="ppd-3-coach-table__header-col">
                <span
                  className={
                    'ppd-3-coach-table__header-caption tc__link ' +
                    (artifactsSortIndicator ? 'tc__link--black' : '')
                  }
                >
                  Artifacts
                </span>
                {artifactsSortIndicator}
              </div>
            </div>
            <div
              className="cute- partner_highlights_cute_partner_leaders partner_highlights_menu"
              onClick={() => {
                props.sortBy('partner_leaders');
              }}
            >
              <div className="ppd-3-coach-table__header-col">
                <span
                  className={
                    'ppd-3-coach-table__header-caption tc__link ' +
                    (partnerLeadersSortIndicator ? 'tc__link--black' : '')
                  }
                >
                  Partner Leaders
                </span>
                {partnerLeadersSortIndicator}
              </div>
            </div>
          </div>
        </div>

        {props.partners &&
          props.partners.map((partner, i) => {
            return (
              <PartnerRow key={partner.id} partner={partner} position={i} />
            );
          })}
      </div>
    );
  }

  return (
    <div className="cute-12-desktop ppd-3-coach-table">{partnerTableNode}</div>
  );
};

export default PartnerTable;
