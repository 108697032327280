// @ts-nocheck FIXME
import React from 'react';
import ReactDOM from 'react-dom';
import StrategyApi from './../../../apis/StrategyApi';
import Analytics from './../../../components/Analytics';
import LayerManager from './../../../components/LayerManager';
import StrategyMultiSelectStore from './../../../stores/V2/StrategyMultiSelectStore';
import LayerClose from './../../LayerManager/LayerClose';
import MultiSelect from './MultiSelectCustom';

class CoachPinDialogue extends React.Component {
  constructor(props) {
    super(props);
    StrategyMultiSelectStore.actionDispatchSync('init', props);
    this.state = StrategyMultiSelectStore.getState();
  }

  token = null;

  getAnalyticsEventLabel = () => {
    return 'from_coach_pin_strategy_modal';
  };

  componentDidMount() {
    this.token = StrategyMultiSelectStore.subscribe(this.onStoreChange);
  }

  componentWillUnmount() {
    StrategyMultiSelectStore.actionDispatchSync('init', {});
    StrategyMultiSelectStore.unsubscribe(this.token);
  }

  onStoreChange = () => {
    this.setState(StrategyMultiSelectStore.getState());
  };

  handleAddPin = (e) => {
    e.preventDefault();

    StrategyMultiSelectStore.actionDispatchSync('setBtnDisabled', true);
    StrategyMultiSelectStore.actionDispatchSync('setErrors', {});

    const note = ReactDOM.findDOMNode(this.refs.note).value;

    // make sure at least one name is selected
    if (!this.state.teachers.length && !this.state.teams.length) {
      StrategyMultiSelectStore.actionDispatchSync('setBtnDisabled', false);
      StrategyMultiSelectStore.actionDispatchSync('setErrors', {
        names: ['You must select at least one name'],
      });
      return;
    }

    // Log the Analytics events:
    Analytics.logEvent({
      category: 'strategy',
      action: 'pin',
      label: this.getAnalyticsEventLabel(),
    });
    if (note) {
      Analytics.logEvent({
        category: 'strategy',
        action: 'add_note',
        label: this.getAnalyticsEventLabel(),
      });
    }

    // add the strategy to the selected teachers' pinboards
    StrategyApi.addStrategyCoach({
      coachId: this.props.user.id,
      userId: this.state.teachers,
      teamId: this.state.teams,
      strategyId: this.props.strategy.id,
      note: note,
    })
      .then(
        function (response) {
          LayerManager.close();
          StrategyMultiSelectStore.actionDispatch('setBtnDisabled', false);
          StrategyMultiSelectStore.actionDispatch('setSelections', {
            teachers: [],
            teams: [],
          });
          this.props.onShowAlert('success', 'Strategy successfully pinned!');
        }.bind(this)
      )
      .catch(
        function (response) {
          LayerManager.close();
          StrategyMultiSelectStore.actionDispatch('setBtnDisabled', false);
          StrategyMultiSelectStore.actionDispatch('setSelections', {
            teachers: [],
            teams: [],
          });
          this.props.onShowAlert(
            'error',
            'Sorry, there was a problem pinning this strategy. Please try again later.'
          );
        }.bind(this)
      );
  };

  render() {
    let namesErrorClass = '';
    let namesErrorNode = null;
    if (this.state.errors.names) {
      namesErrorClass = ' error';
      namesErrorNode = (
        <div className="tc__error">
          {this.state.errors.names.map(function (error) {
            return <div key={'error-' + error}>{error}</div>;
          })}
        </div>
      );
    }

    const btnDisabledClass = this.state.btnDisabled ? ' disabled' : '';

    const closeModalAnalyticsEvent = {
      category: 'strategy',
      action: 'close',
      label: this.getAnalyticsEventLabel(),
    };

    return (
      <div className="coach-pin">
        <LayerClose
          href="#"
          className="modal__close"
          analyticsEvent={closeModalAnalyticsEvent}
        >
          <div className="tc-x"></div>
        </LayerClose>
        <h3 className="coach-pin__headline tc__headline-3">Pin Strategy</h3>
        <form className="coach-pin__form">
          <div className="coach-pin__multiselect-container">
            <label className="coach-pin__label">For</label>
            <div className={'coach-pin__multiselect' + namesErrorClass}>
              <MultiSelect
                options={this.props.user.constituents}
                placeholder="Teacher Name or Team Name"
                analyticsLabel={this.getAnalyticsEventLabel()}
              />
            </div>
            <div className="clearfix"></div>
            {namesErrorNode}
          </div>
          <textarea
            ref="note"
            className="coach-pin__notes tc__input"
            placeholder="Add a planning note for this Strategy"
          ></textarea>
          <a
            href="#"
            onClick={this.handleAddPin}
            className={
              'tc__btn tc__btn--blue tc__btn--small' + btnDisabledClass
            }
          >
            Pin Strategy
          </a>
        </form>
      </div>
    );
  }
}

export default CoachPinDialogue;
