// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import NavigationLink from './../NavigationLink';

const Code404 = (props) => {
  return (
    <div className="code-404">
      <Header {...props} page="StrategyBrowser" />

      <div className="row">
        <div className="cute-8-desktop center-desktop cute-12-phone tc__center-text">
          <h1 className="tc__headline-3">Oops. Something is broken.</h1>
          <div className="tc__body-copy">
            The page you’re looking for has been moved, or you don’t have
            permission to access it.
          </div>
          <div className="tc__body-copy">
            But we&apos;re pretty sure it&apos;s the cat&apos;s fault.{' '}
            <NavigationLink
              url={`${process.env.REACT_APP_TEACHING_URL}/search`}
              className="tc__link"
            >
              Search for Lessons and Strategies
            </NavigationLink>
          </div>
          <img src="/legacy-images/404-pets.png" className="code-404__img" />
        </div>
      </div>
    </div>
  );
};

export default Code404;
