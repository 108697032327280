// @ts-nocheck FIXME
import useResourceAuth, {
  resourcePerms,
} from '@legacy-components/react/V5/Common/ParticipantEngagementDetails/resourceAuth';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import PropTypes from 'prop-types';
import NotificationCountBadge from '../../../Common/NotificationCountBadge';
import DomainTag from './DomainTag';

const GoalDisplayView = (props) => {
  const { getDesignDocLink, hasResourcePerms } = useResourceAuth();
  // change the background color if we are filtering by this goal
  const filterClass = props.isFiltering ? 'workspace__goal--filtering' : '';

  // show checkmark as filled in or empty depending on whether goal is completed or not
  const checkClass = props.goalCompleted
    ? 'tc-v5-milestone-complete'
    : 'tc-v5-milestone';
  // title is slightly greyed out for completed goals
  const titleClass = props.goalCompleted
    ? 'workspace__goal-title--completed'
    : '';
  // add gradient border to the card if goal is completed
  const borderClass = props.goalCompleted ? 'workspace__goal--completed' : '';
  // make sure the check mark inside the flag has correct color
  const flagCheckClass = props.goalCompleted
    ? 'workspace__goal-flag-check--completed'
    : '';

  // set up # of timeline entries tagged with this goal
  let entriesCountNode;
  if (props.entriesCount > 0) {
    entriesCountNode = (
      <NotificationCountBadge
        count={props.entriesCount}
        className="workspace__entry-count-badge"
        clickHandler={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          // This makes it a toggle
          props.changeFilter(props.isFiltering ? null : props.id);
        }}
      />
    );
  }

  // set up goal expand/collapse arrow
  const arrowClass = props.goalExpanded
    ? 'workspace__goal-expand-arrow--expanded'
    : '';

  // show goal details if expanded
  const bodyNode = props.goalExpanded ? (
    <div className="workspace__goal-body">
      {props.completed && props.reflection_note && (
        <div className="workspace__goal-reflection-container">
          <span className="tc-v5-left-quotes workspace__goal-reflection-icon"></span>
          <div className="workspace__goal-reflection-note v5__body-copy">
            {props.reflection_note}
          </div>
        </div>
      )}
      <div className="workspace__goal-description v5__body-copy">
        {props.description}
      </div>
      <div>{props.children}</div>
    </div>
  ) : null;

  const renderCompleteGoalIcon = () =>
    props.showCompleteGoalIcon ? (
      <div
        className={`workspace__goal-complete-check ${checkClass}`}
        data-testid="complete-goal-icon"
        onClick={props.toggleGoalCompleted}
      >
        <span className="path1"></span>
        <span className={`path2 ${flagCheckClass}`}></span>
      </div>
    ) : (
      <Tooltip
        tipClass="v5__tooltip--wide"
        tooltip="Only priority outcomes can be changed or marked as complete"
      >
        <div
          className={`workspace__goal-complete-disabled ${checkClass}`}
          data-testid="complete-goal-icon-disabled"
        >
          <span className="path1"></span>
          <span className={`path2 ${flagCheckClass}`}></span>
        </div>
      </Tooltip>
    );

  return (
    <div className={`workspace__goal ${borderClass} ${filterClass}`}>
      <div className="workspace__goal-header">
        {renderCompleteGoalIcon()}
        <h3
          className={`v5__heading-5 workspace__goal-title ${titleClass}`}
          onClick={() => props.setGoalExpanded(!props.goalExpanded)}
        >
          <div>{props.title}</div>
          {entriesCountNode}
          <DomainTag
            containerStyles="workspace__goal-domain--align-top"
            tagStyles="workspace__goal-domain--goal-display"
            id={props.focus_area.id}
            tagline={props.focus_area.tagline}
            label={props.focus_area.title}
            hover={true}
            hasResourcePerms={hasResourcePerms([
              resourcePerms.BL_CONTRACT_COACH,
              resourcePerms.ADMIN,
            ])}
            designDocLink={getDesignDocLink(props.focus_area.title)}
          />
        </h3>
        <div
          className={`tc-v4-circle-arrow workspace__goal-expand-arrow ${arrowClass}`}
          onClick={() => props.setGoalExpanded(!props.goalExpanded)}
        ></div>
      </div>

      {bodyNode}
    </div>
  );
};

GoalDisplayView.propTypes = {
  toggleGoalCompleted: PropTypes.func.isRequired,
  isFiltering: PropTypes.bool,
  goalExpanded: PropTypes.bool,
  setGoalExpanded: PropTypes.func.isRequired,
  pageUser: PropTypes.object.isRequired,
};

export default GoalDisplayView;
