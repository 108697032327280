// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const Fullscreen = (props) => {
  // this componenet should not render anything because browser cant fullscreen
  // or there isn't any resource to fullscreen
  if (!props.hasViablePreview || !props.fullScreenAvailable) {
    return null;
  }

  let fullScreenNode = null;
  let paginationNode = null;

  switch (props.preview_type) {
    case 'image':
      fullScreenNode = (
        <img
          className="artifact-overlay__fullscreen-image"
          src={props.content.preview[0]}
        />
      );

      break;

    case 'document':
      // eslint-disable-next-line no-case-declarations
      const fullScreenPreviewNode = [];

      if (props.content.preview.length > 1) {
        let maxPreviewLength = 25;
        if (props.content.preview.length < 25) {
          maxPreviewLength = props.content.preview.length;
        }

        // dont show the next and last buttons if there are no more items to show
        const nextClass =
          props.activeArtifactPage == maxPreviewLength ? ' tc__no-op' : '';
        const lastClass = props.activeArtifactPage == 1 ? ' tc__no-op' : '';

        paginationNode = (
          <div>
            <div className="artifact-overlay__paging-bar"></div>
            <div className="artifact-overlay__page-controls tc__body-copy">
              <div
                className={'page-control page-control__left' + lastClass}
                onClick={props.handlePreviousPageClick}
              >
                <div className="tc-arrow"></div>
              </div>
              <div className="page-control page-control__current">
                {props.activeArtifactPage} of {maxPreviewLength}
              </div>
              <div
                className={'page-control page-control__right' + nextClass}
                onClick={props.handleNextPageClick}
              >
                <div className="tc-arrow"></div>
              </div>
            </div>
          </div>
        );
      }

      for (let i = 0; props.content.preview.length > i; i++) {
        const displayPageClass =
          i + 1 == props.activeArtifactPage ? ' tc__visible' : ' tc__hidden';

        if (props.activeArtifactPage - 1 == i) {
          fullScreenPreviewNode.push(
            <img
              key={'doc-fullscreen-page-' + i}
              className="artifact-overlay__fullscreen-image"
              src={props.content.preview[i]}
            />
          );
        } else {
          fullScreenPreviewNode.push(
            <img
              key={'doc-fullscreen-page-' + i}
              className={
                'artifact-overlay__fullscreen-image' + displayPageClass
              }
              src={props.content.preview[i]}
            />
          );
        }

        fullScreenNode = <div>{fullScreenPreviewNode}</div>;
      }
      break;
  }

  return (
    <div
      id="artifact-overlay__fullscreen-container"
      className="artifact-overlay__fullscreen-container"
    >
      {fullScreenNode}
      {paginationNode}
    </div>
  );
};

Fullscreen.propTypes = {
  content: PropTypes.object.isRequired,
  preview_type: PropTypes.string.isRequired,
  hasViablePreview: PropTypes.bool,
  fullScreenAvailable: PropTypes.bool.isRequired,
  activeArtifactPage: PropTypes.number,
  handlePreviousPageClick: PropTypes.func,
  handleNextPageClick: PropTypes.func,
};

export default Fullscreen;
