import { CookieToken } from '@app-types/Token';
import { UserManager } from './UserManager';
import cookieStorage from './cookie-storage';

export const getToken = (): CookieToken | null => {
  const tokenString = cookieStorage.getItem(UserManager.userStoreKey);

  if (!tokenString) {
    return null;
  }

  return JSON.parse(tokenString);
};

export const setToken = (token: CookieToken): void => {
  cookieStorage.setItem(UserManager.userStoreKey, JSON.stringify(token));
};
