// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const EventMetadataAndLinks = (props) => {
  const hasGroup = ArrayHelpers.hasElements(props.event.groups);
  const statusIconMap = {
    Confirmed: 'tc-icon_status-done',
    'Pending Details': 'tc-icon_status-temp-inactive',
    Cancelled: 'tc-icon_status-disengaged',
    Closed: 'tc-icon_status-dropped',
    Completed: 'tc-icon-event-completed',
    'Out for Bid': 'tc-icon_out-for-bid',
    'Bid Closed': 'tc-icon-bid-closed',
    Planning: 'tc-icon-planning',
  };

  const statusIconNode = (
    <span
      className={`${
        statusIconMap[props.event.status]
      } admin-v5__event-status-icon`}
    >
      <span className="path1"></span>
      <span className="path2"></span>
    </span>
  );

  // if the event has a learning design product code, display it
  let eventProductCodeNode = (
    <div className="v5__body-copy italic grey-out">Not set</div>
  );
  const eventProductCode = props.event.ld_product_id
    ? props.allProductCodes[props.event.ld_product_id]
    : null;
  if (eventProductCode) {
    eventProductCodeNode = (
      <div className="v5__body-copy">
        {eventProductCode.product_code}: {eventProductCode.name}
      </div>
    );
  }

  // if the event sessions have other product codes assigned to them, list those
  const sessionProductCodes = {};
  props.event.sessions.forEach((session) => {
    // if this session doesn't have a code,
    // or it has the same code as the event,
    // or we've already added its code to the list,
    // or it is not a valid product code, skip it
    if (
      !session.ld_product_id ||
      session.ld_product_id === props.event.ld_product_id ||
      sessionProductCodes[session.ld_product_id] ||
      !props.allProductCodes[session.ld_product_id]
    ) {
      return;
    }
    // otherwise, add the code to the list
    const code = props.allProductCodes[session.ld_product_id];
    sessionProductCodes[
      session.ld_product_id
    ] = `${code.product_code}: ${code.name}`;
  });
  let addlProductCodesNode;
  if (ArrayHelpers.hasElements(Object.keys(sessionProductCodes))) {
    addlProductCodesNode = (
      <div className="edit-event__metadata-row">
        <div className="edit-event__metadata-label">
          Additional Product Codes
        </div>
        <ul className="v5__body-copy edit-event__metadata-list">
          {Object.values(sessionProductCodes).map((code) => {
            return <li key={code}>{code}</li>;
          })}
        </ul>
      </div>
    );
  }

  // show any learning domains associated with the event
  const learningDomains = props.event.focusAreas
    ? props.event.focusAreas.map((learningDomain) => (
        <div key={learningDomain.id}>{learningDomain.label}</div>
      ))
    : [];

  let learningDomainsNode;
  if (learningDomains.length) {
    learningDomainsNode = (
      <div className="edit-event__metadata-row">
        <div className="edit-event__metadata-label">Learning Domains</div>
        <div className="v5__body-copy">{learningDomains}</div>
      </div>
    );
  }

  // show the RSVP link if the event has a display name
  let reservationLinkNode = props.event.display_name ? (
    <React.Fragment>
      {props.event.reservation_link}
      <span
        copytext={props.event.reservation_link}
        onClick={props.handleCopy}
        className="v5__link edit-event__copy-link-text"
      >
        Copy Link
      </span>
    </React.Fragment>
  ) : (
    'Add a display name to create this link.'
  );

  // if the event is over, hide the links
  if (['Cancelled', 'Completed', 'Closed'].indexOf(props.event.status) !== -1) {
    reservationLinkNode = 'RSVP Closed';
  }

  // set up the link(s) to the preview version of the Event Attendee page
  let eventLinksHeader = (
    <div className="edit-event__metadata-label">Event Page</div>
  );
  let eventLinks = (
    <div className="edit-event__event-link v5__body-copy">
      {`${props.baseUrl}/lab/event/${props.event.id}/preview`}
      <span
        copytext={`${props.baseUrl}/lab/event/${props.event.id}/preview`}
        onClick={props.handleCopy}
        className="v5__link edit-event__copy-link-text"
      >
        Copy Link
      </span>
    </div>
  );

  // if there are groups show the group links
  if (hasGroup) {
    eventLinks = props.event.groups.map((group, i) => {
      return (
        <div
          className="edit-event__group-details v5__body-copy"
          key={`group-headline-${i}`}
        >
          <div className="edit-event__group-headline">{group.name}</div>
          <div>
            {`${props.baseUrl}/lab/event/${props.event.id}/group/${group.id}/preview`}
            <span
              copytext={`${props.baseUrl}/lab/event/${props.event.id}/group/${group.id}/preview`}
              onClick={props.handleCopy}
              className="v5__link edit-event__copy-link-text"
            >
              Copy Link
            </span>
          </div>
        </div>
      );
    });
  }

  // hide the links if the event has no display name
  if (!props.event.display_name) {
    eventLinks = null;
    eventLinksHeader = null;
  }

  // an array that holds arrays of sessions statuses grouped by group_id
  const sessionStatuses = hasGroup
    ? props.event.groups.map((group) => {
        return {
          name: group.name,
          id: group.id,
          status: group.status ? group.status : 'Active',
          sessions: [],
        };
      })
    : [{ name: null, id: null, sessions: [] }];

  /**
   * given session data, extract information and properly format
   * @param {obj} session session data
   * @returns {obj} formatted session data ready to be rendered
   */
  const formatSession = (session) => {
    const cancellationTime =
      session.cancellation_time &&
      moment(session.cancellation_time_formatted).tz(
        props.event.time_zone.pytz_timezone
      );
    const formattedCancellationTime =
      cancellationTime &&
      `${cancellationTime.format('M/D/YY, HH:mm A')} ${cancellationTime.format(
        'z'
      )}`;
    return {
      id: session.id,
      date: moment(session.session_date_formatted).format('M/D/YY'),
      status: session.status ? session.status : 'Scheduled',
      statusNote: session.status_note ? session.status_note : '–',
      cancellationTime: formattedCancellationTime,
      retroactivelyNotDelivered: !!session.retroactively_not_delivered,
    };
  };

  // if we don't have groups then simply push all sessions in
  if (!hasGroup && ArrayHelpers.hasElements(props.event.sessions)) {
    for (const session of props.event.sessions) {
      sessionStatuses[0].sessions.push(formatSession(session));
    }
  } else {
    // we have groups so push sessions into the group they belong to
    for (const groupIdx in sessionStatuses) {
      for (const session of props.event.sessions) {
        if (session.group_id === sessionStatuses[groupIdx].id) {
          sessionStatuses[groupIdx].sessions.push(formatSession(session));
        }
      }
    }
  }

  const sessionStatusesNode = sessionStatuses.map((group) => {
    // downsized group have greyed out color
    const groupNameClass =
      group.status === 'Downsized'
        ? ' edit-event__metadata-group-name--downsized'
        : '';
    // set up group name if there are groups
    // append an empty div behind the name to maintain proper css grid
    const groupNameNode = group.name && (
      <React.Fragment key={`meta-${group.id}`}>
        <div className={`edit-event__metadata-group-name${groupNameClass}`}>
          {group.name}
        </div>
        <div></div>
      </React.Fragment>
    );
    // build for sessions in each group if the group isn't downsized
    const sessionsNode =
      group.status !== 'Downsized' &&
      group.sessions.map((session) => {
        // we show Delivered status with different color if a session has been marked as not delivered
        const sessionStatusColorClass = session.retroactivelyNotDelivered
          ? 'Not-Delivered'
          : session.status.replace(' ', '-');
        return (
          <React.Fragment key={`meta-${session.id}`}>
            <div className="edit-event__metadata-session-container">
              <div className="edit-event__metadata-status">{session.date}</div>
              <div className="edit-event__metadata-session-status-icon">
                <span
                  className={`tc-icon_status-active ${sessionStatusColorClass}`}
                >
                  <span className="path1"></span>
                  <span className="path2"></span>
                </span>
              </div>
              <div className="edit-event__metadata-status">
                <div>{session.status}</div>
                <div className="edit-event__metadata-cancellation-time">
                  {session.cancellationTime}
                </div>
              </div>
            </div>
            <div className="edit-event__metadata-status">
              {session.statusNote}
            </div>
          </React.Fragment>
        );
      });
    return (
      <React.Fragment key={`meta-${group.name}`}>
        {groupNameNode}
        {sessionsNode}
      </React.Fragment>
    );
  });

  const buildEventStatusNode = (event = props.event) => {
    const cancellationTime =
      event.cancellation_time &&
      moment(event.cancellation_time_formatted).tz(
        event.time_zone.pytz_timezone
      );
    const formattedCancellationTime =
      cancellationTime &&
      `${cancellationTime.format('M/D/YY, HH:mm A')} ${cancellationTime.format(
        'z'
      )}`;
    const statusNote = event.cancellation_comment
      ? event.cancellation_comment
      : '–';
    return (
      <>
        <div className="edit-event__metadata-session-container">
          <div className="edit-event__metadata-event-status-icon">
            {statusIconNode}
          </div>
          <div className="edit-event__metadata-status">
            <div>{event.status}</div>
            <div className="edit-event__metadata-cancellation-time">
              {formattedCancellationTime}
            </div>
          </div>
        </div>
        <div className="edit-event__metadata-status">{statusNote}</div>
      </>
    );
  };

  return (
    <div className="row">
      <div className="cute-7-desktop">
        <div className="edit-event__metadata-row">
          <div className="v5__comment-copy edit-event__metadata-timezone">
            <span className="tc-icon_time-zone edit-event__row-start-icon" />
            {props.event.time_zone.name}
          </div>
          <h3 className="v5__heading-2">{props.event.name}</h3>
        </div>
        <div className="edit-event__metadata-row">
          <div className="v5__heading-5 light">{props.event.service}</div>
          <div className="v5__body-copy">
            for <span className="bold">{props.event.partner_name}</span>
          </div>
        </div>
        <div className="edit-event__metadata-row">
          <div className="edit-event__metadata-label">Event Product Code</div>
          {eventProductCodeNode}
        </div>
        {addlProductCodesNode}
        {learningDomainsNode}

        <div className="edit-event__metadata-status-container">
          <div className="edit-event__metadata-row">
            <div className="edit-event__metadata-label">Event Status</div>
          </div>
          <div className="edit-event__metadata-row">
            <div className="edit-event__metadata-label">Event Status Notes</div>
          </div>
          {buildEventStatusNode()}
        </div>

        {ArrayHelpers.hasElements(props.event.sessions) && (
          <div className="edit-event__metadata-status-container">
            <div className="edit-event__metadata-row">
              <div className="edit-event__metadata-label">Session Status</div>
            </div>

            <div className="edit-event__metadata-row">
              <div className="edit-event__metadata-label">
                Session Status Notes
              </div>
            </div>
            {sessionStatusesNode}
          </div>
        )}
      </div>

      <div className="cute-5-desktop">
        <div className="v5__heading-4 edit-event__section-headline edit-event__metadata-col-heading">
          Event Links
        </div>
        <div className="edit-event__metadata-row">
          <div>
            <div className="edit-event__metadata-label">RSVP</div>
            <div className="v5__body-copy">{reservationLinkNode}</div>
          </div>
        </div>
        <div className="edit-event__metadata-row">
          <NavigationLink
            url={`/bl/event/${props.event.id}/participants`}
            className="edit-event__attendee-rsvp-link v5__link"
          >
            View RSVP & Attendee List
          </NavigationLink>
        </div>
        <div className="edit-event__metadata-row">
          <div>
            {eventLinksHeader}
            {eventLinks}
          </div>
        </div>
      </div>
    </div>
  );
};

EventMetadataAndLinks.propTypes = {
  event: PropTypes.object.isRequired,
  allProductCodes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  handleCopy: PropTypes.func.isRequired,
};

export default EventMetadataAndLinks;
