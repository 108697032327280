// @ts-nocheck FIXME
import CohortApi from '@legacy-components/apis/CohortApi';
import ParticipantApi from '@legacy-components/apis/ParticipantApi';
import PartnerLeadApi from '@legacy-components/apis/PartnerLeadApi';
import Utils from '@legacy-components/components/DynamicUtils';
import Modal from '@legacy-components/components/Modal';
import { Router } from '@legacy-components/components/Router';
import StorageHelper from '@legacy-components/components/StorageHelper';
import Store from '@legacy-components/components/Store';
import WindowHelpers from '@legacy-components/components/WindowHelpers';

const defaultUi = {
  editCohort: {
    product: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOption: null,
      visibleOptions: [],
    },
    partner: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOption: null,
      visibleOptions: [],
    },
    outcomes: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOptions: [],
      visibleOptions: [],
    },
    matchRequirements: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOptions: [],
      visibleOptions: [],
    },
    priorityDomains: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOptions: [],
      visibleOptions: [],
    },
    capacity: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOptions: null,
      visibleOptions: [],
    },
    coaches: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOptions: [],
      visibleOptions: [],
    },
    startDate: null,
    endDate: null,
    isLoading: false,
    isSelectingCoaches: false,
    errors: {},
  },
  // modals on the Cohorts page
  createCohortModal: {
    opened: false,
    product: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOption: null,
      visibleOptions: [],
    },
    partner: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOption: null,
      visibleOptions: [],
    },
    matchRequirements: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOptions: [],
      visibleOptions: [],
    },
    priorityDomains: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOptions: [],
      visibleOptions: [],
    },
    capacity: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOptions: null,
      visibleOptions: [],
    },
    startDate: null,
    endDate: null,
    isLoading: false,
    errors: {},
  },
  // modals on the CohortUsers page
  addNewCohortLeaderModal: {
    opened: false,
    isLoading: false,
    errors: {},
  },
  // modals on the CohortUsers page
  addCohortParticipantModal: {
    opened: false,
    isLoading: false,
    errors: {},
  },
  // modal used for Cohorts and CohortUsers pages
  confirmSendEmailModal: {
    opened: false,
    object: null,
    isLoading: false,
  },
  // modal used for Cohorts and CohortUsers pages
  confirmDeleteModal: {
    object: null,
    btnDisabled: false,
  },
  uploadArtifactModal: {
    opened: false,
    isLoading: false,
    errors: {},
  },
  viewArtifactModal: {
    opened: false,
    isLoading: false,
    errors: {},
  },
  viewTextModal: {
    opened: false,
    isLoading: false,
    errors: {},
  },
  textEntryModal: {
    opened: false,
    isLoading: false,
    errors: {},
  },
  confirmDeleteArtifactModal: {
    opened: false,
    isLoading: false,
    errors: {},
  },
  displayDescription:
    'Work one-on-one with an expert coach to increase your impact as an educator. Identify your goals, implement and test strategies, and reflect on results.',
};

const store = {
  cohorts: [],
  cohort: null,
  cohortUsers: {},
  // coach selections made during bulk update of coaches in cohort management
  coachSelections: {},
  products: {},
  partners: {},
  sortColumn: 'name',
  sortDirection: 'ascending',
  ui: Object.assign({ alert: {} }, defaultUi),
  showingActiveCohorts: false,
};

/****** PRIVATE FUNCTIONS ******/
const setAlert = (type, message) => {
  store.ui.alert = {
    type: type,
    message: message,
    handleClose: store.clearAlert,
  };
};

const validateTermDates = (obj) => {
  if (!obj.startDate || !obj.endDate || !obj.terminationDate) {
    return true;
  }
  if (
    new Date(obj.endDate) - new Date(obj.startDate) > 0 &&
    new Date(obj.terminationDate) - new Date(obj.startDate) > 0 &&
    new Date(obj.terminationDate) - new Date(obj.endDate) >= 0
  ) {
    return true;
  }
  return false;
};

const validateTermDatesEdit = (obj) => {
  if (!obj.startDate || !obj.endDate) {
    return true;
  }
  if (new Date(obj.endDate) - new Date(obj.startDate) > 0) {
    return true;
  }
  return false;
};

// a,b are dates
// Put's nulls to front
const sortTermDate = (a, b) => {
  a = Utils.getDateObjectFromString(Utils.formatDate(a));
  b = Utils.getDateObjectFromString(Utils.formatDate(b));
  if (!a) {
    if (!b) {
      return 0;
    }
    return -1;
  } else if (!b) {
    return 1;
  }
  if (a > b) {
    return 1;
  } else {
    return -1;
  }
};

// a,b are product ids
const sortByProduct = (a, b) => {
  if (!a) {
    if (!b) {
      return 0;
    }
    return 1;
  } else if (!b) {
    return -1;
  }
  return store.products[a] < store.products[b] ? -1 : 1;
};

// a,b are product ids
const sortByPartnerName = (a, b) => {
  if (!a.name) {
    if (!b.name) {
      return 0;
    }
    return 1;
  } else if (!b.name) {
    return -1;
  }

  return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.products = data.products || {};
  store.partners = data.partners || {};

  store.cohorts = data.cohorts || [];
  store.cohort = data.cohort || null;
  store.cohortUsers = data.cohortUsers || {};
  store.showingActiveCohorts =
    WindowHelpers.getQueryStringParam('archived') !== '1' ? true : false;

  defaultUi.createCohortModal.product.options = data.productOptions;
  defaultUi.createCohortModal.partner.options = data.partnerOptions;

  let localSortState;

  const defaultSortState = { column: 'name', direction: 'ascending' };

  localSortState = StorageHelper.init('cohortsSortState', defaultSortState);

  store.sortColumn = localSortState.column;
  store.sortDirection = localSortState.direction;

  // The default sort from backend is by name so we don't need to resort on it
  switch (localSortState.column) {
    case 'product':
      store.cohorts.sort((a, b) => sortByProduct(a.product_id, b.product_id));
      break;
    case 'partnerName':
      store.cohorts.sort((a, b) => sortByPartnerName(a.partner, b.partner));
      break;
    case 'start':
      store.cohorts.sort((a, b) => sortTermDate(a.term_start, b.term_start));
      break;
    case 'termination':
      store.cohorts.sort((a, b) => sortTermDate(a.term_end, b.term_end));
      break;
    case 'end':
      store.cohorts.sort((a, b) =>
        sortTermDate(a.target_end_date, b.target_end_date)
      );
      break;
  }

  if (localSortState.direction === 'descending') {
    store.cohorts.reverse();
  }

  // if we are editing a cohort, show its data
  defaultUi.editCohort.product.options = data.productOptions;
  defaultUi.editCohort.partner.options = data.partnerOptions;
  defaultUi.editCohort.outcomes.options = data.outcomes;
  defaultUi.editCohort.coaches.options = data.coachOptions;

  defaultUi.editCohort.priorityDomains.options = data.allFocusAreas;
  defaultUi.editCohort.matchRequirements.options = data.allMatchRequirements;

  // always reset ui
  Object.assign(store.ui, Utils.clone(defaultUi));

  if (data.cohort) {
    // Set default display description
    data.cohort.display_description =
      data.cohort.display_description || store.ui.displayDescription;
    data.cohort.vision = data.cohort.vision || store.ui.vision;

    store.ui.viewArtifactModal = Modal(store, store.ui.viewArtifactModal);
    store.ui.viewTextModal = Modal(store, store.ui.viewTextModal);
    store.ui.uploadArtifactModal = Modal(store, store.ui.uploadArtifactModal);
    store.ui.textEntryModal = Modal(store, store.ui.textEntryModal);
    store.ui.confirmDeleteArtifactModal = Modal(
      store,
      store.ui.confirmDeleteArtifactModal
    );

    if (data.cohort.term_start) {
      // Using moment here gives a date on the previous day when no time is provided due to time-zone conversion
      store.ui.editCohort.startDate = new Date(data.cohort.term_start)
        .toISOString()
        .split('T')[0];
    }
    if (data.cohort.target_end_date) {
      // Using moment here gives a date on the previous day when no time is provided due to time-zone conversion
      store.ui.editCohort.endDate = new Date(data.cohort.target_end_date)
        .toISOString()
        .split('T')[0];
    }
    if (data.cohort.term_end) {
      // Using moment here gives a date on the previous day when no time is provided due to time-zone conversion
      store.ui.editCohort.terminationDate = new Date(data.cohort.term_end)
        .toISOString()
        .split('T')[0];
    }
    store.ui.editCohort.product.selectedOption = data.cohort.product;
    store.ui.editCohort.partner.selectedOption = data.cohort.partner;
    store.ui.editCohort.outcomes.selectedOptions = data.cohort.outcomes;
    store.ui.editCohort.priorityDomains.selectedOptions =
      data.cohort.priorityDomains;
    store.ui.editCohort.matchRequirements.selectedOptions =
      data.cohort.matchRequirements;

    store.ui.editCohort.capacity = data.cohort.capacity;
  }

  // don't trigger event
  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.copyEmailAddress = () => {
  setAlert('success', 'Email copied successfully!');
};

// open or close the confirm send email modal
store.openConfirmSendEmailModal = (data) => {
  store.ui.confirmSendEmailModal.opened = true;
  store.ui.confirmSendEmailModal.object = data;
  store.ui.confirmSendEmailModal.isLoading = false;
};
store.closeConfirmSendEmailModal = () => {
  store.ui.confirmSendEmailModal.opened = false;
  store.ui.confirmSendEmailModal.object = null;
};

// open or close the confirm delete modal
store.openConfirmDeleteModal = (data) => {
  store.ui.confirmDeleteModal.object = data;
  store.ui.confirmDeleteModal.btnDisabled = false;
  store.ui.confirmDeleteModal.errorMessage = null;
};
store.closeConfirmDeleteModal = () => {
  store.ui.confirmDeleteModal.object = null;
  store.ui.confirmDeleteModal.errorMessage = null;
};

// show or hide other modals
store.toggleModal = (feature) => {
  const opened = store.ui[feature].opened;
  // reset ui
  Object.assign(store.ui[feature], Utils.clone(defaultUi[feature]));
  // toggle modalDisplayed value
  store.ui[feature].opened = !opened;
};

// Method for the add artifact modal
store.editArtifactCallback = (data) => {
  if (data.update) {
    if (data.index != null) {
      store.cohort.updates[data.index] = data.update;
    } else {
      store.cohort.updates.unshift(data.update);
    }
  } else if (data.report) {
    if (data.index != null) {
      store.cohort.reports[data.index] = data.report;
    } else {
      store.cohort.reports.unshift(data.report);
    }
  }
};

store.deleteCohortReport = (data) => {
  return CohortApi.deleteCohortReport({
    cohortId: store.cohort.id,
    reportId: data.id,
  })
    .then(() => {
      // if the report is in the list, remove it
      if (data.index != null) {
        store.cohort.reports.splice(data.index, 1);
      }
      store.ui.confirmDeleteArtifactModal.close();
    })
    .catch(() => {
      store.ui.uploadArtifactModal.errors.api =
        'Unable to delete report.  Please try again later.';
    });
};

// clear the errors when input is focused on
store.clearErrorsOnFocus = (data) => {
  if (data.field in store.ui[data.feature].errors) {
    delete store.ui[data.feature].errors[data.field];
    return true;
  }

  return false;
};

store.updateSelect = (data) => {
  Object.assign(store.ui[data.feature][data.field], data.data);
};

store.updatePriorityDomainsSelect = (data) => {
  Object.assign(store.ui[data.feature][data.field], data.data);
};

store.getVisibleOptions = (data) => {
  const field = store.ui[data.feature][data.field];
  field.charCount = data.input.length;

  // only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    return option.label.toLowerCase().indexOf(data.input.toLowerCase()) !== -1;
  });
};

store.setTerminationDate = (data) => {
  store.ui[data.feature].terminationDate = data.date;
};
store.setStartDate = (data) => {
  store.ui[data.feature].startDate = data.date;
};

store.setEndDate = (data) => {
  store.ui[data.feature].endDate = data.date;
};

store.addCohort = (data) => {
  // convenience var
  const modal = store.ui.createCohortModal;

  modal.isLoading = true;
  modal.errors = {};

  // validate form data
  if (!data.name) {
    modal.errors.name = 'Please enter a name';
  }
  if (!data.displayName) {
    modal.errors.displayName = 'Please enter a display name';
  }
  if (!data.displayDescription) {
    modal.errors.displayDescription = 'Please enter a description';
  }
  if (!modal.product.selectedOption) {
    modal.errors.product = 'Please select a BL product';
  }
  if (!modal.partner.selectedOption) {
    modal.errors.partner = 'Please select a partner';
  }
  if (!data.capacity) {
    modal.errors.capacity =
      'Please enter the number of educators in this cohort';
  }
  if (!validateTermDates(modal)) {
    modal.errors.end = 'End Date must be between Start and Termination Date';
    modal.errors.start = 'Start Date must be before End and Termination Date';
  }
  if (!modal.startDate) {
    modal.errors.start = 'This is required';
  }
  if (!modal.endDate) {
    modal.errors.end = 'This is required';
  }
  if (!modal.terminationDate) {
    modal.errors.termination = 'This is required';
  }

  // if there were any form errors, display them
  if (Object.keys(modal.errors).length) {
    modal.isLoading = false;
    return;
  }

  // clear any alert
  store.clearAlert();

  // add data from store to form data
  data.productId = modal.product.selectedOption
    ? modal.product.selectedOption.id
    : null;
  data.partnerId = modal.partner.selectedOption
    ? modal.partner.selectedOption.id
    : null;
  data.termStart = modal.startDate;
  data.termEnd = modal.terminationDate;
  data.targetEndDate = modal.endDate;

  // do XHR call
  return CohortApi.createCohort(data).then(
    // XHR success
    (response) => {
      modal.isLoading = false;
      // if we got any errors back, just display those
      if (response.errors) {
        modal.errors = response.errors;
        return;
      }
      // put newly added cohort at the top of the list
      store.cohorts.unshift(response.payload.cohort);
      modal.opened = false;
      setAlert('success', 'Cohort created successfully');
    },

    // XHR error
    (response) => {
      modal.isLoading = false;
      if (response && response.errors && response.errors.name) {
        modal.errors.name = response.errors.name;
        return;
      }
      modal.opened = false;
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not save the cohort. Please try again later.'
      );
    }
  );
};

store.deleteCohort = (cohortId) => {
  store.ui.confirmDeleteModal.btnDisabled = true;
  store.clearAlert();

  // make sure that the cohort is empty no participants no leads before deleting
  const cohortIndex = Utils.getItemIndexFromArrayOfObjects(
    store.cohorts,
    'id',
    cohortId
  );
  if (
    store.cohorts[cohortIndex].participant_count +
      store.cohorts[cohortIndex].lead_count >
    0
  ) {
    store.ui.confirmDeleteModal.errorMessage =
      'Please remove all users from the cohort and try again.';
    store.ui.confirmDeleteModal.btnDisabled = false;
    return;
  }

  return CohortApi.deleteCohort({
    cohortId: cohortId,
  }).then(
    // XHR success
    () => {
      // close modal
      store.ui.confirmDeleteModal.object = null;
      store.ui.confirmDeleteModal.btnDisabled = false;

      // remove cohort from list
      for (let i = 0; i < store.cohorts.length; i++) {
        if (store.cohorts[i].id === cohortId) {
          store.cohorts.splice(i, 1);
          break;
        }
      }

      // inform user that the delete was successful
      setAlert('success', 'Cohort deleted successfully');
    },

    // XHR error
    (response) => {
      // close modal
      store.ui.confirmDeleteModal.object = null;
      store.ui.confirmDeleteModal.btnDisabled = false;
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not delete the cohort. Please try again later.'
      );
    }
  );
};

store.editCohort = (data) => {
  // convenience var
  const form = store.ui.editCohort;

  form.isLoading = true;
  form.errors = {};

  // validate form data
  if (!data.name) {
    form.errors.name = 'Please enter a name';
  }
  if (!data.displayName) {
    form.errors.displayName = 'Please enter a display name';
  }
  if (!data.displayDescription) {
    form.errors.displayDescription = 'Please enter a description';
  }
  if (!form.product.selectedOption) {
    form.errors.product = 'Please select a BL product';
  }
  if (!form.partner.selectedOption) {
    form.errors.partner = 'Please select a partner';
  }
  if (!data.capacity) {
    form.errors.capacity =
      'Please enter the number of educators in this cohort';
  }
  if (!validateTermDatesEdit(form)) {
    form.errors.end = 'End Date must be between Start and Termination Date';
    form.errors.start = 'Start Date must be before End and Termination Date';
  }
  if (!form.startDate) {
    form.errors.start = 'This is required';
  }
  if (!form.endDate) {
    form.errors.end = 'This is required';
  }

  // if there were any form errors, display them
  if (Object.keys(form.errors).length) {
    setAlert('error', 'Changes not saved. You must complete required fields.');
    form.isLoading = false;
    return;
  }

  // clear any alert
  store.clearAlert();

  // add data from store to form data
  data.cohortId = store.cohort.id;
  data.productId = form.product.selectedOption
    ? form.product.selectedOption.id
    : null;
  data.partnerId = form.partner.selectedOption
    ? form.partner.selectedOption.id
    : null;
  data.termStart = form.startDate;
  data.targetEndDate = form.endDate;
  data.priorityDomains = form.priorityDomains.selectedOptions.map(
    (option) => option.id
  );
  data.matchRequirements = form.matchRequirements.selectedOptions.map(
    (option) => option.id
  );

  // do XHR call
  return CohortApi.editCohort(data).then(
    // XHR success
    (response) => {
      form.isLoading = false;
      // if we got any errors back, just display those
      if (response.errors) {
        form.errors = response.errors;
        return;
      }
      setAlert('success', 'Cohort updated successfully');

      store.cohort = response.cohort;
    },
    // XHR error
    (response) => {
      form.isLoading = false;
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not save the cohort. Please try again later.'
      );
    }
  );
};

store.addCohortParticipant = (data) => {
  // convenience var
  const modal = store.ui.addCohortParticipantModal;

  modal.isLoading = true;

  // if there were any form errors, display them
  if (Object.keys(modal.errors).length) {
    modal.isLoading = false;
    return;
  }

  // clear any alert
  store.clearAlert();

  // do XHR call
  return CohortApi.addCohortParticipant({
    cohortId: store.cohort.id,
    email: data.email,
    sendWelcomeEmail: data.sendEmail,
    affiliation: data.affiliation,
  }).then(
    // XHR success
    (response) => {
      modal.isLoading = false;
      // if we got any errors back, return them to process
      if (response.errors) {
        data.failureCallback(response.errors);
        return;
      }
      // put newly added participant at the top of the list
      store.cohortUsers.coachingEngagements.unshift(
        response.coaching_engagement
      );
      setAlert('success', 'Participant added successfully');
      data.successCallback();
    },
    // XHR error
    (response) => {
      modal.isLoading = false;
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not add the participant. Please try again later.'
      );
    }
  );
};

store.moveCohortParticipant = (data) => {
  // convenience var
  const modal = store.ui.addCohortParticipantModal;

  modal.isLoading = true;

  const emailIndex = Utils.getItemIndexFromArrayOfObjects(
    store.cohortUsers.coachingEngagements,
    'email',
    data.email
  );
  // if participant is already attached to cohort throw warning.
  if (emailIndex !== -1) {
    modal.isLoading = false;
    setAlert('error', 'Participant already attached to this cohort.');
    return;
  }

  // if there were any form errors, display them
  if (Object.keys(modal.errors).length) {
    modal.isLoading = false;
    return;
  }

  // clear any alert
  store.clearAlert();

  // do XHR call
  return CohortApi.moveCohortParticipant({
    recordChange: data.recordChange,
    cohortId: store.cohort.id,
    email: data.email,
    coachingEngagementId: data.coachingEngagementId,
    sendWelcomeEmail: data.sendEmail,
  }).then(
    // XHR success
    (response) => {
      modal.isLoading = false;
      // if we got any errors back, just display those
      if (response.errors) {
        data.failureCallback(response.errors);
        return;
      }
      // put newly added participant at the top of the list
      store.cohortUsers.coachingEngagements.unshift(
        response.coaching_engagement
      );
      setAlert('success', 'Participant added successfully');
      data.successCallback();
    },

    // XHR error
    (response) => {
      modal.isLoading = false;
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not add the participant. Please try again later.'
      );
    }
  );
};

store.deleteCohortParticipant = (data) => {
  store.ui.confirmDeleteModal.btnDisabled = true;
  store.clearAlert();

  return CohortApi.deleteCohortParticipant({
    participantId: data.participantId,
    coachingEngagementId: data.coachingEngagementId,
  }).then(
    // XHR success
    () => {
      // close modal
      store.ui.confirmDeleteModal.object = null;
      store.ui.confirmDeleteModal.btnDisabled = false;
      setAlert('success', 'Participant removed successfully');

      // remove user from list
      for (let i = 0; i < store.cohortUsers.coachingEngagements.length; i++) {
        if (
          store.cohortUsers.coachingEngagements[i].coaching_engagement_id ===
          data.coachingEngagementId
        ) {
          store.cohortUsers.coachingEngagements.splice(i, 1);
          return;
        }
      }
    },

    // XHR error
    (response) => {
      // close modal
      store.ui.confirmDeleteModal.object = null;
      store.ui.confirmDeleteModal.btnDisabled = false;
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not delete the participant. Please try again later.'
      );
    }
  );
};

store.deleteCohortCoach = (coachId) => {
  store.ui.confirmDeleteModal.btnDisabled = true;
  store.clearAlert();

  return CohortApi.deleteCohortCoach({
    coachId: coachId,
  }).then(
    // XHR success
    () => {
      // close modal
      store.ui.confirmDeleteModal.object = null;
      store.ui.confirmDeleteModal.btnDisabled = false;
      setAlert('success', 'Coach removed successfully');

      // remove user from list
      for (let i = 0; i < store.cohortUsers.coaches.length; i++) {
        if (store.cohortUsers.coaches[i].id === coachId) {
          store.cohortUsers.coaches.splice(i, 1);
          return;
        }
      }
    },

    // XHR error
    (response) => {
      // close modal
      store.ui.confirmDeleteModal.object = null;
      store.ui.confirmDeleteModal.btnDisabled = false;
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not delete the coach. Please try again later.'
      );
    }
  );
};

store.sendWelcomeEmail = (data) => {
  store.clearAlert();

  // set up loading ui
  store.ui.confirmSendEmailModal.isLoading = true;

  // API endpoint depends on whether user is participant vs partner leader
  if (data.is_leader) {
    return PartnerLeadApi.sendWelcomeEmail({
      leadId: data.id,
    })
      .then(() => {
        store.closeConfirmSendEmailModal();
        setAlert('success', 'Email sent to ' + data.name);
      })
      .catch((response) => {
        store.closeConfirmSendEmailModal();
        setAlert(
          'error',
          (response && response.error) ||
            'Sorry, we could not send the email. Please try again later.'
        );
      });
  } else {
    return ParticipantApi.sendWelcomeEmail({
      participantId: data.id,
    })
      .then(() => {
        store.closeConfirmSendEmailModal();
        setAlert('success', 'Email sent to ' + data.name);
      })
      .catch((response) => {
        store.closeConfirmSendEmailModal();
        setAlert(
          'error',
          (response && response.error) ||
            'Sorry, we could not send the email. Please try again later.'
        );
      });
  }
};

store.sendWelcomeEmailsToCohort = (data) => {
  store.clearAlert();

  // set up loading ui
  store.ui.confirmSendEmailModal.isLoading = true;

  return CohortApi.sendWelcomeEmails({
    cohortId: data.id,
  })
    .then((response) => {
      store.closeConfirmSendEmailModal();
      setAlert(
        'success',
        response.participantCount + ' emails sent to ' + data.name
      );
    })
    .catch((response) => {
      store.closeConfirmSendEmailModal();
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not send the emails. Please try again later.'
      );
    });
};

store.sortBy = (data) => {
  switch (data.column) {
    case 'name':
      //sort alphabetically
      store.cohorts.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );
      break;
    case 'partnerName':
      store.cohorts.sort((a, b) => sortByPartnerName(a.partner, b.partner));
      break;
    case 'product':
      store.cohorts.sort((a, b) => sortByProduct(a.product_id, b.product_id));
      break;
    case 'start':
      store.cohorts.sort((a, b) => sortTermDate(a.term_start, b.term_start));
      break;
    case 'end':
      store.cohorts.sort((a, b) =>
        sortTermDate(a.target_end_date, b.target_end_date)
      );
      break;
    case 'termination':
      store.cohorts.sort((a, b) => sortTermDate(a.term_end, b.term_end));
      break;
  }
  if (data.direction) {
    store.sortDirection = data.direction;
  } else if (store.sortColumn === data.column) {
    if (store.sortDirection === 'ascending') {
      store.sortDirection = 'descending';
      store.cohorts.reverse();
    } else {
      store.sortDirection = 'ascending';
    }
  } else {
    store.sortDirection = 'ascending';
  }
  store.sortColumn = data.column;

  const localSortState = {
    column: store.sortColumn,
    direction: store.sortDirection,
  };
  StorageHelper.setItem('cohortsSortState', localSortState);

  return true;
};

store.toggleCohortsArchivedCheckbox = () => {
  const url = store.showingActiveCohorts
    ? '/bl/cohorts?archived=1'
    : '/bl/cohorts';
  store.showingActiveCohorts = !store.showingActiveCohorts;

  // if we are showing archived cohorts, default to sorting by end date desc
  if (!store.showingActiveCohorts) {
    store.sortBy({ column: 'end', direction: 'descending' });
  }

  Router.route(url);
};

store.updateCoaches = (data) => {
  data.setLoading(true);
  return CohortApi.updateCoaches({
    cohortId: data.cohortId,
    coachSelections: store.coachSelections,
  })
    .then((response) => {
      // set store cohortUsers, with response
      store.cohortUsers = Object.assign({}, response.cohortUsers);
      // disable coach selection and reset selections
      store.clearCoachSelection();
      setAlert('success', 'Coaches updated successfully.');
      data.setLoading(false);
    })
    .catch((response) => {
      store.clearCoachSelection();
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not update coaches. Please try again later.'
      );
      data.setLoading(false);
    });
};

store.clearCoachSelection = () => {
  // reset ui
  Object.assign(store.ui, Utils.clone(defaultUi));

  // reset coach selections
  store.coachSelections = {};
};

store.enableCoachSelection = () => {
  store.ui.editCohort.isSelectingCoaches = true;
};

store.upsertCoachSelection = (coachSelection) => {
  store.coachSelections = Object.assign(
    {},
    store.coachSelections,
    coachSelection
  );
};

store.handleCopy = () => {
  setAlert('success', 'Link copied to clipboard');
};

export default Store('CohortStore', store);
