// @ts-nocheck FIXME
import React from 'react';
import ScaleCenterImage from './../../ScaleCenterImage';
import StudentsImpactedIcons from './../../TeacherProfile/StudentsImpactedIcons';

class Stats extends React.Component {
  getHoursNode = () => {
    let totalPedagogyHours = 0;
    let totalContentHours = 0;
    let totalHours = 0;
    // Add the pedagogy hours from the stats entry:
    totalPedagogyHours += this.props.hours.pedagogyHours
      ? Math.round(this.props.hours.pedagogyHours)
      : 0;
    // Add the additional pedagogy hours from the portfolio:
    totalPedagogyHours += this.props.portfolio.additional_pedagogy_hours
      ? Math.round(this.props.portfolio.additional_pedagogy_hours)
      : 0;

    // Add the content hours from the stats entry:
    totalContentHours += this.props.hours.contentHours
      ? Math.round(this.props.hours.contentHours)
      : 0;
    // Add the additional content hours from the portfolio:
    totalContentHours += this.props.portfolio.additional_content_hours
      ? Math.round(this.props.portfolio.additional_content_hours)
      : 0;

    totalHours = totalPedagogyHours + totalContentHours;

    return (
      <div className="portfolio__stats-row portfolio__stats-row--green">
        <div className="portfolio__stat-number">{totalHours}</div>
        <div className="portfolio__stats-data portfolio__stats-data--double">
          <div className="portfolio__stats-headline">Hours of PD</div>
          <div className="portfolio__stats-subheadline">
            <span className="portfolio__stat-highlight portfolio__stat-highlight--uppercase">
              Pedagogy Hours:
            </span>
            <span className="portfolio__stat-hours">{totalPedagogyHours}</span>
            <span className="portfolio__stat-highlight portfolio__stat-highlight--uppercase">
              Content Hours:
            </span>
            <span className="portfolio__stat-hours">{totalContentHours}</span>
          </div>
        </div>
      </div>
    );
  };

  getChallengesNode = () => {
    let subject = null;

    if (this.props.subjects.length) {
      subject = this.props.subjects.map(function (subject, id) {
        return (
          <span
            className="portfolio__stats-subject portfolio__stat-highlight"
            key={'subject-' + id}
          >
            {subject}
          </span>
        );
      });
    }

    let subjectsNode = '';
    if (subject) {
      subjectsNode = (
        <div className="portfolio__stats-subheadline">
          in
          <span className="portfolio__stat-subject portfolio__stat-highlight--uppercase">
            {subject}
          </span>
        </div>
      );
    }

    return (
      <div className="portfolio__stats-row portfolio__stats-row--red">
        <div className="portfolio__stat-number">
          {this.props.challenges.length}
        </div>
        <div className="portfolio__stats-data portfolio__stats-data--double">
          <div className="portfolio__stats-headline">Teaching Challenges</div>
          {subjectsNode}
        </div>
      </div>
    );
  };

  getStudentsImpactedNode = () => {
    if (this.props.studentsImpacted && this.props.studentsImpacted > 0) {
      // set up the new node for the dom
      return (
        <div className="portfolio__students-impacted portfolio__stats-row portfolio__stats-row--red">
          <div className="portfolio__stat-number portfolio__stat-number--impact">
            {this.props.studentsImpacted}
          </div>
          <div className="portfolio__students-impacted-caption">
            Students impacted
          </div>
          <div className="portfolio__students-impacted-container students-impacted__container">
            <StudentsImpactedIcons
              studentsImpacted={this.props.studentsImpacted}
              cssClassName="students-impacted__icon--portfolio"
            />
          </div>
        </div>
      );
    }
  };

  render() {
    let userImageNode = null;
    if (this.props.photo) {
      userImageNode = (
        <ScaleCenterImage
          src={this.props.photo}
          className="portfolio__stats-user-image"
        />
      );
    }

    let challengesNode = null;
    if (this.props.challenges) {
      challengesNode = this.getChallengesNode();
    }

    let strategyCountNode = null;
    if (this.props.strategyCount) {
      strategyCountNode = (
        <div className="portfolio__stats-row portfolio__stats-row--blue">
          <div className="portfolio__stat-number">
            {this.props.strategyCount}
          </div>
          <div className="portfolio__stats-data">
            <div className="portfolio__stats-headline">Strategies Tried</div>
          </div>
        </div>
      );
    }

    let hoursNode = null;
    if (this.props.hours) {
      hoursNode = this.getHoursNode();
    }

    let danielsonsNode = null;
    let danielsonStandard = null;
    if (this.props.danielsonComponents.length) {
      danielsonStandard = this.props.danielsonComponents.map(function (
        standard,
        id
      ) {
        return (
          <span
            className="portfolio__stats-standard portfolio__stat-highlight"
            key={'danielson-' + id}
          >
            {standard}
          </span>
        );
      });
      danielsonsNode = (
        <div className="portfolio__stats-row portfolio__stats-row--red">
          <div className="portfolio__stat-number">
            {this.props.danielsonComponents.length}
          </div>
          <div className="portfolio__stats-data portfolio__stats-data--double">
            <div className="portfolio__stats-headline">
              Danielson Components
            </div>
            <div className="portfolio__stats-subheadline">
              {danielsonStandard}
            </div>
          </div>
        </div>
      );
    }

    let coreStandardsNode = null;
    let coreStandard = null;
    if (this.props.coreStandards.length) {
      coreStandard = this.props.coreStandards.map(function (standard, id) {
        return (
          <span
            className="portfolio__stats-standard portfolio__stat-highlight"
            key={'core-' + id}
          >
            {standard}
          </span>
        );
      });
      coreStandardsNode = (
        <div className="portfolio__stats-row portfolio__stats-row--blue">
          <div className="portfolio__stat-number">
            {this.props.coreStandards.length}
          </div>
          <div className="portfolio__stats-data portfolio__stats-data--double">
            <div className="portfolio__stats-headline">Common Core</div>
            <div className="portfolio__stats-subheadline">{coreStandard}</div>
          </div>
        </div>
      );
    }

    // Next Gen Science Standards are listed separately from other core standards
    let ngssNode = null;
    if (this.props.ngss && this.props.ngss.length) {
      const ngss = this.props.ngss.map(function (standard, id) {
        return (
          <span
            className="portfolio__stats-standard portfolio__stat-highlight"
            key={'ngss-' + id}
          >
            {standard}
          </span>
        );
      });
      ngssNode = (
        <div className="portfolio__stats-row portfolio__stats-row--green">
          <div className="portfolio__stat-number">{this.props.ngss.length}</div>
          <div className="portfolio__stats-data portfolio__stats-data--double">
            <div className="portfolio__stats-headline">Next Gen Science</div>
            <div className="portfolio__stats-subheadline">{ngss}</div>
          </div>
        </div>
      );
    }

    let artifactCountNode = null;
    if (this.props.strategyCount) {
      artifactCountNode = (
        <div className="portfolio__stats-row portfolio__stats-row--red">
          <div className="portfolio__stat-number">{this.props.artifacts}</div>
          <div className="portfolio__stats-data">
            <div className="portfolio__stats-headline">Classroom Artifacts</div>
          </div>
        </div>
      );
    }

    // set up visual students impacted icons
    const studentsImpactedNode = this.getStudentsImpactedNode();

    return (
      <div className="portfolio__stats">
        <div className="portfolio__stats-user">
          {userImageNode}
          <div className="portfolio__stats-user-name">{this.props.name}</div>
          <div className="portfolio__stats-user-dates">
            Personalized PD Term: {this.props.pdYear}
          </div>
        </div>
        {challengesNode}
        {strategyCountNode}
        {hoursNode}
        {danielsonsNode}
        {coreStandardsNode}
        {ngssNode}
        {artifactCountNode}
        {studentsImpactedNode}
      </div>
    );
  }
}

export default Stats;
