// @ts-nocheck FIXME
import Request from './../../components/Request';

const EventApi = {
  edit: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/${data.eventId}/edit`,
      JSON.stringify(data)
    );
  },
  editOverview: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/${data.eventId}/overview/edit`,
      JSON.stringify(data)
    );
  },
  deleteReservation: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/delete_reservation`,
      JSON.stringify(data)
    );
  },
  deleteAttendee: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/delete-attendee`,
      JSON.stringify(data)
    );
  },
  addNewPL: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/${data.eventId}/add_new_pl`,
      JSON.stringify(data)
    );
  },
  addExistingPL: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/${data.eventId}/add_existing_pl`,
      JSON.stringify(data)
    );
  },
  removeEventPL: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/${data.eventId}/remove_event_pl/${data.plEventId}`,
      JSON.stringify(data)
    );
  },
  participantSessionCheckin: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/${data.eventId}/session/${data.sessionId}/checkin`,
      JSON.stringify(data)
    );
  },
  updateEventGroups: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/${data.eventId}/update-groups`,
      JSON.stringify(data)
    );
  },
  importAttendees: function (data) {
    const fd = new FormData();
    fd.append('file', data.file);
    fd.append('formData', JSON.stringify(data.formData));

    return Request.postForm(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/lab/event/bulk-register-participants',
      fd
    );
  },
  deleteEventSessionRecording: function (data) {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/session-recording/${data.recordingId}/delete`,
      JSON.stringify(data)
    );
  },
  disconnectZoom: function (data) {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/session/${data.eventSessionId}/disconnect`,
      JSON.stringify(data)
    );
  },
  addNewParticipant: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/lab/event/${data.eventId}/register/new_user`,
      JSON.stringify(data)
    );
  },
  addExistingParticipant: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/lab/event/${data.eventId}/register/existing_user`,
      JSON.stringify(data)
    );
  },
  setStaffingCapacities: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/set-staffing-capacities`,
      JSON.stringify(data)
    );
  },
  syncEventToCB: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/${data.eventId}/sync_with_cb`,
      JSON.stringify(data)
    );
  },
  saveContentLink: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/event/${data.eventId}/save_content_link`,
      JSON.stringify(data)
    );
  },
};

export default EventApi;
