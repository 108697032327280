// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import React from 'react';
import MeetingJoinButton from './MeetingJoinButton';

const FutureEventRow = (props) => {
  // Check to see whether it's a meeting or an event
  const meetingLink = !props.meeting.session ? props.participant.zoom_link : '';

  // Check to see whether it's a meeting or an event
  const iconClass = props.meeting.cb_id
    ? 'tc-v5-event-product home-v5__event-color'
    : 'tc-v5-meeting';
  let descriptionTextNode =
    props.meeting.display_name ||
    `Meeting with ${props.coach ? props.coach.first_name : 'your coach'}`;
  const startTime =
    props.meeting.meeting_time_formatted ||
    props.meeting.session.session_start_time_formatted ||
    props.meeting.session.session_date_formatted;
  const startTimeMoment = startTime && moment(startTime).tz(props.userTimeZone);
  let startTimeString =
    startTimeMoment &&
    startTimeMoment.format(
      `dddd, MMMM D, ${Utils.hourRounder(startTimeMoment)} A`
    );

  // If it's an event we get some very complicated logic to display it
  if (props.meeting.session) {
    const hasTime =
      !!props.meeting.session.session_start_time &&
      !!props.meeting.session.session_end_time;
    startTimeString = startTimeMoment && startTimeMoment.format('dddd, MMMM D');

    // If it's an event the user has already registered for then we link the participants page
    if (!props.meeting.reservation) {
      const groupName = props.meeting.event_group.name
        ? `, ${props.meeting.event_group.name}`
        : '';
      descriptionTextNode = `${descriptionTextNode}${groupName}`;
      const zoomLink =
        props.meeting.session.zoom_join_link ||
        props.meeting.session.zoom_link ||
        '';
      let sessionTracker = '';
      if (props.meeting.session_div > 1) {
        sessionTracker = (
          <div>
            {props.meeting.session.name} ({props.meeting.session.num} of{' '}
            {props.meeting.session_div})
          </div>
        );
      }
      descriptionTextNode = (
        <React.Fragment>
          <div>{sessionTracker}</div>
          <div>{descriptionTextNode}</div>
          <div>{zoomLink}</div>
        </React.Fragment>
      );
      // If it's a reservation just show when the first session starts and how many sessions there are
    }
    if (
      moment(props.meeting.session.session_date_formatted).isSameOrBefore(
        moment().format('YYYY-MM-DD')
      ) ||
      props.meeting.started
    ) {
      descriptionTextNode = (
        <NavigationLink
          target="_blank"
          className="tc__link"
          url={`/lab/event/${props.meeting.id}/register`}
        >
          {descriptionTextNode}
        </NavigationLink>
      );
    }
    if (
      props.meeting.session_count > 1 ||
      (props.meeting.advanced_event && !props.meeting.event_group)
    ) {
      startTimeString = `Starting ${startTimeString}`;
      const numSessions = props.meeting.advanced_event
        ? `Up to ${props.meeting.session_count} Sessions`
        : `${props.meeting.session_count} Sessions`;
      descriptionTextNode = (
        <React.Fragment>
          <div>{descriptionTextNode}</div>
          <div>{numSessions}</div>
        </React.Fragment>
      );
    } else if (
      (!props.meeting.advanced_event || !props.meeting.reservation) &&
      hasTime
    ) {
      startTimeString += ', ';
      const endTime = props.meeting.session.session_end_time_formatted;
      startTimeString += Utils.formatEventSessionTime(
        startTime,
        endTime,
        props.userTimeZone,
        false
      );
    }
  }

  return (
    <div className="home-v5__meetings-row home-v5__meetings-margin">
      <div className={`home-v5__event-meeting-width ${iconClass}`}>
        <span className="path1"></span>
        <span className="path2"> </span>
      </div>
      <div className="v5__body-copy home-v5__meetings-row-text">
        <div className="v5__author-copy">{startTimeString}</div>
        <div className="v5__author-title-copy">
          {descriptionTextNode}
          {props.meetingStarted.includes(props.meeting.id) ? (
            <div>
              <NavigationLink
                target="_blank"
                className="tc__link"
                url={meetingLink}
              >
                {meetingLink}
              </NavigationLink>
            </div>
          ) : (
            <div>{meetingLink}</div>
          )}
        </div>
      </div>
      {props.meeting.meeting_time_formatted && (
        <MeetingJoinButton
          {...props.meeting}
          meetingStarted={props.meetingStarted.includes(props.meeting.id)}
          zoomLink={props.participant.zoom_link || null}
        />
      )}
    </div>
  );
};

export default FutureEventRow;
