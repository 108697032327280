// @ts-nocheck FIXME
import Artifact from './TML/Artifact';
import Feedback from './TML/Feedback';
import Measurement from './TML/Measurement';

const TMLInstance = (props) => {
  let teachNode = null;
  if (props.feedback.teach) {
    teachNode = (
      <Feedback
        {...props.feedback.teach}
        key={'feedback-teach-' + props.feedback.teach.id}
      />
    );
  }

  let measureNode = null;
  if (props.feedback.measure) {
    measureNode = (
      <Feedback
        {...props.feedback.measure}
        key={'feedback-measure-' + props.feedback.measure.id}
      />
    );
  }

  let learnNode = null;
  if (props.feedback.learn) {
    learnNode = (
      <Feedback
        {...props.feedback.learn}
        key={'feedback-learn-' + props.feedback.learn.id}
      />
    );
  }

  let artifactsHeaderNode = null;
  let artifactsNode = null;
  if (props.artifacts && props.artifacts.length) {
    artifactsHeaderNode = (
      <div className="portfolio-v2__strategy-headline portfolio-v2__loop-section">
        Artifacts
      </div>
    );
    artifactsNode = props.artifacts.map((artifact, i) => {
      return (
        <Artifact
          {...artifact}
          key={'artifact-' + props.id + '-' + artifact.id + '-' + i}
        />
      );
    });
  }

  let measurementNode = null;
  if (props.measurement) {
    measurementNode = <Measurement {...props.measurement} />;
  }

  return (
    <div>
      {teachNode}
      {artifactsHeaderNode}
      {artifactsNode}
      {measurementNode}
      {measureNode}
      {learnNode}
    </div>
  );
};

export default TMLInstance;
