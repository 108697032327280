// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import React from 'react';
import AbridgeText from './../../../Common/AbridgeText';

const MeetingNotesEntry = (props) => {
  const inputEL = React.useRef(null);

  React.useEffect(() => {
    if (props.smallView) {
      const elem = inputEL.current;
      const elemHeight = elem.getBoundingClientRect().height;

      if (elemHeight > 230) {
        elem.style.height = '230px';
        elem.style.overflowY = 'hidden';
        props.setTruncateState(true);
      }
    }
  }, []);

  let toggle = true;
  if (props.toggle === false) {
    toggle = false;
  }
  const bodyNode = toggle ? (
    <div className="highlights__meeting-notes-entry-container">
      {props.children}
      <AbridgeText
        maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
        isExpandable={true}
        greyFade={props.greyFade}
      >
        <PurifiedHTML
          className="ckeditor__output v5__body-copy"
          content={props.meeting_notes}
        />
      </AbridgeText>
    </div>
  ) : (
    <div className="highlights__meeting-notes-entry-container">
      {props.children}
      <PurifiedHTML
        className="ckeditor__output v5__body-copy"
        ref={inputEL}
        content={props.meeting_notes}
      />
    </div>
  );

  return (
    <div className="workspace__entry-body">
      <div className="workspace__entry-row">{bodyNode}</div>
    </div>
  );
};

MeetingNotesEntry.propTypes = {
  setTruncateState: PropTypes.func,
  toggle: PropTypes.bool,
  greyFade: PropTypes.bool,
};

export default MeetingNotesEntry;
