// @ts-nocheck FIXME
import React from 'react';

class Reflection extends React.Component {
  render() {
    let teacherReflectionNode = null;
    let separatorNode = null;
    let coachReflectionNode = null;

    if (this.props.portfolio.teacher_reflection) {
      teacherReflectionNode = (
        <div className="portfolio__reflection-body">
          {this.props.portfolio.teacher_reflection}
          <div className="portfolio__reflection-author">
            - {this.props.portfolio.author.first_name}{' '}
            {this.props.portfolio.author.last_name}
          </div>
        </div>
      );
    }

    if (this.props.portfolio.coach_reflection) {
      const coach = this.props.portfolio.coach_name;
      coachReflectionNode = (
        <div className="portfolio__reflection-body portfolio__reflection-body--coach">
          <span className="portfolio__reflection-quote">&ldquo;</span>
          <span className="portfolio__reflection-coach">
            {this.props.portfolio.coach_reflection}
          </span>
          <span className="portfolio__reflection-quote">&rdquo;</span>
          <div className="portfolio__reflection-author">
            - {coach ? coach + ', ' : ''}BetterLesson Coach
          </div>
        </div>
      );
    }

    // we only render this if we actually have some reflection text
    let reflections = null;
    if (teacherReflectionNode || coachReflectionNode) {
      // we only render the separator between the two if we have both reflections
      if (teacherReflectionNode && coachReflectionNode) {
        separatorNode = <hr className="portfolio__reflection-separator" />;
      }

      reflections = (
        <div className="portfolio__reflection">
          <div className="portfolio__section-headline portfolio__section-headline--intro">
            Reflections
          </div>
          {teacherReflectionNode}
          {separatorNode}
          {coachReflectionNode}
        </div>
      );
    }

    return reflections;
  }
}

export default Reflection;
