// @ts-nocheck FIXME
const Feedback = (props) => {
  let titleNode = null;
  if (props.title) {
    titleNode = (
      <div className="portfolio-v2__strategy-headline">{props.title}</div>
    );
  }

  let questionNode = null;
  if (props.question) {
    questionNode = (
      <div className="portfolio-v2__feedback-question">{props.question}</div>
    );
  }

  let answerNode = null;
  if (props.answer) {
    answerNode = (
      <div className="portfolio-v2__feedback-answer">{props.answer}</div>
    );
  }

  let commentNode = null;
  if (props.comments) {
    commentNode = props.comments.map((comment) => {
      return (
        <div
          className="portfolio__feedback-coach-message"
          key={props.step + '-feedback-comment-' + comment.created}
        >
          <div className="portfolio__feedback-coach-message-note">
            <span className="portfolio__feedback-quote">&ldquo;</span>
            <span className="portfolio__feedback-comment">{comment.data}</span>
            <span className="portfolio__feedback-quote">&rdquo;</span>
          </div>
          <div className="portfolio__feedback-coach-message-author">
            &mdash; {comment.first_name} {comment.last_name},{' '}
            {comment.is_coach ? 'BetterLesson Coach' : 'Teacher'}
          </div>
        </div>
      );
    });
  }

  return (
    <div className="portfolio-v2__loop-section">
      {titleNode}
      {questionNode}
      {answerNode}
      {commentNode}
    </div>
  );
};

export default Feedback;
