// @ts-nocheck FIXME
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // make sure any link with target=_blank also has rel=noopener
  if (node.hasAttribute('target')) {
    node.setAttribute('rel', 'noopener');
  }
});

const elements = {
  div: 'div',
  span: 'span',
  script: 'script',
  a: 'a',
};

/**
 * calls React.createElement to create HTML of specified tag
 * @param {string} name the name of the element to be created, e.g. div, p, span
 *     name must have an entry in elements, otherwise will default to div
 */
// eslint-disable-next-line react/display-name
const CustomTag = React.forwardRef(({ name, ...props }, ref) => {
  return React.createElement(
    elements[name] || elements.div,
    { ref: ref, ...props },
    props.children
  );
});

/** Wrapper component to sanitize HTML
 * @param {string} content the content to be sanitized
 * @param {string} tagName specified HTML tag around the content ,default div, additional tags should be added to {elements}
 * @param {boolean} sanitize will pass content into sanitizer if true, as-is otherwise
 * @param {object} sanitizerOptions config sanitizer, more at https://github.com/cure53/DOMPurify#can-i-configure-dompurify
 */
// eslint-disable-next-line react/display-name
const PurifiedHTML = React.forwardRef(
  (
    {
      content,
      tagName = 'div',
      sanitize = true,
      sanitizerOptions = null,
      ...props
    },
    ref
  ) => {
    const options = Object.assign({ ADD_ATTR: ['target'] }, sanitizerOptions);
    const html = sanitize ? DOMPurify.sanitize(content, options) : content;

    return (
      <CustomTag
        ref={ref}
        name={tagName}
        {...props}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
);

export default PurifiedHTML;
