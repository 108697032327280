// @ts-nocheck FIXME
import CustomSchoolsApi from './../../apis/CustomSchoolsApi';
import ParticipantApi from './../../apis/ParticipantApi';
import Utils from './../../components/DynamicUtils';
import Modal from './../../components/Modal';
import Store from './../../components/Store';

const defaultUi = {
  alert: {},
  // Modals
  schoolModal: {
    // Single select field
    schoolZipcode: {
      inputStatus: 'blur',
      charCount: 0,
      selectedOption: null,
      visibleOptions: [],
    },
  },
};

const store = {
  customSchools: [],
};

/****** PRIVATE FUNCTIONS ******/
const setAlert = (message, type) => {
  store.ui.alert = {
    type: type,
    message: message,
    handleClose: store.clearAlert,
  };
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.ui = Utils.clone(defaultUi);
  store.customSchools = data.customSchools;
  // Init modals
  store.ui.schoolModal = Modal(store, store.ui.schoolModal);
  return false;
};

store.clearAlert = () => {
  store.ui.alert = Utils.clone(defaultUi.alert);
};

store.edit = (data) => {
  store.ui.schoolModal.toggleLoadingState();
  const schoolName = data.sourceSchoolName || 'School';
  return CustomSchoolsApi.edit(data)
    .then((response) => {
      store.ui.schoolModal.close();
      store.customSchools = response.customSchools;
      setAlert(schoolName + ' has been updated successfully', 'alert');
    })
    .catch((response) => {
      store.ui.schoolModal.close();
      setAlert(
        response.error
          ? response.error
          : 'Sorry, there was a problem updating the school. Please try again later.',
        'error'
      );
    });
};

store.combine = (data) => {
  store.ui.schoolModal.toggleLoadingState();
  return CustomSchoolsApi.combine(data)
    .then((response) => {
      store.ui.schoolModal.close();
      store.customSchools = response.customSchools;
      setAlert('Schools have been combined successfully', 'alert');
    })
    .catch((response) => {
      store.ui.schoolModal.close();
      setAlert(
        response.error
          ? response.error
          : 'Sorry, there was a problem combining the schools. Please try again later.',
        'error'
      );
    });
};

store.approve = (data) => {
  store.ui.schoolModal.toggleLoadingState();
  const schoolName = data.sourceSchoolName || 'School';
  return CustomSchoolsApi.approve(data)
    .then((response) => {
      store.ui.schoolModal.close();
      store.customSchools = response.customSchools;
      setAlert(schoolName + ' has been approved successfully', 'alert');
    })
    .catch((response) => {
      store.ui.schoolModal.close();
      setAlert(
        response.error
          ? response.error
          : 'Sorry, there was a problem approving the school. Please try again later.',
        'error'
      );
    });
};

store.getSingleSelectElement = (data) => {
  const storeElement = store.ui.schoolModal.schoolZipcode;
  storeElement.charCount = data.input.length;
  return ParticipantApi.searchInstitutions({
    searchString: data.input,
  })
    .then((response) => {
      storeElement.visibleOptions = response.result;
    })
    .catch((response) => {
      storeElement.setFieldError({
        field: 'schoolZipcode',
        error:
          response && response.error
            ? response.error
            : 'An error occurred searching for the school. Please try again',
      });
    });
};

store.selectSingleSelectElement = (data) => {
  if (!data.feature || !data.field) {
    return false;
  }
  Object.assign(store.ui[data.feature][data.field], data.data);
};

store.setFeatureFieldError = (data) => {
  const errors = store.ui[data.feature].errors;
  if (data.error) {
    errors[data.field] = data.error;
  } else {
    delete errors[data.field];
  }
};

export default Store('CustomSchoolsStore', store);
