// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import ZenScroll from 'zenscroll';

const AccountSidebar = (props) => {
  const lab = useLegacyLabContext();

  const scrollToSection = (section) => {
    const element = document.getElementById('section-' + section);
    if (element) {
      // on desktop only, clicking on a sidebar link scrolls to the associated section
      ZenScroll.setup(600);
      ZenScroll.to(element);
    }
  };

  let settingsSubNav = null;
  let settingsActiveClass = '';
  if (props.tab === 'account') {
    settingsActiveClass = 'account-side__link--active';
    settingsSubNav = (
      <ul>
        <li>
          <a
            className="account-side__link--inset"
            onClick={() => scrollToSection('email-password')}
          >
            Email &amp; Password
          </a>
        </li>
        <li>
          <a
            className="account-side__link--inset"
            onClick={() => scrollToSection('time-zone')}
          >
            Time Zone
          </a>
        </li>
      </ul>
    );
  }

  let profileSubNav = null;
  let profileActiveClass = '';
  if (props.tab === 'profile') {
    let linkCaption = 'Educator Profile';
    let scrollDestination = 'profile';
    if (Utils.isContractCoach.call(lab, props.pageUser)) {
      linkCaption = 'Coaching Information';
      scrollDestination = 'coaching';
    }
    profileActiveClass = 'account-side__link--active';
    profileSubNav = (
      <ul>
        <li>
          <a
            className="account-side__link--inset"
            onClick={() => scrollToSection('basic-info')}
          >
            Basic Information
          </a>
        </li>
        <li>
          <a
            className="account-side__link--inset"
            onClick={() => scrollToSection(scrollDestination)}
          >
            {linkCaption}
          </a>
        </li>
      </ul>
    );
  }

  const baseUrl =
    props.userId !== props.pageUser.id
      ? `/account/${props.pageUser.id}`
      : '/account';

  // if (hasArchivedWork) {
  //     let archivedYears = [];
  //     let archivedYearsSubNav = null;
  //     let archiveActiveClass = '';
  //     if (props.tab === 'archive') {
  //         archiveActiveClass = 'account-side__link--active';
  //         Object.keys(props.archivedWork).forEach((year) => {
  //             archivedYears.push(x
  //                 <li key={`sidebar-link${year.replace(' - ', '-')}`}>
  //                     <a className="account-side__link--inset"
  //                         onClick={() => scrollToSection(`archived-year-${year.replace(' - ', '-')}`)}>
  //                         {year}
  //                     </a>
  //                 </li>
  //             );
  //         });
  //         archivedYearsSubNav = (
  //             <ul>
  //                 {archivedYears}
  //             </ul>
  //         );
  //     }
  //     archivedWorkNode = (
  //         <li>
  //             <NavigationLink className={`account-side__link ${archiveActiveClass}`} url={`${baseUrl}/archive`}>
  //                 Archived Work
  //             </NavigationLink>
  //             {archivedYearsSubNav}
  //         </li>
  //     );
  // }

  return (
    <ul className="account-side" data-test-id="account-side">
      <li>
        <NavigationLink
          className={`account-side__link ${settingsActiveClass}`}
          url={baseUrl}
        >
          Settings
        </NavigationLink>
        {settingsSubNav}
      </li>
      <li>
        <NavigationLink
          className={`account-side__link ${profileActiveClass}`}
          url={`${baseUrl}/profile`}
        >
          About Me
        </NavigationLink>
        {profileSubNav}
      </li>
      {/* {archivedWorkNode} */}
    </ul>
  );
};

export default AccountSidebar;
