// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import React from 'react';
import ReactDOM from 'react-dom';
import StrategyApi from './../../apis/StrategyApi';
import TmlLoopApi from './../../apis/TmlLoopApi';
import Analytics from './../../components/Analytics';
import Utils from './../../components/DynamicUtils';
import LayerManager from './../../components/LayerManager';
import { Router } from './../../components/Router';
import SmoothScroll from './../../components/SmoothScroll';
import AbsoluteAlert from './../Alerts/Absolute';
import ArtifactModal from './../Modals/Artifact';
import NavigationLink from './../NavigationLink';
import ScaleCenterImage from './../ScaleCenterImage';
import Artifact from './../Strategy/Artifact';
import ArtifactOverlay from './../Strategy/ArtifactOverlay';
import ActionStep from './Strategy/ActionStep';
import MobileStrategySubHead from './Strategy/MobileStrategySubHead';
import StrategyCapsuleV2 from './Strategy/StrategyCapsule';

class Strategy extends React.Component {
  static contextType = LegacyLabContext;

  state = {
    pinned: this.props.si.pinned,
    notes: this.props.si.notes,
    btnDisabledClass: '',
    rating: this.props.si.rating ? this.props.si.rating : 0,
    alert: null,
    alertType: '',
    artifacts: this.props.si.artifacts,
  };

  submitStatus = 'ready';
  scrollInterval = null;
  didScroll = false;

  updateArtifactState = (artifactIn) => {
    const tmpArtifacts = this.state.artifacts;
    for (const i in tmpArtifacts) {
      if (tmpArtifacts[i].id === artifactIn.id) {
        if (artifactIn.image_type === 'none') {
          artifactIn.preview = null;
          artifactIn.preview = '';
          artifactIn.icon = 'link';
        }
        tmpArtifacts[i] = artifactIn;
        break;
      }
    }
    this.setState({ artifacts: tmpArtifacts });
  };

  getAnalyticsTrackingLabel = () => {
    return 'from_sb_strategy';
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      pinned: nextProps.si.pinned,
      notes: nextProps.si.notes,
      btnDisabledClass: '',
      rating: nextProps.si.rating ? nextProps.si.rating : 0,
      alert: null,
      alertType: '',
      artifacts: nextProps.si.artifacts,
    });
  }

  componentDidMount() {
    // if we are coming in from a loop context, then add some logic to slide up the button
    if (this.props.loop) {
      window.addEventListener('scroll', this.scrolled);

      this.scrollInterval = setInterval(
        function () {
          if (this.didScroll) {
            this.didScroll = false;

            const b = ReactDOM.findDOMNode(
              this.refs.showFixedSelectButtonMarker
            ).getBoundingClientRect();
            if (b.top < 0) {
              ReactDOM.findDOMNode(this.refs.fixedSelectButton).className =
                'si-v2__detail-fixedselectbutton';
            } else {
              ReactDOM.findDOMNode(this.refs.fixedSelectButton).className =
                'tc__hidden';
            }
          }
        }.bind(this),
        100
      );
    }
  }

  componentWillUnmount() {
    // clear interval, that's tracking scrolling
    clearInterval(this.scrollInterval);

    // clear scroll event
    window.removeEventListener('scroll', this.scrolled);
  }

  scrolled = () => {
    this.didScroll = true;
  };

  authorDataFixCase = (str) => {
    if (str) {
      // make string lowercase then captailize the first letter
      str = str.toLowerCase().replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      return str;
    }
  };

  handleSelectStrategy = (position, e) => {
    e.preventDefault();

    this.setState({
      btnDisabledClass: ' disabled',
    });

    // prevent resubmission
    if (this.submitStatus !== 'ready') {
      return;
    }
    this.submitStatus = 'waiting';

    const data = {
      step: this.props.step,
      type: this.props.loop.type,
      challengeInstanceId: this.props.loop.challenge_instance_id,
      id: this.props.si.id,
      tmlLoopId: this.props.loop.id,
      teacherId: this.props.loop.user_id,
    };

    const metricFrom = '?from=' + 'strategy_select_' + position;

    if (this.props.step === 'teach') {
      // call the api to save a measurement, and route to teach feedback
      TmlLoopApi.updateStrategy(data)
        .then(
          function () {
            Router.route(
              '/coaching/lab/tml/' +
                this.props.loop.id +
                '/teach/feedback' +
                metricFrom
            );
            this.submitStatus = 'ready';
          }.bind(this)
        )
        .catch(
          function () {
            this.submitStatus = 'ready';
            this.setState({
              btnDisabledClass: '',
              alert:
                'Sorry, there was a problem selecting this strategy. Please try again later.',
              alertType: 'error',
            });
          }.bind(this)
        );
    } else {
      // call the api to save a measurement
      TmlLoopApi.saveNextLoop(data)
        .then(
          function (response) {
            Router.route(
              '/coaching/lab/challenge/' +
                response['payload']['challenge_instance_id'] +
                metricFrom
            );
            this.submitStatus = 'ready';
          }.bind(this)
        )
        .catch(
          function () {
            this.submitStatus = 'ready';
            this.setState({
              btnDisabledClass: '',
              alert:
                'Sorry, there was a problem selecting this strategy. Please try again later.',
              alertType: 'error',
            });
          }.bind(this)
        );
    }
  };

  handleShowAlert = (type, message) => {
    this.setState({ alert: message, alertType: type });
  };

  handleCloseAlert = () => {
    this.setState({ alert: null, alertType: '' });
  };

  handleRatingClick = (rating, e) => {
    e.preventDefault();
    const data = {
      rating: rating,
      id: this.props.si.id,
    };
    StrategyApi.postRating(data).then(
      function () {
        this.setState({ rating: rating });
      }.bind(this)
    );
  };

  handleLinkOverride = (url) => {
    // inhibit the normal functioning of link for public users and bl users
    if (
      this.context.pageData.user.is_public ||
      this.context.pageData.user.is_bl
    ) {
      return null;
    }
    return url;
  };

  getDesktopBreadCrumbsNode = () => {
    let currentCategoryName,
      currentCategoryUrlBit,
      statusClass,
      analyticsFrom,
      breadcrumbClassName;

    // If the user has not selected a category, we cannot construct the breadcrumb:
    if (!this.props.selectedCategory) {
      return null;
    }

    // loop through categories to set up data for current category
    this.props.categories.forEach(
      function (category) {
        if (this.props.selectedCategory === category.id) {
          currentCategoryName = category.name;
          currentCategoryUrlBit = category.seo_url;
        }
      }.bind(this)
    );

    // Indicates to Analytics where the request came from:
    analyticsFrom = 'sb_strategy_breadcrumb';
    // set class of challenge breadcrumb based on the challenge's status
    statusClass = this.props.challengeStatus
      ? ' browser-v2__crumb-link--' + this.props.challengeStatus
      : '';
    // Set the breadcrumb class based on whether we have meta strategies:
    breadcrumbClassName = this.props.si.metaStrategy
      ? 'browser-v2__crumb_id'
      : 'browser-v2__crumb';

    // Category node:
    const categoryNode = (
      <div className={breadcrumbClassName}>
        <NavigationLink
          className="browser-v2__crumb-link"
          title={currentCategoryName}
          url={
            '/coaching/lab/strategy-browser-' +
            currentCategoryUrlBit +
            '?from=' +
            analyticsFrom
          }
          override={this.handleLinkOverride}
        >
          {currentCategoryName}
        </NavigationLink>
      </div>
    );
    const sgaNode = (
      <div className={breadcrumbClassName}>
        <NavigationLink
          className="browser-v2__crumb-link"
          title={this.props.si.sga.name}
          url={
            '/coaching/lab/strategy-browser-' +
            currentCategoryUrlBit +
            '?from=' +
            analyticsFrom
          }
          override={this.handleLinkOverride}
        >
          {this.props.si.sga.name}
        </NavigationLink>
      </div>
    );
    const tcNode = (
      <div className={breadcrumbClassName}>
        <NavigationLink
          className={'browser-v2__crumb-link' + statusClass}
          title={this.props.si.challenge.nickname}
          url={
            '/coaching/lab/strategy-browser/teaching-challenge/' +
            this.props.si.challenge.id +
            '/' +
            this.props.si.challenge.seo_url +
            '?from=' +
            analyticsFrom
          }
          override={this.handleLinkOverride}
        >
          {this.props.si.challenge.nickname}
        </NavigationLink>
      </div>
    );

    let metaStrategyNode;
    if (this.props.si.metaStrategy) {
      metaStrategyNode = (
        <div className={breadcrumbClassName}>
          <NavigationLink
            className={'browser-v2__crumb-link'}
            title={this.props.si.challenge.nickname}
            url={
              '/coaching/lab/strategy-browser/teaching-challenge/' +
              this.props.si.challenge.id +
              '/' +
              this.props.si.challenge.seo_url +
              '/meta-strategy/' +
              this.props.si.metaStrategy.id +
              '?from=' +
              analyticsFrom
            }
            override={this.handleLinkOverride}
          >
            {this.props.si.metaStrategy.name}
          </NavigationLink>
        </div>
      );
    }

    let strategyTitle, strategyTitleNode;
    if (this.props.si.metaStrategy) {
      // Show the SI name:
      strategyTitle = this.props.si.title;
    } else if (this.props.si.strategy) {
      // Show the strategy name:
      strategyTitle = this.props.si.strategy.name;
    }

    if (strategyTitle) {
      strategyTitleNode = (
        <div className={breadcrumbClassName} title={strategyTitle}>
          {strategyTitle}
        </div>
      );
    }

    return (
      <div className="browser-v2__wrap--breadcrumbs show-desktop hide-phone">
        <div className="row">
          <div className="cute-12-desktop">
            {categoryNode}
            {sgaNode}
            {tcNode}
            {metaStrategyNode}
            {strategyTitleNode}
          </div>
        </div>
      </div>
    );
  };

  getArtifactNodes = () => {
    const artifactNodes = [];
    const si = this.props.si;
    if (this.state.artifacts.length === 0) {
      return null;
    }

    // if any artifact is going to be editable, all artifacts need to be taller
    let tallerArtifact = false;
    if (
      Utils.isAdmin.call(this.context) ||
      Utils.isServicesManager.call(this.context)
    ) {
      this.state.artifacts.map(function (artifact) {
        if (artifact.icon === 'link') {
          tallerArtifact = true;
        }
      });
    }

    this.state.artifacts.map(
      function (artifact) {
        // pop overlay artifact if we have an id for it
        if (artifact.id === si.overlay_artifact_id) {
          const layerProps = {
            className: 'artifact-overlay',
            artifact: artifact,
            closeUrl:
              '/coaching/lab/strategy/' +
              si.id +
              '/' +
              si.seo_url +
              '?from=sb_strategy_artifact',
            analyticsLabel: 'from_sb_strategy_artifact',
          };

          const url =
            '/coaching/lab/strategy/' + si.id + '/artifact/' + artifact.id;

          LayerManager.new(ArtifactOverlay, layerProps, ArtifactModal, url);
        }

        artifactNodes.push(
          <Artifact
            key={'artifact' + artifact.id}
            updateArtifactState={this.updateArtifactState}
            tallerArtifact={tallerArtifact}
            analyticsLabel="from_sb_strategy_artifact"
            artifact={artifact}
          />
        );
      }.bind(this)
    );

    return (
      <div className="si-v2__artifacts row">
        <div className="si-v2__artifacts-header tc__headline-2 cute-12-desktop">
          Artifacts
        </div>
        {artifactNodes}
      </div>
    );
  };

  scrollToArtifacts = () => {
    // Scrolls down to the artifacts area of the page
    Analytics.logEvent({
      category: 'artifact',
      action: 'view',
      label: 'from_sb_strategy_artifacts_count',
    });
    const artifactRows = document.querySelectorAll('.si-v2__artifacts-header');
    const artifactRowPosition = Utils.getAbsolutePosition(artifactRows[0]);
    SmoothScroll(document.body, artifactRowPosition[1], 200);
  };

  getArtifactsCountNode = () => {
    if (this.props.si.artifacts && this.props.si.artifacts.length > 0) {
      return (
        <div className="si-v2__detail si-v2__artifacts-count-container cute-12-desktop">
          <span>
            <a
              href="#"
              className="si-v2__artifacts-count-link"
              onClick={this.scrollToArtifacts}
            >
              Artifacts
            </a>
            : {this.props.si.artifacts.length}
          </span>
        </div>
      );
    }
    return null;
  };

  handleActionStepClick = () => {
    Analytics.logEvent({
      category: 'action_step',
      action: 'click',
      label: 'from_sb_strategy',
    });
  };

  getTeachingChallengeNode = () => {
    // The teaching challenge node will look different for demo users:
    return (
      <div className="cute-12-desktop si-v2__detail">
        <span className="si-v2__detail-highlight">Teaching Challenge: </span>
        <span className="si-v2__detail-copy tc__caption-copy">
          {this.props.si.challenge.nickname}
        </span>
      </div>
    );
  };

  getGradesNode = () => {
    if (this.props.si.grades) {
      // Create an array of grade names:
      const gradeNames = this.props.si.grades.map(function (grade) {
        return grade.name;
      });
      if (gradeNames.length > 0) {
        return (
          <div className="si-v2__detail cute-12-desktop">
            <span className="si-v2__detail-highlight">Grade Level: </span>
            <span className="si-v2__detail-copy tc__caption-copy titleCase">
              {gradeNames.join(', ')}
            </span>
          </div>
        );
      }
    }
    return null;
  };

  render() {
    // show the demo version of the page if necessary
    // (has no user actions and a slightly different design)
    const isContentViewer = !Utils.arrayContainsAnyItemInAnotherArray(
      this.props.user.auth_roles,
      ['TC_TEACHER', 'BL_SERVICES_MANAGER']
    );

    // set up breadcrumbs
    const desktopBreadcrumbsNode = this.getDesktopBreadCrumbsNode();
    const mobileBreadCrumbsNode = <MobileStrategySubHead si={this.props.si} />;

    // show alert if there are any api errors or success messages
    let alertNode = null;
    if (this.state.alert) {
      alertNode = (
        <AbsoluteAlert
          type={this.state.alertType}
          message={this.state.alert}
          cssClasses={{ container: 'alert-banner--tml' }}
          handleClose={this.handleCloseAlert}
        />
      );
    }

    // local variable
    const si = this.props.si;
    si.notes = this.state.notes;

    // set up artifact nodes
    const artifactNodes = this.getArtifactNodes();

    // set up the similar strategy nodes
    let similarStrategiesNode = null;
    if (
      this.props.relatedStrategies &&
      this.props.relatedStrategies.length > 0
    ) {
      const similarStrategyNodes = this.props.relatedStrategies.map(
        function (strategy) {
          let url;
          if (this.props.loop) {
            url =
              '/coaching/lab/tml/' +
              this.props.loop.id +
              '/learn/strategy/' +
              strategy.id;
          } else {
            url = '/coaching/lab/strategy/' + strategy.id;
          }
          return (
            <StrategyCapsuleV2
              key={'strategy' + strategy.id}
              url={url}
              capsuleType={'similar'}
              strategy={strategy}
              loop={this.props.loop}
              step={this.props.step}
              analyticsFrom="sb_similar_strategies"
            />
          );
        }.bind(this)
      );

      similarStrategiesNode = (
        <div className="row">
          <div className="si-v2__headline--similar si-v2__headline cute-12-desktop">
            Similar Strategies
          </div>
          {similarStrategyNodes}
        </div>
      );
    }
    // set up the essential questions if they exist
    let questionsNode = '';
    if (si.essential_questions && si.essential_questions.length > 0) {
      const questionNode = si.essential_questions.map(function (
        question,
        offset
      ) {
        return <li key={'question' + offset}>{question.name}</li>;
      });
      questionsNode = (
        <div>
          <div className="si-v2__headline si-v2__headline--detail">
            Strategy Implementation Questions
          </div>
          <div className="tc__body-copy">
            Questions to think about when implementing the strategy
          </div>
          <ul className="si-v2__questions tc__body-copy">{questionNode}</ul>
        </div>
      );
    }

    // set up the grades if they exist
    const gradesNode = this.getGradesNode();

    let lessonNotesNode = '';
    // Hide author notes for ID Strategies:
    if (si.author_notes && !si.metaStrategy) {
      const authorNotes = {
        __html: si.author_notes.replace(
          'href="/',
          'href="' + process.env.REACT_APP_LAB_URL + '/'
        ),
      };
      lessonNotesNode = (
        <div className="si-v2__lesson-notes">
          <div className="si-v2__headline si-v2__headline--detail">
            Lesson Notes
          </div>
          <div className="tc__body-copy">
            <PurifiedHTML content={authorNotes} />
          </div>
        </div>
      );
    }

    // allow html tags entered by the author for the notes and description
    const strategyDescription = { __html: si.description };

    // lower case strings then uppercase first character
    let authorSchool = '';
    let authorLocation = '';
    if (si.author && si.author.school) {
      authorSchool = this.authorDataFixCase(si.author.school.name);
      authorLocation =
        this.authorDataFixCase(si.author.school.location_city) +
        ', ' +
        si.author.school.location_state;
    }

    // set up link back to mtp lesson (Hidden for ID Strategies)
    let lessonLink = null;
    if (this.props.si.lesson && !si.metaStrategy) {
      lessonLink = (
        <a
          className="lesson-link-v2"
          href={
            process.env.REACT_APP_TEACHING_URL +
            '/lesson/' +
            this.props.si.lesson.id +
            '/' +
            this.props.si.lesson.seo_url +
            '?from=sb_strategy_lesson'
          }
        >
          <div className="lesson-link-v2__icon tc-subject-1"></div>
          <div className="lesson-link-v2__details">
            <div className="lesson-link-v2__title tc__link">
              {this.props.si.lesson.name}
            </div>
            <div className="lesson-link-v2__crumbs">
              {this.props.si.lesson.course.name} &raquo;{' '}
              {this.props.si.lesson.unit.name}
            </div>
          </div>
        </a>
      );
    }

    // add the admin features if not being shown in a loop

    // set up default author photo
    let authorPhotoNode = (
      <div className="si-v2__author-image-mask si-v2__author-image-mask--none">
        <img
          className="si-v2__author-image"
          src="/legacy-images/default-user.svg"
        />
      </div>
    );

    // If the author has a photo, show it:
    if (si.author && si.author.photo) {
      authorPhotoNode = (
        <ScaleCenterImage
          src={si.author.photo}
          className="si-v2__author-image"
        />
      );
    }

    // show the TC header
    const headerNode = <Header {...this.props} page="StrategyBrowser" />;

    let authorNode;
    if (si.author) {
      authorNode = (
        <div className="si-v2__author">
          {authorPhotoNode}
          <div className="si-v2__author-details">
            <div className="si-v2__author-name">
              {si.author.first_name} {si.author.last_name}
            </div>
            <div className="si-v2__author-detail">{authorSchool}</div>
            <div className="si-v2__author-detail">{authorLocation}</div>
          </div>
        </div>
      );
    }

    const siNodeClassName = si.metaStrategy ? 'si-v2__copy_id' : 'si-v2__copy';

    const siNode = (
      <div>
        <h3 className="tc__headline-1">{si.title}</h3>
        <PurifiedHTML
          className={siNodeClassName + ' tc__feature-copy'}
          content={strategyDescription}
        />
      </div>
    );

    // Show either the meta strategy or the strategy:
    // The style and positioning will be different for demo/anonymous users:
    let demoStrategyNode = null;
    let desktopStrategyNode, mobileStrategyNode;
    if (si.metaStrategy) {
      if (isContentViewer) {
        demoStrategyNode = [
          <div
            key="metastrategy-desktop"
            className="si-v2__meta-title show-desktop hide-phone"
          >
            <div className="tc__headline-6">{si.metaStrategy.name}</div>
          </div>,
          <div
            key="metastrategy-mobile"
            className="si-v2__meta-title si-v2__meta-title--phone show-phone hide-desktop"
          >
            <div className="tc__headline-6">{si.metaStrategy.name}</div>
          </div>,
        ];
      } else {
        desktopStrategyNode = (
          <div
            key="metastrategy-desktop"
            className="si-v2__detail show-desktop hide-phone"
          >
            <div className="si-v2__meta-name tc__headline-6">
              {si.metaStrategy.name}
            </div>
          </div>
        );
        mobileStrategyNode = (
          <div
            key="metastrategy-mobile"
            className="si-v2__detail si-v2__meta-title--phone show-phone hide-desktop"
          >
            <div className="si-v2__meta-name tc__headline-6">
              {si.metaStrategy.name}
            </div>
          </div>
        );
      }
    } else if (si.strategy) {
      if (isContentViewer) {
        demoStrategyNode = [
          <div
            key="strategy-desktop"
            className="si-v2__title show-desktop hide-phone"
          >
            <div className="strategy__icon tc-target"></div>
            <div className="si-v2__tag tc__headline-6">
              {si.strategy.name}:{' '}
              <span className="si-v2__tag--desc">{si.strategy.definition}</span>
            </div>
          </div>,
          <div
            key="strategy-mobile"
            className="si-v2__title show-phone hide-desktop"
          >
            <div className="si-v2__icon  strategy__icon tc-target"></div>
            <div className="si-v2__tag tc__headline-6">
              {si.strategy.name}:{' '}
              <span className="si-v2__tag--desc">{si.strategy.definition}</span>
            </div>
          </div>,
        ];
      } else {
        desktopStrategyNode = (
          <div
            key="strategy-desktop"
            className="si-v2__detail show-desktop hide-phone"
          >
            <div className="strategy__icon tc-target"></div>
            <div className="si-v2__tag tc__headline-6">{si.strategy.name}:</div>
            <div className="si-v2__tag-desc tc__caption-copy">
              {si.strategy.definition}
            </div>
          </div>
        );
        mobileStrategyNode = (
          <div
            key="strategy-mobile"
            className="si-v2__detail show-phone hide-desktop"
          >
            <div className="si-v2__icon  strategy__icon tc-target"></div>
            <div className="si-v2__title-text">
              <span className="si-v2__tag si-v2__tag--phone tc__headline-6">
                {si.strategy.name}:
              </span>
              <span className="si-v2__tag-desc tc__caption-copy">
                {si.strategy.definition}
              </span>
            </div>
          </div>
        );
      }
    }

    // Add the action steps (only certain roles can see them)
    let actionStepsNode = null;
    if (
      si.action_steps &&
      si.action_steps.length > 0 &&
      Utils.arrayContainsAnyItemInAnotherArray(this.props.user.auth_roles, [
        'ADMIN',
        'BL_SERVICES_MANAGER',
        'TC_DATA_EDITOR',
        'TC_DATA_VIEWER',
        'TC_CONTENT_CURATOR',
        'BL_MANAGER',
      ])
    ) {
      const actionSteps = [];
      si.action_steps.map(
        function (actionStep) {
          actionSteps.push(
            <ActionStep
              key={'actionStep-' + actionStep.id}
              id={actionStep.id}
              action_step={actionStep.action_step}
            />
          );
        }.bind(actionSteps)
      );
      actionStepsNode = (
        <div
          className="si-v2__action-steps-container"
          onClick={this.handleActionStepClick}
        >
          <div className="si-v2__action-steps-header">Action Steps</div>
          <ul className="si-v2__action-step-list">{actionSteps}</ul>
        </div>
      );
    }

    // Add the prep time node if needed:
    let prepTimeNode;
    if (si.skill_level && !si.metaStrategy) {
      prepTimeNode = (
        <div className="si-v2__detail cute-12-phone">
          <span className="si-v2__detail-highlight">
            <div className="si-v2__detail-icon tc-clock"></div>
            <span className="si-v2__detail-preptime">prep time: </span>
          </span>
          <span className="si-v2__detail-copy tc__caption-copy">
            {si.skill_level}
          </span>
        </div>
      );
    }

    // Add the artifacts count node:
    const artifactsCountNode = this.getArtifactsCountNode();
    const siDetailsClassName = si.metaStrategy
      ? 'si-v2__details_id'
      : 'si-v2__details';
    // If we hide the userActions area, we need to show the teaching challenge name under grades
    const teachingChallengeNode =
      this.getTeachingChallengeNode(isContentViewer);

    return (
      <div className="strategy-implementation" id="artifact-parent">
        {!this.props.loop ? <div>{headerNode}</div> : ''}
        {alertNode}
        {desktopBreadcrumbsNode}
        {mobileBreadCrumbsNode}
        <div className="row si-v2__strategy-container">
          <div className={'si-v2__head cute-12-phone cute-12-desktop'}>
            {demoStrategyNode}
            {siNode}
            {mobileStrategyNode}
            <div className="clearfix"></div>
            {isContentViewer ? null : teachingChallengeNode}
          </div>
          {this.props.loop ? (
            <div className="tc__center-text cute-12-desktop">
              <a
                className={
                  'tc__btn tc__btn--blue' + this.state.btnDisabledClass
                }
                href="#select-strategy"
                onClick={this.handleSelectStrategy.bind(this, 'top')}
              >
                Select Strategy
              </a>
              <div ref="showFixedSelectButtonMarker"></div>
              <div ref="fixedSelectButton" className="tc__hidden">
                <a
                  className={
                    'tc__btn tc__btn--blue' + this.state.btnDisabledClass
                  }
                  href="#select-strategy"
                  onClick={this.handleSelectStrategy.bind(this, 'bottom')}
                >
                  Select Strategy
                </a>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={siDetailsClassName + ' row'}>
          <div className="cute-12-phone show-phone hide-desktop">
            <div className="si-v2__headline si-v2__headline--detail cute-12-phone">
              About This Strategy
            </div>
            {prepTimeNode}
            {gradesNode}
            {isContentViewer ? teachingChallengeNode : null}
            {artifactsCountNode}
            {authorNode}
          </div>
          <div className="cute-9-desktop cute-12-phone">
            {actionStepsNode}
            {questionsNode}
            {lessonNotesNode}
            {lessonLink}
          </div>
          <div className="cute-3-desktop show-desktop hide-phone">
            <div className="">
              <div className="si-v2__headline si-v2__headline--detail cute-12-desktop">
                About This Strategy
              </div>
              {desktopStrategyNode}
              {prepTimeNode}
              {gradesNode}
              {isContentViewer ? teachingChallengeNode : null}
              {artifactsCountNode}
            </div>

            <div className="">{authorNode}</div>
          </div>
        </div>
        {artifactNodes}
        {similarStrategiesNode || this.props.loop ? (
          <div className="si-v2__wrap--similar show-desktop hide-phone">
            {similarStrategiesNode}
            {this.props.loop ? (
              <div className="si-v2__back-links tc__center-text">
                <NavigationLink
                  className="si-v2__link si-v2__link--strategies tc__link"
                  url={
                    '/coaching/lab/tml/' +
                    this.props.id +
                    '/learn/strategies?from=strategy_more'
                  }
                  override={this.handleLinkOverride}
                >
                  See More Strategies
                </NavigationLink>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default Strategy;
