// @ts-nocheck FIXME
import React from 'react';
import Utils from './../components/StaticUtils';
import Badge from './Badges/Badge';
import Header from './Header';
import ScaleCenterImage from './ScaleCenterImage';

class Badges extends React.Component {
  componentDidMount() {
    this.scrollToBadge();
  }

  componentWillReceiveProps(nextProps) {
    this.scrollToBadge();
  }

  scrollToBadge = () => {
    const code = window.location.hash.substr(1);
    if (code.length == 0) {
      return;
    }
    const elemPosition = Utils.getAbsolutePosition(
      document.getElementById(code)
    );

    // delay the scroll do to race-condition w/ main app
    setTimeout(function () {
      document.body.scrollTop = parseInt(elemPosition[1]);
    }, 500);
  };

  render() {
    // convenience var, convert badges to hide to an object
    const badgesToHide = {};
    this.props.badgesToHide.map(function (badge) {
      badgesToHide[badge] = true;
    });

    // set up default user photo
    let userPhotoNode = (
      <div className="badges__image-mask badges__image-mask--none">
        <img
          className="badges__image__image"
          src="/legacy-images/default-user.svg"
        />
      </div>
    );

    // if the user photo exists display the user's image
    if (this.props.teacher.photo) {
      userPhotoNode = (
        <ScaleCenterImage
          src={this.props.teacher.photo}
          className="badges__image"
        />
      );
    }

    // build all the badge category sections
    let badgeCount = 0;
    let badgePoints = 0;
    const badgesNode = this.props.badgeCategories.map(function (category) {
      return (
        <div
          key={category.code}
          id={category.code}
          className="badges__category"
        >
          <div className="badges__category-icon">
            <img
              src={
                '/legacy-images/badges/parent-badge-' + category.code + '.svg'
              }
            />
          </div>
          {category.badges.map(function (badge) {
            if (badge.achieved) {
              badgeCount += 1;
              badgePoints += badge.points;
            }
            if (!badge.achieved && badge.code in badgesToHide) {
              return;
            }
            return <Badge key={badge.code} category={category} badge={badge} />;
          })}
          <div className="clearfix"></div>
        </div>
      );
    });

    // set up text under the teacher's name
    let subheadline = (
      <div className="badges__text">
        Earn badges and experience points by completing TeachCycle tasks!
      </div>
    );
    if (badgeCount > 0) {
      subheadline = (
        <div className="badges__text">
          You&rsquo;ve earned <span className="highlight">{badgeCount}</span>{' '}
          TeachCycle&nbsp; badges worth{' '}
          <span className="highlight">{badgePoints}</span> experience points!
        </div>
      );
    }

    return (
      <div>
        <Header {...this.props} page="Badges" />

        <div className="row">
          <div className="cute-8-desktop center-desktop cute-12-phone">
            <div className="badges__headline">
              {userPhotoNode} {this.props.teacher.first_name}{' '}
              {this.props.teacher.last_name}
            </div>
            {subheadline}
            {badgesNode}
          </div>
        </div>
      </div>
    );
  }
}

export default Badges;
