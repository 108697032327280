// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import Breadcrumb from '@legacy-components/react/V5/Common/Breadcrumb';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import AttendeesTable from './modules/AttendeesTable';
import PLCards from './modules/PLCards';
import PreviewLinkCapsule from './modules/PreviewLinkCapsule';

const Event = (props) => {
  const groups = props.event.groups;
  // get user's current time and convert in EST
  const currentTime = moment.tz('US/Eastern');

  const breadcrumb = [
    {
      text: 'Homepage',
      link: `/lab/leader/${props.lead_id}/home`,
    },
    {
      text: `${props.event.display_name}`,
    },
  ];

  // set up the columns/blocks of event session dates at the top of the page
  const eventDateCols = [];
  // if this is an Advanced event with groups, display the group name at the top of each column
  if (props.event.advanced_event && ArrayHelpers.hasElements(groups)) {
    // use the groups to create arrays to organize the session headers
    groups.forEach((group) => {
      // at the top of the page, we only separate out the groups if this is an Advanced event
      eventDateCols.push({
        id: group.id,
        nodes: [
          <div key={`group-headline-${group.id}`}>
            {group.name || `Group ${group.num}`}
          </div>,
        ],
      });
    });
  }

  // set up data for the session checkin columns
  const sessionNumMap = {};
  let maxSessionNum = 1;
  let firstSessionDate;
  // if there are multiple groups in a Basic event,
  // we just show the list of sessions at the top since they are the same for all groups
  const displayGroupId =
    props.event.sessions && props.event.sessions.length
      ? props.event.sessions[0].group_id
      : null;
  const isSingleSession =
    groups.length === props.event.sessions.length ||
    (!ArrayHelpers.hasElements(groups) && props.event.sessions.length === 1);
  props.event.sessions.forEach((session) => {
    // update any data needed for the table session checkin columns
    sessionNumMap[session.id] = session.num;
    if (session.num > maxSessionNum) {
      maxSessionNum = session.num;
    }

    // the text of the session date/start time/end time depends on what data we have
    let sessionDescription = `${session.name}: ` || `Session ${session.num}: `;
    // if there is only one session (per group, or with no groups), don't show the name
    if (isSingleSession) {
      sessionDescription = '';
    }
    // text of session date, time, or TBD if neither of them present
    let sessionDateTime = 'TBD';
    // only show dates/times if the session date is set
    if (session.session_date_formatted) {
      // check if this is the first session for this event
      if (
        !firstSessionDate ||
        moment(session.session_date_formatted) < firstSessionDate
      ) {
        firstSessionDate = moment(session.session_date_formatted);
      }
      const sessionDate = moment(session.session_date_formatted).format(
        'MMMM D, YYYY'
      );
      // only show times if both times are set
      const sessionTimes =
        session.session_start_time_formatted &&
        session.session_end_time_formatted
          ? Utils.formatEventSessionTime(
              session.session_start_time_formatted,
              session.session_end_time_formatted,
              props.event.time_zone.pytz_timezone,
              false
            )
          : '';
      sessionDateTime = sessionTimes
        ? `${sessionDate}, ${sessionTimes}`
        : `${sessionDate}`;
    }

    // show lighter-colored text if session already ended
    const pastSessionClass =
      session.session_end_time_formatted &&
      moment(session.session_end_time_formatted).isBefore(currentTime)
        ? ' pl-event__past-session-times'
        : '';
    const sessionProductName = session.ld_product
      ? session.ld_product.name + ', '
      : '';
    const sessionNode = (
      <div
        className={`pl-event__session-times v5__body-copy${pastSessionClass}`}
        key={`event-session-${session.id}`}
      >
        <span className="pl-event__text-bold">{sessionDescription}</span>
        {sessionProductName}
        {sessionDateTime}
      </div>
    );

    // add this session data to the correct column at the top of the page, based on its group
    const groupHeaderIndex = Utils.getItemIndexFromArrayOfObjects(
      eventDateCols,
      'id',
      session.group_id
    );
    const noGroupHeaderIndex = Utils.getItemIndexFromArrayOfObjects(
      eventDateCols,
      'id',
      'na'
    );

    if (groupHeaderIndex === -1 && noGroupHeaderIndex === -1) {
      eventDateCols.push({
        id: 'na',
        nodes: [sessionNode],
      });
    } else if (
      groupHeaderIndex === -1 &&
      noGroupHeaderIndex !== -1 &&
      (!ArrayHelpers.hasElements(groups) || session.group_id === displayGroupId)
    ) {
      eventDateCols[noGroupHeaderIndex].nodes.push(sessionNode);
    } else if (groupHeaderIndex !== -1) {
      eventDateCols[groupHeaderIndex].nodes.push(sessionNode);
    }
  });

  return (
    <div>
      <Header page="PartnerLeaderEvent" {...props} />

      <div className="pl-event__main-content v5__body-copy">
        <div className="row row--1200">
          <div className="cute-12-desktop">
            <Breadcrumb trail={breadcrumb} divider=" / " />
          </div>
        </div>

        <div className="row row--1200 pl-event__illustration-row">
          <div className="event__attendee-header-image-container cute-4-desktop">
            <img
              className="pl-event__illustration"
              src={Utils.mapBLServiceNameToIllustration(props.event.service)}
              alt={props.event.service}
            />
          </div>
          <div>
            <div className="v5__heading-1 pl-event__display-name">
              {props.event.display_name}
            </div>
            <div className="v5__heading-4">
              Event Date{isSingleSession ? '' : 's'}
            </div>
            <div className="pl-event__timezone">
              {props.event.time_zone.name}
            </div>
            <div className="pl-event__dates">
              {eventDateCols.map((dateCol) => {
                return <div key={`group-${dateCol.id}`}>{dateCol.nodes}</div>;
              })}
            </div>
          </div>
        </div>

        <div className="row row--1200">
          <PreviewLinkCapsule
            id={props.event.id}
            groups={props.event.groups}
            firstSessionDate={firstSessionDate}
          />
        </div>

        <PLCards
          event={props.event}
          firstSessionDate={firstSessionDate}
          setAlert={props.setAlert}
        />

        <div className="row row--1200">
          <AttendeesTable
            attendees={props.event.attendees}
            timeZone={props.event.time_zone.pytz_timezone}
            groups={groups}
            maxSessionNum={maxSessionNum}
            sessionNumMap={sessionNumMap}
          />
        </div>
      </div>
    </div>
  );
};

const WrappedEvents = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => <Event {...props} setAlert={setAlert} />}
  </HeaderConsumer>
);

Event.propTypes = {
  lead_id: PropTypes.number.isRequired,
  event: PropTypes.shape({
    service: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    time_zone: PropTypes.object.isRequired,
    advanced_event: PropTypes.number,
    sessions: PropTypes.array.isRequired,
    groups: PropTypes.array,
    attendees: PropTypes.array,
    session_times: PropTypes.array,
  }).isRequired,
};

export default WrappedEvents;
