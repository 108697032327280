// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import { Router } from '@legacy-components/components/Router';
import PropTypes from 'prop-types';
import React from 'react';
import NavigationLink from './../../../NavigationLink';
import ResultCapsule from './../../common/ResultCapsule';

const Curriculum = (props) => {
  const teacher = props.mtp_page_data.User;

  const handleChangeCourse = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    Router.route(
      `${process.env.REACT_APP_TEACHING_URL}/browse/master_teacher/${teacher.id}/${e.target.value}/${teacher.seo_url}`
    );
  };

  const handleChangeUnit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    Router.route(
      `${process.env.REACT_APP_TEACHING_URL}/browse/master_teacher/${teacher.id}/${props.mtp_page_data.Course.id}/${e.target.value}/${teacher.seo_url}?from=profile_curriculum_unit`
    );
  };

  // set up a list of the teacher's courses, if they have multiple
  let courseNodes = [];
  let mobileCourseSelectNode;
  if (props.mtp_page_data.courses && props.mtp_page_data.courses.length > 1) {
    // on mobile, we show the current course, which opens the course selector on click
    let activeCourseId;
    const mobileCourseOptions = [];

    props.mtp_page_data.courses.forEach((course, i) => {
      const data = course.Course;
      const contentCount = `(${data.unit_count} Unit${
        data.unit_count === 1 ? '' : 's'
      }, ${data.lesson_count} Lesson${data.lesson_count === 1 ? '' : 's'})`;
      const courseText = (
        <React.Fragment>
          <div className="mt-profile__course-name">{data.name}</div>
          <div className="mt-profile__course-unit-count">{contentCount}</div>
        </React.Fragment>
      );

      mobileCourseOptions.push(
        <option value={data.id} key={'select-option-' + data.id}>
          {data.name} {contentCount}
        </option>
      );

      const courseClass = i === 0 ? ' mt-profile__course--no-margin' : '';

      // non-current course names link to their respective pages
      if (data.selected) {
        activeCourseId = data.id;
        courseNodes.push(
          <div
            key={`course-${data.id}`}
            className={`mt-profile__course active${courseClass}`}
          >
            {courseText}
            <div className="tc-bold-arrow mt-profile__mobile-course-arrow hide-desktop show-phone"></div>
          </div>
        );
      } else {
        courseNodes.push(
          <NavigationLink
            key={`course-${data.id}`}
            className={`v5__link v5__link--black mt-profile__course show-desktop hide-phone${courseClass}`}
            url={`${process.env.REACT_APP_TEACHING_URL}/browse/master_teacher/${teacher.id}/${data.id}/${teacher.seo_url}`}
          >
            {courseText}
          </NavigationLink>
        );
      }
    });

    mobileCourseSelectNode = (
      <select
        onChange={handleChangeCourse}
        className="mt-profile__mobile-select hide-desktop show-phone"
        value={activeCourseId}
      >
        {mobileCourseOptions}
      </select>
    );
  }
  // otherwise, just display the current course
  else if (props.mtp_page_data.Course) {
    const unitCount = props.mtp_page_data.Course.unit_count;
    const lessonCount = props.mtp_page_data.Course.lesson_count;

    courseNodes = (
      <div className="mt-profile__course mt-profile__course--no-margin">
        <div className="mt-profile__course-name">
          {props.mtp_page_data.Course.name}
        </div>
        <div className="mt-profile__course-unit-count">
          ({unitCount} Unit{unitCount === 1 ? '' : 's'}, {lessonCount} Lesson
          {lessonCount === 1 ? '' : 's'})
        </div>
      </div>
    );
  }

  // set up the current unit capsule
  let unitSelectNode, desktopUnitOptions;
  if (ArrayHelpers.hasElements(props.mtp_page_data.Units)) {
    // if there is only one unit, the current unit capsule is not clickable
    const singleUnit = props.mtp_page_data.Units.length === 1;

    // set up the unit options, including the current one
    const mobileUnitOptions = [];
    let currentUnitNode;
    desktopUnitOptions = props.mtp_page_data.Units.map((unit) => {
      const unitName = `Unit ${unit.Unit.unit_number} - ${unit.Unit.name}`;
      const lessonCount = `${unit.Unit.lesson_count} Lesson${
        unit.Unit.lesson_count === 1 ? '' : 's'
      }`;

      // if this is the current unit, show it in the capsule
      if (unit.Unit.current) {
        const optionClass = singleUnit
          ? ' mt-profile__category-select-option--unclickable'
          : '';
        const arrowNode = singleUnit ? null : (
          <div className="tc-bold-arrow mt-profile__category-select-option-arrow"></div>
        );
        currentUnitNode = (
          <div
            className={`mt-profile__category-select-option mt-profile__category-select-option--current${optionClass}`}
          >
            <div className="mt-profile__category-select-option-icon tc-folder show-desktop hide-phone"></div>
            <div className="mt-profile__category-select-option-name">
              {unitName}
              <span className="mt-profile__category-select-option-count">
                ({lessonCount})
              </span>
            </div>
            {arrowNode}
          </div>
        );
      }

      mobileUnitOptions.push(
        <option value={unit.Unit.id} key={'select-option-' + unit.Unit.id}>
          {unitName} ({lessonCount})
        </option>
      );

      return (
        <NavigationLink
          className="mt-profile__category-select-option tc__link"
          url={
            process.env.REACT_APP_TEACHING_URL +
            '/browse/master_teacher/' +
            teacher.id +
            '/' +
            props.mtp_page_data.Course.id +
            '/' +
            unit.Unit.id +
            '/' +
            teacher.seo_url +
            '?from=master_teacher_curriculum'
          }
          key={'option-' + unit.Unit.id}
        >
          <div className="mt-profile__category-select-option-icon tc-folder"></div>
          <div className="mt-profile__category-select-option-name">
            {unitName}
            <span className="mt-profile__category-select-option-count">
              ({lessonCount})
            </span>
          </div>
        </NavigationLink>
      );
    });

    // the current unit capsule is only clickable if there are options to choose from
    if (singleUnit) {
      unitSelectNode = (
        <div className="mt-profile__category-select">{currentUnitNode}</div>
      );
    } else {
      // set up the desktop version of the unit selector dropdown if it has been opened
      let desktopDropdownNode;
      if (props.ui.unitsMenuExpanded) {
        desktopDropdownNode = (
          <div className="show-desktop hide-phone mt-profile__category-select-dropdown">
            {desktopUnitOptions}
          </div>
        );
      }

      unitSelectNode = (
        <div
          className="mt-profile__category-select mt-profile__category-select--clickable"
          onClick={props.expandUnitsMenu}
        >
          {currentUnitNode}
          <select
            onChange={handleChangeUnit}
            className="mt-profile__mobile-select show-phone hide-desktop"
            value={props.activeUnitId}
          >
            {mobileUnitOptions}
          </select>

          {desktopDropdownNode}
        </div>
      );
    }
  }

  // set up the list of lesson or blended strategy capsules
  let coursesClass = '';
  let resultCapsuleNodes;
  if (props.mtp_page_data.Implementations && props.ui.tab === 'blended') {
    coursesClass = ' mt-profile__courses--less-margin';
    courseNodes = (
      <div className="mt-profile__course mt-profile__course--no-margin show-desktop hide-phone">
        <div className="mt-profile__course-name">Blended Videos</div>
        <div className="mt-profile__course-unit-count">
          ({teacher.mt_strategy_count} Videos)
        </div>
      </div>
    );
    resultCapsuleNodes = props.mtp_page_data.Implementations.map(
      (implementation) => {
        return (
          <ResultCapsule
            resultId={implementation.id}
            resultType={'strategy'}
            resultUrl={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/blended_video/' +
              implementation.id +
              '/' +
              implementation.seo_url
            }
            preview={implementation.image}
            title={implementation.title}
            summary={implementation.description}
            user={props.user}
            key={'child-blended-strategy-' + implementation.id}
          />
        );
      }
    );
  } else if (props.mtp_page_data.Unit) {
    resultCapsuleNodes = props.mtp_page_data.Unit.Lessons.map((lesson) => {
      return (
        <ResultCapsule
          fromTag={'master_teacher_curriculum'}
          favorite={{}}
          recommend={() => {}}
          addFavorite={() => {}}
          removeFavorite={() => {}}
          resultId={lesson.Lesson.id}
          resultType={'lesson'}
          resultUrl={`${process.env.REACT_APP_TEACHING_URL}/lesson/${lesson.Lesson.id}/${lesson.Lesson.seo_url}?from=master_teacher_curriculum`}
          standards={lesson.Lesson.Standards}
          preview={lesson.Lesson.image_thumb}
          title={lesson.Lesson.name}
          minutes={lesson.Lesson.minutes}
          mtp_subject={lesson.Lesson.mtp_subject}
          favoriteCount={lesson.Lesson.favorite_count}
          resourceCount={lesson.Lesson.resource_count}
          summary={lesson.Lesson.summary}
          course={props.mtp_page_data.Course.name}
          unit={props.mtp_page_data.Unit.name}
          user={props.user}
          key={'child-standard-lesson-' + lesson.Lesson.id}
        />
      );
    });
  }

  return (
    <div className="cute-9-desktop cute-12-phone mt-profile__content-col">
      <div className={`mt-profile__courses${coursesClass}`}>
        {courseNodes}
        {mobileCourseSelectNode}
      </div>
      {unitSelectNode}

      <div className="mt-profile__result-capsules">{resultCapsuleNodes}</div>
    </div>
  );
};

Curriculum.propTypes = {
  mtp_page_data: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  expandUnitsMenu: PropTypes.func.isRequired,
  activeUnitId: PropTypes.any,
};

export default Curriculum;
