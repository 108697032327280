// @ts-nocheck FIXME
const Quote = (props) => {
  // only show the dash if there is an author
  let authorNode = null;
  if (props.data.author) {
    authorNode = (
      <div className="ppp__quote-source">&mdash; {props.data.author}</div>
    );
  }

  return (
    <div className="row">
      <div className="ppp__elem ppp__elem--quote cute-10-desktop cute-1-desktop-offset left">
        <div className="ppp__quote">
          &ldquo;
          {props.data.text}
          &rdquo;
        </div>
        {authorNode}
      </div>
    </div>
  );
};

export default Quote;
