// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import Analytics from './../../../components/Analytics';

const MultiSelect = (props) => {
  let textInput;
  // We would like to wait for the user to stop typing before we fire calls to the server
  let onChangeTimeout = null;

  // Assume that we can select more options
  let canSelectMoreOptions = true;

  if (
    (props.maximumSelectedOptionsCount &&
      props.selectedOptions.length >= props.maximumSelectedOptionsCount) ||
    props.readOnly
  ) {
    canSelectMoreOptions = false;
  }

  const handleSelectClick = (e) => {
    e.stopPropagation();

    if (props.readOnly) {
      return false;
    }

    textInput.focus();
    props.updateMultiSelect({
      field: props.field,
      feature: props.feature,
      data: { inputStatus: 'focus' },
    });

    // If the container element selector is passed, attach the click handler to it
    if (props.containerElementSelector) {
      // Get the container element
      const container = document.querySelector(props.containerElementSelector);

      delayedEventListener(() =>
        container.addEventListener('click', closeOptionsDropdown)
      );
    } else {
      // Attach the click handler to the window
      delayedEventListener(() =>
        window.addEventListener('click', closeOptionsDropdown)
      );
    }
  };

  const handleSelectOption = (option, e) => {
    e.preventDefault();
    if (props.readOnly) {
      return false;
    }

    textInput.value = '';
    const data = {
      selectedOptions: props.selectedOptions.slice(),
      charCount: 0,
    };
    data.selectedOptions.push(option);

    if (props.selectAnalytics) {
      let analyticsAction = '';
      switch (props.selectAnalytics.action) {
        case 'select_grade':
        case 'select_subject':
          analyticsAction =
            'select_' +
            option.label
              .trim()
              .toLowerCase()
              .replace(/,\s|[-\s/]/g, '_');
          switch (analyticsAction) {
            case 'select_english___language_arts':
              analyticsAction = 'select_ela';
              break;
            case 'select_technology_and_engineering':
              analyticsAction = 'select_tech_engineering';
              break;
          }
          break;
      }
      Analytics.logEvent({
        category: props.selectAnalytics.category,
        action: analyticsAction,
        label: props.selectAnalytics.label,
      });
    }

    props.updateMultiSelect({
      field: props.field,
      feature: props.feature,
      data: data,
    });
  };

  const handleRemoveOption = (option, e) => {
    e.preventDefault();

    if (props.readOnly) {
      return false;
    }

    const data = {
      selectedOptions: props.selectedOptions.filter((op) => op.id != option.id),
      inputStatus: 'blur',
    };

    const input = textInput.value.toLowerCase();
    if (input.length >= props.minCharCountForResults && canSelectMoreOptions) {
      props.getVisibleOptions({
        field: props.field,
        feature: props.feature,
        input: input,
      });
    }

    if (props.removeAnalytics) {
      let analyticsAction = '';
      switch (props.removeAnalytics.action) {
        case 'deselect_grade':
        case 'deselect_subject':
          analyticsAction =
            'deselect_' +
            option.label
              .trim()
              .toLowerCase()
              .replace(/,\s|[-\s/]/g, '_');
          switch (analyticsAction) {
            case 'deselect_english___language_arts':
              analyticsAction = 'deselect_ela';
              break;
            case 'deselect_technology_and_engineering':
              analyticsAction = 'deselect_tech_engineering';
              break;
          }
          break;
      }
      Analytics.logEvent({
        category: props.removeAnalytics.category,
        action: analyticsAction,
        label: props.removeAnalytics.label,
      });
    }

    props.updateMultiSelect({
      field: props.field,
      feature: props.feature,
      data: data,
    });
  };

  const handleFocus = (e) => {
    e.preventDefault();

    if (props.readOnly) {
      return false;
    }

    const input = textInput.value.toLowerCase();
    const visibleOptions = [];

    if (input.length >= props.minCharCountForResults && canSelectMoreOptions) {
      props.getVisibleOptions({
        field: props.field,
        feature: props.feature,
        input: input,
      });
    }

    props.updateMultiSelect({
      field: props.field,
      feature: props.feature,
      data: { inputStatus: 'focus' },
    });

    // If the parent provided a focus handler, call it
    if (props.handleFocus && typeof props.handleFocus === 'function') {
      props.handleFocus(e);
    }
  };

  const closeOptionsDropdown = () => {
    if (props.readOnly) {
      return false;
    }

    // If the container element selector is available, remove the click handler from the container
    if (props.containerElementSelector) {
      const container = document.querySelector(props.containerElementSelector);
      container.removeEventListener('click', closeOptionsDropdown);
    } else {
      // Remove the click handler from the window
      window.removeEventListener('click', closeOptionsDropdown);
    }

    props.updateMultiSelect({
      field: props.field,
      feature: props.feature,
      data: { inputStatus: 'blur' },
    });
  };

  const handleTyping = (e) => {
    if (!canSelectMoreOptions) {
      return;
    }

    // Parent component may request to delay firing the event (e.g. 500 ms)
    // to give the user some room to finish typing
    const onChangeDelay = props.onChangeDelay || 0;

    clearTimeout(onChangeTimeout);

    onChangeTimeout = setTimeout(function () {
      const input = textInput.value.toLowerCase();
      if (input.length < props.minCharCountForResults) {
        const data = {
          visibleOptions: [],
          charCount: input.length,
        };

        props.updateMultiSelect({
          field: props.field,
          feature: props.feature,
          data: data,
        });
        return;
      }

      props.getVisibleOptions({
        field: props.field,
        feature: props.feature,
        input: input,
      });
    }, onChangeDelay);
  };

  const availableNodes = props.visibleOptions
    .filter((option) => {
      // If the user has selected the maximum number of options, nothing is available
      if (!canSelectMoreOptions) {
        return false;
      }
      // when the input is blurred... nothing is available
      if (
        props.inputStatus == 'blur' ||
        props.charCount < props.minCharCountForResults
      ) {
        return false;
      }
      // if the value is selected, it is not available
      if (props.selectedOptions.find((op) => op.id == option.id)) {
        return false;
      }
      return true;
    })
    .map((option) => {
      return (
        <div
          className="custom-select__option custom-select__option--variable-height"
          onClick={(e) => handleSelectOption(option, e)}
          key={props.field + option.id}
        >
          <div>{option.label}</div>
          <div className="custom-select__option-description">
            {option.description}
          </div>
        </div>
      );
    });

  // only show the options menu if there are visible options
  let optionsNode = null;
  if (availableNodes.length) {
    optionsNode = (
      <div className="custom-select__options">{availableNodes}</div>
    );
  }

  const selectedNodes = props.selectedOptions.map((option) => {
    return (
      <div className="custom-select__selected" key={'sel-' + option.id}>
        <div className="custom-select__selected-name">{option.label}</div>
        <div
          onClick={(e) => handleRemoveOption(option, e)}
          className="custom-select__selected-remove tc-x"
        ></div>
      </div>
    );
  });

  let inputPlaceholder = props.placeholder;
  let inputEmptyClass = ' tc__input--custom-select-empty';
  if (props.selectedOptions.length) {
    inputPlaceholder = '';
    inputEmptyClass = '';
  }

  return (
    <div className="custom-select__container">
      <div className="custom-select tc__input" onClick={handleSelectClick}>
        <div className="custom-select__selected-options">
          {selectedNodes}
          <input
            type="text"
            ref={(ref) => (textInput = ref)}
            className={'tc__input tc__input--custom-select' + inputEmptyClass}
            placeholder={inputPlaceholder}
            onChange={handleTyping}
            onFocus={handleFocus}
          />
        </div>
      </div>
      {optionsNode}
    </div>
  );
};

MultiSelect.propTypes = {
  field: PropTypes.string.isRequired,
  feature: PropTypes.string,
  placeholder: PropTypes.string,
  inputStatus: PropTypes.string.isRequired,
  minCharCountForResults: PropTypes.number,
  charCount: PropTypes.number,
  selectedOptions: PropTypes.array,
  visibleOptions: PropTypes.array,
  updateMultiSelect: PropTypes.func,
  getVisibleOptions: PropTypes.func,
  // How many options can I select?
  maximumSelectedOptionsCount: PropTypes.number,
  // The container element selector will be used to attach the click handlers to instead of the window
  containerElementSelector: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
};

MultiSelect.defaultProps = {
  minCharCountForResults: 2,
  maximumSelectedOptionsCount: null,
  readOnly: false,
};

export default MultiSelect;
