// @ts-nocheck FIXME

const ArtifactPagination = (props) => {
  // dont show the next and last buttons if there are no more items to show
  const nextClass =
    props.activePage + 1 == props.contentLength ? ' tc__no-op' : '';
  const lastClass = props.activePage == 0 ? ' tc__no-op' : '';

  return (
    <div className="artifact__page-controls tc__body-copy">
      <div
        className={
          'artifact__page-control artifact__page-control--left' + lastClass
        }
        onClick={() => props.handlePaginationClick(props.activePage - 1)}
      >
        <div className="tc-arrow"></div>
      </div>
      <div className="artifact__page-control--current artifact__page-control page-control__current">
        {props.activePage + 1} of {props.contentLength}
      </div>
      <div
        className={
          'artifact__page-control artifact__page-control--right' + nextClass
        }
        onClick={() => props.handlePaginationClick(props.activePage + 1)}
      >
        <div className="tc-arrow"></div>
      </div>
    </div>
  );
};

export default ArtifactPagination;
