// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import AbridgeText from './../../../Common/AbridgeText';

const StrategyCapsule = (props) => {
  // the last capsule in the row has different styles
  const capsuleClass = props.isLast
    ? ' bl4-profile__strategy-capsule--last'
    : '';

  // strategy icon depends on strategy type
  const iconClass =
    props.strategy_type == 'tech_tool' ? 'tc-v4-laptop' : 'tc-v4-strategy';

  // if the current user can view the strategy page, link to it
  if (props.is_viewable) {
    // set up the url for the strategy details page
    let strategyUrl =
      process.env.REACT_APP_TEACHING_URL + '/strategy/' + props.strategy_id;
    if (props.seo_url) {
      strategyUrl += '/' + props.seo_url;
    }
    strategyUrl += '?from=profile';

    return (
      <a
        href={strategyUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={
          'bl4__capsule bl4-capsule--hoverable bl4-profile__strategy-capsule' +
          capsuleClass
        }
      >
        <AbridgeText maxHeight={218}>
          <div className="bl4-profile__strategy-capsule-icon-name-row">
            <div
              className={'bl4-profile__strategy-capsule-icon ' + iconClass}
            ></div>
            <div className="tc__link tc__link--black bl4-profile__strategy-capsule-name">
              {props.title}
            </div>
          </div>
          <div className="bl4-profile__strategy-capsule-tagline">
            {props.tagline}
          </div>
        </AbridgeText>
      </a>
    );
  }

  return (
    <div
      className={'bl4__capsule bl4-profile__strategy-capsule' + capsuleClass}
    >
      <AbridgeText maxHeight={218}>
        <div className="bl4-profile__strategy-capsule-icon-name-row">
          <div
            className={'bl4-profile__strategy-capsule-icon ' + iconClass}
          ></div>
          <div className="bl4-profile__strategy-capsule-name">
            {props.title}
          </div>
        </div>
        <div className="bl4-profile__strategy-capsule-tagline">
          {props.tagline}
        </div>
      </AbridgeText>
    </div>
  );
};

StrategyCapsule.propTypes = {
  id: PropTypes.number.isRequired,
  strategy_id: PropTypes.number,
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  is_viewable: PropTypes.bool,
  isLast: PropTypes.bool,
  fromTag: PropTypes.string,
};

export default StrategyCapsule;
