// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../../../components/DynamicUtils';
import ScaleCenterImage from './../../../../ScaleCenterImage';
import AbridgeText from './../../../Common/AbridgeText';

const EvidenceSlide = (props) => {
  // open the artifact overlay when clicking on the evidence img or title
  const openArtifactModal = () => {
    const data = Utils.clone(props);
    data.storyId = props.storyId;
    data.entryType = 'evidence';
    data.analyticsCategory = 'evidence_modal';

    props.portfolioArtifactModal.open(data);
  };

  // set up text for any growth area the entry is tagged with
  const growthAreaNode = props.growthAreas ? (
    <div className="bl4-profile__story-highlights-growth-area">
      {props.growthAreas[Object.keys(props.growthAreas)[0]].title}
    </div>
  ) : null;

  // set up preview img
  let imgNode = (
    <div
      className="bl4-profile__story-highlights-reflection-img-mask"
      onClick={openArtifactModal}
    ></div>
  );
  if (props.connections && props.connections.preview) {
    imgNode = (
      <div
        className="bl4-profile__story-highlights-reflection-img-container"
        onClick={openArtifactModal}
      >
        <ScaleCenterImage
          src={props.connections.preview}
          className="bl4-profile__story-highlights-reflection-img"
        />
      </div>
    );
  }

  return (
    <div
      key={'evidence-' + props.id}
      className="bl4-profile__story-highlights-reflection"
    >
      {growthAreaNode}
      <div className="bl4-profile__story-highlights-reflection-body-with-img">
        {imgNode}
        <AbridgeText maxHeight={150}>
          <div
            className="tc__link tc__link--black bl4-profile__story-highlights-section-headline"
            onClick={openArtifactModal}
          >
            {props.title}
          </div>
          <div className="tc__body-copy">{props.description}</div>
        </AbridgeText>
      </div>
    </div>
  );
};

EvidenceSlide.propTypes = {
  id: PropTypes.number.isRequired,
  growthAreas: PropTypes.object,
  connections: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  storyId: PropTypes.number.isRequired,
  portfolioArtifactModal: PropTypes.object.isRequired,
};

export default EvidenceSlide;
