// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import ArtifactImage from '@legacy-components/react/V4/Common/ArtifactImage';
import AbridgeText from '@legacy-components/react/V5/Common/AbridgeText';
import PropTypes from 'prop-types';
import React from 'react';
import NavigationLink from './../../../../NavigationLink';

const StrategyEntry = (props) => {
  const inputEL = React.useRef(null);

  React.useEffect(() => {
    if (props.smallView) {
      const elem = inputEL.current;
      const elemHeight = elem.getBoundingClientRect().height;

      if (elemHeight > 220) {
        elem.style.height = '220px';
        elem.style.overflowY = 'hidden';
        props.setTruncateState(true);
      }
    }
  }, []);

  let imageNode;
  if (props.lessonfile && props.lessonfile.connections) {
    imageNode = (
      <ArtifactImage
        preview={props.lessonfile.connections.preview}
        title={props.lessonfile.connections.name}
        containerClass={'workspace__entry-image-container'}
        icon={props.lessonfile.connections.icon}
        imageClass="workspace__entry-image"
      />
    );
    if (props.is_viewable) {
      imageNode = (
        <NavigationLink
          url={`${process.env.REACT_APP_TEACHING_URL}/strategy/${props.strategy_id}?from=workspace`}
          className="v5__link"
          target="_blank"
        >
          {imageNode}
        </NavigationLink>
      );
    }
  }

  // only link to the strategy page if the current user is allowed to view it
  let titleNode = props.title;
  if (props.is_viewable) {
    titleNode = (
      <NavigationLink
        url={`${process.env.REACT_APP_TEACHING_URL}/strategy/${props.strategy_id}?from=workspace`}
        className="v5__link"
        target="_blank"
      >
        {props.title}
      </NavigationLink>
    );
  }

  let toggle = true;
  if (props.toggle === false) {
    toggle = false;
  }

  let bodyNode;
  if (props.note && props.smallView) {
    bodyNode = null;
    props.setTruncateState(true);
  } else if (props.note && toggle) {
    bodyNode = (
      <AbridgeText
        maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
        isExpandable={true}
        greyFade={props.greyFade}
      >
        {props.note}
      </AbridgeText>
    );
  } else {
    bodyNode = props.note;
  }

  return (
    <div className="workspace__entry-body">
      <div className="workspace__entry-image-row">
        {imageNode}
        <div className="workspace__entry-image-description" ref={inputEL}>
          <div className="v5__heading-5 workspace__entry-row">{titleNode}</div>
          <div className="workspace__entry-row v5__body-copy">
            {props.tagline}
          </div>
        </div>
      </div>
      <div className="workspace__entry-row v5__body-copy">{bodyNode}</div>
    </div>
  );
};

StrategyEntry.propTypes = {
  setTruncateState: PropTypes.func,
  toggle: PropTypes.bool,
  greyFade: PropTypes.bool,
};

export default StrategyEntry;
