// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import AbridgeText from './../../../Common/AbridgeText';

const VisionEntry = (props) => {
  return (
    <div className="workspace__entry-body">
      <div className="workspace__entry-row">
        <AbridgeText
          maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
          isExpandable={true}
        >
          <PurifiedHTML
            className="ckeditor__output v5__body-copy"
            content={props.description}
          />
        </AbridgeText>
      </div>
    </div>
  );
};

export default VisionEntry;
