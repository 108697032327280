// @ts-nocheck FIXME
import React from 'react';
import ArtifactCommentV2 from './ArtifactComment';

class ArtifactV2 extends React.Component {
  getPreviewNode = () => {
    let fileNameNode;

    if (this.props.filename) {
      fileNameNode = (
        <div className="portfolio-v2__artifact-filename">
          {this.props.filename}
        </div>
      );
    }

    let previewImageNode;
    if (this.props.preview_type == 'movie') {
      // For videos, display the generic preview image and the file name
      previewImageNode = (
        <div className="portfolio-v2__artifact-movie-data">
          <div className="portfolio-v2__artifact-movie-icon tc-file-video"></div>
          {fileNameNode}
          <div className="portfolio-v2__artifact-movie-url">
            {this.props.publicUrl}
          </div>
        </div>
      );
    } else if (this.props.content.preview && this.props.filename) {
      // For artifacts that have a preview image, show the preview
      previewImageNode = this.props.content.preview.map(
        function (preview, index) {
          // Only display up to 25 preview pages for every artifact:
          if (index <= 24) {
            const artifactKey = this.props.filename.replace(/[. ,:-]+/g, '-');

            return (
              <div
                className="portfolio-v2__artifact-image"
                key={artifactKey + '-' + index}
              >
                <img src={preview} alt={this.props.filename} />
              </div>
            );
          }
        }.bind(this)
      );
    } else {
      // For artifacts that have no preview, show the generic icon and the filename
      previewImageNode = (
        <div>
          <div className="portfolio-v2__artifact-image">
            <img src={this.props.preview} alt={this.props.filename} />
          </div>
          {fileNameNode}
          <div className="clearfix"></div>
        </div>
      );
    }

    return previewImageNode;
  };

  render() {
    const previewImageNode = this.getPreviewNode();
    let linkNode;
    if (this.props.url) {
      linkNode = (
        <div className="portfolio-v2__artifact-url">
          <div className="artifact-overlay__file-icon file-type-icon ext-link"></div>
          Website Address
          <a
            href={this.props.url}
            target="_new"
            className="portfolio-v2__artifact-notes"
          >
            {this.props.url}
          </a>
        </div>
      );
    }

    let artifactTypeNode = null;
    if (this.props.type_name) {
      artifactTypeNode = (
        <div className="artifact__type artifact__type--print">
          <div className="artifact__type-caption">{this.props.type_name}</div>
          <div className="artifact__flag-end artifact__flag-end--print tc-flag-end"></div>
        </div>
      );
    }

    let authorNotesNode = null;
    if (this.props.notes) {
      authorNotesNode = (
        <div className="portfolio-v2__artifact-notes">
          <span className="portfolio-v2__artifact-notes-label">
            Author&rsquo;s Notes:
          </span>{' '}
          <span>{this.props.notes}</span>
        </div>
      );
    }

    const commentsNode = this.props.comments.map(function (comment) {
      return <ArtifactCommentV2 key={'comment' + comment.id} {...comment} />;
    });

    return (
      <div className="portfolio-v2__artifact">
        {artifactTypeNode}
        {authorNotesNode}
        {linkNode}
        {commentsNode}
        {previewImageNode}
      </div>
    );
  }
}

export default ArtifactV2;
