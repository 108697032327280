// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import MultiSelectView from './MultiSelectView';

const MultiSelectContainer = (props) => {
  const [textInput, updateTextInput] = useState('');
  const [hasFocus, updateFocus] = useState(false);
  const [hasRendered, updateHasRendered] = useState(props.openWithOptions);

  useEffect(() => {
    updateHasRendered(true);
    // add event listner to close on mount

    delayedEventListener(() =>
      document.addEventListener('click', props.closeOptions, false)
    );

    return () => {
      // remove event listner to close on unmount
      document.removeEventListener('click', props.closeOptions, false);
    };
  }, []);

  // Assume that we can select more options
  let canSelectMoreOptions = true;
  if (
    (props.maximumSelectedOptionsCount &&
      props.selectedOptions.length >= props.maximumSelectedOptionsCount) ||
    props.readOnly ||
    props.disabled
  ) {
    canSelectMoreOptions = false;
  }

  // Determine if more options can be selected and if the multiselect has search behavior
  // If it has search behavior it will remove input classes on selection of all possible options
  const canTypeText = !(!canSelectMoreOptions && props.isSelectSearch);

  const handleTyping = (text) => {
    updateTextInput(text);
    updateFocus(true);
  };

  const handleSelectClick = (e) => {
    if (props.stayOpenOnSelect) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    if (props.readOnly || props.disabled) {
      return false;
    }

    updateFocus(true);
  };

  const handleSelectOption = (option, e) => {
    e.preventDefault();

    if (props.stayOpenOnSelect) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    if (props.readOnly || props.disabled) {
      return false;
    }

    updateTextInput('');

    props.handleSelectOption(option);
  };

  const handleRemoveOption = (option, e) => {
    e.preventDefault();

    if (props.stayOpenOnSelect) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    if (props.readOnly || props.disabled) {
      return false;
    }

    props.handleSelectOption(option);
  };

  const handleFocus = (e) => {
    e.preventDefault();
    updateFocus(hasRendered);

    if (props.readOnly || props.disabled) {
      return false;
    }

    // If the parent provided a focus handler, call it
    if (props.handleFocus && typeof props.handleFocus === 'function') {
      props.handleFocus(e);
    }
  };

  const handleBlur = (e) => {
    updateFocus(false);

    if (props.readOnly || props.disabled) {
      return false;
    }

    // If the parent provided a blur handler, call it
    if (props.handleBlur && typeof props.handleBlur === 'function') {
      props.handleBlur(e);
    }
  };

  return (
    <MultiSelectView
      {...props}
      canSelectMoreOptions={canSelectMoreOptions}
      handleSelectClick={handleSelectClick}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
      hasFocus={hasFocus}
      textInput={textInput}
      updateTextInput={handleTyping}
      handleSelectOption={handleSelectOption}
      handleRemoveOption={handleRemoveOption}
      autoFocus={props.preventAutoFocus ? false : true}
      canTypeText={canTypeText}
      absolutePosition={props.absolutePosition}
    />
  );
};

MultiSelectContainer.propTypes = {
  uId: PropTypes.string,
  placeholder: PropTypes.string,
  minCharCountForResults: PropTypes.number,
  selectedOptions: PropTypes.array,
  dropdownOptions: PropTypes.array,
  closeOptions: PropTypes.func,
  // if it's readOnly/disabled and there are no selected options, display this empty state node
  emptyStateNode: PropTypes.node,
  stayOpenOnSelect: PropTypes.bool,
  // if true, we show shortLabel for selected options if there is more than one
  shortenLabels: PropTypes.bool,
  showDescription: PropTypes.bool,
  // How many options can I select?
  maximumSelectedOptionsCount: PropTypes.number,
  // disabled is the same as readOnly, but it also greys out the selected option tags and hides the X's
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  openWithOptions: PropTypes.bool,
  isSelectSearch: PropTypes.bool,
  error: PropTypes.string,
};

MultiSelectContainer.defaultProps = {
  minCharCountForResults: 2,
  showDescription: true,
  maximumSelectedOptionsCount: null,
  readOnly: false,
  disabled: false,
  stayOpenOnSelect: true,
  openWithOptions: true,
  isSelectSearch: false,
};

export default MultiSelectContainer;
