// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';
import AbsoluteAlert from './../../../Alerts/Absolute';
import Toggle from './../../../V4/Common/Toggle';
import ConfirmSendEmailModal from './../../Common/ConfirmModal';
import ConfirmDeleteModal from './../Common/ConfirmDeleteModal';
import CohortRow from './CohortRow';
import CreateCohort from './CreateCohort';

const Cohorts = (props) => {
  const lab = useLegacyLabContext();
  const [searchField, setSearchField] = React.useState('');

  const searchForMatch = (cohort) => {
    if (
      cohort.name &&
      cohort.name.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
    ) {
      return true;
    } else if (
      cohort.partner.name &&
      cohort.partner.name.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
    ) {
      return true;
    } else if (
      cohort.product_id &&
      props.products[cohort.product_id]
        .toLowerCase()
        .indexOf(searchField.toLowerCase()) >= 0
    ) {
      return true;
    }
    return false;
  };

  // Data viewers are the only people who can view this page but can't edit
  const canEdit = Utils.isManager.call(lab) || Utils.isAdmin.call(lab);

  let editColumn;

  let cohorts = [...props.cohorts];

  if (searchField) {
    cohorts = cohorts.filter(searchForMatch);
  }

  let nameSortIndicator,
    partnerNameSortIndicator,
    productSortIndicator,
    startSortIndicator,
    endSortIndicator,
    terminationSortIndicator,
    nameSelectedClass = '',
    partnerNameSelectedClass = '',
    productSelectedClass = '',
    startSelectedClass = '',
    endSelectedClass = '',
    terminationSelectedClass = '';

  const handleArchivedCheckboxToggle = (e) => {
    e.preventDefault();
    props.toggleCohortsArchivedCheckbox();
  };

  if (canEdit) {
    editColumn = (
      <div className="cohort-dash__cohort-header grid-big__col-5"></div>
    );
  }

  const arrowClass = props.sortDirection === 'ascending' ? ' up' : '';
  const arrowNode = (
    <div
      className={`tc-bold-arrow admin-users__sort-order-arrow${arrowClass}`}
    ></div>
  );

  // Column titles should only have clickable styling when there are multiple cohorts
  let linkClass = '';
  if (cohorts.length > 1) {
    linkClass = ' tc__link tc__link--black';
  }

  // Arrow should only show up on column that is currently sorted by
  switch (props.sortColumn) {
    case 'name':
      nameSortIndicator = arrowNode;
      nameSelectedClass = ' sorted';
      break;
    case 'partnerName':
      partnerNameSortIndicator = arrowNode;
      partnerNameSelectedClass = ' sorted';
      break;
    case 'product':
      productSortIndicator = arrowNode;
      productSelectedClass = ' sorted';
      break;
    case 'start':
      startSortIndicator = arrowNode;
      startSelectedClass = ' sorted';
      break;
    case 'end':
      endSortIndicator = arrowNode;
      endSelectedClass = ' sorted';
      break;
    case 'termination':
      terminationSortIndicator = arrowNode;
      terminationSelectedClass = ' sorted';
      break;
  }

  const handleSortName = () => {
    props.sortBy({ column: 'name' });
  };

  const handleSortPartnerName = () => {
    props.sortBy({ column: 'partnerName' });
  };

  const handleSortProduct = () => {
    props.sortBy({ column: 'product' });
  };

  const handleSortStart = () => {
    props.sortBy({ column: 'start' });
  };

  const handleSortEnd = () => {
    props.sortBy({ column: 'end' });
  };

  const handleSortTermination = () => {
    props.sortBy({ column: 'termination' });
  };

  // set up any alert banner
  let alertNode = null;
  if (Object.keys(props.ui.alert).length) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }
  // set up the Create/Delete Cohort Modal if necessary
  let modalNode = null;
  if (props.ui.createCohortModal.opened) {
    modalNode = (
      <CreateCohort
        ui={props.ui.createCohortModal}
        displayDescription={props.ui.displayDescription}
        products={props.products}
        toggleModal={props.toggleModal}
        updateSelect={props.updateSelect}
        updatePriorityDomainsSelect={props.updatePriorityDomainsSelect}
        getVisibleOptions={props.getVisibleOptions}
        setStartDate={props.setStartDate}
        setEndDate={props.setEndDate}
        setTerminationDate={props.setTerminationDate}
        addCohort={props.addCohort}
        clearErrorsOnFocus={props.clearErrorsOnFocus}
      />
    );
  } else if (props.ui.confirmSendEmailModal.opened) {
    const cohort = props.ui.confirmSendEmailModal.object;
    const plural = cohort.participant_count > 1 ? 's' : '';
    modalNode = (
      <ConfirmSendEmailModal
        {...props.ui.confirmSendEmailModal}
        bodyText={
          'Send Welcome Email to ' +
          cohort.participant_count +
          ' ' +
          cohort.name +
          ' participant' +
          plural +
          '?'
        }
        submitBtnText="Send Email"
        closeConfirmModal={props.closeConfirmSendEmailModal}
        handleSubmit={props.sendWelcomeEmailsToCohort}
      />
    );
  } else if (props.ui.confirmDeleteModal.object) {
    modalNode = (
      <ConfirmDeleteModal
        {...props.ui.confirmDeleteModal}
        closeConfirmDeleteModal={props.closeConfirmDeleteModal}
        onDelete={props.deleteCohort}
      />
    );
  }

  return (
    <div className="tc__page">
      <Header page="Cohorts" {...props} />
      {alertNode}
      {modalNode}

      <div className="row row--1200 cohort-dash__heading">
        <div className="v5__heading-3">Cohorts</div>
      </div>
      <div className="row row--1200">
        <div className="cute-9-desktop cohort-dash__headline cohort-dash__toggle-row">
          <div className="admin-users__search-term">
            <FormField
              type="text"
              className="cohort-dash__search"
              maxLength={100}
              placeholder="Search by name, partner, or product."
              defaultValue={props.searchTerm}
              onChange={(e) => setSearchField(e.target.value)}
            />
            <div className="tc-search cohort-dash__search-icon"></div>
          </div>
        </div>
        <div className="cute-3-desktop cohort-dash__headline-buttons cohort-dash__toggle-row">
          <Toggle
            checked={props.showingActiveCohorts}
            toggleCallback={handleArchivedCheckboxToggle}
            checkedLabel="Show Active"
            uncheckedLabel="Show Archived"
          />
        </div>
      </div>

      <div className="row row--1200">
        <div className="grid-big__row admin-v5__coach-table__header">
          <div
            className={
              'cohort-dash__cohort-header grid-big__col-7' +
              nameSelectedClass +
              linkClass
            }
            onClick={handleSortName}
          >
            Name {nameSortIndicator}
          </div>
          <div
            className={
              'cohort-dash__cohort-header grid-big__col-10' +
              partnerNameSelectedClass +
              linkClass
            }
            onClick={handleSortPartnerName}
          >
            Partner {partnerNameSortIndicator}
          </div>
          <div
            className={
              'cohort-dash__cohort-header grid-big__col-4 cohort-dash__product-cell' +
              productSelectedClass +
              linkClass
            }
            onClick={handleSortProduct}
          >
            Product {productSortIndicator}
          </div>
          <div
            className={
              'cohort-dash__cohort-header grid-big__col-4' +
              terminationSelectedClass +
              linkClass
            }
            onClick={handleSortTermination}
          >
            Termination {terminationSortIndicator}
          </div>
          <div
            className={
              'cohort-dash__cohort-header grid-big__col-4' +
              startSelectedClass +
              linkClass
            }
            onClick={handleSortStart}
          >
            Start {startSortIndicator}
          </div>
          <div
            className={
              'cohort-dash__cohort-header grid-big__col-4' +
              endSelectedClass +
              linkClass
            }
            onClick={handleSortEnd}
          >
            End {endSortIndicator}
          </div>
          <div className="cohort-dash__cohort-header grid-big__col-7">
            Focus Areas
          </div>
          <div className="cohort-dash__cohort-header grid-big__col-5">
            # Members
          </div>
          {editColumn}
        </div>
        {cohorts.map((cohort) => {
          return (
            <CohortRow
              key={cohort.id}
              cohort={cohort}
              products={props.products}
              openConfirmSendEmailModal={props.openConfirmSendEmailModal}
              openConfirmDeleteModal={props.openConfirmDeleteModal}
              canEdit={canEdit}
            />
          );
        })}
      </div>
    </div>
  );
};

Cohorts.propTypes = {
  ui: PropTypes.object.isRequired,
  cohorts: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addCohort: PropTypes.func.isRequired,
  showingActiveCohorts: PropTypes.bool.isRequired,
  toggleCohortsArchivedCheckbox: PropTypes.func.isRequired,
};

export default Cohorts;
