// @ts-nocheck FIXME
const Artifact = (props) => {
  let fileNameNode;
  if (props.filename) {
    fileNameNode = (
      <div className="portfolio-v2__artifact-filename">{props.filename}</div>
    );
  }

  let previewImageNode = null;
  if (props.preview_type == 'movie') {
    // For videos, display the generic preview image and the file name
    previewImageNode = (
      <div className="portfolio-v2__artifact-movie-data">
        <div className="portfolio-v2__artifact-movie-icon tc-file-video"></div>
        {fileNameNode}
        <div className="portfolio-v2__artifact-movie-url">
          {props.publicUrl}
        </div>
      </div>
    );
  } else if (props.content.preview && props.filename) {
    // For artifacts that have a preview image, show the preview
    previewImageNode = props.content.preview.map((preview, i) => {
      // Only display up to 25 preview pages for every artifact:
      if (i <= 24) {
        const artifactKey = props.filename.replace(/[. ,:-]+/g, '-');

        return (
          <div
            className="portfolio-v2__artifact-image"
            key={artifactKey + '-' + i}
          >
            <img src={preview} alt={props.filename} />
          </div>
        );
      }
    });
  } else {
    // For artifacts that have no preview, show the generic icon and the filename
    previewImageNode = (
      <div>
        <div className="portfolio-v2__artifact-image">
          <img src={props.preview} alt={props.filename} />
        </div>
        {fileNameNode}
        <div className="clearfix"></div>
      </div>
    );
  }

  let linkNode;
  if (props.url) {
    linkNode = (
      <div className="portfolio-v2__artifact-url">
        <div className="artifact-overlay__file-icon file-type-icon ext-link"></div>
        Website Address
        <a
          href={props.url}
          target="_new"
          className="portfolio-v2__artifact-notes"
        >
          {props.url}
        </a>
      </div>
    );
  }

  let artifactTypeNode = null;
  if (props.type_name) {
    artifactTypeNode = (
      <div className="artifact__type artifact__type--print">
        <div className="artifact__type-caption">{props.type_name}</div>
        <div className="artifact__flag-end artifact__flag-end--print tc-flag-end"></div>
      </div>
    );
  }

  let authorNotesNode = null;
  if (props.notes) {
    authorNotesNode = (
      <div className="portfolio-v2__artifact-notes">
        <span className="portfolio-v2__artifact-notes-label">
          Author&rsquo;s Notes:
        </span>{' '}
        <span>{props.notes}</span>
      </div>
    );
  }

  let commentsNode = null;
  if (props.comments) {
    commentsNode = props.comments.map((comment) => {
      const authorRole = props.is_coach ? 'BetterLesson Coach' : 'Teacher';
      return (
        <div
          className="portfolio-v2__artifact-comment"
          key={'aritfact-comment-' + comment.id}
        >
          <div className="portfolio-v2__artifact-comment-text">
            {comment.data}
          </div>
          <div className="portfolio-v2__artifact-comment-author">
            &mdash; {comment.first_name}&nbsp;{comment.last_name}, {authorRole}
          </div>
        </div>
      );
    });
  }

  return (
    <div className="portfolio-v2__artifact">
      {artifactTypeNode}
      {previewImageNode}
      {authorNotesNode}
      {linkNode}
      {commentsNode}
    </div>
  );
};

export default Artifact;
