// @ts-nocheck FIXME
import React from 'react';

class Badge extends React.Component {
  state = {
    tooltipVisible: false,
  };

  handleBadgeHover = () => {
    this.setState({
      tooltipVisible: !this.state.tooltipVisible,
    });
  };

  render() {
    // show badge details on hover
    const visibleClass = this.state.tooltipVisible ? ' visible' : '';

    // show different color badge and points text depending on whether badge is achieved
    let activeImg = '-inactive';
    let pointsClass = '';
    if (this.props.badge.achieved) {
      activeImg = '-active';
      pointsClass = 'badges__points-text--' + this.props.category.code;
    }

    const points = this.props.badge.points == 1 ? 'POINT' : 'POINTS';

    return (
      <div
        className="cute-3-desktop"
        onMouseOver={this.handleBadgeHover}
        onMouseOut={this.handleBadgeHover}
      >
        <div id={this.props.badge.code} className="badges__badge-icon">
          <img
            src={
              '/legacy-images/badges/badge-' +
              this.props.category.code +
              activeImg +
              '.svg'
            }
          />
        </div>
        <div className={'badges__badge-tooltip' + visibleClass}>
          {this.props.badge.description}
        </div>
        <div className="badges__text">{this.props.badge.name}</div>
        <div className={'badges__points-text ' + pointsClass}>
          {this.props.badge.points} {points}
        </div>
      </div>
    );
  }
}

export default Badge;
