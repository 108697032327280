// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Filter from './Filter';
import ResourceCapsule from './ResourceCapsule';

const Resources = (props) => {
  const [activeFilter, setActiveFilter] = useState();

  // set up the workspace resources
  let filtersNode;
  let resourcesNode = (
    <div className="row row--1200 highlights__empty-tab-row">
      <div className="cute-4-desktop cute-1-desktop-offset cute-12-phone cute-0-phone-offset">
        <img
          src="/legacy-images/v5/BL-Lab-NoActivity.png"
          className="highlights__outcomes-img"
        />
      </div>
      <div className="cute-7-desktop cute-12-phone">
        <div className="highlights__outcomes-text highlights__empty-copy">
          Resources have not been shared on the workspace yet.
        </div>
      </div>
    </div>
  );
  if (ArrayHelpers.hasElements(props.resources)) {
    // we only show the resource type filters if we have a mix of the two
    const strategyCount = props.resources.filter(
      (resource) => resource.type === 'curated_strategy'
    ).length;
    const lessonCount = props.resources.filter(
      (resource) => resource.type === 'lesson'
    ).length;
    if (strategyCount && lessonCount) {
      filtersNode = (
        <div className="row row--1200">
          <div className="cute-4-desktop center-desktop cute-12-phone">
            <div className="highlights__resource-filters">
              <Filter
                name="All Resources"
                count={props.resources.length}
                hideCount={true}
                active={!activeFilter}
                selectFilter={() => setActiveFilter(null)}
              />
              <Filter
                name="Strategies"
                count={strategyCount}
                active={activeFilter === 'curated_strategy'}
                selectFilter={() => setActiveFilter('curated_strategy')}
              />
              <Filter
                name="Lessons"
                count={lessonCount}
                active={activeFilter === 'lesson'}
                selectFilter={() => setActiveFilter('lesson')}
              />
            </div>
          </div>
        </div>
      );
    }

    // filter by type if necessary
    let visibleResources = props.resources;
    if (activeFilter) {
      visibleResources = props.resources.filter(
        (resource) => resource.type === activeFilter
      );
    }
    resourcesNode = (
      <div className="cute-11-desktop center-desktop cute-12-phone">
        <div className="highlights__resources">
          {visibleResources.map((entry) => {
            return <ResourceCapsule key={entry.id} {...entry} />;
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      {filtersNode}
      <div className="row row--1200">
        <div className="highlights__count-container">
          <div className="highlights__resources-header-icon-container">
            <span className="tc-v4-strategy highlights__resources-header-icon"></span>
          </div>
          <span className="highlights__count-number">
            {props.resourceCount}
          </span>
          <span className="highlights__count-text">Resources</span>
        </div>
        {resourcesNode}
      </div>
    </>
  );
};

Resources.propTypes = {
  resources: PropTypes.array,
};

export default Resources;
