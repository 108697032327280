// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const MatchAvailability = (props) => {
  const morningOptions = [
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
  ];
  const afternoonOptions = ['12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM'];
  const eveningOptions = [
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM',
    '12 AM',
    '1 AM',
    '2 AM',
  ];

  const morningTimes = [];
  const afternoonTimes = [];
  const eveningTimes = [];

  let weekendAvailableNode = <div>No</div>;
  // sort availabilities in ascending order by id so the time stays consistent
  const availabilities = props.availabilities
    ? Utils.clone(props.availabilities)
    : [];
  availabilities.sort((a, b) => a.id - b.id);
  availabilities.forEach((availability) => {
    // highlight class
    const selectedClass =
      availability.answer === 'yes'
        ? 'coach-match__prefered-time coach-match__prefered-time--details'
        : '';
    const convertedTime = Utils.formatAvailabilityTimeOptions(
      availability.label,
      props.matchTimeZone.pytz_timezone,
      props.coachTimeZone.pytz_timezone
    );

    // set up the template node
    const availabilityOption = (
      <li className={selectedClass} key={`availability-${availability.id}`}>
        {convertedTime}{' '}
        {moment.tz(props.coachTimeZone.pytz_timezone).zoneAbbr()}
      </li>
    );
    if (availability.label === 'Over the weekend') {
      weekendAvailableNode = <div>{availability.answer}</div>;
    }

    // check which list the availability option belongs
    if (morningOptions.includes(convertedTime)) {
      morningTimes.push(availabilityOption);
    } else if (afternoonOptions.includes(convertedTime)) {
      afternoonTimes.push(availabilityOption);
    } else if (eveningOptions.includes(convertedTime)) {
      eveningTimes.push(availabilityOption);
    }
  });

  let morningTimesNode = <div>None</div>;
  if (morningTimes.length) {
    morningTimesNode = <ul className="coach-match__list">{morningTimes}</ul>;
  }
  let afternoonTimesNode = <div>None</div>;
  if (afternoonTimes.length) {
    afternoonTimesNode = (
      <ul className="coach-match__list">{afternoonTimes}</ul>
    );
  }
  let eveningTimesNode = <div>None</div>;
  if (eveningTimes.length) {
    eveningTimesNode = <ul className="coach-match__list">{eveningTimes}</ul>;
  }

  return (
    <section className="row">
      <h3 className="coach-match__headline coach-match__headline--details coach-match__headline--ocean v5__heading-5">
        <i className="tc-v4-calendar match-details__icon" /> Availability
      </h3>
      <div className="coach-match__row">
        <div className="coach-match__col--33">
          <div className="match-details__sub-headline v5__body-copy--bold">
            Time Zone
          </div>
          <div className="match-details__main-detail">
            {props.matchTimeZone && props.matchTimeZone.name}
          </div>
          <div className="match-details__detail-highlight match-details__detail-highlight--green">
            <span className="v5__body-copy--bold">Note: </span>
            Availability has been converted to your timezone,
            <span className="match-details__detail-timezone">
              {' '}
              {props.coachTimeZone && props.coachTimeZone.name}
            </span>
          </div>
        </div>
        <div className="coach-match__col--66 coach-match__row coach-match__m-row">
          <div className="coach-match__col--25 coach-match__col--mobile-50">
            <div className="match-details__sub-headline v5__body-copy--bold">
              Morning
            </div>
            {morningTimesNode}
          </div>
          <div className="coach-match__col--25 coach-match__col--mobile-50">
            <div className="match-details__sub-headline v5__body-copy--bold">
              Afternoon
            </div>
            {afternoonTimesNode}
          </div>
          <div className="coach-match__col--25 coach-match__col--mobile-50">
            <div className="match-details__sub-headline v5__body-copy--bold">
              Evening
            </div>
            {eveningTimesNode}
          </div>
          <div className="coach-match__col--25 coach-match__col--mobile-50">
            <div className="match-details__sub-headline v5__body-copy--bold">
              Weekends
            </div>
            {weekendAvailableNode}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MatchAvailability;

MatchAvailability.propTypes = {
  matchTimeZone: PropTypes.object.isRequired,
  coachTimeZone: PropTypes.object.isRequired,
  availabilities: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
};
