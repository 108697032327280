// @ts-nocheck FIXME
import Store from './../components/Store';

const store = Store('CommentStore', {
  comments: [],
});

// ACTIONS
store.actionCreate('init', function (comments) {
  store.comments = comments;
});

// set mode to add
store.actionCreate('add', function (comment) {
  store.comments.push(comment);
});

export default store;
