// @ts-nocheck FIXME
import Request from './../../components/Request';

const FavoriteApi = {
  addFavorite: function (data) {
    const url = data.userId ? `/favorite/${data.userId}/add` : '/favorite/add';
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + url,
      JSON.stringify(data)
    );
  },
  removeFavorite: function (data) {
    const url = data.userId
      ? `/favorite/${data.userId}/remove`
      : '/favorite/remove';
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + url,
      JSON.stringify(data)
    );
  },
  updateSortOrder: function (queryString) {
    return Request.get(
      process.env.REACT_APP_LAB_SERVICE_URL + '/browse/favorites' + queryString
    );
  },
  filterResults: (queryString, data) => {
    return Request.get(
      process.env.REACT_APP_LAB_SERVICE_URL + '/browse/favorites' + queryString,
      data
    );
  },
};

export default FavoriteApi;
