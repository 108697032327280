// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import AbsoluteAlert from './../Alerts/AbsoluteStateless';
import Header from './../Header';
import PartnerCreate from './PartnerCreate';
import PartnerRow from './PartnerRow';

const PartnerList = (props): JSX.Element => {
  // event handlers
  const handlePartnerAddModal = (e) => {
    e.preventDefault();
    props.toggleModal({ feature: 'addPartner' });
  };

  // list of partners
  const partnerRow = props.partners.map(function (partner) {
    return <PartnerRow partner={partner} key={partner.id} />;
  });

  /* BEGIN RENDERING LOGIC */

  const alertNode = (
    <AbsoluteAlert
      {...props.ui.alert}
      cssClasses={{ container: 'alert-banner--fixed' }}
    />
  );

  return (
    <div>
      <Header page="PartnersList" {...props} />

      {alertNode}

      {props.ui.addPartner.modalDisplayed && (
        <PartnerCreate
          isLoading={props.ui.addPartner.isLoading}
          error={props.ui.addPartner.error}
          notifications={props.ui.addPartner.notifications}
          toggleModal={props.toggleModal}
          addPartner={props.addPartner}
          clearErrorsOnFocus={props.clearErrorsOnFocus}
        />
      )}

      <div className="row">
        <div className="cute-12-desktop">
          <a
            className="bl__btn bl__btn--small bl__btn--blue bl__btn--add-partner"
            onClick={handlePartnerAddModal}
          >
            Add Partner
          </a>
        </div>
      </div>

      <div className="row">
        <table className="admin-table">
          <tbody>
            <tr>
              <th className="admin-table__header">Partner</th>
              <th className="admin-table__header">Created</th>
              <th className="admin-table__header">User Count</th>
              <th className="admin-table__header"></th>
            </tr>
            {partnerRow}
          </tbody>
        </table>
      </div>
    </div>
  );
};

PartnerList.propTypes = {
  partners: PropTypes.array.isRequired,
  addPartner: PropTypes.func.isRequired,
};

export default PartnerList;
