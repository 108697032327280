// @ts-nocheck FIXME
import moment from 'moment';
import PropTypes from 'prop-types';
import Analytics from './../../../../../components/Analytics';
import UserPhoto from './../../UserPhoto';

const Details = (props) => {
  const linkUrl = props.url ? props.url : null;

  const handleUrlClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Analytics.logEvent({
      category: props.analyticsCategory,
      action: 'external_link',
      label: props.analyticsLabel,
    });

    window.open(linkUrl, '_blank');
  };

  const navigateToLesson = (e) => {
    e.preventDefault();

    // Record the analytics event:
    if (props.analyticsLabel) {
      Analytics.logEvent({
        category: props.analyticsCategory,
        action: 'link_to_full_lesson',
        label: props.analyticsLabel,
      });
    }
    e.stopPropagation();
    window.location =
      process.env.REACT_APP_TEACHING_URL +
      '/lesson/' +
      props.lesson.id +
      '/' +
      props.lesson.seo_url;
  };

  // display full screen node if available and an image with a preview
  let fullScreenBtnNode = null;
  if (
    (props.connections.preview_type === 'image' ||
      props.connections.preview_type === 'document') &&
    props.hasViablePreview &&
    props.fullScreenAvailable
  ) {
    fullScreenBtnNode = (
      <div
        className="artifact__fullscreen"
        onClick={props.handleFullScreenClick}
      >
        <div className="artifact__action-icon tc-full-screen"></div>
        <div className="tc__link">Full Screen</div>
      </div>
    );
  }

  // show creator photo + name if necessary
  let creatorNode;
  if (props.showCreator) {
    creatorNode = (
      <div className="artifact-overlay__creator">
        <div className="artifact-overlay__creator-photo">
          <UserPhoto src={props.creator.photo} diameter={30} />
        </div>
        <div className="artifact-overlay__creator-name">
          {props.creator.first_name} {props.creator.last_name}
        </div>
      </div>
    );
  }

  // if this artifact is associated with a story entry, display the entry type
  let entryTypeNode;
  if (props.entryType) {
    let iconClass = '';
    let entryType = '';

    switch (props.entryType) {
      case 'starting_statement':
        iconClass = 'tc-v4-reflection';
        entryType = 'Starting Reflection';
        break;
      case 'custom_strategy':
        iconClass = 'tc-v4-strategy';
        entryType = 'Strategy';
        break;
      case 'evidence':
        iconClass = 'tc-v4-leaf';
        entryType = 'Evidence';
        break;
      case 'reflection':
        iconClass = 'tc-v4-reflection';
        entryType = 'Reflection';
        break;
      case 'coach_note':
        iconClass = 'tc-v4-text';
        entryType = 'Coach Note';
        break;
      case 'text':
        iconClass = 'tc-v4-text';
        entryType = 'Artifact of Practice';
        break;
      case 'file':
      case 'link':
      case 'meeting_clip':
        iconClass = 'tc-v4-upload';
        entryType = 'Artifact of Practice';
        break;
    }
    entryTypeNode = (
      <div className="artifact-overlay__entry-type">
        <div className={'artifact-overlay__entry-type-icon ' + iconClass}></div>
        <div className="artifact-overlay__entry-type-name">{entryType}</div>
      </div>
    );
  }

  const title = props.name ? props.name : props.title;
  const iconNode =
    props.entryType === 'custom_strategy' ? null : (
      <div
        className={
          'artifact-overlay__file-icon file-type-icon ext-' +
          props.connections.icon
        }
      ></div>
    );
  let artifactTitleNode = title ? (
    <div>
      {iconNode}
      <div className="artifact-overlay__file-title tc__headline-5">{title}</div>
    </div>
  ) : null;
  if (props.connections.preview_type === 'link') {
    artifactTitleNode = (
      <div>
        <div
          className={'artifact-overlay__file-icon file-type-icon ext-link'}
        ></div>
        <div className="artifact-overlay__file-title tc__headline-5">
          <a
            className="tc__link"
            href="#"
            onClick={handleUrlClick}
            target="_new"
          >
            {linkUrl}
          </a>
        </div>
      </div>
    );
  }

  // hide the description if the artifact is just text
  let description = props.description;
  if (
    props.connections.preview_type === 'ckeditor_output' ||
    props.entryType === 'custom_strategy'
  ) {
    description = null;
  }

  // only show the mobile link to view hidden details if there are any
  const viewDetailsNode =
    !description || props.mtLesson ? null : (
      <div className="artifact-overlay__view-details tc__link">
        View Details
      </div>
    );

  // if the artifact is downloadable display the button and the user is not public or bl
  let downloadBtnNode = null;
  const downloadSpinner = props.artifactDownloading ? (
    <div className="artifact-overlay__download-loader littlespinner"></div>
  ) : null;
  if (props.connections.download_url) {
    downloadBtnNode = (
      <div
        className="artifact__download"
        data-test-id="download-button"
        onClick={props.handleDownloadClick}
      >
        <div className="artifact__action-icon tc-download"></div>
        <div className="tc__link">Download</div>
        {downloadSpinner}
        <div className="tc__error artifact__download_error artifact__download-error--v3">
          {props.downloadError}
        </div>
      </div>
    );
  } else if (props.connections.link) {
    downloadBtnNode = (
      <a
        href={props.connections.link}
        target="_blank"
        rel="noopener noreferrer"
        className="tc__link artifact__download"
      >
        <div className="artifact__action-icon tc-outlink"></div>
        <div className="tc__link">Open Link</div>
      </a>
    );
  }

  // if there is a mtp link display the button
  let lessonLinkBtnNode = null;
  if (props.lesson) {
    const url =
      process.env.REACT_APP_TEACHING_URL +
      '/lesson/' +
      props.lesson.id +
      '/' +
      props.lesson.seo_url;
    lessonLinkBtnNode = (
      <a href={url} className="artifact__lessonlink" onClick={navigateToLesson}>
        <div className="artifact__lessonlink-icon artifact__action-icon tc-outlink"></div>
        <div className="artifact__lessonlink-caption tc__link">Full Lesson</div>
        <div className="artifact__lessonlink-caption-mobile tc__link">
          Link To Lesson
        </div>
      </a>
    );
  }

  // setup container for artifact actions
  const actionsNode = (
    <div className="artifact-overlay__actions hide-phone">
      {lessonLinkBtnNode}
      {downloadBtnNode}
      {fullScreenBtnNode}
    </div>
  );

  // if we don't have date, then hide showing the added date
  let noAddedDate = false;
  if (!props.created_formatted) {
    noAddedDate = true;
  }
  let notesNode = null;
  if (description) {
    notesNode = (
      <div>
        <div className="artifact-overlay__notes tc__body-copy artifact-overlay__notes--mt">
          {description}
        </div>
        <div className="artifact-overlay__notes--mobile tc__body-copy">
          {description}
        </div>
      </div>
    );
  }
  return (
    <div
      className="artifact-overlay__details-container"
      onClick={props.showDetails}
    >
      <div>
        <div className="artifact-overlay__details artifact-overlay__details--no-author-photo">
          {creatorNode}
          {entryTypeNode}
          {artifactTitleNode}
          {!props.hideAddedDate && !noAddedDate && (
            <div className="artifact-overlay__added-by">
              Added on {moment(props.created_formatted).format('MMMM D, YYYY')}
            </div>
          )}
          {viewDetailsNode}
          <div className="artifact-overlay__view-lesson">
            {lessonLinkBtnNode}
          </div>
        </div>
        {notesNode}
        <div className="artifact-overlay__details-actions--mobile">
          {lessonLinkBtnNode}
        </div>
      </div>
      {actionsNode}
    </div>
  );
};

Details.propTypes = {
  showDetails: PropTypes.func.isRequired,
  hasViablePreview: PropTypes.bool.isRequired,
  fullScreenAvailable: PropTypes.bool.isRequired,
  handleDownloadClick: PropTypes.func,
  handleFullScreenClick: PropTypes.func,
  artifactDownloading: PropTypes.bool,
  hideAddedDate: PropTypes.bool,
  downloadError: PropTypes.string,
  typeVisible: PropTypes.bool,
  lessonfile: PropTypes.object,
  entryType: PropTypes.string,
  showCreator: PropTypes.bool,
  analyticsCategory: PropTypes.string,
  analyticsLabel: PropTypes.string,
};

export default Details;
