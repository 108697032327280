// @ts-nocheck FIXME
import React from 'react';
import ReactDOM from 'react-dom';
import Utils from './../../../components/StaticUtils';

const KpiContainer = (kpiCaption) => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component {
    componentDidMount() {
      // adjust padding for multiline captions
      const caption = ReactDOM.findDOMNode(this.refs.kpiCaption);
      const captionHeight = caption.offsetHeight;
      if (captionHeight > 32) {
        Utils.addClass(caption, 'multiline');
      }
    }

    render() {
      return (
        <div className="ppp__kpi-captions" ref="kpiCaption">
          {kpiCaption}
        </div>
      );
    }
  };
};

export default KpiContainer;
