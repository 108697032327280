// @ts-nocheck FIXME
import React from 'react';

class ArtifactsHeadline extends React.Component {
  render() {
    return (
      <div className="portfolio__section-headline portfolio__section-headline--artifact">
        Classroom Artifacts
      </div>
    );
  }
}

export default ArtifactsHeadline;
