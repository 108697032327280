// @ts-nocheck FIXME
import Request from './../components/Request';

const UserApi = {
  updateUser: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/user/update',
      JSON.stringify(data)
    );
  },

  createUser: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/user/add',
      JSON.stringify(data)
    );
  },

  createUsers: function (data) {
    const fd = new FormData();
    fd.append('file', data.file);
    fd.append('formData', JSON.stringify(data.formData));

    return Request.postForm(
      process.env.REACT_APP_LAB_SERVICE_URL + '/user/bulk_add',
      fd
    );
  },

  updateUserRoles: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/user/update_roles',
      JSON.stringify(data)
    );
  },

  editUserSettings: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/user/' +
        data.userId +
        '/edit-settings',
      JSON.stringify(data)
    );
  },

  prepareImageUpload: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/user/' +
        data.userId +
        '/image/create_before_upload',
      JSON.stringify(data)
    );
  },

  uploadUserImage: function (url, data) {
    return Request.upload(url, data);
  },

  updateUserImage: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/user/' +
        data.userId +
        '/image/update',
      JSON.stringify(data)
    );
  },
};

export default UserApi;
