// @ts-nocheck FIXME
import ZenScroll from 'zenscroll';

import Store from './../../components/Store';

import ParticipantApi from './../../apis/ParticipantApi';
import StoryApi from './../../apis/V4/StoryApi';
import VisionApiV4 from './../../apis/V4/VisionApi';
import Analytics from './../../components/Analytics';
import Modal from './../../components/Modal';
import { History } from './../../components/Router';
import Utils from './../../components/StaticUtils';
import WindowHelpers from './../../components/WindowHelpers';

const store = {
  pageParticipant: null,
  reorderingStories: [],
  sidebarStories: [],
  stories: [],
  ui: {
    sortIndicatorActive: false,
    storySidebarReordering: false,
    activeSidebarLink: null,
    activeAction: null,
    entriesCollapsed: true,
    activeEntryIndex: null,
    // track the index of the entry to be copied.  This works in tandem with activeEntryIndex.
    // At some point we may want to combine the move edit and copy logic into a single function,
    // but the analytics make it difficult right now.
    copyEntryIndex: null,
    // store the current value of the copy entry dropdown
    copyEntryValue: null,
    // Flag used to move artifacts within the user's own profile
    moveEntryFlag: false,
    alert: {},
    visionMode: 'empty',
    designMode: 'empty',
    showConfirmDelete: false,
    addArtifactModal: {},
    newEntry: {
      opened: false,
      type: null,
    },
    editEntry: {
      isPrivate: false,
      loading: false,
      errors: {},
    },
    highlightActiveEntry: false,
    // clicking on a file img opens the artifact overlay
    artifactModal: {
      callbacks: {
        onOpen: (data) => {
          History.replace(
            null,
            null,
            '/profile/' + store.pageUser.id + '/artifact/' + data.id
          );

          // Record the analytics event:
          // Analytics.logEvent({
          //     category: 'artifact',
          //     action: 'view',
          //     label: 'from_outcome_story_page'
          // });
        },
        onClose: () => {
          History.replace(null, null, '/profile/' + store.pageUser.id);

          // Record the analytics event:
          // Analytics.logEvent({
          //     category: 'artifact',
          //     action: 'close',
          //     label: 'from_outcome_story_page'
          // });
        },
      },
    },
    selectedPortfolioStory: {},
    // clicking on a file img opens the artifact overlay
    portfolioArtifactModal: {
      callbacks: {
        onOpen: (data) => {
          if (data.entryType == 'starting_statement') {
            History.replace(
              null,
              null,
              '/profile/' + store.pageUser.id + '/story/' + data.storyId
            );
          } else {
            History.replace(
              null,
              null,
              '/profile/' +
                store.pageUser.id +
                '/story/' +
                data.storyId +
                '/artifact/' +
                data.id
            );
          }
          // Record the analytics event:
          Analytics.logEvent({
            category: data.analyticsCategory,
            action: 'open',
            label: 'from_profile',
          });
        },
        onClose: (data) => {
          History.replace(null, null, '/profile/' + store.pageUser.id);
          // Record the analytics event:
          Analytics.logEvent({
            category: data.analyticsCategory,
            action: 'close',
            label: 'from_profile',
          });
        },
      },
    },
  },
};

/****** PRIVATE FUNCTIONS ******/

const scrollToElementById = (id) => {
  // scroll to the element after a slight delay
  window.setTimeout(() => {
    const element = document.getElementById(id);
    // on mobile, we need to account for the extra height of the sticky header
    ZenScroll.setup(400, WindowHelpers.isMobileDevice() ? 55 : 10);
    ZenScroll.to(element);
  }, 100);
};

const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

const submitTextEntry = (data) => {
  // set up form loading UI
  store.ui.editEntry.loading = true;
  data.participantId = store.pageParticipant.id;
  if (data.textEntryId) {
    // edit an existing custom strategy
    return VisionApiV4.editTextEntry(data)
      .then((response) => {
        store.editEntryCallback(response);
      })
      .catch((response) => {
        store.ui.editEntry.loading = false;
        store.setErrorAlert(
          'Sorry, there was a problem updating the text. Please try again later.'
        );
      });
  } else {
    // create a new text entry
    return VisionApiV4.addTextEntry(data)
      .then((response) => {
        store.addEntryCallback(response);
      })
      .catch((response) => {
        store.ui.editEntry.loading = false;
        store.setErrorAlert(
          'Sorry, there was a problem adding the text. Please try again later.'
        );
      });
  }
};

const submitMeetingClipEntry = (data) => {
  // set up form loading UI
  store.ui.editEntry.loading = true;
  data.participantId = store.pageParticipant.id;
  if (data.textEntryId) {
    // edit an existing custom strategy
    return VisionApiV4.addMeetingClipEntry(data)
      .then((response) => {
        store.editEntryCallback(response);
      })
      .catch((response) => {
        store.ui.editEntry.loading = false;
        store.setErrorAlert(
          'Sorry, there was a problem updating the artifact. Please try again later.'
        );
      });
  } else {
    // create a new text entry
    return VisionApiV4.addMeetingClipEntry(data)
      .then((response) => {
        store.addEntryCallback(response);
      })
      .catch((response) => {
        store.ui.editEntry.loading = false;
        store.setErrorAlert(
          'Sorry, there was a problem adding the artifact. Please try again later.'
        );
      });
  }
};

const setArtifactAnalyticsSubject = (analyticsIndex) => {
  if (analyticsIndex === null) return '';

  switch (store.pageParticipant.visionEntries[analyticsIndex].type) {
    case 'file':
      return 'file';
    case 'link':
      return 'link';
    case 'text':
      return 'text';
  }
};
/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.ui.alert = {};
  store.stories = store.sidebarStories = data.stories;

  store.pageParticipant = data.pageParticipant;
  store.pageUser = data.pageUser;

  store.ui.addArtifactModal = Modal(store, store.ui.addArtifactModal);
  store.ui.artifactModal = Modal(store, store.ui.artifactModal);
  store.ui.portfolioArtifactModal = Modal(
    store,
    store.ui.portfolioArtifactModal
  );

  // empty vision must be expanded by default
  if (
    data.pageParticipant &&
    (data.pageParticipant.vision == '' || !data.pageParticipant.vision)
  ) {
    store.ui.visionMode = 'edit';
  }

  // Add the focusAreas for moving entries to stories
  if (data.storyFocusAreas && data.storyFocusAreas.length) {
    store.ui.copyEntryValue = data.storyFocusAreas[0][0];
  }

  // open the vision artifact overlay if necessary
  if (data.artifactEntryId && store.pageParticipant.visionEntries.length) {
    const artifactEntry = store.pageParticipant.visionEntries.find(
      (entry) => entry.id == data.artifactEntryId
    );
    if (artifactEntry) {
      store.ui.artifactModal.open(artifactEntry);
    }
  }
  // open the story entry artifact overlay if necessary
  else if (data.storyId) {
    const story = data.stories.find((story) => story.id == data.storyId);
    // if we have a story and tab name, scroll to that capsule with the tab active
    if (story && data.tab) {
      store.selectedPortfolioStory = {
        id: data.storyId,
        elementId: 'story-' + data.storyId,
        section: data.tab == 'timeline' ? 'timeline' : 'highlights',
      };
    }
    // otherwise, if we have an entry id, try to open the modal for the entry
    else if (story && data.storyEntryId) {
      const storyEntry = story.entries.find(
        (entry) => entry.id == data.storyEntryId
      );
      // for curated strategy entries, just scroll to show it in Highlights section
      if (storyEntry && storyEntry.type == 'curated_strategy') {
        store.selectedPortfolioStory = {
          id: data.storyId,
          elementId: 'story-' + data.storyId + '-strategies',
          section: 'strategies',
          selectedId: storyEntry.strategy_id,
        };
      }
      // for all other entry types, we can show the appropriate overlay
      else if (storyEntry) {
        // data passed to artifact overlay needs some setup
        const entryData = Utils.clone(storyEntry);
        entryData.storyId = data.storyId;
        entryData.entryType = entryData.tag ? entryData.tag : entryData.type;
        if (entryData.type == 'custom_strategy') {
          entryData.connections = {
            preview_type: 'text',
            content: {
              preview: entryData.description,
            },
          };
        } else if (entryData.type == 'text') {
          entryData.connections = {
            preview_type: 'ckeditor_output',
          };
        }
        if (entryData.tag == 'coach_note') {
          entryData.showCreator = true;
        }
        // open the modal
        store.ui.portfolioArtifactModal.open(entryData);
      }
    }
    // if no entry, then try to open the modal for the starting statement
    else if (story && story.startingStatement) {
      const entryData = Utils.clone(story.startingStatement);
      entryData.storyId = data.storyId;
      entryData.entryType = 'starting_statement';
      entryData.connections = {
        preview_type: 'ckeditor_output',
      };
      store.ui.portfolioArtifactModal.open(entryData);
    }
  }

  // scroll to active Entry if url param is set
  const queryParams = Utils.parseQueryString(window.location.search);
  if ('entryId' in queryParams) {
    scrollToElementById('entry-' + queryParams.entryId);
  }

  // don't trigger event
  return false;
};

store.setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

store.setSuccessAlert = (message) => {
  store.ui.alert = {
    type: 'success',
    message: message,
    handleClose: store.clearAlert,
  };
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.clearError = (field) => {
  if (field in store.ui.editEntry.errors) {
    delete store.ui.editEntry.errors[field];
    return;
  }
  return false;
};

store.changeMode = (data) => {
  store.ui.activeAction =
    data.mode != 'empty' ? 'update-' + data.feature : null;
  store.ui[data.feature + 'Mode'] = data.mode;
};
store.toggleAddArtifactModal = () => {
  const analyticsAction = store.ui.addArtifactModal.opened ? 'close' : 'open';
  Analytics.logEvent({
    category: 'add_widget',
    action: analyticsAction,
    label: 'from_profile',
  });

  if (store.ui.addArtifactModal.opened) {
    store.ui.addArtifactModal.close();
    return;
  }
  store.ui.addArtifactModal.open();
};

store.submitTextArea = (data) => {
  data = data || {};

  // clear errors
  store.ui.alert = {};

  // track form errors
  const errors = {};
  let updateField = function () {};

  switch (data.feature) {
    case 'vision':
      data.participantId = store.pageParticipant.id;
      data.vision = data.value;

      updateField = function () {
        store.pageParticipant.vision = data.value;
      };
      break;
    case 'design':
      data.participantId = store.pageParticipant.id;
      data.designSummary = data.value;

      updateField = function () {
        store.pageParticipant.design_summary = data.value;
      };
      break;
  }

  data.participantId = store.pageParticipant.id;

  //wrong endpoint but still dont have one yet
  return ParticipantApi.editParticipantData(data)
    .then((response) => {
      updateField();

      // clear out opened text areas
      if (data.feature == 'vision') {
        store.ui.designMode = 'empty';
      } else if (data.feature == 'design') {
        store.ui.visionMode = 'empty';
      }
      // open appropreate text areas
      store.ui[data.feature + 'Mode'] = 'view';

      store.ui.activeAction = null;
    })
    .catch((response) => {
      store.ui.activeAction = null;
      setErrorAlert(
        'Sorry, there was a problem submitting the form. Please try again later.'
      );
    });
};

store.closeNewEntry = (data) => {
  store.ui.newEntry.opened = false;
  store.ui.activeAction = null;
  // remove the item from the participants vision
  if (data && data.entryId) {
    return VisionApiV4.deleteEntry({
      participantId: store.pageParticipant.id,
      entryId: data.entryId,
    })
      .then((response) => {
        store.ui.editEntry.loading = false;
      })
      .catch((response) => {
        store.ui.editEntry.loading = false;
        store.setErrorAlert(
          'Sorry, there was a problem deleting the uploaded entry. Please try again later.'
        );
      });
  }
};

store.openNewEntry = (data) => {
  store.ui.activeAction = 'new-entry';
  store.ui.addArtifactModal.close();

  store.ui.newEntry.opened = true;
  store.ui.newEntry.type = data.type;

  store.ui.editEntry.isPrivate = false;
  store.ui.editEntry.loading = false;
  store.ui.editEntry.errors = {};
  store.ui.highlightActiveEntry = false;

  let analyticsCategory = 'add_text_prompt';
  switch (data.type) {
    case 'file':
      analyticsCategory = 'add_file_prompt';
      break;
    case 'link':
      analyticsCategory = 'add_link_prompt';
      break;
    case 'text':
      analyticsCategory = 'add_text_prompt';
      break;
  }

  Analytics.logEvent({
    category: analyticsCategory,
    action: 'open',
    label: 'from_add_widget_profile',
  });

  // scroll to the "new entry" capsule
  scrollToElementById('new-entry-capsule');

  // on desktop, we want to make sure the "more options" menu is closed
  if (!WindowHelpers.isMobileDevice()) {
    store.ui.moreOptionsOpened = false;
  }

  // set up the uploadedFiles on the window object if the user has uploaded
  // files directly on the timeline using a mobile device
  if ('uploadedFiles' in data) {
    window.uploadedFiles = data.uploadedFiles;
  }
};

// after adding a new entry via the API, use this to add it to the timeline
store.addEntryCallback = (response) => {
  let analyticsCategory = 'file_artifact';
  switch (response.entry.type) {
    case 'link':
      analyticsCategory = 'link_artifact';
      break;
    case 'text':
      analyticsCategory = 'text_artifact';
      break;
    case 'file':
      analyticsCategory = 'file_artifact';
      break;
    case 'meeting_clip':
      analyticsCategory = 'clip_artifact';
      break;
  }
  Analytics.logEvent({
    category: analyticsCategory,
    action: 'add',
    label: 'from_profile',
  });
  store.ui.activeAction = null;
  store.ui.editEntry.loading = false;
  // add the new entry to the timeline
  store.pageParticipant.visionEntries.splice(0, 0, response.entry);

  // close the "new entry" capsule
  store.closeNewEntry();
  // scroll to the new entry
  const entryRowId = 'entry-' + response.entry.id;

  scrollToElementById(entryRowId);
};

// after editing an entry via the API, use this to replace the entry in the timeline
store.editEntryCallback = (response) => {
  let analyticsCategory = 'file_artifact';
  switch (response.entry.type) {
    case 'link':
      analyticsCategory = 'link_artifact';
      break;
    case 'text':
      analyticsCategory = 'text_artifact';
      break;
    case 'file':
      analyticsCategory = 'file_artifact';
      break;
    case 'meeting_clip':
      analyticsCategory = 'clip_artifact';
      break;
  }
  Analytics.logEvent({
    category: analyticsCategory,
    action: 'save_edits',
    label: 'from_profile',
  });
  store.ui.activeAction = null;

  store.ui.editEntry.loading = false;
  // replace existing entry
  store.pageParticipant.visionEntries[store.ui.activeEntryIndex] =
    response.entry;
  // stop editing the entry
  store.ui.activeEntryIndex = null;
  store.ui.activeAction = null;
};

// called when any entry add/edit entry/statement form is submitted
// (other than files/links, which are contained in the Artifact component)
store.submitEntry = (data) => {
  // clear any errors
  store.ui.editEntry.errors = {};

  // validate form data
  if (!data.description) {
    store.ui.editEntry.errors.description = 'Please enter some text';
    return;
  }

  // add/edit entry via API
  return submitTextEntry(data);
};

// the "active" entry is the one being edited or deleted
store.setActiveEntryIndex = (index) => {
  store.ui.activeAction = null;

  // get the type of artifact for analytics
  let analyticsIndex = index;
  if (index == null) {
    analyticsIndex = store.ui.activeEntryIndex;
  }
  // for analytics
  const analyticsCategory = setArtifactAnalyticsSubject(analyticsIndex);

  // set current index to open or close the edit box
  store.ui.activeEntryIndex = index;

  // reset editing UI
  store.ui.editEntry.isPrivate = true;
  store.ui.editEntry.loading = false;
  store.ui.editEntry.errors = {};
  store.ui.highlightActiveEntry = false;

  // if we are editing an entry, scroll to it
  let analyticsAction = 'cancel_edits';
  if (index != null) {
    store.ui.activeAction = 'artifact-edit';
    const entry = store.pageParticipant.visionEntries[index];
    const entryRowId = 'entry-' + entry.id;

    analyticsAction = 'edit';

    scrollToElementById(entryRowId);
    // initialize entry data
    store.ui.editEntry.isPrivate = entry.private;
  }

  Analytics.logEvent({
    category: analyticsCategory + '_artifact',
    action: analyticsAction,
    label: 'from_profile',
  });

  // when editing an entry, make sure the more options menu is closed
  store.ui.moreOptionsOpened = false;
};

store.copyEntryValueChange = (value) => {
  store.ui.copyEntryValue = value;
};

store.setMoveArtifact = () => {
  store.ui.moveEntryFlag = true;
};

// Leave this until I can use setActiveEntryIndex instead
store.setCopyEntryIndex = (index) => {
  if (index === null) {
    // restore moveEntryFlag to its initial state when the index gets unset
    store.ui.moveEntryFlag = false;
  }
  store.ui.copyEntryIndex = index;
  store.setActiveEntryIndex(index);

  const entry = store.pageParticipant.visionEntries[index];

  if (index != null) {
    const entryRowId = 'entry-' + entry.id;
    scrollToElementById(entryRowId);
  }
  store.ui.moreOptionsOpened = false;
};

store.moveArtifact = (successMessage) => {
  const activeIndex = store.ui.activeEntryIndex;
  const data = store.pageParticipant.visionEntries[activeIndex];
  store.ui.editEntry.loading = true;

  data['storyId'] = store.ui.copyEntryValue;

  let moveArtifact = null;
  let fileId = null;

  // Sometimes movie files have a preview and sometimes they do not.  Same goes for download.
  if (data.connections) {
    fileId =
      data.connections.download_lessonfile_id ||
      data.connections.preview_lessonfile_id;
  }

  switch (data.type) {
    case 'text':
      moveArtifact = StoryApi.addTextEntry(data);
      break;
    case 'file':
      data['lessonfileId'] = fileId;
      moveArtifact = StoryApi.addFileEntry(data);
      break;
    case 'link':
      data['url'] = data.connections && data.connections.link;
      moveArtifact = StoryApi.addLinkEntry(data);
      break;
    default:
      moveArtifact = Promise.reject(new Error('Invalid entry type'));
      break;
  }

  moveArtifact
    .then(() => {
      store.deleteEntry(activeIndex);
    })
    .then(() => {
      // Close the form and show the success alert
      store.setCopyEntryIndex(null);
      store.setSuccessAlert(successMessage);
    })
    .catch((e) => {
      // Something went wrong
      console.dir(e);
    });
};

store.toggleIsPrivate = () => {
  store.ui.editEntry.isPrivate = !store.ui.editEntry.isPrivate;
};

/***** Delete Entry *****/
store.showConfirmDelete = () => {
  // for analytics
  const analyticsCategory = setArtifactAnalyticsSubject(
    store.ui.activeEntryIndex
  );
  Analytics.logEvent({
    category: analyticsCategory + '_artifact',
    action: 'show_deletion_confirmation',
    label: 'from_profile',
  });
  store.ui.activeAction = 'delete-artifact';
  store.ui.showConfirmDelete = true;
  // scroll to the top of the active entry to make sure the delete form is in view
  scrollToElementById(
    'entry-' + store.pageParticipant.visionEntries[store.ui.activeEntryIndex].id
  );
};
store.hideConfirmDelete = () => {
  const analyticsCategory = setArtifactAnalyticsSubject(
    store.ui.activeEntryIndex
  );
  Analytics.logEvent({
    category: analyticsCategory + '_artifact',
    action: 'cancel_delete',
    label: 'from_profile',
  });
  store.ui.activeAction = null;
  store.ui.activeEntryIndex = null;
  store.ui.showConfirmDelete = false;
};

store.deleteEntry = (index) => {
  // Allow a parameter to be passed for handling delete asynchronously
  if (index === undefined) {
    index = store.ui.activeEntryIndex;
  }
  // set up form loading UI
  store.ui.editEntry.loading = true;
  // for analytics
  const analyticsCategory = setArtifactAnalyticsSubject(index);
  Analytics.logEvent({
    category: analyticsCategory + '_artifact',
    action: 'delete',
    label: 'from_profile',
  });
  // mark the entry as deleted
  return VisionApiV4.deleteEntry({
    participantId: store.pageParticipant.id,
    entryId: store.pageParticipant.visionEntries[index].id,
  })
    .then((response) => {
      store.ui.activeAction = null;
      store.ui.editEntry.loading = false;
      // remove the entry from the list
      store.pageParticipant.visionEntries.splice(index, 1);
      store.ui.activeEntryIndex = null;
      store.ui.showConfirmDelete = false;
    })
    .catch((response) => {
      store.ui.activeAction = null;
      store.ui.editEntry.loading = false;
      store.setErrorAlert(
        'Sorry, there was a problem deleting the entry. Please try again later.'
      );
    });
};
store.scrollToProfileSection = (data) => {
  // scroll to the given id
  scrollToElementById(data.id);
};
store.toggleEntriesCollapsed = () => {
  store.ui.entriesCollapsed = !store.ui.entriesCollapsed;
};

// reordering of sidebar stories
store.toggleEditSidebarStoriesSortOrder = () => {
  store.ui.storySidebarReordering = !store.ui.storySidebarReordering;
  if (store.ui.storySidebarReordering) {
    store.reorderingStories = Utils.clone(store.stories);
    store.ui.sortIndicatorActive = true;
  }
};
store.dropSidebarStoryReorder = (ids) => {
  store.sidebarStories = Utils.sortArrayOfObjectsByProperty(
    store.sidebarStories,
    ids
  );
};
store.saveSidebarStoryReorder = () => {
  store.ui.reorderingLoading = true;
  // api call to update stories in the db
  return ParticipantApi.reorderPortfolioStories({
    participantId: store.pageParticipant.id,
    storyIds: store.sidebarStories.map((story) => story.id),
  })
    .then((response) => {
      // update the actual list of entries with the temporary order
      store.stories = store.sidebarStories;
      // clear the temporary list
      store.reorderingEntries = [];
      // update the UI
      store.toggleEditSidebarStoriesSortOrder();
    })
    .catch((response) => {
      store.ui.reorderingLoading = false;
      store.setErrorAlert(
        'Sorry, there was a problem reordering the entries. Please try again later.'
      );
    });
};
store.cancelSidebarStoryReorder = () => {
  store.sidebarStories = store.reorderingStories;
  store.reorderingStories = [];
  store.ui.sortIndicatorActive = false;
  store.toggleEditSidebarStoriesSortOrder();
};

export default Store('ProfileStore', store);
