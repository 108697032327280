// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const MobileHome = (props) => {
  return (
    <div className="m-coach-dashboard--archive admin-v5__coach-table">
      <div className="admin-v5_coach-dashboard__calendar-heading">
        Archived Participants
      </div>
      <div className="admin-v5_coach-dashboard__mobile-header">
        <div className="admin-v5__coach-mobile-table-header cute-6-phone">
          Name
        </div>
        <div className="cute-6-phone">Dates</div>
      </div>
      <div className="coach__participants">{props.children}</div>
    </div>
  );
};

MobileHome.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MobileHome;
