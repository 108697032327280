// @ts-nocheck FIXME
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import Analytics from './../../../components/Analytics';
import { Router } from './../../../components/Router';
import BrowserStore from './../../../stores/V2/BrowserStore';

const SearchBar = createReactClass({
  displayName: 'SearchBar',

  getInitialState: function () {
    return BrowserStore.getState();
  },

  componentDidMount: function () {
    this.token = BrowserStore.subscribe(this.onStoreChange);
  },

  componentDidUpdate: function () {
    // when opened, focus on textbox, with any existing text highlighted
    if (this.state.searchBarOpened) {
      const textInput = ReactDOM.findDOMNode(this.refs.searchText);
      textInput.focus();
      textInput.select();
    }
  },

  componentWillUnmount: function () {
    BrowserStore.unsubscribe(this.token);
  },

  onStoreChange: function () {
    // eslint-disable-next-line react/no-is-mounted
    if (this.isMounted()) {
      this.setState(BrowserStore.getState());
    }
  },

  handleSearch: function (e) {
    e.preventDefault();

    BrowserStore.actionDispatchSync('toggleSearchBar');
    // by default we search over all strategies,
    // but if the scope-limiting radios are visible then check those
    const searchRadioHere = ReactDOM.findDOMNode(this.refs.searchRadioHere);
    const searchAll = searchRadioHere ? !searchRadioHere.checked : true;

    const searchText = ReactDOM.findDOMNode(this.refs.searchText).value;
    let urlParams = 'searchText=' + searchText;

    const analyticsEvent = {
      label: 'from_sb_search_all',
    };

    if (!searchAll) {
      urlParams += this.props.challengeId
        ? '&challengeId=' + this.props.challengeId
        : '';
      urlParams += this.props.subject ? '&subject=' + this.props.subject : '';
      analyticsEvent.label = 'from_sb_search_tc';
    }

    analyticsEvent.category = 'strategy';
    analyticsEvent.action = 'search';
    Analytics.logEvent(analyticsEvent);

    Router.route(
      process.env.REACT_APP_TEACHING_URL +
        '/coaching/lab/strategy-browser/search?' +
        urlParams
    );
  },

  handleToggleSearchBox: function (e) {
    e.preventDefault();
    BrowserStore.actionDispatchSync('toggleSearchBar');
  },

  render: function () {
    // if we are in a subject/challenge, then we'll let the user choose
    // whether to search within the current scope or across all strategies
    const currentScope = this.props.challengeId
      ? 'Search Challenge: ' + this.props.challenge
      : this.props.subject
      ? 'Search ' + this.props.subject + ' Strategies'
      : null;
    const scopeRadios = [];

    if (currentScope) {
      scopeRadios.push(
        <input
          type="radio"
          name="searchScope"
          id="searchRadioHere"
          key="searchRadioHere"
          ref="searchRadioHere"
          value="here"
          defaultChecked={true}
        />
      );
      scopeRadios.push(
        <label key={'searchRadioHereLabel'} htmlFor="searchRadioHere">
          {currentScope}
        </label>
      );
    }
    // if we don't have a subject/challenge to limit the search, just show the "All" radio
    scopeRadios.push(
      <input
        type="radio"
        name="searchScope"
        id="searchRadioAll"
        key="searchRadioAll"
        ref="searchRadioAll"
        value="all"
        defaultChecked={!currentScope}
      />
    );
    scopeRadios.push(
      <label key={'searchRadioAllLabel'} htmlFor="searchRadioAll">
        Search All Strategies
      </label>
    );

    const openedSearchBarClass = this.state.searchBarOpened ? ' active' : '';

    return (
      <div className={'browser__searchbar-container' + openedSearchBarClass}>
        <div className="browser__searchbar">
          <form
            onSubmit={this.handleSearch}
            className="row browser__search-form"
          >
            <div>
              <input
                type="text"
                placeholder="Enter strategy key words"
                ref="searchText"
                defaultValue={this.props.searchText}
                className="browser__search-text"
              />
              <button
                type="submit"
                className="tc__btn--grey tc-search browser__search-submit-btn"
              ></button>
            </div>
            <div className="browser__search-scope-radios">{scopeRadios}</div>
          </form>
        </div>
        <div
          className="browser__searchbar-underlay"
          onClick={this.handleToggleSearchBox}
        ></div>
      </div>
    );
  },
});

export default SearchBar;
