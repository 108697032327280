// @ts-nocheck FIXME
import ScaleCenterImage from '@legacy-components/react/ScaleCenterImage';
import PropTypes from 'prop-types';

const RegistrationExpired = (props) => {
  const handleGoHome = () => {
    location.href = '/';
  };
  const eventNameNode = props.event_name ? (
    <span className="bold">{props.event_name}</span>
  ) : null;
  const endDate = props.event_date || props.session_date || null;
  return (
    <div className="tc__page tc__page--registration">
      <div className="tc-header__wrap tc-header__wrap--v2">
        <div className="tc-header tc-header--v2 row row--1200">
          <div
            className="logo tc-header__logo cute-3-desktop cute-3-phone left tc-header__registration-phone"
            onClick={handleGoHome}
            src="/legacy-images/betterlesson-logo.svg"
          ></div>
        </div>
      </div>

      <div className="row row--1200">
        <div className="cute-4-desktop cute-8-phone left registration__expired-box">
          <ScaleCenterImage
            className="registration__error-image"
            src="/legacy-images/BL_Lab_ExpiredLink_small.png"
            alt="This registration link has expired"
          />
        </div>
        <div className="cute-7-desktop cute-4-phone event-v5__registration-expired-message">
          <div className="v5__body-copy">
            <div className="v5__heading-2">Oh shoot! You missed the boat.</div>
            {eventNameNode && endDate && (
              <div className="event-v5__registration-expired-title">
                {eventNameNode} ended on {endDate}.
              </div>
            )}
            <div className="event-v5__registration-expired-title">
              Check to see if you have the correct link and try again. If you
              think you are receiving this message in error, please&nbsp;
              <a
                href="https://betterlesson.atlassian.net/servicedesk/customer/portals"
                className="v5__link"
              >
                contact us
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RegistrationExpired.propTypes = {
  registrationType: PropTypes.string.isRequired,
};

export default RegistrationExpired;
