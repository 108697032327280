// @ts-nocheck FIXME
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';

const CircleGraph = (props) => {
  // calculate the value for the circular graph
  const value = !props.total ? 0 : props.count / props.total;

  // if we are displaying the Engagement value, show a tooltip to explain it
  const tooltipNode = props.showTooltip ? (
    <Tooltip
      tooltip="This is the percentage of educators who have a future meeting scheduled. The total number may be less than the total educators in your cohorts, because educators who are Temporarily Inactive, Done with Coaching or Dropped are not counted in this calculation."
      tipClass="v5__tooltip--wide partner-lead-v5__engagement-tooltip"
      inline={true}
      arrow={false}
    >
      <span className="tc-v5-question partner-lead-v5__engagement-tooltip-icon" />
    </Tooltip>
  ) : null;

  return (
    <div className="partner_lead_v5__registration-stat">
      <CircularProgressbar
        value={value}
        maxValue={1}
        text={value > 1 ? '> 100%' : `${Math.round(value * 100)}%`}
      />
      <div className="partner_lead_v5__registration-stat--count">{`${props.count} of ${props.total}`}</div>
      <div className="partner_lead_v5__registration-stat--label">
        {props.label}
        {tooltipNode}
      </div>
    </div>
  );
};

CircleGraph.propTypes = {
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  label: PropTypes.string,
  showTooltip: PropTypes.bool,
};

export default CircleGraph;
