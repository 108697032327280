// @ts-nocheck FIXME
import Utils from './../../../../../components/DynamicUtils';
import TmlProgressChart from './../../../TmlLoop/TmlProgressChart';

const Measurement = (props) => {
  let resultsNode = (
    <div className="portfolio-v2__measurement-results">
      <span className="highlight">{props.measurementValue}</span> out of{' '}
      <span className="highlight">
        {props.measurementTotal} ({props.qualitativePercentage}%)
      </span>
    </div>
  );
  if (props.isInstructionalDesign) {
    resultsNode = (
      <div className="portfolio-v2__measurement-results">
        <span className="highlight">{props.qualitativePercentage / 10}</span>{' '}
        out of <span className="highlight">10</span>
      </div>
    );
  }

  const measurementChart = (
    <div className="portfolio-v2__measurement-chart-container">
      <TmlProgressChart
        isInstructionalDesign={props.isInstructionalDesign}
        baseline={props.baseline.qualitative_percentage}
        goal={props.goal.qualitative_percentage}
        measurementPercent={props.qualitativePercentage}
        measurementValue={props.measurementValue}
      />
    </div>
  );
  return (
    <div className="portfolio-v2__loop-section">
      <div className="portfolio-v2__strategy-headline">THE RESULTS</div>
      <div className="portfolio-v2__measurement-metric">
        <span className="label">I am looking for:</span>{' '}
        {Utils.getMetricName(props.metric.name, props.metric.default_n)}
      </div>
      <div className="portfolio-v2__measurement-results-section">
        <div>
          {props.isInstructionalDesign ? 'Teacher Success' : 'Student Success'}
        </div>
        {resultsNode}
      </div>
      {measurementChart}
    </div>
  );
};

export default Measurement;
