// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import PropTypes from 'prop-types';

const Teacher = (props) => {
  // clicking on their photo or name links to the About tab
  const aboutLink = `${process.env.REACT_APP_TEACHING_URL}/browse/master_teacher/${props.id}/${props.seo_url}/about`;

  // set up some teacher data
  const domainsNode =
    ArrayHelpers.hasElements(props.domains) &&
    props.domains.map((domain) => {
      return (
        <div key={domain} className="mt-profile__teacher-course-name">
          {domain.replaceAll('Learning Domain', 'Master Teacher')}
        </div>
      );
    });

  const coursesNode =
    ArrayHelpers.hasElements(props.courses) &&
    props.courses.map((course) => {
      return (
        <div key={course} className="mt-profile__teacher-course-name">
          {course}
        </div>
      );
    });

  const schoolNode = props.school ? (
    <div className="mt-profile__teacher-school-detail">{props.school}</div>
  ) : null;

  let locationNode;
  if (props.city && props.state) {
    locationNode = (
      <div className="mt-profile__teacher-school-detail">
        {props.city}, {props.state}
      </div>
    );
  } else if (props.state) {
    locationNode = (
      <div className="mt-profile__teacher-school-detail">{props.state}</div>
    );
  }

  return (
    <div className={props.className || ''}>
      <div>
        <NavigationLink
          url={aboutLink}
          className="mt-profile__teacher-photo v5__link"
        >
          <UserPhoto src={props.photo} diameter={80} />
        </NavigationLink>
      </div>
      <div>
        <NavigationLink
          url={aboutLink}
          className="mt-profile__teacher-name v5__link"
        >
          {props.first_name} {props.last_name}
        </NavigationLink>
      </div>

      <div className="mt-profile__teacher-courses">
        {domainsNode}
        {coursesNode}
      </div>

      {schoolNode}
      {locationNode}
    </div>
  );
};

Teacher.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  photo: PropTypes.string,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  seo_url: PropTypes.string,
  domains: PropTypes.array,
  courses: PropTypes.array,
  school: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  className: PropTypes.string,
};

export default Teacher;
