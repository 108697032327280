// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const Filter = (props) => {
  // the active filter is underlined + colored blue
  const activeClass = props.active ? 'active' : '';

  // if the filter has no items associated with it, it's not clickable
  let handleClick;
  let disabledClass = 'disabled';
  let countNode;
  if (props.count) {
    handleClick = props.selectFilter;
    countNode = props.hideCount ? null : `(${props.count})`;
    disabledClass = '';
  }

  return (
    <div
      onClick={handleClick}
      className={`highlights__filter ${activeClass} ${disabledClass}`}
    >
      {props.name} {countNode}
    </div>
  );
};

Filter.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  count: PropTypes.number,
  hideCount: PropTypes.bool,
  selectFilter: PropTypes.func.isRequired,
};

export default Filter;
