// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import PropTypes from 'prop-types';

const Teacher = (props) => {
  const handlePhotoClick = () => {
    Router.route(teacherUrl);
  };

  // url for links to teacher profile
  const teacherUrl = `${process.env.REACT_APP_TEACHING_URL}/browse/master_teacher/${props.id}/${props.seo_url}/strategies/${props.domainId}?from=individual_learning_domain`;

  // show state if applicable
  let locationText = '';
  if (props.location_state) {
    locationText += props.location_state;
  }

  const locationNode = locationText ? (
    <div className="mt-dir__teacher-metadata">{locationText}</div>
  ) : null;

  let contentLinkNode;
  if (props.mt_strategy_count) {
    contentLinkNode = (
      <NavigationLink url={teacherUrl} className="tc__link">
        {props.mt_strategy_count} Strateg
        {props.mt_strategy_count === 1 ? 'y' : 'ies'}
      </NavigationLink>
    );
  }

  let experienceNode;
  if (props.experience) {
    experienceNode = (
      <div className="mt-dir__teacher-metadata">
        {props.experience} year{props.experience === 1 ? '' : 's'} experience
      </div>
    );
  }

  return (
    <div className="domain__master-teacher">
      <div
        onClick={handlePhotoClick}
        className="mt-dir__teacher-photo-container"
      >
        <UserPhoto
          src={props.photo}
          diameter={100}
          className="domain__master-teacher-photo"
        />
      </div>
      <div className="mt-dir__teacher-metadata mt-dir__teacher-metadata--name">
        <NavigationLink url={teacherUrl} className="tc__link">
          {props.name}
        </NavigationLink>
      </div>
      {locationNode}
      {experienceNode}
      <div className="mt-dir__teacher-metadata mt-dir__teacher-metadata--content-count">
        {contentLinkNode}
      </div>
    </div>
  );
};

Teacher.propTypes = {
  id: PropTypes.number.isRequired,
  seo_url: PropTypes.string,
  location_state: PropTypes.string,
  photo: PropTypes.string,
  experience: PropTypes.number,
  mt_strategy_count: PropTypes.number,
};

export default Teacher;
