// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Auth from '@legacy-components/components/Auth';
import Utils from '@legacy-components/components/DynamicUtils';
import { Router } from '@legacy-components/components/Router';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import HeaderStore from './../../../../stores/V4/HeaderStore';
import LessonLink from './../../../LessonLink';
import ScaleCenterImage from './../../../ScaleCenterImage';
import AbridgeText from './../../Common/AbridgeText';
import ExpandableList from './../../Common/ExpandableList';
import UserPhoto from './../../Common/UserPhoto';

const ResultCapsule = (props) => {
  const lab = useLegacyLabContext();
  const analyticsContext = props.searchContext || 'search_results';

  // set up url for lesson or strategy
  let contentUrl =
    process.env.REACT_APP_TEACHING_URL +
    (props.type === 'strategy'
      ? '/strategy/' + props.id
      : '/lesson/' + props.id);
  if (props.seo_url) {
    contentUrl += '/' + props.seo_url;
  }
  contentUrl += '?from=' + analyticsContext;

  const handleCapsuleClick = (e) => {
    e.preventDefault();
    if (
      props.favorite &&
      (props.favorite.status === 'loading-favorite' ||
        props.favorite.status === 'loading-unfavorite')
    ) {
      return;
    }

    // determine if free content limit is reached
    let reachedFreeContentLimit = false;
    // if strategy and strategy is not open, then check if limit is reached
    if (props.type === 'strategy' && props.publish_status !== 'open') {
      reachedFreeContentLimit = Utils.reachedFreeContentLimit.call(
        lab,
        null,
        parseInt(props.id)
      );
    } else if (props.type === 'lesson') {
      reachedFreeContentLimit = Utils.reachedFreeContentLimit.call(
        lab,
        parseInt(props.id),
        null
      );
    }

    // if the logged out user has reached the limit of 5 free content pages,
    // show the registration modal, or school solicitation modal
    if (reachedFreeContentLimit) {
      if (!props.user || (props.user && !props.user.id)) {
        HeaderStore.actionDispatch('openRegisterModal', {
          reason: 'resource_count_reached',
        });
        return;
      }
    }

    Router.route(contentUrl);
  };
  const handleOpenRecommend = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.recommend({
      itemType: props.type,
      itemId: props.id,
    });
  };

  const handleAddFilter = (e, tag, type) => {
    e.preventDefault();
    e.stopPropagation();
    props.updateFilters({
      filterId: tag.id,
      filterCategory: type,
      filterField: type,
      action: 'add',
    });
  };

  const generateTags = (tags, type) => {
    if (!tags) {
      return null;
    }
    return tags.map((tag) => {
      return (
        <div
          className="search__tag"
          title={tag.description}
          key={'result-' + props.id + '-' + type + '-tag-' + tag.id}
          onClick={(e) => {
            handleAddFilter(e, tag, type);
          }}
        >
          {tag.name}
        </div>
      );
    });
  };

  const generateSubjectIcon = () => {
    let iconClass = '';
    let colorClass = '';
    if (props.type === 'lesson') {
      colorClass = ' search__result-icon--lesson';
      // find the first relevant subject in the lesson's list
      const subject = props.subjects.find((sub) => {
        return (
          ['Science', 'Math', 'English / Language Arts'].indexOf(sub.name) !==
          -1
        );
      });
      switch (subject ? subject.name : null) {
        case 'Science':
          iconClass = ' tc-v4-science';
          break;
        case 'Math':
          iconClass = ' tc-v4-math';
          break;
        case 'English / Language Arts':
          iconClass = ' tc-v4-ela';
          break;
        default:
          iconClass = ' tc-v4-ela';
      }
    } else if (props.type === 'strategy') {
      colorClass = ' search__result-icon--strategy';
      switch (props.sub_type) {
        case 'tech tool':
          iconClass = ' tc-v4-laptop';
          break;
        default:
          iconClass = ' tc-v4-strategy';
      }
    }

    return <div className={'search__result-icon' + colorClass + iconClass} />;
  };
  const handleAddFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (
      props.user.id !== props.currentUserId &&
      Auth.hasCoachAccess(props.user)
    ) {
      return;
    }
    props.addFavorite({ itemType: props.type, itemId: props.id });
  };

  const handleRemoveFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (
      props.user.id !== props.currentUserId &&
      Auth.hasCoachAccess(props.user)
    ) {
      return;
    }
    props.removeFavorite({
      itemType: props.type,
      itemId: props.id,
      userId: props.favorite.user_id,
    });
  };
  const handleFavoriteLoadingClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const getAuthorNode = () => {
    if (props.author) {
      // set up author photo
      const authorPhotoNode = (
        <UserPhoto
          src={props.author.photo}
          className="search__owner-image"
          diameter={50}
        />
      );

      // author data depends on lesson vs strategy
      switch (props.type) {
        case 'strategy':
          return (
            <div className="search__owner search__owner--coach search__detail show-desktop hide-phone">
              {authorPhotoNode}
              <div className="search__owner-details">
                <div>{props.author.name}</div>
                <div>
                  {props.author.role == null
                    ? 'BetterLesson Instructional Coach'
                    : props.author.role}
                </div>
              </div>
            </div>
          );
        case 'lesson':
          return (
            <div className="search__owner search__owner--coach search__detail show-desktop hide-phone">
              {authorPhotoNode}
              <div className="search__owner-details">
                <div>{props.author.name}</div>
                <div>{props.author.environment} Env.</div>
              </div>
            </div>
          );
      }
    }
    // strategies without authors use this default data
    if (props.type === 'strategy') {
      return (
        <div className="search__owner search__owner--coach search__detail show-desktop hide-phone">
          <UserPhoto
            src="/legacy-images/bl-icon-circle.png"
            className="search__owner-image"
            diameter={50}
          />
          <div className="search__owner-details">BetterLesson Coaching</div>
        </div>
      );
    }
  };

  // set up strategy learning domains or lesson standards tags if necessary
  let tagsNode = null;
  let tagsHeadline = 'Focus Areas';

  if (
    ArrayHelpers.hasElements(props.focus_areas) ||
    ArrayHelpers.hasElements(props.standards)
  ) {
    const focusAreaTags = generateTags(props.focus_areas, 'focusAreas');
    const focusAreaNodes = ArrayHelpers.hasElements(focusAreaTags) ? (
      <ExpandableList
        elements={focusAreaTags}
        expandClassName="search__tag-more-link"
      />
    ) : null;
    const standardTags = generateTags(props.standards, 'standards');
    const standardNodes = ArrayHelpers.hasElements(standardTags) ? (
      <ExpandableList
        elements={standardTags}
        expandClassName="search__tag-more-link"
      />
    ) : null;

    tagsNode = (
      <div className="search__tags">
        <h5 className="search__tags-headline tc__headline-5">{tagsHeadline}</h5>
        {focusAreaNodes}
        {standardNodes}
      </div>
    );
  }

  let resultImageNode = null;
  let detailsClass = '';
  if (props.preview) {
    detailsClass = ' bl4-capsule__details--has-image';
    resultImageNode = (
      <div className="show-desktop hide-phone bl4-capsule__image-container">
        <ScaleCenterImage src={props.preview} className="bl4-capsule__image" />
      </div>
    );
  }
  let subHeadNode = null;
  let summaryDeclarationNode = null;
  if (props.type === 'lesson') {
    tagsHeadline = 'Standards';
    subHeadNode = (
      <div className="bl4-capsule__subheadline">
        <div className="bl4-capsule__subheadline-segment bl4-capsule__subheadline-segment--first">
          {props.course_name}
        </div>
        <div className="bl4-capsule__subheadline-segment">&raquo; Unit:</div>
        <div className="bl4-capsule__subheadline-segment">
          {props.unit_name}
        </div>
      </div>
    );
    summaryDeclarationNode = (
      <span className="search__summary-declaration">Big Idea:</span>
    );
  }

  // hide the content author if necessary
  const ownerNode = props.hideAuthor ? null : getAuthorNode();

  let minutesNode = null;
  if (props.type === 'lesson') {
    minutesNode = (
      <div className="search__detail search__detail--minutes">
        {props.minutes} minutes
      </div>
    );
  }
  let handleFavorteClick = handleAddFavorite;
  let favoriteIcon = (
    <div
      className="search__detail-icon search__detail-icon--heart tc-v4-heart-empty"
      alt="Add to Favorites"
      title="Add to Favorites"
    />
  );
  let coachRecomendedNode = null;
  let dissableFavClass = '';
  let capsuleFavsLoadingClass = '';
  if (props.favorite) {
    if (props.favorite.status === 'loading-favorite') {
      capsuleFavsLoadingClass = ' favs-updating';
      handleFavorteClick = handleFavoriteLoadingClick;
      dissableFavClass = ' disabled';
      favoriteIcon = (
        <div
          className="search__detail-icon search__detail-icon--heart tc-v4-heart-filled"
          alt="Remove Favorite"
          title="Remove Favorite"
        >
          loading fav
        </div>
      );
    }
    if (props.favorite.status === 'loading-unfavorite') {
      capsuleFavsLoadingClass = ' favs-updating';
      handleFavorteClick = handleFavoriteLoadingClick;
      dissableFavClass = ' disabled';
      favoriteIcon = (
        <div
          className="search__detail-icon search__detail-icon--heart tc-v4-heart-empty"
          alt="Add to Favorites"
          title="Add to Favorites"
        >
          loading unfav
        </div>
      );
    }
    if (props.favorite.status === 'recommended') {
      let coachPhotoNode = null;
      if (props.favorite.creator.photo) {
        coachPhotoNode = (
          <UserPhoto
            src={props.favorite.creator.photo}
            diameter={30}
            className="bl4__user-image-xx-small-mask"
          />
        );
      }

      coachRecomendedNode = (
        <div className="search__detail--recommended">
          <div className="search__result-recommended-image">
            {coachPhotoNode}
          </div>
          <div className="search__result-recommended-caption">
            Coach Recomended
          </div>
        </div>
      );
    }
    handleFavorteClick = handleRemoveFavorite;
    favoriteIcon = (
      <div
        className="search__detail-icon search__detail-icon--heart tc-v4-heart-filled"
        alt="Remove Favorite"
        title="Remove Favorite"
      />
    );
  }

  let favoritesNode = null;
  if (props.user) {
    const favoritesCaption =
      props.favorite_count !== 1 ? 'Favorites' : 'Favorite';

    if (
      props.user.id !== props.currentUserId &&
      Auth.hasCoachAccess(props.user)
    ) {
      dissableFavClass = ' disabled';
    }

    favoritesNode = (
      <div
        className={
          'search__detail search__detail--favorites' + dissableFavClass
        }
        onClick={handleFavorteClick}
      >
        {favoriteIcon}
        <div className="search__detail-copy search__detail-copy--favorite">
          {props.favorite_count} {favoritesCaption}
        </div>
      </div>
    );
  }

  let resourcesNode = null;
  if (props.resource_count) {
    const resourcesCaption =
      props.resource_count !== 1 ? 'Resources' : 'Resource';
    resourcesNode = (
      <div className="search__detail search__detail--resources">
        <div className="search__detail-icon tc-v4-page" />
        <div className="search__detail-copy">
          {props.resource_count} {resourcesCaption}
        </div>
      </div>
    );
  }

  let recommendNode = null;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(props.user.auth_roles, [
      'BL_CONTRACT_COACH',
    ])
  ) {
    recommendNode = (
      <a
        className="search__detail search__detail--recommend tc__link"
        onClick={handleOpenRecommend}
      >
        Recommend
      </a>
    );
  }

  let partnerDetails = {};
  if (props.focus_areas) {
    for (let i = 0; i < props.focus_areas.length; i++) {
      switch (props.focus_areas[i].id) {
        case 14:
          partnerDetails = {
            src: '/legacy-images/partners/newsela-logo.svg',
            alt: 'Newsela',
          };
          break;
        case 22:
        case 25:
          partnerDetails = {
            src: '/legacy-images/partners/kaufman.svg',
            alt: 'Kaufman',
          };
          break;
        case 24:
          partnerDetails = {
            src: '/legacy-images/partners/adobe.svg',
            alt: 'Adobe',
          };
          break;
      }
    }
  }

  let partnerNode = null;
  if (!Utils.isEmpty(partnerDetails)) {
    partnerNode = (
      <div className="search__detail search__detail--partner">
        <img src={partnerDetails.src} alt={partnerDetails.alt} />
      </div>
    );
  }

  let footerNode = null;
  let capsuleWithFooterClass = '';
  if (ownerNode || resourcesNode || favoritesNode || minutesNode) {
    footerNode = (
      <div className="bl4-capsule__footer">
        {ownerNode}
        <div className="bl4-capsule__footer-details show-desktop-flex hide-phone">
          {resourcesNode}
          {favoritesNode}
          {recommendNode}
          {minutesNode}
        </div>
        <div className="bl4-capsule__footer-details cute-12-phone show-phone hide-desktop">
          {resourcesNode}
          {minutesNode}
          {favoritesNode}
          {recommendNode}
        </div>
        {partnerNode}
      </div>
    );
    capsuleWithFooterClass = ' bl4__capsule--result-with-footer';
  }

  // set up capsule title link
  let titleNode = (
    <LessonLink
      id={'lesson-' + props.id}
      url={contentUrl}
      className="tc__link"
      lesson={props.id}
    >
      {props.title}
    </LessonLink>
  );
  if (props.type === 'strategy') {
    titleNode = (
      <LessonLink
        id={'strategy-' + props.id}
        url={contentUrl}
        className="tc__link"
        strategy={props.id}
        publishStatus={props.publish_status}
      >
        {props.title}
      </LessonLink>
    );
  }

  return (
    <div
      className={
        'bl4-result bl4__capsule bl4-capsule--hoverable bl4__capsule--result bl4-result--' +
        props.type +
        capsuleFavsLoadingClass +
        capsuleWithFooterClass
      }
      onClick={handleCapsuleClick}
    >
      <div className="bl4-capsule__content">
        <div className={'bl4-capsule__details' + detailsClass}>
          <h4 className="bl4-capsule__headline tc__headline-3">
            {generateSubjectIcon()}
            <div>
              {titleNode}
              {subHeadNode}
            </div>
          </h4>
          {coachRecomendedNode}
          <AbridgeText maxHeight={70} className="search__summary tc__body-copy">
            {summaryDeclarationNode}
            <PurifiedHTML
              className="ckeditor__output"
              content={props.description}
              tagName="span"
            />
          </AbridgeText>
          {tagsNode}
        </div>
        {resultImageNode}
        {footerNode}
      </div>
    </div>
  );
};
const WrappedResultCapsule = (props) => (
  <HeaderConsumer>
    {({ user }) => <ResultCapsule {...props} user={user} />}
  </HeaderConsumer>
);

export default WrappedResultCapsule;

ResultCapsule.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  recommend: PropTypes.func.isRequired,
  updateFilters: PropTypes.func,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  favorite: PropTypes.object,
  focus_areas: PropTypes.array,
  standards: PropTypes.array,
  preview: PropTypes.string,
  course_name: PropTypes.string,
  unit_name: PropTypes.string,
  author: PropTypes.object,
  hideAuthor: PropTypes.bool,
  user: PropTypes.object,
  resource_count: PropTypes.number,
  title: PropTypes.string.isRequired,
  searchContext: PropTypes.string.isRequired,
};
