// @ts-nocheck FIXME
import moment from 'moment';
import propTypes from 'prop-types';
import React from 'react';
import SurveysRow from './SurveysRow';

const SurveysTable = (props) => {
  // default sorting order and column if we cannot get from local storage
  const [sortDirection, setSortDirection] = React.useState('ascending');
  const [sortColumn, setSortColumn] = React.useState('date');
  const [data, setData] = React.useState(props.data);

  React.useEffect(() => {
    sortSessionsBy(sortColumn, true, props.data);
  }, [props.data]);

  // Sort order indication arrow
  let directionalArrow = (
    <span className="tc-bold-arrow coach-surveys__sort-arrow ppd-3-coach-table__arrow--up"></span>
  );

  if (sortDirection !== 'ascending') {
    directionalArrow = (
      <span className="tc-bold-arrow coach-surveys__sort-arrow ppd-3-coach-table__arrow--down"></span>
    );
  }

  // Handle sorting indication
  let sessionNameSortIndicator;
  let surveysSortIndicator;
  let dateSortIndicator;
  let productSortIndicator;
  let satisfactionSortIndicator;
  let responseSortIndicator;
  let npsSortIndicator;

  switch (sortColumn) {
    case 'session':
      sessionNameSortIndicator = directionalArrow;
      break;
    case 'surveys':
      surveysSortIndicator = directionalArrow;
      break;
    case 'date':
      dateSortIndicator = directionalArrow;
      break;
    case 'nps':
      npsSortIndicator = directionalArrow;
      break;
    case 'satisfaction':
      satisfactionSortIndicator = directionalArrow;
      break;
    case 'response':
      responseSortIndicator = directionalArrow;
      break;
    case 'product':
      productSortIndicator = directionalArrow;
      break;
  }

  // compare session start times first, then end times
  const sortSessionTimes = (a, b) => {
    const aStartTime = moment(a.session_start_time);
    const aEndTime = moment(a.session_end_time);
    const bStartTIme = moment(b.session_start_time);
    const bEndTime = moment(b.session_end_time);

    if (bStartTIme - aStartTime === 0) {
      return bEndTime - aEndTime;
    } else {
      return bStartTIme - aStartTime;
    }
  };

  // format event session for sorting comparison
  const formatEventSessionName = (
    eventName,
    sessionName,
    groupName,
    sessionNum
  ) => {
    return `${eventName} ${sessionName || ''} ${sessionNum} ${groupName || ''}`;
  };

  // sort surveys, compare completed surveys first
  // if they are the same then compare checked-in users
  const sortSurveysCount = (a, b) => {
    if (a.surveys_completed_count === b.surveys_completed_count) {
      return b.checked_in_users_count - a.checked_in_users_count;
    } else {
      return b.surveys_completed_count - a.surveys_completed_count;
    }
  };

  /**
   * sort sessions data by the given column
   * @param {string} column the column to sort
   * @param {boolean} firstSort if the data hasn't been sorted before, default false
   * @param {array} inputData the data to be sorted, default local data state
   */
  const sortSessionsBy = (column, firstSort = false, inputData = data) => {
    // Always default sort new columns ascending
    let newSortDirection = 'ascending';

    // make a copy of the original
    const newData = inputData.slice();

    // if we just need to reverse the array or actually need to sort
    if (sortColumn === column && !firstSort) {
      if (sortDirection === 'ascending') {
        newSortDirection = 'descending';
        newData.reverse();
      } else {
        newSortDirection = 'ascending';
        newData.reverse();
      }
    } else {
      // Sort the data
      switch (column) {
        case 'session':
          newData.sort((a, b) => {
            return formatEventSessionName(
              b.event_display_name || b.event_name,
              b.name,
              b.group_name,
              b.num
            )
              .toLowerCase()
              .localeCompare(
                formatEventSessionName(
                  a.event_display_name || a.event_name,
                  a.name,
                  a.group_name,
                  a.num
                ).toLowerCase()
              );
          });
          break;
        case 'product':
          newData.sort((a, b) => {
            return b.product_code
              .toLowerCase()
              .localeCompare(a.product_code.toLowerCase());
          });
          break;
        case 'date':
          newData.sort((a, b) => sortSessionTimes(a, b));
          break;
        case 'surveys':
          newData.sort((a, b) => sortSurveysCount(a, b));
          break;
        case 'nps':
          newData.sort((a, b) => b.nps - a.nps);
          break;
        case 'satisfaction':
          newData.sort((a, b) => b.satisfaction_rate - a.satisfaction_rate);
          break;
        case 'response':
          newData.sort((a, b) => b.response_rate - a.response_rate);
      }
    }

    // If this is on page load and the direction is reversed, reverse the data
    if (firstSort && sortDirection === 'descending') {
      newSortDirection = 'descending';
      newData.reverse();
    }

    setData(newData);
    setSortDirection(newSortDirection);
    setSortColumn(column);
  };

  return (
    <>
      <div className="admin-v5__coach-table">
        <div className="admin-v5__coach-table-surveys__header grid-ten__row">
          <div
            className={
              'admin-v5__coach-table-surveys__header-col grid-ten__col-2'
            }
            onClick={() => {
              sortSessionsBy('session');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sessionNameSortIndicator ? ' active' : '')
              }
            >
              Session
            </span>
            {sessionNameSortIndicator}
          </div>
          <div
            className={
              'admin-v5__coach-table-surveys__header-col grid-ten__col-1'
            }
            onClick={() => {
              sortSessionsBy('product');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (productSortIndicator ? ' active' : '')
              }
            >
              Product
            </span>
            {productSortIndicator}
          </div>

          <div
            className={
              'admin-v5__coach-table-surveys__header-col grid-ten__col-2'
            }
            onClick={() => {
              sortSessionsBy('date');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (dateSortIndicator ? ' active' : '')
              }
            >
              Date
            </span>
            {dateSortIndicator}
          </div>

          <div
            className={
              'admin-v5__coach-table-surveys__header-col grid-ten__col-1'
            }
            onClick={() => {
              sortSessionsBy('surveys');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (surveysSortIndicator ? ' active' : '')
              }
            >
              Surveys
            </span>
            {surveysSortIndicator}
          </div>

          <div
            className={
              'admin-v5__coach-table-surveys__header-col grid-ten__col-1'
            }
            onClick={() => {
              sortSessionsBy('response');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (responseSortIndicator ? ' active' : '')
              }
            >
              Response %
            </span>
            {responseSortIndicator}
          </div>

          <div
            className={
              'admin-v5__coach-table-surveys__header-col grid-ten__col-1'
            }
            onClick={() => {
              sortSessionsBy('nps');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (npsSortIndicator ? ' active' : '')
              }
            >
              NPS
            </span>
            {npsSortIndicator}
          </div>

          <div
            className={
              'admin-v5__coach-table-surveys__header-col grid-ten__col-1'
            }
            onClick={() => {
              sortSessionsBy('satisfaction');
            }}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (satisfactionSortIndicator ? ' active' : '')
              }
            >
              Satisfaction %
            </span>
            {satisfactionSortIndicator}
          </div>
          <div
            className={
              'admin-v5__coach-table-surveys__header-col grid-ten__col-1'
            }
          ></div>
        </div>
      </div>

      <div className="admin-v5__coach-table-surveys">
        {data.map((session) => {
          return <SurveysRow key={`row-${session.session_id}`} {...session} />;
        })}
      </div>
    </>
  );
};

SurveysTable.propTypes = {
  data: propTypes.array.isRequired,
};

export default SurveysTable;
