// @ts-nocheck FIXME
import AdminApi from '@legacy-components/apis/V5/AdminApi';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Auth from '@legacy-components/components/Auth';
import Utils from '@legacy-components/components/DynamicUtils';
import StorageHelper from '@legacy-components/components/StorageHelper';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import NavigationLink from '@legacy-components/react/NavigationLink';
import FormField from '@legacy-components/react/V5/Common/FormField';
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import React from 'react';
import UsersTable from './modules/UsersTable';

const AdminUsers = (props) => {
  // auth role filter options - by default we exclude internal BL user roles
  let roleOptions = [
    { id: 'TC_TEACHER', label: '(P) Participant', shortLabel: '(P)' },
    { id: 'TC_PARTNER', label: '(L) Partner Leader', shortLabel: '(L)' },
    { id: 'BL_CONTRACT_COACH', label: '(C) Coach', shortLabel: '(C)' },
    {
      id: 'HELP_DESK_AGENT',
      label: '(HD) Help Desk Agent',
      shortLabel: '(HD)',
    },
  ];

  // anyone who has access to this page can see internal users in the search results,
  // unless they are only a Help Desk Agent
  const hasNonAgentRole = Utils.arrayContainsAnyItemInAnotherArray(
    props.user.auth_roles,
    ['ADMIN', 'BL_MANAGER', 'BL_SERVICES_MANAGER', 'BL_TEAM_MEMBER']
  );
  if (hasNonAgentRole) {
    roleOptions = [
      { id: 'TC_TEACHER', label: '(P) Participant', shortLabel: '(P)' },
      { id: 'TC_PARTNER', label: '(L) Partner Leader', shortLabel: '(L)' },
      { id: 'BL_CONTRACT_COACH', label: '(C) Coach', shortLabel: '(C)' },
      {
        id: 'BL_SERVICES_MANAGER',
        label: '(SM) Services Manager',
        shortLabel: '(SM)',
      },
      {
        id: 'TC_CONTENT_CURATOR',
        label: '(CC) Content Curator',
        shortLabel: '(CC)',
      },
      { id: 'BL_MANAGER', label: '(UM) User Manager', shortLabel: '(UM)' },
      {
        id: 'HELP_DESK_AGENT',
        label: '(HD) Help Desk Agent',
        shortLabel: '(HD)',
      },
      { id: 'BL_TEAM_MEMBER', label: '(DV) Data Viewer', shortLabel: '(DV)' },
      { id: 'ADMIN', label: '(SA) Super Admin', shortLabel: '(SA)' },
    ];
  }

  // in some cases we delay the user search api call
  const searchTimeout = React.useRef(null);

  // alert for copy email success message
  const [alert, setAlert] = React.useState();

  // auth roles selected in the multiselect
  const [roles, setRoles] = React.useState(() => {
    if (ArrayHelpers.hasElements(props.roles)) {
      return props.roles.map((role) => {
        return roleOptions.find((op) => op.id === role);
      });
    } else {
      return [];
    }
  });

  // table sort field and direction
  const [sortField, setSortField] = React.useState(props.sortField);
  const [sortDir, setSortDir] = React.useState(props.sortDir);

  // the list of users resulting from the search
  const [searchResults, setSearchResults] = React.useState(props.searchResults);
  // show the search failed component if ES fails
  const [searchFailed, setSearchFailed] = React.useState(props.searchFailed);

  React.useEffect(() => {
    // This will get rid of the timeout on cleanup
    return () => {
      clearTimeout(searchTimeout.current);
    };
  }, []);

  // The cookie is *not* used for page state -
  // it is used to tell the server what the search filters + sort are,
  // and to make sure that the search is saved for 30 min.
  //
  // The only reason to read cookie data is to see what the previous search was.
  const getCookieData = (key) => {
    const searchData = StorageHelper.getCookieItem('admin_users_search');
    if (!searchData) {
      return null;
    }
    return searchData[key];
  };

  const updateCookie = (cookieData) => {
    // we only add to the existing cookie
    if (!cookieData || !Object.keys(cookieData)) {
      return;
    }

    // if there is already a search data cookie,
    // update the existing data with the new data
    const searchData = StorageHelper.getCookieItem('admin_users_search');
    if (searchData) {
      cookieData = Object.assign(searchData, cookieData);
    }
    StorageHelper.setCookieItem('admin_users_search', cookieData, {
      expirationHours: 0.5,
    });
  };

  const handleSearch = () => {
    // start progress bar
    NProgress.start();

    AdminApi.getUsers()
      .progress((e) => {
        // update progress bar
        NProgress.set(e.loaded / e.total);
      })
      .then((response) => {
        if (response.searchFailed) {
          setSearchResults([]);
          setSearchFailed(true);
        } else {
          setSearchResults(response.searchResults);
        }
        // close progress bar
        NProgress.done();
      })
      .catch(() => {
        setSearchResults([]);
        setSearchFailed(true);
        // close progress bar
        NProgress.done();
      });
  };

  const handleTyping = (e) => {
    e.preventDefault();

    clearTimeout(searchTimeout.current);

    // check if we don't need to run a new search
    const searchTerm = getCookieData('searchTerm');
    const newSearchTerm = e.target.value ? e.target.value.trim() : null;
    if (!searchTerm && (!newSearchTerm || newSearchTerm.length <= 1)) {
      return;
    }
    if (searchTerm === newSearchTerm) {
      return;
    }

    // run a delayed search to let the user finish typing
    searchTimeout.current = setTimeout(() => {
      const term =
        newSearchTerm && newSearchTerm.trim().length > 1
          ? newSearchTerm.trim()
          : null;
      updateCookie({ searchTerm: term });

      handleSearch();
    }, 500);
  };

  const updateRoles = (selectedOption) => {
    const newRoles = Utils.clone(roles);
    // check if this role is already selected
    const optionIndex = newRoles.findIndex((op) => op.id === selectedOption.id);
    // either remove or add the role as necessary
    if (optionIndex !== -1) {
      newRoles.splice(optionIndex, 1);
    } else {
      newRoles.push(selectedOption);
    }
    // update the state
    setRoles(newRoles);
  };

  const handleChangeRoles = () => {
    // only run the search on blur of the multiselect
    clearTimeout(searchTimeout.current);

    // check if we don't need to run a new search
    const oldRoles = getCookieData('roles');
    const newRoles = roles.map((role) => role.id);
    if (oldRoles === newRoles) {
      return;
    }

    // delay the search in case there are multiple changes
    searchTimeout.current = setTimeout(() => {
      updateCookie({ roles: newRoles });
      handleSearch();
    }, 500);
  };

  const toggleFreeUsers = (e) => {
    clearTimeout(searchTimeout.current);
    updateCookie({ free: e.target.checked });
    handleSearch();
  };

  const toggleInactiveUsers = (e) => {
    clearTimeout(searchTimeout.current);
    updateCookie({ inactive: e.target.checked });
    handleSearch();
  };

  const toggleDisabledUsers = (e) => {
    clearTimeout(searchTimeout.current);
    updateCookie({ disabled: e.target.checked });
    handleSearch();
  };

  const handleSort = (field) => {
    // only flip the direction if we are not changing the field
    let dir = 'asc';
    if (field === sortField) {
      dir = sortDir === 'asc' ? 'desc' : 'asc';
    }
    updateCookie({ sortField: field, sortDir: dir });
    setSortField(field);
    setSortDir(dir);

    handleSearch();
  };

  // set up alert banner if necessary
  let alertNode;
  if (alert && Object.keys(alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // set up link to Create User page if the current user has permission
  const createUserNode = Auth.canCreateUsers(props.user) && (
    <NavigationLink url="/bl/user/create" className="v5__link">
      <span className="tc-v5-add-user admin-users__add-user-icon"></span>
      Add User
    </NavigationLink>
  );

  return (
    <div className="v5__body-copy">
      <Header page="AdminUsers" {...props} />
      {alertNode}

      <div className="row row--1200">
        <div className="cute-12-desktop">
          <h1 className="v5__heading-3 admin-users__heading">Users</h1>
        </div>
      </div>

      <div className="row row--1200">
        <div className="cute-4-desktop">
          <div className="admin-users__search-term">
            <FormField
              type="text"
              maxLength={100}
              placeholder="Search by name or email"
              defaultValue={props.searchTerm}
              onChange={handleTyping}
              className="admin-users__search-term-input"
            />
            <div className="tc-search admin-users__search-icon"></div>
          </div>
        </div>
        <div className="cute-4-desktop">
          <div className="admin-users__role-filter">
            <label className="admin-users__role-label">Role:</label>
            <MultiSelect
              placeholder="All"
              handleSelectOption={updateRoles}
              selectedOptions={roles}
              dropdownOptions={roleOptions}
              handleBlur={handleChangeRoles}
              minCharCountForResults={0}
              stayOpenOnSelect={false}
              preventAutoFocus={true}
              shortenLabels={true}
              absolutePosition={true}
              className="admin-users__role-input"
            />
          </div>
        </div>
        <div className="cute-4-desktop admin-users__right-align">
          {createUserNode}
        </div>
      </div>

      <div className="row row--1200">
        <div className="cute-12-desktop">
          <div className="admin-users__search-filters-row">
            <div>Include:</div>
            <div className="admin-users__search-filter">
              <input
                type="checkbox"
                id="free-users"
                defaultChecked={props.includeFreeUsers}
                onChange={toggleFreeUsers}
                className="v5__checkbox"
              />
              <label
                htmlFor="free-users"
                className="admin-users__search-filter-label"
              >
                Free Users
              </label>
            </div>
            <div className="admin-users__search-filter">
              <input
                type="checkbox"
                id="inactive-users"
                defaultChecked={props.includeInactiveUsers}
                onChange={toggleInactiveUsers}
                className="v5__checkbox"
              />
              <label
                htmlFor="inactive-users"
                className="admin-users__search-filter-label"
              >
                Inactive 2+ years
              </label>
            </div>
            <div className="admin-users__search-filter">
              <input
                type="checkbox"
                id="disabled-users"
                defaultChecked={props.includeDisabledUsers}
                onChange={toggleDisabledUsers}
                className="v5__checkbox"
              />
              <label
                htmlFor="disabled-users"
                className="admin-users__search-filter-label"
              >
                Disabled
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row row--1200">
        <div className="cute-12-desktop">
          <UsersTable
            user={props.user}
            sortField={sortField}
            sortDir={sortDir}
            handleSort={handleSort}
            searchFailed={searchFailed}
            searchResults={searchResults}
            setAlert={setAlert}
          />
        </div>
      </div>
    </div>
  );
};

AdminUsers.propTypes = {
  searchResults: PropTypes.array.isRequired,
  searchTerm: PropTypes.string,
  roles: PropTypes.array,
  sortField: PropTypes.string,
  sortDir: PropTypes.string,
  includeFreeUsers: PropTypes.bool,
  includeInactiveUsers: PropTypes.bool,
  includeDisabledUsers: PropTypes.bool,
};

export default AdminUsers;
