// @ts-nocheck FIXME
import Content from '@legacy-components/components/Content';
import Utils from '@legacy-components/components/StaticUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';

class Challenge extends React.Component {
  render() {
    // set up metric high lighting and customizaton
    let metricName;
    if (this.props.metric) {
      metricName = Content.setupMetric(
        this.props.metric.name,
        Object.keys(this.props.termDefinitions),
        this.props.challengeInstance.defined_n
      );
    }

    // set up definitions
    const definitions = [];
    let definitionsNode = null;
    if (this.props.termDefinitions && this.props.termDefinitions.length) {
      for (const key in this.props.termDefinitions) {
        definitions.push(
          <div className="portfolio__definition" key={'definition-' + key}>
            <span className="portfolio__definition-declaration">{key}</span>
            <span>- {this.props.termDefinitions[key]}</span>
          </div>
        );
      }
      definitionsNode = (
        <div className="portfolio__definitions">
          <div className="portfolio__declaration">Definitions</div>
          <div className="">{definitions}</div>
        </div>
      );
    }

    // set up frameworks nodes
    let danielsonNode = null;
    let danielsonFrameworksNode = null;
    if (this.props.challenge.danielsonComponents.length) {
      danielsonNode = this.props.challenge.danielsonComponents.map(function (
        standard
      ) {
        return (
          <div className="portfolio__standard" key={standard.identifier}>
            {standard.identifier} - {standard.name}
          </div>
        );
      });
      danielsonFrameworksNode = (
        <div className="portfolio__challenge-standard">
          <div className="portfolio__declaration">DANIELSON COMPONENTS:</div>
          <div className="portfolio__challenge-standards-list">
            {danielsonNode}
          </div>
        </div>
      );
    }

    // set up commoncore nodes
    let coresNode = null;
    let coresFrameworksNode = null;
    if (this.props.challenge.coreStandards.length) {
      coresNode = this.props.challenge.coreStandards.map(function (standard) {
        return (
          <div className="portfolio__standard" key={standard.identifier}>
            {standard.identifier} - {standard.description}
          </div>
        );
      });
      coresFrameworksNode = (
        <div className="portfolio__challenge-standard">
          <div className="portfolio__declaration">COMMON CORE:</div>
          <div className="portfolio__challenge-standards-list">{coresNode}</div>
        </div>
      );
    }

    // set up next gen science standards node
    let ngssNode = null;
    if (this.props.challenge.ngss && this.props.challenge.ngss.length) {
      const ngss = this.props.challenge.ngss.map(function (standard) {
        return (
          <div className="portfolio__standard" key={standard.identifier}>
            {standard.identifier} - {standard.description}
          </div>
        );
      });
      ngssNode = (
        <div className="portfolio__challenge-standard">
          <div className="portfolio__declaration">NEXT GEN SCIENCE:</div>
          <div className="portfolio__challenge-standards-list">{ngss}</div>
        </div>
      );
    }

    // set up visual students impacted icons
    let studentsImpactedNode = null;

    if (
      this.props.challengeInstance.studentsImpacted &&
      this.props.challengeInstance.studentsImpacted > 0
    ) {
      // arrays for color and gender
      const studentGenderSuffixArray = ['f', 'm'];
      const studentColorSuffix = ['r', 'b', 'g'];
      // empty array for the icons
      const studentsImpactedIcons = [];
      // set a random gender and color and push the element to the array
      for (let i = 0; i < this.props.challengeInstance.studentsImpacted; i++) {
        const gender =
          studentGenderSuffixArray[
            Math.floor(Math.random() * studentGenderSuffixArray.length)
          ];
        const color =
          studentColorSuffix[
            Math.floor(Math.random() * studentColorSuffix.length)
          ];
        // if its going to be more than two lines tell how many  more and escape the loop
        if (i > 13) {
          const manyMore = this.props.challengeInstance.studentsImpacted - i;
          if (manyMore > 1) {
            studentsImpactedIcons.push(
              <div
                key={'student-more'}
                className="students-impacted__more students-impacted__more--portfolio"
              >
                {' '}
                and {Utils.numberWithCommas(manyMore)} more.
              </div>
            );
            break;
          }
        }
        studentsImpactedIcons.push(
          <div
            key={'student-icon-' + i}
            className={
              'students-impacted__icon students-impacted__icon--portfolio-challenge students-impacted__icon--' +
              color +
              ' tc-student-' +
              gender
            }
          ></div>
        );
      }
      // set up the new node for the dom
      studentsImpactedNode = (
        <div className="portfolio__challenge-impact">
          <span className="portfolio__declaration portfolio__declaration--challenge-impact">
            Students Impacted:
          </span>
          <span className="portfolio__challenge-impact-count">
            {this.props.challengeInstance.studentsImpacted}
          </span>
          <div className="portfolio__challenge-students-impacted">
            {studentsImpactedIcons}
          </div>
        </div>
      );
    }

    return (
      <div className="portfolio__challenge">
        <div className="portfolio__challenge-header">
          <h2 className="portfolio__challenge-page-headline">
            Classroom Challenge
          </h2>
          <div className="portfolio__challenge-question">
            {this.props.challenge.name}
          </div>
          <div className="portfolio_challenge-details">
            <div className="portfolio__challenge-icon">
              <div className={'tc-sga-' + this.props.challenge.sgaId}></div>
            </div>
            <div className="portfolio__challenge-copy">
              <div className="portfolio__challenge-subject-headline">
                area of student growth
              </div>
              <div className="portfolio__challenge-subject">
                {this.props.challenge.subject}
              </div>
              <div className="portfolio__challenge-sga">
                {this.props.challenge.sga}
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio__challenge-standards">
          {danielsonFrameworksNode}
          {coresFrameworksNode}
          {ngssNode}

          <div className="portfolio__challenge-strategies">
            <span className="portfolio__declaration">Strategies:</span>
            <span>{this.props.challengeInstance.strategyCount}</span>
          </div>
          {studentsImpactedNode}
        </div>
        <div className="portfolio__challenge-metric">
          <div className="portfolio__challenge-metric-data">
            <div className="portfolio__declaration">Success Indicator</div>
            <PurifiedHTML
              className="portfolio__challenge-metric-text"
              content={metricName}
            />
            {definitionsNode}
          </div>
        </div>
      </div>
    );
  }
}

export default Challenge;
