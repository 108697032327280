// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import Modal from './../../Common/Modal';
import Spinner from './../../Common/Spinner';

const ConfirmDisconnectZoom = (props) => {
  // track loading state for Disconnect button
  const [isLoading, setIsLoading] = useState(false);

  const handleDisconnect = (e) => {
    e.preventDefault();

    setIsLoading(true);
    props.disconnectZoom();
  };

  // disable the button when loading
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <Modal close={props.close}>
      <form>
        <div className="v5__heading-3">Disconnect Meeting with Zoom?</div>

        <div className="v5__body-copy workspace__form-row">
          You will not be able to reconnect this meeting to Zoom once it has
          been disconnected.
        </div>

        <div className="workspace__form-row">
          <button
            type="button"
            onClick={handleDisconnect}
            className="v5__btn v5__btn--red"
            {...btnProps}
          >
            <Spinner loading={isLoading} />
            Disconnect
          </button>
        </div>
        <div className="workspace__form-row">
          <button
            type="button"
            onClick={props.close}
            className="v5__link v5__link--grey"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

ConfirmDisconnectZoom.propTypes = {
  disconnectZoom: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default ConfirmDisconnectZoom;
