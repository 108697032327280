// @ts-nocheck FIXME
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import { forwardRef, useImperativeHandle, useRef } from 'react';

/*
This component is for supporting form fields with error styles.

Currently only supports types: text, number, email, password, textarea, select
*/
let FormField = (props, ref) => {
  // set up any default values for props
  const type = props.type || 'text';
  const containerClassName = props.className || '';
  const inputClassName = props.inputClassName || '';

  // used for ref forwarding
  const inputRef = useRef();
  const focusRef = () => {
    inputRef.current.focus();
  };
  const selectRef = () => {
    inputRef.current.select();
  };
  useImperativeHandle(ref, () => ({
    focus: focusRef,
    select: selectRef,
  }));

  // set up any input props passed in
  const inputProps = {};
  if (props.rows) {
    inputProps.rows = props.rows;
  }
  if (props.placeholder) {
    inputProps.placeholder = props.placeholder;
  }
  if (props.defaultValue) {
    inputProps.defaultValue = props.defaultValue;
  }
  if (props.autoFocus) {
    inputProps.autoFocus = props.autoFocus;
  }
  // here, we want to make sure a controlled input does not lose the "value" prop
  // and become an uncontrolled input, this will occur if value is falsy
  // (such as if value is ''), which means if (props.value) will fail
  if (props.isControlled || props.value) {
    inputProps.value = props.value;
  }
  if (props.disabled) {
    inputProps.disabled = props.disabled;
  }
  if (props.onFocus) {
    inputProps.onFocus = props.onFocus;
  }
  if (props.onBlur) {
    inputProps.onBlur = props.onBlur;
  }
  if (props.onKeyPress) {
    inputProps.onKeyPress = props.onKeyPress;
  }
  if (props.onChange) {
    inputProps.onChange = props.onChange;
  }
  if (props.maxLength) {
    inputProps.maxLength = props.maxLength;
  }
  if (props.min) {
    inputProps.min = props.min;
  }
  if (props.max) {
    inputProps.max = props.max;
  }
  if (props.pattern) {
    inputProps.pattern = props.pattern;
  }
  if (props.id) {
    inputProps.id = props.id;
  }
  if (props.autoComplete) {
    inputProps.autoComplete = props.autoComplete;
  }
  if (props.formRef) {
    inputProps.ref = props.formRef;
  }
  if (props.dataTestId) {
    inputProps['data-testid'] = props.dataTestId;
  }
  if (props['data-testid']) {
    inputProps['data-testid'] = props['data-testid'];
  }
  if (props.onKeyDown) {
    inputProps.onKeyDown = props.onKeyDown;
  }
  if (props.onClick) {
    inputProps.onClick = props.onClick;
  }
  if (props.readOnly) {
    inputProps.readOnly = props.readOnly;
  }

  // set up any field error
  let fieldClassName = `v5__input ${inputClassName}`;
  let errorNode;
  if (props.error || props.hasError) {
    fieldClassName += ' error';
    errorNode = <ErrorText {...props} />;
  }

  // set up the input based on the type passed in
  let inputNode;
  switch (type) {
    case 'text':
    case 'email':
    case 'password':
      inputNode = (
        <input
          ref={inputRef}
          type={type}
          maxLength="1000"
          className={fieldClassName}
          {...inputProps}
        />
      );
      break;
    case 'number':
      inputNode = (
        <input
          ref={inputRef}
          type="number"
          className={fieldClassName}
          {...inputProps}
        />
      );
      break;
    case 'date':
      inputNode = (
        <input type={props.type} className={fieldClassName} {...inputProps} />
      );
      break;
    case 'time':
      inputNode = (
        <input type={props.type} className={fieldClassName} {...inputProps} />
      );
      break;
    case 'textarea':
      inputNode = (
        <textarea
          ref={inputRef}
          maxLength="65535"
          className={fieldClassName}
          {...inputProps}
        />
      );
      break;
    case 'select':
      inputNode = (
        <select
          ref={inputRef}
          className={`v5__select ${fieldClassName}`}
          {...inputProps}
        >
          {props.children}
        </select>
      );
      break;
    default:
      // if it's not a valid input type, just return
      return null;
  }

  return (
    <div className={`form-field ${containerClassName}`}>
      {inputNode}
      {errorNode}
    </div>
  );
};

// Since forwardRef prevents these from working, these are now informational only:

// FormField.propTypes = {
//     type: PropTypes.string.isRequired,
//     className: PropTypes.string,
//     inputClassName: PropTypes.string,
//     rows: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.number
//     ]),
//     placeholder: PropTypes.string,
//     isControlled: PropTypes.bool,
//     value: PropTypes.any,
//     disabled: PropTypes.bool,
//     onFocus: PropTypes.func,
//     onBlur: PropTypes.func,
//     onChange: PropTypes.func,
//     maxLength: PropTypes.number,
//     min: PropTypes.number,
//     max: PropTypes.number,
//     error: PropTypes.string
// };
//
// FormField.defaultProps = {
//     type: 'text',
//     className: '',
//     inputClassName: ''
// };

FormField = forwardRef(FormField);

export default FormField;
