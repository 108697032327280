// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';
import UserRow from './UserRow';

const UsersTable = (props) => {
  // when ES search is down, we show a graphic and link to the old user search
  if (props.searchFailed) {
    const oldLink = (
      <NavigationLink url="/bl/legacy/users" className="v5__link">
        old user search page
      </NavigationLink>
    );
    return (
      <div className="admin-users__search-failed">
        <img
          src="/legacy-images/404-pets.png"
          className="code-404__img-overview"
        />
        <div>
          <div className="v5__heading-4 admin-users__search-failed-heading">
            Oops! Something is broken.
          </div>
          <div className="v5__comment-copy">
            <div>User data is temporarily unavailable. (We blame the cat.)</div>
            <div>Thanks for your patience as we tidy up this issue.</div>
            <div className="admin-users__search-failed-link">
              In the meantime, try our {oldLink}.
            </div>
          </div>
        </div>
      </div>
    );
  }

  // set up the arrow based on sort direction
  const arrowClass = props.sortDir === 'asc' ? '' : ' up';
  const arrowNode = (
    <div
      className={`tc-bold-arrow admin-users__sort-order-arrow${arrowClass}`}
    ></div>
  );

  let nameColClass,
    eventColClass,
    meetingColClass,
    loginColClass = '';
  let nameArrowNode, eventArrowNode, meetingArrowNode, loginArrowNode;
  switch (props.sortField) {
    case 'last_name':
      nameColClass = 'active';
      nameArrowNode = arrowNode;
      break;
    case 'next_event.session_start_time':
      eventColClass = 'active';
      eventArrowNode = arrowNode;
      break;
    case 'next_meeting':
      meetingColClass = 'active';
      meetingArrowNode = arrowNode;
      break;
    case 'last_login':
      loginColClass = 'active';
      loginArrowNode = arrowNode;
      break;
  }

  return (
    <table className="admin-users__table">
      <thead className="admin-users__table-header">
        <tr>
          <th className="admin-users__table-header-cell wide">
            <div
              onClick={() => props.handleSort('last_name')}
              className={`admin-users__table-header-cell-text clickable ${nameColClass}`}
            >
              Name {nameArrowNode}
            </div>
          </th>
          <th className="admin-users__table-header-cell admin-users__center-align">
            <div className="admin-users__table-header-cell-text">Role</div>
          </th>
          <th className="admin-users__table-header-cell wide">
            <div
              onClick={() => props.handleSort('next_event.session_start_time')}
              className={`admin-users__table-header-cell-text clickable ${eventColClass}`}
            >
              Next Event {eventArrowNode}
            </div>
          </th>
          <th className="admin-users__table-header-cell mid">
            <div
              onClick={() => props.handleSort('next_meeting')}
              className={`admin-users__table-header-cell-text clickable ${meetingColClass}`}
            >
              Next Meeting {meetingArrowNode}
            </div>
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {props.searchResults.map((user) => {
          return (
            <UserRow
              key={user.id}
              currentUser={props.user}
              {...user}
              setAlert={props.setAlert}
            />
          );
        })}
      </tbody>
    </table>
  );
};

UsersTable.propTypes = {
  sortField: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
  searchFailed: PropTypes.bool,
  searchResults: PropTypes.array.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default UsersTable;
