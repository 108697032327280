// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import React from 'react';
import NavigationLink from './../../../NavigationLink';

class CoreCrumb extends React.Component {
  constructor(props) {
    super(props);
    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.state = {
      dropdownOpened: false,
    };
  }
  openDropdown() {
    this.setState({
      dropdownOpened: true,
    });

    delayedEventListener(() =>
      document.addEventListener('click', this.closeDropdown)
    );
  }
  closeDropdown() {
    this.setState({
      dropdownOpened: false,
    });
    document.removeEventListener('click', this.closeDropdown);
  }
  render() {
    const coreDropdownItems = this.props.core.CommonCore.children.map(
      (item) => {
        const selectedClass = item.CommonCore.selected ? 'selected' : '';
        return (
          <li
            className={selectedClass}
            key={'core-child-dropdown-' + item.CommonCore.id}
          >
            <NavigationLink
              url={
                process.env.REACT_APP_TEACHING_URL +
                '/browse/common_core/standard/' +
                item.CommonCore.id +
                '/' +
                item.CommonCore.seo_url +
                '?from=breadcrumb_standard_level2'
              }
            >
              <span>
                <div className="search__tag">{item.CommonCore.identifier}</div>{' '}
                {item.CommonCore.description}
              </span>
            </NavigationLink>
          </li>
        );
      }
    );
    const coreDropdownClass = this.state.dropdownOpened ? ' active' : '';
    const coreDropdownNode = (
      <div className={'mtp-lesson__crumb-dropdown' + coreDropdownClass}>
        <ul>{coreDropdownItems}</ul>
      </div>
    );

    const borderNode =
      this.props.increment + 1 < this.props.crumbCount ? (
        <div className="mtp-lesson__crumb-border"></div>
      ) : null;

    return (
      <div className="mtp-lesson__crumb">
        <a
          onClick={(e) => {
            e.preventDefault();
            this.openDropdown();
          }}
        >
          <span className="mtp-lesson__crumb-caption">
            {this.props.core.CommonCore.identifier}
          </span>
          <span className="mtp-lesson__crumb-icon tc-bold-arrow"></span>
        </a>
        {coreDropdownNode}
        {borderNode}
      </div>
    );
  }
}
export default CoreCrumb;
