// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const TeacherView = (props) => {
  const handleClick = (e) => {
    e.stopPropagation();
  };

  const handleToggleTeacherView = (e) => {
    const isChecked = e.target.checked;
    props.submitTeacherViewModal(isChecked);
  };

  let viewLabel = 'Coach';
  if (props.ui.teacherView) {
    viewLabel = 'Teacher';
  }

  return (
    <div className="header__admin-switch" onClick={handleClick}>
      <input
        type="checkbox"
        id="teacherView"
        checked={props.ui.teacherView}
        onChange={handleToggleTeacherView}
        className="ppd-v3-curation__switch-input"
      />

      <label
        htmlFor="teacherView"
        className="ppd-v3-curation__inline-label ppd-v3-curation__switch-label ppd-v3-curation__switch-label--header"
      >
        <div className="ppd-v3-curation__switch ppd-v3-curation__switch--header ppd-v3-curation__switch--has-label">
          <span className="ppd-v3-curation__switch-btn"></span>
        </div>
        <div className="ppd-v3-curation__switch-caption">{viewLabel}</div>
      </label>
    </div>
  );
};

TeacherView.propTypes = {
  ui: PropTypes.object.isRequired,
  submitTeacherViewModal: PropTypes.func,
};

export default TeacherView;
