// @ts-nocheck FIXME
import ResourceApi from '@legacy-components/apis/ResourceApi';
import CurationApi from '@legacy-components/apis/V4/CurationApi';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import moment from 'moment';
import PropTypes from 'prop-types';
import CKEditor from './../Common/CKEditor/CKEditor';
import SingleSelect from './../Common/SingleSelect';
import Spinner from './../Common/Spinner';
import SortableItems from './Common/SortableItems';
import ContentResource from './ContentResource';
import ContentResourceReplacePreview from './ContentResourceReplacePreview';
import ContentResourceReplacementPreviewFile from './ContentResourceReplacementPreviewFile';

const ContentBlock = (props) => {
  let title = null;
  let duration = null;
  let link = null;

  const handleAddContentBlock = (e) => {
    e.preventDefault();
    // set up any author info
    let authorId = null;
    let authorRole = null;
    if (props.section.author) {
      authorId = props.section.author.id;
      authorRole = props.section.author.role;
    }

    const data = {
      sectionId: props.section.id,
      type: 'text',
      title: title.value,
      description:
        CKEDITOR.instances[
          'outcome-story__text-wysiwyg-contentBlockTextEntry-0'
        ].getData(),
      authorId: authorId,
      authorRole: authorRole,
      duration: duration.value,
      strategyId: props.section.strategy_id,
    };
    props.saveContentBlock(data);
  };

  const handleUpdateSectionAuthorRole = (e) => {
    e.preventDefault();

    if (e.target && e.target.options) {
      const data = {
        role: e.target.options[e.target.options.selectedIndex].text,
        index: e.target.dataset.index,
      };
      props.updateSectionAuthorRole(data);
    }
  };

  const handleShowResourceMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.toggleAddResourceMenu();
  };

  const handleUpdateSectionOtherRole = (e) => {
    e.preventDefault();

    const data = {
      role: e.target.value,
      index: e.target.dataset.index,
    };
    props.updateSectionAuthorRole(data);
  };

  const handleUpdateSectionUpdateField = (e) => {
    e.preventDefault();

    const data = {
      value: e.target.value,
      field: e.target.dataset.field,
      sectionIndex: e.target.dataset.sectionindex,
      resourceIndex: e.target.dataset.resourceindex,
    };

    props.updateSectionResourceField(data);
  };

  const handleAddSectionAuthor = (e) => {
    e.preventDefault();
    props.showAddSectionAuthor(props.index);
  };

  const handleEditContentBlock = (e) => {
    e.preventDefault();

    const data = {
      sectionId: props.section.id,
      author: props.section.author,
    };
    props.editContentBlock(data);
  };

  const handleDeleteContentBlock = (e) => {
    e.preventDefault();

    const data = {
      sectionId: props.section.id,
      index: props.index,
      title: props.section.title,
    };
    if (data.title === undefined) {
      data.customBodyText = 'Delete this section';
    }
    props.ui.editStrategy.confirmDeleteSectionModal.open(data);
  };

  const handleDeleteContentBlockResource = (e) => {
    e.preventDefault();
    const title =
      props.strategy.sections[e.target.dataset.sectionindex].resources[
        e.target.dataset.resourceindex
      ].title;
    const resourceId =
      props.strategy.sections[e.target.dataset.sectionindex].resources[
        e.target.dataset.resourceindex
      ].id;
    const data = {
      sectionIndex: e.target.dataset.sectionindex,
      resourceId: resourceId,
      resourceIndex: e.target.dataset.resourceindex,
      title: title ? title : 'resource',
    };

    props.ui.editStrategy.confirmDeleteResourceModal.open(data);
  };

  const handleMoveUp = (e) => {
    e.preventDefault();
    const data = {
      sectionId: props.section.id,
      direction: 'up',
      description:
        CKEDITOR.instances[
          'outcome-story__text-wysiwyg-contentBlockTextEntry-0'
        ].getData(),
    };
    props.moveContentBlock(data);
  };

  const handleMoveDown = (e) => {
    e.preventDefault();
    const data = {
      sectionId: props.section.id,
      direction: 'down',
      description:
        CKEDITOR.instances[
          'outcome-story__text-wysiwyg-contentBlockTextEntry-0'
        ].getData(),
    };
    props.moveContentBlock(data);
  };

  const handleToggleAddLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.toggleAddLink();
  };

  const handleAddResource = (e) => {
    e.preventDefault();
    document.getElementById('addFileResource').click();
  };

  const handleAddLinkResource = (e) => {
    e.preventDefault();
    const data = {
      type: 'text',
      url: link.value,
      sectionId: props.section.id,
    };
    props.addLinkResource(data);
  };

  const sortCallbackSectionResources = (elementIds) => {
    const data = { sectionIndex: props.index, resourceIds: elementIds };
    props.sortSectionResources(data);
  };

  const handleCloseContentBlock = (e) => {
    e.preventDefault();
    if (props.ui.addContentBlock) {
      props.handleDeleteStrategySection({
        sectionId: props.section.id,
        index: props.index,
      });
    } else {
      props.closeOpenedContentBlock();
    }
  };

  // if this is the first section, grey out the up arrow and make it unclickable
  let upArrowClass = '';
  let handleUp = handleMoveUp;
  if (
    props.strategy.sections &&
    props.strategy.sections[0].id === props.section.id
  ) {
    upArrowClass = ' disabled';
    handleUp = null;
  }

  // if this is the last section, grey out the down arrow and make it unclickable
  let downArrowClass = '';
  let handleDown = handleMoveDown;
  if (
    props.strategy.sections &&
    props.strategy.sections[props.strategy.sections.length - 1].id ===
      props.section.id
  ) {
    downArrowClass = ' disabled';
    handleDown = null;
  }

  let blockResourceNode = null;
  if (props.section.resources && props.section.resources.length) {
    blockResourceNode = props.section.resources.map((resource, i) => {
      let type = '';
      if (resource.type === 'link') {
        type = 'link';
      } else if (resource.connections && resource.connections.preview_type) {
        type = resource.connections.preview_type;
      }
      return (
        <div key={'resource-' + resource.id + '-iterator-' + i}>
          <div className="row">
            <div className="cute-7-desktop left">{resource.title}</div>
            <div className="cute-2-desktop left">{type}</div>
            <div className="cute-1-desktop left">{resource.type}</div>
            <div className="cute-2-desktop left">
              {moment(resource.created_formatted).format('MM/DD/YYYY')}
            </div>
          </div>
          <div className="row">
            <div className="cute-12-desktop left">{resource.description}</div>
          </div>
        </div>
      );
    });
  }
  let authorNode = null;
  if (props.section.author) {
    authorNode = (
      <div>
        {props.section.author.label} {props.section.author.role}
      </div>
    );
  }
  let contentBlock = (
    <div className="cute-11-desktop left">
      <div className="bl4__capsule bl4__capsule--flat">
        <div className="row ppd-v3-curation__form-row">
          <div className="cute-12-desktop">
            <div className="curation__block-headline-row">
              <span className="tc__headline-3">{props.section.title}</span>
              <span
                onClick={handleEditContentBlock}
                className="tc__link tc-pencil curation__block-edit-icon"
              />
            </div>
          </div>
        </div>
        <div className="row ppd-v3-curation__form-row">
          <PurifiedHTML
            className="cute-10-desktop left"
            content={props.section.description}
          />
        </div>
        <div className="row ppd-v3-curation__form-row">
          <div className="cute-10-desktop left">
            Author
            <div className="tc__headline-5">{authorNode}</div>
          </div>
        </div>
        <div className="row ppd-v3-curation__form-row">
          <div className="cute-10-desktop left">Time in Minutes</div>
          <div className="cute-10-desktop left">{props.section.duration}</div>
        </div>
        <div className="row ppd-v3-curation__form-row">
          <div className="cute-10-desktop left">Resources</div>
          <div className="cute-12-desktop left">{blockResourceNode}</div>
        </div>
      </div>
    </div>
  );

  if (props.edit) {
    authorNode = (
      <div className="row ppd-v3-curation__form-row">
        <div className="cute-12-desktop left">
          <label className="curation__label">Author</label>
          <div onClick={handleAddSectionAuthor} className="tc__link">
            Add Author
          </div>
        </div>
      </div>
    );
    if (props.ui.addSectionAuthor || props.section.author) {
      let roleType = null;
      if (props.section.author.role === '') {
        roleType = 'Other';
      } else if (
        props.section.author.role === 'BetterLesson Instructional Coach' ||
        props.section.author.role === null ||
        props.section.author.role === undefined
      ) {
        roleType = 'BetterLesson Instructional Coach';
      } else if (
        props.section.author.role === 'BetterLesson Coaching Participant'
      ) {
        roleType = 'BetterLesson Coaching Participant';
      } else {
        roleType = 'Other';
      }

      let otherNode = null;
      if (roleType === 'Other') {
        otherNode = (
          <input
            type="text"
            onChange={handleUpdateSectionOtherRole}
            data-index={props.index}
            placeholder="Role Title"
            defaultValue={props.section.author.role}
            className={'tc__input curation__input'}
          />
        );
      }

      authorNode = (
        <div className="row">
          <div className="cute-12-desktop">
            <label className="curation__label">Author</label>
            <div className="curation__edit-author-row">
              <div className="curation__edit-author-col curation__edit-author-col--wide">
                <SingleSelect
                  {...props.ui.editStrategy.sectionAuthor}
                  field="sectionAuthor"
                  placeholder="Find author name"
                  index={props.index}
                  minCharCountForResults={4}
                  updateSingleSelect={props.updateSectionAuthor}
                  getVisibleOptions={props.getSectionAuthorOptions}
                />
              </div>
              <div className="curation__edit-author-col">
                <select
                  onChange={handleUpdateSectionAuthorRole}
                  defaultValue={roleType}
                  data-index={props.index}
                  className="tc__select curation__input"
                >
                  <option value="BetterLesson Instructional Coach">
                    BetterLesson Instructional Coach
                  </option>
                  <option value="BetterLesson Coaching Participant">
                    BetterLesson Coaching Participant
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="curation__edit-author-col">{otherNode}</div>
            </div>
          </div>
        </div>
      );
    }

    let resourcesHeadlineNode,
      blockResourceNode = null;
    if (props.section.resources && props.section.resources.length) {
      resourcesHeadlineNode = (
        <div className="row ppd-v3-curation__form-row">
          <div className="cute-12-desktop left">Resources</div>
        </div>
      );
      const sortBlockResourceNode = props.section.resources.map(
        (resource, i) => {
          let type = '';
          if (resource.type === 'link') {
            type = <div>link</div>;
          } else if (
            resource.connections &&
            resource.connections.preview_type
          ) {
            type = resource.connections.preview_type;
          }

          return (
            <div
              key={'resource-' + resource.id + '-iterator-' + i}
              className={'ppd-v3-curation__row cute-12-desktop'}
              data-element-type={'domain'}
              data-item={resource.id}
            >
              <div className="row">
                <div className="cute-1-desktop">
                  <div className="domains__move-handle">
                    <div className="tc-dots-8 domains__drag-icon domains__drag-icon--nav ppd-v3-curation__drag-icon ppd-v3-curation__drag-icon--domain" />
                  </div>
                </div>
                <div className="cute-6-desktop">
                  <div className="curation__row">
                    <label className="curation__label">Title</label>
                    <input
                      type="text"
                      onBlur={handleUpdateSectionUpdateField}
                      data-sectionindex={props.index}
                      data-resourceindex={i}
                      data-field="title"
                      defaultValue={resource.title}
                      className={'tc__input curation__input'}
                    />
                  </div>
                  <div className="curation__row">
                    <label className="curation__label">Description</label>
                    <textarea
                      onBlur={handleUpdateSectionUpdateField}
                      data-sectionindex={props.index}
                      data-resourceindex={i}
                      data-field="description"
                      defaultValue={resource.description}
                      className={'tc__input curation__input'}
                    />
                  </div>
                </div>
                <div className="cute-1-desktop">{type}</div>
                <div className="cute-1-desktop">{resource.type}</div>
                <div className="cute-2-desktop">
                  {moment(resource.created_formatted).format('MM/DD/YYYY')}
                </div>
                <div
                  className="cute-1-desktop tc-delete tc__link tc__link--black curation__block-delete-icon"
                  onClick={handleDeleteContentBlockResource}
                  data-sectionindex={props.index}
                  data-resourceindex={i}
                />
              </div>

              <ContentResourceReplacePreview
                sectionId={props.section.id}
                resourceId={resource.id}
                lessonfileId={resource.lessonfile_id}
                linkId={resource.link_id}
                updateResourceUploadProgress={
                  props.updateResourcePreviewUploadProgress
                }
                clearAlert={props.clearAlert}
                file={props.ui.uploadResourcePreviewFiles[resource.id]}
                beforeUploadCallback={ResourceApi.getPreviewUploadPayload}
                uploadCallback={ResourceApi.upload}
                afterUploadCallback={props.saveUpdatedPreviewAfterUpload}
                errorCallback={props.setErrorAlert}
              />

              <ContentResourceReplacementPreviewFile
                resource={resource}
                sectionId={props.section.id}
                revertResourcePreview={props.revertResourcePreview}
              />
            </div>
          );
        }
      );

      const SortableBlockResourceNode = SortableItems(
        <div
          id="dragContainerSectionResources"
          className="domains__sidebar-nav ppd-v3-curation__table ppd-v3-curation__table--domains row"
        >
          {sortBlockResourceNode}
        </div>,
        '#dragContainerSectionResources',
        'window',
        sortCallbackSectionResources
      );

      blockResourceNode = (
        <div>
          <SortableBlockResourceNode />
        </div>
      );
    }

    let addLinkNode = null;
    if (props.ui.showAddLink) {
      const disabledClass = props.ui.loadingAddLink ? ' disabled' : '';
      addLinkNode = (
        <div className="row ppd-v3-curation__form-row">
          <div className="cute-10-desktop left">
            <div className="ppd-v3-curation-modal__label">Link</div>
            <input
              type="text"
              ref={(ref) => (link = ref)}
              className="tc__input ppd-v3-curation-modal__input-half"
            />
            <div
              onClick={handleAddLinkResource}
              className={
                'bl4__btn bl4__btn--blue ppd-v3-curation__save-btn' +
                disabledClass
              }
            >
              <Spinner loading={props.ui.loadingAddLink} />
              Add Link
            </div>
            <span
              onClick={handleToggleAddLink}
              className="tc__link curation__cancel-link"
            >
              Cancel
            </span>
          </div>
        </div>
      );
    }

    let addResourceMenuNode = null;
    if (props.ui.addResourceShowMenu) {
      addResourceMenuNode = (
        <div>
          <div
            onClick={handleShowResourceMenu}
            className="clip-modal modal__page-overlay modal__page-overlay--add ppd-v3-curation-modal__page-overlay curation__overlay"
          />
          <div className="curation__tooltip tc__body-copy curation__top">
            <div
              className="tc__link curation__tooltip-row"
              onClick={handleToggleAddLink}
            >
              Add Link
            </div>
            <div
              className="tc__link curation__tooltip-row"
              onClick={handleAddResource}
            >
              Add File Resouce
            </div>
          </div>
        </div>
      );
    }

    // only allow deleting a content block after it's been saved
    const deleteLinkNode = props.ui.addContentBlock ? null : (
      <div className="row tc__center-text">
        <div
          onClick={handleDeleteContentBlock}
          className="curation__delete-link"
        >
          <span className="tc-delete curation__block-delete-icon" /> Delete
        </div>
      </div>
    );

    // disable buttons when making an api call
    const disabledClass = props.ui.loading ? ' disabled' : '';

    contentBlock = (
      <div>
        <div className="clip-modal modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay curation__overlay" />
        <div className="cute-11-desktop curation__top">
          <div className="bl4__capsule bl4__capsule--flat">
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-10-desktop left">
                <div className="curation__label">Title</div>
                <input
                  type="text"
                  ref={(ref) => (title = ref)}
                  defaultValue={props.section.title}
                  className={'tc__input curation__input'}
                />
              </div>
              <div className="cute-2-desktop curation__block-close">
                <span
                  className="tc-x tc__link tc__link--black"
                  onClick={handleCloseContentBlock}
                />
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-10-desktop left">
                <div className="curation__label">Description</div>
                <div className="outcome-story__text-wysiwyg-container">
                  <CKEditor
                    key={props.index}
                    id={'contentBlockTextEntry'}
                    increment={0}
                    innerText={props.section.description}
                    className={'outcome-story__text-wysiwyg'}
                    configs="/legacy-libs/configs/bl-cke-config-curation-block-v4.js"
                  />
                </div>
              </div>
            </div>
            {authorNode}
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-10-desktop left">
                <div className="curation__label">
                  Time in Minutes (optional)
                </div>
                <input
                  type="text"
                  ref={(ref) => (duration = ref)}
                  defaultValue={props.section.duration}
                  className={'tc__input curation__input'}
                />
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-10-desktop left">
                <div className="curation__label">Resources</div>

                <div
                  className={'bl4__btn bl4__btn--blue bl4__btn--small'}
                  onClick={handleShowResourceMenu}
                >
                  Add
                  {addResourceMenuNode}
                </div>
              </div>
            </div>
            {addLinkNode}
            <div>
              <ContentResource
                multiple={false}
                sectionId={props.section.id}
                updateHeroUploadProgress={props.updateResourceUploadProgress}
                clearAlert={props.clearAlert}
                file={props.ui.uploadResourceFile}
                beforeUploadCallback={CurationApi.createResourceBeforeUpload}
                afterUploadCallback={props.saveResourceAfterUpload}
                uploadCallback={ResourceApi.upload}
                cleanupCallback={props.cleanupAfterResourceUploadFailure}
                errorCallback={props.setErrorAlert}
              />
            </div>
            {resourcesHeadlineNode}
            {blockResourceNode}
            <div className="row tc__center-text">
              <div className="cute-12-desktop">
                <button
                  onClick={handleAddContentBlock}
                  className={'bl4__btn bl4__btn--blue' + disabledClass}
                >
                  <Spinner loading={props.ui.loading} />
                  {props.ui.addContentBlock ? 'Save' : 'Update'}
                </button>
              </div>
            </div>
            {deleteLinkNode}
          </div>
        </div>

        <div className="cute-1-desktop curation__top">
          <div className="curation__arrow curation__arrow--up">
            <span
              className={'tc-bold-arrow' + upArrowClass}
              onClick={handleUp}
            />
          </div>
          <div className="curation__arrow">
            <span
              className={'tc-bold-arrow' + downArrowClass}
              onClick={handleDown}
            />
          </div>
        </div>
      </div>
    );
  }

  return <div key={props.section.id}>{contentBlock}</div>;
};

ContentBlock.propTypes = {
  ui: PropTypes.object.isRequired,
};

export default ContentBlock;
