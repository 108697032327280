// @ts-nocheck FIXME
import dateformat from 'dateformat';
import PropTypes from 'prop-types';

const EditSummary = (props) => {
  // set the style of the Intro & Ref item, depending on whether or not any text is there
  let introClass = '';
  let introIcon = ' tc-x';
  if (
    props.portfolio.teacher_introduction ||
    props.portfolio.teacher_reflection ||
    props.portfolio.coach_reflection
  ) {
    introClass = ' portfolio-edit__summary-checklist-item--green';
    introIcon = ' tc-check';
  }

  // get the # of visible strategies and artifacts
  // (for old V1 portfolios we get the counts in the backend)
  let strategyCount = props.loopCount || 0;
  let artifactCount = props.artifactCount || 0;
  // for new V2 portfolios we count from the loop data
  if (props.loops) {
    props.loops.forEach((loop) => {
      if (loop.visible) {
        strategyCount += 1;
        if (loop.artifacts) {
          loop.artifacts.forEach((artifact) => {
            if (artifact.visible) {
              artifactCount += 1;
            }
          });
        }
      }
    });
  }

  // set the text for the counts
  const strategyCountText =
    strategyCount == 1 ? '1 Strategy' : strategyCount + ' Strategies';
  const artifactCountText =
    artifactCount == 1 ? '1 Artifact' : artifactCount + ' Artifacts';

  // set the style of the Strategies item, depending on whether or not any strategies are there
  let strategiesClass = '';
  let strategiesIcon = ' tc-x';
  if (strategyCount) {
    strategiesClass = ' portfolio-edit__summary-checklist-item--green';
    strategiesIcon = ' tc-check';
  }

  return (
    <div className="portfolio-edit__container row">
      <div className="cute-6-desktop center-desktop tc__body-copy portfolio-edit__summary-headline">
        You are almost done. Click the Finish button to complete your portfolio.
      </div>
      <div className="cute-3-desktop cute-3-desktop-offset">
        <div className="portfolio-edit__summary-checklist-item portfolio-edit__summary-checklist-item--green">
          <span className="tc-check portfolio-edit__summary-checklist-icon"></span>
          Cover Page
        </div>
        <div className={'portfolio-edit__summary-checklist-item' + introClass}>
          <span
            className={'portfolio-edit__summary-checklist-icon' + introIcon}
          ></span>
          Introduction & Reflections
        </div>
        <div
          className={'portfolio-edit__summary-checklist-item' + strategiesClass}
        >
          <span
            className={
              'portfolio-edit__summary-checklist-icon' + strategiesIcon
            }
          ></span>
          Strategies & Artifacts
        </div>
      </div>
      <div className="cute-3-desktop left">
        <div className="tc__headline-3 portfolio-edit__summary-name">
          {props.portfolio.name}
        </div>
        <div className="portfolio-edit__summary-text">
          Created: {dateformat(props.portfolio.created, 'mm/dd/yyyy')}
        </div>
        <div className="portfolio-edit__summary-text">
          Modified: {dateformat(props.portfolio.lastmodified, 'mm/dd/yyyy')}
        </div>
        <ul className="portfolio-edit__summary-data-counts">
          <li>
            <span className="portfolio-edit__summary-data-count">
              {strategyCountText}
            </span>
          </li>
          <li>
            <span className="portfolio-edit__summary-data-count">
              {artifactCountText}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

EditSummary.propTypes = {
  portfolio: PropTypes.object.isRequired,
  loops: PropTypes.array,
};

export default EditSummary;
