// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';

// const NavigationLink = require('@legacy-components/react/NavigationLink.jsx');
import UserInfo from './../Common/UserInfo';

import HighlightCarousel from '../modules/HighlightCarousel';
import LearningDomains from '../modules/LearningDomains';
import TabbedMenu from './../modules/TabbedMenu';
import CohortsTable from './CohortsTable';
import EngagementTab from './EngagementTab';
import EventsTable from './EventsTable';
import MobileCohortsTable from './MobileCohortsTable';
import MobileEventsTable from './MobileEventsTable';

const PartnerLeaderHome = (props) => {
  const disabled = false;

  const tabs = [
    {
      name: 'overview',
      disabled: false,
    },
    {
      name: 'engagement',
      disabled: disabled,
      label: 'Engagement',
    },
    {
      name: 'updates',
      disabled: disabled,
      label: 'Updates',
    },
  ];

  const navigationNode =
    props.cohorts.length > 1 ? (
      <TabbedMenu
        tabs={tabs}
        active={props.active_tab}
        urlPrefix={`/lab/leader/${props.partner_lead.id}`}
        currentYear={props.school_year}
      />
    ) : null;
  let psmsList = null;
  if (props.psms && props.psms.length) {
    psmsList = (
      <div className="partner_lead_v5__sidebar-psm-home">
        <div className="v5__heading-6">
          Your Partner Success Manager{props.psms.length > 1 && 's'}
        </div>
        <div className="partner_lead_v5__sidebar-psms">
          {props.psms.sort().map((psm) => (
            <UserInfo key={`psm-${psm.id}`} {...psm} />
          ))}
        </div>
      </div>
    );
  }
  const psmNode = (
    <div className="partner_lead_v5__home-sidebar">
      <div className="v5__heading-1">{`Hello, ${props.page_user.first_name} ${props.page_user.last_name}!`}</div>
      {psmsList}
    </div>
  );

  const headerImageNode = (
    <div className="partner_lead_v5__header partner_lead_v5__header-image partner_lead_v5__header-image-container">
      <img
        src="/legacy-images/v5/BL-PL-PST-Dashboard-Header_MD.png"
        className="partner_lead_v5__hero-image"
      />
    </div>
  );

  const headerNode = (
    <div className="row row--1200 partner_lead_v5__header-container">
      {psmNode}
      {headerImageNode}
    </div>
  );

  // Set default bodyNode to display when no data exists
  let bodyNode = (
    <div className="row row--1200 v5__body-copy">
      <div className="partner_lead__header-container"></div>
      <div className="v5__heading-4">We have no updates to share yet</div>
      Once your educators start working with our coaches, you&apos;ll be able to
      see and track their progress here.
    </div>
  );

  if (props.search_failed) {
    bodyNode = (
      <React.Fragment>
        <div className="v5__registered-table-details v5__heading-5 row home-v5__empty-activity-capsule">
          <div>
            <img
              src="/legacy-images/404-pets.png"
              className="code-404__img-overview"
            />
          </div>
          <div className="home-v5__empty-activity-row">
            <div className="v5__heading-4">Oops! Something is broken.</div>
            <div className="v5__comment-copy">
              <div>
                Your data is temporarily unavailable. (We blame the cat.)
              </div>
              <div>Thanks for your patience as we tidy up this issue.</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (
    (props.cohorts && props.cohorts.length) ||
    (props.events && props.events.length)
  ) {
    switch (props.active_tab) {
      case 'engagement':
        bodyNode = (
          <EngagementTab
            cohorts={props.cohorts}
            engagement_stats={props.engagement_stats}
            search_failed={props.search_failed}
          />
        );
        break;
      case 'updates':
        bodyNode = (
          <div className="row row--1200">
            <LearningDomains {...props} search_failed={props.search_failed} />
          </div>
        );
        break;
      case 'overview':
      default:
        bodyNode = (
          <React.Fragment>
            <div className="row row--1200">
              <HighlightCarousel
                entries={props.highlight_entries}
                reportingPhases={props.reportingPhases}
              />
            </div>
            {!!(props.cohorts && props.cohorts.length) && (
              <div className={'partner_lead_v5__table-spacer'}></div>
            )}
            <div className="show-desktop hide-phone row row--1200">
              {
                <CohortsTable
                  cohorts={props.cohorts}
                  currentYear={props.school_year}
                />
              }
            </div>
            <div className="show-phone hide-desktop">
              {
                <MobileCohortsTable
                  cohorts={props.cohorts}
                  currentYear={props.school_year}
                />
              }
            </div>
            {!!(props.events && props.events.length) && (
              <div className={'partner_lead_v5__table-spacer'}></div>
            )}
            <div className="show-desktop hide-phone row row--1200">
              {
                <EventsTable
                  leadId={props.partner_lead.id}
                  events={props.events}
                />
              }
            </div>
            <div className="show-phone hide-desktop">
              {
                <MobileEventsTable
                  leadId={props.partner_lead.id}
                  events={props.events}
                />
              }
            </div>
          </React.Fragment>
        );
    }
  }

  return (
    <div>
      <Header page="PartnerLeaderHome" {...props} />
      <div className="partner_lead_v5__main-content v5__body-copy">
        {headerNode}
        <div className="row row--1200">{navigationNode}</div>
        {bodyNode}
      </div>
    </div>
  );
};

PartnerLeaderHome.propTypes = {
  active_tab: PropTypes.string,
  user: PropTypes.object.isRequired,
  page_user: PropTypes.object.isRequired,
  cohorts: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  engagement_stats: PropTypes.object,
  learning_domains: PropTypes.object,
  SA_aggregate: PropTypes.object,
  search_failed: PropTypes.bool,
};

export default PartnerLeaderHome;
