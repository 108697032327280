// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import PropTypes from 'prop-types';

/*
This is specifically for the Events tab of the Coach Dashboard, to show a list of users like:

Test User1, test1@betterlesson.com, Test User2, test2@betterlesson.com

where all the emails are styled as links and clickable to copy them to the clipboard.
*/
const UserListRow = (props) => {
  // if there are no users and no default to show in place of the list, display nothing
  if (!props.defaultNode && !ArrayHelpers.hasElements(props.users)) {
    return null;
  }

  let label = props.label;
  if (props.labelPlural && props.users.length > 1) {
    label = props.labelPlural;
  }

  let separator = '';
  if (props.users.length > 1) {
    separator = <span className="show-desktop-inline hide-phone">,&nbsp;</span>;
  }

  // set up the user list
  let usersNode = props.defaultNode;
  if (ArrayHelpers.hasElements(props.users)) {
    usersNode = props.users.map((user, i) => {
      if (i === props.users.length - 1) {
        separator = '';
      }
      return (
        <div key={`user-${user.id}`} className="coach-event__psm-link">
          <span className="coach-event__psm-name">
            {`${user.first_name} ${user.last_name}, `}
          </span>
          <span
            copytext={user.email}
            onClick={props.handleEmailCopy}
            className="coach-event__copy-link-text coach-event__psm-email"
          >
            {user.email}
          </span>
          {separator}
        </div>
      );
    });
  }

  return (
    <div className="flex-row">
      <div className="coach-event__associated-users-label">{label}:</div>
      <div className="coach-event__psms">{usersNode}</div>
    </div>
  );
};

UserListRow.propTypes = {
  label: PropTypes.string.isRequired,
  labelPlural: PropTypes.string,
  users: PropTypes.array.isRequired,
  handleEmailCopy: PropTypes.func.isRequired,
  defaultNode: PropTypes.node,
};

export default UserListRow;
