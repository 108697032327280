// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ScaleCenterImage from './../../ScaleCenterImage';
import TmlProgressChart from './Narrative/Graph';
import NarrativeStep from './Narrative/Step';

const Narrative = (props) => {
  const teacherImage = props.entry.data.teacher_image
    ? props.entry.data.teacher_image
    : '/legacy-images/default-user.svg';

  const coachImage = props.entry.extended_data.coach.image
    ? props.entry.extended_data.coach.image
    : '/legacy-images/default-user.svg';

  let metricNode = null;
  if (props.entry.data.metric) {
    metricNode = (
      <div className="ppp__narrative-section">
        <div className="ppp__narrative-headline-2">I was Looking for</div>
        <div className="ppp__body-copy">{props.entry.data.metric}</div>
      </div>
    );
  }

  return (
    <div className="ppp__section ppp__section--narrative">
      <div className="row">
        <div className="ppp__narrative-teacher cute-9-desktop left">
          <div className="ppp__narrative-teacher-image">
            <ScaleCenterImage
              src={teacherImage}
              className="ppp__narrative-user-image-mask"
            />
          </div>
          <div className="ppp__narrative-teacher-details">
            <div className="ppp__narrative-author-name">
              {props.entry.data.teacher_name}
            </div>
            <div className="ppp__narrative-author-school">
              {props.entry.extended_data.grade},{' '}
              {props.entry.extended_data.subject}
            </div>
          </div>
          <div className="ppp__narrative-teacher-border"></div>
        </div>
      </div>
      <div className="row">
        <div className="cute-9-desktop">
          <div className="row">
            <div className="cute-7-desktop">
              <div className="ppp__narrative-headline-2">My Challenge</div>
              <div className="ppp__quote">
                {props.entry.data.teaching_challenge_name}
              </div>
            </div>
            <div className="cute-5-desktop">
              <div
                className={
                  'ppp__narrative-growtharea-icon tc-sga-nb-' +
                  props.entry.data.growth_area_id
                }
              ></div>
              <div className="ppp__narrative-growtharea-details">
                <div className="ppp__narrative-headline-2">Growth Area</div>
                <div className="ppp__quote ppp__quote--narrative-ga">
                  {props.entry.data.growth_area_name}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="cute-12-desktop">
              <div className="ppp__narrative-section ppp__narrative-section--tried">
                <div className="ppp__narrative-headline-2">
                  I tried the Strategy
                </div>
                <div className="">
                  <a
                    href={
                      '/coaching/lab/strategy/' +
                      props.entry.data.strategy.id +
                      '/' +
                      props.entry.data.strategy.seo_url +
                      '?from=pp_consumer_view'
                    }
                    className="tc__link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.entry.data.strategy.title}
                  </a>
                </div>
                <PurifiedHTML
                  className="ppp__body-copy"
                  content={props.entry.data.strategy.description}
                />
              </div>
              {metricNode}
              <div className="ppp__narrative-section ppp__narrative-section--because">
                <div className="ppp__narrative-headline-2">
                  I&rsquo;m doing this because
                </div>
                <div className="ppp__body-copy">
                  {props.entry.extended_data.feedback.teach}
                </div>
              </div>
              <div className="ppp__narrative-teacher-border"></div>
            </div>

            <div className="ppp__narrative-feedback cute-12-desktop">
              <NarrativeStep
                caption={'Observations and Insights'}
                step="measure"
                feedback={props.entry.extended_data.feedback.measure}
                comments={props.entry.extended_data.comments.measure}
                artifacts={props.entry.extended_data.artifacts}
                showArtifact={props.showArtifact}
              />

              <NarrativeStep
                step="learn"
                caption={'Reflections &amp; Next Steps'}
                feedback={props.entry.extended_data.feedback.learn}
                comments={props.entry.extended_data.comments.learn}
                showArtifact={props.showArtifact}
              />
            </div>
          </div>
        </div>
        <div className="ppp__narrative-sidebar cute-3-desktop">
          {(props.entry.extended_data.baseline.percent != null ||
            props.entry.extended_data.goal.percent != null ||
            props.entry.extended_data.measurement.percent != null ||
            props.entry.extended_data.measurement.value != null) && (
            <div className="ppp__narrative-headline-1 tc__center-text">
              Highlights
            </div>
          )}
          {props.entry.extended_data.baseline.percent != null &&
            props.entry.extended_data.goal.percent != null && (
              <div>
                <div className="ppp__narrative-headline-2 ppp__narrative-graph-header tc__center-text">
                  Starting Point
                </div>
                <div className="ppp__narrative-starting-point-graphs">
                  <TmlProgressChart
                    showLabels={true}
                    baseline={props.entry.extended_data.baseline.percent}
                  />
                  <TmlProgressChart
                    showLabels={true}
                    goal={props.entry.extended_data.goal.percent}
                  />
                </div>
              </div>
            )}
          {props.entry.extended_data.measurement.percent != null &&
            props.entry.extended_data.measurement.value != null && (
              <div>
                <div className="ppp__narrative-headline-2 ppp__narrative-graph-header ppp__narrative-graph-header--results tc__center-text">
                  Results
                </div>
                <div className="ppp__narrative-measurement-graphs">
                  <TmlProgressChart
                    showLabels={true}
                    goal={props.entry.extended_data.goal.percent}
                    baseline={props.entry.extended_data.baseline.percent}
                    measurementPercent={
                      props.entry.extended_data.measurement.percent
                    }
                    measurementValue={
                      props.entry.extended_data.measurement.value
                    }
                  />
                </div>
              </div>
            )}
          <div className="ppp__narrative-coach">
            <div className="ppp__narrative-coach-headline">Coach Bio</div>
            <div className="ppp__narrative-coach-image">
              <ScaleCenterImage
                src={coachImage}
                className="ppp__narrative-user-image-mask"
              />
            </div>
            <div className="ppp__narrative-coach-name">
              {props.entry.extended_data.coach.name}
            </div>
            <div className="ppp__narrative-coach-bio">
              {props.entry.extended_data.coach.bio}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Narrative.propTypes = {
  entry: PropTypes.object.isRequired,
};

export default Narrative;
