// @ts-nocheck FIXME
import ArtifactApi from './../../apis/ArtifactApi';
import PortfolioApi from './../../apis/PortfolioApi';
import Analytics from './../../components/Analytics';
import Utils from './../../components/DynamicUtils';
import { Router } from './../../components/Router';
import Store from './../../components/Store';

const store = {
  portfolio: {},
  loops: [],
  portfolioChanged: null,
  artifactsChanged: {},
  feedbackChanged: {},
  autosaveEnabled: true,

  ui: {
    currentStep: null,
    currentStepKey: null,
    currentLoopId: null,
    autosaveState: null,
    errors: {},
    alert: {},

    strategies: {
      refreshing: false,
      showEditMenuIds: [],
    },
    artifacts: {
      editModeIds: [],
      showCommentsIds: [],
      currentArtifact: null,
      scrollPosition: 0,
      artifactDownloading: false,
      downloadError: null,
      activeArtifactPage: 1,
    },
    feedback: {
      editModeSteps: [],
      showCommentsSteps: [],
    },
  },
};

/****** PRIVATE FUNCTIONS ******/
// Helper method to get the step key:
const getStepKey = (step) => {
  switch (step) {
    case 'cover':
      return 1;
    case 'intro':
      return 2;
    case 'strategies':
      return 3;
    case 'summary':
      return 4;
    default:
      return 1;
  }
};

const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    cssClasses: { container: 'alert-banner--teacher-portfolio-edit-substep' },
    handleClose: store.clearAlert,
  };
};

const savePortfolio = (successCallback, isCoach) => {
  // validate portfolio data
  const data = store.portfolio;

  if (!data.cover_details) {
    store.ui.errors['coverDetails'] = 'This field is required.';
  } else {
    // User cannot enter more than 4 lines of text:
    const coverDetailLines = data.cover_details.match(/\n/g);
    const coverDetailLinesCount = coverDetailLines
      ? coverDetailLines.length + 1
      : 1;
    if (coverDetailLinesCount > 4) {
      store.ui.errors['coverDetails'] =
        'Please enter a maximum of four lines for the cover details.';
    }
  }
  if (!data.cover_title) {
    store.ui.errors['coverTitle'] = 'This field is required.';
  }
  if (!data.name) {
    store.ui.errors['coverName'] = 'This field is required.';
  }
  // if there are any errors, stop trying to save
  if (Object.keys(store.ui.errors).length) {
    store.portfolioChanged = false;
    return;
  }

  // set up saving icon
  store.ui.autosaveState = 'saving';

  // build data for api call
  data.portfolioId = store.portfolio.id;
  data.teacherId = store.portfolio.user_id;

  // also include data for any artifacts that have been edited
  if (Object.keys(store.artifactsChanged).length) {
    data.artifacts = store.artifactsChanged;
  }
  // also include data for any feedback that has been edited
  if (Object.keys(store.feedbackChanged).length) {
    data.feedback = store.feedbackChanged;
  }

  data['isCoach'] = isCoach || false;
  return PortfolioApi.editPortfolio(data)
    .then((response) => {
      store.autosaveEnabled = true;
      store.portfolioChanged = false;
      store.artifactsChanged = {};
      store.feedbackChanged = {};
      store.ui.autosaveState = 'success';
      if (successCallback) {
        successCallback();
      }
    })
    .catch((response) => {
      store.autosaveEnabled = false;
      store.ui.autosaveState = null;
      setErrorAlert(response.error);
    });
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.ui.currentStep = data.currentStep;
  store.ui.currentStepKey = getStepKey(data.currentStep);
  store.portfolio = data.portfolio;
  store.loops = data.loops;
  store.portfolioChanged = false;
  store.autosaveEnabled = true;
  store.ui.autosaveState = null;
  store.ui.errors = {};
  store.ui.alert = {};

  // don't trigger event
  return false;
};

// Changes the step
store.changeStep = (step) => {
  store.ui.currentStep = step;
  // Update the current step key for the top nav:
  store.ui.currentStepKey = getStepKey(step);
};

// Updates the portfolio in the store if changes are detected:
store.updatePortfolio = (portfolio) => {
  // Clear errors and alerts:
  store.ui.errors = {};
  store.ui.alert = {};

  if (!Utils.areObjectsEqual(store.portfolio, portfolio)) {
    store.portfolio = portfolio;
    store.portfolioChanged = true;
  }
};

store.savePortfolio = (successUrl, isCoach) => {
  let successCallback = null;
  if (successUrl) {
    successCallback = () => Router.route(successUrl);
  }

  return savePortfolio(successCallback, isCoach);
};

store.clearAlert = () => {
  store.ui.alert = {};
};

// Handles refreshing the tml data + unpublishing portfolio
store.refreshData = () => {
  store.ui.strategies.refreshing = true;

  return PortfolioApi.unpublishPortfolio({
    teacherId: store.portfolio.user_id,
    portfolioId: store.portfolio.id,
  })
    .then((response) => {
      store.ui.strategies.refreshing = false;
      store.loops = response.payload.loops;
      store.portfolio.published = false;

      // Record the analytics event:
      Analytics.logEvent({
        category: 'strategy',
        action: 'refresh',
        label: 'from_tp_curation_ui',
      });
    })
    .catch((response) => {
      store.ui.strategies.refreshing = false;
    });
};

store.toggleLoopVisibility = (loopId) => {
  // first find the loop to edit
  const loop = store.loops.find((loop) => loop.loopId == loopId);
  if (!loop) {
    return false;
  }

  const data = {
    portfolioId: store.portfolio.id,
    teacherId: store.portfolio.user_id,
    loopId: loopId,
    visible: !loop.visible,
  };

  return PortfolioApi.editLoop(data)
    .then((response) => {
      loop.visible = !loop.visible;
    })
    .catch((response) => {
      store.autosaveEnabled = false;
      setErrorAlert(response.error);
    });
};

// open/close the menu within a loop capsule
store.toggleLoopEditMenu = (loopId) => {
  const index = store.ui.strategies.showEditMenuIds.indexOf(loopId);
  if (index == -1) {
    store.ui.strategies.showEditMenuIds.push(loopId);
  } else {
    store.ui.strategies.showEditMenuIds.splice(index, 1);
  }
};
// close all the loop capsule menus when you click elsewhere on the page
store.closeAllLoopEditMenus = () => {
  store.ui.strategies.showEditMenuIds = [];
};

// the current loop determines which loop's info to use in the loop substep page
store.setCurrentLoopSubstep = (data) => {
  if (data.id) {
    store.ui.currentStep = data.substep;
    store.ui.currentLoopId = data.id;
  } else {
    store.ui.currentStep = 'strategies';
    store.ui.currentLoopId = null;
  }
  switch (data.substep) {
    case 'artifacts':
      Analytics.logEvent({
        category: 'artifact',
        action: 'select',
        label: 'from_tp_curation_ui',
      });
      break;
    case 'feedback':
      Analytics.logEvent({
        category: 'tml_reflections',
        action: 'show',
        label: 'from_tp_curation_ui',
      });
  }
};

// show the artifact overlay for this artifact (or hide it if null)
store.setCurrentArtifact = (artifact) => {
  store.ui.artifacts.currentArtifact = artifact;
  store.ui.artifacts.activeArtifactPage = 1;
  if (artifact) {
    store.ui.artifacts.scrollPosition =
      window.pageYOffset =
      document.documentElement.scrollTop =
        document.body.scrollTop;
    // log metrics
    Analytics.logEvent({
      category: 'artifact',
      action: 'view',
      label: 'from_tp_curation_ui',
    });
  } else {
    store.ui.artifacts.scrollPosition = 0;
  }
};

// these are for artifacts in the overlay with multiple pages
store.showNextPage = () => {
  store.ui.artifacts.activeArtifactPage =
    store.ui.artifacts.activeArtifactPage + 1;
};
store.showPreviousPage = () => {
  store.ui.artifacts.activeArtifactPage =
    store.ui.artifacts.activeArtifactPage - 1;
};

store.downloadArtifact = (data) => {
  // clear any error
  store.ui.artifacts.downloadError = null;

  // If there is no download url, show an error:
  if (!store.ui.artifacts.currentArtifact.download_url) {
    store.ui.artifacts.downloadError = 'An error occurred. Please try again!';
    return;
  }

  // start the spinner
  store.ui.artifacts.artifactDownloading = true;

  // Increment the count in the DB
  return ArtifactApi.postTcArtifactDownload(
    store.ui.artifacts.currentArtifact.id
  )
    .then((response) => {
      store.ui.artifacts.artifactDownloading = false;
      // Trigger the download
      location.href = store.ui.artifacts.currentArtifact.download_url;
    })
    .catch((response) => {
      store.ui.artifacts.downloadError = 'An error occurred. Please try again!';
      store.ui.artifacts.artifactDownloading = false;
    });
};

store.toggleArtifactVisibility = (data) => {
  // first find the artifact's loop
  const loop = store.loops.find((loop) => loop.loopId == data.loopId);
  if (!loop) {
    return false;
  }
  // then find the artifact to edit
  const artifact = loop.artifacts.find((artifact) => artifact.id == data.id);
  if (!artifact) {
    return false;
  }

  const apiData = {
    portfolioId: store.portfolio.id,
    teacherId: store.portfolio.user_id,
    artifactId: data.id,
    visible: !artifact.visible,
  };

  return PortfolioApi.editArtifact(apiData)
    .then((response) => {
      artifact.visible = !artifact.visible;
      // log metrics
      Analytics.logEvent({
        category: 'artifact',
        action: 'set_visibility',
        label: 'from_tp_curation_ui',
      });
    })
    .catch((response) => {
      store.autosaveEnabled = false;
      setErrorAlert(response.error);
    });
};

// in the Artifacts page,
// clicking the "Edit" link in an artifact capsule allows you to edit the note text
store.setArtifactEditMode = (artifactId) => {
  if (store.ui.artifacts.editModeIds.indexOf(artifactId) != -1) {
    return false;
  }
  store.ui.artifacts.editModeIds.push(artifactId);
  // log metrics
  Analytics.logEvent({
    category: 'artifact',
    action: 'edit_note',
    label: 'from_tp_curation_ui',
  });
};

// editing an artifact's notes will also trigger the portfolio autosave
store.updateArtifact = (data) => {
  // first find the artifact's loop
  const loop = store.loops.find((loop) => loop.loopId == data.loopId);
  if (!loop) {
    return false;
  }
  // then find the artifact to edit
  const artifact = loop.artifacts.find((artifact) => artifact.id == data.id);
  if (!artifact) {
    return false;
  }

  // update the artifact and trigger the autosave
  artifact.notes = data.notes;
  store.artifactsChanged[artifact.id] = data.notes;
  store.portfolioChanged = true;
};

// show the comments for the artifact
store.showArtifactComments = (artifactId) => {
  if (store.ui.artifacts.showCommentsIds.indexOf(artifactId) != -1) {
    return false;
  }
  store.ui.artifacts.showCommentsIds.push(artifactId);
  // log metrics
  Analytics.logEvent({
    category: 'artifact',
    action: 'show_comments',
    label: 'from_tp_curation_ui',
  });
};

store.toggleArtifactCommentVisibility = (data) => {
  // first find the artifact's loop
  const loop = store.loops.find((loop) => loop.loopId == data.loopId);
  if (!loop) {
    return false;
  }
  // then find the artifact
  const artifact = loop.artifacts.find(
    (artifact) => artifact.id == data.artifactId
  );
  if (!artifact) {
    return false;
  }
  // then find the comment to edit
  const comment = artifact.comments.find((comment) => comment.id == data.id);
  if (!comment) {
    return false;
  }

  const apiData = {
    portfolioId: store.portfolio.id,
    teacherId: store.portfolio.user_id,
    commentId: data.id,
    visible: !comment.visible,
  };

  return PortfolioApi.editArtifactComment(apiData)
    .then((response) => {
      comment.visible = !comment.visible;
      // log metrics
      Analytics.logEvent({
        category: 'artifact',
        action: 'set_comment_visibility',
        label: 'from_tp_curation_ui',
      });
    })
    .catch((response) => {
      store.autosaveEnabled = false;
      setErrorAlert(response.error);
    });
};

// in the Feedback page,
// clicking the "Edit" link in a feedback row allows you to edit the text
store.setFeedbackEditMode = (step) => {
  if (store.ui.feedback.editModeSteps.indexOf(step) != -1) {
    return false;
  }
  store.ui.feedback.editModeSteps.push(step);

  // set up action for metrics
  let analyticsAction = '';
  switch (step) {
    case 'teach':
      analyticsAction = 'edit_plan';
      break;
    case 'measure':
      analyticsAction = 'edit_feedback';
      break;
    case 'learn':
      analyticsAction = 'edit_reflection';
      break;
  }

  // log metrics
  Analytics.logEvent({
    category: 'strategy',
    action: analyticsAction,
    label: 'from_tp_curation_ui',
  });
};

// editing feedback will also trigger the portfolio autosave
store.updateFeedback = (data) => {
  // first find the loop
  const loop = store.loops.find((loop) => loop.loopId == data.loopId);
  if (!loop) {
    return false;
  }
  // then find the feedback to edit
  const feedback = loop.feedback[data.step];
  if (!feedback) {
    return false;
  }

  // update the feedback and trigger the autosave
  feedback.message = data.message;
  store.feedbackChanged[feedback.id] = data.message;
  store.portfolioChanged = true;
};

// show the comments for the feedback
store.showFeedbackComments = (step) => {
  if (store.ui.feedback.showCommentsSteps.indexOf(step) != -1) {
    return false;
  }
  store.ui.feedback.showCommentsSteps.push(step);
};

store.toggleFeedbackCommentVisibility = (data) => {
  // first find the loop
  const loop = store.loops.find((loop) => loop.loopId == data.loopId);
  if (!loop) {
    return false;
  }
  // then find the feedback
  const feedback = loop.feedback[data.step];
  if (!feedback) {
    return false;
  }
  // then find the comment to edit
  const comment = feedback.comments.find((comment) => comment.id == data.id);
  if (!comment) {
    return false;
  }

  const apiData = {
    portfolioId: store.portfolio.id,
    teacherId: store.portfolio.user_id,
    commentId: data.id,
    visible: !comment.visible,
  };

  return PortfolioApi.editFeedbackComment(apiData)
    .then((response) => {
      comment.visible = !comment.visible;
    })
    .catch((response) => {
      store.autosaveEnabled = false;
      setErrorAlert(response.error);
    });
};

store.saveSubstep = (substep) => {
  let analyticsCategory = '';
  let analyticsAction = '';

  // condition for metrics action and category
  if (substep == 'artifacts') {
    analyticsCategory = 'artifact';
    analyticsAction = 'save_artifacts';
  } else if (substep == 'feedback') {
    analyticsCategory = 'tml_reflections';
    analyticsAction = 'save_reflections';
  }

  return savePortfolio(() => {
    store.ui.currentStep = 'strategies';
    store.ui.currentLoopId = null;
    store.ui.strategies.showEditMenuIds = [];
    store.ui.artifacts.editModeIds = [];
    store.ui.artifacts.showCommentsIds = [];
    store.ui.feedback.editModeSteps = [];
    store.ui.feedback.showCommentsSteps = [];

    // log metrics
    Analytics.logEvent({
      category: analyticsCategory,
      action: analyticsAction,
      label: 'from_tp_curation_ui',
    });
  });
};

store.publishPortfolio = (successUrl) => {
  store.ui.autosaveState = 'saving';

  return PortfolioApi.publishPortfolio({
    portfolioId: store.portfolio.id,
    teacherId: store.portfolio.user_id,
  })
    .then((response) => {
      store.ui.autosaveState = 'success';
      Router.route(successUrl);
    })
    .catch((response) => {
      setErrorAlert(response.error);
    });
};

export default Store('TeacherPortfolioToolStore', store);
