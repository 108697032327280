// @ts-nocheck FIXME
import React from 'react';

class Introduction extends React.Component {
  // we only render segments if we have data for them
  render() {
    let vision = null;
    if (this.props.portfolio.vision_statement) {
      vision = (
        <div className="portfolio__intro">
          <div className="portfolio__section-headline portfolio__section-headline--intro">
            Vision of Student Success
          </div>
          <div className="portfolio__intro-body portfolio__intro-body--vision">
            {this.props.portfolio.vision_statement}
          </div>
        </div>
      );
    }

    let summary = null;
    if (this.props.portfolio.summary_description) {
      summary = (
        <div className="portfolio__intro">
          <div className="portfolio__section-headline portfolio__section-headline--intro">
            Design Summary
          </div>
          <div className="portfolio__intro-body portfolio__intro-body--vision">
            {this.props.portfolio.summary_description}
          </div>
        </div>
      );
    }

    let intro = null;
    if (this.props.portfolio.teacher_introduction) {
      intro = (
        <div className="portfolio__intro">
          <div className="portfolio__section-headline portfolio__section-headline--intro">
            Introduction
          </div>
          <div className="portfolio__intro-body">
            {this.props.portfolio.teacher_introduction}
            <div className="portfolio__intro-author">
              - {this.props.portfolio.author.first_name}{' '}
              {this.props.portfolio.author.last_name}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {vision}
        {summary}
        {intro}
      </div>
    );
  }
}

export default Introduction;
