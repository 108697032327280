// @ts-nocheck FIXME
import React from 'react';
import ArrayHelpers from './../../../../components/ArrayHelpers';
import Utils from './../../../../components/DynamicUtils';
import WindowHelpers from './../../../../components/WindowHelpers';
import SortableItems from './../../Common/SortableItems';
import UserPhoto from './../../Common/UserPhoto';
import WindowScrollElementFixer from './../../Common/WindowScrollElementFixer';
import SidebarStoryItem from './SidebarStoryItem';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.aboutContainer = {};
    this.visionContainer = {};
    this.portfolioContainer = {};
    this.storiesContainers = {};
    this.setupContainerElem = this.setupContainerElem.bind(this);
    this.handleScrollLocationUpdates =
      this.handleScrollLocationUpdates.bind(this);
    this.setUpStoryElementLocations =
      this.setUpStoryElementLocations.bind(this);
  }

  componentDidMount() {
    const aboutContainer = document.getElementById('bl4-profile__about');
    this.aboutContainer = this.setupContainerElem(aboutContainer);

    // check if the vision container exists
    const visionContainer = document.getElementById('bl4-profile__vision');
    if (visionContainer) {
      this.visionContainer = this.setupContainerElem(visionContainer);
    }

    // check if the portfolio container exists
    const portfolioContainer = document.getElementById(
      'bl4-profile__portfolio-2018'
    );
    if (portfolioContainer) {
      this.portfolioContainer = this.setupContainerElem(portfolioContainer);
    }

    Utils.addClass(
      document.querySelector('.bl4__sidebar-item--about'),
      'active'
    );

    // if we have stories, then set them up
    this.setUpStoryElementLocations();

    // attach a scroll listener
    window.addEventListener('scroll', this.handleScrollLocationUpdates);
  }
  componentDidUpdate(prevProps) {
    // reorder stories if the stories in the sidebar order has been submitted
    if (
      JSON.stringify(this.props.stories) != JSON.stringify(prevProps.stories)
    ) {
      this.setUpStoryElementLocations();
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollLocationUpdates);
  }
  setupContainerElem(elemContainer) {
    const elementDetails = {};

    // get the position and height of the about container
    elementDetails.top = Utils.getAbsolutePosition(elemContainer)[1];
    elementDetails.height = elemContainer.offsetHeight;

    // add to height of about container to position to get margin for active state
    elementDetails.bottom = elementDetails.top + elementDetails.height;

    return elementDetails;
  }
  setUpStoryElementLocations() {
    if (this.props.stories) {
      this.props.stories.forEach((story) => {
        const storyContainer = document.getElementById('story-' + story.id);
        this.storiesContainers[story.id] =
          this.setupContainerElem(storyContainer);
      });
    }
  }
  handleScrollLocationUpdates() {
    const currentScrollPosition = WindowHelpers.getVerticalScrollPosition();

    const activeSidebarItem = document.querySelector(
      '.bl4__sidebar-item--level-1.active'
    );
    let sidebarItemToActive = null;
    const userDetails = document.querySelector('.bl4-profile__sidebar-user');
    // check if the scroll position is next to the about container
    if (currentScrollPosition < this.aboutContainer.bottom) {
      sidebarItemToActive = document.querySelector('.bl4__sidebar-item--about');
      Utils.removeClass(userDetails, 'show-details');
    } else if (
      currentScrollPosition > this.aboutContainer.bottom &&
      currentScrollPosition < this.visionContainer.bottom
    ) {
      sidebarItemToActive = document.querySelector(
        '.bl4__sidebar-item--vision'
      );
      Utils.addClass(userDetails, 'show-details');
    } else if (
      currentScrollPosition > this.aboutContainer.bottom &&
      currentScrollPosition > this.visionContainer.bottom &&
      currentScrollPosition < this.portfolioContainer.bottom
    ) {
      Utils.addClass(userDetails, 'show-details');
      sidebarItemToActive = document.querySelector(
        '.bl4__sidebar-item--portflio-2018'
      );
    }
    if (activeSidebarItem) {
      Utils.removeClass(activeSidebarItem, 'active');
    }
    Utils.addClass(sidebarItemToActive, 'active');
  }
  render() {
    const photoNode = (
      <UserPhoto
        src={this.props.pageUser.photo}
        diameter={70}
        hasBorder={true}
      />
    );

    let institutionNode = null;
    if (this.props.pageUser.institution) {
      institutionNode = (
        <div className="bl4-profile__sidebar-user-school">
          {this.props.pageUser.institution.name}
        </div>
      );
    }
    const userDetailsNode = (
      <div className={'bl4-profile__sidebar-user'}>
        {photoNode}
        <div className="bl4-profile__sidebar-user-name">
          {this.props.pageUser.first_name} {this.props.pageUser.last_name}
        </div>
        {institutionNode}
      </div>
    );

    let visionNode = null;
    if (this.props.pageParticipant) {
      visionNode = (
        <a
          className={
            'bl4__sidebar-item bl4__sidebar-item--level-1 bl4__sidebar-item--vision tc__link'
          }
          onClick={(e) => {
            e.preventDefault();
            this.props.scrollToProfileSection({ id: 'bl4-profile__vision' });
          }}
        >
          Vision
        </a>
      );
    }

    let portfolioListNode = null;
    if (this.props.pageUser.archivedPortfolios) {
      const portfolioNodes = this.props.pageUser.archivedPortfolios.map(
        (portfolio) => {
          let portfolioEditableNode = null;
          if (this.props.userCanEditData) {
            portfolioEditableNode = (
              <a
                target="_blank"
                href={
                  '/coaching/lab/participant/' +
                  portfolio.participantId +
                  '/portfolio'
                }
                className="tc__link"
                rel="noreferrer"
              >
                <div className="tc-pencil"></div>
              </a>
            );
          }

          return (
            <div
              className="bl4-profile__sidebar-portfolio bl4__sidebar-item bl4__sidebar-item--level-1 bl4__sidebar-item--portfolio"
              key={'portfolio-' + portfolio.uuid}
            >
              <a
                target="_blank"
                href={'/coaching/lab/participant/portfolio/' + portfolio.uuid}
                className="tc__link"
                rel="noreferrer"
              >
                {portfolio.label || '(no label)'}
              </a>
              {portfolioEditableNode}
            </div>
          );
        }
      );
      portfolioListNode = (
        <div className="bl4__sidebar-portfolios">{portfolioNodes}</div>
      );
    }
    let portfoliosNode = null;
    let sortPortfolioStoriesActionsNode = null;
    if (ArrayHelpers.hasElements(this.props.sidebarStories)) {
      const storiesNode = this.props.sidebarStories.map((story, i) => {
        return (
          <SidebarStoryItem
            key={'sidebar-story-id-' + story.id}
            story={story}
            position={i}
            storySidebarReordering={this.props.storySidebarReordering}
            sortIndicatorActive={this.props.sortIndicatorActive}
            scrollToProfileSection={this.props.scrollToProfileSection}
          />
        );
      });

      const storiesSortingClass = this.props.storySidebarReordering
        ? ' bl4__sidebar-portfolio-stories--sorting'
        : '';
      const SortableBlockStorieseNode = SortableItems(
        <div
          className={'bl4__sidebar-portfolio-stories' + storiesSortingClass}
          id="dragContainerSidebarStories"
        >
          {storiesNode}
        </div>,
        '#dragContainerSidebarStories',
        'window',
        this.props.dropSidebarStoryReorder
      );
      portfoliosNode = (
        <div>
          <a
            className={
              'bl4__sidebar-item bl4__sidebar-item--level-1 bl4__sidebar-item--portflio-2018 tc__link'
            }
            onClick={(e) => {
              e.preventDefault();
              this.props.scrollToProfileSection({
                id: 'bl4-profile__portfolio-2018',
              });
            }}
          >
            2018-2019 Portfolio
          </a>
          <SortableBlockStorieseNode />
        </div>
      );
      sortPortfolioStoriesActionsNode = (
        <div className="bl4__sidebar-sort-actions ">
          <div
            className="tc__link tc-pencil"
            onClick={this.props.toggleEditSidebarStoriesSortOrder}
          ></div>
        </div>
      );
      if (this.props.storySidebarReordering) {
        sortPortfolioStoriesActionsNode = (
          <div className="bl4__sidebar-sort-actions">
            <a
              className="tc__link tc__link--cancel"
              onClick={this.props.cancelSidebarStoryReorder}
            >
              Cancel
            </a>
            <a
              className="tc__link"
              onClick={this.props.saveSidebarStoryReorder}
            >
              Done
            </a>
          </div>
        );
      }
      if (!this.props.userCanEditData) {
        sortPortfolioStoriesActionsNode = null;
      }
    }
    return (
      <div
        className={
          'bl4__sidebar bl4__sidebar--sticky bl4__capsule bl4__capsule--header-graphic bl4__profile-sidebar'
        }
      >
        <div className="bl__sidebar-inner">
          {userDetailsNode}
          <div className="">
            <a
              className={
                'bl4__sidebar-item bl4__sidebar-item--level-1 bl4__sidebar-item--about tc__link'
              }
              onClick={(e) => {
                e.preventDefault();
                this.props.scrollToProfileSection({ id: 'bl4-profile__about' });
              }}
            >
              About
            </a>
          </div>
          {visionNode}
          {portfoliosNode}
          {portfolioListNode}
          {sortPortfolioStoriesActionsNode}
          <WindowScrollElementFixer
            positionThisClass={'bl4__profile-sidebar'}
            positionToClass={'bl4-profile__main'}
            positionThisContainerClass={'bl4-profile__sidebar-container'}
            topMargin={60}
            updateFlag={JSON.stringify(this.props.stories)}
          />
        </div>
      </div>
    );
  }
}

export default Sidebar;

Sidebar.propTypes = {};
