// @ts-nocheck FIXME
import React from 'react';

class PageBreakV2 extends React.Component {
  render() {
    return <hr className="page-break" />;
  }
}

export default PageBreakV2;
