// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';

const RegistrationAlready = (props) => {
  const handleGoHome = () => {
    location.href = '/lab/home';
  };

  return (
    <div className="tc__page tc__page--registration">
      <div className="tc-header__wrap tc-header__wrap--v2">
        <div className="tc-header tc-header--v2 row row--1200">
          <div
            className="logo tc-header__logo cute-3-desktop cute-3-phone left tc-header__registration-phone"
            onClick={handleGoHome}
            src="/legacy-images/betterlesson-logo.svg"
          ></div>
        </div>
      </div>

      <div className="coaching-reg__background-image"></div>
      <div className="event-reg__background-overlay"></div>

      <div className="row row--1200">
        <div className="cute-5-desktop cute-10-phone center-desktop center-phone registration__already-box">
          <div className="v5__heading-2">
            Hold on. You’re already registered.
          </div>
          <div className="v5__body-copy">
            You’re registered for {props.displayName}, so you cannot register
            for this coaching cohort. If you have questions, contact your
            administrator.
          </div>
          <div className="v5__heading-4">Start Exploring</div>
          <div className="v5__body-copy">
            As a registered user, you have access to the BetterLesson Lab, where
            you will work with your coach and can explore thousands of lessons
            and strategies.
          </div>
          <NavigationLink className="v5__btn center-desktop" url="/lab/home">
            Explore the Lab
          </NavigationLink>
        </div>
      </div>
    </div>
  );
};

RegistrationAlready.propTypes = {
  displayName: PropTypes.string.isRequired,
};

export default RegistrationAlready;
