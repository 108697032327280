// @ts-nocheck FIXME
const Fail = (props) => {
  const showDialog = () => {
    const dialog = document.querySelector('#clipboard');
    dialog.showModal();
    setTimeout(() => dialog.close(), 3000);
  };

  const copyErrors = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const networks = props.checkedNetworks
      .map(
        (network) =>
          `${network.displayUrl}: ${network.statusLabel} - ${
            network.status === 'error' ? network.errorMessage : network.status
          }`
      )
      .join('\n');

    if (isSecureContext && navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(networks);
        showDialog();
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }

    return false;
  };

  return (
    <div className="row tc__center-text">
      <div className="network-fail">
        <h2 className="v5__heading-4">
          Some of your network connections are restricted.
        </h2>

        <p className="v5__body-copy">
          Access to all of the following sites are required to use
          BetterLesson&rsquo;s services.
        </p>

        <p className="v5__body-copy">
          Try using a different network connection, or{' '}
          <a href="#" onClick={(e) => copyErrors(e)}>
            copy this error message
          </a>{' '}
          to send to your school&rsquo;s network administrator.
        </p>
      </div>

      <dialog id="clipboard">Copied to clipboard!</dialog>
    </div>
  );
};

export default Fail;
