// @ts-nocheck FIXME
import Auth from './../../../components/Auth';
import NavigationLink from './../../NavigationLink';
import PortfolioPrint from './TeacherPortfolioPrint';

const PortfolioPreview = (props) => {
  // only allow the user to edit the portfolio if they are authorized
  let editNode, editTextNode;
  if (Auth.canEditData(props.user, props.teacherId)) {
    editNode = (
      <NavigationLink
        className="portfolio__preview-overlay-headline-link tc__link--white"
        url={
          '/coaching/lab/teacher/' +
          props.portfolio.user_id +
          '/portfolio/' +
          props.portfolio.id +
          '?from=tp_preview'
        }
      >
        <span className="tc-pencil link-icon"></span>
        Edit
      </NavigationLink>
    );
    editTextNode = (
      <div className="portfolio__print-preview-subheadline">
        To customize your portfolio, click
        <NavigationLink
          className="portfolio__preview-overlay-body-link tc__link--white"
          url={
            '/coaching/lab/teacher/' +
            props.portfolio.user_id +
            '/portfolio/' +
            props.portfolio.id +
            '?from=tp_preview'
          }
        >
          Edit
        </NavigationLink>
        .
      </div>
    );
  }

  return (
    <div className="portfolio__preview-overlay-container">
      <div className="portfolio__preview-overlay">
        <div className="row">
          <div className="cute-11-desktop">
            <div className="portfolio__preview-overlay-headline">
              <span className="portfolio__preview-overlay-headline-text left">
                {props.portfolio.name}
              </span>
              <div className="portfolio__preview-overlay-headline-links left">
                {editNode}
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="portfolio__print-preview-subheadline">
              This is a preview of your portfolio, created with all of your data
              from TeachCycle.
            </div>
            {editTextNode}
          </div>
          <div className="cute-1-desktop">
            <NavigationLink
              className="portfolio__preview-overlay-close-icon"
              url={
                '/coaching/lab/teacher/' +
                props.portfolio.user_id +
                '/portfolios?from=tp_preview'
              }
            >
              <div className="tc-x"></div>
            </NavigationLink>
          </div>
        </div>
      </div>
      <div className="portfolio__preview-overlay-print-background">
        <div className="portfolio__preview-overlay-print-container">
          <PortfolioPrint
            portfolio={props.portfolio}
            entries={props.entries}
            inPreviewMode={true}
          />
        </div>
      </div>
    </div>
  );
};

export default PortfolioPreview;
