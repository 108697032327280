// @ts-nocheck FIXME
import EventApi from '@legacy-components/apis/V5/EventApi';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import { Router } from '@legacy-components/components/Router';
import NavigationLink from '@legacy-components/react/NavigationLink';
import RowHideNode from '@legacy-components/react/V5/Common/ExpandedRowHide';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const EventRow = (props) => {
  const [columnExpanded, setColumnExpanded] = React.useState(false);
  const [syncing, setSyncing] = useState(
    moment().diff(moment(props.event.cb_synced), 'hour') < 1
  );

  const { event, rowExpanded } = props;

  const handleNavigateSessions = (e) => {
    e.preventDefault();
    Router.route('/bl/event/' + event.id + '/sessions');
  };

  const handleOutlinkEvent = (e) => {
    e.preventDefault();
    window.open(
      'https://betterlesson.quickbase.com/db/bpfpnyi2u?a=dr&rid=' + event.cb_id
    );

    return;
  };

  const handleSyncEventToCB = () => {
    setSyncing(true);

    const data = {
      eventId: event.id,
    };

    // Sync event to CB
    EventApi.syncEventToCB(data)
      .then(() => {
        props.setAlert({
          type: 'warning',
          message: 'Sync Initiated.  Please check back later.',
        });
      })
      .catch(() =>
        props.setAlert({
          type: 'error',
          message: `Unable to sync event ${event.name} with Facilitation App`,
        })
      );
  };

  const handleExpandDate = () => {
    if (rowExpanded === event.id) {
      if (columnExpanded === 'date') {
        // Toggle close
        // Close row and column
        handleCloseRow();
      } else {
        // Switch expanded column in this row
        setColumnExpanded('date');
      }
    } else {
      // Toggle open or change row
      // Update both the row and column
      props.setRowExpanded(event.id);
      setColumnExpanded('date');
    }
  };

  const missionFacilatatorIcon = event.sessions.some(
    (s) => !s.lead_facilitator_count
  ) ? (
    <div className="tc-v5-profile" title="Facilitators are missing" />
  ) : null;

  // check if any session in the event is missing a date
  const missionSessionDateIcon = event.sessions.some((s) => !s.session_date) ? (
    <div className="tc-v5-meeting" title="Session dates are missing" />
  ) : null;

  // check if any session in the event does not have its materials finalized
  const missingAgendaDeckIcon = event.sessions.some(
    (s) => !s.materials_finalized
  ) ? (
    <div className="tc-v5-notes" title="Event materials are missing" />
  ) : null;

  // row for row expander
  let expandedRow = null;

  // set up the PSMs column
  let psmsNode;
  if (ArrayHelpers.hasElements(event.psms)) {
    if (event.psms.length === 1) {
      psmsNode = event.psms[0].name;
    } else {
      psmsNode = (
        <ul className="admin-table__event-psms-list">
          {event.psms.map((psm) => {
            return (
              <li key={`psm-${event.id}-${psm.id}`}>
                <span className="admin-table__event-psm">{psm.name}</span>
              </li>
            );
          })}
        </ul>
      );
    }
  }

  // set up the Date and Attendees columns
  let eventSessionTimeNode = null;

  // make eventName a link if we have cb_id
  // display a text otherwise
  const eventNameNode = event.cb_id ? (
    <NavigationLink url={'/bl/event/' + event.id} className="tc__link">
      {event.name}
    </NavigationLink>
  ) : (
    event.name
  );

  const maxCapacityNode = event.max_capacity ? (
    <div className="admin-v5__max-capacity">
      max
      <div> {event.max_capacity} </div>
    </div>
  ) : null;
  // make attandance a link if we have display_name and cb_id
  // display text otherwise
  const eventSessionAttendanceNode =
    event.display_name && event.cb_id ? (
      <NavigationLink
        className="v5__link"
        url={'/bl/event/' + event.id + '/participants'}
      >
        {event.attendance}
      </NavigationLink>
    ) : (
      event.attendance
    );

  const eventSessionAndMaxCapacityAttendanceNode = (
    <div>
      {eventSessionAttendanceNode}
      {maxCapacityNode}
    </div>
  );

  const groupNames = {};
  event.groups.forEach((group) => (groupNames[group.id] = group.name));
  // Handle Expanding the Date
  if (ArrayHelpers.hasElements(event.groups)) {
    eventSessionTimeNode = [];
    // Only show the first group
    const group = event.groups[0];
    // get a list of sessions in this group
    const sessions = event.sessions.filter((s) => s.group_id === group.id);

    // gather session-specific data
    const sessionTimes = [];
    sessions.forEach((session) => {
      const sessionDate =
        session.session_date_formatted &&
        moment(session.session_date_formatted);
      const sessionDateNode = sessionDate ? (
        sessionDate.format('M/DD/YY')
      ) : (
        <span>&mdash;</span>
      );
      const sessionDatePast =
        (sessionDate && moment().diff(sessionDate, 'days') > 0 && 'past') || '';

      sessionTimes.push(
        <li key={`session-time-${session.id}`}>
          <span className={sessionDatePast}>{sessionDateNode}</span>
        </li>
      );
    });

    const expandGroupsLink = (
      <div className="v5__link" onClick={handleExpandDate}>
        + {event.groups.length - 1} more
      </div>
    );

    eventSessionTimeNode.push(
      <div key={group.id} className="admin-table__event-group">
        <div className="admin-table__event-group-name">{group.name}</div>
        <ol className="admin-table__event-session-list">{sessionTimes}</ol>
        {expandGroupsLink}
      </div>
    );
  } else if (
    ArrayHelpers.hasElements(event.sessions) &&
    event.sessions.length === 1
  ) {
    const session = event.sessions[0];
    const sessionDate =
      session.session_date_formatted && moment(session.session_date_formatted);
    if (sessionDate) {
      const sessionDatePast =
        (moment().diff(sessionDate, 'days') > 0 && 'past') || '';
      eventSessionTimeNode = (
        <ol className="admin-table__event-session-list">
          <li className={sessionDatePast}>{sessionDate.format('MM/DD/YY')}</li>
        </ol>
      );
    } else {
      eventSessionTimeNode = (
        <ol className="admin-table__event-session-list">
          <li className="">
            <span>&mdash;</span>
          </li>
        </ol>
      );
    }
  } else {
    const sessionTimes = [];
    event.sessions.forEach((session) => {
      const sessionDate =
        session.session_date_formatted &&
        moment(session.session_date_formatted);
      const sessionDateNode = sessionDate ? (
        sessionDate.format('MM/DD/YY')
      ) : (
        <span>&mdash;</span>
      );

      const sessionDatePast =
        (sessionDate && moment().diff(sessionDate, 'days') > 0 && 'past') || '';

      sessionTimes.push(
        <li key={`session-time-${session.id}`}>
          <span className={sessionDatePast}>{sessionDateNode}</span>
        </li>
      );
    });

    eventSessionTimeNode = (
      <ol className="admin-table__event-session-list">{sessionTimes}</ol>
    );
  }

  // Get all the unique product codes and facilitators
  const eventFacilitators = [];
  const eventFacilitatorIds = new Set();
  const sessionProductCodes = new Set();
  const sessionsByGroup = {};
  let facilitatorCount = 0;
  const firstSessionFacilitatorIds = new Set();
  const firstGroup = event.groups && event.groups.length && event.groups[0];
  const firstGroupId = firstGroup ? firstGroup.id : null;
  event.sessions.forEach((session) => {
    // Get all unique session facilitators
    session.facilitators.forEach((facilitator) => {
      if (!eventFacilitatorIds.has(facilitator.id)) {
        facilitatorCount += 1;
        eventFacilitatorIds.add(facilitator.id);
      }
      if (
        !firstSessionFacilitatorIds.has(facilitator.id) &&
        session.group_id === firstGroupId
      ) {
        firstSessionFacilitatorIds.add(facilitator.id);
        eventFacilitators.push(facilitator);
      }
    });

    if (session.group_id in sessionsByGroup) {
      sessionsByGroup[session.group_id].push(session);
    } else {
      sessionsByGroup[session.group_id] = [session];
    }

    if (
      session.ld_product &&
      session.ld_product.product_code !== event.product_code
    ) {
      sessionProductCodes.add(session.ld_product.product_code);
    }
  });

  const sessionProducts = Array.from(sessionProductCodes).map((productCode) => {
    return (
      <div className={'admin-table__event-fa'} key={productCode}>
        {productCode}
      </div>
    );
  });

  let eventProductCode = null;
  if (event.product_code) {
    eventProductCode = (
      <div className="admin-table__bold">{event.product_code}</div>
    );
  }

  let editAndOutlinkNode = (
    <div
      className="tc-v5-cloud-sync admin-table__icon"
      onClick={handleSyncEventToCB}
    ></div>
  );

  // only show edit and outlink buttons if we have cb_id and the record was last edited more than an hour ago
  if (event.cb_id) {
    editAndOutlinkNode = (
      <>
        <span
          title="View Sessions"
          className="tc-session admin-table__icon green"
          onClick={handleNavigateSessions}
        ></span>
        <span
          title="View event in the Facilitation App"
          className="tc-outlink admin-table__icon green"
          onClick={handleOutlinkEvent}
        ></span>
      </>
    );
  } else if (syncing) {
    // if the sync button has been pressed show the spinner
    editAndOutlinkNode = <div className="littlespinner"></div>;
  }

  const handleCloseRow = () => {
    setColumnExpanded(null);
    props.setRowExpanded(null);
  };

  // Row expander functions
  const handleExpandFacilitator = () => {
    if (rowExpanded === event.id) {
      if (columnExpanded === 'facilitator') {
        // Toggle close
        // Close row and column
        handleCloseRow();
      } else {
        // Switch expanded column in this row
        setColumnExpanded('facilitator');
      }
    } else {
      // Toggle open or change row
      // Update both the row and column
      props.setRowExpanded(event.id);
      setColumnExpanded('facilitator');
    }
  };

  // Facilitators column
  let eventSessionFacilitatorsNode = null;
  if (eventFacilitatorIds.size) {
    // Sort facilitators by role
    const roleOrder = {
      LEAD: 0,
      CO: 1,
    };

    eventFacilitators.sort((a, b) => roleOrder[a.role] - roleOrder[b.role]);

    eventSessionFacilitatorsNode = eventFacilitators.map((facilitator, i) => {
      // We want this expanded if any column is expanded
      const hiddenClass = i >= 3 ? 'tc__hidden' : '';
      return (
        <div
          key={`facilitator-${facilitator.id}`}
          className={`${hiddenClass}${
            hiddenClass ? ' v5__body-copy--bold' : 'v5__body-copy--bold'
          }`}
        >
          {facilitator.label}
        </div>
      );
    });

    if (event.groups.length > 1) {
      eventSessionFacilitatorsNode.push(
        <div
          key="facilitator-expander"
          className="v5__link"
          onClick={handleExpandFacilitator}
        >
          + {facilitatorCount - eventFacilitators.length} More
        </div>
      );
    }
  }
  const sessionMap = props.event.session_attendance_map;
  const sessionMapKeys = Object.keys(sessionMap).sort(
    (key1, key2) =>
      parseInt(key1.substring(0, key1.indexOf(':'))) -
      parseInt(key2.substring(0, key2.indexOf(':')))
  );
  const sessionMapTooltip = sessionMapKeys.map((key) => (
    <div key={key}>
      {sessionMap[key]['name']}: {sessionMap[key]['attendance']}
    </div>
  ));
  let tooltipOffset;
  switch (`${props.event.attendance}`.length) {
    case 3:
      tooltipOffset = 12;
      break;
    case 2:
      tooltipOffset = 8;
      break;
    case 1:
      tooltipOffset = 4;
      break;
    default:
      break;
  }
  const sessionMapTooltipNode = (
    <Tooltip
      tooltip={sessionMapTooltip}
      offsetLeft={tooltipOffset}
      offsetTop={20}
      minWidth={121}
      tipClass="admin-table__event-session-attendance-tooltip"
    >
      {eventSessionAndMaxCapacityAttendanceNode}
    </Tooltip>
  );

  let activeClass = '';
  if (rowExpanded === event.id) {
    const groups = Object.keys(sessionsByGroup);
    activeClass = ' active';
    switch (columnExpanded) {
      case 'date':
        expandedRow = (
          <div className="grid-big__row expanded">
            {groups.map((group, i) => {
              const groupName = groupNames[group];
              let groupNameTooltipNode = (
                <span className="admin-table__event-group-name truncate">
                  {groupName || `${i + 1}`}
                </span>
              );
              if (groupName) {
                groupNameTooltipNode = (
                  <Tooltip
                    className="admin-table__event-session-group-title"
                    tooltip={groupName}
                  >
                    {groupNameTooltipNode}
                  </Tooltip>
                );
              }
              const namedSessionDates = [];
              const sessionDatesNode = sessionsByGroup[group].map((session) => {
                const sessionDate =
                  session.session_date_formatted &&
                  moment(session.session_date_formatted);
                if (sessionDate) {
                  const sessionDatePast =
                    moment().diff(sessionDate, 'days') > 0 ? ' past' : '';
                  if (session.session_start_time && session.session_end_time) {
                    namedSessionDates.push(
                      <div>
                        {session.name}:{' '}
                        {moment(session.session_start_time_formatted)
                          .tz('America/New_York')
                          .format('h:mm A z')}{' '}
                        -{' '}
                        {moment(session.session_end_time_formatted)
                          .tz('America/New_York')
                          .format('h:mm A z')}
                      </div>
                    );
                  } else {
                    namedSessionDates.push(<div>{session.name}: &mdash;</div>);
                  }
                  return (
                    <li
                      key={`session-${session.id}`}
                      className={`admin-table__event-session-list-item${sessionDatePast}`}
                    >
                      {sessionDate.format('MM/DD/YY')}
                    </li>
                  );
                } else {
                  namedSessionDates.push(<div>{session.name}: &mdash;</div>);
                  return (
                    <li
                      key={`session-${session.id}`}
                      className={'admin-table__event-session-list-item$'}
                    >
                      &mdash;
                    </li>
                  );
                }
              });
              const sessionTooltipNode = (
                <Tooltip
                  tipClass="admin-table__event-session-attendance-tooltip"
                  tooltip={namedSessionDates}
                  offsetLeft={-31}
                  offsetTop={52}
                  minWidth={250}
                >
                  {sessionDatesNode}
                </Tooltip>
              );

              return (
                <div
                  key={`group-${group}`}
                  className="grid-big__col-5--start-v flex-col thin event-v5__row"
                >
                  {groupNameTooltipNode}
                  <ol className="admin-table__event-session-list heading">
                    {sessionTooltipNode}
                  </ol>
                </div>
              );
            })}
            <RowHideNode hideRow={handleCloseRow} />
          </div>
        );
        break;
      case 'facilitator':
        expandedRow = (
          <div className="grid-big__row expanded">
            {groups.map((group, i) => {
              const groupName = groupNames[group];
              let groupNameTooltipNode = (
                <span className="admin-table__event-group-name truncate">
                  {groupName || `${i + 1}`}
                </span>
              );
              if (groupName) {
                groupNameTooltipNode = (
                  <Tooltip
                    className="admin-table__event-session-group-title"
                    tooltip={groupName}
                  >
                    {groupNameTooltipNode}
                  </Tooltip>
                );
              }
              return (
                <div
                  key={`group-${group}`}
                  className="grid-big__col-5--start-v flex-col thin event-v5__row"
                >
                  {groupNameTooltipNode}
                  <ul className="admin-table__event-session-list heading">
                    {sessionsByGroup[group].map((session) => {
                      const facilitatorsNode =
                        session.facilitators.length > 0 ? (
                          session.facilitators.map((facilitator) => {
                            return (
                              <div
                                key={`facilitator-${facilitator.id}`}
                                className="v5__body-copy--bold"
                              >
                                {facilitator.label}
                              </div>
                            );
                          })
                        ) : (
                          <div className="v5__body-copy--bold">&mdash;</div>
                        );
                      return (
                        <li
                          key={`session-${session.id}`}
                          className="admin-table__event-session-facilitator-list-item"
                        >
                          <div>{session.name}:</div>
                          {facilitatorsNode}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
            <RowHideNode hideRow={handleCloseRow} />
          </div>
        );
        break;
    }
  }

  return (
    <>
      <div className={`grid-big__row${activeClass}`}>
        <div className="grid-big__col-10--start-v admin-table__col--event-name flex-col">
          <div>{eventNameNode}</div>
          <div>{event.display_name}</div>
        </div>
        <div className="grid-big__col-8--start-v admin-table__col--partner-name">
          {event.partner_name}
        </div>
        <div className="grid-big__col-5--start-v">{event.status}</div>
        <div className="grid-big__col-5--start-v admin-table__col--partner-product">
          {eventProductCode}
          {sessionProducts}
        </div>
        <div className="grid-big__col-4--start-v">{psmsNode}</div>
        <div
          className={`grid-big__col-5--start-v${
            rowExpanded === event.id && columnExpanded === 'facilitator'
              ? ' active'
              : ''
          }`}
        >
          <div className="flex-col flex-start">
            {eventSessionFacilitatorsNode}
          </div>
        </div>
        <div
          className={`grid-big__col-5--start-v${
            rowExpanded === event.id && columnExpanded === 'date'
              ? ' active'
              : ''
          }`}
        >
          <div className="flex-col flex-start">{eventSessionTimeNode}</div>
        </div>
        <div className="grid-big__col-3--start-v">{sessionMapTooltipNode}</div>
        <div className="grid-big__col-2--start-v">
          <div className="admin-table__col--event-alerts">
            {missionSessionDateIcon}
            {missionFacilatatorIcon}
            {missingAgendaDeckIcon}
          </div>
        </div>
        <div className="grid-big__col-3--start-v admin-table__links">
          {editAndOutlinkNode}
        </div>
      </div>
      {expandedRow}
    </>
  );
};

EventRow.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventRow;
