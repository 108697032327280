// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import ScaleCenterImage from './../../../../ScaleCenterImage';

const Comments = (props) => {
  const comments = props.comments.map(function (comment, i) {
    let commentAuthorPhotoNode = (
      <div className="artifact-overlay__author-image-mask artifact-overlay__author-image-mask--none">
        <img
          className="artifact-overlay__author-image"
          src="/legacy-images/default-user.svg"
        />
      </div>
    );

    if (comment.photo) {
      commentAuthorPhotoNode = (
        <ScaleCenterImage
          src={comment.photo}
          className="artifact-overlay__author-image"
        />
      );
    }

    return (
      <div className="artifact-overlay__comment-container" key={'comment-' + i}>
        <div className="artifact-overlay__comment-seperator show-phone hide-desktop"></div>
        <div className="artifact-overlay__comment-image">
          {commentAuthorPhotoNode}
        </div>
        <div className="artifact-overlay__comment-data">
          <span className="artifact-overlay__comment-user tc__headline-5">
            {comment.name}
          </span>
          <span className="artifact-overlay__comment-date">
            <TimeAgo date={comment.created} />
          </span>
          <div className="artifact-overlay__comment-text tc__body-copy">
            {comment.data}
          </div>
        </div>
      </div>
    );
  });

  return <div className="artifact-overlay__comments">{comments}</div>;
};

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
};

export default Comments;
