// @ts-nocheck FIXME
import Analytics from '@legacy-components/components/Analytics';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import React from 'react';

class AbridgeText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      abridgeText: false,
      maxHeight: 'auto',
      expanded: false,
    };

    this.toggleExpand = this.toggleExpand.bind(this);
  }

  componentDidMount() {
    // see if we need to abridge the contents
    this.checkAbridge();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.updateSectionExpandToggleCount &&
      (prevState.expanded !== this.state.expanded ||
        prevState.abridgeText !== this.state.abridgeText)
    ) {
      this.props.updateSectionExpandToggleCount();
    } else if (prevProps.children !== this.props.children) {
      // if the contents change, check again if we need to abridge them
      this.checkAbridge();
    }
  }

  checkAbridge() {
    // set default variables
    const lineHeight = this.props.lineHeight ? this.props.lineHeight : 20;
    const lineCount = this.props.lineCount ? this.props.lineCount : 4;

    // get the heights to compare
    const maxHeight = this.props.maxHeight
      ? this.props.maxHeight
      : lineHeight * lineCount;
    const elemHeight = this.thisElem.offsetHeight;

    // if the contents are taller than the max allowed height, abridge them
    if (elemHeight > maxHeight) {
      if (this.props.updateSectionExpandToggleCount) {
        // update the section toggle count so that elements that rely on page height know that it chagned
        this.props.updateSectionExpandToggleCount();
      }
      this.setState({
        abridgeText: true,
        maxHeight: maxHeight,
      });
    }
    // if the contents are shorter than the max allowed height, don't abridge them
    else if (elemHeight < maxHeight) {
      this.setState({
        abridgeText: false,
        maxHeight: 'auto',
      });
    }
    // note that if the contents are equal to the max allowed height, we want to make no changes
    // (if the contents start at that height then we don't want to abridge,
    // but if they are taller and then we abridge, the height will become equal
    // and we want to continue abridging)
  }

  toggleExpand(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (this.props.analyticsCategory && this.props.analyticsLabel) {
      Analytics.logEvent({
        category: this.props.analyticsCategory,
        action: this.state.expanded ? 'collapse' : 'expand',
        label: this.props.analyticsLabel,
      });
    }

    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    // add a class to abridge the text as necessary
    let abridgeClass = this.state.abridgeText ? ' abridge-text' : '';
    if (this.state.abridgeText && this.props.greyFade) {
      abridgeClass += ' abridge-text--grey-v5';
    }

    // fade either just the last line horizontally or the whole text vertically,
    // depending on whether we have the line height or not
    abridgeClass += this.props.lineHeight ? ' fade-last-line' : ' fade-whole';

    // set up max height
    let style = { maxHeight: this.state.maxHeight };

    // if just displaying text from a WYSIWYG, we want to set it as the inner html
    if (this.props.setInnerHtml) {
      return (
        <PurifiedHTML
          ref={(thisElem) => {
            this.thisElem = thisElem;
          }}
          className={this.props.className + abridgeClass}
          style={style}
          content={this.props.children}
        />
      );
    }

    // set up the Show More link or expand the text if necessary
    let showMoreNode;
    if (this.props.isExpandable && this.state.abridgeText) {
      if (!this.state.expanded) {
        showMoreNode = (
          <div className="abridge-text__show-more">
            <span
              className="tc__link v5__toggle-copy"
              onClick={this.toggleExpand}
            >
              Show All
            </span>
          </div>
        );
      } else {
        abridgeClass = '';
        style = null;
        showMoreNode = (
          <div className="abridge-text__show-more">
            <span
              className="tc__link v5__toggle-copy"
              onClick={this.toggleExpand}
            >
              Show Less
            </span>
          </div>
        );
      }
    }

    return (
      <div className={this.props.containerClassName}>
        <div
          ref={(thisElem) => {
            this.thisElem = thisElem;
          }}
          className={this.props.className + abridgeClass}
          style={style}
        >
          {this.props.children}
        </div>
        {showMoreNode}
      </div>
    );
  }
}

AbridgeText.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  lineHeight: PropTypes.number,
  lineCount: PropTypes.number,
  maxHeight: PropTypes.number,
  setInnerHtml: PropTypes.bool,
  isExpandable: PropTypes.bool,
  greyFade: PropTypes.bool,
  analyticsCategory: PropTypes.string,
  analyticsLabel: PropTypes.string,
  updateSectionExpandToggleCount: PropTypes.func,
};

AbridgeText.defaultProps = {
  containerClassName: '',
  className: '',
};

export default AbridgeText;
