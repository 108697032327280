// @ts-nocheck FIXME
import FavoriteApi from './../../apis/V4/FavoriteApi';
import Analytics from './../../components/Analytics';
import Utils from './../../components/StaticUtils';
import StorageHelper from './../../components/StorageHelper';
import Store from './../../components/Store';
import HeaderStore from './../HeaderStore';

const store = {
  results: [],
  ui: {
    alert: {},
    meetingStarting: false,
    districtCoachTeachers: {
      sortColumn: 'last_name',
      sortDirection: 'ascending',
    },
    teacherToDelete: {},
    invitationLinkModal: {
      callbacks: {
        onOpen: (teacher) => {
          store.ui.invitationDetails = teacher;
          Analytics.logEvent({
            category: 'dt_registration_link_modal',
            action: 'open',
            label: 'from_district_coach_dashboard',
          });
        },
        onClose: () => {
          store.ui.invitationDetails = {};
        },
      },
    },
    confirmSendInvitationModal: {
      callbacks: {
        onOpen: (teacher) => {
          store.ui.invitationDetails = teacher;
          Analytics.logEvent({
            category: 'resend_dt_registration_email_modal',
            action: 'open',
            label: 'from_district_coach_dashboard',
          });
        },
        onClose: () => {
          store.ui.invitationDetails = {};
          store.ui.modalError = null;
        },
      },
    },
    invitationDetails: {},
    modalError: null,
  },
  blogCapsule: {},
  favorites: [],
  pageParticipant: {},
  pageCoach: {},
  pageUser: {},
  user: {},
};

/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};
const setAlert = (type, message) => {
  store.ui.alert = {
    type: type,
    message: message,
    handleClose: store.clearAlert,
  };
};

const sortDateTime = (a, b) => {
  if (!a) {
    if (!b) {
      return 0;
    }
    return 1;
  } else if (!b) {
    return -1;
  }
  if (a > b) {
    return -1;
  } else {
    return 1;
  }
};

const initCoachHomeState = (data) => {
  store.pageCoach = data.pageCoach;

  if (!store.pageCoach) {
    return;
  }

  // keep dashboard sorting in local storage.
  const localSortState = StorageHelper.init(
    'DistrictCoachDashboardSortState',
    defaultSortState
  );

  // this is the default sort column and direction from backend.
  const defaultSortState = {
    column: 'last_name',
    direction: 'ascending',
  };

  //sort alphabetically
  if (localSortState.column === 'last_name') {
    store.pageCoach.teachers.sort((a, b) => {
      const nameA = (a.user.last_name + a.user.first_name).toLowerCase();
      const nameB = (b.user.last_name + b.user.first_name).toLowerCase();
      return nameA.localeCompare(nameB);
    });
  } else if (localSortState.column === 'teacher_status') {
    store.pageCoach.teachers.sort((a, b) =>
      sortDateTime(a.created_formatted, b.created_formatted)
    );
  } else if (localSortState.column === 'teacher_activity') {
    store.pageCoach.teachers.sort((a, b) =>
      sortDateTime(a.lastest_activity, b.lastest_activity)
    );
  }
  if (localSortState.direction === 'descending') {
    store.pageCoach.teachers.reverse();
  }
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.user = data.user;
  store.ui.alert = {};
  store.blogCapsule = data.blogCapsule || {};
  store.favorites = data.favorites || [];
  store.pageParticipant = data.pageParticipant;
  store.pageUser = data.pageUser;
  store.user = data.user;
  store.results = data.results ? data.results : [];

  initCoachHomeState(data);

  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.addFavorite = (data) => {
  if (store.user && !store.user.id) {
    HeaderStore.actionDispatch('openRegisterModal', {
      reason: 'favorite_attempt',
    });
    return true;
  }
  if (data.recommend) {
    data.userId = store.ui.participants.selectedOption.user_id;
  } else {
    // setup page context for analytics
    const analyticsLabel =
      store.searchContext === 'favorites'
        ? 'from_favorites'
        : 'from_search_results';

    Analytics.logEvent({
      category: data.itemType,
      action: 'favorite',
      label: analyticsLabel,
    });
  }
  const resultIndex = Utils.getItemIndexFromArrayOfObjects(
    store.results,
    'id',
    data.itemId
  );
  store.results[resultIndex].favorite = {};
  store.results[resultIndex].favorite.status = 'loading-favorite';

  return FavoriteApi.addFavorite(data)
    .then((response) => {
      if (data.recommend) {
        setAlert(
          'Recommended for ' +
            store.ui.participants.selectedOption.first_name +
            ' ' +
            store.ui.participants.selectedOption.last_name,
          'alert'
        );
        store.ui.participants.charCount = 0;
        store.ui.participants.selectedOption = null;
        store.ui.participants.visibleOptions = [];
        store.ui.participants.inputStatus = 'blur';
        store.ui.recommendModal.close();
        return;
      }
      store.results[resultIndex].favorite_count++;
      store.results[resultIndex].favorite = response.payload;
      const fav = response.payload;
      // create lesson favorite object
      switch (store.results[resultIndex].type) {
        case 'lesson':
          fav.type = 'lesson';
          fav.lesson = {
            name: store.results[resultIndex].title,
            id: store.results[resultIndex].id,
            seo_url: store.results[resultIndex].seo_url,
            course_title: store.results[resultIndex].course_name,
            unit: store.results[resultIndex].unit_name,
          };
          break;
        case 'strategy':
          fav.type = 'strategy';
          fav.strategy = {
            title: store.results[resultIndex].title,
            id: store.results[resultIndex].id,
            strategy_type: store.results[resultIndex].strategy_type,
            seo_url: store.results[resultIndex].seo_url,
          };
          break;
      }
      // add the favorites
      store.favorites.unshift(fav);
    })
    .catch((response) => {
      store.results[resultIndex].favorite = {};
      setErrorAlert(
        response.error
          ? response.error
          : 'Sorry, something went wrong. Please try again later.'
      );
    });
};

store.removeFavorite = (data) => {
  const resultIndex = Utils.getItemIndexFromArrayOfObjects(
    store.results,
    'id',
    data.itemId
  );
  store.results[resultIndex].favorite.status = 'loading-unfavorite';

  // setup page context for analytics
  const analyticsLabel =
    store.searchContext === 'favorites'
      ? 'from_favorites'
      : 'from_search_results';

  Analytics.logEvent({
    category: data.itemType,
    action: 'unfavorite',
    label: analyticsLabel,
  });

  return FavoriteApi.removeFavorite(data)
    .then((response) => {
      store.results[resultIndex].favorite = null;
      store.results[resultIndex].favorite_count--;
      const favIndex = Utils.getItemIndexFromArrayOfObjects(
        store.favorites,
        'id',
        response.id
      );
      // remove the favorite
      store.favorites.pop(store.favorites[favIndex]);
    })
    .catch((response) => {
      store.results[resultIndex].favorite.status === 'selected';
      setErrorAlert(response.error);
    });
};

store.readyStartNextMeeting = () => {
  store.ui.meetingStarting = true;
};

export default Store('HomeStore', store);
