// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';

const SearchView = (props) => {
  let visibleClass = '';
  let containerVisibleClass = '';
  if (props.mobileSearchOpened) {
    visibleClass = 'header__search-bar--visible';
    containerVisibleClass = 'header__search-container--visible';
  }
  // show any items in the search autocomplete list
  let searchAutocompleteNode;
  if (ArrayHelpers.hasElements(props.autoCompleteResults)) {
    searchAutocompleteNode = (
      <ul className="header__search-autocomplete tc__body-copy">
        {props.autoCompleteResults.map((item) => {
          // dropdown menu item UI depends on its type
          let itemNode;
          switch (item.type) {
            case 'cc_standard':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => props.handleCCStandardSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  <span className="tag">{item.identifier}</span>
                  <span className="description">
                    {item.text.replace('\n', '')}
                  </span>
                </li>
              );
              break;
            case 'tag':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => props.handleKeywordSearch(item.text)}
                  className="header__search-autocomplete-item"
                >
                  {item.text}
                </li>
              );
              break;
            case 'subject':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => props.handleSubjectSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  {item.text}
                </li>
              );
              break;
            case 'learning_domain':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => props.handleLearningDomainSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  <span className="tag">{item.text.replace('\n', '')}</span>
                </li>
              );
              break;
            case 'specialty_tag':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => props.handleSpecialtyTagSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  {item.text}
                </li>
              );
              break;
            case 'practice_tag':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => props.handlePracticeTagSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  {item.text}
                </li>
              );
              break;
            case 'danielson_component':
              itemNode = (
                <li
                  key={item.type + item.id}
                  onClick={() => props.handleDanielsonComponentSearch(item.id)}
                  className="header__search-autocomplete-item"
                >
                  <span className="tag">{item.identifier}</span>
                  <span className="description">{item.text}</span>
                </li>
              );
              break;
          }

          return itemNode;
        })}
      </ul>
    );
  }

  let searchClass = 'show-phone hide-desktop'; // This style used important so overriding below

  if (props.responsive) {
    searchClass = 'header-show-phone header-hide-desktop';
  }

  return (
    <div
      className={`header__search-container header__search-container--v5 ${containerVisibleClass}`}
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <div
        className={`header__search-mobile-btn tc__link tc-search ${searchClass}`}
        onClick={props.openMobileSearch}
      ></div>
      <form
        className={`header__search-bar ${visibleClass}`}
        onSubmit={props.handleSearchSubmit}
        onKeyPress={props.handleSearchSubmitOnEnter}
      >
        <input
          type="text"
          onFocus={props.handleFocus}
          onChange={props.handleTyping}
          className="v5__input header__v5-search-input"
          placeholder="Search strategies & lessons"
          value={props.searchTerm}
        />

        <button
          className="header__search-button header__search-button--v5 custom-select__single-selected-remove tc__link tc-search"
          onClick={props.handleSearchSubmit}
        ></button>

        {searchAutocompleteNode}
      </form>
    </div>
  );
};

export default SearchView;
