// @ts-nocheck FIXME
import ArtifactOverlay from './../Modals/V2/ArtifactOverlay';
import Header from './Header';
import NoteContainer from './NoteContainer';
import Cover from './Sections/Cover';
import GrowthAreas from './Sections/GrowthAreas';
import Highlights from './Sections/Highlights';
import Impact from './Sections/Impact';
import NarrativeSpotlight from './Sections/Narrative';
import Spotlights from './Sections/Spotlights';
import StrategySpotlight from './Sections/Strategy';
import StudentVoice from './Sections/StudentVoice';
import TeacherSpotlight from './Sections/Teacher';
import TeacherCohort from './Sections/TeacherCohort';
import TeacherMorale from './Sections/TeacherMorale';
import TeacherNoteModal from './TeacherNoteModal';

// push components to an object used in map
const components = {
  COVER: Cover,
  IMPACT_INDICATORS: Impact,
  GROWTH_AREAS: GrowthAreas,
  HIGHLIGHTS: Highlights,
  TEACHER_COHORT: TeacherCohort,
  SPOTLIGHTS: Spotlights,
  STUDENT_VOICE: StudentVoice,
  TEACHER_MORALE: TeacherMorale,
};

const spotlightPageComponents = {
  NarrativeSpotlight: NarrativeSpotlight,
  StrategySpotlight: StrategySpotlight,
  TeacherSpotlight: TeacherSpotlight,
};

const Portfolio = (props) => {
  let bodyNode = null;
  if (props.entry) {
    const SpotlightComponent = spotlightPageComponents[props.entry.type];
    bodyNode = (
      <SpotlightComponent
        entry={props.entry}
        toggleTeacherSpotlightArtifacts={props.toggleTeacherSpotlightArtifacts}
        showArtifact={props.showArtifact}
      />
    );
  } else {
    // map over sections and display the appropriate template
    const sectionComponents = props.sections.map((section, i) => {
      const SectionComponent = components[section.type];
      const changeDisplayedCategory = null;

      if (section.visible) {
        return (
          <SectionComponent
            tab={props.tab}
            portfolio_guid={props.guid}
            {...section}
            changeDisplayedCategory={props.changeDisplayedCategory}
            changeDisplayedGA={props.changeDisplayedGA}
            changeDisplayedTeacher={props.changeDisplayedTeacher}
            clearScrollToTeacher={props.clearScrollToTeacher}
            showTeacherNote={props.showTeacherNote}
            changeDisplayedSpotlightType={props.changeDisplayedSpotlightType}
            displayedCategoryId={props.ui.displayedCategoryId}
            displayedGAId={props.ui.displayedGAId}
            displayedTeacherArray={props.ui.displayedTeacherArray}
            displayedSpotlightTypeId={props.ui.displayedSpotlightTypeId}
            showArtifact={props.showArtifact}
            key={'section-' + section.type + '-' + i}
            displayedTeacher={props.ui.displayedTeacher}
            scrollToTeacher={props.ui.scrollToTeacher}
          />
        );
      }
    });
    bodyNode = <div className="row">{sectionComponents}</div>;
  }

  // handle any open overlay
  const NoteContainerNode = null;
  let overlayNode = null;
  if (props.ui.artifactVisible) {
    overlayNode = (
      <ArtifactOverlay
        {...props.artifact}
        hideArtifact={props.hideArtifact}
        showPreviousPage={props.showPreviousPage}
        showNextPage={props.showNextPage}
        scrollPosition={props.ui.scrollPosition}
        downloadArtifact={props.downloadArtifact}
        artifactDownloading={props.artifactDownloading}
        downloadError={props.downloadError}
        activeArtifactPage={props.ui.activeArtifactPage}
        analyticsLabel="from_partner_portfolio_artifact_modal"
      />
    );
  } else if (props.teacherNote) {
    const teacherNoteModal = (
      <TeacherNoteModal
        {...props.teacherNote}
        showTeacherNote={props.showTeacherNote}
      />
    );

    const NoteWrapper = NoteContainer(<div>{teacherNoteModal}</div>);
    overlayNode = <NoteWrapper />;
  }

  return (
    <div className="partner-portfolio-presentation">
      <Header {...props} />
      {overlayNode}

      {bodyNode}
    </div>
  );
};

export default Portfolio;
