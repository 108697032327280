// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import Header from './Header';
import EditArtifacts from './Sections/EditArtifacts';
import EditCover from './Sections/EditCover';
import EditFeedback from './Sections/EditFeedback';
import EditIntroduction from './Sections/EditIntroduction';
import EditStrategies from './Sections/EditStrategies';
import EditSummary from './Sections/EditSummary';
import SubstepHeader from './SubstepHeader';

const AUTOSAVE_DELAY = 2500;
let autosaveTimeout;

const TeacherPortfolioTool = (props) => {
  const lab = useLegacyLabContext();
  // clear any existing autosave timeout
  if (autosaveTimeout) {
    clearTimeout(autosaveTimeout);
  }
  // set up an autosave timeout if there are changes in the portfolio
  // (we disable autosave if we get an api error when trying to save, so we don't keep trying)
  if (props.autosaveEnabled && props.portfolioChanged) {
    autosaveTimeout = setTimeout(() => {
      props.savePortfolio(undefined, Utils.isServicesManager.call(lab));
    }, AUTOSAVE_DELAY);
  }

  // set up the header and tab body content based on the current step
  let headerNode = <Header {...props} />;
  let contentNode = null;
  // also find the current loop if we are in a loop substep
  const loop =
    props.loops &&
    props.loops.find((loop) => loop.loopId == props.ui.currentLoopId);
  switch (props.ui.currentStep) {
    case 'cover':
      contentNode = <EditCover {...props} />;
      break;
    case 'intro':
      contentNode = (
        <EditIntroduction
          portfolio={props.portfolio}
          updatePortfolio={props.updatePortfolio}
        />
      );
      break;
    case 'strategies':
      contentNode = (
        <EditStrategies
          {...props.ui.strategies}
          teacherId={props.portfolio.user_id}
          published={props.portfolio.published}
          loops={props.loops}
          refreshData={props.refreshData}
          toggleLoopVisibility={props.toggleLoopVisibility}
          toggleLoopEditMenu={props.toggleLoopEditMenu}
          setCurrentLoopSubstep={props.setCurrentLoopSubstep}
        />
      );
      break;
    case 'summary':
      contentNode = (
        <EditSummary
          portfolio={props.portfolio}
          loops={props.loops}
          loopCount={props.loopCount}
          artifactCount={props.artifactCount}
        />
      );
      break;
    case 'artifacts':
      headerNode = (
        <SubstepHeader
          ui={props.ui}
          teacherId={props.portfolio.user_id}
          portfolioId={props.portfolio.id}
          alert={props.ui.alert}
          substep="artifacts"
          saveSubstep={props.saveSubstep}
        />
      );
      contentNode = (
        <EditArtifacts
          {...props.ui.artifacts}
          loop={loop}
          setCurrentArtifact={props.setCurrentArtifact}
          showPreviousPage={props.showPreviousPage}
          showNextPage={props.showNextPage}
          downloadArtifact={props.downloadArtifact}
          toggleArtifactVisibility={props.toggleArtifactVisibility}
          setArtifactEditMode={props.setArtifactEditMode}
          updateArtifact={props.updateArtifact}
          showArtifactComments={props.showArtifactComments}
          toggleArtifactCommentVisibility={
            props.toggleArtifactCommentVisibility
          }
          saveSubstep={props.saveSubstep}
        />
      );
      break;
    case 'feedback':
      headerNode = (
        <SubstepHeader
          ui={props.ui}
          teacherId={props.portfolio.user_id}
          portfolioId={props.portfolio.id}
          alert={props.ui.alert}
          substep="feedback"
          saveSubstep={props.saveSubstep}
        />
      );
      contentNode = (
        <EditFeedback
          {...props.ui.feedback}
          loop={loop}
          setFeedbackEditMode={props.setFeedbackEditMode}
          updateFeedback={props.updateFeedback}
          showFeedbackComments={props.showFeedbackComments}
          toggleFeedbackCommentVisibility={
            props.toggleFeedbackCommentVisibility
          }
          saveSubstep={props.saveSubstep}
        />
      );
      break;
  }

  return (
    <div onClick={props.closeAllLoopEditMenus}>
      {headerNode}
      {contentNode}
    </div>
  );
};

TeacherPortfolioTool.propTypes = {
  ui: PropTypes.object.isRequired,
  loops: PropTypes.array,
  autosaveEnabled: PropTypes.bool,
  portfolioChanged: PropTypes.bool,
};

export default TeacherPortfolioTool;
