// @ts-nocheck FIXME
import EventApi from '@legacy-components/apis/V5/EventApi';
import Clipboard from 'clipboard';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

// Global
import Utils from '@legacy-components/components/DynamicUtils';

import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';

// Common
import NavigationLink from '@legacy-components/react/NavigationLink';

import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import StickyFooter from '@legacy-components/react/V4/Common/StickyFooter';
import FormField from '@legacy-components/react/V5/Common/FormField';
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';

// Local
import EventMetadataAndLinks from './modules/EventMetadataAndLinks';

import EventOverview from './modules/EventOverview';
import GroupsAndSessionsAdvanced from './modules/GroupsAndSessionsAdvanced';
import GroupsAndSessionsBasic from './modules/GroupsAndSessionsBasic';
import SessionMaterials from './modules/SessionMaterials';

// Modals
import SwitchToBasicConfirmationModal from './modules/SwitchToBasicConfirmationModal';

import ConfirmChangeStatusModal from './modules/ConfirmChangeStatusModal';
import ConfirmDownsizeGroupModal from './modules/ConfirmDownsizeGroupModal';
import ConfirmUndeliverSessionModal from './modules/ConfirmUndeliverSessionModal';
import DeleteEventSessionRecordingConfirmationModal from './modules/DeleteEventSessionRecordingConfirmationModal';
import DeleteGroupConfirmationModal from './modules/DeleteGroupConfirmationModal';
import DeleteSessionConfirmationModal from './modules/DeleteSessionConfirmationModal';
import DisconnectEventSessionFromZoomModal from './modules/DisconnectEventSessionFromZoomModal';
import ShareContentModal from './modules/ShareContentModal';

// Capsules
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import ModificationsCapsule from './modules/ModificationsCapsule';

const EditEvent = (props) => {
  const lab = useLegacyLabContext();
  const timezone = props.event.time_zone;
  const [clipboard, setClipboard] = React.useState('');

  const [alert, setAlert] = React.useState({});
  const [modalOpen, setModalOpen] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [scrollTo, setScrollTo] = React.useState();
  const partnerLeads = props.event.partner_leads;
  const [leadEventFacilitor, setLeadEventFacilitator] = React.useState(
    props.event.lead_facilitator
  );

  // this represents the state of the event in the db
  const [event, setEvent] = React.useState(props.event);
  // the rest represent the working data on this page, NOT saved to the db
  const [displayName, setDisplayName] = React.useState(
    props.event.display_name
  );
  const [maxCapacity, setMaxCapacity] = React.useState(
    props.event.max_capacity
  );

  // for the Events Groups & Sessions section
  const [useAdvancedMode, setUseAdvancedMode] = React.useState(
    props.event.advanced_event
  );
  // uniquely identified by num
  const [groups, setGroups] = React.useState(props.event.groups || []);
  // content link sharable with Partner leader
  const [contentLink, setContentLink] = React.useState(
    props.event.content_link || ''
  );

  const visualizeSession = (session) => {
    // set a default name if necessary
    if (!session.name) {
      session.name = `Session ${session.num}`;
    }
    if (session.session_date) {
      // specify a format here so we ignore the timezone that is part of session_date
      session.date = moment(session.session_date, 'ddd, DD MMM YYYY');
    }
    if (session.cancellation_time_formatted) {
      session.cancelledTime = moment(session.cancellation_time_formatted).tz(
        timezone.pytz_timezone
      );
    }
    // adjust the start/end times for the event's timezone
    if (session.session_start_time_formatted) {
      const startTime = moment(session.session_start_time_formatted).tz(
        timezone.pytz_timezone
      );
      session.startTime = startTime;
    }
    if (session.session_end_time_formatted) {
      const endTime = moment(session.session_end_time_formatted).tz(
        timezone.pytz_timezone
      );
      session.endTime = endTime;
    }
    session.changed = false;
  };
  // uniquely identified by index
  const [sessions, setSessions] = React.useState(() => {
    // given a list of event sessions, restructure their data for the frontend
    if (ArrayHelpers.hasElements(props.event.sessions)) {
      const sessions = Utils.clone(props.event.sessions);
      sessions.forEach(visualizeSession);
      return sessions;
    }
    // if the event has no sessions for some reason, start with a default session
    return [{ name: 'Session 1', num: 1, status: 'Scheduled' }];
  });

  // Admins, User Managers (PSMs) are the only ones allowed to add/remove partner leaders
  const canEditPLs =
    Utils.isAdmin.call(lab, props.user) ||
    Utils.isManager.call(lab, props.user);
  const canAccessPLDashboard = Utils.isDataViewer.call(lab, props.user);

  // Admins, User Managers (PSMs), and Services Managers can edit event details (including groups/sessions)
  // they can also add/edit event overviews
  const canEditEventDetails =
    canEditPLs || Utils.isServicesManager.call(lab, props.user);

  const handleCapacityKeyDown = (e) => {
    // do not allow "e", "-", or "." to be typed in the number input
    if ([69, 189, 190].indexOf(e.keyCode) !== -1) {
      e.preventDefault();
    }
  };

  React.useEffect(() => {
    setClipboard(
      new Clipboard('.edit-event__copy-link-text', {
        text: function (trigger) {
          return trigger.getAttribute('copytext');
        },
      })
    );

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, []);

  const handleCopy = () => {
    setAlert({
      type: 'success',
      message: 'Link copied to clipboard',
      handleClose: () => setAlert({}),
    });
  };

  const disconnectZoom = (eventSessionId) => {
    EventApi.disconnectZoom({ eventSessionId: eventSessionId }).then(() => {
      const newSessions = [...sessions];

      const disconnectedSession = newSessions.find(
        (session) => session.id === eventSessionId
      );
      disconnectedSession.zoom_link = 'https://...';
      disconnectedSession.zoom_meeting_id = null;
      disconnectedSession.zoom_join_link = null;

      setSessions(newSessions);
      setModalOpen({});
    });
  };

  const saveContentLink = (link, method) => {
    setLoading(true);
    // method is either 'save' or 'delete'
    EventApi.saveContentLink({
      eventId: props.event.id,
      contentLink: link,
      method: method,
    }).then(
      () => {
        setLoading(false);
        setAlert({
          type: 'success',
          message: `Content link ${method}d`,
          handleClose: () => setAlert({}),
        });
        setContentLink(link);
        setModalOpen({});
      },
      (response) => {
        setLoading(false);
        // show error
        setAlert({
          type: 'error',
          message: response.error,
          handleClose: () => setAlert({}),
        });
        setModalOpen({});
      }
    );
  };

  const switchToAdvancedMode = () => {
    // clean out groups/sessions
    // first, remove all deleted groups that are not saved in the db
    const newGroups = Utils.clone(groups).filter(
      (group) => group.id || !group.deleted
    );
    // then, remove all sessions that are not saved in the db
    // that are either deleted or belong to a deleted group
    const remainingGroupNums = newGroups.map((group) => group.num);
    const newSessions = Utils.clone(sessions).filter((session) => {
      return (
        session.id ||
        (!session.deleted &&
          remainingGroupNums.indexOf(session.group_num) !== -1)
      );
    });

    // update the groups/sessions state
    setGroups(newGroups);
    setSessions(newSessions);
    setScrollTo(null);
    // show the Advanced mode UI
    setUseAdvancedMode(true);
    WindowHelpers.navigationConfirmation(true);
  };

  const openSwitchToBasicConfirmationModal = (groupToDelete) => {
    // the first group's session data will be used as the "default" for all other groups
    const defaultGroup = groups.find((group) => !group.deleted);
    const defaultSessions = sessions.filter((session) => {
      return session.group_num === defaultGroup.num && !session.deleted;
    });

    // make a list of sessions with event materials that will get deleted by this action
    const lostSessions = [];
    sessions.forEach((session) => {
      // if this session num is higher than the num of sessions in the default group,
      // it will get deleted when we switch back to Basic mode
      if (
        !session.deleted &&
        session.group_num &&
        session.num > defaultSessions.length
      ) {
        const hasEventMaterials =
          ArrayHelpers.hasElements(session.facilitators) ||
          session.agenda_link ||
          session.slide_deck_link ||
          session.zoom_link ||
          session.recording_link;
        // if this session also has any event materials, warn the user
        if (hasEventMaterials) {
          lostSessions.push({
            groupName: groups.find((group) => group.num === session.group_num)
              .name,
            sessionName: session.name,
          });
        }
      }
    });

    setModalOpen({
      type: 'switch_to_basic_confirmation',
      lostSessions: lostSessions,
      group: groupToDelete,
    });
  };

  const switchToBasicMode = () => {
    // clean out groups/sessions
    // first, remove all deleted groups that are not saved in the db
    const newGroups = groups.filter((group) => group.id || !group.deleted);
    // then, remove all sessions that are not saved in the db
    // that are either deleted or belong to a deleted group
    const remainingGroupNums = newGroups.map((group) => group.num);
    let newSessions = sessions.filter((session) => {
      return (
        session.id ||
        (!session.deleted &&
          remainingGroupNums.indexOf(session.group_num) !== -1)
      );
    });

    // get the first group's session data to be used as the "default" for all other groups
    const defaultGroup = newGroups.find((group) => !group.deleted);
    const newDefaultSessions = newSessions
      .filter((session) => {
        return session.group_num === defaultGroup.num && !session.deleted;
      })
      .map((session) => {
        const data = {
          name: session.name,
          num: session.num,
        };
        if (session.date) {
          data.date = session.date;
        }
        if (session.startTime) {
          data.startTime = session.startTime;
        }
        if (session.endTime) {
          data.endTime = session.endTime;
        }
        return data;
      });

    // remove all sessions that are not saved in the db
    // that have a num higher than the default sessions list
    newSessions = newSessions.filter((session) => {
      // make sure any sessions where num is too high are marked as deleted
      if (session.num > newDefaultSessions.length) {
        session.deleted = 1;
      }
      return session.id || !session.deleted;
    });
    // go through all the groups to make sure their session list matches the default sessions
    newDefaultSessions.forEach((ds) => {
      newGroups.forEach((group) => {
        // skip deleted groups
        if (group.deleted) {
          return;
        }
        // find the session in this group that matches the current default session
        const session = newSessions.find((session) => {
          return (
            !session.deleted &&
            session.group_num === group.num &&
            session.num === ds.num
          );
        });
        // update the data if it exists, or create a new one for this group if not
        if (session) {
          Object.assign(session, ds);
        } else {
          newSessions.push(Object.assign({ group_num: group.num }, ds));
        }
      });
    });

    // update the groups/sessions state
    setGroups(newGroups);
    setSessions(newSessions);
    setScrollTo(null);
    // show the Basic mode UI
    setUseAdvancedMode(false);
    // close the confirmation modal
    setModalOpen({});
    WindowHelpers.navigationConfirmation(true);
  };

  const addGroup = () => {
    const newGroups = Utils.clone(groups);
    // set up the new group (its num is one more than the current max num)
    const groupNum =
      groups
        .map((group) => group.num)
        .reduce((prev, next) => Math.max(prev, next), 0) + 1;
    const newGroup = { name: `Group ${groupNum}`, num: groupNum };
    newGroups.push(newGroup);
    // scroll the new group into view once it's added
    setScrollTo(`group-${groupNum}`);

    // set up the group's sessions
    let newSessions = Utils.clone(sessions);
    // try to find an existing group that has not been deleted
    const defaultGroup = groups.find((group) => !group.deleted);
    // in Advanced mode, just add an empty session to the new group
    if (useAdvancedMode) {
      const newSession = {
        name: 'Session 1',
        num: 1,
        group_num: groupNum,
        status: 'Scheduled',
      };
      newSessions.push(newSession);
    }
    // in Basic mode, if the event already had groups, duplicate all the default sessions over to the new group
    else if (defaultGroup) {
      // get all the existing sessions in an existing group
      const defaultSessions = sessions.filter((session) => {
        return session.group_num === defaultGroup.num && !session.deleted;
      });
      defaultSessions.forEach((ds) => {
        const newSession = {
          name: ds.name,
          num: ds.num,
          group_num: groupNum,
          status: ds.status,
          status_note: ds.status_note,
          cancelledTime: ds.cancelledTime,
        };
        if (ds.ld_product_id) {
          newSession.ld_product_id = ds.ld_product_id;
        }
        if (ds.date) {
          newSession.date = ds.date;
        }
        if (ds.startTime) {
          newSession.startTime = ds.startTime;
        }
        if (ds.endTime) {
          newSession.endTime = ds.endTime;
        }
        newSessions.push(newSession);
      });
    }
    // otherwise, if there were no groups, set the sessions to be in the new group
    else {
      newSessions.forEach((session) => {
        if (!session.deleted) {
          session.group_num = groupNum;
        }
      });
      // add a second new group
      const secondGroup = { name: `Group ${groupNum + 1}`, num: groupNum + 1 };
      newGroups.push(secondGroup);
      // duplicate all the existing sessions into the second new group
      const secondGroupSessions = Utils.clone(newSessions)
        .filter((s) => !s.deleted)
        .map((session) => {
          const newSession = {
            name: session.name,
            num: session.num,
            group_num: groupNum + 1,
            status: session.status,
            status_note: session.status_note,
            cancelledTime: session.cancelledTime,
          };
          if (session.ld_product_id) {
            newSession.ld_product_id = session.ld_product_id;
          }
          if (session.date) {
            newSession.date = session.date;
          }
          if (session.startTime) {
            newSession.startTime = session.startTime;
          }
          if (session.endTime) {
            newSession.endTime = session.endTime;
          }
          return newSession;
        });
      // combine the sessions of both groups
      newSessions = newSessions.concat(secondGroupSessions);
    }

    // update the state
    setGroups(newGroups);
    setSessions(newSessions);
    WindowHelpers.navigationConfirmation(true);
  };

  const updateGroup = (index, data) => {
    const newGroups = Utils.clone(groups);
    let group = newGroups[index];
    if (group) {
      group = Object.assign(group, data);
      setGroups(newGroups);
      WindowHelpers.navigationConfirmation(true);
    }
  };

  const deleteGroup = (data) => {
    const newGroups = Utils.clone(groups);
    const newSessions = Utils.clone(sessions);

    // if there are two groups left, just delete them both
    const activeGroups = newGroups.filter((group) => !group.deleted);
    if (activeGroups.length <= 2) {
      activeGroups.forEach((group) => (group.deleted = 1));
      // unassign the group_num for all the sessions associated with
      // the first non-deleted group, and delete all the other sessions
      newSessions.forEach((session) => {
        if (session.group_num === activeGroups[0].num) {
          session.group_id = null;
          session.group_num = null;
        } else {
          session.deleted = 1;
        }
      });
    }
    // otherwise, delete the given group
    else {
      newGroups[data.index].deleted = 1;
      // delete all the sessions associated with the deleted group
      newSessions.forEach((session) => {
        if (session.group_num === data.num) {
          session.deleted = 1;
        }
      });
    }

    // update the state
    setGroups(newGroups);
    setSessions(newSessions);
    setModalOpen({});
    WindowHelpers.navigationConfirmation(true);
  };

  const addSession = (groupNum) => {
    const newSessions = Utils.clone(sessions);
    // scroll the new session into view once it's added
    setScrollTo(`session-${newSessions.length}`);
    // try to find an existing group that has not been deleted
    const defaultGroup = groups.find((group) => !group.deleted);

    // in Advanced mode, just add the session to the given group
    if (useAdvancedMode) {
      const num =
        sessions.filter((session) => {
          return session.group_num === groupNum && !session.deleted;
        }).length + 1;
      newSessions.push({
        name: `Session ${num}`,
        num: num,
        group_num: groupNum,
        status: 'Scheduled',
      });
    }
    // in Basic mode, if the event has groups, add a matching session for each group
    else if (defaultGroup) {
      const num =
        sessions.filter((session) => {
          return session.group_num === defaultGroup.num && !session.deleted;
        }).length + 1;
      groups
        .filter((g) => !g.deleted)
        .forEach((group) => {
          newSessions.push({
            name: `Session ${num}`,
            num: num,
            group_num: group.num,
            status: 'Scheduled',
          });
        });
    }
    // otherwise, just add one new session without a group
    else {
      const num = sessions.filter((s) => !s.deleted).length + 1;
      newSessions.push({
        name: `Session ${num}`,
        num: num,
        group_num: null,
        status: 'Scheduled',
      });
    }
    // update the state
    setSessions(newSessions);
    WindowHelpers.navigationConfirmation(true);
  };

  const updateSession = (index, data) => {
    const newSessions = Utils.clone(sessions);
    const session = newSessions[index];
    if (session) {
      newSessions[index] = Object.assign(session, data);
    }
    setSessions(newSessions);
    WindowHelpers.navigationConfirmation(true);
  };

  const updateSessionsByNum = (num, data) => {
    const newSessions = Utils.clone(sessions);
    // in Basic mode,
    // changing the session name/date/start/end will affect all sessions with the same num
    newSessions.forEach((session) => {
      if (session.num === num) {
        session = Object.assign(session, data);
      }
    });
    setSessions(newSessions);
    WindowHelpers.navigationConfirmation(true);
  };

  const openDeleteSessionConfirmationModal = (data) => {
    // if the session has any event materials, show an additional warning in the modal
    let hasEventMaterials =
      ArrayHelpers.hasElements(data.facilitators) ||
      data.agenda_link ||
      data.slide_deck_link ||
      data.zoom_link ||
      data.recording_link;

    // in Basic mode, we have to check all sessions with the same num
    if (!hasEventMaterials && !useAdvancedMode) {
      sessions.forEach((session) => {
        if (hasEventMaterials || session.deleted || session.num !== data.num) {
          return;
        }
        hasEventMaterials =
          ArrayHelpers.hasElements(session.facilitators) ||
          session.agenda_link ||
          session.slide_deck_link ||
          session.zoom_link ||
          session.recording_link;
      });
    }

    // open the confirmation modal
    setModalOpen({
      type: 'delete_session_confirmation',
      hasEventMaterials: Boolean(hasEventMaterials),
      data: data,
    });
  };

  const checkEditEvent = (e) => {
    e.preventDefault();
    const sessionsChanged = sessions.filter(
      (session) => session.statusChanged && session.status !== 'Scheduled'
    );
    if (sessionsChanged.length > 0) {
      openConfirmChangeStatusModal(sessionsChanged);
    } else {
      handleEditEvent();
    }
  };

  const openConfirmChangeStatusModal = (changedSessions) => {
    // if this session also has any event materials, warn the user
    const materialsChanged = changedSessions.find((session) => {
      return (
        ArrayHelpers.hasElements(session.facilitators) ||
        session.agenda_link ||
        session.slide_deck_link ||
        session.zoom_link ||
        session.recording_link
      );
    });
    setModalOpen({
      type: 'change_status_confirmation',
      hasEventMaterials: Boolean(materialsChanged),
    });
  };

  const openConfirmDownsizeGroupModal = (data) => {
    setModalOpen({
      type: 'downsize_group_confirmation',
      data: data,
    });
  };

  const openConfirmUndeliverSessionModal = (data) => {
    setModalOpen({
      type: 'undeliver_session_confirmation',
      data: data,
    });
  };

  const confirmEditEvent = () => {
    setModalOpen({});
    handleEditEvent();
  };

  const deleteSession = (data) => {
    const newSessions = Utils.clone(sessions);
    newSessions.forEach((session) => {
      // skip deleted sessions
      if (session.deleted) {
        return;
      }
      // in Advanced mode, we ignore sessions in other groups
      if (useAdvancedMode && session.group_num !== data.group_num) {
        return;
      }
      // mark the session as deleted, and shift all the later sessions one num lower
      if (session.num === data.num) {
        session.deleted = 1;
      } else if (session.num > data.num) {
        session.num -= 1;
      }
    });
    // update the state
    setSessions(newSessions);
    setModalOpen({});
    WindowHelpers.navigationConfirmation(true);
  };

  const handleClearErrorsOnFocus = (field) => {
    if (!Object.keys(errors).length) {
      return;
    }
    // clear the error from the field
    if (field in errors) {
      const newErrors = Utils.clone(errors);
      delete newErrors[field];
      setErrors(newErrors);
    }
  };

  const handleEditEvent = () => {
    // don't do anything if the form is submitting
    if (loading) {
      return;
    }

    setLoading(true);
    setErrors({});

    // validate form data
    const newErrors = {};
    if (!displayName) {
      newErrors.displayName = 'Please enter a display name';
    }
    // validate session and group data
    // we only validate sessions and groups that were not deleted
    const activeSessions = sessions.filter((session) => !session.deleted);
    const activeGroups = groups.filter((group) => !group.deleted);

    // We need to update the active sessions to have usable start dates and times
    activeSessions.forEach((session) => {
      if (session.date && session.startTime && session.endTime) {
        const sessionStartDateTime = moment(session.date);
        sessionStartDateTime.hour(session.startTime.hour());
        sessionStartDateTime.minute(session.startTime.minute());
        const sessionEndDateTime = moment(session.date);
        sessionEndDateTime.hour(session.endTime.hour());
        sessionEndDateTime.minute(session.endTime.minute());
        session.sessionStartDateTime = sessionStartDateTime;
        session.sessionEndDateTime = sessionEndDateTime;
      }
    });
    activeSessions.sort((a, b) => {
      if (a.group_num === b.group_num) {
        return a.sessionStartDateTime - b.sessionStartDateTime;
      }
      return a.group_num - b.group_num;
    });
    // an object {groupId: cancellationTime} containing info of the downsized group
    const groupsToBeDownsized = {};
    activeGroups.forEach((group) => {
      group.errors = {};
      group.cancellation_time =
        group.cancelledTime && group.cancelledTime.format('YYYY-MM-DDTHH:mm');
      // when a group's status is set to be changed, and all required fields present, save the group info to cancel sessions later
      if (group.statusChanged) {
        if (group.status !== 'Active') {
          if (!group.cancellation_time) {
            group.errors.cancelTime = 'Required';
            newErrors.group = 'Cancel Time Is Required';
          } else {
            groupsToBeDownsized[group.id] = group.cancellation_time;
          }
        }
      }
    });

    let prevSessionStartDateTime;
    let prevSessionEndDateTime;
    let prevSessionGroup;
    let prevSessionName;
    activeSessions.forEach((session) => {
      // clear any session errors
      session.errors = {};
      // set the session date and start/end datetimes to pass to the backend
      session.session_date =
        session.date && session.date.isValid()
          ? session.date.format('YYYY-MM-DD')
          : null;
      // By actually tracking the date in the start and end time we can more easily convert the time at the end point
      // Default to today's date if no session date is set, this shouldn't happen but better safe than sorry
      const sessionDate = session.session_date || moment().format('YYYY-MM-DD');
      session.session_start_time = session.startTime
        ? `${sessionDate}T${session.startTime.format('HH:mm')}`
        : null;
      session.session_end_time = session.endTime
        ? `${sessionDate}T${session.endTime.format('HH:mm')}`
        : null;
      session.cancellation_time =
        session.cancelledTime &&
        session.cancelledTime.format('YYYY-MM-DDTHH:mm');
      session.retroactively_not_delivered =
        session.RetroActivelyNotDelivered &&
        session.RetroActivelyNotDelivered.format('YYYY-MM-DDTHH:mm');

      if (
        session.status !== 'Delivered' &&
        session.retroactively_not_delivered
      ) {
        newErrors.session =
          'Can only mark a Delivered session to Not Delivered.';
      }

      // make sure this session has a date, start, and end time
      if (!session.session_date) {
        session.errors.date = 'This session must have a date.';
        newErrors.session = 'session lacks a date';
      }

      // If the session has all the required date/times then we can check for overlap
      if (!session.session_start_time || !session.session_end_time) {
        session.errors.startTime =
          'This session must have a start and end time.';
        newErrors.session = 'session lacks start and end time';
      } else if (session.session_date) {
        const currTime = moment().tz(timezone.pytz_timezone);
        if (session.changed) {
          if (
            moment(session.session_start_time).tz(timezone.pytz_timezone) <
            currTime
          ) {
            session.errors.startTime = 'Date can not be in the past';
            newErrors.session = 'session must start and finish after now';
          }
          if (
            moment(session.session_end_time).tz(timezone.pytz_timezone) <
            currTime
          ) {
            session.errors.endTime = 'Date can not be in the past';
            newErrors.session = 'session must start and finish after now';
          }
        }
        // Only worry about this for "Active" sessions
        if (['Delivered', 'Scheduled'].includes(session.status)) {
          // make sure this session does not start before the last one ends
          if (
            session.group_num === prevSessionGroup &&
            prevSessionStartDateTime &&
            prevSessionEndDateTime
          ) {
            // get the datetime from this session to use in the comparison
            const sessionStartDateTime = session.sessionStartDateTime;
            const sessionEndDateTime = session.sessionEndDateTime;

            // if this session comes before the previous session, show an error
            if (
              sessionStartDateTime.isBetween(
                prevSessionStartDateTime,
                prevSessionEndDateTime
              ) ||
              sessionEndDateTime.isBetween(
                prevSessionStartDateTime,
                prevSessionEndDateTime
              ) ||
              sessionEndDateTime.isSame(prevSessionEndDateTime) ||
              sessionStartDateTime.isSame(prevSessionStartDateTime)
            ) {
              session.errors.date = `This session’s date and times overlap with ${prevSessionName}'s.`;
              newErrors.session = 'session times overlap';
            }
          }

          // set the datetime and group to validate the next session
          prevSessionStartDateTime = moment(session.date);
          prevSessionStartDateTime.hour(session.startTime.hour());
          prevSessionStartDateTime.minute(session.startTime.minute());
          prevSessionEndDateTime = moment(session.date);
          prevSessionEndDateTime.hour(session.endTime.hour());
          prevSessionEndDateTime.minute(session.endTime.minute());
          prevSessionGroup = session.group_num;
        }

        // Use this to help the user ID the next session
        prevSessionName = session.name;
      }
      // make sure this session's start time is before the end time
      if (
        session.session_start_time &&
        session.session_end_time &&
        moment(session.session_start_time) >= moment(session.session_end_time)
      ) {
        session.errors = { startTime: 'Start time must come before end time' };
        newErrors.session = 'session times out of order';
      }
      // validate the session materials
      if (session.agenda_link && !Utils.isUrl(session.agenda_link)) {
        session.errors.agendaLink =
          'Whoops! Your link must begin with http:// or https://';
        newErrors.session = 'invalid agenda link';
      }
      if (session.slide_deck_link && !Utils.isUrl(session.slide_deck_link)) {
        session.errors.slideDeckLink =
          'Whoops! Your link must begin with http:// or https://';
        newErrors.session = 'invalid slide deck link';
      }
      if (session.zoom_link && !/^https:\/\/[^ "]+$/.test(session.zoom_link)) {
        session.errors.zoomLink = 'Whoops! Your link must begin with https://';
        newErrors.session = 'invalid zoom link';
      }

      // when a group is set to be downsized, cancel the sessions as well
      if (session.group_id && session.group_id in groupsToBeDownsized) {
        session.statusChanged = true;
        session.status = 'Partner Cancelled';
        session.status_note = 'Group Downsized';
        session.cancellation_time = groupsToBeDownsized[session.group_id];
      }

      if (session.statusChanged && session.status !== 'Scheduled') {
        if (!session.status_note) {
          session.errors.statusNote =
            'Cancelled sessions must have an explanation.';
          newErrors.session = 'Status Note is Required';
        }
        if (!session.cancellation_time) {
          session.errors.cancelTime = 'Required';
          newErrors.session = 'Cancel Time Is Required';
        }
      }
      const videoPatterns = [
        /^https:\/\/betterlesson.zoom.us\/recording\/play\//,
        /^https:\/\/betterlesson.zoom.us\/recording\/download\//,
        /^https:\/\/betterlesson.zoom.us\/rec\/play\//,
        /^https:\/\/betterlesson.zoom.us\/rec\/download\//,
      ];
      if (session.recording_link) {
        // check each line of the textarea
        session.recording_link.split('\n').forEach((link) => {
          if (!videoPatterns.find((videoPattern) => videoPattern.test(link))) {
            session.errors.recordingLink =
              'Whoops! Your links must begin with https://betterlesson.zoom.us/rec/play or https://betterlesson.zoom.us/rec/download in order to be displayed in the Lab.';
            newErrors.session = 'invalid recording link';
          }
        });
      }
    });
    // if there were any form errors, display them
    if (Object.keys(newErrors).length) {
      setLoading(false);
      setErrors(newErrors);
      // show success
      setAlert({
        type: 'error',
        message: 'Please fix the errors on this page before saving',
        handleClose: () => setAlert({}),
      });
      return;
    }

    // clear any alert
    setAlert({});

    // format the lastmodified datetime in the way the backend expects
    const lastmodified = event.lastmodified_formatted
      ? moment(event.lastmodified_formatted)
          .tz('US/Eastern')
          .format('YYYY-MM-DD[T]HH:mm:ss')
      : null;

    const leadFacilitatorId = leadEventFacilitor && leadEventFacilitor.id;

    EventApi.edit({
      eventId: props.event.id,
      lastmodified: lastmodified,
      displayName: displayName,
      maxCapacity: maxCapacity,
      advanced_event: useAdvancedMode,
      groups: groups.filter((g) => !g.deleted || g.id),
      sessions: sessions.filter((s) => !s.deleted || s.id),
      eventTimezone: timezone.pytz_timezone,
      leadEventFacilitorId: leadFacilitatorId,
    })
      .then((response) => {
        WindowHelpers.navigationConfirmation(false);
        setLoading(false);
        // show alert based on response
        if (response.error) {
          setAlert({
            type: 'error',
            message: response.error,
            leftAlign: true,
            handleClose: () => setAlert({}),
            preventAutoclose: true,
          });
        } else {
          setAlert({
            type: 'success',
            message: 'Changes to event saved',
            handleClose: () => setAlert({}),
          });
        }

        // update event state in db
        setEvent(response.event);

        // assign any new database ids to groups/sessions
        const newGroups = Utils.clone(groups);
        response.event.groups.forEach((group) => {
          const newGroup = newGroups.find(
            (g) => g.num === group.num && !g.deleted
          );
          if (newGroup) {
            newGroup.id = group.id;
            newGroup.status = group.status;
            newGroup.cancellation_time = group.cancellation_time;
            newGroup.cancellation_time_formatted =
              group.cancellation_time_formatted;
            newGroup.confirmed = false;
            newGroup.statusChanged = false;
          }
        });

        const newSessions = Utils.clone(response.event.sessions);
        newSessions.forEach(visualizeSession);
        setGroups(newGroups);
        setSessions(newSessions);
      })
      .catch((response) => {
        setLoading(false);
        setAlert({
          type: 'error',
          message: response.error,
          handleClose: () => setAlert({}),
          preventAutoclose: response.type === 'outdated',
        });
      });
  };
  // set up the facilitator options
  const facilitatorOptions = props.facilitator_options.map((facilitator) => {
    return {
      label: `${facilitator.first_name} ${facilitator.last_name}`,
      id: facilitator.id,
    };
  });

  const handleSelectFacilitator = (data) => {
    // if they are not in the list, add them
    if (!leadEventFacilitor) {
      const selectedFacilitator = facilitatorOptions.find(
        (facilitator) => facilitator.id === data.id
      );
      setLeadEventFacilitator(selectedFacilitator);
    } else {
      setLeadEventFacilitator(null);
    }
  };

  // set up any alert banner
  let alertNode = null;
  if (Object.keys(alert).length) {
    const alertClasses = { container: 'alert-banner--fixed' };
    if (alert.leftAlign) {
      alertClasses.headline = 'alert-banner__headline--left-align';
    }
    alertNode = <AbsoluteAlert {...alert} cssClasses={alertClasses} />;
  }

  // style the event details form section slighly differently if it is read-only
  const detailsRowClass = canEditEventDetails
    ? ''
    : 'edit-event__details-form-row--fixed-value';

  // set up the display name
  let displayNameNode = displayName ? (
    <div className="edit-event__fixed-value">{displayName}</div>
  ) : (
    <div className="edit-event__fixed-value empty">Not set</div>
  );

  // set up Lead Facilitator
  let leadFacilitatorNode = leadEventFacilitor ? (
    <div className="edit-event__fixed-value">{leadEventFacilitor.label}</div>
  ) : (
    <div className="edit-event__fixed-value empty">
      No lead facilitator assigned
    </div>
  );

  if (canEditEventDetails) {
    displayNameNode = (
      <FormField
        type="text"
        maxLength="200"
        defaultValue={displayName}
        onChange={(e) => {
          setDisplayName(e.target.value);
          WindowHelpers.navigationConfirmation(true);
        }}
        onFocus={() => handleClearErrorsOnFocus('displayName')}
        error={errors.displayName}
      />
    );
    // build the multiselects
    leadFacilitatorNode = (
      <MultiSelect
        uId={'lead-facilitator'}
        className="form-field"
        handleSelectOption={(data) => handleSelectFacilitator(data)}
        selectedOptions={leadEventFacilitor ? [leadEventFacilitor] : []}
        dropdownOptions={facilitatorOptions}
        minCharCountForResults={0}
        stayOpenOnSelect={false}
        maximumSelectedOptionsCount={1}
        isSelectSearch={true}
        preventAutoFocus={true}
      />
    );
  }

  // set up the max capacity
  let maxCapacityNode = maxCapacity ? (
    <div className="edit-event__fixed-value">{maxCapacity}</div>
  ) : (
    <div className="edit-event__fixed-value empty">N/A</div>
  );
  if (canEditEventDetails) {
    maxCapacityNode = (
      <FormField
        type="number"
        defaultValue={maxCapacity}
        onChange={(e) => {
          setMaxCapacity(e.target.value);
          WindowHelpers.navigationConfirmation(true);
        }}
        onKeyDown={handleCapacityKeyDown}
      />
    );
  }

  // set up the list of PLs
  let partnerLeadNodes = (
    <div className="edit-event__fixed-value empty">
      No partner leaders assigned
    </div>
  );
  if (ArrayHelpers.hasElements(partnerLeads) || canEditPLs) {
    partnerLeadNodes =
      partnerLeads &&
      partnerLeads.map((lead) => {
        const plDashLink = canAccessPLDashboard && (
          <NavigationLink
            target="_blank"
            className="v5__link edit-event__pl-link"
            url={`/lab/leader/${lead.partner_lead.id}/home`}
          >
            View Partner Leader’s Dashboard
          </NavigationLink>
        );
        const isPartnerText = '(Partnership-Level Partner Lead)';
        return (
          <li key={`partner-leader-${lead.id}`}>
            <span className="bold">{`${lead.first_name} ${lead.last_name}`}</span>{' '}
            {lead.email} {isPartnerText}
            {plDashLink}
          </li>
        );
      });
  }

  let modalNode;
  if (modalOpen.type === 'switch_to_basic_confirmation') {
    modalNode = (
      <SwitchToBasicConfirmationModal
        groupName={groups.find((g) => !g.deleted).name}
        lostSessions={modalOpen.lostSessions}
        isDeletingGroups={!!modalOpen.group}
        close={() => setModalOpen({})}
        confirm={() => {
          switchToBasicMode();
          if (modalOpen.group) {
            deleteGroup(modalOpen.group);
          }
        }}
      />
    );
  } else if (modalOpen.type === 'delete_group_confirmation') {
    modalNode = (
      <DeleteGroupConfirmationModal
        useAdvancedMode={useAdvancedMode}
        close={() => setModalOpen({})}
        confirm={() => deleteGroup(modalOpen.data)}
      />
    );
  } else if (modalOpen.type === 'delete_session_confirmation') {
    modalNode = (
      <DeleteSessionConfirmationModal
        hasEventMaterials={modalOpen.hasEventMaterials}
        close={() => setModalOpen({})}
        confirm={() => deleteSession(modalOpen.data)}
      />
    );
  } else if (modalOpen.type === 'delete_event_session_recording') {
    modalNode = (
      <DeleteEventSessionRecordingConfirmationModal
        close={() => setModalOpen({})}
        confirm={modalOpen.confirm}
      />
    );
  } else if (modalOpen.type === 'disconnect_event_session_from_zoom') {
    let confirm = () => disconnectZoom(modalOpen.eventSessionId);

    if (!modalOpen.eventSessionId) {
      confirm = () => {
        const newSessions = [...sessions];

        const disconnectedSession = newSessions[modalOpen.index];
        disconnectedSession.zoom_join_link = '';

        setSessions(newSessions);
        setModalOpen({});
      };
    }

    modalNode = (
      <DisconnectEventSessionFromZoomModal
        close={() => setModalOpen({})}
        confirm={confirm}
        sessionName={modalOpen.sessionName}
      />
    );
  } else if (modalOpen.type === 'share_content_with_partner_leader') {
    modalNode = (
      <ShareContentModal
        eventName={props.event.name}
        contentLink={contentLink}
        close={() => setModalOpen({})}
        confirm={saveContentLink}
      />
    );
  } else if (modalOpen.type === 'change_status_confirmation') {
    modalNode = (
      <ConfirmChangeStatusModal
        hasEventMaterials={modalOpen.hasEventMaterials}
        close={() => setModalOpen({})}
        confirm={confirmEditEvent}
      />
    );
  } else if (modalOpen.type === 'downsize_group_confirmation') {
    modalNode = (
      <ConfirmDownsizeGroupModal
        data={modalOpen.data}
        close={() => setModalOpen({})}
        confirm={(data) => {
          const newGroups = Utils.clone(groups);
          for (const group of newGroups) {
            if (group.id === data.group_id) {
              group.confirmed = true;
              group.status = 'Downsized';
              group.statusChanged = true;
              group.errors = {};
              break;
            }
          }
          setGroups(newGroups);
          setModalOpen({});
        }}
      />
    );
  } else if (modalOpen.type === 'undeliver_session_confirmation') {
    modalNode = (
      <ConfirmUndeliverSessionModal
        data={modalOpen.data}
        close={() => setModalOpen({})}
        confirm={(data) => {
          const newSessions = Utils.clone(sessions);
          for (const session of newSessions) {
            if (session.id === data.session_id) {
              // set the time to be the moment it is confirmed in eastern (server time)
              session.RetroActivelyNotDelivered =
                moment().tz('America/New_York');
              session.errors = {};
              break;
            }
          }
          setSessions(newSessions);
          setModalOpen({});
        }}
      />
    );
  }

  // the layout of the Groups & Sessions section changes depending on Basic vs Advanced mode
  let groupsAndSessionsNode = (
    <GroupsAndSessionsBasic
      switchToAdvancedMode={switchToAdvancedMode}
      groups={groups}
      addGroup={addGroup}
      updateGroup={updateGroup}
      deleteGroup={(data) =>
        setModalOpen({ type: 'delete_group_confirmation', data: data })
      }
      sessions={sessions}
      addSession={addSession}
      updateSessionsByNum={updateSessionsByNum}
      deleteSession={(data) => openDeleteSessionConfirmationModal(data)}
      allProductCodes={props.allProductCodes}
      timezone={timezone}
      canEditEventDetails={canEditEventDetails}
      confirmDownsizeGroup={(data) => openConfirmDownsizeGroupModal(data)}
      confirmUndeliverSession={(data) => openConfirmUndeliverSessionModal(data)}
      scrollTo={scrollTo}
    />
  );
  if (useAdvancedMode) {
    groupsAndSessionsNode = (
      <GroupsAndSessionsAdvanced
        switchToBasicMode={(groupToDelete) =>
          openSwitchToBasicConfirmationModal(groupToDelete)
        }
        groups={groups}
        addGroup={addGroup}
        updateGroup={updateGroup}
        deleteGroup={(data) =>
          setModalOpen({ type: 'delete_group_confirmation', data: data })
        }
        sessions={sessions}
        addSession={addSession}
        updateSession={updateSession}
        deleteSession={(data) => openDeleteSessionConfirmationModal(data)}
        allProductCodes={props.allProductCodes}
        timezone={timezone}
        canEditEventDetails={canEditEventDetails}
        confirmDownsizeGroup={(data) => openConfirmDownsizeGroupModal(data)}
        confirmUndeliverSession={(data) =>
          openConfirmUndeliverSessionModal(data)
        }
        scrollTo={scrollTo}
      />
    );
  }

  // if the user has no roles that can edit the page, hide the Save button
  const hasEditingRole = Utils.arrayContainsAnyItemInAnotherArray(
    props.user.auth_roles,
    ['ADMIN', 'BL_MANAGER', 'BL_SERVICES_MANAGER', 'BL_CONTRACT_COACH']
  );
  let stickyFooterNode;
  if (hasEditingRole) {
    stickyFooterNode = (
      <StickyFooter submit={checkEditEvent} loading={loading} />
    );
  }

  return (
    <div className="cohort-edit tc__page">
      <Header page="EditEvent" {...props} />
      {alertNode}
      {modalNode}

      <div className="row tc__body-copy">
        <div className="cute-12-desktop ppd-v3-curation__breadcrumbs">
          <NavigationLink url="/bl/events" className="tc__link">
            Events
          </NavigationLink>
          <span className="tc-arrow ppd-v3-curation__breadcrumb-separator"></span>
          Edit Event
        </div>
      </div>

      <EventMetadataAndLinks
        event={event}
        allProductCodes={props.allProductCodes}
        baseUrl={props.baseUrl}
        handleCopy={handleCopy}
      />

      <EventOverview
        overview={event.overview}
        canEditOverview={canEditEventDetails}
        changeNotes={event.changeNotes}
        eventId={event.id}
      />

      <div className="edit-event__form-area">
        <ModificationsCapsule
          modifications={event.modifications}
          modificationsExplanation={event.modifications_explanation}
        />

        <div className="row">
          <div
            className={`cute-6-desktop left v5__body-copy edit-event__details-form-row ${detailsRowClass}`}
          >
            <div className="edit-event__details-form-label">Display Name</div>
            {displayNameNode}
          </div>
        </div>

        <div className="row">
          <div
            className={`cute-2-desktop left v5__body-copy edit-event__details-form-row ${detailsRowClass}`}
          >
            <div className="edit-event__details-form-label">Max Capacity</div>
            {maxCapacityNode}
          </div>
        </div>

        <div className="row">
          <div
            className={`cute-2-desktop left v5__body-copy edit-event__details-form-row ${detailsRowClass}`}
          >
            <div className="edit-event__details-form-label">
              Event Lead Facilitator
            </div>
            {leadFacilitatorNode}
          </div>
        </div>

        <div className="row">
          <div
            className={`cute-8-desktop left v5__body-copy edit-event__details-form-row ${detailsRowClass}`}
          >
            <div className="edit-event__details-form-label">
              Partner Leaders
            </div>
            {canEditPLs && (
              <div
                className="v5__link edit-event__share-content"
                onClick={() =>
                  setModalOpen({ type: 'share_content_with_partner_leader' })
                }
              >
                <span className="tc-v4-link edit-event__share-content-icon"></span>
                <span className="edit-event__share-content-text">
                  Share Content With Partner
                </span>
              </div>
            )}
            <div className="admin-v5__event-pl-list">{partnerLeadNodes}</div>
          </div>
        </div>

        <div className="row">
          <div className="cute-12-desktop">
            <hr className="edit-event__section-separator" />
            {groupsAndSessionsNode}
            <SessionMaterials
              groups={groups}
              sessions={sessions}
              handleCopy={handleCopy}
              allProductCodes={props.allProductCodes}
              facilitatorOptions={facilitatorOptions}
              updateSession={updateSession}
              setAlert={setAlert}
              disconnectZoom={disconnectZoom}
              setModalOpen={setModalOpen}
              user={props.user}
              eventHasModifications={!!props.event.modifications}
              canEditEventDetails={canEditEventDetails}
            />
          </div>
        </div>
      </div>

      {stickyFooterNode}
    </div>
  );
};

EditEvent.propTypes = {
  event: PropTypes.object.isRequired,
  facilitator_options: PropTypes.array.isRequired,
  occupations: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default EditEvent;
