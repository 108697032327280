// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ArtifactImage from './../../../V4/Common/ArtifactImage';
import ExpandableCapsule from './../../../V4/Common/ExpandableCapsule';
import VideoNarrative from './VideoNarrative';

const Section = (props) => {
  let durationNode,
    resourcesNodes,
    mobileResourceNodes,
    reflectionNode,
    narrativeNode,
    sectionPreview;

  const handleOpenResource = (resource, lessonFileType) => {
    // open links in a new window
    if (resource.connections && resource.connections.link) {
      window.open(resource.connections.link, '_blank');
      return;
    }

    resource.lessonFileType = lessonFileType;
    // open artifact overlay
    props.openResource(resource);
  };

  // if the section includes a duration, display it as # minutes
  if (props.duration && parseInt(props.duration) > 0) {
    durationNode = (
      <div className="strategy-v4__capsule-time">{props.duration} minutes</div>
    );
  }
  if (props.SectionNarrative && props.SectionNarrative.Narrative) {
    narrativeNode = (
      <PurifiedHTML
        data-section={props.id}
        className="strategy-v4__capsule-description ckeditor__output lesson__section-description"
        content={props.SectionNarrative.Narrative.narrative}
      />
    );
  } else if (props.SectionNarrative && props.SectionNarrative.Lessonfile) {
    if (props.SectionNarrative.Lessonfile.is_movie) {
      narrativeNode = <VideoNarrative {...props.SectionNarrative.Lessonfile} />;
    } else {
      sectionPreview = props.SectionNarrative.Lessonfile.content.preview.map(
        (preview, i) => {
          return (
            <div
              className=""
              key={'lesson-section-file-' + preview.id + '-' + i}
            >
              <img
                src={preview}
                alt={
                  props.SectionNarrative.Lessonfile.title + ' page ' + (i + 1)
                }
              />
            </div>
          );
        }
      );
      narrativeNode = (
        <div className="lesson__section-file-narrative">{sectionPreview}</div>
      );
    }
  }

  // if the section includes resources, display those
  let capsuleClass = '';
  if (props.Resource && props.Resource.length) {
    capsuleClass = 'strategy-v4__capsule--has-resources ';
    // build a list of all the resources
    const resources = props.Resource.map((resource) => {
      if (resource.Lessonfile) {
        const icon =
          resource.Lessonfile && resource.Lessonfile.extension
            ? resource.Lessonfile.extension
            : 'default';
        const preview =
          resource.Lessonfile && resource.Lessonfile.connections
            ? resource.Lessonfile.connections.preview
            : '/';
        return (
          <div
            key={'resource-' + resource.Lessonfile.id}
            className="strategy-v4__capsule-resource"
            onClick={() =>
              handleOpenResource(
                resource.Lessonfile,
                resource.Lessonfile.shortcut ? 'shortcut' : 'lessonfile'
              )
            }
          >
            <ArtifactImage
              preview={preview}
              icon={icon}
              title={resource.Lessonfile.title}
              containerClass="strategy-v4__capsule-resource-image-container lesson__capsule-resource-image-container"
              imageClass="strategy-v4__capsule-resource-image"
            />

            <div className="strategy-v4__capsule-resource-headline-row tc__body-copy">
              <div
                className={
                  'strategy-v4__capsule-resource-icon file-type-icon ext-' +
                  icon
                }
              ></div>
              <div className="lesson__resource-title tc__link">
                {resource.Lessonfile.title}
              </div>
            </div>
          </div>
        );
      } else if (resource.Link) {
        return (
          <div
            key={'resource-' + resource.Link.id}
            className="strategy-v4__capsule-resource"
            onClick={() => handleOpenResource(resource.Link, 'lessonfile')}
          >
            <div className="strategy-v4__capsule-resource-image-container lesson__capsule-resource-image-container">
              <div className="strategy-v4__capsule-resource-image-mask strategy-v4__capsule-resource-image--none">
                <div className="strategy__preview-icon file-type-icon ext-link"></div>
              </div>
            </div>
            <div className="strategy-v4__capsule-resource-headline-row tc__body-copy">
              <div className="strategy-v4__capsule-resource-icon file-type-icon ext-link"></div>
              <div className="lesson__resource-title tc__link">
                {resource.Link.title}
              </div>
            </div>
          </div>
        );
      }
    });

    // on desktop, we want to show up to three resources per row inside the capsule
    resourcesNodes = [];
    for (let i = 0; i < resources.length; i += 3) {
      resourcesNodes.push(
        <div
          key={'section-' + props.index + '-resources-' + i}
          className="strategy-v4__capsule-resources"
        >
          {resources.slice(i, i + 3)}
        </div>
      );
    }

    // on mobile, we show all the resources in one scrollable row
    mobileResourceNodes = [
      <div
        key="mobile-section-resources"
        className="strategy-v4__capsule-resources"
      >
        {resources}
      </div>,
    ];
  }

  if (props.Reflexions && props.Reflexions.length) {
    reflectionNode = props.Reflexions.map((reflection) => {
      if (reflection.Reflexion.narrative) {
        reflection.Reflexion.connections = {
          icon: 'txt',
          preview_type: 'text',
          content: {
            preview: reflection.Reflexion.narrative.narrative,
          },
        };
      }
      const reflectionResources =
        reflection.Reflexion.lessonfiles &&
        reflection.Reflexion.lessonfiles.map((lessonfile) => {
          return (
            <div
              className="lesson__reflection-resource"
              key={
                'section-' +
                props.id +
                '-reflection-' +
                reflection.Reflexion.id +
                '-lessonfile-' +
                lessonfile.id
              }
            >
              <a
                className="tc__link"
                onClick={() => {
                  handleOpenResource(lessonfile, 'lessonfile');
                }}
              >
                <div className="lesson__reflection-title">
                  {lessonfile.title}
                </div>
              </a>
            </div>
          );
        });
      return (
        <div
          className="lesson__reflection"
          key={'section-' + props.id + '-reflection-' + reflection.Reflexion.id}
        >
          <a
            className="tc__link"
            onClick={() => {
              handleOpenResource(reflection.Reflexion, 'reflection');
            }}
          >
            <div className="lesson__reflection-icon tc-lightbulb"></div>
            <div className="lesson__reflection-title">
              {reflection.Reflexion.name}
            </div>
          </a>
          {reflectionResources}
        </div>
      );
    });
  }

  return (
    <ExpandableCapsule
      id={'section-' + props.index}
      expandFromAnywhere={true}
      maxHeight={600}
      className={capsuleClass + 'strategy-v4__capsule lesson__section'}
      stickyFooterNodes={resourcesNodes}
      mobileStickyFooterNode={mobileResourceNodes}
      updateSectionExpandToggleCount={props.updateSectionExpandToggleCount}
      analytics={{
        category: 'lessonsection',
        label: 'from_mtp_lesson',
      }}
    >
      <div className="strategy-v4__capsule-headline-row">
        <h2 className="strategy-v4__capsule-headline">{props.name}</h2>
        {durationNode}
      </div>
      {reflectionNode}
      {narrativeNode}
    </ExpandableCapsule>
  );
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  duration: PropTypes.any,
  Resource: PropTypes.array,
  Reflexions: PropTypes.array,
  openResource: PropTypes.func,
  SectionNarrative: PropTypes.object,
};

export default Section;
