// @ts-nocheck FIXME
import Challenge from './Challenge';

const Category = (props) => {
  let categoryStrategiesCount = 0;

  // loop over sgas
  let sgaChallengeHasAttempts = false;

  // there are no challenges for this sga do not render the sga
  if (props.sga.challenges.length == 0) {
    return <noscript />;
  }

  // loop over challenges
  const challengeNodes = props.sga.challenges.map((challenge) => {
    // if there are no 'tried' tml attempts do not render the challenge
    let challengeTmlAttempts = challenge.tmlAttempts.length;
    for (const attempt in challenge.tmlAttempts) {
      if (!challenge.tmlAttempts.hasOwnProperty(attempt)) {
        continue;
      }

      if (
        challenge.tmlAttempts[attempt]['step'] == 'pending' ||
        challenge.tmlAttempts[attempt]['step'] == 'teach'
      ) {
        challengeTmlAttempts--;
      }
    }

    // there are no tml attepts for this challenge so do not render the challenge
    if (challengeTmlAttempts == 0) {
      return null;
    }

    // there are attempts for this challenge count them
    sgaChallengeHasAttempts = true;
    categoryStrategiesCount += challenge.tmlAttempts.length;

    return (
      <Challenge
        challenge={challenge}
        key={'sga-' + props.sga.id + '-challenge-' + challenge.id}
      />
    );
  });

  // if there were no challenges do not render the sga
  if (!sgaChallengeHasAttempts) {
    return <noscript />;
  }

  let impactCaption = 'Student Growth Area';
  let instructionalDesignClass = '';
  if (props.sga.category == 'Instructional Design') {
    impactCaption = 'Teacher Growth Area';
    instructionalDesignClass =
      ' portfolio__growth-map-sga--instructional-design';
  }

  // render the sga node
  const sgaNode = (
    <div className={'portfolio__growth-map-sga' + instructionalDesignClass}>
      <div className="portfolio__growth-map-sga-icon">
        <div className={'tc-sga-nb-' + props.sga.id}></div>
      </div>
      <div className="portfolio__growth-map-sga-details">
        <div className="portfolio__growth-map-sga-declaration">
          {impactCaption}
        </div>
        <div className="portfolio__growth-map-sga-name">{props.sga.name}</div>
      </div>
      {challengeNodes}
    </div>
  );

  // if there are no strategies for this categories there is no reason to render the node
  if (categoryStrategiesCount == 0) {
    return <noscript />;
  }

  return (
    <div>
      <div className="portfolio__growth-map-category-name">
        {props.category} Challenges
      </div>
      {sgaNode}
    </div>
  );
};

export default Category;
