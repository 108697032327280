// @ts-nocheck FIXME
import Request from './../../components/Request';

const RegistrationApi = {
  login: (data) => {
    let url = process.env.REACT_APP_LAB_SERVICE_URL + '/login';
    if (data.isMobileUser) {
      url =
        process.env.REACT_APP_LAB_SERVICE_URL +
        '/coaching/lab/participant/login';
    }

    return Request.post(url, JSON.stringify(data));
  },
  eventReservation: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/lab/event/${data.event_id}/reserve`,
      JSON.stringify(data)
    );
  },
  register: (data) => {
    // This is the user account registration API
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/register',
      JSON.stringify(data)
    );
  },
  logout: () => {
    return Request.get(
      process.env.REACT_APP_LAB_SERVICE_URL + '/logout?noredirect=true'
    );
  },
  registerForEvent: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/lab/event/${data.event_id}/${data.session_id}/register`,
      JSON.stringify(data)
    );
  },
  registerForCohort: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        `/lab/cohort/${data.cohort_id}/${data.userType}/register`,
      JSON.stringify(data)
    );
  },
};

export default RegistrationApi;
