/**
 * internal function to compose url to a specific path
 * @param basePath {string} the base path to go to, e.g. bl-user-service.com
 * @param urlPath {string} the relative path after the base path, e.g. "login" -> bl-user-service.com/login
 * @param useLabPath {boolean} if true, add '/lab' before urlPath, e.g. bl-user-service.com/lab/login
 * @param useSearch {boolean} if true, include window.location.search data
 * @param useRedirect {boolean} if true, include window.location.pathname data
 * @returns {string} string representation of the newly composed URL
 * @private
 */
const _composeUrl = ({
  basePath,
  urlPath,
  useSearch = true,
  useRedirect = true,
}: {
  basePath: string;
  urlPath: string;
  useSearch?: boolean;
  useRedirect?: boolean;
}): string => {
  const urlString = basePath;
  let urlStringWithPath = urlString.concat(urlPath);

  if (!window) {
    return new URL(urlStringWithPath).toString();
  } else if (useSearch) {
    urlStringWithPath = urlStringWithPath.concat(window.location.search);
  }
  const urlWithPath = new URL(urlStringWithPath);
  if (useRedirect) {
    urlWithPath.searchParams.set('redirect', window.location.pathname);
  }

  return urlWithPath.toString();
};

/**
 * internal function to compose url to user-management
 * @param urlPath {string} the relative path within user-management
 * @param useLabPath {boolean} if true, add '/lab' before urlPath
 * @param useSearch {boolean} if true, include window.location.search data
 * @param useRedirect {boolean} if true, include window.location.pathname data
 * @returns {string} string representation of the newly composed URL
 * @private
 */
const _composeUserManagementUrl = ({
  urlPath,
  useSearch = true,
  useRedirect = true,
}: {
  urlPath: string;
  useSearch?: boolean;
  useRedirect?: boolean;
}): string => {
  const userManagementBase = `${process.env.REACT_APP_USER_MANAGEMENT_URL}`;

  return _composeUrl({
    basePath: userManagementBase,
    urlPath,
    useSearch,
    useRedirect,
  });
};

/**
 * internal function to compose url to survey-service
 * @param urlPath {string} the relative path within survey service
 * @param useSearch {boolean} if true, include window.location.search data
 * @param useRedirect {boolean} if true, include window.location.pathname data
 * @returns {string} string representation of the newly composed URL
 * @private
 */
const _composeSurveyServiceUrl = (
  urlPath: string,
  useSearch = true,
  useRedirect = true
) => {
  const surveyServiceBase = `${process.env.REACT_APP_SURVEY_URL}`;

  return _composeUrl({
    basePath: surveyServiceBase,
    urlPath,
    useSearch,
    useRedirect,
  });
};

const RedirectHelper = {
  /**
   * get the lab login url in user-management with redirect data
   * @param {string} orgUuid - An optional param that specifies the user service organization. Creates membership in user service if specified.
   * @returns {string} the url string
   */
  getUserManagementLoginUrl: (orgUuid?: string): string => {
    const loginUrl = new URL(
      _composeUserManagementUrl({
        urlPath: '/login',
        useSearch: false,
        useRedirect: true,
      })
    );

    if (orgUuid) {
      loginUrl.searchParams.set('orgUuid', orgUuid);
    }

    return loginUrl.toString();
  },

  /**
   * Get the lab register url in user-management with redirect data, userSub, and pwToken
   * @param {string} userSub - The userSub parameter value
   * @param {string} pwToken - The pwToken parameter value
   * @param {string} orgUuid - The orgUuid in user service for the given event
   * @return {string} - The constructed URL string with added parameters if provided
   */
  getUserManagementRegisterUrl: (
    userSub: string,
    pwToken: string,
    orgUuid: string
  ): string => {
    const registerUrl = new URL(
      _composeUserManagementUrl({
        urlPath: '/register',
        useSearch: false,
        useRedirect: true,
      })
    );

    if (orgUuid) {
      registerUrl.searchParams.set('orgUuid', orgUuid);
    }

    if (userSub && pwToken) {
      registerUrl.searchParams.set('sub', userSub);
      registerUrl.searchParams.set('pw_token', pwToken);
    }
    return registerUrl.toString();
  },

  /**
   * redirect to user-management-url/lab/{urlPath} with window.location.search data
   * @param urlPath {string} the relative path to redirect to
   */
  redirectToUserManagement: (urlPath: string): void => {
    if (!window) {
      return;
    }

    location.assign(
      _composeUserManagementUrl({
        urlPath,
        useSearch: true,
        useRedirect: false,
      })
    );
  },

  /**
   * redirect to user-management-url/lab/{urlPath} with redirect data
   * @param urlPath {string} the relative path to redirect to
   */
  redirectToUserManagementWithRedirectSet: (
    urlPath: string,
    orgUuid?: string | null
  ): void => {
    if (!window) {
      return;
    }

    const userManagementUrl = new URL(
      _composeUserManagementUrl({
        urlPath,
        useSearch: false,
        useRedirect: true,
      })
    );
    if (orgUuid) {
      userManagementUrl.searchParams.set('orgUuid', orgUuid);
    }

    location.assign(userManagementUrl.toString());
  },

  /**
   * redirect to survey-service-url/self-assessments/{uuid} with redirect data
   * @param selfAssessmentUUID {string} the UUID of the self assessment to redirect to
   */
  redirectToSelfAssessment: (selfAssessmentUUID: string): void => {
    if (!window) {
      return;
    }

    const urlPath = `/self-assessments/${selfAssessmentUUID}`;
    location.assign(_composeSurveyServiceUrl(urlPath, false, true));
  },
};

export default RedirectHelper;
