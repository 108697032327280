// @ts-nocheck FIXME
const statusLabel = 'Checking connection...';
const status = 'loading';

const networkList = [
  {
    url: 'https://app.live.cluster.betterlesson.com/api/v1/ping',
    displayUrl: '*.betterlesson.com',
    errorMessage:
      'Network does not allow navigation within our user facing applications',
    status,
    statusLabel,
  },

  {
    url: 'https://app.live.cluster.betterlesson.io/api/v1/ping',
    displayUrl: '*.betterlesson.io',
    errorMessage:
      'Network does not enable connections to our backend service and data stores',
    status,
    statusLabel,
  },

  {
    url: 'https://support.pendo.io/',
    displayUrl: '*.pendo.io',
    errorMessage: 'Network does not allow for in-app guidance',
    status,
    statusLabel,
  },

  {
    url: 'https://help.alchemer.com/',
    displayUrl: '*.alchemer.com',
    errorMessage: 'Network does not allow for surveys and self-assessments',
    status,
    statusLabel,
  },

  {
    url: 'https://zoom.us/',
    displayUrl: 'zoom.us',
    errorMessage: 'Network does not allow for video sessions',
    status,
    statusLabel,
  },

  {
    url: 'https://support.zoom.us/',
    displayUrl: '*.zoom.us',
    errorMessage: 'Network does not allow for video sessions',
    status,
    statusLabel,
  },
];

export default networkList;
