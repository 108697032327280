// @ts-nocheck FIXME
import DescriptionTextBox from './DescriptionTextBox';
import ConfirmDeleteEntryRow from './EntryStateRows/ConfirmDelete';
import EditEntryRow from './EntryStateRows/Edit';
import ViewEntryRow from './EntryStateRows/View';

const ParticipantDetails = (props) => {
  // editable for: users who have proper permissions or the owner of the page
  const userCanEditData = false;

  let entriesContainerNode = null;
  if (
    (props.pageParticipant && props.pageParticipant.visionEntries.length > 0) ||
    props.ui.newEntry.opened
  ) {
    const entriesNodes = props.pageParticipant.visionEntries.map((entry, i) => {
      if (i === props.ui.activeEntryIndex && userCanEditData) {
        // if trying to delete the entry
        // show the Confirm Delete form in the capsule
        if (props.ui.showConfirmDelete) {
          return (
            <ConfirmDeleteEntryRow
              key={'entry-' + entry.id}
              ui={props.ui.editEntry}
              highlightActiveEntry={props.ui.highlightActiveEntry}
              id={entry.id}
              type={entry.type}
              delete={props.deleteEntry}
              cancel={props.hideConfirmDelete}
            />
          );
        }
        // otherwise, show the form to edit the entry
        return (
          <EditEntryRow
            key={'entry-' + entry.id}
            ui={props.ui.editEntry}
            highlightActiveEntry={props.ui.highlightActiveEntry}
            type={entry.type}
            close={() => props.setActiveEntryIndex(null)}
            data={entry}
            toggleIsPrivate={props.toggleIsPrivate}
            clearError={props.clearError}
            submit={props.submitEntry}
            confirmDelete={props.showConfirmDelete}
            editMode={true}
            // these are for artifacts
            addEntryCallback={props.addEntryCallback}
            editEntryCallback={props.editEntryCallback}
            setErrorAlert={props.setErrorAlert}
            entryParticipantId={props.pageParticipant.id}
          />
        );
      }
      return (
        <ViewEntryRow
          {...entry}
          edit={() => props.setActiveEntryIndex(i)}
          key={'vision-entry-' + entry.id}
          userCanEditData={userCanEditData}
          artifactModal={props.ui.artifactModal}
          activeAction={props.ui.activeAction}
        />
      );
    });

    let entriesContainerClass = '';
    let expandButtonNode = null;
    if (!userCanEditData) {
      entriesContainerClass = ' bl4-profile__entries--collapsable';
      expandButtonNode = (
        <div className="bl4-profile__artifact-more">
          <div
            className="bl4__btn bl4__btn--blue"
            onClick={props.toggleEntriesCollapsed}
          >
            Show Less
          </div>
        </div>
      );
      if (props.ui.entriesCollapsed) {
        entriesContainerClass += ' bl4-profile__entries--collapsed';
        expandButtonNode = (
          <div className="bl4-profile__artifact-more">
            <div
              className="bl4__btn bl4__btn--blue"
              onClick={props.toggleEntriesCollapsed}
            >
              Show More
            </div>
          </div>
        );
      }
    }

    entriesContainerNode = (
      <div className={'bl4-profile__entries' + entriesContainerClass}>
        <div className="bl4-profile__artifacts-headline">
          My Vision Artifacts
        </div>
        {entriesNodes}
        {expandButtonNode}
      </div>
    );
  }

  const visionNode = (
    <DescriptionTextBox
      title={'My Vision'}
      feature={'vision'}
      description={
        'Your vision describes your goals for growth in your work with BetterLesson.'
      }
      mode={props.ui.visionMode}
      changeMode={props.changeMode}
      submitTextArea={props.submitTextArea}
      content={props.pageParticipant.vision}
      userCanEditData={userCanEditData}
      activeAction={props.ui.activeAction}
    />
  );

  let designSummaryNode = null;
  if (userCanEditData || props.pageParticipant.design_summary) {
    designSummaryNode = (
      <DescriptionTextBox
        title={'My Design Summary'}
        feature={'design'}
        description={
          'Your design summary is the what, why, and how of what you want to build this school year, to get to your vision.'
        }
        mode={props.ui.designMode}
        changeMode={props.changeMode}
        submitTextArea={props.submitTextArea}
        content={props.pageParticipant.design_summary}
        userCanEditData={userCanEditData}
        activeAction={props.ui.activeAction}
      />
    );
  }

  return (
    <div>
      <div id="bl4-profile__vision">
        <div className="bl4-profile__participant-text bl4__capsule bl4__capsule--header-graphic">
          {visionNode}
          {designSummaryNode}
        </div>
        {entriesContainerNode}
      </div>
    </div>
  );
};

export default ParticipantDetails;
