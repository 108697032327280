// @ts-nocheck FIXME
import StringHelpers from '@legacy-components/components/StringHelpers';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import React from 'react';

const errorCodes = {
  no_email: 'Please enter an email.',
  no_user:
    '<span style="color: #e9183e;">This email was not found in the lab.</span> <a href="/bl/tools/bulk-participant-importer" style="text-decoration: none; color: #2196f3;">Use Coaching bulk upload to add a new participant.</a>',
  no_participant: 'This user is not a valid participant.',
  no_engagement: 'This user does not have a valid coaching engagement.',
  cohort_exists: 'This user already belongs to a cohort.',
  cohort_invalid: 'This cohort no longer exists.',
  same_cohort: 'This user is already in this cohort.',
};

const AddCohortUser = (props) => {
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [generalError, setGeneralError] = React.useState('');
  const [sendEmail, setSendEmail] = React.useState(true);
  const [affiliation, setAffiliation] = React.useState('Partner');

  const handleFormClick = () => {
    setGeneralError('');
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    props.close();
  };

  const handleEmailBlur = (e) => {
    if (!StringHelpers.isEmailValid(e.target.value)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
    } else {
      setEmailError('');
    }
  };

  const handleToggleEmail = (e) => {
    setSendEmail(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!StringHelpers.isEmailValid(email)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
      return;
    } else {
      setEmailError('');
    }

    const submitData = {
      email: email,
      affiliation: affiliation,
      sendEmail: sendEmail,
      successCallback: props.close,
      failureCallback: setGeneralError,
    };
    props.submit(submitData);
  };

  const submitMoveParticipant = (e, coachingEngagementId) => {
    e.preventDefault();

    if (!StringHelpers.isEmailValid(email)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
      return;
    } else {
      setEmailError('');
    }

    props.move({
      recordChange: props.recordChange,
      email: email,
      sendEmail: sendEmail,
      successCallback: props.close,
      failureCallback: setGeneralError,
      coachingEngagementId: coachingEngagementId,
    });
  };

  // disable buttons when submitting
  const btnProps = props.isLoading ? { disabled: true } : null;

  // show error message
  let errorNode;
  // remove bottom padding when there are error text to show
  let formFieldErrorClass = '';

  if (generalError && 'email' in generalError) {
    formFieldErrorClass = ' cohort-dashboard__add-participant-modal-formfield';
    let errorText = errorCodes[generalError.email];

    let moveParticipantLink;

    if (generalError.email === 'cohort_exists') {
      // save coaching engagement id from error message
      const coachingEngagementId = generalError.coaching_engagement_id;
      moveParticipantLink = (
        <div>
          <a
            className="tc__link"
            onClick={(e) => submitMoveParticipant(e, coachingEngagementId)}
          >
            Move to this cohort
          </a>
        </div>
      );
      errorText = `${generalError.user_name} already belongs to cohort ${generalError.cohort_name}`;
    }
    if (generalError.email === 'no_user') {
      errorNode = (
        <>
          <div
            className="cohort-dashboard__add-participant-modal-error"
            dangerouslySetInnerHTML={{ __html: errorText }}
          />
          {moveParticipantLink}
        </>
      );
    } else {
      errorNode = (
        <>
          <ErrorText
            errorClass="cohort-dashboard__add-participant-modal-error"
            error={errorText}
          />
          {moveParticipantLink}
        </>
      );
    }
  }

  return (
    <ActionModal
      headlineText={`Add Participant to ${props.title}`}
      close={handleCloseModal}
      spaceFromTop={50}
    >
      <form
        className="participant-v5__modal-body participant-v5__participant-meeting-clip-modal"
        onClick={handleFormClick}
      >
        <label className="workspace__form-row participant-v5__modal-top-row">
          <label className="workspace__form-row">
            <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
              Email <span className="coach-match__required">*</span>
            </div>
            <FormField
              type="text"
              className={`admin-v5__event-pl-form-field${formFieldErrorClass}`}
              disabled={props.isLoading}
              error={emailError}
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setEmailError('')}
              onBlue={handleEmailBlur}
              maxLength="256"
              data-testid="email"
            />
          </label>

          {errorNode}

          <label className="workspace__form-row">
            <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
              Affiliation <span className="coach-match__required">*</span>
            </div>
            <FormField
              type="select"
              className={'admin-v5__event-pl-form-field'}
              disabled={props.isLoading}
              defaultValue="Partner"
              dataTestId="affiliation"
              onChange={(e) => setAffiliation(e.target.value)}
            >
              {['Partner', 'BL Team'].map((affiliationType, id) => {
                return (
                  <option key={'affiliationType-' + id} value={affiliationType}>
                    {affiliationType}
                  </option>
                );
              })}
            </FormField>
          </label>

          <div className="workspace__form-row v5__heading-5 participant-v5__meeting-no-show--checkbox-row">
            <div className="participant-v5__meeting-no-show--checkbox">
              <input
                type="checkbox"
                id="send_email"
                defaultChecked={sendEmail}
                className="tc__checkbox"
                onChange={handleToggleEmail}
                disabled={props.isLoading}
                data-testid="participant-toggle-cancel"
              />
            </div>
            <div className="v5__body-copy">Send Welcome Email</div>
          </div>

          <div className="workspace__form-row">
            <div>
              <button
                className="v5__btn"
                onClick={handleSubmit}
                {...btnProps}
                data-testid="add-new-pl-button"
              >
                <Spinner loading={props.isLoading} />
                Add Participant
              </button>
            </div>
          </div>
        </label>
      </form>
    </ActionModal>
  );
};

AddCohortUser.propTypes = {
  move: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default AddCohortUser;
