// @ts-nocheck FIXME
import Request from './../components/Request';

const PortfolioApi = {
  editPortfolio: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/portfolio/' +
        data.portfolioId +
        '/edit',
      JSON.stringify(data)
    );
  },

  editLoop: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/portfolio/' +
        data.portfolioId +
        '/loop/' +
        data.loopId +
        '/edit',
      JSON.stringify(data)
    );
  },

  editArtifact: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/portfolio/' +
        data.portfolioId +
        '/artifact/' +
        data.artifactId +
        '/edit',
      JSON.stringify(data)
    );
  },

  editArtifactComment: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/portfolio/' +
        data.portfolioId +
        '/artifact/comment/' +
        data.commentId +
        '/edit',
      JSON.stringify(data)
    );
  },

  editFeedbackComment: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/portfolio/' +
        data.portfolioId +
        '/feedback/comment/' +
        data.commentId +
        '/edit',
      JSON.stringify(data)
    );
  },

  unpublishPortfolio: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/portfolio/' +
        data.portfolioId +
        '/unpublish',
      JSON.stringify(data)
    );
  },

  publishPortfolio: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/portfolio/' +
        data.portfolioId +
        '/publish',
      JSON.stringify(data)
    );
  },

  deletePortfolio: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/portfolio/' +
        data.portfolioId +
        '/delete',
      {}
    );
  },

  generatePdf: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/portfolio/' +
        data.portfolioId +
        '/pdf',
      JSON.stringify(data)
    );
  },
};

export default PortfolioApi;
