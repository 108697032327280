// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import { Router } from './../../../components/Router';
import NavigationLink from './../../NavigationLink';
import Teacher from './modules/Teacher';

const MasterTeachersDirectory = (props) => {
  // open/close dropdown menu in breadcrumbs
  const toggleDropdown = (e, field) => {
    e.stopPropagation();
    props.toggleDropdown(field);
  };

  // clicking the Browse button goes to the relevant megamenu
  const handleBrowse = () => {
    let subject = 'blended';
    switch (props.mtp_page_data.teacher_directory_subject) {
      case 'math':
        subject = 'common_core_math';
        break;
      case 'ela':
        subject = 'common_core_ela';
        break;
      case 'science':
        subject = 'next_gen_science';
        break;
    }
    Router.route(
      process.env.REACT_APP_TEACHING_URL +
        '/browse/' +
        subject +
        '?from=master_teacher_directory'
    );
  };

  // set up any visible dropdowns in the breadcrumbs
  let subjectDropdownNode, gradeDropdownNode;
  if (props.ui.activeDropdown == 'subject') {
    // make a list of subject options for the dropdown
    const subjects = [
      {
        id: 1,
        name: 'Math',
        url_name: 'math',
      },
      {
        id: 2,
        name: 'Science',
        url_name: 'science',
      },
      {
        id: 3,
        name: 'English / Language Arts',
        url_name: 'english_-_language_arts',
      },
      {
        id: 4,
        name: 'Blended',
        url_name: 'blended',
      },
    ];
    // build the dropdown items
    const subjectDropdownItems = subjects.map((subject) => {
      // determine whether or not to highlight the subject in the dropdown
      const currentSubject = props.mtp_page_data.current_subject_name;
      const selectedClass =
        subject.name == currentSubject ||
        (!currentSubject && subject.name == 'Blended')
          ? 'selected'
          : '';

      return (
        <li key={'subject-dropdown-' + subject.id} className={selectedClass}>
          <NavigationLink
            className="mt-dir__breadcrumb-dropdown-link"
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/master_teachers/' +
              subject.url_name
            }
          >
            {subject.name}
          </NavigationLink>
        </li>
      );
    });
    subjectDropdownNode = (
      <div className="mtp-lesson__crumb-dropdown mt-dir__breadcrumb-dropdown">
        <ul>{subjectDropdownItems}</ul>
      </div>
    );
  } else if (props.ui.activeDropdown == 'grade') {
    const gradeDropdownItems = props.mtp_page_data.breadcrumb_grade_options.map(
      (option) => {
        const grade = option.Grade;
        return (
          <li
            key={'grade-dropdown-' + grade.id}
            className={grade.current ? 'selected' : ''}
          >
            <NavigationLink
              className="mt-dir__breadcrumb-dropdown-link"
              url={
                process.env.REACT_APP_TEACHING_URL +
                '/browse/master_teachers/' +
                props.mtp_page_data.current_subject_url_name +
                '/' +
                grade.url_name
              }
            >
              {grade.name}
            </NavigationLink>
          </li>
        );
      }
    );
    gradeDropdownNode = (
      <div className="mtp-lesson__crumb-dropdown mt-dir__breadcrumb-dropdown">
        <ul>{gradeDropdownItems}</ul>
      </div>
    );
  }

  let gradeBreadcrumbNode, browseBtnText, teacherNodes;
  if (props.mtp_page_data.grade_groups) {
    gradeBreadcrumbNode = (
      <div
        onClick={(e) => toggleDropdown(e, 'grade')}
        className="mt-dir__breadcrumb mt-dir__breadcrumb--clickable"
      >
        {props.mtp_page_data.current_grade_group_name}
        <span className="mt-dir__breadcrumb-arrow tc-bold-arrow"></span>
        {gradeDropdownNode}
      </div>
    );
    browseBtnText = props.mtp_page_data.current_subject_name + ' Lessons';
    teacherNodes = props.mtp_page_data.grade_groups.map((grade) => {
      return (
        <div
          key={'grade-' + grade.label}
          className="row row--1200 mt-dir__teacher-row"
        >
          <div className="cute-12-desktop">
            <h2 className="tc__headline-4">{grade.label}</h2>
            <div className="mt-dir__teachers tc__body-copy">
              {grade.teachers.map((teacher) => {
                return (
                  <Teacher key={'teacher-' + teacher.User.id} {...teacher} />
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  } else if (props.mtp_page_data.blended_teachers) {
    browseBtnText = 'Blended Topics';
    teacherNodes = (
      <div className="row row--1200">
        <div className="cute-12-desktop">
          <div className="mt-dir__teachers tc__body-copy">
            {props.mtp_page_data.blended_teachers.map((teacher) => {
              return (
                <Teacher key={'teacher-' + teacher.User.id} {...teacher} />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="tc__page mt-dir" onClick={props.closeDropdown}>
      <Header page="masterTeachersDirectory" {...props} />

      <div className="mt-dir__breadcrumbs-wrapper show-desktop hide-phone">
        <div className="row row--1200 mt-dir__breadcrumbs">
          <div className="mt-dir__breadcrumb">Master Teachers</div>
          <div
            onClick={(e) => toggleDropdown(e, 'subject')}
            className="mt-dir__breadcrumb mt-dir__breadcrumb--clickable"
          >
            {props.mtp_page_data.current_subject_name || 'Blended'}
            <span className="mt-dir__breadcrumb-arrow tc-bold-arrow"></span>
            {subjectDropdownNode}
          </div>
          {gradeBreadcrumbNode}
        </div>
      </div>

      <div className="bl4__page-background show-desktop hide-phone"></div>

      <div className="mt-dir__top-section">
        <div className="row row--1200">
          <div className="cute-10-desktop cute-12-phone">
            <h1 className="tc__headline-2 mt-dir__headline">
              Meet the Master Teachers
            </h1>
            <div className="tc__body-copy">
              This project brings together 190+ brilliant, creative teachers in
              grades K-12 Math, ELA, Science, and Blended Learning. These
              talented (not to mention hardworking) folks are sharing lessons as
              they teach them everyday! And these aren&apos;t just the normal
              lessons; they&apos;re super accessible and classroom-ready with
              downloadable examples, videos, and resources. Our Master Teachers
              also share their personal reflections and real-life insights into
              what worked, what misfired and tips/tricks to successful
              implementation.
            </div>
            <button
              onClick={handleBrowse}
              className="bl4__btn bl4__btn--blue mt-dir__browse-btn"
            >
              Browse {browseBtnText}
            </button>
          </div>
          <div className="cute-2-desktop show-desktop hide-phone mt-dir__logo">
            <img
              src="/legacy-images/mtp-images/bl-partners-logos.png"
              alt="BetterLessons Partners"
              className="mt-dir__logo-img"
            />
          </div>
        </div>
      </div>

      {teacherNodes}
    </div>
  );
};

MasterTeachersDirectory.propTypes = {
  ui: PropTypes.object.isRequired,
  mtp_page_data: PropTypes.object.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  closeDropdown: PropTypes.func.isRequired,
};

export default MasterTeachersDirectory;
