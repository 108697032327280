// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../../components/ArrayHelpers';
import Utils from './../../../../components/DynamicUtils';
import ScaleCenterImage from './../../../ScaleCenterImage';
import AbridgeText from './../../Common/AbridgeText';

const PortfolioStoryCapsuleTimeline = (props) => {
  // for curated strategies that the current user can view, open the link in a new tab -
  // otherwise, for other entry types, open the relevant modal
  const handleEntryClick = (data) => {
    if (data.type == 'curated_strategy') {
      if (data.is_viewable) {
        let strategyUrl =
          process.env.REACT_APP_TEACHING_URL + '/strategy/' + data.strategy_id;
        if (data.seo_url) {
          strategyUrl += '/' + data.seo_url;
        }
        strategyUrl += '?from=profile';

        window.open(strategyUrl);
      }
    } else {
      props.portfolioArtifactModal.open(data);
    }
  };

  // set up starting statement at the top of the timeline if there is one
  let startingStatementNode;
  if (props.startingStatement && props.startingStatement.description) {
    // only show the grey line if there are more items in the timeline
    const lineNode = ArrayHelpers.hasElements(props.entries) ? (
      <div className="bl4-profile__story-timeline-entry-line"></div>
    ) : null;
    // set up data to pass to artifact overlay
    const startingStatementData = Utils.clone(props.startingStatement);
    startingStatementData.storyId = props.storyId;
    startingStatementData.entryType = 'starting_statement';
    startingStatementData.connections = {
      preview_type: 'ckeditor_output',
    };

    startingStatementNode = (
      <div
        onClick={() => handleEntryClick(startingStatementData)}
        className="bl4-profile__story-timeline-entry bl4-profile__story-timeline-entry--green"
      >
        {lineNode}
        <div className="bl4-profile__story-timeline-entry-dot"></div>
        <div className="bl4-profile__story-timeline-entry-icon tc-v4-reflection"></div>
        <div className="bl4-profile__story-timeline-entry-body">
          <div className="bl4-profile__story-timeline-entry-title">
            Starting Reflection
          </div>
          <AbridgeText
            maxHeight={110}
            setInnerHtml={true}
            className="ckeditor__output"
          >
            {props.startingStatement.description}
          </AbridgeText>
        </div>
      </div>
    );
  }

  return (
    <div className="bl4-profile__story-timeline">
      {startingStatementNode}
      {props.entries.map((entry, i) => {
        // last entry does not have the grey line on the left
        const lineNode =
          i == props.entries.length - 1 ? null : (
            <div className="bl4-profile__story-timeline-entry-line"></div>
          );

        // track data to be sent to the artifact overlay
        const entryData = Utils.clone(entry);
        entryData.storyId = props.storyId;
        entryData.entryType = entry.tag ? entry.tag : entry.type;

        // set up timeline entry contents
        let entryClass = '';
        let entryTitle = '';
        let iconClass = '';
        let bodyNode, imgNode;
        switch (entry.type) {
          case 'curated_strategy':
            entryClass = 'bl4-profile__story-timeline-entry--green';
            entryTitle = 'Strategy';
            iconClass = 'tc-v4-strategy';

            // if the current user can't view the strategy, change the cursor
            if (!entry.is_viewable) {
              entryClass += ' bl4-profile__story-timeline-entry--not-clickable';
            }

            // set up preview img
            if (
              entry.lessonfile &&
              entry.lessonfile.connections &&
              entry.lessonfile.connections.preview
            ) {
              imgNode = (
                <div className="bl4-profile__story-timeline-img-container">
                  <ScaleCenterImage
                    src={entry.lessonfile.connections.preview}
                    className="bl4-profile__story-timeline-img"
                  />
                </div>
              );
            }

            bodyNode = (
              <div>
                {imgNode}
                <div className="bl4-profile__story-timeline-entry-headline">
                  {entry.title}
                </div>
              </div>
            );
            break;
          case 'custom_strategy':
            entryData.connections = {
              preview_type: 'text',
              content: {
                preview: entry.description,
              },
            };
            entryClass = 'bl4-profile__story-timeline-entry--green';
            entryTitle = 'Strategy';
            iconClass = 'tc-v4-strategy';
            bodyNode = (
              <div className="bl4-profile__story-timeline-entry-headline">
                {entry.title}
              </div>
            );
            break;
          case 'text':
            entryData.connections = {
              preview_type: 'ckeditor_output',
            };
            entryTitle = 'Artifact of Practice';
            iconClass = 'tc-v4-text';
            bodyNode = (
              <AbridgeText
                maxHeight={110}
                setInnerHtml={true}
                className="ckeditor__output"
              >
                {entry.description}
              </AbridgeText>
            );
            break;
          case 'file':
          case 'link':
          case 'meeting_clip':
            entryTitle = 'Artifact of Practice';
            iconClass = 'tc-v4-upload';

            // set up preview img
            if (entry.connections.preview) {
              imgNode = (
                <div className="bl4-profile__story-timeline-img-container">
                  <ScaleCenterImage
                    src={entry.connections.preview}
                    className="bl4-profile__story-timeline-img"
                  />
                </div>
              );
            }

            bodyNode = (
              <div>
                {imgNode}
                <div className="bl4-profile__story-timeline-entry-headline">
                  {entry.title}
                </div>
              </div>
            );
            break;
        }

        switch (entry.tag) {
          case 'evidence':
            entryClass = 'bl4-profile__story-timeline-entry--green';
            entryTitle = 'Evidence';
            iconClass = 'tc-v4-leaf';
            break;
          case 'reflection':
            entryClass = 'bl4-profile__story-timeline-entry--green';
            entryTitle = 'Reflection';
            iconClass = 'tc-v4-reflection';
            break;
          case 'coach_note':
            entryData.showCreator = true;
            entryTitle = 'Coach Note';
            break;
        }

        return (
          <div
            key={'entry-' + entry.id}
            onClick={() => handleEntryClick(entryData)}
            className={'bl4-profile__story-timeline-entry ' + entryClass}
          >
            {lineNode}
            <div className="bl4-profile__story-timeline-entry-dot"></div>
            <div
              className={'bl4-profile__story-timeline-entry-icon ' + iconClass}
            ></div>
            <div className="bl4-profile__story-timeline-entry-body">
              <div className="bl4-profile__story-timeline-entry-title">
                {entryTitle}
              </div>
              {bodyNode}
            </div>
          </div>
        );
      })}
    </div>
  );
};

PortfolioStoryCapsuleTimeline.propTypes = {
  startingStatement: PropTypes.object,
  storyId: PropTypes.number.isRequired,
  entries: PropTypes.array.isRequired,
  portfolioArtifactModal: PropTypes.object.isRequired,
};

export default PortfolioStoryCapsuleTimeline;
