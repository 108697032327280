// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';
import CoreCrumb from './CoreCrumb';

const Breadcrumbs = (props) => {
  const lessonDropdownItems = [];
  let gradeCrumbNode, domainCrumbNode, coreCrumbNode;

  if (props.grade) {
    gradeCrumbNode = (
      <div className="mtp-lesson__crumb">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <span className="mtp-lesson__crumb-caption">
            {props.grade.CommonCore.description}
          </span>
        </a>
        <div className="mtp-lesson__crumb-border"></div>
      </div>
    );
  }

  if (props.domain) {
    const domainDropdownItems = props.domains.map((domain) => {
      const selectedClass = domain.CommonCore.current ? 'selected' : '';
      return (
        <li className={selectedClass} key={'domain-' + domain.CommonCore.id}>
          <NavigationLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/common_core/standard/' +
              domain.CommonCore.id +
              '/' +
              domain.CommonCore.seo_url +
              '?from=breadcrumb_standard_level1'
            }
          >
            <span>{domain.CommonCore.description}</span>
          </NavigationLink>
        </li>
      );
    });
    const domainDropdownClass = props.domainDropdownActive ? ' active' : '';
    const domainDropdownNode = (
      <div className={'mtp-lesson__crumb-dropdown' + domainDropdownClass}>
        <ul>{domainDropdownItems}</ul>
      </div>
    );
    const borderNode = props.core ? (
      <div className="mtp-lesson__crumb-border"></div>
    ) : null;

    domainCrumbNode = (
      <div className="mtp-lesson__crumb">
        <a
          onClick={(e) => {
            e.preventDefault();
            props.openDomainDropdown();
          }}
        >
          <span className="mtp-lesson__crumb-caption">
            {props.domain.CommonCore.description}
          </span>
          <span className="mtp-lesson__crumb-icon tc-bold-arrow"></span>
        </a>
        {domainDropdownNode}
        {borderNode}
      </div>
    );
  }
  if (props.core) {
    coreCrumbNode = props.core.map((core, i) => {
      return (
        <CoreCrumb
          key={'core-' + core.CommonCore.id}
          core={core}
          increment={i}
          crumbCount={props.core.length}
        />
      );
    });
  }
  return (
    <div className="mtp-lesson__breadcumb-wrapper show-desktop hide-phone">
      <div className="row row--1200">
        {gradeCrumbNode}
        {domainCrumbNode}
        {coreCrumbNode}
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  grade: PropTypes.object.isRequired,
  domain: PropTypes.object,
  domains: PropTypes.array.isRequired,
  domainDropdownActive: PropTypes.bool,
  core: PropTypes.array,
  openDomainDropdown: PropTypes.func.isRequired,
};

export default Breadcrumbs;
