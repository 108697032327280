// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const RadioCheckSelect = (props) => {
  const handleChange = () => {
    props.containerCallback(props.id, !props.selected);
  };

  // this component handles two input types
  const type = props.type === 'radio' ? 'radio' : 'checkbox';
  const classNames = props.className || '';

  return (
    <div className={classNames}>
      {/* This empty onChange is needed to suppress a warning */}
      <label onClick={handleChange}>
        <input onChange={() => {}} type={type} checked={props.selected} />
        <span onClick={(e) => e.preventDefault()} className="label">
          {props.label}
        </span>
      </label>
    </div>
  );
};

export default RadioCheckSelect;

RadioCheckSelect.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  containerCallback: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

RadioCheckSelect.defaultProps = {
  containerSelected: false,
  type: 'checkbox',
};
