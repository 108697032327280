// @ts-nocheck FIXME
import React from 'react';
import Layer from './Layer';

class Layers extends React.Component {
  render() {
    const layerNodes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(function (layer) {
      const layerStyle = {
        display: 'none',
      };
      layerStyle.zIndex = layer * 100;
      const key = 'react-layer-' + layer;
      return <Layer key={key} offset={layer} style={layerStyle} />;
    });

    return <div id="react-layer-container">{layerNodes}</div>;
  }
}

export default Layers;
