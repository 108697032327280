// @ts-nocheck FIXME
import Request from './../components/Request';

const VisionApi = {
  createArtifact: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/artifact/save',
      JSON.stringify(data)
    ),

  editArtifact: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/artifact/' +
        data.id +
        '/edit',
      JSON.stringify(data)
    ),

  deleteArtifact: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/artifact/' +
        data.artifactId +
        '/remove',
      JSON.stringify(data)
    ),

  resortArtifacts: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/artifact/sort',
      JSON.stringify(data)
    ),

  submitTextArtifact: function (data) {
    let url;
    if (data.artifactId) {
      url =
        process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/text-artifact/' +
        data.artifactId +
        '/save';
    } else {
      url =
        process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/text-artifact/save';
    }
    return Request.post(url, JSON.stringify(data));
  },

  moveParticipantVisionArtifactToParticipantOutcome: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/vision/artifact/' +
        data.artifactId +
        '/move/outcome/' +
        data.participantOutcomeId,
      JSON.stringify(data)
    );
  },
};

export default VisionApi;
