// @ts-nocheck FIXME
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import NavigationLink from '@legacy-components/react/NavigationLink';
import OutcomeGrowthEntry from '@legacy-components/react/SelfAssessmentProgress/SelfAssessmentProgress';
import TimeDelta from '@legacy-components/react/V4/Common/TimeDelta';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import CustomStrategyEntry from '@legacy-components/react/V5/Workspace/modules/Entries/CustomStrategyEntry';
import DocumentEntry from '@legacy-components/react/V5/Workspace/modules/Entries/DocumentEntry';
import GoalEntry from '@legacy-components/react/V5/Workspace/modules/Entries/GoalEntry';
import LessonEntry from '@legacy-components/react/V5/Workspace/modules/Entries/LessonEntry';
import MeetingClipEntry from '@legacy-components/react/V5/Workspace/modules/Entries/MeetingClipEntry';
import SelfAssessmentEntry from '@legacy-components/react/V5/Workspace/modules/Entries/SelfAssesmentEntry';
import StrategyEntry from '@legacy-components/react/V5/Workspace/modules/Entries/StrategyEntry';
import TextEntry from '@legacy-components/react/V5/Workspace/modules/Entries/TextEntry';
import PropTypes from 'prop-types';

const EntryContainer = (props) => {
  // set an ID for this component in case we need to scroll to it
  const entryId = `entry-${props.id}`;

  // set up the entry icon and body contents
  let iconClass;
  let title = props.title;
  let bodyNode;
  let hideAuthor = false;
  if (props.authorHide) {
    hideAuthor = true;
  }
  switch (props.type) {
    case 'custom_strategy':
      iconClass = 'tc-v4-strategy';
      bodyNode = <CustomStrategyEntry {...props} />;
      break;
    case 'curated_strategy':
      title = 'Strategy';
      iconClass = 'tc-v4-strategy';
      bodyNode = <StrategyEntry {...props} />;
      break;
    case 'lesson':
      title = 'Lesson';
      iconClass = 'tc-v4-ela';
      bodyNode = <LessonEntry {...props} />;
      break;
    case 'file':
      title = (
        <NavigationLink
          className="v5__link workspace__entry-header-text-link"
          url={`/lab/highlights/artifact/${props.story_file_id}`}
        >
          {props.title}
        </NavigationLink>
      );
      iconClass = 'tc-v4-page';
      bodyNode = <DocumentEntry {...props} source_page="highlights" />;
      break;
    case 'link':
      title = (
        <NavigationLink
          className="v5__link workspace__entry-header-text-link"
          url={props.connections.link}
          target="_blank"
        >
          {props.title}
        </NavigationLink>
      );
      iconClass = 'tc-v4-link';
      bodyNode = <DocumentEntry {...props} source_page="highlights" />;
      break;
    case 'text':
      iconClass = 'tc-note';
      bodyNode = <TextEntry {...props} />;
      break;
    case 'meeting_clip':
      iconClass = 'tc-v4-calendar';
      bodyNode = <MeetingClipEntry {...props} entryId={entryId} />;
      break;
    case 'assessment_survey':
      title = props.title;
      iconClass = 'tc-v5-survey-icon';
      bodyNode = <SelfAssessmentEntry {...props} />;
      break;
    case 'participant_goal':
      iconClass = props.completed
        ? 'tc-v5-milestone-complete'
        : 'tc-v5-milestone';
      bodyNode = <GoalEntry {...props} />;
      break;
    case 'self_assessment_progress':
      title = 'Growth Reflection';
      iconClass = 'tc-v5-survey-icon';
      bodyNode = <OutcomeGrowthEntry {...props} />;
  }
  // set up the entry author
  const authorNode = !hideAuthor && props.author && (
    <div className="workspace__entry-author workspace__entry-author--centered">
      <div className="workspace__comment-photo-col">
        <UserPhoto
          src={props.author.photo}
          userId={props.author.id}
          firstName={props.author.first_name}
          lastName={props.author.last_name}
          diameter={40}
        />
      </div>
      <div className="workspace__entry-author-name">
        {props.author.first_name} {props.author.last_name}
      </div>
    </div>
  );

  // set up entry tag
  let tagNode;
  if (props.outcomeTitle) {
    tagNode = (
      <div className="workspace__entry-body workspace__tag-group highlights__tag-group">
        <Tooltip tooltip={props.outcomeTitle}>
          <div className="v5__goal-tag workspace__tag">
            {props.outcomeTitle}
          </div>
        </Tooltip>
      </div>
    );
  }

  // when displayed in PL dashboard icon background should be grey to match parent container
  const greyIconBgClass = props.showInPLDash ? ' highlights__grey-bg' : '';

  const iconNode = !props.hideIcon && (
    <div className={`workspace__entry-header-icon${greyIconBgClass}`}>
      <div className={iconClass}>
        <span className="path1"></span>
        <span className="path2"></span>
      </div>
    </div>
  );
  let datetime;
  if (props.meeting) {
    datetime = props.meeting.meeting_time;
  } else if (props.growth_highlight_id) {
    datetime = props.highlighted;
  } else {
    datetime = props.created_formatted;
  }
  return (
    <div
      id={entryId}
      className={`highlights__entry${
        props.hideIcon ? ' highlights__show_entry_icon' : ''
      }`}
    >
      <div className="v5__heading-5 workspace__entry-header-row">
        {iconNode}
        <div className="workspace__entry-header-text">
          <div>{title}</div>
          <div className="workspace__entry-header-timestamp">
            <TimeDelta datetime={datetime} />
          </div>
        </div>
      </div>

      {bodyNode}
      {authorNode}
      {tagNode}
    </div>
  );
};

EntryContainer.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  author: PropTypes.object,
  outcomeTitle: PropTypes.string,
  user: PropTypes.object,
  authorHide: PropTypes.bool,
  toggle: PropTypes.bool,
  showInPLDash: PropTypes.bool,
};

const ConnectedEntryContainer = (props) => (
  <HeaderConsumer>
    {({ user }) => {
      return <EntryContainer {...props} user={user} />;
    }}
  </HeaderConsumer>
);

export default ConnectedEntryContainer;
