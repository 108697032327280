// @ts-nocheck FIXME
import FavoriteApi from './../../apis/V4/FavoriteApi';
import Analytics from './../../components/Analytics';
import Modal from './../../components/Modal';
import { History } from './../../components/Router';
import Store from './../../components/Store';
import HeaderStore from './../../stores/V4/HeaderStore';

const store = {
  user: null,
  ui: {
    sectionExpandToggleCount: 0,
    alert: {},
    loading: false,
    favorited: false,
    favoriteCount: 0,

    // these are for the coach recommend strategy modal
    recommendModal: {
      callbacks: {
        onOpen: (data) => {
          // Record the analytics event:
          Analytics.logEvent({
            category: 'recommend_modal',
            action: 'open',
            label: store.domains
              ? 'from_instructional_strategies_browse'
              : 'from_individual_learning_domain',
          });
        },
      },
    },
    participants: {
      inputStatus: 'blur',
      charCount: 0,
      selectedOption: null,
      options: [],
      visibleOptions: [],
    },

    // clicking on a resource opens the artifact overlay
    artifactModal: {
      callbacks: {
        onOpen: (data) => {
          History.replace(
            null,
            null,
            '/strategy/' + store.strategy.id + '/resource/' + data.lessonfile_id
          );

          // Record the analytics event:
          // Analytics.logEvent({
          //     category: 'artifact',
          //     action: 'view',
          //     label: 'from_outcome_story_page'
          // });
        },
        onClose: () => {
          History.replace(null, null, '/strategy/' + store.strategy.id);

          // Record the analytics event:
          // Analytics.logEvent({
          //     category: 'artifact',
          //     action: 'close',
          //     label: 'from_outcome_story_page'
          // });
        },
      },
    },
    shareModal: {
      callbacks: {
        onOpen: (data) => {
          // Record the analytics event:
          Analytics.logEvent({
            category: 'share_modal',
            action: 'open',
            label: store.domains
              ? 'from_instructional_strategies_browse'
              : 'from_individual_learning_domain',
          });
        },
        onClose: () => {
          // Record the analytics event:
          Analytics.logEvent({
            category: 'share_modal',
            action: 'close',
            label: store.domains
              ? 'from_instructional_strategies_browse'
              : 'from_individual_learning_domain',
          });
        },
      },
    },
  },
  strategy: {},
  strategies: null,
  domains: null,
};

/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.ui.sectionExpandToggleCount = 0;
  store.user = data.user;
  store.ui.alert = {};
  store.strategy = data.strategy || {};
  store.strategies = data.strategies;
  store.domains = data.domains;

  // set up Strategy page-specific data
  if (data.strategy) {
    store.ui.favorited = !!data.strategy.favorite;
    store.ui.favoriteCount = data.strategy.favorite_count || 0;
  }

  // init the modals
  store.ui.recommendModal = Modal(store, store.ui.recommendModal);
  store.ui.artifactModal = Modal(store, store.ui.artifactModal);
  store.ui.shareModal = Modal(store, store.ui.shareModal);
  // the options in the Recommend modal are the participants assigned to the current user
  store.ui.participants.options = data.participants;

  // open the artifact overlay if necessary
  if (data.resourceId) {
    let resource = null;
    // first see if the hero img/video is the correct resource
    if (store.strategy.lessonfile_id == data.resourceId) {
      resource = store.strategy.lessonfile;
    }
    // if not, check the strategy's section resources for the given resource
    else if (store.strategy.sections && store.strategy.sections.length) {
      store.strategy.sections.forEach((section) => {
        if (section.resources && section.resources.length) {
          section.resources.forEach((res) => {
            if (res.lessonfile_id && res.lessonfile_id == data.resourceId) {
              resource = res;
            }
          });
        }
      });
    }
    // if we found the resource, open it in the overlay
    if (resource) {
      store.ui.artifactModal.open(resource);
    }
  }

  // don't trigger event
  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.getRecommendElement = (data) => {
  const field = store.ui.participants;
  field.charCount = data.input.length;

  // Only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    return option.label.toLowerCase().includes(data.input.toLowerCase());
  });

  Analytics.logEvent({
    category: 'recommend_modal',
    action: 'select_participant',
    label: store.domains
      ? 'from_instructional_strategies_browse'
      : 'from_individual_learning_domain',
  });
};

store.selectRecommendElement = (data) => {
  if (!data.feature || !data.field) {
    return false;
  }

  Object.assign(store.ui.participants, data.data);
};

store.addFavorite = (data) => {
  if (store.user && !store.user.id) {
    // Record the analytics event:
    Analytics.logEvent({
      category: 'login_modal',
      action: 'open',
      label: 'from_' + data.fromTag,
    });
    HeaderStore.actionDispatch('openRegisterModal', {
      reason: 'favorite_attempt',
    });
    return true;
  }
  if (data.recommend) {
    data.userId = store.ui.participants.selectedOption.user_id;
  }

  store.ui.loading = true;
  // on the Strategies and Domain pages, find the relevant strategies so we can update them
  let strategies = null;
  if (store.domains) {
    strategies = [];
    store.domains.forEach((domain) => {
      domain.strategies.forEach((strategy) => {
        if (strategy.id == data.itemId) {
          // for now, we want to give them the "loading" state
          strategy.loading = true;
          strategies.push(strategy);
        }
      });
    });
  } else if (store.strategies) {
    strategies = [];
    store.strategies.forEach((strategy) => {
      if (strategy.id == data.itemId) {
        // for now, we want to give them the "loading" state
        strategy.loading = true;
        strategies.push(strategy);
      }
    });
  }

  return FavoriteApi.addFavorite(data)
    .then((response) => {
      if (data.recommend) {
        store.ui.recommendModal.close();
        store.ui.alert = {
          type: 'success',
          message:
            'Recommended for ' +
            store.ui.participants.selectedOption.first_name +
            ' ' +
            store.ui.participants.selectedOption.last_name,
          handleClose: store.clearAlert,
        };
      } else {
        store.ui.favorited = true;
        store.ui.favoriteCount += 1;
      }
      store.ui.loading = false;
      if (strategies) {
        // update the strategies
        strategies.forEach((strategy) => {
          strategy.loading = false;
          strategy.favorited = true;
        });
      }
    })
    .catch((response) => {
      store.ui.recommendModal.close();
      store.ui.loading = false;
      if (strategies) {
        // update the strategies
        strategies.forEach((strategy) => {
          strategy.loading = false;
        });
      }
      setErrorAlert(
        response.error
          ? response.error
          : 'Sorry, we were unable to favorite this strategy. Please try again later.'
      );
    });
};

store.removeFavorite = (data) => {
  store.ui.loading = true;
  // on the Strategies and Domain pages, find the relevant strategies so we can update them
  let strategies = null;
  if (store.domains) {
    strategies = [];
    store.domains.forEach((domain) => {
      domain.strategies.forEach((strategy) => {
        if (strategy.id == data.itemId) {
          // for now, we want to give them the "loading" state
          strategy.loading = true;
          strategies.push(strategy);
        }
      });
    });
  } else if (store.strategies) {
    strategies = [];
    store.strategies.forEach((strategy) => {
      if (strategy.id == data.itemId) {
        // for now, we want to give them the "loading" state
        strategy.loading = true;
        strategies.push(strategy);
      }
    });
  }

  return FavoriteApi.removeFavorite(data)
    .then((response) => {
      store.ui.favorited = false;
      store.ui.favoriteCount -= 1;
      store.ui.loading = false;

      if (strategies) {
        // update the strategies
        strategies.forEach((strategy) => {
          strategy.loading = false;
          strategy.favorited = false;
        });
      }
    })
    .catch((response) => {
      store.ui.loading = false;
      if (strategies) {
        // update the strategies
        strategies.forEach((strategy) => {
          strategy.loading = false;
        });
      }
      setErrorAlert(
        'Sorry, we were unable to unfavorite this strategy. Please try again later.'
      );
    });
};
store.updateSectionExpandToggleCount = () => {
  store.ui.sectionExpandToggleCount = store.ui.sectionExpandToggleCount + 1;
};
export default Store('ContentBrowserStore', store);
