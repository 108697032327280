// @ts-nocheck FIXME
import AbridgeText from '@legacy-components/react/V5/Common/AbridgeText';
import PropTypes from 'prop-types';
import UserPhoto from './../../Common/UserPhoto';

const HighlightedRow = (props) => {
  let toggleAbridge = true;
  if (props.toggle === false) {
    toggleAbridge = false;
  }

  const entryReflection = toggleAbridge ? (
    <AbridgeText
      maxHeight={260}
      isExpandable={true}
      containerClassName="highlights__reflection-copy"
      greyFade={props.greyFade}
    >
      {props.reflection}
    </AbridgeText>
  ) : (
    props.reflection
  );
  // hide gradient vertical line (when displaying in PL dashboard)
  const gradientClass = props.showInPLDash
    ? ''
    : ' highlights__highlight-row--gradient';

  return (
    <div className={`row row--1200 highlights__highlight-row${gradientClass}`}>
      <div className="cute-9-desktop cute-12-phone highlights__highlight-row-entry-col">
        {props.children}
      </div>

      <div className="cute-3-desktop cute-12-phone highlights__highlight-row-reflection-col">
        <div className="hide-desktop show-phone highlights__highlight-row-reflection-mobile-separator"></div>

        <div className="highlights__highlight-row-reflection-text">
          {entryReflection && (
            <div className="tc-v5-left-quotes highlights__highlight-row-reflection-quote-icon"></div>
          )}
          {entryReflection}

          <div className="highlights__highlight-row-reflection-author">
            <div className="highlights__highlight-author-photo-col">
              <UserPhoto
                src={props.author.photo}
                userId={props.author.id}
                firstName={props.author.first_name}
                lastName={props.author.last_name}
                diameter={40}
              />
            </div>
            <div className="highlights__highlight-author-name">
              {props.author.first_name} {props.author.last_name}
            </div>
          </div>
        </div>
      </div>
      {!(props.isFirstRow && props.isLastRow) &&
        !props.isLastRow &&
        !props.showInPLDash && (
          <div className="cute-12-desktop cute-12-phone">
            <div className="highlights__highlight-row-separator"></div>
          </div>
        )}
    </div>
  );
};

HighlightedRow.propTypes = {
  reflection: PropTypes.string,
  author: PropTypes.object,
  type: PropTypes.string,
  contentId: PropTypes.any,
  storyId: PropTypes.any,
  title: PropTypes.string,
  toggle: PropTypes.bool,
  greyFade: PropTypes.bool,
  isFirstRow: PropTypes.bool,
  isLastRow: PropTypes.bool,
  showInPLDash: PropTypes.bool,
};

export default HighlightedRow;
