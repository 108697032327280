// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';
import Slider from './../../Common/Carousel';
import FavoritesCapsule from './FavoritesCapsule';

const Favorites = (props) => {
  // no favorites for default state
  let favoritesContainerNode = (
    <div className="home-v5__no-favorite">
      <div className="home-v5__no-favorite-image">
        <img
          src="/legacy-images/v5/favorites.png"
          alt="Favorite some resources on BetterLesson"
        />
      </div>
      <div className="home-v5__no-favorite-copy">
        <h4 className="v5__heading-5">Go ahead. You can play favorites.</h4>
        <div className="v5__body-copy home-v5__no-favorite-copy">
          <NavigationLink
            url={process.env.REACT_APP_TEACHING_URL + '/search'}
            className="v5__link"
          >
            Explore 20,000+ lessons and strategies{' '}
          </NavigationLink>
          <span className="home-v5__no-favorite-copy-text">
            and save the ones you love to your Favorites.
          </span>
        </div>
      </div>
    </div>
  );

  // layout favorite cards
  // const favoritesCards = [...props.favorites].fill({}, props.favorites.length, 11)
  const favoritesNode = props.favorites.map((favorite) => (
    <FavoritesCapsule
      key={`favorite-${favorite.id}`}
      favorite={favorite}
      pageUserId={props.pageUserId}
    />
  ));

  // if more than 11 add a view all link
  if (props.favorites.length >= 11) {
    favoritesNode.push(
      <NavigationLink
        className="v5__link"
        url={`${process.env.REACT_APP_TEACHING_URL}/browse/favorites/${props.pageUser.id}`}
      >
        <div className="home-v5__favorites-browse-link home-v5__favorites-card">
          <div className="home-v5__favorites-browse-link-text">
            <div className="home-v5__view-all-text">VIEW ALL</div>
            <div className="v5__heading-5">Favorites and Recommendations</div>
          </div>
        </div>
      </NavigationLink>
    );
  }

  // there are favorites initialize the slider
  if (props.favorites.length) {
    favoritesContainerNode = (
      <div className="home-v5__favorites-carousel">
        <Slider slideStep={3} uniqueKey={'profile-favorites'}>
          {favoritesNode}
        </Slider>
      </div>
    );
  }

  return (
    <div className="home-v5__favorites-capsule">
      <h3 className="v5__heading-6">Favorites and Recommendations</h3>
      {favoritesContainerNode}
    </div>
  );
};

export default Favorites;

Favorites.propTypes = {
  favorites: PropTypes.array,
};
