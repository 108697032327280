// @ts-nocheck FIXME
import Request from './../../components/Request';

const HomeApi = {
  getFavorites: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/lab/home/favorites/${data.userId}`,
      JSON.stringify(data)
    );
  },

  revokeGoogleCredentials: function (data) {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/coach/${data.userId}/google_oauth2_revoke`,
      JSON.stringify(data)
    );
  },

  createBLConnectCTAClick: function () {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/user/bl_connect_cta`
    );
  },
};

export default HomeApi;
