// @ts-nocheck FIXME
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import SortableItems from '../Common/SortableItems';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import DomainsDelete from '../Domains/DomainsDelete';
import Utils from './../../../../components/DynamicUtils';
import { Router } from './../../../../components/Router';
import AbsoluteAlert from './../../../Alerts/Absolute';
import LayerLink from './../../../LayerManager/LayerLink';
import GreyOverlayDialogue from './../../../Modals/GreyOverlayDialogue';
import NavigationLink from './../../../NavigationLink';
import CKEditor from './../../Common/CKEditor/CKEditor';
import StickyFooter from './../../Common/StickyFooter';
import StickyLeftNav from './../../Common/StickyLeftNav';
import CuratorNotes from './../Common/CuratorNotes';
import AddExistingStrategyModal from './AddExistingStrategyModal';

const StrategyPack = (props) => {
  let alertNode;
  const tagline = null;
  let title = null;
  let curationNotes = null;
  const publishType = null;
  let strategiesNode = null;
  const strategyPacksNode = null;

  let published = null;
  let modalNode = null;

  const addBtnDisabledClass = props.strategyPack.new ? 'disabled' : '';

  if (props.ui.confirmPublishUnpublishModal.opened) {
    modalNode = (
      <ConfirmDeleteModal
        {...props.ui.confirmPublishUnpublishModal}
        onDelete={props.updateStrategyPackPublish}
      />
    );
  } else if (props.ui.addExistingStrategyModal.opened) {
    modalNode = (
      <AddExistingStrategyModal
        {...props.ui.addExistingStrategyModal}
        submit={props.addExistingStrategyToPack}
      />
    );
  }

  // mark page that it needs saving
  const dirtyPage = () => {
    WindowHelpers.navigationConfirmation(true);
  };

  const handleShowMenu = (e) => {
    e.preventDefault();
    props.toggleAddStrategyMenu();
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    props.ui.addExistingStrategyModal.close();
  };

  const handleAddExistingStrategy = (e) => {
    e.preventDefault();
    props.ui.addExistingStrategyModal.open();
  };

  const draftIndex = Utils.getItemIndexFromArrayOfObjects(
    props.strategyPackPublishOptions,
    'name',
    'draft'
  );
  const publishIndex = Utils.getItemIndexFromArrayOfObjects(
    props.strategyPackPublishOptions,
    'name',
    'published'
  );

  if (!Utils.isEmpty(props.ui.alert)) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const sortCallbackStrategies = (elementIds) => {
    dirtyPage();
    const data = { pack: props.strategyPack.id, strategies: elementIds };
    props.sortStrategyPackStrategies(data);
  };

  const taglineCount = (e) => {
    e.preventDefault();
    props.updateTaglineLength(tagline.value.length);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    Router.route('/bl/curation/domain/' + props.focusArea.id + '/edit');
  };

  const handleStrategyPackSwitch = (e) => {
    const strategyPackId = e.target.dataset.strategypack;
    const publishStatusId = e.target.checked
      ? e.target.dataset.publish
      : e.target.dataset.draft;
    const data = {
      id: parseInt(strategyPackId),
      publish_status_id: parseInt(publishStatusId),
      customBodyText: 'Are you sure you want to unpublish this strategy pack?',
      label: 'Unpublish',
    };

    if (!e.target.checked) {
      props.ui.confirmPublishUnpublishModal.open(data);
    } else {
      props.updateStrategyPackPublish(data);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      title: title.value,
      description:
        CKEDITOR.instances[
          'outcome-story__text-wysiwyg-descriptionTextEntry-0'
        ].getData(),
      publishStatusId: props.strategyPack.publish_status_id,
      strategyPackId: props.strategyPack.id,
      curationNotes: curationNotes
        ? curationNotes.value
        : props.strategyPack.curation_notes,
      focusAreaId: props.strategyPack.focus_area_id,
    };
    props.saveStrategyPack(data);
  };

  if (props.strategyPack.publish_status_id) {
    const publishIndex = Utils.getItemIndexFromArrayOfObjects(
      props.strategyPackPublishOptions,
      'id',
      props.strategyPack.publish_status_id
    );
    if (props.strategyPackPublishOptions[publishIndex].name == 'published') {
      published = true;
    }
  }
  if (props.strategyPack && props.strategyPack.strategies) {
    strategiesNode = props.strategyPack.strategies.map((strategy) => {
      let visibility = null;
      if ('publish_status_id' in strategy) {
        const visibilityIndex = Utils.getItemIndexFromArrayOfObjects(
          props.publishOptions,
          'id',
          strategy.publish_status_id
        );
        if (visibilityIndex != -1) {
          const visibilityClass =
            props.publishOptions[visibilityIndex].name == 'draft'
              ? ' curation__status--grey'
              : '';
          visibility = (
            <div className={'curation__status' + visibilityClass}>
              {props.publishOptions[visibilityIndex].label}
            </div>
          );
        }
      }
      let status = null;
      if ('curation_status_id' in strategy) {
        const curationIndex = Utils.getItemIndexFromArrayOfObjects(
          props.curationOptions,
          'id',
          strategy.curation_status_id
        );
        if (curationIndex != -1) {
          let statusClass = '';
          switch (props.curationOptions[curationIndex].name) {
            case 'unstarted':
              statusClass = ' curation__status--grey';
              break;
            case 'needs_review':
              statusClass = ' curation__status--orange';
              break;
            case 'complete':
              statusClass = ' curation__status--green';
              break;
          }
          status = (
            <div className={'curation__status' + statusClass}>
              {props.curationOptions[curationIndex].label}
            </div>
          );
        }
      }

      // const deleteNode = props.canDelete ? (
      const deleteNode = (
        <LayerLink
          params={{
            selectedStrategyId: strategy.id,
            selectedStrategyPackId: props.strategyPack.id,
            handleDelete: props.handleDeleteStrategyFromPack,
            title: strategy.title + ' from the strategy pack',
            className: 'portfolio',
          }}
          layer="new"
          className="tc__link icon tc-delete"
          title="Delete {strategy.title}"
          component={DomainsDelete}
          wrapper={GreyOverlayDialogue}
        />
      );
      return (
        <div
          key={'strategy-' + strategy.id}
          className="ppd-v3-curation__row clearfix"
          data-element-type={'domain'}
          data-item={strategy.id}
        >
          <div className="cute-6-desktop">
            <div className="domains__move-handle">
              <div className="tc-dots-8 domains__drag-icon domains__drag-icon--nav ppd-v3-curation__drag-icon ppd-v3-curation__drag-icon--domain" />
            </div>
            <div className="ppd-v3-curation__row-copy">{strategy.title}</div>
          </div>
          <div className="ppd-v3-curation__switch-container cute-2-desktop">
            {status}
          </div>
          <div className="ppd-v3-curation__switch-container cute-2-desktop">
            {visibility}
          </div>
          <div className="ppd-v3-curation__switch-container ppd-v3-curation__options-container cute-2-desktop">
            <div className="row">
              <div className="ppd-v3-curation__option cute-6-desktop">
                <NavigationLink
                  url={
                    '/bl/curation/domain/' +
                    props.strategyPack.focus_area_id +
                    '/pack/' +
                    props.strategyPack.id +
                    '/strategy/' +
                    strategy.id +
                    '/edit'
                  }
                  className="tc__link tc-pencil"
                />
              </div>
              <div className="ppd-v3-curation__option cute-6-desktop">
                {deleteNode}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  let addStrategyMenuNode = null;
  if (props.ui.addStrategyShowMenu) {
    addStrategyMenuNode = (
      <div>
        <div
          onClick={handleCloseModal}
          className="clip-modal modal__page-overlay modal__page-overlay--add ppd-v3-curation-modal__page-overlay curation__overlay"
        />

        <div className="curation__tooltip tc__body-copy curation__top">
          <div
            className="tc__link curation__tooltip-row"
            onClick={handleAddExistingStrategy}
          >
            Add Existing
          </div>
          <NavigationLink
            className="tc__link curation__tooltip-row"
            url={
              '/bl/curation/domain/' +
              props.strategyPack.focus_area_id +
              '/pack/' +
              props.strategyPack.id +
              '/strategy/add'
            }
          >
            Add New
          </NavigationLink>
        </div>
      </div>
    );
  }

  const SortableSectionStrategies = SortableItems(
    <div
      id="dragContainerStrategies"
      className="domains__sidebar-nav ppd-v3-curation__table ppd-v3-curation__table--domains row"
    >
      {strategiesNode}
    </div>,
    '#dragContainerStrategies',
    'window',
    sortCallbackStrategies
  );

  return (
    <div className="tc__page curation">
      <Header page="Curation" {...props} />
      {alertNode}
      {modalNode}
      <div className="row row--1200 tc__body-copy">
        <div className="cute-12-desktop ppd-v3-curation__breadcrumbs">
          <NavigationLink url="/bl/curation/" className="tc__link">
            Content Curation
          </NavigationLink>
          {'>'}
          <NavigationLink url="/bl/curation/domains" className="tc__link">
            Domains
          </NavigationLink>
          {'>'}
          <NavigationLink
            url={'/bl/curation/domain/' + props.focusArea.id + '/edit'}
            className="tc__link"
          >
            {props.focusArea.title}
          </NavigationLink>
          {'>'} Strategy Pack
        </div>
      </div>

      <div className="row row--1200">
        <StickyLeftNav
          mainPageClass="curation__main-col"
          sectionNames={['Introduction', 'Strategies', 'Curator Notes']}
        />

        <div className="cute-8-desktop cute-2-desktop-offset left cute-12-phone cute-0-phone-offset curation__main-col">
          <form className="tc__body-copy ppd-v3-curation__edit-form">
            <div id="section-0" className="row">
              <div className="cute-12-desktop">
                <div className="tc__headline-3">Strategy Pack</div>
                <div>In {props.focusArea.title}</div>
              </div>
            </div>

            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">Title</div>
                <input
                  type="text"
                  ref={(ref) => (title = ref)}
                  defaultValue={props.strategyPack.title}
                  className={'tc__input ppd-v3-curation__input'}
                />
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="tc__modal-add-row">
                  <input
                    type="checkbox"
                    id="published"
                    data-strategypack={props.strategyPack.id}
                    data-draft={props.strategyPackPublishOptions[draftIndex].id}
                    data-publish={
                      props.strategyPackPublishOptions[publishIndex].id
                    }
                    onChange={handleStrategyPackSwitch}
                    checked={
                      !!(
                        props.strategyPack.publish_status_id &&
                        props.strategyPackPublishOptions[publishIndex].id ==
                          props.strategyPack.publish_status_id
                      )
                    }
                    className="ppd-v3-curation__switch-input"
                  />
                  <label
                    htmlFor="published"
                    className="ppd-v3-curation__inline-label ppd-v3-curation__switch-label"
                  >
                    Published
                    <span className="ppd-v3-curation__switch ppd-v3-curation__switch--has-label">
                      <span className="ppd-v3-curation__switch-btn" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">Description</div>
                <div className="outcome-story__text-wysiwyg-container">
                  <CKEditor
                    id="descriptionTextEntry"
                    increment={0}
                    innerText={props.strategyPack.description}
                    className={'outcome-story__text-wysiwyg'}
                    configs="/legacy-libs/configs/bl-cke-config-curation-v4.js"
                  />
                </div>
              </div>
            </div>
            <div id="section-1" className="cd-strategy__details row">
              <span className="tc__headline-3">Strategies</span>
              <span className={'right'}>
                <div
                  className={
                    'bl4__btn bl4__btn--blue bl4__btn--small ' +
                    addBtnDisabledClass
                  }
                  onClick={handleShowMenu}
                >
                  Add
                  {addStrategyMenuNode}
                </div>
              </span>
              <div className="cute-12-desktop">
                <div className="row ppd-v3-curation__domains-header">
                  <div className="ppd-v3-curation__domains-header-item cute-6-desktop">
                    Name
                  </div>
                  <div className="ppd-v3-curation__domains-header-item cute-2-desktop">
                    Status
                  </div>
                  <div className="ppd-v3-curation__domains-header-item cute-2-desktop">
                    Visibility
                  </div>
                  <div className="ppd-v3-curation__domains-header-item cute-2-desktop" />
                </div>
                <SortableSectionStrategies />
              </div>
              <CuratorNotes
                sectionId={2}
                inputRef={(el) => (curationNotes = el)}
                notes={props.strategyPack.curation_notes}
                showNotes={props.ui.addCuratorNotes}
                toggleAddCuratorNotes={props.toggleAddCuratorNotes}
                onBlur={dirtyPage}
              />
            </div>
          </form>
        </div>
      </div>

      <StickyFooter
        submit={handleSave}
        hasCancelLink={true}
        cancel={handleCancel}
        cancelText="Close"
      />
    </div>
  );
};

StrategyPack.PropTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default StrategyPack;
