// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ParticipantRow from './ParticipantRow';

const ParticipantsTable = (props) => {
  const schoolOrCohort = 'Cohort';

  // Sort order indication arrow
  let directionalArrow = (
    <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
  );
  if (props.ui.coachHome.sortDirection !== 'ascending') {
    directionalArrow = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  // Handle sorting indication
  let lastNameSortIndicator;
  let cohortSortIndicator;
  let activitySortIndicator;
  let highlightsSortIndicator;
  let nextMeetingSortIndicator;
  switch (props.ui.coachHome.sortColumn) {
    case 'last_name':
      lastNameSortIndicator = directionalArrow;
      break;
    case schoolOrCohort.toLowerCase():
      cohortSortIndicator = directionalArrow;
      break;
    case 'activity':
      activitySortIndicator = directionalArrow;
      break;
    case 'highlights':
      highlightsSortIndicator = directionalArrow;
      break;
    case 'next_meeting':
      nextMeetingSortIndicator = directionalArrow;
      break;
  }

  const emptyStateText =
    'It sure is lonely here. Don’t worry, your matches will arrive any day now.';

  let coachingEngagementsNode = (
    <div className="admin-v5__coach-table--empty-state">{emptyStateText}</div>
  );

  const escapeSpecialChars = (term) => {
    return term.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&').toLowerCase();
  };

  // narrow the Next Meeting col
  const nextMeetingColClass = 'grid-ten__col-2';

  let highlightsColNode;
  highlightsColNode = (
    <div
      className="admin-v5__coach-table__header-col grid-ten__col-1"
      onClick={() => {
        props.sortByCoachingEngagements('highlights');
      }}
    >
      <span
        className={
          'ppd-3-coach-table__header-caption ppd-3-coach-table__header-caption--highlights left clickable' +
          (highlightsSortIndicator ? ' active' : '')
        }
      >
        Highlights
      </span>
      {highlightsSortIndicator}
    </div>
  );

  // no participants render nothing
  if (props.coachingEngagements && props.coachingEngagements.length) {
    const filterTerm =
      props.filter && new RegExp(escapeSpecialChars(props.filter));
    // create participants rows
    coachingEngagementsNode = props.coachingEngagements.map(
      (coachingEngagement) => {
        // this logic effectively filters the list of participants.
        // I feel this is clearer like this than in a .filter()
        const participant = coachingEngagement.participant;
        if (
          !props.filter ||
          (filterTerm &&
            (filterTerm.test(participant.first_name.toLowerCase()) ||
              filterTerm.test(participant.last_name.toLowerCase()) ||
              filterTerm.test(participant.email.toLowerCase()) ||
              filterTerm.test(
                `${participant.first_name.toLowerCase()} ${participant.last_name.toLowerCase()}`
              )))
        ) {
          return (
            <ParticipantRow
              setAlert={props.setAlert}
              key={participant.id}
              user={props.user}
              pageUser={props.pageUser}
              coachingEngagement={coachingEngagement}
              artifactModal={props.ui.artifactModal}
              openCohortVision={props.openCohortVision}
              setSuccessAlert={props.setSuccessAlert}
              resendInvitationEmail={props.resendInvitationEmail}
            />
          );
        }
      }
    );
  }

  return (
    <div className="row admin-v5__coach-table">
      <div className="admin-v5__coach-table__header grid-ten__row">
        <div
          className={'admin-v5__coach-table__header-col grid-ten__col-3'}
          onClick={() => {
            props.sortByCoachingEngagements('last_name');
          }}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (lastNameSortIndicator ? ' active' : '')
            }
          >
            Name
          </span>
          {lastNameSortIndicator}
        </div>

        <div
          className="admin-v5__coach-table__header-col grid-ten__col-3"
          onClick={() => {
            props.sortByCoachingEngagements(schoolOrCohort.toLowerCase());
          }}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (cohortSortIndicator ? ' active' : '')
            }
          >
            {schoolOrCohort}
          </span>
          {cohortSortIndicator}
        </div>

        <div
          className="admin-v5__coach-table__header-col grid-ten__col-2"
          onClick={() => {
            props.sortByCoachingEngagements('activity');
          }}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (activitySortIndicator ? ' active' : '')
            }
          >
            Activity
          </span>
          {activitySortIndicator}
        </div>
        {highlightsColNode}
        <div
          className={`admin-v5__coach-table__header-col ${nextMeetingColClass}`}
          onClick={() => {
            props.sortByCoachingEngagements('next_meeting');
          }}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (nextMeetingSortIndicator ? ' active' : '')
            }
          >
            Next Meeting
          </span>
          {nextMeetingSortIndicator}
        </div>
      </div>
      {coachingEngagementsNode}
    </div>
  );
};

ParticipantsTable.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  coachingEngagements: PropTypes.array.isRequired,
};

export default ParticipantsTable;
