// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../components/ArrayHelpers';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';
import ArtifactOverlay from './../Common/ArtifactOverlay/ArtifactOverlay';
import ScrollToElementById from './../Common/ScrollToElementById';
import UserPhoto from './../Common/UserPhoto';
import ParticipantDetails from './modules/ParticipantDetails';
import PortfolioStoryCapsule from './modules/PortfolioStoryCapsule';
import Sidebar from './modules/Sidebar';

const Profile = (props) => {
  // only the owner and coaches/admins can edit data
  // (for now we exclude District Coaches since on this page they can only edit vision)
  // const userCanEditData = Auth.hasCoachAccessMinusDC(props.user) ||
  //     props.user.id == props.pageUser.id;
  const userCanEditData = false;
  const mainColClass = userCanEditData ? ' bl4-profile__main--editable' : '';

  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // settings url
  const settingsUrl = '/account/' + props.pageUser.id + '?from=profile';

  // dissable links when page action is in progress
  const disabledActionClass = props.ui.activeAction ? ' disabled' : '';

  // set up user photo or default icon
  let photoNode = (
    <UserPhoto src={props.pageUser.photo} diameter={120} hasBorder={true} />
  );
  // if there is no user photo and the current user has editing permissions, link to Settings page
  if (userCanEditData && !props.pageUser.photo) {
    photoNode = (
      <div>
        <NavigationLink
          className="bl4-profile__add-user-image"
          url={settingsUrl}
        >
          <UserPhoto diameter={120} hasBorder={true} />
          <div className="home__user-img-overlay">Edit</div>
        </NavigationLink>
      </div>
    );
  }

  // if the user has an insitutution show it
  let schoolNode = null;
  if (props.pageUser.institution) {
    schoolNode = (
      <div className="bl4-profile__school">
        {props.pageUser.institution.name}
      </div>
    );
  }

  // setup user details
  const editLinkNode = (
    <NavigationLink
      url={settingsUrl}
      className={'tc__link' + disabledActionClass}
    >
      Add
    </NavigationLink>
  );
  let occupationNode,
    experienceNode,
    gradesNode,
    subjectsNode = null;

  if (userCanEditData) {
    occupationNode = (
      <div className="bl4-profile__detail">
        <span className="bl4-profile__detail-declaration">Role: </span>
        {editLinkNode}
      </div>
    );
    experienceNode = (
      <div className="bl4-profile__detail">
        <span className="bl4-profile__detail-declaration">
          Years of Experience:{' '}
        </span>
        {editLinkNode}
      </div>
    );
    gradesNode = (
      <div className="bl4-profile__detail">
        <span className="bl4-profile__detail-declaration">Grades: </span>
        {editLinkNode}
      </div>
    );
    subjectsNode = (
      <div className="bl4-profile__detail">
        <span className="bl4-profile__detail-declaration">Subjects: </span>
        {editLinkNode}
      </div>
    );
  }
  if (props.pageUser.occupation && props.pageUser.occupation.name) {
    occupationNode = (
      <div className="bl4-profile__detail">
        <span className="bl4-profile__detail-declaration">Role: </span>
        <span>{props.pageUser.occupation.name}</span>
      </div>
    );
  }
  if (props.pageUser.experience) {
    experienceNode = (
      <div className="bl4-profile__detail">
        <span className="bl4-profile__detail-declaration">
          Years of Experience:{' '}
        </span>
        <span>{props.pageUser.experience}</span>
      </div>
    );
  }
  if (props.pageUser.grades.length > 0) {
    const grades = props.pageUser.grades.map((grade, i) => {
      const seperator = i + 1 < props.pageUser.grades.length ? ',' : null;
      return (
        <span key={'grade-' + i}>
          {grade}
          {seperator}
        </span>
      );
    });
    gradesNode = (
      <div className="bl4-profile__detail">
        <span className="bl4-profile__detail-declaration">Grades: </span>
        {grades}
      </div>
    );
  }
  if (props.pageUser.subjects.length > 0) {
    const subjects = props.pageUser.subjects.map((subject, i) => {
      const seperator = i + 1 < props.pageUser.subjects.length ? ',' : null;
      return (
        <span key={'subject-' + i}>
          {subject}
          {seperator}
        </span>
      );
    });
    // all subjects are set
    if (subjects.length >= 12) {
      subjects = [<span key={'subject-all'}>All</span>];
    }
    subjectsNode = (
      <div className="bl4-profile__detail">
        <span className="bl4-profile__detail-declaration">Subjects: </span>
        {subjects}
      </div>
    );
  }

  // on mobile, we show the list of portfolios under the user metadata
  let portfolioListNode = null;
  if (
    props.pageUser.archivedPortfolios &&
    props.pageUser.archivedPortfolios.length
  ) {
    const portfolioNodes = props.pageUser.archivedPortfolios.map(
      (portfolio) => {
        let portfolioEditableNode = null;
        if (userCanEditData) {
          portfolioEditableNode = (
            <a
              target="_blank"
              href={
                '/coaching/lab/participant/' +
                portfolio.participantId +
                '/portfolio'
              }
              className="tc__link"
              rel="noreferrer"
            >
              <span className="tc-pencil bl4__mobile-portfolio-edit-icon"></span>
            </a>
          );
        }

        return (
          <div
            className="bl4__mobile-portfolio"
            key={'portfolio-' + portfolio.uuid}
          >
            <a
              target="_blank"
              href={'/coaching/lab/participant/portfolio/' + portfolio.uuid}
              className="tc__link"
              rel="noreferrer"
            >
              {portfolio.label || '(no label)'}
            </a>
            {portfolioEditableNode}
          </div>
        );
      }
    );
    portfolioListNode = (
      <div className="bl4__mobile-portfolios hide-desktop show-phone">
        {portfolioNodes}
      </div>
    );
  }

  // edit settings button
  let editBtnNode = null;
  if (userCanEditData) {
    editBtnNode = (
      <NavigationLink url={settingsUrl}>
        <div className={'bl4-profile__pd--edit tc__link' + disabledActionClass}>
          <div className="tc-pencil"></div>
          <div>Edit</div>
        </div>
      </NavigationLink>
    );
  }

  // show a modal if one is opened
  let modalNode = null;
  if (props.ui.artifactModal.opened) {
    modalNode = (
      <ArtifactOverlay
        {...props.ui.artifactModal}
        analyticsLabel="from_profile"
      />
    );
  } else if (props.ui.portfolioArtifactModal.opened) {
    modalNode = (
      <ArtifactOverlay
        {...props.ui.portfolioArtifactModal}
        analyticsCategory={
          props.ui.portfolioArtifactModal.data.entryType == 'evidence'
            ? 'evidence'
            : null
        }
        analyticsLabel="from_profile"
      />
    );
  }

  let participantDetailsNode = null;
  if (props.pageParticipant) {
    participantDetailsNode = <ParticipantDetails {...props} />;
  }

  // if the user has stories with data to share, show the 2018-2019 portfolio section
  let portfolioStoriesNode;
  if (ArrayHelpers.hasElements(props.stories)) {
    portfolioStoriesNode = (
      <div
        className="bl4-profile__portfolio"
        id={'bl4-profile__portfolio-2018'}
      >
        <h2 className="bl4-profile__portfolio-headline">
          My 2018-2019 Portfolio
        </h2>
        {props.stories.map((story) => {
          // if the story has a specific starting tab, pass that in
          if (
            props.selectedPortfolioStory &&
            props.selectedPortfolioStory.id == story.id
          ) {
            story.selectedTab = props.selectedPortfolioStory.section;
          }
          return (
            <PortfolioStoryCapsule
              key={'story-' + story.id}
              {...story}
              portfolioArtifactModal={props.ui.portfolioArtifactModal}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="tc__page profile">
      <div className="bl4__page-background"></div>
      <Header page="Profile" {...props} />
      {alertNode}
      {modalNode}

      <div className="row row--1200">
        <div className="bl4-profile__sidebar-container cute-3-desktop show-desktop hide-phone">
          <Sidebar
            userCanEditData={userCanEditData}
            pageUser={props.pageUser}
            pageParticipant={props.pageParticipant}
            stories={props.stories}
            sidebarStories={props.sidebarStories}
            scrollToProfileSection={props.scrollToProfileSection}
            toggleEditSidebarStoriesSortOrder={
              props.toggleEditSidebarStoriesSortOrder
            }
            saveSidebarStoryReorder={props.saveSidebarStoryReorder}
            cancelSidebarStoryReorder={props.cancelSidebarStoryReorder}
            storySidebarReordering={props.ui.storySidebarReordering}
            dropSidebarStoryReorder={props.dropSidebarStoryReorder}
            sortIndicatorActive={props.ui.sortIndicatorActive}
          />
        </div>
        <div
          className={
            'bl4-profile__main cute-9-desktop cute-12-phone' + mainColClass
          }
        >
          <div
            id="bl4-profile__about"
            className="bl4__capsule bl4__capsule--header-graphic bl4__capsule--profile-details"
          >
            {photoNode}
            <h1 className="bl4-profile__user-name tc__headline-4">
              {props.pageUser.first_name} {props.pageUser.last_name}
            </h1>
            {schoolNode}
            <div className="bl4-profile__details">
              <span className="bl4-profile__details-mobile-column">
                {occupationNode}
                {experienceNode}
              </span>
              <span className="bl4-profile__details-mobile-column">
                {gradesNode}
                {subjectsNode}
              </span>
            </div>
            {portfolioListNode}
            {editBtnNode}
          </div>
          {participantDetailsNode && (
            <div className="bl4-profile__pd-seperator"></div>
          )}
          {participantDetailsNode}

          {portfolioStoriesNode}
        </div>
      </div>

      {props.selectedPortfolioStory ? (
        <ScrollToElementById id={props.selectedPortfolioStory.elementId} />
      ) : null}
    </div>
  );
};

Profile.propTypes = {
  ui: PropTypes.object.isRequired,
  pageUser: PropTypes.object.isRequired,
  pageParticipant: PropTypes.object,
};

export default Profile;
