// @ts-nocheck FIXME
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import React from 'react';

const EventGroupForm = (props) => {
  const [sessionId, setSessionId] = React.useState(null);

  const groupOptions = [];
  const groupNums = new Set();
  props.event.current_sessions.forEach((session) => {
    if (!groupNums.has(session.group_num)) {
      groupNums.add(session.group_num);
      groupOptions.push(
        <option key={session.id} value={session.id}>
          {session.group_name}
        </option>
      );
    }
  });

  // If the user hasn't selected a group id yet then display "Select Group" in the select field
  if (!sessionId) {
    groupOptions.unshift(
      <option key={0} value={null}>
        Select Group
      </option>
    );
  }

  const selectGroup = (e) => {
    props.setIsLoading(true);
    e.preventDefault();
    props.selectGroup(sessionId);
  };

  const btnDisabledClass = props.ui.loading || !sessionId ? true : false;

  const btnProps = {
    disabled: btnDisabledClass,
  };

  return (
    <div className="registration-v5-event__register">
      <div className="registration-v5-event__register-header v5__heading-3">
        Select Your Group
      </div>
      <div className="registration-v5-event__register-body v5__body-copy">
        <div className="registration-v5-event__register-for row">
          <div className="cute-12-desktop v5__body-copy">
            To personalize your experience, we split this event into multiple
            groups. Let us know what group you are in to view event materials.
          </div>
          <div className="cute-12-desktop v5__body-copy">
            Not sure what group you&apos;re in? Ask your event facilitator.
          </div>

          <div className="cute-12-desktop v5__body-copy">Group</div>
          <div className="cute-12-desktop">
            <select
              className="v5__body-copy event-v5__registration-select tc__select"
              value={sessionId}
              onChange={(e) => setSessionId(e.target.value)}
            >
              {groupOptions}
            </select>
          </div>
        </div>
        <div className="event-v5__group-controls">
          <button {...btnProps} className={'v5__btn'} onClick={selectGroup}>
            View event materials
            <Spinner
              className="admin__littlespinner"
              loading={props.ui.loading}
            />
          </button>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default EventGroupForm;

EventGroupForm.propTypes = {
  event: PropTypes.shape({
    current_sessions: PropTypes.array,
  }),
  selectGroup: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    loading: PropTypes.bool,
  }),
};
