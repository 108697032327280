// @ts-nocheck FIXME
import propTypes from 'prop-types';

const CapacityQuarterBlock = (props) => {
  /**
   * calculate which color to apply from columnColors
   * @param {int} currCapacity currently filled spots in the given time slot
   * @returns {int} capacity percentage rounded to the nearest 10 [0-100]
   */
  const getPercentage = (currCapacity) => {
    if (!props.capacity) {
      return 0;
    }
    const percentage = Math.floor((currCapacity / props.capacity) * 100);
    return percentage >= 100 ? 100 : Math.floor(percentage / 10) * 10;
  };

  const percentage = getPercentage(props.count);
  const colorClass = `events-cal__quarter-block-${percentage}pct`;
  const darkBorder = percentage >= 30 ? '--dark' : '';

  // if this block is being clicked, controlling the arrow
  const isOpen =
    props.showEventDetail.date === props.date &&
    props.showEventDetail.hour === props.hour &&
    props.showEventDetail.quarter === props.quarter &&
    props.count > 0;

  // make the block non-clickable if this quarter has 0 sessions
  const clickHandler =
    props.count > 0
      ? {
          onClick: () =>
            props.handleShowEventDetail(props.date, props.hour, props.quarter),
        }
      : null;
  const clickableCursorClass =
    props.count > 0 ? 'events-cal__quarter-block--clickable' : '';
  return (
    <div
      className={`events-cal__quarter-block events-cal__quarter-block-border${darkBorder} ${colorClass} ${clickableCursorClass}`}
      {...clickHandler}
      key={`${props.date}-${props.hour}-${props.quarter}`}
      data-testid={`block-${props.date}-${props.hour}-${props.quarter}`}
    >
      {props.count > 0 ? props.count : ''}
      {isOpen && <div className="events-cal__indicator-triangle"></div>}
    </div>
  );
};

CapacityQuarterBlock.propTypes = {
  date: propTypes.string.isRequired,
  hour: propTypes.number.isRequired,
  quarter: propTypes.number.isRequired,
  count: propTypes.number.isRequired,
  showEventDetail: propTypes.object.isRequired,
  handleShowEventDetail: propTypes.func.isRequired,
  capacity: propTypes.number,
};

export default CapacityQuarterBlock;
