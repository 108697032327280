// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Utils from './../../../../../components/DynamicUtils';

class HeaderDropdownContainer extends React.Component {
  constructor(props) {
    super(props);
    this.mq = window.matchMedia('(max-width: 48em)');
    this.overflowBehavior = this.overflowBehavior.bind(this);
  }

  componentDidMount() {
    // update viewport when changing from desktop to mobile, and vice versa
    this.mq.addListener(this.setupViewport);

    // if mobile
    if (this.mq.matches) {
      // account for any initial overflow
      this.overflowBehavior();
      // handle resize, such as rotations, and overflow that might happen
      window.addEventListener('resize', this.overflowBehavior);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.mq.matches &&
      this.props.dropDownVisible != prevProps.dropDownVisible
    ) {
      this.overflowBehavior();
    }
  }

  componentWillUnmount() {
    // remove event listner from window resize event
    window.removeEventListener('resize', this.overflowBehavior);
    this.mq.removeListener(this.setupViewport);
  }

  setupViewport() {
    if (this.matches) {
      // changed to mobile: add mobile behavior to resize
      window.addEventListener('resize', this.overflowBehavior);
      Utils.addClass(document.body, 'no-scroll');
    } else {
      // changed to desktop: remove mobile behavior from resize
      window.removeEventListener('resize', this.overflowBehavior);
      Utils.removeClass(document.body, 'no-scroll');
    }
  }

  overflowBehavior() {
    if (!document) {
      return;
    }

    // get the height of elements to decide if overflow is necessary
    const windowHeight = window.innerHeight;
    const overlayHeight = document.getElementById(
      'header__dropdown--mobile'
    ).offsetHeight;
    const headerHeight =
      document.getElementById('tc-mobile-header').offsetHeight;

    // if the overflow is necessary update the state as such
    const needsOverflow =
      overlayHeight > windowHeight - headerHeight ? true : false;
    this.props.updateDropdownOverflow(needsOverflow);

    return;
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

HeaderDropdownContainer.propTypes = {
  dropDownVisible: PropTypes.bool,
  updateDropdownOverflow: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

export default HeaderDropdownContainer;
