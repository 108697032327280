// @ts-nocheck FIXME
import Request from './../../components/Request';

const CurationApi = {
  editFocusArea: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/focus_area/' +
        data.focusAreaId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addExistingStrategyToFocusArea: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/focus_area/' +
        data.focusAreaId +
        '/add-strategy',
      JSON.stringify(data)
    );
  },

  addExistingStrategyToStrategyPack: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/focus_area/' +
        data.focusAreaId +
        '/strategy_pack/' +
        data.packId +
        '/add-strategy',
      JSON.stringify(data)
    );
  },

  addStrategy: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/curation/strategy/add',
      JSON.stringify(data)
    );
  },

  editStrategy: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/' +
        data.strategyId +
        '/edit',
      JSON.stringify(data)
    );
  },

  deleteStrategy: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/' +
        data.strategyId +
        '/delete',
      JSON.stringify(data)
    );
  },

  editStrategyPack: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategypack/' +
        data.strategyPackId +
        '/edit',
      JSON.stringify(data)
    );
  },

  addSection: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/' +
        data.strategyId +
        '/strategy-section/add',
      JSON.stringify(data)
    );
  },

  editSection: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy-section/' +
        data.sectionId +
        '/edit',
      JSON.stringify(data)
    );
  },

  deleteSection: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy-section/' +
        data.sectionId +
        '/delete',
      JSON.stringify(data)
    );
  },

  createHeroBeforeUpload: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/' +
        data.tcStrategyId +
        '/file/create_before_upload',
      JSON.stringify(data)
    );
  },

  saveHeroAfterUpload: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/' +
        data.tcStrategyId +
        '/file/save_after_upload',
      JSON.stringify(data)
    );
  },

  deleteHero: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/' +
        data.tcStrategyId +
        '/file/delete',
      JSON.stringify(data)
    );
  },

  createResourceBeforeUpload: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy-section/' +
        data.sectionId +
        '/file/create_before_upload',
      JSON.stringify(data)
    );
  },

  saveResourceAfterUpload: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy-section/' +
        data.sectionId +
        '/file/save_after_upload',
      JSON.stringify(data)
    );
  },

  saveUpdatedPreviewAfterUpload: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy-section/resource/' +
        data.resourceId +
        '/update_preview',
      JSON.stringify(data)
    );
  },

  revertResourcePreview: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy-section/resource/' +
        data.resourceId +
        '/revert_preview',
      JSON.stringify(data)
    );
  },

  addLinkResource: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/section/' +
        data.sectionId +
        '/link/add',
      JSON.stringify(data)
    );
  },

  deleteResource: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/section/resource/' +
        data.resourceId +
        '/delete',
      JSON.stringify(data)
    );
  },

  getCreatorsSearch: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/curation/users/search',
      JSON.stringify(data)
    );
  },

  searchStrategies: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/curation/strategies/search',
      JSON.stringify(data)
    );
  },

  getSectionResources: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/section/' +
        data.sectionId +
        '/resources',
      JSON.stringify(data)
    );
  },

  getSections: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/curation/strategy/' +
        data.strategyId +
        '/sections',
      JSON.stringify(data)
    );
  },
};

export default CurationApi;
