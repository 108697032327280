// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { Router } from './../../../../components/Router';
import NavigationLink from './../../../NavigationLink';
import UserPhoto from './../../Common/UserPhoto';

const Favorites = (props) => {
  // by default, if no favorites, link to search
  const handleAddFavoriteClick = () => {
    let searchUrl = process.env.REACT_APP_TEACHING_URL + '/search';
    // if the current user is looking at another user's favorites,
    // clicking on Search will limit the results to match what the owning user can see
    if (props.user.id !== props.pageUser.id) {
      searchUrl += `/${props.pageUser.id}`;
    }
    Router.route(`${searchUrl}?from=participant_dashboard`);
  };

  let favoritesPageLinkNode;
  let favoriteNodes = (
    <div className="home__favorites-capsule-row">
      <div className="tc-v4-heart-filled home__favorites-capsule-row-icon home__favorites-capsule-row-icon--empty"></div>
      <div className="home__favorites-capsule-row-data">
        <div className="home__favorites-capsule-row-title">
          You don&apos;t have any favorites yet. Search for{' '}
          <a className="tc__link" onClick={handleAddFavoriteClick}>
            Lessons and Strategies
          </a>
        </div>
      </div>
    </div>
  );
  // if there are favorites
  if (props.favorites && props.favorites.length) {
    favoriteNodes = props.favorites.map((fav, i) => {
      // show up to 5 of them
      if (i >= 5) {
        return null;
      }
      // some row contents depend on type of element favorited
      let iconClass = '';
      let titleText = '';
      let titleLink = null;
      let textNode = null;
      if (fav.type === 'strategy') {
        // icon depends on strategy type
        iconClass =
          fav.strategy.strategy_type === 'tech tool'
            ? 'tc-v4-laptop'
            : 'tc-v4-strategy';
        iconClass += ' strategy';
        titleText = fav.strategy.title;
        titleLink =
          process.env.REACT_APP_TEACHING_URL + '/strategy/' + fav.strategy.id;
        if (fav.strategy.seo_url) {
          titleLink += '/' + fav.strategy.seo_url;
        }
        titleLink += '?from=home_favorites_strategy_name';
      } else if (fav.type === 'lesson') {
        // icon depends on lesson subject
        switch (fav.lesson.subject) {
          case 'Math':
            iconClass = 'tc-v4-math';
            break;
          case 'Science':
            iconClass = 'tc-v4-science';
            break;
          case 'English / Language Arts':
            iconClass = 'tc-v4-ela';
            break;
          default:
            iconClass = 'tc-v4-ela';
        }
        iconClass += ' lesson';
        titleText = fav.lesson.name;
        titleLink =
          process.env.REACT_APP_TEACHING_URL + '/lesson/' + fav.lesson.id;
        if (fav.lesson.seo_url) {
          titleLink += '/' + fav.lesson.seo_url;
        }
        titleLink += '?from=home_favorites_lesson_name';
        textNode = (
          <div className="home__favorites-capsule-row-text">
            {fav.lesson.course_title} &raquo; {fav.lesson.unit}
          </div>
        );
      }

      // if this was a recommendation, show the coach info
      let coachNode = null;
      if (fav.status === 'recommended') {
        let coachPhotoNode = null;
        if (fav.creator && fav.creator.photo) {
          coachPhotoNode = (
            <UserPhoto
              src={fav.creator.photo}
              className="home__favorites-capsule-row-rec-img"
              diameter={40}
            />
          );
        }
        coachNode = (
          <div className="home__favorites-capsule-row-rec">
            {coachPhotoNode}
            <div className="home__favorites-capsule-row-rec-text">
              Coach Recommended
            </div>
          </div>
        );
      }

      return (
        <div key={'fav-' + fav.id} className="home__favorites-capsule-row">
          <div
            className={'home__favorites-capsule-row-icon ' + iconClass}
          ></div>
          <div className="home__favorites-capsule-row-data">
            <NavigationLink
              url={titleLink}
              className="tc__link tc__link--black home__favorites-capsule-row-title"
              isMtp={fav.type === 'lesson'}
            >
              {titleText}
            </NavigationLink>
            {textNode}
            {coachNode}
          </div>
        </div>
      );
    });
    favoritesPageLinkNode = (
      <div className="home__favorites-capsule-link">
        <NavigationLink
          url={
            process.env.REACT_APP_TEACHING_URL +
            '/browse/favorites/' +
            props.pageUser.id +
            '?from=home_favorites_viewall'
          }
          className="tc__link"
        >
          View All
        </NavigationLink>
      </div>
    );
  } else if (props.searchSuccess === false) {
    favoriteNodes = (
      <div className="row">
        <div className="cute-10-desktop center-desktop cute-12-phone tc__left-text">
          <div className="home__favorites-capsule-row-text-error">
            Your favorites are temporarily unavailable. (We blame the cat.)
          </div>
          <div className="home__favorites-capsule-row-text-error">
            Thanks for your patience as we tidy up this issue.
          </div>
          <img src="/legacy-images/404-pets.png" className="code-404__img" />
        </div>
      </div>
    );
    favoritesPageLinkNode = '';
  }
  return (
    <div className="bl4__capsule">
      <h2 className="home__favorites-capsule-headline">Favorites</h2>
      {favoriteNodes}
      {favoritesPageLinkNode}
    </div>
  );
};

Favorites.propTypes = {
  favorites: PropTypes.array.isRequired,
  pageUser: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  searchSuccess: PropTypes.bool,
};

export default Favorites;
