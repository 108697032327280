// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ActionModal from './../../Common/ActionModal';
import FormField from './../../Common/FormField';
import Spinner from './../../Common/Spinner';

const ParticipantStatusModal = (props) => {
  const iconMap = {
    Active: 'tc-icon_status-active participant-v5__active-icon-color',
    'Temporarily Inactive': 'tc-icon_status-temp-inactive',
    'Done with Coaching': 'tc-icon_status-done',
    Dropped: 'tc-icon_status-dropped',
    Disengaged: 'tc-icon_status-disengaged',
  };
  // set defaults that might change with the timezone being available
  const [isLoading, setLoading] = useState(false);
  const [statusNote, setStatusNote] = useState(props.note || '');
  const [statusError, setStatusError] = useState('');

  const handleChangeNote = (e) => {
    setStatusNote(e.target.value);
    if (e.target.value === '') {
      setStatusError(
        'Hold on, you need to provide context for this status change.'
      );
    } else {
      setStatusError('');
    }
  };

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handleAddNote = (e) => {
    e.preventDefault();

    if (statusNote === '') {
      setStatusError(
        'Hold on, you need to provide context for this status change.'
      );
      return;
    }

    setLoading(true);

    props.submitNote({
      status: props.status,
      note: statusNote,
      successCallback: props.close,
      failureCallback: () => setLoading(false),
    });
  };

  let statusUpdate = `Mark ${props.participantName} as `;
  let headerText = 'Change Status';
  let buttonText = 'Add note';

  // If a note is provided then it's not being edited it's being added
  if (props.note) {
    statusUpdate = '';
    headerText = 'Edit Status Note';
    buttonText = 'Save';
  }

  // disable buttons when submitting
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <ActionModal headlineText={headerText} close={handleCloseModal}>
      <form className="participant-v5__modal-body">
        <div className="workspace__form-row participant-v5__modal-top-row participant-v5__meeting-cancel-modal-topline v5__heading-5 participant-v5__participant-note-status-modal-header">
          <span
            className={`${
              iconMap[props.status]
            } participant-v5__participant-note-icons participant-v5__participant-note-icons-modal`}
          >
            <span className="path1"></span>
            <span className="path2"> </span>
          </span>
          <div>
            &nbsp;&nbsp;{statusUpdate}
            {Utils.formatEngagementStatus(props.status)}
          </div>
        </div>

        <label>
          <div className="workspace__form-row participant-v5__modal-top-row v5__heading-5 participant-v5__meeting-cancel-modal-topline">
            <div className="v5__body-copy ">
              Explain why you are changing this educator&apos;s status.
            </div>
          </div>

          <div className="workspace__form-row">
            <FormField
              type="textarea"
              disabled={isLoading}
              error={statusError}
              rows={6}
              defaultValue={statusNote}
              onChange={handleChangeNote}
              dataTestId="participant-note-field"
            />
          </div>
        </label>

        <div className="workspace__form-row">
          <div className="participant-v5__participant-note-button-row">
            <button
              data-testid="add-note-btn"
              className="v5__btn"
              onClick={handleAddNote}
              {...btnProps}
            >
              <Spinner loading={isLoading} />
              {buttonText}
            </button>
          </div>
        </div>
      </form>
    </ActionModal>
  );
};

ParticipantStatusModal.propTypes = {
  close: PropTypes.func.isRequired,
  submitNote: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  participantName: PropTypes.string.isRequired,
  note: PropTypes.string,
  authorId: PropTypes.number,
};

export default ParticipantStatusModal;
