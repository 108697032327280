// @ts-nocheck FIXME
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import React from 'react';

const GoalCompleteModal = (props) => {
  const [outcomeText, setOutcomeText] = React.useState('');
  const [outcomeError, setOutcomeError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (e) => {
    setOutcomeError('');
    setOutcomeText(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (!outcomeText) {
      setOutcomeError('Hold on, you need to enter a reflection to continue.');
      return;
    }

    setIsLoading(true);

    props.submit({
      goalId: props.data.goal.id,
      note: outcomeText,
    });
  };

  const saveBtnProps = isLoading ? { disabled: true } : null;

  return (
    <ActionModal
      headlineText="Complete Outcome"
      close={props.close}
      spaceFromTop={50}
    >
      <div className="workspace__confirm-complete-title-container">
        <span className="tc-v5-milestone-complete workspace__goal-complete-check">
          <span className="path1"></span>
          <span className="path2 workspace__goal-flag-check--completed"></span>
        </span>
        <div className="v5__heading-5">
          <span>{`${props.data.goal.title}`}</span>
        </div>
      </div>

      <div className="v5__body-copy workspace__confirm-complete-instruction">
        {`Write a growth reflection to share with ${props.data.pageUser.first_name}'s administrator`}
      </div>
      <div className="v5__body-copy workspace__confirm-complete-textarea-label">
        Outcome Reflection
      </div>
      <FormField
        type="textarea"
        error={outcomeError}
        inputClassName="workspace__confirm-complete-textarea"
        placeholder="Include thoughts on progress as this outcome completes."
        onChange={handleChange}
      />

      <div className="workspace__confirm-complete-btn-row">
        <button
          className="v5__btn v5__btn--blue workspace__confirm-complete-btn-save"
          onClick={handleSave}
          {...saveBtnProps}
        >
          <Spinner loading={isLoading} />
          Save
        </button>
      </div>
    </ActionModal>
  );
};

GoalCompleteModal.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.shape({
    goal: PropTypes.object.isRequired,
    pageUser: PropTypes.object.isRequired,
  }).isRequired,
};

export default GoalCompleteModal;
