// @ts-nocheck FIXME
import Analytics from './../../components/Analytics';
import Modal from './../../components/Modal';
import StorageHelper from './../../components/StorageHelper';
import Store from './../../components/Store';

const store = {
  partners: [],
  ui: {
    alert: {},
    sortColumn: 'partner',
    sortDirection: 'ascending',
    toolTipModal: {
      onOpen: (data) => {
        Analytics.logEvent({
          category: 'growth_tooltip',
          action: 'open',
          label: 'from_ppd3_partner_highlights',
        });
      },
      onClose: () => {
        Analytics.logEvent({
          category: 'growth_tooltip',
          action: 'close',
          label: 'from_ppd3_partner_highlights',
        });
      },
    },
  },
};

/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.ui.alert = {};
  store.partners = data.partners;

  store.ui.toolTipModal = Modal(store, store.ui.toolTipModal);

  //keep dashboard sorting in local storage.
  let localSortState;

  // this is the default sort column and direction from backend.
  const defaultSortState = { column: 'partner', direction: 'ascending' };

  localSortState = StorageHelper.init(
    'partnerHighlightsSortState',
    defaultSortState
  );

  store.ui.sortColumn = localSortState.column;
  store.ui.sortDirection = localSortState.direction;

  //skipping participants as its our default if its name we have no work.
  switch (localSortState.column) {
    case 'partners':
      //sort alphabetically
      store.partners.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );
      break;
    case 'growth':
      store.partners.sort((a, b) => (a.growth > b.growth ? -1 : 1));
      break;
    case 'meetings':
      store.partners.sort((a, b) =>
        a.meeting_count > b.meeting_count ? -1 : 1
      );
      break;
    case 'artifacts':
      store.partners.sort((a, b) =>
        a.artifact_count > b.artifact_count ? -1 : 1
      );
      break;
    case 'partner_leaders':
      store.partners.sort((a, b) => (a.lead_count > b.lead_count ? -1 : 1));
      break;
  }

  if (localSortState.direction == 'descending') {
    store.partners.reverse();
  }

  // don't trigger event
  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.sortBy = (column) => {
  console.log('yay', column);
  //if we are resorting by same column
  if (store.ui.sortColumn == column) {
    if (store.ui.sortDirection == 'ascending') {
      store.ui.sortDirection = 'descending';
      store.partners.reverse();
    } else {
      store.ui.sortDirection = 'ascending';
      store.partners.reverse();
    }
  } else {
    //always default sort new columns ascending
    store.ui.sortDirection = 'ascending';
    store.ui.sortColumn = column;

    switch (column) {
      case 'partners':
        //sort alphabetically
        store.partners.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        );
        break;
      case 'participants':
        store.partners.sort((a, b) =>
          a.participant_count > b.participant_count ? -1 : 1
        );
        break;
      case 'growth':
        store.partners.sort((a, b) => (a.growth > b.growth ? -1 : 1));
        break;
      case 'meetings':
        store.partners.sort((a, b) =>
          a.meeting_count > b.meeting_count ? -1 : 1
        );
        break;
      case 'artifacts':
        store.partners.sort((a, b) =>
          a.artifact_count > b.artifact_count ? -1 : 1
        );
        break;
      case 'partner_leaders':
        store.partners.sort((a, b) => (a.lead_count > b.lead_count ? -1 : 1));
        break;
    }
  }

  const localSortState = {
    column: store.ui.sortColumn,
    direction: store.ui.sortDirection,
  };
  StorageHelper.setItem('partnerHighlightsSortState', localSortState);

  // all columns but partners are sort descended so that the default state is flipped. we need to report that to analytics
  let action = 'sort_'.concat(localSortState.direction);
  if (column != 'partners') {
    action = 'sort_ascending';
    if (localSortState.direction == 'ascending') action = 'sort_descending';
  }
  Analytics.logEvent({
    category: localSortState.column,
    action: action,
    label: 'from_ppd3_partner_highlights',
  });
  return true;
};

export default Store('PartnerHighlightsStore', store);
