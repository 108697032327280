// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Spinner from './Spinner';

const ConfirmModal = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(props.object);
  };

  const handleCloseOverlay = (e) => {
    e.preventDefault();
    props.closeConfirmModal();
  };

  // disable buttons when form is submitted
  const disabledClass = props.isLoading ? ' disabled' : '';

  return (
    <div>
      <div
        className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
        onClick={handleCloseOverlay}
      ></div>
      <div className="modal--fixed modal--grey-overlay ppd-v3-curation-modal ppd-v3-curation-modal--short">
        <div className="modal__close" onClick={handleCloseOverlay}>
          <div className="tc-x"></div>
        </div>
        <div className="ppd-v3-curation-modal__body">
          <div className="ppd-v3-curation-modal__body-text">
            {props.bodyText}
          </div>

          <div className="ppd-v3-curation-modal__buttons">
            <button
              onClick={handleSubmit}
              className={
                'tc__btn tc__btn--flat-blue ppd-v3-curation-modal__button' +
                disabledClass
              }
            >
              <Spinner loading={props.isLoading} />
              {props.submitBtnText ? props.submitBtnText : 'Submit'}
            </button>
            <button
              onClick={handleCloseOverlay}
              className={
                'tc__btn tc__btn--flat-grey ppd-v3-curation-modal__button ppd-v3-curation-modal__button--cancel' +
                disabledClass
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  object: PropTypes.object,
  closeConfirmModal: PropTypes.func.isRequired,
  bodyText: PropTypes.string.isRequired,
  submitBtnText: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ConfirmModal;
