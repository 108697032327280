// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import Utils from './../../../../components/DynamicUtils';
import ScaleCenterImage from './../../../ScaleCenterImage';
import TMLInstance from './TML';

const StrategyImplementation = (props) => {
  // show a random image for this strategy
  let imageNode = null;
  if (props.strategy_image_id) {
    const imageSrc =
      '/legacy-images/portfolio_strategies/' + props.strategy_image_id + '.jpg';
    imageNode = (
      <div className="portfolio-v2__strategy-image-container">
        <ScaleCenterImage
          src={imageSrc}
          className="portfolio-v2__strategy-image"
          alt={props.strategy}
        />
      </div>
    );
  }

  // set up the strategy implementation description
  let descriptionNode = null;
  if (props.strategy_description) {
    descriptionNode = (
      <PurifiedHTML
        className="portfolio-v2__strategy-description"
        content={props.strategy_description}
      />
    );
  }

  // set up the strategy type + description
  let strategyTypeNode = null;
  if (props.strategy_name && props.strategy_definition) {
    strategyTypeNode = (
      <div className="portfolio-v2__strategy-detail">
        <span className="label">Strategy Type:</span>
        <span className="portfolio-v2__strategy-name">
          {props.strategy_name}
        </span>{' '}
        - {props.strategy_definition}
      </div>
    );
  }

  // set up the strategy implementation author info
  let strategyAuthorNode = null;
  if (props.strategy_author) {
    strategyAuthorNode = (
      <span className="portfolio-v2__strategy-detail-item">
        <span className="label">Author:</span> Master Teacher{' '}
        {props.strategy_author.first_name +
          ' ' +
          props.strategy_author.last_name}
      </span>
    );
  }

  // set up the strategy implementation source info
  let strategySourceNode = null;
  if (props.strategy_source) {
    strategySourceNode = (
      <span className="portfolio-v2__strategy-detail-item">
        <span className="label">Source:</span> {props.strategy_source}
      </span>
    );
  }

  // set up the start + end dates for this tml loop
  let startedNode = null;
  if (props.started) {
    const started = Utils.formatDate(props.started);
    startedNode = (
      <span className="portfolio-v2__strategy-detail-item">
        <span className="portfolio__declaration">STARTED:</span>
        {started}
      </span>
    );
  }

  let completedNode = null;
  if (props.completed) {
    const completed = Utils.formatDate(props.completed);
    completedNode = (
      <span className="portfolio-v2__strategy-detail-item">
        <span className="portfolio__declaration">COMPLETED:</span>
        {completed}
      </span>
    );
  }

  const TMLInstanceNode = (
    <TMLInstance
      feedback={props.feedback}
      artifacts={props.artifacts}
      measurement={props.measurement}
    />
  );

  // tga info styles are different from sga styles
  const tgaClass = props.challenge.is_instructional_design ? ' tga' : '';

  return (
    <div>
      <div className="portfolio-v2__strategy">
        {imageNode}
        <div className="portfolio-v2__strategy-details">
          <div className="portfolio-v2__strategy-headline">
            FEATURED STRATEGY
          </div>
          <div className="portfolio-v2__strategy-title">{props.strategy}</div>
          <div className="portfolio-v2__strategy-taxonomy">
            <div className="portfolio-v2__strategy-taxonomy-challenge">
              <div className="portfolio-v2__strategy-headline">
                TEACHING CHALLENGE
              </div>
              <div className="portfolio-v2__strategy-taxonomy-value">
                {props.challenge.name}
              </div>
            </div>
            <div className="portfolio-v2__strategy-taxonomy-growth-area">
              <div
                className={
                  'portfolio-v2__challenge-icon portfolio-v2__challenge-icon--strategy' +
                  tgaClass
                }
              >
                <div className={'tc-sga-nb-' + props.challenge.sga.id}></div>
              </div>
              <div className="portfolio-v2__strategy-taxonomy-growth-area-details">
                <div className="portfolio-v2__strategy-headline">
                  GROWTH AREA
                </div>
                <div className="portfolio-v2__strategy-taxonomy-value">
                  {props.challenge.sga.name}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="portfolio-v2__strategy-headline">
          ABOUT THIS STRATEGY
        </div>

        {descriptionNode}

        {strategyTypeNode}
        <div className="portfolio-v2__strategy-detail">
          {strategyAuthorNode}
          {strategySourceNode}
        </div>

        <div className="portfolio-v2__strategy-detail portfolio-v2__strategy-dates">
          {startedNode}
          {completedNode}
        </div>
      </div>

      {TMLInstanceNode}
    </div>
  );
};

export default StrategyImplementation;
