// @ts-nocheck FIXME
import Auth from '@legacy-components/components/Auth';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import ArtifactImage from './../Common/ArtifactImage';

const VisionArtifact = (props) => {
  const entryType = props.artifact.entry_type;

  const getArtifactNode = () => {
    const title = props.artifact.title || props.artifact.connections.name;

    // Only render artifact notes if they exist
    let artifactNotesNode = '';
    if (props.artifact.notes) {
      artifactNotesNode = (
        <div className="participant__artifact-notes artifact__notes tc__body-copy">
          {props.artifact.notes}
        </div>
      );
    }
    let artifactTypeClass = '';
    let artifactPreviewNode;
    if (entryType === 'uploaded_artifact') {
      artifactPreviewNode = (
        <div>
          <div onClick={handleArtifactClick}>
            <ArtifactImage
              preview={props.artifact.connections.preview}
              title={props.artifact.connections.name}
              containerClass="participant__artifact__preview-container"
              icon={props.artifact.connections.icon}
              imageClass="participant__artifact-image"
            />
          </div>
          <div className="participant__artifact-image-title">
            <span
              className="participant-artifact__title artifact__title title tc__link"
              onClick={handleArtifactClick}
            >
              {title}
            </span>
          </div>
        </div>
      );
    } else if (entryType === 'meeting_clip_entry') {
      artifactPreviewNode = (
        <div>
          <div onClick={handleArtifactClick}>
            <ArtifactImage
              preview={props.artifact.connections.content.preview}
              title={props.artifact.connections.name}
              containerClass="participant__artifact__preview-container"
              icon={props.artifact.connections.icon}
              imageClass="participant__artifact-image"
            />
          </div>
          <div className="participant__artifact-image-title">
            <span
              className="participant-artifact__title artifact__title title tc__link"
              onClick={handleArtifactClick}
            >
              {title}
            </span>
          </div>
        </div>
      );
    } else {
      artifactTypeClass = ' participant__artifact-container--text';
      artifactPreviewNode = (
        <div className="ckeditor__output">
          <PurifiedHTML content={props.artifact.data} tagName="span" />
        </div>
      );
    }
    return (
      <div
        id={'entry_' + props.artifact.id}
        className={
          'tc__capsule--time-line participant__artifact-container tc__capsule' +
          artifactTypeClass
        }
      >
        {getArtifactEditButton()}
        {artifactPreviewNode}
        {artifactNotesNode}
        <div className="outcome-story__entry-capsule-date">
          <TimeAgo date={props.artifact.created_formatted} />
        </div>
      </div>
    );
  };

  const getArtifactEditButton = () => {
    if (!Auth.isV3DataEditor(props.loggedInUser)) {
      return null;
    }
    return (
      <div className="participant__capsule-actions">
        <div
          className="participant__capsule-action participant__artifact-edit tc-pencil"
          onClick={handleEditClick}
        ></div>
      </div>
    );
  };

  const handleArtifactClick = (e) => {
    e.preventDefault();
    // Text Artifacts are not clickable
    if (entryType === 'text_entry') {
      return;
    }
    // open links in a new window
    if (props.artifact.url) {
      window.open(props.artifact.url, '_blank');
      return;
    }
    // Open the artifact modal
    props.artifactModal.open(props.artifact);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    // Handle text artifacts
    if (entryType === 'text_entry') {
      props.textArtifactModal.open(props.artifact);
      return;
    }
    // Handle files and links
    const data = {
      fileDetails: {
        id: props.artifact.id,
        title: props.artifact.title,
        preview: props.artifact.connections.preview,
        name: props.artifact.connections.name,
        notes: props.artifact.notes,
        selectedTypeId: props.artifact.artifact_type_id,
        extension: props.artifact.connections.extension
          ? props.artifact.connections.extension
          : 'link',
      },
      uploadType: props.artifact.url ? 'link' : 'upload',
    };
    props.openEditArtifactModal(data);
  };

  return getArtifactNode();
};

VisionArtifact.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  artifactModal: PropTypes.object.isRequired,
  artifact: PropTypes.object.isRequired,
  textArtifactModal: PropTypes.object.isRequired,
};

export default VisionArtifact;
