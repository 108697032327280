// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Element from './../Element';
import Sortable from './../Sortable';

const SubjectPanel = (props) => {
  const handleTogglePanel = (e) => {
    e.preventDefault();
    props.toggleSubjectPanel(props.id);
  };

  const handleToggleSubjectVisibility = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.toggleSubjectVisibility({
      subjectId: props.id,
    });
  };

  const handleGrowthAreaToggleVisible = (growthAreaId, e) => {
    e.preventDefault();
    props.toggleGrowthAreaVisibility({
      subjectId: props.id,
      growthAreaId: growthAreaId,
    });
  };

  const handleAddEntry = (growthAreaId, type, e) => {
    e.preventDefault();
    props.editEntry({
      sectionId: props.sectionId,
      subjectId: props.id,
      growthAreaId: growthAreaId,
      entryData: { mode: 'edit', type: type },
    });
  };

  // build icon to show visibility of subject
  const visibilityIcon = props.visible ? (
    <div className="ppt__visibility-icon selected">
      <div className="tc-check" />
    </div>
  ) : (
    <div className="ppt__visibility-icon"></div>
  );

  // count the total # entries in this panel
  const entryCount = props.growth_areas
    ? props.growth_areas.reduce(
        (prev, area) => (area.entries ? prev + area.entries.length : prev),
        0
      )
    : 0;

  const growthAreasNode =
    !props.isExpanded || !props.growth_areas ? null : (
      <div className="subject-panel__growth-area-section">
        {props.growth_areas.map((area) => {
          const areaVisibilityIcon = area.visible ? (
            <div className="ppt__visibility-icon selected">
              <div className="tc-check" />
            </div>
          ) : (
            <div className="ppt__visibility-icon"></div>
          );

          const elementNode = area.entries.map((element) => (
            <Element
              key={element.id}
              {...element}
              editEntry={props.editEntry}
              deleteEntry={props.deleteEntry}
            />
          ));

          // attach sorting functionality to elements
          const sortKey = 'growthAreaElements-' + area.id;
          const SortableElements = Sortable(
            <div id={sortKey}>{elementNode}</div>,
            '#' + sortKey,
            '#ppt-section__container',
            (entryIds) => {
              props.sortGrowthAreaEntries({
                entryIds: entryIds,
                growthAreaId: area.id,
                subjectId: props.id,
              });
            }
          );

          // build the row for the growth area
          return (
            <div key={area.id} className="subject-panel__growth-area clearfix">
              <div
                className="subject-panel__growth-area-visibility"
                onClick={(e) => handleGrowthAreaToggleVisible(area.id, e)}
              >
                {areaVisibilityIcon}
              </div>
              <div className="subject-panel__growth-area-info">
                {area.name}
                <SortableElements />
                <Element
                  {...area.newEntry}
                  sectionId={props.sectionId}
                  subjectId={props.id}
                  growthAreaId={area.id}
                  editEntry={props.editEntry}
                  deleteEntry={props.deleteEntry}
                  spotlightsOnly={true}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  const enableDraggingClass = !props.isExpanded
    ? ' ppt__drag-icon--ga'
    : ' ppt__drag-icon--no-drag';
  // rendering
  return (
    <div className="ppt-panel" data-id={props.id}>
      <div className="ppt-panel__header" onClick={handleTogglePanel}>
        <div
          className={
            'tc-dots-8 ppt__drag-icon ppt__drag-icon--nav' + enableDraggingClass
          }
        ></div>
        <div
          className="ppt-panel__visibility"
          onClick={handleToggleSubjectVisibility}
        >
          {visibilityIcon}
        </div>
        <div className="ppt-panel__name">{props.name}</div>
        <div className="ppt-panel__growth-area-count">
          {props.growth_areas.length} Growth Areas
        </div>
        <span className="ppt-panel__spotlight-count">
          {entryCount} Spotlights
        </span>
      </div>
      {growthAreasNode}
    </div>
  );
};

SubjectPanel.propTypes = {
  sectionId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  growth_areas: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool,
  toggleSubjectPanel: PropTypes.func.isRequired,
  sortGrowthAreaEntries: PropTypes.func.isRequired,
};

export default SubjectPanel;
