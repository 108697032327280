// @ts-nocheck FIXME
import React from 'react';
import Challenge from './Challenge';

class Category extends React.Component {
  render() {
    let categoryStrategiesCount = 0;

    // loop over sgas
    const sgaNodes = this.props.sgas.map(function (sga, i) {
      let sgaChallengeHasAttempts = false;

      // there are no challenges for this sga do not render the sga
      if (sga.challenges.length == 0) {
        return null;
      }

      // loop over challenges
      const challengeNodes = sga.challenges.map(function (challenge) {
        // if there are no 'tried' tml attempts do not render the challenge
        let challengeTmlAttempts = challenge.tmlAttempts.length;
        for (const attempt in challenge.tmlAttempts) {
          if (!challenge.tmlAttempts.hasOwnProperty(attempt)) continue;

          if (
            challenge.tmlAttempts[attempt]['step'] == 'pending' ||
            challenge.tmlAttempts[attempt]['step'] == 'teach'
          ) {
            challengeTmlAttempts--;
          }
        }

        // there are no tml attepts for this challenge so do not render the challenge
        if (challengeTmlAttempts == 0) {
          return null;
        }

        // there are attempts for this challenge count them
        sgaChallengeHasAttempts = true;
        categoryStrategiesCount += challenge.tmlAttempts.length;

        return <Challenge challenge={challenge} key={challenge.id + '-' + i} />;
      });

      // if there were no challenges do not render the sga
      if (!sgaChallengeHasAttempts) {
        return null;
      }

      let impactCaption = 'Student Growth Area';
      let instructionalDesignClass = '';
      if (sga.category == 'Instructional Design') {
        impactCaption = 'Teacher Growth Area';
        instructionalDesignClass =
          ' portfolio__growth-map-sga--instructional-design';
      }
      // render the sga node
      return (
        <div
          className={'portfolio__growth-map-sga' + instructionalDesignClass}
          key={'sga-' + sga.name + '-' + i}
        >
          <div className="portfolio__growth-map-sga-icon">
            <div className={'tc-sga-nb-' + sga.id}></div>
          </div>
          <div className="portfolio__growth-map-sga-details">
            <div className="portfolio__growth-map-sga-declaration">
              {impactCaption}
            </div>
            <div className="portfolio__growth-map-sga-name">{sga.name}</div>
          </div>
          {challengeNodes}
        </div>
      );
    });

    // if there are no strategies for this categories there is no reason to render the node
    if (categoryStrategiesCount == 0) {
      return null;
    }

    return (
      <div>
        <div className="portfolio__growth-map-category-name">
          {this.props.category} Challenges
        </div>
        {sgaNodes}
      </div>
    );
  }
}

export default Category;
