// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import StatusIcon from '@legacy-components/react/V5/Common/StatusIcon';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const MeetingRow = (props) => {
  let photoNode = null;

  if (props.photo) {
    // Set up user photo instead of the default icon if set
    photoNode = <UserPhoto src={props.photo} diameter={40} hasBorder={false} />;
  } else {
    // Use the participant's name and user ID so that two users with the same name don't use the same color
    // Then convert each letter to a char code, sum and take the modulus so the same user is always the same color
    const participantName =
      props.first_name + ' ' + props.last_name + props.user_id;
    const index = participantName
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc);

    const colors = [
      '#004493',
      '#1A7CD9',
      '##2196F3',
      '#0B8484',
      '#0AABB1',
      '#53E6EB',
      '#53E6EB',
      '#107D54',
      '#33B55F',
      '#2ACAA1',
      '#25E8C8',
      '#FF6B2A',
      '#F4AB2A',
    ];

    const iconStyles = {
      backgroundColor: colors[index % colors.length],
    };

    photoNode = (
      <div style={iconStyles} className="admin-v5_coach-dashboard_default-icon">
        <span>
          {props.first_name.substr(0, 1)}
          {props.last_name.substr(0, 1)}
        </span>
      </div>
    );
  }

  const schoolNameNode = props.school_name || (
    <div className="v5__quote-copy">No school selected</div>
  );

  let lastMeetingNode = <div className="v5__quote-copy">No meetings held</div>;

  if (props.last_meeting && props.last_meeting_status) {
    let meetingClass;
    switch (props.last_meeting_status) {
      case 'Scheduled':
        meetingClass = 'tc-v5-meeting';
        break;
      case 'Completed':
        meetingClass = 'tc-v5-meeting-complete';
        break;
      case 'Cancelled':
        meetingClass = 'tc-mtg-cancelled-icon';
        break;
      case 'No Show':
        meetingClass = 'tc-v5-meeting-missed';
        break;
    }
    lastMeetingNode = (
      <div className="v5__registered-table-educator-count">
        <Tooltip
          offsetLeft={27}
          nowrap={true}
          tooltip={`${props.last_meeting_status}`}
        >
          <div className={`v5__registered-table--status-icon ${meetingClass}`}>
            <span className="path1"></span>
            <span className="path2"> </span>
            <span className="path3"> </span>
          </div>
        </Tooltip>
        {moment(props.last_meeting).format('MMMM D, Y')}
      </div>
    );
  }

  const meetingsHeldNode =
    props.meetings &&
    props.meetings.filter(
      (meeting) =>
        meeting.status === 'Completed' &&
        meeting.coaching_engagement_id === props.engagement.id
    ).length;

  const nextMeetingNode = props.next_meeting ? (
    moment(props.next_meeting).format('MMMM D, Y')
  ) : (
    <div className="v5__quote-copy">No meeting scheduled</div>
  );
  let nextMeetingColumn = (
    <div className="cute-2-desktop v5__registered-table-column">
      <div>{nextMeetingNode}</div>
    </div>
  );
  let nameWidth = 2;
  let schoolWidth = 3;
  if (props.currentYear === 'past') {
    nextMeetingColumn = null;
    nameWidth += 1;
    schoolWidth += 1;
  }
  return (
    <div className="row v5__registered-table-copy v5__registered-table-row">
      <div className={`cute-${nameWidth}-desktop v5__registered-table-column`}>
        <div>{photoNode}</div>
        <div className="v5__registered-table-name">
          <NavigationLink
            className="tc__link"
            url={`/lab/highlights/${props.user_id}`}
          >
            {props.first_name} {props.last_name}
          </NavigationLink>
        </div>
        <StatusIcon status={props.status} />
      </div>

      <div
        className={`cute-${schoolWidth}-desktop v5__registered-table-column`}
      >
        <div>{schoolNameNode}</div>
      </div>

      <div className="cute-2-desktop v5__registered-table-column">
        <div>{meetingsHeldNode}</div>
      </div>

      <div className="cute-3-desktop v5__registered-table-column">
        <div>{lastMeetingNode}</div>
      </div>
      {nextMeetingColumn}
    </div>
  );
};

MeetingRow.propTypes = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  next_meeting: PropTypes.string,
  last_meeting: PropTypes.string,
  meetings: PropTypes.array.isRequired,
};

export default MeetingRow;
