// @ts-nocheck FIXME
import React from 'react';
import RegistrationSteps from './RegistrationSteps';

const ResetPassword = (props) => {
  const userPassword = React.useRef(null);
  const userConfirmPassword = React.useRef(null);

  const handleResetPassword = (e) => {
    props.setIsLoading(true);
    e.preventDefault();
    const resetToken = props.user.reset_token ? props.user.reset_token : '';
    const userSub = props.user.sub ? props.user.sub : '';
    const resetCode =
      !resetToken && !userSub && props.user.reset_code
        ? props.user.reset_code
        : '';
    props.setPassword({
      resetToken: resetToken,
      resetCode: resetCode,
      sub: userSub,
      password: userPassword.current.value,
      confirmPassword: userConfirmPassword.current.value,
      redirect: props.redirect,
      silentLogin: true,
    });
  };

  const formFieldErrorMessages = {};

  const removeErrors = (e) => {
    formFieldErrorMessages[e.target.name] = null;
    props.clearErrorClass(e.target.name);
    props.clearErrorClass('general');
  };

  Object.keys(props.ui.errors).forEach((key) => {
    formFieldErrorMessages[key] = props.ui.errors[key] ? (
      <div className="gatekeeper__input-error" style={{ marginTop: '5px' }}>
        {props.ui.errors[key]}
      </div>
    ) : null;
  });

  const btnDisabledClass = props.ui.loading ? ' disabled' : '';

  return (
    <div className="registration-v5-event__register">
      <div className="registration-v5-event__register-header v5__heading-3">
        {'Get Your Personal Coach'}
      </div>
      <div className="registration-v5-event__register-body v5__body-copy">
        <RegistrationSteps
          hasAccount={false}
          stepOneActive={true}
          registrationProcess={props.cohort ? 'cohort' : 'event'}
        />
        <div className="row">
          <div className="cute-12-desktop">
            <div className="v5__heading-5 registration-v5-event__register-subheading">
              Set a password to create your account
            </div>
            <div className="registration-v5-event__register-description">
              You are creating an account for the email address{' '}
              <span className="bold">{props.user.email}</span>.
            </div>
          </div>
        </div>
        <form onFocus={removeErrors}>
          <div className="row">
            <div className="cute-12-desktop cute-12-phone">
              <input
                className="v5__input"
                name="password"
                type="password"
                autoComplete="new-password"
                ref={userPassword}
                placeholder="Password"
              />
              {formFieldErrorMessages['password']}
            </div>
            <div className="cute-12-desktop cute-12-phone">
              <input
                className="v5__input"
                name="confirmPassword"
                type="password"
                ref={userConfirmPassword}
                placeholder="Confirm Password"
              />
              {formFieldErrorMessages['confirmPassword']}
            </div>
          </div>
          <div className="registration-v5-event__button-container">
            <button
              className={'v5__btn' + btnDisabledClass}
              onClick={handleResetPassword}
            >
              Create Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
