// @ts-nocheck FIXME
import ParticipantApi from './../../apis/ParticipantApi';
import Store from './../../components/Store';

const store = {
  fileName: '',
  validationResultsHeaders: null,
  validationResults: null,
  userCount: 0,
  invalidUserCount: 0,

  ui: {
    sendEmail: true,
    loading: false,
    importSuccess: false,
    errors: {},
    alert: {},
  },
};

/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.fileName = '';
  store.validationResultsHeaders = null;
  store.validationResults = null;
  store.userCount = 0;
  store.invalidUserCount = 0;
  store.ui.sendEmail = true;
  store.ui.importSuccess = false;
  store.ui.errors = {};
  store.ui.alert = {};

  // don't trigger event
  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.clearErrors = () => {
  store.ui.errors = {};
};

store.setFileName = (name) => {
  store.fileName = name;
};

store.toggleSendEmail = () => {
  store.ui.sendEmail = !store.ui.sendEmail;
};

store.submit = (data) => {
  // clear errors
  store.ui.loading = false;
  store.ui.importSuccess = false;
  store.ui.errors = {};
  store.ui.alert = {};

  // if we are trying to preview the import, clear the stored data first
  if (!data.formData.createUsers) {
    store.validationResultsHeaders = null;
    store.validationResults = null;
    store.userCount = 0;
    store.invalidUserCount = 0;
  }

  // track form errors
  const errors = {};
  // only submit the form if there is a csv or tsv file uploaded
  if (
    !data.file ||
    (data.file.type != 'text/csv' &&
      data.file.type != 'text/tab-separated-values')
  ) {
    errors.file = 'Please select a csv or tsv file.';
  }
  if (!data.formData.cohort) {
    errors.cohort = 'Please select a cohort.';
  }

  // if the form has any errors, display them instead of submitting the form
  if (Object.keys(errors).length > 0) {
    store.ui.errors = errors;
    return;
  }

  // set up the UI to show the loading animation
  store.ui.loading = true;

  // add extra form data
  data.formData.sendEmail = store.ui.sendEmail;

  return ParticipantApi.createParticipants({
    file: data.file,
    formData: data.formData,
  })
    .then((response) => {
      store.ui.loading = false;
      store.validationResultsHeaders = response.headers;
      store.validationResults = response.validationResults;
      store.userCount = response.count;
      store.invalidUserCount = response.invalidRowCount;

      if (data.formData.createUsers && response.invalidRowCount == 0) {
        // after a successful import, we want to show a slightly modified results table
        store.ui.importSuccess = true;
      }
    })
    .catch((response) => {
      store.ui.loading = false;
      if (response.error) {
        store.ui.errors[response.field] = response.error;
        return;
      }
      setErrorAlert(
        'Sorry, there was a problem submitting the form. Please try again later.'
      );
    });
};

store.cancelSubmit = () => {
  store.ui.loading = false;
};

export default Store('BulkParticipantImporterStore', store);
