// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ArtifactImage from './../../../Common/ArtifactImage';

const VisionArtifactCapsule = (props) => {
  // clicking on an artifact img or title will open the artifact overlay
  const handleArtifactClick = (e) => {
    e.preventDefault();

    // open links in a new window
    if (props.artifact.url) {
      window.open(props.artifact.url, '_blank');
      return;
    }

    props.artifactModal.open(props.artifact);
  };

  let capsuleBodyNode = (
    <div>
      <div onClick={handleArtifactClick}>
        <ArtifactImage
          preview={props.artifact.connections.preview}
          title={props.artifact.connections.name}
          containerClass=""
          icon={props.artifact.connections.icon}
          imageClass="p-portfolio-presn__vision-artifact-image"
        />
      </div>
      <div
        className="tc__link p-portfolio-presn__vision-artifact-row"
        onClick={handleArtifactClick}
      >
        {props.artifact.title}
      </div>
      <div className="tc__body-copy p-portfolio-presn__vision-artifact-row">
        {props.artifact.notes}
      </div>
    </div>
  );
  if (props.artifact.entry_type === 'text_entry') {
    capsuleBodyNode = (
      <div>
        <PurifiedHTML
          className="tc__body-copy ckeditor__output"
          content={props.artifact.data}
        />
      </div>
    );
  }

  return (
    <div className="tc__capsule tc__capsule--time-line p-portfolio-presn__vision-artifact-capsule">
      {capsuleBodyNode}
    </div>
  );
};

VisionArtifactCapsule.propTypes = {
  artifact: PropTypes.object.isRequired,
  artifactModal: PropTypes.object.isRequired,
};

export default VisionArtifactCapsule;
