// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import moment from 'moment-timezone';
import propTypes from 'prop-types';

const EventSessionRow = (props) => {
  const sessionStartTime = moment(props.session_start_time).tz(
    props.userTimezone
  );
  const sessionEndTime = moment(props.session_end_time).tz(props.userTimezone);
  const formattedStartTime = sessionStartTime.format('MMMM D, YYYY — h:mm A');
  let formattedEndTime;

  // if converted time are in different dates, then display full datetime
  if (sessionEndTime.isSame(sessionStartTime, 'day')) {
    formattedEndTime = sessionEndTime.format('h:mm A');
  } else {
    formattedEndTime = sessionEndTime.format('MMMM D, YYYY — h:mm A');
  }

  const sessionTimeStr = `${formattedStartTime} - ${formattedEndTime}`;

  const designDocsLink =
    props.product && props.product.coaching_design ? (
      <NavigationLink
        url={props.product.coaching_design}
        target="_blank"
        className="participant-v5__event-row--link"
      >
        {`${props.product.product_code}: ${props.product.name}`}
      </NavigationLink>
    ) : (
      ''
    );

  let checkedInTime = null;
  if (props.checked_in_formatted) {
    checkedInTime = moment(props.checked_in_formatted).tz(props.userTimezone);
    checkedInTime = checkedInTime.format('M/D/YY H:mm A');
  }

  const checkedInTooltip = checkedInTime ? (
    <Tooltip
      tooltip={checkedInTime}
      delay={1}
      offsetLeft={27}
      tipClass="participant-v5__status-tooltip"
    >
      <span className="tc-icon_status-done">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    </Tooltip>
  ) : null;

  return (
    <div className="participant-v5__event-row participant-v5__event-row-container">
      <div className="participant-v5__event-row--time">{sessionTimeStr}</div>
      <div className="participant-v5__event-row--text">
        {props.session_name
          ? props.session_name
          : `Session ${props.session_num}`}
      </div>
      <div>{designDocsLink}</div>
      <div className="participant-v5__event-row--icon">{checkedInTooltip}</div>
    </div>
  );
};

EventSessionRow.propTypes = {
  userTimezone: propTypes.string.isRequired,
  product: propTypes.object,
  session_num: propTypes.number.isRequired,
  session_name: propTypes.string,
  session_start_time: propTypes.string.isRequired,
  session_end_time: propTypes.string.isRequired,
  checked_in_formatted: propTypes.string,
};

export default EventSessionRow;
