// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';
import UserPhoto from './../../../V4/Common/UserPhoto';

const MetadataSidebar = (props) => {
  let gradesNode = null;
  let subjectsNode = null;
  let prepTimeNode = null;
  let favoriteNode = null;

  // set up any lesson authors
  let authorNodes = (
    <div>
      <UserPhoto
        src="/legacy-images/bl-icon-circle.png"
        diameter={80}
        className="strategy-v4__author-photo"
      />
      <div className="strategy-v4__author-name">
        Written by BetterLesson Master Teacher
      </div>
    </div>
  );

  if (props.author) {
    authorNodes = (
      <NavigationLink
        url={
          process.env.REACT_APP_TEACHING_URL +
          '/browse/master_teacher/' +
          props.author.id
        }
        className="strategy-v4__metadata-flex-section strategy-v4__metadata-flex-section--centered"
      >
        <div
          key={'author-' + props.author.id}
          className="strategy-v4__metadata-flex-item"
        >
          <UserPhoto
            src={props.author.photo}
            diameter={80}
            className="strategy-v4__author-photo"
          />
          <div className="strategy-v4__author-name">{props.author.name}</div>
          <div className="strategy-v4__metadata-grey-text">
            {props.author.school.location} <br /> {props.author.school.name}
          </div>
        </div>
      </NavigationLink>
    );
  }

  const authorsNode = (
    <div className="strategy-v4__metadata-section tc__center-text">
      <div className="strategy-v4__metadata-headline">Author</div>
      {authorNodes}
    </div>
  );

  if (props.Grade) {
    gradesNode = props.Grade.map((grade) => {
      return (
        <div
          key={`lesson-grade-${grade.id}`}
          className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text"
        >
          {grade.name}
        </div>
      );
    });
  }

  if (props.Subject) {
    subjectsNode = props.Subject.map((subject) => {
      return (
        <div
          key={`lesson-grade-${subject.id}`}
          className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text tc__link"
        >
          <NavigationLink
            url={`${process.env.REACT_APP_TEACHING_URL}/search?subjects=${subject.id}`}
            className="tc__link"
          >
            {subject.name}
          </NavigationLink>
        </div>
      );
    });
  }

  if (parseInt(props.favorite_count) > 0) {
    favoriteNode = (
      <div className="lesson__favorite">
        <span className="lesson__favorite-icon tc-v4-heart-filled"></span>
        <span className="lesson__favorite-caption">
          {props.favorite_count} teachers like this lesson
        </span>
      </div>
    );
  }

  if (props.grade_names) {
    gradesNode = (
      <div className="strategy-v4__metadata-container">
        <div className="strategy-v4__metadata-headline">Grade Level</div>
        <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
          {props.grade_names}
        </div>
      </div>
    );
  }

  if (props.subject_names) {
    subjectsNode = (
      <div className="strategy-v4__metadata-container">
        <div className="strategy-v4__metadata-headline">Subjects</div>
        <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
          {props.subject_names}
        </div>
      </div>
    );
  }

  if (props.skill_level) {
    prepTimeNode = (
      <div className="strategy-v4__metadata-container">
        <div className="strategy-v4__metadata-headline">Time</div>
        <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
          {props.skill_level}
        </div>
      </div>
    );
  }

  return (
    <div className="lesson__metadata-sidebar cute-2-desktop cute-12-phone show-desktop hide-phone">
      {props.socialSharingNode}
      {favoriteNode}
      {authorsNode}
      <div className="strategy-v4__metadata-section">
        {gradesNode}
        {subjectsNode}
        {prepTimeNode}
      </div>
    </div>
  );
};

MetadataSidebar.propTypes = {
  User: PropTypes.object,
  favorite_count: PropTypes.string,
  Grade: PropTypes.array,
  Subject: PropTypes.array,
  CoreStandard: PropTypes.array,
  duration: PropTypes.number,
  socialSharingNode: PropTypes.node,
};

export default MetadataSidebar;
