// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';

const ContentPartners = (props) => {
  const partnerNodes = props.partners.map((partner) => {
    const imageNode = partner.images.map((image) => {
      return (
        <img
          key={image}
          className="content-partner__image"
          src={`/legacy-images/partners/${image}`}
        />
      );
    });

    let btnNode;
    if (partner.link) {
      btnNode = (
        <div className="content-partner__btn">
          <NavigationLink className="v5__btn v5__btn--blue" url={partner.link}>
            {`View ${partner.btnText}`}
          </NavigationLink>
        </div>
      );
    } else if (partner.links) {
      btnNode = (
        <div>
          {partner.links.map((link, i) => {
            return (
              <div key={`btn-${i}`} className="content-partner__btn">
                <NavigationLink className="v5__btn v5__btn--blue" url={link}>
                  {partner.btnTexts[i]}
                </NavigationLink>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <section
        className="content-partner v5__capsule"
        key={`partner-${partner.id}`}
      >
        <div
          className={`v5__capsule-header-color v5__capsule-header-color--partner-${partner.id}`}
        />
        <div className="content-partner__content">
          <div className="content-partner__left">{imageNode}</div>
          <div className="content-partner__right">
            <h2 className="content-partner__headline v5__heading-3">
              {partner.headline}
            </h2>
            <p className="content-partner__copy v5__body-copy">
              {partner.description}
            </p>
            {btnNode}
          </div>
        </div>
      </section>
    );
  });

  return (
    <div className="tc__page">
      <Header page="Domains" {...props} />
      <div className="row row--1200 content-partner__container">
        <header className="content-partner__header" page="Content Partners">
          <div className="content-partner__header-copy">
            <h1 className="v5__heading-2">Our Content Partners</h1>
            <img
              className="content-partner__header-image hide-desktop show-phone"
              src="/legacy-images/partners/content-partners-medium.png"
            />
            <div className="v5__body-copy">
              At BetterLesson, we partner with high-impact education
              organizations that support student and teacher growth. Thanks to
              these partnerships, we&apos;ve created high-quality content and
              resources available freely to all educators to help them create
              personalized, student-centered classrooms where learners tackle
              high-level material and feel valued.
            </div>
          </div>
          <img
            className="content-partner__header-image show-desktop hide-phone"
            src="/legacy-images/partners/content-partners-medium.png"
          />
        </header>
        {partnerNodes}
      </div>
    </div>
  );
};

export default ContentPartners;
