// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import Analytics from './../../components/Analytics';
import Modal from './../../components/Modal';
import Store from './../../components/Store';

const store = {
  mtp_page_data: {},
  loadingArtifactConnections: true,
  openArtifactAfterLoad: {
    shouldOpen: false,
    artifactType: null,
    artifactId: null,
  },
  ui: {
    siblingDropdownActive: false,
    favorited: false,
    favoriteLoading: false,
    recommendLoading: false,
    participants: {
      inputStatus: 'blur',
      charCount: 0,
      selectedOption: null,
      options: [],
      visibleOptions: [],
    },
    recommendModal: {
      callbacks: {
        onOpen: (data) => {},
        onClose: () => undefined,
      },
    },
    // clicking on a file img opens the artifact overlay
    artifactModal: {
      callbacks: {
        onOpen: (data) => {
          store.clearOpenArtifactAfterLoad();
        },
        onClose: () => undefined,
      },
    },
    shareModal: {
      callbacks: {
        onOpen: (data) => {
          // Record the analytics event:
          Analytics.logEvent({
            category: 'share_modal',
            action: 'open',
            label: 'from_blended_video',
          });
        },
        onClose: () => {
          // Record the analytics event:
          Analytics.logEvent({
            category: 'share_modal',
            action: 'close',
            label: 'from_blended_video',
          });
        },
      },
    },
  },
};
/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

const setAlert = (message, type) => {
  store.ui.alert = {
    type: type,
    message: message,
    handleClose: store.clearAlert,
  };
};

store.init = (data) => {
  store.mtp_page_data = data.mtp_page_data;
  // if the page has a resource url open it after the connections are collected
  if (data.mtp_page_data.navigate_to_resource_id) {
    store.openArtifactAfterLoad.shouldOpen = true;
    store.openArtifactAfterLoad.artifactType = 'Resource';
    store.openArtifactAfterLoad.artifactId =
      data.mtp_page_data.navigate_to_resource_id;
  }

  store.ui.alert = {};
  store.ui.favorite = data.favorite ? data.favorite : { status: 'unfavorited' };
  store.ui.artifactModal = Modal(store, store.ui.artifactModal);
  // Init modals
  store.ui.recommendModal = Modal(store, store.ui.recommendModal);
  store.ui.shareModal = Modal(store, store.ui.shareModal);
  store.ui.participants.options = data.participants;
  return false;
};
store.connectionsGatherComplete = () => {
  store.loadingArtifactConnections = false;
};
store.updateResourceConnections = (data) => {
  data.resource.connections = data.connections;
};
store.clearOpenArtifactAfterLoad = () => {
  store.openArtifactAfterLoad.shouldOpen = false;
  store.openArtifactAfterLoad.artifactType = null;
  store.openArtifactAfterLoad.artifactId = null;
};

store.closeSiblingsDropdown = () => {
  store.ui.siblingDropdownActive = false;
  document.removeEventListener('click', store.closeSiblingsDropdown);
};
store.toggleSiblingsDropdown = (feature) => {
  // open appropreate dropdown
  store.ui.siblingDropdownActive = !store.ui.lessonDropdownActive;
  // add listner to close the dropdown from anywhere click

  delayedEventListener(() =>
    document.addEventListener('click', store.closeSiblingsDropdown)
  );
};
export default Store('BlendedStrategyStore', store);
