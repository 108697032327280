// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import AbsoluteAlert from './../../../Alerts/Absolute';
import NavigationLink from './../../../NavigationLink';
import ScaleCenterImage from './../../../ScaleCenterImage';
import AbridgeText from './../../Common/AbridgeText';
import ProfileImageUpload from './../../Common/ProfileImageUpload';
import Subheader from './../modules/PortfolioManagementSubheader';
import OutcomeRow from './modules/OutcomeRow';

const PortfolioManagementHome = (props) => {
  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // convenience let for participant name
  const name =
    props.participant.user.first_name + ' ' + props.participant.user.last_name;
  const vision = props.participant.vision;
  const designSummary = props.participant.design_summary;

  // only link to the Manage Vision page if the participant has data there
  let visionNode = (
    <div className="tc__center-text" style={{ marginTop: 20 }}>
      <NavigationLink
        className="tc__link"
        url={
          '/coaching/lab/participant/' +
          props.participant.id +
          '/vision?from=ppd3_participant_portfolio_manage_UI'
        }
      >
        Add your vision
      </NavigationLink>
    </div>
  );
  if (vision || designSummary || props.vision_artifact_count) {
    let editAlignClass = '';
    let visionText = null;
    if (vision) {
      visionText = (
        <div className="p-portfolio-management__vision-text">
          <AbridgeText
            maxHeight={90}
            className="tc__body-copy ckeditor__output"
          >
            {vision}
          </AbridgeText>
        </div>
      );
    } else if (designSummary) {
      visionText = (
        <div className="p-portfolio-management__vision-text">
          <AbridgeText
            maxHeight={80}
            className="tc__body-copy ckeditor__output"
          >
            {designSummary}
          </AbridgeText>
        </div>
      );
    } else {
      editAlignClass = 'p-portfolio-management__vision-edit-centered';
    }

    visionNode = (
      <div className="row">
        <div className="cute-8-desktop cute-12-phone center-desktop">
          <div className="p-portfolio-management__vision">My Vision</div>
          {visionText}
          <div className={editAlignClass}>
            <NavigationLink
              className="tc__link"
              url={
                '/coaching/lab/participant/' +
                props.participant.id +
                '/portfolio/vision?from=ppd3_participant_portfolio_manage_UI'
              }
            >
              Manage Vision
            </NavigationLink>
          </div>
        </div>
      </div>
    );
  }

  let institutionNode = null;
  if (props.participant.institution) {
    institutionNode = (
      <div className="p-portfolio-management__institution">
        {props.participant.institution.name}
      </div>
    );
  }

  // set up the list of the participant's active outcomes
  let outcomesNode = null;
  if (props.outcomes && props.outcomes.length) {
    outcomesNode = (
      <div>
        <div className="p-portfolio-management__outcome-header">Outcomes</div>
        {props.outcomes.map((outcome, i) => {
          return (
            <OutcomeRow
              key={'outcome-' + outcome.id}
              participantId={props.participantId}
              outcome={outcome}
              hiddenEntryIds={props.hiddenEntryIds}
              toggleOutcomeVisibility={props.toggleOutcomeVisibility}
              noBottomBorder={i == props.outcomes.length - 1}
            />
          );
        })}
      </div>
    );
  }

  // set up default user photo
  let userPhotoNode = (
    <div className="participant-profile__photo-mask">
      <img
        className="participant-profile__photo"
        src="/legacy-images/default-user.svg"
      />
    </div>
  );
  // if the user photo exists display the user's image
  if (props.participant.user.photo) {
    userPhotoNode = (
      <ScaleCenterImage
        src={props.participant.user.photo}
        className="participant-profile__photo"
        noAdjustments={true}
      />
    );
  }

  return (
    <div className="p-portfolio-management__home">
      <Header page="ParticipantPortfolioManagement" {...props} />
      <Subheader
        participantId={props.participant.id}
        participantName={name}
        portfolioUuid={props.portfolio.uuid}
        shareModal={props.ui.shareModal}
        setUrlCopied={props.setShareUrlCopied}
      />
      {alertNode}
      <div className="row">
        <div className="cute-12-desktop cute-12-phone">
          <ProfileImageUpload
            participant={props.participant.user}
            user={props.user}
            updateProfileImageUploadProgress={
              props.updateProfileImageUploadProgress
            }
            afterUploadCallback={props.updateUserPhoto}
            uploadMessage={props.ui.uploadMessage}
            setUploadMessage={props.setUploadMessage}
            clearUploadMessage={props.clearUploadMessage}
            uploadLoading={props.ui.uploadLoading}
            className="participant-profile__photo-mask participant-profile__photo-mask--upload"
          >
            {userPhotoNode}
          </ProfileImageUpload>
        </div>
      </div>
      <div className="row">
        <div className="p-portfolio-management__headline cute-8-desktop cute-12-phone center-desktop">
          {name}
        </div>
        <div className="p-portfolio-management__institution">
          {institutionNode}
        </div>
      </div>
      {visionNode}
      <div>{outcomesNode}</div>
    </div>
  );
};

PortfolioManagementHome.propTypes = {
  participant: PropTypes.object.isRequired,
  visionArtifactCount: PropTypes.number,
  outcomes: PropTypes.array,
};

export default PortfolioManagementHome;
