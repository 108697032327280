// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import PartnerApi from '@legacy-components/apis/PartnerApi';
import Utils from '@legacy-components/components/DynamicUtils';
import StorageHelper from '@legacy-components/components/StorageHelper';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import Breadcrumb from '@legacy-components/react/V5/Common/Breadcrumb';
import AddExistingPartnerLeadModal from '@legacy-components/react/V5/Common/PartnerLeadModals/AddExistingPartnerLeadModal';
import AddNewPartnerLeadModal from '@legacy-components/react/V5/Common/PartnerLeadModals/AddNewPartnerLeadModal';
import DeletePartnerLeadModal from '@legacy-components/react/V5/Common/PartnerLeadModals/DeletePartnerLeadModal';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PartnerLeaderRow from './modules/PartnerLeaderRow';
import PartnerLeaderSurveyDetails from './modules/PartnerLeaderSurveyDetails';
import PartnerLeadersHeader from './modules/PartnerLeadersHeader';

const PartnerLeadersContainer = (props) => {
  const lab = useLegacyLabContext();
  const [leaders, setLeaders] = useState(props.leaders);
  const [loadingLeader, setLoadingLeader] = useState(null);
  const [sortDirection, setSortDirection] = useState(
    StorageHelper.getItem('leader_sort_order') || 'asc'
  );
  const [sortColumn, setSortColumn] = useState(
    StorageHelper.getItem('leader_sort_column') || 'name'
  );
  const [status, setStatus] = useState(props.partner.status || 'All');
  const [modalOpen, setModalOpen] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = React.useState({});

  const resendPartnerLeaderSurvey = (leaderId) => {
    // clone the leaders state in order to set it later.
    const localLeaders = [...leaders];

    // assert who is getting the email
    const leaderIndex = Utils.getItemIndexFromArrayOfObjects(
      localLeaders,
      'id',
      leaderId
    );

    // loading state
    setLoadingLeader(leaderId);

    // just in case
    if (leaderIndex === -1) {
      props.setAlert({
        type: 'error',
        message: 'No leader record found',
      });
      return;
    }

    return PartnerApi.sendPartnerLeaderSurvey({
      partnerId: props.partner.id,
      leaderId: leaderId,
    })
      .then((response) => {
        // update the leaders with new date time
        response.sent_surveys.forEach((sentSurvey) => {
          localLeaders[leaderIndex].surveySent = sentSurvey.survey_email_sent;
          setLeaders(localLeaders);
        });

        // clear loading
        setLoadingLeader(null);
        props.setAlert({
          type: 'success',
          message: `Survey resent to ${leaders[leaderIndex].name}.`,
        });
      })
      .catch(() => {
        // clear loading
        setLoadingLeader(null);

        // Show the error
        props.setAlert({
          type: 'error',
          message: 'Something went wrong, try again later.',
        });
      });
  };

  const addNewPL = (data) => {
    setLoading(true);
    // clone the leaders state in order to set it later.
    const localLeaders = [...leaders];

    PartnerApi.addNewPL(data).then(
      (response) => {
        setLoading(false);
        // show success
        setAlert({
          type: 'success',
          message: 'Partner leader added to partnership',
          handleClose: () => setAlert({}),
        });
        setLeaders(localLeaders.concat(response.partnerLead));
        data.successCallback();
      },
      (response) => {
        setLoading(false);
        if (response.type === 'email') {
          data.emailFailureCallback(response.error);
        } else {
          data.failureCallback(response.error);
        }
      }
    );
  };

  const addExistingPL = (data) => {
    setLoading(true);
    // clone the leaders state in order to set it later.
    const localLeaders = [...leaders];

    PartnerApi.addExistingPL(data).then(
      (response) => {
        setLoading(false);
        // show success
        setAlert({
          type: 'success',
          message: `${
            response.partnerLead.name || 'Partner leader'
          } added to partnership`,
          handleClose: () => setAlert({}),
        });
        setLeaders(localLeaders.concat(response.partnerLead));
        data.successCallback();
      },
      (response) => {
        setLoading(false);
        if (response.type === 'email') {
          data.emailFailureCallback(response.error);
        } else {
          data.failureCallback(response.error);
        }
      }
    );
  };

  const deletePl = (data) => {
    setLoading(true);
    // clone the leaders state in order to set it later.
    const localLeaders = [...leaders];

    PartnerApi.deletePartnerLeader(data).then(
      (response) => {
        setLoading(false);
        let leaderName = 'Partner leader';
        if (response.partnerLead && response.partnerLead.user) {
          leaderName = `${response.partnerLead.user.first_name} ${response.partnerLead.user.last_name}`;
        }
        // show success
        setAlert({
          type: 'success',
          message: `${leaderName} removed from partnership`,
          handleClose: () => setAlert({}),
        });

        setLeaders(
          localLeaders.filter((leader) => leader.id !== response.partnerLead.id)
        );
        data.successCallback();
      },
      (response) => {
        setLoading(false);
        data.failureCallback(response.error);
      }
    );
  };

  // Wrapper function to sort on null values
  const sortWithLead = (func) => {
    // This makes sure Partner level leads always come first
    const partnersFirstOrLast = sortDirection === 'asc' ? 1 : -1;
    return (a, b) => {
      if (a.is_partner_pl && b.is_partner_pl) {
        return func(a, b);
      } else if (a.is_partner_pl) {
        return -1 * partnersFirstOrLast;
      } else if (b.is_partner_pl) {
        return 1 * partnersFirstOrLast;
      } else {
        return func(a, b);
      }
    };
  };

  const sortSurveyStatus = (a, b) => {
    if (a.surveyCompleted && b.surveyCompleted) {
      return 0;
    } else if (a.surveyCompleted) {
      return -1;
    } else if (b.surveyCompleted) {
      return 1;
    } else {
      return 0;
    }
  };

  // Wrapper function to sort on null values
  const sortWithNull = (func, key) => {
    return (a, b) => {
      if (a && a[key]) {
        if (b && b[key]) {
          return func(a, b);
        }
        return -1;
      } else if (b && b[key]) {
        return 1;
      } else {
        return 0;
      }
    };
  };

  const sortSurveySent = (a, b) => {
    return a.surveySent - b.surveySent;
  };

  const sortFunctions = {
    name: sortWithLead(
      sortWithNull((a, b) => a.name.localeCompare(b.name), 'name')
    ),
    survey: sortSurveyStatus,
    surveySent: sortWithNull(sortSurveySent, 'surveySent'),
  };

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortColumn) {
      StorageHelper.setItem('leader_sort_order', sortOrder[sortDirection]);
      setSortDirection(sortOrder[sortDirection]);
    } else {
      StorageHelper.setItem('leader_sort_order', 'asc');
      StorageHelper.setItem('leader_sort_column', column);
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Sort order indication arrow
  let sortIndicator = null;
  const sortedLeaders = [...leaders];
  if (sortDirection === 'asc') {
    sortedLeaders.sort(sortFunctions[sortColumn]);
    sortIndicator = (
      <div className="tc-short-arrow ppp-v3-curation__sort ppp-v3-curation__sort--down"></div>
    );
  } else if (sortDirection === 'desc') {
    sortedLeaders.sort(sortFunctions[sortColumn]);
    sortedLeaders.reverse();
    sortIndicator = (
      <div className="tc-short-arrow ppp-v3-curation__sort ppp-v3-curation__sort--up"></div>
    );
  }

  // filter the list of PLs based on the status of their cohorts/events
  const filteredLeaders = sortedLeaders.filter((lead) => {
    if (status === 'Past') {
      return lead.isPast;
    } else if (status === 'Current') {
      return lead.isCurrent;
    } else {
      return true;
    }
  });

  const isDataViewer = Utils.isDataViewer.call(lab);

  const leaderRows = filteredLeaders.map((leader, i) => {
    return (
      <PartnerLeaderRow
        key={`props.leader-row-${leader.id}-${i}`}
        partnerId={props.partner.id}
        leader={leader}
        isDv={isDataViewer}
        deletePl={() =>
          setModalOpen({
            type: 'delete_pl',
            leaderId: leader.id,
            leaderName: leader.name,
          })
        }
      >
        <PartnerLeaderSurveyDetails
          leaderId={leader.id}
          timeZone={props.user.time_zone.pytz_timezone}
          surveySent={leader.surveySent}
          loading={leader.id === loadingLeader}
          resendPartnerLeaderSurvey={resendPartnerLeaderSurvey}
        />
      </PartnerLeaderRow>
    );
  });

  let emptyLeadersNode;
  if (!filteredLeaders.length) {
    emptyLeadersNode = (
      <div className="partners__empty-search row">
        No partner leaders match your filters.
      </div>
    );
  }

  const breadcrumb = [
    {
      text: 'Partners',
      link: '/bl/partners',
    },
    {
      text: `${props.partner.name}`,
    },
  ];

  let modalNode = null;
  if (modalOpen.type === 'add_new_pl') {
    modalNode = (
      <AddNewPartnerLeadModal
        close={() => setModalOpen({})}
        occupations={props.occupations}
        addNewPL={addNewPL}
        partnerId={props.partner.id}
        isLoading={loading}
        addExisting={() => setModalOpen({ type: 'add_existing_pl' })}
        title={props.partner.name}
      />
    );
  } else if (modalOpen.type === 'add_existing_pl') {
    modalNode = (
      <AddExistingPartnerLeadModal
        currentLeads={sortedLeaders}
        close={() => setModalOpen({})}
        addExistingPL={addExistingPL}
        partnerId={props.partner.id}
        isLoading={loading}
        addNew={() => setModalOpen({ type: 'add_new_pl' })}
        title={props.partner.name}
      />
    );
  } else if (modalOpen.type === 'delete_pl') {
    modalNode = (
      <DeletePartnerLeadModal
        close={() => setModalOpen({})}
        deletePl={deletePl}
        partnerId={props.partner.id}
        partnerName={props.partner.name}
        partnerLeaderId={modalOpen.leaderId}
        partnerLeaderName={modalOpen.leaderName}
        isLoading={loading}
      />
    );
  }

  // set up any alert banner
  let alertNode = null;
  if (Object.keys(alert).length) {
    alertNode = (
      <AbsoluteAlert
        {...alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  let failureNode;
  if (props.search_failed) {
    failureNode = (
      <React.Fragment>
        <div className="v5__registered-table-details v5__heading-5 row home-v5__empty-activity-capsule">
          <div>
            <img
              src="/legacy-images/404-pets.png"
              className="code-404__img-overview"
            />
          </div>
          <div className="home-v5__empty-activity-row">
            <div className="v5__heading-4">Oops! Something is broken.</div>
            <div className="v5__comment-copy">
              <div>
                Our partner tools are temporarily unavailable. (We blame the
                cat.)
              </div>
              <div>Thanks for your patience as we tidy up this issue.</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="tc__page">
      <Header page="PartnerLeaders" {...props} />
      {alertNode}
      {modalNode}
      <div className="row row--1200 participant-v5__breadcrumb">
        <div className="cute-12-desktop">
          <Breadcrumb trail={breadcrumb} divider=" / " />
        </div>
      </div>
      <PartnerLeadersHeader
        partnerName={props.partner.name}
        sortIndicator={sortIndicator}
        toggleSortDirection={toggleSortDirection}
        sortColumn={sortColumn}
        status={status}
        setStatus={(e) => setStatus(e.target.value)}
        openModal={setModalOpen}
      >
        {leaderRows}
      </PartnerLeadersHeader>
      {emptyLeadersNode}
      {failureNode}
    </div>
  );
};

const ConnectedPartnerLeadersContainer = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => (
      <PartnerLeadersContainer {...props} setAlert={setAlert} />
    )}
  </HeaderConsumer>
);

export default ConnectedPartnerLeadersContainer;

PartnerLeadersContainer.propTypes = {
  leaders: PropTypes.array.isRequired,
  partner: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
