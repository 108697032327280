// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const NotificationCountBadge = (props) => {
  // we don't show the badge if the count is zero
  if (!props.count) {
    return null;
  }

  const clickHandler = props.clickHandler
    ? props.clickHandler
    : () => undefined;

  // TODO: we will prob want to build an absolutely positioned version of this,
  // which can be selected via a prop
  return (
    <div
      className={`notification-count-badge ${props.className}`}
      onClick={clickHandler}
    >
      {props.count > 9 ? '9+' : props.count}
    </div>
  );
};

NotificationCountBadge.propTypes = {
  count: PropTypes.number,
  clickHandler: PropTypes.func,
  className: PropTypes.string,
};

NotificationCountBadge.defaultProps = {
  className: '',
};

export default NotificationCountBadge;
