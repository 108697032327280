// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../components/DynamicUtils';
import S3 from './../../../components/S3';

const ContentResource = (props) => {
  let completedFiles = 0;
  let inputFiles = [];
  const imagePreviewNode = null;
  let imageUploadNode = null;

  const handleDelete = (e) => {
    e.preventDefault();
    // Clear existing alerts
    props.clearAlert();
    props.openConfirmDeleteImageModal({
      title: 'Strategy image',
    });
  };

  const handleFilesChange = (e) => {
    e.preventDefault();
    // Clear existing alerts
    props.clearAlert();
    inputFiles = e.target.files;
    completedFiles = 0;
    submitFiles();
  };

  const getUploadProgressNode = () => {
    let progressNode = null;
    let progressFileNameNode = null;

    if (
      props.file &&
      props.file.status == 'during' &&
      props.file.percent < 100
    ) {
      const progressFileNameClass =
        props.preview !== undefined
          ? ' ppd__v2-curation-strategy-artifact__upload-title--replace'
          : '';

      progressFileNameNode = (
        <div className="ppd__v2-curation-strategy-upload-file-name">
          <span className="artifact__type-icon file-type-icon" />
          <span
            className={
              'artifact__upload-title ppd__v2-curation-strategy-artifact__upload-title' +
              progressFileNameClass
            }
          >
            {props.file.name}
          </span>
        </div>
      );

      progressNode = (
        <div
          key="progress"
          className="ppd__v2-curation-strategy-upload-status-indicator"
        >
          <div className="artifact__upload-bar">
            <div
              className="artifact__upload-progress"
              style={{ width: props.file.progress + '%' }}
            />
          </div>
          {progressFileNameNode}
        </div>
      );
    }
    return progressNode;
  };

  const submitFiles = () => {
    let file;

    if (props.startUploadCallback) {
      props.startUploadCallback();
    }
    for (let i = 0; i < inputFiles.length; i++) {
      // Add some of the POST fields that the backend needs
      file = {
        lastModified: inputFiles[i]['lastModified'],
        lastModifiedDate: inputFiles[i]['lastModifiedDate'],
        name: inputFiles[i]['name'],
        size: inputFiles[i]['size'],
        type: inputFiles[i]['type'],
        uuid: Utils.uuid(),
      };
      // max size is 1024MB
      if (file.size > 1024000000) {
        props.errorCallback('Maximum upload size is 1GB');
      } else {
        inputFiles[i]['uuid'] = file['uuid'];
        const callbackFields = {
          sectionId: props.sectionId,
        };
        // Upload the file to S3
        S3.flexibleUpload(
          inputFiles[i],
          props.beforeUploadCallback,
          props.uploadCallback,
          props.afterUploadCallback,
          props.cleanupCallback,
          callbackFields
        ).then(
          function onSuccess(fileData) {
            fileData['message'] = 'Upload Complete';
            fileData['status'] = 'after';
            props.setPreview(fileData['preview']);
          },
          function onError(fileData) {
            // See if the file has the error property
            let error = fileData['error'];
            if (!error) {
              error = 'There was an error uploading your file.';
            }
            props.errorCallback(error);
            // Clear the file from the store
            props.updateHeroUploadProgress(null);
          },
          function onProgress(fileData) {
            // If there is an error, abort
            if (fileData.status === 'error') {
              return;
            }
            fileData['message'] = fileData.progress + '%';
            fileData['status'] = 'during';
            fileData['percent'] = fileData.progress;
            props.updateHeroUploadProgress(fileData);
          }
        );
      }
    }
  };

  const getUploadNode = (label) => {
    let uploadNode = (
      <div>
        <input
          id="addFileResource"
          type="file"
          className="artifact__upload-field artifact__upload-field--v3-curation"
          onChange={handleFilesChange}
          multiple={props.multiple}
          value=""
        />

        <div className="tc__error tc__error--upload">{props.error}</div>
      </div>
    );

    if (label == 'Replace') {
      uploadNode = (
        <div className="ppd__v2-curation-strategy-image-upload-icon tc-upload">
          <input
            id="addFileResource"
            type="file"
            className="artifact__upload-field artifact__upload-field--v3-curation"
            onChange={handleFilesChange}
            multiple={props.multiple}
            value=""
          />

          <div className="tc__error tc__error--upload">{props.error}</div>
        </div>
      );
    }
    return uploadNode;
  };

  imageUploadNode = getUploadNode('Upload');

  // Get the upload progress node
  const uploadProgressNode = getUploadProgressNode();

  return (
    <div className="cute-8-desktop left">
      <div className="">{uploadProgressNode}</div>
      <div className="ppd-v3-curation__strategy-upload-image-container">
        {imageUploadNode}
      </div>
    </div>
  );
};

ContentResource.propTypes = {
  multiple: PropTypes.bool.isRequired,
  updateHeroUploadProgress: PropTypes.func.isRequired,
  clearAlert: PropTypes.func.isRequired,
  beforeUploadCallback: PropTypes.func.isRequired,
  uploadCallback: PropTypes.func.isRequired,
  afterUploadCallback: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
};

export default ContentResource;
