// @ts-nocheck FIXME
import Request from './../../components/Request';

const ZoomApi = {
  deleteTokens: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/coach/zoom_oauth2_delete_tokens`,
      JSON.stringify(data)
    );
  },
};

export default ZoomApi;
