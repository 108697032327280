// @ts-nocheck FIXME
import ArtifactSpotlight from './Elements/ArtifactSpotlight';
import CoachingSpotlight from './Elements/CoachingSpotlight';
import DataPoint from './Elements/DataPoint';
import Kpi from './Elements/Kpi';
import NarrativeSpotlight from './Elements/NarrativeSpotlight';
import QuoteSpotlight from './Elements/QuoteSpotlight';
import StrategySpotlight from './Elements/StrategySpotlight';
import TeacherSpotlight from './Elements/TeacherSpotlight';
import TextElem from './Elements/TextElem';

const components = {
  Kpi: Kpi,
  Text: TextElem,
  Datapoint: DataPoint,
  Quote: QuoteSpotlight,
  TeacherSpotlight: TeacherSpotlight,
  StrategySpotlight: StrategySpotlight,
  ArtifactSpotlight: ArtifactSpotlight,
  NarrativeSpotlight: NarrativeSpotlight,
  CoachingSpotlight: CoachingSpotlight,
};

const Elements = (props) => {
  const entryComponents = props.entries.map((entry, i) => {
    const EntryComponent = components[entry.type];
    if (entry.visible) {
      return (
        <EntryComponent
          {...entry}
          key={'entry-' + entry.type + '-' + i}
          tab={props.tab}
          showArtifact={props.showArtifact}
          portfolio_guid={props.portfolio_guid}
          showDetailed={props.showDetailed}
        />
      );
    }
  });

  return <div>{entryComponents}</div>;
};

export default Elements;
