// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Analytics from './../../../components/Analytics';
import Spinner from './../Common/Spinner';

const NameStep = (props) => {
  let firstName, lastName, role, yearsTeaching;
  const formFieldErrorClasses = {},
    formFieldErrorMessages = {};
  const formFieldNames = [
    'firstName',
    'lastName',
    'role',
    'yearsTeaching',
    'isReturning',
    'coachPreference',
  ];

  formFieldNames.forEach((fieldName) => {
    formFieldErrorClasses[fieldName] = '';
    formFieldErrorMessages[fieldName] = null;
    if (fieldName in props.ui.errors) {
      formFieldErrorClasses[fieldName] = 'error';
      formFieldErrorMessages[fieldName] = (
        <div className="v5__error">{props.ui.errors[fieldName]}</div>
      );
    }
  });

  // handle is_returning in state so that we can properly show/hide the coach pref question
  const [isReturning, setIsReturning] = React.useState(
    props.participant.previous_coach_name ? 1 : 0
  );
  const [coachPreference, setCoachPreference] = React.useState(
    props.participant.coach_preference
  );

  const handleYearsTeachingKeyDown = (e) => {
    // do not allow "e", "-", or "." to be typed in the number input
    if ([69, 189, 190].indexOf(e.keyCode) !== -1) {
      e.preventDefault();
    }
  };

  const handleChangeIsReturning = (e) => {
    e.preventDefault();

    const val = e.target.value;
    // IsReturing is loaded as an int by the db while if it's changed on the page it is a string
    // This can lead to inconsistent behavior if ints aren't parsed
    setIsReturning(parseInt(val));

    if (val) {
      Analytics.logEvent({
        category: 'returning_user',
        action: val === 0 ? 'no' : 'yes',
        label: 'from_first_page_user_welcome',
      });
    }
    setCoachPreference(null);
    props.clearErrorsOnFocus('isReturning');
  };

  const handleChangeCoachPreference = (e) => {
    e.preventDefault();
    setCoachPreference(e.target.value);
    props.clearErrorsOnFocus('coachPreference');
  };

  const handleFocus = (e, field, analyticsCategory, analyticsAction) => {
    e.preventDefault();
    if (field !== 'role') {
      Analytics.logEvent({
        category: analyticsCategory,
        action: analyticsAction || 'edit',
        label: 'from_first_page_user_welcome',
      });
    }

    props.clearErrorsOnFocus(field);
  };

  const handleRoleChange = () => {
    if (!role.value) {
      return;
    }
    const roles = ['teacher', 'coach', 'principal'];
    Analytics.logEvent({
      category: 'role',
      action: 'select_' + roles[role.value - 1],
      label: 'from_first_page_user_welcome',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Analytics.logEvent({
      category: 'next',
      action: 'click',
      label: 'from_first_page_user_welcome',
    });

    const data = {
      firstName: firstName.value,
      lastName: lastName.value,
      role: role.value,
      isReturning: isReturning,
      coachPreference: coachPreference,
      yearsTeaching: yearsTeaching.value,
    };

    props.submit(data);
  };
  const previousCoachNode = props.participant.previous_coach_name && (
    <span className="registration__previous-coach-name">
      Your previous coach: {props.participant.previous_coach_name}
    </span>
  );

  // set up role select
  const roleOptionsNode = props.roles.map((roleObj) => {
    let additionalInfo;
    switch (roleObj.id) {
      case 1:
        additionalInfo = ' (Supporting students in the classroom)';
        break;
      case 2:
        additionalInfo = ' (Supporting other educators)';
        break;
      case 3:
        additionalInfo = ' (Serving in school leadership)';
        break;
    }
    return (
      <option key={'roleoption-' + roleObj.id} value={roleObj.id}>
        {roleObj.name}
        {additionalInfo}
      </option>
    );
  });
  roleOptionsNode.unshift(
    <option key={'roleoption-0'} value="">
      Select a Role
    </option>
  );

  // only show the coach preference question if the participant had a BL coach last year
  let coachPreferenceNodes;
  if (parseInt(isReturning) === 1) {
    coachPreferenceNodes = (
      <React.Fragment>
        <div className="registration__headline tc__headline-2">
          If scheduling allows, would you prefer to work with the same coach?{' '}
          {previousCoachNode}
        </div>
        <div className="registration__form-item">
          <select
            onChange={handleChangeCoachPreference}
            value={coachPreference}
            className={`registration__input tc__select ${formFieldErrorClasses.coachPreference}`}
            data-testid="participant-registration-coach-preference-select"
          >
            <option key="coach-pref-none" value="">
              Select One
            </option>
            {Object.keys(props.coachPreferences).map((optionId) => {
              return (
                <option key={`coach-pref-${optionId}`} value={optionId}>
                  {props.coachPreferences[optionId]}
                </option>
              );
            })}
          </select>
          {formFieldErrorMessages.coachPreference}
        </div>
      </React.Fragment>
    );
  }

  // disable buttons when form submission is loading
  let disabledClass = '';
  if (props.ui.loading) {
    disabledClass = ' disabled';
  }

  return (
    <div className="row row--1200 registration__primary">
      <form className="cute-6-desktop cute-12-phone center-desktop">
        <div className="registration__headline tc__headline-2">
          Can you confirm your personal information?
        </div>
        <div className="registration__form-item">
          <div className="registration__input-label">First Name</div>
          <input
            type="text"
            ref={(ref) => (firstName = ref)}
            defaultValue={props.participant.user.first_name}
            onFocus={(e) => handleFocus(e, 'firstName', 'first_name')}
            className={`registration__input v5__input ${formFieldErrorClasses.firstName}`}
          />
          {formFieldErrorMessages.firstName}
        </div>
        <div className="registration__form-item">
          <div className="registration__input-label">Last Name</div>
          <input
            type="text"
            ref={(ref) => (lastName = ref)}
            defaultValue={props.participant.user.last_name}
            onFocus={(e) => handleFocus(e, 'lastName', 'last_name')}
            className={`registration__input v5__input ${formFieldErrorClasses.lastName}`}
          />
          {formFieldErrorMessages.lastName}
        </div>

        <div className="registration__form-item">
          <div className="registration__headline tc__headline-2">
            What role will be the primary focus of your BetterLesson coaching?
          </div>
          <select
            ref={(ref) => (role = ref)}
            defaultValue={props.participant.role}
            onFocus={(e) => handleFocus(e, 'role', 'role', 'select')}
            onChange={(e) => handleRoleChange(e, 'role', 'role', 'select')}
            className={`registration__input tc__select ${formFieldErrorClasses.role}`}
          >
            {roleOptionsNode}
          </select>
          {formFieldErrorMessages.role}
        </div>

        <div className="registration__headline tc__headline-2">
          Prior to the current school year, how many years of teaching or
          leadership experience do you have?
        </div>
        <div className="registration__form-item">
          <div className="registration__input-label">
            If this is your first year in your role, enter 0
          </div>
          <input
            type="number"
            ref={(ref) => (yearsTeaching = ref)}
            defaultValue={props.participant.years_teaching}
            onFocus={(e) =>
              handleFocus(e, 'yearsTeaching', 'years_teaching', 'enter')
            }
            onKeyDown={handleYearsTeachingKeyDown}
            className={`registration__input registration__input--short v5__input ${formFieldErrorClasses.yearsTeaching}`}
          />
          {formFieldErrorMessages.yearsTeaching}
        </div>

        <div className="registration__headline tc__headline-2">
          Have you previously received one-on-one coaching with a BetterLesson
          Coach?
        </div>
        <div className="registration__form-item">
          <div className="registration__input-label">
            Design workshops, virtual workshops, learning walks, and other group
            learning experiences do not apply
          </div>
          <select
            value={isReturning}
            onChange={handleChangeIsReturning}
            className={`registration__input tc__select ${formFieldErrorClasses.isReturning}`}
            data-testid="participant-registration-is-returning-select"
          >
            <option value="">Select One</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {formFieldErrorMessages.isReturning}
        </div>

        {coachPreferenceNodes}

        <div className="registration__submit-btns">
          <button
            onClick={handleSubmit}
            className={'tc__btn tc__btn--flat-blue' + disabledClass}
          >
            <Spinner loading={props.ui.loading} />
            Next: Your School
          </button>
        </div>
      </form>
    </div>
  );
};

NameStep.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  clearErrorsOnFocus: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default NameStep;
