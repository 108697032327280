// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import React from 'react';
import ArrayHelpers from './../../../../../components/ArrayHelpers';
import Video from './../../../../Utilities/Video';
import AbridgeText from './../../../Common/AbridgeText';

const MeetingClipEntry = (props) => {
  let videoNode;
  const [width, setWidth] = React.useState(600);

  const changeWindowWidth = () => {
    const elem = document.querySelector(
      `#${props.entryId}.workspace__meeting-clip-entry-video`
    );
    let elemWidth;
    // verify that the element exists and if it does get it's width
    if (elem) {
      elemWidth = elem.offsetWidth;
    }
    if (elemWidth && width !== elemWidth) {
      if (elemWidth < 600) {
        setWidth(elemWidth);
      } else if (width < 600) {
        setWidth(600);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowWidth);
    changeWindowWidth();
    return () => window.removeEventListener('resize', changeWindowWidth);
  }, []);

  if (
    props.connections &&
    props.connections.content &&
    ArrayHelpers.hasElements(props.connections.content.formats)
  ) {
    if (props.smallView === true) {
      videoNode = (
        <div
          className="workspace__entry-video-container workspace__meeting-clip-entry-video"
          id={props.entryId}
        >
          <Video
            preview={props.connections.content.preview[0]}
            formats={props.connections.content.formats}
            height={(330 * 440) / 600}
            width={330}
            start={props.start_time}
            end={props.end_time}
          />
        </div>
      );
    } else {
      videoNode = (
        <div
          className="workspace__entry-video-container workspace__meeting-clip-entry-video"
          id={props.entryId}
        >
          <Video
            preview={props.connections.content.preview[0]}
            formats={props.connections.content.formats}
            height={(width * 440) / 600}
            width={width}
            start={props.start_time}
            end={props.end_time}
          />
        </div>
      );
    }
  }

  let descriptionNode;
  if (props.description && !props.smallView) {
    let toggle = true;
    if (props.toggle === false) {
      toggle = false;
    }
    descriptionNode = toggle ? (
      <AbridgeText
        maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
        isExpandable={true}
        className="workspace__entry-image-description v5__body-copy"
        greyFade={props.greyFade}
      >
        {props.description}
      </AbridgeText>
    ) : (
      props.description
    );
  }

  return (
    <div className="workspace__entry-body workspace__entry-image-row workspace__entry-meeting-clip-row">
      {videoNode}
      {descriptionNode}
    </div>
  );
};

MeetingClipEntry.propTypes = {
  connections: PropTypes.object,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  smallView: PropTypes.bool,
  setTruncateState: PropTypes.func,
  toggle: PropTypes.bool,
  greyFade: PropTypes.bool,
};

export default MeetingClipEntry;
