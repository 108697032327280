// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import PropTypes from 'prop-types';
import React from 'react';

const CoachMatchAction = (props) => {
  const reasonDeclined = React.useRef();

  const [understood, setUnderstood] = React.useState(false);
  const handleUnderstandChange = () => {
    setUnderstood(!understood);
  };

  const handleAcceptClick = () => {
    if (props.coachMatchActionLoading) {
      return;
    }
    props.setCoachMatchActionError(null);
    if (!understood) {
      props.setCoachMatchActionError(
        'Confirm you understand the Scope of Work for this match.'
      );
      return;
    }
    props.setCoachMatchActionLoading(true);
    props.updateMatch({
      match_id: props.id,
      status: 'Accepted',
    });
  };

  const handleDeclineClick = () => {
    if (props.coachMatchActionLoading) {
      return;
    }
    props.setCoachMatchActionError(null);
    if (!reasonDeclined.current.value) {
      props.setCoachMatchActionError(
        'You must provide a reason for declining the match.'
      );
      return;
    }
    props.setCoachMatchActionLoading(true);
    props.updateMatch({
      match_id: props.id,
      status: 'Rejected',
      status_note: reasonDeclined.current.value,
    });
  };

  const handleRemoveClick = () => {
    if (props.coachMatchActionLoading) {
      return;
    }
    props.setCoachMatchActionError(null);
    props.setCoachMatchActionLoading(true);
    props.removeParticipant({
      match_id: props.id,
      participantId: props.participant.id,
      status: 'Removed',
    });
  };

  let headline = 'Accept Match';
  let buttonNode = (
    <button
      className={`coach-match__btn v5__btn${
        understood && !props.coachMatchActionLoading ? '' : ' disabled'
      }`}
      onClick={handleAcceptClick}
    >
      <Spinner loading={props.coachMatchActionLoading} />
      Accept
    </button>
  );
  let modalContentNode = (
    <React.Fragment>
      <section className="coach-match__accept-copy">
        By clicking Accept, you are agreeing to become the coach of
        <span>
          {' '}
          {props.user.first_name} {props.user.last_name}{' '}
        </span>
        under the terms outlined in the Scope of Work document.
      </section>
      <section className="coach-match__understood">
        <input type="checkbox" onChange={handleUnderstandChange} />
        <span className="coach-match__understood-label">
          I have read and understand the{' '}
          <a
            href={`https://betterlesson.quickbase.com/db/brnrgu2cx?a=dr&dfid=10&rid=${props.cb_id}`}
            className="v5__link"
          >
            Scope of Work
          </a>
        </span>
      </section>
    </React.Fragment>
  );
  if (props.action === 'decline') {
    headline = 'Decline Match';
    modalContentNode = (
      <React.Fragment>
        <div className="coach-match-action-label v5__label">
          Reason for declining this match
          <span className="coach-match__required">*</span>
        </div>
        <textarea
          ref={reasonDeclined}
          className="v5__input coach-match-action-input"
        />
      </React.Fragment>
    );
    buttonNode = (
      <button
        className={`coach-match__btn v5__btn v5__btn--red${
          !props.coachMatchActionLoading ? '' : ' disabled'
        }`}
        onClick={handleDeclineClick}
      >
        <Spinner loading={props.coachMatchActionLoading} />
        Decline
      </button>
    );
  } else if (props.action === 'remove') {
    headline = 'Remove Participant';
    modalContentNode = (
      <React.Fragment>
        <div className="coach-match-action-label v5__label">
          Reason for Request
        </div>
        {props.cb_note}
      </React.Fragment>
    );
    buttonNode = (
      <button
        className={`coach-match__btn v5__btn v5__btn--red${
          !props.coachMatchActionLoading ? '' : ' disabled'
        }`}
        onClick={handleRemoveClick}
      >
        <Spinner loading={props.coachMatchActionLoading} />
        Remove Participant
      </button>
    );
  }

  let errorNode = null;
  if (props.coachMatchActionError) {
    errorNode = (
      <ErrorText
        errorContainerClass="v5__error-container--no-padding v5__error-container--align-left"
        errorClass="v5__error--above-btn-group"
        error={props.coachMatchActionError}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="v5__modal-header">
        {headline}
        <div className="tc-x v5__modal-close-icon" onClick={props.close} />
      </div>
      <div className="v5__modal-body v5__body-copy">
        <section className="coach-match__head coach-match__head--modal">
          <UserPhoto
            src={props.user.photo}
            userId={props.user.id}
            firstName={props.user.first_name}
            lastName={props.user.last_name}
            diameter={60}
          />
          <div className="coach-match__action-metadata">
            <div className="v5__heading-5">
              {props.user.first_name} {props.user.last_name}
            </div>
            <div>
              {props.participant.role || ''}, {props.user.institution || ''}
            </div>
            <div>
              {Utils.handleFormatDate(
                props.cohort.term_start,
                props.cohort.term_end
              )}
            </div>
          </div>
        </section>
        {modalContentNode}
        <section className="coach-match__btn-group coach-match__btn-group--modal">
          {errorNode}
          {buttonNode}
          <button
            className={`coach-match__btn v5__btn v5__btn--blue-border ${
              !props.coachMatchActionLoading ? '' : ' disabled'
            }`}
            onClick={props.close}
          >
            Cancel
          </button>
        </section>
      </div>
    </React.Fragment>
  );
};

export default CoachMatchAction;

CoachMatchAction.propTypes = {
  action: PropTypes.string.isRequired,
  participant: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  cohort: PropTypes.object.isRequired,
  updateMatch: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
