// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import HelpDeskLinkGateway from '@legacy-components/react/V5/Common/HelpDeskLinkGateway';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import EventAccountRegistration from '../modules/EventAccountRegistration';
import EventProductDescription from '../modules/EventProductDescription';
import ReservationForm from './modules/ReservationForm';
import ReservationSessions from './modules/ReservationSessions';

const EventReservation = (props) => {
  React.useEffect(() => {
    props.setResponsive(true);
    return () => props.setResponsive(false);
  }, []);

  const serviceImage = Utils.mapBLServiceNameToIllustration(
    props.event.service
  );
  const [selectedGroup, setSelectedGroup] = React.useState(
    props.event.event_groups.length && props.event.event_groups[0].id
  );
  const eventId = props.event.id;
  const helpCenterNode = (
    <HelpDeskLinkGateway headline="Need help with your RSVP?" />
  );

  const orgUuid = props.event.org_uuid ? props.event.org_uuid : null;

  const handleGoHome = () => {
    window.location = '/';
  };

  const timezone = props.event.time_zone.pytz_timezone;

  const isActiveUser = props.isLoggedIn || props.accountCreated;

  // default to the create user page
  let visibleForm;

  const reserveEvent = (data) => {
    data.event_id = eventId;
    props.eventReservation(data);
  };

  const getProductDescriptionForEarliestUpcomingSession = () => {
    const eventSessions = props.event.sessions ? props.event.sessions : [];
    const futureSessions = eventSessions.filter((session) =>
      moment(session.session_date_formatted).isSameOrAfter(moment.now())
    );
    if (!futureSessions || !futureSessions.length) {
      return null;
    }
    futureSessions.sort((sessionA, sessionB) => sessionA.num - sessionB.num);
    const earliestUpcomingSession = futureSessions[0];
    const sessionProduct = earliestUpcomingSession.ld_product;
    return sessionProduct ? sessionProduct.description : null;
  };

  if (isActiveUser) {
    visibleForm = <ReservationForm {...props} reserveEvent={reserveEvent} />;
  } else {
    visibleForm = (
      <div className="registration-v5-event__register">
        <div className="registration-v5-event__register-header v5__heading-3">
          Register
        </div>
        <div className="registration-v5-event__register-body v5__body-copy">
          <EventAccountRegistration action={'register'} orgUuid={orgUuid}>
            {helpCenterNode}
          </EventAccountRegistration>
        </div>
      </div>
    );
  }

  let alertNode;
  if (props.ui.errors.general && props.ui.errors.general.length) {
    alertNode = (
      <AbsoluteAlert
        preventAutoclose={props.ui.persistErrors}
        type="error"
        message={props.ui.errors.general[0]}
        handlelose={props.clearErrors}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  return (
    <div className="tc__page tc__page--registration">
      <div className="tc-header__wrap tc-header__wrap--v2">
        <div className="tc-header tc-header--v2 row row--responsive">
          <div
            className="logo tc-header__logo cute-3-desktop cute-3-phone left tc-header__registration-phone"
            onClick={handleGoHome}
            src="/legacy-images/betterlesson-logo.svg"
          ></div>
        </div>
      </div>
      {alertNode}
      <div className="row row--1200 registration-v5__container">
        <div className="cute-5-desktop cute-12-phone left registration-v5-event__event-image-column">
          <img
            src={`${serviceImage}`}
            className="code-404__img event-v5__headline-image"
          />
          <div className={'show-desktop hide-phone'}>
            <EventProductDescription
              description={getProductDescriptionForEarliestUpcomingSession()}
              action={'register'}
            />
          </div>
        </div>
        <div className={'cute-7-desktop cute-12-phone'}>
          <div className={'reservation__event-sessions'}>
            <div className="registration-v5-event__event-display-name v5__heading-1">
              {props.event.display_name}
            </div>
            <div className="registration-v5-event__event-partner-name v5__heading-4">
              {props.event.partner && props.event.partner.name}
            </div>
            <div className="reservation__session-list">
              <ReservationSessions
                sessions={props.event.sessions}
                eventGroups={props.event.event_groups}
                advancedEvent={props.event.advanced_event}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                timezone={timezone}
              />
            </div>
          </div>
          <div>{visibleForm}</div>
        </div>
      </div>
    </div>
  );
};

EventReservation.propTypes = {
  ui: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};

const ConnectedEventReservation = (props) => (
  <HeaderConsumer>
    {({ setResponsive }) => (
      <EventReservation {...props} setResponsive={setResponsive} />
    )}
  </HeaderConsumer>
);

export default ConnectedEventReservation;
