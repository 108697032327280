// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import FreezeWindowScroll from './../../Common/FreezeWindowScroll';
import Spinner from './../../Common/Spinner';

const PartnerLeaderSurveyModal = (props) => {
  let errorAlertNode = null;
  if (props.errors && props.errors.api) {
    errorAlertNode = (
      <div className="outcome-story__modal-error-alert-row">
        <span className="outcome-story__modal-error-alert">
          {props.errors.api}
        </span>
      </div>
    );
  }

  const handleClose = (e) => {
    e.preventDefault();
    props.close();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // send partner survey email
    props.sendPartnerLeaderSurveys({
      id: props.surveyPartner.partner.id,
      leaders: props.surveyPartner.leaders,
    });
  };

  // Handle loading state
  const disabledClass = props.loading ? ' disabled' : '';
  const leadersList = props.surveyPartner.leaders.map((leader) => {
    return (
      <li key={leader.id}>
        <span>
          {leader.first_name} {leader.last_name}
        </span>
        , {leader.email}
      </li>
    );
  });
  return (
    <div>
      <div
        className="modal__page-overlay modal__page-overlay--grey"
        onClick={handleClose}
      ></div>
      <div className="modal--fixed modal--grey-overlay ppd-v3-content-discovery-modal ppd-v3-content-discovery-modal--custom-strategy">
        <div
          className="modal__close hide-phone show-desktop"
          onClick={handleClose}
        >
          <div className="tc-x"></div>
        </div>

        <div className="tc__mobile-header show-phone hide-desktop">
          <div className="tc__back cute-2-desktop left" onClick={handleClose}>
            <div className="tc__back-arrow tc-arrow"></div>
            <div className="tc__back-caption">Back</div>
          </div>
          <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
            Send the Partner Leader Survey
          </div>
        </div>

        <div className="outcome-story__modal-body">
          <div className="outcome-story__modal-headline hide-phone show-desktop">
            Send the Partner Leader Survey
          </div>
          <form className="outcome-story__modal-form">
            <div className="v5__body-copy v5__body-copy--center">
              <p className="partner-survey-modal__p">
                Are you sure you want to send the Partner Leader survey to{' '}
                <br />
                <span className="v5__body-copy bold">
                  {props.surveyPartner.partner.name}
                </span>
                ?
              </p>
              <p className="partner-survey-modal__p">
                The survey email will be sent immediately to the following
                Partner Leaders
              </p>
              <ul className="v5__body-copy bold partner-survey-modal__ul partner-survey-modal__list">
                {leadersList}
              </ul>
            </div>
            <div className="outcome-story__modal-btns">
              {errorAlertNode}
              <button
                onClick={handleSubmit}
                className={`tc__btn tc__btn--flat-blue outcome-story__modal-btn ${disabledClass}`}
              >
                <Spinner loading={props.loading} />
                Send the Survey
              </button>
            </div>
            <div className="outcome-story__modal-btns">
              <a
                className={`v5__link v5__link--grey ${disabledClass}`}
                onClick={handleClose}
              >
                Don’t send the survey
              </a>
            </div>
          </form>
        </div>
      </div>
      <FreezeWindowScroll parentModal="ppd-v3-content-discovery-modal--custom-strategy" />
    </div>
  );
};

PartnerLeaderSurveyModal.propTypes = {
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  sendPartnerLeaderSurveys: PropTypes.func.isRequired,
};

export default PartnerLeaderSurveyModal;
