// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import LessonLink from './../../../LessonLink';
import NavigationLink from './../../../NavigationLink';

const Breadcrumbs = (props) => {
  let subjectCrumb,
    courseCrumb,
    unitCrumb,
    currentUnitCrumb,
    unitDropdown,
    lessonCrumb,
    currentLessonCrumb,
    lessonDropdown;

  const subjectCrumbFrom = '?from=breadcrumb_lesson';
  // setup the subject crumb
  switch (props.crumbSubject) {
    case 'math':
      subjectCrumb = (
        <div className="mtp-lesson__crumb mtp-lesson__crumb--subject">
          <NavigationLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/common_core_math' +
              subjectCrumbFrom
            }
          >
            <span className="mtp-lesson__crumb-caption">Math</span>
          </NavigationLink>
          <div className="mtp-lesson__crumb-border" />
        </div>
      );
      break;
    case 'ela':
      subjectCrumb = (
        <div className="mtp-lesson__crumb mtp-lesson__crumb--subject">
          <NavigationLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/common_core_ela' +
              subjectCrumbFrom
            }
          >
            <span className="mtp-lesson__crumb-caption">ELA</span>
          </NavigationLink>
          <div className="mtp-lesson__crumb-border" />
        </div>
      );
      break;
    case 'science':
      subjectCrumb = (
        <div className="mtp-lesson__crumb mtp-lesson__crumb--subject">
          <NavigationLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/next_gen_science' +
              subjectCrumbFrom
            }
          >
            <span className="mtp-lesson__crumb-caption">Science</span>
          </NavigationLink>
          <div className="mtp-lesson__crumb-border" />
        </div>
      );
      break;
    default:
      subjectCrumb = null;
  }
  courseCrumb = props.level_zero.map((crumb) => {
    if (crumb.Course.current) {
      return (
        <div
          key={'lesson-course-' + crumb.Course.id}
          className="mtp-lesson__crumb mtp-lesson__crumb--course"
        >
          <NavigationLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/master_teacher/' +
              props.user.id +
              '/' +
              props.user.seo_url +
              subjectCrumbFrom
            }
          >
            <span className="mtp-lesson__crumb-caption">
              {crumb.Course.name} ({props.user.first_name}{' '}
              {props.user.last_name})
            </span>
          </NavigationLink>
          <div className="mtp-lesson__crumb-border" />
        </div>
      );
    }
  });

  const unitDropdownItems = [];
  currentUnitCrumb = props.level_one.map((crumb) => {
    if (!crumb.Unit) {
      return null;
    }
    if (crumb.Unit.is_breadcrumb_dropdown) {
      const selectedClass = crumb.Unit.current ? 'selected' : '';
      unitDropdownItems.push(
        <li
          className={selectedClass}
          key={'lesson-unit-dropdown-' + crumb.Unit.id}
        >
          <NavigationLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/master_teacher/' +
              props.user.id +
              '/' +
              crumb.Unit.course_id +
              '/' +
              crumb.Unit.id +
              '/' +
              props.user.seo_url +
              '?from=breadcrumb_lesson'
            }
          >
            Unit {crumb.Unit.number}: {crumb.Unit.name}
          </NavigationLink>
        </li>
      );
    }
    if (crumb.Unit.current) {
      let arrowNode;
      if (props.has_multiple_level_one) {
        arrowNode = <span className="mtp-lesson__crumb-icon tc-bold-arrow" />;
      }
      return (
        <a
          onClick={props.toggleUnitDropdown}
          key={'lesson-crumb-unit-' + crumb.Unit.id}
        >
          <span className="mtp-lesson__crumb-caption">
            <span className="mtp-lesson__crumb-declaration">Unit</span>{' '}
            {crumb.Unit.number}
          </span>
          {arrowNode}
        </a>
      );
    }
  });
  if (props.has_multiple_level_one) {
    const unitDropdownClass = props.unitDropdownActive ? ' active' : '';
    unitDropdown = (
      <div className={'mtp-lesson__crumb-dropdown' + unitDropdownClass}>
        <ul>{unitDropdownItems}</ul>
      </div>
    );
  }
  unitCrumb = (
    <div className="mtp-lesson__crumb mtp-lesson__crumb--unit">
      {currentUnitCrumb}
      {unitDropdown}
      <div className="mtp-lesson__crumb-border" />
    </div>
  );

  const lessonDropdownItems = [];
  currentLessonCrumb = props.level_two.map((crumb) => {
    if (!crumb.Lesson) {
      return null;
    }
    if (crumb.Lesson.is_breadcrumb_dropdown) {
      const selectedClass = crumb.Lesson.current ? 'selected' : '';
      let newLessonNode = null;
      if (crumb.Lesson.is_new_lesson) {
        newLessonNode = (
          <span className="mtp-lesson__crumb-new-lesson">new!</span>
        );
      }
      lessonDropdownItems.push(
        <li
          className={selectedClass}
          key={'lesson-lesson-dropdown-' + crumb.Lesson.id}
        >
          <LessonLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/lesson/' +
              crumb.Lesson.id +
              '/' +
              crumb.Lesson.seo_url +
              '?from=breadcrumb_lesson'
            }
            lesson={crumb.Lesson.id}
          >
            <span>
              LESSON {crumb.Lesson.number}: {crumb.Lesson.name}
              {newLessonNode}
            </span>
          </LessonLink>
        </li>
      );
    }
    if (crumb.Lesson.current) {
      let arrowNode;
      if (props.has_multiple_level_two) {
        arrowNode = <span className="mtp-lesson__crumb-icon tc-bold-arrow" />;
      }
      return (
        <a
          onClick={props.toggleLessonDropdown}
          key={'lesson-crumb-lesson-' + crumb.Lesson.id}
        >
          <span className="mtp-lesson__crumb-caption">
            <span className="mtp-lesson__crumb-declaration">Lesson</span>{' '}
            {crumb.Lesson.number}
          </span>
          {arrowNode}
        </a>
      );
    }
  });
  if (props.has_multiple_level_two) {
    const lessonDropdownClass = props.lessonDropdownActive ? ' active' : '';
    lessonDropdown = (
      <div className={'mtp-lesson__crumb-dropdown' + lessonDropdownClass}>
        <ul>{lessonDropdownItems}</ul>
      </div>
    );
  }
  lessonCrumb = (
    <div className="mtp-lesson__crumb mtp-lesson__crumb--unit">
      {currentLessonCrumb}
      {lessonDropdown}
    </div>
  );

  return (
    <div className="mtp-lesson__breadcumb-wrapper show-desktop hide-phone">
      <div className="row row--1200">
        {subjectCrumb}
        {courseCrumb}
        {unitCrumb}
        {lessonCrumb}
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  user: PropTypes.object.isRequired,
  crumbSubject: PropTypes.string,
  toggleUnitDropdown: PropTypes.func.isRequired,
  toggleLessonDropdown: PropTypes.func.isRequired,
  level_zero: PropTypes.array,
  level_two: PropTypes.array,
  has_multiple_level_one: PropTypes.bool,
  Unit: PropTypes.object,
  unitDropdownActive: PropTypes.bool,
  lessonDropdownActive: PropTypes.bool,
};

export default Breadcrumbs;
