// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ActionModal from './../../Common/ActionModal';
import Spinner from './../../Common/Spinner';

const MeetingNoShowModal = (props) => {
  // set defaults that might change with the timezone being available
  const meetingTimeDefault = moment(props.meetingTimeFormatted).tz(
    props.timeZone
  );
  const meetingDate = meetingTimeDefault.format('MMMM D, YYYY [at] h:mm A z');
  const [isLoading, setLoading] = useState(false);
  const [sendRescheduleEmail, setSendRescheduleEmail] = useState(
    props.startChecked
  );

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handleNoShowMeeting = (e) => {
    e.preventDefault();

    // don't do anything if the form is submitting
    if (isLoading) {
      return;
    }

    setLoading(true);

    props.setMeetingAsNoShow({
      participantId: props.participantId,
      meetingId: props.meetingId,
      status: 4,
      successCallback: () => props.close(),
      failureCallback: () => setLoading(false),
      sendRescheduleEmail: sendRescheduleEmail,
    });
  };

  const handleToggleEmail = (e) => {
    setSendRescheduleEmail(e.target.checked);
  };

  // disable buttons when submitting
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <ActionModal
      headlineText='Confirm Meeting Status as "No Show"'
      close={handleCloseModal}
      spaceFromTop={props.isMobile ? 10 : 100}
    >
      <form className="participant-v5__modal-body">
        <div className="workspace__form-row participant-v5__modal-top-row v5__heading-5 participant-v5__meeting-cancel-modal-topline">
          <span className="tc-v5-meeting-missed home-v5__meetings-activity--missed">
            <span className="path1"></span>
            <span className="path2"></span>
          </span>
          <div className="admin__form-label ">Meeting on {meetingDate}.</div>
        </div>

        <div className="workspace__form-row v5__body-copy">
          Are you sure you want to set this meeting status as “no show”? This
          means the meeting was missed or cancelled within 30 minutes of the
          meeting’s start time.
        </div>

        <label className="workspace__form-row v5__heading-5 participant-v5__meeting-no-show--checkbox-row">
          <div className="participant-v5__meeting-no-show--checkbox">
            <input
              type="checkbox"
              id="send_email"
              defaultChecked={sendRescheduleEmail}
              className="tc__checkbox"
              onChange={handleToggleEmail}
              data-test-id="send-email-checkbox"
            />
          </div>
          <div className="v5__body-copy ">
            Send an email to reschedule meeting
          </div>
        </label>

        <div className="workspace__form-row">
          <div className="participant-v5__meeting-no-show--btn">
            <button
              className="v5__btn"
              onClick={handleNoShowMeeting}
              {...btnProps}
              data-test-id="set-as-no-show"
            >
              <Spinner loading={isLoading} />
              Set as No Show
            </button>
            <button
              className="v5__btn v5__btn--blue-border"
              onClick={handleCloseModal}
              {...btnProps}
              data-test-id="cancel-no-show"
            >
              <Spinner loading={isLoading} />
              Cancel
            </button>
          </div>
        </div>
      </form>
    </ActionModal>
  );
};

MeetingNoShowModal.propTypes = {
  close: PropTypes.func.isRequired,
  meetingTimeFormatted: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  participantId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  setMeetingAsNoShow: PropTypes.func.isRequired,
  startChecked: PropTypes.bool.isRequired,
};

MeetingNoShowModal.defaultProps = {
  data: {},
};

export default MeetingNoShowModal;
