// @ts-nocheck FIXME
import Request from './../components/Request';

const PartnerLeadApi = {
  sendWelcomeEmail: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner-lead/' +
        data.leadId +
        '/send-welcome-email',
      JSON.stringify(data)
    );
  },
};

export default PartnerLeadApi;
