// @ts-nocheck FIXME
import UserApi from '@legacy-components/apis/UserApi';
import Utils from '@legacy-components/components/DynamicUtils';
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import WindowScrollElementFixer from '@legacy-components/react/V5/Common/WindowScrollElementFixer';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import { useEffect, useState } from 'react';
import ZenScroll from 'zenscroll';
import AboutMe from './AboutMe';
import Settings from './Settings';
import AccountSidebar from './modules/AccountSidebar';
// const Archive = require('./Archive.jsx');

const AccountContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [unSavedChanges, setUnSavedChanges] = useState(false);
  const [clearPasswords, setClearPasswords] = useState(false);

  const [windowSize, setWindowSize] = useState(() => {
    const viewPortDimensions = WindowHelpers.getViewportDimensions();
    return viewPortDimensions.height;
  });

  const calculateViewPortHeight = () => {
    const viewPortDimensions = WindowHelpers.getViewportDimensions();
    setWindowSize(viewPortDimensions.height);
  };

  // clear unsaved changes on mount
  useEffect(() => {
    setUnSavedChanges(false);
    window.addEventListener('resize', calculateViewPortHeight);
    return () => {
      window.removeEventListener('resize', calculateViewPortHeight);
      props.setAlert({});
    };
  }, []);

  // prevent unload if unsaved changes
  useEffect(() => {
    WindowHelpers.navigationConfirmation(unSavedChanges);
  }, [unSavedChanges]);

  useEffect(() => {
    // scroll to first error
    if (Object.keys(errors).length) {
      const firstError = document.querySelector('.error');
      ZenScroll.setup(600, 100);
      ZenScroll.to(firstError);
    }
  }, [errors]);

  // this is the main submit method for the settings page
  const submit = (data) => {
    // clear previous errors and set is loading
    setLoading(true);
    setErrors({});

    // add user Id for who to edit
    data['userId'] = props.pageUser.id;
    // post setting request to the Back End
    UserApi.editUserSettings(data)
      .then((response) => {
        // we have a response so end loading state
        setLoading(false);

        // if there is an error that was part of a success response
        // set it for the front end
        if (response.status === 'error') {
          setErrors(response.errors);
          return;
        }

        let successMessage = 'Settings saved!';
        if (data.newPassword && data.email !== props.pageUser.email) {
          successMessage = 'Email and password updated!';
        } else if (data.newPassword) {
          successMessage = 'Password updated!';
        }

        // no errors page saved successfully show alert
        props.setAlert({
          type: 'success',
          message: successMessage,
        });

        // product requested we clear the password fields
        setClearPasswords(true);

        // if a user is not editing their own details or
        // it wasn't any data needed to update the header return from this method
        if (
          props.user.id !== props.pageUser.id ||
          (!data.firstName && !data.lastName)
        ) {
          return;
        }

        // update header with new user details
        const userContext = Utils.clone(props.headerUser);
        if (data.firstName) {
          userContext['first_name'] = data.firstName;
        }
        if (data.lastName) {
          userContext['last_name'] = data.lastName;
        }

        // update the header context with the new user details
        if (data.userId === props.pageUser.id) {
          props.setUser(userContext);
        }
      })
      .catch((response) => {
        // clean up form UI
        setLoading(false);

        // set back end error messages to the front end
        if (response.errors) {
          setErrors(response.errors);
          return;
        }

        // something more general went wront show a default message
        props.setAlert({
          type: 'error',
          message:
            'Sorry, we were unable to update your settings. Please try again later.',
        });
      });
  };

  // default form
  let pageContents = (
    <Settings
      {...props.pageUser}
      loading={loading}
      setClearPasswords={setClearPasswords}
      clearPasswords={clearPasswords}
      setUnSavedChanges={setUnSavedChanges}
      clearAlert={() => props.setAlert({})}
      setErrors={setErrors}
      errors={errors}
      timeZones={props.timeZones}
      setLoading={setLoading}
      submit={submit}
      scrollTo={props.scrollTo}
    />
  );

  // user specific form
  switch (props.tab) {
    case 'profile':
      pageContents = (
        <AboutMe
          loading={loading}
          setLoading={setLoading}
          setUnSavedChanges={setUnSavedChanges}
          user={props.user}
          pageUser={props.pageUser}
          clearAlert={() => props.setAlert({})}
          errors={errors}
          setErrors={setErrors}
          availabilityOptions={props.availabilityOptions}
          submit={submit}
          setAlert={props.setAlert}
          occupations={props.occupations}
          allGrades={props.grades}
          allSubjects={props.subjects}
          spedAnswers={props.spedAnswers}
          ellAnswers={props.ellAnswers}
          topLevelSubjects={props.topLevelSubjects || []}
          gradeBands={props.gradeBands || []}
        />
      );
      break;
    // case 'archive':
    //     pageContents = (
    //         <Archive {...props}/>
    //     );
    //     break;
  }

  return (
    <div className="tc__page">
      <Header {...props} page={'Account'} />
      <div className="row">
        <div className="cute-3-desktop cute-12-phone account-side__container">
          <AccountSidebar
            participants={props.pageUser.participants}
            archivedWork={props.archivedWork}
            tab={props.tab}
            pageUser={props.pageUser}
            userId={props.user.id}
          />
        </div>
        {pageContents}
      </div>
      <WindowScrollElementFixer
        positionToClass="account-content"
        positionThisClass="account-side"
        positionThisContainerClass="account-side__container"
        positionToElemTopMargin={0}
        updateFlag={String(windowSize)}
      />
    </div>
  );
};
const ConnectedAccount = (props) => (
  <HeaderConsumer>
    {({ setAlert, setUser, user }) => (
      <AccountContainer
        {...props}
        setAlert={setAlert}
        headerUser={user}
        setUser={setUser}
      />
    )}
  </HeaderConsumer>
);

export default ConnectedAccount;
