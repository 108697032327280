// @ts-nocheck FIXME
import Request from './../components/Request';

const ResourceApi = {
  // create file and get s3 upload payload
  createBeforeUpload: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/resource/create_before_upload',
      JSON.stringify(data)
    );
  },

  // upload to S3
  upload: function (url, data) {
    return Request.upload(url, data);
  },

  saveLink: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/resource/save_artifact_url',
      JSON.stringify(data)
    );
  },

  saveAfterUpload: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/resource/save_after_upload',
      JSON.stringify(data)
    );
  },

  // creates an entry in bl_tc_artifacts - do not call for link artifacts
  saveArtifactFile: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/resource/save_artifact_file',
      JSON.stringify(data)
    );
  },

  delete: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/resource/delete',
      JSON.stringify(data)
    );
  },

  incrementDownloadCount: function (data) {
    return Request.get(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/resource/' +
        data.id +
        '/increment_download_count'
    );
  },

  getPreviewUploadPayload: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/resource/get_preview_upload_payload',
      JSON.stringify(data)
    );
  },
};

export default ResourceApi;
