// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import React from 'react';
import ReactDOM from 'react-dom';
import ArtifactApi from './../../apis/ArtifactApi';
import Analytics from './../../components/Analytics';
import Utils from './../../components/DynamicUtils';
import LayerManager from './../../components/LayerManager';
import ArtifactModal from './../Modals/Artifact';
import ArtifactImage from './ArtifactImage';
import ArtifactOverlay from './ArtifactOverlay';

class Artifact extends React.Component {
  static contextType = LegacyLabContext;

  state = {
    copyHeight: 'auto',
    textAbridged: false,
    artifact: this.props.artifact,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ artifact: nextProps.artifact });
  }

  componentDidMount() {
    // if there is no copy stop here
    if (!this.refs.artifactCopy) {
      return;
    }
    // get the height of the copy if its small enough stop
    const copyHeight = ReactDOM.findDOMNode(
      this.refs.artifactCopy
    ).offsetHeight;
    if (copyHeight <= 90) {
      return;
    }

    // check the height of the title
    const titleHeight = ReactDOM.findDOMNode(
      this.refs.artifactTitle
    ).offsetHeight;
    // if its a multi line title the copy abridgement needs to be sooner
    const newCopyHeight = titleHeight > 20 ? 90 - (titleHeight - 20) : 90;

    // set the state to abridge the text and add a read more
    this.setState({
      copyHeight: newCopyHeight + 'px',
      textAbridged: true,
    });
  }

  handleArtifactClick = (e) => {
    e.preventDefault();

    if (this.props.analyticsLabel) {
      // Record the analytics event:
      Analytics.logEvent({
        category: 'artifact',
        action: 'view',
        label: this.props.analyticsLabel,
      });
    }

    // check if the element clicked was a read more link
    const readMore = Utils.hasClass(e.target, 'strategy__readmore--artifact');

    if (this.state.artifact.link && !readMore) {
      if (this.state.artifact.icon_label == 'Link') {
        window.open(this.state.artifact.link, '_blank');
      } else {
        location.href = this.state.artifact.link;
      }
    } else {
      const layerProps = {
        className: 'artifact-overlay',
        artifact: this.props.artifact,
        analyticsLabel: this.props.analyticsLabel,
      };

      // open overlay
      const url =
        '/coaching/lab/strategy/' +
        this.state.artifact.strategy_implementation_id +
        '/artifact/' +
        this.state.artifact.id +
        '?from=strategy_artifact';
      LayerManager.new(ArtifactOverlay, layerProps, ArtifactModal, url);
    }
  };

  handleImageReset = (mode, e) => {
    e.preventDefault();
    e.stopPropagation();

    // don't process disabled button
    if (e.target.classList.contains('disabled')) {
      return;
    }

    // clone the immutable property of artifact
    const newArtifact = Utils.clone(this.state.artifact);

    // build data to send to api
    const data = {
      url: this.state.artifact.url,
      mode: mode,
      linkId: this.state.artifact.content_id,
    };
    if (this.state.artifact.preview_lessonfile_id) {
      data.lessonfileId = this.state.artifact.preview_lessonfile_id;
    }

    // set the status to processing
    newArtifact.image_state = 'processing';
    newArtifact.image_type = mode;
    // Reset the preview image to placeholder
    newArtifact.preview = '';
    this.props.updateArtifactState(newArtifact);
    // make backend request to reset image
    ArtifactApi.resetImage(data).then(
      function (response) {
        if (mode === 'none') {
          // Reset the image state
          newArtifact.image_state = null;
        } else if (response.status == 'ok') {
          newArtifact.image_state = null;
          newArtifact.image_type = response.mode;
          if (data.mode == 'intelligent' && response.mode == 'screenshot') {
            alert('Intelligent fell back to screenshot');
          }
          newArtifact.preview_lessonfile_id = response.lessonfile_id;
          newArtifact.preview = response.preview;
        } else {
          // Handle errors
          newArtifact.image_state = 'error';
          newArtifact.image_type = 'none';
          alert(response.error);
        }
        this.props.updateArtifactState(newArtifact);
      }.bind(this),
      function (error) {}
    );
  };

  render() {
    // artifact type for the flags if it was set
    let artifactTypeNode = null;
    if (this.state.artifact.type_name) {
      artifactTypeNode = (
        <div className="artifact__type">
          {this.state.artifact.type_name}
          <div className="artifact__flag-end tc-flag-end"></div>
        </div>
      );
    }

    // only render artifact notes if they exist
    const artifactNotesNode = '';
    if (this.state.artifact.notes) {
      const artifactNotesNode = (
        <div
          ref="artifactCopy"
          className="artifact__notes tc__body-copy"
          style={{ height: this.state.copyHeight }}
        >
          {this.state.artifact.notes}
        </div>
      );
    }

    // add a read more node if the text is abridged
    let readMoreNode = '';
    if (this.state.textAbridged) {
      readMoreNode = (
        <div className="strategy__readmore strategy__readmore--artifact tc__headline-6 tc__link">
          Read More
        </div>
      );
    }

    let imgManagerNode = null;
    let className = 'si__artifact cute-4-desktop cute-12-phone left';

    // make artifacts taller (if necessary)
    if (this.props.tallerArtifact) {
      className += ' si-v2__artifact--with-image-ctl';
    }

    // only show for links, content_type == 23 and
    // user is a Content Curators, User Managers or Super Admin
    if (
      this.state.artifact.content_type == 23 &&
      this.context.pageData.user &&
      (Utils.isContentCurator.call(this.context) ||
        Utils.isManager.call(this.context) ||
        Utils.isAdmin.call(this.context))
    ) {
      let imgType = 'none';
      if (this.state.artifact.image_type) {
        imgType = this.state.artifact.image_type;
      }
      let heading = 'Regenerate Image';
      let spinner;
      if (this.state.artifact.image_state == 'processing') {
        heading = 'Processing';
        spinner = (
          <div
            className="littlespinner refresh-state-icon"
            style={{ float: 'right' }}
          ></div>
        );
      } else if (this.state.artifact.image_state == 'error') {
        heading = 'Processing Error';
      }
      imgManagerNode = (
        <div className="si-v2__imageregen">
          {spinner}
          <h3 className="si-v2__headline">{heading}</h3>
          {imgType == 'intelligent' ? (
            <a
              className="tc__btn tc__btn--blue tc__btn--small disabled"
              onClick={this.handleImageReset.bind(this, 'intelligent')}
              href="#intelligent"
            >
              Intelligent
            </a>
          ) : (
            <a
              className="tc__btn tc__btn--blue tc__btn--small"
              onClick={this.handleImageReset.bind(this, 'intelligent')}
              href="#intelligent"
            >
              Intelligent
            </a>
          )}
          {imgType == 'screenshot' ? (
            <a
              className="tc__btn tc__btn--blue tc__btn--small disabled"
              onClick={this.handleImageReset.bind(this, 'screenshot')}
              href="#screenshot"
            >
              Screenshot
            </a>
          ) : (
            <a
              className="tc__btn tc__btn--blue tc__btn--small"
              onClick={this.handleImageReset.bind(this, 'screenshot')}
              href="#screenshot"
            >
              Screenshot
            </a>
          )}
          {imgType == 'none' ? (
            <a
              className="tc__btn tc__btn--blue tc__btn--small disabled"
              onClick={this.handleImageReset.bind(this, 'none')}
              href="#none"
            >
              Placeholder
            </a>
          ) : (
            <a
              className="tc__btn tc__btn--blue tc__btn--small"
              onClick={this.handleImageReset.bind(this, 'none')}
              href="#none"
            >
              Placeholder
            </a>
          )}
        </div>
      );
    }

    // return the jsx template
    return (
      <div className={className} onClick={this.handleArtifactClick}>
        {artifactTypeNode}
        <ArtifactImage
          key={'artifactimage' + this.state.artifact.id}
          artifact={this.state.artifact}
        />
        <div>
          <span
            className={
              'artifact__type-icon file-type-icon ext-' +
              this.state.artifact.icon
            }
          ></span>
          <span className="artifact__file-type tc__feature-copy">
            {this.state.artifact.icon_label}:
          </span>
          <span
            ref="artifactTitle"
            className="artifact__title tc__feature-copy"
          >
            {this.state.artifact.name}
          </span>
        </div>
        {artifactNotesNode}
        {readMoreNode}
        {imgManagerNode}
      </div>
    );
  }
}

export default Artifact;
