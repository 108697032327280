// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Analytics from './../../../../components/Analytics';

const EditCover = (props) => {
  let name;
  let coverTitle;
  let coverDetails;
  let term;
  let additionalPedagogyHours;
  let additionalContentHours;

  const handleLogInputFocus = (inputType) => {
    // Record the analytics event:
    Analytics.logEvent({
      category: 'TP',
      action: 'add_' + inputType,
      label: 'from_tp_curation_ui',
    });
  };

  const handleFormDataChanges = (e) => {
    e.preventDefault();

    const portfolio = props.portfolio;

    portfolio.name = name.value.trim();
    portfolio.cover_title = coverTitle.value.trim();
    portfolio.cover_details = coverDetails.value.trim();
    portfolio.additional_pedagogy_hours = additionalPedagogyHours.value.trim();
    portfolio.additional_content_hours = additionalContentHours.value.trim();

    if (term) {
      portfolio.term = term.value.trim();
    }

    props.updatePortfolio(portfolio);
  };

  // Displays the line breaks in the cover details:
  let coverDetailsValue;
  if (props.portfolio.cover_details !== undefined) {
    coverDetailsValue = props.portfolio.cover_details.replace('\\n', '\n');
  }

  // set up classes on any fields with errors
  const nameErrorClass = props.ui.errors.coverName ? ' error' : '';
  const titleErrorClass = props.ui.errors.coverTitle ? ' error' : '';
  const detailsErrorClass = props.ui.errors.coverDetails ? ' error' : '';

  // only show the pd term input if the portfolio is V2
  let termNodes = null;
  if (props.portfolio.version != 1) {
    termNodes = [
      <div key="separator" className="portfolio-edit__separator"></div>,
      <div key="headline" className="portfolio-edit__term-headline">
        Personalized PD Term
      </div>,
      <div key="term-input">
        <input
          className="tc__input portfolio-edit__term-input"
          type="text"
          name="term"
          ref={(ref) => (term = ref)}
          defaultValue={props.portfolio.term}
          onChange={handleFormDataChanges}
          onFocus={() => {
            handleLogInputFocus('ppd_term');
          }}
        />
      </div>,
    ];
  }

  return (
    <div className="portfolio-edit__container row">
      <div className="cute-8-desktop center-desktop">
        <div className="portfolio-edit__input-container tc__center-text">
          <input
            id="name"
            className={'tc__input portfolio-edit__name-input' + nameErrorClass}
            type="text"
            name="name"
            ref={(ref) => (name = ref)}
            placeholder="Portfolio name"
            defaultValue={props.portfolio.name}
            onChange={handleFormDataChanges}
            onFocus={() => {
              handleLogInputFocus('name');
            }}
          />
          <div className="tc__error portfolio-edit__name-input-error">
            {props.ui.errors.coverName}
          </div>
        </div>

        <div className="cute-5-desktop portfolio-edit__cover-preview-container">
          <div className="portfolio-edit__cover-field-subtitle tc__center-text">
            Sample cover page
          </div>
          <div className="portfolio-edit__cover-preview"></div>
        </div>
        <div className="cute-7-desktop right">
          <div className="portfolio-edit__input-container tc__center-text">
            <label
              htmlFor="coverTitle"
              className="portfolio-edit__cover-title-label"
            >
              Title
            </label>
            <input
              className={
                'tc__input portfolio-edit__title-input' + titleErrorClass
              }
              type="text"
              name="coverTitle"
              ref={(ref) => (coverTitle = ref)}
              placeholder="Portfolio title"
              defaultValue={props.portfolio.cover_title}
              onChange={handleFormDataChanges}
              onFocus={() => {
                handleLogInputFocus('title');
              }}
            />
            <div className="tc__error">{props.ui.errors.coverTitle}</div>
          </div>
          <div className="portfolio-edit__input-container tc__center-text">
            <label
              htmlFor="coverDetails"
              className="portfolio-edit__cover-details-label"
            >
              Cover Page Details
              <div className="portfolio-edit__cover-field-subtitle">
                You may enter up to four lines of text.
              </div>
            </label>
            <textarea
              className={
                'tc__input portfolio-edit__details-input' + detailsErrorClass
              }
              type="text"
              name="coverDetails"
              ref={(ref) => (coverDetails = ref)}
              placeholder="Cover page details"
              defaultValue={coverDetailsValue}
              onChange={handleFormDataChanges}
              onFocus={() => {
                handleLogInputFocus('cover_details');
              }}
            />
            <div className="tc__error">{props.ui.errors.coverDetails}</div>
          </div>
        </div>
        <div className="clearfix"></div>

        {termNodes}

        <div className="portfolio-edit__separator"></div>
        <div className="portfolio-edit__pd-hours-headline">
          PD Hours
          <div className="portfolio-edit__cover-field-subtitle">
            Additional PD Hours. Your coach will update your PD Hours if
            necessary.
          </div>
        </div>
        <div>
          <div className="left">
            <label
              htmlFor="additionalPedagogyHours"
              className="portfolio-edit__hours-label"
            >
              Pedagogy Hours
            </label>
            <input
              className="tc__input portfolio-edit__hours-input"
              type="number"
              name="additionalPedagogyHours"
              ref={(ref) => (additionalPedagogyHours = ref)}
              defaultValue={props.portfolio.additional_pedagogy_hours}
              onChange={handleFormDataChanges}
              onFocus={() => {
                handleLogInputFocus('pedagogy_hours');
              }}
            />
          </div>
          <div className="left">
            <label
              htmlFor="additionalContentHours"
              className="portfolio-edit__hours-label"
            >
              Content Hours
            </label>
            <input
              className="tc__input portfolio-edit__hours-input"
              type="number"
              name="additionalContentHours"
              ref={(ref) => (additionalContentHours = ref)}
              defaultValue={props.portfolio.additional_content_hours}
              onChange={handleFormDataChanges}
              onFocus={() => {
                handleLogInputFocus('content_hours');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EditCover.propTypes = {
  portfolio: PropTypes.object.isRequired,
  updatePortfolio: PropTypes.func.isRequired,
  ui: PropTypes.object,
};

export default EditCover;
