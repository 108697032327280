// @ts-nocheck FIXME
import NavigationLink from './../../../../NavigationLink';

const MetaDataCollector = (props) => {
  let completedDataValueNode,
    requestNode,
    marketingRequestNode,
    completedMessageNode,
    viewProfileBtnNode;

  // setup an array of fields, and request messages in order of importants
  const baseSettingsUrl = `/account/${props.pageUser.id}`;
  const userMetaDataRequest = [
    {
      field: props.pageUser.grades,
      node: (
        <NavigationLink
          className="tc__link"
          url={`${baseSettingsUrl}?field=grades`}
        >
          What grades do you teach?
        </NavigationLink>
      ),
    },
    {
      field: props.pageUser.subjects,
      node: (
        <NavigationLink
          className="tc__link"
          url={`${baseSettingsUrl}?field=subjects`}
        >
          What subjects do you teach?
        </NavigationLink>
      ),
    },
    {
      field: props.pageUser.institution_id,
      node: (
        <NavigationLink
          className="tc__link"
          url={`${baseSettingsUrl}?field=institution`}
        >
          What is your school?
        </NavigationLink>
      ),
    },
    {
      field: props.pageUser.occupation,
      node: (
        <NavigationLink
          className="tc__link"
          url={`${baseSettingsUrl}?field=role`}
        >
          What best describes your role?
        </NavigationLink>
      ),
    },
    {
      field: props.pageUser.experience,
      node: (
        <NavigationLink
          className="tc__link"
          url={`${baseSettingsUrl}?field=experience`}
        >
          How long have you been teaching?
        </NavigationLink>
      ),
    },
  ];

  const userMarketingDataRequest = [
    /*
        {
            field: props.pageUser.details.data.environment,
            node: (
                <NavigationLink className="tc__link" url={"/settings/" + props.pageUser.id + "?field=enviornment"}>
                    How would you describe your insituions enviorment?
                </NavigationLink>
            )
        }, {
            field: props.pageUser.details.data.demographic,
            node: (
                <NavigationLink className="tc__link" url={"/settings/" + props.pageUser.id + "?field=demographic"}>
                    How would you describe your student demographic?
                </NavigationLink>
            )
        },
        */
    {
      field: props.pageUser.sped,
      node: (
        <NavigationLink
          className="tc__link"
          url={`${baseSettingsUrl}?field=special`}
        >
          Do you teach students with special needs or english language learners?
        </NavigationLink>
      ),
    },
    {
      field: props.pageUser.professional_bio,
      node: (
        <NavigationLink
          className="tc__link"
          url={`${baseSettingsUrl}?field=bio`}
        >
          Tell us more about yourself and your practice.
        </NavigationLink>
      ),
    },
  ];

  // reverse order so that the most necessary request will be set last before render
  userMetaDataRequest.reverse();
  userMarketingDataRequest.reverse();

  // start with total number of completed request items
  let completedDataValue = userMetaDataRequest.length;
  // loop over requests Array
  userMetaDataRequest.forEach((request) => {
    // user data is not defined
    if (
      !request.field ||
      typeof request.field == 'undefined' ||
      request.field.length == 0
    ) {
      completedDataValue--;
      requestNode = request.node;
      return;
    }
  });
  // start with total number of complted request items
  let completedExtraDataValue = userMarketingDataRequest.length;
  if (completedDataValue == 5) {
    completedMessageNode = (
      <div className="home__details-guiding-text">
        Your profile is complete. To provide the most relevant content,
        we&apos;d love to learn more about you.
      </div>
    );
    viewProfileBtnNode = (
      <NavigationLink
        className="home__sidebar-btn  bl4__btn bl4__btn--blue"
        url={`/profile/${props.pageUser.id}`}
      >
        View Profile
      </NavigationLink>
    );
    userMarketingDataRequest.forEach((request) => {
      if (
        props.pageUser.details &&
        props.pageUser.details.data &&
        request.field == props.pageUser.details.data.sped &&
        props.pageUser.occupation &&
        props.pageUser.occupation.name == 'Teacher'
      ) {
        return;
      }
      // user data is not defined
      if (
        !request.field ||
        typeof request.field == 'undefined' ||
        request.field.length == 0
      ) {
        --completedExtraDataValue;
        marketingRequestNode = request.node;
        return;
      }
    });
  }
  completedDataValue = completedDataValue * 20;
  completedDataValueNode = (
    <div
      className={
        'home__user-data-graph home__user-data-graph-' + completedDataValue
      }
    >
      <img
        src={'/legacy-images/donuts/donut-' + completedDataValue + '.svg'}
        alt={completedDataValue + '-percent-completed'}
      />
      <div className="">{completedDataValue}%</div>
    </div>
  );

  return (
    <div>
      {completedDataValueNode}
      {completedMessageNode}
      <div className="home__details-request">{requestNode}</div>
      <div className="home__details-request">{marketingRequestNode}</div>
      {viewProfileBtnNode}
    </div>
  );
};

export default MetaDataCollector;
