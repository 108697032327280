// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import React from 'react';
import StrategyApi from './../../../apis/StrategyApi';
import Analytics from './../../../components/Analytics';
import Utils from './../../../components/DynamicUtils';
import BrowserStore from './../../../stores/V2/BrowserStore';
import LayerLink from './../../LayerManager/LayerLink';
import GreyOverlayDialogue from './../../Modals/GreyOverlayDialogue';
import CoachPinDialogue from './CoachPinDialogue';

class StrategyPin extends React.Component {
  static contextType = LegacyLabContext;

  state = {
    pinStatus: this.props.strategy.pinned ? 'pinned' : null,
    pinCaptionAnimating: false,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      pinStatus: nextProps.strategy.pinned ? 'pinned' : null,
      pinCaptionAnimating: false,
    });
  }

  handleShowAlert = (type, message) => {
    if (
      this.props.handleShowAlert &&
      typeof this.props.handleShowAlert === 'function'
    ) {
      this.props.handleShowAlert(type, message);
    }
  };

  handlePinClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      pinStatus: 'loading',
    });

    // Pin
    if (!this.state.pinStatus) {
      // Record the analytics event:
      Analytics.logEvent({
        category: 'strategy',
        action: 'pin',
        label: this.props.analyticsTrackingLabel,
      });
      StrategyApi.addStrategy({
        userId: this.props.user.id,
        strategyId: this.props.strategy.id,
      })
        .then(
          function () {
            BrowserStore.actionDispatchSync(
              'toggleStrategyPin',
              this.props.strategy.id
            );
            if (this.props.handleSetPinned) {
              this.props.handleSetPinned(true);
            }
            this.setState({
              pinStatus: 'pinned',
              pinCaptionAnimating: true,
            });
          }.bind(this)
        )
        .catch(
          function () {
            this.handleShowAlert(
              'error',
              'Sorry, there was a problem pinning the strategy. Please try again later.'
            );
            this.setState({
              pinStatus: null,
              pinCaptionAnimating: true,
            });
          }.bind(this)
        );
    } else {
      // Unpin
      // Record the analytics event:
      Analytics.logEvent({
        category: 'strategy',
        action: 'unpin',
        label: this.props.analyticsTrackingLabel,
      });
      StrategyApi.removeStrategy({
        userId: this.props.user.id,
        strategyId: this.props.strategy.id,
      })
        .then(
          function () {
            BrowserStore.actionDispatchSync(
              'toggleStrategyPin',
              this.props.strategy.id
            );
            if (this.props.handleSetPinned) {
              this.props.handleSetPinned(false);
            }
            this.setState({
              pinStatus: null,
              pinCaptionAnimating: true,
            });
          }.bind(this)
        )
        .catch(
          function () {
            this.handleShowAlert(
              'error',
              'Sorry, there was a problem removing the strategy pin. Please try again later.'
            );
            this.setState({
              pinStatus: 'pinned',
              pinCaptionAnimating: true,
            });
          }.bind(this)
        );
    }

    this.setState({
      pinCaptionAnimating: false,
    });
  };

  render() {
    // if the user is not a TC Teacher or TC Coach, return nothing:
    if (
      !Utils.arrayContainsAnyItemInAnotherArray(this.props.user.auth_roles, [
        'TC_TEACHER',
        'BL_SERVICES_MANAGER',
      ])
    ) {
      return null;
    }

    const classNamePrefix = this.props.classNamePrefix;
    let pinCaption = 'Pin Strategy';
    const pinCaptionClass = this.state.pinCaptionAnimating
      ? ' animated  fadeInUp'
      : '';
    const mobileCssClassName = this.props.mobileCssClassName;

    // Setup classes for pin icon:
    let pinClass =
      classNamePrefix +
      '-v2__user-action-icon' +
      ' ' +
      classNamePrefix +
      '-v2__user-action-icon--pin';
    switch (this.state.pinStatus) {
      case 'loading':
        pinClass += ' littlespinner';
        break;
      case 'pinned':
        pinClass +=
          ' tc-pin-fill ' + classNamePrefix + '-v2__user-action-icon--active';
        pinCaption = 'Pinned';
        break;
      default:
        pinClass += ' tc-pin';
        break;
    }
    // Hide the pin caption if needed:
    pinCaption = this.props.showCaption ? pinCaption : '';

    // Coaches have a use the coach pin dialog
    if (Utils.isServicesManager.call(this.context)) {
      return (
        <LayerLink
          params={{
            strategy: this.props.strategy,
            user: this.props.user,
            onShowAlert: this.handleShowAlert,
          }}
          layer="new"
          component={CoachPinDialogue}
          wrapper={GreyOverlayDialogue}
          className={this.props.mobileCssClassName}
          title="Pin strategy"
        >
          <span
            className={'tc-pin ' + classNamePrefix + '-v2__user-action-icon'}
          ></span>
          <div
            ref="pinCaption"
            className={
              classNamePrefix + '-v2__pin-caption hide-desktop show-phone'
            }
          >
            {pinCaption}
          </div>
        </LayerLink>
      );
    }
    // Regular teachers have a simple pin button:
    else {
      return (
        <div
          className={mobileCssClassName}
          onClick={this.handlePinClick}
          title={this.props.strategy.pinned ? 'Pinned' : 'Pin strategy'}
        >
          <span className={pinClass}></span>
          <div
            ref="pinCaption"
            className={
              classNamePrefix +
              '-v2__pin-caption hide-desktop show-phone ' +
              pinCaptionClass
            }
          >
            {pinCaption}
          </div>
        </div>
      );
    }
  }
}

export default StrategyPin;
