// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ArtifactImage from './../../../Common/ArtifactImage';
import TimeDelta from './../../../Common/TimeDelta';

const ViewEntryRow = (props) => {
  // clicking on an artifact img or title will open the artifact overlay
  const handleArtifactClick = (e) => {
    e.preventDefault();

    // open links in a new window
    if (props.connections.link) {
      window.open(props.connections.link, '_blank');
      return;
    }

    // open artifact overlay
    props.artifactModal.open(props);
  };

  // header and body of entry capsule depends on entry type and any tag the entry may have
  const headerNode = null;
  let bodyNode = null;

  // for entries with an artifact image, styles differ if there is header text
  const artifactContainerClass = headerNode
    ? ''
    : ' story__artifact-image-container--no-header';

  // entry type mostly determines the capsule body contents
  const capsuleRowId = 'entry-' + props.id;
  switch (props.type) {
    case 'file':
      bodyNode = (
        <div>
          <div onClick={handleArtifactClick}>
            <ArtifactImage
              preview={props.connections.preview}
              title={props.connections.name}
              containerClass={
                'story__artifact-image-container' + artifactContainerClass
              }
              icon={props.connections.icon}
              imageClass="story__artifact-image"
            />
          </div>
          <div
            onClick={handleArtifactClick}
            className="tc__link story__entry-capsule-row story__entry-capsule-artifact-title tc__headline-4"
          >
            {props.title}
          </div>
          <div className="story__entry-capsule-row">{props.description}</div>
        </div>
      );
      break;
    case 'link':
      bodyNode = (
        <div>
          <div onClick={handleArtifactClick}>
            <ArtifactImage
              preview={props.connections.preview}
              title={props.connections.name}
              containerClass={
                'story__artifact-image-container' + artifactContainerClass
              }
              icon={props.connections.icon}
              imageClass="story__artifact-image"
            />
          </div>
          <div
            onClick={handleArtifactClick}
            className="tc__link story__entry-capsule-row story__entry-capsule-artifact-title tc__headline-4"
          >
            {Utils.unicodeToChar(props.title)}
          </div>
          <div className="story__entry-capsule-row">{props.description}</div>
        </div>
      );
      break;
    case 'text':
      bodyNode = (
        <PurifiedHTML
          className="story__entry-capsule-row ckeditor__output"
          content={props.description}
        />
      );
      break;
    case 'meeting_clip':
      bodyNode = (
        <div>
          <div onClick={handleArtifactClick}>
            <ArtifactImage
              preview={props.connections.preview}
              title={props.connections.name}
              containerClass={
                'story__artifact-image-container' + artifactContainerClass
              }
              icon={'mov'}
              imageClass="story__artifact-image"
            />
          </div>
          <div
            onClick={handleArtifactClick}
            className="tc__link story__entry-capsule-row story__entry-capsule-artifact-title tc__headline-4"
          >
            {props.title}
          </div>
          <div className="story__entry-capsule-row">{props.description}</div>
        </div>
      );
      break;
  }

  // lock icon appears in the footer if the entry is private
  const privateIconNode = props.private ? (
    <div className="tc-v4-lock story__entry-capsule-private-icon"></div>
  ) : null;

  // edit icons appear in the footer if the entry is editable
  const disableEditClass = props.activeAction ? ' disabled' : '';

  const editIconNode = props.userCanEditData ? (
    <span
      className={
        'tc-v4-pencil story__entry-capsule-edit-icon' + disableEditClass
      }
      onClick={props.edit}
    ></span>
  ) : null;

  return (
    <div id={capsuleRowId} className="row row--1200">
      <div className="cute-12-desktop cute-12-phone center-desktop">
        <div className="story__entry-capsule tc__body-copy">
          {headerNode}
          {bodyNode}
          <div className="story__entry-capsule-footer-row">
            <div className="story__entry-capsule-timestamp">
              <TimeDelta datetime={props.created_formatted} />
              {privateIconNode}
            </div>
            <div>{editIconNode}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewEntryRow.propTypes = {
  artifactModal: PropTypes.object,
  isEditable: PropTypes.bool,
  edit: PropTypes.func,
};

export default ViewEntryRow;
