// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment';
import PropTypes from 'prop-types';
import Utils from './../../../components/DynamicUtils';
import AbsoluteAlert from './../../Alerts/Absolute';
import LayerLink from './../../LayerManager/LayerLink';
import GreyOverlayDialogue from './../../Modals/GreyOverlayDialogue';
import NavigationLink from './../../NavigationLink';
import DomainsDelete from './Domains/DomainsDelete';

const Strategies = (props) => {
  let alertNode;
  let strategiesNode = null;
  const searchLoadingNode = null;
  let searchOnChangeTimeout = null;

  if (!Utils.isEmpty(props.ui.alert)) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  //code to handle an enter key press to trigger a search
  const handleKeyPress = (target) => {
    // 13 is the enter key
    if (target.charCode === 13) {
      target.preventDefault();
      const input = document.querySelector(
        '.admin-v2__user-search-input'
      ).value;

      if (props.ui.searchLoading) {
        return;
      }
      if (input !== '') {
        if (window.xhrPostRequest) {
          window.xhrPostRequest.abort();
        }
        props.setSearchLoading();
        props.search(input);
      }
    }
  };

  const handleTyping = () => {
    // Clear any existing search timeouts
    window.clearTimeout(searchOnChangeTimeout);

    // Delay search requests by 200 ms
    const searchDelay = 200;

    searchOnChangeTimeout = window.setTimeout(function () {
      const input = document.querySelector(
        '.admin-v2__user-search-input'
      ).value;

      //if we are already loading a search do nothing.
      if (props.ui.searchLoading) {
        return;
      }
      props.setSearchLoading();

      //deleted all of search repop table
      if (input === '') {
        if (window.xhrPostRequest) {
          window.xhrPostRequest.abort();
        }
        props.search('');
        return;
      }
      if (input.length >= props.minCharCountForResults) {
        if (window.xhrPostRequest) {
          window.xhrPostRequest.abort();
        }
        props.search(input);
        return;
      } else {
        props.clearSearchLoading();
      }
    }, searchDelay);
  };

  const handleDomainExpand = (e) => {
    e.preventDefault();
    props.domainExpand(e.target.dataset.index);
  };

  // set up sortable table headers
  const headerArrowClass =
    props.ui.sortStrategies.direction === 'descending' ? ' down' : '';
  const headerArrowNode = (
    <div
      className={
        'tc-short-arrow curation__sortable-header-arrow' + headerArrowClass
      }
    />
  );

  // set up list of strategies
  if (props.strategies) {
    strategiesNode = props.strategies.map((strategy, strategyIndex) => {
      let domains = null;
      if (strategy.focusAreas) {
        domains = strategy.focusAreas.map((domain, domainIndex) => {
          if (!strategy.expand) {
            if (domainIndex > 3) {
              return null;
            } else if (domainIndex === 3) {
              return (
                <div
                  onClick={handleDomainExpand}
                  data-index={strategyIndex}
                  className="tc__link"
                  key={'strategy-' + strategy.id + '-domain-' + domain.id}
                >
                  + {strategy.focusAreas.length - 3} more domains
                </div>
              );
            }
          }
          return (
            <div key={'strategy-' + strategy.id + '-domain-' + domain.id}>
              {domain.title}
            </div>
          );
        });
      }

      // color curation and publish statuses correctly
      let curationStatusClass = '';
      switch (strategy.curation_status.name) {
        case 'unstarted':
          curationStatusClass = ' curation__status--grey';
          break;
        case 'needs_review':
          curationStatusClass = ' curation__status--orange';
          break;
        case 'complete':
          curationStatusClass = ' curation__status--green';
          break;
      }
      const publishStatusClass =
        strategy.publish_status === 'Draft' ? ' curation__status--grey' : '';

      // const deleteNode = props.canDelete ? (
      const deleteNode = (
        <LayerLink
          params={{
            selectedDomainId: strategy.id,
            handleDelete: props.handleDeleteStrategy,
            title: strategy.title,
            className: 'portfolio',
          }}
          layer="new"
          className="tc__link icon tc-delete"
          title="Delete {strategy.title}"
          component={DomainsDelete}
          wrapper={GreyOverlayDialogue}
        />
      );

      return (
        <tr key={'strategy-' + strategy.id} className="admin-table__row">
          <td className="admin-table__col">{strategy.title}</td>
          <td className="admin-table__col">{domains}</td>
          <td className="admin-table__col">
            {strategy.type === 'tech_tool' ? 'Tech Tool' : 'Strategy'}
          </td>
          <td className="admin-table__col">{strategy.editor}</td>
          <td className="admin-table__col">
            {moment(strategy.lastmodified_formatted).format('l')}
          </td>
          <td className="admin-table__col">
            <span className={'curation__status' + curationStatusClass}>
              {strategy.curation_status.label}
            </span>
          </td>
          <td className="admin-table__col">
            <span className={'curation__status' + publishStatusClass}>
              {strategy.publish_status}
            </span>
          </td>
          <td className="admin-table__col admin-table__col--icons-v4">
            <NavigationLink
              url={
                process.env.REACT_APP_TEACHING_URL + '/strategy/' + strategy.id
              }
              target="_blank"
              className="tc__link tc-outlink"
            />
            <NavigationLink
              url={'/bl/curation/strategy/' + strategy.id + '/edit'}
              className="tc__link tc-pencil"
            />
            {deleteNode}
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="tc__page">
      <Header page="Curation" {...props} />
      {alertNode}
      <div className="row tc__body-copy">
        <div className="cute-12-desktop ppd-v3-curation__breadcrumbs">
          <NavigationLink url="/bl/curation" className="tc__link">
            Curation
          </NavigationLink>
          <span className="tc-arrow ppd-v3-curation__breadcrumb-separator" />
          Strategies & Tech Tools
        </div>
      </div>
      <form className="tc__body-copy ppd-v3-curation__edit-form">
        <div className="row">
          <div className="tc__headline-2 cute-12-desktop">
            <h2 className="tc__headline-2">Strategies & Tech Tools</h2>
          </div>
        </div>
        <div className="row">
          <div className="ppd-v3-curation__search cute-5-desktop left">
            <div className="admin-v2__search-container admin-v2__user-search-input-container">
              <span className="admin-v2__user-search-input-magnifying-glass">
                <div className="tc-search" />
              </span>
              <input
                type="text"
                onKeyPress={handleKeyPress}
                onChange={handleTyping}
                className={
                  'tc__input ppd-v3-curation__input admin-v2__user-search-input'
                }
                placeholder="Search"
              />
            </div>
            {searchLoadingNode}
          </div>
          <div className="cute-2-desktop">
            <NavigationLink
              url={'/bl/curation/strategy/add'}
              className="bl4__btn bl4__btn--blue"
            >
              Add
            </NavigationLink>
          </div>
        </div>
        <div className="row">
          <div className="cute-12-desktop">
            <table className="admin-table">
              <tbody>
                <tr>
                  <th
                    className="admin-table__header curation__sortable-header curation__name-header tc__link tc__link--black"
                    onClick={() => props.sortStrategiesBy('title')}
                  >
                    Name
                    {props.ui.sortStrategies.column === 'title' &&
                      headerArrowNode}
                  </th>
                  <th className="admin-table__header curation__name-header">
                    Domains
                  </th>
                  <th
                    className="admin-table__header curation__sortable-header tc__link tc__link--black"
                    onClick={() => props.sortStrategiesBy('type')}
                  >
                    Type
                    {props.ui.sortStrategies.column === 'type' &&
                      headerArrowNode}
                  </th>
                  <th className="admin-table__header">Editor</th>
                  <th
                    className="admin-table__header curation__sortable-header tc__link tc__link--black"
                    onClick={() => props.sortStrategiesBy('modified')}
                  >
                    Modified
                    {props.ui.sortStrategies.column === 'modified' &&
                      headerArrowNode}
                  </th>
                  <th
                    className="admin-table__header curation__sortable-header curation__sortable-header--status tc__link tc__link--black"
                    onClick={() => props.sortStrategiesBy('status')}
                  >
                    Status
                    {props.ui.sortStrategies.column === 'status' &&
                      headerArrowNode}
                  </th>
                  <th
                    className="admin-table__header curation__sortable-header curation__sortable-header--status tc__link tc__link--black"
                    onClick={() => props.sortStrategiesBy('visible')}
                  >
                    Visibility
                    {props.ui.sortStrategies.column === 'visible' &&
                      headerArrowNode}
                  </th>
                  <th className="admin-table__header" />
                </tr>
                {strategiesNode}
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  );
};

Strategies.PropTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default Strategies;
