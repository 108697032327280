// @ts-nocheck FIXME
import Request from './../components/Request';

const TmlLoopApi = {
  saveFeedback: function (data) {
    // construct the JSON values to post
    const post = {
      step: data.step,
      rating: data.rating,
      message: data.message,
      share: data.share,
      tml_loop_id: data.loopId,
    };

    // if there is an id, backend will do an update
    if (data.id) {
      post.id = data.id;
    }

    const url =
      process.env.REACT_APP_LAB_SERVICE_URL +
      '/teacher/' +
      data.teacherId +
      '/tml_loop/feedback';
    return Request.post(url, JSON.stringify(post));
  },

  saveNextLoop: function (data) {
    const post = {
      challenge_instance_id: data.challengeInstanceId,
      strategy_implementation_id: data.id,
    };

    const url =
      process.env.REACT_APP_LAB_SERVICE_URL +
      '/teacher/' +
      data.teacherId +
      '/tml_loop/create';
    return Request.post(url, JSON.stringify(post));
  },

  updateStrategy: function (data) {
    const post = {
      tml_loop_id: data.tmlLoopId,
      strategy_implementation_id: data.id,
    };

    const url =
      process.env.REACT_APP_LAB_SERVICE_URL +
      '/teacher/' +
      data.teacherId +
      '/tml_loop/strategy';
    return Request.post(url, JSON.stringify(post));
  },

  removeArtifact: function (data) {
    const post = data;

    const url =
      process.env.REACT_APP_LAB_SERVICE_URL +
      '/teacher/' +
      data.teacherId +
      '/tml_loop/artifact/remove';
    return Request.post(url, JSON.stringify(post));
  },

  createLoopWithCustomStrategy: function (data) {
    const url =
      process.env.REACT_APP_LAB_SERVICE_URL +
      '/teacher/' +
      data.teacherId +
      '/tml_loop/create';
    return Request.post(url, JSON.stringify(data));
  },

  updateChallengeInstanceMetric: function (data) {
    const url =
      process.env.REACT_APP_LAB_SERVICE_URL +
      '/teacher/' +
      data.teacherId +
      '/tml_loop/metric';
    return Request.post(url, JSON.stringify(data));
  },

  create: function (data) {
    const url =
      process.env.REACT_APP_LAB_SERVICE_URL +
      '/teacher/' +
      data.teacherId +
      '/tml_loop/v2/create';
    return Request.post(url, JSON.stringify(data));
  },

  update: function (data) {
    const url =
      process.env.REACT_APP_LAB_SERVICE_URL +
      '/teacher/' +
      data.teacherId +
      '/tml_loop/v2/' +
      data.loopId;
    return Request.post(url, JSON.stringify(data));
  },
};

export default TmlLoopApi;
