// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import FreezeWindowScroll from './../Common/FreezeWindowScroll';
import Spinner from './../Common/Spinner';

const ConfirmDeleteModal = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.onDelete(props.data);
  };

  const handleCloseOverlay = (e) => {
    e.preventDefault();
    props.close();
  };

  // show the custom body text if provided; otherwise, use the default
  const bodyText = props.data.customBodyText
    ? props.data.customBodyText
    : 'Delete "' + (props.data.title || props.data.name) + '"?';

  // disable buttons when form is submitted
  const disabledClass = props.loading ? ' disabled' : '';
  let errorMessageNode = null;
  if (props.errorMessage) {
    errorMessageNode = (
      <div className="tc__error tc__error--block">{props.errorMessage}</div>
    );
  }

  return (
    <div data-testid="confirm-delete-modal">
      <div
        className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
        onClick={handleCloseOverlay}
      />
      <div className="clip-modal modal--fixed modal--grey-overlay ppd-v3-curation-modal ppd-v3-curation-modal--short">
        <div className="modal__close" onClick={handleCloseOverlay}>
          <div className="tc-x" />
        </div>
        <div className="ppd-v3-curation-modal__body">
          <div className="portfolio-edit__dialog-headline tc__headline-2">
            {bodyText}
          </div>
          {errorMessageNode}
          <div className="portfolio-edit__dialog-buttons">
            <button
              onClick={handleSubmit}
              className={
                'portfolio-edit__dialog-button bl4__btn bl4__btn--red' +
                disabledClass
              }
            >
              <Spinner loading={props.loading} />
              {props && props.data && props.data.label
                ? props.data.label
                : 'Delete'}
            </button>
            <button
              onClick={handleCloseOverlay}
              className={
                'portfolio-edit__dialog-button bl4__btn' + disabledClass
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <FreezeWindowScroll parentModal="clip-modal" />
    </div>
  );
};

ConfirmDeleteModal.propTypes = {
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ConfirmDeleteModal;
