// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../components/DynamicUtils';
import S3 from './../../../components/S3';

const ContentResourceReplacePreview = (props) => {
  let inputFiles = [];

  const handleFilesChange = (e) => {
    e.preventDefault();
    // Clear existing alerts
    props.clearAlert();
    inputFiles = e.target.files;
    submitFiles();
  };

  const getUploadProgressNode = () => {
    let progressNode = null;
    let progressFileNameNode = null;

    if (
      props.file &&
      props.file.status === 'during' &&
      props.file.percent < 100
    ) {
      const progressFileNameClass =
        props.preview !== undefined
          ? ' ppd__v2-curation-strategy-artifact__upload-title--replace'
          : '';

      progressFileNameNode = (
        <div className="curation__block-replace-preview-progress-bar-file-name">
          <span className="artifact__type-icon file-type-icon ext-jpg" />
          <span
            className={
              'artifact__upload-title ppd__v2-curation-strategy-artifact__upload-title' +
              progressFileNameClass
            }
          >
            {props.file.name}
          </span>
        </div>
      );

      progressNode = (
        <div
          key="progress"
          className="curation__block-replace-preview-progress-bar"
        >
          <div className="artifact__upload-bar">
            <div
              className="artifact__upload-progress"
              style={{ width: props.file.progress + '%' }}
            />
          </div>
          {progressFileNameNode}
        </div>
      );
    }
    return progressNode;
  };

  const submitFiles = () => {
    let file;

    for (let i = 0; i < inputFiles.length; i++) {
      // Add some of the POST fields that the backend needs
      file = {
        lastModified: inputFiles[i]['lastModified'],
        lastModifiedDate: inputFiles[i]['lastModifiedDate'],
        name: inputFiles[i]['name'],
        size: inputFiles[i]['size'],
        type: inputFiles[i]['type'],
        uuid: Utils.uuid(),
      };
      // max size is 20 MB
      if (file.size > 20000000) {
        props.errorCallback('Maximum upload size is 20 MB');
      } else {
        inputFiles[i]['uuid'] = file['uuid'];
        const callbackFields = {
          sectionId: props.sectionId,
          resourceId: props.resourceId,
          lessonfileId: props.lessonfileId,
          linkId: props.linkId,
        };
        // Upload the file to S3
        S3.flexibleUpload(
          inputFiles[i],
          props.beforeUploadCallback,
          props.uploadCallback,
          props.afterUploadCallback,
          () => {},
          callbackFields
        ).then(
          function onSuccess(fileData) {
            fileData['message'] = 'Upload Complete';
            fileData['status'] = 'after';
            props.setPreview(fileData['preview']);
          },
          function onError(fileData) {
            // See if the file has the error property
            let error = fileData['error'];
            if (!error) {
              error = 'There was an error uploading your file.';
            }
            props.errorCallback(error);
            // Clear the file from the store
            props.updateResourceUploadProgress({
              id: props.resourceId,
              file: null,
            });
          },
          function onProgress(fileData) {
            // If there is an error, abort
            if (fileData.status === 'error') {
              return;
            }
            fileData['message'] = fileData.progress + '%';
            fileData['status'] = 'during';
            fileData['percent'] = fileData.progress;
            props.updateResourceUploadProgress({
              id: props.resourceId,
              file: fileData,
            });
          }
        );
      }
    }
  };

  return (
    <div className="row">
      <div className="cute-11-desktop cute-1-desktop-offset">
        <div className="">{getUploadProgressNode()}</div>

        <div className="curation__block-replace-preview">
          <span className="bold">Preview Image</span> (500 X 280 pixels minimum)
        </div>
        <label
          htmlFor={`replace-preview-${props.resourceId}`}
          className="v5__link"
        >
          Upload Image
        </label>
        <input
          id={`replace-preview-${props.resourceId}`}
          type="file"
          className="curation__block-replace-preview-input"
          onChange={handleFilesChange}
          multiple={false}
          accept="image/jpeg"
          value=""
        />

        <div className="tc__error tc__error--upload">{props.error}</div>
      </div>
    </div>
  );
};

ContentResourceReplacePreview.propTypes = {
  sectionId: PropTypes.number.isRequired,
  resourceId: PropTypes.number.isRequired,
  lessonfileId: PropTypes.number,
  linkId: PropTypes.number,
  updateResourceUploadProgress: PropTypes.func.isRequired,
  clearAlert: PropTypes.func.isRequired,
  beforeUploadCallback: PropTypes.func.isRequired,
  uploadCallback: PropTypes.func.isRequired,
  afterUploadCallback: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
};

export default ContentResourceReplacePreview;
