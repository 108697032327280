// @ts-nocheck FIXME
import dragula from 'dragula';
import React from 'react';
/*
USAGE

- elements should have a parent element with an ID
- each sortable element should have a data-id attribute
- the node returned should be rendered as a react class, <SomeSortableThing />
- if there are multiple dragula instances on a page, give them different selectors

*/
import WindowHelpers from './../../components/WindowHelpers';

import Utils from './../../components/StaticUtils';
// reference to the sorting library, dragula
const drakes = {};

const Sortable = (
  SortableComponent,
  dragContainer,
  scrollContainer,
  callback,
  customOptions = {}
) => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component {
    // after a component mounts, link the sorting functionality
    componentDidMount() {
      // index dragula instances by their selector
      const drakeId = dragContainer.replace('#');

      // get current viewport
      const viewport = WindowHelpers.getViewportDimensions();

      // merge incoming options with default options
      const options = Object.assign(
        {
          moves: (el, source, handle, sibling) => {
            // editing section name do inhibit functionality
            if (
              customOptions.sectionNameEditing &&
              Utils.hasClass(source, 'ppt__sidebar-nav')
            ) {
              return false;
            }
            if (el.dataset.sortable == 'false') {
              return false;
            }
            // only make dragable from drag handle icon
            const movable = Utils.hasClass(handle, 'ppt__drag-icon');
            if (!movable) {
              return false;
            }

            return true;
          },
          invalid: (el, handle) => {
            return false;
          },
          accepts: (el, target, source, sibling) => {
            // scrolling logic
            if (scrollContainer) {
              scrollContainer = scrollContainer.replace('#', '');
              let scrollingElem = document.getElementById(scrollContainer);
              if (scrollContainer === 'window') {
                scrollingElem = document.body;
              }

              // determine element position
              const elPosition = el.getBoundingClientRect();

              // scroll up
              const topDistance = elPosition['top'];
              if (topDistance < 120) {
                scrollingElem.scrollTop += -20;
              }

              // scroll down
              const bottomDistance = viewport['height'] - elPosition['bottom'];
              if (bottomDistance < 50) {
                scrollingElem.scrollTop += 20;
              }
            }

            return true;
          },

          // dragula work-around for that facilitates use with react
          revertOnSpill: true,
        },
        customOptions
      );

      // remove pre-existing dragula instance if this is a re-render
      if (drakes[drakeId]) {
        drakes[drakeId].destroy();
      }

      // attach dragula functionality via a traditional JS selector
      drakes[drakeId] = dragula(
        [document.querySelector(dragContainer)],
        options

        // this gets called whenever an item is dropped
      ).on('drop', (el, target, source, sibling) => {
        // read ids from all child nodes
        const ids = Array.prototype.map.call(target.childNodes, (item) => {
          return item.dataset.id;
        });

        // don't actually change the DOM, the DOM will get changed when the store changes
        drakes[drakeId].cancel();

        // update the store with the order of the new ids
        callback(ids);
      });
    }

    // render the component that is passed in
    render() {
      return SortableComponent;
    }
  };
};

export default Sortable;
