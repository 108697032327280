// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Sortable from './../Sortable';

const TeacherCohortSection = (props) => {
  /* EVENT HANDLERS */
  const handleRefreshClick = (e) => {
    e.preventDefault();

    props.refreshTeacherCohortSection({
      sectionId: props.section.id,
    });
  };

  const handleToggleVisible = (e, teacherId, visible) => {
    e.preventDefault();

    props.editTeacher({
      teacherId: teacherId,
      visible: visible ? 1 : 0,
      edited: 'visibility',
    });
  };

  const handleEditNote = (e, teacher) => {
    e.preventDefault();
    props.setCurrentTeacher(teacher);
  };

  /* RENDERING */

  // build the "Refresh Data" link
  let refreshNode = (
    <div>
      <span className="tc-refresh ppt__refresh-icon" />
      <a className="tc__link ppt__refresh-link" onClick={handleRefreshClick}>
        Refresh Data
      </a>
    </div>
  );
  if (props.ui.TEACHER_COHORT.refreshing) {
    refreshNode = <div>Data is refreshing...</div>;
  }

  // show any error we got when refreshing
  let refreshErrorNode = null;
  if (props.ui.TEACHER_COHORT.error) {
    refreshErrorNode = (
      <div className="ppt__refresh-error">{props.ui.TEACHER_COHORT.error}</div>
    );
  }

  // build the list of teachers to show
  const teacherNode = props.section.teachers.map((teacher) => {
    // change note icon depending on presence of a note
    const noteIconClass = teacher.note ? 'tc-note-fill' : 'tc-add-note';

    return (
      <tr key={'teacher' + teacher.id} data-id={teacher.id}>
        <td className="ppt-teacher-cohort__icon-field ppt-teacher-cohort__icon-field--drag">
          <div className="tc-dots-8 ppt__drag-icon ppt__drag-icon--bar"></div>
        </td>
        <td className="ppt-teacher-cohort__icon-field">
          <span
            onClick={(e) => {
              handleToggleVisible(e, teacher['id'], !teacher['visible']);
            }}
          >
            {teacher.visible ? (
              <div className="ppt__visibility-icon selected">
                <div className="tc-check" />
              </div>
            ) : (
              <div className="ppt__visibility-icon"></div>
            )}
          </span>
        </td>
        <td className="ppt-teacher-cohort__name-field">{teacher.name}</td>
        <td className="ppt-teacher-cohort__icon-field">
          <span
            className={'ppt-teacher-cohort__note-icon ' + noteIconClass}
            onClick={(e) => handleEditNote(e, teacher)}
          ></span>
        </td>
        <td className="ppt-teacher-cohort__data-field">
          {teacher.entries[0].data.pathway}
        </td>
        <td className="ppt-teacher-cohort__data-field">
          {teacher.entries[0].data.strategyCount || '0'} Strategies Tried and In
          Progress
        </td>
        <td className="ppt-teacher-cohort__data-field">
          {teacher.entries[0].data.artifactCount || '0'} Artifacts Uploaded
        </td>
        <td className="ppt-teacher-cohort__data-field">
          {teacher.entries[0].data.studentsImpacted || '0'} Students Impacted
        </td>
        <td className="ppt-teacher-cohort__data-field">
          {teacher.entries[0].data.coachMeetings || '0'} Coach Meetings
        </td>
      </tr>
    );
  });

  // attach sorting functionality to sections
  const SortableTeachers = Sortable(
    <tbody id="teachersContainer">{teacherNode}</tbody>,
    '#teachersContainer',
    '#ppt-section__container',
    (teacherIds) => props.sortTeachers(teacherIds)
  );

  return (
    <div className="ppt-section">
      <h1 className="tc__headline-2">{props.section.name}</h1>
      <div>
        {refreshNode}
        {refreshErrorNode}
      </div>
      <table className="ppt-table">
        <SortableTeachers />
      </table>
    </div>
  );
};

TeacherCohortSection.propTypes = {
  section: PropTypes.object.isRequired,
  refreshTeacherCohortSection: PropTypes.func.isRequired,
  sortTeachers: PropTypes.func.isRequired,
  editTeacher: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

export default TeacherCohortSection;
