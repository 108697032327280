// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Analytics from './../../../../components/Analytics';
import Spinner from './../Spinner';

const ArtifactLink = (props) => {
  let url;

  const clearError = () => {
    props.setArtifactLinkUploadError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      url: url.value,
    };
    props.submitLink(data);
  };

  const handleLinkFocus = () => {
    Analytics.logEvent({
      category: 'link',
      action: 'enter',
      label: 'from_add_link_modal_vision_page',
    });
  };

  let errorNode = null;
  let errorClass = '';
  if (props.error) {
    errorNode = <div className="tc__error">{props.error}</div>;
    errorClass = ' error';
  }

  let buttonDisabledClass = '';
  let loadingNode = null;
  if (props.loading) {
    buttonDisabledClass = ' disabled';
    loadingNode = <Spinner loading={true} />;
  }

  return (
    <div className="artifact__link--option">
      <div className="tc__headline-5 vision__artifact-link-upload-headline">
        Paste a link to your artifact
      </div>
      <div className="tc__body-copy vision__artifact-link-upload-caption">
        Please double check that this resource is publicly accessible
      </div>
      <div className="vision__artifact-link-input-container">
        <input
          type="text"
          data-testid="artifact-link-input"
          className={`tc__input${errorClass}`}
          ref={(ref) => (url = ref)}
          onClick={clearError}
          onFocus={handleLinkFocus}
        />
        {errorNode}
      </div>
      <div className="vision__artifact-link-button participant-artifact__uplaod-buttons tc__center-text">
        <button
          onClick={handleSubmit}
          className={`tc__btn tc__btn--flat-blue tc__btn--v3 tc__btn--no-margin${buttonDisabledClass}`}
        >
          Add
          {loadingNode}
        </button>
        <button
          className="tc__btn tc__btn--flat-grey"
          onClick={props.handleUploadCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

ArtifactLink.propTypes = {
  handleUploadCancel: PropTypes.func.isRequired,
  submitLink: PropTypes.func.isRequired,
  setArtifactLinkUploadError: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

ArtifactLink.defaultProps = {
  loading: false,
};

export default ArtifactLink;
