// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const MeetingStats = (props) => {
  return (
    <div className="participant-v5__meeting-stats">
      {props.isInternalCoach && props.totalMeetings ? (
        <div className="cute-6-phone cute-12-desktop">
          <div className="participant-v5__meeting-stats--number">
            <span className="tc-v5-meeting"> </span>
            {props.totalMeetings - props.meetingsHeld}
          </div>
          <div className="participant-v5__meeting-stats--text">
            Meetings remaining
          </div>
        </div>
      ) : null}
      <div className="cute-6-phone cute-12-desktop">
        <div className="participant-v5__meeting-stats--number">
          <span className="tc-v5-meeting-complete">
            <span className="path1"></span>
            <span className="path2"> </span>
          </span>
          {props.meetingsHeld}
        </div>
        <div className="participant-v5__meeting-stats--text">
          Meetings held to date
        </div>
      </div>
      <div className="cute-6-phone cute-12-desktop">
        <div className="participant-v5__meeting-stats--number missed">
          <span className="tc-v5-meeting-missed">
            <span className="path1"></span>
            <span className="path2"> </span>
          </span>
          {props.meetingsMissed}
        </div>
        <div className="participant-v5__meeting-stats--text">
          Meetings missed
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

MeetingStats.propTypes = {
  totalMeetings: PropTypes.number,
  meetingsHeld: PropTypes.number.isRequired,
  meetingsMissed: PropTypes.number.isRequired,
};

export default MeetingStats;
