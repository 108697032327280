// @ts-nocheck FIXME
import '@legacy-components/components/DynamicUtils';
import moment from 'moment';
import propTypes from 'prop-types';
import React from 'react';
import CapacitySessionDetails from './CapacitySessionDetails';

const CapacityDetail = (props) => {
  const totalNumSessions = props.sessions.sessions.length;
  const { date, hour, quarter } = props.showEventDetail;
  const time = moment(`${hour}:${quarter * 15}`, 'h:m').format('h:mm A');
  const timeZoneAbbr = moment.tz(props.timeZone).format('z');
  const formattedDateTime = `${moment(date).format(
    'MMM D, YYYY'
  )} - ${time} ${timeZoneAbbr}`;

  // count unique certificates in all sessions in this quarter
  const certificationsMap = new Map();

  // store certification id and display values into a map for easier access
  const certificationNames = new Map();

  // populate the map with predefined certificates
  for (const cert of props.certifications) {
    certificationsMap.set(cert.id, 0);
    certificationNames.set(cert.id, cert.name);
  }

  for (const session of props.sessions.sessions) {
    for (const cert of session.certifications) {
      // count each certification
      if (certificationsMap.has(cert)) {
        certificationsMap.set(cert, certificationsMap.get(cert) + 1);
      }
    }
  }

  // wrap hide event detail logic inside useEffect,
  // so we only update the component after other components have rendered
  React.useEffect(() => {
    if (
      totalNumSessions === 0 ||
      (props.activeCert !== 'all' &&
        certificationsMap.get(props.activeCert) === 0)
    ) {
      props.hideEventDetail();
    }
  }, [props.sessions, props.showEventDetail, props.activeCert]);

  // store certifications in an array for easier access later
  const certificationsCount = [];

  // populate the array with id, display name and count
  certificationsMap.forEach((key, value) => {
    certificationsCount.push({
      id: value,
      name: certificationNames.get(value),
      count: key,
    });
  });

  // store session details filtered by activeCert, pass into CapacitySessionDetail
  const sessions = [];

  // stores capacity details to be displayed
  const sessionCapacityNode = [];

  /**
   * given certification object and active certificate filter, return formatted HTML div
   * @param {object} certification object containing name and count
   * @param {boolean} overall whether the passed in certification is overall capacity
   * @param {string} highlightCert name of certification to be highlighted
   * @returns formatted HTML div with name, capacity, and correct color
   */
  const formatCertificationCapacity = (
    certification,
    overall = false,
    highlightCert = props.activeCert
  ) => {
    if (!certification) return null;
    let highlightClass = '';
    if (overall) {
      // highlight overall tag if the certification passed in is overall, and filter is 'all'
      if (highlightCert === 'all') {
        highlightClass = 'events-cal__capacity-detail-text--highlight';
      }
    } else {
      // otherwise, highlight the certification based on filter
      highlightClass =
        certification.id === highlightCert
          ? 'events-cal__capacity-detail-text--highlight'
          : '';
    }

    // only show available capacity if this certification has a capacity set,
    // or if we can default to the "general" capacity
    let capacityAvailable = null;
    if (props.capacities.general || certification.id in props.capacities) {
      const capacity =
        certification.id in props.capacities
          ? props.capacities[certification.id]
          : props.capacities.general;
      capacityAvailable =
        certification.count > capacity ? 0 : capacity - certification.count;
    }

    return (
      <div
        className="events-cal__capacity-detail-availability"
        key={`capacity-detail-${certification.id}`}
      >
        <span className={`events-cal__capacity-detail-text ${highlightClass}`}>
          {certification.name}:&nbsp;
        </span>
        <span className="events-cal__text--bold events-cal__capacity-detail-count">
          {certification.count}
          {capacityAvailable !== null && ` (${capacityAvailable} available)`}
        </span>
      </div>
    );
  };

  // push today's date
  sessionCapacityNode.push(
    <div
      className="events-cal__text--bold events-cal__capacity-detail-availability"
      key="capacity-detail-time"
    >
      {formattedDateTime}
    </div>
  );

  // push the overall count
  sessionCapacityNode.push(
    formatCertificationCapacity(
      {
        id: 'general',
        name: 'Overall Capacity',
        count: totalNumSessions,
      },
      true
    )
  );

  // push count for each certification
  for (const cert of certificationsCount) {
    sessionCapacityNode.push(formatCertificationCapacity(cert));
  }

  // only populate session details that match certification filter
  props.sessions.sessions.map((session) => {
    if (props.activeCert !== 'all') {
      if (session.certifications.includes(props.activeCert)) {
        sessions.push(session);
      }
    } else {
      // props.activeCert === 'all'
      sessions.push(session);
    }
  });

  return (
    <div className="events-cal__capacity-detail">
      <div
        className="events-cal__capacity-detail-capacity-container"
        key="capacity-names"
      >
        {sessionCapacityNode}
      </div>

      <CapacitySessionDetails
        showEventDetail={props.showEventDetail}
        sessions={sessions}
        timeZone={props.timeZone}
      />
      <button
        className="events-cal__hide-btn"
        onClick={props.hideEventDetail}
        data-testid="hide-btn"
      >
        <span className="events-cal__hide-text">hide</span>
        <div className="tc-v4-circle-arrow events-cal__down-arrow"></div>
      </button>
    </div>
  );
};

CapacityDetail.propTypes = {
  sessions: propTypes.object.isRequired,
  showEventDetail: propTypes.object.isRequired,
  hideEventDetail: propTypes.func.isRequired,
  activeCert: propTypes.string.isRequired,
  certifications: propTypes.array.isRequired,
  capacities: propTypes.object.isRequired,
  timeZone: propTypes.string.isRequired,
};

export default CapacityDetail;
