// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ConfirmDelete from './../ConfirmDelete';
import DomainTag from './DomainTag';

const GoalEditView = (props) => {
  // keeps track of what user typed in the search box
  const [title, setTitle] = useState(props.title || '');
  // keeps track of what sub focus area the user selected
  const [subFocusArea, setSubFocusArea] = useState(
    (props.focus_area && {
      title: props.title,
      id: props.sub_focus_area_id,
      focus_area_id: props.focus_area.id,
      focus_area_title: props.focus_area.title,
      focus_area_tagline: props.focus_area.tagline,
    }) ||
      null
  );

  const initializeSearchOptions = () => {
    if (!props.subFocusAreas) {
      return [];
    }

    if (!props.cohortFocusAreas || !props.cohortFocusAreas.length) {
      return props.subFocusAreas;
    }

    if (props.domains.find((domain) => domain.label === 'ALL Focus Areas')) {
      return props.subFocusAreas;
    }

    return props.subFocusAreas.filter((area) =>
      props.cohortFocusAreas.includes(area.focus_area_id)
    );
  };

  const [searchOptions] = useState(initializeSearchOptions());
  const [description, updateDescription] = useState(props.description || '');
  const [isConfirmDelete, updateConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // keeps track of list of search suggestions
  const [searchResults, setSearchResults] = useState([]);
  // to check if user typed in titles field AFTER they selected an option
  // or typed and changed pre-filled title
  const [optionSelected, setOptionSelected] = useState(
    (subFocusArea && !!subFocusArea.id) || false
  );
  const [isTitleFocused, setIsTitleFocused] = useState(false);

  const existingSubFocusAreas = props.existingSubFocusAreas
    ? props.existingSubFocusAreas
    : [];

  const updateTitle = (title) => {
    //whenever user starts to alter titles we should flag it
    setOptionSelected(false);
    setTitle(title);
    handleSearch(title);
  };

  const handleSearch = (searchText) => {
    const searchRegex = new RegExp(searchText || '', 'i');
    const newSearchResults = searchOptions.filter((area) => {
      const isNotAnExistingFocusArea = !existingSubFocusAreas.includes(area.id);
      const isCurrentlySelected = subFocusArea && subFocusArea.id === area.id;
      const matchesTitle = area.title.search(searchRegex) !== -1;
      const matchesFocusAreaTitle =
        area.focus_area_title.search(searchRegex) !== -1;
      return (
        (isNotAnExistingFocusArea || isCurrentlySelected) &&
        (matchesTitle || matchesFocusAreaTitle)
      );
    });

    setSearchResults(newSearchResults);
  };

  const completeSearchSuggestion = (selectedItem) => {
    setTitle(selectedItem.title);
    setSubFocusArea(selectedItem);
    // set the flag to mark that user selected an option
    setOptionSelected(true);
    setIsTitleFocused(false);
  };

  const handleTitleFocus = () => {
    setIsTitleFocused(true);
    handleFocus('title');
    handleSearch(title);
  };

  const handleFocus = (field) => {
    // clear any field error
    if (errors[field]) {
      const newErrors = Utils.clone(errors);
      delete newErrors[field];
      setErrors(newErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // first, clear any form errors
    setErrors({});

    // validation
    const formErrors = {};
    if (!subFocusArea || !subFocusArea.id || !optionSelected) {
      formErrors.title = 'Hold on, you need to select an outcome to continue.';
    }
    if (!description) {
      formErrors.description =
        'Hold on, you need to enter a description to continue.';
    }
    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setIsLoading(true);

    // make api call in the workspace container
    const apiData = {
      ...subFocusArea,
      sub_focus_area_id: subFocusArea.id,
      description: description,
    };
    const errorCallback = () => setIsLoading(false);
    props.submitGoal(apiData, errorCallback);
  };

  const handleDelete = () => {
    props.deleteGoal({
      goalId: props.id,
    });
  };

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  // only show the delete icon when editing a goal, not when creating a new goal
  let deleteIconNode;
  if (props.goalEditing) {
    deleteIconNode = (
      <span
        onClick={() => updateConfirmDelete(true)}
        data-testid="delete-goal-icon"
        className="tc-delete workspace__delete-icon"
      ></span>
    );
  }

  // show any items in the search autocomplete list
  let searchAutocompleteNode;
  if (searchResults.length && isTitleFocused) {
    searchAutocompleteNode = (
      <ul className="workspace__goal-search-autocomplete tc__body-copy">
        {searchResults.map((item) => {
          const itemNode = (
            <li
              key={`sub-focus-area-${item.id}`}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => completeSearchSuggestion(item)}
              className="workspace__goal-search-autocomplete-item"
            >
              <DomainTag
                id={item.focus_area_id}
                label={item.focus_area_title}
                tagline={item.focus_area_tagline}
              />
              <div className="workspace__goal-search-suggestion-text">
                {item.title}
              </div>
            </li>
          );
          return itemNode;
        })}
      </ul>
    );
  }

  // if we have a selected sub focus area, then show the focus area tag
  let focusAreaTagNode = (
    <div className="workspace__domain-tag-placeholder"></div>
  );
  if (subFocusArea && subFocusArea.focus_area_id && optionSelected) {
    focusAreaTagNode = (
      <DomainTag
        id={subFocusArea.focus_area_id}
        tagline={subFocusArea.focus_area_tagline}
        label={subFocusArea.focus_area_title}
      />
    );
  }

  // the body of the capsule is normally the goal form
  let formNode = (
    <form className="workspace__goal-form">
      <FormField
        type="text"
        className="workspace__goal-form--title"
        isControlled={true}
        placeholder="Begin typing to search for an outcome."
        value={title}
        autoFocus={true}
        onFocus={handleTitleFocus}
        onBlur={() => setIsTitleFocused(false)}
        onChange={(e) => updateTitle(e.target.value)}
        error={errors.title}
      />

      {searchAutocompleteNode}
      {focusAreaTagNode}

      <FormField
        type="textarea"
        rows="4"
        isControlled={true}
        placeholder="What are you hoping to achieve? What does success look like?"
        value={description}
        onFocus={() => handleFocus('description')}
        onChange={(e) => {
          updateDescription(e.target.value);
        }}
        error={errors.description}
      />

      <div className="workspace__btns-row">
        <button
          type="button"
          className="v5__btn v5__btn--blue-border workspace__cancel-link"
          onClick={() => props.cancelGoalEditing()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="v5__btn"
          {...btnProps}
          onClick={handleSubmit}
        >
          <Spinner loading={isLoading} />
          Save
        </button>
        {deleteIconNode}
      </div>
    </form>
  );
  // if the delete icon is clicked, we instead show the delete confirmation
  if (isConfirmDelete) {
    formNode = (
      <div className="workspace__goal-form">
        <ConfirmDelete
          headlineText="Delete this outcome?"
          bodyText="It will be permanently deleted. This outcome tag will be removed from entries and any highlighted entries with this outcome will be unhighlighted."
          delete={handleDelete}
          cancel={() => updateConfirmDelete(false)}
        />
      </div>
    );
  }

  return <div className="workspace__goal">{formNode}</div>;
};

GoalEditView.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  existingSubFocusAreas: PropTypes.array,
  goalEditing: PropTypes.bool,
  cancelGoalEditing: PropTypes.func.isRequired,
  submitGoal: PropTypes.func.isRequired,
};

export default GoalEditView;
