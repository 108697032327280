// @ts-nocheck FIXME
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import UserPhoto from './../../../../V5/Common/UserPhoto';

const ReservationForm = (props) => {
  const handleReserve = () => {
    props.reserveEvent({});
  };

  const changeUsers = () => {
    props.logout();
    props.setHasAccount(true);
    props.setIsLoggedIn(false);
    props.clearErrors();
    props.setRecaptchaResponse(null);
  };

  let userConfirmationNode = null;
  if (props.isLoggedIn) {
    userConfirmationNode = (
      <div className="reservation__user-photo-row">
        <UserPhoto
          src={props.user.photo}
          firstName={props.user.first_name}
          lastName={props.user.last_name}
          userId={props.user.id}
          diameter={40}
          className="search__owner-image left"
        />
        <span>
          <div className="registration-v5__user-name reservation__reservation-form-user-name">
            {`${props.user.first_name} ${props.user.last_name}, ${props.user.email}`}
          </div>
          <div className="v5__comment-copy">
            Not you?{' '}
            <span className="tc__link" onClick={changeUsers}>
              {' '}
              Log in
            </span>
          </div>
        </span>
      </div>
    );
  } else if (props.accountCreated) {
    userConfirmationNode = (
      <div>
        <span className="check-icon"></span>
        <div>{`${props.user.first_name} ${props.user.last_name}, ${props.user.email}`}</div>
      </div>
    );
  }

  const btnDisabledClass = props.ui.loading ? ' disabled' : '';

  return (
    <div className="registration-v5-event__register">
      <div className="registration-v5-event__register-header v5__heading-3">
        Register
      </div>
      <div className="registration-v5-event__register-body v5__body-copy">
        <div className="reservation__form-row">
          <div className="cute-12-desktop v5__heading-5">
            Welcome, {props.user.first_name}!
          </div>

          <div className="cute-12-desktop v5__body-copy">
            {userConfirmationNode}
          </div>

          <div className="cute-12-desktop v5__body-copy">
            We&apos;re excited to have you join us. <br />
            Continue to complete your registration.
          </div>
        </div>

        <div className="reservation__form-row">
          <button
            className={'v5__btn' + btnDisabledClass}
            onClick={handleReserve}
          >
            Continue
            <Spinner
              className="admin__littlespinner"
              loading={props.ui.loading}
            />
          </button>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default ReservationForm;
