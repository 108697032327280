// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../NavigationLink';

const DropdownLink = (props) => {
  const activeClass = props.currentPage ? ' active' : '';
  return (
    <li>
      <NavigationLink
        url={props.url + '?from=tc_menu'}
        target={props.target}
        className={'header__dropdown-link' + activeClass}
      >
        {props.children}
      </NavigationLink>
    </li>
  );
};

DropdownLink.propTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  currentPage: PropTypes.bool,
};

export default DropdownLink;
