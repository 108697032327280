// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import Utils from '@legacy-components/components/StaticUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import ResultCapsule from '@legacy-components/react/V4/Search/modules/ResultCapsule';
import PropTypes from 'prop-types';

const Strategies = (props) => {
  // clicking on a learning domain tag in the strategy capsule goes to search results
  const handleSearch = (searchFilter) => {
    Router.route(`/search?focusAreas=${searchFilter.filterId}`);
  };

  const strategyCount = props.strategies.length;

  const handleChangeDomain = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    Router.route(
      `${process.env.REACT_APP_TEACHING_URL}/browse/master_teacher/${props.teacher.id}/${props.teacher.seo_url}/strategies/${e.target.value}`
    );
  };

  // returns the domain selector option for the current domain
  const getCurrentDomainOption = (unclickable) => {
    const optionClass = unclickable
      ? ' mt-profile__category-select-option--unclickable'
      : '';
    const iconClass = `tc-v4-domain-${Utils.getDomainIdForIcon(
      props.domainId
    )}`;
    const arrowNode = unclickable ? null : (
      <div className="tc-bold-arrow mt-profile__category-select-option-arrow"></div>
    );

    return (
      <div
        className={`mt-profile__category-select-option mt-profile__category-select-option--current${optionClass}`}
      >
        <div
          className={`mt-profile__category-select-option-icon mt-profile__category-select-option-icon--strategy show-desktop hide-phone ${iconClass}`}
        ></div>
        <div className="mt-profile__category-select-option-name">
          {props.domainName}
          <span className="mt-profile__category-select-option-count">
            ({strategyCount} Strateg{strategyCount === 1 ? 'y' : 'ies'})
          </span>
        </div>
        {arrowNode}
      </div>
    );
  };

  // set up the current domain capsule
  let domainSelectNode = (
    <div className="mt-profile__category-select">
      {getCurrentDomainOption(true)}
    </div>
  );
  // if the teacher has multiple domains, clicking the capsule opens a selector
  if (props.domains.length > 1) {
    // set up the dropdown options
    const mobileDomainOptions = [];
    const desktopDomainOptions = [];
    props.domains.forEach((domain) => {
      mobileDomainOptions.push(
        <option value={domain.id} key={'select-option-' + domain.id}>
          {domain.title} ({domain.strategyCount} Strategies)
        </option>
      );

      desktopDomainOptions.push(
        <NavigationLink
          className="mt-profile__category-select-option tc__link"
          url={`${process.env.REACT_APP_TEACHING_URL}/browse/master_teacher/${props.teacher.id}/${props.teacher.seo_url}/strategies/${domain.id}`}
          key={'option-' + domain.id}
        >
          <div
            className={`mt-profile__category-select-option-icon mt-profile__category-select-option-icon--strategy tc-v4-domain-${Utils.getDomainIdForIcon(
              domain.id
            )}`}
          ></div>
          <div className="mt-profile__category-select-option-name">
            {domain.title}
            <span className="mt-profile__category-select-option-count">
              ({domain.strategyCount} Strateg
              {domain.strategyCount === 1 ? 'y' : 'ies'})
            </span>
          </div>
        </NavigationLink>
      );
    });

    // set up the desktop version of the domain selector dropdown if it has been opened
    let desktopDropdownNode;
    if (props.domainSelectorOpened) {
      desktopDropdownNode = (
        <div className="show-desktop hide-phone mt-profile__category-select-dropdown">
          {desktopDomainOptions}
        </div>
      );
    }

    domainSelectNode = (
      <div
        className="mt-profile__category-select mt-profile__category-select--clickable"
        onClick={props.openDomainSelector}
      >
        {getCurrentDomainOption(false)}
        <select
          onChange={handleChangeDomain}
          className="mt-profile__mobile-select show-phone hide-desktop"
          value={props.domainId}
        >
          {mobileDomainOptions}
        </select>

        {desktopDropdownNode}
      </div>
    );
  }

  return (
    <div className="cute-9-desktop cute-12-phone mt-profile__content-col">
      <div className="mt-profile__courses mt-profile__courses--less-margin show-desktop hide-phone">
        <div className="mt-profile__course mt-profile__course--no-margin">
          Strategies
        </div>
      </div>

      {domainSelectNode}

      <div className="mt-profile__result-capsules">
        {props.strategies.map((strategy) => {
          return (
            <ResultCapsule
              key={`strategy-${strategy.id}`}
              id={strategy.id}
              type="strategy"
              preview={strategy.preview}
              title={strategy.title}
              description={strategy.description}
              focus_areas={strategy.focus_areas}
              hideAuthor={true}
              resource_count={strategy.resource_count}
              favorite_count={strategy.favorite_count}
              updateFilters={handleSearch}
              favorite={strategy.favorite}
              recommend={props.recommendModal.open}
              addFavorite={props.addFavorite}
              removeFavorite={props.removeFavorite}
              user={props.user}
              searchContext="domain_master_teacher_profile"
            />
          );
        })}
      </div>
    </div>
  );
};

Strategies.propTypes = {
  domainId: PropTypes.number.isRequired,
  domainName: PropTypes.string.isRequired,
  domains: PropTypes.array.isRequired,
  strategies: PropTypes.array.isRequired,
  teacher: PropTypes.object.isRequired,
  recommendModal: PropTypes.object.isRequired,
  domainSelectorOpened: PropTypes.bool,
  openDomainSelector: PropTypes.func,
};

Strategies.defaultProps = {
  strategies: [],
};

export default Strategies;
