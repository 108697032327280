// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const CoachMatchDetails = (props) => {
  let gradesNode = null;
  if (props.participant.grades) {
    gradesNode = props.participant.grades.map((grade, i) => {
      const seperator =
        props.participant.grades.length > 1 &&
        i !== props.participant.grades.length
          ? ', '
          : '';

      return (
        <span
          className=""
          key={`participant-${props.participant.id}-grade-${grade.id}-${i}`}
        >
          {grade.label}
          {seperator}
        </span>
      );
    });
  }

  let subjectsNode = null;
  if (props.participant.subjects && props.participant.subjects.length) {
    subjectsNode = props.participant.subjects.map((subject, i) => {
      const seperator =
        props.participant.subjects.length > 1 &&
        i !== props.participant.subjects.length
          ? ', '
          : '';

      return (
        <span
          className=""
          key={`participant-${props.participant.id}-subject-${subject.id}-${i}`}
        >
          {subject.label}
          {seperator}
        </span>
      );
    });
  }

  let subjectNotesNode = null;
  if (props.participant.subject_notes) {
    subjectNotesNode = (
      <React.Fragment>
        <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
          Subject Notes
        </div>
        <div className="">{props.participant.subject_notes}</div>
      </React.Fragment>
    );
  }

  let availabilityNode = null;
  // sort availabilities in ascending order by id so the time stays consistent
  const participantAvailabilities = props.participant.availability
    ? Utils.clone(props.participant.availability)
    : [];
  participantAvailabilities.sort((a, b) => a.id - b.id);
  if (participantAvailabilities && participantAvailabilities.length) {
    const availabilities = participantAvailabilities.map((availability) => {
      const selectedClass =
        availability.answer === 'yes' ? 'coach-match__prefered-time' : '';

      // convert participant time availability to coach (viewer) timezone
      const convertedTime = Utils.formatAvailabilityTimeOptions(
        availability.label,
        props.user.time_zone.pytz_timezone,
        props.coachTimeZone
      );
      // if the availability is 'over the weekend' (id = 16) then don't do the conversion (hide the timezone as well)
      const participantTime =
        availability.id === 16
          ? availability.label
          : `${availability.label} ${moment
              .tz(props.user.time_zone.pytz_timezone)
              .zoneAbbr()}`;

      const coachTime =
        availability.id === 16
          ? availability.label
          : `${convertedTime} ${moment.tz(props.coachTimeZone).zoneAbbr()}`;
      // display coach time first, then participant time, if they are the same then only display one
      return (
        <li
          className={selectedClass}
          key={`user-${props.user.id}-availability-${availability.id}`}
        >
          {participantTime === coachTime
            ? `${participantTime}`
            : `${coachTime} / ${participantTime}`}
        </li>
      );
    });
    availabilityNode = (
      <React.Fragment>
        <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy v5__body-copy--bold">
          Availability
        </div>
        <ul className="coach-match__list">{availabilities}</ul>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="coach-match__headline--capsule coach-match__headline coach-match__headline--blueberry coach-match__headline v5__heading-5">
        About {props.user.first_name}
      </div>
      <section className="coach-match__row">
        <div className="coach-match__col--33">
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            Time Zone
          </div>
          <div className="">{props.user.time_zone.pytz_timezone || ''}</div>
          {availabilityNode}
        </div>
        <div className="coach-match__col--33">
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            Years Teaching
          </div>
          <div className="">{props.participant.years_teaching || ''}</div>
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            Grade Band
          </div>
          <div className="">{gradesNode}</div>
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            SPED
          </div>
          <div className="">
            {props.participant.sped || 'No response provided'}
          </div>
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            EL
          </div>
          <div className="">
            {props.participant.ell || 'No response provided'}
          </div>
        </div>
        <div className="coach-match__col--33">
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            Subject
          </div>
          <div className="">{subjectsNode}</div>
          {subjectNotesNode}
        </div>
      </section>
    </React.Fragment>
  );
};

export default CoachMatchDetails;

CoachMatchDetails.propTypes = {
  participant: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
