// @ts-nocheck FIXME
import { Router } from './../../../components/Router';
import ScaleCenterImage from './../../ScaleCenterImage';

const TeacherSpotlight = (props) => {
  const displayTeacherDetails = (e) => {
    e.preventDefault();
    let tab = props.tab.replace('-', '_');
    if (props.showDetailed) {
      tab = 'teacher_list';
    }
    Router.route(
      '/coaching/lab/partner/portfolio/' +
        props.portfolio_guid +
        '/page/' +
        props.tab +
        '/spotlight/' +
        props.id +
        '?from=' +
        tab
    );
  };

  // use default teacher image if teacher has none
  const teacherImage = props.data.teacher_image
    ? props.data.teacher_image
    : '/legacy-images/default-user.svg';

  return (
    <div className="row">
      <div
        onClick={displayTeacherDetails}
        className="ppp__elem ppp__elem--teacher cute-10-desktop cute-1-desktop-offset left"
      >
        <div className="ppp__teacher-col ppp__teacher-col--name">
          <ScaleCenterImage src={teacherImage} className="ppp__user-image" />
          <div className="ppp__teacher-sl-author">
            <span className="ppp__teacher-sl-author-name">
              {props.data.teacher_name}
            </span>
          </div>
        </div>
        {props.data.coach_meetings ? (
          <div className="ppp__teacher-col">
            <span className="ppp__teacher-sl-number">
              {props.data.coach_meetings}
            </span>
            <span className="ppp__section-subheadline">
              Coach {props.data.coach_meetings == 1 ? 'Meeting' : 'Meetings'}
            </span>
          </div>
        ) : (
          <noscript />
        )}
        {props.data.strategies_tried ? (
          <div className="ppp__teacher-col">
            <span className="ppp__teacher-sl-number">
              {props.data.strategies_tried}
            </span>
            <span className="ppp__section-subheadline">
              {props.data.strategies_tried == 1 ? 'Strategy' : 'Strategies'}{' '}
              Tried
            </span>
          </div>
        ) : (
          <noscript />
        )}
        {props.data.artifacts_uploaded ? (
          <div className="ppp__teacher-col">
            <span className="ppp__teacher-sl-number">
              {props.data.artifacts_uploaded}
            </span>
            <span className="ppp__section-subheadline">
              {props.data.artifacts_uploaded == 1 ? 'Artifact' : 'Artifacts'}{' '}
              Uploaded
            </span>
          </div>
        ) : (
          <noscript />
        )}
      </div>
    </div>
  );
};

export default TeacherSpotlight;
