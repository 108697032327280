// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import Analytics from './../../../components/Analytics';

const SingleSelect = (props) => {
  let textInput;
  // We would like to wait for the user to stop typing before we fire calls to the server
  let onChangeTimeout = null;

  const handleSelectClick = (e) => {
    e.stopPropagation();

    if (props.readOnly) {
      return false;
    }

    textInput.focus();
    props.updateSingleSelect({
      field: props.field,
      feature: props.feature,
      index: props.index,
      data: { inputStatus: 'focus' },
    });

    delayedEventListener(() =>
      window.addEventListener('click', closeOptionsDropdown)
    );
  };

  const handleSelectOption = (option, e) => {
    e.preventDefault();

    if (props.readOnly) {
      return false;
    }

    textInput.value = option.label;
    const data = {
      visibleOptions: [],
      selectedOption: option,
      charCount: 0,
      inputStatus: 'blur',
    };
    props.updateSingleSelect({
      field: props.field,
      feature: props.feature,
      index: props.index,
      data: data,
    });
    if (props.selectAnalytics) {
      Analytics.logEvent(props.selectAnalytics);
    }
  };

  const handleClearOption = (e) => {
    e.preventDefault();

    if (props.readOnly) {
      return false;
    }

    textInput.value = '';
    props.updateSingleSelect({
      field: props.field,
      feature: props.feature,
      index: props.index,
      data: {
        visibleOptions: [],
        selectedOption: null,
        inputStatus: 'blur',
      },
    });

    // If the parent provided a clear option handler, call it
    if (
      props.handleClearOption &&
      typeof props.handleClearOption === 'function'
    ) {
      props.handleClearOption(e);
    }
    if (props.removeAnalytics) {
      Analytics.logEvent(props.removeAnalytics);
    }
  };

  const handleFocus = (e) => {
    if (props.readOnly) {
      return false;
    }

    const input = textInput.value.toLowerCase();
    if (input.length >= props.minCharCountForResults) {
      props.getVisibleOptions({
        field: props.field,
        index: props.index,
        feature: props.feature,
        input: input,
      });
    }

    props.updateSingleSelect({
      field: props.field,
      feature: props.feature,
      index: props.index,
      data: { inputStatus: 'focus' },
    });
    // If the parent provided a focus handler, call it
    if (props.handleFocus && typeof props.handleFocus === 'function') {
      props.handleFocus(e);
    }
    if (props.focusAnalytics) {
      Analytics.logEvent(props.focusAnalytics);
    }
  };

  const closeOptionsDropdown = () => {
    if (props.readOnly) {
      return false;
    }

    window.removeEventListener('click', closeOptionsDropdown);
    props.updateSingleSelect({
      field: props.field,
      feature: props.feature,
      index: props.index,
      data: { inputStatus: 'blur' },
    });
  };

  const handleTyping = () => {
    if (props.readOnly) {
      return false;
    }

    if (props.restrictTo) {
      const restrictTo = new RegExp(props.restrictTo);
      if (!restrictTo.test(textInput.value)) {
        textInput.value = textInput.value.slice(0, -1);
      }
    }

    // Parent component may request to delay firing the event (e.g. 500 ms)
    // to give the user some room to finish typing
    const onChangeDelay = props.onChangeDelay || 0;

    clearTimeout(onChangeTimeout);

    onChangeTimeout = setTimeout(function () {
      // Handle non-existing input
      if (!textInput) {
        return;
      }
      const input = textInput.value.toLowerCase();
      if (input.length >= props.minCharCountForResults) {
        props.getVisibleOptions({
          field: props.field,
          feature: props.feature,
          index: props.index,
          input: input,
        });
      }
    }, onChangeDelay);
  };

  const availableNodes = props.visibleOptions
    .filter((option) => {
      // when the input is blurred... nothing is available
      if (
        props.inputStatus === 'blur' ||
        props.charCount < props.minCharCountForResults
      ) {
        return false;
      }
      // if the value is selected, it is not available
      if (props.selectedOption && props.selectedOption.id === option.id) {
        return false;
      }
      return true;
    })
    .map((option, i) => {
      return (
        <div
          className="custom-select__option custom-select__option--variable-height"
          onClick={(e) => handleSelectOption(option, e)}
          key={props.field + '-' + option.id + '-' + i}
        >
          <div>{option.label}</div>
          <div className="custom-select__option-description">
            {option.description}
          </div>
        </div>
      );
    });

  // only show the options menu if there are visible options
  let optionsNode = null;
  if (availableNodes.length) {
    optionsNode = (
      <div className="custom-select__options">{availableNodes}</div>
    );
  }

  let singleSelectClass = ' custom-select__selected-options--empty';
  let selectedNode = '';
  if (props.selectedOption) {
    singleSelectClass = ' custom-select__selected-options--selected';
    selectedNode = (
      <div className="custom-select__selected custom-select__selected--single-select">
        <div className="custom-select__selected-name">
          {props.selectedOption.label}
        </div>
        <div
          onClick={handleClearOption}
          className="custom-select__selected-remove tc-x"
        />
      </div>
    );
  }

  return (
    <div className="custom-select__container">
      <div className="custom-select tc__input" onClick={handleSelectClick}>
        <div className={'custom-select__selected-options' + singleSelectClass}>
          <input
            type="text"
            ref={(ref) => (textInput = ref)}
            className="tc__input tc__input--custom-select tc__input--custom-single-select tc__input--custom-select-empty"
            placeholder={props.placeholder}
            defaultValue={props.selectedOption && props.selectedOption.label}
            readOnly={props.selectedOption}
            onChange={handleTyping}
            onFocus={handleFocus}
          />
          {selectedNode}
        </div>
      </div>
      {optionsNode}
    </div>
  );
};

SingleSelect.propTypes = {
  field: PropTypes.string.isRequired,
  feature: PropTypes.string,
  placeholder: PropTypes.string,
  inputStatus: PropTypes.string.isRequired,
  minCharCountForResults: PropTypes.number,
  charCount: PropTypes.number,
  selectedOption: PropTypes.object,
  visibleOptions: PropTypes.array,
  updateSingleSelect: PropTypes.func,
  getVisibleOptions: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  restrictTo: PropTypes.string,
};

SingleSelect.defaultProps = {
  minCharCountForResults: 2,
  readOnly: false,
};

export default SingleSelect;
