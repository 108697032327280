// @ts-nocheck FIXME
import PartnerApi from './../apis/PartnerApi';
import Store from './../components/Store';

// convenience vars
const defaultUi = {
  isLoading: false,
  error: null,
  notifications: [],
  modalDisplayed: false,
  notificationLink: {},
};

const store = {
  partners: [],
  partner: null,
  partnerUsers: [],
  ui: {
    alert: {
      type: null,
      message: null,
      visible: false,
    },
    addPartner: Object.assign({}, defaultUi),
    addPartnerUser: Object.assign({}, defaultUi),
    editPartner: Object.assign({}, defaultUi),
  },
};

/****** PRIVATE FUNCTIONS ******/

// create new partner
const addPartner = (record) => {
  store.partners.unshift(record);
  store.ui.addPartner.isLoading = false;
  store.ui.addPartner.error = null;
  store.ui.addPartner.notifications = [];
  store.ui.addPartner.modalDisplayed = false;
};

// edit partner
const editPartner = (record) => {
  store.partner.name = record.name;
  store.partner.shortcode = record.shortcode;
  store.ui.editPartner.isLoading = false;
  store.ui.editPartner.error = null;
  store.ui.editPartner.notifications = [];
  store.ui.editPartner.modalDisplayed = false;
};

// add a partner to a user
const addPartnerUser = (record) => {
  store.partnerUsers.unshift(record);
  store.ui.addPartnerUser.isLoading = false;
  store.ui.addPartnerUser.error = null;
  store.ui.addPartnerUser.notifications = [];
  store.ui.addPartnerUser.modalDisplayed = false;
};

// set the loading to dissable buttons
const setIsLoading = (data) => {
  store.ui[data.feature].isLoading = data.isLoading;
};

// display modal inline error
const setError = (data) => {
  store.ui[data.feature].error = data.error;
  store.ui[data.feature].notifications = [];
  store.ui[data.feature].isLoading = false;
};

// display modal inline notifications
const setNotification = (data) => {
  store.ui[data.feature].notifications = data.notifications;
  store.ui[data.feature].error = null;
  store.ui[data.feature].isLoading = false;
  store.ui[data.feature].notificationLink = data.link || {};
};

// flash message actions
const setFlashMessage = (alert) => {
  store.ui.alert.visible = true;
  store.ui.alert.type = alert.type;
  store.ui.alert.message = alert.message;
};

const setupFlashAlert = (alert) => {
  setFlashMessage(alert);
  let flashMessageTimeout;
  flashMessageTimeout = setTimeout(() => {
    store.clearFlashMessage();
  }, 3000);
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.partners = data.partners || [];
  store.partner = data.partner || null;
  store.partnerUsers = data.partnerUsers || [];

  // always reset ui
  store.ui.addPartner = Object.assign({}, defaultUi);
  store.ui.addPartnerUser = Object.assign({}, defaultUi);
  store.ui.editPartner = Object.assign({}, defaultUi);

  // don't trigger event
  return false;
};

// show or hide the modal
store.toggleModal = (data) => {
  if (!store.ui[data.feature]) {
    return false;
  }
  store.ui[data.feature].modalDisplayed =
    !store.ui[data.feature].modalDisplayed;
  for (const key in defaultUi) {
    if (key == 'modalDisplayed') {
      continue;
    }
    store.ui[data.feature][key] = defaultUi[key];
  }
};

// clear the errors when input is focused on
store.clearErrorsOnFocus = (data) => {
  let emitEvent = false;
  if (!store.ui[data.feature]) {
    return emitEvent;
  }
  if (
    store.ui[data.feature].error != null ||
    store.ui[data.feature].notifications.length > 0
  ) {
    emitEvent = true;
  }
  store.ui[data.feature].error = null;
  store.ui[data.feature].notifications = [];
  store.ui[data.feature].notificationLink = {};

  return emitEvent;
};

// initialize flash message
store.clearFlashMessage = () => {
  store.ui.alert.type = null;
  store.ui.alert.message = null;
  store.ui.alert.visible = false;
};

store.addPartner = (data) => {
  const feature = 'addPartner';
  const notifications = [];

  // validate data
  if (!data.name.length) {
    notifications.push('Partner name can not be blank.');
  }
  if (!data.code.length) {
    notifications.push('Partner code can not be blank.');
  }

  // ther are notifications display inline error
  if (notifications.length) {
    setNotification({
      notifications: notifications,
      feature: feature,
    });

    return;
  }

  // let UI know that component is loading
  setIsLoading({
    feature: feature,
    isLoading: true,
  });

  // do XHR call
  return PartnerApi.createPartner(data).then(
    // XHR success
    (response) => {
      // let UI know that component is done loading
      setIsLoading({
        feature: feature,
        isLoading: false,
      });

      // it worked
      if (response.status == 'ok') {
        // put newly added partner at the top of the list
        addPartner(response.payload.partner);

        // display flash success
        setupFlashAlert({
          type: 'success',
          message: 'Partner created successfully',
        });

        return;
      }

      // there are notifications
      if (response.status == 'notice') {
        setNotification({
          notifications: response.notices,
          feature: feature,
        });

        return;
      }
    },

    // XHR error
    (response) => {
      // let UI know that component is done loading
      setIsLoading({
        feature: feature,
        isLoading: false,
      });

      setError({
        error: 'Partner can not be saved at this time.',
        feature: feature,
      });
    }
  );
};

store.addPartnerUser = (data) => {
  const feature = 'addPartnerUser';
  const notifications = [];

  // validate data
  if (!data.email.length) {
    notifications.push('User email can not be blank.');
  }

  for (let i = 0; store.partnerUsers.length > i; i++) {
    if (store.partnerUsers[i].email != data.email) {
      continue;
    }
    notifications.push('Partner user already exists.');
    break;
  }

  if (notifications.length) {
    setNotification({
      notifications: notifications,
      feature: feature,
    });
    return;
  }

  // let UI know that component is loading
  setIsLoading({
    feature: feature,
    isLoading: true,
  });

  // do XHR call
  return PartnerApi.addPartnerUser(data).then(
    // XHR success
    (response) => {
      // let UI know that component is done loading
      setIsLoading({
        feature: feature,
        isLoading: false,
      });

      // it worked
      if (response.status == 'ok') {
        // put newly added partner at the top of the list
        addPartnerUser(response.payload.user);

        // set up succes flash message
        setupFlashAlert({
          type: 'success',
          message: 'User added to partner',
        });

        return;
      }

      // there are notifications
      if (response.status == 'notice') {
        setNotification({
          notifications: response.notices,
          feature: feature,
          link: response.link || {},
        });

        return;
      }
    },

    // XHR error
    (response) => {
      // let UI know that component is done loading
      setIsLoading({
        feature: feature,
        isLoading: false,
      });

      setError({
        error: 'User can not be added to this partner at this time.',
        feature: feature,
      });
    }
  );
};

store.editPartner = (data) => {
  const feature = 'editPartner';
  const notifications = [];

  // validate data
  if (!data.name.length) {
    notifications.push('Partner name can not be blank.');
  }
  if (!data.code.length) {
    notifications.push('Partner code can not be blank.');
  }
  if (notifications.length) {
    setNotification({
      notifications: notifications,
      feature: feature,
    });

    // setup flash message
    setupFlashAlert({
      message: notifications,
      type: 'error',
    });

    return;
  }

  // let UI know that component is loading
  setIsLoading({
    feature: feature,
    isLoading: true,
  });

  // do XHR call
  return PartnerApi.editPartner(data).then(
    // XHR success
    (response) => {
      // it worked
      if (response.status == 'ok') {
        // put newly added partner at the top of the list
        editPartner(response);

        // setup flash message
        setupFlashAlert({
          message: 'Partner edited successfully',
          type: 'success',
        });

        return;
      }

      // there are notifications
      if (response.status == 'notice') {
        setNotification({
          notifications: response.notices,
          feature: feature,
        });

        setupFlashAlert({
          message: response.notices,
          type: 'error',
        });

        return;
      }
    },

    // XHR error
    (response) => {
      const errorResponse = 'Partner can not be saved at this time.';
      setError({
        error: errorResponse,
        feature: feature,
      });

      setupFlashAlert({
        message: errorResponse,
        type: 'error',
      });
    }
  );
};

export default Store('PartnerStore', store);
