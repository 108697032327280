// @ts-nocheck FIXME
import React from 'react';
import SmoothScroll from './../../../../components/SmoothScroll';
import Utils from './../../../../components/StaticUtils';

const ScrollToWrapper = (
  ChildComponent,
  displayedTeacherId,
  clearScrollToTeacher
) => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component {
    scrollToSelectedTeacherRow = () => {
      const currentTeacherRow = document.getElementById(
        'ppp__teacher-cohort-teacher--' + displayedTeacherId
      );
      const currentTeacherRowPosition =
        Utils.getAbsolutePosition(currentTeacherRow);

      const scrollToPosition = currentTeacherRowPosition[1];
      const scrollFromPosition =
        (window.pageYOffset =
        document.documentElement.scrollTop =
          document.body.scrollTop);
      setTimeout(() => {
        SmoothScroll(document.body, scrollToPosition, 200, scrollFromPosition);
      }, 10);
    };

    componentDidMount() {
      if (!displayedTeacherId) {
        return;
      }

      this.scrollToSelectedTeacherRow();
      // we only want to scroll once when the teacher row is opened -
      // after that, we should clear the flag for scrolling
      clearScrollToTeacher();
    }

    render() {
      return ChildComponent;
    }
  };
};

export default ScrollToWrapper;
