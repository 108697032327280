// @ts-nocheck FIXME
import Modal from '@legacy-components/react/V5/Common/Modal';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import React from 'react';

const ConfirmDisconnectZoom = (props) => {
  // track loading state for Disconnect button
  const [isLoading, setIsLoading] = React.useState(false);

  const handleDisconnect = (e) => {
    e.preventDefault();

    setIsLoading(true);
    props.disconnectZoom();
  };

  // disable the button when loading
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <Modal close={props.close}>
      <form>
        <div className="v5__heading-3">Are you sure?</div>

        <div className="v5__body-copy workspace__form-row">
          All existing meetings will be disconnected as well. If you reconnect
          Zoom, your existing meetings will NOT be reconnected.
        </div>

        <div className="workspace__form-row">
          <button
            onClick={handleDisconnect}
            className="v5__btn v5__btn--red"
            {...btnProps}
          >
            <Spinner loading={isLoading} />
            Disconnect
          </button>
        </div>
        <div className="workspace__form-row">
          <button onClick={props.close} className="v5__link v5__link--grey">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

ConfirmDisconnectZoom.propTypes = {
  disconnectZoom: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default ConfirmDisconnectZoom;
