// @ts-nocheck FIXME
import Utils from '@legacy-components/components/StaticUtils';

const CUSTOM_DIMENSION_SEPARATOR = '#';

function setAnalyticsVariable(name, value) {
  const action = 'set';
  // If GA is available, send tracking data to GA
  if (window.ga) {
    window.ga(action, name, value);
  } else {
    console.log('ga', action, name, value);
  }
}

/**
 *
 * @param list e.g. [1, 2, 3]
 * @returns string that can be passed to GA in a single dimension (e.g. #1#2#3#)
 */
function stringifyList(list) {
  // Only arrays are accepted
  if (!Array.isArray(list)) {
    return;
  }
  const result = list
    .map(function (number) {
      return (
        CUSTOM_DIMENSION_SEPARATOR +
        number.toString() +
        CUSTOM_DIMENSION_SEPARATOR
      );
    })
    .join(CUSTOM_DIMENSION_SEPARATOR);
  // Get rid of consecutive separators
  const pattern = new RegExp(CUSTOM_DIMENSION_SEPARATOR + '{2,}', 'g');
  return result.replace(pattern, CUSTOM_DIMENSION_SEPARATOR);
}

/**
 *
 * @param object e.g. {id:12}
 * @returns string that can be passed to GA in a single dimension #id#12#
 */
function stringifyObject(obj) {
  // Only objects are accepted
  if (obj === null || typeof obj !== 'object' || Utils.isEmpty(obj)) {
    return;
  }
  let result = JSON.stringify(obj);
  // Replace double quotes with the analytics separator
  result = result.replace(/"/g, CUSTOM_DIMENSION_SEPARATOR);
  // Replace the opening and closing json curly brackets {}
  result = result.replace(/{/g, CUSTOM_DIMENSION_SEPARATOR);
  result = result.replace(/}/g, CUSTOM_DIMENSION_SEPARATOR);
  // Get rid of commas and colons
  result = result.replace(/,/g, '').replace(/:/g, '');
  // Get rid of consecutive separators
  const pattern = new RegExp(CUSTOM_DIMENSION_SEPARATOR + '{2,}', 'g');
  return result.replace(pattern, CUSTOM_DIMENSION_SEPARATOR);
}

function custom() {
  // Don't set custom dimensions if we don't have sufficient data
  if (!this || !this.pageData || !this.pageData.user) {
    return;
  }

  // Get the user info, usually set at user registration and in settings page
  const userInfo = this.pageData.user;

  // Get the user details
  const userDetails = this.pageData.user.details || { data: {} };

  // User roles
  const roles = stringifyList(this.pageData.user.auth_role_ids);
  // Get the user subjects
  const subjects = stringifyList(userDetails.data.subjects);
  // Get the User grades
  const grades = stringifyList(userDetails.data.grades);
  let cohort = userDetails.data.cohort || [];
  if (Array.isArray(cohort)) {
    // A partner point person will have a list of cohorts that we need to convert to a string
    cohort = stringifyList(cohort);
  } else {
    // A participant will only have one cohort. We still need to convert the number to a string
    cohort = stringifyList([cohort]);
  }
  // Participant outcomes
  const outcomes = stringifyList(userDetails.data.outcomes);

  // Set custom vars for GA
  // Add the user hash
  setAnalyticsVariable.call(this, 'userId', userInfo.hash || '');
  setAnalyticsVariable.call(this, 'dimension1', userInfo.hash || 'empty');
  // Participant coach hash
  setAnalyticsVariable.call(this, 'dimension2', userDetails.data.coach_hash);

  // Add the user details to dimension3
  // Create an empty object
  const userDetailsObject = {};

  // Partner ID (affiliation)
  if (userDetails.partner_id) {
    userDetailsObject['partner'] = userDetails.partner_id;
  }

  // if we have a cohort_partner_id that is what we want to present to google analytics
  // otherwise use the user details partner_id
  if (userDetails.data && userDetails.data.cohort_partner_id) {
    userDetailsObject['cohort_partner'] = userDetails.data.cohort_partner_id;
  }

  // User type
  if (userDetails.type_id) {
    userDetailsObject['type'] = userDetails.type_id;
  }
  // Is Masquerading?
  userDetailsObject['masquerading'] = userInfo.is_masquerading === true ? 1 : 0;

  // User group
  if (userDetails.group_id) {
    userDetailsObject['group'] = userDetails.group_id;
  }
  // User State
  if (userDetails.data.state) {
    userDetailsObject['state'] = userDetails.data.state;
  }
  // Stringify the object and send it to GA
  setAnalyticsVariable.call(
    this,
    'dimension3',
    stringifyObject(userDetailsObject)
  );

  // User roles
  setAnalyticsVariable.call(this, 'dimension4', roles);

  // Add the participant details as dimension4
  // Create an empty object
  const participantDetailsObject = {};
  // Is Returning or new participant?
  if (userDetails.data.is_returning) {
    participantDetailsObject['new'] =
      userDetails.data.is_returning === 0 ? 1 : 0;
  }

  // Participant's years teaching
  if (userDetails.data.years_teaching) {
    participantDetailsObject['years_teaching'] =
      userDetails.data.years_teaching;
  }

  // Participant School Environment
  if (userDetails.data.environment) {
    participantDetailsObject['environment'] = userDetails.data.environment;
  }

  // Participant School demographic
  if (userDetails.data.demographic) {
    participantDetailsObject['demographic'] = userDetails.data.demographic;
  }

  // Participant Product
  if (userDetails.data.product) {
    participantDetailsObject['product'] = userDetails.data.product;
  }

  // Institution/School
  if (userDetails.data.institution) {
    participantDetailsObject['institution'] = userDetails.data.institution;
  }

  // Stringify the object and send it to GA
  setAnalyticsVariable(
    this,
    'dimension5',
    stringifyObject(participantDetailsObject)
  );

  // Participant grades
  setAnalyticsVariable.call(this, 'dimension6', grades);

  // Participant Subject IDs
  setAnalyticsVariable.call(this, 'dimension7', subjects);

  // User cohort(s)
  setAnalyticsVariable.call(this, 'dimension8', cohort);

  // Participant outcomes
  setAnalyticsVariable.call(this, 'dimension9', outcomes);

  // Participant ELL
  setAnalyticsVariable.call(this, 'dimension10', userInfo.ell);

  // Participant SPED
  setAnalyticsVariable.call(this, 'dimension11', userInfo.sped);

  // GA Client (dimension12)

  // Occupation
  setAnalyticsVariable.call(this, 'dimension13', userInfo.occupation_id);
}

function sessionControl() {
  let output = {};

  // make sure we have the user id from the TC global
  if (
    !this ||
    !this.pageData ||
    !this.pageData.user ||
    !this.pageData.user.id
  ) {
    return output;
  }

  // prevent error on ancient browsers
  if (typeof Storage === 'undefined') {
    return output;
  }

  // see if there was a previous user id set
  const lastUserId = sessionStorage.getItem('last-user-id');
  if (!lastUserId) {
    // set the user's id for later comparisson
    sessionStorage.setItem('last-user-id', this.pageData.user.id);
    return output;
  }

  // see if this is a new session and old one needs to end
  if (lastUserId !== this.pageData.user.id) {
    output = { sessionControl: 'start' };
  }

  // set the user's id for later comparisson
  sessionStorage.setItem('last-user-id', this.pageData.user.id);

  return output;
}

const Analytics = {
  // The CUSTOM_DIMENSION_SEPARATOR will be used to distinguish multiple values sent to a single custom dimension
  customDimensionSeparator: CUSTOM_DIMENSION_SEPARATOR,

  logEvent: function (data) {
    try {
      if (typeof data === 'string') {
        //format: 'Category|Action|Label|Value'
        const dataArray = data.split('|');
        const category = dataArray[0];
        const action = dataArray.length > 1 ? dataArray[1] : '';
        const label = dataArray.length > 2 ? dataArray[2] : null;
        const value = dataArray.length > 3 ? parseInt(dataArray[3]) : null;

        // reset data to be an object
        data = {
          category: category,
          action: action,
          label: label,
          value: value,
        };
      }

      if (!data.category || !data.action) {
        //insufficient data
        console.log('Not enough information to log event.');
        return;
      }

      let dataLabel = null;
      if (data.label) {
        // cast to string
        dataLabel = String(data.label);
      }

      let dataValue = null;
      if (data.value) {
        // cast to int
        dataValue = parseInt(data.value);
        // if value is negative, then reset to null
        if (dataValue < 0) {
          dataValue = null;
        }
      }

      // Add custom dimensions to request
      custom.call(this);
      // Add clientId as dimenstion
      if (window.ga) {
        window.ga(function (tracker) {
          tracker.set('dimension12', tracker.get('clientId'));
        });
      } else {
        console.log('ga', 'set', 'dimension12', 'not available without GA');
      }

      if (window.ga) {
        // send event
        window.ga(
          'send',
          'event',
          data.category,
          data.action,
          dataLabel,
          dataValue
        );
      } else {
        console.log('ga send event', {
          category: data.category,
          action: data.action,
          label: dataLabel,
          value: dataValue,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  // For ajax pageviews - record a regular pageview on Google Analytics.
  logPageView: function (url) {
    // end session if necessary
    const sessionControlResponse = sessionControl.call(this);

    try {
      // Add custom dimensions to request
      custom.call(this);
      // Add clientId as dimenstion
      if (window.ga) {
        window.ga(function (tracker) {
          tracker.set('dimension12', tracker.get('clientId'));
        });
      } else {
        console.log('ga', 'set', 'dimension12', 'not available without GA');
      }

      // Set Analytics variable
      setAnalyticsVariable.call(this, 'page', url);
      if (window.ga) {
        // Send page hit
        window.ga('send', 'pageview', sessionControlResponse);
      } else {
        console.log('ga send pageview');
      }

      // add hubspot ajax hit
      window._hsq = window._hsq || [];
      const _hsq = window._hsq;
      if (_hsq) {
        _hsq.push(['setPath', url]);
        _hsq.push(['trackPageView']);
      } else {
        console.log('hs setPath', url);
        console.log('hs trackPageView');
      }
    } catch (error) {
      console.error(error);
    }
  },
};

export default Analytics;
