// @ts-nocheck FIXME
import Header from './../Header';

import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Auth from './../../components/Auth';
import Utils from './../../components/DynamicUtils';
import NavigationLink from './../NavigationLink';

const AdminDashboardV2 = (props) => {
  const lab = useLegacyLabContext();
  // only show these links to users with appropriate roles
  let bulkEventParticipantImportLink,
    testsLink,
    partnerManagerLink,
    partnerPortfoliosLink,
    blLogsLink,
    contentLink,
    cohortManagerLink,
    customSchoolsLink,
    partnerManagerLinkV3,
    bulkUserImportV3Link;

  if (Auth.canCreateUsers(props.user)) {
    testsLink = (
      <div className="admin-v2__nav-link">
        <NavigationLink url="/bl/tests" className="tc__link">
          Tests
        </NavigationLink>
      </div>
    );
    partnerManagerLink = (
      <div className="admin-v2__nav-link">
        <NavigationLink url="/bl/v1/partners" className="tc__link">
          Partner Management
        </NavigationLink>
      </div>
    );
    partnerManagerLinkV3 = (
      <div className="admin-v2__nav-link">
        <NavigationLink url="/bl/partners" className="tc__link">
          Partner Management V3
        </NavigationLink>
      </div>
    );
    cohortManagerLink = (
      <div className="admin-v2__nav-link">
        <NavigationLink url="/bl/cohorts" className="tc__link">
          Cohort Management
        </NavigationLink>
      </div>
    );
    bulkUserImportV3Link = (
      <div className="admin-v2__nav-link">
        <NavigationLink
          url="/bl/tools/bulk-participant-importer"
          className="tc__link"
        >
          Bulk Importer - Participants
        </NavigationLink>
      </div>
    );
    bulkEventParticipantImportLink = (
      <div className="admin-v2__nav-link">
        <NavigationLink
          url="/bl/tools/bulk-participant-event-import"
          className="tc__link"
        >
          Bulk Importer - Event Participants
        </NavigationLink>
      </div>
    );
    customSchoolsLink = (
      <div className="admin-v2__nav-link">
        <NavigationLink url="/bl/custom-schools" className="tc__link">
          Curate Custom Schools
        </NavigationLink>
      </div>
    );
  }
  if (Auth.canManagePortfolios(props.user)) {
    partnerPortfoliosLink = (
      <div className="admin-v2__nav-link">
        <NavigationLink url="/bl/v1/partners/portfolios" className="tc__link">
          Partner Portfolios
        </NavigationLink>
      </div>
    );
  }
  if (Utils.isAdmin.call(lab, props.user)) {
    blLogsLink = (
      <div className="admin-v2__nav-link">
        <NavigationLink url="/bl/admin/view_bl_logs" className="tc__link">
          BL Logs
        </NavigationLink>
      </div>
    );
  }

  return (
    <div>
      <Header page="AdminDashboard" {...props} />

      <div className="row">
        <div className="cute-12-desktop admin-v2__headline tc__headline-1">
          BetterLesson Admin
        </div>

        <div className="cute-4-desktop cute-12-phone left">
          <div className="admin-v2__nav-link">
            <NavigationLink url="/bl/users" className="tc__link">
              User Management
            </NavigationLink>
          </div>
          {partnerManagerLink}
          {partnerPortfoliosLink}
          {testsLink}
        </div>

        <div className="cute-12-desktop admin-v2__headline tc__headline-2">
          Reports
        </div>
        <div className="cute-4-desktop cute-12-phone left">{blLogsLink}</div>

        <div className="cute-12-desktop admin-v2__headline tc__headline-2">
          PPD V3
        </div>
        <div className="cute-4-desktop cute-12-phone left">
          {contentLink}
          {cohortManagerLink}
          {partnerManagerLinkV3}
          {bulkUserImportV3Link}
          {bulkEventParticipantImportLink}
          {customSchoolsLink}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardV2;
