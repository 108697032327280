// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Element from './../Element';
import Sortable from './../Sortable';

const SpotlightTypePanel = (props) => {
  // if there are no entries for this spotlight type do not render the component
  if (props.entries.length < 1) {
    return <noscript />;
  }

  const handleTogglePanel = (e) => {
    e.preventDefault();
    props.toggleSpotlightTypePanel(props.id);
  };

  // count the total # entries in this panel
  const entryCount = props.entries ? props.entries.length : 0;

  // show the border under the header if the panel is expanded and has entries to show
  const expandedClass =
    props.isExpanded && entryCount ? ' ppt-panel__header--border' : '';

  const entriesNode = props.entries.map((element) => (
    <Element
      key={element.id}
      {...element}
      editEntry={props.editEntry}
      deleteEntry={props.deleteEntry}
    />
  ));

  // attach sorting functionality to entries
  const sortKey = 'spotlightElementContainer' + props.entry_type;
  const SortableEntries = Sortable(
    <ul id={sortKey}>{entriesNode}</ul>,
    '#' + sortKey,
    '#ppt-section__container',
    (entryIds) => {
      props.sortSpotlightEntries({
        entryIds: entryIds,
        typeId: props.id,
      });
    }
  );

  const elementsNode =
    !props.isExpanded || !props.entries ? null : (
      <div className="spotlight-type-panel__body">
        <SortableEntries />
      </div>
    );

  // rendering
  return (
    <div className="ppt-panel">
      <div
        className={'ppt-panel__header' + expandedClass}
        onClick={handleTogglePanel}
      >
        <span className="ppt-panel__name">
          {props.entry_type.replace('Spotlight', '')}
        </span>
        <span className="ppt-panel__spotlight-count">
          {entryCount} Spotlights
        </span>
      </div>
      {elementsNode}
    </div>
  );
};

SpotlightTypePanel.propTypes = {
  sectionId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  entry_type: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  toggleSpotlightTypePanel: PropTypes.func.isRequired,
  sortSpotlightEntries: PropTypes.func.isRequired,
};

export default SpotlightTypePanel;
