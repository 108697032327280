// @ts-nocheck FIXME
import Request from './../components/Request';

const StrategyApi = {
  getStrategies: function (searchParams) {
    let url = '/coaching/lab/strategy-browser/search?';
    for (const param in searchParams) {
      // if a param has a bunch of values, we need to list them separately
      if (Array.isArray(searchParams[param])) {
        for (const i in searchParams[param]) {
          url += param + '=' + searchParams[param][i] + '&';
        }
      } else {
        url += param + '=' + searchParams[param] + '&';
      }
    }
    return Request.get(url);
  },

  // gets strategies with pin/note data
  getStrategiesV2: function (searchParams) {
    let url = '/coaching/lab/strategy-browser/search?';
    for (const param in searchParams) {
      // if a param has a bunch of values, we need to list them separately
      if (Array.isArray(searchParams[param])) {
        for (const i in searchParams[param]) {
          url += param + '=' + searchParams[param][i] + '&';
        }
      } else {
        url += param + '=' + searchParams[param] + '&';
      }
    }
    return Request.get(url);
  },

  // delete an sga from a user's growth map
  deleteUserSga: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/strategy/delete_sga',
      JSON.stringify(data)
    );
  },

  sortSga: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/strategy/sort_sga',
      JSON.stringify(data)
    );
  },

  sortChallenge: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/strategy/sort_challenge',
      JSON.stringify(data)
    );
  },

  // add challenge to the user's growth map
  addChallenge: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.userId +
        '/strategy/add_challenge',
      JSON.stringify(data)
    );
  },

  // for coaches to add the challenge to multiple teams' and teachers' growth maps
  addChallengeCoach: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/coach/' +
        data.coachId +
        '/strategy/add_challenge',
      JSON.stringify(data)
    );
  },

  activateChallenge: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.userId +
        '/strategy/challenge/status/activated',
      JSON.stringify(data)
    );
  },

  deactivateChallenge: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.userId +
        '/strategy/challenge/status/added',
      JSON.stringify(data)
    );
  },

  removeChallenge: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/strategy/remove_challenge',
      JSON.stringify(data)
    );
  },

  // add strategy to the user's pinboard
  addStrategy: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.userId +
        '/strategy/add',
      JSON.stringify(data)
    );
  },

  // for coaches to pin a strategy for multiple teachers/teams
  addStrategyCoach: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/coach/' +
        data.coachId +
        '/strategy/add',
      JSON.stringify(data)
    );
  },

  removeStrategy: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.userId +
        '/strategy/remove',
      JSON.stringify(data)
    );
  },

  // add a note to the strategy, and create a pin if there is none
  addStrategyNote: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/strategy/add_note',
      JSON.stringify(data)
    );
  },

  // for coaches to add notes for multiple teachers/teams
  addStrategyNoteCoach: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/coach/' +
        data.coachId +
        '/strategy/add_note',
      JSON.stringify(data)
    );
  },

  addStrategyNoteComment: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.userId +
        '/strategy/add_note_comment',
      JSON.stringify(data)
    );
  },

  sortStrategies: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/strategy/sort',
      JSON.stringify(data)
    );
  },

  // set the metric and/or baseline measurement data
  // for the teacher and teaching challenge on her growth map
  setTeacherChallengeData: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/strategy/set_teacher_challenge_data',
      JSON.stringify(data)
    );
  },

  postRating: function (data) {
    return Request.post(
      '/coaching/lab/strategy/' + data['id'] + '/rating',
      JSON.stringify(data)
    );
  },

  postArtifactView: function (data) {
    return Request.post(
      '/coaching/lab/strategy/' +
        data['strategyId'] +
        '/artifact/' +
        data['artifactId'] +
        '/viewed'
    );
  },

  postTCArtifactView: function (data) {
    return Request.post(
      '/coaching/lab/tcartifact/' + data['artifactId'] + '/viewed'
    );
  },

  postArtifactDownload: () => undefined,

  createCustomStrategy: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/' +
        data['creatorType'] +
        '/' +
        data['userId'] +
        '/strategy/create_custom',
      JSON.stringify(data)
    );
  },
};

export default StrategyApi;
