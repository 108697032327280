// @ts-nocheck FIXME
import propTypes from 'prop-types';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

const ScoreCard = (props) => {
  if (!props.data || !props.data.length) {
    return null;
  }

  // total number of sessions
  const numSessions = props.data.length;

  // total number of surveys completed by participants
  const numSurveys = props.data.reduce(
    (total, each) => total + each.surveys_completed_count,
    0
  );

  // total number of checked-in participants in all sessions
  const numCheckedIn = props.data.reduce(
    (total, each) => total + each.checked_in_users_count,
    0
  );

  // averaged NPS based on number of sessions rounded to integer
  const averageNPS = Math.round(
    props.data.reduce((total, each) => total + each.nps, 0) / numSessions
  );

  // averaged satisfaction rate in percentage rounded to integer
  const averageSatisfaction = numSurveys
    ? Math.round(
        (props.data.reduce(
          (total, each) => total + each.satisfaction_count,
          0
        ) /
          numSurveys) *
          100
      )
    : 0;

  // total number of completed surveys / number of checked-in participants in percentage rounded to integer
  const responseRate = numSurveys
    ? Math.round((numSurveys / numCheckedIn) * 100)
    : 0;

  return (
    <div className="coach-surveys__scorecard-container v5__body-copy">
      <div className="coach-surveys__scorecard-title">
        <div className="coach-surveys__scorecard-title-icon-container">
          <span className="tc-statistics coach-surveys__scorecard-title-icon"></span>
          <span className="v5__heading-5">Event Survey Averages</span>
        </div>
        <div className="coach-surveys__scorecard-title-summary">
          {`${numSurveys} Surveys From ${numSessions} Sessions`}
        </div>
      </div>
      <div className="coach-surveys__scorecard-cards">
        <div className="coach-surveys__scorecard-card">
          <div className="v5__heading-6 coach-surveys__scorecard-card-title">
            Facilitator Satisfaction
          </div>
          <div className="coach-surveys__progressbar coach-surveys__progressbar-satisfaction">
            <CircularProgressbarWithChildren
              value={averageSatisfaction}
              strokeWidth={10}
            >
              <div className="v5__heading-3">{`${averageSatisfaction}%`}</div>
            </CircularProgressbarWithChildren>
          </div>
          <div className="coach-surveys__scorecard-text">
            percent of respondents reported high satisfaction
          </div>
        </div>
        <div className="coach-surveys__scorecard-card">
          <div className="v5__heading-6 coach-surveys__scorecard-card-title">
            Net Promoter Score
          </div>
          <div>
            <span className="tc-ten-point-star coach-surveys__nps-icon">
              <span className="v5__heading-1 coach-surveys__nps-text">
                {averageNPS}
              </span>
            </span>
          </div>
          <div className="coach-surveys__scorecard-text">
            based on weighing each session equally
          </div>
        </div>
        <div className="coach-surveys__scorecard-card">
          <div className="v5__heading-6 coach-surveys__scorecard-card-title">
            Survey Response
          </div>
          <div className="coach-surveys__progressbar coach-surveys__progressbar-response">
            <CircularProgressbarWithChildren
              value={responseRate}
              strokeWidth={10}
            >
              <div className="v5__heading-3">{`${responseRate}%`}</div>
            </CircularProgressbarWithChildren>
          </div>

          <div className="coach-surveys__scorecard-text">
            percent of respondents who completed a survey
          </div>
        </div>
      </div>
    </div>
  );
};

ScoreCard.propTypes = {
  data: propTypes.array.isRequired,
};

export default ScoreCard;
