// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

const MeetingJoinButton = (props) => {
  let nextMeetingBtnNode = null;
  // use the meeting's unique Zoom link if it has one, or default to the coach's general Zoom link
  const joinMeetingLink = props.zoom_join_link
    ? props.zoom_join_link
    : props.zoomLink;
  // if the meeting is going to start within 5 minutes or it is in progress
  if (joinMeetingLink && props.meetingStarted) {
    nextMeetingBtnNode = (
      <div className="home-v5__next-meeting">
        <a
          href={joinMeetingLink}
          target="_blank"
          className="v5__link"
          rel="noreferrer"
        >
          <span className="tc-v5-video workspace__next-meeting-start-icon">
            {' '}
          </span>
          Start
        </a>
      </div>
    );
  }

  return <React.Fragment>{nextMeetingBtnNode}</React.Fragment>;
};

MeetingJoinButton.propTypes = {
  meetingStarted: PropTypes.bool.isRequired,
  zoom_join_link: PropTypes.string,
  zoomLink: PropTypes.string,
};

export default MeetingJoinButton;
