// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ScaleCenterImage from './../../ScaleCenterImage';

/*
given a circle diameter, builds the V4 round user image -
if there is some color behind the img (like the user img on the home page),
then use hasBorder to add a white border in the mask to make the photo clearer

(if no img, uses the appropriate icon as a placeholder)
*/
const UserPhoto = (props) => {
  let photoNode = null;

  // set up the user photo img if they have one
  if (props.src) {
    photoNode = (
      <ScaleCenterImage
        src={props.src}
        maskClassName={props.className}
        className="bl4__user-image"
        noAdjustments={true}
        diameter={props.diameter}
        hasBorder={props.hasBorder}
      />
    );
  }
  // if not, then set up default user photo icon
  else {
    const className = props.className ? props.className : '';
    // use different icons depending on the diameter of the photo
    const iconClass =
      props.diameter > 30 ? 'tc-v4-profile-lg' : 'tc-v4-profile';

    photoNode = (
      <div
        className={'tc-v4-profile__bg ' + className}
        style={{ height: props.diameter, width: props.diameter }}
      >
        <div className={iconClass} style={{ fontSize: props.diameter }}></div>
      </div>
    );
  }

  return photoNode;
};

UserPhoto.propTypes = {
  src: PropTypes.string,
  diameter: PropTypes.number.isRequired,
  hasBorder: PropTypes.bool,
  className: PropTypes.string,
};

export default UserPhoto;
