// @ts-nocheck FIXME
import createReactClass from 'create-react-class';

const FullScreenArtifact = (FullScreenComponent) => {
  return createReactClass({
    displayName: 'FullScreenArtifact',

    componentDidMount: () => {
      const imageElem = document.querySelector(
        '.artifact-overlay__image.tc__visible'
      );
      const containerElem = document.getElementById(
        'artifact-overlay__fullscreen-container'
      );
      const fullScreenImageElem = document.getElementsByClassName(
        'artifact-overlay__fullscreen-image'
      );

      // if there is no resource image dont bother centering it
      if (!imageElem) {
        return;
      }

      const standardImageWidth = 970;
      const screenRatio = screen.height / screen.width;
      const image = new Image();
      image.src = imageElem.src;

      image.onload = function () {
        let imageWidth = imageElem.clientWidth;
        let imageHeight = imageElem.clientHeight;

        // get the image ratio
        const imageRatio = imageHeight / imageWidth;

        // compaire the image ratio to the screen ratio
        if (screenRatio > imageRatio) {
          imageWidth = standardImageWidth;
          imageHeight = imageWidth * imageRatio;
        } else {
          imageHeight = screen.height;
          imageWidth = imageHeight / imageRatio;

          if (imageWidth > standardImageWidth) {
            imageWidth = standardImageWidth;
            imageHeight = imageWidth * imageRatio;
          }
        }

        // add styles to the fullscreen images
        for (const page of fullScreenImageElem) {
          page.setAttribute(
            'style',
            'height:' +
              Math.round(imageHeight) +
              'px; width:' +
              Math.round(imageWidth) +
              'px;'
          );
        }

        // center the image
        const fullscreenMarginLeft = Math.abs(imageWidth / 2) * -1;
        // add styles to the fullscreen container
        const fullscreenContainerStyles = {
          marginLeft: Math.round(fullscreenMarginLeft),
        };
        containerElem.setAttribute(
          'style',
          'left: 50%; margin-left: ' +
            Math.round(fullscreenMarginLeft) +
            'px; margin-top:10px'
        );
      };
    },

    render: () => {
      return FullScreenComponent;
    },
  });
};

export default FullScreenArtifact;
