// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import FreezeWindowScroll from './FreezeWindowScroll';

const Modal = (props) => {
  const spaceFromTop =
    props.spaceFromTop || props.spaceFromTop === 0 ? props.spaceFromTop : 100;

  return (
    <div>
      <div className="v5__modal-page-overlay" onClick={props.close} />

      <div
        style={{ top: spaceFromTop }}
        className={`v5__modal${props.className ? ` ${props.className}` : ''}`}
      >
        <div className="tc-x v5__modal-close-icon" onClick={props.close}></div>

        {props.children}
      </div>

      <FreezeWindowScroll parentModal="v5__modal" spaceFromTop={spaceFromTop} />
    </div>
  );
};

Modal.propTypes = {
  close: PropTypes.func.isRequired,
  spaceFromTop: PropTypes.number,
  className: PropTypes.string,
};

export default Modal;
