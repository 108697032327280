// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

const PartnerLeadersRow = (props) => {
  const lab = useLegacyLabContext();

  const plHomePageLink = props.isDv && (
    <Tooltip tooltip="Admin Dashboard" offsetLeft={26} delay={1}>
      <NavigationLink
        target="_blank"
        url={`/lab/leader/${props.leader.id}/home`}
        className="tc-v5-admin-dash admin-table__multiple-icon v5__link"
      ></NavigationLink>
    </Tooltip>
  );
  let cohortsNode = (
    <div
      className={`italic${
        props.leader.isCurrent ? '' : ' partner_lead_v5__past-data'
      }`}
    >
      None
    </div>
  );
  let eventsNode = (
    <div
      className={`italic${
        props.leader.isCurrent ? '' : ' partner_lead_v5__past-data'
      }`}
    >
      None
    </div>
  );
  if (props.leader.is_partner_pl) {
    cohortsNode = 'All';
    eventsNode = 'All';
  } else {
    const cohorts =
      props.leader.cohorts &&
      props.leader.cohorts.map((cohort) => {
        return (
          <li
            key={`cohort-${cohort.cohortId}`}
            className={cohort.isCurrent ? '' : 'partner_lead_v5__past-data'}
          >
            {cohort.cohortName}
          </li>
        );
      });
    const events =
      props.leader.events &&
      props.leader.events.map((event) => {
        return (
          <li
            key={`event-${event.eventId}`}
            className={event.isCurrent ? '' : 'partner_lead_v5__past-data'}
          >
            {event.eventName}
          </li>
        );
      });
    if (cohorts.length) {
      cohortsNode = <ul>{cohorts}</ul>;
    }
    if (events.length) {
      eventsNode = <ul>{events}</ul>;
    }
  }

  const isPartnerLeadNode = props.leader.is_partner_pl && (
    <span className="tc-icon_partnership-pl partner_lead_v5__partner-partner-lead" />
  );

  let editButtons;
  if (Utils.isManager.call(lab) || Utils.isAdmin.call(lab)) {
    editButtons = (
      <React.Fragment>
        {plHomePageLink}
        <div
          className="v5__link tc-delete admin-table__multiple-icon"
          onClick={props.deletePl}
        ></div>
      </React.Fragment>
    );
  }

  return (
    <tr className="admin-table__row" data-testid={'PartnerLeaderRow'}>
      <td
        className={`admin-table__col${
          props.leader.isCurrent ? '' : ' partner_lead_v5__past-data'
        }`}
      >
        <div>
          {props.leader.name} {isPartnerLeadNode}
        </div>
        <div>{props.leader.email}</div>
      </td>
      <td className="admin-table__col">{cohortsNode}</td>
      <td className="admin-table__col">{eventsNode}</td>
      <td className="admin-table__col">{props.children}</td>
      <td className="admin-table__col">
        {props.leader.surveyCompleted ? 'Completed' : ''}
      </td>
      <td className="admin-table__icon-col">{editButtons}</td>
    </tr>
  );
};

export default PartnerLeadersRow;

PartnerLeadersRow.propTypes = {
  deletePl: PropTypes.func.isRequired,
  partnerId: PropTypes.number.isRequired,
  leader: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
