// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

const EventCSV = (props) => {
  const participants = Utils.clone(props.eventParticipants);
  const sessionNumMap = {};
  const sessionNums = [];
  props.event.sessions.forEach((session) => {
    // push the session table headers
    sessionNumMap[session.id] = session.num;
    if (session.num > sessionNums.length) {
      sessionNums.push(sessionNums.length + 1);
    }
  });
  let sessionCount = 0;
  props.event.sessions.forEach((session) => {
    if (session.num > sessionCount) {
      sessionCount++;
    }
  });
  const hasEvents = props.event.groups && props.event.groups.length;

  // Setup the attendance csv dowload link
  const headers = ['NAME', 'EMAIL', 'SCHOOL'];
  if (hasEvents) {
    headers.push('GROUP');
  }
  let csvData = [headers.concat(sessionNums.map((i) => `SESSION ${i}`))];
  // We need to get the Participants group names and when they registered for sessions
  csvData = csvData.concat(
    participants.map((eventParticipant) => {
      const participantData = [
        `${eventParticipant.user_first_name} ${eventParticipant.user_last_name}`,
        eventParticipant.user_email,
        eventParticipant.institution || '',
      ];
      if (hasEvents) {
        const groupIndex = Utils.getItemIndexFromArrayOfObjects(
          props.event.groups,
          'id',
          parseInt(eventParticipant.group_id)
        );
        participantData.push(
          groupIndex !== -1 ? props.event.groups[groupIndex].name : ''
        );
      }
      sessionNums.forEach((eventSessionNum) => {
        let registered = null;
        if (eventParticipant.event_sessions) {
          eventParticipant.event_sessions.forEach((session) => {
            if (eventSessionNum === sessionNumMap[session.session_id]) {
              const currentSession = eventParticipant.event_sessions.find(
                (eventSession) => eventSession.session_id === session.session_id
              );
              registered = moment(currentSession.registered_formatted).tz(
                props.event.time_zone.pytz_timezone
              );
            }
          });
        }
        if (registered) {
          registered = registered.format('YYYY, D, MMMM HH:mm A z');
        }
        participantData.push(registered);
      });
      return participantData;
    })
  );

  return (
    <CSVLink
      className="event__participant-attendance-download admin-v5__edit-table-btn v5__link admin-v5__btn-copy-emails"
      data={csvData}
      filename={`${props.event.name}-Attendance.csv`}
    >
      <span className="tc-download admin-v5__edit-table-btn-icon"></span>
      Attendance CSV
    </CSVLink>
  );
};

EventCSV.propTypes = {
  event: PropTypes.object.isRequired,
  eventParticipants: PropTypes.array.isRequired,
};

export default EventCSV;
