// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';

const ProfessionalLearningDropdown = (props) => {
  const show = () => {
    props.openMarketingDropdown();
  };

  const domainsActiveClass = props.page === 'Domains' ? 'active' : '';
  const marketingDropdownOpened = props.marketingDropdownOpened ? 'show' : '';

  return (
    <div className="tc-header__marketing-link-container show-desktop hide-phone">
      <a
        className={`header__link tc__link ${domainsActiveClass}`}
        href={`${process.env.REACT_APP_MARKETING_URL}/learning-experiences/student-centered-teaching`}
      >
        Professional Learning
      </a>
      <div
        className="tc-bold-arrow header__dropdown-arrow"
        onClick={show}
      ></div>
      <div
        className={`tc-header__marketing-dropdown ${marketingDropdownOpened}`}
      >
        <div className="tc-header__marketing-dropdown-left">
          <div className="tc-header__marketing-dropdown-links">
            <a
              href={`${process.env.REACT_APP_MARKETING_URL}/learning-experiences/student-centered-teaching`}
            >
              Student-Centered Learning
            </a>
            <NavigationLink
              url={`${process.env.REACT_APP_TEACHING_URL}/browse/content-partners`}
            >
              Our Content Partners
            </NavigationLink>
          </div>
        </div>
        <div className="tc-header__marketing-dropdown-right">
          <a
            className="tc-header__marketing-dropdown-link--blurb"
            href={`${process.env.REACT_APP_MARKETING_URL}/professional-development/`}
            target="_blank"
            rel="noreferrer"
          >
            BetterLesson reimagines professional learning by personalizing
            support for educators to support student-centered learning.
          </a>
          <div>
            <a
              className="tc-header__marketing-dropdown-link--green"
              href={`${process.env.REACT_APP_MARKETING_URL}/professional-development/`}
              target="_blank"
              rel="noreferrer"
            >
              See what we offer
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfessionalLearningDropdown.propTypes = {
  openMarketingDropdown: PropTypes.func.isRequired,
  closeMarketingDropdown: PropTypes.func.isRequired,
  page: PropTypes.string,
  marketingDropdownOpened: PropTypes.bool.isRequired,
};

export default ProfessionalLearningDropdown;
