// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const Initiative = (props) => {
  return (
    <div
      className={`initiative__capsule initiative__capsule--${props.initiative.id} v5__capsule`}
      id={`element-${props.initiative.id}`}
    >
      <div
        className={`v5__capsule-header-color v5__capsule-header-color--domain-${props.initiative.id}`}
      />
      <div className="initiative__capsule-content">
        <div className="initiative__left">
          <img
            data-test-id="image"
            className="initiative__image"
            src={`/legacy-images/v5/initiatives/${props.initiative.image}`}
            alt={props.initiative.title}
          />
          <div
            data-test-id="audience"
            className="initiative__audience v5__heading-6"
          >
            for {props.initiative.audience}
          </div>
          <h3 className="initiative__title v5__heading-4">
            {props.initiative.title}
          </h3>
          <div
            data-test-id="description"
            className="initiative__description v5__body-copy"
          >
            {props.initiative.description}
          </div>
        </div>
        <div
          data-test-id="domains"
          className="initiative__domains initiative__right"
        >
          <div className="initiative__domains-headline v5__heading-6">
            Focus Areas
          </div>
          <div className="initiative__domains-container">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Initiative;

Initiative.propTypes = {
  initiative: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
