// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import { Router } from '@legacy-components/components/Router';
import Breadcrumb from '@legacy-components/react/V5/Common/Breadcrumb';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';
import FilterModal from './../modules/FilterModal';
import FilterTag from './../modules/FilterTag';
import TabbedMenu from './../modules/TabbedMenu';
import Activity from './tabs/Activity';
import Meetings from './tabs/Meetings';
import Overview from './tabs/Overview';
import Registration from './tabs/Registration';

const PartnerLeaderHome = (props) => {
  const disabled =
    !props.search_failed &&
    (!props.participants || props.participants.length <= 0);
  const [returningFilter, setReturningFilter] = React.useState('');
  const [schoolFilters, setSchoolFilters] = React.useState([]);
  const [domainFilters, setDomainFilters] = React.useState([]);
  const [displayFilterModal, setDisplayFilterModal] = React.useState(false);

  const activeLead = ArrayHelpers.hasElements(props.user.leads)
    ? props.user.leads.find((lead) => !lead.archived)
    : null;

  const checkParticipantDomains = (participant, filters) => {
    return (
      participant.active_domains.filter(
        (activeDomain) => filters.indexOf(activeDomain.title) >= 0
      ).length > 0
    );
  };

  const tabs = [
    {
      name: 'overview',
      disabled: false,
    },
    {
      name: 'engagement',
      disabled: disabled,
      label: 'Engagement',
    },
    {
      name: 'meetings',
      disabled: disabled,
      label: 'Meeting',
    },
    {
      name: 'activity',
      disabled: disabled,
      label: 'Activity',
    },
  ];

  const labels = {
    registration: 'Registration',
    meetings: 'Meeting',
    activity: 'Activity',
  };

  let participants = props.participants;
  const activeFilters = [];
  switch (returningFilter) {
    case 'returning':
      activeFilters.push(
        <FilterTag
          key="returning-tag"
          filterTitle="Returning"
          filterRemove={() => setReturningFilter('')}
        />
      );
      participants = participants.filter(
        (participant) => participant.isReturning
      );
      break;
    case 'new':
      activeFilters.push(
        <FilterTag
          key="returning-tag"
          filterTitle="New"
          filterRemove={() => setReturningFilter('')}
        />
      );
      participants = participants.filter(
        (participant) => !participant.isReturning
      );
      break;
  }
  if (schoolFilters.length > 0) {
    participants = participants.filter(
      (participant) =>
        schoolFilters.indexOf(
          participant.school_name && participant.school_name
        ) >= 0
    );
    activeFilters.push(
      ...schoolFilters.map((school, i) => {
        return (
          <FilterTag
            key={`${school}-tag`}
            filterTitle={school}
            filterRemove={() => {
              setSchoolFilters(
                schoolFilters.slice(0, i).concat(schoolFilters.slice(i + 1))
              );
            }}
          />
        );
      })
    );
  }
  if (domainFilters.length > 0) {
    participants = participants.filter((participant) =>
      checkParticipantDomains(participant, domainFilters)
    );
    activeFilters.push(
      ...domainFilters.map((domain, i) => {
        return (
          <FilterTag
            key={`${domain}-tag`}
            filterTitle={domain}
            filterRemove={() => {
              setDomainFilters(
                domainFilters.slice(0, i).concat(domainFilters.slice(i + 1))
              );
            }}
          />
        );
      })
    );
  }

  const filter = (
    <button className="v5__btn" onClick={() => setDisplayFilterModal(true)}>
      Filter
    </button>
  );

  const pluralized = participants.length === 1 ? '' : 's';
  const tableHeadingRow = props.search_failed ? (
    <div className="v5__registered-table-details v5__heading-5 row home-v5__empty-activity-capsule">
      <div>
        <img src="/legacy-images/404-pets.png" className="code-404__img-tabs" />
      </div>
      <div className="home-v5__empty-activity-row">
        <div className="v5__heading-4">Oops! Something is broken.</div>
        <div className="v5__comment-copy">
          <div>
            {labels[props.active_tab]} data is temporarily unavailable. (We
            blame the cat.)
          </div>
          <div>Thanks for your patience as we tidy up this issue.</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="v5__registered-table-details v5__heading-5 row">
      <div>
        {participants.length} educator{pluralized}
      </div>
      <div>{filter}</div>
      {activeFilters}
    </div>
  );

  // @todo @jonathanrys: For performance boost, only load the page we need.  Might need to normalize the props to do this
  const pages = {
    overview: <Overview {...props} />,
    engagement: (
      <Registration
        participants={participants}
        tableHeadingRow={tableHeadingRow}
        search_failed={props.search_failed}
        sortCol={props.sortCol}
      />
    ),
    meetings: (
      <Meetings
        {...props}
        participants={participants}
        tableHeadingRow={tableHeadingRow}
        search_failed={props.search_failed}
        sortCol={props.sortCol}
        currentYear={props.school_year}
      />
    ),
    activity: (
      <Activity
        {...props}
        participants={participants}
        tableHeadingRow={tableHeadingRow}
        search_failed={props.search_failed}
        currentYear={props.school_year}
      />
    ),
  };

  let switchCohort = null;

  if (props.cohorts.length > 1) {
    switchCohort = (
      <div>
        <label className="v5__label">View another cohort</label>
        <select
          className="v5__input v5__select"
          onChange={(e) => {
            if (e.target.value)
              Router.route(`/lab/cohort/${e.target.value}/${props.active_tab}`);
          }}
        >
          {props.cohorts
            .sort((a, b) => a.display_name.localeCompare(b.display_name))
            .map((cohort) => (
              <option key={`cohort-${cohort.id}`} value={cohort.id}>
                {cohort.display_name}
              </option>
            ))}
        </select>
      </div>
    );
  }

  let modalNode;
  if (displayFilterModal) {
    modalNode = (
      <FilterModal
        close={() => setDisplayFilterModal(false)}
        returningFilter={returningFilter}
        setReturningFilter={setReturningFilter}
        schoolFilters={schoolFilters}
        setSchoolFilters={setSchoolFilters}
        domainFilters={domainFilters}
        setDomainFilters={setDomainFilters}
        schools={props.school_names}
        domains={props.active_domains}
        participants={props.participants}
        checkParticipantDomains={checkParticipantDomains}
        isCurrentYear={props.school_year === 'current'}
      />
    );
  }

  const breadcrmbNode = activeLead ? (
    <div className="cute-9-desktop">
      <div className="breadcrumb">
        <Breadcrumb
          trail={[
            {
              text: 'Homepage',
              link: `/lab/leader/${activeLead.id}/home`,
            },
            {
              text: `${props.cohort.display_name}`,
            },
          ]}
          divider=" / "
        />
      </div>
    </div>
  ) : null;

  return (
    <div>
      <Header page="PartnerLeaderDashboard" {...props} />
      {modalNode}
      <div className="show-desktop hide-phone partner_lead_v5__main-content v5__body-copy">
        <div className="row row--1200 partner_lead_v5__header">
          {breadcrmbNode}
          <div className="cute-9-desktop">
            <div>
              <div className="v5__heading-1">{props.cohort.display_name}</div>
              <div>
                {`${props.cohort.capacity} Educators, ${
                  props.cohort.product && props.cohort.product.name
                }`}
              </div>
            </div>
            <TabbedMenu
              tabs={tabs}
              cohortId={props.cohort.id}
              urlPrefix={`/lab/cohort/${props.cohort.id}`}
              active={props.active_tab}
              currentYear={props.school_year}
            />
          </div>
          <div className="cute-3-desktop">{switchCohort}</div>
        </div>
        {pages[props.active_tab]}
      </div>
    </div>
  );
};

PartnerLeaderHome.propTypes = {
  active_tab: PropTypes.string,
  user: PropTypes.object.isRequired,
  cohort: PropTypes.object.isRequired,
  cohorts: PropTypes.array,
  psms: PropTypes.array,
  registration_stats: PropTypes.object,
  sortCol: PropTypes.string,
  learning_domains: PropTypes.object.isRequired,
  SA_aggregate: PropTypes.object.isRequired,
  selectedDomain: PropTypes.string.isRequired,
};

export default PartnerLeaderHome;
