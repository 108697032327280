// @ts-nocheck FIXME
const buildSessionsMap = (sessions) => {
  const sessionsMap = {};

  sessions.forEach((session) => {
    const num = Math.abs(session.num);
    const sessionValues = sessionsMap[num] || [];
    sessionValues.push(session);
    sessionsMap[num] = sessionValues;
  });

  return sessionsMap;
};

const isNotPartnerCancelled = (session) => {
  if (session.deleted) {
    return false;
  }

  return session.status !== 'Partner cancelled';
};

const isFirstGroupSession = (session, groups) => {
  if (session.deleted) {
    return false;
  }

  if (!session.group_num) {
    return true;
  }

  return session.group_num === groups[0].num;
};

const buildDefaultSessions = (sessionsMap, groups) => {
  const defaultSessions = [];

  Object.values(sessionsMap).forEach((sessionValues) => {
    const notPartnerCancelledSession = sessionValues.find(
      isNotPartnerCancelled
    );

    if (notPartnerCancelledSession) {
      defaultSessions.push(notPartnerCancelledSession);
      return;
    }

    const firstGroupSession = sessionValues.find((session) =>
      isFirstGroupSession(session, groups)
    );

    if (firstGroupSession) {
      defaultSessions.push(firstGroupSession);
      return;
    }
  });

  return defaultSessions;
};

export const getDefaultSessions = (sessions, groups) => {
  const sessionsMap = buildSessionsMap(sessions);
  const defaultSessions = buildDefaultSessions(sessionsMap, groups);
  return defaultSessions;
};
