// @ts-nocheck FIXME
import React from 'react';

class TmlProgressChart extends React.Component {
  render() {
    // instructional design challenge measurements use a scale of 1-10 instead of a percentage
    const isID = this.props.isInstructionalDesign || false;

    const chartHeight = this.props.height || 200;

    const baseline = this.props.baseline;
    const baselineHeight = Math.round((baseline / 100) * chartHeight);

    const goal = this.props.goal || 0;
    const goalHeight = Math.round((goal / 100) * chartHeight);
    const measurementPercent = this.props.measurementPercent || 0;
    const measurementValue = this.props.measurementValue || 0;
    const measurementBarHeight = Math.round(
      (measurementPercent / 100) * chartHeight
    );

    const baselineColor = '#1b7ee0';
    const goalColor = '#c2df21';
    const measurementColor = '#f5972e';

    // set what the bar represents based on the info passed in
    let bar;
    if (measurementPercent) {
      let barHeight = measurementBarHeight;
      // if height is 100%, then substract top and bottom border pixels
      if (barHeight == chartHeight) {
        barHeight -= 2;
      }
      // if we have the total # students,
      // we calculate and display the absolute # measured based on the measurement %
      const numStudents = measurementValue ? ' (' + measurementValue + ')' : '';
      bar = {
        color: measurementColor,
        height: barHeight,
        label: isID
          ? measurementPercent / 10
          : measurementPercent + '%' + numStudents,
        mobileLabel: isID
          ? 'This Strategy Attempt: ' + measurementPercent / 10
          : 'This Strategy Attempt: ' + measurementPercent + '%',
      };
    } else if (baseline != null) {
      let barHeight = baselineHeight;
      // if height is 100%, then substract top and bottom border pixels
      if (barHeight == chartHeight) {
        barHeight -= 2;
      }
      bar = {
        color: baselineColor,
        height: barHeight,
      };
    } else if (goal) {
      let barHeight = goalHeight;
      // if height is 100%, then substract top and bottom border pixels
      if (barHeight == chartHeight) {
        barHeight -= 2;
      }
      bar = {
        color: goalColor,
        height: barHeight,
      };
    }

    // set up the measurement bar and the label under it
    let barNode = null;
    let labelNode = null;
    let mobileLabelNode = null;
    if (bar) {
      barNode = (
        <div
          ref="bar"
          className={'chart-v2__total'}
          style={{ height: chartHeight + 'px' }}
        >
          <div
            className="chart-v2__value"
            style={{ height: bar.height + 'px', backgroundColor: bar.color }}
          ></div>
        </div>
      );
      labelNode = (
        <div
          className="chart-v2__label show-desktop hide-phone"
          style={{ color: bar.color }}
        >
          <div>{bar.label}</div>
        </div>
      );
      if (bar.mobileLabel) {
        mobileLabelNode = (
          <div
            className="chart-v2__label hide-desktop show-phone"
            style={{ color: bar.color }}
          >
            <span>{bar.mobileLabel}</span>
            <span
              className="chart-v2__legend-example"
              style={{ borderColor: bar.color }}
            ></span>
          </div>
        );
      }
    }

    let goalLine;
    let goalLegend;
    let mobileGoalLegend;
    if (goal) {
      goalLine = (
        <div
          className="chart-v2__line"
          style={{ borderColor: goalColor, bottom: goalHeight - 1 + 'px' }}
        ></div>
      );
      goalLegend = (
        <div
          className="chart-v2__legend show-desktop hide-phone"
          style={{ color: goalColor, bottom: goalHeight - 8 + 'px' }}
        >
          Goal: {isID ? goal / 10 : goal + '%'}
        </div>
      );
      mobileGoalLegend = (
        <div
          className="chart-v2__label hide-desktop show-phone"
          style={{ color: goalColor }}
        >
          <span>Goal: {isID ? goal / 10 : goal + '%'}</span>
          <span
            className="chart-v2__legend-example"
            style={{ borderColor: goalColor }}
          ></span>
        </div>
      );
    }

    let baselineLine;
    let baselineLegend;
    let mobileBaselineLegend;
    if (baseline != null) {
      baselineLine = (
        <div
          className="chart-v2__line"
          style={{
            borderColor: baselineColor,
            bottom: baselineHeight - 1 + 'px',
          }}
        ></div>
      );
      baselineLegend = (
        <div
          className="chart-v2__legend show-desktop hide-phone"
          style={{ color: baselineColor, bottom: baselineHeight - 8 + 'px' }}
        >
          Baseline: {isID ? baseline / 10 : baseline + '%'}
        </div>
      );
      mobileBaselineLegend = (
        <div
          className="chart-v2__label hide-desktop show-phone"
          style={{ color: baselineColor }}
        >
          <span>Baseline: {isID ? baseline / 10 : baseline + '%'}</span>
          <span
            className="chart-v2__legend-example"
            style={{ borderColor: baselineColor }}
          ></span>
        </div>
      );
    }

    // only show the labels under the chart if showLabels is true
    let labelsNode = null;
    if (this.props.showLabels) {
      labelsNode = (
        <div className="chart-v2__labels">
          {labelNode}
          {mobileLabelNode}
          {mobileGoalLegend}
          {mobileBaselineLegend}
        </div>
      );
    }

    return (
      <div className="chart-v2__container">
        <div className="chart-v2" style={{ height: chartHeight + 'px' }}>
          <div className="chart-v2__measurements">{barNode}</div>
          {baselineLegend}
          {baselineLine}
          {goalLegend}
          {goalLine}
        </div>
        {labelsNode}
      </div>
    );
  }
}

export default TmlProgressChart;
