// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import { useState } from 'react';
import WheelhouseOption from './WheelhouseOption';

const Wheelhouse = (props) => {
  const lab = useLegacyLabContext();
  // only contract coaches can set their wheelhouse
  if (!Utils.isContractCoach.call(lab, props.pageUser)) {
    return null;
  }

  const [userWheelhouse, setUserWheelhouse] = useState(
    props.selectedWheelhouseOptions
  );
  const [organizedBy, setOrganizedBy] = useState('gradeBand');

  const handleUpdateWheelhouse = (data) => {
    const localUserWheelhouse = Utils.clone(userWheelhouse);
    const userWheelHouseIndex = localUserWheelhouse.findIndex(
      (wheelHouseOption) => {
        return (
          wheelHouseOption.subjectId === data.subjectId &&
          wheelHouseOption.gradeBandId === data.gradeBandId
        );
      }
    );

    if (userWheelHouseIndex > -1) {
      // update the correct option
      localUserWheelhouse[userWheelHouseIndex] = data;
    } else {
      // no option found add a new one
      localUserWheelhouse.push(data);
    }

    // setup abandon message
    props.setUnSavedChanges(true);

    // update the state
    setUserWheelhouse(localUserWheelhouse);
    props.setSelectedWheelhouseOptions(localUserWheelhouse);
  };

  let options = [];
  let fieldsHeadline = 'Subject';
  let fieldsOrganizeByLink = (
    <div
      className="v5__link account-wheelhouse__category-link"
      onClick={() => setOrganizedBy('gradeBand')}
    >
      View By Grades
    </div>
  );
  // show form fields by gradeBand as top level
  if (organizedBy === 'gradeBand') {
    fieldsHeadline = 'Grade';
    fieldsOrganizeByLink = (
      <div
        className="v5__link account-wheelhouse__category-link"
        onClick={() => setOrganizedBy('subject')}
      >
        View By Subjects
      </div>
    );
    options = props.gradeBands.map((band, i) => {
      const selectedOptions = [];
      userWheelhouse.forEach((wheelhouseItem) => {
        if (wheelhouseItem.gradeBandId === band.id) {
          selectedOptions.push(wheelhouseItem);
        }
      });
      return (
        <WheelhouseOption
          key={`field-container-band-${band.id}-${i}`}
          gradeOption={band}
          topLevelSubjects={props.topLevelSubjects}
          userWheelhouse={selectedOptions}
          handleUpdateWheelhouse={handleUpdateWheelhouse}
        />
      );
    });
    // show form fields by subject as top level
  } else {
    let wheelhouseOptionNode = null;
    props.topLevelSubjects.forEach((subject, i) => {
      const selectedOptions = [];
      userWheelhouse.forEach((wheelhouseItem) => {
        if (wheelhouseItem.subjectId === subject.id) {
          selectedOptions.push(wheelhouseItem);
        }
      });

      wheelhouseOptionNode = (
        <WheelhouseOption
          key={`subject-band-${subject.id}-${i}`}
          subjectOption={subject}
          gradeBands={props.gradeBands}
          userWheelhouse={selectedOptions}
          handleUpdateWheelhouse={handleUpdateWheelhouse}
          setUserWheelhouse={setUserWheelhouse}
        />
      );

      // if All Subjects (ID: 26597), then put at the front of the array
      if (subject.id === 26597) {
        options.unshift(wheelhouseOptionNode);
      } else {
        options.push(wheelhouseOptionNode);
      }
    });
  }
  return (
    <div className="account-wheelhouse">
      <h3 className="account-wheelhouse__header account-form__header tc__headline-5">
        {`Wheelhouse by ${fieldsHeadline}`}
      </h3>
      {fieldsOrganizeByLink}
      {options}
    </div>
  );
};
export default Wheelhouse;
