// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share';
import ZenScroll from 'zenscroll';
import Analytics from './../../../components/Analytics';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';
import AbridgeText from './../../V4/Common/AbridgeText';
import ActivateOnScrollToElement from './../../V4/Common/ActivateOnScrollToElement';
import ArtifactImage from './../../V4/Common/ArtifactImage';
import ArtifactOverlay from './../../V4/Common/ArtifactOverlay/ArtifactOverlay';
import UserPhoto from './../../V4/Common/UserPhoto';
import WindowScrollElementFixer from './../../V4/Common/WindowScrollElementFixer';
import ShareModal from './../../V4/ContentBrowser/modules/ShareModal';
import Breadcrumbs from './modules/Breadcrumbs';
import MetadataSidebar from './modules/MetadataSidebar';

const BlendedStrategy = (props) => {
  let alertNode,
    artifactModalNode,
    recommendModalNode,
    authorsNode,
    gradesSubjectsNode,
    strategyImageNode,
    strategyResourcesNode,
    similarStrategiesNode;

  const handleSocialShare = (target) => {
    Analytics.logEvent({
      category: 'blended_video',
      action: 'share_on_' + target,
      label: 'from_blended_video',
    });
  };

  const handleOpenResource = (resource, lessonFileType) => {
    // open links in a new window
    if (resource.connections.link) {
      window.open(resource.connections.link, '_blank');
      return;
    }

    resource.lessonFileType = lessonFileType;
    // open artifact overlay
    props.ui.artifactModal.open(resource);
  };

  // clicking on a link in the left side nav scrolls the page to the top of that section
  const scrollToSection = (index) => {
    const element = document.getElementById('section-' + index);
    if (element) {
      // on desktop only, clicking on a sidebar link scrolls to the associated section
      ZenScroll.setup(600, 10);
      ZenScroll.to(element);
    }
  };

  // Build the alert node if needed:
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }
  // show a modal if one is opened
  if (props.ui.artifactModal.opened) {
    artifactModalNode = (
      <ArtifactOverlay
        {...props.ui.artifactModal}
        user={props.user}
        mtLesson={true}
        analyticsLabel=""
      />
    );
  } else if (props.ui.shareModal.opened) {
    artifactModalNode = (
      <ShareModal
        {...props.ui.shareModal}
        analyticsLabel="from_blended_video"
      />
    );
  }

  const sectionLinkNodes = [
    <div
      key="nav-link-0"
      id="nav-link-0"
      onClick={() => scrollToSection(0)}
      className="tc__link strategy-v4__side-nav-row"
    >
      Overview
    </div>,
  ];

  let detailsHasImageClass = '';
  if (props.strategyImage) {
    const lessonImageContent =
      props.lessonImage[props.mtp_page_data.Lesson.image_lessonfile_id];
    detailsHasImageClass = ' lesson__details--has-image';
    strategyImageNode = (
      <ArtifactImage
        preview={lessonImageContent.preview}
        icon={lessonImageContent.icon}
        title={lessonImageContent.name}
        containerClass="lesson__image-container"
        imageClass="lesson__image"
      />
    );
  }

  if (props.mtp_page_data.Implementation.artifacts) {
    const artifactsNode = props.mtp_page_data.Implementation.artifacts.map(
      (artifact) => {
        const icon =
          artifact.connections && artifact.connections.extension
            ? artifact.connections.extension
            : 'default';
        return (
          <div
            onClick={() => {
              handleOpenResource(artifact, 'lessonfile');
            }}
            className="strategy-v4__capsule-resource"
            key={'artifact-' + artifact.id}
          >
            <ArtifactImage
              preview={artifact.connections.preview}
              icon={icon}
              title={artifact.connections.name}
              containerClass="strategy-v4__capsule-resource-image-container lesson__capsule-resource-image-container"
              imageClass="strategy-v4__capsule-resource-image"
            />
            <div className="strategy-v4__blended-artifact-details">
              <div
                className={
                  'strategy-v4__blended-artifact-icon file-type-icon ext-' +
                  icon
                }
              ></div>
              <div className="strategy-v4__blended-artifact-name">
                {artifact.connections.name}
              </div>
            </div>
          </div>
        );
      }
    );
    sectionLinkNodes.push(
      <div
        key="nav-link-1"
        id="nav-link-1"
        onClick={() => scrollToSection(1)}
        className="tc__link strategy-v4__side-nav-row"
      >
        Resources
      </div>
    );
    strategyResourcesNode = (
      <div
        className="bl4__capsule strategy-v4__capsule--has-resources strategy-v4__capsule"
        id="section-1"
      >
        <h3 className="strategy-v4__capsule-headline">Resources</h3>
        <div className="strategy-v4__capsule-resources">{artifactsNode}</div>
      </div>
    );
  }
  if (props.mtp_page_data.Implementation.similar) {
    const similarStrategies = props.mtp_page_data.Implementation.similar.map(
      (similarStrategy) => {
        return (
          <div
            className="cute-4-desktop cute-12-phone"
            key={'similar-strategy-' + similarStrategy.id}
          >
            <div className="lesson__similar bl4__capsule bl4-capsule--hoverable">
              <NavigationLink
                className="tc__link"
                url={
                  process.env.REACT_APP_TEACHING_URL +
                  '/browse/blended_video/' +
                  similarStrategy.id +
                  '/' +
                  similarStrategy.topic_seo_url
                }
              >
                <div className="lesson__similar-icon lesson__similar-icon--blended tc-v4-tablet"></div>
                <div className="lesson__similar-title tc__link">
                  {similarStrategy.title}
                </div>
                <AbridgeText
                  maxHeight={136}
                  isExpandable={false}
                  className="lesson__similar-description"
                >
                  <PurifiedHTML
                    content={similarStrategy.description}
                    tagName="span"
                  />
                </AbridgeText>
              </NavigationLink>
              <NavigationLink
                className="lesson__similar-read-more tc__link"
                url={
                  process.env.REACT_APP_TEACHING_URL +
                  '/browse/blended_video/' +
                  similarStrategy.id +
                  '/' +
                  similarStrategy.topic_seo_url
                }
              >
                read more
              </NavigationLink>
            </div>
          </div>
        );
      }
    );
    sectionLinkNodes.push(
      <div
        key="nav-link-2"
        id="nav-link-2"
        onClick={() => scrollToSection(2)}
        className="tc__link strategy-v4__side-nav-row"
      >
        Related Videos
      </div>
    );
    similarStrategiesNode = (
      <div
        className="lesson__similar-lessons lesson__similar-lessons--blended"
        id="section-2"
      >
        <h3 className="lesson__similar-lessons-headline tc__center-text">
          Related Videos
        </h3>
        <div className="row">{similarStrategies}</div>
      </div>
    );
  }

  // set up social media sharing
  const shareUrl =
    process.env.REACT_APP_TEACHING_URL +
    '/browse/blended_video/' +
    props.mtp_page_data.Implementation.id;
  const socialSharingNode = (
    <div className="strategy-v4__share">
      <div className="strategy-v4__share-text">Share</div>
      <div className="strategy-v4__share-icons">
        <div className="strategy-v4__share-icon">
          <FacebookShareButton
            url={shareUrl}
            hashtag="#BetterLesson"
            beforeOnClick={() => handleSocialShare('facebook')}
          >
            <span className="tc-facebook"></span>
          </FacebookShareButton>
        </div>
        <div className="strategy-v4__share-icon">
          <TwitterShareButton
            url={shareUrl}
            hashtags={['BetterLesson']}
            beforeOnClick={() => handleSocialShare('twitter')}
          >
            <span className="tc-twitter"></span>
          </TwitterShareButton>
        </div>
        <div className="strategy-v4__share-icon">
          <PinterestShareButton
            url={shareUrl}
            media={
              process.env.REACT_APP_LAB_URL +
              '/legacy-images/social_media/BL_squarelogo_pinterest.png'
            }
            beforeOnClick={() => handleSocialShare('pinterest')}
          >
            <span className="tc-pinterest"></span>
          </PinterestShareButton>
        </div>
        <div
          onClick={() => props.ui.shareModal.open({})}
          className="tc-v4-link strategy-v4__share-icon"
        ></div>
      </div>
    </div>
  );

  // set up any lesson authors
  let authorNodes = (
    <div>
      <UserPhoto
        src="/legacy-images/bl-icon-circle.png"
        diameter={80}
        className="strategy-v4__author-photo"
      />
      <div className="strategy-v4__author-name">
        Written by BetterLesson Master Teacher
      </div>
    </div>
  );
  if (props.mtp_page_data.Implementation.author) {
    authorNodes = (
      <NavigationLink
        url={
          process.env.REACT_APP_TEACHING_URL +
          '/browse/master_teacher/' +
          props.mtp_page_data.Implementation.author.id
        }
        className="strategy-v4__author-link strategy-v4__metadata-flex-section strategy-v4__metadata-flex-section--centered"
      >
        <div
          key={'author-' + props.mtp_page_data.Implementation.author.id}
          className="strategy-v4__metadata-flex-item"
        >
          <UserPhoto
            src={props.mtp_page_data.Implementation.author.photo}
            diameter={80}
            className="strategy-v4__author-photo"
          />
          <div className="strategy-v4__author-name">
            {props.mtp_page_data.Implementation.author.name}
          </div>
          <div className="strategy-v4__metadata-grey-text">
            {props.mtp_page_data.Implementation.author.school.location}
          </div>
        </div>
      </NavigationLink>
    );
  }
  authorsNode = (
    <div className="strategy-v4__metadata-section lesson__mobile-author tc__center-text">
      <div className="strategy-v4__metadata-headline">Author</div>
      {authorNodes}
    </div>
  );

  let gradesNode, subjectsNode;
  if (props.mtp_page_data.Implementation.grade_names) {
    gradesNode = (
      <div>
        <div className="strategy-v4__metadata-headline">Grade Level</div>
        <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
          {props.mtp_page_data.Implementation.grade_names}
        </div>
      </div>
    );
  }

  if (props.mtp_page_data.Implementation.subject_names) {
    subjectsNode = (
      <div>
        <div className="strategy-v4__metadata-headline">Subjects</div>
        <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
          {props.mtp_page_data.Implementation.subject_names}
        </div>
      </div>
    );
  }
  if (gradesNode || subjectsNode) {
    gradesSubjectsNode = (
      <div className="strategy-v4__metadata-section">
        {gradesNode}
        {subjectsNode}
      </div>
    );
  }

  return (
    <div className="tc__page">
      <Header page="lesson" {...props} />

      <Breadcrumbs
        breadcrumbs={props.mtp_page_data.Breadcrumbs}
        implementationTitle={props.mtp_page_data.Implementation.title}
        toggleSiblingsDropdown={props.toggleSiblingsDropdown}
        siblingDropdownActive={props.ui.siblingDropdownActive}
      />

      <div className="bl4__page-background show-desktop hide-phone"></div>
      {alertNode}
      {artifactModalNode}
      {recommendModalNode}

      <div className="row row--1200">
        <WindowScrollElementFixer
          positionToClass="strategy-v4__center-column"
          positionThisClass="strategy-v4__side-nav"
          positionThisContainerClass="strategy-v4__side-nav-container"
        />
        <ActivateOnScrollToElement
          elementCount={3}
          elementUpperBuffer={190}
          elementIdPrefix="section-"
          targetIdPrefix="nav-link-"
        />

        <div className="strategy-v4__side-nav-container cute-2-desktop show-desktop hide-phone">
          <div className="strategy-v4__side-nav bl4__sidebar--sticky tc__body-copy">
            {sectionLinkNodes}
          </div>
        </div>

        <div className="cute-8-desktop cute-12-phone strategy-v4__center-column">
          <div className="strategy-v4__grey-background-mobile">
            <div className="strategy-v4__top-section-text-mobile">
              <h1 className="strategy-v4__title">
                {props.mtp_page_data.Implementation.title}
              </h1>

              <div className="hide-desktop show-phone">{socialSharingNode}</div>
            </div>
          </div>
          <div className="bl4__capsule strategy-v4__capsule" id="section-0">
            <div className={'lesson__details' + detailsHasImageClass}>
              <div className="strategy-v4__capsule-headline-row">
                <h2 className="strategy-v4__capsule-headline">
                  {props.mtp_page_data.Implementation.topic}
                </h2>
              </div>
              <PurifiedHTML
                className="strategy-v4__capsule-description ckeditor__output"
                content={props.mtp_page_data.Implementation.description}
              />
              <div className="strategy-v4__capsule-description"></div>
            </div>
            {strategyImageNode}
          </div>
          <div className="hide-desktop show-phone">
            {authorsNode}
            {gradesSubjectsNode}
          </div>
          {strategyResourcesNode}
          {similarStrategiesNode}
        </div>

        <MetadataSidebar
          {...props.mtp_page_data.Implementation}
          socialSharingNode={socialSharingNode}
        />
      </div>
    </div>
  );
};

export default BlendedStrategy;
