// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';

const Domains = (props) => {
  // set up any alert banner
  const alertNode = null;

  const domains = props.domains.map((domain, i) => {
    let publishedStatus = null;
    if (domain.publish_status) {
      const publishClass =
        domain.publish_status.name !== 'Live' ? ' curation__status--grey' : '';
      publishedStatus = (
        <div key={i} className={'curation__status' + publishClass}>
          {domain.publish_status.name}
        </div>
      );
    }

    const editNode = (
      <NavigationLink
        url={'/bl/curation/domain/' + domain.id + '/edit'}
        className="tc__link tc-pencil"
      />
    );
    const searchNode = (
      <NavigationLink
        url={'/browse/learning-domain/' + domain.id}
        target="_blank"
        className="tc__link tc-outlink"
      />
    );

    return (
      <div
        key={'domain-' + domain.id}
        className={'ppd-v3-curation__row clearfix'}
        data-element-type={'domain'}
        data-item={domain.id}
      >
        <div className="cute-9-desktop">
          <div className="ppd-v3-curation__row-copy--no-left-padding">
            {domain.title}
          </div>
        </div>
        <div className="ppd-v3-curation__switch-container cute-1-desktop">
          {publishedStatus}
        </div>
        <div className="ppd-v3-curation__switch-container ppd-v3-curation__options-container cute-2-desktop">
          <div className="row">
            <div className="ppd-v3-curation__option cute-4-desktop">
              {searchNode}
            </div>
            <div className="ppd-v3-curation__option cute-4-desktop">
              {editNode}
            </div>
            <div className="ppd-v3-curation__option cute-4-desktop" />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="curation__bottom_padding">
      <Header page="Focus Areas" {...props} />
      {alertNode}

      <div className="row row--1200 tc__body-copy">
        <div className="cute-12-desktop ppd-v3-curation__breadcrumbs">
          <NavigationLink url="/bl/curation" className="tc__link">
            Content Curation
          </NavigationLink>
          <span className="tc-arrow ppd-v3-curation__breadcrumb-separator"></span>
          Focus Areas
        </div>
      </div>
      <div className="row row--1200">
        <div className="tc__headline-2 cute-12-desktop">
          <span className="cute-12-desktop">
            <h2 className="tc__headline-2">Focus Areas</h2>
          </span>
        </div>
      </div>
      <div className="row ppd-v3-curation__domains-header">
        <div className="ppd-v3-curation__domains-header-item cute-9-desktop">
          Name
        </div>
        <div className="ppd-v3-curation__domains-header-item cute-1-desktop">
          Published
        </div>
        <div className="ppd-v3-curation__domains-header-item cute-2-desktop">
          Actions
        </div>
      </div>
      {domains.map((domain, i) => {
        return (
          <div
            key={i}
            className="domains__sidebar-nav ppd-v3-curation__table ppd-v3-curation__table--domains row"
          >
            {domain}
          </div>
        );
      })}
    </div>
  );
};

Domains.propTypes = {
  ui: PropTypes.object.isRequired,
  editing: PropTypes.number,
  expandAll: PropTypes.func.isRequired,
  collapseAll: PropTypes.func.isRequired,
};

export default Domains;
