// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const CoachMatchBLExperience = (props) => {
  let pastExperienceNode = null;
  if (
    props.archivedCoaching ||
    props.archivedCoaching.length ||
    props.events ||
    props.events.length
  ) {
    const archivedCohorts = [];
    let archivedCohortsNode = (
      <div className="v5__body-copy--italic">No previous coaching</div>
    );
    if (props.archivedCoaching && !Utils.isEmpty(props.archivedCoaching)) {
      for (const key in props.archivedCoaching) {
        let titleNode = <div className="">{key}</div>;
        if (
          key === '2020 - 2021' ||
          key === '2019 - 2020' ||
          key === '2018 - 2019'
        ) {
          // const linkDate = key.replace(' - ', '-');
          // const link = (key === '2019 - 2020' || key === '2020 - 2021') ?
          //     `/lab/highlights/${props.userId}/year/${linkDate}` :
          //     `/profile/${props.userId}`;

          // TODO: change this back once the Highlights page is updated
          // titleNode = (
          //     <NavigationLink url={link} target="_blank"
          //         className="v5__link">
          //         {key}
          //     </NavigationLink>
          // );
          titleNode = key;
        }

        const dateString = Utils.handleFormatDate(
          props.archivedCoaching[key].cohortTermStart,
          props.archivedCoaching[key].cohortTermEnd
        );
        archivedCohorts.push(
          <div
            className="coach-match__archived-cohort"
            key={`archived-cohort-${props.participant.id}-${key}`}
          >
            {titleNode}
            <div className="coach-match__archived-cohort-product">
              {props.archivedCoaching[key].productDescription}
            </div>
            <div className="coach-match__details-metadata">{dateString}</div>
          </div>
        );
      }
      archivedCohortsNode = <React.Fragment>{archivedCohorts}</React.Fragment>;
    }

    let eventsNode = (
      <div className="v5__body-copy--italic">No Events Attended</div>
    );
    if (props.events && props.events.length) {
      eventsNode = props.events.map((event) => {
        if (!event.sessions.length) {
          return null;
        }

        let eventTime = null;

        const eventSessions = event.sessions.filter(
          (session) => session.num > 0
        );
        if (eventSessions.length > 1) {
          eventSessions.sort((a, b) => a.num - b.num);
        }

        const firstSession = eventSessions[0];

        if (firstSession.session_date_formatted) {
          eventTime = moment(firstSession.session_date_formatted).format('LL');
        }

        if (eventSessions.length > 1) {
          // There is more than one session, use the start date of the first session and end date of the last session
          const lastSession = eventSessions[eventSessions.length - 1];

          eventTime = Utils.handleFormatDate(
            firstSession.session_date_formatted,
            lastSession.session_date_formatted
          );
        }

        return (
          <div
            className="match__details-event"
            key={`participant-${props.participant.id}-event-${event.id}`}
          >
            <NavigationLink
              target="_blank"
              className="v5__link"
              url={`/lab/event/${event.id}/preview`}
            >
              {event.display_name}
            </NavigationLink>
            <div className="coach-match__details-metadata">{eventTime}</div>
          </div>
        );
      });
    }

    pastExperienceNode = (
      <section className="coach-match__row">
        <div className="coach-match__col--50">
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            Coaching
          </div>
          {archivedCohortsNode}
        </div>
        <div className="coach-match__col--50">
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            Events
          </div>
          {eventsNode}
        </div>
      </section>
    );
  }

  let cohortDomainsNode = null;
  if (props.cohort && props.cohort.domains && props.cohort.domains.length) {
    const cohortDomains = props.cohort.priority_domains.map((domain) => {
      return (
        <li
          key={`participant-${props.participant.id}-cohort-${props.cohort}-domain-${domain.id}`}
        >
          {domain.label}
        </li>
      );
    });
    cohortDomainsNode = (
      <React.Fragment>
        <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
          Priority Domains
        </div>
        <ul className="coach-match__list">{cohortDomains}</ul>
      </React.Fragment>
    );
  }

  let cohortOverviewNode = (
    <div className="v5__body-copy--italic">No cohort overview provided.</div>
  );
  if (props.cohort.overview) {
    cohortOverviewNode = (
      <React.Fragment>
        <PurifiedHTML
          className="ckeditor__output"
          content={props.cohort.overview}
        />
      </React.Fragment>
    );
  }

  const coachMatchRequirementsNode = (
    <div className="v5__body-copy--italic">None</div>
  );

  return (
    <React.Fragment>
      <div className="coach-match__headline coach-match__headline--blue coach-match__headline--capsule v5__heading-5">
        BetterLesson Experience
      </div>
      {pastExperienceNode}
      <div className="coach-match__headline coach-match__headline--deep-ocean coach-match__headline--capsule v5__heading-5">
        About {props.cohort.display_name}
      </div>
      <section className="coach-match__row">
        <div className="coach-match__col--50">
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            Coach Match Requirements
          </div>
          {coachMatchRequirementsNode}
        </div>
        <div className="coach-match__col--50">{cohortDomainsNode}</div>
      </section>
      <section className="coach-match__row">
        <div className="coach-match__col">
          <div className="coach-match__sub-headline coach-match__sub-headline--blue v5__body-copy--bold">
            Cohort Overview
          </div>
          {cohortOverviewNode}
        </div>
      </section>
    </React.Fragment>
  );
};

export default CoachMatchBLExperience;

CoachMatchBLExperience.propTypes = {
  userId: PropTypes.number.isRequired,
  participant: PropTypes.object.isRequired,
  cohort: PropTypes.object.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      display_name: PropTypes.string.isRequired,
      sessions: PropTypes.array.isRequired,
    })
  ).isRequired,
  archivedCoaching: PropTypes.object.isRequired,
};
