// @ts-nocheck FIXME
import WindowHelpers from './../../../../components/WindowHelpers';
import ArtifactEntry from './../../Common/Artifact';
import CompletedGoalEntryForm from './Entries/modules/forms/CompletedGoalEntryForm';
import CuratedStrategyEntryForm from './Entries/modules/forms/CuratedStrategyEntryForm';
import CustomStrategyEntryForm from './Entries/modules/forms/CustomStrategyEntryForm';
import LessonEntryForm from './Entries/modules/forms/LessonEntryForm';
import MeetingClipEntryForm from './Entries/modules/forms/MeetingClipEntryForm';
import SelfAssessmentEntryForm from './Entries/modules/forms/SelfAssessmentEntryForm';
import TextEntryForm from './Entries/modules/forms/TextEntryForm';

// @todo @jonathanrys fix these components so they are not wrapped in functions

/* Entry edit/add forms go here, they should handle their own api calls but make callbacks to the container
// to update the entries list on the webpage */

// handle adding/removing a goal tag while *not* editing the entry
const goalEntryEditForm = (
  props,
  title,
  createdFormatted,
  description,
  noteInput,
  isVisibleMethod,
  index,
  entryId
) => {
  return (
    <CompletedGoalEntryForm
      id={props.id}
      title={title}
      createdFormatted={createdFormatted}
      description={description}
      noteInput={noteInput}
      addEntryCallback={(responseEntry) =>
        props.addEntryCallback(responseEntry)
      }
      editEntryCallback={(responseEntry) =>
        props.editEntryCallback(responseEntry, index)
      }
      activeStoryId={props.activeStoryId}
      entryId={entryId}
      close={() => isVisibleMethod(false)}
      setErrorAlert={props.setErrorAlert}
    />
  );
};
const customStrategyEntryEditForm = (
  props,
  titleInput,
  textInput,
  author,
  currentGoals,
  isVisibleMethod,
  confirmDelete,
  index,
  entryId,
  highlighted,
  toggleGoalTag
) => {
  return (
    <CustomStrategyEntryForm
      id={props.id}
      titleInput={titleInput}
      textInput={textInput}
      author={author}
      pageUser={props.pageUser}
      currentUser={props.user}
      goals={props.goals}
      currentGoals={currentGoals}
      addEntryCallback={(responseEntry) =>
        props.addEntryCallback(responseEntry)
      }
      editEntryCallback={(responseEntry) =>
        props.editEntryCallback(responseEntry, index)
      }
      activeStoryId={props.activeStoryId}
      entryId={entryId}
      scrollTo={props.scrollTo}
      setScrollTo={props.setScrollTo}
      close={() => isVisibleMethod(false)}
      userId={props.pageUser.id}
      confirmDelete={confirmDelete}
      canEditAuthor={props.canEditAuthor}
      setErrorAlert={props.setErrorAlert}
      isEntryHighlighted={!!highlighted}
      tagGoalResponseCallback={props.tagGoalResponseCallback}
      untagGoalResponseCallback={props.untagGoalResponseCallback}
      toggleGoalTag={toggleGoalTag}
    />
  );
};
const curatedStrategyEntryEditForm = (
  props,
  entryTitle,
  noteInput,
  author,
  currentGoals,
  isVisibleMethod,
  confirmDelete,
  index,
  entry,
  toggleGoalTag
) => {
  return (
    <CuratedStrategyEntryForm
      isAdding={entry.startInEditMode}
      userId={props.pageUser.id}
      index={index}
      id={entry.id}
      strategy_id={entry.strategy_id}
      entryTitle={entryTitle}
      createdFormatted={entry.created_formatted}
      noteInput={noteInput}
      author={author}
      pageUser={props.pageUser}
      currentUser={props.user}
      goals={props.goals}
      currentGoals={currentGoals}
      editEntryCallback={(responseEntry) =>
        props.editEntryCallback(responseEntry, index)
      }
      activeStoryId={props.activeStoryId}
      storyCuratedStrategyId={entry.story_curated_strategy_id}
      lessonfile={entry.lessonfile}
      title={entry.title}
      tagline={entry.tagline}
      is_viewable={entry.is_viewable}
      close={() => isVisibleMethod(false)}
      confirmDelete={confirmDelete}
      canEditAuthor={props.canEditAuthor}
      deleteEntry={props.deleteEntry}
      setErrorAlert={props.setErrorAlert}
      isEntryHighlighted={!!entry.highlighted}
      tagGoalResponseCallback={props.tagGoalResponseCallback}
      untagGoalResponseCallback={props.untagGoalResponseCallback}
      toggleGoalTag={toggleGoalTag}
    />
  );
};
const lessonEntryEditForm = (
  props,
  entryTitle,
  noteInput,
  author,
  currentGoals,
  isVisibleMethod,
  confirmDelete,
  index,
  entry,
  toggleGoalTag
) => {
  return (
    <LessonEntryForm
      isAdding={entry.startInEditMode}
      userId={props.pageUser.id}
      index={index}
      id={entry.id}
      entryTitle={entryTitle}
      createdFormatted={entry.created_formatted}
      noteInput={noteInput}
      author={author}
      pageUser={props.pageUser}
      currentUser={props.user}
      goals={props.goals}
      currentGoals={currentGoals}
      editEntryCallback={(responseEntry) =>
        props.editEntryCallback(responseEntry, index)
      }
      activeStoryId={props.activeStoryId}
      storyLessonId={entry.story_lesson_id}
      lessonfile={entry.lessonfile}
      lesson_id={entry.lesson_id}
      title={entry.title}
      course_name={entry.course_name}
      unit_name={entry.unit_name}
      tagline={entry.tagline}
      close={() => isVisibleMethod(false)}
      confirmDelete={confirmDelete}
      canEditAuthor={props.canEditAuthor}
      deleteEntry={props.deleteEntry}
      setErrorAlert={props.setErrorAlert}
      isEntryHighlighted={!!entry.highlighted}
      tagGoalResponseCallback={props.tagGoalResponseCallback}
      untagGoalResponseCallback={props.untagGoalResponseCallback}
      toggleGoalTag={toggleGoalTag}
    />
  );
};
const textEntryEditForm = (
  props,
  titleInput,
  textInput,
  author,
  currentGoals,
  isVisibleMethod,
  confirmDelete,
  index,
  entryId,
  highlighted,
  toggleGoalTag
) => {
  return (
    <TextEntryForm
      id={props.id}
      titleInput={titleInput}
      textInput={textInput}
      author={author}
      pageUser={props.pageUser}
      currentUser={props.user}
      goals={props.goals}
      currentGoals={currentGoals}
      addEntryCallback={(responseEntry) =>
        props.addEntryCallback(responseEntry)
      }
      editEntryCallback={(responseEntry) =>
        props.editEntryCallback(responseEntry, index)
      }
      activeStoryId={props.activeStoryId}
      entryId={entryId}
      close={() => isVisibleMethod(false)}
      confirmDelete={confirmDelete}
      canEditAuthor={props.canEditAuthor}
      setErrorAlert={props.setErrorAlert}
      isEntryHighlighted={!!highlighted}
      toggleGoalTag={toggleGoalTag}
      tagGoalResponseCallback={props.tagGoalResponseCallback}
      untagGoalResponseCallback={props.untagGoalResponseCallback}
    />
  );
};
const meetingClipEntryEditForm = (
  props,
  titleInput,
  textInput,
  currentGoals,
  isVisibleMethod,
  confirmDelete,
  index,
  entryId,
  connections,
  highlighted,
  toggleGoalTag
) => {
  return (
    <MeetingClipEntryForm
      id={props.id}
      titleInput={titleInput}
      textInput={textInput}
      goals={props.goals}
      currentGoals={currentGoals}
      addEntryCallback={(responseEntry) =>
        props.addEntryCallback(responseEntry)
      }
      editEntryCallback={(responseEntry) =>
        props.editEntryCallback(responseEntry, index)
      }
      activeStoryId={props.activeStoryId}
      entryId={entryId}
      connections={connections}
      close={() => isVisibleMethod(false)}
      confirmDelete={confirmDelete}
      setErrorAlert={props.setErrorAlert}
      isEntryHighlighted={!!highlighted}
      toggleGoalTag={toggleGoalTag}
      tagGoalResponseCallback={props.tagGoalResponseCallback}
      untagGoalResponseCallback={props.untagGoalResponseCallback}
    />
  );
};
const artifactBuilder = (
  props,
  mode,
  type,
  isVisibleMethod,
  confirmDelete,
  index,
  artifact,
  toggleGoalTag
) => {
  return (
    <ArtifactEntry
      mode={mode}
      close={() => isVisibleMethod(false)}
      isLink={type === 'link'}
      addEntryCallback={(responseEntry) =>
        props.addEntryCallback(responseEntry)
      }
      editEntryCallback={(responseEntry) =>
        props.editEntryCallback(responseEntry, index)
      }
      storyId={props.activeStoryId}
      artifactUserId={props.pageUser.id}
      data={artifact}
      confirmDelete={confirmDelete}
      isMobile={WindowHelpers.isMobileDevice()}
      pageUser={props.pageUser}
      currentUser={props.user}
      goals={props.goals}
      canEditAuthor={props.canEditAuthor}
      setErrorAlert={props.setErrorAlert}
      isEntryHighlighted={
        artifact &&
        Object.prototype.hasOwnProperty.call(artifact, 'highlighted') &&
        artifact.highlighted
      }
      tagGoalResponseCallback={props.tagGoalResponseCallback}
      untagGoalResponseCallback={props.untagGoalResponseCallback}
      toggleGoalTag={toggleGoalTag}
    />
  );
};
const selfAssessmentEntryForm = (
  props,
  assessmentSurveyEntryId,
  currentGoals,
  isVisibleMethod,
  index,
  surveyData
) => {
  return (
    <SelfAssessmentEntryForm
      close={() => isVisibleMethod(false)}
      editEntryCallback={(responseEntry) =>
        props.editEntryCallback(responseEntry, index)
      }
      activeStoryId={props.activeStoryId}
      goals={props.goals}
      assessmentSurveyEntryId={assessmentSurveyEntryId}
      setErrorAlert={props.setErrorAlert}
    >
      {surveyData}
    </SelfAssessmentEntryForm>
  );
};

export {
  artifactBuilder,
  curatedStrategyEntryEditForm,
  customStrategyEntryEditForm,
  goalEntryEditForm,
  lessonEntryEditForm,
  meetingClipEntryEditForm,
  selfAssessmentEntryForm,
  textEntryEditForm,
};
