// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import HelpDeskLinkGateway from '@legacy-components/react/V5/Common/HelpDeskLinkGateway';

const AddEntryWidget = (props) => {
  return (
    <div className="workspace__add-entry-menu">
      <h4 className="v5__heading-6 workspace__add-entry-label">
        Add to Workspace
      </h4>

      <div className="workspace__add-entry-widget">
        <div className="workspace__add-entry-option-row">
          <NavigationLink
            url={`/lab/workspace/${props.userId}/select-resource`}
            className="v5__link workspace__add-entry-option"
          >
            <div className="tc-v4-strategy workspace__add-entry-option-icon"></div>
            Resource
          </NavigationLink>
        </div>

        <div className="workspace__add-entry-option-row">
          <span
            className="workspace__add-entry-option"
            onClick={() => props.setNewEntryType('file')}
          >
            <div className="tc-v4-page workspace__add-entry-option-icon"></div>
            File
          </span>
        </div>

        <div className="workspace__add-entry-option-row">
          <span
            className="workspace__add-entry-option"
            onClick={() => props.setNewEntryType('link')}
          >
            <div className="tc-v4-link workspace__add-entry-option-icon"></div>
            Link
          </span>
        </div>

        <div className="workspace__add-entry-option-row">
          <span
            className="workspace__add-entry-option"
            onClick={() => props.setNewEntryType('text')}
          >
            <div className="tc-v4-reflection workspace__add-entry-option-icon"></div>
            Text
          </span>
        </div>

        <HelpDeskLinkGateway
          smallVersion={true}
          className="event-help__attendee-block"
        />
      </div>
    </div>
  );
};

export default AddEntryWidget;
