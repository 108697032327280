// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const TeacherNoteModal = (props) => {
  const handleCloseOverlay = (e) => {
    e.preventDefault();
    props.showTeacherNote(null);
  };

  return (
    <div>
      <div
        className="modal__page-overlay modal__page-overlay--grey ppp-modal__page-overlay"
        onClick={handleCloseOverlay}
      ></div>
      <div className="modal--fixed modal--grey-overlay ppp-modal">
        <div className="modal__close" onClick={handleCloseOverlay}>
          <div className="tc-x"></div>
        </div>
        <div className="ppp-modal__body">
          <div className="tc__headline-2">{props.name}</div>
          <div className="tc__body-copy ppp-modal__body-text">{props.note}</div>
        </div>
      </div>
    </div>
  );
};

TeacherNoteModal.propTypes = {
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  showTeacherNote: PropTypes.func.isRequired,
};

export default TeacherNoteModal;
