// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import PropTypes from 'prop-types';
import React from 'react';

const SwitchToBasicConfirmationModal = (props) => {
  // body text of the modal depends on context
  let textNodes = (
    <React.Fragment>
      <div className="v5__body-copy edit-event__modal-row">
        You will lose sessions created for individual groups and{' '}
        {props.groupName}’s sessions will be applied to all groups.
      </div>

      <div className="v5__body-copy edit-event__modal-row">
        All group names will be copied over. Facilitator assignments and event
        materials links will also be copied over.
      </div>
    </React.Fragment>
  );
  if (props.isDeletingGroups) {
    textNodes = (
      <React.Fragment>
        <div className="v5__body-copy edit-event__modal-row">
          If you no longer want to split the event into multiple groups, we will
          revert you back to the Basic Event Builder.
        </div>

        <div className="v5__body-copy edit-event__modal-row">
          All facilitator assignments and event materials links from{' '}
          {props.groupName}’s sessions will remain, but the other group&apos;s
          sessions will be lost.
        </div>
      </React.Fragment>
    );
  } else if (ArrayHelpers.hasElements(props.lostSessions)) {
    textNodes = (
      <React.Fragment>
        <div className="v5__body-copy edit-event__modal-row">
          You will lose sessions created for individual groups and{' '}
          {props.groupName}’s sessions will be applied to all groups.
        </div>

        <div className="v5__body-copy edit-event__modal-row">
          The number of groups and group names will be copied over.
        </div>

        <div className="v5__body-copy edit-event__modal-row">
          All group names will be copied over. Facilitator assignments and event
          materials links will also be copied over, except for the following:
          <ul>
            {props.lostSessions.map((session) => {
              return (
                <li
                  key={`${session.groupName}-${session.sessionName}`}
                  className="edit-event__modal-list-item"
                >
                  {session.groupName}: {session.sessionName}
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    );
  }

  return (
    <ActionModal
      headlineText="Revert to Basic Event Builder?"
      close={props.close}
      spaceFromTop={50}
    >
      {textNodes}

      <div className="edit-event__modal-btn-row">
        <button className="v5__btn v5__btn--red" onClick={props.confirm}>
          Revert
        </button>
      </div>
    </ActionModal>
  );
};

SwitchToBasicConfirmationModal.propTypes = {
  groupName: PropTypes.string,
  lostSessions: PropTypes.array,
  isDeletingGroups: PropTypes.bool,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default SwitchToBasicConfirmationModal;
