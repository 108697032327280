// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import PropTypes from 'prop-types';

const DomainRow = (props) => {
  let activeTeachers = null;
  if (props.participant_list) {
    activeTeachers = props.participant_list.map((teacher, i) => {
      return (
        <div key={i} className="partner_lead_v5__author-entry domain">
          <div className="workspace__comment-photo-col">
            <UserPhoto
              src={teacher.photo}
              userId={teacher.id}
              firstName={teacher.first_name}
              lastName={teacher.last_name}
              diameter={40}
            />
          </div>
          <div className="partner_lead_v5__teacher-info domain">
            <NavigationLink
              url={`/lab/highlights/${teacher.id}`}
              className="tc__link partner_lead_v5__teacher-name"
            >
              {teacher.first_name} {teacher.last_name}
            </NavigationLink>
            <span className="partner_lead__school-name">
              , {teacher.school_name}
            </span>
          </div>
        </div>
      );
    });
  }

  // set up goal expand/collapse arrow
  const arrowClass = props.openRow
    ? 'workspace__goal-expand-arrow--expanded'
    : '';

  // show goal details if expanded
  const bodyNode = props.openRow ? (
    <div className="partner_lead__learning_domain_teacher">
      <div className="workspace__goal-description v5__body-copy domain">
        {activeTeachers}
      </div>
    </div>
  ) : null;

  let numEducators = 0;
  if (activeTeachers) {
    numEducators = props.participant_list.length;
  }

  let educatorsNode = null;
  let arrowNode = null;
  if (numEducators !== 0) {
    arrowNode = (
      <div
        className={`tc-v4-circle-arrow workspace__goal-expand-arrow partner_lead__expand-arrow partner_lead__strategy-arrow ${arrowClass}`}
        onClick={() => props.updateShow(props.id)}
      ></div>
    );

    educatorsNode = (
      <div className="v5__registered-table-educator-container cute-3-desktop">
        <div className="partner_lead__learning-domain-num-teachers partner_lead__num-educators">
          {numEducators} Educator{numEducators > 1 ? 's' : ''}
        </div>
        {arrowNode}
      </div>
    );
  }

  return (
    <div className="partner_lead__learning_domain_row_container">
      <div className="partner_lead__learning_domain_row">
        <div className="partner_lead__learning-domain-row-header">
          <div className="cute-9-desktop partner_lead__learning-domain-row-header">
            <div
              className={`initiative__domain-icon-container v5__domain-circle v5__domain-tag--${props.id}`}
            >
              <div className={`tc-v4-domain-${props.id}`} />
            </div>
            <NavigationLink
              url={`${process.env.REACT_APP_TEACHING_URL}/browse/learning-domain/${props.id}`}
              className="v5__link"
            >
              {props.title}
            </NavigationLink>
          </div>
          {educatorsNode}
        </div>
      </div>
      {bodyNode}
    </div>
  );
};

DomainRow.propTypes = {
  participant_list: PropTypes.array,
  title: PropTypes.string,
  id: PropTypes.number,
};

export default DomainRow;
