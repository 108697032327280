// @ts-nocheck FIXME
import React from 'react';

class Feedback extends React.Component {
  // render comments associated with each piece of feedback
  renderCommentNode = (step) => {
    if (this.props[step].comments) {
      return this.props[step].comments.map(function (comment) {
        return (
          <div
            className="portfolio__feedback-coach-message"
            key={step + '-feedback-comment-' + comment.created}
          >
            <div className="portfolio__feedback-coach-message-note">
              <span className="portfolio__feedback-quote">&ldquo;</span>
              <span className="portfolio__feedback-comment">
                {comment.data}
              </span>
              <span className="portfolio__feedback-quote">&rdquo;</span>
            </div>
            <div className="portfolio__feedback-coach-message-author">
              &mdash; {comment.first_name} {comment.last_name},{' '}
              {comment.is_coach ? 'BetterLesson Coach' : 'Teacher'}
            </div>
          </div>
        );
      });
    }
  };

  // render individual piece of feedback
  renderFeedbackNode = (step, question, answer) => {
    let questionNode = null;
    if (question) {
      questionNode = (
        <div className="portfolio__feedback-question">{question}</div>
      );
    }
    return (
      <div className="portfolio__feedback">
        {questionNode}
        <div className="portfolio__feedback-answer">{answer}</div>
        <div className="portfolio__feedback-notes">
          {this.props[step].message}
        </div>
        {this.renderCommentNode(step)}
      </div>
    );
  };

  render() {
    let question = '';
    let answer = '';

    let teachNode = null;
    if (this.props.teach) {
      question =
        this.props.loopType == 'baseline'
          ? 'When I started this Teaching Challenge'
          : null;
      answer =
        this.props.loopType == 'baseline'
          ? {
              1: 'I knew I needed support in this area.',
              2: 'I was unsure about how I was supporting students in this area.',
              3: 'I felt confident in my ability to address this challenge.',
            }
          : {
              1: 'After implementing this strategy, I still have room to grow.',
              2: 'My implementation of this strategy was a moderate success.',
              3: 'My implementation of this strategy was a resounding success!',
            };
      teachNode = this.renderFeedbackNode(
        'teach',
        question,
        answer[this.props.teach.rating]
      );
    }

    let measureNode = null;
    if (this.props.measure) {
      question =
        this.props.loopType == 'baseline'
          ? 'At the start of this Teaching Challenge'
          : null;
      answer =
        this.props.loopType == 'baseline'
          ? {
              1: 'My students needed a significant amount of support.',
              2: 'My students needed some support.',
              3: 'My students were off to a great start!',
            }
          : {
              1: "This strategy didn't help my students, but here is what I learned.",
              2: 'This strategy had little impact on my students.',
              3: 'This strategy had a positive impact on my students.',
            };
      measureNode = this.renderFeedbackNode(
        'measure',
        question,
        answer[this.props.measure.rating]
      );
    }

    let learnNode = null;
    if (this.props.learn) {
      answer = {
        1: 'I will not use this strategy again.',
        2: 'I might use this strategy again.',
        3: 'I will use this strategy again.',
      };
      learnNode = this.renderFeedbackNode(
        'learn',
        null,
        answer[this.props.learn.rating]
      );
    }

    // render the header if we have any feedback and its a baseline loop
    let headerNode = null;
    if (
      (teachNode || measureNode || learnNode) &&
      this.props.loopType == 'baseline'
    ) {
      headerNode = (
        <div className="portfolio__section-headline portfolio__section-headline--feedback">
          Initial Reflections
        </div>
      );
    }

    return (
      <div className="portfolio__feedback-section">
        {headerNode}
        {teachNode}
        {measureNode}
        {learnNode}
      </div>
    );
  }
}

export default Feedback;
