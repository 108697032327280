// @ts-nocheck FIXME
import Q from 'q';
import ResourceApi from './../apis/ResourceApi';

const S3 = {
  // return a promise that the upload will happen
  upload: function (file) {
    return Q.Promise(function (resolve, reject, notify) {
      const fileData = {
        id: null, // will be populated upon success of createBeforeUpload
        containerType: file.containerType,
        containerId: file.containerId,
        name: file.name,
        size: file.size,
        progress: 0,
        uuid: file.uuid,
        preview: null,
        teacherId: file.teacherId,
      };

      ResourceApi.createBeforeUpload(fileData)
        .then(null, function onError(reason) {
          fileData['errorReason'] = 'errored';

          reject(fileData);

          throw 'error';
        })
        .then(function onSuccess(response) {
          // save lessonfile information
          fileData.id = response.lessonfileId;

          // build the form data that will be used to submit to S3
          const data = new FormData();
          data.append('key', response.fields.key);
          data.append('policy', response.fields.policy);
          data.append('x-amz-algorithm', response.fields['x-amz-algorithm']);
          data.append('x-amz-credential', response.fields['x-amz-credential']);
          data.append('x-amz-date', response.fields['x-amz-date']);
          data.append('x-amz-signature', response.fields['x-amz-signature']);
          data.append('file', file);

          // kick off S3 upload, and handle error by deleting the record created
          return ResourceApi.upload(response.url, data).then(
            null,
            function onError(reason) {
              fileData['errorReason'] = reason;

              // we want to delete the lessonfile record we created
              ResourceApi.delete({
                lessonfileId: fileData.id,
                teacherId: fileData.teacherId,
              });

              // reject upload promise
              reject(fileData);

              throw 'error';
            }
          );
        })
        .then(
          function onSuccess() {
            const data = {
              lessonfileId: fileData.id,
              containerType: fileData.containerType,
              containerId: fileData.containerId,
              teacherId: fileData.teacherId,
            };

            // now that the file has been uploaded, update the lessonfile record
            return ResourceApi.saveAfterUpload(data).then(
              null,
              function onError() {
                fileData['errorReason'] = 'errored';

                // reject upload promise
                reject(fileData);

                throw 'error';
              }
            );
          },
          null,
          function onProgress(e) {
            // get percentage
            const percentage = Math.floor((e.loaded / file.size) * 100);
            fileData.progress = percentage;

            notify(fileData);
          }
        )
        .then(function onSuccess(response) {
          if (response.payload && response.payload.preview) {
            fileData.preview = response.payload.preview;
          }
          resolve(fileData);
        });
    });
  },

  /*
        flexibleUpload allows the caller to provide their own callbacks
        Params:
        * file
        * beforeUploadCallback
        * uploadCallback
        * afterUploadCallback
        * cleanupCallback
        * callbackFields: An object with all the properties required for the various callbacks
    */
  flexibleUpload: function (
    file,
    beforeUploadCallback,
    uploadCallback,
    afterUploadCallback,
    cleanupCallback,
    callbackFields
  ) {
    return Q.Promise(function (resolve, reject, notify) {
      const fileData = {
        id: null,
        containerType: file.containerType,
        containerId: file.containerId,
        name: file.name,
        size: file.size,
        progress: 0,
        uuid: file.uuid,
        preview: null,
      };

      // Add the callback fields to fileData to pass them along
      Object.keys(callbackFields).forEach(function (key) {
        fileData[key] = callbackFields[key];
      });

      beforeUploadCallback(fileData)
        .then(
          // Nothing should be done on success
          null,
          function onError(response) {
            // Update the file with the error properties
            fileData.error = response.error;
            fileData.status = 'error';
            // reject upload promise
            reject(fileData);
            // Throw an exception to trigger the error handler for the next promise
            throw response.error;
          }
        )
        .then(function onSuccess(response) {
          // Hit the afterUploadCallback
          fileData.id = response.lessonfileId;
          fileData.lessonfileId = response.lessonfileId;
          // build the form data that will be used to submit to S3
          const data = new FormData();
          data.append('key', response.fields.key);
          data.append('policy', response.fields.policy);
          data.append('x-amz-algorithm', response.fields['x-amz-algorithm']);
          data.append('x-amz-credential', response.fields['x-amz-credential']);
          data.append('x-amz-date', response.fields['x-amz-date']);
          data.append('x-amz-signature', response.fields['x-amz-signature']);
          data.append('file', file);
          // kick off S3 upload
          return uploadCallback(response.url, data).then(
            // Nothing should be done on success
            null,
            function onError(reason) {
              // Handle S3 upload failures
              let exception = reason;
              // uploadCallback may throw an exception. Translate criptic error messages to a human readable format
              if (reason === 'errored') {
                exception =
                  'There was an error uploading your file. Please try again.';
              }
              // Update the file with the error properties
              fileData.error = exception;
              fileData.status = 'error';
              // reject upload promise
              reject(fileData);
              // Cleanup the mess of the failed upload
              cleanupCallback(fileData);
              // Propagate the exception
              throw exception;
            }
          );
        })
        .then(
          function onSuccess() {
            // Create the standard data object
            const data = {
              lessonfileId: fileData.id,
              containerType: fileData.containerType,
              containerId: fileData.containerId,
            };
            // Add the provided callback fields to the data object
            Object.keys(callbackFields).forEach(function (key) {
              data[key] = callbackFields[key];
            });
            // now that the file has been uploaded, hit the afterUploadCallback method
            return afterUploadCallback(data).then(
              // Do nothing on success
              null,
              function onError(response) {
                // afterUploadCallback failed
                // Cleanup the mess of the failed upload
                cleanupCallback(data);
                // Update the file with the error properties
                fileData.error = response.error;
                fileData.status = 'error';
                // reject upload promise
                reject(fileData);
                // Throw an exception to trigger the error handler for the next promise
                throw response.error;
              }
            );
          },
          null,
          function onProgress(e) {
            // Notify the caller of upload progress
            const percentage = Math.floor((e.loaded / file.size) * 100);
            fileData.progress = percentage;
            notify(fileData);
          }
        )
        .then(function onSuccess(response) {
          // Update the file object
          if (response.preview) {
            fileData.preview = response.preview;
          } else if (response.payload && response.payload.preview) {
            fileData.preview = response.payload.preview;
          }
          // Resolve the promise(s)
          resolve(fileData);
        });
    });
  },

  profileImageUpload: function (
    file,
    beforeUploadCallback,
    uploadCallback,
    afterUploadCallback,
    callbackFields
  ) {
    return Q.Promise(function (resolve, reject, notify) {
      let fileName = null;
      const fileData = {
        id: null,
        containerType: file.containerType,
        containerId: file.containerId,
        name: file.name,
        size: file.size,
        progress: 0,
        uuid: file.uuid,
        preview: null,
      };

      // Add the callback fields to fileData to pass them along
      Object.keys(callbackFields).forEach(function (key) {
        fileData[key] = callbackFields[key];
      });

      beforeUploadCallback(fileData)
        .then(
          // Nothing should be done on success
          null,
          function onError(response) {
            // Update the file with the error properties
            fileData.error = response.error;
            fileData.status = 'error';
            // reject upload promise
            reject(fileData);
            // Throw an exception to trigger the error handler for the next promise
            throw response.error;
          }
        )
        .then(function onSuccess(response) {
          fileName = response.fields.key;

          // build the form data that will be used to submit to S3
          const data = new FormData();
          data.append('key', response.fields.key);
          data.append('policy', response.fields.policy);
          data.append('x-amz-algorithm', response.fields['x-amz-algorithm']);
          data.append('x-amz-credential', response.fields['x-amz-credential']);
          data.append('x-amz-date', response.fields['x-amz-date']);
          data.append('x-amz-signature', response.fields['x-amz-signature']);
          data.append('file', file);

          // kick off S3 upload
          return uploadCallback(response.url, data).then(
            // Nothing should be done on success
            null,
            function onError(reason) {
              // Handle S3 upload failures
              let exception = reason;
              // uploadCallback may throw an exception. Translate criptic error messages to a human readable format
              if (reason === 'errored') {
                exception =
                  'There was an error uploading your file. Please try again.';
              }
              // Update the file with the error properties
              fileData.error = exception;
              fileData.status = 'error';
              // reject upload promise
              reject(fileData);
              // Propagate the exception
              throw exception;
            }
          );
        })
        .then(
          function onSuccess() {
            // Create the standard data object
            const data = {
              fileName: fileName,
            };

            // Add the provided callback fields to the data object
            Object.keys(callbackFields).forEach(function (key) {
              data[key] = callbackFields[key];
            });

            // now that the file has been uploaded, hit the afterUploadCallback method
            return afterUploadCallback(data).then(
              // Do nothing on success
              null,
              function onError(response) {
                // Update the file with the error properties
                fileData.error = response.error;
                fileData.status = 'error';
                // reject upload promise
                reject(fileData);
                // Throw an exception to trigger the error handler for the next promise
                throw response.error;
              }
            );
          },
          null,
          function onProgress(e) {
            // Notify the caller of upload progress
            const percentage = Math.floor((e.loaded / file.size) * 100);
            fileData.progress = percentage;
            notify(fileData);
          }
        )
        .then(function onSuccess(response) {
          // Resolve the promise(s)
          resolve(fileData);
        });
    });
  },
};

export default S3;
