// @ts-nocheck FIXME
import ArtifactApi from './../apis/ArtifactApi';
import Analytics from './../components/Analytics';
import Store from './../components/Store';

const store = {
  id: null,
  created: null,
  sections: [],
  tab: null,
  entry: null,
  artifact: null,
  teacherNote: null,
  ui: {
    displayedCategoryId: null,
    displayedGAId: null,
    displayedTeacher: null,
    scrollToTeacher: false,
    displayedTeacherArray: [],
    displayedSpotlightTypeId: null,
    artifactVisible: false,
    fullScreenAvailable: false,
    artifactDownloading: false,
    downloadError: null,
    activeArtifactPage: 1,
  },
};

store.init = (data) => {
  store.id = data.id;
  store.tab = data.tab;
  store.created = data.created;
  store.sections = data.sections;
  store.entry = data.entry;
};

const changeDisplayedCategory = (data) => {
  store.ui.displayedCategoryId = data.categoryId;
};

const changeDisplayedGA = (data) => {
  store.ui.displayedGAId = data.gaId;
};

const changeDisplayedTeacher = (data) => {
  if (store.ui.displayedTeacher == data.teacherId) {
    store.ui.displayedTeacher = null;
    return;
  }
  store.ui.displayedTeacher = data.teacherId;
  store.ui.scrollToTeacher = true;
};

store.changeDisplayedCategory = (data) => {
  changeDisplayedCategory({ categoryId: data.categoryId });

  // Record the analytics event:
  const tab = store.tab.replace('-', '_');
  const subject = data.categoryName.replace(' ', '_').toLowerCase();

  Analytics.logEvent({
    category: 'growth_areas',
    action: subject,
    label: 'from_pp_consumer_view',
  });
};

store.changeDisplayedGA = (data) => {
  if (store.ui.displayedGAId == data.gaId) {
    store.ui.displayedGAId = null;
    return;
  }

  store.ui.displayedGAId = data.gaId;
};

store.changeDisplayedTeacher = (data) => {
  changeDisplayedTeacher({
    teacherId: data.teacherId,
  });
};

store.clearScrollToTeacher = (data) => {
  store.ui.scrollToTeacher = false;
};

store.changeDisplayedSpotlightType = (data) => {
  store.ui.displayedSpotlightTypeId = data.spotlightTypeId;

  let action = data.spotlightName.toLowerCase() + '_list';
  switch (data.spotlightName) {
    case 'Quote':
      action = 'testimonial_list';
      break;
    case 'Coaching':
      action = 'coach_video_list';
      break;
  }

  Analytics.logEvent({
    category: 'Spotlights',
    action: action,
    label: 'from_pp_consumer_view',
  });
};

store.toggleTeacherSpotlightArtifacts = (data) => {
  if (
    store.entry &&
    store.entry.extended_data &&
    store.entry.extended_data.tml_loops
  ) {
    const loop = store.entry.extended_data.tml_loops.find(
      (loop) => loop.loopId == data.loopId
    );
    if (loop) {
      loop.showArtifacts = !loop.showArtifacts;
    }
  }
};

store.showArtifact = (data) => {
  store.artifact = data;
  store.ui.scrollPosition =
    window.pageYOffset =
    document.documentElement.scrollTop =
      document.body.scrollTop;
  store.ui.artifactVisible = true;

  // Record the analytics event:
  const tab = store.tab.replace('-', '_');
  Analytics.logEvent({
    category: tab,
    action: 'view_artifact_spotlight',
    label: 'from_pp_consumer_view',
  });
};

store.hideArtifact = (data) => {
  store.artifact = null;
  store.ui.artifactVisible = false;
  store.ui.scrollPosition = 0;
};

store.showNextPage = () => {
  store.ui.activeArtifactPage = store.ui.activeArtifactPage + 1;
};

store.showPreviousPage = () => {
  store.ui.activeArtifactPage = store.ui.activeArtifactPage - 1;
};

store.downloadArtifact = (data) => {
  // clear any error
  store.downloadError = null;

  // If there is no download url, show an error:
  if (!store.artifact.download_url) {
    store.downloadError = 'An error occurred. Please try again!';
    return;
  }

  Analytics.logEvent({
    category: 'artifact',
    action: 'download',
    label: 'from_partner_portfolio_artifact_modal',
  });

  // start the spinner
  store.artifactDownloading = true;

  // call different endpoints depending on whether or not the artifact is a TC artifact
  if (store.artifact.owner) {
    // Increment the count in the DB
    return ArtifactApi.postTcArtifactDownload(store.artifact.id)
      .then((response) => {
        store.artifactDownloading = false;
        // Trigger the download
        location.href = store.artifact.download_url;
      })
      .catch((response) => {
        store.downloadError = 'An error occurred. Please try again!';
        store.artifactDownloading = false;
      });
  } else {
    // Increment the count in the DB
    return ArtifactApi.postArtifactDownload(
      store.artifact.strategy_implementation_id,
      store.artifact.id
    )
      .then((response) => {
        store.artifactDownloading = false;
        // Trigger the download
        location.href = store.artifact.download_url;
      })
      .catch((response) => {
        store.downloadError = 'An error occurred. Please try again!';
        store.artifactDownloading = false;
      });
  }
};

store.showTeacherNote = (data) => {
  store.teacherNote = data;
  const analyticsAction = data ? 'show_note' : 'close_note';

  Analytics.logEvent({
    category: 'teacher_cohort',
    action: analyticsAction,
    label: 'from_pp_consumer_view',
  });
};

export default Store('PartnerPortfolioStore', store);
