// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import ArtifactImage from '@legacy-components/react/V4/Common/ArtifactImage';
import AbridgeText from '@legacy-components/react/V5/Common/AbridgeText';
import PropTypes from 'prop-types';
import React from 'react';
import NavigationLink from './../../../../NavigationLink';

const LessonEntry = (props) => {
  const inputEL = React.useRef(null);

  React.useEffect(() => {
    if (props.smallView) {
      const elem = inputEL.current;
      const elemHeight = elem.getBoundingClientRect().height;

      if (elemHeight > 220) {
        elem.style.height = '220px';
        elem.style.overflowY = 'hidden';
        props.setTruncateState(true);
      }
    }
  }, []);

  let imageNode;
  if (props.lessonfile && props.lessonfile.connections) {
    imageNode = (
      <ArtifactImage
        preview={props.lessonfile.connections.preview}
        title={props.lessonfile.connections.name}
        containerClass={'workspace__entry-image-container'}
        icon={props.lessonfile.connections.icon}
        imageClass="workspace__entry-image"
      />
    );
  }

  let courseNode;
  if (props.course_name || props.unit_name) {
    courseNode = (
      <div className="workspace__entry-header-timestamp">
        {props.course_name} &raquo; {props.unit_name}
      </div>
    );
  }

  let toggle = true;
  if (props.toggle === false) {
    toggle = false;
  }

  let bodyNode;
  if (props.note && props.smallView) {
    bodyNode = null;
    props.setTruncateState(true);
  } else if (props.note && toggle) {
    bodyNode = (
      <AbridgeText
        maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
        isExpandable={true}
        greyFade={props.greyFade}
      >
        {props.note}
      </AbridgeText>
    );
  } else {
    bodyNode = props.note;
  }

  return (
    <div className="workspace__entry-body">
      <div className="workspace__entry-image-row">
        {imageNode}
        <div className="workspace__entry-image-description" ref={inputEL}>
          <div className="v5__heading-5 workspace__entry-row">
            <NavigationLink
              url={`${process.env.REACT_APP_TEACHING_URL}/lesson/${props.lesson_id}`}
              className="v5__link"
              target="_blank"
            >
              {props.title}
            </NavigationLink>
            {courseNode}
          </div>
          <div className="workspace__entry-row v5__body-copy">
            {props.tagline}
          </div>
        </div>
      </div>
      <div className="workspace__entry-row v5__body-copy">{bodyNode}</div>
    </div>
  );
};

export default LessonEntry;

LessonEntry.propTypes = {
  course_name: PropTypes.string,
  unit_name: PropTypes.string,
  lessonfile: PropTypes.object,
  lesson_id: PropTypes.number,
  note: PropTypes.string,
  smallView: PropTypes.bool,
  setTruncateState: PropTypes.func,
  toggle: PropTypes.bool,
  greyFade: PropTypes.bool,
};
