// @ts-nocheck FIXME
import AbridgeText from '@legacy-components/react/V5/Common/AbridgeText';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import FutureEventRow from './FutureEventRow';
import PastMeetingRow from './PastMeetingRow';

const meetingTimes = (meeting, timeZone) => {
  const isEvent = !!meeting.session;
  const meetingTime =
    meeting.meeting_time_formatted ||
    meeting.session.session_start_time_formatted;
  const timeBefore = isEvent ? 10 : 5;
  const displayCutoff = moment.duration(timeBefore, 'minutes');

  const meetingStartTime = moment(meetingTime).tz(timeZone);
  const showNotification = meetingStartTime - displayCutoff;
  const meetingLength = moment.duration(meeting.meeting_length, 'minutes');
  const meetingEndTime = meetingStartTime + meetingLength;
  const meetingId = meeting.session ? meeting.session.id : meeting.id;

  return {
    id: meetingId,
    meetingStartTime: meetingStartTime,
    showNotification: showNotification,
    meetingLength: meetingLength,
    meetingEndTime: meetingEndTime,
  };
};

const MeetingsCapsule = (props) => {
  const userTimeZone = props.user.time_zone.pytz_timezone;
  const currentTime = moment().tz(userTimeZone);
  const meetingInfo = props.upcoming_meetings.map((meeting) =>
    meetingTimes(meeting, userTimeZone)
  );

  const [meetingStarted, setMeetingStarted] = useState(
    meetingInfo.reduce(
      (acc, info) =>
        info.showNotification - currentTime < 0 ? acc.concat(info.id) : acc,
      []
    )
  );
  const [meetingEnded, setMeetingEnded] = useState(
    meetingInfo.reduce(
      (acc, info) =>
        info.meetingEndTime - currentTime < 0 ? acc.concat(info.id) : acc,
      []
    )
  );

  useEffect(() => {
    let startMeeting;
    let endMeeting;

    meetingInfo.forEach((info) => {
      // If the meeting is over don't do anything
      if (
        info.meetingEndTime - currentTime < 0 ||
        info.showNotification - currentTime >= moment.duration(2147483647)
      ) {
        return;
      }

      if (info.showNotification - currentTime < 0) {
        if (!meetingStarted.includes(info.id)) {
          setMeetingStarted([...meetingStarted, info.id]);
        }

        endMeeting = setTimeout(() => {
          // Meeting has already started, set a timeout to fire when the meeting ends
          setMeetingEnded([...meetingEnded, info.id]);
        }, moment.duration(info.meetingStartTime - moment().tz(userTimeZone)).asMilliseconds() + info.meetingLength.asMilliseconds());
      } else if (info.showNotification - currentTime > 0) {
        // Meeting hasn't started yet, set meeting start and end timers
        startMeeting = setTimeout(() => {
          // Set a timeout to fire when the meeting starts
          if (!meetingStarted.includes(info.id)) {
            setMeetingStarted([...meetingStarted, info.id]);
          }
          endMeeting = setTimeout(() => {
            // Set a timeout to fire when the meeting ends
            setMeetingEnded([...meetingEnded, info.id]);
          }, moment.duration(info.meetingStartTime - moment().tz(userTimeZone)).asMilliseconds() + info.meetingLength.asMilliseconds());
        }, moment.duration(info.showNotification - currentTime).asMilliseconds());
      }
    });

    // Clear the timeouts on unmount
    return () => {
      clearTimeout(startMeeting);
      clearTimeout(endMeeting);
    };
  }, [meetingStarted, meetingEnded]);

  const upcoming_meetings = props.upcoming_meetings.filter((meeting) => {
    return !!meeting.session || !meetingEnded.includes(meeting.id);
  });
  let upcomingMeetingsNode = upcoming_meetings.map((meeting, i) => {
    return (
      <FutureEventRow
        meeting={meeting}
        participant={props.participant}
        coach={props.coach}
        userTimeZone={userTimeZone}
        meetingStarted={meetingStarted}
        key={`meeting-${i}`}
      />
    );
  });

  if (
    !upcomingMeetingsNode ||
    !upcomingMeetingsNode.length ||
    (upcomingMeetingsNode.length === 1 && upcomingMeetingsNode[0] === null)
  ) {
    upcomingMeetingsNode = (
      <div className="v5__body-copy home-v5__meetings-capsule-margin">
        <div className="v5__author-copy">No meetings or events scheduled.</div>
        <div className="v5__author-title-copy">Go enjoy your free time!</div>
      </div>
    );
  }

  let pastMeetingsNode = (
    <div className="v5__body-copy">
      <div className="v5__author-copy">Why linger in the past?</div>
      <div className="v5__author-title-copy">
        No meetings or events to see here.
      </div>
    </div>
  );

  if (props.past_meetings && props.past_meetings.length > 0) {
    pastMeetingsNode = props.past_meetings.map((meeting, i) => {
      return (
        <PastMeetingRow
          meeting={meeting}
          index={i}
          timezone={userTimeZone}
          participant_id={props.participant.id}
          key={`meeting-${meeting.id}-${i}`}
        />
      );
    });
  }

  const mq = window.matchMedia('(max-width: 48em)');
  const lineCount =
    mq.matches &&
    (props.upcoming_meetings.length > 1 || props.past_meetings.length > 0)
      ? 7
      : 12;

  return (
    <AbridgeText
      className="row home-v5__meetings"
      lineCount={lineCount}
      isExpandable={true}
    >
      <div className="home-v5__upcomming-meetings cute-6-desktop cute-12-phone home-v5__meetings-capsule-margin">
        <div className="home-v5__meetings-margin home-v5__meeting-headline v5__heading-6">
          Upcoming
        </div>
        <div>{upcomingMeetingsNode}</div>
      </div>
      <div className="home-v5__past-meetings cute-6-desktop cute-12-phone home-v5__meetings-capsule-margin">
        <div className="home-v5__meetings-margin home-v5__meeting-headline v5__heading-6">
          Past
        </div>
        <div>{pastMeetingsNode}</div>
      </div>
    </AbridgeText>
  );
};

export default MeetingsCapsule;
