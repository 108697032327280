// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../NavigationLink';

const Breadcrumb = (props) => {
  return props.trail.map((crumb, i) => {
    let divider = props.divider;
    if (i === 0) divider = '';

    return (
      <span key={`breadcrumb-${i}`} className="v5__breadcrumb">
        {divider}
        {crumb.link ? (
          <NavigationLink url={crumb.link}>{crumb.text}</NavigationLink>
        ) : (
          <span>{crumb.text}</span>
        )}
      </span>
    );
  });
};

Breadcrumb.propTypes = {
  divider: PropTypes.string.isRequired,
  trail: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
};

export default Breadcrumb;
