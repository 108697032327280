// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const CalendarRow = (props) => {
  const [updateMeeting, setUpdateMeeting] = React.useState('');

  if (updateMeeting) {
    // Use this value so the linter doesn't complain
  }

  // Set a default value
  let meetingIcon = null;
  const timezone = props.time_zone.pytz_timezone;
  // Get the current time
  const currentTime = moment().tz(timezone);
  // Set a default meeting start time
  const nextMeetingTime = moment(props.start.dateTime).tz(timezone);

  // Set time to display icon 5 minutes before the meeting
  const beforeMeetingTime = nextMeetingTime.subtract(5, 'minutes');
  // Set time to hide the meeting capsule after the meeting has ended
  const endMeetingTime = moment(props.end.dateTime);

  // Set an empty default class
  let meetingOver = '';

  if (
    currentTime.isAfter(endMeetingTime) ||
    ['Cancelled', 'No Show', 'Coach Cancelled'].indexOf(props.status) !== -1
  ) {
    // meeting is over, disable it
    meetingOver = ' meeting-over';
  } else if (currentTime.isSameOrAfter(beforeMeetingTime) && props.location) {
    // meeting is about to start, show the icon and set timer to remove icon when it ends
    meetingIcon = (
      <div>
        <NavigationLink
          className="admin-v5_coach-dashboard_no-underline"
          url={props.location}
        >
          <span className="tc-v5-video"></span> Start
        </NavigationLink>
      </div>
    );

    // set a timer to hide the icon when the meeting ends
    const timeUntilMeetingEnds = endMeetingTime - currentTime;
    setTimeout(() => {
      setUpdateMeeting('end');
    }, timeUntilMeetingEnds);
  } else {
    // the meeting hasn't started yet
    // set a timer to show the icon 5 minutes before start
    const timeUntilMeetingStarts = beforeMeetingTime - currentTime;
    setTimeout(() => {
      setUpdateMeeting('start');
    }, timeUntilMeetingStarts);
  }

  return (
    <div
      className={
        'admin-v5_coach-dashboard__calendar-row' +
        (props.synced ? ' synced' : '') +
        meetingOver
      }
    >
      <div className="admin-v5_coach-dashboard__calendar-row-date">
        {moment(props.start.dateTime).tz(timezone).format('h:mm A')}
      </div>
      <div className="admin-v5_coach-dashboard__calendar-row-details">
        {props.summary}
        <div>{meetingIcon}</div>
      </div>
    </div>
  );
};

CalendarRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  start: PropTypes.shape({
    dateTime: PropTypes.string.isRequired,
  }).isRequired,
  end: PropTypes.shape({
    dateTime: PropTypes.string.isRequired,
  }).isRequired,
  synced: PropTypes.bool.isRequired,
  location: PropTypes.string,
  time_zone: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    pytz_timezone: PropTypes.string,
  }),
  summary: PropTypes.string.isRequired,
};

export default CalendarRow;
