// @ts-nocheck FIXME
import FormField from '@legacy-components/react/V5/Common/FormField';
import PropTypes from 'prop-types';

const Roles = (props) => {
  // handle dropdown behavior
  const handleRoleChange = (e) => {
    const roleValue = e.target.value;
    if (props.setUnSavedChanges) {
      props.setUnSavedChanges(true);
    }
    props.setRole(roleValue);
  };

  return (
    <FormField
      id="occupation"
      type="select"
      data-test-id={props.dataTestId}
      onChange={handleRoleChange}
      defaultValue={props.role}
      disabled={props.disabled}
      className="settings__input--half"
      error={props.error}
    >
      <option disabled>Select</option>
      {props.occupations.map((occ) => {
        return (
          <option key={occ.id} value={occ.id}>
            {occ.name}
          </option>
        );
      })}
    </FormField>
  );
};

export default Roles;

Roles.propTypes = {
  role: PropTypes.number,
};
