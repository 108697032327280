// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import PropTypes from 'prop-types';
import React from 'react';
import GoalContainer from './GoalContainer';
import GoalEditView from './GoalEditView';

const Goals = (props) => {
  const goalsRef = React.useRef();

  if (props.scrollTo === 'workspace__goals') {
    goalsRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  // set up placeholder for list of goals if the participant has none
  let addGoalBtn = props.canEdit && !props.isEditing && (
    <button
      onClick={() => props.setNewGoalFormVisible(true)}
      className="v5__btn workspace__add-outcome-btn"
    >
      Add an outcome
    </button>
  );
  let goalsListNode = (
    <div className="workspace__goals">
      <h2 className="workspace__heading-2--green v5__heading-3">
        Add your first outcome
      </h2>
      <div className="v5__body-copy">
        Describe the outcomes and personalized goals of your coaching.
      </div>
      {addGoalBtn}
    </div>
  );
  if (ArrayHelpers.hasElements(props.goals) || props.newGoalFormVisible) {
    // collect list of outcome id (sub_focus_area_id) of existing goals to avoid duplicate
    const existingSubFocusAreas = props.goals.map(
      (goal) => goal.sub_focus_area_id
    );
    // by default, we only show up to 3 goals
    const goals = props.allGoalsVisible ? props.goals : props.goals.slice(0, 3);

    // set up list of goal capsules
    const goalNodes = goals.map((goal) => {
      let entriesCount = 0;

      props.entries.forEach((entry) => {
        if (
          (entry.type === 'self_assessment_progress' &&
            entry.outcome_id === goal.sub_focus_area_id) ||
          (entry.goalIds && entry.goalIds.indexOf(goal.id) >= 0)
        ) {
          if (!props.filterByHighlight || entry.highlighted) {
            entriesCount++;
          }
        }
      });
      return (
        <GoalContainer
          key={`goal-${goal.id}`}
          subFocusAreas={props.subFocusAreas}
          cohortFocusAreas={props.cohortFocusAreas}
          existingSubFocusAreas={existingSubFocusAreas}
          goal={goal}
          submitGoal={props.submitGoal}
          deleteGoal={props.deleteGoal}
          completeGoal={() =>
            props.openModal('goalComplete', {
              goal: goal,
              pageUser: props.pageUser,
            })
          }
          confirmUncompleteGoal={() =>
            props.openModal('goalUncomplete', { goalId: goal.id })
          }
          entriesCount={entriesCount}
          changeFilter={props.changeGoalFilter}
          isFiltering={props.goalFilter && goal.id === props.goalFilter.id}
          canEdit={props.canEdit}
          isEditing={props.isEditing}
          setIsEditing={props.setIsEditing}
          canHighlight={props.canHighlight}
          pageUser={props.pageUser}
          loading={props.loading}
          domains={props.domains}
        />
      );
    });

    // set up form to add a new goal, if necessary
    let newGoalNode;
    if (props.newGoalFormVisible) {
      // pass the data from the goal form to the container
      const addGoal = (data, errorCallback) =>
        props.submitGoal(data, null, errorCallback);
      newGoalNode = (
        <GoalEditView
          subFocusAreas={props.subFocusAreas}
          cohortFocusAreas={props.cohortFocusAreas}
          existingSubFocusAreas={existingSubFocusAreas}
          submitGoal={addGoal}
          cancelGoalEditing={() => props.setNewGoalFormVisible(false)}
          canEdit={true}
          domains={props.domains}
        />
      );
    }

    // only show the Add Goal button if the current user can make edits
    addGoalBtn = props.canEdit && !props.isEditing && (
      <button
        className="v5__btn"
        onClick={() => props.setNewGoalFormVisible(true)}
      >
        Add an outcome
      </button>
    );

    // set up toggle to show/hide goals past three
    let seeAllGoalsNode;
    if (props.goals && props.goals.length > 3) {
      seeAllGoalsNode = props.allGoalsVisible ? (
        <div
          className="v5__toggle-copy right"
          onClick={() => props.setAllGoalsVisible(false)}
        >
          Show less
        </div>
      ) : (
        <div
          className="v5__toggle-copy right"
          onClick={() => props.setAllGoalsVisible(true)}
        >
          Show all
        </div>
      );
    }

    goalsListNode = (
      <div id="workspace__goals" className="workspace__goals">
        {goalNodes}
        {newGoalNode}
        {!props.newGoalFormVisible && (
          <div>
            {addGoalBtn}
            {seeAllGoalsNode}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="row row--responsive workspace__goals-section">
      <div className="cute-2-desktop cute-12-phone workspace__goals-img-col">
        <img
          src="/legacy-images/v5/workspace-milestones-badge.png"
          className="workspace__goals-img"
        />
      </div>
      <div className="cute-10-desktop cute-12-phone">
        <h1 className="workspace__heading-1 v5__heading-3">My Outcomes</h1>
        <div className="workspace__goals" ref={goalsRef}>
          {goalsListNode}
        </div>
      </div>
    </div>
  );
};

Goals.propTypes = {
  allGoalsVisible: PropTypes.bool,
  canEdit: PropTypes.bool.isRequired,
  canHighlight: PropTypes.bool.isRequired,
  changeGoalFilter: PropTypes.func.isRequired,
  completeGoal: PropTypes.func.isRequired,
  deleteGoal: PropTypes.func.isRequired,
  entries: PropTypes.array.isRequired,
  filterByHighlight: PropTypes.bool,
  goalFilter: PropTypes.object,
  goals: PropTypes.array.isRequired,
  isEditing: PropTypes.bool.isRequired,
  newGoalFormVisible: PropTypes.bool.isRequired,
  openModal: PropTypes.func,
  pageUser: PropTypes.object.isRequired,
  setAllGoalsVisible: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  setNewGoalFormVisible: PropTypes.func.isRequired,
  submitGoal: PropTypes.func.isRequired,
};

export default Goals;
