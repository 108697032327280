// @ts-nocheck FIXME
const Challenge = (props) => {
  // if ther are no completed strategies do not render the challenge
  if (props.challenge.tmlAttempts.length == 0) {
    return <nosript />;
  }

  const processedStrategies = [];
  let measurementTotal = 0;
  let attemptCount = props.challenge.tmlAttempts.length;

  const strategyNodes = props.challenge.tmlAttempts.map((attempt) => {
    if (attempt.step == 'pending' || attempt.step == 'teach') {
      // if the tml attempt is pending or has not got past the teach step do not count it
      attemptCount--;
      return null;
    }
    const strategyId = attempt.strategy_id;

    // the measurement is quantitative or qualitative
    const measurement = props.challenge.is_instructional_design
      ? attempt.qualitative_percentage
      : attempt.measurement;

    // add measurments together for challenge total
    measurementTotal += measurement;

    // if this strategy is already in the array of ids return nothing to avoid duplicates
    if (processedStrategies.indexOf(strategyId) > -1) {
      return null;
    }

    // count the number of attempts of a specific strategy
    let strategyAttemptCount = 0;
    for (let i = 0; i < props.challenge.tmlAttempts.length; i++) {
      if (props.challenge.tmlAttempts[i]['strategy_id'] != strategyId) {
        continue;
      }
      strategyAttemptCount++;
    }

    // add the strategy id to the processed strategies
    processedStrategies.push(strategyId);

    // render the strategy attempt count if necessary
    const attemptCountNode =
      strategyAttemptCount > 1 ? '(' + strategyAttemptCount + ')' : '';

    return (
      <div className="portfolio__growth-map-strategy" key={attempt.id}>
        {attempt.title + ' ' + attemptCountNode}
      </div>
    );
  });

  let studentsImpactedNode =
    measurementTotal > 0 ? (
      <div className="portfolio__growth-map-tc-declaration portfolio__growth-map-tc-declaration--students-impacted">
        Students Impacted: {measurementTotal}
      </div>
    ) : null;

  if (measurementTotal && props.challenge.is_instructional_design) {
    measurementTotal =
      measurementTotal / props.challenge.tmlAttempts.length / 10;
    studentsImpactedNode = (
      <div className="portfolio__growth-map-tc-declaration portfolio__growth-map-tc-declaration--students-impacted">
        Teacher Success: {measurementTotal} / 10
      </div>
    );
  }

  // dont render this component if there are no strategies
  if (processedStrategies.length == 0) {
    return <noscript />;
  }

  return (
    <div
      className="portfolio__growth-map-challenge"
      key={'challenge-' + props.challenge.name}
    >
      <div className="portfolio__growth-map-tc">
        <div className="portfolio__growth-map-tc-declaration">
          Teaching Challenge
        </div>
        <div className="portfolio__growth-map-tc-name">
          {props.challenge.name}
        </div>
      </div>
      <div className="portfolio__growth-map-attempts">
        <div className="portfolio__growth-map-tc-declaration">
          Strategies Tried: {attemptCount}
        </div>
        {strategyNodes}
        {studentsImpactedNode}
      </div>
    </div>
  );
};

export default Challenge;
