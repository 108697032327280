// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const SessionDateTime = (props) => {
  let sessionTimeMissing = 'Date and time to be announced';
  let sessionTime;
  if (props.sessionDate) {
    sessionTimeMissing = 'Time to be announced';
    sessionTime = `${moment(props.sessionDate).format('MMMM DD, YYYY')}`;
    if (props.sessionStartTime && props.sessionEndTime) {
      sessionTimeMissing = '';
      const time = Utils.formatEventSessionTime(
        props.sessionStartTime,
        props.sessionEndTime,
        props.timezone
      );
      sessionTime = `${sessionTime}, ${time}`;
    }
  }
  const sessionTimeMissingNode = sessionTimeMissing ? (
    <div className="v5__body-copy grey-out italic">{sessionTimeMissing}</div>
  ) : null;
  const sessionNameNode = !props.singleSession && (
    <div className="v5__comment-copy">{props.name}</div>
  );
  return (
    <div className="reservation__session-element">
      {sessionNameNode}
      <div className="v5__heading-4">{sessionTime}</div>
      {sessionTimeMissingNode}
    </div>
  );
};

SessionDateTime.propTypes = {
  sessionDate: PropTypes.string,
  sessionStartTime: PropTypes.string,
  sessionEndTime: PropTypes.string,
  timezone: PropTypes.string.isRequired,
};

export default SessionDateTime;
