// @ts-nocheck FIXME
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import PropTypes from 'prop-types';

const DeleteSessionConfirmationModal = (props) => {
  let textNode;
  if (props.hasEventMaterials) {
    textNode = (
      <div className="v5__body-copy edit-event__modal-row">
        There are facilitator assignments and event materials links attached to
        this session that will be lost. This action cannot be undone.
      </div>
    );
  }
  return (
    <ActionModal
      headlineText="Change Session Status?"
      close={props.close}
      spaceFromTop={50}
    >
      <div className="v5__body-copy edit-event__modal-row">
        Once this status applied, it is permanent. This action cannot be undone.
      </div>
      {textNode}

      <div className="edit-event__modal-btn-row">
        <button
          className="v5__btn edit-event__confirm-status-btn"
          onClick={props.confirm}
        >
          Change Status
        </button>
        <button className="v5__btn v5__btn--red" onClick={props.close}>
          Cancel
        </button>
      </div>
    </ActionModal>
  );
};

DeleteSessionConfirmationModal.propTypes = {
  hasEventMaterials: PropTypes.bool,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default DeleteSessionConfirmationModal;
