// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import React from 'react';
import StorageHelper from './../../../components/StorageHelper';
import HeaderStore from './../../../stores/V4/HeaderStore';
import HeaderModule from './modules/Header';

class Header extends React.Component {
  static contextType = LegacyLabContext;

  constructor(props) {
    super(props);

    this.token = null;
    HeaderStore.actionDispatchSync('init', {
      user: this.props.user,
      headerParticipant: this.props.header_participant,
      handleTeacherViewSubmit: this.props.handleTeacherViewSubmit,
      keepHeaderParticipant: this.props.keepHeaderParticipant ? true : false,
      gatekeeper: this.props.gatekeeper,
    });

    this.state = HeaderStore.getState();
    this.onStoreChange = this.onStoreChange.bind(this);
    this.checkFreeContentLimit = this.checkFreeContentLimit.bind(this);
  }

  onStoreChange() {
    this.setState(HeaderStore.getState());
  }

  componentDidMount() {
    this.token = HeaderStore.subscribe(this.onStoreChange);
    HeaderStore.actionDispatch('browserInit', {
      headerParticipant: this.props.header_participant,
      keepHeaderParticipant: this.props.keepHeaderParticipant ? true : false,
      showConfirmedSuccess: this.props.showConfirmedSuccess,
    });

    this.checkFreeContentLimit();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.ui.showRegisterModal || prevState.ui.showRegisterModal) {
      return;
    }

    this.checkFreeContentLimit();
  }
  componentWillUnmount() {
    HeaderStore.unsubscribe(this.token);
  }

  checkFreeContentLimit() {
    // this only applies to logged out users
    if (this.props.user && this.props.user.id) {
      return;
    }

    // this only applies to the lesson and strategy pages
    if (this.props.page !== 'lesson' && this.props.page !== 'Strategy') {
      return;
    }

    // get the list of lessons and strategies viewed
    const lessons = StorageHelper.getCookieItem('bl_lessons_viewed') || [];
    const strategies =
      StorageHelper.getCookieItem('bl_strategies_viewed') || [];

    // get the current page's lesson or strategy id
    const lessonId = this.props.bl_lesson_id
      ? parseInt(this.props.bl_lesson_id)
      : null;
    const strategyId = this.props.bl_strategy_id
      ? parseInt(this.props.bl_strategy_id)
      : null;
    // some strategies are meant to be completely open, so for those we set strategyId to be -1
    // so we can skip the logic of counting them against the user's views.
    if (strategyId === -1) {
      return;
    }

    // if we are over the 5 free combined lessons and strategies, show the registration modal
    if (
      ((this.props.page === 'lesson' && lessons.indexOf(lessonId) === -1) ||
        (this.props.page === 'Strategy' &&
          strategies.indexOf(strategyId) === -1)) &&
      lessons.length + strategies.length >=
        this.context.pageData.strategy_and_lesson_view_limit
    ) {
      // show the registration modal
      if (this.props.user && !this.props.user.id) {
        HeaderStore.actionDispatch('openRegisterModal', {
          reason: 'resource_count_reached',
        });
      }
    }
    // if not, then track the current content
    else if (lessonId && lessons.indexOf(lessonId) === -1) {
      lessons.push(lessonId);
      StorageHelper.setCookieItem('bl_lessons_viewed', lessons, {
        maxAge: StorageHelper.MAX_COOKIE_AGE,
      });
    } else if (strategyId && strategies.indexOf(strategyId) === -1) {
      strategies.push(strategyId);
      StorageHelper.setCookieItem('bl_strategies_viewed', strategies, {
        maxAge: StorageHelper.MAX_COOKIE_AGE,
      });
    }
  }

  // dropdown widget methods
  updateDropdownOverflow(needsOverflow) {
    HeaderStore.actionDispatchSync('updateDropdownOverflow', needsOverflow);
  }
  openDropdown() {
    HeaderStore.actionDispatch('openDropdown');
  }
  closeDropdown() {
    HeaderStore.actionDispatch('closeDropdown');
  }
  openMobileSearch() {
    HeaderStore.actionDispatch('openMobileSearch');
  }
  closeMobileSearch() {
    HeaderStore.actionDispatch('closeMobileSearch');
  }
  searchAutocomplete(text) {
    HeaderStore.actionDispatch('searchAutocomplete', text);
  }
  openMarketingDropdown() {
    HeaderStore.actionDispatch('openMarketingDropdown');
  }
  closeMarketingDropdown() {
    HeaderStore.actionDispatch('openMarketingDropdown');
  }
  // coach view widget methods
  submitTeacherViewModal(checked) {
    HeaderStore.actionDispatch('submitTeacherViewModal', checked);
  }
  openTeacherViewModal() {
    HeaderStore.actionDispatch('openTeacherViewModal');
  }
  closeTeacherViewModal() {
    HeaderStore.actionDispatch('closeTeacherViewModal');
  }
  openRegisterModal() {
    HeaderStore.actionDispatch('openRegisterModal');
  }
  closeRegisterModal() {
    HeaderStore.actionDispatch('closeRegisterModal');
  }
  openLoginModal(origin) {
    HeaderStore.actionDispatch('openLoginModal', origin);
  }
  closeLoginModal() {
    HeaderStore.actionDispatch('closeLoginModal');
  }

  gatekeeperClearAlert() {
    HeaderStore.actionDispatch('gatekeeperClearAlert');
  }
  register(data) {
    HeaderStore.actionDispatch('register', data);
  }
  login(data) {
    HeaderStore.actionDispatch('login', data);
  }
  requestNewPassword(data) {
    HeaderStore.actionDispatch('requestNewPassword', data);
  }
  resetPassword(data) {
    HeaderStore.actionDispatch('resetPassword', data);
  }
  recaptchaSuccessCallback(data) {
    HeaderStore.actionDispatch('recaptchaSuccessCallback', data);
  }
  setmodalDisplay(data) {
    HeaderStore.actionDispatch('setmodalDisplay', data);
  }
  requestNewPasswordSuccess() {
    HeaderStore.actionDispatch('requestNewPasswordSuccess');
  }

  render() {
    return (
      <HeaderModule
        {...this.props}
        {...this.state}
        submitTeacherViewModal={this.submitTeacherViewModal}
        openTeacherViewModal={this.openTeacherViewModal}
        closeTeacherViewModal={this.closeTeacherViewModal}
        openRegisterModal={this.openRegisterModal}
        closeRegisterModal={this.closeRegisterModal}
        openLoginModal={this.openLoginModal}
        closeLoginModal={this.closeLoginModal}
        updateDropdownOverflow={this.updateDropdownOverflow}
        openDropdown={this.openDropdown}
        closeDropdown={this.closeDropdown}
        openMarketingDropdown={this.openMarketingDropdown}
        closeMarketingDropdown={this.closeMarketingDropdown}
        openMobileSearch={this.openMobileSearch}
        closeMobileSearch={this.closeMobileSearch}
        searchAutocomplete={this.searchAutocomplete}
        gatekeeperClearAlert={this.gatekeeperClearAlert}
        register={this.register}
        login={this.login}
        requestNewPassword={this.requestNewPassword}
        resetPassword={this.resetPassword}
        recaptchaSuccessCallback={this.recaptchaSuccessCallback}
        setmodalDisplay={this.setmodalDisplay}
        requestNewPasswordSuccess={this.requestNewPasswordSuccess}
      />
    );
  }
}
const ConnectedHeader = (props) => (
  <HeaderConsumer>
    {({ alert }) => <Header {...props} alert={alert} />}
  </HeaderConsumer>
);

export default ConnectedHeader;
