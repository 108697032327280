// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import StatusIcon from '@legacy-components/react/V5/Common/StatusIcon';
import EntryContainer from '@legacy-components/react/V5/Highlights/modules/EntryContainer';
import HighlightedRow from '@legacy-components/react/V5/Highlights/modules/HighlightedRow';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const ActivityRow = (props) => {
  const lab = useLegacyLabContext();

  let photoNode = null;
  if (props.photo) {
    // Set up user photo instead of the default icon if set
    photoNode = <UserPhoto src={props.photo} diameter={40} hasBorder={false} />;
  } else {
    // Use the participant's name and user ID so that two users with the same name don't use the same color
    // Then convert each letter to a char code, sum and take the modulus so the same user is always the same color
    const propsName = props.first_name + ' ' + props.last_name + props.user_id;
    const index = propsName
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc);

    const colors = [
      '#004493',
      '#1A7CD9',
      '##2196F3',
      '#0B8484',
      '#0AABB1',
      '#53E6EB',
      '#53E6EB',
      '#107D54',
      '#33B55F',
      '#2ACAA1',
      '#25E8C8',
      '#FF6B2A',
      '#F4AB2A',
    ];

    const iconStyles = {
      backgroundColor: colors[index % colors.length],
    };

    photoNode = (
      <div style={iconStyles} className="admin-v5_coach-dashboard_default-icon">
        <span>
          {props.first_name.substr(0, 1)}
          {props.last_name.substr(0, 1)}
        </span>
      </div>
    );
  }

  const schoolNameNode = props.school_name || (
    <div className="v5__quote-copy">No school selected</div>
  );

  let activeDomainsNode = null;
  if (props.active_domains && props.active_domains.length) {
    activeDomainsNode = props.active_domains.map((domain) => {
      if (domain.publish_status_id === 1) {
        return <li key={`highlight-${domain.id}`}>{domain.title}</li>;
      }

      return (
        <li key={`highlight-${domain.id}`}>
          <NavigationLink
            className="tc__link"
            url={`${process.env.REACT_APP_TEACHING_URL}/browse/learning-domain/${domain.id}`}
          >
            {domain.title}
          </NavigationLink>
        </li>
      );
    });
  } else {
    activeDomainsNode = (
      <div className="v5__quote-copy">No active learning domain</div>
    );
  }

  let recentHighlightNode = null;
  let highlightBody = null;
  let arrowBody = null;

  if (props.recent_highlight) {
    recentHighlightNode = (
      <div className="v5__activity-table--recent-highlight">
        <span className="workspace__action-icon tc-v5-star-solid filled"></span>
        {moment(props.recent_highlight.highlighted).from(
          moment().utcOffset(0, true)
        )}
      </div>
    );
    highlightBody = props.openRow ? (
      <div className="lead-activity__highlight-entry">
        <div className="v5__body-copy partner_lead__highlight-row-highlight">
          <HighlightedRow
            reflection={props.recent_highlight.highlight_reflection}
            author={props.recent_highlight.highlight_author}
            title={props.recent_highlight.title}
            contentId={props.recent_highlight.id}
            teacherTag={false}
            reportingPhase={
              props.reportingPhases[props.recent_highlight.reporting_phase]
            }
            greyFade={true}
            showInPLDash={true}
          >
            <EntryContainer
              {...props.recent_highlight}
              authorHide={true}
              greyFade={true}
              showInPLDash={true}
            />
          </HighlightedRow>
        </div>
        <div className="partner_lead_v5__table-thin-spacer"></div>
      </div>
    ) : null;

    const arrowExpanded = props.openRow
      ? 'workspace__goal-expand-arrow--expanded'
      : '';
    arrowBody = Utils.showPartnerLeaderDetails.call(lab, {
      id: props.user_id,
      auth_roles: props.auth_roles || [],
    }) ? (
      <div
        className={`tc-v4-circle-arrow workspace__goal-expand-arrow partner_lead__expand-arrow ${arrowExpanded}`}
        onClick={() => {
          props.updateShow(props.id);
        }}
      ></div>
    ) : (
      <></>
    );
  } else {
    recentHighlightNode = (
      <div className="v5__quote-copy">No recent highlights</div>
    );
  }
  const recentHighlights = (
    <div className="cute-2-desktop v5__activity-table-column">
      {recentHighlightNode}
      {arrowBody}
    </div>
  );
  const clipClass = 'cute-2-desktop';
  // if (props.currentYear === 'past') {
  //     clipClass = 'cute-4-desktop';
  //     recentHighlights = null;
  // }

  return (
    <div className="row v5__registered-table-copy v5__activity-table-row v5__activity-table-row-highlights">
      <div className="v5__activity-table-columns">
        <div className="cute-2-desktop v5__activity-table-column">
          <div>{photoNode}</div>
          <div className="v5__registered-table-name">
            <NavigationLink
              className="tc__link"
              url={`/lab/highlights/${props.user_id}?scrollToOutcomes=true`}
            >
              {props.first_name} {props.last_name}
            </NavigationLink>
          </div>
          <StatusIcon status={props.status} />
        </div>

        <div className={`${clipClass} v5__activity-table-column`}>
          <div>{schoolNameNode}</div>
        </div>

        <div className="cute-3-desktop v5__activity-table-column">
          <ul>{activeDomainsNode}</ul>
        </div>

        <div className="cute-1-desktop v5__activity-table-column">
          <NavigationLink
            className="tc__link"
            url={`/lab/highlights/${props.user_id}?scrollToResources=true`}
          >
            {props.strategies.length}
          </NavigationLink>
        </div>

        <div className="cute-1-desktop v5__activity-table-column">
          <div>{props.highlight_count}</div>
        </div>

        <div className={'cute-1-desktop v5__activity-table-column'}>
          <div>{props.clips}</div>
        </div>
        {recentHighlights}
      </div>
      {highlightBody}
    </div>
  );
};

ActivityRow.propTypes = {
  user_id: PropTypes.number.isRequired,
  auth_roles: PropTypes.array,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  strategies: PropTypes.array.isRequired,
  clips: PropTypes.number.isRequired,
  recent_highlight: PropTypes.object,
  school: PropTypes.string,
};

export default ActivityRow;
