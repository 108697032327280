// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import StoryApi from './../../../../apis/V4/StoryApi';
import { Router } from './../../../../components/Router';
import Spinner from './../../Common/Spinner';

const ResourceCapsule = (props) => {
  // show the spinner while doing the API call
  const [isLoading, setIsLoading] = useState(false);

  // clicking the Select Strategy button adds the resource to the user's workspace
  // and then routes to the workspace with the new entry in edit mode
  const handleAddResource = () => {
    setIsLoading(true);

    if (props.type === 'lesson') {
      StoryApi.addLessonEntry({
        storyId: props.storyId,
        lessonId: props.id,
      })
        .then((response) => {
          Router.route(
            `/lab/workspace/${props.userId}?editEntry=${response.entryId}`
          );
        })
        .catch(() => {
          setIsLoading(false);
          props.setErrorAlert();
        });
    } else {
      StoryApi.addCuratedStrategyEntry({
        storyId: props.storyId,
        strategyId: props.id,
      })
        .then((response) => {
          Router.route(
            `/lab/workspace/${props.userId}?editEntry=${response.entryId}`
          );
        })
        .catch(() => {
          setIsLoading(false);
          props.setErrorAlert();
        });
    }
  };

  // if the resource has a preview image, show that as the capsule background
  const capsuleProps = props.previewPhoto
    ? { style: { backgroundImage: `url("${props.previewPhoto}")` } }
    : null;

  // if this is a lesson, display any related course
  let courseNode;
  if (props.course_name) {
    courseNode = (
      <div className="v5__heading-6 select-resource__capsule-lesson-course">
        {props.course_name}
      </div>
    );
  }

  // show the "Recommended" label if this was recommended by a coach
  const recommendedNode =
    props.status === 'recommended' ? (
      <div className="v5__heading-6 select-resource__capsule-recommended-text">
        Recommended
      </div>
    ) : null;

  // if this is the selected capsule, show the overlay
  let overlayNode;
  if (props.isSelected) {
    // set up the contents of the capsule overlay
    let resourceLink = `${process.env.REACT_APP_TEACHING_URL}/strategy/${props.id}`;
    if (props.type === 'lesson') {
      resourceLink = `${process.env.REACT_APP_TEACHING_URL}/lesson/${props.id}`;
    }
    if (props.seo_url) {
      resourceLink += `/${props.seo_url}`;
    }

    // append from tag for strategies
    if (props.type === 'strategy') {
      let resourceStrategyFrom = '?from=select_resource_favorites';
      if (props.packTitle) {
        // lower case, replace spaces with underscrores, encode special characters
        const packTitleFormatted = encodeURIComponent(
          props.packTitle.replace(/ /g, '_').toLowerCase()
        );
        resourceStrategyFrom = `?from=select_resource_${packTitleFormatted}`;
      }
      resourceLink += resourceStrategyFrom;
    }

    // disable the Save button after submit
    const btnProps = isLoading ? { disabled: true } : null;

    overlayNode = (
      <div className="select-resource__capsule-overlay">
        <button
          type="button"
          {...btnProps}
          className="v5__btn v5__btn--blue-border"
          onClick={handleAddResource}
        >
          <Spinner loading={isLoading} />
          Select
        </button>
        <div className="select-resource__capsule-overlay-link">
          <a
            href={resourceLink}
            target="_blank"
            className="v5__link v5__link--white"
            rel="noreferrer"
          >
            Read More
            <span className="tc-v5-arrow-circle-right select-resource__capsule-overlay-icon"></span>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="select-resource__capsule-container">
      <div
        className="v5__capsule select-resource__capsule"
        onClick={props.select}
        {...capsuleProps}
      >
        <div className="v5__heading-4 select-resource__capsule-text">
          {props.title}
          {courseNode}
          {recommendedNode}
        </div>

        {overlayNode}
      </div>
    </div>
  );
};

ResourceCapsule.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  previewPhoto: PropTypes.string,
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  course: PropTypes.string,
  seo_url: PropTypes.string,
  status: PropTypes.string,
  isSelected: PropTypes.bool,
  select: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  storyId: PropTypes.number.isRequired,
  setErrorAlert: PropTypes.func.isRequired,
};

export default ResourceCapsule;
