// @ts-nocheck FIXME
import ParticipantDetailsApi from './../../apis/V5/ParticipantDetailsApi';
import Store from './../../components/Store';

const store = {
  data: {},
  participants: {},
  ui: {
    alert: [],
  },
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.zoomLink = data.participant.zoom_link || '';
  store.user = data.user;
  store.cohorts = data.participant.events_cohorts.filter(
    (x) => x.type === 'cohort'
  );

  store.selectedCohortIndex = store.cohorts.length ? 0 : null;

  // Clear any alerts
  store.ui.alert = {};
  store.participant = data.participant;

  // if we came here after deleting a meeting, show any Zoom-related messages for that meeting
  if (data.zoom_status === 'delete_failed') {
    store.ui.alert = {
      type: 'warning',
      message:
        'We’re having trouble communicating with Zoom. Your meeting was not deleted in Zoom.',
      handleClose: store.clearAlert,
    };
  }

  // don't trigger event
  return false;
};

store.setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

store.setSuccessAlert = (message) => {
  store.ui.alert = {
    type: 'success',
    message: message,
    handleClose: store.clearAlert,
  };
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.setSelectedCohortByCohortId = (cohortId = null) => {
  if (!cohortId) store.selectedCohortIndex = null;
  const cohortIndex = store.cohorts.findIndex(
    (cohort) => cohort.cohort_id === cohortId
  );
  store.selectedCohortIndex = cohortIndex >= 0 ? cohortIndex : null;
};

store.cancelMeetingSuccessCallback = (data) => {
  const meeting = store.cohorts[store.selectedCohortIndex].meetings.find(
    (meeting) => meeting.id === data.meetingId
  );
  meeting.status = data.meeting_status;
  meeting.internal_notes = data.internal_notes;
  meeting.when_cancelled_formatted = data.when_cancelled_formatted;
  data.successCallback();
};

store.cancelMeetingFailureCallback = (data) => {
  store.setErrorAlert('Error updating meeting status, please try again later');
  data.failureCallback();
};

store.setMeetingAsCancelled = (data) => {
  return ParticipantDetailsApi.updateMeetingStatus(data).then(
    (response) => {
      store.cancelMeetingSuccessCallback(Object.assign(data, response));
    },
    () => {
      store.cancelMeetingFailureCallback(data);
    }
  );
};

store.setMeetingAsNoShow = (data) => {
  return ParticipantDetailsApi.updateMeetingStatus(data).then(
    (response) => {
      const meeting = store.cohorts[store.selectedCohortIndex].meetings.find(
        (meeting) => meeting.id === data.meetingId
      );
      meeting.status = response.meeting_status;
      data.successCallback();
    },
    () => {
      store.setErrorAlert(
        'Error updating meeting status, please try again later'
      );
      data.failureCallback();
    }
  );
};

store.updateMeetingStatus = (data) => {
  return ParticipantDetailsApi.updateMeetingStatus(data).then(
    (response) => {
      store.updateMeetingStatusSuccess(Object.assign(data, response));
    },
    () => {
      store.setErrorAlert(
        'Error updating meeting status, please try again later'
      );
    }
  );
};

store.updateMeetingStatusSuccess = (data) => {
  const meeting = store.cohorts[store.selectedCohortIndex].meetings.find(
    (meeting) => meeting.id === data.meetingId
  );
  meeting.status = data.meeting_status;
  if (data.note) {
    data.note.author_name = `${store.user.first_name} ${store.user.last_name}`;
    store.cohorts[store.selectedCohortIndex].participant_notes.push(data.note);
    store.cohorts[store.selectedCohortIndex].current_status = 'Active';
  }
};

store.addNoteSuccess = (data) => {
  // show success
  data.note.author_name = `${store.user.first_name} ${store.user.last_name}`;
  store.cohorts[store.selectedCohortIndex].participant_notes.push(data.note);
  store.cohorts[store.selectedCohortIndex].current_status =
    data.note.status || store.cohorts[store.selectedCohortIndex].current_status;
  data.successCallback();
};

store.editNoteSuccess = (data) => {
  // show success
  const noteIndex = store.cohorts[
    store.selectedCohortIndex
  ].participant_notes.findIndex((note) => note.id === data.noteId);
  store.cohorts[store.selectedCohortIndex].participant_notes[noteIndex].note =
    data.note.note;
  data.successCallback();
};

store.addNote = (data) => {
  data.participantId = store.participant.id;
  return ParticipantDetailsApi.addNote(data)
    .then((response) => {
      store.addNoteSuccess(Object.assign(data, response));
    })
    .catch(() => {
      store.setErrorAlert('Something went wrong when adding the note.');
      data.failureCallback();
    });
};

store.editNote = (data) => {
  data.participantId = store.participant.id;
  return ParticipantDetailsApi.editNote(data)
    .then((response) => {
      store.editNoteSuccess(Object.assign(data, response));
    })
    .catch((response) => {
      store.setErrorAlert(response.error);
      data.failureCallback();
    });
};

export default Store('ParticipantDetailsStore', store);
