// @ts-nocheck FIXME
import NavigationLink from './../../../NavigationLink';

const MegaMenuBar = (props) => {
  const handleChangeSubjectMobile = (e) => {
    props.openMenu({ subject: e.target.value });
  };

  let mathActiveClass = !props.activeSubject ? ' active' : '';
  let elaActiveClass = '';
  let scienceActiveClass = '';
  let blendedActiveClass = '';

  const mobileSubjectOptions = (
    <div className="standards__mobile-subject-options">
      <div className="tc-browser-arrow"></div>
      <select
        value={props.activeSubject ? props.activeSubject : 'math'}
        onChange={(e) => {
          handleChangeSubjectMobile(e);
        }}
      >
        <option value="math">Math</option>
        <option value="ela">English Language Arts</option>
        <option value="science">Science</option>
        <option value="blended">Blended</option>
      </select>
    </div>
  );

  switch (props.activeSubject) {
    case 'math':
      mathActiveClass = ' active';
      break;
    case 'ela':
      elaActiveClass = ' active';
      break;
    case 'science':
      scienceActiveClass = ' active';
      break;
    // @TODO: CA We need blended strategies in the static data
    case 'blended':
      blendedActiveClass = ' active';
      break;
  }

  const containerActiveStandardClass = props.activeStandard
    ? ' standards__menu-bar-container--active-standard'
    : '';

  return (
    <div
      className={'standards__menu-bar-container' + containerActiveStandardClass}
    >
      <div className="row row--1200">
        {mobileSubjectOptions}
        <div
          className={
            'cute-3-desktop cute-12-phone standards__menu-bar-headline-container' +
            mathActiveClass
          }
        >
          <NavigationLink
            className={'standards__menu-bar-headline' + mathActiveClass}
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/common_core_math?from=breadcrumb_content_area'
            }
          >
            Math
          </NavigationLink>
        </div>
        <div
          className={
            'cute-3-desktop cute-12-phone standards__menu-bar-headline-container' +
            elaActiveClass
          }
        >
          <NavigationLink
            className={'standards__menu-bar-headline' + elaActiveClass}
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/common_core_ela?from=breadcrumb_content_area'
            }
          >
            English Language Arts
          </NavigationLink>
        </div>
        <div
          className={
            'cute-3-desktop cute-12-phone standards__menu-bar-headline-container' +
            scienceActiveClass
          }
        >
          <NavigationLink
            className={'standards__menu-bar-headline' + scienceActiveClass}
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/next_gen_science?from=breadcrumb_content_area'
            }
          >
            Science
          </NavigationLink>
        </div>
        <div
          className={
            'cute-3-desktop cute-12-phone standards__menu-bar-headline-container' +
            blendedActiveClass
          }
        >
          <NavigationLink
            className={'standards__menu-bar-headline' + blendedActiveClass}
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/blended?from=breadcrumb_content_area'
            }
          >
            Blended Learning
          </NavigationLink>
        </div>
      </div>
    </div>
  );
};

export default MegaMenuBar;
