// @ts-nocheck FIXME
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import moment from 'moment-timezone';
import propTypes from 'prop-types';

const CapacitySessionDetails = (props) => {
  // sessions to be displayed
  const sessionsNode = [];

  // keep track of today's date, so we know when there's a 2-day session
  const displayDate = moment.tz(props.showEventDetail.date, props.timeZone);

  /**
   * takes in datetime string, returns a tooltip indicating a 2-day session
   * @param {string} startTime a string of session starting time in format like "2021-04-15T11:30:00-0400"
   * @param {string} endTime a string of session ending time in format like "2021-04-15T11:30:00-0400"
   * @param {object} day object of a day to be compared with datetime, default today
   * @param {string} timezone timezone string used by moment-timezone
   * @returns {ReactElement | null} tooltip depending on if the session is on the 1st day or 2nd day
   */
  const generateTooltip = (
    startTime,
    endTime,
    day = displayDate,
    timezone = props.timeZone
  ) => {
    const startTimeMoment = moment.tz(startTime, timezone);
    const endTimeMoment = moment.tz(endTime, timezone);
    let secondDayText = '';
    if (
      endTimeMoment.isAfter(day, 'date') &&
      (endTimeMoment.minute() !== 0 || endTimeMoment.hour() !== 0)
    ) {
      secondDayText = 'Continues into next day';
    } else if (startTimeMoment.isBefore(day, 'date')) {
      secondDayText = 'Continued from previous day';
    }
    return secondDayText ? (
      <Tooltip
        tooltip={secondDayText}
        delay={1}
        offsetLeft={55}
        minWidth={120}
        tipClass="events-cal__tooltip"
      >
        <div className="tc-icon_time-zone events-cal__time-zone-icon"></div>
      </Tooltip>
    ) : null;
  };

  for (let i = 0; i < props.sessions.length; i++) {
    const session = props.sessions[i];
    const facilitatorPlural =
      session.facilitators_count === 0 || session.facilitators_count > 1
        ? 'Facilitators'
        : 'Facilitator';

    // truncate event and group name if too long
    const eventName =
      session.event.name.length > 45
        ? session.event.name.slice(0, 45) + '...'
        : session.event.name;
    const groupName =
      session.group_name && session.group_name.length > 10
        ? session.group_name.slice(0, 10) + '...'
        : session.group_name;
    const sessionTooltipText =
      `${session.product_code ? session.product_code + ' - ' : ''}` +
      `${session.event.name}${
        session.group_name ? ', ' + session.group_name : ''
      }`;
    sessionsNode.push(
      <div className="events-cal__session-detail" key={`session-${i}`}>
        <span>
          <Tooltip
            tooltip={sessionTooltipText}
            delay={1}
            offsetLeft={60}
            offsetTop={57}
            minWidth={140}
          >
            <a
              href={`/bl/event/${session.event.id}`}
              className="events-cal__session-detail-session-text tc__link"
              target="_blank"
              rel="noreferrer"
            >
              {`${
                session.product_code ? session.product_code + ' - ' : ''
              }${eventName}${groupName ? ', ' + groupName : ''}`}
            </a>
          </Tooltip>
        </span>
        <span className="events-cal__session-detail-time">
          {moment
            .tz(session.session_start_time_formatted, props.timeZone)
            .format('h:mma')
            .slice(0, -1) +
            '-' +
            moment
              .tz(session.session_end_time_formatted, props.timeZone)
              .format('h:mma')
              .slice(0, -1)}
          {generateTooltip(
            session.session_start_time_formatted,
            session.session_end_time_formatted
          )}
        </span>
        <span className="events-cal__session-detail-facilitators">
          {`${session.facilitators_count} Total ${facilitatorPlural}`}
        </span>
      </div>
    );
  }

  return <div className="events-cal__session-container">{sessionsNode}</div>;
};

CapacitySessionDetails.propTypes = {
  sessions: propTypes.array.isRequired,
  showEventDetail: propTypes.object.isRequired,
  timeZone: propTypes.string.isRequired,
};

export default CapacitySessionDetails;
