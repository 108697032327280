// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Utils from './../../../components/DynamicUtils';
import WindowHelpers from './../../../components/WindowHelpers';

/*
when a given element (like a section on the page) is scrolled into view,
this component will add the "active" class to the associated target element
(like the sidebar link to that section)
*/
class ActivateOnScrollToElement extends React.Component {
  constructor(props) {
    super(props);
    this.updateActiveElement = this.updateActiveElement.bind(this);
  }

  componentDidMount() {
    // be default, do not add the listener on mobile
    if (this.props.useOnMobile || !WindowHelpers.isMobileDevice()) {
      // call it once at the beginning
      this.updateActiveElement();
      // attach a scroll listener
      window.addEventListener('scroll', this.updateActiveElement);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateActiveElement);
  }

  updateActiveElement() {
    // get the window's current scroll position
    const scrollPosition = WindowHelpers.getVerticalScrollPosition();
    // check which element is scrolled into view,
    // and set only the associated target element to be "active"
    let element = null;
    let elementPos = null;
    let foundActive = false;
    let targetElement = null;
    for (let i = 0; i < this.props.elementCount; i++) {
      // get the element and the target element that may be changed
      element = document.getElementById(this.props.elementIdPrefix + i);
      targetElement = document.getElementById(this.props.targetIdPrefix + i);

      // if the element or the target doesn't exist, just skip this #
      if (!element || !targetElement) {
        continue;
      }

      // get the element's scroll position
      elementPos = Utils.getAbsolutePosition(element)[1];

      // the threshold for counting the next element as "active" is
      // at the bottom of this element, minus (i.e. up) a small buffer to improve the UX
      const threshold =
        elementPos + element.offsetHeight - this.props.elementUpperBuffer;

      // if this is the first element that is scrolled into view,
      // set the associated target as "active"
      if (!foundActive && scrollPosition < threshold) {
        if (!Utils.hasClass(targetElement, 'active')) {
          Utils.addClass(targetElement, 'active');
        }
        foundActive = true;
      }
      // otherwise, make sure this target element is not "active"
      else if (Utils.hasClass(targetElement, 'active')) {
        Utils.removeClass(targetElement, 'active');
      }
    }
  }

  render() {
    return null;
  }
}

ActivateOnScrollToElement.propTypes = {
  elementCount: PropTypes.number.isRequired,
  elementUpperBuffer: PropTypes.number.isRequired,
  elementIdPrefix: PropTypes.string,
  targetIdPrefix: PropTypes.string,
  useOnMobile: PropTypes.bool,
};

ActivateOnScrollToElement.defaultProps = {
  elementUpperBuffer: 50,
};

export default ActivateOnScrollToElement;
