// @ts-nocheck FIXME
import Analytics from '@legacy-components/components/Analytics';
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import PropTypes from 'prop-types';
import AbsoluteAlert from '../../../Alerts/AbsoluteStateless';

const ResetPasswordForm = (props) => {
  let password,
    confirmPassword,
    headlineNode,
    formFieldErrorClasses = {},
    errorsArray = [],
    errorsNode,
    rememberMe,
    resetPaswordCaptionNode;

  const formFieldNames = ['password', 'confirmPassword', 'api'];

  const handleFocus = (e, field, analyticsAction) => {
    e.preventDefault();
    const analyticsLabel =
      props.siteName === 'plans'
        ? 'from_set_password'
        : 'from_lab_set_password';
    Analytics.logEvent({
      category: 'password',
      action: analyticsAction,
      label: analyticsLabel,
    });
  };

  const getRememberMeNode = () => {
    // Hide Remember Me for mobile app users
    if (props.isMobileUser || props.siteName !== 'lab app') {
      return null;
    }

    return (
      <div
        onClick={() => props.toggleRememberMe('resetPW')}
        className="participant__login-form-item participant__login-form-item--remember-me"
      >
        <input
          type="checkbox"
          id="rememberMe"
          defaultChecked={props.ui.rememberMe}
          ref={(ref) => (rememberMe = ref)}
          className={'gatekeeper__remember-me-checkbox'}
        />
        <label htmlFor="rememberMe" className="gatekeeper__remember-me-label">
          Remember me
        </label>
      </div>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    props.resetPassword({
      password: password.value,
      confirmPassword: confirmPassword.value,
      rememberMe: rememberMe ? rememberMe.checked : true,
      redirect: props.redirect,
    });
  };

  const alertNode = (
    <AbsoluteAlert
      {...props.ui.alert}
      cssClasses={{ container: 'alert-banner--fixed-under-header' }}
    />
  );

  formFieldNames.forEach((fieldName) => {
    formFieldErrorClasses[fieldName] = '';
    if (fieldName in props.ui.errors) {
      formFieldErrorClasses[fieldName] = ' error';
      errorsArray.push(<div key={fieldName}>{props.ui.errors[fieldName]}</div>);
    }
  });

  if (errorsArray.length > 0) {
    errorsNode = (
      <div className="gatekeeper__message-container gatekeeper__message-container--error">
        {errorsArray}
      </div>
    );
  }

  let buttonLabel = 'Create Password';
  let disabledClass = '';

  if (props.ui.loading) {
    buttonLabel = 'Updating Password...';
    disabledClass = ' disabled';
  }

  const buttonClass =
    props.siteName === 'lab' || props.siteName === 'lab app'
      ? ' gatekeeper__submit--lab'
      : ' gatekeeper__submit--plans';

  let welcome;
  let headlineStyle;
  let pwText;

  welcome = 'Welcome to BetterLesson Coaching!';
  headlineStyle = 'gatekeeper__create-pw-headline';

  pwText = (
    <div className="gatekeeper__create-pw-text">
      {'Please write down the password you create, along with the email address for your account which is ' +
        props.user.email +
        '.'}
    </div>
  );

  if (WindowHelpers.getPathName().includes('registration')) {
    headlineNode = <h1 className={headlineStyle}>{welcome}</h1>;
  }

  if (props.siteName === 'lab app') {
    resetPaswordCaptionNode = (
      <div className="gatekeeper__create-pw-caption">
        {headlineNode}
        {pwText}
      </div>
    );
  }

  return (
    <div className="gatekeeper__form-container">
      {resetPaswordCaptionNode}
      {alertNode}
      <div className="gatekeeper__message">
        {errorsNode}
        <div className="gatekeeper__message--text form-heading">
          Set Your Password
        </div>
        Create a new password for your BetterLesson account.
      </div>
      <form className="gatekeeper__form">
        <div className="participant__login-form-item">
          <input
            type="password"
            placeholder="Create Password"
            ref={(ref) => (password = ref)}
            onFocus={(e) => handleFocus(e, 'password', 'set')}
            className={'gatekeeper__input' + formFieldErrorClasses.password}
          />
        </div>
        <div className="participant__login-form-item">
          <input
            type="password"
            placeholder="Confirm Password"
            ref={(ref) => (confirmPassword = ref)}
            onFocus={(e) => handleFocus(e, 'confirmPassword', 'confirm')}
            className={
              'gatekeeper__input' + formFieldErrorClasses.confirmPassword
            }
          />
        </div>
        {getRememberMeNode()}
        <div className="gatekeeper__submit-container">
          <button
            onClick={handleSubmit}
            className={'gatekeeper__submit' + disabledClass + buttonClass}
          >
            {buttonLabel}
          </button>
        </div>
      </form>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  isMobileUser: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;
