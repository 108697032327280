// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import ScaleCenterImage from '@legacy-components/react/ScaleCenterImage';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';

const EventCapacityReached = (props) => {
  return (
    <div className="tc__page tc__page--registration">
      <Header {...props} />
      <div className="row row--1200">
        <div className="cute-8-desktop cute-12-phone center-desktop">
          <div className="row">
            <div className="cute-6-desktop cute-12-phone registration__capped-col">
              <ScaleCenterImage
                className="registration__error-image registration__capped-image"
                src="/legacy-images/v5/cohort-full-small.png"
                alt="This registration link has expired"
              />
            </div>
            <div className="cute-6-desktop cute-12-phone registration__capped-col registration__capped-col--copy">
              <div className="v5__heading-2">
                {props.event.display_name} is at capacity
              </div>
              <div className="v5__body-copy">
                <p>There are no more available seats for this event.</p>
                <p>
                  Interested in learning more about BetterLesson?
                  <br />
                  <NavigationLink url={'/home'} className="tc__link">
                    View our website
                  </NavigationLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCapacityReached;
