// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

const EventCheckIn = (props) => {
  return (
    <React.Fragment>
      <div className="event__attendee-welcome v5__heading-4">
        Welcome, {props.user.first_name}!
      </div>
      <div className="v5__body-copy">
        Not {props.user.first_name} {props.user.last_name}?{' '}
        <a
          href={`/logout?redirect=/lab/event/${props.event.id}/register`}
          className="v5__link"
        >
          Log in
        </a>{' '}
        for today’s session.
      </div>
      <div className="event__attendee-check-in">
        <div className="event__attendee-check-in-headling v5__heading-5">
          Check In to View Today’s Materials.
        </div>
        <div className="event__attendee-check-in-copy v5__body-copy">
          Let us know you’ve attended today’s session by checking in. After
          checking in, you’ll be given access to today’s event materials,
          including the agenda and presentation deck.
        </div>
        <div
          className="v5__btn v5__btn--gradient"
          onClick={props.handleSessionCheckin}
        >
          Check In
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventCheckIn;

EventCheckIn.propTypes = {
  user: PropTypes.object.isRequired,
  handleSessionCheckin: PropTypes.func,
};
