// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';
import FilterCrumbs from './modules/FilterCrumbs';
import Filters from './modules/Filters';
import InfiniteScroll from './modules/InfiniteScroll';
import RecommendModal from './modules/RecommendModal';
import ResultCapsule from './modules/ResultCapsule';

const Search = (props) => {
  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  let resultText = "Sorry, we couldn't find anything for your search.";
  if (props.searchTerm) {
    resultText = (
      <span>
        Sorry, no results were found for &ldquo;{props.searchTerm}&rdquo;
      </span>
    );
  }
  let resultsNode;
  let filters;
  let mobileFilters;
  if (props.searchSuccess === false) {
    resultsNode = (
      <div className="tc__left-text tc__search-failure">
        <h1 className="tc__headline-3">Oops. Something is broken.</h1>
        <div className="tc__body-copy">
          Our search tool is temporarily unavailable. (We blame the cat.)
        </div>
        <br />
        <div className="tc__body-copy">
          Thanks for your patience as we tidy up this issue.
        </div>
        <img src="/legacy-images/404-pets.png" className="tc__merge-image" />
      </div>
    );
  } else {
    resultsNode = (
      <div className="search__no-results">
        <h3 className="search__headline tc__headline-5">{resultText}</h3>
        <div className="search__no-results-copy">
          Try checking your spelling, or using more general search terms.
        </div>
        <div className="search__no-results-copy">
          You can also try looking for results in{' '}
          <NavigationLink
            url={process.env.REACT_APP_TEACHING_URL + '/search?subjects=4'}
            className="tc__link"
          >
            English / Language Arts
          </NavigationLink>
          ,{' '}
          <NavigationLink
            url={process.env.REACT_APP_TEACHING_URL + '/search?subjects=1'}
            className="tc__link"
          >
            Math
          </NavigationLink>
          , and{' '}
          <NavigationLink
            url={process.env.REACT_APP_TEACHING_URL + '/search?subjects=2'}
            className="tc__link"
          >
            Science
          </NavigationLink>
        </div>
        <img
          className="search__no-results-img"
          src="/legacy-images/v4/search-again.png"
          alt="There are no search results for this query."
        />
      </div>
    );
  }
  if (props.results && props.results.length) {
    const resultNode = props.results.map((result, i) => {
      return (
        <ResultCapsule
          key={'search-result-strategy-' + result.id + '-' + i}
          {...result}
          addFavorite={props.addFavorite}
          removeFavorite={props.removeFavorite}
          recommend={props.ui.recommendModal.open}
          user={props.user}
          updateFilters={props.updateFilters}
          searchContext={props.searchContext}
        />
      );
    });
    resultsNode = <div className="search__capsules">{resultNode}</div>;
    filters = <Filters {...props} />;
    mobileFilters = (
      <div className="search__mobile-options">
        <button
          className="bl4__btn bl4__btn--blue  search__filter-button"
          onClick={() => props.ui.filtersModal.open()}
        >
          Filter
        </button>
      </div>
    );
  }

  let headlineLessonSegment = '';
  let headlineStrategySegment = '';
  let headlineTechToolSegment = '';
  props.filters.types.forEach((resultCount) => {
    if (resultCount.label === 'lesson' && resultCount.count > 0) {
      headlineLessonSegment =
        resultCount.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        '  Lessons';
    }
    if (resultCount.label === 'strategy' && resultCount.count > 0) {
      headlineStrategySegment =
        resultCount.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        '  Strategies';
    }
    if (resultCount.label === 'tech tool' && resultCount.count > 0) {
      headlineTechToolSegment =
        resultCount.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        '  Tech Tools';
    }
  });

  const headlineConnectorStrategy =
    headlineLessonSegment && headlineStrategySegment ? ' and ' : '';
  const headlineConnectorTechTool =
    (headlineStrategySegment && headlineTechToolSegment) ||
    (headlineLessonSegment && headlineTechToolSegment)
      ? ' and '
      : '';
  let headerCaptionNode = null;

  if (props.searchTerm && props.results.length > 0) {
    headerCaptionNode = (
      <div>
        <h5 className="search__headline tc__headline-5">
          {headlineLessonSegment +
            headlineConnectorStrategy +
            headlineStrategySegment +
            headlineConnectorTechTool +
            headlineTechToolSegment}{' '}
          match your search &ldquo;{props.searchTerm}&rdquo;{' '}
          <NavigationLink
            url={process.env.REACT_APP_TEACHING_URL + '/search?'}
            className="tc__link search__clear-search"
          >
            Clear search
          </NavigationLink>
        </h5>
      </div>
    );
  }

  // set up any opened modal
  let modalNode = null;
  if (props.ui.recommendModal.opened) {
    modalNode = (
      <RecommendModal
        {...props.ui.recommendModal}
        participants={props.ui.participants}
        recommend={props.addFavorite}
        selectRecommendElement={props.selectRecommendElement}
        getRecommendElement={props.getRecommendElement}
        user={props.user}
      />
    );
  }

  const filtersColumnWidth =
    props.results.length > 0 ? 'cute-2-desktop' : 'cute-1-desktop';

  return (
    <div className="tc__page tc__page--search">
      <div className="bl4__page-background search__background"></div>
      <Header page="Search" {...props} />
      {alertNode}
      {modalNode}

      <div className="row row--1200">
        <div
          className={`${filtersColumnWidth} cute-12-phone bl4__filters-container left`}
        >
          {filters}
        </div>
        <div className="cute-10-desktop cute-12-phone bl4-results left">
          {headerCaptionNode}
          {mobileFilters}
          <FilterCrumbs {...props} />
          {resultsNode}
        </div>
      </div>
      <InfiniteScroll
        scrollCallback={props.loadMoreResults}
        loading={props.resultLoader.isLoadingMore}
        canScroll={props.resultLoader.canLoadMore}
      />
    </div>
  );
};

Search.propTypes = {
  ui: PropTypes.object.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  loadMoreResults: PropTypes.func.isRequired,
  searchSuccess: PropTypes.bool,
};

export default Search;
