// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import GroupCapsule from './GroupCapsule';

const GroupsAndSessionsAdvanced = (props) => {
  // add the index to every group object, and hide deleted groups
  const groups = props.groups
    .map((group, i) => Object.assign(group, { index: i }))
    .filter((group) => !group.deleted);

  // add the index to every session object, and hide deleted sessions
  const sessions = props.sessions
    .map((session, i) => Object.assign(session, { index: i }))
    .filter((session) => !session.deleted);

  // some actions are disabled after the event has any attendees
  const eventHasAttendees = sessions.some((s) => s.attendee_count);

  // hide the UI elements for adding/editing/deleting by default
  let switchModesCapsule;
  let addGroupNode;
  if (props.canEditEventDetails) {
    // normally you can revert back to Basic mode
    let modeDescriptionNodes = (
      <React.Fragment>
        <div className="v5__body-copy edit-event__switch-modes-capsule-body-text">
          You can revert back to default settings, but you will lose session
          customization and your first group’s session information will be
          applied to all groups.
        </div>
        <button
          onClick={() => props.switchToBasicMode(null)}
          className="v5__btn v5__btn--blue-border edit-event__add-btn"
        >
          Revert to Basic Builder
        </button>
      </React.Fragment>
    );
    // however, if anyone has checked in to any sessions, we no longer allow reverting to Basic mode
    if (eventHasAttendees) {
      modeDescriptionNodes = (
        <div className="v5__body-copy edit-event__switch-modes-capsule-body-text">
          Participants have registered for this event, so you can no longer
          revert back to the Basic Event Builder.
        </div>
      );
    }

    switchModesCapsule = (
      <div className="edit-event__switch-modes-capsule">
        <div className="v5__heading-5 edit-event__switch-modes-capsule-headline-row">
          <div className="tc-v5-event-product edit-event__row-start-icon edit-event__row-start-icon--green"></div>
          <div>You are using the Advanced Event Builder</div>
        </div>
        {modeDescriptionNodes}
      </div>
    );

    addGroupNode = (
      <button
        onClick={props.addGroup}
        className={'v5__btn v5__btn--blue-border edit-event__add-btn'}
      >
        Add a Group
      </button>
    );
  }

  return (
    <div className="edit-event__bottom-section">
      <h2 className="v5__heading-4 edit-event__section-headline">
        Event Groups & Sessions
      </h2>

      {switchModesCapsule}

      {groups.map((group, i) => {
        // if there are more than 2 groups, you can freely delete any of them
        let deleteDisabled = false;
        let handleDelete = () => props.deleteGroup(group);
        // once there are only two groups left, the rules are a little different
        if (groups.length === 2) {
          // you can only delete the second group
          // (and only if there are no attendees and neither group has any registered participants),
          // which switches you back to Basic mode
          deleteDisabled =
            eventHasAttendees ||
            i === 0 ||
            groups.some((g) => g.participant_count);
          handleDelete = () => props.switchToBasicMode(group);
        }
        return (
          <GroupCapsule
            key={`group-${group.num}`}
            {...group}
            sessions={sessions.filter(
              (session) => session.group_num === group.num
            )}
            canEditEventDetails={props.canEditEventDetails}
            update={(data) => props.updateGroup(group.index, data)}
            delete={handleDelete}
            deleteDisabled={deleteDisabled}
            addSession={props.addSession}
            updateSession={props.updateSession}
            deleteSession={props.deleteSession}
            allProductCodes={props.allProductCodes}
            confirmDownsizeGroup={(data) => props.confirmDownsizeGroup(data)}
            timezone={props.timezone}
            confirmUndeliverSession={(data) =>
              props.confirmUndeliverSession(data)
            }
            scrollTo={props.scrollTo}
          />
        );
      })}

      {addGroupNode}
    </div>
  );
};

GroupsAndSessionsAdvanced.propTypes = {
  switchToBasicMode: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  addGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  sessions: PropTypes.array.isRequired,
  addSession: PropTypes.func.isRequired,
  updateSession: PropTypes.func.isRequired,
  deleteSession: PropTypes.func.isRequired,
  timezone: PropTypes.object.isRequired,
  canEditEventDetails: PropTypes.bool,
  confirmUndeliverSession: PropTypes.func.isRequired,
};

export default GroupsAndSessionsAdvanced;
