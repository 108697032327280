// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import HeaderStore from '@legacy-components/stores/V4/HeaderStore';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share';
import ZenScroll from 'zenscroll';
import Analytics from './../../../components/Analytics';
import ArrayHelpers from './../../../components/ArrayHelpers';
import Utils from './../../../components/DynamicUtils';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';
import ActivateOnScrollToElement from './../Common/ActivateOnScrollToElement';
import ArtifactImage from './../Common/ArtifactImage';
import ArtifactOverlay from './../Common/ArtifactOverlay/ArtifactOverlay';
import ExpandableList from './../Common/ExpandableList';
import Spinner from './../Common/Spinner';
import UserPhoto from './../Common/UserPhoto';
import WindowScrollElementFixer from './../Common/WindowScrollElementFixer';
import RecommendModal from './../Search/modules/RecommendModal';
import ShareModal from './modules/ShareModal';
import StrategyCapsule from './modules/StrategyCapsule';
import StrategySchema from './modules/StrategySchema';
import StrategySection from './modules/StrategySection';

const Strategy = (props) => {
  const lab = useLegacyLabContext();

  let alertNode = null;
  let modalNode = null;
  let recommendBtnNode = null;
  let heroArtifactNode = null;
  let relatedStrategiesNode = null;
  let socialSharingNode = null;
  let favoritesCountDesktopNode = null;
  let favoritesCountMobileNode = null;
  let specialtiesNode = null;
  let danielsonNode = null;
  let practicesNode = null;
  let domainsNode = null;
  const lessonsNode = null;
  let sectionNodes = null;

  // clicking on a link in the left side nav scrolls the page to the top of that section
  const scrollToSection = (index) => {
    const element = document.getElementById('section-' + index);
    if (element) {
      // on desktop only, clicking on a sidebar link scrolls to the associated section
      ZenScroll.setup(600, 10);
      ZenScroll.to(element);
    }
  };

  const handleToggleFavorite = () => {
    if (props.user) {
      // if there is no logged in user, show the login/register modal
      if (!props.user.id) {
        HeaderStore.actionDispatch('openRegisterModal', {
          reason: 'favorite_attempt',
        });
        return true;
      }
      // if the strategy is favorited, just unfavorite it
      else if (props.ui.favorited) {
        props.removeFavorite({
          userId: props.user.id,
          itemType: 'strategy',
          itemId: props.strategy.id,
        });
      }
      // otherwise, favorite the strategy
      else {
        props.addFavorite({
          userId: props.user.id,
          itemType: 'strategy',
          itemId: props.strategy.id,
          fromTag: 'strategy_details',
        });
      }
    }
    return false;
  };

  const handleRecommend = () => {
    props.ui.recommendModal.open({
      itemType: 'strategy',
      itemId: props.strategy.id,
    });
  };
  /*
    const handlePrint = () => {
        window.open('/strategy/print/' + props.strategy.id, '_blank');
    };
    */
  const handleSocialShare = (target) => {
    Analytics.logEvent({
      category: 'strategy',
      action: 'share_on_' + target,
      label: 'from_strategy_details',
    });
  };

  // Build the alert node if needed:
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // show a modal if one is opened
  if (props.ui.recommendModal.opened) {
    modalNode = (
      <RecommendModal
        {...props.ui.recommendModal}
        participants={props.ui.participants}
        recommend={props.addFavorite}
        selectRecommendElement={props.selectRecommendElement}
        getRecommendElement={props.getRecommendElement}
        user={props.user}
      />
    );
  } else if (props.ui.artifactModal.opened) {
    modalNode = (
      <ArtifactOverlay
        {...props.ui.artifactModal}
        user={props.user}
        publishStatus={props.strategy.publish_status}
        curatedStrategy={true}
        hideAddedDate={true}
        analyticsLabel=""
      />
    );
  } else if (props.ui.shareModal.opened) {
    modalNode = (
      <ShareModal
        {...props.ui.shareModal}
        analyticsLabel="from_strategy_details"
      />
    );
  }

  // if the strategy has a tagline, show it under the title
  const taglineNode = props.strategy.tagline ? (
    <div className="strategy-v4__tagline tc__center-text">
      {props.strategy.tagline}
    </div>
  ) : null;

  // headline text for the About This capsule depends on strategy type
  const aboutThisHeadline =
    props.strategy.type === 'tech_tool'
      ? 'About This Tech Tool'
      : 'About This Strategy';

  // set up Favorite button
  const btnDisabledClass = props.ui.loading ? ' disabled' : '';
  let favoriteBtnNode = (
    <button
      onClick={handleToggleFavorite}
      className={
        'bl4__btn bl4__btn--blue-border strategy-v4__btn' + btnDisabledClass
      }
    >
      <Spinner loading={props.ui.loading} />
      <span className="tc-v4-heart-empty strategy-v4__btn-icon strategy-v4__btn-icon--red"></span>
      Add to Favorites
    </button>
  );
  if (props.ui.favorited) {
    const favoritedText =
      props.strategy.favorite &&
      props.strategy.favorite.status === 'recommended'
        ? 'Recommended'
        : 'Favorited';
    favoriteBtnNode = (
      <button
        onClick={handleToggleFavorite}
        className={'bl4__btn strategy-v4__btn' + btnDisabledClass}
      >
        <Spinner loading={props.ui.loading} />
        <span className="tc-v4-heart-filled strategy-v4__btn-icon strategy-v4__btn-icon--red"></span>
        {favoritedText}
      </button>
    );
  }

  // set up Recommend button for coaches
  if (Utils.isContractCoach.call(lab, props.user)) {
    recommendBtnNode = (
      <button
        onClick={handleRecommend}
        className={
          'bl4__btn bl4__btn--blue-border strategy-v4__btn' + btnDisabledClass
        }
      >
        <Spinner loading={props.ui.loading} />
        <span className="tc-v4-coaching strategy-v4__btn-icon"></span>
        Recommend
      </button>
    );
  }

  // set up any hero image/video
  let heroImg;
  if (props.strategy.lessonfile && props.strategy.lessonfile.connections) {
    // add the lessonfile_id so that we get the correct URL upon opening the artifact overlay
    const lessonfile = props.strategy.lessonfile;
    lessonfile.lessonfile_id = props.strategy.lessonfile_id;

    // save this img for social sharing below
    heroImg = lessonfile.connections.public_preview_big;

    // video hero artifacts look slightly different than image hero artifacts
    let heroImageClass = 'strategy-v4__hero-image';
    let heroOverlayNode, heroTextNode;
    if (lessonfile.connections.preview_type === 'movie') {
      // show duration for videos
      let videoDurationText = null;
      if (lessonfile.connections.duration_or_pages) {
        videoDurationText = (
          <div className="strategy-v4__hero-duration">
            {Utils.hhmmss(lessonfile.connections.duration_or_pages, true)}
          </div>
        );
      }
      // for hero video, set up overlay with name + duration
      heroImageClass = 'strategy-v4__hero-video';
      heroOverlayNode = (
        <div className="strategy-v4__hero-overlay">
          <div className="strategy-v4__hero-video-arrow"></div>
        </div>
      );
      heroTextNode = (
        <div className="strategy-v4__hero-text">
          {lessonfile.title}
          {videoDurationText}
        </div>
      );
    }

    heroArtifactNode = (
      <div
        className="strategy-v4__hero-section"
        onClick={() => props.ui.artifactModal.open(lessonfile)}
      >
        <ArtifactImage
          preview={lessonfile.connections.preview_big}
          icon={lessonfile.connections.icon}
          hideNoPreviewIcon={true}
          title={props.strategy.title}
          containerClass="strategy-v4__hero-container"
          imageClass={heroImageClass}
        />
        {heroOverlayNode}
        {heroTextNode}
      </div>
    );
  }

  // by default, show the left nav link to the About this __ section
  const sectionLinkNodes = [
    <div
      key="nav-link-0"
      id="nav-link-0"
      onClick={() => scrollToSection(0)}
      className="tc__link strategy-v4__side-nav-row"
    >
      {aboutThisHeadline}
    </div>,
  ];
  // set up any strategy sections (including side nav links to them)
  if (ArrayHelpers.hasElements(props.strategy.sections)) {
    sectionNodes = props.strategy.sections.map((section, index) => {
      const i = index + 1;
      sectionLinkNodes.push(
        <div
          key={'nav-link-' + i}
          id={'nav-link-' + i}
          onClick={() => scrollToSection(i)}
          className="tc__link strategy-v4__side-nav-row"
        >
          {section.title}
        </div>
      );

      return (
        <StrategySection
          key={'section-' + section.id}
          index={i}
          {...section}
          openResource={props.ui.artifactModal.open}
          updateSectionExpandToggleCount={props.updateSectionExpandToggleCount}
        />
      );
    });
  }

  // set up any related strategies capsules
  if (ArrayHelpers.hasElements(props.relatedStrategies)) {
    const linkIndex = sectionLinkNodes.length;
    sectionLinkNodes.push(
      <div
        key={'nav-link-' + linkIndex}
        id={'nav-link-' + linkIndex}
        onClick={() => scrollToSection(linkIndex)}
        className="tc__link strategy-v4__side-nav-row"
      >
        Related Strategies
      </div>
    );
    relatedStrategiesNode = (
      <section id={`section-${linkIndex}`}>
        <div className="strategy-v4__related-strategies-headline-row">
          <div className="strategy-v4__related-strategies-line"></div>
          <div className="strategy-v4__related-strategies-headline">
            Related Strategies
          </div>
          <div className="strategy-v4__related-strategies-line"></div>
        </div>

        <div className="strategy-v4__related-strategies">
          {props.relatedStrategies.map((strategy) => {
            return (
              <StrategyCapsule
                key={'strategy-' + strategy.id}
                {...strategy}
                smallVersion={true}
                fromTag="strategy_details"
              />
            );
          })}
        </div>
      </section>
    );
  }

  // if the strategy is publicly accessible, show social media icons
  if (props.strategy.is_public) {
    // set up current page url for social sharing icons
    let shareUrl =
      process.env.REACT_APP_TEACHING_URL + '/strategy/' + props.strategy.id;
    if (props.strategy.seo_url) {
      shareUrl += '/' + props.strategy.seo_url;
    }
    socialSharingNode = (
      <aside className="strategy-v4__share">
        <div className="strategy-v4__share-text">Share</div>
        <div className="strategy-v4__share-icons">
          <div className="strategy-v4__share-icon">
            <FacebookShareButton
              url={shareUrl}
              hashtag="#BetterLesson"
              beforeOnClick={() => handleSocialShare('facebook')}
            >
              <span className="tc-facebook"></span>
            </FacebookShareButton>
          </div>
          <div className="strategy-v4__share-icon">
            <TwitterShareButton
              url={shareUrl}
              hashtags={['BetterLesson']}
              beforeOnClick={() => handleSocialShare('twitter')}
            >
              <span className="tc-twitter"></span>
            </TwitterShareButton>
          </div>
          <div className="strategy-v4__share-icon">
            <PinterestShareButton
              url={shareUrl}
              media={
                heroImg
                  ? heroImg
                  : process.env.REACT_APP_LAB_URL +
                    '/legacy-images/social_media/BL_squarelogo_pinterest.png'
              }
              beforeOnClick={() => handleSocialShare('pinterest')}
            >
              <span className="tc-pinterest"></span>
            </PinterestShareButton>
          </div>
          <div
            onClick={() => props.ui.shareModal.open({})}
            className="tc-v4-link strategy-v4__share-icon"
          ></div>
        </div>
      </aside>
    );
  }

  // set up the # users who favorited this strategy
  const count = props.ui.favoriteCount;
  if (count) {
    let countText = count + ' teachers like this strategy';
    if (count === 1) {
      countText = '1 teacher likes this strategy';
    }
    favoritesCountDesktopNode = (
      <div className="strategy-v4__metadata-section strategy-v4__favorites">
        <div className="tc-v4-heart-filled strategy-v4__favorites-icon"></div>
        <div className="strategy-v4__favorites-text">{countText}</div>
      </div>
    );
    favoritesCountMobileNode = (
      <div className="strategy-v4__favorites hide-desktop show-phone-flex">
        <div className="tc-v4-heart-filled strategy-v4__favorites-icon"></div>
        <div className="strategy-v4__favorites-text">{countText}</div>
      </div>
    );
  }

  let partnerDetails = {};
  if (props.strategy.focusAreas) {
    for (let i = 0; i < props.strategy.focusAreas.length; i++) {
      switch (props.strategy.focusAreas[i].id) {
        case 14:
          partnerDetails = {
            src: '/legacy-images/partners/newsela-logo.svg',
            alt: 'Newsela',
          };
          break;
        case 22:
          partnerDetails = {
            src: '/legacy-images/partners/kaufman.svg',
            alt: 'Kaufman',
          };
          break;
        case 24:
          partnerDetails = {
            src: '/legacy-images/partners/adobe.svg',
            alt: 'Adobe',
          };
          break;
        case 25:
          partnerDetails = {
            src: '/legacy-images/partners/kaufman.svg',
            alt: 'Kaufman',
          };
          break;
      }
    }
  }

  let partnerNode = null;
  let afterPartnerLogoClass = '';
  if (!Utils.isEmpty(partnerDetails)) {
    afterPartnerLogoClass = 'strategy-v4__metadata-section--after-partner';
    partnerNode = (
      <div className="strategy-v4__metadata-section strategy-v4__metadata-section--partner">
        <img src={partnerDetails.src} alt={partnerDetails.alt} />
      </div>
    );
  }

  // set up any strategy authors
  let authorNodes = (
    <div>
      <UserPhoto
        src="/legacy-images/bl-icon-circle.png"
        diameter={80}
        className="strategy-v4__author-photo"
      />
      <div className="strategy-v4__author-name">
        Written by BetterLesson Coaching
      </div>
    </div>
  );
  if (ArrayHelpers.hasElements(props.strategy.authors)) {
    authorNodes = (
      <div className="strategy-v4__metadata-flex-section strategy-v4__metadata-flex-section--centered">
        {props.strategy.authors.map((author) => {
          return (
            <div
              key={'author-' + author.id}
              className="strategy-v4__metadata-flex-item"
            >
              <UserPhoto
                src={author.photo}
                diameter={80}
                className="strategy-v4__author-photo"
              />
              <div className="strategy-v4__author-name">{author.label}</div>
              <div className="strategy-v4__metadata-grey-text">
                {author.role == null
                  ? 'BetterLesson Instructional Coach'
                  : author.role}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  const authorsNode = (
    <div
      className={`strategy-v4__metadata-section tc__center-text ${afterPartnerLogoClass}`}
    >
      <div className="strategy-v4__metadata-headline">Strategy Authors</div>
      {authorNodes}
    </div>
  );

  // set up the list of grade buckets
  let gradesNode = (
    <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
      All Grades
    </div>
  );
  // list individual grade bands if not all of them were selected
  if (
    ArrayHelpers.hasElements(props.strategy.gradeBands) &&
    props.strategy.gradeBands.length < 5
  ) {
    gradesNode = (
      <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
        {props.strategy.gradeBands.join(', ')}
      </div>
    );
  }
  // set up the list of subjects
  let subjectsNode = (
    <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
      All Subjects
    </div>
  );
  // list individual subjects if not all of them were selected
  if (
    ArrayHelpers.hasElements(props.strategy.subjects) &&
    props.strategy.subjects.length < 5
  ) {
    subjectsNode = (
      <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
        {props.strategy.subjects.map((subject) => subject.label).join(', ')}
      </div>
    );
  }
  const gradesSubjectsNode = (
    <div className="strategy-v4__metadata-section">
      <div className="strategy-v4__metadata-headline">Grade Level</div>
      {gradesNode}
      <div className="strategy-v4__metadata-headline">Subjects</div>
      {subjectsNode}
    </div>
  );

  // set up any specialty tags associated with the strategy
  if (ArrayHelpers.hasElements(props.strategy.specialtyTags)) {
    specialtiesNode = (
      <div className="strategy-v4__metadata-section">
        <div className="strategy-v4__metadata-headline">Specialty Areas</div>
        <div className="strategy-v4__metadata-flex-section">
          {props.strategy.specialtyTags.map((tag) => {
            return (
              <div
                key={'tag-' + tag.id}
                className="strategy-v4__metadata-flex-item"
              >
                <div className="strategy-v4__metadata-tag">{tag.label}</div>
                <div className="strategy-v4__metadata-tag-description">
                  {tag.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // set up any danielson components associated with the strategy
  if (ArrayHelpers.hasElements(props.strategy.danielsonComponents)) {
    danielsonNode = (
      <div className="strategy-v4__metadata-section">
        <div className="strategy-v4__metadata-headline">
          Danielson Framework
        </div>
        <div className="strategy-v4__metadata-flex-section">
          {props.strategy.danielsonComponents.map((dan) => {
            return (
              <div
                key={'dan-' + dan.id}
                className="strategy-v4__metadata-flex-item"
              >
                <div className="strategy-v4__metadata-tag">{dan.label}</div>
                <div className="strategy-v4__metadata-tag-description">
                  {dan.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // set up any instructional practices associated with the strategy
  if (ArrayHelpers.hasElements(props.strategy.practiceTags)) {
    const practiceNodes = props.strategy.practiceTags.map((practice) => {
      return (
        <div
          key={'practice-' + practice.id}
          className="strategy-v4__metadata-link"
        >
          <a
            href={
              process.env.REACT_APP_TEACHING_URL +
              '/search?practiceTags=' +
              practice.id
            }
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link"
          >
            {practice.label}
          </a>
        </div>
      );
    });

    practicesNode = (
      <div className="strategy-v4__metadata-section">
        <div className="strategy-v4__metadata-headline">
          Key Instructional Practices
        </div>
        <ExpandableList
          elements={practiceNodes}
          expandClassName="strategy-v4__metadata-link"
        />
      </div>
    );
  }

  // set up any learning domains associated with the strategy
  if (ArrayHelpers.hasElements(props.strategy.focusAreas)) {
    const domainNodes = props.strategy.focusAreas.map((domain) => {
      return (
        <div key={'domain-' + domain.id} className="strategy-v4__metadata-link">
          <a
            href={
              process.env.REACT_APP_TEACHING_URL +
              '/search?focusAreas=' +
              domain.id
            }
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link"
          >
            {domain.label}
          </a>
        </div>
      );
    });

    domainsNode = (
      <div className="strategy-v4__metadata-section">
        <div className="strategy-v4__metadata-headline">Focus Areas</div>
        <ExpandableList
          elements={domainNodes}
          expandClassName="strategy-v4__metadata-link"
        />
      </div>
    );
  }

  // TODO: finish once related lessons are added
  /*
    if (false) {
        lessonsNode = (
            <div className="strategy-v4__metadata-section">
                <div className="tc-v4-page strategy-v4__metadata-lessons-icon"></div>
                <div className="strategy-v4__metadata-lessons-headline">
                    Related Lessons
                </div>
                <div className="strategy-v4__metadata-link">
                    <NavigationLink url={'/search'}
                        className="tc__link tc__link--black strategy-v4__metadata-lesson-link">
                        Extending and Comparing Ratios
                    </NavigationLink>
                    <div className="strategy-v4__metadata-grey-text">Middle School Math</div>
                </div>
                <div className="strategy-v4__metadata-link">
                    <NavigationLink url={'/search'}
                        className="tc__link tc__link--black strategy-v4__metadata-lesson-link">
                        Box and Whiskers Activity
                    </NavigationLink>
                    <div className="strategy-v4__metadata-grey-text">Hight School ELA</div>
                </div>
            </div>
        );
    }
`   */

  const legalNode = (
    <div className="strategy-v4__metadata-section">
      <div className="strategy-v4__metadata-headline">License</div>
      <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
        Licensed under Creative Commons (
        <NavigationLink
          className="tc__link"
          url="https://creativecommons.org/licenses/by-nc/4.0/"
          target="_blank"
        >
          CC BY-NC 4.0
        </NavigationLink>
        )
      </div>
    </div>
  );

  return (
    <div className="tc__page">
      <Header page="Strategy" {...props} />
      <div className="bl4__page-background show-desktop hide-phone"></div>
      {alertNode}
      {modalNode}

      <div className="row row--1200">
        {/*
                    topMargin is used to calculate the height of the sidebar container and stop position of the sidebar
                    it is necessary because offsetHeigt does not include margins
                */}
        <WindowScrollElementFixer
          positionToClass="strategy-v4__center-column"
          positionThisClass="strategy-v4__side-nav"
          sectionExpandToggleCount={props.ui.sectionExpandToggleCount}
          positionThisContainerClass="strategy-v4__side-nav-container"
          updateFlag={JSON.stringify(props.ui.sectionExpandToggleCount)}
          topMargin={30}
        />

        <ActivateOnScrollToElement
          elementCount={sectionLinkNodes.length}
          elementUpperBuffer={190}
          elementIdPrefix="section-"
          targetIdPrefix="nav-link-"
        />

        <div className="strategy-v4__side-nav-container cute-2-desktop show-desktop hide-phone">
          <div className="bl4__sidebar--sticky strategy-v4__side-nav tc__body-copy">
            {sectionLinkNodes}
          </div>
        </div>

        <div className="cute-8-desktop cute-12-phone strategy-v4__center-column">
          <div className="strategy-v4__grey-background-mobile">
            <div className="strategy-v4__top-section-text-mobile">
              <h1 className="strategy-v4__title">{props.strategy.title}</h1>
              {taglineNode}

              <div className="strategy-v4__btns">
                {favoriteBtnNode}
                {recommendBtnNode}
                {/* <button onClick={handlePrint}
                                    className="bl4__btn bl4__btn--blue-border strategy-v4__btn show-desktop-inline hide-phone">
                                    <span className="tc-print strategy-v4__btn-icon"></span>
                                    Print Strategy
                                </button> */}
              </div>

              {favoritesCountMobileNode}

              <div className="hide-desktop show-phone">{socialSharingNode}</div>
            </div>

            {heroArtifactNode}
          </div>

          <StrategySection
            index={0}
            title={aboutThisHeadline}
            description={props.strategy.description}
            metadataNode={
              <div className="hide-desktop show-phone">
                {partnerNode}
                {authorsNode}
                {gradesSubjectsNode}
                {specialtiesNode}
                {danielsonNode}
                {practicesNode}
                {domainsNode}
                {lessonsNode}
                {legalNode}
              </div>
            }
          />

          {sectionNodes}
          {relatedStrategiesNode}
        </div>

        <div className="cute-2-desktop show-desktop hide-phone">
          {socialSharingNode}
          {favoritesCountDesktopNode}
          {partnerNode}
          {authorsNode}
          {gradesSubjectsNode}
          {specialtiesNode}
          {danielsonNode}
          {practicesNode}
          {domainsNode}
          {lessonsNode}
          {legalNode}
        </div>
      </div>
      <StrategySchema {...props} />
    </div>
  );
};

Strategy.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  strategy: PropTypes.object.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
};

export default Strategy;
