// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Analytics from './../../../../../components/Analytics';
import Utils from './../../../../../components/DynamicUtils';

class Section extends React.Component {
  constructor(props) {
    super(props);

    this.body = null;
    this.bodyWidth = 0;
    this.window = null;
    this.windowWidth = 718;
    this.sectionCount = 1;

    this.state = {
      visibleSection: 0,
      showLeftArrow: false,
      showRightArrow: false,
    };

    this.setupCarousel = this.setupCarousel.bind(this);
    this.checkShowArrows = this.checkShowArrows.bind(this);
    this.handleLeftClick = this.handleLeftClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleDotClick = this.handleDotClick.bind(this);
  }

  componentDidMount() {
    // set up the carousel slides and arrows based on the width of the carousel window
    this.setupCarousel();
    window.addEventListener('resize', this.setupCarousel);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.visibleSection != this.state.visibleSection) {
      this.checkShowArrows();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setupCarousel);
  }

  setupCarousel() {
    // find the width of the carousel window
    this.windowWidth = this.window.offsetWidth;

    // if we have discrete slides, set the width of the slides in the carousel
    const discreteSlides = this.body.querySelectorAll(
      '.bl4-profile__story-highlights-reflection'
    );
    if (discreteSlides.length) {
      discreteSlides.forEach((slide) => {
        slide.style.width = this.windowWidth + 'px';
      });
    }

    // see if we need to show the left and/or right arrows
    this.bodyWidth = this.body.offsetWidth;
    this.sectionCount = this.bodyWidth / this.windowWidth;
    this.checkShowArrows();
  }

  checkShowArrows() {
    // update the state to show/hide the left and/or right arrows
    const newState = {
      showLeftArrow: false,
      showRightArrow: false,
    };
    if (this.state.visibleSection > 0) {
      newState.showLeftArrow = true;
    }
    if (this.state.visibleSection + 1 < this.sectionCount) {
      newState.showRightArrow = true;
    }
    this.setState(newState);
  }

  handleLeftClick() {
    Analytics.logEvent({
      category: this.props.analyticsCategory,
      action: 'scroll_left',
      label: 'from_profile',
    });

    if (this.state.visibleSection > 0) {
      this.setState({ visibleSection: this.state.visibleSection - 1 });
    }
  }

  handleRightClick() {
    Analytics.logEvent({
      category: this.props.analyticsCategory,
      action: 'scroll_right',
      label: 'from_profile',
    });

    this.setState({ visibleSection: this.state.visibleSection + 1 });
  }

  handleDotClick(index) {
    this.setState({ visibleSection: index });
  }

  render() {
    // set up any additional attributes for the capsule div
    const attrs = {};
    if (this.props.id) {
      attrs.id = this.props.id;
    }

    // show the arrows to navigate left/right if necessary
    const arrowClass = this.props.hasDiscreteSections
      ? ''
      : ' bl4-profile__story-highlights-section-arrow--with-shadow';
    let leftArrowNode,
      mobileLeftArrowNode,
      rightArrowNode,
      mobileRightArrowNode;
    if (this.state.showLeftArrow) {
      leftArrowNode = (
        <div
          onClick={this.handleLeftClick}
          className={
            'bl4-profile__story-highlights-section-arrow bl4-profile__story-highlights-section-arrow--left' +
            arrowClass
          }
        >
          <span className="tc-arrow"></span>
        </div>
      );
      mobileLeftArrowNode = (
        <div
          onClick={this.handleLeftClick}
          className="bl4-profile__story-highlights-section-mobile-arrow bl4-profile__story-highlights-section-mobile-arrow--left"
        >
          <span className="tc-arrow"></span>
        </div>
      );
    }
    if (this.state.showRightArrow) {
      rightArrowNode = (
        <div
          onClick={this.handleRightClick}
          className={
            'bl4-profile__story-highlights-section-arrow bl4-profile__story-highlights-section-arrow--right' +
            arrowClass
          }
        >
          <span className="tc-arrow"></span>
        </div>
      );
      mobileRightArrowNode = (
        <div
          onClick={this.handleRightClick}
          className="bl4-profile__story-highlights-section-mobile-arrow"
        >
          <span className="tc-arrow"></span>
        </div>
      );
    }

    // for sections without discrete sections, if the width is less than one section long,
    // just center the elements in the section
    let carouselClass = '';
    if (!this.props.hasDiscreteSections && this.sectionCount <= 1) {
      carouselClass =
        ' bl4-profile__story-highlights-section-carousel--centered';
    }

    // shift the body row based on which section should be visible
    let bodyLeft = this.state.visibleSection * -1 * this.windowWidth;
    // if we do not have discrete sections, reduce the amount that we shift over by a third
    // to make sure everything inside is visible at some point
    if (!this.props.hasDiscreteSections && this.state.visibleSection > 0) {
      bodyLeft += this.windowWidth / 3;
    }

    // set up the navigation dots if necessary
    let dotsNode;
    if (this.props.hasDiscreteSections && this.sectionCount > 1) {
      dotsNode = (
        <div className="bl4-profile__story-highlights-section-dots">
          {Utils.range(0, this.sectionCount).map((i) => {
            const activeClass = i == this.state.visibleSection ? ' active' : '';
            return (
              <div
                key={'dot-' + i}
                onClick={() => this.handleDotClick(i)}
                className={
                  'bl4-profile__story-highlights-section-dot' + activeClass
                }
              ></div>
            );
          })}
        </div>
      );
    }

    return (
      <div className="bl4-profile__story-highlights-section" {...attrs}>
        <div className="bl4-profile__story-highlights-section-icon-row">
          {mobileLeftArrowNode}
          <div
            className={
              'bl4-profile__story-highlights-section-icon ' +
              this.props.iconClass
            }
          ></div>
          {mobileRightArrowNode}
        </div>
        <div className="bl4-profile__story-highlights-section-title">
          {this.props.title}
        </div>

        <div
          className={
            'bl4-profile__story-highlights-section-carousel' + carouselClass
          }
        >
          {leftArrowNode}
          <div
            ref={(el) => (this.window = el)}
            className="bl4-profile__story-highlights-section-carousel-window"
          >
            <div
              ref={(el) => (this.body = el)}
              className="bl4-profile__story-highlights-section-body"
              style={{ left: bodyLeft }}
            >
              {this.props.children}
            </div>
          </div>
          {rightArrowNode}
        </div>

        {dotsNode}
      </div>
    );
  }
}

Section.propTypes = {
  id: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hasDiscreteSections: PropTypes.bool,
  analyticsCategory: PropTypes.string,
};

export default Section;
