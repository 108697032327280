// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const Header = (props) => {
  return (
    <div>
      <div className="row">
        <div className="cute-12-desktop">
          <div className="row">
            <div className="cute-3-desktop cute-12-phone">
              <div className="registration-header__logo">
                <span className="registration-header__logo-caption">
                  BetterLesson Lab
                </span>
              </div>
            </div>
            <div className="registration-header__steps cute-8-phone cute-2-desktop cute-2-desktop-offset left">
              <div>{props.step} of 6</div>
              <div className="registration-header__steps-bar">
                <div
                  className="registration-header__steps-step"
                  style={{ width: (props.step / props.stepTotal) * 100 + '%' }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  step: PropTypes.number,
  stepTotal: PropTypes.number,
};

export default Header;
