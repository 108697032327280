// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import GateKeeperApi from './../../apis/GateKeeperApi';
import SearchApi from './../../apis/V4/SearchApi';
import Analytics from './../../components/Analytics';
import ArrayHelpers from './../../components/ArrayHelpers';
import { Router } from './../../components/Router';
import Utils from './../../components/StaticUtils';
import StorageHelper from './../../components/StorageHelper';
import Store from './../../components/Store';
import StringHelpers from './../../components/StringHelpers';
import WindowHelpers from './../../components/WindowHelpers';

const freezeWindowAsNecessary = () => {
  if (window.matchMedia('(max-width: 48em)').matches) {
    // store the position, in case we need to scroll to it
    store.scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;

    // disable page scrolling if mobile navigation is opened
    Utils.addClass(document.body, 'no-scroll');
  }
};

const unfreezeWindowAsNecessary = () => {
  if (window.matchMedia('(max-width: 48em)').matches) {
    Utils.removeClass(document.body, 'no-scroll');

    window.scrollTo(0, store.scrollPosition);
  }
};

const closeDropdownOnDesktopClick = () => {
  if (!window.matchMedia('(max-width: 48em)').matches) {
    store.actionDispatch('closeDropdown');
  }
};
const closeMarketingDropdownOnDesktopClick = () => {
  if (!window.matchMedia('(max-width: 48em)').matches) {
    store.actionDispatch('closeMarketingDropdown');
  }
};

const closeSearchOnDocumentClick = () => {
  store.actionDispatch('closeMobileSearch');
};

const setGatekeeperSuccessAlert = (message) => {
  store.gatekeeper.ui.alert = {
    type: 'success',
    message: message,
    handleClose: store.gatekeeper.clearAlert,
  };

  window.setTimeout(() => {
    window.scroll(0, 0);
  }, 50);
};

const isMobileUser = () => {
  return WindowHelpers.getPathName() === '/coaching/lab/participant/login';
};

const getFieldErrorMessage = (fieldName) => {
  switch (fieldName) {
    case 'firstName':
      return 'Please enter your first name.';
    case 'lastName':
      return 'Please enter your last name.';
    case 'email':
      return 'Please enter your email.';
    case 'password':
      return 'Please enter a password.';
    case 'role':
      return 'Please select a role.';
    case 'recaptchaResponse':
      return 'Beep boop beep. Looks like you are a robot.';
  }
  return fieldName + ' is required.';
};

const handleRedirect = (redirect = null, fromTag = '') => {
  let redirectDestination = '/';

  if (store.gatekeeper.redirect) {
    redirectDestination = store.gatekeeper.redirect;
  } else if (!store.gatekeeper.redirect && redirect) {
    redirectDestination = redirect;
  }
  location.href = redirectDestination + fromTag;
};

const store = Store('HeaderStore', {
  user: null,
  scrollPosition: null,
  lessonVisiting: null,
  dropDownOverflows: false,
  headerParticipant: null,
  handleTeacherViewSubmit: null,
  ui: {
    teacherView: true,
    teacherViewModalState: true,
    dropdownOpened: false,
    mobileSearchOpened: false,
    searchText: '',
    searchAutocompleteResults: [],
    showLoginModal: false,
    showRegisterModal: false,
    alert: {},
    marketingDropdownOpened: false,
  },
  gatekeeper: {
    modalRegisterOpenedReason: '',
    scrollPosition: 0,
    ui: {
      errorBanner: null,
      modalDisplay: null,
      rememberMe: true,
      loading: false,
      alert: {},
      errors: {},
      successMessage: null,
      cta: null,
    },
    isMobileUser: false,
    // Which site is the user trying to access?
    siteName: 'plans',
    redirect: null,
  },
});
const setupHeaderParticipant = (headerParticipant, keepHeaderParticipant) => {
  // no header participant is set clear it out of web storage
  if (!headerParticipant && !keepHeaderParticipant) {
    StorageHelper.removeItem('headerParticipantStorage');
    return;
  }

  const wsHeaderParticipant = StorageHelper.getItem('headerParticipantStorage');

  // np participant set in WS set it from the backend
  if (!wsHeaderParticipant) {
    StorageHelper.setItem('headerParticipantStorage', headerParticipant);
    return headerParticipant;
  }
  // search page condition just use what we have stored
  if (!headerParticipant) {
    return wsHeaderParticipant;
  }
  if (
    !wsHeaderParticipant ||
    (wsHeaderParticipant && wsHeaderParticipant.id !== headerParticipant.id)
  ) {
    StorageHelper.setItem('headerParticipantStorage', headerParticipant);
    return headerParticipant;
  }
  if (headerParticipant.currentStory) {
    headerParticipant.defaultStory = headerParticipant.currentStory;
    StorageHelper.setItem('headerParticipantStorage', headerParticipant);
    return headerParticipant;
  }
  if (
    wsHeaderParticipant &&
    wsHeaderParticipant.defaultStory &&
    wsHeaderParticipant.defaultStory.id !== headerParticipant.defaultStory.id
  ) {
    return wsHeaderParticipant;
  }

  return headerParticipant;
};

// ACTIONS
store.actionCreate('init', function (data) {
  store.user = data.user;
  store.handleTeacherViewSubmit = data.handleTeacherViewSubmit;

  store.showLoginModal = false;
  store.showRegisterModal = false;
  store.gatekeeper.ui.alert = {};
  store.gatekeeper.ui.errors = {};

  store.gatekeeper.ui.successMessage = null;

  store.gatekeeper.currentLesson = data.lessonId
    ? parseInt(data.lessonId)
    : null;
  store.lessonVisiting = null;
});

store.actionCreate('browserInit', (data) => {
  // preserve widget state across sessions and pages
  const teacherViewState = StorageHelper.getItem('teacherViewState');
  store.ui.teacherView = true;
  if (teacherViewState === false) {
    store.ui.teacherView = false;
  }
  const teacherViewModalState = StorageHelper.getItem('teacherViewModalState');
  if (teacherViewModalState === true) {
    store.ui.teacherViewModalState = true;
  }

  const headerParticipant = setupHeaderParticipant(
    data.headerParticipant,
    data.keepHeaderParticipant
  );
  // set data to be used by the widget template
  store.headerParticipant = headerParticipant;

  // GateKeeper
  if (store.user && !store.user.is_public) {
    // if they logged in correctly get rid of lessons/strategies clicked tracking
    StorageHelper.removeCookieItem('bl_lessons_viewed');
    StorageHelper.removeCookieItem('bl_strategies_viewed');
  }

  /***** uses window *****/
  store.gatekeeper.isMobileUser = isMobileUser();
  const url = Router.getCurrentPath();
  if (url.includes('/coaching/lab/participant')) {
    store.gatekeeper.siteName = 'lab app';
  } else if (url.includes('/coaching/lab')) {
    store.gatekeeper.siteName = 'lab';
  }
  store.gatekeeper.redirect = WindowHelpers.getQueryStringParam('redirect');

  const bannerMessage = WindowHelpers.getQueryStringParam('errorMsg');
  if (bannerMessage) {
    store.gatekeeper.ui.errorBanner = bannerMessage;
  }

  const queryParams = Utils.parseQueryString(window.location.search);
  if ('confirmed' in queryParams) {
    store.actionDispatch('setAlert', {
      type: 'success',
      message: 'Email successfully confirmed',
    });
  }
});

// const setGatekeeperApiError = (message) => {
store.actionCreate('setGatekeeperApiError', function (message) {
  store.gatekeeper.ui.errors.general = store.gatekeeper.ui.errors.general
    ? store.gatekeeper.ui.errors.general
    : [];

  store.gatekeeper.ui.errors.general.push(message);
});

store.actionCreate('updateProfileImage', (data) => {
  store.user.photo = data.photo;
});

/****** Dropdowns ******/

store.actionCreate('openMarketingDropdown', function () {
  delayedEventListener(() =>
    document.addEventListener('click', closeMarketingDropdownOnDesktopClick)
  );

  if (store.ui.mobileSearchOpened) {
    store.actionDispatch('closeMobileSearch');
  }
  freezeWindowAsNecessary();
  store.ui.marketingDropdownOpened = true;
});
store.actionCreate('closeMarketingDropdown', function () {
  document.removeEventListener('click', closeMarketingDropdownOnDesktopClick);
  unfreezeWindowAsNecessary();
  store.ui.marketingDropdownOpened = false;
});

store.actionCreate('updateDropdownOverflow', function (needsOverflow) {
  store.dropDownOverflows = needsOverflow;
});

store.actionCreate('openDropdown', function () {
  delayedEventListener(() =>
    document.addEventListener('click', closeDropdownOnDesktopClick)
  );

  if (store.ui.mobileSearchOpened) {
    store.actionDispatch('closeMobileSearch');
  }
  // in mobile
  freezeWindowAsNecessary();
  store.ui.dropdownOpened = true;
});

store.actionCreate('closeDropdown', function () {
  document.removeEventListener('click', closeDropdownOnDesktopClick);
  unfreezeWindowAsNecessary();
  store.ui.dropdownOpened = false;
});
store.actionCreate('openMobileSearch', function () {
  delayedEventListener(() =>
    document.addEventListener('click', closeSearchOnDocumentClick)
  );

  store.ui.mobileSearchOpened = true;
  if (store.ui.dropdownOpened) {
    store.actionDispatch('closeDropdown');
  }
});

store.actionCreate('closeMobileSearch', function () {
  store.ui.searchAutocompleteResults = [];
  store.ui.mobileSearchOpened = false;
  document.removeEventListener('click', closeSearchOnDocumentClick);
});

store.actionCreate('searchAutocomplete', function (text) {
  // if there are less than 2 characters in the input, hide the autocomplete menu
  if (!text || text.length < 2) {
    store.actionDispatch('updateAutocompleteResults', []);
    return;
  }

  store.ui.searchText = text;

  // otherwise, do a search on the content tags
  return SearchApi.searchAutocomplete({
    text: text,
  })
    .then((response) => {
      store.actionDispatch('updateAutocompleteResults', response.results);
    })
    .catch(() => {
      store.actionDispatch('updateAutocompleteResults', []);
    });
});

store.actionCreate('updateAutocompleteResults', function (data) {
  store.ui.searchAutocompleteResults = data;
});

/****** Teacher Coach View Toggle Element ******/

store.actionCreate('openTeacherViewModal', function () {
  StorageHelper.setItem('teacherViewModalState', true);
  store.ui.teacherViewModalState = true;
  Analytics.logEvent({
    category: 'navigation_widget',
    action: 'expand',
    label: 'from_coach_navigation_widget',
  });
});

store.actionCreate('closeTeacherViewModal', function () {
  StorageHelper.setItem('teacherViewModalState', false);
  store.ui.teacherViewModalState = false;
  Analytics.logEvent({
    category: 'navigation_widget',
    action: 'collapse',
    label: 'from_coach_navigation_widget',
  });
});

store.actionCreate('openRegisterModal', function (data) {
  if (data && data.reason) {
    store.gatekeeper.modalRegisterOpenedReason = data.reason;
  }

  store.ui.showRegisterModal = true;
  store.ui.showLoginModal = false;
  store.gatekeeper.ui.errors = {};
  store.lessonVisiting = null;

  let label = 'from_header';
  if (data && data.lessonId) {
    store.lessonVisiting = data.lessonId;
    label = 'from_mtp_lesson_' + store.lessonVisiting;
  }

  Analytics.logEvent({
    category: 'sign_up',
    action: 'open',
    label: label,
  });
});
store.actionCreate('closeRegisterModal', function () {
  store.actionDispatch('setRegistrationReason', '');
  store.ui.showRegisterModal = false;
  store.ui.showLoginModal = false;
});

store.actionCreate('setAlert', function (data) {
  store.ui.alert = {
    type: data.type,
    message: data.message,
    handleClose: () => store.actionDispatch('clearAlert'),
  };
});
store.actionCreate('clearAlert', function () {
  store.ui.alert = {};
});

store.actionCreate('openLoginModal', function (origin) {
  const label = origin === 'register' ? 'from_sign_up' : 'from_header';
  store.ui.showRegisterModal = false;
  store.ui.showLoginModal = true;
  store.gatekeeper.ui.errors = {};
  Analytics.logEvent({
    category: 'login',
    action: 'open',
    label: label,
  });
});
store.actionCreate('closeLoginModal', function () {
  store.ui.showRegisterModal = false;
  store.ui.showLoginModal = false;
});

store.actionCreate('submitTeacherViewModal', function (data) {
  const teacherViewState = data === false ? false : true;
  StorageHelper.setItem('teacherViewState', teacherViewState);

  if (store.handleTeacherViewSubmit) {
    store.handleTeacherViewSubmit(data);
  }
  const actionTarget = !data ? 'coach' : 'participant';
  Analytics.logEvent({
    category: 'toggle',
    action: 'set_to_' + actionTarget,
    label: 'from_navigation_widget',
  });

  store.ui.teacherView = data;
});

/****** GATEKEEPER FUNCTIONS ******/
store.actionCreate('gatekeeperClearAlert', function () {
  store.gatekeeper.ui.alert = {};
});
store.toggleRememberMe = (page = 'login') => {
  const labelPrefix = 'from_';
  Analytics.logEvent({
    category: 'remember_me',
    action: store.gatekeeper.ui.rememberMe ? 'off' : 'on',
    label:
      page === 'resetPW' ? labelPrefix + 'set_password' : labelPrefix + 'login',
  });
  store.gatekeeper.ui.rememberMe = !store.gatekeeper.ui.rememberMe;
};
store.actionCreate('setRegistrationReason', (reason) => {
  store.gatekeeper.modalRegisterOpenedReason = reason;
});
store.actionCreate('register', function (data) {
  const labelSuffix = store.gatekeeper.modalRegisterOpenedReason
    ? `_${store.gatekeeper.modalRegisterOpenedReason}`
    : '';

  // reset analytics variable
  store.actionDispatch('setRegistrationReason', '');

  data.requestPath = WindowHelpers.getPathName();

  store.gatekeeper.ui.alert = {};
  store.gatekeeper.ui.errors = {};

  let errors = {};
  const requiredFields = [
    'firstName',
    'lastName',
    'email',
    'role',
    'recaptchaResponse',
  ];

  ArrayHelpers.remove(requiredFields, 'recaptchaResponse');
  requiredFields.forEach(function (field) {
    if (!StringHelpers.isValid(data[field], field)) {
      errors[field] = getFieldErrorMessage(field);
      errors.general = [];
      errors.general.push('Please enter all the required fields.');
    }
  });

  const passwordValidationErrors = StringHelpers.getPasswordValidationErrors(
    data.password,
    data.confirmPassword
  );
  errors = Object.assign(errors, passwordValidationErrors);

  if (!Utils.isEmpty(errors)) {
    Analytics.logEvent({
      category: 'sign_up',
      action:
        passwordValidationErrors && errors.length > 1
          ? 'sign_up_failed_password'
          : 'sign_up_failed_required_field',
      label: `from_sign_up_modal${labelSuffix}`,
    });
    store.gatekeeper.ui.errors = errors;

    window.setTimeout(() => {
      window.scroll(0, 0);
    }, 50);
    return;
  }

  store.gatekeeper.ui.loading = true;
  data.isMobileUser = store.gatekeeper.isMobileUser;
  return GateKeeperApi.register(data)
    .then((response) => {
      store.gatekeeper.ui.loading = false;
      Analytics.logEvent({
        category: 'sign_up',
        action: 'sign_up_success',
        label: `from_sign_up_modal${labelSuffix}`,
      });
      store.gatekeeper.freeResourceLimitReached = false;
      store.ui.showLoginModal = false;
      store.ui.showRegisterModal = false;
      if (data.successCallback && typeof data.successCallback === 'function') {
        data.successCallback(response);
        return false;
      }
      const hasQueryString = Utils.hasQueryString(response.redirectUrl);
      const fromTag = `${hasQueryString}from=login`;
      handleRedirect(response.redirectUrl, fromTag);

      return false;
    })
    .catch((response) => {
      store.gatekeeper.ui.loading = false;
      const formattedError = response.error.replace(/ /g, '_').toLowerCase();
      const action = `sign_up_failed_${formattedError}`;
      Analytics.logEvent({
        category: 'sign_up',
        action: action,
        label: `from_sign_up_modal${labelSuffix}`,
      });
      store.actionDispatch('setGatekeeperApiError', response.error);
    });
});

store.actionCreate('login', function (data) {
  store.gatekeeper.ui.alert = {};
  store.gatekeeper.ui.errors = {};

  const errors = {};

  const requiredFields = ['email', 'password'];
  requiredFields.forEach(function (field) {
    if (!StringHelpers.isValid(data[field], field)) {
      errors[field] = getFieldErrorMessage(field);
      errors.general = [];
      errors.general.push('Please enter all the required fields.');
    }
  });

  if (!Utils.isEmpty(errors)) {
    store.gatekeeper.ui.errors = errors;

    window.setTimeout(() => {
      window.scroll(0, 0);
    }, 50);
    return;
  }

  store.gatekeeper.ui.loading = true;
  data.isMobileUser = store.gatekeeper.isMobileUser;

  return GateKeeperApi.login(data)
    .then((response) => {
      store.gatekeeper.freeResourceLimitReached = false;
      store.gatekeeper.ui.loading = false;
      store.ui.showLoginModal = false;
      store.ui.showRegisterModal = false;
      if (data.successCallback && typeof data.successCallback === 'function') {
        data.successCallback(response);
        return false;
      }

      const hasQueryString = Utils.hasQueryString(response.redirectUrl);
      const fromTag = `${hasQueryString}from=login`;
      handleRedirect(response.redirectUrl, fromTag);
    })
    .catch((response) => {
      store.gatekeeper.ui.loading = false;
      store.gatekeeper.ui.loginAttempts = response.attempts;
      store.actionDispatch('setGatekeeperApiError', response.error);
    });
});

store.actionCreate('requestNewPassword', function (data) {
  data.requestPath = WindowHelpers.getPathName();

  store.gatekeeper.ui.alert = {};
  store.gatekeeper.ui.errors = {};
  store.gatekeeper.ui.successMessage = null;

  const errors = {};
  const requiredFields = ['email', 'recaptchaResponse'];

  ArrayHelpers.remove(requiredFields, 'recaptchaResponse');
  requiredFields.forEach(function (field) {
    if (!StringHelpers.isValid(data[field], field)) {
      errors[field] = getFieldErrorMessage(field);
      errors.general = [];
      errors.general.push('Please enter all the required fields.');
    }
  });

  if (!Utils.isEmpty(errors)) {
    store.gatekeeper.ui.errors = errors;

    window.setTimeout(() => {
      window.scroll(0, 0);
    }, 50);
    return;
  }

  store.gatekeeper.ui.loading = true;
  data.isMobileUser = store.gatekeeper.isMobileUser;

  return GateKeeperApi.requestNewPassword(data)
    .then(() => {
      store.gatekeeper.ui.loading = false;
      const successMessage =
        'Please check your email address to finish resetting your password.';
      if (data.successCallback && typeof data.successCallback === 'function') {
        data.successCallback(successMessage);
        return false;
      }
      setGatekeeperSuccessAlert(successMessage);
    })
    .catch((response) => {
      store.gatekeeper.ui.loading = false;
      store.actionDispatch('setGatekeeperApiError', response.error);
    });
});

store.actionCreate('resetPassword', function (data) {
  data.resetCode = WindowHelpers.getQueryStringParam('code');
  store.gatekeeper.ui.alert = {};
  store.gatekeeper.ui.errors = {};

  const errors = StringHelpers.getPasswordValidationErrors(
    data.password,
    data.confirmPassword
  );

  // if the form has any errors, display them instead of submitting the form
  if (!Utils.isEmpty(errors)) {
    store.gatekeeper.ui.errors = errors;

    window.setTimeout(() => {
      window.scroll(0, 0);
    }, 50);
    return;
  }

  store.gatekeeper.ui.loading = true;
  data.isMobileUser = store.gatekeeper.isMobileUser;

  return GateKeeperApi.resetPassword(data)
    .then((response) => {
      const hasQueryString = Utils.hasQueryString(response.redirectUrl);
      const fromTag =
        store.gatekeeper.siteName === 'plans'
          ? `${hasQueryString}from=reset_password`
          : `${hasQueryString}from=lab_set_password`;
      handleRedirect(response.redirectUrl, fromTag);
    })
    .catch((response) => {
      store.gatekeeper.ui.loading = false;
      store.actionDispatch('setGatekeeperApiError', response.error);
    });
});

store.actionCreate('recaptchaSuccessCallback', function (data) {
  if (
    StringHelpers.isValid(data, 'recaptchaResponse') &&
    store.gatekeeper.ui.errors.recaptchaResponse
  ) {
    delete store.gatekeeper.ui.errors.recaptchaResponse;
  }
});

store.actionCreate('setmodalDisplay', (data) => {
  store.gatekeeper.ui.errors = {};
  store.gatekeeper.ui.cta = null;
  store.gatekeeper.ui.modalDisplay = data.modalToShow;

  const appContainer = document.getElementById('common_app');
  const overlayElem = document.querySelector('.mt-resource-overlay');
  if (data.eventTargetId) {
    let message = null;
    switch (data.eventTargetId) {
      case 'static-site-favorite-button':
        message = 'To favorite lessons, please create your free account.';
        break;
      case 'static-site-print-button':
        message = 'To print lessons, please create your free account.';
        break;
      case 'static-site-download-button':
        message = 'To download resources, please create your free account.';
        break;
    }
    store.gatekeeper.ui.cta = message;
  }
  if (data.modalToShow == null) {
    WindowHelpers.scrollTo(0, store.gatekeeper.scrollPosition);
    const BL = window.BL || null;

    if (
      !BL ||
      !appContainer ||
      !overlayElem ||
      Utils.hasClass(overlayElem, 'hidden')
    ) {
      return;
    }

    appContainer.removeAttribute('style');
    BL.UI.disableWindowScroll();
    return;
  }

  store.gatekeeper.scrollPosition =
    document.documentElement.scrollTop || document.body.scrollTop;

  // if the resource overlay is open we need to do some extra work to resolve any scroll issues
  if (!appContainer || !overlayElem || Utils.hasClass(overlayElem, 'hidden')) {
    return;
  }

  appContainer.setAttribute('style', 'height:840px;overflow:hidden;');
  document.body.removeAttribute('style');
  Utils.removeClass(document.body, 'opened-resource-overlay');
});

store.actionCreate('requestNewPasswordSuccess', function () {
  store.gatekeeper.ui.successMessage =
    'Please check your email. We have sent you a link to reset your password.';
});

export default store;
