// @ts-nocheck FIXME
import Action from './Action';

import { History, Router } from './Router';

const addClass = function () {
  if (!document) {
    return;
  }
  document.body.classList.add('layer');
};

const removeClass = function () {
  if (!document) {
    return;
  }
  document.body.classList.remove('layer');
};

const isLayer = function (el) {
  if (el.getAttribute('id') == 'react-layer-container') {
    return true;
  }
  return false;
};

const closeIntent = function (e) {
  // ignore when there is no modal open
  if (LayerManager.count() <= 0) {
    return;
  }

  // inore right click, center click
  if (e.which != 1) {
    return;
  }

  // get the element that was clicked
  let el = e.target || e.toElement;

  // see if the element wants to inhibit this functionality
  if (el.getAttribute('data-no-layer-close') == 1) {
    return;
  }

  // loop through element parents to see if the top one is the layer container
  for (; el && el !== document; el = el.parentNode) {
    if (isLayer(el)) {
      // do nothing, we are in a layer
      return;
    }
  }

  // we are not in a layer, close a layer
  LayerManager.close(false, true);
};

const listenForCloseIntent = function () {
  if (document) {
    document.body.removeEventListener('mousedown', closeIntent);
    document.body.addEventListener('mousedown', closeIntent, false);
  }
};

const LayerManager = {
  layerCount: 0,
  currentIndex: 0,
  history: {},
  params: {},

  count: function () {
    return this.layerCount;
  },

  new: function (Component, params, Wrapper, url) {
    // handle click on background to close
    listenForCloseIntent();

    // open a new layer
    this.currentIndex = this.layerCount + 1;
    this.layerCount = this.currentIndex;
    if (url) {
      this.history[this.currentIndex] = Router.getCurrentPath();
      History.replace({}, '', url);
    }
    if (params.closeUrl) {
      this.history[this.currentIndex] = params.closeUrl;
    }
    this.params[this.currentIndex] = params;

    const style = {
      zIndex: this.currentIndex * 100,
      display: 'block',
    };

    Action.dispatch('LayerManager.' + this.currentIndex, {
      component: Component,
      wrapper: Wrapper,
      style: style,
      params: params,
    });

    addClass();
  },

  current: function (Component, params, Wrapper, url) {
    // handle click on background to close
    listenForCloseIntent();

    // target the current open layer
    if (this.currentIndex == 0) {
      this.currentIndex++;
    }
    this.layerCount = this.currentIndex;

    if (url) {
      this.history[this.currentIndex] = Router.getCurrentPath();
      History.replace({}, '', url);
    }
    if (params.closeUrl) {
      this.history[this.currentIndex] = params.closeUrl;
    }

    const style = {
      zIndex: this.currentIndex * 100,
      display: 'block',
    };

    Action.dispatch('LayerManager.' + this.currentIndex, {
      component: Component,
      wrapper: Wrapper,
      style: style,
      params: params,
    });

    addClass();
  },

  target: function (index, Component, params, Wrapper, url) {
    // handle click on background to close
    listenForCloseIntent();

    // target a specific layer
    if (index == 0) {
      return;
    }
    this.currentIndex = index;

    if (url) {
      this.history[this.currentIndex] = Router.getCurrentPath();
      History.replace({}, '', url);
    }
    if (params.closeUrl) {
      this.history[this.currentIndex] = params.closeUrl;
    }

    // when you target a layer, it closes layers above it
    if (this.currentIndex > this.layerCount) {
      this.layerCount = this.currentIndex;
    }
    if (this.currentIndex < this.layerCount) {
      let i;
      for (i = this.layerCount; i > this.currentIndex; i--) {
        Action.dispatch('LayerManager.' + i, {
          component: null,
          style: {
            zIndex: i * 100,
            display: 'none',
          },
        });
      }
      this.layerCount = this.currentIndex;
    }

    const style = {
      zIndex: this.currentIndex * 100,
      display: 'block',
    };

    Action.dispatch('LayerManager.' + this.currentIndex, {
      component: Component,
      wrapper: Wrapper,
      style: style,
      params: params,
    });

    addClass();
  },

  close: function (url, byIntent) {
    if (this.currentIndex == 0) {
      return;
    }
    if (byIntent && 'disableCloseByIntent' in this.params[this.currentIndex]) {
      return;
    }

    // close whatever the current layer is open
    Action.dispatchSync('LayerManager.' + this.currentIndex, {
      component: null,
      style: {
        zIndex: this.currentIndex * 100,
        display: 'none',
      },
    });

    // restore history
    if (this.history[this.currentIndex]) {
      History.replace({}, '', this.history[this.currentIndex]);
      delete this.history[this.currentIndex];
    }
    if (url) {
      History.replace({}, '', url);
    }

    // decrement the internal counts
    this.currentIndex--;
    this.layerCount--;

    removeClass();
  },

  reset: function () {
    this.currentIndex = 0;
    this.layerCount = 0;
    this.history = {};

    for (let i = 1; i <= 10; i++) {
      Action.dispatch('LayerManager.' + i, {
        component: null,
        style: {
          zIndex: i * 100,
          display: 'none',
        },
      });
    }

    removeClass();
  },
};

export default LayerManager;
