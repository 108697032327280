// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const RowHideNode = (props) => {
  return (
    <div
      className="admin-table__event-list--collapse clickable"
      onClick={props.hideRow}
    >
      hide <span className="tc-v5-expand-collapse-outline"></span>
    </div>
  );
};

RowHideNode.propTypes = {
  hideRow: PropTypes.func.isRequired,
};

export default RowHideNode;
