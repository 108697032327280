// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const EventAttendeeStickyNav = (props) => {
  const handlePreviousSessionClick = () => {
    props.handeSetActiveSession(props.previousSession.id);
  };
  const handleNextSessionClick = () => {
    props.handeSetActiveSession(props.nextSession.id);
  };
  let previousSessionNode = null;
  if (props.previousSession) {
    previousSessionNode = (
      <div
        data-testid="previousSessionArrow"
        onClick={handlePreviousSessionClick}
        className="tc-arrow"
      />
    );
  }
  let nextSessionNode = null;
  if (props.nextSession) {
    nextSessionNode = (
      <div
        data-testid="nextSessionArrow"
        onClick={handleNextSessionClick}
        className="tc-arrow"
      />
    );
  }

  const startTime = props.activeSession.session_start_time_formatted;
  const endTime = props.activeSession.session_end_time_formatted;
  let sessionTime = null;
  let sessionSeperator = '';
  if (startTime && endTime) {
    sessionTime = (
      <React.Fragment>
        {Utils.hourRounder(moment(startTime))} -{' '}
        {Utils.hourRounder(moment(endTime))} {moment(startTime).format('A')}{' '}
        {moment.tz(props.timeZone).zoneAbbr()}
      </React.Fragment>
    );
    sessionSeperator = ', ';
  }

  const sessionStartTimeNode = startTime ? (
    <div>
      {moment(startTime).format('MMMM D, YYYY')}
      {sessionSeperator}
      {sessionTime}
    </div>
  ) : null;

  const bannerVisibleClass = props.sessionsBannerVisible
    ? 'event__attendee-sessions-banner--in'
    : '';
  return (
    <div className={`event__attendee-sessions-banner ${bannerVisibleClass}`}>
      <div className="event__attendee-sessions-banner-arrow event__attendee-sessions-banner-arrow--previous">
        {previousSessionNode}
      </div>
      <div className="event__attendee-sessions-banner-copy">
        <div className="event__attendee-sessions-banner-headline">
          {props.activeSession.name}
        </div>
        {sessionStartTimeNode}
      </div>
      <div className="event__attendee-sessions-banner-arrow event__attendee-sessions-banner-arrow--next">
        {nextSessionNode}
      </div>
    </div>
  );
};

export default EventAttendeeStickyNav;

EventAttendeeStickyNav.propTypes = {
  previousSession: PropTypes.object,
  nextSession: PropTypes.object,
  sessionsBannerVisible: PropTypes.bool.isRequired,
  activeSession: PropTypes.object,
  handeSetActiveSession: PropTypes.func,
};
