// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import NavigationLink from './../../../NavigationLink';
import Breadcrumbs from './Breadcrumbs';

const LevelThree = (props) => {
  const breadcrumbNode = (
    <Breadcrumbs
      openMenu={props.openMenu}
      openDomainDropdown={props.openDomainDropdown}
      domainDropdownActive={props.ui.domainDropdownActive}
      grade={props.standards_browser.selected_core_grade}
      domain={props.standards_browser.selected_core_domain}
      domains={props.standards_browser.core_domains}
    />
  );
  const childrensNode = props.activeStandard.CommonCore.children.map(
    (child) => {
      let childsChildrenNode;
      if (child.CommonCore.has_child_children) {
        const childsChildren = child.CommonCore.child_children.map(
          (childChild) => {
            return (
              <NavigationLink
                url={
                  process.env.REACT_APP_TEACHING_URL +
                  '/browse/common_core/standard/' +
                  childChild.CommonCore.id +
                  '/' +
                  childChild.CommonCore.seo_url +
                  '?from=standard_level1'
                }
                className="standards__child"
                key={'child-child-' + childChild.CommonCore.id}
              >
                <div className="standards__child-id">
                  <div className="search__tag">
                    {childChild.CommonCore.identifier}
                  </div>
                </div>
                <PurifiedHTML
                  className="standards__child-description"
                  content={childChild.CommonCore.description}
                />
                <div className="standards__child-count">
                  <div
                    className={`standards__child-count-icon tc-v4-${props.subject}`}
                  ></div>
                  <div className="standards__child-count-caption">
                    {childChild.CommonCore.lesson_count} Lessons
                  </div>
                </div>
              </NavigationLink>
            );
          }
        );
        childsChildrenNode = (
          <div className="standards__child-capsule bl4__capsule">
            {childsChildren}
          </div>
        );
      }

      return (
        <div key={child.CommonCore.id}>
          <h5 className="standard__child-headline">
            {child.CommonCore.description}
          </h5>
          {childsChildrenNode}
        </div>
      );
    }
  );
  const contentVisibleClass = !props.ui.megaMenuOpen
    ? ' standards__content--visible'
    : ' standards__content--hidden';
  return (
    <div className={'standards__content' + contentVisibleClass}>
      {breadcrumbNode}
      <div className="row row--1200 standards__results">{childrensNode}</div>
    </div>
  );
};

export default LevelThree;
