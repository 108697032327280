// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const ArtifactUpload = (props) => {
  let inputFiles = [];
  const files = {};
  let completedFiles = 0;
  let dropzone;
  let filesInput;

  const submitFiles = () => {
    props.submitFiles({ inputFiles: inputFiles });
  };

  const handleSelectFiles = (e) => {
    // Prevent propagation of the click event
    e.stopPropagation();
    props.handleSelectFiles();
  };

  const onDropHandler = (e) => {
    e.preventDefault();

    props.onDragLeave();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    inputFiles = files;
    completedFiles = 0;

    props.submitFiles({ inputFiles: inputFiles });
  };

  const handleArtifactsChange = (e) => {
    e.preventDefault();
    inputFiles = e.target.files;
    completedFiles = 0;

    submitFiles();
  };

  // control if uploader is blurred or not
  const blurClass = props.blur ? ' artifact__dropzone--blur' : '';
  const dragOverClass = props.dragOver ? ' artifact__dropzone--dragover' : '';

  // button for opening file system
  const fileSelectNode = (
    <div className="artifact__upload-target" key="artifact-upload">
      <div key="upload" onClick={handleSelectFiles}>
        <form>
          <input
            className="artifact__upload-field"
            ref={(ref) => (filesInput = ref)}
            type="file"
            onChange={handleArtifactsChange}
            multiple={props.multiple}
          />
        </form>
      </div>
    </div>
  );

  const errorNode = (
    <div className="tc__error tc__center-text artifact__upload-error">
      {props.error}
    </div>
  );

  // Mobile devices should see the camera option when selecting files
  const mobileUploadNode = (
    <div className="hide-desktop show-phone tc__center-text">
      <button
        href="#upload-dialog"
        className="artifact__upload artifact__upload--mobile bl4__btn bl4__btn--blue"
      >
        Add
        <div className="hide-desktop show-phone" key="artifact-upload-mobile">
          <div key="upload" onClick={handleSelectFiles}>
            <form>
              <input
                className="artifact__upload-field"
                ref={(ref) => (filesInput = ref)}
                type="file"
                onChange={handleArtifactsChange}
                multiple={props.multiple}
                accept="*"
              />
            </form>
          </div>
        </div>
      </button>
    </div>
  );

  // if the user is allowed to switch from file to link, show the button to do so
  let switchToLinkNode = null;
  if (props.canSwitchToLink) {
    switchToLinkNode = (
      <div className="tc__center-text">
        <span
          className="artifact-upload-v4__toggle-file-link"
          onClick={props.switchToLink}
        >
          <span className="tc-v4-link artifact-upload-v4__toggle-file-link-icon"></span>
          Share a link
        </span>
      </div>
    );
  }

  return (
    <div>
      <div className="artifact__wrapper hide-phone show-desktop tc__center-text">
        <div
          ref={(ref) => (dropzone = ref)}
          className={
            'cute-12-desktop artifact__dropzone artifact__dropzone--tml-v2' +
            dragOverClass +
            blurClass
          }
          onClick={props.handleSelectFiles}
          onDragLeave={props.onDragLeave}
          onDragExit={props.onDragLeave}
          onDragOver={props.onDragOver}
          onDragEnter={props.onDragOver}
          onDrop={onDropHandler}
        >
          <div className="tc-v4-upload-lg artifact__dropzone-icon"></div>
          <div className="hide-phone show-desktop artifact__dropzone-headline">
            Drag and Drop
          </div>
          <div className="hide-phone show-desktop artifact__prefer tc__body-copy">
            Your files here or{' '}
            <span className="artifact__dropzone-browse">Choose a file</span>
          </div>
          <div className="hide-phone show-desktop artifact__max-size tc__caption-copy">
            max file size: 1024MB
          </div>
          <div>{fileSelectNode}</div>
        </div>
      </div>

      {mobileUploadNode}
      {errorNode}

      {switchToLinkNode}
    </div>
  );
};

ArtifactUpload.propTypes = {
  submitFiles: PropTypes.func.isRequired,
  handleSelectFiles: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  switchToLink: PropTypes.func.isRequired,
  error: PropTypes.string,
  dragOver: PropTypes.bool,
  blur: PropTypes.bool,
  multiple: PropTypes.bool,
  canSwitchToLink: PropTypes.bool,
};

ArtifactUpload.defaultProps = {
  multiple: false,
  canSwitchToLink: true,
};

export default ArtifactUpload;
