// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Elements from './../Elements';

const Spotlights = (props) => {
  if (props.tab != 'spotlights') {
    return <noscript />;
  }

  // change active type tab
  const selectTypeTab = (e, id, name) => {
    e.preventDefault();
    props.changeDisplayedSpotlightType({
      spotlightTypeId: id,
      spotlightName: name,
    });
  };

  // default tabs and body
  const typeTabNodes = [];
  let activeType = '';
  let elementNodes = null;

  if (props.types) {
    // if there is no active tab, default to the first type
    let activeTypeId = props.displayedSpotlightTypeId
      ? props.displayedSpotlightTypeId
      : props.types[0].id;

    if (!props.displayedSpotlightTypeId) {
      for (const type of props.types) {
        let tabSelected = false;

        // check if any type entries are visible
        for (const entry of type.entries) {
          if (!entry.visible) {
            continue;
          }

          // stop loop we found a visible tab
          tabSelected = true;
          break;
        }

        // set tab as visible stop loop
        if (tabSelected) {
          activeTypeId = type.id;
          break;
        }
      }
    }

    // set up tabs
    props.types.forEach((type) => {
      // do not render a tab with no entries associated with it
      if (type.entries.length < 1) {
        return;
      }

      let visibleEntries = false;
      for (const entry of type.entries) {
        if (entry.visible) {
          visibleEntries = true;
          break;
        }
      }

      if (!visibleEntries) {
        return;
      }

      let activeClass = '';
      // get the type name to display
      const typeName = type.entry_type.replace('Spotlight', '');

      // if the type tab is active, show the elements in the body
      if (activeTypeId == type.id) {
        activeClass = ' active';
        activeType = typeName;
        elementNodes = type.entries && (
          <Elements
            showArtifact={props.showArtifact}
            entries={type.entries}
            tab={props.tab}
            portfolio_guid={props.portfolio_guid}
            showDetailed={true}
          />
        );
      }

      // add the type tab to the list
      typeTabNodes.push(
        <a
          className={
            'ppp__tab ppp__tab--spotlight-type ppp__tab--' +
            props.type.length +
            ' tc__link' +
            activeClass
          }
          onClick={(e) => {
            selectTypeTab(e, type.id, typeName);
          }}
          key={'category-tab-' + type.id}
        >
          {typeName == 'Quote' ? 'Testimonials' : typeName}
        </a>
      );
    });
  }

  return (
    <div className="ppp__section">
      <div className="ppp__tab--spotlight-types">{typeTabNodes}</div>
      {elementNodes}
    </div>
  );
};

Spotlights.propTypes = {
  tab: PropTypes.string.isRequired,
  displayedSpotlightTypeId: PropTypes.number,
  types: PropTypes.array,
};

export default Spotlights;
