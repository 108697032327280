// @ts-nocheck FIXME
import React from 'react';

class ArtifactsHeadlineV2 extends React.Component {
  render() {
    return (
      <div className="portfolio-v2__strategy-headline portfolio-v2__loop-section">
        Artifacts
      </div>
    );
  }
}

export default ArtifactsHeadlineV2;
