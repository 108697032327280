// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Utils from './../../../../components/DynamicUtils';

class PortfolioEntryReorder extends React.Component {
  constructor(props) {
    super(props);

    // get the browser specific transition end event
    this.browserSpecificTransitionEnd = Utils.whichTransitionEvent();

    this.state = {
      height: 0,
    };
  }

  componentDidMount() {
    this.handleSetElementHeight();
    window.addEventListener('resize', this.handleSetElementHeight.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSetElementHeight);
  }

  componentDidUpdate(prevProps) {
    // move has not been updated do nothing
    if (
      this.props.move.target &&
      !Utils.areObjectsEqual(this.props.move, prevProps.move) &&
      this.props.entryId == this.props.move.target.id
    ) {
      // add an event listener to clean up the work when completed
      document.addEventListener(
        this.browserSpecificTransitionEnd,
        this.handleCompleteMove.bind(this)
      );
      this.handleMoveAnimation();
    }
  }

  handleSetElementHeight() {
    // get the height of the child element
    const childElem = document.querySelector(
      '#p-portfolio-entry-container-' +
        this.props.containerId +
        ' .p-portfolio__movable-entry'
    );
    // set the state for the height of the container
    // the 30 extra px is to account for margin
    this.setState({
      height: childElem.offsetHeight + 20,
    });
  }

  handleMoveAnimation() {
    // get the target and destination elements that will switch the place
    const moveTarget = document.querySelector(
      '.p-portfolio__movable-entry--target'
    );
    const moveDestination = document.querySelector(
      '.p-portfolio__movable-entry--destination'
    );

    // no move target has been rendered yet dont go any further
    if (moveTarget == null || moveDestination == null) {
      return;
    }

    let targetMoveTo = 0;
    let destinationMoveTo = 0;
    // move direction is up move target elem up and destination elem down
    if (this.props.move.direction == 'up') {
      targetMoveTo =
        'translateY(-' + (moveDestination.offsetHeight + 30) + 'px)';
      destinationMoveTo =
        'translateY(' + (moveTarget.offsetHeight + 30) + 'px)';
    }

    // move direction is down move target elem down and destination elem up
    if (this.props.move.direction == 'down') {
      targetMoveTo =
        'translateY(' + (moveDestination.offsetHeight + 30) + 'px)';
      destinationMoveTo =
        'translateY(-' + (moveTarget.offsetHeight + 30) + 'px)';
    }

    this.props.animateElemPositions({
      targetMoveTo: targetMoveTo,
      destinationMoveTo: destinationMoveTo,
    });
  }

  handleCompleteMove(e) {
    // remove animation complete event
    document.removeEventListener(
      this.browserSpecificTransitionEnd,
      this.handleCompleteMove
    );

    // condition to ensure the event will only be called once
    if (Utils.hasClass(e.target, 'p-portfolio__movable-entry--target')) {
      // resolve the state and clean up animation
      this.props.completeMove();
    }
  }

  render() {
    const styles = {
      height: this.state.height,
      marginTop: '10px',
    };
    return (
      <div
        style={styles}
        id={'p-portfolio-entry-container-' + this.props.containerId}
        className="cute-6-desktop cute-12-phone phone-reset center-desktop p-portfolio-outcome-story-management__entry-cap"
      >
        {this.props.children}
      </div>
    );
  }
}

PortfolioEntryReorder.propTypes = {
  move: PropTypes.object.isRequired,
  page: PropTypes.string,
  containerId: PropTypes.string.isRequired,
  completeMove: PropTypes.func.isRequired,
  animateElemPositions: PropTypes.func.isRequired,
};

export default PortfolioEntryReorder;
