// @ts-nocheck FIXME
import TimeAgo from 'react-timeago';
import WindowHelpers from './../../components/WindowHelpers';

const BLLogs = (props) => {
  const queryStringParams = ['category', 'severity', 'user', 'created-after'];

  let headerString = 'You are looking for ';
  queryStringParams.forEach((param) => {
    const filter = WindowHelpers.getQueryStringParam(param);
    if (filter) {
      headerString += param + ' : ' + filter;
    }
  });

  const parseMessageObject = (message) => {
    const fields = [];
    for (const key in message) {
      if (message.hasOwnProperty(key)) {
        fields.push(
          <div key={key}>
            {key}: {message[key]}
          </div>
        );
      }
    }
    return <div>{fields}</div>;
  };

  const rows = props.log_entries.map((logEntry) => {
    return (
      <tr className="admin-table__row" key={logEntry.id}>
        <td className="admin-table__col">{logEntry.id}</td>
        <td className="admin-table__col">{logEntry.user_id}</td>
        <td className="admin-table__col">{logEntry.category}</td>
        <td className="admin-table__col">{logEntry.severity}</td>
        <td className="admin-table__col">
          {parseMessageObject(logEntry.message)}
        </td>
        <td className="admin-table__col">
          <TimeAgo date={logEntry.created} />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="row coach__header">
        <div className="cute-12-desktop">
          <h2 className="coach__header-firstrow coach__headline titleCase">
            {headerString}
          </h2>
        </div>
      </div>

      <div className="row">
        <table className="admin-table">
          <tbody>
            <tr>
              <th className="admin-table__header">ID</th>
              <th className="admin-table__header">User ID</th>
              <th className="admin-table__header">Category</th>
              <th className="admin-table__header">Severity</th>
              <th className="admin-table__header">Message</th>
              <th className="admin-table__header">Created</th>
            </tr>
            {rows}
          </tbody>
        </table>
      </div>
    </div>
  );
};

BLLogs.propTypes = {};

export default BLLogs;
