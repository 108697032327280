// @ts-nocheck FIXME
import Elements from './../../Elements';

const GrowthArea = (props) => {
  const selectGrowthArea = (e) => {
    e.preventDefault();
    if (props.entries.some((entry) => entry.visible)) {
      props.changeDisplayedGA({ gaId: props.growth_area_id });
    } else {
      props.changeDisplayedGA({ gaId: null });
    }
  };

  const graphLabel = props.amplitude_value == 1 ? 'strategy' : 'strategies';
  const visibleEntriesClass = props.growthAreaActive ? ' active' : ' inactive';
  const hoverClass = props.entries.some((entry) => entry.visible)
    ? ' clickable'
    : '';

  // count all the different entry types in this growth area
  const spotlightCounts = {};
  props.entries.forEach((entry) => {
    // only count visible entries
    if (!entry.visible) {
      return;
    }
    if (entry.type in spotlightCounts) {
      spotlightCounts[entry.type] += 1;
    } else {
      spotlightCounts[entry.type] = 1;
    }
  });

  const spotlightIconNodes = Object.keys(spotlightCounts).map((type) => {
    // determine the correct icon to show
    let iconType;
    switch (type) {
      case 'ArtifactSpotlight':
        iconType = 'artifact';
        break;
      case 'NarrativeSpotlight':
        iconType = 'narrative';
        break;
      case 'TeacherSpotlight':
        iconType = 'teacher';
        break;
      case 'StrategySpotlight':
        iconType = 'strategy';
        break;
      case 'CoachingSpotlight':
        iconType = 'play';
        break;
      default:
        iconType = type.toLowerCase();
    }
    // if there is more than one of this type, show the count next to the icon
    let countNode = null;
    if (spotlightCounts[type] > 1) {
      countNode = (
        <div className="ppp__growth-area-spotlight-count">
          {spotlightCounts[type]}
        </div>
      );
    }
    return (
      <div
        key={type}
        className={
          'tc-partner-portfolio-' +
          iconType +
          '-icon ppp__growth-area-spotlight-icon'
        }
      >
        {countNode}
      </div>
    );
  });

  // the div with all the spotlight type icons is supposed to be up to two rows tall
  // and expand to the right as the # of icons grows,
  // so we need to calculate the width of the div based on the # of icons to show
  const spotlightIconAreaWidth = Math.ceil(spotlightIconNodes.length / 2) * 47;

  // if the bar is too short for the value text to fit (width around 19%),
  // then we need to reorganize things to move the text to the right of the bar
  let barNode = null;
  if (props.highestCategoryAmplitude > 19) {
    barNode = (
      <div className="ppp__growth-area-amplitude cute-8-desktop left">
        <div
          className="ppp__growth-area-amplitude-graph"
          style={{ width: props.highestCategoryAmplitude + '%' }}
        >
          <div className="ppp__growth-area-amplitude-graph-details">
            <div className="ppp__growth-area-amplitude-value">
              {props.amplitude_value}
            </div>
            <div className="ppp__growth-area-amplitude-caption">
              {graphLabel} tried
            </div>
          </div>
          <div
            className="ppp__growth-area-spotlight-icons"
            style={{
              width: spotlightIconAreaWidth,
              right: -1 * (spotlightIconAreaWidth + 18),
            }}
          >
            {spotlightIconNodes}
          </div>
        </div>
      </div>
    );
  } else {
    barNode = (
      <div className="ppp__growth-area-amplitude cute-8-desktop left">
        <div
          className="ppp__growth-area-amplitude-graph ppp__growth-area-amplitude-graph--short"
          style={{ width: props.highestCategoryAmplitude + '%' }}
        ></div>
        <div className="ppp__growth-area-amplitude-graph-details ppp__growth-area-amplitude-graph-details--short-bar">
          <div className="ppp__growth-area-amplitude-value">
            {props.amplitude_value}
          </div>
          <div className="ppp__growth-area-amplitude-caption">
            {graphLabel} tried
          </div>
        </div>
        <div
          className="ppp__growth-area-spotlight-icons ppp__growth-area-spotlight-icons--short-bar"
          style={{ width: spotlightIconAreaWidth }}
        >
          {spotlightIconNodes}
        </div>
      </div>
    );
  }

  return (
    <div className={'ppp__growth-area' + visibleEntriesClass}>
      <div className={'row' + hoverClass} onClick={selectGrowthArea}>
        <div className="ppp__growth-area-details cute-2-desktop">
          <div className="ppp__growth-area-icon">
            <div className={'tc-sga-nb-' + props.growth_area_id}></div>
          </div>
          <div className="ppp__growth-area-name">{props.name}</div>
        </div>
        {barNode}
      </div>
      <div className={'ppp__growth-area-entries' + visibleEntriesClass}>
        <Elements
          showArtifact={props.showArtifact}
          entries={props.entries}
          tab={props.tab}
          portfolio_guid={props.portfolio_guid}
        />
      </div>
    </div>
  );
};

export default GrowthArea;
