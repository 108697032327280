import RedirectHelper from '../../../../components/RedirectHelper';

type Props = {
  cohort: {
    org_uuid: string;
  };
};

const AccountLoginForm = (props: Props): JSX.Element => {
  const orgUuid = props.cohort.org_uuid ? props.cohort.org_uuid : null;

  RedirectHelper.redirectToUserManagementWithRedirectSet('/login', orgUuid);
  return <></>;
};

export default AccountLoginForm;
