// @ts-nocheck FIXME
const EventsNoneToday = () => {
  return (
    <div className="coach-event-none v5__capsule">
      <div className="coach-event-none__content flex-row">
        <div className="coach-event-none__image-container">
          <img
            className="coach-event-none__image"
            src="/legacy-images/v5/BL-Lab-NoActivity.png"
            alt="no events today"
          />
        </div>
        <div>
          <div className="coach-event-none__text v5__heading-4">
            Take a break
          </div>
          <div className="coach-event-none__text v5__body-copy">
            You have no events scheduled today.
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsNoneToday;
