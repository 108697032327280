// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const AUTOSAVE_DELAY = 500; // 0.5 seconds
let autosaveTimeout;

import Analytics from './../../components/Analytics';

const CoverSection = (props) => {
  let primaryHeading = props.section.entries[0].data['primary_heading'];
  let primarySubheading = props.section.entries[0].data['primary_subheading'];
  let secondaryHeading = props.section.entries[0].data['secondary_heading'];
  let secondarySubheading =
    props.section.entries[0].data['secondary_subheading'];
  const showIcon =
    props.saveIcon === 0 ? 'ppt-cover__not-saved' : 'ppt-cover__saved';

  const postAnalytics = (field) => {
    Analytics.logEvent({
      category: 'pp',
      action: field,
      label: 'from_pp_admin_view',
    });
  };

  // set a timer to save data each time the input is changed
  const updateSaveStatus = () => {
    // removes saved message
    if (props.saveIcon === 1) {
      props.toggleSaveIcon({ value: 0 });
    }

    if (
      primaryHeading &&
      secondaryHeading &&
      primarySubheading &&
      secondarySubheading
    ) {
      const primaryHeadingVal = primaryHeading.value;
      const secondaryHeadingVal = secondaryHeading.value;
      const primarySubheadingVal = primarySubheading.value;
      const secondarySubheadingVal = secondarySubheading.value;

      // delay timer until they are finished typing
      clearTimeout(autosaveTimeout);

      // set a timer that saves the data after a certain amount of time
      autosaveTimeout = setTimeout(() => {
        // collect data
        const data = {
          primary_heading: primaryHeadingVal,
          secondary_heading: secondaryHeadingVal,
          primary_subheading: primarySubheadingVal,
          secondary_subheading: secondarySubheadingVal,
        };
        // call the API
        props.editEntry({
          entryId: props.section.entries[0].id,
          entryData: { data: data },
        });
        // show the "Saved" message
        props.toggleSaveIcon({ value: 1 });
      }, AUTOSAVE_DELAY);
    }
  };

  // rendering
  return (
    <div className="ppt-section">
      <div className="">
        <h1 className="tc__headline-2 ppt-cover__top-bar">Cover</h1>
        <div className={'ppt-cover__save ' + showIcon}>
          <div className="tc-check ppt-cover__top-bar" />
          <div className="ppt-cover__top-bar">Saved</div>
        </div>
      </div>

      <div className="cute-6-desktop">
        <div className="cute-5-desktop">
          <div className="ppt-cover__input-label">Primary Heading:</div>
          <div className="ppt-cover__input-label">Primary Subheading:</div>
          <div className="ppt-cover__input-label">Secondary Heading:</div>
          <div className="ppt-cover__input-label">Secondary Subheading:</div>
        </div>
        <div className="cute-7-desktop">
          <div className="ppt-cover__form-row">
            <input
              id="primary_heading"
              type="text"
              ref={(ref) => (primaryHeading = ref)}
              className="tc__input ppt-cover__input"
              defaultValue={primaryHeading}
              onFocus={(e) => postAnalytics('edit_primary_heading')}
              onChange={updateSaveStatus}
            />
          </div>
          <div className="ppt-cover__form-row">
            <input
              type="text"
              ref={(ref) => (primarySubheading = ref)}
              className="tc__input ppt-cover__input"
              defaultValue={primarySubheading}
              onFocus={(e) => postAnalytics('edit_primary_subheading')}
              onChange={updateSaveStatus}
            />
          </div>
          <div className="ppt-cover__form-row">
            <input
              type="text"
              className="tc__input ppt-cover__input"
              ref={(ref) => (secondaryHeading = ref)}
              defaultValue={secondaryHeading}
              onFocus={(e) => postAnalytics('edit_secondary_heading')}
              onChange={updateSaveStatus}
            />
          </div>
          <div className="ppt-cover__form-row">
            <input
              type="text"
              className="tc__input ppt-cover__input"
              ref={(ref) => (secondarySubheading = ref)}
              defaultValue={secondarySubheading}
              onFocus={(e) => postAnalytics('edit_secondary_subheading')}
              onChange={updateSaveStatus}
            />
          </div>
        </div>
      </div>
      <div className="cute-6-desktop ppt-cover__image-container">
        <img
          className="ppt-cover__image"
          src="/legacy-images/partner-portfolio-cover-image.jpg"
        />
        <div className="ppt-cover-example">
          <div className="tc__headline-5 ppt-cover__heading">
            {props.section.entries[0].data['primary_heading']}
          </div>
          <div className="tc__headline-6 ppt-cover__subheading1">
            {props.section.entries[0].data['primary_subheading']}
          </div>
          <div className="tc__headline-5 ppt-cover__heading">
            {props.section.entries[0].data['secondary_heading']}
          </div>
          <div className="tc__headline-6 ppt-cover__subheading2">
            {props.section.entries[0].data['secondary_subheading']}
          </div>
        </div>
      </div>
    </div>
  );
};

CoverSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default CoverSection;
