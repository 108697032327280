// @ts-nocheck FIXME
import TimeDelta from '@legacy-components/react/V5/Common/TimeDelta';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const AutoSave = (props) => {
  const [lastSaved, setLastSaved] = useState(moment());
  // There are 2 types of saving, interval which saves while typing and delay which saves after typing
  // These both need to be handled by react so they aren't reset on rerender, but useState isn't appropriate
  // Major difference between using useRef this way and useState is that updating the value of a ref doesn't cause a re-render
  // But still persists between renders
  const delaySave = useRef(null);
  const intervalSave = useRef(null);
  const delay = props.delay || 1200;
  const interval = props.interval || 5000;
  useEffect(() => {
    // This will get rid of the timeout on cleanup
    return () => {
      props.resetChanged();
      clearTimeout(delaySave.current);
      clearTimeout(intervalSave.current);
    };
  }, []);
  let displayValue;
  if (props.isLoading) {
    // If we're currently saving let them know
    displayValue = 'Saving';
  } else {
    // Let the user know how long ago the last save was
    displayValue = (
      <React.Fragment>
        Last saved <TimeDelta datetime={lastSaved} />
      </React.Fragment>
    );
  }

  // If saving succeeds update the time and do any callbacks
  const onSaveComplete = () => {
    setLastSaved(moment());
    if (props.successCallback) {
      props.successCallback();
    }
  };

  const autoSave = () => {
    // Clear the timeouts and reset the state so a new timeout won't be created right away
    props.resetChanged();
    clearTimeout(delaySave.current);
    clearTimeout(intervalSave.current);
    intervalSave.current = null;
    delaySave.current = null;
    if (!props.isLoading) {
      props.save(onSaveComplete);
    }
  };

  // Once a change has happened we want to save every ten seconds
  // even if their connection cuts out they still save some work
  // we also want to set a timer that fires more quickly after they're done typing
  if (props.changed) {
    props.resetChanged();
    clearTimeout(delaySave.current);
    delaySave.current = setTimeout(autoSave, delay);
    if (!intervalSave.current) {
      intervalSave.current = setInterval(autoSave, interval);
    }
  }
  return <div className="v5__autosave">{displayValue}</div>;
};

AutoSave.propTypes = {
  changed: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  successCallback: PropTypes.func,
  isLoading: PropTypes.bool,
  resetChanged: PropTypes.func.isRequired,
  interval: PropTypes.number,
  delay: PropTypes.number,
};

export default AutoSave;
