// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment';
import propTypes from 'prop-types';
import Meetings from './Meetings';
import StatusAndNotes from './StatusAndNotes';

const Cohort = (props) => {
  const timeZone = props.timeZone;

  // if the engagement is archived, then disable all editable fields across components
  const isArchived = !Utils.isCoachingEngagementActive(
    props.cohort.current_status
  );

  const StatusAndNotesNode = (
    <StatusAndNotes
      participantNotes={props.cohort.participant_notes}
      participantStatus={props.cohort.current_status}
      userId={props.user.id}
      timeZone={props.timeZone}
      isArchived={isArchived}
      canEditData={props.canEditData}
      isAdmin={props.isAdmin}
      isPSM={props.isPSM}
      isBlCoach={props.isBlCoach}
      updateStatus={props.setStatusUpdating}
      setEditNote={props.setEditNote}
      setAddingNote={props.setAddingNote}
    />
  );

  const meetingsHeld = props.cohort.meetings.filter(
    (meeting) => meeting.status === 2
  ).length;
  const meetingsMissed = props.cohort.meetings.filter(
    (meeting) => meeting.status === 4
  ).length;
  const meetingEmailObject = {
    setCancellation: props.setCancellation,
    setNoShow: props.setNoShow,
    setMeetingObject: props.setMeetingObject,
  };

  const cohortStartDate = moment(
    props.cohort.cohort.term_start_formatted
  ).format('M/DD/YY');
  const cohortEndDate = moment(props.cohort.cohort.term_end_formatted).format(
    'M/DD/YY'
  );

  return (
    <div
      className="show-desktop hide-phone participant-v5"
      style={{ marginBottom: '100px' }}
    >
      <div>
        <Meetings
          cohortName={props.cohort.cohort.display_name}
          cohortId={props.cohort.cohort_id}
          cohortStartDate={cohortStartDate}
          cohortEndDate={cohortEndDate}
          firstName={props.participantUser.first_name}
          cohortProduct={props.cohort.product}
          participantId={props.participantId}
          coachingEngagementId={props.cohort.id}
          meetings={props.cohort.meetings}
          status={props.status}
          userTimezone={timeZone}
          validStatuses={props.cohort.valid_statuses}
          meetingsHeld={meetingsHeld}
          meetingsMissed={meetingsMissed}
          meetingEmailObject={meetingEmailObject}
          isInternalCoach={props.isInternalCoach}
          canEditData={props.canEditData}
          isArchived={isArchived}
          updateMeetingStatus={props.updateMeetingStatus}
        />
      </div>
      <div className="participant-v5__section">{StatusAndNotesNode}</div>
    </div>
  );
};

Cohort.propTypes = {
  user: propTypes.object.isRequired,
  cohort: propTypes.object.isRequired,
  canEditData: propTypes.bool.isRequired,
  participantUser: propTypes.object.isRequired,
  participantId: propTypes.number.isRequired,
  timeZone: propTypes.string.isRequired,
  isInternalCoach: propTypes.bool.isRequired,
  isBlCoach: propTypes.bool.isRequired,
  isPSM: propTypes.bool.isRequired,
  isAdmin: propTypes.bool.isRequired,
  setEditNote: propTypes.func.isRequired,
  setStatusUpdating: propTypes.func.isRequired,
  setAddingNote: propTypes.func.isRequired,
  updateMeetingStatus: propTypes.func.isRequired,
  setNoShow: propTypes.func.isRequired,
  setMeetingObject: propTypes.func.isRequired,
  setCancellation: propTypes.func.isRequired,
};

export default Cohort;
