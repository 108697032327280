// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import ZenScroll from 'zenscroll';
import FavoriteApi from './../../apis/V4/FavoriteApi';
import Analytics from './../../components/Analytics';
import { History } from './../../components/Router';
import Store from './../../components/Store';

const subjectToPath = {
  math: 'common_core_math',
  ela: 'common_core_ela',
  science: 'next_gen_science',
  blended: 'blended',
};

const store = {
  standards_browser: {},
  activeStandard: null,
  ui: {
    alert: {},
    participants: {
      inputStatus: 'blur',
      charCount: 0,
      selectedOption: null,
      options: [],
      visibleOptions: [],
    },
    megaMenuOpen: true,
    activeSubject: 'math',
    domainDropdownActive: false,
    coreDropdownActive: false,
    blendedDropdownActive: false,
    expandedMobileStandardsBlock: null,
  },
};

store.init = (data) => {
  store.activeStandard = null;
  store.ui.megaMenuOpen = true;
  store.ui.activeSubject = data.subject || 'math';

  store.standards_browser = data.standards_browser;
  store.ui.alert = {};

  if (data.standards_browser.core) {
    let subjectName;
    store.activeStandard = data.standards_browser.core;

    store.ui.megaMenuOpen = false;
    data.standards_browser.subject_options.forEach((subject) => {
      if (
        subject.Subject.id != data.standards_browser.core.CommonCore.core_id
      ) {
        return;
      }
      subjectName = subject.Subject.name;
    });
  } else if (data.blended_browser.BlendedTopic) {
    store.ui.megaMenuOpen = false;
  }

  return false;
};

store.openMenu = (data) => {
  store.ui.activeSubject = data.subject;
  store.ui.megaMenuOpen = true;
  History.replace(
    null,
    document.title,
    '/browse/' + subjectToPath[data.subject]
  );
};
store.closeMenu = (data) => {
  store.ui.activeSubject = data.subject;
  store.ui.megaMenuOpen = false;
};
store.openDomainDropdown = () => {
  Analytics.logEvent({
    category: 'standard_level_1',
    action: 'open',
    label: 'from_breadcrumb_standard_level1',
  });
  store.ui.domainDropdownActive = true;
  // add listner to close the dropdown from anywhere click

  delayedEventListener(() =>
    document.addEventListener('click', store.closeDomainDropdown)
  );
};
store.closeDomainDropdown = () => {
  Analytics.logEvent({
    category: 'standard_level_1',
    action: 'close',
    label: 'from_breadcrumb_standard_level1',
  });
  store.ui.domainDropdownActive = false;
  // remove listner to close the dropdown from anywhere click
  document.removeEventListener('click', store.closeDomainDropdown);
};
store.openCoreDropdown = () => {
  store.ui.coreDropdownActive = true;
  // add listner to close the dropdown from anywhere click

  delayedEventListener(() =>
    document.addEventListener('click', store.closeCoreDropdown)
  );
};
store.closeCoreDropdown = () => {
  store.ui.coreDropdownActive = false;
  // remove listner to close the dropdown from anywhere click
  document.removeEventListener('click', store.closeCoreDropdown);
};
store.handleScrollToChildren = () => {
  Analytics.logEvent({
    category: 'child_tags',
    action: 'scroll',
    label: 'from_standard_level2',
  });
  const childTags = document.querySelector('.standards__child--level-2');
  // on mobile, we need to account for the extra height of the sticky header
  ZenScroll.setup(600);
  ZenScroll.to(childTags);
};
store.expandMobileStandardsBlock = (data) => {
  if (store.ui.expandedMobileStandardsBlock == data.blockId) {
    store.ui.expandedMobileStandardsBlock = null;
    return;
  }
  store.ui.expandedMobileStandardsBlock = data.blockId;
};
store.openBlendedDropdown = (data) => {
  Analytics.logEvent({
    category: 'blended_topic',
    action: 'open',
    label: 'from_breadcrumb_blended',
  });
  store.ui.blendedDropdownActive = true;
  // add listner to close the dropdown from anywhere click

  delayedEventListener(() =>
    document.addEventListener('click', store.closeBlendedDropdown)
  );
};
store.closeBlendedDropdown = (data) => {
  Analytics.logEvent({
    category: 'blended_topic',
    action: 'hide',
    label: 'from_breadcrumb_blended',
  });
  store.ui.blendedDropdownActive = false;
  // remove listner to close the dropdown from anywhere click
  document.removeEventListener('click', store.closeBlendedDropdown);
};

store.addFavorite = (data) => {
  console.log('add FAv', data);
  if (data.recommend) {
    data.userId = store.ui.participants.selectedOption.user_id;
  } else {
    // setup page context for analytics
    // let analyticsLabel =
    //     store.searchContext == 'favorites'
    //         ? 'from_favorites'
    //         : 'from_search_results';
    //
    // Analytics.logEvent({
    //     category: data.itemType,
    //     action: 'favorite',
    //     label: analyticsLabel
    // });
  }
  let resultIndex;
  for (let i = 0; i < store.activeStandard.CommonCore.lessons; i++) {
    if (
      data.itemId != store.activeStandard.CommonCore.lessons[i].Lesson['id']
    ) {
      continue;
    }
    resultIndex = i;
  }
  // let resultIndex = Utils.getItemIndexFromArrayOfObjects(
  //     store.activeStandard.CommonCore.lessons,
  //     'id',
  //     data.itemId
  // );
  store.activeStandard.CommonCore.lessons[resultIndex].favorite = {};
  store.activeStandard.CommonCore.lessons[resultIndex].favorite.status =
    'loading-favorite';

  return FavoriteApi.addFavorite(data)
    .then((response) => {
      if (data.recommend) {
        store.ui.participants.charCount = 0;
        store.ui.participants.selectedOption = null;
        store.ui.participants.visibleOptions = [];
        store.ui.participants.inputStatus = 'blur';
        store.ui.recommendModal.close();
        return;
      }
      store.activeStandard.CommonCore.lessons[resultIndex].favorite_count++;
      store.activeStandard.CommonCore.lessons[resultIndex].favorite =
        response.payload;
    })
    .catch((response) => {
      store.activeStandard.CommonCore.lessons[resultIndex].favorite = {};
    });
};

store.removeFavorite = (data) => {
  let resultIndex;
  for (let i = 0; i < store.activeStandard.CommonCore.lessons; i++) {
    if (
      data.itemId != store.activeStandard.CommonCore.lessons[i].Lesson['id']
    ) {
      continue;
    }
    resultIndex = i;
  }
  // let resultIndex = Utils.getItemIndexFromArrayOfObjects(
  //     store.activeStandard.CommonCore.lessons,
  //     'id',
  //     data.itemId
  // );
  store.activeStandard.CommonCore.lessons[resultIndex].favorite.status =
    'loading-unfavorite';

  // setup page context for analytics
  // let analyticsLabel =
  //     store.searchContext == 'favorites'
  //         ? 'from_favorites'
  //         : 'from_search_results';
  //
  // Analytics.logEvent({
  //     category: data.itemType,
  //     action: 'unfavorite',
  //     label: analyticsLabel
  // });

  return FavoriteApi.removeFavorite(data)
    .then((response) => {
      store.activeStandard.CommonCore.lessons[resultIndex].favorite = null;
      store.activeStandard.CommonCore.lessons[resultIndex].favorite_count--;
    })
    .catch((response) => {
      store.activeStandard.CommonCore.lessons[resultIndex].favorite.status ==
        'selected';
    });
};

export default Store('BrowserStore', store);
