// @ts-nocheck FIXME
import NavigationLink from './../../../NavigationLink';

const Breadcrumbs = (props) => {
  const breadcrumbsNode = props.breadcrumbs.map((breadcrumbs) => {
    let activeCrumb;
    const dropdownItemsNode = breadcrumbs.map((item) => {
      let selectedClass = '';
      if (item.selected) {
        selectedClass = ' selected';
        activeCrumb = item;
      }
      return (
        <li className={selectedClass} key={'blended-dropdown-' + item.tc_id}>
          <NavigationLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/blended_topic/' +
              item.tc_id +
              '/' +
              item.seo_url
            }
          >
            <span>{item.name}</span>
          </NavigationLink>
        </li>
      );
    });
    const blendedDropdownClass = props.siblingDropdownActive ? ' active' : '';
    const blendedDropdownNode = (
      <div className={'mtp-lesson__crumb-dropdown' + blendedDropdownClass}>
        <ul>{dropdownItemsNode}</ul>
      </div>
    );

    return (
      <div className="mtp-lesson__crumb" key={'activeCrumb-' + activeCrumb.id}>
        <a
          onClick={(e) => {
            e.preventDefault();
            props.toggleSiblingsDropdown();
          }}
        >
          <span className="mtp-lesson__crumb-caption">{activeCrumb.name}</span>
          <span className="mtp-lesson__crumb-icon tc-bold-arrow"></span>
        </a>
        {blendedDropdownNode}
        <div className="mtp-lesson__crumb-border"></div>
      </div>
    );
  });

  return (
    <div className="mtp-lesson__breadcumb-wrapper show-desktop hide-phone">
      <div className="row row--1200">
        <div className="mtp-lesson__crumb">
          <NavigationLink>
            <span className="mtp-lesson__crumb-caption">Blended Learning</span>
          </NavigationLink>
          <div className="mtp-lesson__crumb-border"></div>
        </div>
        {breadcrumbsNode}
        <div className="mtp-lesson__crumb">
          <a>
            <span className="mtp-lesson__crumb-caption">
              {props.implementationTitle}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
