// @ts-nocheck FIXME
import ChallengeMetaStrategiesV2 from './ChallengeMetaStrategies';
import ChallengeStrategiesV2 from './ChallengeStrategies';

const ChallengeConditional = (props) => {
  if (props.metaStrategies && props.metaStrategies.length > 0) {
    return <ChallengeMetaStrategiesV2 {...props} />;
  }
  return <ChallengeStrategiesV2 {...props} />;
};

export default ChallengeConditional;
