// @ts-nocheck FIXME
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import PropTypes from 'prop-types';

const ConfirmUndeliverSessionModal = (props) => {
  return (
    <ActionModal
      headlineText="Mark as Not Delivered?"
      close={props.close}
      spaceFromTop={50}
    >
      <div className="v5__body-copy edit-event__modal-row">
        After this status is applied, it is permanent. This action cannot be
        undone.
      </div>
      <div className="edit-event__modal-btn-row edit-event__modal-btn-row--centered">
        <button className="v5__btn v5__btn--blue-border" onClick={props.close}>
          Cancel
        </button>
        <button
          className="v5__btn edit-event__confirm-status-btn--left-margin"
          onClick={() => props.confirm(props.data)}
        >
          Change Status
        </button>
      </div>
    </ActionModal>
  );
};

ConfirmUndeliverSessionModal.propTypes = {
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default ConfirmUndeliverSessionModal;
