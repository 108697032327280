// @ts-nocheck FIXME
import React from 'react';
import LayerManager from './../../components/LayerManager';

class LayerLink extends React.Component {
  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // conditionally call the appropriate manager method
    if (this.props.layer == 'new') {
      LayerManager.new(
        this.props.component,
        this.props.params,
        this.props.wrapper
      );
    } else if (this.props.layer == 'current') {
      LayerManager.current(
        this.props.component,
        this.props.params,
        this.props.wrapper
      );
    } else {
      LayerManager.target(
        this.props.layer,
        this.props.component,
        this.props.params,
        this.props.wrapper
      );
    }

    // If the parent povided an onClick event, fire it:
    if (this.props.onClick && typeof this.props.onClick === 'function') {
      this.props.onClick();
    }
  };

  render() {
    let classNames = 'react-layer-open';
    if (this.props.className) {
      classNames += ' ' + this.props.className;
    }
    let id = null;
    if (this.props.id) {
      id = this.props.id;
    }
    return (
      <a
        id={id}
        className={classNames}
        href="#open-layer"
        title={this.props.title}
        onClick={this.handleClick}
      >
        {this.props.children}
      </a>
    );
  }
}

export default LayerLink;
