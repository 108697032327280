// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import FormField from './../../../../Common/FormField';
import Spinner from './../../../../Common/Spinner';
import ConfirmDelete from './../../ConfirmDelete';
import SelectAuthorDropdown from './SelectAuthorDropdown';

const EditCommentContainer = (props) => {
  const [newAuthorId, updateAuthorId] = useState(
    props.author ? props.author.id : props.user.id
  );
  const [textInputValue, updateTextInputValue] = useState(props.comment);
  const [isConfirmDelete, updateConfirmDelete] = useState(false);

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFocus = () => {
    if (error) {
      setError('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // comment text is required
    if (!textInputValue) {
      setError('Hold on, you need to enter a comment to continue.');
      return;
    }

    setIsLoading(true);

    // build API data
    const data = {
      storyId: props.storyId,
      entryId: props.entryId,
      index: props.index,
      comment: textInputValue,
    };
    if (props.canEditAuthor && newAuthorId) {
      data.authorId = newAuthorId;
    }

    // if there is an error submitting to the API, leave the form open to prevent losing work
    const errorCallback = () => setIsLoading(false);

    props.addComment(data, errorCallback);
  };

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  // only show the delete icon when editing a comment, not when adding a new comment
  let deleteIconNode;
  if (props.deleteComment) {
    deleteIconNode = (
      <span
        onClick={() => updateConfirmDelete(true)}
        className="tc-delete workspace__delete-icon"
      ></span>
    );
  }

  // by default, show the comment form
  let bodyNode = (
    <form className="workspace__comment-form">
      <SelectAuthorDropdown
        user={props.user}
        pageUser={props.pageUser}
        author={props.author}
        newAuthorId={newAuthorId}
        updateAuthorId={updateAuthorId}
        canEditAuthor={props.canEditAuthor}
      />

      <FormField
        type="textarea"
        rows="4"
        placeholder="Leave a comment"
        defaultValue={props.comment}
        autoFocus={true}
        onChange={(e) => updateTextInputValue(e.target.value)}
        onFocus={handleFocus}
        error={error}
      />

      <div className="workspace__btns-row">
        <button
          type="button"
          className="v5__btn v5__btn--blue-border workspace__cancel-link"
          onClick={() => props.cancelComment()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="v5__btn"
          {...btnProps}
          onClick={handleSubmit}
        >
          <Spinner loading={isLoading} />
          Save
        </button>
        {deleteIconNode}
      </div>
    </form>
  );

  // if the delete icon is clicked, we instead show the delete confirmation
  if (isConfirmDelete) {
    bodyNode = (
      <div className="workspace__comment-form">
        <ConfirmDelete
          headlineText="Delete comment?"
          bodyText="It will be permanently deleted. Don’t worry, other comments will not be affected."
          delete={() =>
            props.deleteComment({
              entryId: props.entryId,
              storyId: props.storyId,
            })
          }
          cancel={() => updateConfirmDelete(false)}
        />
      </div>
    );
  }

  return bodyNode;
};

EditCommentContainer.propTypes = {
  author: PropTypes.object,
  user: PropTypes.object.isRequired,
  pageUser: PropTypes.object.isRequired,
  entryId: PropTypes.number,
  storyId: PropTypes.number,
  addComment: PropTypes.func.isRequired,
  cancelComment: PropTypes.func.isRequired,
};

export default EditCommentContainer;
