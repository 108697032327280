// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';

const PastMeetingRow = (props) => {
  // Check to see whether it's a meeting or an event
  const isEvent = !!props.meeting.sessions;
  let iconClass = 'tc-v5-meeting-missed';
  if (props.meeting.status === 2) {
    iconClass = 'tc-v5-meeting-complete';
  } else if (props.meeting.cb_id) {
    iconClass = 'tc-v5-event-product home-v5__event-color';
  }

  let descriptionText =
    props.meeting.display_name ||
    `Meeting with ${props.coach ? props.coach.first_name : 'your coach'}`;
  const startTime =
    props.meeting.meeting_time_formatted ||
    props.meeting.sessions[0].session_start_time_formatted ||
    props.meeting.sessions[0].session_date_formatted;
  const startTimeMoment = startTime && moment(startTime).tz(props.timezone);

  let startTimeString =
    startTimeMoment &&
    startTimeMoment.format(
      `dddd, MMMM D, ${Utils.hourRounder(startTimeMoment)} A`
    );
  let sessionsNode;
  if (isEvent) {
    const hasTime =
      !!props.meeting.sessions[0].session_start_time &&
      !!props.meeting.sessions[0].session_end_time;
    // Session times are truncated if the minutes are 00
    // We display the date and time of single session events in a single row
    startTimeString =
      startTimeString &&
      moment(startTime).tz(props.timezone).format('dddd, MMMM D');
    if (hasTime) {
      startTimeString += ', ';
      const endTime = props.meeting.sessions[0].session_end_time_formatted;
      startTimeString += Utils.formatEventSessionTime(
        startTime,
        endTime,
        props.timezone,
        false
      );
    }

    // For multi session events we show the event sessions with their times
    if (props.meeting.sessions.length > 1) {
      startTimeString =
        startTime &&
        moment(startTime).tz(props.timezone).format('dddd, MMMM D');
      if (startTimeString) {
        startTimeString = `Started ${startTimeString}`;
      }
      sessionsNode = props.meeting.sessions.map((session) => {
        const sessionStartTime =
          session.session_start_time_formatted ||
          session.session_date_formatted;
        const sessionStartTimeMoment =
          sessionStartTime && moment(sessionStartTime).tz(props.timezone);
        let sessionStartTimeNode =
          sessionStartTimeMoment &&
          sessionStartTimeMoment.format('dddd, MMMM D');
        const sessionHasTime =
          !!session.session_start_time && !!session.session_end_time;
        if (sessionHasTime) {
          sessionStartTimeNode += ', ';
          const sessionEndTime = session.session_end_time_formatted;
          sessionStartTimeNode += Utils.formatEventSessionTime(
            sessionStartTime,
            sessionEndTime,
            props.timezone,
            false
          );
        }
        const sessionInFuture =
          sessionStartTime && moment(sessionStartTime).isAfter() ? '' : 'gray';
        return (
          <div
            className={'home-v5__session-capsule'}
            key={`session-${session.id}`}
          >
            <div className={`v5__author-copy ${sessionInFuture}`}>
              {session.name}
            </div>
            <div className={`v5__author-title-copy ${sessionInFuture}`}>
              {sessionStartTimeNode}
            </div>
          </div>
        );
      });
    }
    const group_name = props.meeting.event_group.name
      ? `, ${props.meeting.event_group.name}`
      : '';
    descriptionText += group_name;
    descriptionText = (
      <NavigationLink
        target="_blank"
        className="tc__link"
        url={`/lab/event/${props.meeting.id}/participant/${props.participant_id}`}
      >
        {descriptionText}
      </NavigationLink>
    );
  }
  return (
    <div className="home-v5__past-meeting-row">
      <div
        key={`past-meeting-${props.index}`}
        className={'home-v5__meetings-row home-v5__meetings-margin'}
      >
        <div className={`home-v5__event-meeting-width ${iconClass}`}>
          <span className="path1"></span>
          <span className="path2"> </span>
        </div>
        <div className="home-v5__meetings-row-text">
          <div className="v5__author-copy">{startTimeString}</div>
          <div className="v5__author-title-copy">{descriptionText}</div>
        </div>
      </div>
      {sessionsNode}
    </div>
  );
};

export default PastMeetingRow;
