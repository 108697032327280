// @ts-nocheck FIXME
import Analytics from '@legacy-components/components/Analytics';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Auth from '@legacy-components/components/Auth';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import LessonLink from '@legacy-components/react/LessonLink';
import NavigationLink from '@legacy-components/react/NavigationLink';
import ActivateOnScrollToElement from '@legacy-components/react/V4/Common/ActivateOnScrollToElement';
import ArtifactImage from '@legacy-components/react/V4/Common/ArtifactImage';
import ArtifactOverlay from '@legacy-components/react/V4/Common/ArtifactOverlay/ArtifactOverlay';
import Spinner from '@legacy-components/react/V4/Common/Spinner';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import WindowScrollElementFixer from '@legacy-components/react/V4/Common/WindowScrollElementFixer';
import ShareModal from '@legacy-components/react/V4/ContentBrowser/modules/ShareModal';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import HeaderStore from '@legacy-components/stores/V4/HeaderStore';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share';
import ZenScroll from 'zenscroll';
import RecommendModal from './../common/RecommendModal';
import Schema from './Schema';
import Breadcrumbs from './modules/Breadcrumbs';
import LessonLifeCycle from './modules/LessonLifeCycle';
import MetadataSidebar from './modules/MetadataSidebar';
import Section from './modules/Section';
import SimilarLessons from './modules/SimilarLessons';

const Lesson = (props) => {
  let recommendBtnNode;
  let lessonImageNode;
  let crumbSubject;
  let favoriteNode;
  let alertNode;
  let artifactModalNode;
  let recommendModalNode;
  let authorsNode;
  let gradesSubjectsNode;
  let gradesNode;
  let subjectsNode;
  let standardsNode;

  const checkForUserActionBlock = () => {
    if (props.user && !props.user.id) {
      HeaderStore.actionDispatch('openRegisterModal', {
        reason: 'print_attempt',
      });
      return true;
    }

    return false;
  };

  const handlePrintClick = (e) => {
    e.preventDefault();
    if (checkForUserActionBlock()) {
      return;
    }
    window.open('/lesson_print/' + props.mtp_page_data.Lesson.id, '_blank');
  };

  const handleSocialShare = (target) => {
    Analytics.logEvent({
      category: 'lesson',
      action: 'share_on_' + target,
      label: 'from_lesson',
    });
  };

  // Build the alert node if needed:
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }
  // clicking on a link in the left side nav scrolls the page to the top of that section
  const scrollToSection = (index) => {
    const element = document.getElementById('section-' + index);
    if (element) {
      Analytics.logEvent({
        category: 'scroll',
        action: 'lessonsection',
        label: 'from_quick_scroll',
      });
      // on desktop only, clicking on a sidebar link scrolls to the associated section
      ZenScroll.setup(600, 10);
      ZenScroll.to(element);
    }
  };
  const handleFavorite = (e) => {
    e.preventDefault();
    if (checkForUserActionBlock()) {
      return;
    }
    props.addFavorite(e);
  };

  const sectionLinkNodes = [
    <div
      key="nav-link-0"
      id="nav-link-0"
      onClick={() => scrollToSection(0)}
      className="tc__link strategy-v4__side-nav-row"
    >
      Objective
    </div>,
  ];

  // set up any strategy sections (including side nav links to them)
  let sectionNodes = null;
  if (ArrayHelpers.hasElements(props.mtp_page_data.Lesson.Section)) {
    sectionNodes = props.mtp_page_data.Lesson.Section.map((section, index) => {
      const i = index + 1;
      sectionLinkNodes.push(
        <div
          key={'nav-link-' + i}
          id={'nav-link-' + i}
          onClick={() => scrollToSection(i)}
          className="tc__link strategy-v4__side-nav-row"
        >
          {section.LessonSection.name}
        </div>
      );

      return (
        <Section
          key={'section-' + i}
          index={i}
          {...section.LessonSection}
          openResource={props.ui.artifactModal.open}
          openInlineResource={props.openInlineResource}
          updateSectionExpandToggleCount={props.updateSectionExpandToggleCount}
        />
      );
    });
  }

  // set up Favorite button
  const btnDisabledClass = props.ui.loading ? ' disabled' : '';
  let favoriteBtnNode = (
    <button
      onClick={(e) => handleFavorite(e)}
      className={
        'bl4__btn bl4__btn--blue-border strategy-v4__btn' + btnDisabledClass
      }
    >
      <Spinner loading={props.ui.favoriteLoading} />
      <span className="tc-v4-heart-empty strategy-v4__btn-icon strategy-v4__btn-icon--red" />
      Add to Favorites
    </button>
  );
  if (props.ui.favorite.status === 'selected') {
    const favoritedText =
      props.ui.favorited && props.strategy.favorite.status === 'recommended'
        ? 'Recommended'
        : 'Favorited';
    favoriteBtnNode = (
      <button
        onClick={props.removeFavorite}
        className={
          'bl4__btn strategy-v4__btn  strategy-v4__btn--selected-favorite' +
          btnDisabledClass
        }
      >
        <Spinner loading={props.ui.favoriteLoading} />
        <span className="tc-v4-heart-filled strategy-v4__btn-icon strategy-v4__btn-icon--red" />
        {favoritedText}
      </button>
    );
  }
  if (Auth.hasCoachAccess(props.user)) {
    recommendBtnNode = (
      <button
        onClick={() => {
          props.ui.recommendModal.open({
            itemType: 'Lesson',
            itemId: props.mtp_page_data.Lesson.id,
          });
        }}
        className={
          'bl4__btn bl4__btn--blue-border strategy-v4__btn' + btnDisabledClass
        }
      >
        <Spinner loading={props.ui.recommendLoading} />
        <span className="tc-v4-coaching strategy-v4__btn-icon" />
        Recommend
      </button>
    );
  }
  if (parseInt(props.mtp_page_data.Lesson.favorite_count) > 0) {
    favoriteNode = (
      <div className="strategy-v4__favorites hide-desktop show-phone-flex">
        <span className="tc-v4-heart-filled strategy-v4__favorites-icon" />
        <span className="strategy-v4__favorites-text">
          {props.mtp_page_data.Lesson.favorite_count} teachers like this lesson
        </span>
      </div>
    );
  }
  // consolidate flags to reduce props sent to breadcrumbs
  if (props.mtp_page_data.Lesson.is_ela) {
    crumbSubject = 'ela';
  } else if (props.mtp_page_data.Lesson.is_math) {
    crumbSubject = 'math';
  } else if (props.mtp_page_data.Lesson.is_science) {
    crumbSubject = 'science';
  } else {
    crumbSubject = null;
  }

  // set up the next and last buttons
  let prevLinkNode = (
    <span className="lesson__foot-nav-link--disabled">
      <span className="tc-bold-arrow" /> Previous Lesson
    </span>
  );
  if (props.mtp_page_data.prevLesson) {
    prevLinkNode = (
      <LessonLink
        url={
          process.env.REACT_APP_TEACHING_URL +
          '/lesson/' +
          props.mtp_page_data.prevLesson.id +
          '/' +
          props.mtp_page_data.prevLesson.seo_url +
          '?from=mtp_lesson'
        }
        className="tc__link"
        lesson={props.mtp_page_data.prevLesson.id}
      >
        <span className="tc-bold-arrow" /> Previous Lesson
      </LessonLink>
    );
  }
  let nextLinkNode = (
    <span className="lesson__foot-nav-link--disabled">
      Next Lesson <span className="tc-bold-arrow" />
    </span>
  );
  if (props.mtp_page_data.nextLesson) {
    nextLinkNode = (
      <LessonLink
        url={
          process.env.REACT_APP_TEACHING_URL +
          '/lesson/' +
          props.mtp_page_data.nextLesson.id +
          '/' +
          props.mtp_page_data.nextLesson.seo_url +
          '?from=mtp_lesson'
        }
        className="tc__link"
        lesson={props.mtp_page_data.nextLesson.id}
      >
        Next Lesson <span className="tc-bold-arrow" />
      </LessonLink>
    );
  }

  // show a modal if one is opened
  if (props.ui.artifactModal.opened) {
    artifactModalNode = (
      <ArtifactOverlay
        {...props.ui.artifactModal}
        analyticsLabel="from_mtp_lesson"
        analyticsCategory="mtp_resource"
        user={props.user}
        mtLesson={true}
        openInlineResource={props.openInlineResource}
      />
    );
  } else if (props.ui.shareModal.opened) {
    artifactModalNode = (
      <ShareModal {...props.ui.shareModal} analyticsLabel="from_lesson" />
    );
  }

  // set up any opened modal
  if (props.ui.recommendModal.opened) {
    recommendModalNode = (
      <RecommendModal
        {...props.ui.recommendModal}
        participants={props.ui.participants}
        recommend={props.addFavorite}
        selectRecommendElement={props.selectRecommendElement}
        getRecommendElement={props.getRecommendElement}
        user={props.user}
      />
    );
  }

  // set up any strategy authors
  // set up any lesson authors
  let authorNodes = (
    <div>
      <UserPhoto
        src="/legacy-images/bl-icon-circle.png"
        diameter={80}
        className="strategy-v4__author-photo"
      />
      <div className="strategy-v4__author-name">
        Written by BetterLesson Master Teacher
      </div>
    </div>
  );
  if (props.mtp_page_data.Lesson.User) {
    authorNodes = (
      <div className="strategy-v4__metadata-flex-section strategy-v4__metadata-flex-section--centered">
        <div
          key={'author-' + props.mtp_page_data.Lesson.User.id}
          className="strategy-v4__metadata-flex-item"
        >
          <UserPhoto
            src={props.mtp_page_data.Lesson.User.photo}
            diameter={80}
            className="strategy-v4__author-photo"
          />
          <div className="strategy-v4__author-name">
            {props.mtp_page_data.Lesson.User.first_name}{' '}
            {props.mtp_page_data.Lesson.User.last_name}
          </div>
          <div className="strategy-v4__metadata-grey-text">
            {props.mtp_page_data.Lesson.User.School.Location_City},{' '}
            {props.mtp_page_data.Lesson.User.School.Location_State}
          </div>
        </div>
      </div>
    );
  }
  authorsNode = (
    <div className="strategy-v4__metadata-section lesson__mobile-author tc__center-text">
      <div className="strategy-v4__metadata-headline">Lesson Author</div>
      {authorNodes}
    </div>
  );

  if (props.mtp_page_data.Lesson.Grade) {
    const grades = props.mtp_page_data.Lesson.Grade.map((grade) => {
      return (
        <div
          key={'lesson-grade-' + grade.id}
          className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text"
        >
          {grade.name}
        </div>
      );
    });
    gradesNode = (
      <div>
        <div className="strategy-v4__metadata-headline">Grade Level</div>
        {grades}
      </div>
    );
  }

  if (props.mtp_page_data.Lesson.Subject) {
    const subjects = props.mtp_page_data.Lesson.Subject.map((subject) => {
      return (
        <div
          key={'lesson-grade-' + subject.id}
          className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text"
        >
          {subject.name}
        </div>
      );
    });
    subjectsNode = (
      <div>
        <div className="strategy-v4__metadata-headline">Subjects</div>
        {subjects}
      </div>
    );
  }
  if (gradesNode || subjectsNode) {
    gradesSubjectsNode = (
      <div className="strategy-v4__metadata-section">
        {gradesNode}
        {subjectsNode}
      </div>
    );
  }
  standardsNode = (
    <div className="strategy-v4__metadata-section">
      <div className="strategy-v4__metadata-headline">Standards</div>
      <div className="strategy-v4__metadata-flex-section">
        {props.mtp_page_data.Lesson.CoreStandard.map((standard) => {
          return (
            <div
              key={'lesson-standard-' + standard.id}
              className="strategy-v4__metadata-flex-item lesson__mobile-tags"
            >
              <NavigationLink
                url={
                  process.env.REACT_APP_TEACHING_URL +
                  '/browse/standard/' +
                  standard.id +
                  '/' +
                  standard.seo_url +
                  '?from=consumer_lesson_summary_cc'
                }
                className="strategy-v4__metadata-tag"
              >
                {standard.identifier}
              </NavigationLink>
              <div className="strategy-v4__metadata-tag-description">
                {standard.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  let lessonPublicImg =
    process.env.REACT_APP_LAB_URL +
    '/legacy-images/social_media/BL_squarelogo_pinterest.png';
  let detailsHasImageClass = '';
  if (
    props.mtp_page_data.Lesson.lessonImage &&
    props.mtp_page_data.Lesson.image_lessonfile_id
  ) {
    const lessonImageContent =
      props.mtp_page_data.Lesson.lessonImage[
        props.mtp_page_data.Lesson.image_lessonfile_id
      ];
    if (lessonImageContent) {
      lessonPublicImg = lessonImageContent.public_preview_big;
      detailsHasImageClass = ' lesson__details--has-image';
      lessonImageNode = (
        <ArtifactImage
          preview={lessonImageContent.preview}
          icon={lessonImageContent.icon}
          title={lessonImageContent.name}
          containerClass="lesson__image-container"
          imageClass="lesson__image"
        />
      );
    }
  }

  // set up social media sharing
  let shareUrl =
    process.env.REACT_APP_TEACHING_URL +
    '/lesson/' +
    props.mtp_page_data.Lesson.id;
  if (props.mtp_page_data.Lesson.seo_url) {
    shareUrl += '/' + props.mtp_page_data.Lesson.seo_url;
  }
  const socialSharingNode = (
    <div className="strategy-v4__share">
      <div className="strategy-v4__share-text">Share</div>
      <div className="strategy-v4__share-icons">
        <div className="strategy-v4__share-icon">
          <FacebookShareButton
            url={shareUrl}
            hashtag="#BetterLesson"
            beforeOnClick={() => handleSocialShare('facebook')}
          >
            <span className="tc-facebook"></span>
          </FacebookShareButton>
        </div>
        <div className="strategy-v4__share-icon">
          <TwitterShareButton
            url={shareUrl}
            hashtags={['BetterLesson']}
            beforeOnClick={() => handleSocialShare('twitter')}
          >
            <span className="tc-twitter"></span>
          </TwitterShareButton>
        </div>
        <div className="strategy-v4__share-icon">
          <PinterestShareButton
            url={shareUrl}
            media={lessonPublicImg}
            beforeOnClick={() => handleSocialShare('pinterest')}
          >
            <span className="tc-pinterest"></span>
          </PinterestShareButton>
        </div>
        <div
          onClick={() => props.ui.shareModal.open({})}
          className="tc-v4-link strategy-v4__share-icon"
        ></div>
      </div>
    </div>
  );

  // render the page
  return (
    <div className="tc__page">
      <Header page="lesson" {...props} />
      <Breadcrumbs
        {...props.ui}
        {...props.mtp_page_data.Breadcrumb}
        user={props.mtp_page_data.Lesson.User}
        crumbSubject={crumbSubject}
        toggleUnitDropdown={props.toggleUnitDropdown}
        toggleLessonDropdown={props.toggleLessonDropdown}
      />

      <div className="bl4__page-background show-desktop hide-phone" />
      {alertNode}
      {artifactModalNode}
      {recommendModalNode}

      <div className="row row--1200">
        {/*
                    topMargin is used to calculate the height of the sidebar container and stop position of the sidebar
                    it is necessary because offsetHeigt does not include margins
                */}
        <WindowScrollElementFixer
          positionToClass="strategy-v4__center-column"
          positionThisClass="strategy-v4__side-nav"
          positionThisContainerClass={'strategy-v4__side-nav-container'}
          updateFlag={JSON.stringify(props.ui.sectionExpandToggleCount)}
          topMargin={30}
        />
        <ActivateOnScrollToElement
          elementCount={
            ArrayHelpers.hasElements(props.mtp_page_data.Lesson.Section)
              ? props.mtp_page_data.Lesson.Section.length + 1
              : 4
          }
          elementUpperBuffer={190}
          elementIdPrefix="section-"
          targetIdPrefix="nav-link-"
        />

        <div className="strategy-v4__side-nav-container cute-2-desktop show-desktop hide-phone">
          <div className="strategy-v4__side-nav bl4__sidebar--sticky tc__body-copy">
            {sectionLinkNodes}
          </div>
        </div>
        <div className="cute-8-desktop cute-12-phone strategy-v4__center-column">
          <div className="strategy-v4__grey-background-mobile">
            <div className="strategy-v4__top-section-text-mobile">
              <h1 className="strategy-v4__title">
                {props.mtp_page_data.Lesson.name}
              </h1>

              <div className="strategy-v4__btns">
                {favoriteBtnNode}
                {recommendBtnNode}
                {favoriteNode}
                <a
                  onClick={handlePrintClick}
                  className="bl4__btn bl4__btn--blue-border strategy-v4__btn show-desktop-inline hide-phone"
                >
                  <span className="tc-print strategy-v4__btn-icon" />
                  Print Lesson
                </a>
              </div>

              <div className="hide-desktop show-phone">{socialSharingNode}</div>
            </div>
          </div>
          <div className="bl4__capsule strategy-v4__capsule" id="section-0">
            <div className={'lesson__details' + detailsHasImageClass}>
              <div className="strategy-v4__capsule-headline-row">
                <h2 className="strategy-v4__capsule-headline">Objective</h2>
              </div>
              <div className="lesson-big-idea strategy-v4__capsule-description ckeditor__output">
                {props.mtp_page_data.Lesson.custom_objective}
              </div>
              <div className="strategy-v4__capsule-description">
                <h4 className="tc__headline-4">Big Idea</h4>
                <div className="lesson__big-idea ckeditor__output">
                  {props.mtp_page_data.Lesson.summary}
                </div>
              </div>
            </div>
            {lessonImageNode}
          </div>
          <div className="hide-desktop show-phone">
            {authorsNode}
            {gradesSubjectsNode}
            {standardsNode}
          </div>
          {sectionNodes}
          <div className="lesson__foot-nav">
            <div className="lesson__foot-nav-link lesson__foot-nav-link--prev">
              {prevLinkNode}
            </div>
            <div className="lesson__foot-nav-link lesson__foot-nav-link--next">
              {nextLinkNode}
            </div>
          </div>
          <SimilarLessons
            mtpSubject={props.mtp_page_data.Lesson.mtp_subject}
            similarLessons={props.mtp_page_data.Lesson.similar_lessons}
          />
          {/*
                    <div className="lesson__view-all">
                        <NavigationLink
                            className="tc__link"
                            url={props.similarLessonData.url}
                        >
                            View all {props.similarLessonData.count} Lessons
                        </NavigationLink>{' '}
                        in {props.mtp_page_data.Lesson.course_title}
                    </div>
                    */}
        </div>
        <MetadataSidebar
          {...props.mtp_page_data.Lesson}
          socialSharingNode={socialSharingNode}
        />
      </div>
      <LessonLifeCycle
        lessonId={props.mtp_page_data.Lesson.id}
        sections={props.mtp_page_data.Lesson.Section}
        navigateToSectionId={
          props.mtp_page_data.navigate_to_section_id
            ? props.mtp_page_data.navigate_to_section_id
            : null
        }
        openResource={props.ui.artifactModal.open}
        openArtifactAfterLoad={props.openArtifactAfterLoad}
        clearOpenArtifactAfterLoad={props.clearOpenArtifactAfterLoad}
        getSimilarLessonCount={props.getSimilarLessonCount}
        openInlineResource={props.openInlineResource}
      />
      <Schema {...props.mtp_page_data} />
    </div>
  );
};

Lesson.propTypes = {
  ui: PropTypes.object.isRequired,
};

export default Lesson;
