import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import { useEffect } from 'react';

export default function GoogleAnalytics(): null {
  const { pageData } = useLegacyLabContext();

  const initializeGoogleAnalytics = () => {
    if (!process.env.REACT_APP_TEACHCYCLE_GOOGLE_ANALYTICS_ID && pageData) {
      console.log(
        'ga set page',
        window.location.pathname + window.location.search + window.location.hash
      );
      console.log('ga send pageview');
      return;
    }

    /* eslint-disable */
    (function (i, s, o, g, r, a, m) {
      // @ts-ignore
      i['GoogleAnalyticsObject'] = r;
      // @ts-ignore
      (i[r] =
        // @ts-ignore
        i[r] ||
        function () {
          // @ts-ignore
          (i[r].q = i[r].q || []).push(arguments);
        }),
        // @ts-ignore
        (i[r].l = 1 * new Date());
      // @ts-ignore
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      // @ts-ignore
      a.async = 1;
      // @ts-ignore
      a.src = g;
      // @ts-ignore
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      'script',
      '//www.google-analytics.com/analytics.js',
      'ga'
    );
    /* eslint-enable */

    window.ga(
      'create',
      process.env.REACT_APP_TEACHCYCLE_GOOGLE_ANALYTICS_ID,
      'auto'
    );

    if (pageData.user?.hash) window.ga('set', 'userId', pageData.user.hash);

    window.ga(
      'set',
      'dimension1',
      `${pageData.user?.hash ? pageData.user.hash : 'empty'}`
    );

    window.ga(
      'set',
      'dimension2',
      `${
        pageData.user?.details ? pageData.user.details.data.coach_hash : 'empty'
      }`
    );

    window.ga(function (tracker: {
      set: (key: string, val: string) => void;
      get: (str: string) => string;
    }) {
      tracker.set('dimension12', tracker.get('clientId'));
    });
    window.ga('send', 'pageview');
  };

  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  return null;
}
