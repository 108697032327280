// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import StoryApi from './../../../../../../../apis/V4/StoryApi';
import { History } from './../../../../../../../components/Router';
import NavigationLink from './../../../../../../NavigationLink';
import ArtifactImage from './../../../../../../V4/Common/ArtifactImage';
import TimeDelta from './../../../../../../V4/Common/TimeDelta';
import FormField from './../../../../../Common/FormField';
import Spinner from './../../../../../Common/Spinner';
import EntryTags from './../EntryTags';
import SelectAuthorDropdown from './../SelectAuthorDropdown';

const CuratedStrategyEntryForm = (props) => {
  const [note, setNote] = useState(props.noteInput || '');
  const [isLoading, setLoading] = useState(false);
  // set the initially selected author
  const [author, setAuthor] = useState(
    props.author ? props.author.id : props.currentUser.id
  );

  const handleCancel = (e) => {
    e.preventDefault();

    if (props.isAdding) {
      History.replace(null, null, `/lab/workspace/${props.userId}`);
      props.deleteEntry({
        storyId: props.activeStoryId,
        entryId: props.id,
        index: props.index,
      });
    } else {
      props.close();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    // build API data
    const data = {
      storyId: props.activeStoryId,
      note: note,
      goals: props.currentGoals,
      storyCuratedStrategyId: props.storyCuratedStrategyId,
    };
    // if the current user is allowed to change the authorship, pass that in
    if (props.canEditAuthor && author) {
      data.authorId = author;
    }
    // otherwise, if the current user is the workspace owner,
    // make sure they are set as the author
    else if (props.currentUser.id === props.pageUser.id) {
      data.authorId = props.currentUser.id;
    }

    StoryApi.editCuratedStrategyEntry(data)
      .then((response) => {
        // if we just finished adding a new entry, make sure to clean up the URL
        // so we don't re-open this entry upon refreshing the page
        if (props.isAdding) {
          History.replace(null, null, `/lab/workspace/${props.userId}`);
        }
        // update the timeline
        props.editEntryCallback(response.entry);
        props.close();
      })
      .catch(() => {
        props.setErrorAlert();
        setLoading(false);
      });
  };

  let imageNode;
  if (props.lessonfile && props.lessonfile.connections) {
    imageNode = (
      <ArtifactImage
        preview={props.lessonfile.connections.preview}
        title={props.lessonfile.connections.name}
        containerClass={'workspace__entry-image-container'}
        icon={props.lessonfile.connections.icon}
        imageClass="workspace__entry-image"
      />
    );
    if (props.is_viewable) {
      imageNode = (
        <NavigationLink
          url={`${process.env.REACT_APP_TEACHING_URL}${props.strategy_id}?from=workspace`}
          className="v5__link"
          target="_blank"
        >
          {imageNode}
        </NavigationLink>
      );
    }
  }

  // only link to the strategy page if the current user is allowed to view it
  let titleNode = props.title;
  if (props.is_viewable) {
    titleNode = (
      <NavigationLink
        url={`${process.env.REACT_APP_TEACHING_URL}/strategy/${props.strategy_id}?from=workspace`}
        className="v5__link"
        target="_blank"
      >
        {props.title}
      </NavigationLink>
    );
  }

  // set up the author select, if the current user is allowed to edit it
  let authorSelectNode;
  if (props.canEditAuthor) {
    authorSelectNode = (
      <SelectAuthorDropdown
        user={props.currentUser}
        pageUser={props.pageUser}
        author={props.author}
        newAuthorId={author}
        updateAuthorId={setAuthor}
        canEditAuthor={props.canEditAuthor}
      />
    );
  }

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  // only show the delete icon when editing an entry, not when creating a new entry
  let deleteIconNode;
  if (!props.isAdding && props.confirmDelete) {
    deleteIconNode = (
      <span
        onClick={props.confirmDelete}
        className="tc-delete workspace__delete-icon"
      ></span>
    );
  }

  return (
    <form>
      <div className="v5__heading-5 workspace__entry-header-row">
        <div className="tc-v4-strategy workspace__entry-header-icon"></div>
        <div className="workspace__entry-header-text">
          {props.entryTitle}
          <div className="workspace__entry-header-timestamp">
            <TimeDelta datetime={props.createdFormatted} />
          </div>
        </div>
      </div>

      <div className="workspace__entry-body">
        <div className="workspace__entry-image-row">
          {imageNode}
          <div className="workspace__entry-image-description">
            <div className="v5__heading-5 workspace__entry-row">
              {titleNode}
            </div>
            <div className="workspace__entry-row v5__body-copy">
              {props.tagline}
            </div>
          </div>
        </div>

        <FormField
          type="textarea"
          rows="4"
          placeholder="Add a note about this strategy."
          defaultValue={props.noteInput}
          autoFocus={true}
          onChange={(e) => setNote(e.target.value)}
        />

        {authorSelectNode}

        <div className="workspace__entry-footer">
          <EntryTags
            goals={props.goals}
            selectedGoalIds={props.currentGoals}
            canEdit={!props.isEntryHighlighted}
            toggleGoal={props.toggleGoalTag}
            storyId={props.activeStoryId}
            entryId={props.id}
            tagGoalResponseCallback={props.tagGoalResponseCallback}
            untagGoalResponseCallback={props.untagGoalResponseCallback}
          />

          <div className="workspace__btns-row">
            <button
              type="button"
              className="v5__btn v5__btn--blue-border workspace__cancel-link"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="v5__btn"
              {...btnProps}
              onClick={handleSubmit}
            >
              <Spinner loading={isLoading} />
              Save
            </button>
            {deleteIconNode}
          </div>
        </div>
      </div>
    </form>
  );
};

CuratedStrategyEntryForm.propTypes = {
  userId: PropTypes.number,
  index: PropTypes.number.isRequired,
  deleteEntry: PropTypes.func.isRequired,
};

export default CuratedStrategyEntryForm;
