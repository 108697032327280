// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import StoryApi from './../../../apis/V4/StoryApi';
import Utils from './../../../components/DynamicUtils';
import FormField from './FormField';
import Spinner from './Spinner';

const ArtifactLink = (props) => {
  const [url, setUrl] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // first, clear any form errors
    setError('');

    // validation
    if (!url) {
      setError('Hold on, you need to enter a link to continue.');
      return;
    }
    if (!Utils.isUrl(url)) {
      setError('Whoops! Your link must begin with http:// or https://.');
      return;
    }

    setLoading(true);

    StoryApi.addLinkEntry({
      url: url,
      storyId: props.storyId,
    })
      .then((response) => {
        props.addSuccessCallback(response);
      })
      .catch((response) => {
        // TODO: error alert
        setLoading(false);
      });
  };

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <React.Fragment>
      <div className="v5__heading-5 workspace__entry-header-row">
        <div className="tc-v4-link workspace__entry-header-icon"></div>
        <div className="workspace__entry-header-text">Add Link</div>
      </div>

      <div className="workspace__entry-body">
        <form className="workspace__link-form">
          <FormField
            type="text"
            placeholder="https://www.mylink.com"
            autoFocus={true}
            onChange={(e) => setUrl(e.target.value)}
            onFocus={() => setError('')}
            error={error}
          />

          <div className="workspace__btns-row">
            <button
              type="button"
              className="v5__btn v5__btn--blue-border workspace__cancel-link"
              onClick={() => props.close()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="v5__btn"
              {...btnProps}
              onClick={handleSubmit}
            >
              <Spinner loading={isLoading} />
              Save
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

ArtifactLink.propTypes = {
  storyId: PropTypes.number.isRequired,
  addSuccessCallback: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default ArtifactLink;
