// @ts-nocheck FIXME
import Request from './../../components/Request';

const SearchApi = {
  filterResults: (queryString, data) => {
    return Request.get(
      process.env.REACT_APP_LAB_SERVICE_URL + '/search' + queryString,
      data
    );
  },

  searchAutocomplete: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/search/autocomplete',
      JSON.stringify(data)
    );
  },
};

export default SearchApi;
