// @ts-nocheck FIXME
import dateformat from 'dateformat';
import moment from 'moment';
import React from 'react';

// This component was created to replace TimeAgo due to a bug in Safari where the timezone wasn't being respected
class TimeDelta extends React.Component {
  constructor(props) {
    super(props);

    const datetime = props.datetime || dateformat(new Date(), 'isoDateTime');

    this.state = {
      renderDate: props.renderDate,
      datetime: datetime,
      timeDelta: moment(datetime).fromNow(),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        timeDelta: moment(this.state.datetime).fromNow(),
      }),
        15000;
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return this.state.renderDate
      ? this.state.renderDate(this.state.timeDelta)
      : this.state.timeDelta;
  }
}

export default TimeDelta;
