// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import ResourceApi from './../../../../../apis/ResourceApi';
import VisionApi from './../../../../../apis/V4/VisionApi';
import Analytics from './../../../../../components/Analytics';
import Utils from './../../../../../components/DynamicUtils';
import S3 from './../../../../../components/S3';
import ArtifactForm from './../../../Common/ArtifactUpload/ArtifactForm';
import ArtifactLink from './../../../Common/ArtifactUpload/ArtifactLink';
import ArtifactProgress from './../../../Common/ArtifactUpload/ArtifactProgress';
import ArtifactUpload from './../../../Common/ArtifactUpload/ArtifactUpload';
import Capsule from './Capsule';

class Artifact extends React.Component {
  /*** STATE MANAGEMENT ***/
  constructor(props) {
    super(props);

    // mode can be: file, link, during, after
    this.state = {
      mode: this.props.mode || 'file',
      dragOver: false,
      blur: false,
      isPrivate: this.props.isPrivate,
      loading: false,
      errors: {},
      artifact: this.props.data || {},
      isLink: this.props.isLink,
    };

    this.handleClose = this.handleClose.bind(this);
    this.submitFiles = this.submitFiles.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.handleUploadCancel = this.handleUploadCancel.bind(this);
    this.toggleFileLink = this.toggleFileLink.bind(this);
    this.createFileEntry = this.createFileEntry.bind(this);
    this.editArtifact = this.editArtifact.bind(this);
    this.clearError = this.clearError.bind(this);
    this.linkOrFile = this.linkOrFile.bind(this);
    this.createLinkEntry = this.createLinkEntry.bind(this);
  }

  componentDidMount() {
    // If a mobile user uploaded files directly from the timeline
    // submit the said files right away
    if (window.uploadedFiles && this.props.isMobile) {
      this.submitFiles({
        inputFiles: window.uploadedFiles,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    // the store controls some UI elements in the edit form
    const updatedState = {};
    if (
      'isPrivate' in nextProps &&
      nextProps.isPrivate !== this.state.isPrivate
    ) {
      updatedState.isPrivate = nextProps.isPrivate;
    }
    this.setState(updatedState);
  }

  handleClose(e) {
    e.preventDefault();

    // if we are closing the artifact edit before filling out the form
    // remove the artifact from the users vision
    const artifactToDelete = {};
    if (this.state.mode === 'after') {
      if (this.state.artifact.entry_id) {
        artifactToDelete.entryId = this.state.artifact.entry_id;
      } else if (this.state.artifact.visionLinkId) {
        artifactToDelete.entryId = this.state.artifact.visionLinkId;
      }
    }
    this.props.close(artifactToDelete);
  }

  /*** FILE UPLOAD HANDLERS ***/
  submitFiles(data) {
    const inputFiles = data.inputFiles;
    let file;

    // Clear upload errors and set the blur state
    const errors = this.state.errors;
    delete errors.api;

    this.setState({
      errors: errors,
      blur: true,
    });
    for (let i = 0; i < inputFiles.length; i++) {
      // Add some of the POST fields that the backend needs
      file = {
        participantId: this.props.entryParticipantId,
        teacherId: this.props.artifactUserId,
        lastModified: inputFiles[i].lastModified,
        lastModifiedDate: inputFiles[i].lastModifiedDate,
        name: inputFiles[i].name,
        size: inputFiles[i].size,
        type: inputFiles[i].type,
        uuid: Utils.uuid(),
      };

      // max size is 1024MB
      if (file.size > 1024000000) {
        const errors = this.state.errors;
        errors.api = 'Please select a file less than 1024MB';
        // Reset the blur state
        this.setState({
          errors: errors,
          blur: false,
          // Reset to the default mode to show the ArtifactUpload component
          mode: 'file',
          artifact: {},
        });
      } else {
        // The teacherId property is required by the ancient resources interface :-(
        inputFiles[i].uuid = file.uuid;
        const callbackFields = {
          teacherId: this.props.artifactUserId,
        };
        // Upload the file to S3
        S3.flexibleUpload(
          inputFiles[i],
          ResourceApi.createBeforeUpload,
          ResourceApi.upload,
          this.createFileEntry,
          this.uploadCleanupCallback,
          callbackFields
        ).then(
          function onSuccess(fileData) {
            fileData.message = 'Upload Complete';
            fileData.status = 'after';
          },
          function onError(fileData) {
            // See if the file has the error property
            let error = fileData.error;
            if (!error) {
              error =
                'There was an error uploading your file. Please try again.';
            } else if (error === 'aborted') {
              error = 'File upload canceled';
            }
            // Set the error and reset the blur in the state
            const errors = this.state.errors;
            errors.api = error;
            // Clear the file from the store and set the upload error
            this.setState({
              artifact: {},
              errors: errors,
              blur: false,
              // Reset the mode so that the user sees ArtifactUpload
              mode: 'file',
            });
          }.bind(this),
          function onProgress(fileData) {
            // If there is an error, abort
            if (fileData.status === 'error') {
              return;
            }
            fileData.message = fileData.progress + '%';
            fileData.status = 'during';
            fileData.percent = fileData.progress;
            // Update the state's file and the mode
            this.setState({
              artifact: fileData,
              mode: 'during',
            });
          }.bind(this)
        );
      }
    }
    // Destroy any uploadedFiles on the window object (mobile users)
    delete window.uploadedFiles;
  }

  handleSelectFiles() {
    Analytics.logEvent({
      category: 'file',
      action: 'browse',
      label: 'from_file_artifact_modal_outcome_story',
    });
  }

  onDragOver() {
    // Clear upload errors and set drag over
    const errors = this.state.errors;
    delete errors.api;
    this.setState({
      errors: errors,
      dragOver: true,
    });
  }

  onDragLeave() {
    // Clear upload errors and reset drag over
    const errors = this.state.errors;
    delete errors.api;
    this.setState({
      errors: errors,
      dragOver: false,
    });
  }

  handleUploadCancel(e) {
    this.handleClose(e);
  }

  toggleFileLink() {
    // switch the mode between 'file' and 'link'
    const switchToLink = this.state.mode === 'file';
    this.setState({
      mode: switchToLink ? 'link' : 'file',
      errors: {},
      loading: false,
      isLink: switchToLink,
    });
  }

  createFileEntry(data) {
    // clear errors
    this.setState({
      errors: {},
      loading: true,
    });

    data.participantId = this.props.entryParticipantId;
    // Add the entry
    return VisionApi.addFileEntry(data)
      .then(
        function (response) {
          // set up the artifact data needed for the form
          const artifact = this.state.artifact;
          artifact.entry_id = response.entry.id;
          artifact.title = response.entry.title;
          artifact.fileEntryId = response.entry.vision_file_id;

          // update the modal state for next step
          this.setState({
            artifact: artifact,
            visionFileId: response.entry.vision_file_id,
            mode: 'after',
            loading: false,
          });
        }.bind(this)
      )
      .catch(
        function (response) {
          // We could not create the artifact entry, we need to cleanup
          this.uploadCleanupCallback(data);
          this.setState({
            loading: false,
            blur: false,
            // Reset the modal to the default mode so that all users can see the ArtifactUpload component
            mode: 'file',
            errors: {
              api:
                response.error ||
                'Sorry, there was a problem adding the artifact. Please try again later.',
            },
          });
        }.bind(this)
      );
  }

  createLinkEntry(data) {
    Analytics.logEvent({
      category: 'link_artifact_url',
      action: 'add',
      label: 'from_profile',
    });
    // clear errors
    this.setState({
      errors: {},
      loading: true,
    });

    data.participantId = this.props.entryParticipantId;

    // Add the entry
    return VisionApi.addLinkEntry(data)
      .then(
        function (response) {
          // set up the artifact data needed for the form
          const artifact = {
            visionLinkId: response.entry.id,
            title: response.entry.title,
            linkEntryId: response.entry.vision_link_id,
          };

          // update the modal state for next step
          this.setState({
            artifact: artifact,
            mode: 'after',
            loading: false,
            isLink: true,
          });
        }.bind(this)
      )
      .catch(
        function (response) {
          this.setState({
            loading: false,
            blur: false,
            // Reset the modal to the default mode so that all users can see the ArtifactUpload component
            mode: 'link',
            errors: {
              api:
                response.error ||
                'Sorry, there was a problem adding the artifact. Please try again later.',
            },
          });
        }.bind(this)
      );
  }

  uploadCleanupCallback() {}

  editArtifact(data) {
    // clear errors
    this.setState({
      errors: {},
    });

    // validate form data
    const errors = {};
    if (!data.title) {
      errors.title = 'Please enter a title';
    }

    if (Object.keys(errors).length) {
      this.setState({
        errors: errors,
      });
      return;
    }

    // set up form loading UI
    this.setState({
      loading: true,
    });

    // add necessary data to send to API
    data.participantId = this.props.entryParticipantId;

    if (this.props.isLink) {
      // edit the entry data
      VisionApi.editLinkEntry(data)
        .then((response) => {
          if (this.state.mode === 'after') {
            // create a new capsule if we're in the process of adding a new artifact
            this.props.addEntryCallback(response);
          } else {
            // replace the existing capsule if we're editing an existing artifact
            this.props.editEntryCallback(response);
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
          this.props.setErrorAlert(
            'Sorry, there was a problem updating the artifact. Please try again later.'
          );
        });
    } else {
      // edit the entry data
      VisionApi.editFileEntry(data)
        .then((response) => {
          if (this.state.mode === 'after') {
            // create a new capsule if we're in the process of adding a new artifact
            this.props.addEntryCallback(response);
          } else {
            // replace the existing capsule if we're editing an existing artifact
            this.props.editEntryCallback(response);
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
          this.props.setErrorAlert(
            'Sorry, there was a problem updating the artifact. Please try again later.'
          );
        });
    }
  }

  /****** HELPERS ******/
  clearError(field) {
    const errors = this.state.errors;
    if (field in errors) {
      delete errors[field];
      this.setState({
        errors: errors,
      });
    }
  }

  // used for strings in analytics events
  linkOrFile() {
    if (this.state.mode === 'link' || this.state.isLink) {
      return 'link';
    }
    return 'file';
  }

  render() {
    // header text depends on context
    let headerText = this.state.mode === 'edit' ? 'Edit' : 'Add';
    let artifactTypeText = this.state.isLink ? ' Link' : ' File';
    if (this.props.isEvidence || this.state.selectedTag === 'evidence') {
      artifactTypeText = ' Evidence';
    } else if (this.state.selectedTag === 'reflection') {
      artifactTypeText = ' Reflection';
    }
    headerText += artifactTypeText;

    // set up main form
    let formNode;
    switch (this.state.mode) {
      case 'file':
        formNode = (
          <ArtifactUpload
            submitFiles={this.submitFiles}
            handleSelectFiles={this.handleSelectFiles}
            onDragLeave={this.onDragLeave}
            onDragOver={this.onDragOver}
            switchToLink={this.toggleFileLink}
            error={this.state.errors.api}
            blur={this.state.blur}
            dragOver={this.state.dragOver}
            canSwitchToLink={false}
          />
        );
        break;
      case 'link':
        formNode = (
          <div className="story__edit-entry-form-row">
            <ArtifactLink
              switchToFile={this.toggleFileLink}
              submitLink={this.createLinkEntry}
              setArtifactLinkUploadError={() => this.clearError('api')}
              error={this.state.errors.api}
              loading={this.state.loading}
              canSwitchToFile={false}
            />
          </div>
        );
        break;
      case 'during':
        formNode = (
          <ArtifactProgress
            file={this.state.artifact}
            containerCssClass="artifact-upload-v4__progress"
          />
        );
        break;
      case 'after':
      case 'edit':
        formNode = (
          <ArtifactForm
            type={this.state.isLink ? 'link' : 'file'}
            data={this.state.artifact}
            handleFocus={this.clearError}
            toggleIsPrivate={this.props.toggleIsPrivate}
            editArtifact={this.editArtifact}
            isPrivate={this.state.isPrivate}
            loading={this.state.loading}
            errors={this.state.errors}
            handleClose={this.handleClose}
            editMode={this.state.mode === 'edit'}
            hasCoachAccess={this.props.hasCoachAccess}
          >
            {this.props.children}
          </ArtifactForm>
        );
        break;
    }

    return (
      <Capsule highlight={this.props.highlightActiveEntry}>
        <div className="story__edit-entry-header-row">
          <div className="story__edit-entry-header-text">{headerText}</div>
          <div
            className="tc-x story__edit-entry-close-icon"
            onClick={this.handleClose}
          ></div>
        </div>
        {formNode}
      </Capsule>
    );
  }
}

Artifact.propTypes = {
  mode: PropTypes.string,
  close: PropTypes.func.isRequired,
  addEntryCallback: PropTypes.func.isRequired,
  editEntryCallback: PropTypes.func.isRequired,
  setErrorAlert: PropTypes.func.isRequired,
  artifactUserId: PropTypes.number,
};

Artifact.defaultProps = {
  isEvidence: false,
};

export default Artifact;
