// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import AbridgeText from '@legacy-components/react/V5/Common/AbridgeText';
import UpdatesRowContainers from '@legacy-components/react/V5/PartnerLeader/modules/UpdatesRowContainer';
import PropTypes from 'prop-types';
import CircleCapsule from '../../modules/CircleCapsule';
import SideBar from './Common/SideBar';
/** ResourceLink **/

const icons = {
  pdf: 'tc-file-document',
  doc: 'tc-file-document',
  docx: 'tc-file-document',
  docm: 'tc-file-document',
  xls: 'tc-file-document',
  xlsx: 'tc-file-document',
  xlsm: 'tc-file-document',
  txt: 'tc-file-document',
  rtf: 'tc-file-document',
  odt: 'tc-file-document',
  png: 'tc-file-photo',
  jpg: 'tc-file-photo',
  jpeg: 'tc-file-photo',
  bmp: 'tc-file-photo',
  wmf: 'tc-file-photo',
  gif: 'tc-file-photo',
  link: 'tc-file-link',
  mov: 'tc-file-video',
  mpg: 'tc-file-video',
  mp4: 'tc-file-video',
  avi: 'tc-file-video',
  mpeg: 'tc-file-video',
  wav: 'tc-file-audio',
  mp3: 'tc-file-audio',
};

const ResourceLink = (props) => {
  if (Object.entries(props.connections).length === 0) {
    // Don't show text artifacts
    return null;
  }

  const linkProps = {
    url: `/lab/cohort/${props.cohort_id}/artifact/${props.id}`,
  };

  if (props.url) {
    linkProps.url = props.url;
    linkProps.target = '_blank';
    linkProps.rel = 'noopener';
  }

  const resourceLink = (
    <NavigationLink
      className="partner_lead_v5__resource v5__link"
      {...linkProps}
    >
      <span
        className={`partner_lead_v5__resource-icon ${
          icons[props.connections.icon] || 'tc-file-document'
        }`}
      >
        {' '}
      </span>
      <span>{props.title || 'Partnership Update'}</span>
    </NavigationLink>
  );

  return resourceLink;
};

ResourceLink.propTypes = {
  title: PropTypes.string,
  connections: PropTypes.object.isRequired,
};
/** End **/

const Overview = (props) => {
  const values = [
    {
      count: props.registration_stats.registered,
      total: props.registration_stats.total,
      label: 'registered',
    },
    {
      count: props.registration_stats.scheduled,
      total: props.registration_stats.total,
      label: 'scheduled first meeting',
    },
    {
      count: props.registration_stats.completed,
      total: props.registration_stats.total,
      label: 'completed first meeting',
    },
    {
      count: props.registration_stats.engaged,
      total: props.registration_stats.total_engaged,
      label: 'scheduled next meeting',
      showTooltip: true,
    },
  ];

  // Create the resources node
  let resourcesNode = (
    <div className="v5__empty-state-text">No resources have been shared.</div>
  );

  if (props.cohort.reports && props.cohort.reports.length) {
    resourcesNode = props.cohort.reports.map((resource, i) => (
      <ResourceLink key={`resource-${i}`} {...resource} />
    ));
  }

  // Create the vision & Goals node if the vision exists
  let visionNode = (
    <div className="v5__empty-state-text vision-and-goals">
      Vision and goals have not been shared.
    </div>
  );

  if (props.cohort.vision) {
    visionNode = (
      <AbridgeText
        className="partner_lead_v5__vision"
        isExpandable={true}
        maxHeight={180}
      >
        <PurifiedHTML className="v5__body-copy" content={props.cohort.vision} />
      </AbridgeText>
    );
  }

  const engagementNode = props.search_failed ? (
    <div className="v5__registered-table-details v5__heading-5 row home-v5__empty-activity-capsule">
      <div>
        <img
          src="/legacy-images/404-pets.png"
          className="code-404__img-overview"
        />
      </div>
      <div className="home-v5__empty-activity-row">
        <div className="v5__heading-4">Oops! Something is broken.</div>
        <div className="v5__comment-copy">
          <div>
            Registration data is temporarily unavailable. (We blame the cat.)
          </div>
          <div>Thanks for your patience as we tidy up this issue.</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="partner_lead_v5__registration-stats">
      {values.map((value, i) => (
        <CircleCapsule key={`circle-graph-${i}`} {...value} />
      ))}
    </div>
  );

  const highlightRows =
    props.highlight_entries.length === 0 ? (
      <div className="v5__body-copy partner_lead__missing-content">
        Your educators’ professional development journeys are still a work in
        progress. Check back later for updates.
      </div>
    ) : (
      <UpdatesRowContainers
        highlight_entries={props.highlight_entries}
        reportingPhases={props.reportingPhases}
        section={'highlights'}
      />
    );

  const domainRows =
    props.total_learning_domains.length === 0 ? (
      <div className="v5__body-copy partner_lead__missing-content">
        Learning Domains have not been selected yet.
      </div>
    ) : (
      <UpdatesRowContainers
        total_learning_domains={props.total_learning_domains}
        section={'domains'}
      />
    );

  const strategyRows =
    props.strategies.length === 0 ? (
      <div className="v5__body-copy partner_lead__missing-content">
        Your educators haven’t selected strategies to try just yet. Check back
        later for updates.
      </div>
    ) : (
      <UpdatesRowContainers
        strategies={props.strategies}
        section={'strategies'}
      />
    );

  let engagementFullNode;
  let recentHighlightsNode;
  let recentStrategiesNode;
  if (props.school_year === 'current') {
    engagementFullNode = (
      <div className="cute-12-desktop partner_lead_v5__registration">
        <div className="participant-v5__section-header v5__heading-4">
          Engagement
        </div>
        {engagementNode}
      </div>
    );
    recentHighlightsNode = (
      <div className="cute-12-desktop partner_lead_v5__registration">
        <div className="participant-v5__section-header v5__heading-4">
          Recent Highlights
        </div>
        {highlightRows}
      </div>
    );
    recentStrategiesNode = (
      <div className="cute-12-desktop partner_lead_v5__registration">
        <div className="participant-v5__section-header v5__heading-4">
          Recent Strategies
        </div>
        {strategyRows}
      </div>
    );
  }

  return (
    <div className="row row--1200 partner_lead_v5__overview">
      <div className="cute-9-desktop partner_lead_v5__cohort_pane left">
        <div className="participant-v5__section-header v5__heading-4">
          Vision & Goals
        </div>
        {visionNode}
        <div className="participant-v5__section-header v5__heading-4">
          Resources
        </div>
        <div className="partner_lead_v5__resources">{resourcesNode}</div>
      </div>
      <div className="cute-3-desktop partner_lead_v5__sidebar right">
        <SideBar
          psms={props.psms}
          leads={props.cohort.leads}
          schools={props.schools}
        />
      </div>
      {engagementFullNode}
      {recentHighlightsNode}
      <div className="cute-12-desktop partner_lead_v5__registration">
        <div className="participant-v5__section-header v5__heading-4">
          Learning Domains
        </div>
        {domainRows}
      </div>
      {recentStrategiesNode}
    </div>
  );
};

Overview.propTypes = {
  psms: PropTypes.array.isRequired,
  cohort: PropTypes.object.isRequired,
};

export default Overview;
