// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import FormField from '@legacy-components/react/V5/Common/FormField';
import PropTypes from 'prop-types';
import React from 'react';

const EditCapacitiesModal = (props) => {
  const [newCapacity, setNewCapacity] = React.useState('');
  const [newCertification, setNewCertification] = React.useState('');
  const [capacities, setCapacities] = React.useState(props.capacities);

  const handleKeyDown = (e) => {
    // do not allow "e", "-", or "." to be typed in a number input
    if ([69, 189, 190].indexOf(e.keyCode) !== -1) {
      e.preventDefault();
    }
  };

  const handleAddCert = (e) => {
    e.preventDefault();

    if (!newCapacity || !newCertification) {
      return;
    }

    handleSetCapacity(newCertification, newCapacity);
    setNewCapacity('');
    setNewCertification('');
  };

  const handleSetCapacity = (certification, capacity) => {
    const newCapacities = Utils.clone(capacities);

    if (isNaN(parseInt(capacity))) {
      newCapacities[certification] = '';
    } else {
      newCapacities[certification] = parseInt(capacity);
    }

    setCapacities(newCapacities);
  };

  const handleDeleteCert = (certification) => {
    const newCapacities = Utils.clone(capacities);
    delete newCapacities[certification];
    setCapacities(newCapacities);
  };

  const handleSave = () => {
    const newCapacities = Utils.clone(capacities);
    // clear out any certifications where the capacity was deleted
    Object.keys(newCapacities).forEach((certification) => {
      if (!newCapacities[certification]) {
        delete newCapacities[certification];
      }
    });
    props.save(newCapacities);
    props.close();
  };

  // get a list of certifications with assigned capacities
  const assignedCerts = Object.keys(capacities);
  // exclude those certifications from the dropdown options
  const certificationOptionNodes = props.certifications
    .filter((cert) => {
      return !assignedCerts.some((assignedCert) => assignedCert === cert.id);
    })
    .map((cert) => {
      return (
        <option key={cert.id} value={cert.id}>
          {cert.name}
        </option>
      );
    });

  return (
    <ActionModal
      headlineText="Set Capacity"
      close={props.close}
      spaceFromTop={20}
    >
      <div className="v5__heading-5">Set Overall Capacity</div>
      <div className="events-cal__capacities-modal-text">
        Capacities must be set for heatmap to display correctly
      </div>

      <form className="events-cal__capacities-modal-form events-cal__capacities-modal-text">
        <div className="events-cal__capacities-modal-general-col">
          <div className="events-cal__capacities-modal-label">
            General Capacity
          </div>
          <FormField
            type="number"
            min={1}
            isControlled={true}
            value={capacities.general || ''}
            autoFocus={true}
            onKeyDown={handleKeyDown}
            onChange={(e) => handleSetCapacity('general', e.target.value)}
            className="events-cal__capacities-modal-input"
          />
        </div>

        <div className="events-cal__capacities-modal-certs-col">
          <div>Cert Capacity</div>
          <div>Certification</div>
          <div></div>

          <FormField
            type="number"
            min={1}
            isControlled={true}
            value={newCapacity}
            onKeyDown={handleKeyDown}
            onChange={(e) => setNewCapacity(e.target.value)}
            className="events-cal__capacities-modal-input"
            data-testid="new-capacity"
          />
          <select
            value={newCertification}
            onChange={(e) => setNewCertification(e.target.value)}
            className="events-cal__capacities-modal-select"
            data-testid="new-cert"
          >
            <option key="none" value=""></option>
            {certificationOptionNodes}
          </select>
          <button
            className="v5__link events-cal__capacities-modal-cert-btn"
            onClick={handleAddCert}
          >
            Add Cert
          </button>

          {Object.keys(capacities).map((cert) => {
            const certification = props.certifications.find((certification) => {
              return certification.id === cert;
            });
            if (cert === 'general' || !certification) {
              return null;
            }

            return (
              <React.Fragment key={cert}>
                <FormField
                  type="number"
                  min={1}
                  isControlled={true}
                  value={capacities[cert]}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleSetCapacity(cert, e.target.value)}
                  className="events-cal__capacities-modal-input"
                />
                <div className="events-cal__capacities-modal-tag">
                  <div className="events-cal__capacities-modal-tag-text">
                    {certification.name}
                  </div>
                  <div
                    onClick={() => handleDeleteCert(cert)}
                    className="tc-x events-cal__capacities-modal-tag-remove"
                  ></div>
                </div>
                <div></div>
              </React.Fragment>
            );
          })}
        </div>
      </form>

      <div className="events-cal__capacities-modal-btn-row">
        <button className="v5__btn v5__btn--blue" onClick={handleSave}>
          Save
        </button>
      </div>
    </ActionModal>
  );
};

EditCapacitiesModal.propTypes = {
  certifications: PropTypes.array.isRequired,
  capacities: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default EditCapacitiesModal;
