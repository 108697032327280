// @ts-nocheck FIXME
import StudentsImpactedIcons from './../../../../TeacherProfile/StudentsImpactedIcons';

const StudentsImpacted = (props) => {
  let studentsImpactedNode = <noscript />;

  if (props.studentsImpacted && props.studentsImpacted > 0) {
    // set up the new node for the dom
    studentsImpactedNode = (
      <div className="portfolio__students-impacted portfolio__stats-row portfolio__stats-row--red">
        <div className="portfolio__stat-number portfolio__stat-number--impact">
          {props.studentsImpacted}
        </div>
        <div className="portfolio__students-impacted-caption">
          Students impacted
        </div>
        <div className="portfolio__students-impacted-container students-impacted__container">
          <StudentsImpactedIcons
            studentsImpacted={props.studentsImpacted}
            cssClassName="students-impacted__icon--portfolio"
          />
        </div>
      </div>
    );
  }
  return studentsImpactedNode;
};

export default StudentsImpacted;
