// @ts-nocheck FIXME
import EventApi from '@legacy-components/apis/V5/EventApi';
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';
import AbsoluteAlert from './../../Alerts/Absolute';
import AttendeeRow from './Modules/AttendeeRow';

const BulkEventImport = (props) => {
  const [eventId, setEventId] = React.useState(null);
  const [eventName, setEventName] = React.useState(null);
  const [fileName, setFileName] = React.useState('');
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState('');
  const [alert, setAlert] = React.useState({});
  const [importSuccess, setImportSuccess] = React.useState(false);
  const [participantCount, setParticipantCount] = React.useState(0);
  const [invalidRowCount, setInvalidRowCount] = React.useState(0);
  const [importAttendees, setImportAttendees] = React.useState(false);
  const [validationResults, setValidationResults] = React.useState([]);
  const [flaggedRowCount, setFlaggedRowCount] = React.useState(0);

  const resetPageState = () => {
    setImportAttendees(false);
    setValidationResults([]);
    setInvalidRowCount(0);
    setFlaggedRowCount(0);
    setImportSuccess(false);
    setErrors('');
    setParticipantCount(0);
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : '');
    setFile(file);
    resetPageState();
  };

  const setErrorAlert = (message) => {
    setAlert({
      type: 'error',
      message: message,
      handleClose: () => setAlert({}),
    });
  };

  const handleSelectOption = (selectedEvent) => {
    if (eventId) {
      setEventId(null);
      setEventName(null);
    } else {
      setEventId(selectedEvent.id);
      setEventName(selectedEvent.label);
    }
    resetPageState();
  };

  const eventOptions = props.events.map((event) => {
    return {
      label: event.name || '',
      id: event.id,
    };
  });

  const selectedEvent = eventOptions.find((event) => {
    return event.id === eventId;
  });

  const templateLink = (
    <a
      href="https://docs.google.com/spreadsheets/d/1HU_B04kaVDu31upRYL4R-0Ha3Lt064vCEcskf7yRnds/edit#gid=0"
      className="v5__link"
      target="_blank"
      rel="noreferrer"
    >
      Event Bulk Importer Template
    </a>
  );

  const handleSubmit = (importAttendees) => {
    // clear errors
    setLoading(false);
    setImportSuccess(false);
    setErrors(null);
    setAlert({});

    // track form errors
    let localErrors;
    // only submit the form if there is a csv or tsv file uploaded
    if (
      !file ||
      (file.type !== 'text/csv' && file.type !== 'text/tab-separated-values')
    ) {
      localErrors = 'Please select a csv or tsv file.';
    }

    // if the form has any errors, display them instead of submitting the form
    if (localErrors > 0) {
      setErrors(localErrors);
      return;
    }

    // set up the UI to show the loading animation
    setLoading(true);

    return EventApi.importAttendees({
      file: file,
      formData: {
        importAttendees: importAttendees,
        eventId: eventId,
      },
    })
      .then((response) => {
        setLoading(false);

        setValidationResults(response.validationResults);
        setParticipantCount(response.count);
        setInvalidRowCount(response.invalidRowCount);
        setFlaggedRowCount(response.flaggedRowCount);

        if (response.invalidRowCount === 0) {
          if (importAttendees) {
            // after a successful import, we want to show a success state
            setImportAttendees(false);
            setImportSuccess(true);
          } else {
            setImportAttendees(true);
          }
        }
      })
      .catch((response) => {
        setLoading(false);
        if (response.error) {
          if (response.error.file) {
            setErrors(response.error);
          } else {
            setErrorAlert(response.error);
          }
          return;
        }
        setErrorAlert(
          'Sorry, there was a problem submitting the form. Please try again later.'
        );
      });
  };

  let resultsNode;
  if (validationResults.length) {
    const pluralS = participantCount > 1 ? 's' : '';
    let successNode = (
      <React.Fragment>
        <span className="tc-check bulk-event-import__check" />{' '}
        {participantCount} event participant{pluralS} ready for import
      </React.Fragment>
    );
    if (invalidRowCount > 0) {
      successNode = (
        <React.Fragment>
          <span className="tc-v5-alert bulk-event-import__failure" /> This list
          can&apos;t be imported. Correct the errors below and upload a new file
          to continue.
        </React.Fragment>
      );
    } else if (flaggedRowCount > 0) {
      const warningS = flaggedRowCount > 1 ? 's' : '';
      successNode = (
        <React.Fragment>
          <span className="tc-exclamation-triangle bulk-event-import__warning" />{' '}
          Review {flaggedRowCount} warning{warningS} below. You can continue
          with importing {participantCount} event participant{pluralS} or upload
          a new file.
        </React.Fragment>
      );
    }
    if (importSuccess) {
      successNode = (
        <div>
          <div className="bulk-event-import__success-node v5__heading-4">
            <span className="highlight">Import Successful:</span> {fileName}
          </div>

          <div className="bulk-event-import__success-node">
            <span className="tc-check  bulk-event-import__check"></span>
            <span className="highlight">{validationResults.length}</span>{' '}
            participant account{pluralS} successfully added to {eventName}
          </div>
        </div>
      );
    }
    successNode = (
      <div className="bulk-event-import__success-node">{successNode}</div>
    );
    resultsNode = (
      <div className="cute-12-desktop">
        {successNode}
        <table className="admin-table bulk-event-import__event-table">
          <tbody>
            <tr>
              <th className="admin-table__header"> Row </th>
              <th className="admin-table__header"> Status </th>
              <th className="admin-table__header"> First Name </th>
              <th className="admin-table__header"> Last Name </th>
              <th className="admin-table__header"> Role </th>
              <th className="admin-table__header"> Email </th>
              <th className="admin-table__header"> Group </th>
            </tr>
            {validationResults.map((attendee, i) => (
              <AttendeeRow
                {...attendee}
                rowCount={i + 1}
                importSuccess={importSuccess}
                key={`participant-${i}`}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // set up errors for selected file
  let fileErrorNode = null;
  if (errors) {
    fileErrorNode = <div className="tc__error">{errors}</div>;
  }

  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const canSave = !loading && file && eventId;
  let previewButtonText = 'Preview Import';
  let previewButtonClass = '';
  if (importAttendees) {
    previewButtonText = 'Choose New File';
    previewButtonClass = 'v5__btn--blue-border';
  }

  let previewButtonNode = (
    <button
      className={`v5__btn bulk-event-import__button ${previewButtonClass}`}
      disabled={!canSave}
      onClick={() => handleSubmit(false)}
    >
      <Spinner className="admin__littlespinner" loading={loading} />
      {previewButtonText}
    </button>
  );

  let fileNode;
  let fileButtonClass = 'v5__btn v5__btn--blue-border';
  let fileSelectHeader = 'Choose file';
  if (fileName) {
    fileNode = (
      <div className="bulk-event-import__file-node">
        <span className="tc-v4-page bulk-event-import__file" />
        {fileName}
      </div>
    );
    if (loading) {
      fileButtonClass += ' disabled';
    }
    if (flaggedRowCount !== 0 || invalidRowCount !== 0) {
      previewButtonNode = (
        <React.Fragment>
          <input
            className="bulk-upload__file-input"
            id="file"
            type="file"
            accept=".csv,.tsv"
            onChange={handleChangeFile}
            disabled={loading}
          />
          <label htmlFor="file" className={fileButtonClass} disabled={loading}>
            Choose new file
          </label>
        </React.Fragment>
      );
    }
    fileButtonClass = 'v5__link';
    fileSelectHeader = 'Choose a new file';
  }

  if ((importAttendees && flaggedRowCount === 0) || importSuccess) {
    previewButtonNode = null;
  }

  let importButton;
  if (importAttendees) {
    importButton = (
      <button
        className={'v5__btn bulk-event-import__button'}
        disabled={!canSave}
        onClick={() => handleSubmit(true)}
      >
        <Spinner className="admin__littlespinner" loading={loading} />
        Upload List
      </button>
    );
  }

  const exactlyNode = <span className="bold">exactly</span>;

  return (
    <div className="tc__page">
      <Header page="BulkEventImport" {...props} />
      {alertNode}
      <div className="row">
        <div className="cute-9-desktop v5__heading-1 bulk-event-import__topline">
          Bulk Importer: Event Participants
        </div>
        <div className="cute-9-desktop v5__body-copy">
          Use this tool to pre-register teachers and leaders for an upcoming
          event. You can also use this tool assign people to groups in bulk.
        </div>
        <div className="cute-9-desktop v5__heading-3">
          What Happens When You Pre-Register Participants
        </div>
        <div className="cute-9-desktop">
          <div className="v5__heading-4 bulk-event-import__header">
            <span className="tc-v4-profile" /> Creates a BetterLesson Lab
            account (if the user doesn’t already have one)
          </div>
          <div className="v5__body-copy">
            Registered users will have access to the Lab homepage, where they
            can view their upcoming events, and access our full library of
            strategies.
          </div>
        </div>
        <div className="cute-9-desktop">
          <div className="v5__heading-4 bulk-event-import__header">
            <span className="tc-icon_checklist" /> Adds their name and email to
            the Event Attendee List
          </div>
          <div className="v5__body-copy">
            Registered users will receive automated event reminder emails and,
            if applicable, you can assign or change their groups. From this
            list, you can also copy email addresses for sending emails directly
            to all attendees.
          </div>
        </div>
        <div className="cute-9-desktop">
          <div className="v5__heading-4 bulk-event-import__header">
            <span className="tc-icon_email" /> Triggers a “save the date” email
          </div>
          <div className="v5__body-copy">
            This email is delivered immediately upon successful upload. It
            includes event information and an introduction to the BetterLesson
            Lab, for first-time Lab users.
          </div>
        </div>
        <div className="cute-9-desktop">
          <div className="v5__heading-3 bulk-event-import__list-instructions">
            How to Import Your Event Participants List
          </div>
          <div className="v5__body-copy">
            To start your bulk import, create a copy of the {templateLink} and
            fill in the required information. Upload your completed list as a
            CSV or TSV file.
          </div>
        </div>
        <div className="cute-9-desktop">
          <div className="v5__body-copy">Required information</div>
          <div className="v5__body-copy">
            <ul className="bulk-upload__required-fields-list">
              <li>First Name</li>
              <li>Last Name</li>
              <li>Role</li>
              <li>Email address</li>
            </ul>
          </div>
        </div>
        <div className="cute-9-desktop">
          <div className="v5__heading-4 bulk-event-import__list-instructions">
            Assigning Groups
          </div>
          <div className="v5__body-copy">
            If you want to use the bulk importer to assign groups, you will
            first need to create the groups on your event. Then, copy the group
            names {exactlyNode} as they appear in the Lab into the Group Name
            column of your spreadsheet. If any group names in your spreadsheet
            do not match what’s in the Lab, the list will not be imported.
          </div>
        </div>
        <div className="cute-9-desktop">
          <div className="v5__heading-3">
            Upload Your Event Participants List
          </div>
        </div>
        <div className="cute-6-desktop">
          <div className="v5__heading-5 bulk-event-import__list-instructions">
            Select Event
          </div>
          <MultiSelect
            className="form-field"
            handleSelectOption={handleSelectOption}
            selectedOptions={eventId ? [selectedEvent] : []}
            dropdownOptions={eventOptions}
            minCharCountForResults={0}
            stayOpenOnSelect={false}
            maximumSelectedOptionsCount={1}
            isSelectSearch={true}
            placeholder="Search events"
            preventAutoFocus={true}
          />
        </div>
        <div className="cute-9-desktop">
          <div className="v5__heading-5">Select List</div>
        </div>
        <div className="cute-12-desktop">
          <div>
            {fileNode}
            <input
              className="bulk-upload__file-input"
              id="file"
              type="file"
              accept=".csv,.tsv"
              onChange={handleChangeFile}
              disabled={loading}
            />
            <label
              htmlFor="file"
              className={fileButtonClass}
              disabled={loading}
            >
              {fileSelectHeader}
            </label>
            {fileErrorNode}
          </div>
        </div>
        {resultsNode}
        <div className="cute-12-desktop bulk-event-import__buttons">
          {importButton}
          {previewButtonNode}
        </div>
      </div>
    </div>
  );
};

BulkEventImport.propTypes = {
  events: PropTypes.array.isRequired,
};

export default BulkEventImport;
