// @ts-nocheck FIXME
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import PropTypes from 'prop-types';

const FacilitatorCard = (props) => {
  return (
    <div className="event__attendee-facilitator v5__capsule v5__capsule--padding-0">
      <div className="decoration-bar decoration-bar--blue-gradient" />
      <div className="event__attendee-facilitator-content">
        <UserPhoto
          src={props.facilitator.photo}
          userId={props.facilitator.id}
          firstName={props.facilitator.first_name}
          lastName={props.facilitator.last_name}
          diameter={100}
        />
        <div className="event__attendee-facilitator-name">
          {props.facilitator.first_name} {props.facilitator.last_name}
          <div className="v5__body-copy">BetterLesson Coach</div>
        </div>
      </div>
    </div>
  );
};

export default FacilitatorCard;

FacilitatorCard.propTypes = {
  facilitator: PropTypes.object.isRequired,
};
