// @ts-nocheck FIXME
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import SortableItems from '../Common/SortableItems';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import DomainsDelete from '../Domains/DomainsDelete';
import Utils from './../../../../components/DynamicUtils';
import { Router } from './../../../../components/Router';
import AbsoluteAlert from './../../../Alerts/Absolute';
import LayerLink from './../../../LayerManager/LayerLink';
import GreyOverlayDialogue from './../../../Modals/GreyOverlayDialogue';
import NavigationLink from './../../../NavigationLink';
import CKEditor from './../../Common/CKEditor/CKEditor';
import MultiSelect from './../../Common/MultiSelect';
import StickyFooter from './../../Common/StickyFooter';
import StickyLeftNav from './../../Common/StickyLeftNav';
import AddExistingStrategyModal from './AddExistingStrategyModal';

const Domain = (props) => {
  let alertNode;
  let growthAreas = [];
  let strategiesNode = null;
  let strategyPacksNode = null;
  let aboutDomainNode = null;

  if (!Utils.isEmpty(props.ui.alert)) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // track form errors
  const errorClasses = {},
    errorMessages = {};

  // set up error states for form fields
  const formFieldNames = ['title', 'tagline'];
  formFieldNames.forEach((fieldName) => {
    errorClasses[fieldName] = '';
    errorMessages[fieldName] = null;
    if (fieldName in props.ui.errors) {
      errorClasses[fieldName] = ' error';
      errorMessages[fieldName] = (
        <div className="tc__error">{props.ui.errors[fieldName]}</div>
      );
    }
  });

  // mark page that it needs saving
  const dirtyPage = () => {
    WindowHelpers.navigationConfirmation(true);
  };

  const handleShowMenu = (e) => {
    e.preventDefault();
    props.toggleAddStrategyMenu();
  };

  const handleAddExistingStrategy = (e) => {
    e.preventDefault();
    props.ui.addExistingStrategyModal.open();
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    props.ui.addExistingStrategyModal.close();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    Router.route('/bl/curation/domains');
  };

  const handleSave = (e) => {
    e.preventDefault();

    props.saveDomain({
      about:
        CKEDITOR.instances[
          'outcome-story__text-wysiwyg-aboutDomainTextEntry-0'
        ].getData(),
    });
  };

  const sortCallbackStrategies = (elementIds) => {
    dirtyPage();
    const data = { focusAreaId: props.domain.id, strategies: elementIds };
    props.sortStrategies(data);
  };

  const sortCallbackStrategyPacks = (elementIds) => {
    dirtyPage();
    const data = {
      focusAreaId: props.domain.id,
      strategyPacks: elementIds,
    };
    props.sortStrategyPacks(data);
  };

  const handleStrategyPackSwitch = (e) => {
    dirtyPage();
    const strategyPackId = e.target.dataset.strategypack;
    const publishStatusId = e.target.checked
      ? e.target.dataset.publish
      : e.target.dataset.draft;
    const data = {
      id: parseInt(strategyPackId),
      publish_status_id: parseInt(publishStatusId),
      customBodyText: 'Are you sure you want to unpublish this strategy pack?',
      label: 'Unpublish',
    };

    if (!e.target.checked) {
      props.ui.confirmPublishUnpublishPackModal.open(data);
    } else {
      props.domainUpdateStrategyPack(data);
    }
  };

  // Setup the modal node
  let modalNode = null;

  if (props.ui.addExistingStrategyModal.opened) {
    modalNode = (
      <AddExistingStrategyModal
        {...props.ui.addExistingStrategyModal}
        submit={props.addExistingStrategyToFocusArea}
      />
    );
  } else if (props.ui.confirmPublishUnpublishModal.opened) {
    modalNode = (
      <ConfirmDeleteModal
        {...props.ui.confirmPublishUnpublishModal}
        onDelete={props.domainUpdateGrowthArea}
      />
    );
  } else if (props.ui.confirmPublishUnpublishPackModal.opened) {
    modalNode = (
      <ConfirmDeleteModal
        {...props.ui.confirmPublishUnpublishPackModal}
        onDelete={props.domainUpdateStrategyPack}
      />
    );
  }

  if (props.domain && props.domain.about) {
    aboutDomainNode = (
      <div className="outcome-story__text-wysiwyg-container">
        <CKEditor
          id="aboutDomainTextEntry"
          onBlur={dirtyPage}
          increment={0}
          innerText={props.domain.about}
          className={'outcome-story__text-wysiwyg'}
          configs="/legacy-libs/configs/bl-cke-config-curation-v4-with-styles.js"
        />
      </div>
    );
  }

  if (props.domain && props.domain.growthAreas) {
    growthAreas = props.domain.growthAreas.map((growthArea) => {
      const growthAreaPublishStatus = Utils.getCuratedContentPublishStatusText(
        growthArea.ld_publish_status_id
      );
      const growthAreaInactiveClass =
        growthAreaPublishStatus === 'Live' ? '' : ' curation__status--grey';
      const publishedStatus = (
        <div>
          <div className={`curation__status${growthAreaInactiveClass}`}>
            {growthAreaPublishStatus}
          </div>
        </div>
      );

      return (
        <div
          key={'growthArea-' + growthArea.id}
          className={'ppd-v3-curation__row cute-12-desktop'}
          data-element-type={'domain'}
          data-item={growthArea.id}
        >
          <div className="row">
            <div className="cute-10-desktop">
              <div className="curation__row-copy--small-left-padding">
                {growthArea.title}
              </div>
              <div className="curation__row-copy-nickname--small-left-padding">
                {growthArea.nickname}
              </div>
            </div>
            <div className="ppd-v3-curation__switch-container cute-2-desktop">
              {publishedStatus}
            </div>
          </div>
        </div>
      );
    });
  }

  if (props.domain && props.domain.strategyPacks) {
    strategyPacksNode = props.domain.strategyPacks.map((strategyPack) => {
      const draftIndex = Utils.getItemIndexFromArrayOfObjects(
        props.strategyPackPublishOptions,
        'name',
        'draft'
      );
      const publishIndex = Utils.getItemIndexFromArrayOfObjects(
        props.strategyPackPublishOptions,
        'name',
        'published'
      );

      const editNode = (
        <NavigationLink
          url={
            '/bl/curation/domain/' +
            props.domain.id +
            '/strategypack/' +
            strategyPack.id +
            '/edit'
          }
          className="tc__link tc-pencil"
        />
      );

      const deleteNode = (
        <LayerLink
          params={{
            selectedStrategyPackId: strategyPack.id,
            selectedFocusAreaId: props.domain.id,
            handleDelete: props.handleDeleteStrategyPackFromDomain,
            title: strategyPack.title,
            className: 'portfolio',
          }}
          layer="new"
          className="tc__link icon tc-delete"
          title="Delete {strategyPack.title}"
          component={DomainsDelete}
          wrapper={GreyOverlayDialogue}
        />
      );

      const publishedStatus = (
        <div>
          <input
            type="checkbox"
            data-strategypack={strategyPack.id}
            data-draft={props.strategyPackPublishOptions[draftIndex].id}
            data-publish={props.strategyPackPublishOptions[publishIndex].id}
            id={'strategyPack-switch-' + strategyPack.id}
            onChange={handleStrategyPackSwitch}
            checked={
              !!(
                strategyPack.publish_status_id &&
                props.strategyPackPublishOptions[publishIndex].id ===
                  strategyPack.publish_status_id
              )
            }
            className="ppd-v3-curation__switch-input"
          />
          <label
            htmlFor={'strategyPack-switch-' + strategyPack.id}
            className="ppd-v3-curation__inline-label ppd-v3-curation__switch-label"
          >
            <span className="ppd-v3-curation__switch ppd-v3-curation__switch--has-label">
              <span className="ppd-v3-curation__switch-btn" />
            </span>
          </label>
        </div>
      );

      return (
        <div
          key={'strategyPack-' + strategyPack.id}
          className={'ppd-v3-curation__row cute-12-desktop'}
          data-element-type={'domain'}
          data-item={strategyPack.id}
        >
          <div className="row">
            <div className="cute-8-desktop">
              <div className="domains__move-handle">
                <div className="tc-dots-8 domains__drag-icon domains__drag-icon--nav ppd-v3-curation__drag-icon ppd-v3-curation__drag-icon--domain" />
              </div>
              <div className="ppd-v3-curation__row-copy">
                {strategyPack.title}
              </div>
            </div>
            <div className="ppd-v3-curation__switch-container cute-2-desktop">
              {publishedStatus}
            </div>

            <div className="ppd-v3-curation__options-container cute-2-desktop">
              <div className="row">
                <div className="ppd-v3-curation__option cute-6-desktop">
                  {editNode}
                </div>
                <div className="ppd-v3-curation__option cute-6-desktop">
                  {deleteNode}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  const SortableSectionStrategyPacks = SortableItems(
    <div
      id="dragContainerStrategyPacks"
      className="domains__sidebar-nav ppd-v3-curation__table ppd-v3-curation__table--domains row"
    >
      {strategyPacksNode}
    </div>,
    '#dragContainerStrategyPacks',
    'window',
    sortCallbackStrategyPacks
  );

  if (props.domain && props.domain.strategies) {
    strategiesNode = props.domain.strategies.map((strategy) => {
      let visibility = null;
      if ('publish_status_id' in strategy) {
        const visibilityIndex = Utils.getItemIndexFromArrayOfObjects(
          props.publishOptions,
          'id',
          strategy.publish_status_id
        );
        if (visibilityIndex !== -1) {
          const visibilityClass =
            props.publishOptions[visibilityIndex].name === 'draft'
              ? ' curation__status--grey'
              : '';
          visibility = (
            <div className={'curation__status' + visibilityClass}>
              {props.publishOptions[visibilityIndex].label}
            </div>
          );
        }
      }
      let status = null;
      if ('curation_status_id' in strategy) {
        const curationIndex = Utils.getItemIndexFromArrayOfObjects(
          props.curationOptions,
          'id',
          strategy.curation_status_id
        );
        if (curationIndex !== -1) {
          let statusClass = '';
          switch (props.curationOptions[curationIndex].name) {
            case 'unstarted':
              statusClass = ' curation__status--grey';
              break;
            case 'needs_review':
              statusClass = ' curation__status--orange';
              break;
            case 'complete':
              statusClass = ' curation__status--green';
              break;
          }
          status = (
            <div className={'curation__status' + statusClass}>
              {props.curationOptions[curationIndex].label}
            </div>
          );
        }
      }

      // const deleteNode = props.canDelete ? (
      const deleteNode = (
        <LayerLink
          params={{
            selectedStrategyId: strategy.id,
            selectedFocusAreaId: props.domain.id,
            handleDelete: props.handleDeleteStrategyFromDomain,
            title: strategy.title + ' from the focus area',
            className: 'portfolio',
          }}
          layer="new"
          className="tc__link icon tc-delete"
          title="Delete {strategy.title}"
          component={DomainsDelete}
          wrapper={GreyOverlayDialogue}
        />
      );
      return (
        <div
          key={'strategy-' + strategy.id}
          className="ppd-v3-curation__row clearfix"
          data-element-type={'domain'}
          data-item={strategy.id}
        >
          <div className="cute-6-desktop">
            <div className="domains__move-handle">
              <div className="tc-dots-8 domains__drag-icon domains__drag-icon--nav ppd-v3-curation__drag-icon ppd-v3-curation__drag-icon--domain" />
            </div>
            <div className="ppd-v3-curation__row-copy">{strategy.title}</div>
          </div>
          <div className="ppd-v3-curation__switch-container cute-2-desktop">
            {status}
          </div>
          <div className="ppd-v3-curation__switch-container cute-2-desktop">
            {visibility}
          </div>
          <div className="ppd-v3-curation__switch-container ppd-v3-curation__options-container cute-2-desktop">
            <div className="row">
              <div className="ppd-v3-curation__option cute-6-desktop">
                <NavigationLink
                  url={
                    '/bl/curation/domain/' +
                    props.domain.id +
                    '/strategy/' +
                    strategy.id +
                    '/edit'
                  }
                  className="tc__link tc-pencil"
                />
              </div>
              <div className="ppd-v3-curation__option cute-6-desktop">
                {deleteNode}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  const SortableSectionStrategies = SortableItems(
    <div
      id="dragContainerStrategies"
      className="domains__sidebar-nav ppd-v3-curation__table ppd-v3-curation__table--domains row"
    >
      {strategiesNode}
    </div>,
    '#dragContainerStrategies',
    'window',
    sortCallbackStrategies
  );

  let addStrategyMenuNode = null;
  if (props.ui.addStrategyShowMenu) {
    addStrategyMenuNode = (
      <div>
        <div
          onClick={handleCloseModal}
          className="clip-modal modal__page-overlay modal__page-overlay--add ppd-v3-curation-modal__page-overlay curation__overlay"
        />

        <div className="curation__tooltip tc__body-copy curation__top">
          <div
            className="tc__link curation__tooltip-row"
            onClick={handleAddExistingStrategy}
          >
            Add Existing
          </div>
          <NavigationLink
            className="tc__link curation__tooltip-row"
            url={'/bl/curation/domain/' + props.domain.id + '/strategy/add'}
          >
            Add New
          </NavigationLink>
        </div>
      </div>
    );
  }

  let strategyAddNode;
  if (props.domain && props.domain.strategies) {
    strategyAddNode = (
      <div
        className="bl4__btn bl4__btn--blue bl4__btn--small"
        onClick={handleShowMenu}
      >
        Add
        {addStrategyMenuNode}
      </div>
    );
  }
  let strategyPackAddNode;
  if (props.domain && props.domain.strategyPacks) {
    strategyPackAddNode = (
      <NavigationLink
        url={`/bl/curation/domain/${props.domain.id}/strategypack/add`}
        className="bl4__btn bl4__btn--blue bl4__btn--small"
      >
        Add
      </NavigationLink>
    );
  }

  const focusAreaVisibilityStatus = props.domain.publish_status;

  let addMasterTeachersNode = null;

  if (
    props.ui.addMasterTeachers ||
    (props.masterTeachers && props.masterTeachers.length)
  ) {
    addMasterTeachersNode = (
      <div className="cd-strategy__details row">
        <div className="cute-12-desktop left">
          <div className="tc__headline-3">Master Teachers</div>
          <MultiSelect
            {...props.ui.editDomain.masterTeachers}
            field="masterTeachers"
            feature="editDomain"
            minCharCountForResults={4}
            getVisibleOptions={props.getMasterTeacherOptions}
            analyticsLabel={''}
            readOnly={true}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="tc__page curation">
      <Header page="Curation" {...props} />
      {alertNode}
      {modalNode}

      <div className="row row--1200 tc__body-copy">
        <div className="cute-12-desktop ppd-v3-curation__breadcrumbs">
          <NavigationLink url="/bl/curation" className="tc__link">
            Content Curation
          </NavigationLink>
          <span className="tc-arrow ppd-v3-curation__breadcrumb-separator" />
          <NavigationLink url="/bl/curation/domains" className="tc__link">
            Focus Areas
          </NavigationLink>
          <span className="tc-arrow ppd-v3-curation__breadcrumb-separator" />
          {props.domain.title ? props.domain.title : 'New Focus Area'}
        </div>
      </div>

      <div className="row row--1200">
        <StickyLeftNav
          mainPageClass="curation__main-col"
          sectionNames={[
            'Introduction',
            'Topics and Outcomes',
            'Strategies',
            'Strategy Packs',
          ]}
        />

        <div className="cute-8-desktop cute-2-desktop-offset left cute-12-phone cute-0-phone-offset curation__main-col">
          <form className="tc__body-copy ppd-v3-curation__edit-form">
            <div id="section-0" className="row">
              <div className="cute-12-desktop">
                <span className="tc__headline-3">Focus Area</span>
              </div>
            </div>

            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">Title</div>
                <p>{props.domain.title}</p>
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">Tagline</div>
                <p>{props.domain.tagline}</p>
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">About Focus Area</div>
                {aboutDomainNode}
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-12-desktop left">
                <div className="ppd-v3-curation__label">Visibility</div>
                {focusAreaVisibilityStatus
                  ? focusAreaVisibilityStatus.name
                  : '—'}
              </div>
            </div>
            <div id="section-1" className="cd-strategy__details row">
              <span className="tc__headline-3">Topics and Outcomes</span>
              <div className="cute-12-desktop">
                <div className="row ppd-v3-curation__domains-header">
                  <div className="ppd-v3-curation__domains-header-item cute-10-desktop">
                    Name
                  </div>
                  <div className="ppd-v3-curation__domains-header-item cute-2-desktop">
                    Status
                  </div>
                </div>
                {growthAreas.map((growthArea, i) => {
                  return (
                    <div
                      key={i}
                      className="domains__sidebar-nav ppd-v3-curation__table ppd-v3-curation__table--domains row"
                    >
                      {growthArea}
                    </div>
                  );
                })}
              </div>
            </div>
            <div id="section-2" className="cd-strategy__details row">
              <span className="tc__headline-3">Strategies</span>
              <span className={'right'}>{strategyAddNode}</span>
              <div className="cute-12-desktop">
                <div className="row ppd-v3-curation__domains-header">
                  <div className="ppd-v3-curation__domains-header-item cute-6-desktop">
                    Name
                  </div>
                  <div className="ppd-v3-curation__domains-header-item cute-2-desktop">
                    Status
                  </div>
                  <div className="ppd-v3-curation__domains-header-item cute-2-desktop">
                    Visibility
                  </div>
                  <div className="ppd-v3-curation__domains-header-item cute-2-desktop" />
                </div>
                <SortableSectionStrategies />
              </div>
            </div>
            <div id="section-3" className="cd-strategy__details row">
              <span className="tc__headline-3">Strategy Packs</span>
              <span className={'right'}>{strategyPackAddNode}</span>
              <div className="cute-12-desktop">
                <div className="row ppd-v3-curation__domains-header">
                  <div className="ppd-v3-curation__domains-header-item cute-8-desktop">
                    Name
                  </div>
                  <div className="ppd-v3-curation__domains-header-item cute-2-desktop">
                    Published
                  </div>
                  <div className="ppd-v3-curation__domains-header-item cute-2-desktop">
                    Actions
                  </div>
                </div>
                <SortableSectionStrategyPacks />
              </div>
            </div>
            {addMasterTeachersNode}
          </form>
        </div>
      </div>
      <StickyFooter
        loading={props.ui.loading}
        submit={handleSave}
        hasCancelLink={true}
        cancel={handleCancel}
        cancelText="Close"
      />
    </div>
  );
};

Domain.PropTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default Domain;
