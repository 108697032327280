// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const CohortRow = (props) => {
  let className = '';
  if (moment(props.term_end) < moment()) {
    className = 'v5__cohorts-table-expired';
  }
  let cohortName = props.display_name;
  if (props.currentYear === 'current' || props.post_2019) {
    cohortName = (
      <NavigationLink
        url={`/lab/cohort/${props.id}/overview`}
        className="tc__link"
      >
        {cohortName}
      </NavigationLink>
    );
  }
  return (
    <div className="cute-100 partner_lead_v5__cohort-table-row">
      <div className="cute-4-desktop">{cohortName}</div>

      <div className={`cute-2-desktop ${className}`}>{props.product.name}</div>

      <div className={`cute-2-desktop ${className}`}>
        {moment(props.term_start).utc().format('MMMM D, Y')}
      </div>

      <div className={`cute-2-desktop ${className}`}>
        {moment(props.term_end).utc().format('MMMM D, Y')}
      </div>

      <div className={`cute-2-desktop ${className}`}>{props.capacity}</div>
    </div>
  );
};

CohortRow.propTypes = {
  product: PropTypes.object.isRequired,
  display_name: PropTypes.string.isRequired,
  term_start: PropTypes.string.isRequired,
  term_end: PropTypes.string.isRequired,
  capacity: PropTypes.number.isRequired,
};

export default CohortRow;
