// @ts-nocheck FIXME
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';
import AbsoluteAlert from './../../Alerts/Absolute';

const BulkParticipantImporter = (props) => {
  const [cohortId, setCohortId] = React.useState(null);
  const [affiliation, setAffiliation] = React.useState('partner');
  let file;

  const handleChangeFile = (e) => {
    props.setFileName(e.target.files[0] ? e.target.files[0].name : '');
  };

  const handleSelectOption = (selectedCohort) => {
    if (cohortId) {
      setCohortId(null);
      return;
    }

    setCohortId(selectedCohort.id);
  };

  const handleSelectAffiliation = (selectedAffiliation) => {
    if (affiliation) {
      setAffiliation(null);
      return;
    }

    setAffiliation(selectedAffiliation.id);
  };

  const handleSubmit = (createUsers, e) => {
    e.preventDefault();

    props.submit({
      file: file.files[0],
      formData: {
        createUsers: createUsers,
        cohort: cohortId,
        affiliation,
      },
    });
  };

  const handleCancelSubmit = (e) => {
    e.preventDefault();
    // the file upload request is saved in Request.js
    if (window.xhrPostRequest) {
      window.xhrPostRequest.abort();
      props.cancelSubmit();
    }
  };

  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const cohortOptions = props.cohorts
    .map((c) => ({
      label: c.name,
      id: c.id,
    }))
    .sort((a, b) => (a.label || '').localeCompare(b.label));

  const selectedCohort = cohortOptions.find((c) => {
    return c.id === cohortId;
  });

  const affiliationOptions = [
    { id: 'partner', label: 'Partner' },
    { id: 'bl team', label: 'BL Team (test accounts)' },
  ];

  const selectedAffiliation = affiliationOptions.find((a) => {
    return a.id === affiliation;
  });

  // set up errors for selected file
  let fileErrorNode = null;
  if ('file' in props.ui.errors) {
    fileErrorNode = <div className="tc__error">{props.ui.errors.file}</div>;
  }

  // determine whether to show the form submit buttons and/or loading animation
  // and/or results table and/or import success message at the bottom of the page
  let resultsNode = null;
  // disable buttons when form submission is loading
  let disabledClass = '';
  let spinnerNode = null;
  if (props.ui.loading) {
    disabledClass = ' disabled';
    spinnerNode = <span className="littlespinner"></span>;
  }
  // show the results of validating the data after submitting the form,
  // if there are no errors in the global fields and the file itself is valid
  if (props.validationResults && !Object.keys(props.ui.errors).length) {
    // show the file name and row count
    let readyTextNode = (
      <div className="bulk-upload__ready-text">
        <span className="highlight">Ready to Import:</span> {props.fileName}
      </div>
    );
    let rowCountNode = (
      <div className="bulk-upload__row-count-text">
        <span className="highlight">{props.userCount}</span> user accounts ready
        to import
      </div>
    );
    // show both buttons if there are no errors in the results
    let buttonNode = (
      <div className="bulk-upload__submit-btns">
        <button
          onClick={(e) => handleSubmit(true, e)}
          className={
            'bulk-upload__submit-btn tc__btn tc__btn--blue tc__btn--small' +
            disabledClass
          }
        >
          {spinnerNode}
          Import Users
        </button>
        <button
          onClick={(e) => handleSubmit(false, e)}
          className={
            'bulk-upload__submit-btn tc__btn tc__btn--blue tc__btn--small' +
            disabledClass
          }
        >
          New Preview
        </button>
      </div>
    );
    // if there are any rows with errors,
    // then show the invalid row count and hide the "Import Users" button
    if (props.invalidUserCount) {
      readyTextNode = null;
      rowCountNode = (
        <div className="bulk-upload__row-count-text">
          <span className="highlight">{props.invalidUserCount}</span> invalid
          rows found out of {props.userCount} rows
        </div>
      );
      buttonNode = (
        <div className="bulk-upload__submit-btns">
          <button
            onClick={(e) => handleSubmit(false, e)}
            className={
              'bulk-upload__submit-btn tc__btn tc__btn--blue tc__btn--small' +
              disabledClass
            }
          >
            New Preview
          </button>
        </div>
      );
    } else if (!props.userCount) {
      readyTextNode = null;
      rowCountNode = (
        <div className="bulk-upload__row-count-text">
          No users found in file
        </div>
      );
      buttonNode = (
        <div className="bulk-upload__submit-btns">
          <button
            onClick={(e) => handleSubmit(false, e)}
            className={
              'bulk-upload__submit-btn tc__btn tc__btn--blue tc__btn--small' +
              disabledClass
            }
          >
            New Preview
          </button>
        </div>
      );
    } else if (props.ui.importSuccess) {
      readyTextNode = null;
      rowCountNode = (
        <div>
          <div className="bulk-upload__ready-text">
            <span className="highlight">Import Successful:</span>{' '}
            {props.fileName}
          </div>

          <div className="bulk-upload__row-count-text">
            <span className="tc-check"></span>
            <span className="highlight">{props.userCount}</span> user accounts
            successfully added to TeachCycle
          </div>
        </div>
      );
      buttonNode = null;
    }

    // set up results table headers
    let headersNode = props.validationResultsHeaders.map((field) => {
      return (
        <th key={'header-' + field} className="bulk-upload__table-header">
          {field}
        </th>
      );
    });
    headersNode = (
      <tr>
        <th className="bulk-upload__table-header">Row</th>
        <th className="bulk-upload__table-header bulk-upload__table-header--status">
          Status
        </th>
        {headersNode}
      </tr>
    );

    // build results table
    let i = 0;
    const validationResultsNode = props.validationResults.map((row) => {
      i += 1;
      // show a check if the row has no errors/warnings,
      // or a list of errors/warnings if there are some
      let status = [];
      let hasIssues = false;
      if (row.errors && row.errors.length) {
        hasIssues = true;
        status = row.errors.map((error) => {
          return (
            <div key={i + '-' + error}>
              <span className="tc-x"></span> {error}
            </div>
          );
        });
      }
      if (row.warnings && row.warnings.length) {
        hasIssues = true;
        status = status.concat(
          row.warnings.map((warning) => {
            return (
              <div key={i + '-' + warning}>
                <span className="tc-warning"></span> {warning}
              </div>
            );
          })
        );
      }
      if (!hasIssues || props.ui.importSuccess) {
        status = [<span key="check" className="tc-check"></span>];
        // after successful import, we want to show whether or not we created a new user record
        if (row.user_type) {
          status = status.concat(
            <span key="user-type" className="">
              {row.user_type}
            </span>
          );
        }
      }

      return (
        <tr className="user-search-table__row" key={i}>
          <td className="bulk-upload__table-col">{i}</td>
          <td className="bulk-upload__table-col">{status}</td>
          {props.validationResultsHeaders.map((field) => {
            let errorClass = '';
            if (row.invalidFields.indexOf(field) != -1) {
              errorClass = ' error';
            } else if (row.flaggedFields.indexOf(field) != -1) {
              errorClass = ' warning';
            }
            return (
              <td
                key={field + i}
                className={'bulk-upload__table-col' + errorClass}
              >
                {row[field]}
              </td>
            );
          })}
        </tr>
      );
    });

    resultsNode = (
      <div className="bulk-upload__results-container">
        {readyTextNode}
        {rowCountNode}

        {buttonNode}
        <table className="bulk-upload__table">
          <tbody>
            {headersNode}
            {validationResultsNode}
          </tbody>
        </table>
        {buttonNode}
      </div>
    );
  } else {
    // by default, show the "Preview" form submit button
    let loadingStateNode = null;
    if (props.ui.loading) {
      loadingStateNode = (
        <div className="bulk-upload__loading-text">
          <div className="littlespinner"></div> Validating {props.fileName}
          <a
            href="#cancel-preview"
            className="tc__link bulk-upload__cancel"
            onClick={handleCancelSubmit}
          >
            Cancel
          </a>
        </div>
      );
    } else if (props.ui.importSuccess) {
      loadingStateNode = (
        <div>
          <div className="bulk-upload__ready-text">
            <span className="highlight">Import Successful:</span>{' '}
            {props.fileName}
          </div>

          <div className="bulk-upload__row-count-text">
            <span className="tc-check"></span>
            <span className="highlight">{props.userCount}</span> user accounts
            successfully added to TeachCycle
          </div>
        </div>
      );
    }
    resultsNode = (
      <div>
        <button
          onClick={(e) => handleSubmit(false, e)}
          className={
            'bulk-upload__submit-btn tc__btn tc__btn--blue tc__btn--small' +
            disabledClass
          }
        >
          Preview
        </button>
        {loadingStateNode}
      </div>
    );
  }

  return (
    <div>
      <Header page="AdminDashboard" {...props} />
      {alertNode}

      <div className="row">
        <div className="cute-12-desktop admin-v2__headline tc__headline-1">
          Bulk Importer - Participants
        </div>

        <div className="cute-12-desktop tc__body-copy">
          Import multiple users by uploading a CSV or TSV file. Please create a
          copy of the{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link"
            href="https://docs.google.com/spreadsheets/d/1evinpslqmlHVVHl8ZT_XlVcgfcsYjR7qnBf1JELE7BM/edit#gid=0"
          >
            Master Template
          </a>{' '}
          on your own Google Drive to create a user list.
        </div>
        <div className="cute-12-desktop tc__body-copy">
          <div>The following fields are required for the import:</div>
          <ul className="bulk-upload__required-fields-list">
            <li>Participant FIRST Name</li>
            <li>Participant LAST Name</li>
            <li>School Email Address</li>
            <li>Cohort</li>
          </ul>
        </div>

        <form className="cute-12-desktop left">
          <div className="bulk-upload__form-item">
            <input
              className="bulk-upload__file-input"
              id="file"
              ref={(ref) => (file = ref)}
              type="file"
              accept=".csv,.tsv"
              onChange={handleChangeFile}
            />
            <label
              htmlFor="file"
              className="bulk-upload__file-input-label tc__btn tc__btn--grey tc__btn--small"
            >
              Choose a file
            </label>
            <span>{props.fileName}</span>
            {fileErrorNode}
          </div>

          <div className="bulk-upload__global-fields-header tc__headline-3">
            Set Global Fields
          </div>
          <div className="bulk-upload__form-item">
            <label className="bulk-upload__global-input-label">Cohort *</label>
            <MultiSelect
              error={props.ui.errors.cohort}
              handleSelectOption={handleSelectOption}
              selectedOptions={cohortId ? [selectedCohort] : []}
              dropdownOptions={cohortOptions}
              minCharCountForResults={0}
              stayOpenOnSelect={false}
              maximumSelectedOptionsCount={1}
              isSelectSearch={true}
              placeholder="Search cohorts"
              preventAutoFocus={true}
            />
          </div>
          <div className="bulk-upload__form-item">
            <label className="bulk-upload__global-input-label">
              Affiliation *
            </label>
            <MultiSelect
              handleSelectOption={handleSelectAffiliation}
              selectedOptions={affiliation ? [selectedAffiliation] : []}
              dropdownOptions={affiliationOptions}
              minCharCountForResults={0}
              stayOpenOnSelect={false}
              maximumSelectedOptionsCount={1}
              isSelectSearch={true}
              placeholder="Partner"
              preventAutoFocus={true}
            />
          </div>
          <div className="bulk-upload__form-item clearfix">
            <input
              type="checkbox"
              id="sendEmail"
              checked={props.ui.sendEmail}
              onChange={props.toggleSendEmail}
              className="ppd-v3-curation__switch-input"
            />
            <label
              htmlFor="sendEmail"
              className="bulk-upload__switch-label ppd-v3-curation__switch-label"
            >
              <span className="bulk-upload__global-input-label bulk-upload__global-input-label--switch">
                Send Welcome Email
              </span>
              <span className="bulk-upload__switch-input-area">
                <span className="ppd-v3-curation__switch">
                  <span className="ppd-v3-curation__switch-btn"></span>
                </span>
                <span className="bulk-upload__switch-status">
                  {props.ui.sendEmail ? 'On' : 'Off'}
                </span>
              </span>
            </label>
          </div>

          {resultsNode}
        </form>
      </div>
    </div>
  );
};

BulkParticipantImporter.propTypes = {
  ui: PropTypes.object.isRequired,
  cohorts: PropTypes.array.isRequired,
  setFileName: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  cancelSubmit: PropTypes.func.isRequired,
};

export default BulkParticipantImporter;
