// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';
import ParticipantMeetingInfo from './modules/ParticipantMeetingInfo';
import ParticipantMeetingVideo from './modules/ParticipantMeetingVideo';

const ParticipantMeeting = (props) => {
  const [recordingIndex, setRecordingIndex] = React.useState(
    props.meeting.recordingIndex || 0
  );
  const activeRecording =
    (props.meeting.recordings && props.meeting.recordings[recordingIndex]) ||
    {};
  const isClip = props.meeting.start_time >= 0;

  return (
    <div className="">
      <Header page="ParticipantMeeting" {...props} />

      <div className="meeting row">
        <div className="meeting__video-col cute-6-desktop cute-12-phone">
          <ParticipantMeetingVideo
            content={isClip ? props.meeting.content : activeRecording.content}
            start_time={props.meeting.start_time}
            end_time={props.meeting.end_time}
            recordingIndex={recordingIndex}
            recording={props.recording}
            recordings={props.meeting.recordings}
          />
        </div>
        <div className="meeting__details-col cute-6-desktop cute-12-phone">
          <ParticipantMeetingInfo
            {...props.meeting}
            isClip={isClip}
            recordingIndex={recordingIndex}
            setRecordingIndex={setRecordingIndex}
            recording={props.recording}
          />
        </div>
      </div>
    </div>
  );
};

ParticipantMeeting.propTypes = {
  meeting: PropTypes.object.isRequired,
};

export default ParticipantMeeting;
