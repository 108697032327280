// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import Artifact from './Artifact';

const NarrativeStep = (props) => {
  let commentsNode = null;
  if (props.comments) {
    commentsNode = props.comments.map((comment, i) => {
      return (
        <div
          className="ppp__narrative-comment"
          key={'comment-' + props.step + '-' + i}
        >
          <div className="ppp__narrative-comment-text">
            &ldquo;{comment.data}&rdquo;
          </div>
          <div className="ppp__narrative-comment-author">
            - {comment.first_name} {comment.last_name}
          </div>
        </div>
      );
    });
  }
  let artifactsNode = null;
  if (props.artifacts) {
    artifactsNode = props.artifacts.map((artifact, i) => {
      return (
        <div key={'artifact-' + props.step + '-' + i}>
          <Artifact {...artifact} showArtifact={props.showArtifact} />
        </div>
      );
    });
  }

  return (
    <div className="">
      <PurifiedHTML
        className="ppp__narrative-headline-1"
        content={props.caption}
      />
      <div className="ppp__narrative-bubble ppp__quote">
        &ldquo;{props.feedback}&rdquo;
      </div>
      {commentsNode}
      {artifactsNode}
    </div>
  );
};

NarrativeStep.propTypes = {
  step: PropTypes.string,
  caption: PropTypes.string,
  feedback: PropTypes.string,
  comments: PropTypes.array,
  artifacts: PropTypes.array,
};

export default NarrativeStep;
