// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import GoalCompleteModal from './Goal/GoalCompleteModal';
import GoalUncompleteModal from './Goal/GoalUncompleteModal';
import HighlightModal from './HighlightModal';
import UnhighlightModal from './UnhighlightModal';

const WorkspaceModal = (props) => {
  // set up any open modal
  let modalNode = null;
  if (props.modal.isOpen && props.canEdit) {
    switch (props.modal.type) {
      case 'goalComplete':
        modalNode = (
          <GoalCompleteModal
            data={props.modal.data}
            submit={props.completeGoal}
            close={props.closeModal}
          />
        );
        break;
      case 'goalUncomplete':
        modalNode = (
          <GoalUncompleteModal
            submit={() => props.uncompleteGoal(props.modal.data)}
            close={props.closeModal}
          />
        );
        break;
      case 'entryHighlight':
        modalNode = (
          <HighlightModal
            currentUser={props.user}
            pageUser={props.pageUser}
            title={props.modal.data.title}
            submit={props.highlightEntry}
            update={props.highlightAndUpdateGoalTagToEntry}
            highlightGrowth={props.highlightOutcomeGrowth}
            close={props.closeModal}
            reportingPhases={props.reportingPhases}
            entryType={props.modal.data.entryType}
            goals={props.goals}
            userGoals={props.userGoals}
            selectedGoalIds={props.modal.data.goalIds}
            storyId={props.modal.data.storyId}
            entryId={props.modal.data.entryId}
            outcomeId={props.modal.data.outcomeId}
            tagGoalResponseCallback={props.modal.data.tagGoalResponseCallback}
            updateGoalTagResponseCallback={props.updateGoalTagResponseCallback}
          />
        );
        break;
      case 'editHighlight':
        modalNode = (
          <HighlightModal
            currentUser={props.user}
            pageUser={props.pageUser}
            title={props.modal.data.title}
            submit={props.highlightEntry}
            update={props.highlightAndUpdateGoalTagToEntry}
            close={props.closeModal}
            reportingPhases={props.reportingPhases}
            entryType={props.modal.data.entryType}
            goals={props.goals}
            userGoals={props.userGoals}
            selectedGoalIds={props.modal.data.goalIds}
            storyId={props.modal.data.storyId}
            entryId={props.modal.data.entryId}
            reflection={props.modal.data.reflection}
            tagGoalResponseCallback={props.modal.data.tagGoalResponseCallback}
            updateGoalTagResponseCallback={props.updateGoalTagResponseCallback}
            unhighlightEntry={props.modal.data.unhighlightEntry}
          />
        );
        break;
      case 'entryUnhighlight':
        modalNode = (
          <UnhighlightModal
            growthHighlightId={props.modal.data.growthHighlightId}
            participantName={props.pageUser.first_name}
            submit={props.unhighlightEntry}
            unhighlightGrowth={props.unhighlightOutcomeGrowth}
            close={props.closeModal}
          />
        );
        break;
    }
  }

  return modalNode;
};

WorkspaceModal.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  highlightEntry: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
  pageUser: PropTypes.object.isRequired,
  reportingPhases: PropTypes.object.isRequired,
  uncompleteGoal: PropTypes.func.isRequired,
  unhighlightEntry: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default WorkspaceModal;
