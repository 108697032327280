// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import { useState } from 'react';
import SelectAuthorOption from './SelectAuthorOption';

const SelectAuthorDropdown = (props) => {
  const [authorListExpanded, setAuthorListExpanded] = useState(false);

  const handleExpandAuthorList = () => {
    setAuthorListExpanded(true);

    delayedEventListener(() =>
      document.addEventListener('click', handleCollapseAuthorList, false)
    );
  };
  const handleCollapseAuthorList = () => {
    setAuthorListExpanded(false);
    document.removeEventListener('click', handleCollapseAuthorList);
  };

  // by default, just show the current user with no available interactions
  let selectAuthorNode = (
    <div className="workspace__comment-author-options">
      <SelectAuthorOption user={props.user} authorId={props.newAuthorId} />
    </div>
  );

  // If the user is a coach/admin, they are able to select a new author for this comment
  if (props.canEditAuthor) {
    const currentAuthorOptions = [];
    currentAuthorOptions.push(
      <SelectAuthorOption
        key={`comment-author-option-${props.user.id}`}
        user={props.user}
        updateAuthorId={props.updateAuthorId}
        authorId={props.newAuthorId}
      />
    );

    // If the original author is different from both the current user and
    // the page user then they need to be specially added to the list
    if (
      props.author &&
      props.user.id != props.author.id &&
      props.pageUser.id != props.author.id
    ) {
      currentAuthorOptions.push(
        <SelectAuthorOption
          key={`comment-author-option-${props.author.id}`}
          user={props.author}
          updateAuthorId={props.updateAuthorId}
          authorId={props.newAuthorId}
        />
      );
    }

    currentAuthorOptions.push(
      <SelectAuthorOption
        key={`comment-author-option-${props.pageUser.id}`}
        user={props.pageUser}
        updateAuthorId={props.updateAuthorId}
        authorId={props.newAuthorId}
      />
    );

    const selectAuthorExpandedClass = authorListExpanded
      ? 'workspace__comment-author-options--expanded'
      : '';

    const toggleAuthorList = authorListExpanded
      ? handleCollapseAuthorList
      : handleExpandAuthorList;
    selectAuthorNode = (
      <div
        className={`workspace__comment-author-options ${selectAuthorExpandedClass}`}
        onClick={toggleAuthorList}
      >
        {currentAuthorOptions}
      </div>
    );
  }

  const noReportingDropdownClass = props.hideReporting
    ? 'workspace__comment-author-select--no-reporting'
    : '';

  return (
    <div
      className={`workspace__comment-author-select ${noReportingDropdownClass}`}
    >
      {selectAuthorNode}
    </div>
  );
};

export default SelectAuthorDropdown;

SelectAuthorDropdown.propTypes = {
  author: PropTypes.object,
  user: PropTypes.object,
  pageUser: PropTypes.object,
  updateAuthorId: PropTypes.func,
  authorId: PropTypes.number,
};
