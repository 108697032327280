// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../../components/ArrayHelpers';

const PartnerRow = (props) => {
  const lab = useLegacyLabContext();

  const handleEditPsmClick = (e) => {
    e.preventDefault();
    props.openEditPSMs(props.partner);
  };

  const handleEditDpsClick = (e) => {
    e.preventDefault();
    props.openEditDPs(props.partner);
  };

  const openSendPartnerLeaderSurvey = (e, leadersToSend) => {
    e.preventDefault();
    props.openSendPartnerLeaderSurveyModal({
      partner: props.partner,
      leaders: leadersToSend,
    });
  };

  const discernLeadersForEmail = () => {
    const output = {
      leadersToSend: [],
      earliestSend: 0,
    };

    if (
      !props.partner.cohorts.length ||
      props.partner.lead_count[props.statusFilter] === 0
    ) {
      return output;
    }

    props.partner.cohorts.forEach((cohort) => {
      // this cohort is not currently active
      if (
        moment().isBefore(cohort.term_start) ||
        moment().isAfter(cohort.term_end)
      ) {
        return;
      }
      // no leaders no point to continue
      if (cohort.partner_leaders === 0) {
        return;
      }

      cohort.partner_leaders.forEach((lead) => {
        // set initial sent time
        if (lead.survey_email_sent && !output.earliestSend) {
          output.earliestSend = lead.survey_email_sent;
        }
        // if the email was sent already cant sent again
        if (moment(lead.survey_email_sent).isBefore(cohort.term_end)) {
          return;
        }
        // set the earlier instance of first sent surveys
        if (moment(lead.survey_email_sent).isBefore(output.earliestSend)) {
          output.earliestSend = lead.survey_email_sent;
        }
        // only push a leader to the array once
        if (
          Utils.getItemIndexFromArrayOfObjects(
            output.leadersToSend,
            'user_id',
            lead.user_id
          ) === -1
        ) {
          // push to loop output
          output.leadersToSend.push(lead);
        }
      });
    });

    return output;
  };

  // set up cohort PSMs
  let psmNode;

  // set up cohort DPs
  let dpsNode;

  let canEditClass = '';
  let canEdit;
  if (Utils.isManager.call(lab) || Utils.isAdmin.call(lab)) {
    dpsNode = <div className="tc__link">Add DPs</div>;
    psmNode = <div className="tc__link">Add PSMs</div>;
    canEdit = true;
    canEditClass = ' tc-pencil';
  }
  if (ArrayHelpers.hasElements(props.partner.psms)) {
    psmNode = (
      <div>
        {props.partner.psms.map((psm, i) => {
          let psmKey = (
            <div
              key={`psm-name-${psm.id}`}
            >{`${psm.first_name} ${psm.last_name}`}</div>
          );
          if (props.partner.psms.length === i + 1) {
            psmKey = (
              <div key={psm.id}>
                {`${psm.first_name} ${psm.last_name}`}{' '}
                <span
                  className={`tc__link${canEditClass} admin-table__icon`}
                ></span>
              </div>
            );
          }
          return psmKey;
        })}
      </div>
    );
  }

  if (ArrayHelpers.hasElements(props.partner.dps)) {
    dpsNode = (
      <div>
        {props.partner.dps.map((dp, i) => {
          let dpKey = (
            <div key={dp.id}>{`${dp.first_name} ${dp.last_name}`}</div>
          );
          if (props.partner.dps.length === i + 1) {
            dpKey = (
              <div key={dp.id}>
                {`${dp.first_name} ${dp.last_name}`}{' '}
                <span
                  className={`tc__link${canEditClass} admin-table__icon`}
                ></span>
              </div>
            );
          }
          return dpKey;
        })}
      </div>
    );
  }

  let membersNode = null;
  let cohortCount = null;
  let eventCount = null;
  let surveySentNode = null;

  if (props.partner) {
    membersNode = (
      <NavigationLink
        className="v5__link"
        url={`/bl/partner/${props.partner.id}/leaders`}
      >
        {props.partner.lead_count[props.statusFilter]} Leader
        {props.partner.lead_count[props.statusFilter] === 1 ? '' : 's'}
      </NavigationLink>
    );

    // set data for survey button and when sent
    const surveyDetails = discernLeadersForEmail();

    // set up survey button
    let sendSurveyBtn = null;
    let sendSurveyBtnIsVisible = 'partner-survey__copy--no-btn-visible';
    if (
      (Utils.isManager.call(lab, props.user) ||
        Utils.isAdmin.call(lab, props.user) ||
        Utils.isPartnerLeader.call(lab, props.user)) &&
      surveyDetails.leadersToSend.length > 0
    ) {
      sendSurveyBtnIsVisible = '';
      sendSurveyBtn = (
        <div
          className="partner-survey__btn tc-mail v5__link"
          onClick={(e) =>
            openSendPartnerLeaderSurvey(e, surveyDetails.leadersToSend)
          }
        />
      );
    }
    cohortCount =
      props.partner.cohort_count &&
      +props.partner.cohort_count[props.statusFilter];
    eventCount =
      props.partner.event_count &&
      +props.partner.event_count[props.statusFilter];

    // display date for sent email
    let surveySentDate = null;
    if (surveyDetails.earliestSend) {
      surveySentDate = (
        <div
          className={`partner-survey__copy v5__body ${sendSurveyBtnIsVisible}`}
        >
          <NavigationLink
            className="v5__link"
            url={`/bl/partner/${props.partner.id}/leaders`}
          >
            First Sent:{' '}
            {moment(surveyDetails.earliestSend)
              .tz(props.user.time_zone.pytz_timezone)
              .format('M/D/YY h:mm A z')}
          </NavigationLink>
        </div>
      );
    }

    // main survey button container
    if (sendSurveyBtn || surveySentDate) {
      surveySentNode = (
        <div className="partner-survey">
          {sendSurveyBtn}
          {surveySentDate}
        </div>
      );
    }
  }

  // if we are only showing Prospect partnerships, hide the Cohorts/Events count columns
  let cohortsNode = <td className="admin-table__col">{cohortCount}</td>;
  let eventsNode = <td className="admin-table__col">{eventCount}</td>;
  if (props.statusFilter === 'Prospect') {
    cohortsNode = null;
    eventsNode = null;
  }

  let surveyNode = null;
  if (props.statusFilter === 'Current') {
    surveyNode = (
      <td className="admin-table__col admin-table__col--survey">
        {surveySentNode}
      </td>
    );
  }

  return (
    <tr className="admin-table__row">
      <td className="admin-table__col">{props.partner.name}</td>
      {cohortsNode}
      {eventsNode}
      <td
        className="admin-table__col"
        onClick={canEdit ? handleEditPsmClick : (e) => e.preventDefault()}
      >
        {psmNode}
      </td>
      <td
        className="admin-table__col"
        onClick={canEdit ? handleEditDpsClick : (e) => e.preventDefault()}
      >
        {dpsNode}
      </td>
      <td className="admin-table__col">{membersNode}</td>
      {surveyNode}
    </tr>
  );
};

PartnerRow.propTypes = {
  partner: PropTypes.object.isRequired,
  openEditPSMs: PropTypes.func.isRequired,
  openEditDPs: PropTypes.func.isRequired,
  openSendPartnerLeaderSurveyModal: PropTypes.func.isRequired,
};

export default PartnerRow;
