// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import React from 'react';

const MatchCohort = (props) => {
  const coachMatchRequirementsNode = (
    <div className="v5__body-copy--italic">None</div>
  );

  let priorityCohortDomainsNode = <div>None</div>;
  if (props.cohort.priority_domains && props.cohort.priority_domains.length) {
    const priorityCohortDomains = props.cohort.priority_domains.map(
      (domain) => {
        return (
          <li key={`cohort-${props.cohort}-domain-${domain.id}`}>
            {domain.label}
          </li>
        );
      }
    );
    priorityCohortDomainsNode = (
      <ul className="coach-match__list">{priorityCohortDomains}</ul>
    );
  }

  let cohortOverviewNode = (
    <div className="v5__body-copy--italic">No cohort overview provided.</div>
  );
  if (props.cohort.overview) {
    cohortOverviewNode = (
      <React.Fragment>
        <PurifiedHTML
          className="ckeditor__output"
          content={props.cohort.overview}
        />
      </React.Fragment>
    );
  }

  const psmsNode =
    props.cohort.psms &&
    props.cohort.psms.map((psm) => {
      return (
        <div key={`psm-${psm.id}`}>
          {psm.first_name} {psm.last_name}
        </div>
      );
    });

  return (
    <section className="row">
      <h3 className="coach-match__headline coach-match__headline--details coach-match__headline--grass v5__heading-5">
        <i className="tc-icon_checklist match-details__icon" /> Cohort
        Requirements &amp; Considerations
      </h3>
      <div className="coach-match__row">
        <div className="coach-match__col--33">
          <div className="match-details__sub-headline v5__body-copy--bold">
            Product
          </div>
          <div className="match-details__main-detail">
            {(props.cohort.product && props.cohort.product.name) || ''}
          </div>
          <div className="match-details__sub-headline v5__body-copy--bold">
            Dates
          </div>
          <div className="match-details__main-detail">
            {Utils.handleFormatDate(
              props.registrationCompletion,
              props.cohort.target_end_date_formatted
            )}
          </div>
          <div className="match-details__sub-headline v5__body-copy--bold">
            Primary Focus Areas
          </div>
          {priorityCohortDomainsNode}
        </div>
        <div className="coach-match__col--33">
          <div className="match-details__sub-headline v5__body-copy--bold">
            Cohort
          </div>
          <div className="match-details__main-detail">
            {props.cohort.display_name}
          </div>
          <div className="match-details__sub-headline v5__body-copy--bold">
            Cohort Size
          </div>
          <div>{props.cohort.cohort_size && props.cohort.cohort_size[0]}</div>
          <div className="match-details__sub-headline v5__body-copy--bold">
            Cohort Match Requirements
          </div>
          <div>{coachMatchRequirementsNode}</div>
        </div>
        <div className="coach-match__col--33">
          <div className="match-details__sub-headline v5__body-copy--bold">
            Partner
          </div>
          <div className="match-details__main-detail">
            {props.cohort.partner && props.cohort.partner.name}
          </div>
          <div className="match-details__sub-headline v5__body-copy--bold">
            Partner Success Manager{psmsNode && psmsNode.length > 1 ? 's' : ''}
          </div>
          <div>{psmsNode}</div>
        </div>
      </div>
      <div className="coach-match__headline coach-match__headline--details coach-match__headline--spring v5__heading-5">
        <i className="tc-v5-group match-details__icon" /> Cohort Overview
      </div>
      <div className="coach-match__row">{cohortOverviewNode}</div>
    </section>
  );
};

export default MatchCohort;

MatchCohort.propTypes = {
  cohort: PropTypes.object.isRequired,
};
