// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import propTypes from 'prop-types';
import React from 'react';
import ParticipantNote from './ParticipantNote';

const StatusAndNotes = (props) => {
  const [showAllNotes, setShowAllNotes] = React.useState(false);

  const handleStatusChange = (e) => {
    props.updateStatus(e.target.value);
  };

  const disabled = !props.canEditData ? 'disabled' : '';
  let statusDisabled = disabled;

  const activeNode = (
    <option key="active" value="Active">
      {Utils.formatEngagementStatus('Active')}
    </option>
  );
  const inactiveNode = (
    <option key="inactive" value="Temporarily Inactive">
      {Utils.formatEngagementStatus('Temporarily Inactive')}
    </option>
  );
  const doneNode = (
    <option key="done" value="Done with Coaching">
      {Utils.formatEngagementStatus('Done with Coaching')}
    </option>
  );
  const droppedNode = (
    <option key="dropped" value="Dropped">
      {Utils.formatEngagementStatus('Dropped')}
    </option>
  );
  const disengagedNode = (
    <option key="disengaged" value="Disengaged">
      {Utils.formatEngagementStatus('Disengaged')}
    </option>
  );

  let statusOptions = null;

  switch (props.participantStatus) {
    case 'Dropped':
      if (props.isAdmin) {
        statusOptions = [activeNode, droppedNode];
      } else {
        statusOptions = [droppedNode];
        statusDisabled = 'disabled';
      }
      break;
    case 'Disengaged':
      if (props.isAdmin) {
        statusOptions = [activeNode, inactiveNode, droppedNode, disengagedNode];
      } else if (props.isPSM) {
        statusOptions = [inactiveNode, droppedNode, disengagedNode];
      } else {
        statusOptions = [activeNode, inactiveNode, disengagedNode];
      }
      break;
    case 'Active':
      if (props.isAdmin) {
        statusOptions = [
          activeNode,
          inactiveNode,
          droppedNode,
          disengagedNode,
          doneNode,
        ];
      } else if (props.isPSM) {
        statusOptions = [activeNode, inactiveNode, droppedNode];
      } else {
        statusOptions = [activeNode, inactiveNode, doneNode, disengagedNode];
      }
      break;
    case 'Temporarily Inactive':
      if (props.isAdmin) {
        statusOptions = [activeNode, inactiveNode, droppedNode, disengagedNode];
      } else if (props.isPSM) {
        statusOptions = [activeNode, inactiveNode, droppedNode];
      } else {
        statusOptions = [activeNode, inactiveNode, disengagedNode];
      }
      break;
    case 'Done with Coaching':
      if (props.isBlCoach) {
        statusOptions = [activeNode, doneNode];
      } else {
        statusOptions = [doneNode];
        statusDisabled = 'disabled';
      }
      break;
  }

  if (!props.canEditData) {
    // Data viewers can't edit so we supply the full list
    statusOptions = [
      activeNode,
      inactiveNode,
      doneNode,
      droppedNode,
      disengagedNode,
    ];
  }

  let statusOptionsNode = (
    <select
      className={`participant-v5__section-dropdown tc__select side-bar ${statusDisabled}`}
      value={props.participantStatus || ''}
      onChange={handleStatusChange}
      data-testid="participant-status-edit"
    >
      {statusOptions}
    </select>
  );

  if (!props.canEditData || props.isArchived) {
    statusOptionsNode = props.participantStatus;
  }

  const addNoteNode = (
    <div className="participant-v5__link-container">
      <div
        className="participant-v5__clickable-link"
        onClick={() => props.setAddingNote(true)}
        data-testid="add-a-note"
      >
        <span className="tc-v5-plus-solid participant-v5__large-icon" />
        Add a note
      </div>
    </div>
  );

  let participantNotes = [...props.participantNotes].reverse();
  let showNotes =
    participantNotes && participantNotes.length > 3 && 'SHOW LESS';
  if (!showAllNotes && participantNotes && participantNotes.length > 3) {
    participantNotes = participantNotes.slice(0, 3);
    showNotes = 'SHOW ALL';
  }

  const participantNotesNode = participantNotes.map((participantNote) => {
    let editNote = false;
    if (
      (props.isAdmin || props.userId === participantNote.creator_user_id) &&
      participantNote.can_edit
    ) {
      editNote = true;
    }
    return (
      <ParticipantNote
        key={`participant-note-${participantNote.id}`}
        {...participantNote}
        timeZone={props.timeZone}
        editNote={editNote}
        setEditNote={props.setEditNote}
        canEditData={props.canEditData}
        isArchived={props.isArchived}
      />
    );
  });

  return (
    <div>
      <div className="participant-v5__section-header v5__heading-5">
        Status & Notes
      </div>
      <div className="participant-v5__status-container">
        {props.canEditData && !props.isArchived && (
          <>
            <div className="participant-v5__sub-section">
              <div className="participant-v5__sub-section-title">
                Set a Status
              </div>
              {statusOptionsNode}
            </div>

            <div className="participant-v5__status-add-note">
              <div className="participant-v5__sub-section">
                <div className="participant-v5__sub-section-title">
                  Set Internal Notes
                </div>
                {addNoteNode}
              </div>
            </div>
          </>
        )}
      </div>

      {participantNotesNode}
      <div
        className="cute-12-desktop participant-v5__flush-left v5__link participant-v5__participant-show-all"
        onClick={() => setShowAllNotes(!showAllNotes)}
        data-testid="participant-notes-show-all"
      >
        {showNotes}
      </div>
    </div>
  );
};

StatusAndNotes.propTypes = {
  participantNotes: propTypes.array.isRequired,
  canEditData: propTypes.bool.isRequired,
  userId: propTypes.number.isRequired,
  participantStatus: propTypes.string.isRequired,
  isPSM: propTypes.bool.isRequired,
  isAdmin: propTypes.bool.isRequired,
  isArchived: propTypes.bool.isRequired,
};

export default StatusAndNotes;
