// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const AttendeeRow = (props) => {
  // a list of all columns in the row, other than name
  const displayNodes = [];

  /**
   * given a grade string, format ordinal numeric portion to add superscript tag
   * @param {string} grade grade string to be formatted
   * @returns {string} formatted html of the string
   *
   * e.g. 2nd grade -> 2<sup>nd</sup> grade, Pre-K -> Pre-K
   */
  const formatGrade = (grade) => {
    const gradeArr = grade.trim().split(' ');
    let formattedGrade;
    // currently only '1st grade' to '12th grade' satisfy this condition
    if (gradeArr.length === 2 && gradeArr[1] === 'grade') {
      const ordinalNum = Utils.ordinalWordToNumber(gradeArr[0]);
      if (typeof ordinalNum === 'object') {
        formattedGrade = (
          <>
            {ordinalNum.number}
            <sup>{ordinalNum.ordinal}</sup>
            {' ' + gradeArr[1]}
          </>
        );
      }
    }
    return formattedGrade ? formattedGrade : grade.trim();
  };

  displayNodes.push(
    <div
      className="cute-1-desktop pl-event__no-right-padding pl-event__table-cell-text"
      key="row-school"
    >
      {props.institution === 'Not Associated With School' ? (
        <span className="pl-event__table-light-text">
          Not Associated With School
        </span>
      ) : (
        props.institution
      )}
    </div>,
    <div
      className="cute-1-desktop pl-event__no-right-padding pl-event__table-cell-text"
      key="row-grade"
    >
      {props.grades.map((grade) => (
        <div key={grade}>{formatGrade(grade)}</div>
      ))}
    </div>,
    <div
      className="cute-1-desktop pl-event__no-right-padding pl-event__table-cell-text"
      key="row-subject"
    >
      {props.subjects.map((subject, idx) => {
        // don't show comma if rendering the last item in the array
        const comma = !(idx === props.subjects.length - 1) ? ',' : '';
        return (
          <div key={subject}>
            {subject}
            {comma}
          </div>
        );
      })}
    </div>,
    <div
      className="cute-1-desktop pl-event__no-right-padding pl-event__table-cell-text"
      key="row-role"
    >
      {props.role}
    </div>
  );

  // set up the Group column if it is visible
  if (props.showGroupCol) {
    displayNodes.push(
      <div className="cute-1-desktop pl-event__table-cell-text" key="row-group">
        {props.group_name}
      </div>
    );
  }

  // set up the RSVP column if it is visible
  if (props.showRsvpCol) {
    displayNodes.push(
      <div className="cute-1-desktop pl-event__table-cell-text" key="row-rsvp">
        {props.reserved && (
          <span className="tc-v5-complete-outline pl-event__table-checkmark pl-event__table-checkmark-outline"></span>
        )}
      </div>
    );
  }

  // build the session check-in columns based on the max # sessions displayed in the table
  Utils.range(1, props.maxSessionNum + 1).forEach((num) => {
    // see if this attendee has checked in to any session with this num
    const checkedInSession =
      props.event_sessions &&
      props.event_sessions.find(
        (session) => props.sessionNumMap[session.session_id] === num
      );

    let tooltipText;

    if (checkedInSession) {
      tooltipText = moment
        .tz(checkedInSession.registered_formatted, props.timeZone)
        .format('h:mm A');
      tooltipText += ' ' + moment.tz(props.timeZone).zoneAbbr();
    }

    displayNodes.push(
      <div
        key={`session-${num}-col`}
        className="cute-1-desktop pl-event__table-cell-text"
      >
        {checkedInSession && (
          <Tooltip
            tooltip={tooltipText}
            delay={1}
            offsetLeft={18}
            offsetTop={35}
            minWidth={100}
          >
            <span className="tc-icon_status-done pl-event__table-checkmark">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </Tooltip>
        )}
      </div>
    );
  });

  // generate placeholders to maintain table structure
  if (displayNodes.length < 9) {
    Utils.range(displayNodes.length, 10).map((num) => {
      displayNodes.push(
        <div
          key={`session-${num}-placeholder-col`}
          className="cute-1-desktop"
        />
      );
    });
  }

  return (
    <div className="cute-100 pl-event__table-row">
      <div className="cute-3-desktop pl-event__user-photo-name">
        <div className="pl-event__user-photo">
          <UserPhoto
            src={props.photo}
            userId={props.user_id}
            firstName={props.user_first_name}
            lastName={props.user_last_name}
            diameter={40}
          />
        </div>
        <div className="pl-event__user-name-email">
          <div className="pl-event__user-name">
            {props.user_first_name} {props.user_last_name}
          </div>
          <div className="pl-event__user-email">{props.user_email}</div>
        </div>
      </div>
      {displayNodes
        .slice(props.startingColumn, props.startingColumn + 9)
        .map((node) => {
          return node;
        })}
    </div>
  );
};

AttendeeRow.propTypes = {
  user_first_name: PropTypes.string.isRequired,
  user_last_name: PropTypes.string.isRequired,
  user_email: PropTypes.string.isRequired,
  institution: PropTypes.string,
  role: PropTypes.string,
  group_name: PropTypes.string,
  reserved: PropTypes.string,
  event_sessions: PropTypes.array,
  showRsvpCol: PropTypes.bool,
  showGroupCol: PropTypes.bool,
  sessionNumMap: PropTypes.object.isRequired,
  startingColumn: PropTypes.number.isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default AttendeeRow;
