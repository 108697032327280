// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';

const Schema = (props) => {
  let lessonImage = {};
  if (
    props.Lesson.image_lessonfile_id &&
    props.Lesson.lessonImage &&
    props.Lesson.lessonImage[props.Lesson.image_lessonfile_id]
  ) {
    lessonImage = props.Lesson.lessonImage[props.Lesson.image_lessonfile_id];
  }

  const schemaData = {
    '@legacy-components/context': 'http://schema.org',
    '@type': 'ScholarlyArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${process.env.REACT_APP_TEACHING_URL}/lesson/${props.Lesson.id}/${props.Lesson.seo_url}`,
    },
    headline: props.Lesson.name,
    url: `${process.env.REACT_APP_TEACHING_URL}/lesson/${props.Lesson.id}/${props.Lesson.seo_url}`,
    image: props.Lesson.image_lessonfile_id
      ? {
          '@type': 'ImageObject',
          url: lessonImage.public_preview_big || '',
          height: props.Lesson.lesson_image.Lessonfile.height || '',
          width: props.Lesson.lesson_image.Lessonfile.width || '',
        }
      : {},
    thumbnailUrl: lessonImage.public_preview || '',
    dateCreated: props.Lesson.created,
    datePublished: props.Lesson.mt_published,
    author: {
      '@type': 'Person',
      name: `${props.Lesson.User.first_name} ${props.Lesson.User.last_name}`,
    },
    publisher: {
      '@type': 'Organization',
      name: 'BetterLesson',
      logo: {
        '@type': 'ImageObject',
        url: `${process.env.REACT_APP_LAB_URL}/legacy-images/betterlesson-logo.svg`,
        width: '744',
        height: '156',
      },
    },
    description: props.Lesson.summary,
    articleSection: props.Lesson.course_title,
    creator: [`${props.Lesson.User.first_name} ${props.Lesson.User.last_name}`],
    about: props.Lesson.Subject[0].name,
    timeRequired: 'PT${props.Lesson.duration}M',
  };

  return (
    <PurifiedHTML
      type="application/ld+json"
      content={JSON.stringify(schemaData)}
      tagName="script"
    />
  );
};

export default Schema;
