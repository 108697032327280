// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import UserPhoto from './../../Common/UserPhoto';

const StrategySection = (props) => {
  let durationNode, authorNode, resourcesNode;

  // if the section includes a duration, display it as # minutes
  if (props.duration) {
    durationNode = (
      <div className="strategy-v4__capsule-time">{props.duration} minutes</div>
    );
  }

  // if the section includes an author, display their info
  if (props.author) {
    authorNode = (
      <div className="strategy-v4__capsule-author">
        <UserPhoto
          src={props.author.photo}
          diameter={50}
          className="strategy-v4__capsule-author-photo"
        />
        <div className="tc__body-copy">
          <div>{props.author.label}</div>
          <div className="strategy-v4__capsule-author-role">
            {props.author.role == null
              ? 'BetterLesson Instructional Coach'
              : props.author.role}
          </div>
        </div>
      </div>
    );
  }

  // if the section includes resources, display those
  if (props.resources && props.resources.length) {
    // build a list of all the resources
    resourcesNode = (
      <div>
        <div className="tc__headline-6">Resources</div>
        {props.resources.map((resource) => {
          return (
            <div
              key={'resource-' + resource.id}
              className="strategy-v4__capsule-resource-headline-row"
              style={{ marginTop: 10 }}
            >
              <div
                className={
                  'strategy-v4__capsule-resource-icon file-type-icon ext-' +
                  resource.connections.icon
                }
              ></div>
              <div className="strategy-v4__capsule-resource-icon">
                {resource.title}
              </div>
              <div className="tc__link">
                {resource.connections.link
                  ? resource.connections.link
                  : process.env.REACT_APP_TEACHING_URL +
                    '/strategy/' +
                    props.strategyId +
                    '/resource/' +
                    resource.id}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="strategy-v4__capsule bl4__capsule bl4__capsule--flat">
      <div className="strategy-v4__capsule-headline-row">
        <h2 className="strategy-v4__capsule-headline">{props.title}</h2>
        {durationNode}
      </div>
      {authorNode}
      <PurifiedHTML
        className="strategy-v4__capsule-description ckeditor__output"
        content={props.description}
      />
      {resourcesNode}
    </div>
  );
};

StrategySection.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  duration: PropTypes.any,
  author: PropTypes.object,
  resources: PropTypes.array,
  strategyId: PropTypes.number,
};

export default StrategySection;
