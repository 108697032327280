// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';

const EventDetails = (props) => {
  let groupHeadlineNode = null;
  if (props.event.group) {
    groupHeadlineNode = (
      <span>{props.event.group.name || `Group ${props.event.group.num}`}</span>
    );
  }

  let detailsComma = '';
  if (props.event.partner_name && groupHeadlineNode) {
    detailsComma = ', ';
  }

  return (
    <div className="row event__attendee-details">
      <div className="event__attendee-header-image-container cute-5-desktop cute-12-phone">
        <img
          className="event__attendee-header-image"
          src={Utils.mapBLServiceNameToIllustration(props.event.service)}
          alt="BL event services"
        />
      </div>
      <div className="cute-7-desktop cute-12-phone event__attendee-header-details">
        <h1 className="v5__heading-1 attendee-event__name">
          {props.event.display_name || props.event.name}
        </h1>
        <div className="v5__body-copy event__attendee-details-subheadline">
          {props.event.partner_name}
          {detailsComma}
          {groupHeadlineNode}
        </div>
      </div>
    </div>
  );
};

export default EventDetails;

EventDetails.propTypes = {
  event: PropTypes.object.isRequired,
  activeSession: PropTypes.object,
};
