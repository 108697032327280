// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import RedirectHelper from '../../../../components/RedirectHelper';
import Utils from './../../../../components/DynamicUtils';
import NavigationLink from './../../../NavigationLink';
import FreezeWindowScroll from './../../Common/FreezeWindowScroll';

const RegistrationModal = (props) => {
  if (props.gatekeeper.modalRegisterOpenedReason !== 'resource_count_reached') {
    RedirectHelper.redirectToUserManagementWithRedirectSet('/register');
    return null;
  }

  let alertNode;
  // Build the alert node if needed:
  if (!Utils.isEmpty(props.gatekeeper.ui.alert)) {
    alertNode = (
      <AbsoluteAlert
        {...props.gatekeeper.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  let bannerVisibleClass = '';
  let bannerNode;
  if (props.gatekeeper.ui.errorBanner) {
    bannerVisibleClass = ' gatekeeper--banner-visible';
    bannerNode = (
      <div className="gatekeeper__banner gatekeeper__banner--error">
        {props.gatekeeper.ui.errorBanner}
      </div>
    );
  }

  const handleContinue = () => {
    RedirectHelper.redirectToUserManagementWithRedirectSet('/register');
    return null;
  };

  const handleCloseRegisterModal = () => {
    const { pathname } = window.location;

    if (pathname.startsWith('/strategy') || pathname.startsWith('/lesson')) {
      Router.route('/home');
    }

    props.closeRegisterModal();
  };

  const returnNode = (
    <div className="gatekeeper__link-container">
      <a
        id="go-back-link"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleCloseRegisterModal();
        }}
        className="gatekeeper__link"
      >
        Go Back
      </a>
    </div>
  );

  const headerLogoClass = ' gatekeeper__header';

  return (
    <div className="gatekeeper__container">
      <div
        className="modal__page-overlay modal__page-overlay--artifact-overlay gatekeeper__page-overlay"
        onClick={handleCloseRegisterModal}
      />
      <div className="row">
        <div>
          <div className="" />
          {bannerNode}
          {alertNode}
          <div
            className={
              'gatekeeper gatekeeper--stage gatekeeper--stage-v4 row' +
              bannerVisibleClass
            }
          >
            <div
              className="gatekeeper__close-model gatekeeper__close-model--v4 tc-x"
              onClick={handleCloseRegisterModal}
            ></div>
            <div className="overlay__content cute-12-desktop cute-12-phone">
              <div className="gatekeeper-modal row">
                <NavigationLink url={'/home'}>
                  <div
                    id="tc__logo"
                    className={'gatekeeper__header' + headerLogoClass}
                  >
                    <span className="gatekeeper__logo-caption">
                      BetterLesson
                    </span>
                  </div>
                </NavigationLink>
                <div className="gatekeeper__form-container">
                  <div className="gatekeeper__form-copy tc__body-copy">
                    Create your FREE account to access thousands of lessons and
                    resources.
                  </div>

                  <div className="gatekeeper__submit-container">
                    <button
                      onClick={handleContinue}
                      className={'gatekeeper__submit'}
                    >
                      Continue
                    </button>
                  </div>
                </div>

                <div>{returnNode}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={'mt-artifact-overlay'} />
      </div>
      <FreezeWindowScroll parentModal="gatekeeper" />
    </div>
  );
};

export default RegistrationModal;

RegistrationModal.PropTypes = {};
