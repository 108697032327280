// @ts-nocheck FIXME
import React from 'react';
import Analytics from './../../components/Analytics';
import LayerManager from './../../components/LayerManager';
import Utils from './../../components/StaticUtils';

let scrollPosition = 0;

const cleanUpModal = function () {
  // re-allow page scrolling
  const artifactParent = document.getElementById('artifact-parent');
  const teamActivityFeed = document.getElementById('team__activity-feed');

  // if the activity parent exists remove height limit
  if (artifactParent) {
    artifactParent.removeAttribute('style');
  }

  // if the activity feed exists remove height limit
  if (teamActivityFeed) {
    teamActivityFeed.removeAttribute('style');
  }

  Utils.removeClass(document.body, 'no-scroll--mobile');

  window.scrollTo(0, scrollPosition);
};

class ModalArtifact extends React.Component {
  componentWillUnmount() {
    cleanUpModal();
  }

  handleOutsideClose = (e) => {
    e.preventDefault();

    if (this.props.analyticsLabel) {
      // Record the analytics event:
      Analytics.logEvent({
        category: 'artifact',
        action: 'close',
        label: this.props.analyticsLabel,
      });
    }

    e.stopPropagation();

    cleanUpModal();

    LayerManager.close(this.props.closeUrl);
  };

  render() {
    scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;

    let overlayClass = '';
    let modalClass = '';
    if (this.props.className) {
      overlayClass = ' modal__page-overlay--' + this.props.className;
      modalClass = ' modal--' + this.props.className;
    }

    return (
      <div>
        <div
          className="modal__close--artifact"
          onClick={this.handleOutsideClose}
        >
          <div className="tc-x"></div>
        </div>
        <div
          className={'modal__page-overlay' + overlayClass}
          onClick={this.handleOutsideClose}
        ></div>
        <div className={'modal--absolute' + modalClass} id={this.props.id}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ModalArtifact;
