// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import { Router } from '@legacy-components/components/Router';
import React from 'react';

class MobileStrategySubHead extends React.Component {
  static contextType = LegacyLabContext;

  state = {
    displaySubHead: ' show',
  };

  prevScrollTop = 0;
  mq = null;

  componentDidMount() {
    // measure the rendered page
    this.mq = window.matchMedia('(max-width: 48em)');

    if (this.mq.matches) {
      window.addEventListener('scroll', this.handleWindowScroll);
    }

    // listen for responsive page
    this.mq.addListener(this.setupViewport);
  }

  componentWillUnmount() {
    this.mq.removeListener(this.setupViewport);
    if (this.mq.matches) {
      window.removeEventListener('scroll', this.handleWindowScroll);
    }
  }

  setupViewport = () => {
    if (this.mq.matches) {
      window.addEventListener('scroll', this.handleWindowScroll);
    } else {
      window.removeEventListener('scroll', this.handleWindowScroll);
    }
  };

  handleLinkOverride = (url) => {
    // inhibit the normal functioning of link for public users and bl users
    if (
      this.context.pageData.user.is_public ||
      this.context.pageData.user.is_bl
    ) {
      return null;
    }
    return url;
  };

  handleHeaderNavigation = (url, e) => {
    e.preventDefault();

    // stop the propogation
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    url = this.handleLinkOverride(url);

    Router.route(url);
  };

  handleWindowScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // display or hide the sub head if scrolling down or up
    this.setState({
      displaySubHead:
        scrollTop > this.prevScrollTop && scrollTop > 0 ? ' hide' : ' show',
    });

    this.prevScrollTop = scrollTop;
  };

  render() {
    let url =
      '/coaching/lab/strategy-browser/teaching-challenge/' +
      this.props.si.challenge.id +
      '/' +
      this.props.si.challenge.seo_url;
    let name = this.props.si.challenge.nickname;
    // If we have a meta strategy for the current SI, we should redirect the user to the meta strategy when they click "back"
    // We also show the meta strategy name:
    if (this.props.si.metaStrategy) {
      url += '/meta-strategy/' + this.props.si.metaStrategy.id;
      name = this.props.si.metaStrategy.name;
    }
    // Append the analytics from tag:
    url += '?from=sb_strategy_breadcrumb';

    return (
      <a
        onClick={this.handleHeaderNavigation.bind(this, url)}
        className={
          'tc__mobile-header tc__mobile-sub-head tc__mobile-header--strategy show-phone hide-desktop' +
          this.state.displaySubHead
        }
        href={url}
      >
        <div className="tc__back cute-2-desktop left">
          <div className="tc__back-arrow tc-arrow"></div>
        </div>
        <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
          {name}
        </div>
      </a>
    );
  }
}

export default MobileStrategySubHead;
