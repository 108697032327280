// @ts-nocheck FIXME
import EventApi from '@legacy-components/apis/V5/EventApi';
import { Router } from '@legacy-components/components/Router';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import Breadcrumb from '@legacy-components/react/V5/Common/Breadcrumb';
import CKEditor from '@legacy-components/react/V5/Common/CKEditor';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';

const EditEventOverview = (props) => {
  const [alert, setAlert] = React.useState({});
  const [canSave, setCanSave] = React.useState(false);
  const [overviewChanged, setOverviewChanged] = React.useState(false);
  const [changeLogChanged, setChangeLogChanged] = React.useState(false);

  // Enable the save button once the overview and change note have been edited
  React.useEffect(() => {
    if (overviewChanged && changeLogChanged) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [overviewChanged, changeLogChanged]);

  // Define the breadcrumbs
  const breadcrumbs = [
    {
      text: 'Events',
      link: `${props.baseUrl}/bl/events`,
    },
    {
      text: `${props.eventName}`,
      link: `${props.baseUrl}/bl/event/${props.eventId}`,
    },
    {
      text: 'Edit Overview',
    },
  ];

  // Declare handlers
  const handleSave = () => {
    // Save data from editors
    const data = {
      eventId: props.eventId,
      overview:
        CKEDITOR.instances['event__edit-overview-wysiwyg-overview-0'].getData(),
      changeNote:
        CKEDITOR.instances[
          'event__edit-overview-wysiwyg-change-note-0'
        ].getData(),
    };

    // Send the requests and display a banner indicating the response
    EventApi.editOverview(data).then(
      (response) => {
        setAlert({
          type: 'success',
          message: 'Overview updated',
          handleClose: () => setAlert({}),
        });
        // Redirect to the event page
        Router.route(`/bl/event/${response.eventId}`);
      },
      (response) => {
        // Show alert banner
        setAlert({
          type: 'error',
          message: response.error,
          handleClose: () => setAlert({}),
        });
      }
    );
  };

  // Check that the editor's contents have changed and enough text has been entered
  const handleChangelogChange = () => {
    // Strip the html tags off the text
    const elem = document.createElement('div');
    elem.innerHTML =
      CKEDITOR.instances[
        'event__edit-overview-wysiwyg-change-note-0'
      ].getData();

    // Only enable saving when there are at least 10 characters
    if (elem.textContent.length >= 10) {
      setChangeLogChanged(true);
    } else {
      setChangeLogChanged(false);
    }
  };

  // Show the alert banner if there are messages
  let alertNode = null;
  if (Object.keys(alert).length) {
    alertNode = (
      <AbsoluteAlert
        {...alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  return (
    <div className="event-overview-edit tc__page">
      <Header page="EditEventOverview" {...props} />
      {alertNode}
      <div className="row tc__body-copy">
        <div className="cute-12-desktop">
          <Breadcrumb trail={breadcrumbs} divider=" / " />
        </div>
      </div>
      <div className="row tc__body-copy">
        <div className="cute-12-desktop">
          <label className="v5__heading-5 event__edit-overview--label">
            <div className="event__edit-overview--label">
              Edit Event Overview
            </div>
            <CKEditor
              id="overview"
              onChange={() => setOverviewChanged(true)}
              increment={0}
              innerText={props.overview}
              className="event__edit-overview-wysiwyg"
              configs="/legacy-libs/configs/bl-cke-config-cohort.js"
            />
          </label>
        </div>
      </div>
      <div className="row tc__body-copy">
        <div className="cute-12-desktop">
          <label className="v5__heading-5">
            <div className="event__edit-overview--label">Change Note</div>
            <div className="v5__quote-copy">
              A change note is required to save updates
            </div>
            <CKEditor
              id="change-note"
              onChange={handleChangelogChange}
              increment={0}
              innerText={''}
              className="event__edit-overview-wysiwyg"
              configs="/legacy-libs/configs/bl-cke-config-cohort.js"
            />
          </label>
        </div>
      </div>
      <div className="row tc__body-copy">
        <div className="cute-12-desktop">
          <div className="event__edit-overview--btn-row">
            <button
              className="v5__btn"
              onClick={handleSave}
              disabled={!canSave}
            >
              Save
            </button>
            <button
              className="v5__btn v5__btn--blue-border"
              onClick={() => Router.route(`/bl/event/${props.eventId}`)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

EditEventOverview.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  eventName: PropTypes.string.isRequired,
  overview: PropTypes.string.isRequired,
};

export default EditEventOverview;
