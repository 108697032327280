// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import EventCSV from './EventCSVLink';
import EventSessionRow from './EventSessionRow';

const Sessions = (props) => {
  const tz = moment.tz(props.event.time_zone.pytz_timezone).zoneAbbr();
  const current = moment();

  let surveyCount = 0;
  let eventNPS = 0;
  let eventRTC = 0;
  const sessions = [];

  // These two paths are very similar, but different enough that I don't think
  // combining them into a single method would improve code organization
  if (props.event.advanced_event) {
    // For advanced events there's a complex set of rules for coalescing
    // All sessions that share a num, date, product, and name get coalesced
    const sessionMap = {};

    props.event.sessions.forEach((session) => {
      const sessionTime =
        Utils.formatEventSessionTime(
          session.session_start_time_formatted,
          session.session_end_time_formatted,
          props.event.time_zone.pytz_timezone
        ) || 'TBD';
      const sessionDate = session.session_date_formatted
        ? moment(session.session_date_formatted).format('MMMM DD, YYYY')
        : '—';

      const sessionKey = `${session.num}:${sessionDate}:${session.ld_product_id}:${session.name}`;

      if (Object.prototype.hasOwnProperty.call(sessionMap, sessionKey)) {
        sessionMap[sessionKey].checkInCount.push(session.attendee_count);
        sessionMap[sessionKey].attendance += session.attendee_count;
      } else {
        sessionMap[sessionKey] = {
          sessionDate: sessionDate,
          checkInCount: [session.attendee_count],
          attendance: session.attendee_count,
          sessionTime: {},
          sessionStart: [],
          sessionParticipantGroupMap: {},
          sessionIds: [],
          surveyResults: {},
          name: session.name,
          ldProductName: session.ld_product_id && session.ld_product.name,
          complexTimes: true,
        };
      }

      let sessionGroupName = '';
      if (session.group_id) {
        sessionGroupName = session.group_name || `Group ${session.group_num}`;
      } else {
        sessionGroupName = 'Registrants';
      }

      sessionMap[sessionKey].sessionTime[sessionGroupName] = sessionTime;
      if (session.session_start_time_formatted) {
        sessionMap[sessionKey].sessionStart.push(
          moment(session.session_start_time_formatted)
        );
      }

      // For surveys and registrations we need to break them up so that each user
      // is associated registration and survey completed time in the expanded row
      surveyCount += session.survey_results.length;
      sessionMap[sessionKey].sessionIds.push(session.id);
      sessionMap[sessionKey].surveyResults[sessionGroupName] =
        session.survey_results.map((surveyResult) => {
          const surveyCompletedTime = moment
            .utc(surveyResult.survey_completed_time)
            .tz(props.event.time_zone.pytz_timezone);
          eventNPS += surveyResult.nps_promoter_val;
          eventRTC += surveyResult.recommend_score;
          return {
            ...surveyResult,
            surveyCompletedTime: `${surveyCompletedTime.format('hh:mm')}${tz}`,
          };
        });
      sessionMap[sessionKey].surveyResults[sessionGroupName].sort((a, b) =>
        moment(a.survey_completed_time).diff(moment(b.survey_completed_time))
      );

      sessionMap[sessionKey].sessionParticipantGroupMap[sessionGroupName] = [];
      props.event.participants.forEach((participant) => {
        if (!participant.event_sessions) {
          return false;
        }
        const registeredSession = participant.event_sessions.find(
          (parSession) => session.id === parSession.session_id
        );
        if (registeredSession) {
          const registeredTime = moment
            .utc(registeredSession.registered_formatted)
            .tz(props.event.time_zone.pytz_timezone);
          sessionMap[sessionKey].sessionParticipantGroupMap[
            sessionGroupName
          ].push({
            name: `${participant.user_first_name} ${participant.user_last_name}`,
            registrationTime: registeredTime,
            registrationTimeFormatted: `${registeredTime.format('hh:mm')}${tz}`,
          });
        }
      });
      sessionMap[sessionKey].sessionParticipantGroupMap[sessionGroupName].sort(
        (a, b) => a.registrationTime.diff(b.registrationTime)
      );
    });
    Object.entries(sessionMap).forEach((sessionEntry) =>
      sessions.push(sessionEntry[1])
    );

    // Sort so upcoming sessions are sorted first then sort based on time
    sessions.sort((a, b) => {
      let aNextTime;
      let aPrevTime;
      a.sessionStart.forEach((startTime) => {
        if (startTime.isAfter(current)) {
          if (!aNextTime) {
            aNextTime = startTime;
          } else if (startTime.isBefore(aNextTime)) {
            aNextTime = startTime;
          }
        } else if (!aPrevTime) {
          aPrevTime = startTime;
        } else if (startTime.isAfter(aPrevTime)) {
          aPrevTime = startTime;
        }
      });

      let bNextTime;
      let bPrevTime;
      b.sessionStart.forEach((startTime) => {
        if (startTime.isAfter(current)) {
          if (!bNextTime) {
            bNextTime = startTime;
          } else if (startTime.isBefore(bNextTime)) {
            bNextTime = startTime;
          }
        } else if (!bPrevTime) {
          bPrevTime = startTime;
        } else if (startTime.isAfter(bPrevTime)) {
          bPrevTime = startTime;
        }
      });

      if (aNextTime && bNextTime) {
        return aNextTime - bNextTime;
      } else if (aNextTime && !bNextTime) {
        return -1;
      } else if (!aNextTime && bNextTime) {
        return 1;
      } else if (aPrevTime && bPrevTime) {
        return bPrevTime - aPrevTime;
      } else if (aPrevTime && !bPrevTime) {
        return -1;
      } else if (!aPrevTime && bPrevTime) {
        return 1;
      }
      return 0;
    });
  } else {
    // For basic events just coalesce on session num
    const sessionMap = {};

    props.event.sessions.forEach((session) => {
      if (Object.prototype.hasOwnProperty.call(sessionMap, session.num)) {
        sessionMap[session.num].checkInCount.push(session.attendee_count);
        sessionMap[session.num].attendance += session.attendee_count;
      } else {
        const sessionDate = session.session_date_formatted
          ? moment(session.session_date_formatted).format('MMMM DD, YYYY')
          : '—';
        const sessionTime =
          Utils.formatEventSessionTime(
            session.session_start_time_formatted,
            session.session_end_time_formatted,
            props.event.time_zone.pytz_timezone
          ) || 'TBD';
        sessionMap[session.num] = {
          sessionDate: sessionDate,
          checkInCount: [session.attendee_count],
          attendance: session.attendee_count,
          sessionTime: sessionTime,
          sessionStart: session.session_start_time_formatted,
          sessionParticipantGroupMap: {},
          sessionIds: [],
          surveyResults: {},
          name: session.name,
          ldProductName: session.ld_product_id && session.ld_product.name,
          complexTimes: false,
        };
      }

      let sessionGroupName = '';
      if (session.group_id) {
        sessionGroupName = session.group_name || `Group ${session.group_num}`;
      } else {
        sessionGroupName = 'Registrants';
      }

      // For surveys and registrations we need to break them up so that each user
      // is associated registration and survey completed time in the expanded row
      surveyCount += session.survey_results.length;
      sessionMap[session.num].sessionIds.push(session.id);
      sessionMap[session.num].surveyResults[sessionGroupName] =
        session.survey_results.map((surveyResult) => {
          const surveyCompletedTime = moment
            .utc(surveyResult.survey_completed_time)
            .tz(props.event.time_zone.pytz_timezone);
          eventNPS += surveyResult.nps_promoter_val;
          eventRTC += surveyResult.recommend_score;
          return {
            ...surveyResult,
            surveyCompletedTime: `${surveyCompletedTime.format('hh:mm')}${tz}`,
          };
        });
      sessionMap[session.num].surveyResults[sessionGroupName].sort((a, b) =>
        moment(a.survey_completed_time).diff(moment(b.survey_completed_time))
      );

      sessionMap[session.num].sessionParticipantGroupMap[sessionGroupName] = [];
      props.event.participants.map((participant) => {
        if (!participant.event_sessions) {
          return false;
        }
        const registeredSession = participant.event_sessions.find(
          (parSession) => session.id === parSession.session_id
        );
        if (registeredSession) {
          const registeredTime = moment
            .utc(registeredSession.registered_formatted)
            .tz(props.event.time_zone.pytz_timezone);
          sessionMap[session.num].sessionParticipantGroupMap[
            sessionGroupName
          ].push({
            name: `${participant.user_first_name} ${participant.user_last_name}`,
            registrationTime: registeredTime,
            registrationTimeFormatted: `${registeredTime.format('hh:mm')}${tz}`,
          });
        }
      });
      sessionMap[session.num].sessionParticipantGroupMap[sessionGroupName].sort(
        (a, b) => a.registrationTime.diff(b.registrationTime)
      );
    });
    Object.entries(sessionMap).forEach((sessionEntry) =>
      sessions.push(sessionEntry[1])
    );

    // Sort so upcoming sessions are sorted first then sort based on time
    sessions.sort((a, b) => {
      const aStartTime = a.sessionStart && moment(a.sessionStart);
      const bStartTime = b.sessionStart && moment(b.sessionStart);
      if (!aStartTime && !bStartTime) {
        return 0;
      } else if (aStartTime && !bStartTime) {
        return -1;
      } else if (!aStartTime && bStartTime) {
        return 1;
      } else if (aStartTime.isAfter(current) && bStartTime.isBefore(current)) {
        return -1;
      } else if (aStartTime.isBefore(current) && bStartTime.isAfter(current)) {
        return 1;
      } else if (aStartTime.isAfter(current) && bStartTime.isAfter(current)) {
        return aStartTime - bStartTime;
      } else {
        return bStartTime - aStartTime;
      }
    });
  }

  if (surveyCount > 0) {
    // I'd use /= but it disregards the order of operations
    eventNPS = (eventNPS / surveyCount) * 100;
    eventNPS = Math.round(eventNPS);
    eventRTC = (eventRTC / surveyCount / 10.0) * 100;
    eventRTC = Math.round(eventRTC);
    eventRTC /= 10.0;
  }

  return (
    <React.Fragment>
      <div className="row row--1200">
        <div className="cute-5-desktop admin-v5__event-satisfaction-row">
          <div className="admin-v5__event-satisfaction-column">
            EVENT NPS:{' '}
            <span className="admin-v5__event-satisfaction-score">
              {eventNPS}
            </span>
          </div>
          <div className="admin-v5__event-satisfaction-column">
            SURVEYS:{' '}
            <span className="admin-v5__event-satisfaction-score">
              {surveyCount}
            </span>
          </div>
          <div className="admin-v5__event-satisfaction-column">
            RTC:{' '}
            <span className="admin-v5__event-satisfaction-score">
              {eventRTC}
            </span>
          </div>
        </div>
        <div className="cute-7-desktop" />
      </div>
      <div className="row row--1200 tc__body-copy admin-v5__table-filters admin-v5__table-filters--right">
        <EventCSV
          event={props.event}
          eventParticipants={props.eventParticipants}
        />
      </div>
      <div className="row row--1200">
        <div className="grid-big__row admin-v5__coach-table__header">
          <div className="event__participant-header-col cute-2-desktop">
            <span className="ppd-3-coach-table__header-caption left">
              Session
            </span>
          </div>
          <div className="event__participant-header-col cute-2-desktop">
            <span className="ppd-3-coach-table__header-caption left">Time</span>
          </div>
          <div className="event__participant-header-col cute-2-desktop">
            <span className="ppd-3-coach-table__header-caption left">
              Check-In by Group
            </span>
          </div>
          <div className="event__participant-header-col cute-1-desktop">
            <span className="ppd-3-coach-table__header-caption left">
              Attendance
            </span>
          </div>
          <div className="event__participant-header-col cute-1-desktop">
            <span className="ppd-3-coach-table__header-caption left">
              Surveys
            </span>
          </div>
          <div className="event__participant-header-col cute-1-desktop">
            <span className="ppd-3-coach-table__header-caption left">
              Session NPS
            </span>
          </div>
          <div className="event__participant-header-col cute-3-desktop">
            <span className="ppd-3-coach-table__header-caption left">
              Session RTC
            </span>
          </div>
        </div>
        {sessions.map((session) => (
          <EventSessionRow
            key={`session-${session.sessionIds[0]}`}
            {...session}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

Sessions.propTypes = {
  event: PropTypes.object.isRequired,
  eventParticipants: PropTypes.array.isRequired,
};

export default Sessions;
