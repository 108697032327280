// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { Router } from './../../../components/Router';

const EventRegistrationWelcome = (props) => {
  const handleExploreLab = (e) => {
    props.setIsLoading(true);
    e.preventDefault();
    if (props.participant.registration_step === 6) {
      Router.route('/lab/home');
    } else if (props.participant.registration_step < 6) {
      Router.route(
        `/coaching/lab/participant/${props.participant.id}/registration/${props.participant.registration_step}`
      );
    } else {
      Router.route(
        `/coaching/lab/participant/${props.participant.id}/registration/1`
      );
    }
  };

  const btnDisabledClass = props.ui.loading ? ' disabled' : '';

  return (
    <div className="tc__page tc__page--registration">
      <div className="tc-header__wrap tc-header__wrap--v2">
        <div className="tc-header tc-header--v2 row row--1200">
          <div
            className="logo tc-header__logo cute-3-desktop cute-3-phone left"
            onClick={handleExploreLab}
            src="/legacy-images/betterlesson-logo.svg"
          ></div>
        </div>
      </div>
      <div
        className={
          props.cohort
            ? 'coaching-reg__background-image'
            : 'event-reg__background-image'
        }
      ></div>
      <div className="event-reg__background-overlay"></div>
      <div className="row row--1200">
        <div className="cute-3-desktop hide-phone"></div>
        <div className="cute-6-desktop cute-12-phone">
          <div className="event-reg__welcome--text">
            <div className="event-reg__welcome-image"></div>
            <div className="v5__heading-3">{`You're registered for ${
              props.event.display_name || props.event.name
            }!`}</div>
            <div className="v5__body-copy">
              While you&apos;re waiting, explore the BetterLesson Lab and browse
              thousands of instructional strategies and lessons.
            </div>
            <div>
              <button
                className={'v5__btn' + btnDisabledClass}
                onClick={handleExploreLab}
              >
                Explore the Lab
              </button>
            </div>
          </div>
        </div>
        <div className="cute-3-desktop hide-phone"></div>
      </div>
    </div>
  );
};

EventRegistrationWelcome.propTypes = {
  ui: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};

export default EventRegistrationWelcome;
