// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import PLCard from './PLCard';

const PLCards = (props) => {
  const calculateCards = () => {
    if (['Completed', 'Closed'].includes(props.event.status)) {
      return [
        <PLCard
          key={props.event.id}
          type="event-finished"
          setAlert={props.setAlert}
          attendees={props.event.attendees}
          denominator={props.event.capacity}
          event={props.event}
          sessions={props.event.sessions}
          attendeesToDownload={props.event.attendees}
        />,
      ];
    }
    const newCardArray = [];
    let foundASession = false;
    const currTime = moment();
    const hourOff = moment().add(1, 'hours');
    if (props.event.advanced_event) {
      // If there's a currently active session then then the check will short circuit
      // Otherwise we check to see if there's a session in an hour
      // Finally, we get the attendance of the most recent sessions
      // In both cases we only get the first because some stops after it encounters true
      const sessionGroupSet = new Set();
      foundASession =
        !!props.event.session_times.filter((session) => {
          if (
            currTime < moment(session.session_end_time_formatted) &&
            hourOff > moment(session.session_start_time_formatted)
          ) {
            const attendees = props.event.attendees.filter((attendee) => {
              return (
                attendee.event_sessions &&
                attendee.event_sessions.some(
                  (aSession) => session.id === aSession.session_id
                )
              );
            });
            const denominator = props.event.attendees.filter((attendee) => {
              return (
                attendee.group_id === session.group_id || !attendee.group_id
              );
            }).length;
            newCardArray.push(
              <PLCard
                key={session.id}
                type="check-in"
                setAlert={props.setAlert}
                attendees={attendees}
                denominator={denominator}
                event={props.event}
                sessionId={session.id}
                sessionName={session.name}
                groupName={session.group_name}
                sessionStartTime={session.session_start_time_formatted}
              />
            );
            return true;
          }
        }).length ||
        !![...props.event.session_times].reverse().filter((session) => {
          if (
            currTime > moment(session.session_end_time_formatted) &&
            !sessionGroupSet.has(session.group_id)
          ) {
            sessionGroupSet.add(session.group_id);
            const attendees = props.event.attendees.filter((attendee) => {
              return (
                attendee.event_sessions &&
                attendee.event_sessions.some(
                  (aSession) => session.id === aSession.session_id
                )
              );
            });
            const denominator = props.event.attendees.filter((attendee) => {
              return (
                attendee.group_id === session.group_id || !attendee.group_id
              );
            }).length;
            newCardArray.push(
              <PLCard
                key={session.id}
                type="attendance"
                setAlert={props.setAlert}
                attendees={attendees}
                denominator={denominator}
                event={props.event}
                sessionId={session.id}
                sessionName={session.name}
                groupName={session.group_name}
                sessions={props.event.sessions}
                attendeesToDownload={props.event.attendees}
              />
            );
            return true;
          }
        }).length;
    } else {
      // If there's a currently active session then then the check will short circuit
      // Otherwise we check to see if there's a session in an hour
      // Finally, we get the attendance of the most recent session
      // In both cases we only get the first because some stops after it encounters true
      foundASession =
        props.event.session_times.some((session) => {
          if (
            currTime < moment(session.session_end_time_formatted) &&
            hourOff > moment(session.session_start_time_formatted)
          ) {
            const attendees = props.event.attendees.filter((attendee) => {
              return (
                attendee.event_sessions &&
                attendee.event_sessions.some(
                  (aSession) => session.num === aSession.session_num
                )
              );
            });
            newCardArray.push(
              <PLCard
                key={session.id}
                type="check-in"
                setAlert={props.setAlert}
                attendees={attendees}
                denominator={props.event.attendees.length}
                event={props.event}
                sessionId={session.id}
                sessionName={session.name}
              />
            );
            return true;
          }
        }) ||
        [...props.event.session_times].reverse().some((session) => {
          if (currTime > moment(session.session_end_time_formatted)) {
            const attendees = props.event.attendees.filter((attendee) => {
              return (
                attendee.event_sessions &&
                attendee.event_sessions.some(
                  (aSession) => session.num === aSession.session_num
                )
              );
            });
            newCardArray.push(
              <PLCard
                key={session.id}
                type="attendance"
                setAlert={props.setAlert}
                attendees={attendees}
                denominator={props.event.attendees.length}
                event={props.event}
                sessionId={session.id}
                sessionName={session.name}
                sessions={props.event.sessions}
                attendeesToDownload={props.event.attendees}
              />
            );
            return true;
          }
        });
    }
    if (!foundASession) {
      newCardArray.push(
        <PLCard
          key={props.event.id}
          type="registration"
          setAlert={props.setAlert}
          attendees={props.event.attendees}
          denominator={props.event.capacity}
          event={props.event}
        />
      );
    }
    return newCardArray;
  };
  return (
    <div className="row row--1200 admin-v5__pl-card-capsule-row">
      {calculateCards()}
    </div>
  );
};

PLCards.propTypes = {
  event: PropTypes.shape({
    service: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    time_zone: PropTypes.object.isRequired,
    advanced_event: PropTypes.number,
    sessions: PropTypes.array.isRequired,
    groups: PropTypes.array,
    attendees: PropTypes.array,
    session_times: PropTypes.array,
  }).isRequired,
  firstSessionDate: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default PLCards;
