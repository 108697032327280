// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import ZenScroll from 'zenscroll';
import CKEditor from './../../../V5/Common/CKEditor';
import FreezeWindowScroll from './../../Common/FreezeWindowScroll';
import SingleSelect from './../../Common/SingleSelect/SingleSelect';

const CreateCohort = (props) => {
  const [isEditing, setIsEditing] = React.useState(false);

  useEffect(() => {
    if (!isEditing && Object.keys(props.ui.errors).length > 0) {
      const firstError = document.querySelector('.error');
      // on desktop only, clicking on a sidebar link scrolls to the associated section
      ZenScroll.setup(600, 10);
      ZenScroll.to(firstError);
    }
  }, [props.ui.errors, isEditing]);

  const capacity = React.useRef(100);

  let name;
  let displayName;

  const handleCloseModal = (e) => {
    e.preventDefault();
    props.toggleModal('createCohortModal');
  };

  const handleClearErrorsOnFocus = (field) => {
    if (!Object.keys(props.ui.errors).length) {
      return;
    }
    props.clearErrorsOnFocus({ feature: 'createCohortModal', field: field });
    setIsEditing(true);
  };

  const resumeOnBlur = () => {
    setIsEditing(false);
  };

  const handleCapacityKeyDown = (e) => {
    // do not allow "e", "-", or "." to be typed in the number input
    if ([69, 189, 190].indexOf(e.keyCode) !== -1) {
      e.preventDefault();
    }
  };

  const handleTerminationDateChange = (date) => {
    props.setTerminationDate({ feature: 'createCohortModal', date: date });
  };

  const handleStartDateChange = (date) => {
    props.setStartDate({ feature: 'createCohortModal', date: date });
  };

  const handleEndDateChange = (date) => {
    props.setEndDate({ feature: 'createCohortModal', date: date });
  };

  const handleAddCohort = (e) => {
    e.preventDefault();
    // don't do anything if the form is submitting
    if (props.ui.isLoading) {
      return;
    }

    props.addCohort({
      name: name.value,
      displayName: displayName.value,
      capacity: capacity.current.value,
      displayDescription:
        CKEDITOR.instances[
          'cohort__display-description--wysiwyg-cohort-display-description-0'
        ].getData(),
    });
  };

  // set up errors for name
  let nameErrorClass = '';
  let nameErrorNode = null;
  if ('name' in props.ui.errors) {
    nameErrorNode = <div className="tc__error">{props.ui.errors.name}</div>;
    nameErrorClass = ' error';
  }

  // set up errors for display name
  let displayNameErrorClass = '';
  let displayNameErrorNode = null;
  if ('displayName' in props.ui.errors) {
    displayNameErrorNode = (
      <div className="tc__error">{props.ui.errors.displayName}</div>
    );
    displayNameErrorClass = ' error';
  }

  // set up errors for display name
  let displayDescriptionErrorClass = '';
  let displayDescriptionErrorNode = null;
  if ('displayDescription' in props.ui.errors) {
    displayDescriptionErrorNode = (
      <div className="tc__error">{props.ui.errors.displayDescription}</div>
    );
    displayDescriptionErrorClass = ' error';
  }

  // set up errors for product
  let productErrorClass = '';
  let productErrorNode = null;
  if ('product' in props.ui.errors) {
    productErrorNode = (
      <div className="tc__error elevated">{props.ui.errors.product}</div>
    );
    productErrorClass = ' error';
  }

  // set up errors for partner
  let partnerErrorClass = '';
  let partnerErrorNode = null;
  if ('partner' in props.ui.errors) {
    partnerErrorNode = (
      <div className="tc__error elevated">{props.ui.errors.partner}</div>
    );
    partnerErrorClass = ' error';
  }

  // set up errors for dates
  let terminationErrorClass = '';
  let startErrorClass = '';
  let endErrorClass = '';
  let terminationErrorNode = null;
  let startErrorNode = null;
  let endErrorNode = null;
  if ('termination' in props.ui.errors) {
    terminationErrorNode = (
      <div className="tc__error elevated">{props.ui.errors.termination}</div>
    );
    terminationErrorClass = ' error';
  }
  if ('start' in props.ui.errors) {
    startErrorNode = (
      <div className="tc__error elevated date">{props.ui.errors.start}</div>
    );
    startErrorClass = ' error';
  }
  if ('end' in props.ui.errors) {
    endErrorNode = (
      <div className="tc__error elevated date">{props.ui.errors.end}</div>
    );
    endErrorClass = ' error';
  }

  // set up errors for capacity
  let capacityErrorClass = '';
  let capacityErrorNode = null;
  if ('capacity' in props.ui.errors) {
    capacityErrorNode = (
      <div className="tc__error">{props.ui.errors.capacity}</div>
    );
    capacityErrorClass = ' error';
  }

  // disable buttons when submitting
  let disabledClass = '';
  let spinnerNode = null;
  if (props.ui.isLoading) {
    disabledClass = ' disabled';
    spinnerNode = <span className="littlespinner"></span>;
  }

  const startDateMoment = moment(props.ui.startDate);
  const terminationDateMoment = moment(props.ui.terminationDate);
  const enabledStartDates = [
    (date) => {
      const checkDate = moment(date);
      return checkDate.isBefore(terminationDateMoment, 'day');
    },
  ];
  const enabledEndTimes = [
    (date) => {
      const checkDate = moment(date);
      return (
        checkDate.isAfter(startDateMoment, 'day') &&
        checkDate.isSameOrBefore(terminationDateMoment, 'day')
      );
    },
  ];
  return (
    <div>
      <div
        onClick={handleCloseModal}
        className="cohort-modal modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
      ></div>
      <div className="ppd-v3-cohort-modal modal--absolute modal--grey-overlay ppd-v3-curation-modal">
        <form className="ppd-v3-curation-modal__body">
          <div className="modal__close" onClick={handleCloseModal}>
            <div className="tc-x"></div>
          </div>

          <h3 className="tc__modal-add-headline tc__headline-3">
            Create a Cohort
          </h3>

          <div className="tc__modal-add-row">
            <label className="tc__modal-add-label">
              Name
              <input
                type="text"
                className={`v5__input form-control input${nameErrorClass}`}
                ref={(ref) => (name = ref)}
                autoFocus={true}
                onFocus={() => handleClearErrorsOnFocus('name')}
                onBlur={resumeOnBlur}
                defaultValue={name}
              />
            </label>
            {nameErrorNode}
          </div>
          <div className="tc__modal-add-row">
            <label htmlFor="display-name" className="tc__modal-add-label">
              Display Name
            </label>
            <div className="v5__admin--section-description">
              This name is displayed on the coaching registration landing page
              and Partner Leader dashboard.
            </div>
            <input
              type="text"
              id="display-name"
              className={`v5__input form-control input${displayNameErrorClass}`}
              ref={(ref) => (displayName = ref)}
              onFocus={() => handleClearErrorsOnFocus('displayName')}
              onBlur={resumeOnBlur}
              defaultValue={displayName}
            />

            {displayNameErrorNode}
          </div>
          <div className={`tc__modal-add-row${partnerErrorClass}`}>
            <label className="tc__modal-add-label">
              Partner
              <SingleSelect
                {...props.ui.partner}
                field="partner"
                feature="createCohortModal"
                minCharCountForResults={0}
                updateSingleSelect={props.updateSelect}
                getVisibleOptions={props.getVisibleOptions}
                handleFocus={() => handleClearErrorsOnFocus('partner')}
                handleBlur={resumeOnBlur}
              />
            </label>
            {partnerErrorNode}
          </div>
          <div className="tc__modal-add-row">
            <label className="tc__modal-add-label">
              Description of Service
              <div
                className={`wysiwyg-error-container${displayDescriptionErrorClass}`}
              >
                <CKEditor
                  id="cohort-display-description"
                  increment={0}
                  innerText={props.displayDescription}
                  className="cohort__display-description--wysiwyg"
                  configs="/legacy-libs/configs/bl-cke-config-cohort.js"
                  handleFocus={() =>
                    handleClearErrorsOnFocus('displayDescription')
                  }
                  handleBlur={resumeOnBlur}
                />
                {displayDescriptionErrorNode}
              </div>
            </label>
          </div>
          <div className={`tc__modal-add-row${productErrorClass}`}>
            <label className="tc__modal-add-label">
              BL Product
              <SingleSelect
                {...props.ui.product}
                field="product"
                feature="createCohortModal"
                minCharCountForResults={0}
                updateSingleSelect={props.updateSelect}
                getVisibleOptions={props.getVisibleOptions}
                handleFocus={() => handleClearErrorsOnFocus('product')}
                handleBlur={resumeOnBlur}
              />
            </label>
            {productErrorNode}
          </div>
          <div className={`tc__modal-add-row${capacityErrorClass}`}>
            <label className="tc__modal-add-label">
              Cohort Size
              <input
                type="number"
                ref={capacity}
                className={`v5__input form-control input${capacityErrorClass}`}
                min="0"
                onKeyDown={handleCapacityKeyDown}
                defaultValue={props.cohort && props.cohort.capacity}
                onFocus={() => handleClearErrorsOnFocus('capacity')}
                onBlur={resumeOnBlur}
              />
            </label>
            {capacityErrorNode}
          </div>
          <div className="tc__modal-add-row inline">
            <div className={`flatpickr-container${terminationErrorClass}`}>
              <label className="tc__modal-add-label">
                <div>
                  Termination Date
                  <div className="cohort-dash__label-subscript">
                    Once this date is submitted, it cannot be edited
                  </div>
                </div>
                <Flatpickr
                  className="v5__input"
                  data-testid="termination-date"
                  onChange={(selectedTimes, timeStr) =>
                    handleTerminationDateChange(timeStr)
                  }
                  onOpen={() => handleClearErrorsOnFocus('term')}
                  onClose={resumeOnBlur}
                  options={{
                    altInput: true,
                    altFormat: 'm/d/Y',
                    dateFormat: 'Y-m-d',
                    time_24hr: false,
                    defaultDate: props.ui.terminationDate,
                    position: 'below',
                    static: 'true',
                    minDate: moment().format('YYYY-MM-DD'),
                  }}
                />
              </label>
              {terminationErrorNode}
            </div>
          </div>

          <div className="tc__modal-add-row inline">
            <div className={`flatpickr-container${startErrorClass}`}>
              <label className="tc__modal-add-label">
                <div>
                  Start Date
                  <div className="cohort-dash__label-subscript">
                    This is the required start date for a cohort
                  </div>
                </div>
                <Flatpickr
                  className="v5__input"
                  data-testid="start-date"
                  onChange={(selectedTimes, timeStr) =>
                    handleStartDateChange(timeStr)
                  }
                  onOpen={() => handleClearErrorsOnFocus('term')}
                  onClose={resumeOnBlur}
                  options={{
                    altInput: true,
                    altFormat: 'm/d/Y',
                    dateFormat: 'Y-m-d',
                    time_24hr: false,
                    defaultDate: props.ui.startDate,
                    position: 'below',
                    static: 'true',
                    enable: enabledStartDates,
                  }}
                />
              </label>
              {startErrorNode}
            </div>
            <div className={`flatpickr-container${endErrorClass}`}>
              <label className="tc__modal-add-label">
                <div>
                  End Date
                  <div className="cohort-dash__label-subscript">
                    This is the required display end date for a cohort
                  </div>
                </div>
                <Flatpickr
                  className="v5__input"
                  data-testid="end-date"
                  onChange={(selectedTimes, timeStr) =>
                    handleEndDateChange(timeStr)
                  }
                  onOpen={() => handleClearErrorsOnFocus('term')}
                  onClose={resumeOnBlur}
                  options={{
                    altInput: true,
                    altFormat: 'm/d/Y',
                    dateFormat: 'Y-m-d',
                    time_24hr: false,
                    defaultDate: props.ui.endDate,
                    position: 'below',
                    static: 'true',
                    enable: enabledEndTimes,
                  }}
                />
                {endErrorNode}
              </label>
            </div>
          </div>

          <div className="tc__modal-add-row tc__modal-add-row--btn">
            <button
              className={`tc__btn tc__btn--blue tc__btn--small tc__btn--add-submit${disabledClass}`}
              onClick={handleAddCohort}
            >
              {spinnerNode}
              Submit
            </button>
          </div>
        </form>
      </div>
      <FreezeWindowScroll parentModal="cohort-modal" />
    </div>
  );
};

CreateCohort.propTypes = {
  ui: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  clearErrorsOnFocus: PropTypes.func.isRequired,
  updateSelect: PropTypes.func.isRequired,
  getVisibleOptions: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  addCohort: PropTypes.func.isRequired,
};

export default CreateCohort;
