// @ts-nocheck FIXME
import Content from '@legacy-components/components/Content';
import { Router } from '@legacy-components/components/Router';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';
import ReactDOM from 'react-dom';
import ScaleCenterImage from './../../ScaleCenterImage';

class StrategyCapsule extends React.Component {
  state = {
    copyHeight: 'auto',
    textAbridged: false,
    viablePreview: false,
  };

  componentMounted = false;

  componentDidMount() {
    this.componentMounted = true;
    // check for a preview image
    Content.checkForPreview(this, this.props.strategy.preview);

    // get dom elements for measurments
    const title = ReactDOM.findDOMNode(this.refs.implementationTitle);
    const strategy = ReactDOM.findDOMNode(this.refs.strategyTitle);
    const copy = ReactDOM.findDOMNode(this.refs.implementationCopy);

    // use dome element measurements for text abridgint if necessary
    const titleHeight = title.offsetHeight > 18 ? title.offsetHeight - 18 : 0;
    const strategyHeight =
      strategy.offsetHeight > 12 ? strategy.offsetHeight - 12 : 0;
    const copyHeight =
      Math.floor((140 - titleHeight - strategyHeight) / 20) * 20;

    if (copy.offsetHeight > copyHeight) {
      this.setState({
        copyHeight: copyHeight + 'px',
        textAbridged: true,
      });
    }
  }

  componentWillUnmount() {
    this.componentMounted = false;
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // if the url didn't get passed in, don't try to route to it
    if (!this.props.url) {
      return;
    }
    let url = this.props.url;

    if (this.props.analyticsFrom) {
      url += '?from=' + this.props.analyticsFrom;
    }

    Router.route(url);
  };

  render() {
    const metaStrategyName = this.props.strategy.meta_strategy_name;

    // add readmore node if necessary
    let readMoreNode = '';
    if (this.state.textAbridged) {
      readMoreNode = (
        <div
          className="strategy__readmore tc__headline-6 tc__link"
          onClick={this.handleClick}
        >
          Read More
        </div>
      );
    }

    // set up a strategy icon if available
    let strategyIcon = 'doc';
    if (this.props.strategy.extension) {
      strategyIcon = this.props.strategy.extension;
    }

    // default node with no preview
    let strategyPreviewImage = (
      <div className="strategy__image-mask strategy__image--none">
        <div
          className={
            'strategy__preview-icon file-type-icon ext-' + strategyIcon
          }
        ></div>
      </div>
    );

    // if there is apreview available display it
    if (this.state.viablePreview) {
      strategyPreviewImage = (
        <ScaleCenterImage
          src={this.props.strategy.preview}
          className="strategy__image"
          alt={this.props.strategy.strategy}
        />
      );
    }

    let url = this.props.url;

    if (this.props.analyticsFrom) {
      url += '?from=' + this.props.analyticsFrom;
    }

    let strategyNode;
    // Show the meta strategy if we have it:
    if (metaStrategyName) {
      strategyNode = (
        <div className="strategy__strategy-container">
          <div className="strategy__vertical-alignment">
            <span className="strategy__meta-strategy-title" ref="strategyTitle">
              {metaStrategyName}
            </span>
          </div>
        </div>
      );
    } else {
      strategyNode = (
        <div className="strategy__strategy-container">
          <div className="strategy__vertical-alignment">
            <span className="strategy__icon tc-target"></span>
          </div>
          <div className="strategy__vertical-alignment">
            <span className="strategy__strategy-title" ref="strategyTitle">
              {this.props.strategy.strategy}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="strategy-choose__grid-item cute-4-desktop cute-12-phone left">
        <div
          className="tc__capsule tc__capsule--hoverable tc__capsule--strategy-choose"
          ref="strategyCapsule"
          onClick={this.handleClick}
        >
          <h4
            ref="implementationTitle"
            className="tc__capsule-headline strategy__implementation-title"
          >
            <a href={url} onClick={this.handleClick}>
              {this.props.strategy.title}
            </a>
          </h4>
          {strategyPreviewImage}
          <PurifiedHTML
            ref="implementationCopy"
            style={{ height: this.state.copyHeight }}
            className="strategy__implementation-text tc__body-copy"
            content={this.props.strategy.description}
          />
          {readMoreNode}
          {strategyNode}
        </div>
      </div>
    );
  }
}

export default StrategyCapsule;
