// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AbridgeText from './../../Common/AbridgeText';
import ResourceCapsule from './ResourceCapsule';

const StrategyPack = (props) => {
  // expand/collapse the list of strategies in the pack
  const [isExpanded, setIsExpanded] = useState(false);

  // set up the pack description if it has one
  const descriptionNode = props.description ? (
    <AbridgeText
      maxHeight={104}
      isExpandable={true}
      containerClassName="select-resource__pack-description"
    >
      <PurifiedHTML className="v5__comment-copy" content={props.description} />
    </AbridgeText>
  ) : null;

  // set up the expand/collapse arrow
  const arrowClass = isExpanded ? ' up' : '';

  // set up the strategy capsules
  const strategiesNode = ArrayHelpers.hasElements(props.strategies) ? (
    <div className="select-resource__pack-strategies">
      {props.strategies.map((strategy) => {
        return (
          <ResourceCapsule
            key={`pack-strategy-${strategy.id}`}
            id={strategy.id}
            type={strategy.type}
            previewPhoto={strategy.previewPhoto}
            title={strategy.title}
            tagline={strategy.tagline}
            seo_url={strategy.seo_url}
            isSelected={
              props.selectedResource.packId === props.id &&
              props.selectedResource.resourceId === strategy.id
            }
            select={(e) => {
              e.stopPropagation();
              props.setSelectedResource({
                packId: props.id,
                resourceId: strategy.id,
              });
            }}
            userId={props.userId}
            storyId={props.storyId}
            setErrorAlert={props.setErrorAlert}
            packTitle={props.title}
          />
        );
      })}
    </div>
  ) : null;

  const domainClass = ` v5__domain-label--${props.focus_area_id}`;

  return (
    <div className="select-resource__pack">
      <div className="select-resource__pack-wrapper">
        <div>
          <div
            className={`v5__body-copy select-resource__pack-domain${domainClass}`}
          >
            {props.focus_area_title}
          </div>

          <div className="v5__heading-5 select-resource__pack-title">
            {props.title}
          </div>

          <div className="select-resource__pack-visibility">
            <span className="v5__heading-6 select-resource__pack-visibility-text">
              {props.strategies.length} Strateg
              {props.strategies.length === 1 ? 'y' : 'ies'}
            </span>
          </div>
        </div>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          className={`tc-v4-circle-arrow select-resource__pack-expand-arrow ${arrowClass}`}
        ></div>
      </div>
      {isExpanded && descriptionNode}
      {isExpanded && strategiesNode}
    </div>
  );
};

StrategyPack.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  strategies: PropTypes.array,
  selectedResource: PropTypes.object.isRequired,
  setSelectedResource: PropTypes.func.isRequired,
};

export default StrategyPack;
