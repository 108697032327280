// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import DomainTag from '@legacy-components/react/V5/Workspace/modules/Goal/DomainTag';
import PropTypes from 'prop-types';
import AbridgeText from './../../../Common/AbridgeText';

const GoalEntry = (props) => {
  return (
    <div className="workspace__entry-body workspace__goal-title-body">
      <div className="workspace__entry-row">
        <AbridgeText
          maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
          isExpandable={true}
        >
          <PurifiedHTML
            className="ckeditor__output workspace__highlighted-goal-sub-focus-area"
            content={props.description}
          />
        </AbridgeText>

        <DomainTag
          containerStyles="workspace__goal-domain--align-top workspace__goal-domain-tag"
          tagStyles="workspace__goal-domain--goal-display"
          id={props.focus_area.id}
          tagline={props.focus_area.tagline}
          label={props.focus_area.title}
          hover={true}
        />
      </div>
    </div>
  );
};

GoalEntry.propTypes = {
  description: PropTypes.string.isRequired,
};

export default GoalEntry;
