// @ts-nocheck FIXME
import UpdatesRowContainers from '@legacy-components/react/V5/PartnerLeader/modules/UpdatesRowContainer';
import React from 'react';

const LearningDomains = (props) => {
  let bodyNode = null;
  if (props.search_failed) {
    bodyNode = (
      <React.Fragment>
        <div className="v5__registered-table-details v5__heading-5 row home-v5__empty-activity-capsule">
          <div>
            <img
              src="/legacy-images/404-pets.png"
              className="code-404__img-overview"
            />
          </div>
          <div className="home-v5__empty-activity-row">
            <div className="v5__heading-4">Oops! Something is broken.</div>
            <div className="v5__comment-copy">
              <div>
                Updates from your cohort(s) is temporarily unavailable. (We
                blame the cat.)
              </div>
              <div>Thanks for your patience as we tidy up this issue.</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    const domainRows =
      props.total_learning_domains.length === 0 ? (
        <div className="v5__body-copy partner_lead__missing-content">
          Learning Domains have not been selected yet.
        </div>
      ) : (
        <UpdatesRowContainers
          total_learning_domains={props.total_learning_domains}
          section={'domains'}
        />
      );

    const highlightRows =
      props.highlight_entries.length === 0 ? (
        <div className="v5__body-copy partner_lead__missing-content">
          Your educators’ professional development journeys are still a work in
          progress. Check back later for updates.
        </div>
      ) : (
        <UpdatesRowContainers
          highlight_entries={props.highlight_entries}
          reportingPhases={props.reportingPhases}
          section={'highlights'}
        />
      );

    const strategyRows =
      props.strategies.length === 0 ? (
        <div className="v5__body-copy partner_lead__missing-content">
          Your educators haven’t selected strategies to try just yet. Check back
          later for updates.
        </div>
      ) : (
        <UpdatesRowContainers
          strategies={props.strategies}
          section={'strategies'}
        />
      );
    let currentInfo = (
      <React.Fragment>
        <div className="v5__heading-3 partner_lead__learning_domain_header">
          Recent Strategies
        </div>
        {strategyRows}
        <div className="v5__heading-3 partner_lead__learning_domain_header">
          Recent Highlights
        </div>
        {highlightRows}
      </React.Fragment>
    );
    let pastNode = null;
    let className = '';
    if (props.school_year === 'past') {
      className = 'partner_lead__learning_domain_header--past';
      pastNode = (
        <div className="v5__body-copy">
          Displaying data from your 2019-20 cohorts.
        </div>
      );
      currentInfo = null;
    }

    bodyNode = (
      <React.Fragment>
        <div
          className={`v5__heading-3 partner_lead__learning_domain_header ${className}`}
        >
          Learning Domains
          {pastNode}
        </div>
        {domainRows}
        {currentInfo}
      </React.Fragment>
    );
  }

  return <React.Fragment>{bodyNode}</React.Fragment>;
};

export default LearningDomains;
