// @ts-nocheck FIXME
import Store from './../../components/Store';

const store = {
  portfolio: {},
  entries: {},
};

store.init = (data) => {
  store.portfolio = data.portfolio;
  store.entries = data.entries ? data.entries[0].data : {};
};

export default Store('TeacherPortfolioStore', store);
