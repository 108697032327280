// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Analytics from './../../../components/Analytics';
import Auth from './../../../components/Auth';
import MobileArtifactUpload from './../Common/ArtifactUpload/MobileArtifactUpload';

const AddVisionArtifact = (props) => {
  if (!Auth.isV3DataEditor(props.user)) {
    return null;
  }

  const handleToggleArtifactOptions = (e) => {
    e.preventDefault();
    props.toggleUploadArtifactOptions();
  };

  const handleAddResourceArtifact = (e) => {
    props.openArtifactModal('upload');
  };

  const handleAddLinkArtifact = (e) => {
    props.openArtifactModal('link');
  };

  const handleAddTextArtifact = (e) => {
    props.ui.textArtifactModal.open({});
  };

  // Handles uploading mobile files
  // Opens the uploaded artifact modal and submits the files to that modal
  const submitMobileFiles = (data) => {
    // on submit ask the store to open the artifact modal and submit the files.
    props.openArtifactModal('upload');
    props.submitFiles({ inputFiles: data.inputFiles });
  };

  const handleSelectMobileFiles = () => {
    Analytics.logEvent({
      category: 'artifact',
      action: 'select_files',
      label: 'from_vision_artifact_mobile',
    });
  };

  let artifactOptions = null;
  let activeClass = '';
  if (props.ui.artifactUpload.optionsVisibile) {
    activeClass = ' vision__artifact-add--active';
    artifactOptions = (
      <div className="vision__artifact-options">
        <div
          className="vision__artifact-option show-desktop hide-phone"
          onClick={handleAddResourceArtifact}
        >
          <div className="tc-save-upload vision__artifact-option-icon"></div>
          <div className="vision__artifact-option-caption">File</div>
        </div>
        <div className="vision__artifact-option hide-desktop show-phone-inline">
          <MobileArtifactUpload
            submitFiles={submitMobileFiles}
            handleSelectFiles={handleSelectMobileFiles}
          />
          <div className="tc-camera vision__artifact-option-icon"></div>
          <div className="vision__artifact-option-caption">Media</div>
        </div>

        <div
          className="vision__artifact-option"
          onClick={handleAddLinkArtifact}
        >
          <div className="tc-save-url vision__artifact-option-icon"></div>
          <div className="vision__artifact-option-caption">Link</div>
        </div>
        <div
          className="vision__artifact-option"
          onClick={(e) => handleAddTextArtifact(e)}
        >
          <div className="tc-text vision__artifact-option-icon"></div>
          <div className="vision__artifact-option-caption">Text</div>
        </div>
      </div>
    );
  }

  return (
    <div className={'vision__artifact-add' + activeClass}>
      <div
        className="tc-add-artifact"
        onClick={handleToggleArtifactOptions}
      ></div>
      {artifactOptions}
    </div>
  );
};

AddVisionArtifact.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  toggleUploadArtifactOptions: PropTypes.func.isRequired,
};

export default AddVisionArtifact;
