// @ts-nocheck FIXME
import Category from './GrowthMap/Category';

const GrowthMap = (props) => {
  const categoryNodes = [];
  for (const category of props.growthmap) {
    categoryNodes.push(
      <Category key={'category' + category.id} sga={category} />
    );
  }

  return (
    <div>
      <div className="portfolio__growth-map portfolio-v2__loop-section">
        <div className="portfolio__section-headline portfolio__section-headline--growthmap">
          Growth Map
        </div>
        {categoryNodes}
      </div>
      <hr className="page-break" />
    </div>
  );
};

export default GrowthMap;
