// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';

///////////////

import Spinner from './../../Common/Spinner';

import ActionModal from './../../Common/ActionModal';
import FormField from './../../Common/FormField';

const ParticipantNoteModal = (props) => {
  // set defaults that might change with the timezone being available
  const [isLoading, setLoading] = useState(false);
  const [participantNote, setParticipantNote] = useState(props.note || '');
  const [participantError, setParticipantError] = useState('');

  const handleChangeNote = (e) => {
    setParticipantNote(e.target.value);
    if (e.target.value === '') {
      setParticipantError('Whoops! Looks like you forgot to write your note');
    } else {
      setParticipantError('');
    }
  };

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handleAddNote = (e) => {
    e.preventDefault();

    if (participantNote === '') {
      setParticipantError('Whoops! Looks like you forgot to write your note');
      return;
    }

    setLoading(true);

    props.submitNote({
      note: participantNote,
      successCallback: props.close,
      failureCallback: () => setLoading(false),
    });
  };

  let headerText = 'Add a Status Note';
  let buttonText = 'Add Status Note';

  // If a note is provided then it's not being edited it's being added
  if (props.note) {
    headerText = 'Edit Status Note';
    buttonText = 'Save';
  }
  // disable buttons when submitting
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <ActionModal headlineText={headerText} close={handleCloseModal}>
      <form className="participant-v5__modal-body">
        <div className="workspace__form-row participant-v5__modal-top-row v5__heading-5 participant-v5__meeting-cancel-modal-topline">
          <div className="v5__body-copy ">
            Share additional information about this educator&apos;s status
            <div className="participant-v5__status-note-help-text">
              Notes unrelated to a status change should not be added here
            </div>
          </div>
        </div>

        <div className="workspace__form-row">
          <FormField
            type="textarea"
            disabled={isLoading}
            error={participantError}
            rows={6}
            defaultValue={participantNote}
            onChange={handleChangeNote}
            dataTestId="participant-note-field"
          />
        </div>

        <div className="workspace__form-row">
          <div className="participant-v5__participant-note-button-row">
            <button className="v5__btn" onClick={handleAddNote} {...btnProps}>
              <Spinner loading={isLoading} />
              {buttonText}
            </button>
          </div>
        </div>
      </form>
    </ActionModal>
  );
};

ParticipantNoteModal.propTypes = {
  close: PropTypes.func.isRequired,
  submitNote: PropTypes.func.isRequired,
  participantName: PropTypes.string.isRequired,
  note: PropTypes.string,
  authorId: PropTypes.number,
};

export default ParticipantNoteModal;
