// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const ParticipantNote = (props) => {
  const iconMap = {
    Active: 'tc-icon_status-active participant-v5__active-icon-color',
    'Temporarily Inactive': 'tc-icon_status-temp-inactive',
    'Done with Coaching': 'tc-icon_status-done',
    Dropped: 'tc-icon_status-dropped',
    Disengaged: 'tc-icon_status-disengaged',
    Note: 'tc-icon_status-active participant-v5__note-icon-color',
    Registered: 'tc-v5-complete-outline participant-v5__active-icon-color',
  };
  let titleNode = 'Note';
  let editNode = props.editNote && (
    <div
      className="tc-v4-pencil participant-v5__participant-note-pencil"
      onClick={() =>
        props.setEditNote({
          status: props.status,
          note: props.note,
          noteId: props.id,
        })
      }
      data-testid={`participant-note-${props.id}-edit`}
    />
  );

  if (!props.canEditData || props.isArchived) {
    editNode = null;
  }

  const authorName = (
    <span className="participant-v5__participant-note-author">
      {props.author_name || 'Auto-updated'}
    </span>
  );
  const status = props.status ? props.status : 'Note';
  if (iconMap[status]) {
    titleNode = (
      <div className="participant-v5__participant-note-name">
        <span
          className={`${iconMap[status]} participant-v5__participant-note-icons`}
        >
          <span className="path1"></span>
          <span className="path2"> </span>
        </span>
        <span className="participant-v5__participant-note-status">
          {Utils.formatEngagementStatus(status)}
        </span>
      </div>
    );
  }
  return (
    <div className="cute-12-desktop participant-v5__flush-left participant-v5__participant-note-row">
      {titleNode}
      <div className="participant-v5__participant-note-text">
        <div>{props.note}</div>
        <div className="participant-v5__participant-note-author-line">
          <div>
            &mdash;{authorName},{' '}
            {moment(props.lastmodified_formatted)
              .tz(props.timeZone)
              .format('MM/DD/YY hh:mm A')}
          </div>
        </div>
      </div>
      {editNode}
    </div>
  );
};

ParticipantNote.propTypes = {
  status: PropTypes.string,
  note: PropTypes.string.isRequired,
  author_name: PropTypes.string.isRequired,
  lastmodified_formatted: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  editNote: PropTypes.bool.isRequired,
  setEditNote: PropTypes.func,
  isArchived: PropTypes.bool.isRequired,
};

export default ParticipantNote;
