// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import PropTypes from 'prop-types';
import GroupMaterialsCapsule from './GroupMaterialsCapsule';
import SessionMaterialsCapsule from './SessionMaterialsCapsule';

const SessionMaterials = (props) => {
  // add the index to every group object, and hide deleted groups
  const groups = props.groups
    .map((group, i) => Object.assign(group, { index: i }))
    .filter((group) => !group.deleted);

  // add the index to every session object, and hide deleted sessions
  const sessions = props.sessions
    .map((session, i) => Object.assign(session, { index: i }))
    .filter((session) => !session.deleted);

  // show the session capsules inside their group if necessary
  let capsuleNodes;
  if (ArrayHelpers.hasElements(groups)) {
    capsuleNodes = groups.map((group) => {
      return (
        <GroupMaterialsCapsule
          key={`group-${group.num}`}
          {...group}
          eventHasModifications={props.eventHasModifications}
          canEditEventDetails={props.canEditEventDetails}
          allProductCodes={props.allProductCodes}
          facilitatorOptions={props.facilitatorOptions}
          handleCopy={props.handleCopy}
          sessions={sessions.filter(
            (session) => session.group_num === group.num
          )}
          setAlert={props.setAlert}
          setModalOpen={props.setModalOpen}
          updateSession={props.updateSession}
          user={props.user}
        />
      );
    });
  } else {
    capsuleNodes = sessions.map((session, i) => {
      return (
        <SessionMaterialsCapsule
          key={`session-${session.id}-${session.num}`}
          {...session}
          eventHasModifications={props.eventHasModifications}
          canEditEventDetails={props.canEditEventDetails}
          allProductCodes={props.allProductCodes}
          facilitatorOptions={props.facilitatorOptions}
          handleCopy={props.handleCopy}
          isFirst={i === 0}
          setAlert={props.setAlert}
          setModalOpen={props.setModalOpen}
          singleSession={sessions.length === 1}
          update={(data) => props.updateSession(session.index, data)}
          user={props.user}
        />
      );
    });
  }

  return (
    <div className="edit-event__bottom-section">
      <div className="v5__heading-5 edit-event__section-headline-row">
        Facilitators and Event Materials
      </div>
      <div className="v5__body-copy v5__body-copy--italic edit-event__subsection-description">
        Assign facilitators and provide links to event materials for all event
        sessions and groups (if applicable).
      </div>

      {capsuleNodes}
    </div>
  );
};

SessionMaterials.propTypes = {
  groups: PropTypes.array.isRequired,
  sessions: PropTypes.array.isRequired,
};

export default SessionMaterials;
