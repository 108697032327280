// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import FormField from '@legacy-components/react/V5/Common/FormField';
import React, { useState } from 'react';
import InstitutionSearch from './InstitutionSearch';

const Institution = (props) => {
  const errors = props.errors || {};
  const [schoolMode, setSchoolMode] = useState('search');

  const setUnSavedChanges = (value) => {
    if (props.setUnSavedChanges) {
      props.setUnSavedChanges(value);
    }
  };

  // change form from search to add new
  const handleSetSchoolMode = (mode) => {
    const errors = Utils.clone(props.errors || {});

    // clear institution errors
    delete errors.schoolSearch;
    delete errors.schoolName;
    delete errors.schoolZipcode;
    if (props.setErrors) {
      props.setErrors(errors);
    }

    if (mode === 'search') {
      props.setInstitutionName(null);
      props.setInstitutionZipcode(null);
      props.setInstitutionId(null);
    }
    setSchoolMode(mode);
  };

  const handleFocus = () => {};

  let cantFindSchoolLink = null;
  if (!props.selectedOption) {
    cantFindSchoolLink = (
      <div
        data-test-id="no-school-link"
        className={'account-institution__link solicit-school__link tc__link'}
        onClick={() => {
          handleSetSchoolMode('new');
        }}
      >
        I can&apos;t find my school or district
      </div>
    );
  }
  let searchError = null;
  if (errors.schoolSearch) {
    searchError = (
      <div className="v5__error v5__error--relative">
        {props.errors.schoolSearch}
      </div>
    );
  }
  let institutionNode = (
    <div className="form-field">
      <div className="settings__label">
        Search by entering your school or district ZIP code.
      </div>
      <InstitutionSearch
        error={errors.schoolSearch}
        setInstitutionId={props.setInstitutionId}
        selectedOption={props.selectedOption}
        setSelectedOption={props.setSelectedOption}
        setNoSchool={props.setNoSchool}
        setUnSavedChanges={setUnSavedChanges}
        setAlert={props.setAlert}
      />
      {searchError}
      {cantFindSchoolLink}
    </div>
  );
  if (schoolMode === 'new') {
    institutionNode = (
      <React.Fragment>
        <div className="settings__label">School or district name</div>
        <FormField
          type="text"
          data-test-id="institution-name-field"
          onChange={(e) => {
            setUnSavedChanges(true);
            props.setInstitutionName(e.target.value);
          }}
          onFocus={(e) => handleFocus(e, 'schoolName')}
          error={errors.schoolName}
          className="settings__input"
        />

        <div className="settings__label">School or district ZIP code</div>
        <FormField
          type="text"
          data-test-id="institution-zip-field"
          onChange={(e) => {
            setUnSavedChanges(true);
            props.setInstitutionZipcode(e.target.value);
          }}
          onFocus={(e) => handleFocus(e, 'schoolZipcode')}
          error={errors.schoolZipcode}
          className="settings__input"
        />

        <div
          className={'account-institution__link solicit-school__link tc__link'}
          onClick={() => handleSetSchoolMode('search')}
        >
          Search schools or district
        </div>

        <div
          className={'account-institution__link tc__link'}
          onClick={() => {
            props.setInstitutionId(0);
            props.setSelectedOption({
              id: 0,
              label: 'Not Associated With School',
            });
            props.setNoSchool(true);
            handleSetSchoolMode('search');
          }}
        >
          I&apos;m not associated with a specific school or district
        </div>
      </React.Fragment>
    );
  }
  return <React.Fragment>{institutionNode}</React.Fragment>;
};

export default Institution;
