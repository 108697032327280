// @ts-nocheck FIXME
import React from 'react';
import BrowserStore from './../../../stores/V2/BrowserStore';
import NavigationLink from './../../NavigationLink';

class SGANodeV2 extends React.Component {
  state = BrowserStore.getState();
  token = null;

  onStoreChange = () => {
    this.setState(BrowserStore.getState());
  };

  componentDidMount() {
    this.token = BrowserStore.subscribe(this.onStoreChange);
  }

  componentWillUnmount() {
    BrowserStore.unsubscribe(this.token);
  }

  setActiveState = () => {
    if (this.state.activeSGAId == this.props.sga.id) {
      BrowserStore.actionDispatchSync('closeSGAMenu', this.props.sga.id);
      return false;
    }

    BrowserStore.actionDispatchSync('openSGAMenu', this.props.sga.id);
  };

  render() {
    const sga = this.props.sga;

    // teacher growth areas (inside the Instructional Design category) are a different color
    const sgaIconClass =
      this.state.activeCategory == 300 ? ' tc__sga-icon--tga' : '';
    const sgaNameClass =
      this.state.activeCategory == 300 ? ' browser__sga-details--tga' : '';

    // set up the sga teaching challenge links
    let numAddedChallenges = 0;
    const teachingChallengesNodes = sga.tc.map(function (challenge) {
      let classNames = 'tc__link';
      if (challenge.status) {
        classNames += ' tc__link--' + challenge.status;
        numAddedChallenges += 1;
      } else {
        classNames += ' tc__link--none';
      }

      return (
        <div
          key={'tc-' + challenge.id}
          className="browser__challenge browser__challenge--v2"
        >
          <NavigationLink
            className={classNames}
            url={
              '/coaching/lab/strategy-browser/teaching-challenge/' +
              challenge.id +
              '/' +
              challenge.seo_url +
              '?from=sb_tc_name'
            }
          >
            {challenge.nickname}
          </NavigationLink>
        </div>
      );
    }, this);

    // only show the # teaching challenges in the sga if it is not opened
    let challengeCountNode = null;
    let numChallengesNode = null;
    let activeClass = ' active';
    if (this.state.activeSGAId != this.props.sga.id) {
      challengeCountNode = (
        <div className="browser-v2__sga-challenge-count show-phone hide-desktop">
          {teachingChallengesNodes.length} Teaching Challenges
        </div>
      );
      // only show the # of challenges that belong to the current user if they have any
      if (numAddedChallenges) {
        numChallengesNode = (
          <div className="browser-v2__sga-added-challenges-count show-phone hide-desktop">
            Your Challenges: {numAddedChallenges}
          </div>
        );
      }
      activeClass = '';
    }

    // return the sgas for the catagories
    return (
      <div
        key={'sga-' + sga.id}
        onClick={this.setActiveState}
        className="browser__sga cute-3-desktop cute-12-phone left"
      >
        <h4 className="browser__sga-title browser__sga-title--v2">
          <div
            className={
              'tc__sga-icon tc__sga-icon--v2 tc-sga-nb-' + sga.id + sgaIconClass
            }
          ></div>
          <div
            className={
              'browser__sga-details browser__sga-details--v2' + sgaNameClass
            }
          >
            {sga.name}
            {challengeCountNode}
            {numChallengesNode}
          </div>
        </h4>
        <div
          className={
            'browser__sga-challenges browser-v2__sga-challenges' + activeClass
          }
        >
          {teachingChallengesNodes}
        </div>
      </div>
    );
  }
}

export default SGANodeV2;
