// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import HomeApi from '@legacy-components/apis/V5/HomeApi';
import Utils from '@legacy-components/components/DynamicUtils';

const BLConnectSection = () => {
  const lab = useLegacyLabContext();

  const returnToUrl = Utils.hasFeatureFlag.call(lab, 'BL_CONNECT_INTRO_COURSE')
    ? '/account/learning-hub/course/41471'
    : '/account/learning-hub/my-learning/not-started';

  const handleEmptyBLConnectCTAClick = () => {
    HomeApi.createBLConnectCTAClick();
    return true;
  };

  const renderBLConnnectCTA = () =>
    lab.pageData.has_clicked_bl_connect_cta ? (
      <>
        <div className="v5__body-copy home-v5__meetings-capsule-margin">
          <div className="v5__author-copy">
            Access BetterLesson’s self-directed professional learning courses.
          </div>
          <div className="v5__author-title-copy">Keep it going!</div>
        </div>

        <a
          href={process.env.REACT_APP_BL_CONNECT_URL}
          className="home-v5__cta-btn v5__btn"
        >
          BL Courses
        </a>
      </>
    ) : (
      <>
        <div className="v5__body-copy home-v5__meetings-capsule-margin">
          <div className="v5__author-copy">
            No active BL Connect course? Enroll Now!
          </div>
          <div className="v5__author-title-copy">
            Access our library of self-directed professional learning courses.
          </div>
        </div>

        <a
          data-testid="enroll-button"
          href={`${process.env.REACT_APP_BL_CONNECT_URL}?return_to=${returnToUrl}`}
          className="home-v5__cta-btn v5__btn"
          onClick={handleEmptyBLConnectCTAClick}
        >
          Enroll in Courses
        </a>
      </>
    );

  return (
    <div
      className="row home-v5__meetings-capsule"
      data-testid="bl-connect-section"
    >
      <div className="v5__heading-5 home-v5__header">BL Connect Courses</div>
      <div className="v5__border-gradient"></div>
      <div className="home-v5__bl-connect" data-testid="bl-connect-cta">
        {renderBLConnnectCTA()}
      </div>
    </div>
  );
};

export default BLConnectSection;
