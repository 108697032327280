// @ts-nocheck FIXME
import React from 'react';
import HeaderStore from './../stores/HeaderStore';
import HeaderModule from './Header/Header';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.token = null;

    HeaderStore.actionDispatchSync('init', {
      unreadCount: this.props.notifications_unread_count,
      notificationsTotal: this.props.notificationCount,
      headerParticipant: this.props.header_participant,
      keepHeaderParticipant: this.props.show_selected_participant,
      handleTeacherViewSubmit: this.props.handleTeacherViewSubmit,
      user: this.props.user,
    });

    this.state = HeaderStore.getState();

    this.onStoreChange = this.onStoreChange.bind(this);
  }

  onStoreChange() {
    this.setState(HeaderStore.getState());
  }

  componentDidMount() {
    this.token = HeaderStore.subscribe(this.onStoreChange);
  }

  componentWillUnmount() {
    HeaderStore.unsubscribe(this.token);
  }

  // dropdown widget methods
  updateDropdownOverflow(needsOverflow) {
    HeaderStore.actionDispatchSync('updateDropdownOverflow', needsOverflow);
  }
  openDropdown() {
    HeaderStore.actionDispatch('openDropdown');
  }
  closeDropdown() {
    HeaderStore.actionDispatch('closeDropdown');
  }

  // coach view widget methods
  submitTeacherViewModal(checked) {
    HeaderStore.actionDispatch('submitTeacherViewModal', checked);
  }
  openTeacherViewModal() {
    HeaderStore.actionDispatch('openTeacherViewModal');
  }
  closeTeacherViewModal() {
    HeaderStore.actionDispatch('closeTeacherViewModal');
  }

  setDesktopView() {
    HeaderStore.actionDispatchSync('setDesktopView');
  }
  setMobileView() {
    HeaderStore.actionDispatchSync('setMobileView');
  }

  render() {
    return (
      <HeaderModule
        {...this.props}
        {...this.state}
        submitTeacherViewModal={this.submitTeacherViewModal}
        openTeacherViewModal={this.openTeacherViewModal}
        closeTeacherViewModal={this.closeTeacherViewModal}
        setDesktopView={this.setDesktopView}
        setMobileView={this.setMobileView}
        updateDropdownOverflow={this.updateDropdownOverflow}
        openDropdown={this.openDropdown}
        closeDropdown={this.closeDropdown}
      />
    );
  }
}

export default Header;
