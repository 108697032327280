// @ts-nocheck FIXME
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import ResourceApi from './../../../apis/ResourceApi';
import CurationApi from './../../../apis/V4/CurationApi';
import Utils from './../../../components/DynamicUtils';
import { Router } from './../../../components/Router';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';
import CKEditor from './../Common/CKEditor/CKEditor';
import MultiSelect from './../Common/MultiSelect';
import SingleSelect from './../Common/SingleSelect';
import StickyFooter from './../Common/StickyFooter';
import StickyLeftNav from './../Common/StickyLeftNav';
import CuratorNotes from './Common/CuratorNotes';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import ContentBlock from './ContentBlock';
import HeroImage from './HeroImage';

const Strategy = (props) => {
  let alertNode;
  let title = null;
  let curationNotes = null;
  let strategyType = 'strategy';
  let tagline = null;
  let publishType = null;
  let curationType = null;
  let heroTitle;
  let modalNode = null;

  if (!Utils.isEmpty(props.ui.alert)) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // track form errors
  const errorClasses = {},
    errorMessages = {};

  // set up error states for form fields
  const formFieldNames = ['title', 'tagline', 'description'];
  formFieldNames.forEach((fieldName) => {
    errorClasses[fieldName] = '';
    errorMessages[fieldName] = null;
    if (fieldName in props.ui.errors) {
      errorClasses[fieldName] = ' error';
      errorMessages[fieldName] = (
        <div className="tc__error">{props.ui.errors[fieldName]}</div>
      );
    }
  });

  const dirtyPage = () => {
    WindowHelpers.navigationConfirmation(true);
  };

  const taglineCount = (e) => {
    e.preventDefault();
    if (tagline.value.length > 140) {
      tagline.value = tagline.value.substring(0, 140);
    }
    dirtyPage();
    props.updateTaglineLength(tagline.value.length);
  };

  const handleAddContentBlock = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.addContentBlock();
  };

  const handleUpdateAuthorRole = (e) => {
    e.preventDefault();

    if (e.target && e.target.options) {
      const data = {
        role: e.target.options[e.target.options.selectedIndex].text,
        index: e.target.dataset.index,
      };
      props.updateAuthorRole(data);
    }
  };

  const handleUpdateOtherRole = (e) => {
    e.preventDefault();
    dirtyPage();
    const data = {
      role: e.target.value,
      index: e.target.dataset.index,
    };
    props.updateAuthorOtherRole(data);
  };

  const handleAddStrategyAuthor = (e) => {
    e.preventDefault();
    dirtyPage();
    props.showAddAuthorBlock();
  };

  const handleActivateLearningDomains = (e) => {
    e.preventDefault();
    props.activateDomainMultiSelect();
  };

  const handleActivateStrategyPacks = (e) => {
    e.preventDefault();
    props.activatePackMultiSelect();
  };

  const handleFieldFocus = (field) => {
    if (field === 'description') {
      dirtyPage();
    }
    props.clearError(field);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (props.packId) {
      Router.route(
        '/bl/curation/domain/' +
          props.domainId +
          '/strategypack/' +
          props.packId +
          '/edit'
      );
    } else if (props.domainId) {
      Router.route('/bl/curation/domain/' + props.domainId + '/edit');
    } else {
      Router.route('/bl/curation/strategies');
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      title: title.value,
      tagline: tagline.value,
      type: strategyType.value,
      publishStatusId: publishType.value,
      publishStatus: props.publishOptions.find(
        (op) => op.id === parseInt(publishType.value)
      ).label,
      curationStatusId: curationType.value,
      description:
        CKEDITOR.instances[
          'outcome-story__text-wysiwyg-descriptionTextEntry-0'
        ].getData(),
      strategyId: props.strategy.id,
    };
    if (heroTitle) {
      data.heroTitle = heroTitle.value;
    }
    if (curationNotes) {
      data.curationNotes = curationNotes.value;
    }
    props.saveStrategy(data);
  };

  const heroImgTitleNode = props.strategy.lessonfile ? (
    <div className="cute-8-desktop left tc__body-copy">
      <label className="curation__label">Title</label>
      <input
        type="text"
        onBlur={dirtyPage}
        ref={(ref) => (heroTitle = ref)}
        defaultValue={props.strategy.lessonfile.title}
        className="tc__input curation__input"
      />
    </div>
  ) : null;

  let learningDomains = (
    <a onClick={handleActivateLearningDomains} className="tc__link">
      Add Learning Domains
    </a>
  );

  let strategyPacks = (
    <a onClick={handleActivateStrategyPacks} className="tc__link">
      Add Strategy Packs
    </a>
  );

  if (
    props.ui.activateDomainMultiselect ||
    props.strategy.focusAreas.length !== 0
  ) {
    learningDomains = (
      <MultiSelect
        {...props.ui.editStrategy.focusAreas}
        field="focusAreas"
        feature="editStrategy"
        minCharCountForResults={0}
        updateMultiSelect={props.updateSelect}
        getVisibleOptions={props.getVisibleOptions}
        analyticsLabel={''}
      />
    );
  }

  if (props.ui.activatePackMultiselect || props.strategy.packs.length !== 0) {
    strategyPacks = (
      <MultiSelect
        {...props.ui.editStrategy.packs}
        field="packs"
        feature="editStrategy"
        minCharCountForResults={0}
        updateMultiSelect={props.updateSelect}
        getVisibleOptions={props.getPackVisibleOptions}
        analyticsLabel={''}
      />
    );
  }

  if (props.ui.confirmDeleteImageModal.opened) {
    modalNode = (
      <ConfirmDeleteModal
        {...props.ui.confirmDeleteImageModal}
        onDelete={props.deleteHero}
      />
    );
  } else if (props.ui.editStrategy.confirmDeleteSectionModal.opened) {
    modalNode = (
      <ConfirmDeleteModal
        {...props.ui.editStrategy.confirmDeleteSectionModal}
        onDelete={props.handleDeleteStrategySection}
      />
    );
  } else if (props.ui.editStrategy.confirmDeleteResourceModal.opened) {
    modalNode = (
      <ConfirmDeleteModal
        {...props.ui.editStrategy.confirmDeleteResourceModal}
        onDelete={props.handleDeleteStrategySectionResource}
      />
    );
  }

  let authorFormNodes = null;
  if (props.strategy.authors && props.strategy.authors.length) {
    authorFormNodes = props.strategy.authors.map((author, i) => {
      let roleType = null;
      if (author.role === '') {
        roleType = 'Other';
      } else if (
        author.role === 'BetterLesson Instructional Coach' ||
        author.role === null
      ) {
        roleType = 'BetterLesson Instructional Coach';
      } else if (author.role === 'BetterLesson Coaching Participant') {
        roleType = 'BetterLesson Coaching Participant';
      } else {
        roleType = 'Other';
      }
      let otherNode = null;
      if (roleType === 'Other') {
        otherNode = (
          <input
            type="text"
            onBlur={handleUpdateOtherRole}
            data-index={i}
            placeholder="Role Title"
            defaultValue={author.role}
            className={'tc__input curation__input'}
          />
        );
      }
      return (
        <div key={'author-' + i} className="row">
          <div className="cute-12-desktop curation__edit-author-row">
            <div className="curation__edit-author-col curation__edit-author-col--wide">
              <SingleSelect
                {...props.ui.strategyAuthors[i]}
                field="strategyAuthors"
                index={i}
                placeholder="Find author name"
                minCharCountForResults={4}
                updateSingleSelect={props.updateStrategyAuthor}
                getVisibleOptions={props.getAuthorOptions}
              />
            </div>
            <div className="curation__edit-author-col">
              <select
                onChange={handleUpdateAuthorRole}
                defaultValue={roleType}
                data-index={i}
                className="tc__select curation__input"
              >
                <option value="BetterLesson Instructional Coach">
                  BetterLesson Instructional Coach
                </option>
                <option value="BetterLesson Coaching Participant">
                  BetterLesson Coaching Participant
                </option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="curation__edit-author-col">{otherNode}</div>
          </div>
        </div>
      );
    });
  }

  const authorNode = (
    <div>
      <div className="curation__label">Authors</div>
      {authorFormNodes}
    </div>
  );

  const addAuthorNode = (
    <div>
      <a onClick={handleAddStrategyAuthor} className="tc__link">
        Add Author
      </a>
    </div>
  );

  let addGradesNode = (
    <div className="row ppd-v3-curation__form-row">
      <div className="cute-12-desktop left">
        <label className="curation__label">Grades</label>
        <a onClick={props.showAddGrades} className="tc__link">
          Add Grades
        </a>
      </div>
    </div>
  );
  if (
    props.ui.addGrades ||
    (props.strategy.grades && props.strategy.grades.length)
  ) {
    addGradesNode = (
      <div className="row ppd-v3-curation__form-row">
        <div className="cute-12-desktop left">
          <label className="curation__label">Grades</label>
          <div className="curation__label-subtext">
            Leave blank to indicate All Grades
          </div>
          <MultiSelect
            {...props.ui.editStrategy.grades}
            field="grades"
            feature="editStrategy"
            minCharCountForResults={0}
            updateMultiSelect={props.updateSelect}
            getVisibleOptions={props.getVisibleOptions}
            analyticsLabel={''}
          />
        </div>
      </div>
    );
  }

  let addSubjectsNode = (
    <div className="row ppd-v3-curation__form-row">
      <div className="cute-12-desktop left">
        <label className="curation__label">Subjects</label>
        <a onClick={props.showAddSubjects} className="tc__link">
          Add Subjects
        </a>
      </div>
    </div>
  );
  if (
    props.ui.addSubjects ||
    (props.strategy.subjects && props.strategy.subjects.length)
  ) {
    addSubjectsNode = (
      <div className="row ppd-v3-curation__form-row">
        <div className="cute-12-desktop left">
          <label className="curation__label">Subjects</label>
          <div className="curation__label-subtext">
            Leave blank to indicate All Subjects
          </div>
          <MultiSelect
            {...props.ui.editStrategy.subjects}
            field="subjects"
            feature="editStrategy"
            minCharCountForResults={0}
            updateMultiSelect={props.updateSelect}
            getVisibleOptions={props.getVisibleOptions}
            analyticsLabel={''}
          />
        </div>
      </div>
    );
  }

  let addSpecialtiesNode = (
    <div className="row ppd-v3-curation__form-row">
      <div className="cute-12-desktop left">
        <label className="curation__label">Specialty Areas</label>
        <a onClick={props.showAddSpecialties} className="tc__link">
          Add Specialty Areas
        </a>
      </div>
    </div>
  );
  if (
    props.ui.addSpecialties ||
    (props.strategy.specialtyTags && props.strategy.specialtyTags.length)
  ) {
    addSpecialtiesNode = (
      <div className="row ppd-v3-curation__form-row">
        <div className="cute-12-desktop left">
          <label className="curation__label">Specialty Areas</label>
          <MultiSelect
            {...props.ui.editStrategy.specialtyTags}
            field="specialtyTags"
            feature="editStrategy"
            minCharCountForResults={0}
            updateMultiSelect={props.updateSelect}
            getVisibleOptions={props.getVisibleOptions}
            analyticsLabel={''}
          />
        </div>
      </div>
    );
  }

  let addKeyPracticesNode = (
    <div className="row ppd-v3-curation__form-row">
      <div className="cute-12-desktop left">
        <label className="curation__label">Key Instructional Practices</label>
        <a onClick={props.showAddKeyPractices} className="tc__link">
          Add Instructional Practices
        </a>
      </div>
    </div>
  );
  if (
    props.ui.addKeyPractices ||
    (props.strategy.practiceTags && props.strategy.practiceTags.length)
  ) {
    addKeyPracticesNode = (
      <div className="row ppd-v3-curation__form-row">
        <div className="cute-12-desktop left">
          <label className="curation__label">Key Instructional Practices</label>
          <MultiSelect
            {...props.ui.editStrategy.practiceTags}
            field="practiceTags"
            feature="editStrategy"
            minCharCountForResults={0}
            updateMultiSelect={props.updateSelect}
            getVisibleOptions={props.getVisibleOptions}
            analyticsLabel={''}
          />
        </div>
      </div>
    );
  }

  let addDanielsonNode = (
    <div className="row ppd-v3-curation__form-row">
      <div className="cute-12-desktop left">
        <label className="curation__label">Danielson Framework</label>
        <a onClick={props.showAddDanielson} className="tc__link">
          Add Danielson
        </a>
      </div>
    </div>
  );
  if (
    props.ui.addDanielson ||
    (props.strategy.danielsonComponents &&
      props.strategy.danielsonComponents.length)
  ) {
    addDanielsonNode = (
      <div className="row ppd-v3-curation__form-row">
        <div className="cute-12-desktop left">
          <label className="curation__label">Danielson Framework</label>
          <MultiSelect
            {...props.ui.editStrategy.danielsonComponents}
            field="danielsonComponents"
            feature="editStrategy"
            minCharCountForResults={0}
            updateMultiSelect={props.updateSelect}
            getVisibleOptions={props.getVisibleOptions}
            analyticsLabel={''}
          />
        </div>
      </div>
    );
  }

  let previewNode = null;
  if (props.strategy && !props.strategy.isNew) {
    previewNode = (
      <NavigationLink
        url={
          process.env.REACT_APP_TEACHING_URL + '/strategy/' + props.strategy.id
        }
        target="_blank"
        className="tc__link curation__preview-link"
      >
        <span className="tc-outlink curation__preview-link-icon" />
        Preview
      </NavigationLink>
    );
  }
  return (
    <div className="tc__page curation">
      <Header page="Curation" {...props} />
      {alertNode}
      {modalNode}
      <div className="row row--1200 tc__body-copy">
        <div className="cute-12-desktop ppd-v3-curation__breadcrumbs">
          <NavigationLink url="/bl/curation" className="tc__link">
            Content Curation
          </NavigationLink>
          <span className="tc-arrow ppd-v3-curation__breadcrumb-separator" />
          <NavigationLink url="/bl/curation/strategies" className="tc__link">
            Strategies & Tech Tools
          </NavigationLink>
          <span className="tc-arrow ppd-v3-curation__breadcrumb-separator" />
          {props.strategy.title ? props.strategy.title : 'New Strategy'}
        </div>
      </div>

      <div className="row row--1200">
        <StickyLeftNav
          mainPageClass="curation__main-col"
          sectionNames={[
            'Strategy Info',
            'Introduction',
            'Metadata & Tagging',
            'Learning Domains',
            'Content Blocks',
            'Curator Notes',
          ]}
        />

        <div className="cute-8-desktop cute-2-desktop-offset left cute-12-phone cute-0-phone-offset curation__main-col">
          <form className="tc__body-copy ppd-v3-curation__edit-form">
            <div id="section-0" className="row">
              <div className="cute-12-desktop">
                <span className="tc__headline-3">Strategy</span>
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">Type</div>
                <select
                  onChange={dirtyPage}
                  ref={(ref) => (strategyType = ref)}
                  defaultValue={props.strategy.type}
                  className="tc__select curation__input-half"
                >
                  <option value="strategy">Strategy</option>
                  <option value="tech_tool">Tech Tool</option>
                </select>
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">Title</div>
                <input
                  type="text"
                  onBlur={dirtyPage}
                  ref={(ref) => (title = ref)}
                  defaultValue={props.strategy.title}
                  className={'tc__input curation__input' + errorClasses.title}
                  onFocus={() => handleFieldFocus('title')}
                />
                {errorMessages.title}
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">Tagline</div>
                <textarea
                  className={'tc__input curation__input' + errorClasses.tagline}
                  ref={(ref) => (tagline = ref)}
                  onChange={taglineCount}
                  defaultValue={props.strategy.tagline}
                  onFocus={() => handleFieldFocus('tagline')}
                />
                {errorMessages.tagline}
              </div>
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">
                  {props.ui.taglineLength} of 140 characters
                </div>
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">Status</div>
                <select
                  onChange={dirtyPage}
                  ref={(ref) => (curationType = ref)}
                  defaultValue={props.strategy.curation_status_id}
                  className="tc__select curation__input-half"
                >
                  {props.curationOptions.map((publish) => {
                    return (
                      <option key={publish.id} value={publish.id}>
                        {publish.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row ppd-v3-curation__form-row">
              <div className="cute-8-desktop left">
                <div className="ppd-v3-curation__label">Visibility</div>
                <select
                  onChange={dirtyPage}
                  ref={(ref) => (publishType = ref)}
                  defaultValue={props.strategy.publish_status_id}
                  className="tc__select curation__input-half"
                >
                  {props.publishOptions.map((publish) => {
                    return (
                      <option key={publish.id} value={publish.id}>
                        {publish.label}
                      </option>
                    );
                  })}
                </select>
                {previewNode}
              </div>
            </div>
            <div id="section-1" className="cd-strategy__details row">
              <div className="cute-12-desktop tc__headline-3">Introduction</div>
              <div className="cute-12-desktop tc__body-copy">
                <div className="curation__label">Hero Image or Video</div>
                <div>Images should be at least 780x250 pixels</div>
              </div>
              <HeroImage
                multiple={false}
                tcStrategyId={props.strategy.id}
                preview={props.strategy.preview}
                setPreview={props.setPreview}
                loading={props.ui.loading}
                openConfirmDeleteImageModal={
                  props.ui.confirmDeleteImageModal.open
                }
                updateHeroUploadProgress={props.updateHeroUploadProgress}
                file={props.ui.heroFile}
                clearAlert={props.clearAlert}
                beforeUploadCallback={CurationApi.createHeroBeforeUpload}
                afterUploadCallback={props.saveHeroAfterUpload}
                uploadCallback={ResourceApi.upload}
                cleanupCallback={props.cleanupAfterHeroUploadFailure}
                errorCallback={props.setErrorAlert}
              />
              {heroImgTitleNode}

              <div className="cute-12-desktop">
                <div className="ppd-v3-curation__label">Description</div>
                <div
                  className={
                    'story__edit-entry-wysiwyg-container' +
                    errorClasses.description
                  }
                >
                  <CKEditor
                    id="descriptionTextEntry"
                    increment={0}
                    innerText={props.strategy.description}
                    className={'outcome-story__text-wysiwyg'}
                    handleFocus={() => handleFieldFocus('description')}
                    configs="/legacy-libs/configs/bl-cke-config-curation-v4.js"
                  />
                  {errorMessages.description}
                </div>
              </div>
            </div>

            <div id="section-2" className="cd-strategy__details row">
              <div className="tc__headline-3 curation__section-headline">
                Meta Data & Tagging
              </div>
              {authorNode}
              {addAuthorNode}
              {addGradesNode}
              {addSubjectsNode}
              {addSpecialtiesNode}
              {addKeyPracticesNode}
              {addDanielsonNode}
            </div>

            <div id="section-3" className="cd-strategy__details row">
              <div className="tc__headline-3 curation__section-headline">
                Learning Domains
              </div>
              <div className="row ppd-v3-curation__form-row">
                <div className="cute-12-desktop">
                  <div>{learningDomains}</div>
                </div>
              </div>
              <div className="row ppd-v3-curation__form-row">
                <div className="cute-8-desktop left">
                  <div className="admin-v2__user-page-form-item clearfix">
                    <label className="curation__label">Strategy Packs</label>
                  </div>
                  <div>{strategyPacks}</div>
                </div>
              </div>
            </div>

            <div id="section-4" className="cd-strategy__details row">
              <div className="tc__headline-3 curation__section-headline">
                Content Blocks
              </div>
              <div className="row ppd-v3-curation__form-row">
                {props.strategy.sections.map((section, i) => {
                  const editStatus =
                    props.ui.contentBlockEditId === section.id ? true : false;
                  return (
                    <ContentBlock
                      {...props}
                      key={'section-' + section.id}
                      section={section}
                      index={i}
                      edit={editStatus}
                    />
                  );
                })}
              </div>
              <div className="Row">
                <div
                  onClick={handleAddContentBlock}
                  className="bl4__btn bl4__btn--blue bl4__btn--small ppd-v3-curation__save-btn"
                >
                  Add
                </div>
              </div>
            </div>
            <CuratorNotes
              sectionId={5}
              inputRef={(el) => (curationNotes = el)}
              notes={props.strategy.curation_notes}
              showNotes={props.ui.addCuratorNotes}
              toggleAddCuratorNotes={props.toggleAddCuratorNotes}
              onBlur={dirtyPage}
            />
          </form>
        </div>
      </div>

      <StickyFooter
        submit={handleSave}
        loading={props.ui.loading}
        disabled={props.ui.contentBlockEditId ? true : false}
        hasCancelLink={true}
        cancel={handleCancel}
        cancelText="Close"
      />
    </div>
  );
};

Strategy.PropTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default Strategy;
