// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const EventRow = (props) => {
  // only show the event start/end dates if both are available
  let dates = 'TBD';
  if (props.startDate && props.endDate) {
    dates =
      props.startDate === props.endDate
        ? moment(props.startDate).format('MMMM D, Y')
        : `${moment(props.startDate).format('MMMM D, Y')} - ${moment(
            props.endDate
          ).format('MMMM D, Y')}`;
  }

  return (
    <div className="partner_lead_v5__mobile-row">
      <div className="">
        <NavigationLink
          className="v5__link v5__link--black"
          url={`/lab/leader/${props.leadId}/event/${props.id}`}
        >
          {props.display_name}
        </NavigationLink>
      </div>
      <div className="partner_lead_v5__mobile-label">Sessions</div>
      <div>
        {props.minSessionCount === props.maxSessionCount
          ? props.maxSessionCount
          : `Up to ${props.maxSessionCount}`}
      </div>
      <div className="partner_lead_v5__mobile-label">Date(s)</div>
      <div>{dates}</div>
      <div className="partner_lead_v5__mobile-label">RSVPs</div>
      <div>{props.reservation_count}</div>
      <div className="partner_lead_v5__mobile-label">Attendees</div>
      <div>{props.participant_count}</div>
    </div>
  );
};

EventRow.propTypes = {
  id: PropTypes.number.isRequired,
  display_name: PropTypes.string.isRequired,
  minSessionCount: PropTypes.number.isRequired,
  maxSessionCount: PropTypes.number.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  reservation_count: PropTypes.number.isRequired,
  participant_count: PropTypes.number.isRequired,
};

export default EventRow;
