// @ts-nocheck FIXME
import StoryApi from '@legacy-components/apis/V4/StoryApi';
import Utils from '@legacy-components/components/DynamicUtils';
import { History } from '@legacy-components/components/Router';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import { useState } from 'react';
import EntryTags from './../EntryTags';
import SelectAuthorDropdown from './../SelectAuthorDropdown';

const CustomStrategyEntryForm = (props) => {
  const [title, setTitle] = useState(props.titleInput || '');
  const [description, setDescription] = useState(props.textInput || '');
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // set the initially selected author
  const [author, setAuthor] = useState(
    props.author ? props.author.id : props.currentUser.id
  );

  const handleFocus = (field) => {
    // clear any field error
    if (errors[field]) {
      const newErrors = Utils.clone(errors);
      delete newErrors[field];
      setErrors(newErrors);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();

    if (!props.entryId) {
      // clean up the url before closing
      History.replace(null, null, `/lab/workspace/${props.userId}`);
    }

    props.close();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // first, clear any form errors
    setErrors({});

    // validation
    const formErrors = {};
    if (!title) {
      formErrors.title = 'Hold on, you need to enter a title to continue.';
    }
    if (!description) {
      formErrors.description =
        'Hold on, you need to enter a description to continue.';
    }
    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);

    // build API data
    const data = {
      storyId: props.activeStoryId,
      description: description,
      title: title,
      goals: props.currentGoals,
    };
    // if the current user is allowed to change the authorship, pass that in
    if (props.canEditAuthor && author) {
      data.authorId = author;
    }
    // otherwise, if the current user is the workspace owner,
    // make sure they are set as the author
    else if (props.currentUser.id === props.pageUser.id) {
      data.authorId = props.currentUser.id;
    }

    if (props.entryId) {
      data.storyCustomStrategyId = props.entryId;
      StoryApi.editCustomStrategyEntry(data)
        .then((response) => {
          props.editEntryCallback(response.entry);
          props.close();
        })
        .catch(() => {
          props.setErrorAlert();
          setLoading(false);
        });
    } else {
      StoryApi.addCustomStrategyEntry(data)
        .then((response) => {
          props.addEntryCallback(response.entry);
          History.replace(null, null, `/lab/workspace/${props.userId}`);
          props.close();
        })
        .catch(() => {
          props.setErrorAlert();
          setLoading(false);
        });
    }
  };

  // set up the author select, if the current user is allowed to edit it
  let authorSelectNode;
  if (props.canEditAuthor) {
    authorSelectNode = (
      <SelectAuthorDropdown
        user={props.currentUser}
        pageUser={props.pageUser}
        author={props.author}
        newAuthorId={author}
        updateAuthorId={setAuthor}
        canEditAuthor={props.canEditAuthor}
      />
    );
  }

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  // only show the delete icon when editing an entry, not when creating a new entry
  let deleteIconNode;
  if (props.entryId && props.confirmDelete) {
    deleteIconNode = (
      <span
        onClick={props.confirmDelete}
        className="tc-delete workspace__delete-icon"
      ></span>
    );
  }

  return (
    <form id="edit-custom-strategy">
      <div className="v5__heading-5 workspace__entry-header-row workspace__entry-header-row--editing">
        <div className="tc-v4-strategy workspace__entry-header-icon"></div>
        <div className="workspace__entry-header-text">
          <FormField
            type="text"
            placeholder="Give your strategy a title."
            defaultValue={props.titleInput}
            autoFocus={true}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={() => handleFocus('title')}
            error={errors.title}
          />
        </div>
      </div>

      <div className="workspace__entry-body">
        <FormField
          type="textarea"
          rows="4"
          placeholder="Describe your strategy. How will you implement it? How will you know if it works?"
          defaultValue={props.textInput}
          onChange={(e) => setDescription(e.target.value)}
          onFocus={() => handleFocus('description')}
          error={errors.description}
        />

        {authorSelectNode}

        <div className="workspace__entry-footer">
          <EntryTags
            goals={props.goals}
            selectedGoalIds={props.currentGoals}
            canEdit={!props.isEntryHighlighted}
            toggleGoal={props.toggleGoalTag}
            storyId={props.activeStoryId}
            entryId={props.entryId}
            tagGoalResponseCallback={props.tagGoalResponseCallback}
            untagGoalResponseCallback={props.untagGoalResponseCallback}
          />

          <div className="workspace__btns-row">
            <button
              type="button"
              className="v5__btn v5__btn--blue-border workspace__cancel-link"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="v5__btn"
              {...btnProps}
              onClick={handleSubmit}
            >
              <Spinner loading={isLoading} />
              Save
            </button>
            {deleteIconNode}
          </div>
        </div>
      </div>
    </form>
  );
};

export default CustomStrategyEntryForm;
