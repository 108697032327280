// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';

const EventAdditionalResources = () => {
  return (
    <div className="v5__grey-full-bg">
      <div className="row">
        <div className="event__attendee-additional-resources-headline-container cute-12-decktop">
          <h2 className="event__attendee-section-headline v5__heading-3">
            Additional Resources
          </h2>
        </div>
      </div>
      <div className="row flex-row">
        <div className="event__attendee-additional-resources-image-container cute-4-desktop cute-12-phone">
          <div className="event__attendee-additional-resources-mask">
            <img
              src="/legacy-images/v5/event-session/resources.jpeg"
              className="event__attendee-additional-resources-image"
              alt="betterlesson additional resources"
            />
          </div>
        </div>
        <div className="event__attendee-additional-resources cute-8-desktop cute-12-phone">
          <div className="event__attendee-additional-resources-headline">
            <div className="event__attendee-additional-resources-icon tc-v4-strategy-lg" />
            <h4 className="v5__heading-5">
              BetterLesson Instructional Strategies
            </h4>
          </div>
          <p className="event__attendee-additional-resources-copy v5__body-copy">
            Our Instructional Strategies provide ready-to-use resources and
            step-by-step guidance to help education professionals grow in their
            practice.
          </p>
          <p className="event__attendee-additional-resources-copy v5__body-copy">
            Browse hundreds of strategies in the BetterLesson Resource Library.
          </p>
          <NavigationLink
            url={process.env.REACT_APP_TEACHING_URL + '/search?types=strategy'}
            className="event__attendee-additional-resources-button v5__btn v5__btn--blue"
          >
            Search Strategies
          </NavigationLink>
        </div>
      </div>
    </div>
  );
};

export default EventAdditionalResources;
