// @ts-nocheck FIXME
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import ZenScroll from 'zenscroll';
import CurationApi from './../../apis/V4/CurationApi';
import Modal from './../../components/Modal';
import { History, Router } from './../../components/Router';
import Utils from './../../components/StaticUtils';
import StorageHelper from './../../components/StorageHelper';
import Store from './../../components/Store';

const store = {
  domains: [],
  domain: {},
  strategies: [],
  strategy: {},
  strategyPack: {},
  growthArea: {},
  dirtyDomain: {},
  dirtyPack: {},
  fileResource: [],
  minCharCountForResults: 3,
  ui: {
    alert: {},
    sortStrategies: {
      column: 'title',
      direction: 'ascending',
    },
    loading: false,
    errors: {},
    searchLoading: false,
    taglineLength: 0,
    addContentBlock: false,
    contentBlockEditId: null,
    addStrategyShowMenu: false,
    addResourceShowMenu: false,
    addAuthorStrategyBlock: false,
    addGrades: false,
    addSubjects: false,
    addSpecialties: false,
    addKeyPractices: false,
    addDanielson: false,
    addMasterTeachers: false,
    addCuratorNotes: false,
    addSectionAuthor: false,
    showAddLink: false,
    loadingAddLink: false,
    heroFile: null,
    uploadResourceFile: null,
    uploadResourcePreviewFiles: {},
    uploadArtifactModal: {},
    strategyAuthors: [],
    confirmDeleteImageModal: {},
    confirmPublishUnpublishModal: {},
    confirmPublishUnpublishPackModal: {},
    addExistingStrategyModal: {},
    editDomain: {
      masterTeachers: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
    },
    editStrategy: {
      isLoading: false,
      grades: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
      subjects: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
      specialtyTags: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
      practiceTags: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
      danielsonComponents: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
      focusAreas: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
      packs: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
      sectionAuthor: {
        inputStatus: 'blur',
        charCount: 0,
        selectedOption: null,
        visibleOptions: [],
      },
      warningModal: {},
      confirmDeleteSectionModal: {},
      confirmDeleteResourceModal: {},
    },
    unsavedChangesModal: {
      opened: false,
      params: {},
    },
    activateDomainMultiselect: false,
    activatePackMultiselect: false,
  },
};

/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

const resetUnsavedChangesModal = () => {
  store.ui.unsavedChangesModal.opened = false;
  store.ui.unsavedChangesModal.params = {};
};

const handleErrorMessage = (response) => {
  let errorMessage = response.error;
  if (response.errors) {
    for (const k in response.errors) {
      errorMessage = errorMessage + ' ' + k + ' ' + response.errors[k];
    }
  }
  setErrorAlert(errorMessage);
};

const scrollToError = () => {
  // scroll to the first error
  window.setTimeout(() => {
    const firstError = document.querySelector('.error');
    if (firstError) {
      ZenScroll.setup(600, 28);
      ZenScroll.to(firstError);
    }
  }, 100);
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.ui.alert = {};
  store.ui.loading = false;
  store.ui.errors = {};
  store.ui.searchLoading = false;
  store.ui.addStrategyShowMenu = false;
  store.ui.addCuratorNotes = false;
  store.ui.addMasterTeachers = false;
  store.domains = data.focusAreas;
  store.domain = data.focusArea;
  store.strategies = data.strategies;
  store.strategy = data.strategy;
  store.strategyPack = data.strategyPack;
  store.growthArea = data.growthArea;
  store.dirtyDomain = {};
  store.dirtyPack = {};

  // if a created page mark as unsaved
  if (store.strategyPack && store.strategyPack.new) {
    WindowHelpers.navigationConfirmation(true);
  }

  //initialize multiselect objects
  for (const key in store.ui.editStrategy) {
    store.ui.editStrategy[key] = {
      inputStatus: 'blur',
      charCount: 0,
      selectedOption: null,
      visibleOptions: [],
    };
  }
  store.ui.strategyAuthors = [];

  store.ui.taglineLength = 0;
  if (store.domain && store.domain.tagline && store.domain.tagline.length) {
    store.ui.taglineLength = store.domain.tagline.length;
  } else if (
    store.strategy &&
    store.strategy.tagline &&
    store.strategy.tagline.length
  ) {
    store.ui.taglineLength = store.strategy.tagline.length;
  }

  store.ui.addContentBlock = false;
  store.ui.contentBlockEditId = null;
  store.contentBlockEditId = null;

  store.ui.uploadArtifactModal = Modal(store, store.ui.uploadArtifactModal);
  store.ui.addExistingStrategyModal = Modal(
    store,
    store.ui.addExistingStrategyModal
  );
  store.ui.confirmDeleteImageModal = Modal(
    store,
    store.ui.confirmDeleteImageModal
  );
  store.ui.confirmPublishUnpublishModal = Modal(
    store,
    store.ui.confirmPublishUnpublishModal
  );
  store.ui.confirmPublishUnpublishPackModal = Modal(
    store,
    store.ui.confirmPublishUnpublishPackModal
  );
  store.ui.editStrategy.confirmDeleteSectionModal = Modal(
    store,
    store.ui.editStrategy.confirmDeleteSectionModal
  );
  store.ui.editStrategy.confirmDeleteResourceModal = Modal(
    store,
    store.ui.editStrategy.confirmDeleteResourceModal
  );

  // set up store for strategies page
  if (store.strategies) {
    const defaultSortState = { column: 'title', direction: 'ascending' };

    const localSortState = StorageHelper.init(
      'strategiesSortState',
      defaultSortState
    );

    store.ui.sortStrategies.column = localSortState.column;
    store.ui.sortStrategies.direction = localSortState.direction;

    // defaults to title so we dont do anything if it is
    if (localSortState.column === 'type') {
      // sort alphabetically
      store.strategies.sort((a, b) => (a.type < b.type ? -1 : 1));
    } else if (localSortState.column === 'modified') {
      store.strategies.sort((a, b) =>
        a.lastmodified_formatted < b.lastmodified_formatted ? -1 : 1
      );
    } else if (localSortState.column === 'status') {
      store.strategies.sort(
        (a, b) => a.curation_status.id - b.curation_status.id
      );
    }

    if (localSortState.direction === 'descending') {
      store.strategies.reverse();
    }
  }
  // set up store for curate strategy page
  else if (store.strategy) {
    const feature = store.ui.editStrategy;

    feature.grades.selectedOptions = data.strategy.grades;
    feature.grades.options = data.grades;

    feature.subjects.selectedOptions = data.strategy.subjects;
    feature.subjects.options = data.subjects;

    feature.specialtyTags.selectedOptions = data.strategy.specialtyTags;
    feature.specialtyTags.options = data.specialtyTags;

    feature.practiceTags.selectedOptions = data.strategy.practiceTags;
    feature.practiceTags.options = data.practiceTags;

    feature.danielsonComponents.selectedOptions =
      data.strategy.danielsonComponents;
    feature.danielsonComponents.options = data.danielsonComponents;

    feature.focusAreas.selectedOptions = data.strategy.focusAreas;
    feature.focusAreas.options = data.focusAreas;

    feature.packs.selectedOptions = data.strategy.packs;
    feature.packs.options = data.packs;

    if (store.strategy.authors && store.strategy.authors.length) {
      store.strategy.authors.map((author) => {
        const uiAuthor = {
          inputStatus: 'blur',
          charCount: 0,
          selectedOption: author,
          visibleOptions: [],
        };
        store.ui.strategyAuthors.push(uiAuthor);
      });
    }
  }
  // set up store for domain page
  else if (store.domain) {
    store.ui.editDomain.masterTeachers.selectedOptions = data.masterTeachers
      ? data.masterTeachers
      : [];
  }
  // don't trigger event
  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.sortStrategiesBy = (column) => {
  // if we are resorting by same column
  if (store.ui.sortStrategies.column === column) {
    if (store.ui.sortStrategies.direction === 'ascending') {
      store.ui.sortStrategies.direction = 'descending';
    } else {
      store.ui.sortStrategies.direction = 'ascending';
    }
    store.strategies.reverse();
  } else {
    // always default sort new columns ascending
    store.ui.sortStrategies.direction = 'ascending';
    store.ui.sortStrategies.column = column;
    if (column === 'title') {
      // sort alphabetically
      store.strategies.sort((a, b) => (a.title < b.title ? -1 : 1));
    } else if (column === 'type') {
      // sort alphabetically
      store.strategies.sort((a, b) => (a.type < b.type ? -1 : 1));
    } else if (column === 'modified') {
      store.strategies.sort((a, b) =>
        a.lastmodified_formatted < b.lastmodified_formatted ? -1 : 1
      );
    } else if (column === 'status') {
      store.strategies.sort(
        (a, b) => a.curation_status.id - b.curation_status.id
      );
    } else if (column === 'visible') {
      store.strategies.sort((a, b) =>
        a.publish_status < b.publish_status ? -1 : 1
      );
    }
  }

  const localSortState = {
    column: store.ui.sortStrategies.column,
    direction: store.ui.sortStrategies.direction,
  };
  StorageHelper.setItem('strategiesSortState', localSortState);

  return true;
};

store.handleDomainEditVisible = (data) => {
  store.editing = data;
  return true;
};

store.saveDomain = (data) => {
  store.ui.loading = true;

  data.masterTeachers = [];
  if (store.ui.editDomain.masterTeachers) {
    data.masterTeachers =
      store.ui.editDomain.masterTeachers.selectedOptions.map((item) => {
        return item.id;
      });
  }

  data.focusAreaId = store.domain.id;
  return CurationApi.editFocusArea(data)
    .then((response) => {
      store.domain = response.focusArea;
      store.editing = 0;
      store.dirtyDomain = {};
      store.ui.loading = false;

      // page can be safely navigated away to after a save
      WindowHelpers.navigationConfirmation(false);

      // show success alert
      store.ui.alert = {
        type: 'success',
        message: 'Successfully updated this focus area',
        handleClose: store.clearAlert,
      };

      return true;
    })
    .catch((response) => {
      store.ui.loading = false;
      handleErrorMessage(response);
    });
};

store.saveGrowthArea = (data) => {
  return CurationApi.editGrowthArea(data)
    .then((response) => {
      store.growthArea.id = response.growthArea.id;
      History.replace(
        null,
        null,
        '/bl/curation/domain/' +
          store.domain.id +
          '/growtharea/' +
          store.growthArea.id +
          '/edit'
      );
      // show success alert
      store.ui.alert = {
        type: 'success',
        message: 'Successfully saved outcome',
        handleClose: store.clearAlert,
      };
      WindowHelpers.navigationConfirmation(false);
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

store.addExistingStrategyToFocusArea = (data) => {
  store.ui.addExistingStrategyModal.loading = true;

  data.focusAreaId = store.domain.id;
  return CurationApi.addExistingStrategyToFocusArea(data)
    .then(() => {
      store.ui.addExistingStrategyModal.close();
      store.ui.addExistingStrategyModal.loading = false;
      Router.route(
        '/bl/curation/domain/' +
          store.domain.id +
          '/strategy/' +
          data.strategyId +
          '/edit'
      );
    })
    .catch((response) => {
      store.ui.addExistingStrategyModal.loading = false;
      handleErrorMessage(response);
    });
};

store.addExistingStrategyToPack = (data) => {
  store.ui.addExistingStrategyModal.loading = true;

  data.packId = store.strategyPack.id;
  data.focusAreaId = store.strategyPack.focus_area_id;
  return CurationApi.addExistingStrategyToStrategyPack(data)
    .then(() => {
      store.ui.addExistingStrategyModal.close();
      store.ui.addExistingStrategyModal.loading = false;
      Router.route(
        '/bl/curation/domain/' +
          data.focusAreaId +
          '/pack/' +
          data.packId +
          '/strategy/' +
          data.strategyId +
          '/edit'
      );
    })
    .catch((response) => {
      store.ui.addExistingStrategyModal.loading = false;
      handleErrorMessage(response);
    });
};

store.saveStrategyPack = (data) => {
  data.dirtyPack = store.dirtyPack;
  if (store.dirtyPack.sortStrategies) {
    data.dirtyPack.strategiesOrder = [];
    for (let i = 0; i < store.strategyPack.strategies.length; i++) {
      data.dirtyPack.strategiesOrder.push(store.strategyPack.strategies[i].id);
    }
  }

  return CurationApi.editStrategyPack(data)
    .then((response) => {
      if (store.strategyPack.new) {
        // update history
        const updatedUrl =
          '/bl/curation/domain/' +
          store.strategyPack.focus_area_id +
          '/strategypack/' +
          store.strategyPack.id +
          '/edit';
        History.replace(null, document.title, updatedUrl);
      }
      WindowHelpers.navigationConfirmation(false);
      store.strategyPack = response.strategyPack;
      store.dirtyDomain = {};
      // show success alert
      store.ui.alert = {
        type: 'success',
        message: 'Successfully saved strategy pack',
        handleClose: store.clearAlert,
      };
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

store.saveStrategy = (data) => {
  // if trying to publish this strategy, validate required fields
  if (data.publishStatus !== 'Draft') {
    const errors = {};

    if (!data.title) {
      errors.title = 'Please enter a title';
    }
    if (!data.tagline) {
      errors.tagline = 'Please enter a tagline';
    }
    if (!data.description) {
      errors.description = 'Please enter a description';
    }

    if (Object.keys(errors).length) {
      store.ui.errors = errors;
      scrollToError();
      return;
    }
  }

  data.authors = [];
  if (store.strategy.authors) {
    store.strategy.authors.map((author) => {
      if (author.id == null) {
        return;
      }
      data.authors.push({
        id: author.id,
        role: author.role,
      });
    });
  }
  data.grades = [];
  if (store.ui.editStrategy.grades) {
    data.grades = store.ui.editStrategy.grades.selectedOptions.map((item) => {
      return item.id;
    });
  }
  data.subjects = [];
  if (store.ui.editStrategy.subjects) {
    data.subjects = store.ui.editStrategy.subjects.selectedOptions.map(
      (item) => {
        return item.id;
      }
    );
  }
  data.specialtyTags = [];
  if (store.ui.editStrategy.specialtyTags) {
    data.specialtyTags =
      store.ui.editStrategy.specialtyTags.selectedOptions.map((item) => {
        return item.id;
      });
  }
  data.practiceTags = [];
  if (store.ui.editStrategy.practiceTags) {
    data.practiceTags = store.ui.editStrategy.practiceTags.selectedOptions.map(
      (item) => {
        return item.id;
      }
    );
  }
  data.danielsonComponents = [];
  if (store.ui.editStrategy.danielsonComponents) {
    data.danielsonComponents =
      store.ui.editStrategy.danielsonComponents.selectedOptions.map((item) => {
        return item.id;
      });
  }
  data.focusAreas = [];
  if (store.ui.editStrategy.focusAreas) {
    data.focusAreas = store.ui.editStrategy.focusAreas.selectedOptions.map(
      (item) => {
        return item.id;
      }
    );
  }
  data.packs = [];
  if (store.ui.editStrategy.packs) {
    data.packs = store.ui.editStrategy.packs.selectedOptions.map((item) => {
      return item.id;
    });
  }

  return CurationApi.editStrategy(data)
    .then((response) => {
      // show success alert
      store.ui.alert = {
        type: 'success',
        message: 'Successfully updated this strategy',
        handleClose: store.clearAlert,
      };
      store.strategy = response.strategy;
      const feature = store.ui.editStrategy;

      feature.grades.selectedOptions = store.strategy.grades;
      feature.subjects.selectedOptions = store.strategy.subjects;
      feature.specialtyTags.selectedOptions = store.strategy.specialtyTags;
      feature.practiceTags.selectedOptions = store.strategy.practiceTags;
      feature.danielsonComponents.selectedOptions =
        store.strategy.danielsonComponents;
      feature.focusAreas.selectedOptions = store.strategy.focusAreas;
      feature.packs.selectedOptions = store.strategy.packs;
      store.ui.strategyAuthors = [];
      if (store.strategy.authors && store.strategy.authors.length) {
        store.strategy.authors.map((author) => {
          const uiAuthor = {
            inputStatus: 'blur',
            charCount: 0,
            selectedOption: author,
            visibleOptions: [],
          };
          store.ui.strategyAuthors.push(uiAuthor);
        });
      }
      // page can be safely navigated away to after a save
      WindowHelpers.navigationConfirmation(false);
      History.replace(
        null,
        null,
        '/bl/curation/strategy/' + store.strategy.id + '/edit'
      );
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

store.handleDomainAdd = (data) => {
  store.clearAlert();

  return CurationApi.addFocusArea(data)
    .then((response) => {
      store.domains = response.focusAreas;
      store.editing = 0;
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

//saves an edit to domain title
store.handleDomainEdit = (data) => {
  store.clearAlert();
  data['focusAreaId'] = data.id;

  return CurationApi.editFocusArea(data)
    .then((response) => {
      store.domains = response.focusAreas;
      store.editing = 0;
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

store.handleDelete = (data) => {
  store.clearAlert();

  const forApi = { focusAreaId: data.selectedDomainId };

  return CurationApi.deleteFocusArea(forApi)
    .then((response) => {
      store.editing = 0;
      store.domains = response.focusAreas;
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

store.handleDeleteGrowthAreaFromDomain = (data) => {
  store.clearAlert();
  // mark page as having unsaved changes
  WindowHelpers.navigationConfirmation(true);
  const growthAreaIndex = Utils.getItemIndexFromArrayOfObjects(
    store.domain.growthAreas,
    'id',
    data.selectedGrowthAreaId
  );

  store.domain.growthAreas.splice(growthAreaIndex, 1);

  if (!store.dirtyDomain.deleteGrowthAreas) {
    store.dirtyDomain.deleteGrowthAreas = [];
  }
  store.dirtyDomain.deleteGrowthAreas.push(data.selectedGrowthAreaId);
  store.editing = 0;
  return true;
};

store.handleDeleteStrategyPackFromDomain = (data) => {
  WindowHelpers.navigationConfirmation(true);
  store.clearAlert();
  // mark page as having unsaved changes
  WindowHelpers.navigationConfirmation(true);
  const strategyPackIndex = Utils.getItemIndexFromArrayOfObjects(
    store.domain.strategyPacks,
    'id',
    data.selectedStrategyPackId
  );

  store.domain.strategyPacks.splice(strategyPackIndex, 1);

  if (!store.dirtyDomain.deleteStrategyPacks) {
    store.dirtyDomain.deleteStrategyPacks = [];
  }
  store.dirtyDomain.deleteStrategyPacks.push(data.selectedStrategyPackId);
  store.editing = 0;
  return true;
};

store.handleDeleteStrategyFromDomain = (data) => {
  store.clearAlert();
  // mark page as having unsaved changes
  WindowHelpers.navigationConfirmation(true);
  const strategyIndex = Utils.getItemIndexFromArrayOfObjects(
    store.domain.strategies,
    'id',
    data.selectedStrategyId
  );

  store.domain.strategies.splice(strategyIndex, 1);

  if (!store.dirtyDomain.deleteStrategies) {
    store.dirtyDomain.deleteStrategies = [];
  }
  store.dirtyDomain.deleteStrategies.push(data.selectedStrategyId);
  store.editing = 0;
  return true;
};

store.handleDeleteStrategyFromPack = (data) => {
  store.clearAlert();
  // mark page as having unsaved changes
  WindowHelpers.navigationConfirmation(true);
  const strategyIndex = Utils.getItemIndexFromArrayOfObjects(
    store.strategyPack.strategies,
    'id',
    data.selectedStrategyId
  );

  store.strategyPack.strategies.splice(strategyIndex, 1);

  if (!store.dirtyPack.deleteStrategies) {
    store.dirtyPack.deleteStrategies = [];
  }
  store.dirtyPack.deleteStrategies.push(data.selectedStrategyId);
  store.editing = 0;
  return true;
};

store.handleDeleteStrategy = (data) => {
  store.clearAlert();
  const forApi = { strategyId: data.selectedDomainId };
  return CurationApi.deleteStrategy(forApi)
    .then(() => {
      const strategyIndex = Utils.getItemIndexFromArrayOfObjects(
        store.strategies,
        'id',
        forApi.strategyId
      );
      store.strategies.splice(strategyIndex, 1);
      store.editing = 0;
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

store.handleDeleteStrategySection = (data) => {
  store.clearAlert();
  store.ui.editStrategy.confirmDeleteSectionModal.loading = true;

  return CurationApi.deleteSection({
    sectionId: data.sectionId,
  })
    .then(() => {
      store.ui.contentBlockEditId = null;
      store.ui.editStrategy.confirmDeleteSectionModal.loading = false;
      store.ui.editStrategy.confirmDeleteSectionModal.close();
      store.strategy.sections.splice(data.index, 1);
    })
    .catch((response) => {
      store.ui.editStrategy.confirmDeleteSectionModal.loading = false;
      store.ui.editStrategy.confirmDeleteSectionModal.close();
      handleErrorMessage(response);
    });
};

store.handleDeleteStrategySectionResource = (data) => {
  store.clearAlert();
  store.ui.editStrategy.confirmDeleteResourceModal.loading = true;

  return CurationApi.deleteResource({
    strategyId: store.strategy.id,
    resourceId: data.resourceId,
  })
    .then(() => {
      store.ui.editStrategy.confirmDeleteResourceModal.loading = false;
      store.ui.editStrategy.confirmDeleteResourceModal.close();
      store.strategy.sections[data.sectionIndex].resources.splice(
        data.resourceIndex,
        1
      );
    })
    .catch((response) => {
      store.ui.editStrategy.confirmDeleteResourceModal.loading = false;
      store.ui.editStrategy.confirmDeleteResourceModal.close();
      handleErrorMessage(response);
    });
};

store.expandAll = () => {
  const localCollapsedState = {};
  for (let i = 0; i < store.domains.length; i++) {
    store.domains[i].childrenCollapsed = false;
    localCollapsedState[[store.domains[i].id]] = false;
    for (let j = 0; j < store.domains[i].outcomes.length; j++) {
      store.domains[i].outcomes[j].childrenCollapsed = false;
      localCollapsedState[
        [store.domains[i].id, store.domains[i].outcomes[j].id]
      ] = false;
    }
  }

  StorageHelper.setItem('domainsCollapsedState', localCollapsedState);

  store.ui.allExpanded = true;
  store.ui.allCollapsed = false;
  return true;
};

store.collapseAll = () => {
  const localCollapsedState = {};
  for (let i = 0; i < store.domains.length; i++) {
    store.domains[i].childrenCollapsed = true;
    localCollapsedState[[store.domains[i].id]] = true;
    for (let j = 0; j < store.domains[i].outcomes.length; j++) {
      store.domains[i].outcomes[j].childrenCollapsed = true;
      localCollapsedState[
        [store.domains[i].id, store.domains[i].outcomes[j].id]
      ] = true;
    }
  }

  StorageHelper.setItem('domainsCollapsedState', localCollapsedState);

  store.ui.allExpanded = false;
  store.ui.allCollapsed = true;
  return true;
};

store.updateTaglineLength = (newLength) => {
  store.ui.taglineLength = newLength;
  return true;
};

store.clearError = (field) => {
  if (field in store.ui.errors) {
    delete store.ui.errors[field];
  }
};

/***** Single Select and Multi Select dependencies ****/
store.updateSelect = (data) => {
  WindowHelpers.navigationConfirmation(true);
  Object.assign(store.ui[data.feature][data.field], data.data);
};

store.getVisibleOptions = (data) => {
  const field = store.ui[data.feature][data.field];
  field.charCount = data.input.length;

  // only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    return option.label.toLowerCase().indexOf(data.input.toLowerCase()) !== -1;
  });
};

store.getPackVisibleOptions = (data) => {
  const field = store.ui[data.feature][data.field];
  field.charCount = data.input.length;
  // only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    // dont show packs that arent attached to selected domains
    if (
      Utils.getItemIndexFromArrayOfObjects(
        store.ui.editStrategy.focusAreas.selectedOptions,
        'id',
        option.focus_area_id
      ) === -1
    ) {
      return;
    }
    return option.label.toLowerCase().indexOf(data.input.toLowerCase()) !== -1;
  });
};

store.handlePublishUnpublish = (feature, field, index, newValue) => {
  if (newValue) {
    store[feature][field][index] = newValue;
  }
};

store.sortDomains = (data) => {
  store.domains = Utils.sortArrayOfObjectsByProperty(
    store.domains,
    data.domains.map((id) => parseInt(id))
  );
  return CurationApi.sortFocusAreas(data)
    .then((response) => {
      store.domains = response.focusAreas;
      store.editing = 0;
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

store.sortGrowthAreas = (data) => {
  store.domain.growthAreas = Utils.sortArrayOfObjectsByProperty(
    store.domain.growthAreas,
    data.growthAreas.map((id) => parseInt(id))
  );
  store.dirtyDomain.sortGrowthAreas = true;
  return true;
};

store.sortStrategyPacks = (data) => {
  WindowHelpers.navigationConfirmation(true);
  store.domain.strategyPacks = Utils.sortArrayOfObjectsByProperty(
    store.domain.strategyPacks,
    data.strategyPacks.map((id) => parseInt(id))
  );
  store.dirtyDomain.sortStrategyPacks = true;
  return true;
};

store.sortStrategies = (data) => {
  store.domain.strategies = Utils.sortArrayOfObjectsByProperty(
    store.domain.strategies,
    data.strategies.map((id) => parseInt(id))
  );
  store.dirtyDomain.sortStrategies = true;
  return true;
};

store.sortStrategyPackStrategies = (data) => {
  store.strategyPack.strategies = Utils.sortArrayOfObjectsByProperty(
    store.strategyPack.strategies,
    data.strategies.map((id) => parseInt(id))
  );
  WindowHelpers.navigationConfirmation(true);
  store.dirtyPack.sortStrategies = true;
  return true;
};
store.updateGrowthAreaPublish = (data) => {
  WindowHelpers.navigationConfirmation(true);
  store.ui.confirmPublishUnpublishModal.close();
  store.growthArea.publish_status_id = data.publish_status_id;
  return true;
};

store.updateStrategyPackPublish = (data) => {
  WindowHelpers.navigationConfirmation(true);
  store.ui.confirmPublishUnpublishModal.close();
  store.strategyPack.publish_status_id = data.publish_status_id;
  return true;
};

store.domainUpdateGrowthArea = (data) => {
  WindowHelpers.navigationConfirmation(true);
  store.ui.confirmPublishUnpublishModal.close();
  if (!store.dirtyDomain.growthAreas) {
    store.dirtyDomain.growthAreas = [];
  }

  let found = 0;
  for (let i = 0; i < store.dirtyDomain.growthAreas.length; i++) {
    if (store.dirtyDomain.growthAreas[i].id === data.id) {
      store.dirtyDomain.growthAreas[i].publish_status_id =
        data.publish_status_id;
      found = 1;
    }
  }
  if (!found) {
    store.dirtyDomain.growthAreas.push(data);
  }
  const growthAreaIndex = Utils.getItemIndexFromArrayOfObjects(
    store.domain.growthAreas,
    'id',
    data.id
  );
  store.domain.growthAreas[growthAreaIndex].publish_status_id =
    data.publish_status_id;
  return true;
};

store.domainUpdateStrategyPack = (data) => {
  WindowHelpers.navigationConfirmation(true);
  store.ui.confirmPublishUnpublishPackModal.close();
  if (!store.dirtyDomain.strategyPacks) {
    store.dirtyDomain.strategyPacks = [];
  }

  let found = 0;
  for (let i = 0; i < store.dirtyDomain.strategyPacks.length; i++) {
    if (store.dirtyDomain.strategyPacks[i].id === data.id) {
      store.dirtyDomain.strategyPacks[i].publish_status_id =
        data.publish_status_id;
      found = 1;
    }
  }
  if (!found) {
    store.dirtyDomain.strategyPacks.push(data);
  }
  const strategyPackIndex = Utils.getItemIndexFromArrayOfObjects(
    store.domain.strategyPacks,
    'id',
    data.id
  );
  store.domain.strategyPacks[strategyPackIndex].publish_status_id =
    data.publish_status_id;
  return true;
};

store.addContentBlock = () => {
  const data = { strategyId: store.strategy.id };
  return CurationApi.addSection(data)
    .then((response) => {
      store.strategy.sections.push({
        id: response.sectionId,
        resources: [],
      });
      store.ui.contentBlockEditId = response.sectionId;
      store.ui.addContentBlock = true;
      store.ui.addSectionAuthor = false;
      store.ui.sectionAuthor = {
        inputStatus: 'blur',
        charCount: 0,
        selectedOption: null,
        visibleOptions: [],
      };
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

store.editContentBlock = (data) => {
  const sectionIndex = Utils.getItemIndexFromArrayOfObjects(
    store.strategy.sections,
    'id',
    data.sectionId
  );
  store.ui.sectionAuthor = {
    inputStatus: 'blur',
    charCount: 0,
    selectedOption: null,
    visibleOptions: [],
  };
  if (store.strategy.sections[sectionIndex].author) {
    store.ui.sectionAuthor = {
      inputStatus: 'blur',
      charCount: 0,
      selectedOption: store.strategy.sections[sectionIndex].author,
      visibleOptions: [],
    };
  }

  // if we are just closing the form, reset the UI
  if (!data.sectionId) {
    store.ui.contentBlockEditId = null;
    store.ui.showAddLink = true;
    return;
  }

  // otherwise, prepare UI for editing the section
  store.ui.contentBlockEditId = data.sectionId;
  store.ui.showAddLink = false;
  if (data.author) {
    store.ui.addSectionAuthor = true;
    store.ui.editStrategy.sectionAuthor.selectedOption = data.author;
  }
};

store.closeOpenedContentBlock = () => {
  const data = {
    strategyId: store.strategy.id,
  };
  return CurationApi.getSections(data)
    .then((response) => {
      if (response && response.sections) {
        store.strategy.sections = response.sections;
      }
      store.editing = 0;
      store.ui.addContentBlock = false;
      store.ui.contentBlockEditId = null;
      store.ui.addSectionAuthor = false;
      store.ui.loading = false;
      return true;
    })
    .catch(() => {
      store.editing = 0;
      store.ui.addContentBlock = false;
      store.ui.contentBlockEditId = null;
      store.ui.addSectionAuthor = false;
      store.ui.loading = false;
    });
};

store.activatePackMultiSelect = () => {
  store.ui.activatePackMultiselect = true;
};

store.activateDomainMultiSelect = () => {
  store.ui.activateDomainMultiselect = true;
};

store.moveContentBlock = (data) => {
  const sectionIndex = Utils.getItemIndexFromArrayOfObjects(
    store.strategy.sections,
    'id',
    data.sectionId
  );

  store.strategy.sections[sectionIndex].description = data.description;

  if (
    data.direction === 'down' &&
    store.strategy.sections.length > sectionIndex + 1
  ) {
    const item = store.strategy.sections.splice(sectionIndex, 1)[0];
    store.strategy.sections.splice(sectionIndex + 1, 0, item);
  } else if (data.direction === 'up' && sectionIndex - 1 >= 0) {
    const item = store.strategy.sections.splice(sectionIndex, 1)[0];
    store.strategy.sections.splice(sectionIndex - 1, 0, item);
  }
};

store.saveContentBlock = (data) => {
  data.sortContentBlocks = [];
  for (let i = 0; i < store.strategy.sections.length; i++) {
    data.sortContentBlocks.push(store.strategy.sections[i].id);
  }

  const sectionIndex = Utils.getItemIndexFromArrayOfObjects(
    store.strategy.sections,
    'id',
    data.sectionId
  );
  data.authorId = null;
  data.strategyId = store.strategy.id;
  data.resources = store.strategy.sections[sectionIndex].resources.map(
    (item, i) => {
      return {
        id: item.id,
        num: i,
        title: item.title,
        description: item.description,
      };
    }
  );

  if (store.ui.editStrategy.sectionAuthor.selectedOption) {
    data.authorId = store.ui.editStrategy.sectionAuthor.selectedOption.id;
    data.authorRole = store.strategy.sections[sectionIndex].author.role;
  }

  store.ui.loading = true;

  return CurationApi.editSection(data)
    .then((response) => {
      store.strategy.sections = response.sections;
      store.editing = 0;
      store.ui.addContentBlock = false;
      store.ui.contentBlockEditId = null;
      store.ui.addSectionAuthor = false;
      store.ui.loading = false;
      return true;
    })
    .catch((response) => {
      store.ui.loading = false;
      handleErrorMessage(response);
    });
};

store.updateHeroUploadProgress = (file) => {
  store.ui.heroFile = file;
};

store.updateResourceUploadProgress = (file) => {
  store.ui.uploadResourceFile = file;
};

store.updateResourcePreviewUploadProgress = (data) => {
  store.ui.uploadResourcePreviewFiles[data.id] = data.file;
};

store.setPreview = (preview) => {
  store.strategy.preview = preview;
};

store.createHeroBeforeUpload = (data) => {
  store.ui.loading = true;
  return CurationApi.createHeroBeforeUpload(data)
    .then((response) => {
      store.strategy.lessonfile_id = response.lessonfileId;
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      store.ui.loading = false;
      handleErrorMessage(response);
    });
};

store.saveHeroAfterUpload = (data) => {
  store.ui.loading = true;
  return CurationApi.saveHeroAfterUpload(data)
    .then((response) => {
      store.strategy.lessonfile = {
        title: response.title,
      };
      store.strategy.preview = response.preview;
      store.ui.loading = false;
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      store.ui.loading = false;
      handleErrorMessage(response);
    });
};

store.saveResourceAfterUpload = (data) => {
  return CurationApi.saveResourceAfterUpload(data)
    .then((response) => {
      const sectionIndex = Utils.getItemIndexFromArrayOfObjects(
        store.strategy.sections,
        'id',
        data.sectionId
      );

      store.strategy.sections[sectionIndex].resources.push(response.resource);
      return true;
    })
    .catch((response) => {
      store.ui.btnDisabled = false;
      handleErrorMessage(response);
    });
};

store.saveUpdatedPreviewAfterUpload = (data) => {
  return CurationApi.saveUpdatedPreviewAfterUpload(data)
    .then((response) => {
      const sectionIndex = Utils.getItemIndexFromArrayOfObjects(
        store.strategy.sections,
        'id',
        data.sectionId
      );

      const resource = store.strategy.sections[sectionIndex].resources.find(
        (resource) => {
          return resource.id === data.resourceId;
        }
      );
      resource.preview_updated = response.preview_updated;
      resource.preview_updated_formatted = response.preview_updated_formatted;

      return true;
    })
    .catch((response) => {
      handleErrorMessage(response);
    });
};

store.revertResourcePreview = (data) => {
  return CurationApi.revertResourcePreview(data)
    .then(() => {
      const sectionIndex = Utils.getItemIndexFromArrayOfObjects(
        store.strategy.sections,
        'id',
        data.sectionId
      );

      const resource = store.strategy.sections[sectionIndex].resources.find(
        (resource) => {
          return resource.id === data.resourceId;
        }
      );
      resource.preview_updated = null;
      resource.preview_updated_formatted = null;

      return true;
    })
    .catch((response) => {
      handleErrorMessage(response);
    });
};

// Will be used to delete the image if an upload fails
store.cleanupAfterHeroUploadFailure = () => {
  const data = {
    tcStrategyId: store.strategy.id,
  };
  return CurationApi.deleteHero(data)
    .then(() => {
      delete store.strategy.preview;
      store.strategy.lessonfile_id = null;
      store.ui.confirmDeleteImageModal.close();
      return true;
    })
    .catch((response) => {
      handleErrorMessage(response);
    });
};

store.deleteHero = () => {
  const data = {
    tcStrategyId: store.strategy.id,
  };
  return CurationApi.deleteHero(data)
    .then(() => {
      delete store.strategy.preview;
      delete store.strategy.lessonfile;
      delete store.ui.heroFile;
      store.strategy.lessonfile_id = null;
      store.ui.confirmDeleteImageModal.close();
      return true;
    })
    .catch((response) => {
      handleErrorMessage(response);
    });
};

store.toggleAddStrategyMenu = () => {
  store.ui.addStrategyShowMenu = !store.ui.addStrategyShowMenu;
};

store.toggleAddResourceMenu = () => {
  store.ui.addResourceShowMenu = !store.ui.addResourceShowMenu;
};

store.toggleAddLink = () => {
  store.ui.addResourceShowMenu = false;
  store.ui.showAddLink = !store.ui.showAddLink;
};

store.showAddGrades = () => {
  store.ui.addGrades = true;
};

store.showAddSubjects = () => {
  store.ui.addSubjects = true;
};

store.showAddSpecialties = () => {
  store.ui.addSpecialties = true;
};

store.showAddKeyPractices = () => {
  store.ui.addKeyPractices = true;
};

store.showAddDanielson = () => {
  store.ui.addDanielson = true;
};

store.showAddSectionAuthor = (sectionIndex) => {
  store.ui.addSectionAuthor = true;
  store.strategy.sections[sectionIndex].author = {};
  store.ui.editStrategy.sectionAuthor = {
    inputStatus: 'blur',
    charCount: 0,
    selectedOption: null,
    visibleOptions: [],
  };
};

store.showAddMasterTeachers = () => {
  store.ui.addMasterTeachers = true;
};

store.toggleAddCuratorNotes = () => {
  store.ui.addCuratorNotes = !store.ui.addCuratorNotes;
};

store.showAddAuthorBlock = () => {
  const uiAuthor = {
    inputStatus: 'blur',
    charCount: 0,
    selectedOption: null,
    visibleOptions: [],
  };
  const author = {
    id: null,
    label: '',
    role: null,
    photo: null,
  };
  store.ui.strategyAuthors.push(uiAuthor);
  if (!store.strategy.authors) {
    store.strategy.authors = [];
  }
  store.strategy.authors.push(author);
};

store.getAuthorOptions = (data) => {
  store.ui[data.field][data.index].charCount = data.input.length;
  return CurationApi.getCreatorsSearch({ searchText: data.input })
    .then((response) => {
      store.ui[data.field][data.index].visibleOptions = response.result;
    })
    .catch((response) => {
      handleErrorMessage(response);
    });
};

store.getMasterTeacherOptions = (data) => {
  store.ui.editDomain.masterTeachers.charCount = data.input.length;
  return CurationApi.getCreatorsSearch({
    searchText: data.input,
  })
    .then((response) => {
      store.ui.editDomain.masterTeachers.visibleOptions = response.result;
    })
    .catch((response) => {
      handleErrorMessage(response);
    });
};

store.getSectionAuthorOptions = (data) => {
  store.ui.editStrategy.sectionAuthor.charCount = data.input.length;
  return CurationApi.getCreatorsSearch(data.input)
    .then((response) => {
      store.ui.editStrategy.sectionAuthor.visibleOptions = response.result;
    })
    .catch((response) => {
      handleErrorMessage(response);
    });
};

store.updateAuthorRole = (data) => {
  store.strategy.authors[data.index].role = data.role;
  if (data.role === 'Other') {
    store.strategy.authors[data.index].role = '';
  }
  return true;
};

store.updateAuthorOtherRole = (data) => {
  store.strategy.authors[data.index].role = data.role;
  return true;
};

store.updateSectionAuthorRole = (data) => {
  if (store.strategy.sections[data.index].author === undefined) {
    store.strategy.sections[data.index].author = {};
  }
  store.strategy.sections[data.index].author.role = data.role;
  if (data.role === 'Other') {
    store.strategy.sections[data.index].author.role = '';
  }
  return true;
};

store.addLinkResource = (data) => {
  store.ui.loadingAddLink = true;

  return CurationApi.addLinkResource(data)
    .then((response) => {
      const sectionIndex = Utils.getItemIndexFromArrayOfObjects(
        store.strategy.sections,
        'id',
        data.sectionId
      );

      store.strategy.sections[sectionIndex].resources.push(response.resource);

      store.ui.showAddLink = false;
      store.ui.loadingAddLink = false;
      return true;
    })
    .catch((response) => {
      store.ui.loadingAddLink = false;
      handleErrorMessage(response);
    });
};

store.addFileResource = (data) => {
  store.fileResource = data;
};

store.updateStrategyAuthor = (data) => {
  WindowHelpers.navigationConfirmation(true);
  if (!data.field) {
    return false;
  }

  if (data.index !== undefined) {
    Object.assign(store.ui[data.field][data.index], data.data);
  } else {
    Object.assign(store.ui[data.field], data.data);
  }

  if (data.data && data.data.selectedOption !== undefined) {
    store.strategy.authors[data.index].id = data.data.selectedOption
      ? data.data.selectedOption.id
      : null;
    store.strategy.authors[data.index].label = data.data.selectedOption
      ? data.data.selectedOption.label
      : '';
  }

  // set the unsaved changes alert
  WindowHelpers.navigationConfirmation(true);
  return true;
};

store.updateSectionAuthor = (data) => {
  if (!data.field) {
    return false;
  }

  Object.assign(store.ui.editStrategy[data.field], data.data);

  if (store.strategy.sections[data.index].author === undefined) {
    store.strategy.sections[data.index].author = {};
  }
  if (data.data && data.data.selectedOption !== undefined) {
    store.strategy.sections[data.index].author.id = data.data.selectedOption
      ? data.data.selectedOption.id
      : null;
    store.strategy.sections[data.index].author.label = data.data.selectedOption
      ? data.data.selectedOption.label
      : '';
  }

  // set the unsaved changes alert
  WindowHelpers.navigationConfirmation(true);
  return true;
};

/**** Unsaved changes modal *****/
store.openUnsavedChangesModal = (url) => {
  store.ui.unsavedChangesModal.opened = true;

  // Pass the URL and the handler for closing the modal as params to the modal
  store.ui.unsavedChangesModal.params = {
    url: url,
    closeModal: store.closeUnsavedChangesModal,
  };
};

store.closeUnsavedChangesModal = () => {
  resetUnsavedChangesModal();
};

store.setErrorAlert = (data) => {
  setErrorAlert(data);
};

store.sortSectionResources = (data) => {
  store.strategy.sections[data.sectionIndex].resources =
    Utils.sortArrayOfObjectsByProperty(
      store.strategy.sections[data.sectionIndex].resources,
      data.resourceIds.map((id) => parseInt(id))
    );
  return true;
};

store.updateSectionResourceField = (data) => {
  store.strategy.sections[data.sectionIndex].resources[data.resourceIndex][
    data.field
  ] = data.value;
  return true;
};

store.setSearchLoading = () => {
  store.ui.searchLoading = true;
};

store.clearSearchLoading = () => {
  store.ui.searchLoading = false;
};

store.search = (data) => {
  return CurationApi.searchStrategies({ search: data })
    .then((response) => {
      if (!response) {
        store.ui.searchLoading = false;
        return false;
      }

      store.strategies = response.strategies;

      // defaults to title so we dont do anything if it is
      if (store.ui.sortStrategies.column === 'type') {
        // sort alphabetically
        store.strategies.sort((a, b) => (a.type < b.type ? -1 : 1));
      } else if (store.ui.sortStrategies.column === 'modified') {
        store.strategies.sort((a, b) =>
          a.lastmodified_formatted < b.lastmodified_formatted ? -1 : 1
        );
      } else if (store.ui.sortStrategies.column === 'status') {
        store.strategies.sort(
          (a, b) => a.curation_status.id - b.curation_status.id
        );
      }

      if (store.ui.sortStrategies.direction === 'descending') {
        store.strategies.reverse();
      }
      store.ui.searchLoading = false;
      return true;
    })
    .catch((response) => {
      store.ui.searchLoading = false;
      handleErrorMessage(response);
    });
};

store.domainExpand = (index) => {
  store.strategies[index].expand = true;
  return true;
};

export default Store('CurationStore', store);
