// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import MatchFacilitator from './MatchFacilitator';

const MatchPastExperience = (props) => {
  const archivedCohorts = [];
  let archivedCohortsNode = (
    <div className="v5__body-copy--italic">No previous coaching</div>
  );
  if (props.archivedCoaching && !Utils.isEmpty(props.archivedCoaching)) {
    for (const key in props.archivedCoaching) {
      // only showing links for 2018 to 2020
      if (
        key !== '2018 - 2019' &&
        key !== '2019 - 2020' &&
        key !== '2020 - 2021'
      ) {
        continue;
      }

      // no highlights for years < 19
      // let link = `/profile/${props.userId}`;
      // if (key === '2019 - 2020' || key === '2020 - 2021') {
      //     // modify date to remove spaces for link
      //     const linkDate = key.replace(' - ', '-');
      //     link = `/lab/highlights/${props.userId}/year/${linkDate}`;
      // }

      // TODO: change this back once the Highlights page is updated
      // const tileNode = (
      //     <NavigationLink url={link}
      //         target="_blank"
      //         className="match-details__main-detail v5__link">
      //         {props.archivedCoaching[key].productDescription}
      //     </NavigationLink>
      // );
      const tileNode = props.archivedCoaching[key].productDescription;

      // coach info
      let coachNode = null;
      if (props.archivedCoaching[key].coach) {
        coachNode = <MatchFacilitator {...props.archivedCoaching[key].coach} />;
      }

      const dateString = Utils.handleFormatDate(
        props.archivedCoaching[key].cohortTermStart,
        props.archivedCoaching[key].cohortTermEnd
      );
      archivedCohorts.push(
        <div
          className="coach-match__archived-cohort"
          key={`archived-cohort-${key}`}
        >
          {tileNode}
          <div className="coach-match__details-metadata">{dateString}</div>
          {coachNode}
        </div>
      );
    }
    if (archivedCohorts.length) {
      archivedCohortsNode = <React.Fragment>{archivedCohorts}</React.Fragment>;
    }
  }

  let eventsNode = (
    <div className="v5__body-copy--italic">No Events Attended</div>
  );
  if (props.events && props.events.length) {
    eventsNode = props.events.map((event) => {
      // all events should now have sessions
      if (!event.sessions.length) {
        return null;
      }

      // event facilitator
      const coachIds = [];
      const coaches = [];
      event.sessions.forEach((session) => {
        session.facilitators.forEach((facilitator) => {
          // we only want to show facilitators once, they mey be in multiple sessions
          if (coachIds.includes(facilitator.id)) {
            return;
          }
          coachIds.push(facilitator.id);
          coaches.push(
            <MatchFacilitator
              key={`event-${event.id}-facilitator-${facilitator.id}`}
              {...facilitator}
            />
          );
        });
      });

      let coachesNode = null;
      if (coaches.length) {
        coachesNode = (
          <div className="coach-match__row coach-match__row--event-facilitators">
            {coaches}
          </div>
        );
      }
      let eventTime = null;

      const eventSessions = event.sessions.filter((session) => session.num > 0);
      if (eventSessions.length > 1) {
        eventSessions.sort((a, b) => a.num - b.num);
      }

      const firstSession = eventSessions[0];

      if (firstSession.session_date_formatted) {
        eventTime = moment(firstSession.session_date_formatted).format('LL');
      }

      if (eventSessions.length > 1) {
        // There is more than one session, use the start date of the first session and end date of the last session
        const lastSession = eventSessions[eventSessions.length - 1];

        eventTime = Utils.handleFormatDate(
          firstSession.session_date_formatted,
          lastSession.session_date_formatted
        );
      }

      return (
        <div key={`event-${event.id}`}>
          <NavigationLink
            target="_blank"
            className="match-details__main-detail v5__link"
            url={`/lab/event/${event.id}/preview`}
          >
            {event.display_name}
          </NavigationLink>
          <div className="coach-match__details-metadata">{eventTime}</div>
          {coachesNode}
        </div>
      );
    });
  }

  return (
    <section className="row">
      <h3 className="coach-match__headline coach-match__headline--details coach-match__headline--blue v5__heading-5">
        <i className="tc-bl-icon match-details__icon" /> BetterLesson Experience
      </h3>
      <div className="coach-match__row">
        <div className="coach-match__col--33">
          <div className="match-details__sub-headline v5__body-copy--bold">
            Coaching
          </div>
          {archivedCohortsNode}
        </div>
        <div className="coach-match__col--66">
          <div className="match-details__sub-headline v5__body-copy--bold">
            Events
          </div>
          {eventsNode}
        </div>
      </div>
    </section>
  );
};

export default MatchPastExperience;

MatchPastExperience.propTypes = {
  archivedCoaching: PropTypes.object,
  events: PropTypes.array,
  userId: PropTypes.number.isRequired,
};
