// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import React from 'react';
import EventRow from './EventRow';

const EventsTable = (props) => {
  const [sortedColumn, setSortedColumn] = React.useState('start-date');
  const [sortDirection, setSortDirection] = React.useState('asc');

  // don't show this table if there are no events
  if (!props.events.length) return null;

  // Wrapper function to sort on null values
  const sortWithNull = (func, key) => {
    return (a, b) => {
      if (a && a[key]) {
        if (b && b[key]) {
          return func(a, b);
        }
        return -1;
      } else if (b && b[key]) {
        return 1;
      } else {
        return 0;
      }
    };
  };

  const sortFunctions = {
    'event-name': sortWithNull(
      (a, b) => a.display_name.localeCompare(b.display_name),
      'display_name'
    ),
    'session-count': (a, b) => a.maxSessionCount - b.maxSessionCount,
    registered: (a, b) => a.participant_count - b.participant_count,
    dates: Utils.eventUpcomingSort,
  };

  // Sort order indication arrow
  let sortIndicator = null;

  if (sortDirection === 'asc') {
    props.events.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (sortDirection === 'desc') {
    props.events.sort(sortFunctions[sortedColumn]);
    props.events.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortOrder[sortDirection]);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };

  const eventRowNodes = props.events.map((event) => {
    return (
      <EventRow key={`event-${event.id}`} leadId={props.leadId} {...event} />
    );
  });

  return (
    <div className="admin-v5__coach-table">
      <div className="v5__heading-3">Events</div>
      <div className="admin-v5__coach-table__header cute-100">
        <div
          className="v5__registered-table-column-name cute-3-desktop"
          onClick={() => toggleSortDirection('event-name')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'event-name' ? ' active' : '')
            }
          >
            Event
          </span>
          {sortedColumn === 'event-name' ? sortIndicator : null}
        </div>

        <div
          className={'v5__registered-table-column-name cute-1-desktop'}
          onClick={() => toggleSortDirection('session-count')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'session-count' ? ' active' : '')
            }
          >
            Sessions
          </span>
          {sortedColumn === 'session-count' ? sortIndicator : null}
        </div>

        <div
          className={'v5__registered-table-column-name cute-2-desktop'}
          onClick={() => toggleSortDirection('registered')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'registered' ? ' active' : '')
            }
          >
            Registrants
          </span>
          {sortedColumn === 'registered' ? sortIndicator : null}
        </div>

        <div
          className={'v5__registered-table-column-name cute-4-desktop'}
          onClick={() => toggleSortDirection('dates')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'dates' ? ' active' : '')
            }
          >
            Dates
          </span>
          {sortedColumn === 'dates' ? sortIndicator : null}
        </div>

        <div className="v5__registered-table-column-name cute-2-desktop">
          <span className="ppd-3-coach-table__header-caption left">
            Attendees
          </span>
        </div>

        {/* There is an empty column for later use */}
      </div>

      {eventRowNodes}
    </div>
  );
};

EventsTable.propTypes = {
  events: PropTypes.array.isRequired,
};

export default EventsTable;
