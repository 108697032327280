// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import ClampLines from '@legacy-components/react/V5/Common/ClampLines';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import EntryContainer from '@legacy-components/react/V5/Highlights/modules/EntryContainer';
import PropTypes from 'prop-types';
import React from 'react';

const Highlight = (props) => {
  const [truncateState, setTruncateState] = React.useState(false);

  let viewFullHighlight = null;
  if (truncateState || (props.type === 'meeting_clip' && props.description)) {
    // TODO: change scrollTo and remove schoolYear to accompany new highlights structure (line 30 and 52)
    viewFullHighlight = (
      <NavigationLink
        url={`/lab/highlights/${props.teacher.id}?scrollToOutcomes=true&entryId=${props.id}`}
        className="tc__link"
      >
        <div className="v5__link participant-v5__meeting-view-in-workspace-text partner_lead_v5__view-full-highlight">
          View Full Highlight
          <span className="tc-v5-arrow-circle-right participant-v5__workspace-link-icon" />
        </div>
      </NavigationLink>
    );
  }
  const instituionName = props.institution ? `, ${props.institution.name}` : '';

  return (
    <div className="partner_lead_v5__highlights-card-container">
      <div className="partner_lead_v5__highlights-card-left v5__body-copy">
        <div className="partner_lead_v5__author-entry partner_lead_v5__author-highlight-entry">
          <div className="workspace__comment-photo-col partner_lead_v5__author-photo">
            <UserPhoto
              src={props.teacher.photo}
              userId={props.teacher.id}
              firstName={props.teacher.first_name}
              lastName={props.teacher.last_name}
              diameter={40}
            />
          </div>
          <div className="v5__link partner_lead_v5__teacher-info">
            <NavigationLink
              url={`/lab/highlights/${props.teacher.id}?scrollToOutcomes=true`}
              className="tc__link"
            >
              {props.teacher.first_name} {props.teacher.last_name}
              {instituionName}
            </NavigationLink>
          </div>
        </div>
        <div className="partner_lead_v5__highlights-card-left-body">
          <div className="partner_lead_v5__highlights-card-text left">
            <EntryContainer
              {...props}
              authorHide={true}
              hideIcon={true}
              smallView={true}
              toggle={false}
              setTruncateState={setTruncateState}
              showInPLDash={true}
            />
          </div>
        </div>
        {viewFullHighlight}
      </div>
      <div className="partner_lead_v5__highlights-card-right .v5__comment-copy">
        <div className="partner_lead_v5__highlights-card-text partner_lead_v5__highlights-card-reflection">
          <div className="tc-v5-left-quotes partner_lead_v5__highlights-quote-icon" />
          <ClampLines
            text={props.highlight_reflection}
            id={`clamped-entry-${props.id}`}
            buttons={false}
            lines={9}
            setTruncateState={setTruncateState}
          />
        </div>
        <div className="v5__quote-copy partner_lead_v5__quote-copy">
          &mdash; {props.highlight_author.first_name}{' '}
          {props.highlight_author.last_name}
          {props.highlight_author.first_name !== props.teacher.first_name ||
          props.highlight_author.last_name !== props.teacher.last_name
            ? ', Coach'
            : ''}
        </div>
      </div>
    </div>
  );
};

Highlight.propTypes = {
  highlight_author: PropTypes.object,
  highlight_reflection: PropTypes.string,
  teacher: PropTypes.object,
  school_year: PropTypes.string,
  institution: PropTypes.object,
  reportingPhases: PropTypes.object,
  reporting_phase: PropTypes.number,
};
export default Highlight;
