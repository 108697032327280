// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import NavigationLink from './../../NavigationLink';

const MasterTeacherProject = (props) => {
  const handleCapsuleClick = (subjectId) => {
    if (subjectId === 'blended') {
      return Router.route(
        process.env.REACT_APP_TEACHING_URL + '/browse/blended?from=mtp_intro'
      );
    }
    Router.route(
      process.env.REACT_APP_TEACHING_URL +
        '/search?from=mtp_intro&types=lesson&subjects=' +
        subjectId
    );
  };

  return (
    <div className="tc__page">
      <Header page="commonCore" {...props} />
      <div className="row row--1200">
        <div className="cute-8-desktop cute-12-phone center-desktop">
          <h1 className="mtp__header">Lesson Plans from Our Master Teachers</h1>
          <div className="mtp__body">
            The Master Teacher Project is committed to building a body of
            knowledge around authentic instructional practice. We recruit and
            select high-performing teachers across the country to share their
            rich, comprehensive lessons and effective strategies with teachers
            across the globe.
          </div>
        </div>
      </div>
      <div className="mtp__subjects row row--1200">
        <div
          className="mtp__subject cute-3-desktop cute-12-phone"
          data-testid="mtp-math-capsule"
          onClick={() => {
            handleCapsuleClick(1);
          }}
        >
          <div className="mtp__subject-container">
            <div className="mtp__subject-mask">
              <img
                src="/legacy-images/mtp-images/math-mtp-photo.jpg"
                alt="Math BetterLesson Master Teacher Project"
              />
            </div>
            <div className="mtp__subject-overlay">
              <div className="mtp__subject-icon tc-v4-math"></div>
              <div className="mtp__capsule-title">Math Lessons</div>
              <div className="mtp__capsule-spacer"></div>
              <div className="mtp__subject-teachers">
                <NavigationLink
                  url={
                    process.env.REACT_APP_TEACHING_URL +
                    '/browse/master_teachers/math/all?from=mtp_intro'
                  }
                >
                  Meet the Teachers
                </NavigationLink>
              </div>
              <div className="mtp__subject-browse">
                <NavigationLink
                  url={
                    process.env.REACT_APP_TEACHING_URL +
                    '/browse/common_core_math?from=mtp_intro'
                  }
                >
                  Browse By Standard
                </NavigationLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mtp__subject cute-3-desktop cute-12-phone"
          data-testid="mtp-ela-capsule"
          onClick={() => {
            handleCapsuleClick(4);
          }}
        >
          <div className="mtp__subject-container">
            <div className="mtp__subject-mask">
              <img
                src="/legacy-images/mtp-images/ela-mtp-photo.jpg"
                alt="ELA BetterLesson Master Teacher Project"
              />
            </div>
            <div className="mtp__subject-overlay">
              <div className="mtp__subject-icon tc-v4-ela"></div>
              <div className="mtp__capsule-title">ELA Lessons</div>
              <div className="mtp__capsule-spacer"></div>
              <div className="mtp__subject-teachers">
                <NavigationLink
                  url={
                    process.env.REACT_APP_TEACHING_URL +
                    '/browse/master_teachers/english_-_language_arts/all?from=mtp_intro'
                  }
                >
                  Meet the Teachers
                </NavigationLink>
              </div>
              <div className="mtp__subject-browse">
                <NavigationLink
                  url={
                    process.env.REACT_APP_TEACHING_URL +
                    '/browse/common_core_ela?from=mtp_intro'
                  }
                >
                  Browse By Standard
                </NavigationLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mtp__subject cute-3-desktop cute-12-phone"
          data-testid="mtp-science-capsule"
          onClick={() => {
            handleCapsuleClick(2);
          }}
        >
          <div className="mtp__subject-container">
            <div className="mtp__subject-mask">
              <img
                src="/legacy-images/mtp-images/science-mtp-photo.jpg"
                alt="Science BetterLesson Master Teacher Project"
              />
            </div>
            <div className="mtp__subject-overlay">
              <div className="mtp__subject-icon tc-v4-science"></div>
              <div className="mtp__capsule-title">Science Lessons</div>
              <div className="mtp__capsule-spacer"></div>
              <div className="mtp__subject-teachers">
                <NavigationLink
                  url={
                    process.env.REACT_APP_TEACHING_URL +
                    '/browse/master_teachers/science/all?from=mtp_intro'
                  }
                >
                  Meet the Teachers
                </NavigationLink>
              </div>
              <div className="mtp__subject-browse">
                <NavigationLink
                  url={
                    process.env.REACT_APP_TEACHING_URL +
                    '/browse/next_gen_science?from=mtp_intro'
                  }
                >
                  Browse By Standard
                </NavigationLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mtp__subject cute-3-desktop cute-12-phone"
          data-testid="mtp-blended-capsule"
          onClick={() => {
            handleCapsuleClick('blended');
          }}
        >
          <div className="mtp__subject-container">
            <div className="mtp__subject-mask">
              <img
                src="/legacy-images/mtp-images/blended-mtp-photo.jpg"
                alt="Blended BetterLesson Master Teacher Project"
              />
            </div>
            <div className="mtp__subject-overlay">
              <div className="mtp__subject-icon tc-v4-tablet"></div>
              <div className="mtp__capsule-title">Blended Videos</div>
              <div className="mtp__capsule-spacer"></div>
              <div className="mtp__subject-teachers">
                <NavigationLink
                  url={
                    process.env.REACT_APP_TEACHING_URL +
                    '/browse/master_teachers/blended?from=mtp_intro'
                  }
                >
                  Meet the Teachers
                </NavigationLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mtp__partners">
        <div className="row row--1200">
          <div className="cute-6-desktop cute-12-phone cute-2-desktop-offset phone-reset">
            <h2 className="mtp__partner-header">Our Partners</h2>
            <div className="mtp__body">
              We are deeply grateful for all of our amazing partners as we
              undertake this ambitious mission. Special thanks to The Bill and
              Melinda Gates Foundation, The National Education Association, and
              The Learning Accelerator for their extremely generous support of
              the projects.
            </div>
          </div>
          <div className="mtp__partners-sidebar cute-2-desktop cute-12-phone left">
            <img
              src="/legacy-images/v4/betterlesson-partners.png"
              alt="BetterLessons Partners"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterTeacherProject;
