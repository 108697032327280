// @ts-nocheck FIXME
import React from 'react';

class FeedbackV2 extends React.Component {
  // render comments associated with each piece of feedback
  renderCommentNode = () => {
    if (this.props.comments) {
      return this.props.comments.map(
        function (comment) {
          return (
            <div
              className="portfolio__feedback-coach-message"
              key={this.props.step + '-feedback-comment-' + comment.created}
            >
              <div className="portfolio__feedback-coach-message-note">
                <span className="portfolio__feedback-quote">&ldquo;</span>
                <span className="portfolio__feedback-comment">
                  {comment.data}
                </span>
                <span className="portfolio__feedback-quote">&rdquo;</span>
              </div>
              <div className="portfolio__feedback-coach-message-author">
                &mdash; {comment.first_name} {comment.last_name},{' '}
                {comment.is_coach ? 'BetterLesson Coach' : 'Teacher'}
              </div>
            </div>
          );
        }.bind(this)
      );
    }
  };

  render() {
    let titleNode = null;
    if (this.props.title) {
      titleNode = (
        <div className="portfolio-v2__strategy-headline">
          {this.props.title}
        </div>
      );
    }

    let questionNode = null;
    if (this.props.question) {
      questionNode = (
        <div className="portfolio-v2__feedback-question">
          {this.props.question}
        </div>
      );
    }

    let answerNode = null;
    if (this.props.answer) {
      answerNode = (
        <div className="portfolio-v2__feedback-answer">{this.props.answer}</div>
      );
    }

    const commentNode = this.renderCommentNode();

    return (
      <div className="portfolio-v2__loop-section">
        {titleNode}
        {questionNode}
        {answerNode}
        {commentNode}
      </div>
    );
  }
}

export default FeedbackV2;
