// @ts-nocheck FIXME
import Utils from '@legacy-components/components/StaticUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';
import ScaleCenterImage from './../../ScaleCenterImage';

class LoopV2 extends React.Component {
  render() {
    // show a random image for this strategy
    let imageNode = null;
    if (this.props.strategy_image_id) {
      const imageSrc =
        '/legacy-images/portfolio_strategies/' +
        this.props.strategy_image_id +
        '.jpg';
      imageNode = (
        <div className="portfolio-v2__strategy-image-container">
          <ScaleCenterImage
            src={imageSrc}
            className="portfolio-v2__strategy-image"
            alt={this.props.strategy}
          />
        </div>
      );
    }

    // set up the strategy implementation description
    let descriptionNode = null;
    if (this.props.strategy_description) {
      descriptionNode = (
        <PurifiedHTML
          className="portfolio-v2__strategy-description"
          content={this.props.strategy_description}
        />
      );
    }

    // set up the strategy type + description
    let strategyTypeNode = null;
    if (this.props.strategy_name && this.props.strategy_definition) {
      strategyTypeNode = (
        <div className="portfolio-v2__strategy-detail">
          <span className="label">Strategy Type:</span>{' '}
          <span className="portfolio-v2__strategy-name">
            {this.props.strategy_name}
          </span>{' '}
          - {this.props.strategy_definition}
        </div>
      );
    }

    // set up the strategy implementation author info
    let strategyAuthorNode = null;
    if (this.props.strategy_author) {
      strategyAuthorNode = (
        <span className="portfolio-v2__strategy-detail-item">
          <span className="label">Author:</span> Master Teacher{' '}
          {this.props.strategy_author.first_name +
            ' ' +
            this.props.strategy_author.last_name}
        </span>
      );
    }

    // set up the strategy implementation source info
    let strategySourceNode = null;
    if (this.props.strategy_source) {
      strategySourceNode = (
        <span className="portfolio-v2__strategy-detail-item">
          <span className="label">Source:</span> {this.props.strategy_source}
        </span>
      );
    }

    // set up the start + end dates for this tml loop
    let startedNode = null;
    if (this.props.started) {
      const started = Utils.formatDate(this.props.started);
      startedNode = (
        <span className="portfolio-v2__strategy-detail-item">
          <span className="portfolio__declaration">STARTED:</span>
          {started}
        </span>
      );
    }

    let completedNode = null;
    if (this.props.completed) {
      const completed = Utils.formatDate(this.props.completed);
      completedNode = (
        <span className="portfolio-v2__strategy-detail-item">
          <span className="portfolio__declaration">COMPLETED:</span>
          {completed}
        </span>
      );
    }

    return (
      <div className="portfolio-v2__strategy">
        {imageNode}
        <div className="portfolio-v2__strategy-headline">STRATEGY TRIED</div>
        <div className="portfolio-v2__strategy-title">
          {this.props.strategy}
        </div>

        <div className="portfolio-v2__strategy-headline">
          ABOUT THIS STRATEGY
        </div>
        {descriptionNode}

        {strategyTypeNode}
        <div className="portfolio-v2__strategy-detail">
          {strategyAuthorNode}
          {strategySourceNode}
        </div>

        <div className="portfolio-v2__strategy-detail portfolio-v2__strategy-dates">
          {startedNode}
          {completedNode}
        </div>
      </div>
    );
  }
}

export default LoopV2;
