// @ts-nocheck FIXME
import Request from './../components/Request';

const PartnerPortfolioToolApi = {
  createPartnerPortfolio: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/' +
        data.partnerId +
        '/portfolio/create',
      JSON.stringify(data)
    ),

  editPortfolio: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/edit',
      JSON.stringify(data)
    ),

  refreshSection: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/section/' +
        data.sectionId +
        '/refresh',
      JSON.stringify(data)
    ),

  moveSections: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/sections/move',
      JSON.stringify(data)
    ),

  moveSubjects: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/subjects/move',
      JSON.stringify(data)
    ),

  moveTeachers: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/teachers/move',
      JSON.stringify(data)
    ),

  moveEntries: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/entries/move',
      JSON.stringify(data)
    ),

  editTeacher: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/teacher/' +
        data.teacherId +
        '/edit',
      JSON.stringify(data)
    ),

  editSection: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/section/' +
        data.sectionId +
        '/edit',
      JSON.stringify(data)
    ),

  toggleSubjectVisibility: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/subject/' +
        data.subjectId +
        '/edit',
      JSON.stringify(data)
    ),

  toggleGrowthAreaVisibility: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/growth-area/' +
        data.growthAreaId +
        '/edit',
      JSON.stringify(data)
    ),

  createEntry: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/entry/create',
      JSON.stringify(data)
    ),

  editEntry: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/entry/' +
        data.entryId +
        '/edit',
      JSON.stringify(data)
    ),

  deleteEntry: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/portfolio/' +
        data.portfolioId +
        '/entry/' +
        data.entryId +
        '/delete',
      JSON.stringify(data)
    ),
};

export default PartnerPortfolioToolApi;
