// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import AbsoluteAlert from './../../Alerts/Absolute';

// substeps are separate pages that can be accessed from within the Select Strategies step
const SubstepHeader = (props) => {
  // close the substep and return to the Strategies step
  const handleBack = () => {
    props.saveSubstep(props.substep);
  };

  // Build the alert section if we have an alert:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = <AbsoluteAlert {...props.ui.alert} />;
  }

  // set up auto-save status
  let autosaveIcon = null;
  switch (props.ui.autosaveState) {
    case 'saving':
      autosaveIcon = (
        <span className="littlespinner portfolio-edit__header-autosave-icon"></span>
      );
      break;
    case 'success':
      autosaveIcon = (
        <span className="tc-check portfolio-edit__header-autosave-icon"></span>
      );
      break;
  }

  return (
    <div className="portfolio-edit__header-container">
      <div className="tc-header__wrap">
        <div className="tc-header tc-header--portfolio-edit row">
          <div
            onClick={handleBack}
            className="tc__link portfolio-edit__substep-header-navigation-link"
          >
            <span className="tc-arrow-up"></span>
            My Strategies
          </div>
          <div className="right portfolio-edit__substep-header-autosave-icon">
            {autosaveIcon}
          </div>
        </div>
      </div>
      {alertNode}
    </div>
  );
};

SubstepHeader.propTypes = {
  teacherId: PropTypes.number.isRequired,
  portfolioId: PropTypes.number.isRequired,
  ui: PropTypes.object,
  saveSubstep: PropTypes.func.isRequired,
};

export default SubstepHeader;
