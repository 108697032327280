// @ts-nocheck FIXME
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React from 'react';

const CopyToClipboard = (props) => {
  const [clipboard, setClipboard] = React.useState('');

  React.useEffect(() => {
    setClipboard(
      new Clipboard(props.targetSelector, {
        text: function (trigger) {
          return trigger.getAttribute('copytext');
        },
      })
    );

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, []);

  return null;
};

CopyToClipboard.propTypes = {
  // ex: ".classname"
  targetSelector: PropTypes.string.isRequired,
};

export default CopyToClipboard;
