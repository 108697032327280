// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../../components/ArrayHelpers';
import Video from './../../../Utilities/Video';

const About = (props) => {
  // if the teacher has multiple welcome videos, click to switch the current one in the player
  const handleChangeVideo = (index) => {
    props.setCurrentVideo(index);
  };

  // set up any welcome videos the teacher has
  let welcomeVideosNode;
  if (ArrayHelpers.hasElements(props.welcomeVideos)) {
    // get data for the video in the player
    const mainVideo = props.welcomeVideos[props.currentVideoIndex].Lessonfile;

    welcomeVideosNode = (
      <div className="mt-profile__about-videos">
        <div className="mt-profile__about-welcome-video">
          <div className="mt-profile__about-headline">
            Meet the Master Teacher
          </div>
          <div className="">
            <Video
              preview={mainVideo.content.preview}
              formats={mainVideo.content.formats}
            />
          </div>
        </div>
        <div className="mt-profile__about-video-links">
          {props.welcomeVideos.map((video, i) => {
            // videos that are not the current one are clickable
            const clickableClass =
              props.currentVideoIndex === i ? '' : ' clickable';

            return (
              <div
                key={'video-' + video.Lessonfile.id}
                className="mt-profile__about-video-link"
              >
                <div
                  onClick={() => handleChangeVideo(i)}
                  className={
                    'mt-profile__about-video-icon tc-file-video' +
                    clickableClass
                  }
                ></div>
                <div>
                  <div
                    onClick={() => handleChangeVideo(i)}
                    className={'mt-profile__about-video-title' + clickableClass}
                  >
                    {video.Lessonfile.title}
                  </div>
                  <div className="mt-profile__about-video-duration">
                    {video.Lessonfile.pretty_duration}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  let bioNode;
  if (props.user.professional_bio) {
    bioNode = (
      <div>
        <div className="mt-profile__about-sub-headline">Bio</div>
        <div className="mt-profile__about-info">
          {props.user.professional_bio}
        </div>
      </div>
    );
  }

  return (
    <div className="mt-profile__about-container cute-9-desktop cute-12-phone">
      <div className="mt-profile__about">
        {welcomeVideosNode}

        <div className="mt-profile__about-headline">
          About {props.user.first_name}
        </div>
        {bioNode}
      </div>
    </div>
  );
};

About.propTypes = {
  user: PropTypes.object.isRequired,
  welcomeVideos: PropTypes.array,
  currentVideoIndex: PropTypes.number,
  setCurrentVideo: PropTypes.func,
};

export default About;
