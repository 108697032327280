// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const PreviewLinkCapsule = (props) => {
  const hasGroups = ArrayHelpers.hasElements(props.groups);

  // set up the link to the Event Attendee preview page
  let linkNodes = (
    <>
      <div key="links-headline" className="v5__heading-4">
        Event Page
      </div>
      <NavigationLink
        url={`/lab/event/${props.id}/preview`}
        className="v5__link pl-event__preview-link-capsule-link"
      >
        Visit the Event Page
        <span className="tc-v5-arrow-circle-right pl-event__preview-link-capsule-icon"></span>
      </NavigationLink>
    </>
  );
  // if the first event session has not arrived, hide the links
  if (!props.firstSessionDate) {
    linkNodes = (
      <div className="v5__body-copy bold">
        The event page link{hasGroups ? 's' : ''} will be available once the
        event starts.
      </div>
    );
  } else if (props.firstSessionDate > moment().endOf('day')) {
    linkNodes = (
      <div className="v5__body-copy bold">
        The event page link{hasGroups ? 's' : ''} will be available on{' '}
        {props.firstSessionDate.format('MMMM D, YYYY')}.
      </div>
    );
  }
  // if the event has groups, we link to each group's page individually
  else if (hasGroups) {
    linkNodes = (
      <>
        <div key="links-headline" className="v5__heading-4">
          Event Pages by Group
        </div>
        <div className="pl-event__preview-link-capsule-grid">
          {props.groups.map((group) => {
            return (
              <div key={`group-${group.id}`}>
                <NavigationLink
                  url={`/lab/event/${props.id}/group/${group.id}/preview`}
                  className="v5__link pl-event__preview-link-capsule-link"
                >
                  <div className="pl-event__preview-link-capsule-text">
                    {group.name || `Group ${group.num}`}
                  </div>
                  <span className="tc-v5-arrow-circle-right pl-event__preview-link-capsule-icon"></span>
                </NavigationLink>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <div className="pl-event__preview-link-capsule">
      <div className="cute-4-desktop">
        <div className="v5__heading-4">Event Facilitators and Materials</div>
        <div className="v5__body-copy pl-event__preview-link-capsule-description">
          View the event facilitators, agenda, and presentation deck on the
          event page.
        </div>
      </div>
      <div className="cute-8-desktop">{linkNodes}</div>
    </div>
  );
};

PreviewLinkCapsule.propTypes = {
  id: PropTypes.number.isRequired,
  groups: PropTypes.array,
  firstSessionDate: PropTypes.object,
};

export default PreviewLinkCapsule;
