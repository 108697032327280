// @ts-nocheck FIXME
import Storage from './Storage';

const StorageHelper = {
  MAX_COOKIE_AGE: 34560000,
  getLocalStorage() {
    // Tries to get the local storage for the current window
    // and use the local storage if it fails use Storage wrapper
    let localStorage;
    try {
      localStorage = window.localStorage;
      localStorage.setItem('__', 'test');
      localStorage.removeItem('__');
    } catch (err) {
      localStorage = Storage;
    }
    return localStorage;
  },

  getSessionStorage() {
    // Tries to get the local storage for the current window
    // and use the local storage if it fails use Storage wrapper
    let sessionStorage;
    try {
      sessionStorage = window.sessionStorage;
      sessionStorage.setItem('__', 'test');
      sessionStorage.removeItem('__');
    } catch (err) {
      sessionStorage = Storage;
    }
    return sessionStorage;
  },

  // Function needed temporarily to move all data out of session storage into local storage.
  updateSessionStorageToLocalStorage(storeKey) {
    const oldValue = window.sessionStorage.getItem(storeKey);
    // if there is no value in the session storage just return
    if (oldValue === null) {
      return;
    }
    // else we migrate the value out of session storage to local storage
    window.localStorage.setItem(storeKey, oldValue);
    window.sessionStorage.removeItem(storeKey);
  },

  init(storeKey, defaultState = null) {
    // if sessionStorage is not defined, as in during server-side rendering,
    // set it to a dummy storage component
    const localStorage = this.getLocalStorage();

    if (typeof window !== 'undefined' && window.localStorage) {
      this.updateSessionStorageToLocalStorage(storeKey);
    }
    let localState;
    try {
      const stringState = localStorage.getItem(storeKey);

      if (stringState === null) {
        localState = defaultState;
      } else {
        localState = JSON.parse(stringState);
      }
    } catch (err) {
      localState = defaultState;
    }
    if (localState === null) {
      return null;
    }
    return this.setItem(storeKey, localState);
  },

  getItem(storeKey) {
    // if sessionStorage is not defined, as in during server-side rendering,
    // set it to a dummy storage component
    const localStorage = this.getLocalStorage();

    if (typeof window !== 'undefined' && window.localStorage) {
      this.updateSessionStorageToLocalStorage(storeKey);
    }
    let localState;
    try {
      const stringState = localStorage.getItem(storeKey);

      if (stringState === null) {
        localState = null;
      } else {
        localState = JSON.parse(stringState);
      }
    } catch (err) {
      localState = null;
    }
    if (localState === null) {
      return null;
    }
    return localState;
  },

  setItem(storeKey, newState) {
    // if sessionStorage is not defined, as in during server-side rendering,
    // set it to a dummy storage component
    const localStorage = this.getLocalStorage();
    const serializedState = JSON.stringify(newState);
    localStorage.setItem(storeKey, serializedState);
    return newState;
  },

  removeItem(storeKey) {
    // if sessionStorage is not defined, as in during server-side rendering,
    // set it to a dummy storage component
    const localStorage = this.getLocalStorage();
    localStorage.removeItem(storeKey);
  },

  getSessionItem(storeKey) {
    // if sessionStorage is not defined, as in during server-side rendering,
    // set it to a dummy storage component
    const sessionStorage = this.getSessionStorage();

    let sessionState;
    try {
      const stringState = sessionStorage.getItem(storeKey);

      if (stringState === null) {
        sessionState = null;
      } else {
        sessionState = JSON.parse(stringState);
      }
    } catch (err) {
      sessionState = null;
    }
    if (sessionState === null) {
      return null;
    }
    return sessionState;
  },

  setSessionItem(storeKey, newState) {
    // if sessionStorage is not defined, as in during server-side rendering,
    // set it to a dummy storage component
    const sessionStorage = this.getSessionStorage();
    const serializedState = JSON.stringify(newState);
    sessionStorage.setItem(storeKey, serializedState);
    return newState;
  },

  removeSessionItem(storeKey) {
    // if sessionStorage is not defined, as in during server-side rendering,
    // set it to a dummy storage component
    const sessionStorage = this.getSessionStorage();
    sessionStorage.removeItem(storeKey);
  },

  getCookieItem(storeKey) {
    let cookieState;
    try {
      const stringState =
        decodeURIComponent(
          document.cookie.replace(
            new RegExp(
              '(?:(?:^|.*;)\\s*' +
                encodeURIComponent(storeKey).replace(/[-.+*]/g, '\\$&') +
                '\\s*\\=\\s*([^;]*).*$)|^.*$'
            ),
            '$1'
          )
        ) || null;

      if (stringState === null) {
        cookieState = null;
      } else {
        cookieState = JSON.parse(stringState);
      }
    } catch (err) {
      cookieState = null;
    }
    if (cookieState === null) {
      return null;
    }
    return cookieState;
  },

  setCookieItem(
    storeKey,
    newState,
    { expirationHours = 0, path = '/', maxAge } = {}
  ) {
    if (typeof document === 'undefined') {
      return;
    }
    const serializedState = JSON.stringify(newState);
    // key name must be a valid key for a cookie
    if (
      !storeKey ||
      /^(?:expires|max-age|path|domain|secure)$/i.test(storeKey)
    ) {
      return false;
    }
    if (expirationHours) {
      const date = new Date();
      date.setTime(date.getTime() + expirationHours * 60 * 60 * 1000);
      expirationHours = date.toGMTString();
    }
    const maxCookieAge = maxAge ? `; max-age=${maxAge}` : '';
    document.cookie =
      encodeURIComponent(storeKey) +
      '=' +
      encodeURIComponent(serializedState) +
      '; expires=' +
      expirationHours +
      '; path=' +
      path +
      '; domain=' +
      process.env.REACT_APP_DOMAIN +
      maxCookieAge;
    return newState;
  },

  removeCookieItem(storeKey, path = '/') {
    if (typeof document === 'undefined') {
      return;
    }
    document.cookie =
      encodeURIComponent(storeKey) +
      '=' +
      encodeURIComponent(JSON.stringify(null)) +
      '; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=' +
      path +
      '; domain=' +
      process.env.REACT_APP_DOMAIN;
  },
};

export default StorageHelper;
