// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const HelpDeskLinkGateway = (props) => {
  const containerClass = props.className ? props.className : '';

  // if we need the small version, return minimal text
  if (props.smallVersion) {
    return (
      <div
        className={`event-help event-help--small v5__body-copy ${containerClass}`}
      >
        Need help?{' '}
        <a
          href="https://betterlesson.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          className="v5__link"
          rel="noreferrer"
        >
          Contact our support team.
        </a>
      </div>
    );
  }

  // currently, this component is mostly used for help with events,
  // but in some places we show a more generic message
  const descriptionNode = props.forEvent ? (
    <div className="event-help__copy">
      Get in touch with our support team. We’re available to assist you
      immediately during the event and during business hours, Mon–Fri, 8 AM - 5
      PM Eastern Time (ET).
    </div>
  ) : (
    <div className="event-help__copy">
      Need help with your BetterLesson account or attending workshops and
      coaching sessions? Get in touch with our support team.
    </div>
  );

  return (
    <div className={`event-help v5__body-copy ${containerClass}`}>
      <div className="event-help__headline v5__body-copy--bold">
        {props.headline}
      </div>
      {descriptionNode}
      <a
        href="https://betterlesson.atlassian.net/servicedesk/customer/portals"
        target="_blank"
        className="event-help__link v5__link"
        rel="noreferrer"
      >
        Contact Support
        <i className="event-help__icon tc-v5-arrow-circle-right" />
      </a>
    </div>
  );
};

export default HelpDeskLinkGateway;

HelpDeskLinkGateway.propTypes = {
  forEvent: PropTypes.bool.isRequired,
  smallVersion: PropTypes.bool,
  headline: PropTypes.string,
  className: PropTypes.string,
};

HelpDeskLinkGateway.defaultProps = {
  forEvent: true,
};
