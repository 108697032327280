// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ArtifactOverlay from './../../Common/ArtifactOverlay/ArtifactOverlay';
import Header from './../modules/Header';
import EntryCapsule from './modules/EntryCapsule';

const PortfolioPresentationOutcomeStory = (props) => {
  // show a modal if one is opened
  let modalNode = null;
  if (props.ui.artifactModal.opened) {
    modalNode = (
      <ArtifactOverlay
        {...props.ui.artifactModal}
        analyticsLabel="ppd3_participant_portfolio_presentation_UI_vision"
      />
    );
  } else if (props.ui.meetingClipPlayerModal.opened) {
    modalNode = (
      <ArtifactOverlay
        {...props.ui.meetingClipPlayerModal}
        analyticsLabel="ppd3_participant_portfolio_presentation_UI_vision"
      />
    );
  }

  // convenience var
  const name =
    props.participant.user.first_name + ' ' + props.participant.user.last_name;

  // set up list of vision artifacts
  const entriesNode =
    props.participantOutcome.entries &&
    props.participantOutcome.entries.length ? (
      <div className="row">
        <div className="cute-6-desktop center-desktop cute-12-phone p-portfolio-presn__outcome-story-bottom-section">
          {props.participantOutcome.entries.map((entry) => {
            return (
              <EntryCapsule
                key={'entry-' + entry.id}
                {...entry}
                user={props.user}
                currentCoach={props.participant.coach}
                baselineMeasurement={
                  props.participantOutcome.baselineMeasurement
                }
                outcomeId={props.participantOutcome.outcomeId}
                pins={props.pins}
                artifactModal={props.ui.artifactModal}
                meetingClipPlayerModal={props.ui.meetingClipPlayerModal}
              />
            );
          })}
        </div>
      </div>
    ) : null;

  return (
    <div className="tc__page">
      <Header
        portfolioUuid={props.portfolioUuid}
        participantName={name}
        user={props.user}
      />
      {modalNode}

      <div className="p-portfolio-presn__outcome-story-top-section">
        <div className="row">
          <div className="cute-8-desktop cute-12-phone center-desktop">
            <div className="p-portfolio-presn__outcome-story-outcome">
              <div
                className={
                  'p-portfolio-presn__home-outcome-capsule-icon tc-cd-domain-' +
                  props.participantOutcome.domainIconId
                }
              ></div>
              <div>{props.participantOutcome.outcomeTitle}</div>
            </div>
          </div>
        </div>
      </div>
      {entriesNode}
    </div>
  );
};

PortfolioPresentationOutcomeStory.propTypes = {
  portfolioUuid: PropTypes.string.isRequired,
  participant: PropTypes.object.isRequired,
  participantOutcome: PropTypes.object.isRequired,
  entries: PropTypes.array,
};

export default PortfolioPresentationOutcomeStory;
