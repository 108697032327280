// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const EventRow = (props) => {
  const [rowExpanded, setRowExpanded] = React.useState(false);

  // if the page coach is Tech Support for this session, show the laptop icon
  let techSupportIconNode;
  if (props.facilitator_role === 'TECH') {
    techSupportIconNode = (
      <span className="tc-v5-laptop coach-event__tech-icon"></span>
    );
  }

  let eventTime = null;
  let userEventTime = null;
  const eventTimeZone = props.time_zone && props.time_zone.pytz_timezone;

  const eventEndTime = props.session.session_end_time_formatted;
  const eventStartTime = props.session.session_start_time_formatted;

  if (eventTimeZone && eventEndTime && eventStartTime) {
    if (eventTimeZone) {
      eventTime = Utils.formatEventSessionTime(
        eventStartTime,
        eventEndTime,
        eventTimeZone
      );
    }
    if (eventTimeZone && eventTimeZone !== props.userTimeZone) {
      userEventTime = Utils.formatEventSessionTime(
        eventStartTime,
        eventEndTime,
        props.userTimeZone
      );
    }
  }

  let statusIcon = props.eventHasModifications
    ? 'tc-v5-event-materials-with-mods'
    : 'tc-v5-event-materials';
  if (props.tableView === 'upcoming') {
    if (props.eventHasModifications && !props.session.modifications_finalized) {
      statusIcon = 'tc-v5-event-materials-missing-mods';
    } else if (!props.session.materials_finalized) {
      statusIcon = 'tc-v5-event-materials-missing';
    }
  }

  // set up the icon that links to the Edit Event page
  let statusIconNode = (
    <NavigationLink
      url={`/bl/event/${props.id}`}
      title="Edit event materials"
      className={`coach-event__icon ${statusIcon}`}
    >
      <span className="path1"></span>
      <span className="path2"></span>
    </NavigationLink>
  );
  if (
    props.tableView === 'upcoming' &&
    props.eventHasModifications &&
    !props.session.modifications_finalized
  ) {
    // show a tooltip if the event session modifications are in progress
    statusIconNode = (
      <Tooltip
        tooltip="Modifications in progress"
        tipClass="v5__tooltip"
        offsetLeft={10}
      >
        {statusIconNode}
      </Tooltip>
    );
  }

  let expandedClass = '';
  let moreInfoClass = ' hidden';
  let moreInfoMobileClass = ' force-hide';
  let rowExpandedClass = '';
  if (rowExpanded) {
    expandedClass = ' workspace__goal-expand-arrow--expanded';
    moreInfoClass = '';
    moreInfoMobileClass = '';
    rowExpandedClass = ' admin-v5_coach-dashboard--event-row-expanded';
  }

  const attendeeEventPageLink = props.session.group_id
    ? `/lab/event/${props.id}/group/${props.session.group_id}/preview`
    : `/lab/event/${props.id}/preview`;

  const zoomLink = props.session.zoom_join_link || props.session.zoom_link;

  // list all session facilitators
  const leadFacilitator = props.session.facilitators.find(
    (f) => f.role === 'LEAD'
  );
  const coFacilitators = props.session.facilitators.filter(
    (f) => f.role === 'CO'
  );
  const techFacilitators = props.session.facilitators.filter(
    (f) => f.role === 'TECH'
  );

  // only show the Co-Facilitators section if there are facilitators to show
  let coFacilitatorsDesktopNode;
  let coFacilitatorsMobileNode;
  if (ArrayHelpers.hasElements(coFacilitators)) {
    const coFacilitatorsList = (
      <ul className="admin-v5_coach-dashboard--event-session-co-facilitators">
        {coFacilitators.map((facilitator) => {
          return (
            <li key={`facilitator-${facilitator.id}`}>
              <span className="bold">
                {`${facilitator.first_name} ${facilitator.last_name}, `}
              </span>
              <span
                copytext={facilitator.email}
                onClick={props.handleEmailCopy}
                className="coach-event__copy-link-text"
              >
                {facilitator.email}
              </span>
            </li>
          );
        })}
      </ul>
    );
    coFacilitatorsDesktopNode = (
      <React.Fragment>
        <div className="title">{`Co-Facilitator${
          coFacilitators.length > 1 ? 's' : ''
        }`}</div>
        {coFacilitatorsList}
      </React.Fragment>
    );
    coFacilitatorsMobileNode = (
      <div className="admin-v5_coach-dashboard--event-mobile-info-row">
        <div className="title">{`Co-Facilitator${
          coFacilitators.length > 1 ? 's' : ''
        }`}</div>
        {coFacilitatorsList}
      </div>
    );
  }

  // only show the Tech Support section if there are facilitators to show
  let techFacilitatorsDesktopNode;
  let techFacilitatorsMobileNode;
  if (ArrayHelpers.hasElements(techFacilitators)) {
    const techFacilitatorsList = (
      <ul className="admin-v5_coach-dashboard--event-session-co-facilitators">
        {techFacilitators.map((facilitator) => {
          return (
            <li key={`facilitator-${facilitator.id}`}>
              <span className="bold">
                {`${facilitator.first_name} ${facilitator.last_name}, `}
              </span>
              <span
                copytext={facilitator.email}
                onClick={props.handleEmailCopy}
                className="coach-event__copy-link-text"
              >
                {facilitator.email}
              </span>
            </li>
          );
        })}
      </ul>
    );
    techFacilitatorsDesktopNode = (
      <React.Fragment>
        <div className="title trailing">Tech Support</div>
        {techFacilitatorsList}
      </React.Fragment>
    );
    techFacilitatorsMobileNode = (
      <div className="admin-v5_coach-dashboard--event-mobile-info-row">
        <div className="title trailing">Tech Support</div>
        {techFacilitatorsList}
      </div>
    );
  }

  return (
    <div className="row admin-v5_coach-dashboard--events">
      {/* Desktop row */}
      <div
        className={
          'row admin-v5_coach-dashboard--event-row show-desktop hide-phone'
        }
      >
        <div className="cute-3-desktop coach-event__session-name-col">
          <div>
            <NavigationLink
              className="admin-v5_coach-dashboard--event-session-name v5__link"
              url={attendeeEventPageLink}
            >
              {Utils.getEventSessionName(
                props.display_name || props.name,
                props.session.name,
                props.session.group && props.session.group.name,
                props.session.num_sessions
              )}
            </NavigationLink>
            <div>
              {props.session.num_sessions > 1
                ? `Session ${props.session.num} of ${props.session.num_sessions}`
                : ''}
            </div>
          </div>
          {techSupportIconNode}
        </div>
        <div className="cute-4-desktop">{props.display_name}</div>
        <div className="cute-3-desktop">
          <div className="admin-v5_coach-dashboard--event-session-date">
            <div>
              {props.session.session_date_formatted
                ? moment(props.session.session_date_formatted).format('LL')
                : 'TBD'}
            </div>
            {userEventTime}
          </div>
          {eventTime}
        </div>
        <div className="cute-1-desktop">
          <NavigationLink
            target="_blank"
            className="v5__link"
            url={`/bl/event/${props.id}/participants`}
          >
            {props.tableView === 'upcoming'
              ? new Set(
                  [...props.rsvps, ...props.participants].map(
                    (attendee) => attendee.user_id
                  )
                ).size
              : props.session.participants.length}
          </NavigationLink>
        </div>
        <div className="cute-1-desktop admin-v5_coach-dashboard--status-icon">
          {statusIconNode}
        </div>
        <div
          className={`tc-v4-circle-arrow workspace__goal-expand-arrow partner_lead__expand-arrow admin-v5_coach-dashboard__event-row-arrow${expandedClass}`}
          onClick={() => setRowExpanded(!rowExpanded)}
        ></div>
      </div>

      {/* Mobile row */}
      <div
        className={`row admin-v5_coach-dashboard--event-row hide-desktop show-phone${rowExpandedClass}`}
        onClick={() => setRowExpanded(!rowExpanded)}
      >
        <div className="cute-5-phone">
          <NavigationLink
            className="admin-v5_coach-dashboard--event-session-name v5__link"
            url={attendeeEventPageLink}
          >
            {props.session.num_sessions > 1
              ? props.session.name
              : props.display_name}
          </NavigationLink>
          <div>{props.display_name}</div>
          <div>
            {props.session.num_sessions > 1
              ? `Session ${props.session.num} of ${props.session.num_sessions}`
              : ''}
          </div>
        </div>
        <div className="cute-5-phone">
          <div className="admin-v5_coach-dashboard--event-session-date">
            <div>
              {props.session.session_date_formatted
                ? moment(props.session.session_date_formatted).format('LL')
                : ''}
            </div>
            {userEventTime}
          </div>
          {eventTime}
        </div>
        <div
          className={`tc-v4-circle-arrow workspace__goal-expand-arrow partner_lead__expand-arrow admin-v5_coach-dashboard__event-row-arrow${expandedClass}`}
          onClick={() => setRowExpanded(!rowExpanded)}
        ></div>
      </div>

      {/* Desktop data row */}
      <div
        className={`row admin-v5_coach-dashboard--event-row admin-v5_coach-dashboard--more-info-row show-desktop hide-phone${moreInfoClass}`}
      >
        <div className="cute-3-desktop">
          {/* Check-in Link */}
          <div className="title">Check-In link</div>
          <div
            copytext={props.checkin_link}
            onClick={props.handleCopy}
            className="bold coach-event__copy-link-text wrap-text coach-event__psm-email"
          >
            {props.checkin_link}
          </div>
          {/* Zoom Link */}
          <div className="title trailing">{zoomLink ? 'Zoom Link' : ''}</div>
          <div
            copytext={zoomLink}
            onClick={props.handleCopy}
            className="bold coach-event__copy-link-text wrap-text coach-event__psm-email"
          >
            {zoomLink}
          </div>
        </div>
        {/* PSM */}
        <div className="cute-4-desktop">
          <div className="title">{`PSM${
            props.psms.length > 1 ? 's' : ''
          }`}</div>
          {props.psms.length ? (
            props.psms.map((psm) => {
              return (
                <div
                  className="admin-v5_coach-dashboard--event-psm-row"
                  key={`psm-${psm.id}`}
                >
                  <div className="bold">{`${psm.first_name} ${psm.last_name}`}</div>
                  <div
                    copytext={psm.email}
                    onClick={props.handleEmailCopy}
                    className="coach-event__copy-link-text"
                  >
                    {psm.email}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="v5__body-copy--italic">Information unavailable</div>
          )}
          {/* Lead Facilitator */}
          <div className="title trailing">Lead Facilitator</div>
          {leadFacilitator ? (
            <React.Fragment>
              <div className="bold">{`${leadFacilitator.first_name} ${leadFacilitator.last_name}`}</div>
              <div
                copytext={leadFacilitator.email}
                onClick={props.handleEmailCopy}
                className="coach-event__copy-link-text"
              >
                {leadFacilitator.email}
              </div>
            </React.Fragment>
          ) : (
            <div className="v5__body-copy--italic">
              No lead facilitator assigned
            </div>
          )}
        </div>
        <div className="cute-3-desktop">
          {/* Co-Facilitators */}
          {coFacilitatorsDesktopNode}

          {/* Tech Support Facilitators */}
          {techFacilitatorsDesktopNode}
        </div>
        <div className="cute-2-desktop"></div>
      </div>

      {/* Mobile data card */}
      <div
        className={`row admin-v5_coach-dashboard--event-row admin-v5_coach-dashboard--more-info-row hide-desktop show-phone${moreInfoMobileClass}`}
      >
        <div className="cute-12-phone">
          <div className="admin-v5_coach-dashboard--status-icon">
            <NavigationLink
              className="admin-v5_coach-dashboard--status-icon-link"
              url={`/bl/event/${props.id}`}
              title="Edit event materials"
            >
              <span className={`coach-event__icon ${statusIcon}`}>
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
              <span className="coach-event__materials">Event Materials</span>
            </NavigationLink>
          </div>
          <div>
            <div className="title">
              {props.tableView === 'upcoming'
                ? `RSVP${props.rsvps.length !== 1 ? 's' : ''}`
                : `Attendee${
                    props.session.participants.length !== 1 ? 's' : ''
                  }`}
            </div>
            <NavigationLink
              target="_blank"
              className="v5__link"
              url={`/bl/event/${props.id}/participants`}
            >
              {props.tableView === 'upcoming'
                ? props.rsvps.length
                : props.session.participants.length}
            </NavigationLink>
          </div>
          {/* Check-in Link */}
          <div className="admin-v5_coach-dashboard--event-mobile-info-row">
            <div className="title">Check-In link</div>
            <div
              copytext={props.checkin_link}
              onClick={props.handleCopy}
              className="bold coach-event__copy-link-text wrap-text coach-event__psm-email"
            >
              {props.checkin_link}
            </div>
          </div>
          {/* Zoom Link */}
          <div
            className={`admin-v5_coach-dashboard--event-mobile-info-row${
              zoomLink ? '' : ' hidden'
            }`}
          >
            <div className="title">Zoom Link</div>
            <div
              copytext={zoomLink}
              onClick={props.handleCopy}
              className="bold coach-event__copy-link-text wrap-text coach-event__psm-email"
            >
              {zoomLink}
            </div>
          </div>
          {/* PSM */}
          <div className="admin-v5_coach-dashboard--event-mobile-info-row">
            <div className="title">{`PSM${
              props.psms.length > 1 ? 's' : ''
            }`}</div>
            {props.psms.length ? (
              props.psms.map((psm) => {
                return (
                  <div
                    className="admin-v5_coach-dashboard--event-psm-row"
                    key={`psm-${psm.id}`}
                  >
                    <div className="bold">{`${psm.first_name} ${psm.last_name}`}</div>
                    <div
                      copytext={psm.email}
                      onClick={props.handleEmailCopy}
                      className="coach-event__copy-link-text"
                    >
                      {psm.email}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="v5__body-copy--italic">
                Information unavailable
              </div>
            )}
          </div>
          {/* Lead Facilitator */}
          <div className="admin-v5_coach-dashboard--event-mobile-info-row">
            <div className="title trailing">Lead Facilitator</div>
            {leadFacilitator ? (
              <React.Fragment>
                <div className="bold">{`${leadFacilitator.first_name} ${leadFacilitator.last_name}`}</div>
                <div
                  copytext={leadFacilitator.email}
                  onClick={props.handleEmailCopy}
                  className="coach-event__copy-link-text"
                >
                  {leadFacilitator.email}
                </div>
              </React.Fragment>
            ) : (
              <div className="v5__body-copy--italic">
                No lead facilitator assigned
              </div>
            )}
          </div>
          {/* Co-Facilitators */}
          {coFacilitatorsMobileNode}
          {/* Tech Support Facilitators */}
          {techFacilitatorsMobileNode}
        </div>
      </div>
    </div>
  );
};

EventRow.propTypes = {
  eventHasModifications: PropTypes.bool,
  checkin_link: PropTypes.string,
  facilitator_role: PropTypes.string,
  display_name: PropTypes.string,
  tableView: PropTypes.string,
  psms: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    })
  ),
  rsvps: PropTypes.array,
  session: PropTypes.shape({
    name: PropTypes.string,
    participants: PropTypes.array,
    session_date: PropTypes.string,
    zoom_link: PropTypes.string,
    materials_finalized: PropTypes.string,
    modifications_finalized: PropTypes.string,
    facilitators: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        role: PropTypes.string,
      })
    ).isRequired,
  }),
};

export default EventRow;
