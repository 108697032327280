// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import ArrayHelpers from '../../../components/ArrayHelpers';
import { Router } from './../../../components/Router';
import WindowHelpers from './../../../components/WindowHelpers';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';
import ResourceCapsule from './modules/ResourceCapsule';
import StrategyPack from './modules/StrategyPack';

const SelectResourceView = (props) => {
  // on mobile, we only show the first three favorites by default
  const [expandFavorites, setExpandFavorites] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();

    const searchUrl =
      process.env.REACT_APP_TEACHING_URL +
      (props.headerParticipant
        ? `/search/${props.headerParticipant.blUserId}`
        : '/search');

    Router.route(searchUrl);
  };

  // Build the alert node if needed
  let alertNode;
  if (Object.keys(props.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // set up the favorites capsules, or show the placeholder img if none
  let favoritesNode = (
    <div className="select-resource__favorites-empty">
      <div className="v5__heading-5 select-resource__favorites-row">
        Go ahead. You can play favorites.
      </div>
      <img
        src="/legacy-images/v5/favorites.png"
        className="select-resource__favorites-img"
      />
      <div className="v5__body-copy select-resource__favorites-row">
        Explore 20,000+ lessons and strategies. Save the ones you love to your
        Favorites.
      </div>
      <button
        onClick={handleSearch}
        className="v5__btn select-resource__favorites-btn"
      >
        Start Searching
      </button>
    </div>
  );
  if (!props.searchSuccess) {
    // when elasticsearch is down, show the placeholder img
    favoritesNode = (
      <div className="v5__body-copy select-resource__favorites-empty">
        <div className="select-resource__favorites-row">
          Your favorites are temporarily unavailable. (We blame the cat.)
        </div>
        <div className="select-resource__favorites-row">
          Thanks for your patience as we tidy up this issue.
        </div>
        <img
          src="/legacy-images/404-pets.png"
          className="select-resource__favorites-img"
        />
      </div>
    );
  } else if (ArrayHelpers.hasElements(props.favorites)) {
    // set up the favorites capsules
    let favoriteNodes = props.favorites.map((resource) => {
      const res =
        resource.type === 'strategy' ? resource.strategy : resource.lesson;
      return (
        <ResourceCapsule
          key={`resource-${resource.id}`}
          id={res.id}
          type={resource.type}
          previewPhoto={resource.previewPhoto}
          title={res.title || res.name}
          tagline={res.tagline || res.description}
          subject={res.subject}
          course_name={res.course_title}
          unit_name={res.unit}
          seo_url={res.seo_url}
          status={resource.status}
          isSelected={
            !props.selectedResource.packId &&
            props.selectedResource.resourceId === resource.id
          }
          select={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            props.setSelectedResource({
              resourceId: resource.id,
            });
          }}
          userId={props.pageUser.id}
          storyId={props.storyId}
          setErrorAlert={props.setErrorAlert}
        />
      );
    });
    // on mobile, we only show the first three by default
    if (!expandFavorites && WindowHelpers.isMobileDevice()) {
      favoriteNodes = favoriteNodes.slice(0, 3);
    }

    // show the Show More/Less link on mobile if there are more than three favorites
    const showMoreLinkNode =
      props.favorites.length > 3 ? (
        <div className="hide-desktop show-phone">
          <button
            type="button"
            className="tc__link v5__toggle-copy"
            onClick={() => setExpandFavorites(!expandFavorites)}
          >
            Show {expandFavorites ? 'Less' : 'More'}
          </button>
        </div>
      ) : null;

    favoritesNode = (
      <div className="select-resource__favorites">
        {favoriteNodes}
        {showMoreLinkNode}
      </div>
    );
  }

  return (
    <div
      className="tc__page select-resource"
      onClick={() => props.setSelectedResource({})}
    >
      <div className="select-resource__main-content">
        {alertNode}

        <div className="select-resource__header">
          <div className="row row--1200 row--max-100 select-resource__headline-row">
            <div className="cute-11-desktop cute-10-phone">
              <h1 className="v5__heading-3 select-resource__headline">
                Select a Resource
              </h1>
            </div>
            <div className="cute-1-desktop cute-2-phone select-resource__close">
              <NavigationLink
                className="select-resource__close-icon"
                url={`/lab/workspace/${props.pageUser.id}`}
              >
                <span className="tc-x"></span>
              </NavigationLink>
            </div>
          </div>
        </div>

        <div className="row row--1200">
          <div className="cute-6-desktop cute-12-phone">
            <h2 className="v5__heading-4 select-resource__heading-2 select-resource__heading-2--short">
              Favorites and Recommendations
            </h2>
            {favoritesNode}
          </div>
          <div className="cute-6-desktop cute-12-phone">
            <h2 className="v5__heading-4 select-resource__heading-2">
              Strategy Packs
            </h2>
            {props.strategyPacks.map((pack) => {
              return (
                <StrategyPack
                  key={`pack-${pack.id}`}
                  {...pack}
                  selectedResource={props.selectedResource}
                  setSelectedResource={props.setSelectedResource}
                  userId={props.pageUser.id}
                  storyId={props.storyId}
                  setErrorAlert={props.setErrorAlert}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="select-resource__footer">
        <div className="v5__heading-5 select-resource__footer-text">
          Looking for more?
        </div>
        <button
          className="v5__btn v5__btn--blue-border select-resource__footer-btn"
          onClick={handleSearch}
        >
          Search Resources
        </button>
        <div className="select-resource__footer-link-text">
          or{' '}
          <NavigationLink
            className="select-resource__footer-link"
            url={`/lab/workspace/${props.pageUser.id}?customStrategyAdd=true`}
          >
            create a custom strategy
          </NavigationLink>
        </div>
      </div>
    </div>
  );
};

SelectResourceView.propTypes = {
  pageUser: PropTypes.object.isRequired,
  searchSuccess: PropTypes.bool,
  favorites: PropTypes.array,
  strategyPacks: PropTypes.array,
  selectedResource: PropTypes.object.isRequired,
};

export default SelectResourceView;
