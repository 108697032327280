// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import Spinner from './../../Common/Spinner';

const ConfirmDelete = (props) => {
  // track loading state for Delete button
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = (e) => {
    e.preventDefault();

    setIsLoading(true);
    props.delete();
  };

  // disable the button when loading
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <div className="workspace__confirm-delete">
      <h3 className="v5__heading-5 workspace__confirm-delete-heading">
        {props.headlineText || `Delete "${props.title}"?`}
      </h3>

      <div className="v5__body-copy">
        {props.bodyText ||
          'It will be permanently deleted along with any attached comments.'}
      </div>

      <div className="workspace__confirm-delete-btns-row">
        <button
          {...btnProps}
          onClick={handleDelete}
          className="v5__btn v5__btn--red workspace__confirm-delete-btn"
        >
          <Spinner loading={isLoading} />
          Delete
        </button>
        <button onClick={props.cancel} className="v5__link">
          Cancel
        </button>
      </div>
    </div>
  );
};

ConfirmDelete.propTypes = {
  headlineText: PropTypes.string,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  delete: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default ConfirmDelete;
