// @ts-nocheck FIXME
import WorkspaceApi from '@legacy-components/apis/V5/WorkspaceApi';
import Utils from '@legacy-components/components/DynamicUtils';
import TimeDelta from '@legacy-components/react/V4/Common/TimeDelta';
import AbridgeText from '@legacy-components/react/V5/Common/AbridgeText';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import { useState } from 'react';

const CompletedGoalEntryForm = (props) => {
  const [note, setNote] = useState(props.noteInput || '');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    WorkspaceApi.editCompletedGoal({
      storyId: props.activeStoryId,
      note: note,
      storyCompletedGoalId: props.entryId,
    })
      .then((response) => {
        props.editEntryCallback(response.entry);
        props.close();
      })
      .catch(() => {
        props.setErrorAlert();
        setLoading(false);
      });
  };

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <form>
      <div className="v5__heading-5 workspace__entry-header-row">
        <div className="tc-v5-milestone-complete workspace__entry-header-icon">
          <span className="path1"></span>
          <span className="path2"></span>
        </div>
        <div className="workspace__entry-header-text">
          {props.title}
          <div className="workspace__entry-header-timestamp">
            <TimeDelta datetime={props.createdFormatted} />
          </div>
        </div>
      </div>

      <div className="workspace__entry-body">
        <div className="workspace__entry-row v5__body-copy">
          <AbridgeText
            maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
            isExpandable={true}
          >
            {props.description}
          </AbridgeText>
        </div>

        <div className="workspace__entry-reflection-label">
          Growth Reflection
        </div>

        <FormField
          type="textarea"
          rows="4"
          placeholder="Reflect on your experience working on this outcome. What did you learn?"
          defaultValue={props.noteInput}
          autoFocus={true}
          onChange={(e) => setNote(e.target.value)}
        />

        <div className="workspace__btns-row">
          <button
            type="button"
            className="v5__btn v5__btn--blue-border workspace__cancel-link"
            onClick={() => props.close()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="v5__btn"
            {...btnProps}
            onClick={handleSubmit}
          >
            <Spinner loading={isLoading} />
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default CompletedGoalEntryForm;
