// @ts-nocheck FIXME
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import NavigationLink from '@legacy-components/react/NavigationLink';
import RecommendModal from '@legacy-components/react/V4/Search/modules/RecommendModal';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import About from './modules/About';
import Curriculum from './modules/Curriculum';
import Strategies from './modules/Strategies';
import Teacher from './modules/Teacher';

const MasterTeacherProfile = (props) => {
  // the data structure depends on whether this is an mtp teacher or a learning domain teacher
  const teacher = props.mtp_page_data
    ? props.mtp_page_data.User
    : props.lab_data.teacher;

  // set up link to the teacher's base MT profile page
  const baseLink = `${process.env.REACT_APP_TEACHING_URL}/browse/master_teacher/${teacher.id}/${teacher.seo_url}`;

  // Build the alert node if needed when favoriting/recommending
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // set up any opened modal
  let modalNode;
  if (props.ui.recommendModal.opened) {
    modalNode = (
      <RecommendModal
        {...props.ui.recommendModal}
        participants={props.ui.participants}
        recommend={props.addFavorite}
        selectRecommendElement={props.selectRecommendElement}
        getRecommendElement={props.getRecommendElement}
        user={props.user}
      />
    );
  }

  // set up some teacher info - prefer to get it from the MTP data if possible
  let teacherSchool = teacher.institution && teacher.institution.name;
  let teacherCity;
  let teacherState = teacher.institution && teacher.institution.state;
  if (props.mtp_page_data && props.mtp_page_data.School) {
    teacherSchool = props.mtp_page_data.School.name;
    teacherCity = props.mtp_page_data.School.Location_City;
    teacherState = props.mtp_page_data.School.Location_State;
  }

  // set up the left side nav with tabs depending on the type of content the teacher has
  let strategiesLinkNode;
  let mobileStrategiesLinkNode;
  if (props.lab_data && props.lab_data.strategy_count) {
    strategiesLinkNode =
      props.ui.tab === 'strategies' ? (
        <div className="mt-profile__left-nav-item active">
          Strategies ({props.lab_data.strategy_count})
        </div>
      ) : (
        <div className="mt-profile__left-nav-item">
          <NavigationLink url={`${baseLink}/strategies`} className="v5__link">
            Strategies ({props.lab_data.strategy_count})
          </NavigationLink>
        </div>
      );

    mobileStrategiesLinkNode =
      props.ui.tab === 'strategies' ? (
        <div className="v5__capsule mt-profile__mobile-nav-capsule active">
          <div className="mt-profile__mobile-nav-icon blue tc-v4-strategy"></div>
          Strategies ({props.lab_data.strategy_count})
        </div>
      ) : (
        <NavigationLink
          url={`${baseLink}/strategies`}
          className="v5__link v5__link--black v5__capsule mt-profile__mobile-nav-capsule"
        >
          <div className="mt-profile__mobile-nav-icon blue tc-v4-strategy"></div>
          Strategies ({props.lab_data.strategy_count})
        </NavigationLink>
      );
  }

  let lessonsLinkNode;
  let mobileLessonsLinkNode;
  if (props.mtp_page_data && props.mtp_page_data.lesson_count) {
    lessonsLinkNode =
      props.ui.tab === 'curriculum' ? (
        <div className="mt-profile__left-nav-item active">
          Lessons ({props.mtp_page_data.lesson_count})
        </div>
      ) : (
        <div className="mt-profile__left-nav-item">
          <NavigationLink url={`${baseLink}/curriculum`} className="v5__link">
            Lessons ({props.mtp_page_data.lesson_count})
          </NavigationLink>
        </div>
      );

    mobileLessonsLinkNode =
      props.ui.tab === 'curriculum' ? (
        <div className="v5__capsule mt-profile__mobile-nav-capsule active">
          <div className="mt-profile__mobile-nav-icon orange tc-v4-math"></div>
          Lessons ({props.mtp_page_data.lesson_count})
        </div>
      ) : (
        <NavigationLink
          url={`${baseLink}/curriculum`}
          className="v5__link v5__link--black v5__capsule mt-profile__mobile-nav-capsule"
        >
          <div className="mt-profile__mobile-nav-icon orange tc-v4-math"></div>
          Lessons ({props.mtp_page_data.lesson_count})
        </NavigationLink>
      );
  }

  let blendedLinkNode;
  let mobileBlendedLinkNode;
  if (props.mtp_page_data && props.mtp_page_data.blended_count) {
    blendedLinkNode =
      props.ui.tab === 'blended' ? (
        <div className="mt-profile__left-nav-item active">
          Blended Learning ({props.mtp_page_data.blended_count})
        </div>
      ) : (
        <div className="mt-profile__left-nav-item">
          <NavigationLink url={`${baseLink}/blended`} className="v5__link">
            Blended Learning ({props.mtp_page_data.blended_count})
          </NavigationLink>
        </div>
      );

    mobileBlendedLinkNode =
      props.ui.tab === 'blended' ? (
        <div className="v5__capsule mt-profile__mobile-nav-capsule active">
          <div className="mt-profile__mobile-nav-icon green tc-v4-tablet"></div>
          Blended ({props.mtp_page_data.blended_count})
        </div>
      ) : (
        <NavigationLink
          url={`${baseLink}/blended`}
          className="v5__link v5__link--black v5__capsule mt-profile__mobile-nav-capsule"
        >
          <div className="mt-profile__mobile-nav-icon green tc-v4-tablet"></div>
          Blended ({props.mtp_page_data.blended_count})
        </NavigationLink>
      );
  }

  // set up the body of the page based on the active tab
  let mainContent =
    props.lab_data && props.ui.tab === 'strategies' ? (
      <Strategies
        domainId={props.ui.currentDomain.id}
        domainName={props.ui.currentDomain.title}
        domains={props.ui.domains}
        strategies={props.ui.currentDomain.strategies}
        domainSelectorOpened={props.ui.domainSelectorOpened}
        openDomainSelector={props.openDomainSelector}
        user={props.user}
        teacher={teacher}
        addFavorite={props.addFavorite}
        removeFavorite={props.removeFavorite}
        recommendModal={props.ui.recommendModal}
      />
    ) : (
      <Curriculum {...props} />
    );
  if (props.ui.tab === 'about') {
    mainContent = (
      <About
        user={teacher}
        welcomeVideos={
          props.mtp_page_data ? props.mtp_page_data.WelcomeVideo : null
        }
        currentVideoIndex={props.ui.currentVideoIndex}
        setCurrentVideo={props.setCurrentVideo}
      />
    );
  }

  return (
    <div className="tc__page">
      <Header page="mtprofile" {...props} />
      <div className="bl4__page-background show-desktop hide-phone"></div>
      {alertNode}
      {modalNode}

      <div className="row row--1200">
        <div className="mt-profile__sidebar cute-3-desktop cute-12-phone">
          <div className="strategy-v4__metadata-flex-section strategy-v4__metadata-flex-section--centered">
            <Teacher
              id={teacher.id}
              photo={teacher.photo}
              first_name={teacher.first_name}
              last_name={teacher.last_name}
              seo_url={teacher.seo_url}
              domains={
                props.lab_data &&
                props.lab_data.domains.map((domain) => domain.title)
              }
              courses={props.mtp_page_data && props.mtp_page_data.course_names}
              school={teacherSchool}
              city={teacherCity}
              state={teacherState}
              className="strategy-v4__metadata-flex-item"
            />
          </div>

          <div className="mt-profile__left-nav v5__body-copy show-desktop hide-phone">
            <div className="mt-profile__left-nav-separator"></div>

            <div className="mt-profile__left-nav-headline">MY CONTENT</div>
            {strategiesLinkNode}
            {lessonsLinkNode}
            {blendedLinkNode}
          </div>

          <div className="v5__body-copy hide-desktop show-phone">
            <div className="mt-profile__mobile-nav-capsules">
              {mobileStrategiesLinkNode}
              {mobileLessonsLinkNode}
              {mobileBlendedLinkNode}
            </div>
          </div>
        </div>

        {mainContent}
      </div>
    </div>
  );
};

export default MasterTeacherProfile;
