// @ts-nocheck FIXME
const PartnerCreate = (props) => {
  let partnerName;
  let partnerCode;

  // event handlers
  const handlePartnerHideAddModal = (e) => {
    e.preventDefault();
    props.toggleModal({ feature: 'addPartner' });
  };

  const handleClearErrorsOnFocus = () => {
    if (!props.error && !props.notifications.length) {
      return;
    }

    props.clearErrorsOnFocus({ feature: 'addPartner' });
  };

  const addPartner = (e) => {
    e.preventDefault();

    // don't do anything if the form is submitting
    if (props.isLoading) {
      return;
    }

    props.addPartner({ name: partnerName.value, code: partnerCode.value });
  };

  /* BEGIN RENDERING LOGIC */

  // failed validation results
  let errorNode = null;
  let inputErrorClass = '';
  if (props.error) {
    errorNode = (
      <div className="tc__response-inline tc__response-inline--error">
        <ul>
          <li>{props.error}</li>
        </ul>
      </div>
    );
    inputErrorClass = ' error';
  }

  let notificationNode = [];
  if (props.notifications.length) {
    notificationNode = (
      <div className="tc__modal-inline-error tc__response-inline tc__response-inline--error">
        <ul>
          {props.notifications.map((notification, index) => (
            <li key={'notification' + index}>{notification}</li>
          ))}
        </ul>
      </div>
    );
    inputErrorClass = ' error';
  }

  const dissableButtonClass = props.isLoading ? ' disabled' : '';

  return (
    <div className="tc__modal-add-overlay show">
      <div
        onClick={handlePartnerHideAddModal}
        className="modal__page-overlay modal__page-overlay--grey modal__page-overlay--add"
      ></div>
      <div className="tc__modal-add">
        <form className="">
          <h3 className="tc__modal-add-headline tc__headline-3">
            Create a Partner
          </h3>
          {errorNode}
          {notificationNode}
          <div className="tc__modal-add-row">
            <label className="tc__modal-add-label">Name: </label>
            <input
              className={'tc__input tc__modal-add-input' + inputErrorClass}
              ref={(ref) => (partnerName = ref)}
              autoFocus={true}
              key="parnername"
              onFocus={handleClearErrorsOnFocus}
              defaultValue={partnerName}
            />
          </div>
          <div className="tc__modal-add-row">
            <label className="tc__modal-add-label">Code: </label>
            <input
              className={'tc__input tc__modal-add-input' + inputErrorClass}
              ref={(ref) => (partnerCode = ref)}
              key="partnercode"
              onFocus={handleClearErrorsOnFocus}
              defaultValue={partnerCode}
            />
          </div>
          <div className="tc__modal-add-row tc__modal-add-row--btn">
            <input
              className={
                'tc__btn tc__btn--blue tc__btn--small tc__btn--add-submit' +
                dissableButtonClass
              }
              type="submit"
              value="submit"
              onClick={addPartner}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

PartnerCreate.propTypes = {};

export default PartnerCreate;
