import { WarningAmberOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { UnifiedHeader } from 'betterlesson-library-react';
import { useEffect } from 'react';

const PAGE_REFRESH_TIMEOUT = 60 * 1000;

export default function ErrorPage(): JSX.Element {
  const handleReload = () => {
    location.reload();
  };

  useEffect(() => {
    setTimeout(function () {
      location.reload();
    }, PAGE_REFRESH_TIMEOUT);
  });

  const renderErrorMessage = () => (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-testid="error-page"
    >
      <Typography variant="h6" component="div" color="#663c00" fontSize="16px">
        This page is having trouble loading.
      </Typography>

      <Button data-testid="error-page-reload-button" onClick={handleReload}>
        <Typography
          variant="h6"
          component="div"
          color="#663c00"
          fontSize="15px"
        >
          Reload
        </Typography>
      </Button>
    </Stack>
  );

  const renderErrorDescription = () => (
    <Typography
      variant="body1"
      color="#663c00"
      fontSize="18px"
      fontStyle="italic"
    >
      This page will attempt to reload when it can. If this error persists,
      please see our{' '}
      <Typography
        target="_blank"
        variant="body1"
        component="a"
        color="#663c00"
        fontSize="18px"
        fontStyle="italic"
        sx={{ textDecoration: 'underline' }}
        href="https://betterlesson.atlassian.net/servicedesk/customer/portals"
      >
        Support Page.
      </Typography>
    </Typography>
  );

  const renderError = () => (
    <Stack alignItems="center">
      <Box
        sx={{
          width: { xs: 'calc(100% - 20px)', md: '600px' },
          bgcolor: '#fff4e5',
          px: 2,
          py: 1,
          borderRadius: '8px',
          display: 'flex',
          columnGap: 1.5,
          mb: 5,
          position: 'absolute',
          bottom: 0,
        }}
      >
        <Box sx={{ pt: 0.5 }}>
          <WarningAmberOutlined sx={{ color: '#ff8b00' }} />
        </Box>

        <Box>
          {renderErrorMessage()}
          {renderErrorDescription()}
        </Box>
      </Box>
    </Stack>
  );

  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      <UnifiedHeader roles={[]} />

      {renderError()}
    </Box>
  );
}
