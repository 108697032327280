// @ts-nocheck FIXME
import React from 'react';
import ReactDOM from 'react-dom';
import LayerManager from './../../components/LayerManager';
import StrategyMultiSelectStore from './../../stores/V2/StrategyMultiSelectStore';

class CoachPinning extends React.Component {
  state = StrategyMultiSelectStore.getState();
  token = null;

  componentDidMount() {
    this.token = StrategyMultiSelectStore.subscribe(this.onStoreChange);
    this.handleCalculatePosition();
  }

  componentWillUnmount() {
    StrategyMultiSelectStore.unsubscribe(this.token);
  }

  onStoreChange = () => {
    this.setState(StrategyMultiSelectStore.getState());
  };

  componentDidUpdate(prevProps, prevState) {
    // adjust top margin of the modal when options are added or removed from the select list
    if (prevState.selectedOptions.length != this.state.selectedOptions.length) {
      this.handleCalculatePosition();
    }
  }

  handleCalculatePosition = () => {
    const modalHeight = ReactDOM.findDOMNode(this.refs.fixedModal).offsetHeight;
    const marginTop = -(modalHeight / 2);
    // this.setState({margin: marginTop});
    StrategyMultiSelectStore.actionDispatch('setModalMarginTop', marginTop);
  };

  handleOutsideClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    LayerManager.close(this.props.closeUrl);
  };

  render() {
    return (
      <div>
        <div
          className="modal__page-overlay modal__page-overlay--grey hide-phone show-desktop"
          onClick={this.handleOutsideClose}
        ></div>
        <div
          ref="fixedModal"
          id={this.props.id}
          style={{ marginTop: this.state.modalMarginTop }}
          className="modal--fixed modal--grey-overlay hide-phone show-desktop"
        >
          {this.props.children}
        </div>
        <div
          ref="fixedModalMobile"
          id={this.props.id + '-mobile'}
          className="modal--fixed modal--grey-overlay show-phone hide-desktop"
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default CoachPinning;
