// @ts-nocheck FIXME
import CoachMatchApi from '@legacy-components/apis/V5/CoachMatchApi';
import Utils from '@legacy-components/components/DynamicUtils';
import { Router } from '@legacy-components/components/Router';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import Breadcrumb from '@legacy-components/react/V5/Common/Breadcrumb';
import CoachMatchAction from '@legacy-components/react/V5/Common/CoachMatchAction';
import Modal from '@legacy-components/react/V5/Common/Modal';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment-timezone';
import React from 'react';
import MatchAvailability from './modules/MatchAvailability';
import MatchCohort from './modules/MatchCohort';
import MatchPastExperience from './modules/MatchPastExperience';
import MatchTeaching from './modules/MatchTeaching';

const CoachMatchDetailsContainer = (props) => {
  const [coachMatchActionError, setCoachMatchActionError] =
    React.useState(null);
  const [coachMatchActionLoading, setCoachMatchActionLoading] =
    React.useState(false);
  const [matchAction, setMatchAction] = React.useState(null);

  const updateMatch = (data) => {
    CoachMatchApi.updateCoachMatchStatus(data)
      .then((response) => {
        if (response.status === 'error') {
          setCoachMatchActionLoading(false);
          setCoachMatchActionError(response.error);
          return;
        }

        const userName = `${props.proposedMatch.user.first_name} ${props.proposedMatch.user.last_name}`;
        const successMessage =
          data.status === 'Accepted'
            ? `You accepted ${userName} as a coaching participant.`
            : `You declined your match with ${userName}.`;

        props.setAlert({
          type: 'success',
          message: successMessage,
        });

        // route back to coach dashboard
        Router.route(`/lab/coach/${props.user.id}/coaching`);
      })
      .catch(() => {
        setCoachMatchActionLoading(false);
        props.coachMatchActionError(
          'Something went wrong please try again later.'
        );
      });
  };

  const handleAcceptClick = () => {
    setMatchAction('accept');
  };

  const handleDeclineClick = () => {
    setMatchAction('decline');
  };

  const handleEmptySelectedMatchForAction = () => {
    setCoachMatchActionError(null);
    setMatchAction(null);
  };

  let coachMatchModalNode = null;
  if (matchAction) {
    coachMatchModalNode = (
      <Modal
        className={'v5__modal--action'}
        close={handleEmptySelectedMatchForAction}
      >
        <CoachMatchAction
          {...props.proposedMatch}
          user={props.user}
          setCoachMatchActionError={setCoachMatchActionError}
          coachMatchActionError={coachMatchActionError}
          setCoachMatchActionLoading={setCoachMatchActionLoading}
          coachMatchActionLoading={coachMatchActionLoading}
          updateMatch={updateMatch}
          action={matchAction}
          close={handleEmptySelectedMatchForAction}
        />
      </Modal>
    );
  }

  let isReturningNode = null;
  if (
    props.proposedMatch.participant &&
    props.proposedMatch.participant.is_returning
  ) {
    let isReturningCopy = 'Returning';
    for (const key in props.archivedCoaching) {
      if (
        props.archivedCoaching[key].coach &&
        props.archivedCoaching[key].coach.id &&
        props.archivedCoaching[key].coach.id === props.user.id
      ) {
        isReturningCopy = 'Coached by You';
      }
    }
    isReturningNode = (
      <div className="coach-match__returning coach-match__returning--details v5__body-copy v5__body-copy--bold">
        {isReturningCopy}
      </div>
    );
  }

  let matchNotesNode = null;
  if (props.proposedMatch.cb_note) {
    matchNotesNode = (
      <section className="row">
        <h3 className="coach-match__headline coach-match__headline--details coach-match__headline--deep-ocean v5__heading-5">
          <i className="tc-note match-details__icon" /> Match Note
        </h3>
        <div>{props.proposedMatch.cb_note}</div>
      </section>
    );
  }

  const matchDetailsFooter = props.proposedMatch.lab_status ? (
    <div className="match-details__footer-placeholder"></div>
  ) : (
    <section className="match-details__footer">
      <div className="match-details__footer-headline v5__heading-4">
        Would you like to accept or decline this proposed match?
      </div>
      <div className="coach-match__btn-group">
        <button
          onClick={handleAcceptClick}
          className="coach-match__btn v5__btn v5__btn--blue"
        >
          Accept
        </button>
        <button
          onClick={handleDeclineClick}
          className="coach-match__btn v5__btn v5__btn--blue-border"
        >
          Decline
        </button>
      </div>
    </section>
  );

  // these fields should always be available on this page, just in case add a null check
  const proposedMatchCohort = props.proposedMatch.cohort || {};
  const proposedMatchParticipant = props.proposedMatch.participant || {};
  const proposedMatchUser = props.proposedMatch.user || {};

  return (
    <div className="tc__page v5__body-copy">
      <Header page="CoachHome" {...props} />
      {coachMatchModalNode}
      <div className="match-details__content">
        <div className="match-details__breadcumbs row">
          <Breadcrumb
            divider=" / "
            trail={[
              {
                text: 'Coach Dashboard',
                link: `/lab/coach/${props.user.id}/coaching`,
              },
              {
                text: `${proposedMatchUser.first_name} ${proposedMatchUser.last_name}`,
                link: `/lab/participant/${proposedMatchParticipant.id}/details`,
              },
              {
                text: `${props.proposedMatch.lab_status || 'Pending Match'}: ${
                  proposedMatchUser.first_name
                } ${proposedMatchUser.last_name}`,
              },
            ]}
          />
        </div>
        <section className="row coach-match__row coach-match__row--space-between">
          <div className="coach-match__user match-details__user coach-match__col--40">
            <UserPhoto
              src={proposedMatchUser.photo}
              userId={proposedMatchUser.id}
              firstName={proposedMatchUser.first_name}
              lastName={proposedMatchUser.last_name}
              diameter={70}
            />
            <div className="coach-match__participant">
              {isReturningNode}
              <div className="coach-match__name-container">
                <div className="v5__heading-2 match-details__participant-name">
                  {proposedMatchUser.first_name} {proposedMatchUser.last_name}
                </div>
              </div>
              <div className="">
                {proposedMatchParticipant.role || ''},{' '}
                {proposedMatchUser.institution || ''}
              </div>
            </div>
          </div>
          <div className="coach-match__col--60">
            <div className="match-details__detail-highlight coach-match__row">
              <div className="coach-match__col--50">
                <div className="match-details__sub-headline match-details__sub-headline--main-detail v5__body-copy--bold">
                  Product
                </div>
                <div className="match-details__main-detail">
                  {(proposedMatchCohort.product &&
                    proposedMatchCohort.product.name) ||
                    ''}
                </div>
              </div>
              <div className="coach-match__col--50">
                <div className="match-details__sub-headline match-details__sub-headline--main-detail match-details__sub-headline--dates v5__body-copy--bold">
                  Dates
                </div>
                <div className="match-details__main-detail">
                  {Utils.handleFormatDate(
                    props.proposedMatch.coaching_engagement
                      .registration_completion_formatted,
                    proposedMatchCohort.target_end_date_formatted
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {matchNotesNode}

        <MatchAvailability
          matchTimeZone={proposedMatchUser.time_zone}
          coachTimeZone={props.user.time_zone}
          availabilities={proposedMatchParticipant.availability || []}
        />
        <MatchTeaching participant={proposedMatchParticipant} />
        <MatchPastExperience
          userId={proposedMatchParticipant.user_id}
          archivedCoaching={props.proposedMatch.archivedCoaching}
          events={props.proposedMatch.events}
        />
        <MatchCohort
          cohort={proposedMatchCohort}
          registrationCompletion={
            props.proposedMatch.coaching_engagement
              .registration_completion_formatted
          }
        />

        <section className="row">
          <h3 className="coach-match__headline coach-match__headline--details coach-match__headline--blueberry v5__heading-5">
            <i className="tc-v5-coach-product match-details__icon" /> Match
            Information
          </h3>
          <div className="coach-match__row">
            <div className="coach-match__col--33">
              <div className="v5__body-copy--bold">Match Proposed</div>
              <div>{moment(props.created).format('MMMM Do YYYY')}</div>
            </div>
            <div className="coach-match__col--33">
              <div className="v5__body-copy--bold">Match Expires</div>
              <div>
                {moment(props.created).add(1, 'day').format('MMMM Do YYYY')}
              </div>
            </div>
          </div>
        </section>
      </div>
      {matchDetailsFooter}
    </div>
  );
};

const ConnectedCoachMatchDetailsContainer = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => (
      <CoachMatchDetailsContainer {...props} setAlert={setAlert} />
    )}
  </HeaderConsumer>
);

export default ConnectedCoachMatchDetailsContainer;
