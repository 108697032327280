// @ts-nocheck FIXME
import CarouselButton from '@legacy-components/react/V5/Common/CarouselButton';

const CarouselButtonContainer = (props) => {
  const carouselButtons = () => {
    const buttons = [];
    for (let button = 0; button < props.numButtons; button++) {
      buttons.push(
        <CarouselButton
          key={`carousel_button-${button}`}
          number={button}
          buttonHandler={() => props.updateButton(button)}
          buttonSelected={props.buttonSelected}
        />
      );
    }
    return buttons;
  };

  return <div className="carousel__button-container">{carouselButtons()}</div>;
};

export default CarouselButtonContainer;
