import axios, { AxiosInstance } from 'axios';
import { stringify } from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { getToken } from './TokenLocator';

export const getLabAxiosWithCorrelationId = (
  correlationId: string
): AxiosInstance => {
  const labAxios = axios.create({
    baseURL: process.env.REACT_APP_LAB_SERVICE_URL,
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
    validateStatus: () => true,
  });

  const token = getToken();
  if (token) {
    labAxios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token.access_token}`;
  }

  labAxios.defaults.headers.common['x-correlation-id'] = correlationId;
  labAxios.defaults.headers.common['X-BL-WEB-LAB'] = String(true);
  labAxios.defaults.headers.common['Cache-Control'] = 'no-cache';

  return labAxios;
};

export const getLabAxios = (): AxiosInstance => {
  const correlationId = uuidv4();
  return getLabAxiosWithCorrelationId(correlationId);
};
