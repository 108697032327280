// @ts-nocheck FIXME
import Analytics from '@legacy-components/components/Analytics';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Auth from '@legacy-components/components/Auth';
import Utils from '@legacy-components/components/DynamicUtils';
import { Router } from '@legacy-components/components/Router';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import AbridgeText from '@legacy-components/react/V4/Common/AbridgeText';
import ActivateOnScrollToElement from '@legacy-components/react/V4/Common/ActivateOnScrollToElement';
import WindowScrollElementFixer from '@legacy-components/react/V4/Common/WindowScrollElementFixer';
import RecommendModal from '@legacy-components/react/V4/Search/modules/RecommendModal';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share';
import ZenScroll from 'zenscroll';

import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import ShareModal from './modules/ShareModal';
import StrategyCapsule from './modules/StrategyCapsule';
import Teacher from './modules/Teacher';

const Domain = (props) => {
  // clicking on a link in the left side nav scrolls the page to the top of that section
  const scrollToSection = (index) => {
    const element = document.getElementById('section-' + index);
    if (element) {
      // on desktop only, clicking on a sidebar link scrolls to the associated section
      ZenScroll.setup(600, 220);
      ZenScroll.to(element);

      Analytics.logEvent({
        category: 'Scroll',
        action: 'domainsection',
        label: 'from_quick_scroll',
      });
    }
  };

  const handleBrowseStrategies = () => {
    Router.route(
      process.env.REACT_APP_TEACHING_URL +
        '/search?types=strategy&focusAreas=' +
        props.domain.id +
        '&from=individual_learning_domain'
    );
  };

  const handleSocialShare = (target) => {
    Analytics.logEvent({
      category: 'domain',
      action: 'share_on_' + target,
      label: 'from_individual_learning_domain',
    });
  };

  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // show a modal if one is opened
  let modalNode;
  if (props.ui.recommendModal.opened) {
    modalNode = (
      <RecommendModal
        {...props.ui.recommendModal}
        participants={props.ui.participants}
        recommend={props.addFavorite}
        selectRecommendElement={props.selectRecommendElement}
        getRecommendElement={props.getRecommendElement}
        user={props.user}
        analyticsLabel="from_individual_learning_domain"
      />
    );
  } else if (props.ui.shareModal.opened) {
    modalNode = (
      <ShareModal
        {...props.ui.shareModal}
        analyticsLabel="from_individual_learning_domain"
      />
    );
  }

  // set up current page url for social sharing icons
  let shareUrl =
    process.env.REACT_APP_TEACHING_URL +
    '/browse/learning-domain/' +
    props.domain.id;
  if (props.domain.seo_url) {
    shareUrl += '/' + props.domain.seo_url;
  }

  const domainIdForHeroImage = Utils.getDomainIdForHeroImage(
    props.domain.id,
    'with_gradient'
  );
  const domainIdForIcon = Utils.getDomainIdForIcon(props.domain.id);
  // set up the background img of the header based on the domain id
  const headerBackgroundStyle = {
    backgroundImage: `url('/legacy-images/learning_domains/with_gradient/${domainIdForHeroImage}.webp')`,
  };

  // set up page sections (including side nav links to them)
  const sectionLinkNodes = [];
  let aboutSection,
    strategiesSection,
    masterTeachersSection,
    growthAreasSection,
    addlReadingSection,
    contactUsSection;
  if (props.domain.about) {
    sectionLinkNodes.push(
      <div
        key="nav-link-0"
        id="nav-link-0"
        onClick={() => scrollToSection(0)}
        className="tc__link strategy-v4__side-nav-row"
      >
        About
      </div>
    );
    aboutSection = (
      <div id="section-0" className="domain__section domain__section-zebra">
        <div className="row row--1200 domain__section-row-mobile">
          <div className="cute-8-desktop center-desktop cute-12-phone">
            <h2 className="tc__headline-3 domain__section-headline">
              About {props.domain.title}
            </h2>
            <AbridgeText
              maxHeight={240}
              isExpandable={true}
              analyticsCategory="about_section"
              analyticsLabel="from_individual_learning_domain"
              updateSectionExpandToggleCount={
                props.updateSectionExpandToggleCount
              }
            >
              <PurifiedHTML
                className="ckeditor__output"
                content={props.domain.about}
              />
            </AbridgeText>
          </div>
        </div>
      </div>
    );
  }
  if (ArrayHelpers.hasElements(props.strategies)) {
    sectionLinkNodes.push(
      <div
        key="nav-link-1"
        id="nav-link-1"
        onClick={() => scrollToSection(1)}
        className="tc__link strategy-v4__side-nav-row"
      >
        Featured Strategies
      </div>
    );
    strategiesSection = (
      <div id="section-1" className="domain__section domain__section-zebra">
        <div className="row row--1200 domain__section-row-mobile">
          <div className="cute-8-desktop center-desktop cute-12-phone">
            <h2 className="tc__headline-3 domain__section-headline">
              Featured Strategies
            </h2>
            <div className="domain__strategies-row">
              {props.strategies.map((strategy) => {
                return (
                  <StrategyCapsule
                    key={'strategy-' + strategy.id}
                    {...strategy}
                    user={props.user}
                    addFavorite={props.addFavorite}
                    removeFavorite={props.removeFavorite}
                    recommendModal={props.ui.recommendModal}
                    smallVersion={true}
                    greyOnMobile={true}
                    fromTag="individual_learning_domain"
                  />
                );
              })}
            </div>
            <div className="tc__center-text">
              <button
                onClick={handleBrowseStrategies}
                className="bl4__btn bl4__btn--blue"
              >
                See More Strategies
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (ArrayHelpers.hasElements(props.masterTeachers)) {
    sectionLinkNodes.push(
      <div
        key="nav-link-2"
        id="nav-link-2"
        onClick={() => scrollToSection(2)}
        className="tc__link strategy-v4__side-nav-row"
      >
        Master Teachers
      </div>
    );
    masterTeachersSection = (
      <div id="section-2" className="domain__section domain__section-zebra">
        <div className="row row--1200 domain__section-row-mobile">
          <div className="cute-8-desktop center-desktop cute-12-phone">
            <h2 className="tc__headline-3 domain__section-headline">
              Master Teachers
            </h2>
            <div className="mt-dir__teachers tc__body-copy">
              {props.masterTeachers.map((masterTeacher) => {
                return (
                  <Teacher
                    key={'teacher-' + masterTeacher.id}
                    domainId={props.domain.id}
                    {...masterTeacher}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (ArrayHelpers.hasElements(props.domain.growth_areas)) {
    sectionLinkNodes.push(
      <div
        key="nav-link-3"
        id="nav-link-3"
        onClick={() => scrollToSection(3)}
        className="tc__link strategy-v4__side-nav-row"
      >
        Outcomes
      </div>
    );
    growthAreasSection = (
      <div id="section-3" className="domain__section domain__section-zebra">
        <div className="row row--1200 domain__section-row-mobile">
          <div className="cute-8-desktop center-desktop cute-12-phone">
            <h2 className="tc__headline-3 domain__section-headline">
              Outcomes
            </h2>
            <div className="tc__body-copy">
              BetterLesson&apos;s coaching and workshops are focused on specific
              topics.
            </div>
            <div className="domain__growth-areas">
              {props.domain.growth_areas.map((area, i) => {
                const colClass =
                  i % 3 === 2 ? ' domain__growth-area--last-col' : '';
                return (
                  <div
                    key={'growth-area-' + area.id}
                    className={`domain__growth-area${colClass}`}
                  >
                    {area.title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (props.domain.additional_reading) {
    sectionLinkNodes.push(
      <div
        key="nav-link-4"
        id="nav-link-4"
        onClick={() => scrollToSection(4)}
        className="tc__link strategy-v4__side-nav-row"
      >
        Additional Reading
      </div>
    );
    addlReadingSection = (
      <div id="section-4" className="domain__section domain__section-zebra">
        <div className="row row--1200 domain__section-row-mobile">
          <div className="cute-8-desktop center-desktop cute-12-phone">
            <h2 className="tc__headline-3 domain__section-headline">
              Additional Reading
            </h2>
            <AbridgeText
              maxHeight={240}
              isExpandable={true}
              greyFade={true}
              analyticsCategory="additional_reading_section"
              analyticsLabel="from_individual_learning_domain"
              updateSectionExpandToggleCount={
                props.updateSectionExpandToggleCount
              }
            >
              <PurifiedHTML
                className="ckeditor__output"
                content={props.domain.additional_reading}
              />
            </AbridgeText>
          </div>
        </div>
      </div>
    );
  }
  // this section is hidden for participants and partner leaders
  if (!Auth.isEnterprise(props.user)) {
    sectionLinkNodes.push(
      <div
        key="nav-link-5"
        id="nav-link-5"
        onClick={() => scrollToSection(5)}
        className="tc__link strategy-v4__side-nav-row"
      >
        How BetterLesson Can Help
      </div>
    );
    contactUsSection = (
      <div id="section-5" className="domain__section domain__section--graphic">
        <div className="row row--1200 domain__section-row-mobile">
          <div className="cute-8-desktop center-desktop cute-12-phone">
            <h2 className="tc__headline-3 domain__section-headline">
              How BetterLesson Can Help
            </h2>
            <div className="tc__body-copy domain__section-subheadline">
              We provide personalized, continuous support to help teachers and
              instructional leaders make growth towards their professional
              goals.
            </div>
            <a
              href="/schedule-a-conversation/?from=individual_learning_domain"
              target="_blank"
              rel="noopener"
              className="bl4__btn bl4__btn--blue-border"
            >
              Get in Touch
            </a>
          </div>
        </div>
      </div>
    );
  }

  const heroImagePath =
    process.env.REACT_APP_LAB_URL +
    '/legacy-images/learning_domains/small/' +
    domainIdForHeroImage +
    '.webp';
  return (
    <div className="tc__page domain">
      <Header page="Domain" {...props} hideWidget={true} />
      {alertNode}
      {modalNode}

      <WindowScrollElementFixer
        positionToClass="domain__container"
        positionThisClass="domain__header"
        fixedHero={true}
        updateToElement={true}
        updateFlag={JSON.stringify(props.ui.sectionExpandToggleCount)}
      />

      <div className="domain__container">
        <div
          className="domain__header bl4__header--sticky"
          style={headerBackgroundStyle}
        >
          <div className="row row--1200 strategies__header-row">
            <div className="cute-8-desktop center-desktop cute-12-phone strategies__header-body">
              <div className="domain__header-body-text">
                <div
                  className={`domain__header-icon tc-v4-domain-${domainIdForIcon}`}
                ></div>
                <div>
                  <h1 className="tc__headline-2 domain__header-headline">
                    {props.domain.title}
                  </h1>
                  <div className="tc__body-copy domain__header-subheadline">
                    {props.domain.tagline}
                  </div>
                </div>
              </div>
              <button
                onClick={handleBrowseStrategies}
                className="bl4__btn bl4__btn--blue domain__header-button show-desktop hide-phone"
              >
                Browse Strategies
              </button>
            </div>
            <div className="strategies__share">
              <div className="strategies__share-text">Share</div>
              <div className="strategies__share-icons">
                <div className="strategies__share-icon">
                  <FacebookShareButton
                    url={shareUrl}
                    hashtag="#BetterLesson"
                    beforeOnClick={() => handleSocialShare('facebook')}
                  >
                    <span className="tc-facebook"></span>
                  </FacebookShareButton>
                </div>
                <div className="strategies__share-icon">
                  <TwitterShareButton
                    url={shareUrl}
                    hashtags={['BetterLesson']}
                    beforeOnClick={() => handleSocialShare('twitter')}
                  >
                    <span className="tc-twitter"></span>
                  </TwitterShareButton>
                </div>
                <div className="strategies__share-icon">
                  <PinterestShareButton
                    url={shareUrl}
                    media={heroImagePath}
                    beforeOnClick={() => handleSocialShare('pinterest')}
                  >
                    <span className="tc-pinterest"></span>
                  </PinterestShareButton>
                </div>
                <div
                  onClick={() => props.ui.shareModal.open({})}
                  className="tc-v4-link strategies__share-icon"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="domain__content">
          <WindowScrollElementFixer
            positionToClass="domain__sections"
            positionThisClass="domain__side-nav"
            positionThisContainerClass="domain__side-nav-container"
            accountForElemClass="domain__header"
            topMargin={20}
            updateFlag={JSON.stringify(props.ui.sectionExpandToggleCount)}
          />
          <ActivateOnScrollToElement
            elementCount={5}
            elementUpperBuffer={300}
            elementIdPrefix="section-"
            targetIdPrefix="nav-link-"
          />

          <div className="domain__side-nav-container show-desktop hide-phone">
            <div className="domain__side-nav-row">
              <div className="domain__side-nav bl4__sidebar--sticky tc__body-copy">
                {sectionLinkNodes}
              </div>
            </div>
          </div>

          <div className="domain__sections">
            {aboutSection}
            {strategiesSection}
            {masterTeachersSection}
            {growthAreasSection}
            {addlReadingSection}
            {contactUsSection}
          </div>
        </div>
      </div>
    </div>
  );
};

Domain.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object,
  domain: PropTypes.object.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
};

export default Domain;
