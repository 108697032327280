// @ts-nocheck FIXME
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import Analytics from './../../../components/Analytics';
import Utils from './../../../components/StaticUtils';
import ScaleCenterImage from './../../ScaleCenterImage';
import Video from './../../Utilities/Video';
import FullScreen from './ArtifactOverlay/FullScreen';
import PositionWindow from './ArtifactOverlay/PositionWindow';

const ArtifactOverlay = (props) => {
  // fullscreen check
  let fullScreenAvailable = false;
  if (
    document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnable
  ) {
    fullScreenAvailable = true;
  }

  // create image instance
  const checkViablePreview = () => {
    if (!props.content.preview.length) {
      return false;
    }

    const image = new Image();
    image.src = props.content.preview[0];

    let viablePreview = false;
    image.onload = function () {
      viablePreview = true;
    };

    return viablePreview;
  };

  const viablePreview = checkViablePreview();

  /***** event listeners ******/
  const handleCloseOverlay = (e) => {
    e.preventDefault();
    props.hideArtifact();
  };

  const handleFullScreenClick = () => {
    // full screen the resource
    const resourceContainer = document.getElementById(
      'artifact-overlay__fullscreen-container'
    );
    Utils.runPrefixMethod(resourceContainer, 'RequestFullScreen');
  };

  const handleUrlClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Analytics.logEvent({
      category: 'artifact',
      action: 'external_link',
      label: props.analyticsLabel,
    });

    window.open(props.link_url, '_blank');
  };

  const handleDownloadClick = (e) => {
    e.preventDefault();
    props.downloadArtifact();
  };

  const handleNextPageClick = (e) => {
    e.preventDefault();
    props.showNextPage();
  };

  const handlePreviousPageClick = (e) => {
    e.preventDefault();
    props.showPreviousPage();
  };

  let previewNode = null;
  let fullScreenPreviewNode = null;
  let paginationNode = null;
  let fullScreenBtnNode = null;
  let fullScreenNode = null;

  const PositionWindowNode = PositionWindow(<noscript />, props.scrollPosition);

  switch (props.preview_type) {
    case 'movie':
      previewNode = (
        <Video
          preview={props.content.preview[0]}
          formats={props.content.formats}
          height={500}
          width={885}
        />
      );
      break;

    case 'image':
      previewNode = (
        <div className="artifact-overlay__image--none">
          <div
            className={
              'artifact-overlay__preview-icon artifact__preview-icon file-type-icon ext-' +
              props.extension
            }
          ></div>
        </div>
      );

      if (viablePreview) {
        previewNode = (
          <img
            src={props.content.preview[0]}
            id="artifact-overlay__preview"
            className="artifact-overlay__image tc__visible artifact-overlay__image--max-width i-i"
          />
        );

        if (fullScreenAvailable) {
          // attach sorting functionality to entries
          const FullScreenImage = FullScreen(
            <img
              className="artifact-overlay__fullscreen-image"
              src={props.content.preview[0]}
            />
          );

          fullScreenNode = (
            <div
              id="artifact-overlay__fullscreen-container"
              className="artifact-overlay__fullscreen-container"
            >
              <FullScreenImage />
            </div>
          );
          fullScreenBtnNode = (
            <div
              className="artifact__fullscreen"
              onClick={handleFullScreenClick}
            >
              <div className="artifact__action-icon tc-full-screen"></div>
              <div className="tc__link">Full Screen</div>
            </div>
          );
        }
      }
      break;

    case 'document':
      previewNode = (
        <div className="artifact-overlay__image--none">
          <div
            className={
              'artifact-overlay__preview-icon artifact__preview-icon file-type-icon ext-' +
              props.extension
            }
          ></div>
        </div>
      );

      if (viablePreview) {
        previewNode = [];
        fullScreenPreviewNode = [];

        if (props.content.preview.length > 1) {
          let maxPreviewLength = 25;
          if (props.content.preview.length < 25) {
            maxPreviewLength = props.content.preview.length;
          }

          // dont show the next and last buttons if there are no more items to show
          const nextClass =
            props.activeArtifactPage == maxPreviewLength ? ' tc__no-op' : '';
          const lastClass = props.activeArtifactPage == 1 ? ' tc__no-op' : '';

          paginationNode = (
            <div>
              <div className="artifact-overlay__paging-bar"></div>
              <div className="artifact-overlay__page-controls tc__body-copy">
                <div
                  className={'page-control page-control__left' + lastClass}
                  onClick={handlePreviousPageClick}
                >
                  <div className="tc-arrow"></div>
                </div>
                <div className="page-control page-control__current">
                  {props.activeArtifactPage} of {maxPreviewLength}
                </div>
                <div
                  className={'page-control page-control__right' + nextClass}
                  onClick={handleNextPageClick}
                >
                  <div className="tc-arrow"></div>
                </div>
              </div>
            </div>
          );
        }

        for (let i = 0; props.content.preview.length > i; i++) {
          const displayPageClass =
            i + 1 == props.activeArtifactPage ? ' tc__visible' : ' tc__hidden';
          const imagePreviewId = i == 0 ? 'artifact-overlay__preview' : '';

          if (props.artifactArtifactPage - 1 == i) {
            previewNode.push(
              <img
                key={'doc-page-' + i}
                src={props.content.preview[i]}
                className="artifact-overlay__image--full-width i-doc"
              />
            );
            fullScreenPreviewNode.push(
              <img
                key={'doc-fullscreen-page-' + i}
                className="artifact-overlay__fullscreen-image"
                src={props.content.preview[i]}
              />
            );
          } else {
            previewNode.push(
              <img
                id={imagePreviewId}
                key={'doc-page-' + i}
                src={props.content.preview[i]}
                className={'artifact-overlay__image' + displayPageClass}
              />
            );
            fullScreenPreviewNode.push(
              <img
                key={'doc-fullscreen-page-' + i}
                className={
                  'artifact-overlay__fullscreen-image' + displayPageClass
                }
                src={props.content.preview[i]}
              />
            );
          }

          const FullScreenImages = FullScreen(
            <div>{fullScreenPreviewNode}</div>
          );

          fullScreenNode = (
            <div
              id="artifact-overlay__fullscreen-container"
              className="artifact-overlay__fullscreen-container"
            >
              <FullScreenImages />
              {paginationNode}
            </div>
          );
        }

        if (fullScreenAvailable) {
          fullScreenBtnNode = (
            <div
              className="artifact__fullscreen"
              onClick={handleFullScreenClick}
            >
              <div className="artifact__action-icon tc-full-screen"></div>
              <div className="tc__link">Full Screen</div>
            </div>
          );
        }
      }
      break;
  }

  // only show the artifact type flag if the artifact has a type associated with it
  let artifactTypeNode = null;
  if (props.type_name) {
    artifactTypeNode = (
      <div className="artifact__type">
        <div className="artifact__type-caption">{props.type_name}</div>
        <div className="artifact__flag-end artifact__flag-end--overlay tc-flag-end"></div>
      </div>
    );
  }
  // set up default user photo
  let authorImageNode = (
    <div className="artifact-overlay__author-image-mask artifact-overlay__author-image--none">
      <img
        className="artifact-overlay__author-image"
        src="/legacy-images/default-user.svg"
      />
    </div>
  );

  // if content discovery artifact do not default to author icon
  if (props.cd_artifact) {
    authorImageNode = null;
  }

  // if its a tc artifact set up the correct nodes to display
  let addedByNode = null;
  if (props.owner) {
    if (props.owner.photo) {
      authorImageNode = (
        <ScaleCenterImage
          src={props.owner.photo}
          className="artifact-overlay__author-image"
        />
      );
    }

    addedByNode = (
      <div className="artifact-overlay__added-by">
        Added by {props.owner.name}
        &nbsp;on {dateformat(props.created, 'mm/dd/yyyy')}
      </div>
    );
  }

  // if the artifact is downloadable display the button and the user is not public or bl
  let downloadBtnNode = null;
  const downloadSpinner = props.artifactDownloading ? (
    <div className="littlespinner"></div>
  ) : null;
  if (props.preview_type != 'movie' && props.download_url && !props.link_url) {
    downloadBtnNode = (
      <div className="artifact__download" onClick={handleDownloadClick}>
        <div className="artifact__action-icon tc-download"></div>
        <div className="tc__link">Download</div>
        {downloadSpinner}
        <div className="tc__error artifact__download_error">
          {props.downloadError}
        </div>
      </div>
    );
  }

  // setup containder for artifact actions
  const actionsNode = (
    <div className="artifact-overlay__actions hide-phone">
      {downloadBtnNode}
      {fullScreenBtnNode}
    </div>
  );

  const artifactDetailsNode = (
    <div className="artifact-overlay__details-container">
      {authorImageNode}
      <div className={'artifact-overlay__details'}>
        {artifactTypeNode}
        {!props.link_url ? (
          <div>
            <div
              className={
                'artifact-overlay__file-icon file-type-icon ext-' +
                props.extension
              }
            ></div>
            <div className="artifact-overlay__file-title tc__headline-5">
              {props.filename}
            </div>
          </div>
        ) : (
          <div>
            <div
              className={'artifact-overlay__file-icon file-type-icon ext-link'}
            ></div>
            <div className="artifact-overlay__file-title tc__headline-5">
              <a
                className="tc__link"
                href="#"
                onClick={handleUrlClick}
                target="_new"
              >
                {props.link_url}
              </a>
            </div>
          </div>
        )}
        {addedByNode}

        <div className="artifact-overlay__view-details tc__link">
          View Details
        </div>
        {actionsNode}
      </div>
      <div className="artifact-overlay__notes tc__body-copy artifact-overlay__notes--mt">
        {props.notes}
      </div>
    </div>
  );

  const comments =
    props.comments &&
    props.comments.map(function (comment, i) {
      let commentAuthorPhotoNode = (
        <div className="artifact-overlay__author-image-mask artifact-overlay__author-image-mask--none">
          <img
            className="artifact-overlay__author-image"
            src="/legacy-images/default-user.svg"
          />
        </div>
      );

      if (comment.photo) {
        commentAuthorPhotoNode = (
          <ScaleCenterImage
            src={comment.photo}
            className="artifact-overlay__author-image"
          />
        );
      }

      return (
        <div
          className="artifact-overlay__comment-container"
          key={'comment-' + i}
        >
          <div className="artifact-overlay__comment-seperator show-phone hide-desktop"></div>
          <div className="artifact-overlay__comment-image">
            {commentAuthorPhotoNode}
          </div>
          <div className="artifact-overlay__comment-data">
            <span className="artifact-overlay__comment-user tc__headline-5">
              {comment.name}
            </span>
            <span className="artifact-overlay__comment-date">
              <TimeAgo date={comment.created} />
            </span>
            <div className="artifact-overlay__comment-text tc__body-copy">
              {comment.data}
            </div>
          </div>
        </div>
      );
    });
  let commentsNode = null;
  if (props.comments && props.comments.length) {
    commentsNode = <div className="artifact-overlay__comments">{comments}</div>;
  }

  return (
    <div>
      <div className="modal__close--artifact" onClick={handleCloseOverlay}>
        <div className="tc-x"></div>
      </div>
      <div
        className="modal__page-overlay modal__page-overlay--artifact-overlay"
        onClick={handleCloseOverlay}
      ></div>
      <div className="modal--absolute modal--artifact-overlay">
        <div className="mt-artifact-overlay">
          <div className="artifact-overlay__artifact">
            <div className="artifact-overlay__image-container">
              {previewNode}
            </div>
            {paginationNode}
          </div>
          {fullScreenNode}
          {artifactDetailsNode}
          {commentsNode}
        </div>
      </div>
      <PositionWindowNode />
    </div>
  );
};

ArtifactOverlay.propTypes = {
  downloadArtifact: PropTypes.func,
  artifactDownloading: PropTypes.bool,
  downloadError: PropTypes.string,
  showPreviousPage: PropTypes.func,
  showNextPage: PropTypes.func,
  activeArtifactPage: PropTypes.number,
  scrollPosition: PropTypes.number,
  hideArtifact: PropTypes.func.isRequired,
  analyticsLabel: PropTypes.string,
};

export default ArtifactOverlay;
