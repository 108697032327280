import Cookies, { CookieSetOptions } from 'universal-cookie';

const ONE_MONTH = 3600 * 24 * 30;

function shouldUseSecureCookies(): boolean {
  if (process.env.REACT_APP_REQUIRE_SECURE_COOKIES) {
    return process.env.REACT_APP_REQUIRE_SECURE_COOKIES !== 'false';
  }
  return true;
}

const DEFAULT_OPTIONS: CookieSetOptions = Object.freeze({
  domain: process.env.REACT_APP_DOMAIN,
  secure: shouldUseSecureCookies(),
  path: '/',
  maxAge: ONE_MONTH,
});

class CookieStorage extends Cookies {
  get length(): number {
    return this.getAll().length;
  }

  setItem(
    key: string,
    value: string,
    option: CookieSetOptions = DEFAULT_OPTIONS
  ): void {
    this.set(key, value, option);
  }

  getItem(key: string): string | null {
    return this.get(key, { doNotParse: true });
  }

  removeItem(key: string, option: CookieSetOptions = DEFAULT_OPTIONS): void {
    this.remove(key, option);
  }

  key(index: number): string | null {
    const keys = Object.keys(this.getAll());
    return keys.length && keys[index] ? keys[index] : null;
  }

  clear(): void {
    Object.keys(this.getAll()).forEach((key) => {
      this.removeItem(key);
    });
  }
}

export default new CookieStorage();
