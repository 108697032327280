// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import React from 'react';
import StrategyApi from './../../../apis/StrategyApi';
import Analytics from './../../../components/Analytics';
import Utils from './../../../components/DynamicUtils';
import LayerLink from './../../LayerManager/LayerLink';
import CoachPinningModal from './../../Modals/CoachPinning';
import CustomStrategyDialogue from './../Strategy/CustomStrategyDialogue';
import CoachAddChallengeDialogue from './CoachAddChallengeDialogue';

// shows the current state of the teaching challenge in the user's growth map -
// change the state by clicking on the component
class ChallengeStatus extends React.Component {
  static contextType = LegacyLabContext;

  state = {
    challengeStatus: this.props.challengeStatus,
    statusAnimating: false,
  };

  handleStateClick = () => {
    this.setState({
      challengeStatus: 'loading',
    });
    // Create an Analytics event
    const analyticsEvent = {
      category: 'teaching_challenge',
      label: this.props.analyticsTrackingLabel,
    };

    // add the challenge to the user's growth map if not already added
    if (!this.state.challengeStatus) {
      analyticsEvent.action = 'add_to_map';
      StrategyApi.addChallenge({
        userId: this.props.user.id,
        challengeId: this.props.challenge.id,
      })
        .then(
          function () {
            this.setState({
              challengeStatus: 'added',
              statusAnimating: true,
            });
          }.bind(this)
        )
        .catch(
          function () {
            this.props.onShowAlert(
              'error',
              'Sorry, there was a problem adding this challenge. Please try again later.'
            );
            // Reset the challenge status:
            this.setState({
              challengeStatus: this.props.challengeStatus,
              statusAnimating: false,
            });
          }.bind(this)
        );

      // otherwise, activate the challenge for the user
    } else if (this.state.challengeStatus === 'added') {
      analyticsEvent.action = 'activate';
      StrategyApi.activateChallenge({
        userId: this.props.user.id,
        challengeId: this.props.challenge.id,
      })
        .then(
          function () {
            this.setState({
              challengeStatus: 'activated',
              statusAnimating: true,
            });
          }.bind(this)
        )
        .catch(
          function () {
            this.props.onShowAlert(
              'error',
              'Sorry, there was a problem activating this challenge. Please try again later.'
            );
            // Reset the challenge status:
            this.setState({
              challengeStatus: this.props.challengeStatus,
              statusAnimating: false,
            });
          }.bind(this)
        );
    }

    Analytics.logEvent(analyticsEvent);

    this.setState({
      statusAnimating: false,
    });
  };

  render() {
    // if the user is not a TC Teacher or TC Coach, return nothing:
    if (
      !Utils.arrayContainsAnyItemInAnotherArray(this.props.user.auth_roles, [
        'TC_TEACHER',
        'BL_SERVICES_MANAGER',
      ])
    ) {
      return null;
    }

    // can pass in extra classes for the top level of the component
    const extraClass = this.props.className ? this.props.className : '';

    // in some instances (i.e. on mobile) we want the text to be shortened
    const useShortLabels = !!this.props.useShortLabels;

    // and they use a dialog to choose which teachers' growth maps to add the challenge to
    const collaboratingCaption = Utils.isServicesManager.call(this.context)
      ? ''
      : 'Collaborate with your coach to ';
    const customStrategyLabel = Utils.isServicesManager.call(this.context)
      ? 'Create a custom strategy'
      : 'create a custom strategy.';

    let customStrategyLink;

    if (this.props.showCustomStrategyLink) {
      customStrategyLink = (
        <div className="browser-v2__custom-strategy-link">
          <span>{collaboratingCaption}</span>
          <LayerLink
            layer="new"
            className="browser-v2__custom-strategy-layer-link tc__link icon"
            component={CustomStrategyDialogue}
            wrapper={CoachPinningModal}
            params={{
              challenge: this.props.challenge,
              user: this.props.user,
              onShowAlert: this.props.onShowAlert,
            }}
          >
            {customStrategyLabel}
          </LayerLink>
        </div>
      );
    }

    // coaches always see "Add to Map"
    let stateNode = null;
    if (Utils.isServicesManager.call(this.context)) {
      stateNode = (
        <LayerLink
          params={{
            challenge: this.props.challenge,
            user: this.props.user,
            onShowAlert: this.props.onShowAlert,
          }}
          layer="new"
          className="browser-v2__challenge-state-layer-link tc__link icon"
          component={CoachAddChallengeDialogue}
          wrapper={CoachPinningModal}
        >
          <div
            className={
              'browser-v2__challenge-state browser-v2__challenge-state--clickable ' +
              extraClass
            }
          >
            <span className="browser-v2__challenge-state-icon browser-v2__challenge-state-icon--plus">
              +
            </span>
            <span className="browser-v2__challenge-state-caption">
              {useShortLabels ? 'Add to Map' : 'Add to Growth Map'}
            </span>
          </div>
        </LayerLink>
      );
    } else {
      // set up the state of the challenge for the user
      const statusCaptionClass = this.state.statusAnimating
        ? ' animated  fadeInUp'
        : '';

      switch (this.state.challengeStatus) {
        case 'added':
          customStrategyLink = null;
          stateNode = (
            <div
              className={
                'browser-v2__challenge-state browser-v2__challenge-state--clickable ' +
                extraClass
              }
              onClick={this.handleStateClick}
            >
              <span className="tc-playlist-add browser-v2__challenge-state-icon"></span>
              <div
                className={
                  'browser-v2__challenge-state-caption' + statusCaptionClass
                }
              >
                {useShortLabels ? 'Activate' : 'Activate Challenge'}
              </div>
            </div>
          );
          break;
        case 'activated':
          stateNode = (
            <div
              className={
                'browser-v2__challenge-state browser-v2__challenge-state--active ' +
                extraClass
              }
            >
              <span className="tc-playlist-play browser-v2__challenge-state-icon"></span>
              <div
                className={
                  'browser-v2__challenge-state-caption' + statusCaptionClass
                }
              >
                {useShortLabels ? 'Active' : 'Challenge Active'}
              </div>
            </div>
          );
          break;
        case 'completed':
          customStrategyLink = null;
          stateNode = (
            <div
              className={
                'browser-v2__challenge-state browser-v2__challenge-state--complete ' +
                extraClass
              }
            >
              <span className="tc-check browser-v2__challenge-state-icon"></span>
              <div
                className={
                  'browser-v2__challenge-state-caption' + statusCaptionClass
                }
              >
                {useShortLabels ? 'Completed' : 'Challenge Completed'}
              </div>
            </div>
          );
          break;
        case 'loading':
          customStrategyLink = null;
          stateNode = (
            <div className={'browser-v2__challenge-state ' + extraClass}>
              <span className="browser-v2__challenge-state-icon littlespinner"></span>
              <div className="browser-v2__challenge-state-caption"></div>
            </div>
          );
          break;
        default:
          customStrategyLink = null;
          stateNode = (
            <div
              className={
                'browser-v2__challenge-state browser-v2__challenge-state--clickable ' +
                extraClass
              }
              onClick={this.handleStateClick}
            >
              <span className="browser-v2__challenge-state-icon browser-v2__challenge-state-icon--plus">
                +
              </span>
              <div
                className={
                  'browser-v2__challenge-state-caption' + statusCaptionClass
                }
              >
                {useShortLabels ? 'Add to Map' : 'Add to Growth Map'}
              </div>
            </div>
          );
      }
    }
    return (
      <div>
        {stateNode}
        {customStrategyLink}
      </div>
    );
  }
}

export default ChallengeStatus;
