// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';
import FilterCrumbs from './modules/FilterCrumbs';
import Filters from './modules/Filters';
import InfiniteScroll from './modules/InfiniteScroll';
import RecommendModal from './modules/RecommendModal';
import ResultCapsule from './modules/ResultCapsule';
import Sorter from './modules/Sorter';

const Favorite = (props) => {
  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }
  let sorterNode = null;

  let searchUrl = process.env.REACT_APP_TEACHING_URL + '/search';
  // if the current user is looking at another user's favorites,
  // clicking on Search will limit the results to match what the owning user can see
  if (props.user.id !== props.pageUserId) {
    searchUrl += `/${props.pageUserId}`;
  }
  let resultsNode = (
    <div className="search__no-results">
      <div className="search__no-results-copy">
        <div className="v5__heading-5">Go ahead. You can play favorites.</div>
        <div className="v5__body-copy select-resource__favorites-row">
          <NavigationLink url={searchUrl} className="v5__link">
            Explore 20,000+ lessons and strategies
          </NavigationLink>{' '}
          and save the ones you love to your Favorites.
        </div>
      </div>
      <img
        className="search__no-results-img"
        src="/legacy-images/v5/favorites.png"
        alt="You have no favorites yet."
      />
    </div>
  );
  let filters;
  let mobileFilters;
  let resultNode;
  if (props.results && props.results.length) {
    sorterNode = (
      <div className="show-desktop hide-phone">
        <Sorter orderFavorites={props.orderFavorites} />
      </div>
    );
    resultNode = props.results.map((result, i) => {
      return (
        <ResultCapsule
          key={'search-result-strategy-' + result.id + '-' + i}
          {...result}
          addFavorite={props.addFavorite}
          removeFavorite={props.removeFavorite}
          recommend={props.ui.recommendModal.open}
          user={props.user}
          updateFilters={props.updateFilters}
          searchContext={props.searchContext}
        />
      );
    });
    resultsNode = <div className="search__capsules">{resultNode}</div>;
    filters = <Filters {...props} />;
    mobileFilters = (
      <div className="search__mobile-options">
        <button
          className="bl4__btn bl4__btn--blue  search__filter-button"
          onClick={() => props.ui.filtersModal.open()}
        >
          Filter
        </button>
        <Sorter orderFavorites={props.orderFavorites} />
      </div>
    );
  } else if (props.searchSuccess === false) {
    resultNode = (
      <div className="tc__left-text tc__search-failure">
        <div className="tc__body-copy">
          Your favorites are temporarily unavailable. (We blame the cat.)
        </div>
        <br />
        <div className="tc__body-copy">
          Thanks for your patience as we tidy up this issue.
        </div>
        <img src="/legacy-images/404-pets.png" className="tc__merge-image" />
      </div>
    );
    resultsNode = <div className="search__capsules">{resultNode}</div>;
  }

  // set up any opened modal
  let modalNode = null;
  if (props.ui.recommendModal.opened) {
    modalNode = (
      <RecommendModal
        {...props.ui.recommendModal}
        participants={props.ui.participants}
        recommend={props.addFavorite}
        selectRecommendElement={props.selectRecommendElement}
        getRecommendElement={props.getRecommendElement}
        user={props.user}
      />
    );
  }

  const filtersColumnWidth =
    props.results.length > 0 ? 'cute-2-desktop' : 'cute-1-desktop';
  const headlineName =
    props.user.id === props.pageUserId
      ? 'My'
      : `${props.page_user.first_name}'s`;

  return (
    <div className="tc__page">
      <div className="bl4__page-background search__background" />
      <Header page="Favorites" {...props} />
      {alertNode}
      {modalNode}
      <div className="row row--1200">
        <div
          className={
            filtersColumnWidth + ' cute-12-phone bl4__filters-container left'
          }
        >
          {filters}
        </div>
        <div className="cute-10-desktop cute-12-phone bl4-results left">
          <div>
            <h5 className="search__headline tc__headline-5">
              {headlineName} Favorites
            </h5>
          </div>
          {mobileFilters}
          <FilterCrumbs {...props} />
          {sorterNode}
          {resultsNode}
          <InfiniteScroll
            scrollCallback={props.loadMoreResults}
            loading={props.resultLoader.isLoadingMore}
            canScroll={props.resultLoader.canLoadMore}
          />
        </div>
      </div>
    </div>
  );
};

Favorite.propTypes = {
  ui: PropTypes.object.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  loadMoreResults: PropTypes.func.isRequired,
  pageUserId: PropTypes.number.isRequired,
  searchSuccess: PropTypes.bool,
};

export default Favorite;
