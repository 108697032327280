// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import FormField from '@legacy-components/react/V5/Common/FormField';
import propTypes from 'prop-types';
import React from 'react';

const ShareContentModal = (props) => {
  const [contentLink, setContentLink] = React.useState(props.contentLink);
  const [contentLinkError, setContentLinkError] = React.useState('');

  // disable save button when there's nothing in the input form
  const [saveDisabled, setSaveDisabled] = React.useState(!props.contentLink);

  const handleChange = (e) => {
    setContentLinkError('');
    setContentLink(e.target.value);
    if (e.target.value === '') {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (!Utils.isUrl(contentLink)) {
      setContentLinkError('This is not a valid url.');
      return;
    }
    props.confirm(contentLink, 'save');
  };

  const handleDelete = (e) => {
    e.preventDefault();
    props.confirm('', 'delete');
  };

  const saveBtnProps = saveDisabled ? { disabled: true } : null;

  return (
    <ActionModal
      headlineText="Share Content With Partner Leader"
      close={props.close}
      spaceFromTop={20}
    >
      <div className="edit-event__share-content-modal-title-container">
        <span className="tc-presentation edit-event__share-content-modal-icon"></span>
        <div className="v5__heading-5">
          <span className="edit-event__share-content-modal-title">
            {`Event - ${props.eventName}`}
          </span>
        </div>
      </div>

      <div className="v5__body-copy edit-event__share-content-modal-instruction">
        Posting new content will appear on the event portion of the content
        leader’s dashboard.
      </div>
      <FormField
        error={contentLinkError}
        defaultValue={contentLink}
        placeholder="Paste link to slide deck here..."
        onChange={handleChange}
      />

      <div className="edit-event__share-content-modal-btn-row">
        {props.contentLink && (
          <button className="v5__btn v5__btn--red" onClick={handleDelete}>
            Delete
          </button>
        )}
        <button
          className="v5__btn v5__btn--blue edit-event__share-content-modal-btn-save"
          onClick={handleSave}
          {...saveBtnProps}
        >
          Save
        </button>
      </div>
    </ActionModal>
  );
};

ShareContentModal.propTypes = {
  eventName: propTypes.string.isRequired,
  contentLink: propTypes.string,
  confirm: propTypes.func.isRequired,
  close: propTypes.func.isRequired,
};

export default ShareContentModal;
