// @ts-nocheck FIXME

const SurveyResults = (props) => {
  const answerMap = {
    'Not at all': 0,
    'A little': 1,
    Moderately: 2,
    'Very much': 3,
    'To a great extent': 4,
  };

  const surveyResponsesNode = [];
  if (props.response_data) {
    Object.keys(props.response_data).forEach((responseKey) => {
      const surveyResponse = props.response_data[responseKey];

      let firstPoint = answerMap[surveyResponse.start];
      let secondPoint = answerMap[surveyResponse.now];
      let barColorClass = '';
      if (answerMap[surveyResponse.start] > answerMap[surveyResponse.now]) {
        firstPoint = answerMap[surveyResponse.now];
        secondPoint = answerMap[surveyResponse.start];
        barColorClass = 'survey__response-bar--backwards';
      }

      const styles = { left: `${firstPoint * 25}%` };

      // in the last position add margin keep it in the container
      if (styles.left === '100%') {
        styles.marginLeft = '-20px';
      }

      // the width of the bar is the ending point - the starting point * segment width
      if (surveyResponse.now) {
        styles.width = `${(secondPoint - firstPoint) * 25}%`;
      }

      // in the bar account for half of the starting point to center it
      if (
        parseFloat(styles.left) + parseFloat(styles.width) !== 100 &&
        styles.left !== '0%'
      ) {
        styles.marginLeft = '-10px';
      }

      const graph = (
        <div className="survey__response-graph">
          <div
            className={`survey__response-bar ${barColorClass}`}
            style={styles}
            data-test-id="selfAssesmentBar"
          />
        </div>
      );

      surveyResponsesNode.push(
        <div
          className="survey__response-container"
          key={`survey-response-${responseKey}`}
        >
          <div
            className="survey__response-question v5__heading-5"
            data-test-id="selfAssesmentQuestion"
          >
            {surveyResponse.question}
          </div>
          {graph}
          <div
            className="survey__response-options"
            data-test-id="selfAssesmentLegend"
          >
            <div className="survey__response-option survey__response-option--1">
              Not at all
            </div>
            <div className="survey__response-option survey__response-option--2">
              A little
            </div>
            <div className="survey__response-option survey__response-option--3">
              Moderately
            </div>
            <div className="survey__response-option survey__response-option--4">
              Very much
            </div>
            <div className="survey__response-option survey__response-option--5">
              To a great extent
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="hide-phone">
      <div className="survey__caption v5__quote-copy">
        Your growth from the start of the year to the present is represented in
        green
      </div>
      <div className="survey" data-test-id="selfAssesmentResponses">
        {surveyResponsesNode}
      </div>
    </div>
  );
};

export default SurveyResults;
