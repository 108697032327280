// @ts-nocheck FIXME
import moment from 'moment-timezone';
import NProgress from 'nprogress';
import propTypes from 'prop-types';
import React from 'react';
import ZenScroll from 'zenscroll';

// Global
import EventApi from '@legacy-components/apis/V5/EventApi';

import Utils from '@legacy-components/components/DynamicUtils';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';

// Common
import FormField from '@legacy-components/react/V5/Common/FormField';

import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';

// Api
import EventCapacityCalendarApi from '@legacy-components/apis/V5/EventCapacityCalendarApi';

// Local
import DayRow from './modules/DayRow';

import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import EditCapacitiesModal from './modules/EditCapacitiesModal';

export default function EventCapacityCalendar(props) {
  const lab = useLegacyLabContext();
  // fixed list of certifications to be filtered
  // id matches what's saved in the database, name is display name
  const certifications = [
    { id: 'LeadershipAdmin', name: 'Leadership Admin' },
    { id: 'ElementaryIMOUR', name: 'ElementaryIMOURMath' },
    { id: 'SecondaryIMOUR', name: 'SecondaryIMOURMath' },
    { id: 'OURELK-2', name: 'OURELK-2' },
    { id: 'OUREL3-8', name: 'OUREL3-8' },
    {
      id: 'CulturallyResponsiveTeachingLearning',
      name: 'CulturallyResponsiveTeachingLearning',
    },
    { id: 'InPersonFacilitation', name: 'In-Person' },
    { id: 'LWFacilitation', name: 'Learning Walk' },
  ];

  // The timezones need to be sorted otherwise they'll be listed in the order they were added
  const timeZones = props.timeZones.sort(Utils.sortTimeZones);

  const timeZoneOptions = timeZones.map((tz) => {
    return {
      label: tz.name,
      id: tz.id,
      description: tz.pytz.replace('_', ' '),
    };
  });

  // holds session data and current timezone data
  // since they always go together, pack them into one to avoid re-rendering children multiple times
  const [sessions, setSessions] = React.useState({
    sessions: props.sessions,
    timeZone:
      props.user.time_zone && props.user.time_zone.pytz_timezone
        ? {
            name: props.user.time_zone.pytz_timezone,
            id: props.user.time_zone.id,
          }
        : { name: 'US/Eastern', id: 1 },
  });

  // controls show/hide all events in a certain time slot
  const [showEventDetail, setShowEventDetail] = React.useState({
    date: null,
    hour: null,
    quarter: null,
  });

  // controls starting hour of 12-hour range, default 8 (options are 0 (12am), 8 (8am), 12 (12pm))
  const [startTimeRange, setStartTimeRange] = React.useState(8);

  // controls the staffing capacities for events,
  // both overall ("general") and per certification
  const [capacities, setCapacities] = React.useState(props.capacities);
  const [capacitiesModalOpened, setCapacitiesModalOpened] =
    React.useState(false);

  // track the active certification filter, options are 'all', 'none', and cert ids
  const [activeCert, setActiveCert] = React.useState('all');

  const [showLeftArrow, setShowLeftArrow] = React.useState(true);

  const [showRightArrow, setShowRightArrow] = React.useState(true);

  // use to track the selected timezone option
  const [selectedTimeZone, setSelectedTimeZone] = React.useState(
    timeZoneOptions.find((tz) => tz.id === sessions.timeZone.id)
  );

  // default number of weeks to be displayed
  const weeksToLoad = 16;

  const today = moment.tz(sessions.timeZone.name);

  // having date and hour and quarter as null means to hide event detail
  const handleShowEventDetail = (date = null, hour = null, quarter = null) => {
    if (
      showEventDetail.date === date &&
      showEventDetail.hour === hour &&
      showEventDetail.quarter === quarter
    ) {
      setShowEventDetail({ date: null, hour: null, quarter: null });
    } else {
      setShowEventDetail({ date: date, hour: hour, quarter: quarter });
    }
  };

  /**
   * given month string, scroll to the first/earliest day of the month if possible
   * @param {string} month in format of "month-year", e.g. "January-2021"
   */
  const scrollToMonth = (month) => {
    if (month === 'select') return;
    const monthMoment = moment(month, 'MMMM-YYYY');
    let firstDayOfMonth = '';
    if (
      monthMoment.year() === today.year() &&
      monthMoment.month() === today.month()
    ) {
      firstDayOfMonth = today.format('YYYY-MM-DD');
    } else {
      firstDayOfMonth = monthMoment.format('YYYY-MM-DD');
    }
    const element = document.getElementById(firstDayOfMonth);
    if (element) {
      ZenScroll.center(element, 400, 120);
    }
  };

  /**
   * given starting day and number of weeks, return an array of formatted week numbers
   * @param {object} startingDay moment object of the starting day
   * @param {int} numOfWeeks number of weeks to be generated, default 16 (4 months)
   * @returns {array} sorted by time weekNumbers in format of YYYY-w e.g. ['2021-52', '2022-1']
   */
  const generateWeeks = (startingDay, numOfWeeks = weeksToLoad) => {
    const weeks = [];
    const startingWeek = startingDay.week();
    const startingYear = startingDay.year();
    let weeksOfCurrYear = moment(`${startingYear}-12-31`).week();

    // if last day of the year is the first week of the next year, then go back one week
    if (weeksOfCurrYear === 1) {
      weeksOfCurrYear = moment(`${startingYear}-12-25`).week();
    }

    Utils.range(startingWeek, startingWeek + numOfWeeks).forEach((weekNum) => {
      if (weekNum <= weeksOfCurrYear) {
        weeks.push(`${startingYear}-${weekNum}`);
      } else {
        weeks.push(`${startingYear + 1}-${weekNum - weeksOfCurrYear}`);
      }
    });
    return weeks;
  };

  // change display time range and handle show/hide left/right arrows here
  const handleArrowClick = (direction) => {
    if (direction === 'left') {
      if (startTimeRange === 8) {
        setStartTimeRange(0);
        setShowLeftArrow(false);
      } else if (startTimeRange === 12) {
        setStartTimeRange(8);
        setShowRightArrow(true);
      }
    } else if (direction === 'right') {
      if (startTimeRange === 8) {
        setStartTimeRange(12);
        setShowRightArrow(false);
      } else if (startTimeRange === 0) {
        setStartTimeRange(8);
        setShowLeftArrow(true);
      }
    }
  };

  const handleSaveCapacities = (capacities) => {
    setCapacities(capacities);
    EventApi.setStaffingCapacities({ capacities: capacities });
  };

  /**
   * given number of weeks, populate array of month options
   * @param {int} weeks number of weeks (use weeks to stay consistent because elsewhere use weeks for range)
   * @returns array of formatted string of month options e.g. ['January 2021']
   */
  const generateMonthOptions = (weeks = weeksToLoad) => {
    const monthRanges = [];
    let prevMonth = null;
    for (let i = 0; i < weeks; i++) {
      const generatedMonth = moment
        .tz(today, sessions.timeZone.name)
        .add(i, 'week')
        .format('MMMM-YYYY');
      if (!prevMonth || prevMonth !== generatedMonth) {
        monthRanges.push(generatedMonth);
        prevMonth = generatedMonth;
      }
    }
    return monthRanges;
  };

  /**
   * given timezone object {id, description}
   * make api call to server, get data and handles setting data
   * @param {object} timezone object with same structure as the timezone selector
   */
  const fetchSessions = (timezone) => {
    NProgress.start();
    // update selector before fetching data so the UI feels faster
    setSelectedTimeZone(timezone);
    EventCapacityCalendarApi.getSessions(timezone.id)
      .progress((e) => {
        // update progress bar
        NProgress.set(e.loaded / e.total);
      })
      .then((response) => {
        if (response.page_data) {
          // close opening detail before update sessions
          handleShowEventDetail();
          setSessions({
            timeZone: {
              name: timezone.description.replace(' ', '_'),
              id: timezone.id,
            },
            sessions: response.page_data.sessions,
          });
        }
        // close progress bar
        NProgress.done();
      })
      .catch(() => {
        NProgress.done();
      });
  };

  // only fetch data when there's a new timezone
  const handleSelectTimeZone = (selected) => {
    if (selectedTimeZone) {
      setSelectedTimeZone(null);
    } else if (sessions.timeZone.id !== selected.id) {
      fetchSessions(selected);
    } else {
      setSelectedTimeZone(selected);
    }
  };

  // when the selector loses focus only fetch data when there's a new timezone
  const handleTimeZoneBlur = () => {
    if (selectedTimeZone && selectedTimeZone.id !== sessions.timeZone.id) {
      fetchSessions(selectedTimeZone);
    } else {
      // revert to previous selection if what's in the selector isn't valid
      setSelectedTimeZone(
        timeZoneOptions.find((tz) => tz.id === sessions.timeZone.id)
      );
    }
  };

  // set up any open modals
  let modalNode;
  if (capacitiesModalOpened) {
    modalNode = (
      <EditCapacitiesModal
        certifications={certifications}
        capacities={capacities}
        save={handleSaveCapacities}
        close={() => setCapacitiesModalOpened(false)}
      />
    );
  }

  // only Super Admins, Services Managers, and non-PSM User Managers
  // can edit the capacities
  let setCapacityNode;
  if (
    Utils.isAdmin.call(lab) ||
    Utils.isNonPsmUserManager.call(lab, props.user) ||
    Utils.isServicesManager.call(lab)
  ) {
    setCapacityNode = (
      <button
        className="v5__link events-cal__open-capacities-modal-link"
        onClick={() => setCapacitiesModalOpened(true)}
      >
        <span className="tc-v5-plus-solid events-cal__open-capacities-modal-icon"></span>
        Set Capacity
      </button>
    );
  }

  // build the calendar rows, each row contains one week
  const weekNodes = generateWeeks(today).map((yearWeek) => {
    const dayNodes = [];

    // get all the sessions that are in this week
    // yearWeek has format of 'YYYY-w' e.g. 2021-1, 2021-45
    const [year, week] = yearWeek.split('-');

    const sessionsByDate = sessions.sessions[yearWeek];

    // loop through each day within the week, starting from Sunday
    const day = moment(`${year}-${week}`, 'YYYY-w');
    Utils.range(0, 7).forEach((dayIndex) => {
      const dateMoment = moment.tz(
        day.format('YYYY-MM-DD'),
        sessions.timeZone.name
      );
      const date = dateMoment.format('YYYY-MM-DD');

      // only build days in the current and future
      if (today.isSameOrBefore(dateMoment, 'date')) {
        // build the column corresponding to this day
        dayNodes.push(
          <DayRow
            key={`week-${week}-day-${dayIndex}`}
            date={date}
            sessions={
              sessionsByDate && sessionsByDate[date] ? sessionsByDate[date] : []
            }
            startTimeRange={startTimeRange}
            certifications={certifications}
            activeCert={activeCert}
            showEventDetail={showEventDetail}
            handleShowEventDetail={handleShowEventDetail}
            capacities={capacities}
            timeZone={sessions.timeZone.name}
          />
        );
      }

      day.add(1, 'days');
    });

    return dayNodes;
  });

  const hourIndicatorNode = (
    <div className="events-cal__hour-indicator-container" key="hour-indicator">
      {showLeftArrow ? (
        <button
          className={'tc-v4-circle-arrow events-cal__left-arrow'}
          onClick={() => handleArrowClick('left')}
          key="left-arrow"
          data-testid="left-arrow"
        ></button>
      ) : (
        <div />
      )}

      {
        // populate hours indicators here
        Utils.range(startTimeRange, startTimeRange + 12).map((hour) => {
          let formattedHour = moment(hour.toString(), ['HH']).format('ha');
          if (formattedHour === '12pm') {
            formattedHour = 'Noon';
          }
          return (
            <div
              className="events-cal__hour-indicator-text"
              key={`hour-${hour}`}
            >
              {formattedHour}
            </div>
          );
        })
      }

      {showRightArrow ? (
        <button
          className={'tc-v4-circle-arrow events-cal__right-arrow'}
          onClick={() => handleArrowClick('right')}
          key="right-arrow"
          data-testid="right-arrow"
        ></button>
      ) : (
        <div />
      )}
    </div>
  );

  const spectrumNode = [];

  // populate the description text and fill in the first row of grid
  for (let i = 1; i < 11; i++) {
    // don't display any text at first
    let innerText = '';
    if (i === 10) {
      innerText = 'Over';
    } else if (i >= 5) {
      innerText = `>${i}0%`;
    }
    spectrumNode.push(
      <div className="events-cal__spectrum-text" key={`spec-text-${i}0`}>
        {innerText}
      </div>
    );
  }

  // populate the spectrum blocks and fill the second row of the grid
  for (let i = 1; i < 11; i++) {
    spectrumNode.push(
      <div
        className={`events-cal__quarter-block-${i}0pct events-cal__spectrum-block`}
        key={`spectrum-${i}0pct`}
      ></div>
    );
  }

  return (
    <div>
      <Header page="Event Capacity Calendar" {...props} />
      {modalNode}

      <div className="row v5__body-copy row--1200 events-cal__container">
        <div className="events-cal__sticky-container">
          <div className="cute-12-desktop">
            <div className="events-cal__heading-row">
              <h1 className="v5__heading-3 events-cal__heading">
                Upcoming Events
              </h1>
              {setCapacityNode}
            </div>
          </div>

          <div className="events-cal__selectors">
            <div className="cute-3-desktop events-cal__select-container">
              <label className="events-cal__select-label">Date Range:</label>
              <FormField
                type="select"
                onChange={(e) => scrollToMonth(e.target.value)}
                className="events-cal__select events-cal__select-date"
              >
                <option key="select" value="select">
                  Select
                </option>
                {generateMonthOptions(weeksToLoad).map((month) => {
                  return (
                    <option key={month} value={month}>
                      {month.replaceAll('-', ' ')}
                    </option>
                  );
                })}
              </FormField>
              <span className="tc-bold-arrow"></span>
            </div>

            <div className="cute-4-desktop events-cal__select-timezone">
              <label className="events-cal__select-label events-cal__select-timezone-label">
                Time Zone:
              </label>
              <MultiSelect
                className="events-cal__select-timezone-input"
                handleSelectOption={handleSelectTimeZone}
                selectedOptions={selectedTimeZone ? [selectedTimeZone] : []}
                dropdownOptions={timeZoneOptions}
                minCharCountForResults={0}
                stayOpenOnSelect={false}
                maximumSelectedOptionsCount={1}
                isSelectSearch={true}
                handleBlur={handleTimeZoneBlur}
                placeHolder={'Search for a Time Zone'}
                absolutePosition={true}
              />
            </div>

            <div className="cute-5-desktop events-cal__select-container">
              <label className="events-cal__select-label">Certification:</label>
              <FormField
                type="select"
                onChange={(e) => setActiveCert(e.target.value)}
                className="events-cal__select events-cal__select-cert"
              >
                <option key="all" value="all">
                  Show all
                </option>
                {certifications.map((cert) => {
                  return (
                    <option key={cert.id} value={cert.id}>
                      {cert.name}
                    </option>
                  );
                })}
              </FormField>
              <span className="tc-bold-arrow"></span>
            </div>
          </div>

          <div className="events-cal__spectrum-container">
            <div className="cute-4-desktop cute-4-desktop-offset events-cal__description">
              Each block below = 15 min increment
            </div>
            <div className="cute-3-desktop cute-1-desktop-offset right">
              <div className="events-cal__spectrum-grid">{spectrumNode}</div>
            </div>
          </div>

          {hourIndicatorNode}
        </div>
        {weekNodes}
      </div>
    </div>
  );
}

EventCapacityCalendar.propTypes = {
  sessions: propTypes.object.isRequired,
  timeZones: propTypes.array.isRequired,
};
