// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const PartnerLeaderSurveyDetails = (props) => {
  const lab = useLegacyLabContext();

  let surveySentTimeStamp = null;
  let sendSurveyBtnIsVisibleClass = '';
  let mailButton;
  if (Utils.isManager.call(lab) || Utils.isAdmin.call(lab)) {
    mailButton = (
      <div
        data-testid={'resendButton'}
        className="partner-survey__btn tc-resend-email v5__link"
        onClick={() => props.resendPartnerLeaderSurvey(props.leaderId)}
      />
    );
  }

  if (props.surveySent) {
    if (moment().isBefore(moment(props.surveySent).add(9, 'days'))) {
      sendSurveyBtnIsVisibleClass = 'partner-survey__copy--no-btn-visible';
      mailButton = null;
    }
    surveySentTimeStamp = (
      <div
        className={`partner-survey__copy v5__body ${sendSurveyBtnIsVisibleClass}`}
      >
        Last Sent:{' '}
        {moment(props.surveySent).tz(props.timeZone).format('M/D/YY h:mm A z')}
      </div>
    );
  }

  if (props.loading) {
    mailButton = <Spinner loading={props.loading} />;
  }
  return (
    <div className="partner-survey">
      {mailButton}
      {surveySentTimeStamp}
    </div>
  );
};

export default PartnerLeaderSurveyDetails;

PartnerLeaderSurveyDetails.propTypes = {
  leaderId: PropTypes.number.isRequired,
  timeZone: PropTypes.string.isRequired,
  resendPartnerLeaderSurvey: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  surveySent: PropTypes.string,
};
