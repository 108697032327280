// @ts-nocheck FIXME
const Content = {
  setupMetric: function (metricName, key_terms, defined_n) {
    let updatedMetricName = '';

    // add custom metric number
    if (defined_n) {
      updatedMetricName = metricName.replace('{N}', defined_n);
    } else {
      updatedMetricName = metricName;
    }

    // no key terms defined return the current string
    if (!key_terms) {
      return { __html: updatedMetricName };
    }

    // highlight key terms
    for (let i = 0; i < key_terms.length; i++) {
      const term = key_terms[i];
      updatedMetricName = updatedMetricName.replace(
        term,
        '<span class="tc__metric-highlight">' + term + '</span>'
      );
    }

    return { __html: updatedMetricName };
  },

  getFeedbackCaption: function (type, step, rating) {
    const feedbackCaption = {
      measurement: {
        teach: {
          1: 'Off my Game',
          2: 'Not too Shabby',
          3: 'Crushed it!',
        },
        measure: {
          1: 'Negative',
          2: '"Meh"(aka Neutral)',
          3: 'Positive',
        },
        learn: {
          1: 'No, I would not',
          2: 'Maybe. Not Sure.',
          3: 'You Betcha!',
        },
      },
      baseline: {
        teach: {
          1: 'Get Me Strategies Fast!',
          2: 'Unsure',
          3: 'Solid Starting Point!',
        },
        measure: {
          1: 'Mayday, Mayday!',
          2: 'Yep, need some support!',
          3: 'Wowed Me!',
        },
        learn: {
          1: 'No, I would not',
          2: 'Maybe. Not Sure.',
          3: 'You Betcha!',
        },
      },
    };

    if (rating === false) {
      return feedbackCaption[type][step];
    }

    return feedbackCaption[type][step][rating];
  },

  setupSGAPosition: function (count) {
    const position = {
      collumn: 3,
      offset: null,
    };
    if (count >= 4) {
      return position;
    }

    position.collumn = 4;
    position.offset = count < 3 ? (4 * (3 - count)) / 2 : null;

    return position;
  },

  checkForPreview: function (component, imgSrc) {
    // don't attempt to check preview when no imgSrc supplied
    if (!imgSrc) {
      return;
    }

    // create image instance
    const image = new Image();
    image.src = imgSrc;

    // check if loads: return true
    image.onload = function () {
      if (component.componentMounted) {
        component.setState({
          viablePreview: true,
        });
      }
    };
  },
};

export default Content;
