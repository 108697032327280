// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share';
import Analytics from './../../../components/Analytics';
import { Router } from './../../../components/Router';
import AbsoluteAlert from './../../Alerts/Absolute';
import WindowScrollElementFixer from './../Common/WindowScrollElementFixer';
import RecommendModal from './../Search/modules/RecommendModal';

import ShareModal from './modules/ShareModal';
import DomainRow from './modules/StrategiesDomainRow';

const Strategies = (props) => {
  const handleBrowseStrategies = () => {
    Router.route(
      process.env.REACT_APP_TEACHING_URL +
        '/search?types=strategy&from=instructional_strategies_browse'
    );
  };

  const handleSocialShare = (target) => {
    Analytics.logEvent({
      category: 'instructional_strategies',
      action: 'share_on_' + target,
      label: 'from_instructional_strategies_browse',
    });
  };

  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // set up current page url for social sharing icons
  const shareUrl = process.env.REACT_APP_TEACHING_URL + '/browse/strategies';

  // show a modal if one is opened
  let modalNode;
  if (props.ui.recommendModal.opened) {
    modalNode = (
      <RecommendModal
        {...props.ui.recommendModal}
        participants={props.ui.participants}
        recommend={props.addFavorite}
        selectRecommendElement={props.selectRecommendElement}
        getRecommendElement={props.getRecommendElement}
        user={props.user}
        analyticsLabel="from_instructional_strategies_browse"
      />
    );
  } else if (props.ui.shareModal.opened) {
    modalNode = (
      <ShareModal
        {...props.ui.shareModal}
        analyticsLabel="from_instructional_strategies_browse"
      />
    );
  }

  return (
    <div className="tc__page strategies">
      <Header page="Strategies" {...props} hideWidget={true} />
      {alertNode}
      {modalNode}

      <WindowScrollElementFixer
        positionToClass="strategies__container"
        positionThisClass="strategies__header"
        fixedHero={true}
        updateToElement={true}
      />

      <div className="strategies__container">
        <div className="strategies__header bl4__header--sticky">
          <div className="row row--1200 strategies__header-row">
            <div className="cute-8-desktop center-desktop cute-12-phone strategies__header-body">
              <h1 className="tc__headline-2 strategies__header-headline">
                BetterLesson Instructional Strategies
              </h1>
              <div className="tc__body-copy strategies__header-subheadline">
                BetterLesson Instructional Strategies provide ready-to-use
                resources and step-by-step guidance to help education
                professionals grow in their practice.
              </div>
              <button
                onClick={handleBrowseStrategies}
                className="bl4__btn bl4__btn--blue show-desktop hide-phone"
              >
                Browse Strategies
              </button>
            </div>
            <div className="strategies__share">
              <div className="strategies__share-text">Share</div>
              <div className="strategies__share-icons">
                <div className="strategies__share-icon">
                  <FacebookShareButton
                    url={shareUrl}
                    hashtag="#BetterLesson"
                    beforeOnClick={() => handleSocialShare('facebook')}
                  >
                    <span className="tc-facebook"></span>
                  </FacebookShareButton>
                </div>
                <div className="strategies__share-icon">
                  <TwitterShareButton
                    url={shareUrl}
                    hashtags={['BetterLesson']}
                    beforeOnClick={() => handleSocialShare('twitter')}
                  >
                    <span className="tc-twitter"></span>
                  </TwitterShareButton>
                </div>
                <div className="strategies__share-icon">
                  <PinterestShareButton
                    url={shareUrl}
                    media={
                      process.env.REACT_APP_LAB_URL +
                      '/legacy-images/social_media/Strategies_Overview_withlogo_Pinterest.png'
                    }
                    beforeOnClick={() => handleSocialShare('pinterest')}
                  >
                    <span className="tc-pinterest"></span>
                  </PinterestShareButton>
                </div>
                <div
                  onClick={() => props.ui.shareModal.open({})}
                  className="tc-v4-link strategies__share-icon"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="strategies__domains">
          {props.domains.map((domain, i) => {
            return (
              <DomainRow
                {...domain}
                key={'domain-' + domain.id}
                user={props.user}
                addFavorite={props.addFavorite}
                removeFavorite={props.removeFavorite}
                recommendModal={props.ui.recommendModal}
                searchSuccess={props.searchSuccess}
                noBorder={i == props.domains.length - 1}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

Strategies.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object,
  domains: PropTypes.array.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
};

export default Strategies;
