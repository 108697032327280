// @ts-nocheck FIXME
const AbsoluteFlashMessage = (props) => {
  if (props.visible == false) {
    return <noscript />;
  }

  // The CSS classes can be passed in the props:
  const classes = props.cssClasses ? props.cssClasses : {};
  const showClass = props.visible ? ' show' : ' hide';

  // set up styles based on provided classes and/or default classes
  let containerCssClass =
    'alert-banner absolute-banner--stateless ' + classes.container + showClass;
  const containerErrorCssClass = 'alert-banner--red ' + classes.containerError;
  const headlineCssClass = 'alert-banner__headline ' + classes.headline;

  if (props.type === 'error') {
    containerCssClass += ' ' + containerErrorCssClass;
  }

  return (
    <div className={containerCssClass}>
      <div key="alert" className={headlineCssClass}>
        {props.message}
      </div>
    </div>
  );
};

export default AbsoluteFlashMessage;
