// @ts-nocheck FIXME
import ResourceApi from '@legacy-components/apis/ResourceApi';
import StoryApi from '@legacy-components/apis/V4/StoryApi';
import Utils from '@legacy-components/components/DynamicUtils';
import S3 from '@legacy-components/components/S3';
import PropTypes from 'prop-types';
import React from 'react';
import ArtifactForm from './ArtifactForm';
import ArtifactLink from './ArtifactLink';
import ArtifactProgress from './ArtifactProgress';
import ArtifactUpload from './ArtifactUpload';

class Artifact extends React.Component {
  /*** STATE MANAGEMENT ***/
  constructor(props) {
    super(props);

    // mode can be: file, link, during, after
    this.state = {
      mode: this.props.mode || 'file',
      dragOver: false,
      blur: false,
      error: '',
      artifact: this.props.data || {},
      storyArtifactId: null,
      isLink: this.props.isLink,
    };
    this.storyId = this.props.storyId;

    // if we are editing an existing artifact, track its story file/link id
    if (this.props.data) {
      this.state.storyArtifactId =
        this.props.data.story_file_id || this.props.data.story_link_id;
    }

    this.handleClose = this.handleClose.bind(this);
    this.submitFiles = this.submitFiles.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.handleUploadCancel = this.handleUploadCancel.bind(this);
    this.createFileEntry = this.createFileEntry.bind(this);
    this.editArtifact = this.editArtifact.bind(this);
  }

  componentDidMount() {
    // If a mobile user uploaded files directly from the timeline
    // submit the said files right away
    if (window.uploadedFiles && this.props.isMobile) {
      this.submitFiles({
        inputFiles: window.uploadedFiles,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    // if we are are past the first step of adding an artifact,
    // ignore the new props so as not to abandon the adding artifact flow
    if (this.state.mode === 'during' || this.state.mode === 'after') {
      return;
    }

    // otherwise, we are still in the first step of adding an artifact,
    // so we can switch between adding a file or link
    const updatedState = {};
    if ('mode' in nextProps && nextProps.mode !== this.state.mode) {
      updatedState.mode = nextProps.mode;
    }
    if ('isLink' in nextProps && nextProps.isLink !== this.state.isLink) {
      updatedState.isLink = nextProps.isLink;
    }
    this.setState(updatedState);
  }

  handleClose(e) {
    e.preventDefault();

    // If the user uploads an artifact and then changes their mind,
    // we need to delete the artifact entry
    if (
      this.state.artifact &&
      this.state.artifact.entry_id &&
      this.state.mode !== 'edit'
    ) {
      StoryApi.deleteEntry({
        storyId: this.storyId,
        entryId: this.state.artifact.entry_id,
      });
    }

    this.props.close();
  }

  /*** FILE UPLOAD HANDLERS ***/
  submitFiles(data) {
    const inputFiles = data.inputFiles;
    let file;

    // Clear upload errors and set the blur state
    this.setState({
      error: '',
      blur: true,
    });

    for (let i = 0; i < inputFiles.length; i++) {
      // Add some of the POST fields that the backend needs
      file = {
        lastModified: inputFiles[i]['lastModified'],
        lastModifiedDate: inputFiles[i]['lastModifiedDate'],
        name: inputFiles[i]['name'],
        size: inputFiles[i]['size'],
        type: inputFiles[i]['type'],
        uuid: Utils.uuid(),
      };
      // max size is 1024MB
      if (file.size > 1024000000) {
        // Reset the blur state
        this.setState({
          error: 'Whoops! Your file must be smaller than 1024MB.',
          blur: false,
          // Reset to the default mode to show the ArtifactUpload component
          mode: 'file',
          artifact: {},
        });
      } else {
        // The teacherId property is required by the ancient resources interface :-(
        inputFiles[i]['uuid'] = file['uuid'];
        const callbackFields = {
          teacherId: this.props.artifactUserId,
        };
        // Upload the file to S3
        S3.flexibleUpload(
          inputFiles[i],
          ResourceApi.createBeforeUpload,
          ResourceApi.upload,
          this.createFileEntry,
          this.uploadCleanupCallback,
          callbackFields
        ).then(
          function onSuccess(fileData) {
            fileData['message'] = 'Upload Complete';
            fileData['status'] = 'after';
          },
          function onError(fileData) {
            // See if the file has the error property
            let error = fileData['error'];
            if (!error) {
              error =
                'Oh no! Something went wrong. Wait a minute and try again.';
            } else if (error === 'aborted') {
              error = 'File upload canceled';
            }
            // Clear the file from the store and set the upload error
            this.setState({
              artifact: {},
              error: error,
              blur: false,
              // Reset the mode so that the user sees ArtifactUpload
              mode: 'file',
            });
          }.bind(this),
          function onProgress(fileData) {
            // If there is an error, abort
            if (fileData.status === 'error') {
              return;
            }
            fileData['message'] = fileData.progress + '%';
            fileData['status'] = 'during';
            fileData['percent'] = fileData.progress;
            // Update the state's file and the mode
            this.setState({
              artifact: fileData,
              mode: 'during',
            });
          }.bind(this)
        );
      }
    }
    // Destroy any uploadedFiles on the window object (mobile users)
    delete window.uploadedFiles;
  }

  onDragOver() {
    // Clear upload errors and set drag over
    this.setState({
      error: '',
      dragOver: true,
    });
  }

  onDragLeave() {
    // Clear upload errors and reset drag over
    this.setState({
      error: '',
      dragOver: false,
    });
  }

  handleUploadCancel(e) {
    this.handleClose(e);
  }

  createFileEntry(data) {
    // clear error
    this.setState({
      error: '',
    });
    // Add the entry
    const inputData = { ...data };
    inputData.storyId = this.storyId;
    return StoryApi.addFileEntry(inputData)
      .then(
        function (response) {
          this.addSuccessCallback(response);
        }.bind(this)
      )
      .catch(
        function (response) {
          // We could not create the artifact entry, we need to cleanup
          this.uploadCleanupCallback(data);
          this.setState({
            blur: false,
            // Reset the modal to the default mode so that all users can see the ArtifactUpload component
            mode: 'file',
            error:
              response.error ||
              'Sorry, there was a problem adding the artifact. Please try again later.',
          });
        }.bind(this)
      );
  }

  addSuccessCallback(response) {
    // set up the artifact data needed for the form
    this.setState({
      error: '',
      storyArtifactId:
        response.entry.story_file_id || response.entry.story_link_id,
      mode: 'after',
      artifact: {
        entry_id: response.entry.id,
        title: response.entry.title,
        connections: response.entry.connections,
      },
    });
  }

  uploadCleanupCallback(data) {
    if ('teacherId' in data && 'lessonfileId' in data) {
      ResourceApi.delete({
        teacherId: data.teacherId,
        lessonfileId: data.lessonfileId,
      });
    }
  }

  /*** EDIT ARTIFACT HANDLERS ***/
  editArtifact(data, errorCallback) {
    // edit the entry data
    StoryApi.editArtifactEntry(data)
      .then((response) => {
        if (this.state.mode === 'after') {
          // create a new capsule if we're in the process of adding a new artifact
          this.props.addEntryCallback(response.entry);
        } else {
          // replace the existing capsule if we're editing an existing artifact
          this.props.editEntryCallback(response.entry);
        }
        this.props.close();
      })
      .catch(() => {
        this.props.setErrorAlert();
        if (errorCallback) {
          errorCallback();
        }
      });
  }

  render() {
    let formNode = null;
    // set up main form
    switch (this.state.mode) {
      case 'file':
        formNode = (
          <ArtifactUpload
            submitFiles={this.submitFiles}
            onDragLeave={this.onDragLeave}
            onDragOver={this.onDragOver}
            error={this.state.error}
            blur={this.state.blur}
            dragOver={this.state.dragOver}
            close={this.props.close}
          />
        );
        break;
      case 'link':
        formNode = (
          <ArtifactLink
            storyId={this.storyId}
            addSuccessCallback={this.addSuccessCallback.bind(this)}
            close={this.props.close}
          />
        );
        break;
      case 'during':
        formNode = (
          <ArtifactProgress
            file={this.state.artifact}
            containerCssClass="artifact-upload-v4__progress"
          />
        );
        break;
      case 'after':
      case 'edit':
        formNode = (
          <ArtifactForm
            type={this.state.isLink ? 'link' : 'file'}
            data={this.state.artifact}
            storyArtifactId={this.state.storyArtifactId}
            storyId={this.storyId}
            editArtifact={this.editArtifact}
            confirmDelete={this.props.confirmDelete}
            handleClose={this.handleClose}
            editMode={this.state.mode === 'edit'}
            pageUser={this.props.pageUser}
            currentUser={this.props.currentUser}
            author={this.state.artifact.author}
            goals={this.props.goals}
            isEntryHighlighted={this.props.isEntryHighlighted}
            canEditAuthor={this.props.canEditAuthor}
            toggleGoalTag={this.props.toggleGoalTag}
          />
        );
        break;
    }

    return formNode;
  }
}

Artifact.propTypes = {
  mode: PropTypes.string,
  close: PropTypes.func.isRequired,
  addEntryCallback: PropTypes.func.isRequired,
  editEntryCallback: PropTypes.func.isRequired,
  artifactUserId: PropTypes.number,
};

export default Artifact;
