// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';

class AbsoluteAlert extends React.Component {
  state = {
    type: this.props.type,
    message: this.props.message,
    visible: true,
  };

  alertTimeoutID = null;

  componentDidMount() {
    this.resetCloseTimer(this.props.preventAutoclose);
  }

  componentWillReceiveProps(nextProps) {
    this.resetCloseTimer(nextProps.preventAutoclose);
    this.setState({
      type: nextProps.type,
      message: nextProps.message,
      visible: true,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.alertTimeoutID);
  }

  // by default, this alert will close after 5 seconds
  resetCloseTimer = (preventAutoclose) => {
    // Clear any existing timeout ID
    clearTimeout(this.alertTimeoutID);

    if (!preventAutoclose) {
      // Set a timer to close the alert after 5 seconds
      this.alertTimeoutID = setTimeout(this.handleClose, 5000);
    }
  };

  handleClose = () => {
    // use a callback if provided; otherwise, default to just closing
    if (typeof this.props.handleClose === 'function') {
      this.props.handleClose();
    } else {
      this.setState({ visible: false });
    }
  };

  render() {
    let alert = null;

    if (this.state.visible) {
      // The CSS classes can be passed in the props:
      const classes = this.props.cssClasses ? this.props.cssClasses : {};
      // set up styles based on provided classes and/or default classes
      let containerCssClass = classes.container
        ? 'alert-banner ' + classes.container
        : 'alert-banner ';
      const containerErrorCssClass = classes.containerError
        ? 'alert-banner--red ' + classes.containerError
        : 'alert-banner--red ';
      const closeButtonCssClass = classes.closeButton
        ? 'alert-close-link ' + classes.closeButton
        : 'alert-close-link ';
      const headlineCssClass = classes.headline
        ? 'alert-banner__headline ' + classes.headline
        : 'alert-banner__headline ';
      if (this.state.type === 'error') {
        containerCssClass += ' ' + containerErrorCssClass;
      } else if (this.state.type === 'warning') {
        containerCssClass += ' alert-banner--orange ';
      }

      // message can be text or stringified html or react node
      let messageNode = (
        <PurifiedHTML
          key="alert"
          className={headlineCssClass}
          content={this.state.message}
        />
      );
      if (React.isValidElement(this.state.message)) {
        messageNode = (
          <div key="alert" className={headlineCssClass}>
            {this.state.message}
          </div>
        );
      }

      alert = (
        <div className={containerCssClass}>
          <div className="alert-banner__body">
            {messageNode}
            <div
              data-testid="close-alert"
              className={closeButtonCssClass + ' tc-x'}
              onClick={this.handleClose}
            ></div>
          </div>
        </div>
      );
    }

    return alert;
  }
}

export default AbsoluteAlert;
