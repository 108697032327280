// @ts-nocheck FIXME
import React from 'react';
import ReactDOM from 'react-dom';
import TimeAgo from 'react-timeago';
import StrategyApi from './../../../apis/StrategyApi';
import Utils from './../../../components/DynamicUtils';
import LayerManager from './../../../components/LayerManager';
import LayerClose from './../../LayerManager/LayerClose';
import ScaleCenterImage from './../../ScaleCenterImage';

class AddNote extends React.Component {
  state = {
    notes: this.props.strategy.notes || [],
    submitButtonVisible: false,
    btnDisabledClass: '',
    error: null,
  };

  scrollPosition = 0;

  componentWillMount() {
    // save the scroll position to restore it when the dialog closes
    this.scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;
  }

  componentDidMount() {
    // disable page scrolling when dialog is opened
    Utils.addClass(document.body, 'no-scroll--mobile');
  }

  componentWillUnmount() {
    // re-enable page scrolling when dialog is closed
    Utils.removeClass(document.body, 'no-scroll--mobile');

    // restore scroll position on the previous page
    window.scrollTo(0, this.scrollPosition);
  }

  handleBackClick = () => {
    LayerManager.close();
  };

  handleShowSubmit = () => {
    this.setState({
      submitButtonVisible: true,
    });
  };

  handleAddNote = (e) => {
    e.preventDefault();

    this.setState({
      btnDisabledClass: ' disabled',
      error: null,
    });

    const note = ReactDOM.findDOMNode(this.refs.note).value;
    if (!note || note == '') {
      this.setState({
        btnDisabledClass: '',
        error: 'Note cannot be blank',
      });
      return;
    }

    // we pass in the current user as the teacher here if there is no teacher id in the url
    StrategyApi.addStrategyNote({
      userId: this.props.user.id,
      teacherId: this.props.teacher
        ? this.props.teacher.id
        : this.props.user.id,
      strategyId: this.props.strategy.id,
      note: note,
    })
      .then(
        function (response) {
          ReactDOM.findDOMNode(this.refs.note).value = '';
          this.setState({
            notes: this.state.notes.concat(response.payload.note),
            btnDisabledClass: '',
          });
          // call any callback received from the parent
          if ('onAddNote' in this.props) {
            this.props.onAddNote(response.payload.note);
          }
        }.bind(this)
      )
      .catch(
        function (response) {
          this.setState({
            btnDisabledClass: '',
            error:
              'Sorry, there was a problem saving your note. Please try again later.',
          });
        }.bind(this)
      );
  };

  render() {
    let noteErrorClass = '';
    let noteErrorNode = null;
    if (this.state.error) {
      noteErrorClass = ' error';
      noteErrorNode = <div className="tc__error">{this.state.error}</div>;
    }

    const btnHiddenClass = this.state.submitButtonVisible ? '' : ' tc__hidden';

    // set up default user photo
    const defaultUserImageNode = (
      <div className="pinboard__comment-teacher-image-mask pinboard__comment-teacher-image-mask--none">
        <img
          className="pinboard__comment-teacher-image"
          src="/legacy-images/default-user.svg"
        />
      </div>
    );

    let modalCaption = 'Add a planning note';
    let notesNode = (
      <div className="pinboard__comment-no-messages tc__body-copy">
        How will you implement this Strategy?
      </div>
    );

    if (this.state.notes.length) {
      modalCaption = 'Planning Notes';
      notesNode = this.state.notes.map(function (note) {
        let noteUserImageNode = defaultUserImageNode;
        // if the user photo exists display the user's image
        if (note.photo) {
          noteUserImageNode = (
            <ScaleCenterImage
              src={note.photo}
              className="pinboard__comment-teacher-image"
            />
          );
        }

        const noteFormatted = Utils.legacyDateFormatter(note.created_formatted);

        return (
          <div className="pinboard__comment" key={'note-' + note.id}>
            <div className="pinboard__comment-image-col">
              {noteUserImageNode}
            </div>
            <div className="pinboard__comment-data-col">
              <div className="pinboard__comment-header">
                <div className="pinboard__comment-teacher-name">
                  {note.author}
                </div>
                <div className="pinboard__comment-timeago">
                  <TimeAgo date={noteFormatted} />
                </div>
              </div>

              <div className="pinboard__comment-text">{note.data}</div>
            </div>
          </div>
        );
      });
    }

    // only show the add note form if the current user is authorized to add a note
    let formNode = null;
    if (this.props.canAddNote) {
      let commenterUserImageNode = defaultUserImageNode;
      if (this.props.user.photo) {
        commenterUserImageNode = (
          <ScaleCenterImage
            src={this.props.user.photo}
            className="pinboard__comment-teacher-image"
          />
        );
      }
      formNode = (
        <div className="pinboard__comment-form">
          <div className="pinboard__comment-image-col hide-phone show-desktop">
            {commenterUserImageNode}
          </div>
          <div className="pinboard__comment-data-col pinboard__comment-data-col--form-input">
            <form className="pinboard__comment-form-input">
              <div className="pinboard__comment-input-container">
                <textarea
                  type="text"
                  name="note"
                  ref="note"
                  placeholder="Additional comments & notes"
                  className={'tc__input' + noteErrorClass}
                  onFocus={this.handleShowSubmit}
                />
                {noteErrorNode}
              </div>
              <a
                href="#"
                onClick={this.handleAddNote}
                className={
                  'pinboard__comment-button tc__btn tc__btn--blue tc__btn--small hide-phone show-desktop' +
                  btnHiddenClass +
                  this.state.btnDisabledClass
                }
              >
                Add Note
              </a>
              <a
                href="#"
                onClick={this.handleAddNote}
                className={
                  'pinboard__comment-button tc__btn tc__btn--blue tc__btn--small show-phone hide-desktop' +
                  this.state.btnDisabledClass
                }
              >
                Add Note
              </a>
            </form>
          </div>
        </div>
      );
    }

    return (
      <div>
        <LayerClose href="#" className="modal__close hide-phone show-desktop">
          <div className="tc-x"></div>
        </LayerClose>
        <h2 className="pinboard__comment-headline tc__headline-2 hide-phone show-desktop">
          {modalCaption}
        </h2>

        <div className="tc__mobile-header show-phone hide-desktop">
          <div
            className="tc__back cute-2-desktop left"
            onClick={this.handleBackClick}
          >
            <div className="tc__back-arrow tc-arrow"></div>
            <div className="tc__back-caption">Back</div>
          </div>
          <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
            {modalCaption}
          </div>
        </div>

        <div className="pinboard__comment-notes">{notesNode}</div>

        {formNode}
      </div>
    );
  }
}

export default AddNote;
