// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import NavigationLink from '@legacy-components/react/NavigationLink';
import HelpDeskLinkGateway from '@legacy-components/react/V5/Common/HelpDeskLinkGateway';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import AbsoluteAlert from './../../Alerts/Absolute';
import EventAccountRegistration from './modules/EventAccountRegistration';
import EventGroupForm from './modules/EventGroupForm';
import EventProductDescription from './modules/EventProductDescription';
import RegistrationForm from './modules/RegistrationForm';

const EventRegistration = (props) => {
  React.useEffect(() => {
    props.setResponsive(true);
    return () => props.setResponsive(false);
  }, []);

  const serviceImage = Utils.mapBLServiceNameToIllustration(
    props.event.service
  );
  const [selectingGroup, setSelectingGroup] = React.useState(false);

  const eventSessions = props.event.sessions ? props.event.sessions : [];
  eventSessions.filter((session) =>
    moment(session.session_date_formatted).isSameOrAfter(moment.now())
  );
  eventSessions.sort((sessionA, sessionB) => sessionA.num - sessionB.num);

  const getProductDescriptionForEarliestCurrentSession = () => {
    const eventSessions = props.event.current_sessions
      ? props.event.current_sessions
      : [];
    if (!eventSessions || !eventSessions.length) {
      return null;
    }
    eventSessions.sort((sessionA, sessionB) => sessionA.num - sessionB.num);
    const earliestCurrentSession = eventSessions[0];
    const sessionProduct = earliestCurrentSession.ld_product;
    return sessionProduct ? sessionProduct.description : null;
  };

  const handleSelectSession = (session_id) => {
    const event_session = props.event.current_sessions.find(
      (session) => session.id === parseInt(session_id)
    );
    props.registerForEvent({
      event_id: props.event.id,
      session_id: event_session.id,
      group_id: event_session.group_id,
    });
  };

  const handleGoHome = () => {
    window.location = '/';
  };

  const helpCenterNode = (
    <HelpDeskLinkGateway headline="Need help checking in?" />
  );

  const isActiveUser = props.isLoggedIn || props.accountCreated;

  let visibleForm;

  let centeringClass;
  if (selectingGroup) {
    visibleForm = (
      <EventGroupForm {...props} selectGroup={handleSelectSession}>
        {helpCenterNode}
      </EventGroupForm>
    );
  } else if (
    !props.event.current_sessions ||
    !props.event.current_sessions.length
  ) {
    let nextSessionDate = props.event.next_session_date;
    if (
      props.participant &&
      props.participant.event_participant &&
      props.participant.event_participant.group_id
    ) {
      nextSessionDate =
        props.event.next_session_dates[
          props.participant.event_participant.group_id
        ];
    }
    centeringClass = 'event-v5__registration-not-yet-capsule';
    const nextSessionNode = nextSessionDate ? (
      <React.Fragment>
        doesn&apos;t open until <span className="bold">{nextSessionDate}</span>.
        We look forward to seeing you then!
      </React.Fragment>
    ) : (
      <React.Fragment>isn’t currently open.</React.Fragment>
    );
    visibleForm = (
      <div className="v5__body-copy">
        <div className="v5__heading-3 event-v5__registration-not-yet-line">
          You&apos;re early!
        </div>
        <div className="event-v5__registration-not-yet-line">
          We appreciate your enthusiasm to get started, but check-in for this
          event {nextSessionNode}
        </div>
        <div className="event-v5__registration-not-yet-line">
          In the meantime you can learn more about BetterLesson on{' '}
          <NavigationLink className="v5__link" url="/">
            {' '}
            our website.
          </NavigationLink>
        </div>
      </div>
    );
  } else if (isActiveUser) {
    // If the event has multiple groups the user needs to select a group
    let selectGroupCallback;
    const eventParticipant =
      props.participant && props.participant.event_participant;
    if (
      props.event.event_groups &&
      props.event.event_groups.length > 1 &&
      (!props.participant || !props.participant.event_participant.group_id)
    ) {
      selectGroupCallback = setSelectingGroup;
    }
    // Show the registration page
    visibleForm = (
      <RegistrationForm
        {...props}
        handleRegisterForEvent={props.registerForEvent}
        handleRegisterForCohort={props.registerForCohort}
        selectGroupCallback={selectGroupCallback}
        eventParticipant={eventParticipant}
      >
        {helpCenterNode}
      </RegistrationForm>
    );
  } else {
    // Show the login screen
    visibleForm = (
      <div className="registration-v5-event__register">
        <div className="registration-v5-event__register-header v5__heading-3">
          Check In
        </div>
        <div className="registration-v5-event__register-body v5__body-copy">
          <EventAccountRegistration action={'checkin'}>
            {helpCenterNode}
          </EventAccountRegistration>
        </div>
      </div>
    );
  }

  let alertNode;
  if (props.ui.errors.general && props.ui.errors.general.length) {
    alertNode = (
      <AbsoluteAlert
        preventAutoclose={props.ui.persistErrors}
        type="error"
        message={props.ui.errors.general[0]}
        handleClose={props.clearErrors}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const domainLabel = Utils.getDomainIdForIcon(props.event.focus_area.id);
  const focusAreaLabel =
    props.event.focus_area && props.event.focus_area.label
      ? props.event.focus_area.label
      : '';
  const spacer =
    props.event.service &&
    props.event.focus_area &&
    props.event.focus_area.label &&
    ': ';

  return (
    <div className="tc__page tc__page--registration">
      <div className="tc-header__wrap tc-header__wrap--v2">
        <div className="tc-header tc-header--v2 row row--responsive">
          <div
            className="logo tc-header__logo cute-3-desktop cute-3-phone left tc-header__registration-phone"
            onClick={handleGoHome}
            src="/legacy-images/betterlesson-logo.svg"
          ></div>
        </div>
      </div>
      {alertNode}

      <div className="row row--1200 registration-v5__container">
        <div className="cute-5-desktop cute-12-phone left registration-v5-event__event-image-column">
          <img
            src={`${serviceImage}`}
            className="code-404__img event-v5__headline-image"
          />
          <div
            className={`v5__domain-label--${domainLabel} registration-v5-event__event-service v5__heading-5`}
          >
            <span
              className={`tc-v4-domain-${domainLabel} registration-v5-event__domain-icon`}
            />
            <span className="registration-v5-event__event-service-label">
              {props.event.service}
              {spacer}
              {focusAreaLabel}
            </span>
          </div>
          <div className="registration-v5-event__event-display-name registration-v5-event__event-display-name__blueberry v5__heading-1">
            {props.event.display_name}
          </div>
          <div className="registration-v5-event__event-partner-name v5__heading-4">
            {props.event.partner && props.event.partner.name}
          </div>
          <div className={'show-desktop hide-phone'}>
            <EventProductDescription
              description={getProductDescriptionForEarliestCurrentSession()}
              action={'checkin'}
            />
          </div>
        </div>
        <div className={`cute-6-desktop cute-12-phone ${centeringClass}`}>
          {visibleForm}
        </div>
      </div>
    </div>
  );
};

EventRegistration.propTypes = {
  ui: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};

const ConnectedEventRegistration = (props) => (
  <HeaderConsumer>
    {({ setResponsive }) => (
      <EventRegistration {...props} setResponsive={setResponsive} />
    )}
  </HeaderConsumer>
);

export default ConnectedEventRegistration;
