// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import TimeZoneIndicator from '../modules/TimeZoneIndicator';
import CalendarRow from './CalendarRow';

const MobileCalendar = (props) => {
  const createCalendarEntry = (item) => (
    <CalendarRow
      {...item}
      key={`meeting-${item.id}`}
      time_zone={props.user.time_zone}
    />
  );

  if (props.user.calendar && props.canSeeAgenda) {
    // Create a reference date at the current time in the user's time zone
    const now =
      props.pageUser.time_zone && props.pageUser.time_zone.pytz_timezone
        ? moment().tz(props.pageUser.time_zone.pytz_timezone)
        : moment().tz('US/Eastern');

    // Filter out the meetings for today
    const meetingsToday = props.user.calendar.filter((meeting) =>
      moment(meeting.start.dateTime).isSame(now, 'day')
    );

    const todayHeader = (
      <div className="cute-12-phone admin-v5_coach-dashboard__calendar-heading v5__heading-3">
        Today, {moment().format('LL')}
        <TimeZoneIndicator
          className="admin-v5_coach-dashboard--timezone-no-margin"
          {...props.pageUser.time_zone}
        />
      </div>
    );

    let today = null;

    if (meetingsToday.length) {
      today = meetingsToday.map(createCalendarEntry);
    } else {
      today = (
        <div className="admin-v5_coach-dashboard__calendar-row">
          A meeting-free day? Lucky you!
        </div>
      );
    }

    // Filter out the meetings for tomorrow
    const meetingsTomorrow = props.user.calendar.filter(
      (meeting) => !moment(meeting.start.dateTime).isSame(now, 'day')
    );

    let tomorrowHeader = (
      <div
        className="tc__link cute-12-phone admin-v5_coach-dashboard__calendar-toggle v5__toggle-copy"
        onClick={props.toggleShowingMeetingsForTomorrow}
      >
        SHOW ALL
      </div>
    );

    let tomorrow = null;
    let hideTomorrow = null;

    if (props.ui.showMeetingsForTomorrow) {
      tomorrowHeader = (
        <div className="cute-12-phone admin-v5_coach-dashboard__calendar-heading v5__heading-3">
          Tomorrow
        </div>
      );

      if (meetingsTomorrow.length) {
        tomorrow = meetingsTomorrow.map(createCalendarEntry);
      } else {
        tomorrow = (
          <div className="admin-v5_coach-dashboard__calendar-row">
            No meetings tomorrow. Kick back and relax!
          </div>
        );
      }

      hideTomorrow = (
        <div
          className="tc__link cute-12-phone admin-v5_coach-dashboard__calendar-toggle v5__toggle-copy"
          onClick={props.toggleShowingMeetingsForTomorrow}
        >
          SHOW LESS
        </div>
      );
    }

    return (
      <div>
        {todayHeader}
        {today}
        {tomorrowHeader}
        {tomorrow}
        {hideTomorrow}
      </div>
    );
  }

  return null; // Empty state goes here
};

MobileCalendar.propTypes = {
  canSeeAgenda: PropTypes.bool.isRequired,
  toggleShowingMeetingsForTomorrow: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default MobileCalendar;
