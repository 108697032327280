// @ts-nocheck FIXME
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import MeetingNotesEntry from '@legacy-components/react/V5/Workspace/modules/Entries/MeetingNotesEntry';
import EntryHeaderView from '@legacy-components/react/V5/Workspace/modules/Entries/modules/EntryHeaderView';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const MeetingNotesContainer = ({
  author,
  meeting_notes,
  meeting_time_formatted,
  user,
  id,
}) => {
  // get the current user's timezone for meeting times (default is EST)
  const timezone = user.time_zone.pytz_timezone;

  const title = `Meeting on ${moment(meeting_time_formatted)
    .tz(timezone)
    .format('MMMM DD, YYYY')}: Notes & Next Steps`;

  // set up the entry author
  const authorNode = author && (
    <div className="workspace__entry-author workspace__entry-author--centered">
      <div className="workspace__comment-photo-col">
        <UserPhoto
          src={author.photo}
          userId={author.id}
          firstName={author.first_name}
          lastName={author.last_name}
          diameter={40}
        />
      </div>
      <div className="workspace__entry-author-name">
        {author.first_name} {author.last_name}
      </div>
    </div>
  );

  return (
    <div
      id={`meeting-${id}`}
      className="highlights__entry row row--1200 highlights__meeting-notes-row"
    >
      <EntryHeaderView
        title={title}
        iconClass="tc-note"
        createdFormatted={meeting_time_formatted}
      />

      <MeetingNotesEntry
        meeting_notes={meeting_notes}
        hideIcon={false}
        toggle={true}
        smallView={false}
        greyFade={true}
      >
        <span className="tc-v5-left-quotes highlights__left-quotes-sizing"></span>
      </MeetingNotesEntry>
      {authorNode}
    </div>
  );
};

MeetingNotesContainer.propTypes = {
  author: PropTypes.object,
  meeting_notes: PropTypes.string,
  meeting_time_formatted: PropTypes.string,
};

const ConnectedMeetingNotesContainer = (props) => (
  <HeaderConsumer>
    {({ user }) => {
      return <MeetingNotesContainer {...props} user={user} />;
    }}
  </HeaderConsumer>
);

export default ConnectedMeetingNotesContainer;
