// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import CKEditor from './../V3/Common/CKEditor/CKEditor';

export default function Element(props) {
  // save the refs to all the fields that are relevant to this particular element
  const formRefs = {};

  const handleChangeMode = (mode, e) => {
    e.preventDefault();

    const data = {
      entryData: {
        type: props.type,
        mode: mode,
      },
    };

    if (props.id) {
      data.entryId = props.id;
    } else if (props.sectionId) {
      data.sectionId = props.sectionId;
      if (props.subjectId) {
        data.subjectId = props.subjectId;
      }
      if (props.growthAreaId) {
        data.growthAreaId = props.growthAreaId;
      }
    }
    props.editEntry(data);
  };

  const handleSelectType = (type, e) => {
    e.preventDefault();
    // you can only select the type when creating a new element, which requires section info
    if (!props.sectionId) {
      return;
    }
    const data = { entryData: { mode: 'edit', type: type } };
    data.sectionId = props.sectionId;
    if (props.subjectId) {
      data.subjectId = props.subjectId;
    }
    if (props.growthAreaId) {
      data.growthAreaId = props.growthAreaId;
    }
    props.editEntry(data);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    // gather the form data to update the element
    const formData = {};
    Object.entries(formRefs).forEach(([key, val]) => {
      formData[key] = val.value;
    });

    // build the data to pass to the store
    const data = {
      entryData: {
        type: props.type,
        mode: 'update',
        data: formData,
      },
    };

    if (props.id) {
      data.entryId = props.id;
    } else if (props.sectionId) {
      data.sectionId = props.sectionId;
      if (props.subjectId) {
        data.subjectId = props.subjectId;
      }
      if (props.growthAreaId) {
        data.growthAreaId = props.growthAreaId;
      }
    }
    props.editEntry(data);
  };

  const handleToggleVisible = (e) => {
    e.preventDefault();
    props.editEntry({
      entryId: props.id,
      entryData: { visible: !props.visible },
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const data = {
      elementType: props.type,
    };
    if (props.id) {
      data.entryId = props.id;
    } else if (props.sectionId) {
      data.sectionId = props.sectionId;
      if (props.subjectId) {
        data.subjectId = props.subjectId;
      }
      if (props.growthAreaId) {
        data.growthAreaId = props.growthAreaId;
      }
    }
    props.deleteEntry(data);
  };

  // only show the visibility toggle icon if the element is saved to the portfolio
  let visibilityNode = null;
  if (props.id) {
    const visibilityIcon = props.visible ? (
      <div className="ppt__visibility-icon selected">
        <div className="tc-check" />
      </div>
    ) : (
      <div className="ppt__visibility-icon"></div>
    );
    visibilityNode = (
      <div className="ppt-element__visibility" onClick={handleToggleVisible}>
        {visibilityIcon}
      </div>
    );
  }

  let dragHandleNode = null;
  if (props.id) {
    dragHandleNode = <div className="tc-dots-8 ppt__drag-icon"></div>;
  }
  // if there is an existing element, show the icon corresponding to its type
  let iconNode = null;
  // by default, show empty element with + button
  let elementNode = (
    <div
      className="ppt-element__empty-add"
      onClick={(e) => handleChangeMode('selectType', e)}
    >
      <span className="tc-add-balloon-icon ppt-element__add-icon"></span>
      <div className="ppt-element__empty-line"></div>
    </div>
  );
  // by default, show no buttons
  let buttonsNode = null;

  // if the user is adding an element and has clicked the +, show the types they can choose from
  if (props.mode === 'selectType') {
    let typesNode = (
      <div className="ppt-element__select-type">
        <div
          className="ppt-element__select-type-link ppt-element__select-type-letters"
          onClick={(e) => handleSelectType('Text', e)}
        >
          <div className="ppt-element__select-type-icon">a</div>
          <div>TEXT</div>
        </div>
        <div
          className="ppt-element__select-type-link"
          onClick={(e) => handleSelectType('Quote', e)}
        >
          <div className="tc-comment ppt-element__select-type-icon"></div>
          <div>QUOTE</div>
        </div>
        <div
          className="ppt-element__select-type-link ppt-element__select-type-letters"
          onClick={(e) => handleSelectType('Kpi', e)}
        >
          <div className="ppt-element__select-type-icon ppt-element__select-type-icon-kpi">
            %
          </div>
          <div>KPI</div>
        </div>
        <div
          className="ppt-element__select-type-link"
          onClick={(e) => handleSelectType('Datapoint', e)}
        >
          <div className="tc-data-point-icon ppt-element__select-type-icon"></div>
          <div>DATAPOINT</div>
        </div>
        <div
          className="ppt-element__select-type-link"
          onClick={(e) => handleSelectType('Spotlight', e)}
        >
          <div className="tc-link-icon-portfolio ppt-element__select-type-icon"></div>
          <div>URL</div>
        </div>
        <div
          className="ppt-element__select-type-link"
          onClick={(e) => handleSelectType('CoachingSpotlight', e)}
        >
          <div className="tc-video_vector_icon ppt-element__select-type-icon"></div>
          <div>COACH VIDEO</div>
        </div>
      </div>
    );
    if (props.spotlightsOnly) {
      typesNode = (
        <div className="ppt-element__select-type">
          <div
            className="ppt-element__select-type-link"
            onClick={(e) => handleSelectType('Quote', e)}
          >
            <div className="tc-comment ppt-element__select-type-icon"></div>
            <div>QUOTE</div>
          </div>
          <div
            className="ppt-element__select-type-link"
            onClick={(e) => handleSelectType('Spotlight', e)}
          >
            <div className="tc-link-icon-portfolio ppt-element__select-type-icon"></div>
            <div>URL</div>
          </div>
          <div
            className="ppt-element__select-type-link"
            onClick={(e) => handleSelectType('CoachingSpotlight', e)}
          >
            <div className="tc-video_vector_icon ppt-element__select-type-icon"></div>
            <div>COACH VIDEO</div>
          </div>
        </div>
      );
    }
    elementNode = (
      <div className="">
        <span className="tc-add-balloon-icon ppt-element__add-icon"></span>
        {typesNode}
      </div>
    );
  }
  // if we are in editing mode, display the correct form
  else if (props.mode === 'edit') {
    // set up any form errors
    let errorClass = '';
    let errorNode = null;
    if (props.error) {
      errorClass = ' error';
      errorNode = <div className="tc__error">{props.error}</div>;
    }

    // show the correct form depending on the element type
    switch (props.type) {
      case 'Text': {
        const innerText = props.data ? props.data.text : '';
        const elemId = props.id ? props.id : 0;
        const increment = props.increment ? props.increment : 0;
        elementNode = (
          <CKEditor
            id={elemId}
            increment={increment}
            sectionId={props.sectionId}
            update={props.editEntry}
            innerText={innerText}
            className="ppt__ckeditor"
            configs="/legacy-libs/configs/bl-cke-config-ppt.js"
          />
        );
        break;
      }

      case 'Kpi':
        elementNode = (
          <div className="ppt-element__input-container">
            <input
              type="text"
              ref={(ref) => (formRefs.value = ref)}
              className="tc__input ppt-element__input ppt-element__input--short"
              placeholder="Value"
              defaultValue={props.data ? props.data.value : null}
            />
            <input
              type="text"
              ref={(ref) => (formRefs.text = ref)}
              className="tc__input ppt-element__input ppt-element__input--mid"
              placeholder="Enter text"
              defaultValue={props.data ? props.data.text : null}
            />
          </div>
        );
        break;

      case 'Datapoint':
        elementNode = (
          <div className="ppt-element__input-container">
            <input
              type="text"
              ref={(ref) => (formRefs.text = ref)}
              className="tc__input ppt-element__input"
              placeholder="Enter data and text"
              defaultValue={props.data ? props.data.text : null}
            />
          </div>
        );
        break;

      case 'Quote':
        elementNode = (
          <div className="">
            <div className="ppt-element__input-container">
              <textarea
                ref={(ref) => (formRefs.text = ref)}
                className="tc__input ppt-element__input"
                placeholder="Enter quote text"
                defaultValue={props.data ? props.data.text : null}
              />
            </div>
            <div className="ppt-element__input-container">
              <input
                type="text"
                ref={(ref) => (formRefs.author = ref)}
                className="tc__input ppt-element__input"
                placeholder="Enter author"
                defaultValue={props.data ? props.data.author : null}
              />
            </div>
          </div>
        );
        break;

      // when creating a new Spotlight, we do not know what the URL is pointing to,
      // so we just use the generic "Spotlight" type - this should change after it is saved
      case 'Spotlight':
      case 'ArtifactSpotlight':
      case 'NarrativeSpotlight':
      case 'TeacherSpotlight':
      case 'StrategySpotlight':
        elementNode = (
          <div className="ppt-element__input-container">
            <input
              type="text"
              ref={(ref) => (formRefs.url = ref)}
              className={'tc__input ppt-element__input' + errorClass}
              placeholder="Enter URL"
              defaultValue={props.data ? props.data.url : null}
            />
            {errorNode}
          </div>
        );
        break;

      // the Coach Video is a separate icon, and needs different info from the other Spotlights
      case 'CoachingSpotlight':
        elementNode = (
          <div className="">
            <div className="ppt-element__input-container">
              <input
                type="text"
                ref={(ref) => (formRefs.coachName = ref)}
                className="tc__input ppt-element__input"
                placeholder="Coach Name"
                defaultValue={props.data ? props.data.coachName : null}
              />
            </div>
            <div className="ppt-element__input-container">
              <input
                type="text"
                ref={(ref) => (formRefs.teacherName = ref)}
                className="tc__input ppt-element__input"
                placeholder="Teacher Name"
                defaultValue={props.data ? props.data.teacherName : null}
              />
            </div>
            <div className="ppt-element__input-container">
              <input
                type="url"
                ref={(ref) => (formRefs.url = ref)}
                className="tc__input ppt-element__input"
                placeholder="Video URL"
                defaultValue={props.data ? props.data.url : null}
              />
            </div>
            <div className="ppt-element__input-container">
              <textarea
                ref={(ref) => (formRefs.description = ref)}
                className="tc__input ppt-element__input"
                placeholder="Enter a description for the video"
                defaultValue={props.data ? props.data.description : null}
              />
            </div>
          </div>
        );
        break;
    }

    // disable button when we are making an API call
    const disabledClass = props.isLoading ? ' disabled' : '';
    // button text depends on whether we are creating an element or updating an existing one
    const submitText = props.id ? 'Update' : 'Add';

    buttonsNode = (
      <div>
        <button
          onClick={handleUpdate}
          className={
            'ppt-element__btn tc__btn tc__btn--small tc__btn--blue' +
            disabledClass
          }
        >
          {submitText}
        </button>
        <a
          className="tc__link"
          onClick={(e) =>
            props.id ? handleChangeMode(null, e) : handleDelete(e)
          }
        >
          Cancel
        </a>
      </div>
    );
    if (props.type === 'Text') {
      buttonsNode = (
        <a
          className="tc__link"
          onClick={(e) =>
            props.id ? handleChangeMode(null, e) : handleDelete(e)
          }
        >
          Cancel
        </a>
      );
    }
  }
  // if we are showing an existing element, display that instead of the empty state
  else if (props.id) {
    switch (props.type) {
      case 'Text':
        elementNode = (
          <PurifiedHTML
            className="ppt-element__editor cke__editor"
            content={props.data.text}
          />
        );
        break;

      case 'Kpi':
        elementNode = (
          <div className="">
            <span className="ppt-element__bold">{props.data.value}</span>{' '}
            {props.data.text}
          </div>
        );
        break;

      case 'Datapoint':
        elementNode = <div className="">{props.data.text}</div>;
        break;

      case 'Quote': {
        iconNode = (
          <span className="tc-partner-portfolio-quote-icon ppt-element-spotlight-icon" />
        );
        // only show the dash if there is an author
        let authorNode = null;
        if (props.data.author) {
          authorNode = <div>- {props.data.author}</div>;
        }
        elementNode = (
          <div className="">
            <div>&quot;{props.data.text}&quot;</div>
            {authorNode}
          </div>
        );
        break;
      }

      case 'ArtifactSpotlight':
        iconNode = (
          <span className="tc-partner-portfolio-artifact-icon ppt-element-spotlight-icon" />
        );
        elementNode = (
          <a
            href={props.data.url + '?from=pp_admin_view'}
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link"
          >
            {props.data.type_name}: {props.data.filename}
          </a>
        );
        break;

      case 'NarrativeSpotlight':
        iconNode = (
          <span className="tc-partner-portfolio-narrative-icon ppt-element-spotlight-icon" />
        );
        elementNode = (
          <a
            href={props.data.url + '?from=pp_admin_view'}
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link"
          >
            {props.data.teacher_name}: {props.data.strategy.title} (
            {props.data.artifact_count} Artifacts)
          </a>
        );
        break;

      case 'TeacherSpotlight':
        iconNode = (
          <span className="tc-partner-portfolio-teacher-icon ppt-element-spotlight-icon" />
        );
        elementNode = (
          <a
            href={props.data.url + '?from=pp_admin_view'}
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link"
          >
            {props.data.teacher_name} ({props.data.strategies_tried} Strategies
            Tried, {props.data.artifacts_uploaded} Artifacts Uploaded)
          </a>
        );
        break;

      case 'StrategySpotlight':
        iconNode = (
          <span className="tc-partner-portfolio-strategy-icon ppt-element-spotlight-icon" />
        );
        elementNode = (
          <a
            href={props.data.url + '?from=pp_admin_view'}
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link"
          >
            {props.data.strategy_implementation.name} (Tried by{' '}
            {props.data.teachers_tried} Teachers)
          </a>
        );
        break;

      case 'CoachingSpotlight':
        iconNode = (
          <span className="tc-partner-portfolio-play-icon ppt-element-spotlight-icon" />
        );
        elementNode = (
          <div className="">
            <div>{props.data.coachName}</div>
            <div>{props.data.teacherName}</div>
            <a
              className="ppt__element-video-link"
              href={props.data.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.data.url}
            </a>
            <div>{props.data.description}</div>
          </div>
        );
        break;

      // edge case where we've saved a Spotlight without determining its type
      case 'Spotlight':
        elementNode = (
          <div className="">
            <a href={props.data.url} target="_blank" rel="noopener noreferrer">
              {props.data.url}
            </a>
          </div>
        );
        break;
    }
    buttonsNode = (
      <div>
        <a className="tc__link" onClick={(e) => handleChangeMode('edit', e)}>
          Edit
        </a>{' '}
        |{' '}
        <a className="tc__link" onClick={handleDelete}>
          Remove
        </a>
      </div>
    );
  }

  // if we have a saved element,
  // we need to make room on the left for the visibility and type icon nodes
  const bodyClass = visibilityNode ? ' ppt-element__body--has-element' : '';

  // rendering
  return (
    <div className="ppt-element" data-id={props.id}>
      {dragHandleNode}
      {visibilityNode}
      {iconNode}
      <div className={'ppt-element__body' + bodyClass}>
        {elementNode}
        {buttonsNode}
      </div>
    </div>
  );
}

Element.propTypes = {
  editEntry: PropTypes.func.isRequired,
  deleteEntry: PropTypes.func.isRequired,

  id: PropTypes.number,
  type: PropTypes.string,
  version: PropTypes.number,
  //visible: React.PropTypes.bool,
  data: PropTypes.object,
  mode: PropTypes.string,
  isLoading: PropTypes.bool,

  // the following are only necessary for adding a new element
  sectionId: PropTypes.number,
  subjectId: PropTypes.number,
  growthAreaId: PropTypes.number,
  // optional: use this to limit the select type choices to only the ones that appear
  // in the Spotlights section (i.e. Quote, Spotlight, and CoachingSpotlight)
  spotlightsOnly: PropTypes.bool,
};
