// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import Utils from './../../../components/DynamicUtils';
import NavigationLink from './../../NavigationLink';

const AdminDashboard = (props) => {
  const lab = useLegacyLabContext();
  // default link lists
  let oldLinksNode = null;
  let bl4LinksNode = null;
  let devLinksNode = null;

  // v4 links
  const userManagementNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/users'} className="tc__link">
        User Management
      </NavigationLink>
    </div>
  );
  const cohortManagementNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/cohorts'} className="tc__link">
        Cohort Management
      </NavigationLink>
    </div>
  );
  const partnerManagementNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/partners'} className="tc__link">
        Partner Management
      </NavigationLink>
    </div>
  );
  const importParticipantsNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink
        url={'/bl/tools/bulk-participant-importer'}
        className="tc__link"
      >
        Bulk Importer - Participants
      </NavigationLink>
    </div>
  );
  const importEventParticipantImportNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink
        url="/bl/tools/bulk-participant-event-import"
        className="tc__link"
      >
        Bulk Importer - Event Participants
      </NavigationLink>
    </div>
  );
  const curateCustomSchoolsNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/custom-schools'} className="tc__link">
        Curate Custom Schools
      </NavigationLink>
    </div>
  );
  // v3 links
  const partnerPortfoliosNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/v1/partners/portfolios'} className="tc__link">
        Partner Portfolios
      </NavigationLink>
    </div>
  );
  const strategyBrowserNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink
        url={'/coaching/lab/strategy-browser'}
        className="tc__link"
      >
        Strategy Browser
      </NavigationLink>
    </div>
  );
  const partnerHighlightsNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/partner-highlights'} className="tc__link">
        Partner Highlights
      </NavigationLink>
    </div>
  );
  const contentCurationV4Node = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/curation'} className="tc__link">
        Content Curation
      </NavigationLink>
    </div>
  );
  // dev Links
  const blLogsNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/admin/view_bl_logs'} className="tc__link">
        BL Logs
      </NavigationLink>
    </div>
  );
  // v5 links
  const eventManagementNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/events'} className="tc__link">
        Event Management
      </NavigationLink>
    </div>
  );

  const eventCapacityCalendarNode = (
    <div className="admin-v2__nav-link">
      <NavigationLink url={'/bl/events/capacity-calendar'} className="tc__link">
        Event Capacity Calendar
      </NavigationLink>
    </div>
  );

  if (Utils.isHelpDeskAgent.call(lab)) {
    bl4LinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">Admin Links</h3>
        <div className="cute-4-desktop cute-12-phone left">
          {userManagementNode}
          {eventManagementNode}
        </div>
      </div>
    );
  }

  if (Utils.isTeamMember.call(lab)) {
    bl4LinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">Admin Links</h3>
        <div className="cute-4-desktop cute-12-phone left">
          {eventCapacityCalendarNode}
          {userManagementNode}
          {cohortManagementNode}
          {eventManagementNode}
          {partnerManagementNode}
        </div>
      </div>
    );
    oldLinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">PPD v3 Links</h3>
        <div className="cute-4-desktop cute-12-phone left">
          {partnerHighlightsNode}
          {strategyBrowserNode}
        </div>
      </div>
    );
  }

  // according to what type of user you are generate your links
  if (Utils.isServicesManager.call(lab)) {
    bl4LinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">Admin Links</h3>
        <div className="cute-4-desktop cute-12-phone left">
          {eventCapacityCalendarNode}
          {userManagementNode}
          {cohortManagementNode}
          {eventManagementNode}
          {partnerManagementNode}
        </div>
      </div>
    );
    oldLinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">PPD v3 Links</h3>
        <div className="cute-4-desktop cute-12-phone left">
          {partnerHighlightsNode}
          {strategyBrowserNode}
        </div>
      </div>
    );
  }

  if (Utils.isContentCurator.call(lab)) {
    bl4LinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">Admin Links</h3>
        <div className="cute-4-desktop cute-12-phone left">
          {eventCapacityCalendarNode}
          {contentCurationV4Node}
          {userManagementNode}
          {cohortManagementNode}
          {eventManagementNode}
          {partnerManagementNode}
        </div>
      </div>
    );
    oldLinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">PPD v3 Links</h3>
        <div className="cute-4-desktop cute-12-phone left">
          {partnerHighlightsNode}
          {strategyBrowserNode}
        </div>
      </div>
    );
  }

  if (
    Utils.isDataEditor.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.isAdmin.call(lab)
  ) {
    bl4LinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">Admin Links</h3>
        <div className="cute-4-desktop cute-12-phone left">
          {eventCapacityCalendarNode}
          {userManagementNode}
          {cohortManagementNode}
          {eventManagementNode}
          {partnerManagementNode}
          {importParticipantsNode}
          {importEventParticipantImportNode}
          {curateCustomSchoolsNode}
          {contentCurationV4Node}
        </div>
      </div>
    );
    oldLinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">PPD v3 Links</h3>
        <div className="cute-4-desktop cute-12-phone left">
          {partnerPortfoliosNode}
          {partnerHighlightsNode}
          {strategyBrowserNode}
        </div>
      </div>
    );
    devLinksNode = (
      <div className="row">
        <h3 className="admin-v2__headline cute-12-desktop">BL Devs</h3>
        <div className="cute-4-desktop cute-12-phone left">{blLogsNode}</div>
      </div>
    );
  }

  return (
    <div className="tc__page">
      <Header page="admin" {...props} />
      <div className="row row--1200 admin">
        <div className="cute-12-desktop">
          {bl4LinksNode}
          {oldLinksNode}
          {devLinksNode}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
