// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Utils from './../../../../components/DynamicUtils';

const EngagementRow = (props) => {
  // change stats to percentages
  const es_percentages = (stat) => {
    if (stat === 'engaged') {
      return Utils.toPercent(
        props.engagement_stats.engaged,
        props.engagement_stats.total_engaged
      );
    } else {
      return Utils.toPercent(props.engagement_stats[stat], props.capacity);
    }
  };

  return (
    <div className="grid-ten__row">
      <div className="grid-ten__col-3">
        <span data-testid={`${props.id}-Cohort`}>
          <NavigationLink
            url={`/lab/cohort/${props.id}/overview`}
            className="tc__link"
          >
            {props.display_name}
          </NavigationLink>
          <br />
          {moment(props.term_start).format('MMMM D, Y')} &mdash;{' '}
          {moment(props.term_end).format('MMMM D, Y')}
        </span>
      </div>
      <div className="grid-ten__col-2" data-testid={`${props.id}-Size`}>
        {props.capacity}
      </div>
      <div className="grid-ten__col-2" data-testid={`${props.id}-Registered`}>
        <NavigationLink
          url={`/lab/cohort/${props.id}/registration?sortCol=registered`}
          className="tc__link"
        >
          {es_percentages('registered')}%
        </NavigationLink>
      </div>
      <div className="grid-ten__col-2" data-testid={`${props.id}-Scheduled`}>
        <NavigationLink
          url={`/lab/cohort/${props.id}/registration?sortCol=meeting`}
          className="tc__link"
        >
          {es_percentages('scheduled')}%
        </NavigationLink>
      </div>
      <div className="grid-ten__col-2" data-testid={`${props.id}-Completed`}>
        <NavigationLink
          url={`/lab/cohort/${props.id}/registration?sortCol=meeting-status`}
          className="tc__link"
        >
          {es_percentages('completed')}%
        </NavigationLink>
      </div>
      <div className="grid-ten__col-2" data-testid={`${props.id}-Engaged`}>
        <NavigationLink
          url={`/lab/cohort/${props.id}/meetings?sortCol=next-meeting`}
          className="tc__link"
        >
          {es_percentages('engaged')}%
        </NavigationLink>
      </div>
    </div>
  );
};

EngagementRow.propTypes = {
  display_name: PropTypes.string.isRequired,
  capacity: PropTypes.number.isRequired,
  term_start: PropTypes.string.isRequired,
  term_end: PropTypes.string.isRequired,
  engagement_stats: PropTypes.string.isRequired,
};

export default EngagementRow;
