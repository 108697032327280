// @ts-nocheck FIXME
import React from 'react';
import Analytics from './../../components/Analytics';
import LayerManager from './../../components/LayerManager';
import { History } from './../../components/Router';

class LayerClose extends React.Component {
  handleClick = (e) => {
    e.preventDefault();
    LayerManager.close();
    if (this.props.url) {
      History.replace({}, '', this.props.url);
    }
    if (this.props.analyticsEvent) {
      Analytics.logEvent(this.props.analyticsEvent);
    }
    if (
      this.props.onClickCallback &&
      typeof this.props.onClickCallback === 'function'
    ) {
      this.props.onClickCallback();
    }
  };

  render() {
    let classNames = 'react-layer-close';
    if (this.props.className) {
      classNames += ' ' + this.props.className;
    }
    let id = null;
    if (this.props.id) {
      id = this.props.id;
    }
    return (
      <a
        id={id}
        className={classNames}
        href="#close-layer"
        onClick={this.handleClick}
      >
        {this.props.children}
      </a>
    );
  }
}

export default LayerClose;
