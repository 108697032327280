// @ts-nocheck FIXME
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import React from 'react';

const DeletePartnerLeadModal = (props) => {
  const [generalError, setGeneralError] = React.useState('');

  const handleCloseModal = (e) => {
    e.preventDefault();
    props.close();
  };

  const handleDeletePl = (e) => {
    e.preventDefault();

    props.deletePl({
      partnerId: props.partnerId,
      leaderId: props.partnerLeaderId,
      successCallback: props.close,
      failureCallback: setGeneralError,
    });
  };

  // disable buttons when submitting
  const btnProps = props.isLoading ? { disabled: true } : null;

  const errorNode = generalError && <ErrorText error={generalError} />;

  return (
    <ActionModal
      headlineText={`Delete ${props.partnerLeaderName} from ${props.partnerName}`}
      close={handleCloseModal}
      spaceFromTop={50}
    >
      <form className="participant-v5__modal-body participant-v5__participant-meeting-clip-modal">
        <div className="participant-v5__modal-top-row">
          This will remove their access to all cohorts and events—current and
          past—associated with the {props.partnerName} partnership.
          <div className="participant-v5__modal-bottom-row">
            <div>
              <button
                className="v5__btn v5__btn--red"
                onClick={handleDeletePl}
                {...btnProps}
                data-testid="delete-pl-button"
              >
                <Spinner loading={props.isLoading} />
                Delete
              </button>
              <div>{errorNode}</div>
            </div>
          </div>
        </div>
      </form>
    </ActionModal>
  );
};

DeletePartnerLeadModal.propTypes = {
  partnerId: PropTypes.number.isRequired,
  partnerName: PropTypes.string.isRequired,
  partnerLeaderId: PropTypes.number.isRequired,
  partnerLeaderName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  close: PropTypes.func.isRequired,
  deletePl: PropTypes.func,
};

export default DeletePartnerLeadModal;
