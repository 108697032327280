// @ts-nocheck FIXME
import React from 'react';
import ReactDOM from 'react-dom';
import StrategyApi from './../../../apis/StrategyApi';
import Analytics from './../../../components/Analytics';
import LayerManager from './../../../components/LayerManager';
import StrategyMultiSelectStore from './../../../stores/V2/StrategyMultiSelectStore';
import LayerClose from './../../LayerManager/LayerClose';
import MultiSelect from './MultiSelectCustom';

class CoachAddChallengeDialogue extends React.Component {
  constructor(props) {
    super(props);
    StrategyMultiSelectStore.actionDispatchSync('init', props);
    this.state = StrategyMultiSelectStore.getState();
  }

  token = null;

  getAnalyticsEventLabel = () => {
    return 'from_coach_add_challenge_modal';
  };

  componentDidMount() {
    this.token = StrategyMultiSelectStore.subscribe(this.onStoreChange);
  }

  componentWillUnmount() {
    StrategyMultiSelectStore.actionDispatchSync('init', {});
    StrategyMultiSelectStore.unsubscribe(this.token);
  }

  onStoreChange = () => {
    this.setState(StrategyMultiSelectStore.getState());
  };

  handleAddChallenge = (e) => {
    e.preventDefault();

    StrategyMultiSelectStore.actionDispatchSync('setBtnDisabled', true);
    StrategyMultiSelectStore.actionDispatchSync('setErrors', {});

    // make sure at least one name is selected
    if (!this.state.teachers.length && !this.state.teams.length) {
      StrategyMultiSelectStore.actionDispatchSync('setBtnDisabled', false);
      StrategyMultiSelectStore.actionDispatchSync('setErrors', {
        names: ['You must select at least one name'],
      });
      return;
    }

    // build the data to be submitted
    const challengeData = {
      coachId: this.props.user.id,
      userId: this.state.teachers,
      teamId: this.state.teams,
      challengeId: this.props.challenge.id,
    };

    // Log the Add Analytics events:
    Analytics.logEvent({
      category: 'teaching_challenge',
      action: 'add_to_map',
      label: this.getAnalyticsEventLabel(),
    });

    // if this is checked, we add the challenge in the "activated" state instead of "added";
    // otherwise, we leave the challenge at the state it is in
    const activate = ReactDOM.findDOMNode(this.refs.activate).checked;
    if (activate) {
      challengeData.status = 'activated';
      // Add the activate event if needed:
      Analytics.logEvent({
        category: 'teaching_challenge',
        action: 'activate',
        label: this.getAnalyticsEventLabel(),
      });
    }

    // add the challenge to the selected teachers' growth maps
    StrategyApi.addChallengeCoach(challengeData)
      .then(
        function (response) {
          LayerManager.close();
          StrategyMultiSelectStore.actionDispatch('setBtnDisabled', false);
          StrategyMultiSelectStore.actionDispatch('setSelections', {
            teachers: [],
            teams: [],
          });
          this.props.onShowAlert('success', 'Challenge successfully added!');
        }.bind(this)
      )
      .catch(
        function (response) {
          LayerManager.close();
          StrategyMultiSelectStore.actionDispatch('setBtnDisabled', false);
          StrategyMultiSelectStore.actionDispatch('setSelections', {
            teachers: [],
            teams: [],
          });
          this.props.onShowAlert(
            'error',
            'Sorry, there was a problem adding this challenge. Please try again later.'
          );
        }.bind(this)
      );
  };

  render() {
    let namesErrorClass = '';
    let namesErrorNode = null;
    if (this.state.errors.names) {
      namesErrorClass = ' error';
      namesErrorNode = (
        <div className="tc__error">
          {this.state.errors.names.map(function (error) {
            return <div key={'error-' + error}>{error}</div>;
          })}
        </div>
      );
    }

    const btnDisabledClass = this.state.btnDisabled ? ' disabled' : '';

    const closeModalAnalyticsEvent = {
      category: 'teaching_challenge',
      action: 'close',
      label: this.getAnalyticsEventLabel(),
    };

    return (
      <div className="coach-pin">
        <LayerClose
          href="#"
          className="modal__close"
          analyticsEvent={closeModalAnalyticsEvent}
        >
          <div className="tc-x"></div>
        </LayerClose>
        <h3 className="coach-pin__headline tc__headline-3">
          Add Challenge to Growth Map
        </h3>
        <form className="coach-pin__form">
          <div className="coach-pin__multiselect-container">
            <label className="coach-pin__label">For</label>
            <div className={'coach-pin__multiselect' + namesErrorClass}>
              <MultiSelect
                options={this.props.user.constituents}
                placeholder="Teacher Name or Team Name"
                analyticsLabel={this.getAnalyticsEventLabel()}
              />
            </div>
            <div className="clearfix"></div>
            {namesErrorNode}
          </div>
          <div className="coach-pin__checkbox-container">
            <input
              type="checkbox"
              defaultChecked={true}
              ref="activate"
              id="activate"
              className="tc__input coach-pin__checkbox"
            />
            <label htmlFor="activate" className="coach-pin__checkbox-label">
              Activate Challenge
            </label>
          </div>
          <a
            href="#"
            onClick={this.handleAddChallenge}
            className={
              'tc__btn tc__btn--blue tc__btn--small' + btnDisabledClass
            }
          >
            Add Challenge
          </a>
        </form>
      </div>
    );
  }
}

export default CoachAddChallengeDialogue;
