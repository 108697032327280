// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const PartnerRow = (props) => {
  const partner = props.partner;
  let growthNode = (
    <div className="cute- partner_highlights_cute_12">No measurements</div>
  );
  if (partner.growth > 0) {
    growthNode = (
      <div className="cute- partner_highlights_cute_12">
        <span className="partner_highlights_growth_18">
          {Math.round(partner.growth * 100.0) / 100.0}
        </span>
        <span className="partner_highlights_growth_18">x</span>
      </div>
    );
  } else if (partner.starting_point) {
    growthNode = (
      <div className="cute- partner_highlights_cute_12">
        {partner.starting_point.name}
      </div>
    );
  }
  return (
    <div className="cute-12-desktop ppd-3-coach-table__row">
      <div className="cute-4-desktop">
        <div className="ppd-3-coach-table__user-name">{partner.name}</div>
        <div className="tc__breakword ppd-3-coach-table__user">
          {partner.cohort_count} Cohort{partner.cohort_count == 1 ? '' : 's'}
        </div>
      </div>
      <div className="cute-3-desktop">
        <div className="ppd-3-coach-table__user-name">
          {partner.participant_count} Participant
          {partner.participant_count == 1 ? '' : 's'}
        </div>
        {partner.products &&
          Object.keys(partner.products).map((key, index) => {
            return (
              <div key={index}>
                <span className="ppd-3-coach-table__user-name">
                  {partner.products[key] == partner.participant_count
                    ? ''
                    : partner.products[key]}
                </span>
                <span> {key}</span>
              </div>
            );
          })}
      </div>

      {growthNode}

      <div className="cute- partner_highlights_cute_10">
        {partner.meeting_count}
      </div>

      <div className="cute-1-desktop">
        <div>{partner.artifact_count}</div>
      </div>

      <div className="cute- partner_highlights_cute_10">
        <div>{partner.lead_count}</div>
      </div>
    </div>
  );
};

PartnerRow.propTypes = {
  partner: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
};

export default PartnerRow;
