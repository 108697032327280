// @ts-nocheck FIXME
import Request from './../components/Request';

const PartnerApi = {
  createPartner: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/partner/create',
      JSON.stringify(data)
    ),

  editPartner: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/' +
        data.accountId +
        '/edit',
      JSON.stringify(data)
    ),

  addPartnerUser: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/' +
        data.accountId +
        '/add_user',
      JSON.stringify(data)
    ),

  editPSMs: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/' +
        data.partner_id +
        '/psms/edit',
      JSON.stringify(data)
    );
  },

  addNewPL: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/bl/partner/${data.partnerId}/add_new_pl`,
      JSON.stringify(data)
    );
  },

  editDPs: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/' +
        data.partner_id +
        '/dps/edit',
      JSON.stringify(data)
    );
  },

  sendPartnerLeaderSurveys: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/' +
        data.id +
        '/surveys/send',
      JSON.stringify(data)
    );
  },

  sendPartnerLeaderSurvey: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/partner/' +
        data.partnerId +
        '/leader/' +
        data.leaderId +
        '/survey/send',
      JSON.stringify(data)
    );
  },

  addExistingPL: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/bl/partner/${data.partnerId}/add_existing_pl`,
      JSON.stringify(data)
    );
  },
  deletePartnerLeader: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/bl/partner/${data.partnerId}/leader/${data.leaderId}/delete`,
      JSON.stringify(data)
    );
  },
};

export default PartnerApi;
