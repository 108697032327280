// @ts-nocheck FIXME
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import moment from 'moment';

const SelfAssessmentProgress = (props) => {
  const renderQuestion = (question) => (
    <div
      className="self-assessment__response-question v5__heading-5"
      data-test-id="selfAssesmentQuestion"
    >
      {question}
    </div>
  );

  const renderLegend = () => (
    <div
      className="self-assessment__response-options"
      data-test-id="selfAssesmentLegend"
    >
      <div className="self-assessment__response-option self-assessment__response-option--1">
        Strongly Disagree
      </div>
      <div className="self-assessment__response-option self-assessment__response-option--2">
        Disagree
      </div>
      <div className="self-assessment__response-option self-assessment__response-option--3">
        Neither Agree nor Disagree
      </div>
      <div className="self-assessment__response-option self-assessment__response-option--4">
        Agree
      </div>
      <div className="self-assessment__response-option self-assessment__response-option--5">
        Strongly Agree
      </div>
    </div>
  );

  const getBarEndClass = ({ start, end, isDecreasing }) => {
    if (start === end) {
      return 'self-assessment__response-bar-end--center';
    }

    if (isDecreasing) {
      return 'self-assessment__response-bar-end--backwards';
    }

    return '';
  };

  const getBarEndStyles = ({ start, end, isDecreasing }) => {
    const left = isDecreasing ? `${start * 25}%` : `${end * 25}%`;

    if (left === '100%') {
      return {
        left,
        marginLeft: '-30px',
      };
    }

    if (left === '0%') {
      return { left };
    }

    return {
      left,
      marginLeft: '-15px',
    };
  };

  const renderBar = ({ start, end, isDecreasing }) => {
    const className = isDecreasing
      ? 'self-assessment__response-bar--backwards'
      : '';

    return start !== end ? (
      <div
        className={`self-assessment__response-bar ${className}`}
        style={{ left: `${start * 25}%`, width: `${(end - start) * 25}%` }}
        data-test-id="selfAssesmentBar"
      />
    ) : null;
  };

  const renderTooltipText = (currentAssessmentDate) => (
    <span>
      Current Assessment Date
      <br />
      {moment(currentAssessmentDate).format('L')}
    </span>
  );

  const getTooltipOffsetLeft = (left) => {
    if (left === '0%') {
      return -5;
    }

    if (left === '100%') {
      return `calc(${left} - 35px)`;
    }

    return `calc(${left} - 20px)`;
  };

  const renderBarEnd = ({
    start,
    end,
    isDecreasing,
    currentAssessmentDate,
  }) => {
    const className = getBarEndClass({ start, end, isDecreasing });
    const styles = getBarEndStyles({ start, end, isDecreasing });

    return (
      <Tooltip
        delay={300}
        tooltip={renderTooltipText(currentAssessmentDate)}
        tipClass="v5__body-copy--center"
        offsetTop={35}
        offsetLeft={getTooltipOffsetLeft(styles.left)}
        minWidth={163}
      >
        <div
          className={`self-assessment__response-bar-end ${className}`}
          style={styles}
          data-test-id="selfAssesmentBar"
        />
      </Tooltip>
    );
  };

  const renderBarGraph = (surveyResponse) => {
    const isDecreasing =
      surveyResponse.first_answer > surveyResponse.last_answer;
    const start =
      Math.min(surveyResponse.first_answer, surveyResponse.last_answer) - 1;
    const end =
      Math.max(surveyResponse.first_answer, surveyResponse.last_answer) - 1;
    const currentAssessmentDate = surveyResponse.current_assessment_date;

    return (
      <div className="self-assessment__response-graph">
        {renderBarEnd({ start, end, isDecreasing, currentAssessmentDate })}
        {renderBar({ start, end, isDecreasing })}
      </div>
    );
  };

  const renderResponses = () => (
    <div className="self-assessment" data-test-id="selfAssesmentResponses">
      {(props.answers || []).map((answer) => (
        <div
          className="self-assessment__response-container"
          key={`survey-response-${answer.id}`}
        >
          {renderQuestion(answer.question)}
          {renderBarGraph(answer)}
          {renderLegend()}
        </div>
      ))}
    </div>
  );

  return (
    <div className="hide-phone workspace__entry-body">{renderResponses()}</div>
  );
};

export default SelfAssessmentProgress;
