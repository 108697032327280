// @ts-nocheck FIXME
import ParticipantEngagementDetails from '@legacy-components/react/V5/Common/ParticipantEngagementDetails/ParticipantEngagementDetails';
import PropTypes from 'prop-types';
import ZenScroll from 'zenscroll';
import AbsoluteAlert from './../../Alerts/Absolute';
import MobileStickyHeader from './../Common/MobileStickyHeader';
import UserPhoto from './../Common/UserPhoto';
import Header from './../Header/HeaderContainer';
import WorkspaceBody from './modules/WorkspaceBody';
import WorkspaceModal from './modules/WorkspaceModal';

const WorkspaceView = (props) => {
  // get the current user's timezone for meeting times (default is EST)
  const userTimeZone = props.user.time_zone.pytz_timezone;

  // if the workspace owner is a district coach teacher, hide all Highlights-related functionality
  const handleScrollToTop = () => {
    ZenScroll.toY(0, 600);
  };

  // Build the alert node if needed
  let alertNode;
  if (Object.keys(props.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  return (
    <div className="tc__page workspace">
      <Header page="Workspace" {...props} responsive={true} />
      <MobileStickyHeader>
        <div className="workspace__mobile-header">
          <UserPhoto
            src={props.pageUser.photo}
            userId={props.pageUser.id}
            firstName={props.pageUser.first_name}
            lastName={props.pageUser.last_name}
            diameter={30}
            className="workspace__mobile-header-photo"
          />
          <div className="workspace__mobile-header-name">
            {props.pageUser.first_name} {props.pageUser.last_name}
          </div>
          <div
            className="tc-v5-arrow-up workspace__mobile-header-arrow"
            onClick={handleScrollToTop}
          ></div>
        </div>
      </MobileStickyHeader>

      {alertNode}
      <WorkspaceModal {...props} />

      <ParticipantEngagementDetails
        {...props.activeParticipant}
        latest_engagement={props.latestEngagement}
        user={props.pageUser}
        time_zone={props.pageUser.time_zone}
        coach={props.latestEngagement && props.latestEngagement.coach}
        currentUser={props.user}
        canEditData={props.canEditAuthor}
        userTimeZone={userTimeZone}
        setSuccessAlert={props.setSuccessAlert}
        responsive={true}
        domains={props.domains}
        cohortPriorityFocusAreas={props.cohortPriorityFocusAreas}
      />

      <WorkspaceBody {...props} />
    </div>
  );
};

WorkspaceView.propTypes = {
  activeParticipant: PropTypes.object,
  pageUser: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  newGoalFormVisible: PropTypes.bool.isRequired,
};

export default WorkspaceView;
