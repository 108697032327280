// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const CoachTools = (props) => {
  const [displayGCalTip, setdisplayGCalTip] = useState(false);
  const displyGCalToolTip = () => {
    setdisplayGCalTip(true);

    delayedEventListener(() =>
      document.addEventListener('click', hideGCalToolTip)
    );
  };
  const hideGCalToolTip = () => {
    setdisplayGCalTip(false);
    document.removeEventListener('click', hideGCalToolTip);
  };

  const [displayZoomTip, setdisplayZoomTip] = useState(false);
  const displyZoomToolTip = () => {
    setdisplayZoomTip(true);

    delayedEventListener(() =>
      document.addEventListener('click', hideZoomToolTip)
    );
  };
  const hideZoomToolTip = () => {
    setdisplayZoomTip(false);
    document.removeEventListener('click', hideZoomToolTip);
  };

  const calendlyLink = props.calendly_link && (
    <div>
      <div className="v5__heading-6 admin-v5_coach-dashboard-tool-headline">
        Calendly
      </div>
      <div className="admin-v5_coach-dashboard-tool-actions">
        <NavigationLink
          className="tc__link"
          url={props.calendly_link}
          target="_blank"
        >
          {props.calendly_link}
        </NavigationLink>
      </div>
    </div>
  );

  // Create sync button node
  let syncGCalButtonNode = null;
  if (!props.pageUser.has_synced_google_calendar && props.canSeeAgenda) {
    const toolTipNode = displayGCalTip ? (
      <div className="admin-v5_coach-dashboard-tool-tip">
        Connect your Google account to see your full schedule in the agenda
        below.
      </div>
    ) : null;

    // If the calendar integration has been authorized, show the calendar
    syncGCalButtonNode = (
      <div className="admin-v5_coach-dashboard-tool-actions">
        <a href="/api/coach/google_oauth2_authorize" className="v5__link">
          Sync to Google Calendar
        </a>
        <div
          className="tc-v5-question admin-v5_coach-dashboard-tool-icon"
          onClick={displyGCalToolTip}
        />
        {toolTipNode}
      </div>
    );
  } else if (props.pageUser.has_synced_google_calendar) {
    const disconnectGCal = props.canDisconnect && (
      <a
        className="v5__link"
        onClick={() => props.setShowConfirmDisconnectGCal(true)}
      >
        Disconnect
      </a>
    );
    syncGCalButtonNode = (
      <div className="admin-v5_coach-dashboard-tool-actions">
        <div className="tc-v5-sync admin-v5_coach-dashboard-tool-icon" />
        <div>
          <div className="admin-v5_coach-dashboard-tool-title">
            Google Calendar&nbsp;&nbsp;
          </div>
          {disconnectGCal}
        </div>
      </div>
    );
  }

  // allow BL Coaches to connect their Zoom account to sync meetings
  let syncZoomButtonNode;
  // if the current user is an Admin or User Manager, then they can disconnect the coach from Zoom
  if (props.pageUser.has_synced_zoom) {
    const disconnectZoom = props.canDisconnect && (
      <div>
        <a
          className="v5__link"
          onClick={() => props.setShowConfirmDisconnectZoom(true)}
        >
          Disconnect
        </a>
      </div>
    );
    syncZoomButtonNode = (
      <div className="admin-v5_coach-dashboard-tool-actions">
        <div className="tc-v5-sync admin-v5_coach-dashboard-tool-icon" />
        <div>
          <div className="admin-v5_coach-dashboard-tool-title">Zoom</div>
          {disconnectZoom}
        </div>
      </div>
    );
  } else if (props.canSeeAgenda) {
    // show the button for the coach to sync their Zoom account if they have not already;
    // otherwise, show the link for them to uninstall us from Zoom
    if (!props.pageUser.has_synced_zoom) {
      const toolTipNode = displayZoomTip ? (
        <div className="admin-v5_coach-dashboard-tool-tip">
          Connect your Zoom account to create and update Zoom meetings from the
          Lab. Your meeting recordings will also automatically upload to meeting
          records.
        </div>
      ) : null;

      syncZoomButtonNode = (
        <div className="admin-v5_coach-dashboard-tool-actions">
          <a href="/api/coach/zoom_oauth2_authorize" className="v5__link">
            Sync to Zoom
          </a>
          <div
            className="tc-v5-question admin-v5_coach-dashboard-tool-icon"
            onClick={displyZoomToolTip}
          />
          {toolTipNode}
        </div>
      );
    } else {
      const toolTipNode = displayZoomTip ? (
        <div className="admin-v5_coach-dashboard-tool-tip admin-v5_coach-dashboard-tool-tip--connected">
          To disconnect your Zoom account from the Lab, contact the Manager of
          Operations.
        </div>
      ) : null;

      syncZoomButtonNode = (
        <div className="admin-v5_coach-dashboard-tool-actions">
          <div className="tc-v5-sync admin-v5_coach-dashboard-tool-icon" />
          <div>
            <div className="admin-v5_coach-dashboard-tool-title">Zoom</div>
            <div>
              <a className="v5__link" onClick={displyZoomToolTip}>
                Disconnect
              </a>
            </div>
          </div>
          {toolTipNode}
        </div>
      );
    }
  }
  let syncedAppsHeader = null;
  if (syncGCalButtonNode || syncZoomButtonNode) {
    syncedAppsHeader = (
      <div className="v5__heading-6 admin-v5_coach-dashboard-tool-headline">
        Synced Apps
      </div>
    );
  }
  return (
    <React.Fragment>
      <h3 className="show-desktop hide-phone admin-v5_coach-dashboard__calendar-heading--tools v5__heading-3">
        Meeting Tools
      </h3>
      {calendlyLink}
      <div className="admin-v5_coach-dashboard--mobile-ordering">
        {syncedAppsHeader}
        {syncGCalButtonNode}
        {syncZoomButtonNode}
      </div>
    </React.Fragment>
  );
};
CoachTools.propTypes = {
  calendlyLink: PropTypes.string,
  canDisconnect: PropTypes.bool,
  canSeeAgenda: PropTypes.bool,
  pageUser: PropTypes.object.isRequired,
  setShowConfirmDisconnectGCal: PropTypes.func.isRequired,
};

export default CoachTools;
