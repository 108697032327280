// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

const Carousel = (props) => {
  const mq = window.matchMedia('(max-width: 48em)');

  const [cardWidth, setCardWidth] = React.useState(0);
  const [wrapperWidth, setWrapperWidth] = React.useState(0);
  const [slidePosition, updateSlidePosition] = React.useState(0);

  let slideStep = props.slideStep;
  // functionality only needed if carousel buttons are being used
  if (
    props.slideSelected !== undefined &&
    props.slideSelected !== slidePosition
  ) {
    updateSlidePosition(props.slideSelected);
  }
  // on mount use set the width of the container to element.width * elements.length
  React.useEffect(() => {
    // get the elements width
    const elem = document.querySelectorAll('.v5-carousel__item')[0];
    const elemWidth = elem ? elem.offsetWidth : 0;

    setCardWidth(elemWidth);
    setWrapperWidth((elemWidth + 20) * props.children.length - 20);
  }, []);

  if (mq.matches) {
    slideStep = 1;
  }

  // give visible slides an active class so they can be affected via css
  let sliderItemsNode;
  if (props.hideInactiveSlides !== undefined) {
    sliderItemsNode = props.children.map((child, i) => {
      const activePositions = [];

      for (let j = 0; j < slideStep; j++) {
        if (i > props.children.length) {
          break;
        }
        activePositions.push(slidePosition + j);
      }

      return (
        <div
          className={`v5-carousel__item${
            activePositions.indexOf(i) !== -1 ? ' active' : ''
          }`}
          key={`slider-${props.uniqueKey}-item-${i}`}
        >
          {child}
        </div>
      );
    });
  } else {
    sliderItemsNode = props.children.map((child, i) => {
      return (
        <div
          className="v5-carousel__item active"
          key={`slider-${props.uniqueKey}-item-${i}`}
        >
          {child}
        </div>
      );
    });
  }

  const previousSlidesAndButtons = (num) => {
    updateSlidePosition(slidePosition - slideStep);
    props.updateButton(num);
  };

  const nextSlidesAndButtons = (num) => {
    updateSlidePosition(slidePosition + slideStep);
    props.updateButton(num);
  };

  let containerFirstStepClass = '';

  let previousFunctionToUse = updateSlidePosition;
  let previousFunctionArgument = slidePosition - slideStep;

  if (props.slideSelected !== undefined) {
    previousFunctionToUse = previousSlidesAndButtons;
    previousFunctionArgument = slidePosition - 1;
  }

  let previousControlNode = (
    <div
      className="v5-carousel__control v5-carousel__control--last"
      onClick={() => previousFunctionToUse(previousFunctionArgument)}
    >
      <div className="tc-bold-arrow"></div>
    </div>
  );

  // hide previous button if in the first position
  if (slidePosition === 0) {
    containerFirstStepClass = 'v5-carousel__container--first';
    previousControlNode = null;
  }

  let containerLastStepClass = '';

  let nextFunctionToUse = updateSlidePosition;
  let nextFunctionArgument = slidePosition + slideStep;

  if (props.slideSelected !== undefined) {
    nextFunctionToUse = nextSlidesAndButtons;
    nextFunctionArgument = slidePosition + 1;
  }

  let nextControlNode = (
    <div
      className="v5-carousel__control v5-carousel__control--next"
      onClick={() => nextFunctionToUse(nextFunctionArgument)}
    >
      <div className="tc-bold-arrow"></div>
    </div>
  );

  // hide next controls if there are no more to see
  if (slidePosition >= props.children.length - slideStep) {
    containerLastStepClass = 'v5-carousel__container--last';
    nextControlNode = null;
  }

  return (
    <div
      className={`v5-carousel__container ${containerFirstStepClass} ${containerLastStepClass} ${props.classType}`}
    >
      {previousControlNode}
      <div
        className="v5-carousel__slide"
        style={{
          width: wrapperWidth,
          marginLeft: `-${slidePosition * (cardWidth + 20)}px`,
        }}
      >
        {sliderItemsNode}
      </div>
      {nextControlNode}
    </div>
  );
};

export default Carousel;

Carousel.propTypes = {
  slideStep: PropTypes.number,
  uniqueKey: PropTypes.string.isRequired,
  analytics: PropTypes.object,
  slideSelected: PropTypes.number,
};
Carousel.defaultProps = {
  slideStep: 3,
};
