// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';

const User = (props) => {
  const lab = useLegacyLabContext();

  const generateNavOptions = () => {
    const navOptions = [];

    navOptions.push({
      caption: 'Favorites',
      link: process.env.REACT_APP_TEACHING_URL + '/browse/favorites',
      external: true,
    });

    if (
      Utils.isAdmin.call(lab) ||
      Utils.isManager.call(lab) ||
      Utils.isContentCurator.call(lab) ||
      Utils.isDataViewer.call(lab) ||
      Utils.isServicesManager.call(lab) ||
      Utils.isContractCoach.call(lab) ||
      Utils.isParticipant.call(lab) ||
      Utils.isHelpDeskAgent.call(lab)
    ) {
      navOptions.push({
        caption: 'Expectations & Privacy',
        link: `${process.env.REACT_APP_MARKETING_URL}/privacy-policy`,
        external: true,
        newTab: true,
      });
    }

    navOptions.push({
      caption: 'Log Out',
      link: '/logout',
      external: true,
    });
    return navOptions;
  };

  return (
    <Dropdown
      navKey={props.viewType === 'mobile' ? 'mobile-user' : 'user'}
      user={props.user}
      navOptions={generateNavOptions()}
      className="header__dropdown-container--user"
    >
      <UserPhoto
        src={props.user.photo}
        userId={props.user.id}
        firstName={props.user.first_name}
        lastName={props.user.last_name}
        diameter={40}
        className="header__user"
      />
    </Dropdown>
  );
};

const ConnectedUser = (props) => (
  <HeaderConsumer>
    {({ user }) => <User {...props} user={user} />}
  </HeaderConsumer>
);

export default ConnectedUser;

User.propTypes = {
  viewType: PropTypes.string,
  user: PropTypes.object.isRequired,
};
