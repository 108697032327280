// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Element from './Element';
import Sortable from './Sortable';

// a free-form section only has a list of elements in it
const FreeFormSection = (props) => {
  const newElementNode = (
    <Element
      {...props.section.newEntry}
      sectionId={props.section.id}
      editEntry={props.editEntry}
      deleteEntry={props.deleteEntry}
    />
  );

  const elementsNode =
    props.section.entries &&
    props.section.entries.map((element, i) => {
      return (
        <Element
          key={element.id}
          increment={i}
          {...element}
          editEntry={props.editEntry}
          deleteEntry={props.deleteEntry}
        />
      );
    });

  // attach sorting functionality to entries
  const SortableEntries = Sortable(
    <ul id="freeFormElementsContainer">{elementsNode}</ul>,
    '#freeFormElementsContainer',
    'window',
    (entryIds) =>
      props.sortFreeFormEntries({
        section: props.section.type,
        entryIds: entryIds,
      })
  );

  // rendering
  return (
    <div className="ppt-section">
      <h1 className="tc__headline-2">{props.section.name}</h1>

      <div>
        <SortableEntries />
        {newElementNode}
      </div>
    </div>
  );
};

FreeFormSection.propTypes = {
  section: PropTypes.object.isRequired,
  sortFreeFormEntries: PropTypes.func.isRequired,
};

export default FreeFormSection;
