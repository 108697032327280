// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const ConfirmAction = (props) => {
  const handleConfirm = (e) => {
    e.preventDefault();
    const data = props.data;
    data['actionConfirmed'] = true;
    return props.confirmCallback(data);
  };

  const handleClose = (e) => {
    e.preventDefault();
    props.closeCallback();
  };

  return (
    <div>
      <div
        className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
        onClick={handleClose}
      ></div>
      <div className="modal--fixed modal--grey-overlay ppd-v3-curation-modal ppd-v3-curation-modal--short">
        <div className="modal__close" onClick={handleClose}>
          <div className="tc-x"></div>
        </div>
        <div className="ppd-v3-curation-modal__body">
          <div className="tc__headline-2 pinboard__comment-headline">
            {props.labels.title}
          </div>
          <div className="tc__body-copy">{props.labels.prompt}</div>
          <div className="vision__artifact-link-button participant-artifact__uplaod-buttons tc__center-text">
            <button
              className="tc__btn tc__btn--v3 tc__btn--flat-blue"
              onClick={handleConfirm}
            >
              {props.labels.confirm}
            </button>
            <button
              className="tc__btn tc__btn--v3 tc__btn--flat-grey"
              onClick={handleClose}
            >
              {' '}
              {props.labels.cancel}{' '}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmAction.propTypes = {
  confirmCallback: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

ConfirmAction.defaultProps = {
  labels: {
    prompt: 'Are you sure you want to proceed?',
    confirm: 'Yes',
    cancel: 'No',
    title: 'Confirm Action',
  },
  data: {},
};

export default ConfirmAction;
