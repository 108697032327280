// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ScaleCenterImage from './../../ScaleCenterImage';

const getImageSrc = (props) => {
  // the "detailed" version has a smaller image preview
  const noPreviewClass = props.showDetailed
    ? 'ppp__elem-image--no-preview-small'
    : '';
  const noPreviewImage =
    '<div class="ppp__elem-image--no-preview ' +
    noPreviewClass +
    '"><div class="artifact__type-icon artifact__type-icon--no-preview file-type-icon ext-' +
    props.data.extension +
    '"></div></div>';
  const containerElem = 'ppp__elem-preview--' + props.id;

  // create image instance
  const image = new Image();

  // Set the image source to the preview in props if we have it
  if (
    props.data.content &&
    props.data.content.preview &&
    props.data.content.preview.length > 0
  ) {
    image.src = props.data.content.preview[0];
  } else {
    // There is no preview. Trigger the image onError event
    image.src = '';
  }
  // Uses the no preview image if the image.src is invalid
  image.onerror = function () {
    document.getElementById(containerElem).innerHTML = noPreviewImage;
  };

  return image.src;
};

const Artifact = (props) => {
  const displayArtifactDetails = (e) => {
    e.preventDefault();
    props.showArtifact(props.data);
  };

  const imageSrc = getImageSrc(props);

  // on most pages, we show the default artifact spotlight capsule
  let spotlightNode = (
    <div
      onClick={displayArtifactDetails}
      className="ppp__elem ppp__elem--artifact cute-10-desktop cute-1-desktop-offset left"
    >
      <div className="ppp__elem-preview-container">
        <div
          className="ppp__elem-preview"
          id={'ppp__elem-preview--' + props.id}
        >
          <ScaleCenterImage src={imageSrc} className="ppp__elem-image" />
        </div>
      </div>
      <div className="ppp__artifact-details">
        <div className="artifact__type artifact__type--tml-v2">
          <div className="artifact__type-caption">{props.data.type_name}</div>
          <div className="artifact__flag-end tc-flag-end"></div>
        </div>
        <div className="ppp__artifact-filename-container">
          <span
            className={
              'artifact__type-icon file-type-icon ext-' + props.data.extension
            }
          ></span>
          <a className="ppp__artifact-filename tc__link">
            {props.data.filename}
          </a>
        </div>
        <div className="ppp__artifact-notes ppp__section-subheadline">
          {props.data.notes}
        </div>
        <div className="ppp__artifact-strategy ppp__section-subheadline">
          <span className="ppp__artifact-detail-declaration">Strategy: </span>
          <span className="ppp__artifact-detail-value">
            {props.data.strategy_name}
          </span>
        </div>
        <div className="ppp__section-subheadline">
          <span className="ppp__artifact-detail-declaration">
            Teaching Challenge:{' '}
          </span>
          <span className="ppp__artifact-detail-value">
            {props.data.teaching_challenge_name}
          </span>
        </div>
      </div>
    </div>
  );

  // in the Spotlights section, we show the same info, but it's layed out a bit differently
  if (props.showDetailed) {
    spotlightNode = (
      <div
        onClick={displayArtifactDetails}
        className="ppp__elem ppp__elem--artifact cute-10-desktop cute-1-desktop-offset left"
      >
        <div className="ppp__artifact-detailed-preview-col">
          <div
            className="ppp__elem-preview"
            id={'ppp__elem-preview--' + props.id}
          >
            <ScaleCenterImage
              src={imageSrc}
              className="ppp__elem-image--artifact-detail"
            />
          </div>
        </div>
        <div className="ppp__artifact-detailed-main-col">
          <div className="artifact__type artifact__type--tml-v2">
            <div className="artifact__type-caption">{props.data.type_name}</div>
            <div className="artifact__flag-end tc-flag-end"></div>
          </div>
          <div className="ppp__artifact-filename-container">
            <span
              className={
                'artifact__type-icon file-type-icon ext-' + props.data.extension
              }
            ></span>
            <a className="ppp__artifact-filename tc__link">
              {props.data.filename}
            </a>
          </div>
          <div className="ppp__artifact-notes ppp__artifact-notes--detailed ppp__section-subheadline">
            {props.data.notes}
          </div>
        </div>
        <div className="ppp__artifact-detailed-strategy-col">
          <div className="ppp__artifact-strategy ppp__section-subheadline">
            <div className="ppp__artifact-detail-declaration">Strategy:</div>
            <div className="ppp__artifact-detail-value">
              {props.data.strategy_name}
            </div>
          </div>
          <div className="ppp__section-subheadline">
            <div className="ppp__artifact-detail-declaration">
              Teaching Challenge:
            </div>
            <div className="ppp__artifact-detail-value">
              {props.data.teaching_challenge_name}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div className="row">{spotlightNode}</div>;
};

Artifact.propTypes = {
  data: PropTypes.object.isRequired,
  showArtifact: PropTypes.func.isRequired,
};

export default Artifact;
