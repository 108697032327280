// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import ZenScroll from 'zenscroll';
import Utils from './../../../../components/StaticUtils';

class LessonLifeCycle extends React.Component {
  constructor(props) {
    super(props);

    this.inlineResources = null;
    this.handleOpenInlineLessonFile =
      this.handleOpenInlineLessonFile.bind(this);
    this.gatherSectionConnections = this.gatherSectionConnections.bind(this);
    this.openActiveResource = this.openActiveResource.bind(this);
  }

  handleOpenInlineLessonFile(e) {
    e.preventDefault();

    // get the parent section
    const parentElem = Utils.findParentByClassName(
      e.target,
      'lesson__section-description'
    );
    const sectionId = parentElem.getAttribute('data-section');

    const urlArray = e.target.href.split('/');
    const importantResourceSegments = {};
    urlArray.forEach((segment) => {
      // if the segment is an resource or reflection
      if (segment == 'resource' || segment == 'reflection') {
        importantResourceSegments.type = segment;
        return;
      }
      // if only numbers this is the id
      if (/^\d+$/.test(segment)) {
        importantResourceSegments.id = segment;
        return;
      }
    });

    const data = {
      importantResourceSegments: importantResourceSegments,
      urlArray: urlArray,
      sectionId: sectionId,
      href: e.target.href,
    };
    this.props.openInlineResource(data);
  }

  gatherSectionConnections() {
    // get the similar lesson count
    this.props.getSimilarLessonCount();
  }
  componentDidMount() {
    // open an artifact modal if necessary
    if (this.props.openArtifactAfterLoad.shouldOpen) {
      this.openActiveResource();
    }
    // hold on to a reference of all of the inline resources
    this.inlineResources = document.querySelectorAll(
      '.js-open-resources-from-narrative'
    );
    // loop over inline resources and add event listner
    for (let i = 0; i < this.inlineResources; i++) {
      this.inlineResources[i].addEventListener(
        'click',
        this.handleOpenInlineLessonFile
      );
    }
    window.setTimeout(() => {
      if (this.props.navigateToSectionId) {
        for (let i = 0; i < this.props.sections.length; i++) {
          if (
            this.props.sections[i].LessonSection.id !=
            this.props.navigateToSectionId
          ) {
            continue;
          }
          const element = document.getElementById('section-' + (i + 1));
          ZenScroll.to(element);
          break;
        }
      }
    }, 2000);
  }

  openActiveResource() {
    // default variables
    let artifactToOpen = null;
    const activeResourceDetails = this.props.openArtifactAfterLoad;
    let subObject = 'Lessonfile';
    let overlayType = 'resource ';

    // if dealing with a short set a differnt overlay type
    if (activeResourceDetails.isShortcut) {
      overlayType = 'shortcut';
    }

    // if dealing with reflection set necesary variables
    if (activeResourceDetails.artifactType == 'Reflexions') {
      subObject = 'Reflexion';
      overlayType = 'reflection';
    }

    // loop over sections for find appropreate resource
    this.props.sections.forEach((section) => {
      // loop over reflections or resources depending on overlay request type
      section.LessonSection[activeResourceDetails.artifactType].forEach(
        (resource) => {
          // not this resource continue to next itteration
          if (
            !resource[subObject] ||
            resource[subObject].id != activeResourceDetails.artifactId
          ) {
            return;
          }

          // we found the right one set it and stop looping
          resource[subObject].lessonFileType = overlayType;
          artifactToOpen = resource[subObject];
        }
      );

      // the resource wasnt found as a child of the section check the reflection resources
      if (!artifactToOpen && activeResourceDetails.artifactType == 'Resource') {
        // loop over reflections and their resources
        section.LessonSection.Reflexions.forEach((reflection) => {
          reflection.Reflexion.lessonfiles.forEach((reflectionResource) => {
            // this is not the resource so move on
            if (reflectionResource.id != activeResourceDetails.artifactId) {
              return;
            }

            // found it set the appropreate data
            reflectionResource.lessonFileType = overlayType;
            artifactToOpen = reflectionResource;
          });
        });
      }

      // no need to continue itterating we found what we are looking for
      if (artifactToOpen) {
        return;
      }
    });

    // never found it so dont open the overlay
    if (!artifactToOpen) {
      return;
    }

    // open the correct resource
    this.props.openResource(artifactToOpen);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps != this.props &&
      this.props.openArtifactAfterLoad.shouldOpen
    ) {
      this.openActiveResource();
    }
  }
  componentWillUnmount() {
    // loop over inline resources and remove event listner
    for (let i = 0; i < this.inlineResources; i++) {
      this.inlineResources[i].removeEventListener(
        'click',
        this.handleOpenInlineLessonFile
      );
    }
  }
  render() {
    return null;
  }
}

LessonLifeCycle.propTypes = {
  openResource: PropTypes.func.isRequired,
  sections: PropTypes.array,
  navigateToSectionId: PropTypes.func,
  activeResourceDetails: PropTypes.object,
  openArtifactAfterLoad: PropTypes.object,
};

export default LessonLifeCycle;
