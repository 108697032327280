// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import { History } from '@legacy-components/components/Router';
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import ParticipantEngagementDetails from '@legacy-components/react/V5/Common/ParticipantEngagementDetails/ParticipantEngagementDetails';
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ZenScroll from 'zenscroll';
import Header from './../Header/HeaderContainer';
import MeetingNotes from './modules/MeetingNotes';
import Outcomes from './modules/Outcomes';
import Resources from './modules/Resources';

const Highlights = (props) => {
  const lab = useLegacyLabContext();
  // by default, we show the meeting notes tab,
  // but we might start at a different tab based on the URL params
  let startingTab = 'meetingNotes';
  if (props.scrollToResources) {
    startingTab = 'resources';
  } else if (props.entryId || props.scrollToOutcomes) {
    startingTab = 'outcomes';
  }
  const [activeTab, setActiveTab] = useState(startingTab);
  // on mobile, the tabs list uses a select-like interface
  const [tabsListExpanded, setTabsListExpanded] = useState(false);
  // alert for copy email success message
  const [alert, setAlert] = React.useState();
  // bl team members and coaches can see the header part a bit differently
  const canViewHeaderInfo =
    Utils.isContractCoach.call(lab, props.user) ||
    Utils.isDataViewer.call(lab, props.user);

  // set up alert banner if necessary
  let alertNode;
  if (alert && Object.keys(alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const handleSetSuccessAlert = (message) => {
    setAlert({
      type: 'success',
      message: message,
      handleClose: setAlert({}),
    });
  };

  const updateTab = (newTab) => {
    setActiveTab(newTab);
    let queryParams = '';
    switch (newTab) {
      case 'outcomes':
        queryParams = '?scrollToOutcomes=true';
        break;
      case 'resources':
        queryParams = '?scrollToResources=true';
        break;
      default:
        break;
    }
    const location = window.location;
    History.replace(
      null,
      null,
      `${location.origin}${location.pathname}${queryParams}`
    );
  };

  // scroll to a specific milestone or entry on page load if necessary
  useEffect(() => {
    // wait for the page layout to finish setting up
    window.setTimeout(() => {
      if (props.entryId) {
        const el = document.getElementById(`entry-${props.entryId}`);
        if (el) {
          ZenScroll.setup(600, WindowHelpers.isMobileDevice() ? 80 : 30);
          ZenScroll.to(el);
        }
      } else if (props.scrollToResources || props.scrollToOutcomes) {
        const el = document.getElementById('resources');
        if (el) {
          ZenScroll.setup(600, WindowHelpers.isMobileDevice() ? 40 : -10);
          ZenScroll.to(el);
        }
      }
    }, 100);
  }, []);

  // on mobile, the tabs have a different UI
  const handleExpandTabsList = () => {
    setTabsListExpanded(true);

    delayedEventListener(() =>
      document.addEventListener('click', handleCollapseTabsList, false)
    );
  };
  const handleCollapseTabsList = (e, tab) => {
    if (tab) {
      updateTab(tab);
    }
    setTabsListExpanded(false);
    document.removeEventListener('click', handleCollapseTabsList);
  };

  const handleClickTabMobile = tabsListExpanded
    ? handleCollapseTabsList
    : handleExpandTabsList;
  const mobileActiveTabArrow = (
    <span className={'tc-bold-arrow workspace__comment-user-arrow'}></span>
  );
  const mobileTabsExpandedClass = tabsListExpanded
    ? 'highlights__tabs--expanded'
    : '';

  // set up which tab is active
  let meetingNotesActiveClass = 'active';
  let mobileMeetingNotesActiveClass = 'active';
  let outcomesActiveClass = '';
  let mobileOutcomesActiveClass = '';
  let resourcesActiveClass = '';
  let mobileResourcesActiveClass = '';

  const highlightCount = props.outcomes.reduce((accumulator, outcome) => {
    return outcome.entries ? outcome.entries.length + accumulator : accumulator;
  }, 0);

  // the body contents of the tab depend on which one is active - default is meetingNotes tab
  let tabsBodyNode = (
    <React.Fragment>
      <MeetingNotes
        meetingNotes={props.meeting_notes}
        meetingCount={props.meetingCount}
      />
    </React.Fragment>
  );
  switch (activeTab) {
    case 'outcomes':
      meetingNotesActiveClass = '';
      mobileMeetingNotesActiveClass = '';
      outcomesActiveClass = 'active';
      mobileOutcomesActiveClass = 'active';
      tabsBodyNode = (
        <Outcomes
          highlightCount={highlightCount}
          outcomes={props.outcomes}
          domains={props.domains}
          user={props.user}
        />
      );
      break;
    case 'resources':
      meetingNotesActiveClass = '';
      mobileMeetingNotesActiveClass = '';
      resourcesActiveClass = 'active';
      mobileResourcesActiveClass = 'active';
      tabsBodyNode = (
        <Resources
          resourceCount={props.resourceCount}
          resources={props.resources}
        />
      );
      break;
  }
  return (
    <div className="tc__page">
      <Header page="Highlights" {...props} />
      {alertNode}

      <ParticipantEngagementDetails
        {...props.pageParticipant}
        latest_engagement={props.latestEngagement}
        user={props.pageUser}
        time_zone={props.pageUser.time_zone}
        coach={props.latestEngagement && props.latestEngagement.coach}
        currentUser={props.user}
        canEditData={canViewHeaderInfo}
        setSuccessAlert={handleSetSuccessAlert}
        responsive={true}
      />

      <div id="resources" className="highlights__highlights-tabs-section">
        <div className="row row--1200">
          <div className="show-desktop hide-phone cute-8-desktop center-desktop">
            <div className="highlights__tabs">
              <div className="highlights__tab-left">
                <span
                  onClick={() => updateTab('meetingNotes')}
                  className={`highlights__tab ${meetingNotesActiveClass}`}
                >
                  Meeting Notes
                </span>
              </div>
              <div className="highlights__tab-center">
                <span
                  onClick={() => updateTab('outcomes')}
                  className={`highlights__tab ${outcomesActiveClass}`}
                >
                  Outcomes & Highlights
                </span>
              </div>
              <div className="highlights__tab-right">
                <span
                  onClick={() => updateTab('resources')}
                  className={`highlights__tab ${resourcesActiveClass}`}
                >
                  Resources
                </span>
              </div>
            </div>
          </div>

          <div className="hide-desktop show-phone cute-12-phone">
            <div className="highlights__mobile-tabs">
              <div className={`highlights__tabs ${mobileTabsExpandedClass}`}>
                <div
                  onClick={(e) => handleClickTabMobile(e, 'meetingNotes')}
                  className={`highlights__tab-left ${mobileMeetingNotesActiveClass}`}
                >
                  <span
                    className={`highlights__tab ${meetingNotesActiveClass}`}
                  >
                    Meeting Notes
                  </span>
                  {mobileActiveTabArrow}
                </div>
                <div
                  onClick={(e) => handleClickTabMobile(e, 'outcomes')}
                  className={`highlights__tab-center ${mobileOutcomesActiveClass}`}
                >
                  <span className={`highlights__tab ${outcomesActiveClass}`}>
                    Outcomes & Highlights
                  </span>
                  {mobileActiveTabArrow}
                </div>
                <div
                  onClick={(e) => handleClickTabMobile(e, 'resources')}
                  className={`highlights__tab-right ${mobileResourcesActiveClass}`}
                >
                  <span className={`highlights__tab ${resourcesActiveClass}`}>
                    Resources
                  </span>
                  {mobileActiveTabArrow}
                </div>
              </div>
            </div>
          </div>
        </div>

        {tabsBodyNode}
      </div>
    </div>
  );
};

Highlights.propTypes = {
  pageParticipant: PropTypes.object.isRequired,
  pageUser: PropTypes.object.isRequired,
  goals: PropTypes.array,
};

export default Highlights;
