// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import MobileCohortRow from './MobileCohortRow';

const MobileCohortsTable = (props) => {
  const [sortedColumn, setSortedColumn] = React.useState('cohort-name');
  const [sortDirection, setSortDirection] = React.useState('asc');

  // don't show this table if there are no cohorts
  if (!props.cohorts.length) return null;

  const sortFunctions = {
    'cohort-name': (a, b) => a.display_name.localeCompare(b.display_name),
    product: (a, b) => a.product.name.localeCompare(b.product.name),
    dates: (a, b) => moment(a.term_start) - moment(b.term_start),
    size: (a, b) => a.capacity - b.capacity,
  };

  // Sort order indication arrow
  let sortIndicator = null;

  if (sortDirection === 'asc') {
    props.cohorts.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (sortDirection === 'desc') {
    props.cohorts.sort(sortFunctions[sortedColumn]);
    props.cohorts.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortOrder[sortDirection]);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };

  const CohortRows = props.cohorts.map((cohort) => {
    return (
      <MobileCohortRow
        key={`cohort-${cohort.id}`}
        currentYear={props.currentYear}
        {...cohort}
      />
    );
  });

  return (
    <div className="partner_lead_v5__table">
      <div className="v5__heading-3">
        {`Coaching Cohort${props.cohorts.length > 1 ? 's' : ''}`}
      </div>
      <div className="admin-v5__coach-table__header grid-ten__row">
        <div
          className="admin-v5__coach-table__header-col grid-ten__col-3"
          onClick={() => toggleSortDirection('cohort-name')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'cohort-name' ? ' active' : '')
            }
          >
            Cohort
          </span>
          {sortedColumn === 'cohort-name' ? sortIndicator : null}
        </div>
      </div>
      {CohortRows}
    </div>
  );
};

MobileCohortsTable.propTypes = {
  cohorts: PropTypes.array.isRequired,
};

export default MobileCohortsTable;
