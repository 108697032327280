// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';

const HeaderLogo = (props) => {
  const lab = useLegacyLabContext();

  const getLogoLink = () => {
    if (
      Utils.isAdmin.call(lab) ||
      Utils.isManager.call(lab) ||
      Utils.isDataEditor.call(lab) ||
      Utils.isContentCurator.call(lab) ||
      Utils.isDataViewer.call(lab) ||
      Utils.isHelpDeskAgent.call(lab)
    )
      return '/bl/admin/home';

    if (Utils.isServicesManager.call(lab) || Utils.isContractCoach.call(lab))
      return '/lab/coach/home';

    if (
      Utils.isPartnerLeader.call(lab) &&
      ArrayHelpers.hasElements(props.user.leads) &&
      props.user.active_lead === 'v5'
    ) {
      if (
        Utils.participantHasFeatureFlag.call(
          lab,
          'UNIFIED_NAV_BL_LOGO_ROUTES_TO_REPORTING_FOR_PARTNERS'
        )
      )
        return `${process.env.REACT_APP_REPORTING_URL}`;
      return '/lab/home';
    }

    if (Utils.isParticipant.call(lab)) return '/lab/home';
    return '/';
  };

  return (
    <NavigationLink
      url={getLogoLink()}
      id="tc__logo"
      className="tc-header__logo header__logo--v5"
    >
      <span className="tc-header__logo-caption">BetterLesson Lab</span>
    </NavigationLink>
  );
};

export default HeaderLogo;
