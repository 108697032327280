// @ts-nocheck FIXME
import StringHelpers from '@legacy-components/components/StringHelpers';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import FormField from '@legacy-components/react/V5/Common/FormField';
import RadioCheckSelect from '@legacy-components/react/V5/Common/RadioCheckSelect';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import { useState } from 'react';

const AddExistingPartnerLeadModal = (props) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [groupId, setGroupId] = useState(null);
  const [sendEmail, setSendEmail] = useState(true);
  const [affiliation, setAffiliation] = useState('Partner');

  const handleCloseModal = (e) => {
    e.preventDefault();
    props.close();
  };

  const handleEmailBlur = (e) => {
    if (!StringHelpers.isEmailValid(e.target.value)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
    } else {
      setEmailError('');
    }
  };

  const handleAddParticipant = (e) => {
    e.preventDefault();

    if (!StringHelpers.isEmailValid(email)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
      return;
    } else {
      setEmailError('');
    }

    props.addExistingParticipant({
      email: email,
      eventId: props.eventId,
      groupId: groupId,
      successCallback: props.close,
      failureCallback: setGeneralError,
      emailFailureCallback: setEmailError,
      sendEmail: sendEmail,
      affiliation: affiliation,
      eventName: props.eventName,
      creationMethod: 'EVENT_ADD_PARTICIPANT',
    });
  };

  // disable buttons when submitting
  const btnProps = props.isLoading ? { disabled: true } : null;

  const errorNode = generalError && <ErrorText error={generalError} />;

  let groupsNode;
  if (props.groups.length) {
    groupsNode = (
      <label className="workspace__form-row">
        <div className="admin__form-label admin__form-label--inline ">
          Assign to Group
        </div>
        <FormField
          type="select"
          className={'admin-v5__event-pl-form-field'}
          disabled={props.isLoading}
          defaultValue="0"
          onChange={(e) => setGroupId(e.target.value)}
        >
          <option key={'group-null'} value={null}>
            Select
          </option>
          {props.groups.map((group) => {
            return (
              <option key={'group-' + group.id} value={group.id}>
                {group.name}
              </option>
            );
          })}
        </FormField>
      </label>
    );
  }

  const emailAddressClassName =
    emailError ===
    'We have no record of this email in our system. Use the link at the bottom to add new participant.'
      ? 'admin-v5__event-pl-form-field email-not-found'
      : 'admin-v5__event-pl-form-field';

  return (
    <ActionModal
      headlineText="Add Existing Participant to Event"
      close={handleCloseModal}
      spaceFromTop={50}
    >
      <form className="participant-v5__modal-body participant-v5__participant-meeting-clip-modal">
        <label className="workspace__form-row participant-v5__modal-top-row">
          <label className="workspace__form-row">
            <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
              Email address <span className="coach-match__required">*</span>
            </div>
            <FormField
              type="text"
              className={emailAddressClassName}
              disabled={props.isLoading}
              error={emailError}
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setEmailError('')}
              onBlue={handleEmailBlur}
              maxLength="256"
              data-testid="email"
            />
          </label>

          {groupsNode}

          <label className="workspace__form-row">
            <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
              Affiliation <span className="coach-match__required">*</span>
            </div>
            <FormField
              type="select"
              className={'admin-v5__event-pl-form-field'}
              disabled={props.isLoading}
              defaultValue="Partner"
              dataTestId="affiliation"
              onChange={(e) => setAffiliation(e.target.value)}
            >
              {['Partner', 'BL Team'].map((affiliationType, id) => {
                return (
                  <option key={'affiliationType-' + id} value={affiliationType}>
                    {affiliationType}
                  </option>
                );
              })}
            </FormField>
          </label>

          <div className="workspace__form-row v5__heading-5 participant-v5__meeting-no-show--checkbox-row">
            <div className="participant-v5__meeting-no-show--checkbox">
              <RadioCheckSelect
                id="email-check"
                className="admin-v5__radio-button v5__body-copy"
                label="Send an event registration confirmation email"
                type="checkbox"
                containerCallback={(id, selected) => setSendEmail(selected)}
                selected={sendEmail}
              />
            </div>
          </div>

          <div className="workspace__form-row">
            <div>
              <button
                className="v5__btn"
                onClick={handleAddParticipant}
                {...btnProps}
                data-testid="add-existing-pl-button"
              >
                <Spinner loading={props.isLoading} />
                Add Participant
              </button>
              <div className="participant-v5__event-participant-error">
                {errorNode}
              </div>
            </div>
          </div>

          <div className="workspace__form-row" onClick={props.addNew}>
            <div className="admin-v5__event-pl-add">
              Does your participant need a BL account?
            </div>
            <div className="tc__link">Add new participant to this event</div>
          </div>
        </label>
      </form>
    </ActionModal>
  );
};

AddExistingPartnerLeadModal.propTypes = {
  eventId: PropTypes.number,
  isLoading: PropTypes.bool,
  close: PropTypes.func.isRequired,
  addExistingParticipant: PropTypes.func.isRequired,
  addNew: PropTypes.func.isRequired,
  eventName: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  occupations: PropTypes.array.isRequired,
};

export default AddExistingPartnerLeadModal;
