// @ts-nocheck FIXME
import StorageHelper from '@legacy-components/components/StorageHelper';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import Clipboard from 'clipboard';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import TimeZoneIndicator from './../modules/TimeZoneIndicator';
import EventCapsule from './EventCapsule';
import EventsNoneToday from './EventsNoneToday';
import EventsTable from './EventsTable';

const Events = (props) => {
  const search = React.useRef(null);
  const [filter, setFilter] = React.useState(null);

  const [clipboard, setClipboard] = React.useState('');

  React.useEffect(() => {
    setClipboard(
      new Clipboard('.coach-event__copy-link-text', {
        text: function (trigger) {
          return trigger.getAttribute('copytext');
        },
      })
    );

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, []);

  const handleCopy = () => {
    props.setAlert({
      type: 'success',
      message: 'Link copied to clipboard',
      handleClose: () => props.setAlert({}),
    });
  };

  const handleEmailCopy = () => {
    props.setAlert({
      type: 'success',
      message: 'Email copied to clipboard',
      handleClose: () => props.setAlert({}),
    });
  };

  const tableData =
    props.tableView === 'upcoming' ? props.upcoming_events : props.past_events;

  const handleSearchChange = () => {
    setFilter(search.current.value);
  };

  const handleViewChange = () => {
    props.setTableView(props.tableView === 'upcoming' ? 'past' : 'upcoming');
    StorageHelper.setItem(
      'coachEventsTableView',
      props.tableView === 'upcoming' ? 'past' : 'upcoming'
    );
  };

  const searchNode = (
    <div className="admin-v5_coach-dashboard--search-container">
      <input
        type="text"
        ref={search}
        className="v5__input header__v5-search-input"
        placeholder="Search by event or session name"
        onInput={handleSearchChange}
      />
      <button
        className="header__search-button custom-select__single-selected-remove tc__link tc-search"
        onClick={handleSearchChange}
      ></button>
    </div>
  );

  let currentEventsNode = <EventsNoneToday />;
  if (props.current_events && props.current_events.length) {
    currentEventsNode = props.current_events.map((event, i) => {
      return (
        <EventCapsule
          key={`capsule-${event.id}-${i}`}
          handleCopy={handleCopy}
          handleEmailCopy={handleEmailCopy}
          user={props.pageUser}
          userTimeZone={
            props.pageUser.time_zone && props.pageUser.time_zone.pytz_timezone
          }
          {...event}
        />
      );
    });
  }

  return (
    <div className="row">
      <div className="cute-12-desktop cute-12-phone">
        <div className="admin-v5_coach-dashboard--section-heading">
          <h3 className="admin-v5_coach-dashboard--events-title v5__heading-3">
            Events
          </h3>
          <TimeZoneIndicator
            className="show-desktop hide-phone"
            {...props.pageUser.time_zone}
          />
        </div>
        <div className="v5__heading-4 admin-v5_coach-dashboard--event-date">
          Today, {moment().format('LL')}
        </div>
        <TimeZoneIndicator
          className="hide-desktop show-phone"
          {...props.pageUser.time_zone}
        />
        <div className="admin-v5_coach-dashboard--event-capsules">
          {currentEventsNode}
        </div>
        <div className="admin-v5_coach-dashboard--section-heading">
          <h3 className="admin-v5_coach-dashboard--events-title v5__heading-3">
            {props.tableView === 'upcoming' ? 'Upcoming Events' : 'Past Events'}
          </h3>
          <div
            className="admin-v5_coach-dashboard--view-events-container"
            onClick={handleViewChange}
          >
            {props.tableView === 'upcoming' ? (
              <div className="admin-v5_coach-dashboard--view-events tc_link">
                <div className="coach-event__calendar-icon tc-v5-meeting-complete inline">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </div>{' '}
                View past events
              </div>
            ) : (
              <div className="admin-v5_coach-dashboard--view-events tc_link">
                <div className="coach-event__calendar-icon tc-v5-meeting inline"></div>{' '}
                View upcoming events
              </div>
            )}
          </div>
        </div>
        {/* This is for the search failed node */}
        {props.children}
        {searchNode}
        <EventsTable
          {...props}
          filter={filter}
          handleCopy={handleCopy}
          handleEmailCopy={handleEmailCopy}
          tableData={tableData}
          userTimeZone={
            props.pageUser.time_zone && props.pageUser.time_zone.pytz_timezone
          }
        />
      </div>
    </div>
  );
};

const ConnectedEvents = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => <Events {...props} setAlert={setAlert} />}
  </HeaderConsumer>
);

Events.propTypes = {
  pageUser: PropTypes.object,
  upcoming_events: PropTypes.array,
  current_events: PropTypes.array,
  past_events: PropTypes.array,
};

export default ConnectedEvents;
