// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ZenScroll from 'zenscroll';
import WindowScrollElementFixer from './WindowScrollElementFixer';

const StickyLeftNav = (props) => {
  // clicking a link in the nav scrolls to the corresponding section on the page
  const scrollToSection = (id) => {
    // if we were given a callback, use that
    if (props.scrollToSection) {
      props.scrollToSection(id);
      return;
    }

    // otherwise, by default, scroll to the corresponding section
    const element = document.getElementById(id);
    if (element) {
      ZenScroll.setup(600, 10);
      ZenScroll.to(element);
    }
  };

  return (
    <div className="cute-2-desktop show-desktop hide-phone sticky-left-nav__container sticky-left-nav--curation">
      <div className="bl4__sidebar--sticky sticky-left-nav sticky-left-nav--curation">
        <WindowScrollElementFixer
          positionToClass={props.mainPageClass}
          positionThisClass="sticky-left-nav"
          positionThisContainerClass="sticky-left-nav__container"
          topMargin={20}
        />

        {props.sectionNames.map((name, i) => {
          return (
            <div key={'nav-link-' + i} className="sticky-left-nav__row">
              <span
                onClick={() => scrollToSection(props.sectionIdPrefix + i)}
                className="tc__link tc__link--black"
              >
                {name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

StickyLeftNav.propTypes = {
  mainPageClass: PropTypes.string.isRequired,
  sectionNames: PropTypes.array.isRequired,
  sectionIdPrefix: PropTypes.string.isRequired,
  scrollToSection: PropTypes.func,
};

StickyLeftNav.defaultProps = {
  sectionIdPrefix: 'section-',
};

export default StickyLeftNav;
