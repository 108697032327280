// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import PropTypes from 'prop-types';
import Auth from './../../../../../components/Auth';
import Utils from './../../../../../components/DynamicUtils';
import UserPhoto from './../../../Common/UserPhoto';
import HeaderLink from './../HeaderLink';
import SelectedParticipantWidget from './../ParticipantWidget/SelectedParticipantWidget';
import TeacherViewModal from './../ParticipantWidget/TeacherView';
import DropdownContainer from './DropdownContainer';
import DropdownLink from './DropdownLink';

const HeaderDropdown = (props) => {
  const lab = useLegacyLabContext();

  const show = (e) => {
    e.stopPropagation();
    props.openDropdown();
  };

  const hide = (e) => {
    e.stopPropagation();

    // hide the list
    props.closeDropdown();
  };

  // convenience naming
  const user = props.user;
  const userRoles = user.auth_roles;

  // open or close the dropdown according to the state
  const visibleClass = props.ui.dropdownOpened ? ' show' : '';

  // set the class to make the mobile menu scrollable
  const overflowClass = props.dropDownOverflows ? ' overflow' : '';

  // set up participant menu items
  let adminLinkNode;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, [
      'ADMIN',
      'BL_MANAGER',
      'BL_SERVICES_MANAGER',
      'TC_DATA_EDITOR',
      'TC_CONTENT_CURATOR',
      'BL_TEAM_MEMBER',
    ])
  ) {
    adminLinkNode = (
      <DropdownLink url={'/bl/admin/home'} currentPage={props.page === 'Admin'}>
        Admin
      </DropdownLink>
    );
  }
  let teacherWidgetNode;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, [
      'TC_DATA_EDITOR',
      'BL_SERVICES_MANAGER',
      'ADMIN',
    ])
  ) {
    teacherWidgetNode = (
      <li className="show-phone hide-desktop">
        <TeacherViewModal
          ui={props.ui}
          submitTeacherViewModal={props.submitTeacherViewModal}
        />
        <SelectedParticipantWidget
          ui={props.ui}
          openTeacherViewModal={props.openTeacherViewModal}
          closeTeacherViewModal={props.closeTeacherViewModal}
          participant={props.headerParticipant}
          page={props.page}
        />
      </li>
    );
  }

  let privacyNode;
  if (
    Auth.isEnterprise(user) ||
    Utils.isTeacher.call(lab, user) ||
    Auth.isInternalUser(user)
  ) {
    privacyNode = (
      <li>
        <a
          href={`${process.env.REACT_APP_MARKETING_URL}/privacy-policy/?from=tc_menu`}
          className="header__dropdown-link"
          target="_blank"
          rel="noreferrer"
        >
          Expectations &#38; Privacy
        </a>
      </li>
    );
  }

  let favoritesNode, settingsNode;
  let logoutLoginSignupNode = (
    <div className="header__dropdown-logged-out">
      <li>
        <a
          href="/register?redirect=/coaching/lab"
          className="header__dropdown-link"
        >
          Sign Up
        </a>
      </li>
      <li>
        <a
          href="/login?redirect=/coaching/lab"
          className="header__dropdown-link"
        >
          Log In
        </a>
      </li>
    </div>
  );
  if (props.user.id) {
    favoritesNode = (
      <DropdownLink
        url={`${process.env.REACT_APP_TEACHING_URL}/browse/favorites`}
        currentPage={props.page === 'Favorites'}
      >
        Favorites
      </DropdownLink>
    );
    settingsNode = (
      <DropdownLink url={'/account'} currentPage={props.page === 'Account'}>
        My Account
      </DropdownLink>
    );
    logoutLoginSignupNode = (
      <li>
        <a
          href="/logout?from=tc_menu"
          className="header__dropdown-link"
          data-testid="logout-dropdown"
        >
          Log Out
        </a>
      </li>
    );
  }
  // construct dropdown for all users
  const dropdown = (
    <ul className="header__dropdown-list">
      {teacherWidgetNode}
      <HeaderLink
        user={user}
        age={props.page}
        openMarketingDropdown={props.openMarketingDropdown}
        closeMarketingDropdown={props.closeMarketingDropdown}
        marketingDropdownOpened={props.ui.marketingDropdownOpened}
        layout={props.layout}
      />
      {favoritesNode}
      {settingsNode}
      {privacyNode}
      {adminLinkNode}
      {logoutLoginSignupNode}
    </ul>
  );

  let hamburgerOrCancelNode = null;
  if (props.ui.dropdownOpened) {
    hamburgerOrCancelNode = (
      <div
        onClick={hide}
        className="tc-header__mobile-nav-icon show-phone hide-desktop tc-x"
      ></div>
    );
  } else {
    hamburgerOrCancelNode = (
      <div className="tc-header__mobile-nav-icon show-phone hide-desktop tc-hamburger"></div>
    );
  }
  // handle all the mobile / desktop transitions
  let dropdownContainerNode = null;
  if (props.ui.dropdownOpened) {
    dropdownContainerNode = (
      <DropdownContainer
        dropDownVisible={props.ui.dropdownOpened}
        updateDropdownOverflow={props.updateDropdownOverflow}
        hide={hide}
        layout={props.layout}
      >
        <div
          id={'header__dropdown--' + props.layout}
          className={
            'header__dropdown' +
            visibleClass +
            ' cute-12-desktop' +
            overflowClass
          }
        >
          {dropdown}
        </div>
      </DropdownContainer>
    );
  }

  // render the template
  return (
    <div
      className={'tc-header__user' + (props.ui.dropdownOpened ? ' show' : '')}
      onClick={show}
    >
      <div
        className={
          'header__dropdown-container' +
          (props.ui.dropdownOpened ? ' show-phone' : '') +
          ' hide-desktop'
        }
      ></div>
      <div className="tc-header__user-overlay"></div>

      <div className="show-desktop hide-phone">
        <div data-testid="nav-name" className="tc-header__user-name">
          {user.first_name}
        </div>
        <UserPhoto
          src={user.photo}
          hasBorder={false}
          diameter={30}
          className="bl4__user-image-xx-small-mask"
        />
        <div
          data-testid="acct-dpdn-arrow"
          className="tc-bold-arrow header__dropdown-arrow"
        ></div>
      </div>
      {hamburgerOrCancelNode}
      {dropdownContainerNode}
    </div>
  );
};

HeaderDropdown.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dropDownOverflows: PropTypes.bool,
  page: PropTypes.string,
  layout: PropTypes.string.isRequired,
  openDropdown: PropTypes.func.isRequired,
  closeDropdown: PropTypes.func.isRequired,
};

const ConnectedHeaderDropdown = (props) => (
  <HeaderConsumer>
    {({ user }) => <HeaderDropdown {...props} user={user} />}
  </HeaderConsumer>
);

export default ConnectedHeaderDropdown;
