// @ts-nocheck FIXME
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const ContentResourceReplacementPreviewFile = (props) => {
  const [loadingDeletePreview, setLoadingDeletePreview] = React.useState(false);

  const revertResourcePreview = () => {
    setLoadingDeletePreview(true);

    props.revertResourcePreview({
      sectionId: props.sectionId,
      resourceId: props.resource.id,
      lessonfileId: props.resource.lessonfile_id,
      linkId: props.resource.link_id,
    });
  };

  // check if this resource has a replacement preview img
  if (!props.resource.preview_updated_formatted) {
    return null;
  }

  // show the delete icon to revert the preview img back to the original
  let deleteIcon = (
    <div
      onClick={revertResourcePreview}
      className="v5__link tc-delete curation__block-replace-preview-delete-icon"
    ></div>
  );
  // show the spinner instead if it's loading
  if (loadingDeletePreview) {
    deleteIcon = (
      <div className="littlespinner curation__block-replace-preview-delete-icon"></div>
    );
  }

  return (
    <div className="row">
      <div className="cute-11-desktop cute-1-desktop-offset curation__block-replace-preview-updated-date">
        Added on{' '}
        {moment(props.resource.preview_updated_formatted).format(
          'MMM D, YYYY LT'
        )}{' '}
        ET
        {deleteIcon}
      </div>
    </div>
  );
};

ContentResourceReplacementPreviewFile.propTypes = {
  resource: PropTypes.object.isRequired,
  sectionId: PropTypes.number.isRequired,
  revertResourcePreview: PropTypes.func.isRequired,
};

export default ContentResourceReplacementPreviewFile;
