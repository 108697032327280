// @ts-nocheck FIXME
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import NavigationLink from '@legacy-components/react/NavigationLink';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import React, { useState } from 'react';
import ArchivedParticipantRow from './ArchivedParticipantModules/ArchivedParticipantRow';
import ArchivedParticipantSearch from './ArchivedParticipantModules/ArchivedParticipantSearch';
import ArchivedParticipantsTable from './ArchivedParticipantModules/ArchivedParticipantsTable';
import MobileArchivedParticipantRow from './ArchivedParticipantModules/MobileArchivedParticipantRow';
import MobileArchivedParticipantsTable from './ArchivedParticipantModules/MobileArchivedParticipantsTable';

const ArchivedParticipants = (props) => {
  const [searchTerm, setSearchTerm] = useState(null);

  // const [coachingEngagements, setCoachingEngagements] = useState(props.coachingEngagements);
  const coachingEngagements = props.coachingEngagements;
  // We are currently commenting out all references to sorting since that is meant to be handled as part of a separate enhancement ticket
  // const [sortColumn, setSortColumn] = useState('last_name');
  // const [sortDirection, setSortDirection] = useState('ascending');

  const escapeSpecialChars = (term) => {
    return term.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&').toLowerCase();
  };

  // handle school select and name search
  const filterDownCoachingEngagements = () => {
    // no reason to do filter just return the default
    if (!searchTerm) {
      return coachingEngagements;
    }

    filteredCoachingEngagements = coachingEngagements;
    let searchedResultCoachingEngagements = false;
    if (searchTerm) {
      searchedResultCoachingEngagements = [];
      const escapedSearchTerm =
        searchTerm && new RegExp(escapeSpecialChars(searchTerm));
      // filter by search term
      filteredCoachingEngagements.forEach((filteredCoachingEngagement) => {
        if (
          escapedSearchTerm &&
          (escapedSearchTerm.test(
            filteredCoachingEngagement.participant.first_name.toLowerCase()
          ) ||
            escapedSearchTerm.test(
              filteredCoachingEngagement.participant.last_name.toLowerCase()
            ) ||
            escapedSearchTerm.test(
              filteredCoachingEngagement.participant.email.toLowerCase()
            ) ||
            escapedSearchTerm.test(
              `${filteredCoachingEngagement.participant.first_name.toLowerCase()} ${filteredCoachingEngagement.participant.last_name.toLowerCase()}`
            ))
        ) {
          searchedResultCoachingEngagements.push(filteredCoachingEngagement);
        }
      });
    }
    // output the appropreate array
    let output = coachingEngagements;
    if (searchedResultCoachingEngagements) {
      output = searchedResultCoachingEngagements;
    } else if (searchedResultCoachingEngagements.length > 0) {
      output = searchedResultCoachingEngagements;
    }

    return output;
  };

  // const sortBy = (column) => {
  //     let updatedCoachingEngagements = Utils.clone(coachingEngagements);
  //     // if we are resorting by same column
  //     if(sortColumn === column) {
  //         if(sortDirection === 'ascending') {
  //             setSortDirection('descending');
  //             coachingEngagements.reverse();
  //         } else {
  //             setSortDirection('ascending');
  //             coachingEngagements.reverse();
  //         }
  //     } else {
  //         //always default sort new columns ascending
  //         setSortDirection('ascending');
  //         setSortColumn(column);

  //         // alphabetical search conditions
  //         if(column === 'first_name') {
  //             updatedCoachingEngagements.sort( (a, b) => {
  //                 return a.participant.first_name.toLowerCase().localeCompare(b.participant.first_name.toLowerCase());
  //             });
  //         } else if (column === 'last_name') {
  //             updatedCoachingEngagements.sort( (a, b) => {
  //                 return a.participant.first_name.toLowerCase().localeCompare(b.participant.first_name.toLowerCase());
  //             });
  //             updatedCoachingEngagements.sort( (a, b) => {
  //                 return a.participant.last_name.toLowerCase().localeCompare(b.participant.last_name.toLowerCase());
  //             });
  //         } else if (column === 'cohort') {
  //             updatedCoachingEngagements.sort( (a, b) => {
  //                 return a.cohort.cohort_name.toLowerCase().localeCompare(b.cohort.cohort_name.toLowerCase());
  //             });
  //         }
  //     }
  //     // update the default participants order
  //     setCoachingEngagements(updatedCoachingEngagements);
  // };

  const participantsArray = [];
  const mobileParticipantsArray = [];
  let participantsNode = (
    <div className="admin-v5__coach-table--empty-state">
      Your coach record has no archived participants.
    </div>
  );

  // get participants filterd by year option and search
  let filteredCoachingEngagements = filterDownCoachingEngagements();
  if (filteredCoachingEngagements && filteredCoachingEngagements.length) {
    filteredCoachingEngagements.forEach((coachingEngagement) => {
      // Action node links to a participant's workspace
      const actionsNode = (
        <NavigationLink
          className="account-archive__link tc__link"
          url={`/lab/workspace/${coachingEngagement.participant.user_id}`}
        >
          <div className="tc-v5-workspace admin-v5__action-icon" />
        </NavigationLink>
      );

      participantsArray.push(
        <ArchivedParticipantRow
          setSuccessAlert={() =>
            props.setAlert({
              type: 'success',
              message: 'Email copied to clipboard',
            })
          }
          key={`archived-participants-${coachingEngagement.participant.id}`}
          user={props.user}
          pageUser={props.pageUser}
          coachingEngagement={coachingEngagement}
        >
          {actionsNode}
        </ArchivedParticipantRow>
      );

      mobileParticipantsArray.push(
        <MobileArchivedParticipantRow
          setAlert={props.setAlert}
          key={`archived-participants-${coachingEngagement.participant.id}`}
          user={props.user}
          pageUser={props.pageUser}
          coachingEngagement={coachingEngagement}
        >
          {actionsNode}
        </MobileArchivedParticipantRow>
      );
    });
    participantsNode = <React.Fragment>{participantsArray}</React.Fragment>;
  }

  return (
    <div>
      <Header page="CoachHome" {...props} />
      <div className="admin-v5_coach-dashboard__main-content m-coach-dashboard">
        <div className="row row--1200">
          <div className="admin-v5__header-row">
            <div className="admin-v5__left">
              <h1 className="tc__headline-1 admin-v5_coach-dashboard--headline admin-v5__headline">
                Coach Archive
              </h1>
            </div>
            <div className="admin-v5__right">
              <NavigationLink
                className="admin-v5__toggle-link v5__link"
                url={`/lab/coach/${props.pageUser.id}/coaching`}
              >
                <div className="admin-v5__toggle-link-icon tc-v5-coach-product" />
                <div>View Current Dashboard</div>
              </NavigationLink>
            </div>
          </div>
          <div className="admin-v5__header-row">
            <div className="admin-v5__left">
              <ArchivedParticipantSearch setSearchTerm={setSearchTerm} />
            </div>
          </div>
        </div>
        <div className="row row--1200">
          <div className="cute-12-desktop show-desktop hide-phone">
            <ArchivedParticipantsTable>
              {participantsNode}
            </ArchivedParticipantsTable>
          </div>
          <div className="cute-12-desktop show-phone hide-desktop admin-v5__mobile-table-container">
            <MobileArchivedParticipantsTable>
              {mobileParticipantsArray}
            </MobileArchivedParticipantsTable>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConnectedArchivedParticipants = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => <ArchivedParticipants {...props} setAlert={setAlert} />}
  </HeaderConsumer>
);
export default ConnectedArchivedParticipants;
