// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Analytics from './../../../../components/Analytics';
import Spinner from './../Spinner';

const ArtifactLink = (props) => {
  let url;

  const clearError = () => {
    props.setArtifactLinkUploadError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      url: url.value,
    };
    props.submitLink(data);
  };

  const handleLinkFocus = () => {
    Analytics.logEvent({
      category: 'link',
      action: 'enter',
      label: 'from_add_link_modal_vision_page',
    });
  };

  let errorNode = null;
  let errorClass = '';
  if (props.error) {
    errorNode = <div className="tc__error">{props.error}</div>;
    errorClass = ' error';
  }

  // disable buttons when form is loading
  const buttonDisabledClass = props.loading ? ' disabled' : '';

  // if the user is allowed to switch from link to file, show the button to do so
  let switchToFileNode = null;
  if (props.canSwitchToFile) {
    switchToFileNode = (
      <div className="tc__center-text">
        <span
          className="artifact-upload-v4__toggle-file-link"
          onClick={props.switchToFile}
        >
          <span className="tc-v4-upload artifact-upload-v4__toggle-file-link-icon"></span>
          Share a file
        </span>
      </div>
    );
  }

  return (
    <div className="artifact-link-v4__form">
      <div className="tc__headline-5 vision__artifact-link-upload-headline">
        Paste a link to your artifact
      </div>
      <div className="tc__body-copy vision__artifact-link-upload-caption">
        Please double check that this resource is publicly accessible
      </div>
      <div className="vision__artifact-link-input-container">
        <input
          type="text"
          ref={(ref) => (url = ref)}
          placeholder="https://www.mylink.com"
          onClick={clearError}
          onFocus={handleLinkFocus}
          className={'tc__input artifact-link-v4__url-input' + errorClass}
        />
        {errorNode}
      </div>
      <div className="story__edit-entry-form-btns">
        <button
          onClick={handleSubmit}
          className={
            'bl4__btn bl4__btn--blue artifact-link-v4__btn' +
            buttonDisabledClass
          }
        >
          <Spinner loading={props.loading} />
          Add
        </button>
      </div>

      {switchToFileNode}
    </div>
  );
};

ArtifactLink.propTypes = {
  switchToFile: PropTypes.func.isRequired,
  submitLink: PropTypes.func.isRequired,
  setArtifactLinkUploadError: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  canSwitchToFile: PropTypes.bool,
};

ArtifactLink.defaultProps = {
  loading: false,
  canSwitchToFile: true,
};

export default ArtifactLink;
