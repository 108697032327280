// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import SessionMaterialsCapsule from './SessionMaterialsCapsule';

const GroupMaterialsCapsule = (props) => {
  return (
    <div className="edit-event__group-capsule">
      <div className="edit-event__group-capsule-headline-row edit-event__group-capsule-headline-row--materials v5__heading-5">
        <div className="tc-v5-group edit-event__row-start-icon"></div>
        <div className="edit-event__group-capsule-headline-text">
          {props.name}
        </div>
      </div>

      <div className="edit-event__group-capsule-body v5__body-copy">
        {props.sessions.map((session, i) => {
          return (
            <SessionMaterialsCapsule
              key={`group-${props.num}-session-${session.index}`}
              {...session}
              groupNum={props.num}
              eventHasModifications={props.eventHasModifications}
              canEditEventDetails={props.canEditEventDetails}
              allProductCodes={props.allProductCodes}
              facilitatorOptions={props.facilitatorOptions}
              greyBackground={true}
              handleCopy={props.handleCopy}
              isFirst={i === 0}
              setAlert={props.setAlert}
              setModalOpen={props.setModalOpen}
              singleSession={props.sessions.length === 1}
              update={(data) => props.updateSession(session.index, data)}
              user={props.user}
            />
          );
        })}
      </div>
    </div>
  );
};

GroupMaterialsCapsule.propTypes = {
  name: PropTypes.string,
  sessions: PropTypes.array.isRequired,
};

export default GroupMaterialsCapsule;
