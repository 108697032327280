// @ts-nocheck FIXME
import SearchApi from '@legacy-components/apis/V4/SearchApi';
import Analytics from '@legacy-components/components/Analytics';
import { Router } from '@legacy-components/components/Router';
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SearchView from './SearchView';

const HeaderSearch = (props) => {
  const [salt, setSalt] = useState(props.salt);
  const [searchTerm, setSearchTerm] = useState('');
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);

  useEffect(() => {
    // clean up event listners on unmount
    return () => {
      window.removeEventListener('click', closeAutocomplete);
      document.removeEventListener('click', closeSearchOnDocumentClick);
    };
  }, []);

  // if we are in the context of a participant (i.e. we see the participant widget under the header)
  // then searches we do from here should only show results that are relevant to the participant
  const searchUrl =
    process.env.REACT_APP_TEACHING_URL +
    (props.headerParticipant && props.headerParticipant.blUserId
      ? `/search/${props.headerParticipant.blUserId}`
      : '/search');

  const doSearch = (e, searchTerm) => {
    // Create a random string to pass to the backend to get random results yet maintain order during pagination
    setSalt(Math.random().toString(36).substr(2));

    e.preventDefault();
    closeMobileSearch();
    let queryString = `?q=${searchTerm}&from=header`;
    if (salt) {
      // Only add the salt to the URL if it's set
      queryString = `?q=${searchTerm}&salt=${salt}&from=header`;
    }

    Router.route(`${searchUrl}${queryString}`);
  };

  const handleSearchSubmit = (e) => {
    doSearch(e, searchTerm);
  };

  // submit on enter
  const handleSearchSubmitOnEnter = (e) => {
    // 13 is the enter keycode
    const code = e.keyCode || e.which;
    if (code === 13) {
      doSearch(e, searchTerm);
    }
  };

  const searchAutocomplete = (text) => {
    // if there are less than 2 characters in the input, hide the autocomplete menu
    if (!text || text.length < 2) {
      setAutoCompleteResults([]);
      return;
    }
    // otherwise, do a search on the content tags
    return SearchApi.searchAutocomplete({
      text: text,
    })
      .then((response) => {
        setAutoCompleteResults(response.results);
      })
      .catch(() => {
        setAutoCompleteResults([]);
      });
  };

  const closeSearchOnDocumentClick = () => {
    props.setMobileSearchOpened(false);
  };
  const openMobileSearch = () => {
    delayedEventListener(() =>
      document.addEventListener('click', closeSearchOnDocumentClick)
    );

    props.setMobileSearchOpened(true);
    if (props.mobileDropdownOpened) {
      props.setMobileDropdownOpen(false);
    }
  };
  const closeMobileSearch = function () {
    setAutoCompleteResults([]);
    props.setMobileSearchOpened(false);
    document.removeEventListener('click', closeSearchOnDocumentClick);
  };

  // focus event
  const handleFocus = (e) => {
    e.preventDefault();
    Analytics.logEvent({
      category: 'search',
      action: 'type',
      label: 'from_header',
    });

    // Attach the click handler to the window
    delayedEventListener(() =>
      window.addEventListener('click', closeAutocomplete)
    );
  };

  const closeAutocomplete = () => {
    // Remove the click handler from the window
    window.removeEventListener('click', closeAutocomplete);
    // clear the list
    searchAutocomplete('');
  };

  // wait for the user to stop typing before we fire calls to the server
  let onChangeTimeout;

  const handleTyping = (e) => {
    // set the search term
    setSearchTerm(e.target.value);

    clearTimeout(onChangeTimeout);
    // wait for the user to stop typing before we fire calls to the server
    onChangeTimeout = setTimeout(function () {
      searchAutocomplete(searchTerm && searchTerm.toLowerCase());
    }, 500);
  };

  const handleKeywordSearch = (text) => {
    closeMobileSearch();
    Router.route(`${searchUrl}?q=${text}&from=header&autocomplete=1`);
  };

  const handleCCStandardSearch = (standard) => {
    closeMobileSearch();
    Router.route(
      `${searchUrl}?standards=${standard}&from=header&autocomplete=1`
    );
  };

  const handleSubjectSearch = (subject) => {
    closeMobileSearch();
    Router.route(`${searchUrl}?subjects=${subject}&from=header&autocomplete=1`);
  };

  const handleLearningDomainSearch = (domain) => {
    closeMobileSearch();
    Router.route(
      `${searchUrl}?focusAreas=${domain}&types=strategy&from=header&autocomplete=1`
    );
  };

  const handleSpecialtyTagSearch = (specialtyTag) => {
    closeMobileSearch();
    Router.route(
      `${searchUrl}?specialtyTags=${specialtyTag}&from=header&autocomplete=1`
    );
  };

  const handlePracticeTagSearch = (practiceTag) => {
    closeMobileSearch();
    Router.route(
      `${searchUrl}?practiceTags=${practiceTag}&from=header&autocomplete=1`
    );
  };

  const handleDanielsonComponentSearch = (danielsonComponent) => {
    closeMobileSearch();
    Router.route(
      `${searchUrl}?danielsonComponents=${danielsonComponent}&from=header&autocomplete=1`
    );
  };

  return (
    <SearchView
      openMobileSearch={openMobileSearch}
      mobileSearchOpened={props.mobileSearchOpened}
      handleFocus={handleFocus}
      handleTyping={handleTyping}
      responsive={props.responsive}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      autoCompleteResults={autoCompleteResults}
      handleSearchSubmit={handleSearchSubmit}
      handleSearchSubmitOnEnter={handleSearchSubmitOnEnter}
      handleKeywordSearch={handleKeywordSearch}
      handleCCStandardSearch={handleCCStandardSearch}
      handleSubjectSearch={handleSubjectSearch}
      handleLearningDomainSearch={handleLearningDomainSearch}
      handleSpecialtyTagSearch={handleSpecialtyTagSearch}
      handlePracticeTagSearch={handlePracticeTagSearch}
      handleDanielsonComponentSearch={handleDanielsonComponentSearch}
    />
  );
};

export default HeaderSearch;

HeaderSearch.propTypes = {
  salt: PropTypes.string,
  headerParticipant: PropTypes.object,
};
