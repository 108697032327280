// @ts-nocheck FIXME
import React from 'react';
import Category from './GrowthMap/Category';

class GrowthMap extends React.Component {
  render() {
    const categoryNodes = [];

    for (const category in this.props.categories) {
      categoryNodes.push(
        <Category
          key={'category' + category}
          category={category}
          sgas={this.props.categories[category]}
        />
      );
    }

    return (
      <div className="portfolio__growth-map portfolio-v2__loop-section">
        <div className="portfolio__section-headline portfolio__section-headline--growthmap">
          Growth Map
        </div>
        {categoryNodes}
      </div>
    );
  }
}

export default GrowthMap;
