// @ts-nocheck FIXME
import PropTypes from 'prop-types';

import ScaleCenterImage from './../../../ScaleCenterImage';

const showImagePlaceholder = (props) => {
  const noPreviewImage =
    '<div class="ppp__elem-image--no-preview ppp__elem-image--no-preview-narrative"><div class="artifact__type-icon artifact__type-icon--no-preview file-type-icon ext-' +
    props.extension +
    '"></div></div>';
  const containerElem = 'ppp__elem-preview--' + props.id;

  // create image instance
  const image = new Image();
  image.src = props.preview;

  // check if loads: return true
  image.onerror = () => {
    document.getElementById(containerElem).innerHTML = noPreviewImage;
  };
};

const Artifact = (props) => {
  const displayArtifact = (e) => {
    e.preventDefault();
    props.showArtifact(props);
  };

  showImagePlaceholder(props);

  return (
    <div className="row">
      <div
        className="cute-12-desktop ppp__narrative-artifact"
        onClick={displayArtifact}
      >
        <div className="ppp__elem-preview-container ppp__elem-preview-container--narrative">
          <div
            className="ppp__elem-preview"
            id={'ppp__elem-preview--' + props.id}
          >
            <ScaleCenterImage
              src={props.preview}
              className="ppp__narrative-artifact-image"
            />
          </div>
        </div>
        <div className="ppp__artifact-details ppp__artifact-details--narrative">
          {props.type_name && (
            <div className="artifact__type artifact__type--tml-v2">
              <div className="artifact__type-caption">{props.type_name}</div>
              <div className="artifact__flag-end tc-flag-end"></div>
            </div>
          )}
          <div className="ppp__artifact-filename-container">
            <span
              className={
                'artifact__type-icon file-type-icon ext-' + props.extension
              }
            ></span>
            <span className="ppp__artifact-filename">{props.filename}</span>
          </div>
          <div className="ppp__artifact-notes ppp__body-copy">
            {props.notes}
          </div>
        </div>
      </div>
    </div>
  );
};

Artifact.propTypes = {
  showArtifact: PropTypes.func.isRequired,
};

export default Artifact;
