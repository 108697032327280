// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import CopyToClipboard from '@legacy-components/react/V5/Common/CopyToClipboard';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { CSVLink } from 'react-csv';

const PLCard = (props) => {
  let headerClass = '';
  let cardClass = '';
  const index = props.sessionId || props.event.id;
  const colors = Utils.UI_BASIC_COLORS;
  const color = colors[index % colors.length];
  let balloonBottomTextNode;
  let extraSpaceClass = '';
  let ballonHasBottomRightSideText =
    ' admin-v5__pl-card-right-side-bottom-text-with-balloon-bottom-text';
  if (props.denominator > 0) {
    balloonBottomTextNode = <div>of {props.denominator}</div>;
  } else {
    ballonHasBottomRightSideText = '';
    extraSpaceClass = ' admin-v5__pl-card-balloon-no-bottom';
  }

  const handleCopy = () => {
    props.setAlert({
      type: 'success',
      message: 'Link copied to clipboard',
      handleClose: () => props.setAlert({}),
    });
  };

  // Since the balloon will always look the same across all types of cards just declare it here
  const balloon = (
    <div
      className="tc-v5-teardrop admin-v5__pl-card-balloon"
      style={{
        color: color,
      }}
    >
      <div className="admin-v5__pl-card-balloon-text">
        <div className={`v5__heading-2${extraSpaceClass}`}>
          {props.attendees.length}
        </div>
        {balloonBottomTextNode}
      </div>
    </div>
  );

  // The registration and check in cards have the same body structure just with slightly
  // Different text on the card
  const createCardBody = (
    linkTitle,
    link,
    selectorId,
    linkText,
    bottomText
  ) => {
    return (
      <React.Fragment>
        <div className="cute-3-desktop">
          <div className="admin-v5__pl-card-left-side">
            {balloon}
            <div className="admin-v5__pl-card-left-side-bottom-text">
              {bottomText}
            </div>
          </div>
        </div>
        <div className="cute-9-desktop">
          <div className="v5__heading-6">{linkTitle}</div>
          <div className="v5__comment-copy">{link}</div>
          <div
            copytext={link}
            className="v5__link"
            id={`${selectorId}`}
            onClick={handleCopy}
          >
            Copy link
            <CopyToClipboard targetSelector={`#${selectorId}`} />
          </div>
          <div
            className={`v5__heading-6 light admin-v5__pl-card-right-side-bottom-text${ballonHasBottomRightSideText}`}
          >
            {linkText}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const createCSVData = () => {
    let hasGroups = false;
    const sessionColumns = [];
    const sessionNumSet = new Set();
    props.sessions.forEach((session) => {
      if (session.group_id && !hasGroups) {
        hasGroups = true;
        sessionColumns.push({ label: 'GROUP', key: 'GROUP' });
      }
      if (!sessionNumSet.has(session.num)) {
        sessionNumSet.add(session.num);
        const sessKey = `SESSION_${session.num}`;
        sessionColumns.push({ label: sessKey, key: sessKey });
      }
    });
    const csvHeaders = [
      { label: 'NAME', key: 'NAME' },
      { label: 'EMAIL', key: 'EMAIL' },
      { label: 'SCHOOL', key: 'SCHOOL' },
      { label: 'GRADE', key: 'GRADE' },
      { label: 'ROLE', key: 'ROLE' },
      { label: 'SUBJECT', key: 'SUBJECT' },
    ].concat(sessionColumns);
    const csvData = props.attendeesToDownload.map((attendee) => {
      const attendeeData = {
        NAME: `${attendee.user_first_name} ${attendee.user_last_name}`,
        EMAIL: attendee.user_email,
        SCHOOL: attendee.institution,
        GRADE: attendee.grades.join(';'),
        ROLE: attendee.role,
        SUBJECT: attendee.subjects.join(';'),
      };
      if (hasGroups) {
        attendeeData.GROUP = attendee.group_name;
      }
      attendee.event_sessions &&
        attendee.event_sessions.forEach((session) => {
          attendeeData[`SESSION_${session.session_num}`] = moment(
            session.registered_formatted
          ).format('YYYY, D, MMMM HH:mm A z');
        });
      return attendeeData;
    });
    return [csvHeaders, csvData];
  };
  let csvHeaders, csvData;
  let headline;
  let body;
  let attendanceText;
  let sessionStartTime;
  switch (props.type) {
    case 'registration':
      headline = 'Registration Count';
      body = createCardBody(
        'RSVP Link',
        process.env.REACT_APP_LAB_URL +
          '/lab/event/' +
          props.event.id +
          '/reserve',
        'rsvp_link',
        'Share this link to help RSVP to this event',
        'Registered for Event'
      );
      break;

    case 'check-in':
      //Group name is only provided for sessions of advanced events
      //so it works as a flag for determining whether to use basic or advanced layout
      if (props.groupName) {
        sessionStartTime = props.event.time_zone
          ? moment(props.sessionStartTime)
              .tz(props.event.time_zone.pytz_timezone)
              .format('h:mm A')
          : moment(props.sessionStartTime).format('h:mm A');
        headline = `Check-Ins for ${props.groupName}, ${props.sessionName}, ${sessionStartTime}`;
      } else {
        headline = 'Check-Ins for the Next Session';
      }

      body = createCardBody(
        'Check-In Link',
        process.env.REACT_APP_LAB_URL +
          '/lab/event/' +
          props.event.id +
          '/register',
        `session_${props.sessionId}_link`,
        'Share this link with your registrants to help them check in',
        'Attendees have checked-in'
      );
      break;

    case 'attendance':
      [csvHeaders, csvData] = createCSVData();

      headline = 'Session Attendance';
      attendanceText = props.groupName
        ? `attended ${props.groupName}, ${props.sessionName}.`
        : 'attended most recent session.';

      body = (
        <div className="admin-v5__pl-attendance-card-body">
          <div className="admin-v5__pl-attendance-card-data-row">
            {balloon}
            <CSVLink
              headers={csvHeaders}
              className="admin-v5__edit-table-btn v5__link admin-v5__btn-copy-emails admin-v5__pl-attendance-download"
              data={csvData}
              filename={`${props.event.display_name}-${props.sessionName}-Attendance.csv`}
            >
              <span className="tc-download admin-v5__edit-table-btn-icon"></span>
              Attendance CSV
            </CSVLink>
          </div>
          <div className="admin-v5__pl-card-right-side-bottom-text">
            {attendanceText}
          </div>
        </div>
      );

      headerClass = ' admin-v5__pl-attendance-card-headline';
      cardClass = ' admin-v5__pl-attendance-card';
      break;

    case 'event-finished':
      [csvHeaders, csvData] = createCSVData();
      headline = 'Event Attendance';

      body = (
        <div className="admin-v5__pl-attendance-card-body">
          <div className="admin-v5__pl-attendance-card-data-row">
            <CSVLink
              headers={csvHeaders}
              className="admin-v5__edit-table-btn v5__link admin-v5__btn-copy-emails admin-v5__pl-attendance-download"
              data={csvData}
              filename={`${props.event.display_name}-Attendance.csv`}
            >
              <span className="tc-download admin-v5__edit-table-btn-icon"></span>
              Attendance CSV
            </CSVLink>
          </div>
        </div>
      );

      headerClass = ' admin-v5__pl-attendance-card-headline';
      cardClass = ' admin-v5__pl-event-attendance-card';
      break;

    default:
      break;
  }
  return (
    <div className={`v5__capsule admin-v5__pl-card-capsule${cardClass}`}>
      <div className={`v5__heading-4${headerClass}`}>{headline}</div>
      <div>{body}</div>
    </div>
  );
};

PLCard.propTypes = {
  type: PropTypes.string.isRequired,
  attendees: PropTypes.array.isRequired,
  denominator: PropTypes.number,
  event: PropTypes.object.isRequired,
  sessionId: PropTypes.number,
  sessions: PropTypes.array,
  sessionName: PropTypes.string,
  groupName: PropTypes.string,
  sessionStartTime: PropTypes.string,
};

export default PLCard;
