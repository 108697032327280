// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Analytics from './../../../components/Analytics';

/*
given a list of elements, if the list is longer than the given max,
truncate it and add a "+# more" link at the end to show the whole list
*/
class ExpandableList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.expanded && this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'standards',
        action: 'show_all',
        label: this.props.analyticsLabel,
      });
    }
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    // calculate how many elements are hidden by default
    const hiddenCount =
      this.props.elements.length - this.props.defaultMaxVisible;

    // only show the link to expand the list if there are hidden elements
    let expandNode = null;
    if (hiddenCount > 0) {
      expandNode = (
        <div className={this.props.expandClassName}>
          <span onClick={this.toggleExpand} className="tc__link">
            +{hiddenCount} more
          </span>
        </div>
      );
    }

    return (
      <div className={this.props.className}>
        {this.state.expanded
          ? this.props.elements
          : this.props.elements.slice(0, this.props.defaultMaxVisible)}
        {!this.state.expanded && expandNode}
      </div>
    );
  }
}

ExpandableList.propTypes = {
  defaultMaxVisible: PropTypes.number.isRequired,
  elements: PropTypes.array.isRequired,
  className: PropTypes.string,
  expandClassName: PropTypes.string,
};

ExpandableList.defaultProps = {
  defaultMaxVisible: 3,
  className: '',
  expandClassName: '',
};

export default ExpandableList;
