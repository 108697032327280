// @ts-nocheck FIXME
import UserPhoto from './../../../../Common/UserPhoto';

const UserDropdownOption = (props) => {
  const handleClick = props.updateAuthorId
    ? () => props.updateAuthorId(props.user.id)
    : null;

  const photoNode = (
    <div className="workspace__comment-photo">
      <UserPhoto
        src={props.user.photo}
        userId={props.user.id}
        firstName={props.user.first_name}
        lastName={props.user.last_name}
        diameter={30}
      />
    </div>
  );

  let activeClass = '';
  let arrowNode = '';
  if (props.user.id === props.authorId) {
    activeClass = 'workspace__comment-author-option--selected';
    // update some styles based on whether or not the user can edit the author
    if (props.updateAuthorId) {
      arrowNode = (
        <div className="tc-bold-arrow workspace__comment-user-arrow"></div>
      );
    } else {
      activeClass += ' view-only';
    }
  }

  return (
    <div
      className={`workspace__comment-author-option ${activeClass}`}
      onClick={handleClick}
    >
      {photoNode}
      <div>
        {props.user.first_name} {props.user.last_name}
      </div>
      {arrowNode}
    </div>
  );
};

export default UserDropdownOption;
