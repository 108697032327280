// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment-timezone';
import propTypes from 'prop-types';
import React from 'react';
import CapacityDetail from './CapacityDetail';
import CapacityQuarterBlock from './CapacityQuarterBlock';

const DayRow = (props) => {
  const day = moment(props.date).format('ddd');
  const formattedDate = moment(props.date).format('M/D');

  /**
   * check if session should be filtered out depending on given certificates
   * @param {array} certifications certifications in each session
   * @returns {boolean} whether the session satisfies certification filter
   */
  const haveCertification = (certifications = []) => {
    const hasCerts = ArrayHelpers.hasElements(certifications);
    // check if we have a specific certification we are filtering on
    if (props.activeCert !== 'all') {
      if (!hasCerts) return false;
      return certifications.some((c) => c === props.activeCert);
    } else {
      // props.activeCert === 'all'
      return true;
    }
  };

  // takes in sessions and populate the hour state, only being called
  // upon initial render and by useEffect
  const populateHoursNode = (sessions) => {
    const hours = Utils.range(0, 24).map(() => {
      return [
        { sessions: [], count: 0 },
        { sessions: [], count: 0 },
        { sessions: [], count: 0 },
        { sessions: [], count: 0 },
      ];
    });

    // populate each column by counting the sessions in each quarter
    if (sessions.length > 0) {
      sessions.forEach((session) => {
        // count this session in any quarters that contains it
        const sessionStartTime = moment.tz(
          session.session_start_time_formatted,
          props.timeZone
        );
        const sessionEndTime = moment.tz(
          session.session_end_time_formatted,
          props.timeZone
        );
        let startHour = sessionStartTime.hour();
        let endHour = sessionEndTime.hour();
        const endMin = sessionEndTime.minute();
        let startQuarter = Math.floor(sessionStartTime.minute() / 15);
        let endQuarter = Math.floor(sessionEndTime.minute() / 15);

        // when a session ends at the quarter (0,15,30,45), count them as they end just before that time
        // e.g., ends at 13:15 will be treated as ends at 13:14, so it won't show on the 13:15 block
        if (endMin % 15 === 0) {
          if (endQuarter > 0) {
            endQuarter -= 1;
          } else {
            endHour -= 1;
            endQuarter = 3;
          }
        }

        // extend start/end time for 2-day sessions
        if (
          sessionEndTime.isAfter(moment.tz(props.date, props.timeZone), 'date')
        ) {
          endHour = 23;
          endQuarter = 3;
        }

        if (
          sessionStartTime.isBefore(
            moment.tz(props.date, props.timeZone),
            'date'
          )
        ) {
          startHour = 0;
          startQuarter = 0;
        }

        Utils.range(startHour, endHour + 1).forEach((hour) => {
          // fill each quarter all session details (no matter what cert filter is)
          // and session counts in each quarter (cert filters apply)
          if (startHour === endHour) {
            // handles when a session starts and ends in the same hour
            Utils.range(startQuarter, endQuarter + 1).forEach((quarter) => {
              hours[hour][quarter].sessions.push(session);
              if (haveCertification(session.certifications)) {
                hours[hour][quarter].count += 1;
              }
            });
          } else if (hour === startHour) {
            Utils.range(startQuarter, 4).forEach((quarter) => {
              hours[hour][quarter].sessions.push(session);
              if (haveCertification(session.certifications)) {
                hours[hour][quarter].count += 1;
              }
            });
          } else if (hour === endHour) {
            Utils.range(0, endQuarter + 1).forEach((quarter) => {
              hours[hour][quarter].sessions.push(session);
              if (haveCertification(session.certifications)) {
                hours[hour][quarter].count += 1;
              }
            });
          } else {
            // hours and quarters in between start and end, fill them all
            Utils.range(0, 4).forEach((quarter) => {
              hours[hour][quarter].sessions.push(session);
              if (haveCertification(session.certifications)) {
                hours[hour][quarter].count += 1;
              }
            });
          }
        });
      });
    }
    return hours;
  };

  // save populated hours as state and only update when certain props change
  const [hours, setHours] = React.useState(() =>
    populateHoursNode(props.sessions)
  );

  React.useEffect(() => {
    setHours(populateHoursNode(props.sessions));
  }, [props.activeCert, props.sessions]);

  const hourNode = [];

  // make the container taller if it's sunday
  const sundayHourClass =
    day === 'Sun' ? ' events-cal__hour-row-container--tall' : '';

  // show capacity details when true
  const showDetail =
    props.showEventDetail.date === props.date &&
    hours[props.showEventDetail.hour][props.showEventDetail.quarter].sessions
      .length > 0;

  const expandedHourClass = showDetail
    ? ' events-cal__hour-row-container--expanded'
    : '';

  // set the active capacity based on whether there is an active filter
  let capacity = props.capacities.general;
  if (props.activeCert && props.activeCert in props.capacities) {
    capacity = props.capacities[props.activeCert];
  }

  // generate a row of 15-min boxes in a 12-hour display range
  Utils.range(props.startTimeRange, props.startTimeRange + 12).forEach(
    (hour) => {
      hourNode.push(
        <div
          className={`events-cal__hour-row-container${sundayHourClass}${expandedHourClass}`}
          key={`${props.date}-${hour}`}
        >
          {Utils.range(0, 4).map((quarter) => {
            const count = hours[hour][quarter].count;
            return (
              <CapacityQuarterBlock
                key={`${props.date}-${hour}-${quarter}`}
                date={props.date}
                hour={hour}
                quarter={quarter}
                count={count}
                handleShowEventDetail={props.handleShowEventDetail}
                showEventDetail={props.showEventDetail}
                capacity={capacity}
              />
            );
          })}
        </div>
      );
    }
  );

  // make the container taller if it's Sunday
  const sundayClass =
    day === 'Sun' ? ' events-cal__day-row-container--tall' : '';
  // adjust styles when capacityDetail under this row is shown
  const expandedDayClass = showDetail
    ? ' events-cal__day-row-container--expanded'
    : '';
  const expandedDayTextClass = showDetail
    ? ' events-cal__day-text--expanded'
    : '';
  return (
    <>
      <div
        className={`events-cal__day-row-container${sundayClass}${expandedDayClass}`}
        id={props.date}
      >
        <div className={`events-cal__day-text${expandedDayTextClass}`}>
          <span className="events-cal__day-text--grey">{day}</span>
          <br />
          <span className="events-cal__date-text events-cal__text--bold">
            {formattedDate}
          </span>
        </div>
        {hourNode}
      </div>

      {showDetail && (
        <CapacityDetail
          sessions={
            hours[props.showEventDetail.hour][props.showEventDetail.quarter]
          }
          activeCert={props.activeCert}
          certifications={props.certifications}
          showEventDetail={props.showEventDetail}
          hideEventDetail={() => props.handleShowEventDetail()}
          capacities={props.capacities}
          timeZone={props.timeZone}
        />
      )}
    </>
  );
};

DayRow.propTypes = {
  date: propTypes.string.isRequired,
  sessions: propTypes.array.isRequired,
  startTimeRange: propTypes.number.isRequired,
  showEventDetail: propTypes.object.isRequired,
  handleShowEventDetail: propTypes.func.isRequired,
  capacities: propTypes.object.isRequired,
  activeCert: propTypes.string.isRequired,
  certifications: propTypes.array.isRequired,
  timeZone: propTypes.string.isRequired,
};

export default DayRow;
