// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../../components/DynamicUtils';
import NavigationLink from './../../../NavigationLink';
import StrategyCapsule from './StrategyCapsule';

const StrategiesDomainRow = (props) => {
  const domainIdForIcon = Utils.getDomainIdForIcon(props.id);
  const domainIdForHeroImage = Utils.getDomainIdForHeroImage(
    props.id,
    'small_with_gradient'
  );
  // set up the background img of the first capsule based on the domain id
  const domainBackgroundStyle = {
    backgroundImage: `url('/legacy-images/learning_domains/small_with_gradient/${domainIdForHeroImage}.webp')`,
  };

  // set up the url for the learning domain details page
  let domainUrl =
    process.env.REACT_APP_TEACHING_URL + '/browse/learning-domain/' + props.id;
  if (props.seo_url) {
    domainUrl += '/' + props.seo_url;
  }
  domainUrl += '?from=instructional_strategies_browse';

  // the last row should not have a border-bottom
  const borderClass = props.noBorder
    ? ' strategies__domain-strategies-row--no-border'
    : '';

  // show domain tagline if necessary
  const taglineNode = props.tagline ? (
    <div className="tc__body-copy strategies__domain-tagline">
      {props.tagline}
    </div>
  ) : null;

  return (
    <div className="row row--1200">
      <div className="cute-12-desktop strategies__domain-headline-row">
        <div
          className={`strategies__domain-icon tc-v4-domain-${domainIdForIcon}`}
        ></div>
        <div>
          <h2>
            <NavigationLink
              url={domainUrl}
              className="tc__link tc__link--black strategies__domain-title"
            >
              {props.title}
            </NavigationLink>
          </h2>
          {taglineNode}
        </div>
        <div className="hide-desktop show-phone strategies__domain-more-mobile">
          <NavigationLink url={domainUrl} className="tc__link">
            Learn More
          </NavigationLink>
        </div>
      </div>
      <div className="cute-12-desktop">
        <div className={`strategies__domain-strategies-row ${borderClass}`}>
          <div
            className="bl4__capsule strategies__capsule strategies__capsule--domain show-desktop hide-phone"
            style={domainBackgroundStyle}
          >
            <NavigationLink
              url={domainUrl}
              className="tc__link tc__link--white"
            >
              <div className="strategies__capsule-feature-text">
                Learn more about
              </div>
              <div className="strategies__capsule-name">{props.title}</div>
            </NavigationLink>
            <NavigationLink
              url={
                process.env.REACT_APP_TEACHING_URL +
                '/search?focusAreas=' +
                props.id +
                '&from=instructional_strategies_browse'
              }
              className="tc__link strategies__capsule-browse-link"
            >
              Browse Strategies
            </NavigationLink>
          </div>

          {props.strategies.map((strategy) => {
            return (
              <StrategyCapsule
                key={'domain-' + props.id + '-strategy-' + strategy.id}
                {...strategy}
                user={props.user}
                addFavorite={props.addFavorite}
                removeFavorite={props.removeFavorite}
                recommendModal={props.recommendModal}
                searchSuccess={props.searchSuccess}
                fromTag="instructional_strategies_browse"
              />
            );
          })}

          <span className="hide-desktop show-phone strategies__domain-browse-mobile">
            <NavigationLink
              url={
                process.env.REACT_APP_TEACHING_URL +
                '/search?focusAreas=' +
                props.id
              }
              className="bl4__btn bl4__btn--blue"
            >
              Browse Strategies
            </NavigationLink>
          </span>
        </div>
      </div>
    </div>
  );
};

StrategiesDomainRow.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  strategies: PropTypes.array,
  noBorder: PropTypes.bool,
};

export default StrategiesDomainRow;
