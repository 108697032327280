// @ts-nocheck FIXME
const Closing = (props) => {
  // we only render segments if we have data for them
  let teacherReflection = null;
  if (props.teacher_reflection) {
    teacherReflection = (
      <div className="portfolio__reflection-body portfolio__reflection-body--v2">
        {props.teacher_reflection}
        <div className="portfolio__reflection-author">
          - {props.author.first_name} {props.author.last_name}
        </div>
      </div>
    );
  }

  let coachReflection = null;
  if (props.coach_reflection) {
    coachReflection = (
      <div className="portfolio__reflection-body portfolio__reflection-body--v2 portfolio__reflection-body--coach">
        <span className="portfolio__reflection-quote">&ldquo;</span>
        <span className="portfolio__reflection-coach">
          {props.coach_reflection}
        </span>
        <span className="portfolio__reflection-quote">&rdquo;</span>
        <div className="portfolio__reflection-author">
          - {props.coach_name ? props.coach_name + ', ' : ''}BetterLesson Coach
        </div>
      </div>
    );
  }

  return (
    <div className="portfolio__reflection">
      <div className="portfolio__section-headline portfolio__section-headline--intro">
        Reflections
      </div>
      {teacherReflection}
      {coachReflection}
    </div>
  );
};

export default Closing;
