// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../components/ArrayHelpers';
import AbsoluteAlert from './../../Alerts/Absolute';
import ArtifactOverlay from './../Common/ArtifactOverlay/ArtifactOverlay';
import AddVisionArtifact from './AddVisionArtifact';
import ScrollToEntry from './ScrollToEntry';
import TextRow from './TextRow';
import VisionArtifact from './VisionArtifact';
import TextArtifactModal from './VisionArtifactTextModal';
import VisionArtifactUploadModal from './VisionArtifactUploadModal';

const ParticipantVision = (props) => {
  // Disaplay the overlay modal if needed
  let artifactOverlayNode = null;
  if (props.ui.artifactModal.opened) {
    artifactOverlayNode = (
      <ArtifactOverlay
        {...props.ui.artifactModal}
        analyticsLabel="from_view_artifact_modal_vision_page"
      />
    );
  }

  // Display the text modal if needed
  let textModalNode;
  if (props.ui.textArtifactModal && props.ui.textArtifactModal.opened) {
    textModalNode = (
      <TextArtifactModal
        {...props.ui.textArtifactModal}
        submitTextArtifact={props.submitTextArtifact}
        deleteArtifact={props.deleteArtifact}
        toggleElement={props.toggleElement}
        confirmDelete={props.ui.confirmDelete}
        getSingleSelectElement={props.getSingleSelectElement}
        selectSingleSelectElement={props.selectSingleSelectElement}
        setFeatureFieldError={props.setFeatureFieldError}
        moveArtifact={props.moveArtifact}
        confirmMove={props.ui.confirmMove}
      />
    );
  }

  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  let visionArtifactsNode = null;
  let noArtifactsClass = 'participant__story-line--empty';
  if (ArrayHelpers.hasElements(props.participant.visionArtifacts)) {
    noArtifactsClass = '';
    visionArtifactsNode = props.participant.visionArtifacts.map(
      (visionArtifact, i) => {
        return (
          <VisionArtifact
            artifact={visionArtifact}
            artifactModal={props.ui.artifactModal}
            textArtifactModal={props.ui.textArtifactModal}
            key={'artifact' + i}
            openConfirmDeleteModal={props.openConfirmDeleteModal}
            closeConfirmDeleteModal={props.closeConfirmDeleteModal}
            deleteArtifact={props.deleteArtifact}
            openEditArtifactModal={props.openEditArtifactModal}
            loggedInUser={props.user}
          />
        );
      }
    );
  }

  // show the participant's name if current user is not the participant
  const titleSubject =
    props.participant.user_id == props.user.id
      ? 'My'
      : props.participant.user.first_name + "'s";

  return (
    <div className="tc__page tc__page--vision">
      <Header page="ParticipantVision" {...props} />
      {alertNode}
      <div className="row">
        <h1 className="vision__headline cute-8-desktop cute-12-phone center-desktop tc__headline-1 tc__center-text">
          {titleSubject} Vision
        </h1>
      </div>
      <div className="row">
        <div className="vision__editor-caption cute-8-desktop cute-12-phone center-desktop participant-profile__row">
          Your vision sets a defined direction for growth. It is your north star
          to inspire and guide your BetterLesson Lab work.
        </div>
      </div>
      <TextRow
        {...props}
        fieldName="vision"
        labelName="My Vision:"
        prettyFieldName="my vision"
      />
      <div className="row">
        <div className="vision__editor-caption cute-8-desktop cute-12-phone center-desktop participant-profile__row">
          Your design summary is the what, why, and how of what you want to
          build this school year, to get to your vision.
        </div>
      </div>
      <TextRow
        {...props}
        fieldName="design_summary"
        labelName="My Design Summary:"
        prettyFieldName="design summary"
      />
      <div className="row">
        <h2 className="vision__headline vision__headline--artifacts cute-8-desktop center-desktop tc__headline-2 tc__center-text">
          Vision Artifacts
        </h2>
        <div className="noSelect vision__artifacts cute-6-desktop cute-12-phone center-desktop tc__center-text">
          <AddVisionArtifact {...props} />
          <div className={'participant__story-line' + noArtifactsClass}>
            {visionArtifactsNode}
          </div>
        </div>
      </div>

      {artifactOverlayNode}
      {textModalNode}
      <VisionArtifactUploadModal
        {...props.ui.artifactUpload}
        confirmDelete={props.ui.confirmDelete}
        confirmMove={props.ui.confirmMove}
        link={props.ui.link}
        participantId={props.participant.id}
        artifactTypes={props.artifactTypes}
        teacherId={props.user.id}
        handleSelectFiles={props.handleSelectFiles}
        onDragOver={props.onDragOver}
        onDragLeave={props.onDragLeave}
        closeArtifactModal={props.closeArtifactModal}
        setInputFiles={props.setInputFiles}
        showUploadError={props.showUploadError}
        progressCallback={props.progressCallback}
        submitFiles={props.submitFiles}
        submitLink={props.submitLink}
        setArtifactLinkUploadError={props.setArtifactLinkUploadError}
        editArtifact={props.editArtifact}
        showDropDownOptions={props.showDropDownOptions}
        handleSelectArtifactType={props.handleSelectArtifactType}
        toggleElement={props.toggleElement}
        deleteArtifact={props.deleteArtifact}
        participantOutcomes={props.participant.outcomes}
        getSingleSelectElement={props.getSingleSelectElement}
        selectSingleSelectElement={props.selectSingleSelectElement}
        setFeatureFieldError={props.setFeatureFieldError}
        moveArtifact={props.moveArtifact}
      />

      {props.selectedEntry ? <ScrollToEntry id={props.selectedEntry} /> : ''}
    </div>
  );
};

ParticipantVision.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  deleteArtifact: PropTypes.func.isRequired,
  submitTextArtifact: PropTypes.func.isRequired,
  toggleElement: PropTypes.func.isRequired,
  getSingleSelectElement: PropTypes.func.isRequired,
  selectSingleSelectElement: PropTypes.func.isRequired,
  setFeatureFieldError: PropTypes.func.isRequired,
  moveArtifact: PropTypes.func.isRequired,
};

export default ParticipantVision;
