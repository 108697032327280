// @ts-nocheck FIXME
import StringHelpers from '@legacy-components/components/StringHelpers';
import Role from '@legacy-components/react/V5/Account/modules/Role';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import { useState } from 'react';

const AddNewEventParticipantModal = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [groupId, setGroupId] = useState(null);
  const [affiliation, setAffiliation] = useState('Partner');
  const [generalError, setGeneralError] = useState('');
  const [role, setRole] = useState(1);

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handleChangeFirstName = (e) => {
    const text = e.target.value;
    setFirstName(text);

    if (!text) {
      setFirstNameError(
        'Hold on, you need to enter a first name for the participant.'
      );
    } else {
      setFirstNameError('');
    }
  };

  const handleChangeLastName = (e) => {
    const text = e.target.value;
    setLastName(text);

    if (!text) {
      setLastNameError(
        'Hold on, you need to enter a last name for the participant.'
      );
    } else {
      setLastNameError('');
    }
  };

  const handleEmailBlur = (e) => {
    if (!StringHelpers.isEmailValid(e.target.value)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
    } else {
      setEmailError('');
    }
  };

  const handleAddParticipant = (e) => {
    e.preventDefault();
    if (!firstName) {
      setFirstNameError(
        'Hold on, you need to enter a first name for the participant.'
      );
      return;
    } else {
      setFirstNameError('');
    }

    if (!lastName) {
      setLastNameError(
        'Hold on, you need to enter a last name for the participant.'
      );
      return;
    } else {
      setLastNameError('');
    }

    if (!StringHelpers.isEmailValid(email)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
      return;
    } else {
      setEmailError('');
    }

    props.addNewParticipant({
      firstName: firstName,
      lastName: lastName,
      email: email,
      eventId: props.eventId,
      groupId: groupId,
      affiliation: affiliation,
      successCallback: props.close,
      failureCallback: setGeneralError,
      emailFailureCallback: setEmailError,
      eventName: props.eventName,
      creationMethod: 'EVENT_ADD_PARTICIPANT',
    });
  };

  const errorNode = generalError && <ErrorText error={generalError} />;

  const handleChangeRole = (roleValue) => {
    setRole(roleValue);
  };

  // disable buttons when submitting
  const btnProps = props.isLoading ? { disabled: true } : null;

  let groupsNode;
  if (props.groups.length) {
    groupsNode = (
      <label className="workspace__form-row">
        <div className="admin__form-label admin__form-label--inline ">
          Assign to Group
        </div>
        <FormField
          type="select"
          className={'admin-v5__event-pl-form-field'}
          disabled={props.isLoading}
          defaultValue="0"
          onChange={(e) => setGroupId(e.target.value)}
        >
          <option key={'group-null'} value={null}>
            Select
          </option>
          {props.groups.map((group) => {
            return (
              <option key={'group-' + group.id} value={group.id}>
                {group.name}
              </option>
            );
          })}
        </FormField>
      </label>
    );
  }

  return (
    <ActionModal
      headlineText="Add New Participant to Event"
      close={handleCloseModal}
      spaceFromTop={50}
    >
      <form className="participant-v5__modal-body participant-v5__participant-meeting-clip-modal">
        <label className="workspace__form-row participant-v5__modal-top-row">
          <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
            First Name <span className="coach-match__required">*</span>
          </div>
          <FormField
            type="text"
            className={'admin-v5__event-pl-form-field'}
            disabled={props.isLoading}
            error={firstNameError}
            defaultValue={firstName}
            onChange={handleChangeFirstName}
            dataTestId="first-name"
            maxLength="256"
          />
        </label>

        <label className="workspace__form-row">
          <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
            Last Name <span className="coach-match__required">*</span>
          </div>
          <FormField
            type="text"
            className={'admin-v5__event-pl-form-field'}
            disabled={props.isLoading}
            error={lastNameError}
            defaultValue={lastName}
            onChange={handleChangeLastName}
            dataTestId="last-name"
            maxLength="256"
          />
        </label>
        <label className="workspace__form-row">
          <div className="admin__form-label admin__form-label--inline participant-v5__participant-meeting-clip-type-label">
            Role <span className="coach-match__required">*</span>
          </div>
          <Role
            occupations={props.occupations}
            role={parseInt(role)}
            setRole={handleChangeRole}
            disabled={props.isLoading}
          />
        </label>

        <label className="workspace__form-row">
          <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
            Email address <span className="coach-match__required">*</span>
          </div>
          <FormField
            type="text"
            className={'admin-v5__event-pl-form-field'}
            disabled={props.isLoading}
            error={emailError}
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setEmailError('')}
            onBlur={handleEmailBlur}
            dataTestId="email"
            maxLength="256"
          />
        </label>

        {groupsNode}

        <label className="workspace__form-row">
          <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
            Affiliation <span className="coach-match__required">*</span>
          </div>
          <FormField
            type="select"
            className={'admin-v5__event-pl-form-field settings__input--half'}
            disabled={props.isLoading}
            defaultValue="Partner"
            dataTestId="affiliation"
            onChange={(e) => setAffiliation(e.target.value)}
          >
            {['Partner', 'BL Team'].map((affiliationType, id) => {
              return (
                <option key={'affiliationType-' + id} value={affiliationType}>
                  {affiliationType}
                </option>
              );
            })}
          </FormField>
        </label>
        <div className="workspace__form-row">
          <div className="admin-v5__event-pl-add italic">
            Adding a new participant will send an email confirming their
            registration for <span className="bold">{props.eventName}</span>
          </div>
        </div>

        <div className="workspace__form-row">
          <div>
            <button
              className="v5__btn"
              onClick={handleAddParticipant}
              {...btnProps}
              data-testid="add-new-pl-button"
            >
              <Spinner loading={props.isLoading} />
              Add Participant
            </button>
            <div className="participant-v5__event-participant-error">
              {errorNode}
            </div>
          </div>
        </div>
        <div className="workspace__form-row">
          <div className="admin-v5__event-pl-add">
            Does your participant already have a BL account?
          </div>
          <div className="tc__link" onClick={props.addExisting}>
            Add existing participant to this event
          </div>
        </div>
      </form>
    </ActionModal>
  );
};

AddNewEventParticipantModal.propTypes = {
  eventId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  close: PropTypes.func.isRequired,
  addNewParticipant: PropTypes.func.isRequired,
  addExisting: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  eventName: PropTypes.string.isRequired,
  occupations: PropTypes.array.isRequired,
};

export default AddNewEventParticipantModal;
