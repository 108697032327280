// @ts-nocheck FIXME
import Request from './../../components/Request';

const WorkspaceApi = {
  submitGoal: (data) => {
    const endPointSuffix = data.goalId ? `${data.goalId}/edit` : 'add';
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/participant/${data.participantId}/goal/${endPointSuffix}`,
      JSON.stringify(data)
    );
  },
  deleteGoal: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/participant/${data.participantId}/goal/${data.goalId}/delete`,
      JSON.stringify(data)
    );
  },
  completeGoal: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/participant/${data.participantId}/goal/${data.goalId}/complete`,
      JSON.stringify(data)
    );
  },
  uncompleteGoal: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/participant/${data.participantId}/goal/${data.goalId}/uncomplete`,
      JSON.stringify(data)
    );
  },
  highlightGoal: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/participant/${data.participantId}/goal/${data.goalId}/highlight`,
      JSON.stringify(data)
    );
  },
  unhighlightGoal: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/participant/${data.participantId}/goal/${data.goalId}/unhighlight`,
      JSON.stringify(data)
    );
  },

  editCompletedGoal: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/completed-goal/${data.storyCompletedGoalId}/edit`,
      JSON.stringify(data)
    );
  },

  addGoalToEntry: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/entry/${data.entryId}/add-goal-tag`,
      JSON.stringify(data)
    );
  },
  removeGoalFromEntry: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/entry/${data.entryId}/delete-goal-tag`,
      JSON.stringify(data)
    );
  },
  highlightAndUpdateGoalTagToEntry: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/entry/${data.entryId}/highlight-and-update-goal-tag`,
      JSON.stringify(data)
    );
  },

  highlightEntry: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/entry/${data.entryId}/highlight`,
      JSON.stringify(data)
    );
  },
  unhighlightEntry: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/entry/${data.entryId}/unhighlight`,
      JSON.stringify(data)
    );
  },

  addComment: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/entry/${data.entryId}/comment/add`,
      JSON.stringify(data)
    );
  },
  editComment: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/entry/${data.entryId}/comment/${data.commentId}/edit`,
      JSON.stringify(data)
    );
  },
  deleteComment: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/entry/${data.entryId}/comment/${data.commentId}/delete`,
      JSON.stringify(data)
    );
  },

  getEntriesForSchoolYear: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/user/${data.userId}/workspace/get-entries`,
      JSON.stringify(data)
    );
  },
  editSelfAssessmentEntry: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/story/${data.storyId}/self-assessment/${data.assessmentSurveyEntryId}/edit`,
      JSON.stringify(data)
    );
  },
  highlightOutcomeGrowth: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/${data.storyId}/growth_highlights`,
      JSON.stringify(data)
    );
  },
  unhighlightOutcomeGrowth: (data) => {
    return Request.delete(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/${data.storyId}/growth_highlights/${data.growthHighlightId}`,
      JSON.stringify(data)
    );
  },
};

export default WorkspaceApi;
