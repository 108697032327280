// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import React from 'react';
import AbridgeText from './../../../Common/AbridgeText';

const TextEntry = (props) => {
  const inputEL = React.useRef(null);

  React.useEffect(() => {
    if (props.smallView) {
      const elem = inputEL.current;
      const elemHeight = elem.getBoundingClientRect().height;

      if (elemHeight > 230) {
        elem.style.height = '230px';
        elem.style.overflowY = 'hidden';
        props.setTruncateState(true);
      }
    }
  }, []);

  const textNode = props.isWYSIWYG ? (
    <PurifiedHTML
      className="ckeditor__output v5__body-copy"
      ref={inputEL}
      content={props.description}
    />
  ) : (
    <div className="v5__body-copy" ref={inputEL}>
      {props.description}
    </div>
  );

  let toggle = true;
  if (props.toggle === false) {
    toggle = false;
  }

  const bodyNode = toggle ? (
    <AbridgeText
      maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
      isExpandable={true}
      greyFade={props.greyFade}
    >
      {textNode}
    </AbridgeText>
  ) : (
    textNode
  );

  return (
    <div className="workspace__entry-body">
      <div className="workspace__entry-row">{bodyNode}</div>
    </div>
  );
};

TextEntry.propTypes = {
  setTruncateState: PropTypes.func,
  toggle: PropTypes.bool,
  greyFade: PropTypes.bool,
};

export default TextEntry;
