// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import WindowHelpers from './../../../../components/WindowHelpers';
import NavigationLink from './../../../NavigationLink';
import AbridgeText from './../../Common/AbridgeText';
import UserPhoto from './../../Common/UserPhoto';
import MetaDataCollector from './modules/MetaDataCollector';

const UserCapsule = (props) => {
  let photoNode, schoolNode, visionNode, coachNode, metaDataCollectionNode;

  const register = () => {
    const blRegistrationLink = document.getElementById('blRegistrationLink');
    if (blRegistrationLink) {
      blRegistrationLink.click();
    }
  };

  if (props.pageUser && props.pageUser.institution) {
    schoolNode = (
      <div data-testid="school-name" className="home__user-capsule-text">
        {props.pageUser.institution.name}
      </div>
    );
  }
  photoNode = (
    <div>
      <div className="tc-v4-profile-white-bg-lg home__user-capsule-default-photo">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
      </div>
    </div>
  );

  if (props.pageUser.photo) {
    photoNode = (
      <NavigationLink
        url={'/profile/' + props.pageUser.id + '?from=participant_dashboard'}
        className="home__user-capsule-default-photo"
      >
        <UserPhoto src={props.pageUser.photo} diameter={75} hasBorder={true} />
      </NavigationLink>
    );
  } else if (props.pageUser.id) {
    photoNode = (
      <div>
        <NavigationLink
          url={'/account/' + props.pageUser.id + '?from=participant_dashboard'}
          className="tc-v4-profile-white-bg-lg home__user-capsule-default-photo"
        >
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <div className="home__user-img-overlay">Edit</div>
        </NavigationLink>
      </div>
    );
  }
  let noBorderClass = '';
  if (props.pageParticipant) {
    // set up the vision statement
    let visionHeadlineNode, visionTextNode;
    let profileLinkText = 'What is your vision for this school year?';
    if (props.pageParticipant.vision) {
      visionHeadlineNode = (
        <div className="home__user-capsule-headline">My Vision</div>
      );
      visionTextNode = (
        <AbridgeText
          maxHeight={WindowHelpers.isMobileDevice() ? 80 : 220}
          className="home__user-capsule-text home__user-capsule-vision ckeditor__output"
          setInnerHtml={true}
        >
          {props.pageParticipant.vision}
        </AbridgeText>
      );
      profileLinkText = 'View Profile';
    }
    visionNode = (
      <div className="home__user-capsule-section">
        {visionHeadlineNode}
        {visionTextNode}
        <NavigationLink
          url={'/profile/' + props.pageUser.id + '?from=home_user_sidebar'}
          className="tc__link"
        >
          {profileLinkText}
        </NavigationLink>
      </div>
    );

    // set up coach info section
    let coachText = 'Coach Match Coming Soon!';
    let myCoachNode = null;
    if (props.pageParticipant.coach) {
      coachText =
        props.pageParticipant.coach.first_name +
        ' ' +
        props.pageParticipant.coach.last_name;
      myCoachNode = <div className="home__user-capsule-text">My Coach</div>;
    }
    coachNode = (
      <div className="home__user-capsule-section show-desktop hide-phone">
        <UserPhoto
          src={props.pageParticipant.coach && props.pageParticipant.coach.photo}
          className="home__user-capsule-coach-default-photo"
          diameter={50}
        />
        <div className="home__user-capsule-name">{coachText}</div>
        {myCoachNode}
      </div>
    );
  } else if (props.pageUser.id) {
    metaDataCollectionNode = <MetaDataCollector {...props} />;
  } else {
    noBorderClass = ' home__user-capsule--no-border';
  }

  let nameNode = (
    <div data-testid="dashboard-name">
      {props.pageUser.first_name + ' ' + props.pageUser.last_name}
    </div>
  );
  if (!props.pageUser.id) {
    nameNode = (
      <div className="home__user-capsule-logged-out-text">
        <div className="home__user-capsule-get-started-text">
          Get started building your profile with a free BetterLesson account.
        </div>
        <div>
          <a className="tc__link" onClick={register}>
            Sign Up
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="bl4__capsule bl4__capsule--header-graphic home__user-capsule tc__body-copy">
      <div
        className={
          'home__user-capsule home__user-capsule--user-details' + noBorderClass
        }
      >
        {photoNode}
        <h2 className="home__user-capsule-name">{nameNode}</h2>
      </div>
      {schoolNode}
      {visionNode}
      {metaDataCollectionNode}
      {coachNode}
    </div>
  );
};

UserCapsule.propTypes = {
  pageUser: PropTypes.object.isRequired,
  pageParticipant: PropTypes.object,
  user: PropTypes.object,
};

export default UserCapsule;
