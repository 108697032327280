// @ts-nocheck FIXME
import NavigationLink from './../../../NavigationLink';

const MegaMenu = (props) => {
  let childStandardLevels;
  const standardType =
    props.activeSubject == 'science' ? 'next_gen_science' : 'common_core';
  // if (props.childStandards) {
  childStandardLevels = props.childStandards.map((level) => {
    const levelTwoStandards = level.CommonCore.children.map((child) => {
      return (
        <div
          className="standards__megamenu-link"
          key={
            'level-2-standard-' +
            child.CommonCore.parent_id +
            '-' +
            child.CommonCore.id
          }
        >
          <NavigationLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/' +
              standardType +
              '/standard/' +
              child.CommonCore.id +
              '/' +
              child.CommonCore.seo_url +
              '?from=content_area_' +
              props.activeSubject
            }
            className="tc__link"
          >
            {child.CommonCore.description}
          </NavigationLink>
        </div>
      );
    });
    let expandedBlockClass = '';
    let mobileIconExpandClass = ' standards__megamenu-standards-icon--expand';
    if (props.expandedMobileStandardsBlock == level.CommonCore.id) {
      expandedBlockClass = ' standards__megamenu-standard-block--expanded';
      mobileIconExpandClass = ' standards__megamenu-standards-icon--collapse';
    }
    return (
      <div
        className="standards__megamenu-standard"
        key={'level-1-standard-' + level.CommonCore.id}
        onClick={() => {
          props.expandMobileStandardsBlock({ blockId: level.CommonCore.id });
        }}
      >
        <h3 className="standards__megamenu-subheadline">
          {level.CommonCore.description}
        </h3>
        <div
          className={'standards__megamenu-standard-block' + expandedBlockClass}
        >
          {levelTwoStandards}
        </div>
        <div
          className={
            'standards__megamenu-standards-icon' + mobileIconExpandClass
          }
        ></div>
      </div>
    );
  });
  // }
  const megaMenuActiveClass = props.megaMenuOpen ? ' active' : '';
  return (
    <div className={'standards__megamenu row row--1200' + megaMenuActiveClass}>
      <h2 className="standards__megamenu-headline">{props.megaMenuHeadline}</h2>
      <div className="standards__megamenu-items">{childStandardLevels}</div>
    </div>
  );
};

export default MegaMenu;
