// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import React from 'react';
import ReactDOM from 'react-dom';
import Auth from './../../components/Auth';
import { Router } from './../../components/Router';
import AbsoluteAlert from './../Alerts/Absolute';
import NavigationLink from './../NavigationLink';

class AdminUsers extends React.Component {
  state = {
    searchEmail: this.props.userSearch.email || '',
    searchFirstName: this.props.userSearch.firstName || '',
    searchLastName: this.props.userSearch.lastName || '',
    results: this.props.userSearch.results || [],
    searchType: 'email',
    alert: {},
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      searchEmail: nextProps.userSearch.email || '',
      results: nextProps.userSearch.results || [],
      alert: {},
    });
  }

  handleSearchTypeChange = (e) => {
    e.preventDefault();
    const searchType = this.state.searchType === 'email' ? 'name' : 'email';
    this.setState({
      searchType: searchType,
      searchEmail: '',
      searchFirstName: '',
      searchLastName: '',
    });
  };

  handleSearchChange = () => {
    this.setState({
      searchEmail: ReactDOM.findDOMNode(this.refs.email).value || '',
      searchFirstName: ReactDOM.findDOMNode(this.refs.firstName).value || '',
      searchLastName: ReactDOM.findDOMNode(this.refs.lastName).value || '',
    });
  };

  handleClear = () => {
    this.setState({
      alert: {},
      searchEmail: '',
      searchFirstName: '',
      searchLastName: '',
    });

    // go back to /bl/legacy/users when we clear the search field, if we are not already there
    if (this.props.userSearch.email) {
      Router.route('/bl/legacy/users');
    }
  };

  handleSearch = (e) => {
    e.preventDefault();

    this.setState({ alert: {} });

    let urlParam;
    let firstNameParam;
    let lastNameParam;
    let concatNode;
    let paramNode;

    switch (this.state.searchType) {
      case 'email':
        urlParam = '?email=' + encodeURIComponent(this.state.searchEmail);
        break;
      case 'name':
        firstNameParam = this.state.searchFirstName
          ? 'first_name=' + encodeURIComponent(this.state.searchFirstName)
          : '';

        lastNameParam = this.state.searchLastName
          ? 'last_name=' + encodeURIComponent(this.state.searchLastName)
          : '';

        concatNode = firstNameParam && lastNameParam ? '&' : '';
        paramNode = firstNameParam || lastNameParam ? '?' : '';

        urlParam = paramNode + firstNameParam + concatNode + lastNameParam;
        break;
      default:
        urlParam = '';
    }
    Router.route('/bl/legacy/users' + urlParam);
  };

  handleCloseAlert = () => {
    this.setState({ alert: {} });
  };

  render() {
    // can the logged in user create users?
    let createUserNode;

    if (Auth.canCreateUsers(this.props.user)) {
      createUserNode = (
        <NavigationLink
          url="/bl/user/create"
          className="admin-v2__add-user-btn tc__btn tc__btn--blue tc__btn--small"
        >
          Add User
        </NavigationLink>
      );
    }

    // Build the alert node if needed:
    let alertNode;
    if (Object.keys(this.state.alert).length > 0) {
      alertNode = (
        <AbsoluteAlert
          {...this.state.alert}
          handleClose={this.handleCloseAlert}
        />
      );
    }

    // set up "x" in search box if there is text in it
    let xToClear = null;
    if (this.state.searchEmail) {
      xToClear = (
        <span
          className="admin-v2__user-search-input-clear"
          onClick={this.handleClear}
        >
          <div className="tc-x"></div>
        </span>
      );
    }

    // set up user table headers
    const headersNode = (
      <tr>
        <th className="admin-v2__user-table-header">First Name</th>
        <th className="admin-v2__user-table-header">Last Name</th>
        <th className="admin-v2__user-table-header">Email Address</th>
        <th className="admin-v2__user-table-header">Roles</th>
      </tr>
    );

    // by default, show an empty row
    let results = (
      <tr className="user-search-table__row">
        <td className="user-search-table__col"></td>
        <td className="user-search-table__col"></td>
        <td className="user-search-table__col"></td>
        <td className="user-search-table__col"></td>
      </tr>
    );

    if (this.state.results && this.state.results.length > 0) {
      results = this.state.results.map(
        function (user) {
          const roles = [];
          // add roles
          if (user.roles.indexOf('TC_TEACHER') !== -1) {
            roles.push(
              <span key="role-teacher" className="admin-v2__users-table-role">
                T
              </span>
            );
          }
          if (user.roles.indexOf('BL_CONTRACT_COACH') !== -1) {
            roles.push(
              <span key="role-coach" className="admin-v2__users-table-role">
                C
              </span>
            );
          }
          if (user.roles.indexOf('TC_PARTNER') !== -1) {
            roles.push(
              <span key="role-partner" className="admin-v2__users-table-role">
                P
              </span>
            );
          }
          if (user.roles.indexOf('ADMIN') !== -1) {
            roles.push(
              <span key="role-admin" className="admin-v2__users-table-role">
                A
              </span>
            );
          }
          // add permissions
          if (user.roles.indexOf('TC_DATA_VIEWER') !== -1) {
            roles.push(
              <span
                key="permission-data-viewer"
                className="admin-v2__users-table-permission"
              >
                DV
              </span>
            );
          }
          if (user.roles.indexOf('TC_DATA_EDITOR') !== -1) {
            roles.push(
              <span
                key="permission-data-editor"
                className="admin-v2__users-table-permission"
              >
                DE
              </span>
            );
          }
          if (user.roles.indexOf('TC_CONTENT_VIEWER') !== -1) {
            roles.push(
              <span
                key="permission-content-viewer"
                className="admin-v2__users-table-permission"
              >
                CV
              </span>
            );
          }
          if (user.roles.indexOf('TC_CONTENT_CURATOR') !== -1) {
            roles.push(
              <span
                key="permission-content-curator"
                className="admin-v2__users-table-permission"
              >
                CC
              </span>
            );
          }
          if (user.roles.indexOf('MT_CONTENT_CURATOR') !== -1) {
            roles.push(
              <span
                key="permission-mt-content-curator"
                className="admin-v2__users-table-permission"
              >
                MT
              </span>
            );
          }
          if (user.roles.indexOf('BL_SERVICES_MANAGER') !== -1) {
            roles.push(
              <span
                key="permission-services-manager"
                className="admin-v2__users-table-permission"
              >
                SM
              </span>
            );
          }
          if (user.roles.indexOf('BL_MANAGER') !== -1) {
            roles.push(
              <span
                key="permission-user-manager"
                className="admin-v2__users-table-permission"
              >
                UM
              </span>
            );
          }

          const row = (
            <tr className="user-search-table__row" key={user.id}>
              <td className="admin-v2__user-table-col">
                <NavigationLink
                  url={'/bl/user/' + user.id}
                  className="tc__link"
                >
                  {user.first_name}
                </NavigationLink>
              </td>
              <td className="admin-v2__user-table-col">
                <NavigationLink
                  url={'/bl/user/' + user.id}
                  className="tc__link tc__link--black tc__link--light"
                >
                  {user.last_name}
                </NavigationLink>
              </td>
              <td className="admin-v2__user-table-col">
                <NavigationLink
                  url={'/bl/user/' + user.id}
                  className="tc__link tc__link--black tc__link--light"
                >
                  {user.email}
                </NavigationLink>
              </td>
              <td className="admin-v2__user-table-col">{roles}</td>
            </tr>
          );

          return row;
        }.bind(this)
      );
    }
    const hideEmailClass =
      this.state.searchType === 'email' ? '' : ' tc__hidden';
    const hideNameClass = this.state.searchType === 'name' ? '' : ' tc__hidden';

    return (
      <div>
        <Header page="AdminDashboard" {...this.props} />
        {alertNode}

        <div className="row admin-v2__user-search">
          <form
            onSubmit={this.handleSearch}
            className="cute-12-desktop admin-v2__user-search-form"
          >
            <div className={'admin-v2__search-container' + hideEmailClass}>
              <div className="admin-v2__user-search-input-caption tc__body-copy">
                Email
              </div>
              <div className="admin-v2__user-search-input-container">
                <span className="admin-v2__user-search-input-magnifying-glass">
                  <div className="tc-search"></div>
                </span>
                <input
                  type="text"
                  placeholder="Search by email"
                  ref="email"
                  value={this.state.searchEmail}
                  onChange={this.handleSearchChange}
                  className="admin-v2__user-search-input tc__input"
                />
              </div>
            </div>
            <div className={'admin-v2__search-container' + hideNameClass}>
              <div className="admin-v2__user-search-input-caption tc__body-copy">
                First Name
              </div>
              <div className="admin-v2__user-search-input-container">
                <span className="admin-v2__user-search-input-magnifying-glass">
                  <div className="tc-search"></div>
                </span>
                <input
                  type="text"
                  placeholder="Search by First Name"
                  ref="firstName"
                  value={this.state.searchFirstName}
                  onChange={this.handleSearchChange}
                  className="admin-v2__user-search-input tc__input"
                />
              </div>
            </div>
            <div className={'admin-v2__search-container' + hideNameClass}>
              <div className="admin-v2__user-search-input-caption tc__body-copy">
                Last Name
              </div>
              <div className="admin-v2__user-search-input-container">
                <span className="admin-v2__user-search-input-magnifying-glass">
                  <div className="tc-search"></div>
                </span>
                <input
                  type="text"
                  placeholder="Search by Last Name"
                  ref="lastName"
                  value={this.state.searchLastName}
                  onChange={this.handleSearchChange}
                  className="admin-v2__user-search-input tc__input"
                />
                {xToClear}
              </div>
            </div>
            <button
              onClick={this.handleSearch}
              className="admin-v2__search-btn tc__btn tc__btn--blue tc__btn--small"
            >
              <div className="tc-search"></div>
            </button>
            {createUserNode}
            <div className="admin-v2__search-type-toggle">
              or search by{' '}
              <a className="tc__link" onClick={this.handleSearchTypeChange}>
                {this.state.searchType === 'email' ? 'name' : 'email'}
              </a>
            </div>
          </form>
        </div>

        <div className="row">
          <table className="user-search-table">
            <tbody>
              {headersNode}
              {results}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default AdminUsers;
