// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import SessionDateTime from './SessionDateTime';

const ReservationSessions = (props) => {
  let sessionsNode;
  if (props.sessions.length === 1) {
    const session = props.sessions[0];
    sessionsNode = (
      <SessionDateTime
        sessionDate={session.session_date_formatted}
        sessionStartTime={session.session_start_time_formatted}
        sessionEndTime={session.session_end_time_formatted}
        timezone={props.timezone}
        singleSession={true}
        name={session.name}
      />
    );
  } else if (props.advancedEvent) {
    const groupNodes = props.eventGroups.map((group) => {
      const activeGroupClass = group.id === props.selectedGroup ? 'active' : '';
      return (
        <div
          key={`group-${group.id}`}
          className={`left-tab reservation__session-element ${activeGroupClass}`}
          onClick={() => props.setSelectedGroup(group.id)}
        >
          <span className="v5__heading-6 clickable">{group.name}</span>
        </div>
      );
    });
    const sessionsForGroup = props.sessions.filter(
      (session) => session.group_id === props.selectedGroup
    );
    const groupSessions = sessionsForGroup.map((session) => {
      if (session.group_id === props.selectedGroup) {
        return (
          <SessionDateTime
            sessionDate={session.session_date_formatted}
            sessionStartTime={session.session_start_time_formatted}
            sessionEndTime={session.session_end_time_formatted}
            timezone={props.timezone}
            singleSession={sessionsForGroup.length === 1}
            name={session.name}
            key={`session-${session.id}`}
          />
        );
      }
    });
    sessionsNode = (
      <div className="row v5__body-copy">
        <div className="cute-4-desktop cute-12-phone">
          <div className="reservation__session-element bold">Event Groups</div>
          {groupNodes}
        </div>
        <div className="cute-8-desktop cute-12-phone">
          <div className="reservation__session-element bold">Sessions</div>
          {groupSessions}
        </div>
      </div>
    );
  } else if (props.sessions.length > 1) {
    let sessionsList = [...props.sessions];
    // If there are multiple groups then we need to filter out duplicate sessions
    if (props.eventGroups.length > 1) {
      sessionsList = [];
      const sessionNumSet = new Set();
      props.sessions.forEach((session) => {
        if (!sessionNumSet.has(session.num)) {
          sessionNumSet.add(session.num);
          sessionsList.push(session);
        }
      });
    }
    const singleSession = sessionsList.length === 1;
    sessionsNode = sessionsList.map((session) => {
      return (
        <SessionDateTime
          sessionDate={session.session_date_formatted}
          sessionStartTime={session.session_start_time_formatted}
          sessionEndTime={session.session_end_time_formatted}
          timezone={props.timezone}
          singleSession={singleSession}
          name={session.name}
          key={`session-${session.id}`}
        />
      );
    });
  }

  return sessionsNode;
};

ReservationSessions.propTypes = {
  sessions: PropTypes.array.isRequired,
  eventGroups: PropTypes.array.isRequired,
  advancedEvent: PropTypes.number.isRequired,
  selectedGroup: PropTypes.number.isRequired,
  setSelectedGroup: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default ReservationSessions;
