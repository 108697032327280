// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../NavigationLink';

const ParticipantActivityLink = (props) => {
  // link to certain types of activities
  const createActivityLink = (activity) => {
    let linkUrl = '';
    switch (activity.sort_type) {
      // Different entry types have mostly the same text with the primary differentiation being the icon
      case 'Entry':
        linkUrl = `/lab/workspace/${props.userId}?scrollToEntryId=${activity.id}`;
        break;
      // Comments link to the entry that was commented
      case 'Comment':
        linkUrl = `/lab/workspace/${props.userId}?scrollToEntryId=${activity.id}`;
        break;
      // The Vision entry always appears at the top of the page so no special link is required
      case 'Vision':
        linkUrl = `/lab/workspace/${props.userId}`;
        break;
      // Goals are are at the top of the page, no special link required
      case 'Milestone':
        linkUrl = `/lab/workspace/${props.userId}`;
        break;
      // No link here
      case 'Participant':
        break;
      // Only coaches can access the meeting page
      case 'Meeting':
        linkUrl = `/lab/participant/${props.participantId}/meeting/${activity.id}/details`;
        break;
      case 'Favorite':
        linkUrl =
          process.env.REACT_APP_TEACHING_URL +
          (activity.type == 'lesson'
            ? `/lesson/${activity.id}`
            : `/strategy/${activity.id}`);
        break;
    }
    return linkUrl;
  };

  // Capitalize the activity type (can't use .titleCase)
  const activityType =
    props.activity.type.charAt(0).toUpperCase() +
    props.activity.type.substr(1).toLowerCase();

  // Scroll to the active entry
  const activityLink = createActivityLink(props.activity);
  let activityText = `${activityType} ${props.activity.action}`;
  if (activityLink) {
    activityText = (
      <NavigationLink url={activityLink} className="tc__link">
        {activityText}
      </NavigationLink>
    );
  }

  return activityText;
};

ParticipantActivityLink.propTypes = {
  activity: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  participantId: PropTypes.number.isRequired,
};

export default ParticipantActivityLink;
