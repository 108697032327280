// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import LessonApi from './../../apis/Static/LessonApi';
import FavoriteApi from './../../apis/V4/FavoriteApi';
import Analytics from './../../components/Analytics';
import Modal from './../../components/Modal';
import { History, Router } from './../../components/Router';
import Store from './../../components/Store';
import HeaderStore from './../../stores/V4/HeaderStore';

const store = {
  user: null,
  mtp_page_data: {},
  similarLessonData: {},
  lessonImage: null,
  openArtifactAfterLoad: {
    shouldOpen: false,
    artifactType: null,
    artifactId: null,
  },
  ui: {
    sectionExpandToggleCount: 0,
    lessonDropdownActive: false,
    unitDropdownActive: false,
    favorited: false,
    favoriteLoading: false,
    recommendLoading: false,
    participants: {
      inputStatus: 'blur',
      charCount: 0,
      selectedOption: null,
      options: [],
      visibleOptions: [],
    },
    recommendModal: {
      callbacks: {
        onOpen: (data) => {
          Analytics.logEvent({
            category: 'recommend_modal',
            action: 'open',
            label: 'from_mtp_lesson',
          });
        },
        onClose: (data) => {
          if (data.closeFromX) {
            Analytics.logEvent({
              category: 'recommend_modal',
              action: 'cancel',
              label: 'from_mtp_lesson',
            });
          }
        },
      },
    },
    // clicking on a file img opens the artifact overlay
    artifactModal: {
      callbacks: {
        onOpen: (data) => {
          Analytics.logEvent({
            category: 'mtp_resource',
            action: 'show_overlay',
            label: 'from_mtp_lesson',
          });
          store.clearOpenArtifactAfterLoad();
          let historyUrl = '/lesson/resource/' + data.id + '/' + data.seo_url;
          if (data.lessonFileType == 'reflection') {
            historyUrl = '/lesson/reflection/' + data.id + '/' + data.seo_url;
          } else if (data.lessonFileType == 'shortcut') {
            historyUrl =
              '/lesson/resource/' +
              data.id +
              '/' +
              data.shortcut_id +
              '/' +
              data.seo_url;
          }
          History.replace(null, data.name + ' | BetterLesson', historyUrl);
        },
        onClose: () => {
          History.replace(
            null,
            store.mtp_page_data.Lesson.course_name +
              ' ' +
              store.mtp_page_data.Lesson.name +
              ' | BetterLesson',
            '/lesson/' +
              store.mtp_page_data.Lesson.id +
              '/' +
              store.mtp_page_data.Lesson.seo_url
          );
        },
      },
    },
    shareModal: {
      callbacks: {
        onOpen: (data) => {
          // Record the analytics event:
          Analytics.logEvent({
            category: 'share_modal',
            action: 'open',
            label: 'from_lesson',
          });
        },
        onClose: () => {
          // Record the analytics event:
          Analytics.logEvent({
            category: 'share_modal',
            action: 'close',
            label: 'from_lesson',
          });
        },
      },
    },
  },
};
/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

const setAlert = (message, type) => {
  store.ui.alert = {
    type: type,
    message: message,
    handleClose: store.clearAlert,
  };
};

store.init = (data) => {
  store.ui.sectionExpandToggleCount = 0;
  store.mtp_page_data = data.mtp_page_data;
  store.user = data.user;

  // reset openArtifactAfterLoad
  store.openArtifactAfterLoad = {
    shouldOpen: false,
    artifactType: null,
    artifactId: null,
  };
  // if the page has a resource url open it after the connections are collected
  if (data.mtp_page_data.navigate_to_resource_id) {
    store.openArtifactAfterLoad.shouldOpen = true;
    store.openArtifactAfterLoad.artifactType = 'Resource';
    if (data.mtp_page_data.is_shortcut) {
      store.openArtifactAfterLoad.isShortcut = true;
    }
    store.openArtifactAfterLoad.artifactId =
      data.mtp_page_data.navigate_to_resource_id;
  }
  // if the page has a reflection url open it after the connections are collected
  if (data.mtp_page_data.navigate_to_reflection_id) {
    store.openArtifactAfterLoad.shouldOpen = true;
    store.openArtifactAfterLoad.artifactType = 'Reflexions';
    store.openArtifactAfterLoad.artifactId =
      data.mtp_page_data.navigate_to_reflection_id;
  }

  store.ui.alert = {};
  store.ui.favorite = data.favorite ? data.favorite : { status: 'unfavorited' };
  store.ui.artifactModal = Modal(store, store.ui.artifactModal);
  // Init modals
  store.ui.recommendModal = Modal(store, store.ui.recommendModal);
  store.ui.shareModal = Modal(store, store.ui.shareModal);
  store.similarLessonCount = 0;
  store.ui.participants.options = data.participants;
  return false;
};

store.clearOpenArtifactAfterLoad = () => {
  store.openArtifactAfterLoad.shouldOpen = false;
  store.openArtifactAfterLoad.artifactType = null;
  store.openArtifactAfterLoad.artifactId = null;
};
store.getSimilarLessonCount = () => {
  return LessonApi.getSimilarLessonCount(store.mtp_page_data.Lesson.id).then(
    (response) => {
      store.similarLessonData = {
        count: response.count,
        url: response.url,
      };
    }
  );
};

store.closeUnitDropdown = () => {
  Analytics.logEvent({
    category: 'unit_menu',
    action: 'hide',
    label: 'from_breadcrumb_lesson',
  });
  store.ui.unitDropdownActive = false;
  document.removeEventListener('click', store.closeUnitDropdown);
};
store.toggleUnitDropdown = (feature) => {
  Analytics.logEvent({
    category: 'unit_menu',
    action: store.ui.unitDropdownActive ? 'hide' : 'show',
    label: 'from_breadcrumb_lesson',
  });
  // close open dropdowns
  store.ui.lessonDropdownActive = false;
  // open appropreate dropdown
  store.ui.unitDropdownActive = !store.ui.unitDropdownActive;
  // add listner to close the dropdown from anywhere click

  if (store.ui.unitDropdownActive)
    delayedEventListener(() =>
      document.addEventListener('click', store.closeUnitDropdown)
    );
};
store.closeLessonDropdown = () => {
  Analytics.logEvent({
    category: 'lesson_menu',
    action: 'hide',
    label: 'from_breadcrumb_lesson',
  });
  store.ui.lessonDropdownActive = false;
  document.removeEventListener('click', store.closeLessonDropdown);
};
store.toggleLessonDropdown = (feature) => {
  Analytics.logEvent({
    category: 'lesson_menu',
    action: store.ui.unitDropdownActive ? 'hide' : 'show',
    label: 'from_breadcrumb_lesson',
  });
  // close open dropdowns
  store.ui.unitDropdownActive = false;
  // open appropreate dropdown
  store.ui.lessonDropdownActive = !store.ui.lessonDropdownActive;
  // add listner to close the dropdown from anywhere click

  if (store.ui.lessonDropdownActive)
    delayedEventListener(() =>
      document.addEventListener('click', store.closeLessonDropdown)
    );
};

store.addFavorite = (data) => {
  if (store.user && !store.user.id) {
    HeaderStore.actionDispatch('openRegisterModal', {
      lessonId: store.mtp_page_data.Lesson.id,
      reason: 'favorite_attempt',
    });
    return true;
  }
  const requestData = {
    itemType: 'lesson',
    itemId: store.mtp_page_data.Lesson.id,
  };
  if (data.recommend) {
    requestData.userId = store.ui.participants.selectedOption.user_id;
    store.ui.recommendLoading = true;
  } else {
    store.ui.favoriteLoading = true;
  }
  return FavoriteApi.addFavorite(requestData)
    .then((response) => {
      if (data.recommend) {
        Analytics.logEvent({
          category: 'recommend_modal',
          action: 'recommend_to_participant',
          label: 'from_mtp_lesson',
        });
        setAlert(
          'Recommended for ' +
            store.ui.participants.selectedOption.first_name +
            ' ' +
            store.ui.participants.selectedOption.last_name,
          'alert'
        );
        store.ui.participants.charCount = 0;
        store.ui.participants.selectedOption = null;
        store.ui.participants.visibleOptions = [];
        store.ui.participants.inputStatus = 'blur';
        store.ui.recommendLoading = false;
        store.ui.recommendModal.close();
        return;
      } else {
        Analytics.logEvent({
          category: 'lesson',
          action: 'favorite',
          label: 'from_mtp_lesson',
        });
      }

      store.mtp_page_data.Lesson.favorite_count++;
      store.ui.favorite = response.payload;
      store.ui.favoriteLoading = false;
    })
    .catch((response) => {
      if (!response.access) {
        setErrorAlert(
          response.error
            ? response.error
            : 'Sorry, something went wrong. Please try again later.'
        );
      }
      if (data.recommend) {
        store.ui.participants.charCount = 0;
        store.ui.participants.selectedOption = null;
        store.ui.participants.visibleOptions = [];
        store.ui.participants.inputStatus = 'blur';
        store.ui.recommendModal.close();
        return;
      }
      store.ui.favoriteLoading = false;
      store.ui.recommendLoading = false;
    });
};

store.removeFavorite = (data) => {
  const requestData = {
    itemType: 'lesson',
    itemId: store.mtp_page_data.Lesson.id,
  };
  store.ui.favoriteLoading = true;

  return FavoriteApi.removeFavorite(requestData)
    .then((response) => {
      Analytics.logEvent({
        category: 'lesson',
        action: 'unfavorite',
        label: 'from_mtp_lesson',
      });
      store.mtp_page_data.Lesson.favorite_count--;
      store.ui.favorite = { status: 'unfavorited' };
      store.ui.favoriteLoading = false;
    })
    .catch((response) => {
      store.ui.favorite.status = 'selected';
      store.ui.favoriteLoading = false;
      setErrorAlert(response.error);
    });
};

store.getRecommendElement = (data) => {
  const field = store.ui.participants;
  field.charCount = data.input.length;
  // Only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    return option.label.toLowerCase().includes(data.input.toLowerCase());
  });
};

store.selectRecommendElement = (data) => {
  if (!data.feature || !data.field) {
    return false;
  }
  Analytics.logEvent({
    category: 'recommend_modal',
    action: 'select_participant',
    label: 'from_mtp_lesson',
  });
  Object.assign(store.ui.participants, data.data);
};
store.openInlineResource = (data) => {
  let resource;

  if (data.lessonfiles) {
    data.lessonfiles.forEach((lessonfile) => {
      // if not the correct section go to next itteration
      if (lessonfile.id != data.importantResourceSegments.id) {
        return;
      }
      resource = lessonfile;
    });
  }

  if (!resource) {
    store.mtp_page_data.Lesson.Section.forEach((section) => {
      // if not the correct section go to next itteration
      if (section.LessonSection.id != data.sectionId) {
        return;
      }
      // select the appropreate array depending on artifact type
      if (data.importantResourceSegments.type == 'resource') {
        // loop over resources to find the right artifact
        section.LessonSection.Resource.forEach((lessonFile) => {
          const resourceId =
            data.urlArray.length == 8
              ? lessonFile.Lessonfile.shortcut_id
              : lessonFile.Lessonfile.id;

          if (resourceId != data.importantResourceSegments.id) {
            return;
          }
          resource = lessonFile.Lessonfile;
        });
      } else {
        // loop over reflections to find the right artifact
        section.LessonSection.Reflexion.forEach((reflection) => {
          if (reflection.Reflexion.id != data.importantResourceSegments.id) {
            return;
          }
          resource = reflection.Reflexion;
        });
      }
    });
  }

  if (!resource && data.href) {
    Router.route(data.href);
    return;
  } else if (!resource && !data.href) {
    return;
  }

  // open links in a new window
  if (resource.connections && resource.connections.link) {
    window.open(resource.connections.link, '_blank');
    return;
  }
  // open artifact overlay
  store.ui.artifactModal.open(resource);
};

store.updateSectionExpandToggleCount = () => {
  store.ui.sectionExpandToggleCount = store.ui.sectionExpandToggleCount + 1;
};

export default Store('LessonStore', store);
