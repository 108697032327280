// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import LayerManager from './../../../../components/LayerManager';
import LayerClose from './../../../LayerManager/LayerClose';

const DomainsDelete = (props) => {
  const handleDelete = (e) => {
    e.preventDefault();
    let data = {};
    data = props;
    props.handleDelete(data);
    LayerManager.close();
  };

  return (
    <div>
      <LayerClose href="#" className="modal__close">
        <div className="tc-x"></div>
      </LayerClose>
      <h2 className="portfolio-edit__dialog-headline tc__headline-2">
        Are you sure you want to delete {props.title}?
      </h2>

      <div className="portfolio-edit__dialog-buttons">
        <a
          href="#"
          className="portfolio-edit__dialog-button bl4__btn bl4__btn--red"
          onClick={handleDelete}
        >
          Delete
        </a>

        <LayerClose href="#" className="portfolio-edit__dialog-button bl4__btn">
          Cancel
        </LayerClose>
      </div>
    </div>
  );
};

DomainsDelete.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default DomainsDelete;
