// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';
import React, { useEffect, useState } from 'react';

const OptionSettings = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(
    props.selectedOptions || []
  );
  const [selectedOptionIds, setSelectedOptionIds] = useState(
    updateSelectedOptionIdArray
  );
  const [isEditing, setIsEditing] = useState(false);

  // an option was selected update selected and selectable
  useEffect(() => {
    setSelectedOptions(props.selectedOptions);
    setSelectedOptionIds(updateSelectedOptionIdArray);
  }, [props.selectedOptions]);

  // display appropreate selected options
  const updateSelectedOptionIdArray = () => {
    const optionIds = props.selectedOptions.map((option) => {
      return option.id;
    });

    return optionIds;
  };

  // set selected options
  const updateSelect = (data) => {
    const multiSelectData = Utils.clone(selectedOptions);
    Object.assign(multiSelectData, data.data);

    setSelectedOptions(multiSelectData);
  };

  // remove selected options from the dropdown
  const removeItem = (index) => {
    const multiSelectData = Utils.clone(selectedOptions);
    multiSelectData.splice(index, 1);
    props.updateOptions(multiSelectData);
  };

  // an option was selected
  const handleSelectOption = (selectedOption) => {
    const multiSelectData = Utils.clone(selectedOptions);

    // Check if the domain is already in the domains selected for the goal
    const index = selectedOptionIds.findIndex((optionId) => {
      return optionId === selectedOption.id;
    });

    // If the domain is already selected for the goal then remove it, otherwise add it
    if (index !== -1) {
      // Remove the domain if it's already there
      multiSelectData.splice(index, 1);
    } else {
      multiSelectData.push(selectedOption);
    }

    props.updateOptions(multiSelectData);
  };

  // disable the "Add Options" link if the user already selected all the option
  let addClass = '';
  if (selectedOptions.length === props.allOptions.length) {
    addClass = ' disabled';
  }

  // set up the options
  let selectNode = (
    <div className="form-field" id={`settings-${props.label}`}>
      <div className="settings__multiselect-items">
        {selectedOptions.map((selectedOption) => {
          return (
            <div key={selectedOption.id} className="settings__multiselect-item">
              {selectedOption.label}
              <span
                onClick={() => handleSelectOption(selectedOption)}
                className="tc-x settings__multiselect-item-x"
              />
            </div>
          );
        })}
      </div>
      <div>
        <a
          className={`tc__link ${addClass}`}
          onClick={() => setIsEditing(true)}
        >
          Add {props.label}
        </a>
      </div>
    </div>
  );

  if (isEditing) {
    selectNode = (
      <div className="form-field" id={`settings-${props.label}`}>
        <MultiSelect
          stayOpenOnSelect={false}
          selectedOptions={selectedOptions}
          handleSelectOption={handleSelectOption}
          dropdownOptions={props.allOptions}
          field={props.label}
          minCharCountForResults={0}
          updateMultiSelect={updateSelect}
          getVisibleOptions={props.getMultiselectOptions}
          closeOptions={() => setIsEditing(false)}
        />
      </div>
    );
  }
  return <React.Fragment>{selectNode}</React.Fragment>;
};

export default OptionSettings;
