// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import Artifact from './Narrative/Artifact';

const Strategy = (props) => {
  const data = props.entry.data;

  // only show these highlights when their value is not zero
  let teachersTriedNode = null;
  if (data.teachers_tried) {
    const teachersCaptionNode =
      parseInt(data.teachers_tried) === 1 ? 'Teacher' : 'Teachers';
    teachersTriedNode = (
      <div className="cute-4-desktop left">
        <div className="ppp-strategy__data-value">{data.teachers_tried}</div>
        <div className="ppp-strategy__data-caption">
          {teachersCaptionNode} Used This Strategy
        </div>
      </div>
    );
  }
  let growthAboveBaselineNode = null;
  if (data.average_above_baseline) {
    growthAboveBaselineNode = (
      <div className="cute-4-desktop left">
        <div className="ppp-strategy__data-value">
          {data.average_above_baseline}%
        </div>
        <div className="ppp-strategy__data-caption">Growth Above Baseline</div>
      </div>
    );
  }

  return (
    <div className="ppp__section">
      <div className="row">
        <div className="ppp-strategy cute-12-desktop">
          <div className="ppp-strategy__header">Strategy</div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link ppp-strategy__title"
            href={'/coaching/lab/strategy/' + data.strategy_implementation.id}
          >
            {data.strategy_implementation.name}
          </a>
          <PurifiedHTML
            className="tc__body-copy"
            content={data.strategy_implementation.description}
          />
        </div>
      </div>
      <div className="row">
        <div className="cute-4-desktop left">
          <div
            className={
              'ppp__strategy-detailed-icon tc-sga-nb-' + data.growth_area_id
            }
          ></div>
          <div className="ppp__strategy-detailed-subject">
            <div className="ppp-strategy__header">
              {data.subject_name} Growth Area
            </div>
            <div className="tc__body-copy">{data.growth_area_name}</div>
          </div>
        </div>
        <div className="cute-4-desktop left">
          <div className="ppp-strategy__header">Teaching Challenge</div>
          <div className="tc__body-copy">{data.teaching_challenge_name}</div>
        </div>
      </div>
      <div className="row">
        {teachersTriedNode}
        {growthAboveBaselineNode}
      </div>
      <div className="row">
        <div className="cute-12-desktop">
          {props.entry.extended_data.artifacts.map((artifact) => {
            return (
              <Artifact
                {...artifact}
                showArtifact={props.showArtifact}
                key={
                  'artifact-' +
                  artifact.content_id +
                  '-' +
                  artifact.strategy_implementation_id
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

Strategy.propTypes = {
  entry: PropTypes.object.isRequired,
};

export default Strategy;
