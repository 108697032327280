// @ts-nocheck FIXME
const Hours = (props) => {
  let totalPedagogyHours = 0;
  let totalContentHours = 0;
  let totalHours = 0;

  // Add the pedagogy hours from the stats entry:
  totalPedagogyHours += props.pedagogyHours
    ? Math.round(props.pedagogyHours)
    : 0;

  // Add the additional pedagogy hours from the portfolio:
  totalPedagogyHours += props.additional_pedagogy_hours
    ? Math.round(props.additional_pedagogy_hours)
    : 0;

  // Add the content hours from the stats entry:
  totalContentHours += props.contentHours ? Math.round(props.contentHours) : 0;
  // Add the additional content hours from the portfolio:
  totalContentHours += props.additional_content_hours
    ? Math.round(props.additional_content_hours)
    : 0;

  totalHours = totalPedagogyHours + totalContentHours;

  return (
    <div className="portfolio__stats-row portfolio__stats-row--red">
      <div className="portfolio__stat-number">{totalHours}</div>
      <div className="portfolio__stats-data portfolio__stats-data--double">
        <div className="portfolio__stats-headline">Hours of PD</div>
        <div className="portfolio__stats-subheadline">
          <span className="portfolio__stat-highlight portfolio__stat-highlight--uppercase">
            Pedagogy Hours:
          </span>
          <span className="portfolio__stat-hours">{totalPedagogyHours}</span>
          <span className="portfolio__stat-highlight portfolio__stat-highlight--uppercase">
            Content Hours:
          </span>
          <span className="portfolio__stat-hours">{totalContentHours}</span>
        </div>
      </div>
    </div>
  );
};

export default Hours;
