// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const NoteModal = (props) => {
  let note;

  /***** event listeners ******/
  const handleSubmit = (e) => {
    e.preventDefault();
    const editedValue = props.note ? 'update note' : 'add note';
    props.editTeacher({
      teacherId: props.id,
      note: note.value,
      edited: editedValue,
    });
  };

  const handleCloseOverlay = (e) => {
    e.preventDefault();
    props.setCurrentTeacher(null);
  };

  const submitText = props.note ? 'Update' : 'Add';
  const disabledClass = props.buttonsDisabled ? ' disabled' : '';

  return (
    <div>
      <div
        className="modal__page-overlay modal__page-overlay--grey ppt-modal__page-overlay"
        onClick={handleCloseOverlay}
      ></div>
      <div className="modal--fixed modal--grey-overlay ppt-modal">
        <div className="modal__close" onClick={handleCloseOverlay}>
          <div className="tc-x"></div>
        </div>
        <div className="ppt-modal__body">
          <form>
            <div className="tc__headline-2">Add a Note</div>
            <div className="tc__body-copy ppt-modal__subheadline">
              Teacher: {props.name}
            </div>
            <textarea
              ref={(ref) => (note = ref)}
              className="tc__input ppt-modal__textarea"
              defaultValue={props.note}
            />
            <div className="ppt-modal__buttons">
              <button
                onClick={handleSubmit}
                className={
                  'tc__btn tc__btn--small tc__btn--blue ppt-modal__button' +
                  disabledClass
                }
              >
                {submitText}
              </button>
              <button
                onClick={handleCloseOverlay}
                className={
                  'tc__btn tc__btn--small tc__btn--grey ppt-modal__button ppt-modal__button--cancel' +
                  disabledClass
                }
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

NoteModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  buttonsDisabled: PropTypes.bool,
  editTeacher: PropTypes.func.isRequired,
  setCurrentTeacher: PropTypes.func.isRequired,
};

export default NoteModal;
