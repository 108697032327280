// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import propTypes from 'prop-types';
import EventSessionRow from './EventSessionRow';

const Event = (props) => {
  const event = props.event;

  let eventDescription = event.name;
  if (event.product_code) {
    eventDescription = `${eventDescription} – ${event.product_code}`;
  }
  let eventDates = moment(event.event_start_date).format('M/DD/YY');
  if (event.event_start_date !== event.event_end_date) {
    const endDate = moment(event.event_end_date).format('M/DD/YY');
    eventDates = `${eventDates}–${endDate}`;
  }

  let eventLink = `/lab/event/${event.id}/`;
  if (event.group_id) {
    eventLink += `group/${event.group_id}/preview`;
  } else {
    eventLink += 'preview';
  }

  const eventHeader = (
    <div className="participant-v5__event-row">
      <div>
        <span className="participant-v5__date-time">Date, Time</span>
        <span className="participant-v5__time-zone--name">
          {props.timeZone}
        </span>
      </div>
      <div className="participant-v5__header-text">Name</div>
      <div className="participant-v5__header-text">Design Docs</div>
      <div className="participant-v5__header-text">Status</div>
    </div>
  );

  let eventSessionsNode = null;

  if (props.event.sessions) {
    eventSessionsNode = props.event.sessions.map((session) => (
      <EventSessionRow
        {...session}
        key={`event-${session.session_id}`}
        userTimezone={props.timeZone}
      />
    ));
  }

  return (
    <div>
      <div className="participant-v5__cohort">
        <NavigationLink
          className="participant-v5__cohort-name"
          url={eventLink}
          target="_blank"
        >
          {`${eventDescription}`}
        </NavigationLink>
        <span className="v5__comment-copy">{eventDates}</span>
      </div>
      <div className="participant-v5__meeting-row-header">{eventHeader}</div>
      {eventSessionsNode}
    </div>
  );
};

Event.propTypes = {
  event: propTypes.object.isRequired,
  timeZone: propTypes.string.isRequired,
};

export default Event;
