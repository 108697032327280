// @ts-nocheck FIXME
import PartnerPortfolioToolApi from './../apis/PartnerPortfolioToolApi';
import Analytics from './../components/Analytics';
import { Router } from './../components/Router';
import Store from './../components/Store';

const store = {
  partners: [],
  adding: false,
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.partners = data.partners || [];
  store.ui = {
    partnerAdding: null,
  };

  // don't trigger event
  return false;
};

store.addPartner = (data) => {
  store.ui.partnerAdding = data.partnerId;

  return PartnerPortfolioToolApi.createPartnerPortfolio(data).then(
    // success case
    (response) => {
      store.ui.partnerAdding = null;

      // Record the analytics event:
      Analytics.logEvent({
        category: 'pp',
        action: 'create',
        label: 'from_pp_admin_view',
      });

      // redirect the user to the new portfolio
      Router.route(
        '/bl/v1/partner/portfolio/' +
          response.payload.portfolioId +
          '/edit/COVER'
      );
    },

    // error case
    (response) => {
      store.ui.partnerAdding = null;
    }
  );
};

export default Store('PartnerPortfolioDashboardStore', store);
