// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Auth from '@legacy-components/components/Auth';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const ParticipantList = (props) => {
  const lab = useLegacyLabContext();
  /* People */
  // BL_COACH sees Cohort
  const schoolOrCohort = 'Cohort';
  const timezone = props.user.time_zone.pytz_timezone;

  let photoNode = null;
  /* Name */
  if (props.photo) {
    // Set up user photo instead of the default icon if set
    photoNode = <UserPhoto src={props.photo} diameter={40} hasBorder={false} />;
  } else {
    // Use the participant's name and convert each letter to a char code, sum and take the modulus so they always show as the same color
    const participantName =
      props.first_name + ' ' + props.last_name + props.user_id;
    const index = participantName
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc);

    const colors = [
      '#004493',
      '#1A7CD9',
      '##2196F3',
      '#0B8484',
      '#0AABB1',
      '#53E6EB',
      '#53E6EB',
      '#107D54',
      '#33B55F',
      '#2ACAA1',
      '#25E8C8',
      '#FF6B2A',
      '#F4AB2A',
    ];

    const iconStyles = {
      backgroundColor: colors[index % colors.length],
    };

    photoNode = (
      <div style={iconStyles} className="admin-v5_coach-dashboard_default-icon">
        <span className="admin-v5_coach-dashboard_default-icon__inner">
          {props.first_name.substr(0, 1)}
          {props.last_name.substr(0, 1)}
        </span>
      </div>
    );
  }

  const nameNode = (
    <NavigationLink
      url={`/lab/workspace/${props.user_id}`}
      className="tc__link"
    >
      {props.first_name + ' ' + props.last_name}
    </NavigationLink>
  );

  /* Cohort / School */
  // Show the participant's school or cohort
  let cohortNode = <span>{props.cohort || props.institution.name}</span>;

  if (
    Utils.isServicesManager.call(lab, props.pageUser) ||
    Utils.isContractCoach.call(lab, props.pageUser)
  ) {
    if (props.cohort_overview) {
      // Show the name of the cohort with a link to open a modal showing the cohort's vision
      cohortNode = (
        <a
          className="tc__link"
          onClick={() => {
            props.openCohortVision({
              cohortVision: props.cohort_overview,
              cohortName: props.cohort,
            });
          }}
        >
          {props.cohort}
        </a>
      );
    }
  }

  /* Activity */
  // Show the most recent action taken by the participant
  let activityNode = null;

  if (props.registered) {
    if (props.activity && props.activity.date) {
      // The participant is registered and has activity
      let activityType = null;
      // Capitalize the activity type (can't use .titleCase)
      if (props.activity) {
        activityType =
          props.activity.type.charAt(0).toUpperCase() +
          props.activity.type.substr(1).toLowerCase();
      }

      // Get the date of the last activity and adjust for the local timezone if available
      const baseDate = moment(props.activity.date).tz(timezone);

      const activityDate = baseDate.fromNow();

      // Scroll to the active entry
      let storyLink = `/lab/workspace/${props.user_id}`;

      if (props.active_story_id) {
        storyLink = `/lab/workspace/${props.user_id}?scrollToEntryId=${props.active_story_id}`;
      }

      activityNode = (
        <div>
          <div className="admin-v5_coach-dashboard__activity">
            <NavigationLink url={storyLink} className="tc__link">
              {`${activityType} ${props.activity.action}`}
            </NavigationLink>
          </div>
          <div>{activityDate}</div>
        </div>
      );
    } else {
      const baseDate = moment(props.registration_sent).tz(timezone);

      // The user is registered, but has no activity
      activityNode = (
        <div>
          <div>Registered</div>
          <div>{baseDate.format('MMMM Do')}</div>
        </div>
      );
    }
  }

  /* Next meeting */
  // Display an "Add Meeting" link if the participant has no meetings scheduled
  let nextMeetingNode = null;

  if (Auth.hasCoachAccess(props.user)) {
    nextMeetingNode = (
      <NavigationLink
        className="tc__link"
        url={`/lab/participant/${props.id}/meeting/details/add?from=coach_home`}
      >
        Add
      </NavigationLink>
    );
  }

  // Show when the participant's next meeting will be
  if (props.next_meeting && props.next_meeting.status === 1) {
    // Set a default value
    const nextMeeting = moment(props.next_meeting.meeting_time).tz(timezone);

    nextMeetingNode = (
      <NavigationLink
        target="_blank"
        url={`/lab/participant/${props.id}/meeting/${props.next_meeting.id}/details`}
        className="tc__link"
      >
        <div className="admin-v5__coach--next-meeting-mobile">
          {nextMeeting.format('dddd, MMMM D')}
        </div>
        <div>{nextMeeting.format('h:mm A z')}</div>
      </NavigationLink>
    );
  }
  /* Participant Details */
  let surveyLinkNode = null;
  // icon to link to the survey response in the workspace
  if (props.survey_story_entry_id) {
    surveyLinkNode = (
      <div className="admin-v5__icon-container">
        <div className="tc-v5-survey-icon admin-v5__icon--survey" />
      </div>
    );
  }
  /* Participant Details */
  // icon to link to the participant details page
  const participantDetailsNode = (
    <div className="admin-v5__icons">
      {surveyLinkNode}
      <NavigationLink
        url={`/lab/participant/${props.id}/details`}
        className="tc-v5-details admin-v5_coach-dashboard--participant-details"
      ></NavigationLink>
    </div>
  );

  const toggleUserCell = () => {
    props.toggleShowingDetails(props.id);
  };

  const detailsOpen =
    props.ui.detailsOpen.indexOf(props.id) !== -1 ? '' : 'hide-phone';

  return (
    <div
      className="admin-v5__coach-table admin-v5__coach-table--user-cell"
      key={'coach-participant-' + props.id}
    >
      <div className="admin-v5__coach-participant-details">
        <div className="admin-v5__coach-table--user-row">
          <div className="cute-6-phone admin-v5__coach--table--user-details">
            <NavigationLink
              url={`/lab/workspace/${props.user_id}`}
              className="tc__link admin-v5__coach-table--user-image"
            >
              {photoNode}
            </NavigationLink>
            <div>{nameNode}</div>
          </div>
          <div className="cute-5-phone">{nextMeetingNode}</div>
          <div
            onClick={toggleUserCell}
            className={`cute-1-phone admin-v5__coach-expand-btn tc-v4-circle-arrow workspace__goal-expand-arrow ${
              detailsOpen ? '' : 'workspace__goal-expand-arrow--expanded'
            }`}
          ></div>
        </div>
      </div>
      <div className={`admin-v5__coach-participant--info ${detailsOpen}`}>
        <div className="admin-v5__coach-participant--row">
          <div className="tc__breakword admin-v5_coach-dashboard-email">
            {props.email}
          </div>
        </div>
        <div className="admin-v5__coach-participant--row">
          <div className="admin-v5-coach-table__header-caption">
            {schoolOrCohort}
          </div>
          <div>{cohortNode}</div>
        </div>
        <div className="admin-v5__coach-participant--row">
          <div className="admin-v5-coach-table__header-caption">Activity</div>
          <div>{activityNode}</div>
        </div>
        <div className="admin-v5__coach-participant--row">
          <div>{participantDetailsNode}</div>
        </div>
      </div>
    </div>
  );
};

ParticipantList.propTypes = {
  openCohortVision: PropTypes.func.isRequired,
  pageUser: PropTypes.object.isRequired,
  toggleShowingDetails: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default ParticipantList;
