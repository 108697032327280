import LoadingPage from '@components/LoadingPage/LoadingPage';
import { CreateSigninRequestArgs } from 'oidc-client-ts';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

// DISCLAIMER: Component for local development only
export default function Authenticate(): JSX.Element {
  const { isAuthenticated, isLoading, signinRedirect } = useAuth();
  const navigate = useNavigate();
  const isLocalDevelopment = location.hostname === 'localhost';

  const authenticate = () => {
    if (!isAuthenticated && !isLoading) {
      signinRedirect({
        redirect_uri: window.location.href,
      } as CreateSigninRequestArgs);
      return;
    }

    if (isAuthenticated) {
      navigate('/');
    }
  };

  useEffect(() => {
    if (isLocalDevelopment) authenticate();
  }, [isLoading, isAuthenticated]);

  if (!isLocalDevelopment) {
    navigate('/404');
    return <></>;
  }

  return <LoadingPage />;
}
