// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Analytics from './../../../../components/Analytics';

const EditIntroduction = (props) => {
  let teacherIntroduction;
  let teacherReflection;
  let coachReflection;

  const handleLogInputFocus = (inputType) => {
    // Record the analytics event:
    Analytics.logEvent({
      category: 'TP',
      action: 'add_' + inputType,
      label: 'from_tp_curation_ui',
    });
  };

  const handleFormDataChanges = (e) => {
    e.preventDefault();

    const portfolio = props.portfolio;

    portfolio.teacher_introduction = teacherIntroduction.value.trim();
    portfolio.teacher_reflection = teacherReflection.value.trim();
    portfolio.coach_reflection = coachReflection.value.trim();

    props.updatePortfolio(portfolio);
  };

  return (
    <div className="portfolio-edit__container row">
      <div className="cute-8-desktop center-desktop">
        <div className="portfolio-edit__intro-headline">Introduction</div>
        <label
          htmlFor="teacherIntroduction"
          className="portfolio-edit__intro-text"
        >
          Write a brief introduction for your portfolio. Where did you start?
          How did the work that you did with your coach change your teaching
          practice? What did you learn? If you have specific goals or
          requirements you are trying to meet, discuss them here.
        </label>
        <textarea
          id="teacherIntroduction"
          className="tc__input portfolio-edit__intro-input"
          type="text"
          name="teacherIntroduction"
          ref={(ref) => (teacherIntroduction = ref)}
          placeholder="Enter your introduction"
          defaultValue={props.portfolio.teacher_introduction}
          onChange={handleFormDataChanges}
          onFocus={() => {
            handleLogInputFocus('introduction');
          }}
        />

        <div className="portfolio-edit__separator"></div>

        <div className="portfolio-edit__intro-headline">
          Reflection & Closing
        </div>
        <label
          htmlFor="teacherReflection"
          className="portfolio-edit__intro-text"
        >
          The reflection appears at the end of the portfolio, as a closing
          paragraph or paragraphs.
        </label>
        <textarea
          id="teacherReflection"
          className="tc__input portfolio-edit__intro-input"
          type="text"
          name="teacherReflection"
          ref={(ref) => (teacherReflection = ref)}
          placeholder="Enter your reflection"
          defaultValue={props.portfolio.teacher_reflection}
          onChange={handleFormDataChanges}
          onFocus={() => {
            handleLogInputFocus('reflection_closing');
          }}
        />

        <div className="portfolio-edit__separator"></div>

        <div className="portfolio-edit__intro-headline">Coach Reflection</div>
        <label htmlFor="coachReflection" className="portfolio-edit__intro-text">
          Your coach may add a reflection and notes that will appear at the end
          of your portfolio.
        </label>
        <textarea
          id="coachReflection"
          className="tc__input portfolio-edit__intro-input"
          type="text"
          name="coachReflection"
          ref={(ref) => (coachReflection = ref)}
          defaultValue={props.portfolio.coach_reflection}
          onChange={handleFormDataChanges}
          onFocus={() => {
            handleLogInputFocus('coach_reflection');
          }}
        />
      </div>
    </div>
  );
};

EditIntroduction.propTypes = {
  portfolio: PropTypes.object.isRequired,
  updatePortfolio: PropTypes.func.isRequired,
};

export default EditIntroduction;
