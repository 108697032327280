// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Analytics from './../../../../components/Analytics';
import { Router } from './../../../../components/Router';

const BackButton = (props) => {
  const HandleBackClick = () => {
    Router.route(
      '/coaching/lab/participant/' +
        props.participant.id +
        '/registration/' +
        (props.step - 1) +
        '?from_step=' +
        props.step
    );
    Analytics.logEvent({
      category: 'back',
      action: 'click',
      label: props.analyticsLabel ? props.analyticsLabel : 'from_user_welcome',
    });
  };
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        HandleBackClick();
      }}
      className="tc__btn tc__btn--flat-grey tc__btn--flat-grey-v3"
    >
      {props.caption}
    </button>
  );
};

export default BackButton;

BackButton.propTypes = {
  caption: PropTypes.string,
  participant: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
};
BackButton.defaultProps = {
  caption: 'Back',
};
