// @ts-nocheck FIXME
import React from 'react';
import Utils from './../../components/StaticUtils';

class StudentsImpactedIcons extends React.Component {
  render() {
    // If the parent passed a css class name, append it to the default class name:
    let className = 'students-impacted__icon ';
    if (this.props.cssClassName) {
      className += this.props.cssClassName;
    }

    // Create an array that holds random icons (gender, color):
    const studentsImpactedIcons = [];
    for (let i = 0; i < this.props.studentsImpacted; i++) {
      const gender = Utils.getRandomValueFromArray(['m', 'f']);
      const color = Utils.getRandomValueFromArray(['r', 'b', 'g']);

      // If there are more than 2 lines worth of icons, show the phrase: and X more
      if (i > 40) {
        const manyMore = this.props.studentsImpacted - i;
        if (manyMore > 5) {
          studentsImpactedIcons.push(
            <div key={'student-more'} className="students-impacted__more">
              {' '}
              and {Utils.numberWithCommas(manyMore)} more.
            </div>
          );
          break;
        }
      }

      // Customize the class based on color and gender
      const iconClassName =
        className +
        ' students-impacted__icon--' +
        color +
        ' tc-student-' +
        gender;

      studentsImpactedIcons.push(
        <div key={'student-icon-' + i} className={iconClassName}></div>
      );
    }

    return <div>{studentsImpactedIcons}</div>;
  }
}

export default StudentsImpactedIcons;
