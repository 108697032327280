// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import HelpDeskLinkGateway from '@legacy-components/react/V5/Common/HelpDeskLinkGateway';
import PropTypes from 'prop-types';
import React from 'react';
import EventCheckIn from './EventCheckIn';
import EventFacilitator from './EventFacilitator';
import EventZoomCallToAction from './EventZoomCallToAction';

const EventAttendeeSessionContent = (props) => {
  // find the event session participant record that indicates that the participant has checked in
  const hasCheckedIn =
    Utils.getItemIndexFromArrayOfObjects(
      props.eventSessionParticipants,
      'session_id',
      parseInt(props.activeSession.id)
    ) !== -1;

  const sessionActivity = props.sessionActivity[props.activeSession.id];

  if (
    !props.userHasAdminPermissions &&
    !sessionActivity.ended &&
    (sessionActivity.sessionDateStarted || sessionActivity.started) &&
    !hasCheckedIn
  ) {
    return (
      <EventCheckIn
        user={props.user}
        event={props.event}
        handleSessionCheckin={props.handleSessionCheckin}
      />
    );
  }

  let missedSessionNode = null;
  if (
    !props.userHasAdminPermissions &&
    props.participant &&
    sessionActivity.ended &&
    !hasCheckedIn
  ) {
    missedSessionNode = (
      <div className="event__attendee-check-in">
        <div className="event__attendee-check-in-headling v5__heading-5">
          We missed you at this session!
        </div>
        <div className="event__attendee-check-in-copy v5__body-copy">
          Take a look at the event materials below to review what you missed.
        </div>
      </div>
    );
  }

  let facilitatorCardsNode = null;
  let facilitatorsNode = null;
  if (props.activeSession.facilitators) {
    facilitatorCardsNode = props.activeSession.facilitators.map(
      (facilitator) => {
        return (
          <EventFacilitator
            key={`facilitator-${facilitator.id}`}
            facilitator={facilitator}
          />
        );
      }
    );
    if (props.activeSession.facilitators.length) {
      facilitatorsNode = (
        <React.Fragment>
          <h4 className="event__attendee-section-headline v5__heading-3">
            Your Facilitators
          </h4>
          <div className="event__attendee-facilitators">
            {facilitatorCardsNode}
          </div>
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      <div className="">
        <EventZoomCallToAction
          eventTimezone={props.event.time_zone.pytz_timezone}
          activeSession={props.activeSession}
          sessionActivity={sessionActivity}
          zoomLink={
            props.activeSession.zoom_join_link || props.activeSession.zoom_link
          }
          checkedIn={hasCheckedIn}
        >
          <HelpDeskLinkGateway headline="Need help joining the session?" />
        </EventZoomCallToAction>
        {missedSessionNode}
        {facilitatorsNode}
      </div>
      {props.children}
      <HelpDeskLinkGateway
        className="event-help__attendee-block"
        headline="Need help with this event?"
      />
    </React.Fragment>
  );
};

export default EventAttendeeSessionContent;

EventAttendeeSessionContent.propTypes = {
  activeSession: PropTypes.object.isRequired,
  sessionActivity: PropTypes.object,
  eventSessionParticipants: PropTypes.array,
  user: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  handleSessionCheckin: PropTypes.func,
};
