// @ts-nocheck FIXME
import Analytics from '@legacy-components/components/Analytics';
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import React from 'react';
import SelectedOption from './modules/SelectedOption';
import SingleSelectOptions from './modules/SingleSelectOptions';

const SingleSelect = (props) => {
  const textInput = React.useRef(null);
  const [inFocus, setInFocus] = React.useState(false);

  // We would like to wait for the user to stop typing before we fire calls to the server
  let onChangeTimeout = null;
  const handleSelectClick = () => {
    // e.stopPropagation();

    if (props.readOnly) {
      return false;
    }

    textInput.current.focus();
    // props.updateSingleSelect({
    //     field: props.field,
    // });

    delayedEventListener(() =>
      window.addEventListener('click', closeOptionsDropdown)
    );
  };

  const handleSelectOption = (option, e) => {
    e.preventDefault();

    if (props.readOnly) {
      return false;
    }

    textInput.current.value = option.label;
    const data = {
      visibleOptions: [],
      selectedOption: option,
    };
    props.updateSingleSelect({
      field: props.field,
      data: data,
    });
    if (props.selectAnalytics) {
      Analytics.logEvent(props.selectAnalytics);
    }
  };

  const handleClearOption = (e) => {
    e.preventDefault();

    if (props.readOnly) {
      return false;
    }

    textInput.current.value = '';
    props.updateSingleSelect({
      field: props.field,
      data: {
        visibleOptions: [],
        selectedOption: null,
      },
    });

    // If the parent provided a clear option handler, call it
    if (
      props.handleClearOption &&
      typeof props.handleClearOption === 'function'
    ) {
      props.handleClearOption(e);
    }
    if (props.removeAnalytics) {
      Analytics.logEvent(props.removeAnalytics);
    }
  };

  const handleFocus = (e) => {
    if (props.readOnly) {
      return false;
    }

    setInFocus(true);

    const input = textInput.current.value.toLowerCase();
    if (input.length >= props.minCharCountForResults) {
      props.getVisibleOptions({
        field: props.field,
        input: input,
      });
    }
    //
    // props.updateSingleSelect({
    //     field: props.field
    // });
    // If the parent provided a focus handler, call it
    if (props.handleFocus && typeof props.handleFocus === 'function') {
      props.handleFocus(e);
    }
    if (props.focusAnalytics) {
      Analytics.logEvent(props.focusAnalytics);
    }
  };

  const handleBlur = (e) => {
    setInFocus(false);

    // If the parent provided a blur handler, call it
    if (props.handleBlur && typeof props.handleBlur === 'function') {
      props.handleBlur(e);
    }
  };

  const closeOptionsDropdown = () => {
    if (props.readOnly) {
      return false;
    }

    window.removeEventListener('click', closeOptionsDropdown);
    // props.updateSingleSelect({
    //     field: props.field
    // });
  };

  const handleTyping = () => {
    if (props.readOnly) {
      return false;
    }

    if (props.restrictTo) {
      const restrictTo = new RegExp(props.restrictTo);
      if (!restrictTo.test(textInput.current.value)) {
        textInput.current.value = textInput.current.value.slice(0, -1);
      }
    }

    // Parent component may request to delay firing the event (e.g. 500 ms)
    // to give the user some room to finish typing
    const onChangeDelay = props.onChangeDelay || 0;

    clearTimeout(onChangeTimeout);

    onChangeTimeout = setTimeout(function () {
      // Handle non-existing input
      if (!textInput) {
        return;
      }
      const input = textInput.current.value.toLowerCase();
      if (input.length >= props.minCharCountForResults) {
        props.getVisibleOptions({
          field: props.field,
          input: input,
        });
      }
    }, onChangeDelay);
  };

  const singleSelectClass = props.selectedOption
    ? ' custom-select__selected-options--selected'
    : ' custom-select__selected-options--empty';

  const optionsVisibleClass = props.selectedOptions
    ? 'custom-select--options-visible'
    : '';

  const errorClass = props.error ? 'error' : '';

  return (
    <div className="custom-select__container">
      <div
        className={`custom-select v5__input ${optionsVisibleClass} ${errorClass}`}
        onClick={handleSelectClick}
      >
        <div className={`custom-select__selected-options ${singleSelectClass}`}>
          <input
            type="text"
            ref={textInput}
            className="tc__input tc__input--custom-select tc__input--custom-single-select tc__input--custom-select-empty"
            placeholder={props.placeholder}
            defaultValue={props.selectedOption && props.selectedOption.label}
            readOnly={props.selectedOption}
            onChange={handleTyping}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <SelectedOption
            selectedOption={props.selectedOption}
            handleClearOption={handleClearOption}
          />
        </div>
      </div>
      <SingleSelectOptions
        selectedOption={props.selectedOption}
        charCount={textInput.current ? textInput.current.value.length : 0}
        minCharCountForResults={props.minCharCountForResults}
        handleSelectOption={handleSelectOption}
        visibleOptions={props.visibleOptions}
        inFocus={inFocus}
      />
    </div>
  );
};

SingleSelect.propTypes = {
  error: PropTypes.string,
  field: PropTypes.string.isRequired, // This is an ID used to organize the data for multiple single selects
  placeholder: PropTypes.string,
  minCharCountForResults: PropTypes.number, // Use 0 to make this work as a single select
  selectedOption: PropTypes.object, // TODO: create the proper shape here so others can figure out how to use this component
  visibleOptions: PropTypes.array, // The options to display, pass an empty array if something is selected
  updateSingleSelect: PropTypes.func, // callback used to update the state in the parent
  getVisibleOptions: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  restrictTo: PropTypes.string, // RegExp to limit the allowed characters
};

SingleSelect.defaultProps = {
  minCharCountForResults: 2, // The default requires the user to type something to show a result
  readOnly: false,
};

export default SingleSelect;
