// @ts-nocheck FIXME
import moment from 'moment-timezone';
import propTypes from 'prop-types';
import React from 'react';
import SurveyQuestions from './SurveyQuestions';

const SurveysRow = (props) => {
  // hide extra information by default
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const arrowClass = isExpanded ? ' coach-surveys__expand-arrow--up' : '';

  /**
   * given start and end time string in format like 2021-04-21T15:30:00-0400
   * return formatted duration like 3:15-5:30 PM EST
   * @param {string} startTime session start time
   * @param {string} endTime session end time
   * @param {string|null} timezone pytz format timezone string, default null (treat as EST)
   */
  const formatTimeRange = (startTime, endTime, timezone = null) => {
    // convert to local timezone if needed
    if (timezone) {
      startTime = moment.tz(startTime, timezone);
      endTime = moment.tz(endTime, timezone);
    } else {
      startTime = moment(startTime);
      endTime = moment(endTime);
    }

    let formattedStartTime, formattedEndTime;

    // if session time is on the whole hour, then hide the minutes
    if (startTime.minutes() === 0) {
      formattedStartTime = startTime.format('h');
    } else {
      formattedStartTime = startTime.format('h:mm');
    }

    if (endTime.minutes() === 0) {
      formattedEndTime = endTime.format('h A');
    } else {
      formattedEndTime = endTime.format('h:mm A');
    }

    // if no timezone passed in, treat as EST
    const timezoneAbbr = timezone ? startTime.format('z') : 'EST';

    // add space in front of indicator for easier formatting
    let startTimeNoonIndicator = startTime.format('A');
    // if session starts before noon and ends after noon (or vice versa), include am/pm indicator
    if (startTime.format('A') === formattedEndTime.slice(-2)) {
      startTimeNoonIndicator = '';
    } else {
      startTimeNoonIndicator = ' ' + startTimeNoonIndicator;
    }
    return `${formattedStartTime}${startTimeNoonIndicator}–${formattedEndTime} ${timezoneAbbr}`;
  };

  return (
    <>
      <div className="grid-ten__row">
        <div className="coach-surveys__table-row">
          <div className="grid-ten__col-2">
            <div>
              <div className="coach-surveys__session-name coach-surveys__bold-weight">
                {`${props.event_display_name || props.event_name} - ${
                  props.name
                }${props.group_name ? ', ' + props.group_name : ''}`}
              </div>
              <div className="coach-surveys__event-side-text">
                {props.total_sessions > 1 ? (
                  <div>{`Session ${props.num} of ${props.total_sessions}`}</div>
                ) : null}
                <div>{`${props.partner_name || ''}`}</div>
              </div>
            </div>
          </div>
          <div className="grid-ten__col-1">{props.product_code || ''}</div>
          <div className="grid-ten__col-2 coach-surveys__date-text">
            <div className="coach-surveys__bold-weight">
              {moment(props.session_start_time).format('MMMM D, YYYY')}
            </div>
            <div className="coach-surveys__bold-weight">
              {formatTimeRange(
                props.session_start_time,
                props.session_end_time
              )}
            </div>
            {props.event_timezone.id !== 1 ? (
              <div>
                {formatTimeRange(
                  props.session_start_time,
                  props.session_end_time,
                  props.event_timezone.pytz
                )}
              </div>
            ) : null}
          </div>
          <div className="grid-ten__col-1">
            <span className="coach-surveys__bold-weight">
              {props.surveys_completed_count}
            </span>
            <span>&nbsp;of {props.checked_in_users_count}</span>
          </div>
          <div className="grid-ten__col-1">
            {Number.isInteger(props.response_rate)
              ? props.response_rate
              : props.response_rate.toFixed(1)}{' '}
            %
          </div>
          <div className="grid-ten__col-1">
            <span className="coach-surveys__bold-weight">{props.nps}</span>
          </div>
          <div className="grid-ten__col-1">
            {Number.isInteger(props.satisfaction_rate)
              ? props.satisfaction_rate
              : props.satisfaction_rate.toFixed(1)}{' '}
            %
          </div>
          <div className="grid-ten__col-1 coach-surveys__expand-arrow-container">
            <div
              className={`tc-v4-circle-arrow arrow coach-surveys__expand-arrow${arrowClass}`}
              onClick={toggleExpanded}
              data-testid={`row-${props.session_id}`}
            ></div>
          </div>
        </div>
      </div>
      <SurveyQuestions
        content={props.survey_questions}
        isExpanded={isExpanded}
      />
    </>
  );
};

SurveysRow.propTypes = {
  event_display_name: propTypes.string,
  event_name: propTypes.string.isRequired,
  satisfaction_rate: propTypes.number.isRequired,
  satisfaction_count: propTypes.number.isRequired,
  nps: propTypes.number.isRequired,
  checked_in_users_count: propTypes.number.isRequired,
  event_timezone: propTypes.object.isRequired,
  group_name: propTypes.string,
  name: propTypes.string,
  partner_name: propTypes.string.isRequired,
  product_code: propTypes.string.isRequired,
  total_sessions: propTypes.number.isRequired,
  surveys_completed_count: propTypes.number.isRequired,
  survey_questions: propTypes.object.isRequired,
  session_start_time: propTypes.string.isRequired,
  session_end_time: propTypes.string.isRequired,
  session_id: propTypes.number.isRequired,
  num: propTypes.number.isRequired,
};

export default SurveysRow;
