// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ArtifactImage from './../../Common/ArtifactImage';
import ExpandableCapsule from './../../Common/ExpandableCapsule';
import UserPhoto from './../../Common/UserPhoto';

const StrategySection = (props) => {
  let durationNode, authorNode, resourcesNodes, mobileResourceNode;

  const handleOpenResource = (resource) => {
    // open links in a new window
    if (resource.connections.link) {
      window.open(resource.connections.link, '_blank');
      return;
    }

    // open artifact overlay
    props.openResource(resource);
  };

  // if the section includes a duration, display it as # minutes
  if (props.duration) {
    durationNode = (
      <div className="strategy-v4__capsule-time">{props.duration} minutes</div>
    );
  }

  // if the section includes an author, display their info
  if (props.author) {
    authorNode = (
      <div className="strategy-v4__capsule-author">
        <UserPhoto
          src={props.author.photo}
          diameter={50}
          className="strategy-v4__capsule-author-photo"
        />
        <div className="tc__body-copy">
          <div>{props.author.label}</div>
          <div className="strategy-v4__capsule-author-role">
            {props.author.role == null
              ? 'BetterLesson Instructional Coach'
              : props.author.role}
          </div>
        </div>
      </div>
    );
  }

  // if the section includes resources, display those
  let capsuleClass = '';
  if (props.resources && props.resources.length) {
    capsuleClass = 'strategy-v4__capsule--has-resources ';

    // build a list of all the resources
    const resources = props.resources.map((resource) => {
      const icon = resource.connections && resource.connections.icon;
      return (
        <div
          key={'resource-' + resource.id}
          className="strategy-v4__capsule-resource"
          onClick={() => handleOpenResource(resource)}
        >
          <ArtifactImage
            preview={resource.connections && resource.connections.preview}
            icon={icon}
            title={resource.title}
            containerClass="strategy-v4__capsule-resource-image-container"
            imageClass="strategy-v4__capsule-resource-image"
          />

          <div className="strategy-v4__capsule-resource-headline-row tc__body-copy">
            <div
              className={
                'trategy-v4__capsule-resource-icon file-type-icon ext-' + icon
              }
            ></div>
            <div className="tc__link tc__link--black">{resource.title}</div>
          </div>
        </div>
      );
    });

    // on desktop, we want to show up to three resources per row inside the capsule
    resourcesNodes = [];
    for (let i = 0; i < resources.length; i += 3) {
      resourcesNodes.push(
        <div
          key={'section-' + props.index + '-resources-' + i}
          className="strategy-v4__capsule-resources"
        >
          {resources.slice(i, i + 3)}
        </div>
      );
    }

    // on mobile, we show all the resources in one scrollable row
    mobileResourceNode = (
      <div className="strategy-v4__capsule-resources">{resources}</div>
    );
  }

  return (
    <ExpandableCapsule
      id={'section-' + props.index}
      maxHeight={600}
      className={capsuleClass + 'strategy-v4__capsule'}
      stickyFooterNodes={resourcesNodes}
      mobileStickyFooterNode={mobileResourceNode}
      expandFromAnywhere={true}
      updateSectionExpandToggleCount={props.updateSectionExpandToggleCount}
    >
      <div className="strategy-v4__capsule-headline-row">
        <h2 className="strategy-v4__capsule-headline">{props.title}</h2>
        {durationNode}
      </div>
      {authorNode}
      <PurifiedHTML
        className="strategy-v4__capsule-description ckeditor__output"
        content={props.description}
      />
      {props.metadataNode}
    </ExpandableCapsule>
  );
};

StrategySection.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  duration: PropTypes.any,
  author: PropTypes.object,
  resources: PropTypes.array,
  openResource: PropTypes.func,
  metadataNode: PropTypes.element,
};

export default StrategySection;
