// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import NavigationLink from './../../NavigationLink';
import ScaleCenterImage from './../../ScaleCenterImage';

/*
given a circle diameter, builds the V5 round user image -
if there is some color behind the img,
then use hasBorder to add a white border in the mask to make the photo clearer

(if no img, uses the user's initials with a solid color background as a placeholder)
*/
export default function UserPhoto(props) {
  const lab = useLegacyLabContext();
  const pageUser = lab.pageData.pageUser || {};
  const user = lab.pageData.user || {};

  let activeParticipant = null;
  let photoNode = null;

  if (pageUser && pageUser.participants) {
    for (let i = 0; pageUser.participants.length > i; i++) {
      if (!pageUser.participants[i].archived) {
        activeParticipant = pageUser.participants[i];
        break;
      }
    }
  }

  const canEdit =
    Utils.isServicesManager.call(lab, user) ||
    Utils.isAdmin.call(lab, user) ||
    (Utils.isTeacher.call(lab, user) && pageUser.id === user.id) ||
    (activeParticipant &&
      Utils.isContractCoach.call(lab, user) &&
      Utils.isTeachersCoach.call(lab, user, activeParticipant)) ||
    Utils.isManager.call(lab, user);

  // set up the user photo img if they have one
  if (props.src) {
    photoNode = (
      <ScaleCenterImage
        src={props.src}
        maskClassName={props.className}
        className="v5__user-image"
        noAdjustments={true}
        diameter={props.diameter}
        hasBorder={props.hasBorder}
      />
    );
  }
  // if not, then set up default user photo icon
  else {
    const className = props.className ? props.className : '';

    // Use the user's name and user ID so that two users with the same name don't use the same color
    // Then convert each letter to a char code, sum and take the modulus so the same user is always the same color
    const userName = props.firstName + ' ' + props.lastName + props.userId;
    const index = userName
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc);

    const colors = Utils.UI_BASIC_COLORS;

    // set up the user's initials as the placeholder for their photo
    let initials = '';
    if (props.firstName) {
      initials += props.firstName[0];
    }
    if (props.lastName) {
      initials += props.lastName[0];
    }

    photoNode = (
      <div
        className={`bl4__user-image-default-initials ${className}`}
        style={{
          height: props.diameter,
          width: props.diameter,
          backgroundColor: colors[index % colors.length],
          fontSize: props.diameter * 0.4,
          lineHeight: `${props.diameter}px`,
        }}
      >
        {initials}
      </div>
    );
  }

  // only link the photo to the user settings page if the current user can access it
  photoNode =
    props.editable && canEdit ? (
      <NavigationLink
        url={`/account/${props.userId}/profile`}
        className="v5__user-image-container"
      >
        {photoNode}
        <div className="v5__user-image-overlay">Edit</div>
      </NavigationLink>
    ) : (
      photoNode
    );

  return photoNode;
}

UserPhoto.propTypes = {
  src: PropTypes.string,
  userId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  diameter: PropTypes.number.isRequired,
  hasBorder: PropTypes.bool,
  className: PropTypes.string,
  editable: PropTypes.bool,
};
