// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import NavigationLink from '@legacy-components/react/NavigationLink';
import TimeDelta from '@legacy-components/react/V4/Common/TimeDelta';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import EntryContainer from '@legacy-components/react/V5/Highlights/modules/EntryContainer';
import HighlightedRow from '@legacy-components/react/V5/Highlights/modules/HighlightedRow';
import PropTypes from 'prop-types';
import React from 'react';

const HighlightRow = (props) => {
  const lab = useLegacyLabContext();
  let iconClass;
  let title = props.title;

  switch (props.type) {
    case 'participant_goal':
      iconClass = props.completed
        ? 'tc-v5-milestone-complete'
        : 'tc-v5-milestone';
      break;
    case 'custom_strategy':
      iconClass = 'tc-v4-strategy';
      break;
    case 'curated_strategy':
      title = 'Strategy';
      iconClass = 'tc-v4-strategy';
      break;
    case 'lesson':
      title = 'Lesson';
      iconClass = 'tc-v4-ela';
      break;
    case 'file':
      iconClass = 'tc-v4-page';
      break;
    case 'link':
      iconClass = 'tc-v4-link';
      break;
    case 'text':
      iconClass = 'tc-note';
      break;
    case 'meeting_clip':
      iconClass = 'tc-v4-calendar';
      break;
    case 'completed_goal':
      iconClass = 'tc-v5-milestone-complete';
      break;
    case 'assessment_survey':
      iconClass = 'tc-v5-survey-icon';
      break;
  }

  // set up goal expand/collapse arrow
  const arrowClass = props.openRow
    ? 'workspace__goal-expand-arrow--expanded'
    : '';

  const iconNode = (
    <div className="workspace__entry-header-icon">
      <div className={iconClass}>
        <span className="path1"></span>
        <span className="path2"></span>
      </div>
    </div>
  );

  const renderExpandIcon = () => {
    if (!Utils.showPartnerLeaderDetails.call(lab, props.teacher)) {
      return <></>;
    }

    return (
      <div
        className={`tc-v4-circle-arrow workspace__goal-expand-arrow partner_lead__expand-arrow ${arrowClass}`}
        onClick={() => {
          props.updateShow(props.id);
        }}
      ></div>
    );
  };

  const rowHeaderNode = (
    <div className="partner_lead_v5__author-entry highlight">
      <div className="cute-4-desktop partner_lead_v5__highlight-row-teacher">
        <div className="workspace__comment-photo-col partner_lead_v5__author-photo domain">
          <UserPhoto
            src={props.teacher.photo}
            userId={props.teacher.id}
            firstName={props.teacher.first_name}
            lastName={props.teacher.last_name}
            diameter={40}
          />
        </div>
        <div>
          <NavigationLink
            url={`/lab/highlights/${props.teacher.id}?scrollToOutcomes=true`}
            className="v5__link partner_lead_v5__teacher-name"
          >
            {props.teacher.first_name} {props.teacher.last_name}
          </NavigationLink>
          <div className="partner_lead_v5__teacher-name">
            {props.institution.name}
          </div>
        </div>
      </div>
      <div className="cute-6-desktop partner_lead__highlight-row-title">
        {iconNode}
        {title}
      </div>
      <div className="v5__registered-table-highlight-container cute-2-desktop">
        <div className="partner_lead__highlight-row-date">
          <span className="tc-v5-star-solid partner_lead__highlight-row-star"></span>
          <TimeDelta
            datetime={
              props.meeting ? props.meeting.meeting_time : props.highlighted
            }
          />
        </div>
        {renderExpandIcon()}
      </div>
    </div>
  );

  // show goal details if expanded
  const bodyNode = props.openRow ? (
    <React.Fragment>
      <div className="partner_lead_v5__table-thin-spacer"></div>
      <div className="partner_lead__highlight-row-highlight">
        <HighlightedRow
          reflection={props.highlight_reflection}
          author={props.highlight_author}
          title={props.title}
          contentId={props.id}
          teacherTag={false}
          toggle={false}
          greyFade={true}
          reportingPhase={props.reportingPhase}
          showInPLDash={true}
        >
          <EntryContainer
            {...props}
            authorHide={true}
            greyFade={true}
            showInPLDash={true}
          />
        </HighlightedRow>
      </div>
    </React.Fragment>
  ) : null;

  return (
    <div className="partner_lead__learning_domain_row_container highlights">
      <div className="partner_lead__learning_domain_row">
        <div className="partner_lead__domain-row-header">{rowHeaderNode}</div>
      </div>
      {bodyNode}
    </div>
  );
};

const ConnectedHighlightRow = (props) => (
  <HeaderConsumer>
    {({ user }) => <HighlightRow {...props} user={user} />}
  </HeaderConsumer>
);

HighlightRow.propTypes = {
  author: PropTypes.object,
  author_user_id: PropTypes.number,
  created: PropTypes.string,
  created_formatted: PropTypes.string,
  creator_user_id: PropTypes.number,
  editor_user_id: PropTypes.number,
  hidden_from_participant: PropTypes.number,
  highlight_author: PropTypes.object,
  highlight_author_user_id: PropTypes.number,
  highlight_reflection: PropTypes.string,
  highlighted: PropTypes.string,
  id: PropTypes.number,
  institution: PropTypes.object,
  lastmodified: PropTypes.string,
  lastmodified_formatted: PropTypes.string,
  meeting: PropTypes.object,
  private: PropTypes.number,
  reporting_phase: PropTypes.number,
  story_id: PropTypes.number,
  story_meeting_note_id: PropTypes.number,
  tag_id: PropTypes.number,
  teacher: PropTypes.object,
  type: PropTypes.string,
  type_id: PropTypes.number,
  reportingPhase: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
  school_year: PropTypes.string,
};

export default ConnectedHighlightRow;
