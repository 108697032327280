// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import Utils from './../../../../components/DynamicUtils';
import AbsoluteAlert from './../../../Alerts/Absolute';
import CheckArrows from './../modules/CheckArrows';
import PortfolioEntryReorder from './../modules/PortfolioEntryReorder';
import Subheader from './../modules/PortfolioManagementSubheader';
import ReorderButtons from './../modules/ReorderButtons';
import EntryCapsule from './modules/EntryCapsule';

const PortfolioOutcomeStoryManagement = (props) => {
  const outcome = props.outcome;
  const outcomeStoryEntries = props.displayOrder;

  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // show the Reorder button if there is more than one entry
  let buttonNode = null;
  if (outcomeStoryEntries && outcomeStoryEntries.length > 1) {
    buttonNode = (
      <div className="row">
        <div className="p-portfolio-management-outcome-story__reorder cute-2-desktop cute-3-desktop-offset left phone-reset">
          <ReorderButtons {...props} />
        </div>
      </div>
    );
  }

  // show the list of outcome story entry capsules
  let entryNodes = null;
  if (outcomeStoryEntries && outcomeStoryEntries.length) {
    entryNodes = outcomeStoryEntries.map((entry, i) => {
      let opacity = '';
      // if an entry is not visible, give it opacity to show visually hidden
      if (props.hiddenEntryIds.indexOf(entry.id) !== -1) {
        opacity = 'p-portfolio-outcome-story-management__opacity';
      }
      return (
        <div className="row" key={'entry-' + entry.id}>
          <PortfolioEntryReorder
            page="outcomeStory"
            entryId={entry.id}
            containerId={entry.id + '-' + i}
            move={props.ui.move}
            completeMove={props.completeMove}
            animateElemPositions={props.animateElemPositions}
          >
            <div className={opacity}>
              <EntryCapsule
                key={'entry-' + entry.id}
                {...entry}
                move={props.ui.move}
                user={props.user}
                currentCoach={props.participant.coach}
                baselineMeasurement={
                  props.participant_outcome.baselineMeasurement
                }
                participantId={props.participant_outcome.participant_id}
                outcomeId={props.participant_outcome.outcome_id}
                pins={props.pins}
              >
                <div className="p-portfolio-management-vision__artifact-check">
                  <CheckArrows
                    {...props}
                    entryId={entry.id}
                    disableUp={i == 0 || !Utils.isEmpty(props.ui.move)}
                    disableDown={
                      i == outcomeStoryEntries.length - 1 ||
                      !Utils.isEmpty(props.ui.move)
                    }
                  />
                </div>
              </EntryCapsule>
            </div>
          </PortfolioEntryReorder>
        </div>
      );
    });
  }

  return (
    <div className="p-portfolio-outcome-story-management">
      <Header page="ParticipantPortfolioOutcomeStoryManagement" {...props} />
      <Subheader
        participantId={props.participant.id}
        participantName={
          props.participant.user.first_name +
          ' ' +
          props.participant.user.last_name
        }
        portfolioUuid={props.portfolio.uuid}
        shareModal={props.ui.shareModal}
        setUrlCopied={props.setShareUrlCopied}
      />
      {alertNode}

      <div className="row">
        <div className="cute-8-desktop cute-2-desktop-offset center-desktop cute-12-phone p-portfolio-outcome-story-management__outcome">
          <div
            className={
              'p-portfolio-management__outcome-icon tc-cd-domain-' +
              outcome.domainIconId
            }
          ></div>
          <div className="p-portfolio-outcome-story-management__title left">
            {outcome.outcomeTitle}
          </div>
        </div>
      </div>

      {buttonNode}
      {entryNodes}
    </div>
  );
};

PortfolioOutcomeStoryManagement.propTypes = {
  outcome: PropTypes.object.isRequired,
  displayOrder: PropTypes.array.isRequired,
  hiddenEntryIds: PropTypes.array.isRequired,
};

export default PortfolioOutcomeStoryManagement;
