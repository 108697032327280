// @ts-nocheck FIXME
import HomeApi from '@legacy-components/apis/V5/HomeApi';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import NavigationLink from '@legacy-components/react/NavigationLink';
import HelpDeskLinkGateway from '@legacy-components/react/V5/Common/HelpDeskLinkGateway';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import React, { useState } from 'react';
import CoachInfo from './modules/CoachInfo';
import UserInfo from './modules/UserInfo';

// const ActivitiesCapsule = require('./modules/ActivitiesCapsule.jsx');
import MeetingsCapsule from './modules/MeetingsCapsule';

import BLConnectSection from './modules/BLConnectSection';
import CallsToAction from './modules/CallsToAction';
import Favorites from './modules/Favorites';
import TrendingLessonsAndStrategies from './modules/TrendingLessonsAndStrategies';

const UserHome = (props) => {
  const [favoritesList, updateFavorites] = useState(props.favorites);
  const [uiAlert, setUiAlert] = useState({});
  const [isExpandCoach, expandCoach] = useState(false);
  const [isExpandResources, expandResources] = useState(false);

  let alertNode;
  if (Object.keys(uiAlert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...uiAlert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const getNewFavorites = () => {
    HomeApi.getFavorites({ userId: props.pageUser.id }).then((response) => {
      updateFavorites(response.favorites);
    });
  };

  let coachNode;
  if (props.is_being_coached) {
    coachNode = (
      <CoachInfo
        coach={props.coach}
        institution={props.participant.institution}
        engagement={props.participant.coaching_engagement}
        completed_meeting_count={props.completed_meeting_count}
        setUiAlert={setUiAlert}
      />
    );
  }

  // TODO: reintroduce this once Product decides what to do with it
  // let userTimeZone = 'US/Eastern';
  // if (props.user.time_zone) {
  //     userTimeZone = props.user.time_zone.pytz_timezone;
  // }
  // only show the Recent Activity section if the page participant is being coached
  // const recentActivityNode = props.is_being_coached && (
  //     <div className="home-v5__activity-capsule">
  //         <div className="v5__heading-5 home-v5__header">
  //             Recent Activity
  //             <span className='v5__comment-copy'>
  //                 Past 30 days
  //             </span>
  //         </div>
  //         <div className="v5__border-gradient"></div>
  //         <ActivitiesCapsule
  //             {...props}
  //             userTimeZone={userTimeZone} />
  //     </div>
  // );

  let timeZoneNode = null;
  if (props.user.time_zone) {
    timeZoneNode = (
      <div className="v5__body-copy home-v5__meetings-timezone">
        <span className="v5__body-copy grey-out">Times displayed in </span>
        <strong>{props.user.time_zone.name}</strong>.{' '}
        <NavigationLink
          className="v5__link"
          url="/account?from=lab_home_timezone"
        >
          Change your time zone
        </NavigationLink>
      </div>
    );
  }

  const resourcesCapsule = props.search_failed ? (
    <div className="home-v5__empty-activity-capsule">
      <div>
        <img
          src="/legacy-images/404-pets.png"
          className="code-404__img-activity"
        />
      </div>
      <div className="home-v5__error-activity-row">
        <div className="v5__heading-4">Oops! Something is broken.</div>
        <div className="v5__comment-copy">
          <div>
            Your resources are temporarily unavailable. (We blame the cat.)
          </div>
          <div>Thanks for your patience as we tidy up this issue.</div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <Favorites favorites={favoritesList} pageUser={props.pageUser} />
      <TrendingLessonsAndStrategies
        results={props.results}
        pageUser={props.pageUser}
        user={props.user}
        getNewFavorites={getNewFavorites}
      />
    </React.Fragment>
  );
  const coachArrowClass = isExpandCoach
    ? 'workspace__goal-expand-arrow--expanded'
    : '';
  const coachMobileNode = isExpandCoach && coachNode;

  const resourceArrowClass = isExpandResources
    ? 'workspace__goal-expand-arrow--expanded'
    : '';
  const resourceMobileNode = isExpandResources && resourcesCapsule;

  const zoomLink =
    props.participant && props.participant.zoom_link
      ? props.participant.zoom_link
      : null;

  return (
    <div>
      {alertNode}
      <Header page="UserHome" {...props} />
      <div className="home-v5__container">
        <div className="row row--1200">
          <div className="cute-3-desktop cute-12-phone">
            <UserInfo user={props.pageUser} participant={props.participant} />
            <div className="hide-phone show-desktop">
              {coachNode}
              <HelpDeskLinkGateway
                forEvent={false}
                headline="We’re here to help!"
                className="event-help__attendee-block"
              />
            </div>
          </div>
          <div className="cute-9-desktop cute-12-phone">
            <CallsToAction
              pageUser={props.pageUser}
              upcomingMeetings={props.upcoming_meetings.filter(
                (meeting) => meeting.meeting_time_formatted
              )}
              upcomingEvents={props.upcoming_meetings.filter(
                (meeting) => meeting.session
              )}
              checkedInSessions={props.checked_in_session_ids}
              pastEvents={props.past_meetings}
              hasCompletedMeeting={props.completed_meeting_count > 0}
              zoomLink={zoomLink}
              coach={props.coach}
              participant={props.participant}
              user_service_auth={props.user_service_auth}
            />

            {props.user_service_auth &&
            props.user_service_auth.has_bl_connect ? (
              <BLConnectSection />
            ) : null}

            <div className="row home-v5__meetings-capsule">
              <div className="v5__heading-5 home-v5__header">
                Meetings &amp; Events
              </div>
              <div className="v5__border-gradient"></div>
              {timeZoneNode}
              <MeetingsCapsule {...props} />
            </div>
            <div className="home-v5__capsule hide-phone">
              <div className="v5__heading-5 home-v5__header">Resources</div>
              <div className="v5__border-gradient"></div>
              {resourcesCapsule}
            </div>
          </div>
          <div className="hide-desktop show-phone cute-12-phone">
            <div
              className="workspace__goal-header home-v5__header"
              onClick={() => expandCoach(!isExpandCoach)}
            >
              <h3 className={'v5__heading-5'}>Coaching</h3>
              <div
                className={`tc-v4-circle-arrow workspace__goal-expand-arrow ${coachArrowClass}`}
              ></div>
            </div>
            <div className="v5__border-gradient"></div>
            {coachMobileNode}

            <div
              className="workspace__goal-header home-v5__header home-v5__mobile-spacing"
              onClick={() => expandResources(!isExpandResources)}
            >
              <h3 className={'v5__heading-5'}>Resources</h3>
              <div
                className={`tc-v4-circle-arrow workspace__goal-expand-arrow ${resourceArrowClass}`}
              />
            </div>
            <div className="v5__border-gradient"></div>
            <div className="home-v5__capsule">{resourceMobileNode}</div>
            <div className="home-v5__mobile-spacing" />

            <HelpDeskLinkGateway
              forEvent={false}
              headline="We’re here to help!"
              className="event-help__attendee-block"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHome;
