// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import FreezeWindowScroll from './FreezeWindowScroll';

const ActionModal = (props) => {
  const spaceFromTop =
    props.spaceFromTop || props.spaceFromTop === 0 ? props.spaceFromTop : 100;

  return (
    <div>
      <div className="v5__modal-page-overlay" onClick={props.close} />

      <div
        style={{ top: spaceFromTop }}
        className="v5__modal v5__modal--action"
      >
        <div className="v5__modal-header">
          <div className="v5-modal__action-headline-text">
            {props.headlineText}
          </div>
          <div
            data-testid="action-modal-close"
            className="tc-x v5__modal-close-icon"
            onClick={props.close}
          ></div>
        </div>

        <div className="v5__modal-body">{props.children}</div>
      </div>

      <FreezeWindowScroll
        parentModal="v5__modal"
        spaceFromTop={spaceFromTop}
        updateFlag={props.updateFlag ? props.updateFlag : undefined}
      />
    </div>
  );
};

ActionModal.propTypes = {
  updateFlag: PropTypes.any,
  headlineText: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  spaceFromTop: PropTypes.number,
};

export default ActionModal;
