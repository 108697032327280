// @ts-nocheck FIXME
import moment from 'moment';
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../../components/ArrayHelpers';
import Utils from './../../../../components/DynamicUtils';
import AbridgeText from './../../Common/AbridgeText';
import EvidenceSlide from './PortfolioStoryHighlights/EvidenceSlide';
import ReflectionSlide from './PortfolioStoryHighlights/ReflectionSlide';
import CapsuleSection from './PortfolioStoryHighlights/Section';
import StrategyCapsule from './PortfolioStoryHighlights/StrategyCapsule';

const PortfolioStoryCapsuleHighlights = (props) => {
  // open the artifact overlay when clicking on the starting reflection
  const openArtifactModal = () => {
    const data = Utils.clone(props.startingStatement);
    data.storyId = props.id;
    data.entryType = 'starting_statement';
    data.connections = {
      preview_type: 'ckeditor_output',
    };
    data.analyticsCategory = 'starting_reflection_modal';

    props.portfolioArtifactModal.open(data);
  };

  // show the starting reflection section if the story has one
  let startingReflectionNode;
  if (props.startingStatement && props.startingStatement.description) {
    startingReflectionNode = (
      <div className="bl4-profile__story-highlights-section">
        <div className="bl4-profile__story-highlights-section-icon tc-v4-reflection-lg"></div>
        <div className="bl4-profile__story-highlights-section-title">
          Starting Reflection
        </div>
        <div className="bl4-profile__story-highlights-section-carousel">
          <div
            className="bl4-profile__story-highlights-starting-statement"
            onClick={openArtifactModal}
          >
            <AbridgeText
              maxHeight={220}
              setInnerHtml={true}
              className="ckeditor__output bl4-profile__story-highlights-section-text"
            >
              {props.startingStatement.description}
            </AbridgeText>
            <div className="bl4-profile__story-highlights-section-date">
              {moment(props.startingStatement.created_formatted).format(
                'MMMM D, YYYY'
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // show the strategies section if the story has curated strategies
  let strategiesNode;
  if (ArrayHelpers.hasElements(props.strategies)) {
    strategiesNode = (
      <CapsuleSection
        id={'story-' + props.id + '-strategies'}
        iconClass="tc-v4-strategy-lg"
        title="Strategies Tried"
        analyticsCategory="strategy_carousel"
      >
        <div className="bl4-profile__story-highlights-strategies">
          {props.strategies.map((strategy, i) => {
            return (
              <StrategyCapsule
                key={'strategy-' + strategy.id}
                {...strategy}
                isLast={i == props.strategies.length - 1}
              />
            );
          })}
        </div>
      </CapsuleSection>
    );
  }

  // show the evidence section if the story has entries tagged as evidence
  let evidenceNode;
  if (ArrayHelpers.hasElements(props.evidence)) {
    evidenceNode = (
      <CapsuleSection
        iconClass="tc-v4-leaf-lg"
        title="Evidence of Growth"
        hasDiscreteSections={true}
        analyticsCategory="evidence_carousel"
      >
        <div className="bl4-profile__story-highlights-reflections">
          {props.evidence.map((evidence) => {
            return (
              <EvidenceSlide
                key={'evidence-' + evidence.id}
                {...evidence}
                storyId={props.id}
                portfolioArtifactModal={props.portfolioArtifactModal}
              />
            );
          })}
        </div>
      </CapsuleSection>
    );
  }

  // show the reflections section if the story has entries tagged as a reflection
  let reflectionsNode;
  if (ArrayHelpers.hasElements(props.reflections)) {
    reflectionsNode = (
      <CapsuleSection
        iconClass="tc-v4-reflection-lg"
        title="Reflections"
        hasDiscreteSections={true}
        analyticsCategory="reflection_carousel"
      >
        <div className="bl4-profile__story-highlights-reflections">
          {props.reflections.map((reflection) => {
            return (
              <ReflectionSlide
                key={'reflection-' + reflection.id}
                {...reflection}
                storyId={props.id}
                portfolioArtifactModal={props.portfolioArtifactModal}
              />
            );
          })}
        </div>
      </CapsuleSection>
    );
  }

  return (
    <div>
      {startingReflectionNode}
      {strategiesNode}
      {evidenceNode}
      {reflectionsNode}
    </div>
  );
};

PortfolioStoryCapsuleHighlights.propTypes = {
  id: PropTypes.number.isRequired,
  focus_area: PropTypes.object.isRequired,
  portfolioArtifactModal: PropTypes.object.isRequired,
};

export default PortfolioStoryCapsuleHighlights;
