// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import Modal from './../../Common/Modal';
import Spinner from './../../Common/Spinner';

const MeetingDeleteModal = (props) => {
  const [isLoading, makeLoad] = useState(false);

  const handleDelete = (e) => {
    makeLoad(true);
    props.delete(e);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  // disable buttons when submitting
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <Modal close={handleCloseModal}>
      <div className="v5__heading-3 workspace__goal-modal-header-row">
        Delete Meeting on {props.meetingDate}
      </div>

      <div className="v5__heading-5 workspace__form-row">
        It will be permanently deleted, along with all meeting notes and clips.
      </div>

      <div className="workspace__form-row">
        <button
          className="v5__btn v5__btn--red"
          onClick={handleDelete}
          {...btnProps}
          data-testid="action-modal-close"
        >
          <Spinner loading={isLoading} />
          Delete
        </button>
      </div>

      <div className="workspace__form-row">
        <button
          className="v5__link v5__link--grey"
          {...btnProps}
          onClick={handleCloseModal}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

MeetingDeleteModal.propTypes = {
  delete: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default MeetingDeleteModal;
