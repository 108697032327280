// @ts-nocheck FIXME
import React from 'react';
import Utils from './../../../components/DynamicUtils';

class FreezeWindowScroll extends React.Component {
  state = {
    initalLoad: true,
  };

  mq = null;
  scrollPosition = 0;
  parentModal = null;
  parentPage = null;

  componentDidUpdate() {
    this.resizeBodyHeightForModal();
  }

  componentDidMount() {
    // only call match media on browser
    this.mq = window.matchMedia('(max-width: 48em)');

    if (this.props.parentModal) {
      this.parentModal = document.querySelector('.' + this.props.parentModal);
      this.parentPage = document.querySelector('.tc__page');
    }
    this.resizeBodyHeightForModal();
  }

  componentWillUnmount() {
    Utils.removeClass(document.body, 'no-scroll');
    if (this.parentPage) {
      this.parentPage.removeAttribute('style');
    }
  }

  resizeBodyHeightForModal = () => {
    // if we only want functionality for mobile do nothing qs
    if (this.props.mobileOnly && !this.mq.matches) {
      return;
    }
    // if overlay is open and too tall for the screen,
    // add a height and overflow to the overlays parent
    if (
      this.parentModal &&
      this.parentPage &&
      this.parentModal.offsetHeight + 30 > window.innerHeight
    ) {
      Utils.removeClass(document.body, 'no-scroll');
      this.parentPage.setAttribute(
        'style',
        'overflow: hidden; height: ' +
          (this.parentModal.offsetHeight + 30) +
          'px;'
      );
    } else {
      Utils.addClass(document.body, 'no-scroll');
    }

    if (!this.state.initalLoad) {
      return;
    }

    window.setTimeout(() => {
      window.scrollTo(0, 0);
      this.setState({
        initalLoad: false,
      });
    }, 10);
  };

  render() {
    return <noscript />;
  }
}

export default FreezeWindowScroll;
