// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import CKEditor from '@legacy-components/react/V5/Common/CKEditor';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const NextSteps = (props) => {
  // only the page participant can edit the Next Steps
  const currentUserIsPageParticipant =
    props.participant && props.user.id === props.participant.user_id;

  // only start in edit mode if there is no Next Steps text and the current user is the page participant
  const [editMode, setEditMode] = useState(
    currentUserIsPageParticipant && !props.workspaceEntry.next_steps
  );
  const [loading, setLoading] = useState(false);

  // hide this component if this is the preview version of the page,
  // or if there is no Next Steps text and the current user is not the page participant
  if (
    !props.participant ||
    (!props.workspaceEntry.next_steps && !currentUserIsPageParticipant)
  ) {
    return null;
  }

  const handleSaveNextSteps = () => {
    setLoading(true);

    const data = props.workspaceEntry;
    data.next_steps =
      CKEDITOR.instances[
        'event__attendee-next-steps-wysiwyg-next-steps-0'
      ].getData();

    data.successCallback = () => {
      setEditMode(false);
      setLoading(false);
    };
    data.errorCallback = () => {
      setLoading(false);
    };
    props.submitNextSteps(data);
  };

  // either display the Next Steps text or the WYSIWYG editor
  let textNode = (
    <PurifiedHTML
      className="event__attendee-next-steps-text ckeditor__output"
      content={props.workspaceEntry.next_steps}
    />
  );
  // only the page participant can edit the Next Steps
  let btnsNode = (
    <div className="event__attendee-next-steps-btns">
      {currentUserIsPageParticipant && (
        <button
          className="v5__btn event__attendee-next-steps-btn"
          onClick={() => setEditMode(true)}
        >
          Edit Next Steps
        </button>
      )}
      {props.workspaceEntry.next_steps && (
        <a
          href={`/lab/workspace/${props.participant.user_id}?scrollToEntryId=${props.workspaceEntry.entry_id}`}
          target="_blank"
          className="v5__btn v5__btn--blue-border"
          rel="noreferrer"
        >
          View on Workspace
        </a>
      )}
    </div>
  );
  if (editMode) {
    textNode = (
      <div className="event__attendee-next-steps-text">
        <CKEditor
          id="next-steps"
          increment={0}
          innerText={props.workspaceEntry.next_steps}
          className="event__attendee-next-steps-wysiwyg"
          configs="/legacy-libs/configs/bl-cke-config-cohort.js"
        />
      </div>
    );
    const btnProps = loading ? { disabled: true } : {};
    btnsNode = (
      <div className="event__attendee-next-steps-btns">
        <button
          className="v5__btn event__attendee-next-steps-btn"
          onClick={handleSaveNextSteps}
          {...btnProps}
        >
          <Spinner loading={loading} />
          Save
        </button>
        <button
          className="v5__btn v5__btn--blue-border"
          onClick={() => setEditMode(false)}
        >
          Cancel
        </button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <h4 className="event__attendee-section-headline v5__heading-3">
        Next Steps
      </h4>
      <div className="v5__body-copy event__attendee-next-steps-description">
        Use this space to document your next steps for turning your plan into
        action. Your notes will be automatically published to your workspace and
        may be shared with your administrator.
      </div>
      {textNode}
      {btnsNode}
    </React.Fragment>
  );
};

NextSteps.propTypes = {
  workspaceEntry: PropTypes.object.isRequired,
  participant: PropTypes.object,
  user: PropTypes.object.isRequired,
  submitNextSteps: PropTypes.func,
};

export default NextSteps;
