// @ts-nocheck FIXME
import { useState } from 'react';

import WorkspaceApi from '@legacy-components/apis/V5/WorkspaceApi';

import Spinner from './../../../../../Common/Spinner';
import EntryTags from './../EntryTags';

const SelfAssessmentEntryForm = (props) => {
  const [currentGoals, updateCurrentGoals] = useState(props.currentGoals || []);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    // build API data
    const data = {
      storyId: props.activeStoryId,
      goals: currentGoals,
      assessmentSurveyEntryId: props.assessmentSurveyEntryId,
    };

    WorkspaceApi.editSelfAssessmentEntry(data)
      .then((response) => {
        props.editEntryCallback(response.entry);
        props.close();
      })
      .catch(() => {
        props.setErrorAlert();
        setLoading(false);
      });
  };

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <div>
      {props.children}

      <div className="workspace__entry-body">
        <div className="workspace__entry-footer">
          <EntryTags
            goals={props.goals}
            selectedGoalIds={currentGoals}
            updateTempGoalIds={updateCurrentGoals}
            canEdit={true}
          />

          <div className="workspace__btns-row">
            <button
              type="button"
              className="v5__btn v5__btn--blue-border workspace__cancel-link"
              onClick={() => props.close()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="v5__btn"
              {...btnProps}
              onClick={handleSubmit}
            >
              <Spinner loading={isLoading} />
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfAssessmentEntryForm;
