// @ts-nocheck FIXME
const MobilePartnerHighlights = (props) => {
  let sortSelect;

  const handleSortChange = (e) => {
    e.preventDefault();
    props.sortBy(e.target.value);
  };

  const partners = props.partners.map((partner) => {
    let growthNode = (
      <div className="m-coach-participant__row">No measurements</div>
    );
    if (partner.growth > 0) {
      growthNode = (
        <div className="m-coach-participant__row">
          <span className="partner_highlights_growth_18">
            {Math.round(partner.growth * 100.0) / 100.0}
          </span>
          <span className="partner_highlights_growth_18">x growth</span>
        </div>
      );
    } else if (partner.starting_point) {
      growthNode = (
        <div className="m-coach-participant__row">
          {partner.starting_point.name}
        </div>
      );
    }
    return (
      <div
        className="m-coach-participant ppd-3-coach"
        key={'coach-participant-' + partner.id}
      >
        <div>
          <div className="m-coach-participant__name">
            <div className="ppd-3-coach-table__user-name">{partner.name}</div>
          </div>
        </div>
        <div className="m-coach-participant__row">
          {partner.cohort_count} Cohort{partner.cohort_count == 1 ? '' : 's'}
        </div>
        <div className="m-coach-participant__row">
          {partner.participant_count} Participant
          {partner.participant_count == 1 ? '' : 's'}
        </div>
        <div className="m-coach-participant__row">{growthNode}</div>
        <div className="m-coach-participant__row">
          {partner.artifact_count} Artifact
          {partner.artifact_count == 1 ? '' : 's'}
        </div>
        <div className="m-coach-participant__row">
          {partner.meeting_count} Meeting{partner.meeting_count == 1 ? '' : 's'}
        </div>
        <div className="m-coach-participant__row">
          {partner.lead_count} Partner Leader
          {partner.lead_count == 1 ? '' : 's'}
        </div>
      </div>
    );
  });

  return (
    <div className="m-coach-dashboard">
      <h1 className="m-coach-dashboard__headline ppd-3-coach__m-headline tc__headline-1">
        Partner Highlights
      </h1>
      <div className="m-coach-participant__sort m-coach-participant">
        <label>Sort:</label>
        <select
          ref={(ref) => (sortSelect = ref)}
          onChange={handleSortChange}
          defaultValue={props.ui.sortColumn}
        >
          <option value="partner">Partner</option>
          <option value="participants">Participants</option>
          <option value="growth">Growth</option>
          <option value="meetings">Meetings</option>
          <option value="artifacts">Artifacts</option>
          <option value="partnerLeaders">Partner Leaders</option>
        </select>
      </div>
      <div className="coach__participants">{partners}</div>
    </div>
  );
};

export default MobilePartnerHighlights;
