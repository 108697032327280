// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const TeacherRow = (props) => {
  // if this teacher is not visible, show nothing
  if (!props.visible) {
    return <noscript />;
  }

  const selectTeacher = (e) => {
    e.preventDefault();
    props.changeDisplayedTeacher({ teacherId: props.id });
  };

  const showNote = (e) => {
    e.preventDefault();
    props.showTeacherNote({ name: props.data.name, note: props.note });
  };

  // if there is a selected teacher, show her playlist
  let playlistNode = null;
  let activeClass = '';
  if (props.displayedTeacher == props.id) {
    const playlistSections = [];
    activeClass = ' active';
    for (const type in props.data.playlist) {
      // if the section has no loops, skip it
      if (props.data.playlist[type].length === 0) {
        continue;
      }

      // build the section of the playlist for this loop type
      let headerText = '';
      const plural =
        props.data.playlist[type].length > 1 ? 'Strategies' : 'Strategy';
      if (type === 'tried') {
        headerText = 'Tried ' + plural;
      } else {
        headerText = plural + ' in progress';
      }
      // build the list of loops in this section
      const loopNodes = props.data.playlist[type].map((strategy, i) => {
        let artifactNode = null;
        let challengeStyle = null;
        if (strategy.artifactCount != 0) {
          const plural = strategy.artifactCount > 1 ? 'Artifacts' : 'Artifact';
          artifactNode = (
            <div className="ppp__teacher-cohort-playlist-artifact">
              {strategy.artifactCount + ' ' + plural} Uploaded
            </div>
          );
          challengeStyle = 'artifact';
        }
        return (
          <div
            className="ppp__teacher-cohort-teacher-row"
            key={props.id + String(type) + i}
          >
            <div className="cute-7-desktop ppp__teacher-cohort-playlist-strategy">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="tc__link"
                href={
                  '/coaching/lab/strategy/' +
                  strategy.strategyId +
                  '?from=pp_teacher_cohort_consumer_view'
                }
              >
                {strategy.strategyName}
              </a>
              {artifactNode}
            </div>
            <div
              className={
                'cute-5-desktop ppp__teacher-cohort-playlist-challenge ' +
                challengeStyle
              }
            >
              {strategy.teachingChallenge}
            </div>
          </div>
        );
      });

      const playlistSectionNode = (
        <div
          className="ppp__teacher-cohort-playlist-header-row"
          key={'teacher-' + props.id + type}
        >
          <div className="ppp__teacher-cohort-playlist-header">
            {headerText}
          </div>
          <div className="ppp__teacher-cohort-playlist-challenge-header">
            Teaching Challenge
          </div>
          {loopNodes}
        </div>
      );

      playlistSections.push(playlistSectionNode);
    }

    playlistNode = (
      <div key={props.id + '-Playlist'}>{playlistSections.reverse()}</div>
    );
  }

  let noteIconNode = null;
  if (props.note) {
    noteIconNode = (
      <span
        onClick={showNote}
        className="tc-note ppp__teacher-cohort-note-icon"
      ></span>
    );
  }

  let meetingCount = 0;
  let plural = 'Meetings';
  if (props.data.coachMeetings != 0) {
    plural = props.data.coachMeetings == 1 ? 'Meeting' : 'Meetings';
    meetingCount = props.data.coachMeetings;
  }
  meetingCount = (
    <span className="ppp__teacher-cohort-strategy-counts">
      {meetingCount} {plural}
    </span>
  );

  let completedCount = null;
  if (props.data.playlist.tried.length != 0) {
    plural = props.data.playlist.tried.length == 1 ? 'Strategy' : 'Strategies';
    completedCount = (
      <span className="ppp__teacher-cohort-strategy-counts">
        {props.data.playlist.tried.length} {plural} completed
      </span>
    );
  }

  let inProgressCount = null;
  if (props.data.playlist.inProgress.length != 0) {
    plural =
      props.data.playlist.inProgress.length == 1 ? 'Strategy' : 'Strategies';
    inProgressCount = (
      <span className="ppp__teacher-cohort-strategy-counts">
        {props.data.playlist.inProgress.length} {plural} in progress
      </span>
    );
  }

  let artifactCount = null;
  if (props.data.artifactCount != 0) {
    plural = props.data.artifactCount == 1 ? 'Artifact' : 'Artifacts';
    artifactCount = (
      <span className="ppp__teacher-cohort-strategy-counts">
        {props.data.artifactCount} {plural}
      </span>
    );
  }
  let teacherNameNode = (
    <span className="ppp__teacher-cohort-name no-strategies">
      {props.data.name}
    </span>
  );
  if (
    props.data.playlist.inProgress.length + props.data.playlist.tried.length >
    0
  ) {
    teacherNameNode = (
      <span
        onClick={selectTeacher}
        className={'ppp__teacher-cohort-name' + activeClass}
      >
        {props.data.name}
      </span>
    );
  }
  return (
    <div
      className="ppp__teacher-cohort-teacher row"
      id={'ppp__teacher-cohort-teacher--' + props.id}
    >
      <div
        className={
          'cute-10-desktop ppp__teacher-cohort-container cute-1-desktop-offset left' +
          activeClass
        }
      >
        <div>
          <span className="ppp__teacher-cohort-row cute-4-desktop">
            {teacherNameNode}
            {noteIconNode}
          </span>
          <span className="cute-8-desktop">
            {meetingCount}
            {completedCount}
            {inProgressCount}
            {artifactCount}
          </span>
        </div>
        <div>{playlistNode}</div>
      </div>
    </div>
  );
};

TeacherRow.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object,
  displayedTeacher: PropTypes.number,
};

export default TeacherRow;
