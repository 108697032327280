// @ts-nocheck FIXME
import Request from './../components/Request';

const ParticipantPortfolioApi = {
  setParticipantOutcomeVisibility: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/portfolio/participant-outcome/' +
        data.participantOutcomeId +
        '/set-visibility',
      JSON.stringify(data)
    );
  },

  setParticipantVisionVisibility: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/portfolio/' +
        data.portfolioId +
        '/set-vision-visibility',
      JSON.stringify(data)
    );
  },

  setParticipantDesignSummaryVisibility: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/portfolio/' +
        data.portfolioId +
        '/set-ds-visibility',
      JSON.stringify(data)
    );
  },

  setParticipantEntryVisibility: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/portfolio/portfolio-entry/' +
        data.entryId +
        '/set-visibility',
      JSON.stringify(data)
    );
  },

  setDisplayOrder: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/portfolio/' +
        data.portfolioId +
        '/set-display-order',
      JSON.stringify(data)
    );
  },
};

export default ParticipantPortfolioApi;
