// @ts-nocheck FIXME
import RowHideNode from '@legacy-components/react/V5/Common/ExpandedRowHide';
import ToolTip from '@legacy-components/react/V5/Common/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

const EventSessionRow = (props) => {
  const [expandRow, setExpandRow] = React.useState('');
  const [expandedIndex, setExpandedIndex] = React.useState(0);
  const checkIns = (props.checkInCount || []).join(', ');
  let expandedNode;
  const hideRow = () => {
    setExpandRow('');
    setExpandedIndex(0);
  };
  const hideNode = !!expandRow.length && <RowHideNode hideRow={hideRow} />;

  const visibileWidth = 5;
  // The expanded row is a carousel that shows each group as a column with each row
  // containing info for a single participant, either when they registered
  // or when they completed a survey
  if (expandRow && expandRow.length) {
    //Number of groups and navigation arrows arrows are shared between different rows
    const visiibleGroups = Object.keys(props.sessionParticipantGroupMap).slice(
      expandedIndex,
      expandedIndex + visibileWidth
    );
    const rightArrow = expandedIndex + visibileWidth <
      Object.keys(props.sessionParticipantGroupMap).length && (
      <div
        className="event__session-arrow-container event__session-arrow-container--right clickable"
        onClick={() => setExpandedIndex(expandedIndex + 1)}
      >
        <span className="tc-bold-arrow event__session-arrow event__session-arrow--right" />
      </div>
    );
    const leftArrow = expandedIndex > 0 && (
      <div
        className="event__session-arrow-container clickable"
        onClick={() => setExpandedIndex(expandedIndex - 1)}
      >
        <span className="tc-bold-arrow event__session-arrow" />
      </div>
    );

    if (expandRow === 'register') {
      expandedNode = (
        <div className="grid-big__row event__expanded-row-groups">
          {visiibleGroups.map((groupName) => (
            <div
              className="grid-ten__col-2 event__expanded-group"
              key={groupName}
            >
              <div className="event__expanded-group-name-container">
                <span className="event__expanded-group-name">{groupName}</span>{' '}
                <span>
                  ({props.sessionParticipantGroupMap[groupName].length})
                </span>
              </div>
              {props.sessionParticipantGroupMap[groupName].map(
                (participant) => (
                  <div
                    key={participant.name}
                    className="event__expanded-group-participant"
                  >
                    <div className="event__expanded-group-participant-name">
                      {participant.name}
                    </div>
                    <div className="event__expanded-group-participant-time">
                      {participant.registrationTimeFormatted}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
          {leftArrow}
          {rightArrow}
        </div>
      );
    } else if (expandRow === 'surveys') {
      expandedNode = (
        <div className="grid-big__row event__expanded-row-surveys">
          {visiibleGroups.map((groupName) => (
            <div
              className="grid-ten__col-2 event__expanded-group"
              key={groupName}
            >
              <div className="event__expanded-group-name-container">
                <span className="event__expanded-group-name">{groupName}</span>
              </div>
              {props.surveyResults[groupName].map((participant) => (
                <div
                  key={participant.participant_name}
                  className="event__expanded-group-participant"
                >
                  <div className="event__expanded-group-participant-name">
                    {participant.participant_name}
                  </div>
                  <div className="event__expanded-group-participant-time">
                    {participant.surveyCompletedTime}
                  </div>
                </div>
              ))}
            </div>
          ))}
          {leftArrow}
          {rightArrow}
        </div>
      );
    }
  }

  let sessionTimeNode;

  // If an event is an advanced event then multiple sessions on the same day can be reduced to
  // a single row even if they have multiple session times
  // In that case we create a tooltip that shows when sessions happen over the course of the day
  if (props.complexTimes) {
    const sessionTimes = Object.entries(props.sessionTime).map(
      (sessionTimeEntry, i) => (
        <React.Fragment key={i}>
          <div>{sessionTimeEntry[0]}:</div>
          <div>{sessionTimeEntry[1]}</div>
        </React.Fragment>
      )
    );
    sessionTimeNode = (
      <ToolTip
        tipClass="event__session-group-tooltip-container"
        tooltip={sessionTimes}
        offsetLeft={16}
        minWidth={150}
        className="event__session-group-tooltip"
      >
        Time Change*
      </ToolTip>
    );
  } else {
    sessionTimeNode = props.sessionTime;
  }

  let sessionNPS = 0;
  let sessionRTC = 0;
  let surveyCount = 0;
  Object.entries(props.surveyResults).forEach((surveyResultsEntry) => {
    surveyResultsEntry[1].forEach((surveyResult) => {
      sessionNPS += surveyResult.nps_promoter_val;
      sessionRTC += surveyResult.recommend_score;
    });
    surveyCount += surveyResultsEntry[1].length;
  });
  if (surveyCount > 0) {
    // I'd use /= but it disregards the order of operations
    sessionNPS = (sessionNPS / surveyCount) * 100;
    sessionNPS = Math.round(sessionNPS);
    sessionRTC = (sessionRTC / surveyCount / 10.0) * 100;
    sessionRTC = Math.round(sessionRTC);
    sessionRTC /= 10.0;
  }
  let sessionInfoNode;
  if (props.name && props.ldProductName) {
    sessionInfoNode = (
      <div className="event__session-name">
        {props.name}: {props.ldProductName}
      </div>
    );
  } else if (props.name) {
    sessionInfoNode = <div className="event__session-name">{props.name}</div>;
  } else if (props.ldProductName) {
    sessionInfoNode = (
      <div className="event__session-name">{props.ldProductName}</div>
    );
  }
  const sessionIdsLink = props.sessionIds
    .map((id) => `p.SessionId=${id}`)
    .join('&');
  return (
    <React.Fragment>
      <div className={'grid-big__row event__participant-row'}>
        <div className="cute-2-desktop event__session-cell">
          <div className="event__session-date">{props.sessionDate}</div>
          {sessionInfoNode}
        </div>
        <div className="cute-2-desktop event__session-cell">
          {sessionTimeNode}
        </div>
        <div
          className="cute-2-desktop event__session-cell v5__link"
          data-testid="group-expand"
          onClick={() => setExpandRow('register')}
        >
          {checkIns}
        </div>
        <div className="cute-1-desktop event__session-cell">
          {props.attendance || 0}
        </div>
        <div
          className="cute-1-desktop event__session-cell v5__link"
          data-testid="survey-expand"
          onClick={() => setExpandRow('surveys')}
        >
          {surveyCount}
        </div>
        <div className="cute-1-desktop event__session-cell">{sessionNPS}</div>
        <div className="cute-2-desktop event__session-cell">{sessionRTC}</div>
        <div className="cute-1-desktop event__session-cell">
          <a
            href={`https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/802b8549-c7b3-404f-b9d9-7d4307343c8b#${sessionIdsLink}`}
            className="v5__link event__session-bi-link tc-bi-tool"
            target="_blank"
            rel="noreferrer"
          ></a>
        </div>
        {expandedNode}
        {hideNode}
      </div>
    </React.Fragment>
  );
};

EventSessionRow.propTypes = {
  sessionDate: PropTypes.string.isRequired,
  checkInCount: PropTypes.array.isRequired,
  attendance: PropTypes.number.isRequired,
  sessionTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sessionStart: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sessionParticipantGroupMap: PropTypes.object.isRequired,
  sessionIds: PropTypes.array.isRequired,
  surveyResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  name: PropTypes.string.isRequired,
  ldProductName: PropTypes.string.isRequired,
  complexTimes: PropTypes.bool.isRequired,
};

export default EventSessionRow;
