// @ts-nocheck FIXME
import Utils from './DynamicUtils';
import WindowHelpers from './WindowHelpers';

const Modal = (store, modal) => {
  // Keep a copy of the store modal
  const storeModal = Utils.clone(modal);

  // Resets the modal
  const reset = () => {
    for (const property in modal) {
      switch (typeof modal[property]) {
        case 'object':
          // Clone objects
          modal[property] = Utils.clone(defaultModal[property]);
          break;
        default:
          modal[property] = defaultModal[property];
          break;
      }
    }
  };

  // By default, closing the modal will trigger the store onClose callback
  const close = (data = { callStoreCallback: true, scroll: true }) => {
    if (!('callStoreCallback' in data)) {
      data.callStoreCallback = true;
    }
    if (!('scroll' in data)) {
      data.scroll = true;
    }

    // Call the store onClose callback if needed
    if (
      data.callStoreCallback &&
      modal.callbacks &&
      typeof modal.callbacks.onClose === 'function'
    ) {
      const modalData = modal.data ? modal.data : {};
      const closeData = Utils.clone(modalData);
      Object.assign(closeData, data);
      modal.callbacks.onClose(closeData);
    }
    // Reset the modal object to its default state
    reset();
    // Update the store state
    store.changed();
    // Scroll the window (unless the scroll flag is set to false)
    if (store.scrollingPosition && data.scroll) {
      WindowHelpers.scrollTo(
        store.scrollingPosition.left,
        store.scrollingPosition.top
      );
    }
  };

  const open = (data) => {
    // Open the modal
    modal.opened = true;
    modal.data = data;

    if (typeof window != 'undefined') {
      // Update the store to keep track of the scrolling position
      store.scrollingPosition = {
        top: WindowHelpers.getVerticalScrollPosition(),
        left: WindowHelpers.getHorizontalScrollPosition(),
      };
    }

    if (modal.callbacks && typeof modal.callbacks.onOpen === 'function') {
      const modalData = modal.data ? modal.data : {};
      const openData = Utils.clone(modalData);
      modal.callbacks.onOpen(openData);
    }
    // Update the store state
    store.changed();
  };

  const setFieldError = (data) => {
    if (data.field && data.error) {
      modal.errors[data.field] = data.error;
    } else if (data.field in modal.errors) {
      // Remove the error
      delete modal.errors[data.field];
    }
    // Update the store state
    store.changed();
  };

  const toggleLoadingState = () => {
    modal.loading = !modal.loading;
    // Update the store state
    store.changed();
  };

  const defaultModal = {
    // Properties
    opened: false,
    loading: false,
    data: {},
    errors: {},
    // Methods
    close: close,
    open: open,
    setFieldError: setFieldError,
    toggleLoadingState: toggleLoadingState,
    callbacks: modal.callbacks,
  };

  // Copy any remaining properties in storeModal to defaultModal
  for (const property in storeModal) {
    // If defaultModal already has the property, ignore it
    if (property in defaultModal) {
      continue;
    }
    switch (typeof storeModal[property]) {
      case 'object':
        // Clone objects
        defaultModal[property] = Utils.clone(storeModal[property]);
        break;
      default:
        defaultModal[property] = storeModal[property];
        break;
    }
  }

  // Init the modal
  modal = Utils.clone(defaultModal);

  return modal;
};

export default Modal;
