// @ts-nocheck FIXME
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState } from 'react';

const MobileArcivedParticipantRow = (props) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const participant = props.coachingEngagement.participant;

  let focusAreasNode = null;
  const focusAreas = participant.focusAreas.map((area, i) => {
    return <li key={`ld-${participant.id}-${area.id}-${i}`}>{area.title}</li>;
  });
  if (focusAreas.length) {
    focusAreasNode = (
      <div className="admin-v5__coach-participant--row">
        <div className="admin-v5-coach-table__header-caption">Focus Areas</div>
        <div>
          <ul className="admin-v5__domains">{focusAreas}</ul>
        </div>
      </div>
    );
  }

  let cohortNode = null;
  if (props.coachingEngagement.cohort.cohort_name) {
    cohortNode = (
      <div className="admin-v5__coach-participant--row">
        <div className="admin-v5-coach-table__header-caption">Cohort</div>
        <div>{props.coachingEngagement.cohort.cohort_name}</div>
      </div>
    );
  }

  const detailsOpenClass = detailsOpen ? '' : 'hide-phone';
  const registrationCompletion = moment(
    participant.registration_completion
  ).format('MMMM D, YYYY');
  const endDate = moment
    .min(
      moment(participant.cohortEndDate),
      moment(participant.finished_coaching)
    )
    .format('MMMM D, YYYY');

  return (
    <div className="admin-v5__coach-table--user-cell">
      <div className="admin-v5__coach-table--user-row">
        <div className="cute-6-phone admin-v5__coach--table--user-details">
          <div className="tc__link admin-v5__coach-table--user-image">
            <UserPhoto
              src={participant.photo}
              userId={participant.user_id}
              diameter={40}
              firstName={participant.first_name}
              lastName={participant.last_name}
              editable={false}
            />
          </div>
          <div className="admin-v5__participant-name">
            {participant.first_name} {participant.last_name}
          </div>
        </div>
        <div className="cute-5-desktop">
          {registrationCompletion} - {endDate}
        </div>
        <div
          onClick={() => setDetailsOpen(!detailsOpen)}
          className={`cute-1-phone admin-v5__coach-expand-btn tc-v4-circle-arrow workspace__goal-expand-arrow ${
            detailsOpen ? '' : 'workspace__goal-expand-arrow--expanded'
          }`}
        />
      </div>
      <div className={`admin-v5__coach-participant--info ${detailsOpenClass}`}>
        <div className="admin-v5__coach-participant--row tc__breakword admin-v5_coach-dashboard-email">
          {participant.email}
        </div>
        {cohortNode}
        {focusAreasNode}
        <div className="admin-v5__coach-participant--row">{props.children}</div>
      </div>
    </div>
  );
};

MobileArcivedParticipantRow.propTypes = {
  coachingEngagement: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default MobileArcivedParticipantRow;
