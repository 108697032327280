// @ts-nocheck FIXME
import ActivateOnScrollToElement from '@legacy-components/react/V4/Common/ActivateOnScrollToElement';
import WindowScrollElementFixer from '@legacy-components/react/V5/Common/WindowScrollElementFixer';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import ZenScroll from 'zenscroll';
import Domains from './modules/Domains';
import Initiative from './modules/Initiative';

const DomainsContainer = (props) => {
  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      // on desktop only, clicking on a sidebar link scrolls to the associated section
      ZenScroll.to(element);
    }
  };

  const initiativesNode = [];
  const initiativesSidebarNode = [];
  const initiativesImagesNode = [];
  props.initiatives.forEach((initiative) => {
    // scope incrementer to interation for scrollToSection
    // it was otherwise always 4
    initiativesNode.push(
      <Initiative key={`initiative-${initiative.id}`} initiative={initiative}>
        <Domains domains={initiative.domains} />
      </Initiative>
    );
    initiativesSidebarNode.push(
      <li
        key={`initiative-link-${initiative.id}`}
        className="v5__link strategy-v4__side-nav-row"
        id={`nav-link-${initiative.id}`}
        onClick={() => scrollToSection(`element-${initiative.id}`)}
      >
        {initiative.title}
      </li>
    );
    initiativesImagesNode.push(
      <div
        key={`initiative-image-${initiative.id}`}
        onClick={() => scrollToSection(`element-${initiative.id}`)}
        className="domains__initiative"
      >
        <div className="domains__initiative-image-container">
          <img
            className="domains__initiative-image"
            src={`/legacy-images/v5/initiatives/${initiative.image}`}
            alt={initiative.title}
          />
        </div>
        <div className="domains__initiative-name v5__body-copy">
          {initiative.title}
        </div>
      </div>
    );
  });

  return (
    <div className="tc__page">
      <Header page="Domains" {...props} />
      <div className="row row--1200">
        <div className="domains__initiative-header cute-10-desktop cute-12-phone center-desktop">
          <h1 className="domains__initiative-headline v5__heading-3">
            4 Essential Elements of Student-Centered Teaching and Learning
          </h1>
          <div data-test-id="pageDescription" className="v5__body-copy">
            <div className="domains__initiative-paragraph">
              When students feel excited about learning and respected in their
              classrooms, they can succeed. In student-centered environments,
              teachers create a collaborative culture by valuing each student’s
              unique strengths and needs. They empower learners to have agency
              and voice, solve real-world challenges, and reflect on progress.
              Student-centered teaching and learning is not a “nice-to-have” -
              it is key to academic achievement, and builds crucial skills like
              resilience, independence, creativity, and collaboration which are
              needed to thrive in our rapidly changing world.
            </div>
            <div className="domains__initiative-paragraph">
              Based on a decade of learning from and supporting thousands of
              teachers, we’ve identified 4 essential elements of
              student-centered teaching and learning. In each of our{' '}
              <a
                className="v5__link"
                href="https://betterlesson.com/professional-learning/"
              >
                services
              </a>
              , we help teachers and leaders build their skills in a few “Focus
              Areas” that meet their individual needs and school or district
              priorities. Our coaches create personalized, job-embedded,
              supportive environments for significant professional growth.
            </div>
          </div>
          <div className="domains__initiative-images">
            {initiativesImagesNode}
          </div>
        </div>
        <div className="v5__border-gradient" />
        <div className="cute-12-desktop domains__initiatives-content">
          <div className="row">
            <div className="strategy-v4__side-nav-container cute-2-desktop hide-phone">
              <div className="domains__initiatives-side-nav bl4__sidebar--sticky">
                <div className="v5__heading-6">The Essential Elements</div>
                <ul className="domains__initiative-sidebar-links strategy-v4__side-nav">
                  {initiativesSidebarNode}
                </ul>
              </div>
            </div>
            <div className="cute-10-desktop cute-12-phone">
              {initiativesNode}
            </div>
          </div>
        </div>
      </div>
      <ActivateOnScrollToElement
        elementCount={5}
        elementUpperBuffer={30}
        elementIdPrefix="element-"
        targetIdPrefix="nav-link-"
      />
      <WindowScrollElementFixer
        positionToClass="domains__initiatives-content"
        positionThisClass="domains__initiatives-side-nav"
        positionThisContainerClass="strategy-v4__side-nav-container"
        positionToElemTopMargin={0}
      />
    </div>
  );
};

export default DomainsContainer;
