// @ts-nocheck FIXME
import Category from './GrowthAreas/Category';

const GrowthAreas = (props) => {
  // dont render this page if we arent on it
  if (props.tab != 'growth-areas') {
    return <noscript />;
  }

  // change active category
  const selectCategory = (e, categoryId, categoryName) => {
    e.preventDefault();
    props.changeDisplayedCategory({
      categoryId: categoryId,
      categoryName: categoryName,
    });
  };

  // default empty arrays
  const categoryNavigationNodes = [];
  const categoryNodes = [];

  // only care about visible subjects
  const visibleSubjects = props.subjects.filter((subject) => subject.visible);

  // if there is no active category default to the first one
  const activeCategoryId = props.displayedCategoryId
    ? props.displayedCategoryId
    : visibleSubjects[0].id;

  // loop over categories
  for (const subject of visibleSubjects) {
    // add the active class to the appropreate category
    const activeClass = activeCategoryId == subject.id ? ' active' : '';

    // setup array of category tabs
    categoryNavigationNodes.push(
      <a
        className={
          'ppp__tab ppp__tab--growth-area ppp__tab--' +
          visibleSubjects.length +
          ' tc__link' +
          activeClass
        }
        onClick={(e) => {
          selectCategory(e, subject.id, subject.name);
        }}
        key={'category-tab-' + subject.id}
      >
        {subject.name}
      </a>
    );

    // set up array of categories
    categoryNodes.push(
      <Category
        {...subject}
        changeDisplayedGA={props.changeDisplayedGA}
        showArtifact={props.showArtifact}
        activeClass={activeClass}
        displayedGAId={props.displayedGAId}
        key={'category-' + subject.id}
        tab={props.tab}
        portfolio_guid={props.portfolio_guid}
      />
    );
  }

  return (
    <div className="ppp__section ppp__section--growth-areas">
      <div className="ppp__section-headline">{props.name}</div>
      <div className="ppp__tab--growth-areas">{categoryNavigationNodes}</div>
      <div className="">{categoryNodes}</div>
    </div>
  );
};

export default GrowthAreas;
