// @ts-nocheck FIXME
import WorkspaceApi from '@legacy-components/apis/V5/WorkspaceApi';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import Utils from '../../../../components/DynamicUtils';
import { getDateRange } from '../../../../components/datepicker/date_utils';
import SelfAssessmentProgress from '../../../SelfAssessmentProgress/SelfAssessmentProgress';
import ArrayHelpers from './../../../../components/ArrayHelpers';
import { Router } from './../../../../components/Router';
import NavigationLink from './../../../NavigationLink';
import CompletedGoalEntry from './Entries/CompletedGoalEntry';
import CustomStrategyEntry from './Entries/CustomStrategyEntry';
import DocumentEntry from './Entries/DocumentEntry';
import EventSessionEntry from './Entries/EventSessionEntry';
import GoalEntry from './Entries/GoalEntry';
import LessonEntry from './Entries/LessonEntry';
import MeetingClipEntry from './Entries/MeetingClipEntry';
import MeetingNotesEntry from './Entries/MeetingNotesEntry';
import SelfAssesmentEntry from './Entries/SelfAssesmentEntry';
import StartingStatementEntry from './Entries/StartingStatementEntry';
import StrategyEntry from './Entries/StrategyEntry';
import TextEntry from './Entries/TextEntry';
import VisionEntry from './Entries/VisionEntry';
import EntryContainer from './Entries/modules/EntryContainer';
import EntryHeaderView from './Entries/modules/EntryHeaderView';
import {
  artifactBuilder,
  curatedStrategyEntryEditForm,
  customStrategyEntryEditForm,
  goalEntryEditForm,
  lessonEntryEditForm,
  meetingClipEntryEditForm,
  selfAssessmentEntryForm,
  textEntryEditForm,
} from './WorkspaceForms';

const WorkspaceEntries = (props) => {
  // set up timeline entries, or show the default image
  let startingReflectionNode = (
    <li>
      Write a{' '}
      <button
        className="v5__link"
        onClick={() => props.handleOpenNewEntry('text')}
      >
        starting reflection
      </button>
    </li>
  );
  let startingStrategyNode = (
    <li>
      Find a{' '}
      <NavigationLink
        url={`/lab/workspace/${props.pageUser.id}/select-resource`}
        className="v5__link"
      >
        strategy
      </NavigationLink>{' '}
      you&apos;d like to try
    </li>
  );
  let startingArticleNode = (
    <li>
      Share an{' '}
      <button
        className="v5__link"
        onClick={() => props.handleOpenNewEntry('link')}
      >
        inspiring article
      </button>
    </li>
  );
  // the placeholder should only allow interactivity if the current user can make edits
  if (!props.canEdit || props.isEditing) {
    startingReflectionNode = <li>Write a starting reflection</li>;
    startingStrategyNode = <li>Find a strategy you&apos;d like to try</li>;
    startingArticleNode = <li>Share an inspiring article</li>;
  }
  let entriesNode =
    props.newEntryType ||
    !props.activeParticipant ||
    !props.activeParticipant.coaching_engagement_id ||
    !props.activeStoryId ? null : (
      <div>
        <div className="workspace__timeline-empty">
          <img
            src="/legacy-images/v5/workspace-timeline.png"
            className="workspace__timeline-empty-img"
          />
          <div>
            <h2 className="workspace__heading-2 v5__heading-3">
              Add your first entry
            </h2>
            <div className="v5__body-copy">
              Every journey begins with the first step. Here are some ways to
              get started:
              <ul className="workspace__timeline-empty-list">
                {startingReflectionNode}
                {startingStrategyNode}
                {startingArticleNode}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );

  const userTimeZone = props.user.time_zone
    ? props.user.time_zone.pytz_timezone
    : 'US/Eastern';

  const getTitleFromFocusArea = (focusAreaId) => {
    const focusArea = props.allDomains.find(
      (focusArea) => focusArea.id === focusAreaId
    );
    if (!focusArea) return null;
    return focusArea.title;
  };

  if (ArrayHelpers.hasElements(props.entries)) {
    // this filtering block is for filtering by goals
    let tempEntries = [...props.entries];
    if (props.goalFilter) {
      tempEntries = tempEntries.filter(
        (entry) =>
          (entry.goalIds && entry.goalIds.indexOf(props.goalFilter.id) >= 0) ||
          (entry.type === 'self_assessment_progress' &&
            entry.outcome_id === props.goalFilter.sub_focus_area_id)
      );
    }
    // also filter by highlighted entries if necessary
    if (props.filterByHighlight) {
      tempEntries = tempEntries.filter((entry) => {
        if (entry.type === 'self_assessment_progress') {
          return entry.highlighted;
        } else {
          const story = props.stories.find(
            (story) => story.id === entry.story_id
          );
          const schoolYear = story ? story.school_year : entry.school_year;
          return (
            schoolYear === Utils.getCurrentSchoolYear() && entry.highlighted
          );
        }
      });
    }

    // Convert the entries to react components
    let currentParticipantId;
    let currentStoryId;
    entriesNode = tempEntries.map((entry) => {
      // get data for the current entry's story
      // NOTE: the participant vision "entry" (and goal "entries" of archived participants)
      //       does NOT have an associated story (since it's at the participant level),
      //       so it is handled slightly differently
      const story = props.stories.find((story) => story.id === entry.story_id);

      const toggleGoalTag = (goalId) => {
        // if the entry already has the goal tag, remove it
        if (entry.goalIds.some((id) => id === goalId)) {
          WorkspaceApi.removeGoalFromEntry({
            storyId: entry.story_id,
            entryId: entry.id,
            goalId: goalId,
          })
            .then((response) => {
              props.untagGoalResponseCallback(response);
            })
            .catch(() => {
              if (props.setErrorAlert) {
                props.setErrorAlert();
              }
            });
        }
        // otherwise, add it
        else {
          WorkspaceApi.addGoalToEntry({
            storyId: entry.story_id,
            entryId: entry.id,
            goalId: goalId,
          })
            .then((response) => {
              props.tagGoalResponseCallback(response);
            })
            .catch(() => {
              if (props.setErrorAlert) {
                props.setErrorAlert();
              }
            });
        }
      };
      // figure out the entry's relevant school year based on when the participant was active,
      // either from the story data or the entry directly if it has no story
      const schoolYear = story ? story.school_year : entry.school_year;

      let fromCurrentEngagement = !!(
        props.activeParticipant &&
        props.activeParticipant.coaching_engagement_id &&
        entry.coaching_engagement_id &&
        entry.coaching_engagement_id ===
          props.activeParticipant.coaching_engagement_id
      );

      // the highlighted status is valid if it is either not been highlighted yet
      // or (the entry has been highlighted and is attached to a goal and has highlight_sub_focus_area_id)
      // need to be combined with other checks
      let hasValidHighlightStatus = !!(
        !entry.highlighted ||
        (entry.highlighted &&
          !ArrayHelpers.isEmpty(entry.goalIds) &&
          entry.highlight_sub_focus_area_id &&
          entry.highlight_sub_focus_area_id !== null)
      );

      // if the entry has a tag (remnant from the story page), prepend that to the title
      let tagName = '';
      switch (entry.tag) {
        case 'evidence':
          tagName = 'Evidence';
          break;
        case 'reflection':
          tagName = 'Reflection';
          break;
        case 'coach_note':
          tagName = 'Coach Note';
          break;
      }

      // build the entry based on it's type
      let entryNode;
      let titleText;
      let title = '';
      let iconClass = '';
      let headerClass = '';
      let footerClass = '';
      let renderDate;
      // by default, we show the entry's author
      // (for entries from the 2018-2019 school year, we only show the author for coach notes)
      let hideAuthor = !entry.author || schoolYear === '2018 - 2019';
      let editEntryFormCall = () => {
        return false;
      };
      let canEdit =
        props.canEdit &&
        !props.isEditing &&
        entry.story_id &&
        entry.story_id === props.activeStoryId;
      let canHighlight = props.canHighlight;

      switch (entry.type) {
        case 'custom_strategy':
          title = entry.title;
          iconClass = 'tc-v4-strategy';
          entryNode = <CustomStrategyEntry {...entry} />;
          editEntryFormCall = (closeCallback, confirmDelete) => {
            return customStrategyEntryEditForm(
              props,
              entry.title,
              entry.description,
              entry.author,
              entry.goalIds,
              closeCallback,
              confirmDelete,
              entry.index,
              entry.story_custom_strategy_id,
              entry.highlighted,
              toggleGoalTag
            );
          };
          break;
        case 'curated_strategy':
          title = 'Strategy';
          iconClass = 'tc-v4-strategy';
          entryNode = <StrategyEntry {...entry} />;
          editEntryFormCall = (closeCallback, confirmDelete) => {
            return curatedStrategyEntryEditForm(
              props,
              title,
              entry.note,
              entry.author,
              entry.goalIds,
              closeCallback,
              confirmDelete,
              entry.index,
              entry,
              toggleGoalTag
            );
          };
          break;
        case 'lesson':
          title = 'Lesson';
          iconClass = 'tc-v4-ela';
          entryNode = <LessonEntry {...entry} />;
          editEntryFormCall = (closeCallback, confirmDelete) => {
            return lessonEntryEditForm(
              props,
              title,
              entry.note,
              entry.author,
              entry.goalIds,
              closeCallback,
              confirmDelete,
              entry.index,
              entry,
              toggleGoalTag
            );
          };
          break;
        case 'link':
          // the entry title changes if it has a legacy tag
          titleText = entry.title;
          if (tagName) {
            titleText = `${tagName}: ${entry.title}`;
          }
          // Links are supposed to link in their header
          title = (
            <NavigationLink
              className="v5__link workspace__entry-header-text-link"
              url={entry.connections.link}
              target="_blank"
            >
              {titleText}
            </NavigationLink>
          );
          iconClass = 'tc-v4-link';
          entryNode = <DocumentEntry {...entry} />;
          editEntryFormCall = (closeCallback, confirmDelete) => {
            return artifactBuilder(
              props,
              'edit',
              entry.type,
              closeCallback,
              confirmDelete,
              entry.index,
              entry,
              toggleGoalTag
            );
          };
          break;
        case 'file':
          // the entry title changes if it has a legacy tag
          titleText = entry.title;
          if (tagName) {
            titleText = `${tagName}: ${entry.title}`;
          }
          title = (
            <NavigationLink
              className="v5__link workspace__entry-header-text-link"
              url={`/lab/workspace/artifact/${entry.story_file_id}`}
            >
              {titleText}
            </NavigationLink>
          );
          iconClass = 'tc-v4-page';
          entryNode = <DocumentEntry {...entry} />;
          editEntryFormCall = (closeCallback, confirmDelete) => {
            return artifactBuilder(
              props,
              'edit',
              entry.type,
              closeCallback,
              confirmDelete,
              entry.index,
              entry,
              toggleGoalTag
            );
          };
          break;
        case 'meeting_clip':
          // the entry title changes if it has a legacy tag
          title = entry.title;
          if (tagName) {
            title = `${tagName}: ${entry.title}`;
          }
          iconClass = 'tc-v4-calendar';
          entryNode = (
            <MeetingClipEntry {...entry} entryId={`meeting-clip-${entry.id}`} />
          );
          editEntryFormCall = (closeCallback, confirmDelete) => {
            return meetingClipEntryEditForm(
              props,
              title,
              entry.description,
              entry.goalIds,
              closeCallback,
              confirmDelete,
              entry.index,
              entry.story_meeting_clip_id,
              entry.connections,
              entry.highlighted,
              toggleGoalTag
            );
          };
          break;
        case 'meeting_note':
          canHighlight = false;
          // The current FRD is ambiguous as to how this entry gets deleted, for now we'll check to make sure there are notes
          if (entry.meeting && entry.meeting.meeting_notes) {
            title = `Meeting on ${moment(entry.meeting.meeting_time)
              .tz(userTimeZone)
              .format('MMMM DD, YYYY')}: Notes & Next Steps`;
            iconClass = 'tc-note';
            entryNode = <MeetingNotesEntry {...entry.meeting} />;
            // meeting_note is a special case where it can only be edited from the meeting details page so we need to go to the page
            editEntryFormCall = () => {
              Router.route(
                `/lab/participant/${entry.participant_id}/meeting/${entry.meeting.id}/details?isEditingMeetingNotes=true`
              );
            };
          }
          canHighlight = false;
          break;
        case 'text':
          // the entry title changes if it has a legacy tag or is from the 2018-2019 school year
          title = entry.title || 'Note';
          if (tagName) {
            title = tagName;
            // the only time we show the author for a 2018-2019 story entry
            // is when it is a coach note
            if (entry.tag === 'coach_note') {
              hideAuthor = false;
            }
          } else if (schoolYear === '2018 - 2019') {
            title = 'Note';
          }
          iconClass = 'tc-note';
          // on the story page, the text entry used a WYSIWYG,
          // but in the workspace it does not
          entryNode = (
            <TextEntry {...entry} isWYSIWYG={schoolYear === '2018 - 2019'} />
          );
          editEntryFormCall = (closeCallback, confirmDelete) => {
            return textEntryEditForm(
              props,
              entry.title,
              entry.description,
              entry.author,
              entry.goalIds,
              closeCallback,
              confirmDelete,
              entry.index,
              entry.story_text_entry_id,
              entry.highlighted,
              toggleGoalTag
            );
          };
          break;
        case 'starting_statement':
          // Starting Statements are a legacy entry type from the Story page,
          // and are not editable
          title = 'Starting Reflection';
          iconClass = 'tc-v4-reflection';
          entryNode = <StartingStatementEntry {...entry} />;
          editEntryFormCall = null;
          canEdit = false;
          break;
        case 'vision':
          // vision statements associated with previous participant records are not editable
          title = 'My Vision';
          iconClass = 'tc-v4-reflection';
          entryNode = <VisionEntry {...entry} />;
          editEntryFormCall = null;
          canEdit = false;
          break;
        case 'goal':
          // goals associated with previous participant records are not editable
          title = entry.title;
          iconClass = 'tc-target-v2';
          entryNode = <GoalEntry {...entry} />;
          editEntryFormCall = null;
          canEdit = false;
          canHighlight = false;
          break;
        case 'completed_goal':
          title = (
            <div>
              <span className="workspace__entry-title-accent">Completed:</span>{' '}
              {entry.title}
            </div>
          );
          iconClass = 'tc-v5-milestone-complete';
          // only editable if the goal has an outcome directly associated with it
          canEdit = canEdit && !!entry.sub_focus_area_id;
          canHighlight = false;
          // on the story page, the participant goal used a WYSIWYG,
          // but in the workspace goals do not
          entryNode = (
            <CompletedGoalEntry
              {...entry}
              isWYSIWYG={schoolYear === '2018 - 2019'}
            />
          );
          editEntryFormCall = (closeCallback) => {
            return goalEntryEditForm(
              props,
              title,
              entry.created_formatted,
              entry.description,
              entry.note,
              closeCallback,
              entry.index,
              entry.goal_entry_id
            );
          };
          break;
        case 'assessment_survey':
          // assesment surveys are not editable
          title = entry.focus_area_title;
          iconClass = 'tc-v5-survey-icon';
          canEdit = false;
          entryNode = <SelfAssesmentEntry {...entry} />;
          editEntryFormCall = (closeCallback) => {
            return selfAssessmentEntryForm(
              props,
              entry.assessment_survey_id,
              entry.goalIds,
              closeCallback,
              entry.index,
              entryNode
            );
          };
          break;
        case 'self_assessment_progress': {
          renderDate = (date) =>
            `Updated ${date}, ${getDateRange(
              entry.first_assessment_date,
              entry.last_assessment_date
            )}`;
          const focusAreaTitle = getTitleFromFocusArea(
            entry.outcome_focus_area_id
          );
          title = `${entry.outcome_title}`;
          // remove the trailing period
          if (title.endsWith('.')) {
            title = title.substring(0, title.length - 1);
          }
          if (focusAreaTitle) {
            title = title.concat(` | ${focusAreaTitle}`);
          }
          iconClass = 'tc-v5-survey-icon';
          canEdit = false;
          // since this type of data doesn't tie to entries in the backend, don't check entry related attributes
          fromCurrentEngagement = !!(
            props.activeParticipant &&
            props.activeParticipant.coaching_engagement_id
          );
          // only the growth of the outcomes that the participant is working on / has worked on can be highlighted
          canHighlight = !!(
            props.canHighlight &&
            props.goals &&
            props.goals.find(
              (goal) => entry.outcome_id === goal.sub_focus_area_id
            )
          );
          hasValidHighlightStatus = !!(
            !entry.highlighted ||
            (entry.highlighted && entry.outcome_id && entry.growth_highlight_id)
          );
          entryNode = <SelfAssessmentProgress {...entry} />;
          break;
        }

        case 'event_session':
          if (entry.session) {
            canHighlight = false;
            // build the entry subtitle based on session data
            const sessionName = entry.session.is_single_session
              ? ''
              : entry.session.session_name;
            const groupName = entry.session.group_name
              ? entry.session.group_name
              : '';
            const nameSep = sessionName && groupName ? ', ' : '';
            title = (
              <div>
                <div>Event: {entry.session.event_display_name}</div>
                <div className="v5__body-copy">
                  {sessionName}
                  {nameSep}
                  {groupName}
                </div>
              </div>
            );
            iconClass = 'tc-v5-event-product';
            const editLink = `/lab/event/${entry.session.event_id}/participant/${entry.participant_id}?session=${entry.session.id}`;
            entryNode = (
              <EventSessionEntry
                nextSteps={entry.next_steps}
                editLink={editLink}
              />
            );
            // event_session is a special case where it can only be edited
            // from the Event Attendee page so we need to go to the page
            editEntryFormCall = () => {
              Router.route(editLink);
            };
          }
          break;
        case 'automatic_entry':
          // automatic entries are read-only
          iconClass = 'tc-v5-profile workspace__automatic-entry-icon';
          title = entry.note;
          canEdit = false;
          canHighlight = false;
          headerClass = 'workspace__entry-header-row--editing';
          footerClass = 'workspace__entry-footer--compact';
          break;
      }

      if (entryNode || entry.type === 'automatic_entry') {
        // set up the separators between stories, if we are at a new story
        // (do not show them for the active story)
        let yearSeparatorNode;
        let domainSeparatorNode;
        if (
          entry.type !== 'automatic_entry' &&
          (!story ||
            (currentStoryId !== entry.story_id &&
              (!props.activeStoryId || entry.story_id !== props.activeStoryId)))
        ) {
          const newParticipantId = story
            ? story.participant_id
            : entry.participant_id;
          // if we are at a new participant, show the school year separator
          if (currentParticipantId !== newParticipantId) {
            yearSeparatorNode = (
              <div className="workspace__story-separator">
                <div className="workspace__story-separator-line workspace__story-separator-line--year"></div>
                <div
                  className="workspace__story-separator-text workspace__story-separator-text--year v5__heading-4"
                  id={`entry-year-${schoolYear.replace(' - ', '-')}`}
                >
                  {schoolYear} School Year
                </div>
                <div className="workspace__story-separator-line workspace__story-separator-line--year"></div>
              </div>
            );
          }

          // if the story has a domain, show the learning domain separator
          if (story && story.focus_area) {
            domainSeparatorNode = (
              <div className="workspace__story-separator">
                <div className="workspace__story-separator-line"></div>
                <div
                  className="workspace__story-separator-text v5__heading-6"
                  id={`entry-ld-${story.focus_area.id}`}
                >
                  {story.focus_area.title}
                </div>
                <div className="workspace__story-separator-line"></div>
              </div>
            );
          }

          // set the current story and participant
          currentStoryId = entry.story_id;
          currentParticipantId = newParticipantId;
        }

        // the time ago we show in the entry header depends on
        // what data is associated with the entry
        let createdFormatted = entry.created_formatted;
        if (entry.meeting) {
          createdFormatted = entry.meeting.meeting_time;
        } else if (entry.session && entry.session.session_time) {
          createdFormatted = entry.session.session_time;
        }
        return (
          <React.Fragment key={`entry-${entry.id}`}>
            {yearSeparatorNode}
            {domainSeparatorNode}

            <EntryContainer
              {...entry}
              key={`entry-${entry.id}-${entry.startInEditMode}`}
              entriesTags={props.entriesTags}
              editEntry={editEntryFormCall}
              deleteEntry={props.deleteEntry}
              domains={props.domains}
              allDomains={props.allDomains}
              goals={props.goals}
              userGoals={props.userGoals}
              tagGoalResponseCallback={props.tagGoalResponseCallback}
              untagGoalResponseCallback={props.untagGoalResponseCallback}
              addComment={props.addComment}
              replyToComment={props.replyToComment}
              editComment={props.editComment}
              deleteComment={props.deleteComment}
              disableEntryActions={false}
              reflection={entry.highlight_reflection}
              highlightEntry={() =>
                props.openModal('entryHighlight', {
                  entryId: entry.id,
                  outcomeId: entry.outcome_id,
                  title:
                    entry.title ||
                    entry.focus_area_title ||
                    entry.outcome_title ||
                    'Note',
                  entryType: entry.type,
                  goals: props.goals,
                  goalIds: entry.goalIds,
                  storyId: entry.story_id,
                  tagGoalResponseCallback: props.tagGoalResponseCallback,
                  untagGoalResponseCallback: props.untagGoalResponseCallback,
                })
              }
              editHighlightEntry={() =>
                props.openModal('editHighlight', {
                  entryId: entry.id,
                  title: entry.title || entry.focus_area_title || 'Note',
                  entryType: entry.type,
                  goals: props.goals,
                  goalIds: entry.goalIds,
                  storyId: entry.story_id,
                  reflection: entry.highlight_reflection,
                  tagGoalResponseCallback: props.tagGoalResponseCallback,
                  untagGoalResponseCallback: props.untagGoalResponseCallback,
                  unhighlightEntry: () =>
                    props.openModal('entryUnhighlight', { entryId: entry.id }),
                })
              }
              // unhighlightEntry only used for outcome growth reflection widget
              unhighlightEntry={() =>
                props.openModal('entryUnhighlight', {
                  growthHighlightId: entry.growth_highlight_id,
                })
              }
              canEdit={canEdit && fromCurrentEngagement}
              canEditHighlights={
                props.canEditHighlights &&
                fromCurrentEngagement &&
                hasValidHighlightStatus
              }
              isEditing={props.isEditing}
              setIsEditing={props.setIsEditing}
              canEditAuthor={
                props.canEditAuthor &&
                entry.story_id &&
                entry.story_id === props.activeStoryId
              }
              pageUser={props.pageUser}
              user={props.user}
              noBorder={!!yearSeparatorNode || !!domainSeparatorNode}
              hideAuthor={hideAuthor}
              setErrorAlert={props.setErrorAlert}
              canHighlight={
                canHighlight && fromCurrentEngagement && hasValidHighlightStatus
              }
              scrollTo={props.scrollTo}
              footerClass={footerClass}
            >
              <EntryHeaderView
                id={entry.id}
                title={title}
                iconClass={iconClass}
                headerClass={headerClass}
                highlighted={entry.highlighted}
                // canHighlight={canHighlight}
                renderDate={renderDate}
                createdFormatted={createdFormatted}
                pageUser={props.pageUser}
              />
              {entryNode}
            </EntryContainer>
          </React.Fragment>
        );
      }
    });

    // entriesNode = entriesNode.concat(
    //     <InfiniteScroll key="infinite-scroll"
    //         scrollCallback={props.loadMoreEntries}
    //         loading={props.isLoadingMoreEntries}
    //         canScroll={props.canLoadMoreEntries}
    //         positionLeft={true} />
    // );
  }

  return entriesNode;
};

WorkspaceEntries.propTypes = {
  handleOpenNewEntry: PropTypes.func.isRequired,
  newEntryType: PropTypes.string.isRequired,
};

export default WorkspaceEntries;
