// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import React from 'react';
import MeetingNotesContainer from './MeetingNotesContainer';

const MeetingNotes = (props) => {
  const lab = useLegacyLabContext();

  const canViewMeetingNotes = Utils.showPartnerLeaderDetails.call(
    lab,
    lab.pageData.pageUser
  );

  const renderMeetingNotes = () => {
    if (
      props.meetingNotes &&
      props.meetingNotes.length &&
      canViewMeetingNotes
    ) {
      return props.meetingNotes.map((meetingNotes) => {
        return (
          <React.Fragment key={meetingNotes.id}>
            <MeetingNotesContainer {...meetingNotes} />
            <div className="row row--1200">
              <div className="cute-12-desktop cute-12-phone">
                <div className="highlights__meeting-notes-row-separator"></div>
              </div>
            </div>
          </React.Fragment>
        );
      });
    }

    const emptyMeetingNotesMessage = canViewMeetingNotes ? (
      <>
        This professional development journey is still a work in progress.
        <div>Check back later for updates.</div>
      </>
    ) : (
      <>
        Activities on this page are private.
        <div>
          If you think you are seeing this page in error, please reach out to
          your CSM for more assistance.
        </div>
      </>
    );

    return (
      <div className="row row--1200 highlights__empty-tab-row">
        <div className="cute-4-desktop cute-1-desktop-offset cute-12-phone cute-0-phone-offset">
          <img
            src="/legacy-images/v5/workspace-milestones.png"
            className="highlights__meeting-notes-img"
          />
        </div>
        <div className="cute-7-desktop cute-12-phone">
          <div className="highlights__outcomes-text highlights__empty-copy">
            {emptyMeetingNotesMessage}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="row row--1200">
      <div className="highlights__count-container">
        <span className="tc-v5-meeting highlights__meeting-notes-header-icon"></span>
        <span className="highlights__count-number">{props.meetingCount}</span>
        <span className="highlights__count-text">Meetings Completed</span>
      </div>
      {renderMeetingNotes()}
    </div>
  );
};

export default MeetingNotes;
