// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import ParticipantEngagementDetails from '@legacy-components/react/V5/Common/ParticipantEngagementDetails/ParticipantEngagementDetails';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import { useState } from 'react';

// const Breadcrumb = require('./../Common/Breadcrumb.jsx');

import MeetingCancelModal from './modules/MeetingCancelModal';

import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Cohort from './modules/Cohort';
import Event from './modules/Event';
import MeetingNoShowModal from './modules/MeetingNoShowModal';
import ParticipantNoteModal from './modules/ParticipantNoteModal';
import ParticipantStatusModal from './modules/ParticipantStatusModal';
import Products from './modules/Products';

const ParticipantDetails = (props) => {
  const lab = useLegacyLabContext();
  const [meetingBeingCancelled, setCancellation] = useState(false);
  const [meetingNoShow, setNoShow] = useState(false);
  const [meetingBeingChanged, setMeetingObject] = useState({});
  const [statusUpdating, setStatusUpdating] = useState('');
  const [addingNote, setAddingNote] = useState(false);
  const [editNote, setEditNote] = useState({});

  const eventsAndCohorts = props.participant.events_cohorts;

  // tracks the index of item in eventsAndCohorts currently displayed
  const [selectedProductIndex, setSelectedProductIndex] = useState(
    eventsAndCohorts && eventsAndCohorts.length ? 0 : null
  );

  const isAdmin = Utils.isAdmin.call(lab, props.user);
  const isInternalCoach =
    Utils.isServicesManager.call(lab, props.user) ||
    Utils.isContractCoach.call(lab, props.user) ||
    isAdmin ||
    Utils.isManager.call(lab, props.user);
  const isBlCoach =
    Utils.isServicesManager.call(lab, props.user) ||
    Utils.isContractCoach.call(lab, props.user) ||
    isAdmin;
  const isPSM = Utils.isManager.call(lab, props.user) || isAdmin;

  const canEditData =
    isPSM ||
    Utils.isServicesManager.call(lab, props.user) ||
    (Utils.isContractCoach.call(lab, props.user) &&
      props.user.isParticipantOfCoach);

  // calls the method in the store to indicate if currently displaying product is a cohort
  const updateProductSelection = (index) => {
    const product = eventsAndCohorts[index];
    if (product.type === 'cohort') {
      props.setSelectedCohortByCohortId(product.cohort_id);
    } else {
      props.setSelectedCohortByCohortId();
    }
    setSelectedProductIndex(index);
  };

  // Build the alert node if needed:
  let alertNode = null;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  let modalNode = null;
  // If the user hasn't selected a time zone, use Eastern time
  const timeZone = props.user.time_zone.pytz_timezone;
  if (meetingBeingCancelled) {
    modalNode = (
      <MeetingCancelModal
        close={() => {
          setCancellation(false);
          setMeetingObject({});
        }}
        meetingStatus={meetingBeingChanged.meetingStatus}
        meetingTimeFormatted={meetingBeingChanged.meetingTimeFormatted}
        internalNotes={meetingBeingChanged.internalNotes}
        timeZone={timeZone}
        participantId={props.participant.id}
        meetingId={meetingBeingChanged.meetingId}
        whenCancelled={meetingBeingChanged.whenCancelled}
        setMeetingAsCancelled={props.setMeetingAsCancelled}
        startChecked={meetingBeingChanged.startChecked || true}
        isMobile={meetingBeingChanged.isMobile}
      />
    );
  } else if (meetingNoShow) {
    modalNode = (
      <MeetingNoShowModal
        close={() => {
          setNoShow(false);
          setMeetingObject({});
        }}
        meetingTimeFormatted={meetingBeingChanged.meetingTimeFormatted}
        timeZone={timeZone}
        participantId={props.participant.id}
        meetingId={meetingBeingChanged.meetingId}
        setMeetingAsNoShow={props.setMeetingAsNoShow}
        startChecked={meetingBeingChanged.startChecked || true}
        isMobile={meetingBeingChanged.isMobile}
      />
    );
  } else if (statusUpdating) {
    modalNode = (
      <ParticipantStatusModal
        close={() => {
          setStatusUpdating('');
        }}
        submitNote={props.addNote}
        status={statusUpdating}
        participantName={props.participant.user.first_name}
      />
    );
  } else if (addingNote) {
    modalNode = (
      <ParticipantNoteModal
        close={() => {
          setAddingNote(false);
        }}
        submitNote={props.addNote}
        participantName={props.participant.user.first_name}
      />
    );
  } else if (editNote.note) {
    if (editNote.status) {
      modalNode = (
        <ParticipantStatusModal
          close={() => setEditNote({})}
          submitNote={(data) =>
            props.editNote(Object.assign(data, { noteId: editNote.noteId }))
          }
          status={editNote.status}
          participantName={props.participant.user.first_name}
          note={editNote.note}
        />
      );
    } else {
      modalNode = (
        <ParticipantNoteModal
          close={() => setEditNote({})}
          submitNote={(data) =>
            props.editNote(Object.assign(data, { noteId: editNote.noteId }))
          }
          participantName={props.participant.user.first_name}
          note={editNote.note}
        />
      );
    }
  }

  // const breadcrumb = [
  //     {
  //         text: 'Coach Dashboard',
  //         link: `/lab/coach/${props.coach ? props.coach.id : props.user.id}/coaching`
  //     }, {
  //         text: `${props.participant.user.first_name} ${props.participant.user.last_name}`
  //     }
  // ];

  // shows the list of products on the left side
  const productsNode = (
    <Products
      eventsAndCohorts={eventsAndCohorts}
      timezone={timeZone}
      updateProductSelection={updateProductSelection}
      selectedProductIndex={selectedProductIndex}
    />
  );

  // display details if we have anything in the array, otherwise show a placeholder
  const detailsNode =
    eventsAndCohorts && eventsAndCohorts.length ? (
      <div className="row row--1200">
        <div className="cute-3-desktop">{productsNode}</div>
        <div className="cute-9-desktop participant-v5__right-pane">
          <div className="participant-v5__section-header v5__heading-5 participant-v5__activity-header">
            Activity
          </div>
          {eventsAndCohorts[selectedProductIndex].type === 'event' ? (
            <Event
              event={eventsAndCohorts[selectedProductIndex]}
              timeZone={timeZone}
            />
          ) : (
            <Cohort
              cohort={eventsAndCohorts[selectedProductIndex]}
              canEditData={canEditData}
              user={props.user}
              participantUser={props.participant.user}
              participantId={props.participant.id}
              timeZone={timeZone}
              isInternalCoach={isInternalCoach}
              isBlCoach={isBlCoach}
              isPSM={isPSM}
              isAdmin={isAdmin}
              setEditNote={setEditNote}
              setStatusUpdating={setStatusUpdating}
              setAddingNote={setAddingNote}
              updateMeetingStatus={props.updateMeetingStatus}
              setNoShow={setNoShow}
              setMeetingObject={setMeetingObject}
              setCancellation={setCancellation}
            />
          )}
        </div>
      </div>
    ) : (
      <div className="v5__body-copy participant-v5__empty-state">
        <p>This participant does not have a record available.</p>
      </div>
    );

  return (
    <div className="tc__page">
      <Header page="ParticipantDetails" {...props} />
      {alertNode}
      {modalNode}
      <div
        className="show-desktop hide-phone participant-v5"
        style={{ marginBottom: '100px' }}
      >
        {/* <div className="row row--1200 participant-v5__breadcrumb">
                    <div className="cute-12-desktop">
                        <Breadcrumb
                            trail={breadcrumb}
                            divider=" / "/>
                    </div>
                </div> */}

        <ParticipantEngagementDetails
          {...props.participant}
          coach={props.coach}
          currentUser={props.user}
          canEditData={canEditData}
          userTimeZone={props.user.time_zone.pytz_timezone}
          setSuccessAlert={props.setSuccessAlert}
        />

        {detailsNode}
      </div>
    </div>
  );
};

ParticipantDetails.propTypes = {
  clearAlert: PropTypes.func.isRequired,
  delight: PropTypes.number,
  participant: PropTypes.object.isRequired,
  recommendation: PropTypes.number,
  setErrorAlert: PropTypes.func.isRequired,
  setSuccessAlert: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  updateMeetingStatus: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ParticipantDetails;
