// @ts-nocheck FIXME
import FormField from '@legacy-components/react/V5/Common/FormField';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

const TimePicker = (props) => {
  const [hour, setHour] = useState(props.value && props.value.format('hh'));
  const [min, setMin] = useState(props.value && props.value.format('mm'));
  const [amPm, setAmPm] = useState(props.value && props.value.format('A'));

  const minField = useRef();
  const hourField = useRef();
  const isLoading = useRef(false);

  const classes = props.className || '';
  const inputClass = props.inputClass || '';
  const clearMarginClass = props.clearMargin
    ? 'v5_timepicker__clear-margin'
    : '';

  const disabled = props.disabled || false;

  // This prevents the user from queuing up too many keydown events in a row
  const handleLoading = () => {
    isLoading.current = false;
  };

  React.useEffect(handleLoading, [amPm]);

  // This makes sure the current state is used
  const firstUpdate = useRef(true);
  const handleChange = () => {
    // don't call onChange on mount
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    // validate the inputs
    let time = null;
    if (
      hour !== undefined &&
      hour !== null &&
      min !== undefined &&
      min !== null &&
      amPm !== undefined &&
      amPm !== null &&
      amPm !== ''
    ) {
      time = moment(`${hour}:${min} ${amPm}`, 'hh:mm A');
    }
    props.onChange(time);
  };
  React.useEffect(handleChange, [hour, min, amPm]);

  const handleFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const handleMinFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
    minField.current.select();
  };

  const handleHourFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
    hourField.current.select();
  };

  const handleHourKeyDown = (e) => {
    // This handles wrapping behavior
    if (e.keyCode === 40 && e.target.value <= 1) {
      e.preventDefault();
      setHour('12');
    } else if (e.keyCode === 38 && e.target.value >= 12) {
      e.preventDefault();
      setHour('01');
    }
  };

  const handleHourChange = (e) => {
    e.preventDefault();
    let value = parseInt(e.target.value);
    if (Number.isNaN(value)) {
      setHour(null);
      return;
    } else if (value > 12) {
      value = '12';
    } else if (value < 10) {
      value = '0' + value;
    }
    if (!amPm) {
      setAmPm('PM');
    }
    if (!min) {
      setMin('00');
    }
    setHour('' + value);
  };

  const handleMinKeyDown = (e) => {
    // This handles wrapping behavior
    if (e.keyCode === 40 && e.target.value <= 0) {
      e.preventDefault();
      setMin('59');
    } else if (e.keyCode === 38 && e.target.value >= 59) {
      e.preventDefault();
      setMin('00');
    }
  };

  const handleMinChange = (e) => {
    e.preventDefault();
    let value = parseInt(e.target.value);
    if (Number.isNaN(value)) {
      setMin(null);
      return;
    } else if (!value || value < 0) {
      value = '00';
    } else if (value < 10) {
      value = '0' + value;
    } else if (value > 59) {
      value = '59';
    }
    if (!amPm) {
      setAmPm('PM');
    }
    if (!hour) {
      setHour('01');
    }
    setMin('' + value);
  };
  const handleAmPmKeyPress = (e) => {
    if (
      e.keyCode !== 38 &&
      e.keyCode !== 40 &&
      e.keyCode !== 65 &&
      e.keyCode !== 80 &&
      e.keyCode !== 8 &&
      e.keyCode !== 46
    ) {
      return;
    }

    e.preventDefault();
    if (isLoading.current) {
      return;
    }

    // a is clicked and it is PM
    if (e.keyCode === 65) {
      if (amPm !== 'AM') {
        toggleAmPmValue();
      }
      return;
    }
    // p is clicked and it is AM
    if (e.keyCode === 80) {
      if (amPm !== 'PM') {
        toggleAmPmValue();
      }
      return;
    }
    // backspace or delete is pressed
    if (e.keyCode === 8 || e.keyCode === 46) {
      setAmPm('');
      return;
    }

    toggleAmPmValue();
  };

  const toggleAmPmValue = () => {
    if (disabled || isLoading.current) {
      return;
    }
    isLoading.current = true;
    if (amPm === 'AM') {
      setAmPm('PM');
    } else {
      setAmPm('AM');
    }
  };

  return (
    <div className={`v5_timepicker ${classes}`} disabled={disabled}>
      <FormField
        dataTestId="hour-picker"
        ref={hourField}
        isControlled={true}
        value={hour}
        className={clearMarginClass}
        inputClassName={`v5_timepicker__hour-input ${inputClass}`}
        type="number"
        onFocus={handleHourFocus}
        onClick={handleHourFocus}
        onKeyDown={handleHourKeyDown}
        onChange={handleHourChange}
        disabled={disabled}
      />
      <span> : </span>
      <FormField
        dataTestId="minute-picker"
        ref={minField}
        isControlled={true}
        value={min}
        className={clearMarginClass}
        inputClassName={`v5_timepicker__minute-input ${inputClass}`}
        type="number"
        onFocus={handleMinFocus}
        onClick={handleMinFocus}
        onKeyDown={handleMinKeyDown}
        onChange={handleMinChange}
        disabled={disabled}
      />
      <FormField
        readOnly={true}
        dataTestId="ampm-picker"
        className={clearMarginClass}
        inputClassName={`v5_timepicker__ampm-input ${inputClass}`}
        onClick={toggleAmPmValue}
        onKeyDown={handleAmPmKeyPress}
        onFocus={handleFocus}
        isControlled={true}
        disabled={disabled}
        value={amPm}
        type="text"
      />
    </div>
  );
};

export default TimePicker;

TimePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  inputClass: PropTypes.string,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  clearMargin: PropTypes.bool,
};
