// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ActionModal from './../../Common/ActionModal';
import FormField from './../../Common/FormField';
import Spinner from './../../Common/Spinner';
import EntryTags from './Entries/modules/EntryTags';

const HighlightModal = (props) => {
  const [reflection, setReflection] = useState(
    props.reflection ? props.reflection : ''
  );

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [selectedGoalId, setSelectedGoalId] = useState(
    props.selectedGoalIds ? props.selectedGoalIds[0] : []
  );
  const [initialGoalId] = useState(
    props.selectedGoalIds ? props.selectedGoalIds[0] : null
  );
  const [selectedGoalIdList, setselectedGoalIdList] = useState(
    props.selectedGoalIds ? props.selectedGoalIds : []
  );

  const [outcomeErrorText, setOutcomeErrorText] = useState('');

  const isHighlightingGrowthWidget =
    props.entryType === 'self_assessment_progress';

  const handleFocus = (field) => {
    // clear any field error
    if (errors[field]) {
      const newErrors = Utils.clone(errors);
      delete newErrors[field];
      setErrors(newErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isHighlightingGrowthWidget) {
      return handleSubmitHighlightGrowth();
    } else {
      return handleSubmitHighlightEntry();
    }
  };

  const handleSubmitHighlightGrowth = () => {
    setIsLoading(true);
    const errorCallback = () => setIsLoading(false);

    const payload = {
      outcome_id: props.outcomeId,
    };
    props.highlightGrowth(payload, errorCallback);
    return;
  };

  const handleSubmitHighlightEntry = () => {
    // first, clear any form errors
    setErrors({});

    // validation
    const formErrors = {};

    if (!reflection) {
      formErrors.reflection =
        'Hold on, you need to enter a reflection to continue.';
    }
    if (!selectedGoalId) {
      setOutcomeErrorText('Oops! You forgot to tag this to an outcome!');
    }
    if (Object.keys(formErrors).length || !selectedGoalId) {
      setErrors(formErrors);
      return;
    }

    setIsLoading(true);

    let data = {
      reflection: reflection,
      title: props.title,
      participantName: props.pageUser.first_name,
      goalId: selectedGoalId,
    };
    // if there is an error submitting to the API, leave the modal open to prevent losing work
    const errorCallback = () => setIsLoading(false);

    // Check if goal tag has changed, update if it has been changed.
    if (initialGoalId !== selectedGoalId) {
      data = {
        reflection: reflection,
        title: props.title,
        participantName: props.pageUser.first_name,
        oldGoalId: initialGoalId,
        newGoalId: selectedGoalId,
      };
      props.update(data, errorCallback);
    }
    // Only need to highlight if goal tag hasn't changed
    else {
      props.submit(data, errorCallback);
    }
  };

  const toggleGoalTag = (goalId) => {
    // if the entry already has the goal tag, remove it
    if (selectedGoalId === goalId) {
      setSelectedGoalId(null);
      setselectedGoalIdList([]);
    } else {
      setSelectedGoalId(goalId);
      setselectedGoalIdList([goalId]);
    }
  };

  // passing in an existing highlight reflection indicates that we are editing instead of adding
  const isEditing = !!props.reflection;

  // disable the Highlight button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  const deleteButton = isEditing ? (
    <button
      onClick={props.unhighlightEntry}
      className="v5__btn delete"
      {...btnProps}
    >
      <Spinner loading={isLoading} />
      {'Delete'}
    </button>
  ) : null;

  let editOutcomeNode = null;
  editOutcomeNode = (
    <EntryTags
      goals={props.goals}
      userGoals={props.userGoals}
      selectedGoalIds={selectedGoalIdList}
      toggleGoal={toggleGoalTag}
      canEdit={true}
      openedFromModal={true}
    />
  );
  const outcomeErrorNode = <div className="v5__error">{outcomeErrorText}</div>;

  const modalTitle = isHighlightingGrowthWidget
    ? 'Highlighting this entry means that any additional self assessment responses for this outcome will update this Growth Reflection and display on this highlight.'
    : `Write a growth reflection to share with ${props.pageUser.first_name}'s administrator.`;
  const saveText = isHighlightingGrowthWidget ? 'Submit' : 'Save';
  return (
    <ActionModal
      headlineText={`${isEditing ? 'Edit' : 'Create a'} Highlight`}
      close={props.close}
    >
      <form>
        <div className="v5__heading-3 workspace__highlight-modal-title-row">
          <div className="tc-v5-star-solid workspace__highlight-modal-title-icon"></div>
          <div>{props.title}</div>
        </div>
        <div className="v5__body-copy workspace__form-row workspace__highlight-title">
          {modalTitle}
        </div>

        {!isHighlightingGrowthWidget && (
          <>
            <div className="v5__body-copy workspace__form-row workspace__growth-reflection workspace__required-fields">
              Growth Reflection
            </div>
            <div className="v5__heading-4 workspace__form-row">
              <FormField
                type="textarea"
                rows="4"
                placeholder="What makes this entry significant? How is this evidence of progress toward their milestones or vision?"
                isControlled={true}
                value={reflection}
                onChange={(e) => setReflection(e.target.value)}
                onFocus={() => handleFocus('reflection')}
                error={errors.reflection}
              />
            </div>
          </>
        )}

        {!isHighlightingGrowthWidget && editOutcomeNode}
        {outcomeErrorNode}

        <div className="workspace__btns-row">
          {deleteButton}
          <button onClick={handleSubmit} className="v5__btn" {...btnProps}>
            <Spinner loading={isLoading} />
            {saveText}
          </button>
        </div>
      </form>
    </ActionModal>
  );
};

HighlightModal.propTypes = {
  entryType: PropTypes.string,
  outcomeId: PropTypes.number,
  currentUser: PropTypes.object.isRequired,
  pageUser: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  reflection: PropTypes.string,
  author: PropTypes.object,
  reportingPhase: PropTypes.any,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  reportingPhases: PropTypes.object.isRequired,
};

export default HighlightModal;
