// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import UserPhoto from './../../../../Common/UserPhoto';
import ConfirmDelete from './../../ConfirmDelete';
import DomainTag from './../../Goal/DomainTag';
import EditCommentContainer from './EditCommentContainer';
import EntryTags from './EntryTags';

// const CommentView = require('./CommentView.jsx');
import WorkspaceApi from './../../../../../../apis/V5/WorkspaceApi';

import Tooltip from '@legacy-components/react/V5/Common/Tooltip';

const EntryContainer = (props) => {
  const [commentBoxOpen, setCommentBoxOpen] = useState(
    props.replyToComment === props.id
  );
  const [editFormVisible, setEditFormVisible] = useState(props.startInEditMode);
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  // const [expandedComments, setExpandedComments] = useState(false);

  const ref = React.useRef();

  React.useEffect(() => {
    if (props.id === props.scrollTo) {
      setTimeout(() => {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 200);
    }
  }, []);

  // handle adding/removing a goal tag while *not* editing the entry
  const toggleGoalTag = (goalId) => {
    // if the entry already has the goal tag, remove it
    if (props.goalIds.some((id) => id === goalId)) {
      WorkspaceApi.removeGoalFromEntry({
        storyId: props.story_id,
        entryId: props.id,
        goalId: goalId,
      })
        .then((response) => {
          props.untagGoalResponseCallback(response);
        })
        .catch(() => {
          if (props.setErrorAlert) {
            props.setErrorAlert();
          }
        });
    }
    // otherwise, add it
    else {
      WorkspaceApi.addGoalToEntry({
        storyId: props.story_id,
        entryId: props.id,
        goalId: goalId,
      })
        .then((response) => {
          props.tagGoalResponseCallback(response);
        })
        .catch(() => {
          if (props.setErrorAlert) {
            props.setErrorAlert();
          }
        });
    }
  };

  const handleStartEdit = () => {
    // meeting_note and event_session are a special cases where they
    // can only be edited from other pages
    if (props.type === 'meeting_note' || props.type === 'event_session') {
      props.editEntry();
      return;
    }

    props.setIsEditing(true);
    setEditFormVisible(true);
  };

  // const handleStartAddComment = () => {
  //     props.setIsEditing(true);
  //     setCommentBoxOpen(true);
  // };

  const handleCancelAddComment = () => {
    props.setIsEditing(false);
    setCommentBoxOpen(false);
  };

  const addComment = (data, errorCallback) => {
    WorkspaceApi.addComment(data)
      .then((response) => {
        props.addComment(response.comment, props);
        setCommentBoxOpen(false);
      })
      .catch(() => {
        errorCallback();
        props.setErrorAlert();
      });
  };

  // hide the border-top if necessary
  const entryClass = props.noBorder ? 'workspace__entry--no-border' : '';

  // This design pattern allows us to have multiple edit forms open at once and also avoids sharing state
  let childNode = props.children;
  if (editFormVisible) {
    // Construct the edit Node
    childNode = props.editEntry(
      () => {
        // Passing in the close call
        setEditFormVisible(false);
        props.setIsEditing(false);
        setConfirmDelete(false);
      },
      // and confirm delete
      () => setConfirmDelete(true)
    );
  }

  // only link to the edit entry form if the current user is allowed to edit any entry,
  // or if the entry has no author, or they are the author of this entry
  const canEditEntryContents =
    (props.canEdit || props.type === 'event_session') &&
    !props.isEditing &&
    (props.canEditAuthor || !props.author || props.author.id === props.user.id);

  // when editing/deleting the entry, hide display data
  let editLinkNode;
  let authorNode;
  if (!editFormVisible && !isConfirmDelete) {
    // for some entry types, there is a link that takes you to edit mode when there is no note
    if (
      canEditEntryContents &&
      !props.note &&
      ['curated_strategy', 'lesson', 'completed_goal'].indexOf(props.type) !==
        -1
    ) {
      editLinkNode = (
        <div className="workspace__entry-add-note-link">
          <button type="button" className="v5__link" onClick={handleStartEdit}>
            {props.type === 'completed_goal'
              ? 'Write a reflection...'
              : 'Add a note...'}
          </button>
        </div>
      );
    }
    // show the entry author for most entry types
    const showAuthorLine =
      commentBoxOpen ||
      (props.comments && props.comments.length && props.comments.length < 5);
    authorNode = !props.hideAuthor && props.author && (
      <div className="workspace__entry-author">
        <div className="workspace__comment-photo-col">
          <UserPhoto
            src={props.author.photo}
            userId={props.author.id}
            firstName={props.author.first_name}
            lastName={props.author.last_name}
            diameter={30}
          />
          {showAuthorLine ? (
            <div className="workspace__comment-line"></div>
          ) : null}
        </div>
        <div className="workspace__entry-author-name">
          {props.author.first_name} {props.author.last_name}
        </div>
      </div>
    );
  }

  // if the delete icon is clicked, we instead show the delete confirmation
  if (isConfirmDelete) {
    childNode = (
      <div className="workspace__entry-body">
        <ConfirmDelete
          title={props.title || 'Note'}
          delete={() =>
            props.deleteEntry({
              storyId: props.story_id,
              entryId: props.id,
              index: props.index,
            })
          }
          cancel={() => setConfirmDelete(false)}
        />
      </div>
    );
  }

  // set up the comment form if adding a new comment
  let addCommentForm;
  if (commentBoxOpen) {
    addCommentForm = (
      <EditCommentContainer
        isEdit={false}
        user={props.user}
        pageUser={props.pageUser}
        entryId={props.id}
        storyId={props.story_id}
        addComment={addComment}
        cancelComment={handleCancelAddComment}
        canEditAuthor={props.canEditAuthor}
        setErrorAlert={props.setErrorAlert}
      />
    );
  }

  // set up the goal tags for the entry
  // A blank div ensures the placement of the edit icon remains at the right place without editing other CSS flex rules
  const reflectionExists = !!props.highlighted;
  let goalsNode =
    props.type !== 'meeting_note' ? (
      <EntryTags
        goals={props.goals}
        userGoals={props.userGoals}
        selectedGoalIds={props.goalIds}
        toggleGoal={toggleGoalTag}
        canEdit={props.canEdit && !reflectionExists}
      />
    ) : (
      <div className="workspace__blank-placeholder"></div>
    );
  // don't show goal tags if the entry is a completed_goal
  if (props.type === 'completed_goal' && props.focus_area) {
    goalsNode = null;
    // if the completed_goal has a focus area, display that instead
    if (props.focus_area) {
      goalsNode =
        isConfirmDelete || editFormVisible ? null : (
          <div className="workspace__tag-group">
            <DomainTag
              key={props.focus_area.id}
              {...props.focus_area}
              label={props.focus_area.title}
            />
          </div>
        );
    }
  }

  // hightlighting

  // set up icons for commenting/highlighting/editing the entry
  const starIconClass = props.highlighted
    ? 'tc-v5-star-solid filled'
    : 'tc-v5-star-empty';
  const highlightedReadOnlyText =
    'This is highlighted for your administrator! Keep it up!';

  let highlightIconNode = props.highlighted ? (
    <Tooltip tooltip={highlightedReadOnlyText} minWidth={180} offsetLeft={-4}>
      <span
        className={`workspace__action-icon disabled ${starIconClass}`}
      ></span>
    </Tooltip>
  ) : null;

  if (props.canHighlight && !props.isEditing && props.canEditHighlights) {
    let highlightTitle = 'Highlight';
    let highlightIconAction = props.highlightEntry;

    if (props.highlighted) {
      if (props.type === 'self_assessment_progress') {
        highlightTitle = 'Remove Highlight';
        highlightIconAction = props.unhighlightEntry;
      } else {
        highlightTitle = 'Edit Highlight';
        highlightIconAction = props.editHighlightEntry;
      }
    }

    highlightIconNode = (
      <span
        onClick={highlightIconAction}
        title={highlightTitle}
        className={`workspace__action-icon ${starIconClass}`}
      ></span>
    );
  }

  let editIconNode;
  let iconsRowClass = ' workspace__action-icons-row--single-icon';
  // hide the action icons if the current user cannot make edits
  let commentIconNode;

  if ((props.canEdit || props.type === 'event_session') && !props.isEditing) {
    iconsRowClass = '';
    // commentIconNode = (
    //     <div className="workspace__action-icon tc-v5-comment"
    //         title="Add Comment"
    //         onClick={handleStartAddComment}></div>
    // );

    // only show the edit icon if the current user is allowed to edit any entry,
    // or if the entry has no author, or they are the author of this entry
    editIconNode = canEditEntryContents ? (
      <div
        className="workspace__action-icon tc-v4-pencil"
        title="Edit"
        onClick={handleStartEdit}
      ></div>
    ) : null;
  }
  const actionsNode = !props.disableEntryActions ? (
    <div className={`workspace__action-icons-row ${iconsRowClass}`}>
      {commentIconNode}
      {highlightIconNode}
      {editIconNode}
    </div>
  ) : null;
  // if you are editing the entry or adding a comment, you can't access any other actions
  const footerNode =
    editFormVisible || commentBoxOpen ? null : (
      <div
        className={`workspace__entry-footer ${
          props.footerClass ? props.footerClass : ''
        }`}
      >
        {goalsNode}
        {actionsNode}
      </div>
    );

  return (
    <div
      className={`workspace__entry ${entryClass}`}
      id={`entry-${props.id}`}
      ref={ref}
    >
      {childNode}
      {editLinkNode}
      {authorNode}
      <div className="workspace__entry-body">
        {/*expandNode*/}
        {/*commentsNode*/}
        {addCommentForm}

        {footerNode}
      </div>
    </div>
  );
};

EntryContainer.propTypes = {
  type: PropTypes.string.isRequired,
  comments: PropTypes.array,
  highlightEntry: PropTypes.func,
  unhighlightEntry: PropTypes.func,
  deleteEntry: PropTypes.func,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  canEdit: PropTypes.bool,
  canEditAuthor: PropTypes.bool,
  author: PropTypes.object,
  user: PropTypes.object.isRequired,
  noBorder: PropTypes.bool,
  hideAuthor: PropTypes.bool,
  setErrorAlert: PropTypes.func,
  userGoals: PropTypes.array.isRequired,
  allDomains: PropTypes.array.isRequired,
  disableEntryActions: PropTypes.bool,
  canHighlight: PropTypes.bool,
  footerClass: PropTypes.string,
};

export default EntryContainer;
