// @ts-nocheck FIXME
import UserApi from '@legacy-components/apis/UserApi';
import Utils from '@legacy-components/components/DynamicUtils';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import ProfileImageUpload from '@legacy-components/react/V4/Common/ProfileImageUpload';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import HeaderStore from '@legacy-components/stores/V4/HeaderStore';
import { useState } from 'react';

const ProfileImageUploadContainer = (props) => {
  const [uploadMessage, setUploadMessage] = useState({});
  const [uploadLoading, setUploadLoading] = useState({
    status: null,
    message: null,
  });

  // handle progress bar behavior
  const updateProfileImageUploadProgress = (fileData) => {
    setUploadLoading({
      status: fileData.status,
      message: fileData.message,
    });
  };

  // update the user photo with the newly updated image
  const updateUserPhoto = (data) => {
    UserApi.updateUserImage(data)
      .then((response) => {
        props.setUserPhoto(response.file);
        setUploadMessage({});

        setUploadLoading({
          status: null,
          message: null,
        });

        // update the header image if the user uploading the image is the current logged in user
        if (props.user.id === props.pageUser.id) {
          // update v4 header
          HeaderStore.actionDispatch('updateProfileImage', {
            photo: response.file,
          });

          // update v5 header
          const userContext = Utils.clone(props.headerUser);
          userContext['photo'] = response.file;
          props.setUser(userContext);
        }
      })
      .catch((response) => {
        setUploadMessage({
          type: 'error',
          message:
            'Sorry, there was a problem uploading the image. Please try again later.',
        });
      });
  };

  return (
    <div className="">
      <ProfileImageUpload
        user={props.user}
        participant={props.pageUser}
        updateProfileImageUploadProgress={updateProfileImageUploadProgress}
        afterUploadCallback={updateUserPhoto}
        uploadMessage={uploadMessage}
        setUploadMessage={(data) => {
          setUploadMessage({
            type: data.type,
            message: data.message,
          });
        }}
        clearUploadMessage={() => setUploadMessage({})}
        uploadLoading={uploadLoading}
        className="settings__user-image-container"
      >
        <UserPhoto
          src={props.userPhoto}
          userId={props.pageUser.id}
          firstName={props.pageUser.first_name}
          lastName={props.pageUser.last_name}
          diameter={75}
        />
      </ProfileImageUpload>
    </div>
  );
};
const ConnectedImageUpload = (props) => (
  <HeaderConsumer>
    {({ setUser, user }) => (
      <ProfileImageUploadContainer
        {...props}
        headerUser={user}
        setUser={setUser}
      />
    )}
  </HeaderConsumer>
);
export default ConnectedImageUpload;
