// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import OccupationIcon from '@legacy-components/react/V5/Common/AuthRoleIcon';
import RowHideNode from '@legacy-components/react/V5/Common/ExpandedRowHide';
import ToolTip from '@legacy-components/react/V5/Common/Tooltip';
import Clipboard from 'clipboard';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const EventParticipantRow = (props) => {
  const gradeMap = {
    'pre-k': 'Pre-K',
    'kindergarten ': 'K',
    'first grade': '1st',
    'second grade': '2nd',
    'third grade': '3rd',
    'fourth grade': '4th',
    'fifth grade': '5th',
    'sixth grade': '6th',
    'seventh grade': '7th',
    'eighth grade': '8th',
    'ninth grade': '9th',
    'tenth grade': '10th',
    'eleventh grade': '11th',
    'twelfth grade': '12th',
  };
  const [expandRow, setExpandRow] = React.useState(false);

  // state variables
  const groupRef = React.useRef();

  const [clipboard, setClipboard] = React.useState('');

  // state effects
  React.useEffect(() => {
    setClipboard(
      new Clipboard('.copy-email-to-clipboard', {
        text: function (trigger) {
          return trigger.getAttribute('copytext');
        },
      })
    );

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, []);

  const changeGroup = () => {
    props.handleUpdateGroups({
      userId: props.eventParticipant.user_id,
      participantId: props.eventParticipant.participant_id,
      newGroupId: groupRef.current.value,
      index: props.eventParticipant.index,
    });
  };

  const handleCopyToClipboard = () => {
    props.setAlert({
      type: 'success',
      message: 'Attendee Email copied to clipboard',
    });
  };

  // variables
  const participant = props.eventParticipant;
  const name = participant.user_first_name + ' ' + participant.user_last_name;

  let photoNode = null;

  /* Name */
  if (participant.photo) {
    // Set up user photo instead of the default icon if set
    photoNode = (
      <UserPhoto
        src={participant.photo}
        diameter={40}
        hasBorder={false}
        className="event__photo-icon"
      />
    );
  } else {
    // Use the participant's name and user ID so that two users with the same name don't use the same color
    // Then convert each letter to a char code, sum and take the modulus so the same user is always the same color
    const participantName =
      participant.user_first_name +
      ' ' +
      participant.user_last_name +
      participant.user_id;
    const index = participantName
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc);

    const colors = [
      '#004493',
      '#1A7CD9',
      '##2196F3',
      '#0B8484',
      '#0AABB1',
      '#53E6EB',
      '#53E6EB',
      '#107D54',
      '#33B55F',
      '#2ACAA1',
      '#25E8C8',
      '#FF6B2A',
      '#F4AB2A',
    ];

    const iconStyles = {
      backgroundColor: colors[index % colors.length],
    };
    /* Name */
    photoNode = (
      <div
        style={iconStyles}
        className="admin-v5_coach-dashboard_default-icon event__photo-icon"
      >
        <span>
          {participant.user_first_name.substr(0, 1)}
          {participant.user_last_name.substr(0, 1)}
        </span>
      </div>
    );
  }

  // check to see if reserved
  let isReservedNode = null;
  if (participant.reserved) {
    const reserved = moment(participant.reserved_formatted).tz(
      props.eventTimezone
    );
    isReservedNode = (
      <ToolTip
        tooltip={`${reserved.format('MMMM D, YYYY')}\n${Utils.hourRounder(
          reserved
        )} ${reserved.format('A z')}`}
        offsetLeft={10}
        prewrap={true}
        className="tc-v5-complete-outline event__attendee-rsvp-cell"
      >
        <span className="path1"></span>
        <span className="path2"></span>
      </ToolTip>
    );
  }

  let groupField = null;
  if (props.groups && props.groups.length) {
    const groupIndex = Utils.getItemIndexFromArrayOfObjects(
      props.groups,
      'id',
      parseInt(participant.group_id)
    );

    // set the name of the group if available
    groupField = (
      <div className={`${props.groupClass} event__participant-cell`}>
        {groupIndex !== -1 ? props.groups[groupIndex].name : ''}
      </div>
    );

    // there is no group for this row, so the table cell is empty
  }
  if (props.editingGroups) {
    const groupOptions = props.groups.map((group, i) => {
      return (
        <option
          key={`participant-group-option-${group.id}-${i}`}
          value={group.id}
        >
          {group.name}
        </option>
      );
    });
    // render the groups dropdown for participant
    groupField = (
      <div className={`${props.groupClass} event__participant-cell`}>
        <select
          ref={groupRef}
          defaultValue={participant.group_id}
          className="v5__select event__participants-groups tc__select"
          onChange={changeGroup}
        >
          <option key="participant-group-option-none" value=""></option>
          {groupOptions}
        </select>
      </div>
    );
  }

  const eventSessionsNode = props.eventSessions.map((eventSessionNum, i) => {
    let sessionRegistered = null;
    if (participant.event_sessions) {
      participant.event_sessions.forEach((session) => {
        if (eventSessionNum === props.eventSessionsMap[session.session_id]) {
          const currentSession = participant.event_sessions.find(
            (eventSession) => eventSession.session_id === session.session_id
          );
          const registered = moment(currentSession.registered_formatted).tz(
            props.eventTimezone
          );

          sessionRegistered = (
            <ToolTip
              tooltip={`${registered.format(
                'MMMM D, YYYY'
              )}\n${Utils.hourRounder(registered)} ${registered.format('A z')}`}
              offsetLeft={10}
              prewrap={true}
              className="tc-icon_status-done"
            >
              <span className="path1"></span>
              <span className="path2"></span>
            </ToolTip>
          );
        }
      });
    }

    return (
      <div
        className={`${props.sessionClass} event__participant-cell event__participant-session-cell`}
        key={`participant-event-session-${i}`}
      >
        <div className="admin-v5__session-icon">{sessionRegistered}</div>
      </div>
    );
  });

  const subjects = participant.subjects.map((subject) => (
    <div key={subject} className="admin-v5__participant-subjects">
      {subject}
    </div>
  ));

  // Ell and Special Ed have unique names for each setting
  const ellMap = {
    1: 'EL - somewhat',
    2: 'EL - yes',
    3: 'EL - yes',
  };
  const spedMap = {
    1: 'Sped - inclusion',
    2: 'Sped - self-contained',
  };
  if (participant.ell) {
    subjects.push(
      <div key="ell" className="admin-v5__participant-subjects">
        {ellMap[participant.ell]}
      </div>
    );
  }
  const spedValue = spedMap[participant.sped];
  if (spedValue) {
    subjects.push(
      <div key="sped" className="admin-v5__participant-subjects">
        {spedValue}
      </div>
    );
  }

  const subjectsNeedExpand = participant.subjects.length > 3;
  const handleExpandSubjects = () => {
    if (subjectsNeedExpand) {
      setExpandRow(true);
    }
  };
  const moreSubjectsNode = subjectsNeedExpand && (
    <div className="v5__link">+{participant.subjects.length - 3} more</div>
  );
  const subjectsNode = expandRow ? (
    <React.Fragment>{subjects}</React.Fragment>
  ) : (
    <React.Fragment>
      {subjects.slice(0, 3)}
      {moreSubjectsNode}
    </React.Fragment>
  );

  // users who are only Help Desk Agents cannot access the Workspace or delete attendees
  const hasNonAgentRole = Utils.arrayContainsAnyItemInAnotherArray(
    props.user.auth_roles,
    ['ADMIN', 'BL_MANAGER', 'BL_SERVICES_MANAGER', 'BL_CONTRACT_COACH']
  );

  // since they cannot click any of the icons, we just hide the column
  let iconsColNode;
  if (hasNonAgentRole) {
    // only show the link to the user's workspace if they have an active participant record
    const workspaceLink = participant.participant_id ? (
      <NavigationLink
        url={`/lab/home/${participant.user_id}`}
        title={`${name}'s workspace`}
        className="tc-icon_home admin-table__icon v5__link green"
      />
    ) : null;

    // only show the delete icon if the user is not editing other things
    let deleteIcon;
    if (!props.editingGroups) {
      deleteIcon = (
        <span
          data-testid={`${props.eventParticipant.user_id}-delete-icon`}
          title="Delete from event"
          className="tc-delete admin-table__icon v5__link green"
          onClick={() =>
            props.openConfirmDeleteEventAttendeeModal(props.eventParticipant)
          }
        ></span>
      );
    }

    iconsColNode = (
      <div
        className={`${props.iconsClass} event__participant-cell event__participant-name-profile-cell event__attendee-actions-cell`}
      >
        {workspaceLink}
        {deleteIcon}
      </div>
    );
  }
  const gradesNeedExpand = participant.grades.length > 4;
  const handleExpandGrades = () => {
    if (gradesNeedExpand) {
      setExpandRow(true);
    }
  };
  const moreGradesNode = gradesNeedExpand && (
    <div className="v5__link">+{participant.grades.length - 3} more</div>
  );
  const gradesNode =
    expandRow || !gradesNeedExpand ? (
      participant.grades.map((grade) => (
        <div key={grade} className="admin-v5__participant-grades">
          {gradeMap[grade.toLocaleLowerCase()]}
        </div>
      ))
    ) : (
      <React.Fragment>
        {participant.grades.slice(0, 3).map((grade) => (
          <div key={grade} className="admin-v5__participant-grades">
            {gradeMap[grade.toLocaleLowerCase()]}
          </div>
        ))}
        {moreGradesNode}
      </React.Fragment>
    );
  const hideNode = expandRow && (
    <RowHideNode hideRow={() => setExpandRow(false)} />
  );
  const expandedClass = expandRow ? 'expanded' : '';
  return (
    <div className={`grid-big__row event__participant-row ${expandedClass}`}>
      <div
        className={`${props.nameClass} event__participant-name-profile-cell event__participant-cell`}
      >
        {photoNode}
        <div className="event__participant-name-cell">
          <div className="admin-v5__participant-name">{name}</div>
          <div className="event__email-text">
            <a
              className="copy-email-to-clipboard v5__body-copy event__participant-email clickable"
              copytext={participant.user_email}
              onClick={handleCopyToClipboard}
            >
              {participant.user_email}
            </a>
          </div>
        </div>
      </div>
      <div
        className={`${props.schoolClass} event__participant-cell event__participant-name-profile-cell`}
      >
        <OccupationIcon
          role={participant.role}
          className={'event__participant-occupation'}
        />
        {participant.institution}
      </div>
      <div
        className={`${props.gradeClass} event__participant-cell`}
        onClick={handleExpandGrades}
      >
        {gradesNode}
      </div>
      <div
        className={`${props.subjectClass} event__participant-cell`}
        onClick={handleExpandSubjects}
      >
        {subjectsNode}
      </div>
      {groupField}
      <div
        className={`${props.rsvpClass} event__participant-cell event__participant-session-cell`}
      >
        <div className="admin-v5__session-icon">{isReservedNode}</div>
      </div>
      {eventSessionsNode}
      {iconsColNode}
      {hideNode}
    </div>
  );
};

EventParticipantRow.propTypes = {
  eventParticipant: PropTypes.object.isRequired,
  groups: PropTypes.array,
  editingGroups: PropTypes.bool,
  eventSessions: PropTypes.array,
  eventSessionsMap: PropTypes.object,
  user: PropTypes.object.isRequired,
  handleUpdateGroups: PropTypes.func.isRequired,
  setSuccessAlert: PropTypes.func.isRequired,
  openConfirmDeleteEventAttendeeModal: PropTypes.func.isRequired,
  nameClass: PropTypes.string.isRequired,
  schoolClass: PropTypes.string.isRequired,
  gradeClass: PropTypes.string.isRequired,
  subjectClass: PropTypes.string.isRequired,
  groupClass: PropTypes.string.isRequired,
  rsvpClass: PropTypes.string.isRequired,
  iconsClass: PropTypes.string.isRequired,
  sessionClass: PropTypes.string.isRequired,
};

export default EventParticipantRow;
