// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const Row = (props) => {
  const handleActionClick = (e, action) => {
    e.preventDefault();
    const data = props.school;
    data.mode = action;
    props.schoolModal.open(data);
  };

  return (
    <tr className="user-search-table__row">
      <td className="admin-v2__user-table-col">{props.school.name}</td>
      <td className="admin-v2__user-table-col">
        {props.school.mailing_zipcode}
      </td>
      <td className="admin-v2__user-table-col">
        {props.school.number_of_participants}
      </td>
      <td className="admin-v2__user-table-col">
        <div className="admin-v2__user-align-center">
          <a
            className="tc__link"
            onClick={(e) => handleActionClick(e, 'approve')}
          >
            Approve
          </a>
          &nbsp;&nbsp;
          <a className="tc__link" onClick={(e) => handleActionClick(e, 'edit')}>
            Edit
          </a>
          &nbsp;&nbsp;
          <a
            className="tc__link"
            onClick={(e) => handleActionClick(e, 'combine')}
          >
            Combine
          </a>
        </div>
      </td>
    </tr>
  );
};

Row.propTypes = {
  school: PropTypes.object.isRequired,
  approve: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  schoolModal: PropTypes.object.isRequired,
};

export default Row;
