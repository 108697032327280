// @ts-nocheck FIXME
import React from 'react';
import Utils from './../../../components/DynamicUtils';

class ScrollToEntry extends React.Component {
  componentDidMount() {
    // delay to make sure the page is loaded and browser does not scroll us elsewhere afterward
    window.setTimeout(() => {
      // get scroll position of entry
      const entryOffset = Utils.getAbsolutePosition(
        document.getElementById('entry_' + this.props.id)
      )[1];

      // scroll (minus 25 so it shows the whole capsule)
      document.documentElement.scrollTop = document.body.scrollTop =
        entryOffset - 25;
    }, 200);
  }

  render() {
    return null;
  }
}

export default ScrollToEntry;
