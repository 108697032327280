// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ResourceCapsule = (props) => {
  const [backgroundImageClass, updateBackground] = useState({});

  useEffect(() => {
    const preview =
      props.lessonfile &&
      props.lessonfile.connections &&
      props.lessonfile.connections.preview;
    if (preview) {
      const image = new Image();
      image.src = preview;
      image.onload = () => {
        updateBackground({ backgroundImage: `url("${preview}")` });
      };
    }
  }, []);

  const resultLink =
    process.env.REACT_APP_TEACHING_URL +
    (props.type === 'curated_strategy'
      ? `/strategy/${props.strategy_id}`
      : `/lesson/${props.lesson_id}`);
  const courseTitle = props.course_name ? (
    <div className="home-v5__favorites-course-title v5__heading-6">
      {props.course_name}
    </div>
  ) : null;

  return (
    <div className="home-v5__favorites-card-container highlights__resource">
      <NavigationLink className="v5__link" url={resultLink}>
        <div className="home-v5__favorites-card" style={backgroundImageClass}>
          <div className="home-v5__favorite-link-text v5__heading-4">
            {props.title}
            {courseTitle}
          </div>
        </div>
      </NavigationLink>
    </div>
  );
};

ResourceCapsule.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  course_name: PropTypes.string,
  lessonfile: PropTypes.object.isRequired,
};

export default ResourceCapsule;
