// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import Slider from '@legacy-components/react/V5/Common/Carousel';
import CarouselButtonContainer from '@legacy-components/react/V5/Common/CarouselButtonContainer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Highlight from './Highlight';

const HighlightCarousel = (props) => {
  const lab = useLegacyLabContext();
  const [buttonSelected, updateButtonSelected] = useState(0);

  const highlightCapsules = props.entries
    .filter((entry) => Utils.showPartnerLeaderDetails.call(lab, entry.teacher))
    .map((entry) => {
      return (
        <Highlight
          key={`highlight-${entry.id}`}
          reportingPhases={props.reportingPhases}
          {...entry}
        />
      );
    });

  if (highlightCapsules.length) {
    return (
      <React.Fragment>
        <div className="v5__heading-3 partner_lead__header-container">
          Recent Highlights
        </div>
        <div className="pl-v5__highlights-carousel">
          <Slider
            slideSelected={buttonSelected}
            updateButton={updateButtonSelected}
            slideStep={1}
            uniqueKey={'highlights-carousel'}
            classType="pl-v5__highlights-slides"
            hideInactiveSlides={true}
          >
            {highlightCapsules}
          </Slider>
        </div>
        <CarouselButtonContainer
          updateButton={updateButtonSelected}
          numButtons={highlightCapsules.length}
          buttonSelected={buttonSelected}
        />
      </React.Fragment>
    );
  }

  return null;
};

HighlightCarousel.propTypes = {
  entries: PropTypes.array.isRequired,
  reportingPhases: PropTypes.object,
};

export default HighlightCarousel;
