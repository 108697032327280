// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import PropTypes from 'prop-types';

const TabbedMenu = (props) => {
  const urlPrefix = props.urlPrefix || '/lab/home';

  const tabbedMenu = (
    <div className="v5__tabbed-menu-bar">
      {props.tabs.map((tab) => {
        if (
          props.currentYear === 'past' &&
          (tab.name === 'engagement' || tab.name === 'registration')
        ) {
          return;
        }
        const btnProps = {};
        if (!tab.disabled) {
          btnProps.onClick = () => {
            Router.route(`${urlPrefix}/${tab.name}`);
          };
        }
        let tabName = tab.name;
        const isActive = props.active === tabName;
        if (tabName === 'updates' && props.currentYear === 'past') {
          tabName = 'learning domains';
        }
        const classes = `v5__tabbed-menu-item${
          tab.disabled ? ' disabled' : ''
        }${isActive ? ' active' : ''}`;
        return (
          <div key={`menubar-${tabName}`} className={classes} {...btnProps}>
            {tabName}
          </div>
        );
      })}
    </div>
  );

  return tabbedMenu;
};

TabbedMenu.propTypes = {
  tabs: PropTypes.array.isRequired,
  active: PropTypes.string,
  urlPrefix: PropTypes.string,
};

export default TabbedMenu;
