// @ts-nocheck FIXME
import Request from '@legacy-components/components/Request';

const EventCapacityCalendarApi = {
  getSessions: (timezoneId) => {
    return Request.get(`/api/bl/events/capacity-calendar/${timezoneId}`);
  },
};

export default EventCapacityCalendarApi;
