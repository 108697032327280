// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import ScaleCenterImage from './../../ScaleCenterImage';

class ArtifactImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viablePreview: false,
    };
  }

  componentDidMount() {
    const thisComponent = this;
    // don't attempt to check preview when no imgSrc supplied
    if (!this.props.preview) {
      return;
    }

    // create image instance
    const image = new Image();
    image.src = this.props.preview;

    // check if loads: return true
    image.onload = function () {
      thisComponent.setState({
        viablePreview: true,
      });
    };
  }

  // render the component that is passed in
  render() {
    if (!this.state.viablePreview) {
      return (
        <div className={this.props.containerClass}>
          <div
            className={
              this.props.imageClass +
              '-mask ' +
              this.props.imageClass +
              '--none'
            }
          >
            {!this.props.hideNoPreviewIcon && (
              <div
                className={
                  'strategy__preview-icon file-type-icon ext-' + this.props.icon
                }
              ></div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={this.props.containerClass}>
        <ScaleCenterImage
          src={this.props.preview}
          className={this.props.imageClass}
          alt={this.props.title}
          icon={this.props.icon}
        />
      </div>
    );
  }
}

ArtifactImage.propTypes = {
  containerClass: PropTypes.string,
  imageClass: PropTypes.string,
  hideNoPreviewIcon: PropTypes.bool,
};

export default ArtifactImage;
