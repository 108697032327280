// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';

const BlogCapsule = (props) => {
  // show only the first img inside the blog summary
  const blogImg = props.summary.match(/<img.+>/);
  const imgNode = blogImg ? (
    <PurifiedHTML
      href={props.link + '?from=participant_dashboard'}
      target="_blank"
      rel="noopener"
      className="home__blog-capsule-text"
      content={blogImg[0]}
      tagName="a"
    />
  ) : null;

  return (
    <div className="bl4__capsule">
      <h2 className="home__favorites-capsule-headline">
        Latest from BetterLesson&apos;s Blog
      </h2>

      <a
        href={props.link + '?from=participant_dashboard'}
        target="_blank"
        rel="noopener noreferrer"
        className="home__blog-capsule-title tc__link tc__link--black"
      >
        {props.title}
      </a>
      <div className="home__blog-capsule-timestamp">
        <TimeAgo date={props.published} />
      </div>

      {imgNode}

      <div className="home__blog-capsule-btn">
        <a
          href={props.link + '?from=participant_dashboard'}
          target="_blank"
          rel="noopener noreferrer"
          className="bl4__btn bl4__btn--blue"
        >
          Read Article
        </a>
      </div>
    </div>
  );
};

BlogCapsule.propTypes = {
  link: PropTypes.string.isRequired,
  published: PropTypes.instanceOf(Date),
  summary: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BlogCapsule;
