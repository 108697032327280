// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

class CKEditor extends React.Component {
  constructor(props) {
    super(props);
    // Keep a copy of the editor to be used in various lifecycle functions
    this.editor = null;
    this.editorId = null;
    this.state = {
      focused: false,
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.setReadOnlyMode = this.setReadOnlyMode.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    // resolving scope issues for onload
    const editorId = this.editorId;
    const props = this.props;

    // create script object
    const script = document.createElement('script');
    script.src = '/legacy-libs/ckeditor/ckeditor.js?cb=3';

    // load file after component mounts to display the editor
    script.onload = function () {
      const component = this;

      // set editor to local scope
      this.editor = CKEDITOR;

      // create the editor
      CKEDITOR.replace(props.className + '-' + editorId, {
        customConfig: props.configs,
        removePlugins: 'contextmenu,liststyle,tabletools,elementspath',
      });

      // convience variable
      const editorInstance =
        this.editor.instances[this.props.className + '-' + this.editorId];

      // Add the focus handler as soon as the editor is ready
      editorInstance.on('contentDom', function (instance) {
        const editor = instance.editor;
        const editable = editor.editable();

        component.setReadOnlyMode(component.props.disabled);
        document
          .querySelectorAll('.tc__cke-loading')
          .forEach((el) => (el.style.display = 'none'));

        editable.attachListener(editable, 'focus', component.handleFocus);
        editable.attachListener(editable, 'blur', component.handleBlur);
      });

      editorInstance.on('contentDomUnload', function (instance) {
        const editor = instance.editor;
        const editable = editor.editable();

        editable.removeListener(editable, 'focus', component.handleFocus);
        editable.removeListener(editable, 'blur', component.handleBlur);
      });
    }.bind(this);

    // append the script to the header
    document.head.appendChild(script);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setReadOnlyMode(this.props.disabled);
  }

  componentWillUnmount() {
    // convience variable
    const editorInstance =
      this.editor.instances[this.props.className + '-' + this.editorId];

    // blur the editor to prevent lingering focus event listener
    editorInstance.focusManager.blur(true);

    // remove instance of the editor
    editorInstance.destroy();
  }

  handleFocus() {
    // set state for blue border
    this.setState({ focused: true });

    // Call the parent's focus handler
    if (
      this.props.handleFocus &&
      typeof this.props.handleFocus === 'function'
    ) {
      this.props.handleFocus();
    }
  }

  handleBlur() {
    // set state for no border
    this.setState({ focused: false });
  }

  setReadOnlyMode(disabled) {
    if (
      this.editor &&
      this.editor.instances &&
      this.editor.instances[this.props.className + '-' + this.editorId]
    ) {
      this.editor.instances[
        this.props.className + '-' + this.editorId
      ].setReadOnly(disabled);
    }
  }

  handleUpdate() {
    // get the output for this editor instance
    const editorOutput =
      this.editor.instances[
        this.props.className + '-' + this.editorId
      ].getData();

    // create data object
    const data = {
      entryData: {
        type: 'text',
        mode: 'update',
        data: {
          text: editorOutput,
        },
      },
    };

    if (this.props.id) {
      data.entryId = this.props.id;
    } else if (this.props.sectionId) {
      data.sectionId = this.props.sectionId;
    }

    // use store method to update the db
    this.props.update(data);
  }

  render() {
    this.editorId = this.props.id + '-' + this.props.increment;

    let buttonNode = null;
    if (this.props.update) {
      buttonNode = (
        <button
          onClick={this.handleUpdate}
          className={'ppt-element__btn tc__btn tc__btn--small tc__btn--blue'}
        >
          {this.props.id ? 'Update' : 'Add'}
        </button>
      );
    }

    const focusedClass = this.state.focused
      ? ' tc__cke-container--focused'
      : '';
    return (
      <div
        className={'tc__cke-container ' + this.props.className + focusedClass}
      >
        <div className="tc__cke-loading"></div>
        <textarea
          className="tc__no-show"
          name={this.props.className + '-' + this.editorId}
          id={this.props.className + '-' + this.editorId}
          rows="10"
          cols="80"
          defaultValue={this.props.innerText}
        ></textarea>
        {buttonNode}
      </div>
    );
  }
}

CKEditor.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

CKEditor.defaultProps = {
  disabled: false,
};

export default CKEditor;
