// @ts-nocheck FIXME
import CoachSurveysApi from '@legacy-components/apis/V5/CoachSurveysApi';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import moment from 'moment';
import propTypes from 'prop-types';
import React from 'react';
import ScoreCard from './ScoreCard';
import SurveysTable from './SurveysTable';

const Surveys = (props) => {
  // default 90 days to show
  const [daysRange, setDaysRange] = React.useState(90);

  // keep track of days that have been already fetched so we don't over-fetch
  const [fetchedDays, setFetchedDays] = React.useState(90);

  // show error placeholder in case elasticsearch failed
  const [searchFailed, setSearchFailed] = React.useState(props.search_failed);

  const [isLoading, setIsLoading] = React.useState(false);

  // stores all fetched session surveys
  const [sessionSurveys, setSessionSurveys] = React.useState(
    props.session_surveys ? props.session_surveys : []
  );

  // stores session surveys to show based on days range
  const [sessionSurveysToShow, setSessionSurveysToShow] = React.useState(
    props.session_surveys ? props.session_surveys : []
  );

  // modify sessions array based on selected days range
  const filterSessionsByDays = (daysRange) => {
    const startingDay = moment().subtract(daysRange, 'days');
    return sessionSurveys.filter((session) =>
      moment(session.session_start_time).isSameOrAfter(startingDay, 'days')
    );
  };

  const handleChangeShowRange = (daysRange) => {
    // value in options tag is string, convert to int first
    const daysRangeInt = parseInt(daysRange);

    // only fetch from API when requested range is more than what frontend has, or search failed last time
    if (daysRangeInt > fetchedDays || searchFailed) {
      fetchSessionSurveys(daysRangeInt);
      setFetchedDays(daysRangeInt);
    } else {
      // other wise just filter local data
      setSessionSurveysToShow(filterSessionsByDays(daysRangeInt));
    }
    setDaysRange(daysRangeInt);
  };

  const fetchSessionSurveys = (daysRange) => {
    setIsLoading(true);
    CoachSurveysApi.getSessionSurveys({
      coachId: props.pageUser.id,
      days: daysRange,
    })
      .then((response) => {
        if ('search_failed' in response) {
          setSearchFailed(true);
        } else if ('session_surveys' in response) {
          setSearchFailed(false);
          setSessionSurveys(response.session_surveys);
          setSessionSurveysToShow(response.session_surveys);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  // when ES search is down, we show a graphic
  const searchFailedPlaceHolder = (
    <div className="coach-surveys__search-failed">
      <img
        src="/legacy-images/404-pets.png"
        className="code-404__img-overview"
      />
      <div>
        <div className="v5__heading-4 coach-surveys__search-failed-heading">
          Oops! Something is broken.
        </div>
        <div className="v5__comment-copy">
          <div>User data is temporarily unavailable. (We blame the cat.)</div>
          <div>Thanks for your patience as we tidy up this issue.</div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="admin-v5_coach-dashboard--section-heading">
        <h3 className="v5__heading-3">Event Surveys</h3>
      </div>

      {searchFailed ? (
        searchFailedPlaceHolder
      ) : (
        <>
          <div className="coach-surveys__select-container">
            <label className="coach-surveys-label">Show:</label>
            <FormField
              type="select"
              onChange={(e) => handleChangeShowRange(e.target.value)}
              defaultValue={daysRange}
              className="coach-surveys__select"
              disabled={isLoading}
              data-testid="select"
            >
              <option key="week" value="7">
                Week
              </option>
              <option key="month" value="30">
                Month
              </option>
              <option key="3-months" value="90">
                3 months
              </option>
              <option key="6-months" value="180">
                6 months
              </option>
              <option key="year" value="365">
                Year
              </option>
            </FormField>
            <span className="tc-bold-arrow"></span>
            <Spinner loading={isLoading} />
          </div>

          <ScoreCard data={sessionSurveysToShow} />
          <SurveysTable data={sessionSurveysToShow} />
        </>
      )}
    </div>
  );
};

const ConnectedSurveys = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => <Surveys {...props} setAlert={setAlert} />}
  </HeaderConsumer>
);

Surveys.propTypes = {
  pageUser: propTypes.object,
  session_surveys: propTypes.array,
  search_failed: propTypes.bool,
};

export default ConnectedSurveys;
