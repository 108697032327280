// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import CircleCapsule from '../modules/CircleCapsule';
import EngagementTable from './EngagementTable';

const EngagementTab = (props) => {
  // values for circle capsules (registered, scheduled, completed, engaged)
  // --> taken from engagement_stats
  const values = [
    {
      count: props.engagement_stats.registered,
      total: props.engagement_stats.capacity,
      label: 'registered',
    },
    {
      count: props.engagement_stats.scheduled,
      total: props.engagement_stats.capacity,
      label: 'scheduled first meeting',
    },
    {
      count: props.engagement_stats.completed,
      total: props.engagement_stats.capacity,
      label: 'completed first meeting',
    },
    {
      count: props.engagement_stats.engaged,
      total: props.engagement_stats.total_engaged,
      label: 'scheduled next meeting',
      showTooltip: true,
    },
  ];

  let bodyNode = (
    <React.Fragment>
      <div className="v5__heading-3 partner-lead-v5__engagement-header">
        Engagement Overview
      </div>
      <div className="partner_lead_v5__registration-stats">
        {values.map((value, i) => (
          <CircleCapsule key={`circle-graph-${i}`} {...value} />
        ))}
      </div>
      <EngagementTable cohorts={props.cohorts} />
    </React.Fragment>
  );

  if (props.search_failed) {
    bodyNode = (
      <React.Fragment>
        <div className="v5__registered-table-details v5__heading-5 row home-v5__empty-activity-capsule">
          <div>
            <img
              src="/legacy-images/404-pets.png"
              className="code-404__img-overview"
            />
          </div>
          <div className="home-v5__empty-activity-row">
            <div className="v5__heading-4">Oops! Something is broken.</div>
            <div className="v5__comment-copy">
              <div>
                Engagement data from your cohort(s) is temporarily unavailable.
                (We blame the cat.)
              </div>
              <div>Thanks for your patience as we tidy up this issue.</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="row row--1200 partner_lead_v5__overview">{bodyNode}</div>
  );
};

EngagementTab.propTypes = {
  cohorts: PropTypes.array.isRequired,
  engagement_stats: PropTypes.object.isRequired,
  search_failed: PropTypes.bool,
};

export default EngagementTab;
