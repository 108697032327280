// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Auth from '@legacy-components/components/Auth';
import Utils from '@legacy-components/components/DynamicUtils';
import { Router } from '@legacy-components/components/Router';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import HeaderStore from './../../../../stores/V4/HeaderStore';
import LessonLink from './../../../LessonLink';
import ScaleCenterImage from './../../../ScaleCenterImage';
import AbridgeText from './../../Common/AbridgeText';
import UserPhoto from './../../Common/UserPhoto';

const FeedResult = (props) => {
  const lab = useLegacyLabContext();
  // set up url for lesson or strategy
  let contentUrl =
    process.env.REACT_APP_TEACHING_URL +
    (props.type === 'strategy'
      ? '/strategy/' + props.id
      : '/lesson/' + props.id);
  if (props.seo_url) {
    contentUrl += '/' + props.seo_url;
  }
  contentUrl += '?from=home_feed_lesson_capsule';

  const handleCapsuleClick = (e) => {
    e.preventDefault();

    if (
      props.favorite &&
      (props.favorite.status === 'loading-favorite' ||
        props.favorite.status === 'loading-unfavorite')
    ) {
      return;
    }

    // determine if free content limit is reached
    let reachedFreeContentLimit = false;
    // if strategy and strategy is not open, then check if limit is reached
    if (props.type === 'strategy' && props.publish_status !== 'open') {
      reachedFreeContentLimit = Utils.reachedFreeContentLimit.call(
        lab,
        null,
        parseInt(props.id)
      );
    } else if (props.type === 'lesson') {
      reachedFreeContentLimit = Utils.reachedFreeContentLimit.call(
        lab,
        parseInt(props.id),
        null
      );
    }

    // if the logged out user has reached the limit of 5 free content pages,
    // show the registration modal
    if (reachedFreeContentLimit) {
      if (!props.user || (props.user && !props.user.id)) {
        HeaderStore.actionDispatch('openRegisterModal', {
          reason: 'resource_count_reached',
        });
        return;
      }
    }

    Router.route(contentUrl);
  };

  const generateSubjectIcon = () => {
    let iconClass = '';
    let colorClass = '';
    if (props.type === 'lesson') {
      colorClass = ' search__result-icon--lesson';
      // find the first relevant subject in the lesson's list
      const subject = props.subjects.find((sub) => {
        return (
          ['Science', 'Math', 'English / Language Arts'].indexOf(sub.name) !==
          -1
        );
      });
      switch (subject ? subject.name : null) {
        case 'Science':
          iconClass = ' tc-v4-science';
          break;
        case 'Math':
          iconClass = ' tc-v4-math';
          break;
        case 'English / Language Arts':
          iconClass = ' tc-v4-ela';
          break;
        default:
          iconClass = ' tc-v4-ela';
      }
    } else if (props.type === 'strategy') {
      colorClass = ' search__result-icon--strategy';
      switch (props.sub_type) {
        case 'tech tool':
          iconClass = ' tc-v4-laptop';
          break;
        default:
          iconClass = ' tc-v4-strategy';
      }
    }

    return (
      <div className={'search__result-icon' + colorClass + iconClass}></div>
    );
  };

  // prevent multiple clicks
  const handleFavoriteLoadingClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const handleAddFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    props.addFavorite({ itemType: props.type, itemId: props.id });
  };

  const handleRemoveFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    props.removeFavorite({
      itemType: props.type,
      itemId: props.id,
      userId: props.favorite.user_id,
    });
  };

  const getAuthorNode = () => {
    if (props.author) {
      // set up author photo
      const authorPhotoNode = (
        <UserPhoto
          src={props.author.photo}
          className="feed__owner-image search__owner-image"
          diameter={50}
        />
      );

      // author data depends on lesson vs strategy
      let authorNode = null;
      switch (props.type) {
        case 'strategy':
          authorNode = (
            <div className="feed__owner">
              {authorPhotoNode}
              <div className="feed__owner-details search__owner-details">
                <div>{props.author.name}</div>
                <div>
                  {props.author.role == null
                    ? 'BetterLesson Instructional Coach'
                    : props.author.role}
                </div>
              </div>
            </div>
          );
          break;
        case 'lesson':
          authorNode = (
            <div className="feed__owner">
              {authorPhotoNode}
              <div className="feed__owner-details search__owner-details">
                <div>{props.author.name}</div>
                <div>{props.author.environment} Env.</div>
              </div>
            </div>
          );
          break;
      }

      return authorNode;
    }
    // strategies without authors use this default data
    if (props.type === 'strategy') {
      return (
        <div className="search__owner search__owner--coach search__detail show-desktop hide-phone">
          <UserPhoto
            src="/legacy-images/bl-icon-circle.png"
            className="search__owner-image"
            diameter={50}
          />
          <div className="search__owner-details">BetterLesson Coaching</div>
        </div>
      );
    }
  };

  let resultImageNode = null;
  let detailsClass = '';

  const ownerNode = getAuthorNode();

  if (props.preview) {
    detailsClass = ' bl4-capsule__details--has-image';
    resultImageNode = (
      <div className="show-desktop hide-phone bl4-capsule__image-container">
        <ScaleCenterImage src={props.preview} className="bl4-capsule__image" />
      </div>
    );
  }
  let subHeadNode = null;
  let summaryDeclarationNode = null;
  if (props.type === 'lesson') {
    subHeadNode = (
      <div className="bl4-capsule__subheadline">
        <div className="bl4-capsule__subheadline-segment bl4-capsule__subheadline-segment--first">
          {props.course_name}
        </div>
        <div className="bl4-capsule__subheadline-segment">&raquo; Unit:</div>
        <div className="bl4-capsule__subheadline-segment">
          {props.unit_name}
        </div>
      </div>
    );
    summaryDeclarationNode = (
      <span className="search__summary-declaration">Big Idea:</span>
    );
  }

  let handleFavorteClick = handleAddFavorite;
  let favoriteIcon = (
    <div
      className="tc-v4-heart-empty"
      alt="Add to Favorites"
      title="Add to Favorites"
    ></div>
  );
  let dissableFavClass = '';
  let capsuleFavsLoadingClass = '';
  if (props.favorite) {
    if (props.favorite.status === 'loading-favorite') {
      capsuleFavsLoadingClass = ' favs-updating';
      handleFavorteClick = handleFavoriteLoadingClick;
      dissableFavClass = ' disabled';
      favoriteIcon = (
        <div
          className="tc-v4-heart-filled"
          alt="Remove Favorite"
          title="Remove Favorite"
        >
          loading fav
        </div>
      );
    }
    if (props.favorite.status === 'loading-unfavorite') {
      capsuleFavsLoadingClass = ' favs-updating';
      handleFavorteClick = handleFavoriteLoadingClick;
      dissableFavClass = ' disabled';
      favoriteIcon = (
        <div
          className="tc-v4-heart-empty"
          alt="Add to Favorites"
          title="Add to Favorites"
        >
          loading unfav
        </div>
      );
    }

    handleFavorteClick = handleRemoveFavorite;
    favoriteIcon = (
      <div
        className="tc-v4-heart-filled"
        alt="Remove Favorite"
        title="Remove Favorite"
      ></div>
    );
  }

  if (
    props.user.id !== props.currentUserId &&
    Auth.hasCoachAccess(props.user)
  ) {
    dissableFavClass = ' disabled';
  }

  let favoritesNode = null;
  if (props.user) {
    favoritesNode = (
      <div
        className={'bl4-feed__favorite-btn' + dissableFavClass}
        onClick={handleFavorteClick}
      >
        {favoriteIcon}
      </div>
    );
  }

  let footerNode = null;
  let capsuleWithFooterClass = '';
  if (ownerNode) {
    footerNode = (
      <div className="bl4-capsule__footer bl4-capsule__footer--feed">
        {ownerNode}
      </div>
    );
    capsuleWithFooterClass = ' bl4__capsule--result-with-footer';
  }

  // set up capsule title link
  let titleNode = (
    <LessonLink url={contentUrl} className="tc__link" lesson={props.id}>
      {props.title}
    </LessonLink>
  );
  if (props.type === 'strategy') {
    titleNode = (
      <LessonLink
        url={contentUrl}
        className="tc__link"
        strategy={props.id}
        publishStatus={props.publish_status}
      >
        {props.title}
      </LessonLink>
    );
  }

  return (
    <div
      className={
        'bl4-result bl4-result--feed bl4__capsule bl4__capsule--feed bl4-capsule--hoverable bl4__capsule--result bl4-result--' +
        props.type +
        capsuleFavsLoadingClass +
        capsuleWithFooterClass
      }
      onClick={handleCapsuleClick}
    >
      <div className="bl4-capsule__header bl4-capsule__header--feed">
        {generateSubjectIcon()}
        <div className="bl4-feed__favorites-caption">
          {props.favorite_count} teachers favorited a{' '}
          {props.type === 'lesson' ? 'Lesson' : 'Strategy'}
        </div>
        {favoritesNode}
      </div>
      <div className="bl4-capsule__content">
        <div className={'bl4-capsule__details' + detailsClass}>
          <AbridgeText maxHeight={190}>
            <h4 className="bl4-capsule__headline tc__headline-3">
              <div>
                {titleNode}
                <div className="bl4-capsule__subheadline bl4-capsule__mobile-user">
                  {props.author ? 'By ' + props.author.name : ''}
                </div>
                {subHeadNode}
              </div>
            </h4>

            <div className="search__summary tc__body-copy">
              {summaryDeclarationNode}
              <PurifiedHTML
                className="ckeditor__output"
                content={props.description}
                tagName="span"
              />
            </div>
          </AbridgeText>
        </div>
        {resultImageNode}
        {footerNode}
      </div>
    </div>
  );
};

const WrappedFeedResult = (props) => (
  <HeaderConsumer>
    {({ user }) => <FeedResult {...props} user={user} />}
  </HeaderConsumer>
);
export default WrappedFeedResult;

FeedResult.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  updateFilters: PropTypes.func,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  favorite: PropTypes.object,
  preview: PropTypes.string,
  course_name: PropTypes.string,
  unit_name: PropTypes.string,
  creator: PropTypes.object,
  user: PropTypes.object,
  title: PropTypes.string.isRequired,
};
