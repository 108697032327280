// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import MeetingRow from './Common/MeetingRow';

const Meetings = (props) => {
  const [sortedColumn, setSortedColumn] = React.useState(props.sortCol);
  const [sortDirection, setSortDirection] = React.useState('asc');

  const ascending = sortDirection === 'asc';
  const descending = sortDirection === 'desc';

  // @todo @jonathanrys move these into Utilities
  // Recursive function to get a nested value from an object based on a dot-separated string key
  const getValue = (baseObject, keys) => {
    if (keys.length) {
      return getValue(baseObject[keys.pop()], keys);
    }
    return baseObject;
  };

  // Wrapper function to sort on null values
  const sortWithNull = (func, key) => {
    // Allow nested values
    const accessors = key.split('.').reverse();

    return (a, b) => {
      if (getValue(a, [...accessors])) {
        if (getValue(b, [...accessors])) {
          return func(a, b);
        }
        return -1;
      }
      return 1;
    };
  };

  // Wrapper function to reverse sort on null values so that null always floats to the bottom
  const reverseSortWithNull = (func, key) => {
    // Allow nested values
    const accessors = key.split('.').reverse();

    return (a, b) => {
      if (getValue(a, [...accessors])) {
        if (getValue(b, [...accessors])) {
          return func(a, b);
        }
        return 1;
      }
      return -1;
    };
  };

  const nullSorter = ascending ? sortWithNull : reverseSortWithNull;

  const sortFunctions = {
    name: (a, b) => a.last_name.localeCompare(b.last_name),
    school: nullSorter(
      (a, b) => a.school_name.localeCompare(b.school_name),
      'school_name'
    ),
    'meetings-held': (a, b) => a.meeting_count - b.meeting_count,
    'last-meeting': nullSorter(
      (a, b) => moment(a.last_meeting) - moment(b.last_meeting),
      'last_meeting'
    ),
    'next-meeting': nullSorter(
      (a, b) => moment(a.next_meeting) - moment(b.next_meeting),
      'next_meeting'
    ),
  };

  // Sort order indication arrow
  let sortIndicator = null;

  if (ascending) {
    props.participants.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (descending) {
    props.participants.sort(sortFunctions[sortedColumn]);
    props.participants.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortOrder[sortDirection]);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };

  const meetingRows = props.participants.map((participant) => {
    return (
      <MeetingRow
        key={`event-${participant.id}`}
        currentYear={props.currentYear}
        {...participant}
      />
    );
  });

  let participantsTable;

  if (props.participants.length > 0) {
    let nextMeetingColumn = (
      <div
        className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
        onClick={() => toggleSortDirection('next-meeting')}
      >
        <span
          className={
            'ppd-3-coach-table__header-caption left clickable' +
            (sortedColumn === 'next-meeting' ? ' active' : '')
          }
        >
          Next Meeting
        </span>
        {sortedColumn === 'next-meeting' ? sortIndicator : null}
      </div>
    );
    let nameWidth = 2;
    let schoolWidth = 3;
    if (props.currentYear === 'past') {
      nextMeetingColumn = null;
      nameWidth += 1;
      schoolWidth += 1;
    }
    participantsTable = (
      <React.Fragment>
        <div className="admin-v5__coach-table__header row v5__registered-table-header-copy">
          <div
            className={`cute-${nameWidth}-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header`}
            onClick={() => toggleSortDirection('name')}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sortedColumn === 'name' ? ' active' : '')
              }
            >
              Name
            </span>
            {sortedColumn === 'name' ? sortIndicator : null}
          </div>

          <div
            className={`cute-${schoolWidth}-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header`}
            onClick={() => toggleSortDirection('school')}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sortedColumn === 'school' ? ' active' : '')
              }
            >
              School
            </span>
            {sortedColumn === 'school' ? sortIndicator : null}
          </div>

          <div
            className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
            onClick={() => toggleSortDirection('meetings-held')}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sortedColumn === 'meetings-held' ? ' active' : '')
              }
            >
              Meetings Held
            </span>
            {sortedColumn === 'meetings-held' ? sortIndicator : null}
          </div>

          <div
            className="cute-3-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
            onClick={() => toggleSortDirection('last-meeting')}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sortedColumn === 'last-meeting' ? ' active' : '')
              }
            >
              Last Meeting
            </span>
            {sortedColumn === 'last-meeting' ? sortIndicator : null}
          </div>
          {nextMeetingColumn}
        </div>
        {meetingRows}
      </React.Fragment>
    );
  }

  return (
    <div className="cute-12-desktop v5__registered-table">
      {props.tableHeadingRow}
      {participantsTable}
    </div>
  );
};

Meetings.propTypes = {
  tableHeadingRow: PropTypes.object.isRequired,
  participants: PropTypes.array.isRequired,
  sortCol: PropTypes.string.isRequired,
};

export default Meetings;
