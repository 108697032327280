// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';

const CoachMatchCohort = (props) => {
  return (
    <section
      className="coach-match__cohort-details"
      onClick={props.handleSetExpandedDetails}
    >
      <div className="coach-match__row">
        <div className="coach-match__cohort coach-match__col--33">
          <div className="v5__body-copy v5__body-copy--bold">Cohort</div>
          <div className="">{props.cohort.name || ''}</div>
        </div>
        <div className="coach-match__col--33">
          <div className="v5__body-copy v5__body-copy--bold">Product</div>
          <div className="">{props.cohort.product.name || ''}</div>
        </div>
        <div className="coach-match__col--33 coach-match__extend-icon-container">
          <div>
            <div className="v5__body-copy--bold">Dates</div>
            <div className="">
              {Utils.handleFormatDate(
                props.cohort.term_start,
                props.cohort.term_end
              )}
            </div>
          </div>
          <div
            className={`coach-match__extend-icon tc-v5-expand-collapse-outline ${
              props.expandedDetails ? 'coach-match__extend-icon--expanded' : ''
            }`}
          />
        </div>
        <div className="coach-match__col--33 coach-match__view-details-mobile">
          <NavigationLink
            url={`/lab/coach/${props.pageUser.id}/match/${props.participantId}/details`}
            className="v5__btn v5__btn--blue-border"
          >
            <span className="coach-match__btn-icon tc-v5-details" />
            <span>View Match Details</span>
          </NavigationLink>
        </div>
      </div>
    </section>
  );
};

export default CoachMatchCohort;

CoachMatchCohort.propTypes = {
  participantId: PropTypes.number.isRequired,
  pageUser: PropTypes.object.isRequired,
  cohort: PropTypes.object.isRequired,
  expandedDetails: PropTypes.bool,
  handleSetExpandedDetails: PropTypes.func.isRequired,
};
