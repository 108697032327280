// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import StrategyApi from './../../apis/StrategyApi';
import Analytics from './../../components/Analytics';
import BrowserStore from './../../stores/V2/BrowserStore';
import NavigationLink from './../NavigationLink';
import SearchBar from './Strategy/SearchBar';
import SearchTab from './Strategy/SearchTab';
import WideStrategyCapsuleV2 from './Strategy/WideStrategyCapsule';

const SearchStrategiesV2 = createReactClass({
  displayName: 'SearchStrategiesV2',
  token: null,

  getAnalyticsTrackingLabel: function () {
    return 'from_sb_search_results';
  },

  getInitialState: function () {
    BrowserStore.actionDispatchSync('init', this.props);
    return BrowserStore.getState();
  },

  componentDidMount: function () {
    this.token = BrowserStore.subscribe(this.onStoreChange);

    // when the user scrolls to the bottom, automatically grab more search results
    window.addEventListener('scroll', this.handleScrollBottom);
  },

  componentDidUpdate: function () {
    window.addEventListener('scroll', this.handleScrollBottom);
  },

  componentWillUnmount: function () {
    BrowserStore.unsubscribe(this.token);
    window.removeEventListener('scroll', this.handleScrollBottom);
  },

  componentWillReceiveProps: function (nextProps) {
    BrowserStore.actionDispatchSync('init', nextProps);
  },

  onStoreChange: function () {
    // eslint-disable-next-line react/no-is-mounted
    if (this.isMounted()) {
      this.setState(BrowserStore.getState());
    }
  },

  handleToggleSearchBar: function (e) {
    e.preventDefault();
    let action = 'search';

    if (this.state.searchBarOpened) {
      action = 'close_search';
    }

    // Record the Analytics event:
    Analytics.logEvent({
      category: 'strategy',
      action: action,
      label: 'from_sb_new_search',
    });
    BrowserStore.actionDispatchSync('toggleSearchBar');
  },

  handleScrollBottom: function () {
    // get the current scroll position
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    // load more search results if scrolled to the bottom
    if (
      ReactDOM.findDOMNode(this).offsetHeight -
        currentScrollPosition -
        window.innerHeight <
      2000
    ) {
      window.removeEventListener('scroll', this.handleScrollBottom);

      // only load more results if we are not currently waiting for more results to load
      if (!this.state.loading) {
        this.loadMoreResults();
      }
    }
  },

  loadMoreResults: function () {
    const nextOffset = this.state.offset + 15;

    // only load more if there are additional results to show
    if (nextOffset < this.props.numResults) {
      // Record the Analytics event:
      Analytics.logEvent({
        category: 'teaching_challenge',
        action: 'view_more_strategies',
        label: 'from_sb_search_results',
      });
      // show Loading... spinner
      BrowserStore.actionDispatchSync('startLoadingResults');

      const searchData = {
        searchText: this.props.searchText,
        offset: nextOffset,
      };
      if (this.props.challenge) {
        searchData.challengeId = this.props.challenge.id;
      } else if (this.props.subject) {
        searchData.subject = this.props.subject;
      }

      StrategyApi.getStrategiesV2(searchData).then(
        function (response) {
          BrowserStore.actionDispatchSync(
            'addSearchResults',
            response.page_data
          );
        }.bind(this)
      );
    }
  },

  render: function () {
    // Used to pass in the correct analytics label for children:
    const analyticsTrackingLabel = this.getAnalyticsTrackingLabel();

    const searchBarProps = {
      searchText: this.props.searchText,
    };
    if (this.props.subject) {
      searchBarProps.subject = this.props.subject;
    }
    if (this.props.challenge) {
      searchBarProps.challenge = this.props.challenge.nickname;
      searchBarProps.challengeId = this.props.challenge.id;
    }

    // build the breadcrumbs at the top of the page
    const headerBreadcrumbs = [];

    // set class of challenge breadcrumb based on the challenge's status
    const statusClass = this.props.challengeStatus
      ? ' browser-v2__crumb-link--' + this.props.challengeStatus
      : '';
    // Inidicates to Analytics where the request is coming from:
    const analyticsFrom = 'sb_search_header';
    // if this was just a keyword search, just link to the strategy browser
    if (!this.props.subject && !this.props.challenge) {
      headerBreadcrumbs.push(
        <div key={'crumb-strategies'} className="browser-v2__crumb">
          <NavigationLink
            className="browser-v2__crumb-link"
            title="Strategies"
            url={'/coaching/lab/strategy-browser?from=' + analyticsFrom}
          >
            Strategies
          </NavigationLink>
        </div>
      );

      // otherwise we have a subject/challenge that we can link to
    } else {
      // find the current subject and set up url bit to link back to the correct browser tab
      const currentSubject = this.props.categories.filter(function (category) {
        return category.name == this.props.subject;
      }, this);
      // if we don't find a matching subject, default to using the subject name search param
      const subjectUrlBit = currentSubject.length
        ? currentSubject[0].seo_url
        : this.props.subject.toLowerCase().replace(' ', '-');

      headerBreadcrumbs.push(
        <div key={'crumb-subject'} className="browser-v2__crumb">
          <NavigationLink
            className="browser-v2__crumb-link"
            title={this.props.subject}
            url={
              '/coaching/lab/strategy-browser-' +
              subjectUrlBit +
              '?from=' +
              analyticsFrom
            }
          >
            {this.props.subject}
          </NavigationLink>
        </div>
      );

      if (this.props.challenge) {
        headerBreadcrumbs.push(
          <div key={'crumb-sga'} className="browser-v2__crumb">
            <NavigationLink
              className="browser-v2__crumb-link"
              title={this.props.sga.name}
              url={
                '/coaching/lab/strategy-browser-' +
                subjectUrlBit +
                '?from=' +
                analyticsFrom
              }
            >
              {this.props.sga.name}
            </NavigationLink>
          </div>
        );

        headerBreadcrumbs.push(
          <div key={'crumb-challenge'} className="browser-v2__crumb">
            <NavigationLink
              className={'browser-v2__crumb-link' + statusClass}
              title={this.props.challenge.name}
              url={
                '/coaching/lab/strategy-browser/teaching-challenge/' +
                this.props.challenge.id +
                '/' +
                this.props.challenge.seo_url +
                '?from=' +
                analyticsFrom
              }
            >
              {this.props.challenge.name}
            </NavigationLink>
          </div>
        );
      }
    }

    headerBreadcrumbs.push(
      <div
        key={'crumb-search-text'}
        className="browser-v2__crumb"
        title={this.props.searchText}
      >
        {this.props.searchText}
      </div>
    );

    // the starting level of the breadcrumbs in the capsules depends on the search context - we don't want to duplicate info
    const capsuleBreadcrumbsStart = this.props.challenge
      ? null
      : this.props.subject
      ? 'sga'
      : 'subject';

    // show the TC header
    const headerNode = <Header {...this.props} page="StrategyBrowser" />;

    return (
      <div className="challenge-strategies">
        <div className="show-desktop hide-phone">{headerNode}</div>

        <div className="browser-v2__wrap--breadcrumbs show-desktop hide-phone">
          <div className="row">
            <div className="cute-12-desktop browser-v2__search-results-crumbs">
              {headerBreadcrumbs}
              <SearchTab />
            </div>
          </div>
        </div>

        <SearchBar {...searchBarProps} />

        <div className="row">
          <div className="cute-12-desktop">
            <div className="browser-v2__search-results-text">
              {this.props.numResults} results for{' '}
              {'"' + this.props.searchText + '"'}
              <a
                href="#"
                className="tc__link"
                onClick={this.handleToggleSearchBar}
              >
                New Search
              </a>
            </div>

            {this.state.searchResults.map(
              function (result) {
                return (
                  <WideStrategyCapsuleV2
                    capsuleType={'searchStrategy'}
                    key={'strategy' + result.id}
                    url={
                      '/coaching/lab/strategy/' +
                      result.id +
                      '/' +
                      result.seo_url +
                      '?from=sb_search_strategy_title'
                    }
                    {...this.props}
                    strategy={result}
                    breadcrumbsStart={capsuleBreadcrumbsStart}
                    analyticsTrackingLabel={analyticsTrackingLabel}
                  />
                );
              }.bind(this)
            )}
            {this.state.loading ? (
              <div className="browser-v2__search-results-loading-text">
                <div className="bigspinner"></div>
                Loading more results...
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  },
});

export default SearchStrategiesV2;
