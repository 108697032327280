// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../components/ArrayHelpers';
import Utils from './../../../components/DynamicUtils';
import ArtifactImage from './../Common/ArtifactImage';
import UserPhoto from './../Common/UserPhoto';
import StrategySection from './modules/StrategyPrintSection';

const StrategyPrint = (props) => {
  let heroArtifactNode,
    specialtiesNode,
    danielsonNode,
    practicesNode,
    domainsNode,
    sectionNodes;

  window.print();

  // if the strategy has a tagline, show it under the title
  const taglineNode = props.strategy.tagline ? (
    <div className="strategy-v4__tagline tc__center-text">
      {props.strategy.tagline}
    </div>
  ) : null;

  // headline text for the About This capsule depends on strategy type
  const aboutThisHeadline =
    props.strategy.type == 'tech_tool'
      ? 'About This Tech Tool'
      : 'About This Strategy';

  // set up any hero image/video
  if (props.strategy.lessonfile && props.strategy.lessonfile.connections) {
    // add the lessonfile_id so that we get the correct URL upon opening the artifact overlay
    const lessonfile = props.strategy.lessonfile;
    lessonfile.lessonfile_id = props.strategy.lessonfile_id;

    // video hero artifacts look slightly different than image hero artifacts
    let heroImageClass = 'strategy-v4__hero-image';
    let heroOverlayNode, heroTextNode;
    if (lessonfile.connections.preview_type == 'movie') {
      // show duration for videos
      let videoDurationText = null;
      if (lessonfile.connections.duration_or_pages) {
        videoDurationText = (
          <div className="strategy-v4__hero-duration">
            {Utils.hhmmss(lessonfile.connections.duration_or_pages, true)}
          </div>
        );
      }
      // for hero video, set up overlay with name + duration
      heroImageClass = 'strategy-v4__hero-video';
      heroOverlayNode = <div className="strategy-v4__hero-overlay"></div>;
      heroTextNode = (
        <div className="strategy-v4__hero-text">
          {lessonfile.connections.name}
          {videoDurationText}
        </div>
      );
    }

    heroArtifactNode = (
      <div className="strategy-v4__hero-section">
        <ArtifactImage
          preview={lessonfile.connections.preview}
          icon={lessonfile.connections.icon}
          title={props.strategy.title}
          containerClass="strategy-v4__hero-container"
          imageClass={heroImageClass}
        />
        {heroOverlayNode}
        {heroTextNode}
      </div>
    );
  }

  // set up any strategy sections (including side nav links to them)
  if (ArrayHelpers.hasElements(props.strategy.sections)) {
    sectionNodes = props.strategy.sections.map((section, index) => {
      const i = index + 1;

      return (
        <StrategySection
          key={'section-' + section.id}
          index={i}
          strategyId={props.strategy.id}
          {...section}
        />
      );
    });
  }

  // set up any strategy authors
  let authorNodes = (
    <div>
      <UserPhoto
        src="/legacy-images/bl-icon-circle.png"
        diameter={80}
        className="strategy-v4__author-photo"
      />
      <div className="strategy-v4__author-name">
        Written by BetterLesson Coaching
      </div>
    </div>
  );
  if (ArrayHelpers.hasElements(props.strategy.authors)) {
    authorNodes = (
      <div className="strategy-v4__metadata-flex-section strategy-v4__metadata-flex-section--centered">
        {props.strategy.authors.map((author) => {
          return (
            <div
              key={'author-' + author.id}
              className="strategy-v4__metadata-flex-item"
            >
              <UserPhoto
                src={author.photo}
                diameter={80}
                className="strategy-v4__author-photo"
              />
              <div className="strategy-v4__author-name">{author.label}</div>
              <div className="strategy-v4__metadata-grey-text">
                {author.role == null
                  ? 'BetterLesson Instructional Coach'
                  : author.role}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  const authorsNode = (
    <div className="strategy-v4__metadata-section tc__center-text">
      <div className="strategy-v4__metadata-headline">Strategy Authors</div>
      {authorNodes}
    </div>
  );

  // set up the list of grade buckets
  let gradesNode = (
    <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
      All Grades
    </div>
  );
  if (ArrayHelpers.hasElements(props.strategy.gradeBands)) {
    gradesNode = (
      <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
        {props.strategy.gradeBands.join(', ')}
      </div>
    );
  }
  // set up the list of subjects
  let subjectsNode = (
    <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
      All Subjects
    </div>
  );
  if (ArrayHelpers.hasElements(props.strategy.subjects)) {
    subjectsNode = (
      <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
        {props.strategy.subjects.map((subject) => subject.label).join(', ')}
      </div>
    );
  }
  const gradesSubjectsNode = (
    <div className="strategy-v4__metadata-section">
      <div className="strategy-v4__metadata-headline">Grade Level</div>
      {gradesNode}
      <div className="strategy-v4__metadata-headline">Subjects</div>
      {subjectsNode}
    </div>
  );

  // set up any specialty tags associated with the strategy
  if (ArrayHelpers.hasElements(props.strategy.specialtyTags)) {
    specialtiesNode = (
      <div className="strategy-v4__metadata-section">
        <div
          className="strategy-v4__metadata-headline"
          style={{ marginBottom: 10 }}
        >
          Specialty Areas
        </div>
        <div className="strategy-v4__metadata-tag-description">
          {props.strategy.specialtyTags.map((tag) => {
            return (
              <div key={'tag-' + tag.id} style={{ marginTop: 10 }}>
                {tag.label} - {tag.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // set up any danielson components associated with the strategy
  if (ArrayHelpers.hasElements(props.strategy.danielsonComponents)) {
    danielsonNode = (
      <div className="strategy-v4__metadata-section">
        <div
          className="strategy-v4__metadata-headline"
          style={{ marginBottom: 10 }}
        >
          Danielson Framework
        </div>
        <div className="strategy-v4__metadata-tag-description">
          {props.strategy.danielsonComponents.map((dan) => {
            return (
              <div key={'dan-' + dan.id} style={{ marginTop: 10 }}>
                {dan.label} - {dan.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // set up any instructional practices associated with the strategy
  if (ArrayHelpers.hasElements(props.strategy.practiceTags)) {
    const practiceNodes = props.strategy.practiceTags.map((practice) => {
      return (
        <div
          key={'practice-' + practice.id}
          className="strategy-v4__metadata-link"
        >
          <a
            href={process.env.REACT_APP_TEACHING_URL + '/search'}
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link"
          >
            {practice.label}
          </a>
        </div>
      );
    });

    practicesNode = (
      <div className="strategy-v4__metadata-section">
        <div className="strategy-v4__metadata-headline">
          Key Instructional Practices
        </div>
        {practiceNodes}
      </div>
    );
  }

  // set up any learning domains associated with the strategy
  if (ArrayHelpers.hasElements(props.strategy.focusAreas)) {
    const domainNodes = props.strategy.focusAreas.map((domain) => {
      return (
        <div key={'domain-' + domain.id} className="strategy-v4__metadata-link">
          <a
            href={
              process.env.REACT_APP_TEACHING_URL +
              '/search?focusAreas=' +
              domain.id
            }
            target="_blank"
            rel="noopener noreferrer"
            className="tc__link"
          >
            {domain.label}
          </a>
        </div>
      );
    });

    domainsNode = (
      <div className="strategy-v4__metadata-section">
        <div className="strategy-v4__metadata-headline">Learning Domains</div>
        {domainNodes}
      </div>
    );
  }

  return (
    <div style={{ padding: 10 }}>
      <div>
        <img
          src="/legacy-images/betterlesson-logo.svg"
          style={{ height: 50 }}
        />
      </div>

      <div>
        <h1 className="strategy-v4__title">{props.strategy.title}</h1>
        {taglineNode}
        {/* {heroArtifactNode} */}

        <div>
          {authorsNode}
          {gradesSubjectsNode}
          {specialtiesNode}
          {danielsonNode}
          {practicesNode}
          {domainsNode}
        </div>

        <StrategySection
          index={0}
          strategyId={props.id}
          title={aboutThisHeadline}
          description={props.strategy.description}
        />

        {sectionNodes}
      </div>
    </div>
  );
};

StrategyPrint.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  strategy: PropTypes.object.isRequired,
};

export default StrategyPrint;
