// @ts-nocheck FIXME
import Request from './../components/Request';

const AdminApi = {
  // currently the user search only supports the 'email' param
  getUsers: (searchParams) => {
    let url = '/bl/legacy/users?';
    for (const param in searchParams) {
      // if a param has a bunch of values, we need to list them separately
      if (Array.isArray(searchParams[param])) {
        for (const i in searchParams[param]) {
          url += param + '=' + encodeURIComponent(searchParams[param][i]) + '&';
        }
      } else {
        url += param + '=' + encodeURIComponent(searchParams[param]) + '&';
      }
    }
    return Request.get(url);
  },

  updateTeacherAccountV2: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/user/update_details',
      JSON.stringify(data)
    );
  },

  getTests: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_URL +
        '/bl/tests/suite/' +
        data.suiteId +
        '/tests',
      {}
    );
  },

  runTest: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_URL +
        '/bl/tests/test/' +
        data.testId +
        '/execute',
      {}
    );
  },

  validateTest: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_URL +
        '/bl/tests/test/' +
        data.testId +
        '/validate',
      {}
    );
  },

  clearSession: function () {
    return Request.post(
      process.env.REACT_APP_LAB_URL + '/bl/tests/clear-session',
      {}
    );
  },
};

export default AdminApi;
