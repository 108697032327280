// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../components/ArrayHelpers';
import Utils from './../../../components/DynamicUtils';
import { Router } from './../../../components/Router';
import HeaderStore from './../../../stores/V4/HeaderStore';
import LessonLink from './../../LessonLink';
import NavigationLink from './../../NavigationLink';
import ScaleCenterImage from './../../ScaleCenterImage';
import AbridgeText from './../../V4/Common/AbridgeText';
import ExpandableList from './../../V4/Common/ExpandableList';
import UserPhoto from './../../V4/Common/UserPhoto';

const ResultCapsule = (props) => {
  const lab = useLegacyLabContext();

  const handleCapsuleClick = (e) => {
    e.preventDefault();
    if (
      props.favorite &&
      (props.favorite.status === 'loading-favorite' ||
        props.favorite.status === 'loading-unfavorite')
    ) {
      return;
    }

    // if the logged out user has reached the limit of 5 free content pages,
    // show the registration modal
    const reachedFreeContentLimit = Utils.reachedFreeContentLimit.call(
      lab,
      parseInt(props.resultId),
      null
    );
    if (reachedFreeContentLimit) {
      if (!props.user || (props.user && !props.user.id)) {
        HeaderStore.actionDispatch('openRegisterModal');
        return;
      }

      HeaderStore.actionDispatch('openRegisterModal', {
        reason: 'resource_count_reached',
      });
      return;
    }

    Router.route(props.resultUrl);
  };

  const handleOpenRecommend = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!props.recommend) {
      return;
    }
    props.recommend({
      itemType: props.resultType,
      itemId: props.resultId,
    });
  };

  const generateTags = (tags) => {
    if (!tags) {
      return null;
    }

    return tags.map((tag) => {
      const tagIteration = tag.CoreStandard ? tag.CoreStandard : tag;
      const fromTag = props.fromTag ? '&from=' + props.fromTag : '';
      return (
        <NavigationLink
          url={
            process.env.REACT_APP_TEACHING_URL +
            '/search?from=cc_lesson_core&standards=' +
            tagIteration.id +
            fromTag
          }
          className="search__tag"
          title={tagIteration.description}
          key={'result-' + props.resultId + '-standard-tag-' + tagIteration.id}
        >
          {tagIteration.identifier}
        </NavigationLink>
      );
    });
  };

  const generateSubjectIcon = () => {
    let iconClass = '';
    let colorClass = '';

    if (props.resultType === 'lesson') {
      colorClass = ' search__result-icon--lesson';
      // find the first relevant subject in the lesson's list
      if (!props.subjects && !props.mtp_subject) {
        return (
          <div
            className={'search__result-icon' + colorClass + ' tc-v4-ela'}
          ></div>
        );
      }

      // change how the logo is generated depending on where this component is being called from
      // called from Browser/BlendedBrowser.jsx > a strategy will not hit this condition
      // Browser/modules/LevelFive.jsx > props.Lesson
      // MasterTeacherProfile/Curriculum.jsx > props.mtp_subject
      let subject;
      if (props.Lesson && props.Lesson.mtp_subject) {
        subject = props.Lesson.mtp_subject;
      } else if (props.mtp_subject) {
        subject = props.mtp_subject;
      } else {
        // return the first value in the subjects array that is contained by the following array of top level subjects
        subject = props.subjects.find((sub) => {
          return (
            [
              'Science',
              'science',
              'Math',
              'math',
              'English / Language Arts',
              'ela',
            ].indexOf(sub) !== -1
          );
        });
      }

      switch (subject ? subject : null) {
        case 'Science':
        case 'science':
          iconClass = ' tc-v4-science';
          break;
        case 'Math':
        case 'math':
          iconClass = ' tc-v4-math';
          break;
        case 'English / Language Arts':
        case 'ela':
          iconClass = ' tc-v4-ela';
          break;
        default:
          iconClass = ' tc-v4-ela';
      }
    } else if (props.resultType === 'strategy') {
      colorClass = ' search__result-icon--blended-strategy';
      iconClass = ' tc-v4-tablet';
    }

    return <div className={'search__result-icon' + colorClass + iconClass} />;
  };

  const getAuthorNode = () => {
    if (props.resultUser) {
      return (
        <div className="search__owner search__owner--coach search__detail show-desktop hide-phone">
          <UserPhoto
            src={props.resultUser.photo}
            className="search__owner-image"
            diameter={50}
          />

          <div className="search__owner-details">
            <div>{props.resultUser.user_full_name}</div>
            <div>{props.resultUser.environment} Env.</div>
          </div>
        </div>
      );
    }
  };

  // set up strategy learning domains or lesson standards tags if necessary
  let tagsNode = null;
  if (ArrayHelpers.hasElements(props.standards)) {
    const standardTags = generateTags(props.standards);

    let analyticsLabel = null;
    if (props.fromTag) {
      analyticsLabel =
        props.fromTag === 'standard_level2'
          ? 'from_lesson_capsule_' + props.fromTag
          : props.fromTag;
    }

    const standardNodes = ArrayHelpers.hasElements(standardTags) ? (
      <ExpandableList
        elements={standardTags}
        expandClassName="search__tag-more-link"
        analyticsLabel={analyticsLabel}
      />
    ) : null;

    tagsNode = (
      <div className="search__tags">
        <h5 className="search__tags-headline tc__headline-5">Standards</h5>
        {standardNodes}
      </div>
    );
  }

  let resultImageNode = null;
  let detailsClass = '';
  if (props.preview) {
    detailsClass = ' bl4-capsule__details--has-image';
    resultImageNode = (
      <div className="show-desktop hide-phone bl4-capsule__image-container">
        <ScaleCenterImage src={props.preview} className="bl4-capsule__image" />
      </div>
    );
  }

  const ownerNode = getAuthorNode();

  let minutesNode = null;
  if (props.minutes) {
    minutesNode = (
      <div className="search__detail search__detail--minutes">
        {props.minutes} minutes
      </div>
    );
  }

  let favoritesNode = null;
  if (props.favoriteCount) {
    const favoritesCaption =
      props.favoriteCount !== 1 ? 'Favorites' : 'Favorite';
    favoritesNode = (
      <div className="search__detail search__detail--favorites">
        <div className="search__detail-icon search__detail-icon--heart search__detail-icon--heart-unclickable tc-v4-heart-filled" />
        <div className="search__detail-copy">
          {props.favoriteCount} {favoritesCaption}
        </div>
      </div>
    );
  }

  let resourcesNode = null;
  if (props.resourceCount) {
    const resourcesCaption =
      props.resourceCount !== 1 ? 'Resources' : 'Resource';
    resourcesNode = (
      <div className="search__detail search__detail--resources">
        <div className="search__detail-icon tc-v4-page" />
        <div className="search__detail-copy">
          {props.resourceCount} {resourcesCaption}
        </div>
      </div>
    );
  }

  let recommendNode = null;
  if (props.recommend && Utils.isContractCoach.call(lab, props.user)) {
    recommendNode = (
      <a
        className="search__detail search__detail--recommend tc__link"
        onClick={handleOpenRecommend}
      >
        Recommend
      </a>
    );
  }

  let footerNode = null;
  let capsuleWithFooterClass = ' bl4__capsule--result-without-footer';
  if (ownerNode || resourcesNode || favoritesNode || minutesNode) {
    footerNode = (
      <div className="bl4-capsule__footer">
        {ownerNode}
        <div className="bl4-capsule__footer-details show-desktop-flex hide-phone">
          {resourcesNode}
          {favoritesNode}
          {recommendNode}
          {minutesNode}
        </div>
        <div className="bl4-capsule__footer-details cute-12-phone show-phone hide-desktop">
          {resourcesNode}
          {minutesNode}
          {favoritesNode}
          {recommendNode}
        </div>
      </div>
    );
    capsuleWithFooterClass = ' bl4__capsule--result-with-footer';
  }

  const titleNode = (
    <LessonLink
      url={props.resultUrl}
      className="tc__link"
      lesson={props.resultId}
    >
      {props.title}
    </LessonLink>
  );

  const courseName = props.course ? props.course : 'All Grades';
  let unitName = 'All Subjects';
  let unitSeperator = (
    <div className="bl4-capsule__subheadline-segment">&raquo;</div>
  );
  if (props.unit) {
    unitName = props.unit;
    unitSeperator = (
      <div className="bl4-capsule__subheadline-segment">&raquo; Unit:</div>
    );
  }
  let topicSeperator, topicCrumb;
  if (props.topic) {
    topicCrumb = (
      <div className="bl4-capsule__subheadline-segment">{props.topic}</div>
    );
    topicSeperator = (
      <div className="bl4-capsule__subheadline-segment">&raquo;</div>
    );
  }
  return (
    <div
      className={
        'bl4-result .responsive-layout bl4__capsule--result bl4__capsule bl4-capsule--hoverable bl4__capsule--result bl4-result--' +
        props.resultType +
        capsuleWithFooterClass
      }
      onClick={handleCapsuleClick}
    >
      <div className="bl4-capsule__content">
        <div className={'bl4-capsule__details' + detailsClass}>
          <h4 className="bl4-capsule__headline tc__headline-3">
            {generateSubjectIcon()}
            <div>
              {titleNode}
              <div className="bl4-capsule__subheadline">
                <div className="bl4-capsule__subheadline-segment bl4-capsule__subheadline-segment--first">
                  {courseName}
                </div>
                {unitSeperator}
                <div className="bl4-capsule__subheadline-segment">
                  {unitName}
                </div>
                {topicSeperator}
                {topicCrumb}
              </div>
            </div>
          </h4>
          <AbridgeText
            maxHeight={100}
            className="search__summary tc__body-copy"
          >
            <span className="search__summary-declaration">Big Idea:</span>
            <PurifiedHTML
              className="ckeditor__output"
              content={props.summary}
              tagName="span"
            />
          </AbridgeText>
          {tagsNode}
        </div>
        {resultImageNode}
        {footerNode}
      </div>
    </div>
  );
};

const WrappedResultCapsule = (props) => (
  <HeaderConsumer>
    {({ user }) => <ResultCapsule {...props} user={user} />}
  </HeaderConsumer>
);

export default WrappedResultCapsule;

ResultCapsule.propTypes = {
  resultId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recommend: PropTypes.func,
  addFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
  favorite: PropTypes.object,
  resultUser: PropTypes.object,
  standards: PropTypes.array,
  minutes: PropTypes.string,
  favoriteCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  preview: PropTypes.string,
  creator: PropTypes.object,
  user: PropTypes.object,
  resourceCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
};
