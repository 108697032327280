// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const NextMeeting = (props) => {
  const meeting = props.nextMeeting;

  // there should never be a "next meeting" with no coach,
  // but just in case we default to using the participant's timezone
  const timezone = props.coachTimeZone || props.participantTimeZone;
  const currentTime = moment().tz(timezone);

  // calculate some meeting data
  let meetingLink;
  let nextMeetingTime;
  let beforeMeetingTime;
  let endMeetingTime;
  let meetingLength;
  if (meeting) {
    meetingLink = meeting.zoom_join_link;
    // set time that the next meeting starts
    nextMeetingTime = moment(
      meeting &&
        (meeting.meeting_time_formatted || meeting.start_time_formatted)
    ).tz(timezone);
    // set time to display button 5 minutes before the meeting
    const fiveMinutes = moment.duration(5, 'minutes');
    beforeMeetingTime = nextMeetingTime - fiveMinutes;
    // set length of meeting
    meetingLength = moment.duration(
      meeting && meeting.meeting_length,
      'minutes'
    );
    // set time to hide the meeting data after the meeting has ended
    endMeetingTime = moment(nextMeetingTime) + meetingLength;
  }

  const [meetingStarted, setMeetingStarted] = React.useState(
    meeting &&
      currentTime.isSameOrAfter(beforeMeetingTime) &&
      currentTime.isBefore(endMeetingTime)
  );
  const [meetingEnded, setMeetingEnded] = React.useState(
    meeting && currentTime.isSameOrAfter(endMeetingTime)
  );

  React.useEffect(() => {
    // If there is no meeting or the meeting is more than 24 days away, don't do anything
    if (
      !meeting ||
      nextMeetingTime - currentTime >= moment.duration(24, 'days')
    ) {
      // Don't bother trying to update things if the meeting is more than a few weeks in the future
      return;
    }

    let startMeeting;
    let endMeeting;

    // Select the next meeting and calculate the time until it starts
    const timeUntilMeeting = moment.duration(nextMeetingTime - currentTime);
    const fiveMinutes = moment.duration(5, 'minutes');

    if (timeUntilMeeting.asMinutes() <= fiveMinutes.asMinutes()) {
      // set a timeout to fire when the meeting ends
      endMeeting = setTimeout(() => {
        setMeetingStarted(false);
        setMeetingEnded(true);
      }, moment.duration(nextMeetingTime - moment().tz(timezone)).asMilliseconds() + meetingLength.asMilliseconds());
    } else {
      startMeeting = setTimeout(() => {
        // set a timeout to fire when the meeting starts
        setMeetingStarted(true);
        // set a timeout to fire when the meeting ends
        endMeeting = setTimeout(() => {
          setMeetingStarted(false);
          setMeetingEnded(true);
        }, moment.duration(nextMeetingTime - moment().tz(timezone)).asMilliseconds() + meetingLength.asMilliseconds());
      }, timeUntilMeeting.asMilliseconds() - fiveMinutes.asMilliseconds());
    }

    // clear the timeouts on unmount
    return () => {
      clearTimeout(startMeeting);
      clearTimeout(endMeeting);
    };
  }, []);

  // default message for no next meeting
  let nextMeetingTimeNode = (
    <div className="engagement-details__copy engagement-details__copy--italic engagement-details__copy--grey">
      No upcoming meetings
    </div>
  );
  // if the meeting has ended, just show a dash
  if (meetingEnded) {
    nextMeetingTimeNode = <div className="engagement-details__copy">-</div>;
  }
  // otherwise, show the next meeting time
  else if (nextMeetingTime) {
    const coachMeetingTime = nextMeetingTime.format('h:mm A z');
    let meetingTimeNode = (
      <div className="engagement-details__copy engagement-details__copy--bold">
        {coachMeetingTime}
      </div>
    );
    // display the meeting time in both the coach and participant timezones,
    // if they are different
    if (timezone !== props.participantTimeZone) {
      const participantMeetingTime = nextMeetingTime
        .tz(props.participantTimeZone)
        .format('h:mm A z');
      meetingTimeNode = (
        <div className="engagement-details__copy engagement-details__copy--bold">
          {coachMeetingTime}
          <span className="engagement-details__time-separator">|</span>
          <span className="engagement-details__copy--grey">
            {participantMeetingTime}
          </span>
        </div>
      );
      // if the current user is the participant, show their time first
      if (props.userIsParticipant) {
        meetingTimeNode = (
          <div className="engagement-details__copy engagement-details__copy--bold">
            {participantMeetingTime}
            <span className="engagement-details__time-separator">|</span>
            <span className="engagement-details__copy--grey">
              {coachMeetingTime}
            </span>
          </div>
        );
      }
    }

    nextMeetingTimeNode = (
      <>
        <div className="engagement-details__copy engagement-details__copy--bold">
          {nextMeetingTime.format('dddd, MMMM D')}
        </div>
        {meetingTimeNode}
      </>
    );
  }

  let zoomLinkNode;
  if (meetingLink && nextMeetingTime && !meetingEnded) {
    zoomLinkNode =
      props.canJoinMeeting && meetingStarted ? (
        <div>
          <a
            href={meetingLink}
            className="v5__link engagement-details__copy engagement-details__copy--url"
            target="_blank"
            rel="noreferrer"
          >
            {meetingLink}
          </a>
        </div>
      ) : (
        <div className="engagement-details__copy engagement-details__copy--grey engagement-details__copy--url">
          {meetingLink}
        </div>
      );
  }

  let nextMeetingBtnNode;
  // if the meeting is going to start within 5 minutes or it is in progress
  if (props.canJoinMeeting && meetingLink && meetingStarted) {
    nextMeetingBtnNode = (
      <a
        href={meetingLink}
        target="_blank"
        className="v5__link workspace__meeting-action-link"
        rel="noreferrer"
      >
        <span className="tc-v5-video workspace__meeting-action-link-icon"></span>
        Start
      </a>
    );
  }

  let addMeetingBtnNode;
  if (props.canAddMeeting) {
    addMeetingBtnNode = (
      <NavigationLink
        url={`/lab/participant/${props.participantId}/meeting/details/add`}
        className="v5__link workspace__meeting-action-link"
      >
        <span className="tc-v5-plus-solid workspace__meeting-action-link-icon"></span>
        Add
      </NavigationLink>
    );
  }

  return (
    <div className="engagement-details__next-meeting">
      <div className="v5__heading-6">Next Meeting</div>
      <div className="workspace__next-meeting">
        {nextMeetingTimeNode}
        {zoomLinkNode}
      </div>
      <div>
        {addMeetingBtnNode}
        {nextMeetingBtnNode}
      </div>
    </div>
  );
};

NextMeeting.propTypes = {
  nextMeeting: PropTypes.object,
  participantId: PropTypes.number.isRequired,
  coachTimeZone: PropTypes.string,
  participantTimeZone: PropTypes.string.isRequired,
  userIsParticipant: PropTypes.bool,
  canAddMeeting: PropTypes.bool,
  canJoinMeeting: PropTypes.bool,
};

export default NextMeeting;
