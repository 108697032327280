// @ts-nocheck FIXME
import Request from './../../components/Request';

const ParticipantDetailsApi = {
  updateMeetingStatus: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/lab/participant/${data.participantId}/meeting/${data.meetingId}/status/update`,
      JSON.stringify(data)
    );
  },
  addNote: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/lab/participant/${data.participantId}/addNote`,
      JSON.stringify(data)
    );
  },
  editNote: (data) => {
    return Request.post(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/lab/participant/${data.participantId}/editNote/${data.noteId}`,
      JSON.stringify(data)
    );
  },
};

export default ParticipantDetailsApi;
