// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const Spinner = (props) => {
  let spinnerNode = null;

  if (props.loading) {
    let className = 'littlespinner ';
    if (props.className) {
      className += props.className;
    }
    spinnerNode = <span className={className}></span>;
  }

  return spinnerNode;
};

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  loading: false,
};

export default Spinner;
