// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import React from 'react';

class NavigationLink extends React.Component {
  state = {
    classList: this.props.className,
  };

  url = null;

  componentDidUpdate(prevProps) {
    if (prevProps.className !== this.props.className) {
      this.setState({ classList: this.props.className });
    }
  }

  handleClick = (e) => {
    if (this.props.onClick && typeof this.props.onClick === 'function') {
      this.props.onClick();
    }
    if (this.props.target && this.props.target === '_blank') {
      // We want to open the link in a new window. Return:
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (!this.url) {
      return;
    }

    // if link is a button disable on click
    if (this.props.className && this.props.className.indexOf('tc__btn') > -1) {
      this.setState({
        classList: this.props.className + ' disabled',
      });
    }

    if (this.props.isMtp || this.url.match(/^http|^\/\//gi)) {
      window.location = this.url;
      return;
    }

    Router.route(this.url);
  };

  render() {
    const className = this.state.classList || null;
    const target = this.props.target ? this.props.target : '_self';
    const title = this.props.title ? this.props.title : '';
    const id = this.props.id ? this.props.id : null;
    const linkStyles = this.props.style ? { style: this.props.style } : null;
    this.url = this.props.url;
    if (this.props.override) {
      this.url = this.props.override(this.props.url);
    }

    const dataTestId = {};
    if (this.props.dataTestId) {
      dataTestId['data-test-id'] = this.props.dataTestId;
    }
    if (id) {
      return (
        <a
          id={id}
          onClick={this.handleClick}
          href={this.url}
          className={className}
          target={target}
          title={title}
          {...dataTestId}
          {...linkStyles}
        >
          {this.props.children}
        </a>
      );
    }
    return (
      <a
        onClick={this.handleClick}
        href={this.url}
        className={className}
        target={target}
        title={title}
        {...linkStyles}
      >
        {this.props.children}
      </a>
    );
  }
}

export default NavigationLink;
