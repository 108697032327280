// @ts-nocheck FIXME
import { useRef } from 'react';

const eventFilter = (props) => {
  const selectRef = useRef();
  const handleChange = () => {
    props.handleChange(selectRef.current.value || null);
  };

  const selectOptions = props.options.map((option, i) => {
    return (
      <option key={`${props.filterType}-${i}`} value={option.label}>
        {option.label}
      </option>
    );
  });

  return (
    <div className="admin-table__filter-container">
      <div className="v5__body-copy">{props.filterType}</div>
      <select
        ref={selectRef}
        className="admin-table__filter event__participants-groups tc__select"
        onChange={handleChange}
        defaultValue={props.defaultValue}
      >
        <option value="">All</option>
        {selectOptions}
      </select>
    </div>
  );
};

export default eventFilter;
