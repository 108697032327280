// @ts-nocheck FIXME
import StringHelpers from '@legacy-components/components/StringHelpers';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';
import { useState } from 'react';

const AddExistingPartnerLeadModal = (props) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [generalError, setGeneralError] = useState('');

  const addNewPLText = 'Add a new partner leader';
  const headlineText = `Add a partner leader to ${props.title}`;

  const handleCloseModal = (e) => {
    e.preventDefault();
    props.close();
  };

  const handleEmailBlur = (e) => {
    if (!StringHelpers.isEmailValid(e.target.value)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
    } else if (
      props.currentLeads.findIndex((lead) => lead.email === e.target.value) >= 0
    ) {
      setEmailError(
        'A partner leader with that email is already on this partner.'
      );
    } else {
      setEmailError('');
    }
  };

  const handleAddPl = (e) => {
    e.preventDefault();

    if (!StringHelpers.isEmailValid(email)) {
      setEmailError('Hold on, you need to enter a valid email to continue.');
      return;
    } else if (
      props.currentLeads.findIndex((lead) => lead.email === email) >= 0
    ) {
      setEmailError(
        'A partner leader with that email is already on this partner.'
      );
      return;
    } else {
      setEmailError('');
    }

    props.addExistingPL({
      email: email,
      partnerId: props.partnerId,
      eventId: props.eventId,
      successCallback: props.close,
      failureCallback: setGeneralError,
      emailFailureCallback: setEmailError,
    });
  };

  // disable buttons when submitting
  const btnProps = props.isLoading ? { disabled: true } : null;

  const errorNode = generalError && <ErrorText error={generalError} />;

  return (
    <ActionModal
      headlineText={headlineText}
      close={handleCloseModal}
      spaceFromTop={50}
    >
      <form className="participant-v5__modal-body participant-v5__participant-meeting-clip-modal">
        <label className="workspace__form-row participant-v5__modal-top-row">
          <label className="workspace__form-row">
            <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
              Email
            </div>
            <FormField
              type="text"
              className={'admin-v5__event-pl-form-field'}
              disabled={props.isLoading}
              error={emailError}
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setEmailError('')}
              onBlue={handleEmailBlur}
              maxLength="256"
              data-testid="add-event-pl-email"
            />
          </label>

          <div className="workspace__form-row">
            <div>
              <button
                className="v5__btn"
                onClick={handleAddPl}
                {...btnProps}
                data-testid="add-existing-pl-button"
              >
                <Spinner loading={props.isLoading} />
                Add Partner Leader
              </button>
              <div>{errorNode}</div>
            </div>
          </div>

          <div className="workspace__form-row" onClick={props.addNew}>
            <div className="admin-v5__event-pl-add">
              Does your partner leader need a BL account?
            </div>
            <div className="tc__link">{addNewPLText}</div>
          </div>
        </label>
      </form>
    </ActionModal>
  );
};

AddExistingPartnerLeadModal.propTypes = {
  currentLeads: PropTypes.array.isRequired,
  partnerId: PropTypes.number.isRequired,
  eventId: PropTypes.number,
  isLoading: PropTypes.bool,
  close: PropTypes.func.isRequired,
  addExistingPL: PropTypes.func.isRequired,
  addNew: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default AddExistingPartnerLeadModal;
