// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import React from 'react';
import Analytics from './../../../components/Analytics';
import Utils from './../../../components/DynamicUtils';
import LayerLink from './../../LayerManager/LayerLink';
import GreyOverlayDialogue from './../../Modals/GreyOverlayDialogue';
import AddNote from './../TeacherPinboard/AddNote';

class StrategyNotes extends React.Component {
  static contextType = LegacyLabContext;

  handleAddNote = (note) => {
    // Record the analytics event:
    Analytics.logEvent({
      category: 'strategy',
      action: 'add_note',
      label: this.props.analyticsTrackingLabel,
    });

    // If the parent provided a handler for adding a note, call it:
    if (
      this.props.handleAddNote &&
      typeof this.props.handleAddNote === 'function'
    ) {
      this.props.handleAddNote(note);
    }
  };

  render() {
    // only TC Teachers should see the Add Note icon
    if (!Utils.isTeacher.call(this.context)) {
      return null;
    }

    // Regular users should see the # of strategy notes and should be able to add notes:
    const noteCount = !this.props.strategy.notes
      ? 0
      : this.props.strategy.notes.length;

    // Setup css classes + counter for note icon
    let noteClass = '';
    let noteCountNode = null;
    const classNamePrefix = this.props.classNamePrefix;

    if (noteCount > 0) {
      noteClass = classNamePrefix + '-v2__user-action-icon--active';

      let noteCountClassSize = '';
      if (noteCount > 99) {
        noteCountClassSize = classNamePrefix + '-v2__note-count--very-large';
      } else if (noteCount > 9) {
        noteCountClassSize = classNamePrefix + '-v2__note-count--large';
      }
      noteCountNode = (
        <div
          className={classNamePrefix + '-v2__note-count ' + noteCountClassSize}
        >
          {noteCount}
        </div>
      );
    }

    return (
      <LayerLink
        params={{
          strategy: this.props.strategy,
          user: this.props.user,
          canAddNote: this.props.canAddNote,
          onAddNote: this.handleAddNote,
        }}
        layer="new"
        className={this.props.mobileCssClassName}
        title={noteCount ? 'Notes' : 'Add note'}
        component={AddNote}
        wrapper={GreyOverlayDialogue}
      >
        <div>
          <span
            className={
              'tc-note ' +
              classNamePrefix +
              '-v2__user-action-icon ' +
              noteClass
            }
          >
            {noteCountNode}
          </span>
          {this.props.label}
        </div>
      </LayerLink>
    );
  }
}

export default StrategyNotes;
