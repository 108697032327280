// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { Router } from './../../../../../components/Router';
import NavigationLink from './../../../../NavigationLink';

const OutcomeRow = (props) => {
  // target url for clicking on elements in the row
  const manageOutcomeStoryUrl =
    '/coaching/lab/participant/' +
    props.participantId +
    '/portfolio/outcome/' +
    props.outcome.id +
    '?from=ppd3_participant_portfolio_manage_UI';

  // clicking on the outcome icon redirects to manage outcome story page
  const handleIconClick = () => {
    Router.route(manageOutcomeStoryUrl);
  };

  // outcome looks different when visible vs hidden
  let iconColor = '';
  let linkColor = '';
  let titleColor = '';
  let checkColor = '';
  if (props.hiddenEntryIds.indexOf(props.outcome.id) !== -1) {
    iconColor = ' p-portfolio-management__outcome-icon--invisible';
    linkColor = ' tc__link--portfolioGrey';
    titleColor = ' p-portfolio-management__outcome-title--invisible';
    checkColor = ' p-portfolio-management__check--invisible';
  }

  // show a line under the outcome, as long as it's not the last outcome in the list
  let dividerNode = null;
  if (!props.noBottomBorder) {
    dividerNode = (
      <div className="row">
        <div className="cute-7-desktop cute-2-desktop-offset left p-portfolio-management__bottom-border show-desktop hide-phone"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="cute-9-desktop cute-1-desktop-offset center-desktop cute-12-phone p-portfolio-management__outcome-capsule">
          <div
            className={'p-portfolio-management__check' + checkColor}
            onClick={() => props.toggleOutcomeVisibility(props.outcome.id)}
          >
            <span className="tc-check"></span>
          </div>
          <div
            onClick={handleIconClick}
            className={
              'p-portfolio-management__outcome-icon' +
              iconColor +
              ' tc-cd-domain-' +
              props.outcome.domainIconId
            }
          ></div>
          <div
            className={
              'p-portfolio-management__outcome-title left' + titleColor
            }
          >
            <NavigationLink
              className={'tc__link' + linkColor}
              url={manageOutcomeStoryUrl}
            >
              {props.outcome.outcomeTitle}
            </NavigationLink>
          </div>
        </div>
      </div>
      {dividerNode}
    </div>
  );
};

OutcomeRow.propTypes = {
  participantId: PropTypes.number.isRequired,
  outcome: PropTypes.object.isRequired,
  hiddenEntryIds: PropTypes.array.isRequired,
  toggleOutcomeVisibility: PropTypes.func.isRequired,
  noBottomBorder: PropTypes.bool,
};

export default OutcomeRow;
