// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Auth from '@legacy-components/components/Auth';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import ParticipantActivityLink from '@legacy-components/react/V5/Common/ParticipantActivityLink';
import StatusIcon from '@legacy-components/react/V5/Common/StatusIcon';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import Clipboard from 'clipboard';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const ParticipantRow = (props) => {
  const lab = useLegacyLabContext();
  const coachingEngagement = props.coachingEngagement;
  const participant = coachingEngagement.participant;
  const [clipboard, setClipboard] = React.useState('');
  const timezone = props.user.time_zone.pytz_timezone;

  React.useEffect(() => {
    setClipboard(
      new Clipboard('.admin-v5_coach-dashboard-email', {
        text: function (trigger) {
          return trigger.getAttribute('copytext');
        },
      })
    );

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, []);

  const handleClick = () => {
    props.setSuccessAlert('Email copied to clipboard');
  };

  let photoNode = null;

  /* Name */
  if (participant.photo) {
    // Set up user photo instead of the default icon if set
    photoNode = (
      <UserPhoto src={participant.photo} diameter={40} hasBorder={false} />
    );
  } else {
    // Use the participant's name and user ID so that two users with the same name don't use the same color
    // Then convert each letter to a char code, sum and take the modulus so the same user is always the same color
    const participantName =
      participant.first_name +
      ' ' +
      participant.last_name +
      participant.user_id;
    const index = participantName
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc);

    const colors = [
      '#004493',
      '#1A7CD9',
      '##2196F3',
      '#0B8484',
      '#0AABB1',
      '#53E6EB',
      '#53E6EB',
      '#107D54',
      '#33B55F',
      '#2ACAA1',
      '#25E8C8',
      '#FF6B2A',
      '#F4AB2A',
    ];

    const iconStyles = {
      backgroundColor: colors[index % colors.length],
    };
    /* Name */
    photoNode = (
      <div style={iconStyles} className="admin-v5_coach-dashboard_default-icon">
        <span>
          {participant.first_name.substr(0, 1)}
          {participant.last_name.substr(0, 1)}
        </span>
      </div>
    );
  }

  // show link to their workspace
  const nameNode = (
    <NavigationLink
      url={`/lab/workspace/${participant.user_id}`}
      className="tc__link"
      target="_blank"
    >
      {participant.first_name + ' ' + participant.last_name}
    </NavigationLink>
  );

  /* Cohort / School */
  let cohortNode = null;

  if (
    Utils.isServicesManager.call(lab, props.pageUser) ||
    Utils.isContractCoach.call(lab, props.pageUser)
  ) {
    if (participant.cohort_overview) {
      // Show the name of the cohort with a link to open a modal showing the cohort's vision
      cohortNode = (
        <Tooltip
          tooltip="View cohort details"
          hideTooltip={props.artifactModal ? props.artifactModal.opened : false}
        >
          <a
            className="tc__link"
            onClick={() => {
              props.openCohortVision({
                cohortVision: coachingEngagement.cohort.cohort_overview,
                cohortName: coachingEngagement.cohort.cohort_name,
              });
            }}
          >
            {coachingEngagement.cohort.cohort_name}
          </a>
        </Tooltip>
      );
    } else {
      // Just display the cohort's name
      cohortNode = <span>{participant.cohort}</span>;
    }
  }

  /* Activity */
  let activityNode = (
    <div className="admin-v5_coach-dashboard__activity">No recent activity</div>
  );

  if (participant.registered) {
    if (participant.activity && participant.activity.date) {
      // Get the date of the last activity and adjust for the local timezone if available
      const baseDate = moment(participant.activity.date).tz(timezone);

      const activityDate = baseDate.fromNow();
      const dateTitle = baseDate.format('MMMM Do, h:mm:ss a');

      activityNode = (
        <div>
          <div className="admin-v5_coach-dashboard__activity">
            <Tooltip tooltip={participant.active_story_focus_area}>
              <ParticipantActivityLink
                activity={participant.activity}
                userId={participant.user_id}
                participantId={participant.id}
              />
            </Tooltip>
          </div>
          <Tooltip tooltip={dateTitle}>{activityDate}</Tooltip>
        </div>
      );
    }
  }
  /* Highlights */
  let highlightsColNode;
  // Use an open star or a closed star icon depending on the value
  const iconClass = participant.highlights_count
    ? 'tc-v5-star-solid'
    : 'tc-v5-star-empty';

  highlightsColNode = (
    <div className="grid-ten__col-1 grid-ten__col-1--center-h">
      <div>
        <span style={{ color: 'goldenrod' }} className={`${iconClass}`}>
          {' '}
        </span>
        {participant.highlights_count || 0}
      </div>
    </div>
  );

  const nextMeetingColClass =
    'grid-ten__col-2 grid-ten__col-2--space-between-h';

  /* Next meeting */
  // Display the participant's next meeting
  let nextMeetingNode = null;

  // Hide the add meeting link for non coach access users
  if (Auth.hasCoachAccess(props.user)) {
    nextMeetingNode = (
      <NavigationLink
        url={`/lab/participant/${participant.id}/meeting/details/add?from=coach_home`}
        className="tc__link clickable"
      >
        Add
      </NavigationLink>
    );
  }

  if (participant.next_meeting && participant.next_meeting.status === 1) {
    // Set a default value
    const nextMeeting = moment(participant.next_meeting.meeting_time).tz(
      timezone
    );

    nextMeetingNode = (
      <Tooltip tooltip={`Next meeting ${moment(nextMeeting).fromNow()}`}>
        <NavigationLink
          target="_blank"
          url={`/lab/participant/${participant.id}/meeting/${participant.next_meeting.id}/details`}
          className="tc__link"
        >
          <div className="admin-v5_coach-dashboard__next-meeting">
            {nextMeeting.format('dddd, MMMM D')}
          </div>
          <div>{`${Utils.hourRounder(nextMeeting)} ${nextMeeting.format(
            'A z'
          )}`}</div>
        </NavigationLink>
      </Tooltip>
    );
  }

  /* Participant Details */
  let surveyLinkNode = null;
  // icon to link to the survey response in the workspace
  if (participant.survey_story_entry_id) {
    surveyLinkNode = (
      <div className="admin-v5__icon-container">
        <Tooltip
          tooltip="View Self-Assesment Results"
          offsetLeft={-8}
          nowrap={true}
        >
          <NavigationLink
            url={`/lab/workspace/${participant.user_id}?scrollToEntryId=${participant.survey_story_entry_id}`}
            className="tc-v5-survey-icon admin-v5__icon--survey"
          ></NavigationLink>
        </Tooltip>
      </div>
    );
  }
  // icon to link to the participant details page
  const participantDetailsNode = (
    <div className="admin-v5__icons">
      {surveyLinkNode}
      <Tooltip tooltip="View Participant Details" offsetLeft={-8} nowrap={true}>
        <NavigationLink
          url={`/lab/participant/${participant.id}/details`}
          target="_blank"
          className="tc-v5-details admin-v5_coach-dashboard--participant-details"
        ></NavigationLink>
      </Tooltip>
    </div>
  );

  return (
    <div className="grid-ten__row">
      <div className="grid-ten__col-3 admin-v5__coach-participant-name-row">
        <div className="ppd-3-coach-table__user-image admin-v5_coach-dashboard-photo">
          <NavigationLink
            url={`/lab/workspace/${participant.user_id}`}
            className="tc__link"
          >
            {photoNode}
          </NavigationLink>
        </div>
        <div className="ppd-3-coach-table__user-details admin-v5_coach-dashboard-name">
          <div className="admin-v5_coach-dashboard--user-name">{nameNode}</div>
          <div
            className="tc__breakword clickable v5__author-copy admin-v5_coach-dashboard-email"
            copytext={participant.email}
            onClick={handleClick}
          >
            <Tooltip tooltip="Copy to clipboard">{participant.email}</Tooltip>
          </div>
        </div>
        <StatusIcon status={participant.status} />
      </div>

      <div className="grid-ten__col-3">{cohortNode}</div>

      <div className="grid-ten__col-2">{activityNode}</div>
      {highlightsColNode}
      <div className={nextMeetingColClass}>
        <div className="admin-v5_coach-dashboard--next-meeting">
          {nextMeetingNode}
        </div>
        <div>{participantDetailsNode}</div>
      </div>
    </div>
  );
};

ParticipantRow.propTypes = {
  coachingEngagement: PropTypes.object.isRequired,
};

export default ParticipantRow;
