// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import ScaleCenterImage from './../../ScaleCenterImage';
import Artifact from './Narrative/Artifact';

const Teacher = (props) => {
  // show/hide the artifacts for the given loop
  const handleToggleArtifacts = (e, loopId) => {
    e.preventDefault();
    props.toggleTeacherSpotlightArtifacts({ loopId: loopId });
  };
  // convenience vars
  const data = props.entry.data;
  const extended_data = props.entry.extended_data;

  // use the default teacher image if the teacher does not have one
  const teacherImage = data.teacher_image
    ? data.teacher_image
    : '/legacy-images/default-user.svg';

  // only show the grade/subject under the teacher name if we have the data
  let gradeSubjectNode = null;
  if (extended_data.grade || extended_data.subject) {
    const gradeSubjectText =
      extended_data.grade && extended_data.subject
        ? extended_data.grade + ', ' + extended_data.subject
        : extended_data.grade
        ? extended_data.grade
        : extended_data.subject;
    gradeSubjectNode = (
      <div className="ppp-teacher__grade-subject">{gradeSubjectText}</div>
    );
  }

  // only show these highlights when their value is not zero
  let coachMeetingsNode = null;
  if (data.coach_meetings) {
    const meetingsCaptionNode =
      parseInt(data.coach_meetings) === 1 ? 'Meeting' : 'Meetings';
    coachMeetingsNode = (
      <div className="ppp-teacher__data">
        <div className="ppp-teacher__data-value">{data.coach_meetings}</div>
        <div className="ppp-teacher__data-caption">
          Coach {meetingsCaptionNode} Attended
        </div>
      </div>
    );
  }
  let strategiesTriedNode = null;
  if (data.strategies_tried) {
    const strategiesCaptionNode =
      parseInt(data.strategies_tried) === 1 ? 'Strategy' : 'Strategies';
    strategiesTriedNode = (
      <div className="ppp-teacher__data">
        <div className="ppp-teacher__data-value">{data.strategies_tried}</div>
        <div className="ppp-teacher__data-caption">
          {strategiesCaptionNode} Tried
        </div>
      </div>
    );
  }
  let danielsonNode = null;
  if (
    extended_data.danielson_components &&
    extended_data.danielson_components.length
  ) {
    const danielsonCaptionNode =
      extended_data.danielson_components.length === 1
        ? 'Component'
        : 'Components';
    danielsonNode = (
      <div className="ppp-teacher__data">
        <div className="ppp-teacher__data-value">
          {extended_data.danielson_components.length}
        </div>
        <div className="ppp-teacher__data-caption">
          <div>Danielson {danielsonCaptionNode}</div>
          <div className="ppp-teacher__data-details">
            {extended_data.danielson_components.join(' ')}
          </div>
        </div>
      </div>
    );
  }
  let standardsNode = null;
  if (extended_data.core_standards && extended_data.core_standards.length) {
    const standardsCaptionNode =
      extended_data.core_standards.length === 1 ? 'Standard' : 'Standards';
    standardsNode = (
      <div className="ppp-teacher__data">
        <div className="ppp-teacher__data-value">
          {extended_data.core_standards.length}
        </div>
        <div className="ppp-teacher__data-caption">
          <div>State {standardsCaptionNode}</div>
          <div className="ppp-teacher__data-details">
            {extended_data.core_standards.join(' ')}
          </div>
        </div>
      </div>
    );
  }
  let hoursNode = null;
  const pdHours = extended_data.pedagogy_hours + extended_data.content_hours;
  if (pdHours) {
    const hoursCaptionNode = pdHours === 1 ? 'Hour' : 'Hours';
    hoursNode = (
      <div className="ppp-teacher__data">
        <div className="ppp-teacher__data-value">{pdHours}</div>
        <div className="ppp-teacher__data-caption">
          <div>{hoursCaptionNode} of PD</div>
          <div className="ppp-teacher__data-details">
            PEDAGOGY HOURS: {extended_data.pedagogy_hours}
          </div>
          <div className="ppp-teacher__data-details">
            CONTENT HOURS: {extended_data.content_hours}
          </div>
        </div>
      </div>
    );
  }

  // build the list of tml loops to show at the bottom
  let tmlLoopNodes = null;
  if (extended_data.tml_loops && extended_data.tml_loops.length) {
    tmlLoopNodes = (
      <div className="row">
        <div className="cute-12-desktop ppp-teacher__strategies-headline">
          <div className="ppp-teacher__border ppp-teacher__border--top"></div>
          Selected Strategies
        </div>
        {extended_data.tml_loops.map((strategy, i) => {
          // only show the growth if it's not zero
          let growthAboveBaselineNode = null;
          if (strategy.growth_above_baseline) {
            growthAboveBaselineNode = (
              <div className="ppp-teacher__growth">
                Growth Above Baseline:
                <span className="ppp-teacher__growth-value">
                  {strategy.growth_above_baseline}%
                </span>
              </div>
            );
          }
          // only show the Artifacts toggle link if there are artifacts to display
          let artifactsLinkNode = null;
          if (strategy.artifacts && strategy.artifacts.length) {
            // the artifact link arrow is rotated when active
            const rotateArrowClass = strategy.showArtifacts ? ' active' : '';
            artifactsLinkNode = (
              <div
                className="ppp-teacher__artifacts-link"
                onClick={(e) => handleToggleArtifacts(e, strategy.loopId)}
              >
                <div
                  className={
                    'tc-browser-arrow ppp-teacher__artifacts-link-icon' +
                    rotateArrowClass
                  }
                ></div>
                Artifacts ({strategy.artifact_count})
              </div>
            );
          }

          return (
            <div
              className="ppp-teacher__strategy cute-12-desktop"
              key={'strategy-' + strategy.id + '-' + i}
            >
              {i !== 0 && <div className="ppp-teacher__border"></div>}
              <div className="ppp-teacher__strategy-header">Strategy</div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="tc__link ppp-teacher__strategy-title"
                href={'/coaching/lab/strategy/' + strategy.id}
              >
                {strategy.title}
              </a>
              <div className="row">
                <div className="cute-4-desktop">
                  <div
                    className={
                      'ppp__strategy-detailed-icon tc-sga-nb-' +
                      strategy.growth_area.id
                    }
                  ></div>
                  <div className="ppp__strategy-detailed-subject">
                    <div className="ppp-teacher__strategy-header">
                      {strategy.subject_name} Growth Area
                    </div>
                    <div className="tc__body-copy">
                      {strategy.growth_area.name}
                    </div>
                  </div>
                </div>
                <div className="cute-4-desktop left">
                  <div className="ppp-teacher__strategy-header">
                    Teaching Challenge
                  </div>
                  <div className="tc__body-copy">
                    {strategy.teaching_challenge.name}
                  </div>
                </div>
              </div>
              <PurifiedHTML
                className="tc__body-copy ppp-teacher__strategy-description"
                content={strategy.description}
              />

              {growthAboveBaselineNode}
              {artifactsLinkNode}
              {strategy.showArtifacts &&
                strategy.artifacts &&
                strategy.artifacts.map((artifact) => {
                  return (
                    <Artifact
                      {...artifact}
                      showArtifact={props.showArtifact}
                      key={'artifact-' + artifact.id}
                    />
                  );
                })}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="ppp__section">
      <div className="row">
        <div className="ppp-teacher__teacher-info cute-12-desktop">
          <ScaleCenterImage
            src={teacherImage}
            className="ppp-teacher__user-image"
          />
          <div className="ppp-teacher__teacher-name">{data.teacher_name}</div>
          {gradeSubjectNode}
        </div>
      </div>
      <div className="row">
        {coachMeetingsNode}
        {strategiesTriedNode}
        {danielsonNode}
        {standardsNode}
        {hoursNode}
      </div>
      {tmlLoopNodes}
    </div>
  );
};

Teacher.propTypes = {
  entry: PropTypes.object.isRequired,
  toggleTeacherSpotlightArtifacts: PropTypes.func.isRequired,
};

export default Teacher;
