// @ts-nocheck FIXME
import FavoriteApi from '@legacy-components/apis/V4/FavoriteApi';
import Analytics from '@legacy-components/components/Analytics';
import Utils from '@legacy-components/components/DynamicUtils';
import Modal from '@legacy-components/components/Modal';
import Store from '@legacy-components/components/Store';
import HeaderStore from '@legacy-components/stores/V4/HeaderStore';
import { delayedEventListener } from '@utils/EventListenerHandler';

const store = {
  user: null,
  ui: {
    alert: {},
    tab: 'curriculum',
    domains: [],
    unitsMenuExpanded: false,
    domainSelectorOpened: false,
    currentDomain: {},
    participants: {
      inputStatus: 'blur',
      charCount: 0,
      selectedOption: null,
      options: [],
      visibleOptions: [],
    },
    recommendModal: {},
    currentVideoIndex: 0,
  },
};

/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

const setAlert = (message, type) => {
  store.ui.alert = {
    type: type,
    message: message,
    handleClose: store.clearAlert,
  };
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.user = data.user;
  store.ui.alert = {};
  // make sure the active tab is valid
  store.ui.tab =
    ['strategies', 'curriculum', 'blended', 'about'].indexOf(data.tab) === -1
      ? 'curriculum'
      : data.tab;
  store.ui.currentVideoIndex = 0;

  if (data.lab_data && data.lab_data.domains && data.lab_data.domains.length) {
    const selectedDomain =
      data.lab_data.domains.find(
        (domain) => domain.id === data.selectedDomain
      ) || data.lab_data.domains[0];

    store.ui.currentDomain = selectedDomain;
    // map these domains so we can show dropdown options
    store.ui.domains = data.lab_data.domains.map((domain) => {
      return {
        id: domain.id,
        seo_url: domain.seo_url,
        title: domain.title,
        strategyCount: domain.strategies.length,
      };
    });
  }

  // set up Recommend modal for coaches
  store.ui.recommendModal = Modal(store, store.ui.recommendModal);
  store.ui.participants.options = data.lab_data
    ? data.lab_data.participants
    : [];
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.expandUnitsMenu = () => {
  Analytics.logEvent({
    category: 'unit_dd',
    action: 'open',
    label: 'from_master_teacher_curriculum',
  });

  store.ui.unitsMenuExpanded = true;

  delayedEventListener(() =>
    document.addEventListener('click', store.collapseUnitsMenu)
  );
};

store.collapseUnitsMenu = () => {
  Analytics.logEvent({
    category: 'unit_dd',
    action: 'close',
    label: 'from_master_teacher_curriculum',
  });
  store.ui.unitsMenuExpanded = false;
  document.removeEventListener('click', store.collapseUnitsMenu);
};

store.openDomainSelector = () => {
  store.ui.domainSelectorOpened = true;

  delayedEventListener(() =>
    document.addEventListener('click', store.closeDomainSelector)
  );
};

store.closeDomainSelector = () => {
  store.ui.domainSelectorOpened = false;
  document.removeEventListener('click', store.closeDomainSelector);
};

store.getRecommendElement = (data) => {
  const field = store.ui.participants;
  field.charCount = data.input.length;

  // Only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    return option.label.toLowerCase().includes(data.input.toLowerCase());
  });
};

store.selectRecommendElement = (data) => {
  Object.assign(store.ui.participants, data.data);
};

store.addFavorite = (data) => {
  if (!store.user || !store.user.id) {
    HeaderStore.actionDispatch('openRegisterModal', {
      reason: 'favorite_attempt',
    });
    return true;
  }
  const strategies = store.ui.currentDomain.strategies;
  const index = Utils.getItemIndexFromArrayOfObjects(
    strategies,
    'id',
    data.itemId
  );
  if (data.recommend) {
    // make sure an option was selected
    if (!store.ui.participants.selectedOption) {
      return;
    }
    data.userId = store.ui.participants.selectedOption.user_id;
  } else {
    Analytics.logEvent({
      category: 'strategy',
      action: 'favorite',
      label: 'from_domain_master_teacher_profile',
    });
    strategies[index].favorite = {};
    strategies[index].favorite.status = 'loading-favorite';
  }

  return FavoriteApi.addFavorite(data)
    .then((response) => {
      if (data.recommend) {
        setAlert(
          'Recommended for ' +
            store.ui.participants.selectedOption.first_name +
            ' ' +
            store.ui.participants.selectedOption.last_name,
          'alert'
        );
        store.ui.participants.charCount = 0;
        store.ui.participants.selectedOption = null;
        store.ui.participants.visibleOptions = [];
        store.ui.participants.inputStatus = 'blur';
        store.ui.recommendModal.close();
        strategies[index].favorite_count++;
        return;
      }
      strategies[index].favorite_count++;
      strategies[index].favorite = response.payload;
    })
    .catch((response) => {
      delete strategies[index].favorite;
      store.ui.participants.charCount = 0;
      store.ui.participants.selectedOption = null;
      store.ui.participants.visibleOptions = [];
      store.ui.participants.inputStatus = 'blur';
      store.ui.recommendModal.close();
      if (!response.access) {
        setErrorAlert(
          response.error
            ? response.error
            : 'Sorry, something went wrong. Please try again later.'
        );
      }
    });
};

store.removeFavorite = (data) => {
  const strategies = store.ui.currentDomain.strategies;
  const index = Utils.getItemIndexFromArrayOfObjects(
    strategies,
    'id',
    data.itemId
  );
  strategies[index].favorite.status = 'loading-unfavorite';

  Analytics.logEvent({
    category: 'strategy',
    action: 'unfavorite',
    label: 'from_domain_master_teacher_profile',
  });

  return FavoriteApi.removeFavorite(data)
    .then(() => {
      strategies[index].favorite = null;
      strategies[index].favorite_count--;
    })
    .catch((response) => {
      strategies[index].favorite.status === 'selected';
      setErrorAlert(response.error);
    });
};

store.setCurrentVideo = (index) => {
  store.ui.currentVideoIndex = index;
};

export default Store('MasterTeacherProfileStore', store);
