// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import { Router } from '@legacy-components/components/Router';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import React from 'react';
import HeaderStore from './../stores/V4/HeaderStore';

class LessonLink extends React.Component {
  static contextType = LegacyLabContext;

  state = {
    classList: this.props.className,
  };

  url = null;

  componentWillReceiveProps(nextProps) {
    this.setState({ classList: nextProps.className });
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // determine if free content limit is reached
    let reachedFreeContentLimit = false;
    // if strategy and strategy is not open, then check if limit is reached
    if (this.props.strategy && this.props.publishStatus !== 'open') {
      reachedFreeContentLimit = Utils.reachedFreeContentLimit.call(
        this.context,
        null,
        parseInt(this.props.strategy)
      );
    } else if (this.props.lesson) {
      reachedFreeContentLimit = Utils.reachedFreeContentLimit.call(
        this.context,
        parseInt(this.props.lesson),
        null
      );
    }

    // if the logged out user has reached the limit of 5 free content pages,
    // show the registration modal
    if (reachedFreeContentLimit) {
      if (!this.props.user || (this.props.user && !this.props.user.id)) {
        HeaderStore.actionDispatch('openRegisterModal', {
          reason: 'resource_count_reached',
        });
        return;
      }
    }

    if (this.props.target && this.props.target === '_blank') {
      // We want to open the link in a new window. Return:
      window.open(this.url, '_blank');
      return;
    }

    if (!this.url) {
      return;
    }

    // if link is a  button dissable on click
    if (this.props.className && this.props.className.indexOf('tc__btn') > -1) {
      this.setState({
        classList: this.props.className + ' disabled',
      });
    }

    if (this.props.isMtp || this.url.match(/^http|^\/\//gi)) {
      window.location = this.url;
      return;
    }

    Router.route(this.url);
  };

  render() {
    const className = this.state.classList || null;
    const target = this.props.target ? this.props.target : '_self';
    const title = this.props.title ? this.props.title : '';
    const id = this.props.id ? this.props.id : null;
    this.url = this.props.url;
    if (this.props.override) {
      this.url = this.props.override(this.props.url);
    }
    if (id) {
      return (
        <a
          id={id}
          onClick={this.handleClick}
          href={this.url}
          className={className}
          target={target}
          title={title}
        >
          {this.props.children}
        </a>
      );
    }
    return (
      <a
        onClick={this.handleClick}
        href={this.url}
        className={className}
        target={target}
        title={title}
      >
        {this.props.children}
      </a>
    );
  }
}

const WrappedLessonLink = (props) => (
  <HeaderConsumer>
    {({ user }) => <LessonLink {...props} user={user} />}
  </HeaderConsumer>
);

export default WrappedLessonLink;
