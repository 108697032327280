// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';

export const resourcePerms = Object.freeze({
  BL_CONTRACT_COACH: 'BL_CONTRACT_COACH',
  BL_MANAGER: 'BL_MANAGER',
  BL_SERVICES_MANAGE: 'BL_SERVICES_MANAGE',
  BL_TEAM_MEMBER: 'BL_TEAM_MEMBER',
  TC_CONTENT_CURATOR: 'TC_CONTENT_CURATOR',
  TC_DATA_VIEWER: 'TC_DATA_VIEWER',
  ADMIN: 'ADMIN',
});

export default function useResourceAuth() {
  const lab = useLegacyLabContext();

  const hasResourcePerms = (perms) =>
    lab.pageData.user.auth_roles.some((role) => perms.includes(role));

  const filteredProducts = () =>
    Object.values(lab.pageData.allProductCodes).filter(
      (product) => !!product.coaching_design && product.service === 'Coaching'
    );

  const getDesignDocLink = (focus_area) => {
    const found = filteredProducts().find(
      (product) => product.focus_area === focus_area
    );
    return found ? found.coaching_design : undefined;
  };

  return {
    hasResourcePerms,
    getDesignDocLink,
  };
}
