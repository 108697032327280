// @ts-nocheck FIXME
import { useEffect, useState } from 'react';
import NavigationLink from './../../../NavigationLink';

const FavoritesCapsule = (props) => {
  const [backgroundImageClass, updateBackground] = useState({});
  useEffect(() => {
    const image = new Image();
    image.src = props.favorite.previewPhoto;

    image.onload = () => {
      updateBackground({
        backgroundImage: `url("${props.favorite.previewPhoto}")`,
      });
    };
  }, []);
  const type = props.favorite.strategy ? 'strategy' : 'lesson';
  const favoriteId = props.favorite.strategy
    ? props.favorite.strategy.id
    : props.favorite.lesson.id;
  const favoriteTitle = props.favorite.strategy
    ? props.favorite.strategy.title
    : props.favorite.lesson.name;
  const favoriteLink =
    process.env.REACT_APP_TEACHING_URL +
    (props.favorite.strategy
      ? `/strategy/${props.favorite.strategy.id}?from=home_favorites_carousel`
      : `/lesson/${props.favorite.lesson.id}`);
  const recommendedNode =
    props.favorite.status == 'recommended' ? (
      <div className="home-v5__favorites-recommended v5__heading-6">
        recommended
      </div>
    ) : null;
  const courseTitle =
    props.favorite.type == 'lesson' && props.favorite.lesson.course_title ? (
      <div className="home-v5__favorites-course-title v5__heading-6">
        {props.favorite.lesson.course_title}
      </div>
    ) : null;
  return (
    <div
      className={'home-v5__favorites-card-container'}
      key={`profile-favorites-${props.favorite.id}`}
    >
      <NavigationLink className="v5__link" url={favoriteLink}>
        <div className="home-v5__favorites-card" style={backgroundImageClass}>
          <div className="home-v5__favorite-link-text v5__heading-4">
            {favoriteTitle}
            {courseTitle}
            {recommendedNode}
          </div>
        </div>
      </NavigationLink>
    </div>
  );
};

export default FavoritesCapsule;
