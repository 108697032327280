// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import moment from 'moment';
import PropTypes from 'prop-types';
import ScaleCenterImage from './../../../../ScaleCenterImage';
import AbridgeText from './../../../Common/AbridgeText';

const ReflectionSlide = (props) => {
  // open the artifact overlay when clicking on the reflection
  const openArtifactModal = () => {
    const data = Utils.clone(props);
    data.storyId = props.storyId;
    data.entryType = 'reflection';
    if (props.type === 'text') {
      data.connections = {
        preview_type: 'ckeditor_output',
      };
    }
    data.analyticsCategory = 'reflection_modal';

    props.portfolioArtifactModal.open(data);
  };

  // body contents depend on entry type
  let bodyNode;
  switch (props.type) {
    case 'text':
      bodyNode = (
        <div>
          <AbridgeText maxHeight={140}>
            <PurifiedHTML
              className="ckeditor__output"
              content={props.description}
            />
          </AbridgeText>
          <div className="bl4-profile__story-highlights-section-date">
            {moment(props.created_formatted).format('MMMM D, YYYY')}
          </div>
        </div>
      );
      break;
    case 'file':
    case 'link':
    case 'meeting_clip': {
      let imgNode = (
        <div className="bl4-profile__story-highlights-reflection-img-mask"></div>
      );
      if (props.connections.preview) {
        imgNode = (
          <div className="bl4-profile__story-highlights-reflection-img-container">
            <ScaleCenterImage
              src={props.connections.preview}
              className="bl4-profile__story-highlights-reflection-img"
            />
          </div>
        );
      }
      bodyNode = (
        <div className="bl4-profile__story-highlights-reflection-body-with-img">
          {imgNode}
          <AbridgeText maxHeight={150}>
            <div className="tc__link tc__link--black bl4-profile__story-highlights-section-headline">
              {props.title}
            </div>
            <div className="bl4-profile__story-highlights-section-date bl4-profile__story-highlights-section-subheadline">
              {moment(props.created_formatted).format('MMMM D, YYYY')}
            </div>
            <div className="tc__body-copy">{props.description}</div>
          </AbridgeText>
        </div>
      );
    }
  }

  return (
    <div
      onClick={openArtifactModal}
      className="bl4-profile__story-highlights-reflection bl4-profile__story-highlights-reflection--clickable"
    >
      {bodyNode}
    </div>
  );
};

ReflectionSlide.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  connections: PropTypes.object,
  title: PropTypes.string,
  created_formatted: PropTypes.string,
  description: PropTypes.string,
  storyId: PropTypes.number.isRequired,
  portfolioArtifactModal: PropTypes.object.isRequired,
};

export default ReflectionSlide;
