// @ts-nocheck FIXME
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import PropTypes from 'prop-types';
import React from 'react';
import AbsoluteAlert from './../../Alerts/Absolute';
import AccountLoginForm from './modules/AccountLoginForm';
import AccountRegistration from './modules/AccountRegistration';
import BulkRegistration from './modules/BulkRegistration';
import CohortDetails from './modules/CohortDetails';
import ForgotPassword from './modules/ForgotPassword';
import RegistrationForm from './modules/RegistrationForm';
import RegistrationSteps from './modules/RegistrationSteps';
import ResetPassword from './modules/ResetPassword';

const RegistrationContainer = (props) => {
  React.useEffect(() => {
    props.setResponsive(true);
    return () => props.setResponsive(false);
  }, []);

  const gotoCreateAccount = () => {
    props.setHasAccount(false);
    props.clearErrors();
    props.setRecaptchaResponse(null);
  };

  const handleGoHome = () => {
    window.location = '/';
  };
  const redirectUrl = `/lab/cohort/${props.cohort.id}/${props.userType}/register`;

  // default to the create user page
  let visibleForm = (
    <AccountRegistration
      {...props}
      showHelpLink={true}
      helpCenterHeadline="Need help with your RSVP?"
      handleCreateAccount={props.register}
      redirectUrl={redirectUrl}
    />
  );

  if (props.ui.showSetPassword) {
    // Show the reset password form
    if (props.isLoggedIn) {
      visibleForm = <BulkRegistration {...props} />;
    } else {
      visibleForm = <ResetPassword {...props} />;
    }
  } else if (props.ui.showForgotPassword) {
    // Show the forgot password form
    visibleForm = <ForgotPassword {...props} />;
  } else if (props.isLoggedIn || props.accountCreated) {
    // Show the registration page
    visibleForm = (
      <RegistrationForm
        {...props}
        handleRegisterForCohort={props.registerForCohort}
        showHelpLink={true}
      />
    );
  } else if (props.hasAccount) {
    // Show the login screen
    visibleForm = (
      <div className="registration-v5-event__register">
        <div className="registration-v5-event__register-header v5__heading-3">
          {'Get Your Personal Coach'}
        </div>
        <div className="registration-v5-event__register-body v5__body-copy">
          <RegistrationSteps
            hasAccount={props.hasAccount}
            stepOneActive={true}
          />
          <div className="row">
            <div className="cute-12-desktop">
              <div className="v5__heading-5">Log In</div>
              <span className="v5__body-copy">Don&apos;t have an account?</span>
              <span onClick={gotoCreateAccount} className="tc__link">
                {' '}
                Create account
              </span>
            </div>
          </div>
          <AccountLoginForm {...props} redirectUrl={redirectUrl} />
        </div>
      </div>
    );
  }

  let alertNode;
  if (props.ui.errors.general && props.ui.errors.general.length) {
    alertNode = (
      <AbsoluteAlert
        preventAutoclose={props.ui.persistErrors}
        type="error"
        message={props.ui.errors.general[0]}
        handlelose={props.clearErrors}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  return (
    <div className="tc__page tc__page--registration">
      <div className="tc-header__wrap tc-header__wrap--v2">
        <div className="tc-header tc-header--v2 row--responsive">
          <div
            className="logo tc-header__logo cute-3-desktop cute-3-phone left tc-header__registration-phone"
            onClick={handleGoHome}
            src="/legacy-images/betterlesson-logo.svg"
          ></div>
        </div>
      </div>
      {alertNode}

      <div className="coaching-reg__background-image"></div>
      <div className="event-reg__background-overlay"></div>

      <div className="row row--1200 tc__page--registration registration-v5__container">
        <div className="cute-5-desktop cute-12-phone left">
          <CohortDetails cohort={props.cohort} />
        </div>
        <div className="cute-6-desktop cute-12-phone">{visibleForm}</div>
      </div>
    </div>
  );
};

RegistrationContainer.propTypes = {
  ui: PropTypes.object.isRequired,
};

const ConnectedRegistrationContainer = (props) => (
  <HeaderConsumer>
    {({ setResponsive }) => (
      <RegistrationContainer {...props} setResponsive={setResponsive} />
    )}
  </HeaderConsumer>
);

export default ConnectedRegistrationContainer;
