// @ts-nocheck FIXME
import { useRef } from 'react';

const WheelhouseOptionSelect = (props) => {
  const fieldOption = useRef();

  const handleSelectChange = () => {
    //depending on if this is a gradeband or subject set the id
    const output = { ...props.optionOutputBase };
    output.answer = fieldOption.current.value;
    if ('subjectId' in output) {
      output.gradeBandId = props.option.id;
    } else {
      output.subjectId = props.option.id;
    }
    props.handleUpdateWheelhouse(output);
  };
  return (
    <div
      className={`account-wheelhouse__category ${
        !props.expanded ? 'tc__hidden' : ''
      }`}
    >
      <div className="settings__label settings__label--wheelhouse">
        {props.option.label}
      </div>
      <select
        ref={fieldOption}
        className=" settings__select--whelhouse v5__input v5__select"
        defaultValue={props.defaultValue}
        onChange={handleSelectChange}
      >
        <option></option>
        <option value="wh">Wheelhouse</option>
        <option value="zpd">ZPD</option>
        <option value="tor">Teacher of Record</option>
      </select>
    </div>
  );
};

export default WheelhouseOptionSelect;
