// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Analytics from './../../../components/Analytics';
import CKEditor from './../Common/CKEditor/CKEditor';
import FreezeWindowScroll from './../Common/FreezeWindowScroll';
import MoveButton from './../Common/MoveComponents/Button';
import MoveForm from './../Common/MoveComponents/Form';
import Spinner from './../Common/Spinner';

const TextModal = (props) => {
  const formFieldsAreDisabled =
    props.confirmDelete.visible || props.confirmMove.visible;
  const disabledCssClass = formFieldsAreDisabled ? ' disabled ' : '';

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleClearError = (field) => {
    if (props.errors[field]) {
      props.setErrorField({
        field: field,
        error: null,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      data: CKEDITOR.instances[
        'vision-artifact-wysiwyg-textArtifact-0'
      ].getData(),
      artifactId: props.data.id,
    };
    if (!data.data) {
      props.setFieldError({
        field: 'text',
        error: 'Please enter your text',
      });
      return false;
    }
    props.submitTextArtifact(data);
  };

  const toggleConfirmDelete = (e) => {
    e.preventDefault();
    const data = {
      analyticsLabel: 'from_text_artifact_modal_vision_page',
      analyticsCategory: 'text_artifact',
      analyticsAction: props.confirmDelete.visible ? 'cancel_delete' : 'delete',
      element: 'confirmDelete',
    };
    props.toggleElement(data);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    props.deleteArtifact({
      id: props.data.id,
      analyticsLabel: 'from_text_artifact_modal_vision_page',
      analyticsCategory: 'text_artifact',
    });
  };
  const handleCancel = () => {
    Analytics.logEvent({
      category: 'text_artifact',
      action: 'cancel_edit',
      label: 'from_text_artifact_modal_vision_page',
    });
    props.close({ callStoreCallback: false });
  };
  const handleFocus = () => {
    handleClearError('text');
  };

  const toggleMoveArtifact = (e) => {
    e.preventDefault();
    props.toggleElement({
      analyticsCategory: 'entry',
      analyticsLabel: props.confirmMove.visible
        ? 'from_move_vision_page_entry_modal'
        : 'from_vision_page_edit_entry_modal',
      analyticsAction: props.confirmMove.visible ? 'cancel_move' : 'move',
      element: 'confirmMove',
    });
  };

  const getActionsNode = () => {
    let actionsNode, deleteOption, moveOption, loadingNode;
    const btnDisabledClass = props.loading ? ' disabled' : '';

    if (props.confirmDelete.visible) {
      return (
        <div className="outcome-story__modal-btns">
          <div className="outcome-story__modal-confirm-delete-text">
            Are you sure you want to delete this text?
          </div>
          {errorAlertNode}
          <div className="outcome-story__modal-btns tc__center-text">
            <div
              onClick={(e) => handleDelete(e)}
              className="tc__btn tc__btn--flat-red outcome-story__modal-btn"
            >
              Delete
            </div>
            <div
              onClick={toggleConfirmDelete}
              className="tc__btn tc__btn--flat-grey outcome-story__modal-btn"
            >
              Cancel
            </div>
          </div>
        </div>
      );
    }

    if (props.confirmMove.visible) {
      return (
        <MoveForm
          {...props.confirmMove}
          cssClassNamePrefix="outcome-story"
          selectSingleSelectElement={props.selectSingleSelectElement}
          getSingleSelectElement={props.getSingleSelectElement}
          feature="confirmMove"
          field="participantOutcomes"
          setFeatureFieldError={props.setFeatureFieldError}
          submit={props.moveArtifact}
          id={props.data.id}
          cancel={toggleMoveArtifact}
          loading={props.loading}
          containerName="textArtifactModal"
        />
      );
    }

    // Display the delete/move nodes for existing entries
    if (props.data && props.data.id) {
      deleteOption = (
        <div className="partiacipant-artifact__delete tc__center-text">
          <a
            className="participant-artifact__upload-link"
            onClick={toggleConfirmDelete}
          >
            <div className="partiacipant-artifact__delete-icon tc-delete"></div>
            <div className="partiacipant-artifact__delete-caption">Delete</div>
          </a>
        </div>
      );
      moveOption = (
        <MoveButton
          cssClassNamePrefix="partiacipant-artifact"
          onClick={toggleMoveArtifact}
          label="Move Artifact"
        />
      );
    }

    return (
      <div>
        <div className="outcome-story__modal-btns">
          {errorAlertNode}
          <button
            onClick={handleSubmit}
            className={
              'tc__btn tc__btn--flat-blue outcome-story__modal-btn' +
              btnDisabledClass
            }
          >
            <Spinner loading={props.loading} />
            {props.data && props.data.id ? 'Update' : 'Add'}
          </button>
          <button
            onClick={handleCancel}
            className="tc__btn tc__btn--flat-grey outcome-story__modal-btn"
          >
            Cancel
          </button>
        </div>
        {deleteOption}
        {moveOption}
      </div>
    );
  };

  // set up any errors on the wysiwyg
  let textErrorClass = '';
  let textErrorNode = null;
  if (props.errors && props.errors.text) {
    textErrorClass = ' error';
    textErrorNode = <div className="tc__error">{props.errors.text}</div>;
  }

  // set up any errors that pertain to the whole modal
  let errorAlertNode = null;
  if (props.errors && props.errors.api) {
    errorAlertNode = (
      <div className="outcome-story__modal-error-alert-row">
        <span className="outcome-story__modal-error-alert">
          {props.errors.api}
        </span>
      </div>
    );
  }

  let moveFormVisibleClass = '';
  if (props.confirmMove.visible) {
    moveFormVisibleClass = props.confirmMove.visible
      ? ' outcome-story__modal-form--move-visible'
      : moveFormVisibleClass;
  }
  return (
    <div onClick={handleStopPropagation}>
      <div
        className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
        onClick={(e) => props.close()}
      ></div>

      <div className="modal--fixed modal--grey-overlay outcome-story__modal">
        <div
          className="modal__close hide-phone show-desktop"
          onClick={(e) => props.close()}
        >
          <div className="tc-x"></div>
        </div>

        <div className="tc__mobile-header show-phone hide-desktop">
          <div
            className="tc__back cute-2-desktop left"
            onClick={(e) => props.close()}
          >
            <div className="tc__back-arrow tc-arrow"></div>
            <div className="tc__back-caption">Back</div>
          </div>
          <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
            {props.data && props.data.id ? 'Edit Text' : 'Add Text'}
          </div>
        </div>

        <div className="outcome-story__modal-body">
          <div className="outcome-story__modal-headline show-desktop hide-phone">
            {props.data && props.data.id ? 'Edit Text' : 'Add Text'}
          </div>

          <form
            className={
              'outcome-story__modal-form outcome-story__modal-form--movable outcome-story__modal-form--movable' +
              moveFormVisibleClass
            }
          >
            <div
              className={
                'outcome-story__text-wysiwyg-container ' +
                textErrorClass +
                disabledCssClass
              }
            >
              <CKEditor
                id="textArtifact"
                increment={0}
                innerText={props.data && props.data.data}
                className={'vision-artifact-wysiwyg'}
                configs="/legacy-libs/configs/bl-cke-config-outcome-story.js"
                handleFocus={handleFocus}
                disabled={formFieldsAreDisabled}
              />
              {textErrorNode}
            </div>
            {getActionsNode()}
          </form>
        </div>
      </div>
      <FreezeWindowScroll parentModal="outcome-story__modal" />
    </div>
  );
};

TextModal.propTypes = {
  submitTextArtifact: PropTypes.func.isRequired,
  toggleElement: PropTypes.func.isRequired,
  deleteArtifact: PropTypes.func.isRequired,
  confirmDelete: PropTypes.object.isRequired,
  getSingleSelectElement: PropTypes.func.isRequired,
  selectSingleSelectElement: PropTypes.func.isRequired,
  setFeatureFieldError: PropTypes.func.isRequired,
  moveArtifact: PropTypes.func.isRequired,
  confirmMove: PropTypes.object.isRequired,
};

export default TextModal;
