// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import React from 'react';
import NavigationLink from './../../../../NavigationLink';
import ArtifactImage from './../../../../V4/Common/ArtifactImage';
import AbridgeText from './../../../Common/AbridgeText';

const DocumentEntry = (props) => {
  const inputEL = React.useRef(null);

  const isBlConnect = (connections) => {
    if (!connections.link) return;
    const urls = ['benchprep.com', 'connect.betterlesson.com'];
    return urls.some((url) => connections.link.includes(url));
  };

  React.useEffect(() => {
    if (props.smallView) {
      const elem = inputEL.current;
      const elemHeight = elem.getBoundingClientRect().height;

      if (elemHeight > 300) {
        elem.style.height = '215px';
        elem.style.overflowY = 'hidden';
        props.setTruncateState(true);
      }
    }
  }, []);

  let imageNode;

  if (!props.hide_preview && props.connections) {
    // files route to the artifact page and links open the link in a new tab
    let imgLinkProps = {
      url: `/lab/${props.source_page}/artifact/${props.story_file_id}`,
    };
    if (props.type === 'link') {
      imgLinkProps = {
        url: props.connections.link,
        target: '_blank',
      };
    }
    imageNode = (
      <NavigationLink {...imgLinkProps}>
        <ArtifactImage
          preview={props.connections.preview}
          title={props.connections.name}
          containerClass={'workspace__entry-image-container'}
          icon={props.connections.icon}
          imageClass="workspace__entry-image"
          isBlConnect={isBlConnect(props.connections)}
        />
      </NavigationLink>
    );
  }

  let descriptionNode;
  if (props.description) {
    let toggle = true;
    if (props.toggle === false) {
      toggle = false;
    }

    descriptionNode = toggle ? (
      <AbridgeText
        maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
        isExpandable={true}
        className="workspace__entry-image-description v5__body-copy"
        greyFade={props.greyFade}
      >
        {props.description}
      </AbridgeText>
    ) : (
      props.description
    );
  }

  // if there is no image and no description, just return
  if (!imageNode && !descriptionNode) {
    return null;
  }

  return (
    <div className="workspace__entry-body">
      <div className="workspace__entry-image-row" ref={inputEL}>
        {imageNode}
        {descriptionNode}
      </div>
    </div>
  );
};

DocumentEntry.propTypes = {
  story_file_id: PropTypes.number,
  type: PropTypes.string,
  description: PropTypes.string,
  connections: PropTypes.object,
  source_page: PropTypes.string,
  smallView: PropTypes.bool,
  setTruncateState: PropTypes.func,
  toggle: PropTypes.bool,
  greyFade: PropTypes.bool,
};

DocumentEntry.defaultProps = {
  source_page: 'workspace',
};

export default DocumentEntry;
