// @ts-nocheck FIXME
import React from 'react';

const NoteContainer = (Overlay) => {
  return (
    ///////////////

    // eslint-disable-next-line react/display-name
    class extends React.Component {
      componentDidMount() {
        document.body.setAttribute('class', 'no-scroll');
      }

      componentWillUnmount() {
        document.body.removeAttribute('class');
      }

      render() {
        return Overlay;
      }
    }
  );
};

export default NoteContainer;
