// @ts-nocheck FIXME
import ParticipantMeetingApi from '@legacy-components/apis/ParticipantMeetingApi';
import { History, Router } from '@legacy-components/components/Router';
import React from 'react';
import ZenScroll from 'zenscroll';
import ParticipantMeetingDetailsView from './ParticipantMeetingDetailsView';

class ParticipantMeetingDetailsContainer extends React.Component {
  componentDidMount = () => {
    if (this.props.scrollToSection === 'videos') {
      const videoSection = document.getElementById('videos');
      ZenScroll.setup(600, 20);
      ZenScroll.to(videoSection);
    }
  };

  clearAlert = () => {
    this.setState({ alert: {} });
  };

  setAlert = (type, message) => {
    this.setState({
      alert: {
        type: type,
        message: message,
        handleClose: this.state.clearAlert,
      },
    });
  };

  closeMeetingClipModal = () => {
    this.setState({
      editMeetingClipModal: {
        ...this.state.editMeetingClipModal,
        opened: false,
        data: {},
      },
    });
  };

  closePublishClipModal = () => {
    this.setState({
      publishMeetingClipModal: {
        ...this.state.publishMeetingClipModal,
        opened: false,
        data: {},
      },
    });
  };

  openMeetingClipModal = (clip) => {
    this.setState({
      editMeetingClipModal: {
        ...this.state.editMeetingClipModal,
        opened: true,
        data: clip,
      },
    });
  };

  openPublishClipModal = (clip) => {
    this.setState({
      publishMeetingClipModal: {
        ...this.state.publishMeetingClipModal,
        opened: true,
        data: clip,
      },
    });
  };

  openConfirmDisconnectZoomModal = () => {
    this.setState({
      confirmDisconnectZoomModal: {
        ...this.state.confirmDisconnectZoomModal,
        opened: true,
      },
    });
  };

  closeConfirmDisconnectZoomModal = () => {
    this.setState({
      confirmDisconnectZoomModal: {
        ...this.state.confirmDisconnectZoomModal,
        opened: false,
      },
    });
  };

  disconnectZoomForMeeting = () => {
    ParticipantMeetingApi.disconnectZoom({ id: this.state.meeting.id })
      .then(() => {
        // clear the Zoom fields on the current meeting
        const meeting = { ...this.state.meeting };
        meeting.zoom_meeting_id = null;
        meeting.zoom_join_link = null;
        this.setState({ meeting: meeting });
        // close the confirmation modal
        this.closeConfirmDisconnectZoomModal();
      })
      .catch(() => {
        this.setAlert(
          'error',
          'Oh no! Looks like something broke on our end. Wait a minute and try again.'
        );
        this.closeConfirmDisconnectZoomModal();
      });
  };

  updateMeetingDetails = (meeting) => {
    const newMeeting = { ...this.state.meeting };
    this.setState({ meeting: Object.assign(newMeeting, meeting) });
  };

  saveParticipantMeeting = (data) => {
    if (data.meeting.meetingTime === 'Invalid date') {
      this.setAlert('error', 'Invalid Meeting Time');
      return true;
    }

    this.setState({ isLoading: true });

    if (data.meeting.meetingId) {
      ParticipantMeetingApi.edit(data.meeting)
        .then((response) => {
          this.setState({ isLoading: false });
          this.updateMeetingDetails(response.meeting);
          data.successCallback(response.meeting);
          // if there was an issue connecting with Zoom, let the user know
          if (response.error) {
            this.setAlert('warning', response.error);
          }
        })
        .catch((response) => {
          this.setAlert('error', response.error);

          this.setState({ isLoading: false });
        });
    } else {
      ParticipantMeetingApi.add(data.meeting)
        .then((response) => {
          this.setState({ isLoading: false });
          this.updateMeetingDetails(response.meeting);
          data.successCallback(response.meeting);
          History.replace(
            null,
            null,
            `/lab/participant/${this.state.participant.id}/meeting/${response.meeting.id}/details`
          );
          // if there was an issue connecting with Zoom, let the user know
          if (response.error) {
            this.setAlert('warning', response.error);
          }
        })
        .catch((response) => {
          this.setAlert('error', response.error);
          this.setState({ isLoading: false });
        });
    }
  };

  saveParticipantMeetingNotesCallback = (data) => {
    const newMeeting = { ...this.state.meeting };
    newMeeting.meeting_notes = data.meeting_notes;
    newMeeting.note_entry_id = data.note_entry_id;
    newMeeting.story_entry_id = data.story_entry_id;
    this.setState({ meeting: newMeeting });
    this.toggleEditMeetingNotes();
    // Reset the url to the default meeting details page
    History.replace(
      null,
      null,
      `/lab/participant/${this.state.participant.id}/meeting/${this.state.meeting.id}/details`
    );
  };

  saveParticipantMeetingNotes = (meeting, closeOnComplete, successCallback) => {
    this.setState({ isLoading: true });
    if (!meeting.storyEntryId) {
      ParticipantMeetingApi.addMeetingNotes(meeting).then(
        (response) => {
          // We don't want this to close if AutoSaving
          if (closeOnComplete) {
            this.saveParticipantMeetingNotesCallback({
              meeting_notes: response.meeting_notes,
              note_entry_id: response.note_entry_id,
              story_entry_id: response.story_entry_id,
            });
          }
          this.setState({ isLoading: false });
          // Right now this is for calling clean up on the AutoSave module
          if (successCallback) {
            successCallback();
          }
        },
        (response) => {
          this.setAlert('error', response.error);

          this.setState({ isLoading: false });
        }
      );
    } else {
      ParticipantMeetingApi.editMeetingNotes(meeting).then(
        (response) => {
          // We don't want this to close if AutoSaving
          if (closeOnComplete) {
            this.saveParticipantMeetingNotesCallback({
              meeting_notes: response.meeting_notes,
              note_entry_id: response.note_entry_id,
              story_entry_id: response.story_entry_id,
            });
          }
          this.setState({ isLoading: false });
          // Right now this is for calling clean up on the AutoSave module
          if (successCallback) {
            successCallback();
          }
        },
        (response) => {
          this.setAlert('error', response.error);
          this.setState({ isLoading: false });
        }
      );
    }
  };

  saveParticipantMeetingInternalNotesCallback = (data) => {
    const newMeeting = { ...this.state.meeting };
    newMeeting.internal_notes = data.internal_notes;
    this.setState({ meeting: newMeeting });
    this.toggleEditInternalNotes();
    // Reset the url to the default meeting details page
    History.replace(
      null,
      null,
      `/lab/participant/${this.state.participant.id}/meeting/${this.state.meeting.id}/details`
    );
  };

  saveParticipantMeetingInternalNotes = (
    meeting,
    closeOnComplete,
    successCallback
  ) => {
    this.setState({ isLoading: true });
    ParticipantMeetingApi.editInternalNotes(meeting).then(
      (response) => {
        // We don't want this to close if AutoSaving
        if (closeOnComplete) {
          this.saveParticipantMeetingInternalNotesCallback({
            internal_notes: response.internal_notes,
          });
        }
        // Right now this is for calling clean up on the AutoSave module
        if (successCallback) {
          successCallback();
        }
        this.setState({ isLoading: false });
      },
      (response) => {
        this.setAlert('error', response.error);
        this.setState({ isLoading: false });
      }
    );
  };

  // Checking all the edit modes at once means that only one edit mode can be active at a time
  toggleEditMode = () => {
    this.setState({
      isEditing:
        !this.state.isEditingVideoLink &&
        !this.state.isEditing &&
        !this.state.isEditingMeetingNotes &&
        !this.state.isEditingInternalNotes,
    });
  };

  toggleEditMeetingNotes = () => {
    this.setState({
      isEditingMeetingNotes:
        !this.state.isEditingVideoLink &&
        !this.state.isEditing &&
        !this.state.isEditingMeetingNotes &&
        !this.state.isEditingInternalNotes,
    });
    // By updating the current url if the user navigates away from the page, when they navigate back the edit window will be open
    History.replace(
      null,
      null,
      `/lab/participant/${this.state.participant.id}/meeting/${this.state.meeting.id}/details?isEditingMeetingNotes=true`
    );
  };

  toggleEditInternalNotes = () => {
    this.setState({
      isEditingInternalNotes:
        !this.state.isEditingVideoLink &&
        !this.state.isEditing &&
        !this.state.isEditingMeetingNotes &&
        !this.state.isEditingInternalNotes,
    });
    // By updating the current url if the user navigates away from the page, when they navigate back the edit window will be open
    History.replace(
      null,
      null,
      `/lab/participant/${this.state.participant.id}/meeting/${this.state.meeting.id}/details?isEditingInternalNotes=true`
    );
  };

  toggleEditVideoLink = () => {
    this.setState({
      isEditingVideoLink:
        !this.state.isEditingVideoLink &&
        !this.state.isEditing &&
        !this.state.isEditingMeetingNotes &&
        !this.state.isEditingInternalNotes,
    });
  };

  saveParticipantMeetingClip = (clip) => {
    this.setState({
      editMeetingClipModal: {
        ...this.state.editMeetingClipModal,
        isLoading: true,
      },
    });

    if (clip.clipId) {
      // this is to differentiate from the V4 meeting page
      clip.updateWorkspaceEntry = true;
      ParticipantMeetingApi.editClip(clip).then(
        (response) => {
          this.setState({
            editMeetingClipModal: {
              ...this.state.editMeetingClipModal,
              isLoading: false,
            },
          });
          this.closeMeetingClipModal();

          // update meeting state to get any meeting updates
          this.setState({
            meeting: Object.assign(
              {},
              { ...this.state.meeting },
              response.meeting
            ),
          });

          // show success
          this.setAlert('success', 'Clip edited successfully.');
        },
        () =>
          this.setState({
            editMeetingClipModal: {
              ...this.state.editMeetingClipModal,
              isLoading: false,
            },
          })
      );
    } else {
      ParticipantMeetingApi.addClip(clip).then(
        (response) => {
          this.setState({
            editMeetingClipModal: {
              ...this.state.editMeetingClipModal,
              isLoading: false,
            },
          });
          this.closeMeetingClipModal();

          // update meeting state to get any meeting updates
          this.setState({
            meeting: Object.assign(
              {},
              { ...this.state.meeting },
              response.meeting
            ),
          });

          // show success
          this.setAlert('success', 'Clip added successfully.');
        },
        () => {
          this.setState({
            editMeetingClipModal: {
              ...this.state.editMeetingClipModal,
              isLoading: false,
            },
          });
        }
      );
    }
  };

  deleteParticipantMeetingClip = (clip) => {
    this.setState({
      editMeetingClipModal: {
        ...this.state.editMeetingClipModal,
        isLoading: true,
      },
    });

    clip.meetingId = this.state.meeting.id;
    // this is to differentiate from the V4 meeting page
    clip.deleteWorkspaceEntry = true;
    return ParticipantMeetingApi.deleteClip(clip).then(
      (response) => {
        // update meeting state to remove clip
        this.setState({
          meeting: Object.assign(
            {},
            { ...this.state.meeting },
            response.meeting
          ),
        });
        this.setState({
          editMeetingClipModal: {
            ...this.state.editMeetingClipModal,
            isLoading: false,
          },
        });
        this.closeMeetingClipModal();

        // show success
        this.setAlert('success', 'Clip deleted successfully.');
      },
      () => {
        // show failure
        this.setAlert('error', 'Something went wrong. Please try again.');
      }
    );
  };

  deleteParticipantMeeting = () => {
    ParticipantMeetingApi.delete(this.state.meeting).then(
      (response) => {
        // if there was an issue connecting with Zoom,
        // we need to alert the user on the next screen
        const error = response.error ? `?zoom=${response.error}` : '';
        Router.route(
          `/lab/participant/${this.state.participant.id}/details${error}`
        );
      },
      () => {
        // show failure
        this.setAlert('error', 'Something went wrong. Please try again.');
      }
    );
  };

  publishParticipantMeetingClip = (clip) => {
    // this is to differentiate from the V4 meeting page
    clip.updateWorkspaceEntry = true;
    const feature = { ...this.state.publishMeetingClipModal };
    ParticipantMeetingApi.editClip(clip).then(
      (response) => {
        // update meeting state to get any meeting updates
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.meeting = Object.assign(
          {},
          this.state.meeting,
          response.meeting
        );

        return ParticipantMeetingApi.publishClip(clip).then(
          (response) => {
            // update meeting state to add link to list of links and update count
            this.setState({
              meeting: Object.assign(
                {},
                { ...this.state.meeting },
                response.meeting
              ),
            });
            // show success
            this.setAlert('success', 'Clip published successfully.');

            this.setState({ publishMeetingClipModal: feature });
            this.closePublishClipModal();
          },
          () => {
            this.setAlert('error', 'Something went wrong. Please try again.');
            this.setState({ publishMeetingClipModal: feature });
            this.closePublishClipModal();
          }
        );
      },
      (response) => {
        this.setAlert('error', response.error);
      }
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      delight: '',
      recommendation: '',
      status: {},
      alert: {},
      editMeetingNotes: {
        isLoading: false,
      },
      editInternalNotes: {
        isLoading: false,
      },
      editMeetingClipModal: {},
      publishMeetingClipModal: {
        typeFieldDisabled: false,
        type: 'coachView',
      },
      confirmDisconnectZoomModal: {},
      isEditing: false,
    };
    this.state = Object.assign(this.state, props);
    this.state.meeting = props.meeting;
    this.state.participant = props.participant;
    this.state.isEditing = !props.meeting.id;
    this.state.isEditingMeetingNotes = props.isEditingMeetingNotes || false;
    // you can only edit one of these at a time
    this.state.isEditingInternalNotes =
      (props.isEditingInternalNotes && !props.isEditingMeetingNotes) || false;
    this.state.isEditingVideoLink = false;
    this.state.cameFromWorkspace = props.cameFromWorkspace || false;
    this.state.cameFromCoachHome = props.cameFromCoachHome || false;

    if (!this.state.allFocusAreas) {
      return;
    }
  }

  render() {
    return (
      <ParticipantMeetingDetailsView
        {...this.state}
        updateMeetingDetails={this.updateMeetingDetails}
        saveParticipantMeeting={this.saveParticipantMeeting}
        saveParticipantMeetingNotesCallback={
          this.saveParticipantMeetingNotesCallback
        }
        saveParticipantMeetingNotes={this.saveParticipantMeetingNotes}
        saveParticipantMeetingInternalNotesCallback={
          this.saveParticipantMeetingInternalNotesCallback
        }
        saveParticipantMeetingInternalNotes={
          this.saveParticipantMeetingInternalNotes
        }
        toggleEditMode={this.toggleEditMode}
        toggleEditMeetingNotes={this.toggleEditMeetingNotes}
        toggleEditInternalNotes={this.toggleEditInternalNotes}
        toggleEditVideoLink={this.toggleEditVideoLink}
        saveParticipantMeetingClip={this.saveParticipantMeetingClip}
        deleteParticipantMeetingClip={this.deleteParticipantMeetingClip}
        clearAlert={this.clearAlert}
        deleteParticipantMeeting={this.deleteParticipantMeeting}
        publishParticipantMeetingClip={this.publishParticipantMeetingClip}
        closeMeetingClipModal={this.closeMeetingClipModal}
        closePublishClipModal={this.closePublishClipModal}
        openMeetingClipModal={this.openMeetingClipModal}
        openPublishClipModal={this.openPublishClipModal}
        openConfirmDisconnectZoomModal={this.openConfirmDisconnectZoomModal}
        closeConfirmDisconnectZoomModal={this.closeConfirmDisconnectZoomModal}
        disconnectZoomForMeeting={this.disconnectZoomForMeeting}
      />
    );
  }
}

export default ParticipantMeetingDetailsContainer;
