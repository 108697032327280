// @ts-nocheck FIXME
import Store from './../../components/Store';

import FavoriteApi from './../../apis/V4/FavoriteApi';
import SearchApi from './../../apis/V4/SearchApi';
import Analytics from './../../components/Analytics';
import Modal from './../../components/Modal';
import { History, Router } from './../../components/Router';
import Utils from './../../components/StaticUtils';
import HeaderStore from './../../stores/V4/HeaderStore';

const store = {
  searchContext: 'search',
  ui: {
    alert: {},
    participants: {
      inputStatus: 'blur',
      charCount: 0,
      selectedOption: null,
      options: [],
      visibleOptions: [],
    },
    filtersModal: {
      callbacks: {
        onOpen: () => undefined,
        onClose: () => undefined,
      },
    },
    recommendModal: {
      callbacks: {
        onOpen: (data) => {},
        onClose: () => undefined,
      },
    },
  },
  salt: null,
  user: null,
  currentUserId: null,
  searchTerm: null,
  searchFilters: [],
  filters: {},
  results: [],
  searchArguments: [],
  resultLoader: {
    isLoadingMore: false,
    offset: 0,
    canLoadMore: true,
    totalCount: 0,
  },
};

/****** PRIVATE FUNCTIONS ******/
const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

const setAlert = (message, type) => {
  store.ui.alert = {
    type: type,
    message: message,
    handleClose: store.clearAlert,
  };
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.ui.alert = {};
  store.searchContext = data.searchContext;
  store.currentUserId = data.currentUserId;
  // Init modals
  store.ui.recommendModal = Modal(store, store.ui.recommendModal);

  store.ui.participants.options = data.participants;
  store.ui.filtersModal = Modal(store, store.ui.filtersModal);
  store.user = data.user;
  store.searchTerm = data.searchTerm;
  store.searchFilters = data.search_filters || [];
  store.filters = data.filters || {};
  store.results = data.results;

  store.resultLoader.offset = data.results.length;
  store.resultLoader.totalCount = data.total_count;
  store.resultLoader.isLoadingMore = false;
  if (store.resultLoader.offset >= store.resultLoader.totalCount) {
    store.resultLoader.canLoadMore = false;
  }
  store.salt = data.searchSalt || Math.random().toString(36).substr(2);

  // don't trigger event
  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.setSalt = (salt) => {
  store.salt = salt;
};

const createQueryString = (data) => {
  let queryString = '?';
  let firstVar = '';

  // firstVar is an empty string for the first let and an ampersand for all successive vars so we set it to an ampersand after it's used
  if (store.searchTerm) {
    queryString = `${queryString}${firstVar}q=${store.searchTerm}`;
    firstVar = '&';
  }

  if (store.salt) {
    queryString = `${queryString}${firstVar}salt=${store.salt}`;
    firstVar = '&';
  }

  // loop over active filters to create new query string
  for (let i = 0; i < store.searchFilters.length; i++) {
    if (
      (data.action == 'remove' || data.action == 'add') &&
      store.searchFilters[i].category == data.filterCategory
    ) {
      continue;
    } else {
      queryString = `${queryString}${firstVar}${store.searchFilters[i].field}=${store.searchFilters[i].filter_id}`;
      firstVar = '&';
    }
  }

  if (data.action == 'add') {
    queryString = `${queryString}${firstVar}${data.filterField}=${data.filterId}`;
    firstVar = '&';
  }

  return queryString;
};

const setUpFilterAnalytics = (data) => {
  // setup page context for analytics
  const analyticsLabel =
    store.searchContext == 'favorites'
      ? 'from_favorites'
      : 'from_search_results';

  // handle standards differently it is not part of the filters array
  if (data.filterField == 'standards') {
    let analyticsObject = {
      category: 'standards_filter',
      action: 'on',
      label: analyticsLabel,
    };
    if (data.action == 'remove') {
      analyticsObject = {
        category: 'standards_filter',
        action: 'remove',
        label: analyticsLabel,
      };
    }
    return analyticsObject;
  }
  if (data.filterField == 'focusAreas') {
    let analyticsObject = {
      category: 'focusAreas_filter',
      action: 'on',
      label: analyticsLabel,
    };
    if (data.action == 'remove') {
      analyticsObject = {
        category: 'focusAreas_filter',
        action: 'remove',
        label: analyticsLabel,
      };
    }
    return analyticsObject;
  }
  if (data.filterField == 'specialtyTags') {
    let analyticsObject = {
      category: 'specialtyTags_filter',
      action: 'on',
      label: analyticsLabel,
    };
    if (data.action == 'remove') {
      analyticsObject = {
        category: 'specialtyTags_filter',
        action: 'remove',
        label: analyticsLabel,
      };
    }
    return analyticsObject;
  }
  if (data.filterField == 'practiceTags') {
    let analyticsObject = {
      category: 'practiceTags_filter',
      action: 'on',
      label: analyticsLabel,
    };
    if (data.action == 'remove') {
      analyticsObject = {
        category: 'practiceTags_filter',
        action: 'remove',
        label: analyticsLabel,
      };
    }
    return analyticsObject;
  }
  if (data.filterField == 'danielsonComponents') {
    let analyticsObject = {
      category: 'danielsonComponents_filter',
      action: 'on',
      label: analyticsLabel,
    };
    if (data.action == 'remove') {
      analyticsObject = {
        category: 'danielsonComponents_filter',
        action: 'remove',
        label: analyticsLabel,
      };
    }
    return analyticsObject;
  }

  // get the appropriate index for the necessary filter label
  const filterIndex = Utils.getItemIndexFromArrayOfObjects(
    store.filters[data.filterCategory],
    'id',
    data.filterId
  );

  // set up analytics action values
  let filterActionPrefix = 'set_to_';
  let filterActionVal = store.filters[data.filterCategory][filterIndex].label
    .toLowerCase()
    .replace(/,\s|[-\s/]/g, '_')
    .replace(/\(|\)/g, '');
  filterActionVal =
    filterActionVal == 'english___language_arts' ? 'ela' : filterActionVal;
  if (data.action == 'remove') {
    filterActionVal = 'all';
    if (data.from == 'crumb') {
      filterActionPrefix = '';
      filterActionVal = 'remove';
    }
  }

  return {
    category: data.filterCategory.slice(0, -1) + '_filter',
    action: filterActionPrefix + filterActionVal,
    label: analyticsLabel,
  };
};

store.updateFilters = (data = {}) => {
  const queryString = createQueryString(data);

  // use a different api depending on if search page or favorites page
  const filterApi =
    store.searchContext == 'favorites' ? FavoriteApi : SearchApi;

  const analyticsObject = setUpFilterAnalytics(data);
  Analytics.logEvent(analyticsObject);

  // make backend request and update the ui
  Router.route(`${location.pathname}${queryString}`);
};

store.addFavorite = (data) => {
  if (store.user && !store.user.id) {
    HeaderStore.actionDispatch('openRegisterModal', {
      reason: 'favorite_attempt',
    });
    return true;
  }

  const resultIndex = Utils.getItemIndexFromArrayOfObjects(
    store.results,
    'id',
    data.itemId
  );
  if (data.recommend) {
    // make sure an option was selected
    if (!store.ui.participants.selectedOption) {
      return;
    }
    data.userId = store.ui.participants.selectedOption.user_id;
  } else {
    // setup page context for analytics
    const analyticsLabel =
      store.searchContext == 'favorites'
        ? 'from_favorites'
        : 'from_search_results';

    Analytics.logEvent({
      category: data.itemType,
      action: 'favorite',
      label: analyticsLabel,
    });
    store.results[resultIndex].favorite = {};
    store.results[resultIndex].favorite.status = 'loading-favorite';
  }

  return FavoriteApi.addFavorite(data)
    .then((response) => {
      if (data.recommend) {
        setAlert(
          'Recommended for ' +
            store.ui.participants.selectedOption.first_name +
            ' ' +
            store.ui.participants.selectedOption.last_name,
          'alert'
        );
        store.ui.participants.charCount = 0;
        store.ui.participants.selectedOption = null;
        store.ui.participants.visibleOptions = [];
        store.ui.participants.inputStatus = 'blur';
        store.ui.recommendModal.close();
        store.results[resultIndex].favorite_count++;
        return;
      }
      store.results[resultIndex].favorite_count++;
      store.results[resultIndex].favorite = response.payload;
    })
    .catch((response) => {
      delete store.results[resultIndex].favorite;
      store.ui.participants.charCount = 0;
      store.ui.participants.selectedOption = null;
      store.ui.participants.visibleOptions = [];
      store.ui.participants.inputStatus = 'blur';
      store.ui.recommendModal.close();
      if (!response.access) {
        setErrorAlert(
          response.error
            ? response.error
            : 'Sorry, something went wrong. Please try again later.'
        );
      }
    });
};

store.removeFavorite = (data) => {
  const resultIndex = Utils.getItemIndexFromArrayOfObjects(
    store.results,
    'id',
    data.itemId
  );
  store.results[resultIndex].favorite.status = 'loading-unfavorite';

  // setup page context for analytics
  const analyticsLabel =
    store.searchContext == 'favorites'
      ? 'from_favorites'
      : 'from_search_results';

  Analytics.logEvent({
    category: data.itemType,
    action: 'unfavorite',
    label: analyticsLabel,
  });

  return FavoriteApi.removeFavorite(data)
    .then((response) => {
      store.results[resultIndex].favorite = null;
      store.results[resultIndex].favorite_count--;
    })
    .catch((response) => {
      store.results[resultIndex].favorite.status == 'selected';
      setErrorAlert(response.error);
    });
};

store.orderFavorites = (data) => {
  let queryString = '';
  for (let i = 0; i < store.searchFilters.length; i++) {
    let querySegment = '';
    const segmentData = {
      index: i,
      filter: {
        filterCategory: 'order',
        filterField: 'order',
        filterId: data.sortOrder,
        action: 'add',
      },
      category: store.searchFilters[i].category,
      id: store.searchFilters[i].filter_id,
    };

    querySegment = createQueryString(segmentData);

    // append the query segment to the query string
    const queryConsolidator = i < store.searchFilters.length ? '&' : '';
    if (querySegment) {
      queryString += querySegment + queryConsolidator;
    }
  }

  queryString = queryString ? queryString : '?';
  queryString += 'order=' + data.sortOrder;

  let updateQueryString = queryString;
  if (store.currentUserId) {
    updateQueryString = '/' + store.currentUserId + updateQueryString;
  }

  return FavoriteApi.updateSortOrder(updateQueryString)
    .then((response) => {
      store.searchFilters = response.page_data.search_filters;
      store.results = response.page_data.results;

      // update history for sharable url
      History.replace(null, null, queryString);
    })
    .catch((response) => {
      setErrorAlert(response.error);
    });
};

/*** Infinite Scroll: Loading More Results ***/

// Gets more indicator strategies
store.loadMoreResults = () => {
  // Only load more results if there are more results to load
  if (store.resultLoader.canLoadMore) {
    // Update the state
    store.resultLoader.canLoadMore = false;
    store.resultLoader.isLoadingMore = true;
    const filterApi =
      store.searchContext == 'favorites' ? FavoriteApi : SearchApi;

    // create query string of our current url
    const queryString = createQueryString({});
    // put in search with offset
    const offset = { offset: store.resultLoader.offset };
    // make backend request and update the ui
    return filterApi
      .filterResults(queryString, offset)
      .then((response) => {
        const data = response.page_data;
        store.searchTerm = data.searchTerm;
        store.searchFilters = data.search_filters;
        store.filters = data.filters;
        Array.prototype.push.apply(store.results, data.results);

        store.resultLoader.offset =
          store.resultLoader.offset + data.results.length;
        store.resultLoader.totalCount = data.total_count;
        store.resultLoader.isLoadingMore = false;
        if (store.resultLoader.offset >= store.resultLoader.totalCount) {
          store.resultLoader.canLoadMore = false;
        } else {
          store.resultLoader.canLoadMore = true;
        }
      })
      .catch((response) => {
        store.resultLoader.isLoadingMore = false;
        setErrorAlert(response.error);
      });
  }
};

store.getRecommendElement = (data) => {
  const field = store.ui.participants;
  field.charCount = data.input.length;

  // Only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    return option.label.toLowerCase().includes(data.input.toLowerCase());
  });
};

store.selectRecommendElement = (data) => {
  if (!data.feature || !data.field) {
    return false;
  }
  Object.assign(store.ui.participants, data.data);
};

export default Store('SearchStore', store);
