// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import CohortRow from './CohortRow';

const CohortsTable = (props) => {
  const [sortedColumn, setSortedColumn] = React.useState('cohort-name');
  const [sortDirection, setSortDirection] = React.useState('asc');

  // don't show this table if there are no cohorts
  if (!props.cohorts.length) return null;

  const sortStartDate = (a, b) => {
    if (a.term_start !== b.term_start) {
      return moment(a.term_start) - moment(b.term_start);
    } else {
      return a.display_name.localeCompare(b.display_name);
    }
  };

  const sortEndDate = (a, b) => {
    if (a.term_end !== b.term_end) {
      return moment(a.term_end) - moment(b.term_end);
    } else {
      return a.display_name.localeCompare(b.display_name);
    }
  };

  const sortFunctions = {
    'cohort-name': (a, b) => a.display_name.localeCompare(b.display_name),
    product: (a, b) => a.product.name.localeCompare(b.product.name),
    start: (a, b) => sortStartDate(a, b),
    end: (a, b) => sortEndDate(a, b),
    size: (a, b) => a.capacity - b.capacity,
  };

  // Sort order indication arrow
  let sortIndicator = null;

  if (sortDirection === 'asc') {
    props.cohorts.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (sortDirection === 'desc') {
    props.cohorts.sort(sortFunctions[sortedColumn]);
    props.cohorts.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortOrder[sortDirection]);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };
  const cohortHeader = (
    <div className="v5__heading-3">
      {`Coaching Cohort${props.cohorts.length > 1 ? 's' : ''}`}
    </div>
  );
  // if (props.currentYear === 'past') {
  //     cohortHeader = (
  //         <React.Fragment>
  //             <div className="v5__heading-3">
  //                 {`Your Cohort${props.cohorts.length > 1 ? 's' : ''}`}
  //             </div>
  //             <div className="v5__body-copy partner_lead_v5__overview-past-message">
  //                 Displaying the history of all your past cohorts. Only cohorts from 2019-20 will have detailed meeting and activity data.
  //             </div>
  //         </React.Fragment>
  //     );
  // }

  const CohortRows = props.cohorts.map((cohort) => {
    return (
      <CohortRow
        key={`cohort-${cohort.id}`}
        currentYear={props.currentYear}
        {...cohort}
      />
    );
  });

  return (
    <div className="admin-v5__coach-table">
      {cohortHeader}
      <div className="admin-v5__coach-table__header cute-100">
        <div
          className="v5__registered-table-column-name cute-4-desktop"
          onClick={() => toggleSortDirection('cohort-name')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'cohort-name' ? ' active' : '')
            }
          >
            Cohort
          </span>
          {sortedColumn === 'cohort-name' ? sortIndicator : null}
        </div>

        <div
          className="v5__registered-table-column-name cute-2-desktop"
          onClick={() => toggleSortDirection('product')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'product' ? ' active' : '')
            }
          >
            Term
          </span>
          {sortedColumn === 'product' ? sortIndicator : null}
        </div>

        <div
          className="v5__registered-table-column-name cute-2-desktop"
          onClick={() => toggleSortDirection('start')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'start' ? ' active' : '')
            }
          >
            Start Date
          </span>
          {sortedColumn === 'start' ? sortIndicator : null}
        </div>

        <div
          className="v5__registered-table-column-name cute-2-desktop"
          onClick={() => toggleSortDirection('end')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'end' ? ' active' : '')
            }
          >
            End Date
          </span>
          {sortedColumn === 'end' ? sortIndicator : null}
        </div>

        <div
          className="v5__registered-table-column-name cute-2-desktop"
          onClick={() => toggleSortDirection('size')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'size' ? ' active' : '')
            }
          >
            Size
          </span>
          {sortedColumn === 'size' ? sortIndicator : null}
        </div>
      </div>
      {CohortRows}
    </div>
  );
};

CohortsTable.propTypes = {
  cohorts: PropTypes.array.isRequired,
};

export default CohortsTable;
