// @ts-nocheck FIXME
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import React from 'react';
import { Router } from './../../../../components/Router';
import NavigationLink from './../../../NavigationLink';

const CohortRow = (props) => {
  const cohortFocusAreasNode = (
    <ul>
      {props.cohort.priorityDomains.map((domain) => {
        return <li key={domain.id}>{domain.label}</li>;
      })}
    </ul>
  );

  const handleEditCohort = (e) => {
    e.preventDefault();
    Router.route('/bl/cohort/' + props.cohort.id);
  };

  const handleSendWelcomeEmails = (e) => {
    e.preventDefault();
    props.openConfirmSendEmailModal(props.cohort);
  };

  const handleDeleteCohort = (e) => {
    e.preventDefault();
    props.openConfirmDeleteModal(props.cohort);
  };

  // set up participant count
  let participantText = '0 Participants';
  if (props.cohort.participant_count) {
    participantText =
      props.cohort.participant_count === 1
        ? '1 Participant'
        : props.cohort.participant_count + ' Participants';
  }

  // set up lead count
  let leadText = '0 Leaders';
  if (props.cohort.lead_count) {
    leadText =
      props.cohort.lead_count === 1
        ? '1 Leader'
        : props.cohort.lead_count + ' Leaders';
  }

  let partnerNode = '';
  if (props.cohort.partner) {
    partnerNode = <div>{props.cohort.partner.name}</div>;
  }

  let editRow;
  if (props.canEdit) {
    editRow = (
      <div className="cohort-dash__cohort-data-cell grid-big__col-5 cohort-dash__edit-cell">
        <span
          title={'View Details for ' + props.cohort.name}
          className="tc-visibility_black_24dp admin-table__icon"
          onClick={handleEditCohort}
        ></span>
        <span
          title={'Send Welcome Emails to all participants'}
          className="tc-mail admin-table__icon"
          onClick={handleSendWelcomeEmails}
        ></span>
      </div>
    );
  }

  let participantCountsNode = (
    <React.Fragment>
      <div>{participantText}</div>
      <div>{leadText}</div>
    </React.Fragment>
  );

  if (props.cohort.is_current) {
    participantCountsNode = (
      <NavigationLink
        url={'/bl/cohort/' + props.cohort.id + '/members'}
        className="tc__link"
      >
        {participantCountsNode}
      </NavigationLink>
    );
  }

  return (
    <div className="grid-big__row event__participant-row">
      <div className="cohort-dash__cohort-data-cell grid-big__col-7--start-v">
        <NavigationLink
          url={'/lab/cohort/' + props.cohort.id + '/overview'}
          className="tc__link"
        >
          {props.cohort.name}
        </NavigationLink>
      </div>
      <div className="cohort-dash__cohort-data-cell grid-big__col-10--start-v cohort-dash__partner-cell">
        {partnerNode}
      </div>
      <div className="cohort-dash__cohort-data-cell grid-big__col-4--start-v cohort-dash__product-cell">
        {props.cohort.product_id && props.products[props.cohort.product_id]}
      </div>
      <div className="cohort-dash__cohort-data-cell grid-big__col-4--start-v cohort-dash__termination-cell">
        {props.cohort.term_end &&
          dateformat(props.cohort.term_end, 'UTC:mm/dd/yyyy')}
      </div>
      <div className="cohort-dash__cohort-data-cell grid-big__col-4--start-v">
        {props.cohort.term_start &&
          dateformat(props.cohort.term_start, 'UTC:mm/dd/yyyy')}
      </div>
      <div className="cohort-dash__cohort-data-cell grid-big__col-4--start-v">
        {props.cohort.target_end_date &&
          dateformat(props.cohort.target_end_date, 'UTC:mm/dd/yyyy')}
      </div>
      <div className="cohort-dash__cohort-data-cell grid-big__col-7--start-v">
        {cohortFocusAreasNode}
      </div>
      <div className="cohort-dash__cohort-data-cell grid-big__col-5--start-v admin-table__col--cohort-member-counts">
        {participantCountsNode}
      </div>
      {editRow}
    </div>
  );
};

CohortRow.propTypes = {
  cohort: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  openConfirmSendEmailModal: PropTypes.func.isRequired,
  openConfirmDeleteModal: PropTypes.func.isRequired,
  focusAreas: PropTypes.array.isRequired,
};

CohortRow.defaultProps = {
  focusAreas: [],
};

export default CohortRow;
