// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useRef } from 'react';

const ArchivedParticipantSearch = (props) => {
  const searchTerm = useRef();
  return (
    <div className="admin-v5_coach-dashboard--search-container admin-v5__search">
      <input
        type="text"
        ref={searchTerm}
        className="v5__input header__v5-search-input"
        placeholder="Search by name or email"
        onInput={() => props.setSearchTerm(searchTerm.current.value)}
      />
      <button
        className="header__search-button header__search-button--v5 custom-select__single-selected-remove tc__link tc-search"
        onClick={() => props.setSearchTerm(searchTerm.current.value)}
      />
    </div>
  );
};

export default ArchivedParticipantSearch;

ArchivedParticipantSearch.propTypes = {
  setSearchTerm: PropTypes.func.isRequired,
};
