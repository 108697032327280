// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { Router } from './../../components/Router';
import Utils from './../../components/StaticUtils';

const PartnerRow = (props) => {
  // event listeners
  const handleShowPartnerEdit = (e) => {
    e.preventDefault();
    Router.route('/bl/v1/partner/' + props.partner.id);
  };

  const handleShowPartnerUsers = (e) => {
    e.preventDefault();
    Router.route('/bl/v1/partner/' + props.partner.id + '/users');
  };

  /* BEGIN RENDERING LOGIC */

  const partnerCreated = Utils.formatDate(props.partner.created);

  return (
    <tr className="admin-table__row">
      <td className="admin-table__col">
        <a
          className="tc__link"
          onClick={handleShowPartnerUsers}
          href={'/bl/v1/partner/' + props.partner.id + '/users'}
        >
          {props.partner.name}
        </a>
      </td>
      <td className="admin-table__col">{partnerCreated}</td>
      <td className="admin-table__col">{props.partner.count}</td>
      <td className="admin-table__col">
        <a
          className="tc__link admin-table__action-link"
          onClick={handleShowPartnerEdit}
          href={'/bl/v1/partner/' + props.partner.id}
        >
          edit
        </a>
        <span className="admin-table__action-seperator"> | </span>
        <a
          className="tc__link admin-table__action-link"
          onClick={handleShowPartnerUsers}
          href={'/bl/v1/partner/' + props.partner.id + '/users'}
        >
          users
        </a>
      </td>
    </tr>
  );
};

PartnerRow.propTypes = {
  partner: PropTypes.object.isRequired,
};

export default PartnerRow;
