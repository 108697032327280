// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';

/* 
Usage:
- In all examples below, you can also add these extra attributes:
    height={ <int> } // defaults to 760
    width={ <int> } // defaults to 160
    labels={ true | false} // defaults to false
    legend={ true | false} // defaults to false
    usePercentages={ true | false } // defaults to false (set to true if the metric is qualitative)

Examples:
// just goal
<TmlProgressChart goal={ { value: 10, percentage: 50, total: 20 } } />

// just baseline
<TmlProgressChart baseline={ { value: 4, percentage: 20, total: 20 } } />

// both goal and baseline on same chart
<TmlProgressChart 
    goal={ { value: 10, percentage: 50, total: 20 } } 
    baseline={ { value: 4, percentage: 20, total: 20 } } 
/>

// just one loop (any loop)
<TmlProgressChart 
    goal={ { value: 10, percentage: 50, total: 20 } } 
    baseline={ { value: 4, percentage: 20, total: 20 } } 
    measurements={ [
        { 
            loop: 2, // loop number ex. 1, 2, 3, 4, ...
            value: 8,
            percentage: 40,
            total: 20
        }
    ] }
/>

// multi loops
<TmlProgressChart 
    goal={ { value: 10, percentage: 50, total: 20 } } 
    baseline={ { value: 4, percentage: 20, total: 20 } }
    measurements={ [
        { 
            loop: 1,
            value: 6,
            percentage: 30,
            total: 20
        },
        { 
            loop: 2,
            value: 8,
            percentage: 40,
            total: 20
        },
        { 
            loop: 3,
            value: 14,
            percentage: 70,
            total: 20
        }
    ] }
/>

*/

class TmlProgressChart extends React.Component {
  render() {
    const chartHeight = this.props.height || 180;

    const showLabels = !!this.props.labels;
    const showLegend = !!this.props.legend;
    const usePercentages = !!this.props.usePercentages;

    const baseline = this.props.baseline || '';
    const baselinePercentage = usePercentages
      ? baseline.percentage / 100
      : baseline.value / baseline.total;
    const baselineRelative = Math.round(baselinePercentage * chartHeight);

    const goal = this.props.goal || '';
    const goalPercentage = usePercentages
      ? goal.percentage / 100
      : goal.value / goal.total;
    const goalRelative = Math.round(goalPercentage * chartHeight);

    const measurements = this.props.measurements || [];
    let measurementPercentage;
    const measurementValuesRelative = [];
    for (let i = 0; i < measurements.length; i++) {
      measurementPercentage = usePercentages
        ? measurements[i].percentage / 100
        : measurements[i].value / measurements[i].total;
      measurementValuesRelative.push(
        Math.round(measurementPercentage * chartHeight)
      );
    }

    const baselineColor = '#1b7ee0';
    const goalColor = '#c2df21';
    const measurementDefaultColor = 'red';
    const measurementColors = [
      '#f5972e',
      '#f8d43a',
      '#00e5b5',
      '#795fce',
      '#14b5c9',
      '#cc7e14',
      '#f7ee5c',
      '#5dc178',
      '#aca2cf',
      '#9de2dd',
    ];

    const bars = [];
    let bar;
    const barLegend = [];
    let barColor, barHeight, loopValue, loopPercent;

    if (measurements.length > 1) {
      let measurement;
      for (let i = 0; i < measurements.length; i++) {
        measurement = measurements[i];

        // default bar color
        barColor = measurementDefaultColor;
        //  if loop number is not out of bounds, then assign a color from the measurement colors
        if (measurement.loop <= measurementColors.length) {
          barColor = measurementColors[measurement.loop - 1];
        }

        // height
        barHeight = measurementValuesRelative[i];
        // if height is 100%, then substract top and bottom border pixels
        if (barHeight === chartHeight) {
          barHeight -= 2;
        }

        loopValue = usePercentages ? measurement.percentage : measurement.value;
        loopPercent = usePercentages
          ? '%'
          : ' (' +
            Math.round((measurement.value / measurement.total) * 100) +
            '%)';

        bar = {
          color: barColor,
          height: barHeight,
          type: 'multi',
          label: 'Loop ' + measurement.loop + ': ' + loopValue + loopPercent,
        };
        bars.push(bar);

        // add the bar's color to the legend
        if (showLegend) {
          barLegend.push(
            <div
              key={'bar-legend-' + measurement.loop}
              className="chart__legend"
            >
              <div
                className="chart__legend-example chart__legend-example--solid"
                style={{ borderColor: barColor }}
              ></div>
              <div className="chart__legend-caption chart__legend-caption--small">
                <span className="tc__declaration-highlight--7">
                  Loop {measurement.loop}: {loopValue}
                </span>
                {loopPercent}
              </div>
            </div>
          );
        }
      }
    } else if (measurements.length > 0) {
      // default bar color
      barColor = measurementDefaultColor;
      //  if loop number is not out of bounds, then assign a color from the measurement colors
      if (measurements[0].loop <= measurementColors.length) {
        if (measurements[0].loop === 0) {
          barColor = baselineColor;
        } else {
          barColor = measurementColors[measurements[0].loop - 1];
        }
      }

      // height
      barHeight = measurementValuesRelative[0];
      // if height is 100%, then substract top and bottom border pixels
      if (barHeight === chartHeight) {
        barHeight -= 2;
      }

      loopValue = usePercentages
        ? measurements[0].percentage
        : measurements[0].value;
      loopPercent = usePercentages
        ? '%'
        : ' (' +
          Math.round((measurements[0].value / measurements[0].total) * 100) +
          '%)';

      bar = {
        color: barColor,
        height: barHeight,
        type: 'single',
        label: 'Loop ' + measurements[0].loop + ': ' + loopValue + loopPercent,
      };
      bars.push(bar);

      // add the bar's color to the legend
      if (showLegend) {
        barLegend.push(
          <div key="bar-legend-1" className="chart__legend">
            <div
              className="chart__legend-example chart__legend-example--solid"
              style={{ borderColor: barColor }}
            ></div>
            <div className="chart__legend-caption chart__legend-caption--small">
              <span className="tc__declaration-highlight--7">
                Loop {measurements[0].loop}: {loopValue}
              </span>
              {loopPercent}
            </div>
          </div>
        );
      }
    } else if (goal && baseline) {
      // height
      barHeight = baselineRelative;
      // if height is 100%, then substract top and bottom border pixels
      if (barHeight === chartHeight) {
        barHeight -= 2;
      }
      bar = {
        color: baselineColor,
        height: barHeight,
        type: 'multi',
      };
      bars.push(bar);

      // height
      barHeight = goalRelative;
      // if height is 100%, then substract top and bottom border pixels
      if (barHeight === chartHeight) {
        barHeight -= 2;
      }
      bar = {
        color: goalColor,
        height: barHeight,
        type: 'multi',
      };
      bars.push(bar);
    } else if (goal) {
      // height
      barHeight = goalRelative;
      // if height is 100%, then substract top and bottom border pixels
      if (barHeight === chartHeight) {
        barHeight -= 2;
      }

      bar = {
        color: goalColor,
        height: barHeight,
        type: 'single',
      };
      bars.push(bar);
    } else if (baseline) {
      // height
      barHeight = baselineRelative;
      // if height is 100%, then substract top and bottom border pixels
      if (barHeight === chartHeight) {
        barHeight -= 2;
      }

      bar = {
        color: baselineColor,
        height: barHeight,
        type: 'single',
      };
      bars.push(bar);
    }

    const barNodes = bars.map(function (bar, i) {
      return (
        <div
          key={'bar-' + i}
          ref={bar.type + 'Bar'}
          className={'chart__total chart__total--' + bar.type}
          style={{ height: chartHeight + 'px' }}
        >
          <div
            className={'chart__value chart__value--' + bar.type}
            style={{ height: bar.height + 'px', backgroundColor: bar.color }}
          ></div>
        </div>
      );
    });

    let labelNodes = '';
    if (showLabels) {
      labelNodes = bars.map(function (bar, i) {
        // if showing labels, set height so we can show the labels
        return (
          <div
            key={'label-' + i}
            className={'chart__label chart__label--' + bar.type}
          >
            <PurifiedHTML content={bar.label} />
          </div>
        );
      });

      labelNodes = (
        <div className="chart__labels chart__labels--small">{labelNodes}</div>
      );
    }

    let goalLine;
    let goalLegend;
    const goalValue = usePercentages ? goal.percentage : goal.value;
    if (goal && goalValue) {
      goalLine = (
        <div
          className="chart__line"
          style={{ borderColor: goalColor, bottom: goalRelative - 1 + 'px' }}
        ></div>
      );
      if (showLegend) {
        const goalMeasurementNode = usePercentages
          ? '%'
          : ' (' + Math.round((goal.value / goal.total) * 100) + '%)';
        goalLegend = (
          <div className="chart__legend">
            <div
              className="chart__legend-example"
              style={{ borderColor: goalColor }}
            ></div>
            <div className="chart__legend-caption chart__legend-caption--small">
              <span className="tc__declaration-highlight--7">
                Goal: {goalValue}
              </span>
              {goalMeasurementNode}
            </div>
          </div>
        );
      }
    }
    let baselineLine;
    let baselineLegend;
    const baselineValue = usePercentages ? baseline.percentage : baseline.value;
    if (baseline && baselineValue) {
      baselineLine = (
        <div
          className="chart__line"
          style={{
            borderColor: baselineColor,
            bottom: baselineRelative - 1 + 'px',
          }}
        ></div>
      );
      if (showLegend) {
        const baselineMeasurementNode = usePercentages
          ? '%'
          : ' (' + Math.round((baseline.value / baseline.total) * 100) + '%)';
        baselineLegend = (
          <div className="chart__legend">
            <div
              className="chart__legend-example"
              style={{ borderColor: baselineColor }}
            ></div>
            <div className="chart__legend-caption chart__legend-caption--small">
              <span className="tc__declaration-highlight--7">
                Baseline: {baselineValue}
              </span>
              {baselineMeasurementNode}
            </div>
          </div>
        );
      }
    }
    let legendsNode = '';
    if (showLegend) {
      legendsNode = (
        <div className="chart__legends">
          {barLegend}
          {goalLegend}
          {baselineLegend}
        </div>
      );
    }

    return (
      <div>
        <div className="chart__labels">{labelNodes}</div>
        <div className="chart" style={{ height: chartHeight + 'px' }}>
          <div className="chart__measurements">{barNodes}</div>
          {baselineLine}
          {goalLine}
        </div>
        {legendsNode}
      </div>
    );
  }
}

export default TmlProgressChart;
