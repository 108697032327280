// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import React from 'react';
import Analytics from './../../../components/Analytics';
import StrategyMultiSelectStore from './../../../stores/V2/StrategyMultiSelectStore';

function formatName(option) {
  return option.group == 'Teachers'
    ? option.name + ' (' + option.email + ')'
    : option.name;
}

function getVisibleOptions(options, input) {
  return options
    .filter(function (option) {
      // get name to search
      const name = formatName(option);
      const key = option.group + option.id;

      // if the value does not match it is not available
      if (name.toLowerCase().indexOf(input) == -1) {
        return false;
      }

      // it is a match
      return true;
    })
    .map(function (option) {
      return option.group + option.id;
    });
}

class MultiSelectCustom extends React.Component {
  state = StrategyMultiSelectStore.getState();
  token = null;

  getAnalyticsEventLabel = () => {
    return this.props.analyticsLabel;
  };

  componentDidMount() {
    this.token = StrategyMultiSelectStore.subscribe(this.onStoreChange);
  }

  componentWillUnmount() {
    StrategyMultiSelectStore.unsubscribe(this.token);
  }

  onStoreChange = () => {
    this.setState(StrategyMultiSelectStore.getState());
  };

  handleSelectClick = (e) => {
    e.stopPropagation();

    this.refs['input'].focus();
    StrategyMultiSelectStore.actionDispatchSync('updateMultiSelect', {
      inputStatus: 'focus',
    });

    delayedEventListener(() =>
      window.addEventListener('click', this.closeOptionsDropdown)
    );
  };

  handleSelectOption = (option, e) => {
    e.preventDefault();

    // Log the Analytics event:
    const analyticsEvent = {
      category: 'teacher',
      action: 'select',
      label: this.getAnalyticsEventLabel(),
    };
    if (option.group.toLowerCase() === 'teams') {
      analyticsEvent.category = 'team';
    }
    Analytics.logEvent(analyticsEvent);

    this.refs['input'].value = '';

    const data = {
      selectedOptions: this.state.selectedOptions.slice(),
      charCount: 0,
      teams: this.state.teams,
      teachers: this.state.teachers,
    };

    data.selectedOptions.push(option.group + '-' + option.id);

    // push the option id to the appropreate group
    const group = option.group.toLowerCase();
    data[group].push(option.value);

    StrategyMultiSelectStore.actionDispatchSync('updateMultiSelect', data);
  };

  handleRemoveOption = (option, e) => {
    e.preventDefault();

    // Log the Analytics event:
    const analyticsEvent = {
      category: 'teacher',
      action: 'remove',
      label: this.getAnalyticsEventLabel(),
    };
    if (option.group.toLowerCase() === 'teams') {
      analyticsEvent.category = 'team';
    }
    Analytics.logEvent(analyticsEvent);

    const data = {
      inputStatus: 'active',
      selectedOptions: this.state.selectedOptions.slice(),
      teams: this.state.teams,
      teachers: this.state.teachers,
      charCount: 0,
      inputStatus: 'blur',
    };

    const index = data.selectedOptions.indexOf(option.group + '-' + option.id);
    if (index > -1) {
      data.selectedOptions.splice(index, 1);
    }

    // push the option id to the appropreate group
    const group = option.group.toLowerCase();
    const optionIndex = data[group].indexOf(option.value);
    if (optionIndex > -1) {
      data[group].splice(optionIndex, 1);
    }

    const input = this.refs['input'].value.toLowerCase();
    if (input.length >= 2) {
      data.visibleOptions = getVisibleOptions(this.props.options, input);
    }

    StrategyMultiSelectStore.actionDispatchSync('updateMultiSelect', data);
  };

  handleFocus = (e) => {
    const input = this.refs['input'].value.toLowerCase();
    let visibleOptions = [];

    if (input.length >= 2) {
      visibleOptions = getVisibleOptions(this.props.options, input);
    }

    const data = {
      inputStatus: 'focus',
      visibleOptions: visibleOptions,
    };
    StrategyMultiSelectStore.actionDispatch('clearError', 'names');
    StrategyMultiSelectStore.actionDispatchSync('updateMultiSelect', data);
  };

  closeOptionsDropdown = () => {
    StrategyMultiSelectStore.actionDispatchSync('updateMultiSelect', {
      inputStatus: 'blur',
    });
  };

  handleBlur = (e) => {
    setTimeout(
      function () {
        if (this.state.inputStatus != 'focus') {
          return;
        }
        StrategyMultiSelectStore.actionDispatchSync('updateMultiSelect', {
          inputStatus: 'blur',
        });
      }.bind(this),
      300
    );
  };

  handleTyping = (e) => {
    const input = this.refs['input'].value.toLowerCase();
    if (input.length < 2) {
      const data = {
        visibleOptions: [],
        charCount: input.length,
      };

      StrategyMultiSelectStore.actionDispatchSync('updateMultiSelect', data);

      return;
    }

    // Log the Analytics event:
    Analytics.logEvent({
      category: 'teacher',
      action: 'search',
      label: this.getAnalyticsEventLabel(),
    });

    const visibleOptions = getVisibleOptions(this.props.options, input);

    // no matches found
    if (visibleOptions.length == 0) {
      visibleOptions.push(-1);
    }

    const data = {
      visibleOptions: visibleOptions,
      charCount: input.length,
    };

    StrategyMultiSelectStore.actionDispatchSync('updateMultiSelect', data);
  };

  render() {
    const availableNodes = this.props.options
      .filter(
        function (option) {
          // when the input is blurred... nothing is available
          if (this.state.inputStatus == 'blur' || this.state.charCount < 2) {
            return false;
          }

          // if there are no matches
          if (this.state.visibleOptions.indexOf(-1) != -1) {
            return false;
          }

          const key = option.group + option.id;

          // if the value is selected, it is not available
          if (this.state.selectedOptions.indexOf(key) > -1) {
            return false;
          }

          // if the value is filtered out, it is not available
          if (
            this.state.visibleOptions.length > 0 &&
            !(this.state.visibleOptions.indexOf(key) > -1)
          ) {
            return false;
          }
          return true;
        }.bind(this)
      )
      .map(
        function (option) {
          return (
            <div
              className="custom-select__option"
              onClick={this.handleSelectOption.bind(this, option)}
              key={'avail-' + option.group + option.id}
            >
              {formatName(option)}
            </div>
          );
        }.bind(this)
      );

    // only show the options menu if there are visible options
    let optionsNode = null;
    if (availableNodes.length) {
      optionsNode = (
        <div className="custom-select__options">{availableNodes}</div>
      );
    }

    const selectedNodes = this.state.selectedOptions.map(
      function (option) {
        const optionArray = option.split('-');
        const optionId = optionArray[1];
        let selectedOption;
        // get the data for the appropreate selected option
        for (let i = 0; this.props.options.length > i; i++) {
          const loopOption = this.props.options[i];
          if (loopOption.id != optionId) {
            continue;
          }
          selectedOption = loopOption;
          break;
        }

        return (
          <div
            className="custom-select__selected"
            key={'sel-' + optionArray[0] + optionId}
          >
            <div className="custom-select__selected-name">
              {selectedOption.name}
            </div>
            <div
              onClick={this.handleRemoveOption.bind(this, selectedOption)}
              className="custom-select__selected-remove tc-x"
            ></div>
          </div>
        );
      }.bind(this)
    );

    let inputPlaceholder = 'Teacher Name or Team Name';
    let inputEmptyClass = ' tc__input--custom-select-empty';
    if (this.state.selectedOptions.length) {
      inputPlaceholder = '';
      inputEmptyClass = '';
    }

    return (
      <div className="custom-select tc__input" onClick={this.handleSelectClick}>
        <div className="custom-select__selected-options">
          {selectedNodes}

          <input
            className={'tc__input tc__input--custom-select' + inputEmptyClass}
            type="text"
            ref="input"
            placeholder={inputPlaceholder}
            onChange={this.handleTyping}
            onFocus={this.handleFocus}
          />
        </div>
        {optionsNode}
      </div>
    );
  }
}

export default MultiSelectCustom;
