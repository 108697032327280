// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Sortable from './../Sortable';
import SubjectPanel from './SubjectPanel';

const GrowthAreasSection = (props) => {
  /* EVENT HANDLERS */
  const handleSubmitRefresh = (e) => {
    e.preventDefault();

    props.refreshGrowthAreaSection({
      sectionId: props.section.id,
    });
  };
  /* RENDERING */
  // build the "Refresh Data" link
  let refreshNode = (
    <div>
      <span className="tc-refresh ppt__refresh-icon" />
      <a className="tc__link ppt__refresh-link" onClick={handleSubmitRefresh}>
        Refresh Data
      </a>
    </div>
  );

  if (props.ui.GROWTH_AREAS.refreshing) {
    refreshNode = (
      <div className="ppt-dashboard_spinner_container">
        Refreshing Data..
        <div className="ppt-dashboard_spinner"></div>
      </div>
    );
  }

  // show any error we got when refreshing
  let refreshErrorNode = null;
  if (props.ui.GROWTH_AREAS.error) {
    refreshErrorNode = (
      <div className="ppt__refresh-error">{props.ui.GROWTH_AREAS.error}</div>
    );
  }

  // create array of subject nodes
  let subjectNode = [];
  if (props.section.subjects) {
    subjectNode = props.section.subjects.map((subject) => (
      <SubjectPanel
        key={subject.id}
        {...subject}
        sectionId={props.section.id}
        toggleSubjectPanel={props.toggleSubjectPanel}
        toggleSubjectVisibility={props.toggleSubjectVisibility}
        toggleGrowthAreaVisibility={props.toggleGrowthAreaVisibility}
        editEntry={props.editEntry}
        deleteEntry={props.deleteEntry}
        sortGrowthAreaEntries={props.sortGrowthAreaEntries}
      />
    ));
  }

  // attach sorting functionality to sections
  const SortableSubjects = Sortable(
    <div id="subjectsContainer">{subjectNode}</div>,
    '#subjectsContainer',
    '#ppt-section__container',
    (subjectIds) => props.sortSubjects(subjectIds),
    {
      // only drag when header is clicked
      moves: (el, source, handle, sibling) => {
        return handle.classList.contains('ppt__drag-icon--ga');
      },
    }
  );

  return (
    <div className="ppt-section">
      <h1 className="tc__headline-2">Growth Areas</h1>

      <form>
        <div>
          Graph Amplitude&nbsp;
          <select
            defaultValue={props.section.growth_area_amplitude}
            className="tc__select"
          >
            <option key="0" value="NUM_STRATEGIES_TRIED">
              # of Strategies Tried
            </option>
          </select>
        </div>
        <div>
          {refreshNode}
          {refreshErrorNode}
        </div>
      </form>

      <div className="tc__headline-2 ppt-growth-areas__content-areas-header">
        Content Areas
      </div>
      <SortableSubjects />
    </div>
  );
};

GrowthAreasSection.propTypes = {
  section: PropTypes.object.isRequired,
  refreshGrowthAreaSection: PropTypes.func.isRequired,
  sortSubjects: PropTypes.func.isRequired,
};

export default GrowthAreasSection;
