// @ts-nocheck FIXME
import Request from './../components/Request';

const ParticipantApi = {
  createParticipants: function (data) {
    const fd = new FormData();
    fd.append('file', data.file);
    fd.append('formData', JSON.stringify(data.formData));

    return Request.postForm(
      process.env.REACT_APP_LAB_SERVICE_URL + '/participant/bulk_add',
      fd
    );
  },

  editParticipantData: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/edit',
      JSON.stringify(data)
    );
  },

  sendWelcomeEmail: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/send-welcome-email',
      JSON.stringify(data)
    );
  },

  setParticipantVisibility: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/set-visibility',
      JSON.stringify(data)
    );
  },

  setParticipantPartnerNote: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/set-partner-note',
      JSON.stringify(data)
    );
  },

  searchInstitutions: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/institution/search',
      JSON.stringify(data)
    );
  },

  reorderPortfolioStories: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/participant/' +
        data.participantId +
        '/portfolio-stories/reorder',
      JSON.stringify(data)
    );
  },
};

export default ParticipantApi;
