// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';
import React from 'react';
import MeetingRow from './MeetingRow';
import MeetingStats from './MeetingStats';

const Meetings = (props) => {
  const [showAllMeetings, setShowAllMeetings] = React.useState(false);
  const participantMeetings = props.meetings;

  const meetingHeader = (
    <div className="participant-v5__meeting-row-header participant-v5__meeting-row">
      <div className="participant-v5__meeting-date participant-v5__flush-left">
        <span className="participant-v5__date-time">Date, Time</span>
        <span className="participant-v5__time-zone--name">
          {props.userTimezone}
        </span>
      </div>
      <div className="participant-v5__meeting-status participant-v5__header-text">
        Status
      </div>
      <div className="participant-v5__meeting-cta"></div>
      <div className="clearfix"></div>
    </div>
  );

  let meetingsNode = (
    <div className="participant-v5__no-meetings">
      <NavigationLink
        className="tc__link"
        url={`/lab/participant/${props.participantId}/meeting/details/add?from=participant_details`}
      >
        {`Schedule your first meeting with ${props.firstName}`}
      </NavigationLink>
    </div>
  );

  if (!props.canEditData || props.isArchived) {
    meetingsNode = (
      <div className="participant-v5__cohort-overview participant-v5__cohort-overview--empty no-overview v5__body-copy">
        No meetings scheduled yet.
      </div>
    );
  }

  if (participantMeetings && participantMeetings.length) {
    meetingsNode = participantMeetings.map((meeting) => (
      <MeetingRow
        {...meeting}
        key={`meeting-${meeting.id}`}
        participantId={props.participantId}
        coachingEngagementId={props.coachingEngagementId}
        canEditData={props.canEditData}
        isArchived={props.isArchived}
        userTimezone={props.userTimezone}
        updateMeetingStatus={props.updateMeetingStatus}
        validStatuses={props.validStatuses}
        firstName={props.firstName}
        meetingEmailObject={props.meetingEmailObject}
        isMobile={props.isMobile}
      />
    ));
  }

  let showMeetings =
    participantMeetings && participantMeetings.length > 4 && 'SHOW LESS';
  if (
    !showAllMeetings &&
    participantMeetings &&
    participantMeetings.length > 4
  ) {
    meetingsNode = meetingsNode.slice(0, 4);
    showMeetings = 'SHOW ALL';
  }

  let addMeetingNode = (
    <NavigationLink
      className="participant-v5__add-meeting-link"
      url={`/lab/participant/${props.participantId}/meeting/details/add?from=participant_details`}
    >
      Add a meeting
    </NavigationLink>
  );

  if (!props.canEditData || props.isArchived) {
    addMeetingNode = null;
  }

  return (
    <div>
      <div className="participant-v5__cohort">
        <NavigationLink
          className="participant-v5__cohort-name"
          url={`/lab/cohort/${props.cohortId}/overview`}
          target="_blank"
        >
          {`${props.cohortName}, ${props.cohortProduct.name}`}
        </NavigationLink>
        <span className="v5__comment-copy">
          {`${props.cohortStartDate}–${props.cohortEndDate}`}
        </span>
      </div>
      <div className="participant-v5__meetings">
        <div className="participant-v5__meetings-container">
          {meetingHeader}
          {meetingsNode}
          <div
            className="cute-12-desktop participant-v5__flush-left v5__link participant-v5__participant-show-all"
            onClick={() => setShowAllMeetings(!showAllMeetings)}
            data-testid="participant-meetings-show-all"
          >
            {showMeetings}
          </div>
        </div>
        <div className="show-desktop hide-phone participant-v5__meetings-stats">
          {props.canEditData && !props.isArchived && (
            <div className="bl4__btn bl4__btn--blue participant-v5__add-meeting-btn">
              {addMeetingNode}
            </div>
          )}
          <MeetingStats
            isInternalCoach={props.isInternalCoach}
            totalMeetings={
              (props.cohortProduct && props.cohortProduct.number_of_sessions) ||
              0
            }
            meetingsHeld={props.meetingsHeld}
            meetingsMissed={props.meetingsMissed}
          />
        </div>
      </div>
    </div>
  );
};

Meetings.propTypes = {
  isInternalCoach: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
  meetingEmailObject: PropTypes.shape({
    setCancellation: PropTypes.func.isRequired,
    setNoShow: PropTypes.func.isRequired,
    setMeetingObject: PropTypes.func.isRequired,
  }).isRequired,
  product: PropTypes.shape({
    active: PropTypes.number,
    audience: PropTypes.string,
    cadence: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    length: PropTypes.string,
    name: PropTypes.string.isRequired,
    num_outcomes: PropTypes.number,
    number_of_sessions: PropTypes.number,
  }),
  participantId: PropTypes.number.isRequired,
  meetings: PropTypes.arrayOf(
    PropTypes.shape({
      coach_next_steps: PropTypes.string,
      coaching_focus: PropTypes.string,
      gcal_event_id: PropTypes.string,
      general_notes: PropTypes.string,
      id: PropTypes.number.isRequired,
      meeting_length: PropTypes.number,
      meeting_time: PropTypes.string,
      meeting_time_formatted: PropTypes.string.isRequired,
      outcome_id: PropTypes.number,
      participant_id: PropTypes.number,
      pre_meeting_notes: PropTypes.string,
      rating_delight: PropTypes.number,
      status: PropTypes.number.isRequired,
      story_id: PropTypes.number,
      teacher_next_steps: PropTypes.string,
      uuid: PropTypes.string,
      video_download_link: PropTypes.string,
      video_length: PropTypes.number,
      video_link: PropTypes.string,
      video_status: PropTypes.number,
    })
  ),
  userTimezone: PropTypes.string.isRequired,
  updateMeetingStatus: PropTypes.func.isRequired,
  validStatuses: PropTypes.shape({
    1: PropTypes.string,
    2: PropTypes.string,
    3: PropTypes.string,
    4: PropTypes.string,
  }),
};

export default Meetings;
