// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../NavigationLink';

const FinalStep = () => {
  return (
    <div className="row row--1200 registration__primary">
      <div className="cute-6-desktop cute-12-phone center-desktop">
        <div className="registration__final-step-img">
          <img
            src="/legacy-images/v5/registration-final-step.png"
            alt="Thanks for filling out the survey."
          />
        </div>
        <div className="registration__headline v5__heading-5">
          All done! Stay tuned for your coach match.
        </div>
        <div className="registration__input-label">
          We’ll review your responses and match you with a coach within a week.
          Your coach will reach out to introduce themselves and schedule your
          first meeting.
        </div>
        <div className="registration__headline v5__heading-5">
          Explore the BetterLesson Lab
        </div>
        <div className="registration__input-label">
          While you’re waiting to connect with your coach, get to know the Lab,
          where you can access thousands of comprehensive lesson plans,
          instructional resources, and strategies from some of the nation’s most
          effective teachers.
        </div>
        <NavigationLink
          className="registration__final-step-link"
          url={'/lab/home'}
        >
          <button className="bl4__btn bl4__btn--blue registration__final-step-btn">
            Check out the Lab
          </button>
        </NavigationLink>
      </div>
    </div>
  );
};

FinalStep.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
};

export default FinalStep;
