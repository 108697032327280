// @ts-nocheck FIXME
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const ArchivedParticipantRow = (props) => {
  const participant = props.coachingEngagement.participant;
  React.useEffect(() => {
    return () => {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
    };
  }, []);

  const handleCopyToClipboard = () => {
    props.setSuccessAlert('Email copied to clipboard');
  };

  let learningDomainsNode = null;
  const learningDomains = participant.focusAreas.map((area, i) => {
    return <li key={`ld-${participant.id}-${area.id}-${i}`}>{area.title}</li>;
  });
  if (learningDomains.length) {
    learningDomainsNode = <ul>{learningDomains}</ul>;
  }
  // Show the first of either the target end date or done with coaching
  const registrationCompletion = moment(
    participant.registration_completion
  ).format('MMMM D, YYYY');
  const endDate = moment
    .min(
      moment(participant.cohortEndDate),
      moment(participant.finished_coaching)
    )
    .format('MMMM D, YYYY');

  return (
    <div className="grid-ten__row admin-v5__archive-table">
      <div className="grid-ten__col-3 grid-ten__col-3--start-v">
        <div className="ppd-3-coach-table__user-image admin-v5_coach-dashboard-photo">
          <UserPhoto
            src={participant.photo}
            userId={participant.user_id}
            diameter={40}
            firstName={participant.first_name}
            lastName={participant.last_name}
            editable={false}
          />
        </div>
        <div className="ppd-3-coach-table__user-details admin-v5_coach-dashboard-name admin-v5__archive-user-details">
          <div className="admin-v5_coach-dashboard--user-name admin-v5__participant-name admin-v5__participant-name--archived">
            {participant.first_name + ' ' + participant.last_name}
          </div>
          <div
            className="tc__breakword clickable v5__author-copy admin-v5_coach-dashboard-email"
            copytext={participant.email}
            onClick={handleCopyToClipboard}
          >
            <Tooltip tooltip="Copy to clipboard" tipClass="v5__tooltip">
              {participant.email}
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="grid-ten__col-2 grid-ten__col-2--start-v">
        <div className="admin-v5__archive-cohort">
          {props.coachingEngagement.cohort.cohort_name}
        </div>
      </div>
      <div className="grid-ten__col-2 grid-ten__col-2--start-v">
        <div className="admin-v5__archive-year">
          {registrationCompletion} - {endDate}
        </div>
      </div>
      <div className="grid-ten__col-2 grid-ten__col-2--start-v">
        <div className="admin-v5__archive-domains">{learningDomainsNode}</div>
      </div>
      <div className="grid-ten__col-1 grid-ten__col-1--start-v admin-v5__archive-icon">
        <div className="admin-v5__archive-icon">{props.children}</div>
      </div>
    </div>
  );
};

ArchivedParticipantRow.propTypes = {
  coachingEngagement: PropTypes.object.isRequired,
  setSuccessAlert: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ArchivedParticipantRow;
