// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../components/DynamicUtils';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';

const LandingStage = (props) => {
  let alertNode;
  // Build the alert node if needed:
  if (!Utils.isEmpty(props.ui.alert)) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  let bannerVisibleClass = '';
  let bannerNode;
  if (props.ui.errorBanner) {
    bannerVisibleClass = ' gatekeeper--banner-visible';
    bannerNode = (
      <div className="gatekeeper__banner gatekeeper__banner--error">
        {props.ui.errorBanner}
      </div>
    );
  }

  const headerLogoClass = ' gatekeeper__header';

  return (
    <div>
      <div className="gatekeeper__page" />
      {bannerNode}
      {alertNode}
      <div className={'gatekeeper gatekeeper--stage row' + bannerVisibleClass}>
        <div className="overlay__content cute-12-desktop cute-12-phone">
          <div className="gatekeeper-modal row">
            <NavigationLink url={'/home'}>
              <div
                id="tc__logo"
                className={'gatekeeper__header' + headerLogoClass}
              >
                <span className="gatekeeper__logo-caption">BetterLesson</span>
              </div>
            </NavigationLink>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

LandingStage.propTypes = {
  ui: PropTypes.object.isRequired,
  siteName: PropTypes.string.isRequired,
};

export default LandingStage;
