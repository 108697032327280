// @ts-nocheck FIXME
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import ArtifactOverlay from './../../../Modals/V2/ArtifactOverlay';
import ArtifactCapsule from './EditArtifacts/ArtifactCapsule';

const EditArtifacts = (props) => {
  // handle clicking on the Save & Exit button in the footer
  const handleSave = () => {
    props.saveSubstep('artifacts');
  };

  // show the artifact overlay if necessary
  let artifactOverlayNode = null;
  if (props.currentArtifact) {
    artifactOverlayNode = (
      <ArtifactOverlay
        {...props.currentArtifact}
        scrollPosition={props.scrollPosition}
        hideArtifact={() => props.setCurrentArtifact(null)}
        showPreviousPage={props.showPreviousPage}
        showNextPage={props.showNextPage}
        activeArtifactPage={props.activeArtifactPage}
        downloadArtifact={props.downloadArtifact}
        artifactDownloading={props.artifactDownloading}
        downloadError={props.downloadError}
        analyticsLabel="from_teacher_portfolio_artifact_modal"
      />
    );
  }

  // only show the loop's completed date it if has one
  const completedDateNode = props.loop.completed ? (
    <div className="tc__body-copy portfolio-edit__substep-strategy-completed">
      Completed: {dateformat(props.loop.completed, 'mm/dd/yyyy')}
    </div>
  ) : null;

  // build the list of artifacts in the loop
  const artifactNodes = props.loop.artifacts.map((artifact) => {
    const inEditMode = props.editModeIds.indexOf(artifact.id) != -1;
    const commentsExpanded = props.showCommentsIds.indexOf(artifact.id) != -1;
    return (
      <ArtifactCapsule
        key={artifact.id}
        artifact={artifact}
        loopId={props.loop.loopId}
        setCurrentArtifact={props.setCurrentArtifact}
        toggleArtifactVisibility={props.toggleArtifactVisibility}
        setArtifactEditMode={props.setArtifactEditMode}
        inEditMode={inEditMode}
        updateArtifact={props.updateArtifact}
        showArtifactComments={props.showArtifactComments}
        commentsExpanded={commentsExpanded}
        toggleArtifactCommentVisibility={props.toggleArtifactCommentVisibility}
      />
    );
  });

  return (
    <div>
      {artifactOverlayNode}
      <div className="portfolio-edit__substep-container">
        <div className="row">
          <div className="cute-10-desktop center-desktop">
            <div className="tc__headline-6">Strategy</div>
            <div className="tc__headline-1 portfolio-edit__substep-strategy-name">
              {props.loop.title}
            </div>
            {completedDateNode}
            <div className="tc__body-copy portfolio-edit__artifacts-instructional-text">
              All of your artifacts are included in the portfolio by default. If
              you wish to hide an artifact from your portfolio, click the check
              icon next to the artifact. If you change your mind, you can click
              the icon again to show the artifact in your portfolio.
            </div>
            {artifactNodes}
          </div>
        </div>
      </div>
      <div className="portfolio-edit__substep-footer tc__fixed-bottom">
        <button
          className="tc__btn tc__btn--small tc__btn--blue"
          onClick={handleSave}
        >
          Save &amp; Exit
        </button>
      </div>
    </div>
  );
};

EditArtifacts.propTypes = {
  loop: PropTypes.object.isRequired,
  editModeIds: PropTypes.array.isRequired,
  showCommentsIds: PropTypes.array.isRequired,
  saveSubstep: PropTypes.func.isRequired,
};

export default EditArtifacts;
