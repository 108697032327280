// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import React from 'react';

class FreezeWindowScroll extends React.Component {
  constructor(props) {
    super(props);

    this.mq = null;
    this.scrollPosition = null;
    this.parentModal = null;
    this.parentPage = null;
    this.pageFooter = null;

    this.state = {
      initalLoad: true,
    };

    this.resizeBodyHeightForModal = this.resizeBodyHeightForModal.bind(this);
  }

  componentDidMount() {
    // only call match media on browser
    this.mq = window.matchMedia('(max-width: 48em)');

    if (this.props.parentModal) {
      this.parentModal = document.querySelector('.' + this.props.parentModal);
      this.parentPage = document.querySelector('.tc__page');
      this.pageFooter = document.querySelector('.footer');
    }
    this.resizeBodyHeightForModal();
  }

  componentDidUpdate() {
    this.resizeBodyHeightForModal();
  }

  componentWillUnmount() {
    Utils.removeClass(document.body, 'no-scroll');
    if (this.parentPage) {
      this.parentPage.removeAttribute('style');
    }
    if (this.pageFooter) {
      this.pageFooter.removeAttribute('style');
    }
  }

  resizeBodyHeightForModal() {
    // if we only want functionality for mobile do nothing qs
    if (this.props.mobileOnly && !this.mq.matches) {
      return;
    }
    // if overlay is open and too tall for the screen,
    // add a height and overflow to the overlays parent
    if (
      this.parentModal &&
      this.parentPage &&
      this.parentModal.offsetHeight + 30 > window.innerHeight
    ) {
      Utils.removeClass(document.body, 'no-scroll');
      this.parentPage.setAttribute(
        'style',
        'overflow: hidden; height: ' +
          (this.parentModal.offsetHeight + 30) +
          'px;'
      );
      // also hide the page footer
      if (this.pageFooter) {
        this.pageFooter.setAttribute('style', 'display: none');
      }
    } else {
      Utils.addClass(document.body, 'no-scroll');
    }

    if (!this.state.initalLoad) {
      return;
    }

    window.setTimeout(() => {
      window.scrollTo(0, 0);
      this.setState({
        initalLoad: false,
      });
    }, 10);
  }

  render() {
    return <noscript />;
  }
}

FreezeWindowScroll.propTypes = {
  mobileOnly: PropTypes.bool,
  parentModal: PropTypes.string.isRequired,
};

export default FreezeWindowScroll;
