// @ts-nocheck FIXME
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import Clipboard from 'clipboard';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const CoachInfo = (props) => {
  const [clipboard, setClipboard] = React.useState('');

  const handleCopyEmailToClipboard = () => {
    props.setUiAlert({
      type: 'success',
      message: 'Email copied to clipboard',
      handleClose: props.setUiAlert({}),
    });
  };

  useEffect(() => {
    setClipboard(
      new Clipboard('.home-v5__email-link', {
        text: function () {
          return props.coach.email;
        },
      })
    );

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, []);

  let coachInfoNode = (
    <div className="participant-details-v5__participant-info home-v5__no-coach">
      <div>
        <img
          className="home-v5__no-coach-image"
          src="/legacy-images/v5/wait-for-coach.png"
        />
      </div>
      <div className="v5__heading-5">Waiting for your coach match</div>
      <div className="v5__body-copy home-v5__no-coach-body">
        We’re reviewing your registration responses and connecting you with the
        right coach. Stay tuned!
      </div>
    </div>
  );

  if (props.coach && props.coach.id) {
    const schoolNode = props.institution && (
      <div className="home-v5__coach-capsule-school">
        {props.institution.name}
      </div>
    );

    // if the product does not have a set # of sessions, it's unlimited coaching
    let meetingsLeftText = 'Unlimited sessions';
    if (props.engagement.product.number_of_sessions) {
      // the participant may have more meetings than the # of sessions,
      // in that case, don't show a negative number
      const meetingsLeft = Math.max(
        props.engagement.product.number_of_sessions -
          props.completed_meeting_count,
        0
      );
      meetingsLeftText = `${meetingsLeft} session${
        meetingsLeft === 1 ? '' : 's'
      } left`;
    }

    // show when the participant finished coaching registration, if at all
    let registeredText = 'Not registered';
    if (props.engagement.registration_completion_formatted) {
      registeredText = `Registered - ${moment(
        props.engagement.registration_completion_formatted
      ).format('M/D/YYYY')}`;
    }

    coachInfoNode = (
      <React.Fragment>
        <div className="participant-details-v5__participant-info--user-photo">
          <UserPhoto
            src={props.coach.photo}
            diameter={60}
            hasBorder={false}
            firstName={props.coach.first_name}
            lastName={props.coach.last_name}
          />
          <div className="v5__comment-copy">Your Coach</div>
        </div>
        <div className="v5__heading-5">
          {props.coach.first_name} {props.coach.last_name}
        </div>
        <div
          className="v5__sidebar-link home-v5__email-link"
          onClick={handleCopyEmailToClipboard}
        >
          <Tooltip tooltip="Copy to clipboard">
            <span className="text">{props.coach.email}</span>
          </Tooltip>
        </div>
        <div className="home-v5__coach-capsule-separator"></div>
        {schoolNode}
        <div className="home-v5__coach-capsule-product">
          {props.engagement.product.name}
        </div>
        <div className="home-v5__coach-capsule-remaining">
          {meetingsLeftText}
        </div>
        <div className="home-v5__coach-capsule-registered">
          {registeredText}
        </div>
        <div className="home-v5__coach-capsule-expiration">
          Expires -{' '}
          {moment(props.engagement.cohort.target_end_date_formatted).format(
            'M/D/YYYY'
          )}
        </div>
      </React.Fragment>
    );
  }

  return <div className="home-v5__coach-info">{coachInfoNode}</div>;
};

CoachInfo.propTypes = {
  coach: PropTypes.object,
  institution: PropTypes.object,
  engagement: PropTypes.object,
  completed_meeting_count: PropTypes.number.isRequired,
  setUiAlert: PropTypes.func,
};

export default CoachInfo;
