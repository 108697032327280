// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import NavigationLink from './../../NavigationLink';
import BlendedMenu from './modules/BlendedMenu';
import LevelFive from './modules/LevelFive';
import LevelThree from './modules/LevelThree';
import MegaMenu from './modules/MegaMenu';
import MegaMenuBar from './modules/MegaMenuBar';

const CommonCore = (props) => {
  let activeStandardNode, mobileBackBar;
  let urlPath = 'common_core_math';
  let megaMenuHeadline = 'Math';
  let childStandards = props.standards_browser.math_child_standards;
  switch (props.ui.activeSubject) {
    case 'ela':
      urlPath = 'common_core_ela';
      megaMenuHeadline = 'English Language Arts';
      childStandards = props.standards_browser.ela_child_standards;
      break;
    case 'science':
      urlPath = 'next_gen_science';
      megaMenuHeadline = 'Science';
      childStandards = props.standards_browser.science_child_standards;
      break;
  }

  let megaMenuNode = (
    <MegaMenu
      activeSubject={props.ui.activeSubject}
      childStandards={childStandards}
      expandMobileStandardsBlock={props.expandMobileStandardsBlock}
      expandedMobileStandardsBlock={props.ui.expandedMobileStandardsBlock}
      megaMenuHeadline={megaMenuHeadline}
      megaMenuOpen={props.ui.megaMenuOpen}
    />
  );
  if (props.ui.activeSubject == 'blended') {
    megaMenuNode = (
      <BlendedMenu
        expandMobileStandardsBlock={props.expandMobileStandardsBlock}
        expandedMobileStandardsBlock={props.ui.expandedMobileStandardsBlock}
        categories={props.blended_browser.BlendedTopicCategories}
        megaMenuHeadline={megaMenuHeadline}
        megaMenuOpen={props.ui.megaMenuOpen}
      />
    );
  }
  if (props.activeStandard) {
    let mainComponent;
    if (
      parseInt(props.activeStandard.CommonCore.level) == 3 ||
      parseInt(props.activeStandard.CommonCore.level) == 4
    ) {
      activeStandardNode = <LevelThree {...props} />;
      const backLink =
        process.env.REACT_APP_TEACHING_URL +
        (props.ui.activeSubject ? '/browse/' + urlPath : '/browse/common_core');
      mobileBackBar = (
        <div className="standards__mobile-header show-phone hide-desktop">
          <NavigationLink url={backLink} className="standards__mobile-back">
            <div className="standards__mobile-back-icon tc-arrow"></div>
            <div className="standards__mobile-back-caption">Back</div>
          </NavigationLink>
          <div className="standards__mobile-header-title">
            {props.activeStandard.CommonCore.description}
          </div>
        </div>
      );
    } else if (
      parseInt(props.activeStandard.CommonCore.level) == 5 ||
      parseInt(props.activeStandard.CommonCore.level) == 6 ||
      parseInt(props.activeStandard.CommonCore.level) == 7
    ) {
      activeStandardNode = <LevelFive {...props} />;
      mobileBackBar = (
        <div className="standards__mobile-header show-phone hide-desktop">
          <NavigationLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/browse/common_core/standard/' +
              props.standards_browser.selected_core_domain.CommonCore.id
            }
            className="standards__mobile-back"
          >
            <div className="standards__mobile-back-icon tc-arrow"></div>
            <div className="standards__mobile-back-caption">Back</div>
          </NavigationLink>
          <div className="standards__mobile-header-title">
            {props.activeStandard.CommonCore.identifier}
          </div>
        </div>
      );
    }
  }

  return (
    <div className="tc__page">
      <Header page="commonCore" {...props} />
      <div className="bl4__page-background"></div>
      <MegaMenuBar
        activeStandard={props.activeStandard}
        activeSubject={props.ui.activeSubject}
        openMenu={props.openMenu}
        closeMenu={props.closeMenu}
        megaMenuOpen={props.ui.megaMenuOpen}
      />
      {megaMenuNode}
      {mobileBackBar}
      {activeStandardNode}
    </div>
  );
};

CommonCore.propTypes = {
  ui: PropTypes.object.isRequired,
  standards_browser: PropTypes.object.isRequired,
  blended_browser: PropTypes.object.isRequired,
  activeStandard: PropTypes.object,
};

export default CommonCore;
