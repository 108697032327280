// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import StickyFooter from '@legacy-components/react/V4/Common/StickyFooter';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import React from 'react';
import AbsoluteAlert from './../../../Alerts/Absolute';
import NavigationLink from './../../../NavigationLink';
import ConfirmSendEmailModal from './../../Common/ConfirmModal';
import ConfirmDeleteModal from './../Common/ConfirmDeleteModal';
import AddCohortUser from './AddCohortUser';
import CohortUserRow from './CohortUserRow';

const CohortUsers = (props) => {
  const lab = useLegacyLabContext();
  const [loading, setLoading] = React.useState(false);

  const isDataViewer = Utils.isDataViewer.call(lab);

  const handleAddCohortUserModal = (e) => {
    e.preventDefault();
    props.toggleModal('addCohortParticipantModal');
  };

  const handleUpdateCoaches = (e) => {
    e.preventDefault();

    props.updateCoaches({
      cohortId: props.cohort.id,
      setLoading: setLoading,
    });
  };

  const handleSelectCoaches = (e) => {
    e.preventDefault();

    props.enableCoachSelection();
  };

  // set up any alert banner
  let alertNode = null;
  if (Object.keys(props.ui.alert).length) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // set up the Add/Delete User Modal if necessary
  let modalNode = null;
  if (props.ui.addCohortParticipantModal.opened) {
    modalNode = (
      <AddCohortUser
        ui={props.ui.addCohortParticipantModal}
        close={() => props.toggleModal('addCohortParticipantModal')}
        recordChange={true}
        move={props.moveCohortParticipant}
        submit={props.addCohortParticipant}
        isLoading={props.ui.addCohortParticipantModal.isLoading}
        title={props.cohort.name}
      />
    );
  } else if (props.ui.confirmSendEmailModal.opened) {
    const user = props.ui.confirmSendEmailModal.object;
    modalNode = (
      <ConfirmSendEmailModal
        {...props.ui.confirmSendEmailModal}
        bodyText={
          'Send Welcome Email to ' + user.name + ' at ' + user.email + '?'
        }
        submitBtnText="Send Email"
        closeConfirmModal={props.closeConfirmSendEmailModal}
        handleSubmit={props.sendWelcomeEmail}
      />
    );
  } else if (props.ui.confirmDeleteModal.object) {
    // API endpoint is different for different roles
    const onDelete = props.ui.confirmDeleteModal.object.is_coach
      ? props.deleteCohortCoach
      : props.deleteCohortParticipant;
    modalNode = (
      <ConfirmDeleteModal
        {...props.ui.confirmDeleteModal}
        customBodyText={
          'Remove ' +
          props.ui.confirmDeleteModal.object.name +
          ' from ' +
          props.cohort.name +
          '?'
        }
        closeConfirmDeleteModal={props.closeConfirmDeleteModal}
        onDelete={onDelete}
      />
    );
  }
  // Data viewers are the only people who can view this page but can't edit
  const canEdit = Utils.isManager.call(lab) || Utils.isAdmin.call(lab);

  let editButtons;
  let editColumn;
  let selectCoachNode = null;

  if (canEdit) {
    if (props.ui.editCohort.isSelectingCoaches) {
      selectCoachNode = (
        <StickyFooter
          submit={handleUpdateCoaches}
          loading={loading}
          submitText="Save Coaches"
        />
      );
    } else {
      selectCoachNode = (
        <StickyFooter
          submit={handleSelectCoaches}
          loading={loading}
          submitText="Edit Coaches"
        />
      );
    }
    editButtons = (
      <React.Fragment>
        <div className="cohort-dashboard__table-modals">
          <button
            className="admin-v5__edit-table-btn v5__link cohort-dashboard"
            onClick={handleAddCohortUserModal}
          >
            <span className="tc-v5-plus-solid admin-v5__edit-table-btn-icon"></span>
            <div className="cohort-dashboard__btn-text">Add Participant</div>
          </button>
        </div>
      </React.Fragment>
    );
    editColumn = (
      <div className={'cohort-dash__cohort-header grid-big__col-4'}></div>
    );
  }
  const productName = props.cohort.product.label;
  const productNode = (
    <div className="v5__heading-5 cohort-dashboard__product-name">
      {productName}
    </div>
  );
  const priorityDomains = props.cohort.priorityDomains;
  const focusAreasNode = priorityDomains.map((priorityDomain, i) => {
    return (
      <p key={i} className="cohort-dashboard__focus-areas">
        {' '}
        {priorityDomain.label}{' '}
      </p>
    );
  });
  const editCohortNode = (
    <NavigationLink
      url={'/bl/cohort/' + props.cohort.id}
      className="tc_link edit-cohort"
    >
      <span
        title={'Edit Cohort'}
        className="tc-pencil workspace__action-icon"
      ></span>
    </NavigationLink>
  );

  let noCoachingEngagementsNode = null;
  if (props.cohortUsers.coachingEngagements.length === 0) {
    noCoachingEngagementsNode = (
      <div className="cohort-dashboard__empty-state">
        No participants have registered for this cohort yet.
      </div>
    );
  }

  return (
    <div className="cohort-edit tc__page">
      <Header page="CohortsUserList" {...props} />
      {alertNode}
      {modalNode}

      <div className="row tc__body-copy">
        <div className="cute-12-desktop ppd-v3-curation__breadcrumbs">
          <NavigationLink url="/bl/cohorts" className="tc__link">
            Cohorts
          </NavigationLink>
          <span className="tc-arrow ppd-v3-curation__breadcrumb-separator"></span>
          Cohort Members
        </div>
      </div>

      <div className="row">
        <div className="cute-8-desktop">
          <h3 className="cohorts__headline tc__headline-2">
            {props.cohort.name}
            {editCohortNode}
          </h3>
          {productNode}
          {focusAreasNode}
        </div>
        <div className="cute-4-desktop">{editButtons}</div>
      </div>

      <div className="row">
        <div className="grid-big__row admin-v5__coach-table__header">
          <div className={'cohort-dash__cohort-header grid-big__col-13'}>
            Name
          </div>
          <div className={'cohort-dash__cohort-header grid-big__col-4'}>
            Role
          </div>
          <div className={'cohort-dash__cohort-header grid-big__col-6'}>
            Registration
          </div>
          <div className={'cohort-dash__cohort-header grid-big__col-6'}>
            Last Active
          </div>
          <div className={'cohort-dash__cohort-header grid-big__col-9'}>
            Outcomes
          </div>
          <div className={'cohort-dash__cohort-header grid-big__col-6'}>
            Coach
          </div>
          {editColumn}
        </div>
        {props.cohortUsers.leads.map((cohortUser) => {
          return (
            <CohortUserRow
              key={'cohort-leader-' + cohortUser.id}
              isDv={isDataViewer}
              type={'leader'}
              cohortUser={cohortUser}
              openConfirmSendEmailModal={props.openConfirmSendEmailModal}
              canEdit={canEdit}
              copyEmailAddress={props.copyEmailAddress}
            />
          );
        })}
        {props.cohortUsers.coachingEngagements.map((coachingEngagement) => {
          return (
            <CohortUserRow
              key={'cohort-user-' + coachingEngagement.coaching_engagement_id}
              cohortUser={coachingEngagement.participant}
              coachingEngagement={coachingEngagement}
              coachSelections={props.coachSelections}
              isSelectingCoaches={props.ui.editCohort.isSelectingCoaches}
              coachOptions={props.ui.editCohort.coaches.options}
              upsertCoachSelection={props.upsertCoachSelection}
              openConfirmSendEmailModal={props.openConfirmSendEmailModal}
              openConfirmDeleteModal={props.openConfirmDeleteModal}
              type={'participant'}
              canEdit={canEdit}
              copyEmailAddress={props.copyEmailAddress}
            />
          );
        })}
        {noCoachingEngagementsNode}
      </div>
      {selectCoachNode}
    </div>
  );
};

CohortUsers.propTypes = {
  ui: PropTypes.object.isRequired,
  cohort: PropTypes.object.isRequired,
  cohortUsers: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default CohortUsers;
