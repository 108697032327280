// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import FeedbackApi from './../../apis/FeedbackApi';
import Store from './../../components/Store';
import WindowHelpers from './../../components/WindowHelpers';

const store = Store('TmlLoopStoreV2', {
  isMobile: false,
  alert: null,
  step: 'teach',
  feedback: {},
  artifacts: [],
  artifactSectionMode: 'start',
  artifactFile: null,
  measurementPercent: null,
  measurementValue: '',
  totalNumStudents: null,
  learnStepSatisfied: true,
  completed: false,
  doneChecked: false,
  metricId: null,
  metricText: null,
  baseline: null,
  goal: null,
  openEditBox: 0,
  coachEditingMeasurement: false,
  challenge: null,
  feedbackCommentUI: {
    teach: {
      show: false,
      submitting: false,
      error: null,
      notifications: [],
    },
    measure: {
      show: false,
      submitting: false,
      error: null,
      notifications: [],
    },
    learn: {
      show: false,
      submitting: false,
      error: null,
      notifications: [],
    },
  },
  comments: { teach: [], measure: [], learn: [] },
});

// ACTIONS
store.actionCreate('init', function (data) {
  store.isMobile = data.isMobile || false;
  store.coachEditingMeasurement = false;
  store.alert = null;
  store.step = data.loop.tml_step || 'teach';
  store.feedback = data.loop.feedback || {};
  store.artifacts = data.loop.artifacts || [];
  store.artifactSectionMode = 'start';
  store.artifactFile = null;
  store.measurementPercent = data.loop.measurement.percent || null;
  store.measurementValue = data.loop.measurement.value || null;
  store.measurementPercentInitial = data.loop.measurement.percent || null;
  store.measurementValueInitial = data.loop.measurement.value || null;
  store.measurementSubmitted = data.loop.measurement.value ? true : false;
  store.totalNumStudents = data.loop.challenge_instance.num_students || null;
  store.doneChecked = !!data.loop.completed;
  store.metricId =
    data.loop.challenge_instance.teaching_challenge_metric_id || null;
  store.metricText =
    data.loop.challenge_instance.teaching_challenge_metric_description || null;
  store.baseline = data.loop.challenge_instance.baseline_value;
  store.goal = data.loop.challenge_instance.goal_value || null;
  store.completed = data.loop.completed ? true : false;
  store.comments = data.loop.comments;

  // the "I'm done" checkbox is disabled unless there is text in the "learn" feedback field
  // or the checkbox is already checked
  if ('learn' in data.loop.feedback) {
    store.learnStepUnsatisfied =
      !data.loop.feedback.learn && !data.loop.completed;
  }

  // add a section for the challenge
  store.challenge = data.loop.challenge_instance;
  store.challenge.teaching_challenge_metric_id = store.metricId;
  store.challenge.teaching_challenge_metric_description = store.metricText;
  store.challenge.baseline_value = store.baseline;
  // eslint-disable-next-line no-self-assign
  store.challenge.baseline_measurement = store.challenge.baseline_measurement;
  // eslint-disable-next-line no-self-assign
  store.challenge.goal_measurement = store.challenge.goal_measurement;
  store.challenge.goal_value = store.goal;
  store.challenge.num_students = store.totalNumStudents;
  store.challenge.is_instructional_design = data.loop.isInstructionalDesign;

  // cancel navigation alert
  WindowHelpers.navigationConfirmation(false);
});

store.actionCreate('setMeasurementSubmitted', (status) => {
  store.measurementSubmitted = status;
});

store.actionCreate('revertMeasurements', () => {
  store.measurementPercent = store.measurementPercentInitial;
  store.measurementValue = store.measurementValueInitial;
});

store.actionCreate('reinitializeMeasurements', () => {
  store.measurementPercentInitial = store.measurementPercent;
  store.measurementValueInitial = store.measurementValue;
});

store.actionCreate('artifactCommentIncrement', (artifactId) => {
  store.artifacts = store.artifacts.map((artifact) => {
    if (artifact.id != artifactId) {
      return artifact;
    }
    artifact.commentCount++;
    return artifact;
  });
});

store.actionCreate('setCommentToEditMode', (data) => {
  store.comments[data.step] = store.comments[data.step].map((comment) => {
    if (data.commentId == comment.id) {
      comment.editting = !comment.editting;
    }
    return comment;
  });
});

store.actionCreate('setFeedbackCommentShow', (data) => {
  // add case
  if (!data.commentId) {
    store.feedbackCommentUI[data.step].show =
      !store.feedbackCommentUI[data.step].show;
    return;
  }

  // edit case
  store.comments[data.step] = store.comments[data.step].map((comment) => {
    if (comment.id == data.commentId) {
      comment.error = null;
      comment.editting = !comment.editting;
      comment.notifications = [];
    }
    return comment;
  });
});

store.actionCreate('setFeedbackCommentSubmitting', (data) => {
  // add case
  if (!data.commentId) {
    store.feedbackCommentUI[data.step].submitting = data.submitting;
    store.feedbackCommentUI[data.step].notifications = [];
    return;
  }

  // edit case
  store.comments[data.step] = store.comments[data.step].map((comment) => {
    if (comment.id == data.commentId) {
      comment.submitting = data.submitting;
    }
    return comment;
  });
});

store.actionCreate('setFeedbackCommentError', (data) => {
  // add case
  if (!data.commentId) {
    store.feedbackCommentUI[data.step].error = data.error;
    store.feedbackCommentUI[data.step].notifications = [];
    return;
  }

  // edit case
  store.comments[data.step] = store.comments[data.step].map((comment) => {
    if (comment.id == data.commentId) {
      comment.error = data.error;
    }
    return comment;
  });
});

store.actionCreate('setFeedbackCommentNotification', (data) => {
  // add case
  if (!data.commentId) {
    if (!data.notification) {
      store.feedbackCommentUI[data.step].notifications = [];
      return;
    }
    // avoid duplicate
    if (
      store.feedbackCommentUI[data.step].notifications.indexOf(
        data.notification
      ) !== -1
    ) {
      return;
    }
    store.feedbackCommentUI[data.step].notifications.push(data.notification);
    return;
  }

  // edit case
  store.comments[data.step] = store.comments[data.step].map((comment) => {
    if (comment.id != data.commentId) {
      return comment;
    }

    if (!data.notification) {
      comment.notifications = [];
      return comment;
    }

    if (!comment.notifications) {
      comment.notifications = [];
    }

    // avoid duplicate
    if (comment.notifications.indexOf(data.notification) !== -1) {
      return comment;
    }

    comment.notifications.push(data.notification);

    return comment;
  });
});

store.actionCreate('clearCommentNotifications', function (data) {
  store.feedbackCommentUI[data.step].notifications = [];
  store.feedbackCommentUI[data.step].error = '';
});

store.actionCreate('addFeedbackComment', (data) => {
  store.comments[data.step].push(data.comment);
});

store.actionCreate('editFeedbackComment', (data) => {
  store.comments[data.step] = store.comments[data.step].map((comment) => {
    if (comment.id == data.commentId) {
      comment.data = data.comment;
      comment.error = null;
      comment.editting = false;
      comment.notifications = [];
    }
    return comment;
  });
});

store.actionCreate('setStudentCount', function (value) {
  store.totalNumStudents = value;
  if (store.challenge) {
    store.challenge.num_students = value;
  }
});

store.actionCreate('setIsMobile', function (isMobile) {
  store.isMobile = isMobile;
});

store.actionCreate('setAlert', function (alert) {
  store.alert = alert;
});

store.actionCreate('setStep', function (step) {
  store.step = step;
});

store.actionCreate('setFeedback', function (data) {
  store.feedback[data.step] = data.message;
  // the "I'm done" checkbox is disabled unless there is text in the "learn" feedback field
  // or it is already checked
  if (data.step == 'learn') {
    store.learnStepUnsatisfied = !data.message && !store.doneChecked;
  }
});

store.actionCreate('artifactStartMode', function () {
  store.artifactSectionMode = 'start';
  store.artifactFile = null;
});

store.actionCreate('artifactAddMode', function () {
  store.artifactSectionMode = 'add';
  store.artifactFile = null;
});

// called by LoopArtifactUpload as the file is uploading
store.actionCreate('artifactEditMode', function (file) {
  // show alert if user attempts to navigate away
  WindowHelpers.navigationConfirmation(true);
  store.artifactSectionMode = 'edit';
  store.artifactFile = file;
});

// update the artifact with data if it already exists; otheriwse, add a new artifact to the store
store.actionCreate('editArtifact', function (data) {
  // cancel navigation alert
  WindowHelpers.navigationConfirmation(false);

  const artifacts = Utils.clone(store.artifacts);
  let matched = false;
  artifacts.forEach(function (artifact) {
    if (artifact.id == data.id) {
      matched = true;
      artifact.artifact_type_id = data.artifact_type_id;
      artifact.type_name = data.type_name;
      artifact.notes = data.notes;
    }
  });
  if (!matched) {
    if (!data.commentCount) {
      data.commentCount = 0;
    }
    artifacts.push(data);
  }
  store.artifacts = artifacts;
});

// remove the artifact from the store
store.actionCreate('removeArtifact', function (data) {
  const artifacts = Utils.clone(store.artifacts);
  artifacts.forEach(function (artifact, i) {
    if (artifact.id == data.id) {
      artifacts.splice(i, 1);
    }
  });
  store.artifacts = artifacts;
});

// if we are given a percentage, update the value to match
store.actionCreate('setMeasurementPercent', function (percent) {
  store.measurementPercent = percent;
  if (store.totalNumStudents) {
    store.measurementValue = Math.round(
      (percent / 100) * store.totalNumStudents
    );
  }
});

// if we are given a valid value, update the percentage to match
store.actionCreate('setMeasurementValue', function (value) {
  if (value >= 0 && value <= store.totalNumStudents) {
    store.measurementValue = value;
    if (store.totalNumStudents) {
      store.measurementPercent = Math.round(
        (value / store.totalNumStudents) * 100
      );
    }
  }
});

store.actionCreate('toggleDoneChecked', function () {
  store.doneChecked = !store.doneChecked;
});

store.actionCreate('updateMetric', function (data) {
  store.metricId = data.metricId;
  store.metricText = data.metricText;
});

store.actionCreate('updateBaselineGoal', function (data) {
  store.baseline = data.baseline;
  store.goal = data.goal;
  store.totalNumStudents = data.num_students;
  if (store.measurementPercent) {
    store.measurementValue = Math.round(
      (store.measurementPercent / 100) * store.totalNumStudents
    );
  }
  store.challenge.baseline_value = data.baseline;
  store.challenge.baseline_measurement = data.baseline_measurement;
  store.challenge.goal_value = data.goal;
  store.challenge.goal_measurement = data.goal_measurement;
});

store.actionCreate('openEditBox', function () {
  store.openEditBox = store.openEditBox++;
});

store.actionCreate('toggleEditingMeasurement', function () {
  store.coachEditingMeasurement = !store.coachEditingMeasurement;

  // prevent navigating without confirmation while open
  if (store.coachEditingMeasurement) {
    WindowHelpers.navigationConfirmation(true);
  } else {
    WindowHelpers.navigationConfirmation(false);
  }
});

// functions for operating on this domain
store.functionCreate('feedbackCommentUpsert', function (data) {
  // input validation
  if (!data.comment) {
    store.actionDispatch('setFeedbackCommentNotification', {
      step: data.step,
      notification: 'Comment can not be empty',
      commentId: data.commentId,
    });
    return;
  }

  // mark the component as sending
  store.actionDispatchSync('setFeedbackCommentSubmitting', {
    step: data.step,
    submitting: true,
    commentId: data.commentId,
  });

  // server communication
  FeedbackApi.postComment(data).then(
    // success case
    (response) => {
      // handle add case
      if (!data.commentId) {
        // take out of submission mode, don't emit event (yet)
        store.actionDispatchSync(
          'setFeedbackCommentSubmitting',
          {
            step: data.step,
            submitting: false,
            commentId: data.commentId,
          },
          false
        );

        // hide the input, don't emit event (yet)
        store.actionDispatchSync(
          'setFeedbackCommentShow',
          {
            step: data.step,
          },
          false
        );

        // add comment to store
        store.actionDispatchSync('addFeedbackComment', {
          step: data.step,
          comment: response.payload.comment,
        });
      } else {
        // edit the comment in the store
        store.actionDispatchSync('editFeedbackComment', {
          step: data.step,
          comment: data.comment,
          commentId: data.commentId,
        });
      }
    },

    // error case
    (response) => {
      store.actionDispatch('setFeedbackCommentError', {
        step: data.step,
        error: 'Comment can not be posted at this time.',
        commentId: data.commentId,
      });
    }
  );
});

export default store;
