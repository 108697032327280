// @ts-nocheck FIXME
import UpdatesRowContainers from '@legacy-components/react/V5/PartnerLeader/modules/UpdatesRowContainer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const Activity = (props) => {
  const [sortedColumn, setSortedColumn] = React.useState('recent-highlight');
  const [sortDirection, setSortDirection] = React.useState('asc');

  const ascending = sortDirection === 'asc';
  const descending = sortDirection === 'desc';

  // @todo @jonathanrys move these into Utilities
  // Recursive function to get a nested value from an object based on a dot-separated string key
  const getValue = (baseObject, keys) => {
    if (!baseObject) return null;

    if (keys.length) {
      return getValue(baseObject[keys.pop()], keys);
    }
    return baseObject;
  };

  // Wrapper function to sort on null values
  const sortWithNull = (func, key) => {
    // Allow nested values
    const accessors = key.split('.').reverse();

    return (a, b) => {
      if (getValue(a, [...accessors])) {
        if (getValue(b, [...accessors])) {
          return func(a, b);
        }
        return -1;
      }
      return 1;
    };
  };

  // Wrapper function to reverse sort on null values so that null always floats to the bottom
  const reverseSortWithNull = (func, key) => {
    // Allow nested values
    const accessors = key.split('.').reverse();

    return (a, b) => {
      if (getValue(a, [...accessors])) {
        if (getValue(b, [...accessors])) {
          return func(a, b);
        }
        return 1;
      }
      return -1;
    };
  };

  const nullSorter = ascending ? sortWithNull : reverseSortWithNull;

  const sortFunctions = {
    name: (a, b) => a.last_name.localeCompare(b.last_name),
    school: nullSorter(
      (a, b) => a.school_name.localeCompare(b.school_name),
      'school_name'
    ),
    strategies: (a, b) => b.strategies.length - a.strategies.length,
    highlights: (a, b) => b.highlight_count - a.highlight_count,
    clips: (a, b) => b.clips - a.clips,
    'recent-highlight': nullSorter(
      (a, b) =>
        moment(a.recent_highlight.highlighted) -
        moment(b.recent_highlight.highlighted),
      'recent_highlight.highlighted'
    ),
  };

  // Sort order indication arrow
  let sortIndicator = null;

  if (ascending) {
    props.participants.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (descending) {
    props.participants.sort(sortFunctions[sortedColumn]);
    props.participants.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortOrder[sortDirection]);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };
  const clipClass = 'cute-2-desktop';
  // if (props.currentYear === 'past') {
  //     clipClass = 'cute-4-desktop';
  // }

  const activityRows = (
    <UpdatesRowContainers
      participants={props.participants}
      section="participants"
      reportingPhases={props.reportingPhases}
      currentYear={props.currentYear}
    />
  );

  let participantsTable;
  if (props.participants.length > 0) {
    const recentHighlights = (
      <div
        className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
        onClick={() => toggleSortDirection('recent-highlight')}
      >
        <span className={'ppd-3-coach-table__header-caption left clickable'}>
          Recent Highlight
        </span>
        {sortedColumn === 'recent-highlight' ? sortIndicator : null}
      </div>
    );
    // if (props.currentYear === 'past') {
    //     recentHighlights = null;
    // }
    participantsTable = (
      <React.Fragment>
        <div className="admin-v5__coach-table__header row v5__registered-table-header-copy">
          <div
            className="cute-2-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header"
            onClick={() => toggleSortDirection('name')}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sortedColumn === 'name' ? ' active' : '')
              }
            >
              Name
            </span>
            {sortedColumn === 'name' ? sortIndicator : null}
          </div>

          <div
            className={`${clipClass} admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header`}
            onClick={() => toggleSortDirection('school')}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sortedColumn === 'school' ? ' active' : '')
              }
            >
              School
            </span>
            {sortedColumn === 'school' ? sortIndicator : null}
          </div>

          <div className="cute-3-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header">
            <span className="ppd-3-coach-table__header-caption left">
              Active Learning Domains
            </span>
          </div>

          <div
            className="cute-1-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header sort-arrow-adjust"
            onClick={() => toggleSortDirection('strategies')}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sortedColumn === 'strategies' ? ' active' : '')
              }
            >
              Strategies
            </span>
            {sortedColumn === 'strategies' ? sortIndicator : null}
          </div>

          <div
            className="cute-1-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header sort-arrow-adjust"
            onClick={() => toggleSortDirection('highlights')}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sortedColumn === 'highlights' ? ' active' : '')
              }
            >
              Highlights
            </span>
            {sortedColumn === 'highlights' ? sortIndicator : null}
          </div>

          <div
            className={
              'cute-1-desktop admin-v5__coach-table__header-col partner_lead_v5__cohort-row-column-header'
            }
            onClick={() => toggleSortDirection('clips')}
          >
            <span
              className={
                'ppd-3-coach-table__header-caption left clickable' +
                (sortedColumn === 'clips' ? ' active' : '')
              }
            >
              Clips
            </span>
            {sortedColumn === 'clips' ? sortIndicator : null}
          </div>
          {recentHighlights}
        </div>
        {activityRows}
      </React.Fragment>
    );
  }

  return (
    <div className="cute-12-desktop v5__registered-table">
      {props.tableHeadingRow}
      {participantsTable}
    </div>
  );
};

Activity.propTypes = {
  tableHeadingRow: PropTypes.object.isRequired,
  participants: PropTypes.array.isRequired,
};

export default Activity;
