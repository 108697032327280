// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { Router } from './../../components/Router';
import Utils from './../../components/StaticUtils';

const PartnerUserRow = (props) => {
  // event listeners
  const handleShowPartnerUser = (e) => {
    e.preventDefault();
    Router.route('/bl/user/' + props.partnerUser.id);
  };

  /* BEGIN RENDERING LOGIC */

  // format dates
  const termStart = Utils.formatDate(props.partnerUser.term_start);
  const termEnd = Utils.formatDate(props.partnerUser.term_end);

  return (
    <tr className="admin-table__row">
      <td className="admin-table__col">
        <a
          className="tc__link"
          onClick={handleShowPartnerUser}
          href={'/bl/user/' + props.partnerUser.id}
        >
          {props.partnerUser.name}
        </a>
      </td>
      <td className="admin-table__col">{props.partnerUser.email}</td>
      <td className="admin-table__col">{props.partnerUser.pathway}</td>
      <td className="admin-table__col">{termStart}</td>
      <td className="admin-table__col">{termEnd}</td>
    </tr>
  );
};

PartnerUserRow.propTypes = {
  partnerUser: PropTypes.object.isRequired,
};

export default PartnerUserRow;
