// @ts-nocheck FIXME
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import React from 'react';

const MultiSelectView = (props) => {
  const inputElem = React.useRef();

  // focus on the input when this component becomes editable
  React.useEffect(() => {
    props.hasFocus &&
      props.canTypeText &&
      props.canSelectMoreOptions &&
      inputElem.current &&
      inputElem.current.focus();
  }, [props.canTypeText, props.hasFocus, props.canSelectMoreOptions]);

  const selectId = props.uId ? props.uId : 'custom-select-input';
  const availableNodes =
    props.hasFocus &&
    props.dropdownOptions
      .filter((option) => {
        // If the user has selected the maximum number of options, nothing is available
        if (!props.canSelectMoreOptions) {
          return false;
        }
        // when the input is blurred... nothing is available
        if (props.textInput.length < props.minCharCountForResults) {
          return false;
        }
        // if the value is selected, it is not available
        if (props.selectedOptions.find((op) => op.id === option.id)) {
          return false;
        }
        // There are some cases where checking the option's description makes sense, specifically timezones
        // Additionally since descriptions are always visible this should cause no issues where the user doesn't understand why an option is shown
        const textInputToCompare = props.textInput.toLocaleLowerCase();
        const matchesDescription =
          option.description &&
          option.description.toLocaleLowerCase().includes(textInputToCompare);
        return (
          option.label.toLocaleLowerCase().includes(textInputToCompare) ||
          matchesDescription
        );
      })
      .map((option, i) => {
        let descriptionNode = null;
        if (props.showDescription) {
          descriptionNode = (
            <div className="custom-select__option-description">
              {option.description}
            </div>
          );
        }
        return (
          <div
            key={`option-${option.id}-${i}`}
            className="custom-select__option custom-select__option--variable-height"
            onMouseDown={(e) => props.handleSelectOption(option, e)}
          >
            {option.label}
            {descriptionNode}
          </div>
        );
      });
  const absolutePosition = props.absolutePosition ? '-absolute' : '';
  // only show the options menu if there are visible options
  let optionsNode = null;
  let optionsVisibleClass = '';
  if (availableNodes.length) {
    optionsNode = (
      <div className={`custom-select__options${absolutePosition}`}>
        {availableNodes}
      </div>
    );
    optionsVisibleClass = ' custom-select--options-visible';
  }

  const selectedNodes = props.selectedOptions.map((option, i) => {
    const disabledClass = props.disabled
      ? ' custom-select__selected--disabled'
      : '';
    const deleteNode = !props.disabled && (
      <div
        className="custom-select__selected-remove tc-x"
        onClick={(e) => props.handleRemoveOption(option, e)}
        data-testid={`multiselect-remove-${option.label}`}
      ></div>
    );
    // show the shortLabel if there is more than one option selected
    const label =
      props.shortenLabels &&
      option.shortLabel &&
      props.selectedOptions.length > 1
        ? option.shortLabel
        : option.label;

    return (
      <div
        className={`custom-select__selected${disabledClass}`}
        key={`sel-${option.id}-${i}`}
      >
        <div className="custom-select__selected-name">{label}</div>
        {deleteNode}
      </div>
    );
  });

  // if there are no selected options and the input is not usable,
  // return any empty state node that was provided
  if (!selectedNodes.length && !props.canTypeText && props.emptyStateNode) {
    return props.emptyStateNode;
  }

  // set up any field error
  let errorClass = '';
  let errorNode;
  if (props.error || props.hasError) {
    errorClass = ' error';
    errorNode = <ErrorText {...props} />;
  }

  let inputPlaceholder = props.placeholder;
  let inputEmptyClass = ' tc__input--custom-select-empty';
  if (props.selectedOptions.length) {
    inputPlaceholder = '';
    inputEmptyClass = '';
  }

  // If the user can no longer type and this is a a search style input then remove the inputs
  const inputNode = props.canTypeText && (
    <input
      type="text"
      autoFocus={props.autoFocus}
      id={selectId}
      ref={inputElem}
      value={props.textInput}
      className={'tc__input tc__input--custom-select' + inputEmptyClass}
      placeholder={inputPlaceholder}
      onChange={(e) => {
        props.updateTextInput(e.target.value);
      }}
      onClick={props.handleFocus}
      onFocus={props.handleFocus}
      onBlur={props.handleBlur}
      data-testid={props.dataTestId || 'multiselect-input'}
    />
  );
  const borderClass = props.canTypeText ? 'custom-select tc__input' : '';

  const classes = props.className || '';
  return (
    <div className={`custom-select__container ${classes}`}>
      <div
        className={borderClass + errorClass + optionsVisibleClass}
        onClick={props.handleSelectClick}
        data-testid={`${props.uId ? props.uId + '-' : ''}multiselect-container`}
      >
        <div className="custom-select__selected-options">
          {selectedNodes}
          {inputNode}
        </div>
      </div>
      {optionsNode}
      {errorNode}
    </div>
  );
};

export default MultiSelectView;
