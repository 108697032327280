// @ts-nocheck FIXME
import moment from 'moment';
import PropTypes from 'prop-types';

const UpdateRow = (props) => {
  const handleViewUpdate = () => {
    const data = props.update;
    data['type'] = 'report';
    props.viewArtifact(data);
  };

  const handleEdit = () => {
    const data = props.update;
    data['index'] = props.index;
    data['mode'] = 'edit';
    data['type'] = 'report';
    props.editArtifact(data);
  };

  const handleDelete = () => {
    const data = props.update;
    data['index'] = props.index;
    data['type'] = 'report';

    props.openConfirmDelete(data);
  };

  // set up update title with link, depending on type of update
  let updateTitleNode = (
    <div
      className="cute-5-desktop cute-12-phone lead-dash__link"
      onClick={handleViewUpdate}
    >
      <span className="lead-dash__update-icon tc-file-document"></span>
      <span className="lead-dash__update-title">
        {props.update.title ? props.update.title : 'Partnership Update'}
      </span>
    </div>
  );
  if (props.update.url) {
    updateTitleNode = (
      <div className="cute-5-desktop cute-12-phone">
        <a
          href={props.update.url}
          target="_blank"
          rel="noopener noreferrer"
          className="lead-dash__link"
        >
          <span className="lead-dash__update-icon tc-file-link"></span>
          <span className="lead-dash__update-title">
            {props.update.title ? props.update.title : 'Partnership Update'}
          </span>
        </a>
      </div>
    );
  }

  return (
    <div className="v5__admin--report-row">
      <div className="row">
        {updateTitleNode}
        <div className="cute-5-desktop cute-12-phone left lead-dash__update-date">
          Added {moment(props.update.created_formatted).fromNow()}
        </div>
        <div className="cute-2-desktop cute-12-phone cohort-dash__update-action-icons">
          <span
            className="tc-pencil cohort-dash__update-action-icon"
            data-testid="update-edit-icon"
            onClick={handleEdit}
          ></span>
          <span
            className="tc-delete cohort-dash__update-action-icon"
            data-testid="update-delete-icon"
            onClick={handleDelete}
          ></span>
        </div>
      </div>
    </div>
  );
};

UpdateRow.propTypes = {
  update: PropTypes.object.isRequired,
  viewArtifact: PropTypes.func.isRequired,
  viewText: PropTypes.func.isRequired,
  editArtifact: PropTypes.func.isRequired,
  editText: PropTypes.func.isRequired,
  openConfirmDelete: PropTypes.func.isRequired,
};

export default UpdateRow;
