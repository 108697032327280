// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { Router } from './../../components/Router';

const PartnerUserAdd = (props) => {
  // input value variable declaration
  let partnerUserEmail;

  // event listners
  const handlePartnerHideAddUser = (e) => {
    e.preventDefault();
    props.toggleModal({ feature: 'addPartnerUser' });
  };

  const handleAddPartnerUser = (e) => {
    e.preventDefault();

    // don't do anything if the form is submitting
    if (props.isLoading) {
      return;
    }

    const data = {
      email: partnerUserEmail.value,
      accountId: props.partnerId,
    };

    props.addPartnerUser(data);
  };

  const handleClearErrorsOnFocus = (e) => {
    e.preventDefault();
    props.clearErrorsOnFocus({ feature: 'addPartnerUser' });
  };

  /* BEGIN RENDERING LOGIC */

  // failed validation results
  let errorNode = null;
  let inputErrorClass = '';
  if (props.error) {
    errorNode = (
      <div className="tc__response-inline tc__response-inline--error">
        <ul>
          <li>{props.error}</li>
        </ul>
      </div>
    );
    inputErrorClass = ' error';
  }

  // build notifications
  let notificationNode = [];
  if (props.notifications.length) {
    notificationNode = (
      <div className="tc__modal-inline-error tc__response-inline tc__response-inline--error">
        <ul>
          {props.notifications.map((notification, index) => (
            <li key={'notification' + index}>{notification}</li>
          ))}
        </ul>
      </div>
    );
    inputErrorClass = ' error';
  }

  // display notification link
  let notificationLinkNode;
  if (props.notificationLink.path) {
    notificationLinkNode = (
      <a
        className="tc__link--modal-add-user tc__link"
        href={props.notificationLink.path}
        onClick={(e) => {
          e.preventDefault();
          Router.route(props.notificationLink.path);
        }}
      >
        {props.notificationLink.text}
      </a>
    );
  }

  const dissableButtonClass = props.isLoading ? ' disabled' : '';

  return (
    <div className="tc__modal-add-overlay show">
      <div
        onClick={handlePartnerHideAddUser}
        className="modal__page-overlay modal__page-overlay--grey modal__page-overlay--add"
      ></div>
      <div className="tc__modal-add">
        <form className="">
          <h3 className="tc__modal-add-headline tc__headline-3">
            Add Partner User by Email
          </h3>
          {errorNode}
          {notificationNode}
          <div className="tc__modal-add-row">
            <label className="tc__modal-add-label">Email: </label>
            <input
              className={'tc__input tc__modal-add-input' + inputErrorClass}
              key="partnerinput"
              ref={(ref) => (partnerUserEmail = ref)}
              autoFocus={true}
              onFocus={handleClearErrorsOnFocus}
              defaultValue={partnerUserEmail}
            />
            {notificationLinkNode}
          </div>
          <div className="tc__modal-add-row tc__modal-add-row--btn">
            <input
              className={
                'tc__btn tc__btn--blue tc__btn--small tc__btn--add-submit' +
                dissableButtonClass
              }
              type="submit"
              value="submit"
              onClick={handleAddPartnerUser}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

PartnerUserAdd.propTypes = {
  partnerId: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addPartnerUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  notificationLink: PropTypes.object.isRequired,
  userName: PropTypes.string,
};

export default PartnerUserAdd;
