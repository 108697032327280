// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import CoachMatchApi from '@legacy-components/apis/V5/CoachMatchApi';
import ZoomApi from '@legacy-components/apis/V5/ZoomApi';
import Utils from '@legacy-components/components/DynamicUtils';
import StorageHelper from '@legacy-components/components/StorageHelper';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import NavigationLink from '@legacy-components/react/NavigationLink';
import CoachMatchAction from '@legacy-components/react/V5/Common/CoachMatchAction';
import Modal from '@legacy-components/react/V5/Common/Modal';
import WindowScrollElementFixer from '@legacy-components/react/V5/Common/WindowScrollElementFixer';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ZenScroll from 'zenscroll';
import CalendarRow from './Calendar/CalendarRow';
import CoachModals from './Coaching/CoachModals';
import CoachTools from './Coaching/CoachTools';
import ParticipantsTable from './Coaching/ParticipantsTable';
import Events from './Events/Events';
import CoachMatches from './Matches/CoachMatches';
import MobileHome from './MobileHome';
import Surveys from './Surveys/Surveys';
import TimeZoneIndicator from './modules/TimeZoneIndicator';

const CoachHomeContainer = (props) => {
  const lab = useLegacyLabContext();
  const search = React.useRef(null);
  const pageHeadline = React.useRef();

  const [tableView, setTableView] = React.useState(
    StorageHelper.getItem('coachEventsTableView') || 'upcoming'
  );
  const [filter, setFilter] = React.useState(null);
  const [proposedMatches, setProposedMatches] = React.useState(
    props.proposedMatches
  );
  const [coachMatchActionError, setCoachMatchActionError] =
    React.useState(null);
  const [coachMatchActionLoading, setCoachMatchActionLoading] =
    React.useState(false);
  const [selectedMatchForAction, setSelectedMatchForAction] = React.useState({
    id: null,
    action: null,
  });
  const [participants, setParticipants] = React.useState(props.participants);
  // show/hide the disconnect Zoom confirmation modal
  const [showConfirmDisconnectZoom, setShowConfirmDisconnectZoom] =
    React.useState(false);

  // show/hide the disconnect Zoom confirmation modal
  const [showConfirmDisconnectGCal, setShowConfirmDisconnectGCal] =
    React.useState(false);

  const activePage = props.pageName || 'events';

  const updateMatch = (data) => {
    CoachMatchApi.updateCoachMatchStatus(data)
      .then((response) => {
        setCoachMatchActionLoading(false);
        if (response.status === 'error') {
          setCoachMatchActionError(response.error);
          return;
        }

        handleEmptySelectedMatchForAction();

        const localPropsedMatches = [...proposedMatches];
        const matchIndex = Utils.getItemIndexFromArrayOfObjects(
          localPropsedMatches,
          'id',
          response.coach_match.id
        );

        const selectedMatch = localPropsedMatches[matchIndex];
        const userName = `${selectedMatch.user.first_name} ${selectedMatch.user.last_name}`;

        localPropsedMatches.splice(matchIndex, 1);
        setProposedMatches(localPropsedMatches);
        if (response.participants) {
          setParticipants(response.participants);
        }

        const successMessage =
          data.status === 'Accepted'
            ? `You accepted ${userName} as a coaching participant.`
            : `You declined your match with ${userName}.`;

        props.setAlert({
          type: 'success',
          message: successMessage,
        });

        ZenScroll.to(pageHeadline.current);

        // remove match for the list
      })
      .catch(() => {
        setCoachMatchActionLoading(false);
        props.coachMatchActionError(
          'Something went wrong. Please try again later.'
        );
      });
  };

  const removeParticipant = (data) => {
    CoachMatchApi.updateCoachMatchStatus(data)
      .then((response) => {
        // Remove loading state
        setCoachMatchActionLoading(false);
        // Check for errors
        if (response.status === 'error') {
          setCoachMatchActionError(response.error);
          return;
        }

        // Close the modal
        handleEmptySelectedMatchForAction();
        // Remove participant from list of matches
        setProposedMatches(
          proposedMatches.filter((match) => match.id !== data.match_id)
        );
        // Remove participant from the list
        setParticipants(
          participants.filter(
            (participant) => participant.id !== data.participantId
          )
        );

        // Set success alert
        props.setAlert({
          type: 'success',
          message: `${props.user.first_name} ${props.user.last_name} removed from coaching participants`,
        });

        ZenScroll.to(pageHeadline.current);
      })
      .catch(() => {
        setCoachMatchActionLoading(false);
        props.coachMatchActionError(
          'Something went wrong. Please try again later.'
        );
      });
  };

  const handleSetSelectedMatchForAction = (data) => {
    setSelectedMatchForAction(data);
  };

  const handleEmptySelectedMatchForAction = () => {
    setCoachMatchActionError(null);
    setSelectedMatchForAction({
      id: null,
      action: null,
    });
  };

  let coachMatchModalNode = null;
  if (selectedMatchForAction.id) {
    const matchIndex = Utils.getItemIndexFromArrayOfObjects(
      proposedMatches,
      'id',
      selectedMatchForAction.id
    );
    coachMatchModalNode = (
      <Modal
        className={'v5__modal--action'}
        close={handleEmptySelectedMatchForAction}
      >
        <CoachMatchAction
          user={props.user}
          setCoachMatchActionError={setCoachMatchActionError}
          coachMatchActionError={coachMatchActionError}
          setCoachMatchActionLoading={setCoachMatchActionLoading}
          coachMatchActionLoading={coachMatchActionLoading}
          removeParticipant={removeParticipant}
          updateMatch={updateMatch}
          {...proposedMatches[matchIndex]}
          action={selectedMatchForAction.action}
          close={handleEmptySelectedMatchForAction}
        />
      </Modal>
    );
  }

  const canDisconnect =
    Utils.isAdmin.call(lab, props.user) ||
    Utils.isManager.call(lab, props.user);

  // Only show the agenda if the user is viewing their own page as a coach
  const canSeeAgenda =
    props.user.id === props.pageUser.id &&
    (Utils.isServicesManager.call(lab, props.user) ||
      Utils.isContractCoach.call(lab, props.user));

  // Only show the surveys tab if the user is viewing their own page as a coach or the user is a Super admin
  const canSeeSurveys =
    (props.user.id === props.pageUser.id &&
      Utils.isContractCoach.call(lab, props.user)) ||
    Utils.isAdmin.call(lab);

  React.useEffect(() => {
    if (props.failedGoogleToken) {
      props.setErrorAlert(
        'Failed to grant user access on your google account, please remove Betterlesson from your google account.'
      );
    }
    if (props.expiredGoogleToken) {
      props.setErrorAlert(
        'Your google account connection expired, please sync again.'
      );
    }
    if (props.googleCalendarContactError) {
      props.setErrorAlert(
        'We encountered an error recovering your calendar info, try again later.'
      );
    }
  }, []);

  const handleDisconnectZoom = () => {
    ZoomApi.deleteTokens({ userId: props.pageUser.id })
      .then(() => {
        props.disconnectZoom();
        setShowConfirmDisconnectZoom(false);
      })
      .catch(() => {
        props.setErrorAlert(
          'Oh no! Looks like something broke on our end. Wait a minute and try again.'
        );
        setShowConfirmDisconnectZoom(false);
      });
  };

  const createCalendarEntry = (item) => (
    <CalendarRow
      {...item}
      key={`meeting-${item.id}`}
      time_zone={props.user.time_zone}
    />
  );

  // Create the calendar node
  let calendarContentNode = (
    <div className="admin-v5__coach-table--no-permissions">
      You do not have permissions to view this coach&apos;s calendar.
    </div>
  );

  if (canSeeAgenda) {
    const timezone = props.user.time_zone.pytz_timezone;
    const now = moment().tz(timezone);

    let today = null;
    let tomorrow = null;

    if (props.user.calendar) {
      const meetingsToday = props.user.calendar.filter((meeting) =>
        moment(meeting.start.dateTime).isSame(now, 'day')
      );
      today = meetingsToday.map(createCalendarEntry);

      const meetingsTomorrow = props.user.calendar.filter(
        (meeting) => !moment(meeting.start.dateTime).isSame(now, 'day')
      );
      tomorrow = meetingsTomorrow.map(createCalendarEntry);
    }

    calendarContentNode = (
      <React.Fragment>
        {today && today.length ? (
          today
        ) : (
          <div className="admin-v5_coach-dashboard__calendar-row">
            A meeting-free day? Lucky you!
          </div>
        )}
        <div className="v5__heading-4 admin-v5_coach-dashboard__calendar-heading">
          Tomorrow
        </div>
        {tomorrow && tomorrow.length ? (
          tomorrow
        ) : (
          <div className="admin-v5_coach-dashboard__calendar-row">
            No meetings tomorrow. Kick back and relax!
          </div>
        )}
      </React.Fragment>
    );
  }

  // Build the alert node if needed:
  let alertNode;

  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const handleSearchChange = (e) => {
    setFilter(e.target.value);
  };

  const searchFailedNode = props.search_failed && (
    <div className="cute-12-desktop">
      <div className="admin-v5_coach-dashboard__search-failed v5__comment-copy">
        <div className="admin-v5-search-fail-padding">
          <span className="bold">
            Our activity feed for participants is temporarily unavailable.{' '}
          </span>
          Thanks for your patience as we tidy up this issue.
        </div>
      </div>
    </div>
  );

  /* Build the page based which page is selected */
  let visiblePage = null;
  let participantSearchNode = null;
  let coachMatchesNode = null;

  if (props.pageName === 'events') {
    visiblePage = (
      <Events {...props} tableView={tableView} setTableView={setTableView}>
        {searchFailedNode}
      </Events>
    );
  } else if (props.pageName === 'coaching') {
    participantSearchNode = (
      <div className="admin-v5_coach-dashboard--search-container">
        <input
          type="text"
          ref={search}
          className="v5__input header__v5-search-input"
          placeholder="Search by name or email"
          onInput={handleSearchChange}
        />
        <button
          className="header__search-button custom-select__single-selected-remove tc__link tc-search"
          onClick={handleSearchChange}
        ></button>
      </div>
    );

    const coachToolsNode = (
      <CoachTools
        {...props}
        canSeeAgenda={canSeeAgenda}
        setShowConfirmDisconnectZoom={setShowConfirmDisconnectZoom}
        setShowConfirmDisconnectGCal={setShowConfirmDisconnectGCal}
        canDisconnect={canDisconnect}
      />
    );

    /* Invite participant */
    const inviteParticipantNode = null;

    if (proposedMatches && proposedMatches.length) {
      coachMatchesNode = (
        <div className="row">
          <div className="cute-12-desktop">
            <CoachMatches
              pageUser={props.pageUser}
              coachTimeZone={props.user.time_zone.pytz_timezone}
              handleSetSelectedMatchForAction={handleSetSelectedMatchForAction}
              proposedMatches={proposedMatches}
            />
          </div>
        </div>
      );
    }
    visiblePage = (
      <React.Fragment>
        {coachMatchesNode}
        <div className="row">
          <div className="cute-9-desktop">
            <div className="admin-v5_coach-dashboard--calendar">
              <div className="admin-v5_coach-dashboard--section-heading">
                <h3 className="admin-v5_coach-dashboard--calendar-title v5__heading-3">
                  Meetings
                </h3>
                <TimeZoneIndicator {...props.pageUser.time_zone} />
              </div>
              <div className="v5__heading-4 admin-v5_coach-dashboard__calendar-heading">
                Today, {moment().format('LL')}
              </div>
              {calendarContentNode}
            </div>
          </div>
          <div className="cute-3-desktop">{coachToolsNode}</div>
        </div>
        <div className="row">
          <div className="cute-12-desktop">
            <h3 className="admin-v5_coach-dashboard--participants-title left v5__heading-3">
              Participants
            </h3>
            <div className="right">
              <NavigationLink
                className="admin-v5__toggle-link v5__link"
                url={`/lab/coach/${props.pageUser.id}/archive`}
              >
                <div className="admin-v5__toggle-link-icon tc-v5-archive" />
                <div>View Archive</div>
              </NavigationLink>
            </div>
          </div>
          {searchFailedNode}
          <div className="cute-4-desktop">{participantSearchNode}</div>
          <div className="cute-8-desktop">{inviteParticipantNode}</div>
        </div>
        <div>
          <ParticipantsTable
            {...props}
            participants={participants}
            filter={filter}
          />
        </div>
      </React.Fragment>
    );
  } else if (props.pageName === 'surveys') {
    visiblePage = <Surveys {...props} />;
  }

  const eventsPageLink = (
    <NavigationLink
      className={`admin-v5_coach-dashboard__navigation--row events ${
        activePage === 'events' ? ' active' : ''
      }`}
      url={`/lab/coach/${props.pageUser.id}/events`}
    >
      <div className="inline-icon tc-v5-icon-events large-icon"></div>
      <span>Events</span>
    </NavigationLink>
  );

  const surveyLink = (
    <NavigationLink
      className={`admin-v5_coach-dashboard__navigation--row surveys ${
        activePage === 'surveys' ? ' active' : ''
      }`}
      url={process.env.REACT_APP_COACH_DASHBOARD_SURVEY_URL}
      target="_blank"
    >
      <div className="inline-icon tc-v5-survey-icon-2 large-icon"></div>
      <span className="coach-surveys__surveys-tab-text">Surveys</span>
    </NavigationLink>
  );

  const surveysPageLink = canSeeSurveys ? surveyLink : null;

  return (
    <div className="tc__page">
      <Header page="CoachHome" {...props} />
      {alertNode}
      {coachMatchModalNode}
      <CoachModals
        {...props}
        hasSyncedGoogleCalendar={props.pageUser.has_synced_google_calendar}
        handleDisconnectGCal={props.revokeGoogleCredentials}
        handleDisconnectZoom={handleDisconnectZoom}
        showConfirmDisconnectZoom={showConfirmDisconnectZoom}
        setShowConfirmDisconnectZoom={setShowConfirmDisconnectZoom}
        showConfirmDisconnectGCal={showConfirmDisconnectGCal}
        setShowConfirmDisconnectGCal={setShowConfirmDisconnectGCal}
        revokeGoogleCredentials={() =>
          props.revokeGoogleCredentials(props.pageUser.id)
        }
      />

      {/* Desktop */}
      <div className="show-desktop hide-phone admin-v5_coach-dashboard__main-content">
        <div
          className="row row--1200 admin-v5_coach-dashboard__page-header"
          ref={pageHeadline}
        >
          <div className="cute-12-desktop">
            <div className="left">
              <h1 className="v5__heading-1 admin-v5_coach-dashboard--headline">
                Coach Dashboard
              </h1>
            </div>
          </div>
        </div>
        <div className="row row--1200 admin-v5_coach-dashboard--content-container">
          <div className="cute-2-desktop admin-v5_coach-dashboard--navigation-sidebar">
            <div
              className={
                'show-desktop hide-phone admin-v5_coach-dashboard__navigation'
              }
            >
              {eventsPageLink}
              <NavigationLink
                className={`admin-v5_coach-dashboard__navigation--row coaching ${
                  activePage === 'coaching' ? ' active' : ''
                }`}
                url={`/lab/coach/${props.pageUser.id}/coaching`}
              >
                <div className="inline-icon tc-v5-coaching_lineart large-icon"></div>
                <span>Coaching</span>
              </NavigationLink>
              {/* {surveysPageLink} */}
            </div>
            <WindowScrollElementFixer
              positionToClass="admin-v5_coach-dashboard--content-container"
              positionThisClass="admin-v5_coach-dashboard__navigation"
              positionThisContainerClass="admin-v5_coach-dashboard--navigation-sidebar"
              updateFlag={JSON.stringify({
                ...props,
                activePage: activePage,
                tableView: tableView,
              })}
            />
          </div>
          <div className="cute-10-desktop admin-v5_coach-dashboard--content-table">
            {visiblePage}
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="hide-desktop show-phone">
        <MobileHome
          {...props}
          tableView={tableView}
          setTableView={setTableView}
          activePage={activePage}
          canDisconnect={canDisconnect}
          canSeeAgenda={canSeeAgenda}
          coachMatchesNode={coachMatchesNode}
          setShowConfirmDisconnectGCal={setShowConfirmDisconnectGCal}
        >
          {searchFailedNode}
          <div className="cute-12-desktop">{participantSearchNode}</div>
        </MobileHome>
      </div>
    </div>
  );
};

CoachHomeContainer.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const ConnectedCoachHomeContainer = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => <CoachHomeContainer {...props} setAlert={setAlert} />}
  </HeaderConsumer>
);

export default ConnectedCoachHomeContainer;
