// @ts-nocheck FIXME
import Request from './../components/Request';

const FeedbackApi = {
  postComment: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/teacher/' +
        data.teacherId +
        '/tml_loop/v2/' +
        data.loopId +
        '/comment',
      JSON.stringify(data)
    );
  },

  getComments: function (feedbackId) {
    return Request.get('/coaching/lab/feedback/' + feedbackId + '/comments');
  },
};

export default FeedbackApi;
