// @ts-nocheck FIXME
import Analytics from './../../../components/Analytics';
import ScaleCenterImage from './../../ScaleCenterImage';

const CoachingSpotlight = (props) => {
  const displayCoachingDetails = (e) => {
    e.preventDefault();

    const tab = props.tab.toLowerCase().replace('-', '_');
    Analytics.logEvent({
      category: tab,
      action: 'view_coach_video',
      label: 'from_pp_consumer_view',
    });

    window.open(props.data.url, '_blank').focus();
  };

  const teacherImage = props.data.teacher_image
    ? props.data.teacher_image
    : '/legacy-images/default-user.svg';

  return (
    <div className="row">
      <div
        onClick={displayCoachingDetails}
        className="ppp__elem ppp__elem--coaching cute-10-desktop cute-1-desktop-offset left"
      >
        <div className="ppp__coach-video">
          <div className="ppp__coach-video-icon">
            <img
              alt="Coaching Spotlight"
              src="/legacy-images/partner-portfolio-coaching-spotlight-placeholder.svg"
            />
          </div>
        </div>
        <div className="ppp__coach-details">
          <div className="ppp__coach-user">
            <div className="ppp__coach-user-image">
              <ScaleCenterImage
                src={teacherImage}
                className="ppp__user-image"
              />
            </div>
            <div className="ppp__coach-user-name ppp__elem-headline">
              {props.data.coachName}
            </div>
          </div>
          <div className="ppp__coach-teacher ppp__elem-subheadline">
            <span className="ppp__elem-label">Teacher: </span>
            <span>{props.data.teacherName}</span>
          </div>
          <a className="ppp__coach-video-description tc__link ppp__elem-headline">
            {props.data.description}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CoachingSpotlight;
