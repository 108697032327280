// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';

const EventProductDescription = (props) => {
  const ACTION = {
    REGISTER: 'register',
    CHECK_IN: 'checkin',
  };
  const overviewHeading =
    props.action === ACTION.REGISTER
      ? 'About this upcoming session'
      : 'About this session';

  return props.description ? (
    <div className={'reservation__overview'}>
      <div className="v5__heading-4 reservation__overview-heading">
        {overviewHeading}
      </div>
      <PurifiedHTML
        className="ckeditor__output reservation__overview-text"
        content={props.description}
      />
    </div>
  ) : null;
};

export default EventProductDescription;
