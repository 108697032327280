// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

const Tooltip = (props) => {
  const [hover, setHover] = React.useState(false);
  const showing = [];
  const hiding = [];

  React.useEffect(() => {
    // I'm using the useEffect hook for it's cleanup method to clear the timeouts that reference this component's scope
    return () => {
      showing.forEach((x) => clearTimeout(x));
      hiding.forEach((x) => clearTimeout(x));
    };
  }, [showing, hiding]);

  React.useEffect(() => {
    if (props.hideTooltip) {
      setHover(false);
    }
  }, [props.hideTooltip]);

  // Return null if no tooltip is provided
  if (!props.tooltip) return props.children;

  const mouseIn = () => {
    // Clear any existing timeouts
    showing.forEach((x) => clearTimeout(x));
    showing.length = 0;
    showing.push(
      setTimeout(() => {
        setHover(true);
      }, props.delay || 350)
    );
  };

  const mouseOut = () => {
    // Clear any existing timeouts
    showing.forEach((x) => clearTimeout(x));
    showing.length = 0;
    hiding.push(
      setTimeout(() => {
        setHover(false);
      }, props.delay || 750)
    );
  };

  const styles = {};

  if (!isNaN(parseInt(props.offsetLeft))) {
    // Use the offset prop to control the left side of the tooltip for situations where the parent container is a different size than the child
    styles.left = `${props.offsetLeft}px`;
  }

  if (props.offsetLeft) {
    styles.left = props.offsetLeft;
  }

  if (!isNaN(parseInt(props.offsetTop))) {
    // Use the offset prop to control the left side of the tooltip for situations where the parent container is a different size than the child
    styles.top = `${props.offsetTop}px`;
  }

  if (props.nowrap) {
    styles.whiteSpace = 'nowrap';
  } else if (props.prewrap) {
    styles.whiteSpace = 'pre-wrap';
    styles.width = 'max-content';
  }

  if (props.minWidth) {
    styles.minWidth = `${props.minWidth}px`;
  }

  const overallClass =
    props.arrow === false ? 'v5__tooltip-no-arrow' : 'v5__tooltip';

  const tooltip = (
    <span
      className={`${overallClass} ${props.tipClass || ''}`}
      style={styles}
      onMouseOver={mouseOut}
    >
      {props.tooltip}
    </span>
  );

  return (
    <div
      className={props.className}
      style={{
        position: 'relative',
        display: props.inline ? 'inline-block' : 'block',
      }}
      onMouseOver={mouseIn}
      onMouseLeave={mouseOut}
    >
      {props.children}
      {hover ? tooltip : null}
    </div>
  );
};

Tooltip.propTypes = {
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tipClass: PropTypes.string,
  delay: PropTypes.number,
  offsetTop: PropTypes.number,
  offsetLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string.isRequired,
  inline: PropTypes.bool,
};

Tooltip.defaultProps = {
  className: '',
};

export default Tooltip;
