// @ts-nocheck FIXME
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import React from 'react';
import AttendeeRow from './AttendeeRow';

const AttendeesTable = (props) => {
  const [sortedColumn, setSortedColumn] = React.useState('name');
  const [sortDirection, setSortDirection] = React.useState('asc');

  // starting index of columns to show, 0 is school
  const [startingColumn, setStartingColumn] = React.useState(0);

  // don't show this table if there are no attendees
  if (!props.attendees.length) return null;

  // don't show the Group column if this event has no groups
  const showGroupCol = ArrayHelpers.hasElements(props.groups);

  // don't show the RSVP column if this event has no RSVPs
  const showRsvpCol =
    props.attendees.reduce((prev, next) => {
      return prev + !!next.reserved;
    }, 0) > 0;

  // 4 means grades + subject + school + role columns, we always show name column so it doesn't count
  const maxColumns = showRsvpCol + showGroupCol + props.maxSessionNum + 4;

  // whether display left and right arrow button
  // by default hide left button, and show right button if max columns more than 9
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(maxColumns > 9);

  const displayNodes = [];

  // Wrapper function to sort on null values
  const sortWithNull = (func, key) => {
    return (a, b) => {
      if (a && a[key]) {
        if (b && b[key]) {
          return func(a, b);
        }
        return -1;
      } else if (b && b[key]) {
        return 1;
      } else {
        return 0;
      }
    };
  };

  // set up sort functions for all the table columns
  const sortFunctions = {
    name: sortWithNull(
      (a, b) => a.user_last_name.localeCompare(b.user_last_name),
      'user_last_name'
    ),
    school: sortWithNull(
      (a, b) => a.institution.localeCompare(b.institution),
      'institution'
    ),
    role: sortWithNull((a, b) => a.role.localeCompare(b.role), 'role'),
    group: sortWithNull(
      (a, b) => a.group_name.localeCompare(b.group_name),
      'group_name'
    ),
    rsvp: (a, b) => !!b.reserved - !!a.reserved,
  };
  // add sort functions for each of the session check-in columns
  Utils.range(1, props.maxSessionNum + 1).forEach((num) => {
    sortFunctions[`session-${num}`] = (a, b) => {
      // see if the two attendees have checked in to any session with this num
      const aCheckedIn =
        a.event_sessions &&
        a.event_sessions.some((session) => {
          return props.sessionNumMap[session.session_id] === num;
        });
      const bCheckedIn =
        b.event_sessions &&
        b.event_sessions.some((session) => {
          return props.sessionNumMap[session.session_id] === num;
        });

      return aCheckedIn === bCheckedIn ? 0 : aCheckedIn ? -1 : 1;
    };
  });

  // Sort order indication arrow
  let sortIndicator = null;
  if (sortDirection === 'asc') {
    props.attendees.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (sortDirection === 'desc') {
    props.attendees.sort(sortFunctions[sortedColumn]);
    props.attendees.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortOrder[sortDirection]);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };

  // direction is either left or right
  const handleStartingColumnChange = (direction) => {
    let newStartingColumn = startingColumn;
    if (direction === 'left') {
      newStartingColumn -= 3;
      // if the button gets clicked it means there must be extra columns
      setShowRightArrow(true);

      // we are reaching the left-most cell, hide left arrow
      if (newStartingColumn <= 0) {
        newStartingColumn = 0;
        setShowLeftArrow(false);
      }
    } else if (direction === 'right') {
      newStartingColumn += 3;
      setShowLeftArrow(true);
      // we are reaching the right-most cell, hide right arrow
      if (newStartingColumn + 9 >= maxColumns) {
        newStartingColumn = maxColumns - 9;
        setShowRightArrow(false);
      }
    }
    setStartingColumn(newStartingColumn);
  };

  displayNodes.push(
    <div
      className={'v5__registered-table-column-name cute-1-desktop'}
      key="table-school"
      onClick={() => toggleSortDirection('school')}
    >
      <span
        className={
          'ppd-3-coach-table__header-caption left clickable' +
          (sortedColumn === 'school' ? ' active' : '')
        }
      >
        School
      </span>
      {sortedColumn === 'school' ? sortIndicator : null}
    </div>,
    <div
      className={'v5__registered-table-column-name cute-1-desktop'}
      key="table-grade"
    >
      <span className={'ppd-3-coach-table__header-caption left'}>Grade</span>
    </div>,
    <div
      className={'v5__registered-table-column-name cute-1-desktop'}
      key="table-subject"
    >
      <span className={'ppd-3-coach-table__header-caption left'}>Subject</span>
    </div>,
    <div
      className={'v5__registered-table-column-name cute-1-desktop'}
      key="table-role"
      onClick={() => toggleSortDirection('role')}
    >
      <span
        className={
          'ppd-3-coach-table__header-caption left clickable' +
          (sortedColumn === 'role' ? ' active' : '')
        }
      >
        Role
      </span>
      {sortedColumn === 'role' ? sortIndicator : null}
    </div>
  );

  // set up the Group column header if necessary
  if (showGroupCol) {
    displayNodes.push(
      <div
        className={'v5__registered-table-column-name cute-1-desktop'}
        key="table-group"
        onClick={() => toggleSortDirection('group')}
      >
        <span
          className={
            'ppd-3-coach-table__header-caption left clickable' +
            (sortedColumn === 'group' ? ' active' : '')
          }
        >
          Group
        </span>
        {sortedColumn === 'group' ? sortIndicator : null}
      </div>
    );
  }

  // set up the RSVP column header if necessary
  if (showRsvpCol) {
    displayNodes.push(
      <div
        className="v5__registered-table-column-name cute-1-desktop"
        key="table-rsvp"
        onClick={() => toggleSortDirection('rsvp')}
      >
        <span
          className={
            'ppd-3-coach-table__header-caption left clickable' +
            (sortedColumn === 'rsvp' ? ' active' : '')
          }
        >
          RSVP
        </span>
        {sortedColumn === 'rsvp' ? sortIndicator : null}
      </div>
    );
  }

  Utils.range(1, props.maxSessionNum + 1).map((num) => {
    displayNodes.push(
      <div
        key={`session-${num}-col`}
        className="v5__registered-table-column-name cute-1-desktop pl-event__no-right-padding"
        onClick={() => toggleSortDirection(`session-${num}`)}
      >
        <span
          className={
            'ppd-3-coach-table__header-caption left clickable' +
            (sortedColumn === `session-${num}` ? ' active' : '')
          }
        >
          {`Session ${num}`}
        </span>
        {sortedColumn === `session-${num}` ? sortIndicator : null}
      </div>
    );
  });

  // generate placeholders to maintain table layout
  if (displayNodes.length < 9) {
    Utils.range(displayNodes.length, 10).map((num) => {
      displayNodes.push(
        <div
          key={`session-${num}-placeholder-col`}
          className={'v5__registered-table-column-name cute-1-desktop'}
        />
      );
    });
  }

  return (
    <div className="pl-event__table">
      <div className="v5__heading-3">Event Attendance</div>

      <div className="admin-v5__coach-table__header cute-100">
        <div
          className={'v5__registered-table-column-name cute-3-desktop'}
          onClick={() => toggleSortDirection('name')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'name' ? ' active' : '')
            }
          >
            Name
          </span>
          {sortedColumn === 'name' ? sortIndicator : null}
        </div>

        {displayNodes.slice(startingColumn, startingColumn + 9).map((node) => {
          return node;
        })}
      </div>

      {showLeftArrow && (
        <div
          className="pl-event__arrow-container"
          onClick={() => handleStartingColumnChange('left')}
          data-testid="left-arrow"
        >
          <span className="tc-bold-arrow pl-event__arrow"></span>
        </div>
      )}
      {showRightArrow && (
        <div
          className="pl-event__arrow-container pl-event__arrow-container--right"
          onClick={() => handleStartingColumnChange('right')}
          data-testid="right-arrow"
        >
          <span className="tc-bold-arrow pl-event__arrow pl-event__arrow--right"></span>
        </div>
      )}

      {props.attendees.map((attendee) => {
        return (
          <AttendeeRow
            key={`attendee-${attendee.user_id}`}
            {...attendee}
            timeZone={props.timeZone}
            startingColumn={startingColumn}
            showRsvpCol={showRsvpCol}
            showGroupCol={showGroupCol}
            maxSessionNum={props.maxSessionNum}
            sessionNumMap={props.sessionNumMap}
          />
        );
      })}
    </div>
  );
};

AttendeesTable.propTypes = {
  attendees: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  maxSessionNum: PropTypes.number.isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default AttendeesTable;
