// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../components/StaticUtils';
import NavigationLink from './../../NavigationLink';

const SelectedParticipantWidget = (props) => {
  if (Utils.isEmpty(props.participant)) {
    return null;
  }

  return (
    <div className="">
      <div className="show-desktop hide-phone">
        <div className="left">
          <div className="header__admin-participant-name ppd-v3-curation__inline-label">
            {props.participant.name}&apos;s
          </div>
          <div className="header__admin-icons">
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Dashboard"}
                url={
                  '/coaching/lab/participant/' +
                  props.participant.id +
                  '/dashboard?from=navigation_widget'
                }
                className="tc__link tc-dashboard"
              ></NavigationLink>
            </span>
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Vision"}
                url={
                  '/coaching/lab/participant/' +
                  props.participant.id +
                  '/vision?from=navigation_widget'
                }
                className="tc__link tc-vision"
              ></NavigationLink>
            </span>
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Profile"}
                url={
                  '/coaching/lab/participant/' +
                  props.participant.id +
                  '/profile?from=navigation_widget'
                }
                className="tc__link tc-profile"
              ></NavigationLink>
            </span>
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Portfolio"}
                url={
                  '/coaching/lab/participant/' +
                  props.participant.id +
                  '/portfolio'
                }
                className="tc__link tc-presentation"
              ></NavigationLink>
            </span>
          </div>
        </div>
      </div>

      <div className="hide-desktop show-phone">
        <div className="header__admin-icons">
          <div className="ppd-v3-curation__inline-label">
            {props.participant.name}&apos;s
          </div>
          <div>
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Dashboard"}
                url={
                  '/coaching/lab/participant/' +
                  props.participant.id +
                  '/dashboard?from=navigation_widget'
                }
                className="tc__link tc-dashboard"
              ></NavigationLink>
            </span>
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Vision"}
                url={
                  '/coaching/lab/participant/' +
                  props.participant.id +
                  '/vision?from=navigation_widget'
                }
                className="tc__link tc-vision"
              ></NavigationLink>
            </span>
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Profile"}
                url={
                  '/coaching/lab/participant/' +
                  props.participant.id +
                  '/profile?from=navigation_widget'
                }
                className="tc__link tc-profile"
              ></NavigationLink>
            </span>
            <span className="ppd-3-coach__icon">
              <NavigationLink
                title={props.participant.name + "'s Portfolio"}
                url={
                  '/coaching/lab/participant/' +
                  props.participant.id +
                  '/portfolio'
                }
                className="tc__link tc-presentation"
              ></NavigationLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

SelectedParticipantWidget.propTypes = {
  participant: PropTypes.object,
};

export default SelectedParticipantWidget;
