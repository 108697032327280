// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useState } from 'react';
import Modal from './../../Common/Modal';
import Spinner from './../../Common/Spinner';

const UnhighlightModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (props.growthHighlightId) {
      handleUnhighlightOutcomeGrowth();
    } else {
      handleUnhighlightEntry();
    }
  };

  const handleUnhighlightEntry = () => {
    props.submit(props.participantName);
  };

  const handleUnhighlightOutcomeGrowth = () => {
    props.unhighlightGrowth(props.growthHighlightId, props.participantName);
  };

  // disable the Highlight button after submit
  const btnProps = isLoading ? { disabled: true } : null;
  const warningTrailingCopy = props.growthHighlightId
    ? '.'
    : ' and your Growth Reflection will be deleted.';

  return (
    <Modal close={props.close}>
      <form>
        <div className="v5__heading-3">Remove highlight?</div>

        <div className="v5__body-copy workspace__form-row">
          {`This entry will be removed from ${props.participantName}'s highlights${warningTrailingCopy}`}
        </div>

        <div className="v5__body-copy workspace__form-row">
          You can still access this {props.type} in {props.participantName}
          &apos;s workspace.
        </div>

        <div className="workspace__form-row">
          <button
            onClick={handleSubmit}
            className="v5__btn v5__btn--red"
            {...btnProps}
          >
            <Spinner loading={isLoading} />
            Remove
          </button>
        </div>
        <div className="workspace__form-row">
          <button onClick={props.close} className="v5__link v5__link--grey">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

UnhighlightModal.propTypes = {
  type: PropTypes.string.isRequired,
  participantName: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

UnhighlightModal.defaultProps = {
  type: 'entry',
};

export default UnhighlightModal;
