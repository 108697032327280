// @ts-nocheck FIXME
import { getToken } from '@utils/TokenLocator';
import Q from 'q';
import reqwest from './../libraries/reqwest';

const getAccessToken = () => {
  const token = getToken();
  return token && token.access_token;
};

const Request = {
  get: function (url, data = null) {
    // setup xhr options
    const options = {
      method: 'get',
      type: 'json',
      url: url,
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
      },
    };

    if (data) {
      options.data = data;
    }

    return requestWithToken(options);
  },

  post: function (url, postData) {
    // set up xhr options
    const options = {
      method: 'post',
      contentType: 'application/json',
      type: 'json',
      url: url,
      data: postData,
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
      },
    };

    return requestWithToken(options);
  },

  delete: function (url, data = null) {
    // set up xhr options
    const options = {
      method: 'delete',
      contentType: 'application/json',
      type: 'json',
      url: url,
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
      },
    };

    if (data) {
      options.data = data;
    }

    return requestWithToken(options);
  },

  // use to post a FormData object
  postForm: function (url, postData) {
    // set up xhr options
    const options = {
      method: 'post',
      url: url,
      data: postData,
      processData: false,
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
      },
    };

    return requestWithToken(options);
  },

  upload: function (url, postData) {
    return Q.Promise(function (resolve, reject, notify) {
      // save so that we can abort it later
      window.xhrUploadRequest = new XMLHttpRequest();

      window.xhrUploadRequest.upload.addEventListener(
        'progress',
        function (e) {
          notify(e);
        },
        false
      );
      window.xhrUploadRequest.addEventListener(
        'load',
        function (e) {
          /**
                    AWS S3 returns a 204 status for successful uploads
                    Look for success_action_status on
                    http://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
                **/
          if (
            window.xhrUploadRequest.status === 200 ||
            window.xhrUploadRequest.status === 204
          ) {
            resolve();
          }

          reject('errored');
        },
        false
      );

      window.xhrUploadRequest.addEventListener(
        'error',
        function () {
          reject('errored');
        },
        false
      );

      window.xhrUploadRequest.addEventListener(
        'abort',
        function () {
          reject('aborted');
        },
        false
      );

      window.xhrUploadRequest.open('POST', url, true);
      window.xhrUploadRequest.send(postData);
    });
  },
};

export default Request;

import { PAGE_DATA_KEY } from '@contexts/LegacyLabContext';
import RedirectHelper from './RedirectHelper';

// this is a private function that abstracts the XHR request so that we can pass
// certain headers on all requests and retry requests that fail due to token errors
const requestWithToken = function (options) {
  options.headers['X-Requested-With'] = 'XMLHttpRequest';

  // all API functions expect a promise to be returned
  return Q.Promise(function (resolve, reject, notify) {
    // add notify callback to XHR option
    options.progress = notify;

    // If the user makes a new get request when we already have one, abort the old get request:
    if (options.method === 'get' && window.xhrGetRequest) {
      window.xhrGetRequest.abort();
    }
    // make an xhr request:
    const xhrRequest = reqwest(options).then(
      function onSuccess(response) {
        if (response.page_data) {
          console.error(
            `API request is setting page data: ${options.method} ${options.url}`
          );

          localStorage.setItem(
            PAGE_DATA_KEY,
            JSON.stringify(response.page_data)
          );
        }

        resolve(response);
      },

      function onError(requestObj) {
        // Ignore errors triggered by a request aborted by the user
        if (requestObj.aborted) {
          return;
        }

        let response;

        try {
          response = JSON.parse(requestObj.response || {});
        } catch (err) {
          console.error(`Failed to parse error: ${err}`);
        }

        // Handle authentication/authorization errors
        const authError =
          response &&
          response.error &&
          [
            'access_denied_no_token',
            'private_access_denied',
            'invalid_token',
            'access_denied',
          ].includes(response.error);

        if (authError) {
          RedirectHelper.redirectToUserManagementWithRedirectSet('/login');
          reject({ access: response.error });
          return;
        }

        reject(response);
      }
    );

    // Only attach the xhr request to the window if it is a "get" request:
    if (options.method === 'get') {
      window.xhrGetRequest = xhrRequest;
      // or if it's a "post" request
    } else if (options.method === 'post') {
      window.xhrPostRequest = xhrRequest;
    }
  });
};
