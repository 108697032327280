// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

// const NavigationLink = require('@legacy-components/react/NavigationLink.jsx');
import TimeDelta from './../../../../../V4/Common/TimeDelta';

// const Tooltip = require('@legacy-components/react/V5/Common/Tooltip.jsx');

const EntryHeaderView = (props) => {
  const cleanUpTitle = (title) => title.replace(/-/g, ' ');

  const ssoLink = (childProps) => {
    const substringsToCheck = ['/app//', '/app/'];

    for (const substring of substringsToCheck) {
      const index = childProps.url.indexOf(substring);
      if (index > -1)
        return childProps.url
          .substring(index + substring.length)
          .replace(substring, '');
    }

    return childProps.children;
  };

  const showTitle = () => {
    const childProps = props.title?.props;
    if (!childProps?.url) return props.title;
    const regex = /app\/(.*?)\?/;
    const match = regex.exec(childProps.url);
    if (!match) return cleanUpTitle(ssoLink(childProps));
    return cleanUpTitle(match[1]);
  };

  return (
    <div
      className={`v5__heading-5 workspace__entry-header-row ${
        props.headerClass ? props.headerClass : ''
      }`}
    >
      <div className="workspace__entry-header-icon">
        <div className={props.iconClass}>
          <span className="path1"></span>
          <span className="path2"></span>
        </div>
      </div>
      <div className="workspace__entry-header-text">
        <div className="workspace__entry-header-content">
          <Typography component="div" textTransform="capitalize">
            {showTitle()}
          </Typography>
          {/*props.highlighted && !props.canHighlight && (
                        <Tooltip inline={true}
                            className="workspace__entry-header-highlight"
                            tipClass="v5__tooltip--wide"
                            tooltip="Your coach highlighted this for your administrator. Great work!">

                            <NavigationLink url={`/lab/highlights/${props.pageUser.id}?entryId=${props.id}`}
                                className="v5__link workspace__action-icon workspace__action-icon--view-only tc-v5-star-solid filled" />

                        </Tooltip>
                    )*/}
        </div>
        <div className="workspace__entry-header-timestamp">
          <TimeDelta
            datetime={props.createdFormatted}
            renderDate={props.renderDate}
          />
        </div>
      </div>
    </div>
  );
};

EntryHeaderView.propTypes = {
  iconClass: PropTypes.string.isRequired,
  headerClass: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  highlighted: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  canHighlight: PropTypes.bool,
  createdFormatted: PropTypes.string.isRequired,
  pageUser: PropTypes.object.isRequired,
};

export default EntryHeaderView;
