// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

const AbridgeList = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  let itemsToShow = props.itemsShown;

  // We can't show more items than there are
  if (props.children.length < itemsToShow) {
    itemsToShow = props.children.length;
  }

  // If there are no children to show, return null
  if (!props.children || !itemsToShow) {
    return null;
  }

  // Set up the Show More link or expand the nodes if necessary
  let showMoreNode = null;
  let abridgeClass = ' abridge-text';
  let nodes = props.children;

  if (props.children.length > itemsToShow) {
    if (!expanded) {
      nodes = [];

      for (let i = 0; i < itemsToShow; i++) {
        nodes.push(props.children[i]);
      }

      showMoreNode = (
        <div className="abridge-text__show-more">
          <span
            className="tc__link v5__toggle-copy"
            onClick={() => setExpanded(true)}
          >
            Show All
          </span>
        </div>
      );
    } else {
      abridgeClass = '';
      showMoreNode = (
        <div className="abridge-text__show-more">
          <span
            className="tc__link v5__toggle-copy"
            onClick={() => setExpanded(false)}
          >
            Show Less
          </span>
        </div>
      );
    }
  }

  return (
    <div className={props.containerClassName}>
      <div className={props.className + abridgeClass}>{nodes}</div>
      {showMoreNode}
    </div>
  );
};

AbridgeList.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.array.isRequired,
  itemsShown: PropTypes.number,
};

AbridgeList.defaultProps = {
  className: '',
  containerClassName: '',
  itemsShown: 1,
};

export default AbridgeList;
