// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import PropTypes from 'prop-types';
import ArrayHelpers from './../../../components/ArrayHelpers';
import Utils from './../../../components/DynamicUtils';
import AbsoluteAlert from './../../Alerts/Absolute';
import Modal from './Modal';
import Row from './Row';

const CustomSchools = (props) => {
  const getNoSchoolsNode = () => {
    if (ArrayHelpers.isEmpty(props.customSchools)) {
      return (
        <div className="row">
          There are no schools to curate at this time :-)
        </div>
      );
    }
    return null;
  };

  const getHeadersNode = () => {
    if (ArrayHelpers.isEmpty(props.customSchools)) {
      return null;
    }
    return (
      <tr>
        <th className="admin-v2__user-table-header">School</th>
        <th className="admin-v2__user-table-header">Zip Code</th>
        <th className="admin-v2__user-table-header"># Users</th>
        <th className="admin-v2__user-table-header">Actions</th>
      </tr>
    );
  };

  const getModalNode = () => {
    if (props.ui.schoolModal.opened) {
      return (
        <Modal
          {...props.ui.schoolModal}
          approve={props.approve}
          edit={props.edit}
          combine={props.combine}
          mode={props.ui.schoolModal.data.mode}
          getSingleSelectElement={props.getSingleSelectElement}
          selectSingleSelectElement={props.selectSingleSelectElement}
        />
      );
    }
    return null;
  };

  let alertNode;
  if (!Utils.isEmpty(props.ui.alert)) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  const customSchoolRows = props.customSchools.map((school, i) => {
    return (
      <Row
        key={i}
        school={school}
        approve={props.approve}
        edit={props.edit}
        combine={props.combine}
        schoolModal={props.ui.schoolModal}
      />
    );
  });

  return (
    <div>
      {getModalNode()}
      <Header page="CustomSchools" {...props} />
      {alertNode}
      <div>
        <div className="row">
          <h1 className="tc__headline-1 ppd-3-coach__headline cute-5-desktop left">
            School Curation
          </h1>
        </div>
        {getNoSchoolsNode()}
        <div className="row">
          <table className="user-search-table">
            <tbody>
              {getHeadersNode()}
              {customSchoolRows}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

CustomSchools.propTypes = {
  ui: PropTypes.object.isRequired,
  customSchools: PropTypes.array.isRequired,
  approve: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  combine: PropTypes.func.isRequired,
  getSingleSelectElement: PropTypes.func.isRequired,
  selectSingleSelectElement: PropTypes.func.isRequired,
};

export default CustomSchools;
