// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const ArtifactProgress = (props) => {
  const handleEditCancel = (e) => {
    e.preventDefault();

    // the uploadRequest is saved in Request.js
    if (window.xhrUploadRequest) {
      window.xhrUploadRequest.abort();

      // Record the analytics event:
      // Analytics.logEvent({
      //     category: 'add_file_modal',
      //     action: 'cancel_upload',
      //     label: 'from_vision_page'
      // });
    }
  };

  const fileName = props.file.name ? props.file.name : props.file.filename;

  let extension = 'ext-link';
  if (!props.file.url) {
    // get extension for icon
    if (props.file.extension) {
      extension = 'ext-' + props.file.extension;
    } else if (fileName) {
      extension = 'ext-' + fileName.substr(fileName.lastIndexOf('.') + 1);
    }
  }

  let progress = props.file.progress;
  if (progress > 100) {
    progress = 100;
  }

  return (
    <div className={props.containerCssClass}>
      <div className="vision__upload-progress-title">
        <span
          className={'artifact__type-icon file-type-icon ' + extension}
        ></span>
        <span className="artifact__upload-title">{fileName}</span>
      </div>
      <div className="vision__upload-progress-bar artifact__upload-bar">
        <div
          className="artifact__upload-progress"
          style={{ width: progress + '%' }}
        ></div>
      </div>
      <div className="tc__center-text tml-v2__upload-cancel">
        <a href="#cancel" className="tc__link" onClick={handleEditCancel}>
          Cancel
        </a>
      </div>
    </div>
  );
};

ArtifactProgress.propTypes = {
  file: PropTypes.object.isRequired,
  containerCssClass: PropTypes.string.isRequired,
};

export default ArtifactProgress;
