// @ts-nocheck FIXME
import ScaleCenterImage from '@legacy-components/react/ScaleCenterImage';
import PropTypes from 'prop-types';

const RegistrationExpired = (props) => {
  const handleGoHome = () => {
    location.href = '/lab/home';
  };

  return (
    <div className="tc__page tc__page--registration">
      <div className="tc-header__wrap tc-header__wrap--v2">
        <div className="tc-header tc-header--v2 row row--1200">
          <div
            className="logo tc-header__logo cute-3-desktop cute-3-phone left tc-header__registration-phone"
            onClick={handleGoHome}
            src="/legacy-images/betterlesson-logo.svg"
          ></div>
        </div>
      </div>

      <div className="coaching-reg__background-image"></div>
      <div className="event-reg__background-overlay"></div>

      <div className="row row--1200">
        <div className="cute-5-desktop cute-8-phone center-desktop center-phone registration__expired-box">
          <ScaleCenterImage
            className="registration__error-image"
            src="/legacy-images/BL_Lab_ExpiredLink_small.png"
            alt="This registration link has expired"
          />
          <div className="v5__heading-2">Oh shoot! You missed the boat.</div>
          <div className="v5__body-copy">
            Registration for this {props.registrationType} is now closed.
          </div>
        </div>
      </div>
    </div>
  );
};

RegistrationExpired.propTypes = {
  registrationType: PropTypes.string.isRequired,
};

export default RegistrationExpired;
