// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import ResourceApi from './../../../../apis/ResourceApi';
import Analytics from './../../../../components/Analytics';
import Utils from './../../../../components/DynamicUtils';
import WindowHelpers from './../../../../components/WindowHelpers';
import FreezeWindowScroll from './../FreezeWindowScroll';
import Comments from './modules/Comments';
import Details from './modules/Details';
import FullScreen from './modules/FullScreen';
import Preview from './modules/Preview';
import ResponsiveSetup from './modules/ResponsiveSetup';

class ArtifactOverlay extends React.Component {
  state = {
    hasViablePreview: false,
    mobileDetailsVisible: false,
    activeArtifactPage: 1,
    artifactDownloading: false,
    downloadError: null,
    fullScreenAvailable: false,
  };

  componentDidMount() {
    // fullscreen check
    if (WindowHelpers.checkFullScreenEnabled) {
      this.setState({
        fullScreenAvailable: true,
      });
    }

    // resource image check
    this.checkViablePreview();
  }

  checkViablePreview = () => {
    if (!this.props.data.connections.content.preview.length) {
      return;
    }

    const image = new Image();
    image.src = this.props.data.connections.content.preview[0];

    image.onload = function () {
      this.setState({
        hasViablePreview: true,
      });
    }.bind(this);
  };

  handleDownloadClick = (e) => {
    e.preventDefault();
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'artifact',
        action: 'download',
        label: this.props.analyticsLabel,
      });
    }

    const data = {};

    if (this.props.data.connections.content.preview_lessonfile_id) {
      data['id'] = this.props.data.connections.content.preview_lessonfile_id;
    } else if (this.props.data.connections.download_lessonfile_id) {
      data['id'] = this.props.data.connections.download_lessonfile_id;
    } else {
      data['id'] = null;
    }

    // Handle cases where the lessonfile id is not known
    if (!data['id']) {
      this.setState({
        downloadError:
          'Sorry, we could not download the artifact. Please try again later.',
        artifactDownloading: false,
      });
      return false;
    }

    this.setState({
      artifactDownloading: true,
    });

    ResourceApi.incrementDownloadCount(data)
      .then((response) => {
        location.href = this.props.data.connections.download_url;
        this.setState({
          artifactDownloading: false,
        });
      })
      .catch(
        function (response) {
          this.setState({
            downloadError:
              'Sorry, we could not find the artifact. Please try again later.',
            artifactDownloading: false,
          });
        }.bind(this)
      );
  };

  handleNextPageClick = (e) => {
    e.preventDefault();
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'artifact',
        action: 'paginate_next',
        label: this.props.analyticsLabel,
      });
    }
    this.setState({
      activeArtifactPage: this.state.activeArtifactPage + 1,
    });
  };

  handlePreviousPageClick = (e) => {
    e.preventDefault();
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'artifact',
        action: 'paginate_previous',
        label: this.props.analyticsLabel,
      });
    }
    this.setState({
      activeArtifactPage: this.state.activeArtifactPage - 1,
    });
  };

  showDetails = () => {
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'artifact',
        action: 'show_mobile_details',
        label: this.props.analyticsLabel,
      });
    }
    this.setState({
      mobileDetailsVisible: true,
    });
  };

  handleBackClick = () => {
    // close modal details
    if (this.state.mobileDetailsVisible) {
      if (this.props.analyticsLabel) {
        Analytics.logEvent({
          category: 'artifact',
          action: 'hide_mobile_details',
          label: this.props.analyticsLabel,
        });
      }
      this.setState({
        mobileDetailsVisible: false,
      });
      return;
    }

    // details not open so close overlay
    this.props.close();
  };

  handleFullScreenClick = () => {
    // Record the analytics event:
    if (this.props.analyticsLabel) {
      Analytics.logEvent({
        category: 'artifact',
        action: 'view_full_screen',
        label: this.props.analyticsLabel,
      });
    }
    // full screen the resource
    const resourceContainer = document.getElementById(
      'artifact-overlay__fullscreen-container'
    );
    Utils.runPrefixMethod(resourceContainer, 'RequestFullScreen');
  };

  render() {
    let title = this.props.data.title;
    if (!this.props.data.title) {
      title = this.props.data.url
        ? this.props.data.url
        : this.props.data.connections.name;
    }
    return (
      <div>
        <div
          className="modal__close--artifact"
          onClick={(e) => this.props.close()}
        >
          <div className="tc-x"></div>
        </div>
        <div
          className="modal__page-overlay modal__page-overlay--artifact-overlay"
          onClick={(e) => this.props.close()}
        ></div>
        <div className="modal--absolute modal--artifact-overlay">
          <div className="row">
            <div className="artifact-overlay__mobile-header cute-12-phone show-phone hide-desktop">
              <div className="row">
                <div
                  className="artifact-overlay__back cute-2-phone"
                  onClick={(e) => this.handleBackClick()}
                >
                  <div className="artifact-overlay__back-arrow tc-arrow"></div>
                  <div className="artifact-overlay__back-caption">Back</div>
                </div>
                <div className="artifact-overlay__mobile-header-title tc__headline-4 cute-8-phone left">
                  {title}
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              'mt-artifact-overlay' +
              (this.state.mobileDetailsVisible ? ' details-visible' : '')
            }
          >
            <Preview
              content={this.props.data.connections.content}
              preview_type={this.props.data.connections.preview_type}
              icon={this.props.data.connections.icon}
              start_time={this.props.data.start_time}
              end_time={this.props.data.end_time}
              hasViablePreview={this.state.hasViablePreview}
              activeArtifactPage={this.state.activeArtifactPage}
              handlePreviousPageClick={this.handlePreviousPageClick}
              handleNextPageClick={this.handleNextPageClick}
            />

            <FullScreen
              content={this.props.data.connections.content}
              hasViablePreview={this.state.hasViablePreview}
              preview_type={this.props.data.connections.preview_type}
              fullScreenAvailable={this.state.fullScreenAvailable}
              activeArtifactPage={this.state.activeArtifactPage}
              handlePreviousPageClick={this.handlePreviousPageClick}
              handleNextPageClick={this.handleNextPageClick}
            />

            <Details
              {...this.props.data}
              hideAuthorPhoto={this.props.data.hideAuthorPhoto}
              title={title}
              hasViablePreview={this.state.hasViablePreview}
              fullScreenAvailable={this.state.fullScreenAvailable}
              handleFullScreenClick={this.handleFullScreenClick}
              handleDownloadClick={this.handleDownloadClick}
              showDetails={this.showDetails}
              artifactDownloading={this.state.artifactDownloading}
              downloadError={this.state.downloadError}
            />

            {this.props.data.comments && this.props.data.comments.length ? (
              <Comments comments={this.props.data.comments} />
            ) : null}
          </div>
        </div>
        <ResponsiveSetup />
        <FreezeWindowScroll
          parentModal="modal--artifact-overlay"
          hasViablePreview={this.state.hasViablePreview}
        />
      </div>
    );
  }
}

ArtifactOverlay.propTypes = {
  analyticsLabel: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default ArtifactOverlay;
