// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import AbsoluteAlert from './../../Alerts/Absolute';
import AvailabilityStep from './AvailabilityStep';
import FinalStep from './FinalStep';
import GradeStep from './GradeStep';
import Header from './Header';
import NameStep from './NameStep';
import SchoolStep from './SchoolStep';
import SubjectStep from './SubjectStep';

const ParticipantRegistration = (props) => {
  // Build the alert node if needed:
  let alertNode;
  if (Object.keys(props.ui.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  // if the registration was incomplete and we redirected the user here,
  // then show an extra headline
  let incompleteHeadlineNode = null;
  if (props.ui.showIncompleteHeadline) {
    incompleteHeadlineNode = (
      <div className="row row--1200">
        <div className="cute-6-desktop cute-12-phone center-desktop tc__center-text registration__headline tc__headline-2">
          Welcome back!
        </div>
      </div>
    );
  }

  // determine which step to show
  const steps = [
    NameStep,
    SchoolStep,
    GradeStep,
    SubjectStep,
    AvailabilityStep,
    FinalStep,
  ];
  const Step = steps[props.step - 1];

  return (
    <div className="tc__page">
      <Header step={props.step} stepTotal={props.stepTotal} />
      {alertNode}

      {incompleteHeadlineNode}
      <Step {...props} />
    </div>
  );
};

ParticipantRegistration.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  availabilityOptions: PropTypes.array.isRequired,
  allSubjectsId: PropTypes.number.isRequired,
};

export default ParticipantRegistration;
