// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import PropTypes from 'prop-types';

const StrategyRow = (props) => {
  const teachersToHide = props.participant_list.slice(
    1,
    props.participant_list.length
  );
  const teacherToShow = props.participant_list[0];

  let activeTeachers = null;
  let educatorsNode = <div className="cute-3-desktop"></div>;
  let arrowNode = null;

  if (teachersToHide.length !== 0) {
    activeTeachers = teachersToHide.map((teacher) => {
      return (
        <div
          className="partner_lead_v5__author-entry hidden_strategy"
          key={`teacher-${teacher.id}`}
        >
          <div className="workspace__comment-photo-col">
            <UserPhoto
              src={teacher.photo}
              userId={teacher.id}
              firstName={teacher.first_name}
              lastName={teacher.last_name}
              diameter={40}
            />
          </div>
          <div className="partner_lead_v5__teacher-info domain">
            <NavigationLink
              url={`/lab/highlights/${teacher.id}?scrollToResources=true`}
              className="tc__link partner_lead_v5__teacher-name"
            >
              {teacher.first_name} {teacher.last_name}
            </NavigationLink>
            <span className="partner_lead__school-name">
              , {teacher.school_name}
            </span>
          </div>
        </div>
      );
    });

    const arrowClass = props.openRow
      ? 'workspace__goal-expand-arrow--expanded'
      : '';

    arrowNode = (
      <div
        className={`tc-v4-circle-arrow workspace__goal-expand-arrow partner_lead__expand-arrow partner_lead__strategy-arrow ${arrowClass}`}
        onClick={() => props.updateShow(props.id)}
      ></div>
    );

    if (!props.openRow) {
      educatorsNode = (
        <div className="v5__registered-table-educator-container cute-3-desktop">
          <div className="partner_lead__learning-domain-num-teachers partner_lead__num-educators">
            +{teachersToHide.length} Educator
            {teachersToHide.length > 1 ? 's' : ''}
          </div>
          {arrowNode}
        </div>
      );
    } else {
      educatorsNode = (
        <div className="v5__registered-table-educator-container cute-3-desktop">
          {arrowNode}
        </div>
      );
    }
  }

  // show goal details if expanded
  const bodyNode = props.openRow ? (
    <div className="partner_lead__strategy-hidden-teachers">
      {activeTeachers}
    </div>
  ) : null;

  const teacherInfoNode = (
    <div className="partner_lead_v5__author-entry strategy">
      <div className="workspace__comment-photo-col">
        <UserPhoto
          src={teacherToShow.photo}
          userId={teacherToShow.id}
          firstName={teacherToShow.first_name}
          lastName={teacherToShow.last_name}
          diameter={40}
        />
      </div>
      <div className="partner_lead_v5__teacher-info domain">
        <NavigationLink
          url={`/lab/highlights/${teacherToShow.id}?scrollToResources=true`}
          className="tc__link partner_lead_v5__teacher-name"
        >
          {teacherToShow.first_name} {teacherToShow.last_name}
        </NavigationLink>
        <span className="partner_lead__school-name">
          , {teacherToShow.school_name}
        </span>
      </div>
    </div>
  );

  return (
    <div className="partner_lead__learning_domain_row_container strategy">
      <div className="partner_lead__learning_domain_row">
        <div className="partner_lead__learning-domain-row-header">
          <NavigationLink
            url={`${process.env.REACT_APP_TEACHING_URL}/strategy/${props.id}/${props.seo_url}`}
            className="v5__link partner_lead__strategy-title cute-4-desktop"
          >
            {props.title}
          </NavigationLink>
          <div className="partner_lead__strategy-row-teacher-container cute-5-desktop">
            {teacherInfoNode}
          </div>
          {educatorsNode}
        </div>
      </div>
      {bodyNode}
    </div>
  );
};

StrategyRow.propTypes = {
  participant_list: PropTypes.array,
  title: PropTypes.string,
};

export default StrategyRow;
