// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const CohortRow = (props) => {
  let cohortName = props.display_name;
  if (props.currentYear === 'current') {
    cohortName = (
      <NavigationLink
        url={`/lab/cohort/${props.id}/overview`}
        className="tc__link"
      >
        {cohortName}
      </NavigationLink>
    );
  }
  return (
    <div className="partner_lead_v5__mobile-row">
      <div>{cohortName}</div>
      <div className="partner_lead_v5__mobile-label">Coaching Product</div>
      <div>{props.product.name}</div>
      <div className="partner_lead_v5__mobile-label">Dates</div>
      <div>
        {moment(props.term_start).format('MMMM D, Y')} &mdash;{' '}
        {moment(props.term_end).format('MMMM D, Y')}
      </div>
      <div className="partner_lead_v5__mobile-label">Cohort Size</div>
      <div>{props.capacity}</div>
    </div>
  );
};

CohortRow.propTypes = {
  product: PropTypes.object.isRequired,
  display_name: PropTypes.string.isRequired,
  term_start: PropTypes.string.isRequired,
  term_end: PropTypes.string.isRequired,
  capacity: PropTypes.number.isRequired,
};

export default CohortRow;
