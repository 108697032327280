// @ts-nocheck FIXME
const Badges = (props) => {
  // build all the badge category sections
  const badgesNode = props.badges.map((category) => {
    // only show categories that have badges
    if (category.badges.length) {
      return (
        <div key={category.code} className="portfolio__badges-category">
          <div className="portfolio__badges-category-icon">
            <img
              src={
                '/legacy-images/badges/parent-badge-' + category.code + '.svg'
              }
            />
          </div>
          {category.badges.map((badge) => {
            const pointsClass =
              'portfolio__badges-points-text--' + category.code;
            const points = badge.points == 1 ? 'POINT' : 'POINTS';

            return (
              <div
                key={'badge-' + badge.code}
                className="portfolio__badges-badge"
              >
                <div className="portfolio__badges-badge-icon">
                  <img
                    src={
                      '/legacy-images/badges/badge-' +
                      category.code +
                      '-active.svg'
                    }
                  />
                </div>
                <div className="portfolio__badges-text">{badge.name}</div>
                <div className={'portfolio__badges-points-text ' + pointsClass}>
                  {badge.points} {points}
                </div>
              </div>
            );
          })}
          <div className="clearfix"></div>
        </div>
      );
    }
  });

  return (
    <div>
      <div className="portfolio__badges">
        <div className="portfolio__section-headline portfolio__section-headline--badges">
          Badges Earned
        </div>
        {badgesNode}
      </div>
      <hr className="page-break" />
    </div>
  );
};

export default Badges;
