// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import { Router } from './../../components/Router';
import AbsoluteAlert from './../Alerts/AbsoluteStateless';
import PartnerUserAdd from './PartnerUserAdd';

const PartnerEdit = (props) => {
  let partnerName;
  let partnerShortCode;

  // event listeners
  const handleShowPartners = (e) => {
    e.preventDefault();
    Router.route('/bl/v1/partners');
  };

  const handleEditPartner = (e) => {
    e.preventDefault();

    // don't do anything if the form is submitting
    if (props.ui.editPartner.isLoading) {
      return;
    }

    const data = {
      accountId: props.partner.id,
      name: partnerName.value,
      code: partnerShortCode.value,
    };

    props.editPartner(data);
  };

  const handlePartnerUserAddModal = (e) => {
    e.preventDefault();
    props.toggleModal({ feature: 'addPartnerUser' });
  };

  /* BEGIN RENDERING LOGIC */

  // failed validation results
  const inputErrorClass =
    props.ui.editPartner.error || props.ui.editPartner.notifications.length
      ? ' error'
      : '';

  const alertNode = (
    <AbsoluteAlert
      {...props.ui.alert}
      cssClasses={{ container: 'alert-banner--fixed' }}
    />
  );

  const defaultName =
    props.partner && props.partner.name ? props.partner.name : '';
  const dissableButtonClass = props.ui.editPartner.isLoading ? ' disabled' : '';
  const partnerId = props.partner && props.partner.id ? props.partner.id : null;

  return (
    <div>
      <Header page="PartnerEdit" {...props} />

      {alertNode}

      {props.ui.addPartnerUser.modalDisplayed && (
        <PartnerUserAdd
          partnerId={partnerId}
          toggleModal={props.toggleModal}
          addPartnerUser={props.addPartnerUser}
          isLoading={props.ui.addPartnerUser.isLoading}
          error={props.ui.addPartnerUser.error}
          notifications={props.ui.addPartnerUser.notifications}
          notificationLink={props.ui.addPartnerUser.notificationLink}
          clearErrorsOnFocus={props.clearErrorsOnFocus}
        />
      )}

      <div className="row">
        <div className="cute-12-desktop">
          <div className="admin-v2__user-page-breadcrumbs">
            <a
              onClick={handleShowPartners}
              className="tc__link"
              href={'/bl/v1/partners'}
            >
              Partners
            </a>
            <span> | Edit Partner</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="cute-6-desktop">
          <h3 className="tc__modal-add-headline tc__headline-2">
            {props.partner.name}
          </h3>
        </div>
        <div className="cute-6-desktop">
          <a
            className="bl__btn bl__btn--small bl__btn--blue bl__btn--add-partner"
            onClick={handlePartnerUserAddModal}
          >
            Add User to Partner
          </a>
        </div>
      </div>

      <div className="admin-v2__user-tab-body">
        <div className="row">
          <div className="cute-12-desktop">
            <div className="admin-v2__user-page-form-item clearfix">
              <label className="admin-v2__user-page-label">Name</label>
              <input
                type="text"
                placeholder="Name"
                ref={(ref) => (partnerName = ref)}
                className={
                  'admin-v2__user-page-input tc__input' + inputErrorClass
                }
                defaultValue={defaultName}
              />
            </div>
            <div className="admin-v2__user-page-form-item clearfix">
              <label className="admin-v2__user-page-label">Code</label>
              <input
                type="text"
                placeholder="Code"
                ref={(ref) => (partnerShortCode = ref)}
                className={
                  'admin-v2__user-page-input tc__input' + inputErrorClass
                }
                defaultValue={props.partner.shortcode}
              />
            </div>
            <div className="admin-v2__user-page-form-item clearfix">
              <input
                className={
                  'tc__btn tc__btn--blue tc__btn--small tc__btn--add-submit' +
                  dissableButtonClass
                }
                type="submit"
                value="submit"
                onClick={handleEditPartner}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerEdit;
