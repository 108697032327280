// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import { useState } from 'react';
import NavigationLink from './../../../../NavigationLink';

const DomainTag = (props) => {
  const lab = useLegacyLabContext();
  // switch between showing details on mouse hover, or mouse click
  const useHover = props.hover ? props.hover : false;
  const [displayCard, toggleDisplayCard] = useState(false);

  const closeGoalCard = () => {
    if (!useHover) {
      document.removeEventListener('click', closeGoalCard);
    }
    toggleDisplayCard(false);
  };

  const openGoalCard = () => {
    toggleDisplayCard(true);
    if (!useHover) {
      delayedEventListener(() =>
        document.addEventListener('click', closeGoalCard)
      );
    }
  };

  // This exists to prevent bloating the tag div
  let goalTagClickMethod = openGoalCard;

  // Display the domain info in a tooltip if the tag is clicked
  let goalCard;
  if (displayCard) {
    goalTagClickMethod = null;

    goalCard = (
      <div
        className="v5__tooltip-card workspace__tooltip-card"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        <div>
          <NavigationLink
            url={`${process.env.REACT_APP_TEACHING_URL}/browse/learning-domain/${props.id}`}
            className="v5__link workspace__tooltip-card-heading"
            target="_blank"
          >
            {props.label}
            <span className="tc-outlink workspace__tooltip-card-outlink-icon" />
          </NavigationLink>
        </div>
        <div className="v5__body-copy workspace__tooltip-card-row">
          {props.tagline}
        </div>

        {props.hasResourcePerms &&
        Utils.hasFeatureFlag.call(lab, 'COACHING_RESOURCES') ? (
          props.designDocLink ? (
            <p className="design-link">
              <NavigationLink
                url={props.designDocLink}
                className={'v5__link workspace__tooltip-card-heading'}
                target="_blank"
              >
                <>
                  View Design Doc
                  <span className="tc-outlink workspace__tooltip-card-outlink-icon" />
                </>
              </NavigationLink>
            </p>
          ) : (
            <p className="design-link--disabled workspace__tooltip-card-heading">
              Design link coming soon
            </p>
          )
        ) : null}
      </div>
    );
  }
  // allow props to set tagStyles to empty by providing empty string
  const tagStyles =
    props.tagStyles === undefined || props.tagStyles === null
      ? 'workspace__tag'
      : props.tagStyles;

  // have either onClick or OnHover behaviors, but not both
  const mouseObject = {
    onMouseEnter: () => toggleDisplayCard(true),
    onMouseLeave: () => toggleDisplayCard(false),
  };
  const clickObject = { onClick: goalTagClickMethod };
  const triggerMethod = () =>
    useHover ? { ...mouseObject } : { ...clickObject };

  return (
    <div
      className={props.containerStyles ? props.containerStyles : ''}
      {...triggerMethod()}
    >
      <div
        className={`v5__domain-tag v5__domain-tag--${props.id} ${tagStyles} workspace__tag--clickable`}
      >
        {props.label}
      </div>

      {goalCard}
    </div>
  );
};

DomainTag.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  containerStyles: PropTypes.string,
  tagStyles: PropTypes.string,
  hover: PropTypes.bool,
  designDocLink: PropTypes.string,
};

export default DomainTag;
