// @ts-nocheck FIXME
import Store from './../../components/Store';

const store = {
  meeting: {},
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.meeting = data.meeting;
  // don't trigger event
  return false;
};

export default Store('MeetingStore', store);
