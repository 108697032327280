// @ts-nocheck FIXME
const Sorter = (props) => {
  let selectElem = null;
  const handleSort = (e) => {
    props.orderFavorites({
      sortOrder: selectElem.value,
    });
  };
  return (
    <div className="bl4__results-sort">
      <label>Sort: </label>
      <select
        type="select"
        className="tc__select"
        ref={(ref) => {
          selectElem = ref;
        }}
        onChange={handleSort}
      >
        <option value="desc">Newest</option>
        <option value="asc">Oldest</option>
      </select>
    </div>
  );
};

export default Sorter;
