// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import StorageHelper from '@legacy-components/components/StorageHelper';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import AbsoluteAlert from '@legacy-components/react/Alerts/Absolute';
import V4Header from '@legacy-components/react/V4/Header/Header';
import { useEffect, useState } from 'react';
import FreeContentLimit from './modules/FreeContentLimit';
import CoachParticipant from './modules/Navigation/CoachParticipant';
import HamburgerMenu from './modules/Navigation/HamburgerMenu';
import Links from './modules/Navigation/Links';
import Logo from './modules/Navigation/Logo';
import User from './modules/Navigation/User';
import Search from './modules/SearchContainer';

const HeaderContainer = (props) => {
  const lab = useLegacyLabContext();
  // if a logged out user or free user load v4 header
  if (!props.user || !props.user.id || Utils.isFreeUser.call(lab)) {
    return <V4Header {...props} />;
  }

  const [mobileDropdownOpened, setMobileDropdownOpen] = useState(false);
  const [mobileDropdownOverflows, setMobileDropdownOverflows] = useState(false);
  const [mobileSearchOpened, setMobileSearchOpened] = useState(false);

  useEffect(() => {
    const mq = window.matchMedia('(max-width: 72em)');
    // if mobile
    if (mq.matches) {
      // account for any initial overflow
      overflowBehavior();
      // handle resize, such as rotations, and overflow that might happen
      window.addEventListener('resize', overflowBehavior);
    }
    return () => {
      window.removeEventListener('resize', overflowBehavior);
    };
  }, [mobileDropdownOpened]);

  useEffect(() => {
    // GateKeeper
    if (props.user && !props.user.is_public) {
      // if they logged in correctly get rid of lessons/strategies clicked tracking
      StorageHelper.removeCookieItem('bl_lessons_viewed');
      StorageHelper.removeCookieItem('bl_strategies_viewed');
    }
  }, [props.user]);

  useEffect(() => {
    return () => props.setAlert({});
  }, []);

  const overflowBehavior = () => {
    // get the height of elements to decide if overflow is necessary
    const windowHeight = window.innerHeight;
    const overlayHeight = document.getElementById(
      'header__dropdown--mobile'
    ).offsetHeight;
    const headerHeight =
      document.getElementById('tc-mobile-header').offsetHeight;

    // if the overflow is necessary update the state as such
    const needsOverflow =
      overlayHeight > windowHeight - headerHeight ? true : false;
    setMobileDropdownOverflows(needsOverflow);

    return;
  };

  /**** UI ****/

  const searchNode = (
    <Search
      salt={props.salt}
      responsive={props.responsive}
      mobileDropdownOpened={mobileDropdownOpened}
      setMobileDropdownOpen={setMobileDropdownOpen}
      headerParticipant={props.headerParticipant}
      mobileSearchOpened={mobileSearchOpened}
      setMobileSearchOpened={setMobileSearchOpened}
    />
  );

  const hamburgerOrCancelNode = mobileDropdownOpened ? (
    <div
      onClick={() => {
        props.unfreezeWindowAsNecessary();
        setMobileDropdownOpen(false);
      }}
      className="tc-x header__mobile-menu-button"
    ></div>
  ) : (
    <div
      onClick={() => {
        props.freezeWindowAsNecessary();
        setMobileSearchOpened(false);
        setMobileDropdownOpen(true);
      }}
      className="tc-hamburger header__mobile-menu-button"
    ></div>
  );

  let rowClass = 'row--1200';
  let headerClass = 'show-desktop hide-phone'; // This style used important so overriding below
  let hamburgerClass = 'show-phone hide-desktop'; // This style used important so overriding below

  if (props.responsive) {
    rowClass = 'row--responsive';
    headerClass = 'header-show-desktop-flex header-hide-phone';
    hamburgerClass = 'header-show-phone header-hide-desktop';
    props.setResponsive(true);
  } else {
    props.setResponsive(false);
  }

  let coachParticipantNode;
  let coachParticipantVisibleClass = '';
  const showSettings = props.showSettings ? props.showSettings : false;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(props.user.auth_roles, [
      'BL_SERVICES_MANAGER',
      'BL_CONTRACT_COACH',
      'BL_MANAGER',
      'ADMIN',
    ]) &&
    props.headerParticipant &&
    !Utils.isEmpty(props.headerParticipant)
  ) {
    coachParticipantVisibleClass = 'header--widget-visible';
    coachParticipantNode = (
      <div className="tc-header__admin-nav tc-header__admin-nav--v2 tc-header__coach-nav-v5">
        <div className={`row ${rowClass}`}>
          <CoachParticipant
            participant={props.headerParticipant}
            showSettings={showSettings}
          />
        </div>
      </div>
    );
  }

  // Build the alert node if needed
  let alertNode;
  if (Object.keys(props.alert).length > 0) {
    alertNode = (
      <AbsoluteAlert
        {...props.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
        handleClose={() => props.setAlert({})}
      />
    );
  }

  return (
    <header className={`header--v5 ${coachParticipantVisibleClass}`}>
      <div className={'tc-header__wrap tc-header__wrap--v2'}>
        <div
          className={`tc-header tc-header--v2 row header--v5 ${rowClass} ${headerClass}`}
        >
          <div className="header__left">
            <Logo user={props.user} />
            <Links user={props.user} />
          </div>
          <div className="header__right">
            {searchNode}
            <User user={props.user} />
          </div>
        </div>
        {coachParticipantNode}

        <div
          id="tc-mobile-header"
          className="tc-header header--v5 tc-header--mobile row"
        >
          <Logo user={props.user} />
          <div className="header__mobile-options">
            <div
              className="header__mobile-search-button"
              onClick={() => {
                setMobileSearchOpened(true);
                setMobileDropdownOpen(false);
              }}
            ></div>
            {hamburgerOrCancelNode}
          </div>
        </div>
        <HamburgerMenu
          user={props.user}
          mobileDropdownOverflows={mobileDropdownOverflows}
          mobileDropdownOpened={mobileDropdownOpened}
          responsive={props.responsive}
          setMobileDropdownOpen={setMobileDropdownOpen}
          headerParticipant={props.headerParticipant}
        />
        <div className={`${hamburgerClass}`}>{searchNode}</div>
      </div>
      {alertNode}
      <FreeContentLimit {...props} />
    </header>
  );
};

const ConnectedHeader = (props) => (
  <HeaderConsumer>
    {({
      setResponsive,
      headerParticipant,
      freezeWindowAsNecessary,
      unfreezeWindowAsNecessary,
      setAlert,
      alert,
    }) => (
      <HeaderContainer
        {...props}
        user={props.user}
        headerParticipant={headerParticipant}
        setResponsive={setResponsive}
        freezeWindowAsNecessary={freezeWindowAsNecessary}
        unfreezeWindowAsNecessary={unfreezeWindowAsNecessary}
        setAlert={setAlert}
        alert={alert}
      />
    )}
  </HeaderConsumer>
);

export default ConnectedHeader;
