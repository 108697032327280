// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import PropTypes from 'prop-types';
import Utils from './../../../components/DynamicUtils';
import ScaleCenterImage from './../../ScaleCenterImage';
import SelectedParticipantWidget from './../ParticipantWidget/SelectedParticipantWidget';
import TeacherViewModal from './../ParticipantWidget/TeacherView';
import DropdownLink from './DropdownLink';
import HeaderDropdownContainer from './HeaderDropdownContainer';

const HeaderDropdown = (props) => {
  const lab = useLegacyLabContext();

  const show = (e) => {
    e.stopPropagation();
    props.openDropdown();
  };

  const hide = (e) => {
    e.stopPropagation();

    // hide the list
    props.closeDropdown();
  };

  // convenience naming
  const user = props.user;
  const userRoles = user.auth_roles;

  // open or close the dropdown according to the state
  const visibleClass = props.ui.dropdownOpened ? ' show' : '';

  // set the class to make the mobile menu scrollable
  const overflowClass = props.dropDownOverflows ? ' overflow' : '';

  // set up teacher menu items
  let growthMapNode, dashboardNode;

  // set up participant menu items
  let portfolioNode;
  if (Utils.isParticipant.call(lab, user)) {
    dashboardNode = (
      <DropdownLink
        url={
          '/coaching/lab/participant/' + user.participants[0].id + '/dashboard'
        }
        activePage={props.page === 'ParticipantDashboard'}
      >
        Home
      </DropdownLink>
    );
    portfolioNode = (
      <DropdownLink
        url={
          '/coaching/lab/participant/' +
          user.participants[0].id +
          '/portfolio?from=tc_menu'
        }
        activePage={'ParticipantPortfolioManagement'}
      >
        My Portfolio
      </DropdownLink>
    );
    growthMapNode = (
      <DropdownLink
        url={'/coaching/lab/participant/' + user.participants[0].id + '/vision'}
        activePage={props.page === 'ParticipantVision'}
      >
        My Vision
      </DropdownLink>
    );
  }

  if (Utils.isPartnerLeader.call(lab, user) && user.active_lead === 'v5') {
    const url = `/lab/leader/${user.leads[0].id}/home`;
    dashboardNode = (
      <DropdownLink
        url={url}
        activePage={props.page === 'PartnerLeadDashboard'}
      >
        Dashboard
      </DropdownLink>
    );
  }

  // set up link to Strategy Browser
  let strategyBrowserNode;
  if (
    !Utils.isParticipant.call(lab, user) &&
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, [
      'ADMIN',
      'BL_MANAGER',
      'BL_SERVICES_MANAGER',
      'TC_TEACHER',
      'TC_DATA_EDITOR',
      'TC_DATA_VIEWER',
      'TC_CONTENT_CURATOR',
    ])
  ) {
    strategyBrowserNode = (
      <DropdownLink
        url={'/coaching/lab/strategy-browser'}
        activePage={props.page === 'StrategyBrowser'}
      >
        Strategy Browser
      </DropdownLink>
    );
  }

  // set up link to betterlesson.com/strategies
  let blEditStrategiesNode;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, [
      'ADMIN',
      'BL_MANAGER',
      'TC_CONTENT_CURATOR',
    ])
  ) {
    blEditStrategiesNode = (
      <li>
        <a href="/strategies?from=tc_menu" className="header__dropdown-link">
          Edit Strategies
        </a>
      </li>
    );
  }

  // set up link to Admin Dashboard
  let adminLinkNode;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, ['ADMIN', 'BL_MANAGER'])
  ) {
    adminLinkNode = (
      <DropdownLink
        url={'/bl/admin/home'}
        activePage={props.page === 'AdminDashboard'}
      >
        Manage Users
      </DropdownLink>
    );
  } else if (
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, [
      'TC_DATA_VIEWER',
      'TC_DATA_EDITOR',
      'BL_SERVICES_MANAGER',
    ])
  ) {
    adminLinkNode = (
      <DropdownLink
        url={'/bl/users'}
        activePage={props.page === 'AdminDashboard'}
      >
        Search Users
      </DropdownLink>
    );
  }

  // set up links to Help Center
  let helpCenterNode;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, [
      'ADMIN',
      'BL_MANAGER',
      'BL_SERVICES_MANAGER',
      'TC_TEACHER',
      'TC_DATA_VIEWER',
      'TC_DATA_EDITOR',
      'TC_CONTENT_CURATOR',
    ])
  ) {
    helpCenterNode = (
      <li>
        <a
          href="/coaching/lab/help-center?from=tc_menu"
          className="header__dropdown-link"
          target="_blank"
        >
          Help Center
        </a>
      </li>
    );
  }

  let teacherWidgetNode;
  if (
    Utils.arrayContainsAnyItemInAnotherArray(userRoles, [
      'TC_DATA_VIEWER',
      'TC_DATA_EDITOR',
      'BL_SERVICES_MANAGER',
      'BL_MANAGER',
      'ADMIN',
    ])
  ) {
    teacherWidgetNode = (
      <li className="show-phone hide-desktop">
        <TeacherViewModal
          ui={props.ui}
          submitTeacherViewModal={props.submitTeacherViewModal}
        />
        <SelectedParticipantWidget participant={props.headerParticipant} />
      </li>
    );
  }

  let partnerHighlightsNode = null;
  //if affiliation is bl team show partner highlights and not a teacher
  if (!Utils.arrayContainsAnyItemInAnotherArray(userRoles, ['TC_TEACHER'])) {
    if (user && user.details && user.details.partner_id === 1) {
      partnerHighlightsNode = (
        <li>
          <a href="/bl/partner-highlights" className="header__dropdown-link">
            Partner Highlights
          </a>
        </li>
      );
    }
  }

  // construct dropdown for all users
  const dropdown = (
    <ul className="header__dropdown-list">
      {teacherWidgetNode}
      {dashboardNode}
      {growthMapNode}
      {strategyBrowserNode}
      {portfolioNode}
      {blEditStrategiesNode}
      {adminLinkNode}
      {partnerHighlightsNode}
      <li>
        <a href="/home?from=tc_menu" className="header__dropdown-link">
          BetterLesson.com
        </a>
      </li>
      {helpCenterNode}
      <li>
        <a
          href="/legal/?from=tc_menu"
          className="header__dropdown-link"
          target="_blank"
        >
          Terms of Service
        </a>
      </li>
      <li>
        <a
          href={`${process.env.REACT_APP_MARKETING_URL}/privacy-policy/?from=tc_menu`}
          className="header__dropdown-link"
          target="_blank"
          rel="noreferrer"
        >
          Expectations &#38; Privacy
        </a>
      </li>
      <li>
        <a href="/logout?from=tc_menu" className="header__dropdown-link">
          Sign Out
        </a>
      </li>
    </ul>
  );

  // set up default user photo
  let userPhotoNode = (
    <div className="tc-header__user-image-mask tc-header__user-image-mask--none">
      <img
        className="tc-header__user-image"
        src="/legacy-images/default-user.svg"
      />
    </div>
  );

  // if the user photo exists display the user's image
  if (user.photo) {
    userPhotoNode = (
      <ScaleCenterImage src={user.photo} className="tc-header__user-image" />
    );
  }

  let hamburgerOrCancelNode = null;
  if (props.ui.dropdownOpened) {
    hamburgerOrCancelNode = (
      <div
        onClick={hide}
        className="tc-header__mobile-nav-icon show-phone hide-desktop tc-x"
      ></div>
    );
  } else {
    hamburgerOrCancelNode = (
      <div className="tc-header__mobile-nav-icon show-phone hide-desktop tc-hamburger"></div>
    );
  }
  // handle all the mobile / desktop transitions
  let headerDropdownContainerNode = null;
  if (props.ui.dropdownOpened) {
    headerDropdownContainerNode = (
      <HeaderDropdownContainer
        dropDownVisible={props.ui.dropdownOpened}
        updateDropdownOverflow={props.updateDropdownOverflow}
        hide={hide}
        layout={props.layout}
      >
        <div
          id={'header__dropdown--' + props.layout}
          className={
            'header__dropdown' +
            visibleClass +
            ' cute-12-desktop' +
            overflowClass
          }
        >
          {dropdown}
        </div>
      </HeaderDropdownContainer>
    );
  }

  // render the template
  return (
    <div
      className={'tc-header__user' + (props.ui.dropdownOpened ? ' show' : '')}
      onClick={show}
    >
      <div
        className={
          'header__dropdown-container' +
          (props.ui.dropdownOpened ? ' show-phone' : '') +
          ' hide-desktop'
        }
      ></div>
      <div className="tc-header__user-overlay"></div>

      <div className="show-desktop hide-phone">
        <div className="tc-header__user-name">{user.first_name}</div>
        {userPhotoNode}
        <div className="header__dropdown-arrow"></div>
      </div>
      {hamburgerOrCancelNode}
      {headerDropdownContainerNode}
    </div>
  );
};

HeaderDropdown.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dropDownOverflows: PropTypes.bool,
  page: PropTypes.string,
  teacherViewModal: PropTypes.bool,
  submitTeacherViewModal: PropTypes.func,
  headerParticipant: PropTypes.object,
  layout: PropTypes.string.isRequired,
  openDropdown: PropTypes.func.isRequired,
  closeDropdown: PropTypes.func.isRequired,
};

export default HeaderDropdown;
