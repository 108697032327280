// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import NavigationLink from './../../NavigationLink';

const CurationHome = (props) => {
  return (
    <div>
      <Header page="ContentCuration" {...props} />
      <div className="row">
        <div className="cute-12-desktop">
          <h2 className="admin-v2__headline tc__headline-1">
            Content Curation
          </h2>
        </div>
        <div className="cute-4-desktop cute-12-phone left">
          <div className="admin-v2__nav-link">
            <NavigationLink url={'/bl/curation/domains'} className="tc__link">
              Learning Domains
            </NavigationLink>
          </div>
          <div className="admin-v2__nav-link">
            <NavigationLink
              url={'/bl/curation/strategies'}
              className="tc__link"
            >
              Strategies & Tech Tools
            </NavigationLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurationHome;
