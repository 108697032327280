// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Utils from './../../../components/DynamicUtils';
import WindowHelpers from './../../../components/WindowHelpers';

const FreezeWindowScroll = (props) => {
  let mq;
  let scrollPosition;
  let parentModal;
  let parentPage;
  let pageFooter;

  // this one is only called on mount (and cleaned up on unmount)
  useEffect(() => {
    // only call match media on browser
    mq = window.matchMedia('(max-width: 48em)');

    // remember the scroll position of the page before we freeze the window
    scrollPosition = {
      top: WindowHelpers.getVerticalScrollPosition(),
      left: WindowHelpers.getHorizontalScrollPosition(),
    };

    if (props.parentModal) {
      parentModal = document.querySelector('.' + props.parentModal);
      parentPage = document.querySelector('.tc__page');
      pageFooter = document.querySelector('.footer');
    }
    resizeBodyHeightForModal();

    // add a resize event to recalculate window scroll
    window.addEventListener('resize', resizeBodyHeightForModal);

    window.setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);

    // on unmount, we want to clean up the UI
    return () => {
      Utils.removeClass(document.body, 'no-scroll');
      if (parentPage) {
        parentPage.removeAttribute('style');
      }
      if (pageFooter) {
        pageFooter.removeAttribute('style');
      }

      // remove resize event
      window.removeEventListener('resize', resizeBodyHeightForModal);

      // return the page scroll position
      if (scrollPosition) {
        window.setTimeout(() => {
          window.scrollTo(scrollPosition.left, scrollPosition.top);
        }, 10);
      }
    };
  }, []);

  const resizeBodyHeightForModal = () => {
    // if we only want functionality for mobile do nothing qs
    if (props.mobileOnly && !mq.matches) {
      return;
    }

    parentModal = document.querySelector('.' + props.parentModal);
    parentPage = document.querySelector('.tc__page');
    pageFooter = document.querySelector('.footer');

    // if overlay is open and too tall for the screen,
    // add a height and overflow to the overlays parent
    const spaceFromTop = props.spaceFromTop || 30;

    const heightToCheck = parentModal.offsetHeight + 2 * spaceFromTop;

    if (parentModal && parentPage && heightToCheck > window.innerHeight) {
      Utils.removeClass(document.body, 'no-scroll');

      parentPage.setAttribute(
        'style',
        'overflow: hidden; height: ' + heightToCheck + 'px;'
      );
      // also hide the page footer
      if (pageFooter) {
        pageFooter.setAttribute('style', 'display: none');
      }
    } else {
      Utils.addClass(document.body, 'no-scroll');
    }
  };

  // this is called on any update
  useEffect(() => {
    resizeBodyHeightForModal();
  });

  return null;
};

FreezeWindowScroll.propTypes = {
  updateFlag: PropTypes.any,
  mobileOnly: PropTypes.bool,
  parentModal: PropTypes.string.isRequired,
  spaceFromTop: PropTypes.number,
};

export default FreezeWindowScroll;
