// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';
import ProfessionalLearningDropdown from './ProfessionalLearningDropdown';

const HeaderLink = (props) => {
  // set up link for Home
  const homeNode = props.isFreeUser ? (
    <NavigationLink
      className="header__link tc__link"
      url="/home?from=header_link"
    >
      Home
    </NavigationLink>
  ) : null;

  const domainsNode = (
    <ProfessionalLearningDropdown
      openMarketingDropdown={props.openMarketingDropdown}
      closeMarketingDropdown={props.closeMarketingDropdown}
      page={props.page}
      marketingDropdownOpened={props.marketingDropdownOpened}
    />
  );

  const domainsActiveClass = props.page === 'Domains' ? ' active' : '';
  const mobileDomainsNode = (
    <a
      className={`show-phone hide-desktop header__link tc__link${domainsActiveClass}`}
      href={`${process.env.REACT_APP_MARKETING_URL}/learning-experiences/student-centered-teaching?from=header_link`}
    >
      Professional Learning
    </a>
  );

  const partnersActiveClass =
    props.page === 'Content Partners' ? ' active' : '';
  const contentPartnersNode = (
    <NavigationLink
      className={`show-phone hide-desktop header__link tc__link${partnersActiveClass}`}
      url={`${process.env.REACT_APP_TEACHING_URL}/browse/content-partners?from=header_link`}
    >
      Our Content Partners
    </NavigationLink>
  );

  const strategiesActiveClass = props.page === 'Strategies' ? ' active' : '';
  const strategiesNode = (
    <NavigationLink
      className={`header__link tc__link${strategiesActiveClass}`}
      url={`${process.env.REACT_APP_TEACHING_URL}/browse/strategies?from=header_link`}
    >
      Instructional Strategies
    </NavigationLink>
  );

  const lessonsActiveClass = props.page === 'commonCore' ? ' active' : '';
  return (
    <div className="header__link-container">
      {homeNode}
      {domainsNode}
      {mobileDomainsNode}
      {contentPartnersNode}
      {strategiesNode}
      <a
        className={`header__link tc__link${lessonsActiveClass}`}
        href={`${process.env.REACT_APP_TEACHING_URL}/browse/master_teachers/projects?from=header_link`}
      >
        Lesson Plans
      </a>
    </div>
  );
};

HeaderLink.propTypes = {
  user: PropTypes.object.isRequired,
  isFreeUser: PropTypes.bool,
};

export default HeaderLink;
