// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const Toggle = (props) => {
  return (
    <div
      className="header__admin-switch cohort-dash__toggle-row cohort-dash__toggle-container"
      onClick={props.toggleCallback}
    >
      <input
        type="checkbox"
        id="archivedCheckbox"
        checked={props.checked}
        className="ppd-v3-curation__switch-input"
        onChange={props.toggleCallback}
      />
      <label
        htmlFor="teacherView"
        className="ppd-v3-curation__inline-label ppd-v3-curation__switch-label ppd-v3-curation__switch-label--cohorts"
      >
        <div className="ppd-v3-curation__switch ppd-v3-curation__switch--cohorts ppd-v3-curation__switch--has-label">
          <span className="ppd-v3-curation__switch-btn"></span>
        </div>
        <div className="ppd-v3-curation__switch-caption ppd-v3-curation__switch-caption--cohort">
          {props.checked ? props.checkedLabel : props.uncheckedLabel}
        </div>
      </label>
    </div>
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  toggleCallback: PropTypes.func.isRequired,
  checkedLabel: PropTypes.string.isRequired,
  uncheckedLabel: PropTypes.string.isRequired,
};

export default Toggle;
