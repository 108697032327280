// @ts-nocheck FIXME
import NProgress from 'nprogress';
import ParticipantApi from './../../apis/ParticipantApi';
import VisionApi from './../../apis/VisionApi';
import Analytics from './../../components/Analytics';
import Utils from './../../components/DynamicUtils';
import Modal from './../../components/Modal';
import { History } from './../../components/Router';
import S3 from './../../components/S3';
import Store from './../../components/Store';
import WindowHelpers from './../../components/WindowHelpers';

let completedFiles = 0;

const defaultUi = {
  scrollTop: 0,
  alert: {},
  errors: {},
  artifactModal: {
    callbacks: {
      onOpen: (data) => {
        History.replace(null, null, store.baseurl + '/artifact/' + data.id);
        Analytics.logEvent({
          category: 'vision',
          action: 'open_artifact_overlay',
          label: 'from_vision_page',
        });
      },
      onClose: () => {
        History.replace(null, null, store.baseurl);
        Analytics.logEvent({
          category: 'vision',
          action: 'close_artifact_overlay',
          label: 'from_view_artifact_modal_vision_page',
        });
      },
    },
  },
  artifactUpload: {
    optionsVisibile: false,
    uploadType: null,
    sectionMode: 'start',
    file: {},
    error: '',
    dragOver: false,
    blur: false,
    selectActive: false,
    selectedTypeId: null,
    loading: false,
  },
  // Used for deleting an artifact
  confirmDelete: {
    visible: false,
  },
  // Used for moving an artifact
  confirmMove: {
    visible: false,
    errors: {},
    participantOutcomes: {
      inputStatus: 'blur',
      charCount: 0,
      options: [],
      selectedOption: null,
      visibleOptions: [],
    },
  },
  link: {
    error: '',
  },
  textArtifactModal: {
    callbacks: {
      onOpen: (data) => {
        // clear opened areas
        store.ui.editModeFields = [];
        Analytics.logEvent({
          category: 'add_text_modal',
          action: 'open',
          label: 'from_vision_page',
        });
      },
      onClose: () => {
        Analytics.logEvent({
          category: 'text_artifact_close',
          action: 'close',
          label: 'from_text_artifact_modal_vision_page',
        });
      },
    },
  },
  loadingFields: [],
  editModeFields: [],
};

const store = {
  baseurl: '',
  participant: {},
  selectedEntry: null,
  artifactTypes: [],
};

/****** PRIVATE FUNCTIONS ******/
const setAlert = (message, type) => {
  store.ui.alert = {
    type: type,
    message: message,
    handleClose: store.clearAlert,
  };
};

const setErrorAlert = (message) => {
  setAlert(message, 'error');
};

const resetComponent = (name) => {
  if (store.ui[name].close && typeof store.ui[name].close === 'function') {
    store.ui[name].close({ callStoreCallback: false });
    return;
  }
  store.ui[name] = Utils.clone(defaultUi[name]);
};

const toggleLoadingState = (name) => {
  if (
    store.ui[name].toggleLoadingState &&
    typeof store.ui[name].toggleLoadingState === 'function'
  ) {
    store.ui[name].toggleLoadingState();
    return;
  }
  store.ui[name].loading = !store.ui[name].loading;
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.artifactTypes = data.artifact_types;
  store.participant = data.participant;
  store.selectedEntry = data.selectedEntry || null;
  store.baseurl =
    '/coaching/lab/participant/' + store.participant.id + '/vision';
  // Add the participant outcomes to the ui
  defaultUi.confirmMove.participantOutcomes.options =
    store.participant.outcomes;
  store.ui = Utils.clone(defaultUi);

  // Init modals
  store.ui.artifactModal = Modal(store, store.ui.artifactModal);

  store.ui.textArtifactModal = Modal(store, store.ui.textArtifactModal);

  if ('chosen' in data) {
    store.ui.artifactModal.open(store.participant.visionArtifacts[data.chosen]);
  }

  // if vision field is empty default to opened vision
  if (!store.participant.vision) {
    store.ui.editModeFields.push('vision');
  }

  // don't trigger event
  return false;
};

// Toggles confirmMove and confirmDelete elements
store.toggleElement = (data) => {
  const element = store.ui[data.element];
  element.visible = !element.visible;
  // Reset the element if needed
  if (!element.visible) {
    resetComponent(data.element);
  }

  if (
    WindowHelpers.isMobileDevice() &&
    data.element == 'confirmMove' &&
    element.visible
  ) {
    window.setTimeout(() => {
      const elementPosition = document.querySelector(
        '.artifact-move__form'
      ).offsetTop;
      document.querySelector('.modal--fixed').scroll(0, elementPosition - 62);
    }, 200);
  }

  Analytics.logEvent({
    category: data.analyticsCategory,
    action: data.analyticsAction,
    label: data.analyticsLabel,
  });
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.setEditMode = (data) => {
  // clear opened areas
  store.ui.editModeFields = [];
  // open appropreate area
  store.ui.editModeFields.push(data.fieldName);

  const action =
    data.clickType == 'add' ? 'add_' + data.fieldName + '_link' : 'edit';
  // Record the analytics event:
  Analytics.logEvent({
    category: 'add_' + data.fieldName + '_link',
    action: 'click',
    label: 'from_vision_page',
  });
};

store.setDisplayMode = (data) => {
  // remove field from the list
  for (let i = 0; i < store.ui.editModeFields.length; i++) {
    if (store.ui.editModeFields[i] == data.field) {
      store.ui.editModeFields.splice(i, 1);
      // Record the analytics event:
      const action =
        data.operativeTerm == 'Update' ? 'cancel_update' : 'cancel_add';
      Analytics.logEvent({
        category: data.field,
        action: action,
        label: 'from_vision_page',
      });

      return;
    }
  }
};

const finishLoadingField = (field) => {
  // remove field from the list
  for (let i = 0; i < store.ui.loadingFields.length; i++) {
    if (store.ui.loadingFields[i] == field) {
      store.ui.loadingFields.splice(i, 1);
      return;
    }
  }
};

store.submitTextArea = (data) => {
  data = data || {};

  // clear errors
  store.ui.loadingFields = [];
  store.ui.errors = {};
  store.ui.alert = {};
  // track form errors
  const errors = {};
  let updateField = function () {};

  switch (data.field) {
    case 'vision':
      data.participantId = store.participant.id;
      data.vision = data.value;

      updateField = function () {
        store.participant.vision = data.value;
      };
      break;
    case 'design_summary':
      data.participantId = store.participant.id;
      data.designSummary = data.value;

      updateField = function () {
        store.participant.design_summary = data.value;
      };
      break;
  }

  // if the form has any errors, display them instead of submitting the form
  if (Object.keys(errors).length > 0) {
    store.ui.errors = errors;
    return;
  }

  // set up the UI to show the loading animation
  store.ui.loadingFields.push(data.field);

  // data.step = data.field;
  // add general data to send to api
  data.participantId = store.participant.id;

  //wrong endpoint but still dont have one yet
  return ParticipantApi.editParticipantData(data)
    .then((response) => {
      store.setDisplayMode(data.field);
      finishLoadingField(data.field);
      updateField();
      // remove the field from the opened fields array to close it
      for (let i = 0; i < store.ui.editModeFields.length; i++) {
        if (store.ui.editModeFields[i] != data.field) {
          return;
        }
        store.ui.editModeFields.splice(i, 1);
      }

      const analyticsAction = data.operativeTerm == 'Add' ? 'add' : 'update';
      // Record the analytics event:
      Analytics.logEvent({
        category: data.field,
        action: analyticsAction,
        label: 'from_vision_page',
      });
    })
    .catch((response) => {
      finishLoadingField(data.field);
      setErrorAlert(
        'Sorry, there was a problem submitting the form. Please try again later.'
      );
    });
};

store.toggleUploadArtifactOptions = () => {
  store.ui.artifactUpload.optionsVisibile =
    !store.ui.artifactUpload.optionsVisibile;

  const analyticsAction = store.ui.artifactUpload.optionsVisibile
    ? 'open'
    : 'close';
  // Record the analytics event:
  Analytics.logEvent({
    category: 'add_widget',
    action: analyticsAction,
    label: 'from_vision_page',
  });
};

store.openArtifactModal = (artifactType) => {
  store.ui.artifactUpload.uploadType = artifactType;

  store.ui.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const analyticsCategory =
    artifactType == 'link' ? 'add_link_modal' : 'add_file_modal';
  // Record the analytics event:
  Analytics.logEvent({
    category: analyticsCategory,
    action: 'open',
    label: 'from_vision_page',
  });
};

store.openEditArtifactModal = (data) => {
  store.ui.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const visionArtifacts = store.participant.visionArtifacts;
  for (const index in visionArtifacts) {
    if (visionArtifacts[index]['id'] == data.fileDetails.id) {
      delete visionArtifacts[index]['new'];
      break;
    }
  }

  if ('selectedTypeId' in data.fileDetails) {
    store.ui.artifactUpload.selectedTypeId = data.fileDetails.selectedTypeId;
  }
  store.ui.artifactUpload.sectionMode = 'edit';
  store.ui.artifactUpload.file = data.fileDetails;
  store.ui.artifactUpload.uploadType = data.uploadType;
};

store.closeArtifactModal = (shouldDelete) => {
  // When a user cancels after uploading an artifact, delete the artifact
  if (shouldDelete && store.ui.artifactUpload.file) {
    const modalArtifactId = store.ui.artifactUpload.file.id;
    const visionArtifacts = store.participant.visionArtifacts;
    const index = Utils.getItemIndexFromArrayOfObjects(
      visionArtifacts,
      'id',
      store.ui.artifactUpload.file.id
    );
    if (
      visionArtifacts[index]['id'] == modalArtifactId &&
      visionArtifacts[index]['new']
    ) {
      store.deleteArtifact({
        id: store.ui.artifactUpload.file.id,
        analyticsCategory: 'vision_artifact',
        analyticsLabel: 'from_add_file_modal_vision_page',
      });
    }
  }
  resetComponent('artifactUpload');
  resetComponent('confirmDelete');
  resetComponent('confirmMove');
  store.setArtifactLinkUploadError('');
  window.onbeforeunload = null;
  window.setTimeout(() => {
    window.scroll(0, store.ui.scrollTop);
  }, 50);
};

/****** Artifact Upload ******/

store.handleSelectFiles = () => {
  Analytics.logEvent({
    category: 'file',
    action: 'browse',
    label: 'from_add_file_modal_vision_page',
  });
};

store.onDragOver = () => {
  store.ui.artifactUpload.dragOver = true;
};

store.onDragLeave = () => {
  store.ui.artifactUpload.dragOver = false;
};

store.handleUploadCancel = () => {
  resetComponent('artifactUpload');
  // clear the navigation alert
  WindowHelpers.navigationConfirmation(false);
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.showUploadError = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

store.progressCallback = (files) => {
  WindowHelpers.navigationConfirmation(true);

  let file;
  for (const key in files) {
    file = files[key];
  }

  store.ui.artifactUpload.file = file;
};

store.saveCallback = (file) => {
  // show alert if user attempts to navigate away
  WindowHelpers.navigationConfirmation(true);
  store.ui.artifactUpload.sectionMode = 'after';
  store.ui.artifactUpload.file = file;
};

const files = {};
const updateFileInState = (data) => {
  files[data['uuid']] = data;
  store.ui.artifactUpload.sectionMode = data.status;
  if (store.progressCallback && data['status'] != 'error') {
    store.progressCallback(files);
  }
};

store.submitFiles = (data) => {
  let file;

  for (let i = 0; i < data.inputFiles.length; i++) {
    file = {
      lastModified: data.inputFiles[i]['lastModified'],
      lastModifiedDate: data.inputFiles[i]['lastModifiedDate'],
      containerType: 1000, // BL_RBP_TC_USER
      name: data.inputFiles[i]['name'],
      size: data.inputFiles[i]['size'],
      type: data.inputFiles[i]['type'],
      status: 'before',
      message: 'Uploading...',
      uuid: Utils.uuid(),
    };
    // max size is 1024MB
    if (file.size > 1024000000) {
      store.showUploadError('maxSizeExceeded');
      return;
    }

    data.inputFiles[i]['containerType'] = file['containerType'];
    data.inputFiles[i]['uuid'] = file['uuid'];
    data.inputFiles[i]['teacherId'] = store.participant.user_id;
    updateFileInState(file);

    // upload files
    S3.upload(data.inputFiles[i]).then(
      function onSuccess(fileData) {
        // create an artifact object for the uploaded lessonfile
        VisionApi.createArtifact({
          participantId: store.participant.id,
          lessonfileId: fileData.id,
        }).then(
          function (response) {
            fileData['message'] = 'Upload Complete';
            fileData['status'] = 'after';
            fileData['title'] = response.artifact.title;
            updateFileInState(fileData);

            fileData.id = response.artifact.id;
            fileData.extension = response.artifact.connections.icon;
            fileData.artifact = response.artifact;
            response.artifact['new'] = true;
            store.participant.visionArtifacts.unshift(response.artifact);
            try {
              store.saveCallback(fileData);
            } catch (err) {
              // @todo @team bubble up the error to the UI
              console.log('error in save: ' + err);
            }
            completedFiles++;
            if (completedFiles == data.inputFiles.length) {
              data.inputFiles = [];
              completedFiles = 0;
            }
          },
          function (response) {
            store.showUploadError(response);
          }
        );
      },
      function onError(fileData) {
        fileData['status'] = 'error';
        updateFileInState(fileData);
        if (fileData.errorReason == 'errored') {
          store.showUploadError('There was error uploading your file.');
        } else if (fileData.errorReason == 'aborted') {
          store.handleUploadCancel();
        }
      },
      function onProgress(fileData) {
        if (files[fileData['uuid']]['status'] == 'error') {
          return;
        }
        fileData['message'] = fileData.progress + '%';
        fileData['status'] = 'during';
        fileData['percent'] = fileData.progress;
        updateFileInState(fileData);
      }
    );
  }
};

const handleArtifactSaved = (responseData) => {
  store.ui.artifactUpload.sectionMode = 'edit';
  store.ui.artifactUpload.file = responseData;
};

store.setArtifactLinkUploadError = (error) => {
  store.ui.link.error = error;
};

/****** Artifact Link Upload ******/
store.submitLink = (data) => {
  if (!Utils.isUrl(data.url)) {
    store.setArtifactLinkUploadError('The link is invalid.');
    toggleLoadingState('artifactUpload');
    return true;
  }
  // send the link to the backend
  const linkData = {
    teacherId: store.participant.user_id,
    participantId: store.participant.id,
    url: data.url,
  };

  // visual indicator of sending
  toggleLoadingState('artifactUpload');

  VisionApi.createArtifact(linkData)
    .then((response) => {
      // end visual indicator
      NProgress.done();
      const fileData = {};
      fileData.artifact = response.artifact;
      fileData.url = response.artifact.connections.link;
      fileData.title = response.artifact.title;
      fileData.id = response.artifact.id;
      fileData.content_type = response.artifact.content_type;
      response.artifact['new'] = true;
      store.participant.visionArtifacts.unshift(response.artifact);
      try {
        store.saveCallback(fileData);
      } catch (err) {
        toggleLoadingState('artifactUpload');
        console.log('error in save: ' + err);
      }

      // handle success case
      const responseData = {
        linkId: response.artifact.preview_lessonfile_id,
        id: response.id,
        url: data.url,
        title: '',
        name: '',
        preview: response.artifact.preview || null,
        artifact: response.artifact,
      };

      toggleLoadingState('artifactUpload');

      Analytics.logEvent({
        category: 'link',
        action: 'add',
        label: 'from_add_link_modal_vision_page',
      });

      return;
    })
    .catch((response) => {
      store.setArtifactLinkUploadError('The link can not be reached.');
      toggleLoadingState('artifactUpload');
    });
};

/****** Artifact Form Actions ******/
store.editArtifact = (data) => {
  data.participantId = store.participant.id;
  toggleLoadingState('artifactUpload');

  VisionApi.editArtifact(data)
    .then((response) => {
      toggleLoadingState('artifactUpload');
      const index = Utils.getItemIndexFromArrayOfObjects(
        store.participant.visionArtifacts,
        'id',
        data.id
      );
      store.participant.visionArtifacts[index] = response.artifact;

      store.closeArtifactModal(false);
      const analyticsAction = data.submitType.toLowerCase();
      Analytics.logEvent({
        category: data.analyticsCategory,
        action: analyticsAction,
        label: data.analyticsLabel,
      });
    })
    .catch(
      function (response) {
        toggleLoadingState('artifactUpload');
        store.showUploadError(
          'Sorry, we could not save the artifact. Please try again later.'
        );
      }.bind(this)
    );
};

/****** Delete Artifact ******/
store.deleteArtifact = (data) => {
  data['participantId'] = store.participant.id;
  data['artifactId'] = data.id;
  return VisionApi.deleteArtifact(data)
    .then((response) => {
      const index = Utils.getItemIndexFromArrayOfObjects(
        store.participant.visionArtifacts,
        'id',
        data.id
      );
      store.participant.visionArtifacts.splice(index, 1);
      resetComponent('confirmDelete');
      resetComponent('artifactUpload');
      if (store.ui.textArtifactModal.opened) {
        store.ui.textArtifactModal.close({
          callStoreCallback: false,
        });
      }
      Analytics.logEvent({
        category: data.analyticsCategory,
        action: 'delete_artifact',
        label: data.analyticsLabel,
      });
    })
    .catch((response) => {
      setErrorAlert(
        'Sorry, there was a problem deleting artifact. Please try again later.'
      );
    });
};

store.applySort = () => {
  const ids = [];
  store.participant.visionArtifacts.map((artifact) => {
    ids.push(artifact['id']);
  });
  const blob = {};
  blob['participantId'] = store.participant.id;
  blob['ids'] = ids;
  VisionApi.resortArtifacts(blob).catch((response) => {
    setErrorAlert(
      'Sorry, there was a problem sorting your artifacts. Please try again later.'
    );
  });
};

store.submitTextArtifact = (data) => {
  toggleLoadingState('textArtifactModal');
  // Add the participant
  data.participantId = store.participant.id;
  VisionApi.submitTextArtifact(data)
    .then((response) => {
      toggleLoadingState('textArtifactModal');
      const artifact = response.artifact;
      // Try to find the artifact in the store
      const index = Utils.getItemIndexFromArrayOfObjects(
        store.participant.visionArtifacts,
        'id',
        artifact.id
      );
      if (index >= 0) {
        store.participant.visionArtifacts[index] = artifact;
      } else {
        store.participant.visionArtifacts.unshift(artifact);
      }
      Analytics.logEvent({
        category: 'text_artifact',
        action: data.artifactId ? 'update' : 'add',
        label: 'from_text_artifact_modal_vision_page',
      });
      resetComponent('artifactUpload');
      resetComponent('textArtifactModal');
    })
    .catch((response) => {
      toggleLoadingState('textArtifactModal');
      store.ui.textArtifactModal.setFieldError({
        field: 'text',
        error:
          response && response.error
            ? response.error
            : 'An error occurred. Please try again',
      });
    });
};

/****** Move Artifact ******/
store.moveArtifact = (data) => {
  data['participantId'] = store.participant.id;
  data['artifactId'] = data.id;
  data['participantOutcomeId'] = data.destinationId;
  toggleLoadingState(data.containerName);
  return VisionApi.moveParticipantVisionArtifactToParticipantOutcome(data)
    .then((response) => {
      store.participant.visionArtifacts = response.visionArtifacts;
      resetComponent('confirmMove');
      resetComponent('artifactUpload');
      if (store.ui.textArtifactModal.opened) {
        store.ui.textArtifactModal.close({
          callStoreCallback: false,
        });
      }
      Analytics.logEvent({
        category: 'entry',
        action: 'confirm_move',
        label: 'from_move_vision_page_entry_modal',
      });
      const message =
        'Artifact moved to <a href="/coaching/lab/participant/' +
        store.participant.id +
        '/outcome/' +
        data.destinationId +
        '?from=participant_vision"/>your Outcome</a>';
      setAlert(message, 'alert');
    })
    .catch((response) => {
      resetComponent('confirmMove');
      resetComponent(data.containerName);
      const error = response.error
        ? response.error
        : 'Sorry, there was a problem moving the artifact. Please try again later.';
      setErrorAlert(error);
    });
};

store.getSingleSelectElement = (data) => {
  const field = store.ui[data.feature][data.field];
  field.charCount = data.input.length;

  const label = 'from_move_vision_page_entry_modal';
  const action = 'search';
  const category = 'outcome';

  Analytics.logEvent({
    category: category,
    action: action,
    label: label,
  });
  // Only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    return option.label.toLowerCase().includes(data.input.toLowerCase());
  });
};

store.selectSingleSelectElement = (data) => {
  if (!data.feature || !data.field) {
    return false;
  }
  Object.assign(store.ui[data.feature][data.field], data.data);
  // Handle Analytics
  // Only capture selecting or removing an option
  if (data.data.selected || data.data.removed) {
    const label = 'from_move_vision_page_entry_modal';
    const category = 'outcome';
    const action = data.data.selected ? 'select' : 'remove';
    Analytics.logEvent({
      category: category,
      action: action,
      label: label,
    });
  }
};

store.setFeatureFieldError = (data) => {
  const errors = store.ui[data.feature].errors;
  if (data.error) {
    errors[data.field] = data.error;
  } else {
    delete errors[data.field];
  }
};

export default Store('ParticipantVisionStore', store);
