// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../components/StaticUtils';
import NavigationLink from './../NavigationLink';

const PortfolioRow = (props) => {
  const dateCreated = Utils.formatDate(props.portfolio.created);
  const lastmodified = Utils.formatDate(props.portfolio.lastmodified);

  return (
    <tr className="admin-table__row">
      <td className="admin-table__col">
        <NavigationLink
          url={
            '/bl/v1/partner/portfolio/' +
            props.portfolio.id +
            '/edit/COVER?from=bl_partners_portfolios'
          }
          className="tc__link"
        >
          {props.portfolio.name}
        </NavigationLink>
      </td>
      <td></td>
      <td></td>
      <td className="admin-table__col">{dateCreated}</td>
      <td className="admin-table__col">{lastmodified}</td>
      <td className="admin-table__col">
        <NavigationLink
          url={
            '/bl/v1/partner/portfolio/' +
            props.portfolio.id +
            '/edit/COVER?from=bl_partners_portfolios'
          }
          className="tc__link"
        >
          <span className="tc-pencil admin-table__action-icon" />
          Edit
        </NavigationLink>
        &nbsp;
        <a
          href={
            '/coaching/lab/partner/portfolio/' +
            props.portfolio.guid +
            '?from=bl_partners_portfolios'
          }
          className="tc__link admin-table__preview-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="tc-file-link admin-table__action-icon" />
          Preview
        </a>
      </td>
    </tr>
  );
};

PortfolioRow.propTypes = {
  portfolio: PropTypes.object.isRequired,
};

export default PortfolioRow;
