import Authenticate from '@components/Authenticate/Authenticate';
import LegacyLabPageWrapper from '@components/LegacyLabPageWrapper/LegacyLabPageWrapper';
import Logout from '@components/Logout/Logout';
import { LegacyLabProvider } from '@contexts/LegacyLabContext';
import PartnerLeaderEvent from '@legacy-components//react/V5/PartnerLeader/Event/Event';
import Badges from '@legacy-components/react/Badges';
import Code404 from '@legacy-components/react/HttpStatus/Code404';
import PartnerPortfolioDashboard from '@legacy-components/react/PartnerPortfolioDashboard/PartnerPortfolioDashboard';
import PartnerPortfolioPresentation from '@legacy-components/react/PartnerPortfolioPresentation/Portfolio';
import PartnerPortfolioTool from '@legacy-components/react/PartnerPortfolioTool/PartnerPortfolioTool';
import PartnerEdit from '@legacy-components/react/Partners/PartnerEdit';
import PartnerList from '@legacy-components/react/Partners/PartnerList';
import PartnerUsersList from '@legacy-components/react/Partners/PartnerUsersList';
import PortfolioPreview from '@legacy-components/react/PortfolioPreview';
import PortfolioPrint from '@legacy-components/react/PortfolioPrint';
import BlendedStrategy from '@legacy-components/react/StaticSite/BlendedStrategy/BlendedStrategy';
import BlendedBrowser from '@legacy-components/react/StaticSite/Browser/BlendedBrowser';
import CommonCore from '@legacy-components/react/StaticSite/Browser/CommonCore';
import Lesson from '@legacy-components/react/StaticSite/Lesson/Lesson';
import MasterTeacherProfile from '@legacy-components/react/StaticSite/MasterTeacherProfile/MasterTeacherProfile';
import MasterTeacherProject from '@legacy-components/react/StaticSite/MasterTeacherProject/MasterTeacherProject';
import MasterTeachersDirectory from '@legacy-components/react/StaticSite/MasterTeachersDirectory/MasterTeachersDirectory';
import AdminDashboardV2 from '@legacy-components/react/V2/AdminDashboard';
import AdminTests from '@legacy-components/react/V2/AdminTests';
import AdminUser from '@legacy-components/react/V2/AdminUser';
import AdminUserCreate from '@legacy-components/react/V2/AdminUserCreate';
import AdminUsersLegacy from '@legacy-components/react/V2/AdminUsers';
import BLLogs from '@legacy-components/react/V2/BLLogs';
import ChallengeStrategies from '@legacy-components/react/V2/ChallengeConditional';
import ChallengeStrategiesV2 from '@legacy-components/react/V2/ChallengeStrategies';
import SearchStrategiesV2 from '@legacy-components/react/V2/SearchStrategies';
import StrategyV2 from '@legacy-components/react/V2/Strategy';
import StrategyBrowserV2 from '@legacy-components/react/V2/StrategyBrowser';
import TeacherPortfolioPreview from '@legacy-components/react/V2/TeacherPortfolio/TeacherPortfolioPreview';
import TeacherPortfolioPrint from '@legacy-components/react/V2/TeacherPortfolio/TeacherPortfolioPrint';
import TeacherPortfolioTool from '@legacy-components/react/V2/TeacherPortfolioTool/TeacherPortfolioTool';
import BulkParticipantImporter from '@legacy-components/react/V3/BulkParticipantImporter/BulkParticipantImporter';
import CohortUsers from '@legacy-components/react/V3/CohortManagement/CohortUsers/CohortUsers';
import Cohorts from '@legacy-components/react/V3/CohortManagement/Cohorts/Cohorts';
import EditCohort from '@legacy-components/react/V3/CohortManagement/EditCohort/EditCohort';
import CustomSchools from '@legacy-components/react/V3/CustomSchools/Main';
import GateKeeperLogin from '@legacy-components/react/V3/GateKeeper/Login';
import GateKeeperRegistration from '@legacy-components/react/V3/GateKeeper/Registration';
import GateKeeperRequestNewPassword from '@legacy-components/react/V3/GateKeeper/RequestNewPassword';
import GateKeeperResetPassword from '@legacy-components/react/V3/GateKeeper/ResetPassword';
import ParticipantMeeting from '@legacy-components/react/V3/ParticipantMeeting/ParticipantMeeting';
import ParticipantPortfolioManagementHome from '@legacy-components/react/V3/ParticipantPortfolioManagement/Home/PortfolioManagementHome';
import ParticipantPortfolioOutcomeStoryManagement from '@legacy-components/react/V3/ParticipantPortfolioManagement/OutcomeStory/PortfolioOutcomeStoryManagement';
import ParticipantPortfolioVisionManagement from '@legacy-components/react/V3/ParticipantPortfolioManagement/Vision/PortfolioVisionManagement';
import ParticipantPortfolioPresentationHome from '@legacy-components/react/V3/ParticipantPortfolioPresentation/Home/PortfolioPresentationHome';
import ParticipantPortfolioPresentationOutcomeStory from '@legacy-components/react/V3/ParticipantPortfolioPresentation/OutcomeStory/PortfolioPresentationOutcomeStory';
import ParticipantPortfolioPresentationVision from '@legacy-components/react/V3/ParticipantPortfolioPresentation/Vision/PortfolioPresentationVision';
import ParticipantRegistration from '@legacy-components/react/V3/ParticipantRegistration/ParticipantRegistration';
import ParticipantVision from '@legacy-components/react/V3/ParticipantVision/ParticipantVision';
import PartnerHighlights from '@legacy-components/react/V3/PartnerHighlights/PartnerHighlights';
import Partners from '@legacy-components/react/V3/PartnerManagement/Partners/Partners';
import AdminDashboardV4 from '@legacy-components/react/V4/Admin/Dashboard';
import ParticipantAdmin from '@legacy-components/react/V4/Admin/Participant';
import V4Domain from '@legacy-components/react/V4/ContentBrowser/Domain';
import V4Domains from '@legacy-components/react/V4/ContentBrowser/Domains';
import V4Strategies from '@legacy-components/react/V4/ContentBrowser/Strategies';
import V4Strategy from '@legacy-components/react/V4/ContentBrowser/Strategy';
import V4StrategyPrint from '@legacy-components/react/V4/ContentBrowser/StrategyPrint';
import ContentCuration from '@legacy-components/react/V4/Curation/CurationHome';
import CurateDomain from '@legacy-components/react/V4/Curation/Domain/Domain';
import CurateStrategyPack from '@legacy-components/react/V4/Curation/Domain/StrategyPack';
import CurateDomains from '@legacy-components/react/V4/Curation/Domains/Domains';
import CurateStrategies from '@legacy-components/react/V4/Curation/Strategies';
import CurateStrategy from '@legacy-components/react/V4/Curation/Strategy';
import FreeUserHome from '@legacy-components/react/V4/Home/FreeUserHome';
import Profile from '@legacy-components/react/V4/Profile/Profile';
import Favorite from '@legacy-components/react/V4/Search/Favorite';
import Search from '@legacy-components/react/V4/Search/Search';
import AccountV5 from '@legacy-components/react/V5/Account/AccountContainer';
import AdminUsers from '@legacy-components/react/V5/AdminUsers/AdminUsers';
import Artifact from '@legacy-components/react/V5/Artifact/ArtifactContainer';
import BulkEventParticipantImport from '@legacy-components/react/V5/BulkEventParticipantImport/BulkEventParticipantImport';
import CoachArchivedParticipants from '@legacy-components/react/V5/Coach/ArchivedParticipants';
import CoachHomeV5 from '@legacy-components/react/V5/Coach/CoachHomeContainer';
import CoachMatchDetails from '@legacy-components/react/V5/CoachMatchDetails/CoachMatchDetailsContainer';
import ContentPartners from '@legacy-components/react/V5/ContentPartners/ContentPartners';
import DomainsContainer from '@legacy-components/react/V5/Domains/DomainsContainer';
import EventAttendee from '@legacy-components/react/V5/Event/EventAttendeeContainer';
import EventSessionRecording from '@legacy-components/react/V5/Event/EventSessionRecording/EventSessionRecording';
import EventCapacityCalendar from '@legacy-components/react/V5/EventCapacityCalendar/EventCapacityCalendar';
import EditEvent from '@legacy-components/react/V5/EventManagement/EditEvent/EditEvent';
import EditEventOverview from '@legacy-components/react/V5/EventManagement/EditEvent/EditEventOverview';
import EventParticipants from '@legacy-components/react/V5/EventManagement/EventParticipants/EventParticipants';
import Events from '@legacy-components/react/V5/EventManagement/Events/Events';
import Highlights from '@legacy-components/react/V5/Highlights/Highlights';
import V5Home from '@legacy-components/react/V5/Home/HomeContainer';
import Networkcheck from '@legacy-components/react/V5/Networkcheck/Networkcheck';
import ParticipantDetails from '@legacy-components/react/V5/ParticipantDetails/ParticipantDetails';
import ParticipantMeetingDetails from '@legacy-components/react/V5/ParticipantDetails/ParticipantMeetingDetailsContainer';
import PartnerLeaderMain from '@legacy-components/react/V5/PartnerLeader/Dashboard/Main';
import PartnerLeaderHome from '@legacy-components/react/V5/PartnerLeader/Home/Home';
import PartnerLeadersContainer from '@legacy-components/react/V5/PartnerLeaders/PartnerLeadersContainer';
import EventCapacityReached from '@legacy-components/react/V5/Registration/EventCapacityReached';
import EventRegistration from '@legacy-components/react/V5/Registration/EventRegistration';
import EventRegistrationExpired from '@legacy-components/react/V5/Registration/EventRegistrationExpired';
import EventRegistrationWelcome from '@legacy-components/react/V5/Registration/EventRegistrationWelcome';
import ConfirmEventReservation from '@legacy-components/react/V5/Registration/EventReservation/ConfirmReservation';
import EventReservation from '@legacy-components/react/V5/Registration/EventReservation/ReservationContainer';
import RegisteredAlready from '@legacy-components/react/V5/Registration/RegisteredAlready';
import RegistrationContainer from '@legacy-components/react/V5/Registration/RegistrationContainer';
import RegistrationExpired from '@legacy-components/react/V5/Registration/RegistrationExpired';
import Unauthorized from '@legacy-components/react/V5/Unauthorized';
import Workspace from '@legacy-components/react/V5/Workspace/WorkspaceContainer';
import WorkspaceSelectResource from '@legacy-components/react/V5/WorkspaceSelectResource/SelectResourceContainer';
import PartnerPortfolioDashboardStore from '@legacy-components/stores/PartnerPortfolioDashboardStore';
import PartnerPortfolioStore from '@legacy-components/stores/PartnerPortfolioStore';
import PartnerPortfolioToolStore from '@legacy-components/stores/PartnerPortfolioToolStore';
import PartnerStore from '@legacy-components/stores/PartnerStore';
import BlendedStrategyStore from '@legacy-components/stores/StaticSite/BlendedStrategyStore';
import BrowserStore from '@legacy-components/stores/StaticSite/BrowserStore';
import LessonStore from '@legacy-components/stores/StaticSite/LessonStore';
import MasterTeacherProfileStore from '@legacy-components/stores/StaticSite/MasterTeacherProfileStore';
import StaticSiteStore from '@legacy-components/stores/StaticSiteStore';
import TeacherPortfolioStore from '@legacy-components/stores/V2/TeacherPortfolioStore';
import TeacherPortfolioToolStore from '@legacy-components/stores/V2/TeacherPortfolioToolStore';
import BulkParticipantImporterStore from '@legacy-components/stores/V3/BulkParticipantImporterStore';
import CohortsStore from '@legacy-components/stores/V3/CohortsStore';
import CustomSchoolsStore from '@legacy-components/stores/V3/CustomSchoolsStore';
import GateKeeperStore from '@legacy-components/stores/V3/GateKeeperStore';
import ParticipantMeetingStore from '@legacy-components/stores/V3/ParticipantMeetingStore';
import ParticipantPortfolioManagementStore from '@legacy-components/stores/V3/ParticipantPortfolioManagementStore';
import ParticipantPortfolioPresentationStore from '@legacy-components/stores/V3/ParticipantPortfolioPresentationStore';
import ParticipantRegistrationStore from '@legacy-components/stores/V3/ParticipantRegistrationStore';
import ParticipantVisionStore from '@legacy-components/stores/V3/ParticipantVisionStore';
import PartnerHighlightsStore from '@legacy-components/stores/V3/PartnerHighlightsStore';
import PartnersStore from '@legacy-components/stores/V3/PartnersStore';
import AdminStore from '@legacy-components/stores/V4/AdminStore';
import ContentBrowserStore from '@legacy-components/stores/V4/ContentBrowserStore';
import CurationStore from '@legacy-components/stores/V4/CurationStore';
import HomeStore from '@legacy-components/stores/V4/HomeStore';
import ProfileStore from '@legacy-components/stores/V4/ProfileStore';
import SearchStore from '@legacy-components/stores/V4/SearchStore';
import ParticipantDetailsStore from '@legacy-components/stores/V5/ParticipantDetailsStore';
import V5PartnerLeaderStore from '@legacy-components/stores/V5/PartnerLeaderStore';
import RegistrationStore from '@legacy-components/stores/V5/RegistrationStore';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';

function App(): JSX.Element {
  const { events, startSilentRenew } = useAuth();

  useEffect(() => {
    return events.addAccessTokenExpiring(startSilentRenew);
  }, [events, startSilentRenew]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/authenticate" element={<Authenticate />} />
        <Route path="/logout" element={<Logout />} />

        <Route element={<LegacyLabProvider />}>
          <Route
            path="/unauthorized"
            element={<LegacyLabPageWrapper Component={Unauthorized} />}
          />
          <Route
            path="/networkcheck"
            element={<LegacyLabPageWrapper Component={Networkcheck} />}
          />
          <Route
            path="/bl/admin"
            element={<LegacyLabPageWrapper Component={AdminDashboardV2} />}
          />
          <Route
            path="/bl/users"
            element={<LegacyLabPageWrapper Component={AdminUsers} />}
          />
          <Route
            path="/bl/legacy/users"
            element={<LegacyLabPageWrapper Component={AdminUsersLegacy} />}
          />
          <Route
            path="/bl/user/create"
            element={<LegacyLabPageWrapper Component={AdminUserCreate} />}
          />
          <Route
            path="/bl/user/:userId"
            element={<LegacyLabPageWrapper Component={AdminUser} />}
          />
          <Route
            path="/bl/tests"
            element={<LegacyLabPageWrapper Component={AdminTests} />}
          />
          <Route
            path="/bl/admin/view_bl_logs"
            element={<LegacyLabPageWrapper Component={BLLogs} />}
          />
          <Route
            path="/bl/tools/bulk-participant-importer"
            element={
              <LegacyLabPageWrapper
                Component={BulkParticipantImporter}
                store={BulkParticipantImporterStore}
              />
            }
          />
          <Route
            path="/bl/tools/bulk-participant-event-import"
            element={
              <LegacyLabPageWrapper Component={BulkEventParticipantImport} />
            }
          />
          <Route
            path="/bl/admin/home"
            element={
              <LegacyLabPageWrapper
                Component={AdminDashboardV4}
                store={AdminStore}
              />
            }
          />
          <Route
            path="/bl/v1/partners"
            element={
              <LegacyLabPageWrapper
                Component={PartnerList}
                store={PartnerStore}
              />
            }
          />
          <Route
            path="/bl/v1/partner/:partnerId"
            element={
              <LegacyLabPageWrapper
                Component={PartnerEdit}
                store={PartnerStore}
              />
            }
          />
          <Route
            path="/bl/v1/partner/:partnerId/users"
            element={
              <LegacyLabPageWrapper
                Component={PartnerUsersList}
                store={PartnerStore}
              />
            }
          />
          <Route
            path="/bl/v1/partners/portfolios"
            element={
              <LegacyLabPageWrapper
                Component={PartnerPortfolioDashboard}
                store={PartnerPortfolioDashboardStore}
              />
            }
          />
          <Route
            path="/bl/v1/partner/portfolio/:portfolioId/edit/:currentSection"
            element={
              <LegacyLabPageWrapper
                Component={PartnerPortfolioTool}
                store={PartnerPortfolioToolStore}
              />
            }
          />

          {[
            '/coaching/lab/strategy-browser',
            '/coaching/lab/strategy-browser-ela',
            '/coaching/lab/strategy-browser-math',
            '/coaching/lab/strategy-browser-science',
            '/coaching/lab/strategy-browser-learning-environment',
            '/coaching/lab/strategy-browser-instructional-design',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={StrategyBrowserV2} />}
            />
          ))}

          <Route
            path="/coaching/lab/strategy-browser/teaching-challenge/:challengeId/:seourl/meta-strategy/:metaStrategyId"
            element={<LegacyLabPageWrapper Component={ChallengeStrategiesV2} />}
          />

          {[
            '/coaching/lab/strategy-browser/teaching-challenge/:challengeId',
            '/coaching/lab/strategy-browser/teaching-challenge/:challengeId/:seourl',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={ChallengeStrategies} />}
            />
          ))}

          <Route
            path="/coaching/lab/strategy-browser/search"
            element={<LegacyLabPageWrapper Component={SearchStrategiesV2} />}
          />

          {[
            '/coaching/lab/strategy/:strategyId/artifact/:artifactId/:seourl',
            '/coaching/lab/strategy/:strategyId/artifact/:artifactId',
            '/coaching/lab/strategy/:strategyId/:seourl',
            '/coaching/lab/strategy/:strategyId',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={StrategyV2} />}
            />
          ))}
          <Route
            path="/bl/partners"
            element={
              <LegacyLabPageWrapper
                Component={Partners}
                store={PartnersStore}
              />
            }
          />
          <Route
            path="/bl/partner-highlights"
            element={
              <LegacyLabPageWrapper
                Component={PartnerHighlights}
                store={PartnerHighlightsStore}
              />
            }
          />
          <Route
            path="/bl/cohorts"
            element={
              <LegacyLabPageWrapper Component={Cohorts} store={CohortsStore} />
            }
          />
          <Route
            path="/bl/cohort/:cohortId"
            element={
              <LegacyLabPageWrapper
                Component={EditCohort}
                store={CohortsStore}
              />
            }
          />
          <Route
            path="/bl/cohort/:cohortId/members"
            element={
              <LegacyLabPageWrapper
                Component={CohortUsers}
                store={CohortsStore}
              />
            }
          />
          <Route
            path="/coaching/lab/participant/:participantId/registration/:stepId"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantRegistration}
                store={ParticipantRegistrationStore}
              />
            }
          />

          <Route
            path="/lab/cohort/:cohortId/already_registered"
            element={<LegacyLabPageWrapper Component={RegisteredAlready} />}
          />

          <Route
            path="/lab/cohort/expired"
            element={<LegacyLabPageWrapper Component={RegistrationExpired} />}
          />

          {[
            '/lab/cohort/:cohortId/participant/register',
            '/lab/cohort/:cohortId/participant/register/bulk',
            '/lab/cohort/:cohortId/coach/register',
            '/lab/cohort/:cohortId/coach/register/bulk',
            '/coaching/lab/participant/:participantId/registration/reset_password',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={RegistrationContainer}
                  store={RegistrationStore}
                />
              }
            />
          ))}

          <Route
            path="/coaching/lab/meeting/:meetingUUID"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantMeeting}
                store={ParticipantMeetingStore}
              />
            }
          />

          {[
            '/coaching/lab/meeting/recording/:recordingUUID',
            '/coaching/lab/meeting/clip/:meetingClipUUID',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={ParticipantMeeting} />}
            />
          ))}

          <Route
            path="/coaching/lab/teacher/:teacherId/portfolio/:portfolioId/preview"
            element={<LegacyLabPageWrapper Component={PortfolioPreview} />}
          />

          <Route
            path="/coaching/lab/teacher/:teacherId/portfolio/:portfolioId/print-lambda-v1"
            element={<LegacyLabPageWrapper Component={PortfolioPrint} />}
          />

          <Route
            path="/coaching/lab/teacher/:teacherId/portfolio/:portfolioId/view"
            element={
              <LegacyLabPageWrapper
                Component={TeacherPortfolioPreview}
                store={TeacherPortfolioStore}
              />
            }
          />

          <Route
            path="/coaching/lab/teacher/:teacherId/portfolio/:portfolioId/print-lambda-v2"
            element={
              <LegacyLabPageWrapper
                Component={TeacherPortfolioPrint}
                store={TeacherPortfolioStore}
              />
            }
          />

          {[
            '/coaching/lab/teacher/:teacherId/portfolio/:portfolioId/:step',
            '/coaching/lab/teacher/:teacherId/portfolio/:portfolioId',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={TeacherPortfolioTool}
                  store={TeacherPortfolioToolStore}
                />
              }
            />
          ))}

          <Route
            path="/coaching/lab/teacher/:teacherId/badges"
            element={<LegacyLabPageWrapper Component={Badges} />}
          />

          {[
            '/coaching/lab/partner/portfolio/:portfolioGuid',
            '/coaching/lab/partner/portfolio/:portfolioGuid/page/:tab',
            '/coaching/lab/partner/portfolio/:portfolioGuid/page/:tab/spotlight/:entryId',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={PartnerPortfolioPresentation}
                  store={PartnerPortfolioStore}
                />
              }
            />
          ))}

          {[
            '/coaching/lab/participant/login',
            '/login',
            '/community/users/login',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={GateKeeperLogin}
                  store={GateKeeperStore}
                />
              }
            />
          ))}

          <Route
            path="/register"
            element={
              <LegacyLabPageWrapper
                Component={GateKeeperRegistration}
                store={GateKeeperStore}
              />
            }
          />

          <Route
            path="/forgot-password"
            element={
              <LegacyLabPageWrapper
                Component={GateKeeperRequestNewPassword}
                store={GateKeeperStore}
              />
            }
          />

          {['/reset-password', '/coaching/lab/reset'].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={GateKeeperResetPassword}
                  store={GateKeeperStore}
                />
              }
            />
          ))}

          <Route
            path="/bl/custom-schools"
            element={
              <LegacyLabPageWrapper
                Component={CustomSchools}
                store={CustomSchoolsStore}
              />
            }
          />

          <Route
            path="/lab/participant/:participantId/details"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantDetails}
                store={ParticipantDetailsStore}
              />
            }
          />

          {[
            '/lab/participant/:participantId/meeting/:meetingId/details',
            '/lab/participant/:participantId/meeting/details/add',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper Component={ParticipantMeetingDetails} />
              }
            />
          ))}

          {['/search', '/search/:userId'].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper Component={Search} store={SearchStore} />
              }
            />
          ))}

          {['/browse/favorites', '/browse/favorites/:userId'].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={Favorite}
                  store={SearchStore}
                />
              }
            />
          ))}

          {['/home', '/home/:userId'].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={FreeUserHome}
                  store={HomeStore}
                />
              }
            />
          ))}

          {[
            '/profile',
            '/profile/:userId',
            '/profile/:userId/:seoUrl',
            '/profile/:userId/artifact/:artifactEntryId',
            '/profile/:userId/story/:storyId',
            '/profile/:userId/story/:storyId/:tab',
            '/profile/:userId/story/:storyId/artifact/:storyEntryId',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={Profile}
                  store={ProfileStore}
                />
              }
            />
          ))}

          <Route
            path="/browse/learning-domains/V4"
            element={
              <LegacyLabPageWrapper
                Component={V4Domains}
                store={ContentBrowserStore}
              />
            }
          />

          {[
            '/browse/learning-domain/:focusAreaId',
            '/browse/learning-domain/:focusAreaId/:seoUrl',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={V4Domain}
                  store={ContentBrowserStore}
                />
              }
            />
          ))}

          <Route
            path="/browse/strategies"
            element={
              <LegacyLabPageWrapper
                Component={V4Strategies}
                store={ContentBrowserStore}
              />
            }
          />

          {[
            '/strategy/:strategyId',
            '/strategy/:strategyId/resource/:resourceId',
            '/strategy/:strategyId/:seoUrl',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={V4Strategy}
                  store={ContentBrowserStore}
                />
              }
            />
          ))}

          <Route
            path="/strategy/print/:strategyId"
            element={
              <LegacyLabPageWrapper
                Component={V4StrategyPrint}
                store={ContentBrowserStore}
              />
            }
          />

          {[
            '/lab/coach/home',
            '/lab/coach/:coachId',
            '/lab/coach/:coachId/home',
            '/lab/coach/:coachId/events',
            '/lab/coach/:coachId/coaching',
            '/lab/coach/:coachId/surveys',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={CoachHomeV5}
                  store={AdminStore}
                />
              }
            />
          ))}

          {['/lab/coach/archive', '/lab/coach/:coachId/archive'].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper Component={CoachArchivedParticipants} />
              }
            />
          ))}

          <Route
            path="/lab/coach/:coachId/match/:participantId/details"
            element={<LegacyLabPageWrapper Component={CoachMatchDetails} />}
          />

          <Route
            path="/bl/participant/:participantId"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantAdmin}
                store={AdminStore}
              />
            }
          />

          <Route
            path="/bl/curation"
            element={<LegacyLabPageWrapper Component={ContentCuration} />}
          />

          <Route
            path="/bl/curation/domains"
            element={
              <LegacyLabPageWrapper
                Component={CurateDomains}
                store={CurationStore}
              />
            }
          />

          {[
            '/bl/curation/domain/add',
            '/bl/curation/domain/:domainId/edit',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={CurateDomain}
                  store={CurationStore}
                />
              }
            />
          ))}

          <Route
            path="/bl/curation/strategies"
            element={
              <LegacyLabPageWrapper
                Component={CurateStrategies}
                store={CurationStore}
              />
            }
          />

          {[
            '/bl/curation/strategy/add',
            '/bl/curation/strategy/:strategyId/edit',
            '/bl/curation/domain/:domainId/strategy/add',
            '/bl/curation/domain/:domainId/strategy/:strategyId/edit',
            '/bl/curation/domain/:domainId/pack/:packId/strategy/add',
            '/bl/curation/domain/:domainId/pack/:packId/strategy/:strategyId/edit',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={CurateStrategy}
                  store={CurationStore}
                />
              }
            />
          ))}

          {[
            '/bl/curation/domain/:domainId/strategypack/add',
            '/bl/curation/domain/:domainId/strategypack/:strategyPackId/edit',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={CurateStrategyPack}
                  store={CurationStore}
                />
              }
            />
          ))}

          {['/lab/workspace', '/lab/workspace/:userId'].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={Workspace} />}
            />
          ))}

          <Route
            path="/lab/workspace/:userId/select-resource"
            element={
              <LegacyLabPageWrapper Component={WorkspaceSelectResource} />
            }
          />

          {[
            '/lab/workspace/artifact/:artifactEntryId',
            '/lab/workspace/:userId/artifact/:artifactEntryId',
            '/lab/highlights/artifact/:artifactEntryId',
            '/lab/highlights/:userId/artifact/:artifactEntryId',
            '/lab/cohort/:cohortId/artifact/:reportId',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={Artifact} />}
            />
          ))}

          {['/lab/highlights', '/lab/highlights/:userId'].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={Highlights} />}
            />
          ))}

          {['/lab/home', '/lab/home/:userId'].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={V5Home} />}
            />
          ))}

          <Route
            path="/bl/events"
            element={
              <LegacyLabPageWrapper Component={Events} store={AdminStore} />
            }
          />

          {[
            '/bl/event/:eventId/participants',
            '/bl/event/:eventId/sessions',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={EventParticipants}
                  store={AdminStore}
                />
              }
            />
          ))}

          <Route
            path="/bl/events/capacity-calendar"
            element={<LegacyLabPageWrapper Component={EventCapacityCalendar} />}
          />

          <Route
            path="/bl/event/:eventId"
            element={<LegacyLabPageWrapper Component={EditEvent} />}
          />

          <Route
            path="/bl/event/:eventId/overview"
            element={<LegacyLabPageWrapper Component={EditEventOverview} />}
          />

          <Route
            path="/lab/event/:eventId/reserve"
            element={
              <LegacyLabPageWrapper
                Component={EventReservation}
                store={RegistrationStore}
              />
            }
          />

          <Route
            path="/lab/event/:eventId/confirm-reservation"
            element={
              <LegacyLabPageWrapper Component={ConfirmEventReservation} />
            }
          />

          <Route
            path="/lab/event/:eventId/register"
            element={
              <LegacyLabPageWrapper
                Component={EventRegistration}
                store={RegistrationStore}
              />
            }
          />

          <Route
            path="/lab/event/:eventId/welcome"
            element={
              <LegacyLabPageWrapper
                Component={EventRegistrationWelcome}
                store={RegistrationStore}
              />
            }
          />

          <Route
            path="/lab/event/expired"
            element={
              <LegacyLabPageWrapper Component={EventRegistrationExpired} />
            }
          />

          <Route
            path="/lab/event/:eventId/capacity-reached"
            element={<LegacyLabPageWrapper Component={EventCapacityReached} />}
          />

          {[
            '/lab/event/:eventId/participant/:participantId',
            '/lab/event/:eventId/preview',
            '/lab/event/:eventId/group/:groupId/preview',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={EventAttendee} />}
            />
          ))}

          <Route
            path="/lab/event_session/recording/:recordingUUID"
            element={<LegacyLabPageWrapper Component={EventSessionRecording} />}
          />

          {[
            '/account',
            '/account/profile',
            '/account/:userId',
            '/account/:userId/profile',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={<LegacyLabPageWrapper Component={AccountV5} />}
            />
          ))}

          {[
            '/lab/leader/home',
            '/lab/leader/year/past/home',
            '/lab/leader/year/current/home',
            '/lab/leader/:leadId/home',
            '/lab/leader/:leadId/year/past/home',
            '/lab/leader/:leadId/year/current/home',
            '/lab/leader/:leadId/overview',
            '/lab/leader/:leadId/year/past/overview',
            '/lab/leader/:leadId/year/current/overview',
            '/lab/leader/:leadId/engagement',
            '/lab/leader/:leadId/year/past/engagement',
            '/lab/leader/:leadId/year/current/engagement',
            '/lab/leader/:leadId/updates',
            '/lab/leader/:leadId/year/past/updates',
            '/lab/leader/:leadId/year/current/updates',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={PartnerLeaderHome}
                  store={V5PartnerLeaderStore}
                />
              }
            />
          ))}

          <Route
            path="/lab/leader/:leadId/event/:eventId"
            element={
              <LegacyLabPageWrapper
                Component={PartnerLeaderEvent}
                store={V5PartnerLeaderStore}
              />
            }
          />

          {[
            '/lab/cohort/:cohortId',
            '/lab/cohort/:cohortId/overview',
            '/lab/cohort/:cohortId/engagement',
            '/lab/cohort/:cohortId/meetings',
            '/lab/cohort/:cohortId/activity',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={PartnerLeaderMain}
                  store={V5PartnerLeaderStore}
                />
              }
            />
          ))}

          <Route
            path="/browse/learning-domains"
            element={<LegacyLabPageWrapper Component={DomainsContainer} />}
          />

          <Route
            path="/browse/content-partners"
            element={<LegacyLabPageWrapper Component={ContentPartners} />}
          />

          <Route
            path="/bl/partner/:partnerId/leaders"
            element={
              <LegacyLabPageWrapper Component={PartnerLeadersContainer} />
            }
          />

          <Route
            path="/browse/master_teachers/projects"
            element={
              <LegacyLabPageWrapper
                Component={MasterTeacherProject}
                store={StaticSiteStore}
              />
            }
          />

          {[
            '/browse/master_teachers',
            '/browse/master_teachers/:subject',
            '/browse/master_teachers/:subject/:grade',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={MasterTeachersDirectory}
                  store={StaticSiteStore}
                />
              }
            />
          ))}

          {[
            '/browse/master_teacher/:userId',
            '/browse/master_teacher/:userId/about',
            '/browse/master_teacher/:userId/:seoUrl',
            '/browse/master_teacher/:userId/:seoUrl/:tab',
            '/browse/master_teacher/:userId/:courseId/:seoUrl',
            '/browse/master_teacher/:userId/:courseId/:seoUrl/curriculum',
            '/browse/master_teacher/:userId/:courseId/:unitId',
            '/browse/master_teacher/:userId/:courseId/:unitId/:seoUrl',
            '/browse/master_teacher/:userId/:courseId/:unitId/:seoUrl/curriculum',
            '/browse/master_teacher/:userId/strategies',
            '/browse/master_teacher/:userId/:seoUrl/strategies',
            '/browse/master_teacher/:userId/:seoUrl/strategies/:domain',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={MasterTeacherProfile}
                  store={MasterTeacherProfileStore}
                />
              }
            />
          ))}

          <Route
            path="/browse/master_teacher/:userId/:courseId"
            element={
              <LegacyLabPageWrapper
                Component={MasterTeacherProfile}
                store={StaticSiteStore}
              />
            }
          />

          {[
            '/lesson/:lessonId',
            '/lesson/:lessonId/:seoUrl',
            '/lesson/resource/:resourceId',
            '/lesson/resource/:resourceId/:seoUrl',
            '/lesson/resource/:resourceId/:shorcutId/:seoUrl',
            '/lesson/reflection/:reflectionId',
            '/lesson/reflection/:reflectionId/:seoUrl',
            '/lesson/section/:sectionId',
            '/lesson/section/:sectionId/:seoUrl',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper Component={Lesson} store={LessonStore} />
              }
            />
          ))}

          {[
            '/browse/blended_video/:strategyId',
            '/browse/blended_video/:strategyId/:seoUrl',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={BlendedStrategy}
                  store={BlendedStrategyStore}
                />
              }
            />
          ))}

          {[
            '/browse/blended_topic/:topicId',
            '/browse/blended_topic/:topicId/:seoUrl',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={BlendedBrowser}
                  store={BrowserStore}
                />
              }
            />
          ))}

          {[
            '/browse/common_core',
            '/browse/common_core_math',
            '/browse/common_core_ela',
            '/browse/next_gen_science',
            '/browse/blended',
            '/browse/common_core/standard/:standardId/',
            '/browse/common_core/standard/:standardId/:seoUrl',
            '/browse/next_gen_science/standard/:standardId/',
            '/browse/next_gen_science/standard/:standardId/:seoUrl',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={CommonCore}
                  store={BrowserStore}
                />
              }
            />
          ))}

          {[
            '/coaching/lab/participant/:participantId/vision',
            '/coaching/lab/participant/:participantId/vision/artifact/:artifactVisionId',
            '/coaching/lab/participant/:participantId/vision/entry/:entryId',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LegacyLabPageWrapper
                  Component={ParticipantVision}
                  store={ParticipantVisionStore}
                />
              }
            />
          ))}

          <Route
            path="/coaching/lab/participant/:participantId/portfolio"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantPortfolioManagementHome}
                store={ParticipantPortfolioManagementStore}
              />
            }
          />

          <Route
            path="/coaching/lab/participant/:participantId/portfolio/vision"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantPortfolioVisionManagement}
                store={ParticipantPortfolioManagementStore}
              />
            }
          />

          <Route
            path="/coaching/lab/participant/:participantId/portfolio/outcome/:participantOutcomeId"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantPortfolioOutcomeStoryManagement}
                store={ParticipantPortfolioManagementStore}
              />
            }
          />

          <Route
            path="/coaching/lab/participant/portfolio/:portfolioUUID"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantPortfolioPresentationHome}
                store={ParticipantPortfolioPresentationStore}
              />
            }
          />

          <Route
            path="/coaching/lab/participant/portfolio/:portfolioUUID/vision"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantPortfolioPresentationVision}
                store={ParticipantPortfolioPresentationStore}
              />
            }
          />

          <Route
            path="/coaching/lab/participant/portfolio/:portfolioUUID/outcome/:participantOutcomeId"
            element={
              <LegacyLabPageWrapper
                Component={ParticipantPortfolioPresentationOutcomeStory}
                store={ParticipantPortfolioPresentationStore}
              />
            }
          />

          <Route
            path="/404"
            element={<LegacyLabPageWrapper Component={Code404} />}
          />
        </Route>

        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <ToastContainer
        autoClose={6000}
        hideProgressBar
        limit={3}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        position={'top-center'}
        transition={Zoom}
      />
    </BrowserRouter>
  );
}

export default App;
