// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import PropTypes from 'prop-types';
import React from 'react';
import Utils from '../../../../components/DynamicUtils';

const Material = (props) => {
  const lab = useLegacyLabContext();

  const findSessionParticipant = (session_participant) => {
    return session_participant.session_id === props.session.id;
  };

  // Find the session participant for the current session
  const sessionParticipant =
    props.participant &&
    props.participant.event_session_participants &&
    props.participant.event_session_participants.find(findSessionParticipant);

  const isToday = () => {
    const now = new Date(Date.now());
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const formatted = [year, month, day].join('-');
    return props.session.session_date_formatted === formatted;
  };

  const facilitatorCanView = () =>
    props.session.isFacilitatorForEventSession && isToday();
  const participantCanView = () =>
    lab.pageData.user.auth.isParticipant &&
    props.session.isLearningWalkCreated &&
    isToday();
  const canDisplayObservation = () =>
    (facilitatorCanView() || participantCanView()) &&
    props.session.canLearningWalkBeCreated;

  const materialsNode = props.materials.map((material) => {
    let link = null;
    if (material.title === 'Agenda') {
      if (!props.session.agenda_link) {
        return null;
      }
      link = props.session.agenda_link;
    } else if (material.title === 'Presentation Deck') {
      if (!props.session.slide_deck_link) {
        return null;
      }
      link = props.session.slide_deck_link;
      // Id 3 correspsonds to a survey link, check to make sure user can take the survey
    } else if (material.id === 3) {
      const hasSurveyServiceEventSessionSurvey =
        sessionParticipant &&
        sessionParticipant.post_session_survey &&
        !sessionParticipant.post_session_survey.completed;

      if (!hasSurveyServiceEventSessionSurvey) {
        return null;
      }

      link = `${process.env.REACT_APP_SURVEY_URL}/outstanding`;
      // Id 4 correspsonds to a completed survey indicator
    } else if (material.id === 4) {
      if (
        !sessionParticipant ||
        !sessionParticipant.session_survey ||
        !sessionParticipant.session_survey.survey_completed ||
        Utils.hasFeatureFlag.call(lab, 'OUTSTANDING_SURVEYS_CTA')
      ) {
        return null;
      }
    } else if (material.id === 5) {
      if (!canDisplayObservation()) return;
      link = `${process.env.REACT_APP_LEARNINGWALK_URL}/event-session/${props.session.id}`;
    } else if (material.id === 6) {
      if (
        !props.session.canLearningWalkBeCreated ||
        !props.session.isLearningWalkCreated
      )
        return;
      link = props.session.session_url + '/classroom-report';
    }

    const sessionTitle =
      props.sessionsLength > 1 &&
      props.session.name &&
      props.session.name !== ''
        ? `: ${props.session.name}`
        : '';

    const button = material.buttonCopy && (
      <div className="event__attendee-material-button">
        <a
          href={link}
          target="_blank"
          className="v5__btn v5__btn--blue"
          rel="noreferrer"
        >
          {material.buttonCopy}
        </a>
      </div>
    );

    return (
      <div
        className="event__attendee-material v5__capsule v5__capsule--margin-bottom-30"
        key={`material-${props.session.id}-${material.id}`}
      >
        <div className="event__attendee-material-container">
          <img
            className="event__attendee-material-icon"
            src={`/legacy-images/v5/event-session/${material.image}`}
            alt={material.title}
          />
        </div>
        <div className="event__attendee-material-content">
          <h5 className="event__attendee-material-headline v5__heading-4">
            {material.title}
            {sessionTitle}
          </h5>
          <p className="event__attendee-material-copy v5__body-copy">
            {material.copy}
          </p>
        </div>
        {button}
      </div>
    );
  });

  // display headline if there are any visible materials capsules
  let headlineNode;

  if (materialsNode.some((node) => !!node)) {
    headlineNode = (
      <h3 className="event__attendee-section-headline v5__heading-3">
        Event Materials
      </h3>
    );
  }

  return (
    <React.Fragment>
      {headlineNode}
      {materialsNode}
    </React.Fragment>
  );
};

export default Material;

Material.propTypes = {
  materials: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired,
  participant: PropTypes.object,
};
