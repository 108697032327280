// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';
import React, { useState } from 'react';

const sortSelectedAvailabilities = (a, b) => (a.label > b.label ? 1 : -1);

const Availability = (props) => {
  const lab = useLegacyLabContext();

  const orderSelectedOptions = (selectedOptions) => {
    const amOneCharOptions = [];
    const amTwoCharOptions = [];
    const pmOneCharOptions = [];
    const pmTwoCharOptions = [];
    selectedOptions.forEach((option) => {
      const splitOptionLabel = option.label.split(' ');
      // break out am options
      if (splitOptionLabel[1] === 'AM') {
        // sort options that have two characters seperatly so 11 does not go before 2
        if (splitOptionLabel[0].length > 1) {
          amTwoCharOptions.push(option);
          return;
        }
        amOneCharOptions.push(option);
        return;
      }
      // break out pm options
      // sort options that have two characters seperatly so 11 does not go before 2
      if (splitOptionLabel[0].length > 1) {
        pmTwoCharOptions.push(option);
        return;
      }
      pmOneCharOptions.push(option);
    });

    // sort arrays
    [
      amOneCharOptions,
      amTwoCharOptions,
      pmOneCharOptions,
      pmTwoCharOptions,
    ].forEach((options) => options.sort(sortSelectedAvailabilities));

    // recombine arrays
    return amOneCharOptions.concat(
      amTwoCharOptions,
      pmOneCharOptions,
      pmTwoCharOptions
    );
  };

  const [availabilities, setAvailabilities] = useState({
    inputStatus: 'blur',
    charCount: 0,
    options: props.availabilityOptions,
    dropdownOptions: [],
    selectedOptions: orderSelectedOptions(props.selectedAvailabilities) || [],
    // selectedOptions: props.selectedAvailabilities || [],
    visibleOptions: [],
  });

  const updateSelectedAvailabilities = (data) => {
    const localAvailabilities = Utils.clone(availabilities);
    const selectedOptionIndex = Utils.getItemIndexFromArrayOfObjects(
      localAvailabilities.selectedOptions,
      'id',
      data.id
    );
    if (selectedOptionIndex === -1) {
      localAvailabilities.selectedOptions.push(data);
    } else {
      localAvailabilities.selectedOptions.splice(selectedOptionIndex, 1);
    }

    // cronilogical ordering
    const orderedOptions = orderSelectedOptions(
      localAvailabilities.selectedOptions
    );
    localAvailabilities.selectedOptions = orderedOptions;

    // update the multiselect
    setAvailabilities(localAvailabilities);

    // setup abandon message
    props.setUnSavedChanges(true);

    // update form container
    props.setSelectedAvailabilities(orderedOptions);
  };

  const filterAvailabilities = (data) => {
    const localAvailabilities = Utils.clone(availabilities);
    localAvailabilities.charCount = data.input.length;

    // only show options that contain the input text
    localAvailabilities.visibleOptions = localAvailabilities.options.filter(
      (option) => {
        return (
          option.label.toLowerCase().indexOf(data.input.toLowerCase()) !== -1
        );
      }
    );

    setAvailabilities(localAvailabilities);
  };

  const focusAvailabilities = () => {
    const localAvailabilities = Utils.clone(availabilities);
    localAvailabilities.dropdownOptions =
      localAvailabilities.visibleOptions.length > 0
        ? localAvailabilities.visibleOptions
        : localAvailabilities.options;

    setAvailabilities(localAvailabilities);
  };

  let availabilityNode = null;
  if (Utils.isContractCoach.call(lab, props.pageUser)) {
    availabilityNode = (
      <React.Fragment>
        <h3
          className="account-form__header tc__headline-4"
          id="section-profile"
        >
          Availability
        </h3>
        <div className="account-about__availability-no-tz">
          <div className="tc-v5-alert" />
          <div>
            You must{' '}
            <NavigationLink
              className="v5__link"
              url={`/account/${props.pageUser.id}?scrollTo=timezone`}
            >
              set your time zone
            </NavigationLink>{' '}
            before you can choose your availability
          </div>
          .
        </div>
      </React.Fragment>
    );

    if (props.pageUser.time_zone_id) {
      availabilityNode = (
        <React.Fragment>
          <h3
            className="account-form__header tc__headline-4"
            id="section-profile"
          >
            Availability
          </h3>
          <div className="settings__label">
            Tell us your availability in your local timezone.
          </div>
          <div className="form-field" data-testid="availability-field">
            <MultiSelect
              {...availabilities}
              field="availability"
              feature="aboutMeSettings"
              minCharCountForResults={0}
              getVisibleOptions={filterAvailabilities}
              analyticsLabel={''}
              handleSelectOption={updateSelectedAvailabilities}
              handleFocus={focusAvailabilities}
              preventAutoFocus={true}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  return <React.Fragment>{availabilityNode}</React.Fragment>;
};

export default Availability;
