// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import { useEffect } from 'react';

const Unauthorized = (props) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_LAB_URL}/home`;
    }, 10000);
  }, []);

  return (
    <div className="code-404">
      <Header {...props} page="StrategyBrowser" />

      <div className="row">
        <div className="cute-12-phone">
          <div className="unauthorized-container">
            <img
              src="/legacy-images/unauthorized.png"
              className="code-404__img"
            />

            <span>
              <h1 className="v5__heading-3">
                <strong>Ni! None Shall Pass!</strong>
              </h1>

              <p className="v5__body-copy">
                We&rsquo;re sorry, but you don&rsquo;t have access to this page.
                Try visiting your{' '}
                <a
                  href={`${process.env.REACT_APP_LAB_URL}/lab/home`}
                  className="unauthorized-link"
                >
                  home page
                </a>
                , our{' '}
                <a
                  href={`${process.env.REACT_APP_TEACHING_URL}/search`}
                  className="unauthorized-link"
                >
                  strategies & lesson plans
                </a>
                , or our{' '}
                <a
                  href="https://blog.betterlesson.com"
                  className="unauthorized-link"
                >
                  blog
                </a>
                . If you think you&rsquo;re seeing this message in error, please{' '}
                <a
                  href="https://betterlesson.atlassian.net/servicedesk/customer/portal/2/group/4"
                  className="unauthorized-link"
                >
                  contact us
                </a>
                .
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
