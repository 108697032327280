// @ts-nocheck FIXME
import PubSub from 'pubsub-js';
import Utils from './DynamicUtils';

const Action = {
  dispatch: (msg, data) => {
    return PubSub.publish(msg, Utils.clone(data));
  },

  dispatchSync: (msg, data) => {
    return PubSub.publishSync(msg, Utils.clone(data));
  },

  create: (msg, callback) => {
    return PubSub.subscribe(msg, (msgInner, data) => {
      callback(data);
    });
  },

  destroy: (token) => {
    return PubSub.unsubscribe(token);
  },
};

export default Action;
