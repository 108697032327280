// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import React from 'react';
import AbridgeText from './../../../Common/AbridgeText';

const CompletedGoalEntry = (props) => {
  const textNode = props.isWYSIWYG ? (
    <PurifiedHTML
      className="ckeditor__output v5__body-copy"
      content={props.description}
    />
  ) : (
    <div className="v5__body-copy">{props.description}</div>
  );

  const reflectionNodes = props.note ? (
    <React.Fragment>
      <div className="workspace__entry-reflection-label">Growth Reflection</div>
      <div className="workspace__entry-row v5__body-copy">
        <AbridgeText
          maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
          isExpandable={true}
          greyFade={props.greyFade}
        >
          {props.note}
        </AbridgeText>
      </div>
    </React.Fragment>
  ) : null;

  return (
    <div className="workspace__entry-body">
      <div className="workspace__entry-row">
        <AbridgeText
          maxHeight={Utils.WORKSPACE_ABRIDGED_TEXT_HEIGHT}
          isExpandable={true}
          greyFade={props.greyFade}
        >
          {textNode}
        </AbridgeText>
      </div>
      {reflectionNodes}
    </div>
  );
};

CompletedGoalEntry.propTypes = {
  description: PropTypes.string.isRequired,
  note: PropTypes.string,
  isWYSIWYG: PropTypes.bool,
  greyFade: PropTypes.bool,
};

export default CompletedGoalEntry;
