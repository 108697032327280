// @ts-nocheck FIXME
import Request from '../../components/Request';

const CoachSurveysApi = {
  getSessionSurveys: (data) => {
    return Request.get(
      `${process.env.REACT_APP_LAB_SERVICE_URL}/coach/${data.coachId}/surveys?days=${data.days}`
    );
  },
};

export default CoachSurveysApi;
