// @ts-nocheck FIXME
import { delayedEventListener } from '@utils/EventListenerHandler';
import Analytics from './../components/Analytics';
import Utils from './../components/DynamicUtils';
import StorageHelper from './../components/StorageHelper';
import Store from './../components/Store';

const freezeWindowAsNecessary = () => {
  if (window.matchMedia('(max-width: 48em)').matches) {
    // store the position, in case we need to scroll to it
    store.scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;

    // disable page scrolling if mobile navigation is opened
    Utils.addClass(document.body, 'no-scroll');
  }
};
const unfreezeWindowAsNecessary = () => {
  if (window.matchMedia('(max-width: 48em)').matches) {
    Utils.removeClass(document.body, 'no-scroll');

    window.scrollTo(0, store.scrollPosition);
  }
};

const closeDropdownOnDesktopClick = () => {
  if (!window.matchMedia('(max-width: 48em)').matches) {
    store.actionDispatch('closeDropdown');
  }
};

const store = Store('HeaderStore', {
  user: null,
  responsiveLayout: false,
  scrollPosition: null,

  dropDownOverflows: false,

  headerParticipant: null,
  handleTeacherViewSubmit: null,
  ui: {
    teacherView: true,
    teacherViewModalState: false,
    notificationLoadingSet: false,
    dropdownOpened: false,
  },
});

// ACTIONS
store.actionCreate('init', function (data) {
  store.user = data.user;

  store.handleTeacherViewSubmit = data.handleTeacherViewSubmit;

  const teacherViewState = StorageHelper.getItem('teacherViewState');
  store.ui.teacherView = true;
  if (!teacherViewState) {
    store.ui.teacherView = false;
  }

  const teacherViewModalState = StorageHelper.getItem('teacherViewModalState');
  if (teacherViewModalState == true) {
    store.ui.teacherViewModalState = true;
  }

  let headerParticipantName = StorageHelper.getItem('headerParticipantName');
  let headerParticipantId = StorageHelper.getItem('headerParticipantId');
  let headerParticipantCoachUserId = StorageHelper.getItem(
    'headerParticipantCoachUserId'
  );
  let headerParticipantOutcomeTitle = StorageHelper.getItem(
    'headerParticipantOutcomeTitle'
  );
  let headerParticipantOutcomeId = StorageHelper.getItem(
    'headerParticipantOutcomeId'
  );

  // if we don't have a participant clear it out
  if (!data.keepHeaderParticipant && !data.headerParticipant) {
    StorageHelper.removeItem('headerParticipantName');
    StorageHelper.removeItem('headerParticipantId');
    StorageHelper.removeItem('headerParticipantCoachUserId');
    StorageHelper.removeItem('headerParticipantOutcomeId');
    StorageHelper.removeItem('headerParticipantOutcomeTitle');
    store.headerParticipant = null;
  } else if (data.headerParticipant) {
    // if participant changed clear out old participant outcome
    if (headerParticipantId != data.headerParticipant.id) {
      StorageHelper.removeItem('headerParticipantOutcomeId');
      StorageHelper.removeItem('headerParticipantOutcomeTitle');
      headerParticipantOutcomeId = null;
      headerParticipantOutcomeTitle = null;
    }

    if (data.headerParticipant.name) {
      headerParticipantName = data.headerParticipant.name;
    }

    if (data.headerParticipant.id) {
      headerParticipantId = data.headerParticipant.id;
    }

    if (data.headerParticipant.coach_user_id) {
      headerParticipantCoachUserId = data.headerParticipant.coach_user_id;
    }

    if (
      !headerParticipantOutcomeTitle &&
      !Utils.isEmpty(data.headerParticipant.default_outcome)
    ) {
      headerParticipantOutcomeId =
        data.headerParticipant.default_outcome.participant_outcome_id;
      headerParticipantOutcomeTitle =
        data.headerParticipant.default_outcome.title;
    }

    if (data.headerParticipant.current_outcome) {
      headerParticipantOutcomeId =
        data.headerParticipant.current_outcome.participant_outcome_id;
      headerParticipantOutcomeTitle =
        data.headerParticipant.current_outcome.title;
    }

    if (headerParticipantId) {
      StorageHelper.setItem('headerParticipantId', headerParticipantId);
    }

    if (headerParticipantCoachUserId) {
      StorageHelper.setItem(
        'headerParticipantCoachUserId',
        headerParticipantCoachUserId
      );
    }

    if (headerParticipantName) {
      StorageHelper.setItem('headerParticipantName', headerParticipantName);
    }

    if (headerParticipantOutcomeId) {
      StorageHelper.setItem(
        'headerParticipantOutcomeId',
        headerParticipantOutcomeId
      );
    }

    if (headerParticipantOutcomeTitle) {
      StorageHelper.setItem(
        'headerParticipantOutcomeTitle',
        headerParticipantOutcomeTitle
      );
    }

    if (headerParticipantId) {
      store.headerParticipant = {
        id: headerParticipantId,
        name: headerParticipantName,
        coach_user_id: headerParticipantCoachUserId,
        outcomeId: headerParticipantOutcomeId,
        outcomeTitle: headerParticipantOutcomeTitle,
      };
    }
  }
});

store.actionCreate('updateDropdownOverflow', function (needsOverflow) {
  store.dropDownOverflows = needsOverflow;
});

// set mobile view // check if we can move this in to container componenent
store.actionCreate('setMobileView', function () {
  // dissable page scrolling if mobile navigation is opened
  Utils.addClass(document.body, 'no-scroll');
});
// set desktop view // check if we can move this in to container componenent
store.actionCreate('setDesktopView', function (position) {
  // reenable page scroll
  Utils.removeClass(document.body, 'no-scroll');
});

/****** Dropdown ******/
store.actionCreate('openDropdown', function () {
  delayedEventListener(() =>
    document.addEventListener('click', closeDropdownOnDesktopClick)
  );

  // in mobile
  freezeWindowAsNecessary();
  store.ui.dropdownOpened = true;
});
store.actionCreate('closeDropdown', function () {
  document.removeEventListener('click', closeDropdownOnDesktopClick);
  unfreezeWindowAsNecessary();
  store.ui.dropdownOpened = false;
});

/****** Teacher Coach View Toggle Element ******/

store.actionCreate('openTeacherViewModal', function () {
  StorageHelper.setItem('teacherViewModalState', true);
  store.ui.teacherViewModalState = true;
  Analytics.logEvent({
    category: 'navigation_widget',
    action: 'expand',
    label: 'from_coach_navigation_widget',
  });
});

store.actionCreate('closeTeacherViewModal', function () {
  StorageHelper.setItem('teacherViewModalState', false);
  store.ui.teacherViewModalState = false;
  Analytics.logEvent({
    category: 'navigation_widget',
    action: 'collapse',
    label: 'from_coach_navigation_widget',
  });
});

store.actionCreate('submitTeacherViewModal', function (data) {
  const teacherViewState = data == false ? false : true;
  StorageHelper.setItem('teacherViewState', teacherViewState);

  if (store.handleTeacherViewSubmit) {
    store.handleTeacherViewSubmit(data);
  }
  const actionTarget = !data ? 'coach' : 'participant';
  Analytics.logEvent({
    category: 'toggle',
    action: 'set_to_' + actionTarget,
    label: 'from_navigation_widget',
  });

  store.ui.teacherView = data;
});

store.actionCreate('updateProfileImage', (data) => {
  store.user.photo = data.photo;
});

export default store;
