// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import ScaleCenterImage from './../../../../ScaleCenterImage';

const FeedbackRow = (props) => {
  let message;

  // convenience var
  const feedback = props.feedback;

  // handle setting the feedback to edit mode
  const handleEditMode = () => {
    props.setFeedbackEditMode(feedback.step);
  };

  // handle updating the feedback
  const handleFeedbackChanges = (e) => {
    e.preventDefault();
    props.updateFeedback({
      loopId: props.loopId,
      step: feedback.step,
      message: message.value,
    });
  };

  // hide/show the feedback's comments
  const handleShowComments = () => {
    props.showFeedbackComments(feedback.step);
  };

  // hide/show the feedback's comment in the portfolio
  const handleToggleCommentVisible = (commentId) => {
    props.toggleFeedbackCommentVisibility({
      loopId: props.loopId,
      step: feedback.step,
      id: commentId,
    });
  };

  // by default, show the feedback text
  const feedbackMessage = feedback.message ? (
    feedback.message
  ) : (
    <span className="portfolio-edit__empty-placeholder">
      No reflection added
    </span>
  );
  let messageNode = (
    <div className="portfolio-edit__feedback-text">
      {feedbackMessage}
      <div className="tc__link" onClick={handleEditMode}>
        Edit
      </div>
    </div>
  );
  // in edit mode, show the message in a textarea
  if (props.inEditMode) {
    messageNode = (
      <div className="portfolio-edit__feedback-text">
        <textarea
          className="tc__input portfolio-edit__substep-textarea"
          defaultValue={feedback.message}
          ref={(ref) => (message = ref)}
          onChange={handleFeedbackChanges}
        />
      </div>
    );
  }

  // build the comments section
  let commentsNode = null;
  if (feedback.comment_count) {
    // set up the text for the comment count
    let selectedCommentCount = 0;
    feedback.comments &&
      feedback.comments.forEach((comment) => {
        if (comment.visible) {
          selectedCommentCount += 1;
        }
      });
    const commentCaption =
      selectedCommentCount +
      ' of ' +
      feedback.comment_count +
      ' Comments Selected';

    // by default, just show the # of comments
    commentsNode = (
      <div className="cute-11-desktop right portfolio-edit__tml-comments">
        <div onClick={handleShowComments}>
          <div className={'portfolio-edit__comment-icon tc-comment'}></div>
          <div className={'portfolio-edit__comment-icon-caption'}>
            {commentCaption}
          </div>
        </div>
      </div>
    );
    // after clicking on the #, we show the actual list of comments
    if (props.commentsExpanded) {
      commentsNode = feedback.comments.map((comment) => {
        // grey things out if the comment is not visible
        let commentVisibilityIconClass = '';
        let commentClass = '';
        if (!comment.visible) {
          commentVisibilityIconClass =
            ' portfolio-edit__strategy-visible-icon--grey';
          commentClass = ' portfolio-edit__tml-comment--grey';
        }

        // show the default user image if the commenter has no photo
        const commenterImg = comment.photo
          ? comment.photo
          : '/legacy-images/default-user.svg';

        return (
          <div key={comment.id} className={'row' + commentClass}>
            <div className="cute-1-desktop portfolio-edit__strategy-capsule-icon-col">
              <span
                onClick={(e) => handleToggleCommentVisible(comment.id)}
                className={
                  'tc-check portfolio-edit__strategy-visible-icon' +
                  commentVisibilityIconClass
                }
              ></span>
            </div>
            <div className="cute-1-desktop">
              <ScaleCenterImage
                src={commenterImg}
                className="portfolio-edit__tml-commenter-image"
              />
            </div>
            <div className="cute-10-desktop left">
              <div className="portfolio-edit__tml-commenter">
                {comment.first_name} {comment.last_name}
              </div>
              <div className="portfolio-edit__tml-comment-text tc__body-copy">
                {comment.data}
              </div>
            </div>
          </div>
        );
      });
      commentsNode = (
        <div className="cute-11-desktop right portfolio-edit__tml-comments">
          {commentsNode}
        </div>
      );
    }
  }

  return (
    <div className="row">
      <div
        className={
          'cute-1-desktop portfolio-edit__feedback-step portfolio-edit__feedback-step--' +
          feedback.step
        }
      >
        <div
          className={
            'portfolio-edit__feedback-step-icon tc-tml-' + feedback.step
          }
        ></div>
        <div className="portfolio-edit__feedback-step-name">
          {feedback.step}
        </div>
      </div>
      <div className="cute-11-desktop tc__body-copy">
        <div className="portfolio-edit__feedback-question">
          {feedback.question}
        </div>
        {messageNode}
      </div>
      {commentsNode}
    </div>
  );
};

FeedbackRow.propTypes = {
  feedback: PropTypes.object.isRequired,
  loopId: PropTypes.number.isRequired,
  setFeedbackEditMode: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  showFeedbackComments: PropTypes.func.isRequired,
  commentsExpanded: PropTypes.bool.isRequired,
  toggleFeedbackCommentVisibility: PropTypes.func.isRequired,
};

export default FeedbackRow;
