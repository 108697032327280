// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import FreezeWindowScroll from './../../Common/FreezeWindowScroll';
import MultiSelect from './../../Common/MultiSelect/MultiSelect';
import Spinner from './../../Common/Spinner';

const PartnerDP = (props) => {
  let errorAlertNode = null;
  if (props.errors && props.errors.api) {
    errorAlertNode = (
      <div className="outcome-story__modal-error-alert-row">
        <span className="outcome-story__modal-error-alert">
          {props.errors.api}
        </span>
      </div>
    );
  }

  const handleCloseDP = (e) => {
    e.preventDefault();
    props.close();
  };

  const handleSubmitDP = (e) => {
    e.preventDefault();
    props.submitEntry(props);
  };

  // Handle loading state
  const disabledClass = props.loading ? ' disabled' : '';

  return (
    <div>
      <div
        className="modal__page-overlay modal__page-overlay--grey"
        onClick={handleCloseDP}
      ></div>
      <div className="modal--fixed modal--grey-overlay ppd-v3-content-discovery-modal ppd-v3-content-discovery-modal--custom-strategy">
        <div
          className="modal__close hide-phone show-desktop"
          onClick={handleCloseDP}
        >
          <div className="tc-x"></div>
        </div>

        <div className="tc__mobile-header show-phone hide-desktop">
          <div className="tc__back cute-2-desktop left" onClick={handleCloseDP}>
            <div className="tc__back-arrow tc-arrow"></div>
            <div className="tc__back-caption">Back</div>
          </div>
          <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
            Add a DP
          </div>
        </div>

        <div className="outcome-story__modal-body">
          <div className="outcome-story__modal-headline hide-phone show-desktop">
            Add a DP
          </div>
          <form className="outcome-story__modal-form">
            <MultiSelect
              {...props.dps}
              field="dps"
              placeholder="Select an option"
              onChangeDelay={700}
              minCharCountForResults={0}
              containerElementSelector=".modal--fixed"
              analyticsLabel="from_add_strategy_modal_outcome_story"
              updateMultiSelect={props.updateSelect}
              getVisibleOptions={props.getVisibleOptions}
            />

            <div className="outcome-story__modal-btns">
              {errorAlertNode}
              <button
                onClick={handleSubmitDP}
                className={
                  'tc__btn tc__btn--flat-blue outcome-story__modal-btn' +
                  disabledClass
                }
              >
                <Spinner loading={props.loading} />
                Save
              </button>
              <button
                onClick={handleCloseDP}
                className="tc__btn tc__btn--flat-grey outcome-story__modal-btn"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      <FreezeWindowScroll parentModal="ppd-v3-content-discovery-modal--custom-strategy" />
    </div>
  );
};

PartnerDP.propTypes = {
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  submitEntry: PropTypes.func.isRequired,
};

export default PartnerDP;
