// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import moment from 'moment-timezone';

const CohortDetails = (props) => {
  const startDate =
    props.cohort &&
    moment(props.cohort.term_start_formatted)
      .tz('US/Eastern')
      .format('MMM DD, YYYY');
  const endDate =
    props.cohort &&
    moment(props.cohort.target_end_date_formatted)
      .tz('US/Eastern')
      .format('MMM DD, YYYY');

  return (
    <div>
      <div className="registration-v5-event__headline v5__heading-1">
        BetterLesson Coaching for {props.cohort && props.cohort.display_name}
      </div>
      <div className="registration-v5-event__start v5__heading-4">
        {props.cohort &&
        props.cohort.term_start_formatted &&
        props.cohort.target_end_date_formatted
          ? `${startDate} - ${endDate}`
          : null}
      </div>
      <PurifiedHTML
        className="registration-v5-event__description"
        content={
          (props.cohort && props.cohort.display_description) ||
          'Work one-on-one with an expert coach to increase your impact as an educator. Identify your goals, implement and test strategies, and reflect on results.'
        }
      />
    </div>
  );
};

export default CohortDetails;
