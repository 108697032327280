// @ts-nocheck FIXME
import Auth from '@legacy-components/components/Auth';
import Content from '@legacy-components/components/Content';
import Utils from '@legacy-components/components/DynamicUtils';
import { Router } from '@legacy-components/components/Router';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';
import BrowserStore from './../../../stores/V2/BrowserStore';
import NavigationLink from './../../NavigationLink';
import ScaleCenterImage from './../../ScaleCenterImage';
import StrategyNotes from './StrategyNotes';
import StrategyPin from './StrategyPin';

class WideStrategyCapsule extends React.Component {
  state = {
    viablePreview: false,
  };

  componentMounted = false;

  componentDidMount() {
    this.componentMounted = true;
    // check for a preview image
    Content.checkForPreview(this, this.props.strategy.preview);
  }

  componentWillUnmount() {
    this.componentMounted = false;
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Router.route(this.props.url);
  };

  handleShowAlert = (type, message) => {
    BrowserStore.actionDispatchSync('setAlert', {
      alert: message,
      alertType: type,
    });
  };

  getBreadcrumbsNode = () => {
    if (this.props.strategy.subject && this.props.breadcrumbsStart) {
      // find the strategy's subject and set up url bit to link back to the correct browser tab
      const currentSubject = this.props.categories.filter(function (category) {
        return category.name === this.props.strategy.subject;
      }, this);
      // if we don't find a matching subject, default to using the strategy's subject name
      const subjectUrlBit = currentSubject.length
        ? currentSubject[0].seo_url
        : this.props.strategy.subject.toLowerCase().replace(' ', '-');
      // build links
      const breadcrumbsInner = [];
      // Indicates to Analytics where the request is coming from
      const analyticsFrom = 'sb_search_capsule';

      switch (this.props.breadcrumbsStart) {
        case 'subject':
          breadcrumbsInner.push(
            <div
              key={'crumbs-subject'}
              className="wide-capsule-v2__crumb"
              title={this.props.strategy.subject}
            >
              <NavigationLink
                className="tc__link"
                url={
                  '/coaching/lab/strategy-browser-' +
                  subjectUrlBit +
                  '?from=' +
                  analyticsFrom
                }
              >
                {this.props.strategy.subject}
              </NavigationLink>
            </div>
          );
          breadcrumbsInner.push(
            <div
              key={'crumbs-separator-subject'}
              className="wide-capsule-v2__crumb"
            >
              {'>'}
            </div>
          );
          break;
        case 'sga':
          breadcrumbsInner.push(
            <div
              key={'crumbs-sga'}
              className="wide-capsule-v2__crumb"
              title={this.props.strategy.sga}
            >
              <NavigationLink
                className="tc__link"
                url={
                  '/coaching/lab/strategy-browser-' +
                  subjectUrlBit +
                  '?from=' +
                  analyticsFrom
                }
              >
                {this.props.strategy.sga}
              </NavigationLink>
            </div>
          );
          breadcrumbsInner.push(
            <div
              key={'crumbs-separator-sga'}
              className="wide-capsule-v2__crumb"
            >
              {'>'}
            </div>
          );
          break;
        case 'challenge':
          breadcrumbsInner.push(
            <div
              key={'crumbs-challenge'}
              className="wide-capsule-v2__crumb"
              title={this.props.strategy.teaching_challenge}
            >
              <NavigationLink
                className="tc__link"
                url={
                  '/coaching/lab/strategy-browser/teaching-challenge/' +
                  this.props.strategy.teaching_challenge_id +
                  '/' +
                  this.props.strategy.teaching_challenge_seo_url +
                  '?from=' +
                  analyticsFrom
                }
              >
                {this.props.strategy.teaching_challenge}
              </NavigationLink>
            </div>
          );
          // If there is a meta strategy id, add it to the breadcrumb:
          if (this.props.strategy.meta_strategy_id) {
            breadcrumbsInner.push(
              <div
                key={'crumbs-separator-meta-strategy'}
                className="wide-capsule-v2__crumb"
              >
                {'>'}
              </div>
            );
            breadcrumbsInner.push(
              <div
                key={'crumbs-challenge-meta-strategies'}
                className="wide-capsule-v2__crumb"
                title={this.props.strategy.meta_strategy_name}
              >
                <NavigationLink
                  className="tc__link"
                  url={
                    '/coaching/lab/strategy-browser/teaching-challenge/' +
                    this.props.strategy.teaching_challenge_id +
                    '/' +
                    this.props.strategy.teaching_challenge_seo_url +
                    '/meta-strategy/' +
                    this.props.strategy.meta_strategy_id +
                    '?from=' +
                    analyticsFrom
                  }
                >
                  {this.props.strategy.meta_strategy_name}
                </NavigationLink>
              </div>
            );
          }
      }

      return (
        <div className="cute-12-desktop wide-capsule-v2__crumbs">
          {breadcrumbsInner}
        </div>
      );
    }
  };

  getStrategyPinNode = () => {
    return (
      <StrategyPin
        strategy={this.props.strategy}
        user={this.props.user}
        analyticsTrackingLabel={this.props.analyticsTrackingLabel}
        handleShowAlert={this.handleShowAlert}
        classNamePrefix="wide-capsule"
        mobileCssClassName=" tc__link "
      />
    );
  };

  getStrategyNotesNode = () => {
    return (
      <StrategyNotes
        strategy={this.props.strategy}
        user={this.props.user}
        analyticsTrackingLabel={this.props.analyticsTrackingLabel}
        handleAddNote={this.handleAddNote}
        classNamePrefix="wide-capsule"
        canAddNote={Auth.canEditData(this.props.user)}
      />
    );
  };

  handleAddNote = (note) => {
    BrowserStore.actionDispatchSync('addNote', {
      strategyId: this.props.strategy.id,
      note: note,
    });
  };

  getGradesNode = () => {
    const gradeNames = this.props.strategy.grade_names;
    if (gradeNames.length > 0) {
      return (
        <div className="wide-capsule-v2__detail titleCase show-desktop hide-phone">
          GRADE LEVEL: {gradeNames.join(', ')}
        </div>
      );
    }
    return null;
  };

  render() {
    // Do we have a meta strategy?
    const metaStrategySelected = this.props.strategy.meta_strategy_id
      ? true
      : false;

    let wideCapsuleClassName = 'tc__capsule tc__capsule--hoverable ';
    wideCapsuleClassName += metaStrategySelected
      ? 'id-wide-capsule-v2'
      : 'wide-capsule-v2';
    let wideCapsuleTextClassName = metaStrategySelected
      ? 'id-wide-capsule-v2__text'
      : 'wide-capsule-v2__text';
    const wideCapsuleBodyClassName = metaStrategySelected
      ? 'id-wide-capsule-v2__body'
      : 'wide-capsule-v2__body';

    // only show the user action icons on the left if the user is a TC Teacher or TC Coach
    let userActionsNode = null;
    let userActionsMobileBorderNode = null;
    let wideCapsuleDetailsClassName =
      ' wide-capsule-v2__details--no-user-actions';
    if (
      Utils.arrayContainsAnyItemInAnotherArray(this.props.user.auth_roles, [
        'TC_TEACHER',
        'BL_SERVICES_MANAGER',
      ])
    ) {
      wideCapsuleTextClassName += ' wide-capsule-v2__text--border-left';
      userActionsNode = (
        <div className="wide-capsule-v2__user-actions">
          {this.getStrategyPinNode()}
          {this.getStrategyNotesNode()}
        </div>
      );
      userActionsMobileBorderNode = (
        <div className="wide-capsule-v2__actions-border"></div>
      );
      wideCapsuleDetailsClassName = '';
    }

    // set up a strategy icon if available
    let strategyIcon = 'doc';
    if (this.props.strategy.extension) {
      // TODO: the extension is not in solr, so either add it or get it from db
      strategyIcon = this.props.strategy.extension;
    }

    // default node with no preview
    let strategyPreviewImage;
    // Do not show the default preview for meta strategy implementations!
    if (!metaStrategySelected) {
      strategyPreviewImage = (
        <div className="strategy__image-mask strategy__image--none">
          <div
            className={
              'strategy__preview-icon file-type-icon ext-' + strategyIcon
            }
          ></div>
        </div>
      );
    }
    // if there is a preview available display it
    if (this.state.viablePreview) {
      const previewClassName = metaStrategySelected
        ? 'id_strategy__image'
        : 'strategy__image';
      strategyPreviewImage = (
        <ScaleCenterImage
          src={this.props.strategy.preview}
          className={previewClassName}
          alt={this.props.strategy.strategy}
        />
      );
    }

    const gradesNode = this.getGradesNode();

    // build list of artifact types that are listed at the bottom of the right column
    const artifactTypes = [
      <div
        key={'artifact-types-flag'}
        className="wide-capsule-v2__detail--artifact-flag"
      >
        ARTIFACTS
        <div className="artifact__flag-end tc-flag-end"></div>
      </div>,
    ];

    for (const type in this.props.strategy.artifact_types) {
      artifactTypes.push(
        <div
          key={'artifact-type-' + type}
          className="wide-capsule-v2__detail--artifact-type"
        >
          {type} ({this.props.strategy.artifact_types[type]})
        </div>
      );
    }

    // Handle the absence of artifacts:
    if (artifactTypes.length === 1) {
      artifactTypes.push(
        <div
          key="artifact-type-1"
          className="wide-capsule-v2__detail--artifact-type"
        >
          None
        </div>
      );
    }

    // build breadcrumbs at the bottom of the capsule
    const breadcrumbs = this.getBreadcrumbsNode();

    // Strategy and prep time nodes are not displayed for meta strategy implementations:
    let strategyNode, prepTimeNode;
    if (!metaStrategySelected) {
      strategyNode = (
        <div className="strategy__vertical-alignment--v2">
          <div className="strategy__vertical-alignment strategy__vertical-alignment--wide-capsule">
            <span className="wide-capsule-v2__icon tc-target"></span>
          </div>
          <div className="strategy__vertical-alignment wide-capsule-v2__icon-label">
            {this.props.strategy.strategy}
          </div>
        </div>
      );
      prepTimeNode = (
        <div className="wide-capsule-v2__detail">
          <div className="strategy__vertical-alignment">
            <span className="wide-capsule-v2__icon tc-clock"></span>
          </div>
          <div className="strategy__vertical-alignment wide-capsule-v2__icon-label">
            PREP TIME: {this.props.strategy.skill_level}
          </div>
        </div>
      );
    }

    return (
      <div className={wideCapsuleClassName} onClick={this.handleClick}>
        <div className="wide-capsule-v2__content">
          {userActionsNode}
          <div className={wideCapsuleTextClassName}>
            <div className="wide-capsule-v2__text-container">
              {userActionsMobileBorderNode}
              <div className="wide-capsule-v2__preview-container show-desktop hide-phone">
                {strategyPreviewImage}
              </div>
              <div>
                <div className="tc__capsule-headline wide-capsule-v2__headline">
                  <a href={this.props.url} onClick={this.handleClick}>
                    {this.props.strategy.title}
                  </a>
                </div>
                <PurifiedHTML
                  className={'tc__body-copy ' + wideCapsuleBodyClassName}
                  content={this.props.strategy.description}
                />
              </div>
              <div className="wide-capsule-v2__strategy-name-mobile hide-desktop show-phone">
                {strategyNode}
              </div>
            </div>
          </div>
          <div
            className={
              'cute-3-desktop wide-capsule-v2__details show-desktop hide-phone' +
              wideCapsuleDetailsClassName
            }
          >
            <div>{strategyNode}</div>
            {prepTimeNode}
            {gradesNode}
            {artifactTypes}
          </div>
        </div>
        {breadcrumbs}
      </div>
    );
  }
}

export default WideStrategyCapsule;
