// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import ArtifactOverlay from '@legacy-components/react/V4/Common/ArtifactOverlay/ArtifactOverlay';
import PropTypes from 'prop-types';
import React from 'react';
import MobileCalendar from './Calendar/MobileCalendar';
import CoachTools from './Coaching/CoachTools';
import ParticipantList from './Coaching/ParticipantList';
import Events from './Events/Events';

const MobileHome = (props) => {
  const [navOpen, setNavOpen] = React.useState(false);

  const handleSortChange = (e) => {
    e.preventDefault();

    props.sortBy(e.target.value);
  };

  let modalNode = null;

  if (props.ui.artifactModal.opened) {
    const artifactOverlayData = {
      title: props.ui.cohortName,
      connections: {
        preview_type: 'text',
        content: {
          preview: props.ui.cohortVision,
        },
      },
    };

    modalNode = (
      <ArtifactOverlay {...props.ui.artifactModal} data={artifactOverlayData} />
    );
  }

  let meetingsHeader = null;
  if (props.user.calendar && props.canSeeAgenda) {
    meetingsHeader = (
      <div className="admin-v5_coach-dashboard--meetings-header-mobile v5__heading-3">
        Meetings
      </div>
    );
  }

  let activePage = null;

  /* Return a page based which page is selected */
  if (props.pageName === 'events') {
    activePage = (
      <React.Fragment>
        <div
          className="admin-v5_coach-dashboard__mobile-navigation"
          onClick={() => setNavOpen(!navOpen)}
        >
          <div className={'admin-v5_coach-dashboard__navigation'}>
            <div className="admin-v5_coach-dashboard__navigation--row events active">
              <div className="inline-icon tc-v5-icon-events large-icon"></div>
              <span>Events</span>
            </div>
            <span className="tc-bold-arrow--up tc__link tc-bold-arrow"></span>
          </div>
          <div
            className={`admin-v5_coach-dashboard__navigation${
              navOpen ? '' : ' hidden'
            }`}
          >
            <NavigationLink
              className="admin-v5_coach-dashboard__navigation--row coaching"
              url={`/lab/coach/${props.pageUser.id}/coaching`}
            >
              <div className="inline-icon tc-v5-coaching_lineart large-icon"></div>
              <span>Coaching</span>
            </NavigationLink>
          </div>
        </div>
        <Events {...props} />
      </React.Fragment>
    );
  } else if (props.pageName === 'coaching') {
    activePage = (
      <React.Fragment>
        <div
          className="admin-v5_coach-dashboard__mobile-navigation"
          onClick={() => setNavOpen(!navOpen)}
        >
          <div className={'admin-v5_coach-dashboard__navigation'}>
            <div className="admin-v5_coach-dashboard__navigation--row coaching active">
              <div className="inline-icon tc-v5-coaching_lineart large-icon"></div>
              <span>Coaching</span>
            </div>
            <span className="tc-bold-arrow--up tc__link tc-bold-arrow"></span>
          </div>
          <div
            className={`admin-v5_coach-dashboard__navigation${
              navOpen ? '' : ' hidden'
            }`}
          >
            <NavigationLink
              className="admin-v5_coach-dashboard__navigation--row events"
              url={`/lab/coach/${props.pageUser.id}/events`}
            >
              <div className="inline-icon tc-v5-icon-events large-icon"></div>
              <span>Events</span>
            </NavigationLink>
          </div>
        </div>
        <div>
          {props.coachMatchesNode}
          <div className="admin-v5_coach-dashboard--meeting-tools-header-mobile v5__heading-3">
            Meeting Tools
          </div>
          <CoachTools
            calendly_link={props.calendly_link}
            canSeeAgenda={props.canSeeAgenda}
            canDisconnect={props.canDisconnect}
            pageUser={props.pageUser}
            setShowConfirmDisconnectGCal={props.setShowConfirmDisconnectGCal}
          />
          {meetingsHeader}
          <MobileCalendar {...props} />
        </div>
        <div className="v5__heading-3 admin-v5_coach-dashboard--participants-title">
          Participants
        </div>
        <div>
          <NavigationLink
            className="admin-v5__toggle-link v5__link"
            url={`/lab/coach/${props.pageUser.id}/archive`}
          >
            <div className="admin-v5__toggle-link-icon admin-v5__toggle-link-icon--home tc-v5-archive" />
            <div>View Archive</div>
          </NavigationLink>
        </div>
        {/* This is for the search bar and search failed node */}
        {props.children}
        <div className="admin-v5_coach-dashboard__mobile-sort">
          <label>Sort:</label>
          <select
            className="tc__select"
            onChange={handleSortChange}
            defaultValue={props.ui.coachHome.sortColumn}
          >
            <option value="last_name">Last Name</option>
            <option value="next_meeting">Next Meeting</option>
          </select>
        </div>
        <div className="admin-v5_coach-dashboard__mobile-header">
          <div className="cute-6-phone">Name</div>
          <div className="cute-6-phone">Next Meeting</div>
          <div className="clearfix"></div>
        </div>
        <div className="coach__participants">
          {props.coachingEngagements.map((coachingEngagement) => (
            <ParticipantList
              key={`participant-${coachingEngagement.participant.id}`}
              {...props}
              {...coachingEngagement.participant}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="m-coach-dashboard">
      {modalNode}
      <h1 className="m-coach-dashboard__headline ppd-3-coach__m-headline tc__headline-1">
        Coach Dashboard
      </h1>
      {activePage}
    </div>
  );
};

MobileHome.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  coachingEngagements: PropTypes.array,
  sortBy: PropTypes.func.isRequired,
};

export default MobileHome;
