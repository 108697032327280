// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

/*
Adds a sticky header that appears under the normal sticky mobile header
that animates in and out based on how far down the page is scrolled
(appears when scrolled past the top of the element with id=props.showHeaderElementId)
*/
const MobileStickyHeader = (props) => {
  // visibility can be one of three states: false, 'visible', 'fade-out'
  const [visibility, setVisibility] = React.useState(false);

  React.useEffect(() => {
    // show/hide the sticky header depending on page scroll position
    window.addEventListener('scroll', checkIfSticky);

    // remove event listener when unmounted
    return () => {
      window.removeEventListener('scroll', checkIfSticky);
    };
  }, [props.showHeaderElementId, visibility]);

  const checkIfSticky = () => {
    const stickyHeaderStartEl = document.getElementById(
      props.showHeaderElementId
    );

    if (stickyHeaderStartEl) {
      const headerIsSticky =
        stickyHeaderStartEl.getBoundingClientRect().top <= 0;
      // when we scrolled down far enough, add the class to make the sticky header visible
      if (headerIsSticky && visibility != 'visible') {
        setVisibility('visible');
      }
      // when we scrolled up far enough, add the class for the fade-out animation
      else if (!headerIsSticky && visibility === 'visible') {
        setVisibility('fade-out');
        // then hide the sticky header after the animation
        window.setTimeout(() => {
          setVisibility(false);
        }, 500);
      }
    }
  };

  // set the header class based on whether we want it to be visible or animating
  let visibilityClass = '';
  switch (visibility) {
    case 'visible':
      visibilityClass = 'visible show-phone';
      break;
    case 'fade-out':
      visibilityClass = 'fade-out show-phone';
      break;
  }

  return (
    <div className={`hide-desktop mobile-sticky-header ${visibilityClass}`}>
      {props.children}
    </div>
  );
};

MobileStickyHeader.propTypes = {
  // when you scroll past the top of this element, the header appears
  showHeaderElementId: PropTypes.string.isRequired,
};

MobileStickyHeader.defaultProps = {
  showHeaderElementId: 'mobile-sticky-header-start',
};

export default MobileStickyHeader;
