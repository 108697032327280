// @ts-nocheck FIXME
import { LegacyLabContext } from '@contexts/LegacyLabContext';
import StoryApi from '@legacy-components/apis/V4/StoryApi';
import WorkspaceApi from '@legacy-components/apis/V5/WorkspaceApi';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import dateformat from 'dateformat';
import React from 'react';
import ZenScroll from 'zenscroll';
import RedirectHelper from '../../../components/RedirectHelper';
import WorkspaceView from './WorkspaceView';

class WorkspaceContainer extends React.Component {
  static contextType = LegacyLabContext;

  constructor(props) {
    super(props);
    this.state = {
      activeParticipant: props.activeParticipant,
      activeStoryId: props.activeStoryId,
      customStrategyAdd: props.customStrategyAdd,
      entries: props.entries,
      goals: props.goals,
      userGoals: props.userGoals,
      pageUser: props.pageUser,
      scrollTo: props.scrollToEntryId || props.editEntryId,
      yearLoaded: props.yearLoaded,
      goalLoading: false,
    };

    // these are used by the infinite scroll to load more timeline entries
    this.state.isLoadingMoreEntries = false;
    this.state.canLoadMoreEntries = true;

    // used to show any page-level success/error alert banner
    this.state.alert = {};
    this.state.finishedMounting = false;

    // modal types:
    //     goalComplete,    goalUncomplete,
    //     goalHighlight,   goalUnhighlight,
    //     entryHighlight,  entryUnhighlight
    this.state.modal = {
      isOpen: false,
      type: null,
      data: {},
    };

    // set up default state for the two timeline filters
    this.state.filterByHighlight = false;
    this.state.goalFilter = null;

    this.state.newGoalFormVisible = false;
    this.state.isEditing = false;

    let tags;
    if (props.domains) {
      tags = {
        inputStatus: 'focus',
        charCount: 1,
        options: props.domains.map((option) => {
          return { label: option.label, id: option.label };
        }),
        selectedOptions: [],
        visibleOptions: [],
      };
    }
    this.state.entriesTags = { tags: tags };

    // Determine if the user has edit rights
    // (Note: currently, if there is not an active participant/story and
    //        coaching engagement then nothing is editable)
    this.state.canEdit =
      !!(
        props.activeParticipant &&
        props.activeParticipant.coaching_engagement_id &&
        props.activeStoryId
      ) &&
      (Utils.isServicesManager.call(this.context, props.user) ||
        Utils.isAdmin.call(this.context, props.user) ||
        (Utils.isTeacher.call(this.context, props.user) &&
          props.pageUser.id === props.user.id) ||
        (Utils.isContractCoach.call(this.context, props.user) &&
          Utils.isTeachersCoach.call(
            this.context,
            props.user,
            props.activeParticipant
          )) ||
        Utils.isManager.call(this.context, props.user));
    this.state.canEditHighlights =
      !!(
        props.activeParticipant &&
        props.activeParticipant.coaching_engagement_id &&
        props.activeStoryId
      ) &&
      (Utils.isServicesManager.call(this.context, props.user) ||
        Utils.isAdmin.call(this.context, props.user) ||
        (Utils.isContractCoach.call(this.context, props.user) &&
          Utils.isTeachersCoach.call(
            this.context,
            props.user,
            props.activeParticipant
          )) ||
        Utils.isManager.call(this.context, props.user));
    // the workspace owner cannot edit authorship
    this.state.canEditAuthor =
      props.activeParticipant &&
      props.activeParticipant.coaching_engagement_id &&
      props.activeStoryId &&
      (Utils.isServicesManager.call(this.context, props.user) ||
        Utils.isAdmin.call(this.context, props.user) ||
        (Utils.isContractCoach.call(this.context, props.user) &&
          Utils.isTeachersCoach.call(
            this.context,
            props.user,
            props.activeParticipant
          )) ||
        Utils.isManager.call(this.context, props.user));

    //
    this.state.setScrollTo = (type) => this.setState({ scrollTo: type });

    this.state.canHighlight =
      props.activeParticipant &&
      props.activeStoryId &&
      (Utils.isContractCoach.call(this.context, props.user) ||
        Utils.isElevatedUserType.call(this.context, props.user) ||
        (Utils.isTeacher.call(this.context, props.user) &&
          props.pageUser.id === props.user.id));

    // Sort the goals so that completed goals are placed after active goals and are order from most to least recent
    if (this.state.goals) {
      this.state.goals.sort((a, b) => {
        if (a.completed && b.completed) {
          return dateformat(a.completed, 'yyyy mm dd HH MM ss') >
            dateformat(b.completed, 'yyyy mm dd HH MM ss')
            ? -1
            : 1;
        } else if (a.completed) {
          return 1;
        } else if (b.completed) {
          return -1;
        } else {
          return -(a.id - b.id);
        }
      });
    } else {
      this.state.goals = [];
    }

    // This might not work with scroll to entry for the month after a school year ends and archived activities still show up
    if (
      props.entries.find(
        (entry) =>
          entry.type !== 'self_assessment_progress' &&
          entry.id === props.replyToComment
      )
    ) {
      this.state.isEditing = true;
    } else if (props.editEntryId) {
      // start the page with a given entry in edit mode if necessary
      const activeEntry = this.state.entries.find(
        (entry) =>
          entry.type !== 'self_assessment_progress' &&
          entry.id === props.editEntryId
      );
      if (activeEntry) {
        activeEntry.startInEditMode = true;
        this.state.isEditing = true;
      }
    }
  }

  componentDidMount() {
    this.setState({ finishedMounting: true });
    // wait for the page layout to finish setting up
    // if we are coming from the Select a Resource page and
    // trying to add a custom strategy, scroll to the opened form
    if (this.state.customStrategyAdd) {
      this.setState({ isEditing: true, scrollTo: 'workspace-timeline' });
    }
  }

  setErrorAlert = (message) => {
    // use default error message if necessary
    message =
      message ||
      'Oh no! Looks like something broke on our end. Wait a minute and try again.';
    this.setState({
      alert: {
        type: 'error',
        message: message,
        handleClose: this.clearAlert,
      },
    });
  };

  setSuccessAlert = (message) => {
    this.setState({
      alert: {
        type: 'success',
        message: message,
        handleClose: this.clearAlert,
      },
    });
  };

  clearAlert = () => {
    this.setState({ alert: {} });
  };

  openModal = (type, data) => {
    if (type === 'goalComplete') {
      data.participantId = this.state.activeParticipant.id;
      data.goalId = data.goal.id;
      this.setState({ goalLoading: true });
      WorkspaceApi.completeGoal(data)
        .then((response) => {
          if (response.self_assessment_uuid) {
            RedirectHelper.redirectToSelfAssessment(
              response.self_assessment_uuid
            );
            this.setState({ goalLoading: false });
            return;
          }

          this.setState({
            goalLoading: false,
            goalFilter: null,
            filterByHighlight: false,
          });

          response.entry.type = 'completed_goal';

          this.addResponseSuccess(response.entry, false);

          // Mark the goal as completed in the UI
          this.completeGoalUi(
            data.goalId,
            response.entry.completed,
            response.entry.note
          );
        })
        .catch(() => {
          this.setState({ goalLoading: false });
          this.setErrorAlert();
        });
      return;
    }

    this.setState({
      modal: {
        isOpen: true,
        type: type,
        data: data || {},
      },
    });
  };

  closeModal = () => {
    this.setState({
      modal: {
        isOpen: false,
        type: null,
        data: {},
      },
    });
  };

  setNewGoalFormVisible = (isVisible) => {
    this.setState({
      newGoalFormVisible: isVisible,
      isEditing: isVisible,
    });
  };

  submitGoal = (data, successCallback, errorCallback) => {
    data.participantId = this.state.activeParticipant.id;
    let goals = [...this.state.goals];
    const entries = [...this.state.entries];

    // make api call to add or edit the goal
    WorkspaceApi.submitGoal(data)
      .then((response) => {
        // Determine if the goal already exists
        const resultIndex = Utils.getItemIndexFromArrayOfObjects(
          goals,
          'id',
          response.goal.id
        );
        // If the goal already exists replace it otherwise add a new goal to the page
        if (resultIndex >= 0) {
          goals[resultIndex] = response.goal;
          // Determine if the goal is completed
          if (response.goal.completed) {
            // If the goal is completed update the related entry
            const entryResultIndex = Utils.getItemIndexFromArrayOfObjects(
              entries,
              'goal_id',
              response.goal.id
            );
            entries[entryResultIndex].title = response.goal.title;
            entries[entryResultIndex].description = response.goal.description;
          }
        } else {
          if (response.self_assessment_uuid) {
            RedirectHelper.redirectToSelfAssessment(
              response.self_assessment_uuid
            );
            return;
          }

          goals = [response.goal].concat(goals);
        }
        this.setState({
          newGoalFormVisible: false,
          goals: goals,
          userGoals: goals,
          isEditing: false,
        });
        if (successCallback) {
          successCallback();
        }
      })
      .catch(() => {
        this.setErrorAlert();
        if (errorCallback) {
          errorCallback();
        }
      });
  };

  completeGoal = (data) => {
    data.participantId = this.state.activeParticipant.id;
    this.setState({ goalLoading: true });
    WorkspaceApi.completeGoal(data)
      .then((response) => {
        this.setState({
          goalLoading: false,
          goalFilter: null,
          filterByHighlight: false,
        });
        response.entry.type = 'completed_goal';
        this.addResponseSuccess(response.entry, false);
        // Mark the goal as completed in the UI
        this.completeGoalUi(
          data.goalId,
          response.entry.completed,
          response.entry.note
        );
        // close the confirmation modal
        this.closeModal();
      })
      .catch(() => {
        this.setState({ goalLoading: false });
        this.setErrorAlert();
        // close the confirmation modal
        this.closeModal();
      });
  };

  uncompleteGoal = (data) => {
    this.setState({ goalLoading: true });
    data.participantId = this.state.activeParticipant.id;

    WorkspaceApi.uncompleteGoal(data)
      .then(() => {
        this.setState({ goalLoading: false });
        this.deleteResponseSuccess(
          Utils.getItemIndexFromArrayOfObjects(
            this.state.entries,
            'goal_id',
            data.goalId
          )
        );
        // Mark the goal as uncompleted in the UI
        this.completeGoalUi(data.goalId, null, null);
        // close the confirmation modal
        this.closeModal();
        // scroll to top of goals section
        this.setState({ scrollTo: 'workspace__goals' });
      })
      .catch(() => {
        this.setState({ goalLoading: false });
        this.setErrorAlert();
        // close the confirmation modal
        this.closeModal();
      });
  };
  /**
   * marks a goal as complete/incomplete
   * @param goalId {int} the id of the goal to be marked
   * @param completed {datetime str|null} if null then mark the goal as incomplete, o.w. mark as complete
   * @param note {string|null} if marked as complete then reflection note is added to the goal in state object
   *
   * @returns none
   */
  completeGoalUi = (goalId, completed, note) => {
    const goalIndex = Utils.getItemIndexFromArrayOfObjects(
      this.state.goals,
      'id',
      goalId
    );
    const newGoal = { ...this.state.goals[goalIndex] };
    newGoal.completed = completed;
    // only add reflection note if a goal is completed
    if (completed) {
      newGoal.reflection_note = note;
    }
    let newGoals = this.state.goals.slice(0, goalIndex);
    newGoals = newGoals.concat([newGoal]);
    newGoals = newGoals.concat(this.state.goals.slice(goalIndex + 1));
    // Sort the goals so that completed goals are placed after active goals and are order from most to least recent
    newGoals.sort((a, b) => {
      if (a.completed && b.completed) {
        return dateformat(a.completed, 'yyyy mm dd HH MM ss') >
          dateformat(b.completed, 'yyyy mm dd HH MM ss')
          ? -1
          : 1;
      } else if (a.completed) {
        return 1;
      } else if (b.completed) {
        return -1;
      } else {
        return -(a.id - b.id);
      }
    });
    this.setState({ goals: newGoals });
  };

  scrollToSection = (entryId) => {
    const element = document.getElementById('entry-' + entryId);
    if (element) {
      ZenScroll.setup(600, WindowHelpers.isMobileDevice() ? 90 : 10);
      ZenScroll.to(element);
    }
  };

  tagGoalResponseCallback = (response) => {
    const entries = [...this.state.entries];
    // find the now tagged entry and add the goal to it
    const resultIndex = Utils.getItemIndexFromArrayOfObjects(
      entries,
      'id',
      response.entryId
    );
    entries[resultIndex].goalIds.push(response.goalId);
    this.setState({ entries: entries });
  };

  untagGoalResponseCallback = (response) => {
    const entries = [...this.state.entries];
    // find the entry that was untagged and remove the goal from it
    const resultIndex = Utils.getItemIndexFromArrayOfObjects(
      entries,
      'id',
      response.entryId
    );
    entries[resultIndex].goalIds = entries[resultIndex].goalIds.filter(
      (goalId) => {
        return goalId !== response.goalId;
      }
    );
    this.setState({ entries: entries });
  };

  updateGoalTagResponseCallback = (response) => {
    const entries = [...this.state.entries];
    // find the entry that was untagged and remove the goal from it
    const resultIndex = Utils.getItemIndexFromArrayOfObjects(
      entries,
      'id',
      response.entryId
    );
    entries[resultIndex].goalIds = entries[resultIndex].goalIds.filter(
      (goalId) => {
        return goalId !== response.oldGoalId;
      }
    );
    entries[resultIndex].goalIds.push(response.newGoalId);
    this.setState({ entries: entries });
  };

  deleteGoal = (data, errorCallback) => {
    data.participantId = this.state.activeParticipant.id;

    WorkspaceApi.deleteGoal(data)
      .then((response) => {
        const goals = [...this.state.goals];
        let entries = [...this.state.entries];

        // Locate the goal in the goal list
        const resultIndex = Utils.getItemIndexFromArrayOfObjects(
          goals,
          'id',
          response.goalId
        );
        // Remove the goal from the current goals displayed
        goals.splice(resultIndex, 1);
        // remove the goal tag from any entries that use it
        entries = entries.map((entry) => {
          const temp = { ...entry };
          // automatic entries don't have goalIds so we skip those
          if (temp.goalIds) {
            temp.goalIds = temp.goalIds.filter((id) => id !== response.goalId);
            if (response.unhighlightedEntryIds.includes(temp.id)) {
              delete temp.highlight_author_user_id;
              delete temp.highlight_reflection;
              delete temp.highlighted;
            }
          }
          return temp;
        });
        // remove any completed_goal entry associated with the deleted goal
        entries = entries.filter((entry) => entry.goal_id !== response.goalId);

        this.setState({
          goals: goals,
          userGoals: goals,
          entries: entries,
          isEditing: false,
        });
      })
      .catch(() => {
        this.setErrorAlert();
        this.setState({ isEditing: false });
        if (errorCallback) {
          errorCallback();
        }
      });
  };

  deleteEntry = (data) => {
    StoryApi.deleteEntry(data)
      .then(() => {
        this.deleteResponseSuccess(data.index);
      })
      .catch(() => {
        this.setErrorAlert();
      });
  };

  // Adds a new entry to the page
  addResponseSuccess = (entry, startInEditMode) => {
    entry.startInEditMode = startInEditMode;
    this.setState({
      entries: [entry].concat(this.state.entries),
      isEditing: startInEditMode,
    });
  };

  // Edits an entry on the page
  editResponseSuccess = (entry, index) => {
    let newEntries = this.state.entries.slice(0, index);
    newEntries = newEntries.concat([entry]);
    newEntries = newEntries.concat(this.state.entries.slice(index + 1));
    this.setState({
      entries: newEntries,
      isEditing: false,
    });
  };

  // Removes an entry from the page
  deleteResponseSuccess = (index) => {
    let newEntries = this.state.entries.slice(0, index);
    newEntries = newEntries.concat(this.state.entries.slice(index + 1));
    this.setState({
      entries: newEntries,
      isEditing: false,
    });
  };

  addComment = (comment, entry) => {
    const entries = [...this.state.entries];
    entries[entry.index].comments =
      entries[entry.index].comments.concat(comment);
    this.setState({
      entries: entries,
      isEditing: false,
    });
  };

  editComment = (comment, author, entry) => {
    const entries = [...this.state.entries];
    entries[entry.index].comments = [...entries[entry.index].comments];
    const commentToEdit = entries[entry.index].comments[comment.index];
    commentToEdit.comment_text = comment.comment;
    if (author) {
      commentToEdit.author = author;
    }
    this.setState({
      entries: entries,
      isEditing: false,
    });
  };

  deleteComment = (commentIndex, entry) => {
    const entries = [...this.state.entries];
    entries[entry.index].comments = [...entries[entry.index].comments];
    entries[entry.index].comments.splice(commentIndex, 1);
    this.setState({
      entries: entries,
      isEditing: false,
    });
  };

  highlightEntry = (data, errorCallback) => {
    if (this.state.activeStoryId) {
      const entryId = this.state.modal.data.entryId;
      data.storyId = this.state.activeStoryId;
      data.entryId = entryId;
      // Only one goal id is present in data, but EntryTags uses an array of goal ids, hence left untouched
      const goalsList = [...this.state.goals];
      const targetGoal = goalsList.find((goal) => goal.id === data.goalId);
      data.sub_focus_area_id = targetGoal['sub_focus_area_id'];
      data.participantId = this.state.activeParticipant.id;

      WorkspaceApi.highlightEntry(data)
        .then(() => {
          const newEntries = Utils.clone(this.state.entries);
          const entry = newEntries.find(
            (entry) =>
              entry.type !== 'self_assessment_progress' && entry.id === entryId
          );
          entry.highlighted = true;
          entry.highlight_reflection = data.reflection;
          entry.highlight_sub_focus_area_id = targetGoal['sub_focus_area_id'];
          this.setState({ entries: newEntries });
          // close the modal
          this.closeModal();
          // show success alert
          this.setSuccessAlert(`${data.title} has been highlighted!`);
        })
        .catch(() => {
          this.setErrorAlert();
          if (errorCallback) {
            errorCallback();
          }
        });
    }
  };

  highlightOutcomeGrowth = (data, errorCallback) => {
    if (this.state.activeStoryId) {
      const outcomeId = data.outcomeId;
      data.storyId = this.state.activeStoryId;
      data.coaching_engagement_id =
        this.state.activeParticipant.coaching_engagement_id;
      data.author_user_id = this.props.user.id;
      WorkspaceApi.highlightOutcomeGrowth(data)
        .then((response) => {
          const newEntries = Utils.clone(this.state.entries);
          const entry = newEntries.find(
            (entry) =>
              entry.type === 'self_assessment_progress' &&
              entry.outcome === outcomeId
          );
          entry.highlighted = true;
          entry.highlight_reflection = '';
          entry.growth_highlight_id = response.growth_highlight.id;
          this.setState({ entries: newEntries });
          // close the modal
          this.closeModal();
          // show success alert
          this.setSuccessAlert('Growth Reflection has been highlighted!');
        })
        .catch((e) => {
          console.log(e);
          this.setErrorAlert();
          if (errorCallback) {
            errorCallback();
          }
        });
    }
  };

  unhighlightOutcomeGrowth = (growthHighlightId, participantName) => {
    if (this.state.activeStoryId) {
      WorkspaceApi.unhighlightOutcomeGrowth({
        growthHighlightId: growthHighlightId,
        storyId: this.state.activeStoryId,
      })
        .then(() => {
          const newEntries = Utils.clone(this.state.entries);
          const entry = newEntries.find(
            (entry) =>
              entry.type === 'self_assessment_progress' &&
              entry.growth_highlight_id === growthHighlightId
          );
          entry.highlighted = false;
          entry.highlight_reflection = '';
          entry.growth_highlight_id = null;
          this.setState({ entries: newEntries });
          // close the modal
          this.closeModal();
          // show success alert
          this.setSuccessAlert(
            `Growth Reflection was removed from ${participantName}’s highlights.`
          );
        })
        .catch(() => {
          this.setErrorAlert();
          // close the modal
          this.closeModal();
        });
    }
  };

  highlightAndUpdateGoalTagToEntry = (data, errorCallback) => {
    if (this.state.activeStoryId) {
      const entryId = this.state.modal.data.entryId;
      data.storyId = this.state.activeStoryId;
      data.entryId = entryId;
      // Only one goal id is present in data, but EntryTags uses an array of goal ids, hence left untouched
      const goalsList = [...this.state.goals];
      const targetGoal = goalsList.find((goal) => goal.id === data.newGoalId);
      data.sub_focus_area_id = targetGoal['sub_focus_area_id'];
      data.participantId = this.state.activeParticipant.id;

      WorkspaceApi.highlightAndUpdateGoalTagToEntry(data)
        .then(() => {
          const existingEntries = [...this.state.entries];
          // find the entry that was untagged and remove the goal from it
          const resultIndex = Utils.getItemIndexFromArrayOfObjects(
            existingEntries,
            'id',
            data.entryId
          );
          existingEntries[resultIndex].highlighted = true;
          existingEntries[resultIndex].highlight_reflection = data.reflection;
          if (data.oldGoalId) {
            existingEntries[resultIndex].goalIds = existingEntries[
              resultIndex
            ].goalIds.filter((goalId) => {
              return goalId !== data.oldGoalId;
            });
          }
          existingEntries[resultIndex].goalIds.push(data.newGoalId);
          this.setState({ entries: existingEntries });
          // close the modal
          this.closeModal();
          // show success alert
          this.setSuccessAlert(`${data.title} has been highlighted!`);
        })
        .catch((error) => {
          console.log(error);
          this.setErrorAlert();
          if (errorCallback) {
            errorCallback();
          }
        });
    }
  };

  unhighlightEntry = (participantName) => {
    if (this.state.activeStoryId) {
      const entryId = this.state.modal.data.entryId;

      WorkspaceApi.unhighlightEntry({
        storyId: this.state.activeStoryId,
        entryId: entryId,
      })
        .then(() => {
          const newEntries = Utils.clone(this.state.entries);
          const entry = newEntries.find(
            (entry) =>
              entry.type !== 'self_assessment_progress' && entry.id === entryId
          );
          entry.highlighted = false;
          entry.highlight_reflection = '';
          this.setState({ entries: newEntries });
          // close the modal
          this.closeModal();
          // show success alert
          this.setSuccessAlert(
            `${
              entry.title || entry.focus_area_title
            } was removed from ${participantName}’s highlights.`
          );
        })
        .catch(() => {
          this.setErrorAlert();
          // close the modal
          this.closeModal();
        });
    }
  };

  toggleHighlightFilter = () => {
    this.setState({ filterByHighlight: !this.state.filterByHighlight });
  };

  changeGoalFilter = (goalId) => {
    if (goalId === null) {
      this.setState({ goalFilter: null });
    } else {
      const goalIdIndex = this.state.goals
        .map((goal) => goal.id)
        .indexOf(goalId);
      if (goalIdIndex < 0) {
        this.setState({ goalFilter: null });
      }
      this.setState({ goalFilter: this.state.goals[goalIdIndex] });
      const element = document.getElementById('workspace-timeline');
      if (element) {
        ZenScroll.setup(600, WindowHelpers.isMobileDevice() ? 100 : 10);
        ZenScroll.to(element);
      }
    }
  };

  setIsEditing = (val) => {
    this.setState({ isEditing: val });
  };

  loadMoreEntries = () => {
    // this is the earliest year for which we have story data
    if (this.state.yearLoaded === '2018 - 2019') {
      this.setState({ canLoadMoreEntries: false });
      return;
    }

    // show the spinner
    this.setState({ isLoadingMoreEntries: true });

    // calculate the prev school year based on the last one we loaded
    const prevYear = Utils.getPrevSchoolYear(this.state.yearLoaded);

    // get any workspace entries that the user had for that school year
    WorkspaceApi.getEntriesForSchoolYear({
      userId: this.state.pageUser.id,
      year: prevYear,
    })
      .then((response) => {
        // add any new entries to the bottom of the timeline
        if (ArrayHelpers.hasElements(response.entries)) {
          this.setState({
            yearLoaded: prevYear,
            entries: this.state.entries.concat(response.entries),
            isLoadingMoreEntries: false,
          });
        } else {
          // TODO: check prev year?
          this.setState({
            yearLoaded: prevYear,
            isLoadingMoreEntries: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          yearLoaded: prevYear,
          isLoadingMoreEntries: false,
          canLoadMoreEntries: false,
        });
      });
  };

  render() {
    const entries = [...this.state.entries];
    // Give each entry an index so when we are modifying or removing them they know where to insert themselves
    entries.forEach((entry, i) => (entry.index = i));

    return (
      <WorkspaceView
        {...this.state}
        addComment={this.addComment}
        addDomainToGoal={this.addDomainToGoal}
        addEntry={this.addEntry}
        addEntryCallback={this.addResponseSuccess}
        canEdit={this.state.canEdit}
        canEditHighlights={this.state.canEditHighlights}
        canEditAuthor={this.state.canEditAuthor}
        canLoadMoreEntries={this.state.canLoadMoreEntries}
        changeGoalFilter={this.changeGoalFilter}
        cohortPriorityFocusAreas={this.props.cohort_priority_focus_areas}
        closeModal={this.closeModal}
        completeGoal={this.completeGoal}
        deleteComment={this.deleteComment}
        deleteEntry={this.deleteEntry}
        deleteEntryCallback={this.deleteResponseSuccess}
        deleteGoal={this.deleteGoal}
        domains={this.props.domains}
        allDomains={this.props.allDomains}
        editComment={this.editComment}
        editEntryCallback={this.editResponseSuccess}
        tagGoalResponseCallback={this.tagGoalResponseCallback}
        untagGoalResponseCallback={this.untagGoalResponseCallback}
        userGoals={this.state.userGoals}
        entries={entries}
        entriesTags={this.state.entriesTags}
        filterByHighlight={this.state.filterByHighlight}
        finishedMounting={this.state.finishedMounting}
        goalFilter={this.state.goalFilter}
        highlightEntry={this.highlightEntry}
        highlightAndUpdateGoalTagToEntry={this.highlightAndUpdateGoalTagToEntry}
        isEditing={this.state.isEditing}
        isLoadingMoreEntries={this.state.isLoadingMoreEntries}
        loadMoreEntries={this.loadMoreEntries}
        nextMeeting={this.props.nextMeeting}
        openModal={this.openModal}
        replyToComment={this.props.replyToComment}
        reportingPhases={this.props.reportingPhases}
        scrollTo={this.state.scrollTo}
        setScrollTo={this.state.setScrollTo}
        setSuccessAlert={this.setSuccessAlert}
        setErrorAlert={this.setErrorAlert}
        setIsEditing={this.setIsEditing}
        setNewGoalFormVisible={this.setNewGoalFormVisible}
        stories={this.props.stories}
        submitGoal={this.submitGoal}
        toggleHighlightFilter={this.toggleHighlightFilter}
        uncompleteGoal={this.uncompleteGoal}
        unhighlightEntry={this.unhighlightEntry}
        highlightOutcomeGrowth={this.highlightOutcomeGrowth}
        unhighlightOutcomeGrowth={this.unhighlightOutcomeGrowth}
        user={this.props.user}
        goalLoading={this.state.goalLoading}
        subFocusAreas={this.props.sub_focus_areas}
        cohortFocusAreas={this.props.cohort_focus_areas}
        latestEngagement={this.props.latestEngagement}
      />
    );
  }
}

export default WorkspaceContainer;
