// @ts-nocheck FIXME
import Request from './../components/Request';

const ArtifactApi = {
  getTcArtifact: function (id) {
    return Request.get('/coaching/lab/tcartifact/' + id);
  },

  postTcComment: function (data) {
    return Request.post(
      '/coaching/lab/tcartifact/' + data['artifact_id'] + '/comment',
      JSON.stringify(data)
    );
  },

  getTcComments: function (artifactId) {
    return Request.get('/coaching/lab/tcartifact/' + artifactId + '/comments');
  },

  postTcArtifactDownload: function (artifactId) {
    return Request.post(
      '/coaching/lab/tcartifact/' + artifactId + '/download',
      {}
    );
  },

  postArtifactDownload: function (strategyImplementationId, artifactId) {
    return Request.post(
      '/coaching/lab/strategy/' +
        strategyImplementationId +
        '/artifact/' +
        artifactId +
        '/download',
      {}
    );
  },

  resetImage: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/artifact/reset_link_image',
      JSON.stringify(data)
    );
  },
};

export default ArtifactApi;
