import { getPageData } from '@apis/lab';
import RedirectHelper from '@legacy-components/components/RedirectHelper';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

type LegacyLabContextType = {
  error: boolean;
  isLoading: boolean;
  pageData: any | null;
};

export const LegacyLabContext = createContext<LegacyLabContextType>(
  {} as LegacyLabContextType
);

export const PAGE_DATA_KEY = 'page_data';

const PAGE_API: Record<string, string> = {
  '/404': '/unauthorized',
};

export const LegacyLabProvider = (): JSX.Element => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({});
  const defaultTitle = useRef(document.title);

  const getPageApiPath = (): string => {
    if (PAGE_API[location.pathname]) {
      return PAGE_API[location.pathname];
    }

    return location.pathname + location.search;
  };

  const setPageTitle = (data: any | null) => {
    if (!data?.page_title) {
      document.title = defaultTitle.current;
      return;
    }

    document.title = data.page_title;
  };

  const fetchPageData = async () => {
    setIsLoading(true);
    const pageApiPath = getPageApiPath();
    const { status, data } = await getPageData(pageApiPath);
    setPageTitle(data);

    switch (status) {
      case 401:
        RedirectHelper.redirectToUserManagementWithRedirectSet('/login');
        return;
      case 403:
        navigate('/unauthorized');
        return;
      case 300:
        navigate(data.path || '/');
        return;
    }

    if (status >= 400) {
      setError(true);
      return;
    }

    setPageData(data.page_data);
    setIsLoading(false);
  };

  const handleLocalStorageUpdate = () => {
    const newPageData = localStorage.getItem(PAGE_DATA_KEY);

    if (!newPageData) {
      return;
    }

    setPageData(JSON.parse(newPageData));
    localStorage.removeItem(PAGE_DATA_KEY);
  };

  useEffect(() => {
    if (!auth.isLoading) {
      fetchPageData();
    }
  }, [location.pathname, auth.isLoading]);

  useEffect(() => {
    addEventListener('storage', handleLocalStorageUpdate);
    return () => removeEventListener('storage', handleLocalStorageUpdate);
  }, []);

  return (
    <LegacyLabContext.Provider
      value={{
        pageData,
        isLoading,
        error,
      }}
    >
      <Outlet />
    </LegacyLabContext.Provider>
  );
};

export const useLegacyLabContext = (): LegacyLabContextType =>
  useContext(LegacyLabContext);
