// @ts-nocheck FIXME
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import PropTypes from 'prop-types';

const MatchFacilitator = (props) => {
  return (
    <div className="match-detail__experience-coach">
      <UserPhoto
        src={props.photo}
        userId={props.id}
        firstName={props.first_name}
        lastName={props.last_name}
        diameter={30}
      />
      <div className="match-details__experience-coach-name v5__body-copy--bold">
        {props.first_name} {props.last_name}
      </div>
    </div>
  );
};

export default MatchFacilitator;

MatchFacilitator.propTypes = {
  photo: PropTypes.string,
  id: PropTypes.number.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
};
