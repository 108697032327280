// @ts-nocheck FIXME
import React from 'react';
import Utils from './../../../../components/DynamicUtils';
import WindowHelpers from './../../../../components/WindowHelpers';

class SidebarStoryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStory: null,
    };
    this.storyContainer = null;
    this.elementDetails = {};
    this.handleScrollLocationUpdates =
      this.handleScrollLocationUpdates.bind(this);
  }
  componentDidMount() {
    this.storyContainer = document.getElementById(
      'story-' + this.props.story.id
    );

    // get the position and height of the about container
    this.elementDetails.top = Utils.getAbsolutePosition(this.storyContainer)[1];
    this.elementDetails.height = this.storyContainer.offsetHeight;

    // add to height of about container to position to get margin for active state
    this.elementDetails.bottom =
      this.elementDetails.top + this.elementDetails.height;

    // attach a scroll listener
    window.addEventListener('scroll', this.handleScrollLocationUpdates);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollLocationUpdates);
  }

  handleScrollLocationUpdates() {
    const currentScrollPosition = WindowHelpers.getVerticalScrollPosition();

    if (
      currentScrollPosition >= this.elementDetails.top - 10 &&
      currentScrollPosition < this.elementDetails.bottom
    ) {
      this.setState({
        activeStory: true,
      });
    } else {
      this.setState({
        activeStory: false,
      });
    }
  }
  render() {
    const storyLinkActiveClass = this.state.activeStory ? ' active' : '';
    let orderingIconNode = null;
    let sortIndicatorActiveClass = '';
    if (this.props.storySidebarReordering) {
      const firstIconClass =
        this.props.position == 0 ? ' bl4__sidebar-sort-icon--first' : '';
      orderingIconNode = (
        <div
          className={'bl4__sidebar-sort-icon tc-reorder' + firstIconClass}
        ></div>
      );
      sortIndicatorActiveClass =
        this.props.sortIndicatorActive && this.props.position == 0
          ? ' bl4__sidebar-sort-indicator'
          : '';
    }

    return (
      <div
        className={'bl4__sidebar-portfolio-story' + sortIndicatorActiveClass}
        key={'sidebar-story-id-' + this.props.story.id}
        data-element-type={'story'}
        data-id={this.props.story.id}
      >
        {orderingIconNode}
        <a
          className={
            'bl4__sidebar-item bl4__sidebar-item--level-2 tc__link' +
            storyLinkActiveClass
          }
          onClick={(e) => {
            e.preventDefault();
            this.setState({
              activeStoryId: this.props.story.id,
            });
            this.props.scrollToProfileSection({
              id: 'story-' + this.props.story.id,
            });
          }}
        >
          {this.props.story.focus_area.title}
        </a>
      </div>
    );
  }
}

export default SidebarStoryItem;
