// @ts-nocheck FIXME
import StorageHelper from '@legacy-components/components/StorageHelper';
import { useEffect } from 'react';

const FreeContentLimit = (props) => {
  const checkFreeContentLimit = () => {
    // this only applies to logged out users
    if (props.user && props.user.id) {
      return;
    }

    // this only applies to the lesson and strategy pages
    if (props.page !== 'lesson' && props.page !== 'Strategy') {
      return;
    }

    // get the list of lessons and strategies viewed
    const lessons = StorageHelper.getCookieItem('bl_lessons_viewed') || [];
    const strategies =
      StorageHelper.getCookieItem('bl_strategies_viewed') || [];

    // get the current page's lesson or strategy id
    const lessonId = props.bl_lesson_id ? parseInt(props.bl_lesson_id) : null;
    const strategyId = props.bl_strategy_id
      ? parseInt(props.bl_strategy_id)
      : null;
    // some strategies are meant to be completely open, so for those we set strategyId to be -1
    // so we can skip the logic of counting them against the user's views.
    if (strategyId === -1) {
      return;
    }

    // if not, then track the current content
    // add the current lesson or strategy id to the list
    else if (lessonId && lessons.indexOf(lessonId) === -1) {
      lessons.push(lessonId);
      StorageHelper.setCookieItem('bl_lessons_viewed', lessons, {
        maxAge: StorageHelper.MAX_COOKIE_AGE,
      });
    } else if (strategyId && strategies.indexOf(strategyId) === -1) {
      strategies.push(strategyId);
      StorageHelper.setCookieItem('bl_strategies_viewed', strategies, {
        maxAge: StorageHelper.MAX_COOKIE_AGE,
      });
    }
  };

  useEffect(() => {
    checkFreeContentLimit();
  });

  return null;
};

export default FreeContentLimit;
