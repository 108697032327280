// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../components/DynamicUtils';
import S3 from './../../../components/S3';
import ScaleCenterImage from './../../ScaleCenterImage';

const HeroImage = (props) => {
  let inputFiles = [];
  let completedFiles = 0;
  let imagePreviewNode = null;
  let imageUploadNode = null;
  const disabledClass = props.loading ? ' disabled' : '';

  const handleDelete = (e) => {
    e.preventDefault();
    // Clear existing alerts
    props.clearAlert();
    props.openConfirmDeleteImageModal({
      title: 'hero artifact',
    });
  };

  const handleFilesChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Clear existing alerts
    props.clearAlert();

    inputFiles = e.target.files;
    completedFiles = 0;

    submitFiles();
  };

  const handleAddHeroClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById('addHeroFile').click();
  };

  const getUploadProgressNode = () => {
    let progressNode = null;
    let progressFileNameNode = null;

    if (
      props.file &&
      props.file.status == 'during' &&
      props.file.percent < 100
    ) {
      const progressFileNameClass =
        props.preview !== undefined
          ? ' ppd__v2-curation-strategy-artifact__upload-title--replace'
          : '';

      progressFileNameNode = (
        <div className="ppd__v2-curation-strategy-upload-file-name">
          <span className="artifact__type-icon file-type-icon" />
          <span
            className={
              'artifact__upload-title ppd__v2-curation-strategy-artifact__upload-title' +
              progressFileNameClass
            }
          >
            {props.file.name}
          </span>
        </div>
      );

      progressNode = (
        <div
          key="progress"
          className="ppd__v2-curation-strategy-upload-status-indicator"
        >
          <div className="artifact__upload-bar">
            <div
              className="artifact__upload-progress"
              style={{ width: props.file.progress + '%' }}
            />
          </div>
          {progressFileNameNode}
        </div>
      );
    }
    return progressNode;
  };

  const submitFiles = () => {
    let file;

    if (props.startUploadCallback) {
      props.startUploadCallback();
    }
    for (let i = 0; i < inputFiles.length; i++) {
      // Add some of the POST fields that the backend needs
      file = {
        lastModified: inputFiles[i]['lastModified'],
        lastModifiedDate: inputFiles[i]['lastModifiedDate'],
        name: inputFiles[i]['name'],
        size: inputFiles[i]['size'],
        type: inputFiles[i]['type'],
        uuid: Utils.uuid(),
      };
      // max size is 1024MB
      if (file.size > 1024000000) {
        props.errorCallback('Maximum upload size is 1GB');
      } else {
        inputFiles[i]['uuid'] = file['uuid'];
        const callbackFields = {
          tcStrategyId: props.tcStrategyId,
        };
        // Upload the file to S3
        S3.flexibleUpload(
          inputFiles[i],
          props.beforeUploadCallback,
          props.uploadCallback,
          props.afterUploadCallback,
          props.cleanupCallback,
          callbackFields
        ).then(
          function onSuccess(fileData) {
            fileData['message'] = 'Upload Complete';
            fileData['status'] = 'after';
            props.setPreview(fileData['preview']);
          },
          function onError(fileData) {
            // See if the file has the error property
            let error = fileData['error'];
            if (!error) {
              error = 'There was an error uploading your file.';
            }
            props.errorCallback(error);
            // Clear the file from the store
            props.updateHeroUploadProgress(null);
          },
          function onProgress(fileData) {
            // If there is an error, abort
            if (fileData.status === 'error') {
              return;
            }
            fileData['message'] = fileData.progress + '%';
            fileData['status'] = 'during';
            fileData['percent'] = fileData.progress;
            props.updateHeroUploadProgress(fileData);
          }
        );
      }
    }
  };

  const getUploadNode = (label) => {
    let uploadNode = null;
    if (label != 'Replace') {
      uploadNode = (
        <div>
          <div
            className={
              'ppd__v2-curation-strategy-image-upload-button' + disabledClass
            }
            onClick={handleAddHeroClick}
          >
            <div className="bl4__btn bl4__btn--blue">{label}</div>

            <div className="tc__error tc__error--upload">{props.error}</div>
          </div>
          <div>
            <input
              id="addHeroFile"
              type="file"
              className="artifact__upload-field artifact__upload-field--v3-curation"
              onChange={handleFilesChange}
              multiple={props.multiple}
              value=""
            />
          </div>
        </div>
      );
    }
    return uploadNode;
  };

  // If the strategy already has a preview, we will display a preview node and some action buttons (delete, replace)
  // Otherwise, we display the upload node
  if (props.preview !== undefined) {
    // the image replace node will be used to replace the strategy image
    let imageReplaceNode = null;
    if (!props.file) {
      imageReplaceNode = getUploadNode('Replace');
    }

    // Handle cases where the preview is not generated yet
    let imageNode = (
      <div className="ppd__v2-curation-strategy-image-mask--none">
        <div
          className={
            'ppd__v2-curation-strategy-image-icon file-type-icon ext-jpg'
          }
        />
      </div>
    );

    // grey out artifact image
    let imageLoadingOverlayNode = null;
    if (
      props.file &&
      props.file.status == 'during' &&
      props.file.status < 100
    ) {
      imageLoadingOverlayNode = (
        <div className="ppd__v2-curation-strategy-image-loading-overlay" />
      );
    }

    if (props.preview !== null && props.preview !== '') {
      imageNode = (
        <div>
          <ScaleCenterImage
            src={props.preview}
            className={'ppd__v2-curation-strategy-image'}
          />
        </div>
      );
    }
    imagePreviewNode = (
      <div className="ppd__v2-curation-strategy-image-container">
        {imageNode}
        <div>
          {imageLoadingOverlayNode}
          <a
            className="ppd__v2-curation-strategy-image-delete-icon tc-x"
            onClick={handleDelete}
          />
          {imageReplaceNode}
        </div>
      </div>
    );
  } else {
    if (!props.file) {
      imageUploadNode = getUploadNode('Upload');
    }
  }

  // Get the upload progress node
  const uploadProgressNode = getUploadProgressNode();

  return (
    <div className="ppd-v3-curation__strategy-upload cute-8-desktop left">
      <div className="">{uploadProgressNode}</div>
      <div className="ppd-v3-curation__strategy-upload-image-container">
        {imagePreviewNode}
        {imageUploadNode}
      </div>
    </div>
  );
};

HeroImage.propTypes = {
  multiple: PropTypes.bool.isRequired,
  tcStrategyId: PropTypes.number.isRequired,
  preview: PropTypes.string,
  setPreview: PropTypes.func.isRequired,
  openConfirmDeleteImageModal: PropTypes.func.isRequired,
  updateHeroUploadProgress: PropTypes.func.isRequired,
  file: PropTypes.object,
  clearAlert: PropTypes.func.isRequired,
  beforeUploadCallback: PropTypes.func.isRequired,
  uploadCallback: PropTypes.func.isRequired,
  afterUploadCallback: PropTypes.func.isRequired,
  cleanupCallback: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
};

export default HeroImage;
