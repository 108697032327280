// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import PropTypes from 'prop-types';

const DropdownUser = (props) => {
  return (
    <div className="">
      <span className="header__dropdown-user-details">
        <NavigationLink
          url="/account"
          className="header__dropdown-user-settings"
        >
          <UserPhoto
            src={props.user.photo}
            userId={props.user.id}
            firstName={props.user.first_name}
            lastName={props.user.last_name}
            diameter={50}
            className="header__dropdown-user-photo"
          />
          <div className="header__dropdown-user-name">
            {props.user.first_name} {props.user.last_name}
          </div>
          <div className="tc-v4-settings"></div>
        </NavigationLink>
      </span>

      {props.children}
    </div>
  );
};

export default DropdownUser;

DropdownUser.propTypes = {
  user: PropTypes.object.isRequired,
};
