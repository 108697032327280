// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import WindowHelpers from './../../../../../components/WindowHelpers';
import ArtifactCapsule from './../EditEntryCapsules/Artifact';
import MeetingClipCapsule from './../EditEntryCapsules/MeetingClip';
import TextCapsule from './../EditEntryCapsules/Text';

const EditEntryRow = (props) => {
  // when Delete is clicked, change the capsule to a confirm delete form
  const handleConfirmDelete = (e) => {
    e.preventDefault();
    props.confirmDelete();
  };

  // set up the Delete button that appears in most capsules when editing
  const deleteNode = (
    <div className="story__edit-entry-delete-link-row">
      <div
        onClick={handleConfirmDelete}
        className="story__edit-entry-delete-link"
      >
        <div className="tc-delete story__edit-entry-delete-icon"></div>
        Delete
      </div>
    </div>
  );

  // set the id of the containing row so we can scroll to it if necessary
  let capsuleRowId = 'new-entry-capsule';
  if (props.editMode) {
    capsuleRowId = 'entry-' + props.data.id;
  }

  // set up the entry capsule for the entry type we're editing
  let capsuleNode = null;
  switch (props.type) {
    case 'file':
    case 'link': {
      // artifact component starting mode depends on context
      let mode = props.type === 'link' ? 'link' : 'file';
      if (props.editMode) {
        mode = 'edit';
      }
      capsuleNode = (
        <ArtifactCapsule
          {...props.ui}
          highlightActiveEntry={props.highlightActiveEntry}
          mode={mode}
          close={props.close}
          isLink={props.type === 'link'}
          data={props.data}
          toggleIsPrivate={props.toggleIsPrivate}
          addEntryCallback={props.addEntryCallback}
          editEntryCallback={props.editEntryCallback}
          setErrorAlert={props.setErrorAlert}
          artifactUserId={props.artifactUserId}
          entryParticipantId={props.entryParticipantId}
          isMobile={WindowHelpers.isMobileDevice()}
        >
          {deleteNode}
        </ArtifactCapsule>
      );
      break;
    }

    case 'text':
      capsuleNode = (
        <TextCapsule
          ui={props.ui}
          highlightActiveEntry={props.highlightActiveEntry}
          close={props.close}
          data={props.data}
          toggleIsPrivate={props.toggleIsPrivate}
          clearError={props.clearError}
          submit={props.submit}
          editMode={props.editMode}
        >
          {deleteNode}
        </TextCapsule>
      );
      break;
    case 'meeting_clip':
      capsuleNode = (
        <MeetingClipCapsule
          {...props.ui}
          highlightActiveEntry={props.highlightActiveEntry}
          close={props.close}
          data={props.data}
          toggleIsPrivate={props.toggleIsPrivate}
          editEntryCallback={props.editEntryCallback}
          setErrorAlert={props.setErrorAlert}
          artifactUserId={props.artifactUserId}
        >
          {deleteNode}
        </MeetingClipCapsule>
      );
      break;
  }

  return (
    <div id={capsuleRowId} className="row row--1200">
      {capsuleNode}
    </div>
  );
};

EditEntryRow.propTypes = {
  type: PropTypes.string.isRequired,
  confirmDelete: PropTypes.func,
};

export default EditEntryRow;
