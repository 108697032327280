// @ts-nocheck FIXME
import PropTypes from 'prop-types';

/*
This is a wrapper for entry capsules that can be "active"
(i.e. those that have some sort of form in them)
*/
const Capsule = (props) => {
  const capsuleClass = props.highlight
    ? ' story__entry-capsule--border-pulse'
    : '';

  return (
    <div className="cute-12-desktop cute-12-phone center-desktop">
      <div className={'story__entry-capsule tc__body-copy' + capsuleClass}>
        {props.children}
      </div>
    </div>
  );
};

Capsule.propTypes = {
  highlight: PropTypes.bool,
};

export default Capsule;
