// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const ErrorText = (props) => {
  return (
    <div
      className={`v5__error-container ${props.errorContainerClass}`}
      data-testid="error"
    >
      <div className={`v5__error ${props.errorClass}`}>{props.error}</div>
    </div>
  );
};

ErrorText.propTypes = {
  errorContainerClass: PropTypes.string,
  errorClass: PropTypes.string,
  error: PropTypes.string,
};

ErrorText.defaultProps = {
  errorContainerClass: '',
  errorClass: '',
};

export default ErrorText;
