// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import React from 'react';
import Utils from '../../../components/DynamicUtils';

const BL_ACCOUNT_ID = 111089;

const PendoContainer = () => {
  const lab = useLegacyLabContext();

  const getPartnerId = () => {
    if (lab.pageData.user.partner_id) {
      return lab.pageData.user.partner_id;
    }

    if (Utils.isTeamMember.call(lab) || Utils.isContractCoach.call(lab)) {
      return BL_ACCOUNT_ID;
    }

    return null;
  };

  const getProductRoles = () => {
    if (!lab.pageData.user.product_roles) {
      return [];
    }

    const validProductRoles = Object.entries(
      lab.pageData.user.product_roles
    ).filter(({ 1: roles }) => roles.length);

    return validProductRoles.map(([product]) => product);
  };

  const getUserProfessionalRole = () => {
    const userProfessionalRole = {
      professionalRole: '',
      teacherExperience: '',
      teacherSubject: '',
    };

    const professionalRole = lab.pageData.user.professional_role;

    if (!professionalRole) {
      return userProfessionalRole;
    }

    const { role, subjectExperience } = professionalRole;
    userProfessionalRole.professionalRole = role;

    if (!subjectExperience) {
      return userProfessionalRole;
    }

    userProfessionalRole.teacherExperience = subjectExperience.experience;
    userProfessionalRole.teacherSubject = subjectExperience.subject;
    return userProfessionalRole;
  };

  const getPendoVisitor = (user) => {
    const userId = user && user.id;

    if (!userId) {
      return { id: '' };
    }

    return {
      id: userId,
      email: user.email,
      authRoles: user.auth_roles,
      productRoles: getProductRoles(),
      firstName: user.first_name,
      full_name: `${user.first_name} ${user.last_name}`,
      lastLogin: user.last_login,
      ...getUserProfessionalRole(),
    };
  };

  const getPendoAccount = (user) => {
    const userId = user && user.id;

    if (!userId) {
      return {};
    }

    const partnerId = getPartnerId();

    if (!partnerId) {
      return {};
    }

    return { id: partnerId };
  };

  const getPendoFields = (user) => {
    const visitor = getPendoVisitor(user);
    const account = getPendoAccount(user);

    return visitor.id
      ? {
          visitor,
          account,
        }
      : {
          visitor: { id: 'VISITOR-UNIQUE-ID' },
          cookieDomain: process.env.REACT_APP_DOMAIN,
        };
  };

  React.useEffect(() => {
    const user = lab.pageData.user;
    const pendoFields = getPendoFields(user);
    // This is basically exactly copy pasted from pendo, it's simplest to ignore linting
    /* eslint-disable */
    (function (apiKey) {
      (function (p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                );
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');

      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      if (!Utils.hasFeatureFlag.call(lab, 'EXCLUDE_FROM_PENDO')) {
        pendo.initialize(pendoFields);
      }
    })(process.env.REACT_APP_PENDO_API_KEY);
    /* eslint-enable */
  }, []);
  return null;
};

export default PendoContainer;
