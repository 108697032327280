import { getLabAxios } from '@utils/LabAxiosFactory';

type ResponseType = {
  data: any;
  status: number;
};

export const getPageData = async (path: string): Promise<ResponseType> => {
  try {
    const response = await getLabAxios().get(path);
    return response;
  } catch (err) {
    console.error(`Error when fetching page data: ${err}`);
    return { data: null, status: 502 };
  }
};
