// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import React from 'react';
import ScaleCenterImage from './../../ScaleCenterImage';

class Loop extends React.Component {
  render() {
    // if there is an image available display it
    let strategyPreviewImage = null;
    let hasPreviewClass = '';
    if (this.props.strategy_preview) {
      strategyPreviewImage = (
        <ScaleCenterImage
          src={this.props.strategy_preview.preview}
          className="portfolio__strategy-image"
          alt={this.props.strategy}
        />
      );
      hasPreviewClass = ' portfolio__strategy-data--has-preview';
    }

    let explanationNode = null;
    if (this.props.strategy_description) {
      explanationNode = (
        <PurifiedHTML
          className="portfolio__strategy-copy portfolio__text-sans"
          content={this.props.strategy_description}
        />
      );
    }

    let loopNode = null;
    let strategyAuthorNode = null;
    if (this.props.strategy_author) {
      strategyAuthorNode = (
        <div className="portfolio__strategy-author">
          Proven strategy by Master Teacher:{' '}
          {this.props.strategy_author.first_name +
            ' ' +
            this.props.strategy_author.last_name}
        </div>
      );
    }
    const strategyName = this.props.strategy_name
      ? this.props.strategy_name
      : '';
    const strategyDefinition = this.props.strategy_definition
      ? this.props.strategy_definition
      : '';

    if (this.props.type === 'measurement') {
      loopNode = (
        <div className="portfolio__loop">
          <div className="portfolio__strategy">
            <div className="portfolio__section-headline">
              <span>Strategy Tried:</span>
              <span className="portfolio__strategy-title">
                {this.props.strategy}
              </span>
            </div>
            {strategyAuthorNode}
            <div className={'portfolio__strategy-data' + hasPreviewClass}>
              {strategyPreviewImage}
              <div className="portfolio__strategy-data-text">
                <div className="">
                  <div className="portfolio__strategy-icon tc-target"></div>
                  <div className="portfolio__strategy-details">
                    <span className="portfolio__strategy-tag">
                      {strategyName}
                    </span>
                    <span className="portfolio__strategy-tag-copy">
                      {strategyDefinition}
                    </span>
                  </div>
                </div>
                {explanationNode}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div className="portfolio__strategy-loop">{loopNode}</div>;
  }
}

export default Loop;
