// @ts-nocheck FIXME
import offset from '@legacy-components/libraries/videojs-offset';
import React from 'react';
import ReactDOM from 'react-dom';
import videojs from 'video.js';

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: props.width,
      height: props.height,
    };
    this.setUpVideo = this.setUpVideo.bind(this);
  }

  componentDidMount() {
    this.setUpVideo();
  }

  componentDidUpdate(prevProps) {
    // if the video changed, rebuild the player
    if (this.props.preview !== prevProps.preview) {
      this.setUpVideo();
    }
    if (
      this.props.width !== this.state.width ||
      this.props.height !== this.state.height
    ) {
      this.setState({
        width: this.props.width,
        height: this.props.height,
      });
      this.player.width(this.props.width);
      this.player.height(this.props.height);
    }
  }

  setUpVideo() {
    let html =
      '<div class="strategy__preview-icon file-type-icon ext-mp4" style="height: ' +
      this.props.height +
      'px; width: ' +
      this.props.width +
      'px" ></div>';
    const target = ReactDOM.findDOMNode(this.refs.target);
    target.innerHTML = html;

    if (this.props.preview) {
      const playInlineAttribute = this.props.playsinline ? 'playsInline' : '';
      html =
        '<video  class="video-js vjs-default-skin" controls preload="auto" width="640" height="264" poster="' +
        this.props.preview +
        '" ' +
        playInlineAttribute +
        '>';

      if (this.props.formats) {
        for (let i = 0; i < this.props.formats.length; i++) {
          html +=
            '<source src="' +
            this.props.formats[i].url +
            '" type=\'' +
            this.props.formats[i].type +
            "' />";
        }
      }
      html +=
        '<p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>';
      html += '</video>';

      target.innerHTML = html;

      videojs.options.flash.swf = '/legacy-libs/videojs/video-js.swf';
      videojs.plugin('offset', offset);
      this.player = videojs(target.firstChild, {
        controls: true,
        preload: 'metadata',
        autoplay: false,
        width: this.state.width,
        height: this.state.height,
        fluid: true,
      }).on('ended', function () {
        this.currentTime(0);
      });

      if (this.props.start >= 0) {
        this.player.offset({
          start: this.props.start,
          end: this.props.end,
          restart_beginning: true,
        });
      }
    }
  }

  render() {
    return <div className="vjs__video-container" ref="target" />;
  }
}

export default Video;
