// @ts-nocheck FIXME
import PubSub from 'pubsub-js';
import Action from './Action';
import Utils from './StaticUtils';

const storeFunctions = {};

const reservedFunctionNames = [
  'actionCreate',
  'actionDispatch',
  'actionDispatchSync',
  'functionCreate',
  'functionCall',
  'changed',
  'subscribe',
  'subscribeOnly',
  'unsubscribe',
  'getState',
  'getName',
];

export default (storeName, store) => {
  // decore the store with an action creation helper
  store.actionCreate = (actionName, callback, changed = true) => {
    Action.create(storeName + '.' + actionName, (data) => {
      // if the callback return false, don't emit a store change
      const doChange = callback(data);
      if (doChange === false) {
        return;
      }

      // if the action was created with changed to false, don't emit store change
      if (changed == true) {
        Action.dispatchSync(storeName + '.changed');
      }
    });
  };

  // decorate store with a function that is tied to this domain, that never emits events
  store.functionCreate = (functionName, storeFunction) => {
    storeFunctions[storeName + '.' + functionName] = storeFunction;
  };

  // call a previously defined store function
  store.functionCall = (functionName, data) => {
    return storeFunctions[storeName + '.' + functionName](data);
  };

  // decorate store with an action dispatch helper
  store.actionDispatch = (actionName, data) => {
    Action.dispatch(storeName + '.' + actionName, data);
  };

  store.actionDispatchSync = (actionName, data) => {
    Action.dispatchSync(storeName + '.' + actionName, data);
  };

  // decorate store with subscript helpers
  store.subscribe = (callback) => {
    return Action.create(storeName + '.changed', callback);
  };

  // ensure that any other subscribers is unsubscribed first
  store.subscribeOnly = (callback) => {
    Action.destroy(storeName + '.changed');
    return Action.create(storeName + '.changed', callback);
  };

  store.unsubscribe = (token) => {
    Action.destroy(token);
  };

  // shortcut to indicate that the store has changed
  store.changed = () => {
    Action.dispatchSync(storeName + '.changed');
  };

  store.getName = () => {
    return storeName;
  };

  // if you want to clone the store instead of copying it
  store.getState = () => {
    const state = Utils.clone(store);
    reservedFunctionNames.map(function (method) {
      delete state[method];
    });
    return state;
  };

  // make functions automatically dispatch change events
  for (const property in store) {
    // don't operated on reservedFunctionNames
    if (reservedFunctionNames.indexOf(property) > -1) {
      continue;
    }

    // only operate on functions
    if (typeof store[property] != 'function') {
      continue;
    }

    // store the originally defined function
    const originalFunction = store[property];

    // re-assign the same function name, calling the original function and sending notifications
    store[property] = (data) => {
      // call the original function
      const result = originalFunction(data);

      // the function will not emit an event if it returns false
      if (result === false) {
        return;
      }

      // if the function returns a promise, a notification will be sent
      // aysnchronously when it is done
      if (typeof result == 'object' && result.done) {
        result.done(() => {
          PubSub.publishSync(storeName + '.changed', store.getState());
        });
      }

      // alert store subscribers
      // the function will emit an event synchronously if it returns true
      if (result === true) {
        PubSub.publishSync(storeName + '.changed', store.getState());
      } else {
        PubSub.publish(storeName + '.changed', store.getState());
      }
    };
  }

  return store;
};
