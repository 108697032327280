// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Goals from './Goal/Goals';
import WorkspaceTimeline from './WorkspaceTimeline';

const WorkspaceBody = (props) => {
  const [allGoalsVisible, setAllGoalsVisible] = React.useState(false);

  let goalsNode = null;

  if (
    props.activeParticipant &&
    props.activeParticipant.coaching_engagement_id
  ) {
    goalsNode = (
      <Goals
        allGoalsVisible={allGoalsVisible}
        canEdit={props.canEdit}
        canHighlight={props.canHighlight}
        changeGoalFilter={props.changeGoalFilter}
        completeGoal={props.completeGoal}
        confirmUncompleteGoal={props.confirmUncompleteGoal}
        deleteGoal={props.deleteGoal}
        domains={props.domains}
        entries={props.entries}
        filterByHighlight={props.filterByHighlight}
        goalFilter={props.goalFilter}
        goals={props.goals}
        isEditing={props.isEditing}
        isFiltering={props.isFiltering}
        newGoalFormVisible={props.newGoalFormVisible}
        openModal={props.openModal}
        pageUser={props.pageUser}
        scrollTo={props.scrollTo}
        setScrollTo={props.setScrollTo}
        setAllGoalsVisible={setAllGoalsVisible}
        setIsEditing={props.setIsEditing}
        setNewGoalFormVisible={props.setNewGoalFormVisible}
        submitGoal={props.submitGoal}
        loading={props.goalLoading}
        subFocusAreas={props.subFocusAreas}
        cohortFocusAreas={props.cohortFocusAreas}
      />
    );
  }

  return (
    <div>
      {goalsNode}
      <WorkspaceTimeline {...props} allGoalsVisible={allGoalsVisible} />
    </div>
  );
};

WorkspaceBody.propTypes = {
  activeParticipant: PropTypes.object,
};

// @todo @jonathanrys move the timeline and goals into their own components

export default WorkspaceBody;
