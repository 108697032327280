// @ts-nocheck FIXME
import equal from 'fast-deep-equal';
import moment from 'moment-timezone';

type DomainHeroImageType = 'small' | 'with_gradient' | 'small_with_gradient';

const getSchoolYearForDate = (date) => {
  if (!date) {
    return null;
  }
  date = moment(date);

  // starting from the 2020 - 2021 school year,
  // we are no longer using school years to organize data,
  // so any data from that year onward is considered in the same "category"
  if (date.isSameOrAfter(moment('2020-07-01'))) {
    return '2020 - 2021';
  }

  // compare the given date to the last day of the school year in that same year
  const startOfSchoolYear = moment('7-1', 'MM-DD');
  startOfSchoolYear.year(date.year());
  return date.isBefore(startOfSchoolYear)
    ? `${date.year() - 1} - ${date.year()}`
    : `${date.year()} - ${date.year() + 1}`;
};

let objectCreate = Object.create;
if (typeof objectCreate !== 'function') {
  objectCreate = function (o) {
    function F() {}
    F.prototype = o;
    return new F();
  };
}

const Utils = {
  WORKSPACE_ABRIDGED_TEXT_HEIGHT: 130, // this translates to 5 lines of v5__body-copy text,

  UI_BASIC_COLORS: [
    '#004493',
    '#1A7CD9',
    '#2196F3',
    '#0B8484',
    '#0AABB1',
    '#53E6EB',
    '#95FFEE',
    '#107D54',
    '#33B55F',
    '#2ACAA1',
    '#25E8C8',
    '#FF6B2A',
    '#F4AB2A',
  ],

  clone: function (obj) {
    if (obj == null || typeof obj !== 'object') {
      return obj;
    }

    const objType = Object.prototype.toString.call(obj);

    // special case for arrays
    if (objType === '[object Array]') {
      const out = [],
        len = obj.length;

      let i = 0;
      for (; i < len; i++) {
        out[i] = Utils.clone(obj[i]);
      }
      return out;
    } else if (obj._isAMomentObject) {
      // Moment objects behave weirdly with the code below,
      // and Moment has its own clone() func, so use that instead
      return obj.clone();
    }

    // make sure the returned object has the same prototype as the original
    const ret = objectCreate(obj.constructor.prototype);
    for (const key in obj) {
      ret[key] = Utils.clone(obj[key]);
    }
    return ret;
  },

  getAbsolutePosition: (el) => {
    let curleft = 0;
    let curtop = 0;

    // add the offset of all parents to the current offset
    if (el.offsetParent) {
      do {
        curleft += el.offsetLeft;
        curtop += el.offsetTop;
      } while ((el = el.offsetParent));
    }

    return [curleft, curtop];
  },

  hasClass: (elem, className) => {
    if (!elem) {
      return;
    }

    if (elem.classList) {
      return elem.classList.contains(className);
    } else {
      return new RegExp('(^| )' + className + '( |$)', 'gi').test(
        elem.className
      );
    }
  },

  uuid: function () {
    // http://www.ietf.org/rfc/rfc4122.txt
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    for (const index in [8, 13, 18, 23]) {
      s[index] = '-';
    }

    const uuid = s.join('');
    return uuid;
  },

  isUrl: function (url) {
    const valid = /^(http|https):\/\/[^ "]+$/.test(url);
    return valid;
  },

  isNumeric: function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },

  toPercent: function (value, total) {
    return total < 1 ? 0 : Math.round((value / total) * 100);
  },

  capitalizeFirstLetter: function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  lowercaseFirstLetter: function (string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  },

  numberWithCommas: function (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  extend: function (obj, src) {
    for (const key in src) {
      if (Object.prototype.hasOwnProperty.call(src, key)) {
        obj[key] = src[key];
      }
    }
    return obj;
  },

  parseQueryString: function (str) {
    if (typeof str !== 'string') {
      return {};
    }

    str = str.trim().replace(/^(\?|#|&)/, '');

    if (!str) {
      return {};
    }

    return str.split('&').reduce(function (ret, param) {
      const parts = param.replace(/\+/g, ' ').split('=');
      let key = parts[0];
      let val = parts[1];

      key = decodeURIComponent(key);
      // missing `=` should be `null`:
      // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
      val = val === undefined ? null : decodeURIComponent(val);

      if (!Object.prototype.hasOwnProperty.call(ret, key)) {
        ret[key] = val;
      } else if (Array.isArray(ret[key])) {
        ret[key].push(val);
      } else {
        ret[key] = [ret[key], val];
      }

      return ret;
    }, {});
  },

  stringifyQueryString: function (obj) {
    return obj
      ? Object.keys(obj)
          .sort()
          .map(function (key) {
            const val = obj[key];

            if (Array.isArray(val)) {
              return val
                .sort()
                .map(function (val2) {
                  return (
                    encodeURIComponent(key) + '=' + encodeURIComponent(val2)
                  );
                })
                .join('&');
            }

            return encodeURIComponent(key) + '=' + encodeURIComponent(val);
          })
          .join('&')
      : '';
  },

  runPrefixMethod: function (obj, method) {
    let pfx = ['webkit', 'moz', 'ms', 'o', ''];
    let p = 0,
      m,
      t;
    while (p < pfx.length && !obj[m]) {
      m = method;
      if (pfx[p] === '') {
        m = m.substr(0, 1).toLowerCase() + m.substr(1);
      }
      m = pfx[p] + m;
      t = typeof obj[m];
      if (t !== 'undefined') {
        pfx = [pfx[p]];
        return t === 'function' ? obj[m]() : obj[m];
      }
      p++;
    }
  },

  bytesToSize: function (bytes) {
    const sizes = ['b', 'kb', 'mb', 'gb', 'tb'];
    if (bytes === 0) {
      return '0 kb';
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) {
      return bytes / Math.pow(1024, i) + ' ' + sizes[i];
    }
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  },

  getMonthName: function (date) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    // split up date replace slashes with spaces and exchange month number for name
    const dateArray = date.split('/');
    const newDate =
      monthNames[dateArray[0] - 1] + ' ' + dateArray[1] + ' ' + dateArray[2];

    return newDate;
  },

  // this logic mirrors the same function in utilities.py - please update that if you update this
  getSchoolYearForDate,

  urlFormatYear: function (schoolYear) {
    // given a school year formatted like 20XX - 20XY, returns 20XX-20XY
    return schoolYear ? schoolYear.replace(' - ', '-') : schoolYear;
  },

  shortenSchoolYear: function (schoolYear) {
    // given a school year formatted like 20XX - 20XY, returns 20XX-XY
    return schoolYear.replace(' - 20', '-');
  },

  getCurrentSchoolYear: function () {
    return getSchoolYearForDate(new Date());
  },

  getPrevSchoolYear: function (schoolYear) {
    // given a school year formatted like 20XX - 20XY, returns the previous school year
    return schoolYear.replace(/20([\d]{2})/g, (match, p1) => {
      return `20${parseInt(p1) - 1}`;
    });
  },

  getNextSchoolYear: function (schoolYear) {
    // given a school year formatted like 20XX - 20XY, returns the next school year
    return schoolYear.replace(/20([\d]{2})/g, (match, p1) => {
      return `20${parseInt(p1) + 1}`;
    });
  },

  addClass: function (elem, className) {
    if (!elem) {
      return;
    }

    if (elem.classList) {
      elem.classList.add(className);
    } else {
      elem.className += ' ' + className;
    }
  },

  removeClass: function (elem, className) {
    if (!elem) {
      return;
    }

    if (elem.classList) {
      elem.classList.remove(className);
    } else {
      elem.className = elem.className.replace(
        new RegExp(
          '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  },

  hasAncestor: function (elem, className) {
    // Traverse the DOM up with a while loop
    while (elem.className !== className) {
      // Increment the loop to the parent node
      elem = elem.parentNode;
      if (!elem) {
        return null;
      }
    }
    // At this point, the while loop has stopped and `elem` represents the elemement that has
    // the class you specified in the second parameter of the function `className`

    // Then return the matched elemement
    return elem;
  },
  inArray: function (value, array) {
    if (!array) {
      return false;
    }
    return array.indexOf(value) > -1;
  },

  // Determines if two objects are equal(deep comparison)
  // Does not handle functions or arrays
  // @param: obj1, obj2: The two objects to compare
  // @return Boolean
  areObjectsEqual: function (obj1, obj2) {
    return equal(obj1, obj2);
  },

  // Returns a random element from an array:
  getRandomValueFromArray: function (inputArray) {
    return inputArray[Math.floor(Math.random() * inputArray.length)];
  },

  // Formats the input date string in the m/d/yyyy format
  formatDate: function (input) {
    if (!Date.parse(input)) {
      return '';
    }

    const inputDate = new Date(parseFloat(Date.parse(input)));
    return (
      inputDate.getMonth() +
      1 +
      '/' +
      inputDate.getDate() +
      '/' +
      inputDate.getFullYear() +
      ' '
    );
  },

  getTimeZone: function (input) {
    if (!(input instanceof moment)) {
      return '';
    }
    const dateObject = input.toDate();

    const currentTimeZone = dateObject.toString().split('(')[1].slice(0, -1);
    return currentTimeZone;
  },

  getMetricName: function (name, defaultName) {
    // if name is not defined, just return empty string
    if (!name) {
      return '';
    }

    // Replace {N} in the name with the default value:
    return name.replace('{N}', defaultName);
  },

  isEmpty: function (obj) {
    if (!obj) {
      return true;
    }

    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) return false;
    }

    return true && JSON.stringify(obj) === JSON.stringify({});
  },

  range: function (start, end, step) {
    // set up default values (step defaults to 1 or -1 depending on start and end)
    start = start || 0;
    step = step || (start <= end ? 1 : -1);
    const list = [];

    // if the step passed in is the wrong direction, just return an empty list
    if ((start <= end && step <= 0) || (start >= end && step >= 0)) {
      return list;
    }

    // build list
    for (let i = start; i < end; i += step) {
      list.push(i);
    }
    return list;
  },

  // Gets a date object from a string
  // The string must be a valid date string
  getDateObjectFromString: function (dateString) {
    // Handle cases where the string is empty:
    if (!dateString) {
      return null;
    }
    // ios devices do not like dashes in the date string. Replace dashes with slashes
    dateString = dateString.replace(/-/g, '/');
    return new Date(dateString).valueOf();
  },

  // Determines whether a name:value pair is found in an array
  nameValuePairInArrayOfObjects: function (name, value, input) {
    // Handle bad inputs
    if (!Array.isArray(input)) {
      return false;
    }
    return input.some(function (el) {
      return el[name] === value;
    });
  },

  // Determines whether an array contains ANY item in another array
  arrayContainsAnyItemInAnotherArray: function (containerArray, checkedArray) {
    // Make sure both parameters are arrays:
    if (!Array.isArray(containerArray) || !Array.isArray(checkedArray)) {
      return false;
    }
    return containerArray.some(function (el) {
      return checkedArray.indexOf(el) > -1;
    });
  },

  sortArrayOfObjectsByProperty: (
    objectsArray,
    propertyValues,
    propertyName = 'id'
  ) => {
    // we are not mutating the original array, we are sending back a new array
    const newObjects = [];

    propertyValues.map((value) => {
      objectsArray.map((object) => {
        if (object[propertyName] !== value) {
          return;
        }
        // we don't want a reference to the old element
        newObjects.push(Object.assign({}, object));
      });
    });

    return newObjects;
  },

  getItemIndexFromArrayOfObjects: (
    objectsArray,
    propertyName,
    propertyValue
  ) => {
    if (!objectsArray.length) {
      return -1;
    }

    const result = objectsArray.findIndex(
      (object) => object[propertyName] === propertyValue
    );
    return result;
  },

  getQueryStringParameterValue: (queryString, param) => {
    param = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + param + '=([^&#]*)');
    const results = regex.exec(queryString);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },

  scrollToFirstError: (modalElem) => {
    window.setTimeout(() => {
      const firstError = document.querySelector('.error');
      const position = Utils.getAbsolutePosition(firstError);
      modalElem.scrollTop = position[1];
    }, 50);
  },

  // change the Outcome title from third person to first person,
  // for displaying on a participant's pages
  firstPersonOutcomeTitle: (title) => {
    return title.replace(/^Teachers*\b/, 'I');
  },

  legacyDateFormatter: (date) => {
    if (!date || date === '') {
      return null;
    }
    // Hacking this function so the tests pass.  This is only used in two places
    return date;
    // let formattedDate = moment(date);
    // let currentTimeZone = (formattedDate.toDate()).toString().split('(')[1].slice(0, -1);
    // return formattedDate.format('YYYY-MM-DD HH:mm:ss') + ' ' + currentTimeZone;
  },

  whichTransitionEvent: () => {
    let t;
    const el = document.createElement('fakeelement');
    const transitions = {
      transition: 'transitionend',
      OTransition: 'oTransitionEnd',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd',
    };

    for (t in transitions) {
      if (el.style[t] !== undefined) {
        return transitions[t];
      }
    }
  },

  hhmmss: (seconds, stripLeadingZeros = false) => {
    function pad(num) {
      return ('0' + num).slice(-2);
    }

    // if seconds is undefined
    if (!seconds) seconds = 0;

    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    // if necessary, return the time without any leading zeros (unless its just seconds)
    if (stripLeadingZeros) {
      if (hours) {
        return hours + ':' + pad(minutes) + ':' + pad(seconds);
      }
      return minutes + ':' + pad(seconds);
    }

    return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds);
  },

  findParentByClassName: (elem, className) => {
    while (elem.parentNode) {
      elem = elem.parentNode;
      if (Utils.hasClass(elem, className)) {
        return elem;
      }
    }
    return null;
  },

  getDomainIdForIcon: (domainId: number): string => {
    // these are the learning domain ids for which we have icons
    // in teach-cycle-icons.ttf
    if (
      [
        2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
        23, 24, 25, 28, 29, 30, 31, 32, 34, 35, 36, 48, 50, 51, 52,
      ].indexOf(domainId) === -1
    ) {
      return 'default';
    }
    return String(domainId);
  },

  getDomainIdForHeroImage: (
    domainId: number,
    type: DomainHeroImageType
  ): string => {
    // these are the learning domain ids for which we have hero images
    // in legacy-images/learning_domains/<type>
    let domainIds: number[] = [];
    switch (type) {
      case 'small':
        domainIds = [2, 3, 4, 5, 7, 8, 9, 10, 11, 14, 15, 16, 17, 18];
        break;
      case 'small_with_gradient':
        domainIds = [
          2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
          22, 23, 24, 25, 28, 29, 30, 31, 32, 34, 35, 36, 50,
        ];
        break;
      case 'with_gradient':
        domainIds = [
          2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
          22, 23, 24, 25, 28, 29, 30, 31, 32, 34, 35, 36, 50,
        ];
        break;
      default:
        break;
    }
    if (domainIds.indexOf(domainId) === -1) {
      return 'default';
    }
    return String(domainId);
  },

  objectsAlphabeticallySortHandler: (property) => {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  },

  unicodeToChar: (text) => {
    if (!text) return '';
    return text.replace(/\\u[\dA-F]{4}/gi, (match) => {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
  },

  //converts an array of HTML elements into a comma separated list in the Oxford style: ['a', 'b', 'c', 'd'] becomes 'a, b, c, and d'
  joinHTMLElementsOxfordStyle: (values) => {
    if (values.length < 1) {
      return [];
    } else if (values.length < 3) {
      return values.reduce((accumulated, next) => {
        return [accumulated, ' & ', next];
      });
    } else {
      const valuesMinusOne = values.slice(0, -1).reduce((accumulated, next) => {
        return [accumulated, ', ', next];
      });
      const finalValue = values.slice(-1)[0];
      return [valuesMinusOne, ', & ', finalValue];
    }
  },

  // Takes an array and returns a string separated with the provided separator
  separateList: (arr, separator) => {
    if (arr.length === 0) {
      return [];
    }

    return arr.slice(1).reduce(
      function (acc, item) {
        return acc.concat([separator, item]);
      },
      [arr[0]]
    );
  },

  // Takes a url and determines whether appended parameters should use & or ?
  hasQueryString: (url) => {
    if (url.search(/\?/) >= 0) {
      return '&';
    }
    return '?';
  },

  // Sorts time zones and clusters them in a way I(Sean Moss) agree with
  sortTimeZones: (a, b) => {
    const usTimeZoneValuesMap = {
      'US/Eastern': 1,
      'US/Central': 2,
      'US/Mountain': 3,
      'US/Arizona': 4,
      'US/Pacific': 5,
      'US/Alaska': 6,
      'US/Aleutian': 7,
      'America/Barbados': 8,
      'Africa/Cairo': 9,
      WET: 10,
      CET: 11,
      EET: 12,
      'Europe/Moscow': 13,
    };
    const aValue = usTimeZoneValuesMap[a.pytz];
    const bValue = usTimeZoneValuesMap[b.pytz];
    if (aValue && bValue) {
      return aValue - bValue;
    } else if (aValue) {
      return -1;
    } else if (bValue) {
      return 1;
    } else {
      return a.pytz.toLowerCase() < b.pytz.toLowerCase() ? -1 : 1;
    }
  },
  mapBLServiceNameToIllustration: (serviceName) => {
    const serviceMap = {
      'Virtual Workshop':
        '/legacy-images/v5/services/bl-virtual-learning-sessions.png',
      'Virtual Workshop Series':
        '/legacy-images/v5/services/bl-virtual-learning-sessions.png',
      'Learning Series':
        '/legacy-images/v5/services/bl-virtual-learning-sessions.png',

      'On Demand Coaching':
        '/legacy-images/v5/services/bl-on-demand-coaching.png',

      'Learning Walk': '/legacy-images/v5/services/bl-learning-walk.png',
      'Onsite Coaching': '/legacy-images/v5/services/bl-learning-walk.png',
      Observation: '/legacy-images/v5/services/bl-learning-walk.png',

      'Design Studio/Launch':
        '/legacy-images/v5/services/bl-design-workshop.png',
      'Design Workshop': '/legacy-images/v5/services/bl-design-workshop.png',
      Custom: '/legacy-images/v5/services/bl-design-workshop.png',

      Course: '/legacy-images/v5/services/bl-course.png',

      '1:1 Coaching': '/legacy-images/v5/services/coaching.png',
    };
    return (
      serviceMap[serviceName] ||
      '/legacy-images/v5/services/bl-design-workshop.png'
    );
  },

  hourRounder: function (time) {
    let minutes = time.format(':mm');
    if (minutes === ':00') {
      minutes = '';
    }
    const hour = time.format('h');
    return `${hour}${minutes}`;
  },

  handleFormatDate: (startDateToFormat = null, endDateToFormat = null) => {
    let startDate = null;
    let startDateFormatted = '';
    if (startDateToFormat) {
      // parse and format the start date if it exists
      startDate = moment(startDateToFormat);
      startDateFormatted = startDate.format('LL');
    }

    let endDate = null;
    let endDateFormatted = '';
    if (endDateToFormat) {
      // parse and format the end date if it exists
      endDate = moment(endDateToFormat);
      endDateFormatted = endDate.format('LL');
    }

    let dateSeperator = '';
    if (startDateToFormat && endDateFormatted) {
      if (startDate.isSame(endDate)) {
        // if they are on the same day, only show one date
        endDateFormatted = '';
      } else {
        dateSeperator = '\u2013';
        // If they are in the same year, only show the year once
        if (startDate.format('YYYY') === endDate.format('YYYY')) {
          startDateFormatted = startDate.format('MMMM D');
          // If they are in the same month, only show the month once
          if (startDate.format('MM') === endDate.format('MM')) {
            endDateFormatted = startDate.format('D YYYY');
          }
        }
      }
    }

    return `${startDateFormatted}${dateSeperator}${endDateFormatted}`;
  },

  getEventSessionName: (
    eventName,
    sessionName,
    groupName = '',
    numSessions = 0
  ) => {
    groupName = groupName && groupName.length ? `, ${groupName}` : '';

    if (numSessions > 1) {
      return `${sessionName}${groupName}`;
    }
    return `${eventName}`;
  },

  // startTime is session_start_time_formatted
  // endTime is session_end_time_formatted
  // timezone is the event's pytz_timezone
  formatEventSessionTime: function (
    startTime,
    endTime,
    timezone,
    showTimezone = true
  ) {
    // if either time is missing, we display nothing
    if (!startTime || !endTime) {
      return '';
    }

    // set up the start/end time values to display based on the event timezone
    let start = moment(startTime);
    let end = moment(endTime);
    if (timezone) {
      start = moment.utc(startTime).tz(timezone);
      end = moment.utc(endTime).tz(timezone);
    }

    // we only show the start time AM/PM value if it is different from the end time AM/PM value
    const endAmPm = end.format('A');
    const startAmPm =
      start.format('A') === endAmPm ? '' : ` ${start.format('A')}`;

    // in most places we show the timezone abbreviation after the time
    const tz =
      timezone && showTimezone ? ` ${moment.tz(timezone).zoneAbbr()}` : '';

    return `${this.hourRounder(start)}${startAmPm} - ${this.hourRounder(
      end
    )} ${endAmPm}${tz}`;
  },

  eventUpcomingSort: (event_a, event_b) => {
    // the Dates column is sorted based on the next session date of each event, like so:
    // 1. list all groups with any future session dates, from closest to furthest
    // 2. list all groups with no session dates
    // 3. list all groups with only past session dates, from most recent to least recent
    let aPrevDate;
    let aNextDate;
    event_a.sessions.forEach((session) => {
      // if this session has no date, skip it
      if (!session.session_date) {
        return;
      }
      const startTime = session.session_start_time_formatted
        ? moment(session.session_start_time_formatted)
        : moment(session.session_date_formatted);
      // replace Event A's previous session date if this session started in the most recent past
      if (startTime < moment() && (!aPrevDate || aPrevDate < startTime)) {
        aPrevDate = startTime;
      }
      // replace Event A's next session date if this session starts closer in the future
      if (startTime > moment() && (!aNextDate || aNextDate > startTime)) {
        aNextDate = startTime;
      }
    });
    let bPrevDate;
    let bNextDate;
    event_b.sessions.forEach((session) => {
      // if this session has no date, skip it
      if (!session.session_date) {
        return;
      }
      const startTime = session.session_start_time_formatted
        ? moment(session.session_start_time_formatted)
        : moment(session.session_date_formatted);
      // replace Event B's previous session date if this session started in the most recent past
      if (startTime < moment() && (!bPrevDate || bPrevDate < startTime)) {
        bPrevDate = startTime;
      }
      // replace Event B's next session date if this session starts closer in the future
      if (startTime > moment() && (!bNextDate || bNextDate > startTime)) {
        bNextDate = startTime;
      }
    });
    // if both events have a next session date, order the two as usual
    if (aNextDate && bNextDate) {
      return aNextDate - bNextDate;
    }
    // if Event A is in a category that comes before Event B's category, keep the order
    else if (
      (aNextDate && !bNextDate) ||
      (!aNextDate && !aPrevDate && !bNextDate && bPrevDate)
    ) {
      return -1;
    }
    // if Event B is in a category that comes before Event A's category, switch them
    else if (
      (!aNextDate && bNextDate) ||
      (!aNextDate && aPrevDate && !bNextDate && !bPrevDate)
    ) {
      return 1;
    }
    // finally, if both have previous session dates, order the two as usual
    else if (aPrevDate && bPrevDate) {
      return bPrevDate - aPrevDate;
    }
    return 0;
  },
  /**
   * given a string representing an ordinal number (e.g. first, second)
   * returns object {number, ordinal} of the word (e.g. {number: 1, ordinal: st})
   * @param {string} numString ordinal string range from first to twelfth (1-12)
   * @returns {object|string} object contains number and its ordinal, or original string if not in the mapping
   */
  ordinalWordToNumber: (numString) => {
    const wordToNumMapping = {
      first: { number: 1, ordinal: 'st' },
      second: { number: 2, ordinal: 'nd' },
      third: { number: 3, ordinal: 'rd' },
      fourth: { number: 4, ordinal: 'th' },
      fifth: { number: 5, ordinal: 'th' },
      sixth: { number: 6, ordinal: 'th' },
      seventh: { number: 7, ordinal: 'th' },
      eighth: { number: 8, ordinal: 'th' },
      ninth: { number: 9, ordinal: 'th' },
      tenth: { number: 10, ordinal: 'th' },
      eleventh: { number: 11, ordinal: 'th' },
      twelfth: { number: 12, ordinal: 'th' },
    };
    if (numString.toLowerCase() in wordToNumMapping) {
      return wordToNumMapping[numString.toLowerCase()];
    } else {
      return numString;
    }
  },
  /**
   * handles formatting of coaching engagement status (status in c_participant_note)
   * so if we want different wording on the UI but not backend we can easily adjust
   * @param {string} statusString the string representing the status
   * @returns a string representing the status (with possibly altered wording)
   */
  formatEngagementStatus: (statusString = '') => {
    let engagementStatus;
    switch (statusString) {
      case 'Disengaged':
        engagementStatus = 'Inactive';
        break;
      default:
        engagementStatus = statusString;
        break;
    }
    return engagementStatus;
  },
  /**
   * given a string representing time and its original timezone
   * convert into corresponding timezone with the same format
   * @param {string} timeOption the option, like "1 AM", "11 PM"
   * @param {string} fromTimeZone pytz_timezone formatted timezone, like "US/Pacific"
   * @param {string} toTimeZone pytz_timezone formatted timezone, like "US/Eastern"
   *
   * @returns {string} a string representing time, like "4 AM", "2AM"
   */
  formatAvailabilityTimeOptions: (timeOption, fromTimeZone, toTimeZone) => {
    // if timeOption does not look like something that can be parsed, return it
    if (timeOption.split(' ').length !== 2) {
      return timeOption;
    }
    // if from and to are in the same timezone then no conversion needed
    if (fromTimeZone === toTimeZone) {
      return timeOption;
    }
    // retrieve current time, to extract date and numeric timezone information
    const now = moment.tz(fromTimeZone);
    const dateString = now.format('YYYY-MM-DDT');
    const timeZonePostfix = now.format('ZZ');
    let hour = timeOption.split(' ')[0];
    const ampm = timeOption.split(' ')[1];

    if (ampm === 'PM') {
      hour = (parseInt(hour) + 12).toString();
    }

    if (hour.length === 1) {
      hour = `0${hour}`;
    }
    // we have all the information, now stitch them together
    // it will look like "2021-11-11T13:00-0400"
    const timeString = `${dateString}${hour}:00${timeZonePostfix}`;
    // only get the hour and ampm and return
    return moment.tz(timeString, toTimeZone).format('h A');
  },
  /**
   * given a status string representing coaching engagement,
   * returns if the engagement is inactive or not
   * @param {string} coachingEngagementStatus
   * @returns {boolean} whether the coaching engagement is active and
   * their workspace / participant details should be archived (if not active)
   */
  isCoachingEngagementActive: (coachingEngagementStatus) => {
    const archivedList = ['Done with Coaching', 'Dropped'];
    return !archivedList.includes(coachingEngagementStatus);
  },
  /**
   * given a status ID representing a publish status (ld_publish_status)
   * currently applies to focus areas and outcomes
   * returns the text representing the status
   * @param {integer} publishStatusId
   * @returns {string} the text representing the status (or empty string if not found)
   */
  getCuratedContentPublishStatusText: (publishStatusId) => {
    if (!publishStatusId) {
      return '';
    }

    const LDPublishStatuses = {
      1: 'Proposed',
      2: 'Live',
      3: 'Retired',
    };

    const statusText = LDPublishStatuses[publishStatusId];
    if (statusText !== undefined) {
      return statusText;
    }
    return '';
  },
};

export default Utils;
