// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import { useEffect, useState } from 'react';
import Fail from './Fail';
import networkList from './NetworkList';
import Networks from './Networks';
import Pass from './Pass';

const Networkcheck = (props) => {
  const [loading, setLoading] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);
  const [checkedNetworks] = useState([...networkList]);
  const [progress, setProgress] = useState(0);

  const canAccess = async (network, index) => {
    const headers = new Headers().append('Content-Type', 'text/html');
    const request = new Request(network.url);
    const init = {
      method: 'GET',
      headers,
      mode: 'no-cors',
      cache: 'default',
    };

    try {
      const results = await fetch(request, init);
      if (results) {
        const index = checkedNetworks.findIndex(
          (checked) => checked.url === network.url
        );
        checkedNetworks[index].status = 'success';
        checkedNetworks[index].statusLabel = 'Good connection';
      }
    } catch (e) {
      setHasErrors(true);
      const index = checkedNetworks.findIndex(
        (checked) => checked.url === network.url
      );
      checkedNetworks[index].status = 'error';
      checkedNetworks[index].statusLabel = 'Connection issue';
    }

    setProgress((prev) => Math.round(prev + 100 / networkList.length));
    if (index === networkList.length - 1) setLoading(false);
  };

  const init = () => {
    networkList.forEach(async (network, index) => {
      await canAccess(network, index);
    });
  };

  useEffect(() => {
    init();
  }, []);

  const testing = () => (
    <div className="row tc__center-text">
      <p className="v5__body-copy network-testing">
        Testing your connections to our network, please wait a moment...
      </p>

      <p className="v5__body-copy">
        <progress value={progress} max="100" /> {progress}%
      </p>
    </div>
  );

  const footer = () => (
    <p className="v5__body-copy network-footer">
      If you still need assistance, visit our{' '}
      <a
        href="https://betterlesson.atlassian.net/servicedesk/customer/portal/2/group/4"
        target="_blank"
        rel="noreferrer"
      >
        Help Center
      </a>{' '}
      or reach out to your Customer Success Manager.
    </p>
  );

  return (
    <>
      <Header {...props} page="Network Check" />

      <div className="network-check-page">
        <div className="row tc__center-text">
          <div className="network-heading">
            <h1 className="v5__heading-3">
              To provide you with the best experience, BetterLesson needs to
              check your network access.
            </h1>

            <p className="v5__body-copy">
              Please ensure you are testing the network where you plan to access
              BetterLesson&rsquo;s services.
            </p>
          </div>

          <hr />
        </div>

        {loading && testing()}
        {hasErrors && !loading && <Fail checkedNetworks={checkedNetworks} />}
        {!hasErrors && !loading && <Pass />}
        <Networks checkedNetworks={checkedNetworks} />
        <div className="row tc__center-text">{footer()}</div>
      </div>
    </>
  );
};

export default Networkcheck;
