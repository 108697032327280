// @ts-nocheck FIXME
import Analytics from '@legacy-components/components/Analytics';
import Utils from '@legacy-components/components/DynamicUtils';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import CKEditor from './../../Common/CKEditor/CKEditor';

const DescriptionTextBox = (props) => {
  // set up variables for analytics
  let analyticsCategory = 'vision';
  let actionSubject = 'vision';
  let addActionContext = 'add';
  let cancelActionContext = 'add';
  if (props.content) {
    cancelActionContext = 'edits';
    addActionContext = 'save_edits';
  }
  if (props.feature === 'design') {
    analyticsCategory = 'design_summary';
    actionSubject = 'summary';
  }
  const handleEditClick = (e) => {
    e.preventDefault();
    const analyticsAction = Utils.hasClass(
      e.target,
      'bl4-profile__pd-text--add'
    )
      ? 'add_' + actionSubject + '_cta'
      : 'edit';

    Analytics.logEvent({
      category: analyticsCategory,
      action: analyticsAction,
      label: 'from_profile',
    });
    props.changeMode({
      feature: props.feature,
      mode: 'edit',
    });
  };
  const handleCancelClick = (e) => {
    e.preventDefault();
    Analytics.logEvent({
      category: analyticsCategory,
      action: 'cancel_' + cancelActionContext,
      label: 'from_profile',
    });

    // @TODO check if content exists to decide which feature
    props.changeMode({
      feature: props.feature,
      mode: 'empty',
    });
  };
  const handleSubmitText = (e) => {
    e.preventDefault();
    Analytics.logEvent({
      category: analyticsCategory,
      action: addActionContext,
      label: 'from_profile',
    });
    const ckeDescriptionInstanceId =
      'profile__text-wysiwyg-textEntry--' + props.feature + '-0';

    props.submitTextArea({
      feature: props.feature,
      value: CKEDITOR.instances[ckeDescriptionInstanceId].getData(),
    });
  };

  // set button copy if adding or editing
  const buttonCopy = props.content ? 'Update' : 'Add';

  // disable links when page action is in progress
  const disabledActionClass = props.activeAction ? ' disabled' : '';

  let profileTextNode,
    editBtnNode = null;
  if (props.userCanEditData) {
    const caption =
      props.feature === 'vision'
        ? '+ Add Vision of Student Success'
        : '+ Add a Design Summary';
    profileTextNode = (
      <div className="bl4-profile__pd-text-content">
        <a
          className={'bl4-profile__pd-text--add tc__link' + disabledActionClass}
          onClick={handleEditClick}
        >
          {caption}
        </a>
      </div>
    );
    editBtnNode = (
      <a onClick={handleEditClick}>
        <div className={'bl4-profile__pd--edit tc__link' + disabledActionClass}>
          <div className="tc-pencil"></div>
          <div>Edit</div>
        </div>
      </a>
    );
  }

  if (props.content) {
    profileTextNode = (
      <div className="bl4-profile__pd-text-content">
        <PurifiedHTML className="ckeditor__output" content={props.content} />
        {editBtnNode}
      </div>
    );
  }
  if (props.mode === 'edit' && props.userCanEditData) {
    profileTextNode = (
      <div className="bl4-profile__pd-text-content">
        <CKEditor
          id={'textEntry--' + props.feature}
          editorId={'profile--' + props.feature}
          increment={0}
          innerText={props.content}
          className="profile__text-wysiwyg"
          configs="/legacy-libs/configs/bl-cke-config-story.js"
        />
        <div className="story__edit-entry-form-btns">
          <button
            className="bl4__btn bl4__btn--blue story__edit-entry-form-btn"
            onClick={handleSubmitText}
          >
            {buttonCopy}
          </button>
          <button
            className="bl4__btn bl4__btn--blue-border story__edit-entry-form-btn"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bl4-profile__pd-text">
      <h3 className="bl4-profile__pd-text-headline tc__headline-5">
        {props.title}
      </h3>
      <div className="bl4-profile__pd-text-description">
        {props.description}
      </div>
      {profileTextNode}
    </div>
  );
};

DescriptionTextBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  userCanEditData: PropTypes.bool,
};

export default DescriptionTextBox;
