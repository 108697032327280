// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import PropTypes from 'prop-types';
import { getDefaultSessions } from '../utils/Sessions';
import GroupCapsule from './GroupCapsule';
import SessionCapsule from './SessionCapsule';

const GroupsAndSessionsBasic = (props) => {
  // add the index to every group object, and hide deleted groups
  const groups = props.groups
    .map((group, i) => Object.assign(group, { index: i }))
    .filter((group) => !group.deleted);

  // add the index to every session object
  let sessions = getDefaultSessions(
    props.sessions.map((session, i) => Object.assign(session, { index: i })),
    groups
  );

  // each session that is displayed should have the total attendee count
  // for that session num across all groups
  sessions = Utils.clone(sessions);
  sessions.forEach((session) => {
    session.attendee_count = props.sessions
      .filter((s) => {
        return !s.deleted && s.num === session.num;
      })
      .reduce((prev, curr) => {
        return curr.attendee_count ? prev + curr.attendee_count : prev;
      }, 0);

    // also track whether any session with this num has either
    // any facilitators assigned OR modifications done on the session materials
    session.matchingSessionHasFacilitatorsOrMods = props.sessions.some((s) => {
      return (
        !s.deleted &&
        s.num === session.num &&
        ((s.facilitators &&
          s.facilitators.some((facilitator) => !facilitator.deleted)) ||
          s.modifications_finalized)
      );
    });
  });

  // hide the UI elements for adding/editing/deleting by default
  let addGroupNode;
  let switchModesCapsule;
  let addSessionNode;
  if (props.canEditEventDetails) {
    // set up the "Add a Group" button
    let addGroupBtnClass = '';
    let addGroupBtnText = 'Add a Group';
    if (!groups.length) {
      addGroupBtnClass = 'edit-event__add-btn--empty';
      addGroupBtnText = 'Create Groups';
    }
    addGroupNode = (
      <button
        onClick={props.addGroup}
        className={`v5__btn v5__btn--blue-border edit-event__add-btn ${addGroupBtnClass}`}
      >
        {addGroupBtnText}
      </button>
    );

    // set up the option to switch modes
    if (groups.length) {
      switchModesCapsule = (
        <div className="edit-event__switch-modes-capsule">
          <div className="v5__heading-5 edit-event__switch-modes-capsule-headline-row">
            <div className="tc-v5-event-product edit-event__row-start-icon"></div>
            <div>Use Advanced Event Builder?</div>
          </div>
          <div className="v5__body-copy edit-event__switch-modes-capsule-body-text">
            If session information is not the same across all groups, use the
            Advanced Event Builder to create your event. In this mode, you will
            have to manually add and remove sessions for each group.
          </div>
          <button
            onClick={props.switchToAdvancedMode}
            className="v5__btn v5__btn--blue-border edit-event__add-btn"
          >
            Switch to Advanced Builder
          </button>
        </div>
      );
    }

    // set up the "Add a Session" button
    addSessionNode = (
      <button
        onClick={props.addSession}
        className="v5__btn v5__btn--blue-border edit-event__add-btn"
      >
        Add a Session
      </button>
    );
  }

  return (
    <div className="edit-event__bottom-section">
      <h2 className="v5__heading-4 edit-event__section-headline">
        Event Groups & Sessions
      </h2>

      <div className="v5__heading-5 edit-event__section-headline-row">
        <div className="tc-v5-group edit-event__row-start-icon"></div>
        <div>Groups</div>
      </div>
      <div className="v5__body-copy v5__body-copy--italic edit-event__subsection-description">
        Groups allow you to divide an event into separate rooms and among
        facilitators.
      </div>

      {groups.map((group) => {
        return (
          <GroupCapsule
            key={`group-${group.num}`}
            {...group}
            canEditEventDetails={props.canEditEventDetails}
            update={(data) => props.updateGroup(group.index, data)}
            delete={() => props.deleteGroup(group)}
            deleteDisabled={
              groups.length === 2 && groups.some((g) => g.participant_count)
            }
            allProductCodes={props.allProductCodes}
            confirmDownsizeGroup={(data) => props.confirmDownsizeGroup(data)}
            timezone={props.timezone}
            scrollTo={props.scrollTo}
          />
        );
      })}

      {addGroupNode}

      <div className="v5__heading-5 edit-event__section-headline-row">
        <div className="tc-v5-book edit-event__row-start-icon"></div>
        <div>Sessions</div>
      </div>
      <div className="v5__body-copy v5__body-copy--italic edit-event__subsection-description">
        Sessions are sections of an event that require separate facilitators,
        agendas, and/or decks. Sessions can represent days of an event or
        portions of a day (e.g. morning and afternoon sessions).
      </div>

      {switchModesCapsule}
      {sessions.map((session) => {
        return (
          <SessionCapsule
            key={`session-${session.id}-${session.num}`}
            {...session}
            singleSession={sessions.length === 1}
            canEditEventDetails={props.canEditEventDetails}
            update={(data) => props.updateSessionsByNum(session.num, data)}
            delete={() => props.deleteSession(session)}
            allProductCodes={props.allProductCodes}
            timezone={props.timezone}
            confirmUndeliverSession={
              !groups.length
                ? (data) => props.confirmUndeliverSession(data)
                : null
            }
            scrollTo={props.scrollTo}
          />
        );
      })}

      {addSessionNode}
    </div>
  );
};

GroupsAndSessionsBasic.propTypes = {
  switchToAdvancedMode: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  addGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  sessions: PropTypes.array.isRequired,
  addSession: PropTypes.func.isRequired,
  updateSessionsByNum: PropTypes.func.isRequired,
  deleteSession: PropTypes.func.isRequired,
  timezone: PropTypes.object.isRequired,
  canEditEventDetails: PropTypes.bool,
  confirmUndeliverSession: PropTypes.func.isRequired,
};

export default GroupsAndSessionsBasic;
