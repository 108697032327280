// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import StringHelpers from '@legacy-components/components/StringHelpers';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Institution from '@legacy-components/react/V5/Common/Institution/Institution';
import React, { useState } from 'react';
import Availability from './modules/Availability';
import ImageUpload from './modules/ImageUpload';
import OptionSetting from './modules/OptionSettings';
import Role from './modules/Role';
import Wheelhouse from './modules/Wheelhouse';

const ProfileSettingsForm = (props) => {
  const lab = useLegacyLabContext();
  // form fields photo and multiselects
  const [userPhoto, setUserPhoto] = useState(props.pageUser.photo || null);
  const [subjects, setSubjects] = useState(props.pageUser.subjects || []);
  const [grades, setGrades] = useState(props.pageUser.grades || []);

  // institution single select state
  const [institutionId, setInstitutionId] = useState(null);
  const [institutionName, setInstitutionName] = useState(null);
  const [institutionZipcode, setInstitutionZipcode] = useState(null);

  const [institutionSelectedOption, setInstitutionSelectedOption] = useState(
    props.pageUser.institution
      ? {
          id: props.pageUser.institution.id,
          label: props.pageUser.institution.name,
          description: props.pageUser.institution.mailing_zipcode,
        }
      : null
  );

  const [noSchool, setNoSchool] = useState(false);

  // form fields
  const [role, setRole] = useState(props.pageUser.occupation_id || null);
  const [firstName, setFirstName] = useState(props.pageUser.first_name);
  const [lastName, setLastName] = useState(props.pageUser.last_name);
  const [bio, setBio] = useState(props.pageUser.professional_bio);
  const [ell, setEll] = useState(props.pageUser.ell);
  const [sped, setSped] = useState(props.pageUser.sped);
  const [experience, setExperience] = useState(props.pageUser.experience);

  const [selectedAvailabilities, setSelectedAvailabilities] = useState(
    props.pageUser.contractCoachAvailabilities &&
      props.pageUser.contractCoachAvailabilities.length
      ? props.pageUser.contractCoachAvailabilities.filter(
          (option) => option.answer === 'yes'
        )
      : []
  );

  const [selectedWheelhouseOptions, setSelectedWheelhouseOptions] = useState(
    props.pageUser.wheelHouse || []
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    // clear errors on submit
    props.setErrors({});
    props.clearAlert();

    const formErrors = {};

    // first name is required
    if (!StringHelpers.isValid(firstName)) {
      formErrors.firstName = 'Please enter your first name';
    }

    // last name is required
    if (!StringHelpers.isValid(lastName)) {
      formErrors.lastName = 'Please enter your last name';
    }

    // validate not been teaching for over 100 years as this is unlikley to be true
    if (experience && (experience > 100 || experience < 0)) {
      formErrors.experience = 'Please enter a value between 0 and 100';
    }

    if (
      props.pageUser.institution_id &&
      institutionSelectedOption === null &&
      (!institutionName || !institutionZipcode)
    ) {
      formErrors.schoolName = 'Institution is required';
      formErrors.schoolSearch = 'Institution is required';
    }

    // if the user fills out one of the two custom school forms, the other is required
    if (institutionName && !StringHelpers.isValid(institutionZipcode)) {
      formErrors.schoolZipcode = "Please enter your school's zip code";
    }

    // school name is required if zip has been entered
    if (!StringHelpers.isValid(institutionName) && institutionName) {
      formErrors.schoolName = "Please enter your school's name";
    }

    // initialize data
    const data = {
      firstName: firstName,
      lastName: lastName,
      grades: grades.map((grade) => grade.id),
      subjects: subjects.map((subject) => subject.id),
      professionalBio: bio,
      ell: parseInt(ell),
      sped: parseInt(sped),
      experience: experience,
    };

    if (role) {
      data['occupation'] = role;
    }
    // if we are showing the custom school fields, include those
    if (institutionName) {
      data['schoolName'] = institutionName;
    }
    if (institutionZipcode) {
      data['schoolZipcode'] = institutionZipcode;
    }

    // not institution custom entry
    if (!institutionName && !institutionZipcode) {
      // something is selected from the dropdown
      if (institutionId) {
        data['institutionId'] = institutionId;
      }

      // user clicked no school link
      if ((!institutionId || institutionId === 0) && setNoSchool) {
        data['noSchool'] = noSchool;
      }
    }

    if (Utils.isContractCoach.call(lab, props.pageUser)) {
      data['availabilityOptions'] = selectedAvailabilities;
      data['wheelhouseOptions'] = selectedWheelhouseOptions;
    }

    // set errors
    if (Object.keys(formErrors).length) {
      props.setErrors(formErrors);
      return;
    }

    // clear unsaved changes
    props.setUnSavedChanges(false);

    // submit data
    props.submit(data);
  };

  const ellOptions = Object.keys(props.ellAnswers);
  ellOptions.reverse();

  // disable buttons when form submission is loading
  let disabledSubmitClass = '';
  let spinnerNode = null;
  if (props.loading) {
    disabledSubmitClass = ' disabled';
    spinnerNode = <span className="littlespinner"></span>;
  }
  let educatorProfileNode = (
    <React.Fragment>
      <h3 className="account-form__header tc__headline-4" id="section-profile">
        Educator Profile
      </h3>

      <div className="form-field">
        <Institution
          setUnSavedChanges={props.setUnSavedChanges}
          institution={props.pageUser.institution || {}}
          setNoSchool={setNoSchool}
          selectedOption={institutionSelectedOption}
          setSelectedOption={setInstitutionSelectedOption}
          setInstitutionId={setInstitutionId}
          setInstitutionName={setInstitutionName}
          setInstitutionZipcode={setInstitutionZipcode}
          institutionNameError={props.errors.schoolName}
          institutionZipCodeError={props.errors.schooZipcode}
          setAlert={props.setAlert}
          setErrors={props.setErrors}
          errors={props.errors}
        />
      </div>

      <div className="settings__label">Role</div>
      <Role
        dataTestId="role-field"
        setUnSavedChanges={props.setUnSavedChanges}
        occupations={props.occupations}
        role={parseInt(role)}
        setRole={setRole}
        error={props.errors.role}
      />

      <div className="settings__label">Grades</div>
      <OptionSetting
        dataTestId="grades-field"
        label={'grades'}
        selectedOptions={grades}
        allOptions={props.allGrades}
        updateOptions={setGrades}
      />

      <div className="settings__label">Subjects</div>

      <OptionSetting
        dataTestId="subjects-field"
        label={'subjects'}
        selectedOptions={subjects}
        allOptions={props.allSubjects}
        updateOptions={setSubjects}
      />

      <div className="settings__label">
        In your role, are you <span className="italic">primarily</span> focused
        on English Learners (EL)?
      </div>
      <FormField
        dataTestId="ell-field"
        type="select"
        disabled={props.loading}
        error={props.errors.ell}
        onChange={(e) => {
          setEll(e.target.value);
          props.setUnSavedChanges(true);
        }}
        value={ell !== null ? ell : 'Select'}
        isControlled={true}
      >
        <option disabled>Select</option>
        {ellOptions.map((id) => {
          return (
            <option key={id} value={id}>
              {props.ellAnswers[id]}
            </option>
          );
        })}
      </FormField>

      <div className="settings__label">
        Do you primarily teach students with Special Needs?
      </div>
      <FormField
        dataTestId="sped-field"
        type="select"
        disabled={props.loading}
        error={props.errors.sped}
        onChange={(e) => {
          setSped(e.target.value);
          props.setUnSavedChanges(true);
        }}
        defaultValue={sped ? sped : 'Select'}
      >
        <option disabled>Select</option>
        {Object.keys(props.spedAnswers).map((id) => {
          return (
            <option key={id} value={id}>
              {props.spedAnswers[id]}
            </option>
          );
        })}
      </FormField>

      <div className="settings__label">Years of Experience</div>
      <FormField
        dataTestId="experience-field"
        type="text"
        disabled={props.loading}
        error={props.errors.experience}
        onChange={(e) => {
          setExperience(e.target.value);
          props.setUnSavedChanges(true);
        }}
        defaultValue={experience}
      />
    </React.Fragment>
  );
  let coachingInfoHeader = null;
  if (Utils.isContractCoach.call(lab, props.pageUser)) {
    educatorProfileNode = null;
    coachingInfoHeader = (
      <h3 className="account-form__header tc__headline-4" id="section-coaching">
        Coaching Information
      </h3>
    );
  }

  return (
    <div className="cute-6-desktop cute-12-phone left account-content">
      <form>
        <h1 className="tc__headline-1" id="section-basic-info">
          About Me
        </h1>
        <div className="form-field">
          <ImageUpload
            user={props.user}
            pageUser={props.pageUser}
            setUserPhoto={setUserPhoto}
            userPhoto={userPhoto}
          />
        </div>

        <div className="settings__label">First Name</div>
        <FormField
          type="text"
          dataTestId="first-name-field"
          disabled={props.loading}
          error={props.errors.firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            props.setUnSavedChanges(true);
          }}
          defaultValue={props.pageUser.first_name}
        />

        <div className="settings__label">Last Name</div>
        <FormField
          type="text"
          dataTestId="last-name-field"
          disabled={props.loading}
          error={props.errors.lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            props.setUnSavedChanges(true);
          }}
          defaultValue={props.pageUser.last_name}
        />

        <div className="settings__label">Bio</div>
        <FormField
          dataTestId="bio-field"
          type="textarea"
          disabled={props.loading}
          error={props.errors.bio}
          rows={4}
          className="settings__input"
          onChange={(e) => {
            setBio(e.target.value);
            props.setUnSavedChanges(true);
          }}
          defaultValue={props.pageUser.professional_bio}
        />
        {coachingInfoHeader}
        <Wheelhouse
          pageUser={props.pageUser}
          gradeBands={props.gradeBands}
          topLevelSubjects={props.topLevelSubjects}
          setUnSavedChanges={props.setUnSavedChanges}
          selectedWheelhouseOptions={selectedWheelhouseOptions}
          setSelectedWheelhouseOptions={setSelectedWheelhouseOptions}
        />

        <Availability
          availabilityOptions={props.availabilityOptions}
          pageUser={props.pageUser}
          setSelectedAvailabilities={setSelectedAvailabilities}
          setUnSavedChanges={props.setUnSavedChanges}
          selectedAvailabilities={selectedAvailabilities}
        />

        {educatorProfileNode}

        <button
          onClick={(e) => handleSubmit(e)}
          className={`account-submit bl4__btn bl4__btn--blue ${disabledSubmitClass}`}
        >
          {spinnerNode}
          Save
        </button>
      </form>
    </div>
  );
};

export default ProfileSettingsForm;
