// @ts-nocheck FIXME
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Utils from './../../../components/DynamicUtils';
import ArtifactImage from './../../V4/Common/ArtifactImage';
import EntryTags from './../Workspace/modules/Entries/modules/EntryTags';
import SelectAuthorDropdown from './../Workspace/modules/Entries/modules/SelectAuthorDropdown';
import FormField from './FormField';
import Spinner from './Spinner';

const ArtifactForm = (props) => {
  // set the initially selected author
  const [author, setAuthor] = useState(
    props.author ? props.author.id : props.currentUser.id
  );
  const [title, setTitle] = useState(Utils.unicodeToChar(props.data.title));
  const [description, setDescription] = useState(props.data.description);
  const [hidePreview, setHidePreview] = useState(props.data.hide_preview);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleFocus = (field) => {
    // clear any field error
    if (errors[field]) {
      const newErrors = Utils.clone(errors);
      delete newErrors[field];
      setErrors(newErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // first, clear any form errors
    setErrors({});

    // validation
    const formErrors = {};
    if (!title) {
      formErrors.title = 'Hold on, you need to enter a title to continue.';
    }
    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);

    // build API data
    const data = {
      type: props.type,
      storyArtifactId: props.storyArtifactId,
      title: title,
      description: description,
      hide_preview: hidePreview,
      goals: props.currentGoals,
      storyId: props.storyId,
    };
    // if the current user is allowed to change the authorship, pass that in
    if (props.canEditAuthor && author) {
      data.authorId = author;
    }
    // otherwise, if the current user is the workspace owner,
    // make sure they are set as the author
    else if (props.currentUser.id === props.pageUser.id) {
      data.authorId = props.currentUser.id;
    }

    const errorCallback = () => setLoading(false);
    props.editArtifact(data, errorCallback);
  };

  // show the right icon for file vs link
  const iconClass = props.type === 'link' ? 'tc-v4-link' : 'tc-note';

  // show any artifact preview image
  let imageNode;
  if (!hidePreview && props.data.connections) {
    imageNode = (
      <div className="workspace__entry-removable-img">
        <div className="">
          <Tooltip
            tooltip="Remove link preview"
            tipClass="workspace__entry-image-remove--tooltip"
          >
            <div
              className="tc-x workspace__entry-image-remove"
              onClick={() => setHidePreview(true)}
            ></div>
          </Tooltip>
        </div>
        <ArtifactImage
          preview={props.data.connections.preview}
          title={props.data.connections.name}
          containerClass="workspace__entry-image-container"
          icon={props.data.connections.icon}
          imageClass="workspace__entry-image"
        />
      </div>
    );
  }

  // set up the author select, if the current user is allowed to edit it
  let authorSelectNode;
  if (props.canEditAuthor) {
    authorSelectNode = (
      <SelectAuthorDropdown
        user={props.currentUser}
        pageUser={props.pageUser}
        author={props.author}
        newAuthorId={author}
        updateAuthorId={setAuthor}
        canEditAuthor={props.canEditAuthor}
      />
    );
  }

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  // only show the delete icon when editing an entry, not when creating a new entry
  let deleteIconNode;
  if (props.editMode && props.confirmDelete) {
    deleteIconNode = (
      <span
        onClick={props.confirmDelete}
        className="tc-delete workspace__delete-icon"
      ></span>
    );
  }

  return (
    <form>
      <div className="v5__heading-5 workspace__entry-header-row workspace__entry-header-row--editing">
        <div className={`${iconClass} workspace__entry-header-icon`}></div>
        <div className="workspace__entry-header-text">
          <FormField
            type="text"
            placeholder="Title"
            defaultValue={Utils.unicodeToChar(props.data.title)}
            autoFocus={true}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={() => handleFocus('title')}
            error={errors.title}
          />
        </div>
      </div>

      <div className="workspace__entry-body">
        <div className="workspace__entry-image-row">
          {imageNode}

          <FormField
            type="textarea"
            rows="6"
            placeholder={`Describe this ${props.type}. What is its significance to your growth?`}
            defaultValue={props.data.description}
            onChange={(e) => setDescription(e.target.value)}
            className="workspace__entry-image-description"
          />
        </div>

        {authorSelectNode}

        <div className="workspace__entry-footer">
          {props.goals && (
            <EntryTags
              goals={props.goals}
              selectedGoalIds={props.data.goalIds}
              canEdit={!props.isEntryHighlighted}
              toggleGoal={props.toggleGoalTag}
              storyId={props.storyId}
              entryId={props.data.id}
              tagGoalResponseCallback={props.tagGoalResponseCallback}
              untagGoalResponseCallback={props.untagGoalResponseCallback}
              isEntryHighlighted={props.isEntryHighlighted}
            />
          )}

          <div className="workspace__btns-row">
            <button
              type="button"
              className="v5__btn v5__btn--blue-border workspace__cancel-link"
              onClick={props.handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="v5__btn"
              {...btnProps}
              onClick={handleSubmit}
            >
              <Spinner loading={isLoading} />
              Save
            </button>
            {deleteIconNode}
          </div>
        </div>
      </div>
    </form>
  );
};

ArtifactForm.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  storyArtifactId: PropTypes.number,
  editArtifact: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
};

ArtifactForm.defaultProps = {
  editMode: false,
};

export default ArtifactForm;
