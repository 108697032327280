// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Analytics from './../../../components/Analytics';
import BackButton from './modules/BackButton';

const GradeStep = (props) => {
  let gradeBand;

  const handleGradesChange = (e, gradeName) => {
    e.preventDefault();

    const actionPrefix = e.target.checked ? 'select_' : 'deselect_';

    let actionName = gradeName.toLowerCase().replace(/[-\s/]/g, '_');
    actionName = actionName === 'pre_k' ? 'pre_kindergarten' : actionName;

    Analytics.logEvent({
      category: 'grade_info',
      action: actionPrefix + actionName,
      label: 'from_third_page_user_welcome',
    });

    // only allow individual grades to be toggled if "All Grades" is not checked
    const val = parseInt(e.target.value);
    if (e.target.checked) {
      props.ui.grades.push(val);
    } else {
      props.ui.grades.splice(props.ui.grades.indexOf(val), 1);
    }
    props.clearErrorsOnFocus('grade');
    props.setGrades(props.ui.grades);
  };

  // on this page, only the Notes field uses this, which has no errors
  const handleFocus = (e, field) => {
    e.preventDefault();
    props.clearErrorsOnFocus(field);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Analytics.logEvent({
      category: 'next',
      action: 'click',
      label: 'from_third_page_user_welcome',
    });

    props.submit({
      importGradeId: gradeBand.value,
    });
  };

  // disable individual grades when All Grades is selected
  const gradeDisabledClass = props.ui.allGradesChecked ? ' disabled' : '';

  // set up errors for first name
  let gradeErrorClass = '';
  let gradeErrorNode = null;
  if ('grade' in props.ui.errors) {
    gradeErrorClass = ' error';
    gradeErrorNode = <div className="v5__error">{props.ui.errors.grade}</div>;
  }

  // set up errors for grade band
  let gradeBandErrorClass = '';
  let gradeBandErrorNode = null;
  if ('gradeBand' in props.ui.errors) {
    gradeBandErrorClass = ' error';
    gradeBandErrorNode = (
      <div className="v5__error">{props.ui.errors.gradeBand}</div>
    );
  }

  // set up grade band select
  const gradeBandOptionsNode = Object.keys(props.gradeBandAnswers).map(
    (optionId) => {
      return (
        <option key={'spedoption-' + optionId} value={optionId}>
          {props.gradeBandAnswers[optionId]}
        </option>
      );
    }
  );
  gradeBandOptionsNode.unshift(
    <option key={'spedoption-0'} value="">
      Select One
    </option>
  );

  // disable buttons when form submission is loading
  let disabledClass = '';
  let spinnerNode = null;
  if (props.ui.loading) {
    disabledClass = ' disabled';
    spinnerNode = <span className="littlespinner"></span>;
  }

  return (
    <div className="row row--1200 registration__primary">
      <form className="cute-10-desktop cute-12-phone center-desktop">
        <div
          className={
            'registration__form-item registration__form-item--input-area' +
            gradeErrorClass
          }
        >
          <div className="registration__headline tc__headline-2">
            What grades are you working with in the current school year?
          </div>
          <div className="registration__input-label">
            If you are not a teacher, indicate the grades taught by the teachers
            you work with.
            <div className="registration__input-label--sub-label">
              Select all that apply.
            </div>
          </div>
          <div className="registration__checkbox-container">
            {props.grades.map((grade, i) => {
              const checked = props.ui.grades.indexOf(grade.id) !== -1;
              const checkedClass = checked ? ' checked' : '';
              const lastInRowClass = (i + 1) % 4 === 0 ? ' last-in-row' : '';
              return (
                <label
                  key={'grade-' + grade.id}
                  className={
                    'registration__checkbox-capsule registration__checkbox-capsule--tall-mobile' +
                    checkedClass +
                    gradeDisabledClass +
                    lastInRowClass
                  }
                >
                  <input
                    type="checkbox"
                    checked={checked}
                    value={grade.id}
                    onChange={(e) => handleGradesChange(e, grade.name)}
                  />
                  <span className="tc-check"></span>
                  {grade.name}
                </label>
              );
            })}
            {gradeErrorNode}
          </div>
        </div>

        <div className="registration__form-item">
          <div className="registration__headline tc__headline-2">
            What grade level would you like to focus on for coaching?
          </div>
          <div className="registration__input-label">
            Your selection helps us pair you with a coach with relevant
            experience.
          </div>
          <select
            ref={(ref) => (gradeBand = ref)}
            defaultValue={props.participant.grade_band_id}
            onFocus={(e) => handleFocus(e, 'gradeBand')}
            className={'registration__input tc__select' + gradeBandErrorClass}
          >
            {gradeBandOptionsNode}
          </select>
          {gradeBandErrorNode}
        </div>

        <div className="registration__submit-btns">
          <BackButton
            caption={'Back to Your School'}
            participant={props.participant}
            step={props.step}
            analyticsLabel={'from_third_page_user_welcome'}
          />
          <button
            onClick={handleSubmit}
            className={
              'tc__btn tc__btn--flat-blue tc__btn--flat-blue-v3' + disabledClass
            }
          >
            {spinnerNode}
            Next: Subjects
          </button>
        </div>
      </form>
    </div>
  );
};

GradeStep.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  setGrades: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default GradeStep;
