// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import { delayedEventListener } from '@utils/EventListenerHandler';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import DropdownUser from './DropdownUser';

const Dropdown = (props) => {
  const [dropdownVisible, toggleDropdown] = useState(false);

  const closeDropdown = () => {
    toggleDropdown(false);
    document.removeEventListener('click', closeDropdown);
  };

  useEffect(() => {
    if (dropdownVisible) {
      delayedEventListener(() =>
        document.addEventListener('click', closeDropdown)
      );
    }
  }, [dropdownVisible]);

  let arrowNode = <div className="tc-bold-arrow header__dropdown-arrow"></div>;
  const options = props.navOptions.map((navOption, i) => {
    if (navOption.external) {
      return (
        <li key={`option-${props.navKey}-${i}`}>
          <a href={navOption.link} className="header__dropdown-link">
            {navOption.caption}
          </a>
        </li>
      );
    }
    if (navOption.newTab) {
      return (
        <li key={`option-${props.navKey}-${i}`}>
          <a
            className="header__dropdown-link"
            href={navOption.link}
            target="_blank"
            rel="noreferrer"
          >
            {navOption.caption}
          </a>
        </li>
      );
    }
    return (
      <li key={`option-${props.navKey}-${i}`}>
        <NavigationLink className="header__dropdown-link" url={navOption.link}>
          {navOption.caption}
        </NavigationLink>
      </li>
    );
  });
  let dropdownClass = '';
  let optionsNode = <ul className="header__dropdown-list">{options}</ul>;
  if (props.navKey === 'user') {
    dropdownClass = 'header__dropdown--user';
    arrowNode = null;
    optionsNode = <DropdownUser user={props.user}>{optionsNode}</DropdownUser>;
  }
  const containerClass = props.className ? props.className : '';
  return (
    <div
      className={`header__dropdown-container header__dropdown-container--v5 ${containerClass}`}
      onClick={() => toggleDropdown(true)}
    >
      {props.children}
      <div
        className={`header__dropdown ${dropdownClass} ${
          dropdownVisible ? 'show' : ''
        }`}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        {optionsNode}
      </div>
      {arrowNode}
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  navKey: PropTypes.string.isRequired,
  navOptions: PropTypes.array.isRequired,
  user: PropTypes.object,
  external: PropTypes.bool,
  newTab: PropTypes.bool,
};
