// @ts-nocheck FIXME
import ScrollToWrapper from './TeacherCohort/ScrollToWrapper';
import TeacherRow from './TeacherCohort/TeacherRow';

const TeacherCohort = (props) => {
  if (props.tab != 'teacher-cohort') {
    return <noscript />;
  }

  const teacherRowNodes = props.entries.map((entry, i) => {
    return (
      <TeacherRow
        {...entry}
        displayedTeacher={props.displayedTeacher}
        changeDisplayedTeacher={props.changeDisplayedTeacher}
        showTeacherNote={props.showTeacherNote}
        key={'teacher-' + entry.id + '-' + i}
      />
    );
  });

  // scroll to the opened teacher row (only when initially opened)
  const ScrollToWrapperElem = ScrollToWrapper(
    <div className="ppp__section ppp__section--teacher-cohort">
      <div className="ppp__section-headline">{props.name}</div>
      {teacherRowNodes}
    </div>,
    props.scrollToTeacher ? props.displayedTeacher : null,
    props.clearScrollToTeacher
  );

  return <ScrollToWrapperElem />;
};

export default TeacherCohort;
