// @ts-nocheck FIXME
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import NavigationLink from './../../../NavigationLink';
import ResultCapsule from './../../common/ResultCapsule';
import Breadcrumbs from './Breadcrumbs';

const LevelFive = (props) => {
  const breadcrumbNode = (
    <Breadcrumbs
      openMenu={props.openMenu}
      grade={props.standards_browser.selected_core_grade}
      domain={props.standards_browser.selected_core_domain}
      domains={props.standards_browser.core_domains}
      openDomainDropdown={props.openDomainDropdown}
      domainDropdownActive={props.ui.domainDropdownActive}
      core={props.standards_browser.core_breadcrumb}
      openCoreDropdown={props.openCoreDropdown}
      coreDropdownActive={props.ui.coreDropdownActive}
    />
  );

  const resultSubject = [];
  if (props.activeSubject) {
    resultSubject.push(props.activeSubject);
  } else {
    switch (parseInt(props.activeStandard.CommonCore.core_id)) {
      case 1:
        resultSubject.push('math');
        break;
      case 2:
        resultSubject.push('science');
        break;
      case 4:
        resultSubject.push('ela');
        break;
    }
  }

  // only display lessons that are related to the active subject
  const subjectFilteredLessons = props.activeStandard.CommonCore.lessons.filter(
    (lesson) => {
      return lesson.Lesson.mtp_subject === props.subject;
    }
  );

  const lessonsNode = subjectFilteredLessons.map((lesson) => {
    return (
      <ResultCapsule
        fromTag={'standard_level2'}
        favorite={{}}
        subjects={resultSubject}
        recommend={() => {}}
        addFavorite={() => {}}
        removeFavorite={() => {}}
        resultId={lesson.Lesson.id}
        resultType={'lesson'}
        resultUser={lesson.User}
        resultUrl={
          process.env.REACT_APP_TEACHING_URL +
          '/lesson/' +
          lesson.Lesson.id +
          '/' +
          lesson.Lesson.seo_url +
          '?from=cc_lesson'
        }
        standards={lesson.Lesson.Standards}
        preview={lesson.Lesson.image_thumb}
        title={lesson.Lesson.name}
        minutes={lesson.Lesson.minutes}
        favoriteCount={lesson.Lesson.favorite_count}
        resourceCount={lesson.Lesson.resource_count}
        summary={lesson.Lesson.summary}
        course={lesson.Course.name}
        unit={lesson.Unit.name}
        user={props.user}
        {...lesson}
        setChildStandard={props.setChildStandard}
        key={'child-standard-lesson' + lesson.Lesson.id}
      />
    );
  });
  const viewAllLessonsNode = (
    <NavigationLink
      className="standard__view-all tc__link"
      url={
        process.env.REACT_APP_TEACHING_URL +
        '/search?standards=' +
        props.activeStandard.CommonCore.id +
        '&from=standard_level2'
      }
    >
      View All {props.activeStandard.CommonCore.lesson_count} Lessons
    </NavigationLink>
  );
  const childrenStandardsNode = props.activeStandard.CommonCore.children.map(
    (child) => {
      let countNode = null;
      let hasCountClass = '';
      if (child.CommonCore.lesson_count && child.CommonCore.lesson_count > 0) {
        hasCountClass = ' standards__child-child-description--has-count';
        countNode = (
          <div className="standards__child-count tc__link">
            <div
              className={`standards__child-count-icon tc-v4-${props.subject}`}
            ></div>
            <div className="standards__child-count-caption">
              {child.CommonCore.lesson_count} Lessons
            </div>
          </div>
        );
      }
      return (
        <NavigationLink
          key={'browse-standard-tag-' + child.CommonCore.id}
          url={
            process.env.REACT_APP_TEACHING_URL +
            '/browse/common_core/standard/' +
            child.CommonCore.id +
            '/' +
            child.CommonCore.seo_url +
            '?from=core_child'
          }
          className="row"
        >
          <div className="standards__child standards__child--level-2 standards__child--headline cute-12-desktp">
            <div className="standards__child-id">
              <div className="search__tag">{child.CommonCore.identifier}</div>
            </div>
            <PurifiedHTML
              className={
                'standards__child-description standards__child-child-description' +
                hasCountClass
              }
              content={child.CommonCore.description}
            />
            {countNode}
          </div>
        </NavigationLink>
      );
    }
  );
  const contentVisibleClass = !props.ui.megaMenuOpen
    ? ' standards__content--visible'
    : ' standards__content--hidden';
  return (
    <div className={'standards__content' + contentVisibleClass}>
      {breadcrumbNode}
      <div className="row row--1200">
        <div className="cute-12-desktop">
          <div className="row">
            <div className="cute-12-desktp standards__child standards__child--level-1">
              <div className="standards__child-id">
                <div className="search__tag">
                  {props.activeStandard.CommonCore.identifier}
                </div>
              </div>
              <PurifiedHTML
                className="standards__child-description standards__child-description--level-2"
                content={props.activeStandard.CommonCore.description}
              />
              <div className="standards__child-count">
                <div
                  className={`standards__child-count-icon tc-v4-${props.subject}`}
                ></div>
                <div className="standards__child-count-caption tc__ link">
                  {props.activeStandard.CommonCore.lesson_count} Lessons
                </div>
              </div>
              <a
                className="standard__view-children tc__link"
                onClick={props.handleScrollToChildren}
              >
                <div className="standard__view-children-icon tc-sublevel"></div>
                <div className="standard__view-children-caption">
                  View child tags
                </div>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="standards__child-lessons cute-10-desktop cute-12-phone center-desktop">
              {lessonsNode}
              {viewAllLessonsNode}
            </div>
          </div>
          {childrenStandardsNode}
        </div>
      </div>
    </div>
  );
};

export default LevelFive;
