// @ts-nocheck FIXME
import { useRef, useState } from 'react';

import Utils from './../../../../../../../components/DynamicUtils';

import StoryApi from './../../../../../../../apis/V4/StoryApi';
import FormField from './../../../../../Common/FormField';
import Spinner from './../../../../../Common/Spinner';
import EntryTags from './../EntryTags';
import SelectAuthorDropdown from './../SelectAuthorDropdown';

const TextEntryEditForm = (props) => {
  const [title, setTitle] = useState(props.titleInput || '');
  const [titleHighlighted, setTitleHighlighted] = useState(false);
  const [text, setText] = useState(props.textInput || '');
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // set the initially selected author
  const [author, setAuthor] = useState(
    props.author ? props.author.id : props.currentUser.id
  );

  const descriptionEl = useRef();

  const handleTitleKeyPress = (e) => {
    // override default browser behavior when pressing "Enter" within the Title field
    // to focus on the Description field
    if (e.key === 'Enter') {
      e.preventDefault();
      descriptionEl.current.focus();
    }
  };

  const handleFocus = (field, e) => {
    // clear any field error
    if (errors[field]) {
      const newErrors = Utils.clone(errors);
      delete newErrors[field];
      setErrors(newErrors);
    }

    // when opening the form, the Title text should all be highlighted
    if (field === 'title' && !titleHighlighted) {
      e.target.select();
      setTitleHighlighted(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // first, clear any form errors
    setErrors({});

    // validation
    const formErrors = {};
    if (!title) {
      formErrors.title = 'Hold on, you need to enter a title to continue.';
    }
    if (!text) {
      formErrors.text = 'Hold on, you need to enter a note to continue.';
    }
    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);

    // build API data
    const data = {
      storyId: props.activeStoryId,
      description: text,
      title: title,
      goals: props.currentGoals,
    };
    // if the current user is allowed to change the authorship, pass that in
    if (props.canEditAuthor && author) {
      data.authorId = author;
    }
    // otherwise, if the current user is the workspace owner,
    // make sure they are set as the author
    else if (props.currentUser.id === props.pageUser.id) {
      data.authorId = props.currentUser.id;
    }

    if (props.entryId) {
      data.storyTextEntryId = props.entryId;
      StoryApi.editTextEntry(data)
        .then((response) => {
          props.editEntryCallback(response.entry);
          props.close();
        })
        .catch(() => {
          props.setErrorAlert();
          setLoading(false);
        });
    } else {
      StoryApi.addTextEntry(data)
        .then((response) => {
          props.addEntryCallback(response.entry);
          props.close();
        })
        .catch(() => {
          props.setErrorAlert();
          setLoading(false);
        });
    }
  };

  // set up the author select, if the current user is allowed to edit it
  let authorSelectNode;
  if (props.canEditAuthor) {
    authorSelectNode = (
      <SelectAuthorDropdown
        user={props.currentUser}
        pageUser={props.pageUser}
        author={props.author}
        newAuthorId={author}
        updateAuthorId={setAuthor}
        canEditAuthor={props.canEditAuthor}
      />
    );
  }

  // disable the Save button after submit
  const btnProps = isLoading ? { disabled: true } : null;

  // only show the delete icon when editing an entry, not when creating a new entry
  let deleteIconNode;
  if (props.entryId && props.confirmDelete) {
    deleteIconNode = (
      <span
        onClick={props.confirmDelete}
        className="tc-delete workspace__delete-icon"
      ></span>
    );
  }

  return (
    <form>
      <div className="v5__heading-5 workspace__entry-header-row workspace__entry-header-row--editing">
        <div className="tc-note workspace__entry-header-icon"></div>
        <div className="workspace__entry-header-text">
          <FormField
            type="text"
            placeholder="Give your note a title."
            defaultValue={props.titleInput}
            autoFocus={true}
            onKeyPress={handleTitleKeyPress}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={(e) => handleFocus('title', e)}
            error={errors.title}
          />
        </div>
      </div>

      <div className="workspace__entry-body">
        <FormField
          type="textarea"
          rows="4"
          placeholder="Reflect on your progress toward your vision or milestones."
          defaultValue={props.textInput}
          onChange={(e) => setText(e.target.value)}
          onFocus={() => handleFocus('text')}
          error={errors.text}
          ref={descriptionEl}
        />

        {authorSelectNode}

        <div className="workspace__entry-footer">
          <EntryTags
            goals={props.goals}
            selectedGoalIds={props.currentGoals}
            canEdit={!props.isEntryHighlighted}
            toggleGoal={props.toggleGoalTag}
            storyId={props.activeStoryId}
            entryId={props.entryId}
            tagGoalResponseCallback={props.tagGoalResponseCallback}
            untagGoalResponseCallback={props.untagGoalResponseCallback}
          />

          <div className="workspace__btns-row">
            <button
              type="button"
              className="v5__btn v5__btn--blue-border workspace__cancel-link"
              onClick={() => props.close()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="v5__btn"
              {...btnProps}
              onClick={handleSubmit}
            >
              <Spinner loading={isLoading} />
              Save
            </button>
            {deleteIconNode}
          </div>
        </div>
      </div>
    </form>
  );
};

export default TextEntryEditForm;
