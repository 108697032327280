// @ts-nocheck FIXME
import React from 'react';

class PageBreak extends React.Component {
  render() {
    return <hr className="page-break" />;
  }
}

export default PageBreak;
