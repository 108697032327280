// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import StringHelpers from '@legacy-components/components/StringHelpers';
import NavigationLink from '@legacy-components/react/NavigationLink';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import FormField from '@legacy-components/react/V5/Common/FormField';
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import dateformat from 'dateformat';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ClipEditModal = (props) => {
  const [clipTypes, setClipTypes] = useState(props.data.clip_types || []);
  const [selectedRecording, setSelectedRecording] = React.useState(
    props.meeting.recordings &&
      (props.meeting.recordings
        .map((recording, i) => {
          recording.label = `Recording ${
            i + 1
          } (${StringHelpers.formatDurationHms(recording.video_length)})`;
          return recording;
        })
        .find((recording) => {
          return recording.id === props.data.recording_id;
        }) || {
        ...props.meeting.recordings[0],
        label: `Recording 1 (${StringHelpers.formatDurationHms(
          props.meeting.recordings[0].video_length
        )})`,
      })
  );
  const [recordingOptions, setRecordingOptions] = React.useState(
    props.meeting.recordings || []
  );
  const [startTime, updateStartTime] = useState(
    Utils.hhmmss(props.data.start_time)
  );
  const [endTime, updateEndTime] = useState(Utils.hhmmss(props.data.end_time));
  const [description, updateDescription] = useState(props.data.description);
  const [name, updateName] = useState(
    props.data.name ||
      `Clip from ${dateformat(
        props.meeting.meeting_time_formatted,
        'mmmm dd, yyyy'
      )}`
  );
  const [nameError, changeNameError] = useState('');
  const [timeError, changeTimeError] = useState('');
  const [recordingError, setRecordingError] = useState('');
  const clipNameRef = React.useRef(null);

  useEffect(() => {
    clipNameRef.current.focus();
  }, []);

  const availableRecordingOptions = props.meeting.recordings.map(
    (recording, i) => {
      return {
        ...recording,
        label: `Recording ${i + 1} (${StringHelpers.formatDurationHms(
          recording.video_length
        )})`,
      };
    }
  );

  const handleSelectRecordingOption = (recording) => {
    if (recordingOptions.find((recordingId) => recordingId === recording.id)) {
      setSelectedRecording(
        availableRecordingOptions.find((option) => option.id === recording.id)
      );
      setRecordingOptions(
        recordingOptions.filter((recordingId) => recording.id !== recordingId)
      );
    } else {
      setSelectedRecording(null);
      setRecordingOptions(recordingOptions.concat(recording.id));
    }
  };

  const handleRecordingFocus = () => {
    if (selectedRecording) {
      setRecordingOptions(
        recordingOptions.filter(
          (recording) => recording.id !== selectedRecording.id
        )
      );
    }
    setRecordingError(null);
  };

  const handleRecordingBlur = () => {
    if (!selectedRecording || !selectedRecording.id) {
      setRecordingError('You must select a recording to clip from.');
    } else {
      setRecordingError(null);
    }
  };

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handleChangeName = (text) => {
    updateName(text);

    if (!text) {
      changeNameError('Hold on, you need to enter a clip name to continue.');
    } else {
      changeNameError('');
    }
  };

  const timeRegex = /^[0-9][0-9]?:[0-5]?[0-9]:[0-5]?[0-9]$/;

  const handleChangeStartTime = (text) => {
    if (!(timeRegex.test(endTime) && timeRegex.test(text))) {
      changeTimeError(
        'Please make sure that your timestamps are of the form HH:mm:ss'
      );
    } else if (endTime < text) {
      changeTimeError(
        'Sorry, we can’t bend time. Select an end time that is later than the start time.'
      );
    } else if (endTime === text) {
      changeTimeError('Your clip must be at least 1 second long.');
    } else {
      changeTimeError('');
    }
    updateStartTime(text);
  };

  const handleChangeEndTime = (text) => {
    if (!(timeRegex.test(text) && timeRegex.test(startTime))) {
      changeTimeError(
        'Please make sure that your timestamps are of the form HH:mm:ss'
      );
    } else if (text < startTime) {
      changeTimeError(
        'Sorry, we can’t bend time. Select an end time that is later than the start time.'
      );
    } else if (text === startTime) {
      changeTimeError('Your clip must be at least 1 second long.');
    } else {
      changeTimeError('');
    }
    updateEndTime(text);
  };

  const pageHasErrors = timeError || nameError || recordingError;

  const handleSaveClip = (e) => {
    e.preventDefault();

    if (endTime < startTime) {
      changeTimeError(
        'Sorry, we can’t bend time. Select an end time that is later than the start time.'
      );
      return;
    } else if (endTime === startTime) {
      changeTimeError('Your clip must be at least 1 second long.');
      return;
    } else if (
      endTime >
      moment.utc(selectedRecording.video_length * 1000).format('HH:mm:ss')
    ) {
      changeTimeError('Your clip cannot be longer than your recording.');
      return;
    }

    props.saveClip({
      clipId: props.data.id,
      meetingId: props.meeting.id,
      recordingId: selectedRecording.id,
      clipTypes: clipTypes,
      startTime: startTime,
      endTime: endTime,
      description: description,
      name: name,
    });
  };

  const handleDeleteClip = (e) => {
    e.preventDefault();

    if (
      confirm(
        'Are you sure you want to delete this clip? If it was published, it will also be removed from the workspace.'
      )
    ) {
      props.deleteClip({
        id: props.data.id,
        meeting_id: props.meeting.id,
      });
    }
  };

  const handleSelectClipTypeOption = (selectedClipType) => {
    if (clipTypes.find((clipTypeId) => clipTypeId === selectedClipType.id)) {
      setClipTypes(
        clipTypes.filter((clipTypeId) => selectedClipType.id !== clipTypeId)
      );
    } else {
      setClipTypes(clipTypes.concat(selectedClipType.id));
    }
  };

  // disable buttons when submitting
  const btnProps = props.isLoading ? { disabled: true } : null;

  const headlineText = props.data.id
    ? 'Edit Meeting Clip'
    : 'Create Meeting Clip';
  const submitOrAdd = props.data.id ? 'Submit' : 'Add';

  const selectedClipTypes = clipTypes.map((clipType) => {
    return {
      id: clipType,
      label: props.meeting.clipTypeOptions[clipType],
    };
  });

  const deleteNode = props.data.id && (
    <div
      onClick={handleDeleteClip}
      data-testid="meeting-delete-button"
      className="story__edit-entry-delete-link participant-v5__meeting-delete-button"
    >
      <div className="tc-delete story__edit-entry-delete-icon"></div>
    </div>
  );

  const zoomRecordingNode = availableRecordingOptions.length ? (
    <label className="workspace__form-row">
      <div className="admin__form-label admin__form-label--inline participant-v5__participant-meeting-recording-label">
        Zoom recording
      </div>
      <MultiSelect
        openWithOptions={false}
        maximumSelectedOptionsCount={1}
        uId="recording"
        disabled={props.isLoading}
        error={recordingError}
        handleFocus={handleRecordingFocus}
        handleBlur={handleRecordingBlur}
        errorContainerClass="relative"
        handleSelectOption={handleSelectRecordingOption}
        selectedOptions={selectedRecording ? [selectedRecording] : []}
        stayOpenOnSelect={false}
        dropdownOptions={availableRecordingOptions}
        minCharCountForResults={0}
        dataTestId="recording"
      />
    </label>
  ) : null;

  return (
    <ActionModal
      headlineText={headlineText}
      close={handleCloseModal}
      spaceFromTop={50}
    >
      <form className="participant-v5__modal-body participant-v5__participant-meeting-clip-modal">
        <label className="workspace__form-row participant-v5__modal-top-row">
          <div className="admin__form-label participant-v5__participant-meeting-clip-type-label">
            Clip Name
          </div>
          <FormField
            type="text"
            className="participant-v5__form-input-title"
            disabled={props.isLoading}
            error={nameError}
            errorContainerClass="relative"
            defaultValue={name}
            onChange={(e) => handleChangeName(e.target.value)}
            maxLength="256"
            formRef={clipNameRef}
          />
        </label>

        <label className="workspace__form-row">
          <div className="admin__form-label admin__form-label--inline participant-v5__participant-meeting-clip-type-label">
            Clip Type
          </div>
          <MultiSelect
            openWithOptions={false}
            uId="clip-multiselect"
            handleSelectOption={handleSelectClipTypeOption}
            selectedOptions={selectedClipTypes}
            stayOpenOnSelect={false}
            dropdownOptions={Object.entries(props.meeting.clipTypeOptions).map(
              ([key, value]) => {
                return {
                  label: value,
                  field: value,
                  id: key,
                };
              }
            )}
            minCharCountForResults={0}
            dataTestId="clip-types"
          />
        </label>

        {zoomRecordingNode}

        <div className="workspace__form-row no-bottom">
          <label className="participant-v5__participant-meeting-clip-time">
            <div className="admin__form-label admin__form-label--inline">
              Start Time
            </div>
            <FormField
              type="text"
              placeholder="00:00:00"
              className="participant-v5__participant-meeting-clip-time-input"
              pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
              disabled={props.isLoading}
              error={timeError}
              errorContainerClass="participant-v5__meeting-clip-time-error"
              onChange={(e) => handleChangeStartTime(e.target.value)}
              defaultValue={Utils.hhmmss(props.data.start_time)}
            />
          </label>
          <label className="participant-v5__participant-meeting-clip-time">
            <div className="admin__form-label admin__form-label--inline">
              End Time
            </div>
            <FormField
              type="text"
              placeholder="00:00:00"
              className="participant-v5__participant-meeting-clip-time-input"
              pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
              disabled={props.isLoading}
              hasError={!!timeError}
              onChange={(e) => handleChangeEndTime(e.target.value)}
              defaultValue={Utils.hhmmss(props.data.end_time)}
            />
          </label>
        </div>

        <div className="workspace__form-row participant-v5__participant-meeting-clip-video-link">
          <NavigationLink
            url={`/coaching/lab/meeting/${props.meeting.uuid}`}
            target="_blank"
            className="tc__link participant-v5__meeting-video-link"
          >
            View full video for reference
          </NavigationLink>
        </div>

        <label className="workspace__form-row">
          <div className="admin__form-label">Clip Description</div>
          <FormField
            type="textarea"
            disabled={props.isLoading}
            inputClassName="tc__input admin__form-input participant-v5__meeting-description-input tc__input v5__input"
            rows={3}
            defaultValue={props.data.description}
            onChange={(e) => updateDescription(e.target.value)}
          />
        </label>

        <div className="workspace__form-row admin__right-btn-col">
          <div className="participant-v5__modal-btns">
            {deleteNode}
            <button
              className="v5__btn"
              onClick={handleSaveClip}
              disabled={pageHasErrors || props.isLoading}
              {...btnProps}
              data-testid="clip-save-button"
            >
              <Spinner loading={props.isLoading} />
              {submitOrAdd}
            </button>
          </div>
        </div>
      </form>
    </ActionModal>
  );
};

ClipEditModal.propTypes = {
  meeting: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  saveClip: PropTypes.func.isRequired,
};

ClipEditModal.defaultProps = {
  data: {},
};

export default ClipEditModal;
