// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import Utils from './../../../components/DynamicUtils';
import AbsoluteAlert from './../../Alerts/Absolute';
import NavigationLink from './../../NavigationLink';
import MultiSelect from './../../V4/Common/MultiSelect';
import SingleSelect from './../../V4/Common/SingleSelect';
import Spinner from './../../V4/Common/Spinner';
import ConfirmAction from './ConfirmAction';

const Participant = (props) => {
  const lab = useLegacyLabContext();
  const ui = props.ui.editParticipant || {};
  // only Super Admins and UMs can edit this page, and only if the participant
  // is part of the current school year
  const schoolYear = props.participant.school_year;
  const readOnly =
    schoolYear !== Utils.getCurrentSchoolYear() ||
    !(
      Utils.isAdmin.call(lab, props.user) ||
      Utils.isManager.call(lab, props.user)
    );

  // if the user has any internal roles, show them all the data -
  // users with only the HELP_DESK_AGENT role only see event-related data
  const hasInternalRole = Utils.arrayContainsAnyItemInAnotherArray(
    props.user.auth_roles,
    ['ADMIN', 'BL_MANAGER', 'BL_SERVICES_MANAGER', 'BL_TEAM_MEMBER']
  );

  let alertNode,
    disabledClass = ui.isLoading || readOnly ? ' disabled' : '',
    productErrorNode,
    productErrorClass = '',
    confirmActionNode;

  if (Object.keys(props.ui.alert).length) {
    alertNode = (
      <AbsoluteAlert
        {...props.ui.alert}
        cssClasses={{ container: 'alert-banner--fixed' }}
      />
    );
  }

  if (ui.warningModal.opened) {
    const labels = {
      prompt:
        "You have deleted one of the participant's learning domains. Are you sure you want to proceed?",
      confirm: 'Yes',
      cancel: 'No',
      title: 'Confirm Deletion',
    };
    confirmActionNode = (
      <ConfirmAction
        confirmCallback={props.editParticipant}
        closeCallback={ui.warningModal.close}
        labels={labels}
      />
    );
  }

  const handleClearErrorsOnFocus = (field) => {
    if (!Object.keys(ui.errors).length) {
      return;
    }
    props.clearErrorsOnFocus({ feature: 'editParticipant', field: field });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.editParticipant();
  };

  let cohortLabelNode = null;
  if (hasInternalRole && props.participant.cohort) {
    cohortLabelNode = (
      <div className="admin-v2__user-page-form-item clearfix">
        <label className="admin-v2__user-page-label">Cohort:</label>
        <div style={{ padding: '10px 0 0 20px' }}>
          <b>{props.participant.cohort.name}</b>{' '}
          <NavigationLink
            url={`/bl/cohort/${props.participant.cohort.id}`}
            className="tc__link"
          >
            link
          </NavigationLink>
        </div>
      </div>
    );
  }

  let eventsLabelNode = null;
  if (props.participant.events.length > 0) {
    const eventDict = {};
    props.participant.events.map((event) => {
      let eventDescription = event.name;
      if (event.group_name) {
        eventDescription = `${eventDescription}, ${event.group_name}`;
      }
      if (event.event_start_date) {
        const start_date = moment(event.event_start_date).format('M/DD/YY');
        eventDescription = `${eventDescription} ${start_date}`;
        if (event.event_start_date !== event.event_end_date) {
          const end_date = moment(event.event_end_date).format('M/DD/YY');
          eventDescription = `${eventDescription}-${end_date}`;
        }
      }
      eventDict[event.id] = eventDescription;
    });
    eventsLabelNode = (
      <div className="admin-v2__user-page-form-item clearfix">
        <label className="admin-v2__user-page-label">Event(s):</label>
        <br />
        {Object.keys(eventDict).map((id) => (
          <li key={id} style={{ padding: '10px 0 0 20px' }}>
            <NavigationLink url={`/bl/event/${id}`} className="tc__link">
              <b>{eventDict[id]}</b>
            </NavigationLink>
          </li>
        ))}
      </div>
    );
  }

  const getLinks = () => {
    // by default we link to the Participant Details page
    let participantLinks = (
      <React.Fragment>
        <div className="admin-v2__user-teachcycle-link">
          <NavigationLink
            className="tc__link"
            url={`/lab/participant/${props.participant.id}/details`}
          >
            Participant Details
          </NavigationLink>
        </div>
      </React.Fragment>
    );
    // link to the 2017-18 Portfolio if necessary
    if (schoolYear === '2017 - 2018') {
      participantLinks = (
        <React.Fragment>
          <div className="admin-v2__user-teachcycle-link">
            <NavigationLink
              className="tc__link"
              url={
                '/coaching/lab/participant/' +
                props.participant.id +
                '/portfolio'
              }
            >
              2017-18 Portfolio
            </NavigationLink>
          </div>
        </React.Fragment>
      );
    }

    return participantLinks;
  };

  if ('product' in ui.errors) {
    productErrorNode = <div className="tc__error">{ui.errors.product}</div>;
    productErrorClass = ' error';
  }

  let learningDomainsNode, productNode, coachInfoNode, submitNode;
  learningDomainsNode = null;
  // only show these if a participant is in a cohort
  if (props.participant.cohort) {
    let groupFocusAreas = null;
    let groupFocusAreasNode = null;
    if (props.groupFocusAreas.length) {
      groupFocusAreas = props.groupFocusAreas.map((focusArea) => {
        return (
          <li key={`focus_area_${focusArea.id}`} className="">
            {focusArea.label}
          </li>
        );
      });
      groupFocusAreasNode = (
        <ul className="admin__participant-cohort-focus-areas tc_body-copy">
          {groupFocusAreas}
        </ul>
      );
      learningDomainsNode = (
        <div className="row">
          <div className="admin-v2__user-page-form-item clearfix">
            <label className="admin-v2__user-page-label">
              Learning Domains
            </label>
            {groupFocusAreasNode}
          </div>
        </div>
      );
    }

    productNode = (
      <div className="row">
        <div
          className={
            'admin-v2__user-page-form-item clearfix' + productErrorClass
          }
        >
          <label className="admin-v2__user-page-label">BL Product</label>
          <SingleSelect
            {...ui.product}
            field="product"
            feature="editParticipant"
            minCharCountForResults={0}
            updateSingleSelect={props.updateSelect}
            getVisibleOptions={props.getVisibleOptions}
            handleFocus={() => handleClearErrorsOnFocus('product')}
            readOnly={true}
          />
          {productErrorNode}
        </div>
      </div>
    );
    coachInfoNode = (
      <React.Fragment>
        <div className="row">
          <div className="admin-v2__user-page-section admin-v2__user-page-form-item clearfix">
            <div className="tc__headline-4">Coach Selection</div>
          </div>
          <div className={'admin-v2__user-page-form-item clearfix'}>
            <label className="admin-v2__user-page-label">Coach</label>
            <SingleSelect
              {...ui.coach}
              field="coach"
              feature="editParticipant"
              minCharCountForResults={0}
              updateSingleSelect={props.updateSelect}
              getVisibleOptions={props.getVisibleOptions}
              readOnly={readOnly}
            />
          </div>

          <div className={'admin-v2__user-page-form-item clearfix'}>
            <label className="admin-v2__user-page-label">Subject Focus</label>
            <SingleSelect
              {...ui.importSubject}
              field="importSubject"
              feature="editParticipant"
              minCharCountForResults={0}
              updateSingleSelect={props.updateSelect}
              getVisibleOptions={props.getVisibleOptions}
              readOnly={readOnly}
            />
          </div>

          <div className={'admin-v2__user-page-form-item clearfix'}>
            <label className="admin-v2__user-page-label">Grade Band</label>
            <SingleSelect
              {...ui.importGrade}
              field="importGrade"
              feature="editParticipant"
              minCharCountForResults={0}
              updateSingleSelect={props.updateSelect}
              getVisibleOptions={props.getVisibleOptions}
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="row">
          <div className="admin-v2__user-page-form-item clearfix">
            <label className="admin-v2__user-page-label">Availability</label>
            <MultiSelect
              {...ui.availabilityOptions}
              field="availabilityOptions"
              feature="editParticipant"
              minCharCountForResults={0}
              updateMultiSelect={props.updateSelect}
              getVisibleOptions={props.getVisibleOptions}
              analyticsLabel={''}
              readOnly={readOnly}
            />
          </div>
        </div>
      </React.Fragment>
    );
    submitNode = (
      <div className="row">
        <div className="admin-v2__user-page-form-item clearfix">
          <button
            className={
              'tc__btn tc__btn--blue tc__btn--small tc__btn--add-submit' +
              disabledClass
            }
            onClick={handleSubmit}
          >
            <Spinner loading={ui.isLoading} />
            Submit
          </button>
        </div>
      </div>
    );
  }

  if (!props.participant.cohort && props.groupFocusAreas.length) {
    learningDomainsNode = (
      <div className="row">
        <div className="admin-v2__user-page-form-item clearfix">
          <label className="admin-v2__user-page-label">Learning Domains</label>
          <ul className="admin__participant-cohort-focus-areas tc_body-copy">
            {props.groupFocusAreas.map((focusArea) => {
              return (
                <li key={`focus_area_${focusArea.id}`} className="">
                  {focusArea.label}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  // if the current user has only the HELP_DESK_AGENT role, hide this whole section
  let participantDataNodes;
  let highlightsLinkNode;
  if (hasInternalRole) {
    // for most participants we link to the Highlights page
    highlightsLinkNode = (
      <div className="admin-v2__user-teachcycle-link">
        <NavigationLink
          className="tc__link"
          url={`/lab/highlights/${props.participant.user_id}`}
        >
          Highlights
        </NavigationLink>
      </div>
    );

    // there are no Highlights for participants from 2017-18
    if (schoolYear === '2017 - 2018') {
      highlightsLinkNode = null;
    } else if (schoolYear === '2018 - 2019') {
      highlightsLinkNode = (
        <div className="admin-v2__user-teachcycle-link">
          <NavigationLink
            className="tc__link"
            url={'/profile/' + props.participant.user_id}
          >
            Profile
          </NavigationLink>
        </div>
      );
    }

    participantDataNodes = (
      <React.Fragment>
        <div className="row">
          <div className="admin-v2__user-page-section admin-v2__user-page-form-item clearfix">
            <div className="tc__headline-4">
              Participant Links
              {schoolYear ? ` (school year: ${schoolYear})` : ''}
            </div>
            <div className="admin-v2__user-teachcycle-link">
              <NavigationLink
                className="tc__link"
                url={'/lab/home/' + props.participant.user_id}
              >
                Home
              </NavigationLink>
            </div>
            <div className="admin-v2__user-teachcycle-link">
              <NavigationLink
                className="tc__link"
                url={'/lab/workspace/' + props.participant.user_id}
              >
                Workspace
              </NavigationLink>
            </div>
            {highlightsLinkNode}
            {getLinks()}
          </div>
        </div>

        {learningDomainsNode}
        {productNode}
        {coachInfoNode}
        {submitNode}
      </React.Fragment>
    );
  }

  return (
    <div>
      <Header page="Admin" {...props} />
      {alertNode}
      {confirmActionNode}

      <div className="admin-v2__user-tab-body">
        <div className="row">
          <div className="cute-12-desktop">
            <div className="admin-v2__user-page-form-item clearfix">
              <label className="admin-v2__user-page-label">
                Name: <b>{props.participant.name}</b>
              </label>
            </div>
            {cohortLabelNode}
            {eventsLabelNode}
          </div>
        </div>

        {participantDataNodes}
      </div>
    </div>
  );
};

Participant.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  updateSelect: PropTypes.func.isRequired,
  getVisibleOptions: PropTypes.func.isRequired,
  editParticipant: PropTypes.func.isRequired,
  clearErrorsOnFocus: PropTypes.func.isRequired,
};

export default Participant;
