import { useEffect } from 'react';

export default function HubSpot(): null {
  const initializeHubSpot = () => {
    if (!process.env.REACT_APP_BETTERLESSON_HUBSPOT_ID) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${process.env.REACT_APP_BETTERLESSON_HUBSPOT_ID}.js`;
    document.head.appendChild(script);
  };

  useEffect(() => {
    initializeHubSpot();
  }, []);

  return null;
}
