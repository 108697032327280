// @ts-nocheck FIXME
const Cover = (props) => {
  return (
    <div>
      <div className="portfolio__cover">
        <div className="portfolio__cover-header">
          <div
            id="tc__logo"
            className="tc-header__logo tc-header__logo--portfolio"
          >
            <span className="tc-header__logo-caption">
              BetterLesson PersonalizedPD
            </span>
          </div>
          <h1 className="portfolio__cover-headline">{props.cover_title}</h1>
        </div>
        <div className="portfolio__cover-bg">
          <div className="portfolio__cover-data">
            <div className="portfolio__cover-details">
              {props.cover_details}
            </div>
          </div>
        </div>
      </div>
      <hr className="page-break" />
    </div>
  );
};

export default Cover;
