// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import MobileEventRow from './MobileEventRow';

const MobileEventsTable = (props) => {
  const [sortedColumn, setSortedColumn] = React.useState('event-name');
  const [sortDirection, setSortDirection] = React.useState('asc');

  // don't show this table if there are no events
  if (!props.events.length) return null;

  // Wrapper function to sort on null values
  const sortWithNull = (func, key) => {
    return (a, b) => {
      if (a && a[key]) {
        if (b && b[key]) {
          return func(a, b);
        }
        return -1;
      } else if (b && b[key]) {
        return 1;
      } else {
        return 0;
      }
    };
  };

  const sortFunctions = {
    'event-name': sortWithNull(
      (a, b) => a.display_name.localeCompare(b.display_name),
      'display_name'
    ),
    'session-count': (a, b) => a.maxSessionCount - b.maxSessionCount,
    'start-date': sortWithNull(
      (a, b) => moment(a.startDate) - moment(b.startDate),
      'startDate'
    ),
    'end-date': sortWithNull(
      (a, b) => moment(a.endDate) - moment(b.endDate),
      'endDate'
    ),
    rsvp: (a, b) => a.reservation_count - b.reservation_count,
    registered: (a, b) => a.participant_count - b.participant_count,
  };

  // Sort order indication arrow
  let sortIndicator = null;

  if (sortDirection === 'asc') {
    props.events.sort(sortFunctions[sortedColumn]);
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (sortDirection === 'desc') {
    props.events.sort(sortFunctions[sortedColumn]);
    props.events.reverse();
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
  }

  const sortOrder = {
    asc: 'desc',
    desc: 'asc',
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      setSortDirection(sortOrder[sortDirection]);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };

  const eventRowNodes = props.events.map((event) => {
    return (
      <MobileEventRow
        key={`event-${event.id}`}
        leadId={props.leadId}
        {...event}
      />
    );
  });

  return (
    <div className="partner_lead_v5__table">
      <div className="v5__heading-3">Events</div>
      <div className="admin-v5__coach-table__header grid-ten__row">
        <div
          className="admin-v5__coach-table__header-col grid-ten__col-5"
          onClick={() => toggleSortDirection('event-name')}
        >
          <span
            className={
              'ppd-3-coach-table__header-caption left clickable' +
              (sortedColumn === 'event-name' ? ' active' : '')
            }
          >
            Event
          </span>
          {sortedColumn === 'event-name' ? sortIndicator : null}
        </div>
      </div>

      {eventRowNodes}
    </div>
  );
};

MobileEventsTable.propTypes = {
  events: PropTypes.array.isRequired,
};

export default MobileEventsTable;
