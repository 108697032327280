// @ts-nocheck FIXME
import RedirectHelper from '@legacy-components/components/RedirectHelper';

const EventAccountRegistration = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const sub = urlParams.get('sub');
  const pwToken = urlParams.get('pw_token');
  const orgUuid = props.orgUuid;
  const userManagementRegisterUrl = RedirectHelper.getUserManagementRegisterUrl(
    sub,
    pwToken,
    orgUuid
  );
  const userManagementLoginUrl =
    RedirectHelper.getUserManagementLoginUrl(orgUuid);

  return (
    <>
      <div className="cute-12-desktop" style={{ paddingLeft: 0 }}>
        <div className="v5__heading-4">Claim Your Spot</div>
        <div className="v5__body-copy reservation__signup-description"></div>
      </div>

      <div className="reservation__form-row">
        <a className="v5__btn" href={userManagementRegisterUrl} target="_self">
          Continue
        </a>
      </div>

      <div className="reservation__form-row">
        <div>
          Have an account already?
          <a
            href={userManagementLoginUrl}
            className="v5__link reservation__login-link"
            target="_self"
          >
            Log in
          </a>
        </div>
      </div>
    </>
  );
};

export default EventAccountRegistration;
