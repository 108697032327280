// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import PropTypes from 'prop-types';
import Analytics from './../../../../components/Analytics';
import Utils from './../../../../components/DynamicUtils';
import { Router } from './../../../../components/Router';
import HeaderStore from './../../../../stores/V4/HeaderStore';
import LessonLink from './../../../LessonLink';
import AbridgeText from './../../Common/AbridgeText';

const StrategyCapsule = (props) => {
  const lab = useLegacyLabContext();
  // set up the url for the strategy details page
  let strategyUrl =
    process.env.REACT_APP_TEACHING_URL + '/strategy/' + props.id;
  if (props.seo_url) {
    strategyUrl += '/' + props.seo_url;
  }
  if (props.fromTag) {
    strategyUrl += '?from=' + props.fromTag;
  }

  const handleCapsuleClick = () => {
    // determine if free content limit is reached
    let reachedFreeContentLimit = false;
    // if strategy and strategy is not open, then check if limit is reached
    if (props.publish_status !== 'open') {
      reachedFreeContentLimit = Utils.reachedFreeContentLimit.call(
        lab,
        null,
        props.id
      );
    }

    // if the logged out user has reached the limit of 5 free content pages,
    // show the registration modal
    if (reachedFreeContentLimit) {
      if (!props.user || (props.user && !props.user.id)) {
        HeaderStore.actionDispatch('openRegisterModal', {
          reason: 'resource_count_reached',
        });
        return;
      }
    }

    Router.route(strategyUrl);
  };

  const handleAddFavorite = (e) => {
    e.stopPropagation();

    if (props.fromTag) {
      // Record the analytics event:
      Analytics.logEvent({
        category: 'strategy',
        action: 'favorite',
        label: 'from_' + props.fromTag,
      });
    }

    props.addFavorite({
      userId: props.user.id,
      itemId: props.id,
      itemType: 'strategy',
      fromTag: props.fromTag,
    });
  };

  const handleRemoveFavorite = (e) => {
    e.stopPropagation();

    if (props.fromTag) {
      // Record the analytics event:
      Analytics.logEvent({
        category: 'strategy',
        action: 'unfavorite',
        label: 'from_' + props.fromTag,
      });
    }

    props.removeFavorite({
      userId: props.user.id,
      itemId: props.id,
      itemType: 'strategy',
    });
  };

  const handleRecommend = (e) => {
    e.stopPropagation();

    props.recommendModal.open({
      itemType: 'strategy',
      itemId: props.id,
    });
  };

  // only show the heart for favoriting/recommending if necessary
  let heartNode;
  if (props.addFavorite || props.recommendModal) {
    // icon and action depends on whether the current user is a coach,
    // or has the strategy favorited/recommended
    let heartIconClass = 'tc-v4-heart-empty';
    let handleFavClick = handleAddFavorite;
    if (props.loading) {
      heartIconClass = 'littlespinner';
      handleFavClick = null;
    } else if (Utils.isContractCoach.call(lab, props.user)) {
      heartIconClass = 'tc-v4-coaching';
      handleFavClick = handleRecommend;
    } else if (props.favorited) {
      heartIconClass = 'tc-v4-heart-filled';
      handleFavClick = handleRemoveFavorite;
    }
    if (props.searchSuccess !== false) {
      heartNode = (
        <div
          onClick={handleFavClick}
          className={'strategies__capsule-favorite ' + heartIconClass}
        ></div>
      );
    }
  }

  // strategy icon depends on strategy type
  const iconClass =
    props.type === 'tech_tool' ? 'tc-v4-laptop' : 'tc-v4-strategy';

  // set up classes for different UI options
  let capsuleClass = '',
    bodyClass = '';
  let maxHeight = 225;
  // dimensions are slightly different for small version of capsule
  if (props.smallVersion) {
    capsuleClass = ' strategies__capsule--small';
    maxHeight = 187;
  }
  // if the capsule needs to be grey on mobile,
  // make sure the background color and the fade out are grey
  let mobileBodyNode;
  if (props.greyOnMobile) {
    capsuleClass += ' strategies__capsule--mobile-grey';
    bodyClass = 'show-desktop hide-phone';
    mobileBodyNode = (
      <AbridgeText
        maxHeight={maxHeight}
        greyFade={true}
        containerClassName="hide-desktop show-phone"
      >
        <div className="strategies__capsule-icon-name-row">
          <div className={'strategies__capsule-icon ' + iconClass}></div>
          <div>
            <LessonLink
              url={strategyUrl}
              strategy={props.id}
              publishStatus={props.publish_status}
              className="tc__link tc__link--black strategies__capsule-name"
            >
              {props.title}
            </LessonLink>
          </div>
        </div>
        <div className="strategies__capsule-tagline">{props.tagline}</div>
      </AbridgeText>
    );
  }
  return (
    <div
      className={
        'bl4__capsule strategies__capsule strategies__capsule--strategy' +
        capsuleClass
      }
      onClick={handleCapsuleClick}
    >
      {heartNode}
      <AbridgeText maxHeight={maxHeight} containerClassName={bodyClass}>
        <div className="strategies__capsule-icon-name-row">
          <div className={'strategies__capsule-icon ' + iconClass}></div>
          <div>
            <LessonLink
              url={strategyUrl}
              strategy={props.id}
              publishStatus={props.publish_status}
              className="tc__link tc__link--black strategies__capsule-name"
            >
              {props.title}
            </LessonLink>
          </div>
        </div>
        <div className="strategies__capsule-tagline">{props.tagline}</div>
      </AbridgeText>

      {mobileBodyNode}
    </div>
  );
};
const WrappedStrategyCapsule = (props) => (
  <HeaderConsumer>
    {({ user }) => <StrategyCapsule {...props} user={user} />}
  </HeaderConsumer>
);

StrategyCapsule.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  user: PropTypes.object,
  addFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
  recommendModal: PropTypes.object,
  smallVersion: PropTypes.bool,
  greyOnMobile: PropTypes.bool,
  fromTag: PropTypes.string,
  searchSuccess: PropTypes.bool,
};

export default WrappedStrategyCapsule;
