// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import StringHelpers from '@legacy-components/components/StringHelpers';
import WindowHelpers from '@legacy-components/components/WindowHelpers';
import FormField from '@legacy-components/react/V5/Common/FormField';
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import ZenScroll from 'zenscroll';

const AccountSettingsForm = (props) => {
  const timeZoneRef = useRef();

  // form fields state
  const [email, setEmail] = useState(props.email || null);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [timeZone, setTimeZone] = useState(props.time_zone_id || null);

  useEffect(() => {
    if (props.scrollTo === 'timezone') {
      if (timeZoneRef.current) {
        ZenScroll.setup(600, WindowHelpers.isMobileDevice() ? 90 : 10);
        ZenScroll.to(timeZoneRef.current);
      }
    }
  }, [props.tab]);

  // clear passwords after submit
  useEffect(() => {
    if (props.clearPasswords) {
      const passwordFields = document.querySelectorAll(
        'input[type="password"]'
      );
      passwordFields.forEach((passwordField) => {
        passwordField.value = '';
      });

      setCurrentPassword(null);
      setNewPassword(null);
      setConfirmNewPassword(null);

      props.setClearPasswords(false);
    }
  }, [props.clearPasswords]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // clear errors on submit
    props.setErrors({});
    props.clearAlert();

    const formErrors = {};

    // validate email not empty
    if (!email) {
      formErrors.email = 'Please enter an email';
      // validate email formatted properly
    } else if (!StringHelpers.isValid(email, 'email')) {
      formErrors.email = 'Please enter a valid email';
    }

    // if either new or confirm password is set
    if (newPassword || confirmNewPassword) {
      // validate current password is set
      if (!currentPassword) {
        formErrors.currentPassword = 'Please enter your current password';
      }

      // validate password is valid
      if (!StringHelpers.passwordIsValid(newPassword)) {
        formErrors.newPassword =
          'Passwords must be at least 8 characters in length, with combination of letters, numbers and symbols @$!%*#?&';
        // validate new password matches confirm password
      } else if (newPassword !== confirmNewPassword) {
        formErrors.confirmNewPassword = 'Passwords do not match';
      }
    }

    // verify that time zone exists
    if (!timeZone || timeZone === 0) {
      formErrors.timeZone = 'Please select your timezone';
    }

    // initialize data
    let data = {
      email: email,
      timezone: timeZone,
    };

    // we have necessary data to attempt a password update append said data
    if (newPassword && confirmNewPassword && currentPassword) {
      data = Object.assign(data, {
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      });
    }

    // set errors
    if (Object.keys(formErrors).length) {
      props.setErrors(formErrors);
      return;
    }

    // clear unsaved changes
    props.setUnSavedChanges(false);

    // submit data
    props.submit(data);
  };

  // The timezones need to be sorted otherwise they'll be listed in the order they were added
  const timeZones = props.timeZones.sort(Utils.sortTimeZones);

  const timeZoneOptions = timeZones.map((tz) => {
    return {
      label: tz.name,
      id: tz.id,
      description: tz.pytz.replace('_', ' '),
    };
  });

  const selectedTimezone = timeZoneOptions.find((tz) => tz.id === timeZone);

  const handleGuess = () => {
    const IANAtimezone = moment.tz.guess(true);
    const guessedTimeZone = props.timeZones.find(
      (tz) => tz.pytz === IANAtimezone
    );
    setTimeZone(guessedTimeZone.id);
    props.setUnSavedChanges(true);
  };

  const handleSelectOption = (selectedTimezone) => {
    if (timeZone) {
      setTimeZone(null);
    } else {
      setTimeZone(selectedTimezone.id);
    }
    props.setUnSavedChanges(true);
  };

  // disable buttons when form submission is loading
  let disabledSubmitClass = '';
  let spinnerNode = null;
  if (props.loading) {
    disabledSubmitClass = ' disabled';
    spinnerNode = <span className="littlespinner"></span>;
  }
  const extraMarginClass = props.errors.newPassword
    ? 'settings__form-field--double-bottom-margin'
    : '';

  return (
    <div className="cute-6-desktop cute-12-phone left account-content">
      <form>
        <h1 className="tc__headline-1">Settings</h1>

        <h3
          className="account-form__header tc__headline-4"
          id="section-email-password"
        >
          Email
        </h3>
        <div className="settings__label">Email</div>
        <FormField
          type="email"
          className=""
          disabled={true}
          error={props.errors.email}
          defaultValue={props.email || ''}
          onChange={(e) => {
            setEmail(e.target.value);
            props.setUnSavedChanges(true);
          }}
          dataTestId="email-field"
        />

        <h3 className="account-form__header tc__headline-4">Change Password</h3>

        <div className="settings__label">Current Password</div>

        <FormField
          type="password"
          className=""
          disabled={props.loading}
          error={props.errors.currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          dataTestId="current-password-field"
        />

        <div className="settings__label">New Password</div>

        <FormField
          type="password"
          className={extraMarginClass}
          disabled={props.loading}
          error={props.errors.newPassword}
          dataTestId="new-password-field"
          onChange={(e) => {
            setNewPassword(e.target.value);
            props.setUnSavedChanges(true);
          }}
        />

        <div className="settings__label">Confirm Password</div>

        <FormField
          type="password"
          className=""
          disabled={props.loading}
          error={props.errors.confirmNewPassword}
          dataTestId="confirm-password-field"
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />

        <label dataTestId="timezone-field">
          <h3
            className="account-form__header tc__headline-4"
            id="section-time-zone"
            ref={timeZoneRef}
          >
            Time Zone
          </h3>
          <MultiSelect
            className="form-field"
            handleSelectOption={handleSelectOption}
            selectedOptions={selectedTimezone ? [selectedTimezone] : []}
            dropdownOptions={timeZoneOptions}
            minCharCountForResults={0}
            stayOpenOnSelect={false}
            maximumSelectedOptionsCount={1}
            isSelectSearch={true}
            placeholder="Search for your time zone"
            error={props.errors.timeZone}
          />
          <div className="form-field">
            Need help? Let us{' '}
            <span className="v5__link" onMouseDown={handleGuess}>
              guess your time zone.
            </span>
          </div>
        </label>

        <button
          onClick={(e) => handleSubmit(e)}
          data-test-id="submit-button"
          className={`account-submit bl4__btn bl4__btn--blue ${disabledSubmitClass}`}
        >
          {spinnerNode}
          Save
        </button>
      </form>
    </div>
  );
};

export default AccountSettingsForm;
