// @ts-nocheck FIXME
import Auth from '@legacy-components/components/Auth';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';
import CKEditor from './../Common/CKEditor/CKEditor';

const TextRow = (props) => {
  // clicking on a row sets that field to edit mode
  const handleClickRow = (e, clickType) => {
    e.preventDefault();
    if (props.ui.editModeFields.indexOf(props.fieldName) === -1) {
      props.setEditMode({
        fieldName: props.fieldName,
        clickType: clickType,
      });
    }
  };

  const handleSubmit = (operativeTerm) => {
    const ckeDescriptionInstanceId =
      'participant-profile__form-input--' +
      props.fieldName +
      '-' +
      props.participant.id +
      '-0';

    props.submitTextArea({
      field: props.fieldName,
      value: CKEDITOR.instances[ckeDescriptionInstanceId].getData(),
      operativeTerm: operativeTerm,
    });
  };

  const getEditVisionTextButton = () => {
    if (Auth.isV3DataEditor(props.user)) {
      return (
        <div
          className="tc__link vision__text-link"
          onClick={(e) => {
            handleClickRow(e, 'edit');
          }}
        >
          Edit
        </div>
      );
    }
  };

  const getVisionTextArea = () => {
    if (Auth.isV3DataEditor(props.user)) {
      return (
        <CKEditor
          id={props.participant.id}
          editorId={props.fieldName + '-' + props.participant.id}
          increment={0}
          innerText={props.participant[props.fieldName]}
          className={'participant-profile__form-input--' + props.fieldName}
          configs="/legacy-libs/configs/bl-cke-config-vision.js"
        />
      );
    }
  };

  let fieldTitle = 'Summary';
  let addTextLink = '+ Add a Design Summary';
  if (props.fieldName === 'vision') {
    fieldTitle = 'Vision';
    addTextLink = '+ Add Vision of Student Success';
  }

  let operativeTerm = 'Add';
  let fieldNode;

  if (Auth.isV3DataEditor(props.user)) {
    fieldNode = (
      <a
        className="tc__link"
        onClick={(e) => {
          handleClickRow(e, 'add');
        }}
      >
        {addTextLink}
      </a>
    );
  }

  if (props.participant[props.fieldName]) {
    operativeTerm = 'Update';
    fieldNode = (
      <div className="">
        <div className="tc__body-copy">
          <span className="vision__text-label">{props.labelName}</span>
          <PurifiedHTML
            className="vision__text-data ckeditor__output"
            content={props.participant[props.fieldName]}
            tagName="span"
          />
        </div>
        {getEditVisionTextButton()}
      </div>
    );
  }

  // by default, show the whole row as clickable to edit
  let rowClass = ' participant-profile__display-row';

  // if the row is in edit mode, show the field form
  if (props.ui.editModeFields.indexOf(props.fieldName) !== -1) {
    rowClass = '';

    // set up errors for the field
    let fieldErrorClass = '';
    let fieldErrorNode = null;
    if (props.fieldName in props.ui.errors) {
      fieldErrorClass = ' error';
      fieldErrorNode = (
        <div className="tc__error">{props.ui.errors[props.fieldName]}</div>
      );
    }

    let buttonDisabledClass = '';
    let loadingNode = null;
    if (props.ui.loadingFields.indexOf(props.fieldName) !== -1) {
      buttonDisabledClass = ' disabled';
      loadingNode = <div className="spinner-icon"></div>;
    }

    let cancelNode, submitNode;

    if (Auth.isV3DataEditor(props.user)) {
      submitNode = (
        <span
          className={
            'tc__btn tc__btn--v3 tc__btn--flat-blue ' + buttonDisabledClass
          }
          onClick={() => {
            handleSubmit(operativeTerm);
          }}
        >
          {operativeTerm + ' ' + fieldTitle} {loadingNode}
        </span>
      );
      // Don't show cancel node if there has never been a vision
      if (props.fieldName !== 'vision' || props.participant[props.fieldName]) {
        cancelNode = (
          <span
            className="tc__btn tc__btn--flat-grey participant-profile__form-cancel-btn participant-profile__form-cancel-btn--vision"
            onClick={() =>
              props.setDisplayMode({
                field: props.fieldName,
                operativeTerm: operativeTerm,
              })
            }
          >
            Cancel
          </span>
        );
      }
    }

    // TODO: clear errors on focus
    fieldNode = (
      <form className="vision__text-fields">
        <div className={fieldErrorClass}>{getVisionTextArea()}</div>
        {fieldErrorNode}
        <div className="vision__form-btns">
          {submitNode}
          {cancelNode}
        </div>
      </form>
    );
  }

  return (
    <div className="row">
      <div
        className={
          'vision__form-container cute-8-desktop cute-12-phone center-desktop' +
          rowClass
        }
      >
        {fieldNode}
      </div>
    </div>
  );
};

TextRow.propTypes = {
  ui: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setDisplayMode: PropTypes.func.isRequired,
  submitTextArea: PropTypes.func.isRequired,
};

export default TextRow;
