// @ts-nocheck FIXME
import createReactClass from 'create-react-class';
import Analytics from './../../../components/Analytics';
import BrowserStore from './../../../stores/V2/BrowserStore';

const SearchTab = createReactClass({
  displayName: 'SearchTab',

  getInitialState: function () {
    return BrowserStore.getState();
  },

  componentDidMount: function () {
    this.token = BrowserStore.subscribe(this.onStoreChange);
  },

  onStoreChange: function () {
    // eslint-disable-next-line react/no-is-mounted
    if (this.isMounted()) {
      this.setState(BrowserStore.getState());
    }
  },

  handleToggleSearchBar: function (e) {
    e.preventDefault();

    let action = 'search';

    if (this.state.searchBarOpened) {
      action = 'close_search';
    }
    // Record the event in GA:
    Analytics.logEvent({
      category: 'strategy',
      action: action,
      label: 'from_sb_find_strategies',
    });
    BrowserStore.actionDispatchSync('toggleSearchBar');
  },

  componentWillUnmount: function () {
    BrowserStore.unsubscribe(this.token);
  },

  render: function () {
    const openedSearchBarClass = this.state.searchBarOpened ? ' active' : '';

    return (
      <a
        className={
          'browser__tab browser__tab--search tc__center-text hide-phone show-desktop tc-search' +
          openedSearchBarClass
        }
        onClick={this.handleToggleSearchBar}
      ></a>
    );
  },
});

export default SearchTab;
