// @ts-nocheck FIXME
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import FeedbackRow from './EditFeedback/FeedbackRow';

const EditFeedback = (props) => {
  // handle clicking on the Save & Exit button in the footer
  const handleSave = () => {
    props.saveSubstep('feedback');
  };

  // only show the loop's completed date it if has one
  const completedDateNode = props.loop.completed ? (
    <div className="tc__body-copy portfolio-edit__substep-strategy-completed">
      Completed: {dateformat(props.loop.completed, 'mm/dd/yyyy')}
    </div>
  ) : null;

  // build the list of feedback in the loop
  const feedbackNodes = [];
  ['teach', 'measure', 'learn'].forEach((step) => {
    const feedback = props.loop.feedback[step];
    const inEditMode = props.editModeSteps.indexOf(step) != -1;
    const commentsExpanded = props.showCommentsSteps.indexOf(step) != -1;
    feedbackNodes.push(
      <hr
        key={feedback.id + '-sep'}
        className="portfolio-edit__feedback-separator"
      />
    );
    feedbackNodes.push(
      <FeedbackRow
        key={feedback.id}
        feedback={feedback}
        loopId={props.loop.loopId}
        setFeedbackEditMode={props.setFeedbackEditMode}
        inEditMode={inEditMode}
        updateFeedback={props.updateFeedback}
        showFeedbackComments={props.showFeedbackComments}
        commentsExpanded={commentsExpanded}
        toggleFeedbackCommentVisibility={props.toggleFeedbackCommentVisibility}
      />
    );
  });

  return (
    <div>
      <div className="portfolio-edit__substep-container">
        <div className="row">
          <div className="cute-10-desktop center-desktop">
            <div className="tc__headline-6">Strategy</div>
            <div className="tc__headline-1 portfolio-edit__substep-strategy-name">
              {props.loop.title}
            </div>
            {completedDateNode}
            <div className="portfolio-edit__feedback-headline">
              Your Reflections on this strategy
            </div>
            {feedbackNodes}
          </div>
        </div>
      </div>
      <div className="portfolio-edit__substep-footer tc__fixed-bottom">
        <button
          className="tc__btn tc__btn--small tc__btn--blue"
          onClick={handleSave}
        >
          Save &amp; Exit
        </button>
      </div>
    </div>
  );
};

EditFeedback.propTypes = {
  loop: PropTypes.object.isRequired,
  editModeSteps: PropTypes.array.isRequired,
  showCommentsSteps: PropTypes.array.isRequired,
  saveSubstep: PropTypes.func.isRequired,
};

export default EditFeedback;
