// @ts-nocheck FIXME
import Modal from '@legacy-components/react/V5/Common/Modal';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import PropTypes from 'prop-types';

const ConfirmDeleteAttendeeModal = (props) => {
  // text depends on whether or not the attendee has checked in for any sessions
  let textNode = (
    <div className="v5__body-copy">
      <span className="bold">{props.attendeeName}</span> will be removed from
      the event attendance list and they will not receive any future
      event-related emails.
    </div>
  );
  if (props.hasCheckedIn) {
    textNode = (
      <div className="v5__body-copy">
        <span className="bold">{props.attendeeName}</span> has checked in for
        one or more event sessions. You will lose all RSVP and attendance
        information and they will not receive any future event-related emails.
      </div>
    );
  }

  // disable the Delete button after submit
  const deleteBtnProps = props.loading ? { disabled: true } : null;

  return (
    <Modal
      className="admin-v5__confirm-delete-modal"
      close={props.close}
      spaceFromTop={50}
    >
      <div className="admin-v5__confirm-delete-modal-heading">
        Delete {props.attendeeName} from Event?
      </div>

      {textNode}

      <div className="admin-v5__confirm-delete-modal-btn-row">
        <button
          className="v5__btn v5__btn--red"
          onClick={props.confirm}
          {...deleteBtnProps}
        >
          <Spinner loading={props.loading} />
          Delete
        </button>

        <button
          onClick={props.close}
          className="admin-v5__confirm-delete-modal-cancel-btn v5__btn v5__btn--blue-border"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

ConfirmDeleteAttendeeModal.propTypes = {
  hasCheckedIn: PropTypes.bool,
  attendeeName: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ConfirmDeleteAttendeeModal;
