// @ts-nocheck FIXME
import CohortApi from '@legacy-components/apis/CohortApi';
import ResourceApi from '@legacy-components/apis/ResourceApi';
import Utils from '@legacy-components/components/DynamicUtils';
import S3 from '@legacy-components/components/S3';
import ArtifactLink from '@legacy-components/react/V3/Common/ArtifactUpload/ArtifactLink';
import ArtifactProgress from '@legacy-components/react/V3/Common/ArtifactUpload/ArtifactProgress';
import ArtifactUpload from '@legacy-components/react/V3/Common/ArtifactUpload/ArtifactUpload';
import FreezeWindowScroll from '@legacy-components/react/V3/Common/FreezeWindowScroll';
import PropTypes from 'prop-types';
import React from 'react';
import UploadArtifactModalEditForm from './UploadArtifactModalEditForm';

class UploadArtifactModal extends React.Component {
  constructor(props) {
    super(props);
    // some of the initial state depends on props
    const entry = props.entry || {};

    this.state = {
      mode: props.entry.mode,
      entry: entry,
      dragOver: false,
      blur: false,
      isLoading: false,
      errors: {},
    };
  }

  componentDidMount() {
    // If a mobile user uploaded files directly from the timeline
    // submit the said files right away
    if (window.uploadedFiles && this.state.mode === 'mobile') {
      this.submitFiles({
        inputFiles: window.uploadedFiles,
      });
    }
  }

  handleCloseModal = (e) => {
    e.preventDefault();
    // If the user uploads an artifact and then changes their mind,
    // we need to delete the artifact
    const entry = this.state.entry;
    if (entry && entry.isNew) {
      this.props.delete({ id: entry.id });
    }

    this.props.close();
  };

  /*** ENTRY HANDLERS ***/
  handleFocus = (field) => {
    const errors = Utils.clone(this.state.errors);
    // remove the field's error and any form-wide errors
    delete errors[field];
    delete errors.api;
    this.setState({
      errors: errors,
    });
  };

  submitEntry = (data) => {
    // clear errors
    this.setState({
      errors: {},
    });

    // validate data
    const errors = {};
    if (!data.title) {
      errors.title = 'Please enter a title';
    }

    // Given that we create an empty entry when a file or a link is uploaded,
    // we will never create an entry using this endpoint
    // The modal should always have an entry/artifact id
    if (!this.state.entry) {
      errors.api =
        'Sorry, there was a problem submitting the artifact. Please try again later.';
    }
    if (Object.keys(errors).length) {
      this.setState({
        errors: errors,
      });
      return;
    }

    // start loading animation
    this.setState({
      isLoading: true,
    });
    // add necessary data to send to API
    data.cohortId = this.props.cohortId;

    // add the update id from the modal
    data.updateId = this.state.entry.id;
    data.reportId = this.state.entry.id;

    return CohortApi.editCohortReport(data)
      .then((response) => {
        // add the update to the list
        response['index'] = this.state.entry.index;
        this.props.editArtifactCallback(response);
        // close the modal
        this.props.close();
      })
      .catch((response) => {
        this.setState({
          isLoading: false,
          errors: {
            api:
              response.error ||
              'Sorry, there was a problem submitting the artifact. Please try again later.',
          },
        });
      });
  };

  /*** FILE UPLOAD HANDLERS ***/
  handleArtifactsChange = (e) => {
    e.preventDefault();
    // Clear upload errors
    const errors = this.state.errors;
    delete errors.api;
    this.setState({
      errors: errors,
    });

    this.submitFiles();
  };

  submitFiles = (data) => {
    const inputFiles = data.inputFiles;
    let file;

    // Clear upload errors and set the blur state
    const errors = this.state.errors;
    delete errors.api;

    this.setState({
      errors: errors,
      blur: true,
    });

    for (let i = 0; i < inputFiles.length; i++) {
      // Add some of the POST fields that the backend needs
      file = {
        lastModified: inputFiles[i]['lastModified'],
        lastModifiedDate: inputFiles[i]['lastModifiedDate'],
        name: inputFiles[i]['name'],
        size: inputFiles[i]['size'],
        type: inputFiles[i]['type'],
        uuid: Utils.uuid(),
      };
      // max size is 1024MB
      if (file.size > 1024000000) {
        const errors = this.state.errors;
        errors['api'] = 'Please select a file less than 1024MB';
        // Reset the blur state
        this.setState({
          errors: errors,
          blur: false,
          // Reset the modal to the default mode so that all users can see the ArtifactUpload component
          mode: null,
          entry: {},
        });
      } else {
        // The teacherId property is required by the ancient resources interface :-(
        inputFiles[i]['uuid'] = file['uuid'];
        const callbackFields = {
          cohortId: this.props.cohortId,
          teacherId: this.props.artifactUserId,
        };
        // Upload the file to S3
        S3.flexibleUpload(
          inputFiles[i],
          ResourceApi.createBeforeUpload,
          ResourceApi.upload,
          this.createArtifact,
          this.uploadCleanupCallback,
          callbackFields
        ).then(
          function onSuccess(fileData) {
            fileData['message'] = 'Upload Complete';
            fileData['status'] = 'after';
          },
          function onError(fileData) {
            // See if the file has the error property
            let error = fileData['error'];
            if (!error) {
              error =
                'There was an error uploading your file. Please try again.';
            }
            // Set the error and reset the blur in the state
            const errors = this.state.errors;
            errors.api = error;
            // Clear the file from the store and set the upload error
            this.setState({
              entry: {},
              errors: errors,
              blur: false,
              // Reset the mode so that the user sees ArtifactUpload
              mode: null,
            });
          }.bind(this),
          function onProgress(fileData) {
            // If there is an error, abort
            if (fileData.status === 'error') {
              return;
            }
            fileData['message'] = fileData.progress + '%';
            fileData['status'] = 'during';
            fileData['percent'] = fileData.progress;
            // Update the state's file and the mode
            this.setState({
              entry: fileData,
              mode: 'during',
            });
          }.bind(this)
        );
      }
    }
    // Destroy any uploadedFiles on the window object (mobile users)
    delete window.uploadedFiles;
  };

  handleSelectFiles = () => {};

  onDragOver = () => {
    // Clear upload errors and set drag over
    const errors = this.state.errors;
    delete errors.api;
    this.setState({
      errors: errors,
      dragOver: true,
    });
  };

  onDragLeave = () => {
    // Clear upload errors and reset drag over
    const errors = this.state.errors;
    delete errors.api;
    this.setState({
      errors: errors,
      dragOver: false,
    });
  };

  handleUploadCancel = (e) => {
    this.handleCloseModal(e);
  };

  createArtifact = (data) => {
    // clear errors
    this.setState({
      errors: {},
      isLoading: true,
    });

    // add necessary data to send to API
    data.cohortId = this.props.cohortId;

    // Add the entry
    return CohortApi.addCohortReport(data)
      .then(
        function (response) {
          const newEntry = response.update ? response.update : response.report;
          // Declare the entry to be new
          newEntry.isNew = true;
          // update the modal state for next step
          this.setState({
            entry: newEntry,
            mode: 'edit',
            isLoading: false,
          });
        }.bind(this)
      )
      .catch(
        function (response) {
          // We could not create the artifact entry, we need to cleanup
          this.uploadCleanupCallback(data);
          this.setState({
            isLoading: false,
            entry: {},
            blur: false,
            // Reset the modal to the default mode so that all users can see the ArtifactUpload component
            mode: null,
            errors: {
              api:
                response.error ||
                'Sorry, there was a problem adding the artifact. Please try again later.',
            },
          });
        }.bind(this)
      );
  };

  uploadCleanupCallback = (data) => {
    if ('teacherId' in data && 'lessonfileId' in data) {
      ResourceApi.delete({
        teacherId: data.teacherId,
        lessonfileId: data.lessonfileId,
      });
    }
  };

  /****** HELPERS ******/
  clearApiError = () => {
    const errors = this.state.errors;
    delete errors.api;
    this.setState({
      errors: errors,
    });
  };

  render() {
    const entry = this.state.entry || {};
    // modal headline text and form depend on the step
    let modalHeading = 'Add Resource';
    let formNode = null;

    switch (this.state.mode) {
      case 'mobile':
        // Mobile users uploading files directly from the timeline will
        // see the ArtifactProgress component
        formNode = (
          <ArtifactProgress
            file={{
              name: 'Processing',
            }}
            containerCssClass="outcome-story_artifact__upload-progress"
          />
        );
        break;
      case 'during':
        formNode = (
          <ArtifactProgress
            file={entry}
            containerCssClass="outcome-story_artifact__upload-progress"
          />
        );
        break;
      case 'after':
      case 'edit':
        modalHeading = this.state.entry.isNew
          ? modalHeading
          : modalHeading.replace('Add', 'Edit');
        formNode = (
          <UploadArtifactModalEditForm
            file={entry}
            isNew={this.state.entry.isNew}
            loading={this.state.isLoading}
            errors={this.state.errors}
            handleFocus={this.handleFocus}
            editArtifact={this.submitEntry}
            handleCloseModal={this.handleCloseModal}
          />
        );
        break;
      default:
        if (this.props.uploadType === 'link') {
          formNode = (
            <ArtifactLink
              handleUploadCancel={this.handleUploadCancel}
              submitLink={this.createArtifact}
              loading={this.state.isLoading}
              setArtifactLinkUploadError={this.clearApiError}
              error={this.state.errors.api}
              buttonDisabled={this.state.isLoading}
            />
          );
        } else {
          formNode = (
            <ArtifactUpload
              {...this.props.ui}
              submitFiles={this.submitFiles}
              handleSelectFiles={this.handleSelectFiles}
              onDragLeave={this.onDragLeave}
              onDragOver={this.onDragOver}
              handleUploadCancel={this.handleUploadCancel}
              error={this.state.errors.api}
              blur={this.state.blur}
              dragOver={this.state.dragOver}
            />
          );
        }
    }

    // by default, show the modal with the form
    const modalNode = (
      <div className="modal--fixed modal--grey-overlay outcome-story__modal">
        <div
          className="modal__close hide-phone show-desktop"
          onClick={this.handleCloseModal}
        >
          <div className="tc-x"></div>
        </div>

        <div className="tc__mobile-header show-phone hide-desktop">
          <div
            className="tc__back cute-2-desktop left"
            onClick={this.handleCloseModal}
          >
            <div className="tc__back-arrow tc-arrow"></div>
            <div className="tc__back-caption">Back</div>
          </div>
          <div className="tc__mobile-header-title tc__headline-4 cute-8-phone left">
            {modalHeading}
          </div>
        </div>

        <div className="outcome-story__modal-body">
          <div className="outcome-story__modal-headline show-desktop hide-phone">
            {modalHeading}
          </div>
          {formNode}
        </div>
      </div>
    );

    return (
      <div>
        <div
          className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
          onClick={this.handleCloseModal}
        ></div>
        {modalNode}
        <FreezeWindowScroll parentModal="outcome-story__modal" />
      </div>
    );
  }
}

UploadArtifactModal.propTypes = {
  uploadType: PropTypes.string,
  entryType: PropTypes.string,
  entry: PropTypes.object,
  close: PropTypes.func.isRequired,
  editArtifactCallback: PropTypes.func.isRequired,
  cohortId: PropTypes.number.isRequired,
  delete: PropTypes.func.isRequired,
  artifactUserId: PropTypes.number.isRequired,
};

export default UploadArtifactModal;
