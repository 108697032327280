// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const ArchivedParticipantsTable = (props) => {
  // let directionalArrow = (props.sortDirection === 'ascending') ? (
  //     <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
  // ) : (
  //     <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
  // );
  // Commenting out references related to sorting
  return (
    <div
      className="admin-v5__coach-table"
      data-test-id="archived-participants-table"
    >
      <div className="admin-v5__coach-table__header grid-ten__row">
        <div
          className="admin-v5__coach-table__header-col grid-ten__col-3"
          data-test-id="tableHeader"
        >
          {/* onClick={() => props.sortBy('last_name')} */}
          <span className={'ppd-3-coach-table__header-caption left'}>
            {/* clickable ${props.sortColumn === 'last_name' ? ' active' : ''}`} */}
            Name
          </span>
          {/* {props.sortColumn === 'last_name' ? directionalArrow : null} */}
        </div>
        <div
          className="admin-v5__coach-table__header-col grid-ten__col-2"
          data-test-id="tableHeader"
        >
          {/* onClick={() => props.sortBy('cohort')} */}
          <span className={'ppd-3-coach-table__header-caption left'}>
            {/* clickable${props.sortColumn === 'cohort' ? ' active' : ''}`}> */}
            Cohort
          </span>
          {/* {props.sortColumn === 'cohort' ? directionalArrow : null} */}
        </div>
        <div
          className="admin-v5__coach-table__header-col grid-ten__col-2"
          data-test-id="tableHeader"
        >
          <span className={'ppd-3-coach-table__header-caption left'}>
            Dates
          </span>
        </div>
        <div
          className="admin-v5__coach-table__header-col grid-ten__col-1"
          data-test-id="tableHeader"
        >
          <span
            className={
              'ppd-3-coach-table__header-caption ppd-3-coach-table__header-caption--highlights left'
            }
          >
            Focus Areas
          </span>
        </div>
        <div className="admin-v5__coach-table__header-col grid-ten__col-1" />
      </div>
      {props.children}
    </div>
  );
};

export default ArchivedParticipantsTable;

ArchivedParticipantsTable.propTypes = {
  // sortColumn: PropTypes.string.isRequired,
  // sortDirection: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
