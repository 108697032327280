// @ts-nocheck FIXME

const selectedOption = (props) => {
  if (!props.selectedOption) {
    return null;
  }
  return (
    <div className="custom-select__selected custom-select__selected--single-select">
      <div className="custom-select__selected-name">
        {props.selectedOption.label}
      </div>
      <div
        onClick={props.handleClearOption}
        className="custom-select__selected-remove tc-x"
      />
    </div>
  );
};

export default selectedOption;
