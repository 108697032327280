// @ts-nocheck FIXME
import Header from '@legacy-components/react/V5/Header/HeaderContainer';
import React from 'react';
import ReactDOM from 'react-dom';
import UserApi from './../../apis/UserApi';
import { Router } from './../../components/Router';
import SmoothScroll from './../../components/SmoothScroll';
import AbsoluteAlert from './../Alerts/Absolute';
import NavigationLink from './../NavigationLink';

class AdminUserCreate extends React.Component {
  state = {
    btnDisabledClass: '',
    alert: {},
    errors: {},
  };

  handleAddUser = (e) => {
    e.preventDefault();

    // Reset alerts:
    this.setState({
      btnDisabledClass: ' disabled',
      alert: {},
      errors: {},
    });

    const data = {
      first_name: ReactDOM.findDOMNode(this.refs.first_name).value,
      last_name: ReactDOM.findDOMNode(this.refs.last_name).value,
      email: ReactDOM.findDOMNode(this.refs.email).value,
      password: ReactDOM.findDOMNode(this.refs.password).value,
    };

    // Validate input
    const errors = {};
    if (!data.first_name) {
      errors.first_name = 'Please enter a first name.';
    }
    if (!data.last_name) {
      errors.last_name = 'Please enter a last name.';
    }
    if (!data.email) {
      errors.email = 'Please enter an email address.';
    }

    if (Object.keys(errors).length) {
      this.setState({ btnDisabledClass: '', errors: errors });
      // scrolls to the top of the page in 400 ms
      SmoothScroll(document.body, 0, 300);
      return;
    }

    // create the user, and redirect to /bl/user/<id> if successful
    UserApi.createUser(data)
      .then(function (response) {
        Router.route('/bl/user/' + response.id + '#show-metadata');
      })
      .catch(
        function (response) {
          this.setState({
            btnDisabledClass: '',
            alert: {
              type: 'error',
              message: response.error,
            },
          });
        }.bind(this)
      );
  };

  clearAlerts = () => {
    this.setState({
      alert: {},
      errors: {},
    });
  };

  render() {
    // Build the alert node if needed:
    let alertNode;
    if (Object.keys(this.state.alert).length > 0) {
      alertNode = (
        <AbsoluteAlert
          {...this.state.alert}
          cssClasses={{ container: 'alert-banner--fixed' }}
        />
      );
    }

    // set up errors
    let firstNameErrorClass = '';
    let firstNameErrorNode = null;
    if ('first_name' in this.state.errors) {
      firstNameErrorNode = (
        <div className="tc__error">{this.state.errors.first_name}</div>
      );
      firstNameErrorClass = ' error';
    }
    let lastNameErrorClass = '';
    let lastNameErrorNode = null;
    if ('last_name' in this.state.errors) {
      lastNameErrorNode = (
        <div className="tc__error">{this.state.errors.last_name}</div>
      );
      lastNameErrorClass = ' error';
    }
    let emailErrorClass = '';
    let emailErrorNode = null;
    if ('email' in this.state.errors) {
      emailErrorNode = (
        <div className="tc__error">{this.state.errors.email}</div>
      );
      emailErrorClass = ' error';
    }

    return (
      <div>
        <Header page="AdminDashboard" {...this.props} />
        {alertNode}

        <div className="row">
          <div className="cute-12-desktop">
            <div className="admin-v2__user-page-breadcrumbs">
              <NavigationLink url="/bl/users" className="tc__link">
                Users
              </NavigationLink>{' '}
              | New User Account
            </div>
            <h1 className="admin-v2__user-create-header tc__headline-2">
              New User Account
            </h1>
          </div>
        </div>
        <div className="row">
          <form className="cute-12-desktop">
            <div className="admin-v2__user-page-section-subheader">
              These fields are required. If you do not provide a password, then
              we will randomly generate one for the user.
            </div>
            <div className="admin-v2__user-create-form-item admin-v2__user-create-form-item--room-for-error clearfix">
              <input
                type="text"
                placeholder="First Name"
                ref="first_name"
                className={`cute-6-desktop cute-12-phone left tc__input${firstNameErrorClass}`}
                onFocus={this.clearAlerts}
              />
              {firstNameErrorNode}
            </div>
            <div className="admin-v2__user-create-form-item admin-v2__user-create-form-item--room-for-error clearfix">
              <input
                type="text"
                ref="last_name"
                placeholder="Last Name"
                className={`cute-6-desktop cute-12-phone left tc__input${lastNameErrorClass}`}
                onFocus={this.clearAlerts}
              />
              {lastNameErrorNode}
            </div>
            <div className="admin-v2__user-create-form-item admin-v2__user-create-form-item--room-for-error clearfix">
              <input
                type="text"
                ref="email"
                placeholder="Email Address"
                className={`cute-6-desktop cute-12-phone left tc__input${emailErrorClass}`}
                onFocus={this.clearAlerts}
              />
              {emailErrorNode}
            </div>
            <div className="admin-v2__user-create-form-item clearfix">
              <input
                type="password"
                ref="password"
                placeholder="Password"
                className="cute-6-desktop cute-12-phone left tc__input"
              />
            </div>

            <div className="admin-v2__user-create-form-item clearfix">
              <div>
                <div className="admin-v2__user-page-section-subheader">
                  After you create the user, you need to assign them the
                  necessary permissions to use the app.
                </div>
                <button
                  onClick={this.handleAddUser}
                  className={`admin-v2__submit-btn tc__btn tc__btn--blue tc__btn--small${this.state.btnDisabledClass}`}
                >
                  Add User
                </button>
                <NavigationLink url="/bl/users" className="tc__link">
                  Cancel
                </NavigationLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AdminUserCreate;
