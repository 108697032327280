// @ts-nocheck FIXME
import moment from 'moment-timezone';

const StringHelpers = {
  splitCamelCase: function (input) {
    // Splits a string by capital letters, replaces capitals with lower case letters and adds spaces
    return input.replace(/([A-Z])/g, ' $1');
  },

  getPasswordValidationErrors: function (password, confirmPassword) {
    /*
        Validates the provided passwords and returns a {} of errors
        */
    const errors = {};
    if (!this.passwordIsValid(password)) {
      errors.password =
        'Please enter a password. Passwords must be at least 8 characters in length, with combination of letters, numbers and symbols @$!%*#?&';
    }
    if (!confirmPassword) {
      errors.confirmPassword = 'Please confirm your password.';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'The provided passwords do not match.';
    }

    return errors;
  },

  passwordIsValid: function (password) {
    // password is valid if it matches the strong password requirement in user-service
    // where it requires at least 8 characters, in which:
    //  at least 1 character is alphabet,
    //  at least 1 character is number,
    //  at least 1 character is a symbol in [@, $, !, %, *, #, ?, &]
    return (
      password &&
      new RegExp(
        '^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$'
      ).test(password)
    );
  },

  isValid: function (value, type) {
    switch (type) {
      case 'email':
        return this.isEmailValid(value);
      default:
        return value && value.length > 0 && value.trim() !== '';
    }
  },

  isEmailValid: function (email) {
    // @todo @kinano See if we can use an external library instead of writing our home-grown regexes
    const re =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[_a-z0-9][-_a-z0-9]*\.)*(?:[a-z0-9][-a-z0-9]{0,62})\.(?:(?:[a-z]{2}\.)?[a-z]{2,})$/i;

    return re.test(email);
  },
  formatDurationHms: (duration) => {
    let formattedDuration = '00:00';

    if (duration) {
      formattedDuration = moment.utc(duration * 1000).format('mm:ss');
      // Only show hours if the recording is longer than an hour
      if (duration > 3600) {
        formattedDuration = moment.utc(duration * 1000).format('HH:mm:ss');
      }
    }

    return formattedDuration;
  },
};

export default StringHelpers;
