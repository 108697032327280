// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';

const AttendeeRow = (props) => {
  let status = <span className="tc-check bulk-event-import__check" />;
  if (
    (props.errors.length > 0 || props.warnings.length > 0) &&
    !props.importSuccess
  ) {
    status = (
      <React.Fragment>
        <div className="bulk-event-import__failure">
          {props.errors.map((error, i) => (
            <div key={i}>
              <span className="tc-v5-alert" /> {error}
            </div>
          ))}
        </div>
        <div className="bulk-event-import__warning">
          {props.warnings.map((warning, i) => (
            <div key={i}>
              <span className="tc-exclamation-triangle" /> {warning}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
  return (
    <tr className="admin-table__row">
      <td className="admin-table__col"> {props.rowCount} </td>
      <td className="admin-table__col"> {status} </td>
      <td className="admin-table__col"> {props['Attendee FIRST Name']} </td>
      <td className="admin-table__col"> {props['Attendee LAST Name']} </td>
      <td className="admin-table__col"> {props['Attendee Role']} </td>
      <td className="admin-table__col"> {props['School Email Address']} </td>
      <td className="admin-table__col"> {props['Group (optional)']} </td>
    </tr>
  );
};

AttendeeRow.propTypes = {
  'Attendee FIRST Name': PropTypes.string.isRequired,
  'Attendee LAST Name': PropTypes.string.isRequired,
  'Attendee Role': PropTypes.string.isRequired,
  'Group (optional)': PropTypes.string,
  'School Email Address': PropTypes.string.isRequired,
  errors: PropTypes.array,
  invalidFields: PropTypes.array,
  importSuccess: PropTypes.bool,
};

export default AttendeeRow;
