// @ts-nocheck FIXME

const SingleSelectOptions = (props) => {
  if (
    !props.visibleOptions.length ||
    props.charCount < props.minCharCountForResults
  ) {
    return null;
  }

  const availableNodes = props.visibleOptions
    .filter((option) => {
      // if the value is selected, it is not available
      if (props.selectedOption && props.selectedOption.id === option.id) {
        return false;
      }
      return true;
    })
    .map((option, i) => {
      return (
        <div
          className="custom-select__option custom-select__option--variable-height"
          onClick={(e) => {
            props.handleSelectOption(option, e);
          }}
          key={props.field + '-' + option.id + '-' + i}
        >
          <div>{option.label}</div>
          <div className="custom-select__option-description">
            {option.description}
          </div>
        </div>
      );
    });

  // only show the options menu if there are visible options
  return <div className="custom-select__options">{availableNodes}</div>;
};

export default SingleSelectOptions;
