// @ts-nocheck FIXME
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import React from 'react';
import SelectResourceView from './SelectResourceView';

class SelectResourceContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: {},
      selectedResource: {},
    };

    this.setErrorAlert = this.setErrorAlert.bind(this);
    this.setSelectedResource = this.setSelectedResource.bind(this);
  }

  setErrorAlert = (message) => {
    // use default error message if necessary
    message =
      message ||
      'Oh no! Looks like something broke on our end. Wait a minute and try again.';
    this.setState({
      alert: {
        type: 'error',
        message: message,
        handleClose: this.clearAlert,
      },
    });
  };

  clearAlert = () => {
    this.setState({ alert: {} });
  };

  setSelectedResource(data) {
    this.setState({ selectedResource: data });
  }

  render() {
    return (
      <SelectResourceView
        pageUser={this.props.pageUser}
        storyId={this.props.activeStoryId}
        searchSuccess={this.props.searchSuccess}
        favorites={this.props.favorites}
        strategyPacks={this.props.strategyPacks}
        selectedResource={this.state.selectedResource}
        setSelectedResource={this.setSelectedResource}
        alert={this.state.alert}
        setErrorAlert={this.setErrorAlert}
        headerParticipant={this.props.headerParticipant}
      />
    );
  }
}

const ConnectedSelectResourceContainer = (props) => (
  <HeaderConsumer>
    {({ headerParticipant }) => (
      <SelectResourceContainer
        {...props}
        headerParticipant={headerParticipant}
      />
    )}
  </HeaderConsumer>
);

export default ConnectedSelectResourceContainer;
