// @ts-nocheck FIXME
import KpiContainer from './KpiContainer';

const Kpi = (props) => {
  // measure line height
  const KpiCaption = KpiContainer(props.data.text);

  return (
    <div className="row">
      <div className="ppp__elem ppp__elem--kpi cute-10-desktop cute-1-desktop-offset left">
        <div className="ppp__kpi-value">{props.data.value}</div>
        <KpiCaption />
      </div>
    </div>
  );
};

export default Kpi;
