// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const SelectType = (props) => {
  const handleClick = (e) => {
    e.preventDefault();

    if (props.handleFocus) {
      props.handleFocus();
    }
    props.showDropDownOptions();
  };

  let selectedLabel = 'Select Artifact Type';
  if (props.selectedTypeId) {
    props.artifactTypes.map(function (type) {
      if (type['id'] == props.selectedTypeId) {
        selectedLabel = type['name'];
      }
    });
  }

  let errorClass = '';
  let errorNode = null;
  if (props.error) {
    errorClass = ' error';
    errorNode = <div className="tc__error">{props.error}</div>;
  }

  // We want to prevent users from selecting a deleted artifact type
  // unless this is a legacy record that already had a deleted artifact type
  const artifactTypes = props.artifactTypes.filter(function (artifactType) {
    return artifactType.deleted == 0 || artifactType.id == props.selectedTypeId;
  });

  return (
    <div key="artifactSelect" className="artifact__form-row">
      <label className="artifact__type-label artifact__upload-label">
        What kind of artifact is this?
      </label>
      <div
        className={
          'tc__select--artifact-type tc__select--artifact-type-tml-v2 tc__select' +
          errorClass +
          (props.selectActive == true ? ' active' : '')
        }
        onClick={handleClick}
      >
        {selectedLabel}

        <div className="tc__select-dropdown">
          {artifactTypes.map(function (artifactType) {
            return (
              <div
                key={'artifactType' + artifactType.id}
                className="tc__select-option tc__link"
                data-value={artifactType.id}
                onClick={() => {
                  props.handleSelectArtifactType(artifactType.id);
                }}
              >
                {artifactType.name}
              </div>
            );
          })}
        </div>
      </div>
      {errorNode}
    </div>
  );
};

SelectType.proptypes = {
  showDropDownOptions: PropTypes.func.isRequired,
  handleSelectArtifactType: PropTypes.func.isRequired,
  handleFocus: PropTypes.func,
  artifactTypes: PropTypes.array.isRequired,
  error: PropTypes.string,
  selectedTypeId: PropTypes.number,
};

export default SelectType;
