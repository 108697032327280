// @ts-nocheck FIXME
import Request from './../components/Request';

const CustomSchoolsApi = {
  approve: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/custom-school/approve',
      JSON.stringify(data)
    );
  },

  edit: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/custom-school/edit',
      JSON.stringify(data)
    );
  },

  combine: (data) => {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/custom-school/combine',
      JSON.stringify(data)
    );
  },
};

export default CustomSchoolsApi;
