// @ts-nocheck FIXME
import dateformat from 'dateformat';
import moment from 'moment';
import React from 'react';

// This component was created to replace TimeAgo due to a bug in Safari where the timezone wasn't being respected
class TimeDelta extends React.Component {
  constructor(props) {
    super(props);

    // We override new Date to return a static value during testing so
    // we need to pass in Date.now() so that this test always passes
    const datetime =
      props.datetime || dateformat(new Date(Date.now()), 'isoDateTime');

    this.state = {
      timeDelta: moment(datetime).fromNow(),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        timeDelta: moment(this.props.datetime).fromNow(),
      }),
        15000;
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return this.state.timeDelta;
  }
}

export default TimeDelta;
