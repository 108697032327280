// @ts-nocheck FIXME
import Elements from './../Elements';

const StudentVoice = (props) => {
  if (props.tab != 'student-voice') {
    return <noscript />;
  }

  return (
    <div className="ppp__section">
      <div className="ppp__section-headline">{props.name}</div>
      <Elements
        showArtifact={props.showArtifact}
        entries={props.entries}
        tab={props.tab}
        portfolio_guid={props.portfolio_guid}
      />
    </div>
  );
};

export default StudentVoice;
