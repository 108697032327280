// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';
import UserPhoto from './../../../V4/Common/UserPhoto';

const MetadataSidebar = (props) => {
  let favoriteNode = null;
  let standardsNode = null;
  let gradesNode = null;
  let subjectsNode = null;

  // set up any lesson authors
  let authorNodes = (
    <div>
      <UserPhoto
        src="/legacy-images/bl-icon-circle.png"
        diameter={80}
        className="strategy-v4__author-photo"
      />
      <div className="strategy-v4__author-name">
        Written by BetterLesson Master Teacher
      </div>
    </div>
  );

  if (props.User) {
    authorNodes = (
      <NavigationLink
        url={
          process.env.REACT_APP_TEACHING_URL +
          '/browse/master_teacher/' +
          props.User.id +
          '/' +
          props.User.seo_url +
          '?from=mtp_lesson'
        }
        className="strategy-v4__metadata-flex-section strategy-v4__metadata-flex-section--centered"
      >
        <div
          key={'author-' + props.User.id}
          className="strategy-v4__metadata-flex-item"
        >
          <UserPhoto
            src={props.User.photo}
            diameter={80}
            className="strategy-v4__author-photo"
          />
          <div className="strategy-v4__author-name">
            {props.User.first_name} {props.User.last_name}
          </div>
          <div className="strategy-v4__metadata-grey-text">
            {props.User.School.Location_City},{' '}
            {props.User.School.Location_State}
          </div>
        </div>
      </NavigationLink>
    );
  }

  const authorsNode = (
    <div className="strategy-v4__metadata-section tc__center-text">
      <div className="strategy-v4__metadata-headline">Lesson Author</div>
      {authorNodes}
    </div>
  );

  if (props.Grade) {
    gradesNode = props.Grade.map((grade) => {
      return (
        <div
          key={`lesson-grade-${grade.id}`}
          className="lesson__metadata-item strategy-v4__metadata-grey-text strategy-v4__metadata-default-text"
        >
          {grade.name}
        </div>
      );
    });
  }

  if (props.Subject) {
    subjectsNode = props.Subject.map((subject) => {
      return (
        <div
          key={`lesson-grade-${subject.id}`}
          className="lesson__metadata-item strategy-v4__metadata-grey-text strategy-v4__metadata-default-text tc__link"
        >
          <NavigationLink
            url={`${process.env.REACT_APP_TEACHING_URL}/search?subjects=${subject.id}&from=mtp_lesson`}
            className="tc__link"
          >
            {subject.name}
          </NavigationLink>
        </div>
      );
    });
  }

  if (props.CoreStandard) {
    standardsNode = (
      <div className="lesson__standards">
        <div className="strategy-v4__metadata-headline">Standards</div>
        {props.CoreStandard.map((standard) => {
          return (
            <NavigationLink
              url={
                process.env.REACT_APP_TEACHING_URL +
                '/search?standards=' +
                standard.id +
                '&from=mtp_lesson'
              }
              key={'lesson-standard-' + standard.id}
              className="search__tag"
              title={standard.description}
            >
              {standard.identifier}
            </NavigationLink>
          );
        })}
      </div>
    );
  }

  const legalNode = (
    <div className="strategy-v4__metadata-section--legal">
      <div className="strategy-v4__metadata-headline">License</div>
      <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
        Licensed under Creative Commons (
        <NavigationLink
          className="tc__link"
          url="https://creativecommons.org/licenses/by-nc/4.0/"
          target="_blank"
        >
          CC BY-NC 4.0
        </NavigationLink>
        )
      </div>
    </div>
  );

  if (parseInt(props.favorite_count) > 0) {
    favoriteNode = (
      <div className="lesson__favorite">
        <span className="lesson__favorite-icon tc-v4-heart-filled"></span>
        <span className="lesson__favorite-caption">
          {props.favorite_count} teachers like this Lesson
        </span>
      </div>
    );
  }

  return (
    <div className="lesson__metadata-sidebar cute-2-desktop cute-12-phone show-desktop hide-phone">
      {props.socialSharingNode}
      {favoriteNode}
      {authorsNode}
      <div className="strategy-v4__metadata-section">
        <div className="strategy-v4__metadata-headline">Grade Level</div>
        {gradesNode}
        <div className="strategy-v4__metadata-headline">Subjects</div>
        {subjectsNode}
        <div className="strategy-v4__metadata-headline">Time</div>
        <div className="strategy-v4__metadata-grey-text strategy-v4__metadata-default-text">
          {props.duration} Minutes
        </div>
        {standardsNode}
        {legalNode}
      </div>
    </div>
  );
};

MetadataSidebar.propTypes = {
  User: PropTypes.object,
  favorite_count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  Grade: PropTypes.array,
  Subject: PropTypes.array,
  CoreStandard: PropTypes.array,
  duration: PropTypes.number,
  socialSharingNode: PropTypes.node,
};

export default MetadataSidebar;
