// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const ReorderButtons = (props) => {
  if (props.reorderActive) {
    return (
      <div className="p-portfolio-management-vision__buttons">
        <div
          className="tc__btn tc__btn--flat-blue p-portfolio-management-vision__done-button"
          onClick={props.saveDisplayOrder}
        >
          Done Reordering
        </div>
        <div
          className="tc__btn tc__btn--flat-grey p-portfolio-management-vision__cancel-button"
          onClick={props.cancelDisplayReorder}
        >
          Cancel
        </div>
      </div>
    );
  } else {
    return (
      <div className="p-portfolio-management-vision__buttons">
        <div
          className="tc__btn tc__btn--flat-blue"
          onClick={props.toggleButtonState}
        >
          Reorder
        </div>
      </div>
    );
  }
};

ReorderButtons.propTypes = {
  saveDisplayOrder: PropTypes.func,
  cancelDisplayReorder: PropTypes.func,
  toggleButtonState: PropTypes.func,
};

export default ReorderButtons;
