// @ts-nocheck FIXME
import React from 'react';
import Auth from './../components/Auth';
import NavigationLink from './NavigationLink';
import PortfolioPrint from './PortfolioPrint';

class PortfolioPreview extends React.Component {
  state = {
    entries: this.props.entries,
  };

  render() {
    // only allow the user to edit the portfolio if if it not read-only and they are authorized
    let editNode, editTextNode;
    if (
      !this.props.portfolio.readOnly &&
      Auth.canEditData(this.props.user, this.props.teacherId)
    ) {
      editNode = (
        <NavigationLink
          className="portfolio__preview-overlay-headline-link tc__link--white"
          url={
            '/coaching/lab/teacher/' +
            this.props.portfolio.user_id +
            '/portfolio/' +
            this.props.portfolio.id
          }
        >
          <span className="tc-pencil link-icon"></span>
          Edit
        </NavigationLink>
      );
      editTextNode = (
        <div className="portfolio__print-preview-subheadline">
          To customize your portfolio, click
          <NavigationLink
            className="portfolio__preview-overlay-body-link tc__link--white"
            url={
              '/coaching/lab/teacher/' +
              this.props.portfolio.user_id +
              '/portfolio/' +
              this.props.portfolio.id
            }
          >
            Edit
          </NavigationLink>
          .
        </div>
      );
    }

    return (
      <div className="portfolio__preview-overlay-container">
        <div className="portfolio__preview-overlay">
          <div className="row">
            <div className="cute-11-desktop">
              <div className="portfolio__preview-overlay-headline">
                <span className="portfolio__preview-overlay-headline-text left">
                  {this.props.portfolio.name}
                </span>
                <div className="portfolio__preview-overlay-headline-links left">
                  {editNode}
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="portfolio__print-preview-subheadline">
                This is a preview of your portfolio, created with all of your
                data from TeachCycle.
              </div>
              {editTextNode}
            </div>
            <div className="cute-1-desktop">
              <NavigationLink
                className="portfolio__preview-overlay-close-icon"
                url={
                  '/coaching/lab/teacher/' +
                  this.props.portfolio.user_id +
                  '/portfolios?from=tp_preview'
                }
              >
                <div className="tc-x"></div>
              </NavigationLink>
            </div>
          </div>
        </div>
        <div className="portfolio__preview-overlay-print-background">
          <div className="portfolio__preview-overlay-print-container">
            <PortfolioPrint
              entries={this.state.entries}
              portfolio={this.props.portfolio}
              user={this.props.user}
              inPreviewMode={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioPreview;
