// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import GoalDisplayView from './GoalDisplayView';
import GoalEditView from './GoalEditView';

const GoalContainer = (props) => {
  // common state
  const [goalExpanded, setGoalExpanded] = React.useState(false);
  const [goalEditing, setGoalEditing] = React.useState(false);

  // since completed is a time string and not just a bool we need to convert it to a bool
  const goalCompleted = !!props.goal.completed;

  // open the complete goal modal
  const handleComplete = () => {
    if (!props.canEdit || props.loading) {
      return;
    }

    props.completeGoal();
  };

  // open the confirm uncomplete modal
  const handleUncomplete = () => {
    if (!props.canEdit || props.loading) {
      return;
    }

    props.confirmUncompleteGoal();
  };

  const handleStartEdit = (e) => {
    e.preventDefault();

    props.setIsEditing(true);
    setGoalEditing(true);
  };

  const submitGoal = (data, errorCallback) => {
    // add the goal id
    data['goalId'] = props.goal.id;
    // submit the goal updates
    const successCallback = () => {
      setGoalEditing(false);
      props.setIsEditing(false);
    };
    props.submitGoal(data, successCallback, errorCallback);
  };

  const deleteGoal = (data) => {
    // if the goal doesn't get deleted, just revert it to display view
    const errorCallback = () => setGoalEditing(false);
    props.deleteGoal(data, errorCallback);
  };

  const isCohortFocusAreaGoal = () => {
    if (!props.cohortFocusAreas || !props.cohortFocusAreas.length) {
      return true;
    }

    if (props.domains.find((domain) => domain.label === 'ALL Focus Areas')) {
      return true;
    }

    const focusAreaId = props.goal.focus_area.id;
    return props.cohortFocusAreas.includes(focusAreaId);
  };

  let editIconNode;
  if (props.canEdit && !props.isEditing && isCohortFocusAreaGoal()) {
    editIconNode = (
      <div
        className="workspace__action-icon tc-v4-pencil"
        title="Edit"
        onClick={handleStartEdit}
      ></div>
    );
  }

  return (
    <React.Fragment>
      {goalEditing ? (
        <GoalEditView
          {...props.goal}
          existingSubFocusAreas={props.existingSubFocusAreas}
          goalEditing={true}
          cancelGoalEditing={() => {
            setGoalEditing(false);
            props.setIsEditing(false);
          }}
          deleteGoal={deleteGoal}
          subFocusAreas={props.subFocusAreas}
          cohortFocusAreas={props.cohortFocusAreas}
          submitGoal={submitGoal}
          canEdit={props.canEdit}
          domains={props.domains}
        />
      ) : (
        <GoalDisplayView
          {...props.goal}
          goalCompleted={goalCompleted}
          toggleGoalCompleted={
            goalCompleted ? handleUncomplete : handleComplete
          }
          entriesCount={props.entriesCount}
          changeFilter={props.changeFilter}
          isFiltering={props.isFiltering}
          goalExpanded={goalExpanded}
          canHighlight={props.canHighlight}
          canEdit={props.canEdit}
          setGoalExpanded={setGoalExpanded}
          pageUser={props.pageUser}
          showCompleteGoalIcon={isCohortFocusAreaGoal()}
        >
          <div>
            <div className="workspace__action-icons-row">
              <span className="workspace__action-icon workspace__action-icon--hidden"></span>
              {/* {highlightIconNode} */}
              {editIconNode}
            </div>
          </div>
        </GoalDisplayView>
      )}
    </React.Fragment>
  );
};

GoalContainer.propTypes = {
  goal: PropTypes.object.isRequired,
  existingSubFocusAreas: PropTypes.array,
  submitGoal: PropTypes.func.isRequired,
  completeGoal: PropTypes.func.isRequired,
  confirmUncompleteGoal: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

export default GoalContainer;
