// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import FreezeWindowScroll from './../../Common/FreezeWindowScroll';
import Spinner from './../../Common/Spinner';

const AddExistingStrategyModal = (props) => {
  let strategyId;

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO: validate strategy id?
    props.submit({ strategyId: strategyId.value });
  };

  // Handle errors
  // let noteErrorClass = "";
  // let noteErrorNode = null;
  // if (props.errors && props.errors.note) {
  //     noteErrorClass = " error";
  //     noteErrorNode =  (
  //         <div className="tc__error">
  //             {props.errors.note}
  //         </div>
  //     );
  // }

  // disable buttons when submitting
  let disabledClass = '';
  if (props.loading) {
    disabledClass = ' disabled';
  }

  return (
    <div>
      <div
        onClick={handleCloseModal}
        className="modal__page-overlay modal__page-overlay--grey ppd-v3-curation-modal__page-overlay"
      ></div>
      <div className="clip-modal modal--grey-overlay admin__modal">
        <form className="ppd-v3-curation-modal__body">
          <div className="modal__close" onClick={handleCloseModal}>
            <div className="tc-x"></div>
          </div>

          <h3 className="tc__modal-add-headline tc__headline-2">
            Add Existing Strategy
          </h3>

          <div className="admin__form-row">
            <label className="admin__form-label admin__form-label--inline">
              Strategy ID
            </label>
            <input
              type="number"
              ref={(ref) => (strategyId = ref)}
              className="tc__input"
            />
          </div>

          <div className="admin__form-row admin__form-row--centered">
            <button
              className={'bl4__btn bl4__btn--blue' + disabledClass}
              onClick={handleSubmit}
            >
              <Spinner loading={props.loading} />
              Add
            </button>
          </div>
        </form>
      </div>

      <FreezeWindowScroll parentModal="clip-modal" />
    </div>
  );
};

AddExistingStrategyModal.propTypes = {
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default AddExistingStrategyModal;
