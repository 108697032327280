// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import ErrorText from '@legacy-components/react/V5/Common/ErrorText';
import FormField from '@legacy-components/react/V5/Common/FormField';
import MultiSelect from '@legacy-components/react/V5/Common/MultiSelect/MultiSelectContainer';
import TimePicker from '@legacy-components/react/V5/Common/TimePicker';
import Tooltip from '@legacy-components/react/V5/Common/Tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import Utils from '../../../../../components/DynamicUtils';

const SessionCapsule = (props) => {
  const lab = useLegacyLabContext();

  const currTime = props.cancellation_time
    ? moment(props.cancellation_time_formatted)
    : moment();
  const [canceledTime, setCanceledTime] = React.useState(null);
  const [canceledDate, setCanceledDate] = React.useState(
    currTime.tz(props.timezone.pytz_timezone).format('YYYY-MM-DD')
  );

  const getCancelDateTime = () => {
    if (!canceledTime || !canceledDate) {
      props.update({
        cancelledTime: null,
      });
      return;
    }
    const combinedTime = moment(`${canceledDate} ${canceledTime}`);
    return combinedTime;
  };

  const handleSetStatus = (status, statusChanged) => {
    props.update({
      status: status,
      statusChanged: statusChanged,
      cancelledTime: getCancelDateTime(),
    });
  };

  const ref = React.useRef();

  React.useEffect(() => {
    if (props.scrollTo === `session-${props.index}`) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  React.useEffect(() => {
    props.update({
      cancelledTime: getCancelDateTime(),
    });
  }, [canceledDate, canceledTime]);

  const handleSetCancelTime = (time) => {
    if (!time || time === null) {
      setCanceledTime(null);
      return;
    }

    setCanceledTime(time.format('HH:mm'));
  };

  const updateProductCode = (selectedOption) => {
    // if we are selecting a product code, pass its info up and clear the materials links
    if (!props.ld_product_id && selectedOption) {
      props.update({
        ld_product_id: selectedOption.id,
        materials_finalized: null,
        modifications_finalized: null,
        agenda_link: null,
        slide_deck_link: null,
      });
    }
    // otherwise, if we are removing the selected product code, clear out some session data
    else {
      props.update({
        ld_product_id: null,
        materials_finalized: null,
        modifications_finalized: null,
        agenda_link: null,
        slide_deck_link: null,
      });
    }
  };

  const handleCheckNotDelivered = (e) => {
    e.preventDefault();
    props.confirmUndeliverSession({ session_id: props.id });
  };

  let capsuleClass = props.greyBackground
    ? 'edit-event__session-capsule--slate'
    : '';
  // highlight the capsule when we create a new session
  if (!props.id) {
    capsuleClass += ' edit-event__session-capsule--border-pulse';
  }

  // if there is only one session, we hide the heading row and the Name input
  let headingNode;
  let nameNode;
  if (!props.singleSession) {
    // if this session has any attendees, show the # and disable the delete icon
    let attendeeCountNode;
    if (props.attendee_count && props.attendee_count > 0) {
      attendeeCountNode = (
        <div className="edit-event__attendee-count">
          {props.attendee_count} attendee{props.attendee_count > 1 ? 's' : ''}
        </div>
      );
    }
    // set up the delete icon, and hide/disable it if necessary
    let deleteIconNode;
    if (props.canEditEventDetails) {
      deleteIconNode = (
        <div
          data-testid={`session-delete-icon-${props.index}`}
          onClick={props.delete}
          className="tc-delete edit-event__delete-icon v5__link"
        ></div>
      );
      if (attendeeCountNode) {
        deleteIconNode = (
          <Tooltip
            tipClass="v5__tooltip--wide"
            tooltip="This session cannot be deleted because it has attendees."
          >
            <div
              data-testid={`session-delete-icon-${props.index}`}
              onClick={props.delete}
              className="tc-delete edit-event__delete-icon v5__link disabled"
            ></div>
          </Tooltip>
        );
      }
    }
    // set up the row with the session name
    headingNode = (
      <div className="edit-event__session-capsule-headline-row v5__heading-5">
        <div className="tc-v5-book edit-event__row-start-icon"></div>
        <div className="edit-event__session-capsule-headline-text">
          {props.name}
        </div>
        {attendeeCountNode}
        {deleteIconNode}
      </div>
    );
    // if the current user can't edit the name,
    // they can just see the name in the capsule heading, so we can hide this input
    nameNode = props.canEditEventDetails && (
      <div className="edit-event__session-capsule-form-group name">
        <label className="edit-event__session-capsule-label">Name</label>
        <FormField
          type="text"
          maxLength="200"
          value={props.name}
          isControlled={true}
          onChange={(e) => props.update({ name: e.target.value })}
        />
      </div>
    );
  }

  // set up the product code input, or just show the info if the user cannot edit it
  const productCodeEmptyNode = (
    <div className="v5__body-copy italic grey-out edit-event__fixed-value">
      No product code set
    </div>
  );
  const productCode = props.ld_product_id
    ? props.allProductCodes[props.ld_product_id]
    : null;
  let productCodeInputNode = productCode ? (
    <div className="edit-event__fixed-value">
      {productCode.product_code}: {productCode.name}
    </div>
  ) : (
    productCodeEmptyNode
  );
  if (props.canEditEventDetails) {
    // format the data to fit in the multiselect
    const selectedOption = productCode
      ? {
          id: productCode.id,
          label: `${productCode.product_code}: ${productCode.name}`,
        }
      : null;
    const dropdownOptions = Object.values(props.allProductCodes).map((code) => {
      return {
        id: code.id,
        label: `${code.product_code}: ${code.name}`,
      };
    });

    // the product code becomes uneditable once any session with the same num has either
    // facilitators assigned OR modifications done to the session materials
    const hasFacilitatorsOrMods = !!(
      props.matchingSessionHasFacilitatorsOrMods ||
      props.modifications_finalized ||
      (props.facilitators &&
        props.facilitators.some((facilitator) => !facilitator.deleted))
    );

    productCodeInputNode = (
      <MultiSelect
        uId={`session-${props.id}-${props.num}-product-code`}
        handleSelectOption={updateProductCode}
        selectedOptions={selectedOption ? [selectedOption] : []}
        dropdownOptions={dropdownOptions}
        emptyStateNode={productCodeEmptyNode}
        minCharCountForResults={0}
        stayOpenOnSelect={false}
        maximumSelectedOptionsCount={1}
        isSelectSearch={true}
        preventAutoFocus={true}
        disabled={hasFacilitatorsOrMods}
      />
    );
  }

  // set up any error messsages
  let dateErrorClass = '';
  let dateErrorNode;
  if (props.errors && props.errors.date) {
    dateErrorClass = 'error';
    dateErrorNode = (
      <ErrorText
        errorContainerClass="v5__error-container--no-padding"
        error={props.errors.date}
      />
    );
  }
  let startTimeErrorClass = '';
  let endTimeErrorClass = '';
  let cancelledTimeErrorClass = '';
  let startTimeErrorNode;
  let endTimeErrorNode;
  let cancelledTimeErrorNode;
  let statusNoteErrorText;
  if (props.errors && props.errors.startTime) {
    startTimeErrorClass = 'error';
    endTimeErrorClass = 'error';
    startTimeErrorNode = (
      <ErrorText
        errorContainerClass="v5__error-container--no-padding"
        error={props.errors.startTime}
      />
    );
  }

  if (props.errors && props.errors.endTime) {
    endTimeErrorClass = 'error';
    startTimeErrorNode = (
      <ErrorText
        errorContainerClass="v5__error-container--no-padding"
        error={props.errors.endTime}
      />
    );
  }

  if (props.errors && props.errors.cancelTime) {
    cancelledTimeErrorClass = 'error';
    cancelledTimeErrorNode = (
      <ErrorText
        errorContainerClass="v5__error-container--no-padding"
        error={props.errors.cancelTime}
      />
    );
  }

  if (props.errors && props.errors.statusNote) {
    statusNoteErrorText = props.errors.statusNote;
  }

  // set up the date/times input, or just show the info if the user cannot edit them
  let dateInputNode = props.date ? (
    <div className="edit-event__fixed-value">
      {props.date.format('YYYY-M-D')}
    </div>
  ) : (
    <div className="v5__body-copy italic grey-out edit-event__fixed-value">
      TBD
    </div>
  );
  const minDate = moment().tz(props.timezone.pytz_timezone);
  let enabledDates = [
    (date) => {
      return moment(date).isSameOrAfter(minDate, 'day');
    },
  ];
  if (props.startTime <= moment()) {
    enabledDates = [
      (date) => {
        const checkDate = moment(date).tz(props.timezone.pytz_timezone);
        const startDate = props.date;
        return (
          checkDate.isSameOrAfter(minDate, 'day') ||
          checkDate.isSame(startDate, 'day')
        );
      },
    ];
  }
  if (props.canEditEventDetails) {
    const dateOptions = {
      altInput: true,
      altFormat: 'm/d/Y',
      dateFormat: 'Y-m-d',
      enable: enabledDates,
    };
    dateInputNode = (
      <Flatpickr
        key={props.errors && props.errors.date}
        className={`v5__input ${dateErrorClass}`}
        onChange={(dates) => {
          return props.update({
            date: dates.length ? moment(dates[0]) : null,
            changed: true,
          });
        }}
        value={props.date && props.date.format('YYYY-MM-DD')}
        options={dateOptions}
      />
    );
  }

  let startTimeInputNode = props.startTime ? (
    <div className="edit-event__fixed-value">
      {props.startTime.format('h:mm A')}
    </div>
  ) : (
    <div className="edit-event__fixed-value empty">TBD</div>
  );
  let endTimeInputNode = props.endTime ? (
    <div className="edit-event__fixed-value">
      {props.endTime.format('h:mm A')}
    </div>
  ) : (
    <div className="edit-event__fixed-value empty">TBD</div>
  );
  // we show Delivered status with different color if a session has been marked as not delivered
  const sessionStatusColorClass =
    props.retroactively_not_delivered || props.RetroActivelyNotDelivered
      ? 'Not-Delivered'
      : props.status.replace(' ', '-');
  let statusInputNode = (
    <div className={`edit-event__status-${sessionStatusColorClass}`}>
      <div
        className={`edit-event__status-${sessionStatusColorClass} no-select`}
      >
        {props.status}
      </div>
    </div>
  );
  let advancedActionsNode;
  if (props.canEditEventDetails) {
    if (Utils.isAdmin.call(lab) || Utils.isServicesManager.call(lab)) {
      statusInputNode = (
        <div
          className={`clickable edit-event__status-${sessionStatusColorClass}`}
        >
          <select
            className="clickable"
            value={props.status}
            onChange={(e) => handleSetStatus(e.target.value, true)}
          >
            <option value="Scheduled">Scheduled</option>
            <option value="Partner cancelled">Partner Cancelled</option>
            <option value="No show">No Show</option>
            <option value="BL cancelled">BL Cancelled</option>
          </select>
          <div
            className="tc-x clickable"
            onClick={() => handleSetStatus('Scheduled', false)}
          />
        </div>
      );
    }
    startTimeInputNode = (
      <TimePicker
        className={startTimeErrorClass}
        onChange={(time) =>
          props.update({
            startTime: time,
            changed: true,
          })
        }
        value={props.startTime}
        clearMargin={true}
      />
    );
    endTimeInputNode = (
      <TimePicker
        className={endTimeErrorClass}
        onChange={(time) => props.update({ endTime: time })}
        value={props.endTime}
        clearMargin={true}
      />
    );
  }
  if (props.status !== 'Scheduled') {
    let changeTimesNode;
    let changeNotesNode;
    let asteriskNode;
    if (!props.statusChanged) {
      statusInputNode = (
        <div
          className={`edit-event__status-${sessionStatusColorClass} no-select`}
        >
          {props.status}
        </div>
      );
      changeTimesNode = (
        <React.Fragment>
          <div className={'flatpickr-container'}>
            {currTime.format('M/DD/YY')}
          </div>
          <div
            className={
              'participant-v5__meeting-time-margin edit-event__cancel-time-display'
            }
          >
            {currTime.format('hh:mm A')}
          </div>
        </React.Fragment>
      );
      changeNotesNode = <div>{props.status_note}</div>;
    } else {
      asteriskNode = <span className="v5__error">*</span>;
      changeTimesNode = (
        <React.Fragment>
          <div className={'flatpickr-container'}>
            <Flatpickr
              className={`v5__input ${cancelledTimeErrorClass}`}
              onChange={(selectedDates) =>
                setCanceledDate(moment(selectedDates[0]).format('YYYY-MM-DD'))
              }
              value={canceledDate}
            />
            {cancelledTimeErrorNode}
          </div>
          <div className={'participant-v5__meeting-time-margin'}>
            <TimePicker
              startEmpty={true}
              className={`${cancelledTimeErrorClass}`}
              onChange={(time) => handleSetCancelTime(time)}
              clearMargin={true}
            />
            {cancelledTimeErrorNode}
          </div>
        </React.Fragment>
      );
      changeNotesNode = (
        <FormField
          type="textarea"
          errorContainerClass="edit-event__cancel-error-container"
          error={statusNoteErrorText}
          value={props.status_note}
          rows="2"
          onChange={(e) =>
            props.update({
              status_note: e.target.value,
            })
          }
        />
      );
    }
    if (props.status !== 'Delivered') {
      advancedActionsNode = (
        <React.Fragment>
          <div className="cancel-time">
            <label className="edit-event__session-capsule-label">
              Requested Change{asteriskNode}
            </label>
            <div className="edit-event__cancel-time-container">
              {changeTimesNode}
            </div>
          </div>
          <div className="cancel-reason">
            <label className="edit-event__session-capsule-label">
              Status Notes{asteriskNode}
            </label>
            {changeNotesNode}
          </div>
        </React.Fragment>
      );
    } else if (
      props.status === 'Delivered' &&
      !props.statusChanged &&
      props.confirmUndeliverSession &&
      props.canEditEventDetails
    ) {
      // shows the checkbox to mark as session as not delivered only when
      // session status is delivered and is saved, and it has the function (not passed in in basic mode with groups)
      const checkboxDisabled = !!(
        props.retroactively_not_delivered || props.RetroActivelyNotDelivered
      );
      const checkboxLabelClass = checkboxDisabled ? ' disabled' : '';
      const failureToDeliverNode = (
        <div className="edit-event__session-capsule-checkbox">
          <input
            type="checkbox"
            id={`failure-to-deliver-checkbox-${props.id}`}
            checked={checkboxDisabled}
            disabled={checkboxDisabled}
            onChange={handleCheckNotDelivered}
            className="v5__checkbox edit-event__session-capsule-facilitators-checkbox"
          />
          <label
            className={`edit-event__session-capsule-facilitators-checkbox-label${checkboxLabelClass}`}
            htmlFor={`failure-to-deliver-checkbox-${props.id}`}
          >
            Mark as Not Delivered
          </label>
        </div>
      );
      advancedActionsNode = (
        <div className="cancel-time">{failureToDeliverNode}</div>
      );
    }
  }

  return (
    <div className={`edit-event__session-capsule ${capsuleClass}`} ref={ref}>
      {headingNode}

      <div className="edit-event__session-capsule-form v5__body-copy">
        <div className="edit-event__session-capsule-form-group productCode">
          <label className="edit-event__session-capsule-label">
            Product Code
          </label>
          <div className="form-field">{productCodeInputNode}</div>
        </div>

        {nameNode}

        <div className="edit-event__session-capsule-form-group date">
          <label className="edit-event__session-capsule-label">Date</label>
          <div className="form-field">
            {dateInputNode}
            {dateErrorNode}
          </div>
        </div>

        <div className="edit-event__session-capsule-form-group startTime">
          <label className="edit-event__session-capsule-label">
            Start Time ({props.timezone.name})
          </label>
          <div className="form-field">
            {startTimeInputNode}
            {startTimeErrorNode}
          </div>
        </div>

        <div className="edit-event__session-capsule-form-group endTime">
          <label className="edit-event__session-capsule-label">
            End Time ({props.timezone.name})
          </label>
          <div className="form-field">
            {endTimeInputNode}
            {endTimeErrorNode}
          </div>
        </div>

        <div className="edit-event__session-capsule-form-group status">
          <label className="edit-event__session-capsule-label">Status</label>
          <div className="form-field">{statusInputNode}</div>
        </div>
        {advancedActionsNode}
      </div>
    </div>
  );
};

SessionCapsule.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
  num: PropTypes.number,
  facilitators: PropTypes.array,
  matchingSessionHasFacilitatorsOrMods: PropTypes.bool,
  ld_product_id: PropTypes.number,
  allProductCodes: PropTypes.object.isRequired,
  name: PropTypes.string,
  date: PropTypes.any,
  startTime: PropTypes.any,
  endTime: PropTypes.any,
  timezone: PropTypes.object.isRequired,
  errors: PropTypes.object,
  singleSession: PropTypes.bool,
  modifications_finalized: PropTypes.string,
  canEditEventDetails: PropTypes.bool,
  update: PropTypes.func.isRequired,
  delete: PropTypes.func,
  attendee_count: PropTypes.number,
  greyBackground: PropTypes.bool,
  scrollTo: PropTypes.string,
  confirmUndeliverSession: PropTypes.func,
};

export default SessionCapsule;
