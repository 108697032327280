// @ts-nocheck FIXME
import ParticipantPortfolioApi from './../../apis/ParticipantPortfolioApi';
import UserApi from './../../apis/UserApi';
import Analytics from './../../components/Analytics';
import Utils from './../../components/DynamicUtils';
import Modal from './../../components/Modal';
import Store from './../../components/Store';
import HeaderStore from './../HeaderStore';

const store = {
  hiddenEntryIds: [],
  displayOrder: [],
  workingCopy: [],

  visionVisibility: null,
  designSummaryVisibility: null,
  participantId: null,
  portfolioId: null,
  reorderActive: false,
  itemType: null,

  ui: {
    uploadMessage: {},
    uploadLoading: {
      status: null,
      message: null,
    },
    move: {},
    alert: {},
    shareModal: {
      urlCopied: false,
      callbacks: {
        onOpen: (data) => {
          Analytics.logEvent({
            category: 'share_modal',
            action: 'open',
            label: 'from_ppd3_participant_portfolio_manage_UI',
          });
        },
        onClose: () => {
          Analytics.logEvent({
            category: 'share_modal',
            action: 'close',
            label: 'from_ppd3_participant_portfolio_manage_UI',
          });
        },
      },
    },
  },
};

/****** PRIVATE FUNCTIONS ******/

const setErrorAlert = (message) => {
  store.ui.alert = {
    type: 'error',
    message: message,
    handleClose: store.clearAlert,
  };
};

/****** EXTERNAL FUNCTIONS ******/

store.init = (data) => {
  store.participant = data.participant;
  store.user = data.user;
  store.participantId = data.participantId;
  store.portfolioId = data.portfolio.id;
  store.visionVisibility = data.portfolio.is_vision_visible;
  store.designSummaryVisibility = data.portfolio.is_design_summary_visible;
  store.participantId = data.participant.id;
  store.hiddenEntryIds = data.hidden_entry_ids;
  store.displayOrder = Utils.clone(data.items_to_display);
  store.workingCopy = Utils.clone(store.displayOrder);
  store.reorderActive = false;
  store.itemType = data.item_type;

  store.ui.alert = {};

  // Init modals
  store.ui.shareModal = Modal(store, store.ui.shareModal);
  return false;
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.setShareUrlCopied = (urlCopied) => {
  store.ui.shareModal.urlCopied = urlCopied;
};

store.toggleOutcomeVisibility = (participantOutcomeId) => {
  const index = store.hiddenEntryIds.indexOf(participantOutcomeId);
  const visible = index === -1;

  // trigger event
  Analytics.logEvent({
    category: 'outcome_visibility',
    action: visible ? 'off' : 'on',
    label: 'from_ppd3_participant_portfolio_manage_UI',
  });

  return ParticipantPortfolioApi.setParticipantOutcomeVisibility({
    participantId: store.participantId,
    participantOutcomeId: participantOutcomeId,
    visible: !visible,
    portfolioId: store.portfolioId,
  })
    .then((response) => {
      // if was visible, no longer visible
      if (visible) {
        store.hiddenEntryIds.push(participantOutcomeId);
      } else {
        store.hiddenEntryIds.splice(index, 1);
      }
    })
    .catch((response) => {
      setErrorAlert('An error occurred. Please try again later');
    });
};

store.toggleVisionVisibility = () => {
  const visible = store.visionVisibility;

  // trigger event
  Analytics.logEvent({
    category: 'vision_visibility',
    action: visible ? 'off' : 'on',
    label: 'from_ppd3_participant_portfolio_manage_vision_UI',
  });

  return ParticipantPortfolioApi.setParticipantVisionVisibility({
    participantId: store.participantId,
    portfolioId: store.portfolioId,
    visible: !visible,
  })
    .then((response) => {
      // if was visible, no longer visible
      if (visible) {
        store.visionVisibility = false;
      } else {
        store.visionVisibility = true;
      }
    })
    .catch((response) => {
      setErrorAlert('An error occurred. Please try again later');
    });
};

store.toggleDesignSummaryVisibility = () => {
  const visible = store.designSummaryVisibility;

  // trigger event
  Analytics.logEvent({
    category: 'design_summary_visibility',
    action: visible ? 'off' : 'on',
    label: 'from_ppd3_participant_portfolio_manage_vision_UI',
  });

  return ParticipantPortfolioApi.setParticipantDesignSummaryVisibility({
    participantId: store.participantId,
    portfolioId: store.portfolioId,
    visible: !visible,
  })
    .then((response) => {
      // if was visible, no longer visible
      if (visible) {
        store.designSummaryVisibility = false;
      } else {
        store.designSummaryVisibility = true;
      }
    })
    .catch((response) => {
      setErrorAlert('An error occurred. Please try again later');
    });
};

store.toggleEntryVisibility = (entryId) => {
  const index = store.hiddenEntryIds.indexOf(entryId);
  const visible = index === -1;
  const action = null;
  let category = null;
  let label = null;
  if (store.itemType == 'VISION_ARTIFACT') {
    category = 'vision_artifact';
    label = 'from_ppd3_participant_portfolio_manage_vision_UI';
  } else {
    category = 'outcome_entry';
    label = 'from_ppd3_participant_portfolio_manage_outcome_UI';
  }
  // trigger event
  Analytics.logEvent({
    category: category,
    action: visible ? 'visibility_off' : 'visibility_on',
    label: label,
  });

  return ParticipantPortfolioApi.setParticipantEntryVisibility({
    participantId: store.participantId,
    entryId: entryId,
    entryType: store.itemType,
    visible: !visible,
    portfolioId: store.portfolioId,
  })
    .then((response) => {
      // if was visible, no longer visible
      if (visible) {
        store.hiddenEntryIds.push(entryId);
      } else {
        store.hiddenEntryIds.splice(index, 1);
      }
    })
    .catch((response) => {
      setErrorAlert('An error occurred. Please try again later');
    });
};

store.toggleButtonState = () => {
  // buttons are arrows if reordering, checks if not
  const active = store.reorderActive;
  let category = null;
  let label = null;
  if (store.itemType == 'VISION_ARTIFACT') {
    category = 'vision_artifact';
    label = 'from_ppd3_participant_portfolio_manage_vision_UI';
  } else {
    category = 'outcome_entry';
    label = 'from_ppd3_participant_portfolio_manage_outcome_UI';
  }
  // trigger event
  Analytics.logEvent({
    category: category,
    action: active ? 'reordering_off' : 'reordering_on',
    label: label,
  });

  if (active) {
    store.reorderActive = false;
  } else {
    store.reorderActive = true;
  }
};

store.swapEntryUp = (entryId) => {
  const entryIdOrder = store.displayOrder.map((ent) => ent.id);
  const index = entryIdOrder.indexOf(entryId);

  let category = null;
  let label = null;
  if (store.itemType == 'VISION_ARTIFACT') {
    category = 'vision_artifact';
    label = 'from_ppd3_participant_portfolio_manage_vision_UI';
  } else {
    category = 'outcome_entry';
    label = 'from_ppd3_participant_portfolio_manage_outcome_UI';
  }
  // trigger event
  Analytics.logEvent({
    category: category,
    action: 'move_up',
    label: label,
  });

  // check that it is not the first entry displayed
  if (index !== 0) {
    // set the state for the animations
    const current = Utils.clone(store.displayOrder[index]);
    const prev = Utils.clone(store.displayOrder[index - 1]);

    // swap the entry clicked on with the one above it
    store.ui.move.target = current;
    store.ui.move.destination = prev;
    store.ui.move.direction = 'up';
  }
};

store.swapEntryDown = (entryId) => {
  const entryIdOrder = store.displayOrder.map((ent) => ent.id);
  const index = entryIdOrder.indexOf(entryId);
  let category = null;
  let label = null;
  if (store.itemType == 'VISION_ARTIFACT') {
    category = 'vision_artifact';
    label = 'from_ppd3_participant_portfolio_manage_vision_UI';
  } else {
    category = 'outcome_entry';
    label = 'from_ppd3_participant_portfolio_manage_outcome_UI';
  }
  // trigger event
  Analytics.logEvent({
    category: category,
    action: 'move_down',
    label: label,
  });

  // check that it is not the last entry displayed
  if (index < entryIdOrder.length - 1) {
    // swap entry clicked on with the one below it
    const current = Utils.clone(store.displayOrder[index]);
    const next = Utils.clone(store.displayOrder[index + 1]);

    // set the state for the animations
    store.ui.move.target = current;
    store.ui.move.destination = next;
    store.ui.move.direction = 'down';
  }
};

store.animateElemPositions = (data) => {
  store.ui.move.targetMoveTo = data.targetMoveTo;
  store.ui.move.destinationMoveTo = data.destinationMoveTo;
};

store.completeMove = () => {
  if (Utils.isEmpty(store.ui.move)) {
    return;
  }

  const entryIdOrder = store.displayOrder.map((ent) => ent.id);
  const index = entryIdOrder.indexOf(store.ui.move.target.id);

  const targetAdjustment =
    store.ui.move.direction == 'down' ? index + 1 : index - 1;

  // acitually move the element
  store.displayOrder[index] = store.ui.move.destination;
  store.displayOrder[targetAdjustment] = store.ui.move.target;

  // clear store
  store.ui.move = {};
};

store.saveDisplayOrder = () => {
  const idOrder = store.displayOrder.map((item) => item.id);
  let category = null;
  let label = null;
  if (store.itemType == 'VISION_ARTIFACT') {
    category = 'vision_artifact';
    label = 'from_ppd3_participant_portfolio_manage_vision_UI';
  } else {
    category = 'outcome_entry';
    label = 'from_ppd3_participant_portfolio_manage_outcome_UI';
  }
  // trigger event
  Analytics.logEvent({
    category: category,
    action: 'save_reordering',
    label: label,
  });

  return ParticipantPortfolioApi.setDisplayOrder({
    idOrder: idOrder,
    portfolioId: store.portfolioId,
    participantId: store.participantId,
    entryType: store.itemType,
  })
    .then((response) => {
      store.toggleButtonState();
      // update working copy with saved order
      store.workingCopy = Utils.clone(store.displayOrder);
    })
    .catch((response) => {
      setErrorAlert('An error occurred. Please try again later');
    });
};

store.cancelDisplayReorder = () => {
  let category = null;
  let label = null;
  if (store.itemType == 'VISION_ARTIFACT') {
    category = 'vision_artifact';
    label = 'from_ppd3_participant_portfolio_manage_vision_UI';
  } else {
    category = 'outcome_entry';
    label = 'from_ppd3_participant_portfolio_manage_outcome_UI';
  }
  // trigger event
  Analytics.logEvent({
    category: category,
    action: 'cancel_reordering',
    label: label,
  });
  store.toggleButtonState();
  // revert displayed order to last saved order
  store.displayOrder = Utils.clone(store.workingCopy);
};

/****** User Image Uploading ******/

store.updateUserPhoto = (data) => {
  // update the database with the new image
  return UserApi.updateUserImage(data)
    .then((response) => {
      store.participant.user.photo = response.file;
      store.uploadMessage = {};
      store.ui.uploadLoading.status = null;
      store.ui.uploadLoading.message = null;
      // update the header image if the user uploading the image is the current logged in user
      if (store.participant.user.id == store.user.id) {
        HeaderStore.actionDispatch('updateProfileImage', {
          photo: response.file,
        });
      }
    })
    .catch((response) => {
      store.setUploadMessage({
        type: 'error',
        message:
          'Sorry, there was a problem uploading your image. Please try again later.',
      });
    });
};

store.updateProfileImageUploadProgress = (fileData) => {
  store.ui.uploadLoading.status = fileData.status;
  store.ui.uploadLoading.message = fileData.message;
};

store.setUploadMessage = (data) => {
  store.ui.uploadMessage.type = data.type;
  store.ui.uploadMessage.message = data.message;
};
store.clearUploadMessage = () => {
  store.ui.uploadMessage = {};
};

export default Store('ParticipantPorfolioManagementStore', store);
