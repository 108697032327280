// @ts-nocheck FIXME
import moment from 'moment-timezone';
import PartnerApi from './../../apis/PartnerApi';
import Modal from './../../components/Modal';
import Utils from './../../components/StaticUtils';
import StorageHelper from './../../components/StorageHelper';
import Store from './../../components/Store';

// convenience vars
const defaultUi = {
  psmModal: {
    callbacks: {
      onOpen: (data) => {
        store.ui.psms.selectedOptions = data.psms;
      },
      onClose: () => {
        store.ui.psms.selectedOptions = [];
      },
    },
  },
  dpModal: {
    callbacks: {
      onOpen: (data) => {
        store.ui.dps.selectedOptions = data.dps;
      },
      onClose: () => {
        store.ui.dps.selectedOptions = [];
      },
    },
  },
  partnerLeaderSurveyModal: {
    callbacks: {
      onOpen: (data) => {
        store.ui.surveyPartner = data;
      },
      onClose: () => {
        store.ui.surveyPartner = {};
      },
    },
  },
  psms: {
    inputStatus: 'blur',
    charCount: 0,
    options: [],
    selectedOptions: [],
    visibleOptions: [],
  },
  dps: {
    inputStatus: 'blur',
    charCount: 0,
    options: [],
    selectedOptions: [],
    visibleOptions: [],
  },
  surveyPartner: {},
  surveySentLoading: false,
  alert: {},
};

const store = {
  partners: [],
  status: 'Current',
  ui: Object.assign({ alert: {} }, defaultUi),
  sortColumn: 'partnerName',
  sortDirection: 'ascending',
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  // don't trigger event
  store.partners = data.partners || [];
  store.ui.psms.options = data.psms || [];
  store.ui.dps.options = data.dps || [];

  // Init the modals
  store.ui.psmModal = Modal(store, store.ui.psmModal);
  store.ui.dpModal = Modal(store, store.ui.dpModal);
  store.ui.partnerLeaderSurveyModal = Modal(
    store,
    store.ui.partnerLeaderSurveyModal
  );

  let localSortState;

  const defaultSortState = { column: 'partnerName', direction: 'ascending' };

  localSortState = StorageHelper.init('partnersSortState', defaultSortState);

  store.sortColumn = localSortState.column;
  store.sortDirection = localSortState.direction;

  //defaults to name so don't need to sort if sort column is name
  sortPartners(localSortState.column);

  if (localSortState.direction === 'descending') {
    store.partners.reverse();
  }

  return false;
};

store.setStatus = (value) => {
  store.status = value;
  // Resort based on the new status
  sortPartners(store.sortColumn);
  if (store.sortDirection === 'descending') {
    store.partners.reverse();
  }
};

store.clearAlert = () => {
  store.ui.alert = {};
};

// clear the errors when input is focused on
store.clearErrorsOnFocus = (data) => {
  if (data.field in store.ui[data.feature].errors) {
    delete store.ui[data.feature].errors[data.field];
    return true;
  }

  return false;
};

store.updateSelect = (data) => {
  Object.assign(store.ui[data.field], data.data);
};

store.getVisibleOptions = (data) => {
  const field = store.ui[data.field];
  field.charCount = data.input.length;

  // only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    return option.label.toLowerCase().indexOf(data.input.toLowerCase()) !== -1;
  });
};

store.editPSMs = (data) => {
  const submitData = {
    partner_id: data.data.id,
    psms: store.ui.psms.selectedOptions,
  };
  return PartnerApi.editPSMs(submitData)
    .then(function (response) {
      const partnerIndex = Utils.getItemIndexFromArrayOfObjects(
        store.partners,
        'id',
        data.data.id
      );
      // update the table
      store.partners[partnerIndex].psms = response.psms;
      store.ui.psmModal.close();
    })
    .catch(function (response) {
      // Show the error
      store.ui.psmModal.setFieldError({
        field: 'note',
        error:
          response && response.error
            ? response.error
            : 'An error occurred. Please try again later',
      });
    });
};

store.editDPs = (data) => {
  const submitData = {
    partner_id: data.data.id,
    dps: store.ui.dps.selectedOptions,
  };
  return PartnerApi.editDPs(submitData)
    .then(function (response) {
      const partnerIndex = Utils.getItemIndexFromArrayOfObjects(
        store.partners,
        'id',
        data.data.id
      );
      // update the table
      store.partners[partnerIndex].dps = response.dps;
      store.ui.dpModal.close();
    })
    .catch(function (response) {
      // Show the error
      store.ui.dpModal.setFieldError({
        field: 'note',
        error:
          response && response.error
            ? response.error
            : 'An error occurred. Please try again later',
      });
    });
};

const sortPartners = (column) => {
  if (column === 'partnerName') {
    //sort alphabetically
    store.partners.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  } else if (column === 'cohortCount') {
    store.partners.sort((a, b) => {
      return b.cohort_count[store.status] - a.cohort_count[store.status];
    });
  } else if (column === 'eventCount') {
    store.partners.sort((a, b) => {
      return b.event_count[store.status] - a.event_count[store.status];
    });
  } else if (column === 'status') {
    store.partners.sort((a, b) => (a.status < b.status ? -1 : 1));
  } else if (column === 'surveySent') {
    store.partners.sort((a, b) =>
      surveySent(a).isAfter(surveySent(b)) ? -1 : 1
    );
  }
};

const findFirstSent = (acc, lead) => {
  if (lead.survey_email_sent && moment(lead.survey_email_sent).isBefore(acc)) {
    return moment(lead.survey_email_sent);
  } else {
    return acc;
  }
};

const filterInvalidCohorts = (partner) => {
  return partner.cohorts.filter((cohort) => {
    if (
      !cohort.term_start ||
      !cohort.term_end ||
      moment().isBefore(cohort.term_start) ||
      moment().isAfter(cohort.term_end)
    ) {
      return false;
    }
    return true;
  });
};

const surveySent = (partner) => {
  const cohorts = filterInvalidCohorts(partner);

  const emailTimes = cohorts.map((cohort) =>
    cohort.partner_leaders.reduce(findFirstSent, moment())
  );

  return emailTimes.reduce((acc, date) => {
    return moment(date).isBefore(acc) ? moment(date) : acc;
  }, moment());
};

store.sortBy = (column) => {
  //if we are resorting by same column
  if (store.sortColumn === column) {
    if (store.sortDirection === 'ascending') {
      store.sortDirection = 'descending';
      store.partners.reverse();
    } else {
      store.sortDirection = 'ascending';
      store.partners.reverse();
    }
  } else {
    //always default sort new columns ascending
    store.sortDirection = 'ascending';
    store.sortColumn = column;
    sortPartners(column);
  }

  const localSortState = {
    column: store.sortColumn,
    direction: store.sortDirection,
  };

  StorageHelper.setItem('partnersSortState', localSortState);

  return true;
};

store.sendPartnerLeaderSurveys = (partnerData) => {
  store.ui.surveySentLoading = true;
  return PartnerApi.sendPartnerLeaderSurveys(partnerData)
    .then((response) => {
      // get the correct leader object
      const partnerId = Utils.getItemIndexFromArrayOfObjects(
        store.partners,
        'id',
        partnerData.id
      );

      // loop over cohort leaders and update email sent
      const partnerCohorts = Utils.clone(store.partners[partnerId].cohorts);
      partnerCohorts.forEach((cohort) => {
        if (!cohort.partner_leaders.length) {
          return;
        }
        cohort.partner_leaders.forEach((lead) => {
          response.sent_surveys.forEach((sent_survey) => {
            if (lead.id !== sent_survey.id) {
              return;
            }

            lead.survey_email_sent = sent_survey.survey_email_sent;
          });
        });
      });

      store.partners[partnerId].cohorts = partnerCohorts;
      store.ui.surveySentLoading = false;

      const pluralSuccessChar = response.sent_surveys.length > 1 ? 's' : '';
      store.ui.alert = {
        type: 'success',
        message: `Survey sent to leader${pluralSuccessChar}.`,
      };
      store.ui.partnerLeaderSurveyModal.close();
    })
    .catch(() => {
      store.ui.surveySentLoading = false;
      // Show the error
      store.ui.alert = {
        type: 'error',
        message: 'Something went wrong, try again later.',
      };
      store.ui.partnerLeaderSurveyModal.close();
    });
};

export default Store('PartnerStore', store);
