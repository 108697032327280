// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import EventApi from '@legacy-components/apis/V5/EventApi';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import StickyFooter from '@legacy-components/react/V4/Common/StickyFooter';
import Clipboard from 'clipboard';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import EventCSV from './EventCSVLink';
import EventParticipantRow from './EventParticipantRow';

const Participants = (props) => {
  const lab = useLegacyLabContext();
  const groupFilter = React.useRef();
  let rsvpCount = 0;

  const [loading, setLoading] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState('name');
  const [sortDirection, setSortDirection] = React.useState('asc');
  const [editingGroups, setEditingGroups] = React.useState(false);
  const [activeGroupFilter, setActiveGroupFilter] = React.useState('all');
  const [participantGroupsToUpdate, setParticipantGroupsToUpdate] =
    React.useState([]);
  const [clipboard, setClipboard] = React.useState('');
  const [searchField, setSearchField] = React.useState('');
  // users who are only Help Desk Agents cannot access the Workspace or delete attendees,
  // so we just hide the icons column from them
  const hasNonAgentRole = Utils.arrayContainsAnyItemInAnotherArray(
    props.user.auth_roles,
    ['ADMIN', 'BL_MANAGER', 'BL_SERVICES_MANAGER', 'BL_CONTRACT_COACH']
  );
  const hasGroup = props.event.groups && props.event.groups.length ? 1 : 0;
  let sessionCount = 0;
  props.event.sessions.forEach((session) => {
    if (session.num > sessionCount) {
      sessionCount++;
    }
  });

  // We define a weight for each column so that we can properly size them all
  // Hard assigning a col count instead of a flex weight means that we can be sure that
  // all cells in a column are the right width
  // These weights start to break above 9 sessions
  const nameProp = 14;
  const schoolProp = 5;
  const gradeProp = 3;
  const subjectProp = 4;
  const groupProp = 3;
  const rsvpProp = 2;
  const iconsProp = 3;
  const sessionProp = 1;
  const numColumns = 50;

  const totalColumns =
    nameProp +
    schoolProp +
    gradeProp +
    subjectProp +
    rsvpProp +
    iconsProp +
    sessionCount * sessionProp +
    hasGroup * groupProp;
  // Math time, this is how we convert the width of everything to the correct number of columns
  const schoolWidth = Math.max(
    Math.ceil((schoolProp / totalColumns) * numColumns),
    5
  );
  const gradeWidth = Math.max(
    Math.ceil((gradeProp / totalColumns) * numColumns),
    2
  );
  const subjectWidth = Math.max(
    Math.ceil((subjectProp / totalColumns) * numColumns),
    2
  );
  const groupWidth = hasGroup
    ? Math.max(Math.ceil((groupProp / totalColumns) * numColumns), 2)
    : 0;
  const rsvpWidth = Math.max(
    Math.floor((rsvpProp / totalColumns) * numColumns),
    4
  );
  const iconsWidth = hasNonAgentRole
    ? Math.max(Math.floor((iconsProp / totalColumns) * numColumns), 2)
    : 0;
  const sessionWidth = Math.max(
    Math.floor((sessionProp / totalColumns) * numColumns),
    2
  );
  // Name width takes up the remainder to ensure that we don't have any leftover space
  const nameWidth =
    numColumns -
    (schoolWidth +
      gradeWidth +
      subjectWidth +
      groupWidth +
      rsvpWidth +
      iconsWidth +
      sessionWidth * sessionCount);
  const nameClass = `grid-big__col-${nameWidth}--start-v`;
  const schoolClass = `grid-big__col-${schoolWidth}`;
  const gradeClass = `grid-big__col-${gradeWidth}`;
  const subjectClass = `grid-big__col-${subjectWidth}`;
  const groupClass = `grid-big__col-${groupWidth}`;
  const rsvpClass = `grid-big__col-${rsvpWidth}`;
  const iconsClass = `grid-big__col-${iconsWidth}`;
  const sessionClass = `grid-big__col-${sessionWidth}`;
  const iconsColHeader = hasNonAgentRole ? (
    <div className={`event__participant-header-col ${iconsClass}`}>
      <span className="ppd-3-coach-table__header-caption left"></span>
    </div>
  ) : null;

  const changeGroupFilter = () => {
    setActiveGroupFilter(groupFilter.current.value);
  };

  const handleEditGroups = () => {
    setEditingGroups(true);
  };

  const handleUpdateGroups = (data) => {
    const localGroupsToUpdate = Utils.clone(participantGroupsToUpdate);

    // check if already in changed
    const localGroupParticipantIndex = Utils.getItemIndexFromArrayOfObjects(
      localGroupsToUpdate,
      'userId',
      data.userId
    );

    // not alredy changed make a new record
    if (localGroupParticipantIndex === -1) {
      localGroupsToUpdate.push(data);

      // alredy changed update the record
    } else {
      localGroupsToUpdate[localGroupParticipantIndex] = data;
    }

    setParticipantGroupsToUpdate(localGroupsToUpdate);
  };

  const handleCopy = () => {
    props.setAlert({
      type: 'success',
      message: 'Attendee Emails copied to clipboard',
    });
  };

  React.useEffect(() => {
    setClipboard(
      new Clipboard('.admin-v5__btn-copy-emails', {
        text: function (trigger) {
          return trigger.getAttribute('copytext');
        },
      })
    );

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, []);

  const handleSaveGroups = () => {
    setLoading(true);
    const data = {
      eventId: props.event.id,
      participantGroupsToUpdate: participantGroupsToUpdate,
    };

    return EventApi.updateEventGroups(data).then(
      (response) => {
        setLoading(false);

        // update the relevant participants in the state
        const participants = Utils.clone(props.eventParticipants);

        participantGroupsToUpdate.forEach((parGroup) => {
          participants[parGroup.index].group_id = parGroup.newGroupId;
          participants[parGroup.index].event_sessions = [];
          // if we created a participant record for this user, save the participant id
          if (parGroup.userId in response.userParticipantIds) {
            participants[parGroup.index].participant_id =
              response.userParticipantIds[parGroup.userId];
          }
        });

        // update list
        props.setEventParticipants(participants);
        setParticipantGroupsToUpdate([]);

        // show success
        props.setAlert({
          type: 'success',
          message: 'Group changes saved',
        });

        setEditingGroups(false);
      },
      () => {
        setLoading(false);
        props.setAlert({
          type: 'error',
          message: 'Something went wrong when updating the participant groups.',
        });
        setEditingGroups(false);
      }
    );
  };

  const toggleSortDirection = (column) => {
    if (column === sortedColumn) {
      const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      setSortDirection(newSortDirection);
    } else {
      setSortDirection('asc');
      setSortedColumn(column);
    }
  };

  let participants = Utils.clone(props.eventParticipants);
  participants.forEach((participant, i) => {
    participant.index = i;
  });
  // filter by event
  if (activeGroupFilter !== 'all') {
    participants = participants.filter(
      (participant) =>
        parseInt(participant.group_id) === parseInt(activeGroupFilter)
    );
  }
  if (searchField) {
    participants = participants.filter((participant) => {
      return (
        `${participant.user_first_name} ${participant.user_last_name}`
          .toLocaleLowerCase()
          .includes(searchField.toLocaleLowerCase()) ||
        participant.user_email
          .toLocaleLowerCase()
          .includes(searchField.toLocaleLowerCase())
      );
    });
  }
  // Sort order indication arrow
  let sortIndicator = null;
  if (sortDirection === 'asc') {
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--up"></div>
    );
  } else if (sortDirection === 'desc') {
    sortIndicator = (
      <div className="tc-bold-arrow admin-v5__coach-table__arrow ppd-3-coach-table__arrow--down"></div>
    );
    // reverse array accordingly
    participants.reverse();
  }

  // set up list of emails for the copy button
  let participantsEmailString = '';
  participants.forEach((participant, i) => {
    const emailSeperator = i > 0 ? '; ' : '';
    participantsEmailString += `${emailSeperator}${participant.user_email}`;
  });

  const eventSessionsHeaders = [];
  let groupFilters = null;
  let groupTableHeader = null;
  let copyEmailsButton = (
    <button
      className="admin-v5__edit-table-btn v5__link admin-v5__btn-copy-emails"
      onClick={handleCopy}
      copytext={participantsEmailString}
    >
      <span className="tc-v5-copy admin-v5__edit-table-btn-icon"></span>
      Copy Emails
    </button>
  );
  let addParticipantButton = (
    <button
      className="admin-v5__edit-table-btn v5__link"
      onClick={() => props.setAddingExistingParticipant(true)}
      data-testid="add-a-participant"
    >
      <span className="tc-v5-plus-solid admin-v5__edit-table-btn-icon" />
      Add a Participant
    </button>
  );
  let editGroupButton = null;
  const hasEvents = props.event.groups && props.event.groups.length;
  // there are groups
  if (hasEvents) {
    // set the appropreate header
    groupTableHeader = (
      <div className={`event__participant-header-col ${groupClass}`}>
        <span className="ppd-3-coach-table__header-caption left">Group</span>
      </div>
    );

    // set up the filter options
    const groupOptions = [
      <option key="group-option-all" value="all">
        All
      </option>,
    ];

    // use the groups to create arrays to organize the session headers
    props.event.groups.forEach((group, groupIndex) => {
      // at the top of the page, we only separate out the groups if this is an Advanced event
      if (props.event.advanced_event) {
        eventSessionsHeaders.push({
          id: group.id,
          nodes: [
            <div
              key={`group-headline-${group.id}-groupIndex`}
              className="admin-v5__group-headline"
            >
              {group.name || `Group ${groupIndex}`}
            </div>,
          ],
        });
      }
      groupOptions.push(
        <option key={`group-option-${group.id}-${groupIndex}`} value={group.id}>
          {group.name}
        </option>
      );
    });

    // set up the Group filter and button to edit the groups in the table
    groupFilters = (
      <div className="admin-v5__table-filter-flex admin-table__filter-container">
        Group:&nbsp;
        <select
          ref={groupFilter}
          className="v5__select admin-table__filter event__participants-groups tc__select"
          value={activeGroupFilter}
          onChange={changeGroupFilter}
        >
          {groupOptions}
        </select>
      </div>
    );
    editGroupButton = (
      <StickyFooter
        submit={handleEditGroups}
        loading={loading}
        submitText="Edit Groups"
      />
    );
    // while we're editing the groups, show the Save Groups button and
    // hide the other UI elements in the button row
    if (editingGroups) {
      groupFilters = null;
      copyEmailsButton = null;
      addParticipantButton = null;

      editGroupButton = (
        <StickyFooter
          submit={handleSaveGroups}
          loading={loading}
          submitText="Save Groups"
        />
      );
    }
  }

  const sessionNumMap = {};
  const sessionsTableHeaders = [];
  const sessionNums = [];
  // if there are multiple groups in a Basic event,
  // we just show the list of sessions at the top since they are the same for all groups
  const displayGroupId = props.event.sessions[0].group_id;
  const isSingleSession =
    props.event.groups.length === props.event.sessions.length ||
    (!ArrayHelpers.hasElements(props.event.groups) &&
      props.event.sessions.length === 1);
  props.event.sessions.forEach((session) => {
    // push the session table headers
    sessionNumMap[session.id] = session.num;
    if (session.num > sessionsTableHeaders.length) {
      sessionsTableHeaders.push(
        <div className={`event__participant-header-col ${sessionClass}`}>
          <span className="ppd-3-coach-table__header-caption left">
            Sess. {session.num}
          </span>
        </div>
      );
      sessionNums.push(sessionsTableHeaders.length);
    }

    // setup the sessions headers copy
    const groupHeaderIndex = Utils.getItemIndexFromArrayOfObjects(
      eventSessionsHeaders,
      'id',
      session.group_id
    );
    const noGroupHeaderIndex = Utils.getItemIndexFromArrayOfObjects(
      eventSessionsHeaders,
      'id',
      'na'
    );

    // the text of the session date/start time/end time depends on what data we have
    let sessionDescription = `${session.name}: ` || `Session ${session.num}: `;
    // if there is only one session (per group, or with no groups), don't show the name
    if (isSingleSession) {
      sessionDescription = '';
    }
    // only show dates/times if the session date is set
    if (session.session_date_formatted) {
      const sessionDate = moment(session.session_date_formatted).format(
        'MMMM D, YYYY'
      );
      // only show times if both times are set
      let sessionTimes = '';
      if (
        session.session_start_time_formatted &&
        session.session_end_time_formatted
      ) {
        const timezone =
          props.event.time_zone && props.event.time_zone.pytz_timezone;
        sessionTimes = `, ${Utils.formatEventSessionTime(
          session.session_start_time_formatted,
          session.session_end_time_formatted,
          timezone
        )}`;
      }
      sessionDescription += `${sessionDate + sessionTimes}`;
    } else {
      sessionDescription += 'TBD';
    }

    const sessionNode = (
      <div
        className="admin-v5__session-times v5__body-copy"
        key={`event-session-${session.id}`}
      >
        {sessionDescription}
      </div>
    );

    if (groupHeaderIndex === -1 && noGroupHeaderIndex === -1) {
      eventSessionsHeaders.push({
        id: 'na',
        nodes: [sessionNode],
      });
    } else if (
      groupHeaderIndex === -1 &&
      noGroupHeaderIndex !== -1 &&
      (!ArrayHelpers.hasElements(props.event.groups) ||
        session.group_id === displayGroupId)
    ) {
      eventSessionsHeaders[noGroupHeaderIndex].nodes.push(sessionNode);
    } else if (groupHeaderIndex !== -1) {
      eventSessionsHeaders[groupHeaderIndex].nodes.push(sessionNode);
    }
  });

  // render session headers
  const eventSessionsHeaderNodes = [];
  eventSessionsHeaders.forEach((sessionHeader, i) => {
    eventSessionsHeaderNodes.push(
      <div
        className="admin-v5__group-container"
        key={`group-${sessionHeader.id}-sessions-${i}`}
      >
        {sessionHeader.nodes}
      </div>
    );
  });

  let noParticipantsNode = null;
  if (participants.length === 0) {
    noParticipantsNode = (
      <div className="edit-event__table--empty-state">
        No participants have registered or RSVP’d for this event yet.
      </div>
    );
  }

  // Setup the attendance csv dowload link
  const headers = ['NAME', 'EMAIL', 'SCHOOL'];
  if (hasEvents) {
    headers.push('GROUP');
  }
  // We need to get the Participants group names and when they registered for sessions
  participants.forEach((eventParticipant) => {
    if (eventParticipant.reserved) {
      rsvpCount += 1;
    }
  });

  const downloadCSVNode = !editingGroups && (
    <EventCSV event={props.event} eventParticipants={props.eventParticipants} />
  );
  // by default, allow the user to filter the table and copy the emails
  let editButtons = (
    <div className="row row--1200 tc__body-copy admin-v5__table-filters admin-v5__table-filters--right">
      {copyEmailsButton}
      {downloadCSVNode}
    </div>
  );
  // if the user has editing permissions, also show the add/edit buttons
  if (
    Utils.isManager.call(lab) ||
    Utils.isAdmin.call(lab) ||
    Utils.isContractCoach.call(lab) ||
    Utils.isServicesManager.call(lab) ||
    Utils.isHelpDeskAgent.call(lab)
  ) {
    editButtons = (
      <div className="row row--1200 tc__body-copy admin-v5__table-filters admin-v5__table-filters--right">
        {copyEmailsButton}
        {downloadCSVNode}
        {addParticipantButton}
      </div>
    );
  }

  return (
    <>
      <div className="row row--1200 event__participants-search-bar">
        <div className="cute-4-desktop">
          <input
            type="text"
            className="v5__input header__v5-search-input"
            placeholder="Search by name or email"
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
          <span className="header__search-button event__search-icon tc-search" />
        </div>
        <div className="cute-5-desktop">{groupFilters}</div>
        <div className="cute-3-desktop" />
      </div>
      {editButtons}
      <div className="row row--1200">
        <div className="grid-big__row admin-v5__coach-table__header">
          <div
            className={`event__participant-header-col ${nameClass}`}
            onClick={() => toggleSortDirection('name')}
          >
            <span className="ppd-3-coach-table__header-caption left clickable">
              Name
            </span>
            {sortedColumn === 'name' ? sortIndicator : ''}
          </div>
          <div className={`event__participant-header-col ${schoolClass}`}>
            <span className="ppd-3-coach-table__header-caption left">
              Role & School
            </span>
          </div>
          <div className={`event__participant-header-col ${gradeClass}`}>
            <span className="ppd-3-coach-table__header-caption left">
              Grade
            </span>
          </div>
          <div className={`event__participant-header-col ${subjectClass}`}>
            <span className="ppd-3-coach-table__header-caption left">
              Subject
            </span>
          </div>
          {groupTableHeader}
          <div className={`event__participant-header-col ${rsvpClass}`}>
            <span className="ppd-3-coach-table__header-caption left">
              RSVP ({rsvpCount})
            </span>
          </div>
          {sessionsTableHeaders}
          {iconsColHeader}
        </div>
        {participants.map((eventParticipant) => {
          return (
            <EventParticipantRow
              key={`participant-${eventParticipant.user_id}`}
              groups={props.event.groups}
              eventParticipant={eventParticipant}
              eventSessions={sessionNums}
              eventSessionsMap={sessionNumMap}
              eventTimezone={props.event.time_zone.pytz_timezone}
              user={props.user}
              setSuccessAlert={props.setSuccessAlert}
              openConfirmDeleteEventAttendeeModal={(data) => {
                props.setDeleteAttendeeModal({
                  opened: true,
                  loading: false,
                  data: data,
                });
              }}
              activeGroup={activeGroupFilter}
              editingGroups={editingGroups}
              handleUpdateGroups={handleUpdateGroups}
              nameClass={nameClass}
              schoolClass={schoolClass}
              gradeClass={gradeClass}
              subjectClass={subjectClass}
              groupClass={groupClass}
              rsvpClass={rsvpClass}
              iconsClass={iconsClass}
              sessionClass={sessionClass}
              setAlert={props.setAlert}
            />
          );
        })}
        {noParticipantsNode}
      </div>
      {editGroupButton}
    </>
  );
};

Participants.propTypes = {
  ui: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default Participants;
