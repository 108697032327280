import { CircularProgress, Stack } from '@mui/material';

export default function LoadingPage(): JSX.Element {
  return (
    <Stack
      data-testid="loading-page"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <CircularProgress sx={{ color: '#1976d2' }} size={80} />
    </Stack>
  );
}
