// @ts-nocheck FIXME
import React from 'react';
import ReactDOM from 'react-dom';
import LayerManager from './../../components/LayerManager';

class GreyOverlayDialogue extends React.Component {
  state = {
    margin: 0,
  };

  componentDidMount() {
    const modalHeight = ReactDOM.findDOMNode(this.refs.fixedModal).offsetHeight;
    const marginTop = -(modalHeight / 2);
    this.setState({ margin: marginTop });
  }

  handleOutsideClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    LayerManager.close(this.props.closeUrl);
  };

  render() {
    return (
      <div>
        <div
          className="modal__page-overlay modal__page-overlay--grey hide-phone show-desktop"
          onClick={this.handleOutsideClose}
        ></div>
        <div
          ref="fixedModal"
          id={this.props.id}
          style={{ marginTop: this.state.margin }}
          className="modal--fixed modal--grey-overlay hide-phone show-desktop"
        >
          {this.props.children}
        </div>
        <div
          ref="fixedModalMobile"
          id={this.props.id + '-mobile'}
          className="modal--fixed modal--grey-overlay show-phone hide-desktop"
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default GreyOverlayDialogue;
