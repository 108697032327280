// @ts-nocheck FIXME
import React from 'react';
import CoachMatchCapsule from './CoachMatchCapsule';

const CoachMatchs = (props) => {
  if (!props.proposedMatches) {
    return null;
  }

  const [expandedDetails, setExpandedDetails] = React.useState(null);
  const handleSetExpandedDetails = (matchId) => {
    if (expandedDetails === matchId) {
      setExpandedDetails(null);
      return;
    }
    setExpandedDetails(matchId);
  };

  return (
    <section className="row row--1200 admin-v5_coach-dashboard--content-container">
      <div className="cute-12-desktop coach-matches__container">
        <header className="coach-matches__headline coach-match__headline--capsule v5__heading-3">
          Matches to Review
        </header>
        {props.proposedMatches.map((match, i) => {
          return (
            <CoachMatchCapsule
              {...match}
              pageUser={props.pageUser}
              coachTimeZone={props.coachTimeZone}
              expandedDetails={expandedDetails}
              handleSetExpandedDetails={handleSetExpandedDetails}
              handleSetSelectedMatchForAction={
                props.handleSetSelectedMatchForAction
              }
              key={`coach-match-${i}`}
            />
          );
        })}
      </div>
    </section>
  );
};

export default CoachMatchs;
