// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const MatchTeaching = (props) => {
  let gradesNode = <div>No grades provided</div>;
  if (props.participant.grades && props.participant.grades.length) {
    const grades = props.participant.grades.map((grade, i) => {
      return (
        <li
          className=""
          key={`participant-${props.participant.id}-grade-${grade.id}-${i}`}
        >
          {grade.label}
        </li>
      );
    });
    gradesNode = <ul className="coach-match__list">{grades}</ul>;
  }

  let subjectsNode = <div>No subjects provided</div>;
  if (props.participant.subjects && props.participant.subjects.length) {
    const subjects = props.participant.subjects.map((subject, i) => {
      return (
        <li
          className=""
          key={`participant-${props.participant.id}-subject-${subject.id}-${i}`}
        >
          {subject.label}
        </li>
      );
    });
    subjectsNode = <ul className="coach-match__list">{subjects}</ul>;
  }

  let subjectNotesNode = <div>No notes provided</div>;
  if (props.participant.subject_notes) {
    subjectNotesNode = (
      <div className="ckeditor__output">{props.participant.subject_notes}</div>
    );
  }
  return (
    <section className="row">
      <div className="coach-match__headline coach-match__headline--details coach-match__headline--blueberry v5__heading-5">
        <i className="tc-teaching-experience match-details__icon" /> Teaching
        Experience
      </div>
      <div className="coach-match__row">
        <div className="coach-match__col--33">
          <div className="match-details__sub-headline v5__body-copy--bold">
            Years Teaching
          </div>
          <div className="match-details__main-detail">
            {props.participant.years_teaching || 'No response provided'}
          </div>
          <div className="match-details__sub-headline v5__body-copy--bold">
            Sped
          </div>
          <div>{props.participant.sped || 'No response provided'}</div>
          <div className="match-details__sub-headline v5__body-copy--bold">
            EL
          </div>
          <div>{props.participant.ell || 'No response provided'}</div>
        </div>
        <div className="coach-match__col--33">
          <div className="match-details__sub-headline v5__body-copy--bold">
            Grade Bands
          </div>
          <div className="match-details__main-detail">
            {props.participant.importGrade &&
            props.participant.importGrade.label
              ? props.participant.importGrade.label
              : 'No response provided'}
          </div>
          <div className="match-details__sub-headline v5__body-copy--bold">
            Grades
          </div>
          <div>{gradesNode}</div>
        </div>
        <div className="coach-match__col--33">
          <div className="match-details__sub-headline v5__body-copy--bold">
            Subject
          </div>
          <div className="match-details__main-detail">
            {props.participant.importSubject &&
            props.participant.importSubject.label
              ? props.participant.importSubject.label
              : 'No response provided'}
          </div>
          <div className="match-details__sub-headline v5__body-copy--bold">
            Subjects
          </div>
          {subjectsNode}
        </div>
      </div>
      <div className="coach-match__col">
        <div className="match-details__subject-notes match-details__sub-headline v5__body-copy--bold">
          Additional Notes
        </div>
        {subjectNotesNode}
      </div>
    </section>
  );
};

export default MatchTeaching;
MatchTeaching.propTypes = {
  participant: PropTypes.object.isRequired,
};
