// @ts-nocheck FIXME
import ParticipantApi from '@legacy-components/apis/ParticipantApi';
import Utils from '@legacy-components/components/DynamicUtils';
import SingleSelect from '@legacy-components/react/V5/Common/SingleSelect/SingleSelectContainer';
import PropTypes from 'prop-types';
import { useState } from 'react';

const SolicitNewSchool = (props) => {
  const [multiSelectOptions, setMultiSelectOptions] = useState({
    selectedOption: props.selectedOption,
    visibleOptions: [],
  });

  // something was selected or removed, update the selected option
  const updateSelect = (data) => {
    props.setUnSavedChanges(true);

    let multiSelectData = Utils.clone(multiSelectOptions);
    multiSelectData = Object.assign(multiSelectData, data.data);

    setMultiSelectOptions(multiSelectData);
    props.setSelectedOption(multiSelectData.selectedOption);
    // send the necessary info to the form handler
    if (multiSelectData && multiSelectData.selectedOption) {
      props.setInstitutionId(multiSelectData.selectedOption.id);
    } else {
      props.setInstitutionId(null);
      props.setNoSchool(false);
    }
  };

  // get updated list of schools for auto complete search
  const getSchoolVisibleOptions = (data) => {
    ParticipantApi.searchInstitutions({
      searchString: data.input,
    })
      .then((response) => {
        const multiSelectData = Utils.clone(multiSelectOptions);
        Object.assign(multiSelectData, {
          visibleOptions: response.result,
        });

        setMultiSelectOptions(multiSelectData);
      })
      .catch(() => {
        props.setAlert({
          type: 'error',
          message:
            'Sorry, we were unable to update your settings. Please try again later.',
          handleClose: () => props.setAlert({}),
        });
      });
  };

  return (
    <SingleSelect
      {...multiSelectOptions}
      field="schoolZipcode"
      minCharCountForResults={5}
      restrictTo={'^[0-9]*$'}
      updateSingleSelect={updateSelect}
      getVisibleOptions={getSchoolVisibleOptions}
      analyticsCategory="school_info"
      analyticsLabel="from_settings"
      placeholder="Search school or district by ZIP code"
      onChangeDelay="700"
      error={props.error}
    />
  );
};

export default SolicitNewSchool;

SolicitNewSchool.propTypes = {
  setAlert: PropTypes.func.isRequired,
};
