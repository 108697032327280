// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import NavigationLink from '@legacy-components/react/NavigationLink';
import UserPhoto from '@legacy-components/react/V4/Common/UserPhoto';
import PropTypes from 'prop-types';

const Teacher = (props) => {
  const handlePhotoClick = () => {
    Router.route(teacherUrl);
  };

  // url for links to teacher curriculum
  let teacherUrl =
    process.env.REACT_APP_TEACHING_URL +
    '/browse/master_teacher/' +
    props.User.id +
    '/' +
    props.User.seo_url +
    '/curriculum?from=master_teacher_directory';

  // show city/state if applicable
  let locationText = '';
  if (props.School.Location_City) {
    locationText += props.School.Location_City + ', ';
  }
  if (props.School.Location_State) {
    locationText += props.School.Location_State;
  }
  const locationNode = locationText ? (
    <div className="mt-dir__teacher-metadata">{locationText}</div>
  ) : null;

  // link at the bottom depends on whether the teacher has mt lessons or strategies
  let contentLinkNode = (
    <NavigationLink url={teacherUrl} className="tc__link">
      {props.User.mt_lesson_count} Lessons
    </NavigationLink>
  );
  if (props.User.mt_strategy_count) {
    teacherUrl = `${process.env.REACT_APP_TEACHING_URL}/browse/master_teacher/${props.User.id}/${props.User.seo_url}/blended?from=mtp_directory`;
    contentLinkNode = (
      <NavigationLink url={teacherUrl} className="tc__link">
        {props.User.mt_strategy_count} Videos
      </NavigationLink>
    );
  }

  return (
    <div className="mt-dir__teacher">
      <div
        onClick={handlePhotoClick}
        className="mt-dir__teacher-photo-container"
      >
        <UserPhoto src={props.User.photo} diameter={100} />
      </div>
      <div className="mt-dir__teacher-metadata mt-dir__teacher-metadata--name">
        <NavigationLink url={teacherUrl} className="tc__link">
          {props.User.first_name} {props.User.last_name}
        </NavigationLink>
      </div>
      {locationNode}
      <div className="mt-dir__teacher-metadata">{props.User.environment}</div>
      <div className="mt-dir__teacher-metadata">
        {props.User.experience} years experience
      </div>
      <div className="mt-dir__teacher-metadata mt-dir__teacher-metadata--content-count">
        {contentLinkNode}
      </div>
    </div>
  );
};

Teacher.propTypes = {
  User: PropTypes.object.isRequired,
  School: PropTypes.object.isRequired,
};

export default Teacher;
