// @ts-nocheck FIXME
import Video from '@legacy-components/react//Utilities/Video';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import PropTypes from 'prop-types';

const Preview = (props) => {
  let previewNode = null;
  let paginationNode = null;
  let noImageMessageNode = null;

  switch (props.preview_type) {
    case 'movie':
      previewNode = (
        <Video
          preview={props.content.preview[0]}
          formats={props.content.formats}
          start={props.start_time}
          end={props.end_time}
          height={500}
          width={885}
        />
      );
      break;

    case 'image':
      if (props.content.preview.length < 1) {
        noImageMessageNode = (
          <div className="artifact-overlay__message--none">
            This resource does not have a preview at this time.
          </div>
        );
      }

      previewNode = (
        <div className="artifact-overlay__image--none">
          <div
            className={
              'artifact-overlay__preview-icon artifact__preview-icon file-type-icon ext-' +
              props.icon
            }
          ></div>
          {noImageMessageNode}
        </div>
      );

      if (props.hasViablePreview) {
        previewNode = (
          <img
            src={props.content.preview[0]}
            id="artifact-overlay__preview"
            className="artifact-overlay__image tc__visible artifact-overlay__image--max-width i-i"
          />
        );
      }
      break;

    case 'document':
      if (props.content.preview.length < 1) {
        noImageMessageNode = (
          <div className="artifact-overlay__message--none">
            This resource does not have a preview at this time.
          </div>
        );
      }

      previewNode = (
        <div className="artifact-overlay__image--none">
          <div
            className={
              'artifact-overlay__preview-icon artifact__preview-icon file-type-icon ext-' +
              props.icon
            }
          ></div>
        </div>
      );

      if (props.hasViablePreview) {
        previewNode = [];

        if (props.content.preview.length > 1) {
          let maxPreviewLength = 25;
          if (props.content.preview.length < 25) {
            maxPreviewLength = props.content.preview.length;
          }

          // dont show the next and last buttons if there are no more items to show
          const nextClass =
            props.activeArtifactPage === maxPreviewLength ? ' tc__no-op' : '';
          const lastClass = props.activeArtifactPage === 1 ? ' tc__no-op' : '';

          paginationNode = (
            <div>
              <div className="artifact-overlay__paging-bar"></div>
              <div className="artifact-overlay__page-controls tc__body-copy">
                <div
                  className={'page-control page-control__left' + lastClass}
                  onClick={props.handlePreviousPageClick}
                >
                  <div className="tc-arrow"></div>
                </div>
                <div className="page-control page-control__current">
                  {props.activeArtifactPage} of {maxPreviewLength}
                </div>
                <div
                  className={'page-control page-control__right' + nextClass}
                  onClick={props.handleNextPageClick}
                >
                  <div className="tc-arrow"></div>
                </div>
              </div>
            </div>
          );
        }

        for (let i = 0; props.content.preview.length > i; i++) {
          const displayPageClass =
            i + 1 === props.activeArtifactPage ? ' tc__visible' : ' tc__hidden';

          const imagePreviewId = i === 0 ? 'artifact-overlay__preview' : '';

          if (props.activeArtifactPage - 1 === i) {
            previewNode.push(
              <img
                key={'doc-page-' + i}
                src={props.content.preview[i]}
                className="artifact-overlay__image--full-width i-doc"
              />
            );
          } else {
            previewNode.push(
              <img
                id={imagePreviewId}
                key={'doc-page-' + i}
                src={props.content.preview[i]}
                className={'artifact-overlay__image' + displayPageClass}
              />
            );
          }
        }
      }
      break;

    case 'text':
      previewNode = (
        <PurifiedHTML
          className="artifact-overlay__text tc__body-copy"
          content={props.content.preview.replace(
            /href="\//g,
            'href="' + process.env.REACT_APP_LAB_URL + '/'
          )}
        />
      );
      break;
  }

  return (
    <div className="artifact-overlay__artifact">
      <div className="artifact-overlay__image-container">{previewNode}</div>
      {paginationNode}
    </div>
  );
};

Preview.propTypes = {
  content: PropTypes.object.isRequired,
  preview_type: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  hasViablePreview: PropTypes.bool,
  activeArtifactPage: PropTypes.number,
  handlePreviousPageClick: PropTypes.func,
  handleNextPageClick: PropTypes.func,
};

export default Preview;
