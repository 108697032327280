// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import Slider from './../../Common/Carousel';
import TrendingCapsule from './TrendingCapsule';

const TrendingLessonsAndStrategies = (props) => {
  // no favorites for default state
  let trendingContainerNode = null;
  const results = [];
  // layout favorite cards
  const trendingCapsules = props.results.map((result) => {
    return (
      <TrendingCapsule
        key={`trending-${result.id}`}
        result={result}
        pageUser={props.pageUser}
        user={props.user}
        getNewFavorites={props.getNewFavorites}
      />
    );
  });

  // there are favorites initialize the slider
  if (trendingCapsules.length) {
    trendingContainerNode = (
      <div className="home-v5__favorites-capsule">
        <h3 className="v5__heading-6">Trending Lessons And Strategies</h3>
        <div className="home-v5__favorites-carousel">
          <Slider slideStep={3} uniqueKey={'profile-trending'}>
            {trendingCapsules}
          </Slider>
        </div>
      </div>
    );
  }

  return <React.Fragment>{trendingContainerNode}</React.Fragment>;
};

export default TrendingLessonsAndStrategies;

TrendingLessonsAndStrategies.propTypes = {
  results: PropTypes.array,
  getNewFavorites: PropTypes.func,
  pageUserId: PropTypes.number,
};
