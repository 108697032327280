// @ts-nocheck FIXME

const Pass = () => {
  return (
    <div className="row tc__center-text">
      <div className="network-connected">
        <h2 className="v5__heading-4">All of your connections are working.</h2>
      </div>
    </div>
  );
};

export default Pass;
