// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Utils from './../../../components/DynamicUtils';

class Slider extends React.Component {
  state = {
    segmentsFilled: 0,
    sliderCurrentWidth: 0,
    inEditMode: this.props.inEditMode,
    hovering: false,
    selectedPoint: 0,
    error: null,
  };

  slider = null;
  position = null;
  offsetWidth = null;
  tenthOffsetWidth = null;

  componentDidMount() {
    this.setupSlider(this.props);

    // calculate new position of overlay on resize
    window.addEventListener('resize', this.mobileSliderResize);
  }

  componentWillUnmount() {
    // remove resize listener
    window.removeEventListener('resize', this.mobileSliderResize);
  }

  componentWillReceiveProps(nextProps) {
    this.setupSlider(nextProps);
  }

  mobileSliderResize = () => {
    this.setupSlider(this.props);
  };

  setupSlider = (props) => {
    // Get the slider
    this.slider = ReactDOM.findDOMNode(this.refs.slider);

    // Get the position of the slider
    this.position = Utils.getAbsolutePosition(this.slider);

    // Get the width of the slider
    this.offsetWidth = this.slider.offsetWidth;

    // get the position of the points on the slider
    this.tenthOffsetWidth = this.offsetWidth / 10;

    // set the inital value if provided
    if ('initialValue' in props) {
      this.setState({
        sliderCurrentWidth: Number(props.initialValue) * this.tenthOffsetWidth,
        selectedPoint: props.initialValue,
      });
    }
  };

  getValidationErrors = (newValue) => {
    const type = this.props.type;

    if (newValue === null) {
      return 'Please select a value.';
    }
  };

  handleSliderHover = (e) => {
    // If the slider is NOT in edit mode, we should prevent state changes
    if (!this.props.inEditMode) {
      return;
    }

    // round the width of the hover bar to the nearest point
    const segmentsFilled = Math.round(
      (e.pageX - this.position[0]) / this.tenthOffsetWidth
    );
    this.setState({
      hovering: true,
      segmentsFilled: segmentsFilled,
      sliderCurrentWidth: segmentsFilled * this.tenthOffsetWidth,
      error: null,
    });

    // clear errors on hover
    if (typeof this.props.handleSliderError === 'function') {
      this.props.handleSliderError(null);
    }
  };

  handleSliderHoverOut = () => {
    // If the slider is NOT in edit mode, we should prevent state changes
    if (!this.props.inEditMode) {
      return;
    }
    // clear the hovered value
    this.setState({
      hovering: false,
      segmentsFilled: 0,
      sliderCurrentWidth: this.state.selectedPoint * this.tenthOffsetWidth,
    });
  };

  handleSliderClick = () => {
    // If the slider is NOT in edit mode, we should prevent state changes
    if (!this.props.inEditMode) {
      return;
    }
    const segments = {
      filled: this.state.segmentsFilled,
    };

    // clear errors and set slider width so that subsequent animations are smooth
    this.setState({
      error: null,
      sliderCurrentWidth: segments.filled * this.tenthOffsetWidth,
    });

    const newValue = segments.filled;
    const error = this.getValidationErrors(newValue);

    if (error) {
      this.handleError(error);
      return;
    } else {
      // set the selected point
      this.setState({
        selectedPoint: segments.filled,
      });
      // Send the new value to the parent
      if (typeof this.props.handleSliderSelect === 'function') {
        this.props.handleSliderSelect(newValue);
      }
    }
  };

  handleError = (error) => {
    this.setState({
      error: error,
    });

    if (typeof this.props.handleSliderError === 'function') {
      this.props.handleSliderError(error);
    }
  };

  renderBalloon = (color, value) => {
    return (
      <div className="slider__selector" style={{ color: color }}>
        <div className="slider__selector-icon tc-balloon"></div>
        <div className="slider__selector-caption">{value}</div>
      </div>
    );
  };

  render() {
    // Each measurement type has its own background color
    const baselineColor = '#1A7CD9';
    const measurementColor = '#f4ab2a';

    const bgColor =
      this.props.type == 'measurement' ? measurementColor : baselineColor;

    // set up slider bars
    const sliderBarActiveClass = this.state.hovering ? ' active' : '';
    const sliderSelectedWidth = {
      width: this.state.sliderCurrentWidth,
      background: bgColor,
    };

    let sliderInactiveClass = '';
    if (!this.state.inEditMode) {
      sliderInactiveClass = ' inactive';
    }

    // set up baseline marker and marker position
    let baselineMarker = null;
    if (typeof this.props.baseline === 'number') {
      baselineMarker = (
        <div className="slider__marker slider__marker--baseline">
          <div className="slider__marker-arrow slider__marker-arrow--baseline"></div>
          <div className="slider__marker-copy">
            Starting
            <br />
            Point
            <div className="slider__marker-copy-value">
              {this.props.baseline}
            </div>
          </div>
        </div>
      );
    }
    // set up all 11 points with a loop
    const sliderPointsNode = [];
    const numSliderSegments = 10;
    for (let i = 0; i <= numSliderSegments; i++) {
      // default styles for slider point
      let pointSelectedClass = '';
      let pointSelectedStyles = {
        borderColor: bgColor,
      };
      const balloon = null;

      // if the point is selected, display the correct point styles
      if (this.state.selectedPoint == i) {
        // the selected point should be colored in with the slider's color
        pointSelectedClass = ' selected';
        pointSelectedStyles = {
          background: bgColor,
          borderColor: bgColor,
        };
      }

      // if the point is hovered over and there is no error,
      // display the correct point styles + balloon
      if (
        this.state.segmentsFilled == i &&
        this.state.hovering &&
        !this.state.error
      ) {
        // the point the cursor is at should be colored in white
        pointSelectedClass = ' active';
        const pointSelectedStyles = {
          borderColor: bgColor,
        };
      }

      // add the baseline marker to the correct position
      const baselineMarkerNode =
        this.props.baseline == i ? baselineMarker : null;

      let pointNode = null;
      // the last point doesn't have a slider segment extending to the right of it
      if (!this.state.inEditMode) {
        const pointClass =
          i == numSliderSegments
            ? 'slider__point--last'
            : 'slider__segment slider__segment--' + i;
        pointNode = (
          <div key={'point-' + i} className={pointClass}>
            {baselineMarkerNode}
          </div>
        );
      } else if (i == numSliderSegments) {
        pointNode = (
          <div
            key={'point-' + i}
            className={'slider__point slider__point--last' + pointSelectedClass}
            style={pointSelectedStyles}
          >
            {baselineMarkerNode}
          </div>
        );
      } else {
        pointNode = (
          <div
            key={'point-' + i}
            className={'slider__segment slider__segment--' + i}
          >
            <div
              className={'slider__point' + pointSelectedClass}
              style={pointSelectedStyles}
            >
              {baselineMarkerNode}
            </div>
          </div>
        );
      }

      // push slider point node to the array
      sliderPointsNode.push(pointNode);
    }

    const balloon = this.renderBalloon(bgColor, this.state.selectedPoint);

    let containerClassName = 'outcome-story_slider';
    if (this.props.baseline == 0) {
      containerClassName += ' low-baseline';
    } else if (this.props.baseline == 10) {
      containerClassName += ' high-baseline';
    }

    return (
      <div className="qualitative-slider-container">
        <div className={containerClassName}>
          <div
            className={'slider__container' + sliderInactiveClass}
            ref="slider"
            onClick={this.handleSliderClick}
            onMouseMove={this.handleSliderHover}
            onMouseOut={this.handleSliderHoverOut}
          >
            <div className="slider__bar-bg">
              <div className="slider__bar-fgbg"></div>
              <div
                className={'slider__bar-fg' + sliderBarActiveClass}
                style={sliderSelectedWidth}
              >
                <div className="slider__baloon-container">{balloon}</div>
              </div>

              <div className="slider__points">{sliderPointsNode}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Slider.propTypes = {
  initialValue: PropTypes.number.isRequired,
  type: PropTypes.string,
  baseline: PropTypes.number,
  inEditMode: PropTypes.bool.isRequired,
  handleSliderSelect: PropTypes.func,
  handleSliderError: PropTypes.func,
};

export default Slider;
