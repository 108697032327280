// @ts-nocheck FIXME
import moment from 'moment-timezone';
import propTypes from 'prop-types';

const Products = (props) => {
  let productsNode = [];

  if (!props.eventsAndCohorts.length) {
    productsNode = (
      <div className="participant-v5__sub-section participant-v5__product">
        <div className="v5__author-name-copy no-product">
          This person hasn&apos;t registered for any products
        </div>
      </div>
    );
  }

  const createCohort = (cohort) => {
    const cohortStartDate = moment(cohort.cohort.term_start_formatted).format(
      'M/DD/YY'
    );
    const cohortEndDate = moment(cohort.cohort.term_end_formatted).format(
      'M/DD/YY'
    );
    return (
      <>
        <div className="tc-v5-coach-product participant-v5__product-icon"></div>
        <div>
          <div className="v5__author-name-copy">
            {`${cohort.cohort.name}, ${cohort.product.name}`}
          </div>
          <div className="v5__comment-copy">
            {`${cohortStartDate}–${cohortEndDate}`}
          </div>
        </div>
      </>
    );
  };

  const createEvent = (event) => {
    let eventDescription = event.name;
    if (event.product_code) {
      eventDescription = `${eventDescription} – ${event.product_code}`;
    }
    let eventDates = moment(event.event_start_date).format('M/DD/YY');
    if (event.event_start_date !== event.event_end_date) {
      const endDate = moment(event.event_end_date).format('M/DD/YY');
      eventDates = `${eventDates}–${endDate}`;
    }

    return (
      <>
        <div className="tc-v5-event-product participant-v5__event-icon"></div>
        <div>
          <div className="v5__author-name-copy">{eventDescription}</div>
          <div className="v5__comment-copy">{eventDates}</div>
        </div>
      </>
    );
  };

  productsNode = props.eventsAndCohorts.map((item) => {
    if (item.type === 'cohort') return createCohort(item);
    else if (item.type === 'event') return createEvent(item);
  });

  return (
    <div>
      <div className="participant-v5__products-header v5__heading-5 participant-v5__sub-section">
        Products
      </div>
      {productsNode.map((product, index) => {
        const selectedClass =
          index === props.selectedProductIndex
            ? ' participant-v5__product--selected'
            : '';
        return (
          <div
            className={`participant-v5__sub-section participant-v5__product${selectedClass}`}
            key={`product-${index}`}
            onClick={() => props.updateProductSelection(index)}
          >
            {product}
          </div>
        );
      })}
    </div>
  );
};

Products.propTypes = {
  eventsAndCohorts: propTypes.array.isRequired,
  timezone: propTypes.string.isRequired,
  selectedProductIndex: propTypes.number.isRequired,
  updateProductSelection: propTypes.func.isRequired,
};

export default Products;
