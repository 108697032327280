// @ts-nocheck FIXME
const WindowHelpers = {
  getViewportDimensions: () => {
    let width;
    let height;

    // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
    if (typeof window.innerWidth != 'undefined') {
      (width = window.innerWidth), (height = window.innerHeight);

      // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
    } else if (
      typeof document.documentElement != 'undefined' &&
      typeof document.documentElement.clientWidth != 'undefined' &&
      document.documentElement.clientWidth != 0
    ) {
      (width = document.documentElement.clientWidth),
        (height = document.documentElement.clientHeight);

      // older versions of IE
    } else {
      (width = document.getElementsByTagName('body')[0].clientWidth),
        (height = document.getElementsByTagName('body')[0].clientHeight);
    }

    return { width: parseInt(width), height: parseInt(height) };
  },

  hasScrolledToBottom: (margin) => {
    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    return window.scrollY + window.innerHeight > documentHeight - margin;
  },

  isMobileDevice: () => {
    // Get window elements that match the mobile css property
    const mq = window.matchMedia('(max-width: 48em)');
    return mq.matches;
  },

  removeHashFromUrl: () => {
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
  },

  checkFullScreenEnabled: () => {
    // check that browser can handle fullscreen
    let fullScreenAvailable = false;
    if (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnable
    ) {
      fullScreenAvailable = true;
    }
    return fullScreenAvailable;
  },

  // Checks for the presense of a cookie key/value
  hasCookieKeyAndValue: (key, value) => {
    return (
      typeof key === 'string' &&
      typeof value === 'string' &&
      document.cookie.includes(key + '=' + value)
    );
  },

  scrollTo: (horizontalPosition = 0, verticalPosition = 0) => {
    window.setTimeout(() => {
      window.scroll(horizontalPosition, verticalPosition);
    }, 50);
  },

  getVerticalScrollPosition: () => {
    return window.pageYOffset || document.documentElement.scrollTop;
  },

  getHorizontalScrollPosition: () => {
    return window.pageXOffset || document.documentElement.scrollLeft;
  },

  getPathName: () => {
    return window.location.pathname;
  },

  getQueryStringParam: (param) => {
    // Returns the value of the requested param from the query string
    const queryString = window.location.search || '';
    param = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + param + '=([^&#]*)');
    const results = regex.exec(queryString);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },

  navigationConfirmation: (enable) => {
    if (enable) {
      window.onbeforeunload = (e) => {
        e.preventDefault();
        return false;
      };
      return;
    }
    window.onbeforeunload = null;
  },
};

export default WindowHelpers;
