// @ts-nocheck FIXME
import dragula from 'dragula';
import React from 'react';

/*
USAGE

- elements should have a parent element with an ID
- each sortable element should have a data-id attribute
- the node returned should be rendered as a react class, <SomeSortableThing />
- if there are multiple dragula instances on a page, give them different selectors

*/
import WindowHelpers from './../../../../components/WindowHelpers';

import Utils from './../../../../components/DynamicUtils';
// reference to the sorting library, dragula
const drakes = {};

const Sortable = (
  SortableComponent,
  dragContainer,
  scrollContainer,
  callback,
  customOptions = {}
) => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component {
    // scrolling logic
    handleScrolling = (scrollContainer, el) => {
      if (scrollContainer) {
        // detimine viewport dimensions
        const viewport = WindowHelpers.getViewportDimensions();

        // determine element position
        const elPosition = el.getBoundingClientRect();
        const topDistance = elPosition['top'];
        const bottomDistance = viewport['height'] - elPosition['bottom'];

        let scrollingElem = null;
        if (scrollContainer != 'window') {
          // get the element being scrolled
          scrollContainer = scrollContainer.replace('#', '');
          scrollingElem = document.getElementById(scrollContainer);
        } else {
          scrollingElem = document.documentElement;
        }

        // scroll up
        if (topDistance < 120) {
          scrollingElem.scrollTop += -20;
        }

        // scroll down
        if (bottomDistance < 50) {
          scrollingElem.scrollTop += 20;
        }
      }
    };

    // after a component mounts, link the sorting functionality
    componentDidMount() {
      // index dragula instances by their selector
      const drakeId = dragContainer.replace('#');

      // merge incoming options with default options
      const options = Object.assign({
        // dragula work-around for that facilitates use with react
        revertOnSpill: true,

        moves: (el, source, handle, sibling) => {
          // only make dragable from drag handle icon
          if (
            !Utils.hasClass(handle, 'domains__drag-icon') ||
            !Utils.hasClass(handle, 'ppd-v3-curation__drag-icon--domain')
          ) {
            return false;
          }
          // must be sortable
          if (el.dataset.sortable == 'false') {
            return false;
          }
          // only expanded elements are draggable
          if (el.dataset.collapsed == 'false') {
            return false;
          }

          // all good here
          return true;
        },

        invalid: (el, handle) => {
          return false;
        },

        accepts: (el, target, source, sibling) => {
          // scrolling logic
          this.handleScrolling(scrollContainer, el);
          return true;
        },
      });

      // remove pre-existing dragula instance if this is a re-render
      if (drakes[drakeId]) {
        drakes[drakeId].destroy();
      }

      // attach dragula functionality via a traditional JS selector

      drakes[drakeId] = dragula(
        [document.querySelector(dragContainer)],
        options

        // this gets called whenever an item is dropped
      ).on('drop', (el, target, source, sibling) => {
        // read ids from all child nodes
        const ids = Array.prototype.map.call(target.childNodes, (item) => {
          return item.dataset.item;
        });

        // don't actually change the DOM, the DOM will get changed when the store changes
        drakes[drakeId].cancel();

        // update the store with the order of the new ids
        callback(ids);
      });
    }

    // render the component that is passed in
    render() {
      return SortableComponent;
    }
  };
};

export default Sortable;
