// @ts-nocheck FIXME
import propTypes from 'prop-types';
import React from 'react';

const SurveyQuestions = (props) => {
  // track which answers panel is expanded
  const [expandedQuestion, setExpandedQuestion] = React.useState(null);

  const toggleExpanded = (questionId) => {
    // if clicking on the same expanded panel, close it
    // otherwise only allow one expanded panel
    if (expandedQuestion === questionId) {
      setExpandedQuestion(null);
    } else {
      setExpandedQuestion(questionId);
    }
  };

  const surveyQuestionsNode = [];

  for (const [questionId, questionBody] of Object.entries(props.content)) {
    const question = questionBody.question_name;
    const answers = questionBody.question_answers;

    // make sure only the clicked arrow button changes
    const arrowClass =
      expandedQuestion === questionId ? ' coach-surveys__expand-arrow--up' : '';
    surveyQuestionsNode.push(
      <div
        className="coach-surveys__question-container grid-ten__row"
        key={questionId}
      >
        <div className="coach-surveys__question grid-ten__row">
          <div className="coach-surveys__question-text">{question}</div>
          {answers.length ? (
            <div
              className={`tc-v4-circle-arrow arrow coach-surveys__expand-arrow${arrowClass}`}
              onClick={() => toggleExpanded(questionId)}
              data-testid={`question-arrow-${questionId}`}
            ></div>
          ) : (
            <div></div>
          )}
        </div>

        {answers.length && expandedQuestion === questionId ? (
          <div className="grid-ten__row">
            <ol className="coach-surveys__answer-list">
              {answers.map((answer, answerIdx) => {
                return (
                  <li
                    className="coach-surveys__answer-text"
                    key={`${questionId}-${answerIdx}`}
                  >
                    <p>{answer}</p>
                  </li>
                );
              })}
            </ol>
          </div>
        ) : null}
      </div>
    );
  }

  return props.isExpanded ? (
    <div className="coach-surveys__questions grid-ten__row">
      {surveyQuestionsNode.map((question) => {
        return question;
      })}
    </div>
  ) : null;
};
SurveyQuestions.propTypes = {
  content: propTypes.object.isRequired,
  isExpanded: propTypes.bool.isRequired,
};

export default SurveyQuestions;
