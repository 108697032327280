// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';

const CoachParticipant = (props) => {
  if (Utils.isEmpty(props.participant)) {
    return null;
  }

  return (
    <div className="header__admin-link-container">
      <div className="header__admin-participant-name">
        {props.participant.full_name}
      </div>

      <div className="header__admin-icons tc-header__admin-icons-v5">
        <NavigationLink
          title={`${props.participant.name}'s Home`}
          url={`/lab/home/${props.participant.blUserId}?from=navigation_widget`}
          className="tc__link tc-icon_home"
        ></NavigationLink>

        <NavigationLink
          title={`${props.participant.name}'s Workspace`}
          url={`/lab/workspace/${props.participant.blUserId}?from=navigation_widget`}
          className="tc__link tc-v5-workspace"
        ></NavigationLink>

        <NavigationLink
          title={`${props.participant.name}'s Favorites`}
          url={`${process.env.REACT_APP_TEACHING_URL}/browse/favorites/${props.participant.blUserId}?from=navigation_widget`}
          className="tc__link tc-v4-heart-filled"
        ></NavigationLink>

        <NavigationLink
          title={`${props.participant.name}'s Highlights`}
          url={`/lab/highlights/${props.participant.blUserId}?from=navigation_widget`}
          className="tc__link tc-v5-star-solid"
        ></NavigationLink>

        <NavigationLink
          title={`${props.participant.name}'s Details`}
          url={`/lab/participant/${props.participant.id}/details?from=navigation_widget`}
          className="tc__link tc-v5-details"
        ></NavigationLink>
        {props.showSettings && (
          <NavigationLink
            title={`${props.participant.name}'s Settings`}
            url={`/account/${props.participant.blUserId}?from=navigation_widget`}
            className="tc__link tc-gear"
          ></NavigationLink>
        )}
      </div>
    </div>
  );
};

CoachParticipant.propTypes = {
  participant: PropTypes.object,
};

export default CoachParticipant;
