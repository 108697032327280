// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const CuratorNotes = (props) => {
  // by default, show the Add Notes link
  let noteNode = (
    <div className="cute-12-desktop left">
      <div className="tc__headline-3 curation__section-headline">
        Curator Notes
      </div>
      <a onClick={props.toggleAddCuratorNotes} className="tc__link">
        Add Notes
      </a>
    </div>
  );
  // if there are already notes or the user has clicked Add Notes, show the textarea
  const hasNotes = props.notes && props.notes.length;
  if (props.showNotes || hasNotes) {
    noteNode = (
      <div className="cute-12-desktop left">
        <div className="tc__headline-3 curation__section-headline">
          Curator Notes
        </div>
        <textarea
          rows="8"
          onBlur={props.onBlur}
          className={'tc__input curation__input tc__modal-add-input'}
          ref={props.inputRef}
          defaultValue={props.notes}
        />
        {hasNotes ? null : (
          <div onClick={props.toggleAddCuratorNotes} className="tc__link">
            Cancel
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      id={'section-' + props.sectionId}
      className="row ppd-v3-curation__form-row"
    >
      {noteNode}
    </div>
  );
};

CuratorNotes.propTypes = {
  sectionId: PropTypes.number,
  inputRef: PropTypes.func,
  showNotes: PropTypes.bool,
  notes: PropTypes.string,
  toggleAddCuratorNotes: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default CuratorNotes;
