// @ts-nocheck FIXME
import TimePicker from '@legacy-components/react/V5/Common/TimePicker';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import ActionModal from './../../Common/ActionModal';
import CKEditor from './../../Common/CKEditor';
import Spinner from './../../Common/Spinner';

const MeetingCancelModal = (props) => {
  // set defaults that might change with the timezone being available
  const meetingTimeDefault = moment(props.meetingTimeFormatted).tz(
    props.timeZone
  );

  const meetingDate = meetingTimeDefault.format('MMMM D, YYYY [at] h:mm A z');

  const cancelTimeDefault = moment().tz(props.timeZone);

  const [canceledTime, setCanceledTime] = useState(
    cancelTimeDefault.format('HH:mm')
  );
  const [canceledDate, setCanceledDate] = useState(
    cancelTimeDefault.format('YYYY-MM-DD')
  );
  const [canceledTimeError, setCanceledTimeError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [sendRescheduleEmail, setSendRescheduleEmail] = useState(
    props.startChecked
  );

  const [ckEditorMounted, setCkEditorMounted] = useState(false);

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handleCancelMeeting = (e) => {
    e.preventDefault();

    // don't do anything if the form is submitting
    if (isLoading) {
      return;
    }

    setLoading(true);

    if (!canceledTime || canceledTime === null) {
      setLoading(false);
      setCanceledTimeError('The meeting cancel time is required.');
      return;
    }

    props.setMeetingAsCancelled({
      participantId: props.participantId,
      meetingId: props.meetingId,
      status: props.meetingStatus || 3,
      internal_notes:
        CKEDITOR.instances[
          'participant-v5__participant-meeting-notes-input-internal-notes-0'
        ].getData(),
      when_cancelled: `${canceledDate}T${canceledTime}`,
      successCallback: () => props.close(),
      failureCallback: () => setLoading(false),
      sendRescheduleEmail: sendRescheduleEmail,
      timezone: props.timeZone,
    });
  };

  const handleTimeChange = (time) => {
    if (!time || time === null) {
      setCanceledTime(null);
      return;
    }

    // _i is part of moment object which contains  entered timestamp
    // splitting it on the ':' to get the hour field
    const timeFields = time._i.split(':');
    if (timeFields[0] === '00') {
      setCanceledTime(null);
      return;
    }

    setCanceledTimeError('');
    setCanceledTime(time.format('HH:mm'));
  };

  const handleToggleEmail = (e) => {
    setSendRescheduleEmail(e.target.checked);
  };

  // disable buttons when submitting
  const btnProps = isLoading ? { disabled: true } : null;

  // you can resechedule meetings only when participant canceled
  let resendEmailNode = null;
  if (parseInt(props.meetingStatus) === 3) {
    resendEmailNode = (
      <div className="workspace__form-row v5__heading-5 participant-v5__meeting-no-show--checkbox-row">
        <div className="participant-v5__meeting-no-show--checkbox">
          <input
            type="checkbox"
            id="send_email"
            defaultChecked={sendRescheduleEmail}
            className="tc__checkbox"
            onChange={handleToggleEmail}
            data-testid="participant-toggle-cancel"
          />
        </div>
        <div className="v5__body-copy">Send an email to reschedule meeting</div>
      </div>
    );
  }

  const cancelTimeErrorNode = canceledTimeError ? (
    <div className="v5__body-copy participant-v5__submit-error participant-v5__submit-error--modal">
      {canceledTimeError}
    </div>
  ) : null;

  return (
    <div className="participant-v5__meeting-cancel-modal">
      <ActionModal
        headlineText="Confirm Meeting Cancellation"
        close={handleCloseModal}
        spaceFromTop={props.isMobile ? 10 : 100}
        updateFlag={ckEditorMounted}
      >
        <form className="participant-v5__modal-body">
          <div className="workspace__form-row participant-v5__modal-top-row v5__heading-5 participant-v5__meeting-cancel-modal-topline">
            <span className="tc-v5-meeting"></span>
            <div className="admin__form-label ">Meeting on {meetingDate}.</div>
          </div>

          <div className="workspace__form-row">
            <div className="participant-v5__meeting-cancel-modal-time">
              <div className="admin__form-label admin__form-label--inline">
                When was this meeting cancelled?
              </div>
              <div>
                <div
                  className={
                    'participant-v5__meeting-times flatpickr-container'
                  }
                >
                  <Flatpickr
                    className="v5__input"
                    onChange={(selectedDates) =>
                      setCanceledDate(
                        moment(selectedDates[0]).format('YYYY-MM-DD')
                      )
                    }
                    options={{
                      altInput: true,
                      altFormat: 'm/d/Y',
                      dateFormat: 'Y-m-d',
                      defaultDate: canceledDate,
                      position: 'below',
                      static: 'true',
                    }}
                    data-test-id="participant-cancel-date"
                  />
                </div>
                <div
                  className={
                    'participant-v5__meeting-times flatpickr-container participant-v5__meeting-time-margin'
                  }
                >
                  <TimePicker
                    onChange={(time) => handleTimeChange(time)}
                    value={cancelTimeDefault}
                    clearMargin={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="workspace__form-row">
            <div className="admin__form-label admin__form-label--inline">
              Add context for the meeting cancellation.
            </div>
            <CKEditor
              id="internal-notes"
              increment={0}
              innerText={props.internalNotes}
              className="participant-v5__participant-meeting-notes-input"
              placeholder="Take private notes about this educator. How are they progressing? Is there anything you’d like to note for your next meeting?"
              configs="/legacy-libs/configs/bl-cke-config-workspace.js"
              data-test-id="participant-cancel-field"
              setCkEditorMounted={setCkEditorMounted}
            />
          </div>

          {resendEmailNode}

          <div className="v5__body-copy participant-v5__meeting-cancel-alert">
            <span className="tc-v5-alert participant-v5__resend-alert"> </span>
            <span>
              Once a meeting is set to <span className="bold">Cancelled</span>,
              you cannot change its status.
            </span>
          </div>
          {cancelTimeErrorNode}
          <div className="workspace__form-row admin__right-btn-col">
            <div className="participant-v5__meeting-no-show--btn">
              <button
                className="v5__btn"
                onClick={handleCancelMeeting}
                {...btnProps}
              >
                <Spinner loading={isLoading} />
                Cancel Meeting
              </button>
            </div>
          </div>
        </form>
      </ActionModal>
    </div>
  );
};

MeetingCancelModal.propTypes = {
  close: PropTypes.func.isRequired,
  meetingTimeFormatted: PropTypes.string.isRequired,
  internalNotes: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
  participantId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  whenCancelled: PropTypes.string.isRequired,
  setMeetingAsCancelled: PropTypes.func.isRequired,
  startChecked: PropTypes.bool.isRequired,
};

MeetingCancelModal.defaultProps = {
  data: {},
};

export default MeetingCancelModal;
