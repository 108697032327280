// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import FreezeWindowScroll from './../../Common/FreezeWindowScroll';

const Filters = (props) => {
  const filterCategoryNodes = [];
  // looping over filter categories
  for (const filter in props.filters) {
    let filterSelected = false;
    props.searchFilters.forEach((activeFilter) => {
      // check for activex
      if (activeFilter.category !== filter) {
        return;
      }
      filterSelected = true;
    });

    let filterDisableClass = filterSelected
      ? ' bl4-filter__category-item--disabled'
      : '';

    // set category title
    let filterTitle = '';
    switch (filter) {
      case 'subjects':
        filterTitle = 'subjects';
        break;
      case 'grades':
        filterTitle = 'grade level';
        break;
      case 'focusAreas':
        filterTitle = 'Focus Areas';
        break;
      case 'types':
        filterTitle = 'Type';
        break;
    }

    let toRemoveForAll = null;
    let allLinkClass = ' bl4-filter__category-item--active';
    // create individual filter element
    const filters = props.filters[filter].map((filterElem, i) => {
      // if the filter is already set indicate that and disable filter element
      let disableFilter = false;
      let filterClass = '';
      props.searchFilters.forEach((activeFilter) => {
        if (
          filterElem.id === activeFilter.filter_id &&
          filter === activeFilter.category
        ) {
          allLinkClass = '';
          disableFilter = true;
          filterClass = ' bl4-filter__category-item--active';
          filterDisableClass = '';
          toRemoveForAll = {
            filterId: filterElem.id,
            filterCategory: filter,
            filterField: filterElem.field,
            action: 'remove',
            from: 'filters',
          };
        }
      });

      // set up for class indentation
      const levelClass = ' bl4-filter__category-item--l' + filterElem.level;

      let count = null;
      if (filterElem.count) {
        count =
          '(' +
          filterElem.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          ')';
      }

      // make singular filter labels plural
      let filterLabel = filterElem.label;
      switch (filterElem.label) {
        case 'strategy':
          filterLabel = 'strategies';
          break;
        case 'lesson':
          filterLabel = 'lessons';
          break;
        case 'tech tool':
          filterLabel = 'Tech Tools';
          break;
      }

      return (
        <li
          className={`bl4-filter__category-item tc__link${filterClass}${filterDisableClass}${levelClass}`}
          key={`filter-${filterElem.field}-${filterElem.id}-${i}`}
          onClick={(e) => {
            e.preventDefault();
            if (disableFilter) {
              return;
            }
            props.updateFilters({
              filterId: filterElem.id,
              filterCategory: filter,
              filterField: filterElem.field,
              action: 'add',
            });
          }}
        >
          {filterLabel} {count}
        </li>
      );
    });

    if (!filters.length) {
      continue;
    }

    // append remove filter
    filters.unshift(
      <li
        key={'filter-subject-all'}
        className={`bl4-filter__category-item bl4-filter__category-item--all tc__link${allLinkClass}`}
        onClick={(e) => {
          e.preventDefault();
          props.updateFilters(toRemoveForAll);
        }}
      >
        All
      </li>
    );

    const category = (
      <div
        className="bl4-filter__category"
        key={'filter-category-' + filterTitle}
      >
        <h3 className="tc__headline-4 bl4-filter__headline">{filterTitle}</h3>
        <ul className="bl4-filter__category-list">{filters}</ul>
      </div>
    );

    // type must be first filter set
    if (filter === 'types') {
      filterCategoryNodes.unshift(category);
      continue;
    }

    filterCategoryNodes.push(category);
  }

  let filtersVisibleClass = '';
  let freezeWindowScrollNode = null;
  let filtersBg = null;
  if (props.ui.filtersModal.opened) {
    filtersBg = <div className="bl4-filter__background"></div>;
    filtersVisibleClass = ' bl4__filters--active';
    freezeWindowScrollNode = (
      <FreezeWindowScroll mobileOnly={true} parentModal={'bl4__filters'} />
    );
  }

  return (
    <div>
      {filtersBg}
      <div className={`bl4__filters${filtersVisibleClass}`}>
        <div className="cd-search__filters-mobile-header show-phone hide-desktop">
          <div className="cd-search__filters-mobile-cancel">
            <a
              className="tc__link"
              onClick={() => props.ui.filtersModal.close()}
            >
              Close
            </a>
          </div>
          <div className="cd-search__filters-mobile-caption tc__headline-2">
            Filters
          </div>
        </div>
        {filterCategoryNodes}
        {freezeWindowScrollNode}
      </div>
    </div>
  );
};

export default Filters;

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  activeFilter: PropTypes.object,
  updateFilters: PropTypes.func.isRequired,
  searchFilters: PropTypes.array.isRequired,
};

Filters.defaultProps = {
  searchFilters: [],
};
