// @ts-nocheck FIXME
import Request from './../components/Request';

const GateKeeperApi = {
  getPasswordResetCode: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/user/get_password_reset_code',
      JSON.stringify(data)
    );
  },

  sendPasswordResetEmail: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/user/generate_password_reset_code',
      JSON.stringify(data)
    );
  },

  login: function (data) {
    let url = process.env.REACT_APP_LAB_SERVICE_URL + '/login';
    if (data.isMobileUser) {
      url =
        process.env.REACT_APP_LAB_SERVICE_URL +
        '/coaching/lab/participant/login';
    }
    return Request.post(url, JSON.stringify(data));
  },

  register: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/register',
      JSON.stringify(data)
    );
  },

  requestNewPassword: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/forgot-password',
      JSON.stringify(data)
    );
  },

  resetPassword: function (data) {
    return Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/reset-password',
      JSON.stringify(data)
    );
  },

  getModalData: function () {
    return Request.get(
      process.env.REACT_APP_LAB_SERVICE_URL + '/gate-keeper/get-modal-data'
    );
  },
};

export default GateKeeperApi;
