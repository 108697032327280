// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import ArtifactOverlay from '@legacy-components/react/V4/Common/ArtifactOverlay/ArtifactOverlay';
import DomainTag from '@legacy-components/react/V5/Workspace/modules/Goal/DomainTag';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import CopyToClipboard from './../CopyToClipboard';
import UserPhoto from './../UserPhoto';
import NextMeeting from './modules/NextMeeting';
import useResourceAuth, { resourcePerms } from './resourceAuth';

const ParticipantEngagementDetails = (props) => {
  const { hasResourcePerms } = useResourceAuth();
  const lab = useLegacyLabContext();
  // show/hide the cohort overview modal
  const [cohortModalOpened, setCohortModalOpened] = React.useState(false);

  // if the current user is the participant themselves,
  // we show slightly different data
  const userIsParticipant = props.currentUser.id === props.user_id;

  // show the participant's institution if they have one
  let institutionNode;
  if (props.institution) {
    institutionNode = (
      <div className="engagement-details__copy engagement-details__copy--uppercase">
        {props.institution.name}
      </div>
    );
  }

  const statusIcons = {
    Active: 'tc-icon_status-active participant-v5__active-icon-color',
    'Temporarily Inactive': 'tc-icon_status-temp-inactive',
    'Done with Coaching': 'tc-icon_status-done',
    Dropped: 'tc-icon_status-dropped',
    Disengaged: 'tc-icon_status-disengaged',
  };

  // show the coaching status if the engagement has any notes
  let statusNode;
  // link to the engagement's coach match profile
  let matchProfileLink;
  // if the participant has never had a cohort, hide these two columns
  let cohortNode;
  let nextMeetingNode;
  // if the user clicks on the cohort name,
  // open the modal to display the cohort overview text
  let cohortModal;
  let coachingResourcesNode;

  if (props.latest_engagement) {
    const engagement = props.latest_engagement;

    // show the engagement status (use default text when no status)
    let statusIcon = 'tc-v5-complete-outline participant-v5__active-icon-color';
    let statusText = `Registered for ${engagement.product.name} with ${engagement.cohort.name}!`;
    const coachName = props.coach
      ? `${props.coach.first_name} ${props.coach.last_name}`
      : null;
    if (engagement.status === 'Active') {
      statusIcon = statusIcons[engagement.status];
      statusText = `Matched to ${coachName}!`;
      // in the rare case that an active participant has no coach,
      // show a grey dot and different text
      if (!coachName) {
        statusIcon =
          'tc-icon_status-active v5__activity-table--status-icon-slate';
        statusText = 'Pending New Coach';
      }
    } else if (engagement.status && engagement.status !== 'Registered') {
      statusIcon = statusIcons[engagement.status];
      const coachNameText = coachName ? ` - ${coachName}` : '';
      statusText = `${Utils.formatEngagementStatus(
        engagement.status
      )}${coachNameText}`;
    }

    statusNode = (
      <div className="engagement-details__coaching-status engagement-details__copy">
        <div className={`${statusIcon} engagement-details__icon`}>
          <span className="path1"></span>
          <span className="path2"></span>
        </div>
        <div className="engagement-details__status">{statusText}</div>
      </div>
    );

    // if the participant has a coach and a valid match, link to the coach match profile
    // (for some reason, data viewers cannot access this page)
    if (engagement.coach_user_id && props.canEditData && engagement.has_match) {
      matchProfileLink = (
        <NavigationLink
          url={`/lab/coach/${engagement.coach_user_id}/match/${props.id}/details`}
          className="engagement-details__link"
        >
          <span className="tc-v5-archive engagement-details__icon"></span>
          View Match Profile
        </NavigationLink>
      );
    }

    coachingResourcesNode = Utils.hasFeatureFlag.call(
      lab,
      'COACHING_RESOURCES'
    ) &&
      hasResourcePerms(Object.values(resourcePerms)) &&
      props.cohortPriorityFocusAreas &&
      props.cohortPriorityFocusAreas.length > 0 && (
        <div className="engagement-details__coaching-resources">
          <div className="v5__heading-6">Coaching Resources</div>
          <div className="engagement-details__copy">
            {props.cohortPriorityFocusAreas.map((focusArea, i) => {
              return (
                <DomainTag
                  key={i}
                  containerStyles="workspace__goal-domain--align-top"
                  tagStyles="workspace__goal-domain--goal-display"
                  id={focusArea.id}
                  tagline={focusArea.tagline}
                  label={focusArea.label}
                  hover={true}
                  hasResourcePerms={hasResourcePerms([
                    resourcePerms.BL_CONTRACT_COACH,
                    resourcePerms.ADMIN,
                  ])}
                  designDocLink={focusArea.coaching_design}
                />
              );
            })}
          </div>
        </div>
      );

    if (engagement.cohort) {
      // the "expiration date" is actually the target end date
      const userTimeZone = props.currentUser.time_zone.pytz_timezone;
      const expirationDate = moment(
        engagement.cohort.target_end_date_formatted
      );

      const isArchived = !Utils.isCoachingEngagementActive(engagement.status);

      // if the engagement is over, some of the text gets greyed out
      const expiredClass = isArchived ? ' engagement-details__copy--grey' : '';

      // participants see their cohort name in grey
      let cohortNameNode = (
        <div
          className={`engagement-details__copy engagement-details__copy--medium${expiredClass}`}
        >
          {engagement.cohort.name}
        </div>
      );
      // internal BL users can click on the cohort name to see the cohort overview
      if (!userIsParticipant) {
        cohortNameNode = (
          <button
            onClick={() => setCohortModalOpened(true)}
            className="v5__link engagement-details__copy engagement-details__copy--medium"
          >
            {engagement.cohort.name}
          </button>
        );
      }

      // if the engagement is still active, show how many meetings are left
      let sessionsLeftNode;
      if (!isArchived) {
        let meetingsLeftText = 'Unlimited sessions';
        if (engagement.product.number_of_sessions) {
          // count the number of completed meetings
          const meetingCount = engagement.meetings.reduce((prev, next) => {
            return next.status === 2 ? prev + 1 : prev;
          }, 0);
          const meetingsLeft = Math.max(
            engagement.product.number_of_sessions - meetingCount,
            0
          );
          meetingsLeftText = `${meetingsLeft} session${
            meetingsLeft === 1 ? '' : 's'
          } left`;
        }

        sessionsLeftNode = (
          <div className="engagement-details__copy engagement-details__copy--italic engagement-details__copy--red">
            {meetingsLeftText}
          </div>
        );
      }

      // if the engagement is still active, show the registration status
      let registeredNode;
      if (!isArchived) {
        let registeredText = 'Not registered';
        if (engagement.registration_completion_formatted) {
          registeredText = `Registered ${moment(
            engagement.registration_completion_formatted
          )
            .tz(userTimeZone)
            .format('M/D/YYYY')}`;
        }
        registeredNode = (
          <div className="engagement-details__copy">{registeredText}</div>
        );
      }

      // show the "expiration date" (i.e. target end date)
      let expiredNode = (
        <div className="engagement-details__copy engagement-details__copy--italic engagement-details__copy--grey">
          Expired {expirationDate.format('M/D/YYYY')}
        </div>
      );
      if (!isArchived) {
        expiredNode = (
          <div className="engagement-details__copy engagement-details__copy--bold">
            Expires {expirationDate.format('M/D/YYYY')}
          </div>
        );
      }

      cohortNode = (
        <div className="engagement-details__cohort-info">
          <div className="v5__heading-6">Cohort Overview</div>
          {cohortNameNode}
          <div
            className={`engagement-details__copy engagement-details__copy--bold${expiredClass}`}
          >
            {engagement.product.name}
          </div>
          {sessionsLeftNode}
          {registeredNode}
          {expiredNode}
        </div>
      );

      // if the cohort overview modal is open, display that
      if (cohortModalOpened) {
        const cohortModalData = {
          title: engagement.cohort && engagement.cohort.name,
          connections: {
            preview_type: 'text',
            content: {
              preview: engagement.cohort && engagement.cohort.overview,
            },
          },
        };
        cohortModal = (
          <div className="v5-artifact-overlay">
            <ArtifactOverlay
              data={cohortModalData}
              close={() => setCohortModalOpened(false)}
            />
          </div>
        );
      }

      nextMeetingNode = (
        <NextMeeting
          nextMeeting={engagement.nextMeeting}
          participantId={props.id}
          coachTimeZone={props.coach && props.coach.time_zone.pytz_timezone}
          participantTimeZone={props.time_zone.pytz_timezone}
          userIsParticipant={userIsParticipant}
          canAddMeeting={props.canEditData && !isArchived}
          canJoinMeeting={props.canEditData || userIsParticipant}
        />
      );
    }
  }

  // some links are only visible to internal BL users
  let linksNode;
  if (!userIsParticipant) {
    // link to the participant's Partner Leader Dashboard
    // if they also have an active partner leader record
    let partnerLeadDashboardLink;
    if (props.partner_lead_id) {
      partnerLeadDashboardLink = (
        <NavigationLink
          url={`/lab/leader/${props.partner_lead_id}/home`}
          className="engagement-details__link"
        >
          <span className="tc-v5-admin-dash engagement-details__icon"></span>
          Partner Leader Dashboard
        </NavigationLink>
      );
    }

    linksNode = (
      <div className="engagement-details__links engagement-details__copy">
        <div
          id="participant-email"
          className="engagement-details__link engagement-details__link--email"
          copytext={props.user.email}
          onClick={() => props.setSuccessAlert('Email copied to clipboard')}
        >
          <span className="tc-v5-email engagement-details__icon"></span>
          {props.user.email}
          <CopyToClipboard targetSelector="#participant-email" />
        </div>
        {matchProfileLink}
        {partnerLeadDashboardLink}
      </div>
    );
  }

  // Roles to exclude this logic for, all internal user roles
  const internalUserRoles = [
    'TC_DATA_VIEWER',
    'BL_SERVICES_MANAGER',
    'BL_MANAGER',
    'TC_CONTENT_CURATOR',
    'ADMIN',
  ];
  const isInternalUser = props.currentUser.auth_roles.some((auth_role) =>
    internalUserRoles.includes(auth_role)
  );

  // We want to show a minimalist view for users that are only partner leaders if they are viewing a participant's page
  if (
    !userIsParticipant &&
    props.currentUser.auth_roles.includes('TC_PARTNER') &&
    !isInternalUser
  ) {
    cohortNode = null;
    nextMeetingNode = null;
    statusNode = null;
    linksNode = null;
  }

  return (
    <>
      {cohortModal}
      <div className={`row row--${props.responsive ? 'responsive' : '1200'}`}>
        <div className="cute-12-desktop">
          <div className="engagement-details">
            <div className="engagement-details__photo">
              <UserPhoto
                src={props.user.photo}
                userId={props.user.id}
                firstName={props.user.first_name}
                lastName={props.user.last_name}
                diameter={80}
                editable={props.canEditData}
              />
            </div>

            <div className="engagement-details__user-data">
              <div className="v5__heading-3">
                {props.user.first_name} {props.user.last_name}
              </div>
              {institutionNode}
              {statusNode}
              {linksNode}
            </div>

            {coachingResourcesNode}
            {cohortNode}
            {nextMeetingNode}
          </div>
        </div>
      </div>
    </>
  );
};

ParticipantEngagementDetails.propTypes = {
  user: PropTypes.object.isRequired, // this is the participant's user record
  id: PropTypes.number.isRequired, // this is the participant id
  user_id: PropTypes.number.isRequired, // this is the participant's user_id
  latest_engagement: PropTypes.object, // current or most recently past coaching engagement
  institution: PropTypes.object,
  coach: PropTypes.object,
  partner_lead_id: PropTypes.number,
  time_zone: PropTypes.object.isRequired, // this is the participant's timezone
  currentUser: PropTypes.object.isRequired, // this is the current user viewing the page
  canEditData: PropTypes.bool,
  setSuccessAlert: PropTypes.func.isRequired,
  responsive: PropTypes.bool,
};

export default ParticipantEngagementDetails;
