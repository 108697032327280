// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const EventZoomCallToAction = (props) => {
  // no need for this component if user has not checked into the session
  if (!props.checkedIn || !props.zoomLink) {
    return null;
  }

  let ctaNode = null;
  if (props.sessionActivity.ended) {
    return null;
  } else if (props.sessionActivity.started) {
    ctaNode = (
      <div className="event__attendee-zoom-cta">
        <div className="event__attendee-check-in-headling v5__heading-5">
          Join the session on Zoom
        </div>
        <div className="v5__body-copy">
          It&apos;s time to get started! Join us on Zoom to participate in
          today&apos;s session.
        </div>
        <a
          href={props.zoomLink}
          target="_blank"
          rel="noopener noreferrer"
          className="v5__btn v5__btn--blue event__attendee-zoom-btn"
        >
          Join Event
        </a>
        {props.children}
      </div>
    );
  } else if (
    props.sessionActivity.sessionDateStarted &&
    !props.sessionActivity.started
  ) {
    const tenMinutes = moment.duration(10, 'minutes');
    const sessionStartTime = props.activeSession.session_start_time_formatted
      ? moment(props.activeSession.session_start_time_formatted)
      : null;

    const tenMinBefore =
      sessionStartTime !== null
        ? `
                at ${Utils.hourRounder(moment(sessionStartTime - tenMinutes))}
                ${moment(sessionStartTime - tenMinutes).format('A')}
                ${moment.tz(props.eventTimezone).zoneAbbr()}
            `
        : 'later';

    ctaNode = (
      <div className="event__attendee-zoom-cta">
        <div className="event__attendee-check-in-headling v5__heading-5">
          You&apos;re a little early!
        </div>
        <div className="event__attendee-check-in-copy v5__body-copy">
          We&apos;re not quite ready to start today&apos;s session. Come back{' '}
          {tenMinBefore} when the Zoom room opens. We look forward to seeing you
          soon!
        </div>
      </div>
    );
  }

  return <React.Fragment>{ctaNode}</React.Fragment>;
};

export default EventZoomCallToAction;

EventZoomCallToAction.propTypes = {
  activeSession: PropTypes.object.isRequired,
  sessionActivity: PropTypes.object.isRequired,
  checkedIn: PropTypes.bool.isRequired,
};
