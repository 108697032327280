// @ts-nocheck FIXME
import { useLegacyLabContext } from '@contexts/LegacyLabContext';
import ArrayHelpers from '@legacy-components/components/ArrayHelpers';
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import Dropdown from './Dropdown';

const Links = (props) => {
  const lab = useLegacyLabContext();

  const isInternalMember =
    Utils.isAdmin.call(lab) ||
    Utils.isTeamMember.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.isServicesManager.call(lab) ||
    Utils.isContentCurator.call(lab);

  const ResourcesLink = (
    <Dropdown
      navKey={'resources'}
      navOptions={[
        {
          caption: 'Student-Centered Learning',
          link: `${process.env.REACT_APP_MARKETING_URL}/learning-experiences/student-centered-teaching?from=header_link`,
          external: true,
        },
        {
          caption: 'Our Content Partners',
          link: `${process.env.REACT_APP_TEACHING_URL}/browse/content-partners?from=header_link`,
          external: true,
        },
        {
          caption: 'Instructional Strategies',
          link: `${process.env.REACT_APP_TEACHING_URL}/browse/strategies?from=header_link`,
          external: true,
        },
        {
          caption: 'Lesson Plans',
          link: `${process.env.REACT_APP_TEACHING_URL}/browse/master_teachers/projects?from=header_link`,
          external: true,
        },
      ]}
    >
      <div className="header__dropdown-handle">Instructional Resources</div>
    </Dropdown>
  );

  let leaderHubDropdown = null;
  if (
    Utils.isPartnerLeader.call(lab) &&
    ArrayHelpers.hasElements(props.user.leads) &&
    props.user.active_lead === 'v5'
  ) {
    leaderHubDropdown = Utils.hasFeatureFlag.call(lab, 'LEADER_HUB') ? (
      <Dropdown
        navKey={'leader-hub'}
        navOptions={[
          {
            caption: 'Reports',
            link: `${process.env.REACT_APP_REPORTING_URL}`,
            external: true,
          },
          {
            caption: 'Educators',
            link: `${process.env.REACT_APP_REPORTING_URL}/educators`,
            external: true,
          },
          {
            caption: 'Learning Walks',
            link: `${process.env.REACT_APP_LEARNINGWALK_URL}/partner`,
            external: true,
          },
          {
            caption: 'Cohorts & Events',
            link: '/lab/leader/home',
          },
          {
            caption: 'Learning Catalog',
            link: `${process.env.REACT_APP_LAB_URL}/catalog/`,
            newTab: true,
          },
        ]}
      >
        <div className="header__dropdown-handle">Leader Hub</div>
      </Dropdown>
    ) : (
      <NavigationLink className="header__link tc__link" url="/lab/leader/home">
        Dashboard
      </NavigationLink>
    );
  }

  const reportsLink =
    !Utils.hasFeatureFlag.call(lab, 'LEADER_HUB') &&
    Utils.isReportingUser.call(lab) ? (
      <a
        href={process.env.REACT_APP_REPORTING_URL}
        className="header__link tc__link"
      >
        Reports
      </a>
    ) : null;

  // Coach dashboard is visible to BL coaches and DCs.
  const coachDashboardNvOptions = [
    {
      caption: 'Coach Dashboard',
      link: '/lab/coach/home',
    },
  ];

  coachDashboardNvOptions.push({
    caption: 'Surveys',
    link: `${process.env.REACT_APP_SURVEY_URL}/coach`,
  });

  let coachDashboardLink = null;

  if (Utils.isServicesManager.call(lab) || Utils.isContractCoach.call(lab)) {
    coachDashboardLink = (
      <Dropdown navKey={'admin'} navOptions={coachDashboardNvOptions}>
        <div className="header__dropdown-handle">Coaches</div>
      </Dropdown>
    );
  }

  // Workspace is visible to participants
  let workspaceLink = null;
  if (Utils.isParticipant.call(lab)) {
    workspaceLink = (
      <NavigationLink className="header__link tc__link" url={'/lab/workspace'}>
        Workspace
      </NavigationLink>
    );
  }

  const blConnectLink =
    isInternalMember || Utils.isBLConnectUser.call(lab) ? (
      <Dropdown
        navKey={'bl_connect'}
        navOptions={[
          {
            caption: 'BL Connect Courses',
            link: `${process.env.REACT_APP_BL_CONNECT_URL}`,
            external: true,
          },
          {
            caption: 'Course Catalog',
            link: `${process.env.REACT_APP_COURSE_CATALOG_URL}`,
            newTab: true,
          },
        ]}
      >
        <div className="header__dropdown-handle">BL Connect</div>
      </Dropdown>
    ) : null;

  const blLabLink = isInternalMember ? (
    <NavigationLink className="header__link tc__link" url={'/lab/home'}>
      BL Lab Home
    </NavigationLink>
  ) : null;

  const labHomeLink =
    Utils.isParticipant.call(lab) && !isInternalMember ? (
      <NavigationLink className="header__link tc__link" url={'/lab/home'}>
        Lab Home
      </NavigationLink>
    ) : null;

  // Admin pages visible to UMs and Super Admins, Content Curators, Data viewers,
  // Services Managers, and Help Desk Agents
  let managementLink = null;
  // set up the dropdown options based on which role(s) the user has
  const managementNavOptions = [];
  if (
    Utils.isDataViewer.call(lab) ||
    Utils.isServicesManager.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.isAdmin.call(lab) ||
    Utils.isContentCurator.call(lab)
  ) {
    managementNavOptions.push({
      caption: 'Event Capacity Calendar',
      link: '/bl/events/capacity-calendar',
    });
  }
  if (
    Utils.isDataViewer.call(lab) ||
    Utils.isServicesManager.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.isAdmin.call(lab) ||
    Utils.isHelpDeskAgent.call(lab)
  ) {
    managementNavOptions.push({
      caption: 'User Management',
      link: '/bl/users',
    });
  }
  if (
    Utils.isDataViewer.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.isAdmin.call(lab)
  ) {
    managementNavOptions.push({
      caption: 'Cohort Management',
      link: '/bl/cohorts',
    });
  }
  if (
    Utils.isDataViewer.call(lab) ||
    Utils.isServicesManager.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.isAdmin.call(lab) ||
    Utils.isHelpDeskAgent.call(lab)
  ) {
    managementNavOptions.push({
      caption: 'Event Management',
      link: '/bl/events',
    });
  }
  if (
    Utils.isDataViewer.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.isAdmin.call(lab)
  ) {
    managementNavOptions.push({
      caption: 'Partner Management',
      link: '/bl/partners',
    });
  }
  if (
    Utils.isContentCurator.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.isAdmin.call(lab)
  ) {
    managementNavOptions.push({
      caption: 'Learning Domains Curation',
      link: '/bl/curation/domains',
    });
    managementNavOptions.push({
      caption: 'Strategies & Tech Tools Curation',
      link: '/bl/curation/strategies',
    });
  }
  if (
    Utils.isDataViewer.call(lab) ||
    Utils.isServicesManager.call(lab) ||
    Utils.isManager.call(lab) ||
    Utils.isAdmin.call(lab)
  ) {
    managementNavOptions.push({
      caption: 'CoachBase',
      link: 'https://betterlesson.quickbase.com/db/bmvddf9df',
      newTab: true,
    });
    managementNavOptions.push({
      caption: 'Admin Menu',
      link: '/bl/admin/home',
    });
  }

  // if the user has any options to show, include this menu in the header
  if (ArrayHelpers.hasElements(managementNavOptions)) {
    managementLink = (
      <Dropdown navKey={'admin'} navOptions={managementNavOptions}>
        <div className="header__dropdown-handle">Management &amp; Tools</div>
      </Dropdown>
    );
  }

  return (
    <nav className="header__link-container">
      {labHomeLink}
      {coachDashboardLink}
      {managementLink}
      {leaderHubDropdown}
      {reportsLink}
      {blConnectLink}
      {blLabLink}
      {workspaceLink}
      {ResourcesLink}
    </nav>
  );
};

export default Links;
