// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import UserListRow from './UserListRow';

const EventCapsule = (props) => {
  // Declare event time variables
  // Abstract the props for readablity
  const eventTimeZone = props.time_zone && props.time_zone.pytz_timezone;
  const eventEndTime = props.session.session_end_time_formatted;
  const eventStartTime = props.session.session_start_time_formatted;

  const currentTime = moment();
  const tenMinutes = moment.duration(10, 'minutes');

  // set time that the next Session starts
  const startSessionTime = moment(eventStartTime);
  // set time to display button 5 minutes before the Session
  const beforeSessionTime = startSessionTime - tenMinutes;
  // set length of Session
  const endSessionTime = moment(eventEndTime);

  const [sessionStarted, setSessionStarted] = React.useState(
    currentTime.isSameOrAfter(beforeSessionTime) &&
      currentTime.isBefore(endSessionTime)
  );
  const [sessionEnded, setSessionEnded] = React.useState(
    currentTime.isSameOrAfter(endSessionTime)
  );

  React.useEffect(() => {
    // Don't show the icon if start or end time aren't set
    if (!eventStartTime || !eventEndTime) {
      setSessionStarted(false);
      setSessionEnded(true);
      return;
    }

    // If there is no Session or the Session is more than 24 days away, don't do anything
    if (startSessionTime - currentTime >= moment.duration(172800000)) {
      return;
    }

    let startSession;
    let endSession;
    // Select the next Session and calculate the time until it starts
    const timeUntilSessionStart = moment.duration(
      startSessionTime - currentTime
    );
    if (timeUntilSessionStart.asMinutes() <= tenMinutes.asMinutes()) {
      // set a timeout to fire when the Session ends
      endSession = setTimeout(() => {
        setSessionStarted(false);
        setSessionEnded(true);
      }, moment.duration(endSessionTime - currentTime).asMilliseconds());
    } else if (timeUntilSessionStart.asMinutes() > tenMinutes.asMinutes()) {
      // set a timeout to fire when the Session starts
      startSession = setTimeout(() => {
        setSessionStarted(true);
        setSessionEnded(false);
        // set a timeout to fire when the Session ends
        endSession = setTimeout(() => {
          setSessionStarted(false);
          setSessionEnded(true);
        }, moment.duration(endSessionTime - currentTime).asMilliseconds());
      }, timeUntilSessionStart.asMilliseconds() - tenMinutes.asMilliseconds());
    }

    // clear the timeouts on unmount
    return () => {
      clearTimeout(startSession);
      clearTimeout(endSession);
    };
  }, []);

  const hasMaterials = props.session.materials_finalized;

  const checkinIconNode = props.checkin_link ? (
    <div
      copytext={props.checkin_link}
      onClick={props.handleCopy}
      title="Copy check-in link"
      className="coach-event__icon-padding coach-event__icon tc-v5-icon_check-in-link coach-event__copy-link-text"
    />
  ) : null;

  const zoomLink = props.session.zoom_join_link || props.session.zoom_link;
  const zoomLinkIconNode = zoomLink ? (
    <div
      copytext={zoomLink}
      onClick={props.handleCopy}
      title="Copy Zoom link"
      className="coach-event__icon-padding coach-event__icon tc-v5-icon_zoom-link coach-event__copy-link-text"
    />
  ) : null;

  // Map the user_id to a set and get the size to find unique attendees
  const attendees = new Set(
    [...props.rsvps, ...props.participants].map((attendee) => attendee.user_id)
  ).size;

  const attendeesNode = (
    <span className="coach-event__attendee-count">
      {`${attendees} Attendee${attendees !== 1 ? 's' : ''}`}
    </span>
  );

  const sessionUrl = props.session.group_id
    ? `/lab/event/${props.id}/group/${props.session.group_id}/preview`
    : `/lab/event/${props.id}/preview`;

  let startTimeNode = null;

  if (eventStartTime && eventEndTime) {
    let eventTime = null;
    let eventTimeSeparator = null;
    if (eventTimeZone && eventTimeZone !== props.userTimeZone) {
      eventTime = (
        <span className="coach-event__alt-date">
          {Utils.formatEventSessionTime(
            eventStartTime,
            eventEndTime,
            eventTimeZone
          )}
        </span>
      );
      eventTimeSeparator = (
        <span className="coach-event__alt-date separator"> / </span>
      );
    }

    startTimeNode = (
      <React.Fragment>
        <div>
          <span>
            {Utils.formatEventSessionTime(
              eventStartTime,
              eventEndTime,
              props.userTimeZone
            )}
          </span>
          {eventTimeSeparator}
          {eventTime}
        </div>
      </React.Fragment>
    );
  }

  // if the page coach is Tech Support for this session, show the laptop icon
  let techSupportIconNode;
  if (props.facilitator_role === 'TECH') {
    techSupportIconNode = (
      <span
        className="tc-v5-laptop coach-event__tech-icon coach-event__tech-icon--inline"
        data-testid={`session-${props.session.id}-capsule-tech-icon`}
      ></span>
    );
  }

  // Start Session (10-min before through the end of the Session time)
  let activeMeetingIconNode = null;
  if (zoomLink && sessionStarted && !sessionEnded) {
    activeMeetingIconNode = (
      <a
        href={zoomLink}
        target="_blank"
        className="coach-event__cta v5__link"
        rel="noreferrer"
      >
        <span className="tc-v5-video coach-event__cta-icon" />
        <span className="coach-event__cta-copy">Start</span>
      </a>
    );
  }

  let sessionNumNode = null;
  let displayNameNode = null;

  if (
    props.session.num &&
    props.session.num_sessions &&
    parseInt(props.session.num_sessions) > 1
  ) {
    sessionNumNode = (
      <span>
        (Session {props.session.num} of {props.session.num_sessions})
      </span>
    );
    displayNameNode = (
      <div className="v5__body-copy">
        {props.display_name} {sessionNumNode}
      </div>
    );
  }

  // list any session facilitators
  const leadFacilitator = props.session.facilitators.find(
    (f) => f.role === 'LEAD'
  );
  const coFacilitators = props.session.facilitators.filter(
    (f) => f.role === 'CO'
  );
  const techFacilitators = props.session.facilitators.filter(
    (f) => f.role === 'TECH'
  );
  const leadFacilitatorNode = leadFacilitator ? (
    <div className="coach-event__psm-link">
      <span className="coach-event__psm-name">
        {`${leadFacilitator.first_name} ${leadFacilitator.last_name}, `}
      </span>
      <span
        copytext={leadFacilitator.email}
        onClick={props.handleEmailCopy}
        className="coach-event__copy-link-text coach-event__psm-email"
      >
        {leadFacilitator.email}
      </span>
    </div>
  ) : (
    <div className="v5__body-copy--italic">No lead facilitator assigned</div>
  );

  return (
    <div className="v5__capsule v5__capsule--margin-bottom-30">
      <div className="flex-row flex-space-between">
        <div className="decoration-bar decoration-bar--left decoration-bar--agave" />
        <div className="coach-event__left-col">
          <div className="coach-event__time v5__body-copy ">
            {startTimeNode}
          </div>
          <div>
            <div className="coach-event__title v5__heading-4">
              {techSupportIconNode}
              <NavigationLink
                className="coach-event__left-healine"
                url={sessionUrl}
              >
                {Utils.getEventSessionName(
                  props.display_name || props.name,
                  props.session.name,
                  props.session.group && props.session.group.name,
                  props.session.num_sessions
                )}
              </NavigationLink>
              {activeMeetingIconNode}
            </div>
            {displayNameNode}
            <div className="v5__body-copy coach-event__mobile-rsvp hide-desktop show-phone">
              <NavigationLink
                className="v5__link"
                url={`/bl/event/${props.id}/participants`}
              >
                {attendeesNode}
              </NavigationLink>
            </div>
            <div className="coach-event__capsule-associated-users">
              <div className="flex-row">
                <div className="coach-event__associated-users-label">
                  Lead Facilitator:
                </div>
                <div className="coach-event__psms">{leadFacilitatorNode}</div>
              </div>
              <UserListRow
                label="Co-Facilitator"
                labelPlural="Co-Facilitators"
                users={coFacilitators}
                handleEmailCopy={props.handleEmailCopy}
              />
              <UserListRow
                label="Tech Support"
                users={techFacilitators}
                handleEmailCopy={props.handleEmailCopy}
              />
              <UserListRow
                label="PSM"
                labelPlural="PSMs"
                users={props.psms}
                defaultNode={
                  <div className="coach-event__psms v5__body-copy--italic">
                    Information unavailable.
                  </div>
                }
                handleEmailCopy={props.handleEmailCopy}
              />
            </div>
          </div>
        </div>
        <div className="coach-event__right-col flex-col flex-space-between">
          <div className="always-flex-row justify-flex-end">
            {zoomLinkIconNode}
            {checkinIconNode}
            <div className="coach-event__icon-padding">
              <NavigationLink
                url={`/bl/event/${props.id}`}
                title="Edit event materials"
                className={`coach-event__icon${
                  hasMaterials
                    ? ' tc-v5-event-materials'
                    : ' tc-v5-event-materials-missing'
                }`}
              >
                <span className="path1"></span>
                <span className="path2"></span>
              </NavigationLink>
            </div>
          </div>
          <div className="v5__body-copy admin__right-btn-col show-desktop hide-phone">
            <NavigationLink
              className="v5__link"
              url={`/bl/event/${props.id}/participants`}
            >
              {attendeesNode}
            </NavigationLink>
          </div>
        </div>
      </div>
    </div>
  );
};

EventCapsule.propTypes = {
  id: PropTypes.number.isRequired,
  facilitator_role: PropTypes.string,
  checkin_link: PropTypes.string,
  display_name: PropTypes.string,
  psms: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    })
  ),
  rsvps: PropTypes.array,
  session: PropTypes.shape({
    id: PropTypes.number.isRequired,
    end_time: PropTypes.string,
    name: PropTypes.string,
    facilitators: PropTypes.array.isRequired,
    participants: PropTypes.array,
    start_time: PropTypes.string,
    zoom_join_link: PropTypes.string,
    zoom_link: PropTypes.string,
    materials_finalized: PropTypes.string,
  }),
  user: PropTypes.object.isRequired,
};

export default EventCapsule;
