// @ts-nocheck FIXME
import { HeaderConsumer } from '@legacy-components/context/HeaderContext';
import UserPhoto from '@legacy-components/react/V5/Common/UserPhoto';
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React from 'react';

const UserInfo = (props) => {
  const [clipboard, setClipboard] = React.useState();

  React.useEffect(() => {
    setClipboard(
      new Clipboard('.partner_lead_v5__sidebar--email', {
        text: function (trigger) {
          return trigger.innerHTML;
        },
      })
    );

    return () => {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
    };
  }, []);

  const handleClick = () => {
    props.setAlert({ type: 'success', message: 'Email copied to clipboard' });
  };

  return (
    <div className="partner_lead_v5__sidebar--psm-info">
      <UserPhoto
        diameter={40}
        src={props.photo}
        firstName={props.first_name}
        lastName={props.last_name}
      />
      <div>
        <div className="partner_lead_v5__sidebar--name">{`${props.first_name} ${props.last_name}`}</div>
        <div className="partner_lead_v5__sidebar--email" onClick={handleClick}>
          {props.email}
        </div>
      </div>
    </div>
  );
};

UserInfo.propTypes = {
  photo: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const ConnectedUserInfo = (props) => (
  <HeaderConsumer>
    {({ setAlert }) => <UserInfo {...props} setAlert={setAlert} />}
  </HeaderConsumer>
);

export default ConnectedUserInfo;
