// @ts-nocheck FIXME
import Utils from '@legacy-components/components/DynamicUtils';
import ActionModal from '@legacy-components/react/V5/Common/ActionModal';
import FormField from '@legacy-components/react/V5/Common/FormField';
import Spinner from '@legacy-components/react/V5/Common/Spinner';
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ClipPublishModal = (props) => {
  const [description, updateDescription] = useState(props.data.description);
  const [name, updateName] = useState(
    props.data.name ||
      `Clip from ${dateformat(
        props.meeting.meeting_time_formatted,
        'mmmm dd, yyyy'
      )}`
  );
  const [isLoading, updateLoading] = useState(false);
  const [nameError, changeNameError] = useState('');
  const clipNameRef = React.useRef(null);

  useEffect(() => {
    clipNameRef.current.focus();
  }, []);

  // MultiSelect needs to have labeled options
  const labeledGoals = [...props.goals];
  labeledGoals.forEach((goal) => {
    goal.label = goal.title;
  });

  const handleChangeName = (text) => {
    updateName(text);

    if (!text) {
      changeNameError('Hold on, you need to enter a clip name to continue.');
    } else {
      changeNameError('');
    }
  };

  const handleCloseModal = (e) => {
    e.preventDefault();

    props.close();
  };

  const handlePublishClip = (e) => {
    e.preventDefault();

    if (!name) {
      changeNameError('Hold on, you need to enter a clip name to continue.');
      return;
    } else {
      changeNameError('');
    }

    updateLoading(true);

    props.publishClip({
      clipId: props.data.id,
      meetingId: props.meeting.id,
      startTime: Utils.hhmmss(props.data.start_time),
      endTime: Utils.hhmmss(props.data.end_time),
      description: description,
      name: name,
      storyId: props.storyId,
    });
  };

  // disable buttons when submitting
  const btnProps = isLoading ? { disabled: true } : null;

  return (
    <ActionModal
      headlineText={`Add Meeting Clip to ${props.first_name}'s Workspace`}
      close={handleCloseModal}
      spaceFromTop={50}
    >
      <form className="participant-v5__modal-body participant-v5__participant-meeting-clip-modal">
        <div className="workspace__form-row participant-v5__modal-top-row">
          <div className="participant-v5__participant-meeting-clip-headline-text">
            Would you like to make any edits before sharing on{' '}
            {props.first_name}’s workspace?
          </div>
        </div>

        <div className="workspace__form-row">
          <div className="admin__form-label">Clip Name</div>
          <FormField
            type="text"
            disabled={isLoading}
            error={nameError}
            className={'participant-v5__form-input-title'}
            inputClassName="participant-v5__form-input-title"
            defaultValue={name}
            onChange={(e) => handleChangeName(e.target.value)}
            maxLength="256"
            formRef={clipNameRef}
            dataTestId="clip-name-edit"
          />
        </div>

        <div className="workspace__form-row">
          <div className="admin__form-label">Clip Description</div>
          <FormField
            type="textarea"
            disabled={isLoading}
            className={'participant-v5__form-input-title'}
            inputClassName="tc__input admin__form-input participant-v5__meeting-description-input"
            rows={3}
            defaultValue={props.data.description}
            onChange={(e) => updateDescription(e.target.value)}
            dataTestId="clip-description-edit"
          />
        </div>
        <div className="workspace__form-row admin__form-row--centered">
          <button className="v5__btn" onClick={handlePublishClip} {...btnProps}>
            <Spinner loading={isLoading} />
            Add to Workspace
          </button>
        </div>
      </form>
    </ActionModal>
  );
};

ClipPublishModal.propTypes = {
  meeting: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  publishClip: PropTypes.func.isRequired,
};

export default ClipPublishModal;
