// @ts-nocheck FIXME
import moment from 'moment';
import ParticipantApi from './../../apis/ParticipantApi';
import ParticipantMeetingApi from './../../apis/ParticipantMeetingApi';
import HomeApi from './../../apis/V5/HomeApi';
import ArrayHelpers from './../../components/ArrayHelpers';
import Modal from './../../components/Modal';
import { History } from './../../components/Router';
import Utils from './../../components/StaticUtils';
import StorageHelper from './../../components/StorageHelper';
import Store from './../../components/Store';

const store = {
  pageUser: {},
  // coach home
  participants: [],
  // participant edit / meeting add/edit
  participant: {},
  // participant meetings
  meetings: [],
  // meeting add/edit
  meeting: {},
  // meeting clip add/edit
  meetingClip: {},
  // event add/edit
  event: {},

  ui: {
    renderSeed: 0, // This is a hack until I can use hooks
    alert: {},
    coachHome: {
      sortColumn: 'last_name',
      sortDirection: 'ascending',
      showArchived: false,
    },
    detailsOpen: [],
    showMeetingsForTomorrow: false,
    editParticipant: {
      isLoading: false,
      errors: {},
      focusAreas: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
      product: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOption: null,
        visibleOptions: [],
      },
      coach: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOption: null,
        visibleOptions: [],
      },
      importSubject: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOption: null,
        visibleOptions: [],
      },
      importGrade: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOption: null,
        visibleOptions: [],
      },
      availabilityOptions: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOptions: [],
        visibleOptions: [],
      },
      warningModal: {},
    },
    editParticipantZoomLink: {
      opened: false,
      loading: false,
    },
    editParticipantMeeting: {
      isLoading: false,
      focusAreas: {
        inputStatus: 'blur',
        charCount: 0,
        options: [],
        selectedOption: null,
        visibleOptions: [],
      },
    },
    meetingToDelete: null,
    confirmDeleteMeetingModal: {
      callbacks: {
        onOpen: (data) => {
          store.ui.meetingToDelete = data;
        },
        onClose: () => {
          store.ui.meetingToDelete = null;
        },
      },
    },
    invitationDetails: {},
    modalError: null,
    editMeetingClipModal: {},
    publishMeetingClipModal: {
      typeFieldDisabled: false,
      type: 'coachView',
    },
    cohortVision: null,
    artifactModal: {
      callbacks: {
        // onOpen: (data) => {
        // },
        // onClose: () => {
        // }
      },
    },
  },
};

/****** PRIVATE FUNCTIONS ******/
const setAlert = (type, message) => {
  store.ui.alert = {
    type: type,
    message: message,
    handleClose: store.clearAlert,
  };
};

// Put's nulls to front
const sortLastActive = (a, b) => {
  if (!a) {
    if (!b) {
      return 0;
    }
    return 1;
  } else if (!b) {
    return -1;
  }
  if (a > b) {
    return -1;
  } else {
    return 1;
  }
};

const sortLastName = (a, b) => {
  if (!a) {
    if (!b) {
      return 0;
    }
    return 1;
  } else if (!b) {
    return -1;
  }

  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  } else {
    return -1;
  }
};

// Put's nulls to back
const sortMeetings = (a, b, field, invert) => {
  if (!a[field]) {
    if (!b[field]) {
      // make sure last_name exists
      return sortLastName(a['last_name'], b['last_name']);
    }
    return 1;
  } else if (!b[field]) {
    return -1;
  }
  if (
    moment(b[field]['meeting_time']).isBefore(moment(a[field]['meeting_time']))
  ) {
    if (invert) {
      return -1;
    }
    return 1;
  } else {
    if (invert) {
      return 1;
    }
    return -1;
  }
};

const sortActivities = (a, b) => {
  if (!a.activity || !a.registered) {
    if (!b.activity || !b.registered) {
      return 0;
    }
    return 1;
  } else if (!b.activity || !b.registered) {
    return -1;
  } else if (a.activity.date > b.activity.date) {
    return -1;
  } else {
    return 1;
  }
};

const sortSchools = (a, b) => {
  if (!a.institution || !a.institution.name) {
    return 1;
  } else if (!b.institution || !b.institution.name) {
    return -1;
  } else {
    return a.institution.name
      .toLowerCase()
      .localeCompare(b.institution.name.toLowerCase());
  }
};

const cadenceSortValues = {
  WEEKLY: '1',
  'BI-WEEKLY': '2',
  MONTHLY: '3',
  QUARTERLY: '4',
};

// Put's nulls to back
const sortCadence = (a, b) => {
  if (!a['cadence']) {
    if (!b['cadence']) {
      // make sure last_name exists
      return sortLastName(a['last_name'], b['last_name']);
    }
    return 1;
  } else if (!b['cadence']) {
    return -1;
  }
  if (cadenceSortValues[a['cadence']] > cadenceSortValues[b['cadence']]) {
    return 1;
  } else if (
    cadenceSortValues[a['cadence']] < cadenceSortValues[b['cadence']]
  ) {
    return -1;
  } else {
    return sortLastName(a['last_name'], b['last_name']);
  }
};

const initCoachHomeState = () => {
  if (!store.participants) {
    return;
  }

  // keep dashboard sorting in local storage.
  let localSortState;

  // this is the default sort column and direction from backend.
  const defaultSortState = { column: 'last_name', direction: 'ascending' };

  localSortState = StorageHelper.init(
    'coachDashboardSortState',
    defaultSortState
  );

  store.ui.coachHome.sortColumn = localSortState.column;
  store.ui.coachHome.sortDirection = localSortState.direction;

  if (localSortState.column === 'first_name') {
    //sort alphabetically
    store.participants.sort((a, b) => {
      return a.first_name
        .toLowerCase()
        .localeCompare(b.first_name.toLowerCase());
    });
  } else if (localSortState.column === 'last_name') {
    // Sort by first name first so that participants with the same last name get sorted properly
    store.participants.sort((a, b) => {
      return a.first_name
        .toLowerCase()
        .localeCompare(b.first_name.toLowerCase());
    });
    store.participants.sort((a, b) => {
      return a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase());
    });
  } else if (localSortState.column === 'email') {
    store.participants.sort((a, b) => {
      return a.email.toLowerCase().localeCompare(b.email.toLowerCase());
    });
  } else if (localSortState.column === 'cohort') {
    store.participants.sort((a, b) => {
      return a.cohort.toLowerCase().localeCompare(b.cohort.toLowerCase());
    });
  } else if (localSortState.column === 'school') {
    store.participants.sort(sortSchools);
  } else if (localSortState.column === 'next_meeting') {
    store.participants.sort((a, b) =>
      sortMeetings(a, b, 'next_meeting', false)
    );
  } else if (localSortState.column === 'last_meeting') {
    store.participants.sort((a, b) => sortMeetings(a, b, 'last_meeting', true));
  } else if (localSortState.column === 'last_active') {
    store.participants.sort((a, b) =>
      sortLastActive(a.last_active, b.last_active)
    );
  } else if (localSortState.column === 'activity') {
    store.participants.sort(sortActivities);
  } else if (localSortState.column === 'highlights') {
    store.participants.sort((a, b) => b.highlights - a.highlights);
  } else if (localSortState.column === 'cadence') {
    store.participants.sort((a, b) => sortCadence(a, b));
  }

  if (localSortState.direction === 'descending') {
    store.participants.reverse();
  }

  // set show archived from local storage, if any
  const defaultShowArchived = false;
  const localShowArchived = StorageHelper.init(
    'coachHomeShowArchived',
    defaultShowArchived
  );
  store.ui.coachHome.showArchived = localShowArchived;
};

store.setFilter = (filterText) => {
  store.ui.filter = filterText || null;
};

const initEditParticipantUi = (data) => {
  if (
    !data.allProducts ||
    !data.allFocusAreas ||
    !data.allCoaches ||
    !data.participant
  ) {
    return;
  }

  const feature = store.ui.editParticipant;

  feature.product.selectedOption = data.participant.product;
  feature.product.options = data.allProducts;

  feature.focusAreas.selectedOptions = data.participant.focusAreas;
  feature.focusAreas.options = data.allFocusAreas;

  feature.coach.selectedOption = data.participant.coach;
  feature.coach.options = data.allCoaches;

  feature.importSubject.selectedOption = data.participant.importSubject;
  feature.importSubject.options = data.allImportSubjects;

  feature.importGrade.selectedOption = data.participant.importGrade;
  feature.importGrade.options = data.allImportGrades;

  feature.availabilityOptions.selectedOptions = data.participant.availabilities;
  feature.availabilityOptions.options = data.availabilityOptions;

  store.ui.editParticipant.warningModal = Modal(
    store,
    store.ui.editParticipant.warningModal
  );
};

const initEditParticipantMeetingUI = (data) => {
  if (!data.meeting || !data.participant) {
    return;
  }

  // init modals
  store.ui.editMeetingClipModal = Modal(store, store.ui.editMeetingClipModal);
  store.ui.publishMeetingClipModal = Modal(
    store,
    store.ui.publishMeetingClipModal
  );

  store.ui.editParticipantMeeting.focusAreas.selectedOptions =
    data.meeting && data.meeting.focusAreas ? data.meeting.focusAreas : [];
  store.ui.editParticipantMeeting.focusAreas.options =
    data.participant.focusAreas;
};

/****** EXTERNAL FUNCTIONS ******/
store.init = (data) => {
  store.user = data.user;
  store.ui.alert = {};
  store.pageUser = data.pageUser;
  store.coachingEngagements = data.coachingEngagements;
  // coach home
  store.participants = data.participants;
  // participant edit/meetings
  store.participant = data.participant;
  // participant meetings
  store.meetings = data.meetings;
  // participant meeting
  store.meeting = data.meeting;
  // event
  store.event = data.event;
  // groupFocusAreas
  store.groupFocusAreas = data.groupFocusAreas;

  initCoachHomeState();
  initEditParticipantUi(data);
  initEditParticipantMeetingUI(data);

  store.ui.artifactModal = Modal(store, store.ui.artifactModal);

  store.ui.confirmDeleteMeetingModal = Modal(
    store,
    store.ui.confirmDeleteMeetingModal
  );

  // on Coach Home V5, if we failed to connect the user's Zoom account, show the error banner
  if (data.zoom_status === 'error') {
    store.setErrorAlert(
      'Sorry, we were unable to connect to your Zoom account. Please try again later.'
    );
  }

  return false;
};

store.setErrorAlert = (message) => {
  setAlert('error', message);
};

store.setSuccessAlert = (message) => {
  setAlert('success', message);
};

store.clearAlert = () => {
  store.ui.alert = {};
};

store.sortBy = (column) => {
  //if we are resorting by same column
  if (store.ui.coachHome.sortColumn === column) {
    if (store.ui.coachHome.sortDirection === 'ascending') {
      store.ui.coachHome.sortDirection = 'descending';
      store.participants.reverse();
    } else {
      store.ui.coachHome.sortDirection = 'ascending';
      store.participants.reverse();
    }
  } else {
    //always default sort new columns ascending
    store.ui.coachHome.sortDirection = 'ascending';
    store.ui.coachHome.sortColumn = column;

    if (column === 'first_name') {
      //sort alphabetically
      store.participants.sort((a, b) => {
        return a.first_name
          .toLowerCase()
          .localeCompare(b.first_name.toLowerCase());
      });
    } else if (column === 'last_name') {
      store.participants.sort((a, b) => {
        return a.first_name
          .toLowerCase()
          .localeCompare(b.first_name.toLowerCase());
      });
      store.participants.sort((a, b) => {
        return a.last_name
          .toLowerCase()
          .localeCompare(b.last_name.toLowerCase());
      });
    } else if (column === 'email') {
      store.participants.sort((a, b) => {
        return a.email.toLowerCase().localeCompare(b.email.toLowerCase());
      });
    } else if (column === 'cohort') {
      store.participants.sort((a, b) => {
        return a.cohort.toLowerCase().localeCompare(b.cohort.toLowerCase());
      });
    } else if (column === 'school') {
      store.participants.sort(sortSchools);
    } else if (column === 'next_meeting') {
      store.participants.sort((a, b) =>
        sortMeetings(a, b, 'next_meeting', false)
      );
    } else if (column === 'last_meeting') {
      store.participants.sort((a, b) =>
        sortMeetings(a, b, 'last_meeting', true)
      );
    } else if (column === 'last_active') {
      store.participants.sort((a, b) =>
        sortLastActive(a.last_active, b.last_active)
      );
    } else if (column === 'activity') {
      store.participants.sort(sortActivities);
    } else if (column === 'highlights') {
      store.participants.sort((a, b) => b.highlights - a.highlights);
    } else if (column === 'cadence') {
      store.participants.sort((a, b) => sortCadence(a, b));
    }
  }

  const localSortState = {
    column: store.ui.coachHome.sortColumn,
    direction: store.ui.coachHome.sortDirection,
  };

  StorageHelper.setItem('coachDashboardSortState', localSortState);

  return true;
};

store.sortByCoachingEngagements = (column) => {
  //if we are resorting by same column
  if (store.ui.coachHome.sortColumn === column) {
    if (store.ui.coachHome.sortDirection === 'ascending') {
      store.ui.coachHome.sortDirection = 'descending';
      store.coachingEngagements.reverse();
    } else {
      store.ui.coachHome.sortDirection = 'ascending';
      store.coachingEngagements.reverse();
    }
  } else {
    //always default sort new columns ascending
    store.ui.coachHome.sortDirection = 'ascending';
    store.ui.coachHome.sortColumn = column;

    if (column === 'first_name') {
      //sort alphabetically
      store.coachingEngagements.sort((a, b) => {
        return a.participant.first_name
          .toLowerCase()
          .localeCompare(b.participant.first_name.toLowerCase());
      });
    } else if (column === 'last_name') {
      store.coachingEngagements.sort((a, b) => {
        return a.participant.first_name
          .toLowerCase()
          .localeCompare(b.participant.first_name.toLowerCase());
      });
      store.coachingEngagements.sort((a, b) => {
        return a.participant.last_name
          .toLowerCase()
          .localeCompare(b.participant.last_name.toLowerCase());
      });
    } else if (column === 'email') {
      store.coachingEngagements.sort((a, b) => {
        return a.participant.email
          .toLowerCase()
          .localeCompare(b.participant.email.toLowerCase());
      });
    } else if (column === 'cohort') {
      store.coachingEngagements.sort((a, b) => {
        return a.cohort.cohort_name
          .toLowerCase()
          .localeCompare(b.cohort.cohort_name.toLowerCase());
      });
    } else if (column === 'school') {
      store.coachingEngagements.sort(sortSchools);
    } else if (column === 'next_meeting') {
      store.coachingEngagements.sort((a, b) =>
        sortMeetings(a.participant, b.participant, 'next_meeting', false)
      );
    } else if (column === 'last_meeting') {
      store.coachingEngagements.sort((a, b) =>
        sortMeetings(a, b, 'last_meeting', true)
      );
    } else if (column === 'last_active') {
      store.coachingEngagements.sort((a, b) =>
        sortLastActive(a.last_active, b.last_active)
      );
    } else if (column === 'activity') {
      store.coachingEngagements.sort(sortActivities);
    } else if (column === 'highlights') {
      store.coachingEngagements.sort((a, b) => b.highlights - a.highlights);
    } else if (column === 'cadence') {
      store.coachingEngagements.sort((a, b) => sortCadence(a, b));
    }
  }

  const localSortState = {
    column: store.ui.coachHome.sortColumn,
    direction: store.ui.coachHome.sortDirection,
  };

  StorageHelper.setItem('coachDashboardSortState', localSortState);

  return true;
};

store.toggleShowingArchived = () => {
  const newValue = !store.ui.coachHome.showArchived;
  store.ui.coachHome.showArchived = newValue;
  StorageHelper.setItem('coachHomeShowArchived', newValue);
  return true;
};

store.toggleShowingMeetingsForTomorrow = () => {
  store.ui.showMeetingsForTomorrow = !store.ui.showMeetingsForTomorrow;
};

store.toggleShowingDetails = (id) => {
  // this is for toggling the participant details on mobile
  // I'm doing it this way in case we ever want to show more than one open at a time -JR
  if (store.ui.detailsOpen.indexOf(id) === -1) {
    store.ui.detailsOpen = [id];
  } else {
    store.ui.detailsOpen = [];
  }
};

store.editParticipant = (data) => {
  const feature = store.ui.editParticipant;

  data = {
    product_id: feature.product.selectedOption
      ? feature.product.selectedOption.id
      : null,
    focusAreas: feature.focusAreas.selectedOptions.map((option) => option.id),
    participantId: store.participant.id,
    coachId: feature.coach.selectedOption
      ? feature.coach.selectedOption.id
      : null,
    importSubjectId: feature.importSubject.selectedOption
      ? feature.importSubject.selectedOption.id
      : null,
    importGradeId: feature.importGrade.selectedOption
      ? feature.importGrade.selectedOption.id
      : null,
    participantAvailabilities: {},
    actionConfirmed: data ? data.actionConfirmed : false,
  };

  feature.availabilityOptions.selectedOptions.forEach(
    (availability) => (data.participantAvailabilities[availability.id] = 'yes')
  );

  const removedFocusAreaIds = [];

  store.participant.focusAreas.forEach((focusArea) => {
    if (!Utils.inArray(focusArea.id, data.focusAreas)) {
      removedFocusAreaIds.push(focusArea.id);
    }
  });

  if (!ArrayHelpers.isEmpty(removedFocusAreaIds) && !data.actionConfirmed) {
    feature.warningModal.open();
    return;
  }

  if (!data.product_id) {
    feature.errors.product = 'Please select a BL product';
    return;
  }

  feature.isLoading = true;
  feature.warningModal.close();

  return ParticipantApi.editParticipantData(data).then(
    (response) => {
      feature.isLoading = false;
      store.participant = response.participant;
      setAlert('success', 'Participant updated successfully');
    },
    (response) => {
      feature.isLoading = false;
      setAlert(
        'error',
        (response && response.error) ||
          'Sorry, we could not save the participant. Please try again later.'
      );
    }
  );
};

/***** Single Select and Multi Select dependencies ****/
store.updateSelect = (data) => {
  Object.assign(store.ui[data.feature][data.field], data.data);
};

store.getVisibleOptions = (data) => {
  const field = store.ui[data.feature][data.field];
  field.charCount = data.input.length;

  // only show options that contain the input text
  field.visibleOptions = field.options.filter((option) => {
    return option.label.toLowerCase().indexOf(data.input.toLowerCase()) !== -1;
  });
};

// clear the errors when input is focused on
store.clearErrorsOnFocus = (data) => {
  if (data.field in store.ui[data.feature].errors) {
    delete store.ui[data.feature].errors[data.field];
    return true;
  }
  return false;
};

// resend the invitation email
store.resendInvitationEmail = (data) => {
  ParticipantApi.resendTeacherInviteEmail(data)
    .then(() => {
      // show success
      setAlert('success', 'Invitation link Sent successfully.');
      // Analytics.logEvent({
      //     category: 'dt_registration_email',
      //     action: 'send',
      //     label: 'from_resend_dt_registration_email_modal'
      // });
      store.ui.confirmSendInvitationModal.close();
    })
    .catch((response) => {
      store.ui.modalError = response.error;
    });
};

store.toggleModal = (feature) => {
  const opened = store.ui[feature].opened;
  // reset ui
  // Object.assign(store.ui[feature], Utils.clone(defaultUi[feature]));
  // toggle modalDisplayed value
  store.ui[feature].opened = !opened;
};

store.toggleEditParticipantZoomLink = () => {
  store.ui.editParticipantZoomLink.opened =
    !store.ui.editParticipantZoomLink.opened;
};

store.saveParticipantZoomLink = (data) => {
  store.ui.editParticipantZoomLink.loading = true;

  return ParticipantApi.editParticipantData(data).then(
    () => {
      store.ui.editParticipantZoomLink.loading = false;
      store.ui.editParticipantZoomLink.opened = false;

      // update state
      store.participant.zoom_link = data.zoomLink;

      // show success
      setAlert('success', 'Zoom link updated.');
    },
    () => {
      store.ui.editParticipantZoomLink.loading = false;
      store.ui.editParticipantZoomLink.opened = false;
      // show success
      setAlert('error', 'Something went wrong. Please try again.');
    }
  );
};

store.deleteParticipantMeeting = () => {
  return ParticipantMeetingApi.delete(store.ui.meetingToDelete).then(
    (response) => {
      // update meetings state to remove meeting
      store.meetings = response.meetings;

      // show success
      setAlert('success', 'Meeting deleted successfully.');
    },
    () => {
      // show success
      setAlert('error', 'Something went wrong. Please try again.');
    }
  );
};

store.saveParticipantMeeting = (meeting) => {
  const feature = store.ui.editParticipantMeeting;

  feature.isLoading = true;

  if (meeting.meetingId) {
    return ParticipantMeetingApi.edit(meeting).then(
      () => {
        feature.isLoading = false;

        // show success
        setAlert('success', 'Meeting edited successfully.');
      },
      (response) => {
        store.setErrorAlert(response.error);

        feature.isLoading = false;
      }
    );
  } else {
    return ParticipantMeetingApi.add(meeting).then(
      (response) => {
        feature.isLoading = false;
        // update meeting state to have the meeting id
        store.meeting = {
          ...store.meeting,
          ...response.meeting,
          ...{ clips: [] },
        };

        // update history
        const updatedUrl =
          '/bl/participant/' +
          store.participant.id +
          '/meeting/' +
          store.meeting.id;
        History.replace(null, document.title, updatedUrl);

        // show success
        setAlert('success', 'Meeting added successfully.');
      },
      (response) => {
        store.setErrorAlert(response.error);

        feature.isLoading = false;
      }
    );
  }
};

store.saveParticipantMeetingClip = (clip) => {
  const feature = store.ui.editMeetingClipModal;

  feature.loading = true;

  if (clip.clipId) {
    return ParticipantMeetingApi.editClip(clip).then(
      (response) => {
        feature.loading = false;
        feature.close();

        // update meeting state to get any meeting updates
        store.meeting = Object.assign({}, store.meeting, response.meeting);

        // show success
        setAlert('success', 'Clip edited successfully.');
      },
      () => {
        feature.loading = false;
      }
    );
  } else {
    return ParticipantMeetingApi.addClip(clip).then(
      (response) => {
        feature.loading = false;
        feature.close();

        // update meeting state to get any meeting updates
        store.meeting = Object.assign({}, store.meeting, response.meeting);

        // show success
        setAlert('success', 'Clip added successfully.');
      },
      () => {
        feature.loading = false;
      }
    );
  }
};

store.deleteParticipantMeetingClip = (clip) => {
  return ParticipantMeetingApi.deleteClip(clip).then(
    (response) => {
      // update meeting state to remove clip
      store.meeting = Object.assign({}, store.meeting, response.meeting);

      // show success
      setAlert('success', 'Clip deleted successfully.');
    },
    () => {
      // show success
      setAlert('error', 'Something went wrong. Please try again.');
    }
  );
};

store.setClipTypeFieldDisabled = (val) => {
  // certain publishing destinations can only have "artifact" clips
  store.ui.publishMeetingClipModal.typeFieldDisabled = val;
  if (val) {
    store.ui.publishMeetingClipModal.type = 'artifact';
  }
};

store.setClipType = (val) => {
  store.ui.publishMeetingClipModal.type = val;
};

store.publishParticipantMeetingClip = (clip) => {
  return ParticipantMeetingApi.publishClip(clip).then(
    (response) => {
      // update meeting state to add link to list of links and update count
      store.meeting = Object.assign({}, store.meeting, response.meeting);
      // show success
      setAlert('success', 'Clip published successfully.');

      store.ui.publishMeetingClipModal.close();
    },
    () => {
      setAlert('error', 'Something went wrong. Please try again.');
      store.ui.publishMeetingClipModal.close();
    }
  );
};

store.updateMeetingStatus = (data) => {
  store.meeting.status = data.status;
};

store.toggleModal = (feature) => {
  const opened = store.ui[feature].opened;
  // reset ui
  Object.assign(store.ui[feature], Utils.clone(store.ui[feature]));
  // toggle modalDisplayed value
  store.ui[feature].opened = !opened;
};

store.openCohortVision = (data) => {
  store.ui.cohortVision = data.cohortVision;
  store.ui.cohortName = data.cohortName;
  store.ui.artifactModal.open();
};

/***** Coach Home v5 *****/
store.disconnectZoom = () => {
  store.pageUser.has_synced_zoom = false;
  store.setSuccessAlert(
    'We have disconnected your BetterLesson account from Zoom.'
  );
};

store.revokeGoogleCredentials = (userId) => {
  return HomeApi.revokeGoogleCredentials({ userId: userId }).then(
    () => {
      store.pageUser.has_synced_google_calendar = false;

      // show success
      setAlert(
        'success',
        'We have disconnected your BetterLesson account from Google Calendar'
      );
    },
    (response) => {
      // show success
      setAlert('error', response.error);
    }
  );
};

export default Store('AdminStore', store);
