// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import moment from 'moment-timezone';
import React from 'react';
import CoachMatchBLExperience from './modules/CoachMatchBLExperience';
import CoachMatchCohort from './modules/CoachMatchCohort';
import CoachMatchDetails from './modules/CoachMatchDetails';
import CoachMatchUser from './modules/CoachMatchUser';

const CoachMatchCapsule = (props) => {
  // This is the name of the person to contact in case of problems with coach match
  const leadContact = 'Jessi Anderson';

  const handleSetExpandedDetails = () => {
    props.handleSetExpandedDetails(props.id);
  };

  const handleAcceptClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    props.handleSetSelectedMatchForAction({
      id: props.id,
      participantId: props.participant.id,
      action: 'accept',
    });
  };

  const handleDeclineClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    props.handleSetSelectedMatchForAction({
      id: props.id,
      action: 'decline',
    });
  };

  const handleRemoveParticipant = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    props.handleSetSelectedMatchForAction({
      id: props.id,
      action: 'remove',
    });
  };

  let matchNoteNode = null;
  let matchIconNode = null;
  if (props.cb_note) {
    matchNoteNode = (
      <React.Fragment>
        <div className="coach-match__headline coach-match__headline--deep-ocean v5__heading-5">
          Match Notes
        </div>
        <div className="coach-match__row">{props.cb_note}</div>
      </React.Fragment>
    );
    matchIconNode = <div className="coach-match__note-icon tc-note" />;
  }

  let infoNode = null;
  if (props.cb_status === 'Match Retracted') {
    infoNode = (
      <div className="v5__heading-6 coach-match__head--info">
        <span className="tc-v5-alert red"></span>Requesting new coach
      </div>
    );
    matchIconNode = (
      <div className="coach-match__head--remove-participant">
        <button
          type="button"
          className="v5__btn"
          onClick={handleRemoveParticipant}
        >
          Remove Participant
        </button>
      </div>
    );
  }

  // matching experation display
  const matchDateFormatted = moment(props.created).format('MMMM Do YYYY');
  const currentTime = moment();
  const matchDateWindow = moment(props.created).add(1, 'day');
  let matchDateNode = (
    <div>
      <div className="coach-match__proposed-time">
        Proposed {matchDateFormatted}
      </div>
      {matchIconNode}
    </div>
  );
  if (currentTime.isAfter(matchDateWindow)) {
    matchDateNode = (
      <div>
        <div className="coach-match__proposed-time coach-match__proposed-time--expired">
          Expired Match: {matchDateFormatted}
        </div>
        {matchIconNode}
      </div>
    );
  }

  let matchDetailsNode = null;
  if (props.cb_status === 'Match Retracted') {
    matchDetailsNode = (
      <div className="coach-match__match-retracted-details">
        <div className="v5__body-copy v5__body-copy--bold">
          Reason for Request
        </div>
        {props.cb_note}
        <div className="coach-match__match-retracted-contact">
          If you have questions or concerns about this request, contact{' '}
          {leadContact}.
        </div>
      </div>
    );
  } else {
    matchDetailsNode = (
      <React.Fragment>
        <CoachMatchCohort
          pageUser={props.pageUser}
          handleSetExpandedDetails={handleSetExpandedDetails}
          cohort={props.cohort}
          participantId={props.participant.id}
          expandedDetails={props.expandedDetails === props.id}
        />
        <div
          className={`coach-match__extended-details ${
            props.expandedDetails === props.id
              ? 'coach-match__extended-details--display'
              : 'coach-match__extended-details--hidden'
          }`}
        >
          {matchNoteNode}
          <CoachMatchDetails
            coachTimeZone={props.coachTimeZone}
            user={props.user}
            participant={props.participant}
          />
          <CoachMatchBLExperience
            user={props.user}
            userId={props.participant.user_id}
            participant={props.participant}
            cohort={props.cohort}
            events={props.events}
            archivedCoaching={props.archivedCoaching}
          />
          <section className="coach-match__row coach-match__row--buttons coach-match__row--space-between">
            <div className="coach-match__col--50">
              <NavigationLink
                url={`/lab/coach/${props.pageUser.id}/match/${props.participant.id}/details`}
                className="v5__btn v5__btn--blue-border"
              >
                <span className="coach-match__btn-icon tc-v5-details" />
                <span>View Match Details</span>
              </NavigationLink>
            </div>
            <div className="coach-match__btn-group coach-match__col--25">
              <button
                onClick={handleAcceptClick}
                className="coach-match__btn v5__btn v5__btn--blue"
              >
                Accept
              </button>
              <button
                onClick={handleDeclineClick}
                className="coach-match__btn v5__btn v5__btn--blue-border"
              >
                Decline
              </button>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="v5__capsule v5__capsule--padding-0 v5__capsule--margin-bottom-30 v5__body-copy">
      <div className="coach-match__head">
        <CoachMatchUser user={props.user} participant={props.participant}>
          {infoNode}
        </CoachMatchUser>
        {matchDateNode}
      </div>
      <div className="coach-match__details">{matchDetailsNode}</div>
    </div>
  );
};
export default CoachMatchCapsule;
