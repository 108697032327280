// @ts-nocheck FIXME
import { Router } from '@legacy-components/components/Router';
import PurifiedHTML from '@legacy-components/react/Utilities/PurifiedHTML';
import BrowserStore from '@legacy-components/stores/StaticSite/BrowserStore';
import React from 'react';

class WideMetaStrategyCapsule extends React.Component {
  state = {};

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Router.route(this.props.url);
  };

  getStrategyCountNode = () => {
    const count = this.props.metaStrategy.strategy_implementations_count;
    const countSuffix = count === 1 ? 'Strategy' : 'Strategies';
    return (
      <div className="tc__body-copy wide-meta-capsule-v2__strategy-count">
        <a
          className="wide-meta-capsule-v2__strategy-count-link"
          onClick={this.handleClick}
        >
          {count} {countSuffix}
        </a>
      </div>
    );
  };

  handleShowAlert = (type, message) => {
    BrowserStore.actionDispatchSync('setAlert', {
      alert: message,
      alertType: type,
    });
  };

  render() {
    const strategyCountNode = this.getStrategyCountNode();
    const metaStrategyDescriptionNode = (
      <PurifiedHTML
        className="tc__body-copy wide-meta-capsule-v2__body"
        content={this.props.metaStrategy.description}
      />
    );

    return (
      <div
        className="tc__capsule tc__capsule--hoverable wide-meta-capsule-v2"
        onClick={this.handleClick}
      >
        <div className="wide-meta-capsule-v2__content">
          <div className="wide-meta-capsule-v2__text">
            <div>
              <div className="tc__capsule-headline wide-meta-capsule-v2__headline">
                <a onClick={this.handleClick}>{this.props.metaStrategy.name}</a>
              </div>
              {metaStrategyDescriptionNode}
              {strategyCountNode}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WideMetaStrategyCapsule;
