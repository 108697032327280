// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import NavigationLink from './../../../NavigationLink';

const BlendedMenu = (props) => {
  const blendedCategoriesNode = props.categories.map((category) => {
    const subCategoriesNode = category.categories.map((subCategory) => {
      const topicsNode = subCategory.topics.map((topic) => {
        return (
          <div
            className="standards__megamenu-link"
            key={'blended-topic' + topic.id}
          >
            <NavigationLink
              className="tc__link"
              url={
                process.env.REACT_APP_TEACHING_URL +
                '/browse/blended_topic/' +
                topic.id +
                '/' +
                topic.seo_url +
                '?from=content_area_blended'
              }
            >
              {topic.name}
            </NavigationLink>
          </div>
        );
      });

      let expandedBlockClass = '';
      let mobileIconExpandClass = ' standards__megamenu-standards-icon--expand';
      if (props.expandedMobileStandardsBlock == subCategory.id) {
        expandedBlockClass = ' standards__megamenu-standard-block--expanded';
        mobileIconExpandClass = ' standards__megamenu-standards-icon--collapse';
      }

      return (
        <div
          className="standards__megamenu-standard"
          key={'subCategory-' + subCategory.id}
          onClick={() => {
            props.expandMobileStandardsBlock({ blockId: subCategory.id });
          }}
        >
          <h3 className="standards__megamenu-subheadline">
            {subCategory.name}
          </h3>
          <div
            className={
              'standards__megamenu-standard-block' + expandedBlockClass
            }
          >
            {topicsNode}
          </div>
          <div
            className={
              'standards__megamenu-standards-icon' + mobileIconExpandClass
            }
          ></div>
        </div>
      );
    });
    return (
      <div
        className="standards__megamenu-blended-category"
        key={'category-' + category.id}
      >
        <h2 className="standards__megamenu-blended-headline">
          {category.name}
        </h2>
        <div className="standards__megamenu-blended-description">
          {category.description}
        </div>
        <div className="standards__megamenu-blended-category-items ">
          {subCategoriesNode}
        </div>
      </div>
    );
  });
  const megaMenuActiveClass = props.megaMenuOpen ? ' active' : '';
  return (
    <div className={'standards__megamenu row row--1200' + megaMenuActiveClass}>
      <h2 className="standards__megamenu-headline">Blended Learning</h2>
      <div className="standards__megamenu-items">{blendedCategoriesNode}</div>
    </div>
  );
};
BlendedMenu.propTypes = {
  categories: PropTypes.array.isRequired,
  expandedMobileStandardsBlock: PropTypes.number,
  expandMobileStandardsBlock: PropTypes.func.isRequired,
  megaMenuOpen: PropTypes.bool,
};
export default BlendedMenu;
