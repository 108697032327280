// @ts-nocheck FIXME
import Request from './../../components/Request';

const LessonApi = {
  getSimilarLessonCount: (lessonId) => {
    return Request.get(
      process.env.REACT_APP_LAB_SERVICE_URL + '/lesson/similar/' + lessonId
    );
  },
};

export default LessonApi;
