// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import LessonLink from './../../../LessonLink';
import UserPhoto from './../../../V4/Common/UserPhoto';

const SimilarLessons = (props) => {
  if (!props.similarLessons || props.similarLessons.lenght < 1) {
    return null;
  }
  let similarLessons;
  if (props.similarLessons) {
    similarLessons = props.similarLessons.map((lesson) => {
      let userLocation, userEnv;
      if (lesson.School.Location_City || lesson.School.Location_State) {
        userLocation = (
          <div>
            {lesson.School.Location_City} {lesson.School.Location_State}
          </div>
        );
      }
      if (lesson.User.environment) {
        userEnv = <div>{lesson.User.environment}</div>;
      }

      const subjectIcons = {
        math: ' tc-v4-math',
        science: ' tc-v4-science',
        ela: ' tc-v4-ela',
      };

      return (
        <div
          className="cute-4-desktop cute-12-phone"
          key={'similar-lesson-' + lesson.Lesson.id}
        >
          <LessonLink
            url={
              process.env.REACT_APP_TEACHING_URL +
              '/lesson/' +
              lesson.Lesson.id +
              '/' +
              lesson.Lesson.seo_url +
              '?from=mtp_lesson'
            }
            lesson={lesson.Lesson.id}
          >
            <div className="lesson__similar bl4__capsule bl4-capsule--hoverable">
              <div
                className={
                  'lesson__similar-icon' + subjectIcons[props.mtpSubject]
                }
              />
              <div className="lesson__similar-details">
                <div className="lesson__similar-title tc__link">
                  {lesson.Lesson.name}
                </div>
                <div className="lesson__similar-crumbs">
                  {lesson.Course.name} &raquo; {lesson.Unit.name}
                </div>
              </div>
              <div className="lesson__similar-author">
                <UserPhoto
                  src={lesson.User.photo}
                  diameter={55}
                  className="lesson__similar-author-photo"
                  alt={lesson.User.first_name + ' ' + lesson.User.last_name}
                />
                <div className="lesson__similar-author-details">
                  <div>
                    {lesson.User.first_name + ' ' + lesson.User.last_name}
                  </div>
                  {userLocation}
                  {userEnv}
                </div>
              </div>
            </div>
          </LessonLink>
        </div>
      );
    });
  }
  return (
    <div className="lesson__similar-lessons">
      <h3 className="lesson__similar-lessons-headline tc__center-text">
        Related Lessons
      </h3>
      <div className="row">{similarLessons}</div>
    </div>
  );
};
SimilarLessons.propTypes = {
  similarLessons: PropTypes.array,
};
export default SimilarLessons;
