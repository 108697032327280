// @ts-nocheck FIXME
import Request from './../components/Request';

const CohortApi = {
  createCohort: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL + '/cohort/create',
      JSON.stringify(data)
    ),

  editCohort: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/edit',
      JSON.stringify(data)
    ),

  deleteCohort: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/delete',
      JSON.stringify(data)
    ),

  addCohortParticipant: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/participant/add',
      JSON.stringify(data)
    ),

  moveCohortParticipant: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/participant/move',
      JSON.stringify(data)
    ),

  sendWelcomeEmails: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/send-welcome-emails',
      JSON.stringify(data)
    ),

  deleteCohortParticipant: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/participant/' +
        data.participantId +
        '/delete',
      JSON.stringify(data)
    ),

  deleteCohortCoach: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/coach/' +
        data.coachId +
        '/delete',
      JSON.stringify(data)
    ),

  editCohortVision: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/vision/edit',
      JSON.stringify(data)
    ),

  addCohortUpdate: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/update/add',
      JSON.stringify(data)
    ),

  editCohortUpdate: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/update/' +
        data.updateId +
        '/edit',
      JSON.stringify(data)
    ),

  deleteCohortUpdate: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/update/' +
        data.updateId +
        '/delete',
      JSON.stringify(data)
    ),

  addCohortReport: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/report/add',
      JSON.stringify(data)
    ),

  editCohortReport: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/report/' +
        data.reportId +
        '/edit',
      JSON.stringify(data)
    ),

  deleteCohortReport: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/report/' +
        data.reportId +
        '/delete',
      JSON.stringify(data)
    ),

  updateCoaches: (data) =>
    Request.post(
      process.env.REACT_APP_LAB_SERVICE_URL +
        '/cohort/' +
        data.cohortId +
        '/update_coach_selections',
      JSON.stringify(data)
    ),
};

export default CohortApi;
