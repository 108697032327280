// @ts-nocheck FIXME
import NavigationLink from '@legacy-components/react/NavigationLink';
import PropTypes from 'prop-types';
import React from 'react';

const Domains = (props) => {
  return (
    <React.Fragment>
      {props.domains.map((domain, i) => {
        return (
          <div
            data-test-id="domain"
            className="initiative__domain"
            key={`domain-${domain.id}-${i}`}
          >
            <div className="initiative__domain-header">
              <div>
                <div
                  data-test-id="icon"
                  className={'initiative__domain-icon-container'}
                >
                  <div className={`tc-v4-domain-${domain.id}`} />
                </div>
              </div>
              <div className="initiative__domain-title">
                <NavigationLink
                  data-test-id="titleLink"
                  url={`${process.env.REACT_APP_TEACHING_URL}/browse/learning-domain/${domain.id}`}
                  className="v5__link"
                >
                  {domain.title}
                </NavigationLink>
              </div>
            </div>
            <div
              data-test-id="tagline"
              className="initiative__domain-tagline v5__comment-copy"
            >
              {domain.tagline}
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default Domains;

Domains.propTypes = {
  domains: PropTypes.array.isRequired,
};
