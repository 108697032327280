// @ts-nocheck FIXME
import PropTypes from 'prop-types';

const CheckArrows = (props) => {
  // show the up/down arrows if we are reordering
  if (props.reorderActive) {
    // if an arrow is disabled, grey it out and make it unclickable
    const upArrowClass = props.disableUp ? ' disabled' : '';
    const downArrowClass = props.disableDown ? ' disabled' : '';

    return (
      <div>
        <div className="p-portfolio-management-vision__up-arrow">
          <span
            className={'tc-arrow' + upArrowClass}
            onClick={() => props.swapEntryUp(props.entryId)}
          ></span>
        </div>
        <div className="p-portfolio-management-vision__down-arrow">
          <span
            className={'tc-arrow' + downArrowClass}
            onClick={() => props.swapEntryDown(props.entryId)}
          ></span>
        </div>
      </div>
    );
  }

  // show the checkmark to hide/show the entry if not
  else {
    // check is greyed out when entry is hidden
    let checkColor = '';
    if (props.hiddenEntryIds.indexOf(props.entryId) !== -1) {
      checkColor = ' p-portfolio-management-vision__check--grey';
    }

    // check has slightly different styles for different pages
    const outcomeStoryManagementCheck =
      props.itemType == 'OUTCOME_STORY_ENTRY'
        ? ' p-portfolio-outcome-story-management__check'
        : '';

    return (
      <div
        className={
          'p-portfolio-management-vision__check' +
          checkColor +
          outcomeStoryManagementCheck
        }
        onClick={() => props.toggleEntryVisibility(props.entryId)}
      >
        <span className="tc-check"></span>
      </div>
    );
  }
};

CheckArrows.propTypes = {
  reorderActive: PropTypes.bool.isRequired,
  itemType: PropTypes.string.isRequired,
  disableUp: PropTypes.bool,
  disableDown: PropTypes.bool,
  swapEntryUp: PropTypes.func.isRequired,
  swapEntryDown: PropTypes.func.isRequired,
  toggleEntryVisibility: PropTypes.func.isRequired,
  entryId: PropTypes.number.isRequired,
};

export default CheckArrows;
