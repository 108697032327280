// @ts-nocheck FIXME
const Pass = (props) => {
  const setIcon = (status) => {
    switch (status) {
      case 'success':
        return 'tc-v5-check-in';
      case 'error':
        return 'tc-v5-alert';
      default:
        return 'tc-v5-question';
    }
  };

  return (
    <div className="row">
      <ul className="network-list">
        {props.checkedNetworks.map((network, i) => (
          <li
            key={i}
            className={`${setIcon(network.status)} network network-${
              network.status
            }`}
          >
            <div>
              <h3 className="v5__heading-4">{network.displayUrl}</h3>
              <p className="v5__body-copy">{network.statusLabel}</p>

              {network.status === 'error' && (
                <p className="v5__body-copy">{network.errorMessage}</p>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pass;
