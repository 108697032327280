import { getPageData } from '@apis/lab';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import cookieStorage from '@utils/cookie-storage';
import { UserManager } from '@utils/UserManager';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Logout(): JSX.Element {
  const navigate = useNavigate();

  const logout = async () => {
    await getPageData('/logout?noredirect=true');
    cookieStorage.remove(UserManager.userStoreKey);
    navigate('/login');
  };

  useEffect(() => {
    logout();
  }, []);

  return <LoadingPage />;
}
