// @ts-nocheck FIXME
import PropTypes from 'prop-types';
import Utils from './../../../components/DynamicUtils';
import Badges from './Sections/Badges';
import Challenge from './Sections/Challenge';
import Closing from './Sections/Closing';
import Cover from './Sections/Cover';
import GrowthMap from './Sections/GrowthMap';
import Introduction from './Sections/Introduction';
import Stats from './Sections/Stats';

const TeacherPortfolioPrint = (props) => {
  // add a class to adjust the margin of the cover page details for preview mode
  let portfolioModeClass = ' print-portfolio--preview-mode';

  // if we are in print mode, print the portfolio
  if (!props.inPreviewMode) {
    portfolioModeClass = ' print-portfolio--print-mode';
    Utils.addClass(document.body, 'fluid');
    window.print();
  }

  // set up challenges strategies > feedback > (comment, measurements, artifacts)
  const ChallengesNode = props.entries.challenges.map((challenge, i) => {
    return <Challenge {...challenge} key={'challenge-' + challenge.id + i} />;
  });

  return (
    <div className={'print-portfolio' + portfolioModeClass}>
      <Cover {...props.portfolio} />
      <Stats {...props.entries.stats} pdYear={props.portfolio.term} />
      <Introduction {...props.portfolio} />
      <GrowthMap growthmap={props.entries.growthmap} />
      {ChallengesNode}
      <Badges badges={props.entries.badges} />
      <Closing {...props.portfolio} />
    </div>
  );
};

TeacherPortfolioPrint.propTypes = {
  inPreviewMode: PropTypes.bool,
  entries: PropTypes.object.isRequired,
};

export default TeacherPortfolioPrint;
